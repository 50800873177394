import { shuffle } from "../utils/array"
import { pack_color_f } from "./math_funcs"

/**
 * @typedef ColorLike
 * @property {number} r
 * @property {number} g
 * @property {number} b
 * @property {number} a
 */

/**
 * @param r
 * @param g
 * @param b
 * @param a
 */
export function Color(r = 1.0, g = 1.0, b = 1.0, a = 1.0) {
    /** @type {number} */
    this.r = r
    /** @type {number} */
    this.g = g
    /** @type {number} */
    this.b = b
    /** @type {number} */
    this.a = a

    /** @type {[number, number, number, number]} */
    this._rgba = null
}
Color.prototype = {
    constructor: Color,
    /**
     * @param {number} r
     * @param {number} g
     * @param {number} b
     * @param {number} [a]
     * @returns {this}
     */
    set(r, g, b, a = 1.0) {
        this.r = r
        this.g = g
        this.b = b
        this.a = a
        return this
    },
    /**
     * @param {number} hex
     * @returns {this}
     */
    set_with_hex(hex) {
        const [r, g, b] = hex2rgb(hex)
        this.r = r
        this.g = g
        this.b = b
        return this
    },
    /**
     * @param {number[]} arr
     * @returns {this}
     */
    set_with_array(arr) {
        this.r = arr[0]
        this.g = arr[1]
        this.b = arr[2]
        this.a = arr[3]
        return this
    },

    /**
     * @param {ColorLike} c
     * @returns {this}
     */
    copy(c) {
        this.r = c.r
        this.g = c.g
        this.b = c.b
        this.a = c.a
        return this
    },
    clone() {
        return new Color().copy(this)
    },

    to_linear() {
        return new Color().set(
            this.r < 0.04045 ? this.r * (1.0 / 12.92) : Math.pow((this.r + 0.055) * (1.0 / (1 + 0.055)), 2.4),
            this.g < 0.04045 ? this.g * (1.0 / 12.92) : Math.pow((this.g + 0.055) * (1.0 / (1 + 0.055)), 2.4),
            this.b < 0.04045 ? this.b * (1.0 / 12.92) : Math.pow((this.b + 0.055) * (1.0 / (1 + 0.055)), 2.4),
            this.a
        )
    },

    to_srgb() {
        return new Color().set(
            this.r < 0.0031308 ? 12.92 * this.r : (1.0 + 0.055) * Math.pow(this.r, 1.0 / 2.4) - 0.055,
            this.g < 0.0031308 ? 12.92 * this.g : (1.0 + 0.055) * Math.pow(this.g, 1.0 / 2.4) - 0.055,
            this.b < 0.0031308 ? 12.92 * this.b : (1.0 + 0.055) * Math.pow(this.b, 1.0 / 2.4) - 0.055,
            this.a
        )
    },

    /**
     * @param {ColorLike} c
     * @returns {this}
     */
    multiply(c) {
        this.r *= c.r
        this.g *= c.g
        this.b *= c.b
        this.a *= c.a
        return this
    },

    /**
     * @param {ColorLike} p_over
     * @returns {this}
     */
    blend(p_over) {
        let res_a = 0
        const sa = 1 - p_over.a
        res_a = this.a * sa + p_over.a
        if (res_a === 0) {
            return this.set(0, 0, 0, 0)
        } else {
            return this.set(
                (this.r * this.a * sa + p_over.r * p_over.a) / res_a,
                (this.g * this.a * sa + p_over.g * p_over.a) / res_a,
                (this.b * this.a * sa + p_over.b * p_over.a) / res_a,
                res_a
            )
        }
    },

    /**
     * @param {ColorLike} p_b
     * @param {number} p_t
     * @param {Color} [r_out]
     * @returns {Color}
     */
    linear_interpolate(p_b, p_t, r_out = new Color()) {
        r_out.copy(this)
        r_out.r += (p_t * (p_b.r - this.r))
        r_out.g += (p_t * (p_b.g - this.g))
        r_out.b += (p_t * (p_b.b - this.b))
        r_out.a += (p_t * (p_b.a - this.a))
        return r_out
    },

    as_hex() {
        if (!this._rgba) this._rgba = Array(4)
        this._rgba[0] = this.r
        this._rgba[1] = this.g
        this._rgba[2] = this.b
        this._rgba[3] = this.a
        return rgb2hex(this._rgba)
    },

    as_rgba8() {
        return pack_color_f(this.r, this.g, this.b, this.a)
    },

    /**
     * @param {number[]} [out]
     * @returns {number[]}
     */
    as_array(out) {
        if (!out && !this._rgba) {
            this._rgba = [this.r, this.g, this.b, this.a]
            return this._rgba
        }
        const _out = out || this._rgba
        _out[0] = this.r
        _out[1] = this.g
        _out[2] = this.b
        _out[3] = this.a
        return _out
    },

    /**
     * @param {ColorLike} value
     * @returns {boolean}
     */
    equals(value) {
        return this.r === value.r
            &&
            this.g === value.g
            &&
            this.b === value.b
            &&
            this.a === value.a
    },
}

/**
 * @param {number} p_hex
 * @returns {Color}
 */
Color.hex = (p_hex) => {
    const rgb = hex2rgb(p_hex)
    return new Color(rgb[0], rgb[1], rgb[2])
}

/**
 * @param {string} p_color
 * @returns {Color}
 */
Color.html = (p_color) => Color.hex(parseInt(p_color.replace(/^#/, '0x'), 16))

/**
 * Converts a hex color number to an [R, G, B] array
 *
 * @param {number} hex - The number to convert
 * @param  {number[] | Float32Array} [out] If supplied, this array will be used rather than returning a new one
 * @returns {number[] | Float32Array} An array representing the [R, G, B] of the color.
 */
export function hex2rgb(hex, out = []) {
    out[0] = ((hex >> 16) & 0xFF) / 255
    out[1] = ((hex >> 8) & 0xFF) / 255
    out[2] = (hex & 0xFF) / 255
    return out
}

/**
 * Converts a hex color number to a string.
 *
 * @param {number} hex_num - Number in hex
 * @returns {string} The string color.
 */
export function hex2string(hex_num) {
    let hex = hex_num.toString(16)
    hex = '000000'.substr(0, 6 - hex.length) + hex
    return `#${hex}`
}

/**
 * Converts a color as an [R, G, B] array to a hex number
 *
 * @param {number[] | Float32Array} rgb - rgb array
 * @returns {number} The color number
 */
export function rgb2hex(rgb) {
    return (((rgb[0] * 255) << 16) + ((rgb[1] * 255) << 8) + (rgb[2] * 255 | 0))
}

export const hsv2rgb = (hue, s, v) => {
    const saturation = Math.max(0, Math.min(s, 1))
    const value = Math.max(0, Math.min(v, 1))

    let rgb
    if (saturation === 0) {
        return [value, value, value]
    }

    const side = hue / 60
    const chroma = value * saturation
    const x = chroma * (1 - Math.abs((side % 2) - 1))
    const match = value - chroma

    switch (Math.floor(side)) {
        case 0:
            rgb = [chroma, x, 0]
            break
        case 1:
            rgb = [x, chroma, 0]
            break
        case 2:
            rgb = [0, chroma, x]
            break
        case 3:
            rgb = [0, x, chroma]
            break
        case 4:
            rgb = [x, 0, chroma]
            break
        case 5:
            rgb = [chroma, 0, x]
            break
        default:
            rgb = [0, 0, 0]
    }

    rgb[0] += match
    rgb[1] += match
    rgb[2] += match

    return rgb
}

export const rgb2hsv = (r, g, b) => {
    const max = Math.max(r, g, b)
    const min = Math.min(r, g, b)
    const d = max - min
    const s = max === 0 ? 0 : d / max
    const v = max

    let h
    switch (max) {
        case min:
            h = 0
            break
        case r:
            h = g - b + d * (g < b ? 6 : 0)
            h /= 6 * d
            break
        case g:
            h = b - r + d * 2
            h /= 6 * d
            break
        case b:
            h = r - g + d * 4
            h /= 6 * d
            break
        default:
            h = 0
            break
    }

    return [Math.round(h * 360), s, v]
}

// eslint-disable-next-line no-unused-vars
const rgb2cielab = (() => {
    // default illuminant is D65
    const illuminantRef = [1.052156925, 1.000000000, 0.918357670]
    const invIlluminantRef = illuminantRef.map(v => 1.0 / v)

    const a11 = 10135552.0 / 24577794.0
    const a12 = 8788810.0 / 24577794.0
    const a13 = 4435075.0 / 24577794.0
    const a21 = 2613072.0 / 12288897.0
    const a22 = 8788810.0 / 12288897.0
    const a23 = 887015.0 / 12288897.0
    const a31 = 1425312.0 / 73733382.0
    const a32 = 8788810.0 / 73733382.0
    const a33 = 70074185.0 / 73733382.0

    const delta = 6.0 / 29.0
    const deltaSquare = delta * delta
    const deltaCube = delta * deltaSquare
    const factor = 1.0 / (3.0 * deltaSquare)
    const term = 4.0 / 29.0

    const inv_3 = 1.0 / 3.0

    /**
     * @param {number} r
     * @param {number} g
     * @param {number} b
     */
    return (r, g, b) => {
        const x = a11 * r + a12 * g + a13 * b
        const y = a21 * r + a22 * g + a23 * b
        const z = a31 * r + a32 * g + a33 * b

        let x2 = x * invIlluminantRef[0]
        let y2 = y * invIlluminantRef[1]
        let z2 = z * invIlluminantRef[2]
        x2 = ((x2 > deltaCube) ? Math.pow(x2, inv_3) : (factor * x2 + term))
        y2 = ((y2 > deltaCube) ? Math.pow(y2, inv_3) : (factor * y2 + term))
        z2 = ((z2 > deltaCube) ? Math.pow(z2, inv_3) : (factor * z2 + term))
        const o_L = 116.0 * y2 - 16.0
        const o_a = 500.0 * (x2 - y2)
        const o_b = 200.0 * (y2 - z2)

        return [o_L, o_a, o_b]
    }
})()

/**
 * @param {number} color_amount
 * @param {number} divisions
 * @param {number} non_grey > 0 to avoid greys
 * @param {number} min_sum > 0 to avoid dark colors
 * @param {number} gamma
 */
export function buildRandomPalette(color_amount, divisions, non_grey = 0, min_sum = 0, gamma = 1.0 / 2.2) {
    /** @type {number[]} */
    const colors = []
    for (let i = 0, num = divisions * divisions * divisions; i < num; i++) {
        let r = (i % divisions) / (divisions - 1)
        let g = (((i / divisions) | 0) % divisions) / (divisions - 1)
        let b = (((i / (divisions * divisions)) | 0) % divisions) / (divisions - 1)
        // gamma fix
        r **= gamma
        g **= gamma
        b **= gamma
        // ignore dark colors
        if (r + g + b < min_sum) {
            continue
        }
        // ignore grey colors
        if (Math.abs(r - g) < non_grey && Math.abs(r - b) < non_grey && Math.abs(g - b) < non_grey) {
            continue
        }
        colors.push(rgb2hex([r, g, b]))
    }
    return shuffle(colors.slice(0, Math.min(colors.length, color_amount)))
}
