import { css } from 'styled-components'

export const checkerBackground = (
  size,
  color,
  bg = 'black'
) => `linear-gradient(45deg, ${color} 25%, transparent 25%) 0 0 / ${size}px ${size}px,
linear-gradient(-45deg, ${color} 25%, transparent 25%) 0 ${size / 2}px / ${size}px ${size}px,
linear-gradient(45deg, transparent 75%, ${color} 75%) ${size / 2}px -${size / 2}px / ${size}px ${size}px,
linear-gradient(-45deg, transparent 75%, ${color} 75%) -${size / 2}px 0 / ${size}px ${size}px, ${bg}
`

export const boxShadow = (level) => {
  const shadows = {
    0: 'none',
    1: '0px 0px 1px 0px rgba(0, 0, 0, .4)',
    2: '0px 1px 2px 0px rgba(0, 0, 0, .4)',
    3: '0px 2px 4px 0px rgba(0, 0, 0, .4)',
    4: '0px 4px 6px 0px rgba(0, 0, 0, .4)',
    5: '0px 8px 10px 0px rgba(0, 0, 0, .4)',
    outline: '0px 0px 1px 0px rgba(255, 255, 255, .6)'
  }
  return `box-shadow: ${shadows[level] || 'none'};`
}

export const color = (name, alpha) => (props) => {
  const palette = props.theme.colors[name]
  return alpha ? palette[alpha] : palette
}

export const font = (size, weight, transformOrigin) => (props) => {
  const { fontWeights, fontSizes, lineHeights, letterSpaces } = props.theme
  const fontWeight = fontWeights[weight]
  const fontSize = fontSizes[size]
  const lineHeight = lineHeights[size]
  const letterSpacing = letterSpaces[size]
  return css`
    ${fontSize >= 12
      ? `
          font-size: ${fontSize}px;
          max-width: 100%;
        `
      : `
          font-size: 12px;
          max-width: calc(100% / ${fontSize} * 12);
          transform: scale(calc(${fontSize} / 12));
          transform-origin: ${transformOrigin};
        `}
    line-height: ${lineHeight};
    letter-spacing: ${letterSpacing};
    font-weight: ${fontWeight};
  `
}
