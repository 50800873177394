import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ContainerElementType, ElementType } from '@phase-software/types'

import { useModal, useSetModal } from '../../providers/ModalProvider'
import { useDataStoreActions } from '../../providers/dataStore/DataStoreProvider'
import { getElementSnapshot } from '../../providers/dataStore/ElementProvider'
import { ContextMenu } from '../shared'
import { translateMenuOptions } from '../shared/Menu/utils'

const KEY = 'ElementContextMenu'

const ACTIONS = {
  CUT: 'CUT',
  COPY: 'COPY',
  PASTE: 'PASTE',
  PASTE_HERE: 'PASTE_HERE',
  DUPLICATE: 'DUPLICATE',
  DELETE: 'DELETE',
  MOVE_TO_TOP: 'MOVE_TO_TOP',
  MOVE_TO_BOTTOM: 'MOVE_TO_BOTTOM',
  MOVE_UP: 'MOVE_UP',
  MOVE_DOWN: 'MOVE_DOWN',
  GROUP: 'GROUP',
  CONTAINER: 'CONTAINER',
  UNGROUP: 'UNGROUP',
  TOGGLE_VISIBLE: 'TOGGLE_VISIBLE',
  TOGGLE_LOCK: 'TOGGLE_LOCK',
  FLIP_HORIZONTAL: 'FLIP_HORIZONTAL',
  FLIP_VERTICAL: 'FLIP_VERTICAL',
  MASK: 'MASK'
}

const OPTIONS = {
  CUT: { name: 'menu.cut', value: ACTIONS.CUT, shortcut: '⌘X' },
  COPY: { name: 'menu.copy', value: ACTIONS.COPY, shortcut: '⌘C' },
  PASTE: { name: 'menu.paste', value: ACTIONS.PASTE, shortcut: '⌘V' },
  // FIXME: PASTE_HERE_OPTION
  //    Temporary hide this option, requested by design team
  //    This is due to the behavior of paste here is not clearly defined
  // PASTE_HERE: { name: 'Paste here', value: ACTIONS.PASTE_HERE },
  DUPLICATE: { name: 'menu.duplicate', value: ACTIONS.DUPLICATE, shortcut: '⌘D' },
  DELETE: { name: 'menu.delete', value: ACTIONS.DELETE },
  MOVE_TO_TOP: {
    name: 'menu.bring_to_front',
    value: ACTIONS.MOVE_TO_TOP,
    shortcut: '⌥⌘]'
  },
  MOVE_TO_BOTTOM: {
    name: 'menu.send_to_back',
    value: ACTIONS.MOVE_TO_BOTTOM,
    shortcut: '⌥⌘['
  },
  MOVE_UP: { name: 'menu.move_forward', value: ACTIONS.MOVE_UP, shortcut: '⌘]' },
  MOVE_DOWN: {
    name: 'menu.move_backward',
    value: ACTIONS.MOVE_DOWN,
    shortcut: '⌘['
  },
  GROUP: { name: 'menu.group_selection', value: ACTIONS.GROUP, shortcut: '⌘G' },
  CONTAINER: { name: 'menu.container_selection', value: ACTIONS.CONTAINER, shortcut: '⌥⌘G' },
  UNGROUP: { name: 'menu.ungroup', value: ACTIONS.UNGROUP, shortcut: '⇧⌘G' },
  TOGGLE_VISIBLE: {
    name: 'menu.visible',
    value: ACTIONS.TOGGLE_VISIBLE,
    shortcut: '⇧⌘H'
  },
  TOGGLE_LOCK: {
    name: 'menu.lock',
    value: ACTIONS.TOGGLE_LOCK,
    shortcut: '⇧⌘L'
  },
  FLIP_HORIZONTAL: { name: 'menu.flip_horizontal', value: ACTIONS.FLIP_HORIZONTAL },
  FLIP_VERTICAL: { name: 'menu.flip_vertical', value: ACTIONS.FLIP_VERTICAL },
  MASK: { name: 'menu.mask_selection', value: ACTIONS.MASK, shortcut: '⌃⌘M' }
}

const orderOptionGroup = [OPTIONS.MOVE_TO_TOP, OPTIONS.MOVE_TO_BOTTOM, OPTIONS.MOVE_UP, OPTIONS.MOVE_DOWN]

const groupOptionGroup = [OPTIONS.GROUP, OPTIONS.CONTAINER, OPTIONS.UNGROUP]

const groupWithMaskOptionGroup = [OPTIONS.GROUP, OPTIONS.CONTAINER, OPTIONS.MASK]

const generalOptionGroup = [OPTIONS.TOGGLE_VISIBLE, OPTIONS.TOGGLE_LOCK]

// const flipOptionGroup = [
//   OPTIONS.FLIP_HORIZONTAL,
//   OPTIONS.FLIP_VERTICAL
// ]

const createCutCopyOptions = (elementType) => {
  return elementType === ElementType.SCREEN ? [] : [OPTIONS.CUT, OPTIONS.COPY]
}

// FIXME: PASTE_HERE_OPTION
//    Temporary hide this option, requested by design team
//    This is due to the behavior of paste here is not clearly defined
// const createPasteHereOption = (disablePaste, mousePos) => {
//   return mousePos ? { ...OPTIONS.PASTE_HERE, disabled: disablePaste } : null
// }

const createDuplicateDeleteOptions = (elementType) => {
  return elementType === ElementType.SCREEN ? null : [OPTIONS.DUPLICATE, OPTIONS.DELETE]
}

const getEditOptions = (elementType) => {
  const cutCopyOptions = createCutCopyOptions(elementType)
  // FIXME: PASTE_HERE_OPTION
  //    Temporary hide this option, requested by design team
  //    This is due to the behavior of paste here is not clearly defined
  // const pasteHereOption = createPasteHereOption(disablePaste, mousePos)
  const duplicateDeleteOptions = createDuplicateDeleteOptions(elementType)

  const options = [...cutCopyOptions, OPTIONS.PASTE]

  // FIXME: PASTE_HERE_OPTION
  //    Temporary hide this option, requested by design team
  //    This is due to the behavior of paste here is not clearly defined
  // if (pasteHereOption) {
  //   options.push(pasteHereOption)
  // }

  if (duplicateDeleteOptions) {
    options.push(...duplicateDeleteOptions)
  }

  return options
}

const mapOptionGroupListToOptions = (optionGroupList) => {
  return optionGroupList.reduce((options, optGroup) => {
    if (options.length) {
      options.push('-')
    }
    return options.concat(optGroup)
  }, [])
}

const getElementMenuOptions = (elementType, mousePos) => {
  switch (elementType) {
    case ElementType.SCREEN:
      return mapOptionGroupListToOptions([getEditOptions(elementType, mousePos), generalOptionGroup])
    case ElementType.TEXT:
    case ElementType.PATH:
      return mapOptionGroupListToOptions([
        getEditOptions(elementType, mousePos),
        orderOptionGroup,
        groupWithMaskOptionGroup,
        generalOptionGroup
        // flipOptionGroup
      ])
    case ElementType.CONTAINER:
      return mapOptionGroupListToOptions([
        getEditOptions(elementType, mousePos),
        orderOptionGroup,
        groupOptionGroup,
        generalOptionGroup
        // flipOptionGroup
      ])
  }
}

const getMenuOptions = (elements, mousePos) => {
  const isMulti = elements.length > 1
  if (isMulti) {
    const hasContainer = elements.some((el) => el.isContainer)
    return mapOptionGroupListToOptions([
      getEditOptions(null, mousePos),
      orderOptionGroup,
      hasContainer ? groupOptionGroup : [...groupWithMaskOptionGroup, OPTIONS.UNGROUP],
      generalOptionGroup
      // flipOptionGroup
    ])
  }
  const elementType = elements[0].elementType
  return getElementMenuOptions(elementType, mousePos)
}

const ElementContextMenu = () => {
  const {
    open,
    data: { elementIds = [], mousePos, canvasPosition }
  } = useModal((o) => o[KEY])
  const { closeModal } = useSetModal(KEY)
  const elementMap = getElementSnapshot()
  const {
    cutElementList,
    copyElementList,
    pasteToElementList,
    duplicateElementList,
    deleteElementList,
    moveElementListToTop,
    moveElementListToBottom,
    moveElementListUp,
    moveElementListDown,
    groupElementList,
    ungroupElementList,
    changeElementListVisible,
    changeElementListLock,
    flipElementListHorizontal,
    flipElementListVertical,
    maskGroupElementList
  } = useDataStoreActions()
  const { t } = useTranslation('file')

  const elements = elementIds.map((elId) => elementMap[elId]).filter(Boolean)

  const options = useMemo(() => {
    const options = elements.length ? getMenuOptions(elements, mousePos) : []
    return translateMenuOptions(t, options)
  }, [elements, mousePos, t])

  const actionHandler = {
    [ACTIONS.CUT]: cutElementList,
    [ACTIONS.COPY]: copyElementList,
    [ACTIONS.PASTE]: () => pasteToElementList(null),
    [ACTIONS.PASTE_HERE]: () => pasteToElementList(mousePos),
    [ACTIONS.DUPLICATE]: duplicateElementList,
    [ACTIONS.DELETE]: deleteElementList,
    [ACTIONS.MOVE_TO_TOP]: moveElementListToTop,
    [ACTIONS.MOVE_TO_BOTTOM]: moveElementListToBottom,
    [ACTIONS.MOVE_UP]: moveElementListUp,
    [ACTIONS.MOVE_DOWN]: moveElementListDown,
    [ACTIONS.GROUP]: () => groupElementList(ContainerElementType.NORMAL_GROUP),
    [ACTIONS.CONTAINER]: () => groupElementList(ContainerElementType.CONTAINER),
    [ACTIONS.UNGROUP]: ungroupElementList,
    [ACTIONS.TOGGLE_VISIBLE]: changeElementListVisible,
    [ACTIONS.TOGGLE_LOCK]: changeElementListLock,
    [ACTIONS.FLIP_HORIZONTAL]: flipElementListHorizontal,
    [ACTIONS.FLIP_VERTICAL]: flipElementListVertical,
    [ACTIONS.MASK]: maskGroupElementList
  }

  const handleSelect = (action) => {
    actionHandler[action.value](elementIds)
  }

  const cursorPosition = useMemo(() => {
    if (!mousePos) return

    if (canvasPosition) {
      return {
        top: mousePos.y + canvasPosition.top,
        left: mousePos.x + canvasPosition.left
      }
    }

    return {
      top: mousePos.y,
      left: mousePos.x
    }
  }, [mousePos, canvasPosition])

  return (
    <ContextMenu
      open={open}
      options={options}
      onSelect={handleSelect}
      onClose={closeModal}
      cursorPosition={cursorPosition}
    />
  )
}

export default React.memo(ElementContextMenu)
