import { useCallback, useEffect, useState } from 'react'
import { useOnPreferenceUpdatedSubscription, useTemplateSectionVisibilityPreferenceQuery } from '../generated/graphql'
import { useProfile, useProfileActions } from '../providers/ProfileProvider'

export const useFeaturedTemplate = () => {
  const profile = useProfile()
  const { updateTemplateSectionVisibility } = useProfileActions()
  const [isSectionVisible, setIsSectionVisible] = useState(false)

  const { data, loading, refetch } = useTemplateSectionVisibilityPreferenceQuery({
    variables: { id: profile.id }
  })

  const hideTemplateSection = useCallback(async () => {
    setIsSectionVisible(false)
    await updateTemplateSectionVisibility(false)
  }, [updateTemplateSectionVisibility])

  useEffect(() => {
    if (loading) {
      return
    }
    const templateVisibility = data?.user_preferences_by_pk?.value ?? true
    setIsSectionVisible(templateVisibility ?? true)
  }, [data, loading, setIsSectionVisible])

  useEffect(() => {
    refetch()
  }, [refetch])

  const { data: updatedData, loading: subscriptionLoading } = useOnPreferenceUpdatedSubscription({
    variables: { id: profile.id, key: 'template-section-visibility' },
    skip: !profile.id || !isSectionVisible
  })

  useEffect(() => {
    if (!subscriptionLoading && updatedData?.user_preferences && updatedData.user_preferences.length > 0) {
      const updatedVisibility = updatedData?.user_preferences[0].value
      if (updatedVisibility == null) return

      setIsSectionVisible(updatedVisibility)
    }
  }, [updatedData, subscriptionLoading])

  return { isSectionVisible: isSectionVisible && !loading, hideTemplateSection }
}
