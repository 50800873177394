import {
    LayerType,
    PaintType,
    BlendMode
} from '@phase-software/types'
import { Vector4, createPropTypes, setAdd } from '@phase-software/data-utils'
import { DEFAULT_SHADOW_VALUE, DEFAULT_INNER_SHADOW_VALUE } from '../constant'
import { ComputedLayer } from './ComputedLayer'


const PROP_TYPES = createPropTypes({
    color: { type: Vector4 }
})

const PROP_KEYS_LAYER_TO_CL = {
    paint: {
        __switch__: 'paintType',
        SOLID: [
            'paintType',
            'blendMode',
            'opacity',
            'color'
        ]
    },
    offsetX: null,
    offsetY: null,
    blur: null,
    spread: null
}

const PROP_KEYS_CL_TO_LAYER = [
    {
        prop: 'paint',
        keys: {
            __switch__: 'paintType',
            SOLID: [
                'color',
                'opacity',
                'blendMode'
            ]
        }
    },
    'offsetX',
    'offsetY',
    'blur',
    'spread'
]

const UNDO_CHANGES = [
    'paintType',
    'blendMode',
    'opacity',
    'color',
    'offsetX',
    'offsetY',
    'blur',
    'spread'
]


export class ComputedShadow extends ComputedLayer {
    /**
     * @param {DataStore} dataStore
     * @param {ComputedShadowData} data
     */
    constructor(dataStore, data) {
        super(dataStore, data)

        setAdd(this.undoChanges, UNDO_CHANGES)
    }

    _init() {
        super._init()

        this.propTypes = { ...this.propTypes, ...PROP_TYPES }

        this._propKeysLayerToCL = { ...this._propKeysLayerToCL, ...PROP_KEYS_LAYER_TO_CL }
        this._propKeysCLToLayer = [ ...this._propKeysCLToLayer, ...PROP_KEYS_CL_TO_LAYER ]

        this.data.paintType = PaintType.SOLID
        this.data.blendMode = BlendMode.NORMAL
        this.data.opacity = 1

        // Solid paint
        this.data.color = new Vector4(Vector4.ONE)

        this.data.offsetX = 0
        this.data.offsetY = 0
        this.data.blur = 4
        this.data.spread = 0
    }

    /**
     * @param {ComputedFillData} data
     */
    load(data) {
        super.load(data)

        const { paintType } = data

        // keys from Paint
        this.data.paintType = paintType
        this.data.blendMode = data.blendMode
        this.data.opacity = data.opacity

        this.data.color = new Vector4(data.color)

        this.data.offsetX = data.offsetX
        this.data.offsetY = data.offsetY
        this.data.blur = data.blur
        this.data.spread = data.spread
    }

    defaultValue() {
        return this.data.layerType === LayerType.SHADOW
            ? DEFAULT_SHADOW_VALUE
            : DEFAULT_INNER_SHADOW_VALUE
    }
}