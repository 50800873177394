import React from 'react'
import { useTranslation } from 'react-i18next'

import { toDeg, toRad } from '@phase-software/data-utils'

import { formatAsDegrees } from '../../../../utils/formatter'
import { degreeFormatValidator } from '../../../../utils/validator'
import { Icon } from '../../../shared'
import Property from '../Property'
import PropertyRow from '../PropertyRow'

export const Skew = () => {
  const { t } = useTranslation('file', { keyPrefix: 'property_editor.transform_editor' })

  return (
    <PropertyRow columns={[94, 94]} className="relative">
      <Property
        captionAlign="center"
        field="skewX"
        caption={<Icon interactive={false} name="SkewX" />}
        data-test-id="skew-X-input"
        toDisplay={toDeg}
        toValue={toRad}
        formatter={formatAsDegrees}
        validator={degreeFormatValidator}
        tooltip={t('skew_x')}
      />
      <Property
        captionAlign="center"
        field="skewY"
        caption={<Icon interactive={false} name="SkewY" />}
        data-test-id="skew-Y-input"
        toDisplay={toDeg}
        toValue={toRad}
        formatter={formatAsDegrees}
        validator={degreeFormatValidator}
        tooltip={t('skew_y')}
      />
    </PropertyRow>
  )
}

export default Skew
