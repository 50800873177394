import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { EndShape, LayerType, Mode } from '@phase-software/types'

import { useDataStoreActions } from '../../../../providers/dataStore/DataStoreProvider'
import { useEditorActions } from '../../../../providers/dataStore/EditorProvider'
import { useStroke } from '../../../../providers/dataStore/StrokeProvider'
import { useUI } from '../../../../providers/dataStore/UIProvider'
import { RadioButtonGroup } from '../../../shared'
import PropertyState from '../PropertyState'

type StrokeEndsProps = {
  layerItemId: string
  disabled: boolean
}

export const StrokeEnds = ({ layerItemId, disabled }: StrokeEndsProps) => {
  const [hoverWrap, setHoverWrap] = useState(false)
  const ends = useStroke((o) => o[layerItemId].ends)
  const { setLayerEnds, toggleLayerVisible } = useEditorActions()
  const { commitUndo } = useDataStoreActions()
  const mode = useUI().mode
  const { t } = useTranslation('file', { keyPrefix: 'stroke_end_shape' })

  const isActionMode = mode === Mode.ACTION

  const handleChange = (v: string) => {
    setLayerEnds(layerItemId, parseInt(v))
    commitUndo()
  }

  const handleMouseEnter = useCallback(() => {
    setHoverWrap(true)
  }, [])

  const handleMouseLeave = useCallback(() => {
    setHoverWrap(false)
  }, [])

  const onSelectionFocus = useCallback(() => {
    toggleLayerVisible(layerItemId, true)
  }, [layerItemId, toggleLayerVisible])

  const radioButtonOptions = useMemo(
    () => [
      {
        name: 'stroke_end_straight',
        value: EndShape.STRAIGHT,
        dataTestId: 'border-stroke-ends-straight',
        tooltip: t('straight'),
        icon: 'EndStraight'
      },
      {
        name: 'stroke_end_round',
        value: EndShape.ROUND,
        dataTestId: 'border-stroke-ends-round',
        tooltip: t('round'),
        icon: 'EndRound'
      }
    ],
    [t]
  )

  return (
    <div
      className={`flex items-center ${isActionMode ? 'cursor-pointer' : ''}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="w-24">
        <PropertyState
          name="ends"
          layerItemId={layerItemId}
          layerType={LayerType.STROKE}
          hoverWrap={hoverWrap}
          hoverContent={false}
        />
      </div>
      <RadioButtonGroup
        options={radioButtonOptions}
        selectedValue={ends}
        onSelectionChange={handleChange}
        onSelectionFocus={onSelectionFocus}
        disabled={disabled}
        className="w-full"
      />
    </div>
  )
}

export default React.memo(StrokeEnds)
