import { IRProperty } from './IRProperty'

export class IRComponent {
  static props = []

  constructor(data = {}) {
    this._props = new Map()

    // Combine properties from the current instance's constructor and all ancestors
    let currentClass = this.constructor
    while (currentClass) {
      if (currentClass.props) {
        const propsToAdd = currentClass.props
        for (const prop of propsToAdd) {
          if (typeof prop === 'string') {
            this._props.set(prop, { key: prop, animatable: true })
          } else {
            this._props.set(prop.key, prop)
          }
        }
      }
      currentClass = Object.getPrototypeOf(currentClass)
    }

    // load data from the object
    this._props.forEach(({ key, defaultValue, ...rest }) => {
      const value = data[key] === undefined ? defaultValue : data[key]
      this[key] = new IRProperty({ ...rest, value })
    })
  }

  get propKeys() {
    return Array.from(this._props.keys())
  }

  get animatableKeys() {
    return this.constructor.props.reduce((acc, cur) => {
      if (typeof cur === 'string') {
        acc.push(cur)
      }
      if (cur.animatable) {
        acc.push(cur.key)
      }
      return acc
    }, [])
  }

  fromJSON(data) {
    this.propKeys.forEach(propKey => {
      this[propKey].fromJSON(data[propKey])
    })
    return this
  }

  toJSON() {
    const data = {}
    this.propKeys.forEach(propKey => {
      data[propKey] = this[propKey].toJSON()
    })
    return data
  }
}
