import { ScrollDirection } from '../../atoms'

export const getMsPerPx = (width: number, scale: number, duration: number): number => {
  return duration / width / scale
}

/**
 * Rounds time to the nearest 10 ms
 * @param {number} time
 * @returns {number}
 */
export const getTimeAtStep = (time: number): number => {
  return Math.round(time / 10) * 10
}

export function animateWithDuration(job: (progress: number) => void, duration: number): () => void {
  let startTime: null | number = null
  let _raf: number

  function step(currentTime: number) {
    if (startTime === null) {
      startTime = currentTime // Initialize start time on the first call
    }
    const elapsed = currentTime - startTime
    const progress = Math.min(elapsed / duration, 1) // Calculate progress percentage

    job(progress) // Call the job function with the current progress

    if (progress < 1) {
      _raf = requestAnimationFrame(step) // Continue the animation if not done
    }
  }

  _raf = requestAnimationFrame(step) // Start the animation
  return () => {
    cancelAnimationFrame(_raf)
  }
}

export const getSnappedTime = (time: number, timeList: number[], snapThreshold: number): number => {
  let snappedTime = getTimeAtStep(time)
  for (const t of timeList) {
    const diff = Math.abs(time - t)
    if (diff <= snapThreshold) {
      snappedTime = t
    }
  }
  return snappedTime
}

enum Position {
  BEFORE,
  AFTER,
  NONE
}

export const getPosition = (value: number, range: [number, number], offset = 16) => {
  if (value - range[0] < offset) {
    return Position.BEFORE
  }
  if (range[1] - value < offset) {
    return Position.AFTER
  }
  return Position.NONE
}

type Bounds = {
  top: number
  left: number
  width: number
  height: number
}

export const getScrollDirection = (
  { clientX, clientY }: { clientX: number; clientY: number },
  bounds: Bounds,
  offset = 16
) => {
  const set = new Set<ScrollDirection>()

  const hPos = getPosition(clientX, [bounds.left, bounds.left + bounds.width], offset)
  if (hPos === Position.BEFORE) {
    set.add(ScrollDirection.LEFT)
  }
  if (hPos === Position.AFTER) {
    set.add(ScrollDirection.RIGHT)
  }

  const vPos = getPosition(clientY, [bounds.top, bounds.top + bounds.height], offset)
  if (vPos === Position.BEFORE) {
    set.add(ScrollDirection.TOP)
  }
  if (vPos === Position.AFTER) {
    set.add(ScrollDirection.BOTTOM)
  }
  return set
}
