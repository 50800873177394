import { isNull } from './commons'
import { Vector4 } from './Vector4'

export class ColorStop {
    /**
     * Creates new ColorStop object from data
     * @param  {ColorStopData} data
     */
    constructor({ position, color } = {}) {
        if (isNull(position) || isNull(color)) {
            throw new Error('ColorStop position or color arguments are missing')
        }
        this.position = position
        this.color = new Vector4(color)
    }

    /**
     * Copies values from another ColorStop
     * @param  {(ColorStop | ColorStopData)} data
     */
    copy({ position, color } = {}) {
        this.position = position
        this.color = new Vector4(color)
    }

    /**
     * Checks if this ColorStop is equal to another ColorStop
     * @param  {ColorStop} cs
     * @returns {bool}      true if equal; false otherwise
     */
    eq(cs) {
        return cs && this.position === cs.position && this.color.eq(cs.color)
    }

    /**
     * Serialize ColorStop data
     * @returns {ColorStopData}
     */
    save() {
        const data = {}
        data.position = this.position
        data.color = [...this.color]
        return data
    }
}

/**
 * @typedef {object} ColorStopData
 * @property {number} position        range [0.0, 1.0]
 * @property {Vector4} color
 */
