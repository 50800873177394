export enum EffectType {
  NONE = 0,
  CUSTOM = 5,
  PAINT_OVER_TRANSPARENT = 7,
  TINT = 20,
  FILL = 21,
  STROKE = 22,
  TRITONE = 23,
  PRO_LEVELS = 24,
  DROP_SHADOW = 25,
  RADIAL_WIPE = 26,
  DISPLACEMENT_MAP = 27,
  MATTE3 = 28,
  GAUSSIAN_BLUR = 29,
  MESH_WRAP = 31,
  WAVY = 32,
  SPHERIZE = 33,
  PUPPET = 34,
}
