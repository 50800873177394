import React from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'

import { IfFeatureEnabled } from '@growthbook/growthbook-react'
import { WorkspaceType } from '@phase-software/types'

import { NotificationList } from '../../components/Notification'
import { Icon, NavigationItem, ScrollView, SidebarTitle } from '../../components/shared'
import { SESSION_STORAGE_KEYS } from '../../constant'
import { FEATURE_KEYS } from '../../growthbook-feature-keys'
import { useWorkspaceContext } from '../../providers/WorkspaceContextProvider'
import { PERSONAL_SETTINGS_CHANGE_PASSWORD, PERSONAL_SETTINGS_PROFILE } from '../../routeConstants'
import {
  getPersonalChangePasswordPath,
  getPersonalProfilePath,
  getWorkspaceIndexPath
} from '../../utils/pathGenerators'
import PersonalPassword from './PersonalPassword'
import PersonalProfile from './PersonalProfile'
import { PersonalSettingsContextProvider } from './PersonalSettingsContext'

const PersonalSettings = () => {
  const { t } = useTranslation('setting')
  const { workspaceData, personalWorkspaceData } = useWorkspaceContext()
  const history = useHistory()

  const personalWorkspaceSlug = personalWorkspaceData.slug

  return (
    <>
      <NotificationList offsetLeft={248} />
      <div className="grid grid-cols-[248px,1fr] items-stretch h-screen w-screen text-white bg-neutral-90">
        <PersonalSettingsContextProvider>
          <div className="flex flex-col bg-neutral-90">
            <SidebarTitle
              dataTestId="back-to-dashboard"
              onClick={() => {
                const redirectPath = sessionStorage.getItem(SESSION_STORAGE_KEYS.REDIRECT_URL)
                if (redirectPath) {
                  sessionStorage.removeItem(SESSION_STORAGE_KEYS.REDIRECT_URL)
                  history.push(redirectPath)
                } else {
                  history.push(getWorkspaceIndexPath(WorkspaceType.PERSONAL, workspaceData.slug))
                }
              }}
            >
              <Icon name="AngleLeft24" size="xxl" interactive={false} />
              <div>{t('back_to_dashboard')}</div>
            </SidebarTitle>
            <NavigationItem to={getPersonalProfilePath(personalWorkspaceSlug)} iconName="Profile">
              {t('profile_setting.profile')}
            </NavigationItem>
            <IfFeatureEnabled feature={FEATURE_KEYS.CHANGE_PASSWORD}>
              <NavigationItem to={getPersonalChangePasswordPath(personalWorkspaceSlug)} iconName="Lock">
                {t('profile_setting.password')}
              </NavigationItem>
            </IfFeatureEnabled>
          </div>
          <div className="bg-neutral-80 px-64 h-screen overflow-y-auto overflow-x-hidden relative">
            <div className="w-[680px] h-auto flex flex-col flex-grow bg-neutral-90 rounded-xl p-40 m-auto my-64">
              <Switch>
                <Route path={PERSONAL_SETTINGS_PROFILE}>
                  <div className="flex flex-col flex-grow h-full">
                    <p className="text-h3 font-semibold border-b border-neutral-80 pb-16 w-full">
                      {t('profile_setting.profile')}
                    </p>
                    {/* @ts-ignore TODO: fix after refactor of ScrollView */}
                    <ScrollView className="p-4 -m-4">
                      <PersonalProfile />
                    </ScrollView>
                  </div>
                </Route>
                <Route path={PERSONAL_SETTINGS_CHANGE_PASSWORD}>
                  <div className="flex flex-col flex-grow">
                    <p className="text-h3 font-semibold border-b border-neutral-80 pb-16 w-full">
                      {t('profile_setting.password')}
                    </p>
                    {/* @ts-ignore TODO: fix after refactor of ScrollView */}
                    <ScrollView className="p-4 -m-4">
                      <PersonalPassword />
                    </ScrollView>
                  </div>
                </Route>
                <Route>
                  <Redirect to={getPersonalProfilePath(personalWorkspaceSlug)} />
                </Route>
              </Switch>
            </div>
          </div>
        </PersonalSettingsContextProvider>
      </div>
    </>
  )
}

export default PersonalSettings
