import React, { memo, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { ClientSideSuspense } from '@liveblocks/react'
import { Mode, ToolType } from '@phase-software/types'

import BottomResizeHandler from '../../components/BottomResizeHandler'
import CommentPanel from '../../components/CommentPanel'
import FloatingControl from '../../components/FloatingControl'
import InspectPanel from '../../components/InspectPanel'
import LeftResizeHandler from '../../components/LeftResizeHandler'
import LoadingSpinner from '../../components/LoadingSpinner'
import Properties from '../../components/Properties'
import { FILE_EDITOR_LAYOUT_AREAS as LAYOUT, RIGHT_PANEL_WIDTH } from '../../constant'
import { useFileVersionContext } from '../../providers/FileVersionContextProvider'
import { InspectProvider } from '../../providers/InspectProvider'
import { useLayout, useSetLayout } from '../../providers/LayoutProvider'
import { RightPanelProviderProvider } from '../../providers/RightPanelProvider'
import { ScrollProvider } from '../../providers/ScrollProvider'
import { useSpinnerLoadingContext } from '../../providers/SpinnerLoadingProvider'
import { useTool } from '../../providers/dataStore/ToolProvider'
import { useUI } from '../../providers/dataStore/UIProvider'
import FileOutdatedConfirmationDialog from './components/FileOutdatedConfirmationDialog'
import FileVersionHistoryPanel from './components/FileVersionHistoryPanel'

type FileLayoutProps = {
  children: React.ReactNode
}
const FileLayout = ({ children }: FileLayoutProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const { actionHeight, contentWidth } = useLayout()
  const { setActionHeight, setContentWidth } = useSetLayout()
  const { t } = useTranslation(['file', 'common'])

  const {
    isToolbarHidden,
    isContentPanelHidden,
    isRightPanelHidden,
    isEditingState,
    isTablingState,
    mode,
    isVersioningState,
    isVersionHistoryPanelHidden
  } = useUI()
  const { loadingVersionInfo, showFileOutdatedConfirmDialog, closeRestoreConfirmDialog } = useFileVersionContext()
  const { isSpinnerLoading } = useSpinnerLoadingContext()

  const showRightPanel = useMemo(() => {
    if (isVersioningState) {
      return !isVersionHistoryPanelHidden
    }
    if (isEditingState || isTablingState) {
      return !isRightPanelHidden
    }
    return false
  }, [isVersioningState, isEditingState, isTablingState, isVersionHistoryPanelHidden, isRightPanelHidden])

  return (
    <div
      data-test-id="app-container"
      className="relative grid h-screen"
      style={{
        zIndex: 1,
        gridTemplateRows: `${isToolbarHidden ? 'auto' : '48px'} minmax(0, 1fr) auto`,
        gridTemplateColumns: `auto minmax(0, 1fr) ${showRightPanel ? `${RIGHT_PANEL_WIDTH}px` : 'auto'}`,
        gridTemplateAreas: `
          '${LAYOUT.HEADER} ${LAYOUT.HEADER} ${LAYOUT.HEADER}'
          '${LAYOUT.CONTENT_PANEL} ${LAYOUT.CANVAS} ${LAYOUT.RIGHT_PANEL}'
          '${LAYOUT.ACTION_PANEL} ${LAYOUT.ACTION_PANEL} ${LAYOUT.ACTION_PANEL}'
        `
      }}
      ref={ref}
      tabIndex={0}
    >
      {loadingVersionInfo.isLoading && (
        <div
          className="z-1 inset-0 flex flex-col items-center justify-center bg-dark-overlay-80 text-white"
          style={{ gridArea: LAYOUT.CANVAS, paddingLeft: RIGHT_PANEL_WIDTH }}
        >
          <LoadingSpinner className="mb-16" />
          <div className="max-w-[600px] text-14 font-medium truncate">
            {t('file:loading', { version: loadingVersionInfo.versionName })}
          </div>
        </div>
      )}
      {isSpinnerLoading && (
        <div className="z-10 absolute inset-0 flex flex-col items-center justify-center bg-dark-overlay-80 text-white">
          <LoadingSpinner className="mb-16" />
          <div className="max-w-[600px] text-14 font-medium truncate">{t('common:loading')}</div>
        </div>
      )}

      {showFileOutdatedConfirmDialog && (
        <FileOutdatedConfirmationDialog open={showFileOutdatedConfirmDialog} onClose={closeRestoreConfirmDialog} />
      )}

      {children}

      {(isEditingState || isTablingState) && !isContentPanelHidden ? (
        <>
          <LeftResizeHandler elementPanelWidth={contentWidth} onPositionChange={setContentWidth} />
          <BottomResizeHandler
            timelineHeight={actionHeight}
            onPositionChange={setActionHeight}
            active={mode === Mode.ACTION}
          />
        </>
      ) : (
        <div className="z-10 fixed bottom-16 left-1/2 transform -translate-x-1/2 text-white">
          <FloatingControl />
        </div>
      )}
    </div>
  )
}

export default FileLayout

const RightPanel = ({ fileId, projectId }: { fileId: string; projectId: string }) => {
  const { isEditingState, isTablingState, isVersioningState, isRightPanelHidden, isVersionHistoryPanelHidden, isInspectingState } =
    useUI()
  const { activeTool } = useTool()

  let component = null
  let testDataId = ''

  if (!isVersioningState && !isInspectingState && !isRightPanelHidden) {
    if (activeTool === ToolType.COMMENT) {
      component = <ClientSideSuspense fallback={null}>{() => <CommentPanel />}</ClientSideSuspense>
      testDataId = 'comment-panel'
    } else if (isEditingState || isTablingState) {
      component = (
        <ScrollProvider>
          <Properties />
        </ScrollProvider>
      )
      testDataId = 'editor-property-panel'
    }
  } else if (isVersioningState && !isVersionHistoryPanelHidden) {
    component = <FileVersionHistoryPanel fileId={fileId} projectId={projectId} />
    testDataId = 'version-history-panel'
  } else if (isInspectingState && !isRightPanelHidden) {
    if (activeTool === ToolType.COMMENT) {
      component = <ClientSideSuspense fallback={null}>{() => <CommentPanel />}</ClientSideSuspense>
      testDataId = 'comment-panel'
    } else {
      component = (
        <InspectProvider>
          <InspectPanel />
        </InspectProvider>
      )
      testDataId = 'inspect-panel'
    }
  }

  if (!component) return null

  return (
    <RightPanelProviderProvider>
      <div
        className=" bg-neutral-90 border-l border-neutral-80 overflow-hidden z-0 flex flex-col inset-0 w-[248px] box-content"
        style={{ gridArea: LAYOUT.RIGHT_PANEL }}
        data-test-id={testDataId}
      >
        {component}
      </div>
    </RightPanelProviderProvider>
  )
}

FileLayout.RightPanel = memo(RightPanel)
