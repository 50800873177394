import { schema } from 'normalizr'
import { createProvider, useEntity } from '../utils'
export const Image = new schema.Entity(
  'images',
  {},
  {
    processStrategy: (value, parent) => {
      return { ...value, paintId: parent.id }
    }
  }
)

const defaultValue = {}
const [Provider, useSelectState, useSetState] = createProvider('Image', defaultValue)

export const useImage = useSelectState

export const useSetImage = () => {
  const setState = useSetState()
  const [setImage, addImage, updateImage, removeImage, mergeImage, reduceImage, createImage, destroyImage] = useEntity(
    Image,
    setState
  )

  return {
    setImage,
    addImage,
    updateImage,
    removeImage,
    mergeImage,
    reduceImage,
    createImage,
    destroyImage
  }
}

export default Provider
