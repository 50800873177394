import { Transform2D, Vector2 } from "../math"
import { dino } from "../dino"

/** @typedef {import('../gfx/index').Gfx_Image_t} Gfx_Image_t */
/** @typedef {import('../visual_server/VisualStorage').VisualStorage} VisualStorage */
/** @typedef {import('../Viewport').Viewport} Viewport */

/** the extra space for covering boundary clipping area */
const VIEW_MARGIN = 150

/** store current viewport rect size for clipping */
const viewRectSize = new Vector2()
/** store clipping viewport boundary vertices */
const viewportVertices = [
    new Vector2(-VIEW_MARGIN, -VIEW_MARGIN),
    new Vector2(viewRectSize.x + VIEW_MARGIN, -VIEW_MARGIN),
    new Vector2(viewRectSize.x + VIEW_MARGIN, viewRectSize.y + VIEW_MARGIN),
    new Vector2(-VIEW_MARGIN, viewRectSize.y + VIEW_MARGIN),
    new Vector2(-VIEW_MARGIN, -VIEW_MARGIN)
]

export function colorLerp(src_1, src_2, alpha) {
    const r_1 = (src_1 >> 16) & 0xFF
    const g_1 = (src_1 >> 8) & 0xFF
    const b_1 = src_1 & 0xFF
    const r_2 = (src_2 >> 16) & 0xFF
    const g_2 = (src_2 >> 8) & 0xFF
    const b_2 = src_2 & 0xFF
    const newR = Math.round(r_1 * alpha + r_2 * (1 - alpha))
    const newG = Math.round(g_1 * alpha + g_2 * (1 - alpha))
    const newB = Math.round(b_1 * alpha + b_2 * (1 - alpha))
    return (newR << 16) | (newG << 8) | (newB)
}

const HorAlign = {
    "left": 1,
    "center": 2,
    "right": 3
}
const VerAlign = {
    "top": 1,
    "center": 2,
    "bottom": 3
}

export class Pane {
    /**
     * @param {Viewport} viewport
     */
    constructor(viewport) {
        this.index = 0

        this.viewport = viewport

        this.transform = new Transform2D()
        this.globalAlpha = 1.0

        this.fill = {
            color: 0xFFFFFF,
            alpha: 1.0,
        }
        this.stroke = {
            width: 1.0,
            color: 0xFFFFFF,
            alpha: 1.0,
        }

        this.resetTransform()
    }

    reset() {
        this.transform.identity()

        this.globalAlpha = 1.0

        this.fill.color = 0xFFFFFF
        this.fill.alpha = 1.0

        this.stroke.width = 1.0
        this.stroke.color = 0xFFFFFF
        this.stroke.alpha = 1.0

        this.clear()

        this.resetTransform()

        return true
    }

    clear() {
        updateViewportData(this.viewport)
        this.resetTransform()
        return this
    }

    /**
     * @param {number} alpha
     * @returns {this}
     */
    setAlpha(alpha) {
        this.globalAlpha = alpha
        return this
    }

    /**
     * @returns {this}
     */
    resetTransform() {
        this.transform.identity()
        // .scale(this.viewport.pixelRatio, this.viewport.pixelRatio)
        dino().setTransform(this.transform.a, this.transform.b, this.transform.c, this.transform.d, this.transform.tx, this.transform.ty)
        return this
    }

    /**
     * @param {Transform2D} transform
     * @returns {this}
     */
    appendTansform(transform) {
        this.transform.append(transform)
        dino().setTransform(this.transform.a, this.transform.b, this.transform.c, this.transform.d, this.transform.tx, this.transform.ty)
        return this
    }

    /**
     * @param {number} color
     * @param {number} [alpha]
     * @returns {this}
     */
    fillStyle(color, alpha = 1) {
        this.fill.color = color
        this.fill.alpha = alpha
        const r = ((color >> 16) & 0xFF) / 255
        const g = ((color >> 8) & 0xFF) / 255
        const b = (color & 0xFF) / 255
        dino().fillStyle(r, g, b, alpha)
        return this
    }

    /**
     * @param {number} width
     * @param {number} color
     * @param {number} [alpha]
     * @returns {this}
     */
    lineStyle(width, color, alpha = 1) {
        this.stroke.width = width * this.viewport.pixelRatio
        this.stroke.color = color
        this.stroke.alpha = alpha
        const r = ((color >> 16) & 0xFF) / 255
        const g = ((color >> 8) & 0xFF) / 255
        const b = (color & 0xFF) / 255
        dino().strokeStyle(width, r, g, b, alpha)
        return this
    }

    /**
     * @param {number} x0
     * @param {number} y0
     * @param {number} x1
     * @param {number} y1
     * @returns {this}
     */
    drawLine(x0, y0, x1, y1) {
        dino().setTransform(this.transform.a, this.transform.b, this.transform.c, this.transform.d, this.transform.tx, this.transform.ty)

        dino().drawLine(this.layer_id, x0, y0, x1, y1)
        return this
    }

    drawLineShadow(x0, y0, x1, y1) {
        dino().setTransform(this.transform.a, this.transform.b, this.transform.c, this.transform.d, this.transform.tx, this.transform.ty)

        dino().drawLineShadow(this.layer_id, x0, y0, x1, y1)
        return this
    }

    /**
     * @param {number} x
     * @param {number} y
     * @param {number} width
     * @param {number} height
     * @param {number} [cornerRadius]
     * @param {Vector2} elementScale
     * @returns {this}
     */
    drawRect(x, y, width, height, cornerRadius = 0) {
        dino().setTransform(this.transform.a, this.transform.b, this.transform.c, this.transform.d, this.transform.tx, this.transform.ty)

        if (cornerRadius === 0) {
            dino().drawRect(this.layer_id, x, y, width, height)
        } else {
            dino().drawRoundedRect(this.layer_id, x, y, width, height, cornerRadius)
        }
        return this
    }

    /**
     * @param {number} x
     * @param {number} y
     * @param {number} width
     * @param {number} height
     * @param {number} [cornerRadius]
     * @returns {this}
     */
    drawSolidRect(x, y, width, height, cornerRadius = 0) {
        dino().setTransform(this.transform.a, this.transform.b, this.transform.c, this.transform.d, this.transform.tx, this.transform.ty)

        if (cornerRadius === 0) {
            dino().drawSolidRect(this.layer_id, x, y, width, height)
        } else {
            dino().drawSolidRoundedRect(this.layer_id, x, y, width, height, cornerRadius)
        }
        return this
    }

    /**
     * @param {number} x
     * @param {number} y
     * @param {number} width
     * @param {number} [height=width]
     * @param {number} rot
     * @returns {this}
     */
    drawEllipse(x, y, width, height = width, rot = 0) {
        dino().setTransform(this.transform.a, this.transform.b, this.transform.c, this.transform.d, this.transform.tx, this.transform.ty)
        if (width === height) {
            dino().drawCircle(this.layer_id, x, y, width)
        } else {
            dino().drawEllipse(this.layer_id, x, y, width, height, rot)
        }
        return this
    }

    /**
     * @param {number} x
     * @param {number} y
     * @param {number} width
     * @param {number} [height=width]
     * @param {number} rot
     * @returns {this}
     */
    drawEllipseShadow(x, y, width, height = width, rot = 0) {
        dino().setTransform(this.transform.a, this.transform.b, this.transform.c, this.transform.d, this.transform.tx, this.transform.ty)
        dino().drawEllipseShadow(this.layer_id, x, y, width,  height, rot)
        return this
    }

    /**
     * @param {number} x
     * @param {number} y
     * @param {number} width
     * @param {number} [height=width]
     * @param {boolean} [center=true]
     * @param {boolean} [isSizeInScreenSpace=false]
     * @returns {this}
     */
    drawSolidEllipse(x, y, width, height = width) {
        dino().setTransform(this.transform.a, this.transform.b, this.transform.c, this.transform.d, this.transform.tx, this.transform.ty)
        dino().drawSolidEllipse(this.layer_id, x, y, this.transform.a * width * 0.5, this.transform.d * height * 0.5, 0)
        return this
    }

    /**
     * Subpath with same first and last point will be considered as "closed",
     * so it won't have line caps
     * @param {number} x
     * @param {number} y
     * @param {PathData} path
     * @returns {this}
     */
    drawPath(x, y, path) {
        dino().setTransform(this.transform.a, this.transform.b, this.transform.c, this.transform.d, this.transform.tx, this.transform.ty)

        dino().drawPath(this.layer_id, x, y, path)
        return this
    }

    /**
     * @param {string} text
     * @param {0|1|2|3|4} font 0: default(18), 1: small(14)  2: large(22) 3: icon(18) 4: ruler(12)
     * @returns
     */
    measureText(text, font = 0) {
        dino().setTransform(this.transform.a, this.transform.b, this.transform.c, this.transform.d, this.transform.tx, this.transform.ty)

        const { w, h } = dino().measureText(text, font)
        return [w / _viewport.pixelRatio, h / _viewport.pixelRatio]
    }
    /**
     * @param {number} x
     * @param {number} y
     * @param {string} text
     * @param {0|1|2|3|4} font 0: default(18), 1: small(14)  2: large(22) 3: icon(18) 4: ruler(12)
     * @param {"left"|"center"|"right"} align
     * @param {"top"|"center"|"bottom"} valign
     * @returns {this}
     */
    drawText(x, y, text, font = 0, h_align = "left", v_align = "top") {
        dino().setTransform(this.transform.a, this.transform.b, this.transform.c, this.transform.d, this.transform.tx, this.transform.ty)

        const { rotation } = this.transform.decompose()
        dino().drawText(this.layer_id, x, y, text, HorAlign[h_align] || 2, VerAlign[v_align] || 2, font, -rotation * 180 / Math.PI)
        return this
    }

    /**
     * @param {number} id
     * @param {number} dx
     * @param {number} dy
     * @param {number} dw
     * @param {number} dh
     * @param {boolean} [flipY=false]
     * @returns {this}
     */
    drawImage(id, dx, dy, dw, dh) {
        dino().setTransform(this.transform.a, this.transform.b, this.transform.c, this.transform.d, this.transform.tx, this.transform.ty)

        dino().drawImage(this.layer_id, id, dx, dy, dw, dh)
        return this
    }

    /**
     * @param {string} imageSrc
     * @param {number} dx display posX
     * @param {number} dy display posY
     * @param {number} ratioX image size ratio
     * @param {number} ratioY image size ratio
     * @param {bool} centered location of anchor point
     * @param centeredX
     * @param centeredY
     * @returns {this}
     */
    drawImageFromAtlas(imageSrc, dx, dy, ratioX, ratioY, centeredX = true, centeredY = true) {
        const imageAtlas = loadImage(imageSrc)
        if (!imageAtlas || !imageAtlas.valid) {
            console.log(`image [${imageSrc}] not loaded into atlas yet`)
            return this
        }
        const imageInfo = imageAtlas.imageInfo
        let x = dx - imageInfo.w * 0.5 * ratioX / imageInfo.pixelRatio
        let y = dy - imageInfo.h * 0.5 * ratioY / imageInfo.pixelRatio
        const w = imageInfo.w * ratioX / imageInfo.pixelRatio
        const h = imageInfo.h * ratioY / imageInfo.pixelRatio
        if (!centeredX) {
            x = dx
        }
        if (!centeredY) {
            y = dy
        }
        this.drawImage(imageAtlas.image, x, y, w, h)
        return this
    }

    drawSolidRectTexture(x, y, width, height, cornerRadius = 0) {
        dino().setTransform(this.transform.a, this.transform.b, this.transform.c, this.transform.d, this.transform.tx, this.transform.ty)

        // TODO: Can merge by drawSolidRect?
        dino().drawSolidRoundedRect(this.layer_id, x, y, width, height, cornerRadius)
        return this
    }
    drawRectTexture(x, y, width, height, lineWidth, cornerRadius = 0) {
        dino().setTransform(this.transform.a, this.transform.b, this.transform.c, this.transform.d, this.transform.tx, this.transform.ty)

        // TODO: Can merge by drawRect?
        dino().drawRoundedRect(this.layer_id, x, y, width, height, cornerRadius)
        return this
    }
}

export class Overlay {
    /**
     * @param {Gfx} gfx
     * @param {VisualStorage} storage
     * @param {Viewport} viewport
     */
    constructor(gfx, storage, viewport) {
        this.gfx = gfx
        this.storage = storage
        this.viewport = viewport
        _viewport = viewport

        /** @type {Pane[]} */
        this.panes = []

        this.viewport.on('resize', () => {
            // this.setSize(this.viewport.realWidth, this.viewport.realHeight)
        })
    }

    /**
     * @param {number} index
     * @returns {Pane}
     */
    createPane(index) {
        const pane = new Pane(this.viewport)
        pane.layer_id = index
        this.panes[index] = pane
        return pane
    }

    /**
     * @param {number} index
     */
    destroyPane(index) {
        const pane = this.panes[index]
        if (!pane) return

        this.panes[index] = null
    }

    /**
     * @param {number} w
     * @param {number} h
     */
    setSize(w, h) {
        // viewport transform
        xform.identity()
            .scale(2 / w, -2 / h)
            .translate(-1, 1)
    }

    /**
     * Clear all exising panes
     */
    clearPanes() {
        for (const pane of this.panes) {
            pane.clear()
        }
    }

    /**
     * Clear the whole overlay, all panes will be freed
     */
    clear() {
        this.panes.length = 0
    }
}

/* implementation */

const xform = new Transform2D()

// path rendering

/**
 * update clipping viewport data
 * @param {Viewport} viewport
 */
function updateViewportData(viewport) {
    viewRectSize.set(
        viewport.width * viewport.pixelRatio,
        viewport.height * viewport.pixelRatio
    )
    const margin = VIEW_MARGIN * viewport.pixelRatio * Math.max(1, viewport.scale)

    viewportVertices[0].set(-margin, -margin)
    viewportVertices[1].set(viewRectSize.x + margin, -margin)
    viewportVertices[2].set(viewRectSize.x + margin, viewRectSize.y + margin)
    viewportVertices[3].set(-margin, viewRectSize.y + margin)
    viewportVertices[4].set(-margin, -margin)
}

const canvas = document.createElement('canvas')
let _viewport = null
const ctx = canvas.getContext('2d', {
    willReadFrequently: true,
})
/**
 * @typedef {object} ImageInfo
 * @property {number} x
 * @property {number} y
 * @property {number} w
 * @property {number} h
 * @property {number} pixelRatio
 */
/**
 * @typedef {object} ImageAtlas
 * @property {number} image image id
 * @property {boolean} valid
 * @property {ImageInfo} imageInfo
 */
/** @type {Map<string, ImageAtlas>}  */
const imageAtlases = new Map()
/**
 * @param {string} src image src
 */
export const loadImage = (src) => {
    if (imageAtlases.has(src)) {
        return imageAtlases.get(src)
    }

    /** @type {ImageAtlas} */
    const imageAtlas = {
        image: null,
        valid: false,
        imageInfo: {
            x: 0, y: 0,
            w: 0, h: 0,
            pixelRatio: _viewport.pixelRatio * 5
        },
    }
    imageAtlases.set(src, imageAtlas)

    const image = new Image()
    image.src = src
    image.onload = () => {
        ctx.clearRect(0, 0, canvas.width, canvas.height)

        const pixelRatio = imageAtlas.imageInfo.pixelRatio
        canvas.width = Math.max(image.width * pixelRatio, 1)
        canvas.height = Math.max(image.height * pixelRatio, 1)

        ctx.scale(pixelRatio, pixelRatio)
        ctx.drawImage(image, 0, 0)

        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
        const pixels = imageData.data

        const id = dino().uploadImage(pixels, canvas.width, canvas.height)

        imageAtlas.image = id
        imageAtlas.valid = true
        imageAtlas.imageInfo.x = 0
        imageAtlas.imageInfo.y = 0
        imageAtlas.imageInfo.w = canvas.width
        imageAtlas.imageInfo.h = canvas.height
        imageAtlas.imageInfo.pixelRatio = pixelRatio

        imageAtlases.set(src, imageAtlas)
    }

    return imageAtlas
}

/** @typedef {Map<string, boolean>} fontBank */
const fontBank = new Map()
const defaultFontFamily = 'Arial'
/**
 * @param {string} char one character
 * @param {string} fontFamily
 * @param {number} fontsize unit px
 * @param fontSize
 * @param {"normal"|"bold"|"100|200|300|400|500|600|700|800|900} [fontWeight] default normal; normal(=400), bold(=700)
 * @param {"normal"|"italic"|"oblique"} [fontStyle] default normal
 */
export const loadChar = (char, fontFamily, fontSize, fontWeight = 400, fontStyle = 'normal') => {
    const bandKey = `${fontFamily}`

    let atlasKey = `${defaultFontFamily}-${fontWeight}-${fontStyle}-${fontSize}-${char}`
    let font = `${fontWeight} ${fontSize}px ${defaultFontFamily}`
    if (fontBank.has(bandKey)) {
        const loaded = fontBank.get(bandKey)

        if (loaded) {
            atlasKey = `${fontFamily}-${fontWeight}-${fontStyle}-${fontSize}-${char}`
            if (imageAtlases.has(atlasKey)) return imageAtlases.get(atlasKey)

            font = `${fontWeight} ${fontSize}px ${fontFamily}`
        }
    } else {
        fontBank.set(bandKey, false)
        // new FontFaceObserver(fontFamily)
        //     .load()
        //     .then(() => {
        //         fontBank.set(bandKey, true)
        //     }).catch(() => {
        //         console.log('%c Font %s can not be loaded correctly', 'background: red; color: white', bandKey)
        //     })
    }
    if (imageAtlases.has(atlasKey)) return imageAtlases.get(atlasKey)

    /** @type {ImageAtlas} */
    const imageAtlas = {
        image: null,
        valid: false,
        imageInfo: {
            x: 0, y: 0,
            w: 0, h: 0,
            pixelRatio: _viewport.pixelRatio * 5
        },
    }
    imageAtlases.set(atlasKey, imageAtlas)

    const pixelRatio = imageAtlas.imageInfo.pixelRatio
    ctx.font = font
    const text = ctx.measureText(`${char}`)
    canvas.width = Math.max((text.width) * pixelRatio, 1)
    canvas.height = Math.max((text.fontBoundingBoxAscent + text.fontBoundingBoxDescent) * pixelRatio, 1)
    ctx.clearRect(0, 0, canvas.width, canvas.height)

    ctx.font = font
    ctx.textBaseline = 'top'
    ctx.fillStyle = '#ffffff'
    ctx.globalAlpha = 1
    ctx.scale(pixelRatio, pixelRatio)
    ctx.fillText(char, 0, 0)

    // const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
    // const pixels = imageData.data

    // const ptr = allocTempUint8Array(pixels)
    // if (ptr) {
    //     const id = WASM().makeBrushImage(ptr, pixels.length, canvas.width, canvas.height)
    //     imageAtlas.image = id
    //     imageAtlas.valid = true
    // }
    imageAtlas.imageInfo.x = 0
    imageAtlas.imageInfo.y = 0
    imageAtlas.imageInfo.w = canvas.width
    imageAtlas.imageInfo.h = canvas.height

    return imageAtlas
}
