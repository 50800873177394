import { TutorialType } from '../constant'
import tip25 from '../assets/2.5.gif'
import tip23 from '../assets/2.3.gif'

export default {
  key: TutorialType.KEYFRAME,
  mask: false,
  progress: true,
  interact: false,
  steps: [
    {
      header: 'Keyframe',
      content: 'Keyframe indicates your setting at a specific time. You can edit its easing to make animation vivid.',
      anchor: '.js-kf',
      placement: 'top',
      image: tip25
      // confetti: true
    },
    {
      header: 'Action List',
      content: 'Here list all animated elements.',
      anchor: '.pt-action-list',
      placement: 'top',
      image: tip23
    }
  ]
}
