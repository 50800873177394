import {
    EntityType,
    ElementType,
    EventFlag
} from '@phase-software/types'
import { Change, EntityChange, setAdd, NOT_UNDOABLE, NO_COMMIT } from '@phase-software/data-utils'
import { Element } from './Element'


/** @typedef {import('./DataStore').DataStore} DataStore */

/**
 * @typedef {object} GroupChanges
 * @property {Element[]} children       list of affected elements (in order of addition or removal)
 * @property {number} index             singe index at which `children` were added or removed
 * @property {number[]} indices         list of indices indicating at which index was each child from `children` was removed
 */

/** @typedef {('ADD-CHILDREN' | 'REMOVE-CHILDREN')} GroupChangesEvent */

const UNDO_EVENTS = ['ADD-CHILDREN', 'REMOVE-CHILDREN']

const ADD_CHILDREN_OPTIONS = { fire: true, commit: true }
const REMOVE_CHILDREN_OPTIONS = ADD_CHILDREN_OPTIONS

const RECALCULATE_WITH_CHILD_RELATED_CHANGES = [
    'visible',
    'position',
    'x',
    'y',
    'translate',
    'translateX',
    'translateY',
    'size',
    'width',
    'height',
    'rotation',
    'scale',
    'scaleX',
    'scaleY',
    'skew',
    'skewX',
    'skewY'
]

// TODO: [Optimizable] Fill layer ("fills") only affect the boolean bounds by its presence or not,
// not really need to recalculate bounds every time every fill layer content changes.
const AffectBoundsLayerKeys = ['strokes', 'fills']
const IS_LAYER_AFFECT_BOUNDS = true

/**
 * Parent class for Workspace, Screen, ScrollGroup, GeometryGroup and DataStore that adds children to Setter
 * @fires 'ADD-CHILDREN'
 * @fires 'REMOVE-CHILDREN'
 */
export class Group extends Element {
    /**
     * @param {DataStore} dataStore
     * @param {GroupData} [data]
     * @param {object} overrides
     */
    constructor(dataStore, data, overrides) {
        super(dataStore, data, overrides)
        setAdd(this.undoEvents, UNDO_EVENTS)

        this.on('CHANGES', this.handleChanges)

        this.canSyncWithChildren = false
        this._handleChildChangesFn = this._handleChildChanges.bind(this)
        this._layerComponentEventBindings = {}
        this._effectComponentEventBinding = null
        this._layerEventBindings = {}
        this._effectEventBindings = new Map()
        this._previousMode = this.dataStore.get('mode')
        AffectBoundsLayerKeys.forEach((layerListName) => {
            this._layerEventBindings[layerListName] = {}
        })
    }

    connectWithChildren() {
        this.canSyncWithChildren = true
        this.children.forEach((child) => {
            this._bindChildChangesEvent(child)
        })
    }

    disconnectWithChildren() {
        this.children.forEach((child) => {
            this._unbindChildChangesEvent(child)
        })
        this.canSyncWithChildren = false
    }

    clear() {
        super.clear()
        this.children.forEach(child => {
            child.clear()
        })
    }

    /**
     * creates a blank group
     * @protected
     */
    create() {
        super.create()
        this.data.elementType = ElementType.GROUP

        this.children = []
    }

    /**
     * populates data from a Group
     * @param {GroupData} data
     */
    load(data) {
        super.load(data)

        this.children = []
        if (data.children) {
            for (const childData of data.children) {
                const child = this.dataStore.createElement(childData.elementType, childData)
                child.set('parent', this)
                this.children.push(child)
            }
        }
    }

    /**
     * @param {object} [overrides] data object with overrides
     * @returns {Group}
     */
    clone(overrides) {
        const obj = super.clone(overrides)

        obj.children = []
        for (const child of this.children) {
            const newChild = child.clone()
            newChild.set('parent', obj)
            obj.children.push(newChild)
        }
        return obj
    }

    /**
     * saves data for a Group
     * @param {bool} [copy]
     * @returns {GroupData}
     */
    save(copy) {
        const data = super.save()
        data.children = []
        for (const child of this.children) {
            data.children.push(child.save(copy))
        }
        return data
    }

    /** @returns {number} */
    get count() {
        return this.children.length
    }

    _handleModeChange(newMode) {
        super._handleModeChange()

        if (this.isComputedGroup) {
            // Recalculate bounds
            this.recalculateBounds()
        }
        this._previousMode = newMode
    }

    /**
     * Bind child data changes event
     * @param {Element} child
     */
    _bindChildChangesEvent(child) {
        if (!this.canSyncWithChildren || child.isScreen) {
            return
        }

        // Bind computedStyle changes
        child.on('CHANGES', this._handleChildChangesFn)

        if (IS_LAYER_AFFECT_BOUNDS && (this.isBooleanType() || this.isMaskGroup())) {
            AffectBoundsLayerKeys.forEach((layerListName) => {
                // Bind layerComponent children changes
                const layerComponentHandler = this._handleLayerComponentChange.bind(this, child, layerListName)
                this._layerComponentEventBindings[layerListName] = layerComponentHandler
                this.dataStore.library.getComponent(child.base[layerListName][0]).on('CHANGES', layerComponentHandler)

                // Bind computedLayer changes
                const computedLayers = [...child.computedStyle[layerListName]]
                computedLayers.forEach((computedLayer) => {
                    const computedLayerHandler = this._handleComputedLayerOrEffectChanges.bind(this)
                    this._layerEventBindings[layerListName][computedLayer.get('id')] = computedLayerHandler
                    computedLayer.on('CHANGES', computedLayerHandler)
                })
            })

            const effectComponentHandler = this._handleEffectComponentChange.bind(this, child)
            this._effectComponentEventBinding = effectComponentHandler
            this.dataStore.library.getComponent(child.base.effects[0]).on('CHANGES', effectComponentHandler)

            // Bind computedEffect changes
            const computedEffects = [...child.computedStyle.effects]
            computedEffects.forEach((computedEffect) => {
                const computedEffectHandler = this._handleComputedLayerOrEffectChanges.bind(this)
                this._effectEventBindings.set(computedEffect.get('id'), computedEffectHandler)
                computedEffect.on('CHANGES', computedEffectHandler)
            })
        }
    }

    /**
     * Unbind child data changes event
     * @param {Element} child
     */
    _unbindChildChangesEvent(child) {
        if (!this.canSyncWithChildren || child.isScreen) {
            return
        }

        // Unind computedStyle changes
        child.off('CHANGES', this._handleChildChangesFn)

        if (IS_LAYER_AFFECT_BOUNDS) {
            AffectBoundsLayerKeys.forEach((layerListName) => {
                // Unbind layerComponent children changes
                const layerComponent = this.dataStore.library.getComponent(child.base[layerListName][0])
                const layerComponentHandler = this._layerComponentEventBindings[layerListName]
                if (!layerComponent || !layerComponentHandler) {
                    return
                }
                layerComponent.off('CHANGES', layerComponentHandler)

                // Unbind computedLayer changes
                const computedLayers = [...child.computedStyle[layerListName]]
                computedLayers.forEach((computedLayer) => {
                    const computedLayerHandler = this._layerEventBindings[layerListName][computedLayer.get('id')]
                    computedLayer.off('CHANGES', computedLayerHandler)
                })
            })

            if (this._effectComponentEventBinding) {
                const effectComponent = this.dataStore.library.getComponent(child.base.effects[0])
                if (!effectComponent) {
                    return
                }
                effectComponent.off('CHANGES', this._effectComponentEventBinding)

                const computedEffects = [...child.computedStyle.effects]
                computedEffects.forEach((computedEffect) => {
                    const computedEffectHandler = this._effectEventBindings.get(computedEffect.get('id'))
                    if (computedEffectHandler) {
                        computedEffect.off('CHANGES', computedEffectHandler)
                    }
                })
            }
        }
    }

    /**
     * Handle layer component change
     * @param {Element} child
     * @param {string} layerListName
     * @param {Change} changes
     */
    _handleLayerComponentChange(child, layerListName, changes) {
        const layersChange = changes.get('layers')
        if (!layersChange) {
            return
        }

        const beforeLength = layersChange.before.length
        const afterLength = layersChange.after.length
        if (beforeLength > afterLength) {
            // Has remove layer
            const removedLayerIds = layersChange.before.filter((oldLayerId) => !layersChange.after.includes(oldLayerId))
            removedLayerIds.forEach((removedLayerId) => {
                const computedLayer = child.computedStyle.getComputedLayer(layerListName, removedLayerId)
                if (!computedLayer) {
                    return
                }
                const computedLayerHandler = this._layerEventBindings[layerListName][computedLayer.get('id')]
                computedLayer.off('CHANGES', computedLayerHandler)
            })
        } else if (beforeLength < afterLength) {
            // Has add layer
            // ensure the computedStyle library components event handler is bind through _bindBaseAndComponentChangeListeners
            child.computedStyle.reload()
            const addedLayerIds = layersChange.after.filter((newLayerId) => !layersChange.before.includes(newLayerId))
            addedLayerIds.forEach((addedLayerId) => {
                const computedLayer = child.computedStyle.getComputedLayer(layerListName, addedLayerId)
                const computedLayerHandler = this._handleComputedLayerOrEffectChanges.bind(this)
                this._layerEventBindings[layerListName][computedLayer.get('id')] = computedLayerHandler
                computedLayer.on('CHANGES', computedLayerHandler)
            })
        }

        if (beforeLength !== afterLength) {
            this.recalculateBounds()
        }
    }

    /**
     * Handle effect component change
     * @param {Element} child
     * @param {object} changes
     */
    _handleEffectComponentChange(child, changes) {
        const effectsChange = changes.get('effects')
        if (!effectsChange) {
            return
        }

        const beforeLength = effectsChange.before.length
        const afterLength = effectsChange.after.length
        if (beforeLength > afterLength) {
            // Has remove effect
            const removedEffectIds = effectsChange.before.filter((oldEffectId) => !effectsChange.after.includes(oldEffectId))
            removedEffectIds.forEach((removedEffectId) => {
                const computedEffect = child.computedStyle.getComputedEffectByComponentId(removedEffectId)
                if (!computedEffect) {
                    return
                }
                const computedEffectHandler = this._effectEventBindings.get(computedEffect.get('id'))
                computedEffect.off('CHANGES', computedEffectHandler)
            })
        } else if (beforeLength < afterLength) {
            // Has add effect
            // ensure the computedStyle library components event handler is bind through _bindBaseAndComponentChangeListeners
            child.computedStyle.reload()
            const addedEffectIds = effectsChange.after.filter((newEffectId) => !effectsChange.before.includes(newEffectId))
            addedEffectIds.forEach((addedEffectId) => {
                const computedEffect = child.computedStyle.getComputedEffectByComponentId(addedEffectId)
                const computedLayerHandler = this._handleComputedLayerOrEffectChanges.bind(this)
                this._effectEventBindings.set(computedEffect.get('id'), computedLayerHandler)
                computedEffect.on('CHANGES', computedLayerHandler)
            })
        }

        if (beforeLength !== afterLength) {
            this.recalculateBounds()
        }
    }

    /**
     * Handle child data changes
     * @param {object} changes
     * @param {object} options
     */
    _handleChildChanges(changes, options) {
        const { inUndo, inRedo } = this.dataStore.get('undo')
        if (inUndo || inRedo) {
            return
        }

        if (!this.canSyncWithChildren) {
            return
        }

        if (options.flags && options.flags !== EventFlag.FROM_CHILDREN_CHANGE && options.flags !== EventFlag.FROM_MESH_CHANGE) {
            return
        }

        const changeKeys = new Set(changes.keys())
        if (!RECALCULATE_WITH_CHILD_RELATED_CHANGES.some((key) => changeKeys.has(key))) return

        this.recalculateBounds()
    }

    /**
     * Handle data changes
     * @param {object} changes
     * @param {object} options
     */
    handleChanges(changes, options) {
        const { inUndo, inRedo } = this.dataStore.get('undo')
        if (inUndo || inRedo) {
            return
        }

        // TODO: Get position and size changes and apply to children
        if ((options.flags && options.flags !== EventFlag.FROM_PARENT_CHANGE) || this.isScreen || this.isWorkspace) {
            return
        }

        this.updateChildrenSizeWith(changes)
    }

    /**
     * Handle computed layer or effect data changes
     * @param {object} changes
     * @param {object} options
     */
    _handleComputedLayerOrEffectChanges(changes, options) {
        const { inUndo, inRedo } = this.dataStore.get('undo')
        if (inUndo || inRedo) {
            return
        }

        if (!this.canSyncWithChildren || (options.flags && options.flags !== EventFlag.FROM_CHILDREN_CHANGE)) {
            return
        }

        this.recalculateBounds()
    }

    /**
     * Update children size with parent size changes
     */
    updateChildrenSizeWith() {
        // Abstract function
    }

    recalculateBounds() {
        // Abstract function
    }

    /**
     * Adds children to the end of Group
     * @param {Element[]} children
     * @param {object} [options]
     * @param {boolean} [options.fire=true]      true if this method should fire any events; set to false to not fire
     * @param {boolean} [options.commit=true]    true if this method should commit all events it fired; set to false to not commit automatically
     * @param {EntityChange} [changes]
     * @returns {false | Element[]} elements that were successfully added
     */
    addChildren(children, options, changes) {
        return this.addChildrenAt(children, this.children.length, options, changes)
    }

    /**
     * Adds a child at specified index in the Group
     * @param {Element[]} children
     * @param {number} index
     * @param {object} [options]
     * @param {boolean} [options.fire=true]      true if this method should fire any events; set to false to not fire
     * @param {boolean} [options.commit=true]    true if this method should commit all events it fired; set to false to not commit automatically
     * @param {EntityChange} [changes]
     * @returns {false | Element[]}                  elements that were successfully added
     */
    addChildrenAt(
        children,
        index,
        { fire = true, commit = true } = ADD_CHILDREN_OPTIONS,
        changes = new EntityChange()
    ) {
        if (!children || children.length === 0 || index < 0 || index > this.children.length) {
            return false
        }
        const selfBefore = this.children.map(child => child.get('id'))

        // modify scene tree
        const oldParents = new Set()
        const allowedChildren = []
        for (const child of children) {
            // check if this Group is allowed to parent this child
            if (!this.dataStore.canBeChildOf(child, this)) {
                continue
            }
            allowedChildren.push(child)

            const parent = child.get('parent')
            // remove from parent if has one
            if (parent) {
                oldParents.add(parent)
            }
            child.set('parent', this, NOT_UNDOABLE)
            this._bindChildChangesEvent(child)
        }
        let realIndex = index
        for (const parent of oldParents) {
            const parentBefore = parent.children.map(child => child.get('id'))
            const removed = parent.removeChildren(allowedChildren, NO_COMMIT, changes, this)
            // adjust index if we removed from this Group
            if (parent === this) {
                // Only adjust the index when removed element is before the target index
                const adjustment = removed.filter(r => parentBefore.findIndex(c => c === r.get('id')) < index)
                realIndex -= adjustment.length
            }
        }
        this.children.splice(realIndex, 0, ...allowedChildren)

        // update self changes
        changes.update(getContainer(this).get('id'), 'children', new Change({
            before: selfBefore,
            after: this.children.map(child => child.get('id'))
        }))

        if (fire) {
            this.fire('ADD-CHILDREN', { children: allowedChildren, index: realIndex }, NOT_UNDOABLE)
            if (commit && this.dataStore) {
                this.dataStore.commitUndo()
            }
        }

        return allowedChildren
    }

    /**
     * Removed successive number of children at specified index
     * @param {number} index             index at which to start removing elements
     * @param {number} [howMany=1]     by default removes 1 element
     * @param {object} [options]
     * @param {boolean} [options.fire=true]      true if this method should fire any events; set to false to not fire
     * @param {boolean} [options.commit=true]    true if this method should commit all events it fired; set to false to not commit automatically
     * @param {EntityChange} [changes]
     * @param {Group} [newParent=null]          if defined will set parent of removed element to be `newParent`
     * @returns {Element[]}              list of Elements that were removed
     */
    removeChildrenAt(
        index,
        howMany = 1,
        { fire = true, commit = true } = REMOVE_CHILDREN_OPTIONS,
        changes = new EntityChange(),
        newParent = null
    ) {
        if (index < 0 || index >= this.children.length || howMany <= 0) {
            return []
        }
        const before = this.children.map(child => child.get('id'))
        const removed = this.children.splice(index, howMany)

        changes.delete(removed.map(el => el.get('id')))
        for (const child of removed) {
            child.set('parent', newParent, NOT_UNDOABLE)
            this._unbindChildChangesEvent(child)
        }

        if (removed.length > 0) {
            changes.update(getContainer(this).get('id'), 'children', new Change({
                before,
                after: this.children.map(child => child.get('id'))
            }))

            if (fire) {
                this.fire('REMOVE-CHILDREN', { children: removed, index }, NOT_UNDOABLE)
                if (commit && this.dataStore) {
                    this.dataStore.commitUndo()
                }
            }
        }

        return removed
    }

    /**
     * Removes children from the Group
     * @param {Element[]} children
     * @param {object} [options]
     * @param {boolean} [options.fire=true]      true if this method should fire any events; set to false to not fire
     * @param {boolean} [options.commit=true]    true if this method should commit all events it fired; set to false to not commit automatically
     * @param {EntityChange} [changes]
     * @param {Group} [newParent=null]   if defined will set parent of removed element to be `newParent`
     * @returns {Element[]}              list of Elements that were removed
     */
    removeChildren(
        children,
        { fire = true, commit = true } = REMOVE_CHILDREN_OPTIONS,
        changes = new EntityChange(),
        newParent = null
    ) {
        const removed = []
        const removedIndices = []
        if (!children || children.length === 0) {
            return removed
        }
        const before = this.children.map(child => child.get('id'))
        changes.delete(before)
        const toRemove = new Set(children)
        for (let i = 0; i < this.children.length; i++) {
            const child = this.children[i]
            if (!toRemove.has(child)) {
                continue
            }
            removed.push(child)
            removedIndices.push(i)
            this.children.splice(i--, 1)
            child.set('parent', newParent, NOT_UNDOABLE)
            this._unbindChildChangesEvent(child)
        }

        if (removed.length > 0) {
            changes.update(getContainer(this).get('id'), 'children', new Change({
                before,
                after: this.children.map(child => child.get('id'))
            }))

            if (fire) {
                this.fire('REMOVE-CHILDREN', { children: removed, indices: removedIndices }, NOT_UNDOABLE)
                if (commit && this.dataStore) {
                    this.dataStore.commitUndo()
                }
            }
        }

        return removed
    }

    /**
     * Checks if Group has a specified child
     * @param {Element} child
     * @returns {boolean}       true if has `child`; false otherwise
     */
    hasChild(child) {
        return this.children.indexOf(child) !== -1
    }

    /**
     * checks if group has this child wrapped in a Watcher
     * @param {Element} child
     * @returns {boolean}
     */
    hasWatchedChild(child) {
        return this.children.some(c => c.watch === child)
    }

    /**
     * get a child by id
     * @param {string} id
     * @returns {Element}
     */
    getChildById(id) {
        for (const child of this.children) {
            if (child.get('id') === id) {
                return child
            }
        }
        throw new Error('getChildById did not find child')
    }

    /**
     * undo GROUP related event with given changes data
     * @param {string} type
     * @param {object} changes
     * @param {Element[]} changes.children
     * @param {number} changes.index
     * @param {number[]} changes.indices
     */
    undo(type, changes) {
        super.undo(type, changes)

        switch (type) {
            case 'ADD-CHILDREN': {
                const { children, index } = changes
                this.removeChildrenAt(index, children.length, NO_COMMIT)
                break
            }
            case 'REMOVE-CHILDREN': {
                const { children, index, indices } = changes
                // was removed by removeChildren()
                if (index === undefined) {
                    for (let i = 0; i < indices.length; i++) {
                        this.addChildrenAt([children[i]], indices[i], NO_COMMIT)
                    }
                }
                // was removed by removeChildrenAt()
                else {
                    this.addChildrenAt(children, index, NO_COMMIT)
                }
                break
            }
            case 'CHANGES': {
                const containerTypeChange = changes.get('containerType')
                if (containerTypeChange) {
                    // Because we need to clear corner radius first before we change container type.
                    // That means we can't get correct corner radius before undo container type change.
                    // So, use timeout here to update corner radius after container type change.
                    setTimeout(() => {
                        this.dataStore.startTransaction()
                        this.set('cornerRadius', this.getBaseProp('cornerRadius').cornerRadius, { commit: false, interaction: false })
                        if (this.dataStore.isActionMode) {
                            this.dataStore.transition.forceUpdateAnimation()
                        }
                        this.dataStore.endTransaction()
                    })
                }
                break
            }
        }
    }

    /**
     * redo GROUP related event with given changes data
     * @param {string} type
     * @param {object} changes
     * @param {Element[]} changes.children
     * @param {number} changes.index
     */
    redo(type, changes) {
        super.redo(type, changes)

        switch (type) {
            case 'ADD-CHILDREN': {
                const { children, index } = changes
                this.addChildrenAt(children, index, NO_COMMIT)
                break
            }
            case 'REMOVE-CHILDREN': {
                const { children, index } = changes
                // was removed by removeChildrenAt()
                if (index) {
                    this.removeChildrenAt(index, children.length, NO_COMMIT)
                }
                // was removed by removeChildren()
                else {
                    this.removeChildren(children, NO_COMMIT)
                }
                break
            }
            case 'CHANGES': {
                const containerTypeChange = changes.get('containerType')
                if (containerTypeChange) {
                    // Because we need to clear corner radius first before we change container type.
                    // That means we can't get correct corner radius before undo container type change.
                    // So, use timeout here to update corner radius after container type change.
                    setTimeout(() => {
                        this.dataStore.startTransaction()
                        this.set('cornerRadius', this.getBaseProp('cornerRadius').cornerRadius, { commit: false, interaction: false })
                        if (this.dataStore.isActionMode) {
                            this.dataStore.transition.forceUpdateAnimation()
                        }
                        this.dataStore.endTransaction()
                    })
                }
                break
            }
        }
    }

}

/**
 * Get Container if `group` is a Group
 * @param {Element} group
 * @returns {Element}
 */
function getContainer(group) {
    return group.get('type') !== EntityType.DATA_STORE &&
        group.get('elementType') === ElementType.GROUP
        ? group.get('parent')
        : group
}

/** @typedef {import('./Element').ElementData} ElementData */

/**
 * @typedef {ElementData} GroupData
 * @property {ElementData[]} children
 */
