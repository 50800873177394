import React, { forwardRef, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ContextMenu, Icon, IconButton, Tooltip } from '..'
import { useModal, useSetModal } from '../../../providers/ModalProvider'
import ReactionWithData from '../../Comment/ReactionWithData'
import { getCommentContent } from '../../Comment/utils'
import Avatar from '../Avatar'
import { ModalsState } from '../ContextMenu/types'
import { MenuListOptionProps, MenuOptionProps } from '../Menu/Menu.types'
import { translateMenuOptions } from '../Menu/utils'
import CommentTextArea from './CommentTextArea'
import { DELETE_COMMENT_OPTION, EDIT_COMMENT_OPTION } from './commentMenuConstants'
import { CommentBodyContentChildProps, CommentBodyContentProps, CommentDataProps, CommentType } from './types'

const getCommentOptions = (isFirstComment: boolean): MenuListOptionProps[] => {
  const options: MenuListOptionProps[] = [EDIT_COMMENT_OPTION]

  if (!isFirstComment) {
    options.push(DELETE_COMMENT_OPTION)
  }

  return options
}

export interface CommentProps {
  comment: CommentDataProps
  showReactions?: boolean
  onCommentEdit?: (comment: CommentDataProps, newCommentText: string) => void
  toggleDeleteCommentDialog?: (open: boolean) => void
  onMarkThreadAsRead?: (comment: CommentDataProps) => void
  onCopyLinkToThread?: (comment: CommentDataProps) => void
  onThreadResolveClick?: () => void
  setEditingComment?: (isEditing: boolean) => void
  isResolved?: boolean
  showResolveButton?: boolean
  displayTime?: string
  isExpanded?: boolean
  isFirstComment?: boolean
  isEditing?: boolean
  replyCount?: number
  type?: CommentType
  currentUserId?: string
  userName: string
  userAvatar: string
  className?: string
}

const Comment = forwardRef<HTMLDivElement, CommentProps>(
  (
    {
      comment,
      onCommentEdit,
      onMarkThreadAsRead,
      toggleDeleteCommentDialog,
      onThreadResolveClick,
      setEditingComment,
      isResolved,
      showResolveButton,
      displayTime,
      isExpanded = true,
      isFirstComment = false,
      isEditing = false,
      replyCount,
      type = CommentType.PANEL,
      currentUserId = '',
      userName,
      userAvatar,
      className = ''
    },
    forwardedRef
  ) => {
    const { t } = useTranslation('file', { keyPrefix: 'commenting' })
    const triggerRef = React.useRef<HTMLButtonElement>(null)
    const uniqueThreadId = `${type}-${comment.threadId}`
    const uniqueCommentId = `${type}-${comment.id}`
    const [isIconButtonFocused, setIsIconButtonFocused] = useState(false)
    const translatedCommentOptions = useMemo(
      () =>
        translateMenuOptions(t, getCommentOptions(isFirstComment), {
          ns: 'file',
          keyPrefix: 'commenting'
        }),
      [isFirstComment, t]
    )

    const {
      open: isCommentContextMenuOpen,
      data: { trigger, cursorPosition }
    } = useModal((o: ModalsState) => o[`CommentContextMenu-${uniqueCommentId}`])
    const { open: isThreadContextMenuOpen } = useModal((o: ModalsState) => o[`CommentContextMenu-${uniqueThreadId}`])

    const { openModal: openCommentMenu, closeModal: closeCommentMenu } = useSetModal(
      `CommentContextMenu-${uniqueCommentId}`
    )
    const { openModal: openThreadMenu } = useSetModal(`CommentContextMenu-${uniqueThreadId}`)

    const isCreator = comment.userId === currentUserId
    const isPanelType = type === CommentType.PANEL
    const showButtonWithoutHover = isExpanded ? isCommentContextMenuOpen : isThreadContextMenuOpen

    const commentText = getCommentContent(comment.body?.content ?? [])

    const renderContentChild = (
      contentChild: CommentBodyContentChildProps,
      contentIndex: number,
      contentChildIndex: number
    ): React.ReactNode => {
      // `content` is an array to accommodate various content types.
      // Currently, LiveBlocks only supports paragraphs, but it may
      // expand to support other types like images in the future.
      if ('type' in contentChild) {
        if (contentChild.type === 'mention') {
          // TODO: [commenting] Implement the mentioned feature here.
          return <span key={`mention-${contentIndex}-${contentChildIndex}`}>mention</span>
        }
        if (contentChild.type === 'link') {
          return (
            <a
              target="_blank"
              key={`link-${contentIndex}-${contentChildIndex}`}
              href={contentChild.url}
              rel="noopener noreferrer nofollow"
            >
              {contentChild.url}
            </a>
          )
        }
      }

      if ('text' in contentChild) {
        return (
          <span key={`text-${contentIndex}-${contentChildIndex}`} className="whitespace-pre-wrap break-words">
            {contentChild.text}
          </span>
        )
      }
      return null
    }
    const handleIconButtonFocus = () => {
      setIsIconButtonFocused(true)
    }
    const handleIconButtonBlur = () => {
      setIsIconButtonFocused(false)
    }

    const renderContents = (contents: CommentBodyContentProps[]): React.ReactNode[] =>
      contents.map((content: CommentBodyContentProps, contentIndex: number) =>
        content.children.map((contentChild: CommentBodyContentChildProps, contentChildIndex: number) =>
          renderContentChild(contentChild, contentIndex, contentChildIndex)
        )
      )

    const handleThreadResolveClick = (e: React.MouseEvent): void => {
      e.stopPropagation()
      e.preventDefault()
      if (onThreadResolveClick) onThreadResolveClick()
    }

    const handleMoreClick = (e: React.MouseEvent): void => {
      e.stopPropagation()
      e.preventDefault()
      if (isExpanded) {
        openCommentMenu({ trigger: triggerRef })
      } else {
        openThreadMenu({ trigger: triggerRef })
      }
    }

    const handleEditComment = (newCommentText: string) => {
      onCommentEdit?.(comment, newCommentText)
      setEditingComment?.(false)
    }

    const handleCancelEdit = () => {
      setEditingComment?.(false)
    }

    const handleSelectOption = (option: MenuOptionProps) => {
      switch (option.value) {
        case EDIT_COMMENT_OPTION.value:
          setEditingComment?.(true)
          break
        case DELETE_COMMENT_OPTION.value:
          toggleDeleteCommentDialog?.(true)
          break
        case 'MARK_THREAD_AS_UNREAD':
          if (onMarkThreadAsRead) onMarkThreadAsRead(comment)
          break
        default:
          break
      }
    }

    useEffect(() => {
      if (!isExpanded) {
        setEditingComment?.(false)
      }
    }, [isExpanded, setEditingComment])

    useEffect(() => {
      if (isEditing) {
        setIsIconButtonFocused(false)
      }
    }, [isEditing])

    if (comment.deletedAt) {
      return null
    }

    return (
      <div ref={forwardedRef} className={`group flex gap-8 ${className}`}>
        <div className={`py-8 ${isPanelType ? 'pl-8' : 'pl-16'}`}>
          {isResolved && !isExpanded && isPanelType ? (
            <Icon interactive={false} name="ResolvedThread" />
          ) : (
            <Avatar src={userAvatar} alt={userName} size="s" radiusSize="sm" />
          )}
        </div>
        {isEditing ? (
          <CommentTextArea
            type={type}
            variant="edit"
            maxScrollHeight={430}
            defaultValue={commentText}
            onInputSubmit={handleEditComment}
            onEscape={handleCancelEdit}
            onCancel={handleCancelEdit}
            autoFocus
          />
        ) : (
          <>
            <div
              className={`flex flex-1 flex-col gap-8 overflow-hidden py-8 ${isPanelType ? 'pr-8' : 'pr-16'} ${
                isExpanded && 'select-text'
              }`}
            >
              <div className="flex gap-16 justify-between w-full">
                <div className="flex items-center gap-8 overflow-hidden">
                  <div className="text-white font-medium flex-grow truncate">{userName}</div>
                  <div className="text-11 text-light-overlay-60 whitespace-nowrap">{displayTime}</div>
                </div>
                {!(isExpanded && isResolved) && (
                  <div
                    className={`items-center gap-8 flex-shrink-0 ${
                      showButtonWithoutHover || isIconButtonFocused
                        ? 'flex'
                        : 'flex opacity-0 absolute group-hover:static group-hover:opacity-100'
                    }`}
                  >
                    {(isPanelType || isExpanded) && (
                      <>
                        {showResolveButton && !isResolved && (
                          <Tooltip content={t('resolve_thread')}>
                            <Icon name="Check" size="l" onClick={handleThreadResolveClick} />
                          </Tooltip>
                        )}
                        {/* TODO: [Commenting] When the reaction feature is to be implemented, it needs to be added back. */}
                        {/* {isExpanded && (
                          <Tooltip content="Add reaction">
                            <Icon name="Reaction" size="l" />
                          </Tooltip>
                        )} */}
                        {(!isExpanded || isCreator) && (
                          <IconButton
                            onFocus={handleIconButtonFocus}
                            onBlur={handleIconButtonBlur}
                            ref={triggerRef}
                            icon="More"
                            onClick={handleMoreClick}
                            tabIndex={isExpanded ? 0 : -1}
                            disableGroupHover
                            className="focus-visible:absolute group-hover:none"
                          />
                        )}
                      </>
                    )}
                  </div>
                )}
              </div>
              <div className={`text-white ${!isExpanded && 'line-clamp-2 truncate max-h-32'}`}>
                {renderContents(comment.body.content)}
              </div>
              {!isExpanded && replyCount ? (
                <div>
                  <div className="text-11 text-light-overlay-60 whitespace-nowrap">{`${replyCount} ${
                    replyCount > 1 ? t('replies') : t('reply')
                  }`}</div>
                </div>
              ) : null}
              {isExpanded && comment.reactions.length ? (
                <div className="flex gap-8">
                  {comment.reactions.map((reaction: any) => (
                    <ReactionWithData key={reaction.emoji} reaction={reaction} />
                  ))}
                </div>
              ) : null}
            </div>
            {!isResolved && (
              <ContextMenu
                open={isCommentContextMenuOpen}
                options={translatedCommentOptions}
                onSelect={handleSelectOption}
                onClose={closeCommentMenu}
                cursorPosition={cursorPosition}
                trigger={trigger}
              />
            )}
          </>
        )}
      </div>
    )
  }
)

Comment.displayName = 'Comment'

export default Comment
