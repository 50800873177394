import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { EffectType } from '@phase-software/types'

import { EFFECT_PROPS_DISPLAY_NAME_MAP, EFFECT_PROPS_NAME_MAP } from '../../../../constant'
import { useModal, useSetModal } from '../../../../providers/ModalProvider'
import { useSelectEffect } from '../../../../providers/dataStore/EffectProvider'
import { IconButton, Modal } from '../../../shared'
import TrimPathProps from './TrimPathProps'
import { Effect } from './types'

export const EFFECT_MODAL_NAME = 'EffectModal'

const effectComponents: Record<EffectType, React.ElementType> = {
  /** @ts-ignore */
  [EffectType.TRIM_PATH]: TrimPathProps
}

const EffectModal = () => {
  const { closeModal } = useSetModal(EFFECT_MODAL_NAME)
  const {
    open,
    data: { trigger, ...effectItem }
  } = useModal((modal: any) => modal[EFFECT_MODAL_NAME])
  const { t } = useTranslation('file', { keyPrefix: 'effect_type' })

  const effect: Effect = useSelectEffect((o) => o[EFFECT_PROPS_NAME_MAP[effectItem.type as EffectType]]) || {}

  const renderEffectProperties = () => {
    const EffectComponent = effectComponents[effectItem.type as EffectType]
    return EffectComponent ? <EffectComponent {...effect} id={effectItem.id} /> : null
  }

  useEffect(() => {
    return () => closeModal()
  }, [closeModal])

  const effectName = EFFECT_PROPS_DISPLAY_NAME_MAP[effectItem.type as EffectType]

  return (
    <Modal open={open} trigger={trigger} onClose={closeModal} placement="horizontal" offsetX={32} draggable>
      <div className="pb-16">
        <div className="p-16 flex justify-between text-white">
          {effectName ? t(effectName) : ''}
          <IconButton icon="Cross" onClick={closeModal} aria-label="Close" />
        </div>
        {renderEffectProperties()}
      </div>
    </Modal>
  )
}

export default EffectModal
