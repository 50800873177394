/* tslint:disable */
/* eslint-disable */
/**
 * PHASE API
 * Phase API service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateInvitationResponse,
  CreateTeamInvitationRequest,
  PatchTeamUserRequest,
  UpdateInvitationEmailRequest,
  UpdateInvitationRoleRequest,
} from '../models';

export interface TeamUserControllerAcceptTeamInvitationRequest {
    teamId: string;
    invitationId: string;
}

export interface TeamUserControllerCancelTeamInvitationRequest {
    teamId: string;
    invitationId: string;
}

export interface TeamUserControllerCreateTeamInvitationRequest {
    teamId: string;
    createTeamInvitationRequest: CreateTeamInvitationRequest;
}

export interface TeamUserControllerDeleteTeamUserRequest {
    teamId: string;
    userId: string;
}

export interface TeamUserControllerPatchTeamUserRequest {
    teamId: string;
    userId: string;
    patchTeamUserRequest: PatchTeamUserRequest;
}

export interface TeamUserControllerRefuseTeamInvitationRequest {
    teamId: string;
    invitationId: string;
}

export interface TeamUserControllerSendTeamInvitationEmailRequest {
    teamId: string;
    invitationId: string;
}

export interface TeamUserControllerUpdateTeamInvitationEmailRequest {
    teamId: string;
    invitationId: string;
    updateInvitationEmailRequest: UpdateInvitationEmailRequest;
}

export interface TeamUserControllerUpdateTeamInvitationRoleRequest {
    teamId: string;
    invitationId: string;
    updateInvitationRoleRequest: UpdateInvitationRoleRequest;
}

/**
 * 
 */
export class TeamUserApi extends runtime.BaseAPI {

    /**
     */
    async teamUserControllerAcceptTeamInvitationRaw(requestParameters: TeamUserControllerAcceptTeamInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling teamUserControllerAcceptTeamInvitation.');
        }

        if (requestParameters.invitationId === null || requestParameters.invitationId === undefined) {
            throw new runtime.RequiredError('invitationId','Required parameter requestParameters.invitationId was null or undefined when calling teamUserControllerAcceptTeamInvitation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/teams/{teamId}/invitations/{invitationId}:accept`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"invitationId"}}`, encodeURIComponent(String(requestParameters.invitationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async teamUserControllerAcceptTeamInvitation(requestParameters: TeamUserControllerAcceptTeamInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.teamUserControllerAcceptTeamInvitationRaw(requestParameters, initOverrides);
    }

    /**
     */
    async teamUserControllerCancelTeamInvitationRaw(requestParameters: TeamUserControllerCancelTeamInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling teamUserControllerCancelTeamInvitation.');
        }

        if (requestParameters.invitationId === null || requestParameters.invitationId === undefined) {
            throw new runtime.RequiredError('invitationId','Required parameter requestParameters.invitationId was null or undefined when calling teamUserControllerCancelTeamInvitation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/teams/{teamId}/invitations/{invitationId}:cancel`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"invitationId"}}`, encodeURIComponent(String(requestParameters.invitationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async teamUserControllerCancelTeamInvitation(requestParameters: TeamUserControllerCancelTeamInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.teamUserControllerCancelTeamInvitationRaw(requestParameters, initOverrides);
    }

    /**
     */
    async teamUserControllerCreateTeamInvitationRaw(requestParameters: TeamUserControllerCreateTeamInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateInvitationResponse>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling teamUserControllerCreateTeamInvitation.');
        }

        if (requestParameters.createTeamInvitationRequest === null || requestParameters.createTeamInvitationRequest === undefined) {
            throw new runtime.RequiredError('createTeamInvitationRequest','Required parameter requestParameters.createTeamInvitationRequest was null or undefined when calling teamUserControllerCreateTeamInvitation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/teams/{teamId}/invitations`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.createTeamInvitationRequest,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async teamUserControllerCreateTeamInvitation(requestParameters: TeamUserControllerCreateTeamInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateInvitationResponse> {
        const response = await this.teamUserControllerCreateTeamInvitationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async teamUserControllerDeleteTeamUserRaw(requestParameters: TeamUserControllerDeleteTeamUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling teamUserControllerDeleteTeamUser.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling teamUserControllerDeleteTeamUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/teams/{teamId}/users/{userId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async teamUserControllerDeleteTeamUser(requestParameters: TeamUserControllerDeleteTeamUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.teamUserControllerDeleteTeamUserRaw(requestParameters, initOverrides);
    }

    /**
     */
    async teamUserControllerPatchTeamUserRaw(requestParameters: TeamUserControllerPatchTeamUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling teamUserControllerPatchTeamUser.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling teamUserControllerPatchTeamUser.');
        }

        if (requestParameters.patchTeamUserRequest === null || requestParameters.patchTeamUserRequest === undefined) {
            throw new runtime.RequiredError('patchTeamUserRequest','Required parameter requestParameters.patchTeamUserRequest was null or undefined when calling teamUserControllerPatchTeamUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/teams/{teamId}/users/{userId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.patchTeamUserRequest,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async teamUserControllerPatchTeamUser(requestParameters: TeamUserControllerPatchTeamUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.teamUserControllerPatchTeamUserRaw(requestParameters, initOverrides);
    }

    /**
     */
    async teamUserControllerRefuseTeamInvitationRaw(requestParameters: TeamUserControllerRefuseTeamInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling teamUserControllerRefuseTeamInvitation.');
        }

        if (requestParameters.invitationId === null || requestParameters.invitationId === undefined) {
            throw new runtime.RequiredError('invitationId','Required parameter requestParameters.invitationId was null or undefined when calling teamUserControllerRefuseTeamInvitation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/teams/{teamId}/invitations/{invitationId}:refuse`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"invitationId"}}`, encodeURIComponent(String(requestParameters.invitationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async teamUserControllerRefuseTeamInvitation(requestParameters: TeamUserControllerRefuseTeamInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.teamUserControllerRefuseTeamInvitationRaw(requestParameters, initOverrides);
    }

    /**
     */
    async teamUserControllerSendTeamInvitationEmailRaw(requestParameters: TeamUserControllerSendTeamInvitationEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling teamUserControllerSendTeamInvitationEmail.');
        }

        if (requestParameters.invitationId === null || requestParameters.invitationId === undefined) {
            throw new runtime.RequiredError('invitationId','Required parameter requestParameters.invitationId was null or undefined when calling teamUserControllerSendTeamInvitationEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/teams/{teamId}/invitations/{invitationId}:sendEmail`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"invitationId"}}`, encodeURIComponent(String(requestParameters.invitationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async teamUserControllerSendTeamInvitationEmail(requestParameters: TeamUserControllerSendTeamInvitationEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.teamUserControllerSendTeamInvitationEmailRaw(requestParameters, initOverrides);
    }

    /**
     */
    async teamUserControllerUpdateTeamInvitationEmailRaw(requestParameters: TeamUserControllerUpdateTeamInvitationEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling teamUserControllerUpdateTeamInvitationEmail.');
        }

        if (requestParameters.invitationId === null || requestParameters.invitationId === undefined) {
            throw new runtime.RequiredError('invitationId','Required parameter requestParameters.invitationId was null or undefined when calling teamUserControllerUpdateTeamInvitationEmail.');
        }

        if (requestParameters.updateInvitationEmailRequest === null || requestParameters.updateInvitationEmailRequest === undefined) {
            throw new runtime.RequiredError('updateInvitationEmailRequest','Required parameter requestParameters.updateInvitationEmailRequest was null or undefined when calling teamUserControllerUpdateTeamInvitationEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/teams/{teamId}/invitations/{invitationId}:updateEmail`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"invitationId"}}`, encodeURIComponent(String(requestParameters.invitationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.updateInvitationEmailRequest,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async teamUserControllerUpdateTeamInvitationEmail(requestParameters: TeamUserControllerUpdateTeamInvitationEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.teamUserControllerUpdateTeamInvitationEmailRaw(requestParameters, initOverrides);
    }

    /**
     */
    async teamUserControllerUpdateTeamInvitationRoleRaw(requestParameters: TeamUserControllerUpdateTeamInvitationRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling teamUserControllerUpdateTeamInvitationRole.');
        }

        if (requestParameters.invitationId === null || requestParameters.invitationId === undefined) {
            throw new runtime.RequiredError('invitationId','Required parameter requestParameters.invitationId was null or undefined when calling teamUserControllerUpdateTeamInvitationRole.');
        }

        if (requestParameters.updateInvitationRoleRequest === null || requestParameters.updateInvitationRoleRequest === undefined) {
            throw new runtime.RequiredError('updateInvitationRoleRequest','Required parameter requestParameters.updateInvitationRoleRequest was null or undefined when calling teamUserControllerUpdateTeamInvitationRole.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/teams/{teamId}/invitations/{invitationId}:updateRole`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"invitationId"}}`, encodeURIComponent(String(requestParameters.invitationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.updateInvitationRoleRequest,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async teamUserControllerUpdateTeamInvitationRole(requestParameters: TeamUserControllerUpdateTeamInvitationRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.teamUserControllerUpdateTeamInvitationRoleRaw(requestParameters, initOverrides);
    }

}
