import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { usePopper } from 'react-popper'

import { capitalize } from 'lodash'
import moment from 'moment'

import { PresenceMessageData } from '../../../Broadcaster/PresenceManager'
import { useProfile } from '../../../providers/ProfileProvider'
import Avatar, { radiusSizeMap } from './index'

export type OverflowAvatarType = {
  size?: 's' | 'l'
  count: number
  radiusSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  // ref?: React.Ref<HTMLDivElement>
}

export const OverflowAvatar = forwardRef<HTMLDivElement, OverflowAvatarType>(
  ({ count, size = 's', radiusSize = 'md', onMouseEnter, onMouseLeave }, ref) => {
    const cornerRadius = radiusSizeMap[radiusSize]

    const getCount = useCallback((count: number): string => {
      // If the collapsed File Participant amount ≤ 9, show the exact number
      // If the collapsed File Participant amount > 9, show 9+.
      // If the collapsed File Participant amount > 99, show 99+
      if (count <= 9) {
        return count.toString()
      } else if (count > 9 && count <= 99) {
        return '9+'
      } else {
        return '99+'
      }
    }, [])

    const getSizeClassWidth = (size: string) => {
      if (size === 's') {
        const classes = 'text-12 h-28 leading-[28px]'
        if (count > 99) {
          return classes + ' min-w-[32px]'
        }
        return classes + ' min-w-[28px]'
      } else if (size === 'l') {
        const classes = 'text-14 h-32 leading-[32px]'
        if (count > 99) {
          return classes + ' min-w-[36px]'
        }
        return classes + ' min-w-[32px]'
      }
    }

    return (
      <div
        ref={ref}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={`${getSizeClassWidth(
          size
        )} ${cornerRadius} font-normal bg-light-overlay-10 text-light-overlay-60 inline-block text-center`}
      >
        {getCount(count)}
      </div>
    )
  }
)

OverflowAvatar.displayName = 'OverflowAvatar'

type PresenceAvatarType = {
  participant: PresenceMessageData
  disableStateEffect?: boolean
  popperPlacement?: 'top' | 'bottom'
}

const PresenceAvatar = ({
  participant,
  disableStateEffect = false,
  popperPlacement = 'bottom'
}: PresenceAvatarType) => {
  const triggerRef = useRef<HTMLDivElement>(null)
  const profile = useProfile()
  const tooltipRef = useRef<HTMLDivElement>(null)

  const reqAnimation = useRef<number | null>(null)
  const reqTimeout = useRef<NodeJS.Timeout | null>(null)
  const timer = useRef<NodeJS.Timeout | null>(null)
  const [classes, setClasses] = useState('opacity-0 scale-0')

  const [popperClasses, setPopperClasses] = useState('opacity-0 pointer-events-none')
  const [popperOptions, setPopperOptions] = useState({
    placement: popperPlacement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 4]
        }
      }
    ]
  })
  const { t } = useTranslation('common')
  const popper = usePopper(triggerRef.current, tooltipRef.current, popperOptions)
  const { styles, attributes, update } = popper

  const handleeMouseEnter = useCallback(async () => {
    if (timer.current !== null) {
      clearTimeout(timer.current)
    }

    setPopperOptions((opts) => {
      return {
        ...opts,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 8]
            }
          }
        ]
      }
    })
    setPopperClasses('opacity-100 pointer-events-auto')

    if (update) {
      await update()
    }
  }, [update])

  const handleMouseLeave = useCallback(() => {
    timer.current = setTimeout(() => {
      setPopperClasses('opacity-0 pointer-events-none')
      setPopperOptions((opts) => {
        return {
          ...opts,
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 4]
              }
            }
          ]
        }
      })
    }, 100)
  }, [])

  useEffect(() => {
    reqAnimation.current = requestAnimationFrame(() => {
      reqTimeout.current = setTimeout(() => {
        setClasses('opacity-100 scale-100')
      }, 0)
    })
    return () => {
      if (reqTimeout.current) clearTimeout(reqTimeout.current)
      if (reqAnimation.current) cancelAnimationFrame(reqAnimation.current)
    }
  }, [])

  if (!participant.user) return null

  return (
    <div onMouseEnter={handleeMouseEnter} onMouseLeave={handleMouseLeave}>
      <div className="relative" ref={triggerRef}>
        <Avatar
          size="m"
          className={`${classes} transition-all duration-300`}
          src={participant.user.avatar}
          alt={participant.user.username}
          isIdle={participant.idleTime !== null && !disableStateEffect}
        />
      </div>
      <div
        ref={tooltipRef}
        style={styles.popper}
        {...attributes.popper}
        className={`
          rounded-lg shadow-5 bg-neutral-80 flex flex-col px-16 py-12 max-w-[200px] z-10
          transition-all duration-300 opacity-0 ${popperClasses}
        `}
        title={participant.user.username}
        onMouseEnter={handleeMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="text-12 text-white font-medium flex items-center">
          <div className="truncate">{participant.user.username}</div>
          {participant.user.id === profile.id && <div className="whitespace-nowrap">({t('you')})</div>}
        </div>
        <div className="text-11 text-light-overlay-60">
          {participant.idleTime !== null
            ? capitalize(moment(participant.idleTime).fromNow())
            : t('presence_status.online')}
        </div>
      </div>
    </div>
  )
}

export default PresenceAvatar
