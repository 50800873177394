import React from 'react'
import { useTranslation } from 'react-i18next'

interface ReactionTooltipContentProps {
  userName: string
  isCurrentUser: boolean
  showSeparator: boolean
}

const ReactionTooltipContent = ({ userName, isCurrentUser, showSeparator }: ReactionTooltipContentProps) => {
  const { t } = useTranslation('common')
  return (
    <span>
      {showSeparator && ', '}
      {userName}
      {isCurrentUser && ` ${t('you')}`}
    </span>
  )
}

export default ReactionTooltipContent
