/** @todo It's critical that we align the calculations in this package with the renderer's transform calculations. Please, let's make this happen! */
import { mat2d } from 'gl-matrix'
import { EPSILON, isNull, notNull, isNum, mat2dEquals, mat2dBasis } from './commons'

/**
 * Compatibility wrapper for mat2d-like objects,
 * i.e objects that have `a`, `b`, `c`, `d`, `tx` and `ty` components
 */
export class Matrix2D extends Float32Array {
    /**
     * Creates new Matrix2D from from 6 number components or copies values from a single mat2d-like object
     * @param  {number | Matrix2D | mat2d | object } a     a component or mat2d-like object
     * @param  {number} b   b component
     * @param  {number} c   c component
     * @param  {number} d   d component
     * @param  {number} tx  tx component
     * @param  {number} ty  ty component
     */
    constructor(a, b, c, d, tx, ty) {
        super(6)
        if (
            typeof a === 'number' &&
            typeof b === 'number' &&
            typeof c === 'number' &&
            typeof d === 'number' &&
            typeof tx === 'number' &&
            typeof ty === 'number'
        ) {
            this[0] = a
            this[1] = b
            this[2] = c
            this[3] = d
            this[4] = tx
            this[5] = ty
        } else if (a) {
            this.copy(a)
        } else {
            // create idenity matrix (other components are zero by default in Float32Array)
            this[0] = 1
            this[3] = 1
        }
    }

    /**
     * Copies values from an array
     * @param  {number[]} array
     */
    fromArray(array) {
        if (isNull(array)) {
            return
        }
        mat2d.set(this, ...array)
    }

    clone() {
        return new Matrix2D(...this)
    }

    /**
     * Copies values from the mat2d-like object
     * @param  {Matrix2D | mat2d | object} val
     * @returns {Matrix2D} returns itself
     */
    copy(val) {
        if (isNull(val)) {
            return
        }
        const aa = notNull(val[0]) ? val[0] : val.a,
            bb = notNull(val[1]) ? val[1] : val.b,
            cc = notNull(val[2]) ? val[2] : val.c,
            dd = notNull(val[3]) ? val[3] : val.d,
            txx = notNull(val[4]) ? val[4] : val.tx,
            tyy = notNull(val[5]) ? val[5] : val.ty
        if (isNum(aa) && isNum(bb) && isNum(cc) && isNum(dd) && isNum(txx) && isNum(tyy)) {
            mat2d.set(this, aa, bb, cc, dd, txx, tyy)
        }
        return this
    }

    /**
     * Checks if this Matrix2D is equal (has same corresponding component values) to another mat2d-like object
     * @param {Matrix2D | mat2d | object} val     mat2d-like object
     * @param {number} epsilon                    precision; default is 0.0001
     * @returns {boolean}                        true if vectors are equal; false othewise
     */
    eq(val, epsilon = EPSILON) {
        if (isNull(val)) {
            return false
        }
        const other = [
            notNull(val[0]) ? val[0] : val.a,
            notNull(val[1]) ? val[1] : val.b,
            notNull(val[2]) ? val[2] : val.c,
            notNull(val[3]) ? val[3] : val.d,
            notNull(val[4]) ? val[4] : val.tx,
            notNull(val[5]) ? val[5] : val.ty
        ]
        return mat2dEquals(this, other, epsilon)
    }

    /**
     * Creates new basis transform matrix (leaving out translation)
     * @returns {Matrix2D}   new Matrix2D
     */
    basis() {
        return mat2dBasis(new Matrix2D(), this)
    }

    get a() {
        return this[0]
    }
    set a(value) {
        this[0] = value
    }

    get b() {
        return this[1]
    }
    set b(value) {
        this[1] = value
    }

    get c() {
        return this[2]
    }
    set c(value) {
        this[2] = value
    }

    get d() {
        return this[3]
    }
    set d(value) {
        this[3] = value
    }

    get tx() {
        return this[4]
    }
    set tx(value) {
        this[4] = value
    }

    get ty() {
        return this[5]
    }
    set ty(value) {
        this[5] = value
    }

    save() {
        return [
            this[0],
            this[1],
            this[2],
            this[3],
            this[4],
            this[5]
        ]
    }
}

Matrix2D.IDENTITY = new Matrix2D()
Matrix2D.ZERO = new Matrix2D(0, 0, 0, 0, 0, 0)
