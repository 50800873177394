import { throttle } from 'lodash'
import { init, resize } from '@phase-software/renderer'
import dataStore from '../../dataStore'

/** only use this as a reference, use `RendererCanvasContainer` for appending renderer to DOM */
export const RendererCanvas = document.createElement('canvas')
RendererCanvas.id = 'renderer-canvas'
RendererCanvas.addEventListener('contextmenu', (e) => {
  e.preventDefault()
})

/** renderer will append cursor and IME input to this container */
export const RendererCanvasContainer = document.createElement('div')
RendererCanvasContainer.id = 'renderer-container'
RendererCanvasContainer.appendChild(RendererCanvas)

RendererCanvasContainer.style.width = '100%'
RendererCanvasContainer.style.height = '100%'

const throttledResize = throttle(resize, 100)
const observer = new ResizeObserver(throttledResize)
observer.observe(RendererCanvasContainer)

export const initRenderer = (useLowDPR) => init(RendererCanvas, dataStore, useLowDPR)
