import { PropComponentType } from '@phase-software/types'
import { notNull, isNum, isArr } from '@phase-software/data-utils'
import PropertyComponent from './PropertyComponent'

/** @typedef {import('../DataStore').DataStore} DataStore */
/** @typedef {import('./PropertyComponent').AppliedRef} AppliedRef */

export default class CornerRadiusComponent extends PropertyComponent {
    /**
     * @param {DataStore} dataStore
     * @param {CornerRadiusComponentData} [data]
     * @param {object} [options] 
     * @param {bool} [options.regenId=false]   if set to true, will generate new ID
     */
    constructor(dataStore, data = {}, options) {
        super(dataStore, data, options)

        const { cornerRadius, cornerSmoothing } = data

        this.componentType = PropComponentType.CORNER_RADIUS
        this.cornerRadius = isNum(cornerRadius) ? cornerRadius : undefined
        if (this.cornerRadius === undefined) {
            this.cornerRadius = isArr(cornerRadius) ? [...cornerRadius] : 0
        }
        this.cornerSmoothing = notNull(cornerSmoothing) ? cornerSmoothing : 0

        if (!this.name) {
            this.name = 'cornerRadius'
        }
    }

    /** @param {Partial<CornerRadiusComponentSetData>} data */
    set(data) {
        super.set(data)

        if (isNum(data.cornerRadius)) {
            this.updateProp('cornerRadius', data.cornerRadius)
        } else if (isArr(data.cornerRadius)) {
            this.updateProp('cornerRadius', [...data.cornerRadius])
        } else if (data.cornerRadius === null) {
            this.updateProp('cornerRadius', undefined)
        }
        
        if (isNum(data.cornerSmoothing)) {
            this.updateProp('cornerSmoothing', data.cornerSmoothing)
        } else if (data.cornerSmoothing === null) {
            this.updateProp('cornerSmoothing', undefined)
        }
    }

    /**
     * Override this in subclasses
     * CALL super._clone() at the top of overriden method
     * @protected
     * @param {AppliedRef} [ref]
     * @returns {CornerRadiusComponent} obj
     */
    _clone(ref) {
        const obj = super._clone(ref)
        if (notNull(this.cornerRadius)) {
            obj.cornerRadius = isNum(this.cornerRadius) ? this.cornerRadius : [...this.cornerRadius]
        }
        obj.cornerSmoothing = this.cornerSmoothing
        return obj
    }

    /**
     * Override this in subclasses
     * CALL super._save() at the top of overriden method
     * @protected
     * @returns {CornerRadiusComponentData} data
     */
    _save() {
        const data = super._save()
        if (notNull(this.cornerRadius)) {
            data.cornerRadius = isNum(this.cornerRadius) ? this.cornerRadius : [...this.cornerRadius]
        }
        data.cornerSmoothing = this.cornerSmoothing
        return data
    }
}

/** @typedef {import('./PropertyComponent').PropertyComponentData} PropertyComponentData */

/**
 * @typedef {PropertyComponentData} CornerRadiusComponentData
 * @property {number | number[] | undefined} cornerRadius         single number or list of 4 numbers (for rectangle if not all coreners are the same) in px
 * @property {number | undefined} cornerSmoothing               see https://www.figma.com/plugin-docs/api/properties/nodes-cornersmoothing/
 */

/**
 * @typedef {PropertyComponentData} CornerRadiusComponentSetData
 * @property {number | number[] | null} cornerRadius                   single number or list of 4 numbers (for rectangle if not all coreners are the same) in px
 * @property {number | null} cornerSmoothing
 */
