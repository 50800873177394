import { EventEmitter } from 'eventemitter3'
import { getKeyCombo } from './KeyCombo'


/** @typedef {import('./KeyCombo').KeyCombo} KeyCombo */

/**
 * @typedef {object} ISEvent
 * @property {boolean} [handled=true]
 * @property {import('../math').Vector2} mousePos
 * @property {import('../math').Vector2} wheelDelta
 */

/**
 * The last key of a keybind is the trigger (the previous modifiers need to be pressed beforehand)
 *
 * There are 2 kinds of `Action`s:
 *  - one shot actions with `trigger` event listener bound
 *  - recurring actions with `start`, `end` and optinally `update` event listeners bound
 *
 * Grouped recurring actions can transition to and from one another (`start` event won't be fired on the transitioned action)
 *
 * @fires 'trigger'
 * @fires 'start'
 * @fires 'update'
 * @fires 'end'
 */
export class Action extends EventEmitter {
    /**
     * @param {string} name
     * @param {string[]} defaultKeyCombos
     * @param {import('./states').StateData} state
     * @param {symbol} [group]
     * @param {boolean} [fallback]
     */
    constructor(name, defaultKeyCombos, state, group = null, fallback = false) {
        super()
        /** @type {string} */
        this.name = name
        /** @type {import('./states').StateData} */
        this.state = state
        /** @type {symbol} */
        this.group = group
        /** @type {boolean} */
        this.fallback = fallback
        /** @type {string[]} */
        this.defaultKeyCombos = defaultKeyCombos
        /** @type {KeyCombo[]} */
        this._keyCombos = defaultKeyCombos.map(keyCombo => getKeyCombo(keyCombo))
        /** @type {boolean} */
        this._active = true
        /** @type {boolean} */
        this._pressed = false

    }

    get active() {
        return this._active
    }

    set active(value) {
        this._active = value
        if (this._pressed) this._pressed = false
    }

    get keyCombos() {
        return this._keyCombos.map(keyCombo => keyCombo && keyCombo.sourceStr)
    }

    /** @param {string[]} keyCombos */
    set keyCombos(keyCombos) {
        if (!this.active && keyCombos.length !== 0) {
            this.active = true
        }

        if (keyCombos.length === 0) {
            this.active = false
        }

        this._keyCombos = keyCombos.map(keyCombo => getKeyCombo(keyCombo))
    }

    get pressed() {
        return this._pressed
    }

    get usesDefaultKeyCombo() {
        return this.keyCombos.every((keyCombo, i) => keyCombo === this.defaultKeyCombos[i])
    }

    /**
     * @param {ISEvent} e
     * @param {KeyCombo} keyCombo
     * @returns {boolean} true if press was handled
     */
    _press(e, keyCombo) {
        if (!this._active) return

        // reset default
        e.handled = true
        if (this._pressed) {
            this.emit('update', e, keyCombo)
        } else {
            if (!this.emit('trigger', e, keyCombo) && !this.emit('start', e, keyCombo)) {
                e.handled = false
            }
            if (e.handled) {
                this._pressed = this.listeners('end').length !== 0
            }
        }
        return e.handled
    }

    end() {
        if (!this._active) return
        this._pressed = false
        this.emit('end')
    }

    /**
     * @param {KeyCombo} keyCombo
     * @param {ISEvent} ISEvent
     * @returns {boolean}
     */
    trigger(keyCombo, ISEvent) {
        return this.shouldTrigger(keyCombo) && this._press(ISEvent, keyCombo)
    }

    /**
     * special trigger function for passtrough action
     *
     * @param {string} triggerKeyCode
     * @param {Set<string>} pressedKeys
     * @param {string[]} statePath
     * @param {Event} event
     * @returns {boolean}
     */
    triggerPasstrough(triggerKeyCode, pressedKeys, statePath, event) {
        return this.shouldTrigger(triggerKeyCode, pressedKeys, statePath) && this.emit('trigger', event)
    }

    /**
     * @param {Action} nextAction
     * @param {KeyCombo} keyCombo
     * @param {ISEvent} ISEvent
     * @returns {boolean}
     */
    transitionTo(nextAction, keyCombo, ISEvent) {
        if (
            this.isInSameGroup(nextAction) &&
            nextAction.canBeActive(keyCombo)
        ) {
            nextAction._pressed = true
            nextAction._press(ISEvent, keyCombo)
            this._pressed = false
            return true
        }
        return false
    }

    /**
     * @param {KeyCombo} keyCombo
     * @returns {boolean}
     */
    shouldTrigger(keyCombo) {
        return this.canBeActive(keyCombo)
    }

    /**
     * @param {KeyCombo} keyCombo
     * @returns {boolean}
     */
    canBeActive(keyCombo) {
        return this._keyCombos.some(kC => kC.shouldTrigger(keyCombo, this.fallback))
    }

    /**
     * @param {string} keyCode
     * @returns {boolean}
     */
    hasKeyCode(keyCode) {
        return this._keyCombos.some(kC => keyCode === kC.triggerKeyCode)
    }

    /**
     * @param {Action} action
     * @returns {boolean}
     */
    isInSameGroup(action) {
        return this.group !== null && this.group === action.group
    }

    resetKeyCombo() {
        this.keyCombos = this.defaultKeyCombos
    }
}
