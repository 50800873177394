import { EntityType, ElementType } from '@phase-software/types'
import {
    Vector2,
    Vector4,
    createPropTypes,
    setAdd,
    EntityChange,
    reverseEntityChange,
    parseSceneTreeChanges,
    NO_COMMIT,
    NOT_UNDOABLE,
    isStr
} from '@phase-software/data-utils'
import { Group } from './Group'


/** @typedef {import('./Element').Element} Element */
/** @typedef {import('./DataStore').DataStore} DataStore */
/** @typedef {import('./Group').GroupData} GroupData */

const PROP_TYPES = createPropTypes({
    pan: { type: Vector2 },
    color: { type: Vector4 }
})

// TODO: consider put all event names and keys into phase-types
//  so we don't need to create these strings everywhere every time
const SCENE_TREE_CHANGES = 'SCENE_TREE_CHANGES'

const UNDO_CHANGES = ['name']
const UNDO_EVENTS = [SCENE_TREE_CHANGES]

const DELETE_ELEMENTS_OPTIONS = { fire: true, commit: true }
const ADD_CHILDREN_OPTIONS = { ...DELETE_ELEMENTS_OPTIONS, updateOrder: true }
const REMOVE_CHILDREN_OPTIONS = ADD_CHILDREN_OPTIONS

export class Workspace extends Group {
    /**
     * @param {DataStore} dataStore
     * @param {WorkspaceData} [data]
     */
    constructor(dataStore, data) {
        super(dataStore, data)

        // reset undoChanges coming from Element
        this.undoChanges = new Set(UNDO_CHANGES)
        setAdd(this.undoEvents, UNDO_EVENTS)

        this.aliases = {
            panX: { key: 'pan', index: 0 },
            panY: { key: 'pan', index: 1 }
        }

        this.propTypes = PROP_TYPES

        this._isInit = false

        this.elements = new Map() // <ID, Element>
        // this.deletedElements = new Map() // <ID, Element>
        this.elementOrder = new Map() // <ID, index:Number>
        this.elementOrderInvert = new Map() // <index:Number, ID>
        this.elementDepth = new Map() // <ID, depth:Number>
        this.updateElementOrder()

        this._changes = new EntityChange()

        this._isInit = true
    }

    clear() {
        super.clear()
    }

    fireSceneTreeChanges(options) {
        if (this._changes.isEmpty()) {
            return
        }
        this.fire(SCENE_TREE_CHANGES, this._changes, options)
        this._changes = new EntityChange()
    }

    /**
     * @param {object} [overrides] data object with overrides
     * @returns {Workspace}
     */
    clone(overrides) {
        const obj = super.clone(overrides)

        obj.data.pan = new Vector2(this.data.pan)
        obj.data.scale = this.data.scale
        obj.data.color = new Vector4(this.data.color)
        return obj
    }

    /**
     * creates a blank workspace
     * @protected
     */
    create() {
        super.create()
        this.data.type = EntityType.WORKSPACE
        this.data.elementType = null

        this.data.name = 'Workspace'
        this.data.elementType = null
        this.data.pan = new Vector2(0, 0)
        this.data.scale = 1
        this.data.color = new Vector4({ r: 1, g: 1, b: 1, a: 0 })
    }

    /**
     * populates workspace with data
     * @param {WorkspaceData} data
     */
    load(data) {
        super.load(data)
        this.data.type = EntityType.WORKSPACE
        this.data.elementType = null

        this.data.pan = new Vector2(data.pan)
        this.data.scale = data.scale
        this.data.color = new Vector4(data.color)
    }

    /**
     * saves data for workspace
     * @returns {WorkspaceData}
     */
    save() {
        const data = super.save()
        data.pan = [...this.data.pan]
        data.scale = this.data.scale
        data.color = [...this.data.color]
        return data
    }

    /**
     * get element by id from element map
     * @param {string} id
     * @returns {Element}
     */
    getElement(id) {
        if (id === this.data.id) {
            return this
        }
        return this.elements.get(id)
    }

    /**
     * check if element with id exists in element order map
     * @param {string} id
     * @returns {Element}
     */
    hasElement(id) {
        return this.elementOrder.get(id) !== undefined
    }


    /**
     * Calculates order of all elements in scene tree
     */
    updateElementOrder() {
        // TODO: consider optimizing the update: only update changed parts
        this.elementOrder.clear()
        this.elementDepth.clear()
        this.elementOrderInvert.clear()
        let count = 0
        let el, id, depth, child
        for (el of this.traverseSubtree(this)) {
            id = el.get('id')
            if (!this._isInit) {
                this.elements.set(id, el)
            }
            // init element depth starting from Workspace
            if (el === this) {
                this.elementDepth.set(id, 0)
            }

            this.elementOrderInvert.set(count, id)
            this.elementOrder.set(id, count)
            count++

            if (el.children) {
                depth = this.elementDepth.get(id) + 1
                for (child of el.children) {
                    this.elementDepth.set(child.get('id'), depth)
                }
            }
        }
    }

    /**
     * Preorder traversal of the subtree.
     * @param {string | Element}  root
     * @param {bool} [includeRoot=true]         set to false to not include the root itself in the iterable
     * @param {bool} [ordered=false]            set to true to let children start from index 0
     * @yields {string | Element}
     */
    *traverseSubtree(root, includeRoot = true, ordered = false) {
        const isId = isStr(root)
        let el = isId ? this.getElement(root) : root
        if (!el) {
            return
        }
        if (includeRoot) {
            yield root
        }

        if (!el.children) {
            return
        }
        const stack = ordered ? [...el.children].reverse() : [...el.children];
        while (stack.length) {
            el = stack.pop()
            yield isId ? el.get('id') : el

            if (!el.children) {
                continue
            }
            stack.push(...(ordered ? [...el.children].reverse() : [...el.children]));
        }
    }

    /**
     * Sorts a list of elements based on their order in Scene tree of this Workspace
     * @param {Element[]} elementList
     * @param {bool} inPlace
     * @returns {Element[]} `elementList` if `inPlace` is true; new sorted array of Elements otherwise
     */
    sortElements(elementList, inPlace = true) {
        const list = inPlace ? elementList : [...elementList]
        return list.sort((elA, elB) => {
            const idxA = this.elementOrder.get(elA.get('id'))
            const idxB = this.elementOrder.get(elB.get('id'))
            // should be in descending order of the indices
            return idxB - idxA
        })
    }

    /**
     * Find the top most (as displayed in UI) element from the list
     * @param {Iterable<Element>} elementList
     * @returns {Element}       top most element from the `elementList`
     */
    getTopMostElement(elementList) {
        let topMost
        let topMostIdx = Number.MAX_SAFE_INTEGER
        for (const el of elementList) {
            const idx = this.elementOrder.get(el.get('id'))
            if (idx < topMostIdx) {
                topMostIdx = idx
                topMost = el
            }
        }
        return topMost
    }

    /**
     * Find the bottom most (as displayed in UI) element from the list
     * @param {Iterable<Element>} elementList
     * @returns {Element}       bottom most element from the `elementList`
     */
    getBottomMostElement(elementList) {
        let bottomMost
        let bottomMostIdx = -1
        for (const el of elementList) {
            const idx = this.elementOrder.get(el.get('id'))
            if (idx > bottomMostIdx) {
                bottomMostIdx = idx
                bottomMost = el
            }
        }
        return bottomMost
    }

    /**
     * Find the deepest top most element from the list
     * @param {Iterable<Element>} elementList
     * @returns {Element}       deepest top most element from the `elementList`
     */
    getDeepestElement(elementList) {
        let el, depth
        let deepestDepth = -1
        const deepestSet = new Set()
        for (el of elementList) {
            depth = this.elementDepth.get(el.get('id'))
            if (depth < deepestDepth) {
                continue
            }
            if (depth > deepestDepth) {
                deepestSet.clear()
                deepestDepth = depth
            }
            deepestSet.add(el)
        }
        return this.getTopMostElement(deepestSet)
    }

    /**
     * Check if specified `element` is a descendant of specified `ancestor`
     * @param {Element} element
     * @param {Element} ancestor
     * @returns {bool}  true if `element` is a descendant of the `ancestor`; false otherwise
     */
    isDescendantOf(element, ancestor) {
        let el = element
        let parent
        do {
            parent = el.get('parent')
            if (parent === ancestor) {
                return true
            }
            if (parent.get('type') === EntityType.WORKSPACE) {
                return false
            }
            el = parent
        } while (parent)
        return false
    }

    /**
     * Compares scene tree depth of two elements
     * @param {Element} element1
     * @param {Element} element2
     * @returns {number}    negative if element1 depth is smaller than element2 depth;
     *                      positive if element1 depth is bigger than element 2 depth;
     *                      0 if element1 depth is equal to element2 depth
     */
    compareDepth(element1, element2) {
        const id1 = element1.get('id')
        const id2 = element2.get('id')
        if (!this.elementDepth.has(id1) || !this.elementDepth.has(id2)) {
            return
        }
        return this.elementDepth.get(id1) - this.elementDepth.get(id2)
    }

    /**
     * Get parent of the element (considering specifics of the Container elements and stopping at Workspace level)
     * @param {Element} element
     * @param {bool} [includeWorkspace=false]
     * @returns {Element | undefined}
     */
    getParentOf(element, includeWorkspace = false) {
        const parent = element.get('parent')
        if (!parent) {
            return undefined
        }

        if (!includeWorkspace && parent.get('type') === EntityType.WORKSPACE) {
            return undefined
        }
        return parent
    }

    /**
     * Get common ancestor of two elements
     * @param {Element} element1
     * @param {Element} element2
     * @returns {Element | undefined}
     */
    getCommonAncestor(element1, element2) {
        let e1 = element1
        let e2 = element2

        let depthCompare
        do {
            depthCompare = this.compareDepth(e1, e2)
            if (depthCompare < 0) {
                e2 = this.getParentOf(e2)
            } else if (depthCompare > 0) {
                e1 = this.getParentOf(e1)
            }
            if (!e1 || !e2) {
                return
            }
        } while (depthCompare !== 0)

        while (e1 !== e2) {
            e1 = this.getParentOf(e1)
            e2 = this.getParentOf(e2)
        }
        return e1
    }

    /**
     * Adds children to the end of the specified parent
     * @param {Element} parent      new parent
     * @param {Element[]} children
     * @param {object} [options]
     * @param {boolean} [options.updateOrder=true]  true if this method should update the element order; set to false to not update it
     * @param {boolean} [options.fire=true]      true if this method should fire any events; set to false to not fire
     * @param {boolean} [options.commit=true]    true if this method should commit all events it fired; set to false to not commit automatically
     * @returns {false | Element[]} elements that were successfully added
     */
    addChildren(
        parent,
        children,
        { updateOrder = true, fire = true, commit = true } = ADD_CHILDREN_OPTIONS
    ) {
        const oldParents = this._collectExistParents(children)
        // TODO: update options here to NO_FIRE_NO_COMMIT when
        //  fully moved to use SCENE_TREE_CHANGES event everywhere
        const list = parent.addChildren(children, NO_COMMIT, this._changes)
        this._removeEmptyComputedGroupParents(oldParents)
        if (updateOrder) {
            this.updateElementOrder()
        }
        this.mapElements(list)

        if (fire) {
            this.fireSceneTreeChanges()
        }

        if (parent && parent.isComputedGroup) {
            parent.recalculateBounds()
        }
        oldParents.forEach((oldParent) => {
            oldParent.recalculateBounds()
        })

        if (fire && commit) {
            this.dataStore.commitUndo()
        }

        return list
    }

    /**
     * Adds children to the end of the specified parent
     * @param {Element} parent      new parent
     * @param {Element[]} children
     * @param {number} index
     * @param {object} [options]
     * @param {boolean} [options.updateOrder=true]  true if this method should update the element order; set to false to not update it
     * @param {boolean} [options.fire=true]      true if this method should fire any events; set to false to not fire
     * @param {boolean} [options.commit=true]    true if this method should commit all events it fired; set to false to not commit automatically
     * @returns {false | Element[]}                  elements that were successfully added
     */
    addChildrenAt(
        parent,
        children,
        index,
        { updateOrder = true, fire = true, commit = true } = ADD_CHILDREN_OPTIONS
    ) {
        const oldParents = this._collectExistParents(children)
        // TODO: update options here to NO_FIRE_NO_COMMIT when
        //  fully moved to use SCENE_TREE_CHANGES event everywhere
        const list = parent.addChildrenAt(children, index, NO_COMMIT, this._changes)
        if (updateOrder) {
            this.updateElementOrder()
        }
        this.mapElements(list)

        if (fire) {
            this.fireSceneTreeChanges()
        }

        if (parent && parent.isComputedGroup) {
            parent.recalculateBounds()
        }
        oldParents.forEach((oldParent) => {
            oldParent.recalculateBounds()
        })

        if (fire && commit) {
            this.dataStore.commitUndo()
        }

        return list
    }

    /**
     * Removed successive number of children from specified parent at specified index
     * @param {Element} parent
     * @param {number} index           index at which to start removing elements
     * @param {number} [howMany=1]     by default removes 1 element
     * @param {object} [options]
     * @param {boolean} [options.updateOrder=true]  true if this method should update the element order; set to false to not update it
     * @param {boolean} [options.fire=true]      true if this method should fire any events; set to false to not fire
     * @param {boolean} [options.commit=true]    true if this method should commit all events it fired; set to false to not commit automatically
     * @returns {Element[]}              list of Elements that were removed
     */
    removeChildrenAt(
        parent,
        index,
        howMany = 1,
        { updateOrder = true, fire = true, commit = true } = REMOVE_CHILDREN_OPTIONS
    ) {
        // TODO: update options here to NO_FIRE_NO_COMMIT when
        //  fully moved to use SCENE_TREE_CHANGES event everywhere
        const list = parent.removeChildrenAt(index, howMany, NO_COMMIT, this._changes)
        this._removeEmptyComputedGroupParents([parent])
        if (updateOrder) {
            this.updateElementOrder()
        }
        this._mapDeletedElements(list)

        if (fire) {
            this.fireSceneTreeChanges()
        }

        if (parent && parent.isComputedGroup) {
            parent.recalculateBounds()
        }

        if (fire && commit) {
            this.dataStore.commitUndo()
        }

        return list
    }

    /**
     * Removes children from the specified parent
     * @param {Element} parent
     * @param {Element[]} children
     * @param {object} [options]
     * @param {boolean} [options.updateOrder=true]  true if this method should update the element order; set to false to not update it
     * @param {boolean} [options.fire=true]      true if this method should fire any events; set to false to not fire
     * @param {boolean} [options.commit=true]    true if this method should commit all events it fired; set to false to not commit automatically
     * @returns {Element[]}              list of Elements that were removed
     */
    removeChildren(
        parent,
        children,
        { updateOrder = true, fire = true, commit = true } = REMOVE_CHILDREN_OPTIONS
    ) {
        // TODO: update options here to NO_FIRE_NO_COMMIT when
        //  fully moved to use SCENE_TREE_CHANGES event everywhere
        const list = parent.removeChildren(children, NO_COMMIT, this._changes)
        if (updateOrder) {
            this.updateElementOrder()
        }
        this._mapDeletedElements(list)

        if (fire) {
            this.fireSceneTreeChanges()
        }
        
        if (parent && parent.isComputedGroup) {
            parent.recalculateBounds()
        }

        if (fire && commit) {
            this.dataStore.commitUndo()
        }
        
        return list
    }

    /**
     * Delete elements in this workspace
     * @param {Iterable<Elements>} elements
     * @param {object} [options]
     * @param {boolean} [options.fire=true]      true if this method should fire any events; set to false to not fire
     * @param {boolean} [options.commit=true]    true if this method should commit all events it fired; set to false to not commit automatically
     */
    deleteElements(elements, { fire = true, commit = true } = DELETE_ELEMENTS_OPTIONS) {
        /** @type {Map<Element, Element[]>} */
        const parentsMap = new Map()
        let el, parent
        // aggregate by parents
        for (el of elements) {
            if (el.get('elementType') === ElementType.SCREEN) {
                continue
            }
            parent = el.get('parent')
            if (parentsMap.has(parent)) {
                parentsMap.get(parent).push(el)
            } else {
                parentsMap.set(parent, [el])
            }

            el.clear()
        }
        for (parent of parentsMap.keys()) {
            parent.removeChildren(parentsMap.get(parent), NO_COMMIT, this._changes)
            this._removeEmptyComputedGroupParents([parent])
        }
        this.updateElementOrder()

        if (fire) {
            this.fireSceneTreeChanges()
        }

        for (parent of parentsMap.keys()) {
            if (parent && parent.isComputedGroup) {
                parent.recalculateBounds()
            }
        }

        if (fire && commit) {
            this.dataStore.commitUndo()
        }
    }

    /**
     * Toggle expand if:
     * - Some containers is unfolded: Fold all the containers
     * - All the children are folded: Unfold all
     */
    toggleExpand() {
        const expandableChildren = this.children.filter(expandableFilter)
        if (!expandableChildren.length) return

        const expanded = expandableChildren.some(el => el.get('expanded'))
        const expandableList = [...this.elements.values()].filter(expandableFilter)

        expandableList.forEach(el => {
            el.set('expanded', !expanded)
        })
        this.fire('EXPAND_CHANGES')
    }

    /**
     * Initial all added children
     * @param {Element} element
     * @param {string[]} updateList
     */
    _initAllAddedChildren(element, updateList = []) {
        if (element?.children?.length) {
            element.children.forEach((child) => {
                child.setup()
                updateList.push(child.get('id'))
                this._initAllAddedChildren(child)
            })
        }
    }

    /**
     * @param {string} type  event type
     * @param {*} changes
     */
    undo(type, changes) {
        super.undo(type, changes)
        if (type !== SCENE_TREE_CHANGES) {
            return
        }

        const revChanges = reverseEntityChange(changes)
        // TODO: verify if this logic is suitable to move inside of reverseEntityChange()
        revChanges.UPDATE = new Map(
            [...revChanges.UPDATE].reverse()
        )
        const updateList = []
        const { removed, added } = parseSceneTreeChanges(revChanges)
        const parents = new Set()

        added.forEach(elementId => {
            const element = this.getElement(elementId)
            element.setup()
            updateList.push(elementId)

            this._initAllAddedChildren(element, updateList)
        })
        for (const parentId of changes.UPDATE.keys()) {
            const childrenChange = changes.UPDATE.get(parentId).get('children')
            const parent = this.getElement(parentId)
            parents.add(parent)
            parent.children = childrenChange.before.map(id => {
                const child = this.getElement(id)
                child.set('parent', parent)
                updateList.push(id)
                return child
            })
        }
        parents.forEach((parent) => {
            if (parent && parent.isContainer && parent.isComputedGroup) {
                parent.connectWithChildren()
            }
        })

        removed.forEach(elementId => {
            this.getElement(elementId).clear()
        })

        this.updateElementOrder()
        this.fire(SCENE_TREE_CHANGES, revChanges, NOT_UNDOABLE)
        // In the case where the change updates the base data, we need to update
        // the default KF and interval.
        if (this.dataStore.isActionMode) {
            this.dataStore.transition.updateDefaultKFAndInterval()
        }
        this.dataStore.transition.updateElementData(updateList)
    }

    /**
     * @param {string} type  event type
     * @param {*} changes
     */
    redo(type, changes) {
        super.redo(type, changes)
        if (type !== SCENE_TREE_CHANGES) {
            return
        }

        const { removed, added } = parseSceneTreeChanges(changes)
        const updateList = []
        const parents = new Set()

        added.forEach(elementId => {
            const element = this.getElement(elementId)
            element.setup()
            updateList.push(elementId)
          
            this._initAllAddedChildren(element, updateList)
        })
        for (const parentId of changes.UPDATE.keys()) {
            const childrenChange = changes.UPDATE.get(parentId).get('children')
            const parent = this.getElement(parentId)
            parents.add(parent)
            parent.children = childrenChange.after.map(id => {
                const child = this.getElement(id)
                child.set('parent', parent)
                updateList.push(id)
                return child
            })
        }
        parents.forEach((parent) => {
            if (parent && parent.isContainer && parent.isComputedGroup) {
                parent.connectWithChildren()
            }
        })
        
        removed.forEach(elementId => {
            this.getElement(elementId).clear()
        })

        this.updateElementOrder()
        this.fire(SCENE_TREE_CHANGES, changes, NOT_UNDOABLE)
        // In the case where the change updates the base data, we need to update
        // the default KF and interval.
        if (this.dataStore.isActionMode) {
            this.dataStore.transition.updateDefaultKFAndInterval()
        }
        this.dataStore.transition.updateElementData(updateList)
    }

    /**
     *
     * @param {Iterable<Element> | false} list
     */
    mapElements(list) {
        if (!list) {
            return
        }
        let el, descendant
        for (el of list) {
            for (descendant of this.traverseSubtree(el, true)) {
                const elementId = descendant.get('id')
                if (!this.elements.has(elementId)) {
                    this._changes.CREATE.add(elementId)
                }
                this.elements.set(elementId, descendant)
            }
        }
    }

    /**
     *
     * @param {Iterable<Element> | false} list
     */
    _mapDeletedElements(list) {
        if (!list) {
            return
        }
        let el, descendant
        for (el of list) {
            for (descendant of this.traverseSubtree(el, true)) {
                const id = descendant.get('id')
                // TODO: Need to adjust what we gonna do
                // 1. No need to delete item from elements, and no need to use deletedElements
                // 2. To have a consistent way as other interfaces, elements keep the items which
                //    is in usage, and move the removed items into deletedElements.
                //    But need to be careful where we use it.
                // this.deletedElements.set(id, descendant)
                // this.elements.delete(id)
                this.elementOrder.delete(id)
                this.elementDepth.delete(id)
            }
        }
    }

    /**
     * collect the old parent data for checking the number of the boolean/mask group element's children
     * @param {Element[]} children 
     * @returns {Set}
     */
    _collectExistParents(children) {
        const oldParents = new Set()
        for (let index = 0; index < children.length; index++) {
            const parent = children[index].get('parent')
            // skip the new create children
            if (!parent) continue
            oldParents.add(parent)
        }
        return oldParents
    }

    /**
     * check that if the old parent is boolean/mask type and it don't children
     * and then remove the old parent
     * @param {Set<Element> | []} oldParents 
     */
    _removeEmptyComputedGroupParents(oldParents) {
        for (const _parent of oldParents) {
            let parent = _parent
            let ancestor = _parent.get('parent')
            while (parent && parent.isContainer && parent.isComputedGroup && parent.children.length === 0) {
                parent.clear()
                ancestor.removeChildren([parent], NO_COMMIT, this._changes)
                parent = ancestor
                ancestor = ancestor.get('parent')
            }
        }
    }

    forceUpdateLayerListToRenderer() {
        this.elements.forEach((element) => {
            element.computedStyle.forceFireLayerListChanges()
        })
    }
}

/**
 * @private
 * @param {Element} el
 * @returns {boolean}
 */
function expandableFilter(el) {
    const elementType = el.get('elementType')
    return elementType === ElementType.SCREEN ||
        elementType === ElementType.CONTAINER
}

/**
 * @typedef {GroupData} WorkspaceData
 * @property {number} panX
 * @property {number} panY
 * @property {number} scale
 * @property {Vector4} color
 */
