import { IRComponent } from './IRComponent'

export class IRLayer extends IRComponent {
  static props = [...super.props, 'opacity', 'blendMode', 'paint']

  static defaultValue = {
    opacity: 100,
    blendMode: 'NORMAL',
    paint: {}
  }

  constructor(data = IRLayer.defaultValue) {
    super(data)
    this.type = data.type
  }

  fromJSON(data) {
    super.fromJSON(data)
    this.type = data.type
    return this
  }

  toJSON() {
    return {
      ...super.toJSON(),
      type: this.type
    }
  }
}
