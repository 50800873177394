import React from 'react'
import { useTranslation } from 'react-i18next'

import { useSetNotification } from '../../../providers/NotificationProvider'
import { Tooltip } from '../../shared'
import LinkSwitcher from '../../shared/LinkSwitcher'

type InspectRowProps = {
  label?: string
  value?: string
  type?: 'narrow' | 'wide'
  divider?: 'full' | 'partialBorderBottom' | 'partialBorderTop' | 'none'
  leftContent?: React.ReactNode
  rightContent?: React.ReactNode
  showDetailsButton?: boolean
  copyDisabled?: boolean
  valueClassName?: string
  onClick?: () => void
  onClickDetails?: () => void
}

const InspectRow = ({
  label,
  value,
  type = 'narrow',
  divider = 'none',
  leftContent,
  rightContent,
  showDetailsButton,
  copyDisabled = false,
  valueClassName,
  onClick,
  onClickDetails
}: InspectRowProps) => {
  const { t } = useTranslation(['file', 'workspace'])
  const { addNotification } = useSetNotification()
  const handleClickContainer = () => {
    onClick && onClick()
    if (!value || copyDisabled) return
    navigator.clipboard.writeText(value?.toString() || '')

    addNotification({
      type: 'success',
      content: t('workspace:message.copied_to_clipboard')
    })
  }

  const handleClick = (e: any) => {
    e.stopPropagation()
    onClickDetails && onClickDetails()
  }

  const renderDivider = () => {
    let marginStyle = ''
    if (type === 'narrow' || divider === 'none') return null
    if (divider === 'partialBorderBottom') marginStyle = 'mt-8'
    if (divider === 'partialBorderTop') marginStyle = 'mb-8'

    return <div className={`absolute h-24 left-20 border-l border-solid border-light-overlay-60 ${marginStyle}`} />
  }

  const getTitleStyle = () => {
    if (type === 'narrow') return 'w-[96px] min-w-[96px]'
    return `w-[68px] min-w-[68px] ${divider !== 'none' && 'pl-16'}`
  }

  return (
    <div className="flex w-full">
      {renderDivider()}
      <div className="w-full px-8">
        <div
          className={`flex items-center ${
            value && 'justify-between'
          } py-8 px-8 gap-8 rounded-md hover:bg-light-overlay-5 active:bg-light-overlay-10 cursor-pointer`}
          onClick={handleClickContainer}
        >
          <div className={`flex ${value && 'flex-grow'} items-center gap-8 truncate`}>
            {label && (
              <Tooltip content={label} visibleOnOverflow>
                <div className={`${getTitleStyle()} text-light-overlay-60 truncate`}>{label}</div>
              </Tooltip>
            )}
            {leftContent}
            {value && <div className={`text-white truncate ${valueClassName}`}>{value}</div>}
            {rightContent}
          </div>
          {showDetailsButton && (
            <LinkSwitcher className="text-primary-40 flex-shrink-0" onClick={handleClick}>
              {t('inspect.details')}
            </LinkSwitcher>
          )}
        </div>
      </div>
    </div>
  )
}

export default InspectRow
