import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useApolloClient } from '@apollo/client'
import { LoadingStatus } from '@phase-software/types'

import { ProjectFieldsFragment, ProjectFieldsFragmentDoc } from '../../generated/graphql'
import { useWorkspaceActions } from '../../hooks/useWorkspaceActions'
import { useWorkspaceContext } from '../../providers/WorkspaceContextProvider'
import { projectNameValidator } from '../../utils/validator'
import { Dialog, InputField } from '../shared'
import { InputFieldRefProps } from '../shared/InputField'

type RenameProjectDialogProps = {
  id: ProjectFieldsFragment['id']
  originalName: string
  open: boolean
  onClose: () => void
}

const RenameProjectDialog = ({ id, originalName, open, onClose }: RenameProjectDialogProps) => {
  const { t } = useTranslation(['workspace', 'common'])
  const client = useApolloClient()
  const { workspaceData } = useWorkspaceContext()
  const { renameProject } = useWorkspaceActions(workspaceData.type, workspaceData.id)

  const inputRef = useRef<InputFieldRefProps>(null)
  const [name, setName] = useState<string>(originalName)
  const [isRenaming, setIsRenaming] = useState(false)

  const isRenameButtonDisabled = isRenaming || !!projectNameValidator(name, t)

  useEffect(() => {
    if (open && inputRef.current) {
      inputRef.current.focus()
      inputRef.current.select()
    }
  }, [open])

  useEffect(() => {
    setName(originalName)
  }, [originalName])

  const handleChangeName = (newName: string) => {
    setName(newName)
  }

  const handleRename = async () => {
    if (isRenameButtonDisabled) return
    setIsRenaming(true)
    try {
      await renameProject(id, name)

      client.cache.updateFragment(
        {
          id: `projects:${id}`,
          fragment: ProjectFieldsFragmentDoc,
          fragmentName: 'projectFields'
        },
        (data) => ({ ...data, name })
      )
      onClose()
    } catch (error) {
      console.error(error)
    } finally {
      setIsRenaming(false)
    }
  }

  const handleClose = () => {
    setName(originalName)
    onClose()
  }

  return (
    <Dialog
      data-test-id="rename-dialog"
      size="xs"
      title={t('workspace:rename_project')}
      showProgressButton
      progressStatus={isRenaming ? LoadingStatus.WAITING : LoadingStatus.INITIAL}
      confirmBtnText={t('common:button.rename')}
      cancelBtnText={t('common:button.cancel')}
      onConfirm={handleRename}
      onCancel={handleClose}
      disableConfirm={isRenameButtonDisabled}
      open={open}
    >
      <InputField
        ref={inputRef}
        type="text"
        value={name}
        placeholder={t('workspace:project_name')}
        validator={(value) => projectNameValidator(value, t)}
        onInput={handleChangeName}
        onEnterKey={handleRename}
        labelClassName="mb-0"
      />
    </Dialog>
  )
}

export default RenameProjectDialog
