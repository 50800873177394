import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Events } from '@phase-software/data-store'
import { LoadingStatus, MediaType, ToolType } from '@phase-software/types'

import { CONFIRMATION_MAP } from '../../constant'
import { useCommentContext } from '../../providers/CommentProvider'
import { useSetConfirmation } from '../../providers/ConfirmationProvider'
import { getModalSnapshot, useModalActions } from '../../providers/ModalProvider'
import { useSetNotification } from '../../providers/NotificationProvider'
import { useProfile } from '../../providers/ProfileProvider'
import { useSetClipboard } from '../../providers/dataStore/ClipboardProvider'
import { useDataStore, useDataStoreActions } from '../../providers/dataStore/DataStoreProvider'
import { useInteractionActions } from '../../providers/dataStore/InteractionProvider'
import { useTool, useToolActions } from '../../providers/dataStore/ToolProvider'
import { useSetUI, useUI, useUIActions } from '../../providers/dataStore/UIProvider'

const getExportFilename = (fileName, type) => {
  switch (type) {
    case MediaType.GIF:
      return `${fileName}.gif`
    case MediaType.MP4:
      return `${fileName}.mp4`
    case MediaType.LOTTIE:
      return `${fileName}.json`
    case MediaType.DOTLOTTIE:
      return `${fileName}.lottie`
  }
}

const SubscribeEamEvent = () => {
  const profile = useProfile()
  const preferenceKey = `preference_${profile.id}`

  const dataStore = useDataStore()
  const { mode, isEditingState, isTablingState, isInspectingState } = useUI()
  const {
    changeExportProgress,
    changeExportStatus,
    toggleInterface,
    leaveVersionPreview,
    leaveInspectingMode,
    enterInspectingMode,
    setEditOrigin
  } = useUIActions()
  const { closeModal } = useModalActions()
  const {
    selectKeyframeSelection,
    groupElementList,
    booleanGroupElementList,
    maskGroupElementList,
    ungroupElementList,
    deleteSelectedElements,
    moveElementListToTop,
    moveElementListToBottom,
    moveElementListUp,
    moveElementListDown,
    commitUndo
  } = useDataStoreActions()
  const { t } = useTranslation()
  const { deleteSelectedKeyFrame, duplicateSelectedKeyFrame } = useInteractionActions()
  const { addNotification } = useSetNotification()
  const { showConfirmation } = useSetConfirmation()
  const { copy } = useSetClipboard()
  const { isCommentVisible, setCommentVisibility } = useCommentContext()
  const setUI = useSetUI()
  const { activeTool } = useTool()
  const { setLastGeneralTool, setActiveTool } = useToolActions()

  useEffect(() => {
    if (!dataStore) return

    const handleCloseModal = (targetModalKey) => {
      const modals = getModalSnapshot()
      const targetModal = modals[targetModalKey] || modals[`PaintModal-${targetModalKey}`]
      if (!targetModal) return

      const isDialog = targetModalKey.endsWith('Dialog')
      const isOpen = targetModal.open

      if (!isDialog && isOpen) {
        closeModal(targetModalKey)
        dataStore.selection.set('focusedLayer', null)
      }
    }

    const handleRemoveKeyframeSelection = () => {
      selectKeyframeSelection([])
    }

    const handleGroup = (containerType) => {
      groupElementList(containerType)
    }
    const handleBooleanGroup = (booleanType) => {
      booleanGroupElementList(booleanType)
    }
    const handleMaskGroup = () => {
      maskGroupElementList()
    }
    const handleUnGroup = () => {
      ungroupElementList()
    }
    const handleToggleVisible = () => {
      dataStore.toggleElementsVisible()
    }
    const handleToggleLock = () => {
      dataStore.toggleElementsLocked()
    }

    const handleDuplicateKeyframe = () => {
      const duplicatedKeyframes = duplicateSelectedKeyFrame()

      if (duplicatedKeyframes.length > 0) {
        selectKeyframeSelection(duplicatedKeyframes)
        commitUndo()
      }
    }

    const handleDeleteElement = () => {
      deleteSelectedElements()
    }
    const handleDeleteKeyframe = () => {
      const modals = getModalSnapshot()
      const focusedLayerId = dataStore.selection.get('focusedLayer')

      if (focusedLayerId && modals[`PaintModal-${focusedLayerId}`]?.open) {
        const computedFillsKeys = [...dataStore.editor.getLayerItem(focusedLayerId).computedLayerSet].reduce(
          (acc, cur) => {
            // only check non-base layer tracks are going to be deleted
            if (cur.get('trackId')) {
              acc.push(cur.get('trackId'))
            }
            return acc
          },
          []
        )

        const kfs = dataStore.selection.get('kfs')
        for (const kfId of kfs) {
          const keyframe = dataStore.interaction.getKeyFrame(kfId)
          if (keyframe) {
            const propertyTrack = dataStore.interaction.getPropertyTrack(keyframe.trackId)
            if (computedFillsKeys.includes(propertyTrack.parentId)) {
              dataStore.selection.set('focusedLayer', null)
              break
            }
          }
        }
      }
      deleteSelectedKeyFrame()
    }
    const handleNotification = (info) => {
      addNotification({
        ...info,
        // handle the case where the content is not a translation key
        content: t(info.content, info.content)
      })
    }

    const handleShowConfirmation = (confirmationType) => {
      const confirmation = CONFIRMATION_MAP[confirmationType]
      const preference = JSON.parse(localStorage.getItem(preferenceKey) || '{}')

      const onConfirm = (rememberChoice) => {
        if (confirmation.key && rememberChoice) {
          preference[confirmation.key] = true
          localStorage.setItem(preferenceKey, JSON.stringify(preference))
        }
      }

      if (preference[confirmation.key]) {
        return
      }

      showConfirmation({ ...confirmation, onConfirm })
    }

    const handlePlayAnimation = () => {
      dataStore.transition.play()
    }
    const handleStopAnimation = () => {
      dataStore.transition.stop()
    }
    const handleResetAnimation = () => {
      dataStore.transition.reset()
    }

    const handleCancelExport = () => {
      changeExportProgress(0)
      changeExportStatus(LoadingStatus.INITIAL)
    }

    const handleExportProgress = (progress) => {
      changeExportProgress(progress)
    }

    const handleExportFinish = (file, options) => {
      changeExportStatus(LoadingStatus.FINISHED)

      setTimeout(() => {
        const link = document.createElement('a')
        link.href = file
        link.download = getExportFilename(options.fileName, options.type)
        link.click()
        handleCancelExport()
      }, 1000)
    }

    const handleToggleInterface = () => {
      setUI((s) => ({
        ...s,
        isContentPanelHidden: !s.isContentPanelHidden,
        isRightPanelHidden: !s.isRightPanelHidden,
        isToolbarHidden: !s.isToolbarHidden,
        isVersionHistoryPanelHidden: !s.isVersionHistoryPanelHidden,
        isActionPanelHidden: !s.isActionPanelHidden
      }))
    }

    const handleToggleCommentVisibility = () => {
      if (isCommentVisible && activeTool === ToolType.COMMENT) {
        (isEditingState || isTablingState || isInspectingState) ? setLastGeneralTool() : setActiveTool(ToolType.HAND)
      }
      setCommentVisibility(!isCommentVisible)
    }

    const handleToggleRuler = () => {
      setUI((s) => ({ ...s, hideRuler: !s.hideRuler }))
    }

    const handleToggleOrigin = () => {
      setUI((s) => ({ ...s, hideOrigin: !s.hideOrigin }))
    }

    const handleSnapToPixelGrid = () => {
      setUI((s) => ({ ...s, snapToPixelGrid: !s.snapToPixelGrid }))
    }

    const handleSnapToObject = () => {
      setUI((s) => ({ ...s, snapToObject: !s.snapToObject }))
    }

    const handleCopy = copy

    const handleBringToFront = () => {
      const elements = dataStore.selection.get('elements')
      moveElementListToTop(elements.map((el) => el.get('id')))
    }

    const handleSendToBack = () => {
      const elements = dataStore.selection.get('elements')
      moveElementListToBottom(elements.map((el) => el.get('id')))
    }

    const handleMoveForward = () => {
      const elements = dataStore.selection.get('elements')
      moveElementListUp(elements.map((el) => el.get('id')))
    }

    const handleMoveBackward = () => {
      const elements = dataStore.selection.get('elements')
      moveElementListDown(elements.map((el) => el.get('id')))
    }

    const handleEditOrigin = (bool) => {
      setEditOrigin(bool)
    }

    const handleToggleInspecting = () => {
      if (isInspectingState) {
        leaveInspectingMode()
      } else if (isEditingState || isTablingState) {
        enterInspectingMode()
      }
    }

    dataStore.eam.on(Events.GROUP_ELEMENTS, handleGroup)
    dataStore.eam.on(Events.BOOLEAN_GROUP_ELEMENTS, handleBooleanGroup)
    dataStore.eam.on(Events.MASK_GROUP_ELEMENTS, handleMaskGroup)
    dataStore.eam.on(Events.UNGROUP_ELEMENTS, handleUnGroup)
    dataStore.eam.on(Events.DUPLICATE_KEYFRAME, handleDuplicateKeyframe)
    dataStore.eam.on(Events.DELETE_ELEMENT, handleDeleteElement)
    dataStore.eam.on(Events.DELETE_KEYFRAME, handleDeleteKeyframe)
    dataStore.eam.on(Events.CLOSE_MODAL, handleCloseModal)
    dataStore.eam.on(Events.DESELECT_KEYFRAME, handleRemoveKeyframeSelection)
    dataStore.eam.on(Events.PLAY_ANIMATION, handlePlayAnimation)
    dataStore.eam.on(Events.STOP_ANIMATION, handleStopAnimation)
    dataStore.eam.on(Events.RESET_ANIMATION, handleResetAnimation)
    dataStore.eam.on(Events.SHOW_NOTIFICATION, handleNotification)
    dataStore.eam.on(Events.EXPORT_PROGRESS, handleExportProgress)
    dataStore.eam.on(Events.EXPORT_FINISH, handleExportFinish)
    dataStore.eam.on(Events.CANCEL_EXPORT_MEDIA, handleCancelExport)
    dataStore.eam.on(Events.SHOW_CONFIRMATION, handleShowConfirmation)
    dataStore.eam.on(Events.TOGGLE_INTERFACE, handleToggleInterface)
    dataStore.eam.on(Events.TOGGLE_COMMENT_VISIBILITY, handleToggleCommentVisibility)
    dataStore.eam.on(Events.TOGGLE_RULER, handleToggleRuler)
    dataStore.eam.on(Events.TOGGLE_ORIGIN, handleToggleOrigin)
    dataStore.eam.on(Events.TOGGLE_SNAP_TO_PIXEL_GRID, handleSnapToPixelGrid)
    dataStore.eam.on(Events.TOGGLE_SNAP_TO_OBJECT, handleSnapToObject)
    dataStore.eam.on(Events.COPY, handleCopy)
    dataStore.eam.on(Events.TOGGLE_VISIBLE, handleToggleVisible)
    dataStore.eam.on(Events.TOGGLE_LOCK, handleToggleLock)
    dataStore.eam.on(Events.BRING_TO_FRONT, handleBringToFront)
    dataStore.eam.on(Events.SEND_TO_BACK, handleSendToBack)
    dataStore.eam.on(Events.MOVE_FORWARD, handleMoveForward)
    dataStore.eam.on(Events.MOVE_BACKWARD, handleMoveBackward)
    dataStore.eam.on(Events.LEAVE_VERSION_PREVIEW, leaveVersionPreview)
    dataStore.eam.on(Events.EDIT_ORIGIN, handleEditOrigin)
    dataStore.eam.on(Events.TOGGLE_INSPECTING, handleToggleInspecting)

    return () => {
      dataStore.eam.off(Events.GROUP_ELEMENTS, handleGroup)
      dataStore.eam.off(Events.BOOLEAN_GROUP_ELEMENTS, handleBooleanGroup)
      dataStore.eam.off(Events.MASK_GROUP_ELEMENTS, handleMaskGroup)
      dataStore.eam.off(Events.UNGROUP_ELEMENTS, handleUnGroup)
      dataStore.eam.off(Events.DELETE_ELEMENT, handleDeleteElement)
      dataStore.eam.off(Events.DELETE_KEYFRAME, handleDeleteKeyframe)
      dataStore.eam.off(Events.CLOSE_MODAL, handleCloseModal)
      dataStore.eam.off(Events.DESELECT_KEYFRAME, handleRemoveKeyframeSelection)
      dataStore.eam.off(Events.PLAY_ANIMATION, handlePlayAnimation)
      dataStore.eam.off(Events.STOP_ANIMATION, handleStopAnimation)
      dataStore.eam.off(Events.RESET_ANIMATION, handleResetAnimation)
      dataStore.eam.off(Events.SHOW_NOTIFICATION, handleNotification)
      dataStore.eam.off(Events.EXPORT_PROGRESS, handleExportProgress)
      dataStore.eam.off(Events.EXPORT_FINISH, handleExportFinish)
      dataStore.eam.off(Events.CANCEL_EXPORT_MEDIA, handleCancelExport)
      dataStore.eam.off(Events.SHOW_CONFIRMATION, handleShowConfirmation)
      dataStore.eam.off(Events.TOGGLE_INTERFACE, handleToggleInterface)
      dataStore.eam.off(Events.TOGGLE_COMMENT_VISIBILITY, handleToggleCommentVisibility)
      dataStore.eam.off(Events.TOGGLE_RULER, handleToggleRuler)
      dataStore.eam.off(Events.TOGGLE_ORIGIN, handleToggleOrigin)
      dataStore.eam.off(Events.TOGGLE_SNAP_TO_PIXEL_GRID, handleSnapToPixelGrid)
      dataStore.eam.off(Events.TOGGLE_SNAP_TO_OBJECT, handleSnapToObject)
      dataStore.eam.off(Events.COPY, handleCopy)
      dataStore.eam.off(Events.TOGGLE_VISIBLE, handleToggleVisible)
      dataStore.eam.off(Events.TOGGLE_LOCK, handleToggleLock)
      dataStore.eam.off(Events.BRING_TO_FRONT, handleBringToFront)
      dataStore.eam.off(Events.SEND_TO_BACK, handleSendToBack)
      dataStore.eam.off(Events.MOVE_FORWARD, handleMoveForward)
      dataStore.eam.off(Events.MOVE_BACKWARD, handleMoveBackward)
      dataStore.eam.off(Events.LEAVE_VERSION_PREVIEW, leaveVersionPreview)
      dataStore.eam.off(Events.EDIT_ORIGIN, handleEditOrigin)
      dataStore.eam.off(Events.TOGGLE_INSPECTING, handleToggleInspecting)
    }
  }, [
    mode,
    isEditingState,
    isTablingState,
    isInspectingState,
    setActiveTool,
    setLastGeneralTool,
    dataStore,
    changeExportStatus,
    toggleInterface,
    isCommentVisible,
    setCommentVisibility,
    activeTool,
    copy,
    moveElementListToTop,
    moveElementListToBottom,
    moveElementListUp,
    moveElementListDown,
    setUI,
    addNotification,
    booleanGroupElementList,
    closeModal,
    commitUndo,
    deleteSelectedElements,
    deleteSelectedKeyFrame,
    duplicateSelectedKeyFrame,
    groupElementList,
    maskGroupElementList,
    preferenceKey,
    selectKeyframeSelection,
    showConfirmation,
    ungroupElementList,
    changeExportProgress,
    leaveVersionPreview,
    setEditOrigin,
    enterInspectingMode,
    leaveInspectingMode,
    t
  ])

  return null
}

export default SubscribeEamEvent
