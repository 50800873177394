/* tslint:disable */
/* eslint-disable */
/**
 * PHASE API
 * Phase API service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateAutoVersionResponse,
  CreateFileFromAVersionRequest,
  CreateFileFromAVersionResponse,
  CreateNamedVersionRequestV1,
  CreateNamedVersionResponse,
  CreateProjectFileRequest,
  CreateProjectFileResponse,
  CreateVersionContributorRequest,
  CreateVersionContributorResponse,
  DownloadContentResponse,
  DownloadContentResponseV2,
  DuplicateProjectFileRequest,
  DuplicateProjectFileResponse,
  DuplicateTemplateRequest,
  DuplicateTemplateResponse,
  FilePermissionResponse,
  GenerateFileOperateUrlRequest,
  GenerateFileOperateUrlResponse,
  ImportProjectFileResponse,
  ImportProjectImageResponse,
  MoveFileBetweenProjectsRequest,
  MoveFileBetweenProjectsResponse,
  PatchFileRequest,
  ResetVersionResponse,
  RestoreVersionRequest,
  UpdateContributorResponse,
  UpdateVersionRequestV1,
  UpdateVersionResponse,
  UploadContentRequest,
} from '../models';

export interface ProjectFileControllerCreateAutoVersionRequest {
    projectId: string;
    fileId: string;
}

export interface ProjectFileControllerCreateFileRequest {
    projectId: string;
    createProjectFileRequest: CreateProjectFileRequest;
}

export interface ProjectFileControllerCreateFileFromAVersionRequest {
    projectId: string;
    fileId: string;
    versionId: string;
    createFileFromAVersionRequest: CreateFileFromAVersionRequest;
}

export interface ProjectFileControllerCreateNamedVersionRequest {
    projectId: string;
    fileId: string;
    createNamedVersionRequestV1: CreateNamedVersionRequestV1;
}

export interface ProjectFileControllerCreateVersionContributorRequest {
    fileId: string;
    createVersionContributorRequest: CreateVersionContributorRequest;
}

export interface ProjectFileControllerDeleteFileRequest {
    projectId: string;
    fileId: string;
}

export interface ProjectFileControllerDownloadContentRequest {
    projectId: string;
    fileId: string;
}

export interface ProjectFileControllerDownloadContentV2Request {
    projectId: string;
    fileId: string;
}

export interface ProjectFileControllerDownloadContentV3Request {
    projectId: string;
    fileId: string;
}

export interface ProjectFileControllerDuplicateFileRequest {
    projectId: string;
    fileId: string;
    duplicateProjectFileRequest: DuplicateProjectFileRequest;
}

export interface ProjectFileControllerDuplicateFileV3Request {
    projectId: string;
    fileId: string;
    duplicateProjectFileRequest: DuplicateProjectFileRequest;
}

export interface ProjectFileControllerDuplicateTemplateRequest {
    projectId: string;
    fileId: string;
    duplicateTemplateRequest: DuplicateTemplateRequest;
}

export interface ProjectFileControllerGenerateFileOperateUrlRequest {
    projectId: string;
    fileId: string;
    generateFileOperateUrlRequest: GenerateFileOperateUrlRequest;
}

export interface ProjectFileControllerGenerateImageOperateUrlRequest {
    projectId: string;
    fileId: string;
    generateFileOperateUrlRequest: GenerateFileOperateUrlRequest;
}

export interface ProjectFileControllerGetMyPermissionsRequest {
    projectId: string;
    fileId: string;
}

export interface ProjectFileControllerGetVersionRequest {
    projectId: string;
    fileId: string;
    versionId: string;
}

export interface ProjectFileControllerImportFileV2Request {
    projectId: string;
    content: Blob;
    name: string;
    description?: string;
    type?: number;
}

export interface ProjectFileControllerImportImageV1Request {
    projectId: string;
    fileId: string;
    content: any;
    name: string;
}

export interface ProjectFileControllerMoveFileBetweenProjectsRequest {
    projectId: string;
    fileId: string;
    moveFileBetweenProjectsRequest: MoveFileBetweenProjectsRequest;
}

export interface ProjectFileControllerPatchFileRequest {
    projectId: string;
    fileId: string;
    patchFileRequest: PatchFileRequest;
}

export interface ProjectFileControllerResetVersionRequest {
    projectId: string;
    fileId: string;
    versionId: string;
}

export interface ProjectFileControllerRestoreVersionRequest {
    projectId: string;
    fileId: string;
    versionId: string;
    restoreVersionRequest: RestoreVersionRequest;
}

export interface ProjectFileControllerServeImageRequest {
    imageId: string;
    w?: number;
    h?: number;
}

export interface ProjectFileControllerUpdateContributorRequest {
    fileId: string;
    versionId: string;
}

export interface ProjectFileControllerUpdateVersionRequest {
    projectId: string;
    fileId: string;
    versionId: string;
    updateVersionRequestV1: UpdateVersionRequestV1;
}

export interface ProjectFileControllerUploadContentRequest {
    projectId: string;
    fileId: string;
    uploadContentRequest: UploadContentRequest;
}

export interface ProjectFileControllerUploadContentV2Request {
    projectId: string;
    fileId: string;
    content: Blob;
}

export interface ProjectFileControllerUploadContentV3Request {
    projectId: string;
    fileId: string;
    content: Blob;
}

/**
 * 
 */
export class ProjectFileApi extends runtime.BaseAPI {

    /**
     */
    async projectFileControllerCreateAutoVersionRaw(requestParameters: ProjectFileControllerCreateAutoVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateAutoVersionResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectFileControllerCreateAutoVersion.');
        }

        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling projectFileControllerCreateAutoVersion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/_private/v1/projects/{projectId}/files/{fileId}/versions:createAutoVersion`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async projectFileControllerCreateAutoVersion(requestParameters: ProjectFileControllerCreateAutoVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateAutoVersionResponse> {
        const response = await this.projectFileControllerCreateAutoVersionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectFileControllerCreateFileRaw(requestParameters: ProjectFileControllerCreateFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateProjectFileResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectFileControllerCreateFile.');
        }

        if (requestParameters.createProjectFileRequest === null || requestParameters.createProjectFileRequest === undefined) {
            throw new runtime.RequiredError('createProjectFileRequest','Required parameter requestParameters.createProjectFileRequest was null or undefined when calling projectFileControllerCreateFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/projects/{projectId}/files`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.createProjectFileRequest,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async projectFileControllerCreateFile(requestParameters: ProjectFileControllerCreateFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateProjectFileResponse> {
        const response = await this.projectFileControllerCreateFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectFileControllerCreateFileFromAVersionRaw(requestParameters: ProjectFileControllerCreateFileFromAVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateFileFromAVersionResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectFileControllerCreateFileFromAVersion.');
        }

        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling projectFileControllerCreateFileFromAVersion.');
        }

        if (requestParameters.versionId === null || requestParameters.versionId === undefined) {
            throw new runtime.RequiredError('versionId','Required parameter requestParameters.versionId was null or undefined when calling projectFileControllerCreateFileFromAVersion.');
        }

        if (requestParameters.createFileFromAVersionRequest === null || requestParameters.createFileFromAVersionRequest === undefined) {
            throw new runtime.RequiredError('createFileFromAVersionRequest','Required parameter requestParameters.createFileFromAVersionRequest was null or undefined when calling projectFileControllerCreateFileFromAVersion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/projects/{projectId}/files/{fileId}/versions/{versionId}:createFile`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))).replace(`{${"versionId"}}`, encodeURIComponent(String(requestParameters.versionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.createFileFromAVersionRequest,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async projectFileControllerCreateFileFromAVersion(requestParameters: ProjectFileControllerCreateFileFromAVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateFileFromAVersionResponse> {
        const response = await this.projectFileControllerCreateFileFromAVersionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectFileControllerCreateNamedVersionRaw(requestParameters: ProjectFileControllerCreateNamedVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateNamedVersionResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectFileControllerCreateNamedVersion.');
        }

        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling projectFileControllerCreateNamedVersion.');
        }

        if (requestParameters.createNamedVersionRequestV1 === null || requestParameters.createNamedVersionRequestV1 === undefined) {
            throw new runtime.RequiredError('createNamedVersionRequestV1','Required parameter requestParameters.createNamedVersionRequestV1 was null or undefined when calling projectFileControllerCreateNamedVersion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/projects/{projectId}/files/{fileId}/versions:createNamedVersion`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.createNamedVersionRequestV1,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async projectFileControllerCreateNamedVersion(requestParameters: ProjectFileControllerCreateNamedVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateNamedVersionResponse> {
        const response = await this.projectFileControllerCreateNamedVersionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectFileControllerCreateVersionContributorRaw(requestParameters: ProjectFileControllerCreateVersionContributorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateVersionContributorResponse>> {
        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling projectFileControllerCreateVersionContributor.');
        }

        if (requestParameters.createVersionContributorRequest === null || requestParameters.createVersionContributorRequest === undefined) {
            throw new runtime.RequiredError('createVersionContributorRequest','Required parameter requestParameters.createVersionContributorRequest was null or undefined when calling projectFileControllerCreateVersionContributor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/_private/v1/files/{fileId}/versions:createVersionContributor`.replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.createVersionContributorRequest,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async projectFileControllerCreateVersionContributor(requestParameters: ProjectFileControllerCreateVersionContributorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateVersionContributorResponse> {
        const response = await this.projectFileControllerCreateVersionContributorRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectFileControllerDeleteFileRaw(requestParameters: ProjectFileControllerDeleteFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectFileControllerDeleteFile.');
        }

        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling projectFileControllerDeleteFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/projects/{projectId}/files/{fileId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async projectFileControllerDeleteFile(requestParameters: ProjectFileControllerDeleteFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.projectFileControllerDeleteFileRaw(requestParameters, initOverrides);
    }

    /**
     */
    async projectFileControllerDownloadContentRaw(requestParameters: ProjectFileControllerDownloadContentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DownloadContentResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectFileControllerDownloadContent.');
        }

        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling projectFileControllerDownloadContent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/projects/{projectId}/files/{fileId}:downloadContent`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async projectFileControllerDownloadContent(requestParameters: ProjectFileControllerDownloadContentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DownloadContentResponse> {
        const response = await this.projectFileControllerDownloadContentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectFileControllerDownloadContentV2Raw(requestParameters: ProjectFileControllerDownloadContentV2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DownloadContentResponseV2>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectFileControllerDownloadContentV2.');
        }

        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling projectFileControllerDownloadContentV2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v2/projects/{projectId}/files/{fileId}:downloadContent`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async projectFileControllerDownloadContentV2(requestParameters: ProjectFileControllerDownloadContentV2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DownloadContentResponseV2> {
        const response = await this.projectFileControllerDownloadContentV2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectFileControllerDownloadContentV3Raw(requestParameters: ProjectFileControllerDownloadContentV3Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DownloadContentResponseV2>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectFileControllerDownloadContentV3.');
        }

        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling projectFileControllerDownloadContentV3.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/projects/{projectId}/files/{fileId}:downloadContent`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async projectFileControllerDownloadContentV3(requestParameters: ProjectFileControllerDownloadContentV3Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DownloadContentResponseV2> {
        const response = await this.projectFileControllerDownloadContentV3Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Duplicate target file to user personal draft
     */
    async projectFileControllerDuplicateFileRaw(requestParameters: ProjectFileControllerDuplicateFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DuplicateProjectFileResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectFileControllerDuplicateFile.');
        }

        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling projectFileControllerDuplicateFile.');
        }

        if (requestParameters.duplicateProjectFileRequest === null || requestParameters.duplicateProjectFileRequest === undefined) {
            throw new runtime.RequiredError('duplicateProjectFileRequest','Required parameter requestParameters.duplicateProjectFileRequest was null or undefined when calling projectFileControllerDuplicateFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/projects/{projectId}/files/{fileId}:duplicate`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.duplicateProjectFileRequest,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Duplicate target file to user personal draft
     */
    async projectFileControllerDuplicateFile(requestParameters: ProjectFileControllerDuplicateFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DuplicateProjectFileResponse> {
        const response = await this.projectFileControllerDuplicateFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Duplicate target file to user personal draft - v3
     */
    async projectFileControllerDuplicateFileV3Raw(requestParameters: ProjectFileControllerDuplicateFileV3Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DuplicateProjectFileResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectFileControllerDuplicateFileV3.');
        }

        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling projectFileControllerDuplicateFileV3.');
        }

        if (requestParameters.duplicateProjectFileRequest === null || requestParameters.duplicateProjectFileRequest === undefined) {
            throw new runtime.RequiredError('duplicateProjectFileRequest','Required parameter requestParameters.duplicateProjectFileRequest was null or undefined when calling projectFileControllerDuplicateFileV3.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/projects/{projectId}/files/{fileId}:duplicate`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.duplicateProjectFileRequest,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Duplicate target file to user personal draft - v3
     */
    async projectFileControllerDuplicateFileV3(requestParameters: ProjectFileControllerDuplicateFileV3Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DuplicateProjectFileResponse> {
        const response = await this.projectFileControllerDuplicateFileV3Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Duplicate file from Template library (UI)
     */
    async projectFileControllerDuplicateTemplateRaw(requestParameters: ProjectFileControllerDuplicateTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DuplicateTemplateResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectFileControllerDuplicateTemplate.');
        }

        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling projectFileControllerDuplicateTemplate.');
        }

        if (requestParameters.duplicateTemplateRequest === null || requestParameters.duplicateTemplateRequest === undefined) {
            throw new runtime.RequiredError('duplicateTemplateRequest','Required parameter requestParameters.duplicateTemplateRequest was null or undefined when calling projectFileControllerDuplicateTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/projects/{projectId}/files/{fileId}:duplicateTemplate`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.duplicateTemplateRequest,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Duplicate file from Template library (UI)
     */
    async projectFileControllerDuplicateTemplate(requestParameters: ProjectFileControllerDuplicateTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DuplicateTemplateResponse> {
        const response = await this.projectFileControllerDuplicateTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Figma plugin
     */
    async projectFileControllerFigmaExportRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/figma/export`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Figma plugin
     */
    async projectFileControllerFigmaExport(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.projectFileControllerFigmaExportRaw(initOverrides);
    }

    /**
     */
    async projectFileControllerGenerateFileOperateUrlRaw(requestParameters: ProjectFileControllerGenerateFileOperateUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GenerateFileOperateUrlResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectFileControllerGenerateFileOperateUrl.');
        }

        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling projectFileControllerGenerateFileOperateUrl.');
        }

        if (requestParameters.generateFileOperateUrlRequest === null || requestParameters.generateFileOperateUrlRequest === undefined) {
            throw new runtime.RequiredError('generateFileOperateUrlRequest','Required parameter requestParameters.generateFileOperateUrlRequest was null or undefined when calling projectFileControllerGenerateFileOperateUrl.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/projects/{projectId}/files/{fileId}:generateFileOperateUrl`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.generateFileOperateUrlRequest,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async projectFileControllerGenerateFileOperateUrl(requestParameters: ProjectFileControllerGenerateFileOperateUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GenerateFileOperateUrlResponse> {
        const response = await this.projectFileControllerGenerateFileOperateUrlRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectFileControllerGenerateImageOperateUrlRaw(requestParameters: ProjectFileControllerGenerateImageOperateUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GenerateFileOperateUrlResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectFileControllerGenerateImageOperateUrl.');
        }

        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling projectFileControllerGenerateImageOperateUrl.');
        }

        if (requestParameters.generateFileOperateUrlRequest === null || requestParameters.generateFileOperateUrlRequest === undefined) {
            throw new runtime.RequiredError('generateFileOperateUrlRequest','Required parameter requestParameters.generateFileOperateUrlRequest was null or undefined when calling projectFileControllerGenerateImageOperateUrl.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/projects/{projectId}/files/{fileId}:generateImageOperateUrl`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.generateFileOperateUrlRequest,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async projectFileControllerGenerateImageOperateUrl(requestParameters: ProjectFileControllerGenerateImageOperateUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GenerateFileOperateUrlResponse> {
        const response = await this.projectFileControllerGenerateImageOperateUrlRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectFileControllerGetMyPermissionsRaw(requestParameters: ProjectFileControllerGetMyPermissionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FilePermissionResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectFileControllerGetMyPermissions.');
        }

        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling projectFileControllerGetMyPermissions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/projects/{projectId}/files/{fileId}:getMyPermissions`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async projectFileControllerGetMyPermissions(requestParameters: ProjectFileControllerGetMyPermissionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FilePermissionResponse> {
        const response = await this.projectFileControllerGetMyPermissionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Handler of Hasura Action: query team files with user permission setting
     */
    async projectFileControllerGetTeamProjectFilesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/hasura/getTeamProjectFiles`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Handler of Hasura Action: query team files with user permission setting
     */
    async projectFileControllerGetTeamProjectFiles(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.projectFileControllerGetTeamProjectFilesRaw(initOverrides);
    }

    /**
     * Handler of Hasura Action: query team project id list with user permission setting
     */
    async projectFileControllerGetTeamProjectIdsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/hasura/getTeamProjectIds`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Handler of Hasura Action: query team project id list with user permission setting
     */
    async projectFileControllerGetTeamProjectIds(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.projectFileControllerGetTeamProjectIdsRaw(initOverrides);
    }

    /**
     * Download a file snapshot
     */
    async projectFileControllerGetVersionRaw(requestParameters: ProjectFileControllerGetVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectFileControllerGetVersion.');
        }

        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling projectFileControllerGetVersion.');
        }

        if (requestParameters.versionId === null || requestParameters.versionId === undefined) {
            throw new runtime.RequiredError('versionId','Required parameter requestParameters.versionId was null or undefined when calling projectFileControllerGetVersion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/projects/{projectId}/files/{fileId}/versions/{versionId}:getVersion`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))).replace(`{${"versionId"}}`, encodeURIComponent(String(requestParameters.versionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Download a file snapshot
     */
    async projectFileControllerGetVersion(requestParameters: ProjectFileControllerGetVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.projectFileControllerGetVersionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectFileControllerImportFileV2Raw(requestParameters: ProjectFileControllerImportFileV2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ImportProjectFileResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectFileControllerImportFileV2.');
        }

        if (requestParameters.content === null || requestParameters.content === undefined) {
            throw new runtime.RequiredError('content','Required parameter requestParameters.content was null or undefined when calling projectFileControllerImportFileV2.');
        }

        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling projectFileControllerImportFileV2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.content !== undefined) {
            formParams.append('content', requestParameters.content as any);
        }

        if (requestParameters.name !== undefined) {
            formParams.append('name', requestParameters.name as any);
        }

        if (requestParameters.description !== undefined) {
            formParams.append('description', requestParameters.description as any);
        }

        if (requestParameters.type !== undefined) {
            formParams.append('type', requestParameters.type as any);
        }

        const response = await this.request({
            path: `/api/v2/projects/{projectId}/files:import`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async projectFileControllerImportFileV2(requestParameters: ProjectFileControllerImportFileV2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ImportProjectFileResponse> {
        const response = await this.projectFileControllerImportFileV2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectFileControllerImportImageV1Raw(requestParameters: ProjectFileControllerImportImageV1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ImportProjectImageResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectFileControllerImportImageV1.');
        }

        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling projectFileControllerImportImageV1.');
        }

        if (requestParameters.content === null || requestParameters.content === undefined) {
            throw new runtime.RequiredError('content','Required parameter requestParameters.content was null or undefined when calling projectFileControllerImportImageV1.');
        }

        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling projectFileControllerImportImageV1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.content !== undefined) {

            formParams.append('content', new Blob([JSON.stringify(requestParameters.content)], { type: "application/json", }));
        }

        if (requestParameters.name !== undefined) {
            formParams.append('name', requestParameters.name as any);
        }

        const response = await this.request({
            path: `/api/v1/projects/{projectId}/files/{fileId}/images:import`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async projectFileControllerImportImageV1(requestParameters: ProjectFileControllerImportImageV1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ImportProjectImageResponse> {
        const response = await this.projectFileControllerImportImageV1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectFileControllerMoveFileBetweenProjectsRaw(requestParameters: ProjectFileControllerMoveFileBetweenProjectsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MoveFileBetweenProjectsResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectFileControllerMoveFileBetweenProjects.');
        }

        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling projectFileControllerMoveFileBetweenProjects.');
        }

        if (requestParameters.moveFileBetweenProjectsRequest === null || requestParameters.moveFileBetweenProjectsRequest === undefined) {
            throw new runtime.RequiredError('moveFileBetweenProjectsRequest','Required parameter requestParameters.moveFileBetweenProjectsRequest was null or undefined when calling projectFileControllerMoveFileBetweenProjects.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/projects/{projectId}/files/{fileId}:move`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.moveFileBetweenProjectsRequest,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async projectFileControllerMoveFileBetweenProjects(requestParameters: ProjectFileControllerMoveFileBetweenProjectsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MoveFileBetweenProjectsResponse> {
        const response = await this.projectFileControllerMoveFileBetweenProjectsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectFileControllerPatchFileRaw(requestParameters: ProjectFileControllerPatchFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectFileControllerPatchFile.');
        }

        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling projectFileControllerPatchFile.');
        }

        if (requestParameters.patchFileRequest === null || requestParameters.patchFileRequest === undefined) {
            throw new runtime.RequiredError('patchFileRequest','Required parameter requestParameters.patchFileRequest was null or undefined when calling projectFileControllerPatchFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/projects/{projectId}/files/{fileId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.patchFileRequest,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async projectFileControllerPatchFile(requestParameters: ProjectFileControllerPatchFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.projectFileControllerPatchFileRaw(requestParameters, initOverrides);
    }

    /**
     */
    async projectFileControllerResetVersionRaw(requestParameters: ProjectFileControllerResetVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResetVersionResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectFileControllerResetVersion.');
        }

        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling projectFileControllerResetVersion.');
        }

        if (requestParameters.versionId === null || requestParameters.versionId === undefined) {
            throw new runtime.RequiredError('versionId','Required parameter requestParameters.versionId was null or undefined when calling projectFileControllerResetVersion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/projects/{projectId}/files/{fileId}/versions/{versionId}:resetVersion`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))).replace(`{${"versionId"}}`, encodeURIComponent(String(requestParameters.versionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async projectFileControllerResetVersion(requestParameters: ProjectFileControllerResetVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResetVersionResponse> {
        const response = await this.projectFileControllerResetVersionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectFileControllerRestoreVersionRaw(requestParameters: ProjectFileControllerRestoreVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectFileControllerRestoreVersion.');
        }

        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling projectFileControllerRestoreVersion.');
        }

        if (requestParameters.versionId === null || requestParameters.versionId === undefined) {
            throw new runtime.RequiredError('versionId','Required parameter requestParameters.versionId was null or undefined when calling projectFileControllerRestoreVersion.');
        }

        if (requestParameters.restoreVersionRequest === null || requestParameters.restoreVersionRequest === undefined) {
            throw new runtime.RequiredError('restoreVersionRequest','Required parameter requestParameters.restoreVersionRequest was null or undefined when calling projectFileControllerRestoreVersion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/projects/{projectId}/files/{fileId}/versions/{versionId}:restoreVersion`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))).replace(`{${"versionId"}}`, encodeURIComponent(String(requestParameters.versionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.restoreVersionRequest,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async projectFileControllerRestoreVersion(requestParameters: ProjectFileControllerRestoreVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.projectFileControllerRestoreVersionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectFileControllerServeImageRaw(requestParameters: ProjectFileControllerServeImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.imageId === null || requestParameters.imageId === undefined) {
            throw new runtime.RequiredError('imageId','Required parameter requestParameters.imageId was null or undefined when calling projectFileControllerServeImage.');
        }

        const queryParameters: any = {};

        if (requestParameters.w !== undefined) {
            queryParameters['w'] = requestParameters.w;
        }

        if (requestParameters.h !== undefined) {
            queryParameters['h'] = requestParameters.h;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/images/{imageId}`.replace(`{${"imageId"}}`, encodeURIComponent(String(requestParameters.imageId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async projectFileControllerServeImage(requestParameters: ProjectFileControllerServeImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.projectFileControllerServeImageRaw(requestParameters, initOverrides);
    }

    /**
     */
    async projectFileControllerUpdateContributorRaw(requestParameters: ProjectFileControllerUpdateContributorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateContributorResponse>> {
        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling projectFileControllerUpdateContributor.');
        }

        if (requestParameters.versionId === null || requestParameters.versionId === undefined) {
            throw new runtime.RequiredError('versionId','Required parameter requestParameters.versionId was null or undefined when calling projectFileControllerUpdateContributor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/_private/v1/files/{fileId}/versions/{versionId}:updateContributor`.replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))).replace(`{${"versionId"}}`, encodeURIComponent(String(requestParameters.versionId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async projectFileControllerUpdateContributor(requestParameters: ProjectFileControllerUpdateContributorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateContributorResponse> {
        const response = await this.projectFileControllerUpdateContributorRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectFileControllerUpdateVersionRaw(requestParameters: ProjectFileControllerUpdateVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateVersionResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectFileControllerUpdateVersion.');
        }

        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling projectFileControllerUpdateVersion.');
        }

        if (requestParameters.versionId === null || requestParameters.versionId === undefined) {
            throw new runtime.RequiredError('versionId','Required parameter requestParameters.versionId was null or undefined when calling projectFileControllerUpdateVersion.');
        }

        if (requestParameters.updateVersionRequestV1 === null || requestParameters.updateVersionRequestV1 === undefined) {
            throw new runtime.RequiredError('updateVersionRequestV1','Required parameter requestParameters.updateVersionRequestV1 was null or undefined when calling projectFileControllerUpdateVersion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/projects/{projectId}/files/{fileId}/versions/{versionId}:updateVersion`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))).replace(`{${"versionId"}}`, encodeURIComponent(String(requestParameters.versionId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.updateVersionRequestV1,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async projectFileControllerUpdateVersion(requestParameters: ProjectFileControllerUpdateVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateVersionResponse> {
        const response = await this.projectFileControllerUpdateVersionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectFileControllerUploadContentRaw(requestParameters: ProjectFileControllerUploadContentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectFileControllerUploadContent.');
        }

        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling projectFileControllerUploadContent.');
        }

        if (requestParameters.uploadContentRequest === null || requestParameters.uploadContentRequest === undefined) {
            throw new runtime.RequiredError('uploadContentRequest','Required parameter requestParameters.uploadContentRequest was null or undefined when calling projectFileControllerUploadContent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/projects/{projectId}/files/{fileId}:uploadContent`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.uploadContentRequest,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async projectFileControllerUploadContent(requestParameters: ProjectFileControllerUploadContentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.projectFileControllerUploadContentRaw(requestParameters, initOverrides);
    }

    /**
     */
    async projectFileControllerUploadContentV2Raw(requestParameters: ProjectFileControllerUploadContentV2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectFileControllerUploadContentV2.');
        }

        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling projectFileControllerUploadContentV2.');
        }

        if (requestParameters.content === null || requestParameters.content === undefined) {
            throw new runtime.RequiredError('content','Required parameter requestParameters.content was null or undefined when calling projectFileControllerUploadContentV2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.content !== undefined) {
            formParams.append('content', requestParameters.content as any);
        }

        const response = await this.request({
            path: `/api/v2/projects/{projectId}/files/{fileId}:uploadContent`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async projectFileControllerUploadContentV2(requestParameters: ProjectFileControllerUploadContentV2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.projectFileControllerUploadContentV2Raw(requestParameters, initOverrides);
    }

    /**
     * Upload file with hot-cold storage
     */
    async projectFileControllerUploadContentV3Raw(requestParameters: ProjectFileControllerUploadContentV3Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectFileControllerUploadContentV3.');
        }

        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling projectFileControllerUploadContentV3.');
        }

        if (requestParameters.content === null || requestParameters.content === undefined) {
            throw new runtime.RequiredError('content','Required parameter requestParameters.content was null or undefined when calling projectFileControllerUploadContentV3.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.content !== undefined) {
            formParams.append('content', requestParameters.content as any);
        }

        const response = await this.request({
            path: `/api/v3/projects/{projectId}/files/{fileId}:uploadContent`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Upload file with hot-cold storage
     */
    async projectFileControllerUploadContentV3(requestParameters: ProjectFileControllerUploadContentV3Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.projectFileControllerUploadContentV3Raw(requestParameters, initOverrides);
    }

}
