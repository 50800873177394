import { IRAsset } from './IRAsset'
import { nodeTypeMapClass } from './utils'

// FIXME: support scene-tree like func
export class IRPrecompositionAsset extends IRAsset {
  static defaultValue = {
    id: '',
    children: []
  }

  constructor(data = IRPrecompositionAsset.defaultValue) {
    super(data)

    // FIXME: refactor with IRSceneTree
    // store the map for parenting
    this.maskMap = new Map()
    this.transformMap = new Map()
    this.nodeMap = new Map()

    this.children = []
  }

  fromJSON(data) {
    super.fromJSON(data)
    this.children = data.children.map(childData => {
      const parent = this.nodeMap.get(childData.parent)
      const NodeClass = nodeTypeMapClass[childData.type]
      const node = new NodeClass().fromJSON({ ...childData, parent })
      this.nodeMap.set(node.uid, node)
      if (!this.transformMap.has(node.id)) {
        this.transformMap.set(node.id, node.uid)
      }
      if (node.maskParent) {
        this.maskMap.set(node.uid, node.maskParent.uid)
        node.maskParent = this.nodeMap.get(node.maskParent)
      }
      return node
    })
    return this
  }

  toJSON() {
    return {
      ...super.toJSON(),
      children: this.children.map(childNode => childNode.toJSON())
    }
  }

  hasTransform(elementId) {
    return this.transformMap.has(elementId)
  }

  appendChild(childNode, transformParentId = null) {
    this.nodeMap.set(childNode.uid, childNode)
    this.children.push(childNode)

    // only set transform map if it doesn't already exist
    if (!this.transformMap.has(childNode.id)) {
      this.transformMap.set(childNode.id, childNode.uid)
    }

    if (transformParentId) {
      const parentNodeUid = this.transformMap.get(transformParentId)
      childNode.parent = this.nodeMap.get(parentNodeUid)
    }
  }

  mask(maskNode, maskedNode) {
    this.maskMap.set(maskedNode.uid, maskNode.uid)
    maskNode.mask = true
    maskedNode.maskParent = maskNode
  }
}
