export const isSelected = (item) => {
  return item && (item.selected || item.parentSelected)
}

export const getGroupIndex = (list, index, pos, targetLevel) => {
  const item = list[index]
  if (!item || isSelected(item.dataset)) {
    return -1
  }

  if (isAppend(item.dataset.type, pos)) {
    return index
  }

  let idx = index
  const parentLevel = targetLevel - 1
  while (idx >= 0) {
    if (
      Number(list[idx].dataset.level) === parentLevel &&
      !(list[index].dataset.selected || list[index].dataset.parentSelected)
    ) {
      break
    }
    idx--
  }
  return idx
}

export const getInsertAction = (itemType, pos) => {
  if (isAppend(itemType, pos)) {
    return 'append'
  }
  if (isInsertBefore(itemType, pos)) {
    return 'insert-before'
  }
  return 'insert-after'
}

export const isAppend = (itemType, pos) => {
  return itemType === 'group' && pos > 1 / 3 && pos <= 2 / 3
}

export const isInsertBefore = (itemType, pos) => {
  if (itemType === 'group' && pos < 1 / 3) {
    return true
  }
  if (itemType === 'item' && pos < 1 / 2) {
    return true
  }
  return false
}

const firstIndentSize = 28
const defaultIndentSize = 20
export const indentToLevel = (indent) => {
  if (indent < firstIndentSize) {
    return 0
  }
  return Math.floor((indent - firstIndentSize) / defaultIndentSize)
}

export const levelToIndent = (level) => {
  if (!level) {
    return firstIndentSize
  }
  return level * defaultIndentSize + firstIndentSize
}

export const flatten = (map, root, selectedSet, reversed, getInheritProps) => {
  const parentSelectedSet = new Set()

  const itemMap = new Map()
  const queue = root.map((id) => [map.get(id), null, 0, getInheritProps(null)])
  while (queue.length) {
    const [item, parentId, level, inheritedProps] = reversed ? queue.pop() : queue.shift()
    if (!item) {
      continue
    }
    const parentSelected = parentSelectedSet.has(parentId)
    const selected = parentSelected ? false : selectedSet.has(item.id)
    if (selected || parentSelected) {
      parentSelectedSet.add(item.id)
    }

    let children
    if (item.children) {
      children = item.children.slice()

      if (item.expanded) {
        const childList = item.children.map((id) => [
          map.get(id),
          item.id,
          level + 1,
          getInheritProps(item, inheritedProps)
        ])
        reversed ? queue.push(...childList) : queue.unshift(...childList)
      }
    }
    const type = children ? 'group' : 'item'

    itemMap.set(item.id, {
      ...item,
      parentId,
      selected,
      parentSelected,
      type,
      children,
      level,
      ...inheritedProps
    })
  }

  return [...itemMap.values()]
}
