import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'

import PropTypes from 'prop-types'

import { useDataStoreActions } from '../../../providers/dataStore/DataStoreProvider'
import { minmax } from '../../../utils/number'
import PropertyState from '../../Properties/PropertyEditors/PropertyState'
import ErrorTextComponent from '../ErrorTextComponent'
import Icon from '../Icon'
import { Overlay, Tooltip } from '../index'

const MIX = 'Mix'

// helpers
export const getMultiply = (e) => {
  if (e.shiftKey) return 10
  if (e.altKey) return 0.1
  return 1
}

export const getDefaultValidator = (type) => {
  return type === 'number' ? (v) => (isNaN(v) ? 'Invalid' : '') : () => ''
}

// if mouse move distance is smaller than MOUSE_MOVE_THRESHOLD, define it as a mouse click, than make the input focus
const MOUSE_MOVE_THRESHOLD = 2
const CURSOR_RESIZE = 'ew-resize'
const CURSOR_INITIAL = 'initial'

// Functions about spinner
const getDragMixedStepValue = (diff, dragDistance, lastDistanceRef) => {
  if (diff === 0) return 0
  const onDragStartRight = diff > 0
  const isDistanceIncreasing = dragDistance >= lastDistanceRef.current
  return onDragStartRight === isDistanceIncreasing ? 1 : -1
}

const getDragStepValue = (diff, dragStartValue, min, max) => {
  if (Math.abs(diff) === MOUSE_MOVE_THRESHOLD + 1) return dragStartValue.current
  const step = Math.floor(diff > 0 ? diff - MOUSE_MOVE_THRESHOLD - 1 : diff + MOUSE_MOVE_THRESHOLD + 1)
  const thisStepValue = dragStartValue.current + step
  return minmax(thisStepValue, min, max)
}

const checkCursorInsideLabel = (e, boundingBox) => {
  return (
    e.clientX >= boundingBox.left &&
    e.clientX <= boundingBox.right &&
    e.clientY >= boundingBox.top &&
    e.clientY <= boundingBox.bottom
  )
}

const checkIsADrag = (e, dragStart) => {
  return (
    Math.abs(e.clientX - dragStart.current.x) >= MOUSE_MOVE_THRESHOLD ||
    Math.abs(e.clientY - dragStart.current.y) >= MOUSE_MOVE_THRESHOLD
  )
}

const textAlignmentMap = {
  left: 'text-left',
  center: 'text-center',
  right: 'text-right'
}

const getComputedClassName = (isFlatVariant, isDragging, disabled, error) => {
  if (disabled) {
    return {
      labelClassName: 'opacity-40 cursor-not-allowed',
      inputClassName: 'bg-transparent'
    }
  }

  const errorHighlightClassName = error
    ? 'highlight-border-rounded-md-error highlight-border-rounded-md-error-focus'
    : 'highlight-border-rounded-md'

  if (isFlatVariant) {
    return {
      labelClassName: `rounded-md ${
        isDragging ? 'outline-light-overlay-20-1-offset--1' : 'hover:outline-light-overlay-20-1-offset--1'
      } ${errorHighlightClassName}`,
      inputClassName: `px-8 rounded-md ${
        isDragging
          ? 'bg-light-overlay-10'
          : 'bg-transparent focus-visible:bg-light-overlay-5 hover:bg-light-overlay-10 focus-visible:hover:bg-light-overlay-10'
      }`
    }
  }
  return {
    labelClassName: `rounded-md ${
      isDragging
        ? 'bg-light-overlay-10 outline-light-overlay-20-1-offset--1'
        : 'bg-light-overlay-5 hover:bg-light-overlay-10 hover:outline-light-overlay-20-1-offset--1'
    } ${errorHighlightClassName}`,
    inputClassName: 'pl-8 bg-transparent'
  }
}

const Input = forwardRef(
  (
    {
      variant,
      inputSize,
      align,
      labelClassName,
      className,
      formatter,
      validator,
      type,
      value,
      min,
      max,
      step,
      reachMinmax,
      onInput,
      onChange,
      onFocus,
      onBlur,
      onEnterKey,
      onStepChange,
      mixed,
      keepInvalid,
      onErrorChange,
      disabled,
      showErrorTips,
      validateOnChange,
      rightComponent,
      tooltip,
      keyFrameIconProps,
      rightText,
      errorOverride,
      spinner,
      alwaysForceUpdate,
      dataTestId,
      commitUndo,
      updateIS,
      ...rest
    },
    forwardedRef
  ) => {
    const [hoverInput, setHoverInput] = useState(false)
    const [hoverInputLabel, setHoverInputLabel] = useState(false)
    const [isDragging, setIsDragging] = useState(false)
    const [shouldForceUpdate, forceUpdate] = useState()
    const [isOnComposition, setIsOnComposition] = useState(false)

    const ref = useRef(null)
    const labelRef = useRef(null)
    const dragStart = useRef(null)
    const dragStartValue = useRef(null)
    const lastDistanceRef = useRef(0)
    const hasBeenDragged = useRef(false)

    const [inputValue, setInputValue] = useState(value)
    const [isPasswordVisible, setIsPasswordVisible] = useState(false)
    const [error, setError] = useState('')
    const [isTipHide, setTipHide] = useState(false)

    const setErrorAndNotify = useCallback(
      (err) => {
        if (error !== err) onErrorChange(err)
        setError(err)
      },
      [error, setError, onErrorChange]
    )
    useImperativeHandle(forwardedRef, () => ({
      onValidate: handleValidate,
      focus: () => ref.current.focus(),
      blur: () => ref.current.blur(),
      select: () => ref.current.select()
    }))

    const isLargeSize = inputSize === 'l'
    const isNumberType = type === 'number'
    const isPasswordType = type === 'password'
    const isFlatVariant = variant === 'flat'
    const displayType = isPasswordType ? (isPasswordVisible ? 'text' : 'password') : 'text'

    const computedClassName = getComputedClassName(isFlatVariant, isDragging, disabled, error)
    const _validator = validator || getDefaultValidator(type)

    const triggerForceUpdate = useCallback(() => {
      forceUpdate({})
    }, [])

    const stepChange = (e, sign) => {
      const mul = getMultiply(e)
      let v = parseFloat(e.target.value)
      if (!mixed && isNaN(v)) {
        return
      }

      const stepValue = sign * step * mul
      let error
      if (!mixed) {
        v = minmax(v + stepValue, min, max)
        if (!reachMinmax) {
          error = _validator(String(v))
          ref.current.setCustomValidity(error)
          setErrorAndNotify(e.target.validationMessage)
        }
        if (!error) {
          setInputValue(formatter(v))
        }
      }
      if (!error) {
        const options = { commit: false, delta: mixed }
        const newValue = mixed ? stepValue : v
        onChange(newValue, options)
      }

      onStepChange && onStepChange()
      // TODO: remove e.persist after update to React 17
      e.persist()
      if (alwaysForceUpdate) {
        triggerForceUpdate()
      }
      setTimeout(() => {
        e.target.select()
      })
    }

    const handleChange = (e) => {
      const newInputValue = e.target.value
      onInput(newInputValue)
      setInputValue(newInputValue)

      // FIXME: should remove this property after implementing https://phase-software.atlassian.net/browse/PHASE-10383
      if (validateOnChange) {
        const error = _validator(newInputValue)
        ref.current.setCustomValidity(error ?? '')
        setErrorAndNotify(e.target.validationMessage)
      }
    }

    const handleValidate = () => {
      const error = _validator(inputValue)
      ref.current.setCustomValidity(error ?? '')
      setErrorAndNotify(ref.current.validationMessage)
      return !ref.current.validationMessage
    }

    const handleFocus = (e) => {
      setTipHide(true)

      if (e.relatedTarget) {
        e.target.select()
      }
      if (onFocus) {
        onFocus(e)
      }
      updateIS(true)
    }

    const handleMouseUpInput = (e) => {
      if (e.currentTarget.selectionStart === e.currentTarget.selectionEnd && !spinner) {
        e.currentTarget.select()
      }
    }
    const handleMouseDownInput = (e) => {
      if (e.currentTarget.selectionStart === e.currentTarget.selectionEnd && !spinner) {
        e.currentTarget.select()
      }
    }

    const handleKeyDown = (e) => {
      switch (e.key) {
        case 'Escape':
          setInputValue(value)
          setTimeout(() => {
            ref?.current?.blur()
          })
          e.stopPropagation()
          break
        case 'Enter':
          if (!isOnComposition) {
            e.target.blur()
            onEnterKey?.()
            updateIS(false)
          }
          break
        case 'ArrowUp':
          e.preventDefault()
          if (mixed || isNumberType) {
            stepChange(e, 1)
          }
          break
        case 'ArrowDown':
          e.preventDefault()
          if (mixed || isNumberType) {
            stepChange(e, -1)
          }
          break
        default:
          break
      }
    }

    const handleBlur = (e) => {
      setTipHide(false)

      let v = e.target.value

      onBlur && onBlur(e)

      if (mixed && v === MIX) {
        updateIS(false)
        return
      }

      if (isNumberType) {
        v = parseFloat(v)
        if (isNaN(v)) {
          v = value
        }
        v = minmax(v, min, max)
      }

      if (e.target.validity.valid) {
        setInputValue(formatter(v))
        if (value !== v) {
          onChange(v)
        }
      } else if (!keepInvalid) {
        setInputValue(mixed ? MIX : formatter(value))
        ref.current.setCustomValidity('')
        setErrorAndNotify(e.target.validationMessage)
      }

      updateIS(false)
      if (alwaysForceUpdate) {
        triggerForceUpdate()
      }
    }

    const togglePasswordVisibility = useCallback(() => {
      setIsPasswordVisible((pre) => !pre)
    }, [setIsPasswordVisible])

    useEffect(() => {
      ref.current.addEventListener('invalid', (e) => {
        setErrorAndNotify(e.target.validationMessage)
      })
    }, [setErrorAndNotify])

    // sync value change
    useEffect(() => {
      setInputValue(mixed && value === MIX ? MIX : formatter(value))
    }, [mixed, value, formatter, shouldForceUpdate])

    const handleMouseEnterInput = useCallback(() => {
      setHoverInput(true)
    }, [])

    const handleMouseLeaveInput = useCallback(() => {
      setHoverInput(false)
    }, [])

    const handleMouseEnterInputLabel = useCallback(() => {
      setHoverInputLabel(true)
    }, [])

    const handleMouseLeaveInputLabel = (e) => {
      setHoverInputLabel(false)
      if (spinner && isDragging && !disabled) {
        document.body.style.cursor = CURSOR_RESIZE
        e.currentTarget.style.cursor = CURSOR_INITIAL
      }
    }

    const handleMouseDownInputLabel = (e) => {
      if (disabled || document.activeElement === ref.current || !spinner) return

      e.preventDefault()
      e.stopPropagation()
      e.currentTarget.style.cursor = CURSOR_RESIZE
      dragStartValue.current = parseFloat(ref.current.value)
      dragStart.current = { x: e.clientX, y: e.clientY }
      document.addEventListener('mousemove', handleDragging)
      document.addEventListener('mouseup', handleEndDragging, { once: true })
    }

    const handleMouseOver = (e) => {
      if (spinner && !disabled) {
        e.currentTarget.style.cursor = document.activeElement !== ref.current ? CURSOR_RESIZE : CURSOR_INITIAL
      }
    }
    const handleCompositionStart = () => {
      setIsOnComposition(true)
    }

    const handleCompositionEnd = () => {
      setIsOnComposition(false)
    }

    const handleMixedCase = (mixedStepValue) => {
      const stepValue = mixedStepValue
      const options = { commit: false, delta: mixed }
      onChange(stepValue, options)
      ref.current.value = MIX
    }

    const handleDragging = (e) => {
      const isInputFocus = document.activeElement === ref.current
      if (isInputFocus || !spinner) return
      if (!ref.current) return
      setIsDragging(true)
      if (!hasBeenDragged.current && dragStart) {
        hasBeenDragged.current = checkIsADrag(e, dragStart)
      }
      const diff = e.clientX - dragStart.current.x
      const dragDistance = Math.abs(diff)

      if (dragDistance > MOUSE_MOVE_THRESHOLD) {
        const mixedStepValue = getDragMixedStepValue(diff, dragDistance, lastDistanceRef)
        const inputCurrentValue = parseFloat(ref.current.value)
        let validationError
        let stepValue

        if (mixed) {
          handleMixedCase(mixedStepValue)
        } else {
          if (reachMinmax) {
            stepValue = getDragStepValue(diff, dragStartValue, min, max)
          } else {
            stepValue = minmax(inputCurrentValue + mixedStepValue, min, max)
            validationError = _validator(String(stepValue))
            ref.current.setCustomValidity(validationError)
            setErrorAndNotify(ref.current.validationMessage)
          }

          if (validationError || mixedStepValue === 0 || Number(ref.current.value) === stepValue) return

          const options = { commit: false }
          onChange(stepValue, options, true)
          ref.current.value = formatter(stepValue)
        }
        lastDistanceRef.current = dragDistance
      }
    }

    const handleEndDragging = (e) => {
      if (!ref.current) return
      const removeFormatValue = parseFloat(ref.current.value)
      if (!mixed) {
        setInputValue(formatter(removeFormatValue))
      }
      setIsDragging(false)
      document.body.style.cursor = CURSOR_INITIAL

      if (labelRef.current) {
        const boundingBox = labelRef.current.getBoundingClientRect()
        const cursorInsideLabel = checkCursorInsideLabel(e, boundingBox)

        if (!hasBeenDragged.current && cursorInsideLabel) {
          e.target.style.cursor = CURSOR_INITIAL
          if (ref.current) {
            ref.current.focus()
            ref.current.select()
          }
        } else {
          if (ref.current) {
            ref.current.blur()
          }
        }
      }
      commitUndo()
      lastDistanceRef.current = 0
      dragStartValue.current = null
      dragStart.current = undefined
      hasBeenDragged.current = false
      document.removeEventListener('mousemove', handleDragging)
    }

    const rightComponentMemo = useMemo(() => {
      if (rightComponent) {
        return rightComponent
      }
      if (rightText) {
        return <div className="min-w-16 text-12 text-center text-light-overlay-60 whitespace-nowrap">{rightText}</div>
      }
      if (isPasswordType) {
        return <Icon name={isPasswordVisible ? 'Eye' : 'EyeClose'} onClick={togglePasswordVisibility} />
      }
    }, [rightComponent, rightText, isPasswordVisible, togglePasswordVisibility, isPasswordType])

    const right = useMemo(() => {
      if (keyFrameIconProps) {
        const sizeClassName = isLargeSize ? 'p-8 w-32' : 'p-6 w-28'
        return (
          <PropertyState
            {...keyFrameIconProps}
            hoverContent={hoverInput}
            hoverWrap={hoverInputLabel}
            rightComponent={rightComponentMemo}
            className={sizeClassName}
          />
        )
      }
      if (rightComponentMemo) {
        return rightComponentMemo
      }
      return null
    }, [rightComponentMemo, keyFrameIconProps, hoverInputLabel, hoverInput, isLargeSize])

    return (
      <>
        <Tooltip content={tooltip} hide={isTipHide}>
          <ErrorTextComponent
            showErrorTips={showErrorTips}
            errorTips={errorOverride || error}
            className="mt-8 text-left"
          >
            <label
              ref={labelRef}
              onMouseEnter={handleMouseEnterInputLabel}
              onMouseLeave={handleMouseLeaveInputLabel}
              onMouseDown={handleMouseDownInputLabel}
              onMouseOver={handleMouseOver}
              className={`${labelClassName} ${isLargeSize ? 'h-32' : 'h-28'} ${
                computedClassName.labelClassName
              } relative flex items-center align-middle`}
              disabled={disabled}
            >
              <input
                className={`${className} outline-none border-0 text-white flex-grow w-full min-width-0 placeholder-light-overlay-60 text-12 appearance-none search-cancel-button:hidden ${
                  isLargeSize ? 'py-8' : 'py-6'
                } ${textAlignmentMap[align] ?? 'text-left'} ${computedClassName.inputClassName}`}
                ref={ref}
                type={displayType}
                value={inputValue}
                min={min}
                max={max}
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
                onKeyDown={handleKeyDown}
                disabled={disabled}
                onMouseEnter={handleMouseEnterInput}
                onMouseLeave={handleMouseLeaveInput}
                onMouseDown={handleMouseDownInput}
                onMouseUp={handleMouseUpInput}
                onMouseOver={handleMouseOver}
                onCompositionStart={handleCompositionStart}
                onCompositionEnd={handleCompositionEnd}
                data-test-id={dataTestId}
                {...rest}
              />
              {right ? (
                <div className={`${!keyFrameIconProps ? (isLargeSize ? 'p-8' : 'p-6') : ''}`}>{right}</div>
              ) : null}
            </label>
          </ErrorTextComponent>
        </Tooltip>
        {spinner && isDragging ? <Overlay overlayMode="modal" /> : null}
      </>
    )
  }
)
Input.displayName = 'Input'

Input.propTypes = {
  variant: PropTypes.oneOf(['normal', 'flat']),
  align: PropTypes.oneOf(['left', 'center', 'right']),
  labelClassName: PropTypes.string,
  className: PropTypes.string,
  inputSize: PropTypes.oneOf(['s', 'l']),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.oneOf(['text', 'email', 'search', 'password', 'number']),
  step: PropTypes.number,
  max: PropTypes.number,
  min: PropTypes.number,
  reachMinmax: PropTypes.bool,
  onInput: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onEnterKey: PropTypes.func,
  onStepChange: PropTypes.func,
  onErrorChange: PropTypes.func,
  validator: PropTypes.func,
  formatter: PropTypes.func,
  mixed: PropTypes.bool,
  keepInvalid: PropTypes.bool,
  disabled: PropTypes.bool,
  showErrorTips: PropTypes.bool,
  validateOnChange: PropTypes.bool,
  rightComponent: PropTypes.node,
  tooltip: PropTypes.string,
  keyFrameIconProps: PropTypes.object,
  rightText: PropTypes.string,
  errorOverride: PropTypes.string,
  spinner: PropTypes.bool,
  alwaysForceUpdate: PropTypes.bool,
  dataTestId: PropTypes.string,
  commitUndo: PropTypes.func,
  updateIS: PropTypes.func
}

Input.defaultProps = {
  value: '',
  align: 'left',
  variant: 'flat',
  type: 'search',
  inputSize: 's',
  labelClassName: '',
  className: '',
  step: 1,
  reachMinmax: true,
  validator: undefined,
  keepInvalid: false,
  disabled: false,
  showErrorTips: false,
  validateOnChange: true,
  spinner: false,
  alwaysForceUpdate: false,
  errorOverride: '',
  formatter: (x) => x,
  onInput: () => {},
  onChange: () => {},
  onBlur: () => {},
  onEnterKey: () => {},
  onStepChange: () => {},
  onErrorChange: () => {}
}

const InputWrapper = forwardRef(({ noIS, ...props }, ref) => {
  const { changeEAMInputState, commitUndo } = useDataStoreActions()
  const updateIS = useCallback(
    (flag) => {
      if (!noIS) {
        changeEAMInputState(flag)
      }
    },
    [noIS, changeEAMInputState]
  )
  return (
    <Input
      {...props}
      updateIS={updateIS}
      commitUndo={commitUndo}
      className={noIS ? 'input-system-no-handle' : ''}
      ref={ref}
    />
  )
})

InputWrapper.displayName = 'InputWrapper'

InputWrapper.propTypes = {
  noIS: PropTypes.bool
}

export default React.memo(InputWrapper)
