import { vec2 } from 'gl-matrix'
import { Vector2 } from '../Vector2'
import { wrap, setFind } from '../commons'

/** @typedef {import('./Cell').Cell} Cell */
/** @typedef {import('./Vertex').Vertex} Vertex */
/** @typedef {import('./Edge').Edge} Edge */
/** @typedef {import('./Mesh').Mesh} Mesh */
/** @typedef {import('./Edge').HalfEdge} HalfEdge */
/** @typedef {import('./Contour').Contour} Contour */

export class MeshHelper {

    /**
     * @param {Mesh} mesh
     */
    constructor(mesh) {
        this.mesh = mesh
    }

    /**
     * Returns Set of halves for each contour found.
     * @param {boolean} [virtual=false]        set to true to include Virtual vertices and virtual Edges in the search
     * @returns {Array<Set<HalfEdge>>}
     */
    findContours(virtual = false) {
        // TODO: optimize this
        const halves = new Cycles(this.mesh).find(virtual)

        // DEBUG
        // debugger
        // const print = halves.map(set => [...set].map(half => half.edge.id))

        return halves
    }

    /**
     * Resolve self intersections, by splitting intersected Edges into virtual Edges
     * and enumerating virtual Contours created as a result
     */
    selfIntersect() {
        const intersections = this._findIntersections()

        this._createVirtualStructures(intersections)
    }

    /**
     * @returns {Map<Edge, number[]>}
     */
    _findIntersections() {
        const mesh = this.mesh
        const visited = new Set()

        /** @type {Map<Edge, number[]>} split points t-values [0, 1] for each edge */
        const intersections = new Map()

        /**
         * @param {Edge} edge
         * @param {number[][]} splits
         * @param {number} index
         */
        const addIntersection = (edge, splits, index) => {
            let points = intersections.get(edge)
            if (!points) {
                points = []
                intersections.set(edge, points)
            }
            points.push(...splits[index])
        }

        let splitPoints
        for (const e1 of mesh.getEdges()) {
            splitPoints = e1.intersect()
            addIntersection(e1, splitPoints, 0)

            for (const e2 of mesh.getEdges()) {
                if (e1 === e2) {
                    continue
                }
                if (visited.has(`${e1.id},${e2.id}`)) {
                    continue
                }
                visited.add(`${e2.id},${e1.id}`)

                splitPoints = e1.intersect(e2)
                // no intersections found
                if (splitPoints[0].length === 0) {
                    continue
                }

                [e1, e2].forEach((e, i) => {
                    addIntersection(e, splitPoints, i)
                })
            }
        }

        return intersections
    }

    /**
     * @param {Map<Edge, number[]>} intersections
     */
    _createVirtualStructures(intersections) {
        const options = { virtual: true }

        for (const [e, ts] of intersections) {
            this.mesh.splitEdge(e, ts, options)
        }

        for (const halves of this.findContours(true)) {
            this.mesh.addContour(halves, options)
        }
    }
}


class Cycles {
    /**
     * @param {Mesh} mesh
     */
    constructor(mesh) {
        this.mesh = mesh
        /** @type {Vertex[]} */
        this.vertices = []
        /** @type {Map<Vertex, Vertex[]>} */
        this.adjMap = new Map()
        /** @type {Map<Vertex, Edge>} */
        this.realEdgesMap = new Map()
        /** @type {Array<Set<HalfEdge>>} */
        this.loopHalfEdgeSets = []
    }

    /**
     * @param {Mesh} mesh
     * @param {boolean} [virtual]
     */
    makeAdjacencyMap(mesh, virtual = false) {
        for (const v of mesh.getVertices({ virtual })) {
            this.vertices.push(v)
            const adjList = []
            for (const half of v.getOutHalves({ all: virtual })) {
                // skipping loop halves and halves of Edges that have virtual edges
                if (half.w === v || half.hasVirtualEdges()) {
                    continue
                }
                adjList.push(half.w)
            }
            this.adjMap.set(v, adjList)
        }
        // split curved edges and fix repeating adjacencies
        for (const edge of mesh.getEdges()) {
            // skip real edges that have virtual edges
            if (edge.hasVirtualEdges()) {
                continue
            }
            if (edge.v === edge.w) {
                this.dealWithLoops(edge)
                continue
            }
            if (!edge.isCurve) {
                continue
            }
            const mid = this.makeVirtualCurveSplit(edge)
            // DEBUG
            // mid.id = `mid-${edge.id}`
            this.vertices.push(mid)
            this.adjMap.set(mid, [edge.v, edge.w])

            let adjList = this.adjMap.get(edge.v)
            adjList.splice(adjList.indexOf(edge.w), 1, mid)

            adjList = this.adjMap.get(edge.w)
            adjList = adjList.splice(adjList.indexOf(edge.v), 1, mid)
        }
    }

    /**
     * @param {Edge} edge
     */
    dealWithLoops(edge) {
        this.loopHalfEdgeSets.push(new Set([edge.halves[0]]))
    }

    /**
     * @param {Edge} edge
     * @returns {Vertex}
     */
    makeVirtualCurveSplit(edge) {
        const mesh = this.mesh
        const split = edge.split([0.5])
        const options = { virtual: true }
        const newV = mesh.addVertex(split.atPositions[0], options)
        this.realEdgesMap.set(newV, edge)
        return newV
    }

    /**
     * @param {Vertex[][]} cycles
     * @returns {Array<Set<HalfEdge>>}
     */
    convertToHalfEdgeSets(cycles) {
        /** @type {Array<Set<HalfEdge>>} */
        const list = []
        for (const cycle of cycles) {
            /** @type {Set<HalfEdge>} */
            const halves = new Set()

            // if first virtex is virtual - shift cycle by one element
            let realEdge = this.realEdgesMap.get(cycle[0])
            if (realEdge) {
                cycle.unshift(cycle.pop())
            }

            for (let i = 0; i < cycle.length; i++) {
                const v = cycle[i]
                const w = cycle[wrap(i + 1, 0, cycle.length)]

                /** @type {HalfEdge} */
                let half
                realEdge = this.realEdgesMap.get(w)
                if (realEdge) {
                    half = realEdge.getHalf(v, cycle[wrap(i + 2, 0, cycle.length)])
                    i++
                }
                else {
                    half = setFind(v.getOutHalves(), half => !half.edge.isCurve && half.w === w)
                }
                if (!half) {
                    throw new Error('Failed to find matching HalfEdge. This should not happen.')
                }
                halves.add(half)
            }
            list.push(halves)
        }
        // remove virtual points
        for (const [v] of this.realEdgesMap) {
            this.mesh.deleteVertex(v)
        }
        // add loops
        for (const set of this.loopHalfEdgeSets) {
            list.push(set)
        }
        return list
    }

    /**
     * @param {boolean} [virtual=false]   set to true to include virtual Vertices in the search
     * @returns {Array<Set<HalfEdge>>}
     */
    find(virtual = false) {
        this.makeAdjacencyMap(this.mesh, virtual)

        const cycles = []
        let vertices = this.vertices

        while (vertices.length > 0) {
            const v = this.leftBottomVertex(vertices),
                walk = this.reduceWalk(this.closedWalkFrom(v))

            if (walk.length > 2) {
                cycles.push(walk)
            }
            this.removeEdge(walk[0], walk[1])
            vertices = this.removeFilamentAt(walk[0], vertices)
            vertices = this.removeFilamentAt(walk[1], vertices)
        }

        return this.convertToHalfEdgeSets(cycles)
    }

    /**
     * @param {Vertex[]} vertices
     * @returns {Vertex}
     */
    leftBottomVertex(vertices) {
        return vertices.reduce((v1, v2) => {
            const p1 = v1.pos,
                p2 = v2.pos
            const dx = p2[0] - p1[0]
            if (dx < 0){
                return v2
            }
            if (dx > 0){
                return v1
            }
            return p2[1] - p1[1] < 0 ? v1 : v2
        })
    }

    /**
     * @param {Vertex} v
     * @returns {Vertex[]}
     */
    closedWalkFrom(v) {
        const walk = []
        let curr = v,
            prev
        do {
            walk.push(curr)
            ;[curr, prev] = this.getNext(curr, prev)
        } while (curr && curr !== v)
        return walk
    }

    /**
     * @param {Vertex[]} walk
     * @returns {Vertex[]}
     */
    reduceWalk(walk) {
        for (let i = 1; i < walk.length; i++) {
            const idup = walk.lastIndexOf(walk[i])
            if (idup > i) {
                walk.splice(i + 1, idup - i)
            }
        }
        return walk
    }

    /**
     * @param {Vertex} v1
     * @param {Vertex} v2
     */
    removeEdge(v1, v2) {
        this.adjMap.set(v1, this.withoutVertex(v2, this.adjMap.get(v1)))
        this.adjMap.set(v2, this.withoutVertex(v1, this.adjMap.get(v2)))
    }

    /**
     * @param {Vertex} v
     * @param {Vertex[]} vertices
     * @returns {Vertex[]}
     */
    removeFilamentAt(v, vertices) {
        let curr = v,
            _vertices = vertices,
            next
        while (curr && this.adjMap.get(curr).length < 2) {
            _vertices = this.withoutVertex(curr, _vertices)
            next = this.adjMap.get(curr)[0]
            if (next) {
                this.removeEdge(curr, next)
            }
            curr = next
        }
        return _vertices
    }

    /**
     * @param {Vertex} v
     * @param {Vertex[]} adj
     * @returns {Vertex[]}
     */
    withoutVertex(v, adj) {
        if (!adj || !adj.length) {
            return []
        }
        return adj.filter(x => x !== v)
    }

    /**
     * @param {Vertex} curr
     * @param {Vertex} prev
     * @returns {[Vertex, Vertex]}
     */
    getNext(curr, prev) {
        const adj = this.adjMap.get(curr)
        if (!adj || !adj.length) {
            return []
        }

        const next = adj.length === 1 ? adj[0] : this.bestByKind(prev, curr, prev ? 'ccw' : 'cw')
        return [next, curr]
    }

    /**
     * @param {Vertex} prev
     * @param {Vertex} curr
     * @param {string} kind
     * @returns {Vertex}
     */
    bestByKind(prev, curr, kind) {
        let d_curr,
            adj = this.adjMap.get(curr)

        if (prev) {
            d_curr = this.vsub(curr, prev)
            adj = this.withoutVertex(prev, adj)
        } else {
            d_curr = new Vector2(0, -1)
        }
        return adj.reduce((v_so_far, v) => this.betterByKind(v, v_so_far, curr, d_curr, kind), null)
    }

    /**
     * @param {Vertex} v
     * @param {Vertex} vSoFar
     * @param {Vertex} vCurr
     * @param {Vector2} dCurr
     * @param {string} kind
     * @returns {Vertex}
     */
    betterByKind(v, vSoFar, vCurr, dCurr, kind) {
        if (!vSoFar) {
            return v
        }

        const d = this.vsub(v, vCurr),
            dSoFar = this.vsub(vSoFar, vCurr),
            isConvex = this.dotPerp(dSoFar, dCurr) > 0,
            curr2v = this.dotPerp(dCurr, d),
            vsf2v = this.dotPerp(dSoFar, d)
        let vIsBetter

        if (kind === 'cw') {
            vIsBetter =
                (isConvex && (curr2v >= 0 || vsf2v >= 0)) ||
                (!isConvex && curr2v >= 0 && vsf2v >= 0)
        } else {
            vIsBetter =
                (!isConvex && (curr2v < 0 || vsf2v < 0)) || (isConvex && curr2v < 0 && vsf2v < 0)
        }
        return vIsBetter ? v : vSoFar
    }

    /**
     * @param {Vertex} v1
     * @param {Vertex} v2
     * @returns {Vector2}
     */
    vsub(v1, v2) {
        return vec2.sub(new Vector2(), v1.pos, v2.pos)
    }

    /**
     * @param {Vector2} a
     * @param {Vector2} b
     * @returns {number}
     */
    dotPerp(a, b) {
        return a.x * b.y - b.x * a.y
    }
}
