import React from 'react'
import { track } from '../../services/heapAnalytics'
import { IfFeatureEnabled, useFeatureValue } from '@growthbook/growthbook-react'
import { FEATURE_KEYS } from '../../growthbook-feature-keys'

type CampaignBannerTemplateType = {
  title: string
  buttonText: string
  track: string
  link: string
}

const MotionChallengeBanner = () => {
  const campaignBannerTemplate = useFeatureValue<CampaignBannerTemplateType>(FEATURE_KEYS.CAMPAIGN_BANNER, {
    title: '🏅 상금 총 558만원 챌린지 진행 중 (5/31 마감)',
    buttonText: '더 알아보기',
    track: 'Korea - Motion Challenge CTA Clicked',
    link: 'https://www.instagram.com/p/C6hg-08SWp5/'
  })

  const handleTrack = () => {
    track(campaignBannerTemplate.track)
  }

  return (
    <IfFeatureEnabled feature={FEATURE_KEYS.CAMPAIGN_BANNER}>
      <div className="sticky top-0 left-0 z-[11] w-full h-[36px]">
        <div className="flex h-[36px] w-full justify-center items-center bg-primary-50 text-14 gap-16">
          <div>{campaignBannerTemplate.title}</div>
          <a
            href={campaignBannerTemplate.link}
            target="_blank"
            rel="noreferrer noopener"
            className="font-semibold"
            style={{ textDecoration: 'underline' }}
            onClick={handleTrack}
          >
            {campaignBannerTemplate.buttonText}
          </a>
        </div>
      </div>
    </IfFeatureEnabled>
  )
}

export default MotionChallengeBanner
