import {
    PropComponentType
} from '@phase-software/types'
import { notNull } from '@phase-software/data-utils'
import PropertyComponent from './PropertyComponent'

/** @typedef {import('../DataStore').DataStore} DataStore */
/** @typedef {import('./PropertyComponent').AppliedRef} AppliedRef */

export default class RotationComponent extends PropertyComponent {
    /**
     * @param {DataStore} dataStore
     * @param {RotationComponentData} [data]
     * @param {object} [options] 
     * @param {bool} [options.regenId=false]   if set to true, will generate new ID
     */
    constructor(dataStore, data = {}, options) {
        super(dataStore, data, options)

        const { rotation } = data

        this.componentType = PropComponentType.ROTATION
        this.rotation = notNull(rotation) ? rotation : 0

        if (!this.name) {
            this.name = 'rotation'
        }
    }

    /** @param {Partial<RotationComponentData>} data */
    set(data) {
        super.set(data)

        if (notNull(data.rotation)) {
            this.updateProp('rotation', data.rotation)
        }
    }

    /**
     * Override this in subclasses
     * CALL super._clone() at the top of overriden method
     * @protected
     * @param {AppliedRef} [ref]
     * @returns {RotationComponent}
     */
    _clone(ref) {
        const obj = super._clone(ref)
        obj.rotation = this.rotation
        return obj
    }

    /**
     * Override this in subclasses
     * CALL super._save() at the top of overriden method
     * @protected
     * @returns {RotationComponentData}
     */
    _save() {
        const data = super._save()
        data.rotation = this.rotation
        return data
    }
}

/** @typedef {import('./PropertyComponent').PropertyComponentData} PropertyComponentData */

/**
 * @typedef {PropertyComponentData} RotationComponentData
 * @property {number} rotation
 */
