import { Mode } from '@phase-software/types'
import { Stats, isNull } from '@phase-software/data-utils'
import Setter from './helpers/Setter'
import Event from './helpers/Event'
import Cache from './helpers/Cache'
import { IM_PROPS_MAP, TM_PROPS_MAP, TRANSITION_MODES, TRANSITION_STATUS } from './constant'
import { getOrigin } from './utils/data'
import { getProgress, getPropPercentage } from './utils/timing-functions'

/** @typedef {import('@phase-software/data-store/src/DataStore').DataStore} DataStore */
/** @typedef {import('@phase-software/data-store/src/interaction/Manager').default} Interaction */
/** @typedef {import('./components/ElementStack').ElementStack} ElementStack */

/**
 * TransitionManager class for handling the transition style calculation.
 */
class TransitionManager extends Setter {
  /**
   * constructor
   * @param {DataStore} dataStore
   * @param {Interaction} interaction
   */
  constructor(dataStore, interaction) {
    super()
    this.dataStore = dataStore
    this.interaction = interaction

    this.CHANGE_PROPS_MAP = TM_PROPS_MAP

    this._holding = []
    this._init()
    this._initInteraction()

    this._nextTickFn = this._nextTick.bind(this)

    this.dataStore.on('mode', this.handleModeSwitch.bind(this))
  }

  /**
   * initialize Transition Manager
   * @private
   */
  _init() {
    this.cache = new Cache(this)
    this.event = new Event(this, this.dataStore)
    this.mode = this.dataStore.get('mode')

    this.status = TRANSITION_STATUS.INIT
    this.startTime = 0
    this._disabled = false
    this._tmpTime = 0
    this.time = 0
    this.viewerTime = 0
    this.viewerPlaybackRate = 1
    this.viewerLoop = true
  }

  get currentTime() {
    return (this.dataStore.isEditingState || this.dataStore.isTablingState) ? this.time : this.viewerTime
  }

  get currentElapsedTime() {
    return this.startTime + this.currentTime
  }

  get currentPlaybackRate() {
    return (this.dataStore.isEditingState || this.dataStore.isTablingState) ? this.playbackRate : this.viewerPlaybackRate
  }

  get currentLoopStatus() {
    return (this.dataStore.isEditingState || this.dataStore.isTablingState) ? this.looping : this.viewerLoop
  }

  loadInteraction(interaction) {
    this.cache.unwatch()
    this.interaction.off('INTERACTION_CHANGES', this._interactionChangeHandler)
    this.interaction = interaction
    this._initInteraction()
    this.prepareActionTransition()
  }

  _initInteraction() {
    this._firstTimeCacheInit = true
    this.cache.watches()
    // const actionId = this.interaction.getActionList()[0]
    const actionId = this.interaction._getCurrentActionId()
    if (actionId) {
      const action = this.interaction.getAction(actionId)
      this._loadAction(action)
    }
    this._interactionChangeHandler = this._handleActionChange.bind(this)

    this.interaction.on('INTERACTION_CHANGES', this._interactionChangeHandler)
  }

  _handleActionChange({ UPDATE }) {
    const actionChanges = UPDATE.get(this.actionId)
    if (actionChanges) {
      Object.entries(IM_PROPS_MAP).forEach(([key, propKey]) => {
        const change = actionChanges.get(key)
        if (change) {
          this.set(propKey, change.after)
        }
      })
    }
  }

  _loadAction(action) {
    this.actionId = action.id
    Object.entries(IM_PROPS_MAP).forEach(([key, propKey]) => {
      this.set(propKey, action[key])
    })
  }

  /**
   * Handle dataStore mode switch between DESIGN and ACTION
   * @param {Mode} mode 
   */
  handleModeSwitch(mode) {
    this.changeMode(mode)
  }

  /**
   * @param {Element[]} [elements] list of elements for which to update animation data
   * @param {number} [time]   if not specified, current time is used
   */
  updateElementData(elements, time) {
    if (this.mode !== Mode.ACTION) {
      return
    }

    this.cache.updateElementData(isNull(time) ? this.currentElapsedTime : time, elements)
  }

  forceUpdateAnimation() {
    this.setPlayheadTime(this.currentTime, true, false)
  }

  changeViewerPlaybackRate(rate) {
    this.set('viewerPlaybackRate', rate)
  }

  changeViewerLoop(loop) {
    this.set('viewerLoop', loop)
  }

  calculateUpdatedTime(targetTime, forceUpdate, currentTime, endTime) {
    if (forceUpdate) {
      return targetTime;
    }

    if (currentTime > endTime) {
      return 0;
    }

    return Math.max(Math.min(endTime, targetTime), 0);
  }

  /**
   * set playhead time
   * @param {number} time
   * @param {bool} [forceUpdate=false]
   * @param {bool} [fire=true]
   */
  setPlayheadTime(time, forceUpdate = false, fire = true) {
    const updatedTime = this.calculateUpdatedTime(time, forceUpdate, this.currentTime, this.endTime);

    if (updatedTime === this.currentTime && !forceUpdate) {
      return;
    }
    Stats.begin('tm/setTime')
    this.dataStore.startTransaction()
    this.cache.updateElementData(this.startTime + updatedTime, null, true)
    this.dataStore.endTransaction()
    this.setTime(updatedTime, fire)
    Stats.end('tm/setTime')
  }

  setTime(time, fire = true) {
    const fireKey = (this.dataStore.isEditingState || this.dataStore.isTablingState) ? 'time' : 'viewerTime'
    this.set(fireKey, time, fire)
  }

  /**
   * Change transition manager mode
   * @param {string} mode
   */
  changeMode(mode) {
    // TODO: do we need this here?
    //   all things bellow (when switching back to design mode)
    //   don't matter as all element stacks are deleted right here
    // this.clear()
    this.set('mode', mode)
    switch (mode) {
      case Mode.DESIGN:
        this.stop()
        this.cache.updateElementAnimationStatus(false)
        break
      case Mode.ACTION:
        this.startTime = 0
        this.cache.resetElementStartTime(this.startTime)
        this.cache.updateElementAnimationStatus(true)
        this.prepareActionTransition()
        this.cache.updateDefaultKF()
        this.setPlayheadTime(this.currentTime, true)
        break
    }

    // TODO: Will have Prototype mode in the future
  }

  /**
   * get playhead time
   * @returns {number}
   */
  getTime() {
    return this.time
  }

  goToEnd() {
    this.setPlayheadTime(this.startTime + this.endTime)
  }

  play() {
    if (!this.dataStore.isActionMode) {
      return
    }
    if (this.currentTime >= this.endTime) {
      this.setTime(0)
    }
    this.startTime = performance.now() - this.currentTime / this.currentPlaybackRate
    this.cache.resetElementStartTime(this.startTime);
    this.set('status', TRANSITION_STATUS.START);
    this._nextTick(this.startTime + this.currentTime / this.currentPlaybackRate)
  }

  // Hold current settings, so that anyone can use TM to do whatever they want.
  // And after that, they need to resume TM to get the original status.
  hold() {
    this._holding = [
      { key: 'time', value: this.currentTime, type: 'data', fire: false },
      { key: 'startTime', value: this.startTime, type: 'prop', fire: true }
    ]
    this.startTime = 0
    this.cache.resetElementStartTime(0)
    this.setPlayheadTime(0, true, false)
  }

  movePlayheadOut() {
    // Because IM will add the kf at the current playhead time.
    // If we want to add multiple kfs with different time, then we should move playhead out of the start time and end time
    // so that there won't have any kf be override.
    this._disabled = true
    this._tmpTime = this.currentTime
    this.setTime(-1000, false)
  }

  movePlayheadBack() {
    // After finish adding multiple kfs with different time, we need to resume the settings.
    if (!this._disabled) {
      return
    }

    this.setTime(this._tmpTime, false)
  }

  // Resume TM to get the original status
  resume() {
    this._holding.forEach((hold) => {
      switch (hold.type) {
        case 'prop':
          this[hold.key] = hold.value
          break
        case 'data':
          this.set(hold.key, hold.value, hold.fire)
          break
      }
    })
    this.cache.resetElementStartTime(this.startTime)
    switch (this.mode) {
      case Mode.DESIGN:
        this.cache.reloadElements()
        break
      case Mode.ACTION:
        this.cache.updateElementData(this.startTime + this.currentTime)
        break
      default:
        // Might have other modes in the future
        break
    }
  }

  stop() {
    if (this.mode === TRANSITION_MODES.PREVIEW) {
      return false
    }

    // Round time to 10ms so that when user change value,
    // we can add the correct time as the same as what user can see on the Interaction UI.
    this.setPlayheadTime(Math.ceil(this.currentTime / 10) * 10)

    window.cancelAnimationFrame(this._raf)
    this.set('status', TRANSITION_STATUS.STOP);
  }

  reset() {
    this.startTime = 0
    this.cache.resetElementStartTime(0)
    this.setPlayheadTime(0)

    window.cancelAnimationFrame(this._raf)
    this.set('status', TRANSITION_STATUS.STOP);
  }

  _nextTick(time) {
    let elapsedTime = (time - this.startTime)
    if (this.mode === TRANSITION_MODES.PREVIEW) {
      this.setPlayheadTime(elapsedTime)
      this._raf = window.requestAnimationFrame(this._nextTickFn)
      return
    }
    Stats.begin('tm/tick')
    elapsedTime *= this.currentPlaybackRate

    if (elapsedTime > this.endTime) {
      if (this.currentLoopStatus) {
        elapsedTime = 0
        this.startTime = time
        this.cache.resetElementStartTime(time);
        this.setPlayheadTime(elapsedTime)
        this._raf = window.requestAnimationFrame(this._nextTickFn)
      } else {
        this.setPlayheadTime(this.endTime)
        this.stop()
      }
    } else {
      this.setPlayheadTime(elapsedTime)
      this._raf = window.requestAnimationFrame(this._nextTickFn)
    }
    Stats.end('tm/tick')
  }

  /**
   * Add custom event from outside
   * @param {string} key
   * @param {Function} fn
   */
  addCustomEvent(key, fn) {
    this.cache.addCustomEvent(key, fn);
  }

  /**
   * Initial Element stack with responses
   * @param {Response[]} responses
   */
  _initElementStack(responses) {
    responses.forEach((response) => {
      response.elementTrackMap.forEach((elementTrackId) => {
        const elementTrack = this.interaction.getElementTrack(elementTrackId)
        const elementId = elementTrack.elementId
        if (elementId) {
          this.cache.initElementStack(elementId, elementTrack)
        }
      })
    })
    this.cache.updateMaxKFTime()
  }

  /**
   * Prepare transition for Action mode
   */
  prepareActionTransition() {
    if (this._firstTimeCacheInit) {
      this._firstTimeCacheInit = false
      if (this.actionId) {
        const responseId = this.interaction.getResponseList(this.actionId)[0]
        if (responseId) {
          const response = this.interaction.getResponse(responseId)
          this._initElementStack([response])
        }
      }
    } else {
      // create computed layers for non-base layers (if any)
      for (const elementStack of this.cache.elementsStack.values()) {
        elementStack.createNonBaseComputedLayers()
      }
    }
    this.cache.cacheAllElementsBaseValue()
  }

  /**
   * Prepare transition for Preview mode
   */
  _preparePreviewTransition() {
    // TODO: No need Preview transition for MVP
    // TODO: Only need to bind the events for the current screen.
    //       And all the screens for screen enter/leave event.
    const actionList = this.interaction.getActionList()
    const responses = actionList
      .map((actionId) => this.interaction.getAction(actionId).responseList)
      .flat()
      .map((responseId) => this.interaction.getResponse(responseId))
    this._initElementStack(responses);
    this.event.bindActions(actionList);

  }

  /**
   * Reset Preview Transition and restart it
   */
  resetPreviewTransition() {
    this.clear();
    this._preparePreviewTransition();
    this.play();
  }

  /**
   * Get current keyframe state by property
   * @param {string} elementId
   * @param {string} propKey
   * @param {string} layerOrEffectName
   * @param {string} cKey
   * @returns {FrameType}
   */
  getElementKeyframeState(elementId, propKey, layerOrEffectName, cKey) {
    return this.cache.getElementKeyframeState(elementId, propKey, layerOrEffectName, cKey)
  }

  /**
   * Get property value by time for specific element
   * @param {string} elementId
   * @param {string} propKey
   * @param {number} time
   * @returns {number | object}
   */
  getPropertyValueByTime(elementId, propKey, time) {
    const t = this.startTime + time
    if (propKey === 'x' || propKey === 'y' || propKey === 'position') {
      const widthData = this.cache.getPropertyValueByTime(elementId, 'width', t)
      const heightData = this.cache.getPropertyValueByTime(elementId, 'height', t)
      const origin = this.cache.getPropertyValueByTime(elementId, 'origin', t)
      const originInPixel = getOrigin(origin, widthData.width, heightData.height)
      const translateXData = this.cache.getPropertyValueByTime(elementId, 'translateX', t)
      const translateYData = this.cache.getPropertyValueByTime(elementId, 'translateY', t)

      return {
        x: translateXData.translateX - (originInPixel.originX || 0),
        y: translateYData.translateY - (originInPixel.originY || 0)
      }
    }

    return this.cache.getPropertyValueByTime(elementId, propKey, t)
  }
  
  getPropertyValue(elementId, propKey) {
    if (propKey === 'x' || propKey === 'y' || propKey === 'position') {
      const widthData = this.cache.getPropertyValue(elementId, 'width')
      const heightData = this.cache.getPropertyValue(elementId, 'height')
      const origin = this.cache.getPropertyValue(elementId, 'origin')
      const originInPixel = getOrigin(origin, widthData.width, heightData.height)
      const translateXData = this.cache.getPropertyValue(elementId, 'translateX')
      const translateYData = this.cache.getPropertyValue(elementId, 'translateY')

      return {
        x: translateXData.translateX - (originInPixel.originX || 0),
        y: translateYData.translateY - (originInPixel.originY || 0)
      }
    }
    return this.cache.getPropertyValue(elementId, propKey)
  }
  getPropertyTrackValueByTime(elementId, propKey, time) {
    return this.cache.getPropertyTrackValueByTime(elementId, propKey, this.startTime + time)
  }

  getLayerTrackInitValue(elementId, trackId) {
    const elementStack = this.cache.getElementStack(elementId)
    const layerStack = elementStack.propertyStacksMap.get(trackId)
    return layerStack.getInitValue()
  }

  /**
   * Get keyframe value by id
   * @param {string} kfId
   * @returns {number|object}
   */
  getKeyframeValue(kfId) {
    return this.cache.getKeyframeValue(kfId)
  }

  cacheSpecificElementBaseValue(elementId, propKey) {
    this.cache.cacheSpecificElementBaseValue(elementId, propKey)
  }

  /**
   * Get working interval for specific property
   * @param {string} elementId
   * @param {string} propKey
   * @param {number} time
   * @returns {Interval}
   */
  getPropertyWorkingInterval(elementId, propKey, time) {
    return this.cache.getPropertyWorkingInterval(elementId, propKey, time)
  }

  /**
   * Get working interval progress for specific property
   * @param {string} elementId
   * @param {string} propKey
   * @returns {number}
   */
  getPropertyWorkingIntervalProgress(elementId, propKey) {
    const interval = this.getPropertyWorkingInterval(elementId, propKey)
    return getProgress(this.currentTime, interval)
  }

  /**
   * Get start value with current value and the working interval
   * @param {Interval} interval
   * @param {number} currentValue
   * @param {number} time
   * @returns {number}
   */
  getStartValueWithCurrentValueAndInterval(interval, currentValue, time) {
    if (!interval || currentValue === undefined || time === undefined) {
      return
    }

    // d = e - s
    // r = d * p + s
    //   = (e - s) * p + s = e * p + (1 - p) * s
    // => s = (r - e * p) / (1 - p)
    const progress = getProgress(time, interval)
    const percent = getPropPercentage(interval[1], progress)
    const startValue = (currentValue - interval[1].value * percent) / (1 - percent)
    return startValue
  }

  /**
   * update the default KF and the interval data
   */
  updateDefaultKFAndInterval() {
    this.cache.updateDefaultKFAndInterval()
  }

  /**
   * Update specific property default keyframe and interval
   * @param {string} elementId
   * @param {string} propKey
   */
  updateSpecificPropertyDefaultKFAndInterval(elementId, propKey) {
    this.cache.updateSpecificPropertyDefaultKFAndInterval(elementId, propKey)
  }

  /**
   * Clear all caches
   */
  clear() {
    this.stop()
    this._firstTimeCacheInit = true
    this.cache.clearAll()
    this.event.clear()
    this.status = TRANSITION_STATUS.INIT
    this.startTime = 0
    this.viewerTime = 0
  }

  _debug() {
    return this.cache._debug()
  }
}

export default TransitionManager;
