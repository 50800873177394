import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { LayerType } from '@phase-software/types'
import PropTypes from 'prop-types'

import { STROKE_JOIN } from '../../../../constant'
import { useDataStoreActions } from '../../../../providers/dataStore/DataStoreProvider'
import { useEditorActions } from '../../../../providers/dataStore/EditorProvider'
import { useStroke } from '../../../../providers/dataStore/StrokeProvider'
import { Select } from '../../../shared'
import { translateMenuOptions } from '../../../shared/Menu/utils'

const options = [
  {
    name: 'miter',
    value: STROKE_JOIN.MITER,
    icon: 'JoinMiter'
  },
  {
    name: 'round',
    value: STROKE_JOIN.ROUND,
    icon: 'JoinRound'
  },
  {
    name: 'bevel',
    value: STROKE_JOIN.BEVEL,
    icon: 'JoinBevel'
  }
  // NOTE: out of MVP
  // TODO: need to re-evaluate the generation method post-MVP
  // {
  //   name: 'Concave',
  //   value: STROKE_JOIN.CONCAVE,
  //   icon: 'JoinConcave'
  // },
  // {
  // name: 'None',
  // value: STROKE_JOIN.NONE,
  // icon: 'JoinNone'
  // }
]

type JoinShapeContentProps = {
  layerItemId: string
}

const JoinShapeContent = ({ layerItemId, ...rest }: JoinShapeContentProps) => {
  const { t } = useTranslation()
  const join = useStroke((o) => o[layerItemId].join)
  const { setLayerJoin, toggleLayerVisible } = useEditorActions()
  const { commitUndo } = useDataStoreActions()
  const handleChange = (v: string) => {
    setLayerJoin(layerItemId, v)
    commitUndo()
  }

  const keyFrameIconProps = useMemo(
    () => ({
      layerItemId,
      name: 'join',
      layerType: LayerType.STROKE
    }),
    [layerItemId]
  )

  const translatedOptions = useMemo(
    () =>
      translateMenuOptions(t, options, {
        ns: 'file',
        keyPrefix: 'stroke_join'
      }),
    [t]
  )

  return (
    <div data-test-id="border-join-shape-select">
      <Select
        // @ts-ignore TODO: fix after this component is being used
        caret
        keyFrameIconProps={keyFrameIconProps}
        variant="normal"
        name="joinShape"
        options={translatedOptions}
        onChange={handleChange}
        onFocus={() => toggleLayerVisible(layerItemId, true)}
        value={join}
        {...rest}
      />
    </div>
  )
}
JoinShapeContent.propTypes = {
  layerItemId: PropTypes.string.isRequired
}

export default React.memo(JoinShapeContent)
