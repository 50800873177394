import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { WorkspaceType } from '@phase-software/types'

import { useProfile } from '../../providers/ProfileProvider'
import { clearTokens, getAuthorize, isLogin } from '../../services/cognito'
import { isMobileOrTabletOS } from '../../utils/device-detect'
import { getWorkspaceDraftsPath } from '../../utils/pathGenerators'

const withAuthentication = (WrappedComponent: React.ComponentType<any>) => {
  const Authentication = (props: any) => {
    const { location } = props
    if (!isLogin()) {
      getAuthorize()
      return null
    }
    if (isMobileOrTabletOS()) {
      clearTokens()
      window.location.href = `${window.location.origin}/mobile-warning`
      return null
    }

    if (location?.state && location?.state.from) {
      const params = new URLSearchParams(location.state.from.search)
      const cb = params.get('cb')
      if (cb) {
        window.location.href = cb
        return null
      }
    }
    return <WrappedComponent {...props} />
  }

  return Authentication
}

export const RedirectToLogin = () => {
  const profile = useProfile()
  const history = useHistory()
  useEffect(() => {
    if (!isLogin() || !profile) {
      getAuthorize()
    } else {
      if (isMobileOrTabletOS()) {
        clearTokens()
        window.location.href = `${window.location.origin}/mobile-warning`
      } else {
        history.push(getWorkspaceDraftsPath(WorkspaceType.PERSONAL, profile.custom_slug))
      }
    }
  }, [history, profile])
  return null
}

export default withAuthentication
