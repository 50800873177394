import { AnimationFeature } from './animation'
import { LayerFeature } from './layer'
import { ShapeFeature } from './shape'
import { EffectFeature } from './effect'
import { TransformFeature } from './transform'

export * from './animation'
export * from './layer'
export * from './shape'
export * from './effect'
export * from './transform'

export const Feature = {
  ...AnimationFeature,
  ...LayerFeature,
  ...ShapeFeature,
  ...EffectFeature,
  ...TransformFeature,
  EXPRESSIONS: 'EXPRESSIONS'
}
