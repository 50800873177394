import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import { ContextMenu } from '../../../components/shared'
import { MenuOptionProps } from '../../../components/shared/Menu/MenuOption'
import { translateMenuOptions } from '../../../components/shared/Menu/utils'
import { FILE_OPTIONS, TEMPLATE_OPTIONS } from '../../../constants/fileConstants'
import { useModal, useSetModal } from '../../../providers/ModalProvider'
import { useWorkspaceContext } from '../../../providers/WorkspaceContextProvider'
import { getTemplatePath } from '../../../utils/pathGenerators'

type FileContextMenuProps = {
  templateId: string
}

const TemplateDropdownMenu = ({ templateId }: FileContextMenuProps) => {
  const { t } = useTranslation('workspace')
  const {
    open,
    data: { trigger }
  } = useModal((o: any) => o[`TemplateDropdownMenu-${templateId}`])
  const history = useHistory()
  const { closeModal: closeDropdownMenu } = useSetModal(`TemplateDropdownMenu-${templateId}`)
  const { workspaceData } = useWorkspaceContext()

  const translatedMenuOptions = useMemo(
    () =>
      translateMenuOptions(t, TEMPLATE_OPTIONS, {
        ns: 'workspace'
      }),
    [t]
  )

  const handleSelectOption = (option: MenuOptionProps) => {
    switch (option.value) {
      case FILE_OPTIONS.OPEN_IN_NEW_TAB: {
        const url = history.createHref({
          pathname: getTemplatePath(templateId, workspaceData.type, workspaceData.slug)
        })
        window.open(url, '_blank')
        break
      }

      default:
        break
    }
  }

  return (
    <ContextMenu
      open={open}
      options={translatedMenuOptions}
      onSelect={handleSelectOption}
      onClose={closeDropdownMenu}
      trigger={trigger}
    />
  )
}

export default TemplateDropdownMenu
