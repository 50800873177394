import { TeamFieldsFragment } from '../generated/graphql'
import { teamService } from '../services/api'

const useTeamActions = () => {
  const createTeam = (name: string) => teamService.teamApiControllerCreateTeam({ createTeamRequest: { name } })

  const updateTeamProfile = ({
    teamId,
    name,
    description,
    location
  }: {
    teamId: TeamFieldsFragment['id']
    name: string
    description: string
    location: string
  }) =>
    teamService.teamApiControllerPatchTeam({
      teamId,
      patchTeamRequest: {
        name,
        description,
        location,
        updateMask: ['name', 'description', 'location']
      }
    })

  const updateAvatar = async (teamId: TeamFieldsFragment['id'], file: File) => {
    const { url: uploadUrl } = await teamService.teamApiControllerUpdateAvatar({
      teamId,
      updateTeamIconRequest: { contentType: file.type }
    })
    await fetch(uploadUrl, { method: 'PUT', body: file })
  }

  const removeAvatar = (teamId: TeamFieldsFragment['id']) =>
    teamService.teamApiControllerUpdateAvatar({
      teamId,
      updateTeamIconRequest: { removeIcon: true }
    })

  const createDeleteTeamRequest = (teamId: TeamFieldsFragment['id']) =>
    teamService.teamApiControllerCreateDeleteIntent({
      teamId
    })

  const resendDeleteTeamRequest = (teamId: TeamFieldsFragment['id'], intentId: string) =>
    teamService.teamApiControllerResendDeleteIntentEmail({
      teamId,
      intentId
    })

  const cancelDeleteTeamRequest = (teamId: TeamFieldsFragment['id'], intentId: string) =>
    teamService.teamApiControllerCancelDeleteIntent({
      teamId,
      intentId
    })

  const confirmDeleteTeamRequest = (teamId: TeamFieldsFragment['id'], intentId: string, code: string) =>
    teamService.teamApiControllerConfirmDeleteIntent({
      teamId,
      intentId,
      confirmDeleteIntentRequest: {
        code
      }
    })

  const createTransferOwnershipRequest = (teamId: TeamFieldsFragment['id'], toUserId: string) =>
    teamService.teamApiControllerCreateOwnershipTransferIntent({
      teamId,
      createOwnershipTransferIntentRequest: {
        toUserId
      }
    })

  const resendTransferOwnershipRequest = (teamId: TeamFieldsFragment['id'], intentId: string) =>
    teamService.teamApiControllerResendOwnershipTransferIntentEmail({
      teamId,
      intentId
    })

  const confirmTransferOwnershipRequest = (teamId: TeamFieldsFragment['id'], intentId: string, code: string) =>
    teamService.teamApiControllerConfirmOwnershipTransferIntent({
      teamId,
      intentId,
      confirmOwnershipTransferIntentRequest: {
        code
      }
    })

  const cancelTransferOwnershipRequest = (teamId: TeamFieldsFragment['id'], intentId: string) =>
    teamService.teamApiControllerCancelOwnershipTransferIntent({
      teamId,
      intentId
    })

  return {
    cancelDeleteTeamRequest,
    cancelTransferOwnershipRequest,
    confirmDeleteTeamRequest,
    confirmTransferOwnershipRequest,
    createDeleteTeamRequest,
    createTeam,
    createTransferOwnershipRequest,
    removeAvatar,
    resendDeleteTeamRequest,
    resendTransferOwnershipRequest,
    updateAvatar,
    updateTeamProfile
  }
}

export default useTeamActions
