import PropertyStack from '../../PropertyStack'

class BorderWidthStack extends PropertyStack {
  constructor(elementStack, data) {
    super(elementStack, data)
    this.type = 'BORDER_WIDTH'
    this.key = 'borderWidth'
    this.dataKey = 'borderWidth'
    this.animatableProperties = new Set(['borderWidth'])
  }
}

export default BorderWidthStack
