import { PERMISSIONS } from '../access-control'
import { MenuListOptionProps } from '../components/shared/Menu/Menu.types'

export const PROJECT_OPTIONS = {
  OPEN_IN_NEW_TAB: 'OPEN_IN_NEW_TAB',
  COPY_LINK: 'COPY_LINK',
  RENAME: 'RENAME',
  DELETE: 'DELETE'
}

const OPEN_IN_NEW_TAB_OPTION = {
  name: 'common:menu.open_in_new_tab',
  value: PROJECT_OPTIONS.OPEN_IN_NEW_TAB,
  dataTestId: 'project-open-new-tab'
}

const COPY_LINK_OPTION = {
  name: 'common:menu.copy_link',
  value: PROJECT_OPTIONS.COPY_LINK,
  dataTestId: 'project-copy-link'
}

const RENAME_OPTION = {
  name: 'common:menu.rename',
  value: PROJECT_OPTIONS.RENAME,
  dataTestId: 'project-rename',
  permission: PERMISSIONS.RENAME_PROJECT
}
const DELETE_OPTION = {
  name: 'common:menu.delete',
  value: PROJECT_OPTIONS.DELETE,
  dataTestId: 'project-delete',
  permission: PERMISSIONS.DELETE_PROJECT
}

export const PROJECT_DROPDOWN_OPTIONS: MenuListOptionProps[] = [
  OPEN_IN_NEW_TAB_OPTION,
  RENAME_OPTION,
  '-',
  DELETE_OPTION
]

export const PROJECT_CONTEXT_MENU_OPTIONS: MenuListOptionProps[] = [
  OPEN_IN_NEW_TAB_OPTION,
  COPY_LINK_OPTION,
  RENAME_OPTION,
  '-',
  DELETE_OPTION
]
