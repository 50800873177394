import { takeSnapshotAsBlob } from '@phase-software/renderer'
import { THUMBNAIL_WIDTH, MAX_THUMBNAIL_HEIGHT } from '../constant'

/**
 * Commit undo
 * @param {DataStore} dataStore
 */
export const commitUndo = (dataStore) => {
  dataStore.get('undo').commit()
}

/**
 * Get screen data from DataStore Element instance
 * @param {ElementInstance} screen - element with screen type
 * @returns {ScreenData}
 */
export const getScreenData = (screen) => {
  return {
    ...screen.gets('id', 'name', 'size'),
    parent: screen.get('parent').get('id')
  }
}

/**
 * Get the thumbnail size from given element size
 * @param {number[]} size
 * @returns {number[]} size for thumbnail
 */
export const getThumbnailSize = (size) => {
  const [width, height] = size
  let newWidth = THUMBNAIL_WIDTH.S
  if (width > 1450) {
    newWidth = THUMBNAIL_WIDTH.L
  } else if (width > 450) {
    newWidth = THUMBNAIL_WIDTH.M
  }

  return [newWidth, Math.min((height * newWidth) / width, MAX_THUMBNAIL_HEIGHT)]
}

/**
 * Get the scale ratio of the thumbnail
 * @param {number[]} size
 * @returns {number} scale for thumbnail
 */
export const getThumbnailScale = (size) => {
  const width = getThumbnailSize(size)[0]
  return width === THUMBNAIL_WIDTH.XL ? THUMBNAIL_WIDTH.XL / 1920 : width / size[0]
}

/**
 * Get delay and duration for two secondTransitions
 * @param {object} firstTransition
 * @param {object} secondTransition
 * @returns {object}
 */
export const getTransitionDelayDuration = (firstTransition, secondTransition) => {
  const delay = Math.min(firstTransition.delay, secondTransition.delay)
  const duration =
    Math.max(firstTransition.delay + firstTransition.duration, secondTransition.delay + secondTransition.duration) -
    delay

  return { delay, duration }
}

/**
 * Get delay and duration with children
 * @param {Array} children
 * @param {object} transitions
 * @returns {object}
 */
export const getChildrenTransitionDelayDuration = (children, transitions) => {
  const newTransition = {}
  children.forEach((childId, idx) => {
    const transition = { ...transitions[childId] }

    if (idx === 0) {
      const firstTransition = transition
      newTransition.delay = firstTransition.delay
      newTransition.duration = firstTransition.duration
    } else {
      const nextTransition = transition
      const { delay, duration } = getTransitionDelayDuration(newTransition, nextTransition)
      newTransition.delay = delay
      newTransition.duration = duration
    }
  })

  return newTransition
}

/**
 * Get screens data
 * @param {Workspace[]} workspaceList
 * @returns {Array}
 */
export const getScreens = (workspaceList) => {
  return workspaceList
    .map((ws) => ws.children)
    .flat()
    .filter((el) => el.get('elementType') === 'SCREEN')
    .map(getScreenData)
}

/**
 * Get screen data
 * @param {Element} el
 * @returns {Element}
 */
export const getScreen = (el) => {
  if (!el) return null
  const { type, elementType } = el.gets('type', 'elementType')
  if (type === 'WORKSPACE') return null
  if (elementType === 'SCREEN') return el
  return getScreen(el.get('parent'))
}

/**
 * Get selected screen
 * @param {DataStore} dataStore
 * @returns {Element}
 */
export const getSelectedScreen = (selection) => {
  return selection.map(getScreen).filter((s) => !!s)[0]
}

/**
 * Get screenshot
 * @param {DataStore} dataStore
 * @param {Element} screen
 * @returns {string}
 */
export const getScreenShot = (dataStore, screen) => {
  if (!screen || !screen.id) {
    return Promise.resolve()
  }

  const element = dataStore.getById(screen.id)
  return takeSnapshotAsBlob([element], 'jpeg').then((blob) => {
    if (!blob) return null
    return window.URL.createObjectURL(blob)
  })
}

/**
 * Wait until a condition becomes true.
 * @param {Function} conditionFunction - A function that returns true when the condition is met.
 * @returns {Promise} A promise that resolves when the condition is true.
 */
export const waitUntil = (conditionFunction) => {
  return new Promise((resolve) => {
    const checkCondition = () => {
      if (conditionFunction()) {
        resolve()
      } else {
        window.requestAnimationFrame(checkCondition)
      }
    }

    checkCondition()
  })
}
