import {
    EntityType,
    ElementType,
    LayerComponentType,
    PropComponentType,
    ChangeType,
    GeometryType,
    IDType
} from '@phase-software/types'
import {
    id,
    loadId,
    isNull,
    isArr,
    Change,
    Undoable,
    NO_FIRE,
    BASE_PROP_FULL_TO_SHORT_NAME
} from '@phase-software/data-utils'
import { LayerListKeyList } from '../dist'
import { EffectList } from './constant'


/** @typedef {import('./constant').ComponentDataMap} ComponentDataMap */

export const GENERAL_PROPERTIES = [
    'translate',
    'dimensions',
    'rotation',
    'opacity',
    'referencePoint',
    'contentAnchor',
    'scale',
    'skew'
]

export const EXTRA_PROPERTIES = [
    'blurGaussian',
]

export const GEOMETRY_PROPERTIES = [
    'cornerRadius'
]

export const CONTAINER_PROPERTIES = [
    'overflow'
]

export const TEXT_PROPERTIES = [
    'font',
    'textAlignment',
    'textDecoration',
    'textDirection',
]

export const LAYER_PROPERTIES = new Set(LayerListKeyList)

export const EFFECT_PROPERTIES = new Set(EffectList)

const PROP_NAME_TO_COMPONENT_TYPE_MAP = {
    translate: PropComponentType.TRANSLATE,
    dimensions: PropComponentType.DIMENSIONS,
    rotation: PropComponentType.ROTATION,
    opacity: PropComponentType.OPACITY,
    referencePoint: PropComponentType.REFERENCE_POINT,
    contentAnchor: PropComponentType.CONTENT_ANCHOR,
    blurGaussian: PropComponentType.BLUR_GAUSSIAN,
    scale: PropComponentType.SCALE,
    skew: PropComponentType.SKEW,
    cornerRadius: PropComponentType.CORNER_RADIUS,
    overflow: PropComponentType.OVERFLOW,
    font: PropComponentType.FONT,
    textAlignment: PropComponentType.TEXT_ALIGNMENT,
    textDecoration: PropComponentType.TEXT_DECORATION,
    textDirection: PropComponentType.TEXT_DIRECTION,
    fills: LayerComponentType.FILL,
    strokes: LayerComponentType.STROKE,
    shadows: LayerComponentType.SHADOW,
    innerShadows: LayerComponentType.INNER_SHADOW,
    effects: PropComponentType.EFFECT
}

export const NORMAL_ELEMENT_PROPS = [
    ...GENERAL_PROPERTIES,
    ...LAYER_PROPERTIES,
    ...EFFECT_PROPERTIES
]

export const PATH_ELEMENT_PROPS = [
    ...GENERAL_PROPERTIES,
    ...GEOMETRY_PROPERTIES,
    ...LAYER_PROPERTIES,
    ...EFFECT_PROPERTIES
]

export const CONTAINER_ELEMENT_PROPS = [
    ...GENERAL_PROPERTIES,
    ...GEOMETRY_PROPERTIES,
    ...CONTAINER_PROPERTIES,
    ...LAYER_PROPERTIES,
    ...EFFECT_PROPERTIES
]

export const TEXT_ELEMENT_PROPS = [
    ...GENERAL_PROPERTIES,
    ...TEXT_PROPERTIES,
    ...LAYER_PROPERTIES,
    ...EFFECT_PROPERTIES
]

export const NONREPEATABLE_PROPERTIES = [
    ...GENERAL_PROPERTIES,
    ...EXTRA_PROPERTIES,
    ...GEOMETRY_PROPERTIES,
    ...CONTAINER_PROPERTIES,
    ...TEXT_PROPERTIES
]

export const PROPERTIES = [
    ...GENERAL_PROPERTIES,
    ...EXTRA_PROPERTIES,
    ...GEOMETRY_PROPERTIES,
    ...CONTAINER_PROPERTIES,
    ...TEXT_PROPERTIES,
    ...LAYER_PROPERTIES,
    ...EFFECT_PROPERTIES
]

const OVERRIDABLE_PROPERTIES = new Set([
    'translate',
    'dimensions',
    'referencePoint'
])

const ELEM_TYPE_TO_PROPS = {
    [ElementType.TEXT]: TEXT_ELEMENT_PROPS,
    [ElementType.CONTAINER]: CONTAINER_ELEMENT_PROPS,
    [ElementType.SCREEN]: CONTAINER_ELEMENT_PROPS,
    [ElementType.PATH]: PATH_ELEMENT_PROPS
}

/**
 * @fires BASE_CHANGES
 */
export default class Base extends Undoable {
    /**
     * @param {DataStore} dataStore
     * @param {string} elementId
     * @param {BaseData} data
     * @param {bool} [createEmpty=false]   if set to true will skip creating or loading components
     */
    constructor(dataStore, elementId, data, createEmpty = false) {
        super(dataStore, ChangeType.PROPERTY, 'BASE_CHANGES')
        this.dataStore = dataStore
        this.elementId = elementId

        if (createEmpty) {
            return
        }

        if (isNull(data)) {
            this.create()
        } else {
            this.load(data)
        }
    }

    /**
     * Create base and create components
     */
    create() {
        this.id = id(IDType.BASE)
        this.type = EntityType.BASE
        const el = this.dataStore.getById(this.elementId)
        this.elementType = el.get('elementType')
        this.geometryType = el.get('initGeometryType')
        this.components = new Set()

        const propNameList = elementTypeToPropList(this.elementType)
        for (const propName of propNameList) {
            this._createNewComponenet(propName)
        }

        this._ensureFillAndBorderForNewElements()

        this.components = this._createComponentSet()
    }

    /**
     * loads serialized base data
     * @param {StyleData} data
     */
    load(data) {
        // Update IdCounter for Base
        loadId(data.id, IDType.BASE)

        const el = this.dataStore.getById(this.elementId)
        this.elementType = el.get('elementType')
        this.geometryType = el.get('initGeometryType')

        if (data.override) {
            this.create(IDType.BASE)

            // override props data
            let propName, propData
            for (propName of OVERRIDABLE_PROPERTIES) {
                propData = data[propName]
                if (!propData) {
                    continue
                }
                this.dataStore.library.setProperty(this[propName], propData, false)
            }
        } else {
            this.id = data.id || id(IDType.BASE)
            this.type = EntityType.BASE
            const propNameList = elementTypeToPropList(this.elementType)
            for (const propName of propNameList) {
                const shortName = BASE_PROP_FULL_TO_SHORT_NAME[propName] || propName
                const propData = data[shortName] || data[propName]
                if (LAYER_PROPERTIES.has(propName) || EFFECT_PROPERTIES.has(propName)) {
                    this[propName] = propData ? [...propData] : undefined
                } else {
                    this[propName] = propData
                }
            }

            this.components = this._createComponentSet()
        }
    }

    /**
     * clone base
     * @param {string} elementId    id of Element it is cloned to
     * @param {object} [overrides]  data with overrides for overridable properties
     * @returns {Base}
     */
    clone(elementId, overrides = {}) {
        if (!elementId) {
            throw new Error('`elementId` parameter is required')
        }

        const cloned = new this.constructor(this.dataStore, elementId, undefined, true)
        for (const propName of PROPERTIES) {
            if (!this[propName]) {
                continue
            }

            if (LAYER_PROPERTIES.has(propName) || EFFECT_PROPERTIES.has(propName)) {
                cloned[propName] = this[propName].map(id =>
                    this.dataStore.library.cloneComponent(
                        id,
                        NO_FIRE,
                        overrides[propName]
                    )
                )
            } else {
                cloned[propName] = this.dataStore.library.cloneComponent(
                    this[propName],
                    NO_FIRE,
                    overrides[propName]
                )
            }
        }
        this.dataStore.library.fire()

        cloned.components = cloned._createComponentSet()
        return cloned
    }

    /**
     * serializes base data
     * @returns {BaseData}
     */
    save() {
        /** @type {BaseData} */
        const data = {}
        data.id = this.id
        data.type = this.type

        PROPERTIES.forEach(propName => {
            const shortName = BASE_PROP_FULL_TO_SHORT_NAME[propName]
            if (this[propName]) {
                if (LAYER_PROPERTIES.has(propName) || EFFECT_PROPERTIES.has(propName)) {
                    data[shortName] = [...this[propName]]
                } else {
                    data[shortName] = this[propName]
                }
            }
        })
        return data
    }

    /**
     * serialize base data into a flat map of components (with all nested components)
     * @param {ComponentDataMap} [savedMap] map of serialized components used in this Base (including nested components)
     * @returns {ComponentDataMap}  savedMap
     */
    saveComponentList(savedMap) {
        return this.dataStore.library.saveComponentList(this.allComponents(), savedMap)
    }

    /**
     * Set the property with component ID
     * @param {string} propName
     * @param {string|string[]|undefined} componentId
     */
    setProperty(propName, componentId) {
        const isLayerList = LAYER_PROPERTIES.has(propName)
        // copy list if its layer list; else use directly since it's id
        const before = isLayerList ? [...this[propName]] : this[propName]
        const change = new Change({
            before,
            // if undefined is passed, make layer list empty
            after: (isLayerList && !componentId) ? [] : componentId
        })

        this[propName] = (isLayerList && !isArr(componentId)) ? [componentId] : componentId
        this.changes.update(propName, change)
        this._updateComponentSet(before, change.after)
        this.fire()
    }

    /**
     * Undoing base changes
     * @param {BaseChange} bc
     */
    undo(bc) {
        bc.forEach(({before, after}, propName) => {
            this[propName] = before
            this._updateComponentSet(after, before)
        })
        super.undo(bc)
    }

    /**
     * Redoing base changes
     * @param {BaseChange} bc
     */
    redo(bc) {
        bc.forEach(({before, after}, propName) => {
            this[propName] = after
            this._updateComponentSet(before, after)
        })
        super.undo(bc)
    }

    /**
     * Update component set based on before/after changes
     * @param {string|string[]|undefined} before
     * @param {string|string[]|undefined} after
     */
    _updateComponentSet(before, after) {
        if (Array.isArray(before)) {
            before.forEach(id => this.components.delete(id))
        } else {
            this.components.delete(before)
        }

        if (Array.isArray(after)) {
            after.forEach(id => this.components.add(id))
        } else {
            this.components.add(after)
        }
    }

    /**
     * Create component set based on properties
     * @returns {Set<string>} set of component IDs
     */
    _createComponentSet() {
        const set = new Set()
        PROPERTIES.forEach(property => {
            if (!this[property]) {
                return
            }

            if (LAYER_PROPERTIES.has(property) || EFFECT_PROPERTIES.has(property)) {
                this[property].forEach(compId => set.add(compId))
            } else {
                set.add(this[property])
            }
        })
        return set
    }

    /**
     * Creates new component for specific property and assigns it to Base
     * @private
     * @param {string} propName
     * @returns {string} new component id
     */
    _createNewComponenet(propName) {
        const propType = PROP_NAME_TO_COMPONENT_TYPE_MAP[propName]
        const id = this.dataStore.library.addProperty(propType)

        if (LAYER_PROPERTIES.has(propName) || EFFECT_PROPERTIES.has(propName)) {
            this[propName] = [id]
        } else {
            this[propName] = id
        }
        return id
    }

    /**
     * Returns an iterable over all properties in the Base
     * including every layer component
     */
    *allComponents() {
        for (const propName of PROPERTIES) {
            if (!this[propName]) {
                continue
            }
            if (LAYER_PROPERTIES.has(propName) || EFFECT_PROPERTIES.has(propName)) {
                for (const id of this[propName]) {
                    yield id
                }
            } else {
                yield this[propName]
            }
        }
    }

    /**
     * Remaps components after clonning from data
     * @param {BaseData} baseData
     * @param {Map<string, string>} idMap  mapping of old component IDs to new ones
     */
    static remapDataComponents(baseData, idMap) {
        let newId
        for (const propName of PROPERTIES) {
            const shortName = BASE_PROP_FULL_TO_SHORT_NAME[propName]
            if (!baseData[shortName]) {
                continue
            }

            if (LAYER_PROPERTIES.has(propName)) {
                baseData[shortName] = baseData[shortName].map(id => {
                    newId = idMap.get(id)
                    if (!newId) {
                        throw new Error('Failed to map cloned layer');
                    }
                    return newId
                })
            } else if (EFFECT_PROPERTIES.has(propName)) {
                baseData[shortName] = baseData[shortName].map(id => {
                    newId = idMap.get(id)
                    if (!newId) {
                        throw new Error('Failed to map cloned effect');
                    }
                    return newId
                })
            } else {
                newId = idMap.get(baseData[shortName])
                if (!newId) {
                    throw new Error('Failed to map cloned component');
                }
                baseData[shortName] = newId
            }
        }
    }

    /**
     * Use it only in create()
     */
    _ensureFillAndBorderForNewElements() {
        // TODO: add one for text too
        if (this.elementType === ElementType.PATH) {
            if (this.geometryType === GeometryType.POLYGON || this.geometryType === GeometryType.LINE) {
                this.dataStore.library.addLayer(this.strokes[0])
            } else {
                this.dataStore.library.addLayer(this.fills[0])
            }
        }
    }

    clear() {
        this.components.forEach(id => this.dataStore.library.deleteComponent(id, false))
    }

    _debug() {
        if (process.env.NODE_ENV !== 'production') {
            const result = {}
            PROPERTIES.forEach(property => {
                result[property] = LAYER_PROPERTIES.has(property) || EFFECT_PROPERTIES.has(property)
                    ? this[property].map(layerComponentId => this.dataStore.library.getComponent(layerComponentId))
                    : this.dataStore.library.getComponent(this[property])
            })

            console.log(result)
        }
    }
}

/**
 *
 * @param {ElementType} elementType
 * @returns {[string]}
 */
function elementTypeToPropList(elementType) {
    return ELEM_TYPE_TO_PROPS[elementType] || NORMAL_ELEMENT_PROPS
}


/**
 * @typedef {object} BaseData
 * @property {string} id
 * @property {string} type
 * @property {string} translate
 * @property {string} dimensions
 * @property {string} rotation
 * @property {string} opacity
 * @property {string} referencePoint
 * @property {string} contentAnchor
 * @property {string|undefined} blurGaussian
 * @property {string|undefined} scale
 * @property {string|undefined} skew
 * @property {string|undefined} font
 * @property {string|undefined} textAlignment
 * @property {string|undefined} textDecoration
 * @property {string|undefined} textDirection
 * @property {string[]} fills
 * @property {string[]} strokes
 * @property {string[]} shadows
 * @property {string[]} innerShadows
 * @property {string[]} effects
 */
