import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { BooleanOperation, ContainerElementType, EditMode, ElementType } from '@phase-software/types'

import { useScrollContext } from '../../providers/ScrollProvider'
import { useEditor } from '../../providers/dataStore/EditorProvider'
import { useElementSelection } from '../../providers/dataStore/ElementSelectionProvider'
import { useUI } from '../../providers/dataStore/UIProvider'
import PropertyStateContextMenu from '../ContextMenus/PropertyStateContextMenu'
import { Content, FocusLoop, ScrollView, WideTitle } from '../shared'
import LockOverlay from '../shared/LockOverlay'
import EmptyState from './EmptyState'
import EffectEditor from './PropertyEditors/EffectEditor'
import ElementHeader from './PropertyEditors/ElementHeader'
import FillEditor from './PropertyEditors/FillEditor'
import LayoutEditor from './PropertyEditors/LayoutEditor'
import Position from './PropertyEditors/LayoutEditor/Position'
import LayoutTool from './PropertyEditors/LayoutTool'
import OpacityEditor from './PropertyEditors/OpacityEditor'
import { Overflow } from './PropertyEditors/Overflow'
import PathShapeEditor from './PropertyEditors/PathShapeEditor'
import PointShapeEditor from './PropertyEditors/PointShapeEditor'
import PropertyRow from './PropertyEditors/PropertyRow'
import StrokeEditor from './PropertyEditors/StrokeEditor'
import TransformEditor from './PropertyEditors/TransformEditor'

type PropertyEditorsProps = {
  elementIds: string[]
}

export const ScreenEditor = () => {
  return (
    <>
      <LayoutTool />
      <ElementHeader />
      <Content withHeader>
        <LayoutEditor />
      </Content>
      <Overflow />
      <FillEditor />
    </>
  )
}

export const PathLayoutEditor = ({ showIndicator }: { showIndicator: boolean }) => {
  return (
    <>
      <LayoutTool />
      <PathShapeEditor isHover={showIndicator} />
      <Content withHeader>
        <PropertyRow columns={['100%']}>
          <PointShapeEditor />
        </PropertyRow>
        <LayoutEditor />
      </Content>
    </>
  )
}

export const MotionPathEditor = () => {
  const { t } = useTranslation('file', { keyPrefix: 'property_editor.motion_path_editor' })
  return (
    <>
      <WideTitle>{t('title')}</WideTitle>
      <Content withHeader>
        <PropertyRow columns={['100%']}>
          <PointShapeEditor />
        </PropertyRow>
        <Position isShapeMode enableKF={false} />
      </Content>
    </>
  )
}

export const GeneralLayoutEditor = () => {
  return (
    <>
      <LayoutTool />
      <ElementHeader />
      <Content withHeader>
        <LayoutEditor />
      </Content>
    </>
  )
}
export const ElementLayoutEditor = ({ isPathMode }: { isPathMode: boolean }) => {
  const [hoverPath, setHoverPath] = useState(false)

  const handleMouserEnter = useCallback(() => {
    setHoverPath(true)
  }, [setHoverPath])

  const handleMouseLeave = useCallback(() => {
    setHoverPath(false)
  }, [setHoverPath])

  return (
    <div onMouseEnter={handleMouserEnter} onMouseLeave={handleMouseLeave}>
      {isPathMode ? <PathLayoutEditor showIndicator={hoverPath} /> : <GeneralLayoutEditor />}
    </div>
  )
}

export const PropertyEditors = ({ elementIds }: PropertyEditorsProps) => {
  const elementType = useEditor((o) => o.elementType)
  const containerType = useEditor((o) => o.containerType)
  const editMode = useUI((o) => o.editMode)
  const isScreen = elementType === ElementType.SCREEN
  const isContainer = elementType === ElementType.CONTAINER
  const isPathMode = editMode === EditMode.SHAPE
  const isMaskGroup = useEditor((o) => o.isMask)
  const isBooleanGroup = useEditor((o) => o.booleanType !== BooleanOperation.NONE)
  const isNormalGroup =
    isContainer && containerType === ContainerElementType.NORMAL_GROUP && !isMaskGroup && !isBooleanGroup
  const isComputedGroup = isMaskGroup || isBooleanGroup || isNormalGroup

  if (isScreen) {
    return <ScreenEditor />
  }

  if (editMode === EditMode.MOTION_PATH) {
    return <MotionPathEditor />
  }

  return (
    <>
      <ElementLayoutEditor isPathMode={isPathMode} />
      {!isComputedGroup && <Overflow />}
      {!isPathMode && <TransformEditor />}
      <OpacityEditor elementIds={elementIds} />
      {!isMaskGroup && !isNormalGroup && (
        <>
          <FillEditor />
          <StrokeEditor />
        </>
      )}
      {!isMaskGroup && <EffectEditor />}
    </>
  )
}

const Properties = () => {
  const isTargeting = useUI((o) => o.isTargeting)
  const selection = useElementSelection()
  const elementId = selection[0]
  const { scrollRef } = useScrollContext()

  if (!elementId) {
    return <EmptyState />
  }

  return (
    <>
      {/* @ts-ignore adjust when ScrollView has been refactored */}
      <ScrollView ref={scrollRef}>
        <FocusLoop>
          <PropertyEditors elementIds={selection} />
        </FocusLoop>
        {isTargeting && <LockOverlay style={{ top: 0, bottom: 0 }} />}
        <PropertyStateContextMenu />
      </ScrollView>
      <div className="bottom-of-property-panel flex-1" />
    </>
  )
}

export default Properties
