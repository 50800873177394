import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { TOOLBAR_OPTIONS } from '../../constants/fileEditorConstants'
import { FileFieldsFragment } from '../../generated/graphql'
import useHeapAnalytics from '../../hooks/useHeapAnalytics'
import { useSpinnerLoadingContext } from '../../providers/SpinnerLoadingProvider'
import { WorkspaceData } from '../../providers/WorkspaceContextProvider'
import { useDataStoreActions } from '../../providers/dataStore/DataStoreProvider'
import { useUI } from '../../providers/dataStore/UIProvider'
import { track } from '../../services/heapAnalytics'
import { ToolButton } from '../shared'
import { MenuListOptionProps } from '../shared/Menu/Menu.types'

type VersionHistoryButtonProps = {
  fileId: FileFieldsFragment['id']
  projectId: FileFieldsFragment['project_id']
  workspaceData: WorkspaceData
}

const VersionHistoryButton = ({ workspaceData, projectId, fileId }: VersionHistoryButtonProps) => {
  const versionHistoryOptions = useVersionHistoryOptions()
  const { switchState } = useDataStoreActions()
  const { startSpinnerLoadingWithTimeout } = useSpinnerLoadingContext()
  const { teamName, space } = useHeapAnalytics()
  const { t } = useTranslation('file')

  const location = projectId === workspaceData.draftProjectId ? 'drafts' : 'project'

  const onSelect = () => {
    startSpinnerLoadingWithTimeout()
    switchState('VERSIONING')
    track('Version History Opened', {
      fileId,
      teamName,
      location,
      space
    })
  }

  return (
    <ToolButton
      data-test-id="tool-menu"
      childClassName="px-8"
      options={versionHistoryOptions}
      tooltip={t('version_history')}
      onChange={onSelect}
    />
  )
}

export default VersionHistoryButton

const VERSION_HISTORY_OPTION = {
  name: 'Version history',
  value: TOOLBAR_OPTIONS.ENTER_VERSION_PREVIEW,
  icon: 'VersionHistory'
}

const useVersionHistoryOptions = (): MenuListOptionProps[] => {
  const { isVersioningState } = useUI()

  return useMemo(() => [{ ...VERSION_HISTORY_OPTION, disabled: isVersioningState }], [isVersioningState])
}
