import { useCallback, useEffect, useRef } from 'react'
import { ToolType, Mode, WorkspaceType } from '@phase-software/types'
// @ts-ignore
import { EntityChange, parseSceneTreeChanges } from '@phase-software/data-utils'
// @ts-ignore
import { Events } from '@phase-software/data-store'
import { useWorkspaceContext } from '../providers/WorkspaceContextProvider'
import { UndoEvent, UndoEventGroup, ParsedSceneTreeChange } from '../Broadcaster/FileProcessor.types'
import { track } from '../services/heapAnalytics'
import { useUI } from '../providers/dataStore/UIProvider'

const useHeapAnalytics = () => {
  const { workspaceData } = useWorkspaceContext()
  const space = workspaceData.type === WorkspaceType.TEAM ? 'team' : 'personal'
  const teamName = workspaceData.name
  return { space, teamName }
}

export const useAnalyticElementUpdate = (projectId: string, fileId: string, dataStore: any) => {
  const { space, teamName } = useHeapAnalytics()
  const { workspaceData } = useWorkspaceContext()
  const location = projectId === workspaceData.draftProjectId ? 'drafts' : 'project'

  const addElementStack = useRef<string[]>([])
  const { mode } = useUI()

  const parseEvent = useCallback(
    ({ event, type }: UndoEvent) => {
      if (event instanceof EntityChange) {
        switch (type) {
          case 'SCENE_TREE_CHANGES': {
            const { added }: ParsedSceneTreeChange = parseSceneTreeChanges(event)
            addElementStack.current.push(...Array.from(added))
            break
          }
          case 'LIBRARY_CHANGES':
            if (mode === Mode.DESIGN) {
              if ((event.UPDATE.size > 0 || event.DELETE.size > 0) && addElementStack.current.length === 0) {
                track('Element Design Edited', {
                  projectId,
                  fileId,
                  space,
                  teamName,
                  location
                })
              }
            }
            break
          case 'INTERACTION_CHANGES':
            if (event.CREATE.size > 0 || event.UPDATE.size > 0 || event.DELETE.size > 0) {
              track('Animation Edited', { projectId, fileId, space, teamName, location })
            }
            break
        }
      }
    },
    [fileId, location, mode, projectId, space, teamName]
  )

  const handleUndo = useCallback(
    (undo: UndoEventGroup) => {
      undo.events
        // skip some events
        .filter(({ type, owner }: UndoEvent) => owner !== dataStore && !['SELECT', 'SELECT_CELL'].includes(type))
        .forEach((undoEvent) => parseEvent(undoEvent))
    },
    [dataStore, parseEvent]
  )

  const handleSelectToolActivated = useCallback(
    ({ previousTool }: { previousTool: number }) => {
      let tool = ''
      switch (previousTool) {
        case ToolType.RECTANGLE:
          tool = 'rectangle'
          break
        case ToolType.ELLIPSE:
          tool = 'oval'
          break
        case ToolType.CONTAINER:
          tool = 'container'
          break
        case ToolType.PEN:
          tool = 'pen'
          break
        default:
          break
      }
      if (tool && addElementStack.current.length > 0) {
        track('Element Added With Tool', {
          tool,
          fileId,
          projectId,
          space,
          teamName,
          location
        })
        addElementStack.current = []
      }
    },
    [fileId, location, projectId, space, teamName]
  )

  useEffect(() => {
    if (dataStore) {
      dataStore.on('UNDO', handleUndo)
      dataStore.eam.on(Events.ACTIVATE_SELECT_TOOL, handleSelectToolActivated)
    }
    return () => {
      if (dataStore) {
        dataStore.off('UNDO', handleUndo)
        dataStore.eam.off(Events.ACTIVATE_SELECT_TOOL, handleSelectToolActivated)
      }
      addElementStack.current = []
    }
  }, [dataStore, handleUndo, handleSelectToolActivated])
}

export default useHeapAnalytics
