import React, { useEffect, useMemo, useState } from 'react'
import IconButton from '../IconButton'

const CIRCLE_RADIUS = 34.5
const CIRCUMFERENCE = 2 * Math.PI * CIRCLE_RADIUS
const CHANGE_TEXT_TIME_THRESHOLD = 60000

type DialogProgressIndicatorProps = {
  percentage: number
  isComplete: boolean
  onCancel?: () => void
}

const DialogProgressIndicator = ({ percentage, isComplete, onCancel }: DialogProgressIndicatorProps) => {
  const indicatorOffset = CIRCUMFERENCE * (1 - percentage / 100)
  const [shouldChangeText, setShouldChangeText] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setShouldChangeText(true)
    }, CHANGE_TEXT_TIME_THRESHOLD)

    return () => {
      setShouldChangeText(false)
      clearTimeout(timer)
    }
  }, [])

  const displayText = useMemo(() => {
    if (percentage === 100) return 'Done!'
    if (percentage >= 80) return "Hold on tight, we're almost there!"
    if (shouldChangeText && percentage < 80) return 'Larger files may take more time to process...'
    return 'Exporting…'
  }, [percentage, shouldChangeText])

  return (
    <div className="absolute inset-0 bg-dark-overlay-80 text-14 text-white z-20 flex flex-col items-center justify-center">
      <div className="mb-24">{displayText}</div>
      <div className={`relative ${isComplete ? 'mb-32' : 'mb-16'}`}>
        <div className={`transition-opacity duration-300 ease-out ${isComplete ? 'opacity-0' : 'opacity-100'}`}>
          <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="36" cy="36" r={CIRCLE_RADIUS} className="stroke-gray-50" />
            <circle
              cx="36"
              cy="36"
              r={CIRCLE_RADIUS}
              strokeWidth="3"
              strokeLinecap="round"
              strokeDasharray={CIRCUMFERENCE}
              strokeDashoffset={indicatorOffset}
              className="stroke-primary-50 transform -rotate-90 origin-center transition-all"
            />
          </svg>
          <span className="absolute inset-0 flex items-center justify-center">{Math.floor(percentage)}%</span>
        </div>
        <svg
          className="absolute inset-0"
          width="72"
          height="72"
          viewBox="0 0 72 72"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="36"
            cy="36"
            r={CIRCLE_RADIUS}
            strokeWidth="3"
            strokeLinecap="round"
            strokeDasharray={CIRCUMFERENCE}
            strokeDashoffset={-CIRCUMFERENCE}
            className={` stroke-lime-40 transform -rotate-90 origin-center progress-indicator-stroke-animation ${
              isComplete ? 'animate' : ''
            }`}
          />
          <path
            d="M26 36.2963L32.3636 43.5556L46 28"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeDasharray="100"
            strokeDashoffset="100"
            className={`stroke-lime-40 progress-indicator-stroke-animation-delayed ${isComplete ? 'animate' : ''}`}
          />
        </svg>
      </div>
      {!isComplete && <IconButton icon="Cross" onClick={onCancel} data-test-id="cancel-button" />}
    </div>
  )
}

export default DialogProgressIndicator
