import React, { forwardRef, useMemo } from 'react'
import { Avatar, Icon } from '..'
import { ReactComponent as CommentTagSvg } from './icons/CommentTag.svg'
import { UserMeta } from './types'

export type CommentTagProps = {
  user?: UserMeta
  isRead: boolean
  isResolved: boolean
  isSelected: boolean
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
  onMouseDown?: (e: React.MouseEvent<HTMLDivElement>) => void
  className?: string
}

const getComputedClassName = (isRead: boolean, isSelected: boolean) => ({
  commentTagColor: isRead ? 'text-neutral-80' : 'text-primary',
  size: isSelected ? 'w-24 h-24' : 'w-32 h-32',
  contentOpacity: isSelected ? 'opacity-0' : 'opacity-100'
})

export const CommentTag = forwardRef<HTMLDivElement, CommentTagProps>(
  ({ onClick, onMouseDown, isRead, isResolved, isSelected, user, className = '' }, forwardedRef) => {
    const computedClassName = useMemo(() => getComputedClassName(isRead, isSelected), [isRead, isSelected])

    return (
      <div
        className={`w-32 h-32 flex items-center justify-center cursor-pointer ${className}`}
        onClick={onClick}
        onMouseDown={onMouseDown}
        ref={forwardedRef}
      >
        <div className="relative hover:scale-[1.2] group-hover:scale-[1.2] transition-transform duration-[150ms] ease-out">
          <CommentTagSvg
            className={`drop-shadow-2 transition-size duration-[150ms] ease-out ${computedClassName.size} ${computedClassName.commentTagColor}`}
          />
          <div
            className={`absolute inset-0 flex items-center justify-center transition-opacity duration-[150ms] ease-out ${computedClassName.contentOpacity}`}
          >
            {isResolved ? (
              <Icon name="CheckBold" interactive={false} />
            ) : (
              <Avatar
                size={24}
                radiusSize="circle"
                alt={user?.name || 'Anonymous'}
                src={user?.avatar || ''}
                className="pointer-events-none"
              />
            )}
          </div>
        </div>
      </div>
    )
  }
)

CommentTag.displayName = 'CommentTag'

export default CommentTag
