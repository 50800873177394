import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useFeatureValue } from '@growthbook/growthbook-react'
import { TutorialStatus } from '@phase-software/types'

import { FEATURE_KEYS } from '../../growthbook-feature-keys'
import { Checkbox, Dialog, WistiaPlayer } from '../shared'

type TutorialDataProps = {
  key: string
  status: TutorialStatus
  hideCheck: boolean
}

type TutorialDialogProps = {
  title: string
  confirmText: string
  activeTutorial?: TutorialDataProps | null
  onComplete: (doNotShowAgain: boolean) => void
}

const TutorialDialog = ({ title, confirmText, onComplete, activeTutorial = null }: TutorialDialogProps) => {
  const { t } = useTranslation('tutorial')
  const checkboxRef = useRef<HTMLInputElement>(null)
  const [checkboxVisible, setCheckboxVisible] = useState<boolean>(true)
  const videoIds: { [key: string]: string } = useFeatureValue(FEATURE_KEYS.VIDEO_TUTORIAL, {})
  const videoId: string = activeTutorial ? videoIds[activeTutorial.key.toLowerCase()] : ''
  const [isChecked, setChecked] = useState(false)

  useEffect(() => {
    if (activeTutorial) {
      setCheckboxVisible(!activeTutorial.hideCheck)
    }
  }, [activeTutorial])

  const handleComplete = useCallback(() => {
    onComplete(isChecked)
  }, [onComplete, isChecked])

  return (
    <Dialog
      open={!!activeTutorial}
      size="m"
      title={title}
      confirmBtnText={confirmText}
      onConfirm={handleComplete}
      onCancel={handleComplete}
      footerBtnClassName="mt-0"
    >
      <div className="aspect-video">
        <WistiaPlayer videoId={videoId} wrapper={`wistia-player-container-${videoId}`} />
      </div>
      {checkboxVisible && (
        <div className="mt-16">
          <Checkbox
            ref={checkboxRef}
            disabled={false}
            checked={isChecked}
            error={false}
            label={t('dont_show_again')}
            onChange={() => setChecked(!isChecked)}
          />
        </div>
      )}
    </Dialog>
  )
}

export default TutorialDialog
