import React, { forwardRef, useImperativeHandle, useRef, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Box from '../Box'
import { color } from '../utils'
import Icon from '../Icon'

const SCROLLBAR_SIZE = 8
const SCROLLBAR_THUMB_SIZE = 4
const SCROLLBAR_THUMB_RADIUS = SCROLLBAR_THUMB_SIZE / 2
const SCROLLBAR_HIDE_DELAY_MS = 500

const SCROLL_UP = -1
const SCROLL_DOWN = 1
const SCROLL_DISTANCE = 6
const AUTO_SCROLL_FREQUENCY = 10

const Thumb = styled.div`
  background: ${color('lightOverlays', 60)};
  border-radius: ${SCROLLBAR_THUMB_RADIUS}px;
  will-change: width, height, transform, background;
  &:hover,
  &:active {
    background: ${color('lightOverlays', 80)};
  }
`

const Scrollbar = styled.div`
  display: none;
  position: absolute;
  overflow: hidden;
  user-select: none;
  will-change: width, height, transform;
  z-index: 10;
  &:hover,
  &:active {
    display: block;
  }
`

const HScrollbar = styled(Scrollbar)`
  bottom: 0;
  left: 0;
  height: ${SCROLLBAR_SIZE}px;
  & > ${Thumb} {
    height: ${SCROLLBAR_THUMB_SIZE}px;
  }
  padding: inherit;
`

const VScrollbar = styled(Scrollbar)`
  top: 0;
  right: 0;
  width: ${SCROLLBAR_SIZE}px;
  & > ${Thumb} {
    width: ${SCROLLBAR_THUMB_SIZE}px;
    margin: auto;
  }
  padding: inherit;
`

const ScrollViewWrapper = styled(Box)`
  scrollbar-width: none;
  position: relative;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    backface-visibility: hidden;
  }
  &.v-scrolling > ${VScrollbar} {
    display: block;
  }
  &.h-scrolling > ${HScrollbar} {
    display: block;
  }
`

const minmaxp = (min, max, v) => {
  if (v < min) return 0
  if (v > max) return 1
  return v / (max - min)
}

const getDirectionFields = (direction) =>
  direction === 'vertical'
    ? {
        position: 'top',
        size: 'height',
        contentSize: 'clientHeight',
        scrollSize: 'scrollHeight',
        thumbSize: 'thumbHeight',
        mousePosition: 'clientY',
        mouseOffset: 'offsetY'
      }
    : {
        position: 'left',
        size: 'width',
        contentSize: 'clientWidth',
        scrollSize: 'scrollWidth',
        thumbSize: 'thumbWidth',
        mousePosition: 'clientX',
        mouseOffset: 'offsetX'
      }
const vFields = getDirectionFields('vertical')
const hFields = getDirectionFields('horizontal')

const ScrollView = forwardRef(
  (
    { noScrollbar = false, onScroll, showScrollArrow = false, showScrollBarOnHover = false, children, ...rest },
    forwardRef
  ) => {
    const ref = useRef()
    const horizontalScrollbarRef = useRef()
    const verticalScrollbarRef = useRef()
    const horizontalThumbRef = useRef()
    const verticalThumbRef = useRef()
    const topScrollArrowRef = useRef(null)
    const bottomScrollArrowRef = useRef(null)
    const topObserverDivRef = useRef(null)
    const bottomObserverDivRef = useRef(null)
    const autoScrollRef = useRef(null)

    useImperativeHandle(forwardRef, () => ref.current)
    useEffect(() => {
      const node = ref.current
      let meta = {
        dragging: false,
        top: node.offsetTop,
        left: node.offsetLeft,
        width: node.offsetWidth,
        height: node.offsetHeight,
        clientHeight: node.clientHeight,
        clientWidth: node.clientWidth,
        scrollWidth: node.scrollWidth,
        scrollHeight: node.scrollHeight,
        scrollLeft: node.scrollLeft
      }

      const createScrollMouseDownHandler =
        ({ position, scrollSize, size, mousePosition }) =>
        (e) => {
          if (meta.dragging) return
          const rect = node.getBoundingClientRect()
          const p = minmaxp(meta[position], meta[position] + meta[size], e[mousePosition] - rect[position])
          node.scrollTo({
            [position]: meta[scrollSize] * p - meta[size] * p,
            behavior: 'smooth'
          })
        }

      const createThumbMouseDownHandler =
        ({ position, size, scrollSize, thumbSize, mouseOffset, mousePosition }) =>
        (e) => {
          e.stopPropagation()
          const rect = node.getBoundingClientRect()
          const pctOfThumb = minmaxp(0, meta[thumbSize], e[mouseOffset])
          const handleMouseMove = (e) => {
            meta.dragging = true
            const pctOfSize = minmaxp(meta[position], meta[position] + meta[size], e[mousePosition] - rect[position])
            const offset = pctOfSize * meta[scrollSize] - pctOfThumb * meta[size]
            node.scrollTo({
              [position]: offset
            })
          }
          window.addEventListener('mousemove', handleMouseMove)
          window.addEventListener(
            'mouseup',
            () => {
              meta.dragging = false
              window.removeEventListener('mousemove', handleMouseMove)
            },
            { once: true }
          )
        }

      const updateMetaThumbSize = ({ contentSize, scrollSize, size, thumbSize }) => {
        meta[thumbSize] = Math.round((meta[contentSize] / meta[scrollSize]) * meta[size])
      }

      const updateScroll = (scroll, thumb, { size, scrollSize, contentSize }) => {
        scroll.current.style[size] = `${meta[scrollSize]}px`
        thumb.current.style[size] = `${Math.round((meta[contentSize] / meta[scrollSize]) * meta[size])}px`
        scroll.current.style.display = meta[scrollSize] > meta[contentSize] ? '' : 'none'
      }

      const updateScrollSize = () => {
        updateMetaThumbSize(vFields)
        updateMetaThumbSize(hFields)
        updateScroll(verticalScrollbarRef, verticalThumbRef, vFields)
        updateScroll(horizontalScrollbarRef, horizontalThumbRef, hFields)
      }

      const displayScrollbar = ({ dir, isHovering }) => {
        if (showScrollBarOnHover) {
          if (!isHovering) return node.classList.remove('v-scrolling', 'h-scrolling')
          const { scrollHeight, clientHeight, scrollWidth, clientWidth } = meta
          if (scrollHeight > clientHeight) node.classList.add(`v-scrolling`)
          if (scrollWidth > clientWidth) node.classList.add(`h-scrolling`)
          return
        }
        node.classList.add(`${dir}-scrolling`)
        if (meta[`_${dir}TimerId`]) {
          clearTimeout(meta[`_${dir}TimerId`])
        }
        meta[`_${dir}TimerId`] = setTimeout(
          () => node && node.classList.remove(`${dir}-scrolling`),
          SCROLLBAR_HIDE_DELAY_MS
        )
      }

      const handleScroll = (e) => {
        const direction = meta.scrollLeft === node.scrollLeft ? 'v' : 'h'

        if (direction === 'v') {
          node.classList.remove(`h-scrolling`)
          meta.scrollTop = node.scrollTop

          const offsetY = Math.round((meta.height * meta.scrollTop) / meta.scrollHeight) + meta.scrollTop

          horizontalScrollbarRef.current.style.transform = `translateY(${meta.scrollTop}px)`
          verticalThumbRef.current.style.transform = `translateY(${offsetY}px)`
        } else {
          node.classList.remove(`v-scrolling`)
          meta.scrollLeft = node.scrollLeft

          const offsetX = Math.round((meta.width * meta.scrollLeft) / meta.scrollWidth) + meta.scrollLeft

          horizontalThumbRef.current.style.transform = `translateX(${offsetX}px)`
          verticalScrollbarRef.current.style.transform = `translateX(${meta.scrollLeft}px)`
        }

        !noScrollbar && !showScrollBarOnHover && displayScrollbar({ dir: direction })
        onScroll && onScroll(e)
      }

      const rObserver = new ResizeObserver((entries) => {
        const { top, left, width, height } = entries[0].contentRect
        meta = {
          ...meta,
          top,
          left,
          width,
          height,
          clientWidth: node.clientWidth,
          clientHeight: node.clientHeight,
          scrollWidth: node.scrollWidth,
          scrollHeight: node.scrollHeight
        }
        updateScrollSize()
      })

      const mObserver = new MutationObserver(
        () => {
          const showingVScroll = node.classList.contains('v-scrolling')
          const showingHScroll = node.classList.contains('h-scrolling')
          node.classList.remove('v-scrolling', 'h-scrolling')
          meta = {
            ...meta,
            clientWidth: node.clientWidth,
            clientHeight: node.clientHeight,
            scrollWidth: node.scrollWidth,
            scrollHeight: node.scrollHeight
          }
          updateScrollSize()
          if (showingVScroll) node.classList.add('v-scrolling')
          if (showingHScroll) node.classList.add('h-scrolling')
        },
        { childList: true }
      )

      rObserver.observe(node)
      mObserver.observe(node, { childList: true, subtree: true })
      node.addEventListener('scroll', handleScroll, {
        capture: true,
        passive: true
      })
      horizontalThumbRef.current.addEventListener('mousedown', createThumbMouseDownHandler(hFields))
      verticalThumbRef.current.addEventListener('mousedown', createThumbMouseDownHandler(vFields))
      verticalScrollbarRef.current.addEventListener('mousedown', createScrollMouseDownHandler(vFields))
      horizontalScrollbarRef.current.addEventListener('mousedown', createScrollMouseDownHandler(hFields))
      ref.current.addEventListener('mouseenter', () => {
        !noScrollbar && showScrollBarOnHover && displayScrollbar({ isHovering: true })
      })
      ref.current.addEventListener('mouseleave', () => {
        !noScrollbar && showScrollBarOnHover && displayScrollbar({ isHovering: false })
      })
      return () => {
        rObserver.disconnect(node)
        mObserver.disconnect(node)
      }
    }, [noScrollbar, onScroll, showScrollBarOnHover])

    useEffect(() => {
      if (
        !showScrollArrow ||
        !ref.current ||
        !topObserverDivRef.current ||
        !bottomObserverDivRef.current ||
        !topScrollArrowRef.current ||
        !bottomScrollArrowRef.current
      )
        return

      const options = {
        root: ref.current,
        rootMargin: '0px 0px 0px 0px',
        threshold: 1.0
      }
      const TopIndicatorObserver = new IntersectionObserver((entries) => {
        const [topDiv] = entries
        if (topDiv.isIntersecting) topScrollArrowRef.current.classList.add('hidden')
        else topScrollArrowRef.current.classList.remove('hidden')
      }, options)

      const BottomIndicatorObserver = new IntersectionObserver((entries) => {
        const [bottomDiv] = entries
        if (bottomDiv.isIntersecting) bottomScrollArrowRef.current.classList.add('hidden')
        else bottomScrollArrowRef.current.classList.remove('hidden')
      }, options)

      TopIndicatorObserver.observe(topObserverDivRef.current)
      BottomIndicatorObserver.observe(bottomObserverDivRef.current)

      return () => {
        TopIndicatorObserver.disconnect()
        BottomIndicatorObserver.disconnect()
      }
    }, [showScrollArrow])

    const startAutoScroll = useCallback(
      (direction) => () => {
        if (autoScrollRef.current) window.clearInterval(autoScrollRef.current)
        autoScrollRef.current = window.setInterval(() => {
          if (!ref?.current) return
          ref.current?.scrollBy({ top: direction * SCROLL_DISTANCE, behavior: 'instant' })
        }, AUTO_SCROLL_FREQUENCY)
      },
      []
    )

    const stopAutoScroll = useCallback(() => {
      if (autoScrollRef.current) window.clearInterval(autoScrollRef.current)
    }, [])

    useEffect(() => {
      return () => {
        if (autoScrollRef.current) window.clearInterval(autoScrollRef.current)
      }
    }, [])

    return (
      <>
        <ScrollViewWrapper ref={ref} {...rest}>
          {showScrollArrow && <div ref={topObserverDivRef} />}
          {children}
          {showScrollArrow && <div ref={bottomObserverDivRef} />}
          <HScrollbar ref={horizontalScrollbarRef}>
            <Thumb ref={horizontalThumbRef} />
          </HScrollbar>
          <VScrollbar ref={verticalScrollbarRef}>
            <Thumb ref={verticalThumbRef} />
          </VScrollbar>
        </ScrollViewWrapper>
        {showScrollArrow && (
          <>
            <div
              ref={topScrollArrowRef}
              className="absolute inset-x-0 top-0 h-20 bg-inherit flex items-center justify-center"
              onMouseEnter={startAutoScroll(SCROLL_UP)}
              onMouseLeave={stopAutoScroll}
            >
              <Icon name="AngleTop16" interactive={false} />
            </div>
            <div
              ref={bottomScrollArrowRef}
              className="absolute inset-x-0 bottom-0 h-20 bg-inherit flex items-center justify-center"
              onMouseEnter={startAutoScroll(SCROLL_DOWN)}
              onMouseLeave={stopAutoScroll}
            >
              <Icon name="AngleDown16" interactive={false} />
            </div>
          </>
        )}
      </>
    )
  }
)
ScrollView.displayName = 'ScrollView'
ScrollView.propTypes = {
  children: PropTypes.node,
  onScroll: PropTypes.func,
  noScrollbar: PropTypes.bool,
  showScrollArrow: PropTypes.bool,
  showScrollBarOnHover: PropTypes.bool
}

export default React.memo(ScrollView)
