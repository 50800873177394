import { notNull } from '@phase-software/data-utils'
import PropertyComponent from './PropertyComponent'

/** @typedef {import('../DataStore').DataStore} DataStore */
/** @typedef {import('../layer/Layer').Layer} Layer */
/** @typedef {import('../layer/Layer').LayerData} LayerData */
/** @typedef {import('./PropertyComponent').AppliedRef} AppliedRef */

/**
 * @abstract
 */
export default class LayerComponent extends PropertyComponent {
    /**
     * @param {DataStore} dataStore
     * @param {LayerComponentData} [data]
     * @param {object} [options]
     * @param {bool} [options.regenId=false]   if set to true, will generate new ID
     */
    constructor(dataStore, data = {}, options) {
        super(dataStore, data, options)

        const { layerType, layers } = data

        this.layerType = notNull(layerType) ? layerType : 'LAYER'
        this.layers = notNull(layers) ? layers : []

        if (!this.name) {
            this.name = 'layer'
        }
    }

    /**
     * @param {Partial<LayerComponentData>} data
     */
    set(data) {
        super.set(data)

        if (notNull(data.layers)) {
            this.updateProp('layers', data.layers)
        }
    }

    /**
     * Add Layer to LayerComponent
     * @param {string} layerId
     * @param {number} [index=-1]
     */
    addLayer(layerId, index = -1) {
        if (this.layers.includes(layerId)) {
            return
        }

        const idx = index === -1 ? this.layers.length : index
        const newLayers = this.layers.slice()
        newLayers.splice(idx, 0, layerId)
        this.set({ layers: newLayers })
    }

    /**
     * Remove Layer to LayerComponent
     * @param {string} layerId
     */
    removeLayer(layerId) {
        const layerIdx = this.layers.findIndex((lId) => lId === layerId)
        if (layerIdx === -1) {
            return
        }

        const newLayers = this.layers.slice()
        newLayers.splice(layerIdx, 1)
        this.set({ layers: newLayers })
    }

    /**
     * Override this in subclasses
     * CALL super._clone() at the top of overriden method
     * @protected
     * @param {AppliedRef} [ref]
     * @returns {PropertyComponent}
     */
    _clone(ref) {
        const obj = super._clone(ref)
        obj.layerType = this.layerType
        return obj
    }

    /**
     * Override this in subclasses
     * CALL super._save() at the top of overriden method
     * @protected
     * @returns {LayerComponentData} data
     */
    _save() {
        const data = super._save()
        data.layerType = this.layerType
        data.layers = this.layers
        return data
    }

    /**
     * Override this in subclasses if subclass has any inner sharable PropertyComponents
     * CALL super.apply() at the top of overriden method
     * @param {AppliedRef} ref
     */
    apply(ref) {
        super.apply(ref)
        this.layers.forEach(layerId => {
            const layer = this.dataStore.library.getLayer(layerId)
            layer.apply(ref)
        })
    }

    /**
     * Override this in subclasses if subclass has any inner sharable PropertyComponents
     * CALL super.cancel() at the top of overriden method
     * @param {AppliedRef} ref
     */
    cancel(ref) {
        super.cancel(ref)
        this.layers.forEach(layerId => {
            const layer = this.dataStore.library.getLayer(layerId)
            layer.cancel(ref)
        })
    }
}

/** @typedef {import('./PropertyComponent').PropertyComponentData} PropertyComponentData */

/**
 * @typedef {PropertyComponentData} LayerComponentData
 * @property {string} layerType
 */
