import { getElementData } from './Element'

export const getWorkspaceData = (workspace, lazy = false) => {
  if (lazy) {
    return workspace.get('id')
  }
  const data = workspace.gets('id', 'name')
  data.children = workspace.children.map((o) => getElementData(o, true))
  return data
}
