import React, { useCallback, useEffect, useRef, useState } from 'react'

import { NOTIFICATION_ANIMATION_MS, NOTIFICATION_ANIMATION_S } from '../../constant'
import useAfterEffect from '../../hooks/useAfterEffect'
import { useNotification, useSetNotification } from '../../providers/NotificationProvider'
import { Icon, Text } from '../shared'

type NotificationType = 'general' | 'info' | 'error' | 'success' | 'warning' | 'loading'

export type NotificationProps = {
  id: string
  type?: NotificationType
  content: React.ReactNode | string
  action?: string
  callback?: (e: React.MouseEvent) => void
  duration?: number
}

const iconMapper = {
  info: { name: 'Info', color: 'text-primary-40' },
  error: { name: 'Danger', color: 'text-danger-40' },
  success: { name: 'Success', color: 'text-success-40' },
  warning: { name: 'Warning', color: 'text-warning-40' },
  loading: { name: 'Loading', color: 'text-white' }
}
const NotificationIcon = ({ type }: { type: NotificationType }) => {
  if (type === 'general') return null
  const { name, color } = iconMapper[type]
  const loadingClassName = type === 'loading' ? 'animate-spin' : ''

  return <Icon name={name} size="xxl" interactive={false} className={`${loadingClassName} ${color}`} useCurrentColor />
}

const getPaddingXClassName = (type: NotificationProps['type']) => {
  switch (type) {
    case 'loading':
      return 'pl-12 pr-16'
    case 'general':
      return 'pl-16 pr-12'
    default:
      return 'px-12'
  }
}

export const Notification = React.memo(
  ({
    id,
    type = 'general',
    content,
    action: buttonText,
    callback: onButtonClick,
    duration = 3000
  }: NotificationProps) => {
    const { removeNotification } = useSetNotification()

    const ref = useRef<HTMLDivElement>(null)
    const fadeTimerRef = useRef<number>(0)

    const [isHover, setIsHover] = useState(false)
    const [isVisible, setIsVisible] = useState(false)

    const closeNotification = useCallback(() => {
      setIsVisible(false)
      setTimeout(() => {
        removeNotification(id)
      }, NOTIFICATION_ANIMATION_MS)
    }, [removeNotification, id])

    const paddingXClassName = getPaddingXClassName(type)

    useEffect(() => {
      if (type === 'loading') {
        return
      }
      if (isHover && fadeTimerRef.current) {
        window.clearTimeout(fadeTimerRef.current)
        fadeTimerRef.current = 0
      } else if (!isHover && !fadeTimerRef.current) {
        fadeTimerRef.current = window.setTimeout(() => {
          closeNotification()
        }, duration)
      }
      return () => {
        if (fadeTimerRef.current) window.clearTimeout(fadeTimerRef.current)
        fadeTimerRef.current = 0
      }
    }, [isHover, type, duration, closeNotification])

    useEffect(() => {
      const node = ref.current
      if (!node) return

      const handleMouseEnter = () => {
        setIsHover(true)
      }
      const handleMouseLeave = () => {
        setIsHover(false)
      }
      node.addEventListener('mouseenter', handleMouseEnter)
      node.addEventListener('mouseleave', handleMouseLeave)
      return () => {
        node.removeEventListener('mouseenter', handleMouseEnter)
        node.removeEventListener('mouseleave', handleMouseLeave)
      }
    }, [setIsHover])

    const setVisibility = useCallback(() => {
      setIsVisible(true)
      return () => {
        setIsVisible(false)
      }
    }, [setIsVisible])

    // set visibility to true after component is mounted, so that the transition can be triggered
    useAfterEffect(setVisibility)

    const handleClick = (e: React.MouseEvent) => {
      removeNotification(id)
      if (onButtonClick) onButtonClick(e)
    }

    return (
      <div
        className={`max-w-full truncate inline-flex gap-12 items-center pointer-events-auto ${paddingXClassName} py-8 text-white bg-neutral-60-overlay-80 place-self-center rounded-md opacity-0 ease-out -translate-y-full backdrop-blur-[6px] ${
          isVisible ? 'opacity-100 translate-y-0' : ''
        }`}
        style={{
          transition: `opacity ${NOTIFICATION_ANIMATION_S}s 0s, ${
            isVisible
              ? `transform ${NOTIFICATION_ANIMATION_S}s 0s`
              : `transform ${NOTIFICATION_ANIMATION_S}s ${NOTIFICATION_ANIMATION_S}s`
          }`
        }}
        ref={ref}
      >
        <NotificationIcon type={type} />
        <Text ellipsis className="text-14">
          {content}
        </Text>

        {buttonText && (
          <Text ellipsis={false} className="text-primary-40 cursor-pointer font-semibold text-14" onClick={handleClick}>
            {buttonText}
          </Text>
        )}
        {type !== 'loading' && <Icon name="Cross" onClick={closeNotification} />}
      </div>
    )
  }
)

Notification.displayName = 'Notification'

export const NotificationList = ({ offsetTop = 0, offsetLeft = 0 }: { offsetTop?: number; offsetLeft?: number }) => {
  const notifications: NotificationProps[] = useNotification()

  return (
    <div
      className="fixed w-full px-16 z-40 text-center flex flex-col gap-24 items-center justify-center pointer-events-none"
      style={{ maxWidth: `calc(100% - ${offsetLeft}px)`, top: `${offsetTop + 32}px`, left: `${offsetLeft}px` }}
    >
      {Object.values(notifications)
        .reverse()
        .map((notification) => (
          <Notification key={notification.id} {...notification} />
        ))}
    </div>
  )
}
