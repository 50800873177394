import { toDeg } from '@phase-software/data-utils'
import { EasingType } from '@phase-software/types'

import { formatAsDegrees, formatAsPercentage, formatNumberWithUnit, multiplyBy100 } from '../../utils/formatter'

enum Command {
  M = 1,
  L,
  Q,
  C,
  Z
}
interface LottiePathData {
  vertices: number[]
  commands: Command[]
}
export const parsePath = (path: LottiePathData, isZeroWidth: boolean, isZeroHeight: boolean) => {
  class PathPoint {
    constructor() {
      this.i = []
      this.o = []
      this.v = []
      this.c = false
    }

    i: number[][]
    o: number[][]
    v: number[][]
    c: boolean
  }
  const pathData: PathPoint[] = []
  let index = 0
  let pathIndex = -1
  let groupIndex = 0
  let firstX = Infinity
  let firstY = Infinity
  let fromX = Infinity
  let fromY = Infinity
  const ZERO_POINT = [0, 0]

  for (const command of path.commands) {
    let toX = Infinity
    let toY = Infinity

    switch (command) {
      case Command.M:
        pathIndex++
        groupIndex = 0
        pathData.push(new PathPoint())

        pathData[pathIndex].i.push(ZERO_POINT)
        pathData[pathIndex].o.push(ZERO_POINT)
        firstX = path.vertices[index++]
        firstY = path.vertices[index++]
        toX = firstX
        toY = firstY
        pathData[pathIndex].v.push([toX, toY])
        groupIndex++
        break

      case Command.L:
        toX = path.vertices[index++]
        toY = path.vertices[index++]
        pathData[pathIndex].i.push(ZERO_POINT)
        pathData[pathIndex].o.push(ZERO_POINT)
        pathData[pathIndex].v.push([isZeroWidth ? firstX : toX, isZeroHeight ? firstY : toY])
        groupIndex++
        break

      // Uncomment and complete this part if needed
      // case Command.Q:
      //   ...
      //   break;

      case Command.C:
        {
          const cp0X = path.vertices[index++]
          const cp0Y = path.vertices[index++]
          const cp1X = path.vertices[index++]
          const cp1Y = path.vertices[index++]
          toX = path.vertices[index++]
          toY = path.vertices[index++]
          pathData[pathIndex].o[groupIndex - 1] = [cp0X - fromX, cp0Y - fromY]
          pathData[pathIndex].i.push([cp1X - toX, cp1Y - toY])
          pathData[pathIndex].o.push(ZERO_POINT)
          pathData[pathIndex].v.push([isZeroWidth ? firstX : toX, isZeroHeight ? firstY : toY])
          groupIndex++
        }
        break
      case Command.Z: {
        pathData[pathIndex].c = true
        const vList = pathData[pathIndex].v
        const [x1, y1] = vList[0]
        const [x2, y2] = vList[vList.length - 1]
        if (Math.abs(x1 - x2) < Number.EPSILON && Math.abs(y1 - y2) < Number.EPSILON) {
          if (pathData[pathIndex].i.length) {
            const lastIn = pathData[pathIndex].i[pathData[pathIndex].i.length - 1]
            pathData[pathIndex].i[0] = [...lastIn]
            pathData[pathIndex].v.pop()
            pathData[pathIndex].o.pop()
            pathData[pathIndex].i.pop()
          }
        }
        break
      }
    }

    fromX = toX
    fromY = toY
  }

  return pathData.map((point) => ({
    segments: point.i.map((_, index) => ({
      i: point.i[index],
      o: point.o[index],
      v: point.v[index]
    })),
    c: point.c
  }))
}

export const commonFormatter = (value: any) => {
  return formatNumberWithUnit(value, 'px')
}

export const degreeFormatter = (value: any) => {
  return formatAsDegrees(toDeg(value))
}

export const percentFormatter = (value: any) => {
  return formatAsPercentage(multiplyBy100(value))
}

export const commonTextFormatter = (value: any) => {
  return `"${formatNumberWithUnit(value, 'px')}"`
}

export const degreeTextFormatter = (value: any) => {
  return `"${formatNumberWithUnit(toDeg(value), 'deg')}"`
}

export const ratioFormatter = (value: any) => {
  return formatNumberWithUnit(value / 100)
}

export const shouldShowBezierRow = (easingType: EasingType) => {
  return easingType !== EasingType.STEP_END
}

export const addSpaces = (numSpaces: number) => {
  return ' '.repeat(numSpaces)
}
