import React from 'react'

import { useApolloClient } from '@apollo/client'

import { FileFieldsFragmentDoc } from '../../generated/graphql'
import useShareTeamFileActions from '../../hooks/useShareTeamFileActions'
import { MenuOptionProps } from '../shared/Menu/MenuOption'
import { TextAreaTagItemProps } from '../shared/TextArea'
import BaseShareDialog from './BaseShareDialog'
import { useShareFileDialogContext } from './ShareFileDialogContextProvider'
import {
  AllPermissionEnum,
  CollaboratorPermission,
  DraftFilePermissionEnum,
  ProjectFilePermissionEnum
} from './shareTypes'

export type ShareTeamFileDialogProps = {
  isDraftFile: boolean
  teamMemberList: MenuOptionProps[]
}

const ShareTeamFileDialog = ({ isDraftFile, teamMemberList }: ShareTeamFileDialogProps) => {
  const client = useApolloClient()

  const {
    canEdit,
    collaboratorList,
    fileId,
    linkPathname,
    name,
    onClose,
    open,
    permission,
    draftPermission,
    projectId
  } = useShareFileDialogContext()

  const {
    changeTeamFileDefaultPermission,
    changeTeamFileCollaboratorPermission,
    removeTeamFileCollaborator,

    sendTeamFileInvitation,
    resendTeamFileInvitation,
    changeTeamFileInvitationPermission,
    cancelTeamFileInvitation
  } = useShareTeamFileActions()

  const globalAccess = isDraftFile
    ? draftPermission ?? DraftFilePermissionEnum.CAN_REVIEW
    : permission ?? ProjectFilePermissionEnum.CAN_ACCESS

  const changeDefaultPermission = async (permission: AllPermissionEnum) => {
    const permissions = isDraftFile
      ? { draftPermission: permission as DraftFilePermissionEnum, fileId, projectId }
      : { permission, fileId, projectId }

    await changeTeamFileDefaultPermission(permissions)

    client.cache.updateFragment(
      {
        id: `files:${fileId}`,
        fragment: FileFieldsFragmentDoc,
        fragmentName: 'fileFields'
      },
      (data) => ({ ...data, ...(isDraftFile ? { draft_permission: permission } : { permission }) })
    )
  }

  const changeCollaboratorPermission = async (collaborator: MenuOptionProps, permission: CollaboratorPermission) => {
    if (collaborator.isPending) {
      return changeTeamFileInvitationPermission({
        fileId,
        projectId,
        permission,
        invitationId: collaborator.id
      })
    }
    return changeTeamFileCollaboratorPermission({
      fileId,
      projectId,
      collaboratorId: collaborator.userId,
      permission
    })
  }

  const inviteCollaborators = async (
    invitedCollaborators: TextAreaTagItemProps[],
    permission: CollaboratorPermission
  ) => {
    try {
      const inviteCollaboratorRequests = invitedCollaborators.map((invitedCollaborator) =>
        sendTeamFileInvitation({ fileId, projectId, email: invitedCollaborator.value, permission })
      )
      await Promise.all(inviteCollaboratorRequests)
    } catch (error) {
      console.error(error)
    }
  }

  const removeCollaborator = async (collaborator: MenuOptionProps) => {
    return removeTeamFileCollaborator({
      fileId,
      projectId,
      collaboratorId: collaborator.userId
    })
  }

  const cancelInvitation = (collaborator: MenuOptionProps) => {
    return cancelTeamFileInvitation({
      projectId,
      fileId,
      invitationId: collaborator.id
    })
  }

  const resendInvitation = (collaborator: MenuOptionProps) => {
    return resendTeamFileInvitation({
      projectId,
      fileId,
      invitationId: collaborator.id
    })
  }

  return (
    <BaseShareDialog
      collaboratorList={collaboratorList}
      defaultPermission={globalAccess}
      type={isDraftFile ? 'draftFile' : 'projectFile'}
      isEditable={canEdit}
      name={name}
      onClose={onClose}
      linkPathname={linkPathname}
      onCollaboratorPermissionChange={changeCollaboratorPermission}
      onDefaultPermissionChange={changeDefaultPermission}
      onInviteCollaborators={inviteCollaborators}
      onRemoveCollaborator={removeCollaborator}
      onCancelInvitation={cancelInvitation}
      onResendInvitation={resendInvitation}
      open={open}
      teamMemberList={teamMemberList}
      fileId={fileId}
      projectId={projectId}
    />
  )
}

export default ShareTeamFileDialog
