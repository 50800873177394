import i18n, { TFunction } from 'i18next'

import { DEFAULT_LOCALE } from '../constants/localeConstants'
import { parseToDecimals } from './number'

export const multiplyBy100 = (value: number): number => value * 100

export const divideBy100 = (value: number): number => value / 100

export const convertMsToSeconds = (milliseconds: number): number => milliseconds / 1000

export const convertMsToRoundedSeconds = (milliseconds: number): number =>
  parseToDecimals(convertMsToSeconds(milliseconds), 2)

export const formatNumberWithUnit = (value: number, unit = '', decimalPlaces = 2, formatAsString = false): string =>
  `${parseToDecimals(value, decimalPlaces, formatAsString)}${unit}`

export const formatAsPercentage = (value: number): string => formatNumberWithUnit(value, '%', 2)

export const formatAsDegrees = (value: number): string => formatNumberWithUnit(value, '°', 2)

export const formatSecondsWithDecimals = (value: number, formatAsString = false): string =>
  formatNumberWithUnit(value, 's', 2, formatAsString)

export const formatToDigit = (value: number, digits = 2): string => value.toString().padStart(digits, '0')

export const formatSecondsToDuration = (totalSeconds: number): string => {
  const hours = Math.floor(totalSeconds / 3600)
  const minutes = Math.floor((totalSeconds % 3600) / 60)
  const seconds = totalSeconds % 60
  const formattedMinutes = formatToDigit(minutes)
  const formattedSeconds = formatToDigit(seconds)

  if (hours) {
    return `${hours}:${formattedMinutes}:${formattedSeconds}`
  }

  return `${formattedMinutes}:${formattedSeconds}`
}

export function formatVersionCreatedTime(timestamp: string, t: TFunction) {
  const date = new Date(timestamp ?? Date.now())
  const now = new Date()

  const sameDay = now.toISOString().slice(0, 10) === date.toISOString().slice(0, 10)
  const sameYear = now.getFullYear() === date.getFullYear()

  const startOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime()
  const startOfYesterday = startOfToday - 24 * 60 * 60 * 1000
  const oneDayAgo = date.getTime() >= startOfYesterday && date.getTime() < startOfToday

  const time = date.toTimeString().slice(0, 5)

  if (sameDay) {
    return `${t('file:version.today')}, ${time}`
  } else if (oneDayAgo) {
    return `${t('file:version.yesterday')}, ${time}`
  } else if (sameYear) {
    return `${date.toLocaleString(i18n.language, { month: 'short' })} ${date.getDate()}, ${time}`
  } else {
    return `${date.toLocaleString(i18n.language, { month: 'short' })} ${date.getDate()}, ${date.getFullYear()}, ${time}`
  }
}

export function formatUnnamedVersionName(timestamp: string, locale = DEFAULT_LOCALE) {
  const date = new Date(timestamp ?? Date.now())
  return `${date.toLocaleString(locale, {
    month: 'short'
  })} ${date.getDate()}, ${date.getFullYear()}, ${date.toTimeString().slice(0, 5)}`
}
