import { useEffect } from 'react'
import { useSetFonts } from '../../providers/dataStore/FontsProvider'
import { useDataStore } from '../../providers/dataStore/DataStoreProvider'

const SubscribeFontLoad = () => {
  const dataStore = useDataStore()
  const { setFonts } = useSetFonts()

  useEffect(() => {
    if (!dataStore) return
    const handleFontLoad = () => {
      const weightStyleMap = {
        100: 'Thin',
        200: 'Extra Light',
        300: 'Light',
        400: 'Regular',
        500: 'Medium',
        600: 'Semi Bold',
        700: 'Bold',
        800: 'Extra Bold',
        900: 'Black'
      }
      const fontHelper = dataStore.fontHelper

      const fontMap = Array.from(fontHelper.list || []).reduce((acc, [, { postscript, family, italic, weight }]) => {
        if (!acc[family]) {
          acc[family] = new Map()
        }
        const fontStyles = acc[family]
        const styleName = `${weightStyleMap[weight]}${italic ? ' Italic' : ''}`
        fontStyles.set(styleName, { family, italic, weight, postscript })
        return acc
      }, {})
      setFonts({
        families: Object.keys(fontMap),
        stylesMap: fontMap
      })
    }
    dataStore.on('FONT-LIST-LOAD', handleFontLoad)
    return () => {
      dataStore.off('FONT-LIST-LOAD', handleFontLoad)
    }
  }, [dataStore, setFonts])
  return null
}
export default SubscribeFontLoad
