import { pick } from 'lodash'
import { LayerType } from '../constants'
import { getPaintData } from './Paint'

export const getLayerData = (layer) => {
  const data = pick(layer, ['id', 'paint', 'layerType', 'visible'])
  switch (data.layerType) {
    case LayerType.FILL:
      data.paint = getPaintData(data.paint)
      break
    case LayerType.STROKE:
      data.paint = getPaintData(data.paint)
      Object.assign(
        data,
        pick(layer, [
          'cap',
          'capSize',
          'dash',
          'ends',
          'gap',
          'join',
          'joinSize',
          'miter',
          'offset',
          'width',
          'growDirection'
        ])
      )
      break
    case LayerType.SHADOW:
    case LayerType.INNER_SHADOW:
      data.paint = getPaintData(data.paint)
      Object.assign(data, pick(layer, ['offsetX', 'offsetY', 'blur', 'spread']))
      break
    case LayerType.EFFECT:
      Object.assign(data, pick(layer, ['effectType', 'blurType', 'amount', 'saturation', 'angle']))
      break
    default:
      break
  }
  return data
}
