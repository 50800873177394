import { useCallback } from 'react'
import { ALLOWED_UPLOAD_IMAGE_TYPE_SET } from '../constant'
import { projectFileImageService } from '../services/api'
import { useFileContext } from '../providers/FileProvider'
import { useDataStore } from '../providers/dataStore/DataStoreProvider'
import { useSetNotification } from '../providers/NotificationProvider'
import { track } from '../services/heapAnalytics'
import useHeapAnalytics from './useHeapAnalytics'
import { useWorkspaceContext } from '../providers/WorkspaceContextProvider'
import { useTranslation } from 'react-i18next'

type Dimensions = {
  width: number
  height: number
}

export const limitDimensions = (width: number, height: number, maxDimension = 8192) => {
  let ratio

  if (width > maxDimension || height > maxDimension) {
    if (width > height) {
      ratio = maxDimension / width
    } else {
      ratio = maxDimension / height
    }

    width = Math.round(width * ratio)
    height = Math.round(height * ratio)
  }

  return { width, height }
}

export const getImageDimensions = async (file: File): Promise<Dimensions> => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => {
      const { width, height } = limitDimensions(img.width, img.height)
      resolve({
        width,
        height
      })
    }
    img.onerror = (e) => {
      reject(e)
    }
    img.src = URL.createObjectURL(file)
  })
}

const useEditorImage = () => {
  const { t } = useTranslation('workspace')
  const dataStore = useDataStore()
  const { addNotification, updateNotification, removeNotification } = useSetNotification()
  const { id: fileId, projectId } = useFileContext()
  const { space, teamName } = useHeapAnalytics()
  const { workspaceData } = useWorkspaceContext()
  const location = projectId === workspaceData.draftProjectId ? 'drafts' : 'project'

  const uploadAndCreateImage = useCallback(
    async ({ content, name }: { content: object; name: string }) => {
      const imageResponse = await projectFileImageService.projectFileControllerImportImageV1({
        projectId,
        fileId,
        content,
        name
      })
      const image = await dataStore.images.addImageAsync({
        id: imageResponse.id,
        src: imageResponse.download_url
      })
      return image
    },
    [dataStore, fileId, projectId]
  )

  const uploadImageList = useCallback(
    async (files: File[]) => {
      let counter = 0
      const notifyId = addNotification({
        type: 'loading',
        content: `${t('message.uploading_files')} ${counter}/${files.length}`
      })

      const promises = Array.from(files).map(async (file) => {
        const dimensions = await getImageDimensions(file)
        const image = await uploadAndCreateImage({ content: file, name: file.name })
        updateNotification(notifyId, `Uploading files… ${++counter}/${files.length}`)
        const name = file.name.split('.').slice(0, -1).join('.')
        return { image, dimensions, name }
      })

      const result = await Promise.allSettled(promises)
      removeNotification(notifyId)
      return result
    },
    [uploadAndCreateImage, addNotification, updateNotification, removeNotification, t]
  )

  const createElementByImages = useCallback(
    // TODO: target should be ContainableElement
    // @ts-ignore TODO: need to add Vector2 type for worldPos
    async (files: FileList, fileId: string, projectId: string, target?: any, worldPos?: any) => {
      // show warning message to user when some of the files are not supported image type
      const imageFiles = Array.from(files).filter((file) => ALLOWED_UPLOAD_IMAGE_TYPE_SET.has(file.type))
      if (files.length !== imageFiles.length) {
        addNotification({
          type: 'warning',
          content: t('message.upload_error')
        })
      }

      const promises = await uploadImageList(imageFiles)

      const fulfilledPromiseList = promises
        .filter((promise) => promise.status === 'fulfilled')
        .map((promise) => {
          if ('value' in promise) {
            return promise.value
          }
        })

      // show error message to user when server side error
      if (imageFiles.length && !fulfilledPromiseList.length) {
        addNotification({
          type: 'error',
          content: t('message.our_server_error')
        })
        return
      }

      track('Element Imported To File', {
        fileId,
        projectId,
        space,
        teamName,
        location,
        elementImportType: 'image'
      })

      dataStore.startTransaction()
      dataStore.eam.activateSelectTool()
      dataStore.createImageElement(fulfilledPromiseList, target, worldPos)
      dataStore.endTransaction()
    },
    [uploadImageList, space, teamName, location, dataStore, addNotification, t]
  )

  type InsertImageOptions = {
    multiple?: boolean
  }
  const insertImage = useCallback(
    async (options: InsertImageOptions = { multiple: false }) => {
      dataStore.eam.insertImage(options)
    },
    [dataStore]
  )

  const getImageUrl = useCallback(
    (imageId: string) => {
      const image = dataStore.images.getImage(imageId)
      if (image) {
        return image.src
      }
    },
    [dataStore]
  )

  return {
    getImageUrl,
    insertImage,
    uploadAndCreateImage,
    createElementByImages
  }
}

export default useEditorImage
