import { useCallback } from 'react'

import { createProvider } from './utils'

type VirtualProperty = {
  [key: string]: boolean | undefined
}

type ElementsDataProp = {
  [key: string]: VirtualProperty
}

const defaultValue: ElementsDataProp = {}
const [Provider, useSelectState, useSetState, getStateSnapshot] = createProvider<ElementsDataProp>(
  'ElementVirtualProperties',
  defaultValue
)

export const useElementVirtualProperties = useSelectState
export const getElementVirtualPropertiesSnapshot = getStateSnapshot

export const useSetElementVirtualProperties = () => {
  const setState = useSetState()

  const updateElementVirtualProperties = useCallback(
    (elementId: string, elementData: VirtualProperty) => {
      if (!setState) {
        return
      }

      setState((state: ElementsDataProp) => ({
        ...state,
        [elementId]: {
          ...state[elementId],
          ...elementData
        }
      }))
    },
    [setState]
  )

  return { updateElementVirtualProperties }
}

export default Provider
