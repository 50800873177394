import React from 'react'
import { useTranslation } from 'react-i18next'

import { FilesPageEnum } from '../..'
import { Empty } from '../../../../components/shared'
import getStartedIllustration from './get-started.svg'
import noFilesIllustration from './no-file.svg'

type EmptyListProps = {
  page: FilesPageEnum
  hasCreateFilePermission?: boolean
}

const EmptyList = ({ page = FilesPageEnum.DRAFT_PROJECT, hasCreateFilePermission = true }: EmptyListProps) => {
  const { t } = useTranslation('workspace')
  switch (page) {
    case FilesPageEnum.NORMAL_PROJECT:
      if (hasCreateFilePermission) {
        return (
          <Empty
            title={t('empty_list.normal_project_title')}
            description={t('empty_list.normal_project_description')}
            image={{ src: getStartedIllustration, width: 320, height: 200 }}
          />
        )
      }
      return (
        <Empty
          title={t('empty_list.no_permission_project_title')}
          description={t('empty_list.no_permission_project_description')}
          image={{ src: noFilesIllustration, width: 320, height: 200 }}
        />
      )
    case FilesPageEnum.ARCHIVE:
      return (
        <Empty
          title={t('empty_list.archive_title')}
          description={t('empty_list.archive_description')}
          image={{ src: noFilesIllustration, width: 320, height: 200 }}
        />
      )
    case FilesPageEnum.SHARED:
      return (
        <Empty
          title={t('empty_list.shared_title')}
          description={t('empty_list.shared_description')}
          image={{ src: noFilesIllustration, width: 320, height: 200 }}
        />
      )
    default:
      if (hasCreateFilePermission) {
        return (
          <Empty
            title={t('empty_list.normal_project_title')}
            description={t('empty_list.normal_project_description')}
            image={{ src: getStartedIllustration, width: 320, height: 200 }}
          />
        )
      }
      return (
        <Empty
          title={t('empty_list.shared_title')}
          description={t('empty_list.draft_description')}
          image={{ src: noFilesIllustration, width: 320, height: 200 }}
        />
      )
  }
}

export default EmptyList
