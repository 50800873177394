import PropertyStack from '../../PropertyStack'

class EndsStack extends PropertyStack {
  constructor(elementStack, data) {
    super(elementStack, data)
    this.type = 'ENDS'
    this.key = 'ends'
    this.dataKey = 'ends'
    this.animatableProperties = new Set()
  }
}

export default EndsStack
