import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useModal, useSetModal } from '../../../providers/ModalProvider'
import { usePaint } from '../../../providers/dataStore/PaintProvider'
import { formatAsPercentage, multiplyBy100 } from '../../../utils/formatter'
import useColorTextGenerator from '../../InspectPanel/Hooks/useColorTextGenerator'
import { Modal, ScrollView } from '../../shared'
import ColorBox from '../../shared/ColorBox'
import Header from './Header'

type GradientDetailsProps = {
  id: string
}

const GradientDetailsModal = ({ id }: GradientDetailsProps) => {
  const MODAL_KEY = `InspectContextMenu-${id}`
  const { t } = useTranslation('file', { keyPrefix: 'inspect' })
  const { closeModal } = useSetModal(MODAL_KEY)
  const { opacity, gradientStops } = usePaint((o) => o[id])

  const {
    open,
    data: { trigger }
  } = useModal((o: any) => o[MODAL_KEY])
  const { generateColorString } = useColorTextGenerator()

  const closeStrokeAdvancedModal = useCallback(() => {
    closeModal()
  }, [closeModal])

  const getColorBox = (stop: any) => {
    let a
    if (stop?.color) {
      a = stop?.color?.slice(0, 4)
    }

    return <ColorBox color={a} paintType={0} gradientStops={gradientStops} size="m" />
  }

  return (
    <Modal open={open} trigger={trigger} onClose={closeStrokeAdvancedModal} placement="horizontal" offsetX={224}>
      {open && (
        <div className="pb-16">
          <Header name={t('gradient_details')} modalKey={MODAL_KEY} />
          {/* @ts-ignore adjust when ScrollView has been refactored */}
          <ScrollView noScrollbar>
            <div className="max-h-[calc(100vh-100px)]">
              {gradientStops.map((stop: any, index: string) => (
                <div key={index} className="flex flex-col px-8">
                  <div className="flex p-8 gap-8">
                    <div className="w-[68px] text-light-overlay-60">{`${t('stop')} ${index + 1}`}</div>
                  </div>
                  <div className="flex">
                    <div className="w-4 h-24 mt-8 ml-12 border-l border-solid border-light-overlay-60" />
                    <div className="flex p-8 gap-8">
                      <div className="w-[68px] text-light-overlay-60">{t('location')}</div>
                      <div className="text-white">{formatAsPercentage(multiplyBy100(stop?.position))}</div>
                    </div>
                  </div>
                  <div className="flex">
                    <div className="w-4 h-24 mb-8 ml-12 border-l border-solid border-light-overlay-60" />
                    <div className="flex items-center p-8 gap-8">
                      <div className="w-[68px] text-light-overlay-60">{t('color')}</div>
                      {getColorBox(stop)}
                      <div className="text-white">{generateColorString(stop?.color, opacity)}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </ScrollView>
        </div>
      )}
    </Modal>
  )
}

export default React.memo(GradientDetailsModal)
