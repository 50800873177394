import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteConfig, renderRoutes } from 'react-router-config'

import { useAtom } from 'jotai'

import { TutorialStatus } from '@phase-software/types'
import styled from 'styled-components'

import { nonChromiumWarning } from '../../atoms'
import { NotificationList } from '../../components/Notification'
import TutorialDialog from '../../components/modals/TutorialDialog'
import { ScrollView } from '../../components/shared'
import { color } from '../../components/shared/utils'
import { useSkeletonLoadingContext } from '../../providers/SkeletonLoadingProvider'
import { useSetTutorial, useTutorial } from '../../providers/TutorialProvider'
import { TutorialType } from '../../tutorials'
import WorkspaceSidebar from './WorkspaceSidebar'

const TUTORIAL_KEY = 'DASHBOARD'

const Scrollable = styled(ScrollView)`
  display: flex;
  flex-direction: column;
  place-self: stretch;
  flex-grow: 1;
  height: auto;
  overflow: auto;
`

const Layout = styled.div`
  display: grid;
  grid-template-columns: 248px 1fr;
  place-items: stretch;
  height: 100vh;
  width: 100vw;
  color: ${color('white')};
  background: ${color('neutrals', 90)};
`

type WorkspaceRootProps = {
  route: RouteConfig
}
const WorkspaceRoot = ({ route: { routes } }: WorkspaceRootProps) => {
  const { t } = useTranslation('tutorial')
  const { updateWorkspaceFileListReady, updateWorkspaceSidebarReady } = useSkeletonLoadingContext()
  const activeTutorial = useTutorial((o) => o.active)
  const dashboardTutorial = useTutorial((o) => o.status)?.[TUTORIAL_KEY]
  const { startVideoTutorial, closeTutorial } = useSetTutorial()
  const [isNonChromiumWarningOpen] = useAtom(nonChromiumWarning)

  useEffect(() => {
    if (dashboardTutorial?.status !== TutorialStatus.COMPLETED) {
      startVideoTutorial(TutorialType[TUTORIAL_KEY])
    }

    return () => {
      // set sidebar and files not ready when unmounting
      // for every time entering workspace routes shows the skeleton loading if projectList or fileList request not complete
      updateWorkspaceFileListReady(false)
      updateWorkspaceSidebarReady(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleTutorialComplete = useCallback(
    (doNotShowAgain: boolean) => {
      closeTutorial(doNotShowAgain)
    },
    [closeTutorial]
  )

  return (
    <Layout>
      <NotificationList offsetLeft={248} />
      <WorkspaceSidebar />
      {/* @ts-ignore */}
      <Scrollable className="bg-neutral-80">{renderRoutes(routes)}</Scrollable>
      {!isNonChromiumWarningOpen && (
        <TutorialDialog
          title={t('welcome_to_phase')}
          confirmText={t('explore_dashboard')}
          onComplete={handleTutorialComplete}
          activeTutorial={activeTutorial}
        />
      )}
    </Layout>
  )
}

export default WorkspaceRoot
