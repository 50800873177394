import React from 'react'

import { ReactComponent as DiamondIcon } from './icons/explicit.svg'
import { ReactComponent as Highlighted } from './icons/highlighted.svg'
import { ReactComponent as HalfDiamondIcon } from './icons/initial.svg'
import { ReactComponent as EmptyDiamond } from './icons/tween.svg'

type KeyframeProps = {
  isIndicator?: boolean
  selected?: boolean
  highlighted?: boolean
  shouldScaleOnHover?: boolean
  type?: 'EXPLICIT' | 'INITIAL' | 'TWEEN' | 'DEFAULT'
}

const InitialIcon = ({
  colorClassName,
  isIndicator,
  highlighted,
  className = '',
  ...props
}: {
  highlighted?: boolean
  colorClassName?: string
  isIndicator?: boolean
  className?: string
}) => {
  return (
    <div {...props} className={`${className}`}>
      <div className={`absolute inset-0 ${isIndicator ? 'text-white' : 'text-light-overlay-60'}`}>
        <HalfDiamondIcon />
      </div>
      <div className={`${colorClassName}`}>
        <DiamondIcon />
        <div className={`absolute inset-0 js-kf-highlight ${highlighted ? '' : 'hidden'}`}>
          <Highlighted />
        </div>
      </div>
    </div>
  )
}

const DiamondIconWrapper = ({
  highlighted,
  className = '',
  colorClassName = '',
  ...props
}: {
  highlighted?: boolean
  className?: string
  colorClassName?: string
}) => {
  return (
    <div {...props} className={`${className} ${colorClassName}`}>
      <DiamondIcon />
      <div className={`absolute inset-0 js-kf-highlight ${highlighted ? '' : 'hidden'}`}>
        <Highlighted />
      </div>
    </div>
  )
}

const EmptyDiamondIcon = ({
  className = '',
  colorClassName = '',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  highlighted,
  ...props
}: {
  className?: string
  colorClassName?: string
  highlighted?: boolean
}) => {
  return (
    <div {...props} className={`${className} ${colorClassName}`}>
      <EmptyDiamond />
    </div>
  )
}

const KEYFRAME_TYPE_MAP = {
  EXPLICIT: DiamondIconWrapper,
  INITIAL: InitialIcon,
  TWEEN: EmptyDiamondIcon,
  DEFAULT: null
}

const Keyframe = ({
  type = 'DEFAULT',
  selected = false,
  isIndicator = false,
  highlighted = false,
  shouldScaleOnHover = true,
  ...props
}: KeyframeProps) => {
  const IconComponent = KEYFRAME_TYPE_MAP[type]

  if (!IconComponent) return null

  const colorClassName = isIndicator
    ? 'text-light-overlay-60 active:text-light-overlay-40'
    : selected
    ? 'text-primary-40'
    : 'text-neutral-20'

  const scaleClassName = shouldScaleOnHover ? 'hover:scale-125' : 'scale-125'

  if (type === 'INITIAL') {
    return (
      <IconComponent
        {...props}
        className={`w-full transform ${scaleClassName}`}
        highlighted={highlighted}
        colorClassName={colorClassName}
        isIndicator={isIndicator}
      />
    )
  } else {
    return (
      <IconComponent
        {...props}
        className={`w-full transform ${scaleClassName}`}
        highlighted={highlighted}
        colorClassName={colorClassName}
      />
    )
  }
}

export default Keyframe
