import React from 'react'
import { useTranslation } from 'react-i18next'

import { useModal, useSetModal } from '../../../providers/ModalProvider'
import PathDetailsModal from '../Modal/PathDetailsModal'
import InspectRow from './InspectRow'
import InteractiveInspectContent from './InteractiveInspectContent'

const PathInspection = () => {
  const MODAL_KEY = `InspectContextMenu-PathDetails`
  const { t } = useTranslation('file', { keyPrefix: 'inspect' })
  const { toggleModal, closeAllModals } = useSetModal(MODAL_KEY)
  const { open } = useModal((o: any) => o[MODAL_KEY])

  const handleClick = () => {
    if (!open) closeAllModals()
    toggleModal()
  }

  return (
    <InteractiveInspectContent title={t('path_shape')} target="static_pathMorphing">
      <InspectRow label={t('path_data')} showDetailsButton copyDisabled onClickDetails={handleClick} />
      <PathDetailsModal />
    </InteractiveInspectContent>
  )
}

export default PathInspection
