import React, { forwardRef } from 'react'

const styleMap = {
  primary: { padding: 'p-8', fontStyle: 'font-semibold text-white' },
  secondary: { padding: 'px-8', fontStyle: 'text-light-overlay-60 group-hover:text-white' }
}

type SidebarTitleProps = {
  type?: 'primary' | 'secondary'
  onClick?: () => void
  dataTestId?: string
  className?: string
  children: React.ReactNode
}

const SidebarTitle = forwardRef<HTMLDivElement, SidebarTitleProps>(
  ({ type = 'primary', onClick, dataTestId, className = '', children }, ref) => {
    return (
      <div
        ref={ref}
        className={`group cursor-pointer ${styleMap[type].padding}`}
        onClick={onClick}
        data-test-id={dataTestId}
      >
        <div
          className={`min-h-[48px] gap-16 px-16 text-13 truncate flex items-center rounded-md group-hover:bg-light-overlay-10 ${styleMap[type].fontStyle} ${className}`}
        >
          {children}
        </div>
      </div>
    )
  }
)

SidebarTitle.displayName = 'SidebarTitle'

export default SidebarTitle
