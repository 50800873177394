import React, { useCallback } from 'react'
import { IconButton } from '../shared'
import { useEditorActions } from '../../providers/dataStore/EditorProvider'

type RemoveEffectProps = {
  effectItemId: string
}

const RemoveEffect = ({ effectItemId }: RemoveEffectProps) => {
  const { removeEffect } = useEditorActions()

  const handleRemove = useCallback(() => {
    removeEffect(effectItemId)
  }, [effectItemId, removeEffect])

  return <IconButton size="l" data-test-id="effect-remove-button" icon="Remove" onClick={handleRemove} />
}

export default React.memo(RemoveEffect)
