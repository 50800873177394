import { TutorialType } from '../constant'
import tip01 from '../assets/0.1.gif'
import tip21 from '../assets/2.1.gif'
import tip22 from '../assets/2.2.gif'
import tip24 from '../assets/2.4.gif'
import addKeyFrameMp4 from '../assets/how-to-add-keyframe.mp4'
import { delay } from '../utils'

export default {
  key: TutorialType.GENERAL_INTERFACE,
  mask: true,
  progress: true,
  interact: true,
  intro: {
    header: 'You’ll go through a quick tutorial',
    content: 'With a 5 step tutorial, you’ll get basic idea how to create animation on Phase.'
  },
  steps: [
    {
      header: 'Switch Mode',
      content: 'Go check Animation Mode!',
      image: tip01,
      anchor: '.pt-mode-switcher',
      onShow: (next) => {
        document.querySelector('.pt-action-mode').addEventListener('click', next)
        return () => {
          document.querySelector('.pt-action-mode').removeEventListener('click', next)
        }
      },
      onNext: async () => {
        document.querySelector('.pt-action-mode').click()
        await delay(500)
      },
      offset: 8
    },
    {
      header: 'Action Mode',
      content: 'Action Panel shows all animation-related settings.',
      image: tip21,
      anchor: '.pt-action-panel',
      video: {
        title: '👀 How to add Keyframe?',
        src: addKeyFrameMp4
      }
    },
    {
      header: 'Playback Control',
      content: 'You can set up all player related items here, e.g. play, stop, set speed, set maximum time.',
      image: tip22,
      anchor: '.pt-player',
      placement: 'top'
    },
    {
      header: 'Playhead',
      content: 'You can use Playhead to inspect what the Canvas looks like at a specific time of the animation.',
      anchor: '.pt-playhead',
      highlight: ['.pt-action-ruler', '.pt-action-track-list'],
      image: tip24
    },
    {
      header: ' Learn anytime',
      content: 'You can always check our feature tips here.',
      anchor: '.pt-tutorial-menu',
      highlight: ['.pt-tutorial', '.pt-tutorial-menu'],
      placement: 'left',
      required: true,
      onShow: (next) => {
        document.querySelector('.pt-tutorial').click()
        document.querySelector('.pt-tutorial').addEventListener('click', next)
        return () => {
          document.querySelector('.pt-tutorial').removeEventListener('click', next)
        }
      }
    }
  ]
}
