import { EffectType, TrimPathMode } from '@phase-software/types'
import { setAdd } from '@phase-software/data-utils'
import { DEFAULT_EFFECT_VALUE } from '../constant'
import { ComputedEffect } from './ComputedEffect'


const UNDO_CHANGES = [
    'start',
    'end',
    'offset',
    'mode'
]

const NOT_ANIMATABLE_PROPS = new Set(['mode'])


export class ComputedTrimPath extends ComputedEffect {
    /**
     * @param {DataStore} dataStore
     * @param {ComputedEffectData} data
     */
    constructor(dataStore, data) {
        super(dataStore, data)

        this.notAnimatableProps = NOT_ANIMATABLE_PROPS

        setAdd(this.undoChanges, UNDO_CHANGES)
    }

    _init() {
        super._init()

        this.propTypes = { ...this.propTypes }

        this.data.effectType = EffectType.TRIM_PATH
        this.data.start = 0
        this.data.end = 100
        this.data.offset = 0
        this.data.mode = TrimPathMode.SIMULTANEOUSLY
    }


    /**
     * @param {ComputedTrimPathData} data
     */
    load(data) {
        super.load(data)

        this.data.effectType = data.effectType
        this.data.start = data.start
        this.data.end = data.end
        this.data.offset = data.offset
        this.data.mode = data.mode
    }

    defaultValue() {
        return DEFAULT_EFFECT_VALUE[this.data.effectType]
    }
}
