import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, useParams } from 'react-router'

import { FileFieldsFragment, ProjectFieldsFragment, useGetFileByIdQuery } from '../../generated/graphql'
import { useFullScreenLoadingContext } from '../../providers/FullScreenLoadingProvider'
import { useWorkspaceContext } from '../../providers/WorkspaceContextProvider'
import { projectFileService } from '../../services/api'
import { getFileIndexPath, getWorkspaceProjectPath } from '../../utils/pathGenerators'

const TemplateCloner = () => {
  const { t } = useTranslation('workspace')
  const { workspaceData, isWorkspaceDataLoading } = useWorkspaceContext()
  const { startFullScreenLoading, stopFullScreenLoading } = useFullScreenLoadingContext()
  const { templateId } = useParams<{ templateId: FileFieldsFragment['id'] }>()
  const [redirectPath, setRedirectPath] = useState<string>('')

  const { data: fileQueryData, loading: fileQueryLoading } = useGetFileByIdQuery({
    variables: {
      id: templateId
    }
  })

  const isLoading = isWorkspaceDataLoading && fileQueryLoading

  const cloneTemplate = useCallback(
    async ({
      projectId,
      fileId,
      destinationProjectId,
      name
    }: {
      projectId: ProjectFieldsFragment['id']
      fileId: FileFieldsFragment['id']
      destinationProjectId: ProjectFieldsFragment['id']
      name: string
    }) => {
      const response = await projectFileService.projectFileControllerDuplicateTemplate({
        projectId,
        fileId,
        duplicateTemplateRequest: {
          name,
          destinationProjectId
        }
      })
      return response
    },
    []
  )

  useEffect(() => {
    startFullScreenLoading()
    if (!isLoading && fileQueryData) {
      const file = fileQueryData.files_by_pk
      const projectId = file?.project_id
      const name = file?.name
      const executeClone = async () => {
        try {
          const { id } = await cloneTemplate({
            projectId: projectId,
            fileId: templateId,
            destinationProjectId: workspaceData.draftProjectId,
            name: `${name} ${t('copy')}`
          })
          const clonedFilePath = getFileIndexPath(id)
          setRedirectPath(clonedFilePath)
        } catch (e) {
          console.error(e)
          const projectDraftPath = getWorkspaceProjectPath(
            workspaceData.type,
            workspaceData.slug,
            workspaceData.draftProjectId
          )
          setRedirectPath(projectDraftPath)
        } finally {
          stopFullScreenLoading()
        }
      }
      executeClone()
    }
    return () => {
      stopFullScreenLoading()
    }
  }, [
    isLoading,
    fileQueryData,
    templateId,
    workspaceData,
    cloneTemplate,
    startFullScreenLoading,
    stopFullScreenLoading,
    t
  ])

  return redirectPath ? <Redirect to={redirectPath} /> : null
}

export default TemplateCloner
