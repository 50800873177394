import React, { useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

// @ts-ignore TODO: fix after refactor of phase-software/renderer
import { IS } from '@phase-software/renderer'

import { useConfirmation, useSetConfirmation } from '../../providers/ConfirmationProvider'
import { Checkbox, Dialog } from '../shared'

const Confirmation = () => {
  const { t } = useTranslation('tutorial')
  const checkboxRef = useRef<HTMLInputElement>(null)
  const confirmation = useConfirmation()
  const { closeConfirmation } = useSetConfirmation()

  const handleClose = useCallback(() => {
    if (confirmation.onCancel) {
      confirmation.onCancel(checkboxRef.current?.checked)
    }
    closeConfirmation()
  }, [confirmation, closeConfirmation, checkboxRef])

  const handleConfirm = () => {
    if (confirmation.onConfirm) {
      confirmation.onConfirm(checkboxRef.current?.checked)
    }
    closeConfirmation()
  }

  useEffect(() => {
    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        handleClose()
      }
    }
    if (confirmation) {
      IS.pause()
      window.addEventListener('keyup', handleKeyUp)
    } else {
      IS.resume()
    }
    return () => {
      IS.resume()
      window.removeEventListener('keyup', handleKeyUp)
    }
  }, [confirmation, handleClose])

  useEffect(() => {
    const overflow = document.body.style.overflow
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = overflow
    }
  }, [])

  if (!confirmation) {
    return null
  }

  return (
    <Dialog
      size="xs"
      title={confirmation.subject}
      confirmBtnText={confirmation.confirmText}
      onConfirm={handleConfirm}
      onCancel={handleClose}
      open={!!confirmation}
    >
      <div className="mb-16">{confirmation.content}</div>
      <Checkbox label={t('dont_show_again')} ref={checkboxRef} />
    </Dialog>
  )
}

export default Confirmation
