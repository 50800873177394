import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Mode } from '@phase-software/types'

import { useEditor, useEditorActions } from '../../../../providers/dataStore/EditorProvider'
import { useUI } from '../../../../providers/dataStore/UIProvider'
import { IconButton } from '../../../shared'

const AutoOrient = () => {
  const { mode } = useUI()
  const autoOrient = useEditor((o) => o.autoOrient)
  const { setProperties } = useEditorActions()
  const isDesignMode = mode === Mode.DESIGN
  const { t } = useTranslation('file', { keyPrefix: 'property_editor' })

  const toggleAutoOrient = useCallback(() => {
    setProperties({ autoOrient: !autoOrient })
  }, [autoOrient, setProperties])

  const icon = autoOrient ? 'AutoOrientOn' : 'AutoOrientOff'
  if (isDesignMode) {
    return null
  }
  return <IconButton tip={t('auto_oriented')} icon={icon} onClick={toggleAutoOrient} />
}

export default AutoOrient
