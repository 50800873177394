import React, { forwardRef, useCallback, useEffect, useState } from 'react'
import Comment, { CommentProps } from '../shared/CommentThread/Comment'
import { useDeleteComment, useEditComment, useSelf, useUser } from '../../liveblocks.config'
import { toLiveblocksComment } from './utils'
import { CommentDataProps } from '../shared/CommentThread/types'
import DeleteCommentDialog from '../modals/DeleteCommentDialog'
import { useCommentContext } from '../../providers/CommentProvider'

export type CommentWithDataProps = Omit<
  CommentProps,
  'userName' | 'userAvatar' | 'currentUserId' | 'toggleDeleteDialog'
>

const CommentWithData = forwardRef<HTMLDivElement, CommentWithDataProps>(
  ({ type, comment, isExpanded, ...props }, forwardedRef) => {
    const uniqueCommentId = `${type}-${comment.id}`
    const { user }: any = useUser(comment.userId)
    const currentUser = useSelf()
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

    const {
      editingCommentId: commentInEdit,
      setEditingCommentId: setCommentInEdit,
      scrollTargetId,
      setScrollTargetId,
      scrollToThread
    } = useCommentContext()

    const editComment = useEditComment()
    const deleteComment = useDeleteComment()

    const updateComment = useCallback(
      (comment: CommentDataProps, newCommentText: string) => {
        const data = toLiveblocksComment(newCommentText, comment.threadId)
        editComment({
          commentId: comment.id,
          ...data
        })
      },
      [editComment]
    )

    const toggleDeleteDialog = useCallback(
      (open: boolean) => {
        setDeleteDialogOpen(open)
      },
      [setDeleteDialogOpen]
    )

    const handleDeleteComment = useCallback(() => {
      deleteComment({ threadId: comment.threadId, commentId: comment.id })
      setDeleteDialogOpen(false)
    }, [deleteComment, comment])

    const handleEditingComment = useCallback(
      (isEditing: boolean) => {
        if (isEditing) {
          setCommentInEdit(uniqueCommentId)
        } else {
          setCommentInEdit((commentIdInEdit) => {
            if (commentIdInEdit === uniqueCommentId) {
              return ''
            }
            return commentIdInEdit
          })
        }
      },
      [setCommentInEdit, uniqueCommentId]
    )
    useEffect(() => {
      if (isExpanded && scrollTargetId) {
        scrollToThread(scrollTargetId)
        setScrollTargetId('')
      }
    }, [scrollTargetId, setScrollTargetId, scrollToThread, isExpanded])
    return (
      <>
        <Comment
          key={'Comment' + comment.id}
          ref={forwardedRef}
          type={type}
          comment={comment}
          isExpanded={isExpanded}
          userName={user?.name || 'Unknown'}
          userAvatar={user?.avatar || ''}
          currentUserId={currentUser.id}
          onCommentEdit={updateComment}
          toggleDeleteCommentDialog={toggleDeleteDialog}
          isEditing={commentInEdit === uniqueCommentId}
          setEditingComment={handleEditingComment}
          {...props}
        />
        <DeleteCommentDialog
          open={deleteDialogOpen}
          confirmDelete={handleDeleteComment}
          toggleDialog={toggleDeleteDialog}
        />
      </>
    )
  }
)

CommentWithData.displayName = 'CommentWithData'

export default CommentWithData
