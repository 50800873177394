import { useCallback } from 'react'
import { createProvider } from '../utils'

const [Provider, useSelectState, useSetState] = createProvider('Clipboard', {
  hasContent: false
})

export const useClipboard = useSelectState

export const useSetClipboard = () => {
  const setState = useSetState()
  const copy = useCallback(() => setState({ hasContent: true }), [setState])
  return { copy }
}

export default Provider
