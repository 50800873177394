import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { LayerType } from '@phase-software/types'

import { useDataStoreActions } from '../../../../providers/dataStore/DataStoreProvider'
import { useEditorActions } from '../../../../providers/dataStore/EditorProvider'
import { useStroke } from '../../../../providers/dataStore/StrokeProvider'
import { parseToDecimals } from '../../../../utils/number'
import { Icon, Input } from '../../../shared'
import { InputOptions } from '../../../shared/Input/Input.types'
import Label from '../../../shared/Label'

const icon = <Icon name="StrokeWidth" interactive={false} />

type StrokeWidthProps = {
  layerItemId: string
}

export const StrokeWidth = ({ layerItemId }: StrokeWidthProps) => {
  const { commitUndo, debounceCommitUndo } = useDataStoreActions()
  const width = useStroke((o) => o[layerItemId].width)
  const { setLayerWidth, toggleLayerVisible } = useEditorActions()
  const { t } = useTranslation('file', { keyPrefix: 'property_editor.stroke_editor' })

  const handleOffsetChange = (value: number, options: InputOptions) => setLayerWidth(layerItemId, value, options)
  const handleFocus = () => toggleLayerVisible(layerItemId, true)

  const _width = isNaN(width) ? width : parseToDecimals(width, 2)

  const keyFrameIconProps = useMemo(
    () => ({
      name: 'width',
      layerItemId,
      layerType: LayerType.STROKE
    }),
    [layerItemId]
  )

  return (
    <div className="grid gap-4 items-center grid-flow-col">
      <Input
        // @ts-ignore TODO: fix after this component is being used
        tooltip={t('stroke_width')}
        keyFrameIconProps={keyFrameIconProps}
        variant="normal"
        type="number"
        data-test-id="border-width-input"
        min={0}
        formatter={(v: number) => parseToDecimals(v, 2)}
        value={_width}
        onChange={handleOffsetChange}
        onFocus={handleFocus}
        onBlur={commitUndo}
        onStepChange={debounceCommitUndo}
        rightComponent={<Label icon={icon} />}
        spinner
      />
    </div>
  )
}

export default React.memo(StrokeWidth)
