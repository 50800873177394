import React, { createContext, useCallback, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { CUSTOM_EVENT_NAMES } from '../constants'
import { useSetNotification } from './NotificationProvider'
import { useProfileActions } from './ProfileProvider'

type PresenceContextType = {
  isPresenceShowByFile: Map<string, boolean>
  setIsPresenceShowByFile: React.Dispatch<React.SetStateAction<Map<string, boolean>>>
  syncPresencePreference: (fileId: string, isShow: boolean) => void
}

type PresenceProviderProps = {
  children: React.ReactNode
  isPresenceShowByFile: Map<string, boolean>
  setIsPresenceShowByFile: React.Dispatch<React.SetStateAction<Map<string, boolean>>>
}

const defaultValue = {
  isPresenceShowByFile: new Map(),
  setIsPresenceShowByFile: () => {},
  syncPresencePreference: () => {}
}

const PresenceContext = createContext<PresenceContextType>(defaultValue)

const PresenceProvider = ({ children, isPresenceShowByFile, setIsPresenceShowByFile }: PresenceProviderProps) => {
  const { t } = useTranslation('common')
  const { updatePresencePreference } = useProfileActions()
  const { addNotification, removeNotification } = useSetNotification()

  // set presence preference
  const syncPresencePreference = useCallback(
    (fileId: string, isShow: boolean) => {
      if (!fileId || typeof isShow !== 'boolean') return

      setIsPresenceShowByFile((prev) => {
        const newMap = new Map(prev)
        newMap.set(fileId, isShow)

        updatePresencePreference(JSON.stringify(Object.fromEntries(newMap)))
        if (window.PresenceManager) {
          window.PresenceManager.isPresenceShow = isShow
        }
        return newMap
      })
    },
    [setIsPresenceShowByFile, updatePresencePreference]
  )

  const handleNatsEvents = useCallback(
    async (event: Event) => {
      const { type } = event

      let message = ''
      switch (type) {
        case CUSTOM_EVENT_NAMES.NATS_CONNECTED:
          removeNotification('presence')
          break
        case CUSTOM_EVENT_NAMES.NATS_CONNECT_CLOSED:
          message = t('reconnecting')
          break
        case CUSTOM_EVENT_NAMES.NATS_CONNECT_ERROR:
          message = t('disconnected_try_to_reconnect')
          break
        default:
          break
      }

      if (message) {
        addNotification({
          id: 'presence',
          type: 'loading',
          content: message
        })
      }
    },
    [removeNotification, addNotification, t]
  )

  useEffect(() => {
    document.addEventListener(CUSTOM_EVENT_NAMES.NATS_CONNECTED, handleNatsEvents)
    document.addEventListener(CUSTOM_EVENT_NAMES.NATS_CONNECT_CLOSED, handleNatsEvents)
    document.addEventListener(CUSTOM_EVENT_NAMES.NATS_CONNECT_ERROR, handleNatsEvents)
    return () => {
      document.removeEventListener(CUSTOM_EVENT_NAMES.NATS_CONNECTED, handleNatsEvents)
      document.removeEventListener(CUSTOM_EVENT_NAMES.NATS_CONNECT_CLOSED, handleNatsEvents)
      document.removeEventListener(CUSTOM_EVENT_NAMES.NATS_CONNECT_ERROR, handleNatsEvents)
    }
  }, [handleNatsEvents])

  return (
    <PresenceContext.Provider
      value={{
        isPresenceShowByFile,
        setIsPresenceShowByFile,
        syncPresencePreference
      }}
    >
      {children}
    </PresenceContext.Provider>
  )
}

function usePresenceContext(): PresenceContextType {
  const context = useContext(PresenceContext)
  if (context === undefined) {
    throw new Error('usePresenceContext must be used within a PresenceProvider')
  }
  return context
}

export { PresenceProvider, usePresenceContext }
