import { PaintType, EffectType } from '@phase-software/types'

export const EFFECT_TYPE_NAME_MAP = {
  [EffectType.TRIM_PATH]: 'trimPath'
}

// TODO: We only have position change for Text in MVP
// const TEXT_PROPERTIES = {
//   text: TextStack
// }

export const childListMap = new Map([
  ['position', ['x', 'y']],
  ['size', ['width', 'height']],
  ['scale', ['scaleX', 'scaleY']],
  ['skew', ['skewX', 'skewY']]
])

export const GRADIENT_TYPE_SET = new Set([
  PaintType.GRADIENT_LINEAR,
  PaintType.GRADIENT_RADIAL,
  PaintType.GRADIENT_ANGULAR,
  PaintType.GRADIENT_DIAMOND,
])

export const DEFAULT_GRADIENT_TRANSFORM = [0, -1, 1, 0, 0, 1]
