import React, { useMemo } from 'react'
import { LayerType } from '@phase-software/types'
import { Input } from '../../../shared'
import { useStroke } from '../../../../providers/dataStore/StrokeProvider'
import { useDataStoreActions } from '../../../../providers/dataStore/DataStoreProvider'
import { useEditorActions } from '../../../../providers/dataStore/EditorProvider'
import { parseToDecimals } from '../../../../utils/number'
import { degreeFormatValidator } from '../../../../utils/validator'
import { InputOptions } from '../../../shared/Input/Input.types'

type StrokeMiterProps = {
  layerItemId: string
}

export const StrokeMiter = ({ layerItemId, ...rest }: StrokeMiterProps) => {
  const miter = useStroke((o) => o[layerItemId].miter)
  const { setLayerMiter, toggleLayerVisible } = useEditorActions()
  const { commitUndo, debounceCommitUndo } = useDataStoreActions()

  const _miter = isNaN(miter) ? miter : parseToDecimals(miter, 2)

  const keyFrameIconProps = useMemo(
    () => ({
      layerItemId,
      name: 'miter',
      layerType: LayerType.STROKE
    }),
    [layerItemId]
  )

  return (
    <Input
      // @ts-ignore TODO: fix after this component is being used
      variant="normal"
      keyFrameIconProps={keyFrameIconProps}
      type="number"
      name="miter"
      data-test-id="border-miter-input"
      min={7.17}
      max={180}
      value={_miter}
      formatter={(v: number) => `${parseToDecimals(v, 2)}°`}
      validator={degreeFormatValidator}
      onBlur={commitUndo}
      onFocus={() => toggleLayerVisible(layerItemId, true)}
      onChange={(miter: number, options: InputOptions) => setLayerMiter(layerItemId, miter, options)}
      onStepChange={debounceCommitUndo}
      spinner
      {...rest}
    />
  )
}

export default React.memo(StrokeMiter)
