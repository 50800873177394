import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router'

import useVersionLoader, {
  INITIAL_VERSION_ID,
  SPECIFIC_VERSION_PARAMETER_NAME
} from '../pages/File/components/FileVersionHistoryPanel/useVersionLoader'
import { useUI, useUIActions } from './dataStore/UIProvider'
import { LATEST_VERSION_ITEM_ID, VersionProps } from '../utils/transformVersion'
import { FileVersionUpdateMessageData } from '../Broadcaster/Broadcaster'

type FileVersionContextProps = {
  loadingVersionInfo: {
    isLoading: boolean
    versionName: string
  }
  currentVersionId: string | null
  debouncedLoadVersion: (version: VersionProps) => void
  loadLatestVersion: () => void
  setCurrentVersionId: (versionId: string | null) => void
  startLoadVersion: (versionName: string) => void
  stopLoadVersion: () => void
  versionAdded: boolean
  resetVersionAdded: () => void
  notifyVersionAdded: () => void
  onFileVersionUpdate: (message: FileVersionUpdateMessageData) => void
  showFileOutdatedConfirmDialog: boolean
  closeRestoreConfirmDialog: () => void
  currentVersionFileContent: object | null
}

const defaultLoadingVersionInfo = {
  isLoading: false,
  versionName: ''
}

const defaultFileVersionContext: FileVersionContextProps = {
  currentVersionId: null,
  debouncedLoadVersion: () => {},
  loadLatestVersion: () => {},
  loadingVersionInfo: defaultLoadingVersionInfo,
  setCurrentVersionId: () => {},
  startLoadVersion: () => {},
  stopLoadVersion: () => {},
  versionAdded: false,
  resetVersionAdded: () => {},
  notifyVersionAdded: () => {},
  onFileVersionUpdate: () => {},
  showFileOutdatedConfirmDialog: false,
  closeRestoreConfirmDialog: () => {},
  currentVersionFileContent: null
}

export const FileVersionContext = createContext<FileVersionContextProps>(defaultFileVersionContext)

const FileVersionContextProvider = ({
  children,
  fileContent,
  fileId,
  projectId,
  canEditFile = false
}: {
  children: React.ReactNode
  fileContent: object | null
  fileId: string
  projectId: string
  canEditFile?: boolean
}) => {
  const history = useHistory()

  const { isVersioningState } = useUI()

  const { enterVersionPreview, leaveVersionPreview } = useUIActions()

  const searchParams = new URLSearchParams(history.location.search)
  const specificVersionId = searchParams.get(SPECIFIC_VERSION_PARAMETER_NAME)

  const isMounted = useRef(false)
  const hasSpecificVersionIdRef = useRef(specificVersionId !== null)

  const [loadingVersionInfo, setLoadingVersionInfo] = useState(defaultLoadingVersionInfo)
  const [versionAdded, setVersionAdded] = useState(false)
  const [showFileOutdatedConfirmDialog, setShowFileOutdatedConfirmDialog] = useState(false)

  const notifyVersionAdded = () => {
    setVersionAdded(true)
  }

  const resetVersionAdded = () => {
    setVersionAdded(false)
  }

  const startLoadVersion = useCallback((versionName: string) => {
    setLoadingVersionInfo({
      isLoading: true,
      versionName
    })
  }, [])

  const stopLoadVersion = useCallback(() => {
    setLoadingVersionInfo(defaultLoadingVersionInfo)
  }, [])

  const onFileVersionUpdate = useCallback(
    (message: FileVersionUpdateMessageData) => {
      switch (message.type) {
        case 'version.create':
          notifyVersionAdded()
          break

        case 'version.restore': {
          if (isVersioningState && specificVersionId !== null) {
            notifyVersionAdded()
            return
          }
          setShowFileOutdatedConfirmDialog(true)
          break
        }
        default:
          break
      }
    },
    [specificVersionId, isVersioningState]
  )

  const closeRestoreConfirmDialog = useCallback(() => {
    setShowFileOutdatedConfirmDialog(false)
    history.go(0)
  }, [history])

  const {
    currentVersionFileContent,
    currentVersionId,
    debouncedLoadVersion,
    loadLatestVersion,
    initializeWithVersion,
    initializeWithoutVersion,
    setCurrentVersionId
  } = useVersionLoader({
    fileContent,
    fileId,
    projectId,
    startLoadVersion,
    stopLoadVersion,
    versionId: specificVersionId || INITIAL_VERSION_ID
  })

  const handleEnterVersionPreview = useCallback(async () => {
    if (currentVersionId !== INITIAL_VERSION_ID) return
    enterVersionPreview()
    setCurrentVersionId(LATEST_VERSION_ITEM_ID)
    await loadLatestVersion()
  }, [currentVersionId, enterVersionPreview, loadLatestVersion, setCurrentVersionId])

  const handleLeaveVersionPreview = useCallback(async () => {
    if (currentVersionId === INITIAL_VERSION_ID) return
    setCurrentVersionId(INITIAL_VERSION_ID)
    await loadLatestVersion()
    leaveVersionPreview()
  }, [currentVersionId, leaveVersionPreview, loadLatestVersion, setCurrentVersionId])

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true

      if (specificVersionId) {
        initializeWithVersion(specificVersionId)
      } else {
        initializeWithoutVersion(canEditFile)
      }
      return
    }

    if (hasSpecificVersionIdRef.current) {
      if (isVersioningState) hasSpecificVersionIdRef.current = false
      return
    }

    if (isVersioningState) {
      handleEnterVersionPreview()
    } else {
      handleLeaveVersionPreview()
    }
  }, [
    canEditFile,
    handleEnterVersionPreview,
    handleLeaveVersionPreview,
    isVersioningState,
    initializeWithoutVersion,
    initializeWithVersion,
    specificVersionId
  ])

  const value = useMemo(
    () => ({
      closeRestoreConfirmDialog,
      currentVersionId,
      currentVersionFileContent,
      debouncedLoadVersion,
      loadLatestVersion,
      loadingVersionInfo,
      notifyVersionAdded,
      onFileVersionUpdate,
      resetVersionAdded,
      setCurrentVersionId,
      showFileOutdatedConfirmDialog,
      startLoadVersion,
      stopLoadVersion,
      versionAdded
    }),
    [
      closeRestoreConfirmDialog,
      currentVersionFileContent,
      currentVersionId,
      debouncedLoadVersion,
      loadLatestVersion,
      loadingVersionInfo,
      onFileVersionUpdate,
      setCurrentVersionId,
      showFileOutdatedConfirmDialog,
      startLoadVersion,
      stopLoadVersion,
      versionAdded
    ]
  )

  return <FileVersionContext.Provider value={value}>{children}</FileVersionContext.Provider>
}

function useFileVersionContext(): FileVersionContextProps {
  const context = useContext(FileVersionContext)
  if (context === undefined) {
    throw new Error('useFileVersionContext must be used within a FileVersionContextProvider')
  }
  return context
}

export { FileVersionContextProvider, useFileVersionContext }
