import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import ImportFailedDialog from '../../../components/modals/ImportFailedDialog'
import { DISALLOWED_IMPORT_MULTIPLE_FILE_MESSAGE, UPLOAD_SVG_ERROR_MESSAGE } from '../../../constant'
import { FEATURE_KEYS } from '../../../growthbook-feature-keys'
import useHeapAnalytics from '../../../hooks/useHeapAnalytics'
import useImportFile from '../../../hooks/useImportFile'
import { useSetNotification } from '../../../providers/NotificationProvider'
import { useWorkspaceContext } from '../../../providers/WorkspaceContextProvider'
import { track } from '../../../services/heapAnalytics'

const FileDropUploader = ({ projectId }: { projectId: string }) => {
  const { t } = useTranslation(['file', 'common'])
  const [error, setError] = useState('')
  const canImportPhase = useFeatureIsOn(FEATURE_KEYS.IMPORT_PHASE_FILE)
  const allowedExtensionSet = useMemo(() => {
    const set = new Set(['json', 'svg', 'lottie'])
    if (canImportPhase) {
      set.add('phase')
    }
    return set
  }, [canImportPhase])

  const importErrorMessage = canImportPhase
    ? t('file:phase_enabled_import_error_message')
    : t('file:phase_disabled_import_error_message')

  const { workspaceData } = useWorkspaceContext()
  const { processImportedFile } = useImportFile({ canImportPhase, projectId, importErrorMessage })
  const { addNotification } = useSetNotification()
  const { space, teamName } = useHeapAnalytics()
  const location = projectId === workspaceData.draftProjectId ? 'drafts' : 'project'

  useEffect(() => {
    const handleDragOver = (e: DragEvent) => {
      e.preventDefault()
    }
    const handleDrop = async (e: DragEvent) => {
      e.preventDefault()

      const files = e.dataTransfer?.files
      const file = files?.[0]
      if (!file) {
        return
      }

      // multiple files not allowed
      if (files.length > 1) {
        addNotification({
          type: 'error',
          escape: true,
          content: DISALLOWED_IMPORT_MULTIPLE_FILE_MESSAGE
        })
        return
      }

      const extension = file.name.split('.').pop()!
      if (!allowedExtensionSet.has(extension.toLowerCase())) {
        return
      }
      try {
        await processImportedFile({ projectId, blob: file })
      } catch (e) {
        console.error(e)
        // import SVG error
        if (e instanceof Error && e.cause === 'network') {
          setError(UPLOAD_SVG_ERROR_MESSAGE)
        } else {
          // import lottie error
          setError(importErrorMessage)
        }

        track('File Import Failed', {
          space,
          teamName,
          location,
          projectId,
          fileImportType: extension,
          errorMessage: (e instanceof Error && e.message) || UPLOAD_SVG_ERROR_MESSAGE || importErrorMessage
        })
      }
    }

    document.body.addEventListener('drop', handleDrop)
    document.body.addEventListener('dragover', handleDragOver)

    return () => {
      document.body.removeEventListener('drop', handleDrop)
      document.body.removeEventListener('dragover', handleDragOver)
    }
  }, [
    processImportedFile,
    allowedExtensionSet,
    setError,
    importErrorMessage,
    projectId,
    addNotification,
    space,
    teamName,
    workspaceData.draftProjectId,
    location
  ])
  return <ImportFailedDialog error={error} setError={setError} />
}

export default FileDropUploader
