import React from 'react'
import { useTranslation } from 'react-i18next'

import { Content, WideTitle } from '../../../shared'
import Origin from './Origin'
import Scale from './Scale'
import Skew from './Skew'

const TransformEditor = () => {
  const { t } = useTranslation('file', { keyPrefix: 'property_editor.transform_editor' })
  return (
    <div className="pt-transform-editor">
      <WideTitle dividerPosition="top">{t('title')}</WideTitle>
      <Content className="pt-0">
        <Origin />
        <Scale />
        <Skew />
      </Content>
    </div>
  )
}

export default React.memo(TransformEditor)
