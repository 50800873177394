import React from 'react'
import CustomContentLoader from './CustomContentLoader'

// Sidebar SidebarTitle loading
const SidebarTitleLoading = () => {
  return (
    <div className="flex items-center p-24 text-light-overlay-10">
      <CustomContentLoader width="100%" height="100%" viewBox="0 0 184 112">
        <rect x="40" y="4" width="144" height="16" rx="5" />
        <rect width="24" height="24" rx="5" />
        <rect y="44" width="24" height="24" rx="5" />
        <rect y="88" width="24" height="24" rx="5" />
        <rect x="40" y="48" width="80" height="16" rx="5" />
        <rect x="40" y="92" width="104" height="16" rx="5" />
      </CustomContentLoader>
    </div>
  )
}

export default SidebarTitleLoading
