import { EntityType, ElementType, EditMode } from '@phase-software/types'
import { NO_COMMIT, FlagsEnum } from '@phase-software/data-utils'
import { Vector2, Rect2 } from '../../math'
import { setSelectionArea, clearSelectionArea } from '../../panes'
import { searchVertices } from '../../visual_server/HitTest'
import { searchNodesBySelectAreaBox } from '.'

/** @typedef {import('../../visual_server/VisualServer').VisualServer} VisualServer */
/** @typedef {import('@phase-software/data-utils/src/mesh/Mesh').Mesh} Mesh */

export default class SelectionArea {
    /**
     * @param {VisualServer} visualServer
     */
    constructor (visualServer) {
        this.visualServer = visualServer
        this.dataStore = visualServer.dataStore
        this.viewport = visualServer.viewport
        this._zoom = false
        this._fullyInside = false
        this._remove = false
        this.insideContainer = false

        /** in world space */
        this._startPos = new Vector2()
        /** in screen space */
        this._endPos = new Vector2()
        /** in world space */
        this._area = new Rect2()

        this.start = this.start.bind(this)
        this.update = this.update.bind(this)
        this.end = this.end.bind(this)
        this.selectNodesInSelectionArea = this.selectNodesInSelectionArea.bind(this)
        this.updateSelectionWithViewportChange = this._updateSelectionWithViewportChange.bind(this)
    }

    /**
     * Viewport change handler
     * @private
     */
    _updateSelectionWithViewportChange() {
        const items = this._updateSelectionArea()
        if (this.dataStore.get('editMode') === EditMode.ELEMENT) {
            this.selectNodesInSelectionArea(items)
        } else if (this.dataStore.get('editMode') === EditMode.SHAPE){
            this.selectVerticesInSelectionArea(items)
        }
    }

    /**
     * Recursively search children and add found children to selection
     * @param {Set<Element>} elements
     */
    selectNodesInSelectionArea(elements) {
        this.dataStore.selection.addElements([...elements], NO_COMMIT)
        this._removeElementsOutOfSelectionArea(elements)
    }

    /**
     * Select vertices in selection area
     * @param {Set} vertices
     */
    selectVerticesInSelectionArea(vertices) {
        if (this._remove) {
            this.dataStore.selection.selectVertices([...vertices], NO_COMMIT)
        } else {
            this.dataStore.selection.addVertices([...vertices], NO_COMMIT)
        }
    }

    /**
     * Start area selection
     * @param {ISEvent} e
     */
    start(e) {
        this._zoom = false
        this._startPos.copy(this.viewport.toWorld(e.mousePos))
        this._endPos.copy(e.mousePos)
        this.viewport.on('update', this.updateSelectionWithViewportChange)
    }

    /**
     * Update area selection
     * @param {ISEvent} e
     * @param {object} keys
     * @param {bool} keys.shift
     * @param {bool} keys.alt
     * @param {bool} [zoom=false]
     * @param {bool} [hideArea=false]
     * @returns {Set<Element>}
     */
    update(e, keys, zoom = false, hideArea = false) {
        this._updateConfigsWithModifiers(keys, zoom)
        this._endPos.copy(e.mousePos)
        return this._updateSelectionArea(hideArea)
    }

    /**
     * Stop area selection
     * @param {bool} [commit=true]
     */
    end(commit = true) {
        this.viewport.off('update', this.updateSelectionWithViewportChange)
        clearSelectionArea()

        if (this._zoom) {
            if (!this._area.has_no_area()) {
                this.viewport.focus(this._area)
            }
        } else if (commit) {
            this.dataStore.get('undo').commit()
        }
    }

    /**
     * Update configs with modifiers
     * @private
     * @param {object} keys
     * @param {bool} [keys.shift=false]
     * @param {bool} [keys.alt=false]
     * @param {bool} zoom
     */
    _updateConfigsWithModifiers({ shift = false, alt = false } = { sfift: false, alt: false }, zoom) {
        this._zoom = zoom

        if (shift && alt) {
            this._fullyInside = true
            this._remove = false
        } else if (!shift && alt) {
            this._fullyInside = true
            this._remove = true
        } else if (shift && !alt) {
            this._fullyInside = false
            this._remove = true
        } else {
            this._fullyInside = false
            this._remove = true
        }
    }

    /**
     * Update hittest elements in selection area
     * @private
     * @param {bool} hideArea
     * @returns {Set<Element>}
     */
    _updateSelectionArea(hideArea) {
        this._area.set(this._startPos.x, this._startPos.y, 0, 0)
        this._area.expand_to(this.viewport.toWorld(this._endPos))
        setSelectionArea(hideArea ? null : this._area, !this._zoom)

        const editMode = this.dataStore.get('editMode')
        if (editMode === EditMode.ELEMENT) {
            return searchNodesBySelectAreaBox(this._area)
        } else if (editMode === EditMode.SHAPE) {
            const vertices = new Set()
            const item = this.visualServer.getRenderItemOfElement(this.visualServer.snappingPath.element)
            const hittestVertices = searchVertices(this.visualServer.snappingPath.mesh, item, this.viewport, this._area, this._fullyInside)
            for (const vert of hittestVertices) {
                if (vert.isFlagged(FlagsEnum.CURVE_VERT)) continue
                vertices.add(vert)
            }
            return vertices
        }
    }

    _canBeSelect(item, parent, screens, elements) {
        const inScreen = parent.get('type') === EntityType.WORKSPACE ||
            (parent.get('elementType') === ElementType.SCREEN && screens.has(parent) && !elements.has(parent))
        const inContainer = parent.get('elementType') === ElementType.CONTAINER && !elements.has(parent)
        const inParent = this.insideContainer ? inScreen || inContainer : inScreen
        const inArea = !this._fullyInside || (this._fullyInside && item.isInside(this._area))
        return inParent && inArea
    }

    /**
     * Remove elements which is out side of selection area
     * @private
     * @param {Element} elements
     */
    _removeElementsOutOfSelectionArea(elements) {
        if (this._remove) {
            if (elements.size === 0) {
                this.dataStore.selection.clear(NO_COMMIT)
            } else {
                const selected = this.dataStore.selection.get('elements')
                const shouldRemove = selected.filter(element => !elements.has(element))
                this.dataStore.selection.removeElements(shouldRemove, NO_COMMIT)
            }
        }
    }
}
