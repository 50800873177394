import React from 'react'

type TextProps = {
  size?: number
  ellipsis?: boolean
  dangerouslySetInnerHTML?: {
    __html: string
  }
  className?: string
  onClick?: (e: React.MouseEvent) => void
  onMouseEnter?: (e: React.MouseEvent) => void
  onMouseLeave?: (e: React.MouseEvent) => void
  children?: React.ReactNode
}

const Text = ({
  children,
  className = '',
  dangerouslySetInnerHTML,
  ellipsis = true,
  onClick,
  onMouseEnter,
  onMouseLeave
}: TextProps) => {
  const ellipsisClass = ellipsis ? 'overflow-hidden whitespace-nowrap overflow-ellipsis' : ''
  return (
    <div
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      dangerouslySetInnerHTML={dangerouslySetInnerHTML}
      className={`text-12 ${ellipsisClass} ${className}`}
    >
      {children}
    </div>
  )
}

export default Text
