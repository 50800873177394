import { FrameType } from '@phase-software/types'
import Interval from '../helpers/Interval'

/** @typedef {import('@phase-software/types').KeyFrame} KeyFrame */


const DIRECTION = {
  LEFT: 'LEFT',
  RIGHT: 'RIGHT'
}

const INITIAL_WORKING_INDEX = -1

class Action {
  constructor(parent, data, startTime) {
    this.parent = parent
    this.manager = parent.manager
    // FIXME: Might not need dataStore
    this.dataStore = parent.dataStore
    this.startTime = startTime || 0

    this.load(data)
  }

  load(data = {}) {
    this.type = 'ACTION'
    // TODO:  Need to pass action id to ActionStack in the future
    // Now we only have one ActionStack per PropertyStack
    this.id = data.id
    this._workingIdx = INITIAL_WORKING_INDEX
    this._interval = new Interval()
    this._endInterval = new Interval()
    this.defaultKF = null
    this.maxTime = Number.MIN_SAFE_INTEGER
    const keyFrameList = data.keyFrameList || []

    this._addKfsToMap(keyFrameList)

    this._updateMaxTime()
  }

  init() {
    this.defaultKF = this._createDefaultKF()
  }

  /**
   * Add keyframe list to cache map and kfs cache
   * @param {string[]} kfList
   */
  _addKfsToMap(kfList) {
    this.kfs = kfList.map((kfId) => {
      const kf = this.dataStore.interaction.getKeyFrame(kfId)
      if (!kf) {
        return null
      }

      this.manager.cache.addToMap(kf)
      return kf
    }).filter(Boolean).sort((a, b) => a.time - b.time)
  }

  /**
   * Sort keyframe list to cache map and kfs cache
   */
  _sortKf() {
    this.kfs = this.kfs.map((kf) => {
      const newKf = this.dataStore.interaction.getKeyFrame(kf.id)
      if (!newKf) {
        return null
      }

      this.manager.cache.addToMap(newKf)
      return newKf
    }).filter(Boolean).sort((a, b) => a.time - b.time)
  }

  /**
   * Create default KF
   * If, not having a KF at 0ms, then we put default KF to the first interval for animation
   * @returns {Partial<KeyFrame>}
   */
  _createDefaultKF() {
    const propData = this.parent.getInitValue()
    return {
      time: 0,
      value: propData
    }
  }

  /**
   * Recalculate default KF
   */
  recalculateDefaultKF() {
    this.defaultKF = this._createDefaultKF()
  }

  /**
   * Update order of KFs
   * @param {string[]} newKFList
   */
  updateKeyFrameOrder(newKFList) {
    this._addKfsToMap(newKFList)
    if (!this.kfs.length) {
      this._workingIdx = INITIAL_WORKING_INDEX
      this._interval.clear()
      this._endInterval.clear()
    }
    this.recalculateDefaultKF()
    this._updateMaxTime()
    this.updateWorkingInterval(this.manager.currentTime + this.manager.startTime)
  }

  /**
   * Get keyframe state by playhead time
   * @param {number} time
   * @returns {FrameType}
   */
  getKFState(time) {
    if (this._interval.isEmpty) {
      return 'DEFAULT'
    }

    const workingTime = this.getWorkingTime(time)
    const t = Math.round(workingTime / 10) * 10
    if (t === this._interval[0].time && this._interval[0].frameType !== undefined) {
      return this._interval[0].frameType
    }
    if (t === this._interval[1].time && this._interval[1].frameType !== undefined) {
      return this._interval[1].frameType
    }
    return 'TWEEN'
  }

  /**
   * Add a keyframe
   * @param {object} kf
   */
  addKF(kf) {
    this.kfs.splice(this.kfs.length, 0, kf)
    this._addKfsToMap(this.kfs.map(kf => kf.id))
    this._updateMaxTime(kf.time)
    this.updateWorkingInterval(this.manager.currentTime + this.manager.startTime)
    this.manager.cache.addToMap(kf)
  }

  /**
   * Update max time for the ActionStack
   * @param {number | undefined} time   if time is undefined, takes the time of the
   *                                      last KF as max time
   */
  _updateMaxTime(time = undefined) {
    const lastIdx = this.kfs.filter(Boolean).length - 1
    if (time === undefined && this.kfs.length > 0) {
      this.maxTime = this.kfs[lastIdx].time
    } else if (time !== undefined) {
      this.maxTime = Math.max(this.maxTime, time)
    }
    this.parent.elementStack.updateMaxKFTime(this.maxTime)

    if (this.kfs.length) {
      this._endInterval.update(this.kfs[lastIdx], this.kfs[lastIdx])
    } else {
      this._endInterval.clear()
    }
  }

  /**
   * Update keyframe data
   * @param {string} kfId
   * @param {object} data
   */
  updateKF(kfId, data) {
    const kf = this.kfs.find((kf) => kf.id === kfId)
    if (kf) {
      // We save the ref KF in the action, so we only need to check max time.
      // Should not update KF data here.
      if (data.time !== undefined) {
        this._updateMaxTime(data.time)
      }
      this._sortKf()
      this.recalculateDefaultKF()
      this.updateWorkingInterval(this.manager.currentTime + this.manager.startTime)
    }
  }

  /**
   * Delete keyframe
   * @param {string} kfId
   */
  deleteKF(kfId) {
    const idx = this.kfs.findIndex((kf) => kf.id === kfId)
    if (idx < 0) {
      return
    }
    this.kfs.splice(idx, 1)
    this.updateWorkingInterval(this.manager.currentTime + this.manager.startTime)
    this.manager.cache.deleteFromMap(kfId)
    this._updateMaxTime()
  }

  /**
   * Get value from base
   * @returns {number}
   */
  _getBaseValue() {
    return this.parent.getBaseValue()
  }

  /**
   * Get initial value from base
   * @returns {number}
   */
  _getInitValue() {
    return this.parent.getInitValue()
  }

  /**
   * Get first non-init keyframe
   * @returns {object}
   */
  getFirstNonInitKF() {
    const kf = this.kfs.find((kf) => kf.frameType !== FrameType.INITIAL)
    return kf
  }

  /**
   * Get first non-init keyframe value
   * @returns {null|number}
   */
  _getFirstNonInitKFValue() {
    const kf = this.getFirstNonInitKF()
    return kf ? kf.value : null
  }

  /**
   * Get keyframe value
   * @param {object} kf
   * @returns {*}
   */
  getKFValue(kf) {
    if (kf.frameType !== FrameType.INITIAL) {
      return kf.value
    }

    if (this.parent.key === 'x' || this.parent.key === 'y') {
      return 0
    }

    if (!this.parent.isRepeatableType || this.parent.isBaseLayer()) {
      const initValue = this._getInitValue()
      return initValue
    }

    const firstNonInitKFValue = this._getFirstNonInitKFValue()
    return firstNonInitKFValue || kf.value
  }

  /**
   * Get working time for animation
   * @param {number} time
   * @returns {number}
   */
  getWorkingTime(time) {
    return time - this.startTime
  }

  /**
   * Get working interval by time
   * @param {number} time
   * @returns {Interval | undefined}  interval (READONLY) or undefined if there is no keyframes.
   *                                  DO NOT modify this returned value
   */
  getWorkingInterval(time) {
    if (!this.kfs.length) {
      return
    }

    // If is still in the current interval, then return the current interval
    const interval = this._interval
    if (
      !interval.isEmpty &&
      time > interval.start.time &&
      time <= interval.end.time
    ) {
      return interval
    }

    if (!this.updateWorkingInterval(time)) {
      return
    }
    return this._interval
  }

  /**
   * Update working interval
   * @param {number} time
   * @param {bool} [forceUpdate=false]
   * @returns {bool}      true if successful; false if there's no keyframes
   */
  updateWorkingInterval(time, forceUpdate = false) {
    if (!this.kfs.length) {
      return false
    }
    const workingTime = this.getWorkingTime(time)
    const snappingWorkingTime = Math.ceil(workingTime / 10) * 10

    let workingIdx
    if (forceUpdate) {
      this._workingIdx = INITIAL_WORKING_INDEX
      workingIdx = this._findAndUpdateInterval(snappingWorkingTime)
    } else {
      if (this._interval.start && this._interval.start.time > snappingWorkingTime) {
        // Left side of the current working interval
        workingIdx = this._findAndUpdateInterval(snappingWorkingTime, DIRECTION.LEFT)
      } else if (this._interval.end && this._interval.end.time < snappingWorkingTime) {
        // Right side of the current working interval
        workingIdx = this._findAndUpdateInterval(snappingWorkingTime, DIRECTION.RIGHT)
      } else {
        workingIdx = this._findAndUpdateInterval(snappingWorkingTime)
      }
    }

    this._workingIdx = workingIdx
    return true
  }

  /**
   * Find interval by time in specific range
   * @param {number} workingTime
   * @param {DIRECTION} direction
   * @returns {number} working index
   */
  _findAndUpdateInterval(workingTime, direction) {
    const allKfs = [...this.kfs]
    if (this.kfs[0] && this.kfs[0].time > 0) {
      allKfs.unshift(this.defaultKF)
    }

    const lastIndex = allKfs.length - 1
    // Only need to get new working interval from left or right side of the current working interval.
    // Don't traverse the whole keyframe list.
    let startIndex = 0
    let endIndex = lastIndex
    if (direction === DIRECTION.LEFT) {
      startIndex = 0
      endIndex = this._workingIdx
    } else if (direction === DIRECTION.RIGHT) {
      startIndex = Math.max(0, this._workingIdx)
      endIndex = lastIndex
    }

    let workingIdx = INITIAL_WORKING_INDEX
    for (let i = startIndex; i < endIndex; i++) {
      const cur = allKfs[i]
      const next = allKfs[i + 1]
      if (cur.time < workingTime && next.time >= workingTime) {
        workingIdx = i
      }
    }
    
    const lastKF = allKfs[lastIndex]
    if (workingIdx === INITIAL_WORKING_INDEX && workingTime === 0) {
      // use the same KF if only have one KF at T0
      this._interval.update(allKfs[0], allKfs[1] || allKfs[0])
    } else if (lastKF && workingTime >= lastKF.time) {
      this._interval.update(allKfs[lastIndex - 1] || lastKF, lastKF)
      workingIdx = lastIndex
    } else if (workingIdx > INITIAL_WORKING_INDEX) {
      this._interval.update(allKfs[workingIdx], allKfs[workingIdx + 1])
    }

    return workingIdx
  }

  /**
   * Reset start time
   * @param {number} time
   */
  resetStartTime(time) {
    this.startTime = time
    this._workingIdx = INITIAL_WORKING_INDEX
    this._interval.clear()
  }

  /**
   * Check if the action stack is finished
   * @param {number} time
   * @returns {bool}
   */
  isActionFinished(time) {
    return time > (this.startTime + this.maxTime)
  }

  clear() {
    this.kfs.forEach((kf) => {
      this.manager.cache.deleteFromMap(kf.id)
    })
    this.kfs = []
    this._workingIdx = INITIAL_WORKING_INDEX
    this._interval = new Interval()
    this._endInterval = new Interval()
    this.maxTime = Number.MIN_SAFE_INTEGER
  }
}

export default Action
