import { PointShape } from '@phase-software/types'
/** @todo It's critical that we align the calculations in this package with the renderer's transform calculations. Please, let's make this happen! */
import { vec2, vec3, mat2d } from 'gl-matrix'
import { Bezier } from 'bezier-js/src/bezier'
import { Vector2 } from './Vector2'
import { Vector4 } from './Vector4'
import { AABB } from './AABB'
import { EPSILON, notNull, lerp } from './commons'
import { Mesh } from './mesh/Mesh'
import { Matrix2D } from './Matrix2D'


/**
 * Calculate points for a bezier curve with a starting point and two control points
 * from https://stackoverflow.com/a/15399173/1955997
 * @param  {Vector2} p1          [description]
 * @param  {Vector2} cp1         [description]
 * @param  {Vector2} cp2         [description]
 * @param  {Vector2} p2          [description]
 * @param  {number} pointsInArc  [description]
 * @param  {boolean} includeStartEnd  true if p1 and p2 should be included in the returned list; false otherwise
 * @returns {Vector2[]}           list of points in a curve
 */
export function bezierCurve(p1, cp1, cp2, p2, pointsInArc = 5, includeStartEnd = false) {
    const points = []
    const interval = 1 / pointsInArc
    const start = includeStartEnd ? 0 : interval
    const end = includeStartEnd ? 1 : 1 - interval

    for (let t = start; t <= end; t += interval) {
        const B0_t = Math.pow(1 - t, 3),
            B1_t = 3 * t * Math.pow(1 - t, 2),
            B2_t = 3 * Math.pow(t, 2) * (1 - t),
            B3_t = Math.pow(t, 3)

        points.push(new Vector2(
            (B0_t * p1[0]) + (B1_t * cp1[0]) + (B2_t * cp2[0]) + (B3_t * p2[0]),
            (B0_t * p1[1]) + (B1_t * cp1[1]) + (B2_t * cp2[1]) + (B3_t * p2[1])
        ))
    }

    return points
}

/**
 * @param {object} beziers
 * @param {boolean} beziers.c
 * @param {number[][]} beziers.v
 * @param {number[][]} beziers.i
 * @param {number[][]} beziers.o
 * @param {AABB} aabb
 * @returns {AABB} aabb
 */
export function getLottieStyleBeziersBounds(beziers, aabb = new AABB()) {
    const { c, i, v, o } = beziers
    for (let index = 1; index < v.length; index++) {
        const prevVertex = new Vector2(v[index - 1][0], v[index - 1][1])
        const currVertex = new Vector2(v[index][0], v[index][1])
        bezierCurveBounds(prevVertex, new Vector2(prevVertex.x + o[index - 1][0], prevVertex.y + o[index - 1][1]), new Vector2(currVertex.x + i[index][0], currVertex.y + i[index][1]), new Vector2(v[index][0], v[index][1]), aabb)
    }
    if (c && v.length > 2) {
        const lastIndex = v.length - 1
        bezierCurveBounds(new Vector2(v[lastIndex][0], v[lastIndex][1]), new Vector2(v[lastIndex][0] + o[lastIndex][0], v[lastIndex][1] + o[lastIndex][1]), new Vector2(v[0][0] + i[0][0], v[0][1] + i[0][1]), new Vector2(v[0][0], v[0][1]), aabb)
    }
    return aabb
}

// From https://stackoverflow.com/a/14429749
// Original Source: http://blog.hackers-cafe.net/2009/06/how-to-calculate-bezier-curves-bounding.html
// Original version: NISHIO Hirokazu
// Modifications: Timo
/**
 * Calculates bounds of the bezier curve by finding local extrema
 *  and minmaxing them against aabb.
 * @param  {Vector2} p1     start point
 * @param  {Vector2} cp1    handle of the start point
 * @param  {Vector2} cp2    handle of the end point
 * @param  {Vector2} p2     end point
 * @param  {AABB} [aabb]    AABB to minmax against; if not provided will create new AABB
 * @returns {AABB} aabb
 */
export function bezierCurveBounds(p1, cp1, cp2, p2, aabb = new AABB()) {
    const tvalues = []
    let a, b, c, t, t1, t2, b2ac, sqrtb2ac
    for (let i = 0; i < 2; ++i) {
        if (i === 0) {
            b = 6 * p1[0] - 12 * cp1[0] + 6 * cp2[0]
            a = -3 * p1[0] + 9 * cp1[0] - 9 * cp2[0] + 3 * p2[0]
            c = 3 * cp1[0] - 3 * p1[0]
        } else {
            b = 6 * p1[1] - 12 * cp1[1] + 6 * cp2[1]
            a = -3 * p1[1] + 9 * cp1[1] - 9 * cp2[1] + 3 * p2[1]
            c = 3 * cp1[1] - 3 * p1[0]
        }

        if (Math.abs(a) < 1e-12) { // Numerical robustness
            if (Math.abs(b) < 1e-12) { // Numerical robustness
                continue
            }
            t = -c / b
            if (0 < t && t < 1) {
                tvalues.push(t)
            }
            continue
        }
        b2ac = b * b - 4 * c * a
        sqrtb2ac = Math.sqrt(b2ac)
        if (b2ac < 0) {
            continue
        }
        t1 = (-b + sqrtb2ac) / (2 * a)
        if (0 < t1 && t1 < 1) {
            tvalues.push(t1)
        }
        t2 = (-b - sqrtb2ac) / (2 * a)
        if (0 < t2 && t2 < 1) {
            tvalues.push(t2)
        }
    }

    let x, y, j = tvalues.length, mt
    while (j--) {
        t = tvalues[j]
        mt = 1 - t
        x = (mt * mt * mt * p1[0]) + (3 * mt * mt * t * cp1[0]) + (3 * mt * t * t * cp2[0]) + (t * t * t * p2[0])
        y = (mt * mt * mt * p1[1]) + (3 * mt * mt * t * cp1[1]) + (3 * mt * t * t * cp2[1]) + (t * t * t * p2[1])
        aabb.minMax(new Vector2(x, y))
    }

    aabb.minMax(p1)
    aabb.minMax(p2)
    return aabb
}

/**
 * Computed normal vector to the line formed by two points
 * @param {Vector2} from   edge/line start
 * @param {Vector2} to     edge/line end
 * @returns {Vector2}
 */
export function lineNormal(from, to) {
    // direction from 0 to 1
    const dir = vec2.sub(vec2.create(), to, from)
    return dirNormal(dir, true)
}

/**
 * Computes normal vector to direction, orthogonal to dir CCW (for screen-like axis alignment)
 * @param  {Vector2} dir         direction vector
 * @param {boolean} normalize   if true will normalize the normal vector; false by default for better performance
 * @returns {Vector2}
 */
export function dirNormal(dir, normalize = false) {
    const n = new Vector2(dir[1], -dir[0])
    if (normalize) {
        vec2.normalize(n, n)
    }
    return n
}

/**
 * Computes normal vector to direction, orthogonal to dir CW
 * @param  {Vector2} dir         direction vector
 * @param {boolean} normalize   if true will normalize the normal vector; false by default for better performance
 * @returns {Vector2}
 */
export function dirNormalCW(dir, normalize = false) {
    const n = new Vector2(-dir[1], dir[0])
    if (normalize) {
        vec2.normalize(n, n)
    }
    return n
}

/**
 * Test if point `p` is on the segment `a``b` (excluding the segments's endpoints)
 * @param {Vector2} a       start point of the segement
 * @param {Vector2} b       end point of the segment
 * @param {Vector2} p       position of the point to check against
 * @returns {boolean}       true if p is on the segment
 */
export function isPointOnSegment(a, b, p) {
    const collinear = isCollinear(p[0] - a[0], p[1] - a[1], p[0] - b[0], p[1] - b[1])
    if (!collinear) return false; // Not collinear

    // Ensure the point is within the bounding box of the line segment
    return (p[0] >= Math.min(a[0], b[0])) && (p[0] <= Math.max(a[0], b[0])) &&
        (p[1] >= Math.min(a[1], b[1])) && (p[1] <= Math.max(a[1], b[1]))
}

/**
 * Determines contour direction: CCW or CW
 * @param {Iterable<[number, number]>} contour   an iterable of contour vec2-like points (in sequence)
 * @returns {numer}  1 if direction is CCW; -1 if direction is CW
 */
export function contourDirection(contour) {
    let sum = 0
    let firstP = null
    let lastP = null
    for (const p of contour) {
        if (lastP) {
            sum += (p[0] - lastP[0]) * (p[1] + lastP[1])
        } else {
            firstP = p
        }
        lastP = p
    }
    sum += (firstP[0] - lastP[0]) * (firstP[1] + lastP[1])
    return Math.sign(sum)
}

/**
 * @param {number} centerX
 * @param {number} centerY
 * @param {[number, number]} size
 * @param {number | number[]} [cornerRadius]
 * @returns {Mesh}
 */
export function getRectangleMesh(centerX, centerY, [W, H], cornerRadius = 0) {
    const mesh = new Mesh()

    vec2Buffer.x = W
    vec2Buffer.y = 0
    const tR = mesh.addVertex(vec2Buffer)
    if (notNull(cornerRadius[0])) tR.set({ cornerRadius: cornerRadius[0] })
    
    vec2Buffer.x = W
    vec2Buffer.y = H
    const bR = mesh.addVertex(vec2Buffer)
    if (notNull(cornerRadius[1])) bR.set({ cornerRadius: cornerRadius[1] })

    vec2Buffer.x = 0
    vec2Buffer.y = H
    const bL = mesh.addVertex(vec2Buffer)
    if (notNull(cornerRadius[2])) bL.set({ cornerRadius: cornerRadius[2] })

    vec2Buffer.x = 0
    vec2Buffer.y = 0
    const tL = mesh.addVertex(vec2Buffer)
    if (notNull(cornerRadius[3])) tL.set({ cornerRadius: cornerRadius[3] })

    for (const vertex of mesh.vertices){
        vertex.pos[0] += (centerX - 0.5 * W)
        vertex.pos[1] += (centerY - 0.5 * H)
    }
    mesh.addEdge(tR, bR)
    mesh.addEdge(bR, bL)
    mesh.addEdge(bL, tL)
    mesh.addEdge(tL, tR)

    mesh.detectContours()
    for (const contour of mesh.getContours()) {
        if (contour.isClosed) {
            contour.isFilled = true
        }
    }

    return Mesh.fromData(mesh.save())
}

const k = 4 * (Math.SQRT2 - 1) / 3
/**
 * @param {number} centerX
 * @param {number} centerY
 * @param {number[]} size
 * @returns {Mesh}
 */
export function getEllipseMesh(centerX, centerY, [W, H]) {
    const mesh = new Mesh()
    const w2 = W * 0.5
    const h2 = H * 0.5

    const wk = w2 * k
    const hk = h2 * k

    const x0 = w2 - wk
    const x1 = w2 + wk

    const y0 = h2 - hk
    const y1 = h2 + hk

    vec2Buffer.x = w2
    vec2Buffer.y = 0
    const v0 = mesh.addVertex(vec2Buffer, { data: { mirror: PointShape.ANGLE_AND_LENGTH } })
    vec2Buffer.x = W
    vec2Buffer.y = h2
    const v1 = mesh.addVertex(vec2Buffer, { data: { mirror: PointShape.ANGLE_AND_LENGTH } })
    vec2Buffer.x = w2
    vec2Buffer.y = H
    const v2 = mesh.addVertex(vec2Buffer, { data: { mirror: PointShape.ANGLE_AND_LENGTH } })
    vec2Buffer.x = 0
    vec2Buffer.y = h2
    const v3 = mesh.addVertex(vec2Buffer, { data: { mirror: PointShape.ANGLE_AND_LENGTH } })

    curveBuffer[0].x = x1
    curveBuffer[0].y = 0
    curveBuffer[1].x = W
    curveBuffer[1].y = y0
    mesh.addEdge(v0, v1, { curve: curveBuffer })

    curveBuffer[0].x = W
    curveBuffer[0].y = y1
    curveBuffer[1].x = x1
    curveBuffer[1].y = H
    mesh.addEdge(v1, v2, { curve: curveBuffer })

    curveBuffer[0].x = x0
    curveBuffer[0].y = H
    curveBuffer[1].x = 0
    curveBuffer[1].y = y1
    mesh.addEdge(v2, v3, { curve: curveBuffer })

    curveBuffer[0].x = 0
    curveBuffer[0].y = y0
    curveBuffer[1].x = x0
    curveBuffer[1].y = 0
    mesh.addEdge(v3, v0, { curve: curveBuffer })

    mesh.detectContours()
    for (const contour of mesh.getContours()) {
        if (contour.isClosed) {
            contour.isFilled = true
        }
    }

    for (const vertex of mesh.vertices){
        vertex.pos[0] += (centerX - 0.5 * W)
        vertex.pos[1] += (centerY - 0.5 * H)
    }

    return Mesh.fromData(mesh.save())
}

const vec2Buffer = new Vector2()
const curveBuffer = [new Vector2(), new Vector2()]
const bezierUtils = Bezier.getUtils();


/**
 * @typedef ControlPoints
 * @property {Vector2} e1
 * @property {Vector2} e2
 * @property {Vector2} C1
 * @property {Vector2} C2
 */
/**
 *
 * @param {Vector2} S
 * @param {Vector2} B
 * @param {Vector2} E
 * @returns {ControlPoints}
 */
export function findControlPoints(S, B, E) {
    const d1 = bezierUtils.dist(S, B);
    const d2 = bezierUtils.dist(E, B);
    const t = d1 / (d1 + d2);

    const { A, C } = Bezier.getABC(3, S, B, E, t);
    return _findControlPoints(t, A, B, C, S, E)
}

/**
 *
 * [Ref.]{@link https://pomax.github.io/bezierinfo/chapters/pointcurves/cubic.js}
 * @param {number} t
 * @param {Vector2} A
 * @param {Vector2} B
 * @param {Vector2} C
 * @param {Vector2} S
 * @param {Vector2} E
 * @returns {ControlPoints}
}
 */
function _findControlPoints(t, A, B, C, S, E) {
    // get our e1-e2 distances
    const angle = Math.atan2(E.y - S.y, E.x - S.x) - Math.atan2(B.y - S.y, B.x - S.x),
        bc = (angle < 0 || angle > Math.PI ? -1 : 1) * bezierUtils.dist(S, E) / 3,
        de1 = t * bc,
        de2 = (1 - t) * bc;

    
    if (Math.abs(angle) < EPSILON) {
        const e1 = { x: B.x + 0.5 * (S.x - B.x), y: B.y + 0.5 * (S.y - B.y) };
        const e2 = { x: B.x + 0.5 * (E.x - B.x), y: B.y + 0.5 * (E.y - B.y) };
        return { e1, e2 }
    }
    // get the circle-aligned slope as normalized dx/dy
    const c = bezierUtils.getccenter(S, B, E),
        tangent = [
            { x: B.x - (B.y - c.y), y: B.y + (B.x - c.x) },
            { x: B.x + (B.y - c.y), y: B.y - (B.x - c.x) }
        ],
        tlength = bezierUtils.dist(tangent[0], tangent[1]),
        dx = (tangent[1].x - tangent[0].x) / tlength,
        dy = (tangent[1].y - tangent[0].y) / tlength;

    // then set up an e1 and e2 parallel to the baseline
    const e1 = { x: B.x + de1 * dx, y: B.y + de1 * dy };
    const e2 = { x: B.x - de2 * dx, y: B.y - de2 * dy };

    // then use those e1/e2 to derive the new hull coordinates
    const v1 = {
        x: A.x + (e1.x - A.x) / (1 - t),
        y: A.y + (e1.y - A.y) / (1 - t)
    };

    const v2 = {
        x: A.x + (e2.x - A.x) / t,
        y: A.y + (e2.y - A.y) / t
    };

    const C1 = {
        x: S.x + (v1.x - S.x) / t,
        y: S.y + (v1.y - S.y) / t
    };

    const C2 = {
        x: E.x + (v2.x - E.x) / (1 - t),
        y: E.y + (v2.y - E.y) / (1 - t),
    };

    return { e1, e2, C1, C2 };
}


/**
 * @param {EntityChange} changes
 * @returns {object}
 */
export function parseSceneTreeChanges(changes) {
    let parentId, elId
    const oldParents = new Map()
    const newParents = new Map()
    const removed = new Set()
    const added = new Set()
    const moved = new Set()
    const reordered = new Set()

    for (parentId of changes.UPDATE.keys()) {
        const { before, after } = changes.UPDATE.get(parentId).get('children')
        for (elId of before) {
            oldParents.set(elId, parentId)
            removed.add(elId)
        }
        for (elId of after) {
            if (removed.has(elId)) {
                removed.delete(elId)
                if (oldParents.get(elId) !== parentId) {
                    moved.add(elId)
                    newParents.set(elId, parentId)
                } else {
                    reordered.add(elId)
                    newParents.set(elId, parentId)
                }
            } else {
                added.add(elId)
                newParents.set(elId, parentId)
            }
        }
    }

    added.forEach(elId => {
        if (removed.has(elId)) {
            removed.delete(elId)
            added.delete(elId)
            moved.add(elId)
        }
    })

    return { oldParents, newParents, removed, added, moved, reordered }
}


/**
 * Get interpolated gradient color by position
 * @param {Vector4[]} gradientStops
 * @param {number} activeIdx
 * @param {number} position - 0~1
 * @returns {Vector4}
 */
export const getGradientColorByPosition = (gradientStops, activeIdx, position) => {
    let leftStopIdx = -1
    let rightStopIdx = -1

    for (let i = 0; i < gradientStops.length; i++) {
        const stop = gradientStops[i]
        if (stop.position <= position && (leftStopIdx === -1 || gradientStops[leftStopIdx].position < stop.position)) {
            leftStopIdx = i
        }
        if (stop.position >= position && (rightStopIdx === -1 || gradientStops[rightStopIdx].position > stop.position)) {
            rightStopIdx = i
        }
    }

    let color = gradientStops[activeIdx].color
    if (leftStopIdx === -1) {
        color = gradientStops[rightStopIdx].color
    } else if (rightStopIdx === -1) {
        color = gradientStops[leftStopIdx].color
    } else {
        const leftStop = gradientStops[leftStopIdx]
        const rightStop = gradientStops[rightStopIdx]
        const ratio = (position - leftStop.position) / (rightStop.position - leftStop.position)
        const r = lerp(leftStop.color.r, rightStop.color.r, ratio)
        const g = lerp(leftStop.color.g, rightStop.color.g, ratio)
        const b = lerp(leftStop.color.b, rightStop.color.b, ratio)
        const a = lerp(leftStop.color.a, rightStop.color.a, ratio)
        color = new Vector4(r, g, b, a)
    }

    return color
}

export const parseGradientTransform = (start, end, size, isLinear) => {
    if (size[0] === 0 || size[1] === 0){
        console.warn('parseGradientTransform get zero size, please check if the element is a line')
    }
    
    return isLinear ? _parseGradientTransformLinear(start, end, size) : _parseGradientTransformRadial(start, end, size)
}

/**
 * 
 * @param {number[]} start 
 * @param {number[]} end 
 * @param {number[]} size 
 * @returns {Matrix2D}
 */
function _parseGradientTransformRadial(start, end, size) {
    const sx = start[0]
    const sy = start[1]
    const ex = end[0]
    const ey = end[1]
    const width = size[0] > 0 ? size[0] : 1
    const height = size[1] > 0 ? size[1] : 1
    const origin = vec2.fromValues(0.5, 0.5)
    const normalizedStart = vec2.fromValues(sx / width, sy / height)

    const totalTransVec = vec2.fromValues(origin[0] - normalizedStart[0], origin[1] - normalizedStart[1])
    const totalTransMat = mat2d.create()
    mat2d.fromTranslation(totalTransMat, totalTransVec)

    const minusPivotMat = mat2d.create()
    mat2d.translate(minusPivotMat, minusPivotMat, vec2.fromValues(-normalizedStart[0], -normalizedStart[1]))
    const pivotMat = mat2d.create()
    mat2d.translate(pivotMat, pivotMat, vec2.fromValues(normalizedStart[0], normalizedStart[1]))
    const scaleRotateMat = mat2d.create()
    const normalizedDir = vec2.fromValues((ex - sx) / width, (ey - sy) / height)
    const vecOriginal = vec2.fromValues(0.5 - origin[0], 1 - origin[1])

    const scale = vec2.length(vecOriginal) / vec2.length(normalizedDir)
    mat2d.scale(scaleRotateMat, scaleRotateMat, vec2.fromValues(scale, scale * (height / width)))
    const t = mat2d.create()
    mat2d.multiply(t, scaleRotateMat, minusPivotMat)
    mat2d.multiply(t, pivotMat, t)
    mat2d.multiply(t, totalTransMat, t)

    const g = mat2d.fromValues(0, -1, 1, 0, 0, 1)
    mat2d.multiply(t, g, t)

    return new Matrix2D(t)
}

/**
 * Note: This function may not work as intended due to the unique characteristics of our gradient implementation.
 * Ours seemingly involves a dual-step rotation process where the start point rotates around the end point, 
 * followed by the end point rotating around the new position of the start point.
 * It's advisable to review and align the transformation logic accordingly.
 * 
 * @param {number[]} start 
 * @param {number[]} end 
 * @param {number[]} size 
 * @returns {Matrix2D}
 */
function _parseGradientTransformLinear(start, end, size) {
    const sx = start[0]
    const sy = start[1]
    const ex = end[0]
    const ey = end[1]
    const width = size[0] > 0 ? size[0] : 1
    const height = size[1] > 0 ? size[1] : 1

    // const origin = isLinear ? vec2.fromValues(0.5, 0) : vec2.fromValues(0.5, 0.5)
    const origin = vec2.fromValues(0.5, 0)
    const normalizedStart = vec2.fromValues(sx / width, sy / height)

    const totalTransVec = vec2.fromValues(origin[0] - normalizedStart[0], origin[1] - normalizedStart[1])
    const totalTransMat = mat2d.create()
    mat2d.fromTranslation(totalTransMat, totalTransVec)

    const minusPivotMat = mat2d.create()
    mat2d.translate(minusPivotMat, minusPivotMat, vec2.fromValues(-normalizedStart[0], -normalizedStart[1]))
    const pivotMat = mat2d.create()
    mat2d.translate(pivotMat, pivotMat, vec2.fromValues(normalizedStart[0], normalizedStart[1]))
    const scaleRotateMat = mat2d.create()
    const normalizedDir = vec2.fromValues((ex - sx) / width, (ey - sy) / height)
    const vecOriginal = vec2.fromValues(0.5 - origin[0], 1 - origin[1])
    const rotate = Math.atan2(vec2.cross(vec3.create(), normalizedDir, vecOriginal)[2], vec2.dot(normalizedDir, vecOriginal))
    const scale = vec2.length(vecOriginal) / vec2.length(normalizedDir)
    mat2d.scale(scaleRotateMat, scaleRotateMat, vec2.fromValues(scale, scale))
    mat2d.rotate(scaleRotateMat, scaleRotateMat, rotate)
    const tmp = scaleRotateMat[1]
    scaleRotateMat[1] = -scaleRotateMat[2]
    scaleRotateMat[2] = -tmp
    const t = mat2d.create()
    mat2d.multiply(t, scaleRotateMat, minusPivotMat)
    mat2d.multiply(t, pivotMat, t)
    mat2d.multiply(t, totalTransMat, t)

    const g = mat2d.fromValues(0, -1, 1, 0, 0, 1)
    mat2d.multiply(t, g, t)

    return new Matrix2D(t)
}

/**
 * NOTE: We use normalized vectors so that the epsilon comparison is
 * reliable. We could instead scale the epsilon based on the vector
 * length. But instead of normalizing the vectors before calculating
 * the cross product, we can scale the epsilon accordingly.
 * @param {number} x1
 * @param {number} y1
 * @param {number} x2
 * @param {number} y2
 * @returns {boolean}
 */
export const isCollinear = (x1, y1, x2, y2) => (
    Math.abs(x1 * y2 - y1 * x2)
    <=
    Math.sqrt((x1 * x1 + y1 * y1) * (x2 * x2 + y2 * y2)) * EPSILON
)


/**
 * @param {Mesh} mesh
 * @param {Edge} edge
 * @returns {boolean}
 */
export function isCollinearSegemet(mesh, edge) {
    if (edge.isStraight) return true
    if (!isPointOnSegment(edge.v.pos, edge.w.pos, edge.cpV.pos)) return false
    if (!isPointOnSegment(edge.v.pos, edge.w.pos, edge.cpW.pos)) return false
    // eslint-disable-next-line eqeqeq
    if (edge.v.unlinkedCurveControl != null && mesh.cellTable.has(edge.v.unlinkedCurveControl)) {
        const p = mesh.cellTable.get(edge.v.unlinkedCurveControl).pos

        if (!isCollinear(edge.v.pos[0] - p[0], edge.v.pos[1] - p[1], edge.w.pos[0] - p[0], edge.w.pos[1] - p[1])) return false
    }
    // eslint-disable-next-line eqeqeq
    if (edge.w.unlinkedCurveControl != null && mesh.cellTable.has(edge.w.unlinkedCurveControl)) {
        const p = mesh.cellTable.get(edge.w.unlinkedCurveControl).pos
        // eslint-disable-next-line eqeqeq
        if (!isCollinear(edge.v.pos[0] - p[0], edge.v.pos[1] - p[1], edge.w.pos[0] - p[0], edge.w.pos[1] - p[1])) return false
    }
    return true
}

/**
 * check two number are equal with floating point tolerance
 * @param {number} num1
 * @param {number} num2
 * @param {number} tolerance
 * @returns {boolean}
 */
export function areNumbersEqual(num1, num2, tolerance = 0.001) {
    return Math.abs(num1 - num2) < tolerance + Number.EPSILON;
}
