import React from 'react'
import { useTranslation } from 'react-i18next'

import { toDeg } from '@phase-software/data-utils'
import { BooleanOperation, ContainerElementType, EditMode, ElementType } from '@phase-software/types'

import { useEditor } from '../../../providers/dataStore/EditorProvider'
import { useUI } from '../../../providers/dataStore/UIProvider'
import { formatAsDegrees, formatNumberWithUnit } from '../../../utils/formatter'
import InspectContent from './InspectContent'
import InspectRow from './InspectRow'

const BasePropertyInspection = () => {
  const elementData = useEditor()
  const { editMode } = useUI()
  const { t } = useTranslation('file', { keyPrefix: 'inspect' })
  const booleanType = useEditor((o) => o.booleanType)
  const isMask = useEditor((o) => o.isMask)
  const elementType = useEditor((o) => o.elementType)
  const containerType = useEditor((o) => o.containerType)
  const isScreen = elementData.elementType === ElementType.SCREEN
  const isShapeMode = editMode === EditMode.SHAPE
  return (
    <InspectContent>
      {!isScreen && (
        <InspectRow
          label={t('position')}
          value={`${formatNumberWithUnit(elementData.x)}px, ${formatNumberWithUnit(elementData.y)}px`}
        />
      )}
      <InspectRow label={t('width')} value={`${formatNumberWithUnit(elementData.width)}px`} />
      <InspectRow label={t('height')} value={`${formatNumberWithUnit(elementData.height)}px`} />
      {!isScreen && <InspectRow label={t('rotation')} value={formatAsDegrees(toDeg(elementData.rotation))} />}
      {!isScreen &&
        !isShapeMode &&
        booleanType === BooleanOperation.NONE &&
        !isMask &&
        !(elementType === ElementType.CONTAINER && containerType === ContainerElementType.NORMAL_GROUP) && (
          <InspectRow label={t('corner_radius')} value={`${formatNumberWithUnit(elementData.cornerRadius)}px`} />
        )}
    </InspectContent>
  )
}

export default BasePropertyInspection
