import { STATES } from '../states'

/** @typedef {import('../index').ActionData} ActionData */


/** All mouse and keyboard input events */
/** @type {Record<string, ActionData>} */
export const INPUTS = {
    LEFT_CLICK_MOVE: {
        keyCombos: ['LeftClick', 'LeftClickMove'],
        state: STATES.WORKSPACE,
        fallback: true
    },
    SPACE: {
        keyCombos: ['Space'],
        state: STATES.WORKSPACE,
    },
    MIDDLE_CLICK_MOVE: {
        keyCombos: ['MiddleClickMove'],
        state: STATES.WORKSPACE
    },
    RIGHT_CLICK_MOVE: {
        keyCombos: ['RightClick', 'RightClickMove'],
        state: STATES.WORKSPACE
    },
    MOUSE_MOVE: {
        keyCombos: ['MouseMove'],
        state: STATES.WORKSPACE,
        fallback: true
    },
    DOUBLE_LEFT_CLICK: {
        keyCombos: ['DoubleLeftClick'],
        state: STATES.WORKSPACE
    },
    DRAG_OVER: {
        keyCombos: ['DragOver'],
        state: STATES.WORKSPACE,
        fallback: true
    },
    DRAG_END: {
        keyCombos: ['DragEnd'],
        state: STATES.WORKSPACE,
        fallback: true
    },
    DROP: {
        keyCombos: ['Drop'],
        state: STATES.WORKSPACE,
        fallback: true
    },
    SELECT_PREVIOUS_ELEMENT: {
        keyCombos: ['Tab'],
        state: STATES.WORKSPACE
    },
    SELECT_NEXT_ELEMENT: {
        keyCombos: ['Shift + Tab'],
        state: STATES.WORKSPACE
    },
    TEXT_MOVE_CARET_TO_LINE_START: {
        keyCombos: ['Home'],
        state: STATES.WORKSPACE
    },
    TEXT_MOVE_CARET_TO_TEXT_START: {
        keyCombos: ['Modifier + Home'],
        state: STATES.WORKSPACE
    },
    TEXT_EXPAND_SELECTION_TO_LINE_START: {
        keyCombos: ['Shift + Home'],
        state: STATES.WORKSPACE
    },
    TEXT_EXPAND_SELECTION_TO_TEXT_START: {
        keyCombos: ['Modifier + Shift + Home'],
        state: STATES.WORKSPACE
    },
    TEXT_MOVE_CARET_TO_LINE_END: {
        keyCombos: ['End'],
        state: STATES.WORKSPACE
    },
    TEXT_MOVE_CARET_TO_TEXT_END: {
        keyCombos: ['Modifier + End'],
        state: STATES.WORKSPACE
    },
    TEXT_EXPAND_SELECTION_TO_LINE_END: {
        keyCombos: ['Shift + End'],
        state: STATES.WORKSPACE
    },
    TEXT_EXPAND_SELECTION_TO_TEXT_END: {
        keyCombos: ['Modifier + Shift + End'],
        state: STATES.WORKSPACE
    },
    ENTER: {
        keyCombos: ['Enter'],
        state: STATES.WORKSPACE
    },
    ENTER_SHIFT: {
        keyCombos: ['Shift + Enter'],
        state: STATES.WORKSPACE
    },
    ESCAPE: {
        keyCombos: ['Escape'],
        state: STATES.WORKSPACE,
        fallback: true
    },
    DELETE: {
        keyCombos: ['Delete', 'Backspace'],
        state: STATES.WORKSPACE,
        fallback: true
    },
    MOVE_RIGHT: {
        keyCombos: ['ArrowRight'],
        state: STATES.WORKSPACE,
        fallback: true
    },
    MOVE_LEFT: {
        keyCombos: ['ArrowLeft'],
        state: STATES.WORKSPACE,
        fallback: true
    },
    MOVE_UP: {
        keyCombos: ['ArrowUp'],
        state: STATES.WORKSPACE,
        fallback: true
    },
    MOVE_DOWN: {
        keyCombos: ['ArrowDown'],
        state: STATES.WORKSPACE,
        fallback: true
    },
    UNDO: {
        keyCombos: ['Modifier + Z'],
        state: STATES.WORKSPACE
    },
    REDO: {
        keyCombos: ['Modifier + Shift + Z'],
        state: STATES.WORKSPACE
    },
    ZOOM_OUT: {
        keyCombos: ['Modifier + -', '-'],
        state: STATES.WORKSPACE
    },
    ZOOM_IN: {
        keyCombos: ['Modifier + =', '=', '+'],
        state: STATES.WORKSPACE
    },
    ZOOM_RESET: {
        keyCombos: ['Modifier + 0'],
        state: STATES.WORKSPACE
    },
    ZOOM_FIT_CONTENT: {
        keyCombos: ['Modifier + 1'],
        state: STATES.WORKSPACE
    },
    ZOOM_FIT_SELECTION: {
        keyCombos: ['Modifier + 2'],
        state: STATES.WORKSPACE
    },
    ZOOM_CENTER_SELECTION: {
        keyCombos: ['Modifier + 3'],
        state: STATES.WORKSPACE
    },
    COPY: {
        keyCombos: ['Copy'],
        state: STATES.WORKSPACE,
        fallback: true
    },
    PASTE: {
        keyCombos: ['Paste'],
        state: STATES.WORKSPACE,
        fallback: true
    },
    CUT: {
        keyCombos: ['Modifier + X', 'Shift + Modifier + X'],
        state: STATES.WORKSPACE
    },
    DUPLICATE: {
        keyCombos: ['Modifier + D', 'Shift + Modifier + D'],
        state: STATES.WORKSPACE
    },
    SELECT_ALL: {
        keyCombos: ['Modifier + A'],
        state: STATES.WORKSPACE
    },
    TOGGLE_ANIMATE_MODE:{
        keyCombos: ['A'],
        state: STATES.WORKSPACE
    },
    ACTIVATE_SELECT_TOOL: {
        keyCombos: ['V'],
        state: STATES.WORKSPACE
    },
    ACTIVATE_SCALE_TOOL: {
        keyCombos: ['K'],
        state: STATES.WORKSPACE
    },
    ACTIVATE_HAND_TOOL: {
        keyCombos: ['H'],
        state: STATES.WORKSPACE
    },
    ACTIVATE_RECTANGLE_TOOL: {
        keyCombos: ['R'],
        state: STATES.WORKSPACE
    },
    ACTIVATE_CONTAINER_TOOL: {
        keyCombos: ['N'],
        state: STATES.WORKSPACE
    },
    ACTIVATE_ELLIPSE_TOOL: {
        keyCombos: ['O'],
        state: STATES.WORKSPACE
    },
    ACTIVATE_PEN_TOOL: {
        keyCombos: ['P'],
        state: STATES.WORKSPACE
    },
    ACTIVATE_COMMENT_TOOL: {
        keyCombos: ['C'],
        state: STATES.WORKSPACE
    },
    TOGGLE_EYE_DROPPER_TOOL: {
        keyCombos: ['I'],
        state: STATES.WORKSPACE
    },
    INCREASE_CORNER_RADIUS: {
        keyCombos: ['Modifier + O'],
        state: STATES.WORKSPACE
    },
    DECREASE_CORNER_RADIUS: {
        keyCombos: ['Modifier + K'],
        state: STATES.WORKSPACE
    },
    GROUP_ELEMENTS: {
        keyCombos: ['Modifier + G', 'Modifier + Alt + G'],
        state: STATES.WORKSPACE
    },
    UNGROUP_ELEMENT: {
        keyCombos: ['Modifier + Shift + G'],
        state: STATES.WORKSPACE
    },
    TOGGLE_EXPAND: {
        keyCombos: ['Alt + L'],
        state: STATES.WORKSPACE
    },
    MOVE_VIEWPORT_WHEEL: {
        keyCombos: ['WheelXPositive', 'WheelYPositive', 'WheelXNegative', 'WheelYNegative'],
        state: STATES.WORKSPACE,
        fallback: true
    },
    ZOOM_TO_POINTER: {
        keyCombos: [
            'Modifier + WheelXPositive',
            'Modifier + WheelYPositive',
            'Modifier + WheelXNegative',
            'Modifier + WheelYNegative'
        ],
        state: STATES.WORKSPACE
    },
    MAC_PINCH_ZOOM_TO_POINTER: {
        keyCombos: [
            'Ctrl + WheelXPositive',
            'Ctrl + WheelYPositive',
            'Ctrl + WheelXNegative',
            'Ctrl + WheelYNegative'
        ],
        state: STATES.WORKSPACE,
        platforms: ['Mac']
    },
    // alignment
    ALIGN_LEFT: {
        keyCombos: ['Alt + A'],
        state: STATES.WORKSPACE,
    },
    ALIGN_CENTER: {
        keyCombos: ['Alt + H'],
        state: STATES.WORKSPACE,
    },
    ALIGN_RIGHT: {
        keyCombos: ['Alt + D'],
        state: STATES.WORKSPACE,
    },
    ALIGN_TOP: {
        keyCombos: ['Alt + W'],
        state: STATES.WORKSPACE,
    },
    ALIGN_MIDDLE: {
        keyCombos: ['Alt + V'],
        state: STATES.WORKSPACE,
    },
    ALIGN_BOTTOM: {
        keyCombos: ['Alt + S'],
        state: STATES.WORKSPACE,
    },
    // distribute
    DISTRIBUTE_HORIZONTAL: {
        keyCombos: ['Ctrl + Alt + H'],
        state: STATES.WORKSPACE,
    },
    DISTRIBUTE_VERTICAL: {
        keyCombos: ['Ctrl + Alt + V'],
        state: STATES.WORKSPACE,
    },
    TOGGLE_ORIGIN: {
        keyCombos: ['Shift + Y'],
        state: STATES.WORKSPACE
    },
    TOGGLE_RULER: {
        keyCombos: ['Shift + R'],
        state: STATES.WORKSPACE
    },
    TOGGLE_INTERFACE: {
        keyCombos: ['Modifier + \\'],
        state: STATES.WORKSPACE
    },
    TOGGLE_COMMENT_VISIBILITY:{
        keyCombos: ['Shift + C'],
        state: STATES.WORKSPACE
    },
    TOGGLE_VISIBLE: {
        keyCombos: ['Modifier + Shift + H'],
        state: STATES.WORKSPACE,
    },
    TOGGLE_LOCK: {
        keyCombos: ['Modifier + Shift + L'],
        state: STATES.WORKSPACE,
    },
    BRING_TO_FRONT: {
        keyCombos: ['Alt + Modifier + ]'],
        state: STATES.WORKSPACE
    },
    SEND_TO_BACK: {
        keyCombos: ['Alt + Modifier + ['],
        state: STATES.WORKSPACE
    },
    MOVE_FORWARD: {
        keyCombos: ['Modifier + ]'],
        state: STATES.WORKSPACE
    },
    MOVE_BACKWARD: {
        keyCombos: ['Modifier + ['],
        state: STATES.WORKSPACE
    },
    MASK_SELECTION: {
        keyCombos: ['Ctrl + Modifier + M'],
        state: STATES.WORKSPACE
    },
    TOGGLE_PRESENCE: {
        keyCombos: ['Shift + Modifier + \\'],
        state: STATES.WORKSPACE,
    },
    EDIT_ORIGIN: {
        keyCombos: ['Y'],
        state: STATES.WORKSPACE
    },
    TOGGLE_INSPECTING:{
        keyCombos: ['Shift + I'],
        state: STATES.WORKSPACE
    }
}
