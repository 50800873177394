import React, { createContext, useContext } from 'react'
import { useApolloClient } from '@apollo/client'
import { MenuOptionProps } from '../shared/Menu/MenuOption'
import { AllPermissionEnum, DraftFilePermissionEnum } from './shareTypes'

import { transformCollaborator } from './shareHelper'
import { BaseShareDialogProps } from './BaseShareDialog'
import {
  FileFieldsFragment,
  FileFieldsFragmentDoc,
  useOnFileCollaboratorsUpdatedSubscription
} from '../../generated/graphql'
import { getFileIndexPath } from '../../utils/pathGenerators'
import { useFilePermissionContext } from '../../providers/FilePermissionProvider'
import { PERMISSIONS, useAccessControl } from '../../access-control'

type ShareFileDialogContextData = Pick<BaseShareDialogProps, 'onClose' | 'open'> & {
  canEdit: boolean
  collaboratorList: MenuOptionProps[]
  fileId: string
  linkPathname: string
  name: FileFieldsFragment['name']
  permission: AllPermissionEnum
  draftPermission: DraftFilePermissionEnum
  projectId: string
}

const ShareFileDialogContext = createContext<ShareFileDialogContextData | undefined>(undefined)

type ShareFileDialogContextProviderProps = Pick<BaseShareDialogProps, 'onClose' | 'open'> & {
  projectId: string
  fileId: string
  children: React.ReactNode
}

const ShareFileDialogContextProvider = ({
  children,
  fileId,
  onClose,
  open,
  projectId
}: ShareFileDialogContextProviderProps) => {
  const client = useApolloClient()
  const { userHasPermission } = useAccessControl()
  const { fileWithPermission, refetchPermissions } = useFilePermissionContext()

  const fileData = client.readFragment({
    fragment: FileFieldsFragmentDoc,
    fragmentName: 'fileFields',
    id: `files:${fileId}`
  })

  const { data: collaboratorListData } = useOnFileCollaboratorsUpdatedSubscription({
    variables: {
      fileId
    },
    onData: () => {
      refetchPermissions()
    }
  })

  const linkPathname = getFileIndexPath(fileId)
  const collaboratorList =
    collaboratorListData?.file_users_view?.map((collaborator) => transformCollaborator(collaborator)) ?? []

  return (
    <ShareFileDialogContext.Provider
      value={{
        canEdit: userHasPermission(PERMISSIONS.MANAGE_FILE_COLLABORATOR, fileWithPermission),
        collaboratorList,
        fileId,
        linkPathname,
        name: fileData.name,
        onClose,
        open,
        permission: fileData.permission,
        draftPermission: fileData.draft_permission,
        projectId
      }}
    >
      {children}
    </ShareFileDialogContext.Provider>
  )
}

function useShareFileDialogContext(): ShareFileDialogContextData {
  const context = useContext(ShareFileDialogContext)
  if (!context) {
    throw new Error('useShareFileDialogContext must be used within a ShareFileDialogContextProvider')
  }
  return context
}

export { ShareFileDialogContextProvider, useShareFileDialogContext }
