import React from 'react'
import { useTranslation } from 'react-i18next'

import { Label } from '../../../../shared'
import { IEffectTrimPathProps } from '../types'
import TrimPathEnd from './TrimPathEnd'
import TrimPathMode from './TrimPathMode'
import TrimPathOffset from './TrimPathOffset'
import TrimPathStart from './TrimPathStart'

type PropertyComponent = {
  key: keyof IEffectTrimPathProps
  label: string
  component: React.ElementType
}

const propertyComponents: PropertyComponent[] = [
  {
    key: 'start',
    label: 'start',
    component: TrimPathStart
  },
  {
    key: 'end',
    label: 'end',
    component: TrimPathEnd
  },
  {
    key: 'offset',
    label: 'offset',
    component: TrimPathOffset
  },
  {
    key: 'mode',
    label: 'mode',
    component: TrimPathMode
  }
]

const TrimPathProps = (props: IEffectTrimPathProps) => {
  const { t } = useTranslation('file', { keyPrefix: 'property_editor.effect_editor.trim_path_modal' })

  return (
    <>
      {propertyComponents.map(({ key, label, component }) => (
        <div key={key} className="px-16 pt-0 mb-8 last:mb-0 grid items-center grid-cols-[64px,144px] gap-8">
          <Label text={t(label)} />

          <div className="w-[144px] flex items-center gap-8">
            {React.createElement(component, { id: props.id, value: props[key] })}
          </div>
        </div>
      ))}
    </>
  )
}

export default TrimPathProps
