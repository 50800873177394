import PropertyStack from '../PropertyStack'

class EffectStack extends PropertyStack {
  constructor(elementStack, data, effectType) {
    super(elementStack, data)
    this.type = ''
    this.key = ''
    this.dataKey = ''
    this.animatableProperties = new Set()
    this.effectType = effectType

    this._getComputedEffectId()
    this.ceKeyCache = null
  }

  _getComputedEffectId() {
    const element = this.dataStore.getById(this.elementStack.id)
    for (const ce of element.computedStyle.effects) {
      if (ce.get('effectType') === this.effectType) {
        this._computedEffectId = ce.get('id')
        break
      }
    }
  }

  /**
   * Get computedEffect
   * @returns {ComputedEffect}
   */
  get computedEffect() {
    const element = this.dataStore.getById(this.elementStack.id)
    return element.computedStyle.getComputedEffectById(this._computedEffectId)
  }

  get ceKey() {
    if (!this.computedEffect) {
      return undefined
    }

    this.ceKeyCache = this.computedEffect.get('id')
    return this.ceKeyCache
  }

  /**
   * Get keyframe state by time
   * @param {number} time
   * @returns {object}
   */
  getKFState(time) {
    let childKFStates = {}
    this.children.forEach((child) => {
      childKFStates = {
        ...childKFStates,
        ...child.getKFState(time)
      }
    })
    return childKFStates
  }

  getComputedData(time) {
    let updateData = {}
    // Get animation data from children stacks
    this.children.forEach((childStack) => {
      const animationData = childStack.getAnimateData(time)
      if (animationData !== undefined) {
        updateData = {
          ...updateData,
          ...animationData
        }
      }
    })

    return updateData
  }

  updateComputedData(time) {
    const updateData = this.getComputedData(time)
    if (Object.keys(updateData).length) {
      this.computedEffect.sets(updateData, { flags: 1 })
    }
  }

  resetComputedData(propKey) {
    if (!this.computedEffect) {
      return
    }

    const baseData = this.getInitValue()
    if (this.animatableProperties.has(propKey) && baseData[propKey] !== undefined) {
      this.computedEffect.set(propKey, baseData[propKey], { flags: 1 })
    }
  }

  resetComputedEffect() {
    if (!this.computedEffect) {
      return
    }

    const baseData = this.getInitValue()
    const updatedData = {}
    this.animatableProperties.forEach((propKey) => {
      updatedData[propKey] = baseData[propKey]
    })
    this.computedEffect.sets({
      ...updatedData
    }, { flags: 1 })
  }
}

export default EffectStack
