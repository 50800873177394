import React from 'react'
import { RouteConfigComponentProps } from 'react-router-config'
import { Redirect } from 'react-router-dom'

import { WorkspaceType } from '@phase-software/types'

import withAuthentication, { RedirectToLogin } from './components/shared/withAuthentication'
import withChromium from './components/withChromium'
import ErrorPages from './pages/ErrorPages'
import File from './pages/File'
import Files, { FilesPageEnum } from './pages/Files'
import JoinFileCollaborator from './pages/Join/JoinFileCollaborator'
import JoinProjectCollaborator from './pages/Join/JoinProjectCollaborator'
import JoinTeam from './pages/Join/JoinTeam'
import Lottie from './pages/Lottie'
import PersonalSettings from './pages/Settings/PersonalSettings'
import TeamSettings from './pages/Settings/TeamSettings'
import Templates from './pages/Templates'
import TemplateCloner from './pages/Templates/TemplateCloner'
import WorkspaceRoot from './pages/Workspace/WorkspaceRoot'
import { WorkspaceData } from './providers/WorkspaceContextProvider'
import {
  FILE_INDEX,
  JOIN_FILE_COLLABORATOR,
  JOIN_PROJECT_COLLABORATOR,
  JOIN_TEAM,
  PERSONAL_SHARED,
  TEMPLATE_CLONE,
  WORKSPACE_ARCHIVE,
  WORKSPACE_DRAFTS,
  WORKSPACE_INDEX,
  WORKSPACE_PROJECT,
  WORKSPACE_SETTINGS_INDEX,
  WORKSPACE_TEMPLATE,
  WORKSPACE_TEMPLATE_CLONE,
  WORKSPACE_WILDCARD
} from './routeConstants'

const WithAuthenticationTeamSetting = withChromium(withAuthentication(TeamSettings))
const WithAuthenticationPersonalSetting = withChromium(withAuthentication(PersonalSettings))

const routes = [
  {
    path: '/',
    exact: true,
    component: RedirectToLogin
  },
  {
    path: JOIN_TEAM,
    component: withAuthentication(JoinTeam)
  },
  {
    path: JOIN_PROJECT_COLLABORATOR,
    component: withAuthentication(JoinProjectCollaborator)
  },
  {
    path: JOIN_FILE_COLLABORATOR,
    exact: true,
    component: withAuthentication(JoinFileCollaborator)
  },
  {
    path: TEMPLATE_CLONE,
    exact: true,
    component: withAuthentication(TemplateCloner)
  },
  {
    path: FILE_INDEX,
    component: withChromium(withAuthentication(File))
  },
  {
    path: WORKSPACE_SETTINGS_INDEX,
    render: ({ match }: RouteConfigComponentProps) =>
      (match.params as { workspaceType: WorkspaceData['type'] }).workspaceType === WorkspaceType.PERSONAL ? (
        <WithAuthenticationPersonalSetting />
      ) : (
        <WithAuthenticationTeamSetting />
      )
  },
  {
    path: WORKSPACE_INDEX,
    exact: true,
    component: ({ match }: RouteConfigComponentProps) => <Redirect to={`${match.url}/drafts`} />
  },
  {
    path: WORKSPACE_INDEX,
    component: withChromium(withAuthentication(WorkspaceRoot)),
    routes: [
      {
        path: WORKSPACE_DRAFTS,
        component: () => <Files page={FilesPageEnum.DRAFT_PROJECT} />
      },
      {
        path: WORKSPACE_ARCHIVE,
        component: () => <Files page={FilesPageEnum.ARCHIVE} />
      },
      {
        path: WORKSPACE_TEMPLATE,
        exact: true,
        component: () => <Templates />
      },
      {
        path: WORKSPACE_TEMPLATE_CLONE,
        component: () => <TemplateCloner />
      },
      {
        path: PERSONAL_SHARED,
        component: () => <Files page={FilesPageEnum.SHARED} />
      },
      {
        path: WORKSPACE_PROJECT,
        component: () => <Files />
      },
      {
        path: WORKSPACE_WILDCARD,
        component: () => <Redirect to="./drafts" />
      }
    ]
  },
  {
    path: '/oops',
    component: () => <ErrorPages errorCode={500} />
  },
  {
    path: '/400',
    component: () => <ErrorPages errorCode={400} />
  },
  {
    path: '/403',
    component: () => <ErrorPages errorCode={403} />
  },
  {
    path: '/404',
    component: () => <ErrorPages errorCode={404} />
  },
  {
    path: '*',
    component: () => <ErrorPages errorCode={404} />
  }
]

if (process.env.NODE_ENV !== 'production') {
  routes.unshift({
    path: '/lottie',
    exact: true,
    component: Lottie
  })
}
export default routes
