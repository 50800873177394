import React from 'react'

import PropTypes from 'prop-types'

const SelectText = ({ selected, mixed }) => {
  return <span className="truncate">{mixed ? 'Mix' : selected ? selected.name : ''}</span>
}

SelectText.propTypes = {
  selected: PropTypes.object,
  mixed: PropTypes.bool
}

export default SelectText
