import {
    PropComponentType,
    OpacityType,
    BlendMode
} from '@phase-software/types'
import { notNull, Vector2, OpacityStop } from '@phase-software/data-utils'
import PropertyComponent from './PropertyComponent'

/** @typedef {import('../DataStore').DataStore} DataStore */
/** @typedef {import('./PropertyComponent').AppliedRef} AppliedRef */

export default class OpacityComponent extends PropertyComponent {
    /**
     * @param {DataStore} dataStore
     * @param {OpacityComponentData} [data]
     * @param {object} [options] 
     * @param {bool} [options.regenId=false]   if set to true, will generate new ID
     */
    constructor(dataStore, data = {}, options) {
        super(dataStore, data, options)

        const { opacityType, blendMode, opacity, opacityHandles, opacityStops } = data

        this.componentType = PropComponentType.OPACITY
        this.opacityType = notNull(opacityType) ? opacityType : OpacityType.SOLID
        this.blendMode = notNull(blendMode) ? blendMode : BlendMode.PASS_THROUGH
        this.opacity = notNull(opacity) ? opacity : 1
        this.opacityHandles = notNull(opacityHandles) ? opacityHandles.map(position => new Vector2(position)) : []
        this.opacityStops = notNull(opacityStops) ? opacityStops.map(stop => new OpacityStop(stop)) : []

        if (!this.name) {
            this.name = 'opacity'
        }
    }

    /** @param {Partial<OpacityComponentData>} data */
    set(data) {
        super.set(data)

        if (data.opacityType in OpacityType) {
            this.updateProp('opacityType', data.opacityType)
        }
        if (data.blendMode in BlendMode) {
            this.updateProp('blendMode', data.blendMode)
        }
        if (notNull(data.opacity)) {
            this.updateProp('opacity', data.opacity)
        }
        if (notNull(data.opacityHandles)) {
            const opacityHandles = data.opacityHandles.map(position => new Vector2(position))
            this.updateProp('opacityHandles', opacityHandles)
        }
        if (notNull(data.opacityStops)) {
            const opacityStops = data.opacityStops.map(stop => new OpacityStop(stop))
            this.updateProp('opacityStops', opacityStops)
        }
    }

    /**
     * Override this in subclasses
     * CALL super._clone() at the top of overriden method
     * @protected
     * @param {AppliedRef} [ref]
     * @returns {OpacityComponent} obj
     */
    _clone(ref) {
        const obj = super._clone(ref)
        obj.opacityType = this.opacityType
        obj.blendMode = this.blendMode
        obj.opacity = this.opacity
        obj.opacityHandles = this.opacityHandles.map(position => new Vector2(position))
        obj.opacityStops = this.opacityStops.map(stop => new OpacityStop(stop))
        return obj
    }

    /**
     * Override this in subclasses
     * CALL super._save() at the top of overriden method
     * @protected
     * @returns {OpacityComponentData} data
     */
    _save() {
        const data = super._save()
        data.opacityType = this.opacityType
        data.blendMode = this.blendMode
        data.opacity = this.opacity
        data.opacityHandles = this.opacityHandles.map(position => [...position])
        data.opacityStops = this.opacityStops.map(gradientStop => gradientStop.save())
        return data
    }
}

/** @typedef {('SOLID'|'LINEAR'|'RADIAL'|'ANGULAR'|'DIAMOND'|'SHAPE'|'ACROSS'|'ALONG')} OpacityType */
/** @typedef {import('./PropertyComponent').PropertyComponentData} PropertyComponentData */
/** @typedef {import('./PaintComponent').BlendMode} BlendMode */
/** @typedef {import('../data/OpacityStop').OpacityStopData} OpacityStopData */

/**
 * @typedef {PropertyComponentData} OpacityComponentData
 * @property {OpacityType} opactityType
 * @property {BlendMode} blendMode
 * @property {number} opacity
 * @property {Vector2[]} opacityHandles
 * @property {OpacityStop[]} opacityStops
 */
