import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../../../components/shared'

type ErrorPageProps = {
  errorCode: number
  title: string
  image: { src: string; width: number; height: number }
  errorMessageA: string
  errorMessageB: string
  onClick: () => void
}

const ErrorPage = ({
  errorCode,
  title,
  image: { src, width, height },
  errorMessageA,
  errorMessageB,
  onClick
}: ErrorPageProps) => {
  const { t } = useTranslation('common', { keyPrefix: 'error_page' })
  return (
    <section className="w-900 mx-auto flex flex-col items-center justify-center h-screen">
      <img src={src} width={width} height={height} alt={title} />
      <div className="h-240">
        <header className="text-center text-light-overlay-60 text-h1 font-semibold mb-16">{errorCode}</header>
        <main className="text-center text-white text-h1 font-semibold mb-32">{title}</main>
        <p className="text-center text-white text-14 mb-32">
          {errorMessageA}
          <br />
          {errorMessageB}
        </p>
        <div className="text-center">
          <Button variant="solid" color="primary" onClick={onClick}>
            {t('back_to_home')}
          </Button>
        </div>
      </div>
    </section>
  )
}

export default ErrorPage
