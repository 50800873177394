import React, { forwardRef } from 'react'
import BaseButton from './BaseButton'

type ButtonColors = 'primary' | 'secondary' | 'danger'

interface SolidButtonProps {
  color?: ButtonColors
  size?: 's' | 'l' | 'xl'
  className?: string
  children: string
}

const SolidButton = forwardRef<HTMLButtonElement, SolidButtonProps>(
  ({ color = 'primary', size, className, children, ...rest }, ref) => {
    const fillMapping = {
      primary: 'bg-primary-50 hover:bg-primary-40 active:bg-primary-60 disabled:opacity-40 disabled:bg-primary-50',
      secondary:
        'bg-light-overlay-10 hover:bg-light-overlay-15 active:bg-light-overlay-5 disabled:opacity-40 disabled:bg-light-overlay-10',
      danger: 'bg-danger-50 hover:bg-danger-40 active:bg-danger-60 disabled:opacity-40 disabled:bg-danger-50'
    }
    const borderMapping = {
      primary: 'focus-visible:outline-primary-1-offset-2 disabled:outline-none',
      secondary: 'focus-visible:outline-primary-1-offset--1 disabled:outline-none',
      danger: 'focus-visible:outline-primary-1-offset-2 disabled:outline-none'
    }

    return (
      <BaseButton
        color={color}
        variant="solid"
        ref={ref}
        className={`${fillMapping[color]} ${borderMapping[color]} ${className}`}
        size={size}
        {...rest}
      >
        {children}
      </BaseButton>
    )
  }
)

SolidButton.displayName = 'SolidButton'

export default SolidButton
