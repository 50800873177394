import React, { useMemo } from 'react'

import { useAtomValue } from 'jotai'

import { DragAction, dragActionAtom, keyframeOverlappedAtom } from '../../../atoms'

export const DragCursor = () => {
  const dragAction = useAtomValue(dragActionAtom)
  const isOverlapped = useAtomValue(keyframeOverlappedAtom)

  const cursor = useMemo(() => {
    if (isOverlapped) {
      return `url(${process.env.PUBLIC_URL}/cursors/Replace.svg) 3 5, auto`
    }

    switch (dragAction) {
      case DragAction.MOVE:
        return 'pointer'
      case DragAction.STRETCH:
        return 'ew-resize'
      case DragAction.SELECT:
      case DragAction.NONE:
        return 'auto'
    }
  }, [dragAction, isOverlapped])

  const pointerEvents = dragAction === DragAction.NONE ? 'pointer-events-none' : ''

  return <div className={`fixed inset-0 z-50 ${pointerEvents}`} style={{ cursor }} />
}
