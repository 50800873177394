import React, { useRef, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { getGradientColorByPosition } from '@phase-software/data-utils'
import { Events } from '@phase-software/data-store'
import { usePaint } from '../../../providers/dataStore/PaintProvider'
import { useEditorActions } from '../../../providers/dataStore/EditorProvider'
import { useDataStore, useDataStoreActions } from '../../../providers/dataStore/DataStoreProvider'
import { useSetNotification } from '../../../providers/NotificationProvider'
import ColorStop from './ColorStop'
import { stopsToRgbaString } from './utils'
import { checkerBackground } from '../../shared/utils'
import { NOTIFICATION_MAP } from '../../../constant'
import GradientPositionInput from './GradientPositionInput'
import FlipGradientButton from './FlipGradientButton'

const checkerBackgroundStr = checkerBackground(4, '#484755', 'black')

export const convertPaletteToLinearGradient = (palette) => {
  return `linear-gradient(to right, ${stopsToRgbaString(palette)})`
}

let selectedHistory = [0]

/**
 * Add selected gradient stop to history
 * @param {number} index
 */
const addSelectedStop = (index) => {
  // Put the stop index to the end of the history
  const i = selectedHistory.indexOf(index)
  if (i > -1) {
    selectedHistory.splice(i, 1)
  }
  selectedHistory.push(index)
}

/**
 * Get previous selected gradient stop from history
 * @returns {number}
 */
const getPreviousStop = () => {
  const curr = selectedHistory.pop() || 0
  // Should minus the index for which is large than the delete one
  selectedHistory = selectedHistory.map((i) => (i < curr ? i : i - 1))
  // The last one in the history will be the previous selected stop
  return selectedHistory[selectedHistory.length - 1]
}

const GradientStops = ({ layerItemId, activeIdx, onActiveChange }) => {
  const dataStore = useDataStore()
  const { addNotification } = useSetNotification()
  const ref = useRef()
  const gradientStops = usePaint((o) => o[layerItemId].gradientStops)
  const activeGradientStopIdx = usePaint((o) => o[layerItemId].activeGradientStopIdx)
  const { setLayerPaint } = useEditorActions()
  const { commitUndo, selectGradientStop } = useDataStoreActions()

  let selectedGradientStop
  selectedGradientStop = gradientStops[activeIdx]
  if (!selectedGradientStop) {
    let min = 1
    let idx = Number.MAX_SAFE_INTEGER
    gradientStops.forEach((gs, i) => {
      if (gs.position < min) {
        min = gs.position
        idx = i
      }
    })
    selectedGradientStop = gradientStops[idx]
  }

  const createStop = (e) => {
    e.stopPropagation()
    const { left, width } = ref.current.getBoundingClientRect()
    const position = (e.pageX - left) / width
    const color = getGradientColorByPosition(gradientStops, activeIdx, position)
    const newStops = [...gradientStops, { color, position }]
    setLayerPaint(layerItemId, {
      gradientStops: newStops,
      activeGradientStopIdx: gradientStops.length
    })
  }

  const updateStopPosition = (position) => {
    const newStops = [
      ...gradientStops.slice(0, activeIdx),
      { ...gradientStops[activeIdx], position },
      ...gradientStops.slice(activeIdx + 1)
    ]
    setLayerPaint(layerItemId, { gradientStops: newStops }, { commit: false })
  }

  const removeStop = useCallback(() => {
    if (gradientStops.length <= 2) {
      addNotification(NOTIFICATION_MAP.DELETE_MIN_GRADIENT_STOPS)
      return
    }
    const newStops = [
      ...gradientStops.slice(0, activeGradientStopIdx),
      ...gradientStops.slice(activeGradientStopIdx + 1)
    ]
    const previousIdx = getPreviousStop()
    setLayerPaint(layerItemId, {
      gradientStops: newStops,
      activeGradientStopIdx: previousIdx
    })
  }, [addNotification, activeGradientStopIdx, gradientStops, layerItemId, setLayerPaint])

  const reverseStops = () => {
    const newStops = gradientStops.map(({ position, ...rest }) => ({
      position: 1 - position,
      ...rest
    }))
    setLayerPaint(layerItemId, { gradientStops: newStops })
    commitUndo()
  }

  const handleClickColorStop = (idx) => {
    onActiveChange(idx)
    setLayerPaint(
      layerItemId,
      {
        activeGradientStopIdx: idx
      },
      { commit: false }
    )
    selectGradientStop(idx)
  }

  const handleGradientStopPositionChange = (pos) => {
    const newStops = [
      ...gradientStops.slice(0, activeIdx),
      { ...gradientStops[activeIdx], position: pos / 100 },
      ...gradientStops.slice(activeIdx + 1)
    ]
    setLayerPaint(layerItemId, { gradientStops: newStops }, { commit: false })
  }

  const handleBlur = useCallback(() => {
    selectGradientStop(-1)
  }, [selectGradientStop])

  useEffect(() => {
    selectGradientStop(activeIdx)
  }, [selectGradientStop, activeIdx])

  useEffect(() => {
    return () => {
      // Clear active gradient stop idx when close paint picker with gradient stops controls.
      selectGradientStop(-1)
    }
  }, [selectGradientStop])

  useEffect(() => {
    dataStore.eam.on(Events.DELETE_GRADIENT_STOP, removeStop)

    return () => {
      dataStore.eam.off(Events.DELETE_GRADIENT_STOP, removeStop)
    }
  }, [dataStore.eam, removeStop])

  useEffect(() => {
    addSelectedStop(activeGradientStopIdx)
    onActiveChange(activeGradientStopIdx)
  }, [activeGradientStopIdx, onActiveChange])

  return (
    <>
      <div className="flex items-center gap-x-8 mb-8">
        <GradientPositionInput
          selectedGradientStopPosition={selectedGradientStop.position}
          onBlur={commitUndo}
          onChange={handleGradientStopPositionChange}
        />
        <FlipGradientButton reverseStops={reverseStops} />
      </div>
      <div
        className="relative h-16 w-full rounded-lg px-2 mb-8"
        style={{ background: `${convertPaletteToLinearGradient(gradientStops)},${checkerBackgroundStr}` }}
        ref={ref}
        onMouseDown={createStop}
      >
        {gradientStops.map((stop, idx) => (
          <ColorStop
            key={idx}
            index={idx}
            position={stop.position}
            color={[...stop.color]}
            active={activeIdx === idx}
            onMove={updateStopPosition}
            onMoveEnd={commitUndo}
            onActivate={handleClickColorStop}
            onBlur={handleBlur}
          />
        ))}
      </div>
    </>
  )
}

GradientStops.propTypes = {
  layerItemId: PropTypes.string.isRequired,
  activeIdx: PropTypes.number.isRequired,
  onActiveChange: PropTypes.func.isRequired
}

export default React.memo(GradientStops)
