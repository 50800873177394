import { IRKeyFrame } from './IRKeyFrame'

export class IRProperty {
  constructor(data) {
    this.value = data.value
    this.animatable = data.animatable !== false
    this.keyFrames = []
  }

  get animated() {
    return this.keyFrames.length > 0
  }

  fromJSON(data) {
    this.value = data.value
    if (this.animatable) {
      this.keyFrames = data.keyFrames.map(keyFrameData => new IRKeyFrame().fromJSON(keyFrameData))
    }
    return this
  }

  addKeyFrame(keyFrame) {
    if (this.animatable) {
      // keep the motion path keyframe at t0
      if (keyFrame.time === 0 && !(keyFrame.inTangent || keyFrame.outTangent)) {
        this.value = keyFrame.value
      } else {
        this.keyFrames.push(keyFrame)
      }
    }
  }

  toJSON() {
    const data = {
      value: this.value
    }
    if (this.animatable) {
      data.keyFrames = this.keyFrames.map(keyFrame => keyFrame.toJSON())
    }
    return data
  }
}
