import { CapShape, JoinShape } from "@phase-software/types"
import { GEOMETRIC_EPSILON, Vector2 } from "../../math"
import { CubicBezTypes, detectCubicBezType } from "../utils"
import {
    solveItp,
    solveCubic,
    solveQuadratic,
    factorQuarticInner,
} from "./common"
import { Line } from "./Line"
import { CubicBez } from "./CubicBez"
import { BezierPath, BezierShape } from "./BezierShape"

/** @typedef {import("../../math/Vector2").Vector2Like} Vector2Like */
/** @typedef {import("./Segment").Segment} Segment */
/** @typedef {import("./QuadBez").QuadBez} QuadBez */
/** @typedef {import("./BezierShape").CurveData} CurveData */

export function StrokeStyle() {
    /** @type {number} */
    this.width = 1

    /** @type {JoinShape} */
    this.join = JoinShape.BEVEL
    /** @type {number} */
    this.miterLimit = 4

    /** @type {CapShape} */
    this.startCap = CapShape.NONE
    /** @type {CapShape} */
    this.endCap = CapShape.NONE

    /** @type {number[]} */
    this.dashPattern = [0,0,0,0]

    /** @type {boolean} */
    this.scale = false
}

/**
 * @param {BezierShape} shape
 * @param {StrokeStyle} style
 * @param {number} tolerance
 */
export function stroke2(shape, style, tolerance = 1e-3) {
    if (!Array.isArray(style.dashPattern) || style.dashPattern.length <= 1) {
        return strokeUndashed(shape, style, tolerance)
    } else {
        const roundLineCaps = (style.startCap === CapShape.ROUND || style.endCap === CapShape.ROUND)
        const dashed = buildDashedShape(shape, style.dashPattern, roundLineCaps)
        if (dashed.isEmpty()) {
            return dashed
        }
        return strokeUndashed(dashed, style, tolerance)
    }
}

/**
 * @param {BezierShape} shape
 * @param {StrokeStyle} style
 * @param {number} tolerance
 */
function strokeUndashed(shape, style, tolerance = 1e-3) {
    const ctx = new StrokeCtx()
    ctx.join_thresh = 2 * tolerance / style.width

    const p0 = new Vector2()
    const p1 = new Vector2()
    const p2 = new Vector2()
    const p3 = new Vector2()
    const c = new CubicBez()

    // let inX = 0
    // let inY = 0
    // let outX = 0
    // let outY = 0

    for (let i = 0, len = shape._children.length; i < len; i++) {
        const path = shape._children[i]
        if (path.isEmpty()) continue
        const segs = path._segments

        ctx.finish(style)

        const seg0 = segs[0]
        p0.copy(seg0._point)
        p1.copy(seg0._point).add(seg0._handleOut)
        ctx.last_pt.copy(p0)

        // outX = p1.x
        // outY = p1.y

        // const prev_tan1 = new Vector2(0, 0)
        // const prev_tan2 = new Vector2(0, 0)

        ctx.start_pt.copy(p0)
        for (let si = 1, slen = segs.length, end = path._closed ? slen + 1 : slen; si < end; si++) {
            const seg = segs[si % slen]

            p2.copy(seg._point).add(seg._handleIn)
            p3.copy(seg._point)

            const type = detectCubicBezType(
                p0.x, p0.y,
                p1.x, p1.y,
                p2.x, p2.y,
                p3.x, p3.y
            )

            // line
            if (type === CubicBezTypes.line) {
            // if (
            //     inX === p3.x
            //     &&
            //     inY === p3.y
            //     &&
            //     outX === p0.x
            //     &&
            //     outY === p0.y
            // ) {
                if (!p3.equals(p0)) {
                    const tangent = p3.clone().sub(p0)

                    // const tan_n = tangent.normalized()
                    // const tan_n_neg = tan_n.clone().negate()
                    // if (tan_n_neg.equals(prev_tan1) && tan_n_neg.equals(prev_tan2)) {
                    //     tangent.negate()
                    //     ctx.doJoin(style, tangent)
                    //     ctx.last_tan.copy(tangent)
                    //     ctx.doLine(style, tangent, p3)
                    // } else {
                    //     ctx.doJoin(style, tangent)
                    //     ctx.last_tan.copy(tangent)
                    //     ctx.doLine(style, tangent, p3)
                    // }

                    ctx.doJoin(style, tangent, !!seg._path._closed)
                    ctx.last_tan.copy(tangent)
                    ctx.doLine(style, tangent, p3)

                    // prev_tan2.copy(prev_tan1)
                    // prev_tan1.copy(tan_n)
                }
            }
            // curve
            else {
                if (!p1.equals(p0) || !p2.equals(p0) || !p3.equals(p0)) {
                    c.initWith4Points(p0, p1, p2, p3)
                    const [tan0, tan1] = endpointTangents(p0, p1, p2, p3)
                    ctx.doJoin(style, tan0, !!seg._path._closed)
                    ctx.last_tan.copy(tan1)
                    ctx.doCubic(style, c, tolerance)
                }

                // prev_tan2.set(0, 0)
                // prev_tan1.set(0, 0)
            }

            p0.copy(seg._point)
            p1.copy(seg._point).add(seg._handleOut)
            ctx.last_pt.copy(p0)

            // inX = p1.x
            // inY = p1.y
        }

        if (path._closed) {
            ctx.finishClosed(style)
        }
    }

    ctx.finish(style)

    // reduce for better result
    ctx.output.reduce()

    // This is a workaround for boolean operations.
    // If a stroked path is not closed and gets passed into a boolean operation,
    // you may encounter unexpected behavior (round caps being cut) from boolean calculation.
    // See http://sketch.paperjs.org/#V/0.12.17/S/nZNBbsIwEEWvYnlDIjmRJ4WGInXVCyCxbLpwHKtUARs5rlgg7s7YigqlSeSym3zbb/58TU5Ui72iK7pplZNbyqg0jf+WRneOHITbAnklWh3JGuskrXTQctE0iVc36nOvtAv12nxhBeWSkSe+SBm5ipwR/ldII3DFfO5xy2kc5HiL5xCJfEYk3BnKgZEMOb/ULMhejfcKxbC1h8bv03yZHj8LTbLYAKD0AfByZNThWP7hdjFmbiKCzlnTqjezMxYXblbvhGxnla70dROLgU0sftzcUkfPgN+cRrREvbf3XTsrpEvC0x5xT7Cqwff4E9VWifbgm3Z09f5xvgA=
    if (!ctx.output.isEmpty()) {
        ctx.output.close()
    }

    return ctx.output
}

function StrokeCtx() {
    /** @type {BezierShape} */
    this.output = new BezierShape()

    /** @type {BezierPath} */
    this.forward_path = new BezierPath()
    /** @type {BezierPath} */
    this.backward_path = new BezierPath()

    /** @type {Vector2} */
    this.start_pt = new Vector2()
    /** @type {Vector2} */
    this.start_norm = new Vector2()
    /** @type {Vector2} */
    this.start_tan = new Vector2()

    /** @type {Vector2} */
    this.last_pt = new Vector2()
    /** @type {Vector2} */
    this.last_tan = new Vector2()

    /** @type {number} */
    this.join_thresh = 0
}
StrokeCtx.prototype = {
    constructor: StrokeCtx,

    /**
     * @param {StrokeStyle} style
     */
    finish(style) {
        if (this.forward_path.isEmpty()) {
            return
        }

        const tolerance = 1e-3
        const return_p = this.backward_path.getLastSegment()._point
        const d = this.last_pt.clone().sub(return_p)

        extendShape(this.output, this.forward_path)
        switch (style.endCap) {
            case CapShape.ROUND: {
                roundCap(this.output, tolerance, this.last_pt, d)
                break
            }
            case CapShape.SQUARE: {
                squareCap(this.output, false, this.last_pt, d)
                break
            }
            default: {
                this.output.lineTo(return_p.x, return_p.y)
                break
            }
        }

        extendShapeRev(this.output, this.backward_path, true)
        switch (style.startCap) {
            case CapShape.ROUND: {
                roundCap(this.output, tolerance, this.start_pt, this.start_norm)
                break
            }
            case CapShape.SQUARE: {
                squareCap(this.output, true, this.start_pt, this.start_norm)
                break
            }
            default: {
                this.output.close()
                break
            }
        }

        this.forward_path.clear()
        this.backward_path.clear()
    },

    /**
     * @param {StrokeStyle} style
     */
    finishClosed(style) {
        const tolerance = 1e-3
        this.doJoin(style, this.start_tan)
        extendShape(this.output, this.forward_path)
        this.output.close(tolerance)
        const last_seg = this.backward_path.getLastSegment()
        this.output.moveTo(last_seg._point.x, last_seg._point.y)
        extendShapeRev(this.output, this.backward_path)
        this.output.close(tolerance)
        this.forward_path.clear()
        this.backward_path.clear()
    },

    /**
     * @param {StrokeStyle} style
     * @param {Vector2} tan0
     * @param {boolean} closed
     */
    doJoin(style, tan0, closed = true) {
        const tolerance = 1e-3
        const scale = tan0.length() === 0 ? 0 : 0.5 * style.width / tan0.length()
        const norm = new Vector2(-tan0.y, tan0.x).scale(scale)
        const p0 = this.last_pt
        if (this.forward_path.isEmpty()) {
            this.forward_path.moveTo(p0.x - norm.x, p0.y - norm.y)
            this.backward_path.moveTo(p0.x + norm.x, p0.y + norm.y)
            this.start_tan.copy(tan0)
            this.start_norm.copy(norm)
        } else {
            const ab = this.last_tan
            const cd = tan0
            const cross = ab.cross(cd)
            const dot = ab.dot(cd)
            const hypot = Math.hypot(cross, dot)
            if (!closed || Math.abs(cross) >= hypot * this.join_thresh) {
                switch (style.join) {
                    case JoinShape.MITER: {
                        if (hypot * 2 < (hypot + dot) * (style.miterLimit * style.miterLimit)) {
                            const last_scale = ab.length() === 0 ? 0 : 0.5 * style.width / ab.length()
                            const last_norm = new Vector2(-ab.y, ab.x).scale(last_scale)
                            if (cross > 0) {
                                const fp_last = p0.clone().sub(last_norm)
                                const fp_this = p0.clone().sub(norm)
                                const h = ab.cross(fp_this.clone().sub(fp_last)) / cross
                                const miter_pt = cd.clone().scale(-h).add(fp_this)
                                this.forward_path.lineTo(miter_pt.x, miter_pt.y)
                            } else if (cross < 0) {
                                const fp_last = p0.clone().add(last_norm)
                                const fp_this = p0.clone().add(norm)
                                const h = ab.cross(fp_this.clone().sub(fp_last)) / cross
                                const miter_pt = cd.clone().scale(-h).add(fp_this)
                                this.backward_path.lineTo(miter_pt.x, miter_pt.y)
                            }
                        }
                        this.forward_path.lineTo(p0.x - norm.x, p0.y - norm.y)
                        this.backward_path.lineTo(p0.x + norm.x, p0.y + norm.y)
                        break
                    }
                    case JoinShape.ROUND: {
                        const angle = Math.atan2(cross, dot)
                        if (cross > 0) {
                            this.backward_path.lineTo(p0.x + norm.x, p0.y + norm.y)
                            roundJoin(this.forward_path, tolerance, p0, norm, angle)
                        } else if (cross < 0) {
                            this.forward_path.lineTo(p0.x - norm.x, p0.y - norm.y)
                            roundJoinRev(this.backward_path, tolerance, p0, norm.negate(), -angle)
                        } else {
                            if (angle <= 0) {
                                this.forward_path.lineTo(p0.x - norm.x, p0.y - norm.y)
                                roundJoinRev(this.backward_path, tolerance, p0, norm.negate(), -angle)
                            } else {
                                this.backward_path.lineTo(p0.x + norm.x, p0.y + norm.y)
                                roundJoin(this.forward_path, tolerance, p0, norm, angle)
                            }
                        }
                        break
                    }
                    default: {
                        this.forward_path.lineTo(p0.x - norm.x, p0.y - norm.y)
                        this.backward_path.lineTo(p0.x + norm.x, p0.y + norm.y)
                        break
                    }
                }
            }
        }
    },

    /**
     * @param {StrokeStyle} style
     * @param {Vector2} tangent
     * @param {Vector2} p1
     */
    doLine(style, tangent, p1) {
        const scale = 0.5 * style.width / tangent.length()
        const norm = new Vector2(-tangent.y, tangent.x).scale(scale)
        this.forward_path.lineTo(p1.x - norm.x, p1.y - norm.y)
        this.backward_path.lineTo(p1.x + norm.x, p1.y + norm.y)
        this.last_pt.copy(p1)
    },

    /**
     * @param {StrokeStyle} style
     * @param {CubicBez} c
     * @param {number} tolerance
     */
    doCubic(style, c, tolerance) {
        const DIM_TUNE = 0.25
        const dimension = tolerance * DIM_TUNE

        /** @type {CubicOffset} */
        let co = null

        co = CubicOffset.regularized(c, -0.5 * style.width, dimension)
        const forward = fitToBezpath(co, tolerance)
        this.forward_path.join(forward)

        co = CubicOffset.regularized(c, 0.5 * style.width, dimension)
        const backward = fitToBezpath(co, tolerance)
        this.backward_path.join(backward)

        this.last_pt.copy(c.getP3())
    },
}

/**
 * @param {BezierShape} shape
 * @param {BezierPath} path
 * @param {boolean} try_merge
 */
function extendShape(shape, path, try_merge = false) {
    // skip empty path
    if (path.isEmpty()) {
        return shape
    }

    // not trying to merge with existing path
    if (!try_merge) {
        return shape.addChild(path.clone())
    }

    // try to merge yet no path exist yet
    if (shape.isEmpty()) {
        return shape.addChild(path.clone())
    }

    // try to join last segment with path's first segment
    const shapeLastPath = shape._children[shape._children.length - 1]
    const shapeLastSeg = shapeLastPath.getLastSegment()
    const otherFirstSeg = path.getFirstSegment()
    if (otherFirstSeg._point.isClose(shapeLastSeg._point, GEOMETRIC_EPSILON)) {
        shapeLastSeg.setHandleOut(otherFirstSeg._handleOut)
        for (let i = 1, len = path._segments.length; i < len; i++) {
            shapeLastPath.add(path._segments[i])
        }
    } else {
        shape.addChild(path.clone())
    }

    return shape
}

/**
 * @param {BezierShape} shape
 * @param {BezierPath} path
 * @param {boolean} try_merge
 */
function extendShapeRev(shape, path, try_merge) {
    path.reverse()
    return extendShape(shape, path, try_merge)
}

/**
 * @param {BezierShape} out
 * @param {number} tolerance
 * @param {Vector2} center
 * @param {Vector2} norm
 * @param {number} angle
 */
function roundJoin(out, tolerance, center, norm, angle) {
    const a = new Affine([norm.x, norm.y, -norm.y, norm.x, center.x, center.y])
    const arc = new Arc(new Vector2(0, 0), new Vector2(1, 1), Math.PI - angle, angle, 0)
    const cs = arc.toCubicBeziers(tolerance)
    for (let i = 0; i < cs.length; i += 3) {
        const p1 = cs[i]
        const p2 = cs[i+1]
        const p3 = cs[i+2]
        a.applyPoint(p1, p1)
        a.applyPoint(p2, p2)
        a.applyPoint(p3, p3)
        out.cubicCurveTo(
            p1.x, p1.y,
            p2.x, p2.y,
            p3.x, p3.y
        )
    }
}

/**
 * @param {BezierShape} out
 * @param {number} tolerance
 * @param {Vector2} center
 * @param {Vector2} norm
 * @param {number} angle
 */
function roundJoinRev(out, tolerance, center, norm, angle) {
    const a = new Affine([norm.x, norm.y, norm.y, -norm.x, center.x, center.y])
    const arc = new Arc(new Vector2(0, 0), new Vector2(1, 1), Math.PI - angle, angle, 0)
    const cs = arc.toCubicBeziers(tolerance)
    for (let i = 0; i < cs.length; i += 3) {
        const p1 = cs[i]
        const p2 = cs[i+1]
        const p3 = cs[i+2]
        a.applyPoint(p1, p1)
        a.applyPoint(p2, p2)
        a.applyPoint(p3, p3)
        out.cubicCurveTo(
            p1.x, p1.y,
            p2.x, p2.y,
            p3.x, p3.y
        )
    }
}

/**
 * @param {BezierShape} out
 * @param {number} tolerance
 * @param {Vector2} center
 * @param {Vector2} norm
 */
function roundCap(out, tolerance, center, norm) {
    roundJoin(out, tolerance, center, norm, Math.PI)
}

/**
 * @param {BezierShape} out
 * @param {boolean} close
 * @param {Vector2} center
 * @param {Vector2} norm
 */
function squareCap(out, close, center, norm) {
    const a = new Affine([norm.x, norm.y, -norm.y, norm.x, center.x, center.y])
    const p = new Vector2()
    a.applyPoint(p.set(1, 1), p)
    out.lineTo(p.x, p.y)
    a.applyPoint(p.set(-1, 1), p)
    out.lineTo(p.x, p.y)
    if (close) {
        out.close()
    } else {
        a.applyPoint(p.set(-1, 0), p)
        out.lineTo(p.x, p.y)
    }
}

/**
 * @param {Vector2} radii
 * @param {number} x_rotation
 * @param {number} angle
 */
function sampleEllipse(radii, x_rotation, angle) {
    const angle_sin = Math.sin(angle)
    const angle_cos = Math.cos(angle)
    const u = radii.x * angle_cos
    const v = radii.y * angle_sin
    return rotatePoint(u, v, x_rotation)
}
/**
 * @param {number} x
 * @param {number} y
 * @param {number} angle
 */
function rotatePoint(x, y, angle) {
    const angle_sin = Math.sin(angle)
    const angle_cos = Math.cos(angle)
    return new Vector2(
        x * angle_cos - y * angle_sin,
        x * angle_sin + y * angle_cos
    )
}

/**
 * Returns an array of candidate cubics matching given metrics.
 * @param {number} th0
 * @param {number} th1
 * @param {number} area
 * @param {number} mx
 * @returns {{ cand: CubicBez, d0: number, d1: number }[]}
 */
function cubicFit(th0, th1, area, mx) {
    const [c0, s0] = [Math.cos(th0), Math.sin(th0)]
    const [c1, s1] = [Math.cos(th1), Math.sin(th1)]
    const a4 = -9
        * c0
        * (((2 * s1 * c1 * c0 + s0 * (2 * c1 * c1 - 1)) * c0 - 2 * s1 * c1) * c0
            - c1 * c1 * s0)
    const a3 = 12
        * ((((c1 * (30 * area * c1 - s1) - 15 * area) * c0 + 2 * s0
            - c1 * s0 * (c1 + 30 * area * s1))
            * c0
            + c1 * (s1 - 15 * area * c1))
            * c0
            - s0 * c1 * c1)
    const a2 = 12
        * ((((70 * mx + 15 * area) * s1 * s1 + c1 * (9 * s1 - 70 * c1 * mx - 5 * c1 * area))
            * c0
            - 5 * s0 * s1 * (3 * s1 - 4 * c1 * (7 * mx + area)))
            * c0
            - c1 * (9 * s1 - 70 * c1 * mx - 5 * c1 * area))
    const a1 = 16
        * (((12 * s0 - 5 * c0 * (42 * mx - 17 * area)) * s1
            - 70 * c1 * (3 * mx - area) * s0
            - 75 * c0 * c1 * area * area)
            * s1
            - 75 * c1 * c1 * area * area * s0)
    const a0 = 80 * s1 * (42 * s1 * mx - 25 * area * (s1 - c1 * area))
    let roots = []
    const EPS = 1e-12
    if (Math.abs(a4) > EPS) {
        const a = a3 / a4
        const b = a2 / a4
        const c = a1 / a4
        const d = a0 / a4
        const quads = factorQuarticInner(a, b, c, d, false)
        if (quads !== null) {
            for (let i = 0; i < quads.length; i += 2) {
                const qc1 = quads[i]
                const qc0 = quads[i + 1]
                const qroots = solveQuadratic(qc0, qc1, 1)
                if (qroots.length > 0) {
                    roots = roots.concat(qroots)
                } else {
                    // Real part of pair of complex roots
                    roots.push(-0.5 * qc1)
                }
            }
        }
    } else if (Math.abs(a3) > EPS) {
        roots = roots.concat(solveCubic(a0, a1, a2, a3))
    } else {
        roots = roots.concat(solveQuadratic(a0, a1, a2))
    }

    const s01 = s0 * c1 + s1 * c0
    const cubics = []
    for (let i = 0, len = roots.length; i < len; i++) {
        let d0 = roots[i]
        let d1 = 0
        if (d0 > 0) {
            d1 = (d0 * s0 - area * (10 / 3.0)) / (0.5 * d0 * s01 - s1)
            if (d1 <= 0) {
                d0 = s1 / s01
                d1 = 0
            }
        } else {
            d0 = 0
            d1 = s0 / s01
        }
        if (d0 >= 0 && d1 >= 0) {
            cubics.push({
                cand: new CubicBez().initWith4PointsN(
                    0, 0,
                    d0 * c0, d0 * s0,
                    1 - d1 * c1, d1 * s1,
                    1, 0
                ),
                d0,
                d1
            })
        }
    }
    return cubics
}

const TAU = Math.PI * 2
/** @param {number} th */
const mod2pi = (th) => {
    const th_scaled = th * FRAC_1_PI * 0.5
    return TAU * (th_scaled - Math.round(th_scaled))
}

const D_PENALTY_ELBOW = 0.65
const D_PENALTY_SLOPE = 2.0
/** @param {number} d */
const scalef = (d) => (1.0 + Math.max(d - D_PENALTY_ELBOW, 0.0) * D_PENALTY_SLOPE)

/**
 * @param {CubicOffset} source
 * @param {number} rangeStart
 * @param {number} rangeEnd
 * @param {number} accuracy
 */
function fitToCubic(source, rangeStart, rangeEnd, accuracy) {
    const start = source.samplePointTangent(rangeStart, 1.0)
    const end = source.samplePointTangent(rangeEnd, -1.0)
    const d = end.p.clone().sub(start.p)
    const chord2 = d.length_squared()
    const acc2 = accuracy * accuracy
    if (chord2 <= acc2) {
        return tryFitLine(source, accuracy, rangeStart, rangeEnd, start.p, end.p)
    }
    const th = d.angle()
    const th0 = mod2pi(start.tangent.angle() - th)
    const th1 = mod2pi(th - end.tangent.angle())

    let { area, x, y } = source.momentIntegrals(rangeStart, rangeEnd)
    const [x0, y0] = [start.p.x, start.p.y]
    const [dx, dy] = [d.x, d.y]
    // Subtract off area of chord
    area -= dx * (y0 + 0.5 * dy)
    // `area` is signed area of closed curve segment.
    // This quantity is invariant to translation and rotation.

    // Subtract off moment of chord
    const dy_3 = dy * (1.0 / 3.0)
    x -= dx * (x0 * y0 + 0.5 * (x0 * dy + y0 * dx) + dy_3 * dx)
    y -= dx * (y0 * y0 + y0 * dy + dy_3 * dy)
    // Translate start point to origin; convert raw integrals to moments.
    x -= x0 * area
    y = 0.5 * y - y0 * area
    // Rotate into place (this also scales up by chordlength for efficiency).
    const moment = d.x * x + d.y * y
    // `moment` is the chordlength times the x moment of the curve translated
    // so its start point is on the origin, and rotated so its end point is on the
    // x axis.

    const chord2_inv = 1 / chord2
    const unit_area = area * chord2_inv
    const mx = moment * chord2_inv * chord2_inv
    // `unit_area` is signed area scaled to unit chord; `mx` is scaled x moment

    const chord = Math.sqrt(chord2)
    const aff = Affine.translate(start.p).multiply(Affine.rotate(th)).multiply(Affine.scale(chord))
    const curve_dist = new CurveDist(source, rangeStart, rangeEnd)
    /** @type {CubicBez | null} */
    let best_c = null
    /** @type {number | null} */
    let best_err2 = null
    const candidates = cubicFit(th0, th1, unit_area, mx)
    for (let i = 0, len = candidates.length; i < len; i++) {
        const { cand, d0, d1 } = candidates[i]
        const c = aff.applyCubic(cand)
        let err2 = curve_dist.evalDist(source, c, acc2)
        if (err2 !== null) {
            const scale = Math.pow(Math.max(scalef(d0), scalef(d1)), 2)
            err2 *= scale
            if (
                err2 < acc2
                &&
                (
                    (best_err2 !== null && err2 < best_err2)
                    ||
                    (best_err2 === null)
                )
            ) {
                best_c = c
                best_err2 = err2
            }
        }
    }
    if (best_c !== null && best_err2 !== null) {
        return { c: best_c, err: best_err2 }
    } else {
        return null
    }
}

/**
 * @param {CubicOffset} src source offset curve
 * @param {number} accuracy
 */
function fitToBezpath(src, accuracy) {
    const path = new BezierPath()
    fitToBezpathRec(src, 0.0, 1.0, accuracy, path)
    return path
}

/**
 * @param {CubicOffset} source
 * @param {number} accuracy
 * @param {number} rangeStart
 * @param {number} rangeEnd
 * @param {Vector2} start
 * @param {Vector2} end
 */
function tryFitLine(source, accuracy, rangeStart, rangeEnd, start, end) {
    const acc2 = accuracy * accuracy
    const chord_l = new Line().initP(start, end)
    const SHORT_N = 7
    let max_err2 = 0
    const dt = (rangeEnd - rangeStart) / (SHORT_N + 1)
    for (let i = 0; i < SHORT_N; i++) {
        const t = rangeStart + (i + 1) * dt
        const p = source.samplePointDeriv(t).p
        const err2 = chord_l.nearest(p, accuracy).distance_sq
        if (err2 > acc2) {
            return null
        }
        max_err2 = (err2 > max_err2) ? err2 : max_err2
    }
    const p1 = start.clone().linear_interpolate(end, 1 / 3)
    const p2 = end.clone().linear_interpolate(start, 1 / 3)
    const c = new CubicBez().initWith4Points(start, p1, p2, end)
    return { c, err: max_err2 }
}

/**
 * @param {CubicOffset} source source offset curve
 * @param {number} rangeStart
 * @param {number} rangeEnd
 * @param {number} accuracy
 * @param {BezierPath} path
 */
function fitToBezpathRec(source, rangeStart, rangeEnd, accuracy, path) {
    const start = rangeStart
    const end = rangeEnd
    const start_p = source.samplePointTangent(rangeStart, 1).p
    const end_p = source.samplePointTangent(rangeEnd, -1).p
    if (start_p.distance_squared_to(end_p) <= accuracy * accuracy) {
        const res = tryFitLine(source, accuracy, rangeStart, rangeEnd, start_p, end_p)
        if (res) {
            const c = res.c

            if (path.isEmpty()) {
                const p0 = c.getP0()
                path.moveTo(p0.x, p0.y)
            }
            const p1 = c.getP1()
            const p2 = c.getP2()
            const p3 = c.getP3()
            path.cubicCurveTo(
                p1.x, p1.y,
                p2.x, p2.y,
                p3.x, p3.y
            )
            return
        }
    }
    let t = source.breakCusp(rangeStart, rangeEnd)
    // eslint-disable-next-line no-negated-condition
    if (t !== null) {
        fitToBezpathRec(source, start, t, accuracy, path)
        fitToBezpathRec(source, t, end, accuracy, path)
    } else {
        const fit = fitToCubic(source, rangeStart, rangeEnd, accuracy)
        // eslint-disable-next-line no-negated-condition
        if (fit !== null) {
            if (path.isEmpty()) {
                const p0 = fit.c.getP0()
                path.moveTo(p0.x, p0.y)
            }
            const p1 = fit.c.getP1()
            const p2 = fit.c.getP2()
            const p3 = fit.c.getP3()
            path.cubicCurveTo(
                p1.x, p1.y,
                p2.x, p2.y,
                p3.x, p3.y
            )
        } else {
            // Fallback to midpoint subdivision
            t = 0.5 * (start + end)
            fitToBezpathRec(source, start, t, accuracy, path)
            fitToBezpathRec(source, t, end, accuracy, path)
        }
    }
}

/**
 * @param {[number, number, number, number, number, number]} c
 */
function Affine(c) {
    /** @type {[number, number, number, number, number, number]} */
    this.c = c
}
Affine.prototype = {
    constructor: Affine,

    /**
     * @param {Vector2} p
     * @param {Vector2} out
     */
    applyPoint(p, out = new Vector2()) {
        const c = this.c
        const x = c[0] * p.x + c[2] * p.y + c[4]
        const y = c[1] * p.x + c[3] * p.y + c[5]
        return out.set(x, y)
    },

    /**
     * @param {CubicBez} cu
     * @returns {CubicBez}
     */
    applyCubic(cu) {
        const c = this.c
        const p0 = cu.getP0()
        const p1 = cu.getP1()
        const p2 = cu.getP2()
        const p3 = cu.getP3()
        return new CubicBez().initWith4PointsN(
            c[0] * p0.x + c[2] * p0.y + c[4],
            c[1] * p0.x + c[3] * p0.y + c[5],
            c[0] * p1.x + c[2] * p1.y + c[4],
            c[1] * p1.x + c[3] * p1.y + c[5],
            c[0] * p2.x + c[2] * p2.y + c[4],
            c[1] * p2.x + c[3] * p2.y + c[5],
            c[0] * p3.x + c[2] * p3.y + c[4],
            c[1] * p3.x + c[3] * p3.y + c[5]
        )
    },

    /**
     * @param {Affine} other
     * @returns {Affine}
     */
    multiply(other) {
        const c1 = this.c
        const c2 = other.c
        return new Affine([
            c1[0] * c2[0] + c1[2] * c2[1],
            c1[1] * c2[0] + c1[3] * c2[1],
            c1[0] * c2[2] + c1[2] * c2[3],
            c1[1] * c2[2] + c1[3] * c2[3],
            c1[0] * c2[4] + c1[2] * c2[5] + c1[4],
            c1[1] * c2[4] + c1[3] * c2[5] + c1[5],
        ])
    },
}

/**
 * @param {Vector2} p
 * @returns {Affine}
 */
Affine.translate = (p) => (new Affine([1, 0, 0, 1, p.x, p.y]))

/**
 * @param {number} th
 * @returns {Affine}
 */
Affine.rotate = (th) => {
    const c = Math.cos(th)
    const s = Math.sin(th)
    return new Affine([c, s, -s, c, 0, 0])
}

/**
 * @param {number} s
 * @returns {Affine}
 */
Affine.scale = (s) => (new Affine([s, 0, 0, s, 0, 0]))

const FRAC_PI_2 = Math.PI * 0.5
const FRAC_1_PI = 1 / Math.PI

/**
 * @param {Vector2} center
 * @param {Vector2} radii
 * @param {number} start_angle
 * @param {number} sweep_angle
 * @param {number} x_rotation
 */
function Arc(center, radii, start_angle, sweep_angle, x_rotation) {
    /** @type {Vector2} */
    this.center = center
    /** @type {Vector2} */
    this.radii = radii
    /** @type {number} */
    this.start_angle = start_angle
    /** @type {number} */
    this.sweep_angle = sweep_angle
    /** @type {number} */
    this.x_rotation = x_rotation
}
Arc.prototype = {
    constructor: Arc,

    /**
     * @param {number} tolerance
     */
    toCubicBeziers(tolerance) {
        const self = {
            idx: 0,
            center: new Vector2().copy(this.center),
            radii: new Vector2().copy(this.radii),
            x_rotation: this.x_rotation,
            n: 0,
            arm_len: 0,
            angle_step: 0,
            p0: new Vector2(),
            angle0: 0,
        }
        const sign = Math.sign(this.sweep_angle)
        const scaled_err = Math.max(this.radii.x, this.radii.y) / tolerance
        const n_err = Math.max(Math.pow(1.1163 * scaled_err, 1/6), 3.999999)
        self.n = Math.ceil(n_err * Math.abs(this.sweep_angle) * (1 / TAU))
        self.angle_step = this.sweep_angle / self.n
        self.arm_len = Math.tan(Math.abs((4/3) * (0.25 * self.angle_step))) * sign
        self.angle0 = this.start_angle
        self.p0 = sampleEllipse(this.radii, this.x_rotation, self.angle0)

        /** @type {Vector2[]} */
        const out = []

        while (self.idx < self.n) {
            const angle1 = self.angle0 + self.angle_step
            const p0 = self.p0.clone()
            const p1 = p0.clone().add(sampleEllipse(self.radii, self.x_rotation, self.angle0 + FRAC_PI_2).scale(self.arm_len))
            const p3 = sampleEllipse(self.radii, self.x_rotation, angle1)
            const p2 = p3.clone().sub(sampleEllipse(self.radii, self.x_rotation, angle1 + FRAC_PI_2).scale(self.arm_len))

            self.angle0 = angle1
            self.p0 = p3
            self.idx += 1

            out.push(
                p1.clone().add(self.center),
                p2.clone().add(self.center),
                p3.clone().add(self.center)
            )
        }

        return out
    },
}

const GAUSS_LEGENDRE_COEFFS_16 = [
    0.1894506104550685, -0.0950125098376374,
    0.1894506104550685, 0.0950125098376374,
    0.1826034150449236, -0.2816035507792589,
    0.1826034150449236, 0.2816035507792589,
    0.1691565193950025, -0.4580167776572274,
    0.1691565193950025, 0.4580167776572274,
    0.1495959888165767, -0.6178762444026438,
    0.1495959888165767, 0.6178762444026438,
    0.1246289712555339, -0.7554044083550030,
    0.1246289712555339, 0.7554044083550030,
    0.0951585116824928, -0.8656312023878318,
    0.0951585116824928, 0.8656312023878318,
    0.0622535239386479, -0.9445750230732326,
    0.0622535239386479, 0.9445750230732326,
    0.0271524594117541, -0.9894009349916499,
    0.0271524594117541, 0.9894009349916499,
]

const GAUSS_LEGENDRE_COEFFS_32 = [
    0.0965400885147278, -0.0483076656877383,
    0.0965400885147278, 0.0483076656877383,
    0.0956387200792749, -0.1444719615827965,
    0.0956387200792749, 0.1444719615827965,
    0.0938443990808046, -0.2392873622521371,
    0.0938443990808046, 0.2392873622521371,
    0.0911738786957639, -0.3318686022821277,
    0.0911738786957639, 0.3318686022821277,
    0.0876520930044038, -0.4213512761306353,
    0.0876520930044038, 0.4213512761306353,
    0.0833119242269467, -0.5068999089322294,
    0.0833119242269467, 0.5068999089322294,
    0.0781938957870703, -0.5877157572407623,
    0.0781938957870703, 0.5877157572407623,
    0.0723457941088485, -0.6630442669302152,
    0.0723457941088485, 0.6630442669302152,
    0.0658222227763618, -0.7321821187402897,
    0.0658222227763618, 0.7321821187402897,
    0.0586840934785355, -0.7944837959679424,
    0.0586840934785355, 0.7944837959679424,
    0.0509980592623762, -0.8493676137325700,
    0.0509980592623762, 0.8493676137325700,
    0.0428358980222267, -0.8963211557660521,
    0.0428358980222267, 0.8963211557660521,
    0.0342738629130214, -0.9349060759377397,
    0.0342738629130214, 0.9349060759377397,
    0.0253920653092621, -0.9647622555875064,
    0.0253920653092621, 0.9647622555875064,
    0.0162743947309057, -0.9856115115452684,
    0.0162743947309057, 0.9856115115452684,
    0.0070186100094701, -0.9972638618494816,
    0.0070186100094701, 0.9972638618494816,
]

/**
 * @param {CubicBez} c
 * @param {number} d
 */
function CubicOffset(c, d) {
    /** @type {CubicBez} */
    this.c = c
    /** @type {number} */
    this.d = d
    /** @type {QuadBez} */
    this.q = c.deriv()
    const d0 = this.q.p0.clone()
    const d1 = this.q.p1.clone().sub(this.q.p0).scale(2)
    const d2 = this.q.p0.clone().sub(this.q.p1.clone().scale(2)).add(this.q.p2)
    this.c0 = d * d1.cross(d0)
    this.c1 = d * 2 * d2.cross(d0)
    this.c2 = d * d2.cross(d1)
}
CubicOffset.prototype = {
    constructor: CubicOffset,
    /**
     * @param {number} t
     * @returns {Vector2}
     */
    evalOffset(t) {
        const dp = this.q.eval(t)
        const norm = new Vector2(-dp.y, dp.x)
        const len = dp.length()
        if (len === 0) {
            return new Vector2(0, 0)
        }
        return norm.scale(this.d / dp.length())
    },

    /**
     * @param {number} t
     * @returns {Vector2}
     */
    eval(t) {
        return this.c.eval(t).add(this.evalOffset(t))
    },

    /**
     * @param {number} t
     * @returns {Vector2}
     */
    evalDeriv(t) {
        return this.q.eval(t).scale(this.cuspSign(t))
    },

    /**
     * @param {number} n
     * @returns {{ arclen: number, p: Vector2, d: Vector2}[]}
     */
    samplePoints(n) {
        const result = []
        let arclen = 0
        // Probably overkill, but keep it simple
        const co = GAUSS_LEGENDRE_COEFFS_32
        const dt = 1 / (n + 1)
        for (let i = 0; i < n; i++) {
            for (let j = 0; j < co.length; j += 2) {
                const t = dt * (i + 0.5 + 0.5 * co[j + 1])
                arclen += co[j] * this.evalDeriv(t).length()
            }
            const t = dt * (i + 1)
            const d = this.evalOffset(t)
            const p = this.c.eval(t).add(d)
            result.push({ arclen: arclen * 0.5 * dt, p: p, d: d })
        }
        return result
    },

    /**
     * @param {number} t
     * @returns {number}
     */
    cuspSign(t) {
        const ds2 = this.q.eval(t).length_squared()
        const sign = (ds2 === 0)
            ? Infinity
            : ((this.c2 * t + this.c1) * t + this.c0) / (ds2 * Math.sqrt(ds2))
        return sign + 1.0
    },

    /**
     * @param {number} t
     * @param {number} sign
     * @returns {CurveFitSample}
     */
    samplePointTangent(t, sign) {
        const p = this.eval(t)
        const CUSP_EPS = 1e-8
        let cusp = this.cuspSign(t)
        if (Math.abs(cusp) < CUSP_EPS) {
            cusp = sign * (this.cuspSign(t + CUSP_EPS) - this.cuspSign(t - CUSP_EPS))
        }
        const tangent = this.q.eval(t).scale(Math.sign(cusp))
        return new CurveFitSample(p, tangent)
    },

    /**
     * @param {number} t
     * @returns {{ p: Vector2, dp: Vector2 }}
     */
    samplePointDeriv(t) {
        return { p: this.eval(t), dp: this.evalDeriv(t) }
    },

    /**
     * @param {number} rangeStart
     * @param {number} rangeEnd
     * @returns {{ area: number, x: number, y: number }}
     */
    momentIntegrals(rangeStart, rangeEnd) {
        const t0 = 0.5 * (rangeStart + rangeEnd)
        const dt = 0.5 * (rangeEnd - rangeStart)
        let area = 0, x = 0, y = 0
        const co_e = GAUSS_LEGENDRE_COEFFS_16
        for (let i = 0; i < co_e.length; i += 2) {
            const [wi, xi] = [co_e[i], co_e[i + 1]]
            const t = t0 + xi * dt
            const { p, dp } = this.samplePointDeriv(t)
            const _a = wi * dp.x * p.y
            const _x = p.x * _a
            const _y =  p.y * _a
            area += _a
            x += _x
            y += _y
        }
        return { area: area * dt, x: x * dt, y: y * dt}
    },

    /**
     * @param {number} rangeStart
     * @param {number} rangeEnd
     * @returns {number | null}
     */
    breakCusp(rangeStart, rangeEnd) {
        const CUSP_EPS = 1e-8
        /**
         * When an endpoint is on (or very near) a cusp, move just far enough
         * away from the cusp that we're confident we have the right sign.
         * @param {number} x
         * @param {number} d
         */
        const break_cusp_help = (x, d) => {
            let cusp = this.cuspSign(x)
            while (Math.abs(cusp) < CUSP_EPS && d < 1.0) {
                // eslint-disable-next-line no-param-reassign
                x += d
                const old_cusp = cusp
                cusp = this.cuspSign(x)
                if (Math.abs(cusp) > Math.abs(old_cusp)) {
                    break
                }
                // eslint-disable-next-line no-param-reassign
                d *= 2.0
            }
            return [x, cusp]
        }
        const [a, cusp0] = break_cusp_help(rangeStart, 1e-12)
        const [b, cusp1] = break_cusp_help(rangeEnd, -1e-12)
        if (a >= b || cusp0 * cusp1 >= 0) {
            return null
        }
        const s = Math.sign(cusp1)
        const f = (t) => (s * this.cuspSign(t))
        const k1 = 0.2 / (b - a)
        const ITP_EPS = 1e-12
        return solveItp(f, a, b, ITP_EPS, 1, k1, s * cusp0, s * cusp1)
    },
}
/**
 * @param {CubicBez} c
 * @param {number} d
 * @param {number} dimension
 */
CubicOffset.regularized = (c, d, dimension) => (new CubicOffset(c.clone().regularize(dimension), d))

/**
 * @param {Vector2} p
 * @param {Vector2} tangent
 */
function CurveFitSample(p, tangent) {
    /** @type {Vector2} */
    this.p = p
    /** @type {Vector2} */
    this.tangent = tangent
}
CurveFitSample.prototype = {
    constructor: CurveFitSample,
    /**
     * @param {CubicBez} c
     * @returns {number[]}
     */
    intersect(c) {
        const p1 = c.getP1().clone()
            .sub(c.getP0()).scale(3)
        const p2 = c.getP2().clone().scale(3)
            .sub(c.getP1().clone().scale(6))
            .add(c.getP0().clone().scale(3))
        const p3 = c.getP3().clone()
            .sub(c.getP0())
            .sub(c.getP2().clone().sub(c.getP1()).scale(3))
        const c0 = c.getP0().clone()
            .sub(this.p)
            .dot(this.tangent)
        const c1 = p1.dot(this.tangent)
        const c2 = p2.dot(this.tangent)
        const c3 = p3.dot(this.tangent)
        const res = solveCubic(c0, c1, c2, c3)
        /** @type {number[]} */
        const results = []
        for (let i = 0; i < res.length; i++) {
            const t = res[i]
            if (t >=0 && t <= 1) {
                results.push(t)
            }
        }
        return results
    },
}

const N_SAMPLE = 20

/**
 * @param {CubicOffset} src
 * @param {number} rangeStart
 * @param {number} rangeEnd
 */
function CurveDist(src, rangeStart, rangeEnd) {
    /** @type {CurveFitSample[]} */
    this.samples = []
    /** @type {number[]} */
    this.arcparams = []
    /** @type {number} */
    this.rangeStart = rangeStart
    /** @type {number} */
    this.rangeEnd = rangeEnd
    /** @type {boolean} */
    this.spicy = false

    const step = (rangeEnd - rangeStart) * (1.0 / (N_SAMPLE + 1))
    let last_tan = null
    const SPICY_THRESH = 0.2
    for (let i = 0; i < N_SAMPLE + 2; i++) {
        const sample = src.samplePointTangent(rangeStart + i * step, 1.0)
        if (last_tan !== null) {
            const cross = sample.tangent.cross(last_tan)
            const dot = sample.tangent.dot(last_tan)
            if (Math.abs(cross) > SPICY_THRESH * Math.abs(dot)) {
                this.spicy = true
            }
        }
        if (sample.tangent) {
            last_tan = sample.tangent
        }
        if (i > 0 && i < N_SAMPLE + 1) {
            this.samples.push(sample)
        }
    }
}
CurveDist.prototype = {
    constructor: CurveDist,
    /**
     * @param {CubicOffset} src
     */
    computeArcParams(src) {
        const N_SUBSAMPLE = 10
        const [start, end] = [this.rangeStart, this.rangeEnd]
        const dt = (end - start) * (1.0 / ((N_SAMPLE + 1) * N_SUBSAMPLE))
        let arclen = 0
        for (let i = 0; i < N_SAMPLE + 1; i++) {
            for (let j = 0; j < N_SUBSAMPLE; j++) {
                const t = start + dt * ((i * N_SUBSAMPLE + j) + 0.5)
                arclen += src.samplePointDeriv(t).dp.length()
            }
            if (i < N_SAMPLE) {
                this.arcparams.push(arclen)
            }
        }
        const arclen_inv = 1 / arclen
        for (let x = 0; x < this.arcparams.length; x++) {
            this.arcparams[x] *= arclen_inv
        }
    },

    /**
     * Arclength of a cubic bezier curve
     * @param {CubicBez} c
     * @param {number} acc2 accuracy
     * @returns {number | null}
     */
    evalArc(c, acc2) {
        // TODO: this could perhaps be tuned.
        const EPS = 1e-9
        const c_arclen = c.arclen(EPS)
        let max_err2 = 0
        for (let i = 0; i < this.samples.length; i++) {
            const sample = this.samples[i]
            const s = this.arcparams[i]
            const t = c.invArclen(c_arclen * s, EPS)
            const err = sample.p.distance_squared_to(c.eval(t))
            max_err2 = Math.max(err, max_err2)
            if (max_err2 > acc2) {
                return null
            }
        }
        return max_err2
    },

    /**
     * Evaluate distance to a cubic approximation.
     * @param {CubicBez} c
     * @param {number | null} acc2
     */
    evalRay(c, acc2) {
        let max_err2 = 0
        for (let si = 0, slen = this.samples.length; si < slen; si++) {
            const sample = this.samples[si]
            let best = acc2 + 1
            const intersects = sample.intersect(c)
            for (let ti = 0, tlen = intersects.length; ti < tlen; ti++) {
                const t = intersects[ti]
                const err = sample.p.distance_squared_to(c.eval(t))
                best = Math.min(best, err)
            }
            max_err2 = Math.max(best, max_err2)
            if (max_err2 > acc2) {
                return null
            }
        }
        return max_err2
    },

    /**
     * @param {CubicOffset} src
     * @param {CubicBez} c
     * @param {number} acc2
     * @returns {number | null}
     */
    evalDist(src, c, acc2) {
        // Always compute cheaper distance, hoping for early-out.
        const ray_dist = this.evalRay(c, acc2)
        if (ray_dist === null) {
            return null
        }
        if (!this.spicy) {
            return ray_dist
        }
        if (this.arcparams.length === 0) {
            this.computeArcParams(src)
        }
        this.evalArc(c, acc2)
    },
}

/**
 * @param {Vector2} p0
 * @param {Vector2} p1
 * @param {Vector2} p2
 * @param {Vector2} p3
 */
function endpointTangents(p0, p1, p2, p3) {
    const EPS = 1e-12

    const d0 = new Vector2()
    const d1 = new Vector2()

    const d01 = p1.clone().sub(p0)
    if (d01.length_squared() > EPS) {
        d0.copy(d01)
    } else {
        const d02 = p2.clone().sub(p0)
        if (d02.length_squared() > EPS) {
            d0.copy(d02)
        } else {
            d0.copy(p3).sub(p0)
        }
    }

    const d23 = p3.clone().sub(p2)
    if (d23.length_squared() > EPS) {
        d1.copy(d23)
    } else {
        const d13 = p3.clone().sub(p1)
        if (d13.length_squared() > EPS) {
            d1.copy(d13)
        } else {
            d1.copy(p3).sub(p0)
        }
    }

    return [d0, d1]
}

/**
 * @param {BezierShape} shape
 * @param {number[]} dash_pattern
 * @param {boolean} round_line_caps
 */
export function buildDashedShape(shape, dash_pattern, round_line_caps) {
    // ignore dash segments without gap
    const pattern = (dash_pattern.length % 2)
        ? dash_pattern.slice(0, dash_pattern.length - 1)
        : dash_pattern

    const thresh = 1e-1
    // return the original shape when dash+gap are all zero
    if (pattern.every(v => v < thresh)) {
        return shape
    }
    for (let i = 0; i < pattern.length; i += 2) {
        if (pattern[i] < thresh) {
            if (pattern[i + 1] >= thresh && round_line_caps) {
                // assign a minimum value to dash to match Lottie behaviour
                pattern[i] = thresh
            } else {
                // ignore dash+gap when dash is almost 0
                pattern.splice(i, 2)
                i -= 2
            }
        }
    }

    const dashed_shapes = new BezierShape()

    if (pattern.length === 0) {
        return dashed_shapes
    }

    for (let i = 0, len = shape._children.length; i < len; i++) {
        const path = shape._children[i]
        if (path.isEmpty()) continue

        const curves = path.getCurves()
        let curve_idx = 0
        const curve_idx_max = curves.length - 1
        let length_remain = path.getLength()
        let last_curve = curves[curve_idx]?.clone()
        let dash_idx = 0
        let dash_len = pattern[dash_idx]
        while (length_remain > 0 && last_curve) {
            const is_dash = (dash_idx % 2) === 0

            if (!last_curve) {
                last_curve = curves[curve_idx++].clone()
            }
            const segs = is_dash ? [last_curve._segment1] : null

            let local_dash_len = dash_len
            let curve_len = last_curve.getLength()
            while (curve_len < local_dash_len) {
                if (is_dash) {
                    segs.push(last_curve._segment2)
                }

                local_dash_len -= curve_len

                if (curve_idx >= curve_idx_max) {
                    last_curve = null
                    break
                } else {
                    curve_idx += 1
                    last_curve = curves[curve_idx].clone()
                    curve_len = last_curve.getLength()
                    if (is_dash && last_curve) {
                        // Pop the currently pushed segment2 because we will get the segment2 from the new last_curve
                        segs.pop()
                    }
                }
            }

            // cut the last curve and insert end segment
            if (last_curve && local_dash_len > 0) {
                const t = CubicBez.getTimeAt(last_curve.getValues(), local_dash_len)
                const rest_curve = last_curve.divideAtTime(t, true)
                if (is_dash) {
                    segs.push(last_curve._segment2)
                }
                last_curve = rest_curve
            }

            // make a path for current dash
            if (is_dash && segs.length > 1) {
                const dash = new BezierPath()
                dash.setSegments(segs)

                dashed_shapes.addChild(dash)
            }

            // advance
            length_remain -= dash_len
            dash_idx = (dash_idx + 1) % pattern.length
            dash_len = pattern[dash_idx]
        }
    }

    return dashed_shapes
}


/**
 * @param {BezierShape} shape
 * @param {number[]} dash_pattern
 * @param {boolean} round_line_caps
 */
export function buildDashedPath(shape, dash_pattern, round_line_caps) {
    // ignore dash segments without gap
    const pattern = (dash_pattern.length % 2)
        ? dash_pattern.slice(0, dash_pattern.length - 1)
        : dash_pattern

    const thresh = 1e-1
    // return the original shape when dash+gap are all zero
    if (pattern.every(v => v < thresh)) {
        return shape
    }
    for (let i = 0; i < pattern.length; i += 2) {
        if (pattern[i] < thresh) {
            if (pattern[i + 1] >= thresh && round_line_caps) {
                // assign a minimum value to dash to match Lottie behaviour
                pattern[i] = thresh
            } else {
                // ignore dash+gap when dash is almost 0
                pattern.splice(i, 2)
                i -= 2
            }
        }
    }

    const dashed_shapes = new BezierShape()

    if (pattern.length === 0) {
        return dashed_shapes
    }

    for (let i = 0, len = shape._children.length; i < len; i++) {
        const path = shape._children[i]
        if (path.isEmpty()) continue

        const curves = path.getCurves()
        let curve_idx = 0
        const curve_idx_max = curves.length - 1
        let length_remain = path.getLength()
        let last_curve = curves[curve_idx]?.clone()
        let dash_idx = 0
        let dash_len = pattern[dash_idx]
        while (length_remain > 0 && last_curve) {
            const is_dash = (dash_idx % 2) === 0

            if (!last_curve) {
                last_curve = curves[curve_idx++].clone()
            }
            const segs = is_dash ? [last_curve._segment1] : null

            let local_dash_len = dash_len
            let curve_len = last_curve.getLength()
            while (curve_len < local_dash_len) {
                if (is_dash) {
                    segs.push(last_curve._segment2)
                }

                local_dash_len -= curve_len

                if (curve_idx >= curve_idx_max) {
                    last_curve = null
                    break
                } else {
                    curve_idx += 1
                    last_curve = curves[curve_idx].clone()
                    curve_len = last_curve.getLength()
                    if (is_dash && last_curve && (segs[0].hasHandles() || segs[1].hasHandles())) {
                        // Pop the currently pushed segment2 because we will get the segment2 from the new last_curve
                        segs.pop()
                    }
                }
            }

            // cut the last curve and insert end segment
            if (last_curve && local_dash_len > 0) {
                const t = CubicBez.getTimeAt(last_curve.getValues(), local_dash_len)
                const rest_curve = last_curve.divideAtTime(t, false)
                if (is_dash) {
                    segs.push(last_curve._segment2)
                }
                last_curve = rest_curve
            }

            // make a path for current dash
            if (is_dash && segs.length > 1) {
                const dash = new BezierPath()
                dash.setSegments(segs)

                dashed_shapes.addChild(dash)
            }

            // advance
            length_remain -= dash_len
            dash_idx = (dash_idx + 1) % pattern.length
            dash_len = pattern[dash_idx]
        }
    }

    return dashed_shapes
}
