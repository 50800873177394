import React, { useRef, useState, useEffect } from 'react'

export type ToggleProps = {
  name: string
  checked: boolean
  disabled?: boolean
  onChange?: () => void
}

const Toggle = ({ name, checked, disabled = false, onChange }: ToggleProps) => {
  const bg = checked ? 'bg-primary-50' : 'bg-light-overlay-10'
  const hoverBg = checked ? 'hover:bg-primary-40' : 'hover:bg-light-overlay-15'
  const activeBg = checked ? 'active:bg-primary-60' : 'active:bg-light-overlay-5'
  const inputRef = useRef<HTMLInputElement>(null)
  const [focusedOutline, setFocusedOutline] = useState('')

  const nextFocusedOutlineStyle = checked ? 'outline-primary-1-offset-2' : 'outline-primary-1'

  const handleOnChange = () => {
    if (inputRef.current) {
      inputRef.current.blur()
    }
    onChange?.()
  }

  const handleFocusChange = () => {
    if (inputRef.current === document.activeElement) {
      setFocusedOutline(nextFocusedOutlineStyle)
    }
  }

  const handleBlur = () => {
    setFocusedOutline('')
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    const target = e.target as HTMLElement
    if (e.key === ' ' || e.key === 'Enter') {
      e.preventDefault()
      target.click()
      target.focus()
    } else if (e.key === 'Escape') {
      console.log('here')
      target.blur()
    }
  }

  useEffect(() => {
    if (inputRef.current === document.activeElement) setFocusedOutline(nextFocusedOutlineStyle)
  }, [checked, setFocusedOutline, nextFocusedOutlineStyle])

  return (
    <label className={`${disabled ? 'opacity-40' : ''} rounded-10`} onKeyDown={handleKeyDown}>
      <input
        ref={inputRef}
        className="absolute opacity-0 cursor-auto w-0 h-0"
        type="checkbox"
        tabIndex={0}
        name={name}
        checked={checked}
        disabled={disabled}
        onChange={handleOnChange}
        onFocus={handleFocusChange}
        onBlur={handleBlur}
      />
      <div
        className={`w-28 h-16 rounded-10 ${bg} ${hoverBg} ${activeBg} ${focusedOutline} ${
          disabled ? 'cursor-not-allowed' : 'cursor-pointer'
        }`}
      >
        <div
          className={`relative top-2 left-2 w-12 h-12 bg-white rounded-circle transition duration-300 transform ${
            checked ? 'translate-x-12' : 'translate-x-0'
          }`}
        />
      </div>
    </label>
  )
}

export default Toggle
