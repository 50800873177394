import { EventEmitter } from 'eventemitter3'
import { Vector2, Rect2, rectsIntersect } from '../math'

/** @typedef {import('../math/Transform2D').Transform2D} Transform2D */
/** @typedef {import("../math/Vector2").Vector2Like} Vector2Like */

export class Bounds extends EventEmitter {
    constructor() {
        super()

        this.rect = new Rect2()
        // bounds in local space (transformed by local transform)
        this.local = new Rect2()
        this.world = new Rect2()

        this.visual = new Rect2()
        this.visualWorld = new Rect2()

        this._data = {
            size: new Vector2(),
        }
        this._dirty = true
    }

    get calcFromContent() {
        return this._data.size === null
    }

    reset() {
        this.rect.set(0, 0, 0, 0)
        this.world.set(0, 0, 0, 0)

        this.visual.set(0, 0, 0, 0)
        this.visualWorld.set(0, 0, 0, 0)

        if (this._data.size) {
            this._data.size.set(0, 0)
        } else {
            this._data.size = new Vector2(0, 0)
        }
        this._dirty = true

        return true
    }

    recalculateFromContent() {
        if (this.calcFromContent) {
            this._dirty = true
        }
    }

    /**
     * @param {Vector2Like|null} size
     */
    setSize(size) {
        if (size === null) {
            this._data.size = null
        } else {
            this._data.size.copy(size)
        }
        this._dirty = true
    }

    updateLocal(transform) {
        transform.xform_rect(this.rect, this.local)
        this.emit('updateLocal')
    }

    /**
     * @param {Transform2D} transform
     */
    updateWorld(transform) {
        transform.xform_rect(this.rect, this.world)
        transform.xform_rect(this.visual, this.visualWorld)
        this.emit('updateWorld')
    }

    /**
     * @param {Vector2} p
     * @param {Transform2D} transform
     * @returns {boolean}
     */
    containsPoint(p, transform) {
        const _p = transform.xform(p)
        const out = this.rect.has_point(_p)
        return out
    }

    /**
     * @param {Rect2} rect
     * @param {Transform2D} transform
     * @returns {boolean}
     */
    intersectWith(rect, transform) {
        const [clt, clb, crt, crb] = getEndPointsFromRect(this.rect)
        const selectRect = transform.xform_rect(rect)
        const [nlt, nlb, nrt, nrb] = getEndPointsFromRect(selectRect)

        const hasIntersect = rectsIntersect(
            crt, clt, crb, clb,
            nrt, nlt, nrb, nlb
        )

        return hasIntersect
    }
}

const getEndPointsFromRect = (rect) => {
    const lt = new Vector2(rect.x, rect.y)
    const lb = new Vector2(rect.x, rect.y + rect.height)
    const rt = new Vector2(rect.x + rect.width, rect.y)
    const rb = new Vector2(rect.x + rect.width, rect.y + rect.height)
    return [lt, lb, rt, rb]
}
