import { debounce } from 'lodash'

import { Image } from '@phase-software/data-store'
// @ts-ignore
import { Events } from '@phase-software/data-store/src/Eam/constants'
// @ts-ignore
import { BaseChange, EntityChange, Mesh, PropChange, parseSceneTreeChanges } from '@phase-software/data-utils'
import { ElementData, ElementType, EntityType, EventFlag, GeometryType, Mode, PropKey } from '@phase-software/types'

import { uploadContent } from '../services/nats'
import { unzipContent, zipContent } from '../utils/file'
import Broadcaster, { BroadcasterMessageData, BroadcasterOptions } from './Broadcaster'
import {
  ParsedChange,
  ParsedSceneTreeChange,
  ParsedUndoEvent,
  ParsedUndoEventPayload,
  PropertyChange,
  SpecificChange,
  UndoEvent,
  UndoEventGroup,
  UndoEventPayload
} from './FileProcessor.types'
import MeshChangeHandler from './MeshChangeHandler'
import { replacer, reviver } from './utils'

const DELETE_KEY = '_'

export type FileMessageBasicData = {
  type: 'file'
  fileId: string
  protocol: string
  data: any
}

type FileChangeMessageData = FileMessageBasicData & { type: 'file'; data: string; event: string }

type FileCallbackMessageData = FileChangeMessageData & BroadcasterMessageData

type FileProcessorMeta = {
  fileId: string
  projectId: string
}

class FileProcessor {
  broadcaster: Broadcaster
  dataStore: any
  subject: string
  options: { saveEntireFile: boolean }
  meta: any

  _isDrawing: boolean
  _pathQueue: any[]
  _pathData: any
  _enqueueUpload: boolean
  _uploading: boolean

  debouncedUpload: any

  meshChangeHandler: MeshChangeHandler

  // FIXME: options is only for debugging and will be remove after BE partial save is stable
  constructor(broadcaster: Broadcaster, dataStore: any, options: BroadcasterOptions) {
    this.broadcaster = broadcaster
    this.dataStore = dataStore
    this.subject = broadcaster.subject

    // FIXME: temporary solution
    this.options = {
      saveEntireFile: options?.saveEntireFile ?? true
    }

    this.meta = {}
    this.dataStore.on('UNDO', this.handleUndo)

    // draw path stats
    this._isDrawing = false

    // FIXME: considering not cache path data and just fire event
    this._pathQueue = []
    this._pathData = null

    this._enqueueUpload = false
    this._uploading = false
    this.debouncedUpload = debounce(uploadContent, 300, { leading: true })

    const handleUnload = (e: BeforeUnloadEvent) => {
      if (this._uploading || this._enqueueUpload) {
        e.preventDefault()
        e.returnValue = ''
      }
    }

    window.addEventListener('beforeunload', handleUnload)

    this._onEAMEvents()
    this.meshChangeHandler = new MeshChangeHandler(dataStore)
  }

  setMeta(meta: FileProcessorMeta) {
    this.meta = meta
  }

  clearMeta() {
    this.meta = {}
  }

  _handleStartDrawPath = () => {
    this._setIsDrawing(true)
  }

  _handleEndDrawPath = () => {
    this._setIsDrawing(false)
    this._popPathQueueBySelection()
  }

  _handleStarDrawPathOnVertex = () => {
    this._setIsDrawing(true)
  }

  _handleEndDrawPathOnVertex = () => {
    this._setIsDrawing(false)
    this._popPathQueueBySelection()
  }

  _handleStartDrawPathOnEdge = () => {
    this._setIsDrawing(true)
  }

  _handleEndDrawPathOnEdge = () => {
    this._setIsDrawing(false)
    this._popPathQueueBySelection()
  }

  _onEAMEvents() {
    // for draw path event
    this.dataStore.eam
      .on(Events.START_DRAW_PATH, this._handleStartDrawPath)
      .on(Events.END_DRAW_PATH, this._handleEndDrawPath)
      .on(Events.START_DRAW_PATH_ON_VERTEX, this._handleStarDrawPathOnVertex)
      .on(Events.END_DRAW_PATH_ON_VERTEX, this._handleEndDrawPathOnVertex)
      .on(Events.START_DRAW_PATH_ON_EDGE, this._handleStartDrawPathOnEdge)
      .on(Events.END_DRAW_PATH_ON_EDGE, this._handleEndDrawPathOnEdge)
  }

  _offEAMEvents() {
    // for draw path event
    this.dataStore.eam
      .off(Events.START_DRAW_PATH, this._handleStartDrawPath)
      .off(Events.END_DRAW_PATH, this._handleEndDrawPath)
      .off(Events.START_DRAW_PATH_ON_VERTEX, this._handleStarDrawPathOnVertex)
      .off(Events.END_DRAW_PATH_ON_VERTEX, this._handleEndDrawPathOnVertex)
      .off(Events.START_DRAW_PATH_ON_EDGE, this._handleStartDrawPathOnEdge)
      .off(Events.END_DRAW_PATH_ON_EDGE, this._handleEndDrawPathOnEdge)
  }

  onMessage = async (message: FileCallbackMessageData) => {
    const { event, data } = message
    const decodedEvent = await unzipContent(event)
    const decodedData = await unzipContent(data)
    this.receive(JSON.parse(decodedEvent, reviver), JSON.parse(decodedData, reviver))

    // FIXME: use better way to sync base value to TM and CS
    if (this.dataStore.isActionMode) {
      const isPlaying = this.dataStore.transition.status === 'START'
      this.dataStore.set('mode', Mode.DESIGN)
      this.dataStore.set('mode', Mode.ACTION)
      if (isPlaying) {
        this.dataStore.transition.play()
      }
    }

    // FIXME: not use private function, or create a force update interface to use
    this.dataStore.editor._resetLayerItemMap()
    this.dataStore.editor._resetEffectItemMap()
    this.dataStore.editor.reloadPropertiesAndStates(new Set(['mode']))
  }

  // FIXME: hide implementation details
  receive = (changeList: SpecificChange[], data: UndoEventPayload) => {
    // load data
    Object.entries(data.library).forEach(([entityId, entity]) => {
      if (entityId === DELETE_KEY) {
        return
      }
      if (!this.dataStore.library.getComponent(entityId)) {
        this.dataStore.library.load({ [entityId]: entity })
      }
    })
    const { interaction } = this.dataStore
    Object.entries(data.interaction).forEach(([entityId, entity]) => {
      if (entity && !interaction.deletedMap.has(entityId)) {
        const newEntity = interaction._load(entity)
        interaction.deletedMap.set(entityId, newEntity)
      }
    })

    for (const change of changeList) {
      switch (change.event) {
        case 'IMAGE_CHANGES': {
          const { images } = this.dataStore
          change.payload.CREATE.forEach((imageId: string) => {
            const imageData = data.images[imageId]
            if (imageData) {
              images.deleted.set(imageId, new Image(imageData))
            }
          })
          change.payload.DELETE.forEach((imageId: string) => {
            const imageData = data.images[imageId]
            if (imageData) {
              images.images.set(imageId, new Image(imageData))
            }
          })
          this.dataStore.images.redo(change.payload)
          break
        }
        case 'LIBRARY_CHANGES': {
          this.dataStore.library.redo(change.payload)
          break
        }
        case 'SCENE_TREE_CHANGES': {
          const workspace = this.dataStore.workspace.watched

          const deselectRemovedElements = data.element[DELETE_KEY].map((elId: string) =>
            this.dataStore.getElement(elId)
          )
          this.dataStore.selection.removeElements(deselectRemovedElements, { undoable: false, commit: false })

          // FIXME: flatten scene tree
          Object.entries(data.element).forEach(([elementId, elementData]) => {
            if (elementId === DELETE_KEY) return

            const queue = [elementData]
            while (queue.length) {
              const elementData = queue.shift() as ElementData
              if ('children' in elementData) {
                queue.push(...elementData.children)
              }
              if (!workspace.elements.has(elementData.id)) {
                // skip children before redo event to ensure the data exist
                // @ts-ignore
                const { children = [], ...newElementData } = elementData
                const isNewElement = children.every((child: ElementData) => !workspace.elements.has(child.id))
                const element = this.dataStore.createElement(
                  newElementData.elementType,
                  isNewElement ? elementData : newElementData
                )
                workspace.mapElements([element])
              }
            }
          })

          workspace.redo('SCENE_TREE_CHANGES', change.payload)

          break
        }
        case 'INTERACTION_CHANGES': {
          // @ts-ignore
          this.dataStore.interaction.redo(new EntityChange(change.payload), { flag: EventFlag.FROM_DATA_SYNC })
          const removeSelectionKfs = this.dataStore.selection
            .get('kfs')
            .filter((kfId: string) => change.payload.DELETE.has(kfId))
          this.dataStore.selection.removeKFs(removeSelectionKfs)
          break
        }
        case 'MESH_CHANGES': {
          const elementId = change.ownerId
          const element = this.dataStore.getElement(elementId)
          const meshData = data.element[elementId].geometry.mesh
          const mesh = element.get('mesh')
          mesh.vertices.clear()
          mesh.edges.clear()
          mesh.contours.clear()
          mesh.cellTable.clear()
          Mesh.addFromData(mesh, meshData)
          element.cachePathBaseValue(true)
          element.emit('TRIGGER_VECTOR_FORCE_UPDATE')
          break
        }
        // element change
        case 'CHANGES': {
          if (change.ownerType === 'Element') {
            const element = this.dataStore.getElement(change.ownerId)

            // FIXME: reset the geometryType is for the Setter flow to update the CHANGES for UI & Render in undo
            if (change.payload.has('geometryType')) {
              const geometryType = element.get('geometryType')
              if (geometryType !== GeometryType.POLYGON) {
                const geometry = element.get('geometry').watched
                geometry._unbindMeshChanges()
                geometry.data.mesh = Mesh.fromData(data.element[change.ownerId].geometry.mesh)
                geometry._bindMeshChanges()
              }
            }
            element.redo('CHANGES', change.payload)
          }

          break
        }
      }
    }
  }

  _setIsDrawing(isDrawing: boolean) {
    this._isDrawing = isDrawing
  }

  clearPathQueue() {
    this._pathQueue = []
    this._pathData = null
  }

  /*
   * @param {Path} selectedPath
   * */
  _checkIsEdgeFinished(mesh: Mesh) {
    if (mesh) {
      return mesh.edges.size > 0
    } else {
      return false
    }
  }

  async _popPathQueueBySelection() {
    // assume in drawing mode, only one path is selected one time
    const selectedPath = this.dataStore.selection.get('elements')[0] // with circular reference structure

    const pathCached = this._pathQueue.find((eventData) => {
      if (eventData && eventData.event === 'SCENE_TREE_CHANGES') {
        return true
      }
    })
    if (pathCached) {
      const pathElementId = selectedPath.data.id
      const latestUpdatedPath = selectedPath.save()
      this._pathData.element[pathElementId] = latestUpdatedPath
    }

    if (this._pathQueue.length) {
      await this.sendEventToServer(this._pathQueue, this._pathData)
      this.clearPathQueue()
    }
  }

  uploadContentToServer() {
    const { projectId, fileId } = this.meta

    if (!projectId || !fileId) {
      return
    }

    if (this._uploading) {
      this._enqueueUpload = true
    } else {
      const content = this.dataStore.save()
      this._uploading = true
      this.debouncedUpload({ projectId, fileId, content }).finally(() => {
        this._uploading = false
        if (this._enqueueUpload) {
          this._enqueueUpload = false
          this.uploadContentToServer()
        }
      })
    }
  }

  async sendEventToServer(events: ParsedUndoEventPayload[], data: UndoEventPayload) {
    const [base64Event, base64Data] = await Promise.all([
      zipContent(JSON.stringify(events, replacer), 'base64'),
      zipContent(JSON.stringify(data, replacer), 'base64')
    ])

    const message: FileChangeMessageData = {
      data: base64Data,
      event: base64Event,
      fileId: this.meta.fileId,
      protocol: 'v3',
      type: 'file'
    }
    this.broadcaster.sendMessage(message, this.subject)

    if (this.options.saveEntireFile) {
      this.uploadContentToServer()
    }
  }

  filterEvent = (changeEvent: UndoEvent) => {
    const blockedEventSet = new Set(['SELECT', 'SELECT_CELL'])
    if (blockedEventSet.has(changeEvent.type)) {
      return false
    }
    if (changeEvent.owner === this.dataStore) {
      return false
    }
    return true
  }

  _initUndoEventPayload = () => {
    const collectionNames = ['element', 'library', 'interaction', 'image']
    return collectionNames.reduce((payload: UndoEventPayload, collectionName) => {
      payload[collectionName] = {
        [DELETE_KEY]: [] // deleted list
      }
      return payload
    }, {})
  }

  handleUndo = (undo: UndoEventGroup, inUndo?: boolean) => {
    const payload = this._initUndoEventPayload()

    // skip some events
    const events = undo.events
      .filter(this.filterEvent)
      .map((undoEvent) => this.parseEventPayload(undoEvent, payload))
      .filter((parsedEventPayload: ParsedUndoEventPayload) => parsedEventPayload?.payload)

    // FIXME: find another way to handle the undo bulk scene tree changes event
    // FIXME: multi screen will have issue
    if (Object.keys(payload.element).length > 2) {
      const workspace = this.dataStore.workspace.watched
      const screen = workspace.children[0]
      payload.element = {
        [DELETE_KEY]: payload.element[DELETE_KEY],
        [screen.get('id')]: screen.save()
      }
    }

    if (inUndo) {
      events.reverse()
    }

    if (events.length > 0) {
      // queue events when pan tool draw first vertex
      if (this._isDrawing) {
        this._pathQueue = events
        this._pathData = payload
        return
      }

      // this.meta may be cleared from outside
      if (Object.keys(this.meta).length === 0) return

      this.sendEventToServer(events, payload)
    }
  }

  _prepareDataForChangesAndMesh = (event: UndoEvent['event'], owner: UndoEvent['owner'], payload: UndoEventPayload) => {
    if (owner.get('type') !== EntityType.ELEMENT) {
      return null
    }

    const ownerId = owner.data.id
    const ownerType = 'Element'
    const element = this.dataStore.getElement(ownerId)

    const elementData = element.save()
    // @ts-ignore
    const keysIterator = event.keys()

    let item = keysIterator.next()
    while (!item.done) {
      const key = item.value
      // geometry type is not in element data, but it will be trigger when converting
      // the Rect/Oval to Polygon
      if (key in elementData || key === 'geometryType') {
        payload.element[ownerId] = elementData
        return {
          payload: event,
          ownerId,
          ownerType
        }
      }
      item = keysIterator.next()
    }
    return null
  }

  parseEventPayload = (event: UndoEvent, payload: UndoEventPayload): ParsedUndoEventPayload => {
    const eventPayload = this.parseEvent(event, payload)
    return {
      ...eventPayload,
      event: event.type
    }
  }

  parsePropChange = (propChange: PropertyChange) => {
    return Array.from(propChange.entries()).reduce<Record<PropKey, ParsedChange>>((propMap, [propKey, change]) => {
      // only for Lib
      propMap[propKey] = this.parseChange(change)
      return propMap
    }, {})
  }

  parseChange = (change: BaseChange): ParsedChange => {
    const { before, after } = change
    return { before, after }
  }

  parseSceneTreeData(owner: UndoEvent['owner'], event: UndoEvent['event'], payload: UndoEventPayload) {
    const { added, removed, moved, reordered, oldParents, newParents }: ParsedSceneTreeChange =
      parseSceneTreeChanges(event)

    // FIXME: flatten scene tree
    added.forEach((elementId) => {
      const parent = owner.getElement(elementId).get('parent')
      if (parent) {
        const parentId = parent.get('id')
        if (!payload.element[parentId]) {
          payload.element[parentId] = parent.save()
        }
      }
    })

    // FIXME: flatten scene tree
    moved.forEach((elementId) => {
      const parent = owner.getElement(elementId).get('parent')
      if (parent) {
        const parentId = parent.get('id')
        if (!payload.element[parentId] && !added.has(parentId)) {
          payload.element[parentId] = parent.save()
        }
      }
    })

    // FIXME: flatten scene tree
    reordered.forEach((elementId) => {
      const parent = owner.getElement(elementId).get('parent')
      if (parent) {
        const parentId = parent.get('id')
        if (!payload.element[parentId]) {
          payload.element[parentId] = parent.save()
        }
      }
    })

    // FIXME: flatten scene tree
    removed.forEach((elementId) => {
      const element = this.dataStore.getElement(elementId)
      const queue = [element]
      while (queue.length) {
        const target = queue.shift()
        if (target.children) {
          queue.push(...target.children)
        }
        payload.element[DELETE_KEY].push(target.get('id'))
      }
    })

    // FIXME: flatten scene tree 😡
    // when remove last element from Container/Screen
    if (!newParents.size && oldParents.size) {
      oldParents.forEach((parentId) => {
        const parent = owner.getElement(parentId)
        payload.element[parentId] = parent.save()
      })
    }
  }

  // FIXME: hide implementation details
  parseEvent = ({ owner, event, type }: UndoEvent, payload: UndoEventPayload): ParsedUndoEvent => {
    if (event instanceof EntityChange) {
      // parse el data
      switch (type) {
        case 'IMAGE_CHANGES': {
          // FIXME: BE required FE to send entire records to save the file
          payload.images = owner.save()
          break
        }
        case 'SCENE_TREE_CHANGES': {
          this.parseSceneTreeData(owner, event, payload)
          break
        }
        case 'LIBRARY_CHANGES': {
          payload.library[DELETE_KEY].push(...event.DELETE)

          Array.from(event.CREATE).reduce((entityMap, entityId) => {
            const component = owner.getComponent(entityId)

            if (component) {
              entityMap[entityId] = owner.getComponent(entityId).save()
            }

            return entityMap
          }, payload.library)

          Array.from(event.UPDATE.entries()).reduce((entityMap, [entityId]) => {
            const component = owner.getComponent(entityId)

            if (component) {
              entityMap[entityId] = component.save()
            }

            return entityMap
          }, payload.library)

          break
        }
        case 'INTERACTION_CHANGES': {
          payload.interaction[DELETE_KEY].push(...event.DELETE)

          Array.from(event.CREATE).reduce((entityMap, entityId) => {
            const entity = owner.getEntity(entityId)

            if (entity) {
              entityMap[entityId] = this.dataStore.interaction._saveEntity(entity)
            }

            return entityMap
          }, payload.interaction)

          Array.from(event.UPDATE.entries()).reduce((entityMap, [entityId]) => {
            // an entity may be deleted by other tabs while dragging
            // need to check the interaction manager has the entity before saving it
            const entity = owner.getEntity(entityId)

            if (!payload.interaction[DELETE_KEY].includes(entityId) && entity) {
              entityMap[entityId] = this.dataStore.interaction._saveEntity(entity)
            }

            return entityMap
          }, payload.interaction)

          break
        }
        case 'MESH_CHANGES': {
          const dataResult = this.meshChangeHandler.onParseMeshChange({ owner, event })
          const path = this.dataStore.selection.get('elements')[0]

          // event be fired when copy & paste with Path element, but it's own container
          // should be included in the SCENE_TREE_CHANGES
          if (path && path.get('elementType') === ElementType.PATH) {
            payload.element[path.get('id')] = path.save()
          }

          // FIXME: consistency with other event
          return dataResult ?? {}
        }
      }
    } else if (event instanceof PropChange) {
      return {
        payload: this.parsePropChange(event)
      }
    } else if (type === 'CHANGES') {
      return this._prepareDataForChangesAndMesh(event, owner, payload)
    }
    return { payload: event }
  }

  clear() {
    if (this.dataStore) {
      this._offEAMEvents()

      this.dataStore.off('UNDO', this.handleUndo)
      this.dataStore = null
    }
  }
}

export default FileProcessor
