import React from 'react'
import { PaintSlider, PaintSliderProps } from '../../shared'
interface AlphaPickerProps extends PaintSliderProps {
  color: string
}

const AlphaPicker = ({ color, ...props }: AlphaPickerProps) => {
  return <PaintSlider {...props} colors={['transparent', color]} min={0} max={1} step={0.01} />
}

export default React.memo(AlphaPicker)
