/**
 * Convert color value to RGBA in [0,255]
 * @param {object} color - rgba color in [0,1]
 * @param {number} color.0
 * @param {number} color.1
 * @param {number} color.2
 * @param {number} color.3
 * @returns {string} CSS RGB value string
 */
export const convertColorToRGBA = ([r, g, b, a]) => {
  return `rgba(${[r, g, b].map((c) => Math.round(c * 255)).join()},${a})`
}

/**
 * Convert color value to RGB in [0,255]
 * @param {object} color - rgb color in [0,1]
 * @param {number} color.0
 * @param {number} color.1
 * @param {number} color.2
 * @returns {string} CSS RGBA value string
 */
export const convertColorToRGB = ([r, g, b]) => {
  return convertColorToRGBA([r, g, b, 1])
}

export const stopsToRgbaString = (stops) =>
  stops
    .slice()
    .sort((a, b) => a.position - b.position)
    .map(({ color, position }) => `${convertColorToRGBA(color)} ${position * 100}%`)
    .join(',')
