import React, { useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { LayerType, Mode } from '@phase-software/types'

import { useScrollIntoView } from '../../../../providers/ScrollProvider/useScrollIntoView'
import { useDataStoreActions } from '../../../../providers/dataStore/DataStoreProvider'
import { useEditor, useEditorActions } from '../../../../providers/dataStore/EditorProvider'
import { useStroke } from '../../../../providers/dataStore/StrokeProvider'
import { useUI } from '../../../../providers/dataStore/UIProvider'
import { WideTitle } from '../../../shared'
import Content from '../../../shared/Content'
import MixContent from '../MixContent'
import Stroke from './Stroke'

const StrokeEditor = () => {
  const ref = useRef<HTMLDivElement>(null)
  const mode = useUI((o) => o.mode)
  const editor = useEditor()
  const strokeList = useMemo(() => editor.strokeList || [], [editor.strokeList])
  const firstStrokeId = strokeList[0]
  const firstStroke = useStroke((o) => o[firstStrokeId])
  const isMix = firstStroke?.isMix
  const notEditable = !!isMix && mode !== Mode.DESIGN
  const { addLayer } = useEditorActions()
  const { commitUndo } = useDataStoreActions()
  const { activateScroll, handleAutoScroll } = useScrollIntoView()
  const { t } = useTranslation('file', { keyPrefix: 'property_editor.stroke_editor' })

  const handleAdd = () => {
    addLayer(LayerType.STROKE)
    commitUndo()
    activateScroll()
  }

  useEffect(() => {
    handleAutoScroll(ref?.current?.children[1])
  }, [strokeList, handleAutoScroll])

  return (
    <>
      <WideTitle
        icons={[{ name: 'Plus', dataTestId: 'border-plus-icon', onClick: handleAdd }]}
        disabled={notEditable}
        dividerPosition="top"
      >
        {t('title')}
      </WideTitle>
      <Content ref={ref} withHeader>
        {isMix ? (
          <MixContent notEditable={notEditable} />
        ) : (
          strokeList
            .slice()
            .reverse()
            .map((id: string, idx: number, arr: string[]) => (
              <Stroke
                key={idx}
                id={id}
                showBottomBorder={arr.length > 1 && idx !== arr.length - 1}
                isFirst={idx === 0}
                isLast={idx === arr.length - 1}
              />
            ))
        )}
      </Content>
    </>
  )
}

export default StrokeEditor
