import { ChangeType } from '@phase-software/types'

/**
 * @typedef {string} PropKey
 */
/**
 * @typedef {string} ID
 */

const CHANGE_KEYS = ['before', 'after', 'index', 'toIndex', 'fromIndex']


/**
 * @typedef {object}  Change
 * @property {any}    before
 * @property {any}    after
 * @property {number} index
 * @property {number} fromIndex
 * @property {number} toIndex
 */
export class Change {
    constructor(data) {
        let key
        for (key of CHANGE_KEYS) {
            if (key in data) {
                this[key] = data[key]
            }
        }
    }
}

/**
 * @typedef {Map<PropKey, Change>} PropChange
 */
export class PropChange extends Map {
    constructor(iterable) {
        super(iterable)
        this.type = ChangeType.PROPERTY
    }

    update(propName, change) {
        if (!(change instanceof Change)) {
            throw Error('Expect class: Change')
        }
        if (this.has(propName)) {
            const oriChange = this.get(propName)
            change.before = oriChange.before
        }
        return this.set(propName, change)
    }

    isEmpty() {
        return this.size === 0
    }
}

/**
 * @typedef {object} EntityChange
 * @property {Set<ID>}             CREATE
 * @property {Map<ID, PropChange>} UPDATE
 * @property {Set<ID>}             DELETE
 */
export class EntityChange {
    constructor({ CREATE, UPDATE, DELETE } = {}) {
        this.type = ChangeType.ENTITY
        this.CREATE = new Set(CREATE)
        this.UPDATE = new Map(UPDATE)

        this.UPDATE.forEach((propChange, key) => {
            this.UPDATE.set(key, new PropChange(propChange))
        })
        this.DELETE = new Set(DELETE)
    }

    create(idList) {
        let id
        for (id of idList) {
            this.CREATE.add(id)
        }
    }

    update(id, propName, change) {
        if (!this.UPDATE.has(id)) {
            this.UPDATE.set(id, new PropChange())
        }
        const propChange = this.UPDATE.get(id)
        propChange.update(propName, change)
    }

    delete(idList) {
        let id
        for (id of idList) {
            this.DELETE.add(id)
        }
    }

    removeFromCreate(idList) {
        let id
        for (id of idList) {
            this.CREATE.delete(id)
        }
    }

    isEmpty() {
        return this.CREATE.size === 0 && this.DELETE.size === 0 && this.UPDATE.size === 0
    }

    clear() {
        this.CREATE.clear()
        this.UPDATE.clear()
        this.DELETE.clear()
    }
}

/**
 * @param     {Change} change
 * @returns   {Change}
 */
export function reverseChange(change) {
    const { before, after, index, fromIndex, toIndex } = change
    return new Change({
        before: after,
        after: before,
        index,
        fromIndex: toIndex,
        toIndex: fromIndex
    })
}

/**
 * @param     {PropChange} propChange
 * @returns   {PropChange}
 */
export function reversePropChange(propChange) {
    return new PropChange(
        [...propChange.entries()].map(([propKey, change]) => [
            propKey,
            reverseChange(change)
        ])
    )
}


/**
 * @param     {EntityChange} entityChange
 * @returns   {EntityChange}
 */
export function reverseEntityChange(entityChange) {
    const reversedUpdateMap = new Map()
    entityChange.UPDATE.forEach((propChange, id) => {
        reversedUpdateMap.set(
            id,
            reversePropChange(propChange)
        )
    })
    return new EntityChange({
        CREATE: new Set([...entityChange.DELETE].reverse()),
        DELETE: new Set([...entityChange.CREATE].reverse()),
        UPDATE: reversedUpdateMap
    })
}

/**
 * Combine two Change objects (current into original)
 * @param {Change} original 
 * @param {Change} current
 * @returns {bool}   true 
 */
export function combineChange(original, current) {
    original.after = current.after
    if (current.toIndex) {
        original.toIndex = current.toIndex
    }
    return true
}

/**
 * Combine two PropChange objects (current into original)
 * @param {PropChange} original 
 * @param {PropChange} current
 * @returns {bool}   true
 */
export function combinePropChange(original, current) {
    let prop
    for (prop of current.keys()) {
        if (original.has(prop)) {
            combineChange(original.get(prop), current.get(prop))
        } else {
            original.set(prop, current.get(prop))
        }
    }
    return true
}

/**
 * Combine two EntityChange objects (current into original)
 * @param {EntityChange} original 
 * @param {EntityChange} current 
 * @returns {bool}   true
 */
export function combineEntityChange(original, current) {
    let entity
    // TODO: figure out if this is correct to combine events that create or delete things ?
    for (entity of current.CREATE) {
        original.CREATE.add(entity)
        original.DELETE.delete(entity)
    }
    for (entity of current.DELETE) {
        original.DELETE.add(entity)
        original.CREATE.delete(entity)
    }
    for (entity of current.UPDATE.keys()) {
        if (original.UPDATE.has(entity)) {
            combinePropChange(original.UPDATE.get(entity), current.UPDATE.get(entity))
        } else {
            original.UPDATE.set(entity, current.UPDATE.get(entity)) 
        }
    }
    return true
}

export const CHANGE_TYPE_MAP = {
    [ChangeType.PROPERTY]: PropChange,
    [ChangeType.ENTITY]: EntityChange
}
