/* tslint:disable */
/* eslint-disable */
/**
 * PHASE API
 * Phase API service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateUserGroupRequest,
  IdResponse,
  PatchUserGroupRequest,
} from '../models';

export interface UserGroupApiControllerCreateUserGroupRequest {
    teamId: string;
    createUserGroupRequest: CreateUserGroupRequest;
}

export interface UserGroupApiControllerDeleteUserGroupRequest {
    teamId: string;
    userGroupId: string;
}

export interface UserGroupApiControllerPatchUserGroupRequest {
    teamId: string;
    userGroupId: string;
    patchUserGroupRequest: PatchUserGroupRequest;
}

/**
 * 
 */
export class UserGroupApi extends runtime.BaseAPI {

    /**
     */
    async userGroupApiControllerCreateUserGroupRaw(requestParameters: UserGroupApiControllerCreateUserGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IdResponse>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling userGroupApiControllerCreateUserGroup.');
        }

        if (requestParameters.createUserGroupRequest === null || requestParameters.createUserGroupRequest === undefined) {
            throw new runtime.RequiredError('createUserGroupRequest','Required parameter requestParameters.createUserGroupRequest was null or undefined when calling userGroupApiControllerCreateUserGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/teams/{teamId}/user-groups`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.createUserGroupRequest,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async userGroupApiControllerCreateUserGroup(requestParameters: UserGroupApiControllerCreateUserGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IdResponse> {
        const response = await this.userGroupApiControllerCreateUserGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async userGroupApiControllerDeleteUserGroupRaw(requestParameters: UserGroupApiControllerDeleteUserGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling userGroupApiControllerDeleteUserGroup.');
        }

        if (requestParameters.userGroupId === null || requestParameters.userGroupId === undefined) {
            throw new runtime.RequiredError('userGroupId','Required parameter requestParameters.userGroupId was null or undefined when calling userGroupApiControllerDeleteUserGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/teams/{teamId}/user-groups/{userGroupId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"userGroupId"}}`, encodeURIComponent(String(requestParameters.userGroupId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async userGroupApiControllerDeleteUserGroup(requestParameters: UserGroupApiControllerDeleteUserGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.userGroupApiControllerDeleteUserGroupRaw(requestParameters, initOverrides);
    }

    /**
     */
    async userGroupApiControllerPatchUserGroupRaw(requestParameters: UserGroupApiControllerPatchUserGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling userGroupApiControllerPatchUserGroup.');
        }

        if (requestParameters.userGroupId === null || requestParameters.userGroupId === undefined) {
            throw new runtime.RequiredError('userGroupId','Required parameter requestParameters.userGroupId was null or undefined when calling userGroupApiControllerPatchUserGroup.');
        }

        if (requestParameters.patchUserGroupRequest === null || requestParameters.patchUserGroupRequest === undefined) {
            throw new runtime.RequiredError('patchUserGroupRequest','Required parameter requestParameters.patchUserGroupRequest was null or undefined when calling userGroupApiControllerPatchUserGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/teams/{teamId}/user-groups/{userGroupId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"userGroupId"}}`, encodeURIComponent(String(requestParameters.userGroupId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.patchUserGroupRequest,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async userGroupApiControllerPatchUserGroup(requestParameters: UserGroupApiControllerPatchUserGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.userGroupApiControllerPatchUserGroupRaw(requestParameters, initOverrides);
    }

}
