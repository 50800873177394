import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

// @ts-ignore TODO: create type definition file(.d.ts) for the types
import { TM_PROPS_MAP } from '@phase-software/transition-manager'

import { useDataStore, useDataStoreActions } from '../../../providers/dataStore/DataStoreProvider'
import { useInteraction } from '../../../providers/dataStore/InteractionProvider'
import { formatSecondsWithDecimals } from '../../../utils/formatter'
import { parseToDecimals } from '../../../utils/number'
import { secondFormatValidator } from '../../../utils/validator'
import { Input } from '../../shared'

type CurrentTimeProps = {
  playHeadRef: React.RefObject<{ scrollIntoView: () => void }>
  actionId: string
}

const CurrentTime = ({ playHeadRef, actionId }: CurrentTimeProps) => {
  const dataStore = useDataStore()
  const initialTime = useMemo(() => {
    return dataStore.transition.time / 1000 || 0
  }, [dataStore])
  const [time, setTime] = useState(initialTime)
  const { setPlayheadTime } = useDataStoreActions()
  const { t } = useTranslation('file')

  const { maxTime } = useInteraction(actionId)

  const MAX_TIME = useMemo(() => {
    return parseToDecimals(maxTime / 1000, 2)
  }, [maxTime])

  const handleChange = useCallback(
    (t: number) => {
      setPlayheadTime(t * 1000)
      setTimeout(() => {
        playHeadRef.current?.scrollIntoView()
      })
    },
    [setPlayheadTime, playHeadRef]
  )

  const setTimeChange = useCallback(
    (newTime: number) => {
      setTime(newTime / 1000)
    },
    [setTime]
  )

  useEffect(() => {
    if (dataStore) {
      dataStore.transition.on(TM_PROPS_MAP.time, setTimeChange)
    }
    return () => {
      if (dataStore) {
        dataStore.transition.off(TM_PROPS_MAP.time, setTimeChange)
      }
    }
  }, [dataStore, setTimeChange])

  return (
    <Input
      // @ts-ignore TODO: fix after refactor of Input
      tabIndex="-1"
      type="number"
      variant="flat"
      data-test-id="playhead-time"
      min={0}
      max={MAX_TIME}
      value={time}
      formatter={formatSecondsWithDecimals}
      validator={secondFormatValidator}
      onChange={handleChange}
      tooltip={t('current_time')}
      spinner
    />
  )
}

export default React.memo(CurrentTime)
