import { JSONCodec, Msg, NatsConnection, NatsError, Subscription, connect } from 'nats.ws'
// @ts-ignore
import uuid4 from 'uuid/v4'

import { CUSTOM_EVENT_NAMES } from '../constants'
import { getIdToken, getTokenPayload } from '../services/cognito'
import { createCustomEvent } from '../utils/customEventUtils'
import Counter from './Counter'
import FileProcessor, { FileMessageBasicData } from './FileProcessor'
import PresenceManager, { PresenceCallbackMessageData, PresenceMessageData } from './PresenceManager'

// eslint-disable-next-line new-cap
const jc = JSONCodec()

const defaultOptions = {
  saveEntireFile: true
}

export type FileVersionUpdateMessageData = {
  type: 'version.restore' | 'version.create'
}

export type SubjectMessageData = FileMessageBasicData | PresenceMessageData

export type BroadcasterMessageData = SubjectMessageData & {
  tabId: string
  userId: string | null
}

export type BroadcasterOptions = {
  saveEntireFile: boolean
}

type BroadcasterQueueMethod =
  | 'subscribePresenceFile'
  | 'unsubscribePresenceFile'
  | 'unsubscribePresence'
  | 'subscribeFile'
  | 'unsubscribeFile'
  | 'sendMessage'
  | 'subscribeFileVersionUpdate'
  | 'unsubscribeFileVersionUpdate'

type BroadcasterQueueItem = [BroadcasterQueueMethod, any[]]

export default class Broadcaster {
  serverUrl: string
  options: BroadcasterOptions
  subject: string
  uid: BroadcasterMessageData['tabId']
  userId: BroadcasterMessageData['userId']

  queue: Array<BroadcasterQueueItem>
  connection: NatsConnection | null
  fileProcessor: FileProcessor | null
  presenceManagerMap: Map<string, PresenceManager>
  fileSubMap: Map<string, Subscription>
  fileVersionUpdateSubMap: Map<string, Subscription>
  presenceSubMap: Map<string, Subscription>
  _reconnectTime: number
  _syncCounter: Counter

  // FIXME: options is only for debugging and will be remove after BE partial save is stable
  constructor(serverUrl: string, subject: string, options = {}) {
    this.serverUrl = serverUrl
    this.subject = subject

    this.options = Object.assign({}, defaultOptions, options)

    this.uid = uuid4()
    this.userId = null
    this.queue = []

    this.connection = null
    this.fileProcessor = null
    this.fileSubMap = new Map()
    this.fileVersionUpdateSubMap = new Map()

    this.presenceManagerMap = new Map()
    this.presenceSubMap = new Map()

    this._reconnectTime = 1000

    this._syncCounter = new Counter(6000)
  }

  get isReady() {
    return this.connection && !this.connection.isClosed()
  }

  // FIXME: remove it and use more service injection pattern
  setDataStore(dataStore: any) {
    if (!this.fileProcessor) {
      this.fileProcessor = new FileProcessor(this, dataStore, this.options)
    }
  }

  async initialize() {
    await this.connect()
  }

  async connect() {
    const token = getIdToken()

    if (!token) {
      console.error('[Broadcaster] connect: token is required')
      return
    }
    const { phase_uid: userId } = getTokenPayload(token)
    this.userId = userId

    await this.disconnect()

    try {
      this.connection = await connect({
        // set to false because we want to handle reconnect by ourselves
        reconnect: false,
        servers: [`${this.serverUrl}?token=${token}`]
      })

      this.connection.closed().then(async (err) => {
        const closedEvent = createCustomEvent(CUSTOM_EVENT_NAMES.NATS_CONNECT_CLOSED, {
          detail: err
        })
        document.dispatchEvent(closedEvent)
        await this.connect()
      })
      const connectedEvent = createCustomEvent(CUSTOM_EVENT_NAMES.NATS_CONNECTED)
      document.dispatchEvent(connectedEvent)
      this._dequeue()
      this._reconnectTime = 1000
    } catch (e) {
      this._reconnectTime *= 2
      setTimeout(async () => {
        await this.connect()
      }, this._reconnectTime)
      const errorEvent = createCustomEvent(CUSTOM_EVENT_NAMES.NATS_CONNECT_ERROR, {
        detail: e
      })
      document.dispatchEvent(errorEvent)
    }
  }

  _enqueue(method: BroadcasterQueueItem[0], params: BroadcasterQueueItem[1]) {
    this.queue.push([method, params])
  }

  _dequeue() {
    while (this.queue.length) {
      const [method, params] = this.queue.shift()!
      // @ts-ignore
      this[method](...params)
    }
  }

  _handleSyncedEvent() {
    const syncedEvent = createCustomEvent(CUSTOM_EVENT_NAMES.NATS_IS_SYNCED)
    document.dispatchEvent(syncedEvent)
  }

  isSynced() {
    return this._syncCounter.count === 0
  }

  createSubscriptionCallback(cb: CallableFunction): (err: NatsError | null, message: Msg) => void {
    return (err: NatsError | null, message: Msg) => {
      if (err) {
        console.error(err)
      }

      const content = jc.decode(message.data)
      const { tabId, type } = content as BroadcasterMessageData
      const isSameTab = this.uid === tabId

      if (isSameTab && type === 'file') {
        this._syncCounter.countDown()
      }

      switch (type) {
        case 'file':
        case 'version.restore':
        case 'version.create':
          if (isSameTab) return
          cb(content)
          break
        case 'presence':
        case 'presence.remove':
        case 'presence.update':
          cb(content)
          break
      }
    }
  }

  sendMessage(payload: SubjectMessageData, subject: string) {
    if (!this.isReady) {
      this._enqueue('sendMessage', [payload, subject])
      return
    }

    if (!subject) {
      console.error('[Broadcaster] sendMessage: subject is required.')
      return
    }

    const publishPayload = {
      ...payload,
      tabId: this.uid,
      userId: this.userId
    }

    this.publish(subject, publishPayload)
  }

  publish(subject: string, payload: BroadcasterMessageData) {
    if (!this.connection) {
      console.error('[Broadcaster] publish: connection is required.')
      return
    }

    if (!subject) {
      console.error('[Broadcaster] publish: subject is required.')
      return
    }

    try {
      if (payload.type === 'file') {
        this._syncCounter.countUp()
      }
      this.connection.publish(subject, jc.encode(payload))
    } catch (e) {
      console.error('sent payload error: ', e)
    }
  }

  async disconnect() {
    if (this.connection) {
      await this.connection.close()
    }
  }

  subscribeFile(projectId: string, fileId: string) {
    if (!this.fileProcessor || !this.connection) return

    this.fileProcessor.setMeta({ projectId, fileId })

    if (!this.isReady) {
      this._enqueue('subscribeFile', [projectId, fileId])
      return
    }

    // handle NatsError: CONNECTION_CLOSED
    try {
      const callback = this.createSubscriptionCallback(this.fileProcessor.onMessage)
      const fileSub = this.connection.subscribe(fileId, { callback })
      this.fileSubMap.set(fileId, fileSub)
      this._syncCounter.addEventListener(CUSTOM_EVENT_NAMES.COUNT_TO_ZERO, this._handleSyncedEvent)
    } catch (error) {
      console.error('[Broadcaster] subscribeFile error:', error)
    }
  }

  unsubscribeFile(projectId: string, fileId: string) {
    this.fileProcessor?.clearMeta()

    if (!this.isReady) {
      this._enqueue('unsubscribeFile', [projectId, fileId])
      return
    }

    const fileSub = this.fileSubMap.get(fileId)
    if (fileSub) {
      fileSub.unsubscribe()
      this.fileSubMap.delete(fileId)
      this._syncCounter.clear()
      this._syncCounter.removeEventListener(CUSTOM_EVENT_NAMES.COUNT_TO_ZERO, this._handleSyncedEvent)
    }
  }

  subscribePresenceFile(fileId: string, originCb?: (message: PresenceCallbackMessageData) => void) {
    if (!fileId) return

    if (!this.isReady) {
      this._enqueue('subscribePresenceFile', [fileId, originCb])
      return
    }

    const presenceManager = new PresenceManager(this)
    presenceManager.subscribePresenceFile(fileId, originCb)

    this.presenceManagerMap.set(fileId, presenceManager)
  }

  unsubscribePresenceFile(fileId: string) {
    if (!this.isReady) {
      this._enqueue('unsubscribePresenceFile', [fileId])
      return
    }
    this.unsubscribePresence(fileId)
    this.presenceManagerMap.delete(fileId)
  }

  unsubscribePresence(fileId: string) {
    if (!this.isReady) {
      this._enqueue('unsubscribePresence', [fileId])
      return
    }

    const presenceSub = this.presenceSubMap.get(fileId)
    if (presenceSub) {
      presenceSub.unsubscribe()
      this.presenceSubMap.delete(fileId)
    }
  }

  subscribeFileVersionUpdate(fileId: string, callback: (message: FileVersionUpdateMessageData) => void) {
    if (!this.connection) return

    if (!this.isReady) {
      this._enqueue('subscribeFileVersionUpdate', [fileId])
    }

    // handle NatsError: CONNECTION_CLOSED
    try {
      const fileVersionUpdateCallback = this.createSubscriptionCallback(callback)

      const subject = `${fileId}.VersionUpdate`
      const fileVersionUpdateSub = this.connection.subscribe(subject, { callback: fileVersionUpdateCallback })

      this.fileVersionUpdateSubMap.set(fileId, fileVersionUpdateSub)
    } catch (error) {
      console.error('[Broadcaster] subscribeFileVersionUpdate error:', error)
    }
  }

  unsubscribeFileVersionUpdate(fileId: string) {
    if (!this.isReady) {
      this._enqueue('unsubscribeFileVersionUpdate', [fileId])
    }

    const fileVersionUpdateSub = this.fileVersionUpdateSubMap.get(fileId)
    if (fileVersionUpdateSub) {
      fileVersionUpdateSub.unsubscribe()
      this.fileVersionUpdateSubMap.delete(fileId)
    }
  }
}
