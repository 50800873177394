import React from 'react'
import Button from '.'
import styled from 'styled-components'

/**
 * This button is deprecated, confirmed by the design team.
 * It is reserved for the use in the old UI only.
 * TODO: Remove this component when updating the tutorial.
 */

interface HighlightButtonProps {
  icon?: string
  type?: 'button' | 'submit'
  size?: 's' | 'l'
  fluid?: boolean
  className?: string
  children?: string
  disabled?: boolean
  variant?: 'solid' | 'ghost' | 'flat'
  color?: 'primary' | 'secondary' | 'danger'
  autoFocus?: boolean
}

const HighlightButton = React.forwardRef<HTMLButtonElement, HighlightButtonProps>(({ className, ...rest }, ref) => {
  return <Button ref={ref} className={`highlight-border-rounded-5 ${className}`} {...rest} />
})
HighlightButton.displayName = 'HighlightButton'

const WhiteHighlightButton = styled(HighlightButton)`
  &:focus-visible::after {
    box-shadow: 0px 0px 0px 4px ${(props) => props.theme.colors.lightOverlays[40]};
    border-color: ${(props) => props.theme.colors.white};
  }
`
export default WhiteHighlightButton
