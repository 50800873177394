import { useEffect, useCallback, memo } from 'react'
import { useDataStore, useSetDataStore } from '../../providers/dataStore/DataStoreProvider'

const SubscribeActionSelectionChange = () => {
  const dataStore = useDataStore()
  const { setActionSelection } = useSetDataStore()
  const cb = useCallback(
    (changes) => {
      const action = changes.get('action')
      if (action) {
        setActionSelection(action.after)
      }
    },
    [setActionSelection]
  )
  useEffect(() => {
    if (!dataStore) return
    dataStore.selection.on('SELECT', cb)

    return () => {
      dataStore.selection.off('SELECT', cb)
    }
  }, [dataStore, cb])
  return null
}

export default memo(SubscribeActionSelectionChange)
