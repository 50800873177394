import React, { forwardRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import Button from '../Button'
import IconButton from '../IconButton'
import LinkSwitcher from '../LinkSwitcher'
import Tooltip from '../Tooltip'

export type TemplateCardProps = {
  name: string
  imageUrl?: string
  templateUrl: string
  author: string
  authorUrl?: string
  className?: string

  onDropdownMenu: ({ trigger }: { trigger?: React.RefObject<HTMLElement> }) => void
}

const TemplateCard = forwardRef<HTMLDivElement, TemplateCardProps>(
  ({ name, imageUrl, templateUrl, author, authorUrl, onDropdownMenu, className = '' }, forwardedRef) => {
    const { t } = useTranslation('workspace')
    const triggerRef = React.useRef<HTMLButtonElement>(null)

    const handleImageBroken = (e: React.SyntheticEvent<HTMLImageElement>) => {
      e.currentTarget.hidden = true
    }
    const openDropdownMenu = (e: React.MouseEvent) => {
      e.preventDefault()
      onDropdownMenu({
        trigger: triggerRef
      })
    }

    return (
      <div
        data-test-id="template-card"
        className={`
      group rounded-xl text-white bg-light-overlay-5 relative
      after:rounded-xl after:border after:border-transparent after:transition after:duration-300 after:ease-out after:absolute after:inset-0
      hover:after:border-light-overlay-10 hover:bg-light-overlay-10 after:pointer-events-none
      focus-visible:outline-primary-2
      focus:hover:after:border-transparent
      active:after:hidden
      ${className}`}
        tabIndex={0}
        ref={forwardedRef}
      >
        <div className="px-8 pt-8 pb-16 relative">
          <div className="aspect-h-3 aspect-w-4 w-full rounded-sm overflow-hidden bg-secondary-60 relative">
            <img
              src={imageUrl ?? ''}
              alt="animation thumbnail"
              loading="lazy"
              onError={handleImageBroken}
              className="object-cover object-center w-full h-full duration-300 ease-out group-hover:transform group-hover:scale-105"
            />
            <div className="absolute bg-black opacity-0 transition-opacity group-hover:duration-300 ease-out group-hover:opacity-60 focus-within:opacity-60" />
            <div className="absolute inset-0 flex items-center justify-center transition-opacity group-hover:duration-300 ease-out opacity-0 group-hover:opacity-100 focus-within:opacity-100 pointer-events-none group-hover:pointer-events-auto focus-within:pointer-events-auto z-1">
              <LinkSwitcher to={templateUrl} tabIndex={-1}>
                <Button data-test-id="template-button">{t('use_template')}</Button>
              </LinkSwitcher>
            </div>
          </div>
          <div className="px-8 pt-8 cursor-default">
            <Tooltip content={name} visibleOnOverflow>
              <div className="text-14 font-semibold truncate" data-test-id="template-name">
                {name}
              </div>
            </Tooltip>
            <div className="text-12 text-light-overlay-60 truncate">
              <Trans
                i18nKey="workspace:template_by"
                values={{ author }}
                components={{ link: <LinkSwitcher key="template-link" to={authorUrl} /> }}
              >
                By
                <LinkSwitcher to={authorUrl}>{author}</LinkSwitcher>
              </Trans>
            </div>
          </div>
          <div className="absolute left-auto bottom-auto right-24 top-24 w-24 h-24 transition-opacity group-hover:duration-300 ease-out opacity-0 group-hover:opacity-100 focus-within:opacity-100 pointer-events-none group-hover:pointer-events-auto focus-within:pointer-events-auto z-1">
            <IconButton
              data-test-id="template-settings"
              icon="More"
              size="xxl"
              disableGroupHover={true}
              onClick={openDropdownMenu}
              ref={triggerRef}
            />
          </div>
        </div>
      </div>
    )
  }
)

TemplateCard.displayName = 'TemplateCard'

export default TemplateCard
