import { IREffectType, IRTrimMode } from './constants'
import { IREffect } from './IREffect'

export class IRTrimEffect extends IREffect {
  static props = [
    ...super.props,
    { key: 'start', animatable: true, defaultValue: 0 },
    { key: 'end', animatable: true, defaultValue: 100 },
    { key: 'offset', animatable: true, defaultValue: 0 },
    { key: 'mode', animatable: false }
  ]

  static defaultValue = {
    start: 0,
    end: 100,
    offset: 0,
    mode: IRTrimMode.SIMULTANEOUSLY
  }

  constructor(data = IRTrimEffect.defaultValue) {
    data.type = IREffectType.TRIM_PATH
    super(data)
  }
}
