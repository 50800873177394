import React, { forwardRef } from 'react'
import BaseButton from './BaseButton'

type FlatButtonProps = {
  children: string
  className?: string
}

const FlatButton = forwardRef<HTMLButtonElement, FlatButtonProps>(({ children, className, ...rest }, ref) => {
  const fill = 'hover:bg-light-overlay-15 active:bg-light-overlay-5 disabled:bg-transparent disabled:opacity-40'
  const border = 'focus-visible:outline-primary-1-offset--1 disabled:outline-none'

  return (
    <BaseButton variant="flat" ref={ref} className={`${fill} ${border} ${className}`} {...rest}>
      {children}
    </BaseButton>
  )
})

FlatButton.displayName = 'FlatButton'

export default FlatButton
