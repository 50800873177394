const GlyphMaps = {
    Sans: {
        '10808': 'NotoSansCypriot',
        '11303': 'NotoSansTamil',
        '20021': 'NotoSansCJKtc',
        '20046': 'NotoSansCJKtc',
        '20068': 'NotoSansCJKtc',
        '20094': 'NotoSansCJKtc',
        '20118': 'NotoSansCJKtc',
        '20158': 'NotoSansCJKtc',
        '20164': 'NotoSansCJKtc',
        '20204': 'NotoSansCJKtc',
        '20239': 'NotoSansCJKtc',
        '20299': 'NotoSansCJKtc',
        '20325': 'NotoSansCJKtc',
        '20341': 'NotoSansCJKtc',
        '20371': 'NotoSansCJKtc',
        '20465': 'NotoSansCJKtc',
        '20487': 'NotoSansCJKtc',
        '20509': 'NotoSansCJKtc',
        '20547': 'NotoSansCJKtc',
        '20611': 'NotoSansCJKtc',
        '20615': 'NotoSansCJKtc',
        '20628': 'NotoSansCJKtc',
        '20630': 'NotoSansCJKtc',
        '20656': 'NotoSansCJKtc',
        '20676': 'NotoSansCJKtc',
        '20731': 'NotoSansCJKtc',
        '20779': 'NotoSansCJKtc',
        '20807': 'NotoSansCJKtc',
        '20873': 'NotoSansCJKtc',
        '20916': 'NotoSansCJKtc',
        '20923': 'NotoSansCJKtc',
        '20954': 'NotoSansCJKtc',
        '20979': 'NotoSansCJKtc',
        '20984': 'NotoSansCJKtc',
        '21014': 'NotoSansCJKtc',
        '21088': 'NotoSansCJKtc',
        '21096': 'NotoSansCJKtc',
        '21145': 'NotoSansCJKtc',
        '21148': 'NotoSansCJKtc',
        '21180': 'NotoSansCJKtc',
        '21187': 'NotoSansCJKtc',
        '21201': 'NotoSansCJKtc',
        '21255': 'NotoSansCJKtc',
        '21274': 'NotoSansCJKtc',
        '21336': 'NotoSansCJKtc',
        '21344': 'NotoSansCJKtc',
        '21398': 'NotoSansCJKtc',
        '21413': 'NotoSansCJKtc',
        '21416': 'NotoSansCJKtc',
        '21424': 'NotoSansCJKtc',
        '21452': 'NotoSansCJKtc',
        '21497': 'NotoSansCJKtc',
        '21577': 'NotoSansCJKtc',
        '21582': 'NotoSansCJKtc',
        '21596': 'NotoSansCJKtc',
        '21613': 'NotoSansCJKtc',
        '21619': 'NotoSansCJKtc',
        '21647': 'NotoSansCJKtc',
        '21661': 'NotoSansCJKtc',
        '21692': 'NotoSansCJKtc',
        '21706': 'NotoSansCJKtc',
        '21710': 'NotoSansCJKtc',
        '21726': 'NotoSansCJKtc',
        '21742': 'NotoSansCJKtc',
        '21757': 'NotoSansCJKtc',
        '21820': 'NotoSansCJKtc',
        '21840': 'NotoSansCJKtc',
        '21845': 'NotoSansCJKtc',
        '21852': 'NotoSansCJKtc',
        '21877': 'NotoSansCJKtc',
        '21915': 'NotoSansCJKtc',
        '21922': 'NotoSansCJKtc',
        '21927': 'NotoSansCJKtc',
        '21944': 'NotoSansCJKtc',
        '21958': 'NotoSansCJKtc',
        '21980': 'NotoSansCJKtc',
        '21983': 'NotoSansCJKtc',
        '21988': 'NotoSansCJKtc',
        '21996': 'NotoSansCJKtc',
        '22045': 'NotoSansCJKtc',
        '22049': 'NotoSansCJKtc',
        '22173': 'NotoSansCJKtc',
        '22208': 'NotoSansCJKtc',
        '22218': 'NotoSansCJKtc',
        '22321': 'NotoSansCJKtc',
        '22325': 'NotoSansCJKtc',
        '22465': 'NotoSansCJKtc',
        '22471': 'NotoSansCJKtc',
        '22491': 'NotoSansCJKtc',
        '22513': 'NotoSansCJKtc',
        '22530': 'NotoSansCJKtc',
        '22554': 'NotoSansCJKtc',
        '22609': 'NotoSansCJKtc',
        '22668': 'NotoSansCJKtc',
        '22696': 'NotoSansCJKtc',
        '22698': 'NotoSansCJKtc',
        '22712': 'NotoSansCJKtc',
        '22714': 'NotoSansCJKtc',
        '22775': 'NotoSansCJKtc',
        '22781': 'NotoSansCJKtc',
        '22796': 'NotoSansCJKtc',
        '22803': 'NotoSansCJKtc',
        '22871': 'NotoSansCJKtc',
        '22926': 'NotoSansCJKtc',
        '22939': 'NotoSansCJKtc',
        '22967': 'NotoSansCJKtc',
        '22980': 'NotoSansCJKtc',
        '22993': 'NotoSansCJKtc',
        '23033': 'NotoSansCJKtc',
        '23044': 'NotoSansCJKtc',
        '23066': 'NotoSansCJKtc',
        '23103': 'NotoSansCJKtc',
        '23182': 'NotoSansCJKtc',
        '23225': 'NotoSansCJKtc',
        '23256': 'NotoSansCJKtc',
        '23262': 'NotoSansCJKtc',
        '23281': 'NotoSansCJKtc',
        '23300': 'NotoSansCJKtc',
        '23372': 'NotoSansCJKtc',
        '23400': 'NotoSansCJKtc',
        '23465': 'NotoSansCJKtc',
        '23472': 'NotoSansCJKtc',
        '23519': 'NotoSansCJKtc',
        '23530': 'NotoSansCJKtc',
        '23551': 'NotoSansCJKtc',
        '23567': 'NotoSansCJKtc',
        '23599': 'NotoSansCJKtc',
        '23600': 'NotoSansCJKtc',
        '23617': 'NotoSansCJKtc',
        '23640': 'NotoSansCJKtc',
        '23647': 'NotoSansCJKtc',
        '23659': 'NotoSansCJKtc',
        '23677': 'NotoSansCJKtc',
        '23703': 'NotoSansCJKtc',
        '23716': 'NotoSansCJKtc',
        '23720': 'NotoSansCJKtc',
        '23766': 'NotoSansCJKtc',
        '23781': 'NotoSansCJKtc',
        '23824': 'NotoSansCJKtc',
        '24011': 'NotoSansCJKtc',
        '24057': 'NotoSansCJKtc',
        '24085': 'NotoSansCJKtc',
        '24091': 'NotoSansCJKtc',
        '24096': 'NotoSansCJKtc',
        '24119': 'NotoSansCJKtc',
        '24144': 'NotoSansCJKtc',
        '24161': 'NotoSansCJKtc',
        '24177': 'NotoSansCJKtc',
        '24256': 'NotoSansCJKtc',
        '24259': 'NotoSansCJKtc',
        '24284': 'NotoSansCJKtc',
        '24293': 'NotoSansCJKtc',
        '24295': 'NotoSansCJKtc',
        '24334': 'NotoSansCJKtc',
        '24348': 'NotoSansCJKtc',
        '24396': 'NotoSansCJKtc',
        '24404': 'NotoSansCJKtc',
        '24473': 'NotoSansCJKtc',
        '24505': 'NotoSansCJKtc',
        '24521': 'NotoSansCJKtc',
        '24578': 'NotoSansCJKtc',
        '24618': 'NotoSansCJKtc',
        '24665': 'NotoSansCJKtc',
        '24674': 'NotoSansCJKtc',
        '24697': 'NotoSansCJKtc',
        '24706': 'NotoSansCJKtc',
        '24725': 'NotoSansCJKtc',
        '24812': 'NotoSansCJKtc',
        '24823': 'NotoSansCJKtc',
        '24882': 'NotoSansCJKtc',
        '24896': 'NotoSansCJKtc',
        '24919': 'NotoSansCJKtc',
        '24982': 'NotoSansCJKtc',
        '24994': 'NotoSansCJKtc',
        '25052': 'NotoSansCJKtc',
        '25055': 'NotoSansCJKtc',
        '25122': 'NotoSansCJKtc',
        '25148': 'NotoSansCJKtc',
        '25250': 'NotoSansCJKtc',
        '25299': 'NotoSansCJKtc',
        '25311': 'NotoSansCJKtc',
        '25313': 'NotoSansCJKtc',
        '25419': 'NotoSansCJKtc',
        '25425': 'NotoSansCJKtc',
        '25446': 'NotoSansCJKtc',
        '25535': 'NotoSansCJKtc',
        '25562': 'NotoSansCJKtc',
        '25581': 'NotoSansCJKtc',
        '25584': 'NotoSansCJKtc',
        '25605': 'NotoSansCJKtc',
        '25635': 'NotoSansCJKtc',
        '25651': 'NotoSansCJKtc',
        '25683': 'NotoSansCJKtc',
        '25695': 'NotoSansCJKtc',
        '25706': 'NotoSansCJKtc',
        '25725': 'NotoSansCJKtc',
        '25857': 'NotoSansCJKtc',
        '25872': 'NotoSansCJKtc',
        '25874': 'NotoSansCJKtc',
        '25903': 'NotoSansCJKtc',
        '25946': 'NotoSansCJKtc',
        '25956': 'NotoSansCJKtc',
        '26017': 'NotoSansCJKtc',
        '26021': 'NotoSansCJKtc',
        '26029': 'NotoSansCJKtc',
        '26048': 'NotoSansCJKtc',
        '26060': 'NotoSansCJKtc',
        '26064': 'NotoSansCJKtc',
        '26083': 'NotoSansCJKtc',
        '26097': 'NotoSansCJKtc',
        '26102': 'NotoSansCJKtc',
        '26121': 'NotoSansCJKtc',
        '26258': 'NotoSansCJKtc',
        '26261': 'NotoSansCJKtc',
        '26270': 'NotoSansCJKtc',
        '26286': 'NotoSansCJKtc',
        '26335': 'NotoSansCJKtc',
        '26351': 'NotoSansCJKtc',
        '26402': 'NotoSansCJKtc',
        '26469': 'NotoSansCJKtc',
        '26484': 'NotoSansCJKtc',
        '26498': 'NotoSansCJKtc',
        '26512': 'NotoSansCJKtc',
        '26572': 'NotoSansCJKtc',
        '26612': 'NotoSansCJKtc',
        '26626': 'NotoSansCJKtc',
        '26676': 'NotoSansCJKtc',
        '26716': 'NotoSansCJKtc',
        '26741': 'NotoSansCJKtc',
        '26799': 'NotoSansCJKtc',
        '26846': 'NotoSansCJKtc',
        '26888': 'NotoSansCJKtc',
        '26893': 'NotoSansCJKtc',
        '26911': 'NotoSansCJKtc',
        '26926': 'NotoSansCJKtc',
        '26939': 'NotoSansCJKtc',
        '26951': 'NotoSansCJKtc',
        '26999': 'NotoSansCJKtc',
        '27088': 'NotoSansCJKtc',
        '27109': 'NotoSansCJKtc',
        '27139': 'NotoSansCJKtc',
        '27175': 'NotoSansCJKtc',
        '27267': 'NotoSansCJKtc',
        '27280': 'NotoSansCJKtc',
        '27285': 'NotoSansCJKtc',
        '27352': 'NotoSansCJKtc',
        '27410': 'NotoSansCJKtc',
        '27422': 'NotoSansCJKtc',
        '27449': 'NotoSansCJKtc',
        '27450': 'NotoSansCJKtc',
        '27484': 'NotoSansCJKtc',
        '27486': 'NotoSansCJKtc',
        '27574': 'NotoSansCJKtc',
        '27607': 'NotoSansCJKtc',
        '27639': 'NotoSansCJKtc',
        '27684': 'NotoSansCJKtc',
        '27723': 'NotoSansCJKtc',
        '27741': 'NotoSansCJKtc',
        '27752': 'NotoSansCJKtc',
        '27858': 'NotoSansCJKtc',
        '27870': 'NotoSansCJKtc',
        '27924': 'NotoSansCJKtc',
        '27967': 'NotoSansCJKtc',
        '27985': 'NotoSansCJKtc',
        '28002': 'NotoSansCJKtc',
        '28009': 'NotoSansCJKtc',
        '28048': 'NotoSansCJKtc',
        '28083': 'NotoSansCJKtc',
        '28090': 'NotoSansCJKtc',
        '28189': 'NotoSansCJKtc',
        '28207': 'NotoSansCJKtc',
        '28218': 'NotoSansCJKtc',
        '28256': 'NotoSansCJKtc',
        '28277': 'NotoSansCJKtc',
        '28282': 'NotoSansCJKtc',
        '28306': 'NotoSansCJKtc',
        '28318': 'NotoSansCJKtc',
        '28365': 'NotoSansCJKtc',
        '28408': 'NotoSansCJKtc',
        '28412': 'NotoSansCJKtc',
        '28455': 'NotoSansCJKtc',
        '28468': 'NotoSansCJKtc',
        '28473': 'NotoSansCJKtc',
        '28482': 'NotoSansCJKtc',
        '28501': 'NotoSansCJKtc',
        '28600': 'NotoSansCJKtc',
        '28625': 'NotoSansCJKtc',
        '28678': 'NotoSansCJKtc',
        '28695': 'NotoSansCJKtc',
        '28713': 'NotoSansCJKtc',
        '28804': 'NotoSansCJKtc',
        '28933': 'NotoSansCJKtc',
        '28946': 'NotoSansCJKtc',
        '28956': 'NotoSansCJKtc',
        '28964': 'NotoSansCJKtc',
        '28968': 'NotoSansCJKtc',
        '29079': 'NotoSansCJKtc',
        '29088': 'NotoSansCJKtc',
        '29093': 'NotoSansCJKtc',
        '29110': 'NotoSansCJKtc',
        '29170': 'NotoSansCJKtc',
        '29420': 'NotoSansCJKtc',
        '29433': 'NotoSansCJKtc',
        '29448': 'NotoSansCJKtc',
        '29490': 'NotoSansCJKtc',
        '29719': 'NotoSansCJKtc',
        '29720': 'NotoSansCJKtc',
        '29732': 'NotoSansCJKtc',
        '29750': 'NotoSansCJKtc',
        '29810': 'NotoSansCJKtc',
        '29857': 'NotoSansCJKtc',
        '29903': 'NotoSansCJKtc',
        '29905': 'NotoSansCJKtc',
        '29945': 'NotoSansCJKtc',
        '00000': 'NotoSansYi',
        '0000d': 'NotoSansYi',
        '00020': 'NotoSansYi',
        '00021': 'NotoSansThaana',
        '000a0': 'NotoSansYi',
        '0061f': 'NotoSansThaana',
        '00640': 'NotoSansSyriacWestern',
        '0204f': 'NotoSansArabic',
        '02e41': 'NotoSansArabic',
        '1e900-1e94a': 'NotoSansAdlamUnjoined',
        '1e950-1e959': 'NotoSansAdlamUnjoined',
        '1e95e-1e95f': 'NotoSansAdlamUnjoined',
        '0200b': 'NotoSansTibetan',
        '14400-14646': 'NotoSansAnatolianHieroglyphs',
        '0002c': 'NotoSansThaana',
        '0002d': 'NotoSansThai',
        '0002e': 'NotoSansThaana',
        '00030-00039': 'NotoSansTelugu',
        '0003a': 'NotoSansThaana',
        '000ab': 'NotoSansSyriacWestern',
        '000bb': 'NotoSansSyriacWestern',
        '0034f': 'NotoSansArabic',
        '00600-0060b': 'NotoSansArabic',
        '0060c': 'NotoSansThaana',
        '0060d-0061a': 'NotoSansArabic',
        '0061b-0061c': 'NotoSansThaana',
        '0061e': 'NotoSansArabic',
        '00620': 'NotoSansArabic',
        '00621': 'NotoSansSyriacWestern',
        '00622-0063f': 'NotoSansArabic',
        '00641-0064a': 'NotoSansArabic',
        '0064b-00655': 'NotoSansSyriacWestern',
        '00656-0065f': 'NotoSansArabic',
        '00660-0066c': 'NotoSansThaana',
        '0066d-0066f': 'NotoSansArabic',
        '00670': 'NotoSansSyriacWestern',
        '00671-006ff': 'NotoSansArabic',
        '00750-0077f': 'NotoSansArabic',
        '008a0-008b4': 'NotoSansArabic',
        '008b6-008bd': 'NotoSansArabic',
        '008d4-008ff': 'NotoSansArabic',
        '0200c-0200d': 'NotoSansTifinagh',
        '0200e-0200f': 'NotoSansThaana',
        '02010': 'NotoSansThai',
        '02011': 'NotoSansSylotiNagri',
        '025cc': 'NotoSansTifinagh',
        '0fb50-0fbc1': 'NotoSansArabic',
        '0fbd3-0fd3d': 'NotoSansArabic',
        '0fd3e-0fd3f': 'NotoSansNKo',
        '0fd50-0fd8f': 'NotoSansArabic',
        '0fd92-0fdc7': 'NotoSansArabic',
        '0fdf0-0fdf1': 'NotoSansArabic',
        '0fdf2': 'NotoSansThaana',
        '0fdf3-0fdfc': 'NotoSansArabic',
        '0fdfd': 'NotoSansThaana',
        '0fe70-0fe74': 'NotoSansArabic',
        '0fe76-0fefc': 'NotoSansArabic',
        '00531-00556': 'NotoSansArmenian',
        '00559-0055f': 'NotoSansArmenian',
        '00561-00587': 'NotoSansArmenian',
        '00589': 'NotoSansGeorgian',
        '0058a': 'NotoSansArmenian',
        '0058d-0058f': 'NotoSansArmenian',
        '0fb13-0fb17': 'NotoSansArmenian',
        '000b7': 'NotoSansCJKtc',
        '02e30': 'NotoSansAvestan',
        '02e31': 'NotoSansSamaritan',
        '10b00-10b35': 'NotoSansAvestan',
        '10b39-10b3f': 'NotoSansAvestan',
        '01b00-01b4b': 'NotoSansBalinese',
        '01b50-01b7c': 'NotoSansBalinese',
        '0feff': 'NotoSansTibetan',
        '0a6a0-0a6f7': 'NotoSansBamum',
        '16800-16a38': 'NotoSansBamum',
        '01bc0-01bf3': 'NotoSansBatak',
        '01bfc-01bff': 'NotoSansBatak',
        '00022-00023': 'NotoSansTelugu',
        '00025': 'NotoSansTelugu',
        '00027': 'NotoSansTelugu',
        '00028-00029': 'NotoSansThaana',
        '0002a-0002b': 'NotoSansTelugu',
        '0002f': 'NotoSansTelugu',
        '0003b': 'NotoSansThaana',
        '0003c-0003f': 'NotoSansTelugu',
        '0005b-0005f': 'NotoSansTelugu',
        '0007b': 'NotoSansTelugu',
        '0007c': 'NotoSansTibetan',
        '0007d-0007e': 'NotoSansTelugu',
        '000ad': 'NotoSansTelugu',
        '000d7': 'NotoSansTelugu',
        '000f7': 'NotoSansTelugu',
        '002bc': 'NotoSansThai',
        '00951-00952': 'NotoSansTamil',
        '00964-00965': 'NotoSansTelugu',
        '00980-00983': 'NotoSansBengali',
        '00985-0098c': 'NotoSansBengali',
        '0098f-00990': 'NotoSansBengali',
        '00993-009a8': 'NotoSansBengali',
        '009aa-009b0': 'NotoSansBengali',
        '009b2': 'NotoSansBengali',
        '009b6-009b9': 'NotoSansBengali',
        '009bc-009c4': 'NotoSansBengali',
        '009c7-009c8': 'NotoSansBengali',
        '009cb-009ce': 'NotoSansBengali',
        '009d7': 'NotoSansBengali',
        '009dc-009dd': 'NotoSansBengali',
        '009df-009e3': 'NotoSansBengali',
        '009e6-009ef': 'NotoSansSylotiNagri',
        '009f0-009fb': 'NotoSansBengali',
        '02013-02014': 'NotoSansTelugu',
        '02018-02019': 'NotoSansThaana',
        '0201c-0201d': 'NotoSansThaana',
        '02026': 'NotoSansTelugu',
        '020b9': 'NotoSansTelugu',
        '02212': 'NotoSansTelugu',
        '11000-1104d': 'NotoSansBrahmi',
        '11052-1106f': 'NotoSansBrahmi',
        '01a00-01a1b': 'NotoSansBuginese',
        '01a1e-01a1f': 'NotoSansBuginese',
        '0a9cf': 'NotoSansJavanese',
        '01735-01736': 'NotoSansTagbanwa',
        '01740-01753': 'NotoSansBuhid',
        '00001-0000c': 'NotoSansSymbols2',
        '0000e-0001f': 'NotoSansSymbols2',
        '00024': 'NotoSansCJKtc',
        '00026': 'NotoSansCJKtc',
        '00040': 'NotoSansCJKtc',
        '00041-0005a': 'NotoSansSymbols',
        '00060': 'NotoSansCJKtc',
        '00061-0007a': 'NotoSansSymbols',
        '000a1-000aa': 'NotoSansCJKtc',
        '000ac': 'NotoSansCJKtc',
        '000ae-000af': 'NotoSansCJKtc',
        '000b0': 'NotoSansSyriacWestern',
        '000b1': 'NotoSansCJKtc',
        '000b2-000b3': 'NotoSansTamil',
        '000b4-000b6': 'NotoSansCJKtc',
        '000b8-000ba': 'NotoSansCJKtc',
        '000bc-000d6': 'NotoSansCJKtc',
        '000d8-000f6': 'NotoSansCJKtc',
        '000f8-00103': 'NotoSansCJKtc',
        '00110-00113': 'NotoSansCJKtc',
        '0011a-0011b': 'NotoSansCJKtc',
        '00128-0012b': 'NotoSansCJKtc',
        '00143-00144': 'NotoSansCJKtc',
        '00147-00148': 'NotoSansCJKtc',
        '0014c-0014f': 'NotoSansCJKtc',
        '00152-00153': 'NotoSansCJKtc',
        '00168-0016d': 'NotoSansCJKtc',
        '00192': 'NotoSansCJKtc',
        '001a0-001a1': 'NotoSansCJKtc',
        '001af-001b0': 'NotoSansCJKtc',
        '001cd-001dc': 'NotoSansCJKtc',
        '001f8-001f9': 'NotoSansCJKtc',
        '00251': 'NotoSansCJKtc',
        '00261': 'NotoSansCJKtc',
        '002c7': 'NotoSansCanadianAboriginal',
        '002c9-002cb': 'NotoSansCJKtc',
        '002d9': 'NotoSansCanadianAboriginal',
        '00391-003a1': 'NotoSansCJKtc',
        '003a3-003a9': 'NotoSansCJKtc',
        '003b1-003c1': 'NotoSansCJKtc',
        '003c3-003c9': 'NotoSansCJKtc',
        '00401': 'NotoSansCJKtc',
        '00410-0044f': 'NotoSansCJKtc',
        '00451': 'NotoSansCJKtc',
        '01100-011ff': 'NotoSansCJKtc',
        '01e3e-01e3f': 'NotoSansCJKtc',
        '01ea0-01ef9': 'NotoSansCJKtc',
        '02002-02003': 'NotoSansCJKtc',
        '02012': 'NotoSansCJKtc',
        '02015-02016': 'NotoSansCJKtc',
        '0201a': 'NotoSansCJKtc',
        '0201e': 'NotoSansCJKtc',
        '02020-02021': 'NotoSansCJKtc',
        '02022': 'NotoSansSymbols2',
        '02025': 'NotoSansPhagsPa',
        '02027': 'NotoSansCJKtc',
        '02030': 'NotoSansCJKtc',
        '02032-02033': 'NotoSansCJKtc',
        '02035': 'NotoSansCJKtc',
        '02039-0203c': 'NotoSansCJKtc',
        '02042': 'NotoSansCJKtc',
        '02047': 'NotoSansCJKtc',
        '02048-02049': 'NotoSansMongolian',
        '02051': 'NotoSansCJKtc',
        '020a9': 'NotoSansCJKtc',
        '020ab-020ac': 'NotoSansCJKtc',
        '020dd-020de': 'NotoSansSymbols',
        '02100': 'NotoSansCJKtc',
        '02103': 'NotoSansCJKtc',
        '02105': 'NotoSansCJKtc',
        '02109-0210a': 'NotoSansCJKtc',
        '0210f': 'NotoSansCJKtc',
        '02113': 'NotoSansCJKtc',
        '02116': 'NotoSansCJKtc',
        '02121-02122': 'NotoSansCJKtc',
        '02126-02127': 'NotoSansCJKtc',
        '0212b': 'NotoSansCJKtc',
        '0212e': 'NotoSansCJKtc',
        '02135': 'NotoSansCJKtc',
        '0213b': 'NotoSansCJKtc',
        '02160-0216b': 'NotoSansSymbols',
        '02170-0217b': 'NotoSansSymbols',
        '02190-02199': 'NotoSansSymbols',
        '021b8-021b9': 'NotoSansCJKtc',
        '021c4-021c6': 'NotoSansCJKtc',
        '021cb-021cc': 'NotoSansCJKtc',
        '021d0': 'NotoSansCJKtc',
        '021d2': 'NotoSansCJKtc',
        '021d4': 'NotoSansCJKtc',
        '021e6-021e9': 'NotoSansSymbols2',
        '021f5': 'NotoSansCJKtc',
        '02200': 'NotoSansCJKtc',
        '02202-02203': 'NotoSansCJKtc',
        '02205-0220b': 'NotoSansCJKtc',
        '0220f': 'NotoSansCJKtc',
        '02211': 'NotoSansCJKtc',
        '02213': 'NotoSansCJKtc',
        '02215': 'NotoSansCJKtc',
        '0221a': 'NotoSansCJKtc',
        '0221d-02220': 'NotoSansCJKtc',
        '02223': 'NotoSansCJKtc',
        '02225-0222e': 'NotoSansCJKtc',
        '02234-02237': 'NotoSansCJKtc',
        '0223d': 'NotoSansCJKtc',
        '02243': 'NotoSansCJKtc',
        '02245': 'NotoSansCJKtc',
        '02248': 'NotoSansCJKtc',
        '0224c': 'NotoSansCJKtc',
        '02252': 'NotoSansCJKtc',
        '02260-02262': 'NotoSansCJKtc',
        '02264-02267': 'NotoSansCJKtc',
        '0226a-0226b': 'NotoSansCJKtc',
        '0226e-0226f': 'NotoSansCJKtc',
        '02272-02273': 'NotoSansCJKtc',
        '02276-02277': 'NotoSansCJKtc',
        '02282-02287': 'NotoSansCJKtc',
        '0228a-0228b': 'NotoSansCJKtc',
        '02295-02298': 'NotoSansCJKtc',
        '02299': 'NotoSansSymbols2',
        '022a0': 'NotoSansCJKtc',
        '022a5': 'NotoSansCJKtc',
        '022bf': 'NotoSansCJKtc',
        '022da-022db': 'NotoSansCJKtc',
        '022ef': 'NotoSansCJKtc',
        '02305-02307': 'NotoSansSymbols',
        '02312': 'NotoSansSymbols',
        '02318': 'NotoSansSymbols2',
        '02329-0232a': 'NotoSansSymbols',
        '023b0-023b1': 'NotoSansCJKtc',
        '023be-023cc': 'NotoSansSymbols',
        '023ce': 'NotoSansSymbols2',
        '023da-023db': 'NotoSansSymbols',
        '02423': 'NotoSansSymbols2',
        '02460-024ff': 'NotoSansSymbols',
        '02500-0259f': 'NotoSansCJKtc',
        '025a0-025ab': 'NotoSansSymbols2',
        '025b1-025b3': 'NotoSansSymbols2',
        '025b6-025b7': 'NotoSansSymbols2',
        '025bc-025bd': 'NotoSansSymbols2',
        '025c0-025c1': 'NotoSansSymbols2',
        '025c6-025cb': 'NotoSansSymbols2',
        '025ce-025d3': 'NotoSansSymbols2',
        '025e2-025e6': 'NotoSansSymbols2',
        '025ef': 'NotoSansSymbols2',
        '02600-02603': 'NotoSansSymbols2',
        '02605-02606': 'NotoSansSymbols2',
        '02609': 'NotoSansSymbols2',
        '0260e-0260f': 'NotoSansSymbols2',
        '02616-02617': 'NotoSansSymbols2',
        '0261c-0261f': 'NotoSansSymbols2',
        '0262f': 'NotoSansSymbols',
        '02640-02642': 'NotoSansSymbols',
        '02660-02668': 'NotoSansSymbols2',
        '02669-0266f': 'NotoSansSymbols',
        '02672-0267d': 'NotoSansSymbols',
        '026a0': 'NotoSansSymbols2',
        '026bd-026be': 'NotoSansSymbols2',
        '02702': 'NotoSansSymbols2',
        '02713': 'NotoSansSymbols2',
        '0271a': 'NotoSansSymbols2',
        '0273d': 'NotoSansSymbols2',
        '0273f-02740': 'NotoSansSymbols2',
        '02756': 'NotoSansSymbols2',
        '02776-0277f': 'NotoSansSymbols',
        '027a1': 'NotoSansSymbols2',
        '02934-02935': 'NotoSansCJKtc',
        '029bf': 'NotoSansSymbols2',
        '029fa-029fb': 'NotoSansCJKtc',
        '02b05-02b07': 'NotoSansSymbols2',
        '02b1a': 'NotoSansSymbols2',
        '02e3a-02e3b': 'NotoSansCJKtc',
        '02e80-02e99': 'NotoSansCJKtc',
        '02e9b-02ef3': 'NotoSansCJKtc',
        '02f00-02fd5': 'NotoSansCJKtc',
        '02ff0-02ffb': 'NotoSansCJKtc',
        '03000': 'NotoSansCJKtc',
        '03001-03002': 'NotoSansYi',
        '03003-03006': 'NotoSansCJKtc',
        '03007': 'NotoSansPhagsPa',
        '03008-03011': 'NotoSansYi',
        '03012-03013': 'NotoSansCJKtc',
        '03014-0301b': 'NotoSansYi',
        '0301c-0303f': 'NotoSansCJKtc',
        '03041-03096': 'NotoSansCJKtc',
        '03099-030fa': 'NotoSansCJKtc',
        '030fb': 'NotoSansYi',
        '030fc-030ff': 'NotoSansCJKtc',
        '03105-0312d': 'NotoSansCJKtc',
        '03131-0318e': 'NotoSansCJKtc',
        '03190-031ba': 'NotoSansCJKtc',
        '031c0-031e3': 'NotoSansCJKtc',
        '031f0-0321e': 'NotoSansCJKtc',
        '03220-032fe': 'NotoSansCJKtc',
        '03300-0332b': 'NotoSansCJKtc',
        '0332d-04db5': 'NotoSansCJKtc',
        '04e00-09fd0': 'NotoSansCJKtc',
        '0a960-0a97c': 'NotoSansCJKtc',
        '0ac00-0d7a3': 'NotoSansCJKtc',
        '0d7b0-0d7c6': 'NotoSansCJKtc',
        '0d7cb-0d7fb': 'NotoSansCJKtc',
        '0f900-0fa6d': 'NotoSansCJKtc',
        '0fb00-0fb04': 'NotoSansCJKtc',
        '0fe10-0fe19': 'NotoSansCJKtc',
        '0fe30-0fe3c': 'NotoSansCJKtc',
        '0fe3d-0fe3e': 'NotoSansMongolian',
        '0fe3f-0fe40': 'NotoSansCJKtc',
        '0fe41-0fe44': 'NotoSansMongolian',
        '0fe45-0fe52': 'NotoSansCJKtc',
        '0fe54-0fe66': 'NotoSansCJKtc',
        '0fe68-0fe6b': 'NotoSansCJKtc',
        '0ff01-0ff60': 'NotoSansCJKtc',
        '0ff61-0ff65': 'NotoSansYi',
        '0ff66-0ffbe': 'NotoSansCJKtc',
        '0ffc2-0ffc7': 'NotoSansCJKtc',
        '0ffca-0ffcf': 'NotoSansCJKtc',
        '0ffd2-0ffd7': 'NotoSansCJKtc',
        '0ffda-0ffdc': 'NotoSansCJKtc',
        '0ffe0-0ffe6': 'NotoSansCJKtc',
        '0ffe8-0ffee': 'NotoSansCJKtc',
        '1f100-1f10a': 'NotoSansSymbols',
        '1f110-1f12e': 'NotoSansSymbols',
        '1f130-1f16b': 'NotoSansSymbols',
        '1f170-1f190': 'NotoSansSymbols',
        '1f191-1f19a': 'NotoSansCJKtc',
        '1f200-1f202': 'NotoSansCJKtc',
        '1f210-1f23a': 'NotoSansCJKtc',
        '1f240-1f248': 'NotoSansCJKtc',
        '1f250-1f251': 'NotoSansCJKtc',
        '2000b': 'NotoSansCJKtc',
        '2003e': 'NotoSansCJKtc',
        '2004e': 'NotoSansCJKtc',
        '20086-20087': 'NotoSansCJKtc',
        '20089-2008a': 'NotoSansCJKtc',
        '200a2': 'NotoSansCJKtc',
        '200a4': 'NotoSansCJKtc',
        '200b0': 'NotoSansCJKtc',
        '200ca-200cd': 'NotoSansCJKtc',
        '200d1': 'NotoSansCJKtc',
        '200ee': 'NotoSansCJKtc',
        '200f5': 'NotoSansCJKtc',
        '2010c': 'NotoSansCJKtc',
        '2010e': 'NotoSansCJKtc',
        '201a2': 'NotoSansCJKtc',
        '201a4': 'NotoSansCJKtc',
        '201a9': 'NotoSansCJKtc',
        '201ab': 'NotoSansCJKtc',
        '201c1': 'NotoSansCJKtc',
        '201d4': 'NotoSansCJKtc',
        '201f2': 'NotoSansCJKtc',
        '2020c': 'NotoSansCJKtc',
        '20213-20214': 'NotoSansCJKtc',
        '2025b': 'NotoSansCJKtc',
        '20274-20275': 'NotoSansCJKtc',
        '2029e': 'NotoSansCJKtc',
        '202a0': 'NotoSansCJKtc',
        '202b7': 'NotoSansCJKtc',
        '202bf-202c0': 'NotoSansCJKtc',
        '202e5': 'NotoSansCJKtc',
        '2030a': 'NotoSansCJKtc',
        '2032b': 'NotoSansCJKtc',
        '20345-20347': 'NotoSansCJKtc',
        '2037e-20381': 'NotoSansCJKtc',
        '203a0': 'NotoSansCJKtc',
        '203a7': 'NotoSansCJKtc',
        '203b5': 'NotoSansCJKtc',
        '203c9': 'NotoSansCJKtc',
        '203cb': 'NotoSansCJKtc',
        '203f5': 'NotoSansCJKtc',
        '203f9': 'NotoSansCJKtc',
        '203fc': 'NotoSansCJKtc',
        '20413-20414': 'NotoSansCJKtc',
        '2041f': 'NotoSansCJKtc',
        '2044a': 'NotoSansCJKtc',
        '2048e': 'NotoSansCJKtc',
        '20491-20492': 'NotoSansCJKtc',
        '204a3': 'NotoSansCJKtc',
        '204d7': 'NotoSansCJKtc',
        '204fc': 'NotoSansCJKtc',
        '204fe': 'NotoSansCJKtc',
        '2053f': 'NotoSansCJKtc',
        '2058e': 'NotoSansCJKtc',
        '205a5': 'NotoSansCJKtc',
        '205b1': 'NotoSansCJKtc',
        '205b3': 'NotoSansCJKtc',
        '205c3': 'NotoSansCJKtc',
        '205ca': 'NotoSansCJKtc',
        '205d0': 'NotoSansCJKtc',
        '205d5-205d6': 'NotoSansCJKtc',
        '205df-205e0': 'NotoSansCJKtc',
        '205eb': 'NotoSansCJKtc',
        '20619-2061a': 'NotoSansCJKtc',
        '206ec': 'NotoSansCJKtc',
        '2070e': 'NotoSansCJKtc',
        '2074f': 'NotoSansCJKtc',
        '207c8': 'NotoSansCJKtc',
        '2082c': 'NotoSansCJKtc',
        '2083a': 'NotoSansCJKtc',
        '208b9': 'NotoSansCJKtc',
        '208d5': 'NotoSansCJKtc',
        '2090e': 'NotoSansCJKtc',
        '2097c': 'NotoSansCJKtc',
        '2099d': 'NotoSansCJKtc',
        '209e7': 'NotoSansCJKtc',
        '20a11': 'NotoSansCJKtc',
        '20a50': 'NotoSansCJKtc',
        '20a64': 'NotoSansCJKtc',
        '20a6f': 'NotoSansCJKtc',
        '20a8a': 'NotoSansCJKtc',
        '20ab4': 'NotoSansCJKtc',
        '20ac2': 'NotoSansCJKtc',
        '20acd': 'NotoSansCJKtc',
        '20ad3': 'NotoSansCJKtc',
        '20b0d': 'NotoSansCJKtc',
        '20b1d': 'NotoSansCJKtc',
        '20b8f': 'NotoSansCJKtc',
        '20b9f': 'NotoSansCJKtc',
        '20ba8-20ba9': 'NotoSansCJKtc',
        '20bb7': 'NotoSansCJKtc',
        '20bbf': 'NotoSansCJKtc',
        '20bc6': 'NotoSansCJKtc',
        '20bcb': 'NotoSansCJKtc',
        '20be2': 'NotoSansCJKtc',
        '20beb': 'NotoSansCJKtc',
        '20bfb': 'NotoSansCJKtc',
        '20bff': 'NotoSansCJKtc',
        '20c0b': 'NotoSansCJKtc',
        '20c0d': 'NotoSansCJKtc',
        '20c20': 'NotoSansCJKtc',
        '20c34': 'NotoSansCJKtc',
        '20c3a-20c3b': 'NotoSansCJKtc',
        '20c41-20c43': 'NotoSansCJKtc',
        '20c53': 'NotoSansCJKtc',
        '20c65': 'NotoSansCJKtc',
        '20c77-20c78': 'NotoSansCJKtc',
        '20c7c': 'NotoSansCJKtc',
        '20c8d': 'NotoSansCJKtc',
        '20c96': 'NotoSansCJKtc',
        '20c9c': 'NotoSansCJKtc',
        '20cb5': 'NotoSansCJKtc',
        '20cb8': 'NotoSansCJKtc',
        '20ccf-20cd0': 'NotoSansCJKtc',
        '20cd3-20cd6': 'NotoSansCJKtc',
        '20cdd': 'NotoSansCJKtc',
        '20ced': 'NotoSansCJKtc',
        '20cff': 'NotoSansCJKtc',
        '20d15': 'NotoSansCJKtc',
        '20d28': 'NotoSansCJKtc',
        '20d31-20d32': 'NotoSansCJKtc',
        '20d45-20d49': 'NotoSansCJKtc',
        '20d4c-20d4e': 'NotoSansCJKtc',
        '20d58': 'NotoSansCJKtc',
        '20d6f': 'NotoSansCJKtc',
        '20d71': 'NotoSansCJKtc',
        '20d74': 'NotoSansCJKtc',
        '20d7c': 'NotoSansCJKtc',
        '20d7e-20d7f': 'NotoSansCJKtc',
        '20d96': 'NotoSansCJKtc',
        '20d9c': 'NotoSansCJKtc',
        '20da7': 'NotoSansCJKtc',
        '20db2': 'NotoSansCJKtc',
        '20dc8': 'NotoSansCJKtc',
        '20de1': 'NotoSansCJKtc',
        '20e04': 'NotoSansCJKtc',
        '20e09-20e0a': 'NotoSansCJKtc',
        '20e0d-20e11': 'NotoSansCJKtc',
        '20e16': 'NotoSansCJKtc',
        '20e1d': 'NotoSansCJKtc',
        '20e4c': 'NotoSansCJKtc',
        '20e64': 'NotoSansCJKtc',
        '20e6d': 'NotoSansCJKtc',
        '20e73': 'NotoSansCJKtc',
        '20e75-20e7b': 'NotoSansCJKtc',
        '20e8c': 'NotoSansCJKtc',
        '20e95-20e96': 'NotoSansCJKtc',
        '20e98': 'NotoSansCJKtc',
        '20e9d': 'NotoSansCJKtc',
        '20ea2': 'NotoSansCJKtc',
        '20eaa-20eac': 'NotoSansCJKtc',
        '20eb6': 'NotoSansCJKtc',
        '20ed7-20ed8': 'NotoSansCJKtc',
        '20edd': 'NotoSansCJKtc',
        '20ef8-20efb': 'NotoSansCJKtc',
        '20f1d': 'NotoSansCJKtc',
        '20f26': 'NotoSansCJKtc',
        '20f2d-20f2e': 'NotoSansCJKtc',
        '20f30-20f31': 'NotoSansCJKtc',
        '20f3b': 'NotoSansCJKtc',
        '20f4c': 'NotoSansCJKtc',
        '20f5f': 'NotoSansCJKtc',
        '20f64': 'NotoSansCJKtc',
        '20f8d': 'NotoSansCJKtc',
        '20f90': 'NotoSansCJKtc',
        '20fad': 'NotoSansCJKtc',
        '20fb4-20fb6': 'NotoSansCJKtc',
        '20fbc': 'NotoSansCJKtc',
        '20fdf': 'NotoSansCJKtc',
        '20fea-20fed': 'NotoSansCJKtc',
        '2101d-2101e': 'NotoSansCJKtc',
        '2104f': 'NotoSansCJKtc',
        '2105c': 'NotoSansCJKtc',
        '2106f': 'NotoSansCJKtc',
        '21075-21078': 'NotoSansCJKtc',
        '2107b': 'NotoSansCJKtc',
        '2109d': 'NotoSansCJKtc',
        '210b4': 'NotoSansCJKtc',
        '210bf-210c1': 'NotoSansCJKtc',
        '210c7-210c9': 'NotoSansCJKtc',
        '210cf': 'NotoSansCJKtc',
        '210d3': 'NotoSansCJKtc',
        '210e4': 'NotoSansCJKtc',
        '210f4-210f6': 'NotoSansCJKtc',
        '2112f': 'NotoSansCJKtc',
        '2113b': 'NotoSansCJKtc',
        '2113d': 'NotoSansCJKtc',
        '2114f': 'NotoSansCJKtc',
        '211d9': 'NotoSansCJKtc',
        '2123c-2123d': 'NotoSansCJKtc',
        '2124f': 'NotoSansCJKtc',
        '2127b-2127c': 'NotoSansCJKtc',
        '212a8-212a9': 'NotoSansCJKtc',
        '212b0': 'NotoSansCJKtc',
        '212d7': 'NotoSansCJKtc',
        '212e3-212e4': 'NotoSansCJKtc',
        '212fd-212fe': 'NotoSansCJKtc',
        '21302-21305': 'NotoSansCJKtc',
        '2131b': 'NotoSansCJKtc',
        '2133a': 'NotoSansCJKtc',
        '21375-21376': 'NotoSansCJKtc',
        '2138e': 'NotoSansCJKtc',
        '2139a': 'NotoSansCJKtc',
        '2139c': 'NotoSansCJKtc',
        '213c4-213c6': 'NotoSansCJKtc',
        '213ed': 'NotoSansCJKtc',
        '213fe': 'NotoSansCJKtc',
        '2143f': 'NotoSansCJKtc',
        '21454-21455': 'NotoSansCJKtc',
        '2146d-2146e': 'NotoSansCJKtc',
        '2148a': 'NotoSansCJKtc',
        '214b6': 'NotoSansCJKtc',
        '214e8': 'NotoSansCJKtc',
        '214fd': 'NotoSansCJKtc',
        '215d7': 'NotoSansCJKtc',
        '2160a': 'NotoSansCJKtc',
        '2163e': 'NotoSansCJKtc',
        '216b4': 'NotoSansCJKtc',
        '216b8': 'NotoSansCJKtc',
        '216ba': 'NotoSansCJKtc',
        '216c0-216c2': 'NotoSansCJKtc',
        '216d3': 'NotoSansCJKtc',
        '216d5': 'NotoSansCJKtc',
        '216df': 'NotoSansCJKtc',
        '216e6-216e8': 'NotoSansCJKtc',
        '216fa-216fc': 'NotoSansCJKtc',
        '216fe': 'NotoSansCJKtc',
        '2170d': 'NotoSansCJKtc',
        '2173a-2173c': 'NotoSansCJKtc',
        '2176c-21771': 'NotoSansCJKtc',
        '21773-21774': 'NotoSansCJKtc',
        '217ab': 'NotoSansCJKtc',
        '217b0-217b5': 'NotoSansCJKtc',
        '217c3': 'NotoSansCJKtc',
        '217c7': 'NotoSansCJKtc',
        '217d9-217dc': 'NotoSansCJKtc',
        '217df': 'NotoSansCJKtc',
        '217ef': 'NotoSansCJKtc',
        '217f5-217f6': 'NotoSansCJKtc',
        '217f8-217fc': 'NotoSansCJKtc',
        '21828-2182a': 'NotoSansCJKtc',
        '2182d': 'NotoSansCJKtc',
        '21839-2183b': 'NotoSansCJKtc',
        '2185e': 'NotoSansCJKtc',
        '21861-21864': 'NotoSansCJKtc',
        '2187b': 'NotoSansCJKtc',
        '21883-21885': 'NotoSansCJKtc',
        '2189e-218a2': 'NotoSansCJKtc',
        '218bd-218bf': 'NotoSansCJKtc',
        '218d1': 'NotoSansCJKtc',
        '218d6-218d9': 'NotoSansCJKtc',
        '218fa': 'NotoSansCJKtc',
        '21903-21905': 'NotoSansCJKtc',
        '21910-21912': 'NotoSansCJKtc',
        '2191c': 'NotoSansCJKtc',
        '2193b': 'NotoSansCJKtc',
        '2196a': 'NotoSansCJKtc',
        '2197c': 'NotoSansCJKtc',
        '219c3': 'NotoSansCJKtc',
        '219db': 'NotoSansCJKtc',
        '219f3': 'NotoSansCJKtc',
        '21a1a': 'NotoSansCJKtc',
        '21a2d': 'NotoSansCJKtc',
        '21a34': 'NotoSansCJKtc',
        '21a45': 'NotoSansCJKtc',
        '21a4b': 'NotoSansCJKtc',
        '21a63': 'NotoSansCJKtc',
        '21b44': 'NotoSansCJKtc',
        '21bc1-21bc2': 'NotoSansCJKtc',
        '21c2a': 'NotoSansCJKtc',
        '21c56': 'NotoSansCJKtc',
        '21c70': 'NotoSansCJKtc',
        '21ca2': 'NotoSansCJKtc',
        '21ca5': 'NotoSansCJKtc',
        '21cac': 'NotoSansCJKtc',
        '21d2d': 'NotoSansCJKtc',
        '21d45-21d46': 'NotoSansCJKtc',
        '21d53': 'NotoSansCJKtc',
        '21d5e': 'NotoSansCJKtc',
        '21d62': 'NotoSansCJKtc',
        '21d78': 'NotoSansCJKtc',
        '21d90': 'NotoSansCJKtc',
        '21d92': 'NotoSansCJKtc',
        '21d9c': 'NotoSansCJKtc',
        '21da1': 'NotoSansCJKtc',
        '21db6-21db7': 'NotoSansCJKtc',
        '21dba': 'NotoSansCJKtc',
        '21dca': 'NotoSansCJKtc',
        '21dd1': 'NotoSansCJKtc',
        '21de0': 'NotoSansCJKtc',
        '21deb': 'NotoSansCJKtc',
        '21df9': 'NotoSansCJKtc',
        '21e1c': 'NotoSansCJKtc',
        '21e23': 'NotoSansCJKtc',
        '21e33-21e34': 'NotoSansCJKtc',
        '21e37': 'NotoSansCJKtc',
        '21e3d': 'NotoSansCJKtc',
        '21e89': 'NotoSansCJKtc',
        '21ea4': 'NotoSansCJKtc',
        '21ea8': 'NotoSansCJKtc',
        '21ec8': 'NotoSansCJKtc',
        '21ed5': 'NotoSansCJKtc',
        '21f0f': 'NotoSansCJKtc',
        '21f15': 'NotoSansCJKtc',
        '21f1e': 'NotoSansCJKtc',
        '21f6a': 'NotoSansCJKtc',
        '21f76': 'NotoSansCJKtc',
        '21f9e': 'NotoSansCJKtc',
        '21fa1': 'NotoSansCJKtc',
        '21fe8': 'NotoSansCJKtc',
        '21ffa': 'NotoSansCJKtc',
        '2207e': 'NotoSansCJKtc',
        '2209a': 'NotoSansCJKtc',
        '220c7': 'NotoSansCJKtc',
        '220fc': 'NotoSansCJKtc',
        '2212a': 'NotoSansCJKtc',
        '2215b': 'NotoSansCJKtc',
        '2217a-2217b': 'NotoSansCJKtc',
        '221a1': 'NotoSansCJKtc',
        '221c1': 'NotoSansCJKtc',
        '221c3': 'NotoSansCJKtc',
        '2227c': 'NotoSansCJKtc',
        '2231e': 'NotoSansCJKtc',
        '223ad': 'NotoSansCJKtc',
        '223bd': 'NotoSansCJKtc',
        '223d0': 'NotoSansCJKtc',
        '223d7': 'NotoSansCJKtc',
        '223fa': 'NotoSansCJKtc',
        '2248b': 'NotoSansCJKtc',
        '224b0': 'NotoSansCJKtc',
        '224bc': 'NotoSansCJKtc',
        '224c1': 'NotoSansCJKtc',
        '224c9': 'NotoSansCJKtc',
        '224cc': 'NotoSansCJKtc',
        '224ed': 'NotoSansCJKtc',
        '2251b': 'NotoSansCJKtc',
        '2258d': 'NotoSansCJKtc',
        '225af': 'NotoSansCJKtc',
        '225be': 'NotoSansCJKtc',
        '2261b-2261c': 'NotoSansCJKtc',
        '2262b': 'NotoSansCJKtc',
        '2267a': 'NotoSansCJKtc',
        '226f3-226f6': 'NotoSansCJKtc',
        '2271b': 'NotoSansCJKtc',
        '2271f': 'NotoSansCJKtc',
        '2272a': 'NotoSansCJKtc',
        '227b4-227b5': 'NotoSansCJKtc',
        '227cd': 'NotoSansCJKtc',
        '2285b': 'NotoSansCJKtc',
        '2285f-22860': 'NotoSansCJKtc',
        '228ab': 'NotoSansCJKtc',
        '228ad': 'NotoSansCJKtc',
        '228c1': 'NotoSansCJKtc',
        '228f7': 'NotoSansCJKtc',
        '2294f': 'NotoSansCJKtc',
        '2296b': 'NotoSansCJKtc',
        '2298f': 'NotoSansCJKtc',
        '22a66': 'NotoSansCJKtc',
        '22ab8': 'NotoSansCJKtc',
        '22acf': 'NotoSansCJKtc',
        '22ad5': 'NotoSansCJKtc',
        '22ae6': 'NotoSansCJKtc',
        '22ae8': 'NotoSansCJKtc',
        '22b0e': 'NotoSansCJKtc',
        '22b22': 'NotoSansCJKtc',
        '22b3f': 'NotoSansCJKtc',
        '22b43': 'NotoSansCJKtc',
        '22b46': 'NotoSansCJKtc',
        '22b4f-22b50': 'NotoSansCJKtc',
        '22b6a': 'NotoSansCJKtc',
        '22ba6': 'NotoSansCJKtc',
        '22bca': 'NotoSansCJKtc',
        '22bce': 'NotoSansCJKtc',
        '22c1d': 'NotoSansCJKtc',
        '22c24': 'NotoSansCJKtc',
        '22c26-22c27': 'NotoSansCJKtc',
        '22c38': 'NotoSansCJKtc',
        '22c4c': 'NotoSansCJKtc',
        '22c51': 'NotoSansCJKtc',
        '22c55': 'NotoSansCJKtc',
        '22c62': 'NotoSansCJKtc',
        '22c88': 'NotoSansCJKtc',
        '22c9b': 'NotoSansCJKtc',
        '22ca1': 'NotoSansCJKtc',
        '22ca9': 'NotoSansCJKtc',
        '22cb2': 'NotoSansCJKtc',
        '22cb7': 'NotoSansCJKtc',
        '22cc2': 'NotoSansCJKtc',
        '22cc6': 'NotoSansCJKtc',
        '22cc9': 'NotoSansCJKtc',
        '22d07-22d08': 'NotoSansCJKtc',
        '22d12': 'NotoSansCJKtc',
        '22d44': 'NotoSansCJKtc',
        '22d4c': 'NotoSansCJKtc',
        '22d67': 'NotoSansCJKtc',
        '22d8d': 'NotoSansCJKtc',
        '22d95': 'NotoSansCJKtc',
        '22da0': 'NotoSansCJKtc',
        '22da3-22da4': 'NotoSansCJKtc',
        '22db7': 'NotoSansCJKtc',
        '22de1': 'NotoSansCJKtc',
        '22dee': 'NotoSansCJKtc',
        '22e0d': 'NotoSansCJKtc',
        '22e36': 'NotoSansCJKtc',
        '22e42': 'NotoSansCJKtc',
        '22e78': 'NotoSansCJKtc',
        '22e8b': 'NotoSansCJKtc',
        '22eb3': 'NotoSansCJKtc',
        '22eef': 'NotoSansCJKtc',
        '22f74': 'NotoSansCJKtc',
        '22fcc': 'NotoSansCJKtc',
        '22fe3': 'NotoSansCJKtc',
        '22feb': 'NotoSansCJKtc',
        '2304b': 'NotoSansCJKtc',
        '2307d-2307e': 'NotoSansCJKtc',
        '2308e': 'NotoSansCJKtc',
        '230b7': 'NotoSansCJKtc',
        '230bc': 'NotoSansCJKtc',
        '230da': 'NotoSansCJKtc',
        '2313d': 'NotoSansCJKtc',
        '2317d': 'NotoSansCJKtc',
        '231a4-231a5': 'NotoSansCJKtc',
        '231b3': 'NotoSansCJKtc',
        '231b6': 'NotoSansCJKtc',
        '231c3-231c4': 'NotoSansCJKtc',
        '231c8-231c9': 'NotoSansCJKtc',
        '231ea': 'NotoSansCJKtc',
        '231f5': 'NotoSansCJKtc',
        '231f7-231f9': 'NotoSansCJKtc',
        '2320f': 'NotoSansCJKtc',
        '2322f': 'NotoSansCJKtc',
        '23231-23234': 'NotoSansCJKtc',
        '2325e': 'NotoSansCJKtc',
        '23289-2328a': 'NotoSansCJKtc',
        '232ab-232ad': 'NotoSansCJKtc',
        '232d2': 'NotoSansCJKtc',
        '232e0-232e1': 'NotoSansCJKtc',
        '2330a': 'NotoSansCJKtc',
        '2331f': 'NotoSansCJKtc',
        '233b4': 'NotoSansCJKtc',
        '233cc': 'NotoSansCJKtc',
        '233d0': 'NotoSansCJKtc',
        '233d2-233d3': 'NotoSansCJKtc',
        '233d5': 'NotoSansCJKtc',
        '233da': 'NotoSansCJKtc',
        '233de-233df': 'NotoSansCJKtc',
        '233e4': 'NotoSansCJKtc',
        '233e6': 'NotoSansCJKtc',
        '233f4-233f5': 'NotoSansCJKtc',
        '233f9-233fa': 'NotoSansCJKtc',
        '233fe': 'NotoSansCJKtc',
        '2343f': 'NotoSansCJKtc',
        '2344a-2344b': 'NotoSansCJKtc',
        '23450-23451': 'NotoSansCJKtc',
        '2346f': 'NotoSansCJKtc',
        '234e4-234e5': 'NotoSansCJKtc',
        '2355a': 'NotoSansCJKtc',
        '23594-23595': 'NotoSansCJKtc',
        '2359c': 'NotoSansCJKtc',
        '235bb': 'NotoSansCJKtc',
        '235c4': 'NotoSansCJKtc',
        '235cb': 'NotoSansCJKtc',
        '235cd-235cf': 'NotoSansCJKtc',
        '235f3': 'NotoSansCJKtc',
        '2361a': 'NotoSansCJKtc',
        '23638-2363a': 'NotoSansCJKtc',
        '2363c': 'NotoSansCJKtc',
        '2365f': 'NotoSansCJKtc',
        '2368e': 'NotoSansCJKtc',
        '2369e': 'NotoSansCJKtc',
        '236a6': 'NotoSansCJKtc',
        '236ad': 'NotoSansCJKtc',
        '236ba': 'NotoSansCJKtc',
        '236df': 'NotoSansCJKtc',
        '236ee': 'NotoSansCJKtc',
        '2370c': 'NotoSansCJKtc',
        '2371c': 'NotoSansCJKtc',
        '2372d': 'NotoSansCJKtc',
        '2372f': 'NotoSansCJKtc',
        '2373f': 'NotoSansCJKtc',
        '23763-23764': 'NotoSansCJKtc',
        '237a2': 'NotoSansCJKtc',
        '237bc': 'NotoSansCJKtc',
        '237c2': 'NotoSansCJKtc',
        '237d5-237d7': 'NotoSansCJKtc',
        '237e7': 'NotoSansCJKtc',
        '237f1': 'NotoSansCJKtc',
        '237ff': 'NotoSansCJKtc',
        '2383a': 'NotoSansCJKtc',
        '2383d': 'NotoSansCJKtc',
        '239c2': 'NotoSansCJKtc',
        '23a98': 'NotoSansCJKtc',
        '23aa7': 'NotoSansCJKtc',
        '23adb': 'NotoSansCJKtc',
        '23aee': 'NotoSansCJKtc',
        '23afa': 'NotoSansCJKtc',
        '23b1a': 'NotoSansCJKtc',
        '23b5a': 'NotoSansCJKtc',
        '23c63': 'NotoSansCJKtc',
        '23c7f': 'NotoSansCJKtc',
        '23c97-23c9b': 'NotoSansCJKtc',
        '23cb5': 'NotoSansCJKtc',
        '23cb7': 'NotoSansCJKtc',
        '23cbe': 'NotoSansCJKtc',
        '23cc7-23cc9': 'NotoSansCJKtc',
        '23cfc-23d00': 'NotoSansCJKtc',
        '23d0e': 'NotoSansCJKtc',
        '23d40': 'NotoSansCJKtc',
        '23d5b': 'NotoSansCJKtc',
        '23d7e': 'NotoSansCJKtc',
        '23d8f': 'NotoSansCJKtc',
        '23db6-23dbd': 'NotoSansCJKtc',
        '23dd3': 'NotoSansCJKtc',
        '23de3': 'NotoSansCJKtc',
        '23df8-23dfa': 'NotoSansCJKtc',
        '23e06': 'NotoSansCJKtc',
        '23e11': 'NotoSansCJKtc',
        '23e23': 'NotoSansCJKtc',
        '23e2c-23e31': 'NotoSansCJKtc',
        '23e39': 'NotoSansCJKtc',
        '23e88-23e8b': 'NotoSansCJKtc',
        '23eb9': 'NotoSansCJKtc',
        '23ebf': 'NotoSansCJKtc',
        '23ed7': 'NotoSansCJKtc',
        '23ef7-23efc': 'NotoSansCJKtc',
        '23f35': 'NotoSansCJKtc',
        '23f41': 'NotoSansCJKtc',
        '23f4a': 'NotoSansCJKtc',
        '23f61': 'NotoSansCJKtc',
        '23f7e-23f82': 'NotoSansCJKtc',
        '23f8f': 'NotoSansCJKtc',
        '23fb4': 'NotoSansCJKtc',
        '23fb7': 'NotoSansCJKtc',
        '23fc0': 'NotoSansCJKtc',
        '23fc5': 'NotoSansCJKtc',
        '23feb-23ff0': 'NotoSansCJKtc',
        '24039-2403d': 'NotoSansCJKtc',
        '2404b': 'NotoSansCJKtc',
        '2408b-2408d': 'NotoSansCJKtc',
        '240c9': 'NotoSansCJKtc',
        '240e1': 'NotoSansCJKtc',
        '240ec': 'NotoSansCJKtc',
        '24103-24104': 'NotoSansCJKtc',
        '2410f': 'NotoSansCJKtc',
        '2413f-24140': 'NotoSansCJKtc',
        '2414e': 'NotoSansCJKtc',
        '24155-24157': 'NotoSansCJKtc',
        '2415c': 'NotoSansCJKtc',
        '2415f': 'NotoSansCJKtc',
        '2417a': 'NotoSansCJKtc',
        '241a3-241a5': 'NotoSansCJKtc',
        '241ac': 'NotoSansCJKtc',
        '241b5': 'NotoSansCJKtc',
        '241c6': 'NotoSansCJKtc',
        '241cd': 'NotoSansCJKtc',
        '241e2': 'NotoSansCJKtc',
        '241fc': 'NotoSansCJKtc',
        '241fe': 'NotoSansCJKtc',
        '2421b': 'NotoSansCJKtc',
        '2424b': 'NotoSansCJKtc',
        '24276-24278': 'NotoSansCJKtc',
        '242a5': 'NotoSansCJKtc',
        '242bf': 'NotoSansCJKtc',
        '242c1': 'NotoSansCJKtc',
        '242c9-242ca': 'NotoSansCJKtc',
        '242ee': 'NotoSansCJKtc',
        '242fa': 'NotoSansCJKtc',
        '2430d': 'NotoSansCJKtc',
        '2431a': 'NotoSansCJKtc',
        '24362-24365': 'NotoSansCJKtc',
        '2438c': 'NotoSansCJKtc',
        '2439c': 'NotoSansCJKtc',
        '243bc-243bd': 'NotoSansCJKtc',
        '243c1': 'NotoSansCJKtc',
        '243d0': 'NotoSansCJKtc',
        '243e9-243ea': 'NotoSansCJKtc',
        '243f2': 'NotoSansCJKtc',
        '243f8': 'NotoSansCJKtc',
        '24435-24436': 'NotoSansCJKtc',
        '2445a-2445b': 'NotoSansCJKtc',
        '24487-24488': 'NotoSansCJKtc',
        '244b9': 'NotoSansCJKtc',
        '244bc': 'NotoSansCJKtc',
        '244ce': 'NotoSansCJKtc',
        '244d3': 'NotoSansCJKtc',
        '244d6': 'NotoSansCJKtc',
        '245c8': 'NotoSansCJKtc',
        '24629-2462a': 'NotoSansCJKtc',
        '246a5': 'NotoSansCJKtc',
        '246d4': 'NotoSansCJKtc',
        '2472f': 'NotoSansCJKtc',
        '2478f': 'NotoSansCJKtc',
        '247e0': 'NotoSansCJKtc',
        '247f1': 'NotoSansCJKtc',
        '248e9': 'NotoSansCJKtc',
        '248f0-248f3': 'NotoSansCJKtc',
        '248fb': 'NotoSansCJKtc',
        '248ff-24901': 'NotoSansCJKtc',
        '2490c': 'NotoSansCJKtc',
        '24916-24917': 'NotoSansCJKtc',
        '2492f': 'NotoSansCJKtc',
        '24933-24934': 'NotoSansCJKtc',
        '2493e-24943': 'NotoSansCJKtc',
        '24962-24963': 'NotoSansCJKtc',
        '24974-24976': 'NotoSansCJKtc',
        '2497b': 'NotoSansCJKtc',
        '2497f': 'NotoSansCJKtc',
        '24988-2498f': 'NotoSansCJKtc',
        '249a4': 'NotoSansCJKtc',
        '249a7': 'NotoSansCJKtc',
        '249a9': 'NotoSansCJKtc',
        '249ab-249ad': 'NotoSansCJKtc',
        '249b7-249bb': 'NotoSansCJKtc',
        '249c5': 'NotoSansCJKtc',
        '249d0': 'NotoSansCJKtc',
        '249da-249db': 'NotoSansCJKtc',
        '249de-249df': 'NotoSansCJKtc',
        '249e3': 'NotoSansCJKtc',
        '249e5': 'NotoSansCJKtc',
        '249ec-249ed': 'NotoSansCJKtc',
        '249f6-249f9': 'NotoSansCJKtc',
        '249fb': 'NotoSansCJKtc',
        '24a0e': 'NotoSansCJKtc',
        '24a12-24a13': 'NotoSansCJKtc',
        '24a15': 'NotoSansCJKtc',
        '24a21-24a2a': 'NotoSansCJKtc',
        '24a3e': 'NotoSansCJKtc',
        '24a42': 'NotoSansCJKtc',
        '24a45': 'NotoSansCJKtc',
        '24a4a': 'NotoSansCJKtc',
        '24a4d-24a51': 'NotoSansCJKtc',
        '24a5d': 'NotoSansCJKtc',
        '24a65-24a67': 'NotoSansCJKtc',
        '24a71': 'NotoSansCJKtc',
        '24a77-24a7a': 'NotoSansCJKtc',
        '24a7d': 'NotoSansCJKtc',
        '24a8c': 'NotoSansCJKtc',
        '24a93-24a96': 'NotoSansCJKtc',
        '24aa4-24aa7': 'NotoSansCJKtc',
        '24ab1-24ab3': 'NotoSansCJKtc',
        '24aba-24abc': 'NotoSansCJKtc',
        '24ac0': 'NotoSansCJKtc',
        '24ac7': 'NotoSansCJKtc',
        '24ac9-24aca': 'NotoSansCJKtc',
        '24ad1': 'NotoSansCJKtc',
        '24adf': 'NotoSansCJKtc',
        '24ae2': 'NotoSansCJKtc',
        '24ae9': 'NotoSansCJKtc',
        '24b0f': 'NotoSansCJKtc',
        '24b56': 'NotoSansCJKtc',
        '24b6e-24b6f': 'NotoSansCJKtc',
        '24bf5': 'NotoSansCJKtc',
        '24c09': 'NotoSansCJKtc',
        '24c16': 'NotoSansCJKtc',
        '24c9e-24c9f': 'NotoSansCJKtc',
        '24cc9': 'NotoSansCJKtc',
        '24cd9': 'NotoSansCJKtc',
        '24d06': 'NotoSansCJKtc',
        '24d13-24d14': 'NotoSansCJKtc',
        '24db8': 'NotoSansCJKtc',
        '24dea-24deb': 'NotoSansCJKtc',
        '24e04': 'NotoSansCJKtc',
        '24e0e': 'NotoSansCJKtc',
        '24e37': 'NotoSansCJKtc',
        '24e3b': 'NotoSansCJKtc',
        '24e50': 'NotoSansCJKtc',
        '24e6a': 'NotoSansCJKtc',
        '24e8b': 'NotoSansCJKtc',
        '24ea5': 'NotoSansCJKtc',
        '24ea7': 'NotoSansCJKtc',
        '24f0e': 'NotoSansCJKtc',
        '24f5c': 'NotoSansCJKtc',
        '24f82': 'NotoSansCJKtc',
        '24f86': 'NotoSansCJKtc',
        '24f97': 'NotoSansCJKtc',
        '24f9a': 'NotoSansCJKtc',
        '24fa9': 'NotoSansCJKtc',
        '24fb8': 'NotoSansCJKtc',
        '24fc2': 'NotoSansCJKtc',
        '24ff2': 'NotoSansCJKtc',
        '2502c': 'NotoSansCJKtc',
        '2504a': 'NotoSansCJKtc',
        '2509d': 'NotoSansCJKtc',
        '2512b': 'NotoSansCJKtc',
        '2517d-2517e': 'NotoSansCJKtc',
        '251a9': 'NotoSansCJKtc',
        '251cd': 'NotoSansCJKtc',
        '251e3': 'NotoSansCJKtc',
        '251e5-251e7': 'NotoSansCJKtc',
        '2521e': 'NotoSansCJKtc',
        '25220-25221': 'NotoSansCJKtc',
        '2524c': 'NotoSansCJKtc',
        '252c7': 'NotoSansCJKtc',
        '252d8': 'NotoSansCJKtc',
        '2530e': 'NotoSansCJKtc',
        '2542e-25430': 'NotoSansCJKtc',
        '2546c': 'NotoSansCJKtc',
        '2546e': 'NotoSansCJKtc',
        '2548e': 'NotoSansCJKtc',
        '2549a': 'NotoSansCJKtc',
        '254d9': 'NotoSansCJKtc',
        '2550e': 'NotoSansCJKtc',
        '25531-25532': 'NotoSansCJKtc',
        '2553f': 'NotoSansCJKtc',
        '2555b-2555e': 'NotoSansCJKtc',
        '25565-25566': 'NotoSansCJKtc',
        '2558f': 'NotoSansCJKtc',
        '255a7-255a8': 'NotoSansCJKtc',
        '255b9': 'NotoSansCJKtc',
        '255d5': 'NotoSansCJKtc',
        '255db': 'NotoSansCJKtc',
        '255e0': 'NotoSansCJKtc',
        '2567f': 'NotoSansCJKtc',
        '256e3': 'NotoSansCJKtc',
        '256f6': 'NotoSansCJKtc',
        '2571d': 'NotoSansCJKtc',
        '2573d': 'NotoSansCJKtc',
        '25771-25772': 'NotoSansCJKtc',
        '257a9': 'NotoSansCJKtc',
        '257b4': 'NotoSansCJKtc',
        '257c7': 'NotoSansCJKtc',
        '257df-257e1': 'NotoSansCJKtc',
        '2585d': 'NotoSansCJKtc',
        '258c8': 'NotoSansCJKtc',
        '258de': 'NotoSansCJKtc',
        '258e1': 'NotoSansCJKtc',
        '259ac': 'NotoSansCJKtc',
        '259c4': 'NotoSansCJKtc',
        '259cc': 'NotoSansCJKtc',
        '259d4': 'NotoSansCJKtc',
        '25a54': 'NotoSansCJKtc',
        '25a95': 'NotoSansCJKtc',
        '25a9c': 'NotoSansCJKtc',
        '25aae-25aaf': 'NotoSansCJKtc',
        '25ad7': 'NotoSansCJKtc',
        '25ae3-25ae4': 'NotoSansCJKtc',
        '25ae9': 'NotoSansCJKtc',
        '25af1': 'NotoSansCJKtc',
        '25b74': 'NotoSansCJKtc',
        '25b89': 'NotoSansCJKtc',
        '25bb2-25bb4': 'NotoSansCJKtc',
        '25bc6': 'NotoSansCJKtc',
        '25be4': 'NotoSansCJKtc',
        '25be8': 'NotoSansCJKtc',
        '25c01': 'NotoSansCJKtc',
        '25c06': 'NotoSansCJKtc',
        '25c21': 'NotoSansCJKtc',
        '25c4a-25c4b': 'NotoSansCJKtc',
        '25c64-25c65': 'NotoSansCJKtc',
        '25c91': 'NotoSansCJKtc',
        '25ca4': 'NotoSansCJKtc',
        '25cc0-25cc1': 'NotoSansCJKtc',
        '25cfe': 'NotoSansCJKtc',
        '25d20': 'NotoSansCJKtc',
        '25d30': 'NotoSansCJKtc',
        '25d43': 'NotoSansCJKtc',
        '25d99': 'NotoSansCJKtc',
        '25da1': 'NotoSansCJKtc',
        '25db9': 'NotoSansCJKtc',
        '25e0e': 'NotoSansCJKtc',
        '25e2e': 'NotoSansCJKtc',
        '25e49': 'NotoSansCJKtc',
        '25e56': 'NotoSansCJKtc',
        '25e62': 'NotoSansCJKtc',
        '25e65': 'NotoSansCJKtc',
        '25e81-25e83': 'NotoSansCJKtc',
        '25ea6': 'NotoSansCJKtc',
        '25ebc': 'NotoSansCJKtc',
        '25ec2': 'NotoSansCJKtc',
        '25ed7-25ed8': 'NotoSansCJKtc',
        '25ee8': 'NotoSansCJKtc',
        '25f1a': 'NotoSansCJKtc',
        '25f23': 'NotoSansCJKtc',
        '25f4b': 'NotoSansCJKtc',
        '25f5c': 'NotoSansCJKtc',
        '25fd4': 'NotoSansCJKtc',
        '25fe0-25fe2': 'NotoSansCJKtc',
        '25ffb': 'NotoSansCJKtc',
        '2600c': 'NotoSansCJKtc',
        '260a4-260a5': 'NotoSansCJKtc',
        '260ed': 'NotoSansCJKtc',
        '26159-2615c': 'NotoSansCJKtc',
        '261ad-261ae': 'NotoSansCJKtc',
        '261b2': 'NotoSansCJKtc',
        '261dd': 'NotoSansCJKtc',
        '26221-26222': 'NotoSansCJKtc',
        '2626a-2626b': 'NotoSansCJKtc',
        '262d0': 'NotoSansCJKtc',
        '2634b-2634c': 'NotoSansCJKtc',
        '263be': 'NotoSansCJKtc',
        '263f5': 'NotoSansCJKtc',
        '263f8': 'NotoSansCJKtc',
        '26410-26412': 'NotoSansCJKtc',
        '2644a': 'NotoSansCJKtc',
        '26488-26489': 'NotoSansCJKtc',
        '2648d': 'NotoSansCJKtc',
        '265a0': 'NotoSansCJKtc',
        '265ad': 'NotoSansCJKtc',
        '265bf': 'NotoSansCJKtc',
        '2667e': 'NotoSansCJKtc',
        '266af-266b1': 'NotoSansCJKtc',
        '266b5': 'NotoSansCJKtc',
        '266da': 'NotoSansCJKtc',
        '266e8': 'NotoSansCJKtc',
        '266fc': 'NotoSansCJKtc',
        '2671d': 'NotoSansCJKtc',
        '2677c': 'NotoSansCJKtc',
        '267b3-267b4': 'NotoSansCJKtc',
        '267cc': 'NotoSansCJKtc',
        '2681c': 'NotoSansCJKtc',
        '2685e': 'NotoSansCJKtc',
        '2686e': 'NotoSansCJKtc',
        '2688a': 'NotoSansCJKtc',
        '268c7': 'NotoSansCJKtc',
        '268dd': 'NotoSansCJKtc',
        '268ea': 'NotoSansCJKtc',
        '2690e': 'NotoSansCJKtc',
        '2696f': 'NotoSansCJKtc',
        '269a8': 'NotoSansCJKtc',
        '269b5': 'NotoSansCJKtc',
        '269dd': 'NotoSansCJKtc',
        '269f2': 'NotoSansCJKtc',
        '269fa': 'NotoSansCJKtc',
        '26a1e': 'NotoSansCJKtc',
        '26a2d-26a2e': 'NotoSansCJKtc',
        '26a34': 'NotoSansCJKtc',
        '26a42': 'NotoSansCJKtc',
        '26a51-26a52': 'NotoSansCJKtc',
        '26a58': 'NotoSansCJKtc',
        '26a8c': 'NotoSansCJKtc',
        '26ab7': 'NotoSansCJKtc',
        '26aff': 'NotoSansCJKtc',
        '26b05': 'NotoSansCJKtc',
        '26b0a': 'NotoSansCJKtc',
        '26b13': 'NotoSansCJKtc',
        '26b15': 'NotoSansCJKtc',
        '26b23': 'NotoSansCJKtc',
        '26b28': 'NotoSansCJKtc',
        '26b50-26b53': 'NotoSansCJKtc',
        '26b5b-26b5c': 'NotoSansCJKtc',
        '26b75': 'NotoSansCJKtc',
        '26b82': 'NotoSansCJKtc',
        '26b96-26b97': 'NotoSansCJKtc',
        '26b9d': 'NotoSansCJKtc',
        '26bb3': 'NotoSansCJKtc',
        '26bc0': 'NotoSansCJKtc',
        '26bf7': 'NotoSansCJKtc',
        '26c21': 'NotoSansCJKtc',
        '26c29': 'NotoSansCJKtc',
        '26c40-26c41': 'NotoSansCJKtc',
        '26c46': 'NotoSansCJKtc',
        '26c73': 'NotoSansCJKtc',
        '26c7e-26c82': 'NotoSansCJKtc',
        '26c9e': 'NotoSansCJKtc',
        '26ca4': 'NotoSansCJKtc',
        '26cb7-26cb8': 'NotoSansCJKtc',
        '26cbd': 'NotoSansCJKtc',
        '26cc0': 'NotoSansCJKtc',
        '26cc3': 'NotoSansCJKtc',
        '26cd1': 'NotoSansCJKtc',
        '26cdd': 'NotoSansCJKtc',
        '26d22-26d2a': 'NotoSansCJKtc',
        '26d51': 'NotoSansCJKtc',
        '26d74': 'NotoSansCJKtc',
        '26da0-26da7': 'NotoSansCJKtc',
        '26dae': 'NotoSansCJKtc',
        '26ddc': 'NotoSansCJKtc',
        '26dea-26deb': 'NotoSansCJKtc',
        '26df0': 'NotoSansCJKtc',
        '26e00': 'NotoSansCJKtc',
        '26e05': 'NotoSansCJKtc',
        '26e07': 'NotoSansCJKtc',
        '26e12': 'NotoSansCJKtc',
        '26e40': 'NotoSansCJKtc',
        '26e42-26e45': 'NotoSansCJKtc',
        '26e65': 'NotoSansCJKtc',
        '26e6e': 'NotoSansCJKtc',
        '26e72': 'NotoSansCJKtc',
        '26e77': 'NotoSansCJKtc',
        '26e84': 'NotoSansCJKtc',
        '26e88': 'NotoSansCJKtc',
        '26e8b': 'NotoSansCJKtc',
        '26e99': 'NotoSansCJKtc',
        '26ed0-26ed7': 'NotoSansCJKtc',
        '26f26': 'NotoSansCJKtc',
        '26f73-26f74': 'NotoSansCJKtc',
        '26f94': 'NotoSansCJKtc',
        '26f9f': 'NotoSansCJKtc',
        '26fa1': 'NotoSansCJKtc',
        '26fbe': 'NotoSansCJKtc',
        '26fde-26fdf': 'NotoSansCJKtc',
        '26ff6-26ff8': 'NotoSansCJKtc',
        '2700e': 'NotoSansCJKtc',
        '2704b': 'NotoSansCJKtc',
        '27052-27053': 'NotoSansCJKtc',
        '270ad-270af': 'NotoSansCJKtc',
        '270cd': 'NotoSansCJKtc',
        '270d2': 'NotoSansCJKtc',
        '270f4': 'NotoSansCJKtc',
        '270f8': 'NotoSansCJKtc',
        '2710c-2710d': 'NotoSansCJKtc',
        '27126-27127': 'NotoSansCJKtc',
        '27164-27165': 'NotoSansCJKtc',
        '271cd': 'NotoSansCJKtc',
        '2721b': 'NotoSansCJKtc',
        '2728b': 'NotoSansCJKtc',
        '272b2': 'NotoSansCJKtc',
        '272b6': 'NotoSansCJKtc',
        '272e6': 'NotoSansCJKtc',
        '2739a': 'NotoSansCJKtc',
        '273da-273db': 'NotoSansCJKtc',
        '273fe-273ff': 'NotoSansCJKtc',
        '275a3': 'NotoSansCJKtc',
        '275e0': 'NotoSansCJKtc',
        '275e4': 'NotoSansCJKtc',
        '275fd-275fe': 'NotoSansCJKtc',
        '2760c': 'NotoSansCJKtc',
        '27614-27615': 'NotoSansCJKtc',
        '27631-27632': 'NotoSansCJKtc',
        '27655-27657': 'NotoSansCJKtc',
        '27693-27694': 'NotoSansCJKtc',
        '2770e-2770f': 'NotoSansCJKtc',
        '27735-27736': 'NotoSansCJKtc',
        '2775e': 'NotoSansCJKtc',
        '27784-27785': 'NotoSansCJKtc',
        '277cc': 'NotoSansCJKtc',
        '2789d': 'NotoSansCJKtc',
        '278b2': 'NotoSansCJKtc',
        '278c8': 'NotoSansCJKtc',
        '2797a': 'NotoSansCJKtc',
        '279a0': 'NotoSansCJKtc',
        '279b4': 'NotoSansCJKtc',
        '279dd': 'NotoSansCJKtc',
        '279fd': 'NotoSansCJKtc',
        '27a0a': 'NotoSansCJKtc',
        '27a0e': 'NotoSansCJKtc',
        '27a3e': 'NotoSansCJKtc',
        '27a53': 'NotoSansCJKtc',
        '27a59': 'NotoSansCJKtc',
        '27a79': 'NotoSansCJKtc',
        '27a84': 'NotoSansCJKtc',
        '27abd-27abe': 'NotoSansCJKtc',
        '27af4': 'NotoSansCJKtc',
        '27b06': 'NotoSansCJKtc',
        '27b0b': 'NotoSansCJKtc',
        '27b18': 'NotoSansCJKtc',
        '27b38-27b3a': 'NotoSansCJKtc',
        '27b48': 'NotoSansCJKtc',
        '27b65': 'NotoSansCJKtc',
        '27bb3': 'NotoSansCJKtc',
        '27bbe': 'NotoSansCJKtc',
        '27bc7': 'NotoSansCJKtc',
        '27bef': 'NotoSansCJKtc',
        '27bf4': 'NotoSansCJKtc',
        '27c12': 'NotoSansCJKtc',
        '27c3c': 'NotoSansCJKtc',
        '27c6c': 'NotoSansCJKtc',
        '27cb1': 'NotoSansCJKtc',
        '27cb8': 'NotoSansCJKtc',
        '27cc5': 'NotoSansCJKtc',
        '27d2f': 'NotoSansCJKtc',
        '27d53-27d54': 'NotoSansCJKtc',
        '27d66': 'NotoSansCJKtc',
        '27d73': 'NotoSansCJKtc',
        '27d84': 'NotoSansCJKtc',
        '27d8f': 'NotoSansCJKtc',
        '27d98': 'NotoSansCJKtc',
        '27da0': 'NotoSansCJKtc',
        '27dbd': 'NotoSansCJKtc',
        '27ddc': 'NotoSansCJKtc',
        '27e10': 'NotoSansCJKtc',
        '27e4d': 'NotoSansCJKtc',
        '27e4f': 'NotoSansCJKtc',
        '27f2e': 'NotoSansCJKtc',
        '27fb7': 'NotoSansCJKtc',
        '27ff9': 'NotoSansCJKtc',
        '2801e': 'NotoSansCJKtc',
        '28023-28024': 'NotoSansCJKtc',
        '2808a': 'NotoSansCJKtc',
        '280bb': 'NotoSansCJKtc',
        '280bd-280be': 'NotoSansCJKtc',
        '280e8-280e9': 'NotoSansCJKtc',
        '280f4': 'NotoSansCJKtc',
        '2812e': 'NotoSansCJKtc',
        '2814f': 'NotoSansCJKtc',
        '2815d': 'NotoSansCJKtc',
        '2816f': 'NotoSansCJKtc',
        '281af': 'NotoSansCJKtc',
        '281bc': 'NotoSansCJKtc',
        '2821a': 'NotoSansCJKtc',
        '2827c': 'NotoSansCJKtc',
        '2829b': 'NotoSansCJKtc',
        '282cd': 'NotoSansCJKtc',
        '282e2': 'NotoSansCJKtc',
        '282f3': 'NotoSansCJKtc',
        '2832f': 'NotoSansCJKtc',
        '2833a': 'NotoSansCJKtc',
        '2836d': 'NotoSansCJKtc',
        '2837d': 'NotoSansCJKtc',
        '2838a': 'NotoSansCJKtc',
        '283cd': 'NotoSansCJKtc',
        '2840c': 'NotoSansCJKtc',
        '2846c': 'NotoSansCJKtc',
        '2853c-2853d': 'NotoSansCJKtc',
        '2856b-2856c': 'NotoSansCJKtc',
        '285c8-285c9': 'NotoSansCJKtc',
        '285e8': 'NotoSansCJKtc',
        '285f4': 'NotoSansCJKtc',
        '2860b': 'NotoSansCJKtc',
        '2863b': 'NotoSansCJKtc',
        '286aa-286ab': 'NotoSansCJKtc',
        '286b2': 'NotoSansCJKtc',
        '286bc': 'NotoSansCJKtc',
        '286d7-286d8': 'NotoSansCJKtc',
        '286e6': 'NotoSansCJKtc',
        '286fa': 'NotoSansCJKtc',
        '2870f': 'NotoSansCJKtc',
        '287e0': 'NotoSansCJKtc',
        '2882b': 'NotoSansCJKtc',
        '2890d': 'NotoSansCJKtc',
        '28948-28949': 'NotoSansCJKtc',
        '2896b-2896d': 'NotoSansCJKtc',
        '2897e': 'NotoSansCJKtc',
        '28987-28989': 'NotoSansCJKtc',
        '289a8': 'NotoSansCJKtc',
        '289aa-289ab': 'NotoSansCJKtc',
        '289b8': 'NotoSansCJKtc',
        '289ba-289bc': 'NotoSansCJKtc',
        '289c0': 'NotoSansCJKtc',
        '289dc': 'NotoSansCJKtc',
        '289de': 'NotoSansCJKtc',
        '289e1': 'NotoSansCJKtc',
        '289e3-289e4': 'NotoSansCJKtc',
        '289e7-289e8': 'NotoSansCJKtc',
        '289f9-289fc': 'NotoSansCJKtc',
        '28a0f': 'NotoSansCJKtc',
        '28a16': 'NotoSansCJKtc',
        '28a1e': 'NotoSansCJKtc',
        '28a25': 'NotoSansCJKtc',
        '28a29': 'NotoSansCJKtc',
        '28a32': 'NotoSansCJKtc',
        '28a36': 'NotoSansCJKtc',
        '28a43-28a4b': 'NotoSansCJKtc',
        '28a59-28a5a': 'NotoSansCJKtc',
        '28a71': 'NotoSansCJKtc',
        '28a81-28a83': 'NotoSansCJKtc',
        '28a99-28a9c': 'NotoSansCJKtc',
        '28ac0': 'NotoSansCJKtc',
        '28ac6': 'NotoSansCJKtc',
        '28acb-28ace': 'NotoSansCJKtc',
        '28add-28ae5': 'NotoSansCJKtc',
        '28aea': 'NotoSansCJKtc',
        '28afc': 'NotoSansCJKtc',
        '28b0c': 'NotoSansCJKtc',
        '28b13': 'NotoSansCJKtc',
        '28b21-28b22': 'NotoSansCJKtc',
        '28b2b-28b2d': 'NotoSansCJKtc',
        '28b2f': 'NotoSansCJKtc',
        '28b46': 'NotoSansCJKtc',
        '28b49': 'NotoSansCJKtc',
        '28b4c': 'NotoSansCJKtc',
        '28b4e': 'NotoSansCJKtc',
        '28b50': 'NotoSansCJKtc',
        '28b63-28b66': 'NotoSansCJKtc',
        '28b6c': 'NotoSansCJKtc',
        '28b8f': 'NotoSansCJKtc',
        '28b99': 'NotoSansCJKtc',
        '28b9c-28b9d': 'NotoSansCJKtc',
        '28bb9': 'NotoSansCJKtc',
        '28bc1-28bc2': 'NotoSansCJKtc',
        '28bc5': 'NotoSansCJKtc',
        '28bd4': 'NotoSansCJKtc',
        '28bd7': 'NotoSansCJKtc',
        '28bd9-28bda': 'NotoSansCJKtc',
        '28be7-28bec': 'NotoSansCJKtc',
        '28bef': 'NotoSansCJKtc',
        '28bf5': 'NotoSansCJKtc',
        '28bff': 'NotoSansCJKtc',
        '28c03': 'NotoSansCJKtc',
        '28c09': 'NotoSansCJKtc',
        '28c1c-28c1d': 'NotoSansCJKtc',
        '28c23': 'NotoSansCJKtc',
        '28c26': 'NotoSansCJKtc',
        '28c2b': 'NotoSansCJKtc',
        '28c30': 'NotoSansCJKtc',
        '28c39': 'NotoSansCJKtc',
        '28c3b': 'NotoSansCJKtc',
        '28c47': 'NotoSansCJKtc',
        '28c4f': 'NotoSansCJKtc',
        '28c51': 'NotoSansCJKtc',
        '28c54': 'NotoSansCJKtc',
        '28cca': 'NotoSansCJKtc',
        '28ccd': 'NotoSansCJKtc',
        '28cd2': 'NotoSansCJKtc',
        '28cdd': 'NotoSansCJKtc',
        '28d10': 'NotoSansCJKtc',
        '28d34': 'NotoSansCJKtc',
        '28d71': 'NotoSansCJKtc',
        '28d99': 'NotoSansCJKtc',
        '28db9': 'NotoSansCJKtc',
        '28dfb': 'NotoSansCJKtc',
        '28e0f': 'NotoSansCJKtc',
        '28e17': 'NotoSansCJKtc',
        '28e1f': 'NotoSansCJKtc',
        '28e36': 'NotoSansCJKtc',
        '28e39': 'NotoSansCJKtc',
        '28e65-28e66': 'NotoSansCJKtc',
        '28e89': 'NotoSansCJKtc',
        '28e97': 'NotoSansCJKtc',
        '28e99': 'NotoSansCJKtc',
        '28eac': 'NotoSansCJKtc',
        '28eb2-28eb3': 'NotoSansCJKtc',
        '28ed9': 'NotoSansCJKtc',
        '28ee7': 'NotoSansCJKtc',
        '28eeb': 'NotoSansCJKtc',
        '28ef6': 'NotoSansCJKtc',
        '28f32': 'NotoSansCJKtc',
        '28fc5': 'NotoSansCJKtc',
        '28ff8': 'NotoSansCJKtc',
        '2908b': 'NotoSansCJKtc',
        '290af-290b1': 'NotoSansCJKtc',
        '290c0': 'NotoSansCJKtc',
        '290e4-290e5': 'NotoSansCJKtc',
        '290ec-290ed': 'NotoSansCJKtc',
        '2910d': 'NotoSansCJKtc',
        '2913c': 'NotoSansCJKtc',
        '2914d': 'NotoSansCJKtc',
        '2915b': 'NotoSansCJKtc',
        '2915e': 'NotoSansCJKtc',
        '2919c': 'NotoSansCJKtc',
        '291a8': 'NotoSansCJKtc',
        '291d5': 'NotoSansCJKtc',
        '291eb': 'NotoSansCJKtc',
        '292a0': 'NotoSansCJKtc',
        '292b1': 'NotoSansCJKtc',
        '2941d': 'NotoSansCJKtc',
        '2943f': 'NotoSansCJKtc',
        '294d0': 'NotoSansCJKtc',
        '294d9-294da': 'NotoSansCJKtc',
        '294e5': 'NotoSansCJKtc',
        '294e7': 'NotoSansCJKtc',
        '2959e': 'NotoSansCJKtc',
        '295b0': 'NotoSansCJKtc',
        '295b8': 'NotoSansCJKtc',
        '295cf': 'NotoSansCJKtc',
        '295d7': 'NotoSansCJKtc',
        '295e9': 'NotoSansCJKtc',
        '295f4': 'NotoSansCJKtc',
        '2967f': 'NotoSansCJKtc',
        '296f0': 'NotoSansCJKtc',
        '297d4': 'NotoSansCJKtc',
        '298a4': 'NotoSansCJKtc',
        '298c6': 'NotoSansCJKtc',
        '298d1': 'NotoSansCJKtc',
        '298ea': 'NotoSansCJKtc',
        '298f1': 'NotoSansCJKtc',
        '298fa': 'NotoSansCJKtc',
        '2992f': 'NotoSansCJKtc',
        '29947-29949': 'NotoSansCJKtc',
        '2995d': 'NotoSansCJKtc',
        '2996a': 'NotoSansCJKtc',
        '2999d': 'NotoSansCJKtc',
        '299c3': 'NotoSansCJKtc',
        '299c9': 'NotoSansCJKtc',
        '29a28': 'NotoSansCJKtc',
        '29a4d': 'NotoSansCJKtc',
        '29a72': 'NotoSansCJKtc',
        '29b05': 'NotoSansCJKtc',
        '29b0e': 'NotoSansCJKtc',
        '29bd5': 'NotoSansCJKtc',
        '29c73': 'NotoSansCJKtc',
        '29cad': 'NotoSansCJKtc',
        '29d3e': 'NotoSansCJKtc',
        '29d4b': 'NotoSansCJKtc',
        '29d5a': 'NotoSansCJKtc',
        '29d7c': 'NotoSansCJKtc',
        '29d98': 'NotoSansCJKtc',
        '29d9b': 'NotoSansCJKtc',
        '29ddb': 'NotoSansCJKtc',
        '29df6': 'NotoSansCJKtc',
        '29e06': 'NotoSansCJKtc',
        '29e15': 'NotoSansCJKtc',
        '29e2d': 'NotoSansCJKtc',
        '29e3d': 'NotoSansCJKtc',
        '29e49': 'NotoSansCJKtc',
        '29e68': 'NotoSansCJKtc',
        '29e8a': 'NotoSansCJKtc',
        '29eac': 'NotoSansCJKtc',
        '29eb0': 'NotoSansCJKtc',
        '29ec3-29ec4': 'NotoSansCJKtc',
        '29edb': 'NotoSansCJKtc',
        '29ee9': 'NotoSansCJKtc',
        '29ef8': 'NotoSansCJKtc',
        '29f23': 'NotoSansCJKtc',
        '29f30': 'NotoSansCJKtc',
        '29f7e': 'NotoSansCJKtc',
        '29f83': 'NotoSansCJKtc',
        '29f8c': 'NotoSansCJKtc',
        '29fb7': 'NotoSansCJKtc',
        '29fce': 'NotoSansCJKtc',
        '29fd7': 'NotoSansCJKtc',
        '29fde': 'NotoSansCJKtc',
        '2a014': 'NotoSansCJKtc',
        '2a01a': 'NotoSansCJKtc',
        '2a02f': 'NotoSansCJKtc',
        '2a082': 'NotoSansCJKtc',
        '2a087': 'NotoSansCJKtc',
        '2a0b9': 'NotoSansCJKtc',
        '2a0e1': 'NotoSansCJKtc',
        '2a0ed': 'NotoSansCJKtc',
        '2a0f3': 'NotoSansCJKtc',
        '2a0f8-2a0f9': 'NotoSansCJKtc',
        '2a0fe': 'NotoSansCJKtc',
        '2a107': 'NotoSansCJKtc',
        '2a123': 'NotoSansCJKtc',
        '2a133-2a134': 'NotoSansCJKtc',
        '2a150': 'NotoSansCJKtc',
        '2a190': 'NotoSansCJKtc',
        '2a192-2a193': 'NotoSansCJKtc',
        '2a1ab': 'NotoSansCJKtc',
        '2a1b4-2a1b5': 'NotoSansCJKtc',
        '2a1df': 'NotoSansCJKtc',
        '2a1f5': 'NotoSansCJKtc',
        '2a220': 'NotoSansCJKtc',
        '2a233': 'NotoSansCJKtc',
        '2a293': 'NotoSansCJKtc',
        '2a29f': 'NotoSansCJKtc',
        '2a2b2': 'NotoSansCJKtc',
        '2a2b4': 'NotoSansCJKtc',
        '2a2b6': 'NotoSansCJKtc',
        '2a2ba': 'NotoSansCJKtc',
        '2a2bd': 'NotoSansCJKtc',
        '2a2df': 'NotoSansCJKtc',
        '2a2ff': 'NotoSansCJKtc',
        '2a351': 'NotoSansCJKtc',
        '2a38c': 'NotoSansCJKtc',
        '2a3a9': 'NotoSansCJKtc',
        '2a3ed': 'NotoSansCJKtc',
        '2a434': 'NotoSansCJKtc',
        '2a437': 'NotoSansCJKtc',
        '2a45b': 'NotoSansCJKtc',
        '2a5c6': 'NotoSansCJKtc',
        '2a5cb': 'NotoSansCJKtc',
        '2a5f1': 'NotoSansCJKtc',
        '2a601-2a602': 'NotoSansCJKtc',
        '2a61a': 'NotoSansCJKtc',
        '2a632': 'NotoSansCJKtc',
        '2a64a': 'NotoSansCJKtc',
        '2a65b': 'NotoSansCJKtc',
        '2a6a9': 'NotoSansCJKtc',
        '2a6b2': 'NotoSansCJKtc',
        '2a7dd': 'NotoSansCJKtc',
        '2a8fb': 'NotoSansCJKtc',
        '2a917': 'NotoSansCJKtc',
        '2a9e6': 'NotoSansCJKtc',
        '2aa30': 'NotoSansCJKtc',
        '2aa36': 'NotoSansCJKtc',
        '2aa58': 'NotoSansCJKtc',
        '2adff': 'NotoSansCJKtc',
        '2afa2': 'NotoSansCJKtc',
        '2b127-2b128': 'NotoSansCJKtc',
        '2b137-2b138': 'NotoSansCJKtc',
        '2b1ed': 'NotoSansCJKtc',
        '2b300': 'NotoSansCJKtc',
        '2b363': 'NotoSansCJKtc',
        '2b36f': 'NotoSansCJKtc',
        '2b372': 'NotoSansCJKtc',
        '2b37d': 'NotoSansCJKtc',
        '2b404': 'NotoSansCJKtc',
        '2b410': 'NotoSansCJKtc',
        '2b413': 'NotoSansCJKtc',
        '2b461': 'NotoSansCJKtc',
        '2b4e7': 'NotoSansCJKtc',
        '2b4ef': 'NotoSansCJKtc',
        '2b4f6': 'NotoSansCJKtc',
        '2b4f9': 'NotoSansCJKtc',
        '2b50d-2b50e': 'NotoSansCJKtc',
        '2b536': 'NotoSansCJKtc',
        '2b5ae-2b5af': 'NotoSansCJKtc',
        '2b5b3': 'NotoSansCJKtc',
        '2b5e7': 'NotoSansCJKtc',
        '2b5f4': 'NotoSansCJKtc',
        '2b61c-2b61d': 'NotoSansCJKtc',
        '2b626-2b628': 'NotoSansCJKtc',
        '2b62a': 'NotoSansCJKtc',
        '2b62c': 'NotoSansCJKtc',
        '2b695-2b696': 'NotoSansCJKtc',
        '2b6ad': 'NotoSansCJKtc',
        '2b6ed': 'NotoSansCJKtc',
        '2b746': 'NotoSansCJKtc',
        '2b751': 'NotoSansCJKtc',
        '2b753': 'NotoSansCJKtc',
        '2b75a': 'NotoSansCJKtc',
        '2b75c': 'NotoSansCJKtc',
        '2b765': 'NotoSansCJKtc',
        '2b776-2b777': 'NotoSansCJKtc',
        '2b77c': 'NotoSansCJKtc',
        '2b782': 'NotoSansCJKtc',
        '2b789': 'NotoSansCJKtc',
        '2b78b': 'NotoSansCJKtc',
        '2b78e': 'NotoSansCJKtc',
        '2b794': 'NotoSansCJKtc',
        '2b7a9': 'NotoSansCJKtc',
        '2b7ac': 'NotoSansCJKtc',
        '2b7af': 'NotoSansCJKtc',
        '2b7bd': 'NotoSansCJKtc',
        '2b7c5': 'NotoSansCJKtc',
        '2b7c9': 'NotoSansCJKtc',
        '2b7cf': 'NotoSansCJKtc',
        '2b7d2': 'NotoSansCJKtc',
        '2b7d8': 'NotoSansCJKtc',
        '2b7e6': 'NotoSansCJKtc',
        '2b7f0': 'NotoSansCJKtc',
        '2b7f9': 'NotoSansCJKtc',
        '2b7fc': 'NotoSansCJKtc',
        '2b806': 'NotoSansCJKtc',
        '2b80a': 'NotoSansCJKtc',
        '2b80d': 'NotoSansCJKtc',
        '2b817': 'NotoSansCJKtc',
        '2b81a': 'NotoSansCJKtc',
        '2b81c': 'NotoSansCJKtc',
        '2b8b8': 'NotoSansCJKtc',
        '2bac7': 'NotoSansCJKtc',
        '2bb5f': 'NotoSansCJKtc',
        '2bb62': 'NotoSansCJKtc',
        '2bb7c': 'NotoSansCJKtc',
        '2bb83': 'NotoSansCJKtc',
        '2bc1b': 'NotoSansCJKtc',
        '2bd77': 'NotoSansCJKtc',
        '2bd87': 'NotoSansCJKtc',
        '2bdf7': 'NotoSansCJKtc',
        '2be29': 'NotoSansCJKtc',
        '2c029-2c02a': 'NotoSansCJKtc',
        '2c0a9': 'NotoSansCJKtc',
        '2c0ca': 'NotoSansCJKtc',
        '2c1d5': 'NotoSansCJKtc',
        '2c1d9': 'NotoSansCJKtc',
        '2c1f9': 'NotoSansCJKtc',
        '2c27c': 'NotoSansCJKtc',
        '2c288': 'NotoSansCJKtc',
        '2c2a4': 'NotoSansCJKtc',
        '2c317': 'NotoSansCJKtc',
        '2c35b': 'NotoSansCJKtc',
        '2c361': 'NotoSansCJKtc',
        '2c364': 'NotoSansCJKtc',
        '2c488': 'NotoSansCJKtc',
        '2c494': 'NotoSansCJKtc',
        '2c497': 'NotoSansCJKtc',
        '2c542': 'NotoSansCJKtc',
        '2c613': 'NotoSansCJKtc',
        '2c618': 'NotoSansCJKtc',
        '2c621': 'NotoSansCJKtc',
        '2c629': 'NotoSansCJKtc',
        '2c62b-2c62d': 'NotoSansCJKtc',
        '2c62f': 'NotoSansCJKtc',
        '2c642': 'NotoSansCJKtc',
        '2c64a-2c64b': 'NotoSansCJKtc',
        '2c72c': 'NotoSansCJKtc',
        '2c72f': 'NotoSansCJKtc',
        '2c79f': 'NotoSansCJKtc',
        '2c7c1': 'NotoSansCJKtc',
        '2c7fd': 'NotoSansCJKtc',
        '2c8d9': 'NotoSansCJKtc',
        '2c8de': 'NotoSansCJKtc',
        '2c8e1': 'NotoSansCJKtc',
        '2c8f3': 'NotoSansCJKtc',
        '2c907': 'NotoSansCJKtc',
        '2c90a': 'NotoSansCJKtc',
        '2c91d': 'NotoSansCJKtc',
        '2ca02': 'NotoSansCJKtc',
        '2ca0e': 'NotoSansCJKtc',
        '2ca7d': 'NotoSansCJKtc',
        '2caa9': 'NotoSansCJKtc',
        '2cb29': 'NotoSansCJKtc',
        '2cb2d-2cb2e': 'NotoSansCJKtc',
        '2cb31': 'NotoSansCJKtc',
        '2cb38-2cb39': 'NotoSansCJKtc',
        '2cb3b': 'NotoSansCJKtc',
        '2cb3f': 'NotoSansCJKtc',
        '2cb41': 'NotoSansCJKtc',
        '2cb4a': 'NotoSansCJKtc',
        '2cb4e': 'NotoSansCJKtc',
        '2cb5a-2cb5b': 'NotoSansCJKtc',
        '2cb64': 'NotoSansCJKtc',
        '2cb69': 'NotoSansCJKtc',
        '2cb6c': 'NotoSansCJKtc',
        '2cb6f': 'NotoSansCJKtc',
        '2cb73': 'NotoSansCJKtc',
        '2cb76': 'NotoSansCJKtc',
        '2cb78': 'NotoSansCJKtc',
        '2cb7c': 'NotoSansCJKtc',
        '2cbb1': 'NotoSansCJKtc',
        '2cbbf-2cbc0': 'NotoSansCJKtc',
        '2cbce': 'NotoSansCJKtc',
        '2cc56': 'NotoSansCJKtc',
        '2cc5f': 'NotoSansCJKtc',
        '2ccf5-2ccf6': 'NotoSansCJKtc',
        '2ccfd': 'NotoSansCJKtc',
        '2ccff': 'NotoSansCJKtc',
        '2cd02-2cd03': 'NotoSansCJKtc',
        '2cd0a': 'NotoSansCJKtc',
        '2cd8b': 'NotoSansCJKtc',
        '2cd8d': 'NotoSansCJKtc',
        '2cd8f-2cd90': 'NotoSansCJKtc',
        '2cd9f-2cda0': 'NotoSansCJKtc',
        '2cda8': 'NotoSansCJKtc',
        '2cdad-2cdae': 'NotoSansCJKtc',
        '2cdd5': 'NotoSansCJKtc',
        '2ce18': 'NotoSansCJKtc',
        '2ce1a': 'NotoSansCJKtc',
        '2ce23': 'NotoSansCJKtc',
        '2ce26': 'NotoSansCJKtc',
        '2ce2a': 'NotoSansCJKtc',
        '2ce7c': 'NotoSansCJKtc',
        '2ce88': 'NotoSansCJKtc',
        '2ce93': 'NotoSansCJKtc',
        '2f804': 'NotoSansCJKtc',
        '2f80f': 'NotoSansCJKtc',
        '2f815': 'NotoSansCJKtc',
        '2f818': 'NotoSansCJKtc',
        '2f81a': 'NotoSansCJKtc',
        '2f822': 'NotoSansCJKtc',
        '2f825': 'NotoSansCJKtc',
        '2f828': 'NotoSansCJKtc',
        '2f82c': 'NotoSansCJKtc',
        '2f833': 'NotoSansCJKtc',
        '2f83b': 'NotoSansCJKtc',
        '2f83f-2f840': 'NotoSansCJKtc',
        '2f846': 'NotoSansCJKtc',
        '2f852': 'NotoSansCJKtc',
        '2f862': 'NotoSansCJKtc',
        '2f86d': 'NotoSansCJKtc',
        '2f873': 'NotoSansCJKtc',
        '2f877-2f878': 'NotoSansCJKtc',
        '2f884': 'NotoSansCJKtc',
        '2f894': 'NotoSansCJKtc',
        '2f899-2f89a': 'NotoSansCJKtc',
        '2f8a6': 'NotoSansCJKtc',
        '2f8ac': 'NotoSansCJKtc',
        '2f8b2': 'NotoSansCJKtc',
        '2f8b6': 'NotoSansCJKtc',
        '2f8cd': 'NotoSansCJKtc',
        '2f8d3': 'NotoSansCJKtc',
        '2f8db-2f8dc': 'NotoSansCJKtc',
        '2f8e1': 'NotoSansCJKtc',
        '2f8e5': 'NotoSansCJKtc',
        '2f8ea': 'NotoSansCJKtc',
        '2f8ed': 'NotoSansCJKtc',
        '2f8fc': 'NotoSansCJKtc',
        '2f903': 'NotoSansCJKtc',
        '2f90b': 'NotoSansCJKtc',
        '2f90f': 'NotoSansCJKtc',
        '2f91a': 'NotoSansCJKtc',
        '2f920-2f921': 'NotoSansCJKtc',
        '2f945': 'NotoSansCJKtc',
        '2f947': 'NotoSansCJKtc',
        '2f96c': 'NotoSansCJKtc',
        '2f994-2f995': 'NotoSansCJKtc',
        '2f9b2': 'NotoSansCJKtc',
        '2f9bc': 'NotoSansCJKtc',
        '2f9d0': 'NotoSansCJKtc',
        '2f9d4': 'NotoSansCJKtc',
        '2f9de-2f9df': 'NotoSansCJKtc',
        '2f9f4': 'NotoSansCJKtc',
        '00131': 'NotoSansCanadianAboriginal',
        '002d8': 'NotoSansCanadianAboriginal',
        '002da-002db': 'NotoSansCanadianAboriginal',
        '00307': 'NotoSansTifinagh',
        '01400-0167f': 'NotoSansCanadianAboriginal',
        '018b0-018f5': 'NotoSansCanadianAboriginal',
        '102a0-102d0': 'NotoSansCarian',
        '01040-01049': 'NotoSansTaiLe',
        '11100-11134': 'NotoSansChakma',
        '11136-11143': 'NotoSansChakma',
        '0aa00-0aa36': 'NotoSansCham',
        '0aa40-0aa4d': 'NotoSansCham',
        '0aa50-0aa59': 'NotoSansCham',
        '0aa5c-0aa5f': 'NotoSansCham',
        '00300': 'NotoSansTaiLe',
        '00301-00302': 'NotoSansTifinagh',
        '00304': 'NotoSansTifinagh',
        '0030b': 'NotoSansOsage',
        '0030c': 'NotoSansTaiLe',
        '00323': 'NotoSansTifinagh',
        '00324': 'NotoSansSyriacWestern',
        '00330': 'NotoSansSyriacWestern',
        '00331': 'NotoSansTifinagh',
        '013a0-013f5': 'NotoSansCherokee',
        '013f8-013fd': 'NotoSansCherokee',
        '0ab70-0abbf': 'NotoSansCherokee',
        '00305': 'NotoSansGothic',
        '00308': 'NotoSansTaiLe',
        '0033f': 'NotoSansCoptic',
        '00361': 'NotoSansCoptic',
        '00374-00375': 'NotoSansCoptic',
        '003e2-003ef': 'NotoSansCoptic',
        '01dcd': 'NotoSansCoptic',
        '02c80-02cf3': 'NotoSansCoptic',
        '02cf9-02cff': 'NotoSansCoptic',
        '0fe24-0fe26': 'NotoSansCoptic',
        '12000-12399': 'NotoSansCuneiform',
        '12400-1246e': 'NotoSansCuneiform',
        '12470-12474': 'NotoSansCuneiform',
        '12480-12543': 'NotoSansCuneiform',
        '10800-10805': 'NotoSansCypriot',
        '1080a-10835': 'NotoSansCypriot',
        '10837-10838': 'NotoSansCypriot',
        '1083c': 'NotoSansCypriot',
        '1083f': 'NotoSansCypriot',
        '10400-1044f': 'NotoSansDeseret',
        '00900-00950': 'NotoSansDevanagari',
        '00953-00963': 'NotoSansDevanagari',
        '00966-0096f': 'NotoSansKaithi',
        '00970-0097f': 'NotoSansDevanagari',
        '01cd0-01cd9': 'NotoSansDevanagari',
        '01cda': 'NotoSansTamil',
        '01cdb-01cf6': 'NotoSansDevanagari',
        '01cf8-01cf9': 'NotoSansDevanagari',
        '020f0': 'NotoSansDevanagari',
        '0a830-0a839': 'NotoSansKaithi',
        '0a8e0-0a8f2': 'NotoSansDevanagari',
        '0a8f3': 'NotoSansTamil',
        '0a8f4-0a8fd': 'NotoSansDevanagari',
        '13000-1342e': 'NotoSansEgyptianHieroglyphs',
        '0030e': 'NotoSansEthiopic',
        '01200-01248': 'NotoSansEthiopic',
        '0124a-0124d': 'NotoSansEthiopic',
        '01250-01256': 'NotoSansEthiopic',
        '01258': 'NotoSansEthiopic',
        '0125a-0125d': 'NotoSansEthiopic',
        '01260-01288': 'NotoSansEthiopic',
        '0128a-0128d': 'NotoSansEthiopic',
        '01290-012b0': 'NotoSansEthiopic',
        '012b2-012b5': 'NotoSansEthiopic',
        '012b8-012be': 'NotoSansEthiopic',
        '012c0': 'NotoSansEthiopic',
        '012c2-012c5': 'NotoSansEthiopic',
        '012c8-012d6': 'NotoSansEthiopic',
        '012d8-01310': 'NotoSansEthiopic',
        '01312-01315': 'NotoSansEthiopic',
        '01318-0135a': 'NotoSansEthiopic',
        '0135d-0137c': 'NotoSansEthiopic',
        '01380-01399': 'NotoSansEthiopic',
        '022ee': 'NotoSansEthiopic',
        '02d80-02d96': 'NotoSansEthiopic',
        '02da0-02da6': 'NotoSansEthiopic',
        '02da8-02dae': 'NotoSansEthiopic',
        '02db0-02db6': 'NotoSansEthiopic',
        '02db8-02dbe': 'NotoSansEthiopic',
        '02dc0-02dc6': 'NotoSansEthiopic',
        '02dc8-02dce': 'NotoSansEthiopic',
        '02dd0-02dd6': 'NotoSansEthiopic',
        '02dd8-02dde': 'NotoSansEthiopic',
        '0ab01-0ab06': 'NotoSansEthiopic',
        '0ab09-0ab0e': 'NotoSansEthiopic',
        '0ab11-0ab16': 'NotoSansEthiopic',
        '0ab20-0ab26': 'NotoSansEthiopic',
        '0ab28-0ab2e': 'NotoSansEthiopic',
        '010a0-010c5': 'NotoSansGeorgian',
        '010c7': 'NotoSansGeorgian',
        '010cd': 'NotoSansGeorgian',
        '010d0-010ff': 'NotoSansGeorgian',
        '020be': 'NotoSansGeorgian',
        '02d00-02d25': 'NotoSansGeorgian',
        '02d27': 'NotoSansGeorgian',
        '02d2d': 'NotoSansGeorgian',
        '00303': 'NotoSansThai',
        '00484': 'NotoSansGlagolitic',
        '00487': 'NotoSansGlagolitic',
        '02c00-02c2e': 'NotoSansGlagolitic',
        '02c30-02c5e': 'NotoSansGlagolitic',
        '0a66f': 'NotoSansGlagolitic',
        '1e000-1e006': 'NotoSansGlagolitic',
        '1e008-1e018': 'NotoSansGlagolitic',
        '1e01b-1e021': 'NotoSansGlagolitic',
        '1e023-1e024': 'NotoSansGlagolitic',
        '1e026-1e02a': 'NotoSansGlagolitic',
        '10330-1034a': 'NotoSansGothic',
        '00a81-00a83': 'NotoSansGujarati',
        '00a85-00a8d': 'NotoSansGujarati',
        '00a8f-00a91': 'NotoSansGujarati',
        '00a93-00aa8': 'NotoSansGujarati',
        '00aaa-00ab0': 'NotoSansGujarati',
        '00ab2-00ab3': 'NotoSansGujarati',
        '00ab5-00ab9': 'NotoSansGujarati',
        '00abc-00ac5': 'NotoSansGujarati',
        '00ac7-00ac9': 'NotoSansGujarati',
        '00acb-00acd': 'NotoSansGujarati',
        '00ad0': 'NotoSansGujarati',
        '00ae0-00ae3': 'NotoSansGujarati',
        '00ae6-00af1': 'NotoSansGujarati',
        '00a01-00a03': 'NotoSansGurmukhi',
        '00a05-00a0a': 'NotoSansGurmukhi',
        '00a0f-00a10': 'NotoSansGurmukhi',
        '00a13-00a28': 'NotoSansGurmukhi',
        '00a2a-00a30': 'NotoSansGurmukhi',
        '00a32-00a33': 'NotoSansGurmukhi',
        '00a35-00a36': 'NotoSansGurmukhi',
        '00a38-00a39': 'NotoSansGurmukhi',
        '00a3c': 'NotoSansGurmukhi',
        '00a3e-00a42': 'NotoSansGurmukhi',
        '00a47-00a48': 'NotoSansGurmukhi',
        '00a4b-00a4d': 'NotoSansGurmukhi',
        '00a51': 'NotoSansGurmukhi',
        '00a59-00a5c': 'NotoSansGurmukhi',
        '00a5e': 'NotoSansGurmukhi',
        '00a66-00a75': 'NotoSansGurmukhi',
        '0262c': 'NotoSansSymbols',
        '01720-01734': 'NotoSansHanunoo',
        '00591-005c7': 'NotoSansHebrew',
        '005d0-005ea': 'NotoSansHebrew',
        '005f0-005f4': 'NotoSansHebrew',
        '020aa': 'NotoSansHebrew',
        '0fb1d-0fb36': 'NotoSansHebrew',
        '0fb38-0fb3c': 'NotoSansHebrew',
        '0fb3e': 'NotoSansHebrew',
        '0fb40-0fb41': 'NotoSansHebrew',
        '0fb43-0fb44': 'NotoSansHebrew',
        '0fb46-0fb4f': 'NotoSansHebrew',
        '10840-10855': 'NotoSansImperialAramaic',
        '10857-1085f': 'NotoSansImperialAramaic',
        '10b60-10b72': 'NotoSansInscriptionalPahlavi',
        '10b78-10b7f': 'NotoSansInscriptionalPahlavi',
        '10b40-10b55': 'NotoSansInscriptionalParthian',
        '10b58-10b5f': 'NotoSansInscriptionalParthian',
        '0a980-0a9cd': 'NotoSansJavanese',
        '0a9d0-0a9d9': 'NotoSansJavanese',
        '0a9de-0a9df': 'NotoSansJavanese',
        '11080-110c1': 'NotoSansKaithi',
        '00c82-00c83': 'NotoSansKannada',
        '00c85-00c8c': 'NotoSansKannada',
        '00c8e-00c90': 'NotoSansKannada',
        '00c92-00ca8': 'NotoSansKannada',
        '00caa-00cb3': 'NotoSansKannada',
        '00cb5-00cb9': 'NotoSansKannada',
        '00cbc-00cc4': 'NotoSansKannada',
        '00cc6-00cc8': 'NotoSansKannada',
        '00cca-00ccd': 'NotoSansKannada',
        '00cd5-00cd6': 'NotoSansKannada',
        '00cde': 'NotoSansKannada',
        '00ce0-00ce3': 'NotoSansKannada',
        '00ce6-00cef': 'NotoSansKannada',
        '00cf1-00cf2': 'NotoSansKannada',
        '0a900-0a92d': 'NotoSansKayahLi',
        '0a92e': 'NotoSansMyanmar',
        '0a92f': 'NotoSansKayahLi',
        '10a00-10a03': 'NotoSansKharoshthi',
        '10a05-10a06': 'NotoSansKharoshthi',
        '10a0c-10a13': 'NotoSansKharoshthi',
        '10a15-10a17': 'NotoSansKharoshthi',
        '10a19-10a33': 'NotoSansKharoshthi',
        '10a38-10a3a': 'NotoSansKharoshthi',
        '10a3f-10a47': 'NotoSansKharoshthi',
        '10a50-10a58': 'NotoSansKharoshthi',
        '01780-017dd': 'NotoSansKhmer',
        '017e0-017e9': 'NotoSansKhmer',
        '017f0-017f9': 'NotoSansKhmer',
        '019e0-019ff': 'NotoSansKhmer',
        '00e81-00e82': 'NotoSansLao',
        '00e84': 'NotoSansLao',
        '00e87-00e88': 'NotoSansLao',
        '00e8a': 'NotoSansLao',
        '00e8d': 'NotoSansLao',
        '00e94-00e97': 'NotoSansLao',
        '00e99-00e9f': 'NotoSansLao',
        '00ea1-00ea3': 'NotoSansLao',
        '00ea5': 'NotoSansLao',
        '00ea7': 'NotoSansLao',
        '00eaa-00eab': 'NotoSansLao',
        '00ead-00eb9': 'NotoSansLao',
        '00ebb-00ebd': 'NotoSansLao',
        '00ec0-00ec4': 'NotoSansLao',
        '00ec6': 'NotoSansLao',
        '00ec8-00ecd': 'NotoSansLao',
        '00ed0-00ed9': 'NotoSansLao',
        '00edc-00edf': 'NotoSansLao',
        '020ad': 'NotoSansLao',
        '01c00-01c37': 'NotoSansLepcha',
        '01c3b-01c49': 'NotoSansLepcha',
        '01c4d-01c4f': 'NotoSansLepcha',
        '01900-0191c': 'NotoSansLimbu',
        '01920-0192b': 'NotoSansLimbu',
        '01930-0193b': 'NotoSansLimbu',
        '01940': 'NotoSansLimbu',
        '01944-0194f': 'NotoSansLimbu',
        '10000-1000b': 'NotoSansLinearB',
        '1000d-10026': 'NotoSansLinearB',
        '10028-1003a': 'NotoSansLinearB',
        '1003c-1003d': 'NotoSansLinearB',
        '1003f-1004d': 'NotoSansLinearB',
        '10050-1005d': 'NotoSansLinearB',
        '10080-100fa': 'NotoSansLinearB',
        '10100-10102': 'NotoSansLinearB',
        '10107-10133': 'NotoSansLinearB',
        '10137-1013f': 'NotoSansLinearB',
        '002cd': 'NotoSansLisu',
        '0a4d0-0a4ff': 'NotoSansLisu',
        '10280-1029c': 'NotoSansLycian',
        '10920-10939': 'NotoSansLydian',
        '1093f': 'NotoSansLydian',
        '00d02-00d03': 'NotoSansMalayalam',
        '00d05-00d0c': 'NotoSansMalayalam',
        '00d0e-00d10': 'NotoSansMalayalam',
        '00d12-00d3a': 'NotoSansMalayalam',
        '00d3d-00d44': 'NotoSansMalayalam',
        '00d46-00d48': 'NotoSansMalayalam',
        '00d4a-00d4e': 'NotoSansMalayalam',
        '00d57': 'NotoSansMalayalam',
        '00d60-00d63': 'NotoSansMalayalam',
        '00d66-00d75': 'NotoSansMalayalam',
        '00d79-00d7f': 'NotoSansMalayalam',
        '00840-0085b': 'NotoSansMandaic',
        '0085e': 'NotoSansMandaic',
        '0aae0-0aaf6': 'NotoSansMeeteiMayek',
        '0abc0-0abed': 'NotoSansMeeteiMayek',
        '0abf0-0abf9': 'NotoSansMeeteiMayek',
        '01800': 'NotoSansMongolian',
        '01801-01803': 'NotoSansPhagsPa',
        '01804': 'NotoSansMongolian',
        '01805': 'NotoSansPhagsPa',
        '01806-0180e': 'NotoSansMongolian',
        '01810-01819': 'NotoSansMongolian',
        '01820-01877': 'NotoSansMongolian',
        '01880-018aa': 'NotoSansMongolian',
        '0202f': 'NotoSansMongolian',
        '01000-0103f': 'NotoSansMyanmar',
        '0104a-0109f': 'NotoSansMyanmar',
        '0a9e0-0a9fe': 'NotoSansMyanmar',
        '0aa60-0aa7f': 'NotoSansMyanmar',
        '0fe00': 'NotoSansPhagsPa',
        '007c0-007fa': 'NotoSansNKo',
        '02e1c-02e1d': 'NotoSansNKo',
        '01980-019ab': 'NotoSansNewTaiLue',
        '019b0-019c9': 'NotoSansNewTaiLue',
        '019d0-019da': 'NotoSansNewTaiLue',
        '019de-019df': 'NotoSansNewTaiLue',
        '01680-0169c': 'NotoSansOgham',
        '01c50-01c7f': 'NotoSansOlChiki',
        '10300-10323': 'NotoSansOldItalic',
        '103a0-103c3': 'NotoSansOldPersian',
        '103c8-103d5': 'NotoSansOldPersian',
        '10a60-10a7f': 'NotoSansOldSouthArabian',
        '10c00-10c48': 'NotoSansOldTurkic',
        '00b01-00b03': 'NotoSansOriya',
        '00b05-00b0c': 'NotoSansOriya',
        '00b0f-00b10': 'NotoSansOriya',
        '00b13-00b28': 'NotoSansOriya',
        '00b2a-00b30': 'NotoSansOriya',
        '00b32-00b33': 'NotoSansOriya',
        '00b35-00b39': 'NotoSansOriya',
        '00b3c-00b44': 'NotoSansOriya',
        '00b47-00b48': 'NotoSansOriya',
        '00b4b-00b4d': 'NotoSansOriya',
        '00b56-00b57': 'NotoSansOriya',
        '00b5c-00b5d': 'NotoSansOriya',
        '00b5f-00b63': 'NotoSansOriya',
        '00b66-00b77': 'NotoSansOriya',
        '00358': 'NotoSansOsage',
        '104b0-104d3': 'NotoSansOsage',
        '104d8-104fb': 'NotoSansOsage',
        '10480-1049d': 'NotoSansOsmanya',
        '104a0-104a9': 'NotoSansOsmanya',
        '0a840-0a877': 'NotoSansPhagsPa',
        '10900-1091b': 'NotoSansPhoenician',
        '1091f': 'NotoSansPhoenician',
        '0a930-0a953': 'NotoSansRejang',
        '0a95f': 'NotoSansRejang',
        '016a0-016f8': 'NotoSansRunic',
        '00800-0082d': 'NotoSansSamaritan',
        '00830-0083e': 'NotoSansSamaritan',
        '0a880-0a8c4': 'NotoSansSaurashtra',
        '0a8ce-0a8d9': 'NotoSansSaurashtra',
        '10450-1047f': 'NotoSansShavian',
        '00d82-00d83': 'NotoSansSinhala',
        '00d85-00d96': 'NotoSansSinhala',
        '00d9a-00db1': 'NotoSansSinhala',
        '00db3-00dbb': 'NotoSansSinhala',
        '00dbd': 'NotoSansSinhala',
        '00dc0-00dc6': 'NotoSansSinhala',
        '00dca': 'NotoSansSinhala',
        '00dcf-00dd4': 'NotoSansSinhala',
        '00dd6': 'NotoSansSinhala',
        '00dd8-00ddf': 'NotoSansSinhala',
        '00de6-00def': 'NotoSansSinhala',
        '00df2-00df4': 'NotoSansSinhala',
        '111e1-111f4': 'NotoSansSinhala',
        '01b80-01bbf': 'NotoSansSundanese',
        '01cc0-01cc7': 'NotoSansSundanese',
        '02055': 'NotoSansSylotiNagri',
        '0a800-0a82b': 'NotoSansSylotiNagri',
        '020df-020e0': 'NotoSansSymbols',
        '020e2-020e3': 'NotoSansSymbols2',
        '020e4': 'NotoSansSymbols',
        '0216c-0216f': 'NotoSansSymbols',
        '0217c-02183': 'NotoSansSymbols',
        '02185-02188': 'NotoSansSymbols',
        '0218a-0218b': 'NotoSansSymbols',
        '02300-02304': 'NotoSansSymbols',
        '02308-0230f': 'NotoSansSymbols',
        '02311': 'NotoSansSymbols',
        '02313-02315': 'NotoSansSymbols',
        '02317': 'NotoSansSymbols',
        '0231c-0231f': 'NotoSansSymbols',
        '02322-02323': 'NotoSansSymbols',
        '0232c-02335': 'NotoSansSymbols',
        '0237c': 'NotoSansSymbols',
        '02380-02393': 'NotoSansSymbols',
        '02394': 'NotoSansSymbols2',
        '02396-0239a': 'NotoSansSymbols',
        '023af': 'NotoSansSymbols',
        '023cd': 'NotoSansSymbols',
        '023d0-023d9': 'NotoSansSymbols',
        '023e2-023e8': 'NotoSansSymbols',
        '0260a-0260d': 'NotoSansSymbols',
        '02613': 'NotoSansSymbols',
        '02624-0262b': 'NotoSansSymbols',
        '0262d-0262e': 'NotoSansSymbols',
        '02638-0263b': 'NotoSansSymbols',
        '0263d-0263f': 'NotoSansSymbols',
        '02643-02653': 'NotoSansSymbols',
        '02670-02671': 'NotoSansSyriacWestern',
        '0267e': 'NotoSansSymbols',
        '02690-0269d': 'NotoSansSymbols',
        '026a2-026a9': 'NotoSansSymbols',
        '026ad-026bc': 'NotoSansSymbols',
        '026ce': 'NotoSansSymbols',
        '026e2-026ff': 'NotoSansSymbols',
        '0271d-02721': 'NotoSansSymbols',
        '02780-02793': 'NotoSansSymbols',
        '02921-02922': 'NotoSansSymbols',
        '1f10b-1f10c': 'NotoSansSymbols',
        '1f19b-1f1ac': 'NotoSansSymbols',
        '1f546-1f549': 'NotoSansSymbols',
        '1f54f': 'NotoSansSymbols',
        '1f610': 'NotoSansSymbols',
        '1f700-1f773': 'NotoSansSymbols',
        '0007f-0009f': 'NotoSansSymbols2',
        '021af': 'NotoSansSymbols2',
        '021ea-021f0': 'NotoSansSymbols2',
        '021f3': 'NotoSansSymbols2',
        '02218': 'NotoSansSymbols2',
        '02219': 'NotoSansTaiTham',
        '022c4-022c6': 'NotoSansSymbols2',
        '02316': 'NotoSansSymbols2',
        '0231a-0231b': 'NotoSansSymbols2',
        '02324-02328': 'NotoSansSymbols2',
        '0232b': 'NotoSansSymbols2',
        '0237b': 'NotoSansSymbols2',
        '0237d-0237f': 'NotoSansSymbols2',
        '023cf': 'NotoSansSymbols2',
        '023e9-023ea': 'NotoSansSymbols2',
        '023ed-023ef': 'NotoSansSymbols2',
        '023f1-023fe': 'NotoSansSymbols2',
        '02400-02422': 'NotoSansSymbols2',
        '02424-02426': 'NotoSansSymbols2',
        '02440-0244a': 'NotoSansSymbols2',
        '025ac-025b0': 'NotoSansSymbols2',
        '025b4-025b5': 'NotoSansSymbols2',
        '025b8-025bb': 'NotoSansSymbols2',
        '025be-025bf': 'NotoSansSymbols2',
        '025c2-025c5': 'NotoSansSymbols2',
        '025cd': 'NotoSansSymbols2',
        '025d4-025e1': 'NotoSansSymbols2',
        '025e7-025ee': 'NotoSansSymbols2',
        '025f0-025ff': 'NotoSansSymbols2',
        '02604': 'NotoSansSymbols2',
        '02607-02608': 'NotoSansSymbols2',
        '02610-02612': 'NotoSansSymbols2',
        '02614-02615': 'NotoSansSymbols2',
        '02618-0261b': 'NotoSansSymbols2',
        '02620-02623': 'NotoSansSymbols2',
        '02630-02637': 'NotoSansSymbols2',
        '0263c': 'NotoSansSymbols2',
        '02654-0265f': 'NotoSansSymbols2',
        '0267f-0268f': 'NotoSansSymbols2',
        '0269e-0269f': 'NotoSansSymbols2',
        '026a1': 'NotoSansSymbols2',
        '026aa-026ac': 'NotoSansSymbols2',
        '026bf-026cd': 'NotoSansSymbols2',
        '026cf-026e1': 'NotoSansSymbols2',
        '02700-02701': 'NotoSansSymbols2',
        '02703-02704': 'NotoSansSymbols2',
        '02706-02709': 'NotoSansSymbols2',
        '0270b-02712': 'NotoSansSymbols2',
        '02714-02719': 'NotoSansSymbols2',
        '0271b-0271c': 'NotoSansSymbols2',
        '02722-02727': 'NotoSansSymbols2',
        '02729-0273c': 'NotoSansSymbols2',
        '0273e': 'NotoSansSymbols2',
        '02741-0274b': 'NotoSansSymbols2',
        '0274d': 'NotoSansSymbols2',
        '0274f-02753': 'NotoSansSymbols2',
        '02757-02775': 'NotoSansSymbols2',
        '02794': 'NotoSansSymbols2',
        '02798-027a0': 'NotoSansSymbols2',
        '027a2-027af': 'NotoSansSymbols2',
        '027b1-027be': 'NotoSansSymbols2',
        '02800-028ff': 'NotoSansSymbols2',
        '02981': 'NotoSansSymbols2',
        '029eb': 'NotoSansSymbols2',
        '02b00-02b04': 'NotoSansSymbols2',
        '02b08-02b0d': 'NotoSansSymbols2',
        '02b12-02b19': 'NotoSansSymbols2',
        '02b1b-02b2f': 'NotoSansSymbols2',
        '02b4d-02b73': 'NotoSansSymbols2',
        '02b76-02b95': 'NotoSansSymbols2',
        '02b98-02bb9': 'NotoSansSymbols2',
        '02bbd-02bc8': 'NotoSansSymbols2',
        '02bca-02bd1': 'NotoSansSymbols2',
        '02bec-02bef': 'NotoSansSymbols2',
        '04dc0-04dff': 'NotoSansSymbols2',
        '0fff9-0fffb': 'NotoSansSymbols2',
        '10140-1018e': 'NotoSansSymbols2',
        '10190-1019b': 'NotoSansSymbols2',
        '101a0': 'NotoSansSymbols2',
        '101d0-101fd': 'NotoSansSymbols2',
        '102e0-102fb': 'NotoSansSymbols2',
        '10e60-10e7e': 'NotoSansSymbols2',
        '1d300-1d356': 'NotoSansSymbols2',
        '1d360-1d371': 'NotoSansSymbols2',
        '1f000-1f02b': 'NotoSansSymbols2',
        '1f030-1f093': 'NotoSansSymbols2',
        '1f0a0-1f0ae': 'NotoSansSymbols2',
        '1f0b1-1f0bf': 'NotoSansSymbols2',
        '1f0c1-1f0cf': 'NotoSansSymbols2',
        '1f0d1-1f0f5': 'NotoSansSymbols2',
        '1f30d-1f30f': 'NotoSansSymbols2',
        '1f315': 'NotoSansSymbols2',
        '1f31c': 'NotoSansSymbols2',
        '1f321-1f32c': 'NotoSansSymbols2',
        '1f336': 'NotoSansSymbols2',
        '1f378': 'NotoSansSymbols2',
        '1f37d': 'NotoSansSymbols2',
        '1f393-1f39f': 'NotoSansSymbols2',
        '1f3a7': 'NotoSansSymbols2',
        '1f3ac-1f3ae': 'NotoSansSymbols2',
        '1f3c2': 'NotoSansSymbols2',
        '1f3c4': 'NotoSansSymbols2',
        '1f3c6': 'NotoSansSymbols2',
        '1f3ca-1f3ce': 'NotoSansSymbols2',
        '1f3d4-1f3e0': 'NotoSansSymbols2',
        '1f3ed': 'NotoSansSymbols2',
        '1f3f1-1f3f3': 'NotoSansSymbols2',
        '1f3f5-1f3f7': 'NotoSansSymbols2',
        '1f408': 'NotoSansSymbols2',
        '1f415': 'NotoSansSymbols2',
        '1f41f': 'NotoSansSymbols2',
        '1f426': 'NotoSansSymbols2',
        '1f43f': 'NotoSansSymbols2',
        '1f441-1f442': 'NotoSansSymbols2',
        '1f446-1f449': 'NotoSansSymbols2',
        '1f44c-1f44e': 'NotoSansSymbols2',
        '1f453': 'NotoSansSymbols2',
        '1f46a': 'NotoSansSymbols2',
        '1f47d': 'NotoSansSymbols2',
        '1f4a3': 'NotoSansSymbols2',
        '1f4b0': 'NotoSansSymbols2',
        '1f4b3': 'NotoSansSymbols2',
        '1f4b9': 'NotoSansSymbols2',
        '1f4bb': 'NotoSansSymbols2',
        '1f4bf': 'NotoSansSymbols2',
        '1f4c8-1f4cb': 'NotoSansSymbols2',
        '1f4da': 'NotoSansSymbols2',
        '1f4df': 'NotoSansSymbols2',
        '1f4e4-1f4e6': 'NotoSansSymbols2',
        '1f4ea-1f4ed': 'NotoSansSymbols2',
        '1f4f7': 'NotoSansSymbols2',
        '1f4f9-1f4fb': 'NotoSansSymbols2',
        '1f4fd-1f4fe': 'NotoSansSymbols2',
        '1f503': 'NotoSansSymbols2',
        '1f507-1f50a': 'NotoSansSymbols2',
        '1f50d': 'NotoSansSymbols2',
        '1f512-1f513': 'NotoSansSymbols2',
        '1f53e-1f545': 'NotoSansSymbols2',
        '1f54a': 'NotoSansSymbols2',
        '1f550-1f579': 'NotoSansSymbols2',
        '1f57b-1f594': 'NotoSansSymbols2',
        '1f597-1f5a3': 'NotoSansSymbols2',
        '1f5a5-1f5fa': 'NotoSansSymbols2',
        '1f650-1f67f': 'NotoSansSymbols2',
        '1f687': 'NotoSansSymbols2',
        '1f68d': 'NotoSansSymbols2',
        '1f691': 'NotoSansSymbols2',
        '1f694': 'NotoSansSymbols2',
        '1f698': 'NotoSansSymbols2',
        '1f6ad': 'NotoSansSymbols2',
        '1f6b2': 'NotoSansSymbols2',
        '1f6b9-1f6ba': 'NotoSansSymbols2',
        '1f6bc': 'NotoSansSymbols2',
        '1f6c6-1f6cb': 'NotoSansSymbols2',
        '1f6cd-1f6cf': 'NotoSansSymbols2',
        '1f6e0-1f6ea': 'NotoSansSymbols2',
        '1f6f0-1f6f3': 'NotoSansSymbols2',
        '1f780-1f7d4': 'NotoSansSymbols2',
        '1f800-1f80b': 'NotoSansSymbols2',
        '1f810-1f847': 'NotoSansSymbols2',
        '1f850-1f859': 'NotoSansSymbols2',
        '1f860-1f887': 'NotoSansSymbols2',
        '1f890-1f8ad': 'NotoSansSymbols2',
        '1f93b': 'NotoSansSymbols2',
        '1f946': 'NotoSansSymbols2',
        '0030a': 'NotoSansSyriacWestern',
        '00320': 'NotoSansSyriacWestern',
        '00325': 'NotoSansSyriacWestern',
        '0032d-0032e': 'NotoSansSyriacWestern',
        '00700-0070d': 'NotoSansSyriacWestern',
        '0070f-0074a': 'NotoSansSyriacWestern',
        '0074d-0074f': 'NotoSansSyriacWestern',
        '02044': 'NotoSansSyriacWestern',
        '01700-0170c': 'NotoSansTagalog',
        '0170e-01714': 'NotoSansTagalog',
        '01760-0176c': 'NotoSansTagbanwa',
        '0176e-01770': 'NotoSansTagbanwa',
        '01772-01773': 'NotoSansTagbanwa',
        '01950-0196d': 'NotoSansTaiLe',
        '01970-01974': 'NotoSansTaiLe',
        '01a20-01a5e': 'NotoSansTaiTham',
        '01a60-01a7c': 'NotoSansTaiTham',
        '01a7f-01a89': 'NotoSansTaiTham',
        '01a90-01a99': 'NotoSansTaiTham',
        '01aa0-01aad': 'NotoSansTaiTham',
        '0a78c': 'NotoSansTaiViet',
        '0aa80-0aac2': 'NotoSansTaiViet',
        '0aadb-0aadf': 'NotoSansTaiViet',
        '00b82-00b83': 'NotoSansTamil',
        '00b85-00b8a': 'NotoSansTamil',
        '00b8e-00b90': 'NotoSansTamil',
        '00b92-00b95': 'NotoSansTamil',
        '00b99-00b9a': 'NotoSansTamil',
        '00b9c': 'NotoSansTamil',
        '00b9e-00b9f': 'NotoSansTamil',
        '00ba3-00ba4': 'NotoSansTamil',
        '00ba8-00baa': 'NotoSansTamil',
        '00bae-00bb9': 'NotoSansTamil',
        '00bbe-00bc2': 'NotoSansTamil',
        '00bc6-00bc8': 'NotoSansTamil',
        '00bca-00bcd': 'NotoSansTamil',
        '00bd0': 'NotoSansTamil',
        '00bd7': 'NotoSansTamil',
        '00be6-00bfa': 'NotoSansTamil',
        '02074': 'NotoSansTamil',
        '02082-02084': 'NotoSansTamil',
        '1133c': 'NotoSansTamil',
        '00c01-00c03': 'NotoSansTelugu',
        '00c05-00c0c': 'NotoSansTelugu',
        '00c0e-00c10': 'NotoSansTelugu',
        '00c12-00c28': 'NotoSansTelugu',
        '00c2a-00c33': 'NotoSansTelugu',
        '00c35-00c39': 'NotoSansTelugu',
        '00c3d-00c44': 'NotoSansTelugu',
        '00c46-00c48': 'NotoSansTelugu',
        '00c4a-00c4d': 'NotoSansTelugu',
        '00c55-00c56': 'NotoSansTelugu',
        '00c58-00c59': 'NotoSansTelugu',
        '00c60-00c63': 'NotoSansTelugu',
        '00c66-00c6f': 'NotoSansTelugu',
        '00c78-00c7f': 'NotoSansTelugu',
        '00780-007b1': 'NotoSansThaana',
        '002d7': 'NotoSansThai',
        '00e01-00e3a': 'NotoSansThai',
        '00e3f-00e5b': 'NotoSansThai',
        '00f00-00f47': 'NotoSansTibetan',
        '00f49-00f6c': 'NotoSansTibetan',
        '00f71-00f97': 'NotoSansTibetan',
        '00f99-00fbc': 'NotoSansTibetan',
        '00fbe-00fcc': 'NotoSansTibetan',
        '00fce-00fda': 'NotoSansTibetan',
        '00306': 'NotoSansTifinagh',
        '00309': 'NotoSansTifinagh',
        '02d30-02d67': 'NotoSansTifinagh',
        '02d6f-02d70': 'NotoSansTifinagh',
        '02d7f': 'NotoSansTifinagh',
        '10380-1039d': 'NotoSansUgaritic',
        '1039f': 'NotoSansUgaritic',
        '0a500-0a62b': 'NotoSansVai',
        '0a000-0a48c': 'NotoSansYi',
        '0a490-0a4c6': 'NotoSansYi'
    },
    Serif: {
        '11303': 'NotoSerifTamil',
        '20087': 'NotoSerifCJKtc',
        '20158': 'NotoSerifCJKtc',
        '20164': 'NotoSerifCJKtc',
        '20213': 'NotoSerifCJKtc',
        '20371': 'NotoSerifCJKtc',
        '20381': 'NotoSerifCJKtc',
        '20509': 'NotoSerifCJKtc',
        '20611': 'NotoSerifCJKtc',
        '20628': 'NotoSerifCJKtc',
        '20676': 'NotoSerifCJKtc',
        '20807': 'NotoSerifCJKtc',
        '20984': 'NotoSerifCJKtc',
        '21201': 'NotoSerifCJKtc',
        '21255': 'NotoSerifCJKtc',
        '21274': 'NotoSerifCJKtc',
        '21336': 'NotoSerifCJKtc',
        '21344': 'NotoSerifCJKtc',
        '21413': 'NotoSerifCJKtc',
        '21647': 'NotoSerifCJKtc',
        '21706': 'NotoSerifCJKtc',
        '21742': 'NotoSerifCJKtc',
        '22218': 'NotoSerifCJKtc',
        '22609': 'NotoSerifCJKtc',
        '23372': 'NotoSerifCJKtc',
        '23451': 'NotoSerifCJKtc',
        '23465': 'NotoSerifCJKtc',
        '23594': 'NotoSerifCJKtc',
        '23647': 'NotoSerifCJKtc',
        '23824': 'NotoSerifCJKtc',
        '24096': 'NotoSerifCJKtc',
        '24103': 'NotoSerifCJKtc',
        '24629': 'NotoSerifCJKtc',
        '24896': 'NotoSerifCJKtc',
        '25055': 'NotoSerifCJKtc',
        '25122': 'NotoSerifCJKtc',
        '25532': 'NotoSerifCJKtc',
        '25562': 'NotoSerifCJKtc',
        '25771': 'NotoSerifCJKtc',
        '25874': 'NotoSerifCJKtc',
        '26017': 'NotoSerifCJKtc',
        '26060': 'NotoSerifCJKtc',
        '26270': 'NotoSerifCJKtc',
        '26286': 'NotoSerifCJKtc',
        '26402': 'NotoSerifCJKtc',
        '26676': 'NotoSerifCJKtc',
        '26951': 'NotoSerifCJKtc',
        '26999': 'NotoSerifCJKtc',
        '27139': 'NotoSerifCJKtc',
        '27410': 'NotoSerifCJKtc',
        '27449': 'NotoSerifCJKtc',
        '27631': 'NotoSerifCJKtc',
        '27684': 'NotoSerifCJKtc',
        '27693': 'NotoSerifCJKtc',
        '27723': 'NotoSerifCJKtc',
        '27752': 'NotoSerifCJKtc',
        '27985': 'NotoSerifCJKtc',
        '28277': 'NotoSerifCJKtc',
        '28282': 'NotoSerifCJKtc',
        '28408': 'NotoSerifCJKtc',
        '28455': 'NotoSerifCJKtc',
        '28678': 'NotoSerifCJKtc',
        '28695': 'NotoSerifCJKtc',
        '28946': 'NotoSerifCJKtc',
        '28949': 'NotoSerifCJKtc',
        '29490': 'NotoSerifCJKtc',
        '29719': 'NotoSerifCJKtc',
        '29750': 'NotoSerifCJKtc',
        '29810': 'NotoSerifCJKtc',
        '00000': 'NotoSerifThai',
        '0000d': 'NotoSerifThai',
        '00020': 'NotoSerifThai',
        '0002d': 'NotoSerifThai',
        '000a0': 'NotoSerifThai',
        '00531-00556': 'NotoSerifArmenian',
        '00559-0055f': 'NotoSerifArmenian',
        '00561-00587': 'NotoSerifArmenian',
        '00589': 'NotoSerifGeorgian',
        '0058a': 'NotoSerifArmenian',
        '0058d-0058f': 'NotoSerifArmenian',
        '02010': 'NotoSerifThai',
        '0fb13-0fb17': 'NotoSerifArmenian',
        '00021-00023': 'NotoSerifTelugu',
        '00025': 'NotoSerifTelugu',
        '00027-0002c': 'NotoSerifTelugu',
        '0002e-0003f': 'NotoSerifTelugu',
        '0005b-0005f': 'NotoSerifTelugu',
        '0007b-0007e': 'NotoSerifTelugu',
        '000ad': 'NotoSerifTelugu',
        '000d7': 'NotoSerifTelugu',
        '000f7': 'NotoSerifTelugu',
        '00964-00965': 'NotoSerifTelugu',
        '00980-00983': 'NotoSerifBengali',
        '00985-0098c': 'NotoSerifBengali',
        '0098f-00990': 'NotoSerifBengali',
        '00993-009a8': 'NotoSerifBengali',
        '009aa-009b0': 'NotoSerifBengali',
        '009b2': 'NotoSerifBengali',
        '009b6-009b9': 'NotoSerifBengali',
        '009bc-009c4': 'NotoSerifBengali',
        '009c7-009c8': 'NotoSerifBengali',
        '009cb-009ce': 'NotoSerifBengali',
        '009d7': 'NotoSerifBengali',
        '009dc-009dd': 'NotoSerifBengali',
        '009df-009e3': 'NotoSerifBengali',
        '009e6-009fb': 'NotoSerifBengali',
        '0200b-0200d': 'NotoSerifThai',
        '02013-02014': 'NotoSerifTelugu',
        '02018-02019': 'NotoSerifTelugu',
        '0201c-0201d': 'NotoSerifTelugu',
        '02026': 'NotoSerifTelugu',
        '020b9': 'NotoSerifTelugu',
        '02212': 'NotoSerifTelugu',
        '025cc': 'NotoSerifThai',
        '0feff': 'NotoSerifTelugu',
        '00024': 'NotoSerifCJKtc',
        '00026': 'NotoSerifCJKtc',
        '00040-0005a': 'NotoSerifCJKtc',
        '00060-0007a': 'NotoSerifCJKtc',
        '000a1-000aa': 'NotoSerifCJKtc',
        '000ab': 'NotoSerifKhmer',
        '000ac': 'NotoSerifCJKtc',
        '000ae': 'NotoSerifCJKtc',
        '000af': 'NotoSerifDevanagari',
        '000b0-000b1': 'NotoSerifCJKtc',
        '000b2-000b3': 'NotoSerifTamil',
        '000b4-000ba': 'NotoSerifCJKtc',
        '000bb': 'NotoSerifKhmer',
        '000bc-000d6': 'NotoSerifCJKtc',
        '000d8-000f6': 'NotoSerifCJKtc',
        '000f8-00103': 'NotoSerifCJKtc',
        '00110-00113': 'NotoSerifCJKtc',
        '0011a-0011b': 'NotoSerifCJKtc',
        '00128-0012b': 'NotoSerifCJKtc',
        '00143-00144': 'NotoSerifCJKtc',
        '00147-00148': 'NotoSerifCJKtc',
        '0014c-0014f': 'NotoSerifCJKtc',
        '00152-00153': 'NotoSerifCJKtc',
        '00168-0016d': 'NotoSerifCJKtc',
        '00192': 'NotoSerifCJKtc',
        '001a0-001a1': 'NotoSerifCJKtc',
        '001af-001b0': 'NotoSerifCJKtc',
        '001cd-001dc': 'NotoSerifCJKtc',
        '001f8-001f9': 'NotoSerifCJKtc',
        '00251': 'NotoSerifCJKtc',
        '00261': 'NotoSerifCJKtc',
        '002c7': 'NotoSerifCJKtc',
        '002c9-002cb': 'NotoSerifCJKtc',
        '002d9': 'NotoSerifCJKtc',
        '00391-003a1': 'NotoSerifCJKtc',
        '003a3-003a9': 'NotoSerifCJKtc',
        '003b1-003c9': 'NotoSerifCJKtc',
        '00401': 'NotoSerifCJKtc',
        '00410-0044f': 'NotoSerifCJKtc',
        '00451': 'NotoSerifCJKtc',
        '01100-011ff': 'NotoSerifCJKtc',
        '01e3e-01e3f': 'NotoSerifCJKtc',
        '01ea0-01ef9': 'NotoSerifCJKtc',
        '02002-02003': 'NotoSerifCJKtc',
        '02011-02012': 'NotoSerifCJKtc',
        '02015-02016': 'NotoSerifCJKtc',
        '0201a': 'NotoSerifCJKtc',
        '0201e': 'NotoSerifCJKtc',
        '02020-02022': 'NotoSerifCJKtc',
        '02025': 'NotoSerifCJKtc',
        '02027': 'NotoSerifCJKtc',
        '02030': 'NotoSerifCJKtc',
        '02032-02033': 'NotoSerifCJKtc',
        '02035': 'NotoSerifCJKtc',
        '02039-0203c': 'NotoSerifCJKtc',
        '02042': 'NotoSerifCJKtc',
        '02047-02049': 'NotoSerifCJKtc',
        '02051': 'NotoSerifCJKtc',
        '020a9': 'NotoSerifCJKtc',
        '020ab-020ac': 'NotoSerifCJKtc',
        '020dd-020de': 'NotoSerifCJKtc',
        '02100': 'NotoSerifCJKtc',
        '02103': 'NotoSerifCJKtc',
        '02105': 'NotoSerifCJKtc',
        '02109-0210a': 'NotoSerifCJKtc',
        '0210f': 'NotoSerifCJKtc',
        '02113': 'NotoSerifCJKtc',
        '02116': 'NotoSerifCJKtc',
        '02121-02122': 'NotoSerifCJKtc',
        '02126-02127': 'NotoSerifCJKtc',
        '0212b': 'NotoSerifCJKtc',
        '0212e': 'NotoSerifCJKtc',
        '02135': 'NotoSerifCJKtc',
        '0213b': 'NotoSerifCJKtc',
        '02160-0216b': 'NotoSerifCJKtc',
        '02170-0217b': 'NotoSerifCJKtc',
        '02190-02199': 'NotoSerifCJKtc',
        '021b8-021b9': 'NotoSerifCJKtc',
        '021c4-021c6': 'NotoSerifCJKtc',
        '021cb-021cc': 'NotoSerifCJKtc',
        '021d0': 'NotoSerifCJKtc',
        '021d2': 'NotoSerifCJKtc',
        '021d4': 'NotoSerifCJKtc',
        '021e6-021e9': 'NotoSerifCJKtc',
        '021f5': 'NotoSerifCJKtc',
        '02200': 'NotoSerifCJKtc',
        '02202-02203': 'NotoSerifCJKtc',
        '02205-0220b': 'NotoSerifCJKtc',
        '0220f': 'NotoSerifCJKtc',
        '02211': 'NotoSerifCJKtc',
        '02213': 'NotoSerifCJKtc',
        '02215': 'NotoSerifCJKtc',
        '0221a': 'NotoSerifCJKtc',
        '0221d-02220': 'NotoSerifCJKtc',
        '02223': 'NotoSerifCJKtc',
        '02225-0222e': 'NotoSerifCJKtc',
        '02234-02237': 'NotoSerifCJKtc',
        '0223d': 'NotoSerifCJKtc',
        '02243': 'NotoSerifCJKtc',
        '02245': 'NotoSerifCJKtc',
        '02248': 'NotoSerifCJKtc',
        '0224c': 'NotoSerifCJKtc',
        '02260-02262': 'NotoSerifCJKtc',
        '02264-02267': 'NotoSerifCJKtc',
        '0226a-0226b': 'NotoSerifCJKtc',
        '0226e-0226f': 'NotoSerifCJKtc',
        '02272-02273': 'NotoSerifCJKtc',
        '02276-02277': 'NotoSerifCJKtc',
        '02282-02287': 'NotoSerifCJKtc',
        '0228a-0228b': 'NotoSerifCJKtc',
        '02295-02299': 'NotoSerifCJKtc',
        '022a0': 'NotoSerifCJKtc',
        '022a5': 'NotoSerifCJKtc',
        '022bf': 'NotoSerifCJKtc',
        '022da-022db': 'NotoSerifCJKtc',
        '022ef': 'NotoSerifCJKtc',
        '02305-02307': 'NotoSerifCJKtc',
        '02312': 'NotoSerifCJKtc',
        '02318': 'NotoSerifCJKtc',
        '02329-0232a': 'NotoSerifCJKtc',
        '023b0-023b1': 'NotoSerifCJKtc',
        '023be-023cc': 'NotoSerifCJKtc',
        '023ce': 'NotoSerifCJKtc',
        '023da-023db': 'NotoSerifCJKtc',
        '02423': 'NotoSerifCJKtc',
        '02460-025ab': 'NotoSerifCJKtc',
        '025b1-025b3': 'NotoSerifCJKtc',
        '025b6-025b7': 'NotoSerifCJKtc',
        '025bc-025bd': 'NotoSerifCJKtc',
        '025c0-025c1': 'NotoSerifCJKtc',
        '025c6-025c7': 'NotoSerifCJKtc',
        '025c9-025cb': 'NotoSerifCJKtc',
        '025ce-025d3': 'NotoSerifCJKtc',
        '025e2-025e6': 'NotoSerifCJKtc',
        '025ef': 'NotoSerifCJKtc',
        '02600-02603': 'NotoSerifCJKtc',
        '02605-02606': 'NotoSerifCJKtc',
        '02609': 'NotoSerifCJKtc',
        '0260e-0260f': 'NotoSerifCJKtc',
        '02616-02617': 'NotoSerifCJKtc',
        '0261c-0261f': 'NotoSerifCJKtc',
        '0262f': 'NotoSerifCJKtc',
        '02640-02642': 'NotoSerifCJKtc',
        '02660-0266f': 'NotoSerifCJKtc',
        '02672-0267d': 'NotoSerifCJKtc',
        '026a0': 'NotoSerifCJKtc',
        '026bd-026be': 'NotoSerifCJKtc',
        '02702': 'NotoSerifCJKtc',
        '02713': 'NotoSerifCJKtc',
        '0271a': 'NotoSerifCJKtc',
        '0273d': 'NotoSerifCJKtc',
        '0273f-02740': 'NotoSerifCJKtc',
        '02756': 'NotoSerifCJKtc',
        '02776-0277f': 'NotoSerifCJKtc',
        '027a1': 'NotoSerifCJKtc',
        '02934-02935': 'NotoSerifCJKtc',
        '029bf': 'NotoSerifCJKtc',
        '029fa-029fb': 'NotoSerifCJKtc',
        '02b05-02b07': 'NotoSerifCJKtc',
        '02b1a': 'NotoSerifCJKtc',
        '02b95': 'NotoSerifCJKtc',
        '02e3a-02e3b': 'NotoSerifCJKtc',
        '02e80-02e99': 'NotoSerifCJKtc',
        '02e9b-02ef3': 'NotoSerifCJKtc',
        '02f00-02fd5': 'NotoSerifCJKtc',
        '02ff0-02ffb': 'NotoSerifCJKtc',
        '03000-0303f': 'NotoSerifCJKtc',
        '03041-03096': 'NotoSerifCJKtc',
        '03099-030ff': 'NotoSerifCJKtc',
        '03105-0312f': 'NotoSerifCJKtc',
        '03131-0318e': 'NotoSerifCJKtc',
        '03190-031ba': 'NotoSerifCJKtc',
        '031c0-031e3': 'NotoSerifCJKtc',
        '031f0-0321e': 'NotoSerifCJKtc',
        '03220-032fe': 'NotoSerifCJKtc',
        '03300-0332b': 'NotoSerifCJKtc',
        '0332d-04db5': 'NotoSerifCJKtc',
        '04e00-09fea': 'NotoSerifCJKtc',
        '0a960-0a97c': 'NotoSerifCJKtc',
        '0ac00-0d7a3': 'NotoSerifCJKtc',
        '0d7b0-0d7c6': 'NotoSerifCJKtc',
        '0d7cb-0d7fb': 'NotoSerifCJKtc',
        '0f900-0fa6d': 'NotoSerifCJKtc',
        '0fb00-0fb04': 'NotoSerifCJKtc',
        '0fe10-0fe19': 'NotoSerifCJKtc',
        '0fe30-0fe52': 'NotoSerifCJKtc',
        '0fe54-0fe66': 'NotoSerifCJKtc',
        '0fe68-0fe6b': 'NotoSerifCJKtc',
        '0ff01-0ffbe': 'NotoSerifCJKtc',
        '0ffc2-0ffc7': 'NotoSerifCJKtc',
        '0ffca-0ffcf': 'NotoSerifCJKtc',
        '0ffd2-0ffd7': 'NotoSerifCJKtc',
        '0ffda-0ffdc': 'NotoSerifCJKtc',
        '0ffe0-0ffe6': 'NotoSerifCJKtc',
        '0ffe8-0ffee': 'NotoSerifCJKtc',
        '1f100-1f10a': 'NotoSerifCJKtc',
        '1f110-1f16b': 'NotoSerifCJKtc',
        '1f170-1f1ac': 'NotoSerifCJKtc',
        '1f200-1f202': 'NotoSerifCJKtc',
        '1f210-1f23b': 'NotoSerifCJKtc',
        '1f240-1f248': 'NotoSerifCJKtc',
        '1f250-1f251': 'NotoSerifCJKtc',
        '2000b': 'NotoSerifCJKtc',
        '20089-2008a': 'NotoSerifCJKtc',
        '200a2': 'NotoSerifCJKtc',
        '200a4': 'NotoSerifCJKtc',
        '200b0': 'NotoSerifCJKtc',
        '200cc': 'NotoSerifCJKtc',
        '200f5': 'NotoSerifCJKtc',
        '201a2': 'NotoSerifCJKtc',
        '2032b': 'NotoSerifCJKtc',
        '203f9': 'NotoSerifCJKtc',
        '2044a': 'NotoSerifCJKtc',
        '2053f': 'NotoSerifCJKtc',
        '205b1': 'NotoSerifCJKtc',
        '205d6': 'NotoSerifCJKtc',
        '206ec': 'NotoSerifCJKtc',
        '2074f': 'NotoSerifCJKtc',
        '207c8': 'NotoSerifCJKtc',
        '2083a': 'NotoSerifCJKtc',
        '208b9': 'NotoSerifCJKtc',
        '2090e': 'NotoSerifCJKtc',
        '2097c': 'NotoSerifCJKtc',
        '2099d': 'NotoSerifCJKtc',
        '20a64': 'NotoSerifCJKtc',
        '20ad3': 'NotoSerifCJKtc',
        '20b1d': 'NotoSerifCJKtc',
        '20b9f': 'NotoSerifCJKtc',
        '20bb7': 'NotoSerifCJKtc',
        '20cd0': 'NotoSerifCJKtc',
        '20d45': 'NotoSerifCJKtc',
        '20d58': 'NotoSerifCJKtc',
        '20de1': 'NotoSerifCJKtc',
        '20e64': 'NotoSerifCJKtc',
        '20e6d': 'NotoSerifCJKtc',
        '20e95': 'NotoSerifCJKtc',
        '20f5f': 'NotoSerifCJKtc',
        '2123d': 'NotoSerifCJKtc',
        '2127b': 'NotoSerifCJKtc',
        '212d7': 'NotoSerifCJKtc',
        '212e4': 'NotoSerifCJKtc',
        '212fd': 'NotoSerifCJKtc',
        '2131b': 'NotoSerifCJKtc',
        '2139a': 'NotoSerifCJKtc',
        '213c4': 'NotoSerifCJKtc',
        '2146d-2146e': 'NotoSerifCJKtc',
        '215d7': 'NotoSerifCJKtc',
        '216b4': 'NotoSerifCJKtc',
        '218bd': 'NotoSerifCJKtc',
        '219c3': 'NotoSerifCJKtc',
        '21a1a': 'NotoSerifCJKtc',
        '21c56': 'NotoSerifCJKtc',
        '21d2d': 'NotoSerifCJKtc',
        '21d45': 'NotoSerifCJKtc',
        '21d62': 'NotoSerifCJKtc',
        '21d78': 'NotoSerifCJKtc',
        '21d92': 'NotoSerifCJKtc',
        '21d9c': 'NotoSerifCJKtc',
        '21da1': 'NotoSerifCJKtc',
        '21db7': 'NotoSerifCJKtc',
        '21de0': 'NotoSerifCJKtc',
        '21e33-21e34': 'NotoSerifCJKtc',
        '21f1e': 'NotoSerifCJKtc',
        '21f76': 'NotoSerifCJKtc',
        '21ffa': 'NotoSerifCJKtc',
        '2217b': 'NotoSerifCJKtc',
        '2231e': 'NotoSerifCJKtc',
        '223ad': 'NotoSerifCJKtc',
        '226f3': 'NotoSerifCJKtc',
        '2285b': 'NotoSerifCJKtc',
        '228ab': 'NotoSerifCJKtc',
        '2298f': 'NotoSerifCJKtc',
        '22ab8': 'NotoSerifCJKtc',
        '22b46': 'NotoSerifCJKtc',
        '22b4f-22b50': 'NotoSerifCJKtc',
        '22ba6': 'NotoSerifCJKtc',
        '22c1d': 'NotoSerifCJKtc',
        '22c24': 'NotoSerifCJKtc',
        '22de1': 'NotoSerifCJKtc',
        '22e42': 'NotoSerifCJKtc',
        '22feb': 'NotoSerifCJKtc',
        '231b6': 'NotoSerifCJKtc',
        '231c3-231c4': 'NotoSerifCJKtc',
        '231f5': 'NotoSerifCJKtc',
        '233cc': 'NotoSerifCJKtc',
        '233d0': 'NotoSerifCJKtc',
        '233d2-233d3': 'NotoSerifCJKtc',
        '233d5': 'NotoSerifCJKtc',
        '233da': 'NotoSerifCJKtc',
        '233df': 'NotoSerifCJKtc',
        '233e4': 'NotoSerifCJKtc',
        '233fe': 'NotoSerifCJKtc',
        '2344a-2344b': 'NotoSerifCJKtc',
        '234e4': 'NotoSerifCJKtc',
        '2355a': 'NotoSerifCJKtc',
        '235c4': 'NotoSerifCJKtc',
        '235cb': 'NotoSerifCJKtc',
        '23638-2363a': 'NotoSerifCJKtc',
        '2370c': 'NotoSerifCJKtc',
        '2371c': 'NotoSerifCJKtc',
        '2373f': 'NotoSerifCJKtc',
        '23763-23764': 'NotoSerifCJKtc',
        '237e7': 'NotoSerifCJKtc',
        '237f1': 'NotoSerifCJKtc',
        '237ff': 'NotoSerifCJKtc',
        '2383d': 'NotoSerifCJKtc',
        '23a98': 'NotoSerifCJKtc',
        '23c7f': 'NotoSerifCJKtc',
        '23c97-23c98': 'NotoSerifCJKtc',
        '23cbe': 'NotoSerifCJKtc',
        '23cfe': 'NotoSerifCJKtc',
        '23d00': 'NotoSerifCJKtc',
        '23d0e': 'NotoSerifCJKtc',
        '23d40': 'NotoSerifCJKtc',
        '23dd3': 'NotoSerifCJKtc',
        '23df9-23dfa': 'NotoSerifCJKtc',
        '23e23': 'NotoSerifCJKtc',
        '23f7e': 'NotoSerifCJKtc',
        '2404b': 'NotoSerifCJKtc',
        '241c6': 'NotoSerifCJKtc',
        '241fe': 'NotoSerifCJKtc',
        '242ee': 'NotoSerifCJKtc',
        '243bc': 'NotoSerifCJKtc',
        '243d0': 'NotoSerifCJKtc',
        '246a5': 'NotoSerifCJKtc',
        '247f1': 'NotoSerifCJKtc',
        '248e9': 'NotoSerifCJKtc',
        '249db': 'NotoSerifCJKtc',
        '24a4d': 'NotoSerifCJKtc',
        '24a7d': 'NotoSerifCJKtc',
        '24ac9': 'NotoSerifCJKtc',
        '24b56': 'NotoSerifCJKtc',
        '24b6f': 'NotoSerifCJKtc',
        '24c16': 'NotoSerifCJKtc',
        '24d14': 'NotoSerifCJKtc',
        '24e04': 'NotoSerifCJKtc',
        '24e0e': 'NotoSerifCJKtc',
        '24e37': 'NotoSerifCJKtc',
        '24e6a': 'NotoSerifCJKtc',
        '24e8b': 'NotoSerifCJKtc',
        '24ff2': 'NotoSerifCJKtc',
        '2504a': 'NotoSerifCJKtc',
        '251a9': 'NotoSerifCJKtc',
        '251cd': 'NotoSerifCJKtc',
        '251e5': 'NotoSerifCJKtc',
        '2521e': 'NotoSerifCJKtc',
        '2524c': 'NotoSerifCJKtc',
        '2542e': 'NotoSerifCJKtc',
        '2548e': 'NotoSerifCJKtc',
        '254d9': 'NotoSerifCJKtc',
        '2550e': 'NotoSerifCJKtc',
        '255a7-255a8': 'NotoSerifCJKtc',
        '2567f': 'NotoSerifCJKtc',
        '257a9': 'NotoSerifCJKtc',
        '257b4': 'NotoSerifCJKtc',
        '259c4': 'NotoSerifCJKtc',
        '259cc': 'NotoSerifCJKtc',
        '259d4': 'NotoSerifCJKtc',
        '25ad7': 'NotoSerifCJKtc',
        '25ae3-25ae4': 'NotoSerifCJKtc',
        '25af1': 'NotoSerifCJKtc',
        '25bb2': 'NotoSerifCJKtc',
        '25c4b': 'NotoSerifCJKtc',
        '25c64': 'NotoSerifCJKtc',
        '25da1': 'NotoSerifCJKtc',
        '25e2e': 'NotoSerifCJKtc',
        '25e56': 'NotoSerifCJKtc',
        '25e62': 'NotoSerifCJKtc',
        '25e65': 'NotoSerifCJKtc',
        '25ec2': 'NotoSerifCJKtc',
        '25ed7-25ed8': 'NotoSerifCJKtc',
        '25ee8': 'NotoSerifCJKtc',
        '25f23': 'NotoSerifCJKtc',
        '25f5c': 'NotoSerifCJKtc',
        '25fd4': 'NotoSerifCJKtc',
        '25fe0': 'NotoSerifCJKtc',
        '25ffb': 'NotoSerifCJKtc',
        '2600c': 'NotoSerifCJKtc',
        '260ed': 'NotoSerifCJKtc',
        '26221-26222': 'NotoSerifCJKtc',
        '2626a': 'NotoSerifCJKtc',
        '2634c': 'NotoSerifCJKtc',
        '2648d': 'NotoSerifCJKtc',
        '2667e': 'NotoSerifCJKtc',
        '266b0': 'NotoSerifCJKtc',
        '2671d': 'NotoSerifCJKtc',
        '2677c': 'NotoSerifCJKtc',
        '268dd': 'NotoSerifCJKtc',
        '268ea': 'NotoSerifCJKtc',
        '2696f': 'NotoSerifCJKtc',
        '269dd': 'NotoSerifCJKtc',
        '26a1e': 'NotoSerifCJKtc',
        '26a58': 'NotoSerifCJKtc',
        '26a8c': 'NotoSerifCJKtc',
        '26ab7': 'NotoSerifCJKtc',
        '26aff': 'NotoSerifCJKtc',
        '26b5c': 'NotoSerifCJKtc',
        '26c21': 'NotoSerifCJKtc',
        '26c29': 'NotoSerifCJKtc',
        '26c73': 'NotoSerifCJKtc',
        '26c9e': 'NotoSerifCJKtc',
        '26cdd': 'NotoSerifCJKtc',
        '26e40': 'NotoSerifCJKtc',
        '26e65': 'NotoSerifCJKtc',
        '26f94': 'NotoSerifCJKtc',
        '26ff6-26ff8': 'NotoSerifCJKtc',
        '270f4': 'NotoSerifCJKtc',
        '2710d': 'NotoSerifCJKtc',
        '273da-273db': 'NotoSerifCJKtc',
        '273fe': 'NotoSerifCJKtc',
        '27614-27615': 'NotoSerifCJKtc',
        '2770e': 'NotoSerifCJKtc',
        '278b2': 'NotoSerifCJKtc',
        '279b4': 'NotoSerifCJKtc',
        '27a84': 'NotoSerifCJKtc',
        '27bb3': 'NotoSerifCJKtc',
        '27bbe': 'NotoSerifCJKtc',
        '27bc7': 'NotoSerifCJKtc',
        '27c3c': 'NotoSerifCJKtc',
        '27cb8': 'NotoSerifCJKtc',
        '27d73': 'NotoSerifCJKtc',
        '27da0': 'NotoSerifCJKtc',
        '27e10': 'NotoSerifCJKtc',
        '27fb7': 'NotoSerifCJKtc',
        '27ff9': 'NotoSerifCJKtc',
        '2808a': 'NotoSerifCJKtc',
        '280bb': 'NotoSerifCJKtc',
        '282f3': 'NotoSerifCJKtc',
        '283cd': 'NotoSerifCJKtc',
        '2840c': 'NotoSerifCJKtc',
        '2856b': 'NotoSerifCJKtc',
        '285c8-285c9': 'NotoSerifCJKtc',
        '286d7': 'NotoSerifCJKtc',
        '286fa': 'NotoSerifCJKtc',
        '287e0': 'NotoSerifCJKtc',
        '2896b': 'NotoSerifCJKtc',
        '28987-28988': 'NotoSerifCJKtc',
        '289ba-289bb': 'NotoSerifCJKtc',
        '28a1e': 'NotoSerifCJKtc',
        '28a29': 'NotoSerifCJKtc',
        '28a43': 'NotoSerifCJKtc',
        '28a71': 'NotoSerifCJKtc',
        '28a99': 'NotoSerifCJKtc',
        '28acd': 'NotoSerifCJKtc',
        '28add': 'NotoSerifCJKtc',
        '28ae4': 'NotoSerifCJKtc',
        '28b49': 'NotoSerifCJKtc',
        '28bc1': 'NotoSerifCJKtc',
        '28bef': 'NotoSerifCJKtc',
        '28c47': 'NotoSerifCJKtc',
        '28c4f': 'NotoSerifCJKtc',
        '28c51': 'NotoSerifCJKtc',
        '28c54': 'NotoSerifCJKtc',
        '28cdd': 'NotoSerifCJKtc',
        '28d10': 'NotoSerifCJKtc',
        '28d71': 'NotoSerifCJKtc',
        '28dfb': 'NotoSerifCJKtc',
        '28e0f': 'NotoSerifCJKtc',
        '28e17': 'NotoSerifCJKtc',
        '28e1f': 'NotoSerifCJKtc',
        '28e36': 'NotoSerifCJKtc',
        '28e89': 'NotoSerifCJKtc',
        '28e99': 'NotoSerifCJKtc',
        '28eeb': 'NotoSerifCJKtc',
        '28ef6': 'NotoSerifCJKtc',
        '28f32': 'NotoSerifCJKtc',
        '28ff8': 'NotoSerifCJKtc',
        '292a0': 'NotoSerifCJKtc',
        '292b1': 'NotoSerifCJKtc',
        '295cf': 'NotoSerifCJKtc',
        '2967f': 'NotoSerifCJKtc',
        '296f0': 'NotoSerifCJKtc',
        '298c6': 'NotoSerifCJKtc',
        '29a72': 'NotoSerifCJKtc',
        '29d4b': 'NotoSerifCJKtc',
        '29ddb': 'NotoSerifCJKtc',
        '29e15': 'NotoSerifCJKtc',
        '29e3d': 'NotoSerifCJKtc',
        '29e49': 'NotoSerifCJKtc',
        '29e8a': 'NotoSerifCJKtc',
        '29ec4': 'NotoSerifCJKtc',
        '29edb': 'NotoSerifCJKtc',
        '29ee9': 'NotoSerifCJKtc',
        '29f7e': 'NotoSerifCJKtc',
        '29f83': 'NotoSerifCJKtc',
        '29f8c': 'NotoSerifCJKtc',
        '29fce': 'NotoSerifCJKtc',
        '29fd7': 'NotoSerifCJKtc',
        '2a01a': 'NotoSerifCJKtc',
        '2a02f': 'NotoSerifCJKtc',
        '2a082': 'NotoSerifCJKtc',
        '2a0f9': 'NotoSerifCJKtc',
        '2a190': 'NotoSerifCJKtc',
        '2a2b2': 'NotoSerifCJKtc',
        '2a38c': 'NotoSerifCJKtc',
        '2a437': 'NotoSerifCJKtc',
        '2a5f1': 'NotoSerifCJKtc',
        '2a602': 'NotoSerifCJKtc',
        '2a61a': 'NotoSerifCJKtc',
        '2a6b2': 'NotoSerifCJKtc',
        '2a7dd': 'NotoSerifCJKtc',
        '2a8fb': 'NotoSerifCJKtc',
        '2a917': 'NotoSerifCJKtc',
        '2a9e6': 'NotoSerifCJKtc',
        '2aa30': 'NotoSerifCJKtc',
        '2aa36': 'NotoSerifCJKtc',
        '2aa58': 'NotoSerifCJKtc',
        '2afa2': 'NotoSerifCJKtc',
        '2b127-2b128': 'NotoSerifCJKtc',
        '2b137-2b138': 'NotoSerifCJKtc',
        '2b1ed': 'NotoSerifCJKtc',
        '2b300': 'NotoSerifCJKtc',
        '2b363': 'NotoSerifCJKtc',
        '2b36f': 'NotoSerifCJKtc',
        '2b372': 'NotoSerifCJKtc',
        '2b37d': 'NotoSerifCJKtc',
        '2b404': 'NotoSerifCJKtc',
        '2b410': 'NotoSerifCJKtc',
        '2b413': 'NotoSerifCJKtc',
        '2b461': 'NotoSerifCJKtc',
        '2b4e7': 'NotoSerifCJKtc',
        '2b4ef': 'NotoSerifCJKtc',
        '2b4f6': 'NotoSerifCJKtc',
        '2b4f9': 'NotoSerifCJKtc',
        '2b50d-2b50e': 'NotoSerifCJKtc',
        '2b536': 'NotoSerifCJKtc',
        '2b5ae-2b5af': 'NotoSerifCJKtc',
        '2b5b3': 'NotoSerifCJKtc',
        '2b5e7': 'NotoSerifCJKtc',
        '2b5f4': 'NotoSerifCJKtc',
        '2b61c-2b61d': 'NotoSerifCJKtc',
        '2b626-2b628': 'NotoSerifCJKtc',
        '2b62a': 'NotoSerifCJKtc',
        '2b62c': 'NotoSerifCJKtc',
        '2b695-2b696': 'NotoSerifCJKtc',
        '2b6ad': 'NotoSerifCJKtc',
        '2b6ed': 'NotoSerifCJKtc',
        '2b746': 'NotoSerifCJKtc',
        '2b751': 'NotoSerifCJKtc',
        '2b753': 'NotoSerifCJKtc',
        '2b75a': 'NotoSerifCJKtc',
        '2b75c': 'NotoSerifCJKtc',
        '2b765': 'NotoSerifCJKtc',
        '2b776-2b777': 'NotoSerifCJKtc',
        '2b77c': 'NotoSerifCJKtc',
        '2b782': 'NotoSerifCJKtc',
        '2b789': 'NotoSerifCJKtc',
        '2b78b': 'NotoSerifCJKtc',
        '2b78e': 'NotoSerifCJKtc',
        '2b794': 'NotoSerifCJKtc',
        '2b7a9': 'NotoSerifCJKtc',
        '2b7ac': 'NotoSerifCJKtc',
        '2b7af': 'NotoSerifCJKtc',
        '2b7bd': 'NotoSerifCJKtc',
        '2b7c5': 'NotoSerifCJKtc',
        '2b7c9': 'NotoSerifCJKtc',
        '2b7cf': 'NotoSerifCJKtc',
        '2b7d2': 'NotoSerifCJKtc',
        '2b7d8': 'NotoSerifCJKtc',
        '2b7e6': 'NotoSerifCJKtc',
        '2b7f0': 'NotoSerifCJKtc',
        '2b7f9': 'NotoSerifCJKtc',
        '2b7fc': 'NotoSerifCJKtc',
        '2b806': 'NotoSerifCJKtc',
        '2b80a': 'NotoSerifCJKtc',
        '2b80d': 'NotoSerifCJKtc',
        '2b817': 'NotoSerifCJKtc',
        '2b81a': 'NotoSerifCJKtc',
        '2b81c': 'NotoSerifCJKtc',
        '2b8b8': 'NotoSerifCJKtc',
        '2bac7': 'NotoSerifCJKtc',
        '2bb5f': 'NotoSerifCJKtc',
        '2bb62': 'NotoSerifCJKtc',
        '2bb7c': 'NotoSerifCJKtc',
        '2bb83': 'NotoSerifCJKtc',
        '2bc1b': 'NotoSerifCJKtc',
        '2bd77': 'NotoSerifCJKtc',
        '2bd87': 'NotoSerifCJKtc',
        '2bdf7': 'NotoSerifCJKtc',
        '2be29': 'NotoSerifCJKtc',
        '2c029-2c02a': 'NotoSerifCJKtc',
        '2c0a9': 'NotoSerifCJKtc',
        '2c0ca': 'NotoSerifCJKtc',
        '2c1d5': 'NotoSerifCJKtc',
        '2c1d9': 'NotoSerifCJKtc',
        '2c1f9': 'NotoSerifCJKtc',
        '2c27c': 'NotoSerifCJKtc',
        '2c288': 'NotoSerifCJKtc',
        '2c2a4': 'NotoSerifCJKtc',
        '2c317': 'NotoSerifCJKtc',
        '2c35b': 'NotoSerifCJKtc',
        '2c361': 'NotoSerifCJKtc',
        '2c364': 'NotoSerifCJKtc',
        '2c488': 'NotoSerifCJKtc',
        '2c494': 'NotoSerifCJKtc',
        '2c497': 'NotoSerifCJKtc',
        '2c542': 'NotoSerifCJKtc',
        '2c613': 'NotoSerifCJKtc',
        '2c618': 'NotoSerifCJKtc',
        '2c621': 'NotoSerifCJKtc',
        '2c629': 'NotoSerifCJKtc',
        '2c62b-2c62d': 'NotoSerifCJKtc',
        '2c62f': 'NotoSerifCJKtc',
        '2c642': 'NotoSerifCJKtc',
        '2c64a-2c64b': 'NotoSerifCJKtc',
        '2c72c': 'NotoSerifCJKtc',
        '2c72f': 'NotoSerifCJKtc',
        '2c79f': 'NotoSerifCJKtc',
        '2c7c1': 'NotoSerifCJKtc',
        '2c7fd': 'NotoSerifCJKtc',
        '2c8d9': 'NotoSerifCJKtc',
        '2c8de': 'NotoSerifCJKtc',
        '2c8e1': 'NotoSerifCJKtc',
        '2c8f3': 'NotoSerifCJKtc',
        '2c907': 'NotoSerifCJKtc',
        '2c90a': 'NotoSerifCJKtc',
        '2c91d': 'NotoSerifCJKtc',
        '2ca02': 'NotoSerifCJKtc',
        '2ca0e': 'NotoSerifCJKtc',
        '2ca7d': 'NotoSerifCJKtc',
        '2caa9': 'NotoSerifCJKtc',
        '2cb29': 'NotoSerifCJKtc',
        '2cb2d-2cb2e': 'NotoSerifCJKtc',
        '2cb31': 'NotoSerifCJKtc',
        '2cb38-2cb39': 'NotoSerifCJKtc',
        '2cb3b': 'NotoSerifCJKtc',
        '2cb3f': 'NotoSerifCJKtc',
        '2cb41': 'NotoSerifCJKtc',
        '2cb4a': 'NotoSerifCJKtc',
        '2cb4e': 'NotoSerifCJKtc',
        '2cb5a-2cb5b': 'NotoSerifCJKtc',
        '2cb64': 'NotoSerifCJKtc',
        '2cb69': 'NotoSerifCJKtc',
        '2cb6c': 'NotoSerifCJKtc',
        '2cb6f': 'NotoSerifCJKtc',
        '2cb73': 'NotoSerifCJKtc',
        '2cb76': 'NotoSerifCJKtc',
        '2cb78': 'NotoSerifCJKtc',
        '2cb7c': 'NotoSerifCJKtc',
        '2cbb1': 'NotoSerifCJKtc',
        '2cbbf-2cbc0': 'NotoSerifCJKtc',
        '2cbce': 'NotoSerifCJKtc',
        '2cc56': 'NotoSerifCJKtc',
        '2cc5f': 'NotoSerifCJKtc',
        '2ccf5-2ccf6': 'NotoSerifCJKtc',
        '2ccfd': 'NotoSerifCJKtc',
        '2ccff': 'NotoSerifCJKtc',
        '2cd02-2cd03': 'NotoSerifCJKtc',
        '2cd0a': 'NotoSerifCJKtc',
        '2cd8b': 'NotoSerifCJKtc',
        '2cd8d': 'NotoSerifCJKtc',
        '2cd8f-2cd90': 'NotoSerifCJKtc',
        '2cd9f-2cda0': 'NotoSerifCJKtc',
        '2cda8': 'NotoSerifCJKtc',
        '2cdad-2cdae': 'NotoSerifCJKtc',
        '2cdd5': 'NotoSerifCJKtc',
        '2ce18': 'NotoSerifCJKtc',
        '2ce1a': 'NotoSerifCJKtc',
        '2ce23': 'NotoSerifCJKtc',
        '2ce26': 'NotoSerifCJKtc',
        '2ce2a': 'NotoSerifCJKtc',
        '2ce7c': 'NotoSerifCJKtc',
        '2ce88': 'NotoSerifCJKtc',
        '2ce93': 'NotoSerifCJKtc',
        '2d544': 'NotoSerifCJKtc',
        '2e278': 'NotoSerifCJKtc',
        '2e6ea': 'NotoSerifCJKtc',
        '2f804': 'NotoSerifCJKtc',
        '2f80f': 'NotoSerifCJKtc',
        '2f815': 'NotoSerifCJKtc',
        '2f818': 'NotoSerifCJKtc',
        '2f81a': 'NotoSerifCJKtc',
        '2f822': 'NotoSerifCJKtc',
        '2f828': 'NotoSerifCJKtc',
        '2f82c': 'NotoSerifCJKtc',
        '2f833': 'NotoSerifCJKtc',
        '2f83f': 'NotoSerifCJKtc',
        '2f846': 'NotoSerifCJKtc',
        '2f852': 'NotoSerifCJKtc',
        '2f862': 'NotoSerifCJKtc',
        '2f86d': 'NotoSerifCJKtc',
        '2f873': 'NotoSerifCJKtc',
        '2f877': 'NotoSerifCJKtc',
        '2f884': 'NotoSerifCJKtc',
        '2f899-2f89a': 'NotoSerifCJKtc',
        '2f8a6': 'NotoSerifCJKtc',
        '2f8ac': 'NotoSerifCJKtc',
        '2f8b2': 'NotoSerifCJKtc',
        '2f8b6': 'NotoSerifCJKtc',
        '2f8d3': 'NotoSerifCJKtc',
        '2f8db-2f8dc': 'NotoSerifCJKtc',
        '2f8e1': 'NotoSerifCJKtc',
        '2f8e5': 'NotoSerifCJKtc',
        '2f8ea': 'NotoSerifCJKtc',
        '2f8ed': 'NotoSerifCJKtc',
        '2f8fc': 'NotoSerifCJKtc',
        '2f903': 'NotoSerifCJKtc',
        '2f90b': 'NotoSerifCJKtc',
        '2f90f': 'NotoSerifCJKtc',
        '2f91a': 'NotoSerifCJKtc',
        '2f920-2f921': 'NotoSerifCJKtc',
        '2f945': 'NotoSerifCJKtc',
        '2f947': 'NotoSerifCJKtc',
        '2f96c': 'NotoSerifCJKtc',
        '2f995': 'NotoSerifCJKtc',
        '2f9d0': 'NotoSerifCJKtc',
        '2f9de-2f9df': 'NotoSerifCJKtc',
        '2f9f4': 'NotoSerifCJKtc',
        '002bc': 'NotoSerifThai',
        '00900-00950': 'NotoSerifDevanagari',
        '00951-00952': 'NotoSerifTamil',
        '00953-00963': 'NotoSerifDevanagari',
        '00966-0097f': 'NotoSerifDevanagari',
        '01cd0-01cd9': 'NotoSerifDevanagari',
        '01cda': 'NotoSerifTamil',
        '01cdb-01cf6': 'NotoSerifDevanagari',
        '0a830-0a839': 'NotoSerifGujarati',
        '0a8e0-0a8f2': 'NotoSerifDevanagari',
        '0a8f3': 'NotoSerifTamil',
        '0a8f4-0a8fb': 'NotoSerifDevanagari',
        '00308': 'NotoSerifGeorgian',
        '0030e': 'NotoSerifEthiopic',
        '01200-01248': 'NotoSerifEthiopic',
        '0124a-0124d': 'NotoSerifEthiopic',
        '01250-01256': 'NotoSerifEthiopic',
        '01258': 'NotoSerifEthiopic',
        '0125a-0125d': 'NotoSerifEthiopic',
        '01260-01288': 'NotoSerifEthiopic',
        '0128a-0128d': 'NotoSerifEthiopic',
        '01290-012b0': 'NotoSerifEthiopic',
        '012b2-012b5': 'NotoSerifEthiopic',
        '012b8-012be': 'NotoSerifEthiopic',
        '012c0': 'NotoSerifEthiopic',
        '012c2-012c5': 'NotoSerifEthiopic',
        '012c8-012d6': 'NotoSerifEthiopic',
        '012d8-01310': 'NotoSerifEthiopic',
        '01312-01315': 'NotoSerifEthiopic',
        '01318-0135a': 'NotoSerifEthiopic',
        '0135d-0137c': 'NotoSerifEthiopic',
        '01380-01399': 'NotoSerifEthiopic',
        '022ee': 'NotoSerifEthiopic',
        '02d80-02d96': 'NotoSerifEthiopic',
        '02da0-02da6': 'NotoSerifEthiopic',
        '02da8-02dae': 'NotoSerifEthiopic',
        '02db0-02db6': 'NotoSerifEthiopic',
        '02db8-02dbe': 'NotoSerifEthiopic',
        '02dc0-02dc6': 'NotoSerifEthiopic',
        '02dc8-02dce': 'NotoSerifEthiopic',
        '02dd0-02dd6': 'NotoSerifEthiopic',
        '02dd8-02dde': 'NotoSerifEthiopic',
        '0ab01-0ab06': 'NotoSerifEthiopic',
        '0ab09-0ab0e': 'NotoSerifEthiopic',
        '0ab11-0ab16': 'NotoSerifEthiopic',
        '0ab20-0ab26': 'NotoSerifEthiopic',
        '0ab28-0ab2e': 'NotoSerifEthiopic',
        '00301-00302': 'NotoSerifGeorgian',
        '00304': 'NotoSerifGeorgian',
        '010a0-010c5': 'NotoSerifGeorgian',
        '010c7': 'NotoSerifGeorgian',
        '010cd': 'NotoSerifGeorgian',
        '010d0-010ff': 'NotoSerifGeorgian',
        '020be': 'NotoSerifGeorgian',
        '02d00-02d25': 'NotoSerifGeorgian',
        '02d27': 'NotoSerifGeorgian',
        '02d2d': 'NotoSerifGeorgian',
        '00a81-00a83': 'NotoSerifGujarati',
        '00a85-00a8d': 'NotoSerifGujarati',
        '00a8f-00a91': 'NotoSerifGujarati',
        '00a93-00aa8': 'NotoSerifGujarati',
        '00aaa-00ab0': 'NotoSerifGujarati',
        '00ab2-00ab3': 'NotoSerifGujarati',
        '00ab5-00ab9': 'NotoSerifGujarati',
        '00abc-00ac5': 'NotoSerifGujarati',
        '00ac7-00ac9': 'NotoSerifGujarati',
        '00acb-00acd': 'NotoSerifGujarati',
        '00ad0': 'NotoSerifGujarati',
        '00ae0-00ae3': 'NotoSerifGujarati',
        '00ae6-00af1': 'NotoSerifGujarati',
        '00591-005c7': 'NotoSerifHebrew',
        '005d0-005ea': 'NotoSerifHebrew',
        '005f0-005f4': 'NotoSerifHebrew',
        '0200e-0200f': 'NotoSerifHebrew',
        '020aa': 'NotoSerifHebrew',
        '0fb1d-0fb36': 'NotoSerifHebrew',
        '0fb38-0fb3c': 'NotoSerifHebrew',
        '0fb3e': 'NotoSerifHebrew',
        '0fb40-0fb41': 'NotoSerifHebrew',
        '0fb43-0fb44': 'NotoSerifHebrew',
        '0fb46-0fb4f': 'NotoSerifHebrew',
        '00c81-00c83': 'NotoSerifKannada',
        '00c85-00c8c': 'NotoSerifKannada',
        '00c8e-00c90': 'NotoSerifKannada',
        '00c92-00ca8': 'NotoSerifKannada',
        '00caa-00cb3': 'NotoSerifKannada',
        '00cb5-00cb9': 'NotoSerifKannada',
        '00cbc-00cc4': 'NotoSerifKannada',
        '00cc6-00cc8': 'NotoSerifKannada',
        '00cca-00ccd': 'NotoSerifKannada',
        '00cd5-00cd6': 'NotoSerifKannada',
        '00cde': 'NotoSerifKannada',
        '00ce0-00ce3': 'NotoSerifKannada',
        '00ce6-00cef': 'NotoSerifKannada',
        '00cf1-00cf2': 'NotoSerifKannada',
        '01780-017dd': 'NotoSerifKhmer',
        '017e0-017e9': 'NotoSerifKhmer',
        '017f0-017f9': 'NotoSerifKhmer',
        '019e0-019ff': 'NotoSerifKhmer',
        '00e81-00e82': 'NotoSerifLao',
        '00e84': 'NotoSerifLao',
        '00e87-00e88': 'NotoSerifLao',
        '00e8a': 'NotoSerifLao',
        '00e8d': 'NotoSerifLao',
        '00e94-00e97': 'NotoSerifLao',
        '00e99-00e9f': 'NotoSerifLao',
        '00ea1-00ea3': 'NotoSerifLao',
        '00ea5': 'NotoSerifLao',
        '00ea7': 'NotoSerifLao',
        '00eaa-00eab': 'NotoSerifLao',
        '00ead-00eb9': 'NotoSerifLao',
        '00ebb-00ebd': 'NotoSerifLao',
        '00ec0-00ec4': 'NotoSerifLao',
        '00ec6': 'NotoSerifLao',
        '00ec8-00ecd': 'NotoSerifLao',
        '00ed0-00ed9': 'NotoSerifLao',
        '00edc-00edf': 'NotoSerifLao',
        '020ad': 'NotoSerifLao',
        '00307': 'NotoSerifMalayalam',
        '00323': 'NotoSerifMalayalam',
        '00d01-00d03': 'NotoSerifMalayalam',
        '00d05-00d0c': 'NotoSerifMalayalam',
        '00d0e-00d10': 'NotoSerifMalayalam',
        '00d12-00d3a': 'NotoSerifMalayalam',
        '00d3d-00d44': 'NotoSerifMalayalam',
        '00d46-00d48': 'NotoSerifMalayalam',
        '00d4a-00d4e': 'NotoSerifMalayalam',
        '00d57': 'NotoSerifMalayalam',
        '00d5f-00d63': 'NotoSerifMalayalam',
        '00d66-00d75': 'NotoSerifMalayalam',
        '00d79-00d7f': 'NotoSerifMalayalam',
        '01000-0109f': 'NotoSerifMyanmar',
        '0a92e': 'NotoSerifMyanmar',
        '0a9e0-0a9fe': 'NotoSerifMyanmar',
        '0aa60-0aa7f': 'NotoSerifMyanmar',
        '0fe00': 'NotoSerifMyanmar',
        '00d82-00d83': 'NotoSerifSinhala',
        '00d85-00d96': 'NotoSerifSinhala',
        '00d9a-00db1': 'NotoSerifSinhala',
        '00db3-00dbb': 'NotoSerifSinhala',
        '00dbd': 'NotoSerifSinhala',
        '00dc0-00dc6': 'NotoSerifSinhala',
        '00dca': 'NotoSerifSinhala',
        '00dcf-00dd4': 'NotoSerifSinhala',
        '00dd6': 'NotoSerifSinhala',
        '00dd8-00ddf': 'NotoSerifSinhala',
        '00de6-00def': 'NotoSerifSinhala',
        '00df2-00df4': 'NotoSerifSinhala',
        '111e1-111f4': 'NotoSerifSinhala',
        '00b82-00b83': 'NotoSerifTamil',
        '00b85-00b8a': 'NotoSerifTamil',
        '00b8e-00b90': 'NotoSerifTamil',
        '00b92-00b95': 'NotoSerifTamil',
        '00b99-00b9a': 'NotoSerifTamil',
        '00b9c': 'NotoSerifTamil',
        '00b9e-00b9f': 'NotoSerifTamil',
        '00ba3-00ba4': 'NotoSerifTamil',
        '00ba8-00baa': 'NotoSerifTamil',
        '00bae-00bb9': 'NotoSerifTamil',
        '00bbe-00bc2': 'NotoSerifTamil',
        '00bc6-00bc8': 'NotoSerifTamil',
        '00bca-00bcd': 'NotoSerifTamil',
        '00bd0': 'NotoSerifTamil',
        '00bd7': 'NotoSerifTamil',
        '00be6-00bfa': 'NotoSerifTamil',
        '02074': 'NotoSerifTamil',
        '02082-02084': 'NotoSerifTamil',
        '1133c': 'NotoSerifTamil',
        '00c01-00c03': 'NotoSerifTelugu',
        '00c05-00c0c': 'NotoSerifTelugu',
        '00c0e-00c10': 'NotoSerifTelugu',
        '00c12-00c28': 'NotoSerifTelugu',
        '00c2a-00c33': 'NotoSerifTelugu',
        '00c35-00c39': 'NotoSerifTelugu',
        '00c3d-00c44': 'NotoSerifTelugu',
        '00c46-00c48': 'NotoSerifTelugu',
        '00c4a-00c4d': 'NotoSerifTelugu',
        '00c55-00c56': 'NotoSerifTelugu',
        '00c58-00c59': 'NotoSerifTelugu',
        '00c60-00c63': 'NotoSerifTelugu',
        '00c66-00c6f': 'NotoSerifTelugu',
        '00c78-00c7f': 'NotoSerifTelugu',
        '002d7': 'NotoSerifThai',
        '00303': 'NotoSerifThai',
        '00331': 'NotoSerifThai',
        '00e01-00e3a': 'NotoSerifThai',
        '00e3f-00e5b': 'NotoSerifThai'
    }
}

const GlyphStyles = new Set(['Regular'])

export { GlyphMaps, GlyphStyles }
