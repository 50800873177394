import React, { useCallback, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'

import { LoadingStatus } from '@phase-software/types'

import { DEFAULT_DIALOG_TRANSITION_TIME } from '../../../constant'
import Button from '../Button'
import FocusLoop from '../FocusLoop'
import Icon, { IconSizeType } from '../Icon'
import IconButton from '../IconButton'
import ProgressButton from '../ProgressButton'
import DialogProgressIndicator from './DialogProgressIndicator'

const DIALOG_SIZE_CLASSES = {
  xs: 'w-[320px]',
  s: 'w-[400px]',
  m: 'w-[600px]',
  l: 'w-[800px]'
}

const containerElement = document.createElement('div')

export type DialogProps = {
  'data-test-id'?: string
  bodyClassName?: string
  cancelBtnText?: string
  children?: React.ReactNode
  closable?: boolean
  confirmBtnText?: string
  dangerConfirmBtnText?: string
  disableCancel?: boolean
  disableConfirm?: boolean
  disableDangerConfirm?: boolean
  footer?: React.ReactNode
  iconName?: string
  iconSize?: IconSizeType
  onCancel?: () => void
  onCancelProgress?: () => void
  onConfirm?: () => void
  onDangerConfirm?: () => void
  progressPercentage?: number
  progressStatus?: LoadingStatus
  rootClassName?: string
  showProgressButton?: boolean
  showProgressIndicator?: boolean
  size?: 'xs' | 's' | 'm' | 'l'
  title?: string
  divider?: boolean
  open: boolean
  className?: string
  footerBtnClassName?: string
}

const Dialog = ({
  bodyClassName = '',
  cancelBtnText,
  children,
  closable = true,
  confirmBtnText,
  dangerConfirmBtnText,
  disableConfirm = false,
  disableDangerConfirm = false,
  footer,
  iconName,
  iconSize = 24,
  onCancel,
  onConfirm,
  onDangerConfirm,
  onCancelProgress,
  showProgressButton = false,
  showProgressIndicator,
  progressPercentage = 0,
  progressStatus,
  rootClassName = '',
  size = 's',
  title,
  divider = false,
  open,
  className = '',
  footerBtnClassName = '',
  ...rest
}: DialogProps) => {
  const ref = useRef<HTMLFormElement>(null)

  const [isRendered, setIsRendered] = useState(false)

  useEffect(() => {
    if (open) {
      setIsRendered(true)
    } else if (!open && isRendered) {
      setTimeout(() => setIsRendered(false), DEFAULT_DIALOG_TRANSITION_TIME)
    }
  }, [open, isRendered])

  useEffect(() => {
    const rootElement = document.querySelector('#modal') || document.body
    rootElement.appendChild(containerElement)
    const bodyOverflow = document.body.style.overflow
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = bodyOverflow
    }
  }, [])

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (!closable) return
      if (e.key === 'Escape') {
        e.preventDefault()
        e.stopPropagation()
        onCancel?.()
      }
    }
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [closable, onCancel])

  const handleOverlayClick = useCallback(
    (e: React.MouseEvent) => {
      if (!closable) return
      if ((e.target as HTMLFormElement).contains(ref?.current)) {
        onCancel?.()
      }
    },
    [closable, onCancel]
  )

  const renderButton = () => {
    return (
      <div className={`mt-4 gap-12 flex justify-end p-16 ${footerBtnClassName}`}>
        {dangerConfirmBtnText && (
          <ConfirmButton
            data-test-id="confirm-btn"
            fluid
            status={progressStatus}
            size="l"
            color="danger"
            type="submit"
            autoFocus
            onClick={onDangerConfirm}
            disabled={disableDangerConfirm}
          >
            {dangerConfirmBtnText}
          </ConfirmButton>
        )}
        {cancelBtnText && (
          <Button
            data-test-id="cancel-btn"
            fluid
            size="l"
            color="secondary"
            onClick={onCancel}
            disabled={showProgressButton && progressStatus === LoadingStatus.WAITING}
          >
            {cancelBtnText}
          </Button>
        )}
        {confirmBtnText && (
          // @ts-ignore TODO: fix after refactor of ProgressButton and Button
          <ConfirmButton
            data-test-id="confirm-btn"
            size="l"
            fluid
            status={progressStatus}
            color="primary"
            autoFocus
            onClick={onConfirm}
            disabled={disableConfirm}
          >
            {confirmBtnText}
          </ConfirmButton>
        )}
      </div>
    )
  }
  const renderFooter = () => {
    const hasFooter = !!footer
    const hasButtons = dangerConfirmBtnText || cancelBtnText || confirmBtnText
    if (hasFooter && hasButtons) {
      return (
        <div className={`flex justify-between`}>
          <div>{footer}</div>
          {renderButton()}
        </div>
      )
    } else if (hasButtons) {
      return renderButton()
    } else if (hasFooter) {
      return <div className="h-48">{footer}</div>
    }
    return <div className="mt-4 gap-12 flex justify-end p-16" />
  }

  const ConfirmButton = showProgressButton ? ProgressButton : Button
  const shouldShowProgressIndicator =
    showProgressIndicator && (progressStatus === LoadingStatus.WAITING || progressStatus === LoadingStatus.FINISHED)

  if (!open && !isRendered) return null

  return ReactDOM.createPortal(
    <FocusLoop
      ref={ref}
      className={`fixed inset-0 flex items-center justify-center bg-dark-overlay-80 z-10 ${rootClassName} transition-opacity duration-200 ${
        open && isRendered ? 'opacity-100' : 'opacity-0'
      }`}
      onClick={shouldShowProgressIndicator ? undefined : handleOverlayClick}
      dataTestId={rest['data-test-id']}
      aria-modal="true"
      aria-hidden={open ? 'false' : 'true'}
      undoable
      {...rest}
    >
      <div
        className={`relative overflow-hidden m-auto bg-neutral-80 rounded-lg shadow-5 transition duration-200 transform ${
          DIALOG_SIZE_CLASSES[size]
        } ${open && isRendered ? 'opacity-100 scale-100' : 'opacity-0 scale-90'} ${className}`}
      >
        {shouldShowProgressIndicator && (
          <DialogProgressIndicator
            percentage={progressPercentage}
            isComplete={progressStatus === LoadingStatus.FINISHED}
            onCancel={onCancelProgress}
          />
        )}
        <div className="px-16 py-12 flex justify-between items-center">
          <span className="text-white text-14 font-medium">{title}</span>
          {closable && <IconButton icon="Cross" onClick={onCancel} aria-label="Close" />}
        </div>
        <div className={`flex px-16 ${bodyClassName}`}>
          {iconName && <Icon name={iconName} size={iconSize} interactive={false} className="mr-8" />}
          <div className="text-light-overlay-60 flex flex-col w-full font-medium">{children}</div>
        </div>
        {divider && <div className="border-t border-solid border-neutral-60" />}
        {renderFooter()}
      </div>
    </FocusLoop>,
    containerElement
  )
}

export default React.memo(Dialog)
