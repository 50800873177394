import React, { useCallback } from 'react'
import { Mode } from '@phase-software/types'
import { IconButton } from '../shared'
import { useUI } from '../../providers/dataStore/UIProvider'
import { useEditorActions } from '../../providers/dataStore/EditorProvider'

interface RemoveLayerProps {
  layerItemId?: string
  isNonBase?: boolean
}

const RemoveLayer = ({ layerItemId, isNonBase }: RemoveLayerProps) => {
  const { mode } = useUI()
  const { removeLayer } = useEditorActions()
  const isDesignMode = mode === Mode.DESIGN
  const cantRemove = !isNonBase && !isDesignMode

  const handleRemove = useCallback(() => {
    if (cantRemove) {
      return
    }
    removeLayer(layerItemId)
  }, [layerItemId, removeLayer, cantRemove])

  if (cantRemove) {
    return null
  }

  return (
    <IconButton
      size="l"
      data-test-id="layer-remove-button"
      icon="Remove"
      tip={cantRemove ? 'This layer can only be deleted in design mode' : ''}
      disabled={cantRemove}
      onClick={handleRemove}
    />
  )
}

export default React.memo(RemoveLayer)
