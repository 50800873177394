// import { Gfx_PixelFormat, Gfx_Filter, Gfx_Wrap, Gfx_Usage } from "../gfx"
import { Resource } from "../Resource"

// const GRADIENT_WIDTH = 1024

/** @typedef {import('../gfx').Gfx} Gfx */
/** @typedef {import('../gfx').Gfx_Image_t} Gfx_Image_t */
/** @typedef {[number, number, number, number]} RGBA */

/**
 * @typedef {object} GradientColorStop
 * @property {number} pos
 * @property {number} order
 * @property {RGBA} color
 */

export class GradientResource extends Resource {
    /**
     * @param {Gfx} gfx
     */
    constructor(gfx) {
        super()

        this.gfx = gfx

        /** @type {GradientColorStop[]} */
        this.colorStops = []

        /** @type {Gfx_Image_t} */
        this._image = null
    }

    /**
     * @param {Array<{ position: number, color: RGBA }>} stops
     */
    setColorStops(stops) {
        this.colorStops.length = 0

        const colorStops = []
        for (const { position, color } of stops) {
            /** @type {RGBA} */
            const origin_8bit = [
                color[0],
                color[1],
                color[2],
                color[3],
            ]

            colorStops.push({
                pos: position,
                color: origin_8bit,
            })
        }
        this.colorStops = colorStops.sort((a, b) => a.pos - b.pos)

        this._dirty = true
    }

    /** @returns {Gfx_Image_t|null} */
    getData() {
        return this._image
    }

    /** @protected */
    update() {
        // this.colorStops.sort((a, b) => (a.pos - b.pos))

        // const stops = this.colorStops.slice()
        // stops.sort((a, b) => ((a.pos === b.pos) ? (a.order - b.order) : (a.pos - b.pos)))

        // const pixels = this.getPixels(GRADIENT_WIDTH, stops)
        // if (this._image) {
        //     this.gfx.updateImage(this._image, [[{ raw: true, data: pixels }]])
        // } else {
        //     this._image = this.gfx.makeImage({
        //         width: GRADIENT_WIDTH,
        //         height: 1,
        //         data: [[{ raw: true, data: pixels }]],
        //         usage: Gfx_Usage.STREAM,
        //         pixelFormat: Gfx_PixelFormat.RGBA8,
        //         minFilter: Gfx_Filter.LINEAR,
        //         magFilter: Gfx_Filter.LINEAR,
        //         wrapU: Gfx_Wrap.CLAMP_TO_EDGE,
        //         wrapV: Gfx_Wrap.CLAMP_TO_EDGE,
        //     })
        // }
    }

    /**
     * @private
     * @param {number} width
     * @param {GradientColorStop[]} stops
     * @returns {Uint8Array}
     */
    getPixels(width, stops) {
        const byteSize = width * 4
        const pixels = new Uint8Array(byteSize)

        if (!stops || stops.length === 0) {
            return pixels
        }

        const currentColor = [0, 0, 0, 0]
        const colorInc = [0, 0, 0, 0]
        let index = 0
        let current = stops[0], next = stops[0]
        for (let i = 0; i < stops.length; i++) {
            next = stops[i]

            const stopIndex = Math.min(next.pos * byteSize, byteSize)
            const length = (stopIndex - index) / 4

            currentColor[0] = current.color[0]
            currentColor[1] = current.color[1]
            currentColor[2] = current.color[2]
            currentColor[3] = current.color[3]

            colorInc[0] = (next.color[0] - current.color[0]) / length
            colorInc[1] = (next.color[1] - current.color[1]) / length
            colorInc[2] = (next.color[2] - current.color[2]) / length
            colorInc[3] = (next.color[3] - current.color[3]) / length

            for (; index < stopIndex; index += 4) {
                pixels[index] = currentColor[0]
                pixels[index + 1] = currentColor[1]
                pixels[index + 2] = currentColor[2]
                pixels[index + 3] = currentColor[3]

                currentColor[0] += colorInc[0]
                currentColor[1] += colorInc[1]
                currentColor[2] += colorInc[2]
                currentColor[3] += colorInc[3]
            }

            if (index === byteSize) {
                pixels[byteSize - 4] = next.color[0]
                pixels[byteSize - 3] = next.color[1]
                pixels[byteSize - 2] = next.color[2]
                pixels[byteSize - 1] = next.color[3]
                break
            }

            current = next
        }

        for (; index < byteSize; index += 4) {
            pixels[index] = next.color[0]
            pixels[index + 1] = next.color[1]
            pixels[index + 2] = next.color[2]
            pixels[index + 3] = next.color[3]
        }

        return pixels
    }
}
