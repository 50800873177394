import React, { useEffect, useMemo, useRef } from 'react'
import FoldToggle from './FoldToggle'
import { levelToIndent } from './utils'
import { scrollIntoView } from '../../../utils/dom'

export const ListItemHeight = 32

type Align = 'center' | 'start' | 'end' | 'nearest'

type ListItemProps = {
  type?: 'item' | 'group'
  expandable?: boolean
  expanded?: boolean
  selected?: boolean
  selectedColor?: 'primary' | 'secondary'
  highlighted?: boolean
  parentSelected?: boolean
  prevSelected?: boolean
  nextSelected?: boolean
  renderItem: React.FC<{
    data: Record<string, any>
    selected: boolean
    highlighted: boolean
    hasAnimation: boolean
  }>
  level?: number
  data: Record<string, any>
  onHover?: (itemId: string) => void
  onExpand?: (itemId: string, flag: boolean, toggleDescendant: boolean) => void
  onSelect?: (e: React.MouseEvent | MouseEvent, itemId: string) => void
  onContextMenu?: (e: React.MouseEvent | MouseEvent, itemId: string) => void
  calcNeedDisableReselect?: (isRightClick: boolean) => boolean
  isMask: boolean
  hasAnimation: boolean
  maskLength: number
  align: Align
  viewAnchor: 'top' | 'bottom'
  disableScrollIntoView?: boolean
}

const getRounded = ({ selected, prevSelected, nextSelected, parentSelected }: Record<string, boolean>) => {
  if (selected || parentSelected) {
    if (prevSelected && nextSelected) {
      return ''
    } else if (prevSelected) {
      return 'rounded-b-md'
    } else if (nextSelected) {
      return 'rounded-t-md'
    }
  }
  return 'rounded-md'
}

const getBackground = ({
  selected,
  parentSelected,
  highlighted,
  selectedColor
}: {
  selected: boolean
  parentSelected: boolean
  highlighted: boolean
  selectedColor: 'primary' | 'secondary'
}) => {
  if (selected) {
    return selectedColor === 'secondary' ? 'bg-neutral-70' : 'bg-primary-50'
  } else if (parentSelected) {
    return 'bg-primary-inherited rounded-none'
  } else if (highlighted) {
    return 'bg-neutral-80'
  }
  return ''
}

const getFoldBackground = ({
  selected,
  type,
  parentSelected,
  ancestorIsMask,
  highlighted
}: {
  selected: boolean
  type: 'item' | 'group'
  parentSelected: boolean
  ancestorIsMask: boolean
  highlighted: boolean
}) => {
  if (parentSelected && ancestorIsMask) {
    return 'bg-primary-inherited'
  }

  if (!ancestorIsMask) {
    return ''
  }

  if (selected && type === 'group') {
    return 'bg-primary-50'
  }

  if (highlighted) {
    return ''
  }

  return 'bg-neutral-90'
}

const ItemBackground = ({ bg, rounded }: { bg: string; rounded: string }) => {
  return <div className={`${bg} absolute top-0 left-8 right-8 bottom-0 h-full ${rounded}`} />
}

const ListItem = ({
  type = 'item',
  data,
  level = 0,
  selectedColor = 'primary',
  expandable = false,
  expanded = false,
  selected = false,
  highlighted = false,
  prevSelected = false,
  nextSelected = false,
  parentSelected = false,
  renderItem,
  onHover = () => {},
  onExpand = () => {},
  onSelect = () => {},
  onContextMenu = () => {},
  calcNeedDisableReselect = () => false,
  isMask,
  hasAnimation,
  maskLength,
  align = 'center',
  viewAnchor = 'top',
  disableScrollIntoView = false
}: ListItemProps) => {
  const ref = useRef(null)

  useEffect(() => {
    if (selected && ref.current && !disableScrollIntoView) {
      scrollIntoView(ref.current, align, null, viewAnchor)
    }
  }, [selected, ref, align, viewAnchor, disableScrollIntoView])

  const backgroundStyles = useMemo(() => {
    return {
      background: getBackground({
        selected,
        parentSelected,
        highlighted,
        selectedColor
      }),
      foldBackground: getFoldBackground({
        selected,
        type: data.type,
        parentSelected,
        ancestorIsMask: data.ancestorIsMask,
        highlighted
      }),
      rounded: getRounded({
        selected,
        parentSelected,
        prevSelected,
        nextSelected
      })
    }
  }, [selected, parentSelected, highlighted, selectedColor, data, prevSelected, nextSelected])

  const handleToggle = (flag: boolean, toggleDescendant: boolean) => {
    onExpand(data.id, flag, toggleDescendant)
  }

  const handleMouseEnter = () => {
    onHover(data.id)
  }

  const handleMouseDown = (e: React.MouseEvent) => {
    if (e.ctrlKey || e.metaKey) {
      // do nothing if parent is selected
      if (data.parentSelected) {
        return
      }
      onSelect(e, data.id)
    } else if (e.shiftKey) {
      onSelect(e, data.id)
    } else {
      if (!data.selected) {
        onSelect(e, data.id)
      } else {
        let moved = false
        window.addEventListener(
          'mousemove',
          () => {
            moved = true
          },
          { once: true, capture: true }
        )
        window.addEventListener(
          'mouseup',
          (e: MouseEvent) => {
            if (!moved && !calcNeedDisableReselect(e.button === 2)) {
              onSelect(e, data.id)
            }
          },
          { once: true, capture: true }
        )
      }
    }
  }

  const handleContextMenu = (e: React.MouseEvent<Element, MouseEvent>) => {
    onContextMenu(e, data.id)
  }

  return (
    <div
      ref={ref}
      className={`js-list-item relative h-${ListItemHeight} items-center list-item`}
      style={{ paddingLeft: levelToIndent(level) }}
      data-id={data.id}
      data-type={type}
      data-selected={selected ? '1' : undefined}
      data-parent-selected={parentSelected ? '1' : undefined}
      data-level={level}
      data-expandable={expandable ? '1' : undefined}
      data-expanded={expanded ? '1' : undefined}
      onMouseEnter={handleMouseEnter}
      onMouseDown={handleMouseDown}
      onContextMenu={handleContextMenu}
    >
      <ItemBackground bg={backgroundStyles.background} rounded={backgroundStyles.rounded} />
      <div className="relative flex-grow max-w-full h-full flex items-center z-1 pr-8">
        {isMask && maskLength > 0 && (
          <div
            className="absolute w-16 flex flex-col"
            style={{ height: maskLength * ListItemHeight, top: `${ListItemHeight}px` }}
          >
            <div className="w-1 h-full rounded-1 bg-light-overlay-20 self-center mb-16 mt-[19px]" />
            <div
              className={`absolute top-0 w-16 flex justify-center items-center`}
              style={{ height: `${ListItemHeight}px` }}
            >
              <div className="w-6 h-6 rounded-circle bg-light-overlay-20" />
            </div>
          </div>
        )}
        {expandable && (
          <FoldToggle
            expanded={expanded}
            onToggle={handleToggle}
            className={`${level ? 'mr-4' : ''}
            ${backgroundStyles.foldBackground} 
            group-hover:${backgroundStyles.background}`}
          />
        )}
        {renderItem({ data, selected, highlighted, hasAnimation })}
      </div>
    </div>
  )
}

export default ListItem
