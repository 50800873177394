import BezierEasing from 'bezier-easing';
import {
  StepAnimateTiming,
  EasingType,
} from '@phase-software/types'
import { DEFAULT_EASING } from '../constant';

/**
 * Step points need x,y,h for each point
 */
export const stepPoints = {
  [EasingType.STEP_START]: [[0, 0, 1], [0, 1, 1]],
  [EasingType.STEP_MIDDLE]: [[0, 0.5, 0], [0.5, 1, 1]],
  [EasingType.STEP_END]: [[0, 1, 0], [1, 1, 1]]
};

/**
 * Define the curve points of different types of easing
 */
export const curvePoints = {
  [EasingType.LINEAR]: [0.25, 0.25, 0.75, 0.75],
  [EasingType.EASE]: [0.25, 0.1, 0.25, 1],
  [EasingType.EASE_IN]: [0.5, 0, 0.88, 0.77],
  [EasingType.EASE_OUT]: [0.12, 0.23, 0.5, 1],
  [EasingType.EASE_IN_OUT]: [0.44, 0, 0.56, 1],
  [EasingType.EASE_IN_SIN]: [0.47, 0, 0.745, 0.715],
  [EasingType.EASE_OUT_SINE]: [0.39, 0.575, 0.565, 1],
  [EasingType.EASE_IN_OUT_SINE]: [0.445, 0.05, 0.55, 0.95],
  [EasingType.EASE_IN_QUAD]: [0.55, 0.085, 0.68, 0.53],
  [EasingType.EASE_OUT_QUAD]: [0.25, 0.46, 0.45, 0.94],
  [EasingType.EASE_IN_OUT_QUAD]: [0.455, 0.03, 0.515, 0.955],
  [EasingType.EASE_IN_CUBIC]: [0.55, 0.055, 0.675, 0.19],
  [EasingType.EASE_OUT_CUBIC]: [0.215, 0.61, 0.355, 1],
  [EasingType.EASE_IN_OUT_CUBIC]: [0.645, 0.045, 0.355, 1],
  [EasingType.EASE_IN_QUART]: [0.895, 0.03, 0.685, 0.22],
  [EasingType.EASE_OUT_QUART]: [0.165, 0.84, 0.44, 1],
  [EasingType.EASE_IN_OUT_QUART]: [0.77, 0, 0.175, 1],
  [EasingType.EASE_IN_QUINT]: [0.755, 0.05, 0.855, 0.06],
  [EasingType.EASE_OUT_QUINT]: [0.23, 1, 0.32, 1],
  [EasingType.EASE_IN_OUT_QUINT]: [0.86, 0, 0.07, 1],
  [EasingType.EASE_IN_EXPO]: [0.95, 0.05, 0.795, 0.035],
  [EasingType.EASE_OUT_EXPO]: [0.19, 1, 0.22, 1],
  [EasingType.EASE_IN_OUT_EXPO]: [1, 0, 0, 1],
  [EasingType.EASE_IN_CIRC]: [0.6, 0.04, 0.98, 0.335],
  [EasingType.EASE_OUT_CIRC]: [0.075, 0.82, 0.165, 1],
  [EasingType.EASE_IN_OUT_CIRC]: [0.785, 0.135, 0.15, 0.86],
  [EasingType.EASE_IN_BACK]: [0.79, -0.33, 0.79, 0.33],
  [EasingType.EASE_OUT_BACK]: [0.15, 0.45, 0.15, 1.35],
  [EasingType.EASE_IN_OUT_BACK]: [0.7, -0.35, 0.3, 1.35]
};

/**
 * Define all the points of different types of easing
 */
export const easePoints = {
  ...curvePoints,
  [EasingType.STEP_END]: stepPoints[EasingType.STEP_END]
};

/**
 * Get the bezier curve function that has supported
 * @param {string} [type=EASE] - easing type
 * @returns {Function} bezier Curve function
 */
export const bezierCurveFunc = (type = EasingType.EASE) => {
  let curvePts = curvePoints[type];
  if (type === EasingType.LINEAR) {
    return t => t;
  } else if (!curvePts) {
    curvePts = curvePoints[EasingType.EASE];
  }

  return BezierEasing(...curvePts); // eslint-disable-line new-cap
};

/**
 * Get bezier easing function
 * @param {number[]} curvePts
 * @returns {Function}
 */
export const bezierEasing = (curvePts) => {
  return BezierEasing(...curvePts);
};

/**
 * Step easing function
 * @param {Array} pts
 * @returns {(pg: number) => number}
 */
export const stepEasingFn = (pts) => (pg) => {
  for (const pt of pts) {
    if (pt[0] <= pg && pt[1] > pg) {
      return pt[2]
    }
  }

  return 1
}

/**
 * Get steps points
 * @param {number} steps
 * @param {StepAnimateTiming} stepAnimateTiming
 * @returns {Array}
 */
export const getStepsPoints = (steps, stepAnimateTiming) => {
  // If steps is not a number, return default START step points.
  if (isNaN(steps)) {
    return stepPoints[EasingType.STEP_START];
  }

  const newPts = [];
  const gap = 1 / steps;
  for (let i = 0; i < steps; i++) {
    let newPt = [gap * i, gap * (i + 1), gap * (i + 1)];
    if (stepAnimateTiming === StepAnimateTiming.END) {
      newPt = [gap * i, gap * (i + 1), gap * i];
    }
    newPts.push(newPt);
  }

  return newPts;
};

/**
 * Get step easing function
 * @param {Array} pts
 * @param {number} steps
 * @param {string} [stepAnimateTiming=StepAnimateTiming.START]
 * @returns {Function}
 */
export const stepEasing = (pts, steps, stepAnimateTiming = StepAnimateTiming.START) => {
  const newPts = steps ? getStepsPoints(steps, stepAnimateTiming) : pts;
  return stepEasingFn(newPts)
}

/**
 * Get easing time function
 * @param {string} [easingType=DEFAULT_EASING]
 * @param {Array} bezier
 * @param {number} steps
 * @param {string} stepAnimateTiming
 * @returns {Function}
 */
export const easingTimeFn = (easingType = DEFAULT_EASING, bezier, steps, stepAnimateTiming) => {
  switch (easingType) {
    case EasingType.STEP_START:
    case EasingType.STEP_MIDDLE:
    case EasingType.STEP_END:
      return stepEasing(stepPoints[easingType])
    case EasingType.STEP_CUSTOM:
      return stepEasing(null, steps, stepAnimateTiming)
    default:
      return bezierEasing(bezier || curvePoints[EasingType.EASE])
  }
}

/**
 * Get property applied percentage
 * @param {Transition} propTransition
 * @param {number} progress
 * @returns {number}
 */
export const getPropPercentage = (propTransition, progress) => {
  if (!propTransition) {
    return progress;
  }

  const { easingType, bezier, steps, stepAnimateTiming } = propTransition;
  const timing = easingTimeFn(easingType, bezier, steps, stepAnimateTiming);
  const percent = timing(progress);
  return percent;
};

/**
 * Get progress from an interval
 * @param {number} time
 * @param {[object,object]} interval
 * @returns {number}
 */
export const getProgress = (time, interval) => {
  if (interval[0].time === interval[1].time) {
    return 1
  }

  return Math.max(0, Math.min(1, (time - interval[0].time) / (interval[1].time - interval[0].time)))
}

/**
 * Get step easing percentage
 * @param {number} progress
 * @returns {number}
 */
export const getStepPercentage = (progress) => {
  const timing = stepEasing(easePoints[EasingType.STEP_END])
  const percent = timing(progress)
  return percent
}

/**
 * Get animation data by percentage
 * @param {number} percent
 * @param {number} start
 * @param {number} end
 * @returns {number}
 */
export const getAnimationByPercentage = (percent, start, end) => {
  const diff = end - start
  return diff * percent + start
}
