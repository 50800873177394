import React from 'react'

import { Player } from '@lottiefiles/react-lottie-player'

import { useFullScreenLoadingContext } from '../../../providers/FullScreenLoadingProvider'
import loadingAnimation from './fullscreen-loading.json'

const LoadingFullScreen = () => {
  const { isFullScreenLoading } = useFullScreenLoadingContext()
  if (isFullScreenLoading === false) {
    return null
  }
  return (
    <div className="fixed inset-0 bg-black z-50 flex items-center justify-center">
      <Player
        autoplay
        loop
        src={loadingAnimation}
        style={{
          height: '280px',
          width: '280px'
        }}
      />
    </div>
  )
}

export default LoadingFullScreen
