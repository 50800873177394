import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { LoadingStatus } from '@phase-software/types'

import { DEFAULT_DIALOG_TRANSITION_TIME } from '../../constant'
import { TeamUserFieldsFragment, useGetTransferOwnershipPendingRequestByIdQuery } from '../../generated/graphql'
import useTeamActions from '../../hooks/useTeamActions'
import { useSetNotification } from '../../providers/NotificationProvider'
import { useWorkspaceContext } from '../../providers/WorkspaceContextProvider'
import { verificationCodeValidator } from '../../utils/validator'
import { Dialog, Input, LinkSwitcher, Message, ScrollView, SearchInput, Text } from '../shared'
import ListItemWithContent from '../shared/List/ListItemWithContent'

const TIME_INTERVAL = 30000 // 30 seconds

interface TransferOwnershipDialogProps {
  open: boolean
  onClose: () => void
  memberList: TeamUserFieldsFragment[]
}

interface ConfirmTransferOwnershipDialogProps extends Omit<TransferOwnershipDialogProps, 'memberList'> {
  selectedMember: TeamUserFieldsFragment
  requestId: string
}

// ------------------------
// Confirm Transfer Ownership Dialog Component
// ------------------------
const ConfirmTransferOwnershipDialog = ({
  onClose,
  open,
  selectedMember,
  requestId
}: ConfirmTransferOwnershipDialogProps) => {
  const { t } = useTranslation(['workspace', 'common'])
  const { addNotification } = useSetNotification()
  const { workspaceData } = useWorkspaceContext()
  const { resendTransferOwnershipRequest, confirmTransferOwnershipRequest } = useTeamActions()

  const lastSentTime = useRef(0)
  const closeMessageTimer = useRef<number>()

  const [verificationErrorMessage, setVerificationErrorMessage] = useState('')
  const [verificationCode, setVerificationCode] = useState('')
  const [confirming, setConfirming] = useState(false)
  const [showResentWarningMessage, setShowResentWarningMessage] = useState(false)

  const handleInput = (value: string) => {
    setVerificationCode(value)
    setVerificationErrorMessage('')
  }

  const handleResendVerificationCode = async () => {
    const now = Date.now()
    const timeDiff = now - lastSentTime.current
    if (timeDiff < TIME_INTERVAL) {
      setShowResentWarningMessage(true)
      return
    }
    lastSentTime.current = now

    try {
      await resendTransferOwnershipRequest(selectedMember?.team_id, requestId)

      addNotification({
        type: 'success',
        content: t('dialog.verification_code_email_resent')
      })
    } catch (error) {
      console.error(error)
    }
  }

  const handleConfirm = async () => {
    if (confirming) return
    const validatorError = verificationCodeValidator(verificationCode, t)
    if (validatorError) {
      const translatedError = t(`common:validator.${validatorError}`)
      setVerificationErrorMessage(translatedError)
      return
    }
    setConfirming(true)
    try {
      await confirmTransferOwnershipRequest(selectedMember?.team_id, requestId, verificationCode)
      onClose()

      addNotification({
        type: 'success',
        content: (
          <Trans
            i18nKey="workspace:dialog.user_is_admin"
            values={{ workspace_name: workspaceData.name }}
            components={[<span key="confirm-ownership" className="font-semibold" />]}
          />
        )
      })
    } catch (error: Error | any) {
      console.error(error)
      setVerificationErrorMessage(error?.message)
    } finally {
      setConfirming(false)
    }
  }

  const handleCloseMessage = () => {
    setShowResentWarningMessage(false)
    clearTimeout(closeMessageTimer.current)
    closeMessageTimer.current = undefined
  }

  useEffect(() => {
    if (!showResentWarningMessage || closeMessageTimer.current) return

    closeMessageTimer.current = window.setTimeout(() => {
      setShowResentWarningMessage(false)
    }, TIME_INTERVAL)
    return () => clearTimeout(closeMessageTimer.current)
  }, [showResentWarningMessage])

  return (
    <Dialog
      data-test-id="confirm-ownership-transfer-dialog"
      size="s"
      title={t('dialog.confirm_the_ownership_transfer')}
      confirmBtnText={t('dialog.confirm')}
      cancelBtnText={t('dialog.no_i_regret')}
      onConfirm={handleConfirm}
      onCancel={onClose}
      disableConfirm={confirming}
      showProgressButton
      progressStatus={confirming ? LoadingStatus.WAITING : LoadingStatus.INITIAL}
      open={open}
    >
      {showResentWarningMessage && (
        <Message
          type="warning"
          description={t('message.you_just_resent_the_verification_code')}
          className="my-8"
          onClose={handleCloseMessage}
        />
      )}
      <Text ellipsis={false}>
        <Trans
          i18nKey="workspace:dialog.transfer_ownership_confirmation"
          values={{
            workspace_name: workspaceData.name,
            username: selectedMember.user?.username,
            email: selectedMember.user?.email
          }}
          components={{ bold: <span key="transfer-ownership-confirmation" className="font-semibold" /> }}
        />
      </Text>
      <LinkSwitcher
        onClick={handleResendVerificationCode}
        className="text-primary-40 mt-8 self-start highlight-border-rounded-3"
      >
        {t('dialog.resend_verification_code')}
      </LinkSwitcher>
      <Input
        // @ts-ignore TODO: fix after refactor of Input
        showErrorTips={!!verificationErrorMessage}
        errorOverride={verificationErrorMessage}
        value={verificationCode}
        inputSize="l"
        variant="normal"
        placeholder={t('dialog.enter_the_deletion_verification_code')}
        onInput={handleInput}
        onEnterKey={handleConfirm}
        className="mt-16"
      />
    </Dialog>
  )
}

// ------------------------
// Transfer Ownership Dialog Component
// ------------------------
const TransferOwnershipDialog = ({ onClose, open, memberList }: TransferOwnershipDialogProps) => {
  const { t } = useTranslation(['workspace', 'setting', 'common'])
  const { createTransferOwnershipRequest, cancelTransferOwnershipRequest } = useTeamActions()

  const [searchMember, setSearchMember] = useState('')
  const [isSending, setIsSending] = useState(false)
  const [selectedMember, setSelectedMember] = useState<TeamUserFieldsFragment | undefined>()
  const [showMemberDialog, setShowMemberDialog] = useState(true)
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const [requestId, setRequestId] = useState('')

  const isSendButtonDisabled = isSending || !selectedMember

  const { data: transferOwnershipPendingRequestData, refetch: refetchTransferOwnershipPendingRequest } =
    useGetTransferOwnershipPendingRequestByIdQuery({
      variables: {
        id: selectedMember?.team_id
      },
      skip: !selectedMember
    })

  const searchMembersResult = useMemo(
    () =>
      memberList.filter((member) => {
        const lowerCaseSearch = searchMember.toLocaleLowerCase()
        const username = member.user?.username?.toLocaleLowerCase() ?? ''
        const email = member.user?.email?.toLocaleLowerCase() ?? ''
        return username.includes(lowerCaseSearch) || email.includes(lowerCaseSearch)
      }),
    [memberList, searchMember]
  )
  const showSearchBar = useMemo(() => memberList.length > 0, [memberList])

  const handleSendEmail = async () => {
    if (isSendButtonDisabled) return
    setIsSending(true)
    try {
      const hasPendingTransferRequest =
        transferOwnershipPendingRequestData?.team_ownership_transfer_intents?.length !== 0

      if (hasPendingTransferRequest) {
        await cancelTransferOwnershipRequest(
          selectedMember?.team_id,
          transferOwnershipPendingRequestData?.team_ownership_transfer_intents?.[0].id
        )
      }
      const { id } = await createTransferOwnershipRequest(selectedMember?.team_id, selectedMember?.user_id)
      setRequestId(id)
      // The transition for closing the MemberDialog requires DEFAULT_DIALOG_TRANSITION_TIME, therefore, there is a need to delay the opening time of the ConfirmDialog by DEFAULT_DIALOG_TRANSITION_TIME
      setShowMemberDialog(false)
      setTimeout(() => {
        setShowConfirmDialog(true)
      }, DEFAULT_DIALOG_TRANSITION_TIME)
    } catch (error) {
      console.error(error)
    } finally {
      setIsSending(false)
    }
  }

  const handleCloseConfirmTransferOwnershipDialog = () => {
    refetchTransferOwnershipPendingRequest()
    // When closing the dialog, the value needs to be set to default. To match the closing transition, a delay of DEFAULT_DIALOG_TRANSITION_TIME is also required.
    setTimeout(() => {
      setShowConfirmDialog(false)
      setShowMemberDialog(true)
      setSelectedMember(undefined)
    }, DEFAULT_DIALOG_TRANSITION_TIME)
    onClose()
  }

  if (!showConfirmDialog) {
    return (
      <Dialog
        data-test-id="transfer-ownership-dialog"
        size="s"
        title={t('dialog.transfer_ownership')}
        confirmBtnText={t('dialog.transfer_ownership')}
        cancelBtnText={t('common:button.cancel')}
        onConfirm={handleSendEmail}
        onCancel={onClose}
        disableConfirm={isSendButtonDisabled}
        showProgressButton
        progressStatus={isSending ? LoadingStatus.WAITING : LoadingStatus.INITIAL}
        bodyClassName="px-0"
        open={open && showMemberDialog}
      >
        {showSearchBar && (
          <div className="px-16 pt-4 pb-8">
            <SearchInput
              size="l"
              value={searchMember}
              onChange={setSearchMember}
              placeholder={t('dialog.search_team_members_by_email_or_name')}
              noCrossButton
            />
          </div>
        )}
        <div className="h-[232px] flex">
          {searchMembersResult.length === 0 ? (
            <Text className="flex-1 flex justify-center items-center text-light-overlay-40">
              {t('dialog.no_members_found_in_this_team')}
            </Text>
          ) : (
            // @ts-ignore TODO: fix after refactor of ScrollView
            <ScrollView className="flex-1">
              {searchMembersResult.map((member) => (
                <ListItemWithContent
                  checked={selectedMember?.user_id === member.user_id}
                  onChecked={() => setSelectedMember(member)}
                  key={member.user_id}
                  title={member.user?.username}
                  description={member.user?.email}
                  avatarSrc={member.user?.avatar ?? ''}
                  avatarAlt={member.user?.username ?? ''}
                  size="l"
                  type="radio"
                  after={t(`setting:team_setting.team_role:${member.team_role.toLowerCase()}`)}
                />
              ))}
            </ScrollView>
          )}
        </div>
      </Dialog>
    )
  }
  if (showConfirmDialog) {
    return (
      <ConfirmTransferOwnershipDialog
        open={open && showConfirmDialog}
        onClose={handleCloseConfirmTransferOwnershipDialog}
        selectedMember={selectedMember!}
        requestId={requestId}
      />
    )
  }
  return null
}

export default TransferOwnershipDialog
