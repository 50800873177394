// clone from renderer
export class Transform2D {
  constructor(a = 1, b = 0, c = 0, d = 1, tx = 0, ty = 0) {
    this.a = a
    this.b = b
    this.c = c
    this.d = d
    this.tx = tx
    this.ty = ty
  }

  affine_inverse() {
    const det = this.a * this.d - this.b * this.c
    const idet = 1.0 / det

    const tmp = this.d
    this.d = this.a
    this.a = tmp

    this.a *= idet
    this.b *= -idet
    this.c *= -idet
    this.d *= idet

    const tx = this.a * -this.tx + this.c * -this.ty
    const ty = this.b * -this.tx + this.d * -this.ty

    this.tx = tx
    this.ty = ty

    return this
  }

  scale(x, y) {
    this.a *= x
    this.d *= y
    this.c *= x
    this.b *= y
    this.tx *= x
    this.ty *= y
    return this
  }

  xform(p_vec) {
    const x = this.a * p_vec[0] + this.c * p_vec[1] + this.tx
    const y = this.b * p_vec[0] + this.d * p_vec[1] + this.ty
    return [x, y]
  }
}
