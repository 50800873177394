import { getQuadraticP, getCubicP } from '@phase-software/data-utils'
import { Command, getSegmentLUT } from './PathData'

/** @typedef {import('../geometry/PathData').PathData} PathData */

/** @enum {number} */
export const CubicBezTypes = {
    line: 1,
    quadratic: 2,
    serpentine: 3,
    cusp: 4,
    loop: 5,
    arch: 6,
}

/**
 * @param {PathData} path
 */
export function makeOuterMostContourCCW(path) {
    if (path !== null) {
        const boundaries = path.getSubpathBoundaries()
        const i = getOuterMostContour(path, boundaries)
        if (isClockwise(path, boundaries[i][1], boundaries[i + 1][1])) {
            path.reverseSubpaths()
        }
    }
}

/**
 * from https://stackoverflow.com/a/1165943
 * @param {PathData} path
 * @param {number} start
 * @param {number} end
 * @returns {boolean}
 */
function isClockwise(path, start, end) {
    let area = 0
    for (let i = start; i <= end - 4; i += 2) {
        const x0 = path.vertices[i]
        const y0 = path.vertices[i + 1]
        const x1 = path.vertices[i + 2]
        const y1 = path.vertices[i + 3]
        area += (x1 - x0) * (y1 + y0)
    }
    return area > 0
}

/**
 * returns a contour that is not inside any other contour
 * @param {PathData} path
 * @param {[number, number][]} boundaries
 * @returns {number}
 */
function getOuterMostContour(path, boundaries) {
    const len = boundaries.length - 1

    if (len === 1) return 0

    /** @type {Set<number>} */
    const exclude = new Set()
    for (let i = 0; i < len; i++) {

        if (exclude.has(i)) continue
        for (let j = 0; j < len; j++) {
            const x = path.vertices[boundaries[j][1]]
            const y = path.vertices[boundaries[j][1] + 1]

            if (i !== j && isPointInsidePath(x, y, path, boundaries[i])) {
                exclude.add(j)
            }
        }
    }

    for (let i = 0; i < len; i++) {
        if (!exclude.has(i)) {
            return i
        }
    }

    console.warn('should have returned')
}

/**
 * Check if a point is inside a Path
 * @param {number} x
 * @param {number} y
 * @param {PathData} path
 * @param {number[]} [start=null]
 * @returns {bool}
 */
export function isPointInsidePath(x, y, path, start = null) {
    let intersections = 0

    let i = start === null ? 0 : start[1]
    let firstX = null
    let firstY = null
    let fromX = null
    let fromY = null
    outer:
    for (let c = start === null ? 0 : start[0]; c < path.commands.length; c++) {
        const command = path.commands[c]

        let toX = null
        let toY = null

        switch (command) {
            case Command.M: {
                // close path
                // if (fromX !== null && fromY !== null) {
                //     intersections += rayIntersectsSegment(x, y, fromX, fromY, firstX, firstY, true) ? 1 : 0
                // }

                if (start !== null && firstX !== null && firstY !== null) {
                    break outer
                }

                firstX = path.vertices[i++]
                firstY = path.vertices[i++]
                toX = firstX
                toY = firstY
            } break
            case Command.L: {
                toX = path.vertices[i++]
                toY = path.vertices[i++]
                intersections += rayIntersectsSegment(x, y, fromX, fromY, toX, toY, true) ? 1 : 0
            } break
            case Command.Q: {
                const cpX = path.vertices[i++]
                const cpY = path.vertices[i++]
                toX = path.vertices[i++]
                toY = path.vertices[i++]
                intersections += rayIntersectsQuadraticCurve(x, y, fromX, fromY, cpX, cpY, toX, toY, true)
            } break
            case Command.C: {
                const cp0X = path.vertices[i++]
                const cp0Y = path.vertices[i++]
                const cp1X = path.vertices[i++]
                const cp1Y = path.vertices[i++]
                toX = path.vertices[i++]
                toY = path.vertices[i++]
                intersections += rayIntersectsCubicCurve(x, y, fromX, fromY, cp0X, cp0Y, cp1X, cp1Y, toX, toY, true)
            } break
        }

        fromX = toX
        fromY = toY
    }

    return intersections % 2 !== 0 // odd nr of intersection points
}

/**
 * check if a ray originating from the point at `x`, `y` towards +x infinity intersects with a line segment
 * @param {number} x
 * @param {number} y
 * @param {number} fromX
 * @param {number} fromY
 * @param {number} toX
 * @param {number} toY
 * @param {boolean} [skipEndPoint]
 * @returns {boolean}
 */
function rayIntersectsSegment(x, y, fromX, fromY, toX, toY, skipEndPoint = false) {
    const p0YSign = Math.sign(fromY - y)
    const p1YSign = Math.sign(toY - y)
    // one of the segment points lies on the ray
    if (p0YSign === 0) return true
    if (p1YSign === 0) return !skipEndPoint
    // both segment points are on the same side of the ray
    if (p0YSign === p1YSign) return false
    // both segment points are to the right of the point p
    if (fromX >= x && toX >= x) return true
    // check if intersection point is to the right of the point p
    return (y - fromY) * (fromX - toX) / (fromY - toY) + fromX >= x
}

/**
 * check if a ray originating from the point at `x`, `y` towards +x infinity intersects with a quadratic bezier curve
 * @param {number} x
 * @param {number} y
 * @param {number} fromX
 * @param {number} fromY
 * @param {number} cpX
 * @param {number} cpY
 * @param {number} toX
 * @param {number} toY
 * @param {boolean} [skipEndPoint]
 * @returns {number} nr of intersections
 */
function rayIntersectsQuadraticCurve(x, y, fromX, fromY, cpX, cpY, toX, toY, skipEndPoint = false) {
    return quadraticCurveRoots(fromY - y, cpY - y, toY - y)
        .filter(t => !(skipEndPoint && t === 1))
        .map(t => getQuadraticP(t, fromX, fromY, cpX, cpY, toX, toY))
        .filter(root => root[0] > x)
        .length
}

/**
 * check if a ray originating from the point at `x`, `y` towards +x infinity intersects with a cubic bezier curve
 * @param {number} x
 * @param {number} y
 * @param {number} fromX
 * @param {number} fromY
 * @param {number} cp0X
 * @param {number} cp0Y
 * @param {number} cp1X
 * @param {number} cp1Y
 * @param {number} toX
 * @param {number} toY
 * @param {boolean} [skipEndPoint]
 * @returns {number} nr of intersections
 */
function rayIntersectsCubicCurve(x, y, fromX, fromY, cp0X, cp0Y, cp1X, cp1Y, toX, toY, skipEndPoint = false) {
    return cubicCurveRoots(fromY - y, cp0Y - y, cp1Y - y, toY - y)
        .filter(t => !(skipEndPoint && t === 1))
        .map(t => getCubicP(t, fromX, fromY, cp0X, cp0Y, cp1X, cp1Y, toX, toY))
        .filter(root => root[0] > x)
        .length
}

/**
 * @param {number} t
 * @returns {boolean}
 */
function filterT(t) {
    return t >= 0 && t <= 1
}

/**
 * @param {number} fromY
 * @param {number} cpY
 * @param {number} toY
 * @returns {number[]} 0 - 2 roots
 */
function quadraticCurveRoots(fromY, cpY, toY) {
    const a = fromY
    const b = cpY
    const c = toY
    const d = a - 2 * b + c
    if (d !== 0) {
        const m1 = -Math.sqrt(b * b - a * c)
        const m2 = -a + b
        const v1 = -(m1 + m2) / d
        const v2 = -(-m1 + m2) / d
        return [v1, v2].filter(filterT)
    } else if (b !== c && d === 0) {
        return [(2 * b - c) / (2 * b - 2 * c)].filter(filterT)
    }
    return []
}

/**
 * @param {number} fromY
 * @param {number} cp0Y
 * @param {number} cp1Y
 * @param {number} toY
 * @returns {number[]} 0 - 3 roots
 */
function cubicCurveRoots(fromY, cp0Y, cp1Y, toY) {
    const pa = fromY
    const pb = cp0Y
    const pc = cp1Y
    const pd = toY

    // see https://www.trans4mind.com/personal_development/mathematics/polynomials/cubicAlgebra.htm
    const d = -pa + 3 * pb - 3 * pc + pd
    let a = 3 * pa - 6 * pb + 3 * pc
    let b = -3 * pa + 3 * pb
    let c = pa

    // this is not a cubic curve
    if (isApprox(d, 0)) {
        // in fact, this is not a quadratic curve either
        if (isApprox(a, 0)) {
            // in fact in fact, there are no solutions
            if (isApprox(b, 0)) {
                return []
            }
            // linear solution:
            return [-c / b].filter(filterT)
        }
        // quadratic solution:
        const q = Math.sqrt(b * b - 4 * a * c)
        const a2 = 2 * a
        return [(q - b) / a2, (-b - q) / a2].filter(filterT)
    }

    // at this point, we know we need a cubic solution:

    a /= d
    b /= d
    c /= d

    const p = (3 * b - a * a) / 3
    const p3 = p / 3
    const q = (2 * a * a * a - 9 * a * b + 27 * c) / 27
    const q2 = q / 2
    const discriminant = q2 * q2 + p3 * p3 * p3

    if (discriminant < 0) {
        const mp3 = -p / 3
        const mp33 = mp3 * mp3 * mp3
        const r = Math.sqrt(mp33)
        const t = -q / (2 * r)
        const cosphi = Math.min(Math.max(t, -1), 1)
        const phi = Math.acos(cosphi)
        const crtr = crt(r)
        const t1 = 2 * crtr
        const x1 = t1 * Math.cos(phi / 3) - a / 3
        const x2 = t1 * Math.cos((phi + 2 * Math.PI) / 3) - a / 3
        const x3 = t1 * Math.cos((phi + 4 * Math.PI) / 3) - a / 3
        return [x1, x2, x3].filter(filterT)
    } else if (discriminant === 0) {
        const u1 = q2 < 0 ? crt(-q2) : -crt(q2)
        const x1 = 2 * u1 - a / 3
        const x2 = -u1 - a / 3
        return [x1, x2].filter(filterT)
    } else {
        const sd = Math.sqrt(discriminant)
        const u1 = crt(-q2 + sd)
        const v1 = crt(q2 + sd)
        return [u1 - v1 - a / 3].filter(filterT)
    }
}

/**
 * returns `true` if `a` is approximately equal to `b`
 * @param {number} a
 * @param {number} b
 * @param {number} [precision]
 * @returns {boolean}
 */
function isApprox(a, b, precision = 0.000001) {
    return Math.abs(a - b) <= precision
}

/**
 * cube root function yielding real roots
 * @param {number} v
 * @returns {number}
 */
function crt(v) {
    return v < 0 ? -Math.pow(-v, 1 / 3) : Math.pow(v, 1 / 3)
}

/**
 * Get Path LUT
 * @param {PathData} path
 * @returns {[{ type, data }]}
 */
export function getPathLUT(path) {
    if (!path) return []

    let firstX = null
    let firstY = null
    let fromX = null
    let fromY = null
    let i = 0
    let luts = []

    for (let c = 0; c < path.commands.length; c++) {
        const command = path.commands[c]
        let toX = null
        let toY = null

        switch (command) {
            case Command.M:
                firstX = path.vertices[i++]
                firstY = path.vertices[i++]
                toX = firstX
                toY = firstY
                luts.push({
                    x: firstX,
                    y: firstY
                })
                break
            case Command.L: {
                toX = path.vertices[i++]
                toY = path.vertices[i++]
                luts.push({
                    x: toX,
                    y: toY
                })
            } break
            case Command.Q: {
                const cpX = path.vertices[i++]
                const cpY = path.vertices[i++]
                toX = path.vertices[i++]
                toY = path.vertices[i++]
                const lut = getSegmentLUT(
                    { x: fromX, y: fromY },
                    { x: cpX, y: cpY },
                    null,
                    { x: toX, y: toY }
                )
                luts = [...luts, ...lut]
            } break
            case Command.C: {
                const cp0X = path.vertices[i++]
                const cp0Y = path.vertices[i++]
                const cp1X = path.vertices[i++]
                const cp1Y = path.vertices[i++]
                toX = path.vertices[i++]
                toY = path.vertices[i++]
                const lut = getSegmentLUT(
                    { x: fromX, y: fromY },
                    { x: cp0X, y: cp0Y },
                    { x: cp1X, y: cp1Y },
                    { x: toX, y: toY }
                )
                luts = [...luts, ...lut]
            } break
        }

        fromX = toX
        fromY = toY
    }

    return luts
}

/**
 * Determine the segment causes the error of the libaray Bezier.js
 * Note:  the issue occurs infrequently in the current version, so there is no need to call this function at this time
 * @param {Bezier} bezier
 * @returns {boolean}
 */
export function detectBezierJs(bezier) {
    let i,
        t1 = 0,
        t2 = 0,
        segment

    const step = 0.01,
        pass1 = [],
        pass2 = []

    // first pass: split on extrema
    let extrema = bezier.extrema().values
    if (extrema.indexOf(0) === -1) {
        extrema = [0].concat(extrema)
    }
    if (extrema.indexOf(1) === -1) {
        extrema.push(1)
    }

    for (t1 = extrema[0], i = 1; i < extrema.length; i++) {
        t2 = extrema[i]
        segment = bezier.split(t1, t2)
        segment._t1 = t1
        segment._t2 = t2
        pass1.push(segment)
        t1 = t2
    }
    const map = (v, ds, de, ts, te) => {
        const d1 = de - ds,
            d2 = te - ts,
            v2 = v - ds,
            r = v2 / d1
        return ts + d2 * r
    }

    // second pass: further reduce these segments to simple segments
    pass1.forEach(function (p1) {
        t1 = 0
        t2 = 0
        while (t2 <= 1) {
            for (t2 = t1 + step; t2 <= 1 + step; t2 += step) {
                segment = p1.split(t1, t2)
                if (!segment.simple()) {
                    t2 -= step
                    if (Math.abs(t1 - t2) < step) {
                        // we can never form a reduction
                        return false
                    }
                    segment = p1.split(t1, t2)
                    segment._t1 = map(t1, 0, 1, p1._t1, p1._t2)
                    segment._t2 = map(t2, 0, 1, p1._t1, p1._t2)
                    pass2.push(segment)
                    t1 = t2
                    return false
                }
            }
        }
    })
    return true
}

const CURVE_EPS = 1e-8
/**
 * @param {number[]} vec
 * @param {number} x
 * @param {number} y
 * @param {number} z
 * @returns {number[]}
 */
function vec3_Set(vec, x, y, z) {
    vec[0] = x
    vec[1] = y
    vec[2] = z
    return vec
}
/**
 * @param {number[]} r_out
 * @param {number[]} a
 * @param {number[]} b
 * @returns {number[]}
 */
function vec2_Cross(r_out, a, b) {
    const ax = a[0], ay = a[1], az = a[2]
    const bx = b[0], by = b[1], bz = b[2]
    r_out[0] = ay * bz - az * by
    r_out[1] = az * bx - ax * bz
    r_out[2] = ax * by - ay * bx
    return r_out
}
/**
 * @param {number[]} a
 * @param {number[]} b
 * @returns {number}
 */
function vec2_Dot(a, b) {
    return a[0] * b[0] + a[1] * b[1] + a[2] * b[2]
}
/**
 * @param {number} num
 * @returns {boolean}
 */
function IsZero(num) {
    return (num >= 0) ? (num < CURVE_EPS) : (num > -CURVE_EPS)
}
/** @enum {number} */
export const CurveTypes = {
    Serpentine: 0,

    Loop: 10,
    LoopA: 11,
    LoopB: 12,

    CuspInflectionAtInf: 20,
    CuspCuspAtInf: 21,
    Cusp: 22, // CuspInf or CuspCusp

    Quadratic: 30,

    LineOrPoint: 40,
}
const b0 = [0, 0, 0]
const b1 = [0, 0, 0]
const b2 = [0, 0, 0]
const b3 = [0, 0, 0]
const cross1 = [0, 0]
const cross2 = [0, 0]
const cross3 = [0, 0]
/**
 * @param {number} x1
 * @param {number} y1
 * @param {number} c1x
 * @param {number} c1y
 * @param {number} c2x
 * @param {number} c2y
 * @param {number} x2
 * @param {number} y2
 */
export function detectCubicBezType(x1, y1, c1x, c1y, c2x, c2y, x2, y2) {
    vec3_Set(b0, x1, y1, 1)
    vec3_Set(b1, c1x, c1y, 1)
    vec3_Set(b2, c2x, c2y, 1)
    vec3_Set(b3, x2, y2, 1)

    vec2_Cross(cross1, b3, b2)
    vec2_Cross(cross2, b0, b3)
    vec2_Cross(cross3, b1, b0)

    const a1 = vec2_Dot(b0, cross1)
    const a2 = vec2_Dot(b1, cross2)
    const a3 = vec2_Dot(b2, cross3)

    const d1 = a1 - 2 * a2 + 3 * a3
    const d2 = -a2 + 3 * a3
    const d3 = 3 * a3

    const D = 3 * d2 * d2 - 4 * d1 * d3
    const discriminant = d1 * d1 * D

    if (IsZero(discriminant)) {
        if (IsZero(d1) && IsZero(d2)) {
            if (IsZero(d3)) {
                return CubicBezTypes.line
            } else {
                return CubicBezTypes.quadratic
            }
        }
        if (!IsZero(d1)) {
            return CubicBezTypes.cusp
        }
        if (D < 0) {
            return CubicBezTypes.loop
        }
        return CubicBezTypes.serpentine
    }

    if (discriminant > 0) {
        return CubicBezTypes.serpentine
    }

    return CubicBezTypes.loop
}
