import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { isEmpty } from 'lodash'

import { useInspectContext } from '../../../providers/InspectProvider'
import { useDataStore, useDataStoreActions } from '../../../providers/dataStore/DataStoreProvider'
import { useEditor } from '../../../providers/dataStore/EditorProvider'
import { useInteractionActions } from '../../../providers/dataStore/InteractionProvider'
import { formatNumberWithUnit } from '../../../utils/formatter'
import { easingTypeNameMap } from '../../Interaction/constant'
import InspectContent from '../PropertyInspection/InspectContent'
import InspectRow from '../PropertyInspection/InspectRow'
import { shouldShowBezierRow } from '../utils'

type PositionInspectionProps = {
  elementId: string
}

const PositionInspection = ({ elementId }: PositionInspectionProps) => {
  const { t } = useTranslation('file')
  const alignment = useEditor((o) => o.alignment)
  const dataStore = useDataStore()
  const { getPropertyKeyFrameGroupByTime, getKeyFrame } = useInteractionActions()
  const { getTimeString } = useInspectContext()
  const { setPlayheadTime } = useDataStoreActions()
  const value = useMemo(
    () => dataStore.transition.getPropertyValue(elementId, 'position'),
    [elementId, dataStore.transition]
  )

  const animationKeyFramesX = useMemo(
    () => getPropertyKeyFrameGroupByTime(elementId, 'x'),
    [elementId, getPropertyKeyFrameGroupByTime]
  )
  const animationKeyFramesY = useMemo(
    () => getPropertyKeyFrameGroupByTime(elementId, 'y'),
    [elementId, getPropertyKeyFrameGroupByTime]
  )

  const renderContent = (): React.ReactNode => {
    const obj = {} as any

    Object.keys(animationKeyFramesX)?.map((key: string) => {
      const keyframe = getKeyFrame(animationKeyFramesX[key][0])
      const val = dataStore.transition.getPropertyValueByTime(elementId, 'position', Number(key))
      obj[key] = { value: val, easingType: keyframe.easingType, bezier: keyframe.bezier }
    })
    Object.keys(animationKeyFramesY)?.map((key: string) => {
      const keyframe = getKeyFrame(animationKeyFramesY[key][0])
      const val = dataStore.transition.getPropertyValueByTime(elementId, 'position', Number(key))
      obj[key] = { value: val, easingType: keyframe.easingType, bezier: keyframe.bezier }
    })
    return Object.keys(obj).map((key) => {
      return (
        <InspectContent key={key} spacedDivider>
          <InspectRow
            type="wide"
            label={t('inspect.time')}
            value={getTimeString(Number(key))}
            onClick={() => setPlayheadTime(Number(key))}
          />
          <InspectRow
            type="wide"
            label={t('inspect.value')}
            value={`${formatNumberWithUnit(obj[key].value.x)}px, ${formatNumberWithUnit(obj[key].value.y)}px`}
          />
          <InspectRow
            type="wide"
            label={t('inspect.ease')}
            value={t(easingTypeNameMap[obj[key].easingType as keyof typeof easingTypeNameMap], {
              keyPrefix: 'easing_type'
            })}
          />
          {shouldShowBezierRow(obj[key].easingType) && (
            <InspectRow type="wide" label={t('inspect.bezier')} value={obj[key]?.bezier?.join(', ')} />
          )}
        </InspectContent>
      )
    })
  }

  if (isEmpty(animationKeyFramesX) && isEmpty(animationKeyFramesY)) return null
  return (
    <>
      <InspectContent title={t('inspect.translate')}>
        <InspectRow
          type="wide"
          label={t('inspect.auto_orient')}
          value={t(String(alignment), {
            keyPrefix: 'inspect'
          })}
        />
      </InspectContent>
      <InspectContent spacedDivider>
        <InspectRow
          type="wide"
          label={t('inspect.initial')}
          value={`${formatNumberWithUnit(value.x)}px, ${formatNumberWithUnit(value.y)}px`}
        />
      </InspectContent>
      <>{renderContent()}</>
    </>
  )
}

export default React.memo(PositionInspection)
