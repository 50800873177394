import PropertyStack from '../PropertyStack'

class RotationStack extends PropertyStack {
  constructor(elementStack, data) {
    super(elementStack, data)
    this.type = 'ROTATION'
    this.key = 'rotation'
    this.dataKey = 'rotation'
    this.animatableProperties = new Set(['rotation'])
  }
}

export default RotationStack
