/* tslint:disable */
/* eslint-disable */
/**
 * PHASE API
 * Phase API service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ConfirmDeleteIntentRequest,
  ConfirmOwnershipTransferIntentRequest,
  CreateDeleteIntentResponse,
  CreateOwnershipTransferIntentRequest,
  CreateTeamRequest,
  CreateTimeResponse,
  CreateTransferIntentResponse,
  PatchTeamRequest,
  UpdateTeamIconRequest,
  UpdateTeamIconResponse,
} from '../models';

export interface TeamApiControllerCancelDeleteIntentRequest {
    teamId: string;
    intentId: string;
}

export interface TeamApiControllerCancelOwnershipTransferIntentRequest {
    teamId: string;
    intentId: string;
}

export interface TeamApiControllerConfirmDeleteIntentRequest {
    teamId: string;
    intentId: string;
    confirmDeleteIntentRequest: ConfirmDeleteIntentRequest;
}

export interface TeamApiControllerConfirmOwnershipTransferIntentRequest {
    teamId: string;
    intentId: string;
    confirmOwnershipTransferIntentRequest: ConfirmOwnershipTransferIntentRequest;
}

export interface TeamApiControllerCreateDeleteIntentRequest {
    teamId: string;
}

export interface TeamApiControllerCreateOwnershipTransferIntentRequest {
    teamId: string;
    createOwnershipTransferIntentRequest: CreateOwnershipTransferIntentRequest;
}

export interface TeamApiControllerCreateTeamRequest {
    createTeamRequest: CreateTeamRequest;
}

export interface TeamApiControllerPatchTeamRequest {
    teamId: string;
    patchTeamRequest: PatchTeamRequest;
}

export interface TeamApiControllerResendDeleteIntentEmailRequest {
    teamId: string;
    intentId: string;
}

export interface TeamApiControllerResendOwnershipTransferIntentEmailRequest {
    teamId: string;
    intentId: string;
}

export interface TeamApiControllerUpdateAvatarRequest {
    teamId: string;
    updateTeamIconRequest: UpdateTeamIconRequest;
}

/**
 * 
 */
export class TeamApi extends runtime.BaseAPI {

    /**
     */
    async teamApiControllerCancelDeleteIntentRaw(requestParameters: TeamApiControllerCancelDeleteIntentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling teamApiControllerCancelDeleteIntent.');
        }

        if (requestParameters.intentId === null || requestParameters.intentId === undefined) {
            throw new runtime.RequiredError('intentId','Required parameter requestParameters.intentId was null or undefined when calling teamApiControllerCancelDeleteIntent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/teams/{teamId}/deleteIntents/{intentId}:cancel`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"intentId"}}`, encodeURIComponent(String(requestParameters.intentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async teamApiControllerCancelDeleteIntent(requestParameters: TeamApiControllerCancelDeleteIntentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.teamApiControllerCancelDeleteIntentRaw(requestParameters, initOverrides);
    }

    /**
     */
    async teamApiControllerCancelOwnershipTransferIntentRaw(requestParameters: TeamApiControllerCancelOwnershipTransferIntentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling teamApiControllerCancelOwnershipTransferIntent.');
        }

        if (requestParameters.intentId === null || requestParameters.intentId === undefined) {
            throw new runtime.RequiredError('intentId','Required parameter requestParameters.intentId was null or undefined when calling teamApiControllerCancelOwnershipTransferIntent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/teams/{teamId}/ownershipTransferIntents/{intentId}:cancel`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"intentId"}}`, encodeURIComponent(String(requestParameters.intentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async teamApiControllerCancelOwnershipTransferIntent(requestParameters: TeamApiControllerCancelOwnershipTransferIntentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.teamApiControllerCancelOwnershipTransferIntentRaw(requestParameters, initOverrides);
    }

    /**
     */
    async teamApiControllerConfirmDeleteIntentRaw(requestParameters: TeamApiControllerConfirmDeleteIntentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling teamApiControllerConfirmDeleteIntent.');
        }

        if (requestParameters.intentId === null || requestParameters.intentId === undefined) {
            throw new runtime.RequiredError('intentId','Required parameter requestParameters.intentId was null or undefined when calling teamApiControllerConfirmDeleteIntent.');
        }

        if (requestParameters.confirmDeleteIntentRequest === null || requestParameters.confirmDeleteIntentRequest === undefined) {
            throw new runtime.RequiredError('confirmDeleteIntentRequest','Required parameter requestParameters.confirmDeleteIntentRequest was null or undefined when calling teamApiControllerConfirmDeleteIntent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/teams/{teamId}/deleteIntents/{intentId}:confirm`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"intentId"}}`, encodeURIComponent(String(requestParameters.intentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.confirmDeleteIntentRequest,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async teamApiControllerConfirmDeleteIntent(requestParameters: TeamApiControllerConfirmDeleteIntentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.teamApiControllerConfirmDeleteIntentRaw(requestParameters, initOverrides);
    }

    /**
     */
    async teamApiControllerConfirmOwnershipTransferIntentRaw(requestParameters: TeamApiControllerConfirmOwnershipTransferIntentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling teamApiControllerConfirmOwnershipTransferIntent.');
        }

        if (requestParameters.intentId === null || requestParameters.intentId === undefined) {
            throw new runtime.RequiredError('intentId','Required parameter requestParameters.intentId was null or undefined when calling teamApiControllerConfirmOwnershipTransferIntent.');
        }

        if (requestParameters.confirmOwnershipTransferIntentRequest === null || requestParameters.confirmOwnershipTransferIntentRequest === undefined) {
            throw new runtime.RequiredError('confirmOwnershipTransferIntentRequest','Required parameter requestParameters.confirmOwnershipTransferIntentRequest was null or undefined when calling teamApiControllerConfirmOwnershipTransferIntent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/teams/{teamId}/ownershipTransferIntents/{intentId}:confirm`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"intentId"}}`, encodeURIComponent(String(requestParameters.intentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.confirmOwnershipTransferIntentRequest,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async teamApiControllerConfirmOwnershipTransferIntent(requestParameters: TeamApiControllerConfirmOwnershipTransferIntentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.teamApiControllerConfirmOwnershipTransferIntentRaw(requestParameters, initOverrides);
    }

    /**
     */
    async teamApiControllerCreateDeleteIntentRaw(requestParameters: TeamApiControllerCreateDeleteIntentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateDeleteIntentResponse>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling teamApiControllerCreateDeleteIntent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/teams/{teamId}/deleteIntents`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async teamApiControllerCreateDeleteIntent(requestParameters: TeamApiControllerCreateDeleteIntentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateDeleteIntentResponse> {
        const response = await this.teamApiControllerCreateDeleteIntentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async teamApiControllerCreateOwnershipTransferIntentRaw(requestParameters: TeamApiControllerCreateOwnershipTransferIntentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateTransferIntentResponse>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling teamApiControllerCreateOwnershipTransferIntent.');
        }

        if (requestParameters.createOwnershipTransferIntentRequest === null || requestParameters.createOwnershipTransferIntentRequest === undefined) {
            throw new runtime.RequiredError('createOwnershipTransferIntentRequest','Required parameter requestParameters.createOwnershipTransferIntentRequest was null or undefined when calling teamApiControllerCreateOwnershipTransferIntent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/teams/{teamId}/ownershipTransferIntents`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.createOwnershipTransferIntentRequest,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async teamApiControllerCreateOwnershipTransferIntent(requestParameters: TeamApiControllerCreateOwnershipTransferIntentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateTransferIntentResponse> {
        const response = await this.teamApiControllerCreateOwnershipTransferIntentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async teamApiControllerCreateTeamRaw(requestParameters: TeamApiControllerCreateTeamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateTimeResponse>> {
        if (requestParameters.createTeamRequest === null || requestParameters.createTeamRequest === undefined) {
            throw new runtime.RequiredError('createTeamRequest','Required parameter requestParameters.createTeamRequest was null or undefined when calling teamApiControllerCreateTeam.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/teams`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.createTeamRequest,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async teamApiControllerCreateTeam(requestParameters: TeamApiControllerCreateTeamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateTimeResponse> {
        const response = await this.teamApiControllerCreateTeamRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async teamApiControllerPatchTeamRaw(requestParameters: TeamApiControllerPatchTeamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling teamApiControllerPatchTeam.');
        }

        if (requestParameters.patchTeamRequest === null || requestParameters.patchTeamRequest === undefined) {
            throw new runtime.RequiredError('patchTeamRequest','Required parameter requestParameters.patchTeamRequest was null or undefined when calling teamApiControllerPatchTeam.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/teams/{teamId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.patchTeamRequest,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async teamApiControllerPatchTeam(requestParameters: TeamApiControllerPatchTeamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.teamApiControllerPatchTeamRaw(requestParameters, initOverrides);
    }

    /**
     */
    async teamApiControllerResendDeleteIntentEmailRaw(requestParameters: TeamApiControllerResendDeleteIntentEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling teamApiControllerResendDeleteIntentEmail.');
        }

        if (requestParameters.intentId === null || requestParameters.intentId === undefined) {
            throw new runtime.RequiredError('intentId','Required parameter requestParameters.intentId was null or undefined when calling teamApiControllerResendDeleteIntentEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/teams/{teamId}/deleteIntents/{intentId}:resendEmail`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"intentId"}}`, encodeURIComponent(String(requestParameters.intentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async teamApiControllerResendDeleteIntentEmail(requestParameters: TeamApiControllerResendDeleteIntentEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.teamApiControllerResendDeleteIntentEmailRaw(requestParameters, initOverrides);
    }

    /**
     */
    async teamApiControllerResendOwnershipTransferIntentEmailRaw(requestParameters: TeamApiControllerResendOwnershipTransferIntentEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling teamApiControllerResendOwnershipTransferIntentEmail.');
        }

        if (requestParameters.intentId === null || requestParameters.intentId === undefined) {
            throw new runtime.RequiredError('intentId','Required parameter requestParameters.intentId was null or undefined when calling teamApiControllerResendOwnershipTransferIntentEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/teams/{teamId}/ownershipTransferIntents/{intentId}:resendEmail`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"intentId"}}`, encodeURIComponent(String(requestParameters.intentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async teamApiControllerResendOwnershipTransferIntentEmail(requestParameters: TeamApiControllerResendOwnershipTransferIntentEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.teamApiControllerResendOwnershipTransferIntentEmailRaw(requestParameters, initOverrides);
    }

    /**
     */
    async teamApiControllerUpdateAvatarRaw(requestParameters: TeamApiControllerUpdateAvatarRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateTeamIconResponse>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling teamApiControllerUpdateAvatar.');
        }

        if (requestParameters.updateTeamIconRequest === null || requestParameters.updateTeamIconRequest === undefined) {
            throw new runtime.RequiredError('updateTeamIconRequest','Required parameter requestParameters.updateTeamIconRequest was null or undefined when calling teamApiControllerUpdateAvatar.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/teams/{teamId}:updateIcon`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.updateTeamIconRequest,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async teamApiControllerUpdateAvatar(requestParameters: TeamApiControllerUpdateAvatarRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateTeamIconResponse> {
        const response = await this.teamApiControllerUpdateAvatarRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
