import { useEffect, useState } from 'react'
import { useCommentVisibilityPreferenceQuery, useOnPreferenceUpdatedSubscription } from '../generated/graphql'
import { useProfile } from '../providers/ProfileProvider'

export const useCommentVisibility = (fileId: string) => {
  const profile = useProfile()
  const [isCommentVisibleByFile, setIsCommentVisibleByFile] = useState<Map<string, boolean>>(new Map())
  const { data, loading } = useCommentVisibilityPreferenceQuery({
    variables: { id: profile.id }
  })

  useEffect(() => {
    if (!loading) {
      const commentVisibility = data?.user_preferences_by_pk?.value
      if (commentVisibility !== null && typeof commentVisibility === 'object' && !Array.isArray(commentVisibility)) {
        const commentVisibilityMap = new Map<string, boolean>(Object.entries(commentVisibility))
        if (!commentVisibilityMap.has(fileId)) {
          commentVisibilityMap.set(fileId, true)
        }
        setIsCommentVisibleByFile(commentVisibilityMap)
      } else {
        const commentVisibilityMap = new Map()
        commentVisibilityMap.set(fileId, true)
        setIsCommentVisibleByFile(commentVisibilityMap)
      }
    }
  }, [fileId, data, loading])

  const { data: updatedData, loading: subscriptionLoading } = useOnPreferenceUpdatedSubscription({
    variables: { id: profile.id, key: 'comment-visibility' },
    skip: !profile.id
  })

  useEffect(() => {
    if (!subscriptionLoading && updatedData?.user_preferences && updatedData.user_preferences.length > 0) {
      const updatedVisibility = updatedData?.user_preferences[0].value
      if (!updatedVisibility) return

      const updatedVisibilityMap = new Map<string, boolean>(Object.entries(updatedVisibility))
      if (!updatedVisibilityMap.has(fileId)) {
        updatedVisibilityMap.set(fileId, true)
      }
      setIsCommentVisibleByFile(updatedVisibilityMap)
    }
  }, [fileId, updatedData, subscriptionLoading])

  return { isCommentVisibleByFile, setIsCommentVisibleByFile }
}
