import { useCallback, useMemo } from 'react'
import { capitalize } from 'lodash'
import { schema } from 'normalizr'
import { createProvider, useEntity } from '../utils'
import { Paint, useSetPaint } from './PaintProvider'
import { useDataStore } from './DataStoreProvider'

export const Layer = new schema.Entity(
  'layers',
  {
    paint: Paint
  },
  {
    processStrategy: (value, parent) => {
      return { ...value, parentId: parent.id }
    }
  }
)

const defaultValue = {}
const [Provider, useSelectState, useSetState, getStateSnapshot] = createProvider('Layer', defaultValue)

export const useLayer = useSelectState
export const getLayerSnapshot = getStateSnapshot
export const useSetLayer = () => {
  const { createPaint, destroyPaint } = useSetPaint()
  const setState = useSetState()
  const [setLayer, addLayer, updateLayer, removeLayer, mergeLayer, reduceLayer, create, destroy] = useEntity(
    Layer,
    setState
  )

  const createLayer = useCallback(
    (entities) => {
      createPaint(entities)
      create(entities)
    },
    [createPaint, create]
  )

  const destroyLayer = useCallback(
    (entities) => {
      destroy(entities)
      destroyPaint(entities)
    },
    [destroyPaint, destroy]
  )

  return {
    setLayer,
    addLayer,
    updateLayer,
    removeLayer,
    mergeLayer,
    reduceLayer,
    createLayer,
    destroyLayer
  }
}

export const useLayerActions = (layerId) => {
  const dataStore = useDataStore()
  const $element = dataStore.selection.get('selected')[0]
  const $layer = useMemo(() => {
    return $element ? $element.selectedStyle.getLayerById(layerId) : {}
  }, [$element, layerId])

  const toggleLayerVisible = useCallback(
    (flag) => {
      $layer.visible = typeof flag === 'undefined' ? !$layer.visible : flag
    },
    [$layer]
  )
  const setLayerFocused = useCallback(
    (focused) => {
      dataStore.selection.set('focusedLayer', focused ? $layer : null)
    },
    [dataStore, $layer]
  )
  const setLayerOffset = useCallback(
    (offset) => {
      $layer.offset = offset
    },
    [$layer]
  )
  const setLayerWidth = useCallback(
    (width) => {
      $layer.width = width
    },
    [$layer]
  )
  const setLayerGrowDirection = useCallback(
    (growDirection) => {
      $layer.growDirection = growDirection
    },
    [$layer]
  )
  const setLayerCap = useCallback(
    (cap) => {
      $layer.cap = cap
    },
    [$layer]
  )
  const setLayerCapSize = useCallback(
    (capSize) => {
      $layer.capSize = capSize
    },
    [$layer]
  )
  const setLayerJoin = useCallback(
    (join) => {
      $layer.join = join
    },
    [$layer]
  )
  const setLayerJoinSize = useCallback(
    (joinSize) => {
      $layer.joinSize = joinSize
    },
    [$layer]
  )
  const setLayerMiter = useCallback(
    (miter) => {
      $layer.miter = miter
    },
    [$layer]
  )
  const setLayerEnds = useCallback(
    (ends) => {
      $layer.ends = ends
    },
    [$layer]
  )
  const setLayerDash = useCallback(
    (dash) => {
      if (!dash) {
        $layer.dash = []
      }
      $layer.dash = [dash]
      if (!$layer.gap[0]) {
        $layer.gap = [dash]
      }
    },
    [$layer]
  )
  const setLayerGap = useCallback(
    (gap) => {
      if (!gap) {
        $layer.gap = []
      }
      $layer.gap = [gap]
      if (!$layer.dash[0]) {
        $layer.dash = [gap]
      }
    },
    [$layer]
  )
  const setLayerPaint = useCallback(
    (paint) => {
      $layer.paint = paint
    },
    [$layer]
  )
  const setLayerPaintType = useCallback(
    (paintType) => {
      const isGradientPaint = !['SOLID', 'IMAGE'].includes(paintType)
      const paintCategory = isGradientPaint ? 'GRADIENT' : paintType
      const shouldChangePaintType = $layer.paint.paintType !== paintCategory
      if (shouldChangePaintType) {
        if ($layer.paintCache[paintCategory]) {
          setLayerPaint($layer.paintCache[paintCategory])
          if (isGradientPaint) {
            setLayerPaint({ gradientType: paintType })
          }
          return
        }
        const $paint = dataStore.createComponent(`${capitalize(paintCategory)}PaintComponent`)
        if (isGradientPaint) {
          $paint.set({ gradientType: paintType })
        }
        setLayerPaint($paint)
      } else {
        if (isGradientPaint) {
          setLayerPaint({ gradientType: paintType })
        } else {
          setLayerPaint({ paintType })
        }
      }
    },
    [dataStore, $layer, setLayerPaint]
  )
  const setLayerBlurType = useCallback(
    (blurType) => {
      $layer.blurType = blurType
    },
    [$layer]
  )
  const setLayerAmount = useCallback(
    (amount) => {
      $layer.amount = amount
    },
    [$layer]
  )
  const setLayerSaturation = useCallback(
    (saturation) => {
      $layer.saturation = saturation
    },
    [$layer]
  )
  const setLayerAngle = useCallback(
    (angle) => {
      $layer.angle = angle
    },
    [$layer]
  )
  const setLayerOffsetX = useCallback(
    (offsetX) => {
      $layer.offsetX = offsetX
    },
    [$layer]
  )
  const setLayerOffsetY = useCallback(
    (offsetY) => {
      $layer.offsetY = offsetY
    },
    [$layer]
  )
  const setLayerBlur = useCallback(
    (blur) => {
      $layer.blur = blur
    },
    [$layer]
  )
  const setLayerSpread = useCallback(
    (spread) => {
      $layer.spread = spread
    },
    [$layer]
  )
  return {
    toggleLayerVisible,
    setLayerFocused,
    setLayerPaint,
    setLayerPaintType,

    // stroke
    setLayerOffset,
    setLayerWidth,
    setLayerGrowDirection,
    setLayerCap,
    setLayerCapSize,
    setLayerDash,
    setLayerGap,
    setLayerJoin,
    setLayerJoinSize,
    setLayerMiter,
    setLayerEnds,

    // effect
    setLayerBlurType,
    setLayerAmount,
    setLayerSaturation,
    setLayerAngle,

    // shadow / inner shadow
    setLayerOffsetX,
    setLayerOffsetY,
    setLayerBlur,
    setLayerSpread
  }
}

export default Provider
