import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../../../components/shared'
import useFileActions from '../../../hooks/useFileActions'
import useImportFile from '../../../hooks/useImportFile'
import { useSetNotification } from '../../../providers/NotificationProvider'

const CreateFileButton = ({ projectId }: { projectId: string }) => {
  const { t } = useTranslation(['common', 'file'])
  const importErrorMessage = t('file:phase_enabled_import_error_message')

  const { addNotification } = useSetNotification()
  const { createFileByDataStore } = useFileActions()
  const { importWithNewPhaseFile } = useImportFile({ projectId, canImportPhase: true, importErrorMessage })

  const [loading, setLoading] = useState(false)

  const handleCreateFile = async () => {
    setLoading(true)
    try {
      const fileData = createFileByDataStore()
      importWithNewPhaseFile(fileData, t('common:untitled'))
    } catch (e: any) {
      console.error(e)

      addNotification({ content: e.message })
      setLoading(false)
    }
  }

  return (
    <Button
      icon="Plus"
      color="primary"
      size="l"
      data-test-id="new-file-button"
      onClick={handleCreateFile}
      disabled={loading}
    >
      {t('new')}
    </Button>
  )
}

export default CreateFileButton
