import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useDataStoreActions } from '../../providers/dataStore/DataStoreProvider'
import { useUI } from '../../providers/dataStore/UIProvider'
import ShareFileDialog from '../Share/ShareFileDialog'
import { Button } from '../shared'

type ShareButtonProps = {
  projectId: string
  fileId: string
}

const ShareButton = ({ projectId, fileId }: ShareButtonProps) => {
  const [shareDialogOpen, setShareDialogOpen] = useState(false)
  const { editOrigin } = useUI()
  const { setFeature } = useDataStoreActions()
  const { t } = useTranslation('common')

  const handleShareDialogClose = (bool: boolean) => {
    if (bool && editOrigin) {
      setFeature('editOrigin', false)
      setShareDialogOpen(bool)
    } else {
      setShareDialogOpen(bool)
    }
  }

  return (
    <>
      <Button
        size="l"
        variant="solid"
        color="secondary"
        data-test-id="editor-top-tool-share-button"
        onClick={() => handleShareDialogClose(true)}
      >
        {t('button.share')}
      </Button>
      <ShareFileDialog
        projectId={projectId}
        fileId={fileId}
        open={shareDialogOpen}
        onClose={() => handleShareDialogClose(false)}
      />
    </>
  )
}

export default ShareButton
