import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import {
  // FIXME: PASTE_HERE_OPTION
  //    Temporary hide this option, requested by design team
  //    This is due to the behavior of paste here is not clearly defined
  // PASTE_HERE_OPTION,
  PASTE_OPTION,
  SELECT_ALL_OPTION,
  SHOW_HIDE_INTERFACE_OPTION,
  SHOW_HIDE_ORIGIN_OPTION,
  TOOLBAR_OPTIONS
} from '../../constants/fileEditorConstants'
import { useModal, useSetModal } from '../../providers/ModalProvider'
import { useDataStoreActions } from '../../providers/dataStore/DataStoreProvider'
import { useUIActions } from '../../providers/dataStore/UIProvider'
import { ContextMenu } from '../shared'
import { MenuOptionProps } from '../shared/Menu/Menu.types'
import { translateMenuOptions } from '../shared/Menu/utils'

const KEY = 'CanvasContextMenu'

const CANVAS_CONTEXT_MENU_OPTIONS = [
  PASTE_OPTION,
  // FIXME: PASTE_HERE_OPTION
  //    Temporary hide this option, requested by design team
  //    This is due to the behavior of paste here is not clearly defined
  // { ...PASTE_HERE_OPTION, disabled: disablePaste },
  SELECT_ALL_OPTION,
  SHOW_HIDE_INTERFACE_OPTION,
  SHOW_HIDE_ORIGIN_OPTION
]

const CanvasContextMenu = () => {
  const { t } = useTranslation('file')
  const {
    open,
    data: { mousePos: canvasMousePos, canvasPosition }
  } = useModal((o: any) => o[KEY])
  const { closeModal } = useSetModal(KEY)
  const { pasteToElementList, selectAll } = useDataStoreActions()
  const { toggleInterface, toggleOrigin } = useUIActions()

  const handleSelect = (option: MenuOptionProps) => {
    switch (option?.value) {
      case TOOLBAR_OPTIONS.PASTE:
        pasteToElementList(null)
        break
      case TOOLBAR_OPTIONS.PASTE_HERE:
        pasteToElementList(canvasMousePos)
        break
      case TOOLBAR_OPTIONS.SELECT_ALL:
        selectAll()
        break
      case TOOLBAR_OPTIONS.TOGGLE_INTERFACE:
        toggleInterface()
        break
      case TOOLBAR_OPTIONS.TOGGLE_ORIGIN:
        toggleOrigin()
        break
      default:
        break
    }
  }

  const cursorPosition = useMemo(() => {
    if (!canvasMousePos || !canvasPosition) return
    return {
      top: canvasMousePos.y + canvasPosition?.top,
      left: canvasMousePos.x + canvasPosition?.left
    }
  }, [canvasMousePos, canvasPosition])

  const translatedOptions = useMemo(() => translateMenuOptions(t, CANVAS_CONTEXT_MENU_OPTIONS), [t])

  return (
    <ContextMenu
      open={open}
      options={translatedOptions}
      onSelect={handleSelect}
      onClose={closeModal}
      checkedOpts={[]}
      cursorPosition={cursorPosition}
    />
  )
}

export default React.memo(CanvasContextMenu)
