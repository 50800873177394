import { Events } from '@phase-software/data-store'
import { DirectionType } from '@phase-software/types'
import { UtfString } from 'utfstring'
import { Vector2, Num } from '../math'

/** @typedef {import('@phase-software/data-store/src/Element').Element} Element */
/** @typedef {import('../resources/TextResource').GlyphLine} GlyphLine */
/** @typedef {import('../resources/TextResource').Glyph} Glyph */
/** @typedef {import('../Viewport').Viewport} Viewport */
/** @typedef {import('../resources/TextResource').TextResource} TextResource */
/** @typedef {import('../visual_server/VisualServer').VisualServer} VisualServer */
/** @typedef {import('../visual_server/RenderItem').RenderItem} RenderItem */

/** @param {VisualServer} visualServer */
export function initText(visualServer) {
    const dataStore = visualServer.dataStore
    /** @type {Text} */
    let _text = null

    dataStore.eam.on(Events.ACTIVATE_TEXT_MODE, () => {
        // const single = visualServer.selection.single
        // const oRes = visualServer.storage.getVectorResource(`o-${single.id}`)
        // if (oRes.data.type === 'ORIGINAL' && oRes.data.options.type === 'TEXT') {
        //     _text = new Text(single.element, single.node, oRes.data.options.resource, visualServer.viewport)
        //     setText(_text)
        // } else {
        //     console.error('Original Vector Resource Not Found!')
        // }
    })


    dataStore.eam.on(Events.DEACTIVATE_TEXT_MODE, () => {
        _text.removeInput()
        _text = null
    })

    const exit = () => {
        dataStore.eam.activateElementEditMode()
    }

    dataStore.eam.on(Events.TEXT_INSERT_NEW_LINE, () => _text.changeCharacter('\n'))
    dataStore.eam.on(Events.SELECT_ALL_TEXT, () => {
        _text.selectAll()
    })

    dataStore.eam.on(Events.CHANGE_TEXT_CARET, (e, { shift }) => {
        const start = _text.first
        const end = _text.last
        const index = _text.getClosestIndexToPos(e.mousePos)
        if (index === null) {
            e.handled = false
            exit()
        } else {
            if (shift) {
                _text.expandSelection(start, end, index)
            } else {
                _text.moveTo(index)
            }
        }
    })

    {
        let start
        let end
        dataStore.eam
            .on(Events.START_MOVE_TEXT_CARET, (e, { shift }) => {
                start = _text.first
                end = _text.last

                const index = _text.getClosestIndexToPos(e.mousePos)
                if (index === null) {
                    e.handled = false
                    exit()
                } else {
                    if (shift) {
                        _text.expandSelection(start, end, index)
                    } else {
                        _text.moveTo(index)
                    }
                }
            })
            .on(Events.UPDATE_MOVE_TEXT_CARET, (e, { shift }) => {
                const index = _text.getClosestIndexToPos(e.mousePos, false)
                if (shift) {
                    _text.expandSelection(start, end, index)
                } else {
                    _text.select(index, _text.opposite)
                }
            })
            .on(Events.END_MOVE_TEXT_CARET, () => { })
    }

    // Don't allow LeftClickUp to passtrough and trigger DESELECT
    // IS.get('BLOCK_DESELECT')
    //     .on('trigger', () => {})

    dataStore.eam.on(Events.MOVE_TEXT_CARET_KEY, (dir) => {
        switch (dir) {
            case DirectionType.LEFT:
                _text.moveTo(_text.isSelected ? _text.first : _text.caret - 1)
                break
            case DirectionType.RIGHT:
                _text.moveTo(_text.isSelected ? _text.last : _text.caret + 1)
                break
            case DirectionType.UP:
                _text.moveTo(_text.getCharacterIndexAboveCaret(), false)
                break
            case DirectionType.DOWN:
                _text.moveTo(_text.getCharacterIndexBelowCaret(), false)
                break
        }
    })
    dataStore.eam.on(Events.MOVE_TEXT_CARET_TO_NEXT_WORD_KEY, (dir) => {
        switch (dir) {
            case DirectionType.LEFT:
                _text.moveTo(_text.getStartOfPrevWord())
                break
            case DirectionType.RIGHT:
                _text.moveTo(_text.getEndOfNextWord())
                break
            case DirectionType.UP:
                break
            case DirectionType.DOWN:
                break
        }
    })
    dataStore.eam.on(Events.EXPAND_TEXT_SELECTION_KEY, (dir) => {
        switch (dir) {
            case DirectionType.LEFT:
                _text.select(_text.caret - 1, _text.opposite)
                break
            case DirectionType.RIGHT:
                _text.select(_text.caret + 1, _text.opposite)
                break
            case DirectionType.UP:
                _text.select(_text.getCharacterIndexAboveCaret(), _text.opposite, false)
                break
            case DirectionType.DOWN:
                _text.select(_text.getCharacterIndexBelowCaret(), _text.opposite, false)
                break
        }
    })
    dataStore.eam.on(Events.EXPAND_TEXT_SELECTION_TO_NEXT_WORD_KEY, (dir) => {
        switch (dir) {
            case DirectionType.LEFT:
                _text.select(_text.getStartOfPrevWord(), _text.opposite)
                break
            case DirectionType.RIGHT:
                _text.select(_text.getEndOfNextWord(), _text.opposite)
                break
            case DirectionType.UP:
                break
            case DirectionType.DOWN:
                break
        }
    })

    dataStore.eam.on(Events.MOVE_TEXT_CARET_TO_LINE_START, (dir) => {
        switch (dir) {
            case DirectionType.START:
                _text.moveTo(_text.getStartOfCurrentLine())
                break
            case DirectionType.END:
                _text.moveTo(_text.getEndOfCurrentLine())
                break
        }
    })
    dataStore.eam.on(Events.MOVE_TEXT_CARET_TO_TEXT_START, (dir) => {
        switch (dir) {
            case DirectionType.START:
                _text.moveTo(0)
                break
            case DirectionType.END:
                _text.moveTo(_text.getMaxLength())
                break
        }
    })
    dataStore.eam.on(Events.EXPAND_TEXT_SELECTION_TO_LINE_START, (dir) => {
        switch (dir) {
            case DirectionType.START:
                _text.select(_text.getStartOfCurrentLine(), _text.opposite)
                break
            case DirectionType.END:
                _text.select(_text.getEndOfCurrentLine(), _text.opposite)
                break
        }
    })
    dataStore.eam.on(Events.EXPAND_TEXT_SELECTION_TO_TEXT_START, (dir) => {
        switch (dir) {
            case DirectionType.START:
                _text.select(0, _text.opposite)
                break
            case DirectionType.END:
                _text.select(_text.getMaxLength(), _text.opposite)
                break
        }
    })

    dataStore.eam.on(Events.DELETE_TEXT, (eventData, { modifier }) => {
        if (modifier) {
            _text.replaceCharacters(_text.getStartOfPrevWord(), _text.caret)
        } else {
            _text.changeCharacter('', 1)
        }
    })
}

const BREAK_CHARACTERS = [' ', '\n', '.', ',']

export class Text {
    /**
     * @param {Element} element
     * @param {RenderItem} node
     * @param {TextResource} resource
     * @param {Viewport} viewport
     */
    constructor(element, node, resource, viewport) {
        this.element = element
        this.node = node
        this.resource = resource
        this.viewport = viewport

        this.caret = 0
        this.opposite = 0

        /** @type {null|number} - null means recalculate */
        this.lastX = null

        this.selectAll()

        /** @type {() => void} */
        this.removeInput = this.setupInput()
    }

    get glyphLines() {
        return this.resource.getData().glyphLines || []
    }

    /**
     * whether any text is selected
     * @returns {boolean}
     */
    get isSelected() {
        return this.caret !== this.opposite
    }

    /**
     * first index of selection
     * @returns {number}
     */
    get first() {
        return Math.min(this.caret, this.opposite)
    }

    /**
     * last index of selection
     * @returns {number}
     */
    get last() {
        return Math.max(this.caret, this.opposite)
    }

    setupInput() {
        const input = document.createElement('input')
        input.id = 'renderer-text-input'
        input.classList.add('input-system-handle-event')

        let _isComposing = false
        let start
        let end
        const inputChange = () => {
            if (input.value) {
                if (_isComposing) {
                    this.replaceCharacters(start, end, input.value)
                    end = this.caret
                } else {
                    this.changeCharacter(input.value)
                    input.value = ''
                }
            }
        }

        const setPos = () => {
            if (!_isComposing) return

            const glyph = this.getGlyphAt(this.caret)
            const x = glyph.x + this.getGlyphOffset(this.caret, glyph)
            const y = glyph.bottom

            const v = new Vector2(x, y)
            const T = this.node.transform.world.clone()
                .prepend(this.viewport.projectionTransform)
            T.xform(v, v)

            input.style.setProperty('--x', `${v.x}px`)
            input.style.setProperty('--y', `${v.y}px`)
        }
        this.viewport.on('update', setPos)

        const compositionStart = () => {
            _isComposing = true
            setPos()

            start = this.first
            end = this.last
        }
        const compositionEnd = () => {
            _isComposing = false
            input.value = ''
        }
        input.addEventListener('input', inputChange)
        input.addEventListener('compositionstart', compositionStart)
        input.addEventListener('compositionend', compositionEnd)

        const focusInputEl = () => input.focus({ preventScroll: true })
        document.getElementById('renderer-container').appendChild(input)

        // keep the input element focused while editing
        input.addEventListener('blur', focusInputEl)
        focusInputEl()

        return () => {
            this.viewport.off('update', setPos)
            input.removeEventListener('blur', focusInputEl)
            input.remove()
        }
    }

    /**
     * @param {number} index
     * @returns {Glyph}
     */
    getGlyphAt(index) {
        if (index === this.getMaxLength()) {
            const lastLine = this.glyphLines[this.glyphLines.length - 1]
            return lastLine.glyphs[lastLine.glyphs.length - 1]
        } else {
            for (const glyphLine of this.glyphLines) {
                for (const glyph of glyphLine.glyphs) {
                    const lastIndex = glyph.index + glyph.length - 1
                    if (index <= lastIndex) return glyph
                }
            }
        }
    }

    /**
     * @param {number} index
     * @param {Glyph} glyph
     * @returns {number}
     */
    getGlyphOffset(index, glyph) {
        // TODO: ligatures can be composed of different character widths
        // consider modifying this calculation to take that into account
        return (index - glyph.index) * (glyph.width / glyph.length)
    }

    /**
     * @param {number} index
     * @param {Glyph} glyph
     * @returns {boolean}
     */
    isIndexPartOfGlyph(index, glyph) {
        const lastIndex = glyph.index + glyph.length - 1
        return glyph.index <= index && index <= lastIndex
    }

    /**
     * @returns {number} column (x-coordinate) used when moving up and down
     */
    getX() {
        if (this.lastX !== null) return this.lastX

        const glyphs = this.getCurrentGlyphLine().glyphs
        for (const glyph of glyphs) {
            if (glyph.index === this.caret - 1) {
                this.lastX = glyph.x + glyph.width
                return this.lastX
            }
        }

        this.lastX = 0
        return this.lastX
    }

    /**
     * @param {number} pos
     * @param {boolean} [recalculateX=true] - pass in false to maintain same X value
     */
    moveTo(pos, recalculateX = true) {
        const length = this.getMaxLength()
        const _pos = Num.clamp(pos, 0, length)

        this.caret = _pos
        this.opposite = _pos

        if (recalculateX) {
            this.lastX = null
        }
    }

    getEndOfNextWord() {
        const length = this.getMaxLength()
        const text = this.element.get('text')
        const original = this.caret
        const safeString = new UtfString(text)
        let pos = this.caret

        while (
            pos < length &&
            (original === pos || !BREAK_CHARACTERS.includes(safeString.charAt(pos)))
        ) {
            pos++
        }

        return pos
    }

    getStartOfPrevWord() {
        const text = this.element.get('text')
        const original = this.caret
        const safeString = new UtfString(text)
        let pos = this.caret

        while (
            pos > 0 &&
            (original === pos || !BREAK_CHARACTERS.includes(safeString.charAt(pos - 1)))
        ) {
            pos--
        }

        return pos
    }

    /**
     * @param {Vector2} pos - in screen space
     * @param {boolean} [constrainToBounds=true]
     * @returns {null|number} returns null if `constrainToBounds === true` and pos is outside bounds
     */
    getClosestIndexToPos(pos, constrainToBounds = true) {
        const T = this.node.transform.worldInv
            .clone()
            .append(this.viewport.invProjectionTransform)

        const v = T.xform(pos)

        const out = constrainToBounds && !this.node.bounds.rect.has_point(v)
            // const out = constrainToBounds && !this.node.itemBounds.has_point(v)
            ? null
            : this.findClosestCharacter(v.x, v.y)

        return out
    }

    /** @returns {number} */
    getMaxLength() {
        const safeString = new UtfString(this.element.get('text'))
        return safeString.length
    }

    /** @returns {number} index */
    getStartOfCurrentLine() {
        const glyphs = this.getCurrentGlyphLine().glyphs
        return glyphs[0].index
    }

    /** @returns {number} index */
    getEndOfCurrentLine() {
        const glyphs = this.getCurrentGlyphLine().glyphs
        return glyphs[glyphs.length - 1].index
    }

    /** @returns {number} */
    getCurrentGlyphLineIndex() {
        for (let i = 0; i < this.glyphLines.length; i++) {
            for (const glyph of this.glyphLines[i].glyphs) {
                if (glyph.index === this.caret) {
                    return i
                }
            }
        }
        return this.glyphLines.length - 1
    }

    /** @returns {GlyphLine} */
    getCurrentGlyphLine() {
        for (const glyphLine of this.glyphLines) {
            for (const glyph of glyphLine.glyphs) {
                if (glyph.index === this.caret) {
                    return glyphLine
                }
            }
        }
        return this.glyphLines[this.glyphLines.length - 1]
    }

    /**
     * @param {GlyphLine} glyphLine
     * @param {number} [x]
     * @returns {number} index
     */
    findClosestCharacterInLine(glyphLine, x = this.getX()) {
        let closestCharIndex = 0
        let distance = Infinity
        for (const glyph of glyphLine.glyphs) {
            const delta = Math.abs(glyph.x - x)
            if (delta < distance) {
                distance = delta
                closestCharIndex = glyph.index

                // if last character => maybe add 1 to index based on distance
                if (closestCharIndex === this.getMaxLength() - 1) {
                    const delta = Math.abs((glyph.x + glyph.width) - x)
                    if (delta < distance) {
                        closestCharIndex++
                    }
                }
            }
        }
        return closestCharIndex
    }

    /**
     * @param {number} x
     * @param {number} y
     * @returns {number}
     */
    findClosestCharacter(x, y) {
        /** @type {GlyphLine} */
        let line
        let distance = Infinity
        for (const glyphLine of this.glyphLines) {
            const yGlyph = glyphLine.glyphs[0].y
            const delta = Math.abs(yGlyph - y)
            if (delta < distance) {
                distance = delta
                line = glyphLine
            }
        }
        if (!line) return 0
        return this.findClosestCharacterInLine(line, x)
    }

    /** @returns {number} */
    getCharacterIndexBelowCaret() {
        const index = this.getCurrentGlyphLineIndex()
        if (index === this.glyphLines.length - 1) return this.getMaxLength()
        return this.findClosestCharacterInLine(this.glyphLines[index + 1])
    }

    /** @returns {number} */
    getCharacterIndexAboveCaret() {
        const index = this.getCurrentGlyphLineIndex()
        if (index === 0) return 0
        return this.findClosestCharacterInLine(this.glyphLines[index - 1])
    }

    /**
     * replaces characters
     * @param {number} start
     * @param {number} end
     * @param {string} [replacement]
     */
    replaceCharacters(start, end, replacement = '') {
        const length = this.getMaxLength()
        const s = Num.clamp(start, 0, length)
        const e = Num.clamp(end, 0, length)

        const text = this.element.get('text')
        const safeString = new UtfString(text)
        let _text = safeString.slice(0, s) + replacement + safeString.slice(s, e)
        // TODO: figgure out how to not add a newline here
        // (right now it's needed because to draw the caret we need at least one glyph)
        const newSafeString = new UtfString(_text)
        if (newSafeString.length === 0) _text += '\n'
        this.element.set('text', _text)

        const replacementSafeString = new UtfString(replacement)
        this.moveTo(s + replacementSafeString.length)
    }

    /**
     * @param {string} key
     * @param {number} [offset]
     */
    changeCharacter(key, offset = 0) {
        if (this.isSelected) {
            this.replaceCharacters(this.first, this.last, key)
        } else {
            this.replaceCharacters(this.caret - offset, this.caret, key)
        }
    }

    selectAll() {
        this.select(this.getMaxLength(), 0)
    }

    /**
     * set selection with numbers between index1 and index2 (in any order)
     *
     * caret will be moved to index1
     * @param {number} index1
     * @param {number} index2
     * @param {boolean} [recalculateX=true] - pass in false to maintain same X value
     */
    select(index1, index2, recalculateX = true) {
        const length = this.getMaxLength()
        const a = Num.clamp(index1, 0, length)
        const b = Num.clamp(index2, 0, length)

        this.caret = a
        this.opposite = b

        if (recalculateX) {
            this.lastX = null
        }
    }

    /**
     * @param {number} oldStart
     * @param {number} oldEnd
     * @param {number} pos
     */
    expandSelection(oldStart, oldEnd, pos) {
        const s = Math.min(oldStart, pos)
        const e = Math.max(oldEnd, pos)
        const isFirst = pos - s < e - pos
        this.select(isFirst ? s : e, isFirst ? e : s)
    }
}

document.addEventListener("DOMContentLoaded", () => {
    const style = document.createElement('style')
    style.innerHTML = `
    #renderer-text-input {
        --x: 0;
        --y: 0;
        position: absolute;
        z-index: 10;
        left: 0;
        top: 0;
        width: 0;
        height: 0;
        transform: translate(var(--x), var(--y));
        overflow: hidden;
        border-top-style: hidden;
        border-right-style: hidden;
        border-left-style: hidden;
    }
    `
    document.body.appendChild(style)
})
