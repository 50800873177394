import React, { useCallback, useMemo } from 'react'

import { LayerType } from '@phase-software/types'
import PropTypes from 'prop-types'

import { useDataStoreActions } from '../../../../providers/dataStore/DataStoreProvider'
import { useEditorActions } from '../../../../providers/dataStore/EditorProvider'
import { useStroke } from '../../../../providers/dataStore/StrokeProvider'
import { parseToDecimals } from '../../../../utils/number'
import { commaSeparatedValidator } from '../../../../utils/validator'
import { Input } from '../../../shared'

const formatter = (value: string) => {
  return getValues(value)
    .map((v) => parseToDecimals(v, 2))
    .join(', ')
}

const getValues = (value: string) => {
  const newDashes = value.split(',')
  const result = []
  for (let i = 0; i < newDashes.length; i++) {
    const v = parseFloat(newDashes[i].trim()) || 0
    result.push(v < 0 ? 0 : v)
  }

  return result
}
type DashInputProps = {
  layerItemId: string
}

const DashInput = ({ layerItemId, ...rest }: DashInputProps) => {
  const dash = useStroke((o) => o[layerItemId].dash)
  const { setLayerDash, toggleLayerVisible } = useEditorActions()
  const { commitUndo } = useDataStoreActions()

  const handleChange = useCallback(
    (value: string) => {
      if (commaSeparatedValidator(value)) {
        return
      }

      const newDashes = getValues(value)
      setLayerDash(layerItemId, newDashes)
      commitUndo()
    },
    [setLayerDash, commitUndo, layerItemId]
  )

  const _dash = dash.length ? dash.map((v: number) => (v < 0 ? 0 : parseToDecimals(v, 2))).join(', ') : '0'

  const keyFrameIconProps = useMemo(
    () => ({
      layerItemId,
      name: 'dash',
      layerType: LayerType.STROKE
    }),
    [layerItemId]
  )

  return (
    <Input
      // @ts-ignore TODO: fix after refactor of Input
      variant="normal"
      keyFrameIconProps={keyFrameIconProps}
      name="dash"
      data-test-id="border-dash-input"
      formatter={formatter}
      value={_dash}
      validator={commaSeparatedValidator}
      onChange={handleChange}
      onFocus={() => toggleLayerVisible(layerItemId, true)}
      {...rest}
    />
  )
}
DashInput.propTypes = {
  layerItemId: PropTypes.string.isRequired
}

export default React.memo(DashInput)
