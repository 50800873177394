import * as runtime from '../generated/api/runtime'
import { ProjectFileControllerImportImageV1Request } from '../generated/api/apis/ProjectFileApi'
import { ImportProjectImageResponse } from '../generated/api/models'

// because of openapi-codegen bug, so we extract the import image endpoint to here
// ref: https://github.com/OpenAPITools/openapi-generator/issues/9850
export class ProjectFileImageApi extends runtime.BaseAPI {
  async projectFileControllerImportImageV1Raw(
    requestParameters: ProjectFileControllerImportImageV1Request,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ImportProjectImageResponse>> {
    if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
      throw new runtime.RequiredError(
        'projectId',
        'Required parameter requestParameters.projectId was null or undefined when calling projectFileControllerImportImageV1.'
      )
    }

    if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
      throw new runtime.RequiredError(
        'fileId',
        'Required parameter requestParameters.fileId was null or undefined when calling projectFileControllerImportImageV1.'
      )
    }

    if (requestParameters.content === null || requestParameters.content === undefined) {
      throw new runtime.RequiredError(
        'content',
        'Required parameter requestParameters.content was null or undefined when calling projectFileControllerImportImageV1.'
      )
    }

    if (requestParameters.name === null || requestParameters.name === undefined) {
      throw new runtime.RequiredError(
        'name',
        'Required parameter requestParameters.name was null or undefined when calling projectFileControllerImportImageV1.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('bearer', [])

      if (tokenString) {
        headerParameters.Authorization = `Bearer ${tokenString}`
      }
    }

    const formParams = new FormData()

    if (requestParameters.content !== undefined) {
      formParams.append('content', requestParameters.content as any)
    }

    if (requestParameters.name !== undefined) {
      formParams.append('name', requestParameters.name as any)
    }

    const response = await this.request(
      {
        path: `/api/v1/projects/{projectId}/files/{fileId}/images:import`
          .replace(`{${'projectId'}}`, encodeURIComponent(String(requestParameters.projectId)))
          .replace(`{${'fileId'}}`, encodeURIComponent(String(requestParameters.fileId))),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response)
  }

  async projectFileControllerImportImageV1(
    requestParameters: ProjectFileControllerImportImageV1Request,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ImportProjectImageResponse> {
    const response = await this.projectFileControllerImportImageV1Raw(requestParameters, initOverrides)
    return await response.value()
  }
}
