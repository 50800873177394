import React, { useMemo } from 'react'

import { atom, useAtom, useAtomValue } from 'jotai'

import {
  DragAction,
  activeKeyframeAtom,
  activeKeyframeHandleAtom,
  dragActionAtom,
  modifierKeysAtom,
  selectedKeyframeTimeRangeAtom
} from '../../../atoms'
import { ReactComponent as StretchLeft } from '../svgs/StretchLeft.svg'
import { ReactComponent as StretchRight } from '../svgs/StretchRight.svg'

type KeyframeStretchHandleProps = {
  dir: 'left' | 'right'
  time: number
  onDrag: (e: React.MouseEvent<HTMLDivElement>) => void
}

const useHandles = (time: number, dir: string) => {
  const [result] = useAtom(
    useMemo(
      () =>
        atom((get) => {
          const timeRange = get(selectedKeyframeTimeRangeAtom)
          const activeKeyframe = get(activeKeyframeAtom)
          const reversed =
            (activeKeyframe.handle === 'left' && activeKeyframe.time === timeRange.end) ||
            (activeKeyframe.handle === 'right' && activeKeyframe.time === timeRange.start)
          const active = time === activeKeyframe.time && activeKeyframe.handle === dir
          const isScaleToEdge =
            (activeKeyframe.handle === 'right' || activeKeyframe.handle === 'left') && timeRange.start === timeRange.end
          const showLeftHandle =
            (time === timeRange.start && !reversed) || (time === timeRange.end && reversed) || isScaleToEdge
          const showRightHandle =
            (time === timeRange.end && !reversed) || (time === timeRange.start && reversed) || isScaleToEdge
          const showHandle = (dir === 'left' && showLeftHandle) || (dir === 'right' && showRightHandle)
          return showHandle ? active : null
        }),
      [time, dir]
    )
  )
  return result
}

export const KeyframeStretchHandle = ({ dir, time, onDrag }: KeyframeStretchHandleProps) => {
  const active = useHandles(time, dir)
  const dragAction = useAtomValue(dragActionAtom)
  const modifierKeys = useAtomValue(modifierKeysAtom)
  const [activeKeyframeHandle, setActiveKeyframeHandle] = useAtom(activeKeyframeHandleAtom)

  if (
    activeKeyframeHandle === 'keyframe' ||
    (dragAction === DragAction.NONE && modifierKeys.size !== 0) ||
    dragAction === DragAction.SELECT ||
    active === null
  ) {
    return null
  }

  const position = dir === 'left' ? 'right-full' : 'left-full'
  const color = `${active ? 'text-white' : 'text-neutral-40'} hover:text-white`

  return (
    <div
      className={`cursor-[ew-resize] absolute ${position} ${color} z-10`}
      data-dir={dir}
      onMouseDown={onDrag}
      onMouseEnter={() => setActiveKeyframeHandle(dir)}
      onMouseLeave={() => setActiveKeyframeHandle(null)}
    >
      {dir === 'left' ? <StretchLeft /> : <StretchRight />}
    </div>
  )
}
