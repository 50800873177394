import { InteractionEntityType } from '@phase-software/types'

import { getBaseValue, getSegmentList, mergeSegmentList } from '../../utils/interaction'

export const getInteractionData = (interaction, dataStore) => {
  const { id, actionList, entityMap } = interaction.data
  const map = new Map(entityMap)

  // generate segment list
  const parentTrackList = []
  const elementTrackList = []
  map.forEach((entity, id) => {
    if (entity.type === InteractionEntityType.PROPERTY_TRACK) {
      if (entity.keyFrameList.length) {
        const elementTrack = dataStore.interaction.getElementTrack(entity.elementTrackId)
        if (!elementTrackList.includes(entity.elementTrackId)) {
          elementTrackList.push(entity.elementTrackId)
        }
        const baseValue = getBaseValue(elementTrack, entity.key, dataStore)

        const kfIdList = dataStore.interaction.getKeyFrameList(id)
        const kfs = kfIdList.map((kfId) => dataStore.interaction.getKeyFrame(kfId))

        const segmentList = getSegmentList(kfs, baseValue)
        map.set(id, { ...entity, segmentList })

        if (entity.parentId && !parentTrackList.includes(entity.parentId)) {
          parentTrackList.push(entity.parentId)
        }
      }
    }
  })

  // TODO: bottom-up aggregate segments
  // update parent track segments
  while (parentTrackList.length) {
    const trackId = parentTrackList.shift()
    const track = map.get(trackId)
    const elementTrack = map.get(track.elementTrackId)

    let segmentList = []
    track.children.forEach((key) => {
      const childTrack = map.get(elementTrack.propertyTrackMap.get(key))
      segmentList = segmentList.concat(childTrack.segmentList)
    })
    segmentList = mergeSegmentList(segmentList)

    map.set(trackId, { ...track, segmentList, expanded: false })

    if (track.parentId && !parentTrackList.includes(track.parentId)) {
      parentTrackList.push(track.parentId)
    }
  }

  // TODO: bottom-up aggregate segments
  // update element track segments
  elementTrackList.forEach((elementTrackId) => {
    const track = map.get(elementTrackId)

    let segmentList = []
    track.propertyTrackMap.forEach((trackId) => {
      segmentList = segmentList.concat(map.get(trackId).segmentList)
    })
    segmentList = mergeSegmentList(segmentList)

    map.set(elementTrackId, { ...track, segmentList, expanded: false })
  })

  return {
    id,
    actionList: actionList.slice(),
    entityMap: map
  }
}
