import Interval from '../../../helpers/Interval'
import PropertyStack from '../../PropertyStack'

const DEFAULT_DASH = [0]

class DashStack extends PropertyStack {
  constructor(elementStack, data) {
    super(elementStack, data)
    this.type = 'DASH'
    this.key = 'dash'
    this.dataKey = 'dash'
    this.animatableProperties = new Set(['dash'])

    this._subInterval = new Interval()
  }

  /**
   * Get working interval by time
   * @param {number} time
   * @returns {Interval | undefined}  interval (READONLY) or undefined if no interval found
   *                                    DO NOT modify this returned value (outside of this class and it's subclasses)
   */
  getWorkingInterval(time) {
    const interval = super.getWorkingInterval(time)
    if (!interval) {
      return
    }

    // copy KFs value data because it's list of numbers
    interval.updateValues([...interval.start.value], [...interval.end.value])
    return interval
  }

  /**
   * Get animation data
   * @param {number} time
   * @returns {object}
   */
  getAnimateData(time) {
    const interval = this.getWorkingInterval(time)
    if (!interval) {
      // TODO: make sure returning "read-only" const value here is ok
      return {
        [this.dataKey]: DEFAULT_DASH
      }
    }

    const workingTime = this.getWorkingTime(time)
    const result = this._getDashChange(interval, workingTime)
    return {
      [this.dataKey]: result
    }
  }

  /**
   * Get border dash change in a specific time
   * @param {Interval} interval
   * @param {number} workingTime
   * @returns {number[]}
   */
  _getDashChange(interval, workingTime) {
    const v1 = interval.start.value
    const v2 = interval.end.value
    if (v1.length > v2.length) {
      this._matchDashInterval(v1, v2)
    } else if (v2.length > v1.length) {
      this._matchDashInterval(v2, v1)
    }

    const dash = []
    for (let i = 0; i < interval.end.value.length; i++) {
      const subInterval = this._getIntervalWithDashValue(interval, i)
      let result = 0
      if (this._isAnimatable()) {
        result = this._getInterpolateData(workingTime, subInterval)
      } else {
        result = this._getInstantChangeData(workingTime, subInterval)
      }
      dash.push(result)
    }

    return dash
  }

  /**
   * Match dash value for different count of dashes
   * @param {Array} bigValue
   * @param {Array} smallValue
   */
  _matchDashInterval(bigValue, smallValue) {
    for (let i = smallValue.length; i < bigValue.length; i++) {
      smallValue[i] = 0
    }
  }

  /**
   * Get specific value for dash from the interval
   * @param {Interval} interval
   * @param {number} idx
   * @returns {Interval}
   */
  _getIntervalWithDashValue(interval, idx) {
    const outInt = this._subInterval
    outInt.copy(interval, true).updateValues(
      interval.start.value[idx],
      interval.end.value[idx]
    )
    return outInt
  }
}

export default DashStack
