import { EntityType } from '@phase-software/types'
import { isNull, notNull, NOT_UNDOABLE, id, loadId } from '@phase-software/data-utils'
import EventEmitter from 'eventemitter3'


/** @typedef {import('./DataStore').DataStore} DataStore */
/** @typedef {import('./data/PropType').PropType} PropType */

/**
 * @typedef {object} Alias
 * @property {string} key
 * @property {(number | string)} [index]    sub key (if key is pointing to object)
 *                                          or index of list (if key is pointing to array like structure)
 * @property {Function} [getter]            `val = getter(data[key])` can be used to convert units
 * @property {Function} [setter]            `data[key] = setter(val)` can be used to convert units
 */

/**
 * @typedef {Map<string, {value: any, original: any}>} ChangesEvent
 */
export class ChangesEvent extends Map {
    addChange(key, value, original) {
        this.set(key, { value, original })
        return this
    }
}

export const reverseChangesEvent = event => {
    const newEvent = new ChangesEvent()
    event.forEach((change, key) => {
        const { value, original } = change
        newEvent.set(key, { value: original, original: value })
    })
    return newEvent
}


/**
 * parent class for all settable elements
 * @abstract
 * @fires 'CHANGES'
 * also fires individual variable events
 */
export class Setter extends EventEmitter {
    /**
     * @param {DataStore} dataStore
     * @param {SetterData} [data]
     */
    constructor(dataStore, data) {
        super()
        this.dataStore = dataStore

        /**
         * internal variables that lift up their contents to the Setter
         * @type {string[]}
         */
        this.liftups = []

        /**
         * parent instance in the liftup chain
         * @type {*}
         */
        this.liftupParent = null

        /**
         * keeps aliases to other props (or indexed/named elements of props)
         * @type {Record<string, Alias>}
         */
        this.aliases = {}

        /**
         * typechecks used for _eq(), _assign(), _getCopy() to know if the property is of complex type
         * @type {Record<string, PropType>}  mapping of Type for each complex type property name
         */
        this.propTypes = {}

        /**
         * array of variables that should not be set() except when set.readonly=true
         * @protected
         * @type {Set<string>}
         */
        this.readOnly = new Set(['id', 'type'])

        /**
         * changes that will be sent to undo
         * @type {Set<string>}
         */
        this.undoChanges = new Set(['name'])

        /**
         * events that will be sent to undo
         * @type {Set<string>}
         */
        this.undoEvents = new Set([])

        /**
         * Watcher watching this Setter
         * @type {Watcher | null}
         */
        this.watcher = null


        this.init()

        if (data) {
            this.load(data)
        } else {
            this.create()
        }
    }

    _debugSets(enable) {
        if (process.env.NODE_ENV !== "production") {
            this._debugSetsEnabled = enable
        }
    }

    /** output events to console */
    _debugEvents() {
        this.emit = (event, ...args) => {
            const res = super.emit(event, ...args)
            console.log(this.data.name, event, ...args)
            return res
        }
    }

    /**
     * fire all events from Element and DataStore
     * @protected
     * @param {string} type
     * @param {any | ChangesEvent[]} data
     * @param {object} [options] whole options object will be passed as a second parameter of CHANGES event
     * @param {boolean} [options.undoable=true] set to false to not add event to Undo
     * @param {boolean} [options.interaction=true] set to false to avoid create keyframe in action mode
     * @param {boolean} options.force
     * @param {any} [options.flags=undefined] used for passing related event IDs
     */
    fire(
        type,
        data,
        {
            undoable = true,
            force = false,
            flags = undefined,
            interaction = true
        } = { undoable: true }
    ) {
        const options = { undoable, force, flags, interaction }

        // only refire and add to undo on the top level of the liftup chain
        if (this.dataStore && !this.liftupParent) {
            // add to undo (if undoable)
            if (undoable) {
                if (type === 'CHANGES') {
                    const changes = new ChangesEvent()
                    for (const [key, { value, original }] of data) {
                        if (this.undoChanges.has(key)) {
                            changes.addChange(key, value, original)
                        }
                    }
                    if (changes.size) {
                        this.dataStore.addUndo(this, type, changes)
                    }
                } else {
                    this.dataStore.addUndo(this, type, data)
                }
            }
            // refire
            this.dataStore.refire(this, type, data)
        }

        // for CHANGES, emit all changed variables separately, and then all together
        if (type === 'CHANGES') {
            for (const [key, { value, original }] of data) {
                this.emit(key, value, original)
            }
        }
        this.emit(type, data, options)

        if (this.watcher) {
            this.watcher.fire(type, data, options)
        }

        if (this.liftupParent) {
            this.liftupParent.fire(type, data, options)
        }
    }

    /**
     * find a liftup that contains the requested key
     * @param {string} key
     * @returns {*}
     */
    findLiftup(key) {
        for (const liftup of this.liftups) {
            if (this.data[liftup].exists(key)) {
                return this.data[liftup]
            }
        }
        return null
    }

    /**
     * Sets a value to a property
     * @param {string} key
     * @param {*} value
     * @param {object} [options]
     * @param {boolean} [options.fire=true] set to false to not fire events (won't add it to Undo either)
     * @param {boolean} [options.undoable=true] set to false to not add event to Undo
     * @param {boolean} [options.force=false] set to true to allow setting read-only properties or same value
     * @param {boolean} [options.interaction=true] set to false to avoid create keyframe in action mode
     * @param {any} [options.flags=undefined] if defined, will be emitted as extra `flags` argument of CHANGES event
     * @returns {boolean} true if actually set (changed) value; false otherwise
     * @throws {Error} if key does not exist in this Setter or any of the liftups
     */
    set(
        key,
        value, {
            fire = true,
            undoable = true,
            force = false,
            flags = undefined,
            interaction = true
        } = { fire: true, undoable: true, force: false, interaction: true }
    ) {
        if (this._debugSetsEnabled) {
            console.log('DEBUG: set', this, { key, value, fire, undoable, force, flags, interaction })
            console.trace()
        }

        if (!force && this.readOnly.has(key)) {
            throw new Error(`trying to set readonly "${key}" in ${EntityType[this.data.type]}`)
        }

        // TODO: consider if need to copy original value (for reference types)

        const alias = this.aliases[key]
        if (alias) {
            const originalAliased = this._getCopy(alias.key)
            const original = this._getAliased(alias, true)
            if (force || !this._eqAliased(alias, original, value)) {
                this._assignAliased(alias, value)
                if (fire) {
                    const changes = new ChangesEvent()
                        .addChange(key, this._getAliased(alias, true), original)
                        .addChange(alias.key, this._getCopy(alias.key), originalAliased)
                    this.fire(
                        'CHANGES',
                        changes,
                        { undoable, force, flags }
                    )
                    this.dataStore.updateTransaction(this, changes)
                }
                return true
            }
        }
        else if (Object.prototype.hasOwnProperty.call(this.data, key)) {
            const original = this._getCopy(key)
            if (force || !this._eq(key, original, value)) {
                this._assign(key, value)
                // handle changing ids during parsing
                if (key === 'id') {
                    delete this.dataStore.map[original]
                    this.dataStore.map[value] = this
                }
                if (fire) {
                    const changes = new ChangesEvent().addChange(key, this._getCopy(key), original)
                    this.fire('CHANGES', changes, { undoable, force, flags, interaction })
                    this.dataStore.updateTransaction(this, changes)
                }
                return true
            }
        } else {
            const liftup = this.findLiftup(key)
            if (liftup) {
                return liftup.set(key, value, { fire, undoable, force, flags, interaction })
            } else {
                throw new Error(`trying to set invalid "${key}" in ${EntityType[this.data.type]}`)
            }
        }
        return false
    }

    /**
     * sets a group of variables
     * @param {object} changes
     * @param {object} [options]
     * @param {boolean} [options.fire=true] set to false to not fire events (won't add it to Undo either)
     * @param {boolean} [options.undoable=true] set to false to not add event to Undo
     * @param {boolean} [options.force=false] set to true to allow setting read-only properties or same value
     * @param {any} [options.flags=undefined] if defined, will be emitted as extra `flags` argument of CHANGES event
     * @param {boolean} [options.interaction=true] set to false to avoid create keyframe in action mode
     * @returns {Record<string, boolean>} true for each key that was set (changed)
     * @throws {Error} If any of the keys does not exist in this Setter or any of the liftups
     */
    sets(changes, {
        fire = true,
        undoable = true,
        force = false,
        flags = undefined,
        interaction = true
    } = { fire: true, undoable: true, force: false }
    ) {
        if (this._debugSetsEnabled) {
            console.log('DEBUG: sets', this, changes, { fire, undoable, force, flags })
            console.trace()
        }

        const updates = new ChangesEvent()
        /** @type {Record<string, boolean>} */
        const success = {}

        /**
         * track non-existent variables to check liftups
         * @type {string[]}
         */
        const invalid = []
        const allOriginalAliased = {}
        for (const key in changes) {
            if (!force && this.readOnly.has(key)) {
                throw new Error(`trying to set readonly "${key}" in ${EntityType[this.data.type]}`)
            }
            const value = changes[key]

            const alias = this.aliases[key]
            if (alias) {
                let originalAliased = allOriginalAliased[alias.key]
                if (!originalAliased) {
                    originalAliased = this._getCopy(alias.key)
                    allOriginalAliased[alias.key] = originalAliased
                }

                const original = this._getAliased(alias, true)
                if (force || !this._eqAliased(alias, original, value)) {
                    this._assignAliased(alias, value)
                    updates.addChange(key, this._getAliased(alias, true), original)
                    updates.addChange(alias.key, this._getCopy(alias.key), originalAliased)
                    success[key] = true
                }
            }
            else if (Object.prototype.hasOwnProperty.call(this.data, key)) {
                const original = this._getCopy(key)
                if (force || !this._eq(key, original, value)) {
                    this._assign(key, value)
                    // handle changing ids during parsing
                    if (key === 'id') {
                        delete this.dataStore.map[original]
                        this.dataStore.map[value] = this
                    }
                    updates.addChange(key, this._getCopy(key), original)
                    success[key] = true
                }
            } else {
                invalid.push(key)
            }
        }

        if (invalid.length) {
            for (const keyLiftup of this.liftups) {
                const liftup = this.data[keyLiftup]

                const sets = {}
                for (let i = 0; i < invalid.length; i++) {
                    const key = invalid[i]
                    if (liftup.exists(key)) {
                        sets[key] = changes[key]

                        invalid.splice(i, 1)
                        i--
                    }
                }

                if (Object.keys(sets).length) {
                    const _success = liftup.sets(sets, { fire, undoable, force, flags, interaction })
                    for (const key in _success) {
                        success[key] = true
                    }
                }
            }
        }

        // TODO: maybe there's no reason to throw the error, since it already could have set other correct properties
        if (invalid.length) {
            throw new Error(`trying to set invalid keys ${invalid} in ${EntityType[this.data.type]}`)
        }

        if (fire && updates.size > 0) {
            this.fire('CHANGES', updates, { undoable, force, flags, interaction })
            this.dataStore.updateTransaction(this, updates)
        }

        return success
    }

    /**
     * check whether a key exists
     * @param {string} key
     * @returns {boolean}
     */
    exists(key) {
        if (this.aliases[key]) {
            return true
        }
        if (typeof this.data[key] !== 'undefined') {
            return true
        }
        for (const liftup of this.liftups) {
            if (this.data[liftup].exists(key)) {
                return true
            }
        }
        return false
    }

    /**
     * gets a variable
     * @param {string} key
     * @param {boolean} [copy=false]  if true will return a copy of the prop value
     * @returns {*}
     */
    get(key, copy = false) {
        const alias = this.aliases[key]
        if (alias) {
            return this._getAliased(alias, copy)
        }
        else if (Object.prototype.hasOwnProperty.call(this.data, key)) {
            return copy ? this._getCopy(key) : this.data[key]
        } else {
            const liftup = this.findLiftup(key)
            if (liftup) {
                return liftup.get(key, copy)
            } else {
                throw new Error(`trying to get invalid "${key}" in ${EntityType[this.data.type]}`)
            }
        }
    }

    // eslint-disable jsdoc/check-param-names
    /**
     * gets a group of variables
     * @param {...string} keys
     * @returns {object}
     */
    // eslint-enable jsdoc/check-param-names
    gets(...keys) {
        // @ts-ignore
        const copy = keys[keys.length - 1] === true
        if (copy) {
            keys.pop()
        }

        const results = {}
        for (const key of keys) {
            const alias = this.aliases[key]
            if (alias) {
                results[key] = this._getAliased(alias, copy)
            }
            else if (Object.prototype.hasOwnProperty.call(this.data, key)) {
                results[key] = copy ? this._getCopy(key) : this.data[key]
            }
            else {
                const liftup = this.findLiftup(key)
                if (liftup) {
                    results[key] = liftup.get(key, copy)
                } else {
                    throw new Error(`trying to get invalid "${key}" in ${EntityType[this.data.type]}`)
                }
            }
        }
        return results
    }

    getAlias(key) {
        if (this.aliases[key] || Object.prototype.hasOwnProperty.call(this.data, key)) {
            return this.aliases[key]
        } else {
            const liftup = this.findLiftup(key)
            if (liftup) {
                return liftup.getAlias(key)
            } else {
                throw new Error(`trying to get alias for invalid "${key}" in ${EntityType[this.data.type]}`)
            }
        }
    }

    /**
     * add a variable to this Setter
     * note: this variable will not be saved; only instance use
     * @param {string} key
     * @param {*} value
     * @returns {*}
     */
    add(key, value) {
        this.data[key] = value
        this.fire('CHANGES', new ChangesEvent().addChange(key, value, undefined))
        return value
    }

    clone(overrides = { dataOnly: false }) {
        // @ts-ignore
        const obj = new this.constructor(this.dataStore, null, overrides)
        obj.data.name = this.data.name
        obj.data.type = this.data.type
        return obj
    }

    init() {
        this.data = {}
    }

    /**
     * creates a blank Setter
     * @protected
     * @param {string} prefix
     */
    create(prefix) {
        this.data.id = id(prefix);
        this.data.name = '';
        this.data.parent = null;

        this.addMap()
    }

    /**
     * @param {SetterData} data
     * @param {string} prefix
     */
    load(data, prefix) {
        // Update IdCounter for Setter instances
        if (data.id) {
            loadId(data.id, prefix)
        }
        this.data.id = data.id || id(prefix);
        this.data.name = data.name;
        this.data.type = data.type;
        this.data.parent = null;

        this.addMap()
    }

    addMap() {
        if (this.dataStore) {
            // this is a hack to create the map within the loading constructor (would have to add a post-constructor init to avoid this, regrettably)
            if (!this.dataStore.map) {
                this.dataStore.map = {}
                this.dataStore.map[this.dataStore.get('id')] = this.dataStore
            }
            this.dataStore.map[this.data.id] = this
        }
    }

    /** @returns {SetterData} data */
    save() {
        const data = {
            id: this.data.id,
            name: this.data.name,
            type: this.data.type
        }
        return data
    }

    /**
     * adds an eventListener for multiple events
     * @param  {...string} args
     * param {Function} callback
     */
    ons(...args) {
        // @ts-ignore
        const callback = args[args.length - 1]
        for (let i = 0; i < args.length - 1; i++) {
            // @ts-ignore
            this.on(args[i], callback)
        }
    }

    /**
     * adds an eventListener for multiple events
     * @param  {...string} args
     * param {Function} callback
     */
    offs(...args) {
        const callback = args[args.length - 1]
        for (let i = 0; i < args.length - 1; i++) {
            // @ts-ignore
            this.off(args[i], callback)
        }
    }

    /**
     * undo a CHANGES event with given ChangesEvent
     * @param {string} type
     * @param {ChangesEvent} changes
     */
    undo(type, changes) {
        if (type === 'CHANGES') {
            const obj = {}
            changes.forEach((change, key) => {
                obj[key] = change.original
            })
            this.sets(obj, NOT_UNDOABLE)
        }
    }

    /**
     * redo a CHANGES event with given ChangesEvent
     * @param {string} type
     * @param {ChangesEvent} changes
     */
    redo(type, changes) {
        if (type === 'CHANGES') {
            const obj = {}
            changes.forEach((change, key) => {
                obj[key] = change.value
            })
            this.sets(obj, NOT_UNDOABLE)
        }
    }

    /**
     * @param {string} type   event type
     * @param {ChangesEvent} original
     * @param {ChangesEvent} current
     * @returns {bool}    true if handled; false otherwise
     */
    combineUndo(type, original, current) {
        if (type !== 'CHANGES') {
            return false
        }
        let key
        for (key of current.keys()) {
            if (original.has(key)) {
                original.get(key).value = current.get(key).value
            } else {
                original.set(key, current.get(key))
            }
        }
        return true
    }

    /**
     * checks if two values are equal
     * @param  {string} key
     * @param  {*} a
     * @param  {*} b
     * @returns {boolean}    true if equal, false otherwise
     */
    _eq(key, a, b) {
        const type = this.propTypes[key]
        if (a === b) {
            return true
        }
        if (isNull(a) || isNull(b)) {
            return false
        }
        if (type) {
            return type.eq(a, b)
        }
        return false
    }

    /**
     * [_assign description]
     * @param  {string}       key   key in `this.data` or sub-data object (if out is not undefined)
     * @param  {*}            val   value
     * @param  {(object|[])}  out   sub-data object if value is to be assigned directly to sub-data key/index
     * @returns {*}     assigned value
     */
    _assign(key, val, out = undefined) {
        const dst = out || this.data
        const type = this.propTypes[key]

        if (type) {
            const d = dst[key]
            // first handle case when type is a list of values
            if (type.enum) {
                if (!type.enum.includes(val)) {
                    throw new Error(
                        `Trying to set invalid value "${val}" to "${key}" in ${EntityType[this.data.type]}. Should be one of ${type.enum}`
                    )
                }
                // will be assigned in the end of the method
            }
            // if type has copy function
            else if (!type.isIterable && d && d.copy && d.copy.constructor === Function) {
                d.copy(val)
                return d
            } else {
                dst[key] = type.copy(val)
                return dst[key]
            }
        }
        dst[key] = val
        return dst[key]
    }

    _getCopy(key) {
        const type = this.propTypes[key]
        if (type && !type.enum) {
            return type.copy(this.data[key])
        }
        return this.data[key]
    }

    _getAliased(alias, copy = false) {
        // TODO: check if need to copy indexed
        if (notNull(alias.index)) {
            return this.data[alias.key][alias.index]
        } else if (notNull(alias.getter)) {
            return alias.getter(copy ? this._getCopy(alias.key) : this.data[alias.key])
        } else {
            return copy ? this._getCopy(alias.key) : this.data[alias.key]
        }
    }

    _eqAliased(alias, original, value) {
        const v = isNull(alias.getter) ? value : alias.getter(value)
        return this._eq(alias.index, original, v)
    }

    _assignAliased(alias, value) {
        if (notNull(alias.index)) {
            this._assign(alias.index, value, this.data[alias.key])
        } else if (notNull(alias.setter)) {
            this._assign(alias.key, alias.setter(value))
        } else {
            this._assign(alias.key, value)
        }
    }

    _setupLiftupParents() {
        for (const liftupKey of this.liftups) {
            const liftup = this.data[liftupKey]
            if (liftup && liftup.liftupParent !== undefined) {
                liftup.liftupParent = this
            }
        }
    }
}

/**
 * @typedef {object} SetterData
 * @property {string} id
 * @property {string} name
 * @property {string} type
 */
