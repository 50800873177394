import { useEffect } from 'react'
import { InteractionEntityType } from '@phase-software/types'
import { useDataStore } from '../../providers/dataStore/DataStoreProvider'
import { useSetInteraction } from '../../providers/dataStore/InteractionProvider'
import { getBaseValue, getSegmentList } from '../../utils/interaction'

const SubscribeInteractionChange = () => {
  const dataStore = useDataStore()
  const { updateEntities } = useSetInteraction()

  useEffect(() => {
    dataStore.interaction.on('INTERACTION_CHANGES', ({ CREATE, UPDATE, DELETE }) => {
      const changes = {}
      const effectedPropTrackSet = new Set()

      const aggregateProp = (entity) => {
        if (entity.type === InteractionEntityType.PROPERTY_TRACK) {
          if (entity.keyFrameList.length) {
            effectedPropTrackSet.add(entity.id)
          }
        }
        if (entity.type === InteractionEntityType.KEY_FRAME) {
          effectedPropTrackSet.add(entity.trackId)
        }
      }

      CREATE.forEach((entityId) => {
        const entity = dataStore.interaction.getEntity(entityId)
        let expanded
        if (
          entity.type === InteractionEntityType.PROPERTY_TRACK ||
          entity.type === InteractionEntityType.ELEMENT_TRACK
        ) {
          expanded = false
        }
        aggregateProp(entity)
        changes[entityId] = { ...entity, expanded }
      })

      UPDATE.forEach((propChange, entityId) => {
        const entity = dataStore.interaction.getEntity(entityId)
        if (!entity) {
          return
        }
        aggregateProp(entity)
        if (!changes[entityId]) {
          changes[entityId] = {}
        }
        propChange.forEach(({ after }, propKey) => {
          changes[entityId][propKey] = after
        })
      })

      // prop, LAYER.prop
      effectedPropTrackSet.forEach((propTrackId) => {
        const propTrack = dataStore.interaction.getPropertyTrack(propTrackId)
        if (!propTrack) {
          return
        }
        const elementTrack = dataStore.interaction.getElementTrack(propTrack.elementTrackId)
        const baseValue = getBaseValue(elementTrack, propTrack.key, dataStore)

        // get kfs
        const kfIds = dataStore.interaction.getKeyFrameList(propTrackId)
        // keyframe might be deleted earlier, caused by multi-tab editing
        const kfs = kfIds.map((kfId) => dataStore.interaction.getKeyFrame(kfId)).filter(Boolean)

        // update segment
        if (!changes[propTrackId]) {
          changes[propTrackId] = {}
        }
        changes[propTrackId].segmentList = getSegmentList(kfs, baseValue)
      })

      updateEntities(changes, DELETE)
    })
  }, [dataStore, updateEntities])

  return null
}

export default SubscribeInteractionChange
