import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { TimeFormat, useInspectContext } from '../../../providers/InspectProvider'
import { Select } from '../../shared'
import { translateMenuOptions } from '../../shared/Menu/utils'

const capOptions = [
  {
    value: TimeFormat.SECOND,
    name: 'second_and_symbol'
  },
  {
    value: TimeFormat.PERCENT,
    name: 'percent_and_symbol'
  }
]

const TimeRow = () => {
  const { timeFormat, setTimeFormat } = useInspectContext()
  const { t } = useTranslation(['file', 'common'])

  const handleChange = (type: TimeFormat) => {
    setTimeFormat(type)
  }

  const translatedCapOptions = useMemo(() => {
    return translateMenuOptions(t, capOptions, {
      ns: 'common'
    })
  }, [t])

  return (
    <div className="flex gap-8 px-16 items-center">
      <div className="w-64 text-light-overlay-60">{t('inspect.time_unit')}</div>
      <div className="flex-grow">
        <Select
          // @ts-ignore TODO: fix after refactor of Select
          caret
          variant="normal"
          options={translatedCapOptions}
          onChange={handleChange}
          value={timeFormat}
        />
      </div>
    </div>
  )
}

export default React.memo(TimeRow)
