import moment from 'moment'

export const loadLocale = (locale: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    // Trim to get only the language code ex: 'en-US' -> 'en'
    const languageCode = locale.split('-')[0]

    if (languageCode === 'en') {
      moment.locale('en')
      resolve()
    } else {
      import(`moment/locale/${languageCode}`)
        .then(() => {
          console.log('successfully loaded locale:', languageCode)
          moment.locale(languageCode)
          resolve()
        })
        .catch((error) => {
          console.error(`Failed to load the locale: ${languageCode}`, error)
          reject(error)
        })
    }
  })
}

export const configMomentLocale = () => {
  moment.updateLocale('en-short', {
    relativeTime: {
      future: 'in %s',
      past: '%s',
      s: 'Now',
      ss: 'Now',
      m: '1min',
      mm: '%dmin',
      h: '1h',
      hh: '%dh',
      d: '1d',
      dd: '%dd',
      w: '1w',
      ww: '%dw',
      M: '1m',
      MM: '%dm',
      y: '1y',
      yy: '%dy'
    }
  })
}
