import { useCallback } from 'react'
import { schema } from 'normalizr'
import { createProvider, useEntity } from '../utils'
import { useSetLayer } from './LayerProvider'
import { Paint } from './PaintProvider'

export const Fill = new schema.Entity(
  'fills',
  { paint: Paint },
  {
    processStrategy: (value, parent) => {
      return { ...value, styleId: parent.id, elementId: parent.elementId }
    }
  }
)

const defaultValue = {}
const [Provider, useSelectState, useSetState] = createProvider('Fill', defaultValue)

export const useFill = useSelectState

export const useSetFill = () => {
  const { createLayer, destroyLayer } = useSetLayer()
  const setState = useSetState()
  const [setFill, addFill, updateFill, removeFill, mergeFill, reduceFill, create, destroy] = useEntity(Fill, setState)

  const createFill = useCallback(
    (entities) => {
      createLayer(entities)
      create(entities)
    },
    [create, createLayer]
  )

  const destroyFill = useCallback(
    (entities) => {
      destroy(entities)
      destroyLayer(entities)
    },
    [destroy, destroyLayer]
  )

  return {
    setFill,
    addFill,
    updateFill,
    removeFill,
    mergeFill,
    reduceFill,
    createFill,
    destroyFill
  }
}

export default Provider
