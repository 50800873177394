import { ToolType } from '@phase-software/types'
import { FlagsEnum } from '@phase-software/data-utils'
import { Transform2D } from '../math'
import { meshToPathData } from '../geometry/generate'
import { PathDataBuilder } from '../geometry/PathData'
import { Vector2 } from '../math/Vector2'
import { loadImage } from '../overlay/Overlay'
import Curve_Handle from './svg/Curve_Handle.svg'
import Hover_Curve_Handle from './svg/Hover_Curve_Handle.svg'
import Hover_Selected_Curve_Handle from './svg/Hover_Selected_Curve_Handle.svg'
import Selected_Curve_Handle from './svg/Selected_Curve_Handle.svg'
import Point_Handle from './svg/Point_Handle.svg'
import Hover_Point_Handle from './svg/Hover_Point_Handle.svg'
import Hover_Selected_Point_Handle from './svg/Hover_Selected_Point_Handle.svg'
import Selected_Point_Handle from './svg/Selected_Point_Handle.svg'

//#region JSDOC type definitions
/** @typedef {import('@phase-software/data-utils').Mesh} Mesh */
/** @typedef {import('@phase-software/data-utils').Vertex} Vertex */
/** @typedef {import('@phase-software/data-utils').Edge} Edge */
/** @typedef {import('@phase-software/data-store/src/Element').Element} Element */
/** @typedef {import('../visual_server/VisualServer').VisualServer} VisualServer */
/** @typedef {import('../overlay/Overlay').Pane} Pane */
/** @typedef {import('@phase-software/data-utils/src/mesh/Mesh').Mesh} Mesh */
/** @typedef {import('../visual_server/RenderItem').RenderItem} RenderItem */
//#endregion

//#region global variables
/** @type {VisualServer} the injected visual server */
let _visualServer = null
/** @type {Pane} the pane draws UI of mesh */
let _generalPane = null
/** @type {Pane} */
let _ghostPointPane = null
/** @type {Pane} */
let _pathPane = null
/** @type {Pane} */
let _highlightPane = null
/** @type {Transform2D} the transform of every frame when viewport updating */
const xform = new Transform2D()
/** @type {Vector2} The shared buffers of the world position */
const worldPos0 = new Vector2()
const worldPos1 = new Vector2()

/** @type {Element} the drawn element */
let _editingElement = null
/** @type {RenderItem} the render node of the mesh */
let _editingNode = null
/** @type {Mesh} */
let _editingMesh = null

let _pathOutlinedirty = true
let _outlinePath = null

const _ghostSegment = {
    visible: false,
    dirty: false,
    position: new Vector2(),
    snapId: null,
    builder: new PathDataBuilder(),
    curved: true
}

const _ghostPoint = {
    visible: false,
    position: new Vector2(),
    highlighted: false,
}

const _highlightedSegment = {
    visible: false,
    dirty: false,
    edgeIds: [],
    builder: new PathDataBuilder()
}
/** @type {Vector2[][]} */
let _assistLines = []
//#endregion

const constants = {
    LINE_WIDTH: 1,
    LINE_COLOR: 0x8B8B8B,
    SELECTED_LINE_COLOR: 0x1C6EE8,
    SVG_COLOR: 0xFFFFFF,
    GHOST_COLOR: 0x1C6EE8,
    CURVE_OFFSET: 4,
    HIGHLIGHTED_COLOR: 0xFF3232,
    ASSIST_LINE_CROSS_SIZE: 3
}
//#region Prepare how to render the vertex in different flags
/**
 * @callback DrawVertexCallback
 * @param {Vertex} vertex
 */
/** @type {Map<number, DrawVertexCallback>} */
const _drawVertMethodSet = new Map()
// Doesn't have selection flag
_drawVertMethodSet.set(
    0/* non flag */,
    /** @type {DrawVertexCallback} */
    (vertex) => {
        const pos = _editingMesh.getVertPos(vertex.id)
        xform.xform(worldPos1.set(pos[0], pos[1]), worldPos1)
        _generalPane.fillStyle(constants.SVG_COLOR)
            .drawImageFromAtlas(Point_Handle, worldPos1.x, worldPos1.y, 1, 1)
    }
)

// Has selection flag
const SELECT_MASK = 1 << FlagsEnum.SELECTED
_drawVertMethodSet.set(
    SELECT_MASK,
    /** @type {DrawVertexCallback} */
    (vertex) => {
        const pos = _editingMesh.getVertPos(vertex.id)
        xform.xform(worldPos1.set(pos[0], pos[1]), worldPos1)
        _generalPane.fillStyle(constants.SVG_COLOR)
            .drawImageFromAtlas(Selected_Point_Handle, worldPos1.x, worldPos1.y, 1, 1)
    }
)

// Has curve flag
const CURVE_MASK = 1 << FlagsEnum.CURVE_VERT

_drawVertMethodSet.set(
    CURVE_MASK/* non flag */,
    /** @type {DrawVertexCallback} */
    (vertex) => {
        if (!vertex.adjacentMainVertex) return
        if (!_editingMesh.vertices.has(vertex.adjacentMainVertex)) return
        if (vertex.adjacentMainVertex.pos.eq(vertex.pos)) return

        const connectedVert = vertex.adjacentMainVertex
        if (!connectedVert.isFlagged(FlagsEnum.CONNECT_SELECTED)) return

        // Hide the independent curve control handle when the ghost segment is not curved
        if (
            _ghostSegment.visible &&
            !_ghostSegment.curved &&
            vertex.id === connectedVert.unlinkedCurveControl &&
            connectedVert.isFlagged(FlagsEnum.SELECTED)
        ) return
        _drawCurveControlConnLineById(vertex.id, connectedVert.id, false)
        _drawCurveControlSquareById(vertex.id, false)
    }
)


_drawVertMethodSet.set(
    (CURVE_MASK | SELECT_MASK),
    /** @type {DrawVertexCallback} */
    (vertex) => {
        if (!vertex.adjacentMainVertex) return
        if (!_editingMesh.vertices.has(vertex.adjacentMainVertex)) return
        if (vertex.adjacentMainVertex.pos.eq(vertex.pos)) return

        const connectedVertId = vertex.adjacentMainVertex.id
        _drawCurveControlConnLineById(vertex.id, connectedVertId, true)
        _drawCurveControlSquareById(vertex.id, true)
    }
)

/**
 *
 * @param {string} vertexId
 * @param {bool} selected
 */
function _drawCurveControlSquareById(vertexId, selected) {
    const pos = _editingMesh.getVertPos(vertexId)
    xform.xform(worldPos1.set(pos[0], pos[1]), worldPos1)
    _drawCurveControlSquare(worldPos1.x, worldPos1.y, selected)
}

/**
 *
 * @param {number} x
 * @param {number} y
 * @param {bool} selected
 */
function _drawCurveControlSquare(x, y, selected) {
    const curveHandleSVG = selected ? Selected_Curve_Handle : Curve_Handle
    _generalPane.fillStyle(constants.SVG_COLOR)
        .drawImageFromAtlas(curveHandleSVG, x, y, 1, 1)
}

/**
 *
 * @param {string} curveHandlerVertId
 * @param {string} connectedVertId
 * @param {bool} selected
 */
function _drawCurveControlConnLineById(curveHandlerVertId, connectedVertId, selected) {
    const pos0 = _editingMesh.getVertPos(curveHandlerVertId, handlerStartPosBuffer)
    const pos1 = _editingMesh.getVertPos(connectedVertId, handlerEndPosBuffer)

    xform.xform(worldPos1.set(pos0[0], pos0[1]), worldPos1)
    xform.xform(worldPos0.set(pos1[0], pos1[1]), worldPos0)
    _drawCurveControlConnLine(worldPos1.x, worldPos1.y, worldPos0.x, worldPos0.y, selected)
}

/**
 *
 * @param {number} x0
 * @param {number} y0
 * @param {number} x1
 * @param {number} y1
 * @param {bool} selected
 */
function _drawCurveControlConnLine(x0, y0, x1, y1, selected) {
    const unitRay = new Vector2(x1 - x0, y1 - y0)
    const norm = unitRay.length()
    const invNorm = 1 / norm
    unitRay.scale(invNorm)
    const lineColor = selected ? constants.SELECTED_LINE_COLOR : constants.LINE_COLOR
    _generalPane.
        lineStyle(constants.LINE_WIDTH, lineColor)
        .drawLine(
            x0,
            y0,
            x0 + (unitRay.x * (norm - constants.CURVE_OFFSET)),
            y0 + (unitRay.y * (norm - constants.CURVE_OFFSET)))
}

const handlerStartPosBuffer = new Float32Array(2)
const handlerEndPosBuffer = new Float32Array(2)
//#endregion

/**
 *
 * @param {VisualServer} visualServer
 * @param {number} pathIndex
 * @param {number} ghostPointIndex
 * @param {number} vertexIndex
 * @param {number} highLightIndex
 */
export function init(visualServer, pathIndex, ghostPointIndex, vertexIndex, highLightIndex) {
    _visualServer = visualServer
    _pathPane = visualServer.overlay.createPane(pathIndex)
    _ghostPointPane = _visualServer.overlay.createPane(ghostPointIndex)
    _generalPane = _visualServer.overlay.createPane(vertexIndex)
    _highlightPane = _visualServer.overlay.createPane(highLightIndex)

    _visualServer.dataStore.selection.on('SELECT_CELL', (changes) => {
        const after = changes.get('vertices').after
        _refreshFlags(after)
        if (!after.length > 0) {
            _ghostSegment.visible = false
        }
    })

    _visualServer.dataStore.on('CHANGES', (changes) => {
        if (changes.has('activeTool')) {
            if (changes.get('activeTool').after !== ToolType.PEN) {
                _ghostSegment.visible = false
                _ghostPoint.visible = false
                _ghostPoint.highlighted = false
            }
        }
    })

    _preloadSVG()
}

/**
 * preload SVG files
 */
function _preloadSVG() {
    loadImage(Curve_Handle)
    loadImage(Hover_Curve_Handle)
    loadImage(Hover_Selected_Curve_Handle)
    loadImage(Selected_Curve_Handle)
    loadImage(Point_Handle)
    loadImage(Hover_Point_Handle)
    loadImage(Hover_Selected_Point_Handle)
    loadImage(Selected_Point_Handle)
}

/**
 * Refresh the flags by the selected cell
 * @param {Vertex[]} selectedVert
 */
function _refreshFlags(selectedVert) {
    /** @todo Use graph traversal instead of current method */
    // O(V + E)
    if (!_editingElement) return
    for (const vertex of _editingMesh.vertices) {
        vertex.removeFlag(FlagsEnum.SELECTED)
        vertex.removeFlag(FlagsEnum.CONNECT_SELECTED)
    }
    for (const vertex of selectedVert) {
        vertex.flag(FlagsEnum.SELECTED)
        vertex.flag(FlagsEnum.CONNECT_SELECTED)
        if (vertex.isFlagged(FlagsEnum.CURVE_VERT)) {
            if (vertex.controllingEdge) {
                vertex.controllingEdge.v.flag(FlagsEnum.CONNECT_SELECTED)
                vertex.controllingEdge.w.flag(FlagsEnum.CONNECT_SELECTED)
            } else if (vertex.adjacentMainVertex) {
                vertex.adjacentMainVertex.flag(FlagsEnum.CONNECT_SELECTED)
            }
        }
    }

    for (const edge of _editingMesh.getEdges()) {
        if (edge.v.isFlagged(FlagsEnum.SELECTED)) {
            edge.w.flag(FlagsEnum.CONNECT_SELECTED)
        }
        if (edge.w.isFlagged(FlagsEnum.SELECTED)) {
            edge.v.flag(FlagsEnum.CONNECT_SELECTED)
        }
    }
}

function _updateDrawHelper() {
    /** @todo Cache all calculation */
    if (_pathOutlinedirty) {
        _outlinePath = meshToPathData(_editingMesh)
        _pathOutlinedirty = false
    }

    if (_outlinePath) {
        _pathPane.lineStyle(constants.LINE_WIDTH, constants.LINE_COLOR).drawPath(0, 0, _outlinePath.applyXorm(xform))
    }
    // Draw the ghost segment if it exists
    if (_ghostSegment.visible) {
        _ghostSegment.visible = _refreshGhostSegments(_ghostSegment)
    }
    if (_ghostSegment.visible) {
        _pathPane.lineStyle(constants.LINE_WIDTH, constants.GHOST_COLOR).drawPath(0, 0, _ghostSegment.builder.path.applyXorm(xform))
    }

    if (_highlightedSegment.visible) {
        _refreshHighlighedSegments()
        if (_highlightedSegment.builder.path.commands.length > 0) {
            _highlightPane.lineStyle(constants.LINE_WIDTH, constants.HIGHLIGHTED_COLOR).drawPath(0, 0, _highlightedSegment.builder.path.applyXorm(xform))
        }
    }

    //#endregion Draw in the element space

    // O(nlogn)
    /** @todo using ordered tree map instead of sort every updating */
    for (const vertex of [..._editingMesh.vertices].sort((a, b) => a.index - b.index)) {
        // accepted selected and curve handler flags
        _drawVertMethodSet.get(vertex.flags & (CURVE_MASK | SELECT_MASK))(vertex)
    }

    // Only 0-1 hand over vertex, be lazy
    /** @type {Vertex} */
    _drawHoverVertex()

    for (const line of _assistLines) {
        _highlightPane.lineStyle(1, constants.HIGHLIGHTED_COLOR).drawLine(
            line[0].x, line[0].y, line[1].x, line[1].y
        ).drawLine(
            line[0].x - constants.ASSIST_LINE_CROSS_SIZE, line[0].y - constants.ASSIST_LINE_CROSS_SIZE,
            line[0].x + constants.ASSIST_LINE_CROSS_SIZE, line[0].y + constants.ASSIST_LINE_CROSS_SIZE
        ).drawLine(
            line[0].x - constants.ASSIST_LINE_CROSS_SIZE, line[0].y + constants.ASSIST_LINE_CROSS_SIZE,
            line[0].x + constants.ASSIST_LINE_CROSS_SIZE, line[0].y - constants.ASSIST_LINE_CROSS_SIZE
        ).drawLine(
            line[1].x - constants.ASSIST_LINE_CROSS_SIZE, line[1].y - constants.ASSIST_LINE_CROSS_SIZE,
            line[1].x + constants.ASSIST_LINE_CROSS_SIZE, line[1].y + constants.ASSIST_LINE_CROSS_SIZE
        ).drawLine(
            line[1].x - constants.ASSIST_LINE_CROSS_SIZE, line[1].y + constants.ASSIST_LINE_CROSS_SIZE,
            line[1].x + constants.ASSIST_LINE_CROSS_SIZE, line[1].y - constants.ASSIST_LINE_CROSS_SIZE
        )
    }
}

/**
 * @returns {void}
 */
function _drawHoverVertex() {
    const hoverVertex = _visualServer.dataStore.selection.get('hoverVertex')
    if (!hoverVertex) return
    if (!_editingElement) return
    const pos = _editingMesh.getVertPos(hoverVertex.id)

    xform.xform(worldPos1.set(pos[0], pos[1]), worldPos1)

    let curveHandleSVG = Hover_Curve_Handle
    let pointHandleSVG = Hover_Point_Handle
    if (hoverVertex.isFlagged(FlagsEnum.SELECTED)) {
        curveHandleSVG = Hover_Selected_Curve_Handle
        pointHandleSVG = Hover_Selected_Point_Handle
    }

    if (hoverVertex.isFlagged(FlagsEnum.CURVE_VERT)) {
        _generalPane.fillStyle(constants.SVG_COLOR)
            .drawImageFromAtlas(curveHandleSVG, worldPos1.x, worldPos1.y, 1, 1)
    } else {
        _generalPane.fillStyle(constants.SVG_COLOR)
            .drawImageFromAtlas(pointHandleSVG, worldPos1.x, worldPos1.y, 1, 1)
    }

    if (_ghostPoint.highlighted) {
        _highlightPane.lineStyle(constants.LINE_WIDTH, constants.HIGHLIGHTED_COLOR)
            .drawLine(
                worldPos1.x - constants.ASSIST_LINE_CROSS_SIZE, worldPos1.y - constants.ASSIST_LINE_CROSS_SIZE,
                worldPos1.x + constants.ASSIST_LINE_CROSS_SIZE, worldPos1.y + constants.ASSIST_LINE_CROSS_SIZE
            )
            .drawLine(
                worldPos1.x - constants.ASSIST_LINE_CROSS_SIZE, worldPos1.y + constants.ASSIST_LINE_CROSS_SIZE,
                worldPos1.x + constants.ASSIST_LINE_CROSS_SIZE, worldPos1.y - constants.ASSIST_LINE_CROSS_SIZE
            )
    }

}

/**
 *
 * @param {object} ghostSegment
 * @param {boolean} ghostSegment.dirty
 * @param {boolean} ghostSegment.curved
 * @param {string} ghostSegment.snapId
 * @param {Vector2} ghostSegment.position
 * @param {boolean} ghostSegment.visible
 * @param {PathDataBuilder} ghostSegment.builder
 * @returns {boolean}
 */
function _refreshGhostSegments(ghostSegment) {

    if (!ghostSegment.dirty) return true
    ghostSegment.dirty = false
    ghostSegment.builder.clear()
    /** @type {Vertex[]} */
    const selectedVerts = _visualServer.dataStore.selection.get('vertices')
    if (selectedVerts.length !== 1 || selectedVerts[0].isFlagged(FlagsEnum.CURVE_VERT)) {
        return false
    }
    const startVert = selectedVerts[0]
    const startPos = _editingMesh.getVertPos(startVert.id, startPosBuffer)

    let connected2MirrorVert = false
    if (ghostSegment.snapId) { // The semgment is already snapping on a vertex
        const snapVert = _editingMesh.cellTable.get(ghostSegment.snapId)
        if (snapVert.isFlagged(FlagsEnum.CURVE_VERT)) {
            return false
        }
        for (const edgeId of snapVert.upperTierIDs) {
            /** @type {Edge} */
            const edge = _editingMesh.cellTable.get(edgeId)
            if (edge.isCurve) continue
            if ((edge.v === startVert && edge.w === snapVert)
                || (edge.w === startVert && edge.v === snapVert)) {
                return false
            }
        }
        _editingMesh.getVertPos(ghostSegment.snapId, endPosBuffer)
        if (snapVert.unlinkedCurveControl) {
            connected2MirrorVert = true
            _editingMesh.getVertPos(snapVert.unlinkedCurveControl, ghostCurve1PosBuffer)
        } else {
            ghostCurve1PosBuffer[0] = endPosBuffer[0]
            ghostCurve1PosBuffer[1] = endPosBuffer[1]
        }
    } else {
        _editingNode.transform.worldInv.xform(ghostSegment.position, ghostSegment.position)
        const startPosVec2 = new Vector2(startPosBuffer[0], startPosBuffer[1])
        if (startPosVec2.distance_to(ghostSegment.position) < Number.EPSILON) return false
        endPosBuffer[0] = ghostSegment.position.x
        endPosBuffer[1] = ghostSegment.position.y
        ghostCurve1PosBuffer[0] = endPosBuffer[0]
        ghostCurve1PosBuffer[1] = endPosBuffer[1]
    }

    if ((startVert.unlinkedCurveControl || connected2MirrorVert) && ghostSegment.curved) {
        if (startVert.unlinkedCurveControl) {
            _editingMesh.getVertPos(startVert.unlinkedCurveControl, ghostCurve0PosBuffer)
        } else {
            ghostCurve0PosBuffer[0] = startPos[0]
            ghostCurve0PosBuffer[1] = startPos[1]
        }
        ghostSegment.builder.start(startPos[0], startPos[1])
        ghostSegment.builder.cubic(
            ghostCurve0PosBuffer[0], ghostCurve0PosBuffer[1],
            ghostCurve1PosBuffer[0], ghostCurve1PosBuffer[1],
            endPosBuffer[0], endPosBuffer[1])
        ghostSegment.builder.end()
        return true
    }

    ghostSegment.builder.start(startPosBuffer[0], startPosBuffer[1])
    ghostSegment.builder.line(endPosBuffer[0], endPosBuffer[1])
    ghostSegment.builder.end()
    return true
}

function _refreshHighlighedSegments() {
    if (!_highlightedSegment.dirty) return
    _highlightedSegment.dirty = false
    _highlightedSegment.builder.clear()

    for (const edgeId of _highlightedSegment.edgeIds) {
        const edge = _editingMesh.cellTable.get(edgeId)
        if (!edge) {
            console.error(`Could not find edge ${edgeId}`)
            continue
        }
        if (edge.isCurve){
            const tmpCurve = _editingMesh.getEdgeCurve(edgeId)
            const curveV = new Vector2(tmpCurve[0].x, tmpCurve[0].y)
            const curveW = new Vector2(tmpCurve[1].x, tmpCurve[1].y)
            const tmpV = _editingMesh.getVertPos(edge.v.id)
            const v = new Vector2(tmpV.x, tmpV.y)
            const tmpW = _editingMesh.getVertPos(edge.w.id)
            const w = new Vector2(tmpW.x, tmpW.y)
            _highlightedSegment.builder.start(v.x, v.y)
            _highlightedSegment.builder.cubic(curveV.x, curveV.y, curveW.x, curveW.y, w.x, w.y)
            _highlightedSegment.builder.end()
        } else{
            const tmpV = _editingMesh.getVertPos(edge.v.id)
            const v = new Vector2(tmpV.x, tmpV.y)
            const tmpW = _editingMesh.getVertPos(edge.w.id)
            const w = new Vector2(tmpW.x, tmpW.y)
            _highlightedSegment.builder.start(v.x, v.y)
            _highlightedSegment.builder.line(w.x, w.y)
            _highlightedSegment.builder.end()
        }
    }
}

//#region Drawing ghost segment buffers
const startPosBuffer = new Float32Array(2)
const endPosBuffer = new Float32Array(2)
const ghostCurve0PosBuffer = new Float32Array(2)
const ghostCurve1PosBuffer = new Float32Array(2)
//#endregion

export function update() {
    _generalPane.clear()
    _ghostPointPane.clear()
    _pathPane.clear()
    _highlightPane.clear()

    // Draw on screen space
    if (_ghostPoint.visible) {
        _ghostPointPane.fillStyle(constants.SVG_COLOR)
            .drawImageFromAtlas(Point_Handle, _ghostPoint.position.x, _ghostPoint.position.y, 1, 1)
    }

    if (_editingElement === null) return
    xform.copy(_editingNode.transform.world)
        .prepend(_visualServer.viewport.projectionTransform)

    _updateDrawHelper()
}

/**
 * @param {Element} element
 */
export function applyMeshHelperPane(element) {
    if (!element.canMorph) {
        console.error(`${element.get('id')} can't edit path`)
    }
    _editingElement = element
    _editingNode = _visualServer.getRenderItemOfElement(element)
    _editingMesh = element.get('geometry').get('mesh')
    _pathOutlinedirty = true
}

export function cleanMeshHelperPane() {
    if (_editingMesh) {
        for (const vertex of _editingMesh.vertices) {
            vertex.removeFlag(FlagsEnum.SELECTED)
        }
    }
    _editingElement = null
    _editingMesh = null
    _editingNode = null
    _generalPane.clear()
    _pathOutlinedirty = true
}

/**
 * Enables ghost point and updates its position
 * @param {number} x
 * @param {number} y
 * @param {boolean} snapToGrid
 */
export function enableGhostPoint(x, y, snapToGrid) {
    _ghostPoint.visible = true
    _ghostPoint.highlighted = false

    if (snapToGrid) {
        const mousePos = new Vector2(x, y)
        const pos = _visualServer.viewport.toWorld(mousePos)
        const mouseWorldPos = pos.round()
        const newScreenMousePos = _visualServer.viewport.toScreen(mouseWorldPos)
        _ghostPoint.position.copy(newScreenMousePos)
    } else {
        _ghostPoint.position.set(x, y)
    }

}

/**
 * Disables ghost point
 * @param {boolean} keepHighlight
 */
export function disableGhostPoint(keepHighlight) {
    _ghostPoint.visible = false
    _ghostPoint.highlighted = keepHighlight
}
/**
 *
 * @param {number} x
 * @param {number} y
 * @param {boolean} curved
 */
export function appendGhostSegment(x, y, curved) {
    _ghostSegment.visible = true
    _ghostSegment.dirty = true
    _ghostSegment.snapId = null
    _ghostSegment.position.copy(_visualServer.viewport.toWorld(_ghostPoint.position))
    _ghostSegment.curved = curved
}

/**
 *
 * @param {string} vertexId
 * @param {boolean} curved
 */
export function snapGhostSegment(vertexId, curved) {
    _ghostSegment.visible = true
    _ghostSegment.dirty = true
    _ghostSegment.snapId = vertexId
    _ghostSegment.curved = curved
}

export function hideGhostSegment() {
    _ghostSegment.visible = false
    _ghostSegment.dirty = true
}

/**
 *
 * @param {string[]} edgeIds
 */
export function highlightEdges(edgeIds) {
    _highlightedSegment.edgeIds = edgeIds
    _highlightedSegment.dirty = true
    _highlightedSegment.visible = edgeIds.length > 0
}

export function stopHighlightEdges() {
    _highlightedSegment.visible = false
    _highlightedSegment.dirty = true
}

/**
 *
 * @param {Vector2[][]} lines
 */
export function drawAssistLinesOnScreen(lines) {
    _assistLines = lines
}

export function markMeshDirty() {
    _pathOutlinedirty = true
}
