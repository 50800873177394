import { Action } from './Action'
import { Manager } from './Manager'
import { ACTIONS_DATA } from './actions'
import { STATES } from './states'
import { platform } from './constants'

/**
 * @typedef {object} ActionData
 * @property {string[]} keyCombos
 * @property {import('./states').StateData} state
 * @property {symbol} [group]
 */


const _manager = new Manager()
_manager.pause()

for (const name in ACTIONS_DATA) {
    const data = ACTIONS_DATA[name]

    // skip adding this action if its target platforms doesn't
    //  include the one we currently run on
    if (data.platforms && !data.platforms.includes(platform)) {
        continue
    }

    const action = new Action(name, data.keyCombos, data.state, data.group, data.fallback)
    _manager.addAction(action)
}

// TODO: maybe remove this?
/** @returns {import('../math').Vector2} */
const mousePos = () => _manager.mousePos

const pause = () => _manager.pause()
const resume = () => _manager.resume()

/**
 * @param {string} actionName
 * @returns {Action}
 */
const get = actionName => _manager.getAction(actionName)

/**
 * @param {Element|Window} targetElement
 * @returns {void}
 */
const watch = targetElement => _manager.watch(targetElement)

export default {
    on: _manager.on.bind(_manager),
    off: _manager.off.bind(_manager),
    get,
    pause,
    resume,
    mousePos,
    watch,
    STATES,
    platform
}
