import { Contour, Edge, FlagsEnum, Vector2, Vertex } from "../.."

export class MeshUtils {
    static processVertices(mesh, vertices, isUpdate = false) {
        const verticesIdSet = new Set()
        if (isUpdate) {
            mesh.vertices.forEach((v) => verticesIdSet.add(v.id))
        }
        const vertexIds = {}
        for (const v of vertices) {
            const vertex = Vertex.fromData(v)
            if (isUpdate && verticesIdSet.has(v.id)) {
                const deletedVertex = mesh.cellTable.get(v.id)
                mesh.vertices.delete(deletedVertex)
            }
            mesh.vertices.add(vertex)
            mesh.cellTable.set(vertex.id, vertex)
            vertexIds[vertex.id] = vertex
        }

        // to link the unlinkedCurveControl to its adjacentMainVertex
        // only in multi-editing data sync
        for (let index = 0; index < vertices.length; index++) {
            const vertData = vertices[index]
            // continue if vertex is not a control point
            if (!vertData.adjMainId){
                continue
            }
            
            // here only link the data below, the other data will be sync when the actual edge created
            // for the unlinkedCurveControl
            const vertexEntity = vertexIds[vertData.id]
            vertexEntity.adjacentMainVertex = vertexIds[vertData.adjMainId]
            // update the vertex flag to curve vertex
            vertexEntity.flag(FlagsEnum.CURVE_VERT)
        }
        return vertexIds
    }

    static processEdges(mesh, edges, vertexIds, isUpdate = false) {
        const edgesIdSet = isUpdate ? new Set([...mesh.edges.values()].map(v => v.id)) : null
        const edgeIds = {}
        const vertexIdSet = new Set(Object.keys(vertexIds))
        for (const e of edges) {
            let cpV, cpW
            const v = vertexIds[e.v]
            const w = vertexIds[e.w]
            if (e.curveIds) {
                cpV = vertexIds[e.curveIds[0]]
                cpW = vertexIds[e.curveIds[1]]
            }
            if (!cpV) cpV = mesh._createAndRecordVertex()
            if (!cpW) cpW = mesh._createAndRecordVertex()
            if (isUpdate) {
                if (edgesIdSet.has(e.id)) {
                    const deletedEdge = mesh.cellTable.get(e.id)
                    mesh.edges.delete(deletedEdge)
                }
            } else if (e.curve) {
                cpV.set({ pos: new Vector2(e.curve[0]) })
                cpW.set({ pos: new Vector2(e.curve[1]) })
            }
            // eslint-disable-next-line eqeqeq
            const hasCurve = e.curve != null
            const edge = Edge.fromData({ ...e, v, cpV, cpW, w }, hasCurve)
            mesh.edges.add(edge)
            edgeIds[edge.id] = edge
            mesh.cellTable.set(edge.id, edge)
            vertexIdSet.delete(v.id)
            vertexIdSet.delete(cpV.id)
            vertexIdSet.delete(cpW.id)
            vertexIdSet.delete(w.id)
        }
        // The remaining vertices are unlinkedCurveControl
        for (const vertexId of vertexIdSet) {
            const vertex = mesh.cellTable.get(vertexId)
            if (vertex.isFlagged(FlagsEnum.CURVE_VERT)) {
                vertex.adjacentMainVertex.unlinkedCurveControl = vertex.id
            }
        }
        return edgeIds
    }

    static processContours(mesh, contours, edgeIds) {
        for (const c of contours) {
            const contour = Contour.fromData(c)
            for (let i = 0; i < c.halves[0].length; i++) {
                const edge = edgeIds[c.halves[0][i]]
                if (!edge) {
                    console.warn(`Edge ${c.halves[0][i]} not found`)
                    continue
                }
                const h = edge.halves[c.halves[1][i]]
                const e = h.edge
                e.upperTierIDs.add(contour.id)
                contour.addHalfEdge(h)
            }
            mesh.contours.add(contour)
            mesh.cellTable.set(contour.id, contour)
        }
    }
}

// Helper function to swap positions between two vertices
/**
 * @param {Vertex} vertex
 * @param {Map<string, VertexData> } baseVertex
 */
function _swapVertexPosition(vertex, baseVertex) {
    let tempPosition = vertex.pos[0]
    vertex.pos[0] = baseVertex.pos[0]
    baseVertex.pos[0] = tempPosition
    tempPosition = vertex.pos[1]
    vertex.pos[1] = baseVertex.pos[1]
    baseVertex.pos[1] = tempPosition
}

// Process vertices and swap positions based on base path, with optional saving
/**
 * @typedef {import('./Cell').Cell} Cell
 */
/**
 * @param {Map<string, Cell>} cellTable
 * @param {Map<string, VertexData> } basePath
 */
export function swapVertexAndBase(cellTable, basePath) {
    
    for(const [id, baseVertex] of basePath){
        if (cellTable.has(id)){
            const pairedVertex = cellTable.get(id)
            _swapVertexPosition(pairedVertex, baseVertex)
        }
    }
}