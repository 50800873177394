import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Player } from '@lottiefiles/react-lottie-player'
import { Mode } from '@phase-software/types'

import { FILE_EDITOR_LAYOUT_AREAS } from '../../constant'
import { useLayout } from '../../providers/LayoutProvider'
import { useFlattenElementList } from '../../providers/dataStore/FlattenElementListProvider'
import { useInteraction, useInteractionActions } from '../../providers/dataStore/InteractionProvider'
import { useKeyframeSelection } from '../../providers/dataStore/KeyframeSelectionProvider'
import { useUI } from '../../providers/dataStore/UIProvider'
import { minmax } from '../../utils/number'
import FloatingControl from '../FloatingControl'
import ActionPanelHeader from './ActionPanelHeader'
import EasingPanel from './Easing/EasingPanel'
import TimeRuler from './TimeRuler'
import { TrackInfoList, TrackKeyFrameList } from './TrackList'
import actionPanelAnimation from './actionPanelEmpty.json'
import { EASING_INFO_WIDTH, SCALE_FACTOR, TRACK_INFO_WIDTH, TRACK_PADDING_X } from './constant'
import { ScrollActionContext, ScrollContext, SizeActionContext, SizeContext } from './contexts'
import { SubscribeInputSystemModifierKeys, SubscribeSelectedKeyframeRange } from './subscribers'
import { getTimeAtStep } from './utils'
import { useDataStore } from '../../providers/dataStore/DataStoreProvider'
import { toInteger } from 'lodash'

const EmptyState = () => {
  const { t } = useTranslation('file')
  return (
    <div
      className="grid place-items-center relative pointer-events-none text-light-overlay-40 z-1 pt-16 pl-16 overflow-hidden"
      style={{ gridArea: 'keyframe' }}
    >
      <div className="w-[240px] h-[208px] pt-action-empty-state">
        <Player
          autoplay
          loop
          src={actionPanelAnimation}
          style={{
            height: '160px',
            width: '240px'
          }}
        />
        <div className="pb-32">{t('empty_track_list_note')}</div>
      </div>
    </div>
  )
}

type ResponseProps = {
  id: string
}

const Response = ({ id }: ResponseProps) => {
  const mode = useUI((o) => o.mode)
  const { isActionPanelHidden } = useUI()
  const actionPanelHeight = useLayout((o) => o.actionHeight)

  const response = useInteraction(id)
  const flattenElements = useFlattenElementList()
  const trackList = Array.from(flattenElements.values()).filter((id) => response.elementTrackMap.has(id))
  const keyframeSelection = useKeyframeSelection()

  const { setScale } = useContext(SizeActionContext)
  const size = useContext(SizeContext)
  const lastAppliedScale = useRef<null | number>(null)
  const trackKeyframeRef = useRef<HTMLDivElement>(null)
  const rulerRef = useRef<HTMLDivElement>(null)
  const shouldHidePanel = isActionPanelHidden || mode === Mode.DESIGN

  const trackInfoRef = useRef()
  const playHeadRef = useRef(null)
  const ref = useRef<HTMLDivElement>(null)
  const cacheRef = useRef({ panel: null, scalePosition: 0.5, scrollWidth: 0, playHeadPosition: 0 })

  const handleWheel = useCallback(
    (e: WheelEvent) => {
      if (e.deltaY === 0) {
        return
      }
      if (!e.metaKey && !e.ctrlKey) {
        return
      }
      if (size.scale === lastAppliedScale.current) return
      e.stopPropagation()
      e.preventDefault()
      const scale = Math.max(1, size.scale * (e.deltaY < 0 ? 1.025 : 1 / 1.025))
      const newScale = minmax(scale, 1, size.duration / size.width / SCALE_FACTOR)

      const mousePositionOnRuler = e.clientX - TRACK_INFO_WIDTH - TRACK_PADDING_X + (rulerRef.current?.scrollLeft || 0)
      const timeUnderMouse = getTimeAtStep((mousePositionOnRuler * size.duration) / size.width / size.scale)
      const newScrollLeft1 =
        (timeUnderMouse * size.width * newScale) / size.duration - e.clientX + TRACK_INFO_WIDTH + TRACK_PADDING_X

      setScale(scale)
      if (newScale === size.scale) {
        lastAppliedScale.current = null
      } else {
        lastAppliedScale.current = size.scale
      }
      if (rulerRef.current) rulerRef.current.scrollLeft = newScrollLeft1
      if (trackKeyframeRef.current) trackKeyframeRef.current.scrollLeft = newScrollLeft1
    },
    [size, setScale]
  )

  useEffect(() => {
    const trackKeyframeNode = trackKeyframeRef.current
    const rulerNode = rulerRef.current

    trackKeyframeNode?.addEventListener('wheel', handleWheel, { passive: false })
    rulerNode?.addEventListener('wheel', handleWheel, { passive: false })

    return () => {
      trackKeyframeNode?.removeEventListener('wheel', handleWheel)
      rulerNode?.removeEventListener('wheel', handleWheel)
    }
  }, [trackKeyframeRef, rulerRef, handleWheel])

  useEffect(() => {
    const node = ref.current
    const handleEnter = () => {
      node?.classList.add('active')
    }
    const handleLeave = () => {
      node?.classList.remove('active')
    }
    node?.addEventListener('mouseenter', handleEnter)
    node?.addEventListener('mouseleave', handleLeave)
    return () => {
      node?.removeEventListener('mouseenter', handleEnter)
      node?.removeEventListener('mouseleave', handleLeave)
    }
  }, [ref])

  return (
    <div
      ref={ref}
      data-test-id="mode-wrapper"
      className="relative grid grid-rows-[40px,minmax(0,1fr)] bg-neutral-90 pt-action-panel js-action-panel border-t border-neutral-80 z-20 box-content"
      style={{
        gridArea: FILE_EDITOR_LAYOUT_AREAS.ACTION_PANEL,
        gridTemplateAreas: "'controls ruler easing' 'track keyframe easing'",
        gridTemplateColumns: `${TRACK_INFO_WIDTH}px minmax(0, 1fr) ${EASING_INFO_WIDTH}px`,
        height: shouldHidePanel ? 0 : actionPanelHeight,
        willChange: 'height',
        boxShadow: '0px -8px 16px 0px #00000033'
      }}
    >
      <div className="absolute top-[-60px] left-1/2 -translate-x-1/2 text-white text-center">
        <FloatingControl actionId={response.actionId} />
      </div>

      {!isActionPanelHidden && (
        <>
          <ActionPanelHeader playHeadRef={playHeadRef} actionId={response.actionId} />
          <TrackInfoList
            // @ts-ignore TODO: fix after refactor of TrackInfoList
            trackList={trackList}
            trackMap={response.elementTrackMap}
            cacheRef={cacheRef}
            ref={trackInfoRef}
            kfRef={trackKeyframeRef}
          />

          <SubscribeSelectedKeyframeRange />

          <SubscribeInputSystemModifierKeys />

          <TrackKeyFrameList
            // @ts-ignore TODO: fix after refactor of TrackKeyFrameList
            trackList={trackList}
            trackMap={response.elementTrackMap}
            cacheRef={cacheRef}
            ref={trackKeyframeRef}
            rulerRef={rulerRef}
            infoRef={trackInfoRef}
          />
          <TimeRuler
            ref={rulerRef}
            // @ts-ignore TODO: fix after refactor of TimeRuler
            trackRef={trackKeyframeRef}
            cacheRef={cacheRef}
            playHeadRef={playHeadRef}
          />

          {response.elementTrackMap.size === 0 ? <EmptyState /> : null}

          <div
            className="absolute w-full h-full z-20 border-r border-neutral-80 pointer-events-none"
            style={{ gridArea: 'controls / controls / track / track' }}
          />
          <div className="relative z-20 border-l border-neutral-80 bg-neutral-90" style={{ gridArea: 'easing' }}>
            <EasingPanel keyframeIdList={keyframeSelection} />
          </div>
        </>
      )}
    </div>
  )
}

type ActionProps = {
  id: string
}

const Action = ({ id }: ActionProps) => {
  const action = useInteraction(id)

  const [size, setSize] = useState({
    width: 0,
    scale: 1,
    duration: 0,
    maxScale: 1
  })

  const [disableScrollIntoView, setDisableScrollIntoView] = useState(false)

  const setScale = useCallback(
    (scale: number) =>
      setSize((s) => ({
        ...s,
        scale: minmax(scale, 1, s.duration / s.width / SCALE_FACTOR)
      })),
    [setSize]
  )
  const setWidth = useCallback(
    (width: number) =>
      setSize((s) => ({
        ...s,
        width,
        scale: minmax(s.scale, 1, s.duration / width / SCALE_FACTOR),
        maxScale: s.duration / width / SCALE_FACTOR
      })),
    [setSize]
  )
  const setDuration = useCallback(
    (duration: number) =>
      setSize((s) => ({
        ...s,
        duration,
        scale: minmax(s.scale, 1, duration / s.width / SCALE_FACTOR),
        maxScale: duration / s.width / SCALE_FACTOR
      })),
    [setSize]
  )

  useEffect(() => {
    setDuration(action.maxTime)
  }, [action.maxTime, setDuration])

  const responseId = action.responseList[0]
  return (
    <SizeContext.Provider value={size}>
      <ScrollContext.Provider value={{ disableScrollIntoView }}>
        <SizeActionContext.Provider value={{ setWidth, setScale, setDuration }}>
          <ScrollActionContext.Provider value={{ setDisableScrollIntoView }}>
            <Response id={responseId} />
          </ScrollActionContext.Provider>
        </SizeActionContext.Provider>
      </ScrollContext.Provider>
    </SizeContext.Provider>
  )
}

const Interaction = () => {
  const { getSelectedAction } = useInteractionActions()

  const actionId = getSelectedAction()
  if (actionId === null) return null

  return <Action id={actionId} />
}

export default Interaction
