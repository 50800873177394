import styled from 'styled-components';
import { compose, color, space, layout, typography, flexbox, grid, LayoutProps, FlexboxProps } from 'styled-system';

interface FlexProps extends LayoutProps, FlexboxProps { }

const Flex = styled.div<FlexProps>(
  {
    display: 'flex',
    alignItems: 'center'
  },
  compose(color, flexbox, layout, space, typography, grid)
);

export default Flex;
