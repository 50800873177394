import React, { useCallback } from 'react'
import { SPEED_OPTIONS, DEFAULT_SPEED } from '../../../constants/interactionConstants'

export type SpeedControlButtonProps = {
  value: number
  onChange: (value: SpeedControlButtonProps['value']) => void
}

const getNextSpeedOption = (currentIndex: number) => {
  return SPEED_OPTIONS[(currentIndex + 1) % SPEED_OPTIONS.length]
}

const computedClassName = {
  default: 'pl-4 text-light-overlay-60 hover:text-white active:text-light-overlay-40',
  withBackground: 'px-6 text-white rounded-md bg-light-overlay-10 hover:bg-light-overlay-15 active:bg-light-overlay-5'
}

export const SpeedControlButton = ({ value, onChange }: SpeedControlButtonProps) => {
  const currentOption = SPEED_OPTIONS.find((option) => option.value === value) ?? DEFAULT_SPEED
  const currentIndex = SPEED_OPTIONS.findIndex((option) => option.value === currentOption.value)

  const isDefaultSpeed = currentOption.value === DEFAULT_SPEED.value

  const handleChange = useCallback(
    (e: React.MouseEvent<HTMLInputElement>) => {
      if (e.currentTarget === document.activeElement) {
        e.currentTarget.blur()
      }
      const nextOption = getNextSpeedOption(currentIndex)
      onChange(nextOption.value)
    },
    [onChange, currentIndex]
  )

  return (
    <label
      data-test-id="playback-speed"
      className={`relative cursor-pointer inline-block select-none font-medium py-6 ${
        isDefaultSpeed ? computedClassName.default : computedClassName.withBackground
      }`}
    >
      <input name="playback-speed" type="button" onClick={handleChange} className="absolute w-0 h-0 opacity-0" />
      {currentOption.name}
    </label>
  )
}

export default SpeedControlButton
