import { useCallback } from 'react'
import { schema } from 'normalizr'
import { createProvider, useEntity } from '../utils'
import { useSetLayer } from './LayerProvider'
import { Paint } from './PaintProvider'

export const Shadow = new schema.Entity(
  'shadows',
  { paint: Paint },
  {
    processStrategy: (value, parent) => {
      return { ...value, styleId: parent.id, elementId: parent.elementId }
    }
  }
)

const defaultValue = {}
const [Provider, useSelectState, useSetState] = createProvider('Shadow', defaultValue)

export const useShadow = useSelectState

export const useSetShadow = () => {
  const { createLayer, destroyLayer } = useSetLayer()
  const setState = useSetState()
  const [setShadow, addShadow, updateShadow, removeShadow, mergeShadow, reduceShadow, create, destroy] = useEntity(
    Shadow,
    setState
  )

  const createShadow = useCallback(
    (entities) => {
      createLayer(entities)
      create(entities)
    },
    [create, createLayer]
  )

  const destroyShadow = useCallback(
    (entities) => {
      destroy(entities)
      destroyLayer(entities)
    },
    [destroy, destroyLayer]
  )

  return {
    setShadow,
    addShadow,
    updateShadow,
    removeShadow,
    mergeShadow,
    reduceShadow,
    createShadow,
    destroyShadow: destroyShadow
  }
}

export default Provider
