/** @typedef {import('./Vector2').Vector2} Vector2 */

/**
 *
 * @param {Vector2} upperRightR0
 * @param {Vector2} upperLeftR0
 * @param {Vector2} lowerRightR0
 * @param {Vector2} lowerLeftR0
 * @param {Vector2} upperRightR1
 * @param {Vector2} upperLeftR1
 * @param {Vector2} lowerRightR1
 * @param {Vector2} lowerLeftR1
 * @returns {boolean}
 */
export function rectsIntersect(
    upperRightR0, upperLeftR0, lowerRightR0, lowerLeftR0,
    upperRightR1, upperLeftR1, lowerRightR1, lowerLeftR1
) {
    const axes = [
        upperRightR0.clone().sub(upperLeftR0),
        upperRightR0.clone().sub(lowerRightR0),
        upperLeftR1.clone().sub(lowerLeftR1),
        upperLeftR1.clone().sub(upperRightR1),
    ]

    let isCollision = true
    for (const axis of axes) {
        if (
            isCollision &&
            !_isAxisCollision(
                axis,
                upperRightR0, upperLeftR0, lowerRightR0, lowerLeftR0,
                upperRightR1, upperLeftR1, lowerRightR1, lowerLeftR1
            )
        ) {
            isCollision = false
        }
    }

    return isCollision
}

/**
 *
 * @param {Vector2} axis
 * @param {Vector2} upperRightR0
 * @param {Vector2} upperLeftR0
 * @param {Vector2} lowerRightR0
 * @param {Vector2} lowerLeftR0
 * @param {Vector2} upperRightR1
 * @param {Vector2} upperLeftR1
 * @param {Vector2} lowerRightR1
 * @param {Vector2} lowerLeftR1
 * @returns {boolean}
 */
function _isAxisCollision(
    axis,
    upperRightR0, upperLeftR0, lowerRightR0, lowerLeftR0,
    upperRightR1, upperLeftR1, lowerRightR1, lowerLeftR1
) {
    const invSqrtLenth = 1 / axis.length_squared()
    const upperRightR0Projection = axis.dot(upperRightR0) * invSqrtLenth
    const upperLeftR0Projection  = axis.dot(upperLeftR0)  * invSqrtLenth
    const lowerRightR0Projection = axis.dot(lowerRightR0) * invSqrtLenth
    const lowerLeftR0Projection  = axis.dot(lowerLeftR0)  * invSqrtLenth
    const upperRightR1Projection = axis.dot(upperRightR1) * invSqrtLenth
    const upperLeftR1Projection  = axis.dot(upperLeftR1)  * invSqrtLenth
    const lowerRightR1Projection = axis.dot(lowerRightR1) * invSqrtLenth
    const lowerLeftR1Projection  = axis.dot(lowerLeftR1)  * invSqrtLenth

    const minR0 = Math.min(upperRightR0Projection, upperLeftR0Projection, lowerRightR0Projection, lowerLeftR0Projection)
    const maxR0 = Math.max(upperRightR0Projection, upperLeftR0Projection, lowerRightR0Projection, lowerLeftR0Projection)
    const minR1 = Math.min(upperRightR1Projection, upperLeftR1Projection, lowerRightR1Projection, lowerLeftR1Projection)
    const maxR1 = Math.max(upperRightR1Projection, upperLeftR1Projection, lowerRightR1Projection, lowerLeftR1Projection)

    const isCollision = (minR0 <= maxR1) && (maxR0 >= minR1)
    return isCollision
}
