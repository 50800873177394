import EventEmitter from 'eventemitter3'

export default class Setter extends EventEmitter {
  constructor() {
    super();
    this.CHANGE_PROPS_MAP = {};
  }

  /**
   * Update data
   * @param {string} key
   * @param {any} value
   * @param {bool} [fire=true]
   */
  set(key, value, fire = true) {
    if (this[key] === value || !this.CHANGE_PROPS_MAP[key]) {
      return;
    }

    this[key] = value;
    if (fire) {
      this.fire(this.CHANGE_PROPS_MAP[key], value);
    }
  }

  /**
   * Batch update data
   * @param {object} changes
   * @param {bool} [fire=true]
   */
  sets(changes, fire = true) {
    const updates = new Map();
    for (const key in changes) {
      if (this[key] === changes[key] || !this.CHANGE_PROPS_MAP[key]) {
        continue;
      }

      updates.set(key, changes[key]);
    }

    if (updates.size) {
      updates.forEach((value, key) => {
        this[key] = value;
      });
      if (fire) {
        this.fire('CHANGES', updates);
      }
    }
  }

  /**
   * Fire event emitter
   * @param {string} eventName
   * @param {any} value
   */
  fire(eventName, value) {
    this.emit(eventName, value);
  }
}
