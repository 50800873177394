export const LayerTypeFeature = {
  PRECOMP_LAYER: 'PRECOMP_LAYER',
  SOLID_LAYER: 'SOLID_LAYER',
  IMAGE_LAYER: 'IMAGE_LAYER',
  NULL_LAYER: 'NULL_LAYER',
  SHAPE_LAYER: 'SHAPE_LAYER',
  TEXT_LAYER: 'TEXT_LAYER',
  AUDIO_LAYER: 'AUDIO_LAYER',
  VIDEO_PLACEHOLDER_LAYER: 'VIDEO_PLACEHOLDER_LAYER',
  IMAGE_SEQUENCE_LAYER: 'IMAGE_SEQUENCE_LAYER',
  VIDEO_LAYER: 'VIDEO_LAYER',
  IMAGE_PLACEHOLDER_LAYER: 'IMAGE_PLACEHOLDER_LAYER',
  GUIDE_LAYER: 'GUIDE_LAYER',
  ADJUSTMENT_LAYER: 'ADJUSTMENT_LAYER',
  CAMERA_LAYER: 'CAMERA_LAYER',
  LIGHT_LAYER: 'LIGHT_LAYER'
}

export const MatteModeFeature = {
  MATTE_NORMAL: 'MATTE_NORMAL',
  MATTE_ALPHA: 'MATTE_ALPHA',
  MATTE_INVERTED_ALPHA: 'MATTE_INVERTED_ALPHA',
  MATTE_LUMA: 'MATTE_LUMA',
  MATTE_INVERTED_LUMA: 'MATTE_INVERTED_LUMA'
}

export const MaskModeFeature = {
  MASK_NONE: 'MASK_NONE',
  MASK_ADD: 'MASK_ADD',
  MASK_SUBTRACT: 'MASK_SUBTRACT',
  MASK_INTERSECT: 'MASK_INTERSECT',
  MASK_LIGHTEN: 'MASK_LIGHTEN',
  MASK_DARKEN: 'MASK_DARKEN',
  MASK_DIFFERENCE: 'MASK_DIFFERENCE'
}

export const LayerFeature = {
  ...LayerTypeFeature,
  ...MatteModeFeature,
  ...MaskModeFeature,
  AUTO_ORIENT: 'AUTO_ORIENT',
  PARENTING: 'PARENTING',
  MASK_INVERT: 'MASK_INVERT'
}
