import { Events, Hover } from '@phase-software/data-store'
import { ElementType } from '@phase-software/types'
import { setHoveredOrigin } from '../../panes'
import { Transform2D, Vector2 } from '../../math'
import { findHoverOrigin } from '../../visual_server/HitTest'
import { fixInfiniteSkew } from '../../visual_server/Transform'

/** @typedef {import('@phase-software/data-store/src/Element').Element} Element */
/** @typedef {import('../../visual_server/VisualServer').VisualServer} VisualServer */
/** @typedef {import('../../visual_server/SpatialCache').SceneNode} SceneNode */
/** @typedef {import('../../Viewport').Viewport} Viewport */
/** @typedef {import('../local_cursor').CursorState} CursorState */
/** @typedef {import('../../visual_server/HitTest').HitTest} HitTest */
/** @typedef {import('./../handles/Snapping').Snapping} Snapping */

/** @type {HitTest} */
let _hittest = null
/** @type {VisualServer} */
let _visualServer = null

const vec2Buffer = new Vector2()


/**
 * @param {VisualServer} visualServer
 * @param {HitTest} hitTest
 * @param {import('..').SetLocalCursorStateFn} setLocalCursorState
 */
export function initSetOriginTool(visualServer, hitTest, setLocalCursorState) {
    const { dataStore, viewport } = visualServer
    _hittest = hitTest
    _visualServer = visualServer
    let hoveredOrigin = null

    dataStore.eam.on(Events.HOVER_ORIGIN, (e) => {
        if (dataStore.data.hideOrigin) return
        if (!dataStore.getFeature('editOrigin')) return
        hoveredOrigin = _searchOrigin(viewport.toWorld(e.mousePos))
        setHoveredOrigin(hoveredOrigin)
        if (hoveredOrigin) {
            setLocalCursorState('arrowMove')
            dataStore.eam.changeHover(Hover.ORIGIN)
        }
    })

    // Drag origin
    {
        const INNER = new Transform2D()
        /** @type {import('../../visual_server/RenderItem').RenderItem} node */
        let node = null
        /** @type {import('@phase-software/data-store/element').Element} element */
        let element = null
        let update = null

        const mPos = new Vector2()
        /** @type {duVector2} */


        /** @type {Snapping} */
        const snapping = _visualServer.snapping
        const _initialMousePos = new Vector2()

        const setOriginByPixel = (mousePos, shift) => {
            const changes = {}
            const delta = vec2Buffer.copy(mousePos).sub(_initialMousePos)
            const iOrigin = _assambleGroupMatrix(node, element)

            // snapping delta pos to axis/diagonal
            const newDelta = snapping.snapAxisAndDiagonal(delta, shift, "ORIGIN")
            delta.copy(newDelta)
            delta.add(_initialMousePos).sub(mPos)

            const originOffset = node.transform.worldInv.basis_xform(delta)
            const scale = node.transform.scale
            if (scale.x === 0 || scale.y === 0) {
                originOffset.copy({
                    x: scale.x === 0 ? 0 : delta.x,
                    y: scale.y === 0 ? 0 : delta.y
                })
                INNER.a = scale.x === 0 ? 1 : INNER.a
                INNER.d = scale.y === 0 ? 1 : INNER.d
            }


            const originX = iOrigin.x + originOffset.x
            const originY = iOrigin.y + originOffset.y

            const referencePoint = element.get('referencePoint')
            changes.contentAnchorX = originX - referencePoint[0]
            changes.contentAnchorY = originY - referencePoint[1]

            const newTranslate = dataStore.drawInfo.getFixedPositionByChanges(element.get('id'), changes )
            changes.translateX = newTranslate.x
            changes.translateY = newTranslate.y
            dataStore.startTransaction()
            element.sets(changes)
            dataStore.endTransaction()

            mPos.add(delta)
        }

        /** @param {import('../../input-system/Action').ISEvent} e */
        const start = (e) => {
            if (hoveredOrigin) {
                node = visualServer.getRenderItem(hoveredOrigin)
                element = dataStore.getById(hoveredOrigin)

                mPos.copy(viewport.toWorld(e.mousePos))
                node.transform.getInnerT(INNER)


                _initialMousePos.copy(mPos)
                // update selection bounds for tracking selection origin
                snapping.setSnappingOriginalPos(node.transform.worldPivot)

                update = setOriginByPixel
            } else {
                e.handled = false
            }
        }

        const end = () => {
            update = null
            snapping.setEndSnapping()
            dataStore.get('undo').commit()
        }

        dataStore.eam
            .on(Events.START_DRAG_ORIGIN, start)
            .on(Events.UPDATE_DRAG_ORIGIN, (e, { shift }) => update(viewport.toWorld(e.mousePos), shift))
            .on(Events.END_DRAG_ORIGIN, end)
    }
}

/**
 * @param {Vector2} mousePosWorld
 * @returns {string}
 */
function _searchOrigin(mousePosWorld) {
    /** @type {SceneNode[]} */
    const selectedNodes = []
    for (const el of _visualServer.dataStore.selection.get('elements')) {
        selectedNodes.push(_hittest.state.scene.node_bank.get(el.get('id')))
    }
    /** @type {SceneNode[]} */
    const items = findHoverOrigin(mousePosWorld, selectedNodes)
    if (items.length === 0) return null
    items.sort(
        (a, b) =>
            a.item.transform.translate.distance_squared_to(mousePosWorld) - b.item.transform.translate.distance_squared_to(mousePosWorld)
    )

    // if element or its any parents are locked then don't return origin id
    if (_visualServer.dataStore.getById(items[0].id).isLocked() || _visualServer.dataStore.getById(items[0].id).get('elementType') === ElementType.SCREEN) {
        return null
    }

    return items[0].id
}

/**
 * @param {RenderItem} node
 * @param {Element} element
 */
function _assambleGroupMatrix(node, element) {
    const { referencePoint, contentAnchor } = element.gets('referencePoint', 'contentAnchor')
    const oldOrigin = new Vector2()
    oldOrigin.copy(referencePoint).add(contentAnchor)
    const { translate, rotation, scale, skew } = node.transform
    const oldTransformWorld = new Transform2D()

    oldTransformWorld.copy(node.transform.parent)
        .translate_right(translate.x, translate.y)
        .rotate_right(rotation)
        .skew_right(fixInfiniteSkew(skew.x), fixInfiniteSkew(skew.y))
        .scale_right(scale.x, scale.y)
        .translate_right(-oldOrigin.x, -oldOrigin.y)
    return oldOrigin
}
