import { vec2 } from 'gl-matrix'
import { isNull, lerp, notNull } from './commons'
import { lineNormal } from './utils'
import { Vector2 } from './Vector2'
import { AABB } from './AABB'

export class Line {
    /**
     * Creates a line in one of the following ways:
     *  - zero length line (if first argument is not defined)
     *  - from another Line-like object (that has `from` and `to` Vector2-like components)
     *  - from two Vector2-like points
     *  - from 4 components (x,y of one point and x,y of another point)
     * @param  {number | Line | Vector2} [ax]  x component of the first point OR a Line-like object, OR Vector2-like object, OR not defined
     * @param  {number | Vector2} [ay]   y component of the first point OR a Vector2-like object
     * @param  {number} [bx]    x component of the second point
     * @param  {number} [by]    y component of the second point
     */
    constructor(ax, ay, bx, by) {
        if (isNull(ax)) {
            this.from = new Vector2()
            this.to = new Vector2()
        } else if (ax.from && ax.to) {
            this.from = new Vector2(ax.from)
            this.to = new Vector2(ax.to)
        } else if (ax.x && ax.y && ay.x && ay.y) {
            this.from = new Vector2(ax)
            this.to = new Vector2(ay)
        } else {
            this.from = new Vector2(ax, ay)
            this.to = new Vector2(bx, by)
        }
    }

    /**
     * @param {Vector2} p0
     * @param {Vector2} p1
     * @returns
     */
    setP(p0, p1) {
        this.from = p0
        this.to = p1
        return this
    }

    /**
     * Transforms the line by the transformation matrix
     * @param  {mat2d} transform
     * @param  {boolean} newInstance    if true returns a new instance of the Line, instead of modifying this one
     * @returns {Line}                  resulting Line
     */
    transform(transform, newInstance = true) {
        const line = newInstance ? new Line() : this
        line.from = vec2.transformMat2d(line.from, this.from, transform)
        line.to = vec2.transformMat2d(line.to, this.to, transform)
        return line
    }

    /**
     * Test if this line intersects another line
     * @param  {[type]} ax [description]
     * @param  {[type]} ay [description]
     * @param  {[type]} bx [description]
     * @param  {[type]} by [description]
     * @returns {boolean}    true if two lines intersect; false otherwise
     */
    intersectsLine(ax, ay, bx, by) {
        let aax = typeof ax === 'number' ? ax : undefined
        if (aax === undefined) {
            aax = notNull(ax.a) ? ax.a.x : ax.x
        }
        let aay = typeof ay === 'number' ? ay : undefined
        if (aay === undefined) {
            aay = notNull(ax.a) ? ax.a.y : ax.y
        }
        let bbx = typeof bx === 'number' ? bx : undefined
        if (bbx === undefined) {
            bbx = notNull(ax.b) ? ax.b.x : ay.x
        }
        let bby = typeof by === 'number' ? by : undefined
        if (bby === undefined) {
            bby = notNull(ax.b) ? ax.b.y : ay.y
        }

        const l1_x = this.b.x - this.a.x,
            l1_y = this.b.y - this.a.y,
            l2_x = bbx - aax,
            l2_y = bby - aay,
            s = (-l1_y * (this.a.x - aax) + l1_x * (this.a.y - aay)) / (-l2_x * l1_y + l1_x * l2_y),
            t = (l2_x * (this.a.y - aay) - l2_y * (this.a.x - aax)) / (-l2_x * l1_y + l1_x * l2_y)
        return s >= 0 && s <= 1 && t >= 0 && t <= 1
    }

    /**
     * Test if this line intersects a box
     * @param  {[type]} x [description]
     * @param  {[type]} y [description]
     * @param  {[type]} width [description]
     * @param  {[type]} height [description]
     * @returns {boolean}    true if line and box intersect; false otherwise
     */
    intersectsBox(x, y, width, height) {
        const box = new AABB(x, y, width, height)

        // FIXME: This check intersects way too much for some reason
        // if (box.intersects(this.a) || box.intersects(this.b)){
        //     return true
        // }

        const topLeft = box.topLeft,
            topRight = box.topRight,
            bottomRight = box.bottomRight,
            bottomLeft = box.bottomLeft

        return (
            this.intersectsLine(topLeft, topRight) ||
            this.intersectsLine(topRight, bottomRight) ||
            this.intersectsLine(bottomRight, bottomLeft) ||
            this.intersectsLine(bottomLeft, topLeft)
        )
    }

    normal() {
        return new Vector2(lineNormal(this.from, this.to))
    }

    /**
     * @param {Vector2} p
     * @returns
     */
    nearest(p) {
        const p0 = this.a
        const d = this.getVector()
        const p1 = p0.clone().add(d)
        const dotp = d.dot(p.clone().sub(p0))
        const d_squared = d.dot(d)
        let t = 0
        let distance_sq = 0
        if (dotp <= 0) {
            t = 0
            distance_sq = p.distance_squared_to(p0)
        } else if (dotp >= d_squared) {
            t = 1
            distance_sq = p.distance_squared_to(p1)
        } else {
            t = dotp / d_squared
            distance_sq = this.eval(t).sub(p).length_squared()
        }
        return { distance_sq, t }
    }

    /**
     * The direction of the line as a vector.
     * @returns
     */
    getVector() {
        return this.to.clone().sub(this.from)
    }

    /**
     * @param {number} t
     * @returns
     */
    eval(t) {
        const vector = this.getVector()
        return this.from.clone().add(
            lerp(0, vector.x, t), 
            lerp(0, vector.y, t)
        )
    }

    get a() {
        return this.from
    }

    get b() {
        return this.to
    }
}
