import React from 'react'
import { useSelf } from '../../liveblocks.config'
import { ReactionProps } from '../shared/CommentThread/types'
import Reaction from '../shared/CommentThread/Reaction'

interface ReactionWithDataProps {
  reaction: ReactionProps
}

const ReactionWithData = (props: ReactionWithDataProps) => {
  const currentUser = useSelf()

  return <Reaction currentUserId={currentUser?.id || ''} {...props} />
}

export default ReactionWithData
