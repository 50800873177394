const AVG_FRAME_NUMBER = 5
const DEFAULT_ENABLED = false

class Stats {
    constructor() {
        this._enabled = DEFAULT_ENABLED
        this._logger = {} 
        this._reset()
    }

    help() {
        console.log("stats.activate(): enable the frame monitoring\n") 
        console.log("stats.deactivate(): disable the frame monitoring\n") 
        console.log("stats.dump(): get the monitoring result\n")
        console.log("stats.help(): show the usage of this tool\n") 
    }

    activate() {
        this._reset()
        this._enabled = true
        console.log("The frame monitoring utility is activated.")
    }

    deactivate() {
        this._reset()
        this._enabled = false
        console.log("The frame monitoring utility is deactivated.")
    }

    has(metricPath) {
        const [metricGroupName, metricName] = this._getMetricNames(metricPath)
        const group = this.metricGroups[metricGroupName]
        if (!group) {
            return false
        }
        return !!group[metricName]
    }

    get(metricPath) {
        if (!this.has(metricPath)) {
            throw new Error(`The metrics "${metricPath}" does not exist`)
        }
        const [metricGroupName, metricName] = this._getMetricNames(metricPath)
        return this.metricGroups[metricGroupName][metricName]
    }

    /**
     * @param {string} metricPath 
     */
    begin(metricPath) {
        // performance.mark(`${metricPath}-begin`)
        if (!this._enabled) {
            return
        }
        const [metricGroupName, metricName] = this._getMetricNames(metricPath)
        if (!this.metricGroups[metricGroupName]) {
            this.metricGroups[metricGroupName] = {}
        }
        if (!this.metricGroups[metricGroupName][metricName]) {
            this.metricGroups[metricGroupName][metricName] = {
                frameCounter: 0,
                deltaTime: 0,
                maxDeltaTime: 0,
                minDeltaTime: Infinity,
                deltaTimeBuffer: new Array(AVG_FRAME_NUMBER).fill(0),
                useSubTimer: false
            }
        }
        this.metricGroups[metricGroupName][metricName].beginTime = performance.now()
        this.metricGroups[metricGroupName][metricName].deltaTime = 0
        this.metricGroups[metricGroupName][metricName].useSubTimer = false
    }

    /**
     * @param {string} metricPath
     * @returns {number}
     */
    end(metricPath) {
        // performance.mark(`${metricPath}-end`)
        // performance.measure(metricPath, `${metricPath}-begin`, `${metricPath}-end`)
        if (!this._enabled) {
            return
        }
        const metric = this.get(metricPath)
        if (!metric.useSubTimer) {
            metric.deltaTime = performance.now() - metric.beginTime
        }
        this._recalculateAvgMinMax(metric)
        return metric.deltaTime
    }

    beginSub(metricPath) {
        if (!this._enabled) {
            return
        }
        const metric = this.get(metricPath)
        metric.useSubTimer = true
        metric.beginTime = performance.now()
    }

    endSub(metricPath) {
        if (!this._enabled) {
            return
        }
        const metric = this.get(metricPath)
        metric.deltaTime += performance.now() - metric.beginTime
    }

    log(name, value) {
        if (!this._enabled) {
            return
        }
        this._logger[name] = value
    }

    /**
     * @param {string} metricPath 
     * @returns {[string, string]}
     */
    _getMetricNames(metricPath) {
        const names = metricPath.split('/')
        if (names.length <= 1) {
            return ["_", names[0]]
        }
        return [names[0], names[1]]
    }

    /**
     * @param {*} metric
     */
    _recalculateAvgMinMax(metric) {
        metric.deltaTimeBuffer[metric.frameCounter++ % AVG_FRAME_NUMBER] = metric.deltaTime
        metric.maxDeltaTime = Math.max(metric.maxDeltaTime, metric.deltaTime)
        metric.minDeltaTime = Math.min(metric.minDeltaTime, metric.deltaTime)
        metric.avgDeltaTime = metric.deltaTimeBuffer.reduce((sum, dt) => (sum + dt), 0) / AVG_FRAME_NUMBER
    }

    _reset() {
        this.metricGroups = {
            _: {}, // default metric group
        }
        this._logger = {}
    }

    dump() {
        const initResult = this._logger
        return Object.keys(this.metricGroups).reduce((result, groupName) => {
            const group = this.metricGroups[groupName]
            const groupTotalTime = Object.keys(group).reduce((time, metricName) => {
                return time + group[metricName].avgDeltaTime
            }, 0)
            result[groupName] = Object.keys(group).reduce((obj, metricName) => {
                obj[metricName] = {
                    frames: group[metricName].frameCounter,
                    avg: group[metricName].avgDeltaTime,
                    min: group[metricName].minDeltaTime,
                    max: group[metricName].maxDeltaTime,
                    pct: 100.0 * (group[metricName].avgDeltaTime / groupTotalTime)
                }
                return obj
            }, { })
            return result
        }, initResult)
    }
}

const stats = new Stats()

if (typeof window !== 'undefined') {
    window.stats = stats
}

export default stats
