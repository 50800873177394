import React, { createRef } from 'react'
import ReactDOM from 'react-dom'
import FocusLoop from '../FocusLoop'
import { ReactComponent as Arrow } from './arrow.svg'
import { minmax } from '../../../utils/number'

const OVERFLOW_PADDING = 8
const ARROW_SIZE = 7

type ModalProps = {
  children?: React.ReactNode
  className?: string
  dataTestId?: string
  draggable?: boolean
  offsetX?: number
  offsetY?: number
  overflowPadding?: number
  onClose: () => void
  open: boolean
  placement?: 'top' | 'left' | 'right' | 'bottom' | 'vertical' | 'screen-center' | 'horizontal' | 'cursor'
  placementTopFirst?: boolean
  showArrow?: boolean
  sticky?: boolean
  style?: React.CSSProperties
  trigger?: React.RefObject<HTMLElement>
  zIndex?: number
  onMouseLeave?: (e: React.MouseEvent<HTMLFormElement>) => void
  onMouseEnter?: (e: React.MouseEvent<HTMLFormElement>) => void
}

type ModalState = {
  arrowStyle?: React.CSSProperties
  computedPlacement?: 'top' | 'left' | 'right' | 'bottom' | 'screen-center' | 'cursor'
  mouseDownPosition?: {
    top: number
    left: number
    width: number
    height: number
  }
}

type ModalArrowProps = {
  placement: ModalState['computedPlacement']
  style?: React.CSSProperties
}

const _setArrowStyle = ({ style, placement }: ModalArrowProps) => {
  const baseStyle = style || {}
  switch (placement) {
    case 'bottom':
      return { ...baseStyle, transform: 'translate(-50%, -6px)' }
    case 'top':
      return { ...baseStyle, transform: 'translate(-50%, -1px) rotate(180deg)' }
    case 'left':
      return { ...baseStyle, transform: 'translate(-3.5px, -50%) rotate(90deg)' }
    case 'right':
      return { ...baseStyle, transform: 'translate(-8.5px, -50%) rotate(270deg)' }
    default:
      return baseStyle
  }
}

export default class Modal extends React.Component<ModalProps, ModalState> {
  static defaultProps = {
    showArrow: false,
    className: '',
    draggable: false,
    offsetX: 0,
    offsetY: 0,
    overflowPadding: OVERFLOW_PADDING,
    placement: 'vertical',
    sticky: false,
    zIndex: 10
  }

  state = {
    computedPlacement: undefined,
    arrowStyle: undefined,
    mouseDownPosition: {
      top: 0,
      left: 0,
      width: 0,
      height: 0
    }
  }

  portalNode: HTMLDivElement
  modalRef: React.RefObject<HTMLFormElement>
  arrowRef: React.RefObject<HTMLDivElement>
  observer: ResizeObserver | null
  triggerRect?: Omit<DOMRect, 'toJSON'>
  isDragging: boolean
  draggingOffset: { x: number; y: number }
  onMouseLeave: ModalProps['onMouseLeave']
  onMouseEnter: ModalProps['onMouseEnter']

  constructor(props: ModalProps) {
    super(props)
    this.portalNode = document.createElement('div')
    this.modalRef = createRef()
    this.arrowRef = createRef()
    this.observer = null
    this.triggerRect = undefined
    this.isDragging = false
    this.draggingOffset = { x: 0, y: 0 }
    this.onMouseLeave = props.onMouseLeave
    this.onMouseEnter = props.onMouseEnter
  }

  componentDidMount() {
    const modalRoot = document.querySelector('#modal') || document.body
    modalRoot.appendChild(this.portalNode)
    document.addEventListener('mousedown', this.handleClick, true)
    document.body.addEventListener('contextmenu', this.handleClick)

    if (!this.props.trigger) {
      document.addEventListener('mousedown', this.handleMouseDown)
    }

    if (this.props.sticky && this.modalRef.current) {
      this.observer = new ResizeObserver(() => {
        if (this.props.open) this.calculatePosition()
      })
      this.observer.observe(this.modalRef.current)
    }
  }

  componentDidUpdate(prevProps: ModalProps) {
    const { open, placement, trigger } = this.props
    if (!open && this.modalRef.current) {
      this.modalRef.current.scrollTop = 0
      this.modalRef.current.removeAttribute('style')
      return
    }
    if (prevProps.open === open && placement === prevProps.placement) {
      return
    }

    if (trigger && trigger.current) {
      this.triggerRect = trigger.current.getBoundingClientRect()
    }
    this.calculatePosition()
  }

  componentWillUnmount() {
    const modalRoot = document.querySelector('#modal') || document.body

    if (modalRoot.contains(this.portalNode)) {
      modalRoot.removeChild(this.portalNode)
    }

    document.removeEventListener('mousedown', this.handleClick, true)
    document.body.removeEventListener('contextmenu', this.handleClick)

    document.removeEventListener('mousedown', this.handleMouseDown)

    if (this.observer) {
      this.observer.disconnect()
    }
  }

  handleClick = (e: MouseEvent) => {
    if (!this.props.open) return

    const isClickInsideModal = this.portalNode.contains(e.target as HTMLElement)

    if (!isClickInsideModal) {
      this.handleOutsideClick(e)
    }
  }

  handleOutsideClick = (e: MouseEvent) => {
    const { className, onClose, trigger } = this.props

    const isClickInsideSpecificDiv = (e.target as HTMLElement)?.closest('.bottom-of-property-panel')
    if (isClickInsideSpecificDiv) {
      onClose?.()
    }

    const isSelectMenu = className?.includes('select-menu')
    const isDropdownMenu = className?.includes('dropdown-menu')
    const isContextMenu = className?.includes('context-menu')
    const isPopover = className?.includes('popover')

    const isClickInsideTrigger = trigger && trigger.current?.contains(e.target as HTMLElement)
    const isClickInsideSelectMenu = (e.target as HTMLElement)?.closest('.select-menu')
    const isClickInsideContextMenu = (e.target as HTMLElement)?.closest('.context-menu')

    if (
      (isSelectMenu || isContextMenu || isDropdownMenu || isPopover) &&
      !isClickInsideTrigger &&
      !isClickInsideSelectMenu &&
      !isClickInsideContextMenu
    ) {
      onClose?.()
    }
  }

  computeModalPlacement = () => {
    const {
      offsetX = 0,
      offsetY = 0,
      showArrow,
      placement,
      placementTopFirst,
      overflowPadding = OVERFLOW_PADDING
    } = this.props
    const { top = 0, left = 0, width = 0, height = 0 } = this.triggerRect || this.state.mouseDownPosition
    const { offsetWidth = 0, offsetHeight = 0 } = this.modalRef.current || {}
    const { innerWidth, innerHeight } = window

    const arrowOffset = Number(showArrow) * ARROW_SIZE + overflowPadding

    switch (placement) {
      case 'top':
      case 'left':
      case 'right':
      case 'bottom':
      case 'cursor':
      case 'screen-center':
        return placement
      case 'horizontal':
        return left + width + offsetWidth + arrowOffset + offsetX <= innerWidth ? 'right' : 'left'
      case 'vertical':
        return placementTopFirst
          ? top - offsetHeight - arrowOffset - offsetY > 0
            ? 'top'
            : 'bottom'
          : top + height + offsetHeight + arrowOffset + offsetY <= innerHeight
          ? 'bottom'
          : 'top'
      default:
        throw new Error(`Unknown modal placement: ${placement}`)
    }
  }

  calculateModalPosition = (computedPlacement: ModalState['computedPlacement']) => {
    const { showArrow, offsetX = 0, offsetY = 0, overflowPadding = OVERFLOW_PADDING } = this.props
    const { top = 0, left = 0, width = 0, height = 0 } = this.triggerRect || this.state.mouseDownPosition
    const { offsetWidth = 0, offsetHeight = 0 } = this.modalRef.current || {}
    const { innerWidth, innerHeight } = window

    const arrowOffset = Number(showArrow) * ARROW_SIZE

    const safeTop = Math.max(
      Math.min(top + height / 2 - offsetHeight / 2, innerHeight - offsetHeight - overflowPadding),
      overflowPadding
    )
    const safeLeft = Math.max(
      Math.min(left + width / 2 - offsetWidth / 2 + offsetX, innerWidth - offsetWidth - overflowPadding),
      overflowPadding
    )

    let start, end

    switch (computedPlacement) {
      case 'cursor': {
        let newTop = top + height + offsetY
        let newLeft = left + offsetX
        let newBottom
        const overflowX = innerWidth - (newLeft + offsetWidth + overflowPadding)
        const overflowY = innerHeight - (newTop + offsetHeight + overflowPadding)

        if (overflowX < 0) {
          newLeft += overflowX
        }

        if (newLeft < overflowPadding) {
          newLeft = overflowPadding
        }

        if (overflowY < 0) {
          // trigger by cursor
          if (height === 0 && width === 0) {
            newTop = minmax(newTop, overflowPadding, innerHeight - offsetHeight - overflowPadding)

            if (newTop === overflowPadding) {
              newBottom = overflowPadding
            }
          } else {
            newBottom = overflowPadding
          }
        }

        return {
          top: newTop,
          left: newLeft,
          bottom: newBottom
        }
      }
      case 'top': {
        start = top - offsetHeight - arrowOffset - offsetY
        const isTopOverflow = start < overflowPadding
        if (isTopOverflow) {
          start = overflowPadding
          end = innerHeight - top
        }
        return { top: start, left: safeLeft, bottom: end }
      }
      case 'left':
        return { top: safeTop, left: left - offsetWidth - arrowOffset - offsetX }
      case 'right':
        return { top: safeTop, left: left + width + arrowOffset + offsetX }
      case 'bottom': {
        start = top + height + arrowOffset + offsetY
        const isBottomOverflow = start + offsetHeight + overflowPadding > innerHeight

        if (isBottomOverflow) {
          end = overflowPadding
        }
        return { top: start, left: safeLeft, bottom: end }
      }
      case 'screen-center':
        return {
          top: innerHeight / 2 - offsetHeight / 2,
          left: innerWidth / 2 - offsetWidth / 2
        }
      default:
        throw new Error(`Unknown arrow placement: ${computedPlacement}`)
    }
  }

  calculateArrowPosition = (
    computedPlacement: ModalState['computedPlacement'],
    modalPosition: Record<string, number | undefined>
  ) => {
    const { top = 0, left = 0, width = 0, height = 0 } = this.triggerRect || this.state.mouseDownPosition
    const { top: modalTop = 0, left: modalLeft = 0 } = modalPosition

    const newTop = top - modalTop + height / 2
    const newLeft = left - modalLeft + width / 2

    switch (computedPlacement) {
      case 'top':
        return { top: '100%', left: newLeft }
      case 'left':
        return { top: newTop, left: '100%' }
      case 'right':
        return { top: newTop, left: 0 }
      case 'bottom':
        return { top: -1, left: newLeft }
      default:
        throw new Error(`Unknown arrow placement: ${computedPlacement}`)
    }
  }

  convertPositionToStyle = (position: Record<string, number | string | undefined>) => {
    return Object.entries(position).reduce((acc: Record<string, string | undefined>, [key, value]) => {
      acc[key] = typeof value === 'number' ? `${value}px` : value
      return acc
    }, {})
  }

  calculatePosition = () => {
    const { showArrow } = this.props
    const computedPlacement = this.computeModalPlacement()

    this.setState({ computedPlacement })

    const modalPosition = this.calculateModalPosition(computedPlacement)
    const modalStyle = this.convertPositionToStyle(modalPosition)

    Object.assign(this.modalRef.current?.style || {}, modalStyle)

    if (showArrow) {
      const arrowPosition = this.calculateArrowPosition(computedPlacement, modalPosition)
      const arrowStyle = this.convertPositionToStyle(arrowPosition)
      this.setState({ arrowStyle })
    }
  }

  handleDragStart = (e: MouseEvent | React.MouseEvent) => {
    if ((e.target as HTMLElement).tagName === 'INPUT' || !this.modalRef.current) return

    this.isDragging = true

    const modalRect = this.modalRef.current.getBoundingClientRect()

    this.draggingOffset = {
      x: e.pageX - modalRect.left,
      y: e.pageY - modalRect.top
    }
    window.addEventListener('mousemove', this.handleDragMove)
    window.addEventListener('mouseup', this.handleDragEnd, { once: true })
  }

  handleDragMove = (e: MouseEvent) => {
    if (!this.isDragging || !this.modalRef.current) return

    const { innerWidth, innerHeight } = window
    const modalRect = this.modalRef.current.getBoundingClientRect()

    // Calculate new position
    let newTop = e.pageY - this.draggingOffset.y
    let newLeft = e.pageX - this.draggingOffset.x

    // Restrict to within viewport boundaries
    newTop = Math.min(Math.max(newTop, 0), innerHeight - modalRect.height)
    newLeft = Math.min(Math.max(newLeft, 0), innerWidth - modalRect.width)

    Object.assign(this.modalRef.current.style, {
      top: `${newTop}px`,
      left: `${newLeft}px`,
      right: 'auto',
      bottom: 'auto'
    })
  }

  handleDragEnd = () => {
    this.isDragging = false
    window.removeEventListener('mousemove', this.handleDragMove)
  }

  handleMouseDown = (e: MouseEvent | React.MouseEvent) => {
    e.stopPropagation()
    this.setState({ mouseDownPosition: { top: e.clientY, left: e.clientX, width: 0, height: 0 } })
  }

  handleMouseEnter = (e: React.MouseEvent<HTMLFormElement>) => {
    e.stopPropagation()
    if (this.onMouseEnter) this.onMouseEnter(e)
  }

  render() {
    const { open, className, zIndex, draggable, showArrow, style, dataTestId } = this.props
    const { computedPlacement, arrowStyle } = this.state

    const visibleClassName = open ? '' : 'invisible opacity-0'
    const bgClassName = draggable ? 'bg-neutral-80 border-neutral-70' : 'bg-neutral-80'

    return ReactDOM.createPortal(
      <FocusLoop
        ref={this.modalRef}
        onMouseDown={draggable ? this.handleDragStart : this.handleMouseDown}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.onMouseLeave}
        dataTestId={dataTestId}
        aria-modal="true"
        aria-hidden={open ? 'false' : 'true'}
        className={`fixed rounded-lg shadow-5 overflow-hidden ${bgClassName} ${visibleClassName} ${className}`}
        style={{
          zIndex,
          willChange: 'top, left',
          ...style
        }}
      >
        {this.props.children}
        {showArrow && computedPlacement !== null && (
          <div
            ref={this.arrowRef}
            className="absolute"
            style={_setArrowStyle({ style: arrowStyle, placement: computedPlacement })}
          >
            <Arrow />
          </div>
        )}
      </FocusLoop>,
      this.portalNode
    )
  }
}
