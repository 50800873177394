import React from 'react'
import { useTranslation } from 'react-i18next'

import { useAtom } from 'jotai'

import { nonChromiumWarning } from '../atoms'
import { Dialog, Icon } from './shared'

const CHROME_WEBSITE = 'https://www.google.com/chrome/'

const withChromium = (WrappedComponent: React.ComponentType<any>) => {
  const WithChromium = (props: any) => {
    const { t } = useTranslation('common')
    const [isWarningOpen, dismissWarning] = useAtom(nonChromiumWarning)
    const toChromeWebsite = () => {
      window.open(CHROME_WEBSITE, '_blank')
    }
    return (
      <>
        <WrappedComponent {...props} />
        <Dialog
          data-test-id="browser-recommendation-dialog"
          title={t('use_chrome_for_best_experience')}
          confirmBtnText={t('continue_with_chrome')}
          onConfirm={toChromeWebsite}
          onCancel={dismissWarning}
          size="xs"
          open={isWarningOpen}
        >
          <div className="flex flex-row gap-x-8">
            <Icon name="Warning" className="text-gold-40" size={24} useCurrentColor interactive={false} />
            <p>{t('use_chrome_for_best_experience_description')}</p>
          </div>
        </Dialog>
      </>
    )
  }

  return WithChromium
}

export default withChromium
