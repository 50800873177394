import { useCallback } from 'react'

import { schema } from 'normalizr'

import { createProvider, useEntity } from '../utils'
import { useDataStore } from './DataStoreProvider'

export const Element = new schema.Entity('elements')
Element.define({ children: [Element] })

const defaultValue = {}
const [Provider, useSelectState, useSetState, getStateSnapshot] = createProvider('Element', defaultValue)

export const useElement = useSelectState
export const getElementSnapshot = getStateSnapshot

export const useSetElement = () => {
  const setState = useSetState()
  const [setElement, addElement, updateElement, removeElement, mergeElement, reduceElement, create, destroy] =
    useEntity(Element, setState)

  const createElement = useCallback(
    (entities) => {
      create(entities)
    },
    [create]
  )

  const destroyElement = useCallback(
    (entities) => {
      destroy(entities)
    },
    [destroy]
  )

  const addElementStyle = useCallback(
    (id, styleId, index) =>
      updateElement(id, (state) => ({
        ...state,
        styles: [...state.styles.slice(0, index), styleId, ...state.styles.slice(index)]
      })),
    [updateElement]
  )

  const removeElementStyle = useCallback(
    (id, styleId) =>
      updateElement(id, (state) => ({
        ...state,
        selectedStyle: state.selectedStyle === styleId ? state.styles[0] : state.selectedStyle,
        styles: state.styles.filter((s) => s !== styleId)
      })),
    [updateElement]
  )

  return {
    setElement,
    addElement,
    updateElement,
    removeElement,
    mergeElement,
    reduceElement,
    createElement,
    destroyElement,
    addElementStyle,
    removeElementStyle
  }
}

export const useElementActions = () => {
  const dataStore = useDataStore()

  const toggleExpand = useCallback(
    (id, expanded, toggleDescendant = false) => {
      const el = dataStore.getElement(id)
      el.set('expanded', expanded)
      if (toggleDescendant) {
        const queue = el.children.filter((o) => o.children)
        while (queue.length) {
          const el = queue.pop()
          el.set('expanded', expanded)
          queue.push(...el.children.filter((o) => o.children))
        }
      }
    },
    [dataStore]
  )

  const setElementName = useCallback(
    (id, name) => {
      const el = dataStore.getElement(id)
      el.set('name', name)
      dataStore.commitUndo()
    },
    [dataStore]
  )

  const toggleLock = useCallback(
    (id) => {
      const el = dataStore.getElement(id)
      el.set('locked', !el.get('locked'))
      dataStore.commitUndo()
    },
    [dataStore]
  )

  const toggleVisible = useCallback(
    (id) => {
      dataStore.startTransaction()
      const el = dataStore.getElement(id)
      el.set('visible', !el.get('visible'))
      dataStore.endTransaction()
      dataStore.commitUndo()
    },
    [dataStore]
  )

  const setElementsVisible = useCallback(
    (id, visible) => {
      dataStore.startTransaction()
      id.forEach((elementId) => {
        const el = dataStore.getElement(elementId)
        el.set('visible', visible)
      })
      dataStore.endTransaction()
      dataStore.commitUndo()
    },
    [dataStore]
  )

  return {
    setElementName,
    toggleExpand,
    toggleLock,
    toggleVisible,
    setElementsVisible
  }
}

export default Provider
