import { IRNodeType } from './constants'
import { IRScreen } from './IRScreen'
import { IRPath } from './IRPath'
import { IRContainer } from './IRContainer'
import { IRPrecomposition } from './IRPrecomposition'
import { IRComputedGroup } from './IRComputedGroup'
import { IRBoolean } from './IRBoolean'
import { IRRectangle } from './IRRectangle'
import { IRImageMask } from './IRImageMask'
import { IREllipse } from './IREllipse'
import { IRImage } from './IRImage'

let _id = 0
export const id = () => ++_id

export const resetId = () => {
  _id = 0
}

export const nodeTypeMapClass = {
  [IRNodeType.SCREEN]: IRScreen,
  [IRNodeType.PATH]: IRPath,
  [IRNodeType.CONTAINER]: IRContainer,
  [IRNodeType.PRECOMPOSITION]: IRPrecomposition,
  [IRNodeType.COMPUTED_GROUP]: IRComputedGroup,
  [IRNodeType.BOOLEAN]: IRBoolean,
  [IRNodeType.RECTANGLE]: IRRectangle,
  [IRNodeType.IMAGE_MASK]: IRImageMask,
  [IRNodeType.ELLIPSE]: IREllipse,
  [IRNodeType.IMAGE]: IRImage
}
