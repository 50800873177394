import { PERMISSIONS } from '../access-control'
import { MenuOptionProps } from '../components/shared/Menu/Menu.types'

export const WORKSPACE_POPOVER_OPTIONS = {
  ADD_NEW_TEAM: 'add-new-team',
  TEAM_SETTINGS: 'team-settings',
  LEAVE_TEAM: 'leave-team',
  ACCOUNT_SETTING: 'account-setting',
  SIGN_OUT: 'sign-out'
}
export const TEAM_OPTIONS: MenuOptionProps[] = [
  {
    name: 'team_settings',
    value: 'team-settings',
    permission: PERMISSIONS.ACCESS_TEAM_GENERAL_PAGE,
    dataTestId: 'team-settings'
  },
  { name: 'leave', value: 'leave-team', dataTestId: 'user-leave-team' },
  { name: 'account_settings', value: 'account-setting', dataTestId: 'user-menu' },
  { name: 'sign_out', value: 'sign-out', dataTestId: 'user-logout' }
]

export const ADD_NEW_TEAM_OPTION: MenuOptionProps = {
  name: 'add_new_team',
  value: WORKSPACE_POPOVER_OPTIONS.ADD_NEW_TEAM,
  dataTestId: 'add-new-team'
}
