import { EventEmitter } from 'eventemitter3'
import { Transform2D, Vector2 } from '../math'

/** @typedef {import("../math/Vector2").Vector2Like} Vector2Like */

/**
 * @typedef {object} TransformData
 * @property {Vector2} translate
 * @property {Vector2} size
 * @property {Vector2} referencePoint
 * @property {Vector2} contentAnchor
 * @property {Vector2} scale
 * @property {Vector2} skew
 * @property {number} rotation
 */

// TODO: need tests for this

export class Transform extends EventEmitter {

    /**
     * @param {Transform} [refTransform]
     */
    constructor(refTransform = undefined) {
        super()

        this._ref = refTransform

        this._init()
    }

    get ref() {
        return this._ref
    }

    /**
     * Connect the reference transform.
     * When reference transform is connected all values are taken from reference transform
     * @param {Transform} refTransform
     */
    connectRef(refTransform) {
        if (!refTransform) {
            return
        }
        if (this._ref !== refTransform) {
            this._clean()
        }
        this._ref = refTransform
    }

    /**
     * Disconnect the reference transform.
     * When reference transform is connected all values are taken from reference transform
     */
    disconnectRef() {
        if (!this._ref) {
            return
        }
        this._ref = undefined
        this._init()
    }

    _init() {
        if (!this._ref) {
            this._local = new Transform2D()
            this._world = new Transform2D()
            this._worldInv = new Transform2D()
            this._parent = new Transform2D()

            this._saveLocal = new Transform2D()
            this._saveWorld = new Transform2D()

            /** @type {TransformData} */
            this._data = {
                referencePoint: new Vector2(0, 0),
                contentAnchor: new Vector2(0, 0),
                translate: new Vector2(),
                size: new Vector2(),
                // for skew, scale and rotation
                scale: new Vector2(1, 1),
                skew: new Vector2(0, 0),
                rotation: 0,
                orient: 0
            }

            this._dirty = true
        }
    }

    reset() {
        this.removeAllListeners()

        this._local.identity()
        this._world.identity()
        this._worldInv.identity()
        this._parent.identity()

        this._saveLocal.identity()
        this._saveWorld.identity()

        this._data.referencePoint.set(0, 0)
        this._data.contentAnchor.set(0, 0)
        this._data.translate.set(0, 0)
        this._data.size.set(0, 0)
        this._data.scale.set(1, 1)
        this._data.skew.set(0, 0)
        this._data.rotation = 0

        this._dirty = true

        return true
    }

    clean() {
        this.removeAllListeners()

        this._clean()
    }

    _clean() {
        this._local = undefined
        this._world = undefined
        this._worldInv = undefined
        this._parent = undefined

        this._saveLocal = undefined
        this._saveWorld = undefined

        this._data = undefined

        this._dirty = true
        return true
    }

    /**
     * @readonly
     * @returns {Transform2D}
     */
    get local() {
        if (this._ref) {
            return this._ref.local
        }
        return this._local
    }

    /**
     * @readonly
     * @returns {Transform2D}
     */
    get world() {
        if (this._ref) {
            return this._ref.world
        }
        return this._world
    }

    /**
     * @readonly
     * @returns {Transform2D}
     */
    get saveLocal() {
        if (this._ref) {
            return this._ref.saveLocal
        }
        return this._saveLocal
    }

    /**
     * @readonly
     * @returns {Transform2D}
     */
    get saveWorld() {
        if (this._ref) {
            return this._ref.saveWorld
        }
        return this._saveWorld
    }

    /**
     * @readonly
     * @returns {Transform2D}
     */
    get worldInv() {
        if (this._ref) {
            return this._ref.worldInv
        }
        return this._worldInv
    }

    /**
     * @readonly
     * @returns {Transform2D}
     */
    get parent() {
        if (this._ref) {
            return this._ref.parent
        }
        return this._parent
    }

    /**
     * @readonly
     * @returns {Vector2}
     */
    get position() {
        if (this._ref) {
            return this._ref.position
        }
        return this._data.position
    }

    /**
     * @readonly
     * @returns {Vector2}
     */
    get size() {
        if (this._ref) {
            return this._ref.size
        }
        return this._data.size
    }

    /**
     * @readonly
     * @returns {Vector2}
     */
    get scale() {
        if (this._ref) {
            return this._ref.scale
        }
        return this._data.scale
    }

    /**
     * @readonly
     * @returns {Vector2}
     */
    get skew() {
        if (this._ref) {
            return this._ref.skew
        }
        return this._data.skew
    }

    /**
     * @readonly
     * @returns {Vector2}
     */
    get referencePoint() {
        if (this._ref) {
            return this._ref.referencePoint
        }
        return this._data.referencePoint
    }

    /**
     * (aka origin, aka anchor in lottie)
     * @readonly
     * @returns {Vector2}
     */
    get contentAnchor() {
        if (this._ref) {
            return this._ref.contentAnchor
        }
        return this._data.contentAnchor
    }

    /** @returns {number} */
    get rotation() {
        if (this._ref) {
            return this._ref.rotation
        }
        return this._data.rotation
    }

    /**
     * @readonly
     * @returns {Vector2}
     */
    get translate() {
        return this._ref ? this._ref.translate : this._data.translate
    }
    /**
     * Generates pooled Vector2 pivot offset
     * @returns {Vector2} offset
     */
    getPivotOffset() {
        return this.referencePoint.clone().add(this.contentAnchor)
    }

    get worldPivot() {
        const offset = this.translate.clone()
        const ret = this.parent.xform(offset, offset)
        return ret
    }

    /** @param {number} value */
    setTranslateX(value) {
        this._data.translate.x = value
        this._dirty = true
    }

    /** @param {number} value */
    setTranslateY(value) {
        this._data.translate.y = value
        this._dirty = true
    }

    /** @param {Vector2Like} value */
    setReferencePoint(value) {
        this._data.referencePoint.copy(value)
        this._dirty = true
    }

    /** @param {Vector2Like} value */
    setContentAnchor(value) {
        this._data.contentAnchor.copy(value)
        this._dirty = true
    }

    /** @param {Vector2Like} value */
    setScale(value) {
        this._data.scale.copy(value)
        this._dirty = true
    }

    /** @param {Vector2Like} value */
    setSkew(value) {
        this._data.skew.copy(value)
        this._dirty = true
    }

    /** @param {number} value */
    setRotation(value) {
        this._data.rotation = value
        this._dirty = true
    }

    /** @param {number} value */
    setOrient(value) {
        this._data.orient = value
        this._dirty = true
    }

    /** @param {Vector2Like} value */
    setSize(value) {
        this._data.size.copy(value)
        this._dirty = true
    }

    /**
     * @todo #transform Replace it by matrix operation
     * @param {Transform2D} [out]
     * @returns {Transform2D} the transform before origin translation
     */
    getInnerT(out = new Transform2D()) {
        const { rotation, scale, skew } = this

        return out.reset()
            .rotate_right(rotation)
            .skew_right(fixInfiniteSkew(skew.x), fixInfiniteSkew(skew.y))
            .scale_right(scale.x, scale.y)
    }

    /**
     * @returns {boolean}
     */
    update() {
        if (this._ref || !this._dirty) {
            return false
        }
        this._dirty = false

        const { translate, rotation, scale, skew } = this

        const pivotOffset = this.getPivotOffset()

        this._local.reset()
            .translate_right(translate.x, translate.y)
            .rotate_right(rotation)
            .skew_right(fixInfiniteSkew(skew.x), fixInfiniteSkew(skew.y))
            .scale_right(scale.x, scale.y)
            .translate_right(-pivotOffset.x, -pivotOffset.y)
        this._saveLocal.reset()
            .translate_right(translate.x, translate.y)
            .rotate_right(rotation)
            .skew_right(
                Math.abs(skew.x + Math.PI * 0.5) % Math.PI === 0 ? 0 : skew.x,
                Math.abs(skew.y + Math.PI * 0.5) % Math.PI === 0 ? 0 : skew.y
            )
            .scale_right(scale.x === 0 ? 1 : scale.x, scale.y === 0 ? 1 : scale.y)
            .translate_right(-pivotOffset.x, -pivotOffset.y)
        return true
    }

    /**
     * @param {Transform} parentTransform
     * @returns {boolean}
     */
    updateWorld(parentTransform) {
        if (this._ref) {
            return false
        }
        this.parent
            .copy(parentTransform.world)

        this.world
            .copy(parentTransform.world)
            .append(this.local)
        this.worldInv
            .copy(this.world)
            .affine_inverse()

        this.saveWorld
            .copy(parentTransform.saveWorld)
            .append(this.saveLocal)

        this.emit('updateWorld')
        return true
    }
}

const HALF_PI = Math.PI / 2
const INF_SKEW_THRESHOLD = 1e-3

/**
 * @param {number} skew
 * @returns {number}
 */
export function fixInfiniteSkew(skew) {
    let fixed_skew = skew % Math.PI
    if (Math.abs(fixed_skew / HALF_PI - 1) < INF_SKEW_THRESHOLD || Math.abs(fixed_skew / HALF_PI - 3) < INF_SKEW_THRESHOLD) {
        fixed_skew += (INF_SKEW_THRESHOLD / 180 * Math.PI) * Math.sign(fixed_skew)
    }
    return fixed_skew
}
