import { schema } from 'normalizr'
import { createProvider, useEntity } from '../utils'

export const TextAlignment = new schema.Entity(
  'textAlignments',
  { data: {} },
  {
    processStrategy: (value, parent) => {
      return { ...value, styleId: parent.id }
    }
  }
)

const defaultValue = {}
const [Provider, useSelectState, useSetState] = createProvider('TextAlignment', defaultValue)

export const useTextAlignment = useSelectState

export const useSetTextAlignment = () => {
  const setState = useSetState()
  const [
    setTextAlignment,
    addTextAlignment,
    updateTextAlignment,
    removeTextAlignment,
    mergeTextAlignment,
    reduceTextAlignment,
    createTextAlignment,
    destroyTextAlignment
  ] = useEntity(TextAlignment, setState)

  return {
    setTextAlignment,
    addTextAlignment,
    updateTextAlignment,
    removeTextAlignment,
    mergeTextAlignment,
    reduceTextAlignment,
    createTextAlignment,
    destroyTextAlignment
  }
}

export default Provider
