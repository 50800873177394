export const registerServiceWorker = () => {
  if (process.env.NODE_ENV === 'production') {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker
          .register(`${process.env.PUBLIC_URL}/service-worker.js`)
          .then((registration) => {
            console.log('Service Worker registered: ', registration)
          })
          .catch((registrationError) => {
            console.log('Service Worker registration failed: ', registrationError)
          })
      })
    }
  }
}
