import PropertyStack from '../../PropertyStack'

class SpreadStack extends PropertyStack {
  constructor(elementStack, data) {
    super(elementStack, data)
    this.type = 'SPREAD'
    this.key = 'spread'
    this.dataKey = 'spread'
    this.animatableProperties = new Set(['spread'])
  }
}

export default SpreadStack
