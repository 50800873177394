import React from 'react'
import Tooltip from '../Tooltip'
import Icon from '../Icon'
import { useDataStoreActions } from '../../../providers/dataStore/DataStoreProvider'

export type RadioButtonProps = {
  tooltip?: string
  name: string // NOTE: name should be unique
  value: string | number
  icon?: string
  checked?: boolean
  disabled?: boolean
  fluid?: boolean
  onChange: (value: string) => void
  onBlur?: (e: React.FocusEvent) => void
  onFocus?: (e: React.FocusEvent) => void
  moveSelection?: (step: 1 | -1) => void
  className?: string
  dataTestId?: string
  children?: React.ReactNode
}

const RadioButton = ({
  tooltip,
  name,
  value,
  icon,
  checked = false,
  disabled = false,
  fluid = false,
  onChange,
  onFocus,
  onBlur,
  moveSelection,
  className = '',
  dataTestId,
  children
}: RadioButtonProps) => {
  const { changeEAMInputState } = useDataStoreActions()

  const paddingClassName = React.useMemo(() => {
    if (children && icon) {
      return fluid ? 'py-6 pr-2' : 'py-6 pl-6 pr-8'
    }
    return 'p-6'
  }, [icon, fluid, children])

  const handleFocus = (e: React.FocusEvent) => {
    onFocus?.(e)
    changeEAMInputState(true)
  }
  const handleBlur = (e: React.FocusEvent) => {
    onBlur?.(e)
    changeEAMInputState(false)
  }
  const updateSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.currentTarget.value)
  }
  const handleClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.currentTarget.blur()
  }
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault()
    switch (e.key) {
      case 'ArrowUp':
      case 'ArrowLeft':
        moveSelection?.(-1)
        break
      case 'ArrowDown':
      case 'ArrowRight':
        moveSelection?.(1)
        break
      case ' ':
      case 'Enter':
        moveSelection?.(1)
        break
      case 'Escape':
        e.currentTarget.blur()
        e.stopPropagation()
        break
      default:
        break
    }
  }

  return (
    <Tooltip content={tooltip}>
      <label htmlFor={name} className={`w-full ${className}`}>
        <input
          type="radio"
          tabIndex={checked ? 0 : -1}
          id={name}
          value={value}
          checked={checked}
          disabled={disabled}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          onClick={handleClick}
          onChange={updateSelection}
          className="peer absolute opacity-0 w-0 h-0"
          data-test-id={dataTestId}
        />
        <div className="h-full w-full flex justify-center items-center rounded-md cursor-pointer hover:text-white active:text-light-overlay-40 peer-checked:text-white peer-checked:active:text-white peer-disabled:text-current peer-disabled:active:text-current peer-disabled:cursor-not-allowed">
          <div className={`grid grid-flow-col gap-x-4 ${paddingClassName}`}>
            {icon ? <Icon name={icon} interactive={false} useCurrentColor /> : null}
            {children}
          </div>
        </div>
      </label>
    </Tooltip>
  )
}

export default RadioButton
