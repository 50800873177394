import { PropComponentType } from '@phase-software/types'
import { isArr } from '@phase-software/data-utils'
import BlurGaussianComponent from './BlurGaussianComponent'
import DimensionsComponent from './DimensionsComponent'
import FontComponent from './FontComponent'
import OverflowComponent from './OverflowComponent'
import OpacityComponent from './OpacityComponent'
import OriginComponent from './OriginComponent'
import ReferencePointComponent from './ReferencePointComponent'
import ContentAnchorComponent from './ContentAnchorComponent'
import CornerRadiusComponent from './CornerRadiusComponent'
import TranslateComponent from './TranslateComponent'
import RotationComponent from './RotationComponent'
import ScaleComponent from './ScaleComponent'
// import ScrollComponent from './ScrollComponent'
import SkewComponent from './SkewComponent'
import FillComponent from './FillComponent'
import StrokeComponent from './StrokeComponent'
import ShadowComponent from './ShadowComponent'
import InnerShadowComponent from './InnerShadowComponent'
import TextAlignmentComponent from './TextAlignmentComponent'
import TextDecorationComponent from './TextDecorationComponent'
import TextDirectionComponent from './TextDirectionComponent'
import PaintComponent from './PaintComponent'
import EffectComponent from './EffectComponent'
import PropertyComponent from './PropertyComponent'


export const COMPONENT_CLASSES = new Map([
    [PropComponentType.TRANSLATE, TranslateComponent],
    [PropComponentType.DIMENSIONS, DimensionsComponent],
    [PropComponentType.ROTATION, RotationComponent],
    [PropComponentType.OPACITY, OpacityComponent],
    [PropComponentType.FILL, FillComponent],
    [PropComponentType.STROKE, StrokeComponent],
    [PropComponentType.SHADOW, ShadowComponent],
    [PropComponentType.INNER_SHADOW, InnerShadowComponent],
    [PropComponentType.FONT, FontComponent],
    [PropComponentType.TEXT_ALIGNMENT, TextAlignmentComponent],
    [PropComponentType.TEXT_DECORATION, TextDecorationComponent],
    [PropComponentType.TEXT_DIRECTION, TextDirectionComponent],
    [PropComponentType.SCALE, ScaleComponent],
    [PropComponentType.SKEW, SkewComponent],
    [PropComponentType.ORIGIN, OriginComponent],
    [PropComponentType.REFERENCE_POINT, ReferencePointComponent],
    [PropComponentType.CONTENT_ANCHOR, ContentAnchorComponent],
    [PropComponentType.OVERFLOW, OverflowComponent],
    [PropComponentType.BLUR_GAUSSIAN, BlurGaussianComponent],
    [PropComponentType.PAINT, PaintComponent],
    [PropComponentType.CORNER_RADIUS, CornerRadiusComponent],
    [PropComponentType.EFFECT, EffectComponent],

    // FIXME: remove it after ET-562 fixed, clone the migration-1.0.0 to another migration if necessary
    // deprecated
    [PropComponentType.IMAGE, PropertyComponent]

])


/**
 * Check if object is an instance of propClass (one of set of propClasses)
 * @param  {Function | Function[]} propClass  single class or list of classes
 * @param  {object} data
 * @returns {bool}
 */
export function instanceOfPropClass(propClass, data) {
    if (isArr(propClass)) {
        return propClass.some(clazz => data instanceof clazz)
    }
    return data instanceof propClass
}
