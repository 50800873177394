import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  concat,
  defaultDataIdFromObject,
  from,
  split
} from '@apollo/client'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { onError } from '@apollo/client/link/error'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition, offsetLimitPagination } from '@apollo/client/utilities'

import { CUSTOM_EVENT_NAMES } from './constants'
import { getAuthorize, getIdToken, refreshToken } from './services/cognito'
import { createCustomEvent } from './utils/customEventUtils'

type ApolloClientConfig = {
  graphql: string
  websocket: string
}

export const getHeaders = () => {
  const token = getIdToken()
  const header: any = {
    'Content-Type': 'application/json'
  }
  if (token != null) {
    header.Authorization = `Bearer ${token}`
  }
  return header
}

const createApiLink = (config: ApolloClientConfig) => {
  const subscriptionMiddleware = {
    applyMiddleware: function (options: any, next: any) {
      options.headers = getHeaders()
      next()
    }
  }

  const wsLink = new WebSocketLink({
    uri: config.websocket!,
    webSocketImpl: WebSocket,
    options: {
      lazy: true,
      reconnect: true,
      connectionParams: () => ({
        headers: getHeaders()
      }),
      connectionCallback: (error) => {
        if (error) {
          if (error.toString() === 'Could not verify JWT: JWTExpired') {
            refreshToken().then((success) => {
              if (!success) {
                getAuthorize()
              }
            })
          }
        }
      }
    }
  })

  // @ts-ignore
  wsLink.subscriptionClient.use([subscriptionMiddleware])

  const batchHttpLink = new BatchHttpLink({
    uri: config.graphql
  })

  const singleHttpLink = new HttpLink({
    uri: config.graphql,
    credentials: 'include'
  })

  const httpLink = concat(
    new ApolloLink((operation, forward) => {
      operation.setContext({
        headers: getHeaders()
      })
      return forward(operation)
    }),
    split(
      ({ query }) => {
        const definition = getMainDefinition(query)
        // Disable request batching on mutation
        return definition.kind === 'OperationDefinition' && definition.operation !== 'mutation'
      },
      batchHttpLink,
      singleHttpLink
    )
  )

  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    },
    wsLink,
    httpLink
  )
  return link
}

const createErrorLink = () => {
  const errorLink = onError(({ graphQLErrors, networkError, response, operation, forward }) => {
    let msg = ''
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, extensions, locations, path }, index) => {
        msg += `--- GraphQL Error ${index + 1} ---\nMessage: ${message}\nLocation: ${JSON.stringify(
          locations
        )}\nCode: ${extensions?.code}\nPath: ${extensions?.path || path}\n`
      })
    }
    if (networkError) {
      // token expired
      if (!response && networkError?.message === 'Failed to fetch') {
        refreshToken().then((success) => {
          if (success) {
            forward(operation)
          } else {
            getAuthorize()
          }
        })
      }
      msg += `--- Network Error ---\n${networkError?.message}`
    }

    if (process.env.NODE_ENV !== 'production') {
      const errorMsgEvent = createCustomEvent(CUSTOM_EVENT_NAMES.DEV_ERROR_MSG, {
        detail: { message: msg }
      })
      console.error(`[Local Environment]\nApollo Client Error during operation: ${operation.operationName}\n`, msg)
      document.dispatchEvent(errorMsgEvent)
    }
  })
  return errorLink
}

export const createApolloClient = (config: ApolloClientConfig) => {
  const linkList = [createApiLink(config)]
  const errorLink = createErrorLink()
  linkList.unshift(errorLink)

  const apolloClient = new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            files: offsetLimitPagination(['$orderBy', '$projectId', '@connection', ['key']]),
            versions: offsetLimitPagination(['$fileId'])
          }
        }
      },
      dataIdFromObject(responseObject) {
        switch (responseObject.__typename) {
          case 'team_users':
            return `team_users:${responseObject.user_id}`
          default:
            return defaultDataIdFromObject(responseObject)
        }
      }
    }),
    link: from(linkList)
  })
  return apolloClient
}
