import { useEffect, useState, useMemo } from 'react'
import { useDataStore, useDataStoreActions } from '../../providers/dataStore/DataStoreProvider'
import { RendererCanvas, RendererCanvasContainer } from '../shared/RendererCanvas'
import PropTypes from 'prop-types'
import theme from '../../theme/dark'
import { debounce } from 'lodash'
import { hex2rgb, rgb2HexString } from '../../utils/color'
import { useElementSelection } from '../../providers/dataStore/ElementSelectionProvider'
import { useEditorActions } from '../../providers/dataStore/EditorProvider'
import { PaintType } from '@phase-software/types'
import { LayerListKey } from '@phase-software/data-store'
import { useModal } from '../../providers/ModalProvider'
import { usePaint } from '../../providers/dataStore/PaintProvider'

const EyeDropperSIZE = 110
const Scale = 2 * window.devicePixelRatio
const BoxSize = 10
const ReactSize = BoxSize - 1 // 9
const ReactCount = Math.ceil(EyeDropperSIZE / ReactSize) // 13
const ReactOffset = Math.floor(ReactCount / 2) // 6

const getPagePng = () => {
  return RendererCanvas.toDataURL('image/png')
}

const loadImage = () => {
  return new Promise((resolve) => {
    const base64 = getPagePng()
    const img = new Image()
    img.src = base64
    img.onload = () => resolve(img)
    img.onerror = () => resolve(null)
  })
}

const drawImageOnCanvas = async () => {
  const img = await loadImage()

  const canvas = document.createElement('canvas')
  canvas.width = RendererCanvasContainer.clientWidth
  canvas.height = RendererCanvasContainer.clientHeight
  const ctx = canvas.getContext('2d', { willReadFrequently: true })
  ctx.drawImage(img, 0, 0, RendererCanvasContainer.clientWidth, RendererCanvasContainer.clientHeight)
  return ctx
}

const getImageColor = (data, rect) => {
  const colors = []
  const { width, height } = rect
  for (let row = 0; row < height; row += 1) {
    if (!colors[row]) {
      colors[row] = []
    }
    const startIndex = row * width * 4
    for (let column = 0; column < width; column += 1) {
      const i = startIndex + column * 4
      const r = data[i] / 255
      const g = data[i + 1] / 255
      const b = data[i + 2] / 255
      const a = data[i + 3] / 255
      colors[row][column] = rgb2HexString([r, g, b, a], false).toUpperCase()
    }
  }
  return colors
}

const getCanvasRectColor = (ctx, rect) => {
  const { x, y, width, height } = rect
  const image = ctx.getImageData(x, y, width, height)
  const { data } = image
  const colors = getImageColor(data, rect)
  return colors
}

const drawPipetteCanvas = (colors, ctx) => {
  if (!ctx) {
    return
  }

  // draw scaled image blocks
  colors.forEach((row, y) =>
    row.forEach((color, x) => {
      ctx.fillStyle = `#${color}`
      const size = ReactSize * Scale
      const firstBoxSize = (EyeDropperSIZE - (ReactCount - 2) * ReactSize) / 2
      const offset = (ReactSize - firstBoxSize) * Scale
      ctx.fillRect(x * size - offset, y * size - offset, size, size)
    })
  )

  // draw vertical line
  let y = 5 * Scale
  while (y < EyeDropperSIZE * Scale) {
    ctx.beginPath()
    ctx.strokeStyle = theme.colors.darkOverlays[20]
    ctx.lineWidth = Scale
    ctx.moveTo(0, y)
    ctx.lineTo(EyeDropperSIZE * Scale, y)
    ctx.stroke()
    y += (BoxSize - 1) * Scale
  }

  // draw horizontal line
  let x = 5 * Scale
  while (x < EyeDropperSIZE * Scale) {
    ctx.beginPath()
    ctx.strokeStyle = theme.colors.darkOverlays[20]
    ctx.lineWidth = Scale
    ctx.moveTo(x, 0)
    ctx.lineTo(x, EyeDropperSIZE * Scale)
    ctx.stroke()
    x += (BoxSize - 1) * Scale
  }

  const radius = (EyeDropperSIZE * Scale) / 2

  // draw round border
  ctx.beginPath()
  ctx.strokeStyle = theme.colors.darkOverlays[20]
  ctx.lineWidth = Scale
  ctx.arc(radius, radius, radius - Scale / 2, 0, 2 * Math.PI)
  ctx.stroke()

  // draw center rectangle box
  ctx.strokeStyle = 0x333333
  ctx.lineWidth = Scale
  ctx.strokeRect(
    radius - (BoxSize * Scale) / 2,
    radius - (BoxSize * Scale) / 2,
    (BoxSize - 1) * Scale,
    (BoxSize - 1) * Scale
  )
  ctx.strokeStyle = theme.colors.white
  ctx.lineWidth = Scale
  ctx.strokeRect(
    radius - ((BoxSize + 2) * Scale) / 2,
    radius - ((BoxSize + 2) * Scale) / 2,
    (BoxSize + 1) * Scale,
    (BoxSize + 1) * Scale
  )
}

const drawCanvas = ({ x, y }) => {
  const canvas = document.createElement('canvas')
  canvas.width = EyeDropperSIZE * Scale
  canvas.height = EyeDropperSIZE * Scale
  const ctx = canvas.getContext('2d')

  canvas.style = `
    border-radius: 50%;
  `

  const container = document.createElement('div')
  container.style = `
    position: absolute;
    left: ${x}px;
    top: ${y}px;
    border: ${1 * Scale}px solid ${theme.colors.white};
    border-radius: 50%;
    will-change: top, left;
    transform: translate(-${50 * Scale}%, -${50 * Scale}%);
    pointer-events: none;
    scale: ${1 / Scale};
    z-index: 100;
  `
  container.className = 'shadow-5'
  container.appendChild(canvas)

  return { container, ctx }
}

const drawColorBlock = (color) => {
  const container = document.createElement('div')
  container.style = `
    position: absolute;
    left: 0px;
    right: 0px;
    height: ${16 * Scale}px;
    bottom: ${13 * Scale}px;
    display: flex;
    justify-content: center;
    align-items: center;
  `

  const colorBlock = document.createElement('div')
  colorBlock.style = `
    background-color: ${theme.colors.darkOverlays[60]};
    color: ${theme.colors.white};
    border-radius: 2px;
    padding: 0 4px;
    scale: ${Scale};
  `
  colorBlock.innerHTML = color
  container.appendChild(colorBlock)
  return container
}

const useRemoveCanvasEvents = () => {
  useEffect(() => {
    const pointerEvents = RendererCanvas.style.pointerEvents
    const cursor = RendererCanvasContainer.style.cursor

    RendererCanvas.style.pointerEvents = 'none'
    RendererCanvasContainer.style.cursor = 'none'

    return () => {
      RendererCanvas.style.pointerEvents = pointerEvents
      RendererCanvasContainer.style.cursor = cursor
    }
  }, [])
}

const useImageFromCanvas = () => {
  const [ctx, setCtx] = useState()
  const dataStore = useDataStore()
  const { editor } = dataStore

  useEffect(() => {
    const updateImage = () => drawImageOnCanvas().then(setCtx).catch(console.log)
    const cb = debounce(updateImage, 50)
    updateImage()

    editor.on('EDITOR_CHANGES', cb)
    dataStore.workspace.ons('scale', 'panX', 'panY', cb)
    return () => {
      editor.off('EDITOR_CHANGES', cb)
      dataStore.workspace.offs('scale', 'panX', 'panY', cb)
    }
  }, [dataStore, editor])
  return ctx
}

const useDrawEyeDropperContainer = (position) => {
  const { container, ctx } = useMemo(() => drawCanvas(position), [position])

  useEffect(() => {
    RendererCanvasContainer.appendChild(container)

    return () => {
      RendererCanvasContainer.removeChild(container)
    }
  }, [container])

  useEffect(() => {
    container.style.left = position.x + 'px'
    container.style.top = position.y + 'px'
  }, [position, container])

  return { container, ctx }
}

const useEyeDropper = (position) => {
  const [colors, setColors] = useState([])
  const ctx = useImageFromCanvas()

  useEffect(() => {
    if (ctx) {
      const colors = getCanvasRectColor(ctx, {
        x: position.x - ReactOffset,
        y: position.y - ReactOffset,
        width: ReactCount,
        height: ReactCount
      })
      setColors(colors)
    }
  }, [ctx, position])
  const color = colors[ReactOffset]?.[ReactOffset]
  return { colors, color }
}

const EyeDropper = ({ position }) => {
  const dataStore = useDataStore()
  const selection = useElementSelection()
  const { getComputedStyleByElementId, getElement } = useDataStoreActions()
  const currentModalKey = `PaintModal-${dataStore.selection.get('focusedLayer')}`
  const { data } = useModal((o) => o[currentModalKey])
  const { layerItemId, activeIdx, paintType } = data
  const { gradientStops } = usePaint((o) => o[layerItemId]) ?? {}
  const { setLayerPaint } = useEditorActions()

  useRemoveCanvasEvents()
  const { container, ctx } = useDrawEyeDropperContainer(position)
  const { colors, color } = useEyeDropper(position)

  useEffect(() => {
    if (color) {
      drawPipetteCanvas(colors, ctx)
      const block = drawColorBlock(color)
      container.appendChild(block)
      return () => {
        container.removeChild(block)
      }
    }
  }, [colors, color, container, ctx])

  useEffect(() => {
    if (color) {
      const cb = (e) => {
        if (e.target !== RendererCanvasContainer) {
          dataStore.eam.setLastGeneralTool()
        } else {
          const [r, g, b, a = 1] = hex2rgb(color)
          const newColor = [r, g, b, a]

          if (layerItemId) {
            if (activeIdx != null && paintType !== PaintType.SOLID) {
              setLayerPaint(layerItemId, {
                gradientStops: [
                  ...gradientStops.slice(0, activeIdx),
                  { ...gradientStops[activeIdx], color: newColor },
                  ...gradientStops.slice(activeIdx + 1)
                ]
              })
            } else {
              setLayerPaint(layerItemId, {
                color: newColor,
                opacity: a
              })
            }
          } else {
            selection.forEach((id) => {
              const fills = getComputedStyleByElementId(id, LayerListKey.FILL)
              const strokes = getComputedStyleByElementId(id, LayerListKey.STROKE)

              dataStore.startTransaction()
              if (fills && fills.length > 0) {
                const target = fills[fills.length - 1]
                if (target.data.paintType === PaintType.SOLID) {
                  target.sets({ color: newColor })
                } else {
                  const gradientStops = [...target.data.gradientStops]
                  gradientStops[0] = { ...gradientStops[0], color: newColor }
                  target.sets({ gradientStops })
                }
              } else if (strokes && strokes.length > 0) {
                strokes[strokes.length - 1].sets({ color: newColor })
              } else {
                const fillId = getElement(id).base.fills[0]
                dataStore.library.addLayer(fillId, 0)
                const fills = getComputedStyleByElementId(id, LayerListKey.FILL)
                fills[fills.length - 1].sets({ color: newColor })
              }
              dataStore.endTransaction()
            })
          }
          dataStore.eam.toggleEyeDropperTool()
        }
      }

      window.addEventListener('click', cb)

      return () => {
        window.removeEventListener('click', cb)
      }
    }
  }, [
    color,
    dataStore,
    selection,
    layerItemId,
    activeIdx,
    paintType,
    gradientStops,
    setLayerPaint,
    getComputedStyleByElementId,
    getElement
  ])

  return null
}

EyeDropper.propTypes = {
  position: PropTypes.object
}
EyeDropper.defaultProps = {
  position: { x: 0, y: 0 }
}

export default EyeDropper
