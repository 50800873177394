import React, { useCallback, useMemo } from 'react'
import Input from '../../../shared/Input'
import { getPaintPreviewText } from './utils'
import { useDataStoreActions } from '../../../../providers/dataStore/DataStoreProvider'
import { usePaint } from '../../../../providers/dataStore/PaintProvider'
import { useEditorActions } from '../../../../providers/dataStore/EditorProvider'
import { hex2rgb } from '../../../../utils/color'
import { getHEXString, hexValidator } from '../../../../utils/inputValidator'

type SolidColorEditorProps = {
  layerItemId: string
  layerType: number
}

const SolidColorEditor = ({ layerItemId, layerType }: SolidColorEditorProps) => {
  const { paintType, color } = usePaint((o) => o[layerItemId])
  const { setLayerPaint, toggleLayerVisible } = useEditorActions()
  const text = getPaintPreviewText({
    paintType,
    color
  })
  const { commitUndo } = useDataStoreActions()
  const handleFocus = useCallback(() => toggleLayerVisible(layerItemId, true), [layerItemId, toggleLayerVisible])
  const handleChange = useCallback(
    (value: string) => {
      const hexstr = getHEXString(value)
      if (!hexValidator(value)) {
        const rgb = hex2rgb(hexstr)
        setLayerPaint(layerItemId, { color: [...rgb, color[3]] })
        commitUndo()
      }
    },
    [layerItemId, commitUndo, color, setLayerPaint]
  )

  const keyFrameIconProps = useMemo(
    () => ({
      name: 'paint',
      layerItemId,
      layerType
    }),
    [layerItemId, layerType]
  )

  return (
    <Input
      // @ts-ignore TODO: fix after refactor of Input
      keyFrameIconProps={keyFrameIconProps}
      validator={hexValidator}
      value={text}
      onChange={handleChange}
      onFocus={handleFocus}
      variant="normal"
    />
  )
}

export default SolidColorEditor
