import React from 'react'
import { useTranslation } from 'react-i18next'

import { ElementType } from '@phase-software/types'

import { useEditor } from '../../../providers/dataStore/EditorProvider'
import InspectContent from './InspectContent'
import InspectRow from './InspectRow'

const OverflowInspection = () => {
  const { t } = useTranslation('file')
  const overflow = useEditor((o) =>
    o.overflowX ? t('property_editor.overflow.hide') : t('property_editor.overflow.show')
  )
  const elementType = useEditor((o) => o.elementType)
  if (elementType === ElementType.SCREEN || elementType === ElementType.CONTAINER) {
    return (
      <InspectContent>
        <InspectRow label={t('inspect.overflow')} value={overflow} />
      </InspectContent>
    )
  }
  return null
}

export default OverflowInspection
