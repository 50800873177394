import { useEffect, useCallback, memo } from 'react'
import { useDataStore, useSetDataStore } from '../../providers/dataStore/DataStoreProvider'

const SubscribeActiveTableElementSelectionChange = () => {
  const dataStore = useDataStore()
  const { setActiveTableElement } = useSetDataStore()
  const cb = useCallback(
    (changes) => {
      const activeTableElement = changes.get('activeTableElement')
      if (activeTableElement) {
        setActiveTableElement(activeTableElement.after)
      }
    },
    [setActiveTableElement]
  )
  useEffect(() => {
    if (!dataStore) return
    dataStore.selection.on('SELECT', cb)

    return () => {
      dataStore.selection.off('SELECT', cb)
    }
  }, [dataStore, cb])
  return null
}

export default memo(SubscribeActiveTableElementSelectionChange)
