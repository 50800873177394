import { EntityType } from '@phase-software/types'
import { notNull } from '@phase-software/data-utils'
import PropertyComponent from '../component/PropertyComponent'


export default class Effect extends PropertyComponent {
    /**
     * @param {DataStore} dataStore
     * @param {EffectData} data
     * @param {object} [options] 
     * @param {bool} [options.regenId=false]   if set to true, will generate new ID
     */
    constructor(dataStore, data = {}, options) {
        super(dataStore, data, options)

        const { effectType, visible } = data
        this.type = EntityType.EFFECT
        this.effectType = notNull(effectType) ? effectType : 'EFFECT'
        this.visible = notNull(visible) ? visible : true

        if (!this.name) {
            this.name = 'effect'
        }
    }

    /**
     * Clones Effect component
     * @returns {Effect}
     */
    clone() {
        const obj = super.clone()
        obj.effectType = this.effectType
        obj.visible = this.visible
        return obj
    }

    /**
     * Save Effect
     * @returns {EffectData}
     */
    save() {
        const obj = super.save()
        obj.effectType = this.effectType
        obj.visible = this.visible
        return obj
    }
}


/** @typedef {('TRIM_PATH')} EffectType */

/**
 * @typedef {object} EffectData
 * @property {EffectType} effectType
 * @property {bool} visible
 */
