import React from 'react'
import { useTranslation } from 'react-i18next'

import { ROW_HEIGHT } from '../../../constant'
import { Icon, Tooltip } from '../../shared'

type MixContentProps = {
  notEditable: boolean
}

const MixContent = ({ notEditable }: MixContentProps) => {
  const { t } = useTranslation('file', { keyPrefix: 'property_editor.mix_content' })
  return (
    <div className={`relative pt-10 pb-10 height-${ROW_HEIGHT}`}>
      {notEditable ? (
        <>
          {t('not_editable_note')}&nbsp;
          <Tooltip content={t('tooltip')}>
            <Icon name="Question" />
          </Tooltip>
        </>
      ) : (
        t('replaceable_note')
      )}
    </div>
  )
}

export default MixContent
