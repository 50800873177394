import React from 'react'
import Modal from '../Modal'

type PopoverProps = {
  open: boolean
  onClose: () => void
  children: React.ReactNode
  trigger: React.RefObject<HTMLDivElement>
  placement?: 'top' | 'bottom'
  className?: string
  showArrow?: boolean
  onMouseLeave?: (e: React.MouseEvent<HTMLFormElement>) => void
  onMouseEnter?: (e: React.MouseEvent<HTMLFormElement>) => void
  dataTestId?: string
}

const Popover = (props: PopoverProps) => {
  return (
    <Modal
      {...props}
      className={`popover ${props.className || ''}`}
      placement={props.placement ?? 'vertical'}
      zIndex={20}
      offsetY={8}
      placementTopFirst
      showArrow={props.showArrow}
    />
  )
}

export default Popover
