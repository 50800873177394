import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  ::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.neutrals[40]};
    border-radius: 6px;
    border: 2px solid transparent;
    background-clip: padding-box;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    backface-visibility: hidden;
    transform:  translateZ(0);
    will-change: transform;
  }
  ::selection {
    background: ${(props) => props.theme.colors.primaryOverlays[60]};
  }

  /* change chrome autofill color to as same as design */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
      -webkit-background-clip: text;
      -webkit-text-fill-color: #fff;
  }

  *, *:before, *:after {
    box-sizing: border-box;
  }
  *:focus, button:focus {
    outline: 0;
  }
  body {
    user-select: none;
    min-height: 100vh;
    margin: 0;
    color: ${(props) => props.theme.colors.text};
    font-size: ${(props) => props.theme.typography.fontSize}px;
    line-height: ${(props) => props.theme.typography.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeight};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  body input {
    user-select: text;
  }

  a[href] {
    text-decoration: none;
  }
  img {
    vertical-align: middle;
  }
  .aspect-video {
    aspect-ratio: 16 / 9;
  }

  .scroll-container {
    &:not(:hover) {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }

    &:not(:hover)::-webkit-scrollbar {
      display: none;
    }
  }
  body.v-resizing {
    cursor: row-resize !important;
  }
`

export default GlobalStyle
