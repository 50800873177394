import { PropComponentType, Unit } from '@phase-software/types'
import { notNull, isNum } from '@phase-software/data-utils'
import PropertyComponent from './PropertyComponent'

/** @typedef {import('../DataStore').DataStore} DataStore */
/** @typedef {import('./PropertyComponent').AppliedRef} AppliedRef */

export default class DimensionsComponent extends PropertyComponent {
    /**
     * @param {DataStore} dataStore
     * @param {DimensionsComponentData} [data]
     * @param {object} [options]
     * @param {bool} [options.regenId=false]   if set to true, will generate new ID
     */
    constructor(dataStore, data = {}, options) {
        super(dataStore, data, options)

        const {
            width,
            widthUnit,
            height,
            heightUnit
        } = data

        this.componentType = PropComponentType.DIMENSIONS

        this.width = isNum(width) ? width : 0
        this.widthUnit = widthUnit || Unit.PIXEL
        this.height = isNum(height) ? height : 0
        this.heightUnit = heightUnit || Unit.PIXEL
        if (!this.name) {
            this.name = 'dimensions'
        }
    }

    /**
     * @param {Partial<DimensionsComponentSetData>} data
     */
    set(data) {
        super.set(data)

        if (isNum(data.width)) {
            this.updateProp('width', data.width)
        } else if (data.width === null) {
            this.updateProp('width', undefined)
        }
        if (isNum(data.height)) {
            this.updateProp('height', data.height)
        } else if (data.height === null) {
            this.updateProp('height', undefined)
        }
        if (data.widthUnit in Unit && notNull(this.width)) {
            this.updateProp('widthUnit', data.widthUnit)
        }
        if (data.heightUnit in Unit && notNull(this.height)) {
            this.updateProp('heightUnit', data.heightUnit)
        }
    }

    /**
     * Override this in subclasses
     * CALL super._clone() at the top of overriden method
     * @protected
     * @param {AppliedRef} [ref]
     * @returns {DimensionsComponent} obj
     */
    _clone(ref) {
        const obj = super._clone(ref)

        obj.width = this.width
        obj.widthUnit = this.widthUnit
        obj.height = this.height
        obj.heightUnit = this.heightUnit
        return obj
    }

    /**
     * Override this in subclasses
     * CALL super._save() at the top of overriden method
     * @protected
     * @returns {DimensionsComponentData}
     */
    _save() {
        const data = super._save()

        if (notNull(this.width)) {
            data.width = this.width
            data.widthUnit = this.widthUnit
        }
        if (notNull(this.height)) {
            data.height = this.height
            data.heightUnit = this.heightUnit
        }
        return data
    }
}

DimensionsComponent.BASE_DEFAULT_DATA = {
    width: 0,
    widthUnit: Unit.PIXEL,
    height: 0,
    heightUnit: Unit.PIXEL
}

/** @typedef {import('./PropertyComponent').PropertyComponentData} PropertyComponentData */

/**
 * @typedef {PropertyComponentData} DimensionsComponentData
 * @property {(number | undefined)} width
 * @property {(number | undefined)} height
 * @property {(string | undefined)} widthUnit
 * @property {(string | undefined)} heightUnit
 */

/**
 * @typedef {PropertyComponentData} DimensionsComponentSetData
 * @property {string | null} widthUnit
 * @property {string | null} heightUnit
 * @property {boolean} flipX
 * @property {boolean} flipY
 */
