import { MediaType, VideoQuality } from '@phase-software/types'

export const mediaTypes = [
  { name: 'GIF', value: MediaType.GIF, dataTestId: 'gif-format' },
  { name: 'MP4', value: MediaType.MP4, dataTestId: 'mp4-format' },
  {
    name: 'Lottie (.json)',
    value: MediaType.LOTTIE,
    dataTestId: 'lottie-format',
    tooltip: 'file:export_dialog.json_files_tooltip'
  },
  {
    name: 'dotLottie (.lottie)',
    value: MediaType.DOTLOTTIE,
    dataTestId: 'dot-lottie-format',
    tooltip: 'file:export_dialog.dotLottie_files_tooltip'
  }
]

export const EXPORT_TYPE_MAP = {
  [MediaType.DOTLOTTIE]: 'dotlottie',
  [MediaType.GIF]: 'gif',
  [MediaType.MP4]: 'mp4',
  [MediaType.LOTTIE]: 'lottie'
}

export const EXPORT_FORMAT_MAP = {
  [MediaType.DOTLOTTIE]: '.lottie',
  [MediaType.GIF]: '.gif',
  [MediaType.MP4]: '.mp4',
  [MediaType.LOTTIE]: '.json'
}

export const qualities = [
  { name: 'ultra', value: VideoQuality.ULTRA },
  { name: 'high', value: VideoQuality.HIGH },
  { name: 'medium', value: VideoQuality.MEDIUM },
  { name: 'low', value: VideoQuality.LOW }
]

export const fpsOptions = {
  [MediaType.GIF]: [
    { name: '24', value: 24 },
    { name: '30', value: 30 },
    { name: '50', value: 50 }
  ],
  [MediaType.MP4]: [
    { name: '24', value: 24 },
    { name: '30', value: 30 },
    { name: '60', value: 60 }
  ],
  [MediaType.LOTTIE]: [
    { name: '24', value: 24 },
    { name: '30', value: 30 },
    { name: '60', value: 60 }
  ],
  [MediaType.DOTLOTTIE]: [
    { name: '24', value: 24 },
    { name: '30', value: 30 },
    { name: '60', value: 60 }
  ]
}

export const DefaultSettings = {
  TYPE: MediaType.LOTTIE,
  QUALITY: qualities[1].value,
  TRANSPARENT: true,
  LOOP: true,
  SPEED: 1,
  FPS: 30,
  START: 0,
  END: 0,
  MAX_TIME: 0
}

export const DEFAULT_FILE_SIZE = 0

export const BOOLEAN_RADIO_BUTTON_OPTIONS = [
  { value: true, label: 'file:export_dialog.on' },
  { value: false, label: 'file:export_dialog.off' }
]

export const FPS_TOOLTIP = 'The number of frames shown per second in animation'
