import { useCallback } from 'react'

import { userService } from '../services/api'
import { getAccessToken, logout } from '../services/cognito'
import { createProvider } from './utils'

const defaultValue = null
const [Provider, useSelectState, useSetState, getStateSnapshot] = createProvider('Profile', defaultValue)

export const useProfile = useSelectState
export const useProfileSnapshot = getStateSnapshot
export const useSetProfile = () => {
  const setState = useSetState()
  return setState
}

export const useProfileActions = () => {
  const userId = getStateSnapshot().id

  const removeAvatar = useCallback(async () => {
    await userService.userApiControllerPatchUser({
      userId,
      patchUserRequest: {
        avatar: null,
        updateMask: ['avatar']
      }
    })
  }, [userId])

  const updateAvatar = useCallback(
    async (file) => {
      const { uploadUrl, downloadUrl } = await userService.userApiControllerGenerateFileOperateUrl({
        userId,
        generateFileOperateUrlRequest: {
          contentType: file.type
        }
      })

      await fetch(uploadUrl, { method: 'PUT', body: file })
      await userService.userApiControllerPatchUser({
        userId,
        patchUserRequest: {
          avatar: downloadUrl,
          updateMask: ['avatar']
        }
      })

      return downloadUrl
    },
    [userId]
  )

  const updatePassword = useCallback(
    async ({ oldPassword, newPassword }) => {
      const accessToken = getAccessToken()
      await userService.userApiControllerChangePassword({
        userId,
        changePassword: {
          oldPassword,
          newPassword,
          accessToken
        }
      })
    },
    [userId]
  )

  const updateUsername = useCallback(
    (username) =>
      userService.userApiControllerPatchUser({
        userId,
        patchUserRequest: {
          username,
          updateMask: ['username']
        }
      }),
    [userId]
  )

  const trackTutorialStatus = useCallback(
    async (status) => {
      await userService.userApiControllerUpsertUserPreference({
        userId,
        preferenceKey: 'tutorial-status',
        upsertUserPreference: {
          value: JSON.stringify(status)
        }
      })
    },
    [userId]
  )

  const updatePresencePreference = useCallback(
    async (value) => {
      await userService.userApiControllerUpsertUserPreference({
        userId,
        preferenceKey: 'presence-preference',
        upsertUserPreference: {
          value
        }
      })
    },
    [userId]
  )

  const deleteAccount = useCallback(async () => {
    await userService.userApiControllerDeleteUser({
      userId
    })
    logout()
  }, [userId])

  const updateCommentVisibility = useCallback(
    async (value) => {
      await userService.userApiControllerUpsertUserPreference({
        userId,
        preferenceKey: 'comment-visibility',
        upsertUserPreference: {
          value
        }
      })
    },
    [userId]
  )

  const updateLanguage = useCallback(
    async (value) => {
      await userService.userApiControllerUpsertUserPreference({
        userId,
        preferenceKey: 'preferred-language',
        upsertUserPreference: {
          value
        }
      })
    },
    [userId]
  )

  const updateTemplateSectionVisibility = useCallback(
    async (value) => {
      await userService.userApiControllerUpsertUserPreference({
        userId,
        preferenceKey: 'template-section-visibility',
        upsertUserPreference: {
          value
        }
      })
    },
    [userId]
  )

  const updateNewsletterSubscription = useCallback(
    async (value) => {
      await userService.userApiControllerUpsertUserPreference({
        userId,
        preferenceKey: 'newsletter-subscription',
        upsertUserPreference: {
          value
        }
      })
    },
    [userId]
  )

  return {
    updateAvatar,
    removeAvatar,
    updateUsername,
    updatePassword,
    deleteAccount,
    trackTutorialStatus,
    updatePresencePreference,
    updateCommentVisibility,
    updateLanguage,
    updateTemplateSectionVisibility,
    updateNewsletterSubscription
  }
}

export default Provider
