import React from 'react'
import CustomContentLoader from './CustomContentLoader'

// Team member dialog loading
const TeamMembersLoading = () => {
  return (
    <div className="py-48 px-40 w-[680px] bg-neutral-90 z-1 rounded-xl">
      <CustomContentLoader width={540} height={432} viewBox="0 0 540 432">
        <path d="M0 6C0 2.68629 2.68629 0 6 0H138C141.314 0 144 2.68629 144 6V10C144 13.3137 141.314 16 138 16H6C2.68629 16 0 13.3137 0 10V6Z" />
        <path d="M0 82C0 78.6863 2.68629 76 6 76H114C117.314 76 120 78.6863 120 82V86C120 89.3137 117.314 92 114 92H6C2.68629 92 0 89.3137 0 86V82Z" />
        <path d="M220 82C220 78.6863 222.686 76 226 76H294C297.314 76 300 78.6863 300 82V86C300 89.3137 297.314 92 294 92H226C222.686 92 220 89.3137 220 86V82Z" />
        <path d="M400 82C400 78.6863 402.686 76 406 76H474C477.314 76 480 78.6863 480 82V86C480 89.3137 477.314 92 474 92H406C402.686 92 400 89.3137 400 86V82Z" />
        <path d="M0 142C0 138.686 2.68629 136 6 136H74C77.3137 136 80 138.686 80 142V146C80 149.314 77.3137 152 74 152H6C2.68629 152 0 149.314 0 146V142Z" />
        <path d="M220 142C220 138.686 222.686 136 226 136H334C337.314 136 340 138.686 340 142V146C340 149.314 337.314 152 334 152H226C222.686 152 220 149.314 220 146V142Z" />
        <path d="M400 142C400 138.686 402.686 136 406 136H494C497.314 136 500 138.686 500 142V146C500 149.314 497.314 152 494 152H406C402.686 152 400 149.314 400 146V142Z" />
        <path d="M0 198C0 194.686 2.68629 192 6 192H94C97.3137 192 100 194.686 100 198V202C100 205.314 97.3137 208 94 208H6C2.68629 208 0 205.314 0 202V198Z" />
        <path d="M220 198C220 194.686 222.686 192 226 192H294C297.314 192 300 194.686 300 198V202C300 205.314 297.314 208 294 208H226C222.686 208 220 205.314 220 202V198Z" />
        <path d="M400 198C400 194.686 402.686 192 406 192H534C537.314 192 540 194.686 540 198V202C540 205.314 537.314 208 534 208H406C402.686 208 400 205.314 400 202V198Z" />
        <path d="M0 254C0 250.686 2.68629 248 6 248H134C137.314 248 140 250.686 140 254V258C140 261.314 137.314 264 134 264H6C2.6863 264 0 261.314 0 258V254Z" />
        <path d="M220 254C220 250.686 222.686 248 226 248H314C317.314 248 320 250.686 320 254V258C320 261.314 317.314 264 314 264H226C222.686 264 220 261.314 220 258V254Z" />
        <path d="M400 254C400 250.686 402.686 248 406 248H514C517.314 248 520 250.686 520 254V258C520 261.314 517.314 264 514 264H406C402.686 264 400 261.314 400 258V254Z" />
        <path d="M0 310C0 306.686 2.68629 304 6 304H74C77.3137 304 80 306.686 80 310V314C80 317.314 77.3137 320 74 320H6C2.68629 320 0 317.314 0 314V310Z" />
        <path d="M220 310C220 306.686 222.686 304 226 304H334C337.314 304 340 306.686 340 310V314C340 317.314 337.314 320 334 320H226C222.686 320 220 317.314 220 314V310Z" />
        <path d="M400 310C400 306.686 402.686 304 406 304H494C497.314 304 500 306.686 500 310V314C500 317.314 497.314 320 494 320H406C402.686 320 400 317.314 400 314V310Z" />
        <path d="M0 366C0 362.686 2.68629 360 6 360H94C97.3137 360 100 362.686 100 366V370C100 373.314 97.3137 376 94 376H6C2.68629 376 0 373.314 0 370V366Z" />
        <path d="M220 366C220 362.686 222.686 360 226 360H294C297.314 360 300 362.686 300 366V370C300 373.314 297.314 376 294 376H226C222.686 376 220 373.314 220 370V366Z" />
        <path d="M400 366C400 362.686 402.686 360 406 360H534C537.314 360 540 362.686 540 366V370C540 373.314 537.314 376 534 376H406C402.686 376 400 373.314 400 370V366Z" />
        <path d="M0 422C0 418.686 2.68629 416 6 416H134C137.314 416 140 418.686 140 422V426C140 429.314 137.314 432 134 432H6C2.6863 432 0 429.314 0 426V422Z" />
        <path d="M220 422C220 418.686 222.686 416 226 416H314C317.314 416 320 418.686 320 422V426C320 429.314 317.314 432 314 432H226C222.686 432 220 429.314 220 426V422Z" />
        <path d="M400 422C400 418.686 402.686 416 406 416H514C517.314 416 520 418.686 520 422V426C520 429.314 517.314 432 514 432H406C402.686 432 400 429.314 400 426V422Z" />
      </CustomContentLoader>
    </div>
  )
}

export default TeamMembersLoading
