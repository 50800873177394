import { PaintType } from '@phase-software/types'

/**
 * Converts a hexadecimal color number to an [R, G, B] array of normalized floats (numbers from 0.0 to 1.0).
 * @function hex2rgb
 * @param {string} hex
 * @returns {number[]} An array representing the [R, G, B] of the color where all values are floats.
 */
export const hex2rgb = (hex) => {
  let formattedHex = hex
  if (formattedHex.length === 3) {
    formattedHex = hex
      .split('')
      .map((s) => s.repeat(2))
      .join('')
  }
  const rt = formattedHex[0] + formattedHex[1]
  const gt = formattedHex[2] + formattedHex[3]
  const bt = formattedHex[4] + formattedHex[5]
  const r = parseInt(rt, 16) / 255
  const g = parseInt(gt, 16) / 255
  const b = parseInt(bt, 16) / 255
  return [r, g, b]
}

/**
 * Converts a hexadecimal color number to a string.
 * @function hex2string
 * @param {number} hex - Number in hex (e.g., `0xffffff`)
 * @param {boolean} withHash
 * @returns {string} The string color (e.g., `"#ffffff"`).
 */
export const hex2string = (hex, withHash) => {
  let hexString = hex.toString(16)
  hexString = '000000'.substr(0, 6 - hexString.length) + hexString
  return withHash ? `#${hexString}` : hexString
}

/**
 * Converts a color as an [R, G, B] array of normalized floats to a hexadecimal number.
 * @function rgb2hex
 * @param {number[]} rgb - Array of numbers where all values are normalized floats from 0.0 to 1.0.
 * @returns {number} Number in hexadecimal.
 */
export const rgb2hex = (rgb) => {
  return ((rgb[0] * 255) << 16) + ((rgb[1] * 255) << 8) + ((rgb[2] * 255) | 0)
}

/**
 * Converts a color as an [R, G, B] array of normalized floats to a hexadecimal string.
 * @function rgb2hex
 * @param {number[]} rgb - Array of numbers where all values are normalized floats from 0.0 to 1.0.
 * @param {boolean} withHash
 * @returns {string} The string color (e.g., `"#ffffff"`).
 */
export const rgb2HexString = (rgb, withHash = true) => hex2string(rgb2hex(rgb), withHash)

export const hsv2rgb = (hue, s, v) => {
  const saturation = Math.max(0, Math.min(s, 1))
  const value = Math.max(0, Math.min(v, 1))

  let rgb
  if (saturation === 0) {
    return [value, value, value]
  }

  const side = hue / 60
  const chroma = value * saturation
  const x = chroma * (1 - Math.abs((side % 2) - 1))
  const match = value - chroma

  switch (Math.floor(side)) {
    case 0:
      rgb = [chroma, x, 0]
      break
    case 1:
      rgb = [x, chroma, 0]
      break
    case 2:
      rgb = [0, chroma, x]
      break
    case 3:
      rgb = [0, x, chroma]
      break
    case 4:
      rgb = [x, 0, chroma]
      break
    case 5:
      rgb = [chroma, 0, x]
      break
    default:
      rgb = [0, 0, 0]
  }

  rgb[0] += match
  rgb[1] += match
  rgb[2] += match

  rgb[0] = Math.round(rgb[0] * 255) / 255
  rgb[1] = Math.round(rgb[1] * 255) / 255
  rgb[2] = Math.round(rgb[2] * 255) / 255
  return rgb
}

export const rgb2hsv = (r, g, b) => {
  const max = Math.max(r, g, b)
  const min = Math.min(r, g, b)
  const d = max - min
  const s = max === 0 ? 0 : d / max
  const v = max

  let h
  switch (max) {
    case min:
      h = 0
      break
    case r:
      h = g - b + d * (g < b ? 6 : 0)
      h /= 6 * d
      break
    case g:
      h = b - r + d * 2
      h /= 6 * d
      break
    case b:
      h = r - g + d * 4
      h /= 6 * d
      break
    default:
      h = 0
      break
  }

  return [Math.round(h * 360), s, v]
}

/**
 * Convert color value to RGBA in [0,255]
 * @param {object} color - rgba color in [0,1]
 * @param {number} color.0
 * @param {number} color.1
 * @param {number} color.2
 * @param {number} color.3
 * @returns {string} CSS RGB value string
 */
export const rgb2rgbaString = ([r, g, b, a = 1]) => {
  return `rgba(${[r, g, b].map((c) => Math.round(c * 255)).join()},${a})`
}

/**
 * Convert color value to RGB in [0,255]
 * @param {object} color - rgb color in [0,1]
 * @param {number} color.0
 * @param {number} color.1
 * @param {number} color.2
 * @returns {string} CSS RGBA value string
 */
export const rgb2rgbString = ([r, g, b]) => {
  return rgb2rgbaString([r, g, b, 1])
}

/**
 * Convert hsl to rgb
 * @param {number} h - in [0, 360]
 * @param {number} s - in [0, 100]
 * @param {number} l - in [0, 100]
 * @returns [r,g,b] in [0,1]
 */
export const hsl2rgb = (h, s, l) => {
  const ns = s / 100
  const nl = l / 100
  const a = ns * Math.min(nl, 1 - nl)
  const f = (n, k = (n + h / 30) % 12) => nl - a * Math.max(Math.min(k - 3, 9 - k, 1), -1)
  return [f(0), f(8), f(4)]
}

export const rgb2hsl = (r, g, b) => {
  const v = Math.max(r, g, b)
  const c = v - Math.min(r, g, b)
  const f = 1 - Math.abs(v + v - c - 1)
  const h = c && (v === r ? (g - b) / c : v === g ? 2 + (b - r) / c : 4 + (r - g) / c)
  return [60 * (h < 0 ? h + 6 : h), 100 * (f ? c / f : 0), 100 * ((v + v - c) / 2)]
}

export const rgb2hsb = (r, g, b) => {
  const v = Math.max(r, g, b)
  const n = v - Math.min(r, g, b)
  const h = n === 0 ? 0 : n && v === r ? (g - b) / n : v === g ? 2 + (b - r) / n : 4 + (r - g) / n
  return [60 * (h < 0 ? h + 6 : h), v && (n / v) * 100, v * 100]
}

/**
 * Convert hsb to rgb
 * @param {number} h - in [0, 360]
 * @param {number} s - in [0, 100]
 * @param {number} l - in [0, 100]
 * @returns [r,g,b] in [0,1]
 */
export const hsb2rgb = (h, s, b) => {
  s /= 100
  b /= 100
  const k = (n) => (n + h / 60) % 6
  const f = (n) => b * (1 - s * Math.max(0, Math.min(k(n), 4 - k(n), 1)))
  return [f(5), f(3), f(1)]
}

export const stops2rgbaString = (stops, posRatio = 1) =>
  stops
    .slice()
    .sort((a, b) => a.position - b.position)
    .map(({ color, position }) => `${rgb2rgbaString(color)} ${position * posRatio * 100}%`)
    .join(',')

const gradientString = {
  [PaintType.GRADIENT_LINEAR]: 'linear-gradient',
  [PaintType.GRADIENT_RADIAL]: 'radial-gradient',
  [PaintType.GRADIENT_ANGULAR]: 'conic-gradient',
  [PaintType.GRADIENT_DIAMOND]: 'linear-gradient'
}

const directionString = {
  [PaintType.GRADIENT_LINEAR]: 'to right',
  [PaintType.GRADIENT_RADIAL]: '50% 50% at 50% 50%',
  [PaintType.GRADIENT_ANGULAR]: 'from 180deg at 50% 50%',
  [PaintType.GRADIENT_DIAMOND]: '50% 50% at 50% 50%'
}

export const getCssGradientString = (paintType, gradientStops, direction) => {
  const stops = gradientStops.length === 1 ? Array(2).fill(gradientStops[0]) : gradientStops
  const dir = direction || directionString[paintType]
  switch (paintType) {
    case PaintType.GRADIENT_LINEAR:
    case PaintType.GRADIENT_RADIAL:
    case PaintType.GRADIENT_ANGULAR:
      return `${gradientString[paintType]}(${dir}${dir && ','}${stops2rgbaString(stops)})`
    case PaintType.GRADIENT_DIAMOND: {
      const gradientTypeString = gradientString[paintType]
      const gradientColorsString = stops2rgbaString(stops, 0.5)
      return `${gradientTypeString}(to bottom right, ${gradientColorsString}) bottom right / 50% 50% no-repeat,
              ${gradientTypeString}(to bottom left, ${gradientColorsString}) bottom left / 50% 50% no-repeat,
              ${gradientTypeString}(to top left, ${gradientColorsString}) top left / 50% 50% no-repeat,
              ${gradientTypeString}(to top right, ${gradientColorsString}) top right / 50% 50% no-repeat`
    }
    default:
      // eslint-disable-next-line no-console
      console.error(`Invalid gradient type: ${paintType}`)
      return ''
  }
}

export const getCssColorString = ({ paintType, color, gradientStops }) => {
  switch (paintType) {
    case PaintType.SOLID:
      return rgb2rgbaString(color)
    case PaintType.GRADIENT_LINEAR:
    case PaintType.GRADIENT_RADIAL:
    case PaintType.GRADIENT_ANGULAR:
    case PaintType.GRADIENT_DIAMOND:
      return getCssGradientString(paintType, gradientStops)
    default:
      return ''
  }
}
