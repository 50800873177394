import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { PointShape } from '@phase-software/types'

import { useEditor, useEditorActions } from '../../../../providers/dataStore/EditorProvider'
import { RadioButtonGroup } from '../../../shared'

const POINT_SHAPE_MAP = {
  PointStraight: PointShape.NONE,
  PointMirror: PointShape.ANGLE_AND_LENGTH,
  PointAsymmetric: PointShape.ANGLE,
  PointIndependent: PointShape.INDEPENDENT
}

const POINT_SHAPE_TITLE_MAP: Record<string, string> = {
  PointStraight: 'straight',
  PointMirror: 'mirrored',
  PointIndependent: 'independent',
  PointAsymmetric: 'asymmetric'
}

const PointShapeEditor = () => {
  const { setProperties } = useEditorActions()
  const { mirror } = useEditor()
  const { t } = useTranslation('file', { keyPrefix: 'point_shape' })

  const handleMirrorChange = useCallback((value: string) => setProperties({ mirror: Number(value) }), [setProperties])

  const radioButtonOptions = useMemo(() => {
    return Object.entries(POINT_SHAPE_MAP).map(([shapeKey, shapeValue]) => ({
      key: shapeKey,
      name: `point-shape-${shapeKey}`,
      value: shapeValue,
      tooltip: t(POINT_SHAPE_TITLE_MAP[shapeKey]),
      icon: shapeKey
    }))
  }, [t])

  return (
    <div data-test-id="point-shape-editor">
      <RadioButtonGroup options={radioButtonOptions} selectedValue={mirror} onSelectionChange={handleMirrorChange} />
    </div>
  )
}

export default React.memo(PointShapeEditor)
