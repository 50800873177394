import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import { useAccessControl } from '../../../access-control'
import DeleteProjectDialog from '../../../components/modals/DeleteProjectDialog'
import RenameProjectDialog from '../../../components/modals/RenameProjectDialog'
import { Dropdown, IconButton } from '../../../components/shared'
import { MenuOptionProps } from '../../../components/shared/Menu/Menu.types'
import {
  isSeparatorOption,
  removeConsecutiveSeparators,
  translateMenuOptions
} from '../../../components/shared/Menu/utils'
import { PROJECT_DROPDOWN_OPTIONS, PROJECT_OPTIONS } from '../../../constants/projectConstants'
import { ProjectFieldsFragment } from '../../../generated/graphql'
import { useProjectPermissionContext } from '../../../providers/ProjectPermissionProvider'
import { WorkspaceData } from '../../../providers/WorkspaceContextProvider'
import { getWorkspaceProjectPath } from '../../../utils/pathGenerators'

type ProjectDropdownProps = {
  workspaceType: WorkspaceData['type']
  workspaceSlug: WorkspaceData['slug']
  id: ProjectFieldsFragment['id']
  name: string
}

const ProjectDropdown = ({ workspaceType, workspaceSlug, id, name }: ProjectDropdownProps) => {
  const { t } = useTranslation('common')
  const history = useHistory()
  const { userHasPermission } = useAccessControl()
  const { projectWithPermission } = useProjectPermissionContext()

  const [renameModalOpen, setRenameModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  const menuOptions = useMemo(() => {
    const filteredOptions = PROJECT_DROPDOWN_OPTIONS.filter(
      (option) =>
        isSeparatorOption(option) || !option.permission || userHasPermission(option.permission, projectWithPermission)
    )
    return translateMenuOptions(t, removeConsecutiveSeparators(filteredOptions))
  }, [projectWithPermission, userHasPermission, t])

  const handleSelectOption = (option: MenuOptionProps) => {
    switch (option?.value) {
      case PROJECT_OPTIONS.OPEN_IN_NEW_TAB:
        window.open(history.createHref({ pathname: getWorkspaceProjectPath(workspaceType, workspaceSlug, id) }))
        break
      case PROJECT_OPTIONS.RENAME:
        setRenameModalOpen(true)
        break
      case PROJECT_OPTIONS.DELETE:
        setDeleteModalOpen(true)
        break
      default:
        break
    }
  }

  return (
    <>
      <Dropdown
        inline
        menu={{
          options: menuOptions,
          onSelect: handleSelectOption
        }}
      >
        <IconButton icon="More" size="xxl" />
      </Dropdown>
      <RenameProjectDialog
        id={id}
        originalName={name}
        open={renameModalOpen}
        onClose={() => setRenameModalOpen(false)}
      />
      <DeleteProjectDialog id={id} name={name} open={deleteModalOpen} onClose={() => setDeleteModalOpen(false)} />
    </>
  )
}

export default ProjectDropdown
