import React from 'react'
import { useTranslation } from 'react-i18next'

import { useUI } from '../../providers/dataStore/UIProvider'
import { Icon } from '../shared'

const EditOriginIndicator = () => {
  const { t } = useTranslation('file', { keyPrefix: 'canvas' })

  const { editOrigin } = useUI()

  return (
    <div
      className="absolute w-full h-full pointer-events-none border-2 border-primary-50"
      style={{ visibility: editOrigin ? 'visible' : 'hidden' }}
    >
      <div className="absolute top-0 left-1/2 transform -translate-x-1/2 px-12 py-6 rounded-b-md bg-primary-50 text-white w-fit flex items-center justify-center">
        <Icon name="Origin" className="mr-4" useCurrentColor />
        <div>{t('editing_origin')}</div>
      </div>
    </div>
  )
}

export default EditOriginIndicator
