import React from 'react'
import { useDataStoreActions } from '../../../providers/dataStore/DataStoreProvider'
import Icon from '../Icon'

export type ToggleButtonProps = {
  name: string // NOTE: name should be unique
  value: string | number
  text?: string
  icon?: string
  checked?: boolean
  disabled?: boolean
  fluid?: boolean
  onChange: (value: string) => void
  onBlur?: (e: React.FocusEvent) => void
  onFocus?: (e: React.FocusEvent) => void
  className?: string
}

const ToggleButton = ({
  name,
  value,
  text,
  icon,
  checked = false,
  disabled = false,
  fluid = false,
  onChange,
  onFocus,
  onBlur,
  className = ''
}: ToggleButtonProps) => {
  const { changeEAMInputState } = useDataStoreActions()

  const paddingClassName = React.useMemo(() => {
    if (text) {
      if (icon) {
        return fluid ? 'py-6 pr-2' : 'pl-6 pr-8'
      }
      return 'px-8'
    }
    return 'p-6'
  }, [icon, text, fluid])

  const handleFocus = (e: React.FocusEvent) => {
    onFocus?.(e)
    changeEAMInputState(true)
  }
  const handleBlur = (e: React.FocusEvent) => {
    onBlur?.(e)
    changeEAMInputState(false)
  }
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case ' ':
      case 'Enter':
        onChange(String(value))
        break
      case 'Escape':
        e.currentTarget.blur()
        break
      default:
        break
    }
  }
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.currentTarget.value)
    e.currentTarget.blur()
  }

  return (
    <label htmlFor={name} className={`${fluid ? 'w-full' : 'w-fit'} cursor-pointer ${className}`}>
      <input
        type="checkbox"
        tabIndex={0}
        id={name}
        value={value}
        checked={checked}
        disabled={disabled}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        className="peer absolute opacity-0 w-0 h-0"
      />
      <div
        className={`h-28 min-w-[28px] ${
          fluid ? 'w-full' : 'w-fit'
        } flex justify-center items-center rounded-md overflow-hidden
        bg-light-overlay-10 text-light-overlay-60 hover:text-white active:text-light-overlay-40 peer-focus-visible:outline-primary-1-offset--1
        peer-checked:bg-light-overlay-20 peer-checked:text-white peer-checked:hover:bg-light-overlay-25 peer-checked:active:bg-light-overlay-15 peer-focus-visible:peer-checked:outline-primary-1-offset-2 peer-disabled:peer-checked:bg-light-overlay-20
        peer-disabled:opacity-40 peer-disabled:hover:text-light-overlay-60 peer-disabled:cursor-not-allowed peer-disabled:outline-none
        `}
      >
        <div className={`grid grid-flow-col gap-x-4 ${paddingClassName}`}>
          {icon ? <Icon name={icon} interactive={false} useCurrentColor /> : null}
          {text}
        </div>
      </div>
    </label>
  )
}

export default ToggleButton
