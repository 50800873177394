import { FlagsEnum } from "@phase-software/data-utils/src/mesh/Cell"
import { AlignType, DistributeType, EditMode, PointShape } from "@phase-software/types"
import { Rect2, Vector2 } from "../math"
import { fixInfiniteSkew } from "../visual_server/Transform"

// Alignment and distribution methods

/** @typedef {import('../visual_server/VisualServer').VisualServer} VisualServer */
/** @typedef {import('../visual_server/Bounds').Bounds} Bounds */
/** @typedef {import('@phase-software/data-utils').Mesh} Mesh */
/** @typedef {import('@phase-software/data-utils').Vertex} Vertex */
/** @typedef {import('../math').Transform2D} Transform2D */


/**
 * @typedef VertexAndPosition
 * @property {Vertex} vertex
 * @property {Vector2} worldPos
 */

/** @type {VisualServer} */
let _vs
// Define the tolerance for coordinate comparison in the editor (to two decimal points)
const EDITOR_EPSILON = 0.01
/**
 *
 * @param {VisualServer} visualServer
 */
export function initAlignmentDistribution(visualServer) {
    _vs = visualServer
}
export default class Alignment {

    constructor() {
    }

    /**
     *
     * @param {Element[]} selection
     * @param {AlignType} direction
     */
    static _doAlignElement(selection, direction) {
        if (selection.length === 1) {
            const element = selection[0]
            const parent = _vs.dataStore.getParentOf(element)
            if (!parent) {
                return
            }
            const elementNode = _vs.getRenderItemOfElement(element)
            const transformedBound = elementNode.transform.local.xform_rect(elementNode.bounds.local)
            const parentBound = _vs.getRenderItemOfElement(parent).bounds.local.clone()

            const newTranslate = Alignment._alginBound(direction, elementNode.transform.translate, parentBound, transformedBound)
            _vs.dataStore.startTransaction()
            element.sets({ translateX: newTranslate.x, translateY: newTranslate.y })
            _vs.dataStore.commitUndo()
            _vs.dataStore.endTransaction()
        } else {
            const alignBound = Alignment._snapToPixelGrid(direction)
            _vs.dataStore.startTransaction()
            for (const element of selection) {
                const elementNode = _vs.getRenderItemOfElement(element)
                const translateWorld = elementNode.transform.parent.xform(elementNode.transform.translate)
                const newTranslateWorld = Alignment._alginBound(direction, translateWorld, alignBound, elementNode.bounds.world)
                if (_vs.dataStore.data.snapToPixelGrid) {
                    if (direction === AlignType.MIDDLE) {
                        newTranslateWorld.y = Math.round(newTranslateWorld.y)
                    } else if (direction === AlignType.CENTER) {
                        newTranslateWorld.x = Math.round(newTranslateWorld.x)
                    }
                }
                const newTransalteLocal = elementNode.transform.parent.clone().affine_inverse().xform(newTranslateWorld)
                newTransalteLocal.x = this.roundToDecimal(newTransalteLocal.x, 2)
                newTransalteLocal.y = this.roundToDecimal(newTransalteLocal.y, 2)
                element.sets({ translateX: newTransalteLocal.x, translateY: newTransalteLocal.y })
            }
            _vs.dataStore.commitUndo()
            _vs.dataStore.endTransaction()
        }
    }

    /**
     *
     * @param {AlignType} direction
     * @param {Vector2} translate
     * @param {Bounds} targetBound
     * @param {Bounds} originBound
     * @returns {Vector2}
     */
    static _alginBound(direction, translate, targetBound, originBound) {
        const newTranslate = translate.clone()
        switch (direction) {
            case AlignType.LEFT:
                newTranslate.x += (targetBound.x - originBound.x)
                break
            case AlignType.RIGHT:
                newTranslate.x += (targetBound.right - originBound.right)
                break
            case AlignType.TOP:
                newTranslate.y += (targetBound.top - originBound.top)
                break
            case AlignType.BOTTOM:
                newTranslate.y += (targetBound.bottom - originBound.bottom)
                break
            case AlignType.MIDDLE:
                newTranslate.y += (targetBound.y + targetBound.height * 0.5) - (originBound.y + originBound.height * 0.5)
                break
            case AlignType.CENTER:
                newTranslate.x += (targetBound.x + targetBound.width * 0.5) - (originBound.x + originBound.width * 0.5)
                break
        }
        return newTranslate
    }

    static _snapToPixelGrid(direction) {
        const alignBound = _vs.selection.bounds.clone()
        if (_vs.dataStore.data.snapToPixelGrid) {
            switch (direction) {
                case AlignType.LEFT:
                    alignBound.x = Math.ceil(alignBound.x)
                    break
                case AlignType.RIGHT:
                    alignBound.width = Math.floor(alignBound.right) - alignBound.x
                    break
                case AlignType.TOP:
                    alignBound.y = Math.ceil(alignBound.y)
                    break
                case AlignType.BOTTOM:
                    alignBound.height = Math.floor(alignBound.bottom) - alignBound.y
                    break
                case AlignType.MIDDLE:
                case AlignType.CENTER:
                    // Does not need to adjust the bounding box
                    break
            }
        }
        return alignBound
    }

    /**
     *
     * @param {Element[]} selection
     * @param {AlignType} direction
     */
    static _doAlignVertices(selection, direction) {
        /** @type {Vertex[]} */
        const selectedVertices = _vs.dataStore.selection.get('vertices')
        if (!selectedVertices.length){
            return
        }
        /** @type {Mesh} */
        const mesh = selection[0].get('geometry').get('mesh')
        const { pathXform, pathXformInv } = Alignment._getPathTransform(selection[0])

        const bounds = new Rect2()
        if (selectedVertices.length === 1) {
            for (const vertex of mesh.getVertices()) {
                this._expandWorldBound(vertex, pathXform, bounds)
            }
            const vertex = selectedVertices[0]
            const worldPos = pathXform.xform(vertex.pos)
            Alignment._alignVertexByDirection(direction, worldPos, bounds)
            const localPos = pathXformInv.xform(worldPos)
            mesh.situateVertexToPos(vertex, localPos.x, localPos.y)
        } else {
            Alignment._setupPointShape(selectedVertices, mesh)
            mesh.applyChanges(mesh.changes)
            /** @type {VertexAndPosition[]} */
            const verticesAndPositions = Alignment._getVerticesWorldPositionsAndUpdateBounds(selectedVertices, pathXform, bounds)
            for (const { vertex, worldPos } of verticesAndPositions) {
                Alignment._alignVertexByDirection(direction, worldPos, bounds)
                const localPos = pathXformInv.xform(worldPos)
                mesh.situateVertexToPos(vertex, localPos.x, localPos.y)
            }
        }

        _vs.dataStore.startTransaction()
        mesh.applyChanges(mesh.changes)
        mesh.fire()
        _vs.dataStore.commitUndo()
        _vs.dataStore.endTransaction()
    }

    /**
     *
     * @param {Vertex[]} selectedVertices
     * @param {Mesh} mesh
     * @returns {void}
     */
    static _setupPointShape(selectedVertices, mesh) {
        // The application does not allow to select vertex and curve control simultaneously
        if (!selectedVertices[0].isFlagged(FlagsEnum.CURVE_VERT)) {
            return
        }
        const adjHash = new Map()
        for (const curveControl of selectedVertices) {
            if (curveControl.isFlagged(FlagsEnum.CURVE_VERT)) {
                if (!curveControl.adjacentMainVertex) {
                    console.warn('Please check file, a curve control does not attach a real vertex')
                    continue
                }
                const adjId = curveControl.adjacentMainVertex.id
                if (adjHash.has(adjId.id)) {
                    mesh.prepareVertexPropertyChanges(adjId, 'mirror', PointShape.INDEPENDENT)
                } else {
                    adjHash.set(adjId.id)
                }
            }
        }
    }

    /**
     *
     * @param {Vertex[]} selectedVertices
     * @param {Transform2D} pathXform
     * @param {Rect2} bounds
     * @returns {VertexAndPosition[]}
     */
    static _getVerticesWorldPositionsAndUpdateBounds(selectedVertices, pathXform, bounds) {
        const verticesAndPositions = []
        for (const vertex of selectedVertices) {
            const worldPos = new Vector2()
            const vertexPos = new Vector2()
            vertexPos.set(vertex.pos[0], vertex.pos[1])
            pathXform.xform(vertex.pos, worldPos)
            verticesAndPositions.push({ vertex, worldPos })
            if (bounds.is_zero()) {
                bounds.x = worldPos.x
                bounds.y = worldPos.y
            } else {
                bounds.expand_to(worldPos)
            }
        }
        return verticesAndPositions
    }

    /**
     *
     * @param {Element} element
     * @returns {{ pathXform:Transform2D, pathXformInv:Transform2D }}
     */
    static _getPathTransform(element) {
        const node = _vs.getRenderItemOfElement(element)
        const { translate, rotation, scale, skew, contentAnchor } = node.transform
        const pathXform = node.transform.parent.clone()
            .translate_right(translate.x, translate.y)
            .rotate_right(rotation)
            .skew_right(fixInfiniteSkew(skew.x), fixInfiniteSkew(skew.y))
            .scale_right(scale.x, scale.y)
            .translate_right(-contentAnchor.x, -contentAnchor.y)
        const pathXformInv = pathXform.clone().affine_inverse()
        return { pathXform, pathXformInv }
    }

    /**
     *
     * @param {AlignType} direction
     * @param {Vector2} worldPos
     * @param {Rect2} bounds
     */
    static _alignVertexByDirection(direction, worldPos, bounds) {
        switch (direction) {
            case AlignType.LEFT:
                worldPos.x = bounds.left
                break
            case AlignType.RIGHT:
                worldPos.x = bounds.right
                break
            case AlignType.TOP:
                worldPos.y = bounds.top
                break
            case AlignType.BOTTOM:
                worldPos.y = bounds.bottom
                break
            case AlignType.MIDDLE:
                worldPos.y = bounds.center.y
                break
            case AlignType.CENTER:
                worldPos.x = bounds.center.x
                break
        }

    }

    /**
     *
     * @param {Element[]} selectionArr
     * @param {DistributeType} direction
     */
    static _doDistributeElement(selectionArr, direction) {
        if (selectionArr.length < 3) {
            return
        }

        const elementIndex = this._preorderTraversalWithIndex(_vs.dataStore.workspace, selectionArr)
        const isHorizontal = direction === DistributeType.HORIZONTAL

        const elemNodePairs = selectionArr
            .map((element, index) => ({
                element,
                bounds: _vs.getRenderItemOfElement(element).bounds.world.clone(),
                index: elementIndex[index]
            })).sort(
                (a, b) => {
                    // Compare elements based on their position; sort by index if positions are within EPSILON tolerance
                    if (isHorizontal) {
                        return Math.abs(a.bounds.left - b.bounds.left) < EDITOR_EPSILON ? a.index - b.index : a.bounds.left - b.bounds.left
                    } else {
                        return Math.abs(a.bounds.top - b.bounds.top) < EDITOR_EPSILON ? a.index - b.index : a.bounds.top - b.bounds.top
                    }
                }
            )

        // Normalization to zero
        const leftmost = elemNodePairs.reduce((min, pair) => Math.min(pair.bounds.left, min), Number.MAX_VALUE)
        const topmost = elemNodePairs.reduce((min, pair) => Math.min(pair.bounds.top, min), Number.MAX_VALUE)
        for (const pair of elemNodePairs) {
            pair.bounds.offset(-leftmost, -topmost)
        }
        let spacing = 0
        for (let i = 1; i < elemNodePairs.length; i++) { // Higher order function, such as reduce, needs a O(n) space
            const prev = elemNodePairs[i - 1]
            const curr = elemNodePairs[i]
            spacing += isHorizontal
                ? curr.bounds.left - prev.bounds.right
                : curr.bounds.top - prev.bounds.bottom
        }
        spacing /= selectionArr.length - 1
        spacing = this.roundToDecimal(spacing, 2)

        const minSpacing = -elemNodePairs.reduce(
            (min, pair) => Math.min(isHorizontal ? pair.bounds.width : pair.bounds.height, min),
            Number.MAX_VALUE)

        if (spacing < minSpacing) {
            _vs.dataStore.eam.showNotification("info", "file:alignment.distribute.error")
            return
        }

        _vs.dataStore.startTransaction()
        const prevBounds = elemNodePairs[0].bounds // Already cloned
        for (let i = 1; i < elemNodePairs.length; i++) {
            const curr = elemNodePairs[i]
            const transform = _vs.getRenderItemOfElement(curr.element).transform
            const adjustment = new Vector2(0, 0)

            if (isHorizontal) {
                adjustment.x = spacing - (curr.bounds.left - prevBounds.right)
            } else {
                adjustment.y = spacing - (curr.bounds.top - prevBounds.bottom)
            }

            // Check if it's the last pair and if the adjustment is positive
            if (i === elemNodePairs.length - 1 && ((isHorizontal && adjustment.x > 0) || (!isHorizontal && adjustment.y > 0))) {
                break
            }

            // Transform adjustment to local coordinate system
            const worldInv = transform.parent.clone().affine_inverse()
            const localAdjustment = worldInv.basis_xform(adjustment)

            curr.element.sets({ translateX: transform.translate.x + localAdjustment.x, translateY: transform.translate.y + localAdjustment.y })
            prevBounds.copy(curr.bounds)
            prevBounds.offset(adjustment.x, adjustment.y)
        }
        _vs.dataStore.commitUndo()
        _vs.dataStore.endTransaction()
    }

    /**
     *
     * @param {Element[]} selection
     * @param {DistributeType} direction
     */
    static _doDistributeVertices(selection, direction) {
        const isHorizontal = direction === DistributeType.HORIZONTAL
        const selectedVertices = _vs.dataStore.selection.get('vertices')
        if (selectedVertices.length < 3) {
            return
        }
        /** @type {Mesh} */
        const mesh = selection[0].get('geometry').get('mesh')
        const { pathXform, pathXformInv } = Alignment._getPathTransform(selection[0])

        const bounds = new Rect2()
        /** @type {VertexAndPosition[]} */
        const verticesAndPositions = Alignment._getVerticesWorldPositionsAndUpdateBounds(selectedVertices, pathXform, bounds)

        const spacing = this.roundToDecimal((isHorizontal ? bounds.width : bounds.height) / (selectedVertices.length - 1), 2)

        if (Math.abs(spacing) < EDITOR_EPSILON) {
            return
        }


        const sortedvertexWithPos = verticesAndPositions.sort((a, b) => {
            if (Math.abs(a.worldPos.x - b.worldPos.x) < EDITOR_EPSILON && Math.abs(a.worldPos.y - b.worldPos.y) < EDITOR_EPSILON) {
                return a.vertex.index - b.vertex.index
            }
            // Compare elements based on their position; sort by index if positions are within EPSILON tolerance
            if (isHorizontal) {
                return Math.abs(a.worldPos.x - b.worldPos.x) < EDITOR_EPSILON ? a.worldPos.y - b.worldPos.y : a.worldPos.x - b.worldPos.x
            } else {
                return Math.abs(a.worldPos.y - b.worldPos.y) < EDITOR_EPSILON ? a.worldPos.x - b.worldPos.x : a.worldPos.y - b.worldPos.y
            }
        })

        /** Setup point shape if user want to move two connected curve controls */
        Alignment._setupPointShape(selectedVertices, mesh)
        mesh.applyChanges(mesh.changes)

        for (let i = 1; i < sortedvertexWithPos.length - 1; i++) {
            const { vertex, worldPos } = sortedvertexWithPos[i]
            if (isHorizontal) {
                worldPos.x = sortedvertexWithPos[0].worldPos.x + (i * spacing)
            } else {
                worldPos.y = sortedvertexWithPos[0].worldPos.y + (i * spacing)
            }
            const localPos = pathXformInv.xform(worldPos)
            mesh.situateVertexToPos(vertex, localPos.x, localPos.y)
        }

        _vs.dataStore.startTransaction()
        mesh.applyChanges(mesh.changes)
        mesh.fire()
        _vs.dataStore.commitUndo()
        _vs.dataStore.endTransaction()
    }

    /**
     *
     * @param {AlignType} direction
     */
    static align(direction) {
        const dataStore = _vs.dataStore
        const selection = dataStore.selection.get('elements')
        if (dataStore.get('editMode') === EditMode.ELEMENT) {
            this._doAlignElement(selection, direction)
        } else if (dataStore.get('editMode') === EditMode.SHAPE) {
            this._doAlignVertices(selection, direction)
        }
        dataStore.commitUndo()
    }

    /**
     *
     * @param {DistributeType} direction
     */
    static distribute(direction) {
        const dataStore = _vs.dataStore
        const selection = dataStore.selection.get('elements')
        if (dataStore.get('editMode') === EditMode.ELEMENT) {
            this._doDistributeElement(selection, direction)
        } else if (dataStore.get('editMode') === EditMode.SHAPE) {
            this._doDistributeVertices(selection, direction)
        }
        dataStore.commitUndo()
    }

    /**
     *
     * @param {Element} root
     * @param {Element[]} elements
     * @returns {number[]}
     */
    static _preorderTraversalWithIndex(root, elements) {
        const result = []
        if (!root) return result

        const stack = [root]
        let index = 0

        while (stack.length > 0) {
            const current = stack.pop()
            const indexInElements = elements.indexOf(current)
            if (indexInElements !== -1) {
                result[indexInElements] = index++
            }

            // Push children to stack in reverse order to process them in the original order
            if (!current.children) {
                continue
            }
            // Our application have special rule
            for (let i = 0; i < current.children.length; i++) {
                stack.push(current.children[i])
            }
        }

        return result
    }

    /**
     *
     * @param {number} value
     * @param {number} decimal
     * @returns {number}
     */
    static roundToDecimal(value, decimal) {
        const floatNumber = Math.pow(10, decimal)
        return Math.round(value * floatNumber) / floatNumber
    }

    /**
     * @param {Vertex} vertex
     * @param {Transform2D} pathXform
     * @param {Rect2} bounds
     * @returns {Rect2}
     */
    static _expandWorldBound(vertex, pathXform, bounds) {
        const newWorldPos = new Vector2()
        const vertexPos = new Vector2()
        vertexPos.set(vertex.pos[0], vertex.pos[1])
        pathXform.xform(vertex.pos, newWorldPos)
        if (bounds.is_zero()) {
            bounds.x = newWorldPos.x
            bounds.y = newWorldPos.y
        } else {
            bounds.expand_to(newWorldPos)
        }
        return bounds
    }
}


