import { LayerType } from '@phase-software/types'
import PaintComponent from '../component/PaintComponent'
import Layer from './Layer'


/** @typedef {import('../DataStore').DataStore} DataStore */
/** @typedef {import('../component/PropertyComponent').AppliedRef} AppliedRef */


const PROP_NAMES = ['paintId']

const PROP_NAME_TO_COMPONENT_MAP = {
    paint: [PaintComponent]
}

export default class Fill extends Layer {
    /**
     * @param {DataStore} dataStore 
     * @param {LayerData} data
     * @param {object} [options] 
     * @param {bool} [options.regenId=false]   if set to true, will generate new ID
     */
    constructor(dataStore, data, options) {
        super(dataStore, data, options)
    }

    _init() {
        super._init()

        PROP_NAMES.forEach(v => this._propNames.add(v))
        this._propClasses = { ...this._propClasses, ...PROP_NAME_TO_COMPONENT_MAP }
        this._subComponentIds = { paint: 'paintId' }
        this._subComponentPropMap = { paintId: 'paint' }
        this._props.paintId = ''
        this.layerType = LayerType.FILL
    }

    /**
     * @param {FillData} data
     * @param {object} [options] 
     * @param {bool} [options.regenId=false]   if set to true, will generate new ID
     */
    load(data, options) {
        super.load(data, options)
        if (data.paintId) {
            this._props.paintId = data.paintId
        }
    }

    /**
     * Clones Layer and applies any inner sharable PropertyComponents to element+style+itself
     * Override this in subclasses
     * CALL super.clone() at the top of overriden method
     * @param {AppliedRef} ref
     * @returns {Fill}
     */
    clone(ref) {
        const obj = super.clone(ref)
        obj._props.paintId = this._props.paintId
        return obj
    }

    /**
     * @returns {FillData} data
     */
    save() {
        const data = super.save()
        data.paintId = this._props.paintId
        return data
    }

    /**
     * Use it to pass on apply calls to inner properties (like Paint)
     * Override this in subclasses if subclass has any inner sharable PropertyComponents
     * CALL super.apply() at the top of overriden method
     * @param {AppliedRef} ref
     */
    // eslint-disable-next-line no-unused-vars
    apply(ref) {
        super.apply(ref)
        this.paint.apply({ ...ref, layerId: this.id })
    }

    /**
     * Use it to pass on cancel calls to inner properties (like Paint)
     * Override this in subclasses if subclass has any inner sharable PropertyComponents
     * CALL super.cancel() at the top of overriden method
     * @param {AppliedRef} ref
     */
    // eslint-disable-next-line no-unused-vars
    cancel(ref) {
        super.cancel(ref)
        this.paint.cancel({ ...ref, layerId: this.id })
    }

    get paint() {
        return this.dataStore.library.getComponent(this._props.paintId)
    }

    set paint(data) {
        this.setProperty('paint', data)
    }

    get paintId() {
        return this._props.paintId
    }

    set paintId(data) {
        this.setProperty('paintId', data)
    }
}

/** @typedef {import('../component/PaintComponent')} PaintComponent */
/** @typedef {import('./Layer').LayerData} LayerData */

/**
 * @typedef {LayerData} FillData
 * @property {PaintComponent} paint
 */
