import PropertyStack from '../../PropertyStack'

class OffsetYStack extends PropertyStack {
  constructor(elementStack, data) {
    super(elementStack, data)
    this.type = 'OFFSET_Y'
    this.key = 'offsetY'
    this.dataKey = 'offsetY'
    this.animatableProperties = new Set(['offsetY'])
  }
}

export default OffsetYStack
