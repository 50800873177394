import { useCallback } from 'react'
import { createProvider } from '../utils'

const [Provider, useSelectState, useSetState] = createProvider('Fonts')

export const useFonts = useSelectState

export const useSetFonts = () => {
  const setState = useSetState()
  const setFonts = useCallback(
    (fonts) => {
      setState(fonts)
    },
    [setState]
  )
  return {
    setFonts
  }
}

export default Provider
