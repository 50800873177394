import {
    CMP_EPSILON,
    TRIGONOMETRIC_EPSILON,
} from './const'

/**
 * @typedef Vector2Like
 * @property {number} x
 * @property {number} y
 */

/**
 * The Vector2 object represents a location in a two-dimensional coordinate system, where x represents
 * the horizontal axis and y represents the vertical axis.
 * @param {number} x
 * @param {number} y
 */
export function Vector2(x = 0, y = 0) {
    /** @type {number} */
    this.x = x
    /** @type {number} */
    this.y = y
    /** @type {[number, number]} */
    this._array = null
}

/**
 * @param {number} x1
 * @param {number} y1
 * @param {number} x2
 * @param {number} y2
 */
Vector2.isOrthogonal = (x1, y1, x2, y2) => (
    Math.abs(x1 * x2 + y1 * y2)
    <=
    Math.sqrt((x1 * x1 + y1 * y1) * (x2 * x2 + y2 * y2)) * TRIGONOMETRIC_EPSILON
)

/**
 * @param {number} x1
 * @param {number} y1
 * @param {number} x2
 * @param {number} y2
 * @returns {boolean}
 */
Vector2.isCollinear = (x1, y1, x2, y2) => (
    // NOTE: We use normalized vectors so that the epsilon comparison is
    // reliable. We could instead scale the epsilon based on the vector
    // length. But instead of normalizing the vectors before calculating
    // the cross product, we can scale the epsilon accordingly.
    Math.abs(x1 * y2 - y1 * x2)
    <=
    Math.sqrt((x1 * x1 + y1 * y1) * (x2 * x2 + y2 * y2)) * TRIGONOMETRIC_EPSILON
)

Vector2.prototype = {
    constructor: Vector2,

    get width() { return this.x },
    set width(value) {
        this.x = value
    },
    /**
     * @param {number} value
     */
    set_width(value) {
        this.x = value
    },

    get height() { return this.y },
    set height(value) {
        this.y = value
    },
    /**
     * @param {number} value
     */
    set_height(value) {
        this.y = value
    },

    /**
     * @param {number} value
     */
    set_x(value) {
        this.x = value
    },
    /**
     * @param {number} value
     */
    set_y(value) {
        this.y = value
    },

    /**
     * @param {[number, number]} [out]
     * @returns {[number, number]}
     */
    as_array(out) {
        if (!out && !this._array) {
            this._array = [this.x, this.y]
            return this._array
        }
        const _out = out || this._array
        _out[0] = this.x
        _out[1] = this.y
        return _out
    },

    /**
     * @param {number[]} arr
     * @returns {this}
     */
    fromArray(arr) {
        this.x = arr[0]
        this.y = arr[1]
        return this
    },

    /**
     * Sets the point to a new x and y position.
     * If y is omitted, both x and y will be set to x.
     *
     * @param {number} [x] - position of the point on the x axis
     * @param {number} [y] - position of the point on the y axis
     * @returns {this}
     */
    set(x = 0, y = 0) {
        this.x = x
        this.y = y
        return this
    },
    /**
     * Copy value from other vector
     *
     * @param {Vector2Like} p_b
     * @returns {this}
     */
    copy(p_b) {
        this.x = p_b.x
        this.y = p_b.y
        return this
    },

    /**
     * @param {Vector2Like} p_b
     * @param {number} t
     * @returns {this}
     */
    mix_with(p_b, t) {
        this.x += (p_b.x - this.x) * t
        this.y += (p_b.y - this.y) * t
        return this
    },

    /**
     * Returns new Vector2 with same value.
     * @returns {Vector2}
     */
    clone() {
        return new Vector2(this.x, this.y)
    },
    /**
     * Returns new Vector2 but normalized.
     * @returns {Vector2}
     */
    normalized() {
        return this.clone().normalize()
    },
    /**
     * Returns new Vector2 but clamped.
     * @param {number} p_length
     * @returns {Vector2}
     */
    clamped(p_length) {
        const len = this.length()
        const v = this.clone()
        if (len > 0 && p_length < len) {
            v.scale(p_length / len)
        }
        return v
    },
    /**
     * Returns new Vector2 but rotated.
     *
     * @param {number} p_rotation
     * @returns {Vector2}
     */
    rotated(p_rotation) {
        return this.clone().rotate(p_rotation)
    },

    /**
     * Whether this equals to another point
     * @param {Vector2Like} p_b
     * @returns {boolean}
     */
    equals(p_b) {
        const a0 = this.x, a1 = this.y
        const b0 = p_b.x, b1 = p_b.y
        return (Math.abs(a0 - b0) <= CMP_EPSILON * Math.max(1.0, Math.abs(a0), Math.abs(b0)) &&
            Math.abs(a1 - b1) <= CMP_EPSILON * Math.max(1.0, Math.abs(a1), Math.abs(b1)))
    },

    /**
     * Whether this equals to another point(precisely)
     * @param {Vector2Like} p_b
     * @returns {boolean}
     */
    exact_equals(p_b) {
        return (this.x === p_b.x) && (this.y === p_b.y)
    },

    /**
     * Add the vector by another vector or number.
     * @param {number | Vector2Like} x
     * @param {number} [y]
     * @returns {this}
     */
    add(x, y) {
        if (y === undefined) {
            // @ts-ignore
            this.x += x.x
            // @ts-ignore
            this.y += x.y
        } else {
            // @ts-ignore
            this.x += x
            // @ts-ignore
            this.y += y
        }
        return this
    },

    /**
     * Subtract the vector by another vector or number.
     * @param {number | Vector2Like} x
     * @param {number} [y]
     * @returns {this}
     */
    subtract(x, y) {
        if (y === undefined) {
            // @ts-ignore
            this.x -= x.x
            // @ts-ignore
            this.y -= x.y
        } else {
            // @ts-ignore
            this.x -= x
            // @ts-ignore
            this.y -= y
        }
        return this
    },
    /**
     * Subtract the vector by another vector or number.
     * @param {number | Vector2Like} x
     * @param {number} [y]
     * @returns {this}
     */
    sub(x, y) {
        if (y === undefined) {
            // @ts-ignore
            this.x -= x.x
            // @ts-ignore
            this.y -= x.y
        } else {
            // @ts-ignore
            this.x -= x
            // @ts-ignore
            this.y -= y
        }
        return this
    },

    /**
     * Multiply the vector by another vector or number.
     * @param {number | Vector2Like} x
     * @param {number} [y]
     * @returns {this}
     */
    multiply(x, y) {
        if (y === undefined) {
            // @ts-ignore
            this.x *= x.x
            // @ts-ignore
            this.y *= x.y
        } else {
            // @ts-ignore
            this.x *= x
            // @ts-ignore
            this.y *= y
        }
        return this
    },

    /**
     * Divide x and y by another vector or number.
     * @param {number | Vector2Like} x
     * @param {number} [y]
     * @returns {this}
     */
    divide(x, y) {
        if (y === undefined) {
            // @ts-ignore
            this.x /= x.x
            // @ts-ignore
            this.y /= x.y
        } else {
            // @ts-ignore
            this.x /= x
            // @ts-ignore
            this.y /= y
        }
        return this
    },

    /**
     * Dot multiply another vector.
     * @param {Vector2Like} p_b
     * @returns {number}
     */
    dot(p_b) {
        return this.x * p_b.x + this.y * p_b.y
    },

    /**
     * Cross multiply another vector.
     * @param {Vector2Like} p_b
     * @returns {number}
     */
    cross(p_b) {
        return this.x * p_b.y - this.y * p_b.x
    },

    /**
     * Change x and y components to their absolute values.
     * @returns {this}
     */
    abs() {
        this.x = Math.abs(this.x)
        this.y = Math.abs(this.y)
        return this
    },

    /**
     * Change x and y components to their sign values.
     * @returns {this}
     */
    sign() {
        this.x = Math.sign(this.x)
        this.y = Math.sign(this.y)
        return this
    },

    /**
     * Ceil x and y components.
     * @returns {this}
     */
    ceil() {
        this.x = Math.ceil(this.x)
        this.y = Math.ceil(this.y)
        return this
    },

    /**
     * Floor x and y components.
     * @returns {this}
     */
    floor() {
        this.x = Math.floor(this.x)
        this.y = Math.floor(this.y)
        return this
    },

    /**
     * Round to int vector.
     * @returns {this}
     */
    round() {
        this.x = Math.round(this.x)
        this.y = Math.round(this.y)
        return this
    },

    /**
     * Truncate to int vector.
     * @returns {this}
     */
    trunc() {
        this.x = Math.trunc(this.x)
        this.y = Math.trunc(this.y)
        return this
    },

    /**
     * Clamp the vector to specific length.
     * @param {number} p_length
     * @returns {this}
     */
    clamp(p_length) {
        const len = this.length()
        if (len > 0 && p_length < len) {
            this.scale(p_length / len)
        }
        return this
    },

    /**
     * Scale the vector by a number factor.
     * @param {number} p_factor
     * @returns {this}
     */
    scale(p_factor) {
        this.x *= p_factor
        this.y *= p_factor
        return this
    },

    /**
     * Scale the vector by a number factor.
     * @param {number} scale
     * @param {Vector2} center
     * @returns {this}
     */
    scale_with_center(scale, center) {
        this.sub(center).scale(scale).add(center)
        return this
    },

    /**
     * Negate x and y components.
     * @returns {this}
     */
    negate() {
        this.x = -this.x
        this.y = -this.y
        return this
    },

    /**
     * Inverse the x and y components.
     * @returns {this}
     */
    inverse() {
        this.x = 1.0 / this.x
        this.y = 1.0 / this.y
        return this
    },

    /**
     * Swap the x and y components.
     * @returns {this}
     */
    swap() {
        const y = this.x
        this.x = this.y
        this.y = y
        return this
    },

    /**
     * Normalize this vector to unit length.
     * @returns {this}
     */
    normalize() {
        const x = this.x, y = this.y
        let len = x * x + y * y
        if (len > 0) {
            len = 1 / Math.sqrt(len)
            this.x *= len
            this.y *= len
        }
        return this
    },

    /**
     * Rotates the vector by “phi” radians.
     * @param {number} p_rotation
     * @returns {this}
     */
    rotate(p_rotation) {
        const x = this.x, y = this.y
        const c = Math.cos(p_rotation), s = Math.sin(p_rotation)
        this.x = x * c - y * s
        this.y = x * s + y * c
        return this
    },

    /**
     * Change this vector to be perpendicular to what it was before. (Effectively
     * roatates it 90 degrees in a clockwise direction with the Y axis pointing up)
     * @returns {this}
     */
    perp() {
        const x = this.x
        this.x = this.y
        this.y = -x
        return this
    },

    /**
     * Change this vector to be perpendicular to what it was before. (Effectively
     * roatates it 90 degrees in a ccw direction with the Y axis pointing up)
     * @returns {this}
     */
    perp_inv() {
        const x = this.x
        this.x = -this.y
        this.y = x
        return this
    },

    /**
     * Returns the normal vector of the line formed by `this` and `b`
     *
     * @param {Vector2} b
     * @returns {Vector2}
     */
    normal(b) {
        return b.clone().sub(this).normalize().perp()
    },

    /**
     * Returns the directional vector of the line formed by `this` and `b`
     *
     * @param {Vector2} b
     * @returns {Vector2}
     */
    direction(b) {
        return b.clone().sub(this).normalize()
    },

    /**
     * Returns new Vector2.
     * @param {number} p_d
     * @param {Vector2} p_vec
     * @returns {Vector2}
     */
    plane_project(p_d, p_vec) {
        const self = this.clone()
        const vec = p_vec.clone().subtract(self.scale(this.dot(p_vec) - p_d))
        return vec
    },

    /**
     * Project to a vector.
     * @param {Vector2} p_b
     * @returns {this}
     */
    project(p_b) {
        const amt = this.dot(p_b) / p_b.length_squared()
        this.x = amt * p_b.x
        this.y = amt * p_b.y
        return this
    },

    /**
     * Project to a vector which is already normalized.
     * @param {Vector2Like} p_b
     * @returns {this}
     */
    project_n(p_b) {
        const amt = this.dot(p_b)
        this.x = amt * p_b.x
        this.y = amt * p_b.y
        return this
    },

    /**
     * Reflects the vector along the given plane, specified by its normal vector.
     * @param {Vector2Like} axis
     * @returns {this}
     */
    reflect(axis) {
        const dot = this.dot(axis)
        this.x = 2 * axis.x * dot - this.x
        this.y = 2 * axis.y * dot - this.y
        return this
    },

    /**
     * Bounce returns the vector “bounced off” from the given plane, specified by its normal vector.
     * @param {Vector2Like} normal
     * @returns {this}
     */
    bounce(normal) {
        return this.reflect(normal).negate()
    },

    /**
     * Slide returns the component of the vector along the given plane, specified by its normal vector.
     * @param {Vector2Like} normal
     * @returns {this}
     */
    slide(normal) {
        return this.subtract(tmp_point.copy(normal).scale(this.dot(normal)))
    },

    /**
     * Returns the length of the vector.
     * @returns {number}
     */
    length() {
        const x = this.x
        const y = this.y
        return Math.sqrt(x * x + y * y)
    },

    /**
     * Returns the squared length of the vector. Prefer this function
     * over “length” if you need to sort vectors or need the squared length for some formula.
     * @returns {number}
     */
    length_squared() {
        const x = this.x
        const y = this.y
        return x * x + y * y
    },

    /**
     * Returns the result of atan2 when called with the Vector’s x and y as parameters (Math::atan2(x,y)).
     * @returns {number} [-PI, PI]
     */
    angle() {
        return Math.atan2(this.y, this.x)
    },

    /**
     * Returns the angle in radians between the two vectors.
     * @param {Vector2Like} p_b
     * @returns {number} [-PI, PI]
     */
    angle_to(p_b) {
        return Math.atan2(this.cross(p_b), this.dot(p_b))
    },

    /**
     * Returns the angle in radians between the two vectors.
     * @param {Vector2Like} p_b
     * @returns {number} [0, 2PI]
     */
    angle_to_2(p_b) {
        let angle = this.angle_to(p_b)
        if (angle < 0) angle += 2 * Math.PI
        return angle
    },

    /**
     * Returns the ccw angle in radians between the two vectors.
     * @param {Vector2Like} p_b
     * @returns {number} [0, 2PI]
     */
    angle_to_ccw(p_b) {
        let angle = Math.atan2(this.y, this.x) - Math.atan2(p_b.y, p_b.x)
        if (angle < 0) angle += 2 * Math.PI
        return angle
    },

    /**
     * @param {Vector2Like} p_b
     * @returns {number}
     */
    angle_to_point(p_b) {
        return Math.atan2(this.y - p_b.y, this.x - p_b.x)
    },

    /**
     * Returns the distance to vector “b”.
     * @param {Vector2Like} p_b
     * @returns {number}
     */
    distance_to(p_b) {
        const x = p_b.x - this.x
        const y = p_b.y - this.y
        return Math.sqrt(x * x + y * y)
    },

    /**
     * Returns the squared distance to vector “b”. Prefer this function
     * over “distance_to” if you need to sort vectors or need the squared distance for some formula.
     * @param {Vector2Like} p_b
     * @returns {number}
     */
    distance_squared_to(p_b) {
        const x = p_b.x - this.x
        const y = p_b.y - this.y
        return x * x + y * y
    },

    /**
     * Returns a perpendicular vector.
     * @param {Vector2} [r_out]
     * @returns {Vector2}
     */
    tangent(r_out = new Vector2()) {
        return r_out.set(this.y, -this.x)
    },

    aspect() {
        return this.x / this.y
    },

    is_zero(tolerance = null) {
        if(!tolerance) return this.x === 0 && this.y === 0
        return isZero(this.x, tolerance) && isZero(this.y, tolerance)
    },

    /**
     * @param {Vector2} point
     */
    isOrthogonal(point) {
        return Vector2.isOrthogonal(this.x, this.y, point.x, point.y)
    },

    // /**
    //  * @param {number} x1
    //  * @param {number} y1
    //  * @param {number} x2
    //  * @param {number} y2
    //  */
    // static isOrthogonal(x1, y1, x2, y2) {
    //     return Math.abs(x1 * x2 + y1 * y2)
    //             <= Math.sqrt((x1 * x1 + y1 * y1) * (x2 * x2 + y2 * y2))
    //                 * TRIGONOMETRIC_EPSILON
    // }

    /**
     * Checks if this vector is collinear (parallel) to another vector.
     *
     * @param {Vector2} vec the vector to check against
     */
    isCollinear(vec) {
        return Vector2.isCollinear(this.x, this.y, vec.x, vec.y)
    },

    /**
     * @param {Vector2Like} p_b
     * @param {number} p_t
     * @returns {this}
     */
    linear_interpolate(p_b, p_t) {
        this.x += (p_t * (p_b.x - this.x))
        this.y += (p_t * (p_b.y - this.y))
        return this
    },

    /**
     * Returns new Vector2.
     * @param {Vector2Like} p_b
     * @param {Vector2Like} p_pre_a
     * @param {Vector2Like} p_post_b
     * @param {number} p_t
     * @returns {Vector2}
     */
    cubic_interpolate(p_b, p_pre_a, p_post_b, p_t) {
        const t2 = p_t * p_t
        const t3 = t2 * p_t
        return new Vector2(
            0.5 * ((this.x * 2) + (-p_pre_a.x + p_b.x) * p_t + (2 * p_pre_a.x - 5 * this.x + 4 * p_b.x - p_post_b.x) * t2 + (-p_pre_a.x + 3 * this.x - 3 * p_b.x + p_post_b.x) * t3),
            0.5 * ((this.y * 2) + (-p_pre_a.y + p_b.y) * p_t + (2 * p_pre_a.y - 5 * this.y + 4 * p_b.y - p_post_b.y) * t2 + (-p_pre_a.y + 3 * this.y - 3 * p_b.y + p_post_b.y) * t3)
        )
    },

    /**
     * @returns {boolean}
     */
    valid() {
        return !isNaN(this.x) && !isNaN(this.y)
    },

    /**
     * Checks if the point is within a given distance of another point.
     * @param {Vector2} point the point to check against
     * @param {number} tolerance the maximum distance allowed
     * @returns {boolean} {@true if it is within the given distance}
     */
    isClose(point, tolerance) {
        return this.getDistance(point) <= tolerance
    },

    /**
     * Returns the distance between the point and another point.
     * @param {Vector2} point
     * @param {boolean} [squared=false] Controls whether the distance should
     * remain squared, or its square root should be calculated
     * @returns {number}
     */
    getDistance(point, squared = false) {
        const x = point.x - this.x
        const y = point.y - this.y
        const d = x * x + y * y
        return squared ? d : Math.sqrt(d)
    },

    /**
     * @param {Rect2} rect
     */
    isInside(rect) {
        return rect.contains(this.x, this.y)
    },

    /**
     * @param {Vector2} v1
     */
    angleToPoint(v1) {
        return Math.atan2(this.y - v1.y, this.x - v1.x)
    },
    /**
     * @param {Vector2} v1
     */
    distance(v1) {
        return Math.hypot(this.x - v1.x, this.y - v1.y)
    }
}

const isZero = (val, tolerance) => (val >= -tolerance && val <= tolerance)

Vector2.ZERO = Object.freeze(new Vector2(0, 0))
Vector2.ONE = Object.freeze(new Vector2(1, 1))
Vector2.INF = Object.freeze(new Vector2(Infinity, Infinity))
Vector2.LEFT = Object.freeze(new Vector2(-1, 0))
Vector2.RIGHT = Object.freeze(new Vector2(1, 0))
Vector2.UP = Object.freeze(new Vector2(0, -1))
Vector2.DOWN = Object.freeze(new Vector2(0, 1))

const tmp_point = new Vector2()
