import {
    LayerType,
    GrowDirection,
    CapShape,
    JoinShape,
    EndShape
} from '@phase-software/types'
import { isNum } from '@phase-software/data-utils'
import Fill from './Fill'

/** @typedef {import('../component/PropertyComponent').AppliedRef} AppliedRef */

const PROP_NAMES = [
    'width',
    'growDirection',
    'offset',
    'dash',
    'gap',
    'cap',
    'capSize',
    'join',
    'joinSize',
    'miter',
    'ends'
]

const PROP_NAME_TO_COMPONENT_MAP = {
    width: undefined,
    growDirection: undefined,
    offset: undefined,
    dash: undefined,
    gap: undefined,
    cap: undefined,
    capSize: undefined,
    join: undefined,
    joinSize: undefined,
    miter: undefined,
    ends: undefined
}

const GROW_DIRECTION = new Set([
    GrowDirection.INSIDE,
    GrowDirection.CENTER,
    GrowDirection.OUTSIDE
])

const CAP_SHAPE = new Set([
    CapShape.NONE,
    CapShape.ROUND,
    CapShape.LINE_ARROW,
    CapShape.TRIANGLE_ARROW_SOLID,
    CapShape.TRIANGLE_ARROW_OUTLINE,
    CapShape.CIRCLE_SOLID,
    CapShape.CIRCLE_OUTLINE,
    CapShape.SQUARE_SOLID,
    CapShape.SQUARE_OUTLINE
])

const JOIN_SHAPE = new Set([
    JoinShape.MITER,
    JoinShape.CONCAVE,
    JoinShape.ROUND,
    JoinShape.BEVEL,
    JoinShape.NONE
])

const ENDS_SHAPE = new Set([
    EndShape.STRAIGHT,
    EndShape.ROUND
]);


export default class Stroke extends Fill {
    /**
     * @param {DataStore} dataStore 
     * @param {LayerData} data
     * @param {object} [options] 
     * @param {bool} [options.regenId=false]   if set to true, will generate new ID
     */
    constructor(dataStore, data, options) {
        super(dataStore, data, options)
    }

    _init() {
        super._init()
        this.layerType = LayerType.STROKE

        PROP_NAMES.forEach(v => this._propNames.add(v))
        this._propClasses = { ...this._propClasses, ...PROP_NAME_TO_COMPONENT_MAP }

        this._props.width = 1
        this._props.growDirection = GrowDirection.CENTER
        this._props.offset = 0
        this._props.dash = []
        this._props.gap = []
        this._props.cap = CapShape.NONE
        this._props.capSize = 100
        this._props.join = JoinShape.MITER
        this._props.joinSize = 100
        this._props.miter = 28.96
        this._props.ends = EndShape.STRAIGHT
        this._props.paintId = ''
    }

    /**
     * @param {StrokeData} data
     * @param {object} [options] 
     * @param {bool} [options.regenId=false]   if set to true, will generate new ID
     */
    load(data, options) {
        super.load(data, options)
        Object.keys(data).forEach((propName) => {
            if (PROP_NAMES.includes(propName)) {
                this._props[propName] = data[propName]
            }
        })
    }

    /**
     * Clones Layer and applies any inner sharable PropertyComponents to element+style+itself
     * Override this in subclasses
     * CALL super.clone() at the top of overriden method
     * @param {AppliedRef} ref
     * @returns {Stroke}
     */
    clone(ref) {
        const obj = super.clone(ref)
        PROP_NAMES.forEach((propName) => {
            obj._props[propName] = this._props[propName]
        })
        return obj
    }

    /**
     * @returns {StrokeData} data
     */
    save() {
        const data = super.save()
        PROP_NAMES.forEach((propName) => {
            data[propName] = this._props[propName]
        })
        return data
    }

    get width() {
        return this._props.width
    }

    set width(data) {
        this.setProperty('width', data)
    }

    get growDirection() {
        return this._props.growDirection
    }

    set growDirection(data) {
        this.setProperty('growDirection', data)
    }

    get offset() {
        return this._props.offset
    }

    set offset(data) {
        this.setProperty('offset', data)
    }

    get dash() {
        return this._props.dash
    }

    set dash(data) {
        this.setProperty('dash', data)
    }

    get gap() {
        return this._props.gap
    }

    set gap(data) {
        this.setProperty('gap', data)
    }

    get cap() {
        return this._props.cap
    }

    set cap(data) {
        this.setProperty('cap', data)
    }

    get capSize() {
        return this._props.capSize
    }

    set capSize(data) {
        this.setProperty('capSize', data)
    }

    get join() {
        return this._props.join
    }

    set join(data) {
        this.setProperty('join', data)
    }

    get joinSize() {
        return this._props.joinSize
    }

    set joinSize(data) {
        this.setProperty('joinSize', data)
    }

    get miter() {
        return this._props.miter
    }

    set miter(data) {
        this.setProperty('miter', data)
    }

    get ends() {
        return this._props.ends
    }

    set ends(data) {
        this.setProperty('ends', data)
    }

    _typeCheck(propName, data) {
        switch (propName) {
            case 'offset':
                return isNum(data)
            case 'width':
            case 'capSize':
            case 'joinSize':
            case 'miter':
                return isNum(data) && data >= 0
            case 'dash':
                return data.every(n => isNum(n) && n >= 0)
            case 'gap':
                return data.every(n => isNum(n) && n >= 0)
            case 'growDirection':
                return GROW_DIRECTION.has(data)
            case 'cap':
                return CAP_SHAPE.has(data)
            case 'join':
                return JOIN_SHAPE.has(data)
            case 'ends':
                return ENDS_SHAPE.has(data)
            default:
                return super._typeCheck(propName, data)
        }
    }
}

/** @typedef {'INSIDE'|'CENTER'|'OUTSIDE'} GrowDirection */

/**
 * @typedef {(
 *  'NONE' | 'LINE_ARROW' | 'TRIANGLE_ARROW_SOLID' | 'TRIANGLE_ARROW_OUTLINE' |
 *  'CIRCLE_SOLID' | 'CIRCLE_OUTLINE' | 'SQUARE_SOLID' | 'SQUARE_OUTLINE'
 * )} CapShape
 */

/** @typedef {('STRAIGHT' | 'CONCAVE' | 'CONVEX' | 'SLANT' | 'NONE')} JoinShape */

/** @typedef {('STRAIGHT' | 'ROUND' )} EndsShape */

/**
 * @typedef {FillData} StrokeData
 * @property {number} width
 * @property {GrowDirection} growDirection
 * @property {number} offset
 * @property {number[]} dash
 * @property {number[]} gap
 * @property {CapShape} cap
 * @property {number} capSize       in %
 * @property {JoinShape} join
 * @property {number} joinSize      in %
 * @property {number} miter         in degrees
 * @property {EndsShape} ends
 */
