import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory, useLocation, useParams } from 'react-router'

import { useApolloClient } from '@apollo/client'
import { WorkspaceType } from '@phase-software/types'

import { Button, Result } from '../../components/shared'
import { GetTeamByIdDocument } from '../../generated/graphql'
import useTeamMemberActions from '../../hooks/useTeamMemberActions'
import { useSetNotification } from '../../providers/NotificationProvider'
import { useProfile } from '../../providers/ProfileProvider'
import { getWorkspaceDraftsPath } from '../../utils/pathGenerators'

const JoinTeam = () => {
  const { t } = useTranslation('workspace')
  const client = useApolloClient()
  const location = useLocation()
  const history = useHistory()
  const profile = useProfile()
  const { workspaceSlug } = useParams() as { workspaceSlug: string }

  const { addNotification } = useSetNotification()

  const queryParams = new URLSearchParams(location.search)
  const invitationId = queryParams.get('id')
  const teamId = queryParams.get('team_id')

  const { joinTeam } = useTeamMemberActions()
  const [invitationInvalid, setInvitationInvalid] = useState(false)

  const handleGoToHomepage = () => {
    window.location.href = process.env.PUBLIC_URL
  }

  useEffect(() => {
    if (!teamId || !invitationId) return
    const validateAndJoin = async () => {
      try {
        await joinTeam({ teamId, invitationId })

        const { data } = await client.query({
          query: GetTeamByIdDocument,
          variables: { id: teamId }
        })
        const teamName = data.teams[0].name

        addNotification({
          type: 'success',
          content: (
            <Trans
              i18nKey="workspace:message.you_successfully_joined_team"
              values={{ team_name: teamName }}
              components={[<span key="join-team-notification" className="font-semibold" />]}
            />
          )
        })

        history.replace(getWorkspaceDraftsPath(WorkspaceType.TEAM, workspaceSlug) + '?source=join')
      } catch (err) {
        console.error(err)
        setInvitationInvalid(true)
      }
    }

    validateAndJoin()
  }, [
    addNotification,
    client,
    history,
    invitationId,
    invitationInvalid,
    joinTeam,
    teamId,
    workspaceSlug,
    profile?.workspaceList?.length,
    t
  ])

  if (invitationInvalid) {
    return (
      <Result
        title={t('invalid_invitation.invalid_email.title')}
        subTitle={<>{t('invalid_invitation.invalid_email.subtitle')}</>}
        extra={
          <Button fluid onClick={handleGoToHomepage}>
            {t('invalid_invitation.back_to_dashboard')}
          </Button>
        }
      />
    )
  }

  return null
}

export default JoinTeam
