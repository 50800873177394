export const IS_MACINTOSH = navigator.userAgent.includes('Mac')

// TODO: change if will need more platforms in the future
/** @typedef {('Mac' | 'Other')} Platform */
export const platform = IS_MACINTOSH ? 'Mac' : 'Other'

export const KEYBOARD_CODE_MAP_KEY = {
    // modifiers
    ControlLeft: 'Control',
    ControlRight: 'Control',
    MetaLeft: 'Modifier',
    MetaRight: 'Modifier',
    ShiftLeft: 'Shift',
    ShiftRight: 'Shift',
    AltLeft: 'Alt',
    AltRight: 'Alt',

    // numbers
    Digit0: '0',
    Digit1: '1',
    Digit2: '2',
    Digit3: '3',
    Digit4: '4',
    Digit5: '5',
    Digit6: '6',
    Digit7: '7',
    Digit8: '8',
    Digit9: '9',
    Numpad0: '0',
    Numpad1: '1',
    Numpad2: '2',
    Numpad3: '3',
    Numpad4: '4',
    Numpad5: '5',
    Numpad6: '6',
    Numpad7: '7',
    Numpad8: '8',
    Numpad9: '9',

    KeyA: 'A',
    KeyB: 'B',
    KeyC: 'C',
    KeyD: 'D',
    KeyE: 'E',
    KeyF: 'F',
    KeyG: 'G',
    KeyH: 'H',
    KeyI: 'I',
    KeyJ: 'J',
    KeyK: 'K',
    KeyL: 'L',
    KeyM: 'M',
    KeyN: 'N',
    KeyO: 'O',
    KeyP: 'P',
    KeyQ: 'Q',
    KeyR: 'R',
    KeyS: 'S',
    KeyT: 'T',
    KeyU: 'U',
    KeyV: 'V',
    KeyW: 'W',
    KeyX: 'X',
    KeyY: 'Y',
    KeyZ: 'Z',

    // symbols
    Equal: '=',
    NumpadAdd: '=',
    Minus: '-',
    NumpadSubtract: '-',
    Period: '.',
    NumpadDecimal: '.',
    Slash: '/',
    NumpadDivide: '/',
    Backslash: '\\',
    BracketRight: ']',
    BracketLeft: '[',
    Quote: "'",
    Semicolon: ';',
    Comma: ',',
    Backquote: '`',

    // align to key
    NumLock: 'Clear',
    Help: 'Insert',

    // keep code as key
    'Space': 'Space'
}
