import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import { LoadingStatus, WorkspaceType } from '@phase-software/types'

import { TeamUserFieldsFragment, UserFieldsFragment } from '../../generated/graphql'
import useTeamMemberActions from '../../hooks/useTeamMemberActions'
import { useSetNotification } from '../../providers/NotificationProvider'
import { useWorkspaceContext } from '../../providers/WorkspaceContextProvider'
import { track } from '../../services/heapAnalytics'
import { getWorkspaceDraftsPath } from '../../utils/pathGenerators'
import { Dialog, Text } from '../shared'

interface RemoveTeamMemberDialogProps {
  open: boolean
  onClose: () => void
  onCancel: () => void
  targetUserId: UserFieldsFragment['id']
  targetUsername?: UserFieldsFragment['username']
  teamUsers: TeamUserFieldsFragment[]
}

const RemoveTeamMemberDialog = ({
  open,
  targetUserId,
  targetUsername,
  onCancel,
  onClose,
  teamUsers
}: RemoveTeamMemberDialogProps) => {
  const { t } = useTranslation('workspace')
  const history = useHistory()
  const { removeTeamMember } = useTeamMemberActions()
  const { addNotification } = useSetNotification()
  const { workspaceData, personalWorkspaceData } = useWorkspaceContext()

  const [isRemoving, setIsRemoving] = useState(false)

  const isCurrentUser = personalWorkspaceData.id === targetUserId
  const teamName = workspaceData.name

  const handleRemoveMember = async () => {
    if (isRemoving) return
    setIsRemoving(true)
    try {
      await removeTeamMember({ teamId: workspaceData.id, userId: targetUserId })

      addNotification({
        type: 'success',
        content: isCurrentUser ? (
          <Trans
            i18nKey="workspace:message.you_have_left"
            values={{ teamName }}
            components={[<span key="project-deleted" className="font-semibold" />]}
          />
        ) : (
          <Trans
            i18nKey="workspace:message.has_been_removed_from_team"
            values={{ targetUsername }}
            components={[<span key="project-deleted" className="font-semibold" />]}
          />
        )
      })
      onClose()
      const teamUser = teamUsers.find((tu) => tu.user_id === targetUserId)
      if (teamUser?.team_role) {
        if (isCurrentUser) {
          track('User Left Team', { teamName, userRole: teamUser.team_role })
        } else {
          track('User Removed From Team', { teamName, userRole: teamUser.team_role })
        }
      }
      if (isCurrentUser) {
        history.replace(getWorkspaceDraftsPath(WorkspaceType.PERSONAL, personalWorkspaceData.slug))
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsRemoving(false)
    }
  }
  return (
    <Dialog
      data-test-id={isCurrentUser ? 'leave-team-dialog' : 'remove-team-member-dialog'}
      size="xs"
      title={isCurrentUser ? t('dialog.leave_team') : t('dialog.remove_team_member')}
      dangerConfirmBtnText={isCurrentUser ? t('leave') : t('remove')}
      cancelBtnText={isCurrentUser ? t('dialog.not_now') : t('common:button.cancel')}
      onDangerConfirm={handleRemoveMember}
      onCancel={onCancel}
      disableDangerConfirm={isRemoving}
      showProgressButton
      progressStatus={isRemoving ? LoadingStatus.WAITING : LoadingStatus.INITIAL}
      open={open}
    >
      <Text ellipsis={false} className="font-medium">
        {isCurrentUser ? (
          <Trans
            i18nKey="workspace:dialog.current_user_leave_team_confirmation"
            values={{ team_name: teamName }}
            components={[<span key="current-user-leave-team-confirmation" className="font-semibold" />]}
          >
            Are you sure to leave <span className="text-white font-semibold">{teamName}</span>? You will lose all access
            to this team. This action cannot be undone.
          </Trans>
        ) : (
          <Trans
            i18nKey="workspace:dialog.other_user_leave_team_confirmation"
            values={{ target_username: targetUsername }}
            components={[<span key="other-user-leave-team-confirmation" className="font-semibold" />]}
          >
            Are you sure you want to remove <span className="text-white font-semibold">{targetUsername}</span> from this
            team? This will revoke their access to all team resources.
          </Trans>
        )}
      </Text>
    </Dialog>
  )
}

export default RemoveTeamMemberDialog
