import { PropComponentType, TextDirectionType } from '@phase-software/types'
import { notNull } from '@phase-software/data-utils'
import PropertyComponent from './PropertyComponent'

/** @typedef {import('../DataStore').DataStore} DataStore */
/** @typedef {import('./PropertyComponent').AppliedRef} AppliedRef */

export default class TextDirectionComponent extends PropertyComponent {
    /**
     * @param {DataStore} dataStore
     * @param {TextDirectionComponentData} [data]
     * @param {object} [options] 
     * @param {bool} [options.regenId=false]   if set to true, will generate new ID
     */
    constructor(dataStore, data = {}, options) {
        super(dataStore, data, options)

        const { textDirection } = data

        this.textDirection = notNull(textDirection) ? textDirection : TextDirectionType.LTR
        this.componentType = PropComponentType.TEXT_DIRECTION

        if (!this.name) {
            this.name = 'text-direction'
        }
    }

    /** @param {Partial<TextDirectionComponentData>} data */
    set(data) {
        super.set(data)

        if (data.textDirection in TextDirectionType) {
            this.updateProp('textDirection', data.textDirection)
        }
    }

    /**
     * Override this in subclasses
     * CALL super._clone() at the top of overriden method
     * @protected
     * @param {AppliedRef} [ref]
     * @returns {TextDirectionComponentData} obj
     */
    _clone(ref) {
        const obj = super._clone(ref)
        obj.textDirection = this.textDirection
        return obj
    }

    /**
     * Override this in subclasses
     * CALL super._save() at the top of overriden method
     * @protected
     * @returns {TextDirectionComponentData} data
     */
    _save() {
        const data = super._save()
        data.textDirection = this.textDirection
        return data
    }
}

/** @typedef {import('./PropertyComponent').PropertyComponentData} PropertyComponentData */

/** @typedef {('LTR'|'RTL')} TextDirectionType */

/**
 * @typedef {PropertyComponentData} TextDirectionComponentData
 * @property {TextDirectionType} textDirection
 */
