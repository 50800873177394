import { EffectType, ElementType, GeometryType, LayerType, PaintType } from '@phase-software/types'
import {
  MergeMode,
  Animation,
  GroupLayer,
  ShapeLayer,
  ShapeType,
  ShapeDirection,
  MatteMode,
  StrokeDashProperty,
  PropertyType,
  LayerType as LottieLayerType
} from '@phase-software/lottie-js'
import { matchGradientStops } from '@phase-software/transition-manager'
import {
  rad2deg,
  addKeyFrameToProp,
  getLayerGradientType,
  getEasing,
  getGradientColors,
  cloneGradientStops,
  getShapeType,
  getLineJoinType,
  getLineCapType,
  getElementType,
  getTrimMode,
  time2frame,
  getMergeMode,
  getStrokeDashType,
  getBlendMode,
  groupPath,
  isShapeShape,
  getPathBaseMesh
} from './utils'
import SceneTree, { Layer, TransformLayer } from './SceneTree'

const defaultValue = {
  frameRate: 60,
  start: 0,
  end: 0,
  speed: 1
}

export class LottieExporter {
  constructor({ frameRate, start, end, speed } = defaultValue) {
    this.speed = speed
    this.frameRate = frameRate
    this.animation = new Animation()
    this.animation.frameRate = frameRate
    const msPerFrame = 1000 / frameRate
    this.animation.inPoint = Math.ceil((start * 1000) / msPerFrame / speed)
    this.animation.outPoint = Math.ceil((end * 1000) / msPerFrame / speed)
    this.animation.meta.generator = '@phase-software/lottie-exporter 0.2.1'
  }

  _getElementIndex(id) {
    return this.dataStore.workspace.watched.elementOrder.get(id)
  }

  _getParent(el) {
    return this.dataStore.getParentOf(el)
  }

  parse(dataStore) {
    this.dataStore = dataStore

    const screen = dataStore.workspace.watched.children[0]
    this.parseAnimationSize(screen)

    const sceneTree = new SceneTree(screen)
    this.sceneTree = sceneTree
    this.parseSceneTree(sceneTree)

    if (screen.hasLayers) {
      const shapeLayer = new ShapeLayer()
      shapeLayer.outPoint = this.animation.outPoint
      shapeLayer.name = screen.get('name')
      this.parseContainerLayers(screen, shapeLayer)
      this.animation.layers.push(shapeLayer)
    }

    const json = this.animation.toJSON()

    return json
  }

  parseSceneTree(sceneTree) {
    const sceneLayers = sceneTree.getLayers()
    sceneLayers.forEach(sceneLayer => {
      let parent
      if (sceneLayer.parent) {
        parent = this.animation.getLayerById(sceneLayer.parent.node.get('id'))
      }
      const el = sceneLayer.node
      const elementType = el && getElementType(el)
      const LayerClass = sceneLayer instanceof TransformLayer ? GroupLayer : ShapeLayer
      const layer = new LayerClass(parent)
      if (sceneLayer.matteTarget) {
        layer.matteTarget = 1
      }
      if (sceneLayer.matte) {
        layer.matteMode = MatteMode.ALPHA
      }

      layer.outPoint = this.animation.outPoint
      layer.index = sceneTree.getIndex(sceneLayer.id)
      if (el) {
        layer.id = el.get('id')
        layer.name = el.get('name')
        layer.isHidden = !el.get('visible')
        layer.blendMode = getBlendMode(el.get('blendMode'))
        if (elementType !== ElementType.PATH) {
          this.parseTransform(el, layer)
        }
      }

      if (sceneLayer instanceof Layer) {
        const group = this.applyParentOpacity(layer, sceneLayer.parent)
        this.parseSceneLayers(sceneLayer.children, group)

        if (el) {
          if (
            [ElementType.CONTAINER, ElementType.BOOLEAN_CONTAINER, ElementType.MASK_CONTAINER].includes(elementType)
          ) {
            this.parseEffectShapes(el, group)
          }
          if (el.isBooleanType()) {
            this.parseMergeShape(el, group)
            this.parseStrokeShapes(el, group)
            this.parseFillShapes(el, group)
          } else if (el.children) {
            this.parseContainerLayers(el, group)
          }
        }

        if (sceneLayer.parent && elementType !== ElementType.MASK_CONTAINER) {
          this.applyParentEffects(layer, sceneLayer.parent)
        }
      }

      this.animation.layers.push(layer)
    })
  }

  parseContainerLayers(el, parent) {
    if (!el.hasLayers) {
      return
    }
    const elementId = el.get('id')
    const elementTrackId = this.dataStore.interaction.getElementTrackIdByElementId(elementId)
    const elementTrack = this.dataStore.interaction.getElementTrack(elementTrackId)
    const rectGroup = parent.createShape(ShapeType.GROUP)
    const rect = rectGroup.createShape(ShapeType.RECTANGLE)
    rect.name = `${el.get('name')} layers`
    rect.isHidden = !el.get('visible')
    const { width, height } = this.dataStore.library.getComponent(el.base.dimensions)
    addKeyFrameToProp(rect.size, 0, [width, height])

    // TODO: size / position animation
    addKeyFrameToProp(rect.position, 0, [width / 2, height / 2])

    addKeyFrameToProp(rect.roundness, 0, el.get('cornerRadius'))
    this._parseKF(elementTrack, rect.roundness, ['cornerRadius'], kf => kf.value)

    rectGroup.shapes.push(rect)

    this.parseStrokeShapes(el, rectGroup)
    this.parseFillShapes(el, rectGroup)

    parent.shapes.push(rectGroup)
  }

  parseSceneLayers(sceneLayers, parent) {
    sceneLayers.forEach(sceneLayer => this.parseSceneLayer(sceneLayer, parent))
  }

  parseSceneLayer(sceneLayer, parent) {
    const el = sceneLayer.node
    switch (getElementType(el)) {
      case ElementType.PATH: {
        this.parseShape(el, parent)
        break
      }
      case ElementType.BOOLEAN_CONTAINER:
      case ElementType.CONTAINER: {
        const wrapper = parent.createShape(ShapeType.GROUP)
        wrapper.isHidden = !el.get('visible')
        wrapper.name = el.get('name')
        wrapper.blendMode = getBlendMode(el.get('blendMode'))
        parent.shapes.push(wrapper)
        this.parseTransform(el, wrapper)
        this.parseSceneLayers(sceneLayer.children, wrapper)
        if (el) {
          this.parseEffectShapes(el, wrapper)
          if (el.isBooleanType()) {
            this.parseMergeShape(el, wrapper)
            this.parseStrokeShapes(el, wrapper)
            this.parseFillShapes(el, wrapper)
          } else if (el.children) {
            this.parseContainerLayers(el, wrapper)
          }
        }
        break
      }
    }
  }

  applyParentEffects(shapeLayer, parent) {
    let currentNode = parent.node
    while (currentNode) {
      this.parseEffectShapes(currentNode, shapeLayer)
      currentNode = this.dataStore.getParentOf(currentNode)
    }
  }

  applyParentOpacity(shapeLayer, parent) {
    // apply parent opacity
    let group = shapeLayer
    let currentNode = parent?.node
    while (currentNode) {
      const elementTrackId = this.dataStore.interaction.getElementTrackIdByElementId(currentNode.get('id'))
      const elementTrack = this.dataStore.interaction.getElementTrack(elementTrackId)

      const opacity = currentNode.get('opacity')
      if (opacity !== 1 || elementTrack?.propertyTrackMap.has('opacity')) {
        const subGroup = group.createShape(ShapeType.GROUP)
        subGroup.name = `${currentNode.get('name')} opacity`
        addKeyFrameToProp(subGroup.transform.opacity, 0, Math.round(opacity * 100))
        this._parseKF(
          elementTrack,
          subGroup.transform.opacity,
          ['opacity'],
          kf => Math.round(kf.value * 100),
          'opacity'
        )

        group.addShape(subGroup)
        group = subGroup
      }
      currentNode = this.dataStore.getParentOf(currentNode)
    }
    return group
  }

  parseAnimationSize(screen) {
    const { width, height } = screen.gets('width', 'height')
    this.animation.width = width
    this.animation.height = height
  }

  _parseKF(elementTrack, animatedProp, keyPair, fn, groupKey) {
    if (!elementTrack) {
      return
    }

    const values =
      animatedProp.split && animatedProp.type === PropertyType.POSITION ? animatedProp[groupKey] : animatedProp.values
    let keySet
    let aliasMap
    if (Array.isArray(keyPair)) {
      keySet = new Set(keyPair)
      aliasMap = new Map(keySet.entries())
    } else {
      keySet = new Set(Object.keys(keyPair))
      aliasMap = new Map(Object.entries(keyPair))
    }

    const kfGroup = this.dataStore.interaction.getElementTrackKeyFrameGroupByTime(elementTrack.id, keySet)
    if (!kfGroup) {
      return
    }

    const easings = {}
    keySet.forEach(key => {
      easings[key] = { last: null, current: null }
    })
    const inOutTangent = []
    const prevData = {}
    const delta = {}
    Object.entries(kfGroup).forEach(([t, kfIdList]) => {
      const time = parseInt(t)
      const frame = time2frame(time / this.speed, this.frameRate)
      const data = {}
      // Get existing kf value and easing
      kfIdList.forEach(kfId => {
        const kf = this.dataStore.interaction.getKeyFrame(kfId)
        const track = this.dataStore.interaction.getPropertyTrack(kf.trackId)
        data[track.key] = fn(kf, track.key)
        if (easings[track.key].current !== null) {
          easings[track.key].last = easings[track.key].current
        }
        easings[track.key].current = {
          time,
          bezier: kf.bezier,
          easingType: kf.easingType
        }
      })
      // Get non-existing kf value at the same time
      keySet.forEach(key => {
        const aliasKey = aliasMap.get(key)
        if (data[key] === undefined) {
          data[key] = this.dataStore.transition.getPropertyValueByTime(elementTrack.elementId, aliasKey, time)[aliasKey]
        }

        delta[key] = prevData[key] ? data[key] - prevData[key] : 0
        prevData[key] = data[key]
      })

      const { easing, hold } = getEasing(keySet, easings, delta)
      inOutTangent.push({
        outTangent: [easing[0], easing[1]],
        inTangent: [easing[2], easing[3]]
      })
      if (groupKey) {
        addKeyFrameToProp(animatedProp, frame, data[groupKey], groupKey)
      } else {
        addKeyFrameToProp(
          animatedProp,
          frame,
          Array.from(keySet.keys()).map(key => data[key])
        )
      }
      // If is STEP_END easing type, set previous kf hold to true to hold the frame.
      if (hold && values.length > 1) {
        values[values.length - 2].hold = hold
      }
    })

    // Apply easing
    values.forEach((kf, idx) => {
      kf.frameInTangent = idx === values.length - 1 ? [1, 1] : inOutTangent[idx].inTangent
      kf.frameOutTangent = idx === values.length - 1 ? [0, 0] : inOutTangent[idx].outTangent
    })
  }

  parseTransform(el, layer, isFromComplicatedPath = false) {
    const elementId = el.get('id')
    const elementTrackId = this.dataStore.interaction.getElementTrackIdByElementId(elementId)
    const elementTrack = this.dataStore.interaction.getElementTrack(elementTrackId)

    // contentAnchor
    const contentAnchor = this.dataStore.library.getComponent(el.base.contentAnchor)
    let referencePointVec = [0, 0]
    if (!isFromComplicatedPath) {
      const referencePoint = this.dataStore.library.getComponent(el.base.referencePoint)
      referencePointVec = [referencePoint.referencePointX, referencePoint.referencePointY]
    }
    addKeyFrameToProp(layer.transform.anchor, 0, [
      contentAnchor.contentAnchorX + referencePointVec[0],
      contentAnchor.contentAnchorY + referencePointVec[1]
    ])
    this._parseKF(
      elementTrack,
      layer.transform.anchor,
      ['contentAnchor'],
      kf => [kf.value.contentAnchorX + referencePointVec[0], kf.value.contentAnchorY + referencePointVec[1]],
      'contentAnchor'
    )

    // translate (delta value)
    const translate = this.dataStore.library.getComponent(el.base.translate)
    const mapP = {
      x: 'translateX',
      y: 'translateY'
    }
    if (layer.type === LottieLayerType.SHAPE) {
      const position = layer.transform.position

      position.split = true
      // init postiion.x and position.y
      addKeyFrameToProp(position, 0, [translate.translateX], 'x')
      addKeyFrameToProp(position, 0, [translate.translateY], 'y')
      // add keyframes data into position.x and position.y
      this._parseKF(elementTrack, position, ['x'], kf => translate[mapP.x] + kf.value, 'x')
      this._parseKF(elementTrack, position, ['y'], kf => translate[mapP.y] + kf.value, 'y')
    } else {
      addKeyFrameToProp(layer.transform.position, 0, [translate.translateX, translate.translateY])
      this._parseKF(elementTrack, layer.transform.position, mapP, (kf, key) => translate[mapP[key]] + kf.value)
    }

    // opacity
    const opacity = this.dataStore.library.getComponent(el.base.opacity)
    addKeyFrameToProp(layer.transform.opacity, 0, Math.round(opacity.opacity * 100))
    this._parseKF(elementTrack, layer.transform.opacity, ['opacity'], kf => Math.round(kf.value * 100), 'opacity')

    // rotation
    const rotation = this.dataStore.library.getComponent(el.base.rotation)
    addKeyFrameToProp(layer.transform.rotation, 0, Math.round(rad2deg(rotation.rotation)))
    this._parseKF(elementTrack, layer.transform.rotation, ['rotation'], kf => rad2deg(kf.value), 'rotation')

    // scale
    const scale = this.dataStore.library.getComponent(el.base.scale)
    addKeyFrameToProp(layer.transform.scale, 0, [Math.round(scale.scaleX * 100), Math.round(scale.scaleY * 100)])
    this._parseKF(elementTrack, layer.transform.scale, ['scaleX', 'scaleY'], kf => Math.round(kf.value * 100))

    // skew
    const skew = this.dataStore.library.getComponent(el.base.skew)
    if (skew.skewX || elementTrack?.propertyTrackMap.has('skewX')) {
      addKeyFrameToProp(layer.transform.skew, 0, -rad2deg(skew.skewX))
      this._parseKF(elementTrack, layer.transform.skew, ['skewX'], kf => -rad2deg(kf.value), 'skewX')
    } else if (skew.skewY || elementTrack?.propertyTrackMap.has('skewY')) {
      addKeyFrameToProp(layer.transform.skewAxis, 0, 90)

      addKeyFrameToProp(layer.transform.skew, 0, rad2deg(skew.skewY))
      this._parseKF(elementTrack, layer.transform.skew, ['skewY'], kf => rad2deg(kf.value), 'skewY')
    }
  }

  parseShape(el, parent) {
    const { elementType } = el.gets('elementType')
    switch (elementType) {
      // TODO: method
      case ElementType.PATH: {
        const geometryType = el.get('geometryType')

        switch (geometryType) {
          case GeometryType.ELLIPSE:
          case GeometryType.RECTANGLE: {
            this._parsePathElement(geometryType, el, parent)
            break
          }
          case GeometryType.LINE:
          case GeometryType.POLYGON: {
            this._parseComplexyPathElement(el, parent)
            break
          }
          default:
            console.log('Unknown geometryType', geometryType)
        }
        break
      }
      default:
        console.log('Unknown element type (shape)', elementType)
    }
  }

  _parsePathElement(geometryType, el, parent) {
    const shapeType = getShapeType(geometryType)
    const group = parent.createShape(ShapeType.GROUP)
    group.name = 'Group'
    group.blendMode = getBlendMode(el.get('blendMode'))
    parent.shapes.push(group)
    this.parseTransform(el, group)

    const shape = group.createShape(shapeType)
    shape.name = el.get('name')
    shape.isHidden = !el.get('visible')
    shape.direction = ShapeDirection.NORMAL

    const { width, height } = this.dataStore.library.getComponent(el.base.dimensions)
    addKeyFrameToProp(shape.size, 0, [width, height])
    addKeyFrameToProp(shape.position, 0, [width / 2, height / 2])

    const elementId = el.get('id')
    const elementTrackId = this.dataStore.interaction.getElementTrackIdByElementId(elementId)
    const elementTrack = this.dataStore.interaction.getElementTrack(elementTrackId)

    this._parseKF(elementTrack, shape.size, ['width', 'height'], kf => kf.value)

    if (shapeType === ShapeType.RECTANGLE) {
      addKeyFrameToProp(shape.roundness, 0, el.get('cornerRadius'))
      this._parseKF(elementTrack, shape.roundness, ['cornerRadius'], kf => kf.value)
    }
    group.shapes.push(shape)
    this.parseStrokeShapes(el, group)
    this.parseFillShapes(el, group)
    this.parseEffectShapes(el, group)
  }

  _parseComplexyPathElement(el, parent) {
    const mesh = getPathBaseMesh(el)

    const basePosition = [0, 0]
    const path = this.dataStore.drawInfo.getMeshOutlinesForExport(mesh, basePosition)
    const verticesData = groupPath(path, this.dataStore.drawInfo)

    // Create group for transform
    const group = parent.createShape(ShapeType.GROUP)
    group.name = 'Group'
    group.blendMode = getBlendMode(el.get('blendMode'))
    parent.shapes.push(group)
    this.parseTransform(el, group, true)
    // Prepare the element info
    const elementId = el.get('id')
    const elementTrackId = this.dataStore.interaction.getElementTrackIdByElementId(elementId)
    const elementTrack = this.dataStore.interaction.getElementTrack(elementTrackId)
    // Set the merge path mode as normal
    const mergePath = group.createShape(ShapeType.MERGE)
    mergePath.mergeMode = MergeMode.NORMAL
    for (let i = 0; i < verticesData.length; i++) {
      this._parseSingleBezierPath(el, elementId, elementTrack, group, verticesData[i], i, basePosition)
    }
    group.shapes.push(mergePath)
    // Add roundness keyfram
    const roundedCornerShape = group.createShape(ShapeType.ROUNDED_CORNERS)
    addKeyFrameToProp(roundedCornerShape.roundness, 0, el.get('cornerRadius'))
    this._parseKF(elementTrack, roundedCornerShape.roundness, ['cornerRadius'], kf => kf.value)
    group.shapes.push(roundedCornerShape)
    // Add layer keyframe
    this.parseStrokeShapes(el, group)
    this.parseFillShapes(el, group)
    this.parseEffectShapes(el, group)
  }

  _parseSingleBezierPath(el, elementId, elementTrack, group, vertices, groupIdx, basePosition) {
    const shape = group.createShape(ShapeType.PATH)
    shape.name = `${el.get('name')}(${groupIdx})`
    shape.isHidden = !el.get('visible')
    shape.direction = ShapeDirection.NORMAL

    addKeyFrameToProp(shape.vertices, 0, vertices)

    this._parsePathMorphing(el, elementTrack, shape, elementId, groupIdx, basePosition)

    group.shapes.push(shape)
  }

  _parsePathMorphing(el, elementTrack, shape, elementId, groupIdx, basePosition) {
    const currentAnimationTime = this.dataStore.transition.currentTime
    this._parseKF(elementTrack, shape.vertices, ['pathMorphing'], kf => {
      const pathMorphingAnimationData = this.dataStore.transition.getPropertyValueByTime(
        elementId,
        'pathMorphing',
        kf.time
      )
      el.updateVerticesPosition(pathMorphingAnimationData)
      const newMesh = el.get('geometry').get('mesh')
      const path = this.dataStore.drawInfo.getMeshOutlinesForExport(newMesh, basePosition)
      const verticesData = groupPath(path, this.dataStore.drawInfo)
      return verticesData[groupIdx]
    })
    this._resumePath(el, shape.vertices, currentAnimationTime, basePosition)
  }

  _resumePath(el, animatedProp, time) {
    if (animatedProp.isAnimated && animatedProp.values.length) {
      if (this.dataStore.isDesignMode) {
        // Reset path with the base path data
        const basePathData = []
        for (const [, v] of el.basePath) {
          basePathData.push({
            id: v.id,
            mirror: v.mirror,
            x: v.pos[0],
            y: v.pos[1]
          })
        }
        el.updateVerticesPosition(basePathData)
      } else {
        // Reset path with current animation time
        const pathMorphingAnimationData = this.dataStore.transition.getPropertyValueByTime(
          el.get('id'),
          'pathMorphing',
          time
        )
        el.updateVerticesPosition(pathMorphingAnimationData)
      }
    }
  }

  _getLayerComponent(el, layerType) {
    const { dataStore } = el
    const typeMap = {
      [LayerType.FILL]: 'fills',
      [LayerType.STROKE]: 'strokes'
    }

    return dataStore.library.getComponent(el.base[typeMap[layerType]][0])
  }

  _parseLayerShape(elementId, elementTrack, layer, parent) {
    switch (layer.paint.paintType) {
      case PaintType.SOLID: {
        const typeMap = {
          [LayerType.FILL]: ShapeType.FILL,
          [LayerType.STROKE]: ShapeType.STROKE
        }
        const shape = parent.createShape(typeMap[layer.layerType])
        this._parseSolidLayer(elementTrack, layer, shape)
        return shape
      }
      case PaintType.GRADIENT_ANGULAR:
      case PaintType.GRADIENT_DIAMOND:
      case PaintType.GRADIENT_LINEAR:
      case PaintType.GRADIENT_RADIAL: {
        const typeMap = {
          [LayerType.FILL]: ShapeType.GRADIENT_FILL,
          [LayerType.STROKE]: ShapeType.GRADIENT_STROKE
        }
        const shape = parent.createShape(typeMap[layer.layerType])
        this._parseGradientLayer(elementId, elementTrack, layer, shape)
        return shape
      }
    }
  }

  _parseSolidLayer(elementTrack, layer, shape) {
    const [r, g, b] = layer.paint.color.slice()
    addKeyFrameToProp(shape.color, 0, [r, g, b])
    addKeyFrameToProp(shape.opacity, 0, Math.round(layer.paint.opacity * 100))
    this._parseKF(
      elementTrack,
      shape.color,
      [`${layer.id}.paint`],
      kf => {
        const paint = this.dataStore.library.getComponent(kf.ref)
        return [...paint.color].slice(0, 3)
      },
      `${layer.id}.paint`
    )
    this._parseKF(elementTrack, shape.opacity, [`${layer.id}.opacity`], kf => kf.value * 100, `${layer.id}.opacity`)
  }

  _parseGradientLayer(elementId, elementTrack, layer, shape) {
    console.log({ type: layer.type })
    shape.gradientType = getLayerGradientType(layer.type, layer.paint.paintType)
    shape.gradientColors.colorCount = layer.paint.gradientStops.length

    const gradientTransform = this._getGradientTransform(elementId, layer.paint, 0)
    addKeyFrameToProp(shape.startPoint, 0, gradientTransform.start)
    addKeyFrameToProp(shape.endPoint, 0, gradientTransform.end)

    // FIXME: implement color stop interpolation
    let maxGradientStops = layer.paint.gradientStops
    let maxStopCount = maxGradientStops.length
    if (elementTrack) {
      const pt = elementTrack.propertyTrackMap.get(`${layer.id}.paint`)
      const paintsKfs = this.dataStore.interaction.getKeyFrameList(pt)
      if (paintsKfs) {
        const paints = paintsKfs.map(kfId => {
          const kf = this.dataStore.interaction.getKeyFrame(kfId)
          const paint = this.dataStore.library.getComponent(kf.ref)
          return paint
        })
        paints.forEach(paint => {
          if (paint.gradientStops.length > maxStopCount) {
            maxGradientStops = paint.gradientStops
            maxStopCount = maxGradientStops.length
          }
        })
      }
    }
    // Clone max gradient stops
    if (maxGradientStops) {
      maxGradientStops = cloneGradientStops(maxGradientStops)
      shape.gradientColors.colorCount = maxStopCount
      const stops = cloneGradientStops(layer.paint.gradientStops)
      if (layer.paint.gradientStops.length < maxStopCount) {
        matchGradientStops(maxGradientStops, stops)
      }
      addKeyFrameToProp(shape.gradientColors.gradientColors, 0, getGradientColors(stops))
    } else {
      addKeyFrameToProp(shape.gradientColors.gradientColors, 0, getGradientColors(layer.paint.gradientStops))
    }

    this._parseKF(
      elementTrack,
      shape.startPoint,
      [`${layer.id}.paint`],
      kf => {
        const paint = this.dataStore.library.getComponent(kf.ref)
        const animateGradientTransform = this._getGradientTransform(elementId, paint, kf.time)
        return animateGradientTransform.start
      },
      `${layer.id}.paint`
    )
    this._parseKF(
      elementTrack,
      shape.endPoint,
      [`${layer.id}.paint`],
      kf => {
        const paint = this.dataStore.library.getComponent(kf.ref)
        const animateGradientTransform = this._getGradientTransform(elementId, paint, kf.time)
        return animateGradientTransform.end
      },
      `${layer.id}.paint`
    )
    this._parseKF(
      elementTrack,
      shape.gradientColors.gradientColors,
      [`${layer.id}.paint`],
      kf => {
        const paint = this.dataStore.library.getComponent(kf.ref)
        const stops = cloneGradientStops(paint.gradientStops)
        if (paint.gradientStops.length < maxStopCount) {
          matchGradientStops(maxGradientStops, stops)
        }
        return getGradientColors(stops)
      },
      `${layer.id}.paint`
    )

    addKeyFrameToProp(shape.opacity, 0, Math.round(layer.paint.opacity * 100))
    this._parseKF(elementTrack, shape.opacity, [`${layer.id}.opacity`], kf => kf.value * 100, `${layer.id}.opacity`)
  }

  _getGradientTransform(elementId, paint, time) {
    const widthData = this.dataStore.transition.getPropertyValueByTime(elementId, 'width', time)
    const heightData = this.dataStore.transition.getPropertyValueByTime(elementId, 'height', time)
    const handles = this.dataStore.drawInfo.getGradientHandlesPosition(
      widthData.width,
      heightData.height,
      paint.paintType === PaintType.GRADIENT_LINEAR,
      paint.gradientTransform
    )
    if (this.dataStore.getById(elementId).canMorph) {
      return {
        start: [handles.center.x, handles.center.y],
        end: [handles.bottom.x, handles.bottom.y]
      }
    } else {
      const nonPathOffset = [0.5 * widthData.width, 0.5 * heightData.height]
      return {
        start: [handles.center.x - nonPathOffset[0], handles.center.y - nonPathOffset[1]],
        end: [handles.bottom.x - nonPathOffset[0], handles.bottom.y - nonPathOffset[1]]
      }
    }
  }

  // TODO: reuse most of code
  parseStrokeShapes(el, parent) {
    const { dataStore } = el
    const elementId = el.get('id')
    const elementTrackId = dataStore.interaction.getElementTrackIdByElementId(elementId)
    const elementTrack = dataStore.interaction.getElementTrack(elementTrackId)
    const layerComponent = this._getLayerComponent(el, LayerType.STROKE)
    layerComponent.layers
      .slice()
      .reverse()
      .map(layerId => {
        const layer = dataStore.library.getLayer(layerId)

        const shape = this._parseLayerShape(elementId, elementTrack, layer, parent)
        shape.lineJoinType = getLineJoinType(layer.join)
        shape.lineCapType = getLineCapType(layer.ends)
        shape.miterLimit = layer.miter
        const strokeDash = getStrokeDashType('dash')
        layer.dash.forEach((dash, index) => {
          const sdp = new StrokeDashProperty(strokeDash)
          sdp.name = `${layer.id}_dash_${index}`
          addKeyFrameToProp(sdp.value, 0, dash)
          this._parseKF(elementTrack, sdp.value, [`${layer.id}.dash`], kf => kf.value, `${layer.id}.dash`)
          shape.addDashes(sdp)
        })

        const strokeGap = getStrokeDashType('gap')
        layer.gap.forEach((gap, index) => {
          const sdp = new StrokeDashProperty(strokeGap)
          sdp.name = `${layer.id}_gap_${index}`
          addKeyFrameToProp(sdp.value, 0, gap)
          shape.addDashes(sdp)
          this._parseKF(elementTrack, sdp.value, [`${layer.id}.gap`], kf => kf.value, `${layer.id}.gap`)
        })

        parent.shapes.push(shape)

        addKeyFrameToProp(shape.width, 0, layer.width)
        this._parseKF(elementTrack, shape.width, [`${layer.id}.width`], kf => kf.value, `${layer.id}.width`)
      })
  }

  // TODO: reuse most of code
  parseFillShapes(el, parent) {
    const { dataStore } = el
    const elementId = el.get('id')
    const elementTrackId = dataStore.interaction.getElementTrackIdByElementId(elementId)
    const elementTrack = dataStore.interaction.getElementTrack(elementTrackId)
    const layerComponent = this._getLayerComponent(el, LayerType.FILL)
    layerComponent.layers
      .slice()
      .reverse()
      .map(layerId => {
        const layer = dataStore.library.getLayer(layerId)

        const shape = this._parseLayerShape(elementId, elementTrack, layer, parent)
        parent.shapes.push(shape)
      })
  }

  parseTrimShape(effect, elementTrack, parentShape) {
    const trimShape = parentShape.createShape(ShapeType.TRIM)

    addKeyFrameToProp(trimShape.trimStart, 0, effect.start)
    this._parseKF(elementTrack, trimShape.trimStart, ['trimPath.start'], kf => kf.value, 'trimPath.start')

    addKeyFrameToProp(trimShape.trimEnd, 0, effect.end)
    this._parseKF(elementTrack, trimShape.trimEnd, ['trimPath.end'], kf => kf.value, 'trimPath.end')

    addKeyFrameToProp(trimShape.trimOffset, 0, effect.offset * 3.6)
    this._parseKF(elementTrack, trimShape.trimOffset, ['trimPath.offset'], kf => kf.value * 3.6, 'trimPath.offset')

    trimShape.trimMultipleShapes = getTrimMode(effect.mode)

    parentShape.shapes.push(trimShape)
  }

  parseEffectShapes(el, parent) {
    if (!el.hasEffect) {
      return
    }

    const { dataStore, base } = el
    const elementId = el.get('id')
    const effectComponent = dataStore.library.getComponent(base.effects[0])

    const elementTrackId = dataStore.interaction.getElementTrackIdByElementId(elementId)
    const elementTrack = dataStore.interaction.getElementTrack(elementTrackId)

    effectComponent.effects.forEach(effectId => {
      const effect = dataStore.library.getComponent(effectId)
      switch (effect.effectType) {
        case EffectType.TRIM_PATH: {
          this.parseTrimShape(effect, elementTrack, parent)
          break
        }
      }
    })
  }

  parseMergeShape(el, parent) {
    const mergeMode = getMergeMode(el.get('booleanType'))
    const mergeShape = parent.createShape(ShapeType.MERGE)
    // moving latest child to top if subtract
    if (mergeMode === MergeMode.SUBTRACT) {
      const lastShapeIndex = parent.shapes.findLastIndex(isShapeShape)
      if (lastShapeIndex !== -1) {
        const shape = parent.shapes[lastShapeIndex]
        parent.shapes.splice(lastShapeIndex, 1)
        parent.shapes.unshift(shape)
      }
    }

    mergeShape.mergeMode = mergeMode
    parent.shapes.push(mergeShape)
  }
}
