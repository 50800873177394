export const Num = {
    /**
     * @param {number} a
     * @param {number} b
     * @param {number} [esp=0.0001]
     * @returns {boolean}
     */
    Equal(a, b, esp = 0.0001) {
        return Math.abs(a - b) < esp
    },

    /**
     * @param {number} v
     * @param {number} min
     * @param {number} max
     * @returns {number}
     */
    clamp(v, min, max) {
        if (v > max) return max
        if (v < min) return min
        return v
    },

    /**
     * @param {number} a
     * @param {number} b
     * @param {number} t
     * @returns {number}
     */
    lerp(a, b, t) {
        return a * (1 - t) + b * t
    },

    /**
     * @param {number} a
     * @param {number} b
     * @param {number} t
     * @returns {number}
     */
    lerp_angle(a, b, t) {
        const diff = (b - a) % (Math.PI * 2)
        const dist = (diff * 2) % (Math.PI * 2) - diff
        return a + dist * t
    },

    /**
     * @param {number} value
     * @returns {boolean}
     */
    IsPowerOf2(value) {
        return (value & (value - 1)) === 0
    },

    /**
     * @param {number} value
     * @returns {number}
     */
    NearestPowerOf2(value) {
        if (value === 0) return 0
        let out = value
        out--
        out |= out >>> 1
        out |= out >>> 2
        out |= out >>> 4
        out |= out >>> 8
        out |= out >>> 16
        return out + 1
    },

    /**
     * @param {number} value
     * @param {number} istart
     * @param {number} istop
     * @param {number} ostart
     * @param {number} ostop
     * @returns {number}
     */
    map(value, istart, istop, ostart, ostop) {
        return ostart + (ostop - ostart) * ((value - istart) / (istop - istart))
    },
}
