import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

// @ts-ignore TODO: create type definition file(.d.ts) for the types
import { MAX_INTERACTION_TIME } from '@phase-software/data-store'

import { useInteraction, useInteractionActions } from '../../../providers/dataStore/InteractionProvider'
import { formatSecondsWithDecimals } from '../../../utils/formatter'
import { parseToDecimals } from '../../../utils/number'
import { secondFormatValidator } from '../../../utils/validator'
import { Input } from '../../shared'

type DurationProps = {
  actionId: string
}

const Duration = ({ actionId }: DurationProps) => {
  const { setActionMaxTime } = useInteractionActions()
  const { maxTime } = useInteraction(actionId)
  const { t } = useTranslation('file')

  const duration = useMemo(() => {
    return { value: parseToDecimals(maxTime / 1000, 2), MAX_DURATION: MAX_INTERACTION_TIME / 1000 }
  }, [maxTime])

  const handleDurationChange = useCallback(
    (time: number) => {
      setActionMaxTime(actionId, time * 1000)
    },
    [actionId, setActionMaxTime]
  )

  return (
    <Input
      data-test-id="playback-maxtime"
      // @ts-ignore TODO: fix after refactor of Input
      noIS
      type="number"
      variant="flat"
      value={duration.value}
      min={0.1}
      max={duration.MAX_DURATION}
      formatter={formatSecondsWithDecimals}
      validator={secondFormatValidator}
      onChange={handleDurationChange}
      tooltip={t('duration')}
      spinner
    />
  )
}

export default Duration
