import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import { LoadingStatus } from '@phase-software/types'

import { Dialog } from '../../components/shared'
import { TeamFieldsFragment, useGetTeamBySlugQuery } from '../../generated/graphql'
import useTeamActions from '../../hooks/useTeamActions'
import { useWorkspaceContext } from '../../providers/WorkspaceContextProvider'

type TeamSettingsContextData = {
  id: TeamFieldsFragment['id']
  avatar: TeamFieldsFragment['icon']
  name: string
  setName: (name: string) => void
  description: string
  setDescription: (description: string) => void
  location: string
  setLocation: (location: string) => void
  updateTeamProfile: ({
    teamId,
    name,
    description,
    location
  }: {
    teamId: TeamFieldsFragment['id']
    name: string
    description: string
    location: string
  }) => void
  updateAvatar: (teamId: TeamFieldsFragment['id'], file: File) => Promise<void>
  removeAvatar: (teamId: TeamFieldsFragment['id']) => void
  refetchTeamData: () => void
}

const TeamSettingsContext = createContext<TeamSettingsContextData | undefined>(undefined)

const TeamSettingsContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation('workspace')
  const history = useHistory()

  const { workspaceData } = useWorkspaceContext()
  const { updateTeamProfile, updateAvatar, removeAvatar } = useTeamActions()
  const { data: teamData, refetch: refetchTeamData } = useGetTeamBySlugQuery({
    variables: { slug: workspaceData.slug },
    // NOTE: prevent error since we use personal workspace as initial value in the WorkspaceContextProvider
    skip: workspaceData.isPersonal
  })

  const team = teamData?.teams[0]
  const originalName = team?.name ?? ''
  const originalDescription = team?.description ?? ''
  const originalLocation = team?.location ?? ''

  const unblockRef = useRef<any>(null)

  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [location, setLocation] = useState('')

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const [isSavingChange, setIsSavingChange] = useState(false)
  const [nextPath, setNextPath] = useState('')

  const nameChanged = name !== originalName && name.trim().length > 0
  const descriptionChanged = description !== originalDescription && description.trim().length > 0
  const locationChanged = location !== originalLocation

  useEffect(() => {
    setName(team?.name ?? '')
    setDescription(team?.description ?? '')
    setLocation(team?.location ?? '')
  }, [team])

  const handleNavigation = useCallback(
    (path: string) => {
      if (nameChanged || descriptionChanged || locationChanged) {
        setShowConfirmationDialog(true)
        setNextPath(path)
        return false // Prevent the navigation
      }
    },
    [descriptionChanged, locationChanged, nameChanged]
  )

  useEffect(() => {
    unblockRef.current = history.block((location) => handleNavigation(location.pathname))

    return () => {
      unblockRef.current?.()
    }
  }, [handleNavigation, history, nameChanged, descriptionChanged, locationChanged])

  const handleUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      if (nameChanged || descriptionChanged || locationChanged) {
        event.preventDefault()
        event.returnValue = ''
      }
    },
    [descriptionChanged, locationChanged, nameChanged]
  )
  useEffect(() => {
    window.addEventListener('beforeunload', handleUnload)

    return () => {
      window.removeEventListener('beforeunload', handleUnload)
    }
  }, [handleUnload, nameChanged, descriptionChanged, locationChanged])

  const confirmNavigation = async () => {
    if (isSavingChange) return
    try {
      setIsSavingChange(true)
      if ((name && nameChanged) || (description && descriptionChanged) || (location && locationChanged)) {
        await updateTeamProfile({ teamId: workspaceData.id, name, description, location })
      }
      setShowConfirmationDialog(false)
      unblockRef.current?.()
      history.push(nextPath)
    } catch (error) {
      console.error(error)
    } finally {
      setIsSavingChange(false)
    }
  }

  const cancelNavigation = () => {
    setShowConfirmationDialog(false)
    if (nameChanged) setName(originalName)
    if (locationChanged) setLocation(originalLocation)
    if (descriptionChanged) setDescription(originalDescription)
    unblockRef.current?.()
    history.push(nextPath)
  }

  return (
    <TeamSettingsContext.Provider
      value={{
        id: team?.id,
        avatar: team?.icon,
        name,
        setName,
        description,
        setDescription,
        location,
        setLocation,
        updateTeamProfile,
        updateAvatar,
        removeAvatar,
        refetchTeamData
      }}
    >
      {children}

      <Dialog
        data-test-id="delete-confirm-dialog"
        size="xs"
        title={t('dialog.unsaved_changes')}
        confirmBtnText={t('dialog.save_changes')}
        cancelBtnText={t('dialog.dont_save')}
        onConfirm={confirmNavigation}
        onCancel={cancelNavigation}
        showProgressButton={isSavingChange}
        progressStatus={isSavingChange ? LoadingStatus.WAITING : LoadingStatus.INITIAL}
        open={showConfirmationDialog}
      >
        {t('dialog.unsaved_changes_message')}
      </Dialog>
    </TeamSettingsContext.Provider>
  )
}

function useTeamSettingsContext(): TeamSettingsContextData {
  const context = useContext(TeamSettingsContext)
  if (context === undefined) {
    throw new Error('useTeamSettingsContext must be used within a TeamSettingsContextProvider')
  }
  return context
}

export { useTeamSettingsContext, TeamSettingsContextProvider }
