import React from 'react'

const Shortcut = ({ shortcut }: { shortcut: string }) => (
  <div className="ml-8 grid grid-flow-col auto-cols-[12px] text-center text-light-overlay-60">
    {Array.from(shortcut).map((char) => (
      <div key={char}>{char}</div>
    ))}
  </div>
)

export default Shortcut
