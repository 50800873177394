import { IRNode } from './IRNode'

export class IRPath extends IRNode {
  static props = [
    ...super.props,
    { key: 'width', animatable: true, defaultValue: 0 },
    { key: 'height', animatable: true, defaultValue: 0 },
    { key: 'cornerRadius', animatable: true, defaultValue: 0 },
    'vertices'
  ]
}
