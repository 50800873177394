import EventEmitter from 'eventemitter3'
import { ElementType, GeometryType } from '@phase-software/types'
import { setAdd, isNull, NO_COMMIT } from '@phase-software/data-utils'
import { Element } from './Element'
import { Watcher } from './Watcher'
import { Geometry } from './geometry/Geometry'

/** @typedef {import('@phase-software/data-utils').AABB} AABB */

/** @typedef {import('./DataStore').DataStore} DataStore */


const UNDO_EVENTS = ['MESH_CHANGES']
const UNDO_CHANGES = ['geometryType']

const ERROR_UPDATE_VERTICES_POSITION = Object.freeze({
    "SUCCESS": 0,
    "NOT_VALID_INSTANCE": 1,
    "NO_CHANGES": 2,
})

export class Path extends Element {
    constructor(dataStore, data, overrides) {
        super(dataStore, data, overrides)
        setAdd(this.undoEvents, UNDO_EVENTS)
        setAdd(this.undoChanges, UNDO_CHANGES)
        this.liftups.push('geometry')
        this._setupLiftupParents()


        this.on('geometryType', (value, oldValue) => {
            this.dataStore.nameCounter.changeGeometryType(this, oldValue, value)
        })

        // FIXME: basePath should be removed from Path
        this._basePath = new Map()
        this._deletedVertices = new Map()

        // If is create a new Path element, it won't have geometry.
        if (data) {
            this.cachePathBaseValue(true)
        }
        this._dirty = false
    }

    markAsDirty() {
        this._dirty = true
    }

    setup(overrides) {
        super.setup(overrides)
        this.dataStore.nameCounter.load(this)
    }

    /**
     * @param {PathData} data
     */
    load(data) {
        if (!data.geometry && isNull(data.geometryType)) {
            throw new Error('Path needs a geometry data or geometry type')
        }
        super.load({ ...data, elementType: ElementType.PATH })

        // do load
        if (data.geometry) {
            this.data.mask = data.mask
            this.data.booleanOperation = data.booleanOperation

            this.data.geometry = this._createGeometry(data.geometry)
        }
        // do create
        else {
            this.data.mask = false
            this.data.booleanOperation = 'NONE'

            this.data.geometry = this._createGeometry({ geometryType: data.geometryType })
        }

        this.data.initGeometryType = this.data.geometry.get('geometryType')

        this._setupLiftupParents()
    }

    /**
     * @param {object} [overrides] data object with overrides
     * @returns {Path}
     */
    clone(overrides) {
        const obj = super.clone(overrides)
        obj._basePath = new Map()
        obj.data.mask = this.data.mask
        obj.data.booleanOperation = this.data.booleanOperation
        obj.data.geometry = this.data.geometry.clone()

        // FIXME: basePath should be removed from Path
        obj.cachePathBaseValue(true)
        obj._setupLiftupParents()
        return obj
    }

    /**
     * @returns {PathData}
     */
    save() {
        const data = super.save()
        if (this._dirty) {
            this.cachePathBaseValue()
        }
        data.mask = this.data.mask
        data.booleanOperation = this.data.booleanOperation
        data.geometry = this.data.geometry.watched.save()
        return data
    }

    get canMorph() {
        if (!this.exists('geometryType')){
            if (this.exists('initGeometryType')){
                return this.data.initGeometryType
            }
            return false
        }

        const gepmetryType = this.get('geometryType')
        return this.get('geometry') && (gepmetryType === GeometryType.POLYGON || gepmetryType === GeometryType.LINE)
    }

    get basePath() {
        return this._basePath && this._basePath.size === 0 ? null : this._basePath
    }

    deleteBaseVertex(id) {
        this._deletedVertices.set(id, this._basePath.get(id))
        this._basePath.delete(id)
    }

    addBaseVertex(id, value) {
        const vertex = this._deletedVertices.get(id)
        if (vertex) {
            this._deletedVertices.delete(id)
            this._basePath.set(id, vertex)
            return
        }
        this._basePath.set(id, value)
    }

    updateBaseVertex(id, changes) {
        if (this._basePath.has(id)) {
            const vertex = this._basePath.get(id)
            for (const [propKey, change] of changes) {
                vertex[propKey] = change.after
            }
        }
    }

    /**
     * replaces the states's geometry and cache base value of path morphing 
     * @param {GeometryType} type
     * @returns {Geometry}
     */
    changeGeometry(type) {
        const elementId = this.get('id')
        this.data.geometry.watched.setGeometryType(type)
        this.data.initGeometryType = this.data.geometry.get('geometryType')
        this._setupLiftupParents()
        this.cachePathBaseValue(true)
        this.dataStore.transition.cacheSpecificElementBaseValue(elementId, 'pathMorphing')
        return this.data.geometry
    }

    removeIfEmpty() {
        const mesh = this.data.geometry.get('mesh')
        const noAnyEdge = mesh && !mesh.edges.size
        if (!this.dataStore.inUndoRedo && noAnyEdge) {
            this.dataStore.selection.clearElements(NO_COMMIT)
            this.dataStore.removeChildren(this.get('parent'), [this], NO_COMMIT)
        }
    }

    undo(type, changes) {
        super.undo(type, changes)
        if (type === 'CHANGES' && changes?.has('geometryType')) {
            this.cachePathBaseValue()
            if (this.dataStore.isActionMode) {
                const size = this.get('size')
                this.set('referencePointX', size[0] / 2, { undoable: false, interaction: false })
                this.set('referencePointY', size[1] / 2, { undoable: false, interaction: false })
            }
        }

        if (this.undoEvents.has(type)) {
            this.data.geometry.watched.undo(type, changes)
        }
    }

    redo(type, changes) {
        if (type === 'CHANGES' && changes?.has('geometryType')) {
            this.cachePathBaseValue()
        }

        super.redo(type, changes)
        if (this.undoEvents.has(type)) {
            this.data.geometry.watched.redo(type, changes)
        }
    }

    clear() {
        super.clear()
        this.dataStore.nameCounter.remove(this)
    }

    /**
     * @param {string} type   event type
     * @param {*} original
     * @param {*} current
     * @returns {bool}    true if handled; false otherwise
     */
    combineUndo(type, original, current) {
        if (this.undoEvents.has(type)) {
            return this.data.geometry.watched.combineUndo(type, original, current)
        }
        return super.combineUndo(type, original, current)
    }

    _createGeometry(data) {
        const newGeometry = new Geometry(this.dataStore, data)
        const watcher = new Watcher(newGeometry)
        return watcher
    }

    cachePathBaseValue(force = false) {
        this._dirty = false
        if (!this.canMorph) {
            return
        }
        if (!this.dataStore.isDesignMode && !force) {
            return
        }
        const geometry = this.data.geometry
        const mesh = geometry.get('mesh')
        if (!mesh) {
            return
        }

        this._basePath.clear()
        mesh.vertices.forEach((v) => {
            this._basePath.set(v.id, v.save())
        })
    }

    /**
     *
     * @param {PointChange[]} pointChanges
     * @returns {ERROR_UPDATE_VERTICES_POSITION}
     */
    updateVerticesPosition(pointChanges) {
        if (this.data.elementType !== ElementType.PATH) {
            return ERROR_UPDATE_VERTICES_POSITION.NOT_VALID_INSTANCE
        }


        if (!this.canMorph) {
            return ERROR_UPDATE_VERTICES_POSITION.NOT_VALID_INSTANCE
        }

        if (!this.data.geometry) {
            return ERROR_UPDATE_VERTICES_POSITION.NOT_VALID_INSTANCE
        }

        /** @type {Mesh} */
        const mesh = this.data.geometry.get('mesh')
        if (!mesh) {
            return ERROR_UPDATE_VERTICES_POSITION.NOT_VALID_INSTANCE
        }

        if (pointChanges.length === 0) {
            return ERROR_UPDATE_VERTICES_POSITION.NO_CHANGES
        }
        mesh.applyVerticesPosition(pointChanges)
        EventEmitter.prototype.emit.apply(mesh, ['TRIGGER_VECTOR_FORCE_UPDATE'])
        return ERROR_UPDATE_VERTICES_POSITION.SUCCESS
    }
}

/** @typedef {import('./Element').ElementData} ElementData */

/** @typedef {'NONE' | 'UNION' | 'SUBTRACT' | 'INTERSECT' | 'DIFFERENCE'} BooleanOperation */

/**
 * @typedef {ElementData} PathData
 * @property {boolean} mask        mask into parent GeometryGroup
 * @property {BooleanOperation} booleanOperation  boolean operation into GeometryGroup
 * @property {geometry} [propName] [description]
 */
