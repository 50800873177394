import { IRComponent } from './IRComponent'
import { IRFill } from './IRFill'
import { IRStroke } from './IRStroke'
import { IRTrimEffect } from './IRTrimEffect'
import { IREffect } from './IREffect'
import { IREffectType, IRBlendMode } from './constants'
import { id } from './utils'

export class IRNode extends IRComponent {
  static defaultValue = {
    id: '',
    name: '',
    autoOrient: false,
    type: '',
    parent: null,
    maskParent: null,
    fills: [],
    strokes: [],
    effects: []
  }

  static props = [
    ...super.props,
    { key: 'blendMode', animatable: true, defaultValue: IRBlendMode.PASS_THROUGH },
    { key: 'motionPath', animatable: true, defaultValue: [0, 0] },
    { key: 'translateX', animatable: true, defaultValue: 0 },
    { key: 'translateY', animatable: true, defaultValue: 0 },
    { key: 'originX', animatable: true, defaultValue: 0 },
    { key: 'originY', animatable: true, defaultValue: 0 },
    { key: 'rotation', animatable: true, defaultValue: 0 },
    { key: 'opacity', animatable: true, defaultValue: 1 },
    { key: 'scaleX', animatable: true, defaultValue: 1 },
    { key: 'scaleY', animatable: true, defaultValue: 1 },
    { key: 'skewX', animatable: true, defaultValue: 0 },
    { key: 'skewY', animatable: true, defaultValue: 0 }
  ]

  constructor(data = IRNode.defaultValue, parent = null) {
    super(data)
    this.uid = data.uid === undefined ? id() : data.uid
    this.id = data.id
    this.name = data.name
    this.type = data.type
    this.visible = data.visible === undefined ? true : data.visible
    this.autoOrient = data.autoOrient === undefined ? false : data.autoOrient
    this.parent = parent
    this.mask = false
    this.maskParent = null
    this.fills = []
    this.strokes = []
    this.effects = []
  }

  fromJSON(data) {
    super.fromJSON(data)
    this.uid = data.uid
    this.id = data.id
    this.name = data.name
    this.type = data.type
    this.visible = data.visible
    this.autoOrient = data.autoOrient
    this.parent = data.parent
    this.mask = data.mask
    this.maskParent = data.maskParent
    this.fills = data.fills.map(fillData => new IRFill().fromJSON(fillData))
    this.strokes = data.strokes.map(strokeData => new IRStroke().fromJSON(strokeData))
    this.effects = data.effects.map(effectData => {
      let EffectClass
      switch (effectData.type) {
        case IREffectType.TRIM_PATH:
          EffectClass = IRTrimEffect
          break
        default:
          EffectClass = IREffect
          break
      }
      return new EffectClass().fromJSON(effectData)
    })
    return this
  }

  get parentId() {
    return this.parent ? this.parent.uid : null
  }

  get hasFill() {
    return this.fills.length > 0
  }

  get hasStroke() {
    return this.strokes.length > 0
  }

  get hasLayer() {
    return this.hasFill || this.hasStroke
  }

  toJSON() {
    return {
      ...super.toJSON(),
      uid: this.uid,
      id: this.id,
      name: this.name,
      type: this.type,
      visible: this.visible,
      autoOrient: this.autoOrient,
      parent: this.parent ? this.parent.uid : null,
      mask: Boolean(this.mask),
      maskParent: this.maskParent ? this.maskParent.uid : null,
      fills: this.fills.map(fill => fill.toJSON()),
      strokes: this.strokes.map(stroke => stroke.toJSON()),
      effects: this.effects.map(effect => effect.toJSON())
    }
  }

  addFill(fill) {
    this.fills.push(fill)
  }

  addStroke(stroke) {
    this.strokes.push(stroke)
  }

  addEffect(effect) {
    this.effects.push(effect)
  }
}
