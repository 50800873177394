import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { NO_COMMIT } from '@phase-software/data-utils'
import { PaintType, ToolType } from '@phase-software/types'
import PropTypes from 'prop-types'

import { useDataStoreActions } from '../../../providers/dataStore/DataStoreProvider'
import { useEditorActions } from '../../../providers/dataStore/EditorProvider'
import { usePaint } from '../../../providers/dataStore/PaintProvider'
import { useTool, useToolActions } from '../../../providers/dataStore/ToolProvider'
import {
  hex2rgb,
  hsb2rgb,
  hsl2rgb,
  hsv2rgb,
  rgb2HexString,
  rgb2hsb,
  rgb2hsl,
  rgb2hsv,
  rgb2rgbaString
} from '../../../utils/color'
import {
  getHEXString,
  getHSBArray,
  getHSLArray,
  getRGBArray,
  hexValidator,
  hsbValidator,
  hslValidator,
  rgbValidator
} from '../../../utils/inputValidator'
import { parseToDecimals } from '../../../utils/number'
import { percentageFormatValidator } from '../../../utils/validator'
import { Button, CircleKnob, Input, Select, Tooltip } from '../../shared'
import AlphaPicker from './AlphaPicker'
import ColorPicker from './ColorPicker'
import HuePicker from './HuePicker'

const COLOR_FORMATS = {
  HEX: 'HEX',
  RGB: 'RGB',
  HSL: 'HSL',
  HSB: 'HSB'
}

const DEFAULT_COLOR_FORMAT = COLOR_FORMATS.HEX
const COLOR_PICKER_PADDING = 6

const colorFormatOptions = [
  { name: COLOR_FORMATS.HEX, value: COLOR_FORMATS.HEX },
  { name: COLOR_FORMATS.RGB, value: COLOR_FORMATS.RGB },
  { name: COLOR_FORMATS.HSL, value: COLOR_FORMATS.HSL },
  { name: COLOR_FORMATS.HSB, value: COLOR_FORMATS.HSB }
]

const getColorValidator = (format) => {
  switch (format) {
    case COLOR_FORMATS.HEX:
      return hexValidator
    case COLOR_FORMATS.RGB:
      return rgbValidator
    case COLOR_FORMATS.HSL:
      return hslValidator
    case COLOR_FORMATS.HSB:
      return hsbValidator
    default:
      return () => {}
  }
}

const getColorConvertor = (format) => {
  switch (format) {
    case COLOR_FORMATS.HEX:
      return (value) => hex2rgb(getHEXString(value))
    case COLOR_FORMATS.RGB:
      return (value) => getRGBArray(value)
    case COLOR_FORMATS.HSL:
      return (value) => hsl2rgb(...getHSLArray(value))
    case COLOR_FORMATS.HSB:
      return (value) => hsb2rgb(...getHSBArray(value))
  }
}

const getColorValue = (color, format) => {
  switch (format) {
    case COLOR_FORMATS.HEX: {
      let rt
      let gt
      let bt
      if (color.length === 3) {
        rt = color[0] + color[0]
        gt = color[1] + color[1]
        bt = color[2] + color[2]
      } else if (color.length === 6) {
        rt = color[0] + color[1]
        gt = color[2] + color[3]
        bt = color[4] + color[5]
      }
      const newHex = rt + gt + bt
      return newHex.toUpperCase()
    }
    default:
      return color
        .split(',')
        .map((c) => parseToDecimals(c.trim(), 0))
        .join(', ')
  }
}

const getColorStrByFormat = (color, format) => {
  const joinStr = (arr, ratio = 1) => {
    return arr.map((c) => parseToDecimals(c * ratio, 0)).join(', ')
  }

  switch (format) {
    case COLOR_FORMATS.HEX: {
      return rgb2HexString(color, false).toUpperCase()
    }
    case COLOR_FORMATS.RGB: {
      const rgb = [...color]
      rgb.pop()
      return `${joinStr(rgb, 255)}`
    }
    case COLOR_FORMATS.HSL: {
      const [r, g, b] = color
      const hsl = rgb2hsl(r, g, b)
      return `${joinStr(hsl)}`
    }
    case COLOR_FORMATS.HSB: {
      const [r, g, b] = color
      const hsb = rgb2hsb(r, g, b)
      return `${joinStr(hsb)}`
    }
  }
}

const ColorPaintPicker = ({ color, onChange = () => {} }) => {
  const { commitUndo } = useDataStoreActions()
  const { activateEyeDropperTool, setActiveTool } = useToolActions()
  const activeTool = useTool().activeTool
  const [h, s] = rgb2hsv(...color)
  const bgColor = rgb2HexString(color)
  const [hue, setHue] = useState(h)
  const hueColor = rgb2HexString(hsv2rgb(hue, 1, 1))
  const alphaFillColor = rgb2rgbaString(color)

  const [format, setFormat] = useState(DEFAULT_COLOR_FORMAT)
  const [isEyeDropper, setIsEyeDropper] = useState(false)
  const pointerRef = useRef()
  const colorPickerRef = useRef()
  const sRef = useRef(s)
  const rectRef = useRef()
  const aRef = useRef(color[3])
  const colorValidator = getColorValidator(format)

  const { t } = useTranslation('file', { keyPrefix: 'property_editor.fill_editor.paint_picker' })

  // if use const isEyeDropper =  useTool().activeTool === ToolType.EYE_DROPPER
  // the initial value would be wrong
  useEffect(() => {
    setIsEyeDropper(activeTool === ToolType.EYE_DROPPER)
  }, [activeTool])

  useEffect(() => {
    rectRef.current = colorPickerRef.current.getBoundingClientRect()
    const rectWidth = rectRef.current.width - 2 * COLOR_PICKER_PADDING
    const rectHeight = rectRef.current.height - 2 * COLOR_PICKER_PADDING
    const [, s, v] = rgb2hsv(...color)
    Object.assign(pointerRef.current.style, {
      transform: `translate(calc(${s * rectWidth + COLOR_PICKER_PADDING}px - 50%), calc(${
        (1 - v) * rectHeight + COLOR_PICKER_PADDING
      }px - 50%))`
    })
  }, [color])

  useEffect(() => {
    const [h, s, v] = rgb2hsv(...color)
    if (v) {
      sRef.current = s
    }

    if (s) {
      setHue(h)
    }
    const rectWidth = rectRef.current.width - 2 * COLOR_PICKER_PADDING
    const rectHeight = rectRef.current.height - 2 * COLOR_PICKER_PADDING
    Object.assign(pointerRef.current.style, {
      transform: `translate(calc(${sRef.current * rectWidth + COLOR_PICKER_PADDING}px - 50%), calc(${
        (1 - v) * rectHeight + COLOR_PICKER_PADDING
      }px - 50%))`
    })
  }, [color, setHue])

  const handleMouseDown = (e) => {
    e.currentTarget.blur()
    e.stopPropagation()
    rectRef.current = colorPickerRef.current.getBoundingClientRect()

    const handleColorUpdate = (e, commit) => {
      if (e.target === document.activeElement) {
        e.target.blur()
      }
      e.stopPropagation()
      const rectWidth = rectRef.current.width - 2 * COLOR_PICKER_PADDING
      const rectHeight = rectRef.current.height - 2 * COLOR_PICKER_PADDING
      const left = Math.max(Math.min(e.clientX - rectRef.current.left - COLOR_PICKER_PADDING, rectWidth), 0)
      const top = Math.max(Math.min(e.clientY - rectRef.current.top - COLOR_PICKER_PADDING, rectHeight), 0)
      const s = 1 - (rectWidth - left) / rectWidth
      sRef.current = s
      const v = (rectHeight - top) / rectHeight
      Object.assign(pointerRef.current.style, {
        transform: `translate(calc(${s * rectWidth + COLOR_PICKER_PADDING}px - 50%), calc(${
          (1 - v) * rectHeight + COLOR_PICKER_PADDING
        }px - 50%))`
      })
      const rgba = [...hsv2rgb(hue, s, v), aRef.current]
      onChange(rgba, commit)
    }

    window.addEventListener('mousemove', handleColorUpdate)
    window.addEventListener(
      'mouseup',
      (e) => {
        e.target.blur()
        window.removeEventListener('mousemove', handleColorUpdate)
      },
      { once: true }
    )
    handleColorUpdate(e)
  }

  const handleKeyDown = (e) => {
    rectRef.current = colorPickerRef.current.getBoundingClientRect()

    let [, s, v] = rgb2hsv(...color)
    s = sRef.current ?? s
    const step = 0.01
    switch (e.key) {
      case 'ArrowLeft':
        e.stopPropagation()
        s = Math.max(s - step, 0)
        break
      case 'ArrowRight':
        e.stopPropagation()
        s = Math.min(s + step, 1)
        break
      case 'ArrowUp':
        e.stopPropagation()
        v = Math.min(v + step, 1)
        break
      case 'ArrowDown':
        e.stopPropagation()
        v = Math.max(v - step, 0)
        break
      case 'Escape':
        e.stopPropagation()
        e.currentTarget.blur()
        break
      default:
        return
    }

    sRef.current = s
    const rectWidth = rectRef.current.width - 2 * COLOR_PICKER_PADDING
    const rectHeight = rectRef.current.height - 2 * COLOR_PICKER_PADDING
    Object.assign(pointerRef.current.style, {
      transform: `translate(calc(${s * rectWidth + COLOR_PICKER_PADDING}px - 50%), calc(${
        (1 - v) * rectHeight + COLOR_PICKER_PADDING
      }px - 50%))`
    })
    const rgba = [...hsv2rgb(hue, s, v), aRef.current]
    onChange(rgba)
  }

  const handleKeyUp = (e) => {
    switch (e.key) {
      case 'ArrowLeft':
      case 'ArrowRight':
      case 'ArrowUp':
      case 'ArrowDown':
        e.stopPropagation()
        break

      default:
        return
    }
    const [r, g, b, a] = color
    onChange([r, g, b, a], true)
  }

  const handleHueChange = (newHue, changeEnd) => {
    const [, s, v] = rgb2hsv(...color)
    setHue(newHue)
    const rgb = hsv2rgb(newHue, s, v)
    const rgba = [...rgb, color[3]]
    onChange(rgba, changeEnd)
  }

  const handleAlphaChange = (a, changeEnd) => {
    const [r, g, b] = color
    aRef.current = a
    onChange([r, g, b, a], changeEnd)
  }

  const handleAChange = (v, { commit } = true) => {
    const a = v / 100
    const [r, g, b] = color
    aRef.current = a
    onChange([r, g, b, a], commit)
  }

  const handleColorInputChange = (value) => {
    const newColorValue = getColorValue(value, format)
    const convertor = getColorConvertor(format)
    const rgb = convertor(newColorValue)
    onChange([...rgb, aRef.current], true)
  }

  const handleColorFormatChange = (format) => {
    setFormat(format)
  }

  const handleEyeDropperClick = (e) => {
    e.stopPropagation()
    !isEyeDropper ? activateEyeDropperTool() : setActiveTool(ToolType.SELECT)
  }

  return (
    <div className="w-[216px]">
      <ColorPicker
        hue={hue}
        onMouseDown={handleMouseDown}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
        ref={colorPickerRef}
        className="mb-8 group"
      >
        <div ref={pointerRef} className="absolute pointer-events-none">
          <CircleKnob color={bgColor} size={12} className="group-focus-visible:outline-primary-2" />
        </div>
      </ColorPicker>
      <div className="flex items-center mb-8">
        <Tooltip content={t('eye_dropper_tooltip')}>
          <div>
            <Button color="secondary" size="xl" onClick={handleEyeDropperClick} icon="Dropper" />
          </div>
        </Tooltip>

        <div className="w-full ml-8 flex flex-col gap-y-8">
          <HuePicker value={hue} fillColor={hueColor} onChange={handleHueChange} dataTestId="hue-picker" />
          <AlphaPicker
            value={aRef.current}
            color={bgColor}
            fillColor={alphaFillColor}
            onChange={handleAlphaChange}
            dataTestId="alpha-picker"
          />
        </div>
      </div>

      <div className="w-[216px] mb-8">
        <Select
          caret
          variant="normal"
          options={colorFormatOptions}
          value={format}
          onChange={handleColorFormatChange}
          data-test-id="color-format-select"
        />
      </div>
      <div className="flex gap-8">
        <Input
          variant="normal"
          size={6}
          onBlur={commitUndo}
          formatter={() => getColorStrByFormat(color, format)}
          value={getColorStrByFormat(color, format)}
          validator={colorValidator}
          onChange={handleColorInputChange}
          data-test-id="colorpicker-color-input"
        />
        <Input
          variant="normal"
          type="number"
          value={parseToDecimals(aRef.current * 100, 2)}
          formatter={(o) => `${o}%`}
          validator={percentageFormatValidator}
          max={100}
          min={0}
          onBlur={commitUndo}
          onChange={handleAChange}
          data-test-id="colorpicker-opacity-input"
          spinner
        />
      </div>
    </div>
  )
}
ColorPaintPicker.propTypes = {
  color: PropTypes.oneOfType([PropTypes.array, PropTypes.instanceOf(Float32Array)]),
  layerItemId: PropTypes.string,
  gradientStops: PropTypes.array,
  activeIdx: PropTypes.number,
  selectedGradientStop: PropTypes.object,
  isSolid: PropTypes.bool,
  onChange: PropTypes.func,
  setLayerPaint: PropTypes.func
}

const Wrapper = ({ layerItemId, activeIdx }) => {
  const { paintType, color, opacity, gradientStops } = usePaint((o) => o[layerItemId])
  const { setLayerPaint } = useEditorActions()
  const isSolid = paintType === PaintType.SOLID
  let activeColor
  let selectedGradientStop
  if (isSolid) {
    const [r, g, b] = color
    activeColor = [r, g, b, opacity]
  } else {
    selectedGradientStop = gradientStops[activeIdx]
    if (!selectedGradientStop) {
      let min = 1
      let idx = Number.MAX_SAFE_INTEGER
      gradientStops.forEach((gs, i) => {
        if (gs.position < min) {
          min = gs.position
          idx = i
        }
      })
      selectedGradientStop = gradientStops[idx]
    }
    activeColor = selectedGradientStop.color
  }

  // Not commit the changes by default
  const handleChange = (color, commit = false) => {
    if (isSolid) {
      const [r, g, b, a] = color
      setLayerPaint(
        layerItemId,
        {
          color: [r, g, b, 1],
          opacity: a
        },
        commit ? undefined : NO_COMMIT
      )
    } else {
      setLayerPaint(
        layerItemId,
        {
          gradientStops: [
            ...gradientStops.slice(0, activeIdx),
            { ...gradientStops[activeIdx], color },
            ...gradientStops.slice(activeIdx + 1)
          ]
        },
        commit ? undefined : NO_COMMIT
      )
    }
  }

  return <ColorPaintPicker color={activeColor} onChange={handleChange} />
}
Wrapper.propTypes = {
  layerItemId: PropTypes.string,
  activeIdx: PropTypes.number
}
export default Wrapper
