import { PropComponentType } from '@phase-software/types'
import { isStr, notNull } from '@phase-software/data-utils'
import PropertyComponent from './PropertyComponent'

/** @typedef {import('../DataStore').DataStore} DataStore */
/** @typedef {import('./PropertyComponent').AppliedRef} AppliedRef */

export default class TextDecorationComponent extends PropertyComponent {
    /**
     * @param {DataStore} dataStore
     * @param {TextDecorationComponentData} [data]
     * @param {object} [options] 
     * @param {bool} [options.regenId=false]   if set to true, will generate new ID
     */
    constructor(dataStore, data = {}, options) {
        super(dataStore, data, options)

        const { underline, strikethrough, cases, subSuper } = data

        this.underline = typeof underline === 'boolean' ? underline : false
        this.strikethrough = typeof strikethrough === 'boolean' ? strikethrough : false
        this.cases = isStr(cases) ? cases : 'NORMAL'
        this.subSuper = isStr(subSuper) ? subSuper : 'NORMAL'
        this.componentType = PropComponentType.TEXT_DECORATION

        if (!this.name) {
            this.name = 'text-decoration'
        }
    }

    /** @param {Partial<TextDecorationComponentData>} data */
    set(data) {
        super.set(data)

        const {
            underline,
            strikethrough,
            cases,
            subSuper
        } = data

        if (typeof underline === 'boolean') {
            this.updateProp('underline', data.underline)
        } else if (underline === null) {
            this.updateProp('underline', undefined)
        }
        if (typeof strikethrough === 'boolean') {
            this.updateProp('strikethrough', data.strikethrough)
        } else if (strikethrough === null) {
            this.updateProp('strikethrough', undefined)
        }
        if (isStr(cases)) {
            this.updateProp('cases', data.cases)
        } else if (cases === null) {
            this.updateProp('cases', undefined)
        }
        if (isStr(subSuper)) {
            this.updateProp('subSuper', data.subSuper)
        } else if (subSuper === null) {
            this.updateProp('subSuper', undefined)
        }
    }

    /**
     * Override this in subclasses
     * CALL super._clone() at the top of overriden method
     * @protected
     * @param {AppliedRef} [ref]
     * @returns {TextDecorationComponentData} obj
     */
    _clone(ref) {
        const obj = super._clone(ref)
        obj.underline = this.underline
        obj.strikethrough = this.strikethrough
        obj.cases = this.cases
        obj.subSuper = this.subSuper
        return obj
    }

    /**
     * Override this in subclasses
     * CALL super._save() at the top of overriden method
     * @protected
     * @returns {TextDecorationComponentData} data
     */
    _save() {
        const data = super._save()
        if (notNull(this.underline)) {
            data.underline = this.underline
        }
        if (notNull(this.strikethrough)) {
            data.strikethrough = this.strikethrough
        }
        if (notNull(this.cases)) {
            data.cases = this.cases
        }
        if (notNull(this.subSuper)) {
            data.subSuper = this.subSuper
        }
        return data
    }
}

TextDecorationComponent.BASE_DEFAULT_DATA = {
    underline: false,
    strikethrough: false,
    cases: 'NORMAL',
    subSuper: 'NORMAL'
}

/** @typedef {import('./PropertyComponent').PropertyComponentData} PropertyComponentData */

/** @typedef {('NORMAL'|'UPPER'|'LOWER'|'TITLE')} TextCaseType */
/** @typedef {('NORMAL'|'SUPERSCRIPT'|'SUBSCRIPT')} SubSuperScriptType */

/**
 * @typedef {PropertyComponentData} TextDecorationComponentData
 * @property {(boolean | undefined)} underline
 * @property {(boolean | undefined)} strikethrough
 * @property {(TextCaseType | undefined)} cases
 * @property {(SubSuperScriptType | undefined)} subSuper
 */

/**
 * @typedef {PropertyComponentData} TextDecorationComponentSetData
 * @property {(boolean | null)} underline
 * @property {(boolean | null)} strikethrough
 * @property {(TextCaseType | null)} cases
 * @property {(SubSuperScriptType | null)} subSuper
 */
