import React, { createContext, useCallback, useContext, useMemo, useState } from 'react'

type SpinnerLoadingContextProps = {
  isSpinnerLoading: boolean
  startSpinnerLoadingWithTimeout: (duration?: number) => void
  startSpinnerLoading: () => void
  stopSpinnerLoading: () => void
}

const defaultSpinnerLoadingContext: SpinnerLoadingContextProps = {
  isSpinnerLoading: false,
  startSpinnerLoadingWithTimeout: () => {},
  startSpinnerLoading: () => {},
  stopSpinnerLoading: () => {}
}

export const SpinnerLoadingContext = createContext<SpinnerLoadingContextProps>(defaultSpinnerLoadingContext)

const SpinnerLoadingProvider = ({ children }: { children: React.ReactNode }) => {
  const [isSpinnerLoading, setIsSpinnerLoading] = useState(false)

  const startSpinnerLoadingWithTimeout = useCallback((duration = 400) => {
    setIsSpinnerLoading(true)
    setTimeout(() => {
      setIsSpinnerLoading(false)
    }, duration)
  }, [])

  const startSpinnerLoading = useCallback(() => {
    setIsSpinnerLoading(true)
  }, [])

  const stopSpinnerLoading = useCallback(() => {
    setIsSpinnerLoading(false)
  }, [])

  const value = useMemo(
    () => ({
      isSpinnerLoading,
      startSpinnerLoadingWithTimeout,
      startSpinnerLoading,
      stopSpinnerLoading
    }),
    [isSpinnerLoading, startSpinnerLoadingWithTimeout, startSpinnerLoading, stopSpinnerLoading]
  )

  return <SpinnerLoadingContext.Provider value={value}>{children}</SpinnerLoadingContext.Provider>
}

function useSpinnerLoadingContext(): SpinnerLoadingContextProps {
  const context = useContext(SpinnerLoadingContext)
  if (context === undefined) {
    throw new Error('useSpinnerLoadingContext must be used within a SpinnerLoadingProvider')
  }
  return context
}

export { SpinnerLoadingProvider, useSpinnerLoadingContext }
