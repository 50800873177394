export class OpacityStop {
    /**
     * [constructor description]
     * @param  {OpacityStopData} data
     */
    constructor({ position, opacity } = {}) {
        if (typeof opacity === 'number' && typeof position === 'number') {
            this.position = position
            this.opacity = opacity
        } else {
            this.copy(position)
        }
    }

    copy(val) {
        this.position = val.position
        this.opacity = val.opacity
    }

    eq(val) {
        return val && this.position === val.position && this.opacity === val.opacity
    }

    save() {
        const data = {}
        data.position = this.position
        data.opacity = this.opacity
        return data
    }
}

/**
 * @typedef {object} OpacityStopData
 * @property {number} position        range [0.0, 1.0]
 * @property {number} opacity         range [0,0, 1.0]
 */
