import React from 'react'
import { useTranslation } from 'react-i18next'

// @ts-ignore
import { MIN_SIZE_THRESHOLD } from '@phase-software/data-utils'
import { GeometryType } from '@phase-software/types'

import { useDataStore } from '../../../../providers/dataStore/DataStoreProvider'
import { useEditor } from '../../../../providers/dataStore/EditorProvider'
import { divideBy100, formatAsPercentage, multiplyBy100 } from '../../../../utils/formatter'
import { transformPercentageFormatValidator } from '../../../../utils/validator'
import { Icon } from '../../../shared'
import Lock from '../Lock'
import Property from '../Property'
import PropertyRow from '../PropertyRow'

const Scale = () => {
  const valueScaleX = useEditor((o) => o.scaleX)
  const valueScaleY = useEditor((o) => o.scaleY)
  const { geometryType, width, height } = useEditor()
  const dataStore = useDataStore()
  const { t } = useTranslation('file', { keyPrefix: 'property_editor.transform_editor' })

  const selectedElements = dataStore.selection.get('elements')
  const isLine = geometryType === GeometryType.LINE
  const disabledLock =
    (valueScaleX === 0 && valueScaleY > 0) ||
    (valueScaleX > 0 && valueScaleY === 0) ||
    (isLine && width < MIN_SIZE_THRESHOLD && height < MIN_SIZE_THRESHOLD) ||
    // FIXME: This should be implemented in Editor
    selectedElements.every(
      (el: any) =>
        el.get('geometryType') === GeometryType.LINE &&
        el.get('width') < MIN_SIZE_THRESHOLD &&
        el.get('height') < MIN_SIZE_THRESHOLD
    )

  return (
    <>
      <PropertyRow columns={[94, 94]}>
        <Property
          field="scaleX"
          data-test-id="scale-X-input"
          caption={<Icon interactive={false} name="ScaleX" />}
          toDisplay={multiplyBy100}
          toValue={divideBy100}
          formatter={formatAsPercentage}
          validator={transformPercentageFormatValidator}
          tooltip={t('scale_x')}
        />
        <Property
          field="scaleY"
          data-test-id="scale-Y-input"
          caption={<Icon interactive={false} name="ScaleY" />}
          toDisplay={multiplyBy100}
          toValue={divideBy100}
          formatter={formatAsPercentage}
          validator={transformPercentageFormatValidator}
          tooltip={t('scale_y')}
        />
        <Lock field="scaleAspectRatioLocked" disabled={disabledLock} className="h-16" />
      </PropertyRow>
    </>
  )
}

export default React.memo(Scale)
