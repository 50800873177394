import React, { useCallback } from 'react'
import { Grid } from '../../../shared'
import { useEditor, useEditorActions } from '../../../../providers/dataStore/EditorProvider'
import { useDataStoreActions } from '../../../../providers/dataStore/DataStoreProvider'
import { useTransitionManagerActions } from '../../../../providers/TransitionManagerProvider'

const ORIGIN_MAP = [
  { contentAnchorX: 0, contentAnchorY: 0 },
  { contentAnchorX: 50, contentAnchorY: 0 },
  { contentAnchorX: 100, contentAnchorY: 0 },
  { contentAnchorX: 0, contentAnchorY: 50 },
  { contentAnchorX: 50, contentAnchorY: 50 },
  { contentAnchorX: 100, contentAnchorY: 50 },
  { contentAnchorX: 0, contentAnchorY: 100 },
  { contentAnchorX: 50, contentAnchorY: 100 },
  { contentAnchorX: 100, contentAnchorY: 100 }
]
const CONTENT_ANCHOR_OFFSET_PERCENT = -50

const dotBaseClassName =
  'relative w-full h-full bg-transparent cursor-pointer disabled:cursor-not-allowed before:absolute before:inset-4 before:rounded-circle focus-visible:before:outline-primary-1-offset-2 disabled:pointer-events-none'

const dotUnselectedClassName = `${dotBaseClassName} before:bg-light-overlay-20 hover:before:bg-light-overlay-40 disabled:before:opacity-40 active:before:bg-light-overlay-10`

const dotSelectedClassName = `${dotBaseClassName} before:bg-white disabled:before:opacity-40 active:before:bg-light-overlay-60`

type OriginPosition = {
  contentAnchorX: number
  contentAnchorY: number
}

const matchOriginPosition = (
  originPosition: OriginPosition,
  contentAnchorPercentX: number,
  contentAnchorPercentY: number
) => {
  const xPosMatch = originPosition.contentAnchorX + CONTENT_ANCHOR_OFFSET_PERCENT === contentAnchorPercentX
  const yPosMatch = originPosition.contentAnchorY + CONTENT_ANCHOR_OFFSET_PERCENT === contentAnchorPercentY
  return xPosMatch && yPosMatch
}

const OriginMap = () => {
  const { setOrigin9Patch } = useEditorActions()
  const { commitUndo } = useDataStoreActions()
  const { contentAnchorPercentX, contentAnchorPercentY } = useEditor()
  const { stopAnimation } = useTransitionManagerActions()

  const handleOriginChange = useCallback(
    (data: OriginPosition, disabled: boolean, isSelected: boolean) => {
      if (disabled || isSelected) {
        return
      }
      stopAnimation()
      setOrigin9Patch(data)
      commitUndo()
    },
    [setOrigin9Patch, commitUndo, stopAnimation]
  )

  const Dot = (data: OriginPosition) => {
    const isSelected = matchOriginPosition(data, contentAnchorPercentX, contentAnchorPercentY)

    const handleOnclick = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      e.stopPropagation()
      handleOriginChange(
        {
          contentAnchorX: data.contentAnchorX + CONTENT_ANCHOR_OFFSET_PERCENT,
          contentAnchorY: data.contentAnchorY + CONTENT_ANCHOR_OFFSET_PERCENT
        },
        false,
        isSelected
      )
    }

    const handleUnfocus: React.MouseEventHandler<HTMLButtonElement> = (e) => {
      ;(e.target as HTMLButtonElement).blur()
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
      switch (e.key) {
        case ' ':
        case 'Enter':
          e.preventDefault()
          e.currentTarget.click()
          break
        case 'Escape':
          e.currentTarget.blur()
          e.stopPropagation()
          break
      }
    }

    return (
      <button
        className={isSelected ? dotSelectedClassName : dotUnselectedClassName}
        onClick={handleOnclick}
        onMouseDown={handleUnfocus}
        onMouseUp={handleUnfocus}
        onKeyDown={handleKeyDown}
        value={`${data.contentAnchorX}-${data.contentAnchorY}`}
        key={`${data.contentAnchorX}-${data.contentAnchorY}`}
        name="origin"
      />
    )
  }

  return (
    <Grid
      className="w-fit mx-auto py-8"
      // @ts-ignore TODO: need to add type in Grid
      gridRowGap={1}
      gridColumnGap={1}
      gridTemplateRows="16px 16px 16px"
      gridTemplateColumns="16px 16px 16px"
    >
      {ORIGIN_MAP.map(Dot)}
    </Grid>
  )
}

export default OriginMap
