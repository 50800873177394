import React, { useCallback, useEffect } from 'react'
import { useEditor, useEditorActions } from '../../../providers/dataStore/EditorProvider'
import AspectRatioLock from './AspectRatioLock'

type LockProps = {
  field: string
  className?: string
  disabled?: boolean
}

const Lock = ({ field, className, disabled }: LockProps) => {
  const value = useEditor((o) => o[field])
  const { setProperties } = useEditorActions()
  const toggleAspectRatioLock = useCallback(() => setProperties({ [field]: !value }), [setProperties, value, field])

  useEffect(() => {
    if (disabled === true) {
      setProperties({ [field]: false }, { commit: false })
    }
  }, [field, disabled, setProperties])

  return (
    <AspectRatioLock
      className={className}
      aspectRatioLocked={value}
      toggleAspectRatioLock={toggleAspectRatioLock}
      disabled={disabled}
    />
  )
}

export default Lock
