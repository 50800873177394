import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useUI, useUIActions } from '../../providers/dataStore/UIProvider'
import SwitcherButton from '../shared/SwitcherButton'

const InspectingButton = () => {
  const { enterInspectingMode, leaveInspectingMode } = useUIActions()
  const { isInspectingState } = useUI()
  const { t } = useTranslation('file')
  const tipData = { text: t('inspect_button'), shortcut: '⇧I' }

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.currentTarget === document.activeElement) {
        e.currentTarget.blur()
      }
      isInspectingState ? leaveInspectingMode() : enterInspectingMode()
    },
    [isInspectingState, leaveInspectingMode, enterInspectingMode]
  )

  return <SwitcherButton checked={isInspectingState} name="Code" tipData={tipData} handleChange={handleChange} />
}

export default InspectingButton
