import React from 'react'
import ReactDOM from 'react-dom'

interface OverlayProps {
  className: string
  overlayMode: 'normal' | 'modal'
}

// z-index 21 can overlap modal's z-index
const Overlay = ({ className, overlayMode = 'normal' }: OverlayProps) => {
  const zIndexClassName = overlayMode === 'normal' ? 'z-5' : 'z-[21]'
  return ReactDOM.createPortal(
    <div className={`absolute left-0 top-0 w-full h-full ${zIndexClassName} ${className} `} />,
    document.body
  )
}

export default Overlay
