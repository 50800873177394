import {
    PropComponentType,
    TextAlignHorizontalType,
    TextAlignVerticalType
} from '@phase-software/types'
import PropertyComponent from './PropertyComponent'

/** @typedef {import('../DataStore').DataStore} DataStore */
/** @typedef {import('./PropertyComponent').AppliedRef} AppliedRef */

export default class TextAlignmentComponent extends PropertyComponent {
    /**
     * @param {DataStore} dataStore
     * @param {TextAlignmentComponentData} [data]
     * @param {object} [options] 
     * @param {bool} [options.regenId=false]   if set to true, will generate new ID
     */
    constructor(dataStore, data = {}, options) {
        super(dataStore, data, options)

        const { horizontalAlignment, verticalAlignment } = data
        this.horizontalAlignment = horizontalAlignment in TextAlignHorizontalType ? horizontalAlignment : undefined
        this.verticalAlignment = verticalAlignment in TextAlignVerticalType ? verticalAlignment : undefined
        this.componentType = PropComponentType.TEXT_ALIGNMENT

        if (!this.name) {
            this.name = 'text-alignment'
        }
    }

    /** @param {Partial<TextAlignmentComponentSetData>} data */
    set(data) {
        super.set(data)

        const { horizontalAlignment, verticalAlignment } = data

        if (horizontalAlignment in TextAlignHorizontalType) {
            this.updateProp('horizontalAlignment', horizontalAlignment)
        } else if (horizontalAlignment === null) {
            this.updateProp('horizontalAlignment', undefined)
        }
        if (verticalAlignment in TextAlignVerticalType) {
            this.updateProp('verticalAlignment', verticalAlignment)
        } else if (verticalAlignment === null) {
            this.updateProp('verticalAlignment', undefined)
        }
    }

    /**
     * Override this in subclasses
     * CALL super._clone() at the top of overriden method
     * @protected
     * @param {AppliedRef} [ref]
     * @returns {TextAlignmentComponentData} obj
     */
    _clone(ref) {
        const obj = super._clone(ref)
        obj.horizontalAlignment = this.horizontalAlignment
        obj.verticalAlignment = this.verticalAlignment
        return obj
    }

    /**
     * Override this in subclasses
     * CALL super._save() at the top of overriden method
     * @protected
     * @returns {TextAlignmentComponentData} data
     */
    _save() {
        const data = super._save()
        if (this.horizontalAlignment in TextAlignHorizontalType) {
            data.horizontalAlignment = this.horizontalAlignment
        }
        if (this.verticalAlignment in TextAlignVerticalType) {
            data.verticalAlignment = this.verticalAlignment
        }
        return data
    }
}

TextAlignmentComponent.BASE_DEFAULT_DATA = {
    horizontalAlignment: TextAlignHorizontalType.LEFT,
    verticalAlignment: TextAlignVerticalType.TOP
}

/** @typedef {import('./PropertyComponent').PropertyComponentData} PropertyComponentData */

/** @typedef {('LEFT'|'RIGHT'|'CENTER'|'JUSTIFIED')} TextAlignHorizontalType */
/** @typedef {('TOP'|'BOTTOM'|'CENTER')} TextAlignVerticalType */

/**
 * @typedef {PropertyComponentData} TextAlignmentComponentData
 * @property {(TextAlignHorizontalType | undefined)} horizontalAlignment
 * @property {(TextAlignVerticalType | undefined)} verticalAlignment
 */

/**
 * @typedef {PropertyComponentData} TextAlignmentComponentSetData
 * @property {(TextAlignHorizontalType | null)} horizontalAlignment
 * @property {(TextAlignVerticalType | null)} verticalAlignment
 */
