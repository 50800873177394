import React from 'react'

import { TFunction } from 'i18next'

import { TeamFieldsFragment, UserFieldsFragment } from '../../generated/graphql'
import { InputField } from '../shared'
import Field from './Field'

type NameFieldProps = {
  label: string
  value: UserFieldsFragment['username'] | TeamFieldsFragment['name']
  onChange: (value: string) => void
  validator: (value: string, t: TFunction) => string
  size?: 'normal' | 'full'
}

const NameField = ({ label, value, onChange, validator, ...rest }: NameFieldProps) => {
  return (
    <Field label={label} {...rest}>
      <InputField
        data-test-id="name-input-edit"
        name="name"
        type="text"
        validator={validator}
        value={value ?? ''}
        onChange={onChange}
        labelClassName="mb-0"
      />
    </Field>
  )
}
export default NameField
