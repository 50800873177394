import { useEffect } from 'react'
import { normalize } from 'normalizr'
import { Element } from '../../providers/dataStore/ElementProvider'
import { useSetDocument } from '../../providers/dataStore/DocumentProvider'

import { useDataStore, useSetDataStore } from '../../providers/dataStore/DataStoreProvider'
import { getElementData } from '../../dataStore'

const SubscribeWorkspaceSelectionChange = () => {
  const dataStore = useDataStore()
  const { setFlattenElementList, mergeElement } = useSetDataStore()
  const { updateDocumentWorkspace } = useSetDocument()

  useEffect(() => {
    if (!dataStore) return
    const handleChange = ({ watch }) => {
      const workspaceId = watch.get('id')
      const elementsData = watch.children.map((o) => getElementData(o))
      const {
        entities: { elements = {} }
      } = normalize(elementsData, [Element])
      mergeElement(elements)
      setFlattenElementList(watch)
      updateDocumentWorkspace(workspaceId)
    }
    const handleLoad = () => {
      handleChange(dataStore.workspace)
    }
    // TODO: Update this part once we refactor the flatten element list
    const handleExpandChange = () => {
      handleChange(dataStore.workspace)
    }
    dataStore.workspace.on('CHANGE-WATCH', handleChange)
    dataStore.workspace.on('EXPAND_CHANGES', handleExpandChange)
    dataStore.on('LOAD', handleLoad)
    return () => {
      dataStore.off('LOAD', handleLoad)
      dataStore.workspace.off('CHANGE-WATCH', handleChange)
    }
  }, [dataStore, updateDocumentWorkspace, setFlattenElementList, mergeElement])
  return null
}

export default SubscribeWorkspaceSelectionChange
