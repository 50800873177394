import PropertyStack from '../../PropertyStack'

class JoinSizeStack extends PropertyStack {
  constructor(elementStack, data) {
    super(elementStack, data)
    this.type = 'JOIN_SIZE'
    this.key = 'joinSize'
    this.dataKey = 'joinSize'
    this.animatableProperties = new Set(['joinSize'])
  }
}

export default JoinSizeStack
