import BezierEasing from 'bezier-easing'
/** @todo It's critical that we align the calculations in this package with the renderer's transform calculations. Please, let's make this happen! */
import { vec2, mat2d } from 'gl-matrix'
import { minmax, ColorStop, Mesh, AABB } from '@phase-software/data-utils'
import {
  MergeMode,
  TrimMode,
  GradientFillType,
  GradientStrokeType,
  BlendMode,
  LineCapType,
  LineJoinType,
  MaskMode,
  ShapeType
} from '@phase-software/lottie-js'
import {
  EndShape,
  CapShape,
  JoinShape,
  BooleanOperation,
  TrimPathMode,
  ElementType,
  Unit,
  PaintType,
  BlendMode as PhaseBlendMode
} from '@phase-software/types'

const IS_ELEMENT_IN_PHASE = new Set([
  ShapeType.ELLIPSE,
  ShapeType.RECTANGLE,
  ShapeType.PATH,
  ShapeType.GROUP,
  ShapeType.TRIM,
  ShapeType.MERGE
])
export const isOnlyPathInGroup = groupShape => {
  for (const shape of groupShape.shapes) {
    if (shape.type === ShapeType.PATH) {
      continue
    }
    if (IS_ELEMENT_IN_PHASE.has(shape.type)) {
      return false
    }
  }
  return true
}

export const deg2rad = deg => (deg / 180) * Math.PI

export const getBezierValue = (start, end, [x1, y1, x2, y2], t) => {
  const p = BezierEasing(x1, y1, x2, y2)(t)
  if (Array.isArray(start)) {
    return start.map((v, i) => {
      // bezier (PathShape) {c, i, o, v}
      if (typeof start[i] === 'object') {
        return {
          c: start[i].c,
          i: start[i].i.map((point, pointIndex) => getBezierValue(point, end[i].i[pointIndex], [x1, y1, x2, y2], t)),
          o: start[i].o.map((point, pointIndex) => getBezierValue(point, end[i].o[pointIndex], [x1, y1, x2, y2], t)),
          v: start[i].v.map((point, pointIndex) => getBezierValue(point, end[i].v[pointIndex], [x1, y1, x2, y2], t))
        }
      }
      return start[i] + (end[i] - start[i]) * p
    })
  } else if (typeof start === 'object') {
    return Object.keys(end).reduce((acc, key) => {
      if (start[key] === undefined) {
        acc[key] = end[key]
        return acc
      }

      acc[key] = getBezierValue(start[key], end[key], [x1, y1, x2, y2], t)
      return acc
    }, {})
  }
  return start + (end - start) * p
}

export const findSegment = (animatedProp, time) => {
  const { value, kfs } = animatedProp
  if (!animatedProp.kfs.length) {
    return [
      { time: 0, value, bezier: [0.25, 0.25, 0.75, 0.75] },
      { time: 0, value, bezier: [0.25, 0.25, 0.75, 0.75] }
    ]
  }
  let begin = null
  let end = null
  if (kfs[0].time >= time) {
    begin = kfs[0].time ? { time: 0, value, bezier: [0.25, 0.25, 0.75, 0.75] } : kfs[0]
    end = kfs[0]
  } else if (time >= kfs[kfs.length - 1].time) {
    begin = kfs[kfs.length - 1]
    end = kfs[kfs.length - 1]
  } else {
    const index = kfs.findIndex(kf => kf.time > time)
    end = kfs[index]
    begin = kfs[index - 1]
  }
  return [begin, end]
}

export const getPercentage = (start, end, t) => {
  if (start >= end) {
    return 1
  }
  return 1 - minmax((end - t) / (end - start), 0, 1)
}

export const sub = (a, b) => [b[0] - a[0], b[1] - a[1]]

export const frameToMs = (frame, fps) => {
  const ms = (frame / fps) * 1000
  return Math.round(ms / 10) * 10
}

export const parseColor = (rgba, opacity) => {
  const { r, g, b, a } = rgba
  let alpha = a
  if (a === undefined) {
    alpha = 1
  }
  if (opacity !== undefined) {
    alpha = opacity / 100
  }
  return [r, g, b, alpha]
}

export const parseHex = hex => {
  try {
    const chunks = hex.match(/[a-fA-F0-9]{1,2}/g)
    const rgb = chunks.map(t => parseInt(t, 16) / 255)
    rgb.push(1)
    return rgb
  } catch (e) {
    console.error(e)
    return [0, 0, 0, 1]
  }
}

export const toPixelUnit = (val, key) => {
  if (key.endsWith('Unit')) {
    return Unit.PIXEL
  }
  return val
}

export const toPercentage = val => val / 100

export const getPaintType = gradientType => {
  const map = {
    [GradientFillType.LINEAR]: PaintType.GRADIENT_LINEAR,
    [GradientFillType.RADIAL]: PaintType.GRADIENT_RADIAL,
    [GradientStrokeType.LINEAR]: PaintType.GRADIENT_LINEAR,
    [GradientStrokeType.RADIAL]: PaintType.GRADIENT_RADIAL
  }
  return map[gradientType]
}

export const getWorldPosition = (element, overridePos) => {
  const position = overridePos || element.get('position')
  const pos = [position[0], position[1]]
  let parent = element.get('parent')
  while (parent) {
    const parentPos = parent.get('position')
    pos[0] += parentPos[0]
    pos[1] += parentPos[1]
    parent = parent.get('parent')
  }
  return pos
}

const PRGB_ELEMENTS = 4
export const parseGradientColors = (gradientColors, count) => {
  const prgbLength = count * PRGB_ELEMENTS // p, r, g, b
  const withOpacity = gradientColors.length > prgbLength
  const prgb = gradientColors.slice(0, prgbLength)
  const posAlphaMap = new Map()

  if (withOpacity) {
    const pa = gradientColors.slice(prgbLength)
    while (pa.length) {
      const p = pa.shift()
      const a = pa.shift()
      if (a !== undefined) {
        posAlphaMap.set(p, a)
      }
    }
  }

  const orderedPos = Array.from(posAlphaMap.keys()).sort((a, b) => a - b)
  const gradientStops = []
  while (prgb.length) {
    const p = prgb.shift()
    const r = prgb.shift()
    const g = prgb.shift()
    const b = prgb.shift()
    let a = 1
    if (posAlphaMap.size) {
      let beforeAlpha, afterAlpha, beforePosition, afterPosition
      // Iterate through the posAlphaMap to find surrounding alphas
      for (const pos of orderedPos) {
        if (pos <= p) {
          beforeAlpha = posAlphaMap.get(pos)
          beforePosition = pos
        } else if (pos >= p && afterAlpha === undefined) {
          afterAlpha = posAlphaMap.get(pos)
          afterPosition = pos
        }
      }
      if (beforeAlpha !== undefined && afterAlpha !== undefined) {
        const alphaDiff = afterAlpha - beforeAlpha
        const positionDiff = afterPosition - beforePosition
        a = beforeAlpha + (alphaDiff * (p - beforePosition)) / positionDiff
      } else if (beforeAlpha !== undefined) {
        a = beforeAlpha
      } else if (afterAlpha !== undefined) {
        a = afterAlpha
      }
    }
    const colorStop = new ColorStop({
      color: [r, g, b, a],
      position: p
    })
    gradientStops.push(colorStop)
  }

  const POSITION_ADJUSTMENT = 0.001 // The slight number to adjust by.
  const firstPosition = gradientStops[0].position

  // Check if all stops have the same position
  if (gradientStops.every(stop => stop.position === firstPosition)) {
    // If true, retain only the first and last stops
    const firstStop = gradientStops[0]
    const lastStop = gradientStops[gradientStops.length - 1]

    // Adjust the position of the first stop if it's at 1 or 0
    if (firstStop.position === 1) {
      firstStop.position = Math.max(0, firstStop.position - POSITION_ADJUSTMENT)
    } else if (lastStop.position === 0) {
      lastStop.position = Math.min(1, lastStop.position + POSITION_ADJUSTMENT)
    }

    // Clear the array and push only the first and last stops
    gradientStops.length = 0
    gradientStops.push(firstStop, lastStop)
  }
  return gradientStops
}

export const parseTrimMode = trimMode => {
  switch (trimMode) {
    case TrimMode.SIMULTANEOUSLY:
      return TrimPathMode.SIMULTANEOUSLY
    case TrimMode.INDIVIDUALLY:
      return TrimPathMode.INDIVIDUALLY
  }
}

export const parseTrimOffset = trimOffset => trimOffset / 3.6

export const parseBlendMode = (blendMode, normalAsPassThrough = false) => {
  switch (blendMode) {
    case BlendMode.NORMAL:
      if (normalAsPassThrough) {
        return PhaseBlendMode.PASS_THROUGH
      }
      return PhaseBlendMode.NORMAL
    case BlendMode.MULTIPLY:
      return PhaseBlendMode.MULTIPLY
    case BlendMode.SCREEN:
      return PhaseBlendMode.SCREEN
    case BlendMode.OVERLAY:
      return PhaseBlendMode.OVERLAY
    case BlendMode.DARKEN:
      return PhaseBlendMode.DARKEN
    case BlendMode.LIGHTEN:
      return PhaseBlendMode.LIGHTEN
    case BlendMode.COLOR_DODGE:
      return PhaseBlendMode.COLOR_DODGE
    case BlendMode.COLOR_BURN:
      return PhaseBlendMode.COLOR_BURN
    case BlendMode.HARD_LIGHT:
      return PhaseBlendMode.HARD_LIGHT
    case BlendMode.SOFT_LIGHT:
      return PhaseBlendMode.SOFT_LIGHT
    case BlendMode.DIFFERENCE:
      return PhaseBlendMode.DIFFERENCE
    case BlendMode.EXCLUSION:
      return PhaseBlendMode.EXCLUSION
    case BlendMode.HUE:
      return PhaseBlendMode.HUE
    case BlendMode.SATURATION:
      return PhaseBlendMode.SATURATION
    case BlendMode.COLOR:
      return PhaseBlendMode.COLOR
    case BlendMode.LUMINOSITY:
      return PhaseBlendMode.LUMINOSITY
    default:
      return PhaseBlendMode.PASS_THROUGH
  }
}

export const parseBezier = (bezier, mesh = new Mesh(), vertexIds = []) => {
  let lastVertex
  const firstIndex = mesh.vertices.size
  bezier.v.forEach((v, idx) => {
    const currentVertex = mesh.addVertex([v[0], v[1]])
    if (lastVertex) {
      const o = bezier.o[idx - 1]
      const i = bezier.i[idx]
      const edge = mesh.addEdge(lastVertex, currentVertex, {
        curve: [
          [o[0] + lastVertex.pos.x, o[1] + lastVertex.pos.y],
          [i[0] + currentVertex.pos.x, i[1] + currentVertex.pos.y]
        ]
      })
      vertexIds.push(edge.v.id, edge.cpV.id, edge.cpW.id, edge.w.id)
    }
    lastVertex = currentVertex
  })

  if (bezier.c) {
    const o = bezier.o[bezier.v.length - 1]
    const i = bezier.i[0]
    const firstVertex = [...mesh.vertices.values()][firstIndex]
    const edge = mesh.addEdge(lastVertex, firstVertex, {
      curve: [
        [o[0] + lastVertex.pos.x, o[1] + lastVertex.pos.y],
        [i[0] + firstVertex.pos.x, i[1] + firstVertex.pos.y]
      ]
    })
    vertexIds.push(edge.v.id, edge.cpV.id, edge.cpW.id, edge.w.id)
  }

  return mesh
}

export const parseMaskInvert = (mode, invert) => {
  switch (mode) {
    case MaskMode.Add:
      return invert
    case MaskMode.Subtract:
      return !invert
    default:
      return null
  }
}

/**
 *
 * @param {Element} group
 * @returns {AABB}
 */
export function getFullGroupSize(group) {
  const bbox = new AABB()
  if (!group.children || !(group.children.length > 0)) {
    return bbox
  }
  for (const child of group.children) {
    if (child.children && child.children.length > 0) {
      const childBBox = getFullGroupSize(child)
      bbox.minMax(childBBox)
    } else {
      const size = child.get('size')
      const position = child.get('position')
      bbox.minMax(new AABB(position[0], position[1], size[0], size[1]))
    }
  }
  return bbox
}

/**
 *
 * @param {import('@phase-software/data-store').DataStore} dataStore
 * @param {Element} element
 * @param {number} time
 * @param {number[]} start
 * @param {number[]} end
 * @returns {GradientPoint} Returns an object with new start and end base coordinates.
 */
export function getLocalStartEndAtTime(dataStore, element, time, start, end) {
  let inv
  if (time === 0) {
    inv = mat2d.invert(mat2d.create(), _getElementLocalTransform(element))
  } else {
    const elementId = element.get('id')
    const { translateX } = dataStore.transition.getPropertyValueByTime(elementId, 'translateX', time)
    const { translateY } = dataStore.transition.getPropertyValueByTime(elementId, 'translateY', time)
    const { rotation } = dataStore.transition.getPropertyValueByTime(elementId, 'rotation', time)
    const { scaleX } = dataStore.transition.getPropertyValueByTime(elementId, 'scaleX', time)
    const { scaleY } = dataStore.transition.getPropertyValueByTime(elementId, 'scaleY', time)
    const { contentAnchorX } = dataStore.transition.getPropertyValueByTime(elementId, 'contentAnchorX', time)
    const { contentAnchorY } = dataStore.transition.getPropertyValueByTime(elementId, 'contentAnchorY', time)
    const { width } = dataStore.transition.getPropertyValueByTime(elementId, 'width', time)
    const { height } = dataStore.transition.getPropertyValueByTime(elementId, 'height', time)
    let referencePointX = width * 0.5
    let referencePointY = height * 0.5
    if (element.canMorph) {
      const referencePointObj = element.getBaseValue('referencePoint')
      referencePointX = referencePointObj.referencePointX
      referencePointY = referencePointObj.referencePointY
    }
    inv = mat2d.invert(
      mat2d.create(),
      _composeTransform(
        referencePointX + contentAnchorX,
        referencePointY + contentAnchorY,
        scaleX,
        scaleY,
        rotation,
        translateX,
        translateY,
        width,
        height
      )
    )
  }

  return {
    startBase: vec2.transformMat2d(vec2.create(), start, inv),
    endBase: vec2.transformMat2d(vec2.create(), end, inv)
  }
}

/**
 * @param {Element} element
 * @returns {mat2d}
 */
function _getElementLocalTransform(element) {
  const translateObj = element.getBaseValue('translate')
  const rotation = element.getBaseValue('rotation').rotation
  const scaleObj = element.getBaseValue('scale')
  const referencePointObj = element.getBaseValue('referencePoint')
  const contentAnchorObj = element.getBaseValue('contentAnchor')
  return _composeTransform(
    referencePointObj.referencePointX + contentAnchorObj.contentAnchorX,
    referencePointObj.referencePointY + contentAnchorObj.contentAnchorY,
    scaleObj.scaleX,
    scaleObj.scaleY,
    rotation,
    translateObj.translateX,
    translateObj.translateY
  )
}

/**
 * @param {number} pivotX
 * @param {number} pivotY
 * @param {number} scaleX
 * @param {number} scaleY
 * @param {number} rotation
 * @param {number} translateX
 * @param {number} translateY
 * @returns {mat2d}
 */
function _composeTransform(pivotX, pivotY, scaleX, scaleY, rotation, translateX, translateY) {
  const pivotMat = mat2d.create()
  mat2d.translate(pivotMat, pivotMat, vec2.fromValues(-pivotX, -pivotY))
  const scaleRotateMat = mat2d.create()
  mat2d.scale(scaleRotateMat, scaleRotateMat, vec2.fromValues(scaleX, scaleY))
  mat2d.rotate(scaleRotateMat, scaleRotateMat, rotation)
  // Align the renderer's transform rotation calculations
  const tmp = scaleRotateMat[1]
  scaleRotateMat[1] = -scaleRotateMat[2]
  scaleRotateMat[2] = -tmp
  // ----------------------------------------------------
  const translateMat = mat2d.create()
  mat2d.fromTranslation(translateMat, vec2.fromValues(translateX, translateY))
  const theTransform = mat2d.create()
  mat2d.multiply(theTransform, scaleRotateMat, pivotMat)
  mat2d.multiply(theTransform, translateMat, theTransform)
  return theTransform
}

export const parseBooleanType = mergeMode => {
  switch (mergeMode) {
    case MergeMode.NORMAL:
      return BooleanOperation.NONE
    case MergeMode.ADD:
      return BooleanOperation.UNION
    case MergeMode.SUBTRACT:
      return BooleanOperation.SUBTRACT
    case MergeMode.INTERSECT:
      return BooleanOperation.INTERSECT
    case MergeMode.EXCLUDE:
      return BooleanOperation.DIFFERENCE
  }
}

export const parseCap = lineCapType => {
  switch (lineCapType) {
    default:
      return CapShape.NONE
  }
}

export const parseEnds = lineCapType => {
  switch (lineCapType) {
    case LineCapType.ROUND:
      return EndShape.ROUND
    default:
      return EndShape.STRAIGHT
  }
}

export const parseJoin = lineJoinType => {
  switch (lineJoinType) {
    case LineJoinType.MITER:
      return JoinShape.MITER
    case LineJoinType.ROUND:
      return JoinShape.ROUND
    case LineJoinType.BEVEL:
      return JoinShape.BEVEL
  }
}

const shapeSet = new Set([ShapeType.ELLIPSE, ShapeType.GROUP, ShapeType.PATH, ShapeType.RECTANGLE, ShapeType.STAR])
export const isShapeShape = shape => shapeSet.has(shape.type)

const nonGroupShapeSet = new Set([ShapeType.ELLIPSE, ShapeType.PATH, ShapeType.RECTANGLE, ShapeType.STAR])
export const isNonGroupShapeShape = shape => nonGroupShapeSet.has(shape.type)

export const FlagsEnum = Object.freeze({
  SELECTED: 1,
  CURVE_VERT: 2, // Only for vertices
  CONNECT_SELECTED: 4 //
})

export const getVertexKfValue = (oldV, newV) => ({
  id: oldV.id,
  pos: [newV.pos[0], newV.pos[1]],
  mirror: newV.mirror
})

export const parsePathMorphing = (mesh, arrKfs, arrVertexIds) => {
  const offsets = []
  const kfsData = new Map()
  for (let i = 0; i < arrKfs.length; i++) {
    const arrExternalVertexId = arrVertexIds[i]
    for (const kf of arrKfs[i]) {
      if (typeof kf.value[0] === 'string') {
        console.warn(`Damaged data field of PathMorphing`)
        continue
      }
      const arrInnerVertexId = []
      const newMesh = parseBezier(kf.value[0], undefined, arrInnerVertexId)
      const kfValues = []
      const createdSet = new Set()
      for (let j = 0; j < arrInnerVertexId.length; j++) {
        const externalVertexId = arrExternalVertexId[j]
        const innerVertexId = arrInnerVertexId[j]
        if (
          mesh.cellTable.has(externalVertexId) &&
          newMesh.cellTable.has(innerVertexId) &&
          !createdSet.has(innerVertexId)
        ) {
          kfValues.push(getVertexKfValue(mesh.cellTable.get(externalVertexId), newMesh.cellTable.get(innerVertexId)))
          createdSet.add(innerVertexId)
        }
      }
      const newBounds = newMesh.bounds
      offsets.push({
        ...kf,
        value: [newBounds.min[0], newBounds.min[1]]
      })
      if (kfsData.has(kf.time)) {
        const conflictData = kfsData.get(kf.time)
        conflictData.value.push(...kfValues)
      } else {
        kfsData.set(kf.time, { ...kf, value: kfValues })
      }
    }
  }

  return {
    offsets,
    kfs: [...kfsData.values()]
  }
}

/**
 * Gets the element type.
 * @param   {Element} node
 * @returns {ElementType}
 */
export const getElementType = node => {
  const elementType = node.get('elementType')
  switch (elementType) {
    case ElementType.PATH:
    case ElementType.MASK_CONTAINER:
    case ElementType.BOOLEAN_CONTAINER:
      return elementType
    case ElementType.CONTAINER: {
      if (node.isMaskGroup()) {
        return ElementType.MASK_CONTAINER
      } else if (node.isBooleanType()) {
        return ElementType.BOOLEAN_CONTAINER
      } else {
        return ElementType.CONTAINER
      }
    }
  }
}

export const getDashValues = (list, refList, mergedList = []) => {
  const count = Math.max(list.length, refList.length)
  const values = list.map(o => o.value)
  mergedList.push(...list)
  while (values.length < count) {
    const index = values.length
    values.push(refList[index].value)
    mergedList.push(refList[index])
  }
  return values
}

export const createKeyFrameByTimeMap = (propList, easingByTimeMap = new Map()) => {
  const keyFrameByTimeMap = propList.reduce((map, prop, index) => {
    prop.kfs.forEach(keyFrame => {
      const key = keyFrame.time
      if (!map.has(key)) {
        map.set(key, Array(index).fill(undefined))
      }
      const list = map.get(key)
      list[index] = keyFrame
      if (!easingByTimeMap.has(key)) {
        easingByTimeMap.set(key, { step: keyFrame.step, bezier: keyFrame.bezier })
      }
    })
    return map
  }, new Map())
  return keyFrameByTimeMap
}

export const interpolateKeyFrameValues = (keyFrameList, prevList, nextList, fallbackValueList, fallbackTime) => {
  const linear = [0.25, 0.25, 0.75, 0.75]
  const values = []
  keyFrameList.forEach((keyFrame, idx) => {
    if (keyFrame) {
      values[idx] = keyFrame.value[0]
    } else {
      const prevKeyFrame = prevList[idx]
      const nextKeyFrame = nextList[idx]
      const start = prevKeyFrame?.value[0] ?? fallbackValueList[idx]
      const end = nextKeyFrame?.value[0] ?? fallbackValueList[idx]
      const startTime = prevKeyFrame?.time ?? 0
      const endTime = nextKeyFrame?.time ?? fallbackTime
      const p = getPercentage(startTime, endTime, fallbackTime)
      const value = getBezierValue(start, end, nextKeyFrame?.bezier ?? linear, p)
      values[idx] = value
    }
  })
  return values
}

export const parseDashList = (list, refList, timeOffset) => {
  const mergedList = []
  const dashValues = getDashValues(list, refList, mergedList)

  const easingByTimeMap = new Map()
  const keyFrameByTimeMap = createKeyFrameByTimeMap(mergedList, easingByTimeMap)

  const sortedTimeList = [...keyFrameByTimeMap.keys()].sort((a, b) => a - b)
  const kfs = sortedTimeList.map((time, index, arr) => {
    const prevTime = arr[index - 1]
    const nextTime = arr[index + 1]
    const currList = keyFrameByTimeMap.get(time)
    const prevList = keyFrameByTimeMap.get(prevTime) || currList
    const nextList = keyFrameByTimeMap.get(nextTime) || currList

    const values = interpolateKeyFrameValues(currList, prevList, nextList, dashValues, time)

    while (values.length < mergedList.length) {
      const ref = refList[values.length]
      const [start, end] = findSegment(ref, time)
      const p = getPercentage(start.time, end.time, time)
      const value = getBezierValue(start.value[0], end.value[0], end.bezier, p)
      values.push(value)
    }
    return {
      time: timeOffset + time,
      value: values,
      ...easingByTimeMap.get(time)
    }
  })

  return {
    value: dashValues,
    kfs
  }
}

/**
 * Calculate the gradient point of an element based on its morphability, geometry and mesh properties.
 * @typedef {object} GradientPoint
 * @property {number[]} startBase - The new start base coordinates, an array where the first item is the x coordinate, and the second item is the y coordinate.
 * @property {number[]} endBase - The new end base coordinates, an array where the first item is the x coordinate, and the second item is the y coordinate.
 */
/**
 * @param {Element} element - The element to calculate the gradient point for.
 * @param {Array} startBase - An array where the first item is the starting x coordinate, and the second item is the starting y coordinate.
 * @param {Array} endBase - An array where the first item is the ending x coordinate, and the second item is the ending y coordinate.
 * @param {number} width - The width of the element
 * @param {number} height - The height of the element
 * @returns {GradientPoint} Returns an object with new start and end base coordinates.
 */
export function calculateGradientPoint(element, startBase, endBase, width, height) {
  if (!Array.isArray(startBase) || !Array.isArray(endBase) || Number.isNaN(width) || Number.isNaN(height)) {
    console.warn(
      `calculateGradientPoint has wrong parameters: start: ${startBase}, end: ${endBase}, width: ${width}, height: ${height}`
    )
  }
  let newStartBase = [...startBase]
  let newEndBase = [...endBase]

  if (element.canMorph) {
    const geometry = element.get('geometry')

    if (!geometry) {
      return { startBase: newStartBase, endBase: newEndBase }
    }

    const mesh = geometry.get('mesh')

    if (!mesh) {
      return { startBase: newStartBase, endBase: newEndBase }
    }

    const {
      min: { x, y }
    } = mesh.bounds

    newStartBase = [startBase[0] - x, startBase[1] - y]
    newEndBase = [endBase[0] - x, endBase[1] - y]
  } else {
    const defaultOriginX = width * 0.5
    const defaultOriginY = height * 0.5

    newStartBase = [startBase[0] + defaultOriginX, startBase[1] + defaultOriginY]
    newEndBase = [endBase[0] + defaultOriginX, endBase[1] + defaultOriginY]
  }

  return { startBase: newStartBase, endBase: newEndBase }
}

export const base64ToBlob = base64 => {
  // Extract MIME type from the base64 string
  const mimeMatch = /^data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*$/.exec(base64)
  if (!mimeMatch) {
    throw new Error('Invalid base64 string')
  }
  const contentType = mimeMatch[1]

  // Remove the data URL prefix and decode the base64 string
  const base64Data = base64.replace(/^data:[a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+;base64,/, '')
  const byteCharacters = atob(base64Data)

  // Convert decoded data into an array of bytes
  const byteNumbers = new Array(byteCharacters.length)
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i)
  }

  // Convert array of bytes into a Blob
  const byteArray = new Uint8Array(byteNumbers)
  const blob = new Blob([byteArray], { type: contentType })

  return blob
}
