import { DataStore } from '@phase-software/data-store'

const createDataStore = (data) => {
  const dataStore = new DataStore(data)
  if (process.env.NODE_ENV !== 'production') {
    window.__DEBUG__DATA_STORES__ = dataStore
  }
  return dataStore
}

const dataStore = createDataStore()

export default dataStore
export * from './constants'
export { getActionData } from './parser/Action'
export { getConditionData } from './parser/Condition'
export { getDataStoreData } from './parser/DataStore'
export { getDimensionsData } from './parser/Dimensions'
export { getElementData } from './parser/Element'
export { getElementTrackData } from './parser/ElementTrack'
export { getEventData } from './parser/Event'
export { getFontData } from './parser/Font'
export { getGeometryData } from './parser/Geometry'
export { getLayerData } from './parser/Layer'
export { getOpacityData } from './parser/Opacity'
export { getPaintData } from './parser/Paint'
export { getPositionData } from './parser/Position'
export { getPresetData } from './parser/Preset'
export { getRotationData } from './parser/Rotation'
export { getScrollData } from './parser/Scroll'
export { getSequenceData } from './parser/Sequence'
export { getStyleData } from './parser/Style'
export { getStyleTrackData } from './parser/StyleTrack'
export { getTextAlignmentData } from './parser/TextAlignment'
export { getTextDecorationData } from './parser/TextDecoration'
export { getTextDirectionData } from './parser/TextDirection'
export { getTextSpacingData } from './parser/TextSpacing'
export { getTransitionData } from './parser/Transition'
export { getTransitionWrapperData } from './parser/TransitionWrapper'
export { getTriggerData } from './parser/Trigger'
export { getWorkspaceData } from './parser/Workspace'
export { getInteractionData } from './parser/Interaction'
export { getEditorData } from './parser/Editor'

// simple import from file
// USE WITH CAUTION

/** @type {object} deserialized data */
let importedData = null

export function getImportedData() {
  return importedData
}

/**
 * @param {string | null} data serialized data
 */
export function setImportedData(data) {
  if (!data) {
    importedData = null
  } else {
    importedData = JSON.parse(data)
  }
}
