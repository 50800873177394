import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../Icon'

const Selector = ({ active, invalid, text, icon, errorMessage, ...rest }) => {
  let textColor = 'text-neutral-30 hover:text-white'
  if (text || active) {
    textColor = 'text-white'
  }
  if (invalid) {
    textColor = 'text-gold'
  }
  return (
    <div
      className={`relative group flex items-center justify-flex-start flex-grow min-w-0 text-12 h-24 px-8 rounded bg-neutral-60 hover:bg-neutral ${textColor}`}
      {...rest}
    >
      {active || !icon ? <Icon name="Crosshair" className="mr-4" /> : icon}
      <div className="truncate min-w-0">{active ? 'Selecting…' : text ?? 'Select'}</div>
      {invalid && errorMessage && (
        <div className="hidden group-hover:block absolute top-full left-1/2 transform -translate-x-1/2 mt-4 px-4 py-2 rounded bg-gold text-dark-overlay-80 z-10 whitespace-nowrap">
          {errorMessage}
        </div>
      )}
    </div>
  )
}

Selector.propTypes = {
  active: PropTypes.bool,
  invalid: PropTypes.bool,
  text: PropTypes.string,
  icon: PropTypes.node,
  errorMessage: PropTypes.string
}

Selector.defaultProps = {
  active: false,
  invalid: false,
  errorMessage: ''
}

export default React.memo(Selector)
