import React from 'react'
import { ReactionProps, ReactionUserProps } from './types'
import Tooltip from '../Tooltip'
import ReactionTooltipContentWithData from '../../Comment/ReactionTooltipContentWithData'

interface ReactionWithDataProps {
  reaction: ReactionProps
  currentUserId: string
}

const Reaction = ({ reaction, currentUserId }: ReactionWithDataProps) => {
  const isCurrentUserInReactionUsers = (reactionUsers: ReactionUserProps[]): boolean => {
    return reactionUsers.some((user: ReactionUserProps) => user.id === currentUserId)
  }

  const renderReactionContent = (reaction: ReactionProps): React.ReactElement => {
    return (
      <div>
        <div className="flex justify-center leading-6 text-h4">{reaction.emoji}</div>
        <div className="font-medium">
          {reaction.users.map((user: ReactionUserProps, index: number) => {
            return <ReactionTooltipContentWithData key={user.id} userId={user.id} showSeparator={index !== 0} />
          })}
        </div>
        {/* // TODO: Replace the :test text */}
        {/* // Currently using a static emoji text due to uncertainty about using emoji-picker-react. */}
        {/* // Once it's confirmed to use emoji-picker-react, this part should be replaced to ensure consistent emoji display. */}
        <div className="flex justify-center text-light-overlay-60">:test</div>
      </div>
    )
  }

  return (
    <Tooltip customContent={renderReactionContent(reaction)} key={reaction.emoji}>
      <div
        className={`flex gap-4 rounded-xl px-6 py-2 cursor-pointer text-white ${
          isCurrentUserInReactionUsers(reaction.users)
            ? ' bg-primary-overlay-40 hover:bg-primary-overlay-60'
            : ' bg-light-overlay-5 hover:bg-light-overlay-10'
        }`}
      >
        <div>{reaction.emoji}</div>
        <div className="text-10">{reaction.users.length}</div>
      </div>
    </Tooltip>
  )
}

export default Reaction
