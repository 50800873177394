/* eslint-disable jsdoc/check-alignment */
import { ChangeType, PointShape, IDType } from '@phase-software/types'
/** @todo It's critical that we align the calculations in this package with the renderer's transform calculations. Please, let's make this happen! */
import { vec2 } from 'gl-matrix'
import { Vector2 } from '../Vector2'
import { AABB } from '../AABB'
import { EPSILON, vec2Equals } from '../commons'
import { Undoable } from '../Undoable'
import { Change, reverseEntityChange, EntityChange } from '../Changes'
import { findControlPoints, isCollinear, isCollinearSegemet } from '../utils'
import { FAKE_IDS, MIN_SIZE_THRESHOLD } from '../constants'
import { id } from '../id'
import { Vertex } from './Vertex'
import { Edge } from './Edge'
import { Contour } from './Contour'
import { MeshHelper } from './MeshHelper'
import { FlagsEnum } from './Cell'
import { MeshUtils, swapVertexAndBase } from './MeshUtils'

/** @typedef {import('./Cell').Cell} Cell */
/** @typedef {import('./Edge').HalfEdge} HalfEdge */
/** @typedef {import('./Vertex').VertexData} VertexData */
/** @typedef {import('./Edge').EdgeData} EdgeData */
/** @typedef {import('./Contour').ContourData} ContourData */

export class Mesh extends Undoable {
    /**
     * Create new instance of the Mesh, or make a copy of existing one
     * @param {object} [options]
     * @param {boolean} [options.autoIncrementIds]   - use auto incremented integers as IDs
     *                                            for Vertices, Edges and Contours
     */
    constructor({ autoIncrementIds = false } = {}) {
        super(undefined, ChangeType.ENTITY, 'MESH_CHANGES')
        /** @type {Map<string, Cell>} Restore all *appeared* cells no matter if they were deleted or not */
        this.cellTable = new Map()
        /** @type {Set<Vertex>} */
        this.vertices = new IndexedSet()
        /** @type {Set<Edge>} */
        this.edges = new IndexedSet()
        /** @type {Set<Contour>} */
        this.contours = new IndexedSet()
        /** @type {AABB} */
        this.bounds = new AABB()
        /** @type {Set<Vertex>} */
        this.virtualVertices = new IndexedSet()
        /** @type {Set<Edge>} */
        this.virtualEdges = new IndexedSet()
        /** @type {Set<Contour>} */
        this.virtualContours = new IndexedSet()

        // for debugging and testing purposes
        if (autoIncrementIds) {
            this._ids = {
                vertices: 0,
                edges: 0,
                contours: 0
            }
        }

        this._meshHelper = new MeshHelper(this)
        this._isCollinear = false
        this.id = id(IDType.MESH)
    }

    get isEmpty() {
        return this.bounds.isInfinite
    }

    get isCollinear() {
        return this._isCollinear
    }
    /**
    * Create Mesh instance from serialized data
    * @param {Mesh} mesh
    * @param  {MeshData} data
    */
    // addFromData : { vertices, edges, contours } using the same id to create edge, vertex and contours
    static addFromData(mesh, { vertices, edges, contours }) {
        const vertexIds = MeshUtils.processVertices(mesh, vertices, true)
        const edgeIds = MeshUtils.processEdges(mesh, edges, vertexIds, true)
        MeshUtils.processContours(mesh, contours, edgeIds)
        mesh.recalculateBounds()
    }
    /**
     * Create Mesh instance from serialized data
     * @param  {MeshData} data
     * @returns {Mesh}
     */
    static fromData({ vertices, edges, contours }) {
        const mesh = new Mesh()
        const vertexIds = MeshUtils.processVertices(mesh, vertices)
        const edgeIds = MeshUtils.processEdges(mesh, edges, vertexIds)
        MeshUtils.processContours(mesh, contours, edgeIds)
        mesh.recalculateBounds()
        return mesh
    }

    /**
     * Creates a copy of this Mesh
     * @param {boolean} [keepID]
     * @param {Map<string, VertexData>} basePath
     * @returns {Mesh} new mesh instance
     */
    copy(keepID = false, basePath) {
        const copy = new Mesh()
        const vMap = new Map()
        const halfMap = new Map()

        // TODO: copy virtual structures as well

        for (const v of this.vertices) {
            const newV = v.copy()

            if (keepID) {
                newV.id = v.id
            }

            if (basePath && basePath.has(v.id)) {
                const baseVertex = basePath.get(v.id)
                newV.pos.x = baseVertex.pos[0]
                newV.pos.y = baseVertex.pos[1]
                newV.mirror = baseVertex.mirror
            }
            vMap.set(v, newV)
            copy.vertices.add(newV)
            copy.cellTable.set(newV.id, newV)
        }

        for (const copyVertex of copy.vertices) {
            if (copyVertex.unlinkedCurveControl != null) {
                copyVertex.unlinkedCurveControl = vMap.get(copyVertex.unlinkedCurveControl)
            }
            if (copyVertex.adjacentMainVertex != null) {
                copyVertex.adjacentMainVertex = vMap.get(copyVertex.adjacentMainVertex)
            }
        }

        for (const e of this.edges) {
            const copyV = vMap.get(e.v)
            const copyCpV = vMap.get(e.cpV)
            const copyCpW = vMap.get(e.cpW)
            const copyW = vMap.get(e.w)
            if (!copyV || !copyCpV || !copyCpW || !copyW) {
                console.warn('The edge can not get its own vertex in the mesh, please check your duplicate function or mesh id in case the instance of mesh is replaced incorrectly')
                continue
            }
            const newE = e.copy(vMap.get(e.v), vMap.get(e.cpV), vMap.get(e.cpW), vMap.get(e.w))
            if (keepID) {
                newE.id = e.id
            }
            halfMap.set(e.halves[0], newE.halves[0])
            halfMap.set(e.halves[1], newE.halves[1])
            copy.edges.add(newE)
            copy.cellTable.set(newE.id, newE)
        }

        // FIXME: Remove this once QAs are available to test
        for (const c of this.contours) {
            const newC = c.copy()
            if (keepID) {
                newC.id = c.id
            }
            if ([...c.halves].some(half => !halfMap.get(half) || !halfMap.get(half).edge)) {
                console.warn('The contour can not get its own edge in the mesh, please check your duplicate function or mesh id in case the instance of mesh is replaced incorrectly')
                continue
            }
            newC.halves = new Set([...c.halves].map(half => halfMap.get(half)))
            copy.contours.add(newC)
            copy.cellTable.set(newC.id, newC)
        }

        copy.bounds.copy(this.bounds)
        return copy
    }


    /**
    * The Figma style no longer meets our animation requirements, but we don't have time to update the unit tests.
    * The unit tests aren't flexible enough to solely verify the algorithm.
    * @returns {MeshData}
    */
    saveFigmaData() {
        return {
            vertices: [...this.vertices]
                .filter(v => !v.isFlagged(FlagsEnum.CURVE_VERT))
                .map(v => {
                    const vData = v.save(true)
                    if (v.upperTierIDs.size === 1) { // We won't save the curve which doesn't belong any edge
                        /** @type {Edge} */
                        const edge = this.cellTable.get(v.upperTierIDs.values().next().value)

                        vData.mirror =
                            (
                                edge &&
                                edge.id &&
                                edge.isCurve
                            ) ? PointShape.INDEPENDENT : PointShape.NONE
                    }
                    return vData
                }),
            edges: [...this.edges].map(e => e.save(true)),
            contours: [...this.contours].map(c => c.save())
        }
    }


    /**
     * Serialize Mesh structure
     * @param {Map<string, VertexData> } basePath
     * @returns {MeshData}
     */
    save(basePath) {
        const vertices = new Array()
        const edges = new Array()
        const contours = new Array()
        
        if (basePath) {
            swapVertexAndBase(this.cellTable, basePath)
        }

        // Serialize vertices, edges, and contours
        for (const v of this.vertices){
            vertices.push(v.save())
        }
        for (const e of this.edges){
            edges.push(e.save())
        }
        for (const c of this.contours){
            contours.push(c.save())
        }

        if (basePath) {
            // Restore original vertex positions after serialization without saving
            swapVertexAndBase(this.cellTable, basePath)
        }

        return { vertices, edges, contours }
    }

    /**
     * Returns iterator over mesh Vertices
     * @param {object} [options]
     * @param {object} [options.virtual = false]  iterates only over virtual Vertices
     * @param {object} [options.all = false]      iterates over all Vertices
     * @yields {Vertex}
     */
    *getVertices({ virtual = false, all = false } = {}) {
        if (all || !virtual) {
            for (const v of this.vertices) {
                if (v.isFlagged(FlagsEnum.CURVE_VERT)) continue
                yield v
            }
        }
        if (all || virtual) {
            for (const v of this.virtualVertices) {
                yield v
            }
        }
    }

    /**
     * Returns iterator over mesh Edges
     * @param {object} [options]
     * @param {object} [options.virtual = false]  iterates only over virtual Edges
     * @param {object} [options.all = false]      iterates over all Edges
     * @yields {Edge}
     */
    *getEdges({ virtual = false, all = false } = {}) {
        if (all || !virtual) {
            for (const e of this.edges) {
                yield e
            }
        }
        if (all || virtual) {
            for (const e of this.virtualEdges) {
                yield e
            }
        }
    }

    /**
     * Returns iterator over mesh Contours
     * @param {object} [options]
     * @param {object} [options.virtual = false]  iterates only over virtual Contours
     * @param {object} [options.all = false]      iterates over all Contours
     * @yields {Contour}
     */
    *getContours({ virtual = false, all = false } = {}) {
        if (all || !virtual) {
            for (const c of this.contours) {
                yield c
            }
        }
        if (all || virtual) {
            for (const c of this.virtualContours) {
                yield c
            }
        }
    }

    /**
     * Returns total number of Vertices in this Mesh
     * @param {object} [options]
     * @param {object} [options.virtual = false]  includes only number of virtual Vertices
     * @param {object} [options.all = false]      includes both real and virtual Vertices
     * @returns {number}
     */
    getNumVertices({ virtual = false, all = false } = {}) {
        let num = 0
        if (all || !virtual) {
            num += this.vertices.size
        }
        if (all || virtual) {
            num += this.virtualVertices.size
        }
        return num
    }

    /**
     * Returns total number of Edges in this Mesh
     * @param {object} [options]
     * @param {object} [options.virtual = false]  includes only number of virtual Edges
     * @param {object} [options.all = false]      includes both real and virtual Edges
     * @returns {number}
     */
    getNumEdges({ virtual = false, all = false } = {}) {
        let num = 0
        if (all || !virtual) {
            num += this.edges.size
        }
        if (all || virtual) {
            num += this.virtualEdges.size
        }
        return num
    }

    /**
     * Returns total number of Contours in this Mesh
     * @param {object} [options]
     * @param {object} [options.virtual = false]  includes only number of virtual Contours
     * @param {object} [options.all = false]      includes both real and virtual Contours
     * @returns {number}
     */
    getNumContours({ virtual = false, all = false } = {}) {
        let num = 0
        if (all || !virtual) {
            num += this.contours.size
        }
        if (all || virtual) {
            num += this.virtualContours.size
        }
        return num
    }

    /**
     * Finds first vertex closest to the specified point
     *  (within the optional epsilon proximity)
     * @param {Vector2} pos                          position of the point
     * @param {object} [options]
     * @param {number} [options.epsilon=0.0001]      proximity (default is 0.0001)
     * @param {boolean} [options.virtual=false]         if true, only searches virtual vertices
     * @param {boolean} [options.all=false]             if true, searches both real and virtual vertices
     * @returns {Vertex}                             closest vertex
     */
    findVertex(pos, options = { epsilon: EPSILON }) {
        return this.findVertices(pos, options).next().value
    }

    /**
     * Returns iterator over all the vertices closest to the specified point (within the optional epsilon proximity)
     * @param {Vector2} pos                          position of the point
     * @param {object} [options]
     * @param {number} [options.epsilon=0.0001]      proximity (default is 0.0001)
     * @param {boolean} [options.virtual=false]         if true, only searches virtual vertices
     * @param {boolean} [options.all=false]             if true, searches both real and virtual vertices
     * @yields {Vertex}             vertices that are within proximity to the point pos
     */
    *findVertices(pos, {
        epsilon = EPSILON,
        virtual = false,
        all = false,
    } = { epsilon: EPSILON }) {
        if (all || !virtual) {
            for (const v of this.vertices.values()) {
                if (vec2Equals(v.pos, pos, epsilon)) {
                    yield v
                }
            }
        }
        if (all || virtual) {
            for (const v of this.virtualVertices.values()) {
                if (vec2Equals(v.pos, pos, epsilon)) {
                    yield v
                }
            }
        }
    }

    /**
     * Wipes previous contour records and enumerates all existing contours again
     * by traversing the Mesh
     */
    detectContours() {
        // TODO: should we care about virtual contours here?
        for (const c of this.contours) {
            c.disconnect()
            this._deleteContour(c)
        }

        for (const halves of this._meshHelper.findContours()) {
            this.addContour(halves)
        }
    }

    /**
     * Resolve self-intersections and create resulting virtual Vertices, Edges, and Contours
     * Erases all previous virtual structures.
     */
    selfIntersect() {
        this.deleteVirtualStructures()
        this._meshHelper.selfIntersect()
    }

    /**
     * Resets and recalculates bounds of the whole Mesh
     * @returns {AABB}  new recalculated bounds
     */
    recalculateBounds() {
        this.bounds.reset()
        for (const vertex of this.getVertices()) {
            if (vertex.isFlagged(FlagsEnum.CURVE_VERT)) {
                continue
            }
            this.bounds.minMax(vertex.pos)
        }
        // Initialize the collinear parameters
        this._isCollinear = true
        for (const edge of this.edges) {
            if (!isCollinearSegemet(this, edge)) {
                this._isCollinear = false
                edge.updateBounds()
                this._updateBounds(edge.bounds) // Only curve needs update because we already used vertex
            }
        }
        if (this._isCollinear) {
            this._validateCollinear()
        }
        return this.bounds
    }


    _validateCollinear() {
        const edgesArray = Array.from(this.edges.values())
        if (edgesArray.length < 2) {
            return // With 0 or 1 edge, they're trivially collinear
        }

        const pointA = edgesArray[0].v.pos
        const pointB = edgesArray[0].w.pos
        const vec1 = [pointB.x - pointA.x, pointB.y - pointA.y]

        for (let i = 1; i < edgesArray.length; i++) {
            const pointC1 = edgesArray[i].v.pos
            const pointC2 = edgesArray[i].w.pos
            const vec2 = [pointC2.x - pointC1.x, pointC2.y - pointC1.y]

            if (!isCollinear(vec1[0], vec1[1], vec2[0], vec2[1])) {
                this._isCollinear = false
                return
            }
        }
    }

    /**
     * Check if Vertex exists in this Mesh
     * @param  {Vertex}  v
     * @returns {boolean}
     */
    hasVertex(v) {
        return this.vertices.has(v) || this.virtualVertices.has(v)
    }

    /**
     * Check if Edge exists in this Mesh
     * @param  {Edge}  e
     * @returns {boolean}
     */
    hasEdge(e) {
        return this.edges.has(e) || this.virtualEdges.has(e)
    }

    /**
     * Check if Contour exists in this Mesh
     * @param  {Contour}  c
     * @returns {boolean}
     */
    hasContour(c) {
        return this.contours.has(c) || this.virtualContours.has(c)
    }

    /**
     * Add Vertex at the point `pos`
     * @param {Vector2}  pos
     * @param {object} [options]
     * @param {object} [options.data]           data that can be provided to Vertex#set()
     * @param {boolean} [options.virtual=false]     set to true if adding a virtual Vertex
     * @param {boolean} [options.findExisting=false]  if true and there's an existing vertex at
     *                                               position `pos`, returns an existing one
     * @param {number} [options.epsilon=0.0001]    proximity (default is 0.0001)
     * @returns {Vertex}
     */
    addVertex(pos, {
        data = undefined,
        virtual = false,
        findExisting = false,
        epsilon = EPSILON
    } = { epsilon: EPSILON }) {
        let v
        if (findExisting) {
            v = this.findVertices(pos, { epsilon, virtual })
        } else {
            v = new Vertex(virtual)
            if (this._ids) {
                v.id = id(IDType.MESH)
            }
            v.set({ ...data, pos })
            this._addVertex(v)
            this.cellTable.set(v.id, v)
        }
        return v
    }

    /**
     * Adds a new edge connecting two vertices. If a position provided as second argument
     * instead of existing vertex, then a new vertex is added at that position as well
     * @param {Vertex}  v
     * @param {Vertex}  w
     * @param {object} [options]
     * @param {Vector2[]} [options.curve=null]
     * @param {boolean} [options.virtual=false]
     * @returns {Edge}
     */
    addEdge(v, w, {
        curve = null,
        virtual = false
    } = { curve: null }) {
        if (!v || !w || !this.hasVertex(v)) {
            return
        }
        if (!virtual && (v.isVirtual || w.isVirtual)) {
            throw new Error('Not allowed to add a real edge to virtual vertices')
        }
        const _w = this.hasVertex(w) ? w : this.addVertex(w, virtual)

        const edge = new Edge(virtual)
        if (this._ids) {
            edge.id = id(IDType.MESH)
        }
        const cpV = this.addVertex(curve ? curve[0] : v.pos, virtual)
        this.cellTable.set(cpV.id, cpV)
        const cpW = this.addVertex(curve ? curve[1] : w.pos, virtual)
        this.cellTable.set(cpW.id, cpW)
        edge.set({ v, cpV, cpW, w: _w }, curve !== null)
        this._addEdge(edge)

        this._updateBounds(edge.bounds)
        this.cellTable.set(edge.id, edge)
        // TODO: track contours
        return edge
    }

    /**
     * Manualy add a closed Contour to Mesh.
     * @param {Set<HalfEdge>}  halves
     * @param {object} [options]
     * @param {boolean} [options.virtual=false]
     * @returns {Contour}
     */
    addContour(halves, { virtual = false } = {}) {
        const c = new Contour(virtual)
        if (this._ids) {
            c.id = id(IDType.MESH)
        }
        c.set({ isClosed: true, halves })
        this._addContour(c)
        return c
    }

    /**
     * Adds a vertex to an existing edge, spliting it in two edges. Original edge
     *  will be removed if isVirtual === false.
     *  Splits will be made in order
     *  (v,splitPoints[0]), (splitPoints[1], splitPoints[2]), ..., (splitPoints[-1], w)
     * @param {Edge} edge
     * @param {number[]} fractions          list of fractions (in range [0, 1] each) along the
     *                                          length of the edge at edge need to be split
     * @param {object} [options]
     * @param {boolean} [options.virtual=false]      set to true to make new edges and vertices virtual
     * @param {boolean} [options.findExisting=false]   if true, will use existing vertices found
     *                                                at split positions (if any)
     * @returns {Edge[]} new edges created after split
     */
    splitEdge(edge, fractions, {
        virtual = false,
        findExisting = false
    } = { virtual: false, findExisting: false }) {
        if (!edge || !fractions || fractions.length === 0) {
            return
        }
        const { atPositions, newCurves } = edge.split(fractions)

        const newVerts = []
        for (const pos of atPositions) {
            newVerts.push(this.addVertex(pos, { virtual, findExisting }))
        }

        const newEdges = []
        newEdges.push(this.addEdge(edge.v, newVerts[0], {
            curve: newCurves[0],
            virtual
        }))
        for (let i = 0; i < newVerts.length - 1; i++) {
            newEdges.push(this.addEdge(newVerts[i], newVerts[i + 1], {
                curve: newCurves[i],
                virtual
            }))
        }
        newEdges.push(this.addEdge(newVerts[newVerts.length - 1], edge.w, {
            curve: newCurves[newCurves.length - 1],
            virtual
        }))

        // if not virtual split - remove original edge
        if (!virtual) {
            edge.disconnect()
            this._deleteEdge(edge)
        }
        // if virtual splits - add virtual edges references to real edge
        else {
            edge.addVirtualEdges(newEdges)
        }
        return newEdges
    }

    /**
     * @param {Vertex} vertex
     */
    deleteVertex(vertex) {
        let success
        if (vertex.isVirtual) {
            success = this.virtualVertices.delete(vertex)
        } else {
            success = this.vertices.delete(vertex)
        }
        if (success) {
            for (const edge of vertex.getEdges()) {
                edge.disconnect()
                if (edge.isVirtual) {
                    this.virtualEdges.delete(edge)
                } else {
                    this.edges.delete(edge)
                }
            }
        }
        // TODO: mark contours for recalculation
    }

    /**
     * Delete cells by vertex
     * @param {Vertex[]} vertices
     * @param {boolean} undoable
     * @todo Sort cells by type when supporting Edge, Contour editing
     */
    deleteCellsByVertex(vertices, undoable = true) {
        /** @type {Set<string>} */
        const idList = new Set()
        /** @type {Edge[]} */
        const connectedEdge = []
        /** @type {Set<Vertex>} */
        const curveCtrlHandlers = new Set()

        for (const vertex of vertices) {
            if (vertex.isFlagged(FlagsEnum.CURVE_VERT)) {
                curveCtrlHandlers.add(vertex)
            } else {
                for (const uppperTireID of vertex.upperTierIDs) {
                    idList.add(uppperTireID)
                    connectedEdge.push(this.cellTable.get(uppperTireID))
                }
                if (vertex.unlinkedCurveControl) {
                    idList.add(vertex.unlinkedCurveControl)
                }
                // if the vertex includes a fake curve control then removes it, so the TM
                // will remove its KF data
                if (this.cellTable.has(FAKE_IDS.CURVE_CONTROL)) {
                    idList.add(this.cellTable.get(FAKE_IDS.CURVE_CONTROL).id)
                }
                idList.add(vertex.id)
            }
        }

        /** @type {Map<string, number>} */
        const numVisisteds = new Map()
        for (const edge of connectedEdge) {
            // Processing curve control handler
            idList.add(edge.cpV.id)
            idList.add(edge.cpW.id)
            // Processing connected vertex
            const vertex = idList.has(edge.v.id) ? edge.w : edge.v
            let numVisisted = 0
            if (numVisisteds.has(vertex.id)) {
                numVisisted = numVisisteds.get(vertex.id)
            }
            numVisisted++
            if (numVisisted === vertex.upperTierIDs.size) {
                idList.add(vertex.id)
                if (vertex.unlinkedCurveControl) {
                    idList.add(vertex.unlinkedCurveControl)
                }
            } else {
                numVisisteds.set(vertex.id, numVisisted)
            }
            // Processing connect contour
            for (const contourId of edge.upperTierIDs) {
                idList.add(contourId)
            }
        }

        /** @type {EntityChange} */
        const changes = this.changes
        /** @type {Vertex[]} */
        const remainVertices = []
        for (const curveCtrl of curveCtrlHandlers) {
            const connVertex = curveCtrl.adjacentMainVertex
            remainVertices.push(connVertex)
            const change = new Change({
                before: new Vector2(curveCtrl.pos),
                after: new Vector2(connVertex.pos)
            })

            changes.update(curveCtrl.id, 'pos', change)

        }

        for (const remainVertex of remainVertices) {
            let isStraight = true

            for (const edgeId of remainVertex.upperTierIDs) {
                /** @type {Edge} */
                const edge = this.cellTable.get(edgeId)
                if (edge.isCurve) {
                    isStraight = false
                    break
                }
            }

            let unlinkedCurveControl = null
            if (remainVertex.unlinkedCurveControl && this.cellTable.has(remainVertex.unlinkedCurveControl)) {
                unlinkedCurveControl = this.cellTable.get(remainVertex.unlinkedCurveControl)
            }
            if (
                unlinkedCurveControl &&
                this.vertices.has(unlinkedCurveControl) &&
                !idList.has(remainVertex.unlinkedCurveControl) &&
                unlinkedCurveControl.pos.eq(remainVertex.pos)
            ) {
                isStraight = false
            }

            const mirrorChange = new Change({
                before: remainVertex.mirror,
                after: isStraight ? PointShape.NONE : PointShape.INDEPENDENT
            })
            changes.update(remainVertex.id, 'mirror', mirrorChange)
        }
        changes.delete(idList)
        this.applyChanges(this.changes)
        this.fire(undoable)
    }
    deleteEdge(/* edge */) {
        // TODO:
    }

    deleteContour(/* contour */) {
        // TODO:
    }

    deleteVirtualStructures() {
        const options = { virtual: true }
        for (const c of this.getContours(options)) {
            c.disconnect()
            this._deleteContour(c)
        }
        for (const e of this.getEdges(options)) {
            e.disconnect()
            this._deleteEdge(e)
        }
        for (const v of this.getVertices(options)) {
            this._deleteVertex(v)
        }
    }

    /**
     * Trims empty space between the vertex positions and bounds.
     */
    trim() {
        const delta = new Vector2(this.bounds.min)
        if (!vec2.equals(delta, Vector2.ZERO)) {
            for (const v of this.vertices) {
                const pos = vec2.sub(v.pos, v.pos, delta)
                v.set({ pos })
            }
        }
        this.recalculateBounds()
    }


    // ----------------------------

    /**
     * @param {AABB} edgeBounds
     */
    _updateBounds(edgeBounds) {
        this.bounds.minMax(edgeBounds)
    }

    /**
     * @param {Vertex} v
     */
    _addVertex(v) {
        if (v.isVirtual) {
            this.virtualVertices.add(v)
        } else {
            this.vertices.add(v)
        }
    }

    /**
     * @param {Edge} e
     */
    _addEdge(e) {
        if (e.isVirtual) {
            this.virtualEdges.add(e)
        } else {
            this.edges.add(e)
        }
    }

    /**
     * @param {Contour} c
     */
    _addContour(c) {
        if (c.isVirtual) {
            this.virtualContours.add(c)
        } else {
            this.contours.add(c)
        }
    }

    /**
     * @param {Vertex} v
     */
    _deleteVertex(v) {
        if (v.isVirtual) {
            this.virtualVertices.delete(v)
        } else {
            this.vertices.delete(v)
        }
    }

    /**
     * @param {Edge} e
     */
    _deleteEdge(e) {
        if (e.isVirtual) {
            this.virtualEdges.delete(e)
        } else {
            this.edges.delete(e)
        }
    }

    /**
     * @param {Contour} c
     */
    _deleteContour(c) {
        if (c.isVirtual) {
            this.virtualContours.delete(c)
        } else {
            this.contours.delete(c)
        }
    }

    /**
     * Move the vertices by offsets and the size of the element space
     * @param {Vertex[]} vertices The vertices we want to move
     * @param {number} offsetX the offset in the x component
     * @param {number} offsetY the offset in the y component
     * @param {bool} [undoable=true]
     * @param {bool} [fire=true]
     */
    moveVertex(vertices, offsetX, offsetY, undoable = true, fire = true) {
        if (!vertices || !(vertices.length > 0)) {
            console.warn(`Empty parameter of moveVertex`)
            return
        }
        const offset = new Vector2(offsetX, offsetY)

        if (Math.abs(offsetX) < MIN_SIZE_THRESHOLD && Math.abs(this.bounds.width) < MIN_SIZE_THRESHOLD) {
            offset.x = 0
        }

        if (Math.abs(offsetY) < MIN_SIZE_THRESHOLD && Math.abs(this.bounds.height) < MIN_SIZE_THRESHOLD) {
            offset.y = 0
        }
        
        this._changePointShapesWhenMoving(vertices)

        for (const vertex of vertices) {
            this._preparePositionUpdate(vertex.id, offset)
            //#region Move curves
            if (vertex.isFlagged(FlagsEnum.CURVE_VERT)) {
                this._prepareMirrorCurveUpdates(vertex, offset)
            } else {
                this._prepareConnectedCurveUpdates(vertex, offset)
            }
            //#endregion
        }
        this.applyChanges(this.changes)
        if (fire) {
            this.fire(undoable)
        }
    }

    /**
     * Note: This function will not submit the change
     * Changes to a vertex's position and move their associated vertices
     * @param {Vertex} vertex - The target vertex whose position needs to be changed.
     * @param {number} x
     * @param {number} y
     * @returns {void} 
     * 
     * @throws {Error} - Throws an error if certain conditions are not met, e.g., unexpected vertex IDs.
     */
    situateVertexToPos(vertex, x, y) {
        const newPos = new Vector2(x, y)
        const change = new Change({
            before: new Vector2(vertex.pos),
            after: newPos
        })
        /** @type {EntityChange} changes */
        const changes = this.changes
        changes.update(vertex.id, 'pos', change)

        const internalOffset = new Vector2(newPos.x - vertex.pos.x, newPos.y - vertex.pos.y)
        //#region Move curves
        if (vertex.isFlagged(FlagsEnum.CURVE_VERT)) {
            this._prepareMirrorCurveUpdates(vertex, internalOffset)
        } else {
            this._prepareConnectedCurveUpdates(vertex, internalOffset)
        }
        //#endregion
    }

    /**
     * 
     * @param {Vertex[]} vertices 
     */
    _changePointShapesWhenMoving(vertices) {
        sharedVerticesSet.clear()
        for (const vertex of vertices) {
            if (!vertex.isFlagged(FlagsEnum.CURVE_VERT)) continue

            const wedgeVert = vertex.adjacentMainVertex

            if (vertices.indexOf(wedgeVert) === -1) {
                if ((
                        sharedVerticesSet.has(wedgeVert.id) &&
                        wedgeVert.mirror !== PointShape.INDEPENDENT
                    ) ||
                    wedgeVert.upperTierIDs.size > 2
                ) {
                    const change = new Change({
                        before: wedgeVert.mirror,
                        after: PointShape.INDEPENDENT
                    })
                    this.changes.update(wedgeVert.id, 'mirror', change)
                } else {
                    sharedVerticesSet.add(wedgeVert.id)
                }
            }
        }
    }

    /**
     * Updates the position of a curve control handle's corresponding mirror based on movement of the initial handle.
     * This function shows the disavandatges of using the edge-based curve control data structure
     * @param {Vertex} movingCurveHandle - The curve handle that is being moved.
     * @param {Vector2} movementOffset - The vector by which the handle has moved.
     */
    _prepareMirrorCurveUpdates(movingCurveHandle, movementOffset) {
        const pivotVertex = movingCurveHandle.adjacentMainVertex

        const isMirrorInvalid = pivotVertex.mirror !== PointShape.ANGLE_AND_LENGTH &&
            pivotVertex.mirror !== PointShape.ANGLE
        const hasExcessiveUpperTiers = pivotVertex.upperTierIDs.size > 2
        if (isMirrorInvalid || hasExcessiveUpperTiers) return
        // Curve controls are attached on the edge not the pivot vertex
        let mirrorEdgeId = null

        // Number of the edges connected to the pivot vertex
        switch (pivotVertex.upperTierIDs.size) {
            case 1:
                if (!pivotVertex.unlinkedCurveControl || pivotVertex.unlinkedCurveControl === movingCurveHandle.id) {
                    mirrorEdgeId = pivotVertex.upperTierIDs.values().next().value
                } else {
                    this._moveMirrorCurveControl(
                        this.cellTable.get(pivotVertex.unlinkedCurveControl),
                        movementOffset,
                        movingCurveHandle,
                        pivotVertex)
                    return
                }
                break
            case 2:
                {
                    if (pivotVertex.unlinkedCurveControl) {
                        console.warn('Unexpected unlinkedCurveControl detected; check the loading process.')
                    }
                    const edgeIDs = Array.from(pivotVertex.upperTierIDs)
                    mirrorEdgeId = edgeIDs.find(id => id !== movingCurveHandle.controllingEdge.id)
                }
                break
        }

        if (mirrorEdgeId) {
            const mirrorEdge = this.cellTable.get(mirrorEdgeId)
            const isPivotVertexAligned = mirrorEdge.v === pivotVertex
            const mirrorCurveControl = mirrorEdge.curve[isPivotVertexAligned ? 0 : 1]

            this._moveMirrorCurveControl(
                mirrorCurveControl,
                movementOffset,
                movingCurveHandle,
                pivotVertex
            )
        }
    }


    /**
     * 
     * @param {Vertex} oppositeCurveControl 
     * @param {Vector2} localOffset 
     * @param {Vertex} movingCurveHandle 
     * @param {Vector2} wedgeVert 
     */
    _moveMirrorCurveControl(oppositeCurveControl, localOffset, movingCurveHandle, wedgeVert) {
        const mirrorDir = new Vector2()
        vec2.add(mirrorDir, movingCurveHandle.pos, localOffset) // the position hasn't changed
        vec2.sub(mirrorDir, mirrorDir, wedgeVert.pos)

        mirrorDir.x *= -1
        mirrorDir.y *= -1

        if (wedgeVert.mirror === PointShape.ANGLE) {
            vec2.sub(curveHandleBuffer, oppositeCurveControl.pos, wedgeVert.pos)
            const norm = vec2.length(curveHandleBuffer)
            vec2.normalize(mirrorDir, mirrorDir)
            mirrorDir.x *= norm
            mirrorDir.y *= norm
        }

        const newCurveValue = new Vector2()
        vec2.add(newCurveValue, wedgeVert.pos, mirrorDir)
        const change = new Change({
            before: new Vector2(oppositeCurveControl.pos),
            after: newCurveValue
        })
        this.changes.update(oppositeCurveControl.id, 'pos', change)
    }

    /**
     * 
     * @param {Vertex} vertex 
     * @param {Vector2} offset 
     */
    _prepareConnectedCurveUpdates(vertex, offset) {
        if (vertex.unlinkedCurveControl) {
            this._preparePositionUpdate(vertex.unlinkedCurveControl, offset)
        }

        for (const edgeId of vertex.upperTierIDs) {
            /** @type {Edge} */
            const edge = this.cellTable.get(edgeId)
            const curveIndex = (edge.v.id === vertex.id) ? 0 : 1
            this._preparePositionUpdate(edge.curve[curveIndex].id, offset)
        }
    }
    /**
     *
     * @param {EntityChange} changes
     */
    applyCreateChanges(changes) {
        //#region Add
        /** @type {Map<string, Set<Cell>>} */
        const addedCategory = new Map()
        addedCategory.set('Vertex', new Set())
        addedCategory.set('Edge', new Set())
        addedCategory.set('Contour', new Set())
        for (const id of changes.CREATE) {
            const cell = this.cellTable.get(id)
            addedCategory.get(cell.type).add(cell)
        }
        for (const cell of addedCategory.get('Vertex')) {
            this.vertices.add(cell)
        }
        for (const cell of addedCategory.get('Edge')) {
            this.edges.add(cell)
            /** @type {Edge} edge */
            const edge = cell
            edge.v.connectEdge(edge)
            edge.w.connectEdge(edge)
        }
        for (const cell of addedCategory.get('Contour')) {
            this.contours.add(cell)
            /** @type {Contour} contour */
            const contour = cell
            for (const half of contour.halves) {
                half.edge.upperTierIDs.add(contour.id)
            }
        }
        //#endregion
    }

    /**
     *
     * @param {EntityChange} changes
     */
    applyDeleteChanges(changes) {
        //#region  Delete
        /** @type {Map<string, Set<Cell>>} */
        const deletedCategory = new Map()
        deletedCategory.set('Vertex', new Set())
        deletedCategory.set('Edge', new Set())
        deletedCategory.set('Contour', new Set())
        for (const id of changes.DELETE) {
            const cell = this.cellTable.get(id)
            deletedCategory.get(cell.type).add(cell)
        }
        for (const cell of deletedCategory.get('Contour')) {
            this.contours.delete(cell)
            /** @type {Contour} contour */
            const contour = cell
            for (const half of contour.halves) {
                half.edge.upperTierIDs.delete(contour.id)
            }
        }
        for (const cell of deletedCategory.get('Edge')) {
            this.edges.delete(cell)
            /** @type {Edge} edge */
            const edge = cell
            edge.v.disconnectEdge(edge)
            edge.w.disconnectEdge(edge)
        }
        for (const cell of deletedCategory.get('Vertex')) {
            this.vertices.delete(cell)
        }
        //#endregion
    }

    /**
     *
     * @param {EntityChange} changes
     */
    applyUpdateChanges(changes) {
        //#region Update
        const dirtyEdgeIDs = new Set()
        const dirtyVertices = new Set()
        for (const [id, updateChanges] of changes.UPDATE) {
            const cell = this.cellTable.get(id)
            for (const [propKey, change] of updateChanges) {
                if (propKey === 'pos') {
                    cell[propKey].copy(change.after)
                    dirtyVertices.add(cell)
                } else {
                    cell[propKey] = change.after
                }
            }
        }

        for (const vertex of dirtyVertices) {
            if (vertex.isFlagged(FlagsEnum.CURVE_VERT)) {
                if (vertex.controllingEdge) {
                    dirtyEdgeIDs.add(vertex.controllingEdge.id)
                }
            } else {
                for (const edgeId of vertex.upperTierIDs) {
                    dirtyEdgeIDs.add(edgeId)
                }
            }
        }

        //#endregion
        for (const edgeId of dirtyEdgeIDs) {
            /** @type {Edge} */
            const edge = this.cellTable.get(edgeId)
            edge.updateBounds()
        }
    }

    /**
     *
     * @param {EntityChange} changes
     */
    applyChanges(changes) {
        if (changes.CREATE.size !== 0) this.applyCreateChanges(changes)
        if (changes.DELETE.size !== 0) this.applyDeleteChanges(changes)
        if (changes.UPDATE.size !== 0) this.applyUpdateChanges(changes)
        this.recalculateBounds()
    }

    /**
     * 
     * @param {PointChange[]} changes 
     */
    applyVerticesPosition(changes) {
        const innerChanges = new EntityChange()
        for (const change of changes) {
            const vertex = this.cellTable.get(change.id)
            if (change.mirror !== undefined && change.mirror !== null) {
                innerChanges.update(change.id, 'mirror', new Change({
                    before: vertex.mirror,
                    after: change.mirror
                }))
            }
            if (change.x !== undefined && change.y !== undefined) {
                innerChanges.update(change.id, 'pos', new Change({
                    before: new Vector2(vertex.pos),
                    after: new Vector2(change.x, change.y)
                }))
            }
        }
        this.applyChanges(innerChanges)
    }


    /**
     *
     * @param {EntityChange} changes
     */
    undo(changes) {
        const reversedChanges = reverseEntityChange(changes)
        this.applyChanges(reversedChanges)
        this.changes = reversedChanges
        this.fire(false, { undoable: false, interaction: false })
    }

    /**
     *
     * @param {EntityChange} changes
     */
    redo(changes) {
        this.applyChanges(changes)
        this.changes = changes
        this.fire(false)
    }

    /**
     * Get the position of the vertex in the meshTransformmeshTransform[0,size] region,
     * with the extension transform of class Geometry if existing
     * @param {string} id
     * @param {Float32Array} [container]
     * @returns {Float32Array}
     */
    getVertPos(id, container = null) {
        const pos = this.cellTable.get(id).pos // It should be always existing position
        const result = container || new Vector2()
        result[0] = pos.x - this.bounds.min.x
        result[1] = pos.y - this.bounds.min.y
        return result
    }

    /**
     * Get the position of the curves in the meshTransformmeshTransform[0,size] region,
     * with the extension transform of class Geometry if existing
     * @param {string} id
     * @param {Float32Array[]} [container]
     * @returns {Float32Array[]}
     */
    getEdgeCurve(id, container = null) {
        /** @type {Edge} */
        const edge = this.cellTable.get(id)
        const result = container || [new Vector2(), new Vector2()]
        const [curveV, curveW] = edge.curve
        this.getVertPos(curveV.id, result[0])
        this.getVertPos(curveW.id, result[1])
        return result
    }

    /**
     * Get the clousre of single cell
     * @param {Cell} cell
     * @param {Set<Cell>} [outputSet] Assign it if the searching is shared between other cell inputs.
     * @param {Map<Cell, number>} [numVisitedChildren] Assign it if the searching is shared between other cell inputs.
     * @returns {Set<Cell>}
     */
    closure(cell, outputSet = new Set(), numVisitedChildren = new Map()) {
        const stk = [cell]
        while (stk.length > 0) {
            const currCell = stk.pop()
            outputSet.add(currCell)
            if (currCell.type === 'Edge') {
                /** @type {Edge} */
                const currEdge = currCell
                outputSet.add(currEdge.v)
                outputSet.add(currEdge.w)
            }
            for (const upperTierID of currCell.upperTierIDs) {
                const upperTier = this.cellTable.get(upperTierID)
                if (outputSet.has(upperTier)) continue
                if (currCell.type === 'Edge') {
                    numVisitedChildren.set(upperTier,
                        numVisitedChildren.get(upperTier) ? numVisitedChildren.get(upperTier) + 1 : 1)
                    /** @type {Contour} */
                    const starContour = upperTier
                    // if all edges are visited, this contour is composed by inputs
                    if (starContour.numEdges - numVisitedChildren.get(upperTier) === 0) {
                        stk.push(upperTier)
                    }
                } else {
                    stk.push(upperTier)
                }
            }

        }
        return outputSet
    }

    /**
     * Get the closure of the cells
     * @param {Cell[]} cells
     * @returns {Set<Cell>}
     */
    closureOfCells(cells) {
        /** @type {Cell[]} */
        const outputSet = new Set()
        /** @type {Map<Cell, number>} */
        const numVisitedChildren = new Map()
        for (const cell of cells) {
            this.closure(cell, outputSet, numVisitedChildren)
        }
        return outputSet
    }

    /**
     * @param {Cell[]} cellArray
     * @param {positions} [positions]  optional map of new positions for every cell
     * @param {boolean} [undoable]
     * @returns {Cell[]} list of duplicated cells
     */
    duplicateCells(cellArray, positions = {}, undoable = true) {
        const dispatchList = new Map()
        dispatchList.set('Contour', [])
        dispatchList.set('Edge', [])
        dispatchList.set('Vertex', [])
        for (let i = 0; i < cellArray.length; i++) {
            const celltype = cellArray[i].type
            dispatchList.get(celltype).push(cellArray[i])
        }

        /** @type {Record<string, Vertex>} Original ID => Cell */
        const vertexIds = {}
        /** @type {Record<string, Edge>} Original ID => Cell */
        const edgeIds = {}
        const createList = []
        const duplicateVertices = []
        //#region Vertex
        const vertices = dispatchList.get('Vertex')
        for (const v of vertices) {
            const vertex = Vertex.fromData(v)
            if (this.cellTable.has(vertex.id)) {
                vertex.id = id(IDType.MESH)
            }
            if (positions[v.id]) {
                vertex.pos.copy(positions[v.id])
            }
            createList.push(vertex.id)
            this.cellTable.set(vertex.id, vertex)
            duplicateVertices.push(vertex)
            vertexIds[v.id] = vertex // Original ID
        }
        //#endregion

        //#region Edge
        const edges = dispatchList.get('Edge')
        for (const e of edges) {
            const edge = Edge.fromData({ 
                id: id(IDType.MESH),
                v: vertexIds[e.v],
                cpV: this._createAndRecordVertex(createList),
                cpW: this._createAndRecordVertex(createList),
                w: vertexIds[e.w]
            }, false)

            if (positions[e.id]) {
                for (let i = 0; i < 2; i++) {
                    const posChange = new Change({
                        before: new Vector2(edge.curve[i].pos),
                        after: new Vector2(positions[e.id][i][0], positions[e.id][i][1])
                    })
                    this.changes.update(edge.curve[i].id, 'pos', posChange)
                }

            }
            createList.push(edge.id)
            this.cellTable.set(edge.id, edge)
            edgeIds[e.id] = edge // Original ID
        }
        //#endregion

        //#region Contour
        const contours = dispatchList.get('Contour')
        for (const c of contours) {
            const contour = Contour.fromData({
                id: id(IDType.MESH),
                isClosed: c.isClosed,
                isFilled: c.isFilled,
                isHole: c.isHole
            })

            // connect contours to edges
            for (let i = 0; i < c.halves[0].length; i++) {
                const h = edgeIds[c.halves[0][i]].halves[c.halves[1][i]]
                const e = h.edge
                e.upperTierIDs.add(contour.id)
                contour.addHalfEdge(h)
            }
            createList.push(contour.id)
            this.cellTable.set(contour.id, contour)
        }
        //#endregion

        /** @type {EntityChange} */
        const changes = this.changes
        changes.create(createList)
        this.applyChanges(this.changes)
        this.fire(undoable)
        return duplicateVertices
    }

    /**
     * Start a new vertex
     * @param {number} x the x component of target coordinate in the element space
     * @param {number} y the y component of target coordinate in the element space
     * @param {boolean} undoable
     * @returns {Cell} the vertex that created via this function
     */
    startNewPath(x, y, undoable = true) {
        const idList = []
        const w = this._createAndRecordVertex(idList)
        const pos = new Vector2()
        if (this.isEmpty) {
            pos.x = 0
            pos.y = 0
            this.bounds.minMax(pos)
        } else {
            pos.x = x
            pos.y = y
        }
        w.set({ pos })

        /** @type {EntityChange} */
        const changes = this.changes
        changes.create(idList)
        this.applyChanges(this.changes)
        this.fire(undoable)
        return w
    }

    /**
     * @typedef {object} CreatedCell
     * @property {Cell} vertex The vertex that created
     * @property {Cell} edge The edge that created
     * @property {Cell} curveCtrl The curve control on the edge and near by the vertex
     */

    /**
     * Append a line to the selected vertex
     * @param {string} cellId
     * @param {number} x the x component of target coordinate in the element space
     * @param {number} y the y component of target coordinate in the element space
     * @param {boolean} mirrorPrevEdge Mirror the previous curve if the parameter is true
     * @param {boolean} undoable
     * @returns {CreatedCell} the vertex and edge that created via this function
     */
    appendLine(cellId, x, y, mirrorPrevEdge, undoable = true) {
        const createIdList = []
        /** @type {Vertex} */
        const v = this.cellTable.get(cellId)
        const w = this._createAndRecordVertex(createIdList)
        const pos = new Vector2(x, y)

        w.set({ pos })

        const [cpV, cpW] = this._setupEdgeCurves(v, w, mirrorPrevEdge, createIdList)
        const e = new Edge()
        e.set({ v, cpV, cpW, w }, true)
        this.cellTable.set(e.id, e)
        createIdList.push(e.id)
        const curveCtrlArray = e.curve
        // Keep the mirror
        if (v.upperTierIDs.size === 1 &&
            !curveCtrlArray[0].pos.eq(v.pos) &&
            v.mirror === PointShape.ANGLE_AND_LENGTH
        ) {
            // Create
            const newCurveCtrl = this._createAndRecordVertex(createIdList)
            newCurveCtrl.set({
                pos: v.pos,
                cap: v.cap,
                end: v.end,
                join: v.join,
                mirror: PointShape.ANGLE_AND_LENGTH
            })
            newCurveCtrl.cornerRadius = v.cornerRadius
            newCurveCtrl.flag(FlagsEnum.CURVE_VERT)
            // Link
            newCurveCtrl.adjacentMainVertex = v
            const keepCurveCtrlChange = new Change({
                before: v.unlinkedCurveControl,
                after: newCurveCtrl.id
            })
            this.changes.update(v.id, 'unlinkedCurveControl', keepCurveCtrlChange)
            // Position
            const dir = new Vector2()
            vec2.subtract(dir, v.pos, curveCtrlArray[0].pos)
            const newPos = new Vector2()
            vec2.add(newPos, v.pos, dir)
            const moveCurveChange = new Change({
                before: new Vector2(newCurveCtrl.pos),
                after: newPos
            })
            this.changes.update(newCurveCtrl.id, 'pos', moveCurveChange)
        }
        /** @type {EntityChange} */
        const changes = this.changes
        changes.create(createIdList)
        this.applyChanges(this.changes)
        this.fire(undoable)
        return { vertex: w, edge: e, curveCtrl: curveCtrlArray[1] }
    }

    /**
     * Connect the selected vertex to the clicked cell
     * @param {string} vId
     * @param {string} wId
     * @param {boolean} mirror Mirror the previous curve if the parameter is true
     * @param {boolean} undoable
     * @returns {CreatedCell|null} the created edge
     */
    connectVertices(vId, wId, mirror, undoable = true) {
        if (vId === wId) return null
        let createList = []
        const deleteList = []
        /** @type {Vertex} */
        const v = this.cellTable.get(vId)
        /** @type {Vertex} */
        const w = this.cellTable.get(wId)
        for (const upperTierID of v.upperTierIDs) {
            /** @type {Edge} */
            const edge = this.cellTable.get(upperTierID)
            if ((edge.w.id === w.id || edge.v.id === w.id)) {
                // Skip connecting the two vertices if they're already connected by a line segment
                if (edge.isStraight && !v.unlinkedCurveControl)
                    return null
            }
        }
        if (w.unlinkedCurveControl) {
            // Change the point shape
            const pointShapeChange = new Change({
                before: w.mirror,
                after: (w.upperTierIDs.size === 1 && mirror)
                    ? PointShape.ANGLE_AND_LENGTH
                    : PointShape.INDEPENDENT
            })
            this.changes.update(w.id, 'mirror', pointShapeChange)
        }
        // Edge creation
        const [cpV, cpW] = this._setupEdgeCurves(v, w, mirror, createList)
        const edge = new Edge()
        edge.set({ v, cpV, cpW, w }, true)
        this.cellTable.set(edge.id, edge)
        createList.push(edge.id)
        const curveCtrlArr = edge.curve
        this.changes.create(createList)
        this.applyChanges(this.changes)
        this.fire(undoable)
        // Another event
        createList = []
        // Contour creation
        for (const contour of this.contours) {
            deleteList.push(contour.id)
        }
        for (const halves of this._meshHelper.findContours()) {
            const c = new Contour(false)
            c.set({ isClosed: true, isFilled: true, halves })
            this.cellTable.set(c.id, c)
            createList.push(c.id)
        }
        this.changes.create(createList)
        this.changes.delete(deleteList)
        this.applyChanges(this.changes)
        this.fire(undoable)
        return { vertex: null, edge: edge, curveCtrl: curveCtrlArr[1] }
    }

    /**
     * 
     * @param {Vertex} v 
     * @param {Vertex} w 
     * @param {boolean} mirror
     * @param {string[]} createIDList
     * @returns {[Vertex, Vertex]} curve The output curve
     */
    _setupEdgeCurves(v, w, mirror, createIDList) {
        const vertices = [v, w]
        const curves = []
        for (let i = 0; i < 2; i++) {
            const currentVertex = vertices[i]
            if (currentVertex.unlinkedCurveControl) {
                curves.push(this.cellTable.get(currentVertex.unlinkedCurveControl))
                // Remove the special relationship between the curve handle and the vertex first
                const rmFromVertexChange = new Change({
                    before: currentVertex.unlinkedCurveControl,
                    after: null
                })
                this.changes.update(
                    currentVertex.id,
                    'unlinkedCurveControl',
                    rmFromVertexChange
                )
                if (!mirror) {
                    this.changes.update(
                        curves[i].id,
                        'pos',
                        new Change({
                            before: new Vector2(curves[i].pos),
                            after: new Vector2(currentVertex.pos)
                        })
                    )
                }
            } else {
                const curveControl = this._createAndRecordVertex(createIDList)
                curveControl.set({ pos: currentVertex.pos })
                curves.push(curveControl)
            }
        }
        return curves
    }

    /**
     * Prepares changes to vertex properties based on the provided key and value, storing them for later application.
     * 
     * @param {string} vertexId - ID of the vertex to be modified.
     * @param {string} key - Property key of the vertex to be changed.
     * @param {*} value - New value to assign to the specified vertex property.
     * 
     * @returns {void}
     * 
     * Notes:
     * - The function performs safety checks to ensure the provided vertex ID exists and is visible.
     * - Depending on the key, changes are prepared with specific behaviors.
     * - To apply the changes, call this.applyChanges(this.changes) followed by this.fire(undoable).
     * - All vertices need to be added to mesh change (a member of the mesh class) before applying.
     * 
     * @throws {Error} Throws an error for unknown vertex IDs.
     * @throws {Warning} Warns if the vertex ID is not visible or if an unexpected key is provided.
    */
    prepareVertexPropertyChanges(vertexId, key, value) {
        const vertex = this.cellTable.get(vertexId)
        if (!vertex) {
            console.error(`constructVertexPropertiesChanges: Unknown vertex id: ${vertexId}`)
            return
        }
        if (!this.vertices.has(vertex)) {
            console.warn(`constructVertexPropertiesChanges: Invisible vertex id: ${vertexId}`)
            return
        }

        const change = new Change({
            before: vertex[key],
            after: value
        })
        /** @todo Refactor this */
        switch (key) {
            case 'cornerRadius':
                this._spreadVertProp(vertex, key, change, vertexId)
                break
            case 'mirror':
                {
                    let updateVert = vertex
                    if (vertex.isFlagged(FlagsEnum.CURVE_VERT)) {
                        updateVert = vertex.adjacentMainVertex
                    }
                    this.changes.update(updateVert.id, 'mirror', change)
                    this.processPointShape(updateVert, value)
                }

                break
            default:
                console.warn(`Unexpected key ${key} for constructVertexPropertiesChanges`)
        }
    }

    /**
     * 
     * @param {Vertex} vertex 
     * @param {PointShape} value 
     */
    processPointShape(vertex, value) {
        if (vertex.isFlagged(FlagsEnum.CURVE_VERT)) return

        if (vertex.upperTierIDs.size === 1) { // Endpoint
            this._processPointShapeEndpoint(vertex, value)
        } else if (vertex.upperTierIDs.size === 2) { // Normal case
            this._processPointShapeRegular(vertex, value)
        } else {
            this._processPointShapeVectorNetworks(vertex, value)
        }
    }

    _processPointShapeVectorNetworks(vertex, value) {
        /** @todo Extract long method */
        if (vertex.mirror !== PointShape.NONE && value === PointShape.NONE) {
            for (const edgeId of vertex.upperTierIDs) {
                /** @type {Edge} */
                const edge = this.cellTable.get(edgeId)
                this._setCurveControlPos(edge, vertex, edge.v === vertex ? edge.v.pos : edge.w.pos)
            }
        } else if (vertex.mirror === PointShape.NONE && value !== PointShape.NONE) {
            let maxLen = 0

            if (value === PointShape.ANGLE_AND_LENGTH) {
                for (const edgeId of vertex.upperTierIDs) {
                    const edge = this.cellTable.get(edgeId)
                    const dir = new Vector2()
                    vec2.subtract(dir, (edge.v === vertex ? edge.w : edge.v).pos, vertex.pos)
                    maxLen = Math.max(maxLen, vec2.len(dir))
                }
            }
            let i = 0
            const step = (Math.PI * 2) / vertex.upperTierIDs.size
            for (const edgeId of vertex.upperTierIDs) {
                const edge = this.cellTable.get(edgeId)
                const dir = new Vector2()
                let edgeLen
                if (value === PointShape.ANGLE_AND_LENGTH) {
                    edgeLen = 0.2 * maxLen
                } else {
                    const edgeVec = new Vector2()
                    vec2.subtract(edgeVec, (edge.v === vertex ? edge.w : edge.v).pos, vertex.pos)
                    edgeLen = 0.2 * vec2.len(edgeVec)
                }
                dir.x = Math.sin(i * step)
                dir.y = Math.cos(i * step)
                vec2.scale(dir, dir, edgeLen)
                vec2.subtract(dir, vertex.pos, dir)
                this._setCurveControlPos(edge, vertex, dir)
                i++
            }

        } else if (vertex.mirror !== PointShape.ANGLE_AND_LENGTH && value === PointShape.ANGLE_AND_LENGTH) {
            let maxCurveLen = 0

            for (const edgeId of vertex.upperTierIDs) {
                /** @type {Edge} */
                const edge = this.cellTable.get(edgeId)
                const dir = new Vector2()
                vec2.subtract(dir, (edge.v === vertex ? edge.w : edge.v).pos, vertex.pos)
                const curve = this.cellTable.get(edge.curve[edge.v === vertex ? 0 : 1])
                vec2.subtract(dir, curve.pos, vertex.pos)
                maxCurveLen = Math.max(maxCurveLen, vec2.len(dir))
            }

            let i = 0
            const step = (Math.PI * 2) / vertex.upperTierIDs.size
            for (const edgeId of vertex.upperTierIDs) {
                const edge = this.cellTable.get(edgeId)
                const dir = new Vector2()

                dir.x = Math.sin(i * step)
                dir.y = Math.cos(i * step)
                vec2.scale(dir, dir, maxCurveLen)
                vec2.subtract(dir, vertex.pos, dir)
                this._setCurveControlPos(edge, vertex, dir)
                i++
            }

        } else if (
            (vertex.mirror !== PointShape.ANGLE && vertex.mirror !== PointShape.ANGLE_AND_LENGTH) &&
            value === PointShape.ANGLE
        ) {
            let i = 0
            const step = (Math.PI * 2) / vertex.upperTierIDs.size
            for (const edgeId of vertex.upperTierIDs) {
                /** @type {Edge} */
                const edge = this.cellTable.get(edgeId)
                const dir = new Vector2()
                let edgeLen
                const curve = edge.curve[edge.v === vertex ? 0 : 1]

                if (curve && this.vertices.has(curve)) {
                    const curveVec = new Vector2()
                    vec2.subtract(curveVec, curve.pos, vertex.pos)
                    edgeLen = vec2.len(curveVec)
                } else {
                    const edgeVec = new Vector2()
                    vec2.subtract(edgeVec, (edge.v === vertex ? edge.w : edge.v).pos, vertex.pos)
                    edgeLen = 0.2 * vec2.len(edgeVec)
                }
                dir.x = Math.sin(i * step)
                dir.y = Math.cos(i * step)
                vec2.scale(dir, dir, edgeLen)
                vec2.subtract(dir, vertex.pos, dir)
                this._setCurveControlPos(edge, vertex, dir)
                i++
            }

        }
    }

    /**
     * 
     * @param {Vertex} vertex 
     * @param {PointShape} value 
     */
    _processPointShapeEndpoint(vertex, value) {
        const iter = vertex.upperTierIDs.values()
        /** @type {Edge} */
        const edge0 = this.cellTable.get(iter.next().value)
        if (vertex.mirror !== PointShape.NONE && value === PointShape.NONE) {
            this._setCurveControlPos(edge0, vertex, edge0.v === vertex ? edge0.v.pos : edge0.w.pos)
            if (vertex.unlinkedCurveControl) {
                const unlinkedCurveControl = this.cellTable.get(vertex.unlinkedCurveControl)
                const moveCurveChange = new Change({
                    before: new Vector2(unlinkedCurveControl.pos),
                    after: new Vector2(vertex.pos.x, vertex.pos.y)
                })
                this.changes.update(unlinkedCurveControl.id, 'pos', moveCurveChange)
            }

        } else if (vertex.mirror === PointShape.NONE && value !== PointShape.NONE) {
            const dir = new Vector2()
            // curve on edge
            vec2.subtract(dir, (edge0.v === vertex ? edge0.w : edge0.v).pos, vertex.pos)
            const dirLen = vec2.len(dir)
            vec2.normalize(dir, dir)
            vec2.scale(dir, dir, dirLen * 0.2)
            vec2.add(dir, vertex.pos, dir)
            this._setCurveControlPos(edge0, vertex, dir)
            // independent curve handle
            vec2.subtract(dir, vertex.pos, (edge0.v === vertex ? edge0.w : edge0.v).pos)
            vec2.normalize(dir, dir)
            vec2.scale(dir, dir, dirLen * (value === PointShape.ANGLE_AND_LENGTH ? 0.2 : 0.3))
            const newPos = new Vector2()
            vec2.add(newPos, vertex.pos, dir)
            const indCurve = this._ensureIndCurveExist(vertex)
            const moveCurveChange = new Change({
                before: new Vector2(indCurve.pos),
                after: newPos
            })
            this.changes.update(indCurve.id, 'pos', moveCurveChange)
        } else if (vertex.mirror === PointShape.INDEPENDENT && value !== PointShape.INDEPENDENT) {
            const indCurve = this._ensureIndCurveExist(vertex)
            const curve0 = edge0.curve[edge0.v === vertex ? 0 : 1]
            if (value === PointShape.ANGLE) {
                const dir = new Vector2()
                vec2.subtract(dir, vertex.pos, (edge0.v === vertex ? edge0.w : edge0.v).pos)
                const defaultLength = vec2.length(dir)
                this._alignExistingCurves(curve0, vertex, indCurve, value, defaultLength * 0.2)
            } else {
                this._alignExistingCurves(curve0, vertex, indCurve, value)
            }
        }
    }

    /**
     * 
     * @param {Vertex} vertex 
     * @param {PointShape} value 
     */
    _processPointShapeRegular(vertex, value) {
        const iter = vertex.upperTierIDs.values()
        /** @type {Edge} */
        const edge0 = this.cellTable.get(iter.next().value)
        /** @type {Edge} */
        const edge1 = this.cellTable.get(iter.next().value)

        if (vertex.mirror !== PointShape.NONE && value === PointShape.NONE) {
            this._setCurveControlPos(edge0, vertex, edge0.v === vertex ? edge0.v.pos : edge0.w.pos)
            this._setCurveControlPos(edge1, vertex, edge1.v === vertex ? edge1.v.pos : edge1.w.pos)
        } else if (vertex.mirror === PointShape.NONE) {
            if (
                value === PointShape.ANGLE ||
                value === PointShape.INDEPENDENT ||
                value === PointShape.ANGLE_AND_LENGTH
            ) {
                const start = edge0.v === vertex ? edge0.w : edge0.v
                const end = edge1.v === vertex ? edge1.w : edge1.v
                let e1, e2
                if (start.pos.eq(end.pos)) {
                    const edgeDir = new Vector2()
                    vec2.subtract(edgeDir, vertex.pos, start.pos)
                    const len = vec2.len(edgeDir)
                    vec2.scale(edgeDir, edgeDir, 1 / len)
                    const tmp = edgeDir.x
                    edgeDir.x = edgeDir.y
                    edgeDir.y = -tmp
                    e1 = new Vector2()
                    vec2.scale(edgeDir, edgeDir, len * 0.2)
                    vec2.add(e1, vertex.pos, edgeDir)
                    e2 = new Vector2()
                    vec2.scale(edgeDir, edgeDir, -1)
                    vec2.add(e2, vertex.pos, edgeDir)
                    if (edge0.index > edge1.index) {
                        [e1, e2] = [e2, e1]
                    }
                } else {
                    const result = findControlPoints(start.pos, vertex.pos, end.pos)
                    e1 = result.e1
                    e2 = result.e2
                    if (value === PointShape.ANGLE_AND_LENGTH) _alignPoints(e1, vertex.pos, e2)
                }
                this._setCurveControlPos(edge0, vertex, e1)
                this._setCurveControlPos(edge1, vertex, e2)

            }
        } else if (vertex.mirror === PointShape.ANGLE && value === PointShape.ANGLE_AND_LENGTH) {
            const curve0 = edge0.curve[edge0.v === vertex ? 0 : 1]
            const curve1 = edge1.curve[edge1.v === vertex ? 0 : 1]
            this._alignExistingCurves(curve0, vertex, curve1, value)
        } else if (vertex.mirror === PointShape.INDEPENDENT && value === PointShape.ANGLE_AND_LENGTH) {
            const curve0 = edge0.curve[edge0.v === vertex ? 0 : 1]
            const curve1 = edge1.curve[edge1.v === vertex ? 0 : 1]
            this._alignExistingCurves(curve0, vertex, curve1, value)
        } else if (vertex.mirror === PointShape.INDEPENDENT && value === PointShape.ANGLE) {
            let defaultLen = 0
            if (edge0.curve[edge0.v === vertex ? 0 : 1].pos.eq(edge0.v === vertex ? edge0.v : edge0.w)) {
                const start = edge0.v === vertex ? edge0.w : edge0.v
                const end = edge1.v === vertex ? edge1.w : edge1.v

                const { e1 } = findControlPoints(start.pos, vertex.pos, end.pos)
                defaultLen = Math.sqrt(Math.pow((e1.x - vertex.pos.x), 2) + Math.pow((e1.y - vertex.pos.y), 2))
                this._setCurveControlPos(edge0, vertex, e1)
            } else if (edge1.curve[edge1.v === vertex ? 0 : 1].pos.eq(edge1.v === vertex ? edge1.v : edge1.w)) {
                const start = edge0.v === vertex ? edge0.w : edge0.v
                const end = edge1.v === vertex ? edge1.w : edge1.v

                const { e2 } = findControlPoints(start.pos, vertex.pos, end.pos)
                defaultLen = Math.sqrt(Math.pow((e2.x - vertex.pos.x), 2) + Math.pow((e2.y - vertex.pos.y), 2))
                this._setCurveControlPos(edge1, vertex, e2)
            }
            const curve0 = edge0.curve[edge0.v === vertex ? 0 : 1]
            const curve1 = edge1.curve[edge1.v === vertex ? 0 : 1]
            this._alignExistingCurves(curve0, vertex, curve1, value, defaultLen)
        }
    }

    _alignExistingCurves(curve0, vertex, curve1, value, defaultLength = 0) {
        const dir0 = new Vector2()
        vec2.subtract(dir0, vertex.pos, curve0.pos)
        const dir1 = new Vector2()
        vec2.subtract(dir1, vertex.pos, curve1.pos)
        let longerCurve, shorterCurve, goalLen
        const len0 = vec2.len(dir0)
        const len1 = vec2.len(dir1)
        if (len0 < len1) {
            longerCurve = curve1
            shorterCurve = curve0
            goalLen = value === PointShape.ANGLE_AND_LENGTH ? len1 : len0
        } else {
            longerCurve = curve0
            shorterCurve = curve1
            goalLen = value === PointShape.ANGLE_AND_LENGTH ? len0 : len1
        }
        if (goalLen < 1.E-5) {
            goalLen = defaultLength
        }
        vec2.subtract(dir0, longerCurve.pos, vertex.pos) // reuse dir0
        dir0.x = -1 * (longerCurve.pos.x - vertex.pos.x)
        dir0.y = -1 * (longerCurve.pos.y - vertex.pos.y)
        vec2.normalize(dir0, dir0)
        vec2.scale(dir0, dir0, goalLen)
        const newPos = new Vector2()
        vec2.add(newPos, vertex.pos, dir0)
        const posChange = new Change({
            before: new Vector2(shorterCurve.pos),
            after: newPos
        })
        this.changes.update(shorterCurve.id, 'pos', posChange)
    }

    /**
     *
     * @param {Vertex} vertex
     * @param {string} key
     * @param {Change} change
     */
    _spreadVertProp(vertex, key, change) {
        const providVert = vertex.isFlagged(FlagsEnum.CURVE_VERT) ?
            vertex.adjacentMainVertex :
            vertex
        this.changes.update(providVert.id, key, change)
        for (const edgeId of providVert.upperTierIDs) {
            const edge = this.cellTable.get(edgeId)
            const curve = edge.curve[edge.v === providVert ? 0 : 1]
            this.changes.update(curve.id, key, change)
        }
    }


    /**
     *
     * @param {Edge} edge
     * @param {Vertex} wedge
     * @param {Vector2} pos Won't change or occupy the Vector2
     */
    _setCurveControlPos(edge, wedge, pos) {
        const cp = edge.curve[edge.v === wedge ? 0 : 1]
        const moveCurveChange = new Change({
            before: new Vector2(cp.pos),
            after: new Vector2(pos.x, pos.y)
        })
        this.changes.update(cp.id, 'pos', moveCurveChange)
    }

    /**
     *
     * @param {Vertex} vertex The vertex will have an independent curve control handle
     * @param {PointShape} pointShape The point shape needs to be updated in the samae event because the UI will filter the event by time
     * @param {boolean} undoable
     * @returns {Vertex}
     */
    addIndpendentCurveCtrl(vertex, pointShape, undoable = true) {
        const curveControl = this._ensureIndCurveExist(vertex, pointShape)
        if (this.changes.CREATE.size !== 0 || this.changes.UPDATE.size !== 0) {
            this.applyChanges(this.changes)
            this.fire(undoable)
        }
        return curveControl
    }

    _ensureIndCurveExist(vertex, pointShape) {
        if (vertex.unlinkedCurveControl) {
            const unlinkedCurveControl = this.cellTable.get(vertex.unlinkedCurveControl)
            if (!this.vertices.has(unlinkedCurveControl)) {
                this.changes.create([unlinkedCurveControl.id])
            }
            if (pointShape && vertex.mirror !== pointShape) {
                const change = new Change({
                    before: vertex.mirror,
                    after: pointShape
                })
                this.changes.update(vertex.id, 'mirror', change)
            }
            return unlinkedCurveControl
        }

        const createList = []
        const curveControl = this._createAndRecordVertex(createList)

        curveControl.set({
            pos: vertex.pos,
            cap: vertex.cap,
            end: vertex.end,
            join: vertex.join,
            mirror: PointShape.ANGLE_AND_LENGTH
        })

        curveControl.flag(FlagsEnum.CURVE_VERT)
        this.cellTable.set(curveControl.id, curveControl)
        curveControl.adjacentMainVertex = vertex
        curveControl.cornerRadius = vertex.cornerRadius

        const indCurveCtrlChange = new Change({
            before: vertex.unlinkedCurveControl,
            after: curveControl.id
        })
        this.changes.update(vertex.id, 'unlinkedCurveControl', indCurveCtrlChange)

        // Important note: UI will update in a fix frequency. The change should include all update
        if (pointShape && vertex.mirror !== pointShape) {
            const mirrorChange = new Change({
                before: vertex.mirror,
                after: pointShape
            })
            this.changes.update(vertex.id, 'mirror', mirrorChange)
        }

        this.changes.create(createList)
        return curveControl
    }

    /**
     * Falsify the independent curve control, can't undo
     * @param {Vertex} vertex The vertex will have an independent curve control handle
     * @returns {Vertex}
     */
    falsifyIndCurveCtrl(vertex) {
        if (!this.cellTable.has(FAKE_IDS.CURVE_CONTROL)) {
            const fakeCurveCtrl = new Vertex()
            fakeCurveCtrl.id = FAKE_IDS.CURVE_CONTROL
            fakeCurveCtrl.flag(FlagsEnum.CURVE_VERT)
            this.cellTable.set(FAKE_IDS.CURVE_CONTROL, fakeCurveCtrl)
        }

        /** @type {Vertex} */
        const curveControl = this.cellTable.get(FAKE_IDS.CURVE_CONTROL)
        curveControl.adjacentMainVertex = vertex
        curveControl.set({
            pos: vertex.pos,
            cap: vertex.cap,
            join: vertex.join,
            mirror: vertex.mirror
        })
        curveControl.cornerRadius = vertex.cornerRadius
        this.vertices.add(curveControl)

        return curveControl
    }

    hideFakeIndCurveCtrl() {
        if (!this.cellTable.has(FAKE_IDS.CURVE_CONTROL)) return
        /** @type {Vertex} */
        const curveControl = this.cellTable.get(FAKE_IDS.CURVE_CONTROL)
        if (this.vertices.has(curveControl)) this.vertices.delete(curveControl)
    }

    /**
     * Move the independent curve control and force the specific curve control mirror to this curve control
     * @param {Vertex} curveCtrl The curve control to move
     * @param {vertex} wedgeVert The wedge vertex between 2 controls
     * @param {Vertex} oppCurveCtrl The curve control to mirror, allow to be null
     * @param {number} x the position in the x component
     * @param {number} y the position in the y component
     * @param {bool} undoable
     */
    moveIndCurveControl(curveCtrl, wedgeVert, oppCurveCtrl, x, y, undoable = true) {
        if (!curveCtrl.isFlagged(FlagsEnum.CURVE_VERT)) {
            console.warn(`The dragging object isn't a curve control`)
            return
        }

        const localPos = new Vector2(x, y)

        const newCurvePos = new Vector2()
        newCurvePos.copy(localPos)
        const posChange = new Change({
            before: new Vector2(curveCtrl.pos),
            after: newCurvePos
        })
        /** @type {EntityChange} changes */
        const changes = this.changes
        changes.update(curveCtrl.id, 'pos', posChange)

        // Rotate by the direction of the moving curve handle
        if (oppCurveCtrl) {
            const newOppCurvePos = new Vector2()
            newOppCurvePos.x = newCurvePos.x - wedgeVert.pos.x
            newOppCurvePos.y = newCurvePos.y - wedgeVert.pos.y
            newOppCurvePos.x = (newOppCurvePos.x * -1) + wedgeVert.pos.x
            newOppCurvePos.y = (newOppCurvePos.y * -1) + wedgeVert.pos.y
            const oppChange = new Change({
                before: new Vector2(oppCurveCtrl.pos),
                after: newOppCurvePos
            })
            this.changes.update(oppCurveCtrl.id, 'pos', oppChange)
        }
        this.applyChanges(this.changes)
        this.fire(undoable)
    }

    /**
     * 
     * @param {string[]} createList 
     * @returns {Vertex}
     */
    _createAndRecordVertex(createList) {
        const vertex = new Vertex();
        this.cellTable.set(vertex.id, vertex);
        if (createList) {
            createList.push(vertex.id);
        }
        return vertex
    }


    /**
     * Prepares a position update for any connected component based on an offset and creates the change record.
     * This method can be used for any movable entity represented by a unique ID.
     * @param {string} id - The ID of the component to update.
     * @param {Vector2} offset - The offset to apply to the component's position.
     */
    _preparePositionUpdate(id, offset) {
        const vertex = this.cellTable.get(id)
        const originalPosition = new Vector2(vertex.pos)
        const newPosition = new Vector2()
        vec2.add(newPosition, vertex.pos, offset)

        const change = new Change({
            before: originalPosition,
            after: newPosition
        })
        this.changes.update(id, 'pos', change)
    }
}

/**
 * Uitilty function
 * @param {Vector2} a 
 * @param {Vector2} b 
 * @param {Vector2} c 
 */
function _alignPoints(a, b, c) {
    let dir0x = a.x - b.x
    let dir0y = a.y - b.y
    let dir1x = c.x - b.x
    let dir1y = c.y - b.y
    const len0 = Math.sqrt(dir0x * dir0x + dir0y * dir0y)
    const len1 = Math.sqrt(dir1x * dir1x + dir1y * dir1y)
    if (len0 < len1) {
        dir0x = (dir0x / len0) * len1
        dir0y = (dir0y / len0) * len1
        a.x = b.x + dir0x
        a.y = b.y + dir0y
    } else {
        dir1x = (dir1x / len1) * len0
        dir1y = (dir1y / len1) * len0
        c.x = b.x + dir1x
        c.y = b.y + dir1y
    }
}

class IndexedSet extends Set {
    constructor() {
        super()
        this.currIndex = 0
    }
    add(value) {
        value.index = this.currIndex++
        return super.add(value)
    }
    delete(value) {
        value.index = -1
        return super.delete(value)
    }
}

const curveHandleBuffer = new Vector2()
const sharedVerticesSet = new Set()

/**
 * @typedef {object} MeshData
 * @property {VertexData[]}   vertices        list of vertex data
 * @property {EdgeData[]}     edges           list of edge data
 * @property {ContourData[]}  contours        list of contour data
 */

/** @typedef {string} ID */

/** 
 * @typedef {object} PointChange
 * @property {string} id
 * @property {number} mirror
 * @property {number} x
 * @property {number} y
 */
