import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { useSetNotification } from '../../../providers/NotificationProvider'
import { Avatar, LinkSwitcher, Tooltip } from '../index'

export const TextOverflow = ({
  value,
  allowCopy,
  copySuccessMessage,
  className = ''
}: {
  value?: string
  maxWidth?: number
  allowCopy?: boolean
  copySuccessMessage?: string
  className?: string
}) => {
  const { t } = useTranslation('common')
  const ref = useRef<HTMLDivElement>(null)
  const { addNotification } = useSetNotification()

  const handleCopy = () => {
    if (!value) {
      return
    }
    navigator.clipboard.writeText(value)
    addNotification({
      content: copySuccessMessage ?? t('message.copied_to_clipboard'),
      type: 'success'
    })
  }

  if (!value) {
    return null
  }
  return (
    <Tooltip content={value} visibleOnOverflow>
      <LinkSwitcher
        onClick={allowCopy ? handleCopy : undefined}
        ref={ref}
        className={`overflow-hidden overflow-ellipsis whitespace-nowrap ${className}`}
      >
        {value}
      </LinkSwitcher>
    </Tooltip>
  )
}

export const TableUser = ({
  name,
  email,
  showYouTag,
  tips,
  avatar
}: {
  name: string
  email?: string
  avatar?: string
  showYouTag?: boolean
  tips?: string
}) => {
  const { t } = useTranslation(['common', 'workspace'])
  const ref = useRef<HTMLDivElement>(null)

  return (
    <div className="flex overflow-hidden" ref={ref}>
      <Avatar alt={name} src={avatar} size="m" className="flex-shrink-0" />
      <div className="ml-8 text-light-overlay-60 min-w-0">
        <div className="flex flex-grow items-center">
          <TextOverflow className="text-12 font-medium text-white truncate" value={name} />
          {showYouTag && <span className="text-12 font-medium text-white whitespace-nowrap">&nbsp;({t('you')})</span>}
        </div>
        <TextOverflow value={email} allowCopy copySuccessMessage={t('message.email_copied', { ns: 'workspace' })} />
        {tips && <div>{tips}</div>}
      </div>
    </div>
  )
}
