import { useEffect } from 'react'

import { useAtomValue } from 'jotai'

import { activeKeyframeTimeAtom } from '../../../atoms'
import { useDataStoreActions } from '../../../providers/dataStore/DataStoreProvider'

export const SyncPlayhead = () => {
  const activeTime = useAtomValue(activeKeyframeTimeAtom)
  const { setPlayheadTime } = useDataStoreActions()
  useEffect(() => {
    if (activeTime !== null) {
      setPlayheadTime(activeTime)
    }
  }, [activeTime, setPlayheadTime])

  return null
}
