import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { useSetNotification } from '../../providers/NotificationProvider'

const RouterChangeWatcher = () => {
  const location = useLocation()
  const { clearNotification } = useSetNotification()
  useEffect(() => {
    clearNotification()
  }, [location, clearNotification])
  return null
}

export default RouterChangeWatcher
