import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '../shared'

const Message = ({ content }) => {
  return (
    <div className="relative text-12 text-white bg-rubin-overlay-10 px-12 py-8 flex rounded-5">
      <div
        className="border border-danger absolute"
        style={{ transform: 'scale(0.5)', borderRadius: 10, inset: '-50%' }}
      />
      <div className="w-24">
        <Icon name="Error" interactive={false} />
      </div>
      {content}
    </div>
  )
}

Message.propTypes = {
  content: PropTypes.string
}

export default Message
