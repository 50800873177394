export const TutorialType = {
  LEARNING_VIDEO_LIST: 'LEARNING_VIDEO_LIST',
  WELCOME: 'WELCOME',
  GENERAL_INTERFACE: 'GENERAL_INTERFACE',
  COMPLETE_INTERFACE: 'COMPLETE_INTERFACE',
  TOOL_MENU: 'TOOL_MENU',
  TRANSFORM: 'TRANSFORM',
  KEYFRAME: 'KEYFRAME',
  INDICATOR: 'INDICATOR',
  DASHBOARD: 'DASHBOARD',
  EDITOR: 'EDITOR'
}

export const LOADING_TIME = 3000
