import { ElementType, GeometryType } from '@phase-software/types'

export class Counter {
  constructor() {
    this._counter = []
  }

  allocate(number) {
    if (number < 1) return
    if (this._counter[number - 1] >= 0) {
      this._counter[number - 1]++
    } else {
      this._counter[number - 1] = 1
    }
  }

  reset() {
    this._counter = []
  }

  release(number) {
    if (this._counter[number - 1] > 0) {
      this._counter[number - 1]--
    }

    if (!this._counter[number - 1] && number === this._counter.length) {
        this._counter.length = this._counter.findLastIndex(o => o > 0) + 1
    }
  }

  get curr() {
    return this._counter.length
  }
}

export class NameCounter {
    constructor(prefix) {
        this.prefix = prefix
        this.counter = new Counter()
        this.pattern = new RegExp(`^${this.prefix} ([1-9]\\d*)$`, 'i')
    }

    next() {
        const next = this.counter.curr + 1
        return `${this.prefix} ${next}`
    }

    rename(from, to) {
        this.remove(to)
        this.create(from)
    }

    create(name) {
        const matches = name.match(this.pattern)
        if (matches) {
            const number = Number(matches[1])
            this.counter.allocate(number)
        }
    }

    remove(name) {
        const matches = name.match(this.pattern)
        if (matches) {
            const number = Number(matches[1])
            this.counter.release(number)
        }
    }

    reset() {
        this.counter.reset()
    }
}

const CounterType = {
    CONTAINER: 'CONTAINER',
    NORMAL_GROUP: 'NORMAL_GROUP',
    RECTANGLE: 'RECTANGLE',
    ELLIPSE: 'ELLIPSE',
    POLYGON: 'POLYGON',
}

const CounterTypeMapPrefix = {
    CONTAINER: 'Container',
    NORMAL_GROUP: 'Group',
    RECTANGLE: 'Rectangle',
    ELLIPSE: 'Oval',
    POLYGON: 'Path'
}

export class ElementNameCounter {
    constructor() {
        this.counterMap = new Map()
        Object.entries(CounterTypeMapPrefix).forEach(([counterType, prefix]) => {
            this.counterMap.set(counterType, new NameCounter(prefix))
        })
    }

    getCounter(instance) {
        const elementType = instance.get('elementType')
        switch (elementType) {
            case ElementType.CONTAINER:
                return this.getCounterByTypes(instance.get('containerType'))
            case ElementType.PATH: {
                const geometryType = instance.get('initGeometryType')
                return this.getCounterByTypes(elementType, geometryType)
            }
        }
    }

    getCounterByTypes(elementType, geometryType) {
        switch (elementType) {
            case ElementType.CONTAINER:
                return this.counterMap.get(CounterType.CONTAINER)
            case ElementType.NORMAL_GROUP:
                return this.counterMap.get(CounterType.NORMAL_GROUP)
            case ElementType.PATH: {
                switch (geometryType) {
                    case GeometryType.RECTANGLE:
                        return this.counterMap.get(CounterType.RECTANGLE)
                    case GeometryType.ELLIPSE:
                        return this.counterMap.get(CounterType.ELLIPSE)
                    case GeometryType.POLYGON:
                    case GeometryType.LINE:
                        return this.counterMap.get(CounterType.POLYGON)
                }
            }
        }
    }

    load(instance) {
        const counter = this.getCounter(instance)
        if (counter) {
            counter.create(instance.get('name'))
        }
    }

    rename(instance, from, to) {
        const counter = this.getCounter(instance)
        if (counter) {
            counter.rename(from, to)
        }
    }

    changeGeometryType(instance, oldGeometryType, newGeometryType) {
        const { name, elementType } = instance.gets('geometryType', 'elementType', 'name')

        const newTypeCounter = this.getCounterByTypes(elementType, newGeometryType)
        newTypeCounter.create(name)

        const oldTypeCounter = this.getCounterByTypes(elementType, oldGeometryType)
        oldTypeCounter.remove(name)
    }

    next(elementType, geometryType) {
        const counter = this.getCounterByTypes(elementType, geometryType)
        if (counter) {
            return counter.next()
        }
    }

    remove(instance) {
        const counter = this.getCounter(instance)
        if (counter) {
            counter.remove(instance.get('name'))
        }
    }

    reset() {
        this.counterMap.forEach(counter => {
            counter.reset()
        })
    }
}
