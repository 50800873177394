export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

export const waitUntil = (selector, timeoutMs = 3000, intervalMs = 50) => {
  let overdue = false
  const done = () => {
    overdue = true
  }
  const query = () => Promise.resolve(document.querySelector(selector))
  const check = (el) => {
    if (overdue) {
      return null
    } else if (el) {
      return el
    } else {
      return delay(intervalMs).then(query).then(check)
    }
  }
  return Promise.race([delay(timeoutMs).then(done), query().then(check)])
}
