import { IRNode } from './IRNode'

export class IRPrecomposition extends IRNode {
  static props = [
    ...super.props,
    { key: 'width', animatable: false },
    { key: 'height', animatable: false },
    { key: 'cornerRadius', animatable: true, defaultValue: 0 }
  ]

  static defaultValue = {
    refId: '',
    static: false
  }

  constructor(data = IRPrecomposition.defaultValue, parent = null) {
    super(data, parent)
    this.refId = data.refId
    this.static = data.static
  }

  fromJSON(data) {
    super.fromJSON(data)
    this.refId = data.refId
    this.static = data.static
    return this
  }

  toJSON() {
    return {
      ...super.toJSON(),
      refId: this.refId,
      static: this.static
    }
  }
}
