import Interval from '../../helpers/Interval'
import PropertyStack from '../PropertyStack'

const DEFAULT_CORNER_RADIUS = 0
const CORNER_RADIUS_TYPES = {
  NUMBER: 'number',
  ARRAY: 'object'
}

class CornerRadiusStack extends PropertyStack {
  constructor(elementStack, data) {
    super(elementStack, data)
    this.type = 'CORNER_RADIUS'
    this.key = 'cornerRadius'
    this.dataKey = 'cornerRadius'
    this.animatableProperties = new Set(['cornerRadius'])

    this._subInterval = new Interval()
  }

  /**
   * Get working type of corner radius for the interval
   * @param {number|number[]} start
   * @param {number|number[]} end
   * @returns {CORNER_RADIUS_TYPES}
   */
  _getWorkingType(start, end) {
    if (typeof start === CORNER_RADIUS_TYPES.NUMBER && typeof end === CORNER_RADIUS_TYPES.NUMBER) {
      return CORNER_RADIUS_TYPES.NUMBER
    }

    return CORNER_RADIUS_TYPES.ARRAY
  }

  /**
   * Get working value of corner radius for an interval
   * @param {CORNER_RADIUS_TYPES} type
   * @param {number|number[]} value
   * @returns {number|number[]}
   */
  _getWorkingValue(type, value) {
    if (typeof value === type) {
      return value
    }

    if (type === CORNER_RADIUS_TYPES.NUMBER) {
      return value[0]
    }

    const result = []
    for (let i = 0; i < 4; i++) {
      result.push(value)
    }
    return result
  }

  /**
   * Get animation data
   * @param {number} time
   * @returns {object}
   */
  getAnimateData(time) {
    const interval = this.getWorkingInterval(time)
    if (!interval) {
      // TODO: make sure returning "read-only" const value here is ok
      return {
        [this.dataKey]: DEFAULT_CORNER_RADIUS
      }
    }

    const workingTime = this.getWorkingTime(time)
    const result = this._getCornerRadiusChange(workingTime, interval)
    return {
      [this.dataKey]: result
    }
  }

  /**
   * Get working interval by time
   * @param {number} time
   * @returns {Interval | undefined}  interval (READONLY) or undefined if no interval found
   *                                    DO NOT modify this returned value (outside of this class and it's subclasses)
   */
  getWorkingInterval(time) {
    const interval = super.getWorkingInterval(time)
    if (!interval) {
      return
    }

    // copy KFs value data because it's list of numbers
    const startValue = interval.start.value
    const endValue = interval.end.value
    const workingType = this._getWorkingType(startValue, endValue)
    const workingStart = this._getWorkingValue(workingType, startValue)
    const workingEnd = this._getWorkingValue(workingType, endValue)
    interval.updateValues(workingStart, workingEnd)
    return interval
  }

  /**
   * Get corner radius change by time
   * @param {number} workingTime
   * @param {Interval} interval
   * @returns {number|number[]}
   */
  _getCornerRadiusChange(workingTime, interval) {
    const workingType = this._getWorkingType(interval.start.value, interval.end.value)

    if (workingType === CORNER_RADIUS_TYPES.NUMBER) {
      return this._getNumberCornerRadiusChange(workingTime, interval)
    }

    return this._getArrayCornerRadiusChange(workingTime, interval)
  }

  /**
   * Get corner radius change of number type
   * @param {number} workingTime
   * @param {Interval} interval
   * @returns {number}
   */
  _getNumberCornerRadiusChange(workingTime, interval) {
    return this._getInterpolateData(workingTime, interval)
  }

  /**
   * Get corner radius change of array type
   * @param {number} workingTime
   * @param {Interval} interval
   * @returns {number[]}
   */
  _getArrayCornerRadiusChange(workingTime, interval) {
    const data = []
    for (let i = 0; i < 4; i++) {
      const subInterval = this._getIntervalWithCornerRadiusValue(interval, i)
      let result = DEFAULT_CORNER_RADIUS
      if (this._isAnimatable()) {
        result = this._getInterpolateData(workingTime, subInterval)
      } else {
        result = this._getInstantChangeData(workingTime, subInterval)
      }
      data.push(result)
    }

    return data
  }

  /**
   * Get specific value for corner radius from the interval
   * @param {Interval} interval
   * @param {number} idx
   * @returns {Interval}
   */
  _getIntervalWithCornerRadiusValue(interval, idx) {
    const outInt = this._subInterval
    outInt.copy(interval, true).updateValues(
      interval.start.value[idx],
      interval.end.value[idx]
    )
    return outInt
  }
}

export default CornerRadiusStack
