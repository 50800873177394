import { atom } from 'jotai'

import { dataStoreAtom } from '../dataStore'

export type TimeRange = {
  start: number | null
  end: number | null
}
export const selectedKeyframeTimeRangeAtom = atom<TimeRange>({ start: null, end: null })

export const activeKeyframeHandleAtom = atom<string | null>(null)
export const activeKeyframeTimeAtom = atom<number | null>(null)
export const activeKeyframeAtom = atom(
  (get) => {
    return {
      handle: get(activeKeyframeHandleAtom),
      time: get(activeKeyframeTimeAtom)
    }
  },
  (get, set, update: { handle: string | null; time: number | null }) => {
    set(activeKeyframeHandleAtom, update.handle)
    set(activeKeyframeTimeAtom, update.time)
  }
)

export enum ScrollDirection {
  TOP,
  BOTTOM,
  LEFT,
  RIGHT
}
export const autoScrollDirectionAtom = atom<Set<ScrollDirection>>(new Set<ScrollDirection>())

export enum DragAction {
  NONE,
  SELECT,
  MOVE,
  STRETCH
}
export const dragActionAtom = atom<DragAction>(DragAction.NONE)
export const keyframeOverlappedAtom = atom<boolean>(false)

export const modifierKeysAtom = atom<Set<string>>(new Set<string>())

export const highlightedKeyframesAtom = atom<Set<string>>(new Set<string>())
export const highlightSelectedKeyframesAtom = atom(null, (get, set) => {
  const dataStore = get(dataStoreAtom)
  const keyframeList = dataStore.selection.get('kfs')
  set(highlightedKeyframesAtom, new Set(keyframeList))
})
export const highlightKeyframesByTimeAtom = atom(null, (get, set, time) => {
  const dataStore = get(dataStoreAtom)
  const keyframeList = dataStore.interaction.getKeyframeListByTime(time)
  set(highlightedKeyframesAtom, new Set(keyframeList))
})
