import { PropComponentType } from '@phase-software/types'
import { notNull } from '@phase-software/data-utils'
import PropertyComponent from './PropertyComponent'

/** @typedef {import('../DataStore').DataStore} DataStore */
/** @typedef {import('./PropertyComponent').AppliedRef} AppliedRef */

export default class SkewComponent extends PropertyComponent {
    /**
     * @param {DataStore} dataStore
     * @param {SkewComponentData} [data]
     * @param {object} [options] 
     * @param {bool} [options.regenId=false]   if set to true, will generate new ID
     */
    constructor(dataStore, data = {}, options) {
        super(dataStore, data, options)

        const { skewX, skewY } = data

        this.skewX = notNull(skewX) ? skewX : 0
        this.skewY = notNull(skewY) ? skewY : 0
        this.componentType = PropComponentType.SKEW

        if (!this.name) {
            this.name = 'skew'
        }
    }

    /** @param {Partial<SkewComponentSetData>} data */
    set(data) {
        super.set(data)

        if (notNull(data.skewX)) {
            this.updateProp('skewX', data.skewX)
        } else if (data.skewX === null) {
            this.updateProp('skewX', undefined)
        }
        
        if (notNull(data.skewY)) {
            this.updateProp('skewY', data.skewY)
        } else if (data.skewY === null) {
            this.updateProp('skewY', undefined)
        }
    }

    /**
     * Override this in subclasses
     * CALL super._clone() at the top of overriden method
     * @protected
     * @param {AppliedRef} [ref]
     * @returns {SkewComponent}
     */
    _clone(ref) {
        const obj = super._clone(ref)
        obj.skewX = this.skewX
        obj.skewY = this.skewY
        return obj
    }

    /**
     * Override this in subclasses
     * CALL super._save() at the top of overriden method
     * @protected
     * @returns {SkewComponentData}
     */
    _save() {
        const data = super._save()
        data.skewX = this.skewX
        data.skewY = this.skewY
        return data
    }
}

SkewComponent.BASE_DEFAULT_DATA = {
    skewX: 0,
    skewY: 0
}

/** @typedef {import('./PropertyComponent').PropertyComponentData} PropertyComponentData */

/**
 * @typedef {SkewComponentData} SkewComponentData
 * @property {number | undefined} skewX
 * @property {number | undefined} skewY
 */

/**
 * @typedef {SkewComponentData} SkewComponentSetData
 * @property {number | null} skewX
 * @property {number | null} skewY
 */