import React, { useRef, useEffect, useCallback, useState } from 'react'
import Knob from './Knob'
import { useDataStoreActions } from '../../../providers/dataStore/DataStoreProvider'

// offset used to align hidden Slider's knob with our custom knob
const xOffsetClassNameMap = {
  solid: '-inset-x-4',
  player: '-inset-x-6'
}

// helper functions
const getBackground = (size: number) => `linear-gradient(to right, #1C6EE8 ${size}%, rgba(255, 255, 255, 0.1) ${size}%)`
const calculatePercentage = ({ value, min, max }: { value: number; min: number; max: number }) =>
  max === min ? 0 : Math.max(0, ((value - min) / (max - min)) * 100)

export interface SliderProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'onMouseUp' | 'onMouseDown'> {
  variant?: 'solid' | 'player'
  mixed?: boolean
  onChange: (value: number, commitUndo: boolean) => void
  onMouseDown?: () => void
  onMouseUp?: () => void
  dataTestId?: string
}

export const Slider = ({
  variant = 'solid',
  min = 0,
  max = 100,
  value = 50,
  onChange,
  onFocus,
  onBlur,
  onMouseDown,
  onMouseUp,
  mixed = false,
  disabled = false,
  dataTestId,
  ...rest
}: SliderProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const knobRef = useRef<HTMLDivElement>(null)
  const trackRef = useRef<HTMLDivElement>(null)
  const isDraggingRef = useRef(false)
  const isMouseDownRef = useRef(false)

  const [knobVisibility, setKnobVisibility] = useState(mixed)

  const updateKnobPositionOnValueChange = useCallback(() => {
    if (!ref.current || !knobRef.current || !inputRef.current || !trackRef.current) return
    const rect = ref.current.getBoundingClientRect()
    const knobWidth = knobRef.current.getBoundingClientRect().width
    const percentage = calculatePercentage({
      value: Number(inputRef.current.value),
      min: Number(min),
      max: Number(max)
    })
    const knobPosition = ((rect.width - knobWidth) * percentage) / 100 + knobWidth / 2
    knobRef.current.style.transform = `translateX(calc(${knobPosition}px - 50%))`
    trackRef.current.style.background = getBackground(percentage)
  }, [min, max])

  const updateKnobPositionOnMouseEvents = useCallback(
    (e: MouseEvent | React.MouseEvent<HTMLInputElement>) => {
      if (!ref.current || !knobRef.current || !trackRef.current) return
      const rect = ref.current.getBoundingClientRect()
      const knobWidth = knobRef.current.getBoundingClientRect().width
      const knobPosition = Math.min(Math.max(e.pageX - rect.x, knobWidth / 2), rect.width - knobWidth / 2)
      knobRef.current.style.transform = `translateX(calc(${knobPosition}px - 50%))`
      const percentage = calculatePercentage({
        value: knobPosition,
        min: knobWidth / 2,
        max: rect.width - knobWidth / 2
      })
      trackRef.current.style.background = getBackground(percentage)
    },
    [ref, knobRef, trackRef]
  )

  const handleValueChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => onChange(Number(e.target.value), false),
    [onChange]
  )

  const handleEditFinished = (e: React.KeyboardEvent<HTMLInputElement>) => e.key === 'Escape' && e.currentTarget.blur()

  const handleMouseMove = (e: MouseEvent) => {
    if (!inputRef.current) return
    if (inputRef.current === document.activeElement) inputRef.current.blur()

    isDraggingRef.current = true
    setKnobVisibility(false)
    updateKnobPositionOnMouseEvents(e)
  }

  const handleMouseUpOrLeave = () => {
    if (onMouseUp) onMouseUp()
    if (!inputRef.current) return
    if (inputRef.current === document.activeElement) inputRef.current.blur()

    isDraggingRef.current = false
    isMouseDownRef.current = false

    setKnobVisibility(mixed)
    onChange(Number(inputRef.current.value), true)
    window.removeEventListener('mousemove', handleMouseMove)
  }

  const handleMouseDown = (e: React.MouseEvent<HTMLInputElement>) => {
    if (onMouseDown) onMouseDown()
    isMouseDownRef.current = true
    setKnobVisibility(false)
    updateKnobPositionOnMouseEvents(e)

    window.addEventListener('mousemove', handleMouseMove)
    window.addEventListener('mouseup', handleMouseUpOrLeave, { once: true })
    window.addEventListener('mouseleave', handleMouseUpOrLeave, { once: true })
  }

  useEffect(() => {
    if (isDraggingRef.current || isMouseDownRef.current) return
    updateKnobPositionOnValueChange()
  }, [value, updateKnobPositionOnValueChange])

  return (
    <div
      ref={ref}
      style={{
        opacity: disabled ? 0.4 : 1
      }}
      className="relative h-28"
      data-test-id={dataTestId}
    >
      <input
        {...rest}
        ref={inputRef}
        type="range"
        min={min}
        max={max}
        value={mixed ? 0 : value}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={handleValueChange}
        onMouseDown={handleMouseDown}
        onKeyUp={handleEditFinished}
        disabled={disabled}
        className={`peer absolute inset-0 cursor-pointer disabled:cursor-not-allowed opacity-0 ${xOffsetClassNameMap[variant]}`}
      />
      <div ref={trackRef} className="absolute my-auto h-2 inset-0 left-0 pointer-events-none" />
      <Knob
        variant={variant}
        ref={knobRef}
        hideKnob={knobVisibility}
        className="peer-disabled:hidden peer-focus-visible:outline-primary-2"
      />
    </div>
  )
}

interface SliderWrapperProps extends SliderProps {
  noIS?: boolean
}

const SliderWrapper = ({ noIS = false, onFocus, onBlur, ...props }: SliderWrapperProps) => {
  const { changeEAMInputState } = useDataStoreActions()
  const focusEAMInputState = useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      if (onFocus) onFocus(e)
      if (!noIS) changeEAMInputState(true)
    },
    [noIS, changeEAMInputState, onFocus]
  )
  const unfocusEAMInputState = useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      if (onBlur) onBlur(e)
      if (!noIS) changeEAMInputState(false)
    },
    [noIS, changeEAMInputState, onBlur]
  )

  return <Slider {...props} onFocus={focusEAMInputState} onBlur={unfocusEAMInputState} />
}

export default SliderWrapper
