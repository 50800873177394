import React, { useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { useApolloClient } from '@apollo/client'
import { WorkspaceType } from '@phase-software/types'

import { useAccessControl } from '../../access-control'
import { FILE_EDITOR_LAYOUT_AREAS } from '../../constant'
import { FILE_OPTIONS, FILE_TITLE_DROPDOWN_OPTIONS } from '../../constants/fileConstants'
import { FileFieldsFragmentDoc, GetFileByIdDocument } from '../../generated/graphql'
import useFileActions from '../../hooks/useFileActions'
import { useFilePermissionContext } from '../../providers/FilePermissionProvider'
import { useLayout } from '../../providers/LayoutProvider'
import { useSetNotification } from '../../providers/NotificationProvider'
import { useProfile } from '../../providers/ProfileProvider'
import { WorkspaceData } from '../../providers/WorkspaceContextProvider'
import { useDataStoreActions } from '../../providers/dataStore/DataStoreProvider'
import { useUI } from '../../providers/dataStore/UIProvider'
import {
  getFileIndexPath,
  getPersonalSharedPath,
  getWorkspaceDraftsPath,
  getWorkspaceProjectPath
} from '../../utils/pathGenerators'
import { translateProjectName } from '../../utils/transformProject'
import DeleteFileDialog from '../modals/DeleteFileDialog'
import MoveFileDialog from '../modals/MoveFileDialog'
import RenameFileDialog from '../modals/RenameFileDialog'
import { LinkSwitcher, Tooltip, WideTitle } from '../shared'
import { MenuListOptionProps, MenuOptionProps } from '../shared/Menu/Menu.types'
import { isSeparatorOption, removeConsecutiveSeparators, translateMenuOptions } from '../shared/Menu/utils'
import ElementTree from './ElementTree'

type ContentPanelProps = {
  workspaceData: WorkspaceData
  projectId: string
  fileId: string
}
export const ContentPanel = ({ workspaceData, projectId, fileId }: ContentPanelProps) => {
  const history = useHistory()
  const profile = useProfile()
  const client = useApolloClient()
  const width = useLayout((o) => o.contentWidth)
  const { editOrigin } = useUI()
  const { setFeature } = useDataStoreActions()
  const { t } = useTranslation(['common', 'workspace'])

  const file = client.readFragment({
    fragment: FileFieldsFragmentDoc,
    fragmentName: 'fileFields',
    id: `files:${fileId}`
  })

  const { id: profileId } = profile

  const { addNotification } = useSetNotification()
  const { clearElementSelection, clearSelection } = useDataStoreActions()
  const { duplicateFile, archiveFile } = useFileActions()
  const { userHasPermission } = useAccessControl()
  const { fileWithPermission } = useFilePermissionContext()

  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [renameModalOpen, setRenameModalOpen] = useState(false)
  const [moveModalOpen, setMoveModalOpen] = useState(false)

  const isDraftProject = projectId === workspaceData.draftProjectId
  const isSharedFile = workspaceData.type === WorkspaceType.PERSONAL && file?.creator_id !== profileId

  const projectPagePath = useMemo(() => {
    if (isDraftProject) return getWorkspaceDraftsPath(workspaceData.type, workspaceData.slug)
    if (isSharedFile) return getPersonalSharedPath(workspaceData.slug)
    return getWorkspaceProjectPath(workspaceData.type, workspaceData.slug, projectId)
  }, [isDraftProject, isSharedFile, projectId, workspaceData.slug, workspaceData.type])

  const fileDropdownOptions = useMemo(() => {
    const filteredOptions = FILE_TITLE_DROPDOWN_OPTIONS.filter((option: MenuListOptionProps) => {
      if (isSeparatorOption(option)) return true

      if (isDraftProject && option.value === FILE_OPTIONS.GO_TO_PROJECT) {
        return false
      }

      return !option.permission || userHasPermission(option.permission, fileWithPermission)
    })

    const removedConsecutiveSeparatorsOptions = removeConsecutiveSeparators(filteredOptions)

    return translateMenuOptions(t, removedConsecutiveSeparatorsOptions, {
      ns: 'workspace'
    })
  }, [fileWithPermission, isDraftProject, t, userHasPermission])

  const handleSelectFileOption = useCallback(
    (option?: MenuOptionProps) => {
      if (editOrigin) {
        setFeature('editOrigin', false)
      }
      switch (option?.value) {
        case FILE_OPTIONS.DUPLICATE:
          clearSelection()
          duplicateFile({
            projectId,
            fileId,
            name: t('workspace:duplicated_file_name', { file_name: file?.name })
          }).then(async (meta) => {
            await client.query({
              query: GetFileByIdDocument,
              variables: { id: meta.id },
              fetchPolicy: 'network-only'
            })
            addNotification({
              type: 'success',
              content: (
                <Trans
                  i18nKey="workspace:duplicated"
                  values={{ file_name: file?.name }}
                  components={[
                    <span
                      key="file-duplicated-notification"
                      className="font-semibold"
                      data-test-id="file-duplicated-notification"
                    />
                  ]}
                />
              ),
              action: t('common:notification.open'),
              callback: () => history.push(getFileIndexPath(meta.id))
            })
          })
          break
        case FILE_OPTIONS.ARCHIVE:
          archiveFile({ projectId, fileId }).then(() => {
            client.refetchQueries({
              include: 'active'
            })
            addNotification({
              type: 'success',
              content: (
                <Trans
                  i18nKey="workspace:archived"
                  values={{ file_name: file?.name }}
                  components={[
                    <span
                      key="file-archived-notification"
                      className="font-semibold"
                      data-test-id="file-archived-notification"
                    />
                  ]}
                />
              )
            })
          })
          history.push(projectPagePath)
          break
        case FILE_OPTIONS.RENAME:
          setRenameModalOpen(true)
          break
        case FILE_OPTIONS.DELETE:
          setDeleteModalOpen(true)
          break
        case FILE_OPTIONS.MOVE:
          setMoveModalOpen(true)
          break
        case FILE_OPTIONS.GO_TO_PROJECT:
          history.push(getWorkspaceProjectPath(workspaceData.type, workspaceData.slug, projectId))
          break
        default:
          throw new Error(`Unknown action: ${option}`)
      }
    },
    [
      addNotification,
      archiveFile,
      client,
      duplicateFile,
      file?.name,
      fileId,
      history,
      projectId,
      projectPagePath,
      workspaceData.slug,
      workspaceData.type,
      clearSelection,
      editOrigin,
      setFeature,
      t
    ]
  )

  const projectName = useMemo(() => {
    if (isSharedFile) {
      return t('shared')
    }
    return translateProjectName(file?.project?.name, t)
  }, [isSharedFile, file, t])

  return (
    <div
      style={{ gridArea: FILE_EDITOR_LAYOUT_AREAS.CONTENT_PANEL, width }}
      className="bg-neutral-90 flex flex-col h-full border-r border-solid border-neutral-80"
      data-test-id="editor-layers-panel"
    >
      <div onClick={clearElementSelection}>
        <WideTitle
          childrenTextId="editor-filename"
          childrenClassName="flex-grow"
          icons={[
            {
              name: 'More',
              dataTestId: 'editor-file-settings',
              dropdownMenu: { options: fileDropdownOptions, onSelect: handleSelectFileOption }
            }
          ]}
          dividerPosition="bottom"
        >
          <div className="flex gap-4">
            <LinkSwitcher to={projectPagePath} className="flex-shrink-0 max-w-[50%] truncate">
              <Tooltip content={file?.project?.name} visibleOnOverflow>
                <div className="truncate">{projectName}</div>
              </Tooltip>
            </LinkSwitcher>
            <div>/</div>
            <Tooltip content={file?.name} visibleOnOverflow>
              <div className="truncate">{file?.name}</div>
            </Tooltip>
          </div>
        </WideTitle>
      </div>
      <ElementTree />
      <RenameFileDialog
        projectId={projectId}
        id={file?.id}
        originalName={file?.name}
        open={renameModalOpen}
        onClose={() => setRenameModalOpen(false)}
      />
      <DeleteFileDialog
        projectId={projectId}
        id={file?.id}
        name={file?.name}
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        redirectTo={projectPagePath}
      />
      <MoveFileDialog
        fileId={file?.id}
        originalProjectId={projectId}
        open={moveModalOpen}
        onClose={() => setMoveModalOpen(false)}
      />
    </div>
  )
}

export default React.memo(ContentPanel)
