import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  _text: any;
  jsonb: any;
  numeric: any;
  timestamp: any;
  timestamptz: any;
  uuid: any;
  versions_scalar: any;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type File = {
  __typename?: 'File';
  created_at?: Maybe<Scalars['DateTime']>;
  creator_id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  draft_permission?: Maybe<Scalars['String']>;
  file_key?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  last_viewed_at?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  permission?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  thumbnail_key?: Maybe<Scalars['String']>;
  thumbnail_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type FileFilters = {
  created_at?: InputMaybe<StringComparisonExp>;
  creator_id?: InputMaybe<IdComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  draft_permission?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IdComparisonExp>;
  last_viewed_at?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  permission?: InputMaybe<StringComparisonExp>;
  project_id?: InputMaybe<IdComparisonExp>;
  status?: InputMaybe<StringComparisonExp>;
  thumbnail_url?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<StringComparisonExp>;
};

export type IdComparisonExp = {
  _eq?: InputMaybe<Scalars['ID']>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

export type LastViewed = {
  last_viewed_at?: InputMaybe<SortOrder>;
};

export type OrderBy = {
  created_at?: InputMaybe<SortOrder>;
  last_viewed?: InputMaybe<LastViewed>;
  name?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type ResultOfProjectIds = {
  __typename?: 'ResultOfProjectIds';
  project_ids?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type StringComparisonExp = {
  _eq?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "_text". All fields are combined with logical 'AND'. */
export type _Text_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['_text']>;
  _gt?: InputMaybe<Scalars['_text']>;
  _gte?: InputMaybe<Scalars['_text']>;
  _in?: InputMaybe<Array<Scalars['_text']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['_text']>;
  _lte?: InputMaybe<Scalars['_text']>;
  _neq?: InputMaybe<Scalars['_text']>;
  _nin?: InputMaybe<Array<Scalars['_text']>>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** columns and relationships of "file_collaborator_invitations" */
export type File_Collaborator_Invitations = {
  __typename?: 'file_collaborator_invitations';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  creator?: Maybe<Users>;
  creator_id: Scalars['uuid'];
  email?: Maybe<Scalars['String']>;
  expired_at?: Maybe<Scalars['timestamptz']>;
  file_id: Scalars['uuid'];
  id: Scalars['uuid'];
  permission: Scalars['String'];
  status: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "file_collaborator_invitations" */
export type File_Collaborator_Invitations_Aggregate = {
  __typename?: 'file_collaborator_invitations_aggregate';
  aggregate?: Maybe<File_Collaborator_Invitations_Aggregate_Fields>;
  nodes: Array<File_Collaborator_Invitations>;
};

/** aggregate fields of "file_collaborator_invitations" */
export type File_Collaborator_Invitations_Aggregate_Fields = {
  __typename?: 'file_collaborator_invitations_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<File_Collaborator_Invitations_Max_Fields>;
  min?: Maybe<File_Collaborator_Invitations_Min_Fields>;
};


/** aggregate fields of "file_collaborator_invitations" */
export type File_Collaborator_Invitations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<File_Collaborator_Invitations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "file_collaborator_invitations". All fields are combined with a logical 'AND'. */
export type File_Collaborator_Invitations_Bool_Exp = {
  _and?: InputMaybe<Array<File_Collaborator_Invitations_Bool_Exp>>;
  _not?: InputMaybe<File_Collaborator_Invitations_Bool_Exp>;
  _or?: InputMaybe<Array<File_Collaborator_Invitations_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  creator?: InputMaybe<Users_Bool_Exp>;
  creator_id?: InputMaybe<Uuid_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  expired_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  permission?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "file_collaborator_invitations" */
export enum File_Collaborator_Invitations_Constraint {
  /** unique or primary key constraint on columns "id" */
  FileCollaboratorInvitationsPkey = 'file_collaborator_invitations_pkey'
}

/** input type for inserting data into table "file_collaborator_invitations" */
export type File_Collaborator_Invitations_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  creator?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  creator_id?: InputMaybe<Scalars['uuid']>;
  email?: InputMaybe<Scalars['String']>;
  expired_at?: InputMaybe<Scalars['timestamptz']>;
  file_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  permission?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type File_Collaborator_Invitations_Max_Fields = {
  __typename?: 'file_collaborator_invitations_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  creator_id?: Maybe<Scalars['uuid']>;
  email?: Maybe<Scalars['String']>;
  expired_at?: Maybe<Scalars['timestamptz']>;
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  permission?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type File_Collaborator_Invitations_Min_Fields = {
  __typename?: 'file_collaborator_invitations_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  creator_id?: Maybe<Scalars['uuid']>;
  email?: Maybe<Scalars['String']>;
  expired_at?: Maybe<Scalars['timestamptz']>;
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  permission?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "file_collaborator_invitations" */
export type File_Collaborator_Invitations_Mutation_Response = {
  __typename?: 'file_collaborator_invitations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<File_Collaborator_Invitations>;
};

/** on_conflict condition type for table "file_collaborator_invitations" */
export type File_Collaborator_Invitations_On_Conflict = {
  constraint: File_Collaborator_Invitations_Constraint;
  update_columns?: Array<File_Collaborator_Invitations_Update_Column>;
  where?: InputMaybe<File_Collaborator_Invitations_Bool_Exp>;
};

/** Ordering options when selecting data from "file_collaborator_invitations". */
export type File_Collaborator_Invitations_Order_By = {
  created_at?: InputMaybe<Order_By>;
  creator?: InputMaybe<Users_Order_By>;
  creator_id?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  expired_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  permission?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: file_collaborator_invitations */
export type File_Collaborator_Invitations_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "file_collaborator_invitations" */
export enum File_Collaborator_Invitations_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  Email = 'email',
  /** column name */
  ExpiredAt = 'expired_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Permission = 'permission',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "file_collaborator_invitations" */
export type File_Collaborator_Invitations_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  creator_id?: InputMaybe<Scalars['uuid']>;
  email?: InputMaybe<Scalars['String']>;
  expired_at?: InputMaybe<Scalars['timestamptz']>;
  file_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  permission?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "file_collaborator_invitations" */
export type File_Collaborator_Invitations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: File_Collaborator_Invitations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type File_Collaborator_Invitations_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  creator_id?: InputMaybe<Scalars['uuid']>;
  email?: InputMaybe<Scalars['String']>;
  expired_at?: InputMaybe<Scalars['timestamptz']>;
  file_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  permission?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "file_collaborator_invitations" */
export enum File_Collaborator_Invitations_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  Email = 'email',
  /** column name */
  ExpiredAt = 'expired_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Permission = 'permission',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type File_Collaborator_Invitations_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<File_Collaborator_Invitations_Set_Input>;
  where: File_Collaborator_Invitations_Bool_Exp;
};

/** columns and relationships of "file_collaborators" */
export type File_Collaborators = {
  __typename?: 'file_collaborators';
  created_at: Scalars['timestamptz'];
  expired_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  file?: Maybe<Files>;
  file_collaborator_invitation_id?: Maybe<Scalars['uuid']>;
  file_id: Scalars['uuid'];
  permission: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "file_collaborators" */
export type File_Collaborators_Aggregate = {
  __typename?: 'file_collaborators_aggregate';
  aggregate?: Maybe<File_Collaborators_Aggregate_Fields>;
  nodes: Array<File_Collaborators>;
};

export type File_Collaborators_Aggregate_Bool_Exp = {
  count?: InputMaybe<File_Collaborators_Aggregate_Bool_Exp_Count>;
};

export type File_Collaborators_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<File_Collaborators_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<File_Collaborators_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "file_collaborators" */
export type File_Collaborators_Aggregate_Fields = {
  __typename?: 'file_collaborators_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<File_Collaborators_Max_Fields>;
  min?: Maybe<File_Collaborators_Min_Fields>;
};


/** aggregate fields of "file_collaborators" */
export type File_Collaborators_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<File_Collaborators_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "file_collaborators" */
export type File_Collaborators_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<File_Collaborators_Max_Order_By>;
  min?: InputMaybe<File_Collaborators_Min_Order_By>;
};

/** input type for inserting array relation for remote table "file_collaborators" */
export type File_Collaborators_Arr_Rel_Insert_Input = {
  data: Array<File_Collaborators_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<File_Collaborators_On_Conflict>;
};

/** Boolean expression to filter rows from the table "file_collaborators". All fields are combined with a logical 'AND'. */
export type File_Collaborators_Bool_Exp = {
  _and?: InputMaybe<Array<File_Collaborators_Bool_Exp>>;
  _not?: InputMaybe<File_Collaborators_Bool_Exp>;
  _or?: InputMaybe<Array<File_Collaborators_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  expired_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  file?: InputMaybe<Files_Bool_Exp>;
  file_collaborator_invitation_id?: InputMaybe<Uuid_Comparison_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
  permission?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "file_collaborators" */
export enum File_Collaborators_Constraint {
  /** unique or primary key constraint on columns "user_id", "file_id" */
  FileCollaboratorsPk = 'file_collaborators_pk'
}

/** input type for inserting data into table "file_collaborators" */
export type File_Collaborators_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  expired_at?: InputMaybe<Scalars['timestamptz']>;
  file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  file_collaborator_invitation_id?: InputMaybe<Scalars['uuid']>;
  file_id?: InputMaybe<Scalars['uuid']>;
  permission?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type File_Collaborators_Max_Fields = {
  __typename?: 'file_collaborators_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  expired_at?: Maybe<Scalars['timestamptz']>;
  file_collaborator_invitation_id?: Maybe<Scalars['uuid']>;
  file_id?: Maybe<Scalars['uuid']>;
  permission?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "file_collaborators" */
export type File_Collaborators_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  expired_at?: InputMaybe<Order_By>;
  file_collaborator_invitation_id?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  permission?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type File_Collaborators_Min_Fields = {
  __typename?: 'file_collaborators_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  expired_at?: Maybe<Scalars['timestamptz']>;
  file_collaborator_invitation_id?: Maybe<Scalars['uuid']>;
  file_id?: Maybe<Scalars['uuid']>;
  permission?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "file_collaborators" */
export type File_Collaborators_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  expired_at?: InputMaybe<Order_By>;
  file_collaborator_invitation_id?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  permission?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "file_collaborators" */
export type File_Collaborators_Mutation_Response = {
  __typename?: 'file_collaborators_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<File_Collaborators>;
};

/** on_conflict condition type for table "file_collaborators" */
export type File_Collaborators_On_Conflict = {
  constraint: File_Collaborators_Constraint;
  update_columns?: Array<File_Collaborators_Update_Column>;
  where?: InputMaybe<File_Collaborators_Bool_Exp>;
};

/** Ordering options when selecting data from "file_collaborators". */
export type File_Collaborators_Order_By = {
  created_at?: InputMaybe<Order_By>;
  expired_at?: InputMaybe<Order_By>;
  file?: InputMaybe<Files_Order_By>;
  file_collaborator_invitation_id?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  permission?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: file_collaborators */
export type File_Collaborators_Pk_Columns_Input = {
  file_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

/** select columns of table "file_collaborators" */
export enum File_Collaborators_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiredAt = 'expired_at',
  /** column name */
  FileCollaboratorInvitationId = 'file_collaborator_invitation_id',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Permission = 'permission',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "file_collaborators" */
export type File_Collaborators_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  expired_at?: InputMaybe<Scalars['timestamptz']>;
  file_collaborator_invitation_id?: InputMaybe<Scalars['uuid']>;
  file_id?: InputMaybe<Scalars['uuid']>;
  permission?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "file_collaborators" */
export type File_Collaborators_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: File_Collaborators_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type File_Collaborators_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  expired_at?: InputMaybe<Scalars['timestamptz']>;
  file_collaborator_invitation_id?: InputMaybe<Scalars['uuid']>;
  file_id?: InputMaybe<Scalars['uuid']>;
  permission?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "file_collaborators" */
export enum File_Collaborators_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiredAt = 'expired_at',
  /** column name */
  FileCollaboratorInvitationId = 'file_collaborator_invitation_id',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Permission = 'permission',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type File_Collaborators_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<File_Collaborators_Set_Input>;
  where: File_Collaborators_Bool_Exp;
};

/** columns and relationships of "file_users_view" */
export type File_Users_View = {
  __typename?: 'file_users_view';
  avatar?: Maybe<Scalars['String']>;
  collaborator_limit?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_slug?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  file_id?: Maybe<Scalars['String']>;
  file_role?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  invitation_creator_id?: Maybe<Scalars['String']>;
  invitation_id?: Maybe<Scalars['String']>;
  invitation_status?: Maybe<Scalars['String']>;
  joined_at?: Maybe<Scalars['String']>;
  last_active_at?: Maybe<Scalars['timestamptz']>;
  permission?: Maybe<Scalars['String']>;
  sort_priority?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
  workspace_icon?: Maybe<Scalars['String']>;
};

/** aggregated selection of "file_users_view" */
export type File_Users_View_Aggregate = {
  __typename?: 'file_users_view_aggregate';
  aggregate?: Maybe<File_Users_View_Aggregate_Fields>;
  nodes: Array<File_Users_View>;
};

/** aggregate fields of "file_users_view" */
export type File_Users_View_Aggregate_Fields = {
  __typename?: 'file_users_view_aggregate_fields';
  avg?: Maybe<File_Users_View_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<File_Users_View_Max_Fields>;
  min?: Maybe<File_Users_View_Min_Fields>;
  stddev?: Maybe<File_Users_View_Stddev_Fields>;
  stddev_pop?: Maybe<File_Users_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<File_Users_View_Stddev_Samp_Fields>;
  sum?: Maybe<File_Users_View_Sum_Fields>;
  var_pop?: Maybe<File_Users_View_Var_Pop_Fields>;
  var_samp?: Maybe<File_Users_View_Var_Samp_Fields>;
  variance?: Maybe<File_Users_View_Variance_Fields>;
};


/** aggregate fields of "file_users_view" */
export type File_Users_View_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<File_Users_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type File_Users_View_Avg_Fields = {
  __typename?: 'file_users_view_avg_fields';
  collaborator_limit?: Maybe<Scalars['Float']>;
  sort_priority?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "file_users_view". All fields are combined with a logical 'AND'. */
export type File_Users_View_Bool_Exp = {
  _and?: InputMaybe<Array<File_Users_View_Bool_Exp>>;
  _not?: InputMaybe<File_Users_View_Bool_Exp>;
  _or?: InputMaybe<Array<File_Users_View_Bool_Exp>>;
  avatar?: InputMaybe<String_Comparison_Exp>;
  collaborator_limit?: InputMaybe<Numeric_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  custom_slug?: InputMaybe<String_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  file_id?: InputMaybe<String_Comparison_Exp>;
  file_role?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invitation_creator_id?: InputMaybe<String_Comparison_Exp>;
  invitation_id?: InputMaybe<String_Comparison_Exp>;
  invitation_status?: InputMaybe<String_Comparison_Exp>;
  joined_at?: InputMaybe<String_Comparison_Exp>;
  last_active_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  permission?: InputMaybe<String_Comparison_Exp>;
  sort_priority?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  username?: InputMaybe<String_Comparison_Exp>;
  workspace_icon?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type File_Users_View_Max_Fields = {
  __typename?: 'file_users_view_max_fields';
  avatar?: Maybe<Scalars['String']>;
  collaborator_limit?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_slug?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  file_id?: Maybe<Scalars['String']>;
  file_role?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  invitation_creator_id?: Maybe<Scalars['String']>;
  invitation_id?: Maybe<Scalars['String']>;
  invitation_status?: Maybe<Scalars['String']>;
  joined_at?: Maybe<Scalars['String']>;
  last_active_at?: Maybe<Scalars['timestamptz']>;
  permission?: Maybe<Scalars['String']>;
  sort_priority?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
  workspace_icon?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type File_Users_View_Min_Fields = {
  __typename?: 'file_users_view_min_fields';
  avatar?: Maybe<Scalars['String']>;
  collaborator_limit?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_slug?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  file_id?: Maybe<Scalars['String']>;
  file_role?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  invitation_creator_id?: Maybe<Scalars['String']>;
  invitation_id?: Maybe<Scalars['String']>;
  invitation_status?: Maybe<Scalars['String']>;
  joined_at?: Maybe<Scalars['String']>;
  last_active_at?: Maybe<Scalars['timestamptz']>;
  permission?: Maybe<Scalars['String']>;
  sort_priority?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
  workspace_icon?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "file_users_view". */
export type File_Users_View_Order_By = {
  avatar?: InputMaybe<Order_By>;
  collaborator_limit?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  custom_slug?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  file_role?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invitation_creator_id?: InputMaybe<Order_By>;
  invitation_id?: InputMaybe<Order_By>;
  invitation_status?: InputMaybe<Order_By>;
  joined_at?: InputMaybe<Order_By>;
  last_active_at?: InputMaybe<Order_By>;
  permission?: InputMaybe<Order_By>;
  sort_priority?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
  workspace_icon?: InputMaybe<Order_By>;
};

/** select columns of table "file_users_view" */
export enum File_Users_View_Select_Column {
  /** column name */
  Avatar = 'avatar',
  /** column name */
  CollaboratorLimit = 'collaborator_limit',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomSlug = 'custom_slug',
  /** column name */
  Email = 'email',
  /** column name */
  FileId = 'file_id',
  /** column name */
  FileRole = 'file_role',
  /** column name */
  Id = 'id',
  /** column name */
  InvitationCreatorId = 'invitation_creator_id',
  /** column name */
  InvitationId = 'invitation_id',
  /** column name */
  InvitationStatus = 'invitation_status',
  /** column name */
  JoinedAt = 'joined_at',
  /** column name */
  LastActiveAt = 'last_active_at',
  /** column name */
  Permission = 'permission',
  /** column name */
  SortPriority = 'sort_priority',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Username = 'username',
  /** column name */
  WorkspaceIcon = 'workspace_icon'
}

/** aggregate stddev on columns */
export type File_Users_View_Stddev_Fields = {
  __typename?: 'file_users_view_stddev_fields';
  collaborator_limit?: Maybe<Scalars['Float']>;
  sort_priority?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type File_Users_View_Stddev_Pop_Fields = {
  __typename?: 'file_users_view_stddev_pop_fields';
  collaborator_limit?: Maybe<Scalars['Float']>;
  sort_priority?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type File_Users_View_Stddev_Samp_Fields = {
  __typename?: 'file_users_view_stddev_samp_fields';
  collaborator_limit?: Maybe<Scalars['Float']>;
  sort_priority?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "file_users_view" */
export type File_Users_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: File_Users_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type File_Users_View_Stream_Cursor_Value_Input = {
  avatar?: InputMaybe<Scalars['String']>;
  collaborator_limit?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  custom_slug?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  file_id?: InputMaybe<Scalars['String']>;
  file_role?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  invitation_creator_id?: InputMaybe<Scalars['String']>;
  invitation_id?: InputMaybe<Scalars['String']>;
  invitation_status?: InputMaybe<Scalars['String']>;
  joined_at?: InputMaybe<Scalars['String']>;
  last_active_at?: InputMaybe<Scalars['timestamptz']>;
  permission?: InputMaybe<Scalars['String']>;
  sort_priority?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  username?: InputMaybe<Scalars['String']>;
  workspace_icon?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type File_Users_View_Sum_Fields = {
  __typename?: 'file_users_view_sum_fields';
  collaborator_limit?: Maybe<Scalars['numeric']>;
  sort_priority?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type File_Users_View_Var_Pop_Fields = {
  __typename?: 'file_users_view_var_pop_fields';
  collaborator_limit?: Maybe<Scalars['Float']>;
  sort_priority?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type File_Users_View_Var_Samp_Fields = {
  __typename?: 'file_users_view_var_samp_fields';
  collaborator_limit?: Maybe<Scalars['Float']>;
  sort_priority?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type File_Users_View_Variance_Fields = {
  __typename?: 'file_users_view_variance_fields';
  collaborator_limit?: Maybe<Scalars['Float']>;
  sort_priority?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "files" */
export type Files = {
  __typename?: 'files';
  author?: Maybe<Scalars['String']>;
  author_source?: Maybe<Scalars['String']>;
  /** An array relationship */
  collaborators: Array<File_Collaborators>;
  /** An aggregate relationship */
  collaborators_aggregate: File_Collaborators_Aggregate;
  created_at: Scalars['timestamptz'];
  creator_id: Scalars['uuid'];
  description?: Maybe<Scalars['String']>;
  draft_permission?: Maybe<Scalars['String']>;
  feature?: Maybe<Scalars['Int']>;
  figma_export_key?: Maybe<Scalars['uuid']>;
  file_key: Scalars['uuid'];
  id: Scalars['uuid'];
  is_plugin_export?: Maybe<Scalars['Int']>;
  /** An object relationship */
  last_viewed?: Maybe<User_File_Last_Viewed>;
  name?: Maybe<Scalars['String']>;
  permission?: Maybe<Scalars['String']>;
  /** An object relationship */
  project?: Maybe<Projects>;
  project_id: Scalars['uuid'];
  status?: Maybe<Scalars['String']>;
  thumbnail_key: Scalars['uuid'];
  thumbnail_url?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "files" */
export type FilesCollaboratorsArgs = {
  distinct_on?: InputMaybe<Array<File_Collaborators_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Collaborators_Order_By>>;
  where?: InputMaybe<File_Collaborators_Bool_Exp>;
};


/** columns and relationships of "files" */
export type FilesCollaborators_AggregateArgs = {
  distinct_on?: InputMaybe<Array<File_Collaborators_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Collaborators_Order_By>>;
  where?: InputMaybe<File_Collaborators_Bool_Exp>;
};

/** aggregated selection of "files" */
export type Files_Aggregate = {
  __typename?: 'files_aggregate';
  aggregate?: Maybe<Files_Aggregate_Fields>;
  nodes: Array<Files>;
};

export type Files_Aggregate_Bool_Exp = {
  count?: InputMaybe<Files_Aggregate_Bool_Exp_Count>;
};

export type Files_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Files_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Files_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "files" */
export type Files_Aggregate_Fields = {
  __typename?: 'files_aggregate_fields';
  avg?: Maybe<Files_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Files_Max_Fields>;
  min?: Maybe<Files_Min_Fields>;
  stddev?: Maybe<Files_Stddev_Fields>;
  stddev_pop?: Maybe<Files_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Files_Stddev_Samp_Fields>;
  sum?: Maybe<Files_Sum_Fields>;
  var_pop?: Maybe<Files_Var_Pop_Fields>;
  var_samp?: Maybe<Files_Var_Samp_Fields>;
  variance?: Maybe<Files_Variance_Fields>;
};


/** aggregate fields of "files" */
export type Files_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Files_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "files" */
export type Files_Aggregate_Order_By = {
  avg?: InputMaybe<Files_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Files_Max_Order_By>;
  min?: InputMaybe<Files_Min_Order_By>;
  stddev?: InputMaybe<Files_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Files_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Files_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Files_Sum_Order_By>;
  var_pop?: InputMaybe<Files_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Files_Var_Samp_Order_By>;
  variance?: InputMaybe<Files_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "files" */
export type Files_Arr_Rel_Insert_Input = {
  data: Array<Files_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Files_On_Conflict>;
};

/** aggregate avg on columns */
export type Files_Avg_Fields = {
  __typename?: 'files_avg_fields';
  feature?: Maybe<Scalars['Float']>;
  is_plugin_export?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "files" */
export type Files_Avg_Order_By = {
  feature?: InputMaybe<Order_By>;
  is_plugin_export?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "files". All fields are combined with a logical 'AND'. */
export type Files_Bool_Exp = {
  _and?: InputMaybe<Array<Files_Bool_Exp>>;
  _not?: InputMaybe<Files_Bool_Exp>;
  _or?: InputMaybe<Array<Files_Bool_Exp>>;
  author?: InputMaybe<String_Comparison_Exp>;
  author_source?: InputMaybe<String_Comparison_Exp>;
  collaborators?: InputMaybe<File_Collaborators_Bool_Exp>;
  collaborators_aggregate?: InputMaybe<File_Collaborators_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  creator_id?: InputMaybe<Uuid_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  draft_permission?: InputMaybe<String_Comparison_Exp>;
  feature?: InputMaybe<Int_Comparison_Exp>;
  figma_export_key?: InputMaybe<Uuid_Comparison_Exp>;
  file_key?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_plugin_export?: InputMaybe<Int_Comparison_Exp>;
  last_viewed?: InputMaybe<User_File_Last_Viewed_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  permission?: InputMaybe<String_Comparison_Exp>;
  project?: InputMaybe<Projects_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  thumbnail_key?: InputMaybe<Uuid_Comparison_Exp>;
  thumbnail_url?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "files" */
export enum Files_Constraint {
  /** unique or primary key constraint on columns "id" */
  FilesPkey = 'files_pkey'
}

/** input type for incrementing numeric columns in table "files" */
export type Files_Inc_Input = {
  feature?: InputMaybe<Scalars['Int']>;
  is_plugin_export?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "files" */
export type Files_Insert_Input = {
  author?: InputMaybe<Scalars['String']>;
  author_source?: InputMaybe<Scalars['String']>;
  collaborators?: InputMaybe<File_Collaborators_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  creator_id?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  draft_permission?: InputMaybe<Scalars['String']>;
  feature?: InputMaybe<Scalars['Int']>;
  figma_export_key?: InputMaybe<Scalars['uuid']>;
  file_key?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_plugin_export?: InputMaybe<Scalars['Int']>;
  last_viewed?: InputMaybe<User_File_Last_Viewed_Obj_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']>;
  permission?: InputMaybe<Scalars['String']>;
  project?: InputMaybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  thumbnail_key?: InputMaybe<Scalars['uuid']>;
  thumbnail_url?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Files_Max_Fields = {
  __typename?: 'files_max_fields';
  author?: Maybe<Scalars['String']>;
  author_source?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  creator_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  draft_permission?: Maybe<Scalars['String']>;
  feature?: Maybe<Scalars['Int']>;
  figma_export_key?: Maybe<Scalars['uuid']>;
  file_key?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  is_plugin_export?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  permission?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  thumbnail_key?: Maybe<Scalars['uuid']>;
  thumbnail_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "files" */
export type Files_Max_Order_By = {
  author?: InputMaybe<Order_By>;
  author_source?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  creator_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  draft_permission?: InputMaybe<Order_By>;
  feature?: InputMaybe<Order_By>;
  figma_export_key?: InputMaybe<Order_By>;
  file_key?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_plugin_export?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  permission?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  thumbnail_key?: InputMaybe<Order_By>;
  thumbnail_url?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Files_Min_Fields = {
  __typename?: 'files_min_fields';
  author?: Maybe<Scalars['String']>;
  author_source?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  creator_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  draft_permission?: Maybe<Scalars['String']>;
  feature?: Maybe<Scalars['Int']>;
  figma_export_key?: Maybe<Scalars['uuid']>;
  file_key?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  is_plugin_export?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  permission?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  thumbnail_key?: Maybe<Scalars['uuid']>;
  thumbnail_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "files" */
export type Files_Min_Order_By = {
  author?: InputMaybe<Order_By>;
  author_source?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  creator_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  draft_permission?: InputMaybe<Order_By>;
  feature?: InputMaybe<Order_By>;
  figma_export_key?: InputMaybe<Order_By>;
  file_key?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_plugin_export?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  permission?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  thumbnail_key?: InputMaybe<Order_By>;
  thumbnail_url?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "files" */
export type Files_Mutation_Response = {
  __typename?: 'files_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Files>;
};

/** input type for inserting object relation for remote table "files" */
export type Files_Obj_Rel_Insert_Input = {
  data: Files_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Files_On_Conflict>;
};

/** on_conflict condition type for table "files" */
export type Files_On_Conflict = {
  constraint: Files_Constraint;
  update_columns?: Array<Files_Update_Column>;
  where?: InputMaybe<Files_Bool_Exp>;
};

/** Ordering options when selecting data from "files". */
export type Files_Order_By = {
  author?: InputMaybe<Order_By>;
  author_source?: InputMaybe<Order_By>;
  collaborators_aggregate?: InputMaybe<File_Collaborators_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  creator_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  draft_permission?: InputMaybe<Order_By>;
  feature?: InputMaybe<Order_By>;
  figma_export_key?: InputMaybe<Order_By>;
  file_key?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_plugin_export?: InputMaybe<Order_By>;
  last_viewed?: InputMaybe<User_File_Last_Viewed_Order_By>;
  name?: InputMaybe<Order_By>;
  permission?: InputMaybe<Order_By>;
  project?: InputMaybe<Projects_Order_By>;
  project_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  thumbnail_key?: InputMaybe<Order_By>;
  thumbnail_url?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: files */
export type Files_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "files" */
export enum Files_Select_Column {
  /** column name */
  Author = 'author',
  /** column name */
  AuthorSource = 'author_source',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  Description = 'description',
  /** column name */
  DraftPermission = 'draft_permission',
  /** column name */
  Feature = 'feature',
  /** column name */
  FigmaExportKey = 'figma_export_key',
  /** column name */
  FileKey = 'file_key',
  /** column name */
  Id = 'id',
  /** column name */
  IsPluginExport = 'is_plugin_export',
  /** column name */
  Name = 'name',
  /** column name */
  Permission = 'permission',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Status = 'status',
  /** column name */
  ThumbnailKey = 'thumbnail_key',
  /** column name */
  ThumbnailUrl = 'thumbnail_url',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "files" */
export type Files_Set_Input = {
  author?: InputMaybe<Scalars['String']>;
  author_source?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  creator_id?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  draft_permission?: InputMaybe<Scalars['String']>;
  feature?: InputMaybe<Scalars['Int']>;
  figma_export_key?: InputMaybe<Scalars['uuid']>;
  file_key?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_plugin_export?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  permission?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  thumbnail_key?: InputMaybe<Scalars['uuid']>;
  thumbnail_url?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Files_Stddev_Fields = {
  __typename?: 'files_stddev_fields';
  feature?: Maybe<Scalars['Float']>;
  is_plugin_export?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "files" */
export type Files_Stddev_Order_By = {
  feature?: InputMaybe<Order_By>;
  is_plugin_export?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Files_Stddev_Pop_Fields = {
  __typename?: 'files_stddev_pop_fields';
  feature?: Maybe<Scalars['Float']>;
  is_plugin_export?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "files" */
export type Files_Stddev_Pop_Order_By = {
  feature?: InputMaybe<Order_By>;
  is_plugin_export?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Files_Stddev_Samp_Fields = {
  __typename?: 'files_stddev_samp_fields';
  feature?: Maybe<Scalars['Float']>;
  is_plugin_export?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "files" */
export type Files_Stddev_Samp_Order_By = {
  feature?: InputMaybe<Order_By>;
  is_plugin_export?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "files" */
export type Files_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Files_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Files_Stream_Cursor_Value_Input = {
  author?: InputMaybe<Scalars['String']>;
  author_source?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  creator_id?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  draft_permission?: InputMaybe<Scalars['String']>;
  feature?: InputMaybe<Scalars['Int']>;
  figma_export_key?: InputMaybe<Scalars['uuid']>;
  file_key?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_plugin_export?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  permission?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  thumbnail_key?: InputMaybe<Scalars['uuid']>;
  thumbnail_url?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Files_Sum_Fields = {
  __typename?: 'files_sum_fields';
  feature?: Maybe<Scalars['Int']>;
  is_plugin_export?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "files" */
export type Files_Sum_Order_By = {
  feature?: InputMaybe<Order_By>;
  is_plugin_export?: InputMaybe<Order_By>;
};

/** update columns of table "files" */
export enum Files_Update_Column {
  /** column name */
  Author = 'author',
  /** column name */
  AuthorSource = 'author_source',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  Description = 'description',
  /** column name */
  DraftPermission = 'draft_permission',
  /** column name */
  Feature = 'feature',
  /** column name */
  FigmaExportKey = 'figma_export_key',
  /** column name */
  FileKey = 'file_key',
  /** column name */
  Id = 'id',
  /** column name */
  IsPluginExport = 'is_plugin_export',
  /** column name */
  Name = 'name',
  /** column name */
  Permission = 'permission',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Status = 'status',
  /** column name */
  ThumbnailKey = 'thumbnail_key',
  /** column name */
  ThumbnailUrl = 'thumbnail_url',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Files_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Files_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Files_Set_Input>;
  where: Files_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Files_Var_Pop_Fields = {
  __typename?: 'files_var_pop_fields';
  feature?: Maybe<Scalars['Float']>;
  is_plugin_export?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "files" */
export type Files_Var_Pop_Order_By = {
  feature?: InputMaybe<Order_By>;
  is_plugin_export?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Files_Var_Samp_Fields = {
  __typename?: 'files_var_samp_fields';
  feature?: Maybe<Scalars['Float']>;
  is_plugin_export?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "files" */
export type Files_Var_Samp_Order_By = {
  feature?: InputMaybe<Order_By>;
  is_plugin_export?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Files_Variance_Fields = {
  __typename?: 'files_variance_fields';
  feature?: Maybe<Scalars['Float']>;
  is_plugin_export?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "files" */
export type Files_Variance_Order_By = {
  feature?: InputMaybe<Order_By>;
  is_plugin_export?: InputMaybe<Order_By>;
};

export type Get_Users_By_Contributors_Args = {
  version_row?: InputMaybe<Scalars['versions_scalar']>;
};

/** columns and relationships of "images" */
export type Images = {
  __typename?: 'images';
  created_at: Scalars['timestamptz'];
  creator_id: Scalars['uuid'];
  download_key: Scalars['uuid'];
  file_list?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  name?: Maybe<Scalars['String']>;
  preview_key: Scalars['uuid'];
  sha: Scalars['String'];
  thumbnail_key: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "images" */
export type Images_Aggregate = {
  __typename?: 'images_aggregate';
  aggregate?: Maybe<Images_Aggregate_Fields>;
  nodes: Array<Images>;
};

/** aggregate fields of "images" */
export type Images_Aggregate_Fields = {
  __typename?: 'images_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Images_Max_Fields>;
  min?: Maybe<Images_Min_Fields>;
};


/** aggregate fields of "images" */
export type Images_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Images_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "images". All fields are combined with a logical 'AND'. */
export type Images_Bool_Exp = {
  _and?: InputMaybe<Array<Images_Bool_Exp>>;
  _not?: InputMaybe<Images_Bool_Exp>;
  _or?: InputMaybe<Array<Images_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  creator_id?: InputMaybe<Uuid_Comparison_Exp>;
  download_key?: InputMaybe<Uuid_Comparison_Exp>;
  file_list?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  preview_key?: InputMaybe<Uuid_Comparison_Exp>;
  sha?: InputMaybe<String_Comparison_Exp>;
  thumbnail_key?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "images" */
export enum Images_Constraint {
  /** unique or primary key constraint on columns "id" */
  ImagesPkey = 'images_pkey'
}

/** input type for inserting data into table "images" */
export type Images_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  creator_id?: InputMaybe<Scalars['uuid']>;
  download_key?: InputMaybe<Scalars['uuid']>;
  file_list?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  preview_key?: InputMaybe<Scalars['uuid']>;
  sha?: InputMaybe<Scalars['String']>;
  thumbnail_key?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Images_Max_Fields = {
  __typename?: 'images_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  creator_id?: Maybe<Scalars['uuid']>;
  download_key?: Maybe<Scalars['uuid']>;
  file_list?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  preview_key?: Maybe<Scalars['uuid']>;
  sha?: Maybe<Scalars['String']>;
  thumbnail_key?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Images_Min_Fields = {
  __typename?: 'images_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  creator_id?: Maybe<Scalars['uuid']>;
  download_key?: Maybe<Scalars['uuid']>;
  file_list?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  preview_key?: Maybe<Scalars['uuid']>;
  sha?: Maybe<Scalars['String']>;
  thumbnail_key?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "images" */
export type Images_Mutation_Response = {
  __typename?: 'images_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Images>;
};

/** on_conflict condition type for table "images" */
export type Images_On_Conflict = {
  constraint: Images_Constraint;
  update_columns?: Array<Images_Update_Column>;
  where?: InputMaybe<Images_Bool_Exp>;
};

/** Ordering options when selecting data from "images". */
export type Images_Order_By = {
  created_at?: InputMaybe<Order_By>;
  creator_id?: InputMaybe<Order_By>;
  download_key?: InputMaybe<Order_By>;
  file_list?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  preview_key?: InputMaybe<Order_By>;
  sha?: InputMaybe<Order_By>;
  thumbnail_key?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: images */
export type Images_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "images" */
export enum Images_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  DownloadKey = 'download_key',
  /** column name */
  FileList = 'file_list',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PreviewKey = 'preview_key',
  /** column name */
  Sha = 'sha',
  /** column name */
  ThumbnailKey = 'thumbnail_key',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "images" */
export type Images_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  creator_id?: InputMaybe<Scalars['uuid']>;
  download_key?: InputMaybe<Scalars['uuid']>;
  file_list?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  preview_key?: InputMaybe<Scalars['uuid']>;
  sha?: InputMaybe<Scalars['String']>;
  thumbnail_key?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "images" */
export type Images_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Images_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Images_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  creator_id?: InputMaybe<Scalars['uuid']>;
  download_key?: InputMaybe<Scalars['uuid']>;
  file_list?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  preview_key?: InputMaybe<Scalars['uuid']>;
  sha?: InputMaybe<Scalars['String']>;
  thumbnail_key?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "images" */
export enum Images_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  DownloadKey = 'download_key',
  /** column name */
  FileList = 'file_list',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PreviewKey = 'preview_key',
  /** column name */
  Sha = 'sha',
  /** column name */
  ThumbnailKey = 'thumbnail_key',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Images_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Images_Set_Input>;
  where: Images_Bool_Exp;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "file_collaborator_invitations" */
  delete_file_collaborator_invitations?: Maybe<File_Collaborator_Invitations_Mutation_Response>;
  /** delete single row from the table: "file_collaborator_invitations" */
  delete_file_collaborator_invitations_by_pk?: Maybe<File_Collaborator_Invitations>;
  /** delete data from the table: "file_collaborators" */
  delete_file_collaborators?: Maybe<File_Collaborators_Mutation_Response>;
  /** delete single row from the table: "file_collaborators" */
  delete_file_collaborators_by_pk?: Maybe<File_Collaborators>;
  /** delete data from the table: "files" */
  delete_files?: Maybe<Files_Mutation_Response>;
  /** delete single row from the table: "files" */
  delete_files_by_pk?: Maybe<Files>;
  /** delete data from the table: "images" */
  delete_images?: Maybe<Images_Mutation_Response>;
  /** delete single row from the table: "images" */
  delete_images_by_pk?: Maybe<Images>;
  /** delete data from the table: "project_collaborator_invitations" */
  delete_project_collaborator_invitations?: Maybe<Project_Collaborator_Invitations_Mutation_Response>;
  /** delete single row from the table: "project_collaborator_invitations" */
  delete_project_collaborator_invitations_by_pk?: Maybe<Project_Collaborator_Invitations>;
  /** delete data from the table: "project_user_groups" */
  delete_project_user_groups?: Maybe<Project_User_Groups_Mutation_Response>;
  /** delete single row from the table: "project_user_groups" */
  delete_project_user_groups_by_pk?: Maybe<Project_User_Groups>;
  /** delete data from the table: "project_users" */
  delete_project_users?: Maybe<Project_Users_Mutation_Response>;
  /** delete single row from the table: "project_users" */
  delete_project_users_by_pk?: Maybe<Project_Users>;
  /** delete data from the table: "projects" */
  delete_projects?: Maybe<Projects_Mutation_Response>;
  /** delete single row from the table: "projects" */
  delete_projects_by_pk?: Maybe<Projects>;
  /** delete data from the table: "share_links" */
  delete_share_links?: Maybe<Share_Links_Mutation_Response>;
  /** delete data from the table: "team_delete_intents" */
  delete_team_delete_intents?: Maybe<Team_Delete_Intents_Mutation_Response>;
  /** delete single row from the table: "team_delete_intents" */
  delete_team_delete_intents_by_pk?: Maybe<Team_Delete_Intents>;
  /** delete data from the table: "team_ownership_transfer_intents" */
  delete_team_ownership_transfer_intents?: Maybe<Team_Ownership_Transfer_Intents_Mutation_Response>;
  /** delete single row from the table: "team_ownership_transfer_intents" */
  delete_team_ownership_transfer_intents_by_pk?: Maybe<Team_Ownership_Transfer_Intents>;
  /** delete data from the table: "team_user_group_users" */
  delete_team_user_group_users?: Maybe<Team_User_Group_Users_Mutation_Response>;
  /** delete single row from the table: "team_user_group_users" */
  delete_team_user_group_users_by_pk?: Maybe<Team_User_Group_Users>;
  /** delete data from the table: "team_user_groups" */
  delete_team_user_groups?: Maybe<Team_User_Groups_Mutation_Response>;
  /** delete single row from the table: "team_user_groups" */
  delete_team_user_groups_by_pk?: Maybe<Team_User_Groups>;
  /** delete data from the table: "team_user_invitations" */
  delete_team_user_invitations?: Maybe<Team_User_Invitations_Mutation_Response>;
  /** delete single row from the table: "team_user_invitations" */
  delete_team_user_invitations_by_pk?: Maybe<Team_User_Invitations>;
  /** delete data from the table: "team_users" */
  delete_team_users?: Maybe<Team_Users_Mutation_Response>;
  /** delete single row from the table: "team_users" */
  delete_team_users_by_pk?: Maybe<Team_Users>;
  /** delete data from the table: "teams" */
  delete_teams?: Maybe<Teams_Mutation_Response>;
  /** delete single row from the table: "teams" */
  delete_teams_by_pk?: Maybe<Teams>;
  /** delete data from the table: "user_file_last_viewed" */
  delete_user_file_last_viewed?: Maybe<User_File_Last_Viewed_Mutation_Response>;
  /** delete single row from the table: "user_file_last_viewed" */
  delete_user_file_last_viewed_by_pk?: Maybe<User_File_Last_Viewed>;
  /** delete data from the table: "user_preferences" */
  delete_user_preferences?: Maybe<User_Preferences_Mutation_Response>;
  /** delete single row from the table: "user_preferences" */
  delete_user_preferences_by_pk?: Maybe<User_Preferences>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** delete data from the table: "version_contributors" */
  delete_version_contributors?: Maybe<Version_Contributors_Mutation_Response>;
  /** delete single row from the table: "version_contributors" */
  delete_version_contributors_by_pk?: Maybe<Version_Contributors>;
  /** delete data from the table: "versions" */
  delete_versions?: Maybe<Versions_Mutation_Response>;
  /** delete single row from the table: "versions" */
  delete_versions_by_pk?: Maybe<Versions>;
  /** insert data into the table: "file_collaborator_invitations" */
  insert_file_collaborator_invitations?: Maybe<File_Collaborator_Invitations_Mutation_Response>;
  /** insert a single row into the table: "file_collaborator_invitations" */
  insert_file_collaborator_invitations_one?: Maybe<File_Collaborator_Invitations>;
  /** insert data into the table: "file_collaborators" */
  insert_file_collaborators?: Maybe<File_Collaborators_Mutation_Response>;
  /** insert a single row into the table: "file_collaborators" */
  insert_file_collaborators_one?: Maybe<File_Collaborators>;
  /** insert data into the table: "files" */
  insert_files?: Maybe<Files_Mutation_Response>;
  /** insert a single row into the table: "files" */
  insert_files_one?: Maybe<Files>;
  /** insert data into the table: "images" */
  insert_images?: Maybe<Images_Mutation_Response>;
  /** insert a single row into the table: "images" */
  insert_images_one?: Maybe<Images>;
  /** insert data into the table: "project_collaborator_invitations" */
  insert_project_collaborator_invitations?: Maybe<Project_Collaborator_Invitations_Mutation_Response>;
  /** insert a single row into the table: "project_collaborator_invitations" */
  insert_project_collaborator_invitations_one?: Maybe<Project_Collaborator_Invitations>;
  /** insert data into the table: "project_user_groups" */
  insert_project_user_groups?: Maybe<Project_User_Groups_Mutation_Response>;
  /** insert a single row into the table: "project_user_groups" */
  insert_project_user_groups_one?: Maybe<Project_User_Groups>;
  /** insert data into the table: "project_users" */
  insert_project_users?: Maybe<Project_Users_Mutation_Response>;
  /** insert a single row into the table: "project_users" */
  insert_project_users_one?: Maybe<Project_Users>;
  /** insert data into the table: "projects" */
  insert_projects?: Maybe<Projects_Mutation_Response>;
  /** insert a single row into the table: "projects" */
  insert_projects_one?: Maybe<Projects>;
  /** insert data into the table: "share_links" */
  insert_share_links?: Maybe<Share_Links_Mutation_Response>;
  /** insert a single row into the table: "share_links" */
  insert_share_links_one?: Maybe<Share_Links>;
  /** insert data into the table: "team_delete_intents" */
  insert_team_delete_intents?: Maybe<Team_Delete_Intents_Mutation_Response>;
  /** insert a single row into the table: "team_delete_intents" */
  insert_team_delete_intents_one?: Maybe<Team_Delete_Intents>;
  /** insert data into the table: "team_ownership_transfer_intents" */
  insert_team_ownership_transfer_intents?: Maybe<Team_Ownership_Transfer_Intents_Mutation_Response>;
  /** insert a single row into the table: "team_ownership_transfer_intents" */
  insert_team_ownership_transfer_intents_one?: Maybe<Team_Ownership_Transfer_Intents>;
  /** insert data into the table: "team_user_group_users" */
  insert_team_user_group_users?: Maybe<Team_User_Group_Users_Mutation_Response>;
  /** insert a single row into the table: "team_user_group_users" */
  insert_team_user_group_users_one?: Maybe<Team_User_Group_Users>;
  /** insert data into the table: "team_user_groups" */
  insert_team_user_groups?: Maybe<Team_User_Groups_Mutation_Response>;
  /** insert a single row into the table: "team_user_groups" */
  insert_team_user_groups_one?: Maybe<Team_User_Groups>;
  /** insert data into the table: "team_user_invitations" */
  insert_team_user_invitations?: Maybe<Team_User_Invitations_Mutation_Response>;
  /** insert a single row into the table: "team_user_invitations" */
  insert_team_user_invitations_one?: Maybe<Team_User_Invitations>;
  /** insert data into the table: "team_users" */
  insert_team_users?: Maybe<Team_Users_Mutation_Response>;
  /** insert a single row into the table: "team_users" */
  insert_team_users_one?: Maybe<Team_Users>;
  /** insert data into the table: "teams" */
  insert_teams?: Maybe<Teams_Mutation_Response>;
  /** insert a single row into the table: "teams" */
  insert_teams_one?: Maybe<Teams>;
  /** insert data into the table: "user_file_last_viewed" */
  insert_user_file_last_viewed?: Maybe<User_File_Last_Viewed_Mutation_Response>;
  /** insert a single row into the table: "user_file_last_viewed" */
  insert_user_file_last_viewed_one?: Maybe<User_File_Last_Viewed>;
  /** insert data into the table: "user_preferences" */
  insert_user_preferences?: Maybe<User_Preferences_Mutation_Response>;
  /** insert a single row into the table: "user_preferences" */
  insert_user_preferences_one?: Maybe<User_Preferences>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** insert data into the table: "version_contributors" */
  insert_version_contributors?: Maybe<Version_Contributors_Mutation_Response>;
  /** insert a single row into the table: "version_contributors" */
  insert_version_contributors_one?: Maybe<Version_Contributors>;
  /** insert data into the table: "versions" */
  insert_versions?: Maybe<Versions_Mutation_Response>;
  /** insert a single row into the table: "versions" */
  insert_versions_one?: Maybe<Versions>;
  /** update data of the table: "file_collaborator_invitations" */
  update_file_collaborator_invitations?: Maybe<File_Collaborator_Invitations_Mutation_Response>;
  /** update single row of the table: "file_collaborator_invitations" */
  update_file_collaborator_invitations_by_pk?: Maybe<File_Collaborator_Invitations>;
  /** update multiples rows of table: "file_collaborator_invitations" */
  update_file_collaborator_invitations_many?: Maybe<Array<Maybe<File_Collaborator_Invitations_Mutation_Response>>>;
  /** update data of the table: "file_collaborators" */
  update_file_collaborators?: Maybe<File_Collaborators_Mutation_Response>;
  /** update single row of the table: "file_collaborators" */
  update_file_collaborators_by_pk?: Maybe<File_Collaborators>;
  /** update multiples rows of table: "file_collaborators" */
  update_file_collaborators_many?: Maybe<Array<Maybe<File_Collaborators_Mutation_Response>>>;
  /** update data of the table: "files" */
  update_files?: Maybe<Files_Mutation_Response>;
  /** update single row of the table: "files" */
  update_files_by_pk?: Maybe<Files>;
  /** update multiples rows of table: "files" */
  update_files_many?: Maybe<Array<Maybe<Files_Mutation_Response>>>;
  /** update data of the table: "images" */
  update_images?: Maybe<Images_Mutation_Response>;
  /** update single row of the table: "images" */
  update_images_by_pk?: Maybe<Images>;
  /** update multiples rows of table: "images" */
  update_images_many?: Maybe<Array<Maybe<Images_Mutation_Response>>>;
  /** update data of the table: "project_collaborator_invitations" */
  update_project_collaborator_invitations?: Maybe<Project_Collaborator_Invitations_Mutation_Response>;
  /** update single row of the table: "project_collaborator_invitations" */
  update_project_collaborator_invitations_by_pk?: Maybe<Project_Collaborator_Invitations>;
  /** update multiples rows of table: "project_collaborator_invitations" */
  update_project_collaborator_invitations_many?: Maybe<Array<Maybe<Project_Collaborator_Invitations_Mutation_Response>>>;
  /** update data of the table: "project_user_groups" */
  update_project_user_groups?: Maybe<Project_User_Groups_Mutation_Response>;
  /** update single row of the table: "project_user_groups" */
  update_project_user_groups_by_pk?: Maybe<Project_User_Groups>;
  /** update multiples rows of table: "project_user_groups" */
  update_project_user_groups_many?: Maybe<Array<Maybe<Project_User_Groups_Mutation_Response>>>;
  /** update data of the table: "project_users" */
  update_project_users?: Maybe<Project_Users_Mutation_Response>;
  /** update single row of the table: "project_users" */
  update_project_users_by_pk?: Maybe<Project_Users>;
  /** update multiples rows of table: "project_users" */
  update_project_users_many?: Maybe<Array<Maybe<Project_Users_Mutation_Response>>>;
  /** update data of the table: "projects" */
  update_projects?: Maybe<Projects_Mutation_Response>;
  /** update single row of the table: "projects" */
  update_projects_by_pk?: Maybe<Projects>;
  /** update multiples rows of table: "projects" */
  update_projects_many?: Maybe<Array<Maybe<Projects_Mutation_Response>>>;
  /** update data of the table: "share_links" */
  update_share_links?: Maybe<Share_Links_Mutation_Response>;
  /** update multiples rows of table: "share_links" */
  update_share_links_many?: Maybe<Array<Maybe<Share_Links_Mutation_Response>>>;
  /** update data of the table: "team_delete_intents" */
  update_team_delete_intents?: Maybe<Team_Delete_Intents_Mutation_Response>;
  /** update single row of the table: "team_delete_intents" */
  update_team_delete_intents_by_pk?: Maybe<Team_Delete_Intents>;
  /** update multiples rows of table: "team_delete_intents" */
  update_team_delete_intents_many?: Maybe<Array<Maybe<Team_Delete_Intents_Mutation_Response>>>;
  /** update data of the table: "team_ownership_transfer_intents" */
  update_team_ownership_transfer_intents?: Maybe<Team_Ownership_Transfer_Intents_Mutation_Response>;
  /** update single row of the table: "team_ownership_transfer_intents" */
  update_team_ownership_transfer_intents_by_pk?: Maybe<Team_Ownership_Transfer_Intents>;
  /** update multiples rows of table: "team_ownership_transfer_intents" */
  update_team_ownership_transfer_intents_many?: Maybe<Array<Maybe<Team_Ownership_Transfer_Intents_Mutation_Response>>>;
  /** update data of the table: "team_user_group_users" */
  update_team_user_group_users?: Maybe<Team_User_Group_Users_Mutation_Response>;
  /** update single row of the table: "team_user_group_users" */
  update_team_user_group_users_by_pk?: Maybe<Team_User_Group_Users>;
  /** update multiples rows of table: "team_user_group_users" */
  update_team_user_group_users_many?: Maybe<Array<Maybe<Team_User_Group_Users_Mutation_Response>>>;
  /** update data of the table: "team_user_groups" */
  update_team_user_groups?: Maybe<Team_User_Groups_Mutation_Response>;
  /** update single row of the table: "team_user_groups" */
  update_team_user_groups_by_pk?: Maybe<Team_User_Groups>;
  /** update multiples rows of table: "team_user_groups" */
  update_team_user_groups_many?: Maybe<Array<Maybe<Team_User_Groups_Mutation_Response>>>;
  /** update data of the table: "team_user_invitations" */
  update_team_user_invitations?: Maybe<Team_User_Invitations_Mutation_Response>;
  /** update single row of the table: "team_user_invitations" */
  update_team_user_invitations_by_pk?: Maybe<Team_User_Invitations>;
  /** update multiples rows of table: "team_user_invitations" */
  update_team_user_invitations_many?: Maybe<Array<Maybe<Team_User_Invitations_Mutation_Response>>>;
  /** update data of the table: "team_users" */
  update_team_users?: Maybe<Team_Users_Mutation_Response>;
  /** update single row of the table: "team_users" */
  update_team_users_by_pk?: Maybe<Team_Users>;
  /** update multiples rows of table: "team_users" */
  update_team_users_many?: Maybe<Array<Maybe<Team_Users_Mutation_Response>>>;
  /** update data of the table: "teams" */
  update_teams?: Maybe<Teams_Mutation_Response>;
  /** update single row of the table: "teams" */
  update_teams_by_pk?: Maybe<Teams>;
  /** update multiples rows of table: "teams" */
  update_teams_many?: Maybe<Array<Maybe<Teams_Mutation_Response>>>;
  /** update data of the table: "user_file_last_viewed" */
  update_user_file_last_viewed?: Maybe<User_File_Last_Viewed_Mutation_Response>;
  /** update single row of the table: "user_file_last_viewed" */
  update_user_file_last_viewed_by_pk?: Maybe<User_File_Last_Viewed>;
  /** update multiples rows of table: "user_file_last_viewed" */
  update_user_file_last_viewed_many?: Maybe<Array<Maybe<User_File_Last_Viewed_Mutation_Response>>>;
  /** update data of the table: "user_preferences" */
  update_user_preferences?: Maybe<User_Preferences_Mutation_Response>;
  /** update single row of the table: "user_preferences" */
  update_user_preferences_by_pk?: Maybe<User_Preferences>;
  /** update multiples rows of table: "user_preferences" */
  update_user_preferences_many?: Maybe<Array<Maybe<User_Preferences_Mutation_Response>>>;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
  /** update multiples rows of table: "users" */
  update_users_many?: Maybe<Array<Maybe<Users_Mutation_Response>>>;
  /** update data of the table: "version_contributors" */
  update_version_contributors?: Maybe<Version_Contributors_Mutation_Response>;
  /** update single row of the table: "version_contributors" */
  update_version_contributors_by_pk?: Maybe<Version_Contributors>;
  /** update multiples rows of table: "version_contributors" */
  update_version_contributors_many?: Maybe<Array<Maybe<Version_Contributors_Mutation_Response>>>;
  /** update data of the table: "versions" */
  update_versions?: Maybe<Versions_Mutation_Response>;
  /** update single row of the table: "versions" */
  update_versions_by_pk?: Maybe<Versions>;
  /** update multiples rows of table: "versions" */
  update_versions_many?: Maybe<Array<Maybe<Versions_Mutation_Response>>>;
};


/** mutation root */
export type Mutation_RootDelete_File_Collaborator_InvitationsArgs = {
  where: File_Collaborator_Invitations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_File_Collaborator_Invitations_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_File_CollaboratorsArgs = {
  where: File_Collaborators_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_File_Collaborators_By_PkArgs = {
  file_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_FilesArgs = {
  where: Files_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Files_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ImagesArgs = {
  where: Images_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Images_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Project_Collaborator_InvitationsArgs = {
  where: Project_Collaborator_Invitations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Project_Collaborator_Invitations_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Project_User_GroupsArgs = {
  where: Project_User_Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Project_User_Groups_By_PkArgs = {
  project_id: Scalars['uuid'];
  team_user_group_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Project_UsersArgs = {
  where: Project_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Project_Users_By_PkArgs = {
  project_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ProjectsArgs = {
  where: Projects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Projects_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Share_LinksArgs = {
  where: Share_Links_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Team_Delete_IntentsArgs = {
  where: Team_Delete_Intents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Team_Delete_Intents_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Team_Ownership_Transfer_IntentsArgs = {
  where: Team_Ownership_Transfer_Intents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Team_Ownership_Transfer_Intents_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Team_User_Group_UsersArgs = {
  where: Team_User_Group_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Team_User_Group_Users_By_PkArgs = {
  team_user_group_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Team_User_GroupsArgs = {
  where: Team_User_Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Team_User_Groups_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Team_User_InvitationsArgs = {
  where: Team_User_Invitations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Team_User_Invitations_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Team_UsersArgs = {
  where: Team_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Team_Users_By_PkArgs = {
  team_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_TeamsArgs = {
  where: Teams_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Teams_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_File_Last_ViewedArgs = {
  where: User_File_Last_Viewed_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_File_Last_Viewed_By_PkArgs = {
  file_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_PreferencesArgs = {
  where: User_Preferences_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Preferences_By_PkArgs = {
  key: Scalars['String'];
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Version_ContributorsArgs = {
  where: Version_Contributors_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Version_Contributors_By_PkArgs = {
  file_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
  version_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_VersionsArgs = {
  where: Versions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Versions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootInsert_File_Collaborator_InvitationsArgs = {
  objects: Array<File_Collaborator_Invitations_Insert_Input>;
  on_conflict?: InputMaybe<File_Collaborator_Invitations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_File_Collaborator_Invitations_OneArgs = {
  object: File_Collaborator_Invitations_Insert_Input;
  on_conflict?: InputMaybe<File_Collaborator_Invitations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_File_CollaboratorsArgs = {
  objects: Array<File_Collaborators_Insert_Input>;
  on_conflict?: InputMaybe<File_Collaborators_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_File_Collaborators_OneArgs = {
  object: File_Collaborators_Insert_Input;
  on_conflict?: InputMaybe<File_Collaborators_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FilesArgs = {
  objects: Array<Files_Insert_Input>;
  on_conflict?: InputMaybe<Files_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Files_OneArgs = {
  object: Files_Insert_Input;
  on_conflict?: InputMaybe<Files_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ImagesArgs = {
  objects: Array<Images_Insert_Input>;
  on_conflict?: InputMaybe<Images_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Images_OneArgs = {
  object: Images_Insert_Input;
  on_conflict?: InputMaybe<Images_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Project_Collaborator_InvitationsArgs = {
  objects: Array<Project_Collaborator_Invitations_Insert_Input>;
  on_conflict?: InputMaybe<Project_Collaborator_Invitations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Project_Collaborator_Invitations_OneArgs = {
  object: Project_Collaborator_Invitations_Insert_Input;
  on_conflict?: InputMaybe<Project_Collaborator_Invitations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Project_User_GroupsArgs = {
  objects: Array<Project_User_Groups_Insert_Input>;
  on_conflict?: InputMaybe<Project_User_Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Project_User_Groups_OneArgs = {
  object: Project_User_Groups_Insert_Input;
  on_conflict?: InputMaybe<Project_User_Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Project_UsersArgs = {
  objects: Array<Project_Users_Insert_Input>;
  on_conflict?: InputMaybe<Project_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Project_Users_OneArgs = {
  object: Project_Users_Insert_Input;
  on_conflict?: InputMaybe<Project_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProjectsArgs = {
  objects: Array<Projects_Insert_Input>;
  on_conflict?: InputMaybe<Projects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Projects_OneArgs = {
  object: Projects_Insert_Input;
  on_conflict?: InputMaybe<Projects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Share_LinksArgs = {
  objects: Array<Share_Links_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Share_Links_OneArgs = {
  object: Share_Links_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Team_Delete_IntentsArgs = {
  objects: Array<Team_Delete_Intents_Insert_Input>;
  on_conflict?: InputMaybe<Team_Delete_Intents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Team_Delete_Intents_OneArgs = {
  object: Team_Delete_Intents_Insert_Input;
  on_conflict?: InputMaybe<Team_Delete_Intents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Team_Ownership_Transfer_IntentsArgs = {
  objects: Array<Team_Ownership_Transfer_Intents_Insert_Input>;
  on_conflict?: InputMaybe<Team_Ownership_Transfer_Intents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Team_Ownership_Transfer_Intents_OneArgs = {
  object: Team_Ownership_Transfer_Intents_Insert_Input;
  on_conflict?: InputMaybe<Team_Ownership_Transfer_Intents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Team_User_Group_UsersArgs = {
  objects: Array<Team_User_Group_Users_Insert_Input>;
  on_conflict?: InputMaybe<Team_User_Group_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Team_User_Group_Users_OneArgs = {
  object: Team_User_Group_Users_Insert_Input;
  on_conflict?: InputMaybe<Team_User_Group_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Team_User_GroupsArgs = {
  objects: Array<Team_User_Groups_Insert_Input>;
  on_conflict?: InputMaybe<Team_User_Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Team_User_Groups_OneArgs = {
  object: Team_User_Groups_Insert_Input;
  on_conflict?: InputMaybe<Team_User_Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Team_User_InvitationsArgs = {
  objects: Array<Team_User_Invitations_Insert_Input>;
  on_conflict?: InputMaybe<Team_User_Invitations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Team_User_Invitations_OneArgs = {
  object: Team_User_Invitations_Insert_Input;
  on_conflict?: InputMaybe<Team_User_Invitations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Team_UsersArgs = {
  objects: Array<Team_Users_Insert_Input>;
  on_conflict?: InputMaybe<Team_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Team_Users_OneArgs = {
  object: Team_Users_Insert_Input;
  on_conflict?: InputMaybe<Team_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TeamsArgs = {
  objects: Array<Teams_Insert_Input>;
  on_conflict?: InputMaybe<Teams_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Teams_OneArgs = {
  object: Teams_Insert_Input;
  on_conflict?: InputMaybe<Teams_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_File_Last_ViewedArgs = {
  objects: Array<User_File_Last_Viewed_Insert_Input>;
  on_conflict?: InputMaybe<User_File_Last_Viewed_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_File_Last_Viewed_OneArgs = {
  object: User_File_Last_Viewed_Insert_Input;
  on_conflict?: InputMaybe<User_File_Last_Viewed_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_PreferencesArgs = {
  objects: Array<User_Preferences_Insert_Input>;
  on_conflict?: InputMaybe<User_Preferences_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Preferences_OneArgs = {
  object: User_Preferences_Insert_Input;
  on_conflict?: InputMaybe<User_Preferences_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Version_ContributorsArgs = {
  objects: Array<Version_Contributors_Insert_Input>;
  on_conflict?: InputMaybe<Version_Contributors_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Version_Contributors_OneArgs = {
  object: Version_Contributors_Insert_Input;
  on_conflict?: InputMaybe<Version_Contributors_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_VersionsArgs = {
  objects: Array<Versions_Insert_Input>;
  on_conflict?: InputMaybe<Versions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Versions_OneArgs = {
  object: Versions_Insert_Input;
  on_conflict?: InputMaybe<Versions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate_File_Collaborator_InvitationsArgs = {
  _set?: InputMaybe<File_Collaborator_Invitations_Set_Input>;
  where: File_Collaborator_Invitations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_File_Collaborator_Invitations_By_PkArgs = {
  _set?: InputMaybe<File_Collaborator_Invitations_Set_Input>;
  pk_columns: File_Collaborator_Invitations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_File_Collaborator_Invitations_ManyArgs = {
  updates: Array<File_Collaborator_Invitations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_File_CollaboratorsArgs = {
  _set?: InputMaybe<File_Collaborators_Set_Input>;
  where: File_Collaborators_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_File_Collaborators_By_PkArgs = {
  _set?: InputMaybe<File_Collaborators_Set_Input>;
  pk_columns: File_Collaborators_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_File_Collaborators_ManyArgs = {
  updates: Array<File_Collaborators_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_FilesArgs = {
  _inc?: InputMaybe<Files_Inc_Input>;
  _set?: InputMaybe<Files_Set_Input>;
  where: Files_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Files_By_PkArgs = {
  _inc?: InputMaybe<Files_Inc_Input>;
  _set?: InputMaybe<Files_Set_Input>;
  pk_columns: Files_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Files_ManyArgs = {
  updates: Array<Files_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ImagesArgs = {
  _set?: InputMaybe<Images_Set_Input>;
  where: Images_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Images_By_PkArgs = {
  _set?: InputMaybe<Images_Set_Input>;
  pk_columns: Images_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Images_ManyArgs = {
  updates: Array<Images_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Project_Collaborator_InvitationsArgs = {
  _set?: InputMaybe<Project_Collaborator_Invitations_Set_Input>;
  where: Project_Collaborator_Invitations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Project_Collaborator_Invitations_By_PkArgs = {
  _set?: InputMaybe<Project_Collaborator_Invitations_Set_Input>;
  pk_columns: Project_Collaborator_Invitations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Project_Collaborator_Invitations_ManyArgs = {
  updates: Array<Project_Collaborator_Invitations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Project_User_GroupsArgs = {
  _set?: InputMaybe<Project_User_Groups_Set_Input>;
  where: Project_User_Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Project_User_Groups_By_PkArgs = {
  _set?: InputMaybe<Project_User_Groups_Set_Input>;
  pk_columns: Project_User_Groups_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Project_User_Groups_ManyArgs = {
  updates: Array<Project_User_Groups_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Project_UsersArgs = {
  _set?: InputMaybe<Project_Users_Set_Input>;
  where: Project_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Project_Users_By_PkArgs = {
  _set?: InputMaybe<Project_Users_Set_Input>;
  pk_columns: Project_Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Project_Users_ManyArgs = {
  updates: Array<Project_Users_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ProjectsArgs = {
  _set?: InputMaybe<Projects_Set_Input>;
  where: Projects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Projects_By_PkArgs = {
  _set?: InputMaybe<Projects_Set_Input>;
  pk_columns: Projects_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Projects_ManyArgs = {
  updates: Array<Projects_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Share_LinksArgs = {
  _set?: InputMaybe<Share_Links_Set_Input>;
  where: Share_Links_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Share_Links_ManyArgs = {
  updates: Array<Share_Links_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Team_Delete_IntentsArgs = {
  _set?: InputMaybe<Team_Delete_Intents_Set_Input>;
  where: Team_Delete_Intents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Team_Delete_Intents_By_PkArgs = {
  _set?: InputMaybe<Team_Delete_Intents_Set_Input>;
  pk_columns: Team_Delete_Intents_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Team_Delete_Intents_ManyArgs = {
  updates: Array<Team_Delete_Intents_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Team_Ownership_Transfer_IntentsArgs = {
  _set?: InputMaybe<Team_Ownership_Transfer_Intents_Set_Input>;
  where: Team_Ownership_Transfer_Intents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Team_Ownership_Transfer_Intents_By_PkArgs = {
  _set?: InputMaybe<Team_Ownership_Transfer_Intents_Set_Input>;
  pk_columns: Team_Ownership_Transfer_Intents_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Team_Ownership_Transfer_Intents_ManyArgs = {
  updates: Array<Team_Ownership_Transfer_Intents_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Team_User_Group_UsersArgs = {
  _set?: InputMaybe<Team_User_Group_Users_Set_Input>;
  where: Team_User_Group_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Team_User_Group_Users_By_PkArgs = {
  _set?: InputMaybe<Team_User_Group_Users_Set_Input>;
  pk_columns: Team_User_Group_Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Team_User_Group_Users_ManyArgs = {
  updates: Array<Team_User_Group_Users_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Team_User_GroupsArgs = {
  _set?: InputMaybe<Team_User_Groups_Set_Input>;
  where: Team_User_Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Team_User_Groups_By_PkArgs = {
  _set?: InputMaybe<Team_User_Groups_Set_Input>;
  pk_columns: Team_User_Groups_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Team_User_Groups_ManyArgs = {
  updates: Array<Team_User_Groups_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Team_User_InvitationsArgs = {
  _set?: InputMaybe<Team_User_Invitations_Set_Input>;
  where: Team_User_Invitations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Team_User_Invitations_By_PkArgs = {
  _set?: InputMaybe<Team_User_Invitations_Set_Input>;
  pk_columns: Team_User_Invitations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Team_User_Invitations_ManyArgs = {
  updates: Array<Team_User_Invitations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Team_UsersArgs = {
  _set?: InputMaybe<Team_Users_Set_Input>;
  where: Team_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Team_Users_By_PkArgs = {
  _set?: InputMaybe<Team_Users_Set_Input>;
  pk_columns: Team_Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Team_Users_ManyArgs = {
  updates: Array<Team_Users_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TeamsArgs = {
  _set?: InputMaybe<Teams_Set_Input>;
  where: Teams_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Teams_By_PkArgs = {
  _set?: InputMaybe<Teams_Set_Input>;
  pk_columns: Teams_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Teams_ManyArgs = {
  updates: Array<Teams_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_File_Last_ViewedArgs = {
  _set?: InputMaybe<User_File_Last_Viewed_Set_Input>;
  where: User_File_Last_Viewed_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_File_Last_Viewed_By_PkArgs = {
  _set?: InputMaybe<User_File_Last_Viewed_Set_Input>;
  pk_columns: User_File_Last_Viewed_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_File_Last_Viewed_ManyArgs = {
  updates: Array<User_File_Last_Viewed_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_PreferencesArgs = {
  _append?: InputMaybe<User_Preferences_Append_Input>;
  _delete_at_path?: InputMaybe<User_Preferences_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<User_Preferences_Delete_Elem_Input>;
  _delete_key?: InputMaybe<User_Preferences_Delete_Key_Input>;
  _prepend?: InputMaybe<User_Preferences_Prepend_Input>;
  _set?: InputMaybe<User_Preferences_Set_Input>;
  where: User_Preferences_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Preferences_By_PkArgs = {
  _append?: InputMaybe<User_Preferences_Append_Input>;
  _delete_at_path?: InputMaybe<User_Preferences_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<User_Preferences_Delete_Elem_Input>;
  _delete_key?: InputMaybe<User_Preferences_Delete_Key_Input>;
  _prepend?: InputMaybe<User_Preferences_Prepend_Input>;
  _set?: InputMaybe<User_Preferences_Set_Input>;
  pk_columns: User_Preferences_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Preferences_ManyArgs = {
  updates: Array<User_Preferences_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _append?: InputMaybe<Users_Append_Input>;
  _delete_at_path?: InputMaybe<Users_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Users_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Users_Delete_Key_Input>;
  _inc?: InputMaybe<Users_Inc_Input>;
  _prepend?: InputMaybe<Users_Prepend_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _append?: InputMaybe<Users_Append_Input>;
  _delete_at_path?: InputMaybe<Users_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Users_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Users_Delete_Key_Input>;
  _inc?: InputMaybe<Users_Inc_Input>;
  _prepend?: InputMaybe<Users_Prepend_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_ManyArgs = {
  updates: Array<Users_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Version_ContributorsArgs = {
  _inc?: InputMaybe<Version_Contributors_Inc_Input>;
  _set?: InputMaybe<Version_Contributors_Set_Input>;
  where: Version_Contributors_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Version_Contributors_By_PkArgs = {
  _inc?: InputMaybe<Version_Contributors_Inc_Input>;
  _set?: InputMaybe<Version_Contributors_Set_Input>;
  pk_columns: Version_Contributors_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Version_Contributors_ManyArgs = {
  updates: Array<Version_Contributors_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_VersionsArgs = {
  _set?: InputMaybe<Versions_Set_Input>;
  where: Versions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Versions_By_PkArgs = {
  _set?: InputMaybe<Versions_Set_Input>;
  pk_columns: Versions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Versions_ManyArgs = {
  updates: Array<Versions_Updates>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "project_collaborator_invitations" */
export type Project_Collaborator_Invitations = {
  __typename?: 'project_collaborator_invitations';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  creator?: Maybe<Users>;
  creator_id: Scalars['uuid'];
  email: Scalars['String'];
  expired_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  permission: Scalars['String'];
  project_id: Scalars['uuid'];
  status: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "project_collaborator_invitations" */
export type Project_Collaborator_Invitations_Aggregate = {
  __typename?: 'project_collaborator_invitations_aggregate';
  aggregate?: Maybe<Project_Collaborator_Invitations_Aggregate_Fields>;
  nodes: Array<Project_Collaborator_Invitations>;
};

/** aggregate fields of "project_collaborator_invitations" */
export type Project_Collaborator_Invitations_Aggregate_Fields = {
  __typename?: 'project_collaborator_invitations_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Project_Collaborator_Invitations_Max_Fields>;
  min?: Maybe<Project_Collaborator_Invitations_Min_Fields>;
};


/** aggregate fields of "project_collaborator_invitations" */
export type Project_Collaborator_Invitations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Collaborator_Invitations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "project_collaborator_invitations". All fields are combined with a logical 'AND'. */
export type Project_Collaborator_Invitations_Bool_Exp = {
  _and?: InputMaybe<Array<Project_Collaborator_Invitations_Bool_Exp>>;
  _not?: InputMaybe<Project_Collaborator_Invitations_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Collaborator_Invitations_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  creator?: InputMaybe<Users_Bool_Exp>;
  creator_id?: InputMaybe<Uuid_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  expired_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  permission?: InputMaybe<String_Comparison_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "project_collaborator_invitations" */
export enum Project_Collaborator_Invitations_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProjectCollaboratorInvitationsPkey = 'project_collaborator_invitations_pkey'
}

/** input type for inserting data into table "project_collaborator_invitations" */
export type Project_Collaborator_Invitations_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  creator?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  creator_id?: InputMaybe<Scalars['uuid']>;
  email?: InputMaybe<Scalars['String']>;
  expired_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  permission?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Project_Collaborator_Invitations_Max_Fields = {
  __typename?: 'project_collaborator_invitations_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  creator_id?: Maybe<Scalars['uuid']>;
  email?: Maybe<Scalars['String']>;
  expired_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  permission?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Project_Collaborator_Invitations_Min_Fields = {
  __typename?: 'project_collaborator_invitations_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  creator_id?: Maybe<Scalars['uuid']>;
  email?: Maybe<Scalars['String']>;
  expired_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  permission?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "project_collaborator_invitations" */
export type Project_Collaborator_Invitations_Mutation_Response = {
  __typename?: 'project_collaborator_invitations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_Collaborator_Invitations>;
};

/** on_conflict condition type for table "project_collaborator_invitations" */
export type Project_Collaborator_Invitations_On_Conflict = {
  constraint: Project_Collaborator_Invitations_Constraint;
  update_columns?: Array<Project_Collaborator_Invitations_Update_Column>;
  where?: InputMaybe<Project_Collaborator_Invitations_Bool_Exp>;
};

/** Ordering options when selecting data from "project_collaborator_invitations". */
export type Project_Collaborator_Invitations_Order_By = {
  created_at?: InputMaybe<Order_By>;
  creator?: InputMaybe<Users_Order_By>;
  creator_id?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  expired_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  permission?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: project_collaborator_invitations */
export type Project_Collaborator_Invitations_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "project_collaborator_invitations" */
export enum Project_Collaborator_Invitations_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  Email = 'email',
  /** column name */
  ExpiredAt = 'expired_at',
  /** column name */
  Id = 'id',
  /** column name */
  Permission = 'permission',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "project_collaborator_invitations" */
export type Project_Collaborator_Invitations_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  creator_id?: InputMaybe<Scalars['uuid']>;
  email?: InputMaybe<Scalars['String']>;
  expired_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  permission?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "project_collaborator_invitations" */
export type Project_Collaborator_Invitations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_Collaborator_Invitations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Collaborator_Invitations_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  creator_id?: InputMaybe<Scalars['uuid']>;
  email?: InputMaybe<Scalars['String']>;
  expired_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  permission?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "project_collaborator_invitations" */
export enum Project_Collaborator_Invitations_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  Email = 'email',
  /** column name */
  ExpiredAt = 'expired_at',
  /** column name */
  Id = 'id',
  /** column name */
  Permission = 'permission',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Project_Collaborator_Invitations_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Collaborator_Invitations_Set_Input>;
  where: Project_Collaborator_Invitations_Bool_Exp;
};

/** columns and relationships of "project_members_view" */
export type Project_Members_View = {
  __typename?: 'project_members_view';
  avatar?: Maybe<Scalars['String']>;
  collaborator_limit?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  custom_slug?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invitation_created_at?: Maybe<Scalars['String']>;
  invitation_creator_id?: Maybe<Scalars['String']>;
  invitation_email?: Maybe<Scalars['String']>;
  invitation_id?: Maybe<Scalars['String']>;
  invitation_status?: Maybe<Scalars['String']>;
  invitation_updated_at?: Maybe<Scalars['String']>;
  joined_at?: Maybe<Scalars['String']>;
  permission?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['String']>;
  project_role?: Maybe<Scalars['String']>;
  sort_priority?: Maybe<Scalars['Int']>;
  system_role?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  workspace_icon?: Maybe<Scalars['String']>;
};

/** aggregated selection of "project_members_view" */
export type Project_Members_View_Aggregate = {
  __typename?: 'project_members_view_aggregate';
  aggregate?: Maybe<Project_Members_View_Aggregate_Fields>;
  nodes: Array<Project_Members_View>;
};

/** aggregate fields of "project_members_view" */
export type Project_Members_View_Aggregate_Fields = {
  __typename?: 'project_members_view_aggregate_fields';
  avg?: Maybe<Project_Members_View_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Project_Members_View_Max_Fields>;
  min?: Maybe<Project_Members_View_Min_Fields>;
  stddev?: Maybe<Project_Members_View_Stddev_Fields>;
  stddev_pop?: Maybe<Project_Members_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Project_Members_View_Stddev_Samp_Fields>;
  sum?: Maybe<Project_Members_View_Sum_Fields>;
  var_pop?: Maybe<Project_Members_View_Var_Pop_Fields>;
  var_samp?: Maybe<Project_Members_View_Var_Samp_Fields>;
  variance?: Maybe<Project_Members_View_Variance_Fields>;
};


/** aggregate fields of "project_members_view" */
export type Project_Members_View_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Members_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Project_Members_View_Avg_Fields = {
  __typename?: 'project_members_view_avg_fields';
  sort_priority?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "project_members_view". All fields are combined with a logical 'AND'. */
export type Project_Members_View_Bool_Exp = {
  _and?: InputMaybe<Array<Project_Members_View_Bool_Exp>>;
  _not?: InputMaybe<Project_Members_View_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Members_View_Bool_Exp>>;
  avatar?: InputMaybe<String_Comparison_Exp>;
  collaborator_limit?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<String_Comparison_Exp>;
  custom_slug?: InputMaybe<String_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  invitation_created_at?: InputMaybe<String_Comparison_Exp>;
  invitation_creator_id?: InputMaybe<String_Comparison_Exp>;
  invitation_email?: InputMaybe<String_Comparison_Exp>;
  invitation_id?: InputMaybe<String_Comparison_Exp>;
  invitation_status?: InputMaybe<String_Comparison_Exp>;
  invitation_updated_at?: InputMaybe<String_Comparison_Exp>;
  joined_at?: InputMaybe<String_Comparison_Exp>;
  permission?: InputMaybe<String_Comparison_Exp>;
  project_id?: InputMaybe<String_Comparison_Exp>;
  project_role?: InputMaybe<String_Comparison_Exp>;
  sort_priority?: InputMaybe<Int_Comparison_Exp>;
  system_role?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<String_Comparison_Exp>;
  username?: InputMaybe<String_Comparison_Exp>;
  workspace_icon?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Project_Members_View_Max_Fields = {
  __typename?: 'project_members_view_max_fields';
  avatar?: Maybe<Scalars['String']>;
  collaborator_limit?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  custom_slug?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invitation_created_at?: Maybe<Scalars['String']>;
  invitation_creator_id?: Maybe<Scalars['String']>;
  invitation_email?: Maybe<Scalars['String']>;
  invitation_id?: Maybe<Scalars['String']>;
  invitation_status?: Maybe<Scalars['String']>;
  invitation_updated_at?: Maybe<Scalars['String']>;
  joined_at?: Maybe<Scalars['String']>;
  permission?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['String']>;
  project_role?: Maybe<Scalars['String']>;
  sort_priority?: Maybe<Scalars['Int']>;
  system_role?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  workspace_icon?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Project_Members_View_Min_Fields = {
  __typename?: 'project_members_view_min_fields';
  avatar?: Maybe<Scalars['String']>;
  collaborator_limit?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  custom_slug?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invitation_created_at?: Maybe<Scalars['String']>;
  invitation_creator_id?: Maybe<Scalars['String']>;
  invitation_email?: Maybe<Scalars['String']>;
  invitation_id?: Maybe<Scalars['String']>;
  invitation_status?: Maybe<Scalars['String']>;
  invitation_updated_at?: Maybe<Scalars['String']>;
  joined_at?: Maybe<Scalars['String']>;
  permission?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['String']>;
  project_role?: Maybe<Scalars['String']>;
  sort_priority?: Maybe<Scalars['Int']>;
  system_role?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  workspace_icon?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "project_members_view". */
export type Project_Members_View_Order_By = {
  avatar?: InputMaybe<Order_By>;
  collaborator_limit?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  custom_slug?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invitation_created_at?: InputMaybe<Order_By>;
  invitation_creator_id?: InputMaybe<Order_By>;
  invitation_email?: InputMaybe<Order_By>;
  invitation_id?: InputMaybe<Order_By>;
  invitation_status?: InputMaybe<Order_By>;
  invitation_updated_at?: InputMaybe<Order_By>;
  joined_at?: InputMaybe<Order_By>;
  permission?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  project_role?: InputMaybe<Order_By>;
  sort_priority?: InputMaybe<Order_By>;
  system_role?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
  workspace_icon?: InputMaybe<Order_By>;
};

/** select columns of table "project_members_view" */
export enum Project_Members_View_Select_Column {
  /** column name */
  Avatar = 'avatar',
  /** column name */
  CollaboratorLimit = 'collaborator_limit',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomSlug = 'custom_slug',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  InvitationCreatedAt = 'invitation_created_at',
  /** column name */
  InvitationCreatorId = 'invitation_creator_id',
  /** column name */
  InvitationEmail = 'invitation_email',
  /** column name */
  InvitationId = 'invitation_id',
  /** column name */
  InvitationStatus = 'invitation_status',
  /** column name */
  InvitationUpdatedAt = 'invitation_updated_at',
  /** column name */
  JoinedAt = 'joined_at',
  /** column name */
  Permission = 'permission',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  ProjectRole = 'project_role',
  /** column name */
  SortPriority = 'sort_priority',
  /** column name */
  SystemRole = 'system_role',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Username = 'username',
  /** column name */
  WorkspaceIcon = 'workspace_icon'
}

/** aggregate stddev on columns */
export type Project_Members_View_Stddev_Fields = {
  __typename?: 'project_members_view_stddev_fields';
  sort_priority?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Project_Members_View_Stddev_Pop_Fields = {
  __typename?: 'project_members_view_stddev_pop_fields';
  sort_priority?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Project_Members_View_Stddev_Samp_Fields = {
  __typename?: 'project_members_view_stddev_samp_fields';
  sort_priority?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "project_members_view" */
export type Project_Members_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_Members_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Members_View_Stream_Cursor_Value_Input = {
  avatar?: InputMaybe<Scalars['String']>;
  collaborator_limit?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['String']>;
  custom_slug?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  invitation_created_at?: InputMaybe<Scalars['String']>;
  invitation_creator_id?: InputMaybe<Scalars['String']>;
  invitation_email?: InputMaybe<Scalars['String']>;
  invitation_id?: InputMaybe<Scalars['String']>;
  invitation_status?: InputMaybe<Scalars['String']>;
  invitation_updated_at?: InputMaybe<Scalars['String']>;
  joined_at?: InputMaybe<Scalars['String']>;
  permission?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['String']>;
  project_role?: InputMaybe<Scalars['String']>;
  sort_priority?: InputMaybe<Scalars['Int']>;
  system_role?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
  workspace_icon?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Project_Members_View_Sum_Fields = {
  __typename?: 'project_members_view_sum_fields';
  sort_priority?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Project_Members_View_Var_Pop_Fields = {
  __typename?: 'project_members_view_var_pop_fields';
  sort_priority?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Project_Members_View_Var_Samp_Fields = {
  __typename?: 'project_members_view_var_samp_fields';
  sort_priority?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Project_Members_View_Variance_Fields = {
  __typename?: 'project_members_view_variance_fields';
  sort_priority?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "project_user_groups" */
export type Project_User_Groups = {
  __typename?: 'project_user_groups';
  created_at: Scalars['timestamptz'];
  files_permission?: Maybe<Scalars['String']>;
  project_id: Scalars['uuid'];
  team_user_group_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "project_user_groups" */
export type Project_User_Groups_Aggregate = {
  __typename?: 'project_user_groups_aggregate';
  aggregate?: Maybe<Project_User_Groups_Aggregate_Fields>;
  nodes: Array<Project_User_Groups>;
};

/** aggregate fields of "project_user_groups" */
export type Project_User_Groups_Aggregate_Fields = {
  __typename?: 'project_user_groups_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Project_User_Groups_Max_Fields>;
  min?: Maybe<Project_User_Groups_Min_Fields>;
};


/** aggregate fields of "project_user_groups" */
export type Project_User_Groups_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_User_Groups_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "project_user_groups". All fields are combined with a logical 'AND'. */
export type Project_User_Groups_Bool_Exp = {
  _and?: InputMaybe<Array<Project_User_Groups_Bool_Exp>>;
  _not?: InputMaybe<Project_User_Groups_Bool_Exp>;
  _or?: InputMaybe<Array<Project_User_Groups_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  files_permission?: InputMaybe<String_Comparison_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  team_user_group_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "project_user_groups" */
export enum Project_User_Groups_Constraint {
  /** unique or primary key constraint on columns "project_id", "team_user_group_id" */
  ProjectUserGroupsPk = 'project_user_groups_pk'
}

/** input type for inserting data into table "project_user_groups" */
export type Project_User_Groups_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  files_permission?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  team_user_group_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Project_User_Groups_Max_Fields = {
  __typename?: 'project_user_groups_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  files_permission?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  team_user_group_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Project_User_Groups_Min_Fields = {
  __typename?: 'project_user_groups_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  files_permission?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  team_user_group_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "project_user_groups" */
export type Project_User_Groups_Mutation_Response = {
  __typename?: 'project_user_groups_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_User_Groups>;
};

/** on_conflict condition type for table "project_user_groups" */
export type Project_User_Groups_On_Conflict = {
  constraint: Project_User_Groups_Constraint;
  update_columns?: Array<Project_User_Groups_Update_Column>;
  where?: InputMaybe<Project_User_Groups_Bool_Exp>;
};

/** Ordering options when selecting data from "project_user_groups". */
export type Project_User_Groups_Order_By = {
  created_at?: InputMaybe<Order_By>;
  files_permission?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  team_user_group_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: project_user_groups */
export type Project_User_Groups_Pk_Columns_Input = {
  project_id: Scalars['uuid'];
  team_user_group_id: Scalars['uuid'];
};

/** select columns of table "project_user_groups" */
export enum Project_User_Groups_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FilesPermission = 'files_permission',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  TeamUserGroupId = 'team_user_group_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "project_user_groups" */
export type Project_User_Groups_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  files_permission?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  team_user_group_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "project_user_groups" */
export type Project_User_Groups_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_User_Groups_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_User_Groups_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  files_permission?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  team_user_group_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "project_user_groups" */
export enum Project_User_Groups_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FilesPermission = 'files_permission',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  TeamUserGroupId = 'team_user_group_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Project_User_Groups_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_User_Groups_Set_Input>;
  where: Project_User_Groups_Bool_Exp;
};

/** columns and relationships of "project_users" */
export type Project_Users = {
  __typename?: 'project_users';
  created_at: Scalars['timestamptz'];
  invitation_id?: Maybe<Scalars['uuid']>;
  permission: Scalars['String'];
  /** An object relationship */
  project?: Maybe<Projects>;
  project_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "project_users" */
export type Project_Users_Aggregate = {
  __typename?: 'project_users_aggregate';
  aggregate?: Maybe<Project_Users_Aggregate_Fields>;
  nodes: Array<Project_Users>;
};

export type Project_Users_Aggregate_Bool_Exp = {
  count?: InputMaybe<Project_Users_Aggregate_Bool_Exp_Count>;
};

export type Project_Users_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Project_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Project_Users_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "project_users" */
export type Project_Users_Aggregate_Fields = {
  __typename?: 'project_users_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Project_Users_Max_Fields>;
  min?: Maybe<Project_Users_Min_Fields>;
};


/** aggregate fields of "project_users" */
export type Project_Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project_users" */
export type Project_Users_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Project_Users_Max_Order_By>;
  min?: InputMaybe<Project_Users_Min_Order_By>;
};

/** input type for inserting array relation for remote table "project_users" */
export type Project_Users_Arr_Rel_Insert_Input = {
  data: Array<Project_Users_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_Users_On_Conflict>;
};

/** Boolean expression to filter rows from the table "project_users". All fields are combined with a logical 'AND'. */
export type Project_Users_Bool_Exp = {
  _and?: InputMaybe<Array<Project_Users_Bool_Exp>>;
  _not?: InputMaybe<Project_Users_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Users_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  invitation_id?: InputMaybe<Uuid_Comparison_Exp>;
  permission?: InputMaybe<String_Comparison_Exp>;
  project?: InputMaybe<Projects_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "project_users" */
export enum Project_Users_Constraint {
  /** unique or primary key constraint on columns "project_id", "user_id" */
  ProjectUsersPk = 'project_users_pk'
}

/** input type for inserting data into table "project_users" */
export type Project_Users_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  invitation_id?: InputMaybe<Scalars['uuid']>;
  permission?: InputMaybe<Scalars['String']>;
  project?: InputMaybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Project_Users_Max_Fields = {
  __typename?: 'project_users_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  invitation_id?: Maybe<Scalars['uuid']>;
  permission?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "project_users" */
export type Project_Users_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  invitation_id?: InputMaybe<Order_By>;
  permission?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Project_Users_Min_Fields = {
  __typename?: 'project_users_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  invitation_id?: Maybe<Scalars['uuid']>;
  permission?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "project_users" */
export type Project_Users_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  invitation_id?: InputMaybe<Order_By>;
  permission?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "project_users" */
export type Project_Users_Mutation_Response = {
  __typename?: 'project_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_Users>;
};

/** on_conflict condition type for table "project_users" */
export type Project_Users_On_Conflict = {
  constraint: Project_Users_Constraint;
  update_columns?: Array<Project_Users_Update_Column>;
  where?: InputMaybe<Project_Users_Bool_Exp>;
};

/** Ordering options when selecting data from "project_users". */
export type Project_Users_Order_By = {
  created_at?: InputMaybe<Order_By>;
  invitation_id?: InputMaybe<Order_By>;
  permission?: InputMaybe<Order_By>;
  project?: InputMaybe<Projects_Order_By>;
  project_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: project_users */
export type Project_Users_Pk_Columns_Input = {
  project_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

/** select columns of table "project_users" */
export enum Project_Users_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  InvitationId = 'invitation_id',
  /** column name */
  Permission = 'permission',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "project_users" */
export type Project_Users_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  invitation_id?: InputMaybe<Scalars['uuid']>;
  permission?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "project_users" */
export type Project_Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Users_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  invitation_id?: InputMaybe<Scalars['uuid']>;
  permission?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "project_users" */
export enum Project_Users_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  InvitationId = 'invitation_id',
  /** column name */
  Permission = 'permission',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Project_Users_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Users_Set_Input>;
  where: Project_Users_Bool_Exp;
};

/** columns and relationships of "projects" */
export type Projects = {
  __typename?: 'projects';
  created_at: Scalars['timestamptz'];
  creator_id: Scalars['uuid'];
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  name?: Maybe<Scalars['String']>;
  permission: Scalars['String'];
  /** An array relationship */
  project_files: Array<Files>;
  /** An aggregate relationship */
  project_files_aggregate: Files_Aggregate;
  /** An array relationship */
  project_users: Array<Project_Users>;
  /** An aggregate relationship */
  project_users_aggregate: Project_Users_Aggregate;
  source_type: Scalars['String'];
  status: Scalars['String'];
  /** An object relationship */
  team?: Maybe<Teams>;
  team_id?: Maybe<Scalars['uuid']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "projects" */
export type ProjectsProject_FilesArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsProject_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsProject_UsersArgs = {
  distinct_on?: InputMaybe<Array<Project_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Users_Order_By>>;
  where?: InputMaybe<Project_Users_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsProject_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Users_Order_By>>;
  where?: InputMaybe<Project_Users_Bool_Exp>;
};

/** aggregated selection of "projects" */
export type Projects_Aggregate = {
  __typename?: 'projects_aggregate';
  aggregate?: Maybe<Projects_Aggregate_Fields>;
  nodes: Array<Projects>;
};

/** aggregate fields of "projects" */
export type Projects_Aggregate_Fields = {
  __typename?: 'projects_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Projects_Max_Fields>;
  min?: Maybe<Projects_Min_Fields>;
};


/** aggregate fields of "projects" */
export type Projects_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Projects_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "projects". All fields are combined with a logical 'AND'. */
export type Projects_Bool_Exp = {
  _and?: InputMaybe<Array<Projects_Bool_Exp>>;
  _not?: InputMaybe<Projects_Bool_Exp>;
  _or?: InputMaybe<Array<Projects_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  creator_id?: InputMaybe<Uuid_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  icon?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  permission?: InputMaybe<String_Comparison_Exp>;
  project_files?: InputMaybe<Files_Bool_Exp>;
  project_files_aggregate?: InputMaybe<Files_Aggregate_Bool_Exp>;
  project_users?: InputMaybe<Project_Users_Bool_Exp>;
  project_users_aggregate?: InputMaybe<Project_Users_Aggregate_Bool_Exp>;
  source_type?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  team?: InputMaybe<Teams_Bool_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "projects" */
export enum Projects_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProjectsPkey = 'projects_pkey'
}

/** input type for inserting data into table "projects" */
export type Projects_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  creator_id?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  permission?: InputMaybe<Scalars['String']>;
  project_files?: InputMaybe<Files_Arr_Rel_Insert_Input>;
  project_users?: InputMaybe<Project_Users_Arr_Rel_Insert_Input>;
  source_type?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  team?: InputMaybe<Teams_Obj_Rel_Insert_Input>;
  team_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Projects_Max_Fields = {
  __typename?: 'projects_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  creator_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  permission?: Maybe<Scalars['String']>;
  source_type?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Projects_Min_Fields = {
  __typename?: 'projects_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  creator_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  permission?: Maybe<Scalars['String']>;
  source_type?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "projects" */
export type Projects_Mutation_Response = {
  __typename?: 'projects_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Projects>;
};

/** input type for inserting object relation for remote table "projects" */
export type Projects_Obj_Rel_Insert_Input = {
  data: Projects_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Projects_On_Conflict>;
};

/** on_conflict condition type for table "projects" */
export type Projects_On_Conflict = {
  constraint: Projects_Constraint;
  update_columns?: Array<Projects_Update_Column>;
  where?: InputMaybe<Projects_Bool_Exp>;
};

/** Ordering options when selecting data from "projects". */
export type Projects_Order_By = {
  created_at?: InputMaybe<Order_By>;
  creator_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  icon?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  permission?: InputMaybe<Order_By>;
  project_files_aggregate?: InputMaybe<Files_Aggregate_Order_By>;
  project_users_aggregate?: InputMaybe<Project_Users_Aggregate_Order_By>;
  source_type?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  team?: InputMaybe<Teams_Order_By>;
  team_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: projects */
export type Projects_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "projects" */
export enum Projects_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  Description = 'description',
  /** column name */
  Icon = 'icon',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Permission = 'permission',
  /** column name */
  SourceType = 'source_type',
  /** column name */
  Status = 'status',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "projects" */
export type Projects_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  creator_id?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  permission?: InputMaybe<Scalars['String']>;
  source_type?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "projects" */
export type Projects_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Projects_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Projects_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  creator_id?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  permission?: InputMaybe<Scalars['String']>;
  source_type?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "projects" */
export enum Projects_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  Description = 'description',
  /** column name */
  Icon = 'icon',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Permission = 'permission',
  /** column name */
  SourceType = 'source_type',
  /** column name */
  Status = 'status',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Projects_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Projects_Set_Input>;
  where: Projects_Bool_Exp;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "file_collaborator_invitations" */
  file_collaborator_invitations: Array<File_Collaborator_Invitations>;
  /** fetch aggregated fields from the table: "file_collaborator_invitations" */
  file_collaborator_invitations_aggregate: File_Collaborator_Invitations_Aggregate;
  /** fetch data from the table: "file_collaborator_invitations" using primary key columns */
  file_collaborator_invitations_by_pk?: Maybe<File_Collaborator_Invitations>;
  /** fetch data from the table: "file_collaborators" */
  file_collaborators: Array<File_Collaborators>;
  /** fetch aggregated fields from the table: "file_collaborators" */
  file_collaborators_aggregate: File_Collaborators_Aggregate;
  /** fetch data from the table: "file_collaborators" using primary key columns */
  file_collaborators_by_pk?: Maybe<File_Collaborators>;
  /** fetch data from the table: "file_users_view" */
  file_users_view: Array<File_Users_View>;
  /** fetch aggregated fields from the table: "file_users_view" */
  file_users_view_aggregate: File_Users_View_Aggregate;
  /** fetch data from the table: "files" */
  files: Array<Files>;
  /** fetch aggregated fields from the table: "files" */
  files_aggregate: Files_Aggregate;
  /** fetch data from the table: "files" using primary key columns */
  files_by_pk?: Maybe<Files>;
  /** execute function "get_users_by_contributors" which returns "users" */
  get_users_by_contributors: Array<Users>;
  /** execute function "get_users_by_contributors" and query aggregates on result of table type "users" */
  get_users_by_contributors_aggregate: Users_Aggregate;
  /** fetch data from the table: "images" */
  images: Array<Images>;
  /** fetch aggregated fields from the table: "images" */
  images_aggregate: Images_Aggregate;
  /** fetch data from the table: "images" using primary key columns */
  images_by_pk?: Maybe<Images>;
  /** fetch data from the table: "project_collaborator_invitations" */
  project_collaborator_invitations: Array<Project_Collaborator_Invitations>;
  /** fetch aggregated fields from the table: "project_collaborator_invitations" */
  project_collaborator_invitations_aggregate: Project_Collaborator_Invitations_Aggregate;
  /** fetch data from the table: "project_collaborator_invitations" using primary key columns */
  project_collaborator_invitations_by_pk?: Maybe<Project_Collaborator_Invitations>;
  /** fetch data from the table: "project_members_view" */
  project_members_view: Array<Project_Members_View>;
  /** fetch aggregated fields from the table: "project_members_view" */
  project_members_view_aggregate: Project_Members_View_Aggregate;
  /** fetch data from the table: "project_user_groups" */
  project_user_groups: Array<Project_User_Groups>;
  /** fetch aggregated fields from the table: "project_user_groups" */
  project_user_groups_aggregate: Project_User_Groups_Aggregate;
  /** fetch data from the table: "project_user_groups" using primary key columns */
  project_user_groups_by_pk?: Maybe<Project_User_Groups>;
  /** An array relationship */
  project_users: Array<Project_Users>;
  /** An aggregate relationship */
  project_users_aggregate: Project_Users_Aggregate;
  /** fetch data from the table: "project_users" using primary key columns */
  project_users_by_pk?: Maybe<Project_Users>;
  /** fetch data from the table: "projects" */
  projects: Array<Projects>;
  /** fetch aggregated fields from the table: "projects" */
  projects_aggregate: Projects_Aggregate;
  /** fetch data from the table: "projects" using primary key columns */
  projects_by_pk?: Maybe<Projects>;
  /** fetch data from the table: "share_links" */
  share_links: Array<Share_Links>;
  /** fetch aggregated fields from the table: "share_links" */
  share_links_aggregate: Share_Links_Aggregate;
  /** fetch data from the table: "team_delete_intents" */
  team_delete_intents: Array<Team_Delete_Intents>;
  /** fetch aggregated fields from the table: "team_delete_intents" */
  team_delete_intents_aggregate: Team_Delete_Intents_Aggregate;
  /** fetch data from the table: "team_delete_intents" using primary key columns */
  team_delete_intents_by_pk?: Maybe<Team_Delete_Intents>;
  /** Query files by user's access right */
  team_files_with_permission?: Maybe<Array<Maybe<File>>>;
  /** fetch data from the table: "team_ownership_transfer_intents" */
  team_ownership_transfer_intents: Array<Team_Ownership_Transfer_Intents>;
  /** fetch aggregated fields from the table: "team_ownership_transfer_intents" */
  team_ownership_transfer_intents_aggregate: Team_Ownership_Transfer_Intents_Aggregate;
  /** fetch data from the table: "team_ownership_transfer_intents" using primary key columns */
  team_ownership_transfer_intents_by_pk?: Maybe<Team_Ownership_Transfer_Intents>;
  /** Query project id list by user's access right */
  team_project_ids_with_permission?: Maybe<ResultOfProjectIds>;
  /** fetch data from the table: "team_user_group_users" */
  team_user_group_users: Array<Team_User_Group_Users>;
  /** fetch aggregated fields from the table: "team_user_group_users" */
  team_user_group_users_aggregate: Team_User_Group_Users_Aggregate;
  /** fetch data from the table: "team_user_group_users" using primary key columns */
  team_user_group_users_by_pk?: Maybe<Team_User_Group_Users>;
  /** fetch data from the table: "team_user_groups" */
  team_user_groups: Array<Team_User_Groups>;
  /** fetch aggregated fields from the table: "team_user_groups" */
  team_user_groups_aggregate: Team_User_Groups_Aggregate;
  /** fetch data from the table: "team_user_groups" using primary key columns */
  team_user_groups_by_pk?: Maybe<Team_User_Groups>;
  /** fetch data from the table: "team_user_invitations" */
  team_user_invitations: Array<Team_User_Invitations>;
  /** fetch aggregated fields from the table: "team_user_invitations" */
  team_user_invitations_aggregate: Team_User_Invitations_Aggregate;
  /** fetch data from the table: "team_user_invitations" using primary key columns */
  team_user_invitations_by_pk?: Maybe<Team_User_Invitations>;
  /** An array relationship */
  team_users: Array<Team_Users>;
  /** An aggregate relationship */
  team_users_aggregate: Team_Users_Aggregate;
  /** fetch data from the table: "team_users" using primary key columns */
  team_users_by_pk?: Maybe<Team_Users>;
  /** fetch data from the table: "teams" */
  teams: Array<Teams>;
  /** fetch aggregated fields from the table: "teams" */
  teams_aggregate: Teams_Aggregate;
  /** fetch data from the table: "teams" using primary key columns */
  teams_by_pk?: Maybe<Teams>;
  /** fetch data from the table: "user_file_last_viewed" */
  user_file_last_viewed: Array<User_File_Last_Viewed>;
  /** fetch aggregated fields from the table: "user_file_last_viewed" */
  user_file_last_viewed_aggregate: User_File_Last_Viewed_Aggregate;
  /** fetch data from the table: "user_file_last_viewed" using primary key columns */
  user_file_last_viewed_by_pk?: Maybe<User_File_Last_Viewed>;
  /** fetch data from the table: "user_preferences" */
  user_preferences: Array<User_Preferences>;
  /** fetch aggregated fields from the table: "user_preferences" */
  user_preferences_aggregate: User_Preferences_Aggregate;
  /** fetch data from the table: "user_preferences" using primary key columns */
  user_preferences_by_pk?: Maybe<User_Preferences>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "version_contributors" */
  version_contributors: Array<Version_Contributors>;
  /** fetch aggregated fields from the table: "version_contributors" */
  version_contributors_aggregate: Version_Contributors_Aggregate;
  /** fetch data from the table: "version_contributors" using primary key columns */
  version_contributors_by_pk?: Maybe<Version_Contributors>;
  /** fetch data from the table: "versions" */
  versions: Array<Versions>;
  /** fetch aggregated fields from the table: "versions" */
  versions_aggregate: Versions_Aggregate;
  /** fetch data from the table: "versions" using primary key columns */
  versions_by_pk?: Maybe<Versions>;
};


export type Query_RootFile_Collaborator_InvitationsArgs = {
  distinct_on?: InputMaybe<Array<File_Collaborator_Invitations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Collaborator_Invitations_Order_By>>;
  where?: InputMaybe<File_Collaborator_Invitations_Bool_Exp>;
};


export type Query_RootFile_Collaborator_Invitations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<File_Collaborator_Invitations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Collaborator_Invitations_Order_By>>;
  where?: InputMaybe<File_Collaborator_Invitations_Bool_Exp>;
};


export type Query_RootFile_Collaborator_Invitations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootFile_CollaboratorsArgs = {
  distinct_on?: InputMaybe<Array<File_Collaborators_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Collaborators_Order_By>>;
  where?: InputMaybe<File_Collaborators_Bool_Exp>;
};


export type Query_RootFile_Collaborators_AggregateArgs = {
  distinct_on?: InputMaybe<Array<File_Collaborators_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Collaborators_Order_By>>;
  where?: InputMaybe<File_Collaborators_Bool_Exp>;
};


export type Query_RootFile_Collaborators_By_PkArgs = {
  file_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


export type Query_RootFile_Users_ViewArgs = {
  distinct_on?: InputMaybe<Array<File_Users_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Users_View_Order_By>>;
  where?: InputMaybe<File_Users_View_Bool_Exp>;
};


export type Query_RootFile_Users_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<File_Users_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Users_View_Order_By>>;
  where?: InputMaybe<File_Users_View_Bool_Exp>;
};


export type Query_RootFilesArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


export type Query_RootFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


export type Query_RootFiles_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGet_Users_By_ContributorsArgs = {
  args: Get_Users_By_Contributors_Args;
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootGet_Users_By_Contributors_AggregateArgs = {
  args: Get_Users_By_Contributors_Args;
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootImagesArgs = {
  distinct_on?: InputMaybe<Array<Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Images_Order_By>>;
  where?: InputMaybe<Images_Bool_Exp>;
};


export type Query_RootImages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Images_Order_By>>;
  where?: InputMaybe<Images_Bool_Exp>;
};


export type Query_RootImages_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootProject_Collaborator_InvitationsArgs = {
  distinct_on?: InputMaybe<Array<Project_Collaborator_Invitations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Collaborator_Invitations_Order_By>>;
  where?: InputMaybe<Project_Collaborator_Invitations_Bool_Exp>;
};


export type Query_RootProject_Collaborator_Invitations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Collaborator_Invitations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Collaborator_Invitations_Order_By>>;
  where?: InputMaybe<Project_Collaborator_Invitations_Bool_Exp>;
};


export type Query_RootProject_Collaborator_Invitations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootProject_Members_ViewArgs = {
  distinct_on?: InputMaybe<Array<Project_Members_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Members_View_Order_By>>;
  where?: InputMaybe<Project_Members_View_Bool_Exp>;
};


export type Query_RootProject_Members_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Members_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Members_View_Order_By>>;
  where?: InputMaybe<Project_Members_View_Bool_Exp>;
};


export type Query_RootProject_User_GroupsArgs = {
  distinct_on?: InputMaybe<Array<Project_User_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_User_Groups_Order_By>>;
  where?: InputMaybe<Project_User_Groups_Bool_Exp>;
};


export type Query_RootProject_User_Groups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_User_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_User_Groups_Order_By>>;
  where?: InputMaybe<Project_User_Groups_Bool_Exp>;
};


export type Query_RootProject_User_Groups_By_PkArgs = {
  project_id: Scalars['uuid'];
  team_user_group_id: Scalars['uuid'];
};


export type Query_RootProject_UsersArgs = {
  distinct_on?: InputMaybe<Array<Project_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Users_Order_By>>;
  where?: InputMaybe<Project_Users_Bool_Exp>;
};


export type Query_RootProject_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Users_Order_By>>;
  where?: InputMaybe<Project_Users_Bool_Exp>;
};


export type Query_RootProject_Users_By_PkArgs = {
  project_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


export type Query_RootProjectsArgs = {
  distinct_on?: InputMaybe<Array<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};


export type Query_RootProjects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};


export type Query_RootProjects_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootShare_LinksArgs = {
  distinct_on?: InputMaybe<Array<Share_Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Share_Links_Order_By>>;
  where?: InputMaybe<Share_Links_Bool_Exp>;
};


export type Query_RootShare_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Share_Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Share_Links_Order_By>>;
  where?: InputMaybe<Share_Links_Bool_Exp>;
};


export type Query_RootTeam_Delete_IntentsArgs = {
  distinct_on?: InputMaybe<Array<Team_Delete_Intents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_Delete_Intents_Order_By>>;
  where?: InputMaybe<Team_Delete_Intents_Bool_Exp>;
};


export type Query_RootTeam_Delete_Intents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Team_Delete_Intents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_Delete_Intents_Order_By>>;
  where?: InputMaybe<Team_Delete_Intents_Bool_Exp>;
};


export type Query_RootTeam_Delete_Intents_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTeam_Files_With_PermissionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<OrderBy>;
  project_id?: InputMaybe<Scalars['String']>;
  team_id?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<FileFilters>;
};


export type Query_RootTeam_Ownership_Transfer_IntentsArgs = {
  distinct_on?: InputMaybe<Array<Team_Ownership_Transfer_Intents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_Ownership_Transfer_Intents_Order_By>>;
  where?: InputMaybe<Team_Ownership_Transfer_Intents_Bool_Exp>;
};


export type Query_RootTeam_Ownership_Transfer_Intents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Team_Ownership_Transfer_Intents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_Ownership_Transfer_Intents_Order_By>>;
  where?: InputMaybe<Team_Ownership_Transfer_Intents_Bool_Exp>;
};


export type Query_RootTeam_Ownership_Transfer_Intents_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTeam_Project_Ids_With_PermissionArgs = {
  team_id?: InputMaybe<Scalars['String']>;
};


export type Query_RootTeam_User_Group_UsersArgs = {
  distinct_on?: InputMaybe<Array<Team_User_Group_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_User_Group_Users_Order_By>>;
  where?: InputMaybe<Team_User_Group_Users_Bool_Exp>;
};


export type Query_RootTeam_User_Group_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Team_User_Group_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_User_Group_Users_Order_By>>;
  where?: InputMaybe<Team_User_Group_Users_Bool_Exp>;
};


export type Query_RootTeam_User_Group_Users_By_PkArgs = {
  team_user_group_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


export type Query_RootTeam_User_GroupsArgs = {
  distinct_on?: InputMaybe<Array<Team_User_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_User_Groups_Order_By>>;
  where?: InputMaybe<Team_User_Groups_Bool_Exp>;
};


export type Query_RootTeam_User_Groups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Team_User_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_User_Groups_Order_By>>;
  where?: InputMaybe<Team_User_Groups_Bool_Exp>;
};


export type Query_RootTeam_User_Groups_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTeam_User_InvitationsArgs = {
  distinct_on?: InputMaybe<Array<Team_User_Invitations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_User_Invitations_Order_By>>;
  where?: InputMaybe<Team_User_Invitations_Bool_Exp>;
};


export type Query_RootTeam_User_Invitations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Team_User_Invitations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_User_Invitations_Order_By>>;
  where?: InputMaybe<Team_User_Invitations_Bool_Exp>;
};


export type Query_RootTeam_User_Invitations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTeam_UsersArgs = {
  distinct_on?: InputMaybe<Array<Team_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_Users_Order_By>>;
  where?: InputMaybe<Team_Users_Bool_Exp>;
};


export type Query_RootTeam_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Team_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_Users_Order_By>>;
  where?: InputMaybe<Team_Users_Bool_Exp>;
};


export type Query_RootTeam_Users_By_PkArgs = {
  team_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


export type Query_RootTeamsArgs = {
  distinct_on?: InputMaybe<Array<Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Order_By>>;
  where?: InputMaybe<Teams_Bool_Exp>;
};


export type Query_RootTeams_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Order_By>>;
  where?: InputMaybe<Teams_Bool_Exp>;
};


export type Query_RootTeams_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUser_File_Last_ViewedArgs = {
  distinct_on?: InputMaybe<Array<User_File_Last_Viewed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_File_Last_Viewed_Order_By>>;
  where?: InputMaybe<User_File_Last_Viewed_Bool_Exp>;
};


export type Query_RootUser_File_Last_Viewed_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_File_Last_Viewed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_File_Last_Viewed_Order_By>>;
  where?: InputMaybe<User_File_Last_Viewed_Bool_Exp>;
};


export type Query_RootUser_File_Last_Viewed_By_PkArgs = {
  file_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


export type Query_RootUser_PreferencesArgs = {
  distinct_on?: InputMaybe<Array<User_Preferences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Preferences_Order_By>>;
  where?: InputMaybe<User_Preferences_Bool_Exp>;
};


export type Query_RootUser_Preferences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Preferences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Preferences_Order_By>>;
  where?: InputMaybe<User_Preferences_Bool_Exp>;
};


export type Query_RootUser_Preferences_By_PkArgs = {
  key: Scalars['String'];
  user_id: Scalars['uuid'];
};


export type Query_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootUsers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootVersion_ContributorsArgs = {
  distinct_on?: InputMaybe<Array<Version_Contributors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Version_Contributors_Order_By>>;
  where?: InputMaybe<Version_Contributors_Bool_Exp>;
};


export type Query_RootVersion_Contributors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Version_Contributors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Version_Contributors_Order_By>>;
  where?: InputMaybe<Version_Contributors_Bool_Exp>;
};


export type Query_RootVersion_Contributors_By_PkArgs = {
  file_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
  version_id: Scalars['String'];
};


export type Query_RootVersionsArgs = {
  distinct_on?: InputMaybe<Array<Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Versions_Order_By>>;
  where?: InputMaybe<Versions_Bool_Exp>;
};


export type Query_RootVersions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Versions_Order_By>>;
  where?: InputMaybe<Versions_Bool_Exp>;
};


export type Query_RootVersions_By_PkArgs = {
  id: Scalars['uuid'];
};

/** columns and relationships of "share_links" */
export type Share_Links = {
  __typename?: 'share_links';
  created_at: Scalars['timestamptz'];
  creator_id: Scalars['uuid'];
  /** An object relationship */
  file?: Maybe<Files>;
  file_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  url?: Maybe<Scalars['String']>;
};

/** aggregated selection of "share_links" */
export type Share_Links_Aggregate = {
  __typename?: 'share_links_aggregate';
  aggregate?: Maybe<Share_Links_Aggregate_Fields>;
  nodes: Array<Share_Links>;
};

/** aggregate fields of "share_links" */
export type Share_Links_Aggregate_Fields = {
  __typename?: 'share_links_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Share_Links_Max_Fields>;
  min?: Maybe<Share_Links_Min_Fields>;
};


/** aggregate fields of "share_links" */
export type Share_Links_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Share_Links_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "share_links". All fields are combined with a logical 'AND'. */
export type Share_Links_Bool_Exp = {
  _and?: InputMaybe<Array<Share_Links_Bool_Exp>>;
  _not?: InputMaybe<Share_Links_Bool_Exp>;
  _or?: InputMaybe<Array<Share_Links_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  creator_id?: InputMaybe<Uuid_Comparison_Exp>;
  file?: InputMaybe<Files_Bool_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "share_links" */
export type Share_Links_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  creator_id?: InputMaybe<Scalars['uuid']>;
  file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  file_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Share_Links_Max_Fields = {
  __typename?: 'share_links_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  creator_id?: Maybe<Scalars['uuid']>;
  file_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Share_Links_Min_Fields = {
  __typename?: 'share_links_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  creator_id?: Maybe<Scalars['uuid']>;
  file_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "share_links" */
export type Share_Links_Mutation_Response = {
  __typename?: 'share_links_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Share_Links>;
};

/** Ordering options when selecting data from "share_links". */
export type Share_Links_Order_By = {
  created_at?: InputMaybe<Order_By>;
  creator_id?: InputMaybe<Order_By>;
  file?: InputMaybe<Files_Order_By>;
  file_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** select columns of table "share_links" */
export enum Share_Links_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  FileId = 'file_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "share_links" */
export type Share_Links_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  creator_id?: InputMaybe<Scalars['uuid']>;
  file_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "share_links" */
export type Share_Links_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Share_Links_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Share_Links_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  creator_id?: InputMaybe<Scalars['uuid']>;
  file_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
};

export type Share_Links_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Share_Links_Set_Input>;
  where: Share_Links_Bool_Exp;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "file_collaborator_invitations" */
  file_collaborator_invitations: Array<File_Collaborator_Invitations>;
  /** fetch aggregated fields from the table: "file_collaborator_invitations" */
  file_collaborator_invitations_aggregate: File_Collaborator_Invitations_Aggregate;
  /** fetch data from the table: "file_collaborator_invitations" using primary key columns */
  file_collaborator_invitations_by_pk?: Maybe<File_Collaborator_Invitations>;
  /** fetch data from the table in a streaming manner: "file_collaborator_invitations" */
  file_collaborator_invitations_stream: Array<File_Collaborator_Invitations>;
  /** fetch data from the table: "file_collaborators" */
  file_collaborators: Array<File_Collaborators>;
  /** fetch aggregated fields from the table: "file_collaborators" */
  file_collaborators_aggregate: File_Collaborators_Aggregate;
  /** fetch data from the table: "file_collaborators" using primary key columns */
  file_collaborators_by_pk?: Maybe<File_Collaborators>;
  /** fetch data from the table in a streaming manner: "file_collaborators" */
  file_collaborators_stream: Array<File_Collaborators>;
  /** fetch data from the table: "file_users_view" */
  file_users_view: Array<File_Users_View>;
  /** fetch aggregated fields from the table: "file_users_view" */
  file_users_view_aggregate: File_Users_View_Aggregate;
  /** fetch data from the table in a streaming manner: "file_users_view" */
  file_users_view_stream: Array<File_Users_View>;
  /** fetch data from the table: "files" */
  files: Array<Files>;
  /** fetch aggregated fields from the table: "files" */
  files_aggregate: Files_Aggregate;
  /** fetch data from the table: "files" using primary key columns */
  files_by_pk?: Maybe<Files>;
  /** fetch data from the table in a streaming manner: "files" */
  files_stream: Array<Files>;
  /** execute function "get_users_by_contributors" which returns "users" */
  get_users_by_contributors: Array<Users>;
  /** execute function "get_users_by_contributors" and query aggregates on result of table type "users" */
  get_users_by_contributors_aggregate: Users_Aggregate;
  /** fetch data from the table: "images" */
  images: Array<Images>;
  /** fetch aggregated fields from the table: "images" */
  images_aggregate: Images_Aggregate;
  /** fetch data from the table: "images" using primary key columns */
  images_by_pk?: Maybe<Images>;
  /** fetch data from the table in a streaming manner: "images" */
  images_stream: Array<Images>;
  /** fetch data from the table: "project_collaborator_invitations" */
  project_collaborator_invitations: Array<Project_Collaborator_Invitations>;
  /** fetch aggregated fields from the table: "project_collaborator_invitations" */
  project_collaborator_invitations_aggregate: Project_Collaborator_Invitations_Aggregate;
  /** fetch data from the table: "project_collaborator_invitations" using primary key columns */
  project_collaborator_invitations_by_pk?: Maybe<Project_Collaborator_Invitations>;
  /** fetch data from the table in a streaming manner: "project_collaborator_invitations" */
  project_collaborator_invitations_stream: Array<Project_Collaborator_Invitations>;
  /** fetch data from the table: "project_members_view" */
  project_members_view: Array<Project_Members_View>;
  /** fetch aggregated fields from the table: "project_members_view" */
  project_members_view_aggregate: Project_Members_View_Aggregate;
  /** fetch data from the table in a streaming manner: "project_members_view" */
  project_members_view_stream: Array<Project_Members_View>;
  /** fetch data from the table: "project_user_groups" */
  project_user_groups: Array<Project_User_Groups>;
  /** fetch aggregated fields from the table: "project_user_groups" */
  project_user_groups_aggregate: Project_User_Groups_Aggregate;
  /** fetch data from the table: "project_user_groups" using primary key columns */
  project_user_groups_by_pk?: Maybe<Project_User_Groups>;
  /** fetch data from the table in a streaming manner: "project_user_groups" */
  project_user_groups_stream: Array<Project_User_Groups>;
  /** An array relationship */
  project_users: Array<Project_Users>;
  /** An aggregate relationship */
  project_users_aggregate: Project_Users_Aggregate;
  /** fetch data from the table: "project_users" using primary key columns */
  project_users_by_pk?: Maybe<Project_Users>;
  /** fetch data from the table in a streaming manner: "project_users" */
  project_users_stream: Array<Project_Users>;
  /** fetch data from the table: "projects" */
  projects: Array<Projects>;
  /** fetch aggregated fields from the table: "projects" */
  projects_aggregate: Projects_Aggregate;
  /** fetch data from the table: "projects" using primary key columns */
  projects_by_pk?: Maybe<Projects>;
  /** fetch data from the table in a streaming manner: "projects" */
  projects_stream: Array<Projects>;
  /** fetch data from the table: "share_links" */
  share_links: Array<Share_Links>;
  /** fetch aggregated fields from the table: "share_links" */
  share_links_aggregate: Share_Links_Aggregate;
  /** fetch data from the table in a streaming manner: "share_links" */
  share_links_stream: Array<Share_Links>;
  /** fetch data from the table: "team_delete_intents" */
  team_delete_intents: Array<Team_Delete_Intents>;
  /** fetch aggregated fields from the table: "team_delete_intents" */
  team_delete_intents_aggregate: Team_Delete_Intents_Aggregate;
  /** fetch data from the table: "team_delete_intents" using primary key columns */
  team_delete_intents_by_pk?: Maybe<Team_Delete_Intents>;
  /** fetch data from the table in a streaming manner: "team_delete_intents" */
  team_delete_intents_stream: Array<Team_Delete_Intents>;
  /** fetch data from the table: "team_ownership_transfer_intents" */
  team_ownership_transfer_intents: Array<Team_Ownership_Transfer_Intents>;
  /** fetch aggregated fields from the table: "team_ownership_transfer_intents" */
  team_ownership_transfer_intents_aggregate: Team_Ownership_Transfer_Intents_Aggregate;
  /** fetch data from the table: "team_ownership_transfer_intents" using primary key columns */
  team_ownership_transfer_intents_by_pk?: Maybe<Team_Ownership_Transfer_Intents>;
  /** fetch data from the table in a streaming manner: "team_ownership_transfer_intents" */
  team_ownership_transfer_intents_stream: Array<Team_Ownership_Transfer_Intents>;
  /** fetch data from the table: "team_user_group_users" */
  team_user_group_users: Array<Team_User_Group_Users>;
  /** fetch aggregated fields from the table: "team_user_group_users" */
  team_user_group_users_aggregate: Team_User_Group_Users_Aggregate;
  /** fetch data from the table: "team_user_group_users" using primary key columns */
  team_user_group_users_by_pk?: Maybe<Team_User_Group_Users>;
  /** fetch data from the table in a streaming manner: "team_user_group_users" */
  team_user_group_users_stream: Array<Team_User_Group_Users>;
  /** fetch data from the table: "team_user_groups" */
  team_user_groups: Array<Team_User_Groups>;
  /** fetch aggregated fields from the table: "team_user_groups" */
  team_user_groups_aggregate: Team_User_Groups_Aggregate;
  /** fetch data from the table: "team_user_groups" using primary key columns */
  team_user_groups_by_pk?: Maybe<Team_User_Groups>;
  /** fetch data from the table in a streaming manner: "team_user_groups" */
  team_user_groups_stream: Array<Team_User_Groups>;
  /** fetch data from the table: "team_user_invitations" */
  team_user_invitations: Array<Team_User_Invitations>;
  /** fetch aggregated fields from the table: "team_user_invitations" */
  team_user_invitations_aggregate: Team_User_Invitations_Aggregate;
  /** fetch data from the table: "team_user_invitations" using primary key columns */
  team_user_invitations_by_pk?: Maybe<Team_User_Invitations>;
  /** fetch data from the table in a streaming manner: "team_user_invitations" */
  team_user_invitations_stream: Array<Team_User_Invitations>;
  /** An array relationship */
  team_users: Array<Team_Users>;
  /** An aggregate relationship */
  team_users_aggregate: Team_Users_Aggregate;
  /** fetch data from the table: "team_users" using primary key columns */
  team_users_by_pk?: Maybe<Team_Users>;
  /** fetch data from the table in a streaming manner: "team_users" */
  team_users_stream: Array<Team_Users>;
  /** fetch data from the table: "teams" */
  teams: Array<Teams>;
  /** fetch aggregated fields from the table: "teams" */
  teams_aggregate: Teams_Aggregate;
  /** fetch data from the table: "teams" using primary key columns */
  teams_by_pk?: Maybe<Teams>;
  /** fetch data from the table in a streaming manner: "teams" */
  teams_stream: Array<Teams>;
  /** fetch data from the table: "user_file_last_viewed" */
  user_file_last_viewed: Array<User_File_Last_Viewed>;
  /** fetch aggregated fields from the table: "user_file_last_viewed" */
  user_file_last_viewed_aggregate: User_File_Last_Viewed_Aggregate;
  /** fetch data from the table: "user_file_last_viewed" using primary key columns */
  user_file_last_viewed_by_pk?: Maybe<User_File_Last_Viewed>;
  /** fetch data from the table in a streaming manner: "user_file_last_viewed" */
  user_file_last_viewed_stream: Array<User_File_Last_Viewed>;
  /** fetch data from the table: "user_preferences" */
  user_preferences: Array<User_Preferences>;
  /** fetch aggregated fields from the table: "user_preferences" */
  user_preferences_aggregate: User_Preferences_Aggregate;
  /** fetch data from the table: "user_preferences" using primary key columns */
  user_preferences_by_pk?: Maybe<User_Preferences>;
  /** fetch data from the table in a streaming manner: "user_preferences" */
  user_preferences_stream: Array<User_Preferences>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table in a streaming manner: "users" */
  users_stream: Array<Users>;
  /** fetch data from the table: "version_contributors" */
  version_contributors: Array<Version_Contributors>;
  /** fetch aggregated fields from the table: "version_contributors" */
  version_contributors_aggregate: Version_Contributors_Aggregate;
  /** fetch data from the table: "version_contributors" using primary key columns */
  version_contributors_by_pk?: Maybe<Version_Contributors>;
  /** fetch data from the table in a streaming manner: "version_contributors" */
  version_contributors_stream: Array<Version_Contributors>;
  /** fetch data from the table: "versions" */
  versions: Array<Versions>;
  /** fetch aggregated fields from the table: "versions" */
  versions_aggregate: Versions_Aggregate;
  /** fetch data from the table: "versions" using primary key columns */
  versions_by_pk?: Maybe<Versions>;
  /** fetch data from the table in a streaming manner: "versions" */
  versions_stream: Array<Versions>;
};


export type Subscription_RootFile_Collaborator_InvitationsArgs = {
  distinct_on?: InputMaybe<Array<File_Collaborator_Invitations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Collaborator_Invitations_Order_By>>;
  where?: InputMaybe<File_Collaborator_Invitations_Bool_Exp>;
};


export type Subscription_RootFile_Collaborator_Invitations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<File_Collaborator_Invitations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Collaborator_Invitations_Order_By>>;
  where?: InputMaybe<File_Collaborator_Invitations_Bool_Exp>;
};


export type Subscription_RootFile_Collaborator_Invitations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootFile_Collaborator_Invitations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<File_Collaborator_Invitations_Stream_Cursor_Input>>;
  where?: InputMaybe<File_Collaborator_Invitations_Bool_Exp>;
};


export type Subscription_RootFile_CollaboratorsArgs = {
  distinct_on?: InputMaybe<Array<File_Collaborators_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Collaborators_Order_By>>;
  where?: InputMaybe<File_Collaborators_Bool_Exp>;
};


export type Subscription_RootFile_Collaborators_AggregateArgs = {
  distinct_on?: InputMaybe<Array<File_Collaborators_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Collaborators_Order_By>>;
  where?: InputMaybe<File_Collaborators_Bool_Exp>;
};


export type Subscription_RootFile_Collaborators_By_PkArgs = {
  file_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


export type Subscription_RootFile_Collaborators_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<File_Collaborators_Stream_Cursor_Input>>;
  where?: InputMaybe<File_Collaborators_Bool_Exp>;
};


export type Subscription_RootFile_Users_ViewArgs = {
  distinct_on?: InputMaybe<Array<File_Users_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Users_View_Order_By>>;
  where?: InputMaybe<File_Users_View_Bool_Exp>;
};


export type Subscription_RootFile_Users_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<File_Users_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Users_View_Order_By>>;
  where?: InputMaybe<File_Users_View_Bool_Exp>;
};


export type Subscription_RootFile_Users_View_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<File_Users_View_Stream_Cursor_Input>>;
  where?: InputMaybe<File_Users_View_Bool_Exp>;
};


export type Subscription_RootFilesArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


export type Subscription_RootFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


export type Subscription_RootFiles_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootFiles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Files_Stream_Cursor_Input>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


export type Subscription_RootGet_Users_By_ContributorsArgs = {
  args: Get_Users_By_Contributors_Args;
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootGet_Users_By_Contributors_AggregateArgs = {
  args: Get_Users_By_Contributors_Args;
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootImagesArgs = {
  distinct_on?: InputMaybe<Array<Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Images_Order_By>>;
  where?: InputMaybe<Images_Bool_Exp>;
};


export type Subscription_RootImages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Images_Order_By>>;
  where?: InputMaybe<Images_Bool_Exp>;
};


export type Subscription_RootImages_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootImages_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Images_Stream_Cursor_Input>>;
  where?: InputMaybe<Images_Bool_Exp>;
};


export type Subscription_RootProject_Collaborator_InvitationsArgs = {
  distinct_on?: InputMaybe<Array<Project_Collaborator_Invitations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Collaborator_Invitations_Order_By>>;
  where?: InputMaybe<Project_Collaborator_Invitations_Bool_Exp>;
};


export type Subscription_RootProject_Collaborator_Invitations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Collaborator_Invitations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Collaborator_Invitations_Order_By>>;
  where?: InputMaybe<Project_Collaborator_Invitations_Bool_Exp>;
};


export type Subscription_RootProject_Collaborator_Invitations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootProject_Collaborator_Invitations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Project_Collaborator_Invitations_Stream_Cursor_Input>>;
  where?: InputMaybe<Project_Collaborator_Invitations_Bool_Exp>;
};


export type Subscription_RootProject_Members_ViewArgs = {
  distinct_on?: InputMaybe<Array<Project_Members_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Members_View_Order_By>>;
  where?: InputMaybe<Project_Members_View_Bool_Exp>;
};


export type Subscription_RootProject_Members_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Members_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Members_View_Order_By>>;
  where?: InputMaybe<Project_Members_View_Bool_Exp>;
};


export type Subscription_RootProject_Members_View_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Project_Members_View_Stream_Cursor_Input>>;
  where?: InputMaybe<Project_Members_View_Bool_Exp>;
};


export type Subscription_RootProject_User_GroupsArgs = {
  distinct_on?: InputMaybe<Array<Project_User_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_User_Groups_Order_By>>;
  where?: InputMaybe<Project_User_Groups_Bool_Exp>;
};


export type Subscription_RootProject_User_Groups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_User_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_User_Groups_Order_By>>;
  where?: InputMaybe<Project_User_Groups_Bool_Exp>;
};


export type Subscription_RootProject_User_Groups_By_PkArgs = {
  project_id: Scalars['uuid'];
  team_user_group_id: Scalars['uuid'];
};


export type Subscription_RootProject_User_Groups_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Project_User_Groups_Stream_Cursor_Input>>;
  where?: InputMaybe<Project_User_Groups_Bool_Exp>;
};


export type Subscription_RootProject_UsersArgs = {
  distinct_on?: InputMaybe<Array<Project_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Users_Order_By>>;
  where?: InputMaybe<Project_Users_Bool_Exp>;
};


export type Subscription_RootProject_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Users_Order_By>>;
  where?: InputMaybe<Project_Users_Bool_Exp>;
};


export type Subscription_RootProject_Users_By_PkArgs = {
  project_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


export type Subscription_RootProject_Users_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Project_Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Project_Users_Bool_Exp>;
};


export type Subscription_RootProjectsArgs = {
  distinct_on?: InputMaybe<Array<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};


export type Subscription_RootProjects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};


export type Subscription_RootProjects_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootProjects_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Projects_Stream_Cursor_Input>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};


export type Subscription_RootShare_LinksArgs = {
  distinct_on?: InputMaybe<Array<Share_Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Share_Links_Order_By>>;
  where?: InputMaybe<Share_Links_Bool_Exp>;
};


export type Subscription_RootShare_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Share_Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Share_Links_Order_By>>;
  where?: InputMaybe<Share_Links_Bool_Exp>;
};


export type Subscription_RootShare_Links_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Share_Links_Stream_Cursor_Input>>;
  where?: InputMaybe<Share_Links_Bool_Exp>;
};


export type Subscription_RootTeam_Delete_IntentsArgs = {
  distinct_on?: InputMaybe<Array<Team_Delete_Intents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_Delete_Intents_Order_By>>;
  where?: InputMaybe<Team_Delete_Intents_Bool_Exp>;
};


export type Subscription_RootTeam_Delete_Intents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Team_Delete_Intents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_Delete_Intents_Order_By>>;
  where?: InputMaybe<Team_Delete_Intents_Bool_Exp>;
};


export type Subscription_RootTeam_Delete_Intents_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTeam_Delete_Intents_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Team_Delete_Intents_Stream_Cursor_Input>>;
  where?: InputMaybe<Team_Delete_Intents_Bool_Exp>;
};


export type Subscription_RootTeam_Ownership_Transfer_IntentsArgs = {
  distinct_on?: InputMaybe<Array<Team_Ownership_Transfer_Intents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_Ownership_Transfer_Intents_Order_By>>;
  where?: InputMaybe<Team_Ownership_Transfer_Intents_Bool_Exp>;
};


export type Subscription_RootTeam_Ownership_Transfer_Intents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Team_Ownership_Transfer_Intents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_Ownership_Transfer_Intents_Order_By>>;
  where?: InputMaybe<Team_Ownership_Transfer_Intents_Bool_Exp>;
};


export type Subscription_RootTeam_Ownership_Transfer_Intents_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTeam_Ownership_Transfer_Intents_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Team_Ownership_Transfer_Intents_Stream_Cursor_Input>>;
  where?: InputMaybe<Team_Ownership_Transfer_Intents_Bool_Exp>;
};


export type Subscription_RootTeam_User_Group_UsersArgs = {
  distinct_on?: InputMaybe<Array<Team_User_Group_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_User_Group_Users_Order_By>>;
  where?: InputMaybe<Team_User_Group_Users_Bool_Exp>;
};


export type Subscription_RootTeam_User_Group_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Team_User_Group_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_User_Group_Users_Order_By>>;
  where?: InputMaybe<Team_User_Group_Users_Bool_Exp>;
};


export type Subscription_RootTeam_User_Group_Users_By_PkArgs = {
  team_user_group_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


export type Subscription_RootTeam_User_Group_Users_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Team_User_Group_Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Team_User_Group_Users_Bool_Exp>;
};


export type Subscription_RootTeam_User_GroupsArgs = {
  distinct_on?: InputMaybe<Array<Team_User_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_User_Groups_Order_By>>;
  where?: InputMaybe<Team_User_Groups_Bool_Exp>;
};


export type Subscription_RootTeam_User_Groups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Team_User_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_User_Groups_Order_By>>;
  where?: InputMaybe<Team_User_Groups_Bool_Exp>;
};


export type Subscription_RootTeam_User_Groups_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTeam_User_Groups_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Team_User_Groups_Stream_Cursor_Input>>;
  where?: InputMaybe<Team_User_Groups_Bool_Exp>;
};


export type Subscription_RootTeam_User_InvitationsArgs = {
  distinct_on?: InputMaybe<Array<Team_User_Invitations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_User_Invitations_Order_By>>;
  where?: InputMaybe<Team_User_Invitations_Bool_Exp>;
};


export type Subscription_RootTeam_User_Invitations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Team_User_Invitations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_User_Invitations_Order_By>>;
  where?: InputMaybe<Team_User_Invitations_Bool_Exp>;
};


export type Subscription_RootTeam_User_Invitations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTeam_User_Invitations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Team_User_Invitations_Stream_Cursor_Input>>;
  where?: InputMaybe<Team_User_Invitations_Bool_Exp>;
};


export type Subscription_RootTeam_UsersArgs = {
  distinct_on?: InputMaybe<Array<Team_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_Users_Order_By>>;
  where?: InputMaybe<Team_Users_Bool_Exp>;
};


export type Subscription_RootTeam_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Team_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_Users_Order_By>>;
  where?: InputMaybe<Team_Users_Bool_Exp>;
};


export type Subscription_RootTeam_Users_By_PkArgs = {
  team_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


export type Subscription_RootTeam_Users_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Team_Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Team_Users_Bool_Exp>;
};


export type Subscription_RootTeamsArgs = {
  distinct_on?: InputMaybe<Array<Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Order_By>>;
  where?: InputMaybe<Teams_Bool_Exp>;
};


export type Subscription_RootTeams_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Order_By>>;
  where?: InputMaybe<Teams_Bool_Exp>;
};


export type Subscription_RootTeams_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTeams_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Teams_Stream_Cursor_Input>>;
  where?: InputMaybe<Teams_Bool_Exp>;
};


export type Subscription_RootUser_File_Last_ViewedArgs = {
  distinct_on?: InputMaybe<Array<User_File_Last_Viewed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_File_Last_Viewed_Order_By>>;
  where?: InputMaybe<User_File_Last_Viewed_Bool_Exp>;
};


export type Subscription_RootUser_File_Last_Viewed_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_File_Last_Viewed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_File_Last_Viewed_Order_By>>;
  where?: InputMaybe<User_File_Last_Viewed_Bool_Exp>;
};


export type Subscription_RootUser_File_Last_Viewed_By_PkArgs = {
  file_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


export type Subscription_RootUser_File_Last_Viewed_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_File_Last_Viewed_Stream_Cursor_Input>>;
  where?: InputMaybe<User_File_Last_Viewed_Bool_Exp>;
};


export type Subscription_RootUser_PreferencesArgs = {
  distinct_on?: InputMaybe<Array<User_Preferences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Preferences_Order_By>>;
  where?: InputMaybe<User_Preferences_Bool_Exp>;
};


export type Subscription_RootUser_Preferences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Preferences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Preferences_Order_By>>;
  where?: InputMaybe<User_Preferences_Bool_Exp>;
};


export type Subscription_RootUser_Preferences_By_PkArgs = {
  key: Scalars['String'];
  user_id: Scalars['uuid'];
};


export type Subscription_RootUser_Preferences_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Preferences_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Preferences_Bool_Exp>;
};


export type Subscription_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUsers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootVersion_ContributorsArgs = {
  distinct_on?: InputMaybe<Array<Version_Contributors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Version_Contributors_Order_By>>;
  where?: InputMaybe<Version_Contributors_Bool_Exp>;
};


export type Subscription_RootVersion_Contributors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Version_Contributors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Version_Contributors_Order_By>>;
  where?: InputMaybe<Version_Contributors_Bool_Exp>;
};


export type Subscription_RootVersion_Contributors_By_PkArgs = {
  file_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
  version_id: Scalars['String'];
};


export type Subscription_RootVersion_Contributors_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Version_Contributors_Stream_Cursor_Input>>;
  where?: InputMaybe<Version_Contributors_Bool_Exp>;
};


export type Subscription_RootVersionsArgs = {
  distinct_on?: InputMaybe<Array<Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Versions_Order_By>>;
  where?: InputMaybe<Versions_Bool_Exp>;
};


export type Subscription_RootVersions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Versions_Order_By>>;
  where?: InputMaybe<Versions_Bool_Exp>;
};


export type Subscription_RootVersions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootVersions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Versions_Stream_Cursor_Input>>;
  where?: InputMaybe<Versions_Bool_Exp>;
};

/** columns and relationships of "team_delete_intents" */
export type Team_Delete_Intents = {
  __typename?: 'team_delete_intents';
  code: Scalars['String'];
  created_at: Scalars['timestamptz'];
  creator_id: Scalars['uuid'];
  id: Scalars['uuid'];
  status: Scalars['String'];
  team_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "team_delete_intents" */
export type Team_Delete_Intents_Aggregate = {
  __typename?: 'team_delete_intents_aggregate';
  aggregate?: Maybe<Team_Delete_Intents_Aggregate_Fields>;
  nodes: Array<Team_Delete_Intents>;
};

/** aggregate fields of "team_delete_intents" */
export type Team_Delete_Intents_Aggregate_Fields = {
  __typename?: 'team_delete_intents_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Team_Delete_Intents_Max_Fields>;
  min?: Maybe<Team_Delete_Intents_Min_Fields>;
};


/** aggregate fields of "team_delete_intents" */
export type Team_Delete_Intents_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Team_Delete_Intents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "team_delete_intents". All fields are combined with a logical 'AND'. */
export type Team_Delete_Intents_Bool_Exp = {
  _and?: InputMaybe<Array<Team_Delete_Intents_Bool_Exp>>;
  _not?: InputMaybe<Team_Delete_Intents_Bool_Exp>;
  _or?: InputMaybe<Array<Team_Delete_Intents_Bool_Exp>>;
  code?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  creator_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "team_delete_intents" */
export enum Team_Delete_Intents_Constraint {
  /** unique or primary key constraint on columns "code" */
  TeamDeleteIntentsCodeKey = 'team_delete_intents_code_key',
  /** unique or primary key constraint on columns "id" */
  TeamDeleteIntentsPkey = 'team_delete_intents_pkey'
}

/** input type for inserting data into table "team_delete_intents" */
export type Team_Delete_Intents_Insert_Input = {
  code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  creator_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Team_Delete_Intents_Max_Fields = {
  __typename?: 'team_delete_intents_max_fields';
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  creator_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Team_Delete_Intents_Min_Fields = {
  __typename?: 'team_delete_intents_min_fields';
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  creator_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "team_delete_intents" */
export type Team_Delete_Intents_Mutation_Response = {
  __typename?: 'team_delete_intents_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Team_Delete_Intents>;
};

/** on_conflict condition type for table "team_delete_intents" */
export type Team_Delete_Intents_On_Conflict = {
  constraint: Team_Delete_Intents_Constraint;
  update_columns?: Array<Team_Delete_Intents_Update_Column>;
  where?: InputMaybe<Team_Delete_Intents_Bool_Exp>;
};

/** Ordering options when selecting data from "team_delete_intents". */
export type Team_Delete_Intents_Order_By = {
  code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  creator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: team_delete_intents */
export type Team_Delete_Intents_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "team_delete_intents" */
export enum Team_Delete_Intents_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "team_delete_intents" */
export type Team_Delete_Intents_Set_Input = {
  code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  creator_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "team_delete_intents" */
export type Team_Delete_Intents_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Team_Delete_Intents_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Team_Delete_Intents_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  creator_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "team_delete_intents" */
export enum Team_Delete_Intents_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Team_Delete_Intents_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Team_Delete_Intents_Set_Input>;
  where: Team_Delete_Intents_Bool_Exp;
};

/** columns and relationships of "team_ownership_transfer_intents" */
export type Team_Ownership_Transfer_Intents = {
  __typename?: 'team_ownership_transfer_intents';
  code: Scalars['String'];
  created_at: Scalars['timestamptz'];
  creator_id: Scalars['uuid'];
  id: Scalars['uuid'];
  status: Scalars['String'];
  team_id: Scalars['uuid'];
  to_user_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "team_ownership_transfer_intents" */
export type Team_Ownership_Transfer_Intents_Aggregate = {
  __typename?: 'team_ownership_transfer_intents_aggregate';
  aggregate?: Maybe<Team_Ownership_Transfer_Intents_Aggregate_Fields>;
  nodes: Array<Team_Ownership_Transfer_Intents>;
};

/** aggregate fields of "team_ownership_transfer_intents" */
export type Team_Ownership_Transfer_Intents_Aggregate_Fields = {
  __typename?: 'team_ownership_transfer_intents_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Team_Ownership_Transfer_Intents_Max_Fields>;
  min?: Maybe<Team_Ownership_Transfer_Intents_Min_Fields>;
};


/** aggregate fields of "team_ownership_transfer_intents" */
export type Team_Ownership_Transfer_Intents_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Team_Ownership_Transfer_Intents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "team_ownership_transfer_intents". All fields are combined with a logical 'AND'. */
export type Team_Ownership_Transfer_Intents_Bool_Exp = {
  _and?: InputMaybe<Array<Team_Ownership_Transfer_Intents_Bool_Exp>>;
  _not?: InputMaybe<Team_Ownership_Transfer_Intents_Bool_Exp>;
  _or?: InputMaybe<Array<Team_Ownership_Transfer_Intents_Bool_Exp>>;
  code?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  creator_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
  to_user_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "team_ownership_transfer_intents" */
export enum Team_Ownership_Transfer_Intents_Constraint {
  /** unique or primary key constraint on columns "id" */
  TeamOwnershipTransferIntentsPkey = 'team_ownership_transfer_intents_pkey'
}

/** input type for inserting data into table "team_ownership_transfer_intents" */
export type Team_Ownership_Transfer_Intents_Insert_Input = {
  code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  creator_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  to_user_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Team_Ownership_Transfer_Intents_Max_Fields = {
  __typename?: 'team_ownership_transfer_intents_max_fields';
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  creator_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['uuid']>;
  to_user_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Team_Ownership_Transfer_Intents_Min_Fields = {
  __typename?: 'team_ownership_transfer_intents_min_fields';
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  creator_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['uuid']>;
  to_user_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "team_ownership_transfer_intents" */
export type Team_Ownership_Transfer_Intents_Mutation_Response = {
  __typename?: 'team_ownership_transfer_intents_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Team_Ownership_Transfer_Intents>;
};

/** on_conflict condition type for table "team_ownership_transfer_intents" */
export type Team_Ownership_Transfer_Intents_On_Conflict = {
  constraint: Team_Ownership_Transfer_Intents_Constraint;
  update_columns?: Array<Team_Ownership_Transfer_Intents_Update_Column>;
  where?: InputMaybe<Team_Ownership_Transfer_Intents_Bool_Exp>;
};

/** Ordering options when selecting data from "team_ownership_transfer_intents". */
export type Team_Ownership_Transfer_Intents_Order_By = {
  code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  creator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  to_user_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: team_ownership_transfer_intents */
export type Team_Ownership_Transfer_Intents_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "team_ownership_transfer_intents" */
export enum Team_Ownership_Transfer_Intents_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  ToUserId = 'to_user_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "team_ownership_transfer_intents" */
export type Team_Ownership_Transfer_Intents_Set_Input = {
  code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  creator_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  to_user_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "team_ownership_transfer_intents" */
export type Team_Ownership_Transfer_Intents_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Team_Ownership_Transfer_Intents_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Team_Ownership_Transfer_Intents_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  creator_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  to_user_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "team_ownership_transfer_intents" */
export enum Team_Ownership_Transfer_Intents_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  ToUserId = 'to_user_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Team_Ownership_Transfer_Intents_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Team_Ownership_Transfer_Intents_Set_Input>;
  where: Team_Ownership_Transfer_Intents_Bool_Exp;
};

/** columns and relationships of "team_user_group_users" */
export type Team_User_Group_Users = {
  __typename?: 'team_user_group_users';
  created_at: Scalars['timestamptz'];
  team_user_group_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['uuid'];
};

/** aggregated selection of "team_user_group_users" */
export type Team_User_Group_Users_Aggregate = {
  __typename?: 'team_user_group_users_aggregate';
  aggregate?: Maybe<Team_User_Group_Users_Aggregate_Fields>;
  nodes: Array<Team_User_Group_Users>;
};

/** aggregate fields of "team_user_group_users" */
export type Team_User_Group_Users_Aggregate_Fields = {
  __typename?: 'team_user_group_users_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Team_User_Group_Users_Max_Fields>;
  min?: Maybe<Team_User_Group_Users_Min_Fields>;
};


/** aggregate fields of "team_user_group_users" */
export type Team_User_Group_Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Team_User_Group_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "team_user_group_users". All fields are combined with a logical 'AND'. */
export type Team_User_Group_Users_Bool_Exp = {
  _and?: InputMaybe<Array<Team_User_Group_Users_Bool_Exp>>;
  _not?: InputMaybe<Team_User_Group_Users_Bool_Exp>;
  _or?: InputMaybe<Array<Team_User_Group_Users_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  team_user_group_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "team_user_group_users" */
export enum Team_User_Group_Users_Constraint {
  /** unique or primary key constraint on columns "team_user_group_id", "user_id" */
  TeamUserGroupUsersPk = 'team_user_group_users_pk'
}

/** input type for inserting data into table "team_user_group_users" */
export type Team_User_Group_Users_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  team_user_group_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Team_User_Group_Users_Max_Fields = {
  __typename?: 'team_user_group_users_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  team_user_group_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Team_User_Group_Users_Min_Fields = {
  __typename?: 'team_user_group_users_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  team_user_group_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "team_user_group_users" */
export type Team_User_Group_Users_Mutation_Response = {
  __typename?: 'team_user_group_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Team_User_Group_Users>;
};

/** on_conflict condition type for table "team_user_group_users" */
export type Team_User_Group_Users_On_Conflict = {
  constraint: Team_User_Group_Users_Constraint;
  update_columns?: Array<Team_User_Group_Users_Update_Column>;
  where?: InputMaybe<Team_User_Group_Users_Bool_Exp>;
};

/** Ordering options when selecting data from "team_user_group_users". */
export type Team_User_Group_Users_Order_By = {
  created_at?: InputMaybe<Order_By>;
  team_user_group_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: team_user_group_users */
export type Team_User_Group_Users_Pk_Columns_Input = {
  team_user_group_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

/** select columns of table "team_user_group_users" */
export enum Team_User_Group_Users_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  TeamUserGroupId = 'team_user_group_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "team_user_group_users" */
export type Team_User_Group_Users_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  team_user_group_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "team_user_group_users" */
export type Team_User_Group_Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Team_User_Group_Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Team_User_Group_Users_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  team_user_group_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "team_user_group_users" */
export enum Team_User_Group_Users_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  TeamUserGroupId = 'team_user_group_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Team_User_Group_Users_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Team_User_Group_Users_Set_Input>;
  where: Team_User_Group_Users_Bool_Exp;
};

/** columns and relationships of "team_user_groups" */
export type Team_User_Groups = {
  __typename?: 'team_user_groups';
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  name?: Maybe<Scalars['String']>;
  team_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "team_user_groups" */
export type Team_User_Groups_Aggregate = {
  __typename?: 'team_user_groups_aggregate';
  aggregate?: Maybe<Team_User_Groups_Aggregate_Fields>;
  nodes: Array<Team_User_Groups>;
};

/** aggregate fields of "team_user_groups" */
export type Team_User_Groups_Aggregate_Fields = {
  __typename?: 'team_user_groups_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Team_User_Groups_Max_Fields>;
  min?: Maybe<Team_User_Groups_Min_Fields>;
};


/** aggregate fields of "team_user_groups" */
export type Team_User_Groups_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Team_User_Groups_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "team_user_groups". All fields are combined with a logical 'AND'. */
export type Team_User_Groups_Bool_Exp = {
  _and?: InputMaybe<Array<Team_User_Groups_Bool_Exp>>;
  _not?: InputMaybe<Team_User_Groups_Bool_Exp>;
  _or?: InputMaybe<Array<Team_User_Groups_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "team_user_groups" */
export enum Team_User_Groups_Constraint {
  /** unique or primary key constraint on columns "id" */
  TeamUserGroupsPkey = 'team_user_groups_pkey'
}

/** input type for inserting data into table "team_user_groups" */
export type Team_User_Groups_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Team_User_Groups_Max_Fields = {
  __typename?: 'team_user_groups_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Team_User_Groups_Min_Fields = {
  __typename?: 'team_user_groups_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "team_user_groups" */
export type Team_User_Groups_Mutation_Response = {
  __typename?: 'team_user_groups_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Team_User_Groups>;
};

/** on_conflict condition type for table "team_user_groups" */
export type Team_User_Groups_On_Conflict = {
  constraint: Team_User_Groups_Constraint;
  update_columns?: Array<Team_User_Groups_Update_Column>;
  where?: InputMaybe<Team_User_Groups_Bool_Exp>;
};

/** Ordering options when selecting data from "team_user_groups". */
export type Team_User_Groups_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: team_user_groups */
export type Team_User_Groups_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "team_user_groups" */
export enum Team_User_Groups_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "team_user_groups" */
export type Team_User_Groups_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "team_user_groups" */
export type Team_User_Groups_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Team_User_Groups_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Team_User_Groups_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "team_user_groups" */
export enum Team_User_Groups_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Team_User_Groups_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Team_User_Groups_Set_Input>;
  where: Team_User_Groups_Bool_Exp;
};

/** columns and relationships of "team_user_invitations" */
export type Team_User_Invitations = {
  __typename?: 'team_user_invitations';
  created_at: Scalars['timestamptz'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  invitee_id?: Maybe<Scalars['uuid']>;
  invitor_id: Scalars['uuid'];
  status: Scalars['String'];
  /** An object relationship */
  team?: Maybe<Teams>;
  team_id: Scalars['uuid'];
  team_role: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "team_user_invitations" */
export type Team_User_Invitations_Aggregate = {
  __typename?: 'team_user_invitations_aggregate';
  aggregate?: Maybe<Team_User_Invitations_Aggregate_Fields>;
  nodes: Array<Team_User_Invitations>;
};

/** aggregate fields of "team_user_invitations" */
export type Team_User_Invitations_Aggregate_Fields = {
  __typename?: 'team_user_invitations_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Team_User_Invitations_Max_Fields>;
  min?: Maybe<Team_User_Invitations_Min_Fields>;
};


/** aggregate fields of "team_user_invitations" */
export type Team_User_Invitations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Team_User_Invitations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "team_user_invitations". All fields are combined with a logical 'AND'. */
export type Team_User_Invitations_Bool_Exp = {
  _and?: InputMaybe<Array<Team_User_Invitations_Bool_Exp>>;
  _not?: InputMaybe<Team_User_Invitations_Bool_Exp>;
  _or?: InputMaybe<Array<Team_User_Invitations_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invitee_id?: InputMaybe<Uuid_Comparison_Exp>;
  invitor_id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  team?: InputMaybe<Teams_Bool_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
  team_role?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "team_user_invitations" */
export enum Team_User_Invitations_Constraint {
  /** unique or primary key constraint on columns "id" */
  TeamUserInvitationsPkey = 'team_user_invitations_pkey'
}

/** input type for inserting data into table "team_user_invitations" */
export type Team_User_Invitations_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  invitee_id?: InputMaybe<Scalars['uuid']>;
  invitor_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  team?: InputMaybe<Teams_Obj_Rel_Insert_Input>;
  team_id?: InputMaybe<Scalars['uuid']>;
  team_role?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Team_User_Invitations_Max_Fields = {
  __typename?: 'team_user_invitations_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  invitee_id?: Maybe<Scalars['uuid']>;
  invitor_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['uuid']>;
  team_role?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Team_User_Invitations_Min_Fields = {
  __typename?: 'team_user_invitations_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  invitee_id?: Maybe<Scalars['uuid']>;
  invitor_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['uuid']>;
  team_role?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "team_user_invitations" */
export type Team_User_Invitations_Mutation_Response = {
  __typename?: 'team_user_invitations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Team_User_Invitations>;
};

/** on_conflict condition type for table "team_user_invitations" */
export type Team_User_Invitations_On_Conflict = {
  constraint: Team_User_Invitations_Constraint;
  update_columns?: Array<Team_User_Invitations_Update_Column>;
  where?: InputMaybe<Team_User_Invitations_Bool_Exp>;
};

/** Ordering options when selecting data from "team_user_invitations". */
export type Team_User_Invitations_Order_By = {
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invitee_id?: InputMaybe<Order_By>;
  invitor_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  team?: InputMaybe<Teams_Order_By>;
  team_id?: InputMaybe<Order_By>;
  team_role?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: team_user_invitations */
export type Team_User_Invitations_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "team_user_invitations" */
export enum Team_User_Invitations_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  InviteeId = 'invitee_id',
  /** column name */
  InvitorId = 'invitor_id',
  /** column name */
  Status = 'status',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  TeamRole = 'team_role',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "team_user_invitations" */
export type Team_User_Invitations_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  invitee_id?: InputMaybe<Scalars['uuid']>;
  invitor_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  team_role?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "team_user_invitations" */
export type Team_User_Invitations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Team_User_Invitations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Team_User_Invitations_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  invitee_id?: InputMaybe<Scalars['uuid']>;
  invitor_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  team_role?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "team_user_invitations" */
export enum Team_User_Invitations_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  InviteeId = 'invitee_id',
  /** column name */
  InvitorId = 'invitor_id',
  /** column name */
  Status = 'status',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  TeamRole = 'team_role',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Team_User_Invitations_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Team_User_Invitations_Set_Input>;
  where: Team_User_Invitations_Bool_Exp;
};

/** columns and relationships of "team_users" */
export type Team_Users = {
  __typename?: 'team_users';
  created_at: Scalars['timestamptz'];
  deactivated?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  team?: Maybe<Teams>;
  team_id: Scalars['uuid'];
  team_role: Scalars['String'];
  team_user_invitation_id?: Maybe<Scalars['uuid']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "team_users" */
export type Team_Users_Aggregate = {
  __typename?: 'team_users_aggregate';
  aggregate?: Maybe<Team_Users_Aggregate_Fields>;
  nodes: Array<Team_Users>;
};

export type Team_Users_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Team_Users_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Team_Users_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Team_Users_Aggregate_Bool_Exp_Count>;
};

export type Team_Users_Aggregate_Bool_Exp_Bool_And = {
  arguments: Team_Users_Select_Column_Team_Users_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Team_Users_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Team_Users_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Team_Users_Select_Column_Team_Users_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Team_Users_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Team_Users_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Team_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Team_Users_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "team_users" */
export type Team_Users_Aggregate_Fields = {
  __typename?: 'team_users_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Team_Users_Max_Fields>;
  min?: Maybe<Team_Users_Min_Fields>;
};


/** aggregate fields of "team_users" */
export type Team_Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Team_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "team_users" */
export type Team_Users_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Team_Users_Max_Order_By>;
  min?: InputMaybe<Team_Users_Min_Order_By>;
};

/** input type for inserting array relation for remote table "team_users" */
export type Team_Users_Arr_Rel_Insert_Input = {
  data: Array<Team_Users_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Team_Users_On_Conflict>;
};

/** Boolean expression to filter rows from the table "team_users". All fields are combined with a logical 'AND'. */
export type Team_Users_Bool_Exp = {
  _and?: InputMaybe<Array<Team_Users_Bool_Exp>>;
  _not?: InputMaybe<Team_Users_Bool_Exp>;
  _or?: InputMaybe<Array<Team_Users_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deactivated?: InputMaybe<Boolean_Comparison_Exp>;
  team?: InputMaybe<Teams_Bool_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
  team_role?: InputMaybe<String_Comparison_Exp>;
  team_user_invitation_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "team_users" */
export enum Team_Users_Constraint {
  /** unique or primary key constraint on columns "user_id", "team_id" */
  TeamUsersPk = 'team_users_pk'
}

/** input type for inserting data into table "team_users" */
export type Team_Users_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deactivated?: InputMaybe<Scalars['Boolean']>;
  team?: InputMaybe<Teams_Obj_Rel_Insert_Input>;
  team_id?: InputMaybe<Scalars['uuid']>;
  team_role?: InputMaybe<Scalars['String']>;
  team_user_invitation_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Team_Users_Max_Fields = {
  __typename?: 'team_users_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  team_id?: Maybe<Scalars['uuid']>;
  team_role?: Maybe<Scalars['String']>;
  team_user_invitation_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "team_users" */
export type Team_Users_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  team_role?: InputMaybe<Order_By>;
  team_user_invitation_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Team_Users_Min_Fields = {
  __typename?: 'team_users_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  team_id?: Maybe<Scalars['uuid']>;
  team_role?: Maybe<Scalars['String']>;
  team_user_invitation_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "team_users" */
export type Team_Users_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  team_role?: InputMaybe<Order_By>;
  team_user_invitation_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "team_users" */
export type Team_Users_Mutation_Response = {
  __typename?: 'team_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Team_Users>;
};

/** on_conflict condition type for table "team_users" */
export type Team_Users_On_Conflict = {
  constraint: Team_Users_Constraint;
  update_columns?: Array<Team_Users_Update_Column>;
  where?: InputMaybe<Team_Users_Bool_Exp>;
};

/** Ordering options when selecting data from "team_users". */
export type Team_Users_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deactivated?: InputMaybe<Order_By>;
  team?: InputMaybe<Teams_Order_By>;
  team_id?: InputMaybe<Order_By>;
  team_role?: InputMaybe<Order_By>;
  team_user_invitation_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: team_users */
export type Team_Users_Pk_Columns_Input = {
  team_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

/** select columns of table "team_users" */
export enum Team_Users_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deactivated = 'deactivated',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  TeamRole = 'team_role',
  /** column name */
  TeamUserInvitationId = 'team_user_invitation_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "team_users_aggregate_bool_exp_bool_and_arguments_columns" columns of table "team_users" */
export enum Team_Users_Select_Column_Team_Users_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Deactivated = 'deactivated'
}

/** select "team_users_aggregate_bool_exp_bool_or_arguments_columns" columns of table "team_users" */
export enum Team_Users_Select_Column_Team_Users_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Deactivated = 'deactivated'
}

/** input type for updating data in table "team_users" */
export type Team_Users_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deactivated?: InputMaybe<Scalars['Boolean']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  team_role?: InputMaybe<Scalars['String']>;
  team_user_invitation_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "team_users" */
export type Team_Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Team_Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Team_Users_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deactivated?: InputMaybe<Scalars['Boolean']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  team_role?: InputMaybe<Scalars['String']>;
  team_user_invitation_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "team_users" */
export enum Team_Users_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deactivated = 'deactivated',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  TeamRole = 'team_role',
  /** column name */
  TeamUserInvitationId = 'team_user_invitation_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Team_Users_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Team_Users_Set_Input>;
  where: Team_Users_Bool_Exp;
};

/** columns and relationships of "teams" */
export type Teams = {
  __typename?: 'teams';
  created_at: Scalars['timestamptz'];
  custom_slug?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  location?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  owner_id: Scalars['uuid'];
  /** An array relationship */
  team_users: Array<Team_Users>;
  /** An aggregate relationship */
  team_users_aggregate: Team_Users_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "teams" */
export type TeamsTeam_UsersArgs = {
  distinct_on?: InputMaybe<Array<Team_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_Users_Order_By>>;
  where?: InputMaybe<Team_Users_Bool_Exp>;
};


/** columns and relationships of "teams" */
export type TeamsTeam_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Team_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_Users_Order_By>>;
  where?: InputMaybe<Team_Users_Bool_Exp>;
};

/** aggregated selection of "teams" */
export type Teams_Aggregate = {
  __typename?: 'teams_aggregate';
  aggregate?: Maybe<Teams_Aggregate_Fields>;
  nodes: Array<Teams>;
};

/** aggregate fields of "teams" */
export type Teams_Aggregate_Fields = {
  __typename?: 'teams_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Teams_Max_Fields>;
  min?: Maybe<Teams_Min_Fields>;
};


/** aggregate fields of "teams" */
export type Teams_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Teams_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "teams". All fields are combined with a logical 'AND'. */
export type Teams_Bool_Exp = {
  _and?: InputMaybe<Array<Teams_Bool_Exp>>;
  _not?: InputMaybe<Teams_Bool_Exp>;
  _or?: InputMaybe<Array<Teams_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  custom_slug?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  icon?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  location?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  owner_id?: InputMaybe<Uuid_Comparison_Exp>;
  team_users?: InputMaybe<Team_Users_Bool_Exp>;
  team_users_aggregate?: InputMaybe<Team_Users_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "teams" */
export enum Teams_Constraint {
  /** unique or primary key constraint on columns "custom_slug" */
  TeamsCustomSlugKey = 'teams_custom_slug_key',
  /** unique or primary key constraint on columns "id" */
  TeamsPkey = 'teams_pkey'
}

/** input type for inserting data into table "teams" */
export type Teams_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  custom_slug?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  owner_id?: InputMaybe<Scalars['uuid']>;
  team_users?: InputMaybe<Team_Users_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Teams_Max_Fields = {
  __typename?: 'teams_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_slug?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  location?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Teams_Min_Fields = {
  __typename?: 'teams_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_slug?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  location?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "teams" */
export type Teams_Mutation_Response = {
  __typename?: 'teams_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Teams>;
};

/** input type for inserting object relation for remote table "teams" */
export type Teams_Obj_Rel_Insert_Input = {
  data: Teams_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Teams_On_Conflict>;
};

/** on_conflict condition type for table "teams" */
export type Teams_On_Conflict = {
  constraint: Teams_Constraint;
  update_columns?: Array<Teams_Update_Column>;
  where?: InputMaybe<Teams_Bool_Exp>;
};

/** Ordering options when selecting data from "teams". */
export type Teams_Order_By = {
  created_at?: InputMaybe<Order_By>;
  custom_slug?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  icon?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  team_users_aggregate?: InputMaybe<Team_Users_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: teams */
export type Teams_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "teams" */
export enum Teams_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomSlug = 'custom_slug',
  /** column name */
  Description = 'description',
  /** column name */
  Icon = 'icon',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  Name = 'name',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "teams" */
export type Teams_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  custom_slug?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  owner_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "teams" */
export type Teams_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Teams_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Teams_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  custom_slug?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  owner_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "teams" */
export enum Teams_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomSlug = 'custom_slug',
  /** column name */
  Description = 'description',
  /** column name */
  Icon = 'icon',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  Name = 'name',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Teams_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Teams_Set_Input>;
  where: Teams_Bool_Exp;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']>;
  _gt?: InputMaybe<Scalars['timestamp']>;
  _gte?: InputMaybe<Scalars['timestamp']>;
  _in?: InputMaybe<Array<Scalars['timestamp']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamp']>;
  _lte?: InputMaybe<Scalars['timestamp']>;
  _neq?: InputMaybe<Scalars['timestamp']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "user_file_last_viewed" */
export type User_File_Last_Viewed = {
  __typename?: 'user_file_last_viewed';
  created_at: Scalars['timestamptz'];
  file_id: Scalars['uuid'];
  last_viewed_at: Scalars['timestamptz'];
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['uuid'];
};

/** aggregated selection of "user_file_last_viewed" */
export type User_File_Last_Viewed_Aggregate = {
  __typename?: 'user_file_last_viewed_aggregate';
  aggregate?: Maybe<User_File_Last_Viewed_Aggregate_Fields>;
  nodes: Array<User_File_Last_Viewed>;
};

/** aggregate fields of "user_file_last_viewed" */
export type User_File_Last_Viewed_Aggregate_Fields = {
  __typename?: 'user_file_last_viewed_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_File_Last_Viewed_Max_Fields>;
  min?: Maybe<User_File_Last_Viewed_Min_Fields>;
};


/** aggregate fields of "user_file_last_viewed" */
export type User_File_Last_Viewed_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_File_Last_Viewed_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "user_file_last_viewed". All fields are combined with a logical 'AND'. */
export type User_File_Last_Viewed_Bool_Exp = {
  _and?: InputMaybe<Array<User_File_Last_Viewed_Bool_Exp>>;
  _not?: InputMaybe<User_File_Last_Viewed_Bool_Exp>;
  _or?: InputMaybe<Array<User_File_Last_Viewed_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
  last_viewed_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_file_last_viewed" */
export enum User_File_Last_Viewed_Constraint {
  /** unique or primary key constraint on columns "user_id", "file_id" */
  UserFileLastViewedPk = 'user_file_last_viewed_pk'
}

/** input type for inserting data into table "user_file_last_viewed" */
export type User_File_Last_Viewed_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file_id?: InputMaybe<Scalars['uuid']>;
  last_viewed_at?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_File_Last_Viewed_Max_Fields = {
  __typename?: 'user_file_last_viewed_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  file_id?: Maybe<Scalars['uuid']>;
  last_viewed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type User_File_Last_Viewed_Min_Fields = {
  __typename?: 'user_file_last_viewed_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  file_id?: Maybe<Scalars['uuid']>;
  last_viewed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "user_file_last_viewed" */
export type User_File_Last_Viewed_Mutation_Response = {
  __typename?: 'user_file_last_viewed_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_File_Last_Viewed>;
};

/** input type for inserting object relation for remote table "user_file_last_viewed" */
export type User_File_Last_Viewed_Obj_Rel_Insert_Input = {
  data: User_File_Last_Viewed_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_File_Last_Viewed_On_Conflict>;
};

/** on_conflict condition type for table "user_file_last_viewed" */
export type User_File_Last_Viewed_On_Conflict = {
  constraint: User_File_Last_Viewed_Constraint;
  update_columns?: Array<User_File_Last_Viewed_Update_Column>;
  where?: InputMaybe<User_File_Last_Viewed_Bool_Exp>;
};

/** Ordering options when selecting data from "user_file_last_viewed". */
export type User_File_Last_Viewed_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  last_viewed_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_file_last_viewed */
export type User_File_Last_Viewed_Pk_Columns_Input = {
  file_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

/** select columns of table "user_file_last_viewed" */
export enum User_File_Last_Viewed_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  LastViewedAt = 'last_viewed_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_file_last_viewed" */
export type User_File_Last_Viewed_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file_id?: InputMaybe<Scalars['uuid']>;
  last_viewed_at?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "user_file_last_viewed" */
export type User_File_Last_Viewed_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_File_Last_Viewed_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_File_Last_Viewed_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file_id?: InputMaybe<Scalars['uuid']>;
  last_viewed_at?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "user_file_last_viewed" */
export enum User_File_Last_Viewed_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  LastViewedAt = 'last_viewed_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type User_File_Last_Viewed_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_File_Last_Viewed_Set_Input>;
  where: User_File_Last_Viewed_Bool_Exp;
};

/** columns and relationships of "user_preferences" */
export type User_Preferences = {
  __typename?: 'user_preferences';
  created_at: Scalars['timestamptz'];
  key: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['uuid'];
  value: Scalars['jsonb'];
};


/** columns and relationships of "user_preferences" */
export type User_PreferencesValueArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "user_preferences" */
export type User_Preferences_Aggregate = {
  __typename?: 'user_preferences_aggregate';
  aggregate?: Maybe<User_Preferences_Aggregate_Fields>;
  nodes: Array<User_Preferences>;
};

/** aggregate fields of "user_preferences" */
export type User_Preferences_Aggregate_Fields = {
  __typename?: 'user_preferences_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Preferences_Max_Fields>;
  min?: Maybe<User_Preferences_Min_Fields>;
};


/** aggregate fields of "user_preferences" */
export type User_Preferences_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Preferences_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type User_Preferences_Append_Input = {
  value?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "user_preferences". All fields are combined with a logical 'AND'. */
export type User_Preferences_Bool_Exp = {
  _and?: InputMaybe<Array<User_Preferences_Bool_Exp>>;
  _not?: InputMaybe<User_Preferences_Bool_Exp>;
  _or?: InputMaybe<Array<User_Preferences_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  value?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_preferences" */
export enum User_Preferences_Constraint {
  /** unique or primary key constraint on columns "key", "user_id" */
  UserPreferencesPk = 'user_preferences_pk'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type User_Preferences_Delete_At_Path_Input = {
  value?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type User_Preferences_Delete_Elem_Input = {
  value?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type User_Preferences_Delete_Key_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "user_preferences" */
export type User_Preferences_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  key?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  value?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate max on columns */
export type User_Preferences_Max_Fields = {
  __typename?: 'user_preferences_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  key?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type User_Preferences_Min_Fields = {
  __typename?: 'user_preferences_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  key?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "user_preferences" */
export type User_Preferences_Mutation_Response = {
  __typename?: 'user_preferences_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Preferences>;
};

/** on_conflict condition type for table "user_preferences" */
export type User_Preferences_On_Conflict = {
  constraint: User_Preferences_Constraint;
  update_columns?: Array<User_Preferences_Update_Column>;
  where?: InputMaybe<User_Preferences_Bool_Exp>;
};

/** Ordering options when selecting data from "user_preferences". */
export type User_Preferences_Order_By = {
  created_at?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_preferences */
export type User_Preferences_Pk_Columns_Input = {
  key: Scalars['String'];
  user_id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type User_Preferences_Prepend_Input = {
  value?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "user_preferences" */
export enum User_Preferences_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Key = 'key',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "user_preferences" */
export type User_Preferences_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  key?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  value?: InputMaybe<Scalars['jsonb']>;
};

/** Streaming cursor of the table "user_preferences" */
export type User_Preferences_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Preferences_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Preferences_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  key?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  value?: InputMaybe<Scalars['jsonb']>;
};

/** update columns of table "user_preferences" */
export enum User_Preferences_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Key = 'key',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value'
}

export type User_Preferences_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<User_Preferences_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<User_Preferences_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<User_Preferences_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<User_Preferences_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<User_Preferences_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Preferences_Set_Input>;
  where: User_Preferences_Bool_Exp;
};

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'users';
  avatar?: Maybe<Scalars['String']>;
  collaborator_limit?: Maybe<Scalars['numeric']>;
  created_at: Scalars['timestamptz'];
  custom_slug?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  external_providers?: Maybe<Scalars['jsonb']>;
  id: Scalars['uuid'];
  last_active_at?: Maybe<Scalars['timestamptz']>;
  system_role: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  username?: Maybe<Scalars['String']>;
  workspace_icon?: Maybe<Scalars['String']>;
};


/** columns and relationships of "users" */
export type UsersExternal_ProvidersArgs = {
  path?: InputMaybe<Scalars['String']>;
};

export type Users_Aggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename?: 'users_aggregate_fields';
  avg?: Maybe<Users_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
  stddev?: Maybe<Users_Stddev_Fields>;
  stddev_pop?: Maybe<Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Users_Stddev_Samp_Fields>;
  sum?: Maybe<Users_Sum_Fields>;
  var_pop?: Maybe<Users_Var_Pop_Fields>;
  var_samp?: Maybe<Users_Var_Samp_Fields>;
  variance?: Maybe<Users_Variance_Fields>;
};


/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "users" */
export type Users_Aggregate_Order_By = {
  avg?: InputMaybe<Users_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Users_Max_Order_By>;
  min?: InputMaybe<Users_Min_Order_By>;
  stddev?: InputMaybe<Users_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Users_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Users_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Users_Sum_Order_By>;
  var_pop?: InputMaybe<Users_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Users_Var_Samp_Order_By>;
  variance?: InputMaybe<Users_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Users_Append_Input = {
  external_providers?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Users_Avg_Fields = {
  __typename?: 'users_avg_fields';
  collaborator_limit?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "users" */
export type Users_Avg_Order_By = {
  collaborator_limit?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Bool_Exp>>;
  _not?: InputMaybe<Users_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Bool_Exp>>;
  avatar?: InputMaybe<String_Comparison_Exp>;
  collaborator_limit?: InputMaybe<Numeric_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  custom_slug?: InputMaybe<String_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  external_providers?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  last_active_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  system_role?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  username?: InputMaybe<String_Comparison_Exp>;
  workspace_icon?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint on columns "custom_slug" */
  UsersCustomSlugKey = 'users_custom_slug_key',
  /** unique or primary key constraint on columns "id" */
  UsersPkey = 'users_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Users_Delete_At_Path_Input = {
  external_providers?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Users_Delete_Elem_Input = {
  external_providers?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Users_Delete_Key_Input = {
  external_providers?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "users" */
export type Users_Inc_Input = {
  collaborator_limit?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  avatar?: InputMaybe<Scalars['String']>;
  collaborator_limit?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  custom_slug?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  external_providers?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  last_active_at?: InputMaybe<Scalars['timestamptz']>;
  system_role?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  username?: InputMaybe<Scalars['String']>;
  workspace_icon?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: 'users_max_fields';
  avatar?: Maybe<Scalars['String']>;
  collaborator_limit?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_slug?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_active_at?: Maybe<Scalars['timestamptz']>;
  system_role?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
  workspace_icon?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "users" */
export type Users_Max_Order_By = {
  avatar?: InputMaybe<Order_By>;
  collaborator_limit?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  custom_slug?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_active_at?: InputMaybe<Order_By>;
  system_role?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
  workspace_icon?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: 'users_min_fields';
  avatar?: Maybe<Scalars['String']>;
  collaborator_limit?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_slug?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_active_at?: Maybe<Scalars['timestamptz']>;
  system_role?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
  workspace_icon?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "users" */
export type Users_Min_Order_By = {
  avatar?: InputMaybe<Order_By>;
  collaborator_limit?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  custom_slug?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_active_at?: InputMaybe<Order_By>;
  system_role?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
  workspace_icon?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** on_conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns?: Array<Users_Update_Column>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  avatar?: InputMaybe<Order_By>;
  collaborator_limit?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  custom_slug?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  external_providers?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_active_at?: InputMaybe<Order_By>;
  system_role?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
  workspace_icon?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Users_Prepend_Input = {
  external_providers?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  Avatar = 'avatar',
  /** column name */
  CollaboratorLimit = 'collaborator_limit',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomSlug = 'custom_slug',
  /** column name */
  Email = 'email',
  /** column name */
  ExternalProviders = 'external_providers',
  /** column name */
  Id = 'id',
  /** column name */
  LastActiveAt = 'last_active_at',
  /** column name */
  SystemRole = 'system_role',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Username = 'username',
  /** column name */
  WorkspaceIcon = 'workspace_icon'
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  avatar?: InputMaybe<Scalars['String']>;
  collaborator_limit?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  custom_slug?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  external_providers?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  last_active_at?: InputMaybe<Scalars['timestamptz']>;
  system_role?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  username?: InputMaybe<Scalars['String']>;
  workspace_icon?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Users_Stddev_Fields = {
  __typename?: 'users_stddev_fields';
  collaborator_limit?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "users" */
export type Users_Stddev_Order_By = {
  collaborator_limit?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Users_Stddev_Pop_Fields = {
  __typename?: 'users_stddev_pop_fields';
  collaborator_limit?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "users" */
export type Users_Stddev_Pop_Order_By = {
  collaborator_limit?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Users_Stddev_Samp_Fields = {
  __typename?: 'users_stddev_samp_fields';
  collaborator_limit?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "users" */
export type Users_Stddev_Samp_Order_By = {
  collaborator_limit?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "users" */
export type Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Stream_Cursor_Value_Input = {
  avatar?: InputMaybe<Scalars['String']>;
  collaborator_limit?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  custom_slug?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  external_providers?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  last_active_at?: InputMaybe<Scalars['timestamptz']>;
  system_role?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  username?: InputMaybe<Scalars['String']>;
  workspace_icon?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Users_Sum_Fields = {
  __typename?: 'users_sum_fields';
  collaborator_limit?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "users" */
export type Users_Sum_Order_By = {
  collaborator_limit?: InputMaybe<Order_By>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  Avatar = 'avatar',
  /** column name */
  CollaboratorLimit = 'collaborator_limit',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomSlug = 'custom_slug',
  /** column name */
  Email = 'email',
  /** column name */
  ExternalProviders = 'external_providers',
  /** column name */
  Id = 'id',
  /** column name */
  LastActiveAt = 'last_active_at',
  /** column name */
  SystemRole = 'system_role',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Username = 'username',
  /** column name */
  WorkspaceIcon = 'workspace_icon'
}

export type Users_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Users_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Users_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Users_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Users_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Users_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Users_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Users_Var_Pop_Fields = {
  __typename?: 'users_var_pop_fields';
  collaborator_limit?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "users" */
export type Users_Var_Pop_Order_By = {
  collaborator_limit?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Users_Var_Samp_Fields = {
  __typename?: 'users_var_samp_fields';
  collaborator_limit?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "users" */
export type Users_Var_Samp_Order_By = {
  collaborator_limit?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Users_Variance_Fields = {
  __typename?: 'users_variance_fields';
  collaborator_limit?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "users" */
export type Users_Variance_Order_By = {
  collaborator_limit?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

/** columns and relationships of "version_contributors" */
export type Version_Contributors = {
  __typename?: 'version_contributors';
  edit_counts?: Maybe<Scalars['Int']>;
  file_id: Scalars['uuid'];
  is_locked?: Maybe<Scalars['Boolean']>;
  lock_timestamp?: Maybe<Scalars['timestamp']>;
  user_id: Scalars['uuid'];
  version_id: Scalars['String'];
};

/** aggregated selection of "version_contributors" */
export type Version_Contributors_Aggregate = {
  __typename?: 'version_contributors_aggregate';
  aggregate?: Maybe<Version_Contributors_Aggregate_Fields>;
  nodes: Array<Version_Contributors>;
};

/** aggregate fields of "version_contributors" */
export type Version_Contributors_Aggregate_Fields = {
  __typename?: 'version_contributors_aggregate_fields';
  avg?: Maybe<Version_Contributors_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Version_Contributors_Max_Fields>;
  min?: Maybe<Version_Contributors_Min_Fields>;
  stddev?: Maybe<Version_Contributors_Stddev_Fields>;
  stddev_pop?: Maybe<Version_Contributors_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Version_Contributors_Stddev_Samp_Fields>;
  sum?: Maybe<Version_Contributors_Sum_Fields>;
  var_pop?: Maybe<Version_Contributors_Var_Pop_Fields>;
  var_samp?: Maybe<Version_Contributors_Var_Samp_Fields>;
  variance?: Maybe<Version_Contributors_Variance_Fields>;
};


/** aggregate fields of "version_contributors" */
export type Version_Contributors_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Version_Contributors_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Version_Contributors_Avg_Fields = {
  __typename?: 'version_contributors_avg_fields';
  edit_counts?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "version_contributors". All fields are combined with a logical 'AND'. */
export type Version_Contributors_Bool_Exp = {
  _and?: InputMaybe<Array<Version_Contributors_Bool_Exp>>;
  _not?: InputMaybe<Version_Contributors_Bool_Exp>;
  _or?: InputMaybe<Array<Version_Contributors_Bool_Exp>>;
  edit_counts?: InputMaybe<Int_Comparison_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
  is_locked?: InputMaybe<Boolean_Comparison_Exp>;
  lock_timestamp?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  version_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "version_contributors" */
export enum Version_Contributors_Constraint {
  /** unique or primary key constraint on columns "version_id", "user_id", "file_id" */
  PkVersionContributors = 'pk_version_contributors'
}

/** input type for incrementing numeric columns in table "version_contributors" */
export type Version_Contributors_Inc_Input = {
  edit_counts?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "version_contributors" */
export type Version_Contributors_Insert_Input = {
  edit_counts?: InputMaybe<Scalars['Int']>;
  file_id?: InputMaybe<Scalars['uuid']>;
  is_locked?: InputMaybe<Scalars['Boolean']>;
  lock_timestamp?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  version_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Version_Contributors_Max_Fields = {
  __typename?: 'version_contributors_max_fields';
  edit_counts?: Maybe<Scalars['Int']>;
  file_id?: Maybe<Scalars['uuid']>;
  lock_timestamp?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['uuid']>;
  version_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Version_Contributors_Min_Fields = {
  __typename?: 'version_contributors_min_fields';
  edit_counts?: Maybe<Scalars['Int']>;
  file_id?: Maybe<Scalars['uuid']>;
  lock_timestamp?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['uuid']>;
  version_id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "version_contributors" */
export type Version_Contributors_Mutation_Response = {
  __typename?: 'version_contributors_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Version_Contributors>;
};

/** on_conflict condition type for table "version_contributors" */
export type Version_Contributors_On_Conflict = {
  constraint: Version_Contributors_Constraint;
  update_columns?: Array<Version_Contributors_Update_Column>;
  where?: InputMaybe<Version_Contributors_Bool_Exp>;
};

/** Ordering options when selecting data from "version_contributors". */
export type Version_Contributors_Order_By = {
  edit_counts?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  is_locked?: InputMaybe<Order_By>;
  lock_timestamp?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: version_contributors */
export type Version_Contributors_Pk_Columns_Input = {
  file_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
  version_id: Scalars['String'];
};

/** select columns of table "version_contributors" */
export enum Version_Contributors_Select_Column {
  /** column name */
  EditCounts = 'edit_counts',
  /** column name */
  FileId = 'file_id',
  /** column name */
  IsLocked = 'is_locked',
  /** column name */
  LockTimestamp = 'lock_timestamp',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VersionId = 'version_id'
}

/** input type for updating data in table "version_contributors" */
export type Version_Contributors_Set_Input = {
  edit_counts?: InputMaybe<Scalars['Int']>;
  file_id?: InputMaybe<Scalars['uuid']>;
  is_locked?: InputMaybe<Scalars['Boolean']>;
  lock_timestamp?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  version_id?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Version_Contributors_Stddev_Fields = {
  __typename?: 'version_contributors_stddev_fields';
  edit_counts?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Version_Contributors_Stddev_Pop_Fields = {
  __typename?: 'version_contributors_stddev_pop_fields';
  edit_counts?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Version_Contributors_Stddev_Samp_Fields = {
  __typename?: 'version_contributors_stddev_samp_fields';
  edit_counts?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "version_contributors" */
export type Version_Contributors_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Version_Contributors_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Version_Contributors_Stream_Cursor_Value_Input = {
  edit_counts?: InputMaybe<Scalars['Int']>;
  file_id?: InputMaybe<Scalars['uuid']>;
  is_locked?: InputMaybe<Scalars['Boolean']>;
  lock_timestamp?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  version_id?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Version_Contributors_Sum_Fields = {
  __typename?: 'version_contributors_sum_fields';
  edit_counts?: Maybe<Scalars['Int']>;
};

/** update columns of table "version_contributors" */
export enum Version_Contributors_Update_Column {
  /** column name */
  EditCounts = 'edit_counts',
  /** column name */
  FileId = 'file_id',
  /** column name */
  IsLocked = 'is_locked',
  /** column name */
  LockTimestamp = 'lock_timestamp',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VersionId = 'version_id'
}

export type Version_Contributors_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Version_Contributors_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Version_Contributors_Set_Input>;
  where: Version_Contributors_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Version_Contributors_Var_Pop_Fields = {
  __typename?: 'version_contributors_var_pop_fields';
  edit_counts?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Version_Contributors_Var_Samp_Fields = {
  __typename?: 'version_contributors_var_samp_fields';
  edit_counts?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Version_Contributors_Variance_Fields = {
  __typename?: 'version_contributors_variance_fields';
  edit_counts?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "versions" */
export type Versions = {
  __typename?: 'versions';
  contributed_users?: Maybe<Array<Users>>;
  contributor_ids?: Maybe<Scalars['_text']>;
  created_at: Scalars['timestamptz'];
  creator_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  download_key: Scalars['uuid'];
  file_id: Scalars['uuid'];
  id: Scalars['uuid'];
  name?: Maybe<Scalars['String']>;
  /** An object relationship */
  parent_file?: Maybe<Files>;
  save_type?: Maybe<Scalars['String']>;
  sha: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "versions" */
export type VersionsContributed_UsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** aggregated selection of "versions" */
export type Versions_Aggregate = {
  __typename?: 'versions_aggregate';
  aggregate?: Maybe<Versions_Aggregate_Fields>;
  nodes: Array<Versions>;
};

/** aggregate fields of "versions" */
export type Versions_Aggregate_Fields = {
  __typename?: 'versions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Versions_Max_Fields>;
  min?: Maybe<Versions_Min_Fields>;
};


/** aggregate fields of "versions" */
export type Versions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Versions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "versions". All fields are combined with a logical 'AND'. */
export type Versions_Bool_Exp = {
  _and?: InputMaybe<Array<Versions_Bool_Exp>>;
  _not?: InputMaybe<Versions_Bool_Exp>;
  _or?: InputMaybe<Array<Versions_Bool_Exp>>;
  contributed_users?: InputMaybe<Users_Bool_Exp>;
  contributor_ids?: InputMaybe<_Text_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  creator_id?: InputMaybe<Uuid_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  download_key?: InputMaybe<Uuid_Comparison_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  parent_file?: InputMaybe<Files_Bool_Exp>;
  save_type?: InputMaybe<String_Comparison_Exp>;
  sha?: InputMaybe<String_Comparison_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "versions" */
export enum Versions_Constraint {
  /** unique or primary key constraint on columns "download_key" */
  VersionsDownloadKeyKey = 'versions_download_key_key',
  /** unique or primary key constraint on columns "id" */
  VersionsPkey = 'versions_pkey'
}

/** input type for inserting data into table "versions" */
export type Versions_Insert_Input = {
  contributor_ids?: InputMaybe<Scalars['_text']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  creator_id?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  download_key?: InputMaybe<Scalars['uuid']>;
  file_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  parent_file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  save_type?: InputMaybe<Scalars['String']>;
  sha?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Versions_Max_Fields = {
  __typename?: 'versions_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  creator_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  download_key?: Maybe<Scalars['uuid']>;
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  save_type?: Maybe<Scalars['String']>;
  sha?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Versions_Min_Fields = {
  __typename?: 'versions_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  creator_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  download_key?: Maybe<Scalars['uuid']>;
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  save_type?: Maybe<Scalars['String']>;
  sha?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "versions" */
export type Versions_Mutation_Response = {
  __typename?: 'versions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Versions>;
};

/** on_conflict condition type for table "versions" */
export type Versions_On_Conflict = {
  constraint: Versions_Constraint;
  update_columns?: Array<Versions_Update_Column>;
  where?: InputMaybe<Versions_Bool_Exp>;
};

/** Ordering options when selecting data from "versions". */
export type Versions_Order_By = {
  contributed_users_aggregate?: InputMaybe<Users_Aggregate_Order_By>;
  contributor_ids?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  creator_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  download_key?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  parent_file?: InputMaybe<Files_Order_By>;
  save_type?: InputMaybe<Order_By>;
  sha?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: versions */
export type Versions_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "versions" */
export enum Versions_Select_Column {
  /** column name */
  ContributorIds = 'contributor_ids',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  Description = 'description',
  /** column name */
  DownloadKey = 'download_key',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  SaveType = 'save_type',
  /** column name */
  Sha = 'sha',
  /** column name */
  Slug = 'slug',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "versions" */
export type Versions_Set_Input = {
  contributor_ids?: InputMaybe<Scalars['_text']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  creator_id?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  download_key?: InputMaybe<Scalars['uuid']>;
  file_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  save_type?: InputMaybe<Scalars['String']>;
  sha?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "versions" */
export type Versions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Versions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Versions_Stream_Cursor_Value_Input = {
  contributor_ids?: InputMaybe<Scalars['_text']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  creator_id?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  download_key?: InputMaybe<Scalars['uuid']>;
  file_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  save_type?: InputMaybe<Scalars['String']>;
  sha?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "versions" */
export enum Versions_Update_Column {
  /** column name */
  ContributorIds = 'contributor_ids',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  Description = 'description',
  /** column name */
  DownloadKey = 'download_key',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  SaveType = 'save_type',
  /** column name */
  Sha = 'sha',
  /** column name */
  Slug = 'slug',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Versions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Versions_Set_Input>;
  where: Versions_Bool_Exp;
};

export type FileFieldsFragment = { __typename?: 'files', id: any, creator_id: any, project_id: any, name?: string | null, status?: string | null, description?: string | null, thumbnail_url?: string | null, created_at: any, updated_at: any, permission?: string | null, draft_permission?: string | null, project?: { __typename?: 'projects', name?: string | null, team?: { __typename?: 'teams', id: any, name?: string | null, custom_slug?: string | null, icon?: string | null, description?: string | null, location?: string | null, owner_id: any } | null } | null };

export type GetNormalFilesQueryVariables = Exact<{
  projectId: Scalars['uuid'];
  orderBy?: InputMaybe<Array<Files_Order_By> | Files_Order_By>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;


export type GetNormalFilesQuery = { __typename?: 'query_root', files: Array<{ __typename?: 'files', id: any, creator_id: any, project_id: any, name?: string | null, status?: string | null, description?: string | null, thumbnail_url?: string | null, created_at: any, updated_at: any, permission?: string | null, draft_permission?: string | null, project?: { __typename?: 'projects', name?: string | null, team?: { __typename?: 'teams', id: any, name?: string | null, custom_slug?: string | null, icon?: string | null, description?: string | null, location?: string | null, owner_id: any } | null } | null }> };

export type GetPersonalArchiveFilesQueryVariables = Exact<{
  creatorId: Scalars['uuid'];
  orderBy?: InputMaybe<Array<Files_Order_By> | Files_Order_By>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;


export type GetPersonalArchiveFilesQuery = { __typename?: 'query_root', files: Array<{ __typename?: 'files', id: any, creator_id: any, project_id: any, name?: string | null, status?: string | null, description?: string | null, thumbnail_url?: string | null, created_at: any, updated_at: any, permission?: string | null, draft_permission?: string | null, project?: { __typename?: 'projects', name?: string | null, team?: { __typename?: 'teams', id: any, name?: string | null, custom_slug?: string | null, icon?: string | null, description?: string | null, location?: string | null, owner_id: any } | null } | null }> };

export type GetPersonalSharedFilesQueryVariables = Exact<{
  collaboratorId: Scalars['uuid'];
  orderBy?: InputMaybe<Array<Files_Order_By> | Files_Order_By>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;


export type GetPersonalSharedFilesQuery = { __typename?: 'query_root', files: Array<{ __typename?: 'files', id: any, creator_id: any, project_id: any, name?: string | null, status?: string | null, description?: string | null, thumbnail_url?: string | null, created_at: any, updated_at: any, permission?: string | null, draft_permission?: string | null, project?: { __typename?: 'projects', name?: string | null, team?: { __typename?: 'teams', id: any, name?: string | null, custom_slug?: string | null, icon?: string | null, description?: string | null, location?: string | null, owner_id: any } | null } | null }> };

export type GetTeamArchiveFilesQueryVariables = Exact<{
  teamId: Scalars['uuid'];
  orderBy?: InputMaybe<Array<Files_Order_By> | Files_Order_By>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;


export type GetTeamArchiveFilesQuery = { __typename?: 'query_root', files: Array<{ __typename?: 'files', id: any, creator_id: any, project_id: any, name?: string | null, status?: string | null, description?: string | null, thumbnail_url?: string | null, created_at: any, updated_at: any, permission?: string | null, draft_permission?: string | null, project?: { __typename?: 'projects', name?: string | null, team?: { __typename?: 'teams', id: any, name?: string | null, custom_slug?: string | null, icon?: string | null, description?: string | null, location?: string | null, owner_id: any } | null } | null }> };

export type GetFilesByCreatorIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetFilesByCreatorIdQuery = { __typename?: 'query_root', files: Array<{ __typename?: 'files', id: any, creator_id: any, project_id: any, name?: string | null, status?: string | null, description?: string | null, thumbnail_url?: string | null, created_at: any, updated_at: any, permission?: string | null, draft_permission?: string | null, project?: { __typename?: 'projects', name?: string | null, team?: { __typename?: 'teams', id: any, name?: string | null, custom_slug?: string | null, icon?: string | null, description?: string | null, location?: string | null, owner_id: any } | null } | null }> };

export type GetFileByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetFileByIdQuery = { __typename?: 'query_root', files_by_pk?: { __typename?: 'files', id: any, creator_id: any, project_id: any, name?: string | null, status?: string | null, description?: string | null, thumbnail_url?: string | null, created_at: any, updated_at: any, permission?: string | null, draft_permission?: string | null, project?: { __typename?: 'projects', name?: string | null, team?: { __typename?: 'teams', id: any, name?: string | null, custom_slug?: string | null, icon?: string | null, description?: string | null, location?: string | null, owner_id: any } | null } | null } | null };

export type FileCollaboratorFieldsFragment = { __typename?: 'file_users_view', avatar?: string | null, email?: string | null, file_role?: string | null, id?: any | null, invitation_id?: string | null, invitation_status?: string | null, permission?: string | null, username?: string | null };

export type OnFileCollaboratorsUpdatedSubscriptionVariables = Exact<{
  fileId: Scalars['String'];
}>;


export type OnFileCollaboratorsUpdatedSubscription = { __typename?: 'subscription_root', file_users_view: Array<{ __typename?: 'file_users_view', avatar?: string | null, email?: string | null, file_role?: string | null, id?: any | null, invitation_id?: string | null, invitation_status?: string | null, permission?: string | null, username?: string | null }> };

export type ProjectFieldsFragment = { __typename?: 'projects', id: any, name?: string | null, status: string, permission: string, creator_id: any };

export type GetProjectIdsWithPermissionQueryVariables = Exact<{
  team_id: Scalars['String'];
}>;


export type GetProjectIdsWithPermissionQuery = { __typename?: 'query_root', team_project_ids_with_permission?: { __typename?: 'ResultOfProjectIds', project_ids?: Array<string | null> | null } | null };

export type GetPersonalProjectsQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetPersonalProjectsQuery = { __typename?: 'query_root', projects: Array<{ __typename?: 'projects', id: any, name?: string | null, status: string, permission: string, creator_id: any }> };

export type GetPersonalNormalProjectsQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetPersonalNormalProjectsQuery = { __typename?: 'query_root', projects: Array<{ __typename?: 'projects', id: any, name?: string | null, status: string, permission: string, creator_id: any }> };

export type GetTeamProjectsQueryVariables = Exact<{
  id_list: Array<Scalars['uuid']> | Scalars['uuid'];
}>;


export type GetTeamProjectsQuery = { __typename?: 'query_root', projects: Array<{ __typename?: 'projects', id: any, name?: string | null, status: string, permission: string, creator_id: any }> };

export type GetTeamNormalProjectsQueryVariables = Exact<{
  id_list: Array<Scalars['uuid']> | Scalars['uuid'];
}>;


export type GetTeamNormalProjectsQuery = { __typename?: 'query_root', projects: Array<{ __typename?: 'projects', id: any, name?: string | null, status: string, permission: string, creator_id: any }> };

export type GetPersonalDraftProjectsQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetPersonalDraftProjectsQuery = { __typename?: 'query_root', projects: Array<{ __typename?: 'projects', id: any, name?: string | null, status: string, permission: string, creator_id: any }> };

export type GetTeamDraftProjectsQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetTeamDraftProjectsQuery = { __typename?: 'query_root', projects: Array<{ __typename?: 'projects', id: any, name?: string | null, status: string, permission: string, creator_id: any }> };

export type GetProjectByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetProjectByIdQuery = { __typename?: 'query_root', projects: Array<{ __typename?: 'projects', id: any, name?: string | null, status: string, permission: string, creator_id: any }> };

export type ProjectCollaboratorFieldsFragment = { __typename?: 'project_members_view', avatar?: string | null, email?: string | null, id?: string | null, invitation_id?: string | null, invitation_status?: string | null, permission?: string | null, project_role?: string | null, username?: string | null };

export type OnProjectCollaboratorsUpdatedSubscriptionVariables = Exact<{
  projectId: Scalars['String'];
}>;


export type OnProjectCollaboratorsUpdatedSubscription = { __typename?: 'subscription_root', project_members_view: Array<{ __typename?: 'project_members_view', avatar?: string | null, email?: string | null, id?: string | null, invitation_id?: string | null, invitation_status?: string | null, permission?: string | null, project_role?: string | null, username?: string | null }> };

export type TeamFieldsFragment = { __typename?: 'teams', id: any, name?: string | null, custom_slug?: string | null, icon?: string | null, description?: string | null, location?: string | null, owner_id: any };

export type OnTeamUpdatedSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type OnTeamUpdatedSubscription = { __typename?: 'subscription_root', teams: Array<{ __typename?: 'teams', id: any, name?: string | null, custom_slug?: string | null, icon?: string | null, description?: string | null, location?: string | null, owner_id: any }> };

export type GetTeamByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetTeamByIdQuery = { __typename?: 'query_root', teams: Array<{ __typename?: 'teams', id: any, name?: string | null, custom_slug?: string | null, icon?: string | null, description?: string | null, location?: string | null, owner_id: any }> };

export type GetTeamBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type GetTeamBySlugQuery = { __typename?: 'query_root', teams: Array<{ __typename?: 'teams', id: any, name?: string | null, custom_slug?: string | null, icon?: string | null, description?: string | null, location?: string | null, owner_id: any }> };

export type GetDeleteTeamPendingRequestByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetDeleteTeamPendingRequestByIdQuery = { __typename?: 'query_root', team_delete_intents: Array<{ __typename?: 'team_delete_intents', id: any }> };

export type GetTransferOwnershipPendingRequestByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetTransferOwnershipPendingRequestByIdQuery = { __typename?: 'query_root', team_ownership_transfer_intents: Array<{ __typename?: 'team_ownership_transfer_intents', id: any }> };

export type TeamUserInvitationFieldsFragment = { __typename?: 'team_user_invitations', id: any, team_role: string, status: string, email?: string | null };

export type OnTeamUserInvitationUpdatedSubscriptionVariables = Exact<{
  slug: Scalars['String'];
}>;


export type OnTeamUserInvitationUpdatedSubscription = { __typename?: 'subscription_root', team_user_invitations: Array<{ __typename?: 'team_user_invitations', id: any, team_role: string, status: string, email?: string | null }> };

export type TeamUserFieldsFragment = { __typename?: 'team_users', team_id: any, user_id: any, team_role: string, user?: { __typename?: 'users', username?: string | null, email?: string | null, avatar?: string | null, last_active_at?: any | null } | null };

export type GetTeamUsersQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type GetTeamUsersQuery = { __typename?: 'query_root', team_users: Array<{ __typename?: 'team_users', team_id: any, user_id: any, team_role: string, user?: { __typename?: 'users', username?: string | null, email?: string | null, avatar?: string | null, last_active_at?: any | null } | null }> };

export type OnTeamUserUpdatedSubscriptionVariables = Exact<{
  slug: Scalars['String'];
}>;


export type OnTeamUserUpdatedSubscription = { __typename?: 'subscription_root', team_users: Array<{ __typename?: 'team_users', team_id: any, user_id: any, team_role: string, user?: { __typename?: 'users', username?: string | null, email?: string | null, avatar?: string | null, last_active_at?: any | null } | null }> };

export type GetTeamMemberByUserIdQueryVariables = Exact<{
  teamId: Scalars['uuid'];
  id: Scalars['uuid'];
}>;


export type GetTeamMemberByUserIdQuery = { __typename?: 'query_root', team_users: Array<{ __typename?: 'team_users', team_id: any, user_id: any, team_role: string, user?: { __typename?: 'users', username?: string | null, email?: string | null, avatar?: string | null, last_active_at?: any | null } | null }> };

export type TemplateFieldsFragment = { __typename?: 'files', id: any, project_id: any, name?: string | null, thumbnail_url?: string | null, created_at: any, updated_at: any, author?: string | null, author_source?: string | null, feature?: number | null };

export type GetTemplatesQueryVariables = Exact<{
  projectId: Scalars['uuid'];
  orderBy?: InputMaybe<Array<Files_Order_By> | Files_Order_By>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;


export type GetTemplatesQuery = { __typename?: 'query_root', files: Array<{ __typename?: 'files', id: any, project_id: any, name?: string | null, thumbnail_url?: string | null, created_at: any, updated_at: any, author?: string | null, author_source?: string | null, feature?: number | null }> };

export type UserFieldsFragment = { __typename?: 'users', id: any, username?: string | null, avatar?: string | null, email?: string | null, custom_slug?: string | null };

export type OnUserUpdatedSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type OnUserUpdatedSubscription = { __typename?: 'subscription_root', users: Array<{ __typename?: 'users', id: any, username?: string | null, avatar?: string | null, email?: string | null, custom_slug?: string | null }> };

export type TutorialStatusQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type TutorialStatusQuery = { __typename?: 'query_root', user_preferences_by_pk?: { __typename?: 'user_preferences', value: any } | null };

export type PresencePreferenceQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type PresencePreferenceQuery = { __typename?: 'query_root', user_preferences_by_pk?: { __typename?: 'user_preferences', value: any } | null };

export type OnPreferenceUpdatedSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
  key: Scalars['String'];
}>;


export type OnPreferenceUpdatedSubscription = { __typename?: 'subscription_root', user_preferences: Array<{ __typename?: 'user_preferences', value: any }> };

export type GetUsersByIdsQueryVariables = Exact<{
  ids: Array<Scalars['uuid']> | Scalars['uuid'];
}>;


export type GetUsersByIdsQuery = { __typename?: 'query_root', users: Array<{ __typename?: 'users', id: any, username?: string | null, avatar?: string | null, email?: string | null, custom_slug?: string | null }> };

export type CommentVisibilityPreferenceQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type CommentVisibilityPreferenceQuery = { __typename?: 'query_root', user_preferences_by_pk?: { __typename?: 'user_preferences', value: any } | null };

export type TemplateSectionVisibilityPreferenceQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type TemplateSectionVisibilityPreferenceQuery = { __typename?: 'query_root', user_preferences_by_pk?: { __typename?: 'user_preferences', value: any } | null };

export type NewsletterSubscriptionPreferenceQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type NewsletterSubscriptionPreferenceQuery = { __typename?: 'query_root', user_preferences_by_pk?: { __typename?: 'user_preferences', value: any } | null };

export type PreferredLanguageQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type PreferredLanguageQuery = { __typename?: 'query_root', user_preferences_by_pk?: { __typename?: 'user_preferences', value: any } | null };

export type VersionFieldsFragment = { __typename?: 'versions', created_at: any, creator_id?: any | null, description?: string | null, id: any, name?: string | null, save_type?: string | null, contributed_users?: Array<{ __typename?: 'users', username?: string | null, avatar?: string | null }> | null, parent_file?: { __typename?: 'files', name?: string | null } | null };

export type GetVersionsQueryVariables = Exact<{
  fileId: Scalars['uuid'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  excludeUnnamed?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetVersionsQuery = { __typename?: 'query_root', versions: Array<{ __typename?: 'versions', created_at: any, creator_id?: any | null, description?: string | null, id: any, name?: string | null, save_type?: string | null, contributed_users?: Array<{ __typename?: 'users', username?: string | null, avatar?: string | null }> | null, parent_file?: { __typename?: 'files', name?: string | null } | null }> };

export type GetVersionByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetVersionByIdQuery = { __typename?: 'query_root', versions_by_pk?: { __typename?: 'versions', created_at: any, creator_id?: any | null, description?: string | null, id: any, name?: string | null, save_type?: string | null, contributed_users?: Array<{ __typename?: 'users', username?: string | null, avatar?: string | null }> | null, parent_file?: { __typename?: 'files', name?: string | null } | null } | null };

export const TeamFieldsFragmentDoc = gql`
    fragment teamFields on teams {
  id
  name
  custom_slug
  icon
  description
  location
  owner_id
}
    `;
export const FileFieldsFragmentDoc = gql`
    fragment fileFields on files {
  id
  creator_id
  project_id
  name
  status
  description
  thumbnail_url
  created_at
  updated_at
  permission
  draft_permission
  project {
    name
    team {
      ...teamFields
    }
  }
}
    ${TeamFieldsFragmentDoc}`;
export const FileCollaboratorFieldsFragmentDoc = gql`
    fragment fileCollaboratorFields on file_users_view {
  avatar
  email
  file_role
  id
  invitation_id
  invitation_status
  permission
  username
}
    `;
export const ProjectFieldsFragmentDoc = gql`
    fragment projectFields on projects {
  id
  name
  status
  permission
  creator_id
}
    `;
export const ProjectCollaboratorFieldsFragmentDoc = gql`
    fragment projectCollaboratorFields on project_members_view {
  avatar
  email
  id
  invitation_id
  invitation_status
  permission
  project_role
  username
}
    `;
export const TeamUserInvitationFieldsFragmentDoc = gql`
    fragment teamUserInvitationFields on team_user_invitations {
  id
  team_role
  status
  email
}
    `;
export const TeamUserFieldsFragmentDoc = gql`
    fragment teamUserFields on team_users {
  team_id
  user_id
  team_role
  user {
    username
    email
    avatar
    last_active_at
  }
}
    `;
export const TemplateFieldsFragmentDoc = gql`
    fragment templateFields on files {
  id
  project_id
  name
  thumbnail_url
  created_at
  updated_at
  author
  author_source
  feature
}
    `;
export const UserFieldsFragmentDoc = gql`
    fragment userFields on users {
  id
  username
  avatar
  email
  custom_slug
}
    `;
export const VersionFieldsFragmentDoc = gql`
    fragment versionFields on versions {
  contributed_users {
    username
    avatar
  }
  created_at
  creator_id
  description
  id
  name
  save_type
  parent_file {
    name
  }
}
    `;
export const GetNormalFilesDocument = gql`
    query getNormalFiles($projectId: uuid!, $orderBy: [files_order_by!], $limit: Int, $offset: Int) {
  files(
    where: {status: {_eq: "NORMAL"}, project_id: {_eq: $projectId}}
    order_by: $orderBy
    limit: $limit
    offset: $offset
  ) @connection(key: "projectFiles") {
    ...fileFields
  }
}
    ${FileFieldsFragmentDoc}`;

/**
 * __useGetNormalFilesQuery__
 *
 * To run a query within a React component, call `useGetNormalFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNormalFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNormalFilesQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetNormalFilesQuery(baseOptions: Apollo.QueryHookOptions<GetNormalFilesQuery, GetNormalFilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNormalFilesQuery, GetNormalFilesQueryVariables>(GetNormalFilesDocument, options);
      }
export function useGetNormalFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNormalFilesQuery, GetNormalFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNormalFilesQuery, GetNormalFilesQueryVariables>(GetNormalFilesDocument, options);
        }
export type GetNormalFilesQueryHookResult = ReturnType<typeof useGetNormalFilesQuery>;
export type GetNormalFilesLazyQueryHookResult = ReturnType<typeof useGetNormalFilesLazyQuery>;
export type GetNormalFilesQueryResult = Apollo.QueryResult<GetNormalFilesQuery, GetNormalFilesQueryVariables>;
export const GetPersonalArchiveFilesDocument = gql`
    query getPersonalArchiveFiles($creatorId: uuid!, $orderBy: [files_order_by!], $limit: Int, $offset: Int) {
  files(
    where: {status: {_eq: "ARCHIVE"}, project: {team_id: {_is_null: true}}, creator_id: {_eq: $creatorId}}
    order_by: $orderBy
    limit: $limit
    offset: $offset
  ) @connection(key: "archivedFiles") {
    ...fileFields
  }
}
    ${FileFieldsFragmentDoc}`;

/**
 * __useGetPersonalArchiveFilesQuery__
 *
 * To run a query within a React component, call `useGetPersonalArchiveFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonalArchiveFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonalArchiveFilesQuery({
 *   variables: {
 *      creatorId: // value for 'creatorId'
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetPersonalArchiveFilesQuery(baseOptions: Apollo.QueryHookOptions<GetPersonalArchiveFilesQuery, GetPersonalArchiveFilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPersonalArchiveFilesQuery, GetPersonalArchiveFilesQueryVariables>(GetPersonalArchiveFilesDocument, options);
      }
export function useGetPersonalArchiveFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPersonalArchiveFilesQuery, GetPersonalArchiveFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPersonalArchiveFilesQuery, GetPersonalArchiveFilesQueryVariables>(GetPersonalArchiveFilesDocument, options);
        }
export type GetPersonalArchiveFilesQueryHookResult = ReturnType<typeof useGetPersonalArchiveFilesQuery>;
export type GetPersonalArchiveFilesLazyQueryHookResult = ReturnType<typeof useGetPersonalArchiveFilesLazyQuery>;
export type GetPersonalArchiveFilesQueryResult = Apollo.QueryResult<GetPersonalArchiveFilesQuery, GetPersonalArchiveFilesQueryVariables>;
export const GetPersonalSharedFilesDocument = gql`
    query getPersonalSharedFiles($collaboratorId: uuid!, $orderBy: [files_order_by!], $limit: Int, $offset: Int) {
  files(
    where: {project: {team_id: {_is_null: true}}, collaborators: {user_id: {_eq: $collaboratorId}}, _not: {creator_id: {_eq: $collaboratorId}}}
    order_by: $orderBy
    limit: $limit
    offset: $offset
  ) @connection(key: "sharedFiles") {
    ...fileFields
  }
}
    ${FileFieldsFragmentDoc}`;

/**
 * __useGetPersonalSharedFilesQuery__
 *
 * To run a query within a React component, call `useGetPersonalSharedFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonalSharedFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonalSharedFilesQuery({
 *   variables: {
 *      collaboratorId: // value for 'collaboratorId'
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetPersonalSharedFilesQuery(baseOptions: Apollo.QueryHookOptions<GetPersonalSharedFilesQuery, GetPersonalSharedFilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPersonalSharedFilesQuery, GetPersonalSharedFilesQueryVariables>(GetPersonalSharedFilesDocument, options);
      }
export function useGetPersonalSharedFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPersonalSharedFilesQuery, GetPersonalSharedFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPersonalSharedFilesQuery, GetPersonalSharedFilesQueryVariables>(GetPersonalSharedFilesDocument, options);
        }
export type GetPersonalSharedFilesQueryHookResult = ReturnType<typeof useGetPersonalSharedFilesQuery>;
export type GetPersonalSharedFilesLazyQueryHookResult = ReturnType<typeof useGetPersonalSharedFilesLazyQuery>;
export type GetPersonalSharedFilesQueryResult = Apollo.QueryResult<GetPersonalSharedFilesQuery, GetPersonalSharedFilesQueryVariables>;
export const GetTeamArchiveFilesDocument = gql`
    query getTeamArchiveFiles($teamId: uuid!, $orderBy: [files_order_by!], $limit: Int, $offset: Int) {
  files(
    where: {status: {_eq: "ARCHIVE"}, project: {team_id: {_eq: $teamId}}}
    order_by: $orderBy
    limit: $limit
    offset: $offset
  ) @connection(key: "sharedFiles") {
    ...fileFields
  }
}
    ${FileFieldsFragmentDoc}`;

/**
 * __useGetTeamArchiveFilesQuery__
 *
 * To run a query within a React component, call `useGetTeamArchiveFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamArchiveFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamArchiveFilesQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetTeamArchiveFilesQuery(baseOptions: Apollo.QueryHookOptions<GetTeamArchiveFilesQuery, GetTeamArchiveFilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeamArchiveFilesQuery, GetTeamArchiveFilesQueryVariables>(GetTeamArchiveFilesDocument, options);
      }
export function useGetTeamArchiveFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamArchiveFilesQuery, GetTeamArchiveFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeamArchiveFilesQuery, GetTeamArchiveFilesQueryVariables>(GetTeamArchiveFilesDocument, options);
        }
export type GetTeamArchiveFilesQueryHookResult = ReturnType<typeof useGetTeamArchiveFilesQuery>;
export type GetTeamArchiveFilesLazyQueryHookResult = ReturnType<typeof useGetTeamArchiveFilesLazyQuery>;
export type GetTeamArchiveFilesQueryResult = Apollo.QueryResult<GetTeamArchiveFilesQuery, GetTeamArchiveFilesQueryVariables>;
export const GetFilesByCreatorIdDocument = gql`
    query getFilesByCreatorId($id: uuid!) {
  files(where: {creator_id: {_eq: $id}}) {
    ...fileFields
  }
}
    ${FileFieldsFragmentDoc}`;

/**
 * __useGetFilesByCreatorIdQuery__
 *
 * To run a query within a React component, call `useGetFilesByCreatorIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilesByCreatorIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilesByCreatorIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFilesByCreatorIdQuery(baseOptions: Apollo.QueryHookOptions<GetFilesByCreatorIdQuery, GetFilesByCreatorIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFilesByCreatorIdQuery, GetFilesByCreatorIdQueryVariables>(GetFilesByCreatorIdDocument, options);
      }
export function useGetFilesByCreatorIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFilesByCreatorIdQuery, GetFilesByCreatorIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFilesByCreatorIdQuery, GetFilesByCreatorIdQueryVariables>(GetFilesByCreatorIdDocument, options);
        }
export type GetFilesByCreatorIdQueryHookResult = ReturnType<typeof useGetFilesByCreatorIdQuery>;
export type GetFilesByCreatorIdLazyQueryHookResult = ReturnType<typeof useGetFilesByCreatorIdLazyQuery>;
export type GetFilesByCreatorIdQueryResult = Apollo.QueryResult<GetFilesByCreatorIdQuery, GetFilesByCreatorIdQueryVariables>;
export const GetFileByIdDocument = gql`
    query getFileById($id: uuid!) {
  files_by_pk(id: $id) {
    ...fileFields
  }
}
    ${FileFieldsFragmentDoc}`;

/**
 * __useGetFileByIdQuery__
 *
 * To run a query within a React component, call `useGetFileByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFileByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFileByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFileByIdQuery(baseOptions: Apollo.QueryHookOptions<GetFileByIdQuery, GetFileByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFileByIdQuery, GetFileByIdQueryVariables>(GetFileByIdDocument, options);
      }
export function useGetFileByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFileByIdQuery, GetFileByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFileByIdQuery, GetFileByIdQueryVariables>(GetFileByIdDocument, options);
        }
export type GetFileByIdQueryHookResult = ReturnType<typeof useGetFileByIdQuery>;
export type GetFileByIdLazyQueryHookResult = ReturnType<typeof useGetFileByIdLazyQuery>;
export type GetFileByIdQueryResult = Apollo.QueryResult<GetFileByIdQuery, GetFileByIdQueryVariables>;
export const OnFileCollaboratorsUpdatedDocument = gql`
    subscription onFileCollaboratorsUpdated($fileId: String!) {
  file_users_view(
    where: {_and: [{file_id: {_eq: $fileId}}, {_or: [{file_role: {_eq: "creator"}}, {invitation_status: {_in: ["ACCEPTED", "PENDING"]}}]}]}
  ) {
    ...fileCollaboratorFields
  }
}
    ${FileCollaboratorFieldsFragmentDoc}`;

/**
 * __useOnFileCollaboratorsUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnFileCollaboratorsUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnFileCollaboratorsUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnFileCollaboratorsUpdatedSubscription({
 *   variables: {
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useOnFileCollaboratorsUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnFileCollaboratorsUpdatedSubscription, OnFileCollaboratorsUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnFileCollaboratorsUpdatedSubscription, OnFileCollaboratorsUpdatedSubscriptionVariables>(OnFileCollaboratorsUpdatedDocument, options);
      }
export type OnFileCollaboratorsUpdatedSubscriptionHookResult = ReturnType<typeof useOnFileCollaboratorsUpdatedSubscription>;
export type OnFileCollaboratorsUpdatedSubscriptionResult = Apollo.SubscriptionResult<OnFileCollaboratorsUpdatedSubscription>;
export const GetProjectIdsWithPermissionDocument = gql`
    query getProjectIdsWithPermission($team_id: String!) {
  team_project_ids_with_permission(team_id: $team_id) {
    project_ids
  }
}
    `;

/**
 * __useGetProjectIdsWithPermissionQuery__
 *
 * To run a query within a React component, call `useGetProjectIdsWithPermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectIdsWithPermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectIdsWithPermissionQuery({
 *   variables: {
 *      team_id: // value for 'team_id'
 *   },
 * });
 */
export function useGetProjectIdsWithPermissionQuery(baseOptions: Apollo.QueryHookOptions<GetProjectIdsWithPermissionQuery, GetProjectIdsWithPermissionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectIdsWithPermissionQuery, GetProjectIdsWithPermissionQueryVariables>(GetProjectIdsWithPermissionDocument, options);
      }
export function useGetProjectIdsWithPermissionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectIdsWithPermissionQuery, GetProjectIdsWithPermissionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectIdsWithPermissionQuery, GetProjectIdsWithPermissionQueryVariables>(GetProjectIdsWithPermissionDocument, options);
        }
export type GetProjectIdsWithPermissionQueryHookResult = ReturnType<typeof useGetProjectIdsWithPermissionQuery>;
export type GetProjectIdsWithPermissionLazyQueryHookResult = ReturnType<typeof useGetProjectIdsWithPermissionLazyQuery>;
export type GetProjectIdsWithPermissionQueryResult = Apollo.QueryResult<GetProjectIdsWithPermissionQuery, GetProjectIdsWithPermissionQueryVariables>;
export const GetPersonalProjectsDocument = gql`
    query getPersonalProjects($id: uuid!) {
  projects(
    where: {creator_id: {_eq: $id}, source_type: {_eq: "PERSONAL"}}
    order_by: {created_at: asc}
  ) {
    ...projectFields
  }
}
    ${ProjectFieldsFragmentDoc}`;

/**
 * __useGetPersonalProjectsQuery__
 *
 * To run a query within a React component, call `useGetPersonalProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonalProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonalProjectsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPersonalProjectsQuery(baseOptions: Apollo.QueryHookOptions<GetPersonalProjectsQuery, GetPersonalProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPersonalProjectsQuery, GetPersonalProjectsQueryVariables>(GetPersonalProjectsDocument, options);
      }
export function useGetPersonalProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPersonalProjectsQuery, GetPersonalProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPersonalProjectsQuery, GetPersonalProjectsQueryVariables>(GetPersonalProjectsDocument, options);
        }
export type GetPersonalProjectsQueryHookResult = ReturnType<typeof useGetPersonalProjectsQuery>;
export type GetPersonalProjectsLazyQueryHookResult = ReturnType<typeof useGetPersonalProjectsLazyQuery>;
export type GetPersonalProjectsQueryResult = Apollo.QueryResult<GetPersonalProjectsQuery, GetPersonalProjectsQueryVariables>;
export const GetPersonalNormalProjectsDocument = gql`
    query getPersonalNormalProjects($id: uuid!) {
  projects(
    where: {creator_id: {_eq: $id}, status: {_eq: "NORMAL"}, source_type: {_eq: "PERSONAL"}}
    order_by: {created_at: asc}
  ) {
    ...projectFields
  }
}
    ${ProjectFieldsFragmentDoc}`;

/**
 * __useGetPersonalNormalProjectsQuery__
 *
 * To run a query within a React component, call `useGetPersonalNormalProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonalNormalProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonalNormalProjectsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPersonalNormalProjectsQuery(baseOptions: Apollo.QueryHookOptions<GetPersonalNormalProjectsQuery, GetPersonalNormalProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPersonalNormalProjectsQuery, GetPersonalNormalProjectsQueryVariables>(GetPersonalNormalProjectsDocument, options);
      }
export function useGetPersonalNormalProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPersonalNormalProjectsQuery, GetPersonalNormalProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPersonalNormalProjectsQuery, GetPersonalNormalProjectsQueryVariables>(GetPersonalNormalProjectsDocument, options);
        }
export type GetPersonalNormalProjectsQueryHookResult = ReturnType<typeof useGetPersonalNormalProjectsQuery>;
export type GetPersonalNormalProjectsLazyQueryHookResult = ReturnType<typeof useGetPersonalNormalProjectsLazyQuery>;
export type GetPersonalNormalProjectsQueryResult = Apollo.QueryResult<GetPersonalNormalProjectsQuery, GetPersonalNormalProjectsQueryVariables>;
export const GetTeamProjectsDocument = gql`
    query getTeamProjects($id_list: [uuid!]!) {
  projects(
    where: {id: {_in: $id_list}, source_type: {_eq: "TEAM"}}
    order_by: {created_at: asc}
  ) {
    ...projectFields
  }
}
    ${ProjectFieldsFragmentDoc}`;

/**
 * __useGetTeamProjectsQuery__
 *
 * To run a query within a React component, call `useGetTeamProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamProjectsQuery({
 *   variables: {
 *      id_list: // value for 'id_list'
 *   },
 * });
 */
export function useGetTeamProjectsQuery(baseOptions: Apollo.QueryHookOptions<GetTeamProjectsQuery, GetTeamProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeamProjectsQuery, GetTeamProjectsQueryVariables>(GetTeamProjectsDocument, options);
      }
export function useGetTeamProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamProjectsQuery, GetTeamProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeamProjectsQuery, GetTeamProjectsQueryVariables>(GetTeamProjectsDocument, options);
        }
export type GetTeamProjectsQueryHookResult = ReturnType<typeof useGetTeamProjectsQuery>;
export type GetTeamProjectsLazyQueryHookResult = ReturnType<typeof useGetTeamProjectsLazyQuery>;
export type GetTeamProjectsQueryResult = Apollo.QueryResult<GetTeamProjectsQuery, GetTeamProjectsQueryVariables>;
export const GetTeamNormalProjectsDocument = gql`
    query getTeamNormalProjects($id_list: [uuid!]!) {
  projects(
    where: {id: {_in: $id_list}, status: {_eq: "NORMAL"}, source_type: {_eq: "TEAM"}}
    order_by: {created_at: asc}
  ) {
    ...projectFields
  }
}
    ${ProjectFieldsFragmentDoc}`;

/**
 * __useGetTeamNormalProjectsQuery__
 *
 * To run a query within a React component, call `useGetTeamNormalProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamNormalProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamNormalProjectsQuery({
 *   variables: {
 *      id_list: // value for 'id_list'
 *   },
 * });
 */
export function useGetTeamNormalProjectsQuery(baseOptions: Apollo.QueryHookOptions<GetTeamNormalProjectsQuery, GetTeamNormalProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeamNormalProjectsQuery, GetTeamNormalProjectsQueryVariables>(GetTeamNormalProjectsDocument, options);
      }
export function useGetTeamNormalProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamNormalProjectsQuery, GetTeamNormalProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeamNormalProjectsQuery, GetTeamNormalProjectsQueryVariables>(GetTeamNormalProjectsDocument, options);
        }
export type GetTeamNormalProjectsQueryHookResult = ReturnType<typeof useGetTeamNormalProjectsQuery>;
export type GetTeamNormalProjectsLazyQueryHookResult = ReturnType<typeof useGetTeamNormalProjectsLazyQuery>;
export type GetTeamNormalProjectsQueryResult = Apollo.QueryResult<GetTeamNormalProjectsQuery, GetTeamNormalProjectsQueryVariables>;
export const GetPersonalDraftProjectsDocument = gql`
    query getPersonalDraftProjects($id: uuid!) {
  projects(
    where: {creator_id: {_eq: $id}, status: {_eq: "DRAFT"}, source_type: {_eq: "PERSONAL"}}
  ) {
    ...projectFields
  }
}
    ${ProjectFieldsFragmentDoc}`;

/**
 * __useGetPersonalDraftProjectsQuery__
 *
 * To run a query within a React component, call `useGetPersonalDraftProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonalDraftProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonalDraftProjectsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPersonalDraftProjectsQuery(baseOptions: Apollo.QueryHookOptions<GetPersonalDraftProjectsQuery, GetPersonalDraftProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPersonalDraftProjectsQuery, GetPersonalDraftProjectsQueryVariables>(GetPersonalDraftProjectsDocument, options);
      }
export function useGetPersonalDraftProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPersonalDraftProjectsQuery, GetPersonalDraftProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPersonalDraftProjectsQuery, GetPersonalDraftProjectsQueryVariables>(GetPersonalDraftProjectsDocument, options);
        }
export type GetPersonalDraftProjectsQueryHookResult = ReturnType<typeof useGetPersonalDraftProjectsQuery>;
export type GetPersonalDraftProjectsLazyQueryHookResult = ReturnType<typeof useGetPersonalDraftProjectsLazyQuery>;
export type GetPersonalDraftProjectsQueryResult = Apollo.QueryResult<GetPersonalDraftProjectsQuery, GetPersonalDraftProjectsQueryVariables>;
export const GetTeamDraftProjectsDocument = gql`
    query getTeamDraftProjects($id: uuid!) {
  projects(
    where: {team_id: {_eq: $id}, status: {_eq: "DRAFT"}, source_type: {_eq: "TEAM"}}
  ) {
    ...projectFields
  }
}
    ${ProjectFieldsFragmentDoc}`;

/**
 * __useGetTeamDraftProjectsQuery__
 *
 * To run a query within a React component, call `useGetTeamDraftProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamDraftProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamDraftProjectsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTeamDraftProjectsQuery(baseOptions: Apollo.QueryHookOptions<GetTeamDraftProjectsQuery, GetTeamDraftProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeamDraftProjectsQuery, GetTeamDraftProjectsQueryVariables>(GetTeamDraftProjectsDocument, options);
      }
export function useGetTeamDraftProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamDraftProjectsQuery, GetTeamDraftProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeamDraftProjectsQuery, GetTeamDraftProjectsQueryVariables>(GetTeamDraftProjectsDocument, options);
        }
export type GetTeamDraftProjectsQueryHookResult = ReturnType<typeof useGetTeamDraftProjectsQuery>;
export type GetTeamDraftProjectsLazyQueryHookResult = ReturnType<typeof useGetTeamDraftProjectsLazyQuery>;
export type GetTeamDraftProjectsQueryResult = Apollo.QueryResult<GetTeamDraftProjectsQuery, GetTeamDraftProjectsQueryVariables>;
export const GetProjectByIdDocument = gql`
    query getProjectById($id: uuid!) {
  projects(where: {id: {_eq: $id}}) {
    ...projectFields
  }
}
    ${ProjectFieldsFragmentDoc}`;

/**
 * __useGetProjectByIdQuery__
 *
 * To run a query within a React component, call `useGetProjectByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectByIdQuery(baseOptions: Apollo.QueryHookOptions<GetProjectByIdQuery, GetProjectByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectByIdQuery, GetProjectByIdQueryVariables>(GetProjectByIdDocument, options);
      }
export function useGetProjectByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectByIdQuery, GetProjectByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectByIdQuery, GetProjectByIdQueryVariables>(GetProjectByIdDocument, options);
        }
export type GetProjectByIdQueryHookResult = ReturnType<typeof useGetProjectByIdQuery>;
export type GetProjectByIdLazyQueryHookResult = ReturnType<typeof useGetProjectByIdLazyQuery>;
export type GetProjectByIdQueryResult = Apollo.QueryResult<GetProjectByIdQuery, GetProjectByIdQueryVariables>;
export const OnProjectCollaboratorsUpdatedDocument = gql`
    subscription onProjectCollaboratorsUpdated($projectId: String!) {
  project_members_view(
    where: {_and: [{project_id: {_eq: $projectId}}, {_or: [{project_role: {_in: "creator"}}, {invitation_status: {_in: ["ACCEPTED", "PENDING", "ADDED_FROM_TEAM"]}}]}]}
  ) {
    ...projectCollaboratorFields
  }
}
    ${ProjectCollaboratorFieldsFragmentDoc}`;

/**
 * __useOnProjectCollaboratorsUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnProjectCollaboratorsUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnProjectCollaboratorsUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnProjectCollaboratorsUpdatedSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useOnProjectCollaboratorsUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnProjectCollaboratorsUpdatedSubscription, OnProjectCollaboratorsUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnProjectCollaboratorsUpdatedSubscription, OnProjectCollaboratorsUpdatedSubscriptionVariables>(OnProjectCollaboratorsUpdatedDocument, options);
      }
export type OnProjectCollaboratorsUpdatedSubscriptionHookResult = ReturnType<typeof useOnProjectCollaboratorsUpdatedSubscription>;
export type OnProjectCollaboratorsUpdatedSubscriptionResult = Apollo.SubscriptionResult<OnProjectCollaboratorsUpdatedSubscription>;
export const OnTeamUpdatedDocument = gql`
    subscription onTeamUpdated($id: uuid!) {
  teams(where: {team_users: {user_id: {_eq: $id}}}, order_by: {created_at: asc}) {
    ...teamFields
  }
}
    ${TeamFieldsFragmentDoc}`;

/**
 * __useOnTeamUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnTeamUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnTeamUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnTeamUpdatedSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOnTeamUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnTeamUpdatedSubscription, OnTeamUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnTeamUpdatedSubscription, OnTeamUpdatedSubscriptionVariables>(OnTeamUpdatedDocument, options);
      }
export type OnTeamUpdatedSubscriptionHookResult = ReturnType<typeof useOnTeamUpdatedSubscription>;
export type OnTeamUpdatedSubscriptionResult = Apollo.SubscriptionResult<OnTeamUpdatedSubscription>;
export const GetTeamByIdDocument = gql`
    query getTeamById($id: uuid!) {
  teams(where: {id: {_eq: $id}}) {
    ...teamFields
  }
}
    ${TeamFieldsFragmentDoc}`;

/**
 * __useGetTeamByIdQuery__
 *
 * To run a query within a React component, call `useGetTeamByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTeamByIdQuery(baseOptions: Apollo.QueryHookOptions<GetTeamByIdQuery, GetTeamByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeamByIdQuery, GetTeamByIdQueryVariables>(GetTeamByIdDocument, options);
      }
export function useGetTeamByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamByIdQuery, GetTeamByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeamByIdQuery, GetTeamByIdQueryVariables>(GetTeamByIdDocument, options);
        }
export type GetTeamByIdQueryHookResult = ReturnType<typeof useGetTeamByIdQuery>;
export type GetTeamByIdLazyQueryHookResult = ReturnType<typeof useGetTeamByIdLazyQuery>;
export type GetTeamByIdQueryResult = Apollo.QueryResult<GetTeamByIdQuery, GetTeamByIdQueryVariables>;
export const GetTeamBySlugDocument = gql`
    query getTeamBySlug($slug: String!) {
  teams(where: {custom_slug: {_eq: $slug}}) {
    ...teamFields
  }
}
    ${TeamFieldsFragmentDoc}`;

/**
 * __useGetTeamBySlugQuery__
 *
 * To run a query within a React component, call `useGetTeamBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetTeamBySlugQuery(baseOptions: Apollo.QueryHookOptions<GetTeamBySlugQuery, GetTeamBySlugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeamBySlugQuery, GetTeamBySlugQueryVariables>(GetTeamBySlugDocument, options);
      }
export function useGetTeamBySlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamBySlugQuery, GetTeamBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeamBySlugQuery, GetTeamBySlugQueryVariables>(GetTeamBySlugDocument, options);
        }
export type GetTeamBySlugQueryHookResult = ReturnType<typeof useGetTeamBySlugQuery>;
export type GetTeamBySlugLazyQueryHookResult = ReturnType<typeof useGetTeamBySlugLazyQuery>;
export type GetTeamBySlugQueryResult = Apollo.QueryResult<GetTeamBySlugQuery, GetTeamBySlugQueryVariables>;
export const GetDeleteTeamPendingRequestByIdDocument = gql`
    query getDeleteTeamPendingRequestById($id: uuid!) {
  team_delete_intents(where: {team_id: {_eq: $id}, status: {_eq: "PENDING"}}) {
    id
  }
}
    `;

/**
 * __useGetDeleteTeamPendingRequestByIdQuery__
 *
 * To run a query within a React component, call `useGetDeleteTeamPendingRequestByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeleteTeamPendingRequestByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeleteTeamPendingRequestByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDeleteTeamPendingRequestByIdQuery(baseOptions: Apollo.QueryHookOptions<GetDeleteTeamPendingRequestByIdQuery, GetDeleteTeamPendingRequestByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeleteTeamPendingRequestByIdQuery, GetDeleteTeamPendingRequestByIdQueryVariables>(GetDeleteTeamPendingRequestByIdDocument, options);
      }
export function useGetDeleteTeamPendingRequestByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeleteTeamPendingRequestByIdQuery, GetDeleteTeamPendingRequestByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeleteTeamPendingRequestByIdQuery, GetDeleteTeamPendingRequestByIdQueryVariables>(GetDeleteTeamPendingRequestByIdDocument, options);
        }
export type GetDeleteTeamPendingRequestByIdQueryHookResult = ReturnType<typeof useGetDeleteTeamPendingRequestByIdQuery>;
export type GetDeleteTeamPendingRequestByIdLazyQueryHookResult = ReturnType<typeof useGetDeleteTeamPendingRequestByIdLazyQuery>;
export type GetDeleteTeamPendingRequestByIdQueryResult = Apollo.QueryResult<GetDeleteTeamPendingRequestByIdQuery, GetDeleteTeamPendingRequestByIdQueryVariables>;
export const GetTransferOwnershipPendingRequestByIdDocument = gql`
    query getTransferOwnershipPendingRequestById($id: uuid!) {
  team_ownership_transfer_intents(
    where: {team_id: {_eq: $id}, status: {_eq: "PENDING"}}
  ) {
    id
  }
}
    `;

/**
 * __useGetTransferOwnershipPendingRequestByIdQuery__
 *
 * To run a query within a React component, call `useGetTransferOwnershipPendingRequestByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransferOwnershipPendingRequestByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransferOwnershipPendingRequestByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTransferOwnershipPendingRequestByIdQuery(baseOptions: Apollo.QueryHookOptions<GetTransferOwnershipPendingRequestByIdQuery, GetTransferOwnershipPendingRequestByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTransferOwnershipPendingRequestByIdQuery, GetTransferOwnershipPendingRequestByIdQueryVariables>(GetTransferOwnershipPendingRequestByIdDocument, options);
      }
export function useGetTransferOwnershipPendingRequestByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTransferOwnershipPendingRequestByIdQuery, GetTransferOwnershipPendingRequestByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTransferOwnershipPendingRequestByIdQuery, GetTransferOwnershipPendingRequestByIdQueryVariables>(GetTransferOwnershipPendingRequestByIdDocument, options);
        }
export type GetTransferOwnershipPendingRequestByIdQueryHookResult = ReturnType<typeof useGetTransferOwnershipPendingRequestByIdQuery>;
export type GetTransferOwnershipPendingRequestByIdLazyQueryHookResult = ReturnType<typeof useGetTransferOwnershipPendingRequestByIdLazyQuery>;
export type GetTransferOwnershipPendingRequestByIdQueryResult = Apollo.QueryResult<GetTransferOwnershipPendingRequestByIdQuery, GetTransferOwnershipPendingRequestByIdQueryVariables>;
export const OnTeamUserInvitationUpdatedDocument = gql`
    subscription onTeamUserInvitationUpdated($slug: String!) {
  team_user_invitations(
    where: {team: {custom_slug: {_eq: $slug}}, status: {_eq: "PENDING"}}
  ) {
    ...teamUserInvitationFields
  }
}
    ${TeamUserInvitationFieldsFragmentDoc}`;

/**
 * __useOnTeamUserInvitationUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnTeamUserInvitationUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnTeamUserInvitationUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnTeamUserInvitationUpdatedSubscription({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useOnTeamUserInvitationUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnTeamUserInvitationUpdatedSubscription, OnTeamUserInvitationUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnTeamUserInvitationUpdatedSubscription, OnTeamUserInvitationUpdatedSubscriptionVariables>(OnTeamUserInvitationUpdatedDocument, options);
      }
export type OnTeamUserInvitationUpdatedSubscriptionHookResult = ReturnType<typeof useOnTeamUserInvitationUpdatedSubscription>;
export type OnTeamUserInvitationUpdatedSubscriptionResult = Apollo.SubscriptionResult<OnTeamUserInvitationUpdatedSubscription>;
export const GetTeamUsersDocument = gql`
    query getTeamUsers($slug: String!) {
  team_users(where: {team: {custom_slug: {_eq: $slug}}}) {
    ...teamUserFields
  }
}
    ${TeamUserFieldsFragmentDoc}`;

/**
 * __useGetTeamUsersQuery__
 *
 * To run a query within a React component, call `useGetTeamUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamUsersQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetTeamUsersQuery(baseOptions: Apollo.QueryHookOptions<GetTeamUsersQuery, GetTeamUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeamUsersQuery, GetTeamUsersQueryVariables>(GetTeamUsersDocument, options);
      }
export function useGetTeamUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamUsersQuery, GetTeamUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeamUsersQuery, GetTeamUsersQueryVariables>(GetTeamUsersDocument, options);
        }
export type GetTeamUsersQueryHookResult = ReturnType<typeof useGetTeamUsersQuery>;
export type GetTeamUsersLazyQueryHookResult = ReturnType<typeof useGetTeamUsersLazyQuery>;
export type GetTeamUsersQueryResult = Apollo.QueryResult<GetTeamUsersQuery, GetTeamUsersQueryVariables>;
export const OnTeamUserUpdatedDocument = gql`
    subscription onTeamUserUpdated($slug: String!) {
  team_users(where: {team: {custom_slug: {_eq: $slug}}}) {
    ...teamUserFields
  }
}
    ${TeamUserFieldsFragmentDoc}`;

/**
 * __useOnTeamUserUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnTeamUserUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnTeamUserUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnTeamUserUpdatedSubscription({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useOnTeamUserUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnTeamUserUpdatedSubscription, OnTeamUserUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnTeamUserUpdatedSubscription, OnTeamUserUpdatedSubscriptionVariables>(OnTeamUserUpdatedDocument, options);
      }
export type OnTeamUserUpdatedSubscriptionHookResult = ReturnType<typeof useOnTeamUserUpdatedSubscription>;
export type OnTeamUserUpdatedSubscriptionResult = Apollo.SubscriptionResult<OnTeamUserUpdatedSubscription>;
export const GetTeamMemberByUserIdDocument = gql`
    query getTeamMemberByUserId($teamId: uuid!, $id: uuid!) {
  team_users(where: {team_id: {_eq: $teamId}, user_id: {_eq: $id}}) {
    ...teamUserFields
  }
}
    ${TeamUserFieldsFragmentDoc}`;

/**
 * __useGetTeamMemberByUserIdQuery__
 *
 * To run a query within a React component, call `useGetTeamMemberByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamMemberByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamMemberByUserIdQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTeamMemberByUserIdQuery(baseOptions: Apollo.QueryHookOptions<GetTeamMemberByUserIdQuery, GetTeamMemberByUserIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeamMemberByUserIdQuery, GetTeamMemberByUserIdQueryVariables>(GetTeamMemberByUserIdDocument, options);
      }
export function useGetTeamMemberByUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamMemberByUserIdQuery, GetTeamMemberByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeamMemberByUserIdQuery, GetTeamMemberByUserIdQueryVariables>(GetTeamMemberByUserIdDocument, options);
        }
export type GetTeamMemberByUserIdQueryHookResult = ReturnType<typeof useGetTeamMemberByUserIdQuery>;
export type GetTeamMemberByUserIdLazyQueryHookResult = ReturnType<typeof useGetTeamMemberByUserIdLazyQuery>;
export type GetTeamMemberByUserIdQueryResult = Apollo.QueryResult<GetTeamMemberByUserIdQuery, GetTeamMemberByUserIdQueryVariables>;
export const GetTemplatesDocument = gql`
    query getTemplates($projectId: uuid!, $orderBy: [files_order_by!], $limit: Int, $offset: Int) {
  files(
    where: {status: {_eq: "NORMAL"}, project_id: {_eq: $projectId}}
    order_by: $orderBy
    limit: $limit
    offset: $offset
  ) @connection(key: "templateFiles") {
    ...templateFields
  }
}
    ${TemplateFieldsFragmentDoc}`;

/**
 * __useGetTemplatesQuery__
 *
 * To run a query within a React component, call `useGetTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplatesQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetTemplatesQuery(baseOptions: Apollo.QueryHookOptions<GetTemplatesQuery, GetTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTemplatesQuery, GetTemplatesQueryVariables>(GetTemplatesDocument, options);
      }
export function useGetTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTemplatesQuery, GetTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTemplatesQuery, GetTemplatesQueryVariables>(GetTemplatesDocument, options);
        }
export type GetTemplatesQueryHookResult = ReturnType<typeof useGetTemplatesQuery>;
export type GetTemplatesLazyQueryHookResult = ReturnType<typeof useGetTemplatesLazyQuery>;
export type GetTemplatesQueryResult = Apollo.QueryResult<GetTemplatesQuery, GetTemplatesQueryVariables>;
export const OnUserUpdatedDocument = gql`
    subscription onUserUpdated($id: uuid!) {
  users(where: {id: {_eq: $id}}) {
    ...userFields
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useOnUserUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnUserUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnUserUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUserUpdatedSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOnUserUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnUserUpdatedSubscription, OnUserUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnUserUpdatedSubscription, OnUserUpdatedSubscriptionVariables>(OnUserUpdatedDocument, options);
      }
export type OnUserUpdatedSubscriptionHookResult = ReturnType<typeof useOnUserUpdatedSubscription>;
export type OnUserUpdatedSubscriptionResult = Apollo.SubscriptionResult<OnUserUpdatedSubscription>;
export const TutorialStatusDocument = gql`
    query tutorialStatus($id: uuid!) {
  user_preferences_by_pk(key: "tutorial-status", user_id: $id) {
    value
  }
}
    `;

/**
 * __useTutorialStatusQuery__
 *
 * To run a query within a React component, call `useTutorialStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useTutorialStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTutorialStatusQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTutorialStatusQuery(baseOptions: Apollo.QueryHookOptions<TutorialStatusQuery, TutorialStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TutorialStatusQuery, TutorialStatusQueryVariables>(TutorialStatusDocument, options);
      }
export function useTutorialStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TutorialStatusQuery, TutorialStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TutorialStatusQuery, TutorialStatusQueryVariables>(TutorialStatusDocument, options);
        }
export type TutorialStatusQueryHookResult = ReturnType<typeof useTutorialStatusQuery>;
export type TutorialStatusLazyQueryHookResult = ReturnType<typeof useTutorialStatusLazyQuery>;
export type TutorialStatusQueryResult = Apollo.QueryResult<TutorialStatusQuery, TutorialStatusQueryVariables>;
export const PresencePreferenceDocument = gql`
    query presencePreference($id: uuid!) {
  user_preferences_by_pk(key: "presence-preference", user_id: $id) {
    value
  }
}
    `;

/**
 * __usePresencePreferenceQuery__
 *
 * To run a query within a React component, call `usePresencePreferenceQuery` and pass it any options that fit your needs.
 * When your component renders, `usePresencePreferenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePresencePreferenceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePresencePreferenceQuery(baseOptions: Apollo.QueryHookOptions<PresencePreferenceQuery, PresencePreferenceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PresencePreferenceQuery, PresencePreferenceQueryVariables>(PresencePreferenceDocument, options);
      }
export function usePresencePreferenceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PresencePreferenceQuery, PresencePreferenceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PresencePreferenceQuery, PresencePreferenceQueryVariables>(PresencePreferenceDocument, options);
        }
export type PresencePreferenceQueryHookResult = ReturnType<typeof usePresencePreferenceQuery>;
export type PresencePreferenceLazyQueryHookResult = ReturnType<typeof usePresencePreferenceLazyQuery>;
export type PresencePreferenceQueryResult = Apollo.QueryResult<PresencePreferenceQuery, PresencePreferenceQueryVariables>;
export const OnPreferenceUpdatedDocument = gql`
    subscription onPreferenceUpdated($id: uuid!, $key: String!) {
  user_preferences(where: {user_id: {_eq: $id}, key: {_eq: $key}}) {
    value
  }
}
    `;

/**
 * __useOnPreferenceUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnPreferenceUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnPreferenceUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnPreferenceUpdatedSubscription({
 *   variables: {
 *      id: // value for 'id'
 *      key: // value for 'key'
 *   },
 * });
 */
export function useOnPreferenceUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnPreferenceUpdatedSubscription, OnPreferenceUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnPreferenceUpdatedSubscription, OnPreferenceUpdatedSubscriptionVariables>(OnPreferenceUpdatedDocument, options);
      }
export type OnPreferenceUpdatedSubscriptionHookResult = ReturnType<typeof useOnPreferenceUpdatedSubscription>;
export type OnPreferenceUpdatedSubscriptionResult = Apollo.SubscriptionResult<OnPreferenceUpdatedSubscription>;
export const GetUsersByIdsDocument = gql`
    query getUsersByIds($ids: [uuid!]!) {
  users(where: {id: {_in: $ids}}) {
    ...userFields
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useGetUsersByIdsQuery__
 *
 * To run a query within a React component, call `useGetUsersByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetUsersByIdsQuery(baseOptions: Apollo.QueryHookOptions<GetUsersByIdsQuery, GetUsersByIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersByIdsQuery, GetUsersByIdsQueryVariables>(GetUsersByIdsDocument, options);
      }
export function useGetUsersByIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersByIdsQuery, GetUsersByIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersByIdsQuery, GetUsersByIdsQueryVariables>(GetUsersByIdsDocument, options);
        }
export type GetUsersByIdsQueryHookResult = ReturnType<typeof useGetUsersByIdsQuery>;
export type GetUsersByIdsLazyQueryHookResult = ReturnType<typeof useGetUsersByIdsLazyQuery>;
export type GetUsersByIdsQueryResult = Apollo.QueryResult<GetUsersByIdsQuery, GetUsersByIdsQueryVariables>;
export const CommentVisibilityPreferenceDocument = gql`
    query commentVisibilityPreference($id: uuid!) {
  user_preferences_by_pk(key: "comment-visibility", user_id: $id) {
    value
  }
}
    `;

/**
 * __useCommentVisibilityPreferenceQuery__
 *
 * To run a query within a React component, call `useCommentVisibilityPreferenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommentVisibilityPreferenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommentVisibilityPreferenceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCommentVisibilityPreferenceQuery(baseOptions: Apollo.QueryHookOptions<CommentVisibilityPreferenceQuery, CommentVisibilityPreferenceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommentVisibilityPreferenceQuery, CommentVisibilityPreferenceQueryVariables>(CommentVisibilityPreferenceDocument, options);
      }
export function useCommentVisibilityPreferenceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommentVisibilityPreferenceQuery, CommentVisibilityPreferenceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommentVisibilityPreferenceQuery, CommentVisibilityPreferenceQueryVariables>(CommentVisibilityPreferenceDocument, options);
        }
export type CommentVisibilityPreferenceQueryHookResult = ReturnType<typeof useCommentVisibilityPreferenceQuery>;
export type CommentVisibilityPreferenceLazyQueryHookResult = ReturnType<typeof useCommentVisibilityPreferenceLazyQuery>;
export type CommentVisibilityPreferenceQueryResult = Apollo.QueryResult<CommentVisibilityPreferenceQuery, CommentVisibilityPreferenceQueryVariables>;
export const TemplateSectionVisibilityPreferenceDocument = gql`
    query templateSectionVisibilityPreference($id: uuid!) {
  user_preferences_by_pk(key: "template-section-visibility", user_id: $id) {
    value
  }
}
    `;

/**
 * __useTemplateSectionVisibilityPreferenceQuery__
 *
 * To run a query within a React component, call `useTemplateSectionVisibilityPreferenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateSectionVisibilityPreferenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateSectionVisibilityPreferenceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTemplateSectionVisibilityPreferenceQuery(baseOptions: Apollo.QueryHookOptions<TemplateSectionVisibilityPreferenceQuery, TemplateSectionVisibilityPreferenceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemplateSectionVisibilityPreferenceQuery, TemplateSectionVisibilityPreferenceQueryVariables>(TemplateSectionVisibilityPreferenceDocument, options);
      }
export function useTemplateSectionVisibilityPreferenceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplateSectionVisibilityPreferenceQuery, TemplateSectionVisibilityPreferenceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemplateSectionVisibilityPreferenceQuery, TemplateSectionVisibilityPreferenceQueryVariables>(TemplateSectionVisibilityPreferenceDocument, options);
        }
export type TemplateSectionVisibilityPreferenceQueryHookResult = ReturnType<typeof useTemplateSectionVisibilityPreferenceQuery>;
export type TemplateSectionVisibilityPreferenceLazyQueryHookResult = ReturnType<typeof useTemplateSectionVisibilityPreferenceLazyQuery>;
export type TemplateSectionVisibilityPreferenceQueryResult = Apollo.QueryResult<TemplateSectionVisibilityPreferenceQuery, TemplateSectionVisibilityPreferenceQueryVariables>;
export const NewsletterSubscriptionPreferenceDocument = gql`
    query newsletterSubscriptionPreference($id: uuid!) {
  user_preferences_by_pk(key: "newsletter-subscription", user_id: $id) {
    value
  }
}
    `;

/**
 * __useNewsletterSubscriptionPreferenceQuery__
 *
 * To run a query within a React component, call `useNewsletterSubscriptionPreferenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsletterSubscriptionPreferenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsletterSubscriptionPreferenceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNewsletterSubscriptionPreferenceQuery(baseOptions: Apollo.QueryHookOptions<NewsletterSubscriptionPreferenceQuery, NewsletterSubscriptionPreferenceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NewsletterSubscriptionPreferenceQuery, NewsletterSubscriptionPreferenceQueryVariables>(NewsletterSubscriptionPreferenceDocument, options);
      }
export function useNewsletterSubscriptionPreferenceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewsletterSubscriptionPreferenceQuery, NewsletterSubscriptionPreferenceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NewsletterSubscriptionPreferenceQuery, NewsletterSubscriptionPreferenceQueryVariables>(NewsletterSubscriptionPreferenceDocument, options);
        }
export type NewsletterSubscriptionPreferenceQueryHookResult = ReturnType<typeof useNewsletterSubscriptionPreferenceQuery>;
export type NewsletterSubscriptionPreferenceLazyQueryHookResult = ReturnType<typeof useNewsletterSubscriptionPreferenceLazyQuery>;
export type NewsletterSubscriptionPreferenceQueryResult = Apollo.QueryResult<NewsletterSubscriptionPreferenceQuery, NewsletterSubscriptionPreferenceQueryVariables>;
export const PreferredLanguageDocument = gql`
    query preferredLanguage($id: uuid!) {
  user_preferences_by_pk(key: "preferred-language", user_id: $id) {
    value
  }
}
    `;

/**
 * __usePreferredLanguageQuery__
 *
 * To run a query within a React component, call `usePreferredLanguageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreferredLanguageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreferredLanguageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePreferredLanguageQuery(baseOptions: Apollo.QueryHookOptions<PreferredLanguageQuery, PreferredLanguageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PreferredLanguageQuery, PreferredLanguageQueryVariables>(PreferredLanguageDocument, options);
      }
export function usePreferredLanguageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PreferredLanguageQuery, PreferredLanguageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PreferredLanguageQuery, PreferredLanguageQueryVariables>(PreferredLanguageDocument, options);
        }
export type PreferredLanguageQueryHookResult = ReturnType<typeof usePreferredLanguageQuery>;
export type PreferredLanguageLazyQueryHookResult = ReturnType<typeof usePreferredLanguageLazyQuery>;
export type PreferredLanguageQueryResult = Apollo.QueryResult<PreferredLanguageQuery, PreferredLanguageQueryVariables>;
export const GetVersionsDocument = gql`
    query getVersions($fileId: uuid!, $limit: Int, $offset: Int, $excludeUnnamed: Boolean) {
  versions(
    where: {file_id: {_eq: $fileId}, _or: [{save_type: {_eq: "named"}}, {_and: [{save_type: {_eq: "unnamed"}}, {save_type: {_is_null: $excludeUnnamed}}]}]}
    limit: $limit
    offset: $offset
    order_by: {created_at: desc}
  ) {
    ...versionFields
  }
}
    ${VersionFieldsFragmentDoc}`;

/**
 * __useGetVersionsQuery__
 *
 * To run a query within a React component, call `useGetVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVersionsQuery({
 *   variables: {
 *      fileId: // value for 'fileId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      excludeUnnamed: // value for 'excludeUnnamed'
 *   },
 * });
 */
export function useGetVersionsQuery(baseOptions: Apollo.QueryHookOptions<GetVersionsQuery, GetVersionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVersionsQuery, GetVersionsQueryVariables>(GetVersionsDocument, options);
      }
export function useGetVersionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVersionsQuery, GetVersionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVersionsQuery, GetVersionsQueryVariables>(GetVersionsDocument, options);
        }
export type GetVersionsQueryHookResult = ReturnType<typeof useGetVersionsQuery>;
export type GetVersionsLazyQueryHookResult = ReturnType<typeof useGetVersionsLazyQuery>;
export type GetVersionsQueryResult = Apollo.QueryResult<GetVersionsQuery, GetVersionsQueryVariables>;
export const GetVersionByIdDocument = gql`
    query getVersionById($id: uuid!) {
  versions_by_pk(id: $id) {
    ...versionFields
  }
}
    ${VersionFieldsFragmentDoc}`;

/**
 * __useGetVersionByIdQuery__
 *
 * To run a query within a React component, call `useGetVersionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVersionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVersionByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVersionByIdQuery(baseOptions: Apollo.QueryHookOptions<GetVersionByIdQuery, GetVersionByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVersionByIdQuery, GetVersionByIdQueryVariables>(GetVersionByIdDocument, options);
      }
export function useGetVersionByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVersionByIdQuery, GetVersionByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVersionByIdQuery, GetVersionByIdQueryVariables>(GetVersionByIdDocument, options);
        }
export type GetVersionByIdQueryHookResult = ReturnType<typeof useGetVersionByIdQuery>;
export type GetVersionByIdLazyQueryHookResult = ReturnType<typeof useGetVersionByIdLazyQuery>;
export type GetVersionByIdQueryResult = Apollo.QueryResult<GetVersionByIdQuery, GetVersionByIdQueryVariables>;