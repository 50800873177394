export const FEATURE_KEYS = {
  AWS_RUM: 'phase7790-aws-rum',
  CAMPAIGN_BANNER: 'campaign-banner',
  CHANGE_PASSWORD: 'change-password',
  EXPORT_PHASE_FILE: 'export-phase-file',
  FPS_METER: 'fps-meter',
  IMPORT_PHASE_FILE: 'import-phase-file',
  LOW_DPR: 'low-dpr',
  MULTILINGUAL_SUPPORT: 'multilingual-support',
  AVAILABLE_LANGUAGES: 'available-languages',
  PARTIAL_SAVE: 'partial-save',
  PRESENCE: 'presence',
  SENTRY: 'sentry',
  SHOW_LOTTIE_GENERATED_FROM_SVG_BUTTON: 'lottie-generated-from-svg-button',
  TEMPLATE: 'built-in-template',
  VIDEO_TUTORIAL: 'video-tutorial',
  WEB_CONFIG: 'web-config'
}
