import React from 'react'
import { useTranslation } from 'react-i18next'

import { Dialog, Icon } from '../../../components/shared'

type FileOutdatedConfirmationDialogProps = {
  open: boolean
  onClose: () => void
}

function FileOutdatedConfirmationDialog({ open, onClose }: FileOutdatedConfirmationDialogProps) {
  const { t } = useTranslation('workspace')
  return (
    <Dialog
      data-test-id="file-outdated-confirmation-dialog"
      size="xs"
      title={t('dialog.reload_file')}
      confirmBtnText={t('dialog.reload')}
      onConfirm={onClose}
      open={open}
      closable={false}
    >
      <div className="flex gap-8">
        <Icon name="Warning" size="xxl" className="text-warning-60" interactive={false} />
        {t('dialog.your_file_is_outdated')}
      </div>
    </Dialog>
  )
}
export default FileOutdatedConfirmationDialog
