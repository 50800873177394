import { fetchFile } from '@ffmpeg/util'
import { VideoQuality } from '@phase-software/types'
import Encoder from '../encoder'

/** @typedef {import('../recorder').RecorderOption} RecorderOption */
/** @typedef {import('@ffmpeg/ffmpeg').FFmpeg} FFmpeg */

/** @type {object} */
const COMPRESS_QUALITY = {
    [VideoQuality.ULTRA]: 2,
    [VideoQuality.HIGH]: 23,
    [VideoQuality.MEDIUM]: 36,
    [VideoQuality.LOW]: 49,
}

export class MP4Encoder extends Encoder {
    /**
     * @param {FFmpeg} ffmpeg
     */
    constructor(ffmpeg) {
        super()

        this.ffmpeg = ffmpeg

        this._width = 0
        this._height = 0
        this._frameRate = 0
        this._speed = 0
        this._quality = 0
        this._index = 0

        this._onProgressHandler = null
    }

    /**
     * @param {RecorderOption} options
     */
    init(options) {
        this._width = options.width
        this._height = options.height
        this._frameRate = options.fps
        this._speed = options.speed
        this._quality = COMPRESS_QUALITY[options.quality]
        this._index = 0

        this._onProgressHandler = null
    }

    static getEstimatedSize({ width, height, fps, duration, speed, videoQuality }){
        const adjustedDuration = duration / speed // Adjust duration based on speed
        const colorBits = 8 // Assuming 8 bits per color channel
        const channels = 4 // Assuming RGBA
        const quantizationParameter = COMPRESS_QUALITY[videoQuality] // COMPRESS_QUALITY[options.quality]

        const baselineCoefficient = 0.05 // 0.1 to 0.25 is a typical range
        const sizeInKilobytes = baselineCoefficient * (width * height * channels * colorBits * fps * adjustedDuration) / (8 * 1024 * quantizationParameter)

        return sizeInKilobytes
    }

    /**
     * Add frame data to encoder
     * @param {Uint8Array} pixels
     */
    async addFrame(pixels) {
        const imageBuffer = await fetchFile(pixels)
        await this.ffmpeg.writeFile(`${this._index}.png`, imageBuffer)
        this._index++
    }

    /**
     * Get encoder data
     */
    getData() {
        return this.ffmpeg.readFile("output.mp4")
    }

    /**
     * Encode frames to mp4 video
     */
    finalize() {
        return this.ffmpeg.exec([
            '-framerate', `${this._frameRate}`,
            '-i', '%d.png',
            '-c:v', 'libx264',
            '-crf', `${this._quality}`,
            '-filter:v', `setpts=${1 / this._speed}*PTS,scale=out_color_matrix=bt709:out_range=tv`,
            '-pix_fmt', 'yuv420p',
            '-bsf:v', 'h264_metadata=video_full_range_flag=0:colour_primaries=1:transfer_characteristics=1:matrix_coefficients=1',
            'output.mp4'
        ])
    }

    /**
     * End of the encoder
     */
    end() {
        if (!this.ffmpeg.loaded) return

        const promises = [this.ffmpeg.deleteFile("output.mp4")]
        for (let i = 0; i <= this._index; i++) {
            promises.push(this.ffmpeg.deleteFile(`${i}.png`))
        }

        Promise.all(promises).then(() => {
        }).catch(error => {
            console.error("Error during cleanup:", error)
        }).then(() => {
            this.ffmpeg.terminate()
        })

        if (this._onProgressHandler) {
            this.ffmpeg.off("progress", this._onProgressHandler)
            this._onProgressHandler = null
        }
    }

    /**
     * Cancel encoder
     */
    cancel() {
        this.end()
    }

    onProgress(onProgress) {
        this._onProgressHandler = ({ progress }) => {
            onProgress(Math.min(progress, 1) * 100)
        }
        this.ffmpeg.on("progress", this._onProgressHandler)
    }
}
