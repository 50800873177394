import React from 'react'

type LockOverlayProps = {
  enabled?: boolean
  style?: React.CSSProperties
}

const LockOverlay: React.FC<LockOverlayProps> = ({ enabled, style }) => {
  return (
    <div
      className={`h-full w-full z-[4] cursor-not-allowed bg-dark-overlay-50 ${enabled ? 'absolute' : 'hidden'}`}
      style={style}
    ></div>
  )
}

export default LockOverlay
