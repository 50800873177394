import { Vector2, Transform2D, RAD_TO_DEG, DEG_TO_RAD, PI_2 } from '../../math'
import { fixInfiniteSkew } from '../../visual_server/Transform'

/** @typedef {import('./index').Handle} Handle */
/** @typedef {import('./index').Anchor} Anchor */

/**
 * @typedef {object} NewSizeData
 * @property {Vector2} position
 * @property {Vector2} size
 * @property {Vector2} scale
 * @property {Vector2} skew
 * @property {number} rotation
 */

/**
 * @param {Transform2D} T
 * @param {Vector2} originalSize
 * @param {Vector2} offset
 * @param {Transform2D} [T2]
 * @returns {NewSizeData}
 */
export function decomposeWithOffset(T, offset = new Vector2()) {
    const { skew, scale, rotation, translation: position } = T.decompose()

    const local = new Transform2D()
        .translate_right(offset.x, offset.y)
        .rotate_right(rotation)
        .skew_right(fixInfiniteSkew(skew.x), fixInfiniteSkew(skew.y))
        .scale_right(scale.x, scale.y)
        .translate_right(-offset.x, -offset.y)

    position.sub(local.get_origin())

    return { position, scale, skew, rotation }
}

/**
 * @param {Vector2} center
 * @param {Vector2} initialMousePos
 * @returns {(mousePos: Vector2) => { delta: number, direction: Vector2 }}
 */
export function rotationTracker(center, initialMousePos) {
    const initialDirection = initialMousePos.sub(center)

    let halfRotations = 0
    let lastDelta

    return mousePos => {
        const direction = mousePos.sub(center)
        const delta = Math.trunc(initialDirection.angle_to(direction) * RAD_TO_DEG) * DEG_TO_RAD

        const inv = Math.abs(delta) < PI_2
        const ignore = halfRotations === 0 && inv
        if (lastDelta !== undefined && !ignore) {
            const l = Math.sign(lastDelta)
            const c = Math.sign(delta)
            let change = 0
            if (l === -1 && c === 1) {
                change = -1
            } else if (l === 1 && c === -1) {
                change = 1
            }
            if (inv) change *= -1
            halfRotations += change
        }
        if (delta !== 0) lastDelta = delta

        const adjustment = Math.PI * Math.sign(halfRotations) * Math.abs(halfRotations % 2)

        return {
            delta: delta + adjustment + halfRotations * Math.PI,
            direction
        }
    }
}

/**
 * add boolean parent to the list
 * @param {DataStore} dataStore
 * @param {Iterator} iter
 * @param {StaticArray} booleanList
 */
export function getBooleanParents(dataStore, iter, booleanList) {
    for (const {element} of iter) {
        const parent = dataStore.getParentOf(element)
        if (parent.isBooleanType()) {
            booleanList.add_unique(parent)
        }
    }
}

