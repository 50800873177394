import React from 'react'
import Icon from '../Icon'

type MessageType = 'success' | 'info' | 'warning' | 'error'

export type MessageProps = {
  className?: string
  description: string
  type?: MessageType
  onClose?: () => void
}

const messageConfig = {
  info: {
    iconName: 'Info',
    iconColor: 'text-primary-40',
    backgroundColor: 'bg-primary-overlay-10',
    outlineStyle: 'outline-primary-overlay-80-1-offset--1'
  },
  error: {
    iconName: 'Danger',
    iconColor: 'text-danger-40',
    backgroundColor: 'bg-rubin-overlay-10',
    outlineStyle: 'outline-rubin-overlay-80-1-offset--1'
  },
  success: {
    iconName: 'Success',
    iconColor: 'text-lime-40',
    backgroundColor: 'bg-lime-overlay-10',
    outlineStyle: 'outline-lime-overlay-80-1-offset--1'
  },
  warning: {
    iconName: 'Warning',
    iconColor: 'text-gold-40',
    backgroundColor: 'bg-gold-overlay-10',
    outlineStyle: 'outline-gold-overlay-80-1-offset--1'
  }
}

const Message = ({ className = '', type = 'info', description, onClose }: MessageProps) => {
  const { iconName, iconColor, backgroundColor, outlineStyle } = messageConfig[type]

  return (
    <div
      className={`${backgroundColor} ${outlineStyle} text-14 font-normal text-left text-white px-16 py-12 items-center rounded-lg flex gap-12 ${className}`}
    >
      <Icon name={iconName} interactive={false} className={`${iconColor} mb-auto`} size="xxl" useCurrentColor />
      <div className="flex-1">{description}</div>
      {onClose && <Icon name="Cross" className="mt-4 mb-auto" onClick={onClose} />}
    </div>
  )
}

export default Message
