import React, { useRef } from 'react'
import { minmax, parseToDecimals } from '../../../utils/number'

const EDITOR_SIZE = {
  WIDTH: 216,
  HEIGHT: 176
}

const GRAPH_SIZE = 108

const DRAGGABLE_POINT_RADIUS = 8
const ANCHOR_POINT_RADIUS = 4
const POINT_TRIGGER_SIZE = 16

const GAP = { X: (EDITOR_SIZE.WIDTH - GRAPH_SIZE) / 2, Y: (EDITOR_SIZE.HEIGHT - GRAPH_SIZE) / 2 }
const GRAPH_EDGE = { RIGHT: GAP.X + GRAPH_SIZE, BOTTOM: GAP.Y + GRAPH_SIZE }

enum HandleIdentifier {
  START,
  END
}

export type BezierCurve = [number, number, number, number]

type EasingEditorProps = {
  value: BezierCurve
  onChange: (value: BezierCurve, commit: boolean) => void
  isStepType: boolean
  isMixed: boolean
}

const EasingEditor = ({ value, onChange, isStepType, isMixed }: EasingEditorProps) => {
  const ref = useRef<HTMLDivElement>(null)

  const [x1, y1, x2, y2] = value.map((v, i) => GRAPH_SIZE * (i % 2 === 1 ? 1 - v : v) + (i % 2 === 1 ? GAP.Y : GAP.X))

  const minY = Math.min(0, y1 - DRAGGABLE_POINT_RADIUS, y2 - DRAGGABLE_POINT_RADIUS)
  const maxY = Math.max(EDITOR_SIZE.HEIGHT, y1 + DRAGGABLE_POINT_RADIUS, y2 + DRAGGABLE_POINT_RADIUS)

  const curvePath = isStepType
    ? `M${GAP.X} ${GRAPH_EDGE.BOTTOM}, ${GRAPH_EDGE.RIGHT} ${GRAPH_EDGE.BOTTOM}, ${GRAPH_EDGE.RIGHT} ${GAP.Y}`
    : `M${GAP.X} ${GRAPH_EDGE.BOTTOM} C ${x1} ${y1}, ${x2} ${y2}, ${GRAPH_EDGE.RIGHT} ${GAP.Y}`

  const getBezierValue = (identifier: HandleIdentifier, x: number, y: number): BezierCurve =>
    identifier === HandleIdentifier.START ? [x, y, value[2], value[3]] : [value[0], value[1], x, y]

  const updateHandlePosition = (identifier: HandleIdentifier) => (e: React.MouseEvent) => {
    e.stopPropagation()
    let isDragging = false
    const { left, top } = ref.current?.getBoundingClientRect() || { left: 0, top: 0 }
    const handleMouseMove = (e: MouseEvent, commit = false) => {
      isDragging = true
      const x = parseToDecimals(minmax((e.clientX - left - GAP.X) / GRAPH_SIZE, 0, 1), 2)
      const y = parseToDecimals(1 - (e.clientY - top - GAP.Y) / GRAPH_SIZE, 2)

      onChange(getBezierValue(identifier, x, y), commit)
    }

    const handleMouseUp = (e: MouseEvent) => {
      if (isDragging) {
        handleMouseMove(e, true)
      }
      window.removeEventListener('mousemove', handleMouseMove)
    }

    window.addEventListener('mousemove', handleMouseMove)
    window.addEventListener('mouseup', handleMouseUp, { once: true })
  }

  const resetHandlePosition = (identifier: HandleIdentifier) => (e: React.MouseEvent) => {
    e.stopPropagation()
    const position = identifier === HandleIdentifier.START ? 0 : 1
    onChange(getBezierValue(identifier, position, position), false)
  }
  return (
    <div
      ref={ref}
      className="relative bg-light-overlay-3 overflow-hidden rounded-md"
      style={{ width: EDITOR_SIZE.WIDTH, height: EDITOR_SIZE.HEIGHT }}
    >
      <svg
        className="absolute top-0 left-0"
        style={{ transform: `translateY(${minY}px)` }}
        width={EDITOR_SIZE.WIDTH}
        height={isStepType ? EDITOR_SIZE.HEIGHT : maxY - minY}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g transform={`translate(0, ${isStepType ? 0 : -minY})`}>
          <GraphReference />
          {!isMixed && <GraphCurve data={curvePath} />}
          {!isStepType && !isMixed && (
            <>
              <DraggableHandle
                x1={GAP.X}
                y1={GRAPH_EDGE.BOTTOM}
                x2={x1}
                y2={y1}
                onMouseDown={updateHandlePosition(HandleIdentifier.START)}
                onMouseDownAnchorPoint={resetHandlePosition(HandleIdentifier.START)}
              />
              <DraggableHandle
                x1={GRAPH_EDGE.RIGHT}
                y1={GAP.Y}
                x2={x2}
                y2={y2}
                onMouseDown={updateHandlePosition(HandleIdentifier.END)}
                onMouseDownAnchorPoint={resetHandlePosition(HandleIdentifier.END)}
              />
            </>
          )}
        </g>
      </svg>
    </div>
  )
}

export default EasingEditor

function DraggableHandle({
  x1,
  x2,
  y1,
  y2,
  onMouseDown,
  onMouseDownAnchorPoint
}: {
  x1: number
  x2: number
  y1: number
  y2: number
  onMouseDown: (e: React.MouseEvent) => void
  onMouseDownAnchorPoint: (e: React.MouseEvent) => void
}) {
  const handleMouseDown = (e: React.MouseEvent) => {
    onMouseDown(e)
  }

  return (
    <g>
      <line x1={x1} y1={y1} x2={x2} y2={y2} stroke="#8B8B8B" strokeWidth="2" strokeLinecap="round" />
      <ellipse
        cx={x1}
        cy={y1}
        rx={ANCHOR_POINT_RADIUS}
        ry={ANCHOR_POINT_RADIUS}
        stroke="transparent"
        strokeWidth={POINT_TRIGGER_SIZE}
        className="fill-current text-neutral-10 hover:text-white active:text-neutral-30 cursor-pointer"
        onMouseUp={onMouseDownAnchorPoint}
      />
      <g className="cursor-pointer group" onMouseDown={handleMouseDown}>
        <circle cx={x2} cy={y2} r={DRAGGABLE_POINT_RADIUS} className="text-neutral-90 fill-current" />
        <circle cx={x2} cy={y2} r={DRAGGABLE_POINT_RADIUS} className="text-white fill-current" fillOpacity="0.03" />
        <circle
          cx={x2}
          cy={y2}
          r={DRAGGABLE_POINT_RADIUS - 3}
          strokeWidth="2"
          className="fill-none stroke-current text-neutral-10 group-hover:text-white group-active:text-white group-active:fill-white"
        />
      </g>
    </g>
  )
}

function GraphCurve({ data }: { data: string }) {
  return <path d={data} stroke="#1C6EE8" strokeWidth="2" strokeLinecap="round" fill="transparent" />
}

function GraphReference() {
  return (
    <>
      <path
        d={`M${GAP.X} ${GRAPH_EDGE.BOTTOM}L${GRAPH_EDGE.RIGHT} ${GAP.Y}`}
        className="text-neutral-60 stroke-current"
        strokeWidth="1"
        strokeLinecap="round"
        strokeDasharray="6 6"
      />
      <rect
        x={GAP.X}
        y={GAP.Y}
        width={GRAPH_SIZE}
        height={GRAPH_SIZE}
        className="text-neutral-60 stroke-current"
        strokeWidth="1"
        fill="transparent"
        strokeLinejoin="round"
      />
    </>
  )
}
