import { FrameType } from '@phase-software/types'
import Interval from '../../helpers/Interval'
import PropertyStack from '../PropertyStack'

class PathMorphingStack extends PropertyStack {
  constructor(elementStack, data) {
    super(elementStack, data)
    this.type = 'PATH_MORPHING'
    this.key = 'pathMorphing'
    this.dataKey = 'pathMorphing'
    this.animatableProperties = new Set([''])
    this._timeIntervalByVertex = new Map()
    this._verticesInterval = new Map()
    this._updateIntervalByVertex()
  }

  getInitValue() {
    const initValue = super.getInitValue()
    return [...initValue.values()]
  }

  addKF(newKF) {
    super.addKF(newKF)
    this._updateIntervalByVertex()
  }

  deleteKF(deletedKFId) {
    super.deleteKF(deletedKFId)
    this._updateIntervalByVertex()
  }

  updateKF(KFId, data) {
    // Force change isProcessingChangeEvent to false because we need to update shape instinct for PathMorphing.
    this.manager.cache.isProcessingChangeEvent = false
    super.updateKF(KFId, data)
    this._updateIntervalByVertex()
  }

  _updateIntervalByVertex() {
    // Clear interval by vertex cache
    this._timeIntervalByVertex.clear()

    // Remap interval by vertex
    const allKfs = this.actions[0].kfs
    allKfs.forEach((kf, idx) => {
      kf.value.forEach((v) => {
        let vertexMap = this._timeIntervalByVertex.get(v.id)
        if (!vertexMap) {
          vertexMap = []
          this._timeIntervalByVertex.set(v.id, vertexMap)
        }
        vertexMap.push({ time: kf.time, easingType: kf.easingType, idx })
      })
    })
  }

  /**
   * Get specific value for corner radius from the interval
   * @param {Interval} interval
   * @param {object} start
   * @param {object} end
   * @param {string} propKey
   * @param {number} startTime
   * @param {number} endTime
   * @param {EasingType} easingType
   * @returns {Interval}
   */
  _getIntervalWithPropertyValue(interval, start, end, propKey, startTime, endTime, easingType) {
    const outInt = new Interval()
    outInt.copy(interval, true)
      .updateValues(
        start[propKey],
        end[propKey]
      )
      .updateTimes(
        startTime,
        endTime
      )
      .updateEasingType(easingType)
    return outInt
  }

  getWorkingInterval(time) {
    const interval = super.getWorkingInterval(time)
    if (!interval) {
      return null
    }

    const workingTime = this.getWorkingTime(time)
    const base = this.getBaseValue()
    const allKfs = this.actions[0].kfs

    this._verticesInterval.clear()
    this._timeIntervalByVertex.forEach((vkfs, vId) => {
      const vertexInterval = {}
      this._verticesInterval.set(vId, vertexInterval)

      let startTime = 0
      let endTime = 0
      let startIdx = -1
      let endIdx = -1
      let easingType
      for (const vkf of vkfs) {
        if (workingTime >= startTime && workingTime < vkf.time) {
          endTime = vkf.time
          endIdx = vkf.idx
          easingType = vkf.easingType
          break
        }
        startTime = vkf.time
        startIdx = vkf.idx
        endTime = startTime
        endIdx = vkf.idx
        easingType = vkf.easingType
      }

      let s = base.get(vId)
      if (startIdx !== -1) {
        const kf = allKfs[startIdx]
        if (kf.frameType === FrameType.EXPLICIT) {
          s = kf.value.find((sv) => sv.id === vId)
        }
      }
      let e = s
      if (endIdx !== -1) {
        const kf = allKfs[endIdx]
        if (kf.frameType === FrameType.EXPLICIT) {
          e = kf.value.find((ev) => ev.id === vId)
        } else if (kf.frameType === FrameType.INITIAL) {
          // If end keyframe is INITIAL type, then we get the data from base again.
          e = base.get(vId)
        }
      }

      if (!s || !e) {
        console.warn('start/end vertex not found, not able to calculate interval value for vertex')
        return this._verticesInterval
      }

      if (s.pos && s.pos[0] !== undefined && e.pos && e.pos[0] !== undefined)  {
        vertexInterval.x = this._getIntervalWithPropertyValue(interval, s.pos, e.pos, 0, startTime, endTime, easingType)
      }
      if (s.pos && s.pos[1] !== undefined && e.pos && e.pos[1] !== undefined)  {
        vertexInterval.y = this._getIntervalWithPropertyValue(interval, s.pos, e.pos, 1, startTime, endTime, easingType)
      }
      if (s.mirror !== undefined && e.mirror !== undefined)  {
        vertexInterval.mirror = this._getIntervalWithPropertyValue(interval, s, e, 'mirror', startTime, endTime, easingType)
      }
    })

    return this._verticesInterval
  }

  getAnimateData(time) {
    const verticesInterval = this.getWorkingInterval(time)
    if (!verticesInterval || !verticesInterval.size) {
      return null
    }

    const workingTime = this.getWorkingTime(time)
    const changes = new Map()
    
    verticesInterval.forEach((vertexInterval, vId) => {
      let change = changes.get(vId)
      if (!change) {
        change = { id: vId }
        changes.set(vId, change)
      }

      if (vertexInterval.x)  {
        change.x = this._getInterpolateData(workingTime, vertexInterval.x)
      }
      if (vertexInterval.y)  {
        change.y = this._getInterpolateData(workingTime, vertexInterval.y)
      }
      if (vertexInterval.mirror)  {
        change.mirror = this._getInstantChangeData(workingTime, vertexInterval.mirror)
      }
    })

    return [...changes.values()]
  }

  updateComputedData(time) {
    const updateData = this.getAnimateData(time)
    this.elementStack.element.updateVerticesPosition(updateData, !this.manager.cache.isProcessingChangeEvent)
  }

  resetComputedData() {
    const base = this.getBaseValue()
    const changes = []
    for (const [id, v] of base) {
      changes.push({
        id,
        x: v.pos[0],
        y: v.pos[1],
        mirror: v.mirror
      })
    }
    this.elementStack.element.updateVerticesPosition(changes)
  }
}

export default PathMorphingStack
