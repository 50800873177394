import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ElementType } from '@phase-software/types'

import { useEditor, useEditorActions } from '../../../../providers/dataStore/EditorProvider'
import { RadioButtonGroup, WideTitle } from '../../../shared'
import Content from '../../../shared/Content'
import PropertyRow from '../PropertyRow'

const OVERFLOW_OPTIONS = [
  { name: 'show', value: 'Show' },
  { name: 'hide', value: 'Hide' }
]

export const Overflow = () => {
  const elementType = useEditor((o) => o.elementType)
  const overflow = useEditor((o) => (o.overflowX ? 'Hide' : 'Show'))
  const { setProperties } = useEditorActions()
  const { t } = useTranslation('file', { keyPrefix: 'property_editor.overflow' })

  const onChange = useCallback(
    (value: string) => {
      const result = value === 'Hide'
      setProperties({
        overflowX: result,
        overflowY: result
      })
    },
    [setProperties]
  )
  const radioButtonOptions = useMemo(
    () =>
      OVERFLOW_OPTIONS.map((option) => ({
        key: option.value,
        name: t(option.name),
        value: option.value
      })),
    [t]
  )
  const radioButtonChildRenderer = useCallback((option: (typeof radioButtonOptions)[number]) => option.name, [])

  if (elementType === ElementType.SCREEN || elementType === ElementType.CONTAINER) {
    return (
      <>
        <WideTitle dividerPosition="top">{t('title')}</WideTitle>
        <Content withHeader>
          <PropertyRow columns={[208]}>
            <RadioButtonGroup
              options={radioButtonOptions}
              selectedValue={overflow}
              onSelectionChange={onChange}
              renderChild={radioButtonChildRenderer}
            />
          </PropertyRow>
        </Content>
      </>
    )
  }
  return null
}
