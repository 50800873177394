import { LayerComponentType, LayerType } from '@phase-software/types'
import LayerComponent from './LayerComponent'

/** @typedef {import('../DataStore').DataStore} DataStore */

export default class ShadowComponent extends LayerComponent {
    /**
     * @param {DataStore} dataStore
     * @param {ShadowComponentData} [data]
     * @param {object} [options] 
     * @param {bool} [options.regenId=false]   if set to true, will generate new ID
     */
    constructor(dataStore, data = {}, options) {
        super(dataStore, data, options)

        this.componentType = LayerComponentType.SHADOW

        if (!this.name || this.name === 'layer') {
            this.name = 'shadow'
        }
        if (!this.layerType || this.layerType === 'LAYER') {
            this.layerType = LayerType.SHADOW
        }
    }
}

/** @typedef {import('./LayerComponent').LayerComponentData} LayerComponentData */
/** @typedef {(import('../layer/Shadow').ShadowData)} ShadowData */

/**
 * @typedef {LayerComponentData} ShadowComponentData
 * @property {ShadowData[]} layers
 */
