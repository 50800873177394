import { schema } from 'normalizr'
import { createProvider, useEntity } from '../utils'

export const Font = new schema.Entity(
  'fonts',
  { data: {} },
  {
    processStrategy: (value, parent) => {
      return { ...value, styleId: parent.id }
    }
  }
)

const defaultValue = {}
const [Provider, useSelectState, useSetState] = createProvider('Font', defaultValue)

export const useFont = useSelectState

export const useSetFont = () => {
  const setState = useSetState()
  const [setFont, addFont, updateFont, removeFont, mergeFont, reduceFont, createFont, destroyFont] = useEntity(
    Font,
    setState
  )

  return {
    setFont,
    addFont,
    updateFont,
    removeFont,
    mergeFont,
    reduceFont,
    createFont,
    destroyFont
  }
}

export default Provider
