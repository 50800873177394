import React from 'react'
import { NavLink } from 'react-router-dom'
import Icon from '../Icon'

type NavigationItemProps = {
  to: string
  children: React.ReactNode
  iconName?: string
  dataTestId?: string
  className?: string
  onContextMenu?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  onMouseEnter?: () => void
}

const NavigationItem = ({
  to,
  iconName,
  dataTestId = 'page-link',
  className = '',
  children,
  onContextMenu,
  onMouseEnter
}: NavigationItemProps) => {
  const handleContextMenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault()
    onContextMenu && onContextMenu(e)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    e.stopPropagation()
    const link = e.currentTarget.querySelector('a') as HTMLElement
    switch (e.key) {
      case 'Escape':
        if (link) {
          link.blur()
        }
        break
      case ' ':
        if (link) {
          link.click()
        }
        break
    }
  }

  return (
    <div
      className={`group px-8 py-2 bg-neutral-90 ${className}`}
      onContextMenu={handleContextMenu}
      onMouseEnter={onMouseEnter}
      onKeyDown={handleKeyDown}
    >
      <NavLink
        to={to}
        className={(isActive) =>
          `flex items-center rounded-md relative
          highlight-border-rounded-a
          ${
            isActive
              ? 'text-white bg-primary-50'
              : 'text-light-overlay-60 group-active:text-light-overlay-40 group-active:bg-neutral-80 group-hover:text-white group-hover:bg-neutral-80'
          }`
        }
        data-test-id={dataTestId}
      >
        <div className="flex items-center w-full pl-18 pr-16 py-4 relative gap-16">
          {iconName && <Icon name={iconName} size="xxl" interactive={false} useCurrentColor />}
          <div className="truncate text-13 font-medium">{children}</div>
        </div>
      </NavLink>
    </div>
  )
}

export default NavigationItem
