import { LayerType } from '@phase-software/types'
import { isNum } from '@phase-software/data-utils'
import PaintComponent from '../component/PaintComponent'
import Layer from './Layer'


/** @typedef {import('../component/PropertyComponent').AppliedRef} AppliedRef */


const PROP_NAMES = ['paintId', 'offsetX', 'offsetY', 'blur', 'spread']

const PROP_NAME_TO_COMPONENT_MAP = {
    paint: PaintComponent
}


export default class Shadow extends Layer {
    /**
     * @param {DataStore} dataStore 
     * @param {LayerData} data
     * @param {object} [options] 
     * @param {bool} [options.regenId=false]   if set to true, will generate new ID
     */
    constructor(dataStore, data, options) {
        super(dataStore, data, options)
    }

    _init() {
        super._init()
        this.layerType = LayerType.SHADOW

        PROP_NAMES.forEach(v => this._propNames.add(v))
        this._propClasses = { ...this._propClasses, ...PROP_NAME_TO_COMPONENT_MAP }
        this._subComponentIds = { paint: 'paintId' }
        this._subComponentPropMap = { paintId: 'paint' }
        this._props.paintId = ''
        this._props.offsetX = 0
        this._props.offsetY = 4
        this._props.blur = 4
        this._props.spread = 0
    }

    /**
     * @param {ShadowData} data
     * @param {object} [options] 
     * @param {bool} [options.regenId=false]   if set to true, will generate new ID
     */
    load(data, options) {
        super.load(data, options)
        Object.keys(data).forEach((propName) => {
            if (PROP_NAMES.includes(propName)) {
                this._props[propName] = data[propName]
            }
        })
    }

    /**
     * Clones Layer and applies any inner sharable PropertyComponents to element+style+itself
     * Override this in subclasses
     * CALL super.clone() at the top of overriden method
     * @param {AppliedRef} ref
     * @returns {Shadow}
     */
    clone(ref) {
        const obj = super.clone(ref)
        PROP_NAMES.forEach((propName) => {
            obj._props[propName] = this._props[propName]
        })
        return obj
    }

    /**
     * @returns {ShadowData} data
     */
    save() {
        const data = super.save()
        PROP_NAMES.forEach((propName) => {
            data[propName] = this._props[propName]
        })
        return data
    }

    /**
     * Use it to pass on apply calls to inner properties (like Paint)
     * Override this in subclasses if subclass has any inner sharable PropertyComponents
     * CALL super.apply() at the top of overriden method
     * @param {AppliedRef} ref
     */
    // eslint-disable-next-line no-unused-vars
    apply(ref) {
        super.apply(ref)
        this.paint.apply({ ...ref, layerId: this.id })
    }

    /**
     * Use it to pass on cancel calls to inner properties (like Paint)
     * Override this in subclasses if subclass has any inner sharable PropertyComponents
     * CALL super.cancel() at the top of overriden method
     * @param {AppliedRef} ref
     */
    // eslint-disable-next-line no-unused-vars
    cancel(ref) {
        super.cancel(ref)
        this.paint.cancel({ ...ref, layerId: this.id })
    }

    get paint() {
        return this.dataStore.library.getComponent(this._props.paintId)
    }

    set paint(data) {
        this.setProperty('paint', data)
    }

    get paintId() {
        return this._props.paintId
    }

    set paintId(data) {
        this.setProperty('paintId', data)
    }

    get offsetX() {
        return this._props.offsetX
    }

    set offsetX(data) {
        this.setProperty('offsetX', data)
    }

    get offsetY() {
        return this._props.offsetY
    }

    set offsetY(data) {
        this.setProperty('offsetY', data)
    }

    get blur() {
        return this._props.blur
    }

    set blur(data) {
        this.setProperty('blur', data)
    }

    get spread() {
        return this._props.spread
    }

    set spread(data) {
        this.setProperty('spread', data)
    }

    _typeCheck(propName, data) {
        switch (propName) {
            case 'offsetX':
                return isNum(data)
            case 'offsetY':
                return isNum(data)
            case 'blur':
                return isNum(data)
            case 'spread':
                return isNum(data)
            default:
                return super._typeCheck(propName, data)
        }
    }
}

/** @typedef {import('./Layer').LayerData} LayerData */
/** @typedef {import('../component/PaintComponent')} PaintComponent */

/**
 * @typedef {LayerData} ShadowData
 * @property {PaintComponent} paint
 * @property {Vector2} offset
 * @property {number} blur
 * @property {number} spread
 */
