import { LayerListKey, LayerListKeyList } from '@phase-software/data-store'
import { EasingType, PaintType } from '@phase-software/types'

import { MenuListOptionProps } from '../shared/Menu/Menu.types'

// Layout
export const TRACK_INFO_WIDTH = 248
export const EASING_INFO_WIDTH = 248
export const TRACK_PADDING_X = 16
export const PLAYHEAD_WIDTH = 9
export const MIN_TICK_WIDTH = 100
export const MAX_TICK_WIDTH = MIN_TICK_WIDTH * 2.5
export const SCALE_FACTOR = MIN_TICK_WIDTH / MAX_TICK_WIDTH
export const TICKS = [100, 200, 500, 1000, 2000, 5000, 10000]

// keyframe
export const MINIMAL_MOVEMENT = 4

export const easingTypeNameMap = {
  [EasingType.STEP_END]: 'step',
  [EasingType.LINEAR]: 'linear',
  [EasingType.EASE_IN]: 'ease_in',
  [EasingType.EASE_OUT]: 'ease_out',
  [EasingType.EASE_IN_OUT]: 'ease_in_out',
  [EasingType.EASE_IN_BACK]: 'ease_in_back',
  [EasingType.EASE_OUT_BACK]: 'ease_out_back',
  [EasingType.EASE_IN_OUT_BACK]: 'ease_in_out_back',
  [EasingType.CUSTOM]: 'custom',
  Mix: 'mix'
}

const EASE_IN_OPTION = {
  name: easingTypeNameMap[EasingType.EASE_IN],
  value: EasingType.EASE_IN,
  icon: 'EaseIn'
}

const EASE_OUT_OPTION = {
  name: easingTypeNameMap[EasingType.EASE_OUT],
  value: EasingType.EASE_OUT,
  icon: 'EaseOut'
}

const EASE_IN_OUT_OPTION = {
  name: easingTypeNameMap[EasingType.EASE_IN_OUT],
  value: EasingType.EASE_IN_OUT,
  icon: 'EaseInOut'
}

export const EASING_TYPE_OPTIONS: MenuListOptionProps[] = [
  {
    name: easingTypeNameMap[EasingType.STEP_END],
    value: EasingType.STEP_END,
    icon: 'Step'
  },
  '-',
  {
    name: easingTypeNameMap[EasingType.LINEAR],
    value: EasingType.LINEAR,
    icon: 'Linear'
  },
  '-',
  EASE_IN_OPTION,
  EASE_OUT_OPTION,
  EASE_IN_OUT_OPTION,
  {
    name: easingTypeNameMap[EasingType.EASE_IN_BACK],
    value: EasingType.EASE_IN_BACK,
    icon: 'EaseInBack'
  },
  {
    name: easingTypeNameMap[EasingType.EASE_OUT_BACK],
    value: EasingType.EASE_OUT_BACK,
    icon: 'EaseOutBack'
  },
  {
    name: easingTypeNameMap[EasingType.EASE_IN_OUT_BACK],
    value: EasingType.EASE_IN_OUT_BACK,
    icon: 'EaseInOutBack'
  }
]

export const CUSTOM_EASING_OPTIONS: MenuListOptionProps[] = [
  ...EASING_TYPE_OPTIONS,
  '-',
  {
    name: easingTypeNameMap[EasingType.CUSTOM],
    value: EasingType.CUSTOM,
    icon: 'EaseCustom'
  }
]

export const COMMON_EASING_TYPE_OPTIONS = [EASE_IN_OPTION, EASE_OUT_OPTION, EASE_IN_OUT_OPTION]

// NOTE: should not change the ordering of property
export const PROPERTY_LIST = [
  'motionPath',
  'dimensions',
  'pathMorphing',
  'rotation',
  'cornerRadius',
  'contentAnchor',
  'scale',
  'skew',
  'blendMode',
  'opacity',
  ...LayerListKeyList,
  'blurs',
  'trimPath'
]

export const effectPropertyNameMap: Record<string, string> = {
  trimPath: 'trim_path',
  'trimPath.start': 'start',
  'trimPath.end': 'end',
  'trimPath.offset': 'offset'
}

export const layerPropertyNameMap: Record<string, string> = {
  // general layer
  blendMode: 'blend',
  opacity: 'opacity',

  // stroke
  cap: 'cap_shape',
  capSize: 'cap_size',
  dash: 'dash',
  ends: 'ends',
  gap: 'gap',
  growDirection: 'grow_direction',
  join: 'join_shape',
  joinSize: 'join_size',
  miter: 'miter_angle',
  offset: 'offset',
  width: 'border_width',

  // shadow
  blur: 'blur',
  offsetX: 'offset_x',
  offsetY: 'offset_y',
  spread: 'spread'
}

export const paintTypeNameMap: Record<PaintType, string> = {
  [PaintType.SOLID]: 'color',
  [PaintType.IMAGE]: 'image',
  [PaintType.GRADIENT_LINEAR]: 'linear_gradient',
  [PaintType.GRADIENT_RADIAL]: 'radial_gradient',
  [PaintType.GRADIENT_ANGULAR]: 'angular_gradient',
  [PaintType.GRADIENT_DIAMOND]: 'diamond_gradient'
}

export const propertyNameMap: Record<string, string> = {
  blendMode: 'blend',
  blurGaussian: 'amount', // TODO: Gaussian blur
  blurs: 'blurs',
  cornerRadius: 'corner_radius',
  dimensions: 'size',
  [LayerListKey.FILL]: 'fills',
  height: 'height',
  [LayerListKey.INNER_SHADOW]: 'inner_shadow',
  opacity: 'opacity',
  contentAnchor: 'origin',
  pathMorphing: 'path_shape',
  motionPath: 'position',
  position: 'position',
  rotation: 'rotation',
  scale: 'scale',
  scaleX: 'scale_x',
  scaleY: 'scale_y',
  [LayerListKey.SHADOW]: 'drop_shadows',
  skew: 'skew',
  skewX: 'skew_x',
  skewY: 'skew_y',
  [LayerListKey.STROKE]: 'borders',
  width: 'width',
  x: 'x',
  y: 'y'
}

export const PropertyInspectionList = [
  'position',
  'motionPath',
  'width',
  'height',
  'rotation',
  'cornerRadius',
  'contentAnchor',
  'scaleX',
  'scaleY',
  'skewX',
  'skewY',
  'opacity',
  'trimPath.start',
  'trimPath.end',
  'trimPath.offset',
  'pathMorphing',
  'blendMode',
  ...LayerListKeyList
]

export const SNAP_RATIO = 0.02
