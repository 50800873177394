import React, { createContext, useContext, useState, useCallback, useMemo } from 'react'
import { useElementSelection } from './dataStore/ElementSelectionProvider'

interface PanelStates {
  [elementId: string]: {
    [panelId: string]: boolean;
  };
}

type RightPanelProviderContextType = {
  inspectPanelSectionStates: any
  toggleInspectPanelSection: (panelId: string) => void
}

type RightPanelProviderProviderProps = {
  children: React.ReactNode
}

const defaultValue = {
  inspectPanelSectionStates: {},
  toggleInspectPanelSection: () => { }
}

export function useInspectPanelSectionState(panelId: string) {
  const { inspectPanelSectionStates } = useRightPanelContext();
  const selection = useElementSelection();
  const elementId = selection[0];

  return useMemo(() => {
    return elementId && panelId && inspectPanelSectionStates[elementId] ? !!inspectPanelSectionStates[elementId][panelId] : false;
  }, [inspectPanelSectionStates, elementId, panelId]);
}

const RightPanelProviderContext = createContext<RightPanelProviderContextType>(defaultValue)

const RightPanelProviderProvider = ({ children }: RightPanelProviderProviderProps) => {
  const [inspectPanelSectionStates, setInspectPanelSectionStates] = useState<PanelStates>({})
  const selection = useElementSelection()
  const elementId = selection[0]


  const toggleInspectPanelSection = useCallback((panelId: string) => {
    setInspectPanelSectionStates(prev => ({
      ...prev,
      [elementId]: {
        ...prev[elementId],
        [panelId]: !(prev[elementId] && prev[elementId][panelId])
      }
    }));
  }, [elementId, setInspectPanelSectionStates]);

  return (
    <RightPanelProviderContext.Provider
      value={{
        inspectPanelSectionStates,
        toggleInspectPanelSection
      }}
    >
      {children}
    </RightPanelProviderContext.Provider>
  )
}

function useRightPanelContext(): RightPanelProviderContextType {
  const context = useContext(RightPanelProviderContext)
  if (context === undefined) {
    throw new Error('useRightPanelContext must be used within a RightPanelProvider')
  }
  return context
}

export { RightPanelProviderProvider, useRightPanelContext }
