import { Events, Hover } from '@phase-software/data-store'
import { Vector2, Rect2 } from '../math'
import * as ScrollbarUI from '../panes/scrollbar'

const SCROLLBAR = {
    size: 20,
    alpha: 0.75,
    fade: {
        duration: 200,
        ease: 'easeInOutSine',
        wait: 3000
    },
    minSize: 30
}

/** @typedef {import('../visual_server/VisualServer').VisualServer} VisualServer */
/** @typedef {import('../Viewport').Viewport} Viewport */
/** @typedef {import('../../../data-store').DataStore} DataStore */
/** @typedef {import('./').SetLocalCursorStateFn} SetLocalCursorStateFn */

/** @typedef {'x'|'y'|null} ScrollbarType */

/** @type {ScrollbarType} */
let _hovering = null
/** @type {SetLocalCursorStateFn} */
let _setLocalCursorState
/** @type {number} */
let _scrollbarAlpha = 0.0
/**
 * The scrollbar on right side has fixed position on x-axis and fixed width
 * The scrollbar on the bottom has fixed position on y-axis and fixed height
 * @private
 * */
const _scrollBarRect = new Rect2()

/**
 * whether any scrollbar is visible (ie, the scaled world is larger than the screen)
 * @param {Rect2} bounds
 * @param {Viewport} viewport
 * @private
 */
function _updateScrollbarRect( bounds, viewport = {} ){
    // calculate the horizontal scrollbar
    let left = -((viewport.x / viewport.scale) + bounds.x) * viewport.width / bounds.width
    // calculate the vertical scrollbar
    let top = -((viewport.y / viewport.scale) + bounds.y) * viewport.height / bounds.height

    const _width = viewport.width * (viewport.width / (viewport.scale * bounds.width))
    const _height = viewport.height * (viewport.height / (viewport.scale * bounds.height))

    const width = Math.max(_width, SCROLLBAR.minSize)
    const height = Math.max(_height, SCROLLBAR.minSize)

    left -= Math.max(0, width - _width) * 0.5
    top -= Math.max(0, height - _height) * 0.5

    _scrollBarRect.set( left, top, width, height )
}
/**
 * whether any scrollbar is visible (ie, the scaled world is larger than the screen)
 * @param {Viewport} viewport
 * @returns {boolean}
 */
function shouldBeVisible( viewport){
    return Math.round( _scrollBarRect.width ) < viewport.width || Math.round( _scrollBarRect.height ) < viewport.height
}

/**
 * @param {VisualServer} visualServer
 * @param {import('./').SetLocalCursorStateFn} setLocalCursorState
 */
export function initScrollbar(visualServer, setLocalCursorState) {
    const { viewport, dataStore } = visualServer
    _setLocalCursorState = setLocalCursorState

    ScrollbarUI.setScrollbar(_scrollBarRect)

    dataStore.eam.on(Events.HOVER_SCROLLBAR, (e) => {
        e.handled = false

        _hovering = null

        if (shouldBeVisible(viewport)) {
            const { x, y } = e.mousePos
            const { left, right, bottom, top } = _scrollBarRect

            const size = SCROLLBAR.size
            if (viewport.width - size < x && x < viewport.width && top < y && y < bottom) {
                _hovering = 'y'
            } else if (viewport.height - size < y && y < viewport.height && left < x && x < right) {
                _hovering = 'x'
            }

            fadeInAndScheduleFadeOut()
        }
        if (_hovering) {
            dataStore.eam.changeHover(Hover.SCROLLBAR)
        } else {
            dataStore.eam.changeHover(dataStore.selection.get('hover') ? Hover.ELEMENT : Hover.NONE)
        }

        ScrollbarUI.setActiveScrollbar(_hovering)
    })

    const _lastMousePos = new Vector2()
    dataStore.eam
        .on(Events.START_MOVE_SCROLLBAR, (e) => {
            if (!_hovering) {
                e.handled = false
                return
            }
            _lastMousePos.copy(e.mousePos)
            _setLocalCursorState('grabbing')

            cancelLastFadeOut()
        })
        .on(Events.UPDATE_MOVE_SCROLLBAR, ({ mousePos }) => {
            const d = _lastMousePos.sub(mousePos)

            const bounds = visualServer.root.bounds.world

            if (_hovering === 'y') {
                d.x = 0.0
                d.y = d.y * bounds.height * viewport.scale / viewport.height
            } else {
                d.x = d.x * bounds.width * viewport.scale / viewport.width
                d.y = 0.0
            }

            viewport.offsetPos(d)
            _lastMousePos.copy(mousePos)
        })
        .on(Events.END_MOVE_SCROLLBAR, () => {
            _setLocalCursorState('default')

            scheduleFadeOut()
        })

    viewport.on('update', () => {
        const bounds = visualServer?.root?.bounds?.world

        _updateScrollbarRect(bounds, viewport)

        // FIXME: Need to have a way to know if is in MOVE_SCROLLBAR
        // if (IS.get('MOVE_SCROLLBAR').pressed) return

        if (shouldBeVisible(viewport)) fadeInAndScheduleFadeOut()
    })
}

/** @type {number} @private */
let _timeoutID

function cancelLastFadeOut() {
    if (_timeoutID) window.clearTimeout(_timeoutID)
}

/** @param {number} [delay] */
function scheduleFadeOut(delay = 0) {
    cancelLastFadeOut()
    _timeoutID = window.setTimeout(_fadeOut, SCROLLBAR.fade.wait + delay)
}

function fadeInAndScheduleFadeOut() {
    _fadeIn()
    scheduleFadeOut(SCROLLBAR.fade.duration)
}

class LocalAnimator{
    constructor(){
        this._easings = new Map()
        // Ease in/out
        this._easings.set('easeInOutSine',
            function(progress) {
                return progress < 0.5 ?
                    (1.0 - Math.cos(progress * Math.PI)) * 0.5 :
                    (Math.sin((progress - 0.5) * Math.PI)) * 0.5 + 0.5
            })
        this._animating = false
        this._reverse = false
        this._doAnimationHandler = this._doAnimation.bind(this)
    }
    animate(duration, easing, callback, reverse = false){
        this._duration = duration
        this._easing = this._easings.get(easing)
        this._callback = callback
        this._reverse = reverse
        this._startTime = -1.0
        if (!this._animating){
            this._animating = true
            window.requestAnimationFrame( this._doAnimationHandler )
        }
    }
    _doAnimation( timestamp ){
        this._animating = false
        // Run animation callbacks
        if ( this._startTime < 0.0 ) this._startTime = timestamp
        let progress = Math.min (( timestamp - this._startTime ) / this._duration, 1.0 )
        if (this._reverse) progress = 1 - progress
        const alpha = this._easing(progress)
        if (this._reverse) progress = 1 - progress
        const shouldBeAnimating = this._callback(alpha, progress, this._duration) && progress < 1.0
        if (!this._animating && shouldBeAnimating){
            this._animating = true
            window.requestAnimationFrame( this._doAnimationHandler )
        }
    }
}
/** @private */
const _fadeInAnimator = new LocalAnimator()
/** @private */
const _fadeOutAnimator = new LocalAnimator()


function _fadeIn(){
    const start = _scrollbarAlpha
    const target = SCROLLBAR.alpha
    if (!_fadeInAnimator._animating){
        _fadeInAnimator.animate (
            SCROLLBAR.fade.duration,
            SCROLLBAR.fade.ease,
            ( alpha ) => {
                _scrollbarAlpha = (target * alpha) + (start * (1 - alpha))
                ScrollbarUI.setAlpha(_scrollbarAlpha)
                return true
            }
        )
    }
}

function _fadeOut(){
    const start = _scrollbarAlpha
    const target = 0
    _fadeOutAnimator.animate (
        SCROLLBAR.fade.duration,
        SCROLLBAR.fade.ease,
        ( alpha ) => {
            _scrollbarAlpha = (target * alpha) + (start * (1 - alpha))
            ScrollbarUI.setAlpha(_scrollbarAlpha)
            return true
        }
    )
}
