import { useCallback } from 'react'
import { createProvider } from '../utils'

const [Provider, useSelectState, useSetState] = createProvider('KeyframeSelection', [])

export const useKeyframeSelection = useSelectState

export const useSetKeyframeSelection = () => {
  const setState = useSetState()
  const setKeyframeSelection = useCallback((selection) => setState(selection), [setState])
  return { setKeyframeSelection }
}

export default Provider
