import PropertyStack from '../PropertyStack'

class BlendModeStack extends PropertyStack {
  constructor(elementStack, data) {
    super(elementStack, data)
    this.type = 'BLEND_MODE'
    this.key = 'blendMode'
    this.dataKey = 'blendMode'
    this.animatableProperties = new Set()
  }
}

export default BlendModeStack
