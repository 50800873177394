import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useApolloClient } from '@apollo/client'

import { Dropdown, Icon, Select, Table } from '../../components/shared'
import { MenuListOptionProps, MenuOptionProps } from '../../components/shared/Menu/Menu.types'
import { translateMenuOptions } from '../../components/shared/Menu/utils'
import { SortOrder } from '../../components/shared/Table'
import { TableUser } from '../../components/shared/Table/TableUser'
import { TEAM_ROLE_OPTIONS } from '../../constants/teamConstants'
import { UpdateInvitationRoleRequestRoleEnum } from '../../generated/api'
import {
  TeamUserFieldsFragment,
  TeamUserInvitationFieldsFragment,
  TeamUserInvitationFieldsFragmentDoc
} from '../../generated/graphql'
import useHeapAnalytics from '../../hooks/useHeapAnalytics'
import useTeamMemberActions, { UpdateTeamInvitationRoleParams } from '../../hooks/useTeamMemberActions'
import { useSetNotification } from '../../providers/NotificationProvider'
import { useWorkspaceContext } from '../../providers/WorkspaceContextProvider'
import { track } from '../../services/heapAnalytics'

const DROPDOWN_OPTIONS: MenuListOptionProps[] = [
  {
    name: 'resend_invitation',
    value: 'resend-invitation',
    dataTestId: 'member-resend-invitation'
  },
  '-',
  {
    name: 'cancel_invitation',
    value: 'cancel-invitation',
    dataTestId: 'member-cancel-invitation'
  }
]

const TeamMembersInvitations = ({
  teamId,
  data
}: {
  teamId: TeamUserFieldsFragment['team_id']
  data: TeamUserInvitationFieldsFragment[]
}) => {
  const { t } = useTranslation('setting')
  const { updateTeamInvitationRole, resendInvitation, cancelInvitation } = useTeamMemberActions()
  const { addNotification } = useSetNotification()
  const { teamName } = useHeapAnalytics()
  const { workspaceData } = useWorkspaceContext()

  const handleResendInvitation = useCallback(
    async ({ invitationId, role }: Pick<UpdateTeamInvitationRoleParams, 'invitationId' | 'role'>) => {
      try {
        await resendInvitation({ teamId, invitationId })

        addNotification({
          type: 'success',
          content: t('team_setting.invitation_resent')
        })
        track('Invitation Resent', { teamName, userRole: role })
      } catch (error) {
        console.error(error)
      }
    },
    [addNotification, teamId, resendInvitation, teamName, t]
  )

  const handleOptionSelect = useCallback(
    async ({ option, invitation }: { invitation: TeamUserInvitationFieldsFragment; option: MenuOptionProps }) => {
      switch (option?.value) {
        case 'resend-invitation':
          await handleResendInvitation({
            invitationId: invitation.id,
            role: invitation.team_role as UpdateInvitationRoleRequestRoleEnum
          })
          break
        case 'cancel-invitation':
          try {
            await cancelInvitation({ teamId: workspaceData.id, invitationId: invitation.id })
            addNotification({
              type: 'success',
              content: (
                <>
                  Invitation for <span className="font-semibold">{invitation.email}</span> has been cancelled
                </>
              )
            })
            track('Invitation Canceled', { teamName, userRole: invitation.team_role })
          } catch (error) {
            console.error(error)
          }
          break
      }
    },
    [handleResendInvitation, addNotification, teamName, workspaceData.id, cancelInvitation]
  )
  const client = useApolloClient()

  const handleUpdateRole = useCallback(
    async ({ invitationId, role }: Omit<UpdateTeamInvitationRoleParams, 'teamId'>) => {
      try {
        await updateTeamInvitationRole({ teamId, invitationId, role })
        await client.cache.updateFragment(
          {
            id: `team_user_invitations:${invitationId}`,
            fragment: TeamUserInvitationFieldsFragmentDoc,
            fragmentName: 'teamUserInvitationFields'
          },
          (data) => ({ ...data, team_role: role })
        )

        addNotification({
          type: 'success',
          content: t('team_setting.roles_has_been_updated')
        })
      } catch (error) {
        console.error(error)
      }
    },
    [addNotification, teamId, updateTeamInvitationRole, client, t]
  )

  const columns = useMemo(
    () => [
      {
        title: t('team_setting.name'),
        key: 'name',
        render: (invitation: TeamUserInvitationFieldsFragment) => (
          <TableUser name={invitation.email ?? ''} tips={t('pending')} />
        ),
        sorter: (a: TeamUserInvitationFieldsFragment, b: TeamUserInvitationFieldsFragment) =>
          a.email?.localeCompare(b.email ?? '') ?? 0,
        sortOrder: SortOrder.ASCEND,
        flex: 1
      },
      {
        title: t('team_setting.role'),
        key: 'role',
        sorter: (a: TeamUserInvitationFieldsFragment, b: TeamUserInvitationFieldsFragment) =>
          a.team_role.localeCompare(b.team_role),
        render: (invitation: TeamUserInvitationFieldsFragment) => (
          <Select
            // @ts-ignore TODO: fix after refactor of Select
            value={invitation.team_role}
            options={translateMenuOptions(t, TEAM_ROLE_OPTIONS, {
              ns: 'setting',
              keyPrefix: 'team_setting'
            })}
            hideFocusStyle
            onChange={(value: string) =>
              handleUpdateRole({ role: value as UpdateInvitationRoleRequestRoleEnum, invitationId: invitation.id })
            }
          />
        )
      },
      {
        title: '',
        key: 'action',
        width: 48,
        render: (invitation: TeamUserInvitationFieldsFragment) => (
          <Dropdown
            menu={{
              options: translateMenuOptions(t, DROPDOWN_OPTIONS, {
                ns: 'setting',
                keyPrefix: 'team_setting'
              }),
              onSelect: (option) => handleOptionSelect({ invitation, option })
            }}
          >
            <Icon name="More" size="s" />
          </Dropdown>
        )
      }
    ],
    [handleOptionSelect, handleUpdateRole, t]
  )

  const translatedData = useMemo(
    () =>
      data.map((invitation) => ({
        ...invitation,
        team_role: t(`team_setting:${invitation.team_role}`)
      })),
    [data, t]
  )

  return <Table dataSource={translatedData} columns={columns} rowKey="id" fixedHeader={true} />
}

export default TeamMembersInvitations
