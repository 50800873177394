import { useCallback } from 'react'
import { schema } from 'normalizr'
import { createProvider, useEntity } from '../utils'

export const Paint = new schema.Entity('paints', {
  processStrategy: (value, parent) => {
    return { ...value, layerId: parent.id }
  }
})

const defaultValue = {}
const [Provider, useSelectState, useSetState] = createProvider('Paint', defaultValue)

export const usePaint = useSelectState

export const useSetPaint = () => {
  const setState = useSetState()
  const [setPaint, addPaint, updatePaint, removePaint, mergePaint, reducePaint, create, destroy] = useEntity(
    Paint,
    setState
  )
  const createPaint = useCallback(
    (entities) => {
      create(entities)
    },
    [create]
  )

  const destroyPaint = useCallback(
    (entities) => {
      destroy(entities)
    },
    [destroy]
  )

  return {
    setPaint,
    addPaint,
    updatePaint,
    removePaint,
    mergePaint,
    reducePaint,
    createPaint,
    destroyPaint
  }
}

export default Provider
