import React from 'react'

type EmptyProps = {
  image: { src: string; width: number; height: number }
  title: string
  description: string
}
const Empty = ({ image, title, description }: EmptyProps) => {
  return (
    <div className="m-auto text-light-overlay-60 px-64">
      <div className="flex flex-col items-center">
        {image && <img className="mb-32" src={image.src} width={image.width} height={image.height} />}
        <div className="text-h3 font-semibold mb-16">{title}</div>
        <div className="text-14">{description}</div>
      </div>
    </div>
  )
}

export default Empty
