import { AllPermissionEnum } from '../components/Share/shareTypes'
import {
  CreateProjectCollaboratorInvitationRequestPermissionEnum,
  PatchProjectMemberRequestPermissionEnum
} from '../generated/api'
import { ProjectFieldsFragment, TeamFieldsFragment } from '../generated/graphql'
import { useWorkspaceContext } from '../providers/WorkspaceContextProvider'
import { projectCollaboratorService, projectMemberService, projectService } from '../services/api'

const useShareTeamProjectActions = () => {
  const { workspaceData } = useWorkspaceContext()

  const changeTeamProjectDefaultPermission = ({
    projectId,
    permission
  }: {
    projectId: ProjectFieldsFragment['id']
    permission: AllPermissionEnum
  }) =>
    projectService.projectApiControllerPatchTeamProject({
      teamId: workspaceData.id,
      projectId,
      // @ts-ignore
      patchProjectRequest: { permission, updateMask: ['permission'] }
    })

  const changeTeamProjectCollaboratorPermission = ({
    projectId,
    memberId,
    permission
  }: {
    projectId: ProjectFieldsFragment['id']
    memberId: string
    permission: PatchProjectMemberRequestPermissionEnum
  }) =>
    projectMemberService.projectMemberApiControllerPatchTeamProjectMember({
      teamId: workspaceData.id,
      projectId,
      memberId,
      patchProjectMemberRequest: {
        permission,
        updateMask: ['permission']
      }
    })

  const removeTeamProjectCollaborator = ({
    projectId,
    memberId
  }: {
    projectId: ProjectFieldsFragment['id']
    memberId: string
  }) =>
    projectMemberService.projectMemberApiControllerDeleteTeamProjectMember({
      teamId: workspaceData.id,
      projectId,
      memberId
    })

  const sendTeamProjectInvitation = ({
    collaboratorId,
    email,
    permission,
    projectId
  }: {
    collaboratorId?: string
    email: string
    permission: CreateProjectCollaboratorInvitationRequestPermissionEnum
    projectId: ProjectFieldsFragment['id']
  }) =>
    projectCollaboratorService.projectCollaboratorControllerCreateTeamProjectCollaboratorInvitation({
      teamId: workspaceData.id,
      projectId,
      createProjectCollaboratorInvitationRequest: {
        collaboratorId,
        email,
        permission
      }
    })

  const resendTeamProjectInvitation = ({
    projectId,
    invitationId
  }: {
    projectId: ProjectFieldsFragment['id']
    invitationId: string
  }) =>
    projectCollaboratorService.projectCollaboratorControllerResendTeamProjectCollaboratorInviteEmail({
      teamId: workspaceData.id,
      projectId,
      invitationId
    })

  const changeTeamProjectInvitationPermission = ({
    projectId,
    invitationId,
    permission
  }: {
    projectId: ProjectFieldsFragment['id']
    invitationId: string
    permission: PatchProjectMemberRequestPermissionEnum
  }) =>
    projectCollaboratorService.projectCollaboratorControllerUpdatePermissionOfTeamProjectCollaboratorInvitation({
      teamId: workspaceData.id,
      projectId,
      invitationId,
      updateProjectInvitationRequest: {
        permission
      }
    })

  const cancelTeamProjectInvitation = ({
    projectId,
    invitationId
  }: {
    projectId: ProjectFieldsFragment['id']
    invitationId: string
  }) =>
    projectCollaboratorService.projectCollaboratorControllerRemoveTeamProjectCollaboratorInvite({
      teamId: workspaceData.id,
      projectId,
      invitationId
    })

  const joinTeamProjectCollaborator = ({
    teamId,
    projectId,
    invitationId
  }: {
    teamId: TeamFieldsFragment['id']
    projectId: ProjectFieldsFragment['id']
    invitationId: string
  }) =>
    projectCollaboratorService.projectCollaboratorControllerAcceptTeamProjectCollaboratorInvite({
      teamId,
      projectId,
      invitationId
    })

  return {
    changeTeamProjectDefaultPermission,
    changeTeamProjectCollaboratorPermission,
    removeTeamProjectCollaborator,

    sendTeamProjectInvitation,
    resendTeamProjectInvitation,
    changeTeamProjectInvitationPermission,
    cancelTeamProjectInvitation,

    joinTeamProjectCollaborator
  }
}

export default useShareTeamProjectActions
