import { PaintType, LayerType, FrameType } from '@phase-software/types'
import {
    SolidPaintKeys,
    GradientPaintKeys,
    ImagePaintKeys,
    FillBaseKeys,
    StrokeBaseKeys,
    ShadowBaseKeys,
    DEFAULT_MOTION_PATH_VALUE,
} from '../constant'

export const getPaintKeys = paintType => {
    switch (paintType) {
        case PaintType.SOLID:
            return SolidPaintKeys
        case PaintType.IMAGE:
            return ImagePaintKeys
        case PaintType.GRADIENT_LINEAR:
        case PaintType.GRADIENT_RADIAL:
        case PaintType.GRADIENT_ANGULAR:
        case PaintType.GRADIENT_DIAMOND:
            return GradientPaintKeys
    }
}

export const getLayerKeys = (layerType, paintType) => {
    const paintKeys = getPaintKeys(paintType)
    switch (layerType) {
        case LayerType.FILL:
            return [...FillBaseKeys, ...paintKeys]
        case LayerType.STROKE:
            return [...StrokeBaseKeys, ...paintKeys]
        case LayerType.SHADOW:
        case LayerType.INNER_SHADOW:
            return [...ShadowBaseKeys, ...paintKeys]
    }
}

export const getMotionPointValue = (keyframe) => {
    return keyframe.frameType === FrameType.INITIAL ?
                { ...DEFAULT_MOTION_PATH_VALUE } :
                { ...keyframe.value }
}
