import React from 'react'
import { useTranslation } from 'react-i18next'

import { toDeg, toRad } from '@phase-software/data-utils'
import { BooleanOperation, ContainerElementType, ElementType } from '@phase-software/types'

import { useEditor } from '../../../../providers/dataStore/EditorProvider'
import { formatAsDegrees } from '../../../../utils/formatter'
import { degreeFormatValidator } from '../../../../utils/validator'
import { Icon } from '../../../shared'
import CornerRadius from '../CornerRadiusEditor/CornerRadius'
import Property from '../Property'
import PropertyRow from '../PropertyRow'

type RotationProps = {
  isShapeMode: boolean
}

const Rotation = ({ isShapeMode }: RotationProps) => {
  const booleanType = useEditor((o) => o.booleanType)
  const isMask = useEditor((o) => o.isMask)
  const elementType = useEditor((o) => o.elementType)
  const containerType = useEditor((o) => o.containerType)
  const { t } = useTranslation('file', { keyPrefix: 'property_editor' })

  return (
    <PropertyRow columns={[94, 94]} isLast>
      {!isShapeMode && (
        <Property
          field="rotation"
          tooltip={t('rotation')}
          data-test-id="rotation-input"
          caption={<Icon interactive={false} name="RotateAngle" />}
          toDisplay={toDeg}
          toValue={toRad}
          validator={degreeFormatValidator}
          formatter={formatAsDegrees}
        />
      )}
      {booleanType === BooleanOperation.NONE &&
        !isMask &&
        !(elementType === ElementType.CONTAINER && containerType === ContainerElementType.NORMAL_GROUP) && (
          <CornerRadius isShapeMode={isShapeMode} />
        )}
    </PropertyRow>
  )
}

export default Rotation
