import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { isEmpty } from 'lodash'

import { FrameType } from '@phase-software/types'

import { useInspectContext } from '../../../providers/InspectProvider'
import { useInspectPanelSectionState } from '../../../providers/RightPanelProvider'
import { useDataStore, useDataStoreActions } from '../../../providers/dataStore/DataStoreProvider'
import { useInteractionActions } from '../../../providers/dataStore/InteractionProvider'
import { easingTypeNameMap } from '../../Interaction/constant'
import { useCopyKeyframesData } from '../Hooks/useCopyKeyframesData'
import InspectContent from '../PropertyInspection/InspectContent'
import InspectRow from '../PropertyInspection/InspectRow'
import InteractiveInspectContent from '../PropertyInspection/InteractiveInspectContent'
import { shouldShowBezierRow } from '../utils'

type CommonInspectionProps = {
  elementId: string
  target: string
  title: string
  formatter: (value: any) => string
}

type InitialInspectionRowProps = {
  elementId: string
  target: string
  label: string
  formatter: (value: any) => string
}

const InitialInspectionRow = ({ elementId, label, target, formatter }: InitialInspectionRowProps) => {
  const dataStore = useDataStore()

  return (
    <InspectRow
      type="wide"
      label={label}
      value={formatter(dataStore.transition.getPropertyValue(elementId, target)[target])}
    />
  )
}

const CommonInspection = ({ elementId, target, title, formatter }: CommonInspectionProps) => {
  const { t } = useTranslation('file')
  const isPanelOpen = useInspectPanelSectionState(target)
  const { getPropertyKeyFrameGroupByTime, getKeyFrame } = useInteractionActions()
  const { getTimeString } = useInspectContext()
  const { setPlayheadTime } = useDataStoreActions()
  const copyKeyframesData = useCopyKeyframesData()

  const animationKeyFrames = useMemo(
    () => getPropertyKeyFrameGroupByTime(elementId, target),
    [elementId, getPropertyKeyFrameGroupByTime, target]
  )

  const handleCopy = () => {
    copyKeyframesData({ animationKeyFrames: animationKeyFrames, elementId: elementId, target: target })
  }

  const renderContent = (): React.ReactNode => {
    const obj = {} as any
    Object.keys(animationKeyFrames)?.map((time: string) => {
      const keyframe = getKeyFrame(animationKeyFrames[time][0])
      obj[time] = {
        value: keyframe.value,
        easingType: keyframe.easingType,
        bezier: keyframe.bezier,
        frameType: keyframe.frameType
      }
    })

    return Object.keys(obj).map((key) => {
      return (
        <InspectContent key={key} spacedDivider>
          <InspectRow
            type="wide"
            label={t('inspect.time')}
            value={getTimeString(Number(key))}
            onClick={() => setPlayheadTime(Number(key))}
          />
          {obj[key].frameType === FrameType.INITIAL ? (
            <InitialInspectionRow elementId={elementId} target={target} label={t('value')} formatter={formatter} />
          ) : (
            <InspectRow type="wide" label={t('inspect.value')} value={formatter(obj[key].value)} />
          )}
          <InspectRow
            type="wide"
            label={t('inspect.ease')}
            value={t(easingTypeNameMap[obj[key].easingType as keyof typeof easingTypeNameMap], {
              ns: 'file',
              keyPrefix: 'easing_type'
            })}
          />
          {shouldShowBezierRow(obj[key].easingType) && (
            <InspectRow type="wide" label={t('inspect.bezier')} value={obj[key]?.bezier?.join(', ')} />
          )}
        </InspectContent>
      )
    })
  }

  if (isEmpty(animationKeyFrames)) return null
  return (
    <>
      <InteractiveInspectContent title={title} target={target} handleCopy={handleCopy}>
        <InitialInspectionRow
          elementId={elementId}
          target={target}
          label={t('inspect.initial')}
          formatter={formatter}
        />
      </InteractiveInspectContent>
      {isPanelOpen && renderContent()}
    </>
  )
}

export default React.memo(CommonInspection)
