import { ElementType } from '@phase-software/types'
import { Vector2 as duVector2 } from '@phase-software/data-utils'
import { Vector2, Rot, Transform2D } from '../../math'
import { MIN_SIZE, MIN_SIZE_THRESHOLD } from '../../constants'
import { rotationTracker } from './utils'
import { Snapping } from './Snapping'

/** @typedef {import('@phase-software/data-utils').Matrix2D} Matrix2D */
/** @typedef {import('@phase-software/data-store/src/Element').Element} Element */
/** @typedef {import('../../math').Rect2} Rect2 */
/** @typedef {import('../../visual_server/RenderItem').RenderItem} RenderItem */
/** @typedef {import('./utils').OriginalSizeData} OriginalSizeData */
/** @typedef {import('./utils').SelectionData} SelectionData */
/** @typedef {import('./utils').NewSizeData} NewSizeData */
/** @typedef {import('./index').Handle} Handle */
/** @typedef {import('./index').Anchor} Anchor */
/** @typedef {import('./Snapping').Snapping} Snapping */

const oldSelectionSize = new Vector2()
const initMousePos = new Vector2()
const initMousePosLocal = new Vector2()
const mouseStartLocal = new Vector2()
/** @type {SelectionData} */
const selectionData = {}

/**
 * @param {Handle} handle
 * @param {Generator<import('../../visual_server/Selection').SelectedItem>} elements
 * @param {Rect2} aabb
 * @param {Snapping} snapping
 * @param {Vector2} mousePos
 * @returns {(mousePos: Vector2, keepAspect: boolean, anchorOverride?: Anchor, snapToGrid: boolean) => void}
 */
export function resize(handle, elements, aabb, snapping, mousePos) {
    /** @type {OriginalSizeData[]} */
    const childrenData = []
    const sign = new Vector2(1, 1)

    selectionData.size = new Vector2(Math.max(MIN_SIZE, aabb.width), Math.max(MIN_SIZE, aabb.height))
    selectionData.position = new Vector2(aabb.x, aabb.y)
    selectionData.center = selectionData.size.clone().scale(0.5).add(aabb.x, aabb.y)
    selectionData.origin = new Vector2(0.5, 0.5)
    selectionData.transform = new Transform2D(1, 0, 0, 1, aabb.x, aabb.y)

    /** @todo Never free these vector2 in the childrenData */
    for (const { element, node } of elements) {

        /** @type {OriginalSizeData} */
        const data = {
            referencePoint: new Vector2(...element.get('referencePoint')),
            contentAnchor: new Vector2(...element.get('contentAnchor')),
            scale: new Vector2(...element.get('scale')),
            size: new Vector2(...element.get('size')),
            skew: new Vector2(...element.get('skew')),
            translate: new Vector2(...element.get('translate')),
            rotation: element.get('rotation'),
            parentWorld: node.item.transform._parent.clone(),
            parentWorldInv: node.item.transform._parent.clone().affine_inverse(),
            element: element,
            node: node,
            sizeFlag: {...node.item.sizeFlag},
            scaleFlag: {...node.item.scaleFlag},
        }
        childrenData.push(data)
    }
    const oldAspect = selectionData.size.x / selectionData.size.y

    oldSelectionSize.copy(selectionData.size)
    initMousePos.copy(mousePos)
    mouseStartLocal.copy(mousePos)
    initMousePosLocal.copy(mousePos).sub(selectionData.position)

    // TOFIX: mutli select resize haven't correctly support all offset calcuation
    // TOFIX: multi select resize seems haven't support snap to grid

    /**
     * @param {Vector2} mousePos
     * @param {boolean} keepAspect
     * @param {boolean} resizeFromCenter
     * @param {Anchor} [anchorOverride]
     * @param {bool} snapToGrid
     * @param {boolean} snapToObject
     */
    return (mousePos, keepAspect, resizeFromCenter, anchorOverride, snapToGrid = true, snapToObject = true) => {
        const resizeCenterHandleAnchor = anchorOverride && resizeFromCenter ? anchorOverride.clone().sub(handle.anchor) : anchorOverride
        const anchor = anchorOverride ? resizeCenterHandleAnchor : handle.anchor
        const drag = new Vector2(1 - anchor.x, 1 - anchor.y)
        const newMousePos = mousePos.clone()

        sign.x = Math.sign(drag.x - 0.5)
        sign.y = Math.sign(drag.y - 0.5)

        // do some calculations for snapping to grid or other elements
        const snappingPos = newMousePos.clone()

        snapping.updateSnapMovingData(false)
        snapping.vs.selection.updateBounds()
        snapping.setResizeSelectedElementOAB(anchor)

        // get selected side/vertex position
        const selectedVertexLocalPos = drag.clone().multiply(oldSelectionSize)
        const selectedVertexWorldPos = selectionData.position.clone().add(selectedVertexLocalPos)
        mouseStartLocal.copy(selectedVertexWorldPos)
        // offset between mousePos and selected edge
        const mouseOffset = drag.clone().sub(anchor)
        mouseOffset.set(Math.abs(mouseOffset.x), Math.abs(mouseOffset.y))
        mouseOffset.multiply(initMousePosLocal.x - selectedVertexLocalPos.x, initMousePosLocal.y - selectedVertexLocalPos.y)
        const isClickEdge = anchor.x === 0.5 || anchor.y === 0.5

        if (snapToGrid) {
            // snapping element size to pixel grid
            const snapToGridPos = snapping.snapResizeElementToPixelGrid(
                snappingPos,
                initMousePos,
                selectedVertexWorldPos,
                isClickEdge,
                true
            )

            snappingPos.copy(snapToGridPos)
            snapToGridPos.sub(mouseOffset.x, mouseOffset.y)
        }
        if (snapToObject) {
            // snapping element to element.
            // If snapToGrid is true, it won't snap to the element
            // which the position of side is not on pixel grid.
            const snapToElementPos = snapping.comparingVerticesWithResize(
                snappingPos,
                initMousePos,
                selectedVertexWorldPos,
                anchor,
                snapToGrid,
                null,
                Snapping.ResizeTypes.RESIZE_ELEMENTS,
                keepAspect
            )
            snappingPos.copy(snapToElementPos)
            snappingPos.sub(mouseOffset.x, mouseOffset.y)
            newMousePos.copy(snappingPos)
        }

        const minSize = MIN_SIZE
        const mouseMotionWorld = newMousePos.clone().sub(mouseStartLocal)
        const sizeFactor = resizeFromCenter ? 2 : 1
        const sizeChange = drag.clone().sub(anchor).multiply(mouseMotionWorld).multiply(sizeFactor, sizeFactor)
        const newSelectionSize = sizeChange.clone().add(selectionData.size)
        if (newSelectionSize.x < MIN_SIZE_THRESHOLD) {
            newSelectionSize.x = minSize
        }
        if (newSelectionSize.y < MIN_SIZE_THRESHOLD) {
            newSelectionSize.y = minSize
        }
        if (keepAspect && oldAspect !== 0 && !Number.isNaN(oldAspect)) {
            // If anchor is centered on y-axis or sizeChange is greater in x direction
            if (anchor.y === 0.5 || (anchor.x !== 0.5 && sizeChange.x >= sizeChange.y)) {
                // adjust Height Based On Width
                newSelectionSize.y = newSelectionSize.x / oldAspect
            } else {
                // adjust Width Based On Height
                newSelectionSize.x = newSelectionSize.y * oldAspect
            }
        }

        // calculate new selection position
        const newSelectionPos = _getNewSelectionPosition(anchor, newSelectionSize)
        if (resizeFromCenter) {
            const resizeFactor = drag.clone().sub(anchor).divide(2, 2)
            const halfSize = newSelectionSize.clone().sub(selectionData.size).multiply(resizeFactor)
            newSelectionPos.sub(halfSize)
        }

        // Get selection scale martix
        const v_scale = newSelectionSize.clone().divide(selectionData.size)

        const dataStore = childrenData[0].element.dataStore
        dataStore.startTransaction()
        for (const childData of childrenData) {
            const element = childData.element
            if (element.isLocked()) continue

            const t_new = new Transform2D()
                .scale_right(v_scale.x, v_scale.y)
                .rotate_right(childData.rotation)
                .skew_right(childData.skew.x, childData.skew.y)

            // Get new rotation, size and skew
            const { rotation: n_rotation, scale: n_scale, skew: n_skew } = t_new.decompose()

            // calculate new size of element
            const newSize = new Vector2().copy(childData.size).multiply(n_scale)
            const newContentAnchor = childData.contentAnchor.clone().multiply(n_scale)
            const newReferencePoint = childData.referencePoint.clone().multiply(n_scale)

            // if resize from zero size, offset the origin
            const translation = childData.translate.clone()
            if (!keepAspect) { // if keep aspect, zero side won't change so no need to offset
                if (childData.sizeFlag.w === 'f0') {
                    translation.x += childData.size.x * 0.5
                }
                if (childData.sizeFlag.h === 'f0') {
                    translation.y += childData.size.y * 0.5
                }
            }
            const worldTrasnalte = childData.parentWorld.xform(translation)

            // calculate new element position relating to selection
            const selectionCenter = selectionData.size.clone().scale(0.5).add(selectionData.position)
            const originRatio = worldTrasnalte.sub(selectionCenter).divide(selectionData.size).add(selectionData.origin)
            const newCenter = originRatio.multiply(newSelectionSize).add(newSelectionPos)
            childData.parentWorldInv.xform(newCenter, newCenter)

            // if resize to zero size, offset the origin
            if (!keepAspect) { // if keep aspect, zero side won't change so no need to offset
                if (newSize.x > 0 && newSize.x < MIN_SIZE_THRESHOLD) {
                    newCenter.x -= newSize.x * 0.5
                }
                if (newSize.y > 0 && newSize.y < MIN_SIZE_THRESHOLD) {
                    newCenter.y -= newSize.y * 0.5
                }
            }

            // Avoid size change if
            // 1. scaleFlag is 0
            // 2. keep aspect and sizeFlag is f0
            const widthShouldnotChange = childData.scaleFlag.x === '0' || (keepAspect && childData.sizeFlag.w === 'f0')
            const heightShouldnotChange = childData.scaleFlag.y === '0' || (keepAspect && childData.sizeFlag.h === 'f0')
            if (widthShouldnotChange) {
                newSize.x = childData.size.x
                newContentAnchor.x = childData.contentAnchor.x
                newReferencePoint.x = childData.contentAnchor.x
            }
            if (heightShouldnotChange) {
                newSize.y = childData.size.y
                newContentAnchor.y = childData.contentAnchor.y
                newReferencePoint.y = childData.contentAnchor.y
            }

            element.sets({
                size: new duVector2(newSize),
                contentAnchorX: newContentAnchor.x,
                contentAnchorY: newContentAnchor.y,
                referencePointX: newReferencePoint.x,
                referencePointY: newReferencePoint.y,
                skew: new duVector2(n_skew),
                rotation: n_rotation,
                translateX : newCenter.x,
                translateY : newCenter.y
            })
        }
        dataStore.endTransaction()
    }
}

/**
 * @param {Generator<{ id: string; element: Element; node: RenderItem; }>} elements
 * @param {Vector2} center
 * @param {Vector2} initialMousePos
 * @param {import('..').SetLocalCursorStateFn} setLocalCursorState
 * @returns {(mousePos: Vector2, shift: boolean) => void}
 */
export function rotate(elements, center, initialMousePos, setLocalCursorState) {
    /** @type {Array<{ rotation: number, element: Element }>} */
    const entries = []

    for (const { element } of elements) {

        const rotation = element.get('rotation')

        entries.push({ rotation, element })
    }

    const tracker = rotationTracker(center, initialMousePos)

    /**
     * @param {Vector2} mousePos
     * @param {boolean} shift
     */
    return (mousePos, { shift }) => {
        const { delta } = tracker(mousePos)
        const dataStore = entries[0].element.dataStore

        dataStore.startTransaction()
        for (const { rotation, element } of entries) {
            if (element.isLocked() || element.get('elementType') === ElementType.SCREEN) continue

            let newRot = rotation + delta
            if (shift) {
                const delta = newRot % Rot[15]
                newRot -= delta >= (Rot[15] * 0.5) ? delta - Rot[15] : delta
            }

            element.set('rotation', newRot)
        }

        setLocalCursorState('rotate')
        dataStore.endTransaction()
    }
}

/**
 * calculate new selection position
 * @param {Vector2} anchor
 * @param {Vector2} newSelectionSize
 * @returns {Vector2}
 */
function _getNewSelectionPosition(anchor, newSelectionSize) {
    const anchorPointLocal = anchor.clone().multiply(selectionData.size)
    const anchorPointWorld = selectionData.transform.xform(anchorPointLocal)
    const anchorToOriginLocal = selectionData.origin.clone().sub(anchor).multiply(newSelectionSize)
    const anchorToOriginWorld = selectionData.transform.basis_xform(anchorToOriginLocal)
    const newOriginWorld = anchorPointWorld.clone().add(anchorToOriginWorld)
    const newOriginLocal = newSelectionSize.clone().multiply(selectionData.origin)
    const newSelectionPos = newOriginLocal.clone().negate().add(newOriginWorld)

    return newSelectionPos
}
