import { Rule, Rules } from '../interfaces'
import evaluateRule from './evaluateRule'

const roleHasPermission = <Role extends string, Permission extends string, User, Data>(
  rules: Rules<Role, Permission, User>,
  role: Role,
  permission: Permission,
  user?: User,
  data?: Data
) => {
  if (!(permission in rules)) {
    return false
  }

  const rule = rules[permission] as Rule<Role, User, Data>

  return evaluateRule(role, rule, user, data)
}

export default roleHasPermission
