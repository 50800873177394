import React, { forwardRef } from 'react'

type ColumnType = number | '1fr' | 'auto' | '100%'

type PropertyRowProps = {
  children?: React.ReactNode
  className?: string
  columns?: ColumnType[]
  showBottomBorder?: boolean
  style?: React.CSSProperties
  isFirst?: boolean
  isLast?: boolean
}

const PropertyRow = forwardRef<HTMLDivElement, PropertyRowProps>(
  ({ children, columns = [104, 104], className = '', style, showBottomBorder }, forwardRef) => {
    const nonArrayChildren = children ? [children] : []
    const childList = Array.isArray(children) ? children.filter(Boolean) : nonArrayChildren
    const borderClassName = showBottomBorder ? 'border-b border-solid border-neutral-80 mb-8' : ''

    return (
      <div className={`flex items-center gap-x-8 pb-8 ${borderClassName} ${className}`} ref={forwardRef} style={style}>
        {childList.map((child, idx) => (
          <div
            key={idx}
            style={{
              width: columns[idx]
            }}
          >
            {child}
          </div>
        ))}
      </div>
    )
  }
)
PropertyRow.displayName = 'PropertyRow'

export default PropertyRow
