import React from 'react'

import { useFeatureValue } from '@growthbook/growthbook-react'

import { LOCALE_NAME_MAP } from '../../constants/localeConstants'
import { FEATURE_KEYS } from '../../growthbook-feature-keys'
import { Select } from '../shared'
import Field from './Field'

type LanguageFieldProps = {
  label: string
  selectedLanguage: string
  onChange: (value: string) => void
  size?: 'normal' | 'full'
}

const LanguageField = ({ label, selectedLanguage, onChange, ...rest }: LanguageFieldProps) => {
  const supportedLanguages = useFeatureValue(FEATURE_KEYS.AVAILABLE_LANGUAGES, [])

  const supportedLanguageOptions = supportedLanguages
    .filter((locale: string) => Boolean(LOCALE_NAME_MAP[locale]))
    .map((locale: string) => ({
      value: locale,
      name: LOCALE_NAME_MAP[locale]
    }))

  return (
    <Field label={label} {...rest}>
      <Select
        // @ts-ignore TODO: fix after refactor of Select
        size="l"
        variant="normal"
        value={selectedLanguage}
        options={supportedLanguageOptions}
        onChange={onChange}
        caret
      />
    </Field>
  )
}
export default LanguageField
