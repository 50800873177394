import React from 'react'
import { useTranslation } from 'react-i18next'

import { parseToDecimals } from '../../../utils/number'
import { percentageFormatValidator } from '../../../utils/validator'
import { Icon, Input } from '../../shared'

type GradientPositionInputProps = {
  selectedGradientStopPosition: number
  onBlur: () => void
  onChange: () => void
}

const GradientPositionInput = ({ selectedGradientStopPosition, onBlur, onChange }: GradientPositionInputProps) => {
  const { t } = useTranslation('file', { keyPrefix: 'property_editor.fill_editor.paint_picker.gradient_stop' })

  return (
    <Input
      // @ts-ignore TODO: fix after refactor of Input
      tooltip={t('position_input_tooltip')}
      variant="normal"
      data-test-id="gradient-stop-position-input"
      type="number"
      value={parseToDecimals(selectedGradientStopPosition * 100, 2)}
      formatter={(o: number) => `${o}%`}
      validator={percentageFormatValidator}
      max={100}
      min={0}
      onBlur={onBlur}
      onChange={onChange}
      rightComponent={
        <Icon name="Location" interactive={false} useCurrentColor className="w-[180px] text-light-overlay-60" />
      }
      spinner
    />
  )
}

export default GradientPositionInput
