import React, { useEffect, useRef } from 'react'
import { Mode } from '@phase-software/types'
// @ts-ignore
import { Vector2 } from '@phase-software/data-utils'
// @ts-ignore
import { Events } from '@phase-software/data-store'
import { ALLOWED_UPLOAD_IMAGE_TYPE_SET } from '../../constant'
import useEditorImage from '../../hooks/useEditorImage'
import { useDataStore } from '../../providers/dataStore/DataStoreProvider'
import { useFileContext } from '../../providers/FileProvider'

const accept = Array.from(ALLOWED_UPLOAD_IMAGE_TYPE_SET).join(',')

type DropPayload = { mousePos: Vector2; dataTransfer: DataTransfer }
type PastePayload = { dataTransfer: DataTransfer }

const ImageUploader = () => {
  const ref = useRef<HTMLInputElement>(null)
  const { createElementByImages } = useEditorImage()
  const dataStore = useDataStore()
  const { id: fileId, projectId } = useFileContext()

  useEffect(() => {
    const dropImage = async ({ dataTransfer, mousePos }: DropPayload) => {
      const worldPos = dataStore.drawInfo.convertMousePosToWorldPosition(mousePos)
      const target = dataStore.selection.get('dragOver')
      await createElementByImages(dataTransfer.files,fileId, projectId, target, worldPos)
      dataStore.selection.set('dragOver', null)
    }

    const pasteImage = ({ dataTransfer }: PastePayload) => {
      switchToDesignMode()
      createElementByImages(dataTransfer.files, fileId, projectId)
    }

    const switchToDesignMode = () => {
      dataStore.switchMode(Mode.DESIGN)
    }

    const openFileDialog = () => {
      switchToDesignMode()
      ref.current?.click()
    }

    dataStore.eam.on(Events.PASTE_WITH_FILES, pasteImage)
    dataStore.eam.on(Events.DRAG_OVER, switchToDesignMode)
    dataStore.eam.on(Events.DROP_WITH_FILES, dropImage)
    dataStore.eam.on(Events.INSERT_IMAGE, openFileDialog)
    return () => {
      dataStore.eam.off(Events.DRAG_OVER, switchToDesignMode)
      dataStore.eam.off(Events.PASTE_WITH_FILES, pasteImage)
      dataStore.eam.off(Events.DROP_WITH_FILES, dropImage)
      dataStore.eam.off(Events.INSERT_IMAGE, openFileDialog)
    }
  }, [dataStore, createElementByImages, fileId, projectId])

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist()
    if (e.target.files) {
      await createElementByImages(e.target.files, fileId, projectId)
      e.target.value = ''
      e.target.blur()
    }
  }

  return <input ref={ref} type="file" accept={accept} multiple onChange={handleImageChange} />
}

export default ImageUploader
