import { getTriggerData } from './Trigger'
import { getSequenceData } from './Sequence'

export const getActionData = (action, lazy) => {
  if (lazy) {
    return action.get('id')
  }
  const data = action.gets('id', 'name', 'triggers', 'sequences')
  data.triggers = data.triggers.map((o) => getTriggerData(o, true))
  data.sequences = data.sequences.map((o) => getSequenceData(o, true))
  data.sequence = getSequenceData(action.sequence.watched, true)
  return data
}
