import { useEffect } from 'react'

const InputBlurBeforeMouseDown = () => {
  useEffect(() => {
    const handleMouseDown = (e) => {
      if (e.target === document.activeElement) {
        return
      }

      // trigger input update first before time change
      if (document.activeElement.tagName === 'INPUT') {
        document.activeElement.blur()
      }
    }
    window.addEventListener('mousedown', handleMouseDown, { capture: true })
    return () => {
      window.removeEventListener('mousedown', handleMouseDown)
    }
  }, [])
  return null
}

export default InputBlurBeforeMouseDown
