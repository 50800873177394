import React from 'react'
import { useTranslation } from 'react-i18next'

import { UserFieldsFragment } from '../../generated/graphql'
import Field from './Field'

const EmailField = ({ value, size = 'normal' }: { value: UserFieldsFragment['email']; size: 'normal' | 'full' }) => {
  const { t } = useTranslation('setting')
  return (
    <Field label={t('profile_setting.email')} size={size}>
      <p className="text-12 font-normal">{value}</p>
    </Field>
  )
}

export default EmailField
