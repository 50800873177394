import PropertyStack from '../PropertyStack'

class HeightStack extends PropertyStack {
  constructor(elementStack, data) {
    super(elementStack, data)
    this.type = 'HEIGHT'
    this.key = 'height'
    this.dataKey = 'height'
    this.animatableProperties = new Set(['height'])
  }
}

export default HeightStack
