import { setAdd } from '@phase-software/data-utils'
import { Setter } from '../Setter'
import ImagePreset from './ImagePreset'

const UNDO_CHANGES = ['latest']
const UNDO_EVENTS = ['PRESET_LIST_CHANGES']

/**
 * @fires 'CHANGES'
 * @fires 'PRESET_LIST_CHANGES'
 */
export default class ImageExport extends Setter {
    constructor(dataStore, data) {
        super(dataStore, data)

        setAdd(this.undoChanges, UNDO_CHANGES)
        setAdd(this.undoEvents, UNDO_EVENTS)
    }

    // Create blank export
    create() {
        super.create()

        this.data.name = 'ImageExport'
        this.data.type = 'IMAGE_EXPORT'
        this.data.latest = new ImagePreset(this.dataStore)
        // TODO: Should have Android/iOS/Web preset by default
        this.presets = new Map()
    }

    /**
     * Load the image export with presets from data
     * @param {ImageExportData} data
     */
    load(data) {
        super.load(data)

        this.presets = new Map()
        for (const presetId in data.presets) {
            this.presets.set(presetId, new ImagePreset(this.dataStore, data.presets[presetId]))
        }
        if (this.presets.has(data.latest)) {
            this.data.latest = this.presets.get(data.latest)
        } else {
            const preset = new ImagePreset(this.dataStore)
            const presetId = preset.get('id')
            this.presets.set(presetId, preset)
            this.data.latest = preset
        }
    }

    /**
     * Clone the image export
     * @returns {ImageExport}
     */
    clone() {
        const obj = super.clone()

        obj.presets = new Map()
        this.presets.forEach((preset) => {
            const clonedPreset = preset.clone()
            obj.presets.set(clonedPreset.get('id'), clonedPreset)
            if (preset === this.get('latest')) {
                obj.data.latest = clonedPreset
            }
        })

        return obj
    }

    /**
     * Save the image export 
     * @returns {ImageExportData} data
     */
    save() {
        const data = super.save()

        data.presets = {}
        this.presets.forEach((preset, presetId) => {
            data.presets[presetId] = preset.save()
        })
        
        data.latest = this.data.latest && this.data.latest.get('id')
        return data
    }

    /**
     * Get the preset from given elements
     * If the element custom preset exists, return the custom one first
     * Otherwise return the latest used global preset
     * If no custom/latest preset applied, return the default preset
     * @param {Element[]} elements
     * @returns {ImagePreset}
     */
    getPreset(elements) {
        const latestPreset = this.get('latest')
        if (!elements.length) {
            return latestPreset
        }

        // If all the selected elements have same preset, return the matched preset
        // otherwise return the latest global preset(or default preset if no latest preset set)
        const selectedPresets = elements.map(element => element.get('imagePreset'))
        const selectedPreset = selectedPresets.every(preset => {
            return preset === selectedPresets[0]
        }) ? selectedPresets[0] || latestPreset : latestPreset

        return selectedPreset
    }

    /**
     * Add preset
     * @param {PresetImageSettingData} data
     * @returns {ImagePreset}
     */
    addPreset(data) {
        const newPreset = new ImagePreset(this.dataStore, data)
        this.presets.set(newPreset.get('id'), newPreset)
        this.fire('PRESET_LIST_CHANGES', {
            ADD: newPreset
        })
        return newPreset
    }

    /**
     * Delete preset
     * @param {string} presetId
     * @returns {ImagePreset}
     */
    removePreset(presetId) {
        const removedPreset = this.presets.get(presetId)
        this.presets.delete(presetId)
        this.fire('PRESET_LIST_CHANGES', {
            REMOVE: removedPreset
        })
        return removedPreset
    }

    /**
     * select preset and set as latest
     * @param {Element[]} elements
     * @param {string} presetId
     * @returns {ImagePreset}
     */
    selectPreset(elements, presetId) {
        const preset = this.presets.get(presetId)

        this.set('latest', preset)
        // Only set the preset to the single selection
        if (elements.length === 1) {
            elements[0].set('imagePreset', preset)
        }
        return preset
    }
}

/** @typedef {import('../Setter').SetterData} SetterData */

/**
 * @typedef {SetterData} ImageExportData
 * @property {Map} presets
 * @property {ImagePreset} latest
 */
