import { Num } from '../math'
import { Command, PathData } from './PathData'

/** @typedef {import('@phase-software/data-store/src/Element').Element} Element */

// https://pomax.github.io/bezierinfo/#circles_cubic
const k = 4 * (Math.SQRT2 - 1) / 3
const k_1 = k - 1

/**
 * draw rounded or normal rectangle
 * @param {[number, number]} size
 * @param {number | [number, number, number, number]} [cornerRadius]
 * @returns {PathData}
 */
export function rectangle([W, H], cornerRadius = 0) {
    // top and bottom corners are actually reversed because WebGL has inverted Y axis

    const isArray = Array.isArray(cornerRadius)
    let bL = isArray ? (cornerRadius[0] || 0) : cornerRadius
    let bR = isArray ? (cornerRadius[1] || 0) : cornerRadius
    let tR = isArray ? (cornerRadius[2] || 0) : cornerRadius
    let tL = isArray ? (cornerRadius[3] || 0) : cornerRadius

    const maxCR = Math.min(W, H) / 2
    bL = Num.clamp(bL, 0, maxCR)
    bR = Num.clamp(bR, 0, maxCR)
    tR = Num.clamp(tR, 0, maxCR)
    tL = Num.clamp(tL, 0, maxCR)

    const path = new PathData()

    // bottom right to top right
    path.commands.push(Command.M, Command.L)
    path.vertices.push(W, bR, W, H - tR)
    // top right corner
    if (tR > 0) {
        path.commands.push(Command.C)
        path.vertices.push(
            W, H + tR * k_1,
            W + tR * k_1, H,
            W - tR, H
        )
    }
    // top right to top left
    path.commands.push(Command.L)
    path.vertices.push(tL, H)
    // top left corner
    if (tL > 0) {
        path.commands.push(Command.C)
        path.vertices.push(
            -tL * k_1, H,
            0, H + tL * k_1,
            0, H - tL
        )
    }
    // top left to bottom left
    path.commands.push(Command.L)
    path.vertices.push(0, bL)
    // bottom left corner
    if (bL > 0) {
        path.commands.push(Command.C)
        path.vertices.push(
            0, -bL * k_1,
            -bL * k_1, 0,
            bL, 0
        )
    }
    // bottom left to bottom right
    path.commands.push(Command.L)
    path.vertices.push(W - bR, 0)
    // bottom right corner
    if (bR > 0) {
        path.commands.push(Command.C)
        path.vertices.push(
            W + bR * k_1, 0,
            W, -bR * k_1,
            W, bR
        )
    }

    path.commands.push(Command.Z)
    return path
}

/**
 * @param {[number, number]} size
 * @returns {PathData}
 */
export function ellipse([W, H]) {
    const w2 = W * 0.5
    const h2 = H * 0.5

    const wk = w2 * k
    const hk = h2 * k

    const x0 = w2 - wk
    const x1 = w2 + wk

    const y0 = h2 - hk
    const y1 = h2 + hk

    const path = new PathData([
        w2, 0,

        x1, 0,
        W, y0,
        W, h2,

        W, y1,
        x1, H,
        w2, H,

        x0, H,
        0, y1,
        0, h2,

        0, y0,
        x0, 0,
        w2, 0,
    ], [
        Command.M, Command.C, Command.C, Command.C, Command.C, Command.Z,
    ])

    return path
}
