import React, { useState, createContext, useContext, useMemo, useCallback } from 'react'

type SkeletonLoadingContextProps = {
  workspaceSidebarReady: boolean
  workspaceFileListReady: boolean
  fileEditorReady: boolean
  updateWorkspaceSidebarReady: (val: boolean) => void
  updateWorkspaceFileListReady: (val: boolean) => void
  updateFileEditorReady: (val: boolean) => void
}

type SkeletonLoadingProviderProps = {
  children: React.ReactNode
}

const defaultValue = {
  workspaceSidebarReady: false,
  workspaceFileListReady: false,
  fileEditorReady: false,
  updateWorkspaceSidebarReady: () => {},
  updateWorkspaceFileListReady: () => {},
  updateFileEditorReady: () => {}
}

export const SkeletonLoadingContext = createContext<SkeletonLoadingContextProps>(defaultValue)

const SkeletonLoadingProvider = ({ children }: SkeletonLoadingProviderProps) => {
  const [workspaceSidebarReady, setWorkspaceSidebarReady] = useState(defaultValue.workspaceSidebarReady)
  const [workspaceFileListReady, setWorkspaceFileListReady] = useState(defaultValue.workspaceFileListReady)
  const [fileEditorReady, setFileEditorReady] = useState(defaultValue.fileEditorReady)

  const updateWorkspaceSidebarReady = useCallback((value: boolean) => {
    setWorkspaceSidebarReady(value)
  }, [])

  const updateWorkspaceFileListReady = useCallback((value: boolean) => {
    setWorkspaceFileListReady(value)
  }, [])

  const updateFileEditorReady = useCallback((value: boolean) => {
    setFileEditorReady(value)
  }, [])

  const value = useMemo(() => {
    return {
      workspaceSidebarReady,
      workspaceFileListReady,
      fileEditorReady,
      updateWorkspaceSidebarReady,
      updateWorkspaceFileListReady,
      updateFileEditorReady
    }
  }, [
    workspaceSidebarReady,
    workspaceFileListReady,
    fileEditorReady,
    updateWorkspaceSidebarReady,
    updateWorkspaceFileListReady,
    updateFileEditorReady
  ])

  return <SkeletonLoadingContext.Provider value={value}>{children}</SkeletonLoadingContext.Provider>
}

function useSkeletonLoadingContext(): SkeletonLoadingContextProps {
  const context = useContext(SkeletonLoadingContext)
  if (context === undefined) {
    throw new Error('useSkeletonLoadingContext must be used within a SkeletonLoadingProvider')
  }
  return context
}

export { SkeletonLoadingProvider, useSkeletonLoadingContext }
