import React from 'react'

export type CircleKnobProps = { size?: number; color: string; className?: string }

const CircleKnob = React.forwardRef<HTMLDivElement, CircleKnobProps>(({ size = 8, color, className = '' }, ref) => (
  <div
    ref={ref}
    className={`my-auto rounded-circle border-2 border-white shadow-1 ${className}`}
    style={{
      width: `${size}px`,
      height: `${size}px`,
      background: color,
      willChange: 'background'
    }}
  />
))

CircleKnob.displayName = 'CircleKnob'

export default CircleKnob
