import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Form, Grid, InputField } from '../../components/shared'
import { usePersonalSettingsContext } from '../../pages/Settings/PersonalSettingsContext'
import { useProfile } from '../../providers/ProfileProvider'
import { confirmPasswordValidator, currentPasswordValidator, passwordValidator } from '../../utils/validator'

const PasswordSettings = () => {
  const { t } = useTranslation('setting')
  const { email, username } = useProfile()
  const {
    password,
    setPassword,
    newPassword,
    setNewPassword,
    confirmPassword,
    setConfirmPassword,
    passwordChanged,
    changePassword
  } = usePersonalSettingsContext()
  const onSubmit = async (formData: { newPassword?: string }) => {
    if (formData.newPassword) {
      await changePassword()
    }
  }
  return (
    // @ts-ignore TODO: fix after refactor of Grid
    <Grid gridGap={24} className="mt-32">
      {/* @ts-ignore TODO: fix after refactor of Form */}
      <Form onSubmit={onSubmit}>
        <InputField
          label={t('profile_setting.current_password')}
          name="password"
          type="password"
          data-test-id="old-password-input"
          placeholder={t('profile_setting.enter_current_password')}
          validator={(value) => currentPasswordValidator(value, t)}
          value={password}
          onInput={setPassword}
          onChange={setPassword}
          labelClassName="mb-24"
          labelFontClassName="text-13"
        />
        <InputField
          label={t('profile_setting.new_password')}
          name="newPassword"
          type="password"
          data-test-id="new-password-input"
          placeholder={t('profile_setting.enter_new_password')}
          validator={(value) => passwordValidator(value, email, username, t)}
          value={newPassword}
          onInput={setNewPassword}
          onChange={setNewPassword}
          labelClassName="mb-24"
          labelFontClassName="text-13"
        />
        <InputField
          label={t('profile_setting.confirm_password')}
          name="confirmPassword"
          type="password"
          data-test-id="new-password-confirm-input"
          placeholder={t('profile_setting.confirm_new_password')}
          validator={(value) => confirmPasswordValidator(newPassword, t)(value)}
          value={confirmPassword}
          onInput={setConfirmPassword}
          onChange={setConfirmPassword}
          labelClassName="mb-32"
          labelFontClassName="text-13"
        />
        <Button
          color="primary"
          disabled={!passwordChanged || newPassword !== confirmPassword}
          type="submit"
          data-test-id="new-password-submit"
        >
          {t('profile_setting.reset_password')}
        </Button>
      </Form>
    </Grid>
  )
}

export default PasswordSettings
