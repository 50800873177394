import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef } from 'react'
import ReactDOM from 'react-dom'
import { useTranslation } from 'react-i18next'
import { CSSTransition } from 'react-transition-group'

import ScrollView from '../ScrollView'
import { INITIAL_ACTIVE_INDEX, MENU_NO_OPTIONS_ID } from './Menu.constants'
import { MenuListOptionProps, MenuOptionProps, MenuType } from './Menu.types'
import MenuOption from './MenuOption'
import useMenuNavigation from './useMenuNavigation'
import useMenuPosition from './useMenuPosition'
import { applyStyleToElementRef, filterOptions, isSeparatorOption } from './utils'

const DEFAULT_VIEWPORT_GAP = 16
const DEFAULT_OFFSET_Y = 8

export type MenuRef = {
  getActiveIndex: () => number
  selectActiveOption: () => void
  onKeyDown: (e: KeyboardEvent) => void
}

export type MenuProps = {
  className?: string
  cursorPosition?: { top: number; left: number }
  customMenuOptionRender?: (option: MenuOptionProps) => React.ReactNode
  filterOption?: (searchValue: string, option: MenuOptionProps) => boolean
  focusOnSelect?: boolean
  hideNoSearchResult?: boolean
  offsetY?: number
  onOpenChange: (open: boolean) => void
  onSelect: (option: MenuOptionProps) => void
  open: boolean
  options: MenuListOptionProps[]
  searchValue?: string
  selectable?: boolean
  selectedValues?: MenuOptionProps['value'][]
  showSearch?: boolean
  trigger?: React.RefObject<HTMLElement>
  triggerInFocusLoop?: boolean
  type: (typeof MenuType)[keyof typeof MenuType]
  viewportGap?: number
}

const Menu = forwardRef<MenuRef, MenuProps>(
  (
    {
      type,
      open,
      onOpenChange,
      viewportGap = DEFAULT_VIEWPORT_GAP,
      offsetY = DEFAULT_OFFSET_Y,
      className = '',

      trigger,
      cursorPosition,
      triggerInFocusLoop,

      selectable = true,
      selectedValues = [],
      onSelect,
      focusOnSelect = true,

      options,
      filterOption,
      customMenuOptionRender,

      showSearch,
      searchValue,
      hideNoSearchResult
    },
    forwardRef
  ) => {
    const { t } = useTranslation('common')
    const menuRef = useRef<HTMLDivElement>(null)
    const scrollViewRef = useRef<HTMLDivElement>(null)

    const firstSelectedOptionIndex = useMemo(
      () => options.findIndex((option) => !isSeparatorOption(option) && option.value === selectedValues[0]),
      [options, selectedValues]
    )
    const firstSelectedOption = useMemo(
      () => options[firstSelectedOptionIndex] as MenuOptionProps | undefined,
      [options, firstSelectedOptionIndex]
    )

    const filteredOptions = useMemo(
      () => filterOptions(options, searchValue, filterOption),
      [searchValue, options, filterOption]
    )

    const { updatePosition } = useMenuPosition({
      cursorPosition,
      firstSelectedOptionIndex,
      scrollViewRef,
      viewportGap,
      offsetY,
      ref: menuRef,
      triggerRef: trigger,
      type,
      showSearch
    })

    const toggleMenuVisibility = useCallback(
      (visible: boolean) => {
        if (visible) return onOpenChange(true)

        onOpenChange(false)
        applyStyleToElementRef(menuRef, { opacity: '0', pointerEvents: 'none' })
        const activeElement = document.activeElement as HTMLElement
        if (menuRef.current?.contains(activeElement)) activeElement.blur()
      },
      [onOpenChange]
    )

    const selectOption = (option?: MenuOptionProps) => {
      if (option?.disabled) {
        return
      }

      if (focusOnSelect) trigger?.current?.focus()
      if (option) {
        onSelect?.(option)
      }

      toggleMenuVisibility(false)
    }

    const { activeIndex, handleKeyDown, changeActiveIndex, selectOptionByActiveIndex } = useMenuNavigation({
      firstSelectedOption,
      firstSelectedOptionIndex,
      filteredOptions,
      ref: scrollViewRef,
      selectOption,
      triggerRef: trigger,
      triggerInFocusLoop,
      onClose: toggleMenuVisibility
    })

    useImperativeHandle(forwardRef, () => ({
      getActiveIndex: () => activeIndex,
      selectActiveOption: selectOptionByActiveIndex,
      onKeyDown: (key) => handleKeyDown(key)
    }))

    useEffect(() => {
      if (!open) return

      const closeMenuOnClickOutside = (e: MouseEvent) => {
        if (!menuRef.current?.contains(e.target as Node) || (e.target as HTMLElement).id === MENU_NO_OPTIONS_ID) {
          toggleMenuVisibility(false)
        }
      }

      setTimeout(() => {
        window.addEventListener('mousedown', closeMenuOnClickOutside, true)
      }, 100)
      return () => {
        window.removeEventListener('mousedown', closeMenuOnClickOutside, true)
      }
    }, [open, toggleMenuVisibility])

    useEffect(() => {
      if (!open) return

      updatePosition()

      const hasValidSelectedValues =
        Array.isArray(selectedValues) && selectedValues.some((value) => value !== undefined)

      if (!hasValidSelectedValues && showSearch) {
        changeActiveIndex(0, false)
        return
      }

      filteredOptions.forEach((option, optionIndex) => {
        if (!isSeparatorOption(option) && selectedValues?.includes(option.value)) {
          changeActiveIndex(optionIndex)
        }
      })
    }, [open, changeActiveIndex, filteredOptions, selectedValues, hideNoSearchResult, updatePosition, showSearch])

    useEffect(() => {
      if (!open) return
      updatePosition()
    }, [open, updatePosition])

    useEffect(() => {
      if (showSearch && searchValue && filteredOptions.length > 0) {
        // if have search function and match to the value user types
        // the first order of menu option will be hovered
        changeActiveIndex(0, true)
      }
    }, [changeActiveIndex, filteredOptions.length, searchValue, showSearch])

    useEffect(() => {
      if (open && !showSearch) {
        scrollViewRef?.current?.focus()
      }
    }, [scrollViewRef, open, showSearch])

    const handleMouseLeave = () => {
      changeActiveIndex(INITIAL_ACTIVE_INDEX, false)
    }

    const handleOptionClick = (e: React.MouseEvent, optionIndex: number) => {
      e.stopPropagation()

      changeActiveIndex(optionIndex, false)
      selectOption(filteredOptions[optionIndex] as MenuOptionProps)
    }

    const renderMenuOptions = () =>
      filteredOptions.map((option, optionIndex) =>
        isSeparatorOption(option) ? (
          <div
            key={`separator-${optionIndex}`}
            className="w-full border-b border-solid border-light-overlay-10 my-8 first:hidden last:hidden"
          />
        ) : (
          <MenuOption
            {...option}
            activatable
            active={activeIndex === optionIndex}
            customRender={customMenuOptionRender}
            key={option.value}
            onClick={(e) => handleOptionClick(e, optionIndex)}
            onMouseEnter={() => changeActiveIndex(optionIndex, false)}
            onMouseLeave={handleMouseLeave}
            optionData={option}
            selectable={selectable}
            selected={selectable && (option.selected || selectedValues.includes(option.value))}
            showAvatar={option.avatarImage !== undefined}
          />
        )
      )

    const renderPortal = () => (
      <CSSTransition in={open} unmountOnExit timeout={300}>
        <div ref={menuRef} className={`menu fixed bg-neutral-80 rounded-lg overflow-hidden z-20 shadow-5 ${className}`}>
          {/* @ts-ignore TODO: fix after refactor of ScrollView */}
          <ScrollView
            aria-hidden={!open}
            className={`${className} overflow-auto h-full py-8 bg-inherit ${showSearch ? 'max-h-[280px]' : ''}`}
            noScrollbar={!showSearch}
            onKeyDown={handleKeyDown}
            onMouseLeave={handleMouseLeave}
            ref={scrollViewRef}
            showScrollArrow={showSearch}
            showScrollBarOnHover={showSearch}
            tabIndex={open && !showSearch ? 0 : -1}
            role="menu"
          >
            {renderMenuOptions()}
            {showSearch && filteredOptions.length === 0 && !hideNoSearchResult && (
              <div id={MENU_NO_OPTIONS_ID} className="text-12 text-light-overlay-40 text-center py-5">
                {t('no_options')}
              </div>
            )}
          </ScrollView>
        </div>
      </CSSTransition>
    )

    return ReactDOM.createPortal(renderPortal(), document.querySelector('#modal') || document.body)
  }
)
Menu.displayName = 'Menu'

export default Menu
