import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { EditMode, ElementType } from '@phase-software/types'

import { useElement } from '../../providers/dataStore/ElementProvider'
import { useElementSelection } from '../../providers/dataStore/ElementSelectionProvider'
import { useMaskGroup } from '../../providers/dataStore/ToolProvider'
import { useUI } from '../../providers/dataStore/UIProvider'
import { translateMenuOptions } from '../shared/Menu/utils'
import ToolButton from '../shared/ToolButton'

const options = [{ name: 'mask', value: true, icon: 'GeometryMask' }]

const MaskTool = () => {
  const editMode = useUI((o) => o.editMode)
  const elements = useElement()
  const selection = useElementSelection()
  const { maskGroupElementList } = useMaskGroup()
  const { t } = useTranslation()

  const isMaskable = useMemo(() => {
    const maskableElements = Object.values(elements)
      .filter((item) => selection.includes(item.id))
      .filter((item) => item.elementType !== ElementType.SCREEN)
    if (editMode === EditMode.SHAPE) {
      return false
    } else if (maskableElements.length >= 1) {
      return true
    } else {
      return false
    }
  }, [editMode, elements, selection])

  const translatedOptions = useMemo(
    () =>
      translateMenuOptions(t, options, {
        ns: 'file',
        keyPrefix: 'tools'
      }),
    [t]
  )

  if (!isMaskable) {
    return null
  } else {
    return (
      <ToolButton
        value={options[0].value}
        options={translatedOptions}
        onChange={maskGroupElementList}
        selectable={false}
        showArrowIcon={false}
      />
    )
  }
}

export default MaskTool
