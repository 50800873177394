import { CUSTOM_EVENT_NAMES } from '../../constants'
import { ErrorContext, Middleware, ResponseContext } from '../../generated/api'
import { createCustomEvent } from '../../utils/customEventUtils'
import { refreshToken } from './../cognito'

// The LiveblocksErrorMiddleware is a temporary solution for handling different error message formats. It is expected that, in the future, once the error message format has been standardized, we will switch to using the ErrorMiddleware.
export default class LiveblocksErrorMiddleware implements Middleware {
  async post(context: ResponseContext) {
    if (!context.response.ok) {
      const data = await context.response.json()

      if (process.env.NODE_ENV !== 'production') {
        const message = data?.errors?.[0]?.msg
        const error = new Error(message == null ? 'Failed' : message)

        // Liveblocks API errors format didn't include the error code and the metadata
        if (message !== 'ROOM_NOT_FOUND') {
          const errorMsgEvent = createCustomEvent(CUSTOM_EVENT_NAMES.DEV_ERROR_MSG, {
            detail: { message: 'Liveblocks API error: ' + error.message }
          })
          console.error(`[Local Environment]\n Liveblocks API error: ${error.message}`)
          document.dispatchEvent(errorMsgEvent)
        }
      }
    }
  }

  async onError(context: ErrorContext): Promise<Response | void> {
    const message = (context as any)?.error?.message
    if (message) {
      // token expired
      if (message === 'Failed to fetch' && !context.response) {
        await refreshToken()
        const errorMsgEvent = createCustomEvent(CUSTOM_EVENT_NAMES.USER_ERROR_MSG, {
          detail: { message: 'common:please_try_again' }
        })
        document.dispatchEvent(errorMsgEvent)
      }
    }
    return context.response
  }
}
