import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { MIX_VALUE } from '@phase-software/data-utils'

import { OPACITY_BLEND_MODE_OPTIONS } from '../../../../constant'
import { useDataStoreActions } from '../../../../providers/dataStore/DataStoreProvider'
import { useEditor, useEditorActions } from '../../../../providers/dataStore/EditorProvider'
import { Select } from '../../../shared'
import { MenuListOptionProps } from '../../../shared/Menu/Menu.types'
import { translateMenuOptions } from '../../../shared/Menu/utils'

const BlendModeSelect = () => {
  const { setProperties } = useEditorActions()
  const { commitUndo } = useDataStoreActions()
  const { t } = useTranslation()
  const blendMode = useEditor((o) => o.blendMode)

  const handleChange = useCallback(
    (blendMode: number) => {
      setProperties({ blendMode })
      commitUndo()
    },
    [setProperties, commitUndo]
  )

  const translatedOptions = useMemo(() => {
    return translateMenuOptions(t, OPACITY_BLEND_MODE_OPTIONS as MenuListOptionProps[], {
      ns: 'file',
      keyPrefix: 'layer_blend_mode'
    })
  }, [t])

  return (
    <div className="grid gap-x-8 grid-flow-col items-center">
      <Select
        // @ts-ignore TODO: fix after refactor of Select
        keyFrameIconProps={{
          name: 'blendMode'
        }}
        caret
        variant="normal"
        options={translatedOptions}
        mixed={blendMode === MIX_VALUE}
        value={blendMode}
        data-test-id="opacity-blend-mode-select"
        onChange={handleChange}
      />
    </div>
  )
}

export default React.memo(BlendModeSelect)
