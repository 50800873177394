import React from 'react'
import PropTypes from 'prop-types'
import { LayerListKeyList } from '@phase-software/data-store'
import { InteractionEntityType } from '@phase-software/types'
import { useDataStore } from '../../../providers/dataStore/DataStoreProvider'
import { useInteraction } from '../../../providers/dataStore/InteractionProvider'
import KeyFrameList from './KeyFrameList'

const PropertyTrack = ({ trackId, indent, place }) => {
  const dataStore = useDataStore()
  const propTrack = useInteraction(trackId)
  if (!propTrack) {
    return null
  }

  // FIXME: During undo/redo the property track might be removed in DS already
  //        but still existed in interaction provider
  if (!dataStore.interaction.getPropertyTrack(propTrack.id)) {
    console.log('FIXME: PropertyTrack not in DS but in provider')
    return null
  }

  const isLayerListTrack = LayerListKeyList.includes(propTrack.key)

  const childList = dataStore.interaction.getChildList(propTrack.id)
  const subTrackList = propTrack.expanded
    ? childList.map((subTrackId, index) => {
        return (
          subTrackId && (
            <PropertyTrack
              key={subTrackId}
              trackId={subTrackId}
              indent={indent + 1}
              index={index}
              parentKey={propTrack.key}
              place={place}
            />
          )
        )
      })
    : []

  if (isLayerListTrack) {
    subTrackList.reverse()
  }

  const item = (
    <KeyFrameList
      key={`${trackId}.kfs`}
      trackId={propTrack.id}
      expanded={propTrack.expanded}
      type={InteractionEntityType.PROPERTY_TRACK}
      showKeyFrame={!propTrack.expanded}
    />
  )
  return [item, ...subTrackList]
}
PropertyTrack.propTypes = {
  place: PropTypes.string,
  trackId: PropTypes.string,
  indent: PropTypes.number,
  folded: PropTypes.bool,
  parentKey: PropTypes.string,
  index: PropTypes.number,
  idx: PropTypes.number
}

export default React.memo(PropertyTrack)
