import React, { useContext, useMemo } from 'react'

import { InteractionEntityType } from '@phase-software/types'

import { useInteractionActions } from '../../../providers/dataStore/InteractionProvider'
import { TRACK_PADDING_X } from '../constant'
import { SizeContext } from '../contexts'
import Segment from './Segment'
import TrackKeyframe from './TrackKeyframe'

type KeyFrameListProps = {
  expanded: boolean
  showKeyFrame: boolean
  trackId: string
  type: number
}

const calculateMaxTime = (keyframesGroupedByTime: Record<string, string[]>) => {
  let maxTime = 0
  for (const time in keyframesGroupedByTime) {
    const t = Number(time)
    if (maxTime < t) {
      maxTime = t
    }
  }
  return maxTime
}

const KeyFrameList = ({ trackId, showKeyFrame, type, expanded }: KeyFrameListProps) => {
  const { scale, width, duration } = useContext(SizeContext)

  const { getKeyFrameListGroupByTime } = useInteractionActions()

  const keyframeGroupByTime: Record<string, string[]> = getKeyFrameListGroupByTime(trackId)

  const timeToPixelRatio = (scale * width) / duration || 1
  const scaledWidth = width * scale

  const segmentEndTime = showKeyFrame ? calculateMaxTime(keyframeGroupByTime) : 0
  const segments = useMemo(() => [[0, segmentEndTime]], [segmentEndTime])

  const keyframes = showKeyFrame
    ? Object.keys(keyframeGroupByTime).map((time) => (
        <TrackKeyframe key={time} time={Number(time)} trackId={trackId} timeToPixelRatio={timeToPixelRatio} />
      ))
    : []

  if (type === InteractionEntityType.PROPERTY_TRACK && !keyframes.length && !expanded) {
    return null
  }

  return (
    <div
      className="flex items-center relative overflow-hidden"
      style={{ width: `${scaledWidth + 2 * TRACK_PADDING_X}px` }}
    >
      <div
        className="flex items-center relative"
        style={{ width: `${scaledWidth + TRACK_PADDING_X}px`, marginLeft: `${TRACK_PADDING_X}px` }}
      >
        {keyframes}
        <Segment key="segment" list={segments} pxRatio={timeToPixelRatio} />
      </div>
    </div>
  )
}

export default KeyFrameList
