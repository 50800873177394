import { schema } from 'normalizr'
import { createProvider, useEntity } from '../utils'

export const Effect = new schema.Entity('effects', {
  processStrategy: (value, parent) => {
    return { ...value, styleId: parent.id, elementId: parent.elementId }
  }
})

const defaultValue = {}
const [Provider, useSelectState, useSetState, getStateSnapshot] = createProvider('Effect', defaultValue)

export const useSelectEffect = useSelectState
export const getEffectSnapshot = getStateSnapshot

export const useSetEffect = () => {
  const setState = useSetState()
  const [setEffect, addEffect, updateEffect, removeEffect, mergeEffect, reduceEffect, createEffect, destroyEffect] =
    useEntity(Effect, setState)

  return {
    setEffect,
    addEffect,
    updateEffect,
    removeEffect,
    mergeEffect,
    reduceEffect,
    createEffect,
    destroyEffect
  }
}

export default Provider
