import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { LoadingStatus } from '@phase-software/types'

import { TEAM_ROLE_OPTIONS } from '../../constants/teamConstants'
import { CreateTeamInvitationRequestTeamRoleEnum } from '../../generated/api'
import { TeamUserFieldsFragment, TeamUserInvitationFieldsFragment } from '../../generated/graphql'
import useTeamMemberActions from '../../hooks/useTeamMemberActions'
import { useSetNotification } from '../../providers/NotificationProvider'
import { useWorkspaceContext } from '../../providers/WorkspaceContextProvider'
import { track } from '../../services/heapAnalytics'
import { isValidEmail } from '../../utils/validator'
import { transformTeamMemberWithInvitationList } from '../Share/shareHelper'
import { Dialog, Select, TextArea } from '../shared'
import { translateMenuOptions } from '../shared/Menu/utils'
import { TextAreaTagItemProps } from '../shared/TextArea'

type InviteTeamMemberDialogProps = {
  open: boolean
  onClose: () => void
  teamUsers: TeamUserFieldsFragment[]
  teamUserInvitations: TeamUserInvitationFieldsFragment[]
}
const InviteTeamMemberDialog = ({ open, onClose, teamUsers, teamUserInvitations }: InviteTeamMemberDialogProps) => {
  const { t } = useTranslation(['setting', 'workspace'])
  const { workspaceData } = useWorkspaceContext()
  const { inviteTeamMember } = useTeamMemberActions()
  const { addNotification } = useSetNotification()

  const [invitedMemberRole, setMemberRole] = useState<CreateTeamInvitationRequestTeamRoleEnum>('MEMBER')
  const [invitedMembers, setInvitedMembers] = useState<TextAreaTagItemProps[]>([])
  const [invitedMembersError, setInvitedMembersError] = useState('')

  const [isInviting, setIsInviting] = useState(false)

  const translatedOptions = useMemo(
    () =>
      translateMenuOptions(t, TEAM_ROLE_OPTIONS, {
        ns: 'setting',
        keyPrefix: 'team_setting'
      }),
    [t]
  )

  const existingMemberSet = new Set(
    transformTeamMemberWithInvitationList({
      teamUsers: teamUsers,
      teamUserInvitations: teamUserInvitations
    })
  )

  const validateInvitedMember = ({ value: email }: TextAreaTagItemProps) =>
    isValidEmail(email) && !existingMemberSet.has(email)

  const checkInvitedMemberAllValid = () => {
    if (invitedMembers.length === 0) {
      setInvitedMembersError(t('workspace:please_enter_at_least_one_email'))
      return false
    }

    if (!invitedMembers.every(({ value }) => isValidEmail(value))) {
      setInvitedMembersError(t('workspace:some_emails_are_invalid'))
      return false
    }

    if (!invitedMembers.every(validateInvitedMember)) {
      setInvitedMembersError(t('workspace:users_already_a_team_member_or_invited'))
      return false
    }

    setInvitedMembersError('')
    return true
  }

  const handleInviteTeamMember = async () => {
    if (isInviting || !workspaceData.id || !checkInvitedMemberAllValid()) return

    try {
      setIsInviting(true)
      const inviteMemberRequests = invitedMembers.map((member) =>
        inviteTeamMember({ teamId: workspaceData.id, email: member.value, teamRole: invitedMemberRole })
      )

      await Promise.all(inviteMemberRequests)
      invitedMembers.forEach(() => track('Invitation To Team Sent', { teamName: name, userRole: invitedMemberRole }))
      addNotification({
        type: 'success',
        content: t('workspace:message.invitation_sent')
      })
      handleClose()
    } catch (error) {
      console.error(error)
    } finally {
      setIsInviting(false)
    }
  }

  const handleClose = () => {
    setInvitedMembers([])
    setInvitedMembersError('')
    onClose()
  }

  return (
    <Dialog
      size="s"
      title={t('setting:team_setting.invite_team_members')}
      confirmBtnText={t('setting:team_setting.send_invitation')}
      cancelBtnText={t('setting:team_setting.cancel')}
      onConfirm={handleInviteTeamMember}
      onCancel={handleClose}
      showProgressButton
      progressStatus={isInviting ? LoadingStatus.WAITING : LoadingStatus.INITIAL}
      closable={!isInviting}
      open={open}
    >
      <TextArea
        disabled={isInviting}
        allowTags
        defaultTags={invitedMembers}
        error={invitedMembersError}
        onChangeTags={setInvitedMembers}
        placeholder={t('workspace:teams.enter_emails_separated_by_coma')}
        customTagValidator={validateInvitedMember}
        rightComponent={
          <Select
            // @ts-ignore TODO: fix after refactor of Select
            focusOnSelect={false}
            size="l"
            value={invitedMemberRole}
            onChange={setMemberRole}
            options={translatedOptions}
            disabled={isInviting}
          />
        }
      />
    </Dialog>
  )
}

export default InviteTeamMemberDialog
