import React, { forwardRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import moment from 'moment'

import { ContextMenu, IconButton, ScrollView, Tooltip } from '..'
import { DEFAULT_LOCALE } from '../../../constants/localeConstants'
import { useModal, useSetModal } from '../../../providers/ModalProvider'
import CommentWithData from '../../Comment/CommentWithData'
import { ModalsState } from '../ContextMenu/types'
import { MenuListOptionProps, MenuOptionProps } from '../Menu/Menu.types'
import { translateMenuOptions } from '../Menu/utils'
import CommentTextArea from './CommentTextArea'
import {
  COPY_LINK_OPTION,
  DELETE_THREAD_OPTION,
  MARK_THREAD_AS_READ_OPTION,
  MARK_THREAD_AS_UNREAD_OPTION,
  REOPEN_THREAD_OPTION
} from './commentMenuConstants'
import { CommentDataProps, CommentType, ThreadDataProps } from './types'

const getComputedClassName = (type: CommentType, isExpanded: boolean, isResolved: boolean) => {
  const styles = {
    [CommentType.CANVAS]: isExpanded ? 'pb-8 bg-neutral-80 max-h-[640px]' : 'py-8 bg-neutral-80',
    [CommentType.PANEL]: isExpanded
      ? ` ${isResolved && 'pb-8'} bg-light-overlay-10`
      : ` py-8 group-hover:bg-light-overlay-5 peer-focus-visible:after:absolute peer-focus-visible:after:outline-primary-1 peer-focus-visible:after:rounded-md peer-focus-visible:relative peer-focus-visible:after:inset-[1px] peer-focus-visible:after:z-20 peer-focus-visible:after:outline-opacity-100`
  }
  return styles[type]
}

const getOpacityClassName = (type: CommentType, isExpanded: boolean, isRead: boolean) => {
  if (type === CommentType.PANEL && !isExpanded && isRead) return 'opacity-60 group-hover:opacity-100'
  return 'opacity-100'
}

const getThreadOptions = ({
  isResolved,
  isRead,
  isThreadCreator
}: {
  isResolved: boolean
  isRead: boolean
  isThreadCreator: boolean
}): MenuListOptionProps[] => {
  const options: MenuListOptionProps[] = []

  if (isResolved) options.push(REOPEN_THREAD_OPTION)

  if (isRead) {
    options.push(MARK_THREAD_AS_UNREAD_OPTION)
  } else {
    options.push(MARK_THREAD_AS_READ_OPTION)
  }

  options.push(COPY_LINK_OPTION)

  if (isThreadCreator) options.push('-', DELETE_THREAD_OPTION)

  return options
}

export interface CommentThreadProps {
  thread: ThreadDataProps
  onThreadClose?: () => void
  updateResolveStatus: (threadId: string, resolved: boolean) => void
  copyLink: (threadId: string, type: CommentType) => void
  onReplySubmit?: ({ threadId, value }: { threadId: string; value: string }) => void
  handleThreadClick?: (e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLInputElement>) => void
  onTextAreaEscape?: () => void
  toggleDeleteThreadDialog?: (open: boolean) => void
  updateUserReadStatus: (read: boolean) => void
  type: CommentType
  isResolved: boolean
  isExpanded: boolean
  isThreadCreator: boolean
  isRead: boolean
  className?: string
}

const CommentThread = forwardRef<HTMLDivElement, CommentThreadProps>(
  (
    {
      thread,
      onThreadClose,
      updateResolveStatus,
      copyLink,
      onReplySubmit,
      handleThreadClick,
      onTextAreaEscape,
      toggleDeleteThreadDialog,
      updateUserReadStatus,
      type = CommentType.PANEL,
      isResolved,
      isExpanded,
      isThreadCreator,
      isRead,
      className = ''
    },
    forwardedRef
  ) => {
    const { t, i18n } = useTranslation('file', { keyPrefix: 'commenting' })
    const currentLanguage = i18n.language
    const triggerRef = React.useRef<HTMLButtonElement>(null)
    const uniqueThreadId = `${type}-${thread.id}`

    const {
      open,
      data: { trigger, cursorPosition }
    } = useModal((o: ModalsState) => o[`CommentContextMenu-${uniqueThreadId}`])
    const { openModal, closeModal } = useSetModal(`CommentContextMenu-${uniqueThreadId}`)

    const isPanelComment = type === CommentType.PANEL
    const xOffsetClassName = isPanelComment ? 'px-8' : 'px-16'

    const menuOptions = useMemo(
      () => getThreadOptions({ isResolved, isRead, isThreadCreator }),
      [isResolved, isRead, isThreadCreator]
    )

    const translatedMenuOptions = useMemo(
      () =>
        translateMenuOptions(t, menuOptions, {
          ns: 'file',
          keyPrefix: 'commenting'
        }),
      [menuOptions, t]
    )

    const nonDeletedComment = useMemo(() => {
      return thread.comments.filter((comment) => !comment.deletedAt)
    }, [thread.comments])

    const handleSelectOption = (option: MenuOptionProps) => {
      switch (option.value) {
        case REOPEN_THREAD_OPTION.value:
          updateResolveStatus(thread.id, false)
          break
        case MARK_THREAD_AS_UNREAD_OPTION.value:
          updateUserReadStatus(false)
          break
        case MARK_THREAD_AS_READ_OPTION.value:
          updateUserReadStatus(true)
          break
        case COPY_LINK_OPTION.value:
          copyLink(thread.id, thread.metadata.type)
          break
        case DELETE_THREAD_OPTION.value: {
          toggleDeleteThreadDialog?.(true)
          break
        }
        default:
          break
      }
    }

    const handleThreadResolveClick = (): void => {
      updateResolveStatus(thread.id, true)
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      switch (e.key) {
        case ' ':
        case 'Enter':
          // if (onClickComment) onClickComment(thread.comments[0])
          handleThreadClick?.(e)
          break
        case 'Escape':
          e.currentTarget.blur()
          break
        default:
          break
      }
    }

    return (
      <>
        <div
          className={`${isPanelComment && 'px-8'} ${!isExpanded && 'cursor-pointer group'} ${className}`}
          onClick={handleThreadClick}
          ref={forwardedRef}
        >
          {!isExpanded && (
            <input type="checkbox" tabIndex={0} onKeyDown={handleKeyDown} className="peer absolute opacity-0 w-0 h-0" />
          )}
          <div className={`relative rounded-md flex flex-col ${getComputedClassName(type, isExpanded, isResolved)}`}>
            {isExpanded && (
              <div
                className={`${xOffsetClassName} mb-8 py-8 flex justify-end gap-8 rounded-t-md border-b border-solid border-neutral-60 ${getOpacityClassName(
                  type,
                  isExpanded,
                  isRead
                )}`}
              >
                {isResolved ? (
                  <div className="flex-1 text-light-overlay-60">{t('resolved')}</div>
                ) : (
                  <Tooltip content={t('resolve_thread')}>
                    <IconButton icon="Check" onClick={handleThreadResolveClick} />
                  </Tooltip>
                )}
                <IconButton ref={triggerRef} icon="More" onClick={() => openModal({ trigger: triggerRef })} />
                <IconButton
                  icon={isPanelComment ? 'Collapsed' : 'Cross'}
                  onClick={() => onThreadClose && onThreadClose()}
                />
              </div>
            )}
            {!isRead && isPanelComment && (
              <div
                className="absolute w-6 h-6 -translate-x-1/2 -translate-y-1/2 bg-primary-50 rounded-circle outline-neutral-90-2"
                style={{
                  top: isExpanded ? '58px' : '25px'
                }}
              />
            )}
            {/* @ts-ignore */}
            <ScrollView
              className={`${getOpacityClassName(type, isExpanded, isRead)} ${!isExpanded && 'cursor-pointer z-30'}`}
            >
              {nonDeletedComment?.map((comment: CommentDataProps, index: number) => {
                if (index !== 0 && !isExpanded) return null
                return (
                  <CommentWithData
                    key={comment.id}
                    isFirstComment={index === 0}
                    comment={comment}
                    showResolveButton={!isResolved && !isExpanded && index === 0}
                    isResolved={isResolved}
                    onThreadResolveClick={handleThreadResolveClick}
                    isExpanded={isExpanded}
                    replyCount={nonDeletedComment.length - 1}
                    type={type}
                    displayTime={
                      currentLanguage === DEFAULT_LOCALE
                        ? moment(comment.createdAt).locale('en-short').fromNow()
                        : moment(comment.createdAt).fromNow()
                    }
                  />
                )
              })}
            </ScrollView>
            {isExpanded && !isResolved && (
              <div className={`${xOffsetClassName} py-8 order-last ${getOpacityClassName(type, isExpanded, isRead)}`}>
                <CommentTextArea
                  type={type}
                  variant="reply"
                  placeholder={t('write_a_reply')}
                  rows={1}
                  onInputSubmit={(value) => onReplySubmit?.({ value, threadId: thread.id })}
                  maxScrollHeight={430}
                  onEscape={onTextAreaEscape}
                />
              </div>
            )}
          </div>
        </div>
        <ContextMenu
          open={open}
          options={translatedMenuOptions}
          onSelect={handleSelectOption}
          onClose={closeModal}
          cursorPosition={cursorPosition}
          trigger={trigger}
        />
      </>
    )
  }
)

CommentThread.displayName = 'CommentThread'

export default CommentThread
