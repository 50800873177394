import React, { useCallback, useEffect, useRef } from 'react'

import { useFeatureValue } from '@growthbook/growthbook-react'
import { ClientSideSuspense } from '@liveblocks/react'
import { Events } from '@phase-software/data-store'

import { FILE_EDITOR_LAYOUT_AREAS } from '../../constant'
import { FEATURE_KEYS } from '../../growthbook-feature-keys'
import { useSetModal } from '../../providers/ModalProvider'
import { useDataStore } from '../../providers/dataStore/DataStoreProvider'
import { useUI, useUIActions } from '../../providers/dataStore/UIProvider'
import { waitUntil } from '../../utils/data'
import CanvasCommentWrapper from '../Comment/CanvasCommentWrapper'
import { RendererCanvasContainer, initRenderer } from '../shared/RendererCanvas'
import EditOriginIndicator from './EditOriginIndicator'

export const Canvas = () => {
  const hasRendererInit = useUI((o) => o.hasRendererInit)
  const { setRendererInit } = useUIActions()
  const canvasContainer = useRef(null)
  const dataStore = useDataStore()
  const useLowDPR = useFeatureValue(FEATURE_KEYS.LOW_DPR, false)

  const canvasCommentWrapperRef = useRef(null)

  const { openModal: openElementModal } = useSetModal('ElementContextMenu')
  const { openModal: openCanvasModal } = useSetModal('CanvasContextMenu')

  const handleClick = useCallback((e) => {
    canvasCommentWrapperRef.current?.handleCanvasClick(e, canvasContainer.current.getBoundingClientRect())
  }, [])

  useEffect(() => {
    const openElementContextMenu = (mousePos) => {
      const selection = dataStore.selection.get('elements')
      if (selection.length === 0) return
      openElementModal({
        elementIds: selection.map((s) => s.data.id),
        mousePos,
        canvasPosition: canvasContainer.current.getBoundingClientRect()
      })
    }
    const openCanvasContextMenu = (mousePos) => {
      openCanvasModal({ mousePos, canvasPosition: canvasContainer.current.getBoundingClientRect() })
    }
    canvasContainer.current.appendChild(RendererCanvasContainer)
    dataStore.eam.on(Events.OPEN_ELEMENT_CONTEXT_MENU, openElementContextMenu)
    dataStore.eam.on(Events.OPEN_CANVAS_CONTEXT_MENU, openCanvasContextMenu)
    // TODO: [Commenting] Implement Comment Tag movement when viewport panning here
    // dataStore.eam.on(Events.MOVE_VIEWPORT_WHEEL, translateCanvasCommentWrapper)
    return () => {
      dataStore.eam.off(Events.OPEN_ELEMENT_CONTEXT_MENU, openElementContextMenu)
      dataStore.eam.off(Events.OPEN_CANVAS_CONTEXT_MENU, openCanvasContextMenu)
    }
  }, [dataStore, openElementModal, openCanvasModal])

  useEffect(() => {
    if (!hasRendererInit) {
      initRenderer(useLowDPR).then(async () => {
        await waitUntil(() => dataStore.drawInfo)
        setRendererInit(true)
      })
    }
  }, [hasRendererInit, setRendererInit, useLowDPR, dataStore])

  return (
    <div
      className="relative bg-neutrals-90 overflow-hidden"
      style={{ gridArea: FILE_EDITOR_LAYOUT_AREAS.CANVAS }}
      id="canvas-container"
      ref={canvasContainer}
      onClick={handleClick}
    >
      <div className="absolute">
        <ClientSideSuspense fallback={null}>
          {() => <CanvasCommentWrapper ref={canvasCommentWrapperRef} />}
        </ClientSideSuspense>
      </div>
      <EditOriginIndicator />
    </div>
  )
}

export default React.memo(Canvas)
