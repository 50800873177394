/**
 * @template T
 * @param {T[]} array Array to be shuffled
 * @returns {T[]} The modified array
 */
export function shuffle(array) {
    const len = array.length - 1

    for (let i = len; i > 0; i--) {
        const random_index = Math.floor(Math.random() * (i + 1))
        const item_at_index = array[random_index]

        array[random_index] = array[i]
        array[i] = item_at_index
    }

    return array
}

/**
 * @template T
 * @param {T[]} from
 * @param {T[]} to
 * @returns {T[]}
 */
export function copy_array_values(from, to) {
    for (let i = 0, len = from.length; i < len; i++) {
        to[i] = from[i]
    }
    return to
}

/**
 * @template T
 * @param {T[]} array
 * @param {number} left
 * @param {number} right
 * @param {(a: T, b: T) => number} compareFn
 * @returns {number}
 */
function partition(array, left, right, compareFn) {
    const pivot = array[Math.floor((right + left) / 2)] // middle
    let i = left, j = right
    /** @type {T} */
    let tmp

    while (i <= j) {
        while (compareFn(array[i], pivot) < 0) {
            i++
        }
        while (compareFn(array[j], pivot) > 0) {
            j--
        }
        if (i <= j) {
            // swap
            tmp = array[i]
            array[i] = array[j]
            array[j] = tmp

            i++
            j--
        }
    }

    return i
}

/**
 * @template T
 * @param {T[]} array
 * @param {number} start
 * @param {number} end
 * @param {(a: T, b: T) => number} compareFn
 * @returns {T[]}
 */
export function range_sort(array, start, end, compareFn) {
    let index = 0
    if (array.length > 1) {
        index = partition(array, start, end, compareFn)
        if (start < index - 1) {
            range_sort(array, start, index - 1, compareFn)
        }
        if (index < end) {
            range_sort(array, index, end, compareFn)
        }
    }
    return array
}

/**
 * @template T
 * @param {T[]} array
 * @param {T} element
 * @param {number} [len] length of the array
 * @returns {boolean}
 */
export function array_contains(array, element, len = -1) {
    if (len < 0) {
        return array.indexOf(element) >= 0
    }

    for (let i = 0; i < len; i++) {
        if (array[i] === element) {
            return true
        }
    }

    return false
}

/** @template T */
export class StaticArray {
    /**
     * @template T
     * @returns {StaticArray<T>}
     */
    static new() {
        const arr = pool_StaticArray.pop()
        if (arr) {
            arr.length = 0
            arr.$p = false
            return arr
        }
        return new StaticArray()
    }

    /** @param {StaticArray} arr */
    static free(arr) {
        if (arr && !arr.$p) {
            arr.$p = true
            pool_StaticArray.add(arr)
        }
    }

    constructor() {
        this.$p = false

        /** @type {T[]} */
        this.array = []
        this.length = 0
    }

    clone() {
        /** @type {StaticArray<T>} */
        const inst = StaticArray.new()
        for (let i = 0; i < this.length; i++) {
            inst.array[i] = this.array[i]
        }
        inst.length = this.length
        return inst
    }

    /** @param {T} e */
    add(e) {
        this.array[this.length++] = e
    }

    /**
     * @param {T} e
     */
    add_unique(e) {
        const idx = this.array.indexOf(e)
        if (idx >= 0 && idx < this.length) return
        this.array[this.length++] = e
    }

    pop() {
        if (this.length === 0) return null
        this.length -= 1
        return this.array[this.length]
    }

    /**
     *
     * @param {T} e
     */
    delete(e) {
        const idx = this.array.indexOf(e)
        if (idx < 0 || idx >= this.length) return

        // for (let i = idx; i < this.length - 1; i++) {
        //     this.array[i] = this.array[i + 1]
        // }

        // swap the last element in the empty slot
        if (idx < this.length - 1) {
            this.array[idx] = this.array[this.length - 1]
            this.array[this.length - 1] = null
        }

        this.length--
    }

    /**
     * @param {T} e
     * @returns {bool}
     */
    contains(e) {
        const idx = this.array.indexOf(e)
        if (idx >= 0 && idx < this.length) return true
        return false
    }

    /**
     * @param {(a: T, b: T) => number} compareFn
     */
    sort(compareFn) {
        range_sort(this.array, 0, this.length - 1, compareFn)
    }

    clear(unref = false) {
        if (unref) {
            this.array.fill(null)
        }
        this.length = 0
    }

    prune() {
        this.array.length = 0
        this.length = 0
    }
}

/** @type {StaticArray[]} */
const pool_StaticArray = []
