import { Feature, MatteModeFeature, LayerTypeFeature } from '../features'
import { Scope } from './constants'

const createEnumRuleFromFeatureSchema = featureSchema =>
  Object.keys(featureSchema).reduce((map, featureKey, index) => {
    map[index] = Feature[featureKey]
    return map
  }, {})

const createAnimatePropertyFeature = propName => ({
  _type: 'value',
  _rule: o => {
    const feats = []
    if (o === undefined) {
      return feats
    }
    if (o.s) {
      feats.push(Feature.SPLIT_VECTOR)
    } else if (o.x) {
      feats.push(Feature.EXPRESSIONS)
    }
    if (o.a) {
      feats.push(Feature[`${propName}_ANIMATION`])
    }
    if (o.k) {
      feats.push(Feature[propName])
      if (Array.isArray(o.k)) {
        const anyMotionPath = o.k.some(kf => {
          if (typeof kf === 'object') {
            return kf.ti || kf.to
          }
          return false
        })
        if (anyMotionPath) {
          feats.push(Feature.MOTION_PATH)
        }
      }
    }

    return feats
  }
})
const stackingOrder = {
  _type: 'value',
  _rule: {
    1: Feature.STACKING_ABOVE,
    2: Feature.STACKING_BELOW
  }
}
const maskInvert = {
  _type: 'value',
  _rule: {
    true: Feature.MASK_INVERT
  }
}
const lineCap = {
  _type: 'value',
  _rule: { 1: Feature.LINE_CAP_BUTT, 2: Feature.LINE_CAP_ROUND, 3: Feature.LINE_CAP_SQUARE }
}
const gradientType = {
  _type: 'value',
  _rule: { 1: Feature.LINE_CAP_BUTT, 2: Feature.LINE_CAP_ROUND, 3: Feature.LINE_CAP_SQUARE }
}
const lineJoin = {
  _type: 'value',
  _rule: { 1: Feature.LINE_JOIN_MITER, 2: Feature.LINE_JOIN_ROUND, 3: Feature.LINE_JOIN_BEVEL }
}
const fillRule = { _type: 'value', _rule: { 1: Feature.FILL_NONE_ZERO, 2: Feature.FILL_EVEN_ODD } }
const starType = { _type: 'value', _rule: { 1: Feature.STAR, 2: Feature.POLYGON } }
const threeD = { _type: 'value', _rule: { 1: Feature.THREE_D } }
const blendMode = { _type: 'value', _rule: Feature.BLEND_MODE }
const autoOrient = { _type: 'value', _rule: { 1: Feature.AUTO_ORIENT } }

const miter = { _type: 'value', _rule: o => (o === undefined ? undefined : Feature.MITER) }
const parenting = { _type: 'value', _rule: o => (o === undefined ? undefined : Feature.PARENTING) }
const matte = { _type: 'value', _rule: createEnumRuleFromFeatureSchema(MatteModeFeature) }
const layerType = { _type: 'value', _rule: createEnumRuleFromFeatureSchema(LayerTypeFeature) }
const timeRemapping = { _type: 'value', _rule: o => (o === undefined ? undefined : Feature.TIME_REMAPPING) }
const dashAndGap = { _type: 'value', _rule: o => (o === undefined ? undefined : Feature.DASH_AND_GAP) }

// polystar
const points = createAnimatePropertyFeature('POINTS')
const innerRadius = createAnimatePropertyFeature('INNER_RADIUS')
const innerRoundness = createAnimatePropertyFeature('INNER_ROUNDNESS')
const outerRadius = createAnimatePropertyFeature('OUTER_RADIUS')
const outerRoundness = createAnimatePropertyFeature('OUTER_ROUNDNESS')

// fill
const color = createAnimatePropertyFeature('COLOR')

const roundness = createAnimatePropertyFeature('ROUNDNESS')
const size = createAnimatePropertyFeature('SIZE')
const position = createAnimatePropertyFeature('POSITION')
const anchor = createAnimatePropertyFeature('ANCHOR')
const rotation = createAnimatePropertyFeature('ROTATION')
const rotationX = createAnimatePropertyFeature('ROTATION_X')
const rotationY = createAnimatePropertyFeature('ROTATION_Y')
const rotationZ = createAnimatePropertyFeature('ROTATION_Z')
const opacity = createAnimatePropertyFeature('OPACITY')
const scale = createAnimatePropertyFeature('SCALE')
const skew = createAnimatePropertyFeature('SKEW')
const skewAngle = createAnimatePropertyFeature('SKEW_ANGLE')
const bezier = createAnimatePropertyFeature('BEZIER')

const miter2 = createAnimatePropertyFeature('MITER')
const startingPoint = createAnimatePropertyFeature('STARTING_POINT')
const endPoint = createAnimatePropertyFeature('END_POINT')
const highlightLength = createAnimatePropertyFeature('HIGHLIGHT_LENGTH')
const highlightAngle = createAnimatePropertyFeature('HIGHLIGHT_ANGLE')
const gradientColor = createAnimatePropertyFeature('GRADIENT_COLOR')
const repeaterOffset = createAnimatePropertyFeature('REPEATER_OFFSET')

const shapeType = {
  _type: 'value',
  _rule: {
    el: Feature.ELLIPSE_SHAPE,
    fl: Feature.FILL_SHAPE,
    gf: Feature.GRADIENT_FILL_SHAPE,
    gs: Feature.GRADIENT_STROKE_SHAPE,
    gr: Feature.GROUP_SHAPE,
    mm: Feature.MERGE_SHAPE,
    op: Feature.OFFSET_PATH_SHAPE,
    sh: Feature.PATH_SHAPE,
    rc: Feature.RECTANGLE_SHAPE,
    rp: Feature.REPEATER_SHAPE,
    rd: Feature.ROUNDED_CORNERS_SHAPE,
    sr: Feature.STAR_SHAPE,
    st: Feature.STROKE_SHAPE,
    tm: Feature.TRIM_SHAPE,
    tw: Feature.TWIST_SHAPE,
    pb: Feature.PUCKER_BLOAT_SHAPE,
    zz: Feature.ZIG_ZAG_SHAPE
  }
}
// const shapeDirection = {
//   _type: 'value',
//   _rule: {
//     1: Feature.SHAPE_DIRECTION_NORMAL,
//     3: Feature.SHAPE_DIRECTION_REVERSED
//   }
// }

const maskMode = {
  _type: 'value',
  _rule: {
    n: Feature.MASK_NONE,
    a: Feature.MASK_ADD,
    s: Feature.MASK_SUBTRACT,
    i: Feature.MASK_INTERSECT,
    l: Feature.MASK_LIGHTEN,
    d: Feature.MASK_DARKEN,
    f: Feature.MASK_DIFFERENCE
  }
}

const effectType = {
  _type: 'value',
  _rule: {
    '0': Feature.EFFECT_NONE,
    '5': Feature.EFFECT_CUSTOM,
    '7': Feature.EFFECT_PAINT_OVER_TRANSPARENT,
    '20': Feature.EFFECT_TINT,
    '21': Feature.EFFECT_FILL,
    '22': Feature.EFFECT_STROKE,
    '23': Feature.EFFECT_TRITONE,
    '24': Feature.EFFECT_PRO_LEVELS,
    '25': Feature.EFFECT_DROP_SHADOW,
    '26': Feature.EFFECT_RADIAL_WIPE,
    '27': Feature.EFFECT_DISPLACEMENT_MAP,
    '28': Feature.EFFECT_MATTE3,
    '29': Feature.EFFECT_GAUSSIAN_BLUR,
    '31': Feature.EFFECT_MESH_WRAP,
    '32': Feature.EFFECT_WAVY,
    '33': Feature.EFFECT_SPHERIZE,
    '34': Feature.EFFECT_PUPPET
  }
}

const animation = {
  _type: 'scope',
  props: {
    ddd: threeD
  }
}
const layer = {
  _type: 'scope',
  props: {
    ty: layerType,
    bm: blendMode,
    ao: autoOrient,
    parent: parenting,
    tt: matte,
    tm: timeRemapping
  }
}

const shapeProps = {
  ty: shapeType,
  bm: blendMode
}

const ellipseShape = {
  _type: 'scope',
  props: {
    ...shapeProps,
    p: position,
    s: size
  }
}

const rectangleShape = {
  _type: 'scope',
  props: {
    ...shapeProps,
    p: position,
    s: size,
    r: roundness
  }
}

const polyStarShape = {
  _type: 'scope',
  props: {
    ...shapeProps,
    p: position,
    or: outerRadius,
    os: outerRoundness,
    r: rotation,
    pt: points,
    sy: starType,
    ir: innerRadius,
    is: innerRoundness
  }
}

const pathShape = {
  _type: 'scope',
  props: {
    ...shapeProps,
    ks: bezier
  }
}

const fillShape = {
  _type: 'scope',
  props: {
    ...shapeProps,
    c: color,
    r: fillRule
  }
}

const strokeShape = {
  _type: 'scope',
  props: {
    ...shapeProps,
    lc: lineCap,
    lj: lineJoin,
    ml: miter,
    ml2: miter2,
    o: opacity,
    d: dashAndGap,
    c: color
  }
}

const gradientShape = {
  _type: 'scope',
  props: {
    ...shapeProps,
    s: startingPoint,
    e: endPoint,
    t: gradientType,
    h: highlightLength,
    a: highlightAngle,
    k: gradientColor
  }
}

const groupShape = {
  _type: 'scope',
  props: {
    ...shapeProps
  }
}

const repeaterShape = {
  _type: 'scope',
  props: {
    ...shapeProps,
    o: repeaterOffset,
    m: stackingOrder
  }
}
// FIXME
const todoShape = {
  _type: 'scope',
  props: {
    ...shapeProps
  }
}

const shape = {
  _type: 'group',
  _key: 'ty',
  _scopes: {
    el: ellipseShape,
    fl: fillShape,
    gf: gradientShape,
    gs: gradientShape,
    gr: groupShape,
    mm: todoShape, // FIXME
    op: todoShape, // FIXME
    sh: pathShape,
    rc: rectangleShape,
    rp: repeaterShape,
    rd: todoShape, // FIXME
    sr: polyStarShape,
    st: strokeShape,
    tm: todoShape, // FIXME
    tw: todoShape, // FIXME
    pb: todoShape, // FIXME
    zz: todoShape // FIXME
  }
}

const masksProperties = {
  _type: 'scope',
  props: {
    mode: maskMode,
    inv: maskInvert
  }
}

const effect = {
  _type: 'scope',
  props: {
    ty: effectType
  }
}

const transform = {
  _type: 'scope',
  props: {
    a: anchor,
    p: position,
    r: rotation,
    rx: rotationX,
    ry: rotationY,
    rz: rotationZ,
    o: opacity,
    s: scale,
    sk: skew,
    sa: skewAngle
  }
}

const createScopeParser = schema => {
  switch (schema._type) {
    case 'scope': {
      return (prop, value) => {
        const subScope = schema.props[prop]

        if (subScope?._type === 'value') {
          const ruleType = typeof subScope._rule
          switch (ruleType) {
            case 'function':
              return subScope._rule(value)
            case 'object':
              return subScope._rule[value]
            default:
              return subScope._rule
          }
        }
      }
    }
    case 'group': {
      return (prop, value, target) => {
        const scope = target[schema._key]
        const scopeParser = schema._scopes[scope]
        if (!scopeParser) {
          return
        }

        const subScope = scopeParser.props[prop]

        if (subScope?._type === 'value') {
          const ruleType = typeof subScope._rule
          switch (ruleType) {
            case 'function':
              return subScope._rule(value)
            case 'object':
              return subScope._rule[value]
            default:
              return subScope._rule
          }
        }
      }
    }
  }
}

const scopeMapParser = new Map([
  [Scope.ANIMATION, createScopeParser(animation)],
  [Scope.LAYER, createScopeParser(layer)],
  [Scope.SHAPE, createScopeParser(shape)],
  [Scope.MASK_PROPERTIES, createScopeParser(masksProperties)],
  [Scope.EFFECT, createScopeParser(effect)],
  [Scope.TRANSFORM, createScopeParser(transform)]
])

const parseScopedFeature = (scope, target, prop) => {
  const value = target[prop]

  if (scopeMapParser.has(scope)) {
    const parser = scopeMapParser.get(scope)
    return parser(prop, value, target)
  }
}

const parseScope = path => {
  const absPath = path.replace(/\[\d+\]/g, '')
  switch (absPath) {
    case '$':
      return Scope.ANIMATION
    case '$.layers':
    case '$.assets.layers':
      return Scope.LAYER
    case '$.layers.masksProperties':
    case '$.assets.layers.masksProperties':
      return Scope.MASK_PROPERTIES
    case '$.layers.ef':
    case '$.assets.layers.ef':
      return Scope.EFFECT
    case '$.layers.ks':
    case '$.assets.layers.ks':
      return Scope.TRANSFORM
    default: {
      const relPath = path
        .split('.')
        .pop()
        .replace(/\[\d+\]/, '')
      switch (relPath) {
        case 'it':
        case 'shapes':
          return Scope.SHAPE
      }
    }
  }
}

const jsonHandler = (path = '$', cb) => ({
  get(target, prop, receiver) {
    const isFunc = typeof target[prop] === 'function'
    const isArray = Array.isArray(target)

    if (typeof prop === 'symbol') {
      if (prop === Symbol.iterator) {
        return target[Symbol.iterator].bind(target)
      }
    } else {
      const key = isArray ? `${path}[${prop}]` : `${path}.${prop}`
      const shouldTrack = !isFunc

      if (shouldTrack) {
        const scope = parseScope(path)
        const feature = parseScopedFeature(scope, target, prop)
        if (feature) {
          cb(feature)
        }
      }

      if (typeof target[prop] === 'object' && target[prop] !== null) {
        return new Proxy(target[prop], jsonHandler(key, cb))
      }
    }
    return Reflect.get(target, prop, receiver)
  }
})

export const analyzeLottieFeature = (json, cb) => new Proxy(json, jsonHandler('$', cb))
