import React, { useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import { LoadingStatus } from '@phase-software/types'

import { Checkbox, Dialog, InputField } from '../../../../components/shared'
import { InputFieldRefProps } from '../../../../components/shared/InputField'
import useFileVersionActions from '../../../../hooks/useFileVersionActions'
import useHeapAnalytics from '../../../../hooks/useHeapAnalytics'
import { useSetNotification } from '../../../../providers/NotificationProvider'
import { useWorkspaceContext } from '../../../../providers/WorkspaceContextProvider'
import { track } from '../../../../services/heapAnalytics'
import { getFileIndexPath } from '../../../../utils/pathGenerators'
import { fileNameValidator } from '../../../../utils/validator'

type CreateFileFromVersionDialogProps = {
  fileId: string
  onClose: () => void
  open: boolean
  originalName: string
  projectId: string
  versionId: string
}

const CreateFileFromVersionDialog = ({
  fileId,
  onClose,
  open,
  originalName,
  projectId,
  versionId
}: CreateFileFromVersionDialogProps) => {
  const history = useHistory()
  const { t } = useTranslation(['file', 'common', 'workspace'])
  const { workspaceData } = useWorkspaceContext()
  const { createFileByVersion } = useFileVersionActions()
  const { addNotification } = useSetNotification()
  const { space, teamName } = useHeapAnalytics()

  const nameInputRef = useRef<InputFieldRefProps>(null)
  const [name, setName] = useState(originalName)
  const [shouldShareWithSamePeople, setShouldShareWithSamePeople] = useState(true)
  const [hasError, setHasError] = useState(false)
  const [isCreating, setIsUpdating] = useState(false)

  const location = projectId === workspaceData.draftProjectId ? 'drafts' : 'project'

  useEffect(() => {
    if (open && nameInputRef.current) {
      nameInputRef.current.focus()
    }
  }, [open])

  useEffect(() => {
    setName(originalName)
  }, [originalName])

  const handleChangeName = (newName: string) => {
    setHasError(false)
    setName(newName)
  }

  const validateInputs = () => {
    if (!nameInputRef.current) return
    const isValid = nameInputRef.current?.onValidate()
    setHasError(!isValid)
    return isValid
  }

  const handleUpdate = async () => {
    if (isCreating || !validateInputs()) return
    setIsUpdating(true)

    try {
      const newFileId = await createFileByVersion({
        fileName: name,
        fileId,
        projectId,
        versionId,
        copyCollaborators: shouldShareWithSamePeople
      })

      addNotification({
        type: 'success',
        content: (
          <Trans
            i18nKey="workspace:dialog.created"
            values={{ file_name: name }}
            components={[
              <span
                key="file-created-notification"
                className="font-semibold"
                data-test-id="file-created-notification"
              />
            ]}
          />
        ),
        action: t('common:notification.open'),
        callback: () => {
          const fullUrl = new URL(history.createHref({ pathname: getFileIndexPath(newFileId) }), window.location.origin)
          window.location.href = fullUrl.toString()
        }
      })

      track('Version Copied', {
        fileId,
        space,
        teamName,
        location
      })
      onClose()
    } catch (error) {
      console.error(error)
    } finally {
      setIsUpdating(false)
    }
  }

  const handleClose = () => {
    setName(originalName)
    setHasError(false)
    onClose()
  }

  return (
    <Dialog
      data-test-id="create-file-from-version-dialog"
      size="xs"
      title={t('file:version.make_a_copy')}
      showProgressButton
      progressStatus={isCreating ? LoadingStatus.WAITING : LoadingStatus.INITIAL}
      confirmBtnText={t('file:version.create')}
      cancelBtnText={t('file:version.cancel')}
      onConfirm={handleUpdate}
      onCancel={handleClose}
      disableConfirm={hasError || isCreating}
      open={open}
    >
      <InputField
        ref={nameInputRef}
        type="text"
        value={name}
        validator={(value) => fileNameValidator(value, t)}
        onInput={handleChangeName}
        onEnterKey={handleUpdate}
        labelClassName="mb-8"
        validateOnChange={false}
      />
      <Checkbox
        // @ts-ignore TODO: fix after refactor of Checkbox
        checked={shouldShareWithSamePeople}
        label={t('file:version.share_it')}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setShouldShareWithSamePeople(e.target.checked)}
      />
    </Dialog>
  )
}

export default CreateFileFromVersionDialog
