const DIRTY_KEYS = {
    ROOT: 'root',
    RESIZE: 'resize'
}

class Status {
    constructor() {
        this._paused = false
        this.dirty = {
            [DIRTY_KEYS.ROOT]: true,
            [DIRTY_KEYS.RESIZE]: false
        }
    }

    get paused() {
        return this._paused
    }

    _updateDirty(key, dirty) {
        this.dirty[key] = dirty
    }

    updateRootDirty(dirty) {
        this._updateDirty(DIRTY_KEYS.ROOT, dirty)
    }

    updateResizeDirty(dirty) {
        this._updateDirty(DIRTY_KEYS.RESIZE, dirty)
    }

    pause() {
        this._paused = true
    }

    resume() {
        this._paused = false
    }
}

const status = new Status()

export default status

export const pauseRenderer = () => {
    status.pause()
}

export const resumeRenderer = () => {
    status.resume()
}
