import React from 'react'
import PropTypes from 'prop-types'

export const Filter = ({ search, onChange }) => {
  const handleChange = (e) => {
    onChange(e.target.value)
  }
  return (
    <input
      className="bg-neutral border border-solid w-full px-4"
      type="search"
      value={search}
      onChange={handleChange}
      placeholder="Type to filter..."
    />
  )
}

Filter.propTypes = {
  search: PropTypes.string,
  onChange: PropTypes.func
}
