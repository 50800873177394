import { PropComponentType, UnitFull } from '@phase-software/types'
import { notNull, isNum, isStr } from '@phase-software/data-utils'
import PropertyComponent from './PropertyComponent'

/** @typedef {import('../DataStore').DataStore} DataStore */
/** @typedef {import('./PropertyComponent').AppliedRef} AppliedRef */
/** @typedef {import('./PropertyComponent').Units} Units */

export default class FontComponent extends PropertyComponent {
    /**
     * @param {DataStore} dataStore
     * @param {FontSetComponentData} [data]
     * @param {object} [options] 
     * @param {bool} [options.regenId=false]   if set to true, will generate new ID
     */
    constructor(dataStore, data = {}, options) {
        super(dataStore, data, options)

        const {
            fontFamily,
            fontStyle,
            fontSize,
            fontSizeUnit,
            characterSpacing,
            wordSpacing,
            lineSpacing,
            paragraphSpacing,
            paragraphIndent
        } = data

        this.componentType = PropComponentType.FONT
        this.fontFamily = isStr(fontFamily) ? fontFamily : 'Roboto'
        this.fontStyle = isStr(fontStyle) ? fontStyle : 'Regular'
        this.fontSize = isNum(fontSize) ? fontSize : 14
        this.fontSizeUnit = isStr(fontSizeUnit) ? fontSizeUnit : UnitFull.PIXEL

        this.characterSpacing = isNum(characterSpacing) ? characterSpacing : 0
        this.wordSpacing = isNum(wordSpacing) ? wordSpacing : 0
        this.lineSpacing = isNum(lineSpacing) ? lineSpacing : 120
        this.paragraphSpacing = isNum(paragraphSpacing) ? paragraphSpacing : 0
        this.paragraphIndent = isNum(paragraphIndent) ? paragraphIndent : 0
        if (!this.name) {
            this.name = 'font'
        }
    }

    /** @param {Partial<FontSetComponentSetData>} data */
    set(data) {
        super.set(data)

        const {
            fontFamily,
            fontStyle,
            fontSize,
            fontSizeUnit,
            characterSpacing,
            wordSpacing,
            lineSpacing,
            paragraphSpacing,
            paragraphIndent
        } = data

        if (isStr(fontFamily)) {
            this.updateProp('fontFamily', fontFamily)
        } else if (fontFamily === null) {
            this.updateProp('fontFamily', undefined)
        }
        if (isStr(fontStyle)) {
            this.updateProp('fontStyle', fontStyle)
        } else if (fontStyle === null) {
            this.updateProp('fontStyle', undefined)
        }
        if (isNum(fontSize)) {
            this.updateProp('fontSize', fontSize)
        } else if (fontSize === null) {
            this.updateProp('fontSize', undefined)
            this.updateProp('fontSizeUnit', 'px')
        }
        if (fontSizeUnit in UnitFull && isNum(this.fontSize)) {
            this.updateProp('fontSizeUnit', fontSizeUnit)
        }
        if (isNum(characterSpacing)) {
            this.updateProp('characterSpacing', characterSpacing)
        } else if (characterSpacing === null) {
            this.updateProp('characterSpacing', undefined)
        }
        if (isNum(wordSpacing)) {
            this.updateProp('wordSpacing', wordSpacing)
        } else if (wordSpacing === null) {
            this.updateProp('wordSpacing', undefined)
        }
        if (isNum(lineSpacing)) {
            this.updateProp('lineSpacing', lineSpacing)
        } else if (lineSpacing === null) {
            this.updateProp('lineSpacing', undefined)
        }
        if (isNum(paragraphSpacing)) {
            this.updateProp('paragraphSpacing', paragraphSpacing)
        } else if (paragraphSpacing === null) {
            this.updateProp('paragraphSpacing', undefined)
        }
        if (isNum(paragraphIndent)) {
            this.updateProp('paragraphIndent', paragraphIndent)
        } else if (paragraphIndent === null) {
            this.updateProp('paragraphIndent', undefined)
        }
    }

    /**
     * Override this in subclasses
     * CALL super._clone() at the top of overriden method
     * @protected
     * @param {AppliedRef} [ref]
     * @returns {FontSetComponent} obj
     */
    _clone(ref) {
        const obj = super._clone(ref)
        obj.fontFamily = this.fontFamily
        obj.fontStyle = this.fontStyle
        obj.fontSize = this.fontSize
        obj.fontSizeUnit = this.fontSizeUnit
        obj.characterSpacing = this.characterSpacing
        obj.wordSpacing = this.wordSpacing
        obj.lineSpacing = this.lineSpacing
        obj.paragraphSpacing = this.paragraphSpacing
        obj.paragraphIndent = this.paragraphIndent
        return obj
    }

    /**
     * Override this in subclasses
     * CALL super._save() at the top of overriden method
     * @protected
     * @returns {FontSetComponentData} data
     */
    _save() {
        const data = super._save()
        if (isStr(this.fontFamily)) {
            data.fontFamily = this.fontFamily
        }
        if (isStr(this.fontStyle)) {
            data.fontStyle = this.fontStyle
        }
        if (isNum(this.fontSize)) {
            data.fontSize = this.fontSize
        }
        if (this.fontSizeUnit in UnitFull) {
            data.fontSizeUnit = this.fontSizeUnit
        }
        if (notNull(this.characterSpacing)) {
            data.characterSpacing = this.characterSpacing
        }
        if (notNull(this.wordSpacing)) {
            data.wordSpacing = this.wordSpacing
        }
        if (notNull(this.lineSpacing)) {
            data.lineSpacing = this.lineSpacing
        }
        if (notNull(this.paragraphSpacing)) {
            data.paragraphSpacing = this.paragraphSpacing
        }
        if (notNull(this.paragraphIndent)) {
            data.paragraphIndent = this.paragraphIndent
        }
        return data
    }
}

FontComponent.BASE_DEFAULT_DATA = {
    fontFamily: 'Roboto',
    fontStyle: 'Regular',
    fontSize: 14,
    fontSizeUnit: UnitFull.PIXEL,
    characterSpacing: 0,
    wordSpacing: 0,
    lineSpacing: 120,
    paragraphSpacing: 0,
    paragraphIndent: 0
}

/** @typedef {import('./PropertyComponent').PropertyComponentData} PropertyComponentData */

/**
 * @typedef {PropertyComponentData} FontComponentData
 * @property {(string | undefined)} fontFamily
 * @property {(string | undefined)} fontStyle
 * @property {(number | undefined)} fontSize
 * @property {Units} fontSizeUnit
 * @property {(number | undefined)} characterSpacing
 * @property {(number | undefined)} wordSpacing
 * @property {(number | undefined)} lineSpacing
 * @property {(number | undefined)} paragraphSpacing
 * @property {(number | undefined)} paragraphIndent
 */

/**
 * @typedef {PropertyComponentData} FontComponentSetData
 * @property {(string | null)} fontFamily
 * @property {(string | null)} fontStyle
 * @property {(number | null)} fontSize
 * @property {Units} fontSizeUnit
 * @property {(number | null)} characterSpacing
 * @property {(number | null)} wordSpacing
 * @property {(number | null)} lineSpacing
 * @property {(number | null)} paragraphSpacing
 * @property {(number | null)} paragraphIndent
 */
