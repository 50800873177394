import React from 'react'

import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { svg2Lottie } from '@phase-software/svg-importer'

import { Button } from '../../../components/shared'
import { FEATURE_KEYS } from '../../../growthbook-feature-keys'

// This button is for QA testing only. It allows us to test the SVG Import code to examine the intermediate Lottie file.
// It should only be rendered for selected email users in Dev and Test3.
// When we have a new version of the SVG importer, which does not use Lottie, this Button can be deleted. Check with Max/QA first.
export function LottieExportButtonForQATesting() {
  const isLottieButtonEnabled = useFeatureIsOn(FEATURE_KEYS.SHOW_LOTTIE_GENERATED_FROM_SVG_BUTTON)

  const handleFilePicker = () => {
    const input = document.createElement('input')
    input.type = 'file'
    input.accept = '.svg'
    input.onchange = (event) => {
      const file = (event.target as HTMLInputElement).files?.[0]
      if (file) {
        const reader = new FileReader()
        reader.onload = async (e) => {
          const text = e.target?.result as string
          const parser = new DOMParser()
          const doc = parser.parseFromString(text, 'image/svg+xml')
          const svgElement = doc.documentElement as unknown as SVGSVGElement
          const lottieContent = svg2Lottie(svgElement)
          const jsonContent = JSON.stringify(lottieContent, null, 2)
          const jsonFileName = file.name.replace('.svg', '.json')
          const blob = new Blob([jsonContent], { type: 'application/json' })
          const url = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = jsonFileName
          a.click()
          URL.revokeObjectURL(url)
        }
        reader.readAsText(file)
      }
    }
    input.click()
  }

  return isLottieButtonEnabled ? (
    <Button variant="solid" color="secondary" size="l" data-test-id="lottie-from-svg" onClick={handleFilePicker}>
      Lottie from SVG
    </Button>
  ) : null
}
