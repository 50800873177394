import React from 'react'
import { LayerType } from '@phase-software/types'
import { useFill } from '../../../../providers/dataStore/FillProvider'
import PaintPreview from '../PaintPreview'
import FillOpacity from './FillOpacity'
import { ContainerWithRemoveLayer } from '../../ContainerWithRemoveLayer'
import PropertyRow from '../PropertyRow'

type FillProps = {
  id: string
}

const Fill = ({ id }: FillProps) => {
  const { isNonBase } = useFill((o) => o[id])

  return (
    <ContainerWithRemoveLayer isNonBase={isNonBase} layerItemId={id} dataTestId="fill-layer">
      <PropertyRow className="pb-0" columns={[124, 64]} isFirst isLast>
        <div data-test-id="fill-color">
          <PaintPreview layerItemId={id} layerType={LayerType.FILL} />
        </div>
        <div className="flex items-center justify-center">
          <FillOpacity layerItemId={id} showIndicator />
        </div>
      </PropertyRow>
    </ContainerWithRemoveLayer>
  )
}

export default React.memo(Fill)
