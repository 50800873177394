import { EntityType, ChangeType, IDType } from '@phase-software/types'
import { isNull, Undoable, Change, id, loadId } from '@phase-software/data-utils'
import { instanceOfPropClass } from '../component'
import PropertyComponent from '../component/PropertyComponent'
import { PAINT_CATEGORY_MAP } from '../constant'


/** @typedef {import('../component/PropertyComponent').AppliedRef} AppliedRef */

const PROP_NAMES = ['visible']

const PROP_NAME_TO_COMPONENT_MAP = {
    visible: undefined
}

const DEFAULT_COMPONENT_OPTIONS = { regenId: false }

/**
 * @fires CHANGES
 */
export default class Layer extends Undoable {
    /**
     * @param {DataStore} dataStore
     * @param {LayerData} data
     * @param {object} [options]
     * @param {bool} [options.regenId=false]   if set to true, will generate new ID
     */
    constructor(dataStore, data, options = DEFAULT_COMPONENT_OPTIONS) {
        super(dataStore, ChangeType.PROPERTY)
        this.dataStore = dataStore

        // flags / change ids; SHOULD start from 1
        this._ids = {
            layerChange: 1
        }
        this._subComponentIds = {}

        if (data) {
            this.load(data, options)
        } else {
            this.create()
        }
    }

    _init() {
        this._propNames = new Set(PROP_NAMES)
        this._propClasses = { ...PROP_NAME_TO_COMPONENT_MAP }

        this._componentMap = new Map()

        this.type = EntityType.LAYER
        this._props = {
            visible: true
        }

        this.paintCache = {
            solid: undefined,
            gradient: undefined,
            image: undefined
        }
    }

    create() {
        this.id = id(IDType.LAYER)
        this.layerType = 'LAYER'

        this._init()

        // non-serializable
        this.component = undefined
    }

    /**
     * @param {LayerData} data
     * @param {object} [options]
     * @param {bool} [options.regenId=false]   if set to true, will set new ID
     */
    load(data, { regenId=false } = DEFAULT_COMPONENT_OPTIONS) {
        if (regenId || !data.id) {
            this.id = id(IDType.LAYER)
        } else {
            // Update IdCounter for Layer
            loadId(data.id, IDType.LAYER)
            this.id = data.id
        }
        this.layerType = data.layerType

        this._init()

        this.setProperty('visible', data.visible, { fire: false })

        // non-serializable
        this.component = undefined
    }

    /**
     * Clones Layer and applies any inner sharable PropertyComponents to element+style+itself
     * Override this in subclasses
     * CALL super.clone() at the top of overriden method
     * @param {AppliedRef} ref
     * @returns {Layer}
     */
    // eslint-disable-next-line no-unused-vars
    clone(ref) {
        const obj = new this.constructor(this.dataStore)
        obj.id = id(IDType.LAYER)
        obj.layerType = this.layerType

        obj._init()

        obj.setProperty('visible', this.visible, { fire: false })
        return obj
    }

    /** @returns {LayerData} */
    save() {
        return {
            id: this.id,
            type: this.type,
            layerType: this.layerType,
            visible: this._props.visible
        }
    }

    /**
     * Use it to pass on apply calls to inner properties (like Paint)
     * Override this in subclasses if subclass has any inner sharable PropertyComponents
     * CALL super.apply() at the top of overriden method
     * @param {AppliedRef} ref
     */
    // eslint-disable-next-line no-unused-vars
    apply(ref) {
        // nothing
    }

    /**
     * Use it to pass on cancel calls to inner properties (like Paint)
     * Override this in subclasses if subclass has any inner sharable PropertyComponents
     * CALL super.cancel() at the top of overriden method
     * @param {AppliedRef} ref
     */
    // eslint-disable-next-line no-unused-vars
    cancel(ref) {
        // nothing
    }

    get properties() {
        return this._propNames
    }

    /**
     * Sets property with data. If data is:
     *   - instance of `PropertyComponent` -  sets this Style's property `propName` to that PropertyComponent
     *                                        and adds this Style to the `appliedTo` list of that PropertyComponent
     *   - `Partial<PropertyComponentData>` - sets values in `data` to corresponding keys in this Style's property `propName`
     *   - any                              - assign `data` directly to property `propName`
     * @param {string} propName
     * @param {PropertyComponent | Partial<PropertyComponentData> | any} data
     * @param {object} [options]
     * @param {bool} [options.fire=true]        set to false to not fire events
     *                                              (won't add it to Undo either)
     * @param {any} [options.flags=undefined]   if defined, will be set as layer._layerChangesId parameter
     *                                               this prevents ComputedStyle from handling LayerChange event resulting from setting property
     */
    setProperty(propName, data, { fire = true, flags = undefined } = { fire: true }) {
        if (!this._propNames.has(propName) && !this._subComponentIds[propName]) {
            throw new Error(`Trying to set non-existent property "${propName}" in layer ${this.id}`)
        }
        // no data provided
        if (isNull(data)) {
            return
        }

        let oldProp = this._props[propName]
        let subComponentIdName = ''
        let subComponentId = ''
        if (this._subComponentIds[propName]) {
            subComponentIdName = this._subComponentIds[propName]
            subComponentId = this._props[subComponentIdName]
            oldProp = this.dataStore.library.getComponent(subComponentId)
        }
        let newProp
        // Trying to assign the same value to property - does nothing
        if (data === oldProp) {
            return
        }

        const propClass = this._propClasses[propName]
        // if this prop should be a subtype of PropertyComponent
        if (propClass) {
            // sets propName property to provided instance of PropertyComponent
            if (data instanceof PropertyComponent) {
                // wrong PropertyComponent provided
                if (!instanceOfPropClass(propClass, data)) {
                    throw new Error(
                        `Provided instance of wrong PropertyComponent subtype for property ${propName} in layer ${this.id}`
                    )
                }
                this._cachePaint(subComponentIdName, data.id)

                // remove this layer from the list of applied references in PropertyComponent (if was present and is shared)
                //  NOTE: if layer is part of shared LayerComponent, it has many refs
                if (oldProp && oldProp.isShared) {
                    this._getRefs.forEach(ref => oldProp.cancel({ ...ref, layerId: this.id }))
                }
                // add this Style to list of applied references in PropertyComponent (only if component is already shared)
                //  NOTE: if layer is part of shared LayerComponent, it has many refs
                if (data.isShared) {
                    this._getRefs.forEach(ref => data.apply({ ...ref, layerId: this.id }))
                }
                // assign prop
                if (subComponentId) {
                    this._props[subComponentIdName] = data.id
                } else {
                    this._props[propName] = data
                }
                this._componentMap.set(data.id, data)
                newProp = data
            }
            // sets values from data to propName property
            else {
                let prop = this._props[propName]
                if (!prop) {
                    prop = this.dataStore.library.addProperty(propClass)
                    const propIdName = this._subComponentIds[propClass]
                    this._props[propIdName] = prop.id
                    this._componentMap.set(prop.id, prop)
                }
                // TODO: Get referenced data once we have the shared component
                const layerData = prop.save().data
                oldProp = Object.keys(data).reduce((acc, key) => {
                    if (key === 'image') {
                        acc[key] = layerData[key].data
                    } else {
                        acc[key] = layerData[key]
                    }
                    return acc
                }, {})

                // TODO: consider having separate set() on PropertyComponent that can do the same as setProperty() but down the data hierarchy
                //  use case: shared components deep inside some other shared components
                prop.set(data)
                newProp = data
            }
        }
        // if just value - not PropertyComponent
        else {
            if (!this._typeCheck(propName, data)) {
                throw new Error(
                    `Trying to set data of wrong type to property "${propName}" in ${this.elementId}(${this.id})`
                )
            }
            if (propName === 'paintId') {
                this._cachePaint(propName, data)
            }
            this._props[propName] = data
            newProp = this._props[propName]
        }

        // fire event if data was set not by ComputedLayer to prevent feedback loop
        if (fire) {
            this._fireLayerChangesEvent(propName, oldProp, newProp, flags)
        }
    }

    /**
     * Get PropertyComponent by its id (if it exists in this layer)
     * @param  {string} id
     * @returns {PropertyComponent}
     */
    getComponentById(id) {
        return this._componentMap.get(id)
    }

    get visible() {
        return this._props.visible
    }

    set visible(data) {
        this.setProperty('visible', data)
    }

    _fireLayerChangesEvent(propName, oldProp, newProp, flags) {
        const isEmpty = !propName
        if (isEmpty) {
            return
        }
        const change = new Change({
            before: oldProp,
            after: newProp
        })
        this.changes.update(propName, change)

        const isPropDataChangeOnly = !isEmpty & oldProp === newProp
        // add to Undo only when LAYER_CHANGES is NOT triggered by CHANGES event
        //  and if its not empty
        if (!flags && !isEmpty && !isPropDataChangeOnly) {
            // TODO: need to think if need to stop fire event in Layer changes
        }
    }

    get _layerChangesId() {
        if (this._ids.layerChange === Number.MAX_SAFE_INTEGER) {
            this._ids.layerChange = 1
        }
        return this._ids.layerChange++
    }

    _getRefs() {
        return this.component.appliedTo
    }

    _typeCheck(propName, data) {
        switch (propName) {
            case 'visible':
                return (data === true || data === false)
        }
        return true
    }

    /**
     * USE IT RESPONSIBLY!
     * @param {string} propName [description]
     * @param  {string} comId
     */
    _cachePaint(propName, comId) {
        if ((propName !== 'paintId') || comId === this._props.paintId) {
            return
        }

        // store previously used paint component per paint type
        const prevPaint = this.paint
        if (prevPaint) {
            this.paintCache[PAINT_CATEGORY_MAP[prevPaint.paintType]] = prevPaint.id
        }
    }

    clearChanges() {
        this.changes.clear()
    }
}


/** @typedef {('FILL' | 'STROKE' | 'SHADOW' | 'INNER_SHADOW' | 'EFFECT')} LayerType */

/**
 * @typedef {object} LayerData
 * @property {string} id
 * @property {boolean} enabled
 * @property {string} type
 * @property {LayerType} layerType
 */
