import React, { ReactNode } from 'react'
import FileLoading from './FileLoading'

type FileListLoadingProps = {
  children?: ReactNode
  iterations?: number
}

const FileLoadingWrapper = ({ children }: { children: ReactNode }) => (
  <div className="grid grid-cols-[repeat(auto-fill,minmax(220px,1fr))] gap-16 px-64">{children}</div>
)

// list of files loading
const FileListLoading = ({ children = null, iterations = 5 }: FileListLoadingProps) => {
  const items = Array.from({ length: iterations }, (_, index) => index)

  const validateChildren = React.Children.map(children, (child) => {
    if (React.isValidElement(child) && (child as React.ReactElement<any>).type === FileLoading) {
      return child as React.ReactElement<any>
    }
  })

  if (validateChildren && validateChildren.length > 0) {
    return <FileLoadingWrapper>{validateChildren}</FileLoadingWrapper>
  }
  return (
    <FileLoadingWrapper>
      {items.map((item) => (
        <div key={item}>
          <FileLoading />
        </div>
      ))}
    </FileLoadingWrapper>
  )
}

export default FileListLoading
