export const ShapeDirection = {
  SHAPE_DIRECTION_NORMAL: 'SHAPE_DIRECTION_NORMAL',
  SHAPE_DIRECTION_REVERSED: 'SHAPE_DIRECTION_REVERSED'
}

export const ShapeTypeFeature = {
  ELLIPSE_SHAPE: 'ELLIPSE_SHAPE',
  FILL_SHAPE: 'FILL_SHAPE',
  GRADIENT_FILL_SHAPE: 'GRADIENT_FILL_SHAPE',
  GRADIENT_STROKE_SHAPE: 'GRADIENT_STROKE_SHAPE',
  GROUP_SHAPE: 'GROUP_SHAPE',
  MERGE_SHAPE: 'MERGE_SHAPE',
  OFFSET_PATH_SHAPE: 'OFFSET_PATH_SHAPE',
  PATH_SHAPE: 'PATH_SHAPE',
  RECTANGLE_SHAPE: 'RECTANGLE_SHAPE',
  REPEATER_SHAPE: 'REPEATER_SHAPE',
  ROUNDED_CORNERS_SHAPE: 'ROUNDED_CORNERS_SHAPE',
  STAR_SHAPE: 'STAR_SHAPE',
  STROKE_SHAPE: 'STROKE_SHAPE',
  TRIM_SHAPE: 'TRIM_SHAPE',
  TWIST_SHAPE: 'TWIST_SHAPE',
  PUCKER_BLOAT_SHAPE: 'PUCKER_BLOAT_SHAPE',
  ZIG_ZAG_SHAPE: 'ZIG_ZAG_SHAPE'
}

export const RectangleShapeFeature = {
  SIZE: 'SIZE',
  SIZE_ANIMATION: 'SIZE_ANIMATION',
  ROUNDNESS: 'ROUNDNESS',
  ROUNDNESS_ANIMATION: 'ROUNDNESS_ANIMATION'
}

export const PolyStarShapeFeature = {
  POINTS: 'POINTS',
  POINTS_ANIMATION: 'POINTS_ANIMATION',
  INNER_RADIUS: 'INNER_RADIUS',
  INNER_RADIUS_ANIMATION: 'INNER_RADIUS_ANIMATION',
  OUTER_RADIUS: 'OUTER_RADIUS',
  OUTER_RADIUS_ANIMATION: 'OUTER_RADIUS_ANIMATION',
  INNER_ROUNDNESS: 'INNER_ROUNDNESS',
  INNER_ROUNDNESS_ANIMATION: 'INNER_ROUNDNESS_ANIMATION',
  OUTER_ROUNDNESS: 'OUTER_ROUNDNESS',
  OUTER_ROUNDNESS_ANIMATION: 'OUTER_ROUNDNESS_ANIMATION',
  STAR: 'STAR',
  POLYGON: 'POLYGON'
}

export const PathShapeFeature = {
  BEZIER: 'BEZIER',
  BEZIER_ANIMATION: 'BEZIER_ANIMATION'
}

export const FillShapeFeature = {
  COLOR: 'COLOR',
  COLOR_ANIMATION: 'COLOR_ANIMATION',
  FILL_NONE_ZERO: 'FILL_NONE_ZERO',
  FILL_EVEN_ODD: 'FILL_EVEN_ODD'
}

export const StrokeShapeFeature = {
  LINE_CAP_BUTT: 'LINE_CAP_BUTT',
  LINE_CAP_ROUND: 'LINE_CAP_ROUND',
  LINE_CAP_SQUARE: 'LINE_CAP_SQUARE',
  LINE_JOIN_MITER: 'LINE_JOIN_MITER',
  LINE_JOIN_ROUND: 'LINE_JOIN_ROUND',
  LINE_JOIN_BEVEL: 'LINE_JOIN_BEVEL',
  MITER: 'MITER',
  MITER_ANIMATION: 'MITER_ANIMATION',
  DASH_AND_GAP: 'DASH_AND_GAP'
}

export const GradientShapeFeature = {
  STARTING_POINT: 'STARTING_POINT',
  STARTING_POINT_ANIMATION: 'STARTING_POINT_ANIMATION',
  END_POINT: 'END_POINT',
  END_POINT_ANIMATION: 'END_POINT_ANIMATION',
  GRADIENT_LINEAR: 'GRADIENT_LINEAR',
  GRADIENT_RADIAL: 'GRADIENT_RADIAL',
  HIGHLIGHT_LENGTH: 'HIGHLIGHT_LENGTH',
  HIGHLIGHT_LENGTH_ANIMATION: 'HIGHLIGHT_LENGTH_ANIMATION',
  HIGHLIGHT_ANGLE: 'HIGHLIGHT_ANGLE',
  HIGHLIGHT_ANGLE_ANIMATION: 'HIGHLIGHT_ANGLE_ANIMATION',
  GRADIENT_COLOR: 'GRADIENT_COLOR',
  GRADIENT_COLOR_ANIMATION: 'GRADIENT_COLOR_ANIMATION'
}

export const ShapeFeature = {
  ...ShapeDirection,
  ...RectangleShapeFeature,
  ...ShapeTypeFeature,
  ...PolyStarShapeFeature,
  ...PathShapeFeature,
  ...FillShapeFeature,
  ...StrokeShapeFeature,
  ...GradientShapeFeature,
  TIME_REMAPPING: 'TIME_REMAPPING'
}
