import React, { useMemo } from 'react'
import { LayerType } from '@phase-software/types'
import { Input } from '../../../shared'
import { parseToDecimals } from '../../../../utils/number'
import { percentageFormatValidator } from '../../../../utils/validator'
import { useDataStoreActions } from '../../../../providers/dataStore/DataStoreProvider'
import { usePaint } from '../../../../providers/dataStore/PaintProvider'
import { useEditorActions } from '../../../../providers/dataStore/EditorProvider'
import { InputOptions } from '../../../shared/Input/Input.types'

type StrokeOpacityProps = {
  layerItemId: string
}

const StrokeOpacity = ({ layerItemId }: StrokeOpacityProps) => {
  const opacity = usePaint((o) => o[layerItemId].opacity)
  const { commitUndo, debounceCommitUndo } = useDataStoreActions()
  const { setLayerPaint, toggleLayerVisible } = useEditorActions()

  const keyFrameIconProps = useMemo(
    () => ({
      name: 'opacity',
      layerItemId,
      layerType: LayerType.STROKE
    }),
    [layerItemId]
  )

  const _opacity = isNaN(opacity) ? opacity : parseToDecimals(opacity * 100, 2)
  return (
    <Input
      // @ts-ignore TODO: fix after this component is being used
      keyFrameIconProps={keyFrameIconProps}
      min={0}
      max={100}
      type="number"
      variant="normal"
      data-test-id="border-opacity-input"
      formatter={(v: number) => `${parseToDecimals(v, 2)}%`}
      validator={percentageFormatValidator}
      value={_opacity}
      onChange={(v: number, options: InputOptions) => setLayerPaint(layerItemId, { opacity: v / 100 }, options)}
      onFocus={() => toggleLayerVisible(layerItemId, true)}
      onBlur={commitUndo}
      onStepChange={debounceCommitUndo}
      spinner
    />
  )
}

export default StrokeOpacity
