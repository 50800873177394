import React from 'react'
import BasicPlaybackControl, { BasicPlaybackControlProps } from './BasicPlaybackControl'
import ProgressBarPlaybackControl from './ProgressBarPlaybackControl'

export enum PlayBackControlType {
  BASIC = 'basic',
  PROGRESS_BAR = 'progressBar'
}

type PlayBackControlProps =
  | ({ type: PlayBackControlType.BASIC } & BasicPlaybackControlProps)
  | { type: PlayBackControlType.PROGRESS_BAR }

const PlayBackControl = (props: PlayBackControlProps) => {
  if (props.type === PlayBackControlType.BASIC) {
    return <BasicPlaybackControl {...props} />
  }
  return <ProgressBarPlaybackControl />
}

PlayBackControl.displayName = 'PlayBackControl'

export default React.memo(PlayBackControl)
