import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Tooltip } from '../../shared'

const FlipGradientButton = ({ reverseStops }: { reverseStops: () => void }) => {
  const { t } = useTranslation('file', { keyPrefix: 'property_editor.fill_editor.paint_picker.gradient_stop' })

  return (
    <Tooltip content={t('reverse_button_tooltip')}>
      <Button color="secondary" onClick={reverseStops} size="s" icon="Toggle" />
    </Tooltip>
  )
}

export default FlipGradientButton
