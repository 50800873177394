import { IRGroup } from './IRGroup'

export class IRImageMask extends IRGroup {
  static defaultValue = {
    ...super.defaultValue,
    computed: false
  }

  constructor(data = IRImageMask.defaultValue, parent = null) {
    super(data, parent)
    this.computed = data.computed
  }

  fromJSON(data) {
    super.fromJSON(data)
    this.computed = data.computed
    return this
  }

  toJSON() {
    return {
      ...super.toJSON(),
      computed: this.computed
    }
  }
}
