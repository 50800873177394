import { useEffect, useCallback, memo } from 'react'
import { useDataStore, useSetDataStore } from '../../providers/dataStore/DataStoreProvider'
import { useFlattenElementListActions } from '../../providers/dataStore/FlattenElementListProvider'
import { ToolType } from '@phase-software/types'

const SubscribeElementSelectionChange = () => {
  const dataStore = useDataStore()
  const { expandAncestors } = useFlattenElementListActions()
  const { setElementSelection } = useSetDataStore()
  const cb = useCallback(
    (changes) => {
      const change = changes.get('elements')
      if (!change) {
        return
      }
      dataStore.eam.closeModal()

      const elementIds = change.after.map((el) => el.get('id'))
      setElementSelection(elementIds)
      if (elementIds.length > 0) {
        elementIds.forEach((elId) => expandAncestors(elId))
      }
      if (dataStore.eam.states.activeTool === ToolType.EYE_DROPPER) {
        dataStore.eam.setLastGeneralTool()
      }
    },
    [dataStore.eam, expandAncestors, setElementSelection]
  )
  useEffect(() => {
    if (!dataStore) return
    dataStore.selection.on('SELECT', cb)
    return () => {
      dataStore.selection.off('SELECT', cb)
    }
  }, [dataStore, cb])
  return null
}

export default memo(SubscribeElementSelectionChange)
