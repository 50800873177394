import { notNull } from '@phase-software/data-utils'
import { Transform } from './Transform'

/** @typedef {import("../math/Transform2D").Transform2D} Transform2D */

const EMPTY_OBJ = {}

export class BaseTransform extends Transform {

    /**
     * @param {object} options
     * @param {number} [options.translateX]
     * @param {number} [options.translateY]
     */
    setTranslate({ translateX, translateY } = EMPTY_OBJ) {
        const v = this._data.translate
        if (notNull(translateX)) v.x = translateX
        if (notNull(translateY)) v.y = translateY
        this._dirty = true
    }

    /**
     * @param {object} options
     * @param {number} [options.referencePointX]
     * @param {number} [options.referencePointY]
     */
    setReferencePoint({ referencePointX, referencePointY } = EMPTY_OBJ) {
        if (!this._data) return
        const v = this._data.referencePoint
        if (notNull(referencePointX)) v.x = referencePointX
        if (notNull(referencePointY)) v.y = referencePointY
        this._dirty = true
    }

    /**
     * @param {object} options
     * @param {number} [options.contentAnchorX]
     * @param {number} [options.contentAnchorY]
     */
    setContentAnchor({ contentAnchorX, contentAnchorY } = EMPTY_OBJ) {
        const v = this._data.contentAnchor
        if (notNull(contentAnchorX)) v.x = contentAnchorX
        if (notNull(contentAnchorY)) v.y = contentAnchorY
        this._dirty = true
    }

    /**
     * @param {object} options
     * @param {number} [options.scaleX]
     * @param {number} [options.scaleY]
     */
    setScale({ scaleX, scaleY } = EMPTY_OBJ) {
        const v = this._data.scale
        if (notNull(scaleX)) v.x = scaleX
        if (notNull(scaleY)) v.y = scaleY
        this._dirty = true
    }

    /**
     * @param {object} options
     * @param {number} [options.skewX]
     * @param {number} [options.skewY]
     */
    setSkew({ skewX, skewY } = EMPTY_OBJ) {
        const v = this._data.skew
        if (notNull(skewX)) v.x = skewX
        if (notNull(skewY)) v.y = skewY
        this._dirty = true
    }

    /**
     * @param {object} options
     * @param {number} [options.width]
     * @param {number} [options.height]
     */
    setSize({ width, height } = EMPTY_OBJ) {
        const v = this._data.size
        if (notNull(width)) v.x = width
        if (notNull(height)) v.y = height
        this._dirty = true
    }

    /**
     * @param {object} options
     * @param {number} options.rotation
     */
    setRotation({ rotation } = EMPTY_OBJ) {
        if (notNull(rotation)) this._data.rotation = rotation
        this._dirty = true
    }

}
