import PropertyStack from '../PropertyStack'

class SkewXStack extends PropertyStack {
  constructor(elementStack, data) {
    super(elementStack, data)
    this.type = 'SKEW_X'
    this.key = 'skewX'
    this.dataKey = 'skewX'
    this.animatableProperties = new Set(['skewX'])
  }
}

export default SkewXStack
