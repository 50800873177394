/** @typedef {import("../math/Vector2").Vector2Like} Vector2Like */

// const
const EPS = 0.000001
const nMax = Number.MAX_SAFE_INTEGER || 9007199254740991
const nMin = Number.MIN_SAFE_INTEGER || -9007199254740991
const ZERO = { x: 0, y: 0 }

// math inlining
const PI = Math.PI
const abs = Math.abs
const cos = Math.cos
const sin = Math.sin
const acos = Math.acos
const atan2 = Math.atan2
const sqrt = Math.sqrt
const pow = Math.pow

// Legendre-Gauss abscissae with n=24 (x_i values, defined at i=n as the roots of the nth order Legendre polynomial Pn(x))
const T_VALUES = [
    -0.0640568928626056260850430826247450385909,
    0.0640568928626056260850430826247450385909,
    -0.1911188674736163091586398207570696318404,
    0.1911188674736163091586398207570696318404,
    -0.3150426796961633743867932913198102407864,
    0.3150426796961633743867932913198102407864,
    -0.4337935076260451384870842319133497124524,
    0.4337935076260451384870842319133497124524,
    -0.5454214713888395356583756172183723700107,
    0.5454214713888395356583756172183723700107,
    -0.6480936519369755692524957869107476266696,
    0.6480936519369755692524957869107476266696,
    -0.7401241915785543642438281030999784255232,
    0.7401241915785543642438281030999784255232,
    -0.8200019859739029219539498726697452080761,
    0.8200019859739029219539498726697452080761,
    -0.8864155270044010342131543419821967550873,
    0.8864155270044010342131543419821967550873,
    -0.9382745520027327585236490017087214496548,
    0.9382745520027327585236490017087214496548,
    -0.9747285559713094981983919930081690617411,
    0.9747285559713094981983919930081690617411,
    -0.9951872199970213601799974097007368118745,
    0.9951872199970213601799974097007368118745,
]

// Legendre-Gauss weights with n=24 (w_i values, defined by a function linked to in the Bezier primer article)
const C_VALUES = [
    0.1279381953467521569740561652246953718517,
    0.1279381953467521569740561652246953718517,
    0.1258374563468282961213753825111836887264,
    0.1258374563468282961213753825111836887264,
    0.121670472927803391204463153476262425607,
    0.121670472927803391204463153476262425607,
    0.1155056680537256013533444839067835598622,
    0.1155056680537256013533444839067835598622,
    0.1074442701159656347825773424466062227946,
    0.1074442701159656347825773424466062227946,
    0.0976186521041138882698806644642471544279,
    0.0976186521041138882698806644642471544279,
    0.086190161531953275917185202983742667185,
    0.086190161531953275917185202983742667185,
    0.0733464814110803057340336152531165181193,
    0.0733464814110803057340336152531165181193,
    0.0592985849154367807463677585001085845412,
    0.0592985849154367807463677585001085845412,
    0.0442774388174198061686027482113382288593,
    0.0442774388174198061686027482113382288593,
    0.0285313886289336631813078159518782864491,
    0.0285313886289336631813078159518782864491,
    0.0123412297999871995468056670700372915759,
    0.0123412297999871995468056670700372915759,
]

// utils

/**
 * @param {Vector2Like[]} points
 * @param {{ p1: Vector2Like, p2: Vector2Like }} line
 * @returns {Vector2Like[]}
 */
function align(points, line) {
    const tx = line.p1.x,
        ty = line.p1.y,
        a = -atan2(line.p2.y - ty, line.p2.x - tx)
    return points.map((v) => ({
        x: (v.x - tx) * cos(a) - (v.y - ty) * sin(a),
        y: (v.x - tx) * sin(a) + (v.y - ty) * cos(a),
    }))
}

/**
 * @param {Vector2Like[]} points
 * @returns {Vector2Like[][]}
 */
function derive(points) {
    /** @type {Vector2Like[][]} */
    const dpoints = []
    for (let p = points, d = p.length, c = d - 1; d > 1; d--, c--) {
        /** @type {Vector2Like[]} */
        const list = []
        for (let j = 0; j < c; j++) {
            list.push({
                x: c * (p[j + 1].x - p[j].x),
                y: c * (p[j + 1].y - p[j].y),
            })
        }
        dpoints.push(list)
        p = list
    }
    return dpoints
}

/**
 * @param {number} a
 * @param {number} b
 * @returns {boolean}
 */
function approxEqual(a, b) {
    return abs(a - b) <= EPS
}

/**
 * @param {number} v
 * @param {number} ds
 * @param {number} de
 * @param {number} ts
 * @param {number} te
 * @returns {number}
 */
function map(v, ds, de, ts, te) {
    const d1 = de - ds,
        d2 = te - ts,
        v2 = v - ds,
        r = v2 / d1
    return ts + d2 * r
}

/**
 * @param {number} r
 * @param {Vector2Like} v1
 * @param {Vector2Like} v2
 * @returns {Vector2Like}
 */
function lerp(r, v1, v2) {
    return {
        x: v1.x + r * (v2.x - v1.x),
        y: v1.y + r * (v2.y - v1.y),
    }
}

/**
 * @param {number[]} p
 * @returns {number[]}
 */
function droots(p) {
    if (p.length === 3) {
        const a = p[0]
        const b = p[1]
        const c = p[2]
        const d = a - 2 * b + c
        if (Math.abs(d) > EPS) {
            const m1 = -sqrt(b * b - a * c)
            const m2 = -a + b
            const v1 = -(m1 + m2) / d
            const v2 = -(-m1 + m2) / d
            return [v1, v2]
        } else if (Math.abs(b - c) > EPS && Math.abs(d) <= EPS) {
            return [(2 * b - c) / (2 * (b - c))]
        }
        return []
    }

    if (p.length === 2) {
        const a = p[0]
        const b = p[1]
        if (a !== b) {
            return [a / (a - b)]
        }
        return []
    }
}

/**
 * @param {number} a
 * @param {number} b
 * @returns {number}
 */
function numberSort(a, b) {
    return a - b
}

/**
 * @param {CubicBezier} curve
 * @param {string} d
 * @param {number[]} list
 * @returns {{ min: number, mid: number, max: number, size: number }}
 */
function getminmax(curve, d, list) {
    if (!list) {
        return {
            min: 0,
            mid: 0,
            max: 0,
            size: 0,
        }
    }
    let min = nMax
    let max = nMin
    let t = 0
    /** @type {Vector2Like} */
    let c = null
    if (list.indexOf(0) === -1) {
        list.unshift(0)
    }
    if (list.indexOf(1) === -1) {
        list.push(1)
    }
    for (let i = 0, len = list.length; i < len; i++) {
        t = list[i]
        c = curve.get(t)
        if (c[d] < min) {
            min = c[d]
        }
        if (c[d] > max) {
            max = c[d]
        }
    }
    return {
        min: min,
        mid: (min + max) / 2,
        max: max,
        size: max - min,
    }
}

/**
 * @param {number} t
 * @param {Vector2Like[]} points
 * @returns {Vector2Like}
 */
function compute(t, points) {
    if (t === 0) {
        return points[0]
    }

    const order = points.length - 1

    if (t === 1) {
        return points[order]
    }

    let p = points
    const mt = 1 - t

    // constant?
    if (order === 0) {
        return points[0]
    }

    /** @type {Vector2Like} */
    let ret = null

    // linear?
    if (order === 1) {
        ret = {
            x: mt * p[0].x + t * p[1].x,
            y: mt * p[0].y + t * p[1].y,
        }
        return ret
    }

    // quadratic/cubic curve?
    if (order < 4) {
        const mt2 = mt * mt
        const t2 = t * t
        let a
        let b
        let c
        let d = 0
        if (order === 2) {
            p = [p[0], p[1], p[2], ZERO]
            a = mt2
            b = mt * t * 2
            c = t2
        } else if (order === 3) {
            a = mt2 * mt
            b = mt2 * t * 3
            c = mt * t2 * 3
            d = t * t2
        }
        const ret = {
            x: a * p[0].x + b * p[1].x + c * p[2].x + d * p[3].x,
            y: a * p[0].y + b * p[1].y + c * p[2].y + d * p[3].y,
        }
        return ret
    }

    // higher order curves: use de Casteljau's computation
    /** @type {Vector2Like[]} */
    const dCpts = JSON.parse(JSON.stringify(points))
    while (dCpts.length > 1) {
        for (let i = 0; i < dCpts.length - 1; i++) {
            dCpts[i] = {
                x: dCpts[i].x + (dCpts[i + 1].x - dCpts[i].x) * t,
                y: dCpts[i].y + (dCpts[i + 1].y - dCpts[i].y) * t,
            }
        }
        dCpts.splice(dCpts.length - 1, 1)
    }
    return dCpts[0]
}

/**
 * @param {Vector2Like} o
 * @param {Vector2Like} v1
 * @param {Vector2Like} v2
 * @returns {number}
 */
function vecAngle(o, v1, v2) {
    const dx1 = v1.x - o.x
    const dy1 = v1.y - o.y
    const dx2 = v2.x - o.x
    const dy2 = v2.y - o.y
    const cross = dx1 * dy2 - dy1 * dx2
    const dot = dx1 * dx2 + dy1 * dy2
    return atan2(cross, dot)
}

/**
 * @param {Vector2Like} p1
 * @param {Vector2Like} p2
 * @returns {number}
 */
function vecDist(p1, p2) {
    const dx = p1.x - p2.x
    const dy = p1.y - p2.y
    return sqrt(dx * dx + dy * dy)
}

/**
 * @param {Vector2Like[]} LUT
 * @param {Vector2Like} point
 * @returns {{ mdist: number, mpos: number }}
 */
function getClosestPoint(LUT, point) {
    let mdist = pow(2, 63)
    let mpos = 0
    let d = 0
    LUT.forEach(function (p, idx) {
        d = vecDist(point, p)
        if (d < mdist) {
            mdist = d
            mpos = idx
        }
    })
    return {
        mdist: mdist,
        mpos: mpos,
    }
}

/**
 * @param {Vector2Like[]} points
 * @returns {number[]}
 */
function inflections(points) {
    if (points.length < 4) return []

    // FIXME: add in inflection abstraction for quartic+ curves?

    const p = align(points, { p1: points[0], p2: points.slice(-1)[0] })
    const a = p[2].x * p[1].y
    const b = p[3].x * p[1].y
    const c = p[1].x * p[2].y
    let d = p[3].x * p[2].y
    const v1 = 18 * (-3 * a + 2 * b + 3 * c - d)
    const v2 = 18 * (3 * a - b - 3 * c)
    const v3 = 18 * (c - a)

    if (approxEqual(v1, 0)) {
        if (!approxEqual(v2, 0)) {
            const t = -v3 / v2
            if (0 <= t && t <= 1) return [t]
        }
        return []
    }

    const trm = v2 * v2 - 4 * v1 * v3,
        sq = sqrt(trm)

    d = 2 * v1

    if (approxEqual(d, 0)) return []

    return [(sq - v2) / d, -(v2 + sq) / d].filter((r) => (0 <= r && r <= 1))
}

/**
 * @param {number} t
 * @param {(t: number) => Vector2Like} derivativeFn
 * @returns {number}
 */
function arcfn(t, derivativeFn) {
    const d = derivativeFn(t)
    const l = d.x * d.x + d.y * d.y
    return sqrt(l)
}

/**
 * @param {(t: number) => Vector2Like} derivativeFn
 * @returns {number}
 */
function length(derivativeFn) {
    const z = 0.5
    let sum = 0
    const len = T_VALUES.length
    for (let i = 0; i < len; i++) {
        const t = z * T_VALUES[i] + z
        sum += C_VALUES[i] * arcfn(t, derivativeFn)
    }
    return z * sum
}

/**
 * @param {number} s
 * @param {number} e
 * @param {number} tlen
 * @param {number} alen
 * @param {number} slen
 * @returns {(v: number) => number}
 */
// eslint-disable-next-line no-unused-vars
function linearDistanceFunction(s, e, tlen, alen, slen) {
    return v => {
        const f1 = alen / tlen
        const f2 = (alen + slen) / tlen
        const d = e - s
        return map(v, 0, 1, s + f1 * d, s + f2 * d)
    }
}

/**
 * @param {number} x1
 * @param {number} y1
 * @param {number} x2
 * @param {number} y2
 * @param {number} x3
 * @param {number} y3
 * @param {number} x4
 * @param {number} y4
 * @returns {Vector2Like}
 */
function lli8(x1, y1, x2, y2, x3, y3, x4, y4) {
    const nx = (x1 * y2 - y1 * x2) * (x3 - x4) - (x1 - x2) * (x3 * y4 - y3 * x4)
    const ny = (x1 * y2 - y1 * x2) * (y3 - y4) - (y1 - y2) * (x3 * y4 - y3 * x4)
    const d = (x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4)
    if (d === 0) {
        return null
    }
    return {
        x: nx / d,
        y: ny / d,
    }
}

/**
 * @param {Vector2Like} p1
 * @param {Vector2Like} p2
 * @param {Vector2Like} p3
 * @param {Vector2Like} p4
 * @returns {Vector2Like}
 */
function lli4(p1, p2, p3, p4) {
    const x1 = p1.x
    const y1 = p1.y
    const x2 = p2.x
    const y2 = p2.y
    const x3 = p3.x
    const y3 = p3.y
    const x4 = p4.x
    const y4 = p4.y
    return lli8(x1, y1, x2, y2, x3, y3, x4, y4)
}

/**
 * @param {Vector2Like} vec
 * @returns {Vector2Like}
 */
function vec2_clone(vec) {
    return { x: vec.x, y: vec.y }
}

/**
 * @typedef SplitCurvePair
 * @property {CubicBezier} left
 * @property {CubicBezier} right
 * @property {Vector2Like[]} span
 */

export class CubicBezier {
    /**
     * @param {number} x1
     * @param {number} y1
     * @param {number} x2
     * @param {number} y2
     * @returns {CubicBezier}
     */
    static line(x1, y1, x2, y2) {
        const dx = (x2 - x1) / 3
        const dy = (y2 - y1) / 3

        const curve = new CubicBezier([
            x1, y1,
            x1 + dx, y1 + dy,
            x2 - dx, y2 - dy,
            x2, y2
        ])
        curve.typeLine = true
        return curve
    }

    /**
     * @param {number} x1
     * @param {number} y1
     * @param {number} cx
     * @param {number} cy
     * @param {number} x2
     * @param {number} y2
     * @returns {CubicBezier}
     */
    static quad(x1, y1, cx, cy, x2, y2) {
        const origC = [x1 / 3, y1 / 3]
        const ctrlC = [cx * 2 / 3, cy * 2 / 3]
        const destC = [x2 / 3, y2 / 3]

        return new CubicBezier([
            x1, y1,
            origC[0] + ctrlC[0], origC[1] + ctrlC[1],
            destC[0] + ctrlC[0], destC[1] + ctrlC[1],
            x2, y2
        ])
    }

    /**
     * @param {number} x1
     * @param {number} y1
     * @param {number} c1x
     * @param {number} c1y
     * @param {number} c2x
     * @param {number} c2y
     * @param {number} x2
     * @param {number} y2
     * @returns {CubicBezier}
     */
    static cubic(x1, y1, c1x, c1y, c2x, c2y, x2, y2) {
        return new CubicBezier([x1, y1, c1x, c1y, c2x, c2y, x2, y2])
    }

    /**
     * @param {number[]} coords
     */
    constructor(coords) {
        this.typeLine = false

        const len = coords.length
        /** @type {Vector2Like[]} */
        const points = []
        for (let idx = 0; idx < len; idx += 2) {
            points.push({
                x: coords[idx],
                y: coords[idx + 1],
            })
        }
        this.points = points
        this.order = points.length - 1

        {
            this._linear = true
            const order = this.order
            const a = align(points, { p1: points[0], p2: points[order] })
            for (let i = 0; i < a.length; i++) {
                if (abs(a[i].y) > 0.0001) {
                    this._linear = false
                    break
                }
            }
        }

        this._t1 = 0
        this._t2 = 0
        this.update()
    }


    // public

    isLine() {
        return this.typeLine
    }
    isQuad() {
        return !this.typeLine && this.order === 2
    }
    isCubic() {
        return !this.typeLine && this.order === 3
    }

    /**
     * @param {number} t
     * @returns {Vector2Like}
     */
    get(t) {
        return compute(t, this.points)
    }

    /**
     * @param {number} t
     * @returns {Vector2Like}
     */
    normal(t) {
        const d = this.derivative(t)
        const q = sqrt(d.x * d.x + d.y * d.y)
        return {
            x: -d.y / q,
            y: d.x / q,
        }
    }

    length() {
        return length(this.derivative.bind(this))
    }

    /**
     * @param {Vector2Like} point
     * @returns {{ x: number, y: number, t: number, d: number }}
     */
    project(point) {
        const LUT = this.getLUT()
        const l = LUT.length - 1
        const closest = getClosestPoint(LUT, point)
        let mdist = closest.mdist
        const mpos = closest.mpos

        let ft = 0
        let t = 0
        /** @type {Vector2Like} */
        let p
        let d = 0
        const t1 = (mpos - 1) / l
        const t2 = (mpos + 1) / l
        const step = 0.1 / l
        mdist += 1
        for (t = t1, ft = t; t < t2 + step; t += step) {
            p = this.get(t)
            d = vecDist(point, p)
            if (d < mdist) {
                mdist = d
                ft = t
            }
        }
        p = this.get(ft)
        return {
            x: p.x,
            y: p.y,
            t: ft,
            d: mdist,
        }
    }


    /**
     * @param {number} t
     * @param {CubicBezier} r_left
     * @param {CubicBezier} r_right
     */
    split(t, r_left, r_right) {
        const self = this.points
        const left = r_left.points
        const right = r_right.points

        left[0].x = self[0].x
        left[0].y = self[0].y
        left[1].x = self[0].x + t * (self[1].x - self[0].x)
        left[1].y = self[0].y + t * (self[1].y - self[0].x)
        left[2].x = self[1].x + t * (self[2].x - self[1].x)
        left[2].y = self[1].y + t * (self[2].y - self[1].y)
        right[2].x = self[2].x + t * (self[3].x - self[2].x)
        right[2].y = self[2].y + t * (self[3].y - self[2].y)
        right[1].x = left[2].x + t * (right[2].x - left[2].x)
        right[1].y = left[2].y + t * (right[2].y - left[2].y)
        left[2].x = left[1].x + t * (left[2].x - left[1].x)
        left[2].y = left[1].y + t * (left[2].y - left[1].y)
        right[0].x = left[2].x + t * (right[1].x - left[2].x)
        right[0].y = left[2].y + t * (right[1].y - left[2].y)
        left[3].x = right[0].x
        left[3].y = right[0].y
        right[3].x = self[3].x
        right[3].y = self[3].y
    }

    /**
     * @param {number} t
     * @param {number} d
     * @returns {{ c: Vector2Like, n: Vector2Like, x: number, y: number }}
     */
    offset(t, d) {
        const c = this.get(t)
        const n = this.normal(t)
        return {
            c,
            n,
            x: c.x + n.x * d,
            y: c.y + n.y * d,
        }
    }

    /**
     * @param {number} d
     * @returns {CubicBezier}
     */
    scale(d) {
        const order = this.order

        const r1 = d
        const r2 = d
        const v = [this.offset(0, 10), this.offset(1, 10)]
        const o = lli4(v[0], v[0].c, v[1], v[1].c)
        if (!o) {
            throw new Error("cannot scale this curve. Try reducing it first.")
        }
        // move all points by distance 'd' wrt the origin 'o'
        const points = this.points
        const np = []

        // move end points by fixed distance along normal.
        for (let t = 0; t <= 1; t++) {
            const p = vec2_clone(points[t * order])
            np[t * order] = p
            p.x += (t ? r2 : r1) * v[t].n.x
            p.y += (t ? r2 : r1) * v[t].n.y
        }

        // move control points to lie on the intersection of the offset
        // derivative vector, and the origin-through-control vector
        for (let t = 0; t <= 1; t++) {
            if (this.order === 2 && !!t) continue
            const p = np[t * order]
            const d = this.derivative(t)
            const p2 = { x: p.x + d.x, y: p.y + d.y }
            np[t + 1] = lli4(p, p2, o, points[t + 1])
        }

        /** @type {number[]} */
        const verts = []
        for (const p of np) {
            verts.push(p.x, p.y)
        }
        return new CubicBezier(verts)
    }

    bbox() {
        const extrema = this.extrema()
        return {
            x: getminmax(this, 'x', extrema.x),
            y: getminmax(this, 'y', extrema.y),
        }
    }

    /**
     * @param {number} t
     * @returns {Vector2Like[]}
     */
    hull(t) {
        let p = this.points
        /** @type {Vector2Like[]} */
        let _p = []
        /** @type {Vector2Like} */
        let pt = null
        /** @type {Vector2Like[]} */
        const q = []
        let idx = 0
        let i = 0
        let l = 0
        q[idx++] = p[0]
        q[idx++] = p[1]
        q[idx++] = p[2]
        if (this.order === 3) {
            q[idx++] = p[3]
        }
        // we lerp between all points at each iteration, until we have 1 point left.
        while (p.length > 1) {
            _p = []
            for (i = 0, l = p.length - 1; i < l; i++) {
                pt = lerp(t, p[i], p[i + 1])
                q[idx++] = pt
                _p.push(pt)
            }
            p = _p
        }
        return q
    }


    /**
     * @param {number} t
     * @returns {Vector2Like}
     */
    derivative(t) {
        const mt = 1 - t
        let a = mt
        let b = t
        let c = 0
        let p = this.dpoints[0]
        if (this.order === 2) {
            p = [p[0], p[1], ZERO]
        } else if (this.order === 3) {
            a = mt * mt
            b = mt * t * 2
            c = t * t
        }
        return {
            x: a * p[0].x + b * p[1].x + c * p[2].x,
            y: a * p[0].y + b * p[1].y + c * p[2].y,
        }
    }

    inflections() {
        return inflections(this.points)
    }

    extrema() {
        const dims = ['x', 'y']
        const result = {
            /** @type {number[]} */
            x: null,
            /** @type {number[]} */
            y: null,
            /** @type {number[]} */
            values: null,
        }
        /** @type {number[]} */
        let roots = []
        /** @type {number[]} */
        let p = null
        /** @type {(v: Vector2Like) => number} */
        let mfn = null
        dims.forEach((dim) => {
            mfn = (v) => v[dim]
            p = this.dpoints[0].map(mfn)
            result[dim] = droots(p)
            if (this.order === 3) {
                p = this.dpoints[1].map(mfn)
                result[dim] = result[dim].concat(droots(p))
            }
            result[dim] = result[dim].filter((t) => t >= 0 && t <= 1)
            roots = roots.concat(result[dim].sort(numberSort))
        })
        roots = roots.sort(numberSort).filter((v, idx) => roots.indexOf(v) === idx)
        result.values = roots
        return result
    }


    // private

    update() {
        /** @type {Vector2Like[]} */
        this._lut = []
        this.dpoints = derive(this.points)
        this.computeDirection()
    }
    computeDirection() {
        this.clockwise = vecAngle(this.points[0], this.points[this.order], this.points[1]) > 0
    }
    /**
     * @param {number} [steps]
     * @returns {Vector2Like[]}
     */
    getLUT(steps = 100) {
        // TODO: force update if changed
        if (this._lut.length === steps) return this._lut
        /** @type {Vector2Like[]} */
        this._lut.length = 0
        const _steps = steps - 1
        for (let t = 0; t <= _steps; t++) {
            this._lut.push(this.get(t / _steps))
        }
        return this._lut
    }
    simple() {
        if (this.order === 3) {
            const a1 = vecAngle(this.points[0], this.points[3], this.points[1])
            const a2 = vecAngle(this.points[0], this.points[3], this.points[2])
            if ((a1 > 0 && a2 < 0) || (a1 < 0 && a2 > 0)) return false
        }
        const n1 = this.normal(0)
        const n2 = this.normal(1)
        const s = n1.x * n2.x + n1.y * n2.y
        const angle = abs(acos(s))
        return angle < PI / 3
    }
}
