import { PaintType } from '@phase-software/types'
import Interval from '../../helpers/Interval'
import { matchGradientStops } from '../../utils/data'
import { DEFAULT_GRADIENT_TRANSFORM } from '../constants'
import PropertyStack from '../PropertyStack'

class PaintStack extends PropertyStack {
  constructor(elementStack, data) {
    super(elementStack, data)
    this.type = 'PAINT'
    this.key = 'paint'
    this.dataKey = 'color'
    this.animatableProperties = new Set(['color', 'gradientStops', 'gradientTransform'])

    this._subInterval = new Interval()
    this._subSubInterval = new Interval()
  }

  _updateDataKeyByPaintType(paintType) {
    if (paintType === PaintType.SOLID) {
      this.dataKey = 'color'
    } else if (paintType === PaintType.IMAGE) {
      // No change
      this.dataKey = 'imageId'
    } else {
      this.dataKey = 'gradientStops'
    }
  }

  getPaintType() {
    const firstKF = this.actions[0] && this.actions[0].kfs[0]
    if (!firstKF) {
      return PaintType.SOLID
    }

    const paint = this.dataStore.library.getComponent(firstKF.ref)
    if (paint) {
      return paint.paintType
    }

    return PaintType.SOLID
  }

  /**
   * Get default value for a computedLayer
   * @returns {Array}
   */
  getInitValue() {
    const paintType = this.getPaintType()
    const parent = this.manager.cache.getById(this.parentId)
    if (!parent) {
      return null
    }
    
    const baseData = parent.getBaseValue()
    const firstNonInitData = parent._getFirstNonInitPaint(this)

    // use first non init data if paint type is the same, otherwise use base data
    let prop = (baseData.paintType === firstNonInitData.paintType) ? baseData : firstNonInitData
    if (firstNonInitData.paintType === undefined) {
      prop = baseData
    }

    switch (paintType) {
        case PaintType.SOLID:
            return prop.color
        case PaintType.IMAGE:
            return {
                imageId: prop.imageId,
                imageMode: prop.imageMode
            }
        case PaintType.GRADIENT_LINEAR:
        case PaintType.GRADIENT_RADIAL:
        case PaintType.GRADIENT_ANGULAR:
        case PaintType.GRADIENT_DIAMOND:
            return {
                gradientStops: prop.gradientStops || [],
                gradientTransform: prop.gradientTransform || []
            }
    }
  }

  /**
   * Get working interval by time
   * @protected
   * @param {number} time
   * @returns {Interval | undefined}  interval (READONLY) or undefined if no interval found
   *                                    DO NOT modify this returned value (outside of this class and it's subclasses)
   */
  getWorkingInterval(time) {
    const interval = super.getWorkingInterval(time)
    for (const kf of interval) {
      if (kf.value === undefined) {
        const paintComponent = this.dataStore.library.getComponent(kf.ref)
        if (!paintComponent) {
          kf.value = this.getInitValue()
          continue
        }

        const paintType = paintComponent.paintType
        if (paintType === PaintType.SOLID) {
          // SOLID paint type
          kf.value = paintComponent.color
        } else if (paintType === PaintType.IMAGE) {
          // IMAGE paint type can not have interpolation change
          kf.value = {
            imageId: paintComponent.imageId,
            imageMode: paintComponent.imageMode
          }
        } else {
          // GRADIENT paint type
          // TODO: @nicktgn consider converting ColorStop list into an interleaved array of numbers
          kf.value = {
            gradientStops: paintComponent.gradientStops.map(gradientStop => ({
              position: gradientStop.position,
              color: [...gradientStop.color]
            })),
            gradientTransform: [...paintComponent.gradientTransform]
          }
        }
      }
    }

    return interval
  }

  /**
   * Get animation data
   * @param {number} time
   * @returns {object}
   */
  getAnimateData(time) {
    const parent = this.manager.cache.getById(this.parentId)
    if (!parent.computedLayer) {
      return {}
    }

    const interval = this.getWorkingInterval(time)
    if (!interval) {
      return {}
    }

    // Get paint data with the paint ref from the keyframe
    const workingTime = this.getWorkingTime(time)
    const result = this._getPaintChange(interval, workingTime)
    return result
  }

  /**
   * Get specific value for a color from the interval
   * @param {Interval} interval
   * @param {number} idx
   * @param {string} key
   * @returns {Interval}
   */
  _getIntervalWithColorValue(interval, idx, key) {
    const outInt = this._subSubInterval
    outInt.copy(interval, true)

    let v1 = interval.start.value
    let v2 = interval.end.value
    if (key) {
      v1 = v1[key]
      v2 = v2[key]
    }
    outInt.start.value = v1[idx]
    outInt.end.value = v2[idx]
    return outInt
  }

  /**
   * Get paint change with different paint type in a specific time
   * @param {Interval} interval
   * @param {number} workingTime
   * @returns {Array}
   */
  _getPaintChange(interval, workingTime) {
    // The first one keyframe might not have paint ref.
    // So, we use the last one keyframe to find the ref paint component.
    const last = interval.end
    const paintComponent = this.dataStore.library.getComponent(last.ref)
    if (!paintComponent) {
      return
    }

    const paintType = paintComponent.paintType
    this._updateDataKeyByPaintType(paintType)
    switch(paintType) {
        case PaintType.SOLID:
            return this._getSolidColorChange(interval, workingTime)
        case PaintType.IMAGE:
            return this._getImageChange(interval)
        case PaintType.GRADIENT_LINEAR:
        case PaintType.GRADIENT_RADIAL:
        case PaintType.GRADIENT_ANGULAR:
        case PaintType.GRADIENT_DIAMOND:
            return this._getGradientColorChange(interval, workingTime)
    }
  }

  /**
   * Get SOLID color change in a specific time
   * @param {[Keyframe, Keyframe]} interval
   * @param {number} workingTime
   * @returns {Array}
   */
  _getSolidColorChange(interval, workingTime) {
    const color = []
    for (let i = 0; i < 4; i++) {
      const subInterval = this._getIntervalWithColorValue(interval, i)
      let result = 0
      if (this._isAnimatable()) {
        result = this._getInterpolateData(workingTime, subInterval)
      } else {
        result = this._getInstantChangeData(workingTime, subInterval)
      }
      color.push(result)
    }

    return { color }
  }

  /**
   * Get GRADIENT stops change in a specific time
   * @param {[Keyframe, Keyframe]} interval
   * @param {number} workingTime
   * @returns {Array}
   */
  _getGradientStopsChange(interval, workingTime) {
    const fromGradient = interval.start.value.gradientStops
    const toGradient = interval.end.value.gradientStops
    const fromL = fromGradient.length
    const toL = toGradient.length

    if (fromL > toL) {
      matchGradientStops(fromGradient, toGradient)
    } else if (toL > fromL) {
      matchGradientStops(toGradient, fromGradient)
    }

    const amount = fromGradient.length
    const result = []
    for (let i = 0; i < amount; i++) {
      const singleGradientInterval = this._subInterval
      singleGradientInterval.copy(interval, true).updateValues(
        fromGradient[i],
        toGradient[i]
      )

      // Calculate gradient stop color interpolation data
      const color = []
      for (let j = 0; j < 4; j++) {
        const colorInterval = this._getIntervalWithColorValue(singleGradientInterval, j, 'color')
        let result = 0
        if (this._isAnimatable()) {
          result = this._getInterpolateData(workingTime, colorInterval)
        } else {
          result = this._getInstantChangeData(workingTime, colorInterval)
        }
        color.push(result)
      }

      // Calculate gradient stop position interpolation data
      const posInterval = singleGradientInterval.updateValues(
        singleGradientInterval.start.value.position,
        singleGradientInterval.end.value.position
      )
      const position = this._getInterpolateData(workingTime, posInterval)

      // Push interpolation data to result for animation
      result.push({
        position,
        color
      })
    }

    return result
  }

  /**
   * Get GRADIENT transform change in a specific time
   * @param {[Keyframe, Keyframe]} interval
   * @param {number} workingTime
   * @returns {Array}
   */
  _getGradientTransformChange(interval, workingTime) {
    const fromTransform = interval.start.value.gradientTransform
    const toTransform = interval.end.value.gradientTransform
    const startTransform = fromTransform.length ? fromTransform : DEFAULT_GRADIENT_TRANSFORM

    const result = []
    for (let i = 0; i < toTransform.length; i++) {
      const transformInterval = this._subInterval
      transformInterval.copy(interval, true).updateValues(
        startTransform[i],
        toTransform[i]
      )
      if (this._isAnimatable()) {
        result.push(this._getInterpolateData(workingTime, transformInterval))
      } else {
        result.push(this._getInstantChangeData(workingTime, transformInterval))
      }
    }

    return result
  }

  /**
   * Get GRADIENT color changes in a specific time
   * @param {[Keyframe, Keyframe]} interval
   * @param {number} workingTime
   * @returns {Array}
   */
  _getGradientColorChange(interval, workingTime) {
    return {
      gradientStops: this._getGradientStopsChange(interval, workingTime),
      gradientTransform: this._getGradientTransformChange(interval, workingTime)
    }
  }

  _getImageChange(interval) {
    return {
        imageId: this._getImagePropChange(interval, 'imageId'),
        imageMode: this._getImagePropChange(interval, 'imageMode')
    }
  }

  _getImagePropChange(interval, key) {
    if (interval.start.value[key] !== undefined) {
        return interval.start.value[key]
    }

    return interval.end.value[key]
  }
}

export default PaintStack
