import { parseToDecimals } from './number'

export const getHEXString = (value) => value.toUpperCase().replace(/[^0-9A-F]/g, '')

export const hexValidator = (value) => {
  if (/^([0-9a-fA-F]{3}|[0-9a-fA-F]{6})$/.test(value)) {
    return ''
  }
  return 'Invalid HEX format'
}

export const getColorArray = (value, ratio = 1) => {
  const color = value.split(',')
  return color.map((c) => parseToDecimals(c.trim(), 0) / ratio)
}

export const getRGBArray = (value) => getColorArray(value, 255)

export const getHSLArray = (value) => {
  const [h, s, l] = getColorArray(value)
  return [Math.round(h), s, l]
}

export const getHSBArray = (value) => getColorArray(value)

const COLOR_INFO = {
  RGB: {
    getter: getRGBArray,
    ranges: [
      [0, 1],
      [0, 1],
      [0, 1]
    ],
    invalidStr: 'Invalid RGB format'
  },
  HSL: {
    getter: getHSLArray,
    ranges: [
      [0, 360],
      [0, 100],
      [0, 100]
    ],
    invalidStr: 'Invalid HSL format'
  },
  HSB: {
    getter: getHSBArray,
    ranges: [
      [0, 360],
      [0, 100],
      [0, 100]
    ],
    invalidStr: 'Invalid HSB format'
  }
}

export const arrayValidator = (format, value) => {
  const { getter, ranges, invalidStr } = COLOR_INFO[format]
  const color = getter(value)
  if (color.length !== 3) {
    return invalidStr
  }

  let valid = true
  for (let i = 0; i < 3; i++) {
    if (isNaN(color[i])) {
      valid = false
      break
    }

    const n = parseFloat(color[i])
    const range = ranges[i]
    if (n < range[0] || n > range[1]) {
      valid = false
      break
    }
  }

  if (valid) {
    return ''
  }

  return invalidStr
}

export const rgbValidator = (value) => arrayValidator('RGB', value)

export const hslValidator = (value) => arrayValidator('HSL', value)

export const hsbValidator = (value) => arrayValidator('HSB', value)
