import { FilterKeys } from './types'

export const SHOW_RESOLVED_OPTION = {
  name: 'show_resolved',
  value: FilterKeys.SHOW_RESOLVED
}

export const MARK_ALL_AS_READ_OPTION = {
  name: 'mark_all_as_read',
  value: 'MARK_ALL_AS_READ'
}

export const EDIT_COMMENT_OPTION = {
  name: 'edit_comment',
  value: 'EDIT_COMMENT'
}

export const DELETE_COMMENT_OPTION = {
  name: 'delete_comment',
  value: 'DELETE_COMMENT'
}

export const REOPEN_THREAD_OPTION = {
  name: 'reopen_thread',
  value: 'REOPEN_THREAD'
}

export const MARK_THREAD_AS_UNREAD_OPTION = {
  name: 'mark_as_unread',
  value: 'MARK_AS_UNREAD'
}

export const MARK_THREAD_AS_READ_OPTION = {
  name: 'mark_as_read',
  value: 'MARK_AS_READ'
}

export const COPY_LINK_OPTION = {
  name: 'copy_link',
  value: 'COPY_LINK'
}

export const DELETE_THREAD_OPTION = {
  name: 'delete_thread',
  value: 'DELETE_THREAD'
}
