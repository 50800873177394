import { getPatternData, handleClipPath, handleMask, handlePatternData, } from './elementHandlers';
import { BlendMode, FillRule, MaskType, StrokeLinecap, StrokeLinejoin, } from './enums';
import { getGradientData, handleColorSpace, handleStyleProperties, handleStyleSheet, handleTransform, handleQueries, parseGradient, parseSVGData, string2CSS, useId, mergeTransforms, handleFilters, neutralizeTransforms, string2RgbArr, handleParentMask, } from '.';
export default function handleAttributes({ el, root, dimensions = {
    x: 0,
    y: 0,
    width: 512,
    height: 512,
    bottom: 512,
    left: 0,
    right: 512,
    top: 0,
    toJSON: function () {
        throw new Error('Function not implemented.');
    }
}, maskElements, ancestorAtts, groupAttributes, isMask = false }) {
    const attributes = {
        backgroundColor: null,
        blendMode: BlendMode.Normal,
        blur: [],
        fill: {
            color: groupAttributes?.fill.color || (root?.getAttribute('fill') ? string2RgbArr(root?.getAttribute('fill') || null) : [0, 0, 0]),
            opacity: groupAttributes?.fill.opacity || null,
            rule: groupAttributes?.fill.rule || FillRule.NonZero
        },
        gradient: {
            fill: null,
            stroke: null,
            transform: {
                anchorPoint: [0, 0],
                height: null,
                position: [0, 0],
                translate: [0, 0],
                scale: [100, 100],
                skew: {
                    angle: 0,
                    length: 0
                },
                rotation: 0,
                width: null,
            }
        },
        href: null,
        mask: {
            id: null,
            node: [],
            maskMode: 'a',
            matteMode: 3,
            type: MaskType.Mask,
            opacity: null,
            transform: {
                anchorPoint: [0, 0],
                height: null,
                position: [0, 0],
                translate: [0, 0],
                rotation: 0,
                scale: [100, 100],
                skew: {
                    angle: 0,
                    length: 0
                },
                width: null,
            }
        },
        opacity: null,
        pathData: [],
        pattern: {
            containerTransform: {
                anchorPoint: [0, 0],
                height: null,
                position: [0, 0],
                translate: [0, 0],
                scale: [100, 100],
                rotation: 0,
                skew: {
                    angle: 0,
                    length: 0
                },
                width: null,
            },
            data: [],
            image: {
                element: null,
                opacity: null,
            },
            opacity: [100, 100],
            transform: {
                anchorPoint: [0, 0],
                height: null,
                position: [0, 0],
                translate: [0, 0],
                scale: [100, 100],
                rotation: 0,
                skew: {
                    angle: 0,
                    length: 0
                },
                width: null,
            },
            type: 0
        },
        shadow: [],
        stroke: {
            color: groupAttributes?.stroke.color || null,
            dasharray: groupAttributes?.stroke.dasharray || null,
            dashoffset: groupAttributes?.stroke.dashoffset || 0,
            linecap: groupAttributes?.stroke.linecap || 1,
            linejoin: groupAttributes?.stroke.linejoin || 1,
            miterlimit: groupAttributes?.stroke.miterlimit || 4,
            opacity: groupAttributes?.stroke.opacity || null,
            width: groupAttributes?.stroke.width || 1,
            data: []
        },
        text: {
            content: null,
            fontName: 'sansSerif',
            fontSize: 12,
            fontWeight: 'normal',
            justify: 0,
            fill: {
                opacity: null,
                color: [0, 0, 0],
                rule: FillRule.NonZero
            },
            stroke: {
                color: null,
                data: [],
                dasharray: null,
                dashoffset: 0,
                linecap: 0,
                linejoin: 0,
                miterlimit: 0,
                opacity: null,
                width: 0,
                strokeOverFill: true
            },
            letterSpacing: 0
        },
        transform: {
            anchorPoint: [0, 0],
            height: 0,
            position: [0, 0],
            translate: [0, 0],
            rotation: 0,
            scale: [100, 100],
            skew: {
                angle: 0,
                length: 0
            },
            width: 0,
        },
        type: 4
    };
    if (!el) {
        return attributes;
    }
    const atts = [...el.attributes];
    if (el instanceof SVGGeometryElement) {
        attributes.pathData = parseSVGData({ el });
    }
    if (el instanceof SVGImageElement || el.firstElementChild instanceof SVGImageElement) {
        attributes.type = 2;
    }
    for (const att of atts) {
        switch (att.nodeName) {
            case 'clip-path': {
                handleClipPath({
                    attributes,
                    dimensions,
                    maskElements,
                    el,
                    root,
                    val: att.value
                });
                break;
            }
            case 'class': {
                const styleElement = root?.querySelector('style'), { style } = handleStyleSheet({
                    className: att.value,
                    cssText: styleElement?.innerHTML
                });
                handleStyleProperties({
                    style,
                    attributes,
                    dimensions,
                    el,
                    root,
                });
                break;
            }
            case 'data-filter': {
                handleFilters({
                    attributes,
                    el,
                    dimensions,
                    value: att.value,
                    root,
                });
                break;
            }
            case 'fill': {
                if (att.value === 'none' || att.value === 'transparent') {
                    attributes.fill.color = null;
                    attributes.fill.opacity = 0;
                    break;
                }
                if (att.value.startsWith('color(')) {
                    attributes.fill.color = handleColorSpace(att.value);
                    break;
                }
                if (!att.value.startsWith('url(') || !root) {
                    if (att.value.startsWith('url')) {
                        attributes.fill.color = [0, 0, 0];
                        break;
                    }
                    attributes.fill.color = string2RgbArr(att.value);
                    break;
                }
                const def = root.querySelector(handleQueries(att.value));
                if (!def) {
                    console.warn(`Could not find: ${handleQueries(att.value)}`);
                    break;
                }
                if (!(def instanceof SVGPatternElement)) {
                    const gradientData = getGradientData({
                        gradientElement: def,
                        container: el
                    });
                    if (gradientData) {
                        attributes.fill.color = [0, 0, 0];
                        attributes.gradient.fill = parseGradient(gradientData);
                        attributes.gradient.transform = gradientData.transform;
                        break;
                    }
                    attributes.fill.opacity = 0;
                    console.warn(`Uncaught/unknown fill attribute: ${att.value} on <${el.tagName}>`);
                    break;
                }
                if (isMask) {
                    attributes.fill.color = [0, 0, 0];
                    break;
                }
                const pattern = getPatternData({
                    el: def,
                    container: el,
                    dimensions,
                    root
                });
                if (pattern.containerTransform) {
                    attributes.pattern.containerTransform = pattern.containerTransform;
                }
                if (pattern.shapeData) {
                    handlePatternData(def, attributes, pattern.shapeData);
                    attributes.pattern.type = 1;
                    attributes.fill.color = [0, 0, 0];
                    break;
                }
                if (!pattern.image || !pattern.transform || !pattern.containerTransform) {
                    attributes.fill.opacity = 0;
                    console.warn(`Uncaught/unknown fill attribute: ${att.value}`);
                    break;
                }
                if (pattern.transform.skew.length) {
                    attributes.mask.type = MaskType.Matte;
                }
                if (!(el instanceof SVGElement)) {
                    break;
                }
                const parentMaskQuery = el.parentElement?.getAttribute('clip-path'), hasParentMask = el.dataset.maskParent !== 'false' && ((!!groupAttributes?.mask && !!el.dataset.maskParent)
                    || !!parentMaskQuery);
                if (hasParentMask) {
                    handleParentMask({
                        attributes,
                        el,
                        groupAttributes,
                        root,
                        transform: pattern.transform
                    });
                }
                if (!hasParentMask && attributes.mask.type === MaskType.Mask) {
                    neutralizeTransforms({
                        el,
                        transform: pattern.transform,
                        containerTransform: pattern.containerTransform,
                        hasBlur: !!groupAttributes?.blur.length
                    });
                }
                attributes.pattern.image.element = pattern.image;
                if (!hasParentMask) {
                    attributes.mask.node = [];
                    attributes.mask.node.push({
                        data: parseSVGData({ el, parseTranslate: true, closePaths: true }),
                        fill: {
                            color: [0, 0, 0],
                            opacity: 100,
                            rule: FillRule.NonZero
                        }
                    });
                }
                attributes.pattern.transform = {
                    anchorPoint: pattern.transform.anchorPoint,
                    position: pattern.transform.position,
                    translate: pattern.transform.translate,
                    height: pattern.transform.height,
                    rotation: pattern.transform.rotation,
                    scale: pattern.transform.scale,
                    skew: pattern.transform.skew,
                    width: pattern.transform.width,
                };
                attributes.type = 2;
                attributes.pattern.type = 2;
                attributes.fill.color = [0, 0, 0];
                break;
            }
            case 'fill-opacity': {
                attributes.fill.opacity = Number(att.value) * 100;
                break;
            }
            case 'fill-rule':
                attributes.fill.rule = att.value;
                break;
            case 'filter': {
                handleFilters({
                    attributes,
                    el,
                    dimensions,
                    value: att.value,
                    root,
                });
                break;
            }
            case 'height':
                attributes.transform.height = Number(parseFloat(att.value.replace(/[^.\d]/g, '')));
                break;
            case 'href':
            case 'xlink:href':
                attributes.href = att.value;
                break;
            case 'id': {
                const styleElement = root?.querySelector('style'), { style } = handleStyleSheet({
                    cssText: styleElement?.innerHTML,
                    id: att.value
                });
                attributes.id = att.value;
                handleStyleProperties({
                    style,
                    attributes,
                    dimensions,
                    root,
                });
                break;
            }
            case 'mask': {
                attributes.mask.type = MaskType.Matte;
                handleMask({
                    attributes,
                    el,
                    maskElements,
                    root,
                    val: att.value
                });
                break;
            }
            case 'opacity': {
                attributes.opacity = Number(att.value) * 100;
                if (!(el instanceof SVGGElement)) {
                    attributes.fill.opacity = (attributes.fill.opacity ?? 100) * Number(att.value);
                    attributes.stroke.opacity = (attributes.stroke.opacity ?? 100) * Number(att.value);
                }
                break;
            }
            case 'stroke': {
                if (att.value === 'none' || att.value === 'transparent') {
                    attributes.stroke.color = null;
                    attributes.stroke.opacity = 0;
                    break;
                }
                if (attributes.type === 2) {
                    attributes.stroke.data = parseSVGData({ el });
                }
                if (!att.value.startsWith('url') || !root) {
                    if (att.value.startsWith('url')) {
                        attributes.stroke.color = [0, 0, 0];
                        break;
                    }
                    attributes.stroke.color = string2RgbArr(att.value);
                    break;
                }
                const def = root.querySelector(handleQueries(att.value));
                if (def instanceof SVGPatternElement) {
                    const pattern = getPatternData({ el: def, root });
                    if (pattern.containerTransform) {
                        attributes.pattern.containerTransform = pattern.containerTransform;
                    }
                    if (pattern.shapeData) {
                        handlePatternData(def, attributes, pattern.shapeData);
                        attributes.stroke.color = [0, 0, 0];
                        break;
                    }
                    if (pattern.image) {
                        attributes.type = 2;
                        attributes.stroke.color = [0, 0, 0];
                        break;
                    }
                    break;
                }
                const gradientData = getGradientData({
                    gradientElement: def,
                    container: el
                });
                if (gradientData) {
                    attributes.gradient.stroke = parseGradient(gradientData);
                    attributes.gradient.transform = gradientData.transform;
                    attributes.stroke.color = [0, 0, 0];
                    break;
                }
                attributes.stroke.opacity = 0;
                attributes.stroke.color = [0, 0, 0];
                console.warn(`Uncaught/unknown stroke attribute: ${att.value}`);
                break;
            }
            case 'stroke-linejoin': {
                if (att.value === StrokeLinejoin.Bevel) {
                    attributes.stroke.linejoin = 3;
                    break;
                }
                if (att.value === StrokeLinejoin.Round) {
                    attributes.stroke.linejoin = 2;
                    break;
                }
                attributes.stroke.linejoin = 1;
                break;
            }
            case 'stroke-linecap': {
                if (att.value === StrokeLinecap.Square) {
                    attributes.stroke.linecap = 3;
                    break;
                }
                if (att.value === StrokeLinecap.Round) {
                    attributes.stroke.linecap = 2;
                    break;
                }
                attributes.stroke.linecap = 1;
                break;
            }
            case 'stroke-miterlimit':
                attributes.stroke.miterlimit = Number(att.value);
                break;
            case 'stroke-opacity':
                attributes.stroke.opacity = Number(att.value) * 100;
                break;
            case 'stroke-dasharray': {
                if (att.value !== 'none') {
                    const dash = Number(att.value.split(/\s?[, ]\s?/)[0]), gap = att.value.split(/\s?[, ]\s?/)[1] ? Number(att.value.split(/\s?[, ]\s?/)[1]) : dash;
                    attributes.stroke.dasharray = {
                        dash,
                        gap,
                    };
                }
                break;
            }
            case 'stroke-dashoffset':
                attributes.stroke.dashoffset = Number(att.value);
                break;
            case 'stroke-width':
                attributes.stroke.width = Number(att.value);
                break;
            case 'style': {
                const style = att instanceof CSSStyleDeclaration ? att : {};
                if (!(att instanceof CSSStyleDeclaration)) {
                    Object.assign(style, string2CSS(att.value.split(/[:;]/g).filter(str => str !== '')));
                }
                handleStyleProperties({
                    style,
                    attributes,
                    el,
                    dimensions,
                    root,
                });
                break;
            }
            case 'transform': {
                const { anchorPoint: a, position: p, rotation: r, skew: sk, scale: s, translate: t } = mergeTransforms(handleTransform({
                    input: att.value,
                    el,
                    root
                }), attributes.transform);
                attributes.transform.anchorPoint = a;
                attributes.transform.position = p;
                attributes.transform.rotation = r;
                attributes.transform.scale = s;
                attributes.transform.skew = sk;
                attributes.transform.translate = t;
                break;
            }
            case 'width':
                attributes.transform.width = Number(parseFloat(att.value.replace(/[^.\d]/g, '')));
                break;
        }
    }
    for (const att of (ancestorAtts || [])) {
        switch (att.nodeName) {
            case 'clip-path': {
                handleClipPath({
                    attributes,
                    dimensions,
                    el,
                    root,
                    val: att.value
                });
                break;
            }
            case 'mask': {
                handleMask({
                    attributes,
                    el,
                    maskElements,
                    root,
                    val: att.value
                });
                break;
            }
            case 'opacity': {
                attributes.opacity = (attributes.opacity ?? 100) * Number(att.value);
                break;
            }
            case 'fill': {
                if (att.value === 'none' || att.value === 'transparent') {
                    break;
                }
                if (!attributes.fill.color) {
                    attributes.fill.color = string2RgbArr(att.value);
                }
                attributes.fill.opacity = 100;
                break;
            }
            case 'filter': {
                handleFilters({
                    attributes,
                    el,
                    dimensions,
                    value: att.value,
                    root,
                });
                break;
            }
            case 'transform': {
                const { anchorPoint: a, position: p, rotation: r, skew: sk, scale: s, translate: t } = mergeTransforms(handleTransform({
                    input: att.value,
                    el,
                    root
                }), attributes.transform);
                attributes.transform.anchorPoint = a;
                attributes.transform.position = p;
                attributes.transform.rotation = r;
                attributes.transform.scale = s;
                attributes.transform.skew = sk;
                attributes.transform.translate = t;
                break;
            }
            case 'stroke': {
                if (att.value === 'none' || att.value === 'transparent') {
                    break;
                }
                if (!attributes.stroke.color) {
                    attributes.stroke.color = string2RgbArr(att.value);
                }
                if (att.value !== 'none' && att.value !== 'transparent') {
                    attributes.stroke.opacity = 100;
                }
                if (!att.value.startsWith('url') || !root) {
                    if (att.value.startsWith('url')) {
                        attributes.stroke.color = [0, 0, 0];
                    }
                    break;
                }
                const def = root.querySelector(handleQueries(att.value));
                if (def instanceof SVGPatternElement) {
                    const pattern = getPatternData({ el: def, root });
                    if (pattern.shapeData) {
                        handlePatternData(def, attributes, pattern.shapeData);
                        attributes.stroke.color = [0, 0, 0];
                        break;
                    }
                    if (pattern.image) {
                        attributes.type = 2;
                        attributes.stroke.color = [0, 0, 0];
                        break;
                    }
                    break;
                }
                const gradientData = getGradientData({
                    gradientElement: def,
                    container: el
                });
                if (gradientData) {
                    attributes.gradient.stroke = parseGradient(gradientData);
                    attributes.stroke.color = [0, 0, 0];
                    break;
                }
                attributes.stroke.opacity = 0;
                attributes.stroke.color = [0, 0, 0];
                console.warn(`Uncaught/unknown stroke attribute: ${att.value}`);
                break;
            }
            case 'stroke-linejoin': {
                if (att.value === StrokeLinejoin.Bevel) {
                    attributes.stroke.linejoin = 3;
                    break;
                }
                if (att.value === StrokeLinejoin.Round) {
                    attributes.stroke.linejoin = 2;
                    break;
                }
                attributes.stroke.linejoin = 1;
                break;
            }
            case 'stroke-linecap':
                if (att.value === StrokeLinecap.Square) {
                    attributes.stroke.linecap = 3;
                    break;
                }
                if (att.value === StrokeLinecap.Round) {
                    attributes.stroke.linecap = 2;
                    break;
                }
                attributes.stroke.linecap = 1;
                break;
            case 'stroke-miterlimit':
                attributes.stroke.miterlimit = Number(att.value);
                break;
            case 'stroke-opacity':
                attributes.stroke.opacity = Number(att.value) * 100;
                break;
            case 'stroke-dasharray': {
                if (att.value !== 'none') {
                    const dash = Number(att.value.split(/\s?[, ]\s?/)[0]), gap = att.value.split(/\s?[, ]\s?/)[1] ? Number(att.value.split(/\s?[, ]\s?/)[1]) : dash;
                    attributes.stroke.dasharray = {
                        dash,
                        gap,
                    };
                }
                break;
            }
            case 'stroke-dashoffset':
                attributes.stroke.dashoffset = Number(att.value);
                break;
            case 'stroke-width':
                attributes.stroke.width = Number(att.value);
                break;
            case 'style': {
                const style = att instanceof CSSStyleDeclaration ? att : {};
                if (!(att instanceof CSSStyleDeclaration)) {
                    Object.assign(style, string2CSS(att.value.split(/[.:.;]/g).filter(str => str !== '')));
                }
                handleStyleProperties({
                    style,
                    dimensions,
                    attributes,
                    root,
                });
                break;
            }
        }
    }
    if (!attributes.id) {
        attributes.id = useId('element');
    }
    if (!attributes.gradient.fill && !attributes.fill.color) {
        attributes.fill.opacity = 0;
    }
    return attributes;
}
