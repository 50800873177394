import { LayerType, PaintType } from '@phase-software/types'
import PropertyStack from '../PropertyStack'
import { GRADIENT_TYPE_SET, DEFAULT_GRADIENT_TRANSFORM } from '../constants'

class LayerStack extends PropertyStack {
  constructor(elementStack, data) {
    super(elementStack, data)
    this.type = ''
    this.key = ''
    this.isRepeatableType = true
    this.animatableProperties = new Set()
    this._computedLayerId = data.computedLayerId

    // clKeyCache will be used by removing layer stack
    // because when we are trying to remove it, we can not get clKey from the removed computedLayer.
    this.clKeyCache = null
  }

  /**
   * Get computedLayer
   * @returns {ComputedLayer}
   */
  get computedLayer() {
    const element = this.dataStore.getById(this.elementStack.id)
    return element.computedStyle.getComputedLayerById(this._computedLayerId)
  }

  get clKey() {
    if (!this.computedLayer) {
      return undefined
    }

    this.clKeyCache = this.computedLayer.get('layerId') || this.computedLayer.get('trackId')
    return this.clKeyCache
  }

  get layerId() {
    if (!this.computedLayer) {
      return undefined
    }

    return this.computedLayer.get('layerId')
  }

  get clId() {
    return this._computedLayerId
  }

  isBaseLayer() {
    return !!this.layerId
  }

  /**
   * Get keyframe state by time
   * @param {number} time
   * @returns {object}
   */
  getKFState(time) {
    let childKFStates = {}
    this.children.forEach((child) => {
      childKFStates = {
        ...childKFStates,
        ...child.getKFState(time)
      }
    })
    return childKFStates
  }

  getBaseValue() {
    // If is base layer, get base value from base layer
    if (this.isBaseLayer()) {
      return super.getBaseValue()
    }

    // If not base layer, get base value from computedLayer default value
    return this.computedLayer.defaultValue()
  }

  updateNonBaseValue() {
    this.elementStack.cacheSingleNonBaseLayerBaseValue(this.dataKey, this.id, this.getInitValue())
  }

  _clearReadOnlyData(data) {
    delete data.id
    delete data.type
    delete data.layerType
    delete data.appliedTo
    delete data.name
    delete data.componentType
    delete data.paintId
  }

  _getFirstNonInitPaint(paintStack) {
    if (!paintStack) {
      return {}
    }

    let paintData = {}
    // Get paint data from first non-init keyframe
    const firstNonInitKF = paintStack.actions[0].getFirstNonInitKF()
    if (firstNonInitKF) {
      // If have at least one non-init keyframe, then we get the default layer data from that keyframe.
      const paint = this.dataStore.library.getComponent(firstNonInitKF.ref)
      if (paint) {
        paintData = paint.save()
      }
    }

    return paintData
  }

  _getFirstNonOpacity(opacityStack) {
    if (!opacityStack) {
      return null
    }

    const firstNonInitOpacity = opacityStack.actions[0].getFirstNonInitKF()
    if (firstNonInitOpacity) {
      return firstNonInitOpacity.value
    }

    return null
  }

  /**
   * Get default value from base for default keyframe
   * @returns {object}
   */
  getInitValue() {
    if (!this.computedLayer) {
      return {}
    }
    const isBase = this.isBaseLayer()
    const baseData = this.getBaseValue()
    if (!baseData) {
        return {}
    }
    const {
      color,
      ...computedLayerData
    } = baseData
    const paintStack = this.children.get('paint')
    const opacityStack = this.children.get('opacity')
    const firstNonInitPaintData = this._getFirstNonInitPaint(paintStack)
    const firstNonInitOpacityData = this._getFirstNonOpacity(opacityStack)
    if (!isBase && firstNonInitOpacityData !== null && color) {
      color[3] = firstNonInitOpacityData
    }

    let layerData = {}
    if (isBase) {
      const firstPaintKF = paintStack && paintStack.actions[0] && paintStack.actions[0].kfs[0]
      const paintRefCom = firstPaintKF && this.dataStore.library.getComponent(firstPaintKF.ref)
      let samePaintTypeAsBase = true
      if (paintRefCom && baseData.paintType !== paintRefCom.paintType) {
        samePaintTypeAsBase = false
      }

      if (samePaintTypeAsBase) {
        layerData = baseData
      } else {
        layerData = {
          paintType: paintRefCom ? paintRefCom.paintType : baseData.paintType,
          color,
          ...layerData,
          ...firstNonInitPaintData
        }

        if (paintRefCom && paintRefCom.paintType !== undefined) {
          if (paintRefCom.paintType === PaintType.SOLID) {
            // Get SOLID color from first GRADIENT stop color
            const firstGradientStopColor = baseData.gradientStops[0].color
            layerData.color = [...firstGradientStopColor]
          } else if (GRADIENT_TYPE_SET.has(paintRefCom.paintType) && baseData.paintType === PaintType.SOLID) {
            // Get GRADIENT color from SOLID color
            const [r, g, b] = baseData.color
            layerData.gradientStops = [{
              color: [r, g, b, 1],
              position: 0
            }, {
              color: [r, g, b, 0],
              position: 1
            }]
            layerData.gradientTransform = DEFAULT_GRADIENT_TRANSFORM
          }
        }
      }
    } else {
      layerData = {
        color,
        ...firstNonInitPaintData
      }
      this.children.forEach((childStack) => {
        if (childStack.key === 'paint') {
          return
        }
        const childFirstNonInitKF = childStack.actions[0].getFirstNonInitKF()
        if (childFirstNonInitKF) {
          layerData[childStack.dataKey] = childFirstNonInitKF.value
        }
      })
    }

    const defaultData = {
      ...computedLayerData,
      ...layerData
    }
    if (!isBase && firstNonInitOpacityData !== null) {
      defaultData.opacity = firstNonInitOpacityData
    }
    this._clearReadOnlyData(defaultData)
    return defaultData
  }

  getComputedData(time) {
    let updateData = {}
    // Get animation data from children stacks
    this.children.forEach((childStack) => {
      const animationData = childStack.getAnimateData(time)
      if (animationData !== undefined) {
        updateData = {
          ...updateData,
          ...animationData
        }

        // Only update paintType when we have paint data update
        if (childStack.key === 'paint') {
          updateData.paintType = childStack.getPaintType()
        }
      }
    })

    return updateData
  }

  updateComputedData(time) {
    const updateData = this.getComputedData(time)
    if (Object.keys(updateData).length) {
      this.computedLayer.sets(updateData, { flags: 1 })
    }
  }

  resetComputedData(propKey) {
    if (!this.computedLayer) {
      return
    }

    const defaultData = this.getInitValue()
    const isChildKeyPresent = Array.from(this.children.values()).some(child => child.dataKey === propKey)
    if (propKey !== 'paint' && (isChildKeyPresent || this.children.has(propKey))) {
      this.computedLayer.set(propKey, defaultData[propKey], { flags: 1 })
      return
    }

    this.computedLayer.sets({
      paintType: PaintType.SOLID,
      color: this.computedLayer.defaultValue().color
    }, { flags: 1 })
  }

  resetComputedLayer() {
    if (!this.computedLayer) {
      return
    }

    const defaultData = this.getInitValue()
    const layer = this.dataStore.library.getLayer(this.computedLayer.get('layerId'))
    const paintData = {}
    if (layer) {
      const paint = layer.paint
      paintData.paintType = paint.paintType
      if (paint.paintType === PaintType.SOLID) {
        paintData.color = [...paint.color]
      } else {
        paintData.gradientStops = [...paint.gradientStops]
        paintData.gradientTransform = [...paint.gradientTransform]
      }
    }
    if (this.type === LayerType.SHADOW || this.type === LayerType.INNER_SHADOW) {
      delete defaultData.gradientStops
      delete defaultData.gradientTransform
    }
    this.computedLayer.sets({
      ...defaultData,
      ...paintData
    }, { flags: 1 })
  }
}

export default LayerStack
