import { FALLBACK_FONT } from '../settings'
import { getGlyphFont } from './FontMap'

const defaultLocal = 'http://127.0.0.1:18413/phase/'
const defaultStatic = 'https://static2.phase.com/fonts/'

class FontDescriptor {
    /** @param {(FontDescriptor|Partial<FontObject>)} [obj] */
    constructor(obj = {}) {
        /** @type {string} */
        this.postscript = obj.postscript
        /** @type {string} */
        this.family = obj.family
        /** @type {string} */
        this.style = obj.style
        /** @type {number} */
        this.stretch = obj.stretch
        /** @type {number} */
        this.weight = obj.weight
        /** @type {boolean} */
        this.italic = obj.italic
        /** @type {string} */
        this.path = obj.path
        /** @type {number} */
        this.revision = obj.revision
    }
}

/**
 * Font Helper
 *
 * before using any functions, load available fonts via `.loadFontList()`
 */
export class FontHelper {
    /**
     * @private
     * @param {string} [localServer]
     * @param {string} [staticServer]
     */
    constructor(localServer = defaultLocal, staticServer = defaultStatic) {
        this.localServer = localServer
        this.staticServer = staticServer

        /** @type {Map<string, FontDescriptor>} */
        this.list = null
        /** @type {Map<string, Map<string, FontDescriptor>>} */
        this.families = null

        this.fontListPromise = null
    }

    /**
     * get font URL from static and local font list
     * @param {string} postscript
     * @returns {Promise<string|undefined>}
     */
    async getURL(postscript) {
        if (this.list === null) {
            if (this.fontListPromise === null) this.fontListPromise = this.loadFontList()
            await this.fontListPromise
        }
        const ps = postscript.replace(/ /g, '')

        let fd = this.list.get(ps)
        // try without '-Regular'
        if (!fd && ps.includes('-Regular')) fd = this.list.get(ps.replace('-Regular', ''))

        if (!fd) throw new Error(`${ps} font not found!`)
        return fd.path
    }

    /**
     * @param {string} family
     * @returns {Map<string, FontDescriptor>|undefined}
     */
    fontFamily(family) {
        const g = this.group()
        return g.get(family)
    }

    /**
     * @returns {Map<string, Map<string, FontDescriptor>>}
     */
    group() {
        if (this.families) {
            return this.families
        }
        this.families = new Map()
        for (const fd of this.list.values()) {
            let family = this.families.get(fd.family)
            if (!family) {
                family = new Map()
                this.families.set(fd.family, family)
            }
            family.set(fd.style || 'Regular', fd)
        }
        return this.families
    }

    /**
     * loads all available fonts from static server and local font helper
     */
    async loadFontList() {
        const staticList = new Map()
        const localList = new Map()

        try {
            const res = await fetch(`${this.staticServer}index.json`)

            /** @type {FontObject[]} */
            const obj = await res.json()
            Object.values(obj).forEach(font => {
                const fd = new FontDescriptor(font)
                fd.path = `${this.staticServer}${font.postscript}_${font.revision}`
                staticList.set(font.postscript, fd)
            })
        } catch (e) {
            console.warn(e)
        }

        try {
            const res = await fetch(`${this.localServer}font-files`)
            // @type {
            // version: 'x.x.x',
            // fontFiles: {
            //     'path/to/file.ttc': [ FontDescriptor ],
            // },
            // }
            const raw = await res.json()
            Object.values(raw.fontFiles).forEach(fonts => {
                fonts.forEach(font => {
                    const fd = new FontDescriptor(font)

                    let u = `${this.localServer}font-file?file=${fd.path}`

                    if (fd.path.endsWith('.ttc')) {
                        u += `&postscript=${fd.postscript}`
                    }

                    fd.path = u
                    localList.set(fd.postscript, fd)
                }, localList)
            })
        } catch (e) {
            console.warn(e)
        }

        // use local font when same font found in local
        this.list = new Map([...staticList, ...localList])
    }

    /**
     * get a suitable font from pre-defined Noto Font table,
     * it supports Sans and Serif now
     * @param {number} charCode
     * @param {'Sans'|'Serif'} [type='Sans'] - style
     * @returns {string} available font family
     */
    static getGlyphFont(charCode, type) {
        return getGlyphFont(charCode, type)
    }

    /**
     * find a fallback font for a glyph that's not available in the current font
     * @param {number} charCode
     * @returns {string}
     */
    static getFallbackFontName(charCode) {
        const fontName = FontHelper.getGlyphFont(charCode)
        if (!fontName) {
            console.warn(`could not find a fallback font for charCode: ${charCode}`)
            return FALLBACK_FONT
        }
        return fontName
    }

    /**
     * get filename of font
     * @param {{ fontFamily: string, fontStyle: string }} fontName
     * @returns {string}
     */
    static translateFontName(fontName) {
        if (!(fontName && fontName.fontFamily)) {
            return FALLBACK_FONT
        }
        return `${fontName.fontFamily}-${fontName.fontStyle || 'Regular'}`
    }

    /** @returns {FontHelper} */
    static instance() {
        if (instance) return instance
        instance = new FontHelper()
        return instance
    }
}

let instance

/**
 * @typedef {object} FontObject
 * @property {string} postscript
 * @property {string} family
 * @property {string} style
 * @property {number} stretch
 * @property {number} weight
 * @property {boolean} italic
 * @property {string} path
 * @property {number} revision
 */
