/* tslint:disable */
/* eslint-disable */
/**
 * Express TypeScript Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiPresenceCollaboratorsFileIdGet200ResponseInner,
  ApiPresenceSerialsFileIdGet200Response,
  ApiPresenceSerialsPost200Response,
  ApiPresenceSerialsPostRequest,
} from '../models';

export interface ApiPresenceCollaboratorsFileIdGetRequest {
    fileId: string;
}

export interface ApiPresenceSerialsFileIdGetRequest {
    fileId: string;
}

export interface ApiPresenceSerialsPostOperationRequest {
    apiPresenceSerialsPostRequest: ApiPresenceSerialsPostRequest;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Retrieve an array of collaborators for a given file ID from Redis.
     * Returns the list of collaborators on a file.
     */
    async apiPresenceCollaboratorsFileIdGetRaw(requestParameters: ApiPresenceCollaboratorsFileIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ApiPresenceCollaboratorsFileIdGet200ResponseInner>>> {
        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling apiPresenceCollaboratorsFileIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/presence/collaborators/{fileId}`.replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Retrieve an array of collaborators for a given file ID from Redis.
     * Returns the list of collaborators on a file.
     */
    async apiPresenceCollaboratorsFileIdGet(requestParameters: ApiPresenceCollaboratorsFileIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ApiPresenceCollaboratorsFileIdGet200ResponseInner>> {
        const response = await this.apiPresenceCollaboratorsFileIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve an array of collaborators for a given file ID from Redis.
     * Returns the list of collaborators on a file.
     */
    async apiPresenceSerialsFileIdGetRaw(requestParameters: ApiPresenceSerialsFileIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiPresenceSerialsFileIdGet200Response>> {
        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling apiPresenceSerialsFileIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/presence/serials/{fileId}`.replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Retrieve an array of collaborators for a given file ID from Redis.
     * Returns the list of collaborators on a file.
     */
    async apiPresenceSerialsFileIdGet(requestParameters: ApiPresenceSerialsFileIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiPresenceSerialsFileIdGet200Response> {
        const response = await this.apiPresenceSerialsFileIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Store a serial number for a given fileId, userId, and tabId.
     * Set a user\'s serial number.
     */
    async apiPresenceSerialsPostRaw(requestParameters: ApiPresenceSerialsPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiPresenceSerialsPost200Response>> {
        if (requestParameters.apiPresenceSerialsPostRequest === null || requestParameters.apiPresenceSerialsPostRequest === undefined) {
            throw new runtime.RequiredError('apiPresenceSerialsPostRequest','Required parameter requestParameters.apiPresenceSerialsPostRequest was null or undefined when calling apiPresenceSerialsPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/presence/serials`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.apiPresenceSerialsPostRequest,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Store a serial number for a given fileId, userId, and tabId.
     * Set a user\'s serial number.
     */
    async apiPresenceSerialsPost(requestParameters: ApiPresenceSerialsPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiPresenceSerialsPost200Response> {
        const response = await this.apiPresenceSerialsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
