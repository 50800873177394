import React from 'react'

import { useElementVirtualProperties } from '../../providers/ElementVirtualPropertiesProvider'
import { useSetModal } from '../../providers/ModalProvider'
import { useDataStoreActions } from '../../providers/dataStore/DataStoreProvider'
import { useElement, useElementActions } from '../../providers/dataStore/ElementProvider'
import { useElementSelection } from '../../providers/dataStore/ElementSelectionProvider'
import { useHoverSelection, useHoverSelectionActions } from '../../providers/dataStore/HoverSelectionProvider'
import { useUI } from '../../providers/dataStore/UIProvider'
import { List } from '../shared'
import Element from './Element'
import { useInteractionActions } from '../../providers/dataStore/InteractionProvider'

const getInheritProps = (item, parentProps, elementsVirtualProperties) => {
  // first round
  if (!item) {
    return {
      parentVisible: true,
      parentLocked: false,
      ancestorIsMask: false
    }
  }

  const data = {
    parentVisible: item.visible && parentProps.parentVisible,
    parentLocked: item.locked || parentProps.parentLocked,
    ancestorIsMask: item.isMask || parentProps.ancestorIsMask
  }

  if (elementsVirtualProperties) {
    data.parentVisible =
      (item.visible || elementsVirtualProperties[item.id]?.virtualDisplay === true) && parentProps.parentVisible
  }

  return data
}

const ElementTree = () => {
  const el = useElement()
  const { isInspectingState } = useUI()
  const { toggleExpand } = useElementActions()
  const { selectElements, getScreenList, reorderElements } = useDataStoreActions()
  const { getAnimatiedElementMap } = useInteractionActions()

  const selection = useElementSelection()
  const elementsVirtualProperties = useElementVirtualProperties()
  const { hoverElement } = useHoverSelectionActions()
  const hover = useHoverSelection()
  const root = getScreenList()
  const { openModal } = useSetModal('ElementContextMenu')
  const handleContextMenu = (e) => {
    if (isInspectingState) return
    openModal({
      elementIds: selection,
      mousePos: {
        x: e.clientX,
        y: e.clientY
      }
    })
  }

  // Toggle Element UI visibility when drag-duplicate element
  const allElements = Object.entries(el)
  const currectSelection = new Set(selection)
  const elementsData = new Map(
    allElements.filter(([, elData]) => elementsVirtualProperties[elData.id]?.virtualDisplay !== false)
  )
  allElements.forEach(([, elData]) => {
    if (elementsVirtualProperties[elData.id]?.virtualSelected === true && !currectSelection.has(elData.id)) {
      currectSelection.add(elData.id)
    }
  })

  return (
    <List
      sortable={!isInspectingState}
      multiSelectable={!isInspectingState}
      className="flex-grow mt-8"
      data={elementsData}
      root={root}
      selectedList={[...currectSelection]}
      highlightedList={[hover]}
      getInheritProps={(item, parentProps) => getInheritProps(item, parentProps, elementsVirtualProperties)}
      renderItem={Element}
      animatedElementMap={getAnimatiedElementMap()}
      onExpand={toggleExpand}
      onSelect={selectElements}
      onItemHover={hoverElement}
      onContextMenu={handleContextMenu}
      onSort={reorderElements}
    />
  )
}

export default ElementTree
