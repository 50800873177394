import { useEffect } from 'react'
import PropTypes from 'prop-types'

export const Dropzone = ({ onDrop }) => {
  useEffect(() => {
    const handleDragOver = (e) => {
      e.preventDefault()
    }
    const handleDrop = async (e) => {
      e.preventDefault()
      const files = e.dataTransfer?.files
      const file = files?.[0]
      if (!file) {
        return
      }
      const fileName = file.name.toLowerCase()
      if (!(fileName.endsWith('.json') || fileName.endsWith('.phase') || fileName.endsWith('.svg'))) {
        alert('not a json/phase/svg file')
        return
      }
      onDrop([...files])
    }
    document.body.addEventListener('drop', handleDrop)
    document.body.addEventListener('dragover', handleDragOver)

    return () => {
      document.body.removeEventListener('drop', handleDrop)
      document.body.removeEventListener('dragover', handleDragOver)
    }
  }, [onDrop])
  return null
}
Dropzone.propTypes = {
  onDrop: PropTypes.func
}
