import { useCallback } from 'react'

import { CreateProjectFileCollaboratorInvitationRequestPermissionEnum } from '../generated/api'
import { FileFieldsFragment, ProjectFieldsFragment, UserFieldsFragment } from '../generated/graphql'
import { useWorkspaceContext } from '../providers/WorkspaceContextProvider'
import { projectFileCollaboratorService } from '../services/api'

const useSharePersonalFileActions = () => {
  const { workspaceData } = useWorkspaceContext()

  const changePersonalFileCollaboratorPermission = ({
    fileId,
    permission,
    projectId,
    collaboratorId
  }: {
    fileId: FileFieldsFragment['id']
    permission: CreateProjectFileCollaboratorInvitationRequestPermissionEnum
    projectId: ProjectFieldsFragment['id']
    collaboratorId: string
  }) =>
    projectFileCollaboratorService.projectFileCollaboratorControllerPatchUserProjectFileCollaborator({
      userId: workspaceData.id,
      projectId,
      fileId,
      collaboratorId,
      patchProjectFileCollaboratorRequest: {
        permission,
        updateMask: ['permission']
      }
    })

  const removePersonalFileCollaborator = ({
    projectId,
    fileId,
    collaboratorId
  }: {
    projectId: ProjectFieldsFragment['id']
    fileId: FileFieldsFragment['id']
    collaboratorId: string
  }) =>
    projectFileCollaboratorService.projectFileCollaboratorControllerDeleteUserProjectFileCollaborator({
      userId: workspaceData.id,
      projectId,
      fileId,
      collaboratorId
    })

  const sendPersonalFileInvitation = ({
    email,
    fileId,
    permission,
    projectId
  }: {
    email: string
    fileId: FileFieldsFragment['id']
    permission: CreateProjectFileCollaboratorInvitationRequestPermissionEnum
    projectId: ProjectFieldsFragment['id']
  }) =>
    projectFileCollaboratorService.projectFileCollaboratorControllerCreateUserProjectFileCollaboratorInvitation({
      userId: workspaceData.id,
      projectId,
      fileId,
      createProjectFileCollaboratorInvitationRequest: {
        email,
        permission
      }
    })

  const resendPersonalFileInvitation = ({
    projectId,
    fileId,
    invitationId
  }: {
    projectId: ProjectFieldsFragment['id']
    fileId: FileFieldsFragment['id']
    invitationId: string
  }) =>
    projectFileCollaboratorService.projectFileCollaboratorControllerResendPersonalProjectCollaboratorInviteEmail({
      projectId,
      fileId,
      invitationId
    })

  const changePersonalFileInvitationPermission = ({
    projectId,
    fileId,
    invitationId,
    permission
  }: {
    projectId: ProjectFieldsFragment['id']
    fileId: FileFieldsFragment['id']
    invitationId: string
    permission: CreateProjectFileCollaboratorInvitationRequestPermissionEnum
  }) =>
    projectFileCollaboratorService.projectFileCollaboratorControllerUpdatePermissionOfPersonalProjectFileInvitation({
      projectId,
      fileId,
      invitationId,
      updateProjectFileInvitationRequest: {
        permission
      }
    })

  const cancelPersonalFileInvitation = ({
    projectId,
    fileId,
    invitationId
  }: {
    projectId: ProjectFieldsFragment['id']
    fileId: FileFieldsFragment['id']
    invitationId: string
  }) =>
    projectFileCollaboratorService.projectFileCollaboratorControllerRemovePersonalProjectFileCollaboratorInvite({
      userId: workspaceData.id,
      projectId,
      invitationId,
      fileId
    })

  const joinPersonalFileCollaborator = useCallback(
    ({
      fileId,
      invitationId,
      projectId,
      userId
    }: {
      userId: UserFieldsFragment['id']
      projectId: ProjectFieldsFragment['id']
      fileId: FileFieldsFragment['id']
      invitationId: string
    }) =>
      projectFileCollaboratorService.projectFileCollaboratorControllerAcceptUserProjectFileCollaboratorInvite({
        userId,
        projectId,
        fileId,
        invitationId
      }),
    []
  )

  return {
    changePersonalFileCollaboratorPermission,
    removePersonalFileCollaborator,

    sendPersonalFileInvitation,
    resendPersonalFileInvitation,
    changePersonalFileInvitationPermission,
    cancelPersonalFileInvitation,
    joinPersonalFileCollaborator
  }
}

export default useSharePersonalFileActions
