import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import { LoadingStatus } from '@phase-software/types'

import { Dialog } from '../../components/shared'
import { loadLocale } from '../../configs/moment'
import { UserFieldsFragment } from '../../generated/graphql'
import { useNewsletterSubscription } from '../../hooks/useNewsletterSubscription'
import { useSetNotification } from '../../providers/NotificationProvider'
import { useProfile, useProfileActions } from '../../providers/ProfileProvider'
import { captureJamMeta } from '../../services/jam'

type PersonalSettingsContextData = {
  avatar: UserFieldsFragment['avatar']
  changeUsername: () => Promise<void>
  changePreferredLanguage: () => Promise<void>
  changePassword: () => void
  changeNewsletterSubscription: () => Promise<void>
  confirmPassword: string
  email: UserFieldsFragment['email']
  username: string
  preferredLanguage: string
  newPassword: string
  newsletterSubscription: boolean
  password: string
  passwordChanged: boolean
  setConfirmPassword: (confirmPassword: string) => void
  setUsername: (name: PersonalSettingsContextData['username']) => void
  setPreferredLanguage: (language: string) => void
  setNewPassword: (newPassword: string) => void
  setNewsletterSubscription: (value: boolean) => void
  setPassword: (password: string) => void
  updateAvatar: (file: File) => void
  removeAvatar: () => Promise<void>
}

const PersonalSettingsContext = createContext<PersonalSettingsContextData | undefined>(undefined)

const PersonalSettingsContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { t, i18n } = useTranslation('workspace')
  const history = useHistory()

  const { addNotification } = useSetNotification()
  const { updatePassword, updateUsername, updateAvatar, removeAvatar, updateLanguage } = useProfileActions()
  const { isSubscribed, setIsSubscribed } = useNewsletterSubscription()

  const user = useProfile()
  const originalUsername = user?.username ?? ''

  const unblockRef = useRef<any>(null)

  const [username, setUsername] = useState(originalUsername)
  const [preferredLanguage, setPreferredLanguage] = useState(i18n.language)
  const [password, setPassword] = useState('')
  const [newsletterSubscription, setNewsletterSubscription] = useState(false)
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const [isSavingChange, setIsSavingChange] = useState(false)
  const [nextPath, setNextPath] = useState('')

  useEffect(() => {
    setUsername(originalUsername)
  }, [originalUsername])

  const usernameChanged = username !== originalUsername && username.trim().length > 0
  const passwordChanged = newPassword.length > 0 && confirmPassword.length > 0 && password.length > 0
  const subscriptionChanged = newsletterSubscription !== isSubscribed
  const preferredLanguageChanged = preferredLanguage !== i18n.language

  const clearPasswordInputs = () => {
    setPassword('')
    setNewPassword('')
    setConfirmPassword('')
  }

  const changeUsername = async () => updateUsername(username)

  const changePreferredLanguage = async () => {
    document.documentElement.lang = preferredLanguage
    captureJamMeta('lang', preferredLanguage)
    await updateLanguage(JSON.stringify(preferredLanguage))
    await loadLocale(preferredLanguage)
    await i18n.changeLanguage(preferredLanguage)
  }

  const changePassword = async () => {
    try {
      await updatePassword({ oldPassword: password, newPassword })
      clearPasswordInputs()

      addNotification({ type: 'success', content: t('message.profile_updated') })
    } catch (err) {
      addNotification({ type: 'error', content: t('message.failed') })
    }
  }

  const changeNewsletterSubscription = async () => {
    if (subscriptionChanged) {
      await setIsSubscribed(newsletterSubscription)
    }
  }

  const handleNavigation = useCallback(
    (path: string) => {
      if (usernameChanged || passwordChanged || subscriptionChanged || preferredLanguageChanged) {
        setShowConfirmationDialog(true)
        setNextPath(path)
        return false // Prevent the navigation
      }
    },
    [usernameChanged, passwordChanged, subscriptionChanged, preferredLanguageChanged]
  )

  useEffect(() => {
    unblockRef.current = history.block((location) => handleNavigation(location.pathname))

    return () => {
      unblockRef.current?.()
    }
  }, [handleNavigation, history, usernameChanged, passwordChanged])

  const handleUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      if (usernameChanged || passwordChanged) {
        event.preventDefault()
        event.returnValue = ''
      }
    },
    [usernameChanged, passwordChanged]
  )
  useEffect(() => {
    window.addEventListener('beforeunload', handleUnload)

    return () => {
      window.removeEventListener('beforeunload', handleUnload)
    }
  }, [handleUnload, usernameChanged, passwordChanged])

  useEffect(() => {
    setNewsletterSubscription(isSubscribed)
  }, [isSubscribed])

  const confirmNavigation = async () => {
    if (isSavingChange) return
    try {
      setIsSavingChange(true)
      if (usernameChanged) await changeUsername()
      if (preferredLanguageChanged) await changePreferredLanguage()
      if (passwordChanged) await changePassword()
      if (subscriptionChanged) await changeNewsletterSubscription()
      setShowConfirmationDialog(false)
      unblockRef.current?.()
      history.push(nextPath)
    } catch (error) {
      console.error(error)
    } finally {
      setIsSavingChange(false)
    }
  }

  const cancelNavigation = () => {
    setShowConfirmationDialog(false)
    if (usernameChanged) setUsername(originalUsername)
    if (preferredLanguageChanged) setPreferredLanguage(i18n.language)
    if (passwordChanged) clearPasswordInputs()
    if (subscriptionChanged) setNewsletterSubscription(isSubscribed)
    unblockRef.current?.()
    history.push(nextPath)
  }

  return (
    <PersonalSettingsContext.Provider
      value={{
        avatar: user?.avatar,
        changePassword,
        changeUsername,
        changePreferredLanguage,
        changeNewsletterSubscription,
        confirmPassword,
        email: user?.email ?? '',
        username,
        preferredLanguage,
        newPassword,
        newsletterSubscription,
        password,
        passwordChanged,
        setConfirmPassword,
        setUsername,
        setPreferredLanguage,
        setNewPassword,
        setNewsletterSubscription,
        setPassword,
        updateAvatar,
        removeAvatar
      }}
    >
      {children}

      <Dialog
        data-test-id="delete-confirm-dialog"
        size="xs"
        title={t('dialog.unsaved_changes')}
        confirmBtnText={t('dialog.save_changes')}
        cancelBtnText={t('dialog.dont_save')}
        onConfirm={confirmNavigation}
        onCancel={cancelNavigation}
        showProgressButton={isSavingChange}
        progressStatus={isSavingChange ? LoadingStatus.WAITING : LoadingStatus.INITIAL}
        open={showConfirmationDialog}
      >
        {t('dialog.unsaved_changes_message')}
      </Dialog>
    </PersonalSettingsContext.Provider>
  )
}

function usePersonalSettingsContext(): PersonalSettingsContextData {
  const context = useContext(PersonalSettingsContext)
  if (context === undefined) {
    throw new Error('usePersonalSettingsContext must be used within a PersonalSettingsContextProvider')
  }
  return context
}

export { usePersonalSettingsContext, PersonalSettingsContextProvider }
