import React from 'react'

import WideTitle, { WideTitleIconProps } from '../../shared/WideTitle'

export type InspectContentProps = {
  title?: string
  spacedDivider?: boolean
  showChildren?: boolean
  children: React.ReactNode
  icons?: WideTitleIconProps[]
}

const InspectContent = ({
  title,
  spacedDivider = false,
  showChildren = true,
  children,
  icons = []
}: InspectContentProps) => {
  return (
    <>
      <div className={`border-t border-solid border-neutral-80 ${spacedDivider && 'mx-16'}`} />
      <div className={`${showChildren && 'pb-8'} ${title || 'pt-8'}`}>
        {title && <WideTitle icons={icons}>{title}</WideTitle>}
        {showChildren && children}
      </div>
    </>
  )
}

export default InspectContent
