import { PropComponentType, Unit, ContentAnchorType } from '@phase-software/types'
import { notNull, isNum } from '@phase-software/data-utils'
import PropertyComponent from './PropertyComponent'

/** @typedef {import('../DataStore').DataStore} DataStore */
/** @typedef {import('./PropertyComponent').AppliedRef} AppliedRef */

export default class ContentAnchorComponent extends PropertyComponent {
    /**
     * @param {DataStore} dataStore
     * @param {ContentAnchorComponentData} [data]
     * @param {object} [options]
     * @param {bool} [options.regenId=false]   if set to true, will generate new ID
     */
    constructor(dataStore, data = {}, options) {
        super(dataStore, data, options)

        const {
            contentAnchorX,
            contentAnchorY,
            contentAnchorXUnit,
            contentAnchorYUnit,
            contentAnchorAutoAdd,
            contentAnchorType
        } = data

        this.componentType = PropComponentType.CONTENT_ANCHOR
        this.contentAnchorX = isNum(contentAnchorX) ? contentAnchorX : 0
        this.contentAnchorY = isNum(contentAnchorY) ? contentAnchorY : 0
        this.contentAnchorXUnit = contentAnchorXUnit in Unit ? contentAnchorXUnit : Unit.PIXEL
        this.contentAnchorYUnit = contentAnchorYUnit in Unit ? contentAnchorYUnit : Unit.PIXEL
        // Disable contentAnchor feature for now
        // Will enable it when we have user need it
        this.contentAnchorAutoAdd = notNull(contentAnchorAutoAdd) ? contentAnchorAutoAdd : false
        this.contentAnchorType = notNull(contentAnchorType) ? contentAnchorType : ContentAnchorType.CONTENT

        if (!this.name) {
            this.name = 'contentAnchor'
        }
    }

    /**
     * @param {Partial<ContentAnchorComponentSetData>} data
     */
    set(data) {
        super.set(data)

        if (isNum(data.contentAnchorX)) {
            this.updateProp('contentAnchorX', data.contentAnchorX)
        } else if (data.contentAnchorX === null) {
            this.updateProp('contentAnchorX', undefined)
        }
        if (isNum(data.contentAnchorY)) {
            this.updateProp('contentAnchorY', data.contentAnchorY)
        } else if (data.contentAnchorY === null) {
            this.updateProp('contentAnchorY', undefined)
        }
        if (data.contentAnchorXUnit in Unit && notNull(this.contentAnchorX)) {
            this.updateProp('contentAnchorXUnit', data.contentAnchorXUnit)
        }
        if (data.contentAnchorYUnit in Unit && notNull(this.contentAnchorY)) {
            this.updateProp('contentAnchorYUnit', data.contentAnchorYUnit)
        }
        if (notNull(data.contentAnchorAutoAdd)) {
            this.updateProp('contentAnchorAutoAdd', data.contentAnchorAutoAdd)
        }
        if (notNull(data.contentAnchorType)) {
            this.updateProp('contentAnchorType', data.contentAnchorType)
        }
    }

    /**
     * Override this in subclasses
     * CALL super._clone() at the top of overriden method
     * @protected
     * @param {AppliedRef} [ref]
     * @returns {ContentAnchorComponent} obj
     */
    _clone(ref) {
        const obj = super._clone(ref)
        obj.contentAnchorX = this.contentAnchorX
        obj.contentAnchorY = this.contentAnchorY
        obj.contentAnchorXUnit = this.contentAnchorXUnit
        obj.contentAnchorYUnit = this.contentAnchorYUnit
        obj.contentAnchorAutoAdd = this.contentAnchorAutoAdd
        obj.contentAnchorType = this.contentAnchorType
        return obj
    }

    /**
     * Override this in subclasses
     * CALL super._save() at the top of overriden method
     * @protected
     * @returns {ContentAnchorComponentData} data
     */
    _save() {
        const data = super._save()
        if (notNull(this.contentAnchorX)) {
            data.contentAnchorX = this.contentAnchorX
            data.contentAnchorXUnit = this.contentAnchorXUnit
        }
        if (notNull(this.contentAnchorY)) {
            data.contentAnchorY = this.contentAnchorY
            data.contentAnchorYUnit = this.contentAnchorYUnit
        }
        data.contentAnchorAutoAdd = this.contentAnchorAutoAdd
        data.contentAnchorType = this.contentAnchorType
        return data
    }
}

ContentAnchorComponent.BASE_DEFAULT_DATA = {
    contentAnchorX: 0,
    contentAnchorY: 0,
    contentAnchorXUnit: 'px',
    contentAnchorYUnit: 'px',
    contentAnchorAutoAdd: true,
    contentAnchorType: ContentAnchorType.CONTENT
}

/** @typedef {import('./PropertyComponent').PropertyComponentData} PropertyComponentData */

/**
 * @typedef {PropertyComponentData} ContentAnchorComponentData
 * @property {(number | undefined)} contentAnchorX
 * @property {(number | undefined)} contentAnchorY
 * @property {(string | undefined)} contentAnchorXUnit
 * @property {(string | undefined)} contentAnchorYUnit
 * @property {(bool | undefined)} contentAnchorAutoAdd
 * @property {(ContentAnchorType | undefined)} contentAnchorType
 */

/**
 * @typedef {PropertyComponentData} ContentAnchorComponentSetData
 * @property {(number | null)} contentAnchorX
 * @property {(number | null)} contentAnchorY
 * @property {string} contentAnchorXUnit
 * @property {string} contentAnchorYUnit
 * @property {bool} contentAnchorAutoAdd
 * @property {ContentAnchorType} contentAnchorType
 */
