import { PropComponentType } from '@phase-software/types'
import { isNum } from '@phase-software/data-utils'
import PropertyComponent from './PropertyComponent'

/** @typedef {import('../DataStore').DataStore} DataStore */
/** @typedef {import('./PropertyComponent').AppliedRef} AppliedRef */

export default class ReferencePointComponent extends PropertyComponent {
    /**
     * @param {DataStore} dataStore
     * @param {ReferencePointComponentData} [data]
     * @param {object} [options]
     * @param {bool} [options.regenId=false]   if set to true, will generate new ID
     */
    constructor(dataStore, data = {}, options) {
        super(dataStore, data, options)

        const { referencePointX, referencePointY } = data

        this.componentType = PropComponentType.REFERENCE_POINT
        this.referencePointX = isNum(referencePointX) ? referencePointX : 0
        this.referencePointY = isNum(referencePointY) ? referencePointY : 0

        if (!this.name) {
            this.name = 'referencePoint'
        }
    }

    /**
     * @param {Partial<ReferencePointComponentSetData>} data
     */
    set(data) {
        super.set(data)

        if (isNum(data.referencePointX)) {
            this.updateProp('referencePointX', data.referencePointX)
        }
        if (isNum(data.referencePointY)) {
            this.updateProp('referencePointY', data.referencePointY)
        }
    }

    /**
     * Override this in subclasses
     * CALL super._clone() at the top of overriden method
     * @protected
     * @param {AppliedRef} [ref]
     * @returns {ReferencePointComponent} obj
     */
    _clone(ref) {
        const obj = super._clone(ref)
        obj.referencePointX = this.referencePointX
        obj.referencePointY = this.referencePointY
        return obj
    }

    /**
     * Override this in subclasses
     * CALL super._save() at the top of overriden method
     * @protected
     * @returns {ReferencePointComponentData} data
     */
    _save() {
        const data = super._save()
        data.referencePointX = this.referencePointX
        data.referencePointY = this.referencePointY
        return data
    }
}

ReferencePointComponent.BASE_DEFAULT_DATA = {
    referencePointX: 0,
    referencePointY: 0
}

/** @typedef {import('./PropertyComponent').PropertyComponentData} PropertyComponentData */

/**
 * @typedef {PropertyComponentData} ReferencePointComponentData
 * @property {(number)} referencePointX
 * @property {(number)} referencePointY
 */

/**
 * @typedef {PropertyComponentData} ReferencePointComponentSetData
 * @property {(number)} referencePointX
 * @property {(number)} referencePointY
 */
