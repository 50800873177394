import { PropComponentType } from '@phase-software/types'
import { notNull } from '@phase-software/data-utils'
import PropertyComponent from './PropertyComponent'

/** @typedef {import('../DataStore').DataStore} DataStore */
/** @typedef {import('./PropertyComponent').AppliedRef} AppliedRef */

export default class BlurGaussianComponent extends PropertyComponent {
    /**
     * @param {DataStore} dataStore
     * @param {BlurGaussianComponentData} [data]
     * @param {object} [options] 
     * @param {bool} [options.regenId=false]   if set to true, will generate new ID
     */
    constructor(dataStore, data = {}, options) {
        super(dataStore, data, options)

        const { amount, visible } = data

        this.componentType = PropComponentType.BLUR_GAUSSIAN
        this.visible = notNull(visible) ? visible : true
        this.amount = notNull(amount) ? amount : 10

        if (!this.name) {
            this.name = 'blurGaussian'
        }
    }

    /** @param {Partial<BlurGaussianComponentData>} data */
    set(data) {
        super.set(data)

        if (notNull(data.amount)) {
            this.updateProp('amount', data.amount)
        }
        if (notNull(data.visible)) {
            this.updateProp('visible', data.visible)
        }
    }

    /**
     * Override this in subclasses
     * CALL super._clone() at the top of overriden method
     * @protected
     * @param {AppliedRef} [ref]
     * @returns {BlurGaussianComponent}
     */
    _clone(ref) {
        const obj = super._clone(ref)
        obj.amount = this.amount
        obj.visible = this.visible
        return obj
    }

    /**
     * Override this in subclasses
     * CALL super._save() at the top of overriden method
     * @protected
     * @returns {BlurGaussianComponentData}
     */
    _save() {
        const data = super._save()
        data.amount = this.amount
        data.visible = this.visible
        return data
    }
}

/** @typedef {import('./PropertyComponent').PropertyComponentData} PropertyComponentData */

/**
 * @typedef {BlurGaussianComponentData} BlurGaussianComponentData
 * @property {number} amount
 * @property {bool} visible
 */
