import { pick } from 'lodash'

export const getTextSpacingData = (textSpacing) => {
  return pick(textSpacing, [
    'characterSpacing',
    'id',
    'lineSpacing',
    'paragraphIndent',
    'paragraphSpacing',
    'wordSpacing'
  ])
}
