import React from 'react'
import { useParams } from 'react-router'

import MotionChallengeBanner from '../../components/MotionChallengeBanner'
import { ProjectPermissionProvider } from '../../providers/ProjectPermissionProvider'
import { useWorkspaceContext } from '../../providers/WorkspaceContextProvider'
import FileList from './FileList'

export enum FilesPageEnum {
  ARCHIVE = 'archive',
  SHARED = 'shared',
  NORMAL_PROJECT = 'project',
  DRAFT_PROJECT = 'drafts'
}

type FilesProps = {
  page?: FilesPageEnum
}

const Files = ({ page = FilesPageEnum.NORMAL_PROJECT }: FilesProps) => {
  const { projectId: normalProjectId } = useParams<{ projectId: string }>()

  const { workspaceData } = useWorkspaceContext()

  const projectId = normalProjectId || workspaceData.draftProjectId

  return (
    <ProjectPermissionProvider key={projectId} id={projectId}>
      <MotionChallengeBanner />
      <FileList id={projectId} page={page} />
    </ProjectPermissionProvider>
  )
}

export default Files
