import { TeamMemberRoleEnum } from '@phase-software/types'

export const TEAM_ROLE_OPTIONS = [
  {
    name: 'team_role.admin',
    value: TeamMemberRoleEnum.ADMIN,
    dataTestId: 'admin-role'
  },
  {
    name: 'team_role.member',
    value: TeamMemberRoleEnum.MEMBER,
    dataTestId: 'member-role'
  },
  {
    name: 'team_role.guest',
    value: TeamMemberRoleEnum.GUEST,
    dataTestId: 'guest-role'
  }
]
