/* tslint:disable */
/* eslint-disable */
/**
 * PHASE API
 * Phase API service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateProjectRequest,
  CreateProjectResponse,
  GetProjectPermissionResponse,
  ModifyProjectOwnership,
  PatchProjectRequest,
} from '../models';

export interface ProjectApiControllerCreateTeamProjectRequest {
    teamId: string;
    createProjectRequest: CreateProjectRequest;
}

export interface ProjectApiControllerCreateUserProjectRequest {
    userId: string;
    createProjectRequest: CreateProjectRequest;
}

export interface ProjectApiControllerDeleteTeamProjectRequest {
    teamId: string;
    projectId: string;
}

export interface ProjectApiControllerDeleteUserProjectRequest {
    userId: string;
    projectId: string;
}

export interface ProjectApiControllerGetPersonalProjectPermissionRequest {
    userId: string;
    projectId: string;
}

export interface ProjectApiControllerGetTeamProjectPermissionRequest {
    teamId: string;
    projectId: string;
}

export interface ProjectApiControllerModifyProjectOwnershipRequest {
    userId: string;
    projectId: string;
    modifyProjectOwnership: ModifyProjectOwnership;
}

export interface ProjectApiControllerPatchTeamProjectRequest {
    teamId: string;
    projectId: string;
    patchProjectRequest: PatchProjectRequest;
}

export interface ProjectApiControllerPatchUserProjectRequest {
    userId: string;
    projectId: string;
    patchProjectRequest: PatchProjectRequest;
}

/**
 * 
 */
export class ProjectApi extends runtime.BaseAPI {

    /**
     */
    async projectApiControllerCreateTeamProjectRaw(requestParameters: ProjectApiControllerCreateTeamProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateProjectResponse>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling projectApiControllerCreateTeamProject.');
        }

        if (requestParameters.createProjectRequest === null || requestParameters.createProjectRequest === undefined) {
            throw new runtime.RequiredError('createProjectRequest','Required parameter requestParameters.createProjectRequest was null or undefined when calling projectApiControllerCreateTeamProject.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/teams/{teamId}/projects`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.createProjectRequest,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async projectApiControllerCreateTeamProject(requestParameters: ProjectApiControllerCreateTeamProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateProjectResponse> {
        const response = await this.projectApiControllerCreateTeamProjectRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectApiControllerCreateUserProjectRaw(requestParameters: ProjectApiControllerCreateUserProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateProjectResponse>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling projectApiControllerCreateUserProject.');
        }

        if (requestParameters.createProjectRequest === null || requestParameters.createProjectRequest === undefined) {
            throw new runtime.RequiredError('createProjectRequest','Required parameter requestParameters.createProjectRequest was null or undefined when calling projectApiControllerCreateUserProject.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/users/{userId}/projects`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.createProjectRequest,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async projectApiControllerCreateUserProject(requestParameters: ProjectApiControllerCreateUserProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateProjectResponse> {
        const response = await this.projectApiControllerCreateUserProjectRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectApiControllerDeleteTeamProjectRaw(requestParameters: ProjectApiControllerDeleteTeamProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling projectApiControllerDeleteTeamProject.');
        }

        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectApiControllerDeleteTeamProject.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/teams/{teamId}/projects/{projectId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async projectApiControllerDeleteTeamProject(requestParameters: ProjectApiControllerDeleteTeamProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.projectApiControllerDeleteTeamProjectRaw(requestParameters, initOverrides);
    }

    /**
     */
    async projectApiControllerDeleteUserProjectRaw(requestParameters: ProjectApiControllerDeleteUserProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling projectApiControllerDeleteUserProject.');
        }

        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectApiControllerDeleteUserProject.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/users/{userId}/projects/{projectId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async projectApiControllerDeleteUserProject(requestParameters: ProjectApiControllerDeleteUserProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.projectApiControllerDeleteUserProjectRaw(requestParameters, initOverrides);
    }

    /**
     * Get personal project permission
     */
    async projectApiControllerGetPersonalProjectPermissionRaw(requestParameters: ProjectApiControllerGetPersonalProjectPermissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProjectPermissionResponse>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling projectApiControllerGetPersonalProjectPermission.');
        }

        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectApiControllerGetPersonalProjectPermission.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/users/{userId}/projects/{projectId}:getMyPermissions`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get personal project permission
     */
    async projectApiControllerGetPersonalProjectPermission(requestParameters: ProjectApiControllerGetPersonalProjectPermissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProjectPermissionResponse> {
        const response = await this.projectApiControllerGetPersonalProjectPermissionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get team project permission
     */
    async projectApiControllerGetTeamProjectPermissionRaw(requestParameters: ProjectApiControllerGetTeamProjectPermissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProjectPermissionResponse>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling projectApiControllerGetTeamProjectPermission.');
        }

        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectApiControllerGetTeamProjectPermission.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/teams/{teamId}/projects/{projectId}:getMyPermissions`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get team project permission
     */
    async projectApiControllerGetTeamProjectPermission(requestParameters: ProjectApiControllerGetTeamProjectPermissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProjectPermissionResponse> {
        const response = await this.projectApiControllerGetTeamProjectPermissionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectApiControllerModifyProjectOwnershipRaw(requestParameters: ProjectApiControllerModifyProjectOwnershipRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling projectApiControllerModifyProjectOwnership.');
        }

        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectApiControllerModifyProjectOwnership.');
        }

        if (requestParameters.modifyProjectOwnership === null || requestParameters.modifyProjectOwnership === undefined) {
            throw new runtime.RequiredError('modifyProjectOwnership','Required parameter requestParameters.modifyProjectOwnership was null or undefined when calling projectApiControllerModifyProjectOwnership.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/users/{userId}/projects/{projectId}:updateOwnership`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.modifyProjectOwnership,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async projectApiControllerModifyProjectOwnership(requestParameters: ProjectApiControllerModifyProjectOwnershipRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.projectApiControllerModifyProjectOwnershipRaw(requestParameters, initOverrides);
    }

    /**
     */
    async projectApiControllerPatchTeamProjectRaw(requestParameters: ProjectApiControllerPatchTeamProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling projectApiControllerPatchTeamProject.');
        }

        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectApiControllerPatchTeamProject.');
        }

        if (requestParameters.patchProjectRequest === null || requestParameters.patchProjectRequest === undefined) {
            throw new runtime.RequiredError('patchProjectRequest','Required parameter requestParameters.patchProjectRequest was null or undefined when calling projectApiControllerPatchTeamProject.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/teams/{teamId}/projects/{projectId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.patchProjectRequest,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async projectApiControllerPatchTeamProject(requestParameters: ProjectApiControllerPatchTeamProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.projectApiControllerPatchTeamProjectRaw(requestParameters, initOverrides);
    }

    /**
     */
    async projectApiControllerPatchUserProjectRaw(requestParameters: ProjectApiControllerPatchUserProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling projectApiControllerPatchUserProject.');
        }

        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectApiControllerPatchUserProject.');
        }

        if (requestParameters.patchProjectRequest === null || requestParameters.patchProjectRequest === undefined) {
            throw new runtime.RequiredError('patchProjectRequest','Required parameter requestParameters.patchProjectRequest was null or undefined when calling projectApiControllerPatchUserProject.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/users/{userId}/projects/{projectId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.patchProjectRequest,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async projectApiControllerPatchUserProject(requestParameters: ProjectApiControllerPatchUserProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.projectApiControllerPatchUserProjectRaw(requestParameters, initOverrides);
    }

}
