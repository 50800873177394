import { EffectType } from '@phase-software/types'
import TrimPath from './TrimPath'
import { ComputedTrimPath } from './ComputedTrimPath'

/**
 * Creates a new instance of Effect
 * @param {DataStore} dataStore
 * @param {EffectData} [data]
 * @param {object} [options] 
 * @param {bool} [options.regenId=false]   if set to true, will generate new ID
 * @returns {Effect}
 */
export function createEffect(dataStore, data, options) {
    switch (data.effectType) {
        case EffectType.TRIM_PATH:
            return new TrimPath(dataStore, data, options)
        default:
            return null
    }
}

/**
 * Creates ComputedEffect with different types
 * @param {DataStore} dataStore
 * @param {EffectType} effectType
 * @param {object} effectData
 * @returns {ComputedEffect}
 */
export function createComputedEffect(dataStore, effectType, effectData) {
    switch (effectType) {
        case EffectType.TRIM_PATH:
            return new ComputedTrimPath(dataStore, effectData)
        default:
            return null
    }
}

/**
 * @param {Effect} effect
 * @returns {ComputedEffectData}
 */
export function effectToComputedEffectData(effect) {
    const effectType = effect.effectType

    const computedEffectData = {
        effectType,
        effectId: effect.id,
        name: effect.name,
        visible: effect.visible
    }

    switch (effectType) {
        case EffectType.TRIM_PATH:
            computedEffectData.start = effect.start
            computedEffectData.end = effect.end
            computedEffectData.offset = effect.offset
            computedEffectData.mode = effect.mode
            break;
    }

    return computedEffectData
}

/**
 * Creates or loads ComputedEffect from Effect (of respective type)
 * @param {DataStore} dataStore
 * @param {Effect} effect
 * @param {string} elementId
 * @returns {ComputedEffect}
 */
export function computeEffect(dataStore, effect, elementId) {
    const { effectType } = effect

    const computedEffectData = effectToComputedEffectData(effect)

    computedEffectData.effectId = effect.id
    computedEffectData.elementId = elementId

    return createComputedEffect(dataStore, effectType, computedEffectData)
}
