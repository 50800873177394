import React, { createRef, PureComponent } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { color } from '../shared'
import { TIMELINE_ADJUST_MIN_HEIGHT } from '../../constant'


const BottomResizeHandlerContainer = styled.div.attrs(({ timelineHeight }) => ({
  style: { bottom: `${timelineHeight}px` }
}))`
  position: absolute;
  left: 0;
  height: 8px;
  right: 0;
  z-index: 10;
  cursor: row-resize;
  transform: translate(0, 50%);

  ${(props) => !props.active && `display: none`};

  &:hover,
  &.active {
    ::after {
      content: '';
      display: block;
      background: ${color('primary', 50)};
      height: 2px;
      transform: translate(0, 100%);
    }
  }
`
const MARGIN_FROM_TOP = 48;
const MARGIN_FROM_RULER = 48;
const FLOATING_CONTROL_SPACE = 35;
const MAX_HEIGHT_OFFSET = MARGIN_FROM_TOP + MARGIN_FROM_RULER + FLOATING_CONTROL_SPACE;

export class BottomResizeHandler extends PureComponent {
  constructor(props) {
    super(props)
    this.ref = createRef()
    this.rafId = null
    this.rafHandleResizeMouseMove = (e) => {
      if (this.rafId) cancelAnimationFrame(this.rafId)
      this.rafId = requestAnimationFrame(() => {
        this.handleResizeMouseMove(e)
        this.rafId = null
      })
    }
  }

  componentDidMount() {
    window.addEventListener('mousemove', this.rafHandleResizeMouseMove)
    window.addEventListener('mouseup', this.handleResizeMouseUp)
  }

  componentWillUnmount() {
    window.removeEventListener('mousemove', this.rafHandleResizeMouseMove)
    window.removeEventListener('mouseup', this.handleResizeMouseUp)
  }

  /**
   * Resize Handler Mouse Down Event
   */
  handleResizeMouseDown = () => {
    document.body.classList.add('v-resizing')
    this.ref.current.classList.add('active')
    this.isResizeMouseDown = true
    this.screenHeight = window.innerHeight
  }

  /**
   * Resize Handler Mouse Move Event
   * @param {object} e
   */
  handleResizeMouseMove = (e) => {
    if (!this.isResizeMouseDown) {
      return
    }

    const currentPosition = e.pageY
    let height = Math.max(
      this.screenHeight - currentPosition,
      TIMELINE_ADJUST_MIN_HEIGHT
    )
    height = Math.min(window.innerHeight - MAX_HEIGHT_OFFSET, height)
    this.props.onPositionChange(height)
  }

  /**
   * Resize Handler Mouse Up Event
   */
  handleResizeMouseUp = () => {
    document.body.classList.remove('v-resizing')
    this.isResizeMouseDown = false
    this.ref.current.classList.remove('active')
  }

  render() {
    return (
      <BottomResizeHandlerContainer
        timelineHeight={this.props.timelineHeight}
        ref={this.ref}
        onMouseDown={this.handleResizeMouseDown}
        active={this.props.active}
      />
    )
  }
}

BottomResizeHandler.propTypes = {
  timelineHeight: PropTypes.number.isRequired,
  onPositionChange: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired
}

export default BottomResizeHandler
