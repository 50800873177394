import {
    ElementType,
    GeometryType,
    EditMode,
    ToolType
} from '@phase-software/types'

export const Hover = {
    NONE: 'NONE',
    ELEMENT: 'ELEMENT',
    RESIZE_HANDLE: 'RESIZE_HANDLE',
    ROTATE_HANDLE: 'ROTATE_HANDLE',
    GRADIENT_STOP_HANDLE: 'GRADIENT_STOP_HANDLE',
    GRADIENT_TRANSFORM_START_HANDLE: 'GRADIENT_TRANSFORM_START_HANDLE',
    GRADIENT_TRANSFORM_END_HANDLE: 'GRADIENT_TRANSFORM_END_HANDLE',
    GRADIENT_TRANSFORM_SHAPE_HANDLE: 'GRADIENT_TRANSFORM_SHAPE_HANDLE',
    GRADIENT_TRANSFORM_REFERENCE_LINE: 'GRADIENT_TRANSFORM_REFERENCE_LINE',
    SCROLLBAR: 'SCROLLBAR',
    CURVE_CONTROL: 'CURVE_CONTROL',
    VERTEX: 'VERTEX',
    ENDPOINT: 'ENDPOINT',
    EDGE: 'EDGE',
    // FACE: 'FACE',// FACE isnot supported
    ORIGIN: 'ORIGIN',
    MULTIPLE_SELECTION_BOUND: 'MULTIPLE_SELECTION_BOUND',
    MOTION_POINT: 'MOTION_POINT'
}

export const ElementSelection = {
    NONE: 'NONE',
    SCREEN: 'SCREEN',
    CONTAINER: 'CONTAINER',
    TEXT: 'TEXT',
    RECTANGLE: 'RECTANGLE',
    ELLIPSE: 'ELLIPSE',
    POLYGON: 'POLYGON',
    REGULAR_POLYGON: 'REGULAR_POLYGON',
    STAR: 'STAR',
    LINE: 'LINE',
    MULTIPLE: 'MULTIPLE'
}

export const ElementSelectionTypeMap = {
    [ElementType.SCREEN]: ElementSelection.SCREEN,
    [ElementType.CONTAINER]: ElementSelection.CONTAINER,
    [ElementType.TEXT]: ElementSelection.TEXT,
    [ElementType.PATH]: {
        [GeometryType.RECTANGLE]: ElementSelection.RECTANGLE,
        [GeometryType.ELLIPSE]: ElementSelection.ELLIPSE,
        [GeometryType.POLYGON]: ElementSelection.POLYGON,
        [GeometryType.REGULAR_POLYGON]: ElementSelection.REGULAR_POLYGON,
        [GeometryType.STAR]: ElementSelection.STAR,
        [GeometryType.LINE]: ElementSelection.LINE
    }
}

export const CellSelection = {
    NONE: 'NONE',
    VERTEX: 'VERTEX',
    ENDPOINT: 'ENDPOINT',
    SEGMENT: 'SEGMENT',
    FACE: 'FACE',
    MIX: 'MIX'
}

export const Events = {
    ACTIVATE_PAN: 'ACTIVATE_PAN',
    DEACTIVATE_PAN: 'DEACTIVATE_PAN',
    COMMENT_DEACTIVATE_PAN: 'COMMENT_DEACTIVATE_PAN',
    ACTIVATE_SHAPE_MODE: 'ACTIVATE_SHAPE_MODE',
    DEACTIVATE_SHAPE_MODE: 'DEACTIVATE_SHAPE_MODE',
    ACTIVATE_TEXT_MODE: 'ACTIVATE_TEXT_MODE',
    DEACTIVATE_TEXT_MODE: 'DEACTIVATE_TEXT_MODE',
    ACTIVATE_GRADIENT_HANDLES_MODE: 'ACTIVATE_GRADIENT_HANDLES_MODE',
    DEACTIVATE_GRADIENT_HANDLES_MODE: 'DEACTIVATE_GRADIENT_HANDLES_MODE',
    ACTIVATE_SELECT_TOOL: 'ACTIVATE_SELECT_TOOL',
    ACTIVATE_SCALE_TOOL: 'ACTIVATE_SCALE_TOOL',
    ACTIVATE_HAND_TOOL: 'ACTIVATE_HAND_TOOL',
    ACTIVATE_RECTANGLE_TOOL: 'ACTIVATE_RECTANGLE_TOOL',
    ACTIVATE_ELLIPSE_TOOL: 'ACTIVATE_ELLIPSE_TOOL',
    ACTIVATE_POLYGON_TOOL: 'ACTIVATE_POLYGON_TOOL',
    ACTIVATE_STAR_TOOL: 'ACTIVATE_STAR_TOOL',
    ACTIVATE_LINE_TOOL: 'ACTIVATE_LINE_TOOL',
    ACTIVATE_ARROW_TOOL: 'ACTIVATE_ARROW_TOOL',
    ACTIVATE_CONTAINER_TOOL: 'ACTIVATE_CONTAINER_TOOL',
    ACTIVATE_PEN_TOOL: 'ACTIVATE_PEN_TOOL',
    ACTIVATE_COMMENT_TOOL: 'ACTIVATE_COMMENT_TOOL',
    ACTIVATE_UNION_TOOL: 'ACTIVATE_UNION_TOOL',
    ACTIVATE_SUBTRACT_TOOL: 'ACTIVATE_SUBTRACT_TOOL',
    ACTIVATE_INTERSECT_TOOL: 'ACTIVATE_INTERSECT_TOOL',
    ACTIVATE_EXCLUDE_TOOL: 'ACTIVATE_EXCLUDE_TOOL',
    ACTIVATE_EYE_DROPPER_TOOL: 'ACTIVATE_EYE_DROPPER_TOOL',
    ACTIVATE_GRADIENT_MODE: 'ACTIVATE_GRADIENT_MODE',
    START_CREATE_ELEMENT: 'START_CREATE_ELEMENT',
    UPDATE_CREATE_ELEMENT: 'UPDATE_CREATE_ELEMENT',
    END_CREATE_ELEMENT: 'END_CREATE_ELEMENT',
    SWITCH_MODE: 'SWITCH_MODE',
    HOVER_ELEMENT: 'HOVER_ELEMENT',
    HOVER_DEEP_ELEMENT: 'HOVER_DEEP_ELEMENT',
    HOVER_SCROLLBAR: 'HOVER_SCROLLBAR',
    HOVER_BOX_HANDLE: 'HOVER_BOX_HANDLE',
    HOVER_GRADIENT_HANDLE: 'HOVER_GRADIENT_HANDLE',
    HOVER_ORIGIN: 'HOVER_ORIGIN',
    HOVER_CELL: 'HOVER_CELL',
    HOVER_CREATE_PATH: 'HOVER_CREATE_PATH',
    HOVER_MOTION_POINT: 'HOVER_MOTION_POINT',
    START_PANNING: 'START_PANNING',
    UPDATE_PANNING: 'UPDATE_PANNING',
    END_PANNING: 'END_PANNING',
    START_AREA_SELECT_ELEMENT: 'START_AREA_SELECT_ELEMENT',
    UPDATE_AREA_SELECT_ELEMENT: 'UPDATE_AREA_SELECT_ELEMENT',
    END_AREA_SELECT_ELEMENT: 'END_AREA_SELECT_ELEMENT',
    START_AREA_SELECT_CELL: 'START_AREA_SELECT_CELL',
    UPDATE_AREA_SELECT_CELL: 'UPDATE_AREA_SELECT_CELL',
    END_AREA_SELECT_CELL: 'END_AREA_SELECT_CELL',
    START_DRAG_ELEMENT: 'START_DRAG_ELEMENT',
    UPDATE_DRAG_ELEMENT: 'UPDATE_DRAG_ELEMENT',
    END_DRAG_ELEMENT: 'END_DRAG_ELEMENT',
    START_DRAG_MULTI_ELEMENTS: 'START_DRAG_MULTI_ELEMENTS',
    UPDATE_DRAG_MULTI_ELEMENTS: 'UPDATE_DRAG_MULTI_ELEMENTS',
    END_DRAG_MULTI_ELEMENTS: 'END_DRAG_MULTI_ELEMENTS',
    START_SCALE_ELEMENT: 'START_SCALE_ELEMENT',
    UPDATE_SCALE_ELEMENT: 'UPDATE_SCALE_ELEMENT',
    END_SCALE_ELEMENT: 'END_SCALE_ELEMENT',
    START_DRAG_ORIGIN: 'START_DRAG_ORIGIN',
    UPDATE_DRAG_ORIGIN: 'UPDATE_DRAG_ORIGIN',
    END_DRAG_ORIGIN: 'END_DRAG_ORIGIN',
    START_RESIZE_ELEMENT: 'START_RESIZE_ELEMENT',
    UPDATE_RESIZE_ELEMENT: 'UPDATE_RESIZE_ELEMENT',
    END_RESIZE_ELEMENT: 'END_RESIZE_ELEMENT',
    START_ROTATE_ELEMENT: 'START_ROTATE_ELEMENT',
    UPDATE_ROTATE_ELEMENT: 'UPDATE_ROTATE_ELEMENT',
    END_ROTATE_ELEMENT: 'END_ROTATE_ELEMENT',
    START_DRAG_CELL: 'START_DRAG_CELL',
    UPDATE_DRAG_CELL: 'UPDATE_DRAG_CELL',
    END_DRAG_CELL: 'END_DRAG_CELL',
    START_DRAG_MOTION_POINT: 'START_DRAG_MOTION_POINT',
    UPDATE_DRAG_MOTION_POINT: 'UPDATE_DRAG_MOTION_POINT',
    END_DRAG_MOTION_POINT: 'END_DRAG_MOTION_POINT',
    // Now only use CELL is enough
    // START_DRAG_VERTEX: 'START_DRAG_VERTEX',
    // UPDATE_DRAG_VERTEX: 'UPDATE_DRAG_VERTEX',
    // END_DRAG_VERTEX: 'END_DRAG_VERTEX',
    // START_DRAG_SEGMENT: 'START_DRAG_SEGMENT',
    // UPDATE_DRAG_SEGMENT: 'UPDATE_DRAG_SEGMENT',
    // END_DRAG_SEGMENT: 'END_DRAG_SEGMENT',
    // START_DRAG_FACE: 'START_DRAG_FACE',
    // UPDATE_DRAG_FACE: 'UPDATE_DRAG_FACE',
    // END_DRAG_FACE: 'END_DRAG_FACE',
    START_DRAG_GRADIENT_HANDLE: 'START_DRAG_GRADIENT_HANDLE',
    UPDATE_DRAG_GRADIENT_HANDLE: 'UPDATE_DRAG_GRADIENT_HANDLE',
    END_DRAG_GRADIENT_HANDLE: 'END_DRAG_GRADIENT_HANDLE',
    START_MOVE_TEXT_CARET: 'START_MOVE_TEXT_CARET',
    UPDATE_MOVE_TEXT_CARET: 'UPDATE_MOVE_TEXT_CARET',
    END_MOVE_TEXT_CARET: 'END_MOVE_TEXT_CARET',
    START_EXPAND_TEXT_SELECTION: 'START_EXPAND_TEXT_SELECTION',
    UPDATE_EXPAND_TEXT_SELECTION: 'UPDATE_EXPAND_TEXT_SELECTION',
    END_EXPAND_TEXT_SELECTION: 'END_EXPAND_TEXT_SELECTION',
    HOVER_CELL_WITH_SELECTION: 'HOVER_CELL_WITH_SELECTION',
    START_DRAW_PATH: 'START_DRAW_PATH',
    UPDATE_DRAW_PATH: 'UPDATE_DRAW_PATH',
    END_DRAW_PATH: 'END_DRAW_PATH',
    START_DRAW_PATH_ON_VERTEX: 'START_DRAW_PATH_ON_VERTEX',
    UPDATE_DRAW_PATH_ON_VERTEX: 'UPDATE_DRAW_PATH_ON_VERTEX',
    END_DRAW_PATH_ON_VERTEX: 'END_DRAW_PATH_ON_VERTEX',
    START_DRAW_PATH_ON_EDGE: 'START_DRAW_PATH_ON_EDGE',
    UPDATE_DRAW_PATH_ON_EDGE: 'UPDATE_DRAW_PATH_ON_EDGE',
    END_DRAW_PATH_ON_EDGE: 'END_DRAW_PATH_ON_EDGE',
    START_MOVE_SCROLLBAR: 'START_MOVE_SCROLLBAR',
    UPDATE_MOVE_SCROLLBAR: 'UPDATE_MOVE_SCROLLBAR',
    END_MOVE_SCROLLBAR: 'END_MOVE_SCROLLBAR',
    START_ZOOM_TO_SELECTION: 'START_ZOOM_TO_SELECTION',
    UPDATE_ZOOM_TO_SELECTION: 'UPDATE_ZOOM_TO_SELECTION',
    END_ZOOM_TO_SELECTION: 'END_ZOOM_TO_SELECTION',
    START_DRAG_SCROLLBAR: 'START_DRAG_SCROLLBAR',
    UPDATE_DRAG_SCROLLBAR: 'UPDATE_DRAG_SCROLLBAR',
    END_DRAG_SCROLLBAR: 'END_DRAG_SCROLLBAR',
    DRAG_OVER: 'DRAG_OVER',
    DRAG_END: 'DRAG_END',
    DROP_WITH_FILES: 'DROP_WITH_FILES',
    PASTE_WITH_FILES: 'PASTE_WITH_FILES',
    INSERT_IMAGE: 'INSERT_IMAGE',
    INSERT_SVG: 'INSERT_SVG',
    SELECT_ELEMENT: 'SELECT_ELEMENT',
    DESELECT_ELEMENT: 'DESELECT_ELEMENT',
    SELECT_MOTION_POINT: 'SELECT_MOTION_POINT',
    DESELECT_MOTION_POINT: 'DESELECT_MOTION_POINT',
    SELECT_ALL_MOTION_POINTS: 'SELECT_ALL_MOTION_POINTS',
    SELECT_CELL: 'SELECT_CELL',
    TOGGLE_CELL_SELECTION: 'TOGGLE_CELL_SELECTION',
    // Now only use CELL is enough
    // SELECT_VERTEX: 'SELECT_VERTEX',
    // SELECT_SEGMENT: 'SELECT_SEGMENT',
    // SELECT_FACE: 'SELECT_FACE',
    DESELECT_CELL: 'DESELECT_CELL',
    DESELECT_KEYFRAME: 'DESELECT_KEYFRAME',
    DOUBLE_LEFT_CLICK: 'DOUBLE_LEFT_CLICK',
    PLAY_ANIMATION: 'PLAY_ANIMATION',
    STOP_ANIMATION: 'STOP_ANIMATION',
    RESET_ANIMATION: 'RESET_ANIMATION',
    EXPAND_TEXT_SELECTION: 'EXPAND_TEXT_SELECTION',
    CHANGE_TEXT_CARET: 'CHANGE_TEXT_CARET',
    MOVE_TEXT_CARET_KEY: 'MOVE_TEXT_CARET_KEY',
    MOVE_TEXT_CARET_TO_NEXT_WORD_KEY: 'MOVE_TEXT_CARET_TO_NEXT_WORD_KEY',
    EXPAND_TEXT_SELECTION_KEY: 'EXPAND_TEXT_SELECTION_KEY',
    EXPAND_TEXT_SELECTION_TO_NEXT_WORD_KEY: 'EXPAND_TEXT_SELECTION_TO_NEXT_WORD_KEY',
    MOVE_VIEWPORT_KEY: 'MOVE_VIEWPORT_KEY',
    MOVE_MOTION_POINTS_SELECTION_KEY: 'MOVE_MOTION_POINTS_SELECTION_KEY',
    MOVE_ELEMENT_SELECTION_KEY: 'MOVE_ELEMENT_SELECTION_KEY',
    MOVE_CELL_SELECTION_KEY: 'MOVE_CELL_SELECTION_KEY',
    MOVE_GRADIENT_HANDLE_KEY: 'MOVE_GRADIENT_HANDLE_KEY',
    DELETE_GRADIENT_STOP: 'DELETE_GRADIENT_STOP',
    ADD_NEW_GRADIENT_STOP: 'ADD_NEW_GRADIENT_STOP',
    OPEN_MODAL: 'OPEN_MODAL',
    OPEN_ELEMENT_CONTEXT_MENU: 'OPEN_ELEMENT_CONTEXT_MENU',
    OPEN_CANVAS_CONTEXT_MENU: 'OPEN_CANVAS_CONTEXT_MENU',
    CLOSE_MODAL: 'CLOSE_MODAL',
    DELETE_MOTION_POINT: 'DELETE_MOTION_POINT',
    DELETE_LAYER: 'DELETE_LAYER',
    DELETE_ELEMENT: 'DELETE_ELEMENT',
    DELETE_KEYFRAME: 'DELETE_KEYFRAME',
    DELETE_TEXT: 'DELETE_TEXT',
    DELETE_CELL: 'DELETE_CELL',
    ZOOM_IN: 'ZOOM_IN',
    ZOOM_OUT: 'ZOOM_OUT',
    ZOOM_RESET: 'ZOOM_RESET',
    ZOOM_FIT_CONTENT: 'ZOOM_FIT_CONTENT',
    ZOOM_FIT_SELECTION: 'ZOOM_FIT_SELECTION',
    ZOOM_CENTER_SELECTION: 'ZOOM_CENTER_SELECTION',
    ZOOM_TO_VALUE: 'ZOOM_TO_VALUE',
    ZOOM_TO_POINTER: 'ZOOM_TO_POINTER',
    MAC_ZOOM_TO_POINTER: 'MAC_ZOOM_TO_POINTER',
    GROUP_ELEMENTS: 'GROUP_ELEMENTS',
    BOOLEAN_GROUP_ELEMENTS: 'BOOLEAN_GROUP_ELEMENTS',
    MASK_GROUP_ELEMENTS: 'MASK_GROUP_ELEMENTS',
    UNGROUP_ELEMENTS: 'UNGROUP_ELEMENTS',
    INCREASE_CORNER_RADIUS: 'INCREASE_CORNER_RADIUS',
    DECREASE_CORNER_RADIUS: 'DECREASE_CORNER_RADIUS',
    SELECT_ALL_ELEMENTS: 'SELECT_ALL_ELEMENTS',
    SELECT_ALL_CELLS: 'SELECT_ALL_CELLS',
    SELECT_ALL_TEXT: 'SELECT_ALL_TEXT',
    UNDO: 'UNDO',
    REDO: 'REDO',
    COPY: 'COPY',
    CUT: 'CUT',
    PASTE: 'PASTE',
    DUPLICATE_ELEMENT: 'DUPLICATE_ELEMENT',
    DUPLICATE_CELL: 'DUPLICATE_CELL',
    DUPLICATE_KEYFRAME: 'DUPLICATE_KEYFRAME',
    SELECT_FIRST_ELEMENT: 'SELECT_FIRST_ELEMENT',
    SELECT_PREVIOUS_ELEMENT: 'SELECT_PREVIOUS_ELEMENT',
    SELECT_NEXT_ELEMENT: 'SELECT_NEXT_ELEMENT',
    SELECT_PARENT_ELEMENT: 'SELECT_PARENT_ELEMENT',
    SELECT_CHILD_ELEMENT: 'SELECT_CHILD_ELEMENT',
    MOVE_TEXT_CARET_TO_LINE_START: 'MOVE_TEXT_CARET_TO_LINE_START',
    MOVE_TEXT_CARET_TO_TEXT_START: 'MOVE_TEXT_CARET_TO_TEXT_START',
    EXPAND_TEXT_SELECTION_TO_LINE_START: 'EXPAND_TEXT_SELECTION_TO_LINE_START',
    EXPAND_TEXT_SELECTION_TO_TEXT_START: 'EXPAND_TEXT_SELECTION_TO_TEXT_START',
    TEXT_INSERT_NEW_LINE: 'TEXT_INSERT_NEW_LINE',
    TOGGLE_EXPAND: 'TOGGLE_EXPAND',
    MOVE_VIEWPORT_WHEEL: 'MOVE_VIEWPORT_WHEEL',
    SWITCH_BEND_TOOL: 'SWITCH_BEND_TOOL',
    SHOW_NOTIFICATION: 'SHOW_NOTIFICATION',
    SHOW_CONFIRMATION: 'SHOW_CONFIRMATION',
    EXIT_EDITOR: 'EXIT_EDITOR',
    EXPORT_MEDIA: 'EXPORT_MEDIA',
    EXPORT_PROGRESS: 'EXPORT_PROGRESS',
    EXPORT_FINISH: 'EXPORT_FINISH',
    CANCEL_EXPORT_MEDIA: 'CANCEL_EXPORT_MEDIA',
    DOWNLOAD_FILE: 'DOWNLOAD_FILE',
    ALIGN: 'ALIGN',
    DISTRIBUTE: 'DISTRIBUTE',
    TOGGLE_ORIGIN: 'TOGGLE_ORIGIN',
    TOGGLE_RULER: 'TOGGLE_RULER',
    TOGGLE_INTERFACE: 'TOGGLE_INTERFACE',
    TOGGLE_COMMENT_VISIBILITY: 'TOGGLE_COMMENT_VISIBILITY',
    TOGGLE_SNAP_TO_PIXEL_GRID: 'TOGGLE_SNAP_TO_PIXEL_GRID',
    TOGGLE_SNAP_TO_OBJECT: 'TOGGLE_SNAP_TO_OBJECT',
    TOGGLE_VISIBLE: 'TOGGLE_VISIBLE',
    TOGGLE_LOCK: 'TOGGLE_LOCK',
    BRING_TO_FRONT: 'BRING_TO_FRONT',
    SEND_TO_BACK: 'SEND_TO_BACK',
    MOVE_FORWARD: 'MOVE_FORWARD',
    MOVE_BACKWARD: 'MOVE_BACKWARD',
    TOGGLE_PRESENCE: 'TOGGLE_PRESENCE',
    LEAVE_VERSION_PREVIEW: 'LEAVE_VERSION_PREVIEW',
    EDIT_ORIGIN: 'EDIT_ORIGIN',
    TOGGLE_INSPECTING: 'TOGGLE_INSPECTING'
}

export const EditModeEventNameMap = {
    [EditMode.SHAPE]: 'SHAPE_MODE',
    [EditMode.TEXT]: 'TEXT_MODE',
    [EditMode.GRADIENT_HANDLES]: 'GRADIENT_HANDLES_MODE'
}

export const ActiveToolEventNameMap = {
    [ToolType.SELECT]: 'SELECT_TOOL',
    [ToolType.SCALE]: 'SCALE_TOOL',
    [ToolType.HAND]: 'HAND_TOOL',
    [ToolType.RECTANGLE]: 'RECTANGLE_TOOL',
    [ToolType.ELLIPSE]: 'ELLIPSE_TOOL',
    [ToolType.POLYGON]: 'POLYGON_TOOL',
    [ToolType.STAR]: 'STAR_TOOL',
    [ToolType.LINE]: 'LINE_TOOL',
    [ToolType.ARROW]: 'ARROW_TOOL',
    [ToolType.CONTAINER]: 'CONTAINER_TOOL',
    [ToolType.PEN]: 'PEN_TOOL',
    [ToolType.UNION]: 'UNION_TOOL',
    [ToolType.SUBTRACT]: 'SUBTRACT_TOOL',
    [ToolType.INTERSECT]: 'INTERSECT_TOOL',
    [ToolType.EXCLUDE]: 'EXCLUDE_TOOL',
    [ToolType.EYE_DROPPER]: 'EYE_DROPPER_TOOL',
    [ToolType.COMMENT]: 'COMMENT_TOOL'
}

export const AllowedEventsInViewingState = new Set([
    Events.OPEN_MODAL,
    Events.CLOSE_MODAL,
    Events.ZOOM_IN,
    Events.ZOOM_OUT,
    Events.ZOOM_RESET,
    Events.ZOOM_FIT_CONTENT,
    Events.ZOOM_TO_VALUE,
    Events.PLAY_ANIMATION,
    Events.STOP_ANIMATION,
    Events.RESET_ANIMATION,
    Events.SWITCH_MODE,
    Events.ACTIVATE_HAND_TOOL,
    Events.ACTIVATE_COMMENT_TOOL,
    Events.ACTIVATE_PAN,
    Events.START_PANNING,
    Events.UPDATE_PANNING,
    Events.END_PANNING,
    Events.MOVE_VIEWPORT_WHEEL,
    Events.MAC_ZOOM_TO_POINTER,
    Events.EXPORT_MEDIA,
    Events.EXPORT_FINISH,
    Events.EXPORT_PROGRESS,
    Events.CANCEL_EXPORT_MEDIA,
    Events.TOGGLE_COMMENT_VISIBILITY,
    Events.TOGGLE_PRESENCE,
    Events.LEAVE_VERSION_PREVIEW,
    Events.TOGGLE_INTERFACE,
    Events.TOGGLE_INSPECTING
]);

export const AllowedEventsInInspectingingState = new Set([
    Events.OPEN_MODAL,
    Events.CLOSE_MODAL,
    Events.ZOOM_IN,
    Events.ZOOM_OUT,
    Events.ZOOM_RESET,
    Events.ZOOM_FIT_CONTENT,
    Events.ZOOM_TO_VALUE,
    Events.PLAY_ANIMATION,
    Events.STOP_ANIMATION,
    Events.RESET_ANIMATION,
    Events.SWITCH_MODE,
    Events.HOVER_ELEMENT,
    Events.ACTIVATE_HAND_TOOL,
    Events.ACTIVATE_COMMENT_TOOL,
    Events.ACTIVATE_PAN,
    Events.DEACTIVATE_PAN,
    Events.START_PANNING,
    Events.UPDATE_PANNING,
    Events.END_PANNING,
    Events.MOVE_VIEWPORT_WHEEL,
    Events.ZOOM_TO_POINTER,
    Events.MAC_ZOOM_TO_POINTER,
    Events.EXPORT_MEDIA,
    Events.EXPORT_FINISH,
    Events.EXPORT_PROGRESS,
    Events.CANCEL_EXPORT_MEDIA,
    Events.TOGGLE_COMMENT_VISIBILITY,
    Events.TOGGLE_PRESENCE,
    Events.LEAVE_VERSION_PREVIEW,
    Events.TOGGLE_INTERFACE,
    Events.TOGGLE_INSPECTING,
    Events.START_DRAG_ELEMENT,
    Events.END_DRAG_ELEMENT,
    Events.SELECT_NEXT_ELEMENT,
    Events.SELECT_PREVIOUS_ELEMENT,
    Events.SELECT_PARENT_ELEMENT,
    Events.TOGGLE_EXPAND,
    Events.DESELECT_ELEMENT,
    Events.TOGGLE_RULER
]);
