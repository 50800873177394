import { Bezier } from 'bezier-js/src/bezier'

export class Track {
  constructor(prop, { delta = false, allowTimeZero = false } = {}) {
    const base = prop.value * Number(delta)
    let interval = {
      startTime: 0,
      start: prop.value,
      end: prop.value,
      easing: [0, 0, 1, 1],
      step: false,
      inTangent: undefined,
      outTangent: undefined,
    }

    this.intervalMap = new Map()
    this.animated = false
    prop.keyFrames.forEach(kf => {
      this.animated = true
      const value = delta ? base + kf.value : kf.value
      if (kf.time === 0) {
        interval = {
          startTime: kf.time,
          start: prop.value,
          end: value,
          easing: kf.easing,
          step: kf.step,
          inTangent: kf.inTangent,
          outTangent: kf.outTangent,
          endTime: kf.time
        }
        if (allowTimeZero) {
            this.intervalMap.set(kf.time, interval)
            interval = {...interval}
        }
      } else {
        interval.end = value
        interval.endTime = kf.time
        interval.easing = kf.easing
        interval.step = kf.step
        interval.inTangent = kf.inTangent
        this.intervalMap.set(kf.time, interval)
        interval = {
          startTime: kf.time,
          start: value,
          end: value,
          easing: kf.easing,
          step: kf.step,
          outTangent: kf.outTangent,
          inTangent: kf.inTangent,
        }
      }
    })
    if (this.intervalMap.size === 0) {
      interval.endTime = 0
      this.intervalMap.set(0, interval)
    }
  }

  get times() {
    return Array.from(this.intervalMap.keys())
  }

  hasTime(time) {
    return this.intervalMap.has(time)
  }

  getValueAtTime(time) {
    const interval = this.getInterval(time)
    if (interval.step) {
      if (time === interval.endTime) {
        return interval.end
      } else {
        return interval.start
      }
    } else {
      if (time >= interval.endTime) {
        return interval.end
      } else if (time === interval.startTime) {
        return interval.start
      } else {
        const bezier = this.getBezier(interval.easing)
        const tNormalized = (time - interval.startTime) / (interval.endTime - interval.startTime)
        const point = bezier.get(tNormalized)
        const value = interval.start + (interval.end - interval.start) * point.y
        return value
      }
    }
  }

  getInterval(time) {
    const times = this.times
    if (time <= times[0]) {
      return this.intervalMap.get(times[0])
    }
    for (let i = 1; i < times.length; i++) {
      if (time <= times[i]) {
        return this.intervalMap.get(times[i])
      }
    }
    return this.intervalMap.get(times[times.length - 1])
  }

  getBezier(easing) {
    return new Bezier([0, 0, ...easing, 1, 1])
  }
}
