import { useCallback } from 'react'
import { createProvider } from './utils'
import { TIMELINE_ADJUST_HEIGHT, ELEMENT_PANEL_ADJUST_WIDTH } from '../constant'

const defaultValue = {
  actionHeight: TIMELINE_ADJUST_HEIGHT,
  contentWidth: ELEMENT_PANEL_ADJUST_WIDTH
}

const [Provider, useSelectState, useSetState] = createProvider('Layout', defaultValue)

export const useLayout = useSelectState

export const useSetLayout = () => {
  const setState = useSetState()
  const setActionHeight = useCallback(
    (actionHeight) => {
      setState((s) => ({
        ...s,
        actionHeight
      }))
    },
    [setState]
  )

  const setContentWidth = useCallback(
    (contentWidth) => {
      setState((s) => ({
        ...s,
        contentWidth
      }))
    },
    [setState]
  )
  return { setActionHeight, setContentWidth }
}

export default Provider
