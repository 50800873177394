import PropertyStack from '../../PropertyStack'

class EndStack extends PropertyStack {
  constructor(elementStack, data) {
    super(elementStack, data)
    this.type = 'END'
    this.key = 'end'
    this.dataKey = 'end'
    this.animatableProperties = new Set(['end'])
  }
}

export default EndStack
