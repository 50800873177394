import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState, useEffect } from 'react'
import { ToolType } from '@phase-software/types'
import CanvasCommentThreadWithTag from './CanvasCommentThreadWithTag'
import { useCommentContext } from '../../providers/CommentProvider'
import { useSetModal } from '../../providers/ModalProvider'
import { CommentType, FilterKeys, Position } from '../shared/CommentThread/types'
import { useSelf, useThreads } from '../../liveblocks.config'
import CanvasCreateComment from './CanvasCreateComment'
import { useTool } from '../../providers/dataStore/ToolProvider'
import { useDataStore } from '../../providers/dataStore/DataStoreProvider'
import useCommentLoader from './useCommentLoader'
import { useUI } from '../../providers/dataStore/UIProvider'
// @ts-ignore
import { toWorld } from '@phase-software/renderer'
import { parseMetadata } from './utils'

export type CanvasCommentWrapperRef = {
  handleCanvasClick: (e: React.MouseEvent, canvasRect: DOMRect) => void
}

const CanvasCommentWrapper = forwardRef<CanvasCommentWrapperRef>((_, forwardRef) => {
  const ref = useRef<HTMLDivElement>(null)
  const MODAL_KEY = 'CommentModal'
  const [mouseUpPos, setMouseUpPos] = useState<Position>({ x: 0, y: 0 })
  const dataStore = useDataStore()
  const { isVersioningState } = useUI()
  const { activeTool } = useTool()
  const {
    setHasUnread,
    filter,
    selectedThread,
    clearSelectedThread,
    openCanvasCreateComment,
    closeCanvasCreateComment,
    isCanvasCreateCommentOpen,
    isCommentVisible
  } = useCommentContext()
  const { id: currentUserId = '' } = useSelf()
  const { threads, error, isLoading } = useThreads()
  const { closeModal, openModal } = useSetModal(MODAL_KEY)
  useCommentLoader()

  useImperativeHandle(forwardRef, () => ({
    handleCanvasClick
  }))

  const closeCanvasExpandedCommentThread = useCallback(() => {
    if (!selectedThread.id) return
    if (selectedThread.type === CommentType.CANVAS) {
      clearSelectedThread()
    }
  }, [selectedThread, clearSelectedThread])

  const handleCanvasClick = useCallback(
    (e: React.MouseEvent, canvasRect: DOMRect) => {
      closeCanvasExpandedCommentThread()
      if (activeTool === ToolType.COMMENT) {
        const { left: canvasContainerLeft, top: canvasContainerTop } = canvasRect
        const mouseUpPos = toWorld(e.clientX - canvasContainerLeft, e.clientY - canvasContainerTop)
        closeCanvasCreateComment()
        setMouseUpPos(mouseUpPos)
        requestAnimationFrame(() => {
          setTimeout(() => {
            openCanvasCreateComment()
          }, 0)
        })
      } else {
        closeCanvasCreateComment()
      }
    },
    [activeTool, closeCanvasExpandedCommentThread, openCanvasCreateComment, closeCanvasCreateComment]
  )

  const handleTagDrag = (pos: Position): void => {
    setMouseUpPos(pos)
  }

  useEffect(() => {
    if ((selectedThread.id && selectedThread.type === CommentType.CANVAS) || isCanvasCreateCommentOpen) {
      openModal()
    } else {
      closeModal()
    }
  }, [selectedThread, isCanvasCreateCommentOpen, openModal, closeModal])

  useEffect(() => {
    const updatePosition = () => {
      if (ref.current && dataStore?.drawInfo?.vs) {
        ref.current.style.transform = `translate(${dataStore.drawInfo.vs.viewport.x}px, ${dataStore.drawInfo.vs.viewport.y}px)`
      }
    }

    if (dataStore) {
      updatePosition()

      dataStore.workspace.ons('scale', 'panX', 'panY', updatePosition)
    }

    return () => {
      if (dataStore) {
        dataStore.workspace.offs('scale', 'panX', 'panY', updatePosition)
      }
    }
  }, [dataStore])

  useEffect(() => {
    if (isLoading || error || !threads) return setHasUnread(false)
    setHasUnread(
      threads.some((thread) => {
        const metadata = parseMetadata(thread.metadata)
        return !metadata.isDeleted && !metadata.usersReadStatus[currentUserId]
      })
    )
  }, [threads, isLoading, error, currentUserId, setHasUnread])

  useEffect(() => {
    if (isVersioningState) clearSelectedThread()
  }, [isVersioningState, clearSelectedThread])

  if (error || isLoading) return null

  return (
    <div id="canvas-comment-wrapper" className="absolute will-change-transform" ref={ref}>
      {isCommentVisible &&
        !isVersioningState &&
        threads.map((thread) => {
          if (thread.metadata.type === CommentType.PANEL) return

          if (thread.metadata.isDeleted) return

          if (thread.metadata.resolved && !filter[FilterKeys.SHOW_RESOLVED]) return

          return <CanvasCommentThreadWithTag key={thread.id} thread={thread} />
        })}
      <CanvasCreateComment position={mouseUpPos} handleTagDrag={handleTagDrag} />
    </div>
  )
})

CanvasCommentWrapper.displayName = 'CanvasCommentWrapper'

export default CanvasCommentWrapper
