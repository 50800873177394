import { useCallback } from 'react'
import { createProvider } from './utils'

const defaultValue = null
const [Provider, useSelectState, useSetState, getStateSnapshot] = createProvider('Confirmation', defaultValue)

export const useConfirmation = useSelectState
export const getConfirmationSnapshot = getStateSnapshot

export const useSetConfirmation = () => {
  const setState = useSetState()
  const showConfirmation = useCallback(
    ({ subject, content, onConfirm, onCancel, confirmText, cancelText }) => {
      setState({
        subject,
        content,
        onConfirm,
        onCancel,
        confirmText,
        cancelText
      })
    },
    [setState]
  )
  const closeConfirmation = useCallback(() => {
    setState(null)
  }, [setState])

  return { showConfirmation, closeConfirmation }
}

export default Provider
