import React, { forwardRef } from 'react'

import FlatButton from './FlatButton'
import GhostButton from './GhostButton'
import SolidButton from './SolidButton'
import WhiteHighlightButton from './WhiteHighlightButton'

type ButtonVariants = 'solid' | 'ghost' | 'flat'

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: string
  type?: 'button' | 'submit'
  size?: 's' | 'l' | 'xl'
  fluid?: boolean
  className?: string
  children?: React.ReactNode
  disabled?: boolean
  variant?: ButtonVariants
  color?: 'primary' | 'secondary' | 'danger'
  autoFocus?: boolean
  fixedWidth?: number
}

const buttonStyleMap: Record<ButtonVariants, any> = {
  solid: SolidButton,
  ghost: GhostButton,
  flat: FlatButton
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(({ children, variant = 'solid', ...rest }, ref) => {
  const StyledButton = buttonStyleMap[variant]
  return (
    <StyledButton ref={ref} {...rest}>
      {children}
    </StyledButton>
  )
})

Button.displayName = 'Button'

export default Button
export { WhiteHighlightButton }
