import { MIX_VALUE_ID, MIX_VALUE, IS_MIX, id } from '@phase-software/data-utils'
import { EI_EFFECT_ALLOWED_KEYS_SET } from '../constant'

export class EffectItem {
    constructor(computedEffect, index) {
        this._data = {}
        if (computedEffect === MIX_VALUE) {
            this.id = MIX_VALUE_ID
            this.isMix = true
            return
        }
        this.effectType = computedEffect.get('effectType')
        for (const prop of EI_EFFECT_ALLOWED_KEYS_SET[this.effectType]) {
            if (prop === IS_MIX) {
                this.isMix = false
                continue
            }
            this._data[prop] = computedEffect.get(prop)
        }
        this.id = id()
        this.computedEffectSet = new Set([computedEffect])
        this.index = index
        this.generateHash()
    }

    generateHash() {
        // TODO: Better way to generate hash?
        this._data.index = this.index
        this._data.isMix = this.isMix
        this.hash = JSON.stringify(this._data)
    }
}
