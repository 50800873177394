import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useApolloClient } from '@apollo/client'
import { LoadingStatus } from '@phase-software/types'

import {
  FileFieldsFragment,
  FileFieldsFragmentDoc,
  GetVersionsDocument,
  ProjectFieldsFragment,
  VersionFieldsFragment
} from '../../generated/graphql'
import useFileActions from '../../hooks/useFileActions'
import { fileNameValidator } from '../../utils/validator'
import { Dialog, InputField } from '../shared'
import { InputFieldRefProps } from '../shared/InputField'

type RenameFileDialogProps = {
  projectId: ProjectFieldsFragment['id']
  id: FileFieldsFragment['id']
  originalName: FileFieldsFragment['name']
  open: boolean
  onClose: () => void
}

const RenameFileDialog = ({ projectId, id, originalName, open, onClose }: RenameFileDialogProps) => {
  const client = useApolloClient()

  const { t } = useTranslation(['workspace', 'common'])
  const { updateFileName } = useFileActions()

  const inputRef = useRef<InputFieldRefProps>(null)
  const [name, setName] = useState(originalName ?? '')
  const [isRenaming, setIsRenaming] = useState(false)

  const isRenameButtonDisabled = isRenaming || !!fileNameValidator(name || '', t)

  useEffect(() => {
    if (open && inputRef.current) {
      inputRef.current.focus()
      inputRef.current.select()
    }
  }, [open])

  useEffect(() => {
    setName(originalName ?? '')
  }, [originalName])

  const handleChangeName = (newName: string) => {
    setName(newName)
  }

  const handleRename = async () => {
    if (isRenameButtonDisabled) return
    setIsRenaming(true)
    try {
      await updateFileName({ projectId, fileId: id, name })

      client.cache.updateFragment(
        {
          id: `files:${id}`,
          fragment: FileFieldsFragmentDoc,
          fragmentName: 'fileFields'
        },
        (data) => ({ ...data, name })
      )

      client.cache.updateQuery({ query: GetVersionsDocument, variables: { fileId: id } }, (data) => {
        if (data === null) return null
        return {
          ...data,
          versions: data.versions.map((version: VersionFieldsFragment) => ({
            ...version,
            parent_file: {
              ...version.parent_file,
              name
            }
          }))
        }
      })

      onClose()
    } catch (error) {
      console.error(error)
    } finally {
      setIsRenaming(false)
    }
  }

  const handleClose = () => {
    setName(originalName ?? '')
    onClose()
  }

  return (
    <Dialog
      data-test-id="rename-dialog"
      size="xs"
      title={t('rename_file')}
      showProgressButton
      progressStatus={isRenaming ? LoadingStatus.WAITING : LoadingStatus.INITIAL}
      confirmBtnText={t('common:button.rename')}
      cancelBtnText={t('common:button.cancel')}
      onConfirm={handleRename}
      onCancel={handleClose}
      disableConfirm={isRenameButtonDisabled}
      open={open}
    >
      <InputField
        ref={inputRef}
        type="text"
        value={name}
        placeholder={t('file_name')}
        validator={(value) => fileNameValidator(value, t)}
        onInput={handleChangeName}
        onEnterKey={handleRename}
        labelClassName="mb-0"
      />
    </Dialog>
  )
}

export default RenameFileDialog
