import React from 'react'
import { useTranslation } from 'react-i18next'

import { Dialog } from '../shared'

type DeleteCommentDialogProps = {
  open: boolean
  isThread?: boolean
  confirmDelete: () => void
  toggleDialog: (open: boolean) => void
}

const deleteCommentContent = {
  title: 'file:commenting.delete_comment',
  description: 'file:commenting.delete_comment_confirmation'
}

const deleteThreadContent = {
  title: 'file:commenting.delete_thread',
  description: 'file:commenting.delete_thread_confirmation'
}

const DeleteCommentDialog = ({ open, isThread = false, confirmDelete, toggleDialog }: DeleteCommentDialogProps) => {
  const { t } = useTranslation(['common', 'file'])
  const { title, description } = isThread ? deleteThreadContent : deleteCommentContent
  const onClose = () => toggleDialog(false)

  return (
    <Dialog
      size="xs"
      title={t(title)}
      dangerConfirmBtnText={t('common:button.delete')}
      cancelBtnText={t('common:button.cancel')}
      onDangerConfirm={confirmDelete}
      onCancel={onClose}
      open={open}
    >
      <p>{t(description)}</p>
    </Dialog>
  )
}

export default DeleteCommentDialog
