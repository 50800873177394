import React, { KeyboardEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { WorkspaceType } from '@phase-software/types'
import { useVirtualizer } from '@tanstack/react-virtual'

import { useAccessControl } from '../../access-control'
import CreateTeamDialog from '../../components/modals/CreateTeamDialog'
import RemoveTeamMemberDialog from '../../components/modals/RemoveTeamMemberDialog'
import { Icon, Popover, ScrollView, Tooltip } from '../../components/shared'
import { ARROW_DOWN, ARROW_UP, INITIAL_ACTIVE_INDEX } from '../../components/shared/Menu/Menu.constants'
import { ArrowDirection } from '../../components/shared/Menu/Menu.types'
import MenuOption, { MenuOptionProps } from '../../components/shared/Menu/MenuOption'
import { calculateNewActiveIndex } from '../../components/shared/Menu/utils'
import { MY_WORKSPACE } from '../../constant'
import { ADD_NEW_TEAM_OPTION, TEAM_OPTIONS, WORKSPACE_POPOVER_OPTIONS } from '../../constants/workspacePopoverConstants'
import { useGetTeamUsersQuery } from '../../generated/graphql'
import { WorkspaceData, useWorkspaceContext } from '../../providers/WorkspaceContextProvider'
import { scrollIntoView } from '../../utils/dom'
import { getWorkspaceIndexPath } from '../../utils/pathGenerators'
import { useWorkSpacePopover } from './useWorkSpacePopover'

export type WorkspacePopoverProps = {
  triggerRef: React.RefObject<HTMLDivElement>
  open: boolean
  onClose: () => void
  list?: MenuOptionProps[]
  currentWorkspaceType: WorkspaceData['type']
  currentWorkspaceSlug: WorkspaceData['slug']
}

const WorkspacePopover = ({
  triggerRef,
  open,
  onClose,
  list,
  currentWorkspaceType,
  currentWorkspaceSlug
}: WorkspacePopoverProps) => {
  const { t } = useTranslation('workspace')
  const { workspaceData, personalWorkspaceData } = useWorkspaceContext()
  const { userHasPermission } = useAccessControl()

  const { data: teamUsers } = useGetTeamUsersQuery({
    variables: { slug: workspaceData.slug }
  })

  const listRef = useRef<HTMLDivElement>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)

  const [activeIndex, setActiveIndex] = useState(INITIAL_ACTIVE_INDEX)

  const showTeamOptions = currentWorkspaceType === WorkspaceType.TEAM

  const workspacePopoverOptions = useMemo(() => {
    const translateWorkspaceName = (name: string) => (name === MY_WORKSPACE ? t(MY_WORKSPACE) : name)

    const workspaceList =
      list?.map((item) => ({
        ...item,
        name: translateWorkspaceName(item.name ?? ''),
        description: t(item.description ?? ''),
        inScrollView: true
      })) ?? []

    const options = [
      ...workspaceList,
      { ...ADD_NEW_TEAM_OPTION, name: t(ADD_NEW_TEAM_OPTION.name ?? ''), inScrollView: true },
      ...TEAM_OPTIONS.filter((option) => {
        switch (option?.value) {
          case WORKSPACE_POPOVER_OPTIONS.TEAM_SETTINGS:
            return showTeamOptions && userHasPermission(option.permission)
          case WORKSPACE_POPOVER_OPTIONS.LEAVE_TEAM:
            return showTeamOptions
          default:
            return !option.permission || userHasPermission(option.permission)
        }
      }).map((option) => {
        if (option.value === WORKSPACE_POPOVER_OPTIONS.LEAVE_TEAM) {
          return {
            ...option,
            name: t('leave_team', { team_name: workspaceData.name }),
            disabled: workspaceData.isOwner
          }
        }
        return {
          ...option,
          name: t(option.name ?? '')
        }
      })
    ]
    return options
  }, [list, showTeamOptions, userHasPermission, workspaceData.isOwner, workspaceData.name, t])

  const {
    handleSelectWorkspace,
    handleClickOption,
    createTeamDialogOpen,
    setCreateTeamDialogOpen,
    leaveTeamDialogOpen,
    setLeaveTeamDialogOpen
  } = useWorkSpacePopover({ currentWorkspaceSlug, workspacePopoverOptions })

  const rowVirtualizer = useVirtualizer({
    // workspaceList?.length + 1 ADD_NEW_TEAM_OPTION
    count: list?.length ? list.length + 1 : 0,
    getScrollElement: () => listRef.current,
    estimateSize: () => 44
  })

  const closeLeaveTeamDialog = useCallback(() => {
    setLeaveTeamDialogOpen(false)
  }, [setLeaveTeamDialogOpen])

  const handleOptionMouseEnter = useCallback(
    (index: number) => {
      setActiveIndex(index)
    },
    [setActiveIndex]
  )

  const handlerHoverDisabled = () => {
    setActiveIndex(INITIAL_ACTIVE_INDEX)
  }

  const handleMouseLeave = () => {
    setActiveIndex(INITIAL_ACTIVE_INDEX)
  }

  // Should skip disabled first or last option
  function setActiveIndexForHomeEndKeys(workspacePopoverOptions: MenuOptionProps[], key: 'Home' | 'End') {
    let index = key === 'Home' ? 0 : workspacePopoverOptions.length - 1
    if (key === 'Home') {
      while (workspacePopoverOptions[index].disabled) {
        index++
        if (index >= workspacePopoverOptions.length) {
          break
        }
      }
      if (index >= workspacePopoverOptions.length) {
        index = INITIAL_ACTIVE_INDEX
      }
    } else if (key === 'End') {
      while (workspacePopoverOptions[index].disabled) {
        index--
        if (index < 0) {
          break
        }
      }
      if (index < 0) {
        index = INITIAL_ACTIVE_INDEX
      }
    }
    setActiveIndex(index)
  }

  const handleArrowKeySelection = (direction: ArrowDirection) => {
    const newActiveIndex = calculateNewActiveIndex(direction, activeIndex, workspacePopoverOptions)
    setActiveIndex(newActiveIndex)
    scrollIntoView(listRef?.current?.children[newActiveIndex])
  }

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation()
    switch (e.key) {
      // press Tab have top focus next, so can not preventDefault
      case 'Tab':
        onClose()
        break
      case 'Escape':
        onClose()
        e.preventDefault()
        break
      case 'Enter':
      case ' ': {
        const targetOption = workspacePopoverOptions[activeIndex].value
        if (targetOption) {
          handleClickOption(targetOption, e, activeIndex)
        }
        e.preventDefault()
        break
      }
      case 'Home':
        setActiveIndexForHomeEndKeys(workspacePopoverOptions, 'Home')
        scrollIntoView(listRef?.current?.children[0])
        e.preventDefault()
        break
      case 'End':
        setActiveIndexForHomeEndKeys(workspacePopoverOptions, 'End')
        e.preventDefault()
        break
      case 'ArrowUp':
        handleArrowKeySelection(ARROW_UP)
        e.preventDefault()
        break
      case 'ArrowDown':
        handleArrowKeySelection(ARROW_DOWN)
        e.preventDefault()
        break
    }
  }

  useEffect(() => {
    const { current } = wrapperRef || {}
    if (!open) {
      setActiveIndex(INITIAL_ACTIVE_INDEX)
    } else {
      current?.focus()
    }
  }, [open, wrapperRef])

  return (
    <Popover
      trigger={triggerRef}
      open={open}
      onClose={onClose}
      className="py-8 w-[232px]"
      dataTestId="workspace-popover"
    >
      <div ref={wrapperRef} onKeyDown={handleKeyDown} onMouseLeave={handleMouseLeave} tabIndex={0}>
        {/* @ts-ignore TODO: fix after refactor of ScrollView */}
        <ScrollView ref={listRef} className="overflow-auto max-h-[470px]">
          <div
            className="w-full relative"
            style={{
              height: `${rowVirtualizer.getTotalSize() - 4}px`
            }}
          >
            {rowVirtualizer.getVirtualItems().map(({ index, key, size, start }) => (
              <div
                key={key}
                className="absolute top-0 left-0 w-full"
                style={{
                  height: `${workspacePopoverOptions?.[index].name === ADD_NEW_TEAM_OPTION.name ? 40 : size}px`,
                  transform: `translateY(${start}px)`
                }}
              >
                {workspacePopoverOptions?.[index].value === ADD_NEW_TEAM_OPTION.value ? (
                  <MenuOption
                    name={workspacePopoverOptions?.[index].name}
                    showAvatar
                    selectable
                    selected={false}
                    onClick={() => setCreateTeamDialogOpen(true)}
                    icon="Plus"
                    optionData={workspacePopoverOptions?.[index]}
                    onMouseEnter={() => handleOptionMouseEnter(index)}
                  />
                ) : (
                  <MenuOption
                    name={workspacePopoverOptions?.[index].name}
                    value={workspacePopoverOptions?.[index].value}
                    avatarImage={workspacePopoverOptions?.[index].avatarImage}
                    description={workspacePopoverOptions?.[index].description}
                    showAvatar
                    selectable
                    selected={
                      getWorkspaceIndexPath(currentWorkspaceType, currentWorkspaceSlug) ===
                      workspacePopoverOptions?.[index].value
                    }
                    className="mb-4"
                    contentClassName="flex py-4 rounded-sm w-full"
                    onClick={(e) => handleSelectWorkspace(e, workspacePopoverOptions?.[index])}
                    onHoverDisabled={handlerHoverDisabled}
                    onMouseEnter={() => handleOptionMouseEnter(index)}
                    activatable
                    active={index === activeIndex}
                  />
                )}
              </div>
            ))}
          </div>
        </ScrollView>
        {!workspaceData.isPersonal && (
          <div
            className="w-full border-b border-solid border-light-overlay-10 my-8"
            data-test-id="workspace-option-divider"
          />
        )}
        {workspacePopoverOptions.map((option, index) => (
          <WorkspaceOption
            key={option.value}
            option={option}
            index={index}
            activeIndex={activeIndex}
            handleClickOption={handleClickOption}
            handleOptionMouseEnter={() => handleOptionMouseEnter(index)}
            workspaceData={workspaceData}
            handlerHoverDisabled={handlerHoverDisabled}
          />
        ))}
      </div>
      {createTeamDialogOpen && (
        <CreateTeamDialog open={createTeamDialogOpen} onClose={() => setCreateTeamDialogOpen(false)} />
      )}
      {leaveTeamDialogOpen && (
        <RemoveTeamMemberDialog
          open={leaveTeamDialogOpen}
          onClose={closeLeaveTeamDialog}
          onCancel={closeLeaveTeamDialog}
          targetUserId={personalWorkspaceData.id}
          teamUsers={teamUsers?.team_users ?? []}
        />
      )}
    </Popover>
  )
}

export default WorkspacePopover

export interface WorkspaceOptionProps {
  option: MenuOptionProps
  index: number
  activeIndex: number
  handleClickOption: (
    value: string | number | undefined,
    e?: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element> | undefined,
    index?: number | undefined
  ) => void
  handleOptionMouseEnter: (index: number) => void
  workspaceData: WorkspaceData
  handlerHoverDisabled: () => void
}

export const WorkspaceOption = ({
  option,
  index,
  activeIndex,
  handleClickOption,
  handleOptionMouseEnter,
  workspaceData,
  handlerHoverDisabled
}: WorkspaceOptionProps) => {
  const { t } = useTranslation('workspace')
  const { value, name, dataTestId } = option

  if (option.inScrollView) return null

  const handleClick = () => handleClickOption(value)

  if (value === WORKSPACE_POPOVER_OPTIONS.LEAVE_TEAM) {
    const rightComponent = workspaceData.isOwner ? (
      <Tooltip content={t('teams.leaving_team_tooltip')}>
        <Icon name="Question" className="ml-4 pointer-events-auto" interactive={false} />
      </Tooltip>
    ) : null

    return (
      <MenuOption
        key={value}
        name={name}
        value={value}
        selectable
        selected={false}
        disabled={option.disabled}
        onClick={handleClick}
        rightComponent={rightComponent}
        onHoverDisabled={handlerHoverDisabled}
        onMouseEnter={() => handleOptionMouseEnter(index)}
        activatable
        active={index === activeIndex}
        dataTestId={dataTestId || ''}
      />
    )
  }
  if (value === WORKSPACE_POPOVER_OPTIONS.SIGN_OUT || value === WORKSPACE_POPOVER_OPTIONS.TEAM_SETTINGS) {
    return (
      <MenuOption
        key={value}
        name={name}
        value={value}
        selectable
        selected={false}
        onClick={handleClick}
        onHoverDisabled={handlerHoverDisabled}
        onMouseEnter={() => handleOptionMouseEnter(index)}
        activatable
        active={index === activeIndex}
        dataTestId={dataTestId || ''}
      />
    )
  }
  return (
    <div key={value}>
      <div
        className="w-full border-b border-solid border-light-overlay-10 my-8"
        data-test-id="workspace-option-divider"
      />
      <MenuOption
        name={name}
        value={value}
        selectable
        selected={false}
        onClick={handleClick}
        onHoverDisabled={handlerHoverDisabled}
        onMouseEnter={() => handleOptionMouseEnter(index)}
        activatable
        active={index === activeIndex}
        dataTestId={dataTestId || ''}
      />
    </div>
  )
}
