import { jam } from '@jam.dev/sdk'

const _meta: Record<string, any> = {}

export const captureJamMeta = (key: string, val: any) => {
  _meta[key] = val
}

export const removeJamMeta = (key: string) => {
  delete _meta[key]
}

jam.metadata(() => {
  // lang can be changed dynamically, so we need to capture it on every metadata call
  const $html = document.querySelector('html')
  if ($html) {
    captureJamMeta('lang', $html.lang)
  }
  return _meta
})
