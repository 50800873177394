export type Permission = keyof typeof PERMISSIONS

export const PERMISSIONS = {
  // Team
  ACCESS_TEAM_GENERAL_PAGE: 'ACCESS_TEAM_GENERAL_PAGE',
  EDIT_TEAM_GENERAL: 'EDIT_TEAM_GENERAL',
  VIEW_TEAM_GENERAL: 'VIEW_TEAM_GENERAL',
  VIEW_TEAM_MEMBER: 'VIEW_TEAM_MEMBER',
  VIEW_TEAM_MEMBER_INVITATION: 'VIEW_TEAM_MEMBER_INVITATION',
  INVITE_TEAM_MEMBER: 'INVITE_TEAM_MEMBER',
  REMOVE_TEAM_MEMBER: 'REMOVE_TEAM_MEMBER',
  EDIT_TEAM_MEMBER_ROLE: 'EDIT_TEAM_MEMBER_ROLE',
  LEAVE_TEAM: 'LEAVE_TEAM',
  DELETE_TEAM: 'DELETE_TEAM',
  TRANSFER_TEAM_OWNERSHIP: 'TRANSFER_TEAM_OWNERSHIP',
  VIEW_TEMPLATE: 'VIEW_TEMPLATE',

  // Project
  CREATE_PROJECT: 'CREATE_PROJECT',
  RENAME_PROJECT: 'RENAME_PROJECT',
  DELETE_PROJECT: 'DELETE_PROJECT',
  SHARE_PROJECT: 'SHARE_PROJECT',
  MANAGE_PROJECT_COLLABORATOR: 'MANAGE_PROJECT_COLLABORATOR',
  CREATE_FILE: 'CREATE_FILE',

  // File
  VIEW_FILE: 'VIEW_FILE',
  EDIT_FILE: 'EDIT_FILE',
  RENAME_FILE: 'RENAME_FILE',
  DUPLICATE_FILE: 'DUPLICATE_FILE',
  MOVE_FILE: 'MOVE_FILE',
  DELETE_FILE: 'DELETE_FILE',
  ARCHIVE_FILE: 'ARCHIVE_FILE',
  MANAGE_FILE_COLLABORATOR: 'MANAGE_FILE_COLLABORATOR'
}
