import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useAccessControl } from './AccessControlProvider'
import { RouteConfig } from 'react-router-config'

type ProtectedRouteProps<Permission extends string> = {
  children: React.ReactNode
  data?: any
  perform: Permission
  unauthorizedUrl: string
} & RouteConfig

const ProtectedRoute = <Permission extends string>({
  children,
  data,
  perform,
  unauthorizedUrl,
  ...rest
}: ProtectedRouteProps<Permission>) => {
  const { userHasPermission } = useAccessControl()

  return (
    <Route {...rest} render={() => (userHasPermission(perform, data) ? children : <Redirect to={unauthorizedUrl} />)} />
  )
}

export default ProtectedRoute
