import React from 'react'
import { useTranslation } from 'react-i18next'

import { WideTitle } from '../../../shared'
import PropertyState from '../PropertyState'

type PathShapeEditorProps = {
  isHover: boolean
}

const PathShapeEditor = ({ isHover }: PathShapeEditorProps) => {
  const { t } = useTranslation('file', { keyPrefix: 'property_editor.path_shape_editor' })

  return (
    <div data-test-id="path-shape-editor">
      <WideTitle
        dividerPosition="top"
        rightComponent={<PropertyState name="pathMorphing" hoverWrap={isHover} rightComponent={<div />} />}
      >
        {t('title')}
      </WideTitle>
    </div>
  )
}

export default React.memo(PathShapeEditor)
