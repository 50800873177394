import { schema } from 'normalizr'
import { createProvider, useEntity } from '../utils'

export const Transition = new schema.Entity('transitions', {})

const defaultValue = {}
const [Provider, useSelectState, useSetState] = createProvider('Transition', defaultValue)

export const useTransition = useSelectState

export const useSetTransition = () => {
  const setState = useSetState()
  const [
    setTransition,
    addTransition,
    updateTransition,
    removeTransition,
    mergeTransition,
    reduceTransition,
    createTransition,
    destroyTransition
  ] = useEntity(Transition, setState)

  return {
    setTransition,
    addTransition,
    updateTransition,
    removeTransition,
    mergeTransition,
    reduceTransition,
    createTransition,
    destroyTransition
  }
}

export default Provider
