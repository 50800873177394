import { useCallback } from 'react'

export type UseDnDProps = {
  onStart?: (e: React.MouseEvent<HTMLElement>, meta: UseDnDMeta, setData: (data: any) => void) => void | boolean
  onUpdate?: (e: MouseEvent, meta: UseDnDMeta, setData: (data: any) => void) => void
  onEnd?: (e: MouseEvent, meta: UseDnDMeta) => void
  containerSelector?: string
}

export type UseDnDMeta = {
  container: HTMLElement | null
  target: HTMLElement
  sx: number
  sy: number
  dx: number
  dy: number
  dragged: boolean
  finished: boolean
  data: any
}

export const useDrag = ({ onStart, onUpdate, onEnd, containerSelector }: UseDnDProps) => {
  return useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const sx = e.clientX
      const sy = e.clientY
      const target = e.currentTarget
      const container = containerSelector ? (target.closest(containerSelector) as HTMLElement) : null

      let data: any
      let dragged = false
      let finished = false
      let clientX = 0
      let clientY = 0
      let shiftKey = false
      let dx = 0
      let dy = 0

      const setData = (newData: any) => {
        data = newData
      }

      if (onStart) {
        const activate = onStart(e, { target, sx, sy, dx, dy, dragged, data, finished, container }, setData)
        if (activate === false) {
          return
        }
      }

      const update = (e: MouseEvent) => {
        clientX = e.clientX
        clientY = e.clientY
        shiftKey = e.shiftKey
        dragged = true
        dx = e.clientX - sx
        dy = e.clientY - sy
        if (onUpdate) {
          onUpdate(e, { target, sx, sy, dx, dy, dragged, data, finished, container }, setData)
        }
      }
      window.addEventListener('mousemove', update)

      const scrollUpdate = (e: Event) => {
        if (container) {
          if (onUpdate) {
            onUpdate(
              { ...e, clientY, clientX, shiftKey } as MouseEvent,
              { target, sx, sy, dx, dy, dragged, data, finished, container },
              setData
            )
          }
        }
      }

      if (container) {
        container.addEventListener('scroll', scrollUpdate, { passive: true })
      }

      const finish = (e: MouseEvent) => {
        finished = true
        window.removeEventListener('mousemove', update)

        if (container) {
          container.removeEventListener('scroll', scrollUpdate)
        }

        // provide the final state and ensure any concluding logic based on the completed drag action is executed
        if (onUpdate) {
          onUpdate(e, { target, sx, sy, dx, dy, dragged, data, finished, container }, setData)
        }
        if (onEnd) {
          onEnd(e, { target, sx, sy, dx, dy, dragged, data, finished, container })
        }
      }
      window.addEventListener('mouseup', finish, { once: true })
    },
    [onStart, onUpdate, onEnd, containerSelector]
  )
}
