import { LayerType } from '@phase-software/types'
import LayerStack from '../LayerStack'

class InnerShadowStack extends LayerStack {
  constructor(elementStack, data) {
    super(elementStack, data)
    this.type = LayerType.INNER_SHADOW
    this.key = 'innerShadows'
    this.dataKey = 'innerShadows'
    this.animatableProperties = new Set([
      'opacity',
      'paint',
      'offsetX',
      'offsetY',
      'blur',
      'spread'
    ])
  }
}

export default InnerShadowStack
