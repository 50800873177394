import { vec2 } from 'gl-matrix'
import { isNull, notNull, isNum, vec2Equals, EPSILON } from './commons'

/**
 * Compatibility wrapper for vec2-like objects,
 * i.e objects that have `x`(`width`) and `y`('height') components
 */
export class Vector2 extends Float32Array {
    /**
     * Creates new Vector2 from 2 number components or copies values from a single vec2-like object
     * @param  {number | Vector2 | vec2 | object} x  x component or vec2-like object
     * @param  {number} y   y component
     */
    constructor(x, y) {
        super(2)
        if (typeof x === 'number' && typeof y === 'number') {
            vec2.set(this, x, y)
        } else {
            this.copy(x)
        }
    }

    /**
     * Copies values from the vec2-like object
     * @param  {Vector2 | vec2 | object} val
     * @returns {Vector2} self
     */
    copy(val) {
        if (isNull(val)) {
            return
        }
        let xx = notNull(val[0]) ? val[0] : val.x
        if (isNull(xx)) {
            xx = val.width
        }
        let yy = notNull(val[1]) ? val[1] : val.y
        if (isNull(yy)) {
            yy = val.height
        }
        if (isNum(xx) && isNum(yy)) {
            vec2.set(this, xx, yy)
        } else {
            throw new Error('Trying to copy NaN to Vector2')
        }
        return this
    }

    /**
     * Checks if this Vector2 is equal (has same corresponding component values) to another vec2-like object
     * @param {Vector2 | vec2 | object} val     vec2-like object
     * @param {number} [epsilon]                precision, default is 0.0001
     * @returns {boolean}                        true if vectors are equal; false othewise
     */
    eq(val, epsilon = EPSILON) {
        if (isNull(val)) {
            return false
        }
        let xx = notNull(val[0]) ? val[0] : val.x
        if (isNull(xx)) {
            xx = val.width
        }
        let yy = notNull(val[1]) ? val[1] : val.y
        if (isNull(yy)) {
            yy = val.height
        }
        return vec2Equals(this, [xx, yy], epsilon)
    }

    get x() {
        return this[0]
    }
    set x(val) {
        if (isNaN(val)) {
            throw new Error('Trying to assign NaN to x component of Vector2')
        }
        this[0] = val
    }

    get y() {
        return this[1]
    }
    set y(val) {
        if (isNaN(val)) {
            throw new Error('Trying to assign NaN to y component of Vector2')
        }
        this[1] = val
    }

    get width() {
        return this[0]
    }
    set width(val) {
        if (isNaN(val)) {
            throw new Error('Trying to assign NaN to width component of Vector2')
        }
        this[0] = val
    }

    get height() {
        return this[1]
    }
    set height(val) {
        if (isNaN(val)) {
            throw new Error('Trying to assign NaN to height component of Vector2')
        }
        this[1] = val
    }
}

Vector2.ZERO = new Vector2(0, 0)
Vector2.ONE = new Vector2(1, 1)
