import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { MIX_VALUE } from '@phase-software/data-utils'
import { GeometryType } from '@phase-software/types'

import { useEditor, useEditorActions } from '../../../../providers/dataStore/EditorProvider'
import { Dropdown, WideTitle } from '../../../shared'
import { MenuOptionProps } from '../../../shared/Menu/Menu.types'
import { translateMenuOptions } from '../../../shared/Menu/utils'

const PathTypeNameMap: Partial<Record<GeometryType, string>> = {
  [GeometryType.RECTANGLE]: 'rectangle',
  [GeometryType.ELLIPSE]: 'oval',
  [GeometryType.POLYGON]: 'path',
  [GeometryType.LINE]: 'path'
}

const convertToPathOptions: MenuOptionProps[] = [
  {
    name: 'convert_to_path',
    value: GeometryType.POLYGON
  }
]

const mixTypeName = 'mix'

const getElementTypeName = (geometryType: GeometryType): string => {
  let name = PathTypeNameMap[geometryType]
  if (geometryType === MIX_VALUE) {
    name = mixTypeName
  }

  return name || ''
}

const PathElementHeader = () => {
  const geometryType = useEditor((o) => o.geometryType)
  const { convertToPath } = useEditorActions()
  const { t } = useTranslation('file', { keyPrefix: 'path_type' })

  const elementTypeName = t(getElementTypeName(geometryType))
  const translatedConvertToPathOptions = useMemo(
    () => translateMenuOptions(t, convertToPathOptions, { ns: 'file', keyPrefix: 'property_editor' }),
    [t]
  )

  return (
    <WideTitle dividerPosition="top">
      {geometryType === GeometryType.POLYGON || geometryType === GeometryType.LINE ? (
        elementTypeName
      ) : (
        <Dropdown
          inline
          menu={{
            options: translatedConvertToPathOptions,
            onSelect: convertToPath
          }}
          dataTestId="editor-path-header"
          arrow
        >
          {elementTypeName}
        </Dropdown>
      )}
    </WideTitle>
  )
}

export default React.memo(PathElementHeader)
