import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { useApolloClient } from '@apollo/client'
import { debounce } from 'lodash'

import { Mode, ToolType } from '@phase-software/types'

import { VersionProps, VersionType } from '../../../../utils/transformVersion'
import { useDataStoreActions } from '../../../../providers/dataStore/DataStoreProvider'
import { useUIActions } from '../../../../providers/dataStore/UIProvider'
import { useToolActions } from '../../../../providers/dataStore/ToolProvider'
import useFileActions from '../../../../hooks/useFileActions'
import useFileVersionActions from '../../../../hooks/useFileVersionActions'
import { GetVersionByIdDocument } from '../../../../generated/graphql'
import { useTransitionManagerActions } from '../../../../providers/TransitionManagerProvider'

export const SPECIFIC_VERSION_PARAMETER_NAME = 'version-id'

export const INITIAL_VERSION_ID = null

type UseVersionLoaderProps = {
  stopLoadVersion: () => void
  startLoadVersion: (versionName: string) => void
  projectId: string
  fileId: string
  fileContent: object | null
  versionId: string | null
}

function useVersionLoader({
  fileContent,
  fileId,
  projectId,
  startLoadVersion,
  stopLoadVersion,
  versionId
}: UseVersionLoaderProps) {
  const history = useHistory()
  const location = useLocation()
  const client = useApolloClient()
  const { switchState, setPlayheadTime, clearUndo } = useDataStoreActions()
  const { resetAnimation } = useTransitionManagerActions()
  const { setMode } = useUIActions()
  const { setActiveTool } = useToolActions()
  const { downloadContent } = useFileActions()
  const { getVersionContent } = useFileVersionActions()

  const [currentVersionFileContent, setCurrentVersionFileContent] = useState(fileContent)
  const [currentVersionId, setCurrentVersionId] = useState<VersionProps['id'] | null>(versionId)

  useEffect(() => {
    if (fileContent) {
      setCurrentVersionFileContent(fileContent)
    }
  }, [fileContent])

  const addVersionIdToUrl = useCallback(
    (versionId: VersionProps['id']) => {
      const searchParams = new URLSearchParams(location.search)
      searchParams.set(SPECIFIC_VERSION_PARAMETER_NAME, versionId)
      history.replace({
        pathname: location.pathname,
        search: `?${searchParams.toString()}`
      })
    },
    [history, location.pathname, location.search]
  )

  const removeVersionIdFromUrl = useCallback(() => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.delete(SPECIFIC_VERSION_PARAMETER_NAME)

    history.replace({
      pathname: location.pathname,
      search: `?${searchParams.toString()}`
    })
  }, [history, location.pathname, location.search])

  const loadLatestVersion = useCallback(async () => {
    const latestContent = await downloadContent({ projectId, fileId })
    setCurrentVersionFileContent(latestContent)
    removeVersionIdFromUrl()
  }, [downloadContent, fileId, projectId, removeVersionIdFromUrl])

  const loadVersionById = useCallback(
    async (versionId: VersionProps['id']) => {
      const versionContent = await getVersionContent({ projectId, fileId, versionId })
      setCurrentVersionFileContent(versionContent)
      addVersionIdToUrl(versionId)
    },
    [addVersionIdToUrl, fileId, getVersionContent, projectId]
  )

  const loadVersion = useCallback(
    async (version: VersionProps) => {
      try {
        startLoadVersion(version.displayName)
        setCurrentVersionId(version.id)
        setPlayheadTime(0)
        resetAnimation()
        if (version.type === VersionType.LATEST) {
          await loadLatestVersion()
        } else {
          await loadVersionById(version.id)
        }
      } catch (error) {
        console.error(error)
      } finally {
        stopLoadVersion()
      }
    },
    [loadLatestVersion, loadVersionById, resetAnimation, setPlayheadTime, startLoadVersion, stopLoadVersion]
  )

  const debouncedLoadVersion = useMemo(() => debounce(loadVersion, 500), [loadVersion])

  const initializeWithVersion = useCallback(
    async (versionId: string) => {
      switchState('VERSIONING')
      setMode(Mode.ACTION)
      clearUndo()

      const { data } = await client.query({
        query: GetVersionByIdDocument,
        variables: { id: versionId },
        fetchPolicy: 'network-only'
      })
      if (data.versions_by_pk === null) {
        history.push('/404')
        return
      }
      setCurrentVersionId(versionId)
      loadVersionById(versionId)
    },
    [clearUndo, client, history, loadVersionById, setMode, switchState]
  )

  const initializeWithoutVersion = useCallback(
    (canEditFile: boolean) => {
      switchState(canEditFile ? 'EDITING' : 'VIEWING')
      setMode(canEditFile ? Mode.DESIGN : Mode.ACTION)
      setActiveTool(canEditFile ? ToolType.SELECT : ToolType.HAND)
    },
    [setActiveTool, setMode, switchState]
  )

  return {
    currentVersionFileContent,
    currentVersionId,
    debouncedLoadVersion,
    loadLatestVersion,
    loadVersionById,
    initializeWithVersion,
    initializeWithoutVersion,
    setCurrentVersionId
  }
}

export default useVersionLoader
