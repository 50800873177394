import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { BooleanOperation, EditMode, ElementType } from '@phase-software/types'

import { useElement } from '../../providers/dataStore/ElementProvider'
import { useElementSelection } from '../../providers/dataStore/ElementSelectionProvider'
import { useBooleanGroup } from '../../providers/dataStore/ToolProvider'
import { useUI } from '../../providers/dataStore/UIProvider'
import { translateMenuOptions } from '../shared/Menu/utils'
import ToolButton from '../shared/ToolButton'

const options = [
  { name: 'union', value: BooleanOperation.UNION, icon: 'GeometryUnion' },
  {
    name: 'subtract',
    value: BooleanOperation.SUBTRACT,
    icon: 'GeometrySubtract'
  },
  {
    name: 'intersect',
    value: BooleanOperation.INTERSECT,
    icon: 'GeometryIntersect'
  },
  {
    name: 'difference',
    value: BooleanOperation.DIFFERENCE,
    icon: 'GeometryDifference'
  }
]

const GeometryTool = () => {
  const editMode = useUI((o) => o.editMode)
  const elements = useElement()
  const selection = useElementSelection()
  const { booleanGroupElementList } = useBooleanGroup()
  const { t } = useTranslation()

  const currentBooleanType = useMemo(() => {
    const booleanableElements = Object.values(elements)
      .filter((item) => selection.includes(item.id))
      .filter((item) => item.elementType !== ElementType.SCREEN)
    if (editMode === EditMode.SHAPE) {
      return BooleanOperation.NONE
    } else if (booleanableElements.length === 1 && booleanableElements[0].booleanType !== undefined) {
      return booleanableElements[0].booleanType
    } else if (booleanableElements.length > 1) {
      return options[0].value
    } else {
      return BooleanOperation.NONE
    }
  }, [editMode, elements, selection])

  const translatedOptions = useMemo(
    () =>
      translateMenuOptions(t, options, {
        ns: 'file',
        keyPrefix: 'tools'
      }),
    [t]
  )

  if (currentBooleanType === BooleanOperation.NONE) {
    return null
  } else {
    return (
      <ToolButton
        value={currentBooleanType}
        options={translatedOptions}
        onChange={booleanGroupElementList}
        selectable={false}
      />
    )
  }
}

export default GeometryTool
