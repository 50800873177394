import { useCallback } from 'react'
import { schema } from 'normalizr'
import { createProvider } from '../utils'
import { Workspace } from './WorkspaceProvider'

export const Document = new schema.Object({
  workspaceList: [Workspace]
  // TODO images, files
})

const [Provider, useSelectState, useSetState] = createProvider('Document', {
  workspaceList: [],
  actionList: [],
  images: []
})

export const useDocument = useSelectState

export const useSetDocument = () => {
  const setState = useSetState()

  const setDocument = useCallback((doc) => setState(doc), [setState])

  const addDocumentAction = useCallback(
    (actionId, index = 0) => {
      setState((state) => {
        const actionList = [...state.actionList]
        actionList.splice(index, 0, actionId)
        return {
          ...state,
          actionList
        }
      })
    },
    [setState]
  )

  const removeDocumentAction = useCallback(
    (actionId) => {
      setState((state) => {
        const index = state.actionList.findIndex((o) => o === actionId)
        const actionList = [...state.actionList]
        if (index !== -1) {
          actionList.splice(index, 1)
        }
        return {
          ...state,
          actionList
        }
      })
    },
    [setState]
  )

  const changeDocumentAction = useCallback(
    (actionId) => {
      setState((state) => ({
        ...state,
        action: actionId
      }))
    },
    [setState]
  )

  const updateDocumentWorkspace = useCallback(
    (workspace) => {
      setState((state) => ({
        ...state,
        workspace
      }))
    },
    [setState]
  )

  return {
    setDocument,
    addDocumentAction,
    removeDocumentAction,
    changeDocumentAction,
    updateDocumentWorkspace
  }
}

export default Provider
