import { dino, getNodeDino } from "../dino"
import { Color } from "../math"
import { VisualServer } from "../visual_server/VisualServer"
/**
 * @param {string} rootID
 * @param {Color} clearColor
 * @param {number} scale
 */
export function takeSnapshot(rootID, clearColor = defaultColor) {
    const VS = VisualServer.instance()
    const root = VS.indexer.getNode(rootID)

    const updateList = [...VS.updateList.values(), root.item]
    VS.indexer.updateNodes(updateList)
    VS.updateList.clear()
    VS.storage.update()

    return renderSnapshot(rootID, clearColor).pixels
}

const defaultColor = Color.hex(0x000000)

/**
 * @param {string} rootID
 * @param {'png'|'jpeg'|'webp'} format
 * @param {Vector2|null} customSize
 * @param {Color} clearColor
 * @returns {Promise<Blob>}
 */
export async function takeSnapshotAsBlob(rootID, format = 'png', customSize = null, clearColor = defaultColor) {
    const VS = VisualServer.instance()
    const root = VS.indexer.getNode(rootID)
    if (!root) {
        console.warn(`Node with rootID ${rootID} not found.`)
        return Promise.reject()
    }

    const updateList = [...VS.updateList.values(), root.item]
    VS.indexer.updateNodes(updateList)
    VS.updateList.clear()
    VS.storage.update()

    const { pixels, width, height } = await renderSnapshot(rootID, clearColor, customSize)
    if (!pixels) {
        console.warn(`Failed to render snapshot for rootID ${rootID}.`)
        return Promise.reject()
    }

    const canvas = document.createElement("canvas")
    canvas.width = width
    canvas.height = height
    const ctx = canvas.getContext("2d")
    ctx.putImageData(new ImageData(new Uint8ClampedArray(pixels), width, height), 0, 0)

    return new Promise((resolve) => canvas.toBlob(resolve, `image/${format}`))
}

/**
 * @param {string} rootID
 * @param {Color} clearColor
 * @param {Vector2|null} customSize
 * @returns {{pixels: Uint8ClampedArray, width: number, height: number}}
 */
function renderSnapshot(rootID, clearColor, customSize = null) {
    const VS = VisualServer.instance()
    const node = VS.indexer.getNode(rootID)
    if (!node) {
        console.warn(`cannot take snapshot of non-exist element "${rootID}"`)
        return {
            width: 0,
            height: 0,
            pixels: null,
        }
    }

    const api = dino()
    const { dinoNode } = getNodeDino(node.item)
    api.setCaptureBackgroundColor(clearColor.r, clearColor.g, clearColor.b, clearColor.a)

    const { x: nodeX, y: nodeY, width: nodeW, height: nodeH } = node.boundsWorldAABB
    const width = Math.ceil((customSize ? customSize.x : nodeW) * 0.5) * 2
    const height = Math.ceil((customSize ? customSize.y : nodeH) * 0.5) * 2
    api.capture(dinoNode.root_id, nodeX, nodeY, nodeW, nodeH, width, height)

    dino().pixels = null
    return new Promise(resolve => {
        const intervalId = setInterval(() => {
            if (dino().pixels !== null) {
                clearInterval(intervalId)
                clearTimeout(timeoutId)
                resolve({ pixels: dino().pixels, width, height })
            }
        }, 100)

        const timeoutId = setTimeout(() => {
            clearInterval(intervalId)
            console.warn(`renderSnapshot timed out`)
            resolve({
                width: 0,
                height: 0,
                pixels: null,
            })
        }, 20000)
    })
}
