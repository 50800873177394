import React, { forwardRef, useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'

import { useSetAtom } from 'jotai'

import { TRANSITION_STATUS } from '@phase-software/transition-manager'
import { InteractionEntityType } from '@phase-software/types'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { DragAction, autoScrollDirectionAtom, dragActionAtom } from '../../../atoms'
import dataStore from '../../../dataStore'
import { useElementVirtualProperties } from '../../../providers/ElementVirtualPropertiesProvider'
import { useTransitionManager, useTransitionManagerActions } from '../../../providers/TransitionManagerProvider'
import { useDataStore, useDataStoreActions } from '../../../providers/dataStore/DataStoreProvider'
import { useElementSelection } from '../../../providers/dataStore/ElementSelectionProvider'
import { useHoverSelection, useHoverSelectionActions } from '../../../providers/dataStore/HoverSelectionProvider'
import {
  useInteraction,
  useInteractionActions,
  useSetInteraction
} from '../../../providers/dataStore/InteractionProvider'
import { useKeyframeSelection } from '../../../providers/dataStore/KeyframeSelectionProvider'
import { List, ScrollView } from '../../shared'
import { TRACK_INFO_WIDTH, TRACK_PADDING_X } from '../constant'
import { ScrollActionContext, ScrollContext, SizeContext } from '../contexts'
import { animateWithDuration, getScrollDirection, getTimeAtStep } from '../utils'
import { AutoScroll } from './AutoScroll'
import { DragCursor } from './DragCursor'
import ElementTrack from './ElementTrack'
import { SyncPlayhead } from './SyncPlayhead'
import TrackHeader from './TrackHeader'

const KeyframeWrapper = styled(ScrollView).attrs(({ w }) => ({
  style: { gridAutoColumns: `minmax(${w}px, 1fr)` }
}))`
  position: relative;
  display: grid;
  grid-area: keyframe;
  grid-auto-rows: 32px;
  grid-auto-flow: row dense;
  background: ${(props) => props.theme.colors.neutrals[100]};
`

export const TrackInfoList = forwardRef(({ trackList, trackMap, cacheRef, kfRef }, ref) => {
  const previousTrackListLength = useRef()
  const hoverEl = useHoverSelection()
  const selection = useElementSelection()
  const elementsVirtualProperties = useElementVirtualProperties()
  const hoverTrackId = hoverEl ? trackMap.get(hoverEl) : null
  const [hover, setHover] = useState()
  const interaction = useInteraction()
  const { hoverElement } = useHoverSelectionActions()
  const { disableScrollIntoView } = useContext(ScrollContext)
  const { setDisableScrollIntoView } = useContext(ScrollActionContext)

  const handleHover = (id) => {
    if (id !== hover) {
      setHover(id)
    }
    if (id) {
      const { elementId } = interaction.entityMap.get(id)
      hoverElement(elementId)
      return
    }
    hoverElement(null)
  }
  const { setAllTrackUnfolded } = useSetInteraction()
  const { getFlattenTrackList, toggleTrackExpanded, toggleKeyFrameSelectionByTrack } = useInteractionActions()
  const root = trackList
    .map((id) => trackMap.get(id))
    .filter((trackId) => {
      // Toggle ElementTrack UI visibility when drag-duplicate element
      const track = interaction.entityMap.get(trackId)
      if (track && track.type === InteractionEntityType.ELEMENT_TRACK) {
        return elementsVirtualProperties[track.elementId]?.virtualDisplay !== false
      }
      return true
    })
  const selectedElementTrack = selection.map((id) => trackMap.get(id))

  const handleInfoScroll = useCallback(
    (e) => {
      const actionPanel = kfRef.current.closest('.js-action-panel')
      const active = actionPanel.classList.contains('active')
      if (!active || cacheRef.current.panel === 'info') {
        kfRef.current.scrollTop = e.currentTarget.scrollTop
      }
    },
    [kfRef, cacheRef]
  )

  const handleEnterInfoList = () => {
    cacheRef.current.panel = 'info'
  }

  const handleOnSelect = useCallback(
    (trackList, trackId, toggleSelect) => {
      toggleKeyFrameSelectionByTrack(trackList, trackId, toggleSelect)
    },
    [toggleKeyFrameSelectionByTrack]
  )

  useEffect(() => {
    if (trackList.length > 0 && previousTrackListLength.current === 0) {
      setTimeout(() => {
        setAllTrackUnfolded()
      }, 0)
    }
    previousTrackListLength.current = trackList.length
  }, [trackList, setAllTrackUnfolded])

  const handleElSelect = useCallback(
    (changes) => {
      const change = changes.get('elements')
      if (!change) {
        return
      }
      setDisableScrollIntoView(dataStore.workspace.children[0].children.length === change.after.length)
    },
    [setDisableScrollIntoView]
  )

  useEffect(() => {
    if (!dataStore) return
    dataStore.selection.on('SELECT', handleElSelect)
    return () => {
      dataStore.selection.off('SELECT', handleElSelect)
    }
  }, [handleElSelect])

  return (
    <List
      selectedColor="secondary"
      ref={ref}
      className="group pt-action-list z-30 flex-grow text-white pt-8"
      data-test-id="track-info-list"
      style={{ maxWidth: TRACK_INFO_WIDTH }}
      noScrollbar
      data={interaction.entityMap}
      root={root}
      flatten={getFlattenTrackList}
      selectedList={[...(interaction.selectedTrackList || []), ...selectedElementTrack]}
      highlightedList={[hover, hoverTrackId]}
      renderItem={TrackHeader}
      onExpand={toggleTrackExpanded}
      onSelect={handleOnSelect}
      onItemHover={handleHover}
      onWheel={handleEnterInfoList}
      onScroll={handleInfoScroll}
      align="start"
      viewAnchor="bottom"
      disableScrollIntoView={disableScrollIntoView}
    />
  )
})
TrackInfoList.displayName = 'TrackInfoList'
TrackInfoList.propTypes = {
  trackMap: PropTypes.object,
  kfRef: PropTypes.object,
  cacheRef: PropTypes.object,
  trackList: PropTypes.array
}

export const TrackKeyFrameList = forwardRef(({ trackList, trackMap, cacheRef, infoRef, rulerRef }, ref) => {
  const keyframeSelection = useKeyframeSelection()
  const setDragAction = useSetAtom(dragActionAtom)
  const setAutoScrollDirection = useSetAtom(autoScrollDirectionAtom)
  const { scale, width, duration } = useContext(SizeContext)
  const { setPlayheadTime, selectKeyframeSelection } = useDataStoreActions()
  const { getKeyFrameByTime } = useInteractionActions()
  const elementsVirtualProperties = useElementVirtualProperties()
  const hasMouseDown = useRef(false)
  const hasDragged = useRef(false)
  const dataStore = useDataStore()
  const { playAnimation } = useTransitionManagerActions()
  const status = useTransitionManager((o) => o.status)
  const { setDisableScrollIntoView } = useContext(ScrollActionContext)

  useLayoutEffect(() => {
    cacheRef.current.panel = 'kfs'
    ref.current.scrollLeft = ref.current.scrollWidth * cacheRef.current.scalePosition - ref.current.offsetWidth / 2
    cacheRef.current.scrollWidth = ref.current.scrollWidth
  }, [cacheRef, ref])

  useEffect(() => {
    if (ref.current.scrollLeft !== rulerRef.current.scrollLeft) {
      ref.current.scrollLeft = rulerRef.current.scrollLeft
    }
  }, [ref, rulerRef, trackList])

  const handleScroll = useCallback(
    (e) => {
      if (cacheRef.current.scrollWidth === ref.current.scrollWidth) {
        cacheRef.current.scalePosition =
          (ref.current.scrollLeft + ref.current.offsetWidth / 2) / ref.current.scrollWidth
      }
      if (cacheRef.current.panel === 'kfs') {
        infoRef.current.scrollTop = e.currentTarget.scrollTop
        rulerRef.current.scrollLeft = e.currentTarget.scrollLeft
      }
    },
    [cacheRef, infoRef, rulerRef, ref]
  )

  const handleClick = useCallback(
    (e) => {
      // Do nothing if has mouse down on the wrapper and has dragged kf
      if (hasMouseDown.current && hasDragged.current) {
        hasDragged.current = false
        hasMouseDown.current = false
        return
      }

      // If not have mouse down, then we should not handle click event
      if (!hasMouseDown.current) {
        hasMouseDown.current = false
        return
      }

      selectKeyframeSelection([])
      if (e.detail === 2) {
        const pos = e.clientX - TRACK_INFO_WIDTH - TRACK_PADDING_X + ref.current.scrollLeft
        let newTime = Math.max(0, (pos * duration) / width / scale)
        newTime = Math.min(newTime, duration)
        newTime = getTimeAtStep(newTime)
        setPlayheadTime(newTime)
      }
      hasDragged.current = false
      hasMouseDown.current = false
    },
    [selectKeyframeSelection, setPlayheadTime, duration, width, scale, ref]
  )

  const handleMouseDown = (e) => {
    cacheRef.current.panel = 'kfs'

    hasMouseDown.current = true
    setDisableScrollIntoView(true)
    setDragAction(DragAction.SELECT)
    const selection = keyframeSelection.slice()
    let candidate = []
    let prevSelection = keyframeSelection.slice()
    const wasPlaying = status === TRANSITION_STATUS.START

    const rect = ref.current.getBoundingClientRect()
    const { top, left } = rect
    const kfs = Array.from(document.querySelectorAll('.js-kf')).map((kf) => {
      const { width, top: T, left: L, height } = kf.getBoundingClientRect()
      const { time, trackId } = kf.dataset
      return {
        idList: getKeyFrameByTime(trackId, time),
        width,
        top: ref.current.scrollTop + T - top,
        left: ref.current.scrollLeft + L - left,
        right: ref.current.scrollLeft + L - left + width,
        bottom: ref.current.scrollTop + T - top + height
      }
    })
    const x1 = ref.current.scrollLeft + e.clientX - left
    const y1 = ref.current.scrollTop + e.clientY - top
    const mask = document.createElement('div')

    Object.assign(mask.style, {
      position: 'absolute',
      background: 'rgba(90, 83, 255, 0.1)',
      border: '1px solid #1C6EE8',
      pointerEvents: 'none',
      top: `${y1}px`,
      left: `${x1}px`,
      maxWidth: `${ref.current.scrollWidth - x1}px`,
      maxHeight: `${ref.current.scrollHeight - y1}px`
    })

    ref.current.appendChild(mask)
    let direction = []
    let _raf = null
    let x = x1
    let y = y1

    let cancelPlayheadTransition
    const setPlayheadAnimation = (time) => {
      if (cancelPlayheadTransition) {
        cancelPlayheadTransition()
        cancelPlayheadTransition = null
      }
      const currPlayheadTime = getTimeAtStep(dataStore.transition.currentTime)
      const timeDiff = time - currPlayheadTime
      if (timeDiff) {
        cancelPlayheadTransition = animateWithDuration((progress) => {
          const currentTime = currPlayheadTime + progress * timeDiff
          setPlayheadTime(currentTime)
          if (progress === 1 && wasPlaying) {
            playAnimation()
          }
        }, 200)
      }
    }

    const updateMaskAndCandidate = () => {
      const x2 = Math.max(0, ref.current.scrollLeft + x - left)
      const y2 = Math.max(0, ref.current.scrollTop + y - top)
      const L = Math.min(x1, x2)
      const T = Math.min(y1, y2)
      const R = L + Math.abs(x1 - x2)
      const B = T + Math.abs(y1 - y2)
      Object.assign(mask.style, {
        left: `${L}px`,
        top: `${T}px`,
        width: `${R - L}px`,
        height: `${B - T}px`,
        maxWidth: `${ref.current.scrollWidth - L}px`,
        maxHeight: `${ref.current.scrollHeight - T}px`,
        zIndex: 100
      })
      candidate = kfs
        .filter((kf) => {
          return !(R < kf.left || L > kf.right || B < kf.top || T > kf.bottom)
        })
        .map((kf) => kf.idList)
        .flat()
    }

    const doScroll = () => {
      if (direction.length) {
        updateMaskAndCandidate()
      }
      _raf = requestAnimationFrame(doScroll)
    }
    _raf = requestAnimationFrame(doScroll)

    const handleMove = (e) => {
      hasDragged.current = true
      direction = getScrollDirection(e, rect)
      setAutoScrollDirection(direction)
      x = e.clientX
      y = e.clientY
      updateMaskAndCandidate()

      let newSelectedEntityId = []
      if (e.metaKey || e.ctrlKey) {
        // difference
        const difference = selection
          .filter((x) => !candidate.includes(x))
          .concat(candidate.filter((x) => !selection.includes(x)))
        selectKeyframeSelection(difference, false)
        newSelectedEntityId = difference.filter((k) => !prevSelection.includes(k))
        prevSelection = difference.slice()
      } else if (e.shiftKey) {
        // union
        const newSelection = Array.from(new Set([...selection, ...candidate]))
        selectKeyframeSelection(newSelection, false)
        newSelectedEntityId = newSelection.filter((k) => !prevSelection.includes(k))
        prevSelection = newSelection.slice()
      } else {
        selectKeyframeSelection(candidate, false)
        newSelectedEntityId = candidate.filter((k) => !prevSelection.includes(k))
        prevSelection = candidate.slice()
      }

      if (newSelectedEntityId.length) {
        const kfs = newSelectedEntityId
          .map((key) => dataStore.interaction.getEntity(key))
          .filter((entity) => entity.type === InteractionEntityType.KEY_FRAME)
        if (kfs.length) {
          setPlayheadAnimation(kfs[0].time)
        }
      }
    }
    window.addEventListener('mousemove', handleMove)
    window.addEventListener(
      'mouseup',
      () => {
        window.removeEventListener('mousemove', handleMove)
        direction = []
        // reset and update again with undoable
        selectKeyframeSelection(selection, false)
        selectKeyframeSelection(prevSelection, true)

        setAutoScrollDirection(direction)
        cancelAnimationFrame(_raf)
        ref.current.removeChild(mask)
        setDisableScrollIntoView(false)
        setDragAction(DragAction.NONE)
      },
      { once: true }
    )
  }

  const handleEnterKeyFrameList = () => {
    cacheRef.current.panel = 'kfs'
  }

  return (
    <KeyframeWrapper
      className="pt-action-track-list py-8"
      key="keyframe"
      w={width * scale}
      ref={ref}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      onWheel={handleEnterKeyFrameList}
      onScroll={handleScroll}
    >
      {trackList.length
        ? trackList
            .filter((elId) => elementsVirtualProperties[elId]?.virtualDisplay !== false)
            .map((elId) => {
              const trackId = trackMap.get(elId)
              return <ElementTrack key={trackId} elementId={elId} trackId={trackId} place="keyframe" />
            })
        : null}
      <AutoScroll targetRef={ref} />
      <DragCursor />
      <SyncPlayhead />
    </KeyframeWrapper>
  )
})
TrackKeyFrameList.displayName = 'TrackKeyFrameList'
TrackKeyFrameList.propTypes = {
  trackMap: PropTypes.object,
  rulerRef: PropTypes.object,
  infoRef: PropTypes.object,
  cacheRef: PropTypes.object,
  trackList: PropTypes.array
}
