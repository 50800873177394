import { LayerType } from '@phase-software/types'
import LayerStack from '../LayerStack'

class StrokeStack extends LayerStack {
  constructor(elementStack, data) {
    super(elementStack, data)
    this.type = LayerType.STROKE
    this.key = 'strokes'
    this.dataKey = 'strokes'

    this.animatableProperties = new Set([
      'opacity',
      'paint',
      'borderWidth',
      'capSize',
      'joinSize',
      'dash',
      'gap',
      'offset'
    ])
  }
}

export default StrokeStack
