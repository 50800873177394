import { Events, Hover } from '@phase-software/data-store'
import { ElementType, ToolType } from '@phase-software/types'
import { Vector2, Rot, Transform2D } from "../../math"
import { HIDING_VALUE_RATIO, MIN_SIZE_THRESHOLD } from '../../constants'
import { getActiveHandle, getActiveHandleForAABB } from '../../visual_server/HitTest'
import { getSizeFlag } from '../../visual_server/RenderItem'
import * as selectionResize from './selection'
import * as elementResize from './element'
import { Snapping } from './Snapping'

/** @typedef {import('@phase-software/data-store/src/Element').Element} Element */
/** @typedef {import('@phase-software/data-utils/index').Mesh} Mesh */
/** @typedef {import('@phase-software/data-utils/src/mesh/Edge').Edge} Edge */
/** @typedef {Vector2} Anchor [0|0.5|1, 0|0.5|1] */
/** @typedef {import('../../visual_server/Selection').SelectedItem} SelectedItem */
/** @typedef {import('../../visual_server/RenderItem').RenderItem} RenderItem */

/** [0|0.5|1, 0|0.5|1] */
export const Anchor = Vector2

/**
 * @typedef {object} Handle
 * @property {Type} type
 * @property {Anchor} anchor
 * @property {Rot} cursorRot
 */


/** @enum {number} */
const Type = {
    RESIZE: 0,
    ROTATE: 1
}

/** @enum {Anchor} */
export const ANCHORS = {
    TOP_LEFT: new Anchor(0, 0),
    TOP: new Anchor(0.5, 0),
    TOP_RIGHT: new Anchor(1, 0),
    RIGHT: new Anchor(1, 0.5),
    BOTTOM_RIGHT: new Anchor(1, 1),
    BOTTOM: new Anchor(0.5, 1),
    BOTTOM_LEFT: new Anchor(0, 1),
    LEFT: new Anchor(0, 0.5),
    CENTER: new Anchor(0.5, 0.5),
}

export const HANDLES = {
    TOP_LEFT: {
        type: Type.RESIZE,
        anchor: ANCHORS.BOTTOM_RIGHT,
        cursorRot: Rot[45]
    },
    TOP_RIGHT: {
        type: Type.RESIZE,
        anchor: ANCHORS.BOTTOM_LEFT,
        cursorRot: -Rot[45]
    },
    BOTTOM_RIGHT: {
        type: Type.RESIZE,
        anchor: ANCHORS.TOP_LEFT,
        cursorRot: Rot[45]
    },
    BOTTOM_LEFT: {
        type: Type.RESIZE,
        anchor: ANCHORS.TOP_RIGHT,
        cursorRot: -Rot[45]
    },
    TOP: {
        type: Type.RESIZE,
        anchor: ANCHORS.BOTTOM,
        cursorRot: Rot[90]
    },
    RIGHT: {
        type: Type.RESIZE,
        anchor: ANCHORS.LEFT,
        cursorRot: Rot[0]
    },
    BOTTOM: {
        type: Type.RESIZE,
        anchor: ANCHORS.TOP,
        cursorRot: Rot[90]
    },
    LEFT: {
        type: Type.RESIZE,
        anchor: ANCHORS.RIGHT,
        cursorRot: Rot[0]
    },
    TOP_LEFT_ROTATE: {
        type: Type.ROTATE,
        anchor: ANCHORS.BOTTOM_RIGHT,
        cursorRot: -Rot[90]
    },
    TOP_RIGHT_ROTATE: {
        type: Type.ROTATE,
        anchor: ANCHORS.BOTTOM_LEFT,
        cursorRot: Rot[0]
    },
    BOTTOM_RIGHT_ROTATE: {
        type: Type.ROTATE,
        anchor: ANCHORS.TOP_LEFT,
        cursorRot: Rot[90]
    },
    BOTTOM_LEFT_ROTATE: {
        type: Type.ROTATE,
        anchor: ANCHORS.TOP_RIGHT,
        cursorRot: Rot[180]
    },
}

/** @typedef {import('../../visual_server/VisualServer').VisualServer} VisualServer */
/** @typedef {import('../../Viewport').Viewport} Viewport */
/** @typedef {import('../local_cursor').CursorState} CursorState */
/** @typedef {import('@phase-software/data-utils').AABB} AABB */
/** @typedef {import('../../input-system/Action').ISEvent} ISEvent */

/**
 * @typedef {object} Handlers
 * @property {ToolType} currentTool
 * @property {bool} ignoreActiveTool
 * @property {(handleName: string) => void} setHandle
 * @property {(e: ISEvent, snapToGrid?: boolean) => void} resizeStart
 * @property {(e: ISEvent) => void} resizeUpdate
 * @property {() => void} resizeEnd
 * @property {(e: ISEvent) => void} rotateStart
 * @property {(mousePos: Vector2, snapTo15deg: boolean) => void} rotateUpdate
 * @property {() => void} rotateEnd
 */

/**
 * @param {VisualServer} visualServer
 * @param {import('..').SetLocalCursorStateFn} setLocalCursorState
 * @param localCursorHandler
 * @returns {Handlers} handlers
 */
export function initHandles(visualServer, localCursorHandler) {
    const { getCurrentState: getCursorState, setState: setLocalCursorState } = localCursorHandler
    const { dataStore, viewport, snapping } = visualServer

    /** @type {Handle} */
    let hoveredHandle = null
    /** @type {Element} */
    let hoveredSelectedItem = null
    let hoveringHandle = null
    /** @type {Vector2} */
    let startPosForScale = null
    /** @type {Vector2} */
    let scaleDir = null
    /** @type {Vector2} */
    const clickPoint = new Vector2()

    let _resizeFn = null

    /** @type {Map<string, { scaleX: number, scaleY: number, sizeFlag: { w: '!0' | 't0' | 'f0', h: '!0' | 't0' | 'f0' }>} */
    const elementsScale = new Map()

    const resizeStartSizeFlag = { w: '!0', h: '!0'}

    /**
     *  handlers for the handles :)
     */
    const handlers = {
        // tool used at the start of the action (resize or rotate)
        currentTool: null,

        // set this to true to ignore active tool and always resize (during element creation)
        ignoreActiveTool: false,

        /** @param {string} handleName */
        setHandle: (handleName) => {
            hoveredHandle = HANDLES[handleName]
        },

        /**
         * @param {ISEvent} e
         * @param {boolean} snapToGrid
         * @param {boolean} snapToObject
         */
        resizeStart: (e, snapToGrid, snapToObject) => {
            if (hoveredHandle && hoveredHandle.type === Type.RESIZE) {
                const single = visualServer.selection.single

                const activeTool = dataStore.get('activeTool')
                handlers.currentTool = handlers.ignoreActiveTool ? ToolType.SELECT : activeTool
                switch (handlers.currentTool) {
                    case ToolType.SELECT:
                        if (single === null) {
                            _resizeFn = selectionResize.resize(
                                hoveredHandle,
                                visualServer.selection.iter(),
                                visualServer.selection.bounds.clone(),
                                snapping,
                                viewport.toWorld(e.mousePos)
                            )
                        } else {
                            let freezeOriginX = false
                            let freezeOriginY = false
                            resizeStartSizeFlag.w = single.node.item.sizeFlag.w
                            resizeStartSizeFlag.h = single.node.item.sizeFlag.h
                            const element = single.element
                            const { width, height } = element.get('size')
                            const contentAnchor = element.get('contentAnchor')
                            const referencePoint = element.get('referencePoint')
                            const changes = {}
                            if (width > 0 && width < MIN_SIZE_THRESHOLD) {
                                const cAX_fromCenter = contentAnchor.x + (referencePoint.x - width * 0.5)
                                if (Math.abs(cAX_fromCenter) > 1) {
                                    freezeOriginX = true
                                } else {
                                    const expandedCAX_fromCenter = cAX_fromCenter * width * HIDING_VALUE_RATIO
                                    const expandedCAX_fromRealRP = expandedCAX_fromCenter - (referencePoint.x - width * 0.5)
                                    changes.contentAnchorX = expandedCAX_fromRealRP
                                }
                            }
                            if (height > 0 && height < MIN_SIZE_THRESHOLD) {
                                const cAY_fromCenter = contentAnchor.y + (referencePoint.y - height * 0.5)
                                if (Math.abs(cAY_fromCenter) > 1) {
                                    freezeOriginY = true
                                } else {
                                    const expandedCAY_fromCenter = cAY_fromCenter * height * HIDING_VALUE_RATIO
                                    const expandedCAY_fromRealRP = expandedCAY_fromCenter - (referencePoint.y - height * 0.5)
                                    changes.contentAnchorY = expandedCAY_fromRealRP
                                }
                            }
                            const translate = dataStore.drawInfo.getFixedPositionByChanges(element.get('id'), changes)
                            if (translate) {
                                changes.translateX = translate[0]
                                changes.translateY = translate[1]
                                single.element.sets(changes)
                            }
                            _resizeFn = elementResize.resize(
                                hoveredHandle,
                                single.element,
                                single.node,
                                snapping,
                                viewport.toWorld(e.mousePos),
                                freezeOriginX,
                                freezeOriginY
                            )
                        }
                        handlers.resizeUpdate = resizeUpdateWithModifiers
                        break
                    case ToolType.SCALE:
                        setupScaleCallback(e, snapToGrid, snapToObject)
                        break
                }
            } else {
                e.handled = false
            }
        },

        /** @type {(mousePos: Vector2, keepAspect: boolean, anchorOverride: Anchor) => void} */
        resizeUpdate: () => { },

        /**
         * @param {bool} [commit=true]
         */
        resizeEnd: (commit = true) => {
            if (handlers.currentTool === ToolType.SCALE) {
                if (startPosForScale) {
                    startPosForScale = null
                }
                if (scaleDir) {
                    scaleDir = null
                }
                elementsScale.clear()
            }
            if (handlers.currentTool === ToolType.SELECT) {
                const single = visualServer.selection.single
                if (single) {
                    const element = single.element
                    const { width, height } = element.get('size')
                    const contentAnchor = element.get('contentAnchor') // ca
                    const referencePoint = element.get('referencePoint') // rf
                    const changes = {}
                    if (width > 0 && width < MIN_SIZE_THRESHOLD && resizeStartSizeFlag.w === '!0') {
                        const cAX_fromCenter = contentAnchor.x + (referencePoint.x - width * 0.5)
                        const compressedCAX_fromCenter = cAX_fromCenter / width / HIDING_VALUE_RATIO
                        const compressedCAX_fromRealRP = compressedCAX_fromCenter - (referencePoint.x - width * 0.5)
                        changes.contentAnchorX = compressedCAX_fromRealRP
                    }
                    if (height > 0 && height < MIN_SIZE_THRESHOLD && resizeStartSizeFlag.h === '!0') {
                        const cAY_fromCenter = contentAnchor.y + (referencePoint.y - height * 0.5)
                        const compressedCAY_fromCenter = cAY_fromCenter / height / HIDING_VALUE_RATIO
                        const compressedCAY_fromRealRP = compressedCAY_fromCenter - (referencePoint.y - height * 0.5)
                        changes.contentAnchorY = compressedCAY_fromRealRP
                    }
                    const translate = dataStore.drawInfo.getFixedPositionByChanges(element.get('id'), changes)
                    if (translate) {
                        changes.translateX = translate[0]
                        changes.translateY = translate[1]
                        single.element.sets(changes)
                    }
                }
            }

            handlers.resizeUpdate = () => { }
            handlers.currentTool = null
            handlers.ignoreActiveTool = false
            snapping.setEndSnapping()
            snapping.endSnapMovingElementToElement()
            if (commit) {
                dataStore.commitUndo()
            }
        },

        /** @param {ISEvent} e */
        rotateStart: (e) => {
            if (hoveredHandle && hoveredHandle.type === Type.ROTATE) {
                const single = visualServer.selection.single
                // for multi-selection but individual rotating in scale tool
                const center = dataStore.get('activeTool') === ToolType.SCALE ? hoveredSelectedItem.node.boundsVisualWorld.center : visualServer.selection.bounds.center
                handlers.rotateUpdate = single === null
                    ? selectionResize.rotate(visualServer.selection.iter(), center, viewport.toWorld(e.mousePos), setLocalCursorState)
                    : elementResize.rotate(single.element, single.node, viewport.toWorld(e.mousePos), setLocalCursorState)
            } else {
                e.handled = false
            }
        },

        /** @type {(mousePos: Vector2, snapTo15deg: boolean) => void} */
        rotateUpdate: () => { },

        rotateEnd: () => {
            handlers.rotateUpdate = () => { }
            dataStore.commitUndo()
        }
    }

    // TODO: change this to be on the off transition of HANDLES state
    dataStore.selection.on('SELECT', () => {
        if (dataStore.selection.isEmpty() && dataStore.get('activeTool') !== ToolType.PEN) {
            setLocalCursorState('default')
        }
    })

    dataStore.on('CHANGES', changes => {
        if (changes.has('activeTool')) {
            if (changes.get('activeTool').value !== ToolType.SCALE) return

            if (hoveredHandle && hoveredHandle.type !== Type.ROTATE) {
                const single = visualServer.selection.single
                const rot = single.node.item.transform.saveWorld.get_rotation()
                setLocalCursorState('scale', rot + hoveredHandle.cursorRot)
            }
        }
    })

    dataStore.eam.on(Events.HOVER_BOX_HANDLE, (e) => {
        const single = visualServer.selection.single
        const activeTool = dataStore.get('activeTool')
        const cursorState = getCursorState()

        hoveringHandle = null
        if (single === null) {
            if (activeTool === ToolType.SCALE) {
                let items = []
                for (const item of visualServer.selection.iter()) {
                    items = [{...item}, ...items]
                }
                hoveringHandle = getActiveHandle(items, e.mousePos)
            } else {
                const bbox = visualServer.selection.bounds.clone()
                const { width, height } = bbox
                const sizeFlagW = getSizeFlag(width)
                const sizeFlagH = getSizeFlag(height)
                if (sizeFlagW === 'f0') {
                    bbox.width = 0
                    bbox.x += width * 0.5
                }
                if (sizeFlagH === 'f0') {
                    bbox.height = 0
                    bbox.y += height * 0.5
                }
                hoveringHandle = getActiveHandleForAABB(bbox, e.mousePos)
            }
        } else {
            hoveringHandle = getActiveHandle([single], e.mousePos)
        }

        hoveredSelectedItem = null
        hoveredHandle = null
        if (hoveringHandle) {
            hoveredSelectedItem = hoveringHandle.item
            hoveredHandle = hoveringHandle.handle
        }
        const isAllElementLocked = visualServer.selection.all(item => item.element.isLocked())

        if (hoveredHandle && !isAllElementLocked) {
            // when selection includes non-screen and unlocked element,
            // the cursor should be able to change into the 'rotate cursor'
            const isSelectingScreenElement = visualServer.selection.all(item => item.element.get('elementType') === ElementType.SCREEN)
            const isAllNonScreenElementLocked = visualServer.selection.all(item =>
                item.element.get('locked') && item.element.get('elementType') !== ElementType.SCREEN)

            if (hoveredHandle.type === Type.ROTATE) {
                if (isSelectingScreenElement || isAllNonScreenElementLocked) {
                    dataStore.eam.changeHover(Hover.NONE)
                    setLocalCursorState('default')
                    return
                }
                dataStore.eam.changeHover(Hover.ROTATE_HANDLE)
                setLocalCursorState('rotate')
            } else {
                if (hoveredSelectedItem && hoveredSelectedItem.element.isLineElement()) {
                    setLocalCursorState(
                        (activeTool === ToolType.SCALE && hoveredSelectedItem) ? 'crossScale' : 'crossMove')
                } else {
                    const rot = hoveredSelectedItem ? hoveredSelectedItem.node.item.transform.saveWorld.get_rotation() : 0
                    let c_rot = rot + hoveredHandle.cursorRot
                    if (hoveredSelectedItem) {
                        // TOFIX: Need to support world skew x, y
                        const skew = hoveredSelectedItem.element.get('skew')
                        const scale = hoveredSelectedItem.node.item.transform.world.get_scale()
                        const anchor = ANCHORS.CENTER.clone().sub(hoveredHandle.anchor).multiply(2, 2).abs()
                        c_rot -= skew[0] * anchor.y / (anchor.x + anchor.y)
                        c_rot += skew[1] * anchor.x / (anchor.x + anchor.y)
                        if (!approxEqAbs(hoveredHandle.anchor.x, 0.5, 1e-3) && !approxEqAbs(hoveredHandle.anchor.y, 0.5, 1e-3)) {
                            if (scale.x < 0) {
                                c_rot += Math.PI * 0.5
                            }
                            if (scale.y < 0) {
                                c_rot += Math.PI * 0.5
                            }
                            if (Math.abs(Math.floor((skew[0] + Math.PI * 0.5) / Math.PI) % 2) === 1) {
                                c_rot += Math.PI * 0.5
                            }
                            if (Math.abs(Math.floor((skew[1] + Math.PI * 0.5) / Math.PI) % 2) === 1) {
                                c_rot += Math.PI * 0.5
                            }
                        }
                    }
                    if (activeTool === ToolType.SCALE) {
                        if (isSelectingScreenElement || isAllNonScreenElementLocked) {
                            dataStore.eam.changeHover(Hover.NONE)
                            setLocalCursorState('default')
                            return
                        }
                        setLocalCursorState('scale', c_rot)
                    } else if (!dataStore.selection.get('hoverMotionPoint')) {
                        setLocalCursorState('resize', c_rot)
                    }
                }
                dataStore.eam.changeHover(Hover.RESIZE_HANDLE)
            }
        } else {
            if (!cursorState.secondState) {
                setLocalCursorState('default')
            }
        }
    })

    const resizeUpdateWithModifiers = (e, keys, snapToGrid, snapToObject) => {
        _resizeFn(viewport.toWorld(e.mousePos), keys.shift, keys.alt, undefined, snapToGrid, snapToObject)
    }

    // ------------------------- //
    // ----  SCALE Handles  ---- //
    // ------------------------- //
    const targetWorldInv = new Transform2D()
    const targetScale = new Vector2()
    const targetSize = new Vector2()
    const handle_origin = new Vector2() // shared among all selected elements
    const local_delta = new Vector2() // local movement shared among all selected elements
    const local_local_delta = new Vector2()
    const local_handle_origin = new Vector2()
    const local_origin = new Vector2()
    const delta = new Vector2()
    const origin = new Vector2()
    const scale = new Vector2()
    const setupScaleCallback = (event, snapToGrid = true, snapToObject = true) => {
        scaleDir = new Vector2(
            ANCHORS.CENTER.x - hoveredHandle.anchor.x,
            ANCHORS.CENTER.y - hoveredHandle.anchor.y
        )
        const selection = visualServer.selection
        const hoveredNode = hoveredSelectedItem.node
        const hoveredElement = hoveredSelectedItem.element
        const snapping = visualServer.snapping
        const isClickEdge = hoveredHandle.anchor.x === 0.5 || hoveredHandle.anchor.y === 0.5
        startPosForScale = viewport.toWorld(event.mousePos)

        const isAlignAxis = snapping.isMultipleOf90Degree(hoveredNode.item.transform.rotation)
        const mouseDownPoint = snapping.getScalingClickPoint(scaleDir, hoveredNode)
        clickPoint.copy(mouseDownPoint)

        elementsScale.clear()
        for (const { element, node } of selection.iter()) {
            const originScale = element.get('scale')
            elementsScale.set(element.get('id'), {
                sizeFlag: node.item.sizeFlag,
                scaleX: originScale.x,
                scaleY: originScale.y,
            })
        }
        const isSnapScalingToPixelGrid = snapping.isScalingSnapToPixelGrid(hoveredNode, isClickEdge)

        if (hoveredNode.item.transform.world.basis_determinant() === 0) {
            const { translate, skew, rotation } = hoveredNode.item.transform
            const pivotOffset = hoveredNode.item.transform.getPivotOffset()

            targetWorldInv.reset()
                .append(hoveredNode.parent.item.transform.world.clone().affine_inverse())
                .translate_right(pivotOffset.x, pivotOffset.y)
                // .scale_right(scale.x===0 ? 1 : 1/scale.x, scale.y===0 ? 1 : 1/scale.y)
                .skew_right(-skew.x, -skew.y)
                .rotate_right(-rotation)
                .translate_right(-translate.x, -translate.y)
        } else {
            targetWorldInv.copy(hoveredNode.item.transform.world.clone().affine_inverse())
        }
        targetScale.set(hoveredElement.get('scale').x, hoveredElement.get('scale').y)
        targetSize.set(hoveredElement.get('size').width, hoveredElement.get('size').height)
        const targetAspect =  (targetScale.y * targetSize.y) === 0 ? 0 : (targetScale.x * targetSize.x) / (targetScale.y * targetSize.y)

        handlers.resizeUpdate = (e, keys) => {
            // update Snapping Data
            snapping.updateSnapMovingData()
            snapping.vs.selection.updateBounds()
            snapping.setResizeSelectedElementOAB(hoveredHandle.anchor, hoveredNode)
            // calculateDeltaAndUpdateSelection
            const mousePos = viewport.toWorld(e.mousePos)
            snapping.updateMoved(mousePos.clone().sub(startPosForScale))

            let dx = mousePos.x - startPosForScale.x
            let dy = mousePos.y - startPosForScale.y

            if (dx !== 0 || dy !== 0) {
                dataStore.startTransaction()

                // if the single selected element is rotated, need to rotate delta to snap to pixel grid
                if (snapToGrid && isSnapScalingToPixelGrid) {
                    const newMousePos = snapping.snapScaleElementToPixelGrid(
                        mousePos,
                        startPosForScale,
                        clickPoint,
                        isClickEdge,
                        isAlignAxis
                    )
                    mousePos.copy(newMousePos)
                    dx = mousePos.x - startPosForScale.x
                    dy = mousePos.y - startPosForScale.y
                }
                if (snapToObject) {
                    const snapToElementPos = snapping.comparingVerticesWithResize(
                        mousePos,
                        startPosForScale,
                        clickPoint,
                        hoveredHandle.anchor,
                        snapToGrid,
                        isAlignAxis,
                        Snapping.ResizeTypes.SCALE_ONE_ELEMENT,
                        keys.shift
                    )
                    snapToElementPos.sub(startPosForScale)
                    dx = snapToElementPos.x
                    dy = snapToElementPos.y
                }
                const keepAspect = new Vector2(1, 1)
                // TODO: need to clean up the logic and can use scaleFlag in RenderItem
                const bothTargetScaleZero = (approxEqAbs(targetScale.x, 0, 1e-3) && approxEqAbs(targetScale.y, 0, 1e-3))
                const bothTargetScaleNotZero = (!approxEqAbs(targetScale.x, 0, 1e-3) && !approxEqAbs(targetScale.y, 0, 1e-3))
                const canShift = keys.shift
                if (canShift && (bothTargetScaleZero || bothTargetScaleNotZero)) {
                    if (isClickEdge) {
                        keepAspect.x = hoveredHandle.anchor.x === 0.5 ? 0 : keepAspect.x
                        keepAspect.y = hoveredHandle.anchor.y === 0.5 ? 0 : keepAspect.y
                    } else {
                        const localMousePos = targetWorldInv.xform(mousePos)
                        const { width, height } = hoveredElement.get('size')
                        const contentAnchor = hoveredElement.get('contentAnchor')
                        const referencePoint = hoveredElement.get('referencePoint')
                        const originX = (referencePoint.x + contentAnchor.x) / width
                        const originY = (referencePoint.y + contentAnchor.y) / height
                        const originPos = new Vector2(originX, originY)
                        const originToMouseDistance = localMousePos.sub(originPos).abs()
                        if (originToMouseDistance.y * targetAspect < originToMouseDistance.x) {
                            keepAspect.x = 0
                        } else {
                            keepAspect.y = 0
                        }
                    }
                }

                // collect info from current dragging target
                handle_origin.set(1.0 - hoveredHandle.anchor.x, 1.0 - hoveredHandle.anchor.y) // shared among all selected elements
                delta.set(dx, dy)
                targetWorldInv.basis_xform(delta, local_delta)
                // TODO: need to clean up the logic, why bothTargetScaleZero condition is needed?
                if (bothTargetScaleNotZero) local_delta.multiply(targetScale.x, targetScale.y)
                local_delta.multiply((handle_origin.x - 0.5) * 2, (handle_origin.y - 0.5) * 2)

                for (const { element } of selection.iter()) {
                    if (element.get('elementType') === ElementType.SCREEN || element.isLocked()) continue
                    const { scaleX, scaleY, sizeFlag } = elementsScale.get(element.get('id'))

                    // TODO: need to clean up the logic and can use scaleFlag in RenderItem
                    const bothScaleZero = (approxEqAbs(scaleX, 0, 1e-3) && approxEqAbs(scaleY, 0, 1e-3))
                    const bothNotScaleZero = (!approxEqAbs(scaleX, 0, 1e-3) && !approxEqAbs(scaleY, 0, 1e-3))
                    const _scaleX = scaleX === 0 ? 1e-12 : scaleX
                    const _scaleY = scaleY === 0 ? 1e-12 : scaleY

                    scale.set(scaleX, scaleY)
                    // get actual size for computation
                    let { width, height } = element.get('size')
                    const contentAnchor = element.get('contentAnchor')
                    const referencePoint = element.get('referencePoint')
                    const originX = (referencePoint.x + contentAnchor.x) / width
                    const originY = (referencePoint.y + contentAnchor.y) / height
                    origin.set(originX, originY)

                    // TODO: need to clean up the logic, why need to use _scaleX and _scaleY?
                    width *= _scaleX
                    height *= _scaleY

                    // fix origin direction for origin/handle overlapping case
                    local_handle_origin.copy(handle_origin)
                    local_origin.copy(origin)
                    if (local_handle_origin.x === 0) {
                        local_handle_origin.x = 1 - local_handle_origin.x
                        local_origin.x = 1 - local_origin.x
                    }
                    if (local_handle_origin.y === 0) {
                        local_handle_origin.y = 1 - local_handle_origin.y
                        local_origin.y = 1 - local_origin.y
                    }
                    if (local_handle_origin.x === local_origin.x) {
                        local_origin.x = 1 - local_origin.x
                    }
                    if (local_handle_origin.y === local_origin.y) {
                        local_origin.y = 1 - local_origin.y
                    }

                    // calculate distance based on motion from/to origin point
                    local_local_delta.copy(local_delta).multiply(Math.sign(local_handle_origin.x - local_origin.x), Math.sign(local_handle_origin.y - local_origin.y))

                    if (!approxEqAbs(local_handle_origin.x, 0.5, 1e-3)) {
                        const Dx = Math.sign(local_handle_origin.x - local_origin.x) * local_local_delta.x
                        scale.x = (width + Dx - width * local_origin.x) / (width * (1.0 - local_origin.x)) * _scaleX
                    }

                    if (!approxEqAbs(local_handle_origin.y, 0.5, 1e-3)) {
                        const Dy = Math.sign(local_handle_origin.y - local_origin.y) * local_local_delta.y
                        scale.y = (height + Dy - height * local_origin.y) / (height * (1.0 - local_origin.y)) * _scaleY
                    }

                    // TODO: need to clean up the logic
                    if (canShift && (bothNotScaleZero || bothScaleZero) && (sizeFlag.w === '!0' && sizeFlag.h === '!0')) {
                        const aspect = bothScaleZero ? (height / width) : (scaleX / scaleY)
                        if (keepAspect.x === 0) {
                            scale.x  = scale.y * aspect
                        } else {
                            scale.y  = scale.x / aspect
                        }
                    }
                    if (canShift && !bothScaleZero) {
                        if (approxEqAbs(scaleX, 0, 1e-3)) scale.x = 0
                        if (approxEqAbs(scaleY, 0, 1e-3)) scale.y = 0
                    }
                    if (canShift && bothScaleZero && (sizeFlag.w === 'f0' || sizeFlag.h === 'f0' || sizeFlag.w === 't0' || sizeFlag.h === 't0')) {
                        scale.x = 0
                        scale.y = 0
                    }
                    // zero size specific handling
                    if (sizeFlag.w === 'f0' || sizeFlag.w === 't0') scale.x = scaleX
                    if (sizeFlag.h === 'f0' || sizeFlag.h === 't0') scale.y = scaleY

                    element.set('scale', scale)
                }

                dataStore.endTransaction()
            }
        }
    }

    return handlers
}

/**
 * @param {number} x
 * @param {number} y
 * @param {number} tolerance
 * @returns {boolean}
 */
function approxEqAbs(x, y, tolerance) {
    return Math.abs(x - y) <= tolerance
}
