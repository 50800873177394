import { EntityType } from '@phase-software/types'
import EventEmitter from 'eventemitter3'
import { isNull } from '@phase-software/data-utils'
import { ChangesEvent } from './Setter'

/**
 * Wrapper around a swappable Setter that maintains events, set(s), and get(s), even when underlying Setter changes.
 * @fires 'CHANGE-WATCH'
 * also refires all events from watched Setter
 */
export class Watcher extends EventEmitter {
    /** @param {Setter} [watch] */
    constructor(watch) {
        super()

        this.type = EntityType.WATCHER

        this.fireCallback = (changes, flags) => this.fire('CHANGES', changes, flags)

        // Watcher that is watching this object
        this.watcher = null

        if (watch) {
            this.change(watch, false)
        } else {
            this.watch = null
        }
    }

    isEmpty() {
        return isNull(this.unravelWatcher(this.watch))
    }

    clone() {
        const obj = new this.constructor()
        obj.watch = this.watch.clone()
        obj.watch.watcher = obj 
        return obj
    }

    /** @returns {Setter} current watched setter */
    get watched() {
        return this.watch
    }

    get liftupParent() {
        return this.watch.liftupParent
    }

    set liftupParent(value) {
        this.watch.liftupParent = value
    }

    /** @returns {Setter[]} */
    get children() {
        if (this.watch.children) {
            return this.watch.children
        } else {
            throw new Error('trying to call Watcher.children from a non-Group')
        }
    }

    /**
     * changes which Setter or Watcher is being watched
     * @param {Setter | Watcher} watch
     * @param {boolean} [fireChangeEvent=true]
     * @returns {Setter | Watcher} watch
     */
    change(watch, fireChangeEvent = true) {
        if (watch !== this.watch) {
            let original
            // disconnect old watched Setter / Whatcher
            if (this.watch) {
                this.watch.watcher = null
                original = this.watch
            }
            this.watch = watch
            if (watch) {
                watch.watcher = this
                if (fireChangeEvent) {
                    this.fire('CHANGE-WATCH', { watch, original })

                    // TODO: check if we still need this
                    // if (original) {
                    //     this.diff(original)
                    // }
                }
            }

            // re-assign liftup parents
            if (original) {
                if (watch) {
                    watch.liftupParent = original.liftupParent
                }
                original.liftupParent = null
            }
        }
        return watch
    }

    /**
     * fire from watcher
     * @private
     * @param {string} type
     * @param {any} data
     * @param {object} [options]                whole options object will be passed as 
     *                                              a second parameter of CHANGES event
     * @param {bool} [options.undoable=true]    (DOESN'T do anything in Watcher) set to false to not add event to Undo
     * @param {any} [options.flags=undefined]   used for passing related event IDs
     */
    fire(type, data, options = { undoable: true }) {
        this.emit(type, data, options)
        if (type === 'CHANGES') {
            for (const [key, { value, original }] of data) {
                this.emit(key, value, original)
            }
        }

        // if there's watcher watching this watcher
        if (this.watcher) {
            this.watcher.fire(type, data, options)
        }
    }

    /**
     * traverses a watcher until it finds the original source
     * @param {(Watcher|Setter)} setter
     * @returns {Setter}
     */
    unravelWatcher(setter) {
        let result = setter
        while (result && typeof result.watch !== 'undefined') {
            result = result.watch
        }
        return result
    }

    /**
     * fires all diff events when changing Setters
     * @private
     * @param {Setter} original
     */
    diff(original) {
        const changes = new ChangesEvent()
        const watch = this.unravelWatcher(this.watch)
        if (watch) {
            const originalElement = this.unravelWatcher(original)
            for (const key of Object.keys(watch.data)) {
                // no need to fire changes to the id
                if (key !== 'id') {
                    if (!Object.keys(originalElement.data).includes(key)) {
                        changes.addChange(key, watch.get(key), undefined)
                    } else if (original[key] !== watch.get(key)) {
                        changes.addChange(key, watch.get(key), originalElement.get(key))
                    }
                }
            }
            if (changes.size) {
                this.fire('CHANGES', changes)
            }
        }
    }

    /**
     * sets a variable in the watched Setter
     * @param {string} key
     * @param {*} value
     * @param {object} [options]
     * @returns {boolean} true if actually set (changed) value; false otherwise
     */
    set(key, value, options = { fire: true, undoable: true, force: false }) {
        return this.watch.set(key, value, options)
    }

    /**
     * sets a group of variables
     * @param {object} changes
     * @param {boolean} [options]
     * @returns {object<boolean>}  true for each key that was set (changed)
     */
    sets(changes, options = { fire: true, undoable: true, force: false }) {
        return this.watch.sets(changes, options)
    }

    /**
     * checks if key exists
     * @param {string} key
     * @returns {boolean}
     */
    exists(key) {
        return this.watch.exists(key)
    }

    /**
     * gets a variable
     * @param {string} key
     * @param {boolean} [copy=false]  if true will return a copy of the prop value
     * @returns {*}
     */
    get(key, copy = false) {
        return this.watch.get(key, copy)
    }

    /**
     * gets a group of variables
     * @param {...string} keys
     * @returns {Array<*>}
     */
    gets(...keys) {
        return this.watch.gets(...keys)
    }

    /**
     * gets alias for the key
     * @param  {string} key
     * @returns {{key: string, index: number, setter: Function, getter: Function}}    alias descriptor object
     */
    getAlias(key) {
        return this.watch.getAlias(key)
    }

    /**
     * add a variable to this Setter
     * note: this variable will not be saved; only instance use
     * @param {string} key
     * @param {*} value
     * @returns {*}
     */
    add(key, value) {
        return this.watch.add(key, value)
    }

    addChild(...args) {
        if (this.watch.children) {
            return this.watch.addChild(...args)
        } else {
            throw new Error('trying to Watcher.addChild to a non-Group')
        }
    }

    addChildAt(...args) {
        if (this.watch.children) {
            return this.watch.addChildAt(...args)
        } else {
            throw new Error('trying to Watcher.addChildAt to a non-Group')
        }
    }

    removeChildren(...args) {
        if (this.watch.children) {
            return this.watch.removeChildren(...args)
        } else {
            throw new Error('trying to Watcher.removeChildren to a non-Group')
        }
    }

    removeChild(...args) {
        if (this.watch.children) {
            return this.watch.removeChild(...args)
        } else {
            throw new Error('trying to Watcher.removeChild to a non-Group')
        }
    }
    swapChild(...args) {
        if (this.watch.children) {
            this.watch.swapChild(...args)
        } else {
            throw new Error('trying to Watcher.swapChild to a non-Group')
        }
    }

    /**
     * adds an eventListener for multiple events
     * @param  {...string} args
     * param {Function} callback
     */
    ons(...args) {
        const callback = args[args.length - 1]
        for (let i = 0; i < args.length - 1; i++) {
            this.on(args[i], callback)
        }
    }

    /**
     * adds an eventListener for multiple events
     * @param  {...string} args
     * param {Function} callback
     */
    offs(...args) {
        const callback = args[args.length - 1]
        for (let i = 0; i < args.length - 1; i++) {
            this.off(args[i], callback)
        }
    }

    /**
     * execute a function on the watched variable
     * @param {string} fn function name
     * @param  {...any} args
     * @returns {*}
     */
    execute(fn, ...args) {
        if (this.watched[fn]) {
            return this.watched[fn](...args)
        } else {
            throw new Error(`Trying to execute unsupported ${fn} on ${this.watched.get('type')}`)
        }
    }
}

/** @typedef {import('./Setter').Setter} Setter */
