import PropertyStack from '../PropertyStack'

class SkewYStack extends PropertyStack {
  constructor(elementStack, data) {
    super(elementStack, data)
    this.type = 'SKEW_Y'
    this.key = 'skewY'
    this.dataKey = 'skewY'
    this.animatableProperties = new Set(['skewY'])
  }
}

export default SkewYStack
