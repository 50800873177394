import { BlendMode, EndShape, GrowDirection, ImageMode, JoinShape, TrimPathMode } from '@phase-software/types'

type MapProps = {
  [key: number]: string
}

type NameMapProps = {
  [key: string]: string
}

export const blendMap: MapProps = {
  [BlendMode.PASS_THROUGH]: 'pass_through',
  [BlendMode.NORMAL]: 'normal',
  [BlendMode.DARKEN]: 'darken',
  [BlendMode.MULTIPLY]: 'multiply',
  [BlendMode.COLOR_BURN]: 'color_burn',
  [BlendMode.LIGHTEN]: 'lighten',
  [BlendMode.SCREEN]: 'screen',
  [BlendMode.COLOR_DODGE]: 'color_dodge',
  [BlendMode.OVERLAY]: 'overlay',
  [BlendMode.SOFT_LIGHT]: 'soft_light',
  [BlendMode.HARD_LIGHT]: 'hard_light',
  [BlendMode.DIFFERENCE]: 'difference',
  [BlendMode.EXCLUSION]: 'exclusion',
  [BlendMode.HUE]: 'hue',
  [BlendMode.SATURATION]: 'saturation',
  [BlendMode.COLOR]: 'color',
  [BlendMode.LUMINOSITY]: 'luminosity',
  [BlendMode.DIVIDE]: 'divide',
  [BlendMode.ADD]: 'add',
  [BlendMode.SUBTRACT]: 'subtract',
  [BlendMode.DISSOLVE]: 'dissolve'
}

export const growDirectionMap: MapProps = {
  [GrowDirection.INSIDE]: 'inside',
  [GrowDirection.CENTER]: 'center',
  [GrowDirection.OUTSIDE]: 'outside'
}

export const joinShapeMap: MapProps = {
  [JoinShape.MITER]: 'miter',
  [JoinShape.CONCAVE]: 'concave',
  [JoinShape.ROUND]: 'round',
  [JoinShape.BEVEL]: 'bevel',
  [JoinShape.NONE]: 'none'
}

export const endShapeMap: MapProps = {
  [EndShape.STRAIGHT]: 'straight',
  [EndShape.ROUND]: 'round'
}

export const trimPathModeMap: MapProps = {
  [TrimPathMode.SIMULTANEOUSLY]: 'simultaneously',
  [TrimPathMode.INDIVIDUALLY]: 'individually'
}

export const imageModeMap: MapProps = {
  [ImageMode.FILL]: 'fill',
  [ImageMode.STRETCH]: 'stretch',
  [ImageMode.FIT]: 'fit'
}

export const layerNameMap: NameMapProps = {
  fills: 'fill',
  strokes: 'border'
}

export const layerPropertyNameMap: NameMapProps = {
  opacity: 'Opacity',
  join: 'Join shape',
  dash: 'Dash',
  gap: 'Gap',
  miter: 'Miter angle',
  width: 'Border width',
  ends: 'Ends'
}

export const titleMap: NameMapProps = {
  position: 'Position',
  width: 'Width',
  height: 'Height',
  rotation: 'Rotation',
  cornerRadius: 'Corner Radius',
  contentAnchor: 'Origin',
  scaleX: 'Scale X',
  scaleY: 'Scale Y',
  skewX: 'Skew X',
  skewY: 'Skew Y',
  opacity: 'Opacity',
  'trimPath.start': 'Trim Path - Start',
  'trimPath.end': 'Trim Path - End',
  'trimPath.offset': 'Trim Path - Offset',
  pathMorphing: 'Path shape',
  blendMode: 'Blend',
  motionPath: 'Motion path'
}

export const layerPropertyKeyMap: NameMapProps = {
  opacity: 'opacity',
  join: 'join_shape',
  dash: 'dash',
  gap: 'gap',
  miter: 'miter_angle',
  width: 'border_width',
  ends: 'ends'
}

export const titleKeyMap: NameMapProps = {
  position: 'position',
  width: 'width',
  height: 'height',
  rotation: 'rotation',
  cornerRadius: 'corner_radius',
  contentAnchor: 'origin',
  scaleX: 'scale.x',
  scaleY: 'scale.y',
  skewX: 'skew.x',
  skewY: 'skew.y',
  opacity: 'opacity',
  'trimPath.start': 'trim_path.start',
  'trimPath.end': 'trim_path.end',
  'trimPath.offset': 'trim_path.offset',
  pathMorphing: 'path_shape',
  blendMode: 'blend',
  motionPath: 'motion_path'
}
