import React, { forwardRef } from 'react'
import svgs from './svgs'

export type IconSize = 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl' | 'xxxl' | 'xxxxl'
type IconSizeMap = Record<IconSize, number>
export type IconSizeType = IconSize | number

type IconProps = {
  className?: string
  disabled?: boolean
  interactive?: boolean
  size?: IconSizeType
  useCurrentColor?: boolean
  onClick?: (e: React.MouseEvent) => void
  onMouseDown?: (e: React.MouseEvent) => void
}

type IconWrapperProps = IconProps & {
  name: string
}

export const iconSizeToPixels = (size: IconSizeType): number => {
  if (typeof size === 'number') return size

  const sizeMap: IconSizeMap = {
    xxs: 8,
    xs: 10,
    s: 12,
    m: 14,
    l: 16, // default
    xl: 20,
    xxl: 24,
    xxxl: 28,
    xxxxl: 32
  }

  return sizeMap[size]
}

const getIconClassNames = (
  className: string,
  disabled: boolean,
  interactive: boolean,
  useCurrentColor: boolean
): string => {
  const baseClass = 'inline-block align-top flex-shrink-0'
  const colorClass = useCurrentColor ? '' : 'text-light-overlay-60 '
  const disabledClass = disabled ? 'cursor-not-allowed opacity-40' : ''
  const interactiveClass = interactive && !disabled ? 'cursor-pointer hover:text-white active:text-white' : ''

  return `${baseClass} ${colorClass} ${disabledClass} ${interactiveClass} ${className}`
}

const Icon = forwardRef<HTMLSpanElement, IconWrapperProps>(
  (
    { name, useCurrentColor = false, className = '', size = 'l', interactive = true, disabled = false, ...rest },
    ref
  ) => {
    const SvgIcon = svgs[name] || (() => null)
    const widthHeight = iconSizeToPixels(size)

    return (
      <span
        className={getIconClassNames(className, disabled, interactive, useCurrentColor)}
        style={{ width: `${widthHeight}px`, height: `${widthHeight}px` }}
        ref={ref}
        {...rest}
      >
        <SvgIcon />
      </span>
    )
  }
)

Icon.displayName = 'Icon'

export default React.memo(Icon)
