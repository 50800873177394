import { IRComponent } from './IRComponent'

export class IREffect extends IRComponent {
  constructor(data = {}) {
    super(data)
    this.type = data.type
  }

  toJSON() {
    return {
      ...super.toJSON(),
      type: this.type
    }
  }
}
