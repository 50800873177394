import { vec4 } from 'gl-matrix'
import { EPSILON, isNull, notNull, isNum, vec4Equals } from './commons'

/**
 * Compatibility wrapper for vec4-like objects,
 * i.e objects that have `x`(`r`), `y`('g'), `z`(`b` or `width`), `w`(`a` or `height`) components
 */
export class Vector4 extends Float32Array {
    /**
     * Creates new Vector4 from 4 number components or copies values from a single vec4-like object
     * @param  {number | Vector4 | vec4 | object} x  x component or vec4-like object
     * @param  {number} [y]  y component
     * @param  {number} [z]  z component
     * @param  {number} [w]  w component
     */
    constructor(x, y, z, w) {
        super(4)
        if (typeof x === 'number' && typeof y === 'number' && typeof z === 'number' && typeof w === 'number') {
            vec4.set(this, x, y, z, w)
        } else {
            this.copy(x)
        }
    }

    /**
     * Copies values from the vec4-like object
     * @param  {Vector4 | vec4 | object} val
     */
    copy(val) {
        if (isNull(val)) {
            return
        }
        let xx = notNull(val[0]) ? val[0] : val.x
        if (isNull(xx)) {
            xx = val.r
        }
        let yy = notNull(val[1]) ? val[1] : val.y
        if (isNull(yy)) {
            yy = val.g
        }
        let zz = notNull(val[2]) ? val[2] : val.z
        if (isNull(zz)) {
            zz = notNull(val.b) ? val.b : val.width
        }
        let ww = notNull(val[3]) ? val[3] : val.w
        if (isNull(ww)) {
            ww = notNull(val.a) ? val.a : val.height
        }
        if (isNum(xx) && isNum(yy) && isNum(zz) && isNum(ww)) {
            vec4.set(this, xx, yy, zz, ww)
        }
    }

    /**
     * Checks if this Vector4 is equal (has same corresponding component values) to anothe vec4-like object
     * @param {Vector4 | vec4 | object} val     vec4-like object
     * @param {number} epsilon                  precision; default is 0.0001
     * @returns {boolean}                        true if vectors are equal; false othewise
     */
    eq(val, epsilon = EPSILON) {
        if (isNull(val)) {
            return false
        }
        let xx = notNull(val[0]) ? val[0] : val.x
        if (isNull(xx)) {
            xx = val.r
        }
        let yy = notNull(val[1]) ? val[1] : val.y
        if (isNull(yy)) {
            yy = val.g
        }
        let zz = notNull(val[2]) ? val[2] : val.z
        if (isNull(zz)) {
            zz = notNull(val.b) ? val.b : val.width
        }
        let ww = notNull(val[3]) ? val[3] : val.w
        if (isNull(ww)) {
            ww = notNull(val.a) ? val.a : val.height
        }
        return vec4Equals(this, [xx, yy, zz, ww], epsilon)
    }

    get x() {
        return this[0]
    }
    set x(val) {
        if (isNaN(val)) {
            throw new Error('Trying to assign NaN to x component of Vector4')
        }
        this[0] = val
    }

    get y() {
        return this[1]
    }
    set y(val) {
        if (isNaN(val)) {
            throw new Error('Trying to assign NaN to y component of Vector4')
        }
        this[1] = val
    }

    get z() {
        return this[2]
    }
    set z(val) {
        if (isNaN(val)) {
            throw new Error('Trying to assign NaN to z component of Vector4')
        }
        this[2] = val
    }

    get w() {
        return this[3]
    }
    set w(val) {
        if (isNaN(val)) {
            throw new Error('Trying to assign NaN to w component of Vector4')
        }
        this[3] = val
    }

    get r() {
        return this[0]
    }
    set r(val) {
        if (isNaN(val)) {
            throw new Error('Trying to assign NaN to r component of Vector4')
        }
        this[0] = val
    }

    get g() {
        return this[1]
    }
    set g(val) {
        if (isNaN(val)) {
            throw new Error('Trying to assign NaN to g component of Vector4')
        }
        this[1] = val
    }

    get b() {
        return this[2]
    }
    set b(val) {
        if (isNaN(val)) {
            throw new Error('Trying to assign NaN to b component of Vector4')
        }
        this[2] = val
    }

    get a() {
        return this[3]
    }
    set a(val) {
        if (isNaN(val)) {
            throw new Error('Trying to assign NaN to a component of Vector4')
        }
        this[3] = val
    }

    get width() {
        return this[2]
    }
    set width(val) {
        if (isNaN(val)) {
            throw new Error('Trying to assign NaN to width component of Vector4')
        }
        this[2] = val
    }

    get height() {
        return this[3]
    }
    set height(val) {
        if (isNaN(val)) {
            throw new Error('Trying to assign NaN to height component of Vector4')
        }
        this[3] = val
    }
}

Vector4.ZERO = new Vector4(0, 0, 0, 0)
Vector4.ONE = new Vector4(1, 1, 1, 1)
