/* tslint:disable */
/* eslint-disable */
/**
 * 
 * @export
 * @interface AddProjectMemberRequest
 */
export interface AddProjectMemberRequest {
    /**
     * 
     * @type {string}
     * @memberof AddProjectMemberRequest
     */
    memberId: string;
    /**
     * 
     * @type {string}
     * @memberof AddProjectMemberRequest
     */
    permission: AddProjectMemberRequestPermissionEnum;
}


/**
 * @export
 */
export const AddProjectMemberRequestPermissionEnum = {
    CanEdit: 'CAN_EDIT',
    CanReview: 'CAN_REVIEW',
    NoAccess: 'NO_ACCESS'
} as const;
export type AddProjectMemberRequestPermissionEnum = typeof AddProjectMemberRequestPermissionEnum[keyof typeof AddProjectMemberRequestPermissionEnum];

/**
 * 
 * @export
 * @interface ChangePassword
 */
export interface ChangePassword {
    /**
     * 
     * @type {string}
     * @memberof ChangePassword
     */
    oldPassword: string;
    /**
     * 
     * @type {string}
     * @memberof ChangePassword
     */
    newPassword: string;
    /**
     * 
     * @type {string}
     * @memberof ChangePassword
     */
    accessToken: string;
}
/**
 * 
 * @export
 * @interface ConfirmDeleteIntentRequest
 */
export interface ConfirmDeleteIntentRequest {
    /**
     * 
     * @type {string}
     * @memberof ConfirmDeleteIntentRequest
     */
    code: string;
}
/**
 * 
 * @export
 * @interface ConfirmOwnershipTransferIntentRequest
 */
export interface ConfirmOwnershipTransferIntentRequest {
    /**
     * 
     * @type {string}
     * @memberof ConfirmOwnershipTransferIntentRequest
     */
    code: string;
}
/**
 * 
 * @export
 * @interface CreateAutoVersionResponse
 */
export interface CreateAutoVersionResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateAutoVersionResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAutoVersionResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAutoVersionResponse
     */
    creatorId: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAutoVersionResponse
     */
    isNewVersion: boolean;
}
/**
 * 
 * @export
 * @interface CreateDeleteIntentResponse
 */
export interface CreateDeleteIntentResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateDeleteIntentResponse
     */
    id: string;
}
/**
 * 
 * @export
 * @interface CreateFileFromAVersionRequest
 */
export interface CreateFileFromAVersionRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateFileFromAVersionRequest
     */
    fileName: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateFileFromAVersionRequest
     */
    copyCollaborators: boolean;
}
/**
 * 
 * @export
 * @interface CreateFileFromAVersionResponse
 */
export interface CreateFileFromAVersionResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateFileFromAVersionResponse
     */
    fileId: string;
}
/**
 * 
 * @export
 * @interface CreateInvitationResponse
 */
export interface CreateInvitationResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateInvitationResponse
     */
    id: string;
}
/**
 * 
 * @export
 * @interface CreateNamedVersionRequestV1
 */
export interface CreateNamedVersionRequestV1 {
    /**
     * 
     * @type {string}
     * @memberof CreateNamedVersionRequestV1
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateNamedVersionRequestV1
     */
    description: string;
}
/**
 * 
 * @export
 * @interface CreateNamedVersionResponse
 */
export interface CreateNamedVersionResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateNamedVersionResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CreateNamedVersionResponse
     */
    name: string;
}
/**
 * 
 * @export
 * @interface CreateOrLinkUserRequest
 */
export interface CreateOrLinkUserRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateOrLinkUserRequest
     */
    externalId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrLinkUserRequest
     */
    externalProvider: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrLinkUserRequest
     */
    avatar?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrLinkUserRequest
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrLinkUserRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrLinkUserRequest
     */
    workspaceIcon?: string;
    /**
     * 
     * @type {object}
     * @memberof CreateOrLinkUserRequest
     */
    locale?: object;
}
/**
 * 
 * @export
 * @interface CreateOwnershipTransferIntentRequest
 */
export interface CreateOwnershipTransferIntentRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateOwnershipTransferIntentRequest
     */
    toUserId: string;
}
/**
 * 
 * @export
 * @interface CreateProjectCollaboratorInvitationRequest
 */
export interface CreateProjectCollaboratorInvitationRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateProjectCollaboratorInvitationRequest
     */
    permission: CreateProjectCollaboratorInvitationRequestPermissionEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectCollaboratorInvitationRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectCollaboratorInvitationRequest
     */
    collaboratorId?: string;
}


/**
 * @export
 */
export const CreateProjectCollaboratorInvitationRequestPermissionEnum = {
    CanEdit: 'CAN_EDIT',
    CanReview: 'CAN_REVIEW',
    NoAccess: 'NO_ACCESS'
} as const;
export type CreateProjectCollaboratorInvitationRequestPermissionEnum = typeof CreateProjectCollaboratorInvitationRequestPermissionEnum[keyof typeof CreateProjectCollaboratorInvitationRequestPermissionEnum];

/**
 * 
 * @export
 * @interface CreateProjectFileCollaboratorInvitationRequest
 */
export interface CreateProjectFileCollaboratorInvitationRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateProjectFileCollaboratorInvitationRequest
     */
    collaboratorId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectFileCollaboratorInvitationRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectFileCollaboratorInvitationRequest
     */
    permission: CreateProjectFileCollaboratorInvitationRequestPermissionEnum;
}


/**
 * @export
 */
export const CreateProjectFileCollaboratorInvitationRequestPermissionEnum = {
    Edit: 'CAN_EDIT',
    Review: 'CAN_REVIEW'
} as const;
export type CreateProjectFileCollaboratorInvitationRequestPermissionEnum = typeof CreateProjectFileCollaboratorInvitationRequestPermissionEnum[keyof typeof CreateProjectFileCollaboratorInvitationRequestPermissionEnum];

/**
 * 
 * @export
 * @interface CreateProjectFileRequest
 */
export interface CreateProjectFileRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateProjectFileRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectFileRequest
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectFileRequest
     */
    author?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectFileRequest
     */
    authorSource?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateProjectFileRequest
     */
    feature?: number;
}
/**
 * 
 * @export
 * @interface CreateProjectFileResponse
 */
export interface CreateProjectFileResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateProjectFileResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectFileResponse
     */
    permission: CreateProjectFileResponsePermissionEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectFileResponse
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectFileResponse
     */
    creatorId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectFileResponse
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectFileResponse
     */
    fileKey: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectFileResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectFileResponse
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectFileResponse
     */
    status: CreateProjectFileResponseStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectFileResponse
     */
    thumbnailKey: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectFileResponse
     */
    thumbnailUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectFileResponse
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectFileResponse
     */
    author: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectFileResponse
     */
    authorSource: string;
    /**
     * 
     * @type {number}
     * @memberof CreateProjectFileResponse
     */
    feature: number;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectFileResponse
     */
    figmaExportKey: string;
    /**
     * 
     * @type {number}
     * @memberof CreateProjectFileResponse
     */
    isPluginExport: number;
}


/**
 * @export
 */
export const CreateProjectFileResponsePermissionEnum = {
    Null: 'NULL',
    CanAccess: 'CAN_ACCESS',
    ReviewOnly: 'REVIEW_ONLY',
    NoAccess: 'NO_ACCESS'
} as const;
export type CreateProjectFileResponsePermissionEnum = typeof CreateProjectFileResponsePermissionEnum[keyof typeof CreateProjectFileResponsePermissionEnum];

/**
 * @export
 */
export const CreateProjectFileResponseStatusEnum = {
    Archive: 'ARCHIVE',
    Normal: 'NORMAL'
} as const;
export type CreateProjectFileResponseStatusEnum = typeof CreateProjectFileResponseStatusEnum[keyof typeof CreateProjectFileResponseStatusEnum];

/**
 * 
 * @export
 * @interface CreateProjectRequest
 */
export interface CreateProjectRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateProjectRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectRequest
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectRequest
     */
    icon?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectRequest
     */
    permission: CreateProjectRequestPermissionEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectRequest
     */
    status: CreateProjectRequestStatusEnum;
}


/**
 * @export
 */
export const CreateProjectRequestPermissionEnum = {
    InviteOnly: 'INVITE_ONLY',
    CanEdit: 'CAN_EDIT',
    CanReview: 'CAN_REVIEW',
    NoAccess: 'NO_ACCESS'
} as const;
export type CreateProjectRequestPermissionEnum = typeof CreateProjectRequestPermissionEnum[keyof typeof CreateProjectRequestPermissionEnum];

/**
 * @export
 */
export const CreateProjectRequestStatusEnum = {
    Archive: 'ARCHIVE',
    Normal: 'NORMAL',
    Draft: 'DRAFT',
    Template: 'TEMPLATE'
} as const;
export type CreateProjectRequestStatusEnum = typeof CreateProjectRequestStatusEnum[keyof typeof CreateProjectRequestStatusEnum];

/**
 * 
 * @export
 * @interface CreateProjectResponse
 */
export interface CreateProjectResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateProjectResponse
     */
    id: string;
}
/**
 * 
 * @export
 * @interface CreateTeamInvitationRequest
 */
export interface CreateTeamInvitationRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateTeamInvitationRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTeamInvitationRequest
     */
    inviteeId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTeamInvitationRequest
     */
    teamRole: CreateTeamInvitationRequestTeamRoleEnum;
}


/**
 * @export
 */
export const CreateTeamInvitationRequestTeamRoleEnum = {
    Owner: 'OWNER',
    Admin: 'ADMIN',
    Member: 'MEMBER',
    Guest: 'GUEST'
} as const;
export type CreateTeamInvitationRequestTeamRoleEnum = typeof CreateTeamInvitationRequestTeamRoleEnum[keyof typeof CreateTeamInvitationRequestTeamRoleEnum];

/**
 * 
 * @export
 * @interface CreateTeamRequest
 */
export interface CreateTeamRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateTeamRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTeamRequest
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTeamRequest
     */
    location?: string;
}
/**
 * 
 * @export
 * @interface CreateTimeResponse
 */
export interface CreateTimeResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateTimeResponse
     */
    id: string;
}
/**
 * 
 * @export
 * @interface CreateTransferIntentResponse
 */
export interface CreateTransferIntentResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateTransferIntentResponse
     */
    id: string;
}
/**
 * 
 * @export
 * @interface CreateUserGroupRequest
 */
export interface CreateUserGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateUserGroupRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserGroupRequest
     */
    description?: string;
}
/**
 * 
 * @export
 * @interface CreateUserResponse
 */
export interface CreateUserResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateUserResponse
     */
    id: string;
}
/**
 * 
 * @export
 * @interface CreateVersionContributorRequest
 */
export interface CreateVersionContributorRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateVersionContributorRequest
     */
    userId: string;
    /**
     * 
     * @type {number}
     * @memberof CreateVersionContributorRequest
     */
    editCounts: number;
}
/**
 * 
 * @export
 * @interface CreateVersionContributorResponse
 */
export interface CreateVersionContributorResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateVersionContributorResponse
     */
    versionId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateVersionContributorResponse
     */
    fileId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateVersionContributorResponse
     */
    userId: string;
    /**
     * 
     * @type {number}
     * @memberof CreateVersionContributorResponse
     */
    editCounts: number;
}
/**
 * 
 * @export
 * @interface DownloadContentResponse
 */
export interface DownloadContentResponse {
    /**
     * 
     * @type {object}
     * @memberof DownloadContentResponse
     */
    content: object;
}
/**
 * 
 * @export
 * @interface DownloadContentResponseV2
 */
export interface DownloadContentResponseV2 {
    /**
     * 
     * @type {string}
     * @memberof DownloadContentResponseV2
     */
    content: string;
}
/**
 * 
 * @export
 * @interface DuplicateProjectFileRequest
 */
export interface DuplicateProjectFileRequest {
    /**
     * 
     * @type {string}
     * @memberof DuplicateProjectFileRequest
     */
    name: string;
}
/**
 * 
 * @export
 * @interface DuplicateProjectFileResponse
 */
export interface DuplicateProjectFileResponse {
    /**
     * 
     * @type {string}
     * @memberof DuplicateProjectFileResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DuplicateProjectFileResponse
     */
    permission: DuplicateProjectFileResponsePermissionEnum;
    /**
     * 
     * @type {string}
     * @memberof DuplicateProjectFileResponse
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof DuplicateProjectFileResponse
     */
    creatorId: string;
    /**
     * 
     * @type {string}
     * @memberof DuplicateProjectFileResponse
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof DuplicateProjectFileResponse
     */
    fileKey: string;
    /**
     * 
     * @type {string}
     * @memberof DuplicateProjectFileResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DuplicateProjectFileResponse
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof DuplicateProjectFileResponse
     */
    status: DuplicateProjectFileResponseStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof DuplicateProjectFileResponse
     */
    thumbnailKey: string;
    /**
     * 
     * @type {string}
     * @memberof DuplicateProjectFileResponse
     */
    thumbnailUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof DuplicateProjectFileResponse
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof DuplicateProjectFileResponse
     */
    author: string;
    /**
     * 
     * @type {string}
     * @memberof DuplicateProjectFileResponse
     */
    authorSource: string;
    /**
     * 
     * @type {number}
     * @memberof DuplicateProjectFileResponse
     */
    feature: number;
    /**
     * 
     * @type {string}
     * @memberof DuplicateProjectFileResponse
     */
    figmaExportKey: string;
    /**
     * 
     * @type {number}
     * @memberof DuplicateProjectFileResponse
     */
    isPluginExport: number;
}


/**
 * @export
 */
export const DuplicateProjectFileResponsePermissionEnum = {
    Null: 'NULL',
    CanAccess: 'CAN_ACCESS',
    ReviewOnly: 'REVIEW_ONLY',
    NoAccess: 'NO_ACCESS'
} as const;
export type DuplicateProjectFileResponsePermissionEnum = typeof DuplicateProjectFileResponsePermissionEnum[keyof typeof DuplicateProjectFileResponsePermissionEnum];

/**
 * @export
 */
export const DuplicateProjectFileResponseStatusEnum = {
    Archive: 'ARCHIVE',
    Normal: 'NORMAL'
} as const;
export type DuplicateProjectFileResponseStatusEnum = typeof DuplicateProjectFileResponseStatusEnum[keyof typeof DuplicateProjectFileResponseStatusEnum];

/**
 * 
 * @export
 * @interface DuplicateTemplateRequest
 */
export interface DuplicateTemplateRequest {
    /**
     * 
     * @type {string}
     * @memberof DuplicateTemplateRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DuplicateTemplateRequest
     */
    destinationProjectId: string;
}
/**
 * 
 * @export
 * @interface DuplicateTemplateResponse
 */
export interface DuplicateTemplateResponse {
    /**
     * 
     * @type {string}
     * @memberof DuplicateTemplateResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DuplicateTemplateResponse
     */
    permission: DuplicateTemplateResponsePermissionEnum;
    /**
     * 
     * @type {string}
     * @memberof DuplicateTemplateResponse
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof DuplicateTemplateResponse
     */
    creatorId: string;
    /**
     * 
     * @type {string}
     * @memberof DuplicateTemplateResponse
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof DuplicateTemplateResponse
     */
    fileKey: string;
    /**
     * 
     * @type {string}
     * @memberof DuplicateTemplateResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DuplicateTemplateResponse
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof DuplicateTemplateResponse
     */
    status: DuplicateTemplateResponseStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof DuplicateTemplateResponse
     */
    thumbnailKey: string;
    /**
     * 
     * @type {string}
     * @memberof DuplicateTemplateResponse
     */
    thumbnailUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof DuplicateTemplateResponse
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof DuplicateTemplateResponse
     */
    author: string;
    /**
     * 
     * @type {string}
     * @memberof DuplicateTemplateResponse
     */
    authorSource: string;
    /**
     * 
     * @type {number}
     * @memberof DuplicateTemplateResponse
     */
    feature: number;
    /**
     * 
     * @type {string}
     * @memberof DuplicateTemplateResponse
     */
    figmaExportKey: string;
    /**
     * 
     * @type {number}
     * @memberof DuplicateTemplateResponse
     */
    isPluginExport: number;
}


/**
 * @export
 */
export const DuplicateTemplateResponsePermissionEnum = {
    Null: 'NULL',
    CanAccess: 'CAN_ACCESS',
    ReviewOnly: 'REVIEW_ONLY',
    NoAccess: 'NO_ACCESS'
} as const;
export type DuplicateTemplateResponsePermissionEnum = typeof DuplicateTemplateResponsePermissionEnum[keyof typeof DuplicateTemplateResponsePermissionEnum];

/**
 * @export
 */
export const DuplicateTemplateResponseStatusEnum = {
    Archive: 'ARCHIVE',
    Normal: 'NORMAL'
} as const;
export type DuplicateTemplateResponseStatusEnum = typeof DuplicateTemplateResponseStatusEnum[keyof typeof DuplicateTemplateResponseStatusEnum];

/**
 * 
 * @export
 * @interface FilePermissionResponse
 */
export interface FilePermissionResponse {
    /**
     * 
     * @type {boolean}
     * @memberof FilePermissionResponse
     */
    edit: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FilePermissionResponse
     */
    view: boolean;
}
/**
 * 
 * @export
 * @interface GenerateFileOperateUrlRequest
 */
export interface GenerateFileOperateUrlRequest {
    /**
     * 
     * @type {string}
     * @memberof GenerateFileOperateUrlRequest
     */
    contentType: string;
}
/**
 * 
 * @export
 * @interface GenerateFileOperateUrlResponse
 */
export interface GenerateFileOperateUrlResponse {
    /**
     * 
     * @type {string}
     * @memberof GenerateFileOperateUrlResponse
     */
    uploadUrl: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateFileOperateUrlResponse
     */
    downloadUrl: string;
}
/**
 * 
 * @export
 * @interface GetProjectPermissionResponse
 */
export interface GetProjectPermissionResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectPermissionResponse
     */
    canDelete: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectPermissionResponse
     */
    edit: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectPermissionResponse
     */
    view: boolean;
}
/**
 * 
 * @export
 * @interface IdResponse
 */
export interface IdResponse {
    /**
     * 
     * @type {string}
     * @memberof IdResponse
     */
    id: string;
}
/**
 * 
 * @export
 * @interface ImportProjectFileResponse
 */
export interface ImportProjectFileResponse {
    /**
     * 
     * @type {string}
     * @memberof ImportProjectFileResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ImportProjectFileResponse
     */
    permission: ImportProjectFileResponsePermissionEnum;
    /**
     * 
     * @type {string}
     * @memberof ImportProjectFileResponse
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof ImportProjectFileResponse
     */
    creatorId: string;
    /**
     * 
     * @type {string}
     * @memberof ImportProjectFileResponse
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof ImportProjectFileResponse
     */
    fileKey: string;
    /**
     * 
     * @type {string}
     * @memberof ImportProjectFileResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ImportProjectFileResponse
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof ImportProjectFileResponse
     */
    status: ImportProjectFileResponseStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ImportProjectFileResponse
     */
    thumbnailKey: string;
    /**
     * 
     * @type {string}
     * @memberof ImportProjectFileResponse
     */
    thumbnailUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportProjectFileResponse
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof ImportProjectFileResponse
     */
    author: string;
    /**
     * 
     * @type {string}
     * @memberof ImportProjectFileResponse
     */
    authorSource: string;
    /**
     * 
     * @type {number}
     * @memberof ImportProjectFileResponse
     */
    feature: number;
    /**
     * 
     * @type {string}
     * @memberof ImportProjectFileResponse
     */
    figmaExportKey: string;
    /**
     * 
     * @type {number}
     * @memberof ImportProjectFileResponse
     */
    isPluginExport: number;
}


/**
 * @export
 */
export const ImportProjectFileResponsePermissionEnum = {
    Null: 'NULL',
    CanAccess: 'CAN_ACCESS',
    ReviewOnly: 'REVIEW_ONLY',
    NoAccess: 'NO_ACCESS'
} as const;
export type ImportProjectFileResponsePermissionEnum = typeof ImportProjectFileResponsePermissionEnum[keyof typeof ImportProjectFileResponsePermissionEnum];

/**
 * @export
 */
export const ImportProjectFileResponseStatusEnum = {
    Archive: 'ARCHIVE',
    Normal: 'NORMAL'
} as const;
export type ImportProjectFileResponseStatusEnum = typeof ImportProjectFileResponseStatusEnum[keyof typeof ImportProjectFileResponseStatusEnum];

/**
 * 
 * @export
 * @interface ImportProjectImageResponse
 */
export interface ImportProjectImageResponse {
    /**
     * 
     * @type {string}
     * @memberof ImportProjectImageResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ImportProjectImageResponse
     */
    creatorId: string;
    /**
     * 
     * @type {string}
     * @memberof ImportProjectImageResponse
     */
    download_url: string;
}
/**
 * 
 * @export
 * @interface InternalDeleteFilesRequest
 */
export interface InternalDeleteFilesRequest {
    /**
     * 
     * @type {string}
     * @memberof InternalDeleteFilesRequest
     */
    projectId: string;
}
/**
 * 
 * @export
 * @interface MailInWhiteListResponse
 */
export interface MailInWhiteListResponse {
    /**
     * 
     * @type {boolean}
     * @memberof MailInWhiteListResponse
     */
    isInWhiteList: boolean;
}
/**
 * 
 * @export
 * @interface ModifyProjectOwnership
 */
export interface ModifyProjectOwnership {
    /**
     * 
     * @type {string}
     * @memberof ModifyProjectOwnership
     */
    ownerId: string;
}
/**
 * 
 * @export
 * @interface MoveFileBetweenProjectsRequest
 */
export interface MoveFileBetweenProjectsRequest {
    /**
     * 
     * @type {string}
     * @memberof MoveFileBetweenProjectsRequest
     */
    targetProjectId: string;
}
/**
 * 
 * @export
 * @interface MoveFileBetweenProjectsResponse
 */
export interface MoveFileBetweenProjectsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof MoveFileBetweenProjectsResponse
     */
    success: boolean;
}
/**
 * 
 * @export
 * @interface PatchFileRequest
 */
export interface PatchFileRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchFileRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchFileRequest
     */
    status?: PatchFileRequestStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PatchFileRequest
     */
    permission?: PatchFileRequestPermissionEnum;
    /**
     * 
     * @type {string}
     * @memberof PatchFileRequest
     */
    draftPermission?: PatchFileRequestDraftPermissionEnum;
    /**
     * 
     * @type {string}
     * @memberof PatchFileRequest
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchFileRequest
     */
    thumbnailUrl?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatchFileRequest
     */
    updateMask: Array<PatchFileRequestUpdateMaskEnum>;
}


/**
 * @export
 */
export const PatchFileRequestStatusEnum = {
    Archive: 'ARCHIVE',
    Normal: 'NORMAL'
} as const;
export type PatchFileRequestStatusEnum = typeof PatchFileRequestStatusEnum[keyof typeof PatchFileRequestStatusEnum];

/**
 * @export
 */
export const PatchFileRequestPermissionEnum = {
    Null: 'NULL',
    CanAccess: 'CAN_ACCESS',
    ReviewOnly: 'REVIEW_ONLY',
    NoAccess: 'NO_ACCESS'
} as const;
export type PatchFileRequestPermissionEnum = typeof PatchFileRequestPermissionEnum[keyof typeof PatchFileRequestPermissionEnum];

/**
 * @export
 */
export const PatchFileRequestDraftPermissionEnum = {
    CanEdit: 'CAN_EDIT',
    CanReview: 'CAN_REVIEW',
    NoAccess: 'NO_ACCESS'
} as const;
export type PatchFileRequestDraftPermissionEnum = typeof PatchFileRequestDraftPermissionEnum[keyof typeof PatchFileRequestDraftPermissionEnum];

/**
 * @export
 */
export const PatchFileRequestUpdateMaskEnum = {
    Name: 'name',
    Status: 'status',
    Permission: 'permission',
    DraftPermission: 'draftPermission',
    Description: 'description',
    ThumbnailUrl: 'thumbnailUrl'
} as const;
export type PatchFileRequestUpdateMaskEnum = typeof PatchFileRequestUpdateMaskEnum[keyof typeof PatchFileRequestUpdateMaskEnum];

/**
 * 
 * @export
 * @interface PatchProjectFileCollaboratorRequest
 */
export interface PatchProjectFileCollaboratorRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchProjectFileCollaboratorRequest
     */
    permission?: PatchProjectFileCollaboratorRequestPermissionEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatchProjectFileCollaboratorRequest
     */
    updateMask: Array<PatchProjectFileCollaboratorRequestUpdateMaskEnum>;
}


/**
 * @export
 */
export const PatchProjectFileCollaboratorRequestPermissionEnum = {
    Edit: 'CAN_EDIT',
    Review: 'CAN_REVIEW'
} as const;
export type PatchProjectFileCollaboratorRequestPermissionEnum = typeof PatchProjectFileCollaboratorRequestPermissionEnum[keyof typeof PatchProjectFileCollaboratorRequestPermissionEnum];

/**
 * @export
 */
export const PatchProjectFileCollaboratorRequestUpdateMaskEnum = {
    Permission: 'permission'
} as const;
export type PatchProjectFileCollaboratorRequestUpdateMaskEnum = typeof PatchProjectFileCollaboratorRequestUpdateMaskEnum[keyof typeof PatchProjectFileCollaboratorRequestUpdateMaskEnum];

/**
 * 
 * @export
 * @interface PatchProjectMemberRequest
 */
export interface PatchProjectMemberRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchProjectMemberRequest
     */
    permission?: PatchProjectMemberRequestPermissionEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatchProjectMemberRequest
     */
    updateMask: Array<PatchProjectMemberRequestUpdateMaskEnum>;
}


/**
 * @export
 */
export const PatchProjectMemberRequestPermissionEnum = {
    CanEdit: 'CAN_EDIT',
    CanReview: 'CAN_REVIEW',
    NoAccess: 'NO_ACCESS'
} as const;
export type PatchProjectMemberRequestPermissionEnum = typeof PatchProjectMemberRequestPermissionEnum[keyof typeof PatchProjectMemberRequestPermissionEnum];

/**
 * @export
 */
export const PatchProjectMemberRequestUpdateMaskEnum = {
    Permission: 'permission'
} as const;
export type PatchProjectMemberRequestUpdateMaskEnum = typeof PatchProjectMemberRequestUpdateMaskEnum[keyof typeof PatchProjectMemberRequestUpdateMaskEnum];

/**
 * 
 * @export
 * @interface PatchProjectRequest
 */
export interface PatchProjectRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchProjectRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchProjectRequest
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchProjectRequest
     */
    status?: PatchProjectRequestStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PatchProjectRequest
     */
    icon?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchProjectRequest
     */
    permission?: PatchProjectRequestPermissionEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatchProjectRequest
     */
    updateMask: Array<PatchProjectRequestUpdateMaskEnum>;
}


/**
 * @export
 */
export const PatchProjectRequestStatusEnum = {
    Archive: 'ARCHIVE',
    Normal: 'NORMAL',
    Draft: 'DRAFT',
    Template: 'TEMPLATE'
} as const;
export type PatchProjectRequestStatusEnum = typeof PatchProjectRequestStatusEnum[keyof typeof PatchProjectRequestStatusEnum];

/**
 * @export
 */
export const PatchProjectRequestPermissionEnum = {
    InviteOnly: 'INVITE_ONLY',
    CanEdit: 'CAN_EDIT',
    CanReview: 'CAN_REVIEW',
    NoAccess: 'NO_ACCESS'
} as const;
export type PatchProjectRequestPermissionEnum = typeof PatchProjectRequestPermissionEnum[keyof typeof PatchProjectRequestPermissionEnum];

/**
 * @export
 */
export const PatchProjectRequestUpdateMaskEnum = {
    Name: 'name',
    Description: 'description',
    Status: 'status',
    Icon: 'icon',
    Permission: 'permission'
} as const;
export type PatchProjectRequestUpdateMaskEnum = typeof PatchProjectRequestUpdateMaskEnum[keyof typeof PatchProjectRequestUpdateMaskEnum];

/**
 * 
 * @export
 * @interface PatchTeamRequest
 */
export interface PatchTeamRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchTeamRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchTeamRequest
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchTeamRequest
     */
    location?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatchTeamRequest
     */
    updateMask: Array<PatchTeamRequestUpdateMaskEnum>;
}


/**
 * @export
 */
export const PatchTeamRequestUpdateMaskEnum = {
    Name: 'name',
    Description: 'description',
    Location: 'location'
} as const;
export type PatchTeamRequestUpdateMaskEnum = typeof PatchTeamRequestUpdateMaskEnum[keyof typeof PatchTeamRequestUpdateMaskEnum];

/**
 * 
 * @export
 * @interface PatchTeamUserRequest
 */
export interface PatchTeamUserRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchTeamUserRequest
     */
    teamRole?: PatchTeamUserRequestTeamRoleEnum;
    /**
     * 
     * @type {boolean}
     * @memberof PatchTeamUserRequest
     */
    deactivated?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatchTeamUserRequest
     */
    updateMask: Array<PatchTeamUserRequestUpdateMaskEnum>;
}


/**
 * @export
 */
export const PatchTeamUserRequestTeamRoleEnum = {
    Owner: 'OWNER',
    Admin: 'ADMIN',
    Member: 'MEMBER',
    Guest: 'GUEST'
} as const;
export type PatchTeamUserRequestTeamRoleEnum = typeof PatchTeamUserRequestTeamRoleEnum[keyof typeof PatchTeamUserRequestTeamRoleEnum];

/**
 * @export
 */
export const PatchTeamUserRequestUpdateMaskEnum = {
    TeamRole: 'teamRole',
    Deactivated: 'deactivated'
} as const;
export type PatchTeamUserRequestUpdateMaskEnum = typeof PatchTeamUserRequestUpdateMaskEnum[keyof typeof PatchTeamUserRequestUpdateMaskEnum];

/**
 * 
 * @export
 * @interface PatchUserGroupRequest
 */
export interface PatchUserGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchUserGroupRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchUserGroupRequest
     */
    description?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatchUserGroupRequest
     */
    updateMask: Array<PatchUserGroupRequestUpdateMaskEnum>;
}


/**
 * @export
 */
export const PatchUserGroupRequestUpdateMaskEnum = {
    Name: 'name',
    Description: 'description'
} as const;
export type PatchUserGroupRequestUpdateMaskEnum = typeof PatchUserGroupRequestUpdateMaskEnum[keyof typeof PatchUserGroupRequestUpdateMaskEnum];

/**
 * 
 * @export
 * @interface PatchUserRequest
 */
export interface PatchUserRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchUserRequest
     */
    avatar?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchUserRequest
     */
    workspaceIcon?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchUserRequest
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchUserRequest
     */
    email?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatchUserRequest
     */
    updateMask: Array<PatchUserRequestUpdateMaskEnum>;
}


/**
 * @export
 */
export const PatchUserRequestUpdateMaskEnum = {
    Avatar: 'avatar',
    WorkspaceIcon: 'workspaceIcon',
    Username: 'username',
    Email: 'email'
} as const;
export type PatchUserRequestUpdateMaskEnum = typeof PatchUserRequestUpdateMaskEnum[keyof typeof PatchUserRequestUpdateMaskEnum];

/**
 * 
 * @export
 * @interface ResetVersionResponse
 */
export interface ResetVersionResponse {
    /**
     * 
     * @type {string}
     * @memberof ResetVersionResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ResetVersionResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ResetVersionResponse
     */
    saveType: string;
}
/**
 * 
 * @export
 * @interface RestoreVersionRequest
 */
export interface RestoreVersionRequest {
    /**
     * 
     * @type {string}
     * @memberof RestoreVersionRequest
     */
    tabId: string;
}
/**
 * 
 * @export
 * @interface StatusResponse
 */
export interface StatusResponse {
    /**
     * 
     * @type {string}
     * @memberof StatusResponse
     */
    userId?: string;
    /**
     * 
     * @type {object}
     * @memberof StatusResponse
     */
    phaseFileApiStatus?: object;
}
/**
 * 
 * @export
 * @interface UpdateContributorResponse
 */
export interface UpdateContributorResponse {
    /**
     * 
     * @type {string}
     * @memberof UpdateContributorResponse
     */
    id: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateContributorResponse
     */
    contributorIds: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateInvitationEmailRequest
 */
export interface UpdateInvitationEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateInvitationEmailRequest
     */
    email: string;
}
/**
 * 
 * @export
 * @interface UpdateInvitationRoleRequest
 */
export interface UpdateInvitationRoleRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateInvitationRoleRequest
     */
    role: UpdateInvitationRoleRequestRoleEnum;
}


/**
 * @export
 */
export const UpdateInvitationRoleRequestRoleEnum = {
    Owner: 'OWNER',
    Admin: 'ADMIN',
    Member: 'MEMBER',
    Guest: 'GUEST'
} as const;
export type UpdateInvitationRoleRequestRoleEnum = typeof UpdateInvitationRoleRequestRoleEnum[keyof typeof UpdateInvitationRoleRequestRoleEnum];

/**
 * 
 * @export
 * @interface UpdateProjectFileInvitationRequest
 */
export interface UpdateProjectFileInvitationRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectFileInvitationRequest
     */
    permission?: UpdateProjectFileInvitationRequestPermissionEnum;
}


/**
 * @export
 */
export const UpdateProjectFileInvitationRequestPermissionEnum = {
    Edit: 'CAN_EDIT',
    Review: 'CAN_REVIEW'
} as const;
export type UpdateProjectFileInvitationRequestPermissionEnum = typeof UpdateProjectFileInvitationRequestPermissionEnum[keyof typeof UpdateProjectFileInvitationRequestPermissionEnum];

/**
 * 
 * @export
 * @interface UpdateProjectInvitationRequest
 */
export interface UpdateProjectInvitationRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectInvitationRequest
     */
    permission?: UpdateProjectInvitationRequestPermissionEnum;
}


/**
 * @export
 */
export const UpdateProjectInvitationRequestPermissionEnum = {
    CanEdit: 'CAN_EDIT',
    CanReview: 'CAN_REVIEW',
    NoAccess: 'NO_ACCESS'
} as const;
export type UpdateProjectInvitationRequestPermissionEnum = typeof UpdateProjectInvitationRequestPermissionEnum[keyof typeof UpdateProjectInvitationRequestPermissionEnum];

/**
 * 
 * @export
 * @interface UpdateTeamIconRequest
 */
export interface UpdateTeamIconRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateTeamIconRequest
     */
    contentType?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTeamIconRequest
     */
    removeIcon?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateTeamIconResponse
 */
export interface UpdateTeamIconResponse {
    /**
     * 
     * @type {string}
     * @memberof UpdateTeamIconResponse
     */
    url: string;
}
/**
 * 
 * @export
 * @interface UpdateUserRequest
 */
export interface UpdateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    avatar?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    workspaceIcon?: string;
}
/**
 * 
 * @export
 * @interface UpdateVersionRequestV1
 */
export interface UpdateVersionRequestV1 {
    /**
     * 
     * @type {string}
     * @memberof UpdateVersionRequestV1
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateVersionRequestV1
     */
    description?: string;
}
/**
 * 
 * @export
 * @interface UpdateVersionResponse
 */
export interface UpdateVersionResponse {
    /**
     * 
     * @type {string}
     * @memberof UpdateVersionResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateVersionResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateVersionResponse
     */
    updatedAt: string;
}
/**
 * 
 * @export
 * @interface UploadContentRequest
 */
export interface UploadContentRequest {
    /**
     * 
     * @type {object}
     * @memberof UploadContentRequest
     */
    content: object;
}
/**
 * 
 * @export
 * @interface UpsertUserPreference
 */
export interface UpsertUserPreference {
    /**
     * 
     * @type {string}
     * @memberof UpsertUserPreference
     */
    value: string;
}
/**
 * 
 * @export
 * @interface UserResponse
 */
export interface UserResponse {
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    avatar?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    systemRole: UserResponseSystemRoleEnum;
}


/**
 * @export
 */
export const UserResponseSystemRoleEnum = {
    Admin: 'admin',
    User: 'user'
} as const;
export type UserResponseSystemRoleEnum = typeof UserResponseSystemRoleEnum[keyof typeof UserResponseSystemRoleEnum];

