import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IS_MIX } from '@phase-software/data-utils'
import { EffectType } from '@phase-software/types'

import { EFFECT_PROPS_DISPLAY_NAME_MAP } from '../../../../constant'
import { useSetModal } from '../../../../providers/ModalProvider'
import { useScrollIntoView } from '../../../../providers/ScrollProvider/useScrollIntoView'
import { useEditor, useEditorActions } from '../../../../providers/dataStore/EditorProvider'
import { Content, EffectItem, WideTitle } from '../../../shared'
import { MenuOptionProps } from '../../../shared/Menu/Menu.types'
import { translateMenuOptions } from '../../../shared/Menu/utils'
import { ContainerWithRemoveEffect } from '../../ContainerWithRemoveEffect'
import PropertyRow from '../PropertyRow'
import EffectModal, { EFFECT_MODAL_NAME } from './EffectModal'
import { Effect } from './types'

const EFFECT_MENU_OPTIONS: MenuOptionProps[] = [
  {
    value: EffectType.TRIM_PATH,
    name: EFFECT_PROPS_DISPLAY_NAME_MAP[EffectType.TRIM_PATH],
    disabled: false
  }
]

export default function EffectEditor() {
  const effectRef = useRef(null)
  const ref = useRef<HTMLDivElement>(null)
  const [activeType, setActiveType] = useState<null | EffectType>(null)
  const { toggleModal, closeModal } = useSetModal(EFFECT_MODAL_NAME)
  const { addEffect } = useEditorActions()
  const { activateScroll, handleAutoScroll } = useScrollIntoView()
  const editor = useEditor()
  const { t } = useTranslation('file', { keyPrefix: 'property_editor' })

  const effectList = useMemo(() => editor.effectList || [], [editor.effectList])

  const isMix = effectList[0] && effectList[0].id === IS_MIX

  const appliedEffectTypeSet = useMemo(() => new Set(effectList.map(({ type }: Effect) => type)), [effectList])
  const shouldCloseModal = !appliedEffectTypeSet.has(activeType)

  const handleAddEffect = (selectedEffect?: MenuOptionProps) => {
    if (selectedEffect) {
      addEffect(selectedEffect.value)
      activateScroll()
    }
  }

  const handleToggleEffectModal = (id: string, type: EffectType) => {
    setActiveType(type)
    toggleModal({ trigger: effectRef, id, type })
  }

  const updatedEffectMenuOptions = useMemo(() => {
    const options =
      appliedEffectTypeSet.size === EFFECT_MENU_OPTIONS.length
        ? []
        : EFFECT_MENU_OPTIONS.map((option: MenuOptionProps) => ({
            ...option,
            disabled: appliedEffectTypeSet.has(option.value as Effect['type'])
          }))

    return translateMenuOptions(t, options, {
      ns: 'file',
      keyPrefix: 'effect_type'
    })
  }, [t, appliedEffectTypeSet])

  useEffect(() => {
    if (shouldCloseModal) {
      closeModal()
      setActiveType(null)
    }
  }, [shouldCloseModal, closeModal, setActiveType])

  useEffect(() => {
    handleAutoScroll(ref?.current?.children[0])
  }, [effectList, handleAutoScroll])

  return (
    <>
      <WideTitle
        icons={[
          {
            name: 'Plus',
            dataTestId: 'effect-plus-icon',
            tooltip: t('effect_editor.add_button_tooltip'),
            dropdownMenu: {
              options: updatedEffectMenuOptions,
              onSelect: handleAddEffect
            }
          }
        ]}
        disabled={updatedEffectMenuOptions.length === 0}
        disabledTooltip={isMix ? t('effect_editor.disabled_mix_tooltip') : ''}
        dividerPosition="top"
      >
        {t('effect_editor.title')}
      </WideTitle>
      <Content ref={ref} withHeader>
        {isMix ? (
          <div className="py-4">{t('mix_content.not_editable_note')}</div>
        ) : (
          effectList.map(({ id, type }: { id: string; type: EffectType }) => (
            <ContainerWithRemoveEffect key={id} effectItemId={id}>
              <PropertyRow className="pb-0" columns={[196]} isFirst isLast>
                <EffectItem ref={effectRef} type={type} onClick={() => handleToggleEffectModal(id, type)} />
              </PropertyRow>
            </ContainerWithRemoveEffect>
          ))
        )}
      </Content>
      <EffectModal />
    </>
  )
}
