import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { loadLocale } from './configs/moment'
import { DEFAULT_LOCALE } from './constants/localeConstants'
import { usePreferredLanguageQuery } from './generated/graphql'
import { FEATURE_KEYS } from './growthbook-feature-keys'
import { captureJamMeta } from './services/jam'

type LoadLanguageProps = {
  userId: string
  isLanguageLoaded: boolean
  setIsLanguageLoaded: React.Dispatch<React.SetStateAction<boolean>>
}

const LoadLanguage = ({ userId, isLanguageLoaded, setIsLanguageLoaded }: LoadLanguageProps) => {
  const { i18n } = useTranslation()
  const isMultilingualSupported = useFeatureIsOn(FEATURE_KEYS.MULTILINGUAL_SUPPORT)

  const { data: languageData, loading: isLoading } = usePreferredLanguageQuery({
    variables: { id: userId },
    skip: !userId || !isMultilingualSupported || isLanguageLoaded
  })

  useEffect(() => {
    if (isLoading || isLanguageLoaded) {
      return
    }
    if (isMultilingualSupported) {
      const preferredLanguage = languageData?.user_preferences_by_pk?.value ?? DEFAULT_LOCALE
      document.documentElement.lang = preferredLanguage
      captureJamMeta('lang', preferredLanguage)

      i18n.changeLanguage(preferredLanguage)
      loadLocale(preferredLanguage)
    }
    setIsLanguageLoaded(true)
  }, [isLoading, languageData, i18n, isMultilingualSupported, isLanguageLoaded, setIsLanguageLoaded])

  return null
}

export default LoadLanguage
