import { TFunction } from 'i18next'
import { INITIAL_ACTIVE_INDEX, OPTION_SEPARATOR } from './Menu.constants'
import { ArrowDirection, MenuListOptionProps, MenuOptionProps } from './Menu.types'

export const isSeparatorOption = (option: MenuListOptionProps): option is typeof OPTION_SEPARATOR =>
  option === OPTION_SEPARATOR
export const isNonSelectableOption = (option: MenuListOptionProps) => isSeparatorOption(option) || option.disabled

export const createOption = (name: string, value: string, dataTestId: string, shortcut?: string): MenuOptionProps => ({
  name,
  value,
  shortcut,
  dataTestId,
  disabled: false
})

export function removeConsecutiveSeparators(filteredOptions: MenuListOptionProps[]): MenuListOptionProps[] {
  const result: MenuListOptionProps[] = []
  let prevWasSeparator = false

  for (const option of filteredOptions) {
    if (isSeparatorOption(option)) {
      if (prevWasSeparator) continue
      prevWasSeparator = true
    } else {
      prevWasSeparator = false
    }

    result.push(option)
  }

  // Remove trailing separator if present
  if (result.length > 0 && isSeparatorOption(result[result.length - 1])) {
    result.pop()
  }

  return result
}

export const calculateNewActiveIndex = (
  direction: ArrowDirection,
  activeIndex: number,
  options: MenuListOptionProps[]
) => {
  const isNoActiveIndex = activeIndex === INITIAL_ACTIVE_INDEX
  const isMovingForward = direction === 1
  const isAtFirstOption = isNoActiveIndex && isMovingForward
  const isAtLastOption = isNoActiveIndex && !isMovingForward

  const adjustedActiveIndex = isAtFirstOption ? INITIAL_ACTIVE_INDEX : isAtLastOption ? options.length : activeIndex

  // circular navigation
  let newActiveIndex = (adjustedActiveIndex + direction + options.length) % options.length

  while (options[newActiveIndex] && isNonSelectableOption(options[newActiveIndex])) {
    newActiveIndex = (newActiveIndex + direction + options.length) % options.length
  }

  return newActiveIndex
}

const convertStyleObjectToCSS = (
  styleObject: Record<string, string | number | undefined>
): Partial<CSSStyleDeclaration> => {
  return Object.entries(styleObject).reduce((acc: Record<string, string | undefined>, [property, value]) => {
    acc[property] = typeof value === 'number' ? `${value}px` : value
    return acc
  }, {})
}

export const applyStyleToElementRef = (
  ref: React.RefObject<HTMLElement>,
  styleObject: Record<string, string | number | undefined>
) => {
  const cssStyleToAssign = convertStyleObjectToCSS(styleObject)
  Object.assign(ref.current?.style ?? {}, cssStyleToAssign)
}

export function filterOptions(
  options: MenuListOptionProps[],
  searchValue?: string,
  filterOption?: (searchValue: string, option: MenuOptionProps) => boolean
): MenuListOptionProps[] {
  if (!searchValue) return options

  const normalizedSearchValue = searchValue.trim().toLowerCase()

  return options.filter((option) => {
    if (isSeparatorOption(option)) {
      return false
    }

    return filterOption
      ? filterOption(normalizedSearchValue, option)
      : option.name?.toLowerCase().startsWith(normalizedSearchValue)
  })
}

interface translationOptions {
  ns: string
  keyPrefix?: string
}

export const translateMenuOptions = (t: TFunction, menuOptions: MenuListOptionProps[], translationOptions?: translationOptions) => {
  return menuOptions.map((option: MenuListOptionProps) => {
    if (isSeparatorOption(option)) {
      return option
    }

    return { ...option, name: t(option.name ?? '', { ...translationOptions }) }
  })
}
