import React, { createContext, useContext, useCallback, useRef, RefObject } from 'react'
import { scrollIntoView } from '../../utils/dom'

type ScrollContextType = {
  scrollRef: RefObject<Element>
  handleScroll: (targetRef: Element) => void
}

type ScrollProviderProps = {
  children: React.ReactNode
}

const defaultValue = {
  scrollRef: { current: null },
  handleScroll: () => {}
}

const ScrollContext = createContext<ScrollContextType>(defaultValue)

const ScrollProvider = ({ children }: ScrollProviderProps) => {
  const scrollRef = useRef<Element>(null)

  const handleScroll = useCallback(
    (targetRef: Element) => {
      if (scrollRef?.current && targetRef) {
        scrollIntoView(targetRef, 'end', scrollRef.current, 'bottom')
      }
    },
    [scrollRef]
  )

  return (
    <ScrollContext.Provider
      value={{
        scrollRef,
        handleScroll
      }}
    >
      {children}
    </ScrollContext.Provider>
  )
}

function useScrollContext(): ScrollContextType {
  const context = useContext(ScrollContext)
  if (context === undefined) {
    throw new Error('useScrollContext must be used within a ScrollProvider')
  }
  return context
}

export { ScrollProvider, useScrollContext }
