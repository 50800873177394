import React from 'react'
import IconButton from '../IconButton'

interface NarrowTitleProps {
  text: string
  className?: string
  disabled?: boolean
  iconName?: string
  iconTooltip?: string
  onIconClick?: () => void
  dataTestId?: string
}

const NarrowTitle = ({
  text,
  className = '',
  disabled = false,
  iconName,
  iconTooltip,
  onIconClick,
  dataTestId
}: NarrowTitleProps) => {
  const handleClickTitle = () => {
    if (disabled) return
    onIconClick?.()
  }

  const handleClickIcon = (e: React.MouseEvent) => {
    if (disabled) return
    e.stopPropagation()
    onIconClick?.()
  }
  return (
    <div
      className={`${
        disabled ? 'cursor-not-allowed' : 'cursor-pointer'
      } group py-8 uppercase text-10 flex items-center justify-between gap-8 ${className}`}
      onClick={handleClickTitle}
      data-test-id={dataTestId || undefined}
    >
      <p
        className={`text-light-overlay-40 ${
          disabled ? '' : 'group-hover:text-white '
        }overflow-ellipsis overflow-hidden whitespace-nowrap`}
      >
        {text}
      </p>
      {iconName && <IconButton onClick={handleClickIcon} icon={iconName} tip={iconTooltip} disabled={disabled} />}
    </div>
  )
}

export default NarrowTitle
