/* tslint:disable */
/* eslint-disable */
/**
 * PHASE API
 * Phase API service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateProjectCollaboratorInvitationRequest,
  IdResponse,
  UpdateProjectInvitationRequest,
} from '../models';

export interface ProjectCollaboratorControllerAcceptTeamProjectCollaboratorInviteRequest {
    teamId: string;
    projectId: string;
    invitationId: string;
}

export interface ProjectCollaboratorControllerCreateTeamProjectCollaboratorInvitationRequest {
    teamId: string;
    projectId: string;
    createProjectCollaboratorInvitationRequest: CreateProjectCollaboratorInvitationRequest;
}

export interface ProjectCollaboratorControllerRefuseTeamProjectCollaboratorInviteRequest {
    teamId: string;
    projectId: string;
    invitationId: string;
}

export interface ProjectCollaboratorControllerRemoveTeamProjectCollaboratorInviteRequest {
    teamId: string;
    projectId: string;
    invitationId: string;
}

export interface ProjectCollaboratorControllerResendTeamProjectCollaboratorInviteEmailRequest {
    teamId: string;
    projectId: string;
    invitationId: string;
}

export interface ProjectCollaboratorControllerUpdatePermissionOfTeamProjectCollaboratorInvitationRequest {
    teamId: string;
    projectId: string;
    invitationId: string;
    updateProjectInvitationRequest: UpdateProjectInvitationRequest;
}

/**
 * 
 */
export class ProjectCollaboratorApi extends runtime.BaseAPI {

    /**
     */
    async projectCollaboratorControllerAcceptTeamProjectCollaboratorInviteRaw(requestParameters: ProjectCollaboratorControllerAcceptTeamProjectCollaboratorInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling projectCollaboratorControllerAcceptTeamProjectCollaboratorInvite.');
        }

        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectCollaboratorControllerAcceptTeamProjectCollaboratorInvite.');
        }

        if (requestParameters.invitationId === null || requestParameters.invitationId === undefined) {
            throw new runtime.RequiredError('invitationId','Required parameter requestParameters.invitationId was null or undefined when calling projectCollaboratorControllerAcceptTeamProjectCollaboratorInvite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/teams/{teamId}/projects/{projectId}/invitations/{invitationId}:accept`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"invitationId"}}`, encodeURIComponent(String(requestParameters.invitationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async projectCollaboratorControllerAcceptTeamProjectCollaboratorInvite(requestParameters: ProjectCollaboratorControllerAcceptTeamProjectCollaboratorInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.projectCollaboratorControllerAcceptTeamProjectCollaboratorInviteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async projectCollaboratorControllerCreateTeamProjectCollaboratorInvitationRaw(requestParameters: ProjectCollaboratorControllerCreateTeamProjectCollaboratorInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IdResponse>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling projectCollaboratorControllerCreateTeamProjectCollaboratorInvitation.');
        }

        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectCollaboratorControllerCreateTeamProjectCollaboratorInvitation.');
        }

        if (requestParameters.createProjectCollaboratorInvitationRequest === null || requestParameters.createProjectCollaboratorInvitationRequest === undefined) {
            throw new runtime.RequiredError('createProjectCollaboratorInvitationRequest','Required parameter requestParameters.createProjectCollaboratorInvitationRequest was null or undefined when calling projectCollaboratorControllerCreateTeamProjectCollaboratorInvitation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/teams/{teamId}/projects/{projectId}/invitations`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.createProjectCollaboratorInvitationRequest,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async projectCollaboratorControllerCreateTeamProjectCollaboratorInvitation(requestParameters: ProjectCollaboratorControllerCreateTeamProjectCollaboratorInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IdResponse> {
        const response = await this.projectCollaboratorControllerCreateTeamProjectCollaboratorInvitationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectCollaboratorControllerRefuseTeamProjectCollaboratorInviteRaw(requestParameters: ProjectCollaboratorControllerRefuseTeamProjectCollaboratorInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling projectCollaboratorControllerRefuseTeamProjectCollaboratorInvite.');
        }

        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectCollaboratorControllerRefuseTeamProjectCollaboratorInvite.');
        }

        if (requestParameters.invitationId === null || requestParameters.invitationId === undefined) {
            throw new runtime.RequiredError('invitationId','Required parameter requestParameters.invitationId was null or undefined when calling projectCollaboratorControllerRefuseTeamProjectCollaboratorInvite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/teams/{teamId}/projects/{projectId}/invitations/{invitationId}:refuse`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"invitationId"}}`, encodeURIComponent(String(requestParameters.invitationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async projectCollaboratorControllerRefuseTeamProjectCollaboratorInvite(requestParameters: ProjectCollaboratorControllerRefuseTeamProjectCollaboratorInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.projectCollaboratorControllerRefuseTeamProjectCollaboratorInviteRaw(requestParameters, initOverrides);
    }

    /**
     * For project share dialog to call to remove pending invitation
     */
    async projectCollaboratorControllerRemoveTeamProjectCollaboratorInviteRaw(requestParameters: ProjectCollaboratorControllerRemoveTeamProjectCollaboratorInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling projectCollaboratorControllerRemoveTeamProjectCollaboratorInvite.');
        }

        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectCollaboratorControllerRemoveTeamProjectCollaboratorInvite.');
        }

        if (requestParameters.invitationId === null || requestParameters.invitationId === undefined) {
            throw new runtime.RequiredError('invitationId','Required parameter requestParameters.invitationId was null or undefined when calling projectCollaboratorControllerRemoveTeamProjectCollaboratorInvite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/teams/{teamId}/projects/{projectId}/invitations/{invitationId}:remove`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"invitationId"}}`, encodeURIComponent(String(requestParameters.invitationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * For project share dialog to call to remove pending invitation
     */
    async projectCollaboratorControllerRemoveTeamProjectCollaboratorInvite(requestParameters: ProjectCollaboratorControllerRemoveTeamProjectCollaboratorInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.projectCollaboratorControllerRemoveTeamProjectCollaboratorInviteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async projectCollaboratorControllerResendTeamProjectCollaboratorInviteEmailRaw(requestParameters: ProjectCollaboratorControllerResendTeamProjectCollaboratorInviteEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling projectCollaboratorControllerResendTeamProjectCollaboratorInviteEmail.');
        }

        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectCollaboratorControllerResendTeamProjectCollaboratorInviteEmail.');
        }

        if (requestParameters.invitationId === null || requestParameters.invitationId === undefined) {
            throw new runtime.RequiredError('invitationId','Required parameter requestParameters.invitationId was null or undefined when calling projectCollaboratorControllerResendTeamProjectCollaboratorInviteEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/teams/{teamId}/projects/{projectId}/invitations/{invitationId}:resendEmail`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"invitationId"}}`, encodeURIComponent(String(requestParameters.invitationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async projectCollaboratorControllerResendTeamProjectCollaboratorInviteEmail(requestParameters: ProjectCollaboratorControllerResendTeamProjectCollaboratorInviteEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.projectCollaboratorControllerResendTeamProjectCollaboratorInviteEmailRaw(requestParameters, initOverrides);
    }

    /**
     * Update the permission of Pending Project-Collaborator invitation
     */
    async projectCollaboratorControllerUpdatePermissionOfTeamProjectCollaboratorInvitationRaw(requestParameters: ProjectCollaboratorControllerUpdatePermissionOfTeamProjectCollaboratorInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling projectCollaboratorControllerUpdatePermissionOfTeamProjectCollaboratorInvitation.');
        }

        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectCollaboratorControllerUpdatePermissionOfTeamProjectCollaboratorInvitation.');
        }

        if (requestParameters.invitationId === null || requestParameters.invitationId === undefined) {
            throw new runtime.RequiredError('invitationId','Required parameter requestParameters.invitationId was null or undefined when calling projectCollaboratorControllerUpdatePermissionOfTeamProjectCollaboratorInvitation.');
        }

        if (requestParameters.updateProjectInvitationRequest === null || requestParameters.updateProjectInvitationRequest === undefined) {
            throw new runtime.RequiredError('updateProjectInvitationRequest','Required parameter requestParameters.updateProjectInvitationRequest was null or undefined when calling projectCollaboratorControllerUpdatePermissionOfTeamProjectCollaboratorInvitation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/teams/{teamId}/projects/{projectId}/invitations/{invitationId}:updateRight`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"invitationId"}}`, encodeURIComponent(String(requestParameters.invitationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.updateProjectInvitationRequest,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update the permission of Pending Project-Collaborator invitation
     */
    async projectCollaboratorControllerUpdatePermissionOfTeamProjectCollaboratorInvitation(requestParameters: ProjectCollaboratorControllerUpdatePermissionOfTeamProjectCollaboratorInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.projectCollaboratorControllerUpdatePermissionOfTeamProjectCollaboratorInvitationRaw(requestParameters, initOverrides);
    }

}
