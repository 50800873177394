import React from 'react'
import { useTranslation } from 'react-i18next'

import { EndShape, GrowDirection, JoinShape, PaintType } from '@phase-software/types'

import { IMAGE_MODE_MAP } from '../../../constant'
import { useModal, useSetModal } from '../../../providers/ModalProvider'
import { useEditor } from '../../../providers/dataStore/EditorProvider'
import { usePaint } from '../../../providers/dataStore/PaintProvider'
import { useStroke } from '../../../providers/dataStore/StrokeProvider'
import { formatAsPercentage, multiplyBy100 } from '../../../utils/formatter'
import useColorTextGenerator from '../../InspectPanel/Hooks/useColorTextGenerator'
import ColorPreview from '../../PaintPicker/ColorPreview'
import GradientDetailsModal from '../Modal/GradientDetailsModal'
import InspectRow from './InspectRow'
import InteractiveInspectContent from './InteractiveInspectContent'

type MapProps = {
  [key: number]: string
}

const growDirectionMap: MapProps = {
  [GrowDirection.INSIDE]: 'inside',
  [GrowDirection.CENTER]: 'center',
  [GrowDirection.OUTSIDE]: 'outside'
}

const joinShapeMap: MapProps = {
  [JoinShape.MITER]: 'miter',
  [JoinShape.CONCAVE]: 'concave',
  [JoinShape.ROUND]: 'round',
  [JoinShape.BEVEL]: 'bevel',
  [JoinShape.NONE]: 'none'
}

const endShapeMap: MapProps = {
  [EndShape.STRAIGHT]: 'straight',
  [EndShape.ROUND]: 'round'
}

type StrokeInspectionProps = {
  id: string
}
const StrokeInspection = ({ id }: StrokeInspectionProps) => {
  const { t } = useTranslation('file', { keyPrefix: 'inspect' })
  const { t: tDirection } = useTranslation('file', { keyPrefix: 'stroke_direction' })
  const { t: tJoin } = useTranslation('file', { keyPrefix: 'stroke_join' })
  const { t: tEnd } = useTranslation('file', { keyPrefix: 'stroke_end_shape' })
  const { t: tImageMode } = useTranslation('file', { keyPrefix: 'image_mode' })
  const { paintType, color, opacity, imageMode } = usePaint((o) => o[id])
  const { width, growDirection, join, dash, gap, miter, ends } = useStroke((o) => o[id])
  const MODAL_KEY = `InspectContextMenu-${id}`
  const { toggleModal, closeAllModals } = useSetModal(MODAL_KEY)
  const { open } = useModal((o: any) => o[MODAL_KEY])
  const { getValues } = useColorTextGenerator()

  return (
    <div className="flex flex-col">
      <InspectRow
        value={getValues(paintType, color, opacity)}
        leftContent={<ColorPreview layerItemId={id} size="m" />}
        showDetailsButton={paintType !== PaintType.SOLID && paintType !== PaintType.IMAGE}
        copyDisabled={paintType !== PaintType.SOLID}
        valueClassName={paintType === PaintType.SOLID ? '' : 'text-light-overlay-60'}
        onClickDetails={() => {
          if (!open) closeAllModals()
          toggleModal()
        }}
      />
      {paintType === PaintType.IMAGE && (
        <InspectRow
          label={t('fill_mode')}
          value={tImageMode(IMAGE_MODE_MAP[imageMode as keyof typeof IMAGE_MODE_MAP])}
        />
      )}
      {paintType !== PaintType.SOLID && (
        <InspectRow label={t('opacity')} value={formatAsPercentage(multiplyBy100(opacity))} />
      )}

      <InspectRow label={t('border_width')} value={`${width}px`} />
      <InspectRow label={t('grow_direction')} value={tDirection(growDirectionMap[growDirection])} />
      <InspectRow label={t('join_shape')} value={tJoin(joinShapeMap[join])} />
      <InspectRow label={t('dash')} value={`${dash[0] || 0}px`} />
      <InspectRow label={t('gap')} value={`${gap[0] || 0}px`} />
      <InspectRow label={t('miter_angle')} value={`${miter}°`} />
      <InspectRow label={t('ends')} value={tEnd(endShapeMap[ends])} />

      {paintType !== 0 && <GradientDetailsModal id={id} />}
    </div>
  )
}

const StrokesInspection = () => {
  const { t } = useTranslation('file', { keyPrefix: 'inspect' })
  const strokeList = useEditor((o) => o.strokeList)

  if (strokeList.length === 0) return null

  return (
    <InteractiveInspectContent title={t('borders')} target="static_strokes">
      <div className="flex flex-col gap-8">
        {strokeList.map((id: string) => (
          <StrokeInspection key={id} id={id} />
        ))}
      </div>
    </InteractiveInspectContent>
  )
}

export default StrokesInspection
