import React, { forwardRef, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useHeapAnalytics from '../../hooks/useHeapAnalytics'
import { useCreateComment, useEditThreadMetadata, useSelf } from '../../liveblocks.config'
import { useCommentContext } from '../../providers/CommentProvider'
import { useFileContext } from '../../providers/FileProvider'
import { useSetNotification } from '../../providers/NotificationProvider'
import { useWorkspaceContext } from '../../providers/WorkspaceContextProvider'
import { track } from '../../services/heapAnalytics'
import DeleteCommentDialog from '../modals/DeleteCommentDialog'
import CommentThread, { CommentThreadProps } from '../shared/CommentThread'
import { CommentType } from '../shared/CommentThread/types'
import { parseMetadata, stringifyMetadata, toLiveblocksComment } from './utils'

type CommentThreadWithDataProps = Omit<
  CommentThreadProps,
  | 'toggleDeleteDialog'
  | 'updateResolveStatus'
  | 'copyLink'
  | 'onReplySubmit'
  | 'onClick'
  | 'updateUserReadStatus'
  | 'isThreadCreator'
  | 'isRead'
  | 'isResolved'
> & {
  scrollToThread?: (threadId: string) => void
  expandThread: () => void
}

const CommentThreadWithData = forwardRef<HTMLDivElement, CommentThreadWithDataProps>(
  ({ thread, scrollToThread, expandThread, isExpanded, ...props }, forwardedRef) => {
    const { t } = useTranslation(['common', 'workspace'])
    const { addNotification } = useSetNotification()
    const { selectCanvasCommentThread, selectPanelCommentThread } = useCommentContext()

    const { id: currentUserId = '' } = useSelf()
    const editThreadMetadata = useEditThreadMetadata()
    const createComment = useCreateComment()
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

    const { workspaceData } = useWorkspaceContext()
    const { id: fileId, projectId } = useFileContext()
    const { teamName, space } = useHeapAnalytics()
    const metadata = useMemo(() => {
      const rawMetaData = thread.metadata
      return parseMetadata(rawMetaData)
    }, [thread.metadata])

    const isThreadCreator = currentUserId === thread.comments[0].userId
    const threadId = thread.id
    const isRead = metadata.usersReadStatus[currentUserId]
    const location = projectId === workspaceData.draftProjectId ? 'drafts' : 'project'
    const commentType = metadata.type === CommentType.CANVAS ? 'OnCanvas' : 'FileLevel'

    const unResolveThread = useCallback(
      (threadId: string) => {
        editThreadMetadata({ threadId, metadata: { resolved: false } })
      },
      [editThreadMetadata]
    )

    const resolveThread = useCallback(
      async (threadId: string) => {
        try {
          await editThreadMetadata({ threadId, metadata: { resolved: true } })
          addNotification({
            type: 'success',
            content: t('workspace:message.comment_thread_resolved'),
            action: t('common:undo'),
            callback: () => {
              unResolveThread(threadId)
              if (metadata.type === CommentType.CANVAS) {
                selectCanvasCommentThread(threadId)
                return
              }
              selectPanelCommentThread(threadId)
              scrollToThread?.(threadId)
            }
          })

          track('Comment Resolved', { fileId, teamName, location, space, commentType })
        } catch (error) {
          console.log('[CommentThreadWithData] resolveThread error:', error)
        }
      },
      [
        editThreadMetadata,
        addNotification,
        fileId,
        teamName,
        location,
        space,
        commentType,
        unResolveThread,
        metadata.type,
        selectPanelCommentThread,
        scrollToThread,
        selectCanvasCommentThread,
        t
      ]
    )

    const updateResolveStatus = useCallback(
      (threadId: string, resolved: boolean) => {
        resolved ? resolveThread(threadId) : unResolveThread(threadId)
      },
      [resolveThread, unResolveThread]
    )

    const updateUserReadStatus = useCallback(
      (isRead: boolean, reserveReadStatus = true) => {
        const newMetadata = stringifyMetadata({
          ...metadata,
          usersReadStatus: reserveReadStatus
            ? { ...metadata.usersReadStatus, [currentUserId]: isRead }
            : { [currentUserId]: isRead }
        })
        editThreadMetadata({ threadId, metadata: newMetadata })
      },
      [threadId, editThreadMetadata, currentUserId, metadata]
    )
    const copyLink = useCallback(
      (threadId: string, type: CommentType) => {
        const currentUrl = new URL(window.location.href)
        const searchParams = new URLSearchParams(currentUrl.search)
        searchParams.set('thread-id', threadId)
        searchParams.set('type', type)
        currentUrl.search = searchParams.toString()
        navigator.clipboard.writeText(currentUrl.toString())
        addNotification({
          type: 'success',
          content: t('common:link_copied')
        })
      },
      [addNotification, t]
    )

    const replyToThread = useCallback(
      async ({ threadId, value }: { threadId: string; value: string }) => {
        const data = toLiveblocksComment(value, threadId)
        try {
          await createComment(data)
          updateUserReadStatus(true, false)

          track('Comment Reply Added', {
            fileId,
            teamName,
            location,
            space,
            commentType
          })
        } catch (error) {
          console.log('[CommentThreadWithData] replyToThread error:', error)
        }
      },
      [commentType, createComment, fileId, location, space, teamName, updateUserReadStatus]
    )

    const toggleDeleteDialog = useCallback(
      (open: boolean) => {
        setDeleteDialogOpen(open)
      },
      [setDeleteDialogOpen]
    )

    const handleDeleteThread = useCallback(() => {
      editThreadMetadata({ threadId, metadata: { isDeleted: true } })
      setDeleteDialogOpen(false)
    }, [editThreadMetadata, threadId])

    const handleThreadClick = useCallback(
      (e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLInputElement>) => {
        if (isExpanded) return
        e.stopPropagation()
        expandThread()
        updateUserReadStatus(true)
      },
      [expandThread, updateUserReadStatus, isExpanded]
    )

    return (
      <>
        <CommentThread
          ref={forwardedRef}
          toggleDeleteThreadDialog={toggleDeleteDialog}
          updateResolveStatus={updateResolveStatus}
          copyLink={copyLink}
          onReplySubmit={replyToThread}
          handleThreadClick={handleThreadClick}
          updateUserReadStatus={updateUserReadStatus}
          isThreadCreator={isThreadCreator}
          isRead={isRead}
          isResolved={metadata.resolved}
          isExpanded={isExpanded}
          thread={thread}
          {...props}
        />
        <DeleteCommentDialog
          isThread
          open={deleteDialogOpen}
          confirmDelete={handleDeleteThread}
          toggleDialog={toggleDeleteDialog}
        />
      </>
    )
  }
)

CommentThreadWithData.displayName = 'CommentThreadWithData'

export default CommentThreadWithData
