import { Unit, PropComponentType } from '@phase-software/types'
import { notNull, isNum } from '@phase-software/data-utils'
import PropertyComponent from './PropertyComponent'

/** @typedef {import('../DataStore').DataStore} DataStore */
/** @typedef {import('./PropertyComponent').AppliedRef} AppliedRef */

export default class OriginComponent extends PropertyComponent {
    /**
     * @param {DataStore} dataStore
     * @param {OriginComponentData} [data]
     * @param {object} [options] 
     * @param {bool} [options.regenId=false]   if set to true, will generate new ID
     */
    constructor(dataStore, data = {}, options) {
        super(dataStore, data, options)

        const { originX, originY, originXUnit, originYUnit } = data

        this.componentType = PropComponentType.ORIGIN
        this.originX = isNum(originX) ? originX : 50
        this.originY = isNum(originY) ? originY : 50
        this.originXUnit = originXUnit in Unit ? originXUnit : Unit.PERCENT
        this.originYUnit = originYUnit in Unit ? originYUnit : Unit.PERCENT

        if (!this.name) {
            this.name = 'origin'
        }
    }

    /** @param {Partial<OriginComponentSetData>} data */
    set(data) {
        super.set(data)

        if (isNum(data.originX)) {
            this.updateProp('originX', data.originX)
        } else if (data.originX === null) {
            this.updateProp('originX', undefined)
        }
        if (isNum(data.originY)) {
            this.updateProp('originY', data.originY)
        } else if (data.originY === null) {
            this.updateProp('originY', undefined)
        }
        if (data.originXUnit in Unit && notNull(this.originX)) {
            this.updateProp('originXUnit', data.originXUnit)
        }
        if (data.originYUnit in Unit && notNull(this.originY)) {
            this.updateProp('originYUnit', data.originYUnit)
        }
    }

    /**
     * Override this in subclasses
     * CALL super._clone() at the top of overriden method
     * @protected
     * @param {AppliedRef} [ref]
     * @returns {ScaleComponent}
     */
    _clone(ref) {
        const obj = super._clone(ref)
        obj.originX = this.originX
        obj.originY = this.originY
        obj.originXUnit = this.originXUnit
        obj.originYUnit = this.originYUnit
        return obj
    }

    /**
     * Override this in subclasses
     * CALL super._save() at the top of overriden method
     * @protected
     * @returns {OriginComponentData}
     */
    _save() {
        const data = super._save()
        if (notNull(this.originX)) {
            data.originX = this.originX
            data.originXUnit = this.originXUnit
        }
        if (notNull(this.originY)) {
            data.originY = this.originY
            data.originYUnit = this.originYUnit
        }
        return data
    }
}

OriginComponent.BASE_DEFAULT_DATA = {
    componentType: PropComponentType.ORIGIN,
    originX: 50,
    originY: 50,
    originXUnit: Unit.PERCENT,
    originYUnit: Unit.PERCENT
}

/** @typedef {import('./PropertyComponent').PropertyComponentData} PropertyComponentData */

/**
 * @typedef {PropertyComponentData} OriginComponentData
 * @property {number | undefined} originX
 * @property {number | undefined} originY
 * @property {Unit | undefined} originXUnit
 * @property {Unit | undefined} originYUnit
 */
