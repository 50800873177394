import { getStyleTrackData } from './StyleTrack'

export const getElementTrackData = (elementTrack, lazy) => {
  if (lazy) {
    return elementTrack.get('id')
  }
  const data = elementTrack.gets('disabled', 'elementId', 'id', 'styleTracks')
  data.styleTracks = data.styleTracks.map((o) => getStyleTrackData(o))
  return data
}
