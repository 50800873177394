import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import moment from 'moment'

import { useApolloClient } from '@apollo/client'
import { TeamMemberRoleEnum } from '@phase-software/types'

import { PERMISSIONS, useAccessControl } from '../../access-control'
import RemoveTeamMemberDialog from '../../components/modals/RemoveTeamMemberDialog'
import TransferOwnershipDialog from '../../components/modals/TransferOwnershipDialog'
import { Dropdown, Icon, Select, Table, Tooltip } from '../../components/shared'
import { MenuOptionProps } from '../../components/shared/Menu/Menu.types'
import { translateMenuOptions } from '../../components/shared/Menu/utils'
import { SortOrder } from '../../components/shared/Table'
import { TableUser } from '../../components/shared/Table/TableUser'
import { TEAM_ROLE_OPTIONS } from '../../constants/teamConstants'
import { PatchTeamUserRequestTeamRoleEnum } from '../../generated/api'
import { TeamUserFieldsFragment, TeamUserFieldsFragmentDoc, UserFieldsFragment } from '../../generated/graphql'
import useTeamMemberActions, { UpdateTeamMemberRoleParams } from '../../hooks/useTeamMemberActions'
import { useSetNotification } from '../../providers/NotificationProvider'
import { useProfile } from '../../providers/ProfileProvider'

interface TargetRemovalMember {
  username: UserFieldsFragment['username']
  userId: UserFieldsFragment['id']
}

const TeamMembersUsers = ({
  teamId,
  data
}: {
  teamId: TeamUserFieldsFragment['team_id']
  data: TeamUserFieldsFragment[]
}) => {
  const { t } = useTranslation('setting', { keyPrefix: 'team_setting' })
  const [targetRemovalMember, setTargetRemovalMember] = useState<TargetRemovalMember | undefined>()
  const [transferOwnershipDialogOpen, setTransferOwnershipDialogOpen] = useState(false)

  const profile = useProfile()
  const { updateTeamMemberRole } = useTeamMemberActions()
  const { addNotification } = useSetNotification()
  const { userHasPermission } = useAccessControl()
  const client = useApolloClient()

  const checkIsCurrentUser = useCallback((id: string) => id === profile.id, [profile.id])

  const closeRemoveTeamMemberDialog = useCallback(
    (targetRemovalMemberId: string) => {
      client.cache.evict({ id: `team_users:${targetRemovalMemberId}` })
      setTargetRemovalMember(undefined)
    },
    [client]
  )

  const cancelRemoveTeamMemberDialog = useCallback(() => setTargetRemovalMember(undefined), [])
  const closeTransferOwnershipDialog = useCallback(() => setTransferOwnershipDialogOpen(false), [])

  const getActionOptions = useCallback(
    (user: TeamUserFieldsFragment) => {
      const isCurrentUser = checkIsCurrentUser(user.user_id)
      const options = [
        {
          name: t('transfer_ownership'),
          value: 'transfer-ownership',
          dataTestId: 'member-transfer-ownership',
          show: userHasPermission(PERMISSIONS.TRANSFER_TEAM_OWNERSHIP, user),
          disabled: data.length === 1,
          rightComponent: (
            <Tooltip
              content={
                data.length === 1
                  ? t('no_member_transfer_ownership_confirmation')
                  : t('transfer_ownership_confirmation')
              }
            >
              <Icon name="Question" className="ml-4 pointer-events-auto" interactive={false} />
            </Tooltip>
          )
        },
        {
          name: isCurrentUser ? t('leave_team') : t('remove'),
          value: 'remove',
          dataTestId: isCurrentUser ? 'member-leave-team' : 'member-remove',
          show:
            userHasPermission(PERMISSIONS.REMOVE_TEAM_MEMBER, user) || userHasPermission(PERMISSIONS.LEAVE_TEAM, user)
        }
      ]
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      return options.filter((option) => option.show).map(({ show, ...rest }) => rest)
    },
    [checkIsCurrentUser, data.length, userHasPermission, t]
  )

  const handleUpdateRole = useCallback(
    async ({ userId, teamRole }: Omit<UpdateTeamMemberRoleParams, 'teamId'>) => {
      try {
        await updateTeamMemberRole({ teamId, userId, teamRole })
        await client.cache.updateFragment(
          {
            id: `team_users:${userId}`,
            fragment: TeamUserFieldsFragmentDoc,
            fragmentName: 'teamUserFields'
          },
          (data) => ({ ...data, team_role: teamRole })
        )

        addNotification({
          type: 'success',
          content: t('roles_has_been_updated')
        })
      } catch (error) {
        console.error(error)
      }
    },
    [addNotification, teamId, updateTeamMemberRole, client, t]
  )

  const handleOptionSelect = useCallback(
    ({ user, option }: { user: TeamUserFieldsFragment; option: MenuOptionProps }) => {
      switch (option?.value) {
        case 'transfer-ownership':
          setTransferOwnershipDialogOpen(true)
          break
        case 'remove':
          setTargetRemovalMember({ userId: user.user_id, username: user.user?.username })
          break
      }
    },
    []
  )

  const transferableMembers = useMemo(
    () =>
      data.filter(
        (member) => member.team_role !== TeamMemberRoleEnum.OWNER && member.team_role !== TeamMemberRoleEnum.GUEST
      ),
    [data]
  )

  const columns = useMemo(
    () => [
      {
        title: t('name'),
        key: 'name',
        render: (user: TeamUserFieldsFragment) => (
          <TableUser
            name={user.user?.username ?? ''}
            email={user.user?.email ?? ''}
            showYouTag={checkIsCurrentUser(user.user_id)}
            avatar={user.user?.avatar ?? ''}
          />
        ),
        sorter: (a: TeamUserFieldsFragment, b: TeamUserFieldsFragment) =>
          a.user?.username?.localeCompare(b.user?.username ?? '') ?? 0,
        sortOrder: SortOrder.ASCEND,
        flex: 1
      },
      {
        title: t('role'),
        key: 'role',
        sorter: (a: TeamUserFieldsFragment, b: TeamUserFieldsFragment) => a.team_role.localeCompare(b.team_role),
        render: (user: TeamUserFieldsFragment) =>
          userHasPermission(PERMISSIONS.EDIT_TEAM_MEMBER_ROLE, user) ? (
            <Select
              // @ts-ignore TODO: fix after refactor of Select
              dataTestId={`member-role-select-${user.user_id}`}
              value={user.team_role}
              options={translateMenuOptions(t, TEAM_ROLE_OPTIONS, {
                ns: 'setting',
                keyPrefix: 'team_setting'
              })}
              hideFocusStyle
              onChange={(value: string) =>
                handleUpdateRole({ userId: user.user_id, teamRole: value as PatchTeamUserRequestTeamRoleEnum })
              }
            />
          ) : (
            <>
              {t(user.team_role.toLowerCase(), {
                keyPrefix: 'team_setting.team_role'
              })}
            </>
          )
      },
      {
        title: t('last_active'),
        key: 'lastActive',
        render: (user: TeamUserFieldsFragment) => moment(user.user?.last_active_at).fromNow()
      },
      {
        title: '',
        key: 'action',
        width: 48,
        render: (user: TeamUserFieldsFragment) => {
          const actionOptions = getActionOptions(user)
          return actionOptions.length > 0 ? (
            <Dropdown
              dataTestId={`member-action-dropdown-${user.user_id}`}
              menu={{ options: actionOptions, onSelect: (option) => handleOptionSelect({ user, option }) }}
            >
              <Icon name="More" />
            </Dropdown>
          ) : null
        }
      }
    ],
    [checkIsCurrentUser, userHasPermission, handleUpdateRole, getActionOptions, handleOptionSelect, t]
  )

  return (
    <>
      <Table dataSource={data} columns={columns} rowKey="user_id" fixedHeader />
      {targetRemovalMember && (
        <RemoveTeamMemberDialog
          open={!!targetRemovalMember}
          onClose={() => closeRemoveTeamMemberDialog(targetRemovalMember.userId)}
          onCancel={cancelRemoveTeamMemberDialog}
          targetUserId={targetRemovalMember.userId}
          targetUsername={targetRemovalMember.username}
          teamUsers={data}
        />
      )}
      {transferOwnershipDialogOpen && (
        <TransferOwnershipDialog
          memberList={transferableMembers}
          open={transferOwnershipDialogOpen}
          onClose={closeTransferOwnershipDialog}
        />
      )}
    </>
  )
}

export default TeamMembersUsers
