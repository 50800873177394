import React, { useEffect, useCallback, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'

export const PlayControls = ({ play, onPlayToggle, time, onTimeChange, maxTime }) => {
  const handlePlayToggle = useCallback(() => {
    onPlayToggle(!play)
  }, [onPlayToggle, play])

  useEffect(() => {
    if (play && time >= maxTime) {
      onTimeChange(0)
    }
  }, [time, play, maxTime, onTimeChange])

  useLayoutEffect(() => {
    let requestId
    let lastT = Date.now()
    const nextTick = () => {
      const now = Date.now()
      const diff = now - lastT
      lastT = now
      onTimeChange((t) => Math.min(t + diff, maxTime))

      requestId = requestAnimationFrame(() => {
        if (play) {
          nextTick()
        }
      })
    }

    if (play) {
      nextTick()
    } else {
      cancelAnimationFrame(requestId)
    }
    return () => {
      cancelAnimationFrame(requestId)
    }
  }, [onTimeChange, play, maxTime])

  useEffect(() => {
    const play = (e) => {
      if (e.key === ' ') {
        handlePlayToggle()
      }
    }
    document.addEventListener('keyup', play)
    return () => {
      document.removeEventListener('keyup', play)
    }
  }, [handlePlayToggle])

  const seek = useCallback(
    (e) => {
      onPlayToggle(false)
      const time = Number(e.target.value)
      onTimeChange(time)
    },
    [onTimeChange, onPlayToggle]
  )

  return (
    <div className="flex mt-8 gap-12">
      <button onClick={handlePlayToggle}>{play ? '⏸️' : '▶️'}</button>
      <div className="w-[80px]">{(time / 1000).toFixed(2)}</div>
      <input type="range" min={0} max={maxTime} step={10} style={{ width: '100%' }} value={time} onChange={seek} />
      <div className="w-[80px]">{(maxTime / 1000).toFixed(2)}</div>
    </div>
  )
}
PlayControls.propTypes = {
  play: PropTypes.bool,
  onPlayToggle: PropTypes.func,
  onTimeChange: PropTypes.func,
  time: PropTypes.number,
  maxTime: PropTypes.number
}
