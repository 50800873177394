import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useModal, useSetModal } from '../../../providers/ModalProvider'
import { formatAsPercentage, multiplyBy100 } from '../../../utils/formatter'
import { Modal, ScrollView } from '../../shared'
import ColorBox from '../../shared/ColorBox'
import useColorTextGenerator from '../Hooks/useColorTextGenerator'
import Header from './Header'

type GradientDetailsProps = {
  paint: any
  modalKey: string
}

const GradientInitialModal = ({ paint, modalKey }: GradientDetailsProps) => {
  const ref = React.useRef<HTMLDivElement>(null)
  const { t } = useTranslation('file', { keyPrefix: 'inspect' })
  const { closeModal } = useSetModal(modalKey)

  const {
    open,
    data: { trigger }
  } = useModal((o: any) => o[modalKey])
  const { generateColorString } = useColorTextGenerator()

  const closeStrokeAdvancedModal = useCallback(() => {
    closeModal()
  }, [closeModal])

  const getColorBox = (stop: any) => {
    let a
    if (stop?.color) {
      a = stop?.color?.slice(0, 4)
    }

    return <ColorBox color={a} paintType={0} gradientStops={paint.gradientStops} size="m" />
  }

  useEffect(() => {
    if (!open) return
    const handleOutsideClick = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        closeModal()
      }
    }
    document.addEventListener('click', handleOutsideClick)
    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [closeModal, open])

  return (
    <Modal open={open} trigger={trigger} onClose={closeStrokeAdvancedModal} placement="horizontal" offsetX={224}>
      {open && (
        <div ref={ref} className="pb-16">
          <Header name={t('gradient_details')} modalKey={modalKey} />
          {/* @ts-ignore adjust when ScrollView has been refactored */}
          <ScrollView noScrollbar>
            <div className="max-h-[calc(100vh-100px)]">
              {paint.gradientStops.map((stop: any, index: string) => (
                <div key={index} className="flex flex-col px-8">
                  <div className="flex p-8 gap-8">
                    <div className="w-[68px] text-light-overlay-60">{`${t('stop')} ${index + 1}`}</div>
                  </div>
                  <div className="flex">
                    <div className="w-4 h-24 mt-8 ml-12 border-l border-solid border-light-overlay-60" />
                    <div className="flex p-8 gap-8">
                      <div className="w-[52px] text-light-overlay-60">{t('location')}</div>
                      <div className="text-white">{formatAsPercentage(multiplyBy100(stop?.position))}</div>
                    </div>
                  </div>
                  <div className="flex">
                    <div className="w-4 h-24 mb-8 ml-12 border-l border-solid border-light-overlay-60" />
                    <div className="flex items-center p-8 gap-8">
                      <div className="w-[52px] text-light-overlay-60">{t('color')}</div>
                      {getColorBox(stop)}
                      <div className="text-white">{generateColorString(stop?.color, paint.opacity)}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </ScrollView>
        </div>
      )}
    </Modal>
  )
}

export default React.memo(GradientInitialModal)
