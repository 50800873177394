import React, { forwardRef, useCallback, useMemo } from 'react'
import Icon from '../Icon'

type BaseButtonProps = {
  type?: 'button' | 'submit'
  size?: 's' | 'l' | 'xl'
  iconSize?: 's' | 'l'
  icon?: string
  fluid?: boolean
  className?: string
  children?: string
  variant?: 'solid' | 'ghost' | 'flat'
  color?: 'primary' | 'secondary' | 'danger'
  fixedWidth?: number
} & Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'type'>

const sizeClassNameMap = {
  s: 'h-28',
  l: 'h-32',
  xl: 'h-40'
}

const paddingClassNameMap = {
  s: { text: 'px-8', icon: 'p-6', iconText: 'pl-6 pr-8' },
  l: { text: 'px-12', icon: 'p-8', iconText: 'pl-10 pr-12' },
  xl: { text: 'px-16', icon: 'p-12', iconText: 'pl-14 pr-16' } // only icon is valid, currently no iconText and text in xl size
}

const getContentType = ({ hasIcon, hasText }: { hasIcon: boolean; hasText: boolean }) => {
  if (hasIcon && hasText) {
    return 'iconText'
  }
  return hasIcon ? 'icon' : 'text'
}

const BaseButton = forwardRef<HTMLButtonElement, BaseButtonProps>(
  (
    {
      type = 'button',
      icon = '',
      fluid = false,
      size = 'l',
      className = '',
      children: text = '',
      disabled = false,
      fixedWidth = 0,
      onKeyDown,
      ...rest
    },
    forwardRef
  ) => {
    const contentType = useMemo(() => getContentType({ hasIcon: !!icon, hasText: !!text }), [icon, text])
    const handleKeyDown = useCallback(
      (e: React.KeyboardEvent<HTMLButtonElement>) => {
        onKeyDown?.(e)
        switch (e.key) {
          case ' ':
          case 'Enter':
            e.preventDefault()
            e.currentTarget.click()
            break
          case 'Escape':
            e.currentTarget.blur()
            e.stopPropagation()
            break
        }
      },
      [onKeyDown]
    )

    const handleMouseUp = (e: React.MouseEvent) => {
      ;(e.currentTarget as HTMLButtonElement).blur()
    }

    return (
      <button
        ref={forwardRef}
        className={`${fluid ? 'grid w-full' : 'inline-grid'} ${sizeClassNameMap[size]} ${
          paddingClassNameMap[size][contentType]
        } ${className} grid-flow-col gap-4 items-center justify-center text-12 text-white font-medium capitalize rounded-md disabled:cursor-not-allowed`}
        disabled={disabled}
        type={type}
        {...rest}
        style={{ width: fixedWidth ? `${fixedWidth}px` : undefined }}
        onKeyDown={handleKeyDown}
        onMouseUp={handleMouseUp}
      >
        {icon ? <Icon name={icon} interactive={false} useCurrentColor /> : null}
        {text}
      </button>
    )
  }
)

BaseButton.displayName = 'BaseButton'

export default BaseButton
