import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { toDeg } from '@phase-software/data-utils'
import {
  BooleanOperation,
  ContainerElementType,
  EditMode,
  EffectType,
  ElementType,
  GeometryType,
  PaintType
} from '@phase-software/types'

import { Effect } from '../../../components/Properties/PropertyEditors/EffectEditor/types'
import { EFFECT_PROPS_NAME_MAP, IMAGE_MODE_MAP, MIN_SIZE_VALUE } from '../../../constant'
import { useSetNotification } from '../../../providers/NotificationProvider'
import { useDataStore } from '../../../providers/dataStore/DataStoreProvider'
import { useEditor } from '../../../providers/dataStore/EditorProvider'
import { useSelectEffect } from '../../../providers/dataStore/EffectProvider'
import { usePaint } from '../../../providers/dataStore/PaintProvider'
import { useStroke } from '../../../providers/dataStore/StrokeProvider'
import { useUI } from '../../../providers/dataStore/UIProvider'
import { formatNumberWithUnit } from '../../../utils/formatter'
import useColorTextGenerator from '../../InspectPanel/Hooks/useColorTextGenerator'
import { IconButton } from '../../shared'
import { blendMap, endShapeMap, growDirectionMap, joinShapeMap, trimPathModeMap } from '../constant'
import { addSpaces, parsePath } from '../utils'

const PAINT_CATEGORY_MAP = {
  [PaintType.SOLID]: 'solid',
  [PaintType.IMAGE]: 'image',
  [PaintType.GRADIENT_LINEAR]: 'linear_gradient',
  [PaintType.GRADIENT_RADIAL]: 'radial_gradient',
  [PaintType.GRADIENT_ANGULAR]: 'angular_gradient',
  [PaintType.GRADIENT_DIAMOND]: 'diamond_gradient'
}

const FillDetails = ({ id, onCollectData }: any) => {
  const data = usePaint((o) => o[id])

  useEffect(() => {
    onCollectData(id, data)
  }, [data, onCollectData, id])

  return null
}

const StrokeDetails = ({ id, onCollectData }: any) => {
  const data = usePaint((o) => o[id])
  const strokeData = useStroke((o) => o[id])

  useEffect(() => {
    onCollectData(id, data, strokeData)
  }, [data, onCollectData, strokeData, id])

  return null
}

const DesignCopyButton = () => {
  const { t } = useTranslation(['workspace', 'file'])
  const elementData = useEditor()
  const effectList = useMemo(() => elementData.effectList || [], [elementData.effectList])
  const effect: Effect = useSelectEffect((o) => o[EFFECT_PROPS_NAME_MAP[effectList[0]?.type as EffectType]]) || {}
  const { editMode } = useUI()
  const dataStore = useDataStore()
  const isBooleanGroup = elementData.booleanType !== BooleanOperation.NONE
  const { addNotification } = useSetNotification()
  const { getValues, generateColorString } = useColorTextGenerator()
  const isContainer = elementData.elementType === ElementType.CONTAINER
  const isPath = elementData.elementType === ElementType.PATH
  const isNormalGroup =
    isContainer &&
    elementData.containerType === ContainerElementType.NORMAL_GROUP &&
    !elementData.isMask &&
    !isBooleanGroup
  const isScreen = elementData.elementType === ElementType.SCREEN
  const isShapeMode = editMode === EditMode.SHAPE
  const isComputedGroup = elementData.isMask || isBooleanGroup || isNormalGroup
  const obj = {} as any
  const strokeObj = {} as any

  const handleCollectData = (id: string, data: any) => {
    obj[id] = data
  }

  const handleStrokeCollectData = (id: string, data: any, strokeData: any) => {
    obj[id] = data
    strokeObj[id] = strokeData
  }

  const getPointPosition = useCallback(
    (pos: number[]) => {
      const selection = dataStore.selection.get('elements')[0]
      const verticesBounds = dataStore.drawInfo.getVerticesBoundWorld(selection)
      const vertexPos = dataStore.drawInfo.vertex2World(selection.data.id, pos)
      const pathPointX = vertexPos[0] - verticesBounds.x
      const pathPointY = vertexPos[1] - verticesBounds.y
      return `{"X": "${formatNumberWithUnit(pathPointX, 'px', 2)}", "Y": "${formatNumberWithUnit(
        pathPointY,
        'px',
        2
      )}"}`
    },
    [dataStore]
  )

  const getBasicData = (): string => {
    let value = ''
    if (!isScreen)
      value += `"${t('file:inspect.position')}": {"X": "${formatNumberWithUnit(
        elementData.x,
        'px'
      )}", "Y": "${formatNumberWithUnit(elementData.y, 'px')}"},\n`
    value += `"${t('file:inspect.width')}": "${formatNumberWithUnit(elementData.width, 'px')}",\n`
    value += `"${t('file:inspect.height')}": "${formatNumberWithUnit(elementData.height, 'px')}",\n`
    if (!isScreen)
      value += `"${t('file:inspect.rotation')}": "${formatNumberWithUnit(toDeg(elementData.rotation), 'deg')}",\n`
    if (
      !isScreen &&
      !isShapeMode &&
      elementData.booleanType === BooleanOperation.NONE &&
      !elementData.isMask &&
      !(
        elementData.elementType === ElementType.CONTAINER &&
        elementData.containerType === ContainerElementType.NORMAL_GROUP
      ) &&
      !isNaN(Number(formatNumberWithUnit(elementData.cornerRadius)))
    ) {
      value += `"${t('file:inspect.corner_radius')}": "${formatNumberWithUnit(elementData.cornerRadius, 'px')}",\n`
    }
    return value
  }

  const getOverflowData = (): string => {
    let value = ''
    if (isScreen || (!isComputedGroup && elementData.elementType === ElementType.CONTAINER)) {
      value += `"${t('file:inspect.overflow')}": "${
        elementData.overflowX ? t('file:overflow.hide') : t('file:overflow.show')
      }",\n`
    }
    return value
  }

  const getTransformAndOpacityData = (): string => {
    let value = ''
    if (!isScreen) {
      value += `"${t('file:inspect.origin')}": {"X": "${formatNumberWithUnit(
        elementData.contentAnchorX,
        'px'
      )}", "Y": "${formatNumberWithUnit(elementData.contentAnchorY, 'px')}"},\n`
      value += `"${t('file:inspect.scale_x')}": ${formatNumberWithUnit(elementData.scaleX)},\n`
      value += `"${t('file:inspect.scale_y')}": ${formatNumberWithUnit(elementData.scaleY)},\n`
      value += `"${t('file:inspect.skew_x')}": "${formatNumberWithUnit(toDeg(elementData.skewX), 'deg')}",\n`
      value += `"${t('file:inspect.skew_y')}": "${formatNumberWithUnit(toDeg(elementData.skewY), 'deg')}",\n`
      value += `"${t('file:inspect.blend')}": "${
        (t(blendMap[elementData.blendMode]).toLowerCase(), { ns: 'file', keyPrefix: 'layer_blend_mode' })
      }",\n`
      value += `"${t('file:inspect.opacity')}": ${formatNumberWithUnit(elementData.opacity)},\n`
    }
    return value
  }

  const getFillsData = (): string => {
    let value = ''
    if (elementData?.fillList?.length > 0) {
      value += `"${t('file:inspect.fills')}": {\n`
      elementData?.fillList
        ?.slice()
        .reverse()
        .forEach((id: string, index: number) => {
          value += `${addSpaces(2)}"${t('file:inspect.fill')} ${elementData.fillList.length - index}": {\n`
          value += `${addSpaces(4)}"${t('file:inspect.type')}": "${t(
            PAINT_CATEGORY_MAP[obj[id]?.paintType as keyof typeof PAINT_CATEGORY_MAP],
            {
              ns: 'file',
              keyPrefix: 'inspect'
            }
          )}",\n`
          if (obj[id]?.paintType === PaintType.SOLID)
            value += `${addSpaces(4)}"${t('file:inspect.color')}": "${getValues(
              obj[id]?.paintType,
              obj[id]?.color,
              obj[id]?.opacity,
              false
            )}",\n`
          if (obj[id]?.paintType === PaintType.IMAGE)
            value += `${addSpaces(4)}"${t('file:inspect.image_content')}": "image.png",\n`
          if (obj[id]?.paintType !== PaintType.SOLID)
            value += `${addSpaces(4)}"${t('file:inspect.opacity')}": "${obj[id]?.opacity}",\n`
          if (obj[id]?.paintType === PaintType.IMAGE)
            value += `${addSpaces(4)}"${t('file:inspect.image_mode')}": "${t(
              IMAGE_MODE_MAP[obj[id]?.imageMode as keyof typeof IMAGE_MODE_MAP],
              {
                ns: 'file',
                keyPrefix: 'image_mode'
              }
            )}",\n`
          if (obj[id]?.paintType !== PaintType.SOLID && obj[id]?.paintType !== PaintType.IMAGE) {
            value += `${addSpaces(4)}"${t('file:inspect.gradient_stops')}": [\n`
            obj[id]?.gradientStops.forEach((stop: any, index: number) => {
              value += `${addSpaces(6)}"${t('file:inspect.stop')} ${index + 1}": {`
              value += `"${t('file:inspect.color')}": "${generateColorString(
                stop?.color,
                obj[id]?.opacity,
                false
              )}", "${t('file:inspect.location')}": ${formatNumberWithUnit(stop?.position)}}`
              if (index !== obj[id]?.gradientStops.length - 1) value += `,`
              value += '\n'
            })
            value += `${addSpaces(4)}]\n`
          }
          value += `${addSpaces(2)}},\n`
        })
      value += `},\n`
    }
    return value
  }

  const getBordersData = (): string => {
    let value = ''
    if (!isScreen && elementData?.strokeList?.length > 0) {
      value += `"${t('file:inspect.borders')}": {\n`
      elementData?.strokeList
        ?.slice()
        .reverse()
        .forEach((id: string, index: number) => {
          value += `${addSpaces(2)}"${t('file:inspect.border')} ${elementData.strokeList.length - index}": {\n`
          value += `${addSpaces(4)}"${t('file:inspect.type')}": "${t(
            PAINT_CATEGORY_MAP[obj[id]?.paintType as keyof typeof PAINT_CATEGORY_MAP],
            {
              ns: 'file',
              keyPrefix: 'inspect'
            }
          )}",\n`
          if (obj[id]?.paintType === PaintType.SOLID)
            value += `${addSpaces(4)}"${t('file:inspect.color')}": "${getValues(
              obj[id]?.paintType,
              obj[id]?.color,
              obj[id]?.opacity,
              false
            )}",\n`
          if (obj[id]?.paintType !== PaintType.SOLID)
            value += `${addSpaces(4)}"${t('file:inspect.opacity')}": "${obj[id]?.opacity}",\n`
          if (obj[id]?.paintType !== PaintType.SOLID) {
            value += `${addSpaces(4)}"${t('file:inspect.gradient_stops')}": [\n`
            obj[id]?.gradientStops.forEach((stop: any, index: number) => {
              value += `${addSpaces(6)}"${t('file:inspect.stop')} ${index + 1}": {`
              value += `"${t('file:inspect.color')}": "${generateColorString(
                stop?.color,
                obj[id]?.opacity,
                false
              )}", "${t('file:inspect.location')}": ${formatNumberWithUnit(stop?.position)}}`
              if (index !== obj[id]?.gradientStops.length - 1) value += `,`
              value += '\n'
            })
            value += `${addSpaces(4)}]\n`
          }
          value += `${addSpaces(4)}"${t('file:inspect.border_width')}": "${formatNumberWithUnit(
            strokeObj[id]?.width,
            'px'
          )}",\n`
          value += `${addSpaces(4)}"${t('file:inspect.grow_direction')}": "${t(
            growDirectionMap[strokeObj[id]?.growDirection],
            {
              ns: 'file',
              keyPrefix: 'stroke_direction'
            }
          ).toLowerCase()}",\n`
          value += `${addSpaces(4)}"${t('file:inspect.join_shape')}": "${t(joinShapeMap[strokeObj[id]?.join], {
            ns: 'file',
            keyPrefix: 'stroke_join'
          }).toLowerCase()}",\n`
          value += `${addSpaces(4)}"${t('file:inspect.dash')}": "${formatNumberWithUnit(
            strokeObj[id]?.dash[0] || 0,
            'px'
          )}",\n`
          value += `${addSpaces(4)}"${t('file:inspect.gap')}": "${formatNumberWithUnit(
            strokeObj[id]?.gap[0] || 0,
            'px'
          )}",\n`
          value += `${addSpaces(4)}"${t('file:inspect.miter_angle')}": "${formatNumberWithUnit(
            strokeObj[id]?.miter,
            'deg'
          )}",\n`
          value += `${addSpaces(4)}"${t('file:inspect.ends')}": "${t(endShapeMap[strokeObj[id]?.ends], {
            ns: 'file',
            keyPrefix: 'stroke_end_shape'
          }).toLowerCase()}",\n`
          value += `${addSpaces(2)}},\n`
        })
      value += `},\n`
    }
    return value
  }

  const getEffectData = (): string => {
    let value = ''
    if (!isScreen && effectList.length > 0) {
      value += `"${t('file:inspect.trim_path')}": {\n`
      value += `${addSpaces(2)}"${t('file:inspect.trim')} ${t('file:inspect.start')}": ${formatNumberWithUnit(
        effect.start
      )},\n`
      value += `${addSpaces(2)}"${t('file:inspect.trim')} ${t('file:inspect.end')}": ${formatNumberWithUnit(
        effect.end
      )},\n`
      value += `${addSpaces(2)}"${t('file:inspect.trim')} ${t('file:inspect.offset')}": ${formatNumberWithUnit(
        effect.offset
      )},\n`
      value += `${addSpaces(2)}"${t('file:inspect.trim')} ${t('file:inspect.mode')}": "${t(
        trimPathModeMap[effect.mode],
        {
          ns: 'file',
          keyPrefix: 'trim_path_mode'
        }
      ).toLowerCase()}"\n`
      value += `},\n`
    }
    return value
  }

  const getPathData = (): string => {
    let value = ''
    let currentMesh = null
    if (
      isPath &&
      (elementData.geometryType === GeometryType.POLYGON || elementData.geometryType === GeometryType.LINE)
    ) {
      currentMesh = dataStore.selection.get('elements')[0]?.get('geometry')?.get('mesh')
    }
    let verticesData = [] as any
    if (currentMesh) {
      const path = dataStore?.drawInfo?.getMeshOutlinesForExport(currentMesh, [0, 0])
      verticesData = parsePath(
        path,
        currentMesh.bounds.width < MIN_SIZE_VALUE,
        currentMesh.bounds.height < MIN_SIZE_VALUE
      )
    }

    if (
      isPath &&
      (elementData.geometryType === GeometryType.POLYGON || elementData.geometryType === GeometryType.LINE) &&
      verticesData.length > 0
    ) {
      value += `"${t('file:inspect.path_shape')}": {\n`
      verticesData[0]?.segments.forEach((point: any, index: number) => {
        value += `${addSpaces(2)}"${t('file:inspect.point')} ${index + 1}": {\n`
        value += `${addSpaces(4)}"${t('file:inspect.position')}": ${getPointPosition([point.v[0], point.v[1]])},\n`
        if (point.i[0] !== 0 && point.i[1] !== 0)
          value += `${addSpaces(4)}"${t('file:inspect.in')} ${t('file:inspect.position')}": ${getPointPosition([
            point.i[0] + point.v[0],
            point.i[1] + point.v[1]
          ])},\n`
        if (point.o[0] !== 0 && point.o[1] !== 0)
          value += `${addSpaces(4)}"${t('file:inspect.out')} ${t('file:inspect.position')}": ${getPointPosition([
            point.o[0] + point.v[0],
            point.o[1] + point.v[1]
          ])}\n`
        value += index === verticesData[0]?.segments.length - 1 ? `${addSpaces(2)}}\n` : `${addSpaces(2)}},\n`
      })
      value += `}\n`
    }
    return value
  }

  const handleClick = () => {
    let value = ''
    value += getBasicData()
    value += getOverflowData()
    value += getTransformAndOpacityData()
    value += getFillsData()
    value += getBordersData()
    value += getEffectData()
    value += getPathData()

    navigator.clipboard.writeText(value?.toString() || '')

    addNotification({
      type: 'success',
      content: t('workspace:message.copied_to_clipboard')
    })
  }

  return (
    <div>
      {elementData?.fillList
        ?.slice()
        .reverse()
        .map((id: string, index: number) => (
          <FillDetails key={id} id={id} onCollectData={handleCollectData} index={index} />
        ))}
      {elementData?.strokeList
        ?.slice()
        .reverse()
        .map((id: string, index: number) => (
          <StrokeDetails key={id} id={id} onCollectData={handleStrokeCollectData} index={index} />
        ))}
      <IconButton icon="Copy" onClick={handleClick} />
    </div>
  )
}

export default DesignCopyButton
