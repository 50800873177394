import React from 'react'
import { useTranslation } from 'react-i18next'

import { divideBy100, formatAsPercentage, multiplyBy100 } from '../../../../utils/formatter'
import { percentageFormatValidator } from '../../../../utils/validator'
import Content from '../../../shared/Content'
import WideTitle from '../../../shared/WideTitle'
import Property from '../Property'
import PropertyRow from '../PropertyRow'
import Visible from '../Visible'
import BlendModeSelect from './BlendModeSelect'

type OpacityEditorProps = {
  elementIds: string[]
}

const OpacityEditor = ({ elementIds }: OpacityEditorProps) => {
  const { t } = useTranslation('file', { keyPrefix: 'property_editor.opacity_editor' })

  return (
    <>
      <WideTitle dividerPosition="top">{t('title')}</WideTitle>
      <Content withHeader>
        <PropertyRow columns={[124, 64]}>
          <BlendModeSelect />
          <Property
            field="opacity"
            data-test-id="opacity-input"
            toDisplay={multiplyBy100}
            toValue={divideBy100}
            formatter={formatAsPercentage}
            min={0}
            max={100}
            validator={percentageFormatValidator}
          />
          <Visible elementIds={elementIds} />
        </PropertyRow>
      </Content>
    </>
  )
}

export default React.memo(OpacityEditor)
