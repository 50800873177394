import { TeamFieldsFragment, TeamUserFieldsFragment, TeamUserInvitationFieldsFragment } from '../generated/graphql'
import {
  CreateTeamInvitationRequestTeamRoleEnum,
  PatchTeamUserRequestTeamRoleEnum,
  UpdateInvitationRoleRequestRoleEnum
} from '../generated/api'
import { teamUserService } from '../services/api'

export interface RemoveTeamMemberParams {
  teamId: TeamUserFieldsFragment['team_id']
  userId: TeamUserFieldsFragment['user_id']
}

export interface UpdateTeamMemberRoleParams extends RemoveTeamMemberParams {
  teamRole?: PatchTeamUserRequestTeamRoleEnum
}

interface TeamInvitationActionParams {
  teamId: TeamUserFieldsFragment['team_id']
  invitationId: TeamUserInvitationFieldsFragment['id']
}

export interface UpdateTeamInvitationRoleParams extends TeamInvitationActionParams {
  role: UpdateInvitationRoleRequestRoleEnum
}

const useTeamMemberActions = () => {
  const inviteTeamMember = ({
    teamId,
    email,
    teamRole
  }: {
    teamId: TeamFieldsFragment['id']
    email: string
    teamRole: CreateTeamInvitationRequestTeamRoleEnum
  }) =>
    teamUserService.teamUserControllerCreateTeamInvitation({
      teamId,
      createTeamInvitationRequest: { email, teamRole }
    })

  const removeTeamMember = ({ teamId, userId }: RemoveTeamMemberParams) =>
    teamUserService.teamUserControllerDeleteTeamUser({
      teamId,
      userId
    })

  const updateTeamMemberRole = ({ teamId, userId, teamRole }: UpdateTeamMemberRoleParams) =>
    teamUserService.teamUserControllerPatchTeamUser({
      teamId,
      userId,
      patchTeamUserRequest: {
        teamRole,
        updateMask: ['teamRole']
      }
    })

  const updateTeamInvitationRole = ({ teamId, invitationId, role }: UpdateTeamInvitationRoleParams) =>
    teamUserService.teamUserControllerUpdateTeamInvitationRole({
      teamId,
      invitationId,
      updateInvitationRoleRequest: { role }
    })

  const joinTeam = ({ teamId, invitationId }: { teamId: TeamFieldsFragment['id']; invitationId: string }) =>
    teamUserService.teamUserControllerAcceptTeamInvitation({
      teamId,
      invitationId
    })

  const resendInvitation = ({ teamId, invitationId }: TeamInvitationActionParams) =>
    teamUserService.teamUserControllerSendTeamInvitationEmail({
      teamId,
      invitationId
    })

  const cancelInvitation = ({ teamId, invitationId }: TeamInvitationActionParams) =>
    teamUserService.teamUserControllerCancelTeamInvitation({
      teamId,
      invitationId
    })

  return {
    cancelInvitation,
    inviteTeamMember,
    joinTeam,
    removeTeamMember,
    resendInvitation,
    updateTeamInvitationRole,
    updateTeamMemberRole
  }
}

export default useTeamMemberActions
