export const IRNodeType = {
  SCREEN: 'SCREEN',
  PRECOMPOSITION: 'PRECOMPOSITION',
  CONTAINER: 'CONTAINER',
  COMPUTED_GROUP: 'COMPUTED_GROUP',
  BOOLEAN: 'BOOLEAN',
  IMAGE_MASK: 'IMAGE_MASK',
  IMAGE: 'IMAGE',
  PATH: 'PATH',
  ELLIPSE: 'ELLIPSE',
  RECTANGLE: 'RECTANGLE'
}

export const IRAssetType = {
  IMAGE: 'IMAGE',
  PRECOMPOSITION: 'PRECOMPOSITION'
}

export const IRPaintType = {
  SOLID: 'SOLID',
  IMAGE: 'IMAGE',
  GRADIENT: 'GRADIENT'
}

export const IRGradientType = {
  LINEAR: 'LINEAR',
  RADIAL: 'RADIAL',
  ANGULAR: 'ANGULAR',
  DIAMOND: 'DIAMOND'
}

export const IRBooleanType = {
  NONE: 'NONE',
  UNION: 'UNION',
  SUBTRACT: 'SUBTRACT',
  INTERSECT: 'INTERSECT',
  DIFFERENCE: 'DIFFERENCE'
}

export const IREffectType = {
  TRIM_PATH: 'TRIM_PATH'
}

export const IRTrimMode = {
  SIMULTANEOUSLY: 'SIMULTANEOUSLY',
  INDIVIDUALLY: 'INDIVIDUALLY'
}

export const IRBlendMode = {
  PASS_THROUGH: 'PASS_THROUGH',
  NORMAL: 'NORMAL',
  DARKEN: 'DARKEN',
  MULTIPLY: 'MULTIPLY',
  COLOR_BURN: 'COLOR_BURN',
  LIGHTEN: 'LIGHTEN',
  SCREEN: 'SCREEN',
  COLOR_DODGE: 'COLOR_DODGE',
  OVERLAY: 'OVERLAY',
  SOFT_LIGHT: 'SOFT_LIGHT',
  HARD_LIGHT: 'HARD_LIGHT',
  DIFFERENCE: 'DIFFERENCE',
  EXCLUSION: 'EXCLUSION',
  HUE: 'HUE',
  SATURATION: 'SATURATION',
  COLOR: 'COLOR',
  LUMINOSITY: 'LUMINOSITY',
  DIVIDE: 'DIVIDE',
  ADD: 'ADD',
  SUBTRACT: 'SUBTRACT',
  DISSOLVE: 'DISSOLVE'
}

export const IRImageMode = {
  FILL: 'FILL',
  FIT: 'FIT',
  STRETCH: 'STRETCH'
}
