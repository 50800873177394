import { createPropTypes, setAdd } from '@phase-software/data-utils'
import { DEFAULT_STROKE_VALUE } from '../constant'
import { ComputedFill } from './ComputedFill'


/** @typedef {import('./Stroke').GrowDirection} GrowDirection */
/** @typedef {import('./Stroke').CapShape} CapShape */
/** @typedef {import('./Stroke').JoinShape} JoinShape */
/** @typedef {import('./Stroke').EndsShape} EndsShape */


const PROP_TYPES = createPropTypes({
    dash: { type: Array },
    gap: { type: Array }
})

const PROP_KEYS_LAYER_TO_CL = {
    width: null,
    growDirection: null,
    offset: null,
    dash: null,
    gap: null,
    cap: null,
    capSize: null,
    join: null,
    joinSize: null,
    miter: null,
    ends: null,
}

const PROP_KEYS_CL_TO_LAYER = [
    'width',
    'growDirection',
    'offset',
    'dash',
    'gap',
    'cap',
    'capSize',
    'join',
    'joinSize',
    'miter',
    'ends'
]

const UNDO_CHANGES = [
    'width',
    'growDirection',
    'offset',
    'dash',
    'gap',
    'cap',
    'capSize',
    'join',
    'joinSize',
    'miter',
    'ends'
]


export class ComputedStroke extends ComputedFill {
    /**
     * @param {DataStore} dataStore
     * @param {ComputedStrokeData} data
     */
    constructor(dataStore, data) {
        super(dataStore, data)

        setAdd(this.undoChanges, UNDO_CHANGES)
    }

    _init() {
        super._init()

        this.propTypes = { ...this.propTypes, ...PROP_TYPES }

        this._propKeysLayerToCL = { ...this._propKeysLayerToCL, ...PROP_KEYS_LAYER_TO_CL }
        this._propKeysCLToLayer = [ ...this._propKeysCLToLayer, ...PROP_KEYS_CL_TO_LAYER ]
    }

    /**
     * @param {ComputedStrokeData} data
     */
    load(data) {
        super.load(data)

        this.data.width = data.width
        this.data.growDirection = data.growDirection
        this.data.offset = data.offset
        this.data.dash = data.dash.map(n => n)
        this.data.gap = data.gap.map(n => n)
        this.data.cap = data.cap
        this.data.capSize = data.capSize
        this.data.join = data.join
        this.data.joinSize = data.joinSize
        this.data.miter = data.miter
        this.data.ends = data.ends
    }

    defaultValue() {
        return DEFAULT_STROKE_VALUE
    }
}

/**
 * @typedef {ComputedFillData} ComputedStrokeData
 * @property {number} width     stroke width
 * @property {GrowDirection} growDirection
 * @property {number} offset
 * @property {number[]} dash
 * @property {number[]} gap
 * @property {CapShape} cap
 * @property {number} capSize
 * @property {JoinShape} join
 * @property {number} joinSize
 * @property {number} miter
 * @property {EndsShape} ends
 */
