import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { TFunction } from 'i18next'

import { EasingType, FrameType, PaintType } from '@phase-software/types'

import { IMAGE_MODE_MAP } from '../../../constant'
import { useInspectContext } from '../../../providers/InspectProvider'
import { useSetNotification } from '../../../providers/NotificationProvider'
import { useDataStore } from '../../../providers/dataStore/DataStoreProvider'
import { useInteractionActions } from '../../../providers/dataStore/InteractionProvider'
import { formatNumberWithUnit } from '../../../utils/formatter'
import { easingTypeNameMap } from '../../Interaction/constant'
import useColorTextGenerator from '../Hooks/useColorTextGenerator'
import { blendMap, endShapeMap, joinShapeMap, layerPropertyKeyMap } from '../constant'
import { addSpaces, commonTextFormatter, degreeTextFormatter, ratioFormatter } from '../utils'

type CopyKeyframesDataProps = {
  animationKeyFrames: any
  target: string
  elementId?: string
  property?: string
  originPaint?: any
  isLayer?: boolean
}

const formatterMap = (t: TFunction) =>
  ({
    width: commonTextFormatter,
    height: commonTextFormatter,
    rotation: degreeTextFormatter,
    cornerRadius: commonTextFormatter,
    scaleX: formatNumberWithUnit,
    scaleY: formatNumberWithUnit,
    skewX: degreeTextFormatter,
    skewY: degreeTextFormatter,
    opacity: formatNumberWithUnit,
    'trimPath.start': ratioFormatter,
    'trimPath.end': ratioFormatter,
    'trimPath.offset': ratioFormatter,
    blendMode: (value: number) => `"${t(`file:layer_blend_mode.${blendMap[value]}`).toLowerCase()}"`
  } as any)

const layerFormatterMap = (t: TFunction) =>
  ({
    width: commonTextFormatter,
    opacity: formatNumberWithUnit,
    ends: (value: number) => `"${t(`file:stroke_end_shape.${endShapeMap[value]}`).toLowerCase()}"`,
    join: (value: number) => `"${t(`file:stroke_join.${joinShapeMap[value]}`).toLowerCase()}"`,
    dash: commonTextFormatter,
    gap: commonTextFormatter,
    miter: degreeTextFormatter
  } as any)

const titleMap = {
  width: 'width',
  height: 'height',
  rotation: 'rotation',
  cornerRadius: 'corner_radius',
  scaleX: 'scale_x',
  scaleY: 'scale_y',
  skewX: 'skew_x',
  skewY: 'skew_y',
  opacity: 'opacity',
  'trimPath.start': 'trim_start',
  'trimPath.end': 'trim_end',
  'trimPath.offset': 'trim_offset',
  blendMode: 'blend',
  contentAnchor: 'origin'
} as any

export const useCopyKeyframesData = () => {
  const { t } = useTranslation(['common', 'file'])
  const dataStore = useDataStore()
  const { getKeyFrame } = useInteractionActions()
  const { getTimeString } = useInspectContext()
  const { getValues, generateColorString } = useColorTextGenerator()
  const { addNotification } = useSetNotification()

  const copyKeyframesData = useCallback(
    ({ animationKeyFrames, target, isLayer = false, elementId, originPaint }: CopyKeyframesDataProps) => {
      let value = ''
      Object.keys(animationKeyFrames)?.forEach((time: string, index: number) => {
        const keyframe = getKeyFrame(animationKeyFrames[time][0])
        const isInitial = keyframe.frameType === FrameType.INITIAL
        value += `"${t('file:inspect.keyframe_time')}": "${getTimeString(Number(time))}",\n`
        if (target === 'contentAnchor') {
          const originValue = dataStore.transition.getPropertyValue(elementId, 'contentAnchor')
          value += `"${t('file:inspect.origin')}": `
          value += `{"X": "${formatNumberWithUnit(
            isInitial ? originValue.contentAnchorX : keyframe.value.contentAnchorX,
            'px'
          )}", `
          value += `"Y": "${formatNumberWithUnit(
            isInitial ? originValue.contentAnchorY : keyframe.value.contentAnchorY,
            'px'
          )}"},\n`
        } else if (isLayer) {
          const paint = dataStore.library.getComponent(keyframe.ref)
          if (paint) {
            if (paint.paintType === PaintType.SOLID) {
              value += `"${t('file:inspect.color')}": "${
                isInitial
                  ? getValues(originPaint.paintType, originPaint.color, originPaint.opacity, false)
                  : getValues(paint.paintType, paint.color, paint.opacity, false)
              }",\n`
            } else if (paint.paintType === PaintType.IMAGE) {
              value += `"${t('file:inspect.image_mode')}": "${
                isInitial
                  ? t(IMAGE_MODE_MAP[originPaint.imageMode as keyof typeof IMAGE_MODE_MAP], {
                      ns: 'file',
                      keyPrefix: 'image_mode'
                    })
                  : t(IMAGE_MODE_MAP[paint.imageMode as keyof typeof IMAGE_MODE_MAP], {
                      ns: 'file',
                      keyPrefix: 'image_mode'
                    })
              }",\n`
            } else {
              value += `"${t('file:inspect.gradient_stops')}": [\n`
              if (isInitial) {
                originPaint.gradientStops.forEach((stop: any, index: number) => {
                  value += `${addSpaces(2)}{\n`
                  value += `${addSpaces(4)}"${t('file:inspect.stop')} ${index + 1}": `
                  value += `{"${t('file:inspect.color')}": "${generateColorString(
                    stop.color,
                    originPaint.opacity,
                    false
                  )}", "Location": ${formatNumberWithUnit(stop.position)}},\n`
                  value += `${addSpaces(2)}},\n`
                })
              } else {
                paint.gradientStops.forEach((stop: any, index: number) => {
                  value += `${addSpaces(2)}{\n`
                  value += `${addSpaces(4)}"Stop ${index + 1}": `
                  value += `{"${t('file:inspect.color')}": "${generateColorString(
                    stop.color,
                    paint.opacity,
                    false
                  )}", "${t('file:inspect.location')}": ${formatNumberWithUnit(stop.position)}},\n`
                  value += `${addSpaces(2)}},\n`
                })
              }
              value += '],\n'
            }
          } else {
            value += `"${t(layerPropertyKeyMap[target.split('.')[1]], {
              ns: 'file',
              keyPrefix: 'inspect'
            })}": ${layerFormatterMap(t)[target.split('.')[1]](keyframe.value)},\n`
          }
        } else {
          value += `"${t(titleMap[target], {
            ns: 'file',
            keyPrefix: 'inspect'
          })}": ${formatterMap(t)[target](
            isInitial ? dataStore.transition.getPropertyValue(elementId, target)[target] : keyframe.value
          )},\n`
        }
        value += `"${t('file:inspect.ease')}": "${t(
          easingTypeNameMap[keyframe.easingType as keyof typeof easingTypeNameMap],
          {
            ns: 'file',
            keyPrefix: 'easing_type'
          }
        )?.toLowerCase()}",\n`
        if (keyframe.bezier && keyframe.easingType !== EasingType.STEP_END) {
          value += `"${t('file:inspect.bezier')}": "(${keyframe.bezier.join(', ')})",\n`
        }
        if (index < Object.keys(animationKeyFrames).length - 1) {
          value += '\n'
        }
      })
      navigator.clipboard.writeText(value?.toString() || '')
      addNotification({
        type: 'success',
        content: t('workspace:message.copied_to_clipboard')
      })
    },
    [
      getTimeString,
      getKeyFrame,
      generateColorString,
      getValues,
      dataStore.library,
      dataStore.transition,
      addNotification,
      t
    ]
  )

  return copyKeyframesData
}
