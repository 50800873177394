import {
  ElementType,
  GeometryType,
  FrameType,
  LayerType as PhaseLayerType,
  EasingType,
  PaintType,
  EffectType,
  BooleanOperation,
  ContainerElementType
} from '@phase-software/types'
import { parseGradientTransform, Track } from '@phase-software/data-utils'
import { Animation, PropertyType, LayerType, ShapeType, StrokeDashType } from '@phase-software/lottie-js'
import {
  deg2rad,
  frameToMs,
  parseColor,
  parseHex,
  toPixelUnit,
  toPercentage,
  parseGradientColors,
  getPaintType,
  parseBezier,
  parseBlendMode,
  parseMaskInvert,
  parseTrimMode,
  parseTrimOffset,
  parseBooleanType,
  parseCap,
  parseJoin,
  parseEnds,
  getBezierValue,
  findSegment,
  parseDashList,
  getPercentage,
  isShapeShape,
  parsePathMorphing,
  getElementType,
  calculateGradientPoint,
  getLocalStartEndAtTime,
  getFullGroupSize,
  isOnlyPathInGroup,
  base64ToBlob
} from './utils'
import { Feature } from './features'
import { analyzeLottieFeature } from './analyzer'

const yieldToMain = () => new Promise(resolve => setTimeout(resolve, 0))

const mergeProperties = (properties, delta = false) => {
  const tracks = properties
    .map(prop => ({
      value: prop.value,
      keyFrames: prop.kfs.map(kf => ({
        value: kf.value[0],
        time: kf.time,
        easing: kf.bezier,
        step: kf.step
      }))
    }))
    .map(trackProp => new Track(trackProp, { delta }))

  const timeSet = new Set(
    tracks
      .map(track => track.times)
      .flat()
      .sort((a, b) => a - b)
  )
  const mergedProp = {
    value: properties.map(prop => prop.value),
    kfs: []
  }

  timeSet.forEach(time => {
    let easing
    let hold
    const values = tracks.map(track => {
      if (!easing && track.hasTime(time)) {
        const interval = track.getInterval(time)
        console.log(interval)
        easing = interval.easing
        hold = interval.step
      }
      return track.getValueAtTime(time)
    })
    mergedProp.kfs.push({
      value: values,
      bezier: easing,
      step: hold,
      time
    })
  })

  return mergedProp
}

class BaseLottieImporter {
  constructor(dataStore, options) {
    this.usedFeatures = new Set()
    this.animation = new Animation()
    this.dataStore = dataStore
    this.maxTime = 0
    this._precompositionLayerIds = new Set()
    this.options = options
    this._layerCount = 0
    this._totalLayerCount = 0
  }

  addUsedFeature(feature) {
    if (Array.isArray(feature)) {
      feature.forEach(feat => {
        this.usedFeatures.add(feat)
      })
    } else {
      this.usedFeatures.add(feature)
    }
  }

  clear() {
    this.usedFeatures.clear()
    this._precompositionLayerIds.clear()
    this.dataStore.clear()
    this.animation = new Animation()
    this._layerCount = 0
    this._totalLayerCount = 0
    this._onProgress = () => {}
  }

  async parse(
    json,
    onProgress = () => {},
    { parseAsElement = false, rootElementType = ElementType.SCREEN, rootElementName } = {}
  ) {
    this.clear()
    this._onProgress = onProgress

    const proxyedJson = analyzeLottieFeature(json, this.addUsedFeature.bind(this))

    this.animation.fromJSON(proxyedJson)

    if (process.env.NODE_ENV !== 'test') {
      console.log(this.usedFeatures)
    }

    this.dataStore.create()
    this.dataStore.addWorkspace()
    const rootElement = await this.parseAnimation(this.animation, { rootElementType, rootElementName })
    return parseAsElement ? this.dataStore.serializeElements([rootElement]) : this.dataStore.save()
  }

  _setTransitionTime(time) {
    this.dataStore.transition.prepareActionTransition()
    this.dataStore.transition.setPlayheadTime(time, true, false)
  }

  _setKeyFrameEasing(keyframeId, keyframe) {
    if (keyframe.step) {
      this.dataStore.interaction.setKeyFrameEasingType(keyframeId, EasingType.STEP_END)
    } else {
      this.dataStore.interaction.setKeyFrameBezier(keyframeId, keyframe.bezier)
    }
  }

  setInteractionMeta(start, end, fps) {
    const maxTime = Math.round(Math.round((end / fps) * 1000) / 10) * 10
    const actionId = this.dataStore.interaction.getActionList()[0]
    this.maxTime = maxTime
    this.dataStore.interaction.setActionMaxTime(actionId, maxTime)
  }

  createScreenElement() {
    const screen = this.dataStore.createElement(ElementType.SCREEN, {
      name: 'Screen'
    })
    this.dataStore.workspace.watched.children.push(screen)
    return screen
  }

  createRootElement({ width, height, type = ElementType.SCREEN, name }) {
    let rootElement
    switch (type) {
      case ElementType.SCREEN: {
        rootElement = this.createScreenElement()
        break
      }
      case ElementType.CONTAINER: {
        const screen = this.createScreenElement()

        rootElement = this.dataStore.createElement(ElementType.CONTAINER, {
          name: name || 'Container'
        })

        this.dataStore.addChildren(screen, [rootElement])
        break
      }
      default:
        break
    }

    rootElement.setBaseProps(
      ['dimensions', 'overflow', 'translate', 'referencePoint'],
      [
        { width, height },
        { overflowX: true, overflowY: true },
        { translateX: width / 2, translateY: height / 2 },
        { referencePointX: width / 2, referencePointY: height / 2 }
      ]
    )
    return rootElement
  }

  async parseAnimation(animation, { rootElementType, rootElementName }) {
    await yieldToMain()
    let rootElement

    switch (rootElementType) {
      case ElementType.SCREEN: {
        rootElement = this.createRootElement({ width: animation.width, height: animation.height })
        const fillCompId = rootElement.base.fills[0]
        const fillId = this.dataStore.library.addLayer(fillCompId, 0)
        const fill = this.dataStore.library.getLayer(fillId)
        fill.paint.color = [1, 1, 1, 1]
        break
      }
      case ElementType.CONTAINER:
        rootElement = this.createRootElement({
          width: animation.width,
          height: animation.height,
          name: rootElementName,
          type: ElementType.CONTAINER
        })
        break
      default:
        break
    }

    const baseOffset = -frameToMs(animation.inPoint, animation.frameRate)
    this.setInteractionMeta(0, animation.outPoint - animation.inPoint, animation.frameRate)

    await this.parseLayers(animation.layers, rootElement, baseOffset)

    this._precompositionLayerIds.clear()

    return rootElement
  }

  async parseLayers(layers, rootElement, timeOffset) {
    this._totalLayerCount += layers.length
    return layers.reduce(async (promise, layer) => {
      await promise
      const ancestorList = []

      let parent = layer.parent
      while (parent) {
        ancestorList.push(parent)
        parent = parent.parent
      }

      let container = rootElement
      while (ancestorList.length) {
        const parentLayer = ancestorList.pop()
        container = await this.parseTransform(parentLayer, container, timeOffset, true)
      }
      const resp = await this.parseLayer(layer, container, timeOffset)
      this._layerCount++
      this._onProgress(this._layerCount / this._totalLayerCount)
      return resp
    }, Promise.resolve())
  }

  markNotSupport() {
    // console.log('TODO:', obj)
  }

  async parseLayer(layer, parent, timeOffset) {
    await yieldToMain()

    // TODO: in & out
    if (layer.isHidden) {
      return
    }

    let container
    const layerOpacity = layer.transform.opacity
    const invisibleTime = timeOffset + frameToMs(layer.outPoint, this.animation.frameRate)
    const visibleStartTime = frameToMs(layer.inPoint, this.animation.frameRate) + timeOffset
    const animationStartTime = frameToMs(this.animation.inPoint, this.animation.frameRate)
    const visibleAtBeginning = visibleStartTime <= animationStartTime && invisibleTime > animationStartTime
    const invisibleBeforeEnding = invisibleTime < this.maxTime
    const invisible = layer.inPoint === layer.outPoint

    const opacity = {
      value: visibleAtBeginning && !invisible ? 100 : 0,
      kfs: []
    }

    let visibleContainer = parent
    let replaceLayerOpacity = false
    if (layerOpacity.isAnimated) {
      if (!visibleAtBeginning && !invisible) {
        opacity.kfs.push({
          time: Math.max(0, timeOffset + Math.min(frameToMs(layer.inPoint, this.animation.frameRate), this.maxTime)),
          step: true,
          value: 100,
          bezier: [0.25, 0.25, 0.75, 0.75]
        })
      }
      if (invisibleBeforeEnding && !invisible) {
        opacity.kfs.push({
          time: Math.max(0, Math.min(invisibleTime, this.maxTime)),
          step: true,
          value: 0,
          bezier: [0.25, 0.25, 0.75, 0.75]
        })
      }
      if (opacity.kfs.length) {
        visibleContainer = this.dataStore.createElement(ElementType.CONTAINER, {
          containerType: ContainerElementType.NORMAL_GROUP,
          name: 'VisibleContainer'
        })
        this.dataStore.addChildrenAt(parent, [visibleContainer], 0)
        this.setBaseAndKeyFrame(visibleContainer, 'opacity', ['opacity'], opacity, toPercentage)
        visibleContainer.setBaseProp('referencePoint', {
          referencePointX: 0,
          referencePointY: 0
        })
      }
    } else {
      if (visibleAtBeginning && !invisible) {
        opacity.value = layerOpacity.values[0].value
      }
      if (!visibleAtBeginning && !invisible) {
        opacity.kfs.push({
          time: Math.max(0, timeOffset + Math.min(frameToMs(layer.inPoint, this.animation.frameRate), this.maxTime)),
          step: true,
          value: layerOpacity.values[0].value,
          bezier: [0.25, 0.25, 0.75, 0.75]
        })
      }
      const invisibleTime = timeOffset + frameToMs(layer.outPoint, this.animation.frameRate)
      if (invisibleBeforeEnding && !invisible) {
        opacity.kfs.push({
          time: Math.max(0, Math.min(invisibleTime, this.maxTime)),
          step: true,
          value: 0,
          bezier: [0.25, 0.25, 0.75, 0.75]
        })
      }
      replaceLayerOpacity = true
    }

    switch (layer.type) {
      case LayerType.SHAPE:
        container = await this.parseShapeLayer(layer, visibleContainer, timeOffset)
        break
      case LayerType.GROUP:
        container = await this.parseGroupLayer(layer, visibleContainer, timeOffset)
        break
      case LayerType.SOLID:
        container = await this.parseSolidLayer(layer, visibleContainer, timeOffset)
        break
      case LayerType.PRECOMPOSITION:
        container = await this.parsePrecompositionLayer(layer, visibleContainer, timeOffset)
        break
      case LayerType.IMAGE:
        container = await this.parseImageLayer(layer, visibleContainer, timeOffset)
        break
      default:
        this.markNotSupport(layer, Feature.LAYER, layer.type)
        break
    }

    if (container) {
      const blendMode = parseBlendMode(layer.blendMode, true)
      container.setBaseProp('opacity', { blendMode })
      container.set('autoOrient', layer.autoOrient)
      if (invisible) {
        container.set('visible', !invisible)
      }

      if (replaceLayerOpacity) {
        this.setBaseAndKeyFrame(container, 'opacity', ['opacity'], opacity, toPercentage)
      }

      if (layer.hasMask) {
        const masksData = layer.masks.map(mask => this.parseMaskData(mask, timeOffset)).filter(Boolean)
        masksData.forEach(mask => {
          const { mesh, origin, size, mode, invert, opacity, pathMorphing } = mask
          const element = this._createPathElement({
            geometryType: GeometryType.POLYGON,
            name: 'Mask',
            mesh,
            origin,
            size,
            translate: { kfs: [], value: [0, 0] },
            pathMorphing
          })

          // create default layer for it
          const fillCompId = element.base.fills[0]
          this.dataStore.library.addLayer(fillCompId, 0)

          const maskInvert = parseMaskInvert(mode, invert)
          // mask only support Add in Phase, the Add with invert can represent the Subtract
          // Subtract if the maskInvert is true
          // Add if the maskInvert is false
          // others if the maskInvert is null (not supported)
          switch (maskInvert) {
            case true:
            case false: {
              // TODO: create mask group
              const maskGroup = this.dataStore.createElement(ElementType.MASK_CONTAINER, {
                name: 'Mask Group',
                invert: maskInvert,
                isMask: true
              })

              if (container.isContainer) {
                this.dataStore.addChildrenAt(container, [maskGroup], 0)
                this.dataStore.addChildren(maskGroup, [...container.children.slice(1), element])
              } else {
                this.parseTransformProps(layer, maskGroup, layer.transform, timeOffset, false)
                maskGroup.setBaseProp('referencePoint', {
                  referencePointX: 0,
                  referencePointY: 0
                })
                const parent = container.get('parent')
                this.dataStore.addChildrenAt(parent, [maskGroup], 0)
                this.dataStore.addChildren(maskGroup, [container, element])
              }

              this.setBaseAndKeyFrame(maskGroup, 'opacity', ['opacity'], opacity, toPercentage)
              break
            }
            default:
              this.markNotSupport(mask, Feature.MASK, mode)
              break
          }
        })
      }

      // FIXME: should check matteParent instead of find the ancestor
      if (layer.matteMode !== undefined) {
        const maskGroup = this.dataStore.createElement(ElementType.MASK_CONTAINER, {
          name: 'Mask Group',
          invert: false,
          isMask: true
        })
        maskGroup.setBaseProp('referencePoint', {
          referencePointX: 0,
          referencePointY: 0
        })
        let layerParent = layer.parent
        let targetParent = visibleContainer

        if (targetParent.get('elementType') !== ElementType.SCREEN && targetParent !== parent) {
          targetParent = this.dataStore.getParentOf(targetParent)
        }
        while (layerParent) {
          targetParent = this.dataStore.getParentOf(targetParent)
          layerParent = layerParent.parent
        }

        this.dataStore.addChildren(maskGroup, targetParent.children.slice(0, 2))
        this.dataStore.addChildrenAt(targetParent, [maskGroup], 0)
      }
    }
  }

  parseMaskData(mask, timeOffset) {
    // mode, inInverted, opacity, points, expansion
    const pathData = this.parsePathVertices([mask.points], timeOffset)
    if (!pathData || pathData.mesh.edges.length < 1) {
      return
    }
    const { origin, size, mesh } = pathData

    return {
      origin,
      size,
      mesh,
      opacity: this.parseAnimatedProp(mask.opacity, timeOffset),
      invert: mask.isInverted,
      mode: mask.mode,
      pathMorphing: pathData.pathMorphing
    }
  }

  async parseShapeLayer(layer, parent, timeOffset) {
    const container = await this.parseTransform(layer, parent, timeOffset)
    await this.parseMultipleShapes(layer.shapes, container, timeOffset)
    return container
  }

  async parseGroupLayer(layer, parent, timeOffset) {
    const container = await this.parseTransform(layer, parent, timeOffset)
    return container
  }

  async parseSolidLayer(layer, parent, timeOffset) {
    const { transform, solidColor, solidWidth, solidHeight } = layer
    const color = parseHex(solidColor)

    const rectangle = this.dataStore.createElement(ElementType.PATH, {
      geometryType: GeometryType.RECTANGLE,
      name: layer.name || 'Rectangle'
    })
    this.parseTransformProps(layer, rectangle, transform, timeOffset, false)

    const fc = this.dataStore.library.getComponent(rectangle.base.fills[0])
    if (fc.layers[0]) {
      this.dataStore.library.deleteLayer(fc.layers[0])
    }
    this.dataStore.addChildrenAt(parent, [rectangle], 0)

    rectangle.setBaseProps(
      ['dimensions', 'referencePoint', 'contentAnchor'],
      [
        { width: solidWidth, height: solidHeight },
        { referencePointX: solidWidth / 2, referencePointY: solidHeight / 2 },
        { contentAnchorX: 0, contentAnchorY: 0 }
      ]
    )

    // set half of the size as the translate value because extra mask group be created later
    if (layer.masks.length) {
      rectangle.setBaseProps(['translate'], [{ translateX: solidWidth / 2, translateY: solidHeight / 2 }])
    }

    const fillCompId = rectangle.base.fills[0]
    const fillId = this.dataStore.library.addLayer(fillCompId, 0)
    const fill = this.dataStore.library.getLayer(fillId)
    fill.paint.color = color

    return rectangle
  }

  // TODO: not create extra container
  async parseImageLayer(layer, parent, timeOffset) {
    const { name = 'Image' } = layer
    const container = await this.parseTransform(layer, parent, timeOffset)

    const rectangle = this.dataStore.createElement(ElementType.PATH, {
      geometryType: GeometryType.RECTANGLE,
      name
    })
    this.dataStore.addChildrenAt(container, [rectangle], 0)

    const imageAsset = this.animation.assets.find(o => o.id === layer.refId)

    rectangle.setBaseProps(
      ['dimensions', 'referencePoint', 'translate', 'contentAnchor'],
      [
        { width: imageAsset.width, height: imageAsset.height },
        { referencePointX: imageAsset.width / 2, referencePointY: imageAsset.height / 2 },
        { translateX: imageAsset.width / 2, translateY: imageAsset.height / 2 },
        { contentAnchorX: 0, contentAnchorY: 0 }
      ]
    )

    const fillComponent = this.dataStore.library.getComponent(rectangle.base.fills[0])
    const fill = this.dataStore.library.getLayer(fillComponent.layers[0])
    fill.paint.paintType = PaintType.IMAGE
    try {
      let blob
      if (imageAsset.embeded) {
        blob = base64ToBlob(imageAsset.data)
      } else {
        // this might have CORS issue, skip the image if it happens
        const imagePath = `${imageAsset.path || ''}${imageAsset.data}`
        const imgReq = await fetch(imagePath)
        const contentType = imgReq.headers.get('content-type').split(';')[0]

        if (contentType.startsWith('image') || contentType === 'binary/octet-stream') {
          blob = await imgReq.blob()
        }
      }

      if (blob) {
        const imageResponse = await this.options.uploadImage(name, blob)
        const image = this.dataStore.images.addImage({
          id: imageResponse.id,
          src: imageResponse.download_url
        })
        fill.paint.imageId = image.data.id
      }
    } catch (error) {
      console.error('Error converting base64 to Blob:', error)
    }

    this.dataStore.addChildrenAt(parent, [container], 0)
    return container
  }

  async parsePrecompositionLayer(layer, parent, baseTimeOffset) {
    const timeOffset = baseTimeOffset + frameToMs(layer.startTime, this.animation.frameRate)
    const comp = this.animation.assets.find(o => o.id === layer.refId)
    const container = await this.parseTransform(layer, parent, baseTimeOffset)
    container.setBaseProps(
      ['dimensions', 'overflow', 'referencePoint'],
      [
        { width: layer.width, height: layer.height },
        { overflowX: true, overflowY: true },
        { referencePointX: layer.width / 2, referencePointY: layer.height / 2 }
      ]
    )
    const currContentAnchor = container.getBaseValue('contentAnchor')
    container.setBaseProp('contentAnchor', {
      contentAnchorX: currContentAnchor.contentAnchorX - layer.width / 2,
      contentAnchorY: currContentAnchor.contentAnchorY - layer.height / 2
    })

    // reposition the precomp container
    this._precompositionLayerIds.add(container.get('id'))

    await this.parseLayers(comp.layers, container, timeOffset)

    return container
  }

  async parseTransformProps(shapeOrLayer, element, transform, timeOffset = 0, parenting = false) {
    // TODO: skew animation
    if (transform.skew) {
      const skew = this.parseAnimatedProp(transform.skew, timeOffset)
      const skewAxis = this.parseAnimatedProp(transform.skewAxis, timeOffset)
      if (skewAxis.value % 180 === 0) {
        // skewX
        this.setBaseAndKeyFrame(element, 'skew', ['skewX'], skew, v => -deg2rad(v))
      } else if (skewAxis.value % 180 === 90) {
        // skewY
        this.setBaseAndKeyFrame(element, 'skew', ['skewY'], skew, v => deg2rad(v))
      } else {
        // TODO: matrix transform
      }
    }

    const anchor = this.parseAnimatedProp(transform.anchor, timeOffset)
    this.setBaseAndKeyFrame(element, 'contentAnchor', ['contentAnchorX', 'contentAnchorY'], anchor, toPixelUnit)

    const position = this.parseAnimatedProp(transform.position, timeOffset)
    if (position.split) {
      this.setBaseValue(element, 'translate', {
        translateX: position.x.value,
        translateY: position.y.value
      })
      const motionPath = mergeProperties([position.x, position.y])
      this.setBaseAndKeyFrame(element, 'translate', ['translateX', 'translateY'], motionPath)
    } else {
      this.setBaseAndKeyFrame(element, 'translate', ['translateX', 'translateY'], position)
    }

    if (transform.rotation) {
      const rotation = this.parseAnimatedProp(transform.rotation, timeOffset)
      this.setBaseAndKeyFrame(element, 'rotation', ['rotation'], rotation, deg2rad)
    }

    if (shapeOrLayer.type !== LayerType.GROUP && !parenting) {
      const opacity = this.parseAnimatedProp(transform.opacity, timeOffset)
      this.setBaseAndKeyFrame(element, 'opacity', ['opacity'], opacity, toPercentage)
    }

    const scale = this.parseAnimatedProp(transform.scale, timeOffset)

    this.setBaseAndKeyFrame(element, 'scale', ['scaleX', 'scaleY'], scale, toPercentage)
  }

  async parseTransform(shapeOrLayer, wrapper, timeOffset = 0, parenting = false) {
    const { name = 'Container', transform } = shapeOrLayer

    const container = this.dataStore.createElement(ElementType.CONTAINER, {
      containerType:
        shapeOrLayer.type === LayerType.PRECOMPOSITION
          ? ContainerElementType.CONTAINER
          : ContainerElementType.NORMAL_GROUP,
      name
    })
    this.parseTransformProps(shapeOrLayer, container, transform, timeOffset, parenting)
    this.dataStore.addChildrenAt(wrapper, [container], 0)

    return container
  }

  setBaseAndKeyFrame(element, propKey, keys, animatedProp, fn = v => v) {
    const base = {}

    if (keys.length === 1) {
      base[keys[0]] = fn(animatedProp.value, keys[0])
    } else {
      keys.reduce((acc, key, ind) => {
        acc[key] = fn(animatedProp.value[ind], key, 0)
        return acc
      }, base)
    }

    // FIXME: cleanup code
    let tKey
    if (propKey === 'contentAnchor') {
      tKey = 'contentAnchor'
    }

    if (propKey === 'translate') {
      tKey = 'motionPath'
    }

    if (propKey === 'opacity' || propKey === 'rotation' || propKey === 'skew') {
      tKey = false
    }
    this.setBaseValue(element, propKey, base)
    this.createKeyFrame(element, keys, animatedProp.kfs, fn, tKey)
  }

  setBaseValue(element, propKey, data) {
    element.setBaseProp(propKey, data)
  }

  // FIXME: cleanup code
  // keyType: individual, group, aggregate
  createKeyFrame(element, keys, kfs = [], fn = v => v, tKey) {
    const elementId = element.get('id')
    // e.g. dimension > width, height
    if (tKey === undefined) {
      kfs.forEach(kf => {
        if (!kf) {
          return
        }
        this._setTransitionTime(kf.time)
        let propKey = keys[0]
        if (propKey === 'pathMorphing') {
          const trackId = this.dataStore.interaction.setProperty(elementId, propKey, kf.value, FrameType.EXPLICIT)
          const keyFrame = this.dataStore.interaction.getKeyFrameByTime(trackId, kf.time)
          if (keyFrame) {
            this._setKeyFrameEasing(keyFrame.id, kf)
          }
        } else {
          kf.value.forEach((value, index) => {
            propKey = keys[index]

            // lottie file might provide x, y, and z but phase only need x and y
            if (!propKey) {
              return
            }

            let val = fn(value, keys[index], kf.time)
            const isDelta = propKey === 'translateX' || propKey === 'translateY'
            if (isDelta) {
              const t = this.dataStore.library.getComponent(element.base.translate)
              val -= t[propKey]
            }
            if (propKey === 'translateX') {
              propKey = 'x'
            }
            if (propKey === 'translateY') {
              propKey = 'y'
            }
            const trackId = this.dataStore.interaction.setProperty(elementId, propKey, val, FrameType.EXPLICIT, isDelta)
            const keyFrame = this.dataStore.interaction.getKeyFrameByTime(trackId, kf.time)
            if (keyFrame) {
              this._setKeyFrameEasing(keyFrame.id, kf)
            }
          })
        }
      })
    }

    if (tKey === 'motionPath') {
      const baseComp = this.dataStore.library.getComponent(element.base.translate)
      kfs.forEach(kf => {
        this._setTransitionTime(kf.time)
        const point = kf.value.map((value, index) => {
          const propKey = keys[index]
          const base = baseComp[propKey]
          const v = fn(value, propKey)
          return v - base
        })
        const keyFrameValue = {
          pos: point,
          in: kf.in || [0, 0],
          out: kf.out || [0, 0]
        }

        const trackId = this.dataStore.interaction.setProperty(elementId, tKey, keyFrameValue, FrameType.EXPLICIT, true)
        const keyFrame = this.dataStore.interaction.getKeyFrameByTime(trackId, kf.time)
        if (keyFrame) {
          this._setKeyFrameEasing(keyFrame.id, kf)
        }
      })
    }

    // e.g. contentAnchor {contentAnchorX, contentAnchorY}
    if (tKey === 'contentAnchor') {
      kfs.forEach(kf => {
        this._setTransitionTime(kf.time)
        const value = kf.value.reduce((acc, value, index) => {
          const propKey = keys[index]
          if (!propKey) {
            return acc
          }

          let val = fn(value, propKey)
          const t = this.dataStore.library.getComponent(element.base.contentAnchor)
          val -= t[propKey]
          acc[propKey] = val
          return acc
        }, {})
        const trackId = this.dataStore.interaction.setProperty(elementId, tKey, value, FrameType.EXPLICIT, true)
        const keyFrame = this.dataStore.interaction.getKeyFrameByTime(trackId, kf.time)
        if (keyFrame) {
          this._setKeyFrameEasing(keyFrame.id, kf)
        }
      })
    }
    // e.g. position > rotation, opacity
    if (tKey === false) {
      kfs.forEach(kf => {
        this._setTransitionTime(kf.time)
        const trackId = this.dataStore.interaction.setProperty(
          elementId,
          keys[0],
          fn(kf.value, keys[0]),
          FrameType.EXPLICIT
        )
        const keyFrame = this.dataStore.interaction.getKeyFrameByTime(trackId, kf.time)
        if (keyFrame) {
          this._setKeyFrameEasing(keyFrame.id, kf)
        }
      })
    }
  }

  async parseMultipleShapes(shapes, container, timeOffset) {
    const shapesClone = shapes.slice()
    // reverse trim
    const trimShapes = shapesClone.filter(shape => shape.type === ShapeType.TRIM)
    if (trimShapes.length > 1) {
      const trimShapeIndex = trimShapes.map(trimShape => shapesClone.indexOf(trimShape))
      trimShapeIndex.forEach((index, idx, arr) => {
        if (arr.length / 2 > idx) {
          return
        }

        shapesClone[index] = trimShapes[trimShapes.length - 1 - idx]
        shapesClone[trimShapeIndex[trimShapes.length - 1 - idx]] = trimShapes[idx]
      })
    }

    let aggregateShapes = []
    return shapesClone.reduce(async (promise, shape, index) => {
      await promise
      if (shape.type === ShapeType.PATH) {
        aggregateShapes.push(shape)
        if (index === shapesClone.length - 1) {
          this.parsePathShapes(aggregateShapes, container, timeOffset)
          aggregateShapes = []
        }
        return
      } else if (aggregateShapes.length) {
        this.parsePathShapes(aggregateShapes, container, timeOffset)
        aggregateShapes = []
      }

      return this.parseShape(shape, container, timeOffset)
    }, Promise.resolve())
  }

  parsePathShapes(shapes, container, timeOffset) {
    const visiblePathShapes = shapes.filter(shape => !shape.isHidden)
    const verticesList = visiblePathShapes.map(shape => shape.vertices)
    const firstShape = visiblePathShapes[0]
    const parentShape = firstShape.parent
    const anchor = parentShape.transform.anchor

    const pathData = this.parsePathVertices(verticesList, timeOffset, anchor)
    if (!pathData || pathData.mesh.edges.length < 1) {
      return
    }
    const { origin, size, mesh, pathMorphing } = pathData

    const translate = { kfs: [], value: [0, 0] }
    const element = this._createPathElement({
      geometryType: GeometryType.POLYGON,
      name: shapes.length > 1 ? `${firstShape.name || 'Path'} (${shapes.length})` : firstShape.name || 'Path',
      mesh,
      origin,
      size,
      translate,
      pathMorphing
    })

    this.dataStore.addChildrenAt(container, [element], 0)

    return element
  }

  async parseShape(shape, container, timeOffset) {
    await yieldToMain()

    // TODO: in & out
    if (shape.isHidden) {
      return
    }
    switch (shape.type) {
      case ShapeType.GROUP:
        await this.parseGroupShape(shape, container, timeOffset)
        break
      case ShapeType.RECTANGLE:
        this.parseRectangleShape(shape, container, timeOffset)
        break
      case ShapeType.ELLIPSE:
        this.parseEllipseShape(shape, container, timeOffset)
        break
      case ShapeType.PATH:
        throw Error('parseShape should not be called for path')
      case ShapeType.STROKE:
        this.parseStrokeShape(shape, container, timeOffset)
        break
      case ShapeType.FILL:
        this.parseFillShape(shape, container, timeOffset)
        break
      case ShapeType.GRADIENT_FILL:
        this.parseGradientFillShape(shape, container, timeOffset)
        break
      case ShapeType.GRADIENT_STROKE:
        this.parseGradientStokeShape(shape, container, timeOffset)
        break
      case ShapeType.TRIM:
        this.parseTrimShape(shape, container, timeOffset)
        break
      case ShapeType.MERGE:
        this.parseMergeShape(shape, container)
        break
      case ShapeType.ROUNDED_CORNERS:
        this.parseRoundedCornersShape(shape, container, timeOffset)
        break
      default:
        this.markNotSupport(shape, Feature.SHAPE, shape.type)
        break
    }
  }

  async parseGroupShape(shape, container, timeOffset) {
    const onlyPathExists = isOnlyPathInGroup(shape)
    const blendMode = parseBlendMode(shape.blendMode, true)
    const transform = shape.transform
    let groupContainer
    if (onlyPathExists) {
      groupContainer = {
        // Dummy object
        isBooleanType: () => false,
        isMaskGroup: () => false,
        children: [],
        get: key => {
          if (key === 'elementType') {
            return ElementType.CONTAINER
          }
        }
      }
    } else {
      groupContainer = await this.parseTransform(shape, container, timeOffset)
      groupContainer.setBaseProp('opacity', { blendMode })
    }

    let aggregateShapes = []
    return shape.shapes.reduce(async (promise, shape, index, arr) => {
      await promise
      const isLastShape = index === arr.length - 1
      const shouldProcessPathShapes = shape.type === ShapeType.PATH || aggregateShapes.length > 0
      if (shape.type === ShapeType.PATH) {
        aggregateShapes.push(shape)
      }

      if (shouldProcessPathShapes && (shape.type !== ShapeType.PATH || isLastShape)) {
        const pathElement = this.parsePathShapes(
          aggregateShapes,
          onlyPathExists ? container : groupContainer,
          timeOffset
        )
        if (onlyPathExists && pathElement) {
          this.setTransformToElement(transform, pathElement, timeOffset)
          pathElement.setBaseProp('opacity', { blendMode })
          groupContainer.children.push(pathElement)
        }
        aggregateShapes = []
      }

      if (shape.type !== ShapeType.PATH) {
        return this.parseShape(shape, groupContainer, timeOffset)
      }
      // will return undefined if it is ShapeType.PATH
    }, Promise.resolve())
  }

  parsePathVertices(verticesList, timeOffset) {
    const bezierList = verticesList
      .map(vertices => this.parseAnimatedProp(vertices, timeOffset))
      .filter(bezier => bezier.value.v.length > 0)

    if (!bezierList.length) {
      return
    }

    let mesh
    const arrVertexIds = []
    for (let i = 0; i < bezierList.length; i++) {
      const vertexIds = []
      mesh = parseBezier(bezierList[i].value, mesh, vertexIds)
      arrVertexIds.push(vertexIds)
    }

    for (const halves of mesh._meshHelper.findContours()) {
      const c = mesh.addContour(halves)
      c.isFilled = true
    }

    const arrKfs = bezierList.map(bezier => bezier.kfs)
    const pathMorphing = parsePathMorphing(mesh, arrKfs, arrVertexIds)
    const size = {
      value: [mesh.bounds.width, mesh.bounds.height],
      kfs: []
    }
    const origin = {
      value: [-mesh.bounds.min[0], -mesh.bounds.min[1]],
      kfs: []
    }
    return { origin, size, mesh: mesh.save(), pathMorphing: pathMorphing.kfs }
  }

  _createPathElement({ name, geometryType, mesh, origin, size, translate, pathMorphing }) {
    const elData = {
      elementType: ElementType.PATH,
      geometryType,
      name
    }
    if (mesh) {
      elData.geometry = {
        geometryType,
        mesh
      }
    }
    const element = this.dataStore.createElement(ElementType.PATH, elData)

    // FIXME: find better way to remove default layer
    const sc = this.dataStore.library.getComponent(element.base.strokes[0])
    if (sc.layers[0]) {
      this.dataStore.library.deleteLayer(sc.layers[0])
    }
    const fc = this.dataStore.library.getComponent(element.base.fills[0])
    if (fc.layers[0]) {
      this.dataStore.library.deleteLayer(fc.layers[0])
    }

    this.setBaseAndKeyFrame(element, 'translate', ['translateX', 'translateY'], translate)

    this.setBaseAndKeyFrame(element, 'dimensions', ['width', 'height'], size)

    if (geometryType === GeometryType.POLYGON) {
      element.setBaseProp('referencePoint', {
        referencePointX: origin.value[0],
        referencePointY: origin.value[1]
      })
    } else {
      element.setBaseProp('referencePoint', {
        referencePointX: size.value[0] * 0.5,
        referencePointY: size.value[1] * 0.5
      })
    }

    if (pathMorphing) {
      this.createKeyFrame(element, ['pathMorphing'], pathMorphing)
    }

    return element
  }

  parsePathElement(shape, container, geometryType, timeOffset) {
    const size = this.parseAnimatedProp(shape.size, timeOffset)
    const translate = this.parseAnimatedProp(shape.position, timeOffset)
    translate.value = [translate.value[0], translate.value[1]]

    const element = this._createPathElement({
      geometryType,
      name: shape.name || '',
      size,
      translate
    })

    this.dataStore.addChildrenAt(container, [element], 0)

    const blendMode = parseBlendMode(shape.blendMode)
    element.setBaseProp('opacity', { blendMode })

    return element
  }

  parseRectangleShape(shape, container, timeOffset) {
    const rectangle = this.parsePathElement(shape, container, GeometryType.RECTANGLE, timeOffset)

    const cornerRadius = this.parseAnimatedProp(shape.roundness, timeOffset)
    this.setBaseAndKeyFrame(rectangle, 'cornerRadius', ['cornerRadius'], cornerRadius)

    const size = this.parseAnimatedProp(shape.size, timeOffset)
    this.setBaseAndKeyFrame(rectangle, 'dimensions', ['width', 'height'], size)

    return rectangle
  }

  parseEllipseShape(shape, container, timeOffset) {
    return this.parsePathElement(shape, container, GeometryType.ELLIPSE, timeOffset)
  }

  parseTrimShape(shape, container, timeOffset) {
    const trimStart = this.parseAnimatedProp(shape.trimStart, timeOffset)
    const trimEnd = this.parseAnimatedProp(shape.trimEnd, timeOffset)
    const trimOffset = this.parseAnimatedProp(shape.trimOffset, timeOffset)
    const trimMode = parseTrimMode(shape.trimMultipleShapes)

    const index = shape.parent.shapes.findIndex(o => o === shape)
    const restShapes = shape.parent.shapes.slice(index)
    const anyShapeAfterTrimShape = restShapes.some(isShapeShape)

    let effectTarget = container
    if (anyShapeAfterTrimShape) {
      effectTarget = this.dataStore.createElement(ElementType.CONTAINER, {
        containerType: ContainerElementType.NORMAL_GROUP,
        name: 'Trim Path Effect'
      })
      effectTarget.setBaseProp('referencePoint', {
        referencePointX: 0,
        referencePointY: 0
      })

      this.dataStore.addChildrenAt(container, [effectTarget], 0)
      this.dataStore.addChildren(effectTarget, container.children.slice(1))
    }
    let effectComponentId = effectTarget.base.effects[0]
    let effectComponent = this.dataStore.library.getComponent(effectComponentId)
    if (effectComponent.hasEffectType(EffectType.TRIM_PATH)) {
      effectTarget = this.dataStore.createElement(ElementType.CONTAINER, {
        containerType: ContainerElementType.NORMAL_GROUP,
        name: 'Trim Path Effect'
      })
      effectTarget.setBaseProp('referencePoint', {
        referencePointX: 0,
        referencePointY: 0
      })
      this.dataStore.addChildrenAt(container, [effectTarget], 0)
      this.dataStore.addChildren(effectTarget, container.children.slice(1))

      effectComponentId = effectTarget.base.effects[0]
      effectComponent = this.dataStore.library.getComponent(effectComponentId)
    }
    const elementId = effectTarget.get('id')

    // base
    const effectId = this.dataStore.library.addEffect(effectComponentId, 0, {
      effectType: EffectType.TRIM_PATH,
      start: trimStart.value,
      end: trimEnd.value,
      offset: parseTrimOffset(trimOffset.value),
      mode: trimMode
    })

    trimStart.kfs.forEach(kf => {
      this._setTransitionTime(kf.time)
      const keyframeId = this.dataStore.interaction.setEffect(
        elementId,
        effectId,
        'start',
        kf.value[0],
        FrameType.EXPLICIT
      )
      this._setKeyFrameEasing(keyframeId, kf)
    })

    trimEnd.kfs.forEach(kf => {
      this._setTransitionTime(kf.time)
      const keyframeId = this.dataStore.interaction.setEffect(
        elementId,
        effectId,
        'end',
        kf.value[0],
        FrameType.EXPLICIT
      )
      this._setKeyFrameEasing(keyframeId, kf)
    })

    trimOffset.kfs.forEach(kf => {
      this._setTransitionTime(kf.time)
      const keyframeId = this.dataStore.interaction.setEffect(
        elementId,
        effectId,
        'offset',
        parseTrimOffset(kf.value[0]),
        FrameType.EXPLICIT
      )
      this._setKeyFrameEasing(keyframeId, kf)
    })
  }

  parseMergeShape(shape, container) {
    const booleanType = parseBooleanType(shape.mergeMode)

    let effectTarget = container
    const containerBoolean = container.get('booleanType')

    // create extra container if container is boolean type
    if (container.isBooleanType()) {
      effectTarget = this.dataStore.createElement(ElementType.CONTAINER, {
        containerType: ContainerElementType.NORMAL_GROUP,
        name: 'Boolean Effect'
      })
      effectTarget.setBaseProp('referencePoint', {
        referencePointX: 0,
        referencePointY: 0
      })
      effectTarget.set('booleanType', containerBoolean)
      this.dataStore.addChildrenAt(container, [effectTarget], 0)
      this.dataStore.addChildren(effectTarget, container.children.slice(1))
      container.set('booleanType', BooleanOperation.NONE)
    }

    // not parse as boolean group if children is less than 2
    if (container.children.length < 2) {
      return
    }

    // moving latest child to top if subtract
    if (booleanType === BooleanOperation.SUBTRACT) {
      this.dataStore.addChildrenAt(effectTarget, [effectTarget.children[effectTarget.children.length - 1]], 0)
    }

    // clear children's layers
    if (booleanType === BooleanOperation.NONE) {
      effectTarget.children.forEach(child => {
        let target = child
        if (child.children?.length === 1) {
          target = child.children[0]
        }
        const sc = this.dataStore.library.getComponent(target.base.strokes[0])
        sc.layers.forEach(layerId => {
          this.dataStore.library.deleteLayer(layerId)
        })
        const fc = this.dataStore.library.getComponent(target.base.fills[0])
        fc.layers.forEach(layerId => {
          this.dataStore.library.deleteLayer(layerId)
        })
      })
    }
    effectTarget.set('booleanType', booleanType)
  }

  parseRoundedCornersShape(shape, container, timeOffset) {
    const list = container.isBooleanType() ? [container] : container.children

    list.forEach(element => {
      const cornerRadius = this.parseAnimatedProp(shape.roundness, timeOffset)
      let target = element
      if (element.children?.length === 1) {
        target = element.children[0]
      }
      this.setBaseAndKeyFrame(target, 'cornerRadius', ['cornerRadius'], cornerRadius)
    })
  }

  // TODO: reuse most of code for parse layers
  parseFillShape(shape, container, timeOffset) {
    // TODO: simplified the color and opacity into two prop tracks
    const shapeColor = this.parseAnimatedProp(shape.color, timeOffset)
    const shapeOpacity = this.parseAnimatedProp(shape.opacity, timeOffset)
    const color = parseColor(shapeColor.value, shapeOpacity.value)

    const list = container.isBooleanType() ? [container] : container.children.slice()
    while (list.length) {
      const element = list.shift()
      if (getElementType(element) === ElementType.CONTAINER) {
        list.push(...element.children)
        continue
      }
      const fillCompId = element.base.fills[0]
      const fillId = this.dataStore.library.addLayer(fillCompId, 0)
      const fill = this.dataStore.library.getLayer(fillId)

      // base
      fill.paint.color = color
      fill.paint.opacity = color[3]

      // opacity kfs
      shapeOpacity.kfs.forEach(kf => {
        this._setTransitionTime(kf.time)
        const keyframeId = this.dataStore.interaction.setLayer(
          element.get('id'),
          fillId,
          'opacity',
          kf.value[0] / 100,
          { layerType: PhaseLayerType.FILL, paintType: PaintType.SOLID },
          FrameType.EXPLICIT
        )
        this._setKeyFrameEasing(keyframeId, kf)
      })

      // color kfs
      shapeColor.kfs.forEach(kf => {
        this._setTransitionTime(kf.time)
        const keyframeId = this.dataStore.interaction.setLayer(
          element.get('id'),
          fillId,
          'color',
          parseColor(kf.value),
          { layerType: PhaseLayerType.FILL, paintType: PaintType.SOLID },
          FrameType.EXPLICIT
        )
        this._setKeyFrameEasing(keyframeId, kf)
      })
    }
  }

  parseGradientFillShape(shape, container, timeOffset) {
    // TODO: calculate gradient transform by shape.startPoint and shape.endPoint (animatedProp)

    // TODO: simplified the color and opacity into two prop tracks
    const gradientColors = this.parseAnimatedProp(shape.gradientColors.gradientColors, timeOffset)
    const shapeOpacity = this.parseAnimatedProp(shape.opacity, timeOffset)
    const gradientStops = parseGradientColors(gradientColors.value, gradientColors.count)
    const startPoints = this.parseAnimatedProp(shape.startPoint, timeOffset)
    const endPoints = this.parseAnimatedProp(shape.endPoint, timeOffset)
    const paintType = getPaintType(shape.gradientType)
    this.processGradientOnElement(
      container,
      container,
      shape,
      paintType,
      gradientStops,
      shapeOpacity,
      gradientColors,
      startPoints,
      endPoints,
      0
    )
  }

  processGradientOnElement(
    container,
    element,
    shape,
    paintType,
    gradientStops,
    shapeOpacity,
    gradientColors,
    startPoints,
    endPoints,
    depth
  ) {
    // Treat booleans as elements that have gradient layers.
    if (getElementType(element) === ElementType.CONTAINER && !element.isBooleanType()) {
      element.children.forEach(child =>
        this.processGradientOnElement(
          element,
          child,
          shape,
          paintType,
          gradientStops,
          shapeOpacity,
          gradientColors,
          startPoints,
          endPoints,
          depth + 1
        )
      )
      return
    }
    const elementId = element.get('id')
    const fillCompId = element.base.fills[0]
    const fillId = this.dataStore.library.addLayer(fillCompId, 0)
    const fill = this.dataStore.library.getLayer(fillId)

    const pathMorphingAnimationData = this.dataStore.transition.getPropertyValueByTime(
      elementId,
      'pathMorphing',
      shape.startPoint.values[0].time
    )
    const hasPathMorphing =
      pathMorphingAnimationData !== 'Invalid property' && pathMorphingAnimationData.pathMorphing !== undefined
    if (hasPathMorphing) {
      element.updateVerticesPosition(pathMorphingAnimationData.pathMorphing)
    }

    const size = element.get('size')
    if (getElementType(element) === ElementType.BOOLEAN_CONTAINER) {
      const groupBBox = getFullGroupSize(element)
      if (!groupBBox.isInfinite) {
        size[0] = groupBBox.width
        size[1] = groupBBox.height
      }
    }
    let { startBase, endBase } = calculateGradientPoint(
      element,
      shape.startPoint.values[0].value,
      shape.endPoint.values[0].value,
      size[0],
      size[1]
    )

    if (hasPathMorphing) {
      // Reset path with the base path data
      const basePathData = []
      for (const [, v] of element.basePath) {
        basePathData.push({
          id: v.id,
          mirror: v.mirror,
          x: v.pos[0],
          y: v.pos[1]
        })
      }
      element.updateVerticesPosition(basePathData)
    }

    if (depth > 1) {
      const result = getLocalStartEndAtTime(this.dataStore, container, 0, startBase, endBase)
      startBase = result.startBase
      endBase = result.endBase
    }

    const gradientTransform = parseGradientTransform(startBase, endBase, size, paintType === PaintType.GRADIENT_LINEAR)

    fill.paint.paintType = paintType
    fill.paint.gradientStops = gradientStops
    fill.paint.gradientTransform = gradientTransform
    fill.paint.opacity = shapeOpacity.value / 100

    // opacity kfs
    shapeOpacity.kfs.forEach(kf => {
      this._setTransitionTime(kf.time)
      const keyframeId = this.dataStore.interaction.setLayer(
        elementId,
        fillId,
        'opacity',
        kf.value[0] / 100,
        { layerType: PhaseLayerType.FILL, paintType },
        FrameType.EXPLICIT
      )
      this._setKeyFrameEasing(keyframeId, kf)
    })

    // color kfs
    gradientColors.kfs.forEach(kf => {
      this._setTransitionTime(kf.time)
      const keyframeId = this.dataStore.interaction.setLayer(
        elementId,
        fillId,
        'gradientStops',
        parseGradientColors(kf.value, gradientColors.count),
        { layerType: PhaseLayerType.FILL, paintType },
        FrameType.EXPLICIT
      )
      this._setKeyFrameEasing(keyframeId, kf)

      const keyframeId2 = this.dataStore.interaction.setLayer(
        elementId,
        fillId,
        'gradientTransform',
        gradientTransform,
        { layerType: PhaseLayerType.FILL, paintType },
        FrameType.EXPLICIT
      )
      this._setKeyFrameEasing(keyframeId2, kf)
    })

    startPoints.kfs.forEach(kf => {
      this._setTransitionTime(kf.time)
      const animateWidthData = this.dataStore.transition.getPropertyValueByTime(elementId, 'width', kf.time)
      const animateHeightData = this.dataStore.transition.getPropertyValueByTime(elementId, 'height', kf.time)
      const [start, end] = findSegment(endPoints, kf.time)
      const p = getPercentage(start.time, end.time, kf.time)
      const value = getBezierValue(start.value, end.value, end.bezier, p)
      let { startBase, endBase } = calculateGradientPoint(
        element,
        Array.isArray(kf.value) ? kf.value : kf.value.target,
        Array.isArray(value) ? value : value.target,
        animateWidthData.width,
        animateHeightData.height
      )
      if (depth > 1) {
        const result = getLocalStartEndAtTime(this.dataStore, container, kf.time, startBase, endBase)
        startBase = result.startBase
        endBase = result.endBase
      }
      //
      const [colorStart, colorEnd] = findSegment(gradientColors, kf.time)
      const colorP = getPercentage(colorStart.time, colorEnd.time, kf.time)
      const colorValue = getBezierValue(colorStart.value, colorEnd.value, colorEnd.bezier, colorP)
      const colorKeyframeId = this.dataStore.interaction.setLayer(
        elementId,
        fillId,
        'gradientStops',
        parseGradientColors(colorValue, gradientColors.count),
        { layerType: PhaseLayerType.FILL, paintType },
        FrameType.EXPLICIT
      )
      this._setKeyFrameEasing(colorKeyframeId, kf)
      // TODO: @gradientTransform
      const animateGradientTransform = parseGradientTransform(
        startBase,
        endBase,
        [animateWidthData.width, animateHeightData.height],
        paintType === PaintType.GRADIENT_LINEAR
      )
      const keyframeId = this.dataStore.interaction.setLayer(
        elementId,
        fillId,
        'gradientTransform',
        animateGradientTransform,
        { layerType: PhaseLayerType.FILL, paintType },
        FrameType.EXPLICIT
      )
      this._setKeyFrameEasing(keyframeId, kf)
    })
    endPoints.kfs.forEach(kf => {
      this._setTransitionTime(kf.time)
      const animateWidthData = this.dataStore.transition.getPropertyValueByTime(elementId, 'width', kf.time)
      const animateHeightData = this.dataStore.transition.getPropertyValueByTime(elementId, 'height', kf.time)
      const [start, end] = findSegment(startPoints, kf.time)
      const p = getPercentage(start.time, end.time, kf.time)
      const value = getBezierValue(start.value, end.value, end.bezier, p)
      let { startBase, endBase } = calculateGradientPoint(
        element,
        Array.isArray(value) ? value : value.target,
        Array.isArray(kf.value) ? kf.value : kf.value.target,
        animateWidthData.width,
        animateHeightData.height
      )
      if (depth > 1) {
        const result = getLocalStartEndAtTime(this.dataStore, container, kf.time, startBase, endBase)
        startBase = result.startBase
        endBase = result.endBase
      }
      //
      const [colorStart, colorEnd] = findSegment(gradientColors, kf.time)
      const colorP = getPercentage(colorStart.time, colorEnd.time, kf.time)
      const colorValue = getBezierValue(colorStart.value, colorEnd.value, colorEnd.bezier, colorP)
      const colorKeyframeId = this.dataStore.interaction.setLayer(
        elementId,
        fillId,
        'gradientStops',
        parseGradientColors(colorValue, gradientColors.count),
        { layerType: PhaseLayerType.FILL, paintType },
        FrameType.EXPLICIT
      )
      this._setKeyFrameEasing(colorKeyframeId, kf)
      // TODO: @gradientTransform
      const animateGradientTransform = parseGradientTransform(
        startBase,
        endBase,
        [animateWidthData.width, animateHeightData.height],
        paintType === PaintType.GRADIENT_LINEAR
      )
      const keyframeId = this.dataStore.interaction.setLayer(
        elementId,
        fillId,
        'gradientTransform',
        animateGradientTransform,
        { layerType: PhaseLayerType.FILL, paintType },
        FrameType.EXPLICIT
      )
      this._setKeyFrameEasing(keyframeId, kf)
    })
  }

  parseStrokeDashes(strokeDashes, timeOffset) {
    if (!strokeDashes) {
      return {}
    }

    const dashAndGap = strokeDashes.dashes.reduce(
      (acc, dashProp) => {
        switch (dashProp.dashType) {
          case StrokeDashType.DASH:
            acc.dash.push(this.parseAnimatedProp(dashProp.value, timeOffset))
            break
          case StrokeDashType.GAP:
            acc.gap.push(this.parseAnimatedProp(dashProp.value, timeOffset))
            break
          case StrokeDashType.OFFSET:
          default:
            break
        }
        return acc
      },
      { dash: [], gap: [] }
    )

    const dash = parseDashList(dashAndGap.dash, dashAndGap.gap, timeOffset)
    const gap = parseDashList(dashAndGap.gap, dashAndGap.dash, timeOffset)
    return { dash, gap }
  }

  // TODO: reuse most of code for parse layers
  parseStrokeShape(shape, container, timeOffset) {
    // TODO: simplified the color and opacity into two prop tracks
    const shapeColor = this.parseAnimatedProp(shape.color, timeOffset)
    const shapeOpacity = this.parseAnimatedProp(shape.opacity, timeOffset)
    const color = parseColor(shapeColor.value, shapeOpacity.value)

    const { dash, gap } = this.parseStrokeDashes(shape.strokeDashes, timeOffset)
    const cap = parseCap(shape.lineCapType)
    const join = parseJoin(shape.lineJoinType)
    const ends = parseEnds(shape.lineCapType)
    const width = this.parseAnimatedProp(shape.width, timeOffset)
    const list = container.isBooleanType() ? [container] : container.children.slice()

    while (list.length) {
      const element = list.shift()
      if (getElementType(element) === ElementType.CONTAINER) {
        list.push(...element.children)
        continue
      }
      const strokeCompId = element.base.strokes[0]
      const strokeId = this.dataStore.library.addLayer(strokeCompId, 0, {
        width: width.value
      })
      const stroke = this.dataStore.library.getLayer(strokeId)

      // base
      stroke.paint.color = color
      stroke.paint.opacity = color[3]
      stroke.cap = cap
      stroke.join = join
      stroke.ends = ends
      stroke.miter = shape.miterLimit

      if (dash) {
        stroke.dash = dash.value
        dash.kfs.forEach(kf => {
          this._setTransitionTime(kf.time)
          const keyframeId = this.dataStore.interaction.setLayer(
            element.get('id'),
            strokeId,
            'dash',
            kf.value,
            { layerType: PhaseLayerType.STROKE },
            FrameType.EXPLICIT
          )
          this._setKeyFrameEasing(keyframeId, kf)
        })
      }

      if (gap) {
        stroke.gap = gap.value
        gap.kfs.forEach(kf => {
          this._setTransitionTime(kf.time)
          const keyframeId = this.dataStore.interaction.setLayer(
            element.get('id'),
            strokeId,
            'gap',
            kf.value,
            { layerType: PhaseLayerType.STROKE },
            FrameType.EXPLICIT
          )
          this._setKeyFrameEasing(keyframeId, kf)
        })
      }

      // width kfs
      width.kfs.forEach(kf => {
        this._setTransitionTime(kf.time)
        const keyframeId = this.dataStore.interaction.setLayer(
          element.get('id'),
          strokeId,
          'width',
          kf.value[0],
          { layerType: PhaseLayerType.STROKE },
          FrameType.EXPLICIT
        )
        this._setKeyFrameEasing(keyframeId, kf)
      })

      // opacity kfs
      shapeOpacity.kfs.forEach(kf => {
        this._setTransitionTime(kf.time)
        const keyframeId = this.dataStore.interaction.setLayer(
          element.get('id'),
          strokeId,
          'opacity',
          kf.value[0] / 100,
          { layerType: PhaseLayerType.STROKE, paintType: PaintType.SOLID },
          FrameType.EXPLICIT
        )
        this._setKeyFrameEasing(keyframeId, kf)
      })

      // color kfs
      shapeColor.kfs.forEach(kf => {
        this._setTransitionTime(kf.time)
        const keyframeId = this.dataStore.interaction.setLayer(
          element.get('id'),
          strokeId,
          'color',
          parseColor(kf.value),
          { layerType: PhaseLayerType.STROKE, paintType: PaintType.SOLID },
          FrameType.EXPLICIT
        )
        this._setKeyFrameEasing(keyframeId, kf)
      })
    }
  }

  // TODO: reuse most of code for parse layers
  parseGradientStokeShape(shape, container, timeOffset) {
    // TODO: calculate gradient transform by shape.startPoint and shape.endPoint (animatedProp)

    // TODO: simplified the color and opacity into two prop tracks
    const gradientColors = this.parseAnimatedProp(shape.gradientColors.gradientColors, timeOffset)
    const shapeOpacity = this.parseAnimatedProp(shape.opacity, timeOffset)
    const gradientStops = parseGradientColors(gradientColors.value, gradientColors.count)
    const paintType = getPaintType(shape.gradientType)
    const cap = parseCap(shape.lineCapType)
    const join = parseJoin(shape.lineJoinType)
    const ends = parseEnds(shape.lineCapType)
    const width = this.parseAnimatedProp(shape.width, timeOffset)
    const startPoints = this.parseAnimatedProp(shape.startPoint, timeOffset)
    const endPoints = this.parseAnimatedProp(shape.endPoint, timeOffset)
    const list = container.isBooleanType() ? [container] : container.children.slice()

    while (list.length) {
      const element = list.shift()
      const elementId = element.get('id')

      if (getElementType(element) === ElementType.CONTAINER) {
        list.push(...element.children)
        continue
      }
      const strokeCompId = element.base.strokes[0]
      const strokeId = this.dataStore.library.addLayer(strokeCompId, 0, {
        width: width.value
      })
      const stroke = this.dataStore.library.getLayer(strokeId)
      stroke.cap = cap
      stroke.join = join
      stroke.ends = ends
      stroke.miter = shape.miterLimit

      const size = element.get('size')
      const { startBase, endBase } = calculateGradientPoint(
        element,
        shape.startPoint.values[0].value,
        shape.endPoint.values[0].value,
        size[0],
        size[1]
      )
      // TODO: @gradientTransform
      const gradientTransform = parseGradientTransform(
        startBase,
        endBase,
        size,
        paintType === PaintType.GRADIENT_LINEAR
      )

      // base
      stroke.paint.paintType = paintType
      stroke.paint.gradientStops = gradientStops
      stroke.paint.gradientTransform = gradientTransform

      // width kfs
      width.kfs.forEach(kf => {
        this._setTransitionTime(kf.time)
        const keyframeId = this.dataStore.interaction.setLayer(
          element.get('id'),
          strokeId,
          'width',
          kf.value[0],
          { layerType: PhaseLayerType.STROKE },
          FrameType.EXPLICIT
        )
        this._setKeyFrameEasing(keyframeId, kf)
      })

      // opacity kfs
      shapeOpacity.kfs.forEach(kf => {
        this._setTransitionTime(kf.time)
        const keyframeId = this.dataStore.interaction.setLayer(
          element.get('id'),
          strokeId,
          'opacity',
          kf.value[0] / 100,
          { layerType: PhaseLayerType.STROKE, paintType },
          FrameType.EXPLICIT
        )
        this._setKeyFrameEasing(keyframeId, kf)
      })

      // color kfs
      gradientColors.kfs.forEach(kf => {
        this._setTransitionTime(kf.time)
        const keyframeId = this.dataStore.interaction.setLayer(
          element.get('id'),
          strokeId,
          'gradientStops',
          parseGradientColors(kf.value, gradientColors.count),
          { layerType: PhaseLayerType.STROKE, paintType },
          FrameType.EXPLICIT
        )
        this._setKeyFrameEasing(keyframeId, kf)

        const keyframeId2 = this.dataStore.interaction.setLayer(
          element.get('id'),
          strokeId,
          'gradientTransform',
          gradientTransform,
          { layerType: PhaseLayerType.STROKE, paintType },
          FrameType.EXPLICIT
        )
        this._setKeyFrameEasing(keyframeId2, kf)
      })

      startPoints.kfs.forEach(kf => {
        this._setTransitionTime(kf.time)
        const animateWidthData = this.dataStore.transition.getPropertyValueByTime(elementId, 'width', kf.time)
        const animateHeightData = this.dataStore.transition.getPropertyValueByTime(elementId, 'height', kf.time)
        const [start, end] = findSegment(endPoints, kf.time)
        const p = getPercentage(start.time, end.time, kf.time)
        const value = getBezierValue(start.value, end.value, end.bezier, p)
        const { startBase, endBase } = calculateGradientPoint(
          element,
          Array.isArray(kf.value) ? kf.value : kf.value.target,
          Array.isArray(value) ? value : value.target,
          animateWidthData.width,
          animateHeightData.height
        )
        //
        const [colorStart, colorEnd] = findSegment(gradientColors, kf.time)
        const colorP = getPercentage(colorStart.time, colorEnd.time, kf.time)
        const colorValue = getBezierValue(colorStart.value, colorEnd.value, colorEnd.bezier, colorP)
        const colorKeyframeId = this.dataStore.interaction.setLayer(
          elementId,
          strokeId,
          'gradientStops',
          parseGradientColors(colorValue, gradientColors.count),
          { layerType: PhaseLayerType.STROKE, paintType },
          FrameType.EXPLICIT
        )
        this._setKeyFrameEasing(colorKeyframeId, kf)
        // TODO: @gradientTransform
        const animateGradientTransform = parseGradientTransform(
          startBase,
          endBase,
          [animateWidthData.width, animateHeightData.height],
          paintType === PaintType.GRADIENT_LINEAR
        )
        const keyframeId = this.dataStore.interaction.setLayer(
          elementId,
          strokeId,
          'gradientTransform',
          animateGradientTransform,
          { layerType: PhaseLayerType.STROKE, paintType },
          FrameType.EXPLICIT
        )
        this._setKeyFrameEasing(keyframeId, kf)
      })
      endPoints.kfs.forEach(kf => {
        this._setTransitionTime(kf.time)
        const animateWidthData = this.dataStore.transition.getPropertyValueByTime(elementId, 'width', kf.time)
        const animateHeightData = this.dataStore.transition.getPropertyValueByTime(elementId, 'height', kf.time)
        const [start, end] = findSegment(startPoints, kf.time)
        const p = getPercentage(start.time, end.time, kf.time)
        const value = getBezierValue(start.value, end.value, end.bezier, p)
        const { startBase, endBase } = calculateGradientPoint(
          element,
          Array.isArray(value) ? value : value.target,
          Array.isArray(kf.value) ? kf.value : kf.value.target,
          animateWidthData.width,
          animateHeightData.height
        )
        //
        const [colorStart, colorEnd] = findSegment(gradientColors, kf.time)
        const colorP = getPercentage(colorStart.time, colorEnd.time, kf.time)
        const colorValue = getBezierValue(colorStart.value, colorEnd.value, colorEnd.bezier, colorP)
        const colorKeyframeId = this.dataStore.interaction.setLayer(
          elementId,
          strokeId,
          'gradientStops',
          parseGradientColors(colorValue, gradientColors.count),
          { layerType: PhaseLayerType.STROKE, paintType },
          FrameType.EXPLICIT
        )
        this._setKeyFrameEasing(colorKeyframeId, kf)
        // TODO: @gradientTransform
        const animateGradientTransform = parseGradientTransform(
          startBase,
          endBase,
          [animateWidthData.width, animateHeightData.height],
          paintType === PaintType.GRADIENT_LINEAR
        )
        const keyframeId = this.dataStore.interaction.setLayer(
          elementId,
          strokeId,
          'gradientTransform',
          animateGradientTransform,
          { layerType: PhaseLayerType.STROKE, paintType },
          FrameType.EXPLICIT
        )
        this._setKeyFrameEasing(keyframeId, kf)
      })
    }
  }

  parseAnimatedProp(prop, timeOffset = 0) {
    if (prop.split) {
      return {
        split: true,
        x: this.parseAnimatedProp({ values: prop.x, isAnimated: prop.x.length > 1 }, timeOffset),
        y: this.parseAnimatedProp({ values: prop.y, isAnimated: prop.y.length > 1 }, timeOffset)
      }
    }

    let inTangent = undefined
    let outTangent = undefined
    let valueInTangent = undefined
    let hold = false
    const kfs = prop.values
      .map(keyFrame => {
        const time = timeOffset + frameToMs(keyFrame.frame, this.animation.frameRate)
        const kf = {
          time: time,
          bezier: outTangent && inTangent ? [...outTangent, ...inTangent] : [0.25, 0.25, 0.75, 0.75],
          in: valueInTangent,
          out: keyFrame.valueOutTangent,
          step: hold,
          value: keyFrame.value
        }
        inTangent = keyFrame.frameInTangent?.map(o => (Array.isArray(o) ? o[0] : o))
        outTangent = keyFrame.frameOutTangent?.map(o => (Array.isArray(o) ? o[0] : o))
        valueInTangent = keyFrame.valueInTangent
        hold = !!keyFrame.hold

        return kf
      })
      .filter(o => o.value !== undefined)
      .filter((o, i, arr) => {
        const next = arr[i + 1]
        if (o.time < 0 && next?.time <= 0) {
          return false
        }
        return true
      })

    const base = { ...kfs[0] }

    if (prop.isAnimated) {
      const firstKf = kfs[0]
      if (firstKf.time === 0) {
        kfs.shift()
      } else if (firstKf.time < 0 && kfs.length > 1) {
        kfs.shift()
        const p = getPercentage(firstKf.time, kfs[0].time, Math.max(firstKf.time, 0))
        base.value = getBezierValue(firstKf.value, kfs[0].value, kfs[0].bezier, p)
      }

      if (firstKf.out && (firstKf.out[0] !== 0 || firstKf.out[1] !== 0)) {
        const firstKfClone = { ...firstKf }
        firstKfClone.in = [0, 0]
        kfs.unshift(firstKfClone)
      }

      if (base.value.length === 1) {
        base.value = base.value[0]
      }
    } else {
      // skip first kf
      kfs.shift()
    }

    const ret = {
      value: base.value,
      kfs
    }
    if (prop.type === PropertyType.GRADIENT) {
      ret.count = prop.colorCount
    }
    return ret
  }

  setTransformToElement(transform, element, timeOffset) {
    // TODO: skew animation
    if (transform.skew) {
      const skew = this.parseAnimatedProp(transform.skew, timeOffset)
      const skewAxis = this.parseAnimatedProp(transform.skewAxis, timeOffset)
      if (skewAxis.value % 180 === 0) {
        // skewX
        this.setBaseAndKeyFrame(element, 'skew', ['skewX'], skew, v => -deg2rad(v))
      } else if (skewAxis.value % 180 === 90) {
        // skewY
        this.setBaseAndKeyFrame(element, 'skew', ['skewY'], skew, v => deg2rad(v))
      } else {
        // TODO: matrix transform
      }
    }

    const anchor = this.parseAnimatedProp(transform.anchor, timeOffset)
    this.setBaseAndKeyFrame(element, 'contentAnchor', ['contentAnchorX', 'contentAnchorY'], anchor, toPixelUnit)

    const position = this.parseAnimatedProp(transform.position, timeOffset)
    if (position.split) {
      this.setBaseValue(element, 'translate', {
        translateX: position.x.value[0],
        translateY: position.y.value[0]
      })
      const motionPath = mergeProperties([position.x, position.y])
      this.setBaseAndKeyFrame(element, 'translate', ['translateX', 'translateY'], motionPath)
    } else {
      this.setBaseAndKeyFrame(element, 'translate', ['translateX', 'translateY'], position)
    }

    if (transform.rotation) {
      const rotation = this.parseAnimatedProp(transform.rotation, timeOffset)
      this.setBaseAndKeyFrame(element, 'rotation', ['rotation'], rotation, deg2rad)
    }
    const opacity = this.parseAnimatedProp(transform.opacity, timeOffset)
    this.setBaseAndKeyFrame(element, 'opacity', ['opacity'], opacity, toPercentage)
    const scale = this.parseAnimatedProp(transform.scale, timeOffset)
    this.setBaseAndKeyFrame(element, 'scale', ['scaleX', 'scaleY'], scale, toPercentage)
    return element
  }

  save() {
    return this.dataStore.save()
  }
}

const analyzer = () => {}

const analyzeHandler = {
  get(target, prop, receiver) {
    if (typeof target[prop] === 'function') {
      return new Proxy(target[prop], {
        apply: (target, thisArg, argumentsList) => {
          analyzer(prop, argumentsList)
          return Reflect.apply(target, thisArg, argumentsList)
        }
      })
    } else {
      return Reflect.get(target, prop, receiver)
    }
  }
}

const proxyHandler = {
  construct(target, args) {
    const lottieImporter = new target(...args)
    return new Proxy(lottieImporter, analyzeHandler)
  }
}

export const LottieImporter = new Proxy(BaseLottieImporter, proxyHandler)
