import React, { useEffect, useRef } from 'react'

// @ts-ignore
import { Events } from '@phase-software/data-store'
import { Mode } from '@phase-software/types'

import { ALLOWED_UPLOAD_SVG_TYPE_SET } from '../../constant'
import useFileActions from '../../hooks/useFileActions'
import { useFileContext } from '../../providers/FileProvider'
import { useDataStore } from '../../providers/dataStore/DataStoreProvider'

const accept = Array.from(ALLOWED_UPLOAD_SVG_TYPE_SET).join(',')

// TO: Integrate SvgUploader and ImageUploader into a single file after implementing multi-file support for SVGs.
const SvgUploader = () => {
  const ref = useRef<HTMLInputElement>(null)
  const { insertSVGToFile } = useFileActions()
  const dataStore = useDataStore()
  const { id: fileId, projectId } = useFileContext()

  useEffect(() => {
    const switchToDesignMode = () => {
      dataStore.switchMode(Mode.DESIGN)
    }

    const openFileDialog = () => {
      switchToDesignMode()

      ref.current?.click()
    }

    dataStore.eam.on(Events.INSERT_SVG, openFileDialog)
    return () => {
      dataStore.eam.off(Events.INSERT_SVG, openFileDialog)
    }
  }, [dataStore, fileId, projectId])

  const handleSvgChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist()
    if (e.target.files) {
      await insertSVGToFile(dataStore, e.target.files[0], projectId, fileId)
      e.target.value = ''
      e.target.blur()
    }
  }

  return <input ref={ref} type="file" accept={accept} onChange={handleSvgChange} />
}

export default SvgUploader
