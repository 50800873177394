import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { usePresencePreferenceQuery, useOnPreferenceUpdatedSubscription } from '../generated/graphql'
import { useProfile } from '../providers/ProfileProvider'
import { usePresenceContext } from '../providers/PresenceProvider'
import { useDataStore } from '../providers/dataStore/DataStoreProvider'
import { presenceService } from '../services/api'
import { PresenceCallbackMessageData } from '../Broadcaster/PresenceManager'
import { useUI } from '../providers/dataStore/UIProvider'
import { RendererCanvas } from '../components/shared/RendererCanvas'
import { FEATURE_KEYS } from '../growthbook-feature-keys'
import { useBroadcaster } from '../Broadcaster'

export const useEnablePresencePreference = (fileId: string) => {
  const profile = useProfile()
  const [isPresenceShowByFile, setIsPresenceShowByFile] = useState<Map<string, boolean>>(new Map())

  const { data: presenceData, loading: isPresenceDataLoading } = usePresencePreferenceQuery({
    variables: { id: profile.id }
  })

  useEffect(() => {
    if (!isPresenceDataLoading) {
      // if haven't set presence preference, presenceValue is null
      const presenceValue = presenceData?.user_preferences_by_pk?.value
      if (presenceValue !== null && typeof presenceValue === 'object' && !Array.isArray(presenceValue)) {
        setIsPresenceShowByFile(new Map(Object.entries(presenceValue)))
        window.PresenceManager.isPresenceShow = fileId in presenceValue ? presenceValue[fileId] : true
      } else {
        const presencePrefMap = new Map()
        presencePrefMap.set(fileId, true)
        setIsPresenceShowByFile(presencePrefMap)
        window.PresenceManager.isPresenceShow = true
      }
    }
  }, [fileId, isPresenceDataLoading, presenceData])

  const { data: prefData } = useOnPreferenceUpdatedSubscription({
    variables: { id: profile.id, key: 'presence-preference' },
    skip: !profile.id
  })

  useEffect(() => {
    if (prefData?.user_preferences && prefData.user_preferences.length > 0) {
      const prefObj = prefData.user_preferences[0].value
      setIsPresenceShowByFile(new Map(Object.entries(prefObj)))
      if (window.PresenceManager && typeof prefObj[fileId] === 'boolean') {
        window.PresenceManager.isPresenceShow = prefObj[fileId]
      }
    }
  }, [fileId, prefData, setIsPresenceShowByFile])

  return { isPresenceShowByFile, setIsPresenceShowByFile }
}

export const useTogglePresencePreference = (fileId: string) => {
  const dataStore = useDataStore()
  const { syncPresencePreference, isPresenceShowByFile } = usePresenceContext()

  const handlePresencePreference = useCallback(() => {
    const toggle = isPresenceShowByFile.has(fileId) ? !isPresenceShowByFile.get(fileId) : false
    syncPresencePreference(fileId, toggle)
  }, [fileId, syncPresencePreference, isPresenceShowByFile])

  useEffect(() => {
    dataStore.eam.on('TOGGLE_PRESENCE', handlePresencePreference)
    return () => {
      dataStore.eam.off('TOGGLE_PRESENCE', handlePresencePreference)
    }
  }, [dataStore.eam, handlePresencePreference])
}

export const useFetchPresenceUser = (fileId: string) => {
  const [data, setData] = useState<PresenceCallbackMessageData[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<unknown>(null)
  const mountedRef = useRef(true)

  const controller = useMemo(() => new AbortController(), [])
  const signal = controller.signal

  const fetch = useCallback(
    async (fileId: string) => {
      setLoading(true)
      try {
        if (!mountedRef.current) return null

        const res = (await presenceService.apiPresenceCollaboratorsFileIdGet(
          { fileId },
          { signal }
        )) as PresenceCallbackMessageData[]
        setData(res)
        setError(null)
        setLoading(false)
      } catch (error) {
        if (!mountedRef.current) return null

        setError(error)
        setLoading(false)
        console.error('[usePresence] fetch error :>> ', error)
      }
    },
    [signal]
  )

  useEffect(() => {
    if (fileId) {
      fetch(fileId)
    }
    return () => {
      if (fileId && controller) {
        controller.abort()
      }
    }
  }, [fetch, fileId, controller])

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [mountedRef])

  return { data, loading, error }
}

export const useParticipate = (fileId: string) => {
  const isPresenceEnabled = useFeatureIsOn(FEATURE_KEYS.PRESENCE)
  const { mode, hasRendererInit, isVersioningState } = useUI()

  useEffect(() => {
    return () => {
      if (fileId && isPresenceEnabled) {
        window.PresenceManager.unsubscribePresenceEditor(fileId)
      }
    }
  }, [fileId, isPresenceEnabled])

  useEffect(() => {
    if (hasRendererInit && isPresenceEnabled) {
      window.PresenceManager.bindCanvas(RendererCanvas)
    }
    return () => {
      if (window.PresenceManager.canvas) {
        window.PresenceManager.unbindCanvas()
      }
    }
  }, [hasRendererInit, isPresenceEnabled])

  useEffect(() => {
    if (isPresenceEnabled) {
      window.PresenceManager.handleModeChange(mode)
    }
  }, [mode, isPresenceEnabled])

  useEffect(() => {
    if (isPresenceEnabled) {
      window.PresenceManager.handleVersioningStateChange(isVersioningState)
    }
  }, [isPresenceEnabled, isVersioningState])
}

type ParticipantType = PresenceCallbackMessageData & {
  joinTimeList: string[]
}

export const usePresenceFileUser = (fileId: string) => {
  const broadcaster = useBroadcaster()
  const isPresenceEnabled = useFeatureIsOn(FEATURE_KEYS.PRESENCE)
  const [users, setUsers] = useState<Map<string, ParticipantType>>(new Map())
  const { data: presenceUsers, loading: isFetchPresenceUserLoading } = useFetchPresenceUser(fileId)
  const presenceUserMap = useRef(new Map())

  const sortMostRecentByJoinTime = useCallback((map: Map<string, ParticipantType>) => {
    return new Map(
      Array.from(map.entries()).sort((a, b) => new Date(b[1].joinTime).getTime() - new Date(a[1].joinTime).getTime())
    )
  }, [])

  const handlePresenceMessage = useCallback(
    (message: PresenceCallbackMessageData) => {
      const { userId, type, joinTime, idleTime, isUpdate, tabId } = message

      if (!isUpdate || !userId) return

      const updatePresenceAvatar = (
        presenceMap: Map<string, PresenceCallbackMessageData[]>,
        presenceMessage: PresenceCallbackMessageData
      ) => {
        setUsers((prev) => {
          let userMap = new Map(prev)

          const presenceList = presenceMap.get(userId)
          const currPresence = prev.get(userId)

          if (type === 'presence') {
            if (!currPresence) {
              userMap.set(userId, { ...presenceMessage, joinTimeList: [] })
            } else {
              const { joinTimeList = [], joinTime: currJoinTime } = currPresence
              joinTimeList.push(joinTime)
              userMap.set(userId, { ...currPresence, ...presenceMessage, joinTime: currJoinTime, joinTimeList })
            }

            userMap = sortMostRecentByJoinTime(userMap)
          } else if (type === 'presence.update' && currPresence && presenceList) {
            let { joinTime: currJoinTime, idleTime: currIdleTime } = currPresence

            if (presenceList.length < 2) {
              currIdleTime = idleTime
            } else {
              if (presenceList.some((p: PresenceCallbackMessageData) => p.idleTime === null)) {
                currIdleTime = null
              } else {
                const idleTimeList = presenceList.map((p: PresenceCallbackMessageData) => {
                  if (p.idleTime) return new Date(p.idleTime).getTime()
                  return 0
                })
                const recentIdleTime = Math.max(...idleTimeList)
                currIdleTime = new Date(recentIdleTime).toISOString()
              }
            }

            userMap.set(userId, {
              ...currPresence,
              ...message,
              joinTime: currJoinTime,
              idleTime: currIdleTime
            })
          } else if (type === 'presence.remove' && currPresence) {
            const { joinTimeList = [], joinTime: currJoinTime } = currPresence

            if (joinTimeList.length > 0) {
              // if the furthest joinTime is close re-sorting avatar UI
              if (new Date(currJoinTime).getTime() === new Date(joinTime).getTime()) {
                userMap.set(userId, { ...currPresence, joinTimeList: joinTimeList.slice(1), joinTime: joinTimeList[0] })
                userMap = sortMostRecentByJoinTime(userMap)
              } else {
                // otherwise just remove the closed tab by joinTime
                const activeJoinTime = joinTimeList.filter(
                  (time) => new Date(time).getTime() !== new Date(joinTime).getTime()
                )
                userMap.set(userId, { ...currPresence, joinTimeList: activeJoinTime })
              }
            } else {
              // only one tab is open
              userMap.delete(userId)
            }
          }

          return userMap
        })
      }

      let presenceList = presenceUserMap.current.get(userId)

      if (type === 'presence') {
        if (presenceList) {
          presenceUserMap.current.set(userId, [...presenceList, message])
        } else {
          presenceUserMap.current.set(userId, [message])
        }
      } else if (type === 'presence.update') {
        if (presenceList) {
          presenceList = presenceList.map((p: PresenceCallbackMessageData) => {
            if (p.tabId === tabId) {
              return message
            }
            return p
          })
          presenceUserMap.current.set(userId, presenceList)
        }
      } else if (type === 'presence.remove') {
        if (presenceList) {
          presenceList = presenceList.filter((p: PresenceCallbackMessageData) => p.tabId !== tabId)
          presenceUserMap.current.set(userId, presenceList)
        }
      }

      updatePresenceAvatar(presenceUserMap.current, message)
    },
    [sortMostRecentByJoinTime]
  )

  const setFileParticipants = useCallback(
    (participants: PresenceCallbackMessageData[]) => {
      const presenceUsers = participants.reduce((acc, curr) => {
        const { userId } = curr

        if (acc.has(userId)) {
          acc.set(userId, [...acc.get(userId), curr])
        } else {
          acc.set(userId, [curr])
        }
        return acc
      }, new Map())

      let userMap = new Map()
      Array.from(presenceUsers.entries()).forEach(([userId, presenceList]) => {
        const joinTimeList = presenceList
          .map((p: PresenceCallbackMessageData) => p.joinTime)
          .sort((a: string, b: string) => a.localeCompare(b))

        let recentIdleTime = presenceList[0].idleTime
        if (presenceList.some((p: PresenceCallbackMessageData) => p.idleTime === null)) {
          recentIdleTime = null
        } else {
          presenceList.forEach((p: PresenceCallbackMessageData) => {
            if (p.idleTime && new Date(p.idleTime).getTime() > new Date(recentIdleTime).getTime()) {
              recentIdleTime = p.idleTime
            }
          })
        }
        userMap.set(userId, {
          ...presenceList[0],
          joinTime: joinTimeList[0],
          joinTimeList: joinTimeList.slice(1),
          idleTime: recentIdleTime
        })
      })

      // compare with other user from the most recent joinTime to the furthest joinTime
      userMap = sortMostRecentByJoinTime(userMap)

      setUsers(userMap)
      presenceUserMap.current = new Map(presenceUsers)
    },
    [sortMostRecentByJoinTime]
  )

  useEffect(() => {
    if (isPresenceEnabled && !isFetchPresenceUserLoading) {
      setFileParticipants(presenceUsers)
    }
  }, [isPresenceEnabled, isFetchPresenceUserLoading, setFileParticipants, presenceUsers])

  useEffect(() => {
    // set current file participants
    if (fileId && broadcaster && !broadcaster.presenceManagerMap.has(fileId) && isPresenceEnabled) {
      broadcaster.subscribePresenceFile(fileId, handlePresenceMessage)
    }
    return () => {
      if (fileId && broadcaster && isPresenceEnabled) {
        // @ts-ignore
        broadcaster.unsubscribePresenceFile(fileId)
      }
    }
  }, [fileId, broadcaster, isPresenceEnabled, isFetchPresenceUserLoading, handlePresenceMessage])

  return { users: Array.from(users.values()) }
}
