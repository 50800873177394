import { CubicBezier } from '../shape/cubic_bezier'
import { Num } from './num'
import { Vector2 } from './Vector2'
import { Rect2 } from './Rect2'

/** @typedef {import('./Vector2').Vector2Like} Vector2Like */

/**
 * @typedef {object} DecomposeResult
 * @property {Vector2} translation
 * @property {number} rotation
 * @property {Vector2} skew
 * @property {Vector2} scale
 */

/**
 * The Matrix class as an object, which makes it a lot faster,
 * here is a representation of it :
 * | a | c | tx|
 * | b | d | ty|
 * | 0 | 0 | 1 |
 * @param {number} [a=1] - x scale
 * @param {number} [b=0] - y skew
 * @param {number} [c=0] - x skew
 * @param {number} [d=1] - y scale
 * @param {number} [tx=0] - x translation
 * @param {number} [ty=0] - y translation
 */
export function Transform2D(a = 1, b = 0, c = 0, d = 1, tx = 0, ty = 0) {
    /** @type {number} */
    this.a = a
    /** @type {number} */
    this.b = b
    /** @type {number} */
    this.c = c
    /** @type {number} */
    this.d = d
    /** @type {number} */
    this.tx = tx
    /** @type {number} */
    this.ty = ty

    /** @type {number[]} */
    this._array = null
}
Transform2D.prototype = {
    constructor: Transform2D,
    /**
     * returns new Vector2
     * @param {Vector2} [r_out]
     * @returns {Vector2}
     */
    get_origin(r_out = new Vector2()) {
        return r_out.set(this.tx, this.ty)
    },

    /**
     * @param {Vector2Like} value
     * @returns {this}
     */
    set_origin(value) {
        this.tx = value.x
        this.ty = value.y
        return this
    },

    /**
     * @param {number} x
     * @param {number} y
     * @returns {this}
     */
    set_origin_n(x, y) {
        this.tx = x
        this.ty = y
        return this
    },

    get_rotation() {
        return Math.atan2(this.b, this.a)
    },

    /**
     * @param {number} value
     * @returns {this}
     */
    set_rotation(value) {
        const cr = Math.cos(value)
        const sr = Math.sin(value)
        this.a = cr
        this.b = sr
        this.c = -sr
        this.d = cr
        return this
    },

    /**
     * returns new Vector2
     * @param {Vector2} [r_out]
     * @returns {Vector2}
     */
    get_scale(r_out = new Vector2()) {
        const basis_determinant = Math.sign(this.a * this.d - this.b * this.c)
        r_out.x = Math.sqrt(this.a * this.a + this.b * this.b)
        r_out.y = Math.sqrt(this.c * this.c + this.d * this.d) * basis_determinant
        return r_out
    },

    /**
     * @param {Vector2Like} scale
     * @returns {this}
     */
    set_scale(scale) {
        const vec = new Vector2()
        vec.set(this.a, this.b).normalize()
        this.a = vec.x * scale.x
        this.b = vec.y * scale.x
        vec.set(this.c, this.d).normalize()
        this.c = vec.x * scale.y
        this.d = vec.y * scale.y
        return this
    },

    /**
     * @param {number} x
     * @param {number} y
     * @returns {this}
     */
    set_scale_n(x, y) {
        const vec = new Vector2()
        vec.set(this.a, this.b).normalize()
        this.a = vec.x * x
        this.b = vec.y * x
        vec.set(this.c, this.d).normalize()
        this.c = vec.x * y
        this.d = vec.y * y
        return this
    },

    /**
     * @param {number} p_rot
     * @param {Vector2Like} p_scale
     * @returns {this}
     */
    set_rotation_and_scale(p_rot, p_scale) {
        const c = Math.cos(p_rot)
        const s = Math.sin(p_rot)
        this.a = c * p_scale.x
        this.d = c * p_scale.y
        this.c = -s * p_scale.y
        this.b = s * p_scale.x
        return this
    },

    /**
     * @param {number} rot
     * @param {Vector2Like} pos
     * @returns {this}
     */
    set_rotation_and_origin(rot, pos) {
        const cr = Math.cos(rot)
        const sr = Math.sin(rot)
        this.a = cr
        this.b = sr
        this.c = -sr
        this.d = cr
        this.tx = pos.x
        this.ty = pos.y
        return this
    },

    get_skew() {
        const vec = get_skew_vec
        const vec2 = get_skew_vec2
        const det = this.basis_determinant()
        const res = Math.acos(
            vec.set(this.a, this.b).normalize().dot(
                vec2.set(this.c, this.d).normalize().scale(Math.sign(det))
            )
        ) - Math.PI * 0.5
        return res
    },

    /**
     * @param {number} p_angle
     * @returns {this}
     */
    set_skew(p_angle) {
        const vec = set_skew_vec
        const det = this.basis_determinant()
        vec.set(this.a, this.b)
            .rotate(Math.PI * 0.5 + p_angle)
            .normalize()
            .scale(Math.sign(det) * Math.hypot(this.c, this.d))
        this.c = vec.x
        this.d = vec.y
        return this
    },

    /**
     * @param {number} p_rot
     * @param {Vector2Like} p_scale
     * @param {number} p_skew
     * @returns {this}
     */
    set_rotation_scale_and_skew(p_rot, p_scale, p_skew) {
        this.a = Math.cos(p_rot) * p_scale.x
        this.d = Math.cos(p_rot + p_skew) * p_scale.y
        this.c = -Math.sin(p_rot + p_skew) * p_scale.y
        this.b = Math.sin(p_rot) * p_scale.x
        return this
    },

    /**
     * Creates a Matrix object based on the given array. The Element to Matrix mapping order is as follows:
     *
     * a = array[0]
     * b = array[1]
     * c = array[3]
     * d = array[4]
     * tx = array[2]
     * ty = array[5]
     *
     * @param {number[]} array - The array that the matrix will be populated from.
     * @returns {this}
     */
    fromArray(array) {
        this.a = array[0]
        this.b = array[1]
        this.c = array[2]
        this.d = array[3]
        this.tx = array[4]
        this.ty = array[5]
        return this
    },

    /**
     * @returns {this}
     */
    reset() {
        this.a = 1
        this.b = 0
        this.c = 0
        this.d = 1
        this.tx = 0
        this.ty = 0
        return this
    },

    /**
     * sets the matrix properties
     *
     * @param {number} [a] - Matrix component
     * @param {number} [b] - Matrix component
     * @param {number} [c] - Matrix component
     * @param {number} [d] - Matrix component
     * @param {number} [tx] - Matrix component
     * @param {number} [ty] - Matrix component
     *
     * @returns {this}
     */
    set(a = 1, b = 0, c = 0, d = 1, tx = 0, ty = 0) {
        this.a = a
        this.b = b
        this.c = c
        this.d = d
        this.tx = tx
        this.ty = ty
        return this
    },

    /**
     * Creates an array from the current Matrix object.
     *
     * @param {boolean} [p_transpose=false] - Whether we need to transpose the matrix or not
     * @param {number[]} [out] - If provided the array will be assigned to out
     * @returns {number[]} the newly created array which contains the matrix
     */
    to_array(p_transpose = false, out) {
        if (!out && !this._array) {
            this._array = new Array(9)
        }
        const _out = out || this._array
        if (p_transpose) {
            _out[0] = this.a
            _out[1] = this.c
            _out[2] = this.tx
            _out[3] = this.b
            _out[4] = this.d
            _out[5] = this.ty
            _out[6] = 0
            _out[7] = 0
            _out[8] = 1
        } else {
            _out[0] = this.a
            _out[1] = this.b
            _out[2] = 0
            _out[3] = this.c
            _out[4] = this.d
            _out[5] = 0
            _out[6] = this.tx
            _out[7] = this.ty
            _out[8] = 1
        }

        return _out
    },

    /**
     * @param {number} p_row
     * @returns {Vector2}
     */
    get_elements(p_row) {
        switch (p_row) {
            case 0: return new Vector2(this.a, this.b)
            case 1: return new Vector2(this.c, this.d)
            case 2: return new Vector2(this.tx, this.ty)
        }
    },

    /**
     * @param {number} p_axis
     * @returns {Vector2}
     */
    get_axis(p_axis) {
        switch (p_axis) {
            case 0: return new Vector2(this.a, this.b)
            case 1: return new Vector2(this.c, this.d)
            case 2: return new Vector2(this.tx, this.ty)
        }
    },

    basis_determinant() {
        return this.a * this.d - this.b * this.c
    },

    /**
     * @param {Transform2D} p_xform
     * @returns {boolean}
     */
    equals(p_xform) {
        return (
            this.a === p_xform.a
            &&
            this.b === p_xform.b
            &&
            this.c === p_xform.c
            &&
            this.d === p_xform.d
            &&
            this.tx === p_xform.tx
            &&
            this.ty === p_xform.ty
        )
    },

    /**
     * @param {Vector2Like} p_vec - The origin
     * @param {Vector2} [r_out] - The point that the new position is assigned to (allowed to be same as input)
     * @returns {Vector2} The new point, transformed through this matrix
     */
    basis_xform(p_vec, r_out = new Vector2()) {
        const x = (this.a * p_vec.x) + (this.c * p_vec.y)
        const y = (this.b * p_vec.x) + (this.d * p_vec.y)
        return r_out.set(x, y)
    },

    /**
     * @param {Vector2Like} p_vec - The origin
     * @param {Vector2} [r_out] - The point that the new position is assigned to (allowed to be same as input)
     * @returns {Vector2} The new point, inverse-transformed through this matrix
     */
    basis_xform_inv(p_vec, r_out = new Vector2()) {
        const x = (this.a * p_vec.x) + (this.b * p_vec.y)
        const y = (this.c * p_vec.x) + (this.d * p_vec.y)
        return r_out.set(x, y)
    },

    /**
     * Get a new position with the current transformation applied.
     * Can be used to go from a child's coordinate space to the world coordinate space. (e.g. rendering)
     *
     * @param {Vector2Like} p_vec - The origin
     * @param {Vector2} [r_out] - The point that the new position is assigned to (allowed to be same as input)
     * @returns {Vector2} The new point, transformed through this matrix
     */
    xform(p_vec, r_out = new Vector2()) {
        const x = (this.a * p_vec.x) + (this.c * p_vec.y) + this.tx
        const y = (this.b * p_vec.x) + (this.d * p_vec.y) + this.ty
        return r_out.set(x, y)
    },

    /**
     * Get a new Vector with the current transformation applied.
     *
     * @param {Vector2Like} p_vec - The vector
     * @param {Vector2} [r_out] - The vector that the new position is assigned to (allowed to be same as input)
     * @returns {Vector2} The new point, transformed through this matrix
     */
    xform_vec(p_vec, r_out = new Vector2()) {
        const origin = this.xform(Vector2.ZERO)
        const vec = this.xform(p_vec, r_out)
        vec.sub(origin)
        return vec
    },

    /**
     * Get a new position with the inverse of the current transformation applied.
     * Can be used to go from the world coordinate space to a child's coordinate space. (e.g. input)
     *
     * @param {Vector2Like} p_vec - The origin
     * @param {Vector2} [r_out] - The point that the new position is assigned to (allowed to be same as input)
     * @returns {Vector2} The new point, inverse-transformed through this matrix
     */
    xform_inv(p_vec, r_out = new Vector2()) {
        const x = this.a * (p_vec.x - this.tx) + this.b * (p_vec.y - this.ty)
        const y = this.c * (p_vec.x - this.tx) + this.d * (p_vec.y - this.ty)
        return r_out.set(x, y)
    },

    /**
     * @param {Rect2} p_rect
     * @param {Rect2} [r_out]
     * @returns {Rect2}
     */
    xform_rect(p_rect, r_out = new Rect2()) {
        r_out.set(0, 0, 0, 0)
        const x = xform_rect_x.set(this.a * p_rect.width, this.b * p_rect.width)
        const y = xform_rect_y.set(this.c * p_rect.height, this.d * p_rect.height)
        const pos = xform_rect_pos.set(p_rect.x, p_rect.y)
        this.xform(pos, pos)

        r_out.x = pos.x
        r_out.y = pos.y
        const vec = xform_rect_vec.set(0, 0)
        r_out.expand_to(vec.copy(pos).add(x))
        r_out.expand_to(vec.copy(pos).add(y))
        r_out.expand_to(vec.copy(pos).add(x).add(y))

        return r_out
    },

    /**
     * @param {Rect2} p_rect
     * @param {Rect2} [r_out]
     * @returns {Rect2}
     */
    xform_inv_rect(p_rect, r_out = new Rect2()) {
        const ends_0 = xform_inv_rect_vec1.set(p_rect.x, p_rect.y)
        const ends_1 = xform_inv_rect_vec2.set(p_rect.x, p_rect.y + p_rect.height)
        const ends_2 = xform_inv_rect_vec3.set(p_rect.x + p_rect.width, p_rect.y + p_rect.height)
        const ends_3 = xform_inv_rect_vec4.set(p_rect.x + p_rect.width, p_rect.y)

        this.xform_inv(ends_0, ends_0)
        this.xform_inv(ends_1, ends_1)
        this.xform_inv(ends_2, ends_2)
        this.xform_inv(ends_3, ends_3)

        r_out.x = ends_0.x
        r_out.y = ends_0.y
        r_out.expand_to(ends_1)
        r_out.expand_to(ends_2)
        r_out.expand_to(ends_3)

        return r_out
    },

    /**
     * @param {CubicBezier} p_curve
     * @returns {CubicBezier}
     */
    xform_cubic_bezier(p_curve) {
        /** @type {number[]} */
        const vertices = []

        const vec = new Vector2()
        for (const p of p_curve.points) {
            this.xform(p, vec)
            vertices.push(vec.x, vec.y)
        }

        return new CubicBezier(vertices)
    },

    /**
     * Translates the matrix on the x and y.
     *
     * @param {number} x How much to translate x by
     * @param {number} y How much to translate y by
     * @returns {this}
     */
    translate(x, y) {
        this.tx += x
        this.ty += y

        return this
    },

    /**
     * Return a new Matrix that not translated.
     * @returns {Transform2D}
     */
    untranslated() {
        const copy = this.clone()
        copy.tx = 0
        copy.ty = 0
        return copy
    },

    /**
     * Applies a scale transformation to the matrix.
     *
     * @param {number} x The amount to scale horizontally
     * @param {number} y The amount to scale vertically
     * @returns {this}
     */
    scale(x, y) {
        this.a *= x
        this.d *= y
        this.c *= x
        this.b *= y
        this.tx *= x
        this.ty *= y
        return this
    },

    /**
     * @param {number} x
     * @param {number} y
     * @returns {this}
     */
    scale_basis(x, y) {
        this.a *= x
        this.b *= y
        this.c *= x
        this.d *= y
        return this
    },

    /**
     * Warning! This function calculation has a bug, it's not working as expected.
     * Skews the matrix on the x and y
     *
     * @param {number} x
     * @param {number} y
     * @returns {this}
     */
    // skew(x, y) {
    //     const tX = Math.tan(y)
    //     const tY = Math.tan(x)

    //     this.b += tX
    //     this.c += tY

    //     this.tx += this.ty * tY
    //     this.ty += this.tx * tX

    //     return this
    // },

    /**
     * Applies a rotation transformation to the matrix.
     *
     * @param {number} angle - The angle in radians.
     * @returns {this}
     */
    rotate(angle) {
        const cos = Math.cos(angle)
        const sin = Math.sin(angle)

        const a1 = this.a
        const c1 = this.c
        const tx1 = this.tx

        this.a = (a1 * cos) - (this.b * sin)
        this.b = (a1 * sin) + (this.b * cos)
        this.c = (c1 * cos) - (this.d * sin)
        this.d = (c1 * sin) + (this.d * cos)
        this.tx = (tx1 * cos) - (this.ty * sin)
        this.ty = (tx1 * sin) + (this.ty * cos)

        return this
    },

    rotate_basis(angle) {
        const cos = Math.cos(angle)
        const sin = Math.sin(angle)
        this.a = cos * this.a + sin * this.c
        this.b = cos * this.b + sin * this.d
        this.c = -sin * this.a + cos * this.c
        this.d = -sin * this.b + cos * this.d
        return this
    },


    /** Right Multiplication (Column Space) - start */

    /**
     * Translate current matrix accumulative.
     * Notice that it should only chain with other right multiplication, or it might not work as expected.
     * @param {number} tx - translation for x
     * @param {number} ty - translation for y
     * @returns {this}
     */
    translate_right(tx, ty) {
        this.append(new Transform2D(1, 0, 0, 1, tx, ty))
        return this
    },

    /**
     * Apply scale to the current matrix accumulative.
     * Notice that it should only chain with other right multiplication, or it might not work as expected.
     * @param {number} sx - scale factor x (1 does nothing)
     * @param {number} sy - scale factor y (1 does nothing)
     * @returns {this}
     */
    scale_right(sx, sy) {
        this.append(new Transform2D(sx, 0, 0, sy, 0, 0))
        return this
    },

    /**
     * Apply skew to the current matrix accumulative.
     * Notice that it should only chain with other right multiplication, or it might not work as expected.
     * @param {number} sx - amount of skew for x
     * @param {number} sy - amount of skew for y
     * @returns {this}
     * */
    skew_right(sx, sy) {
        this.append(new Transform2D(1, Math.tan(sy), Math.tan(sx), 1, 0, 0))
        return this
    },

    /**
     * Apply rotate to current matrix accumulative.
     * Notice that it should only chain with other right multiplication, or it might not work as expected.
     * @param {number} angle - angle in radians
     * @returns {this}
     */
    rotate_right(angle) {
        const cos = Math.cos(angle)
        const sin = Math.sin(angle)
        this.append(new Transform2D(cos, sin, -sin, cos, 0, 0))
        return this
    },

    /** Right Multiplication (Column Space) - end */


    /**
     * Invert this matrix.
     * This method assumes the basis is a rotation matrix, with no scaling.
     * Use affine_inverse instead if scaling is required.
     * @returns {this}
     */
    invert() {
        const tmp = this.b; this.b = this.c; this.c = tmp
        const tx = this.a * (-this.tx) + this.c * (-this.ty)
        const ty = this.b * (-this.tx) + this.d * (-this.ty)
        this.tx = tx
        this.ty = ty
        return this
    },

    /**
     * Return a inverted matrix
     * @returns {Transform2D}
     */
    inverse() {
        return this.clone().invert()
    },

    /**
     * @returns {this}
     */
    orthonormalize() {
        const x = new Vector2(this.a, this.b)
        const y = new Vector2(this.c, this.d)

        x.normalize()
        this.a = x.x
        this.b = x.y

        y.subtract(x.scale(x.dot(y)))
        y.normalize()

        this.c = y.x
        this.d = y.y

        return this
    },

    orthonormalized() {
        return this.clone().orthonormalize()
    },

    /**
     * @param {Transform2D} matrix
     * @returns {this}
     * */
    append(matrix) {
        const a1 = this.a
        const b1 = this.b
        const c1 = this.c
        const d1 = this.d

        this.a = (matrix.a * a1) + (matrix.b * c1)
        this.b = (matrix.a * b1) + (matrix.b * d1)
        this.c = (matrix.c * a1) + (matrix.d * c1)
        this.d = (matrix.c * b1) + (matrix.d * d1)

        this.tx = (matrix.tx * a1) + (matrix.ty * c1) + this.tx
        this.ty = (matrix.tx * b1) + (matrix.ty * d1) + this.ty

        return this
    },

    /**
     * Sets the matrix based on all the available properties
     *
     * @param {number} x - Position on the x axis
     * @param {number} y - Position on the y axis
     * @param {number} pivot_x - Pivot on the x axis
     * @param {number} pivot_y - Pivot on the y axis
     * @param {number} scale_x - Scale on the x axis
     * @param {number} scale_y - Scale on the y axis
     * @param {number} rotation - Rotation in radians
     * @param {number} skew_x - Skew on the x axis
     * @param {number} skew_y - Skew on the y axis
     * @returns {this}
     */
    set_transform(x, y, pivot_x, pivot_y, scale_x, scale_y, rotation, skew_x, skew_y) {
        this.a = Math.cos(rotation + skew_y) * scale_x
        this.b = Math.sin(rotation + skew_y) * scale_x
        this.c = -Math.sin(rotation - skew_x) * scale_y
        this.d = Math.cos(rotation - skew_x) * scale_y

        this.tx = x - ((pivot_x * this.a) + (pivot_y * this.c))
        this.ty = y - ((pivot_x * this.b) + (pivot_y * this.d))

        return this
    },

    /**
     * Prepends the given Matrix to this Matrix (`Matrix_A *= Matrix_B` in Godot)
     *
     * @param {Transform2D} xform - The matrix to prepend
     * @returns {this}
     */
    prepend(xform) {
        const tx1 = this.tx

        if (xform.a !== 1 || xform.b !== 0 || xform.c !== 0 || xform.d !== 1) {
            const a1 = this.a
            const c1 = this.c

            this.a = (a1 * xform.a) + (this.b * xform.c)
            this.b = (a1 * xform.b) + (this.b * xform.d)
            this.c = (c1 * xform.a) + (this.d * xform.c)
            this.d = (c1 * xform.b) + (this.d * xform.d)
        }

        this.tx = (tx1 * xform.a) + (this.ty * xform.c) + xform.tx
        this.ty = (tx1 * xform.b) + (this.ty * xform.d) + xform.ty

        return this
    },

    /**
     * Inverts this matrix
     *
     * @returns {this}
     */
    affine_inverse() {
        const det = (this.a * this.d) - (this.b * this.c)
        if (det === 0) {
            return this.pseudoInverseAffine()
        }
        const idet = 1.0 / det

        const tmp = this.d
        this.d = this.a
        this.a = tmp

        this.a *= idet
        this.b *= -idet
        this.c *= -idet
        this.d *= idet

        const tx = (this.a * -this.tx) + (this.c * -this.ty)
        const ty = (this.b * -this.tx) + (this.d * -this.ty)

        this.tx = tx
        this.ty = ty

        return this
    },

    /**
     * Return Pseudo-inverse ( Moore-Penrose inverse ) when the matrix is uninvertible
     * It is the closest to an inverse, even for non-square matrices.
     * @returns {this}
     */
    pseudoInverseAffine(){
        const dotProduct1 = this.a * this.a + this.b * this.b  // Dot product of first row with itself
        const dotProduct2 = this.c * this.c + this.d * this.d  // Dot product of second row with itself
        if (dotProduct1 === 0 && dotProduct2 === 0) {// 0 matrix
            this.tx *= -1
            this.ty *= -1
            return this
        } else if (dotProduct1 === 0) {
            this.a = 0
            this.b /= dotProduct2
            this.c = 0
            this.d /= dotProduct2
        } else {
            this.a /= dotProduct1
            this.b = 0
            this.c /= dotProduct1
            this.d = 0
        }
        const tx = (this.a * -this.tx) + (this.c * -this.ty)
        const ty = (this.b * -this.tx) + (this.d * -this.ty)
        this.tx = tx
        this.ty = ty
        return this

    },
    /**
     * Resets this Matix to an identity (default) matrix.
     *
     * @returns {this}
     */
    identity() {
        this.a = 1
        this.b = 0
        this.c = 0
        this.d = 1
        this.tx = 0
        this.ty = 0

        return this
    },

    /**
     * @param {Transform2D} p_transform
     * @param {number} p_c
     * @returns {Transform2D}
     */
    interpolate(p_transform, p_c) {
        const sx = Num.lerp(this.get_scale().x, p_transform.get_scale().x, p_c)
        const sy = Num.lerp(this.get_scale().y, p_transform.get_scale().y, p_c)
        const r = Num.lerp_angle(this.get_rotation(), p_transform.get_rotation(), p_c)
        const tx = Num.lerp(this.get_origin().x, p_transform.get_origin().x, p_c)
        const ty = Num.lerp(this.get_origin().y, p_transform.get_origin().y, p_c)

        this.set_transform(tx, ty, 0, 0, sx, sy, r, 0, 0)

        return this
    },

    /**
     * Creates a new Matrix object with the same values as this one.
     *
     * @returns {Transform2D}
     */
    clone() {
        return new Transform2D(
            this.a,
            this.b,
            this.c,
            this.d,
            this.tx,
            this.ty
        )
    },

    /**
     * Copy the values of given matrix to this one.
     *
     * @param {Transform2D} matrix - The matrix to copy from.
     * @returns {this}
     */
    copy(matrix) {
        this.a = matrix.a
        this.b = matrix.b
        this.c = matrix.c
        this.d = matrix.d
        this.tx = matrix.tx
        this.ty = matrix.ty

        return this
    },

    /**
     * Works only when transforms are applied in this order: scale, skew, rotate, translate
     * Reference: https://stackoverflow.com/a/45392997
     *
     * @returns {DecomposeResult}
     */
    decompose2() {
        const t_new = this
        let n_rotation = 0
        const n_scale = new Vector2()
        const n_skew = new Vector2()

        n_rotation = Math.atan2(this.b, this.a)
        const skew = Math.atan2(this.d, this.c) - Math.PI / 2 - n_rotation
        n_skew.set(-skew, 0)
        n_scale.set((Math.sqrt(this.a * this.a + this.b * this.b)), Math.sqrt((this.c * this.c + this.d * this.d)) * Math.cos(skew))

        return {
            translation: new Vector2(t_new.tx, t_new.ty),
            rotation: n_rotation,
            scale: n_scale,
            skew: n_skew,
        }
    },

    /**
     * @returns {DecomposeResult}
     */
    decompose() {
        const a = this.a
        const b = this.b
        const c = this.c
        const d = this.d
        const x = this.tx
        const y = this.ty

        const D = a * d - b * c
        const r = Math.sqrt(a * a + b * b)

        const result = {
            translation: new Vector2(x, y),
            rotation: 0,
            scale: new Vector2(1, 1),
            skew: new Vector2(0, 0),
        }
        if (a === b && b === c && c === d) {
            result.rotation = 0
            result.skew.x = 0
            result.scale.x = 0
            result.scale.y = 0
        } else if (a === 0 && b === 0) {
            result.rotation = -Math.atan2(c, d)
            result.skew.x = 0
            result.scale.x = 0
            result.scale.y = Math.sqrt(c * c + d * d)
        } else if (c === 0 && d === 0) {
            result.rotation = -Math.atan2(-b, a)
            result.skew.x = 0
            result.scale.x = r
            result.scale.y = 0
        } else {
            result.rotation = -Math.atan2(-b, a)
            result.skew.x = Math.atan2(a * c + b * d, D)
            result.scale.x = r
            result.scale.y = D / r
        }

        return result
    },
}

Transform2D.IDENTITY = new Transform2D()

const xform_rect_x = new Vector2()
const xform_rect_y = new Vector2()
const xform_rect_pos = new Vector2()
const xform_rect_vec = new Vector2()

const get_skew_vec = new Vector2()
const get_skew_vec2 = new Vector2()

const set_skew_vec = new Vector2()

const xform_inv_rect_vec1 = new Vector2()
const xform_inv_rect_vec2 = new Vector2()
const xform_inv_rect_vec3 = new Vector2()
const xform_inv_rect_vec4 = new Vector2()
