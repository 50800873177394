import { PropertyType, StrokeDashType } from '../constants';
import { Property } from './property';

export class StrokeDashProperty {
  public name: string;
  public dashType?: StrokeDashType;
  public value?: Property;

  constructor(dashType?: StrokeDashType) {
    this.name = '';
    this.dashType = dashType;
    this.value = new Property(this, PropertyType.STROKE_DASH);
  }

  fromJSON(json: Record<string, any>): StrokeDashProperty {
    this.name = json.nm;
    this.dashType = json.n;
    this.value = new Property(this, PropertyType.STROKE_DASH).fromJSON(json.v);
    return this;
  }

  toJSON(): Record<string, any> {
    return {
      nm: this.name,
      n: this.dashType,
      v: this.value,
    };
  }
}

export class StrokeDash {
  public dashes: StrokeDashProperty[] = [];

  /**
   * Convert the class instance to Lottie JSON object.
   *
   * Called by Javascript when serializing object with JSON.stringify()
   *
   * @returns       JSON object
   */
  public toJSON(): Record<string, any> {
    return this.dashes;
  }

  /**
   * Convert the Lottie JSON object to class instance.
   *
   * @param json    JSON object
   * @returns       StrokeDash instance
   */
  public fromJSON(json: Record<string, any>): StrokeDash {
    this.dashes = json.map((jDash: Record<string, any>) => {
      return new StrokeDashProperty().fromJSON(jDash);
    });

    return this;
  }
}
