import React from 'react'
import { IconButton } from '../../shared'
import { useSetModal } from '../../../providers/ModalProvider'

type HeaderProps = {
  name: string
  modalKey: string
}

const Header = ({ name, modalKey }: HeaderProps) => {
  const { closeModal } = useSetModal(modalKey)

  return (
    <div className="w-[248px] flex text-white p-16 justify-between">
      <span className="font-medium">{name}</span>
      <div className="cursor-pointer" data-test-id="border-advanced-menu-close">
        <IconButton icon="Cross" onClick={closeModal} aria-label="Close" />
      </div>
    </div>
  )
}

export default React.memo(Header)
