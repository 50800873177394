import React, { createContext, useContext } from 'react'

type FileContextType = {
  id: string
  projectId: string
  fileName: string
}

type FileProviderProps = {
  id: string
  projectId: string
  fileName: string
  children: React.ReactNode
}

const defaultValue = {
  id: '',
  projectId: '',
  fileName: ''
}

export const FileContext = createContext<FileContextType>(defaultValue)

const FileProvider = ({ id, projectId, fileName, children }: FileProviderProps) => {
  return <FileContext.Provider value={{ id, projectId, fileName }}>{children}</FileContext.Provider>
}

function useFileContext(): FileContextType {
  const context = useContext(FileContext)
  if (context === undefined) {
    throw new Error('useFileContext must be used within a FileProvider')
  }
  return context
}

export { FileProvider, useFileContext }
