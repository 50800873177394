import React, { useState } from 'react'
import Tooltip from '../Tooltip'

type RenameTextProps = {
  className?: string
  originalText: string
  onFinish: (originalText: string) => void
  onActive?: () => void
  onCancel?: () => void
}

const RenameText = ({ className = '', originalText, onFinish, onActive, onCancel }: RenameTextProps) => {
  const [text, setText] = useState(originalText)
  const [isEditing, setIsEditing] = useState(false)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== originalText) {
      setText(e.target.value)
    }
  }

  const handleDoubleClick = () => {
    setIsEditing(true)
    onActive?.()
  }

  const handleBlur = () => {
    onFinish(text)
    setIsEditing(false)
    onCancel?.()
  }

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.relatedTarget) {
      e.target.select()
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleBlur()
    }
    if (e.key === 'Escape') {
      setText(originalText)
      onFinish(originalText)
      setIsEditing(false)
      onCancel?.()
    }
  }

  const preventDefault = (e: React.MouseEvent) => {
    e.stopPropagation()
  }

  return isEditing ? (
    <input
      onMouseDown={preventDefault}
      onClick={preventDefault}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      onKeyDown={handleKeyDown}
      value={text}
      className={`input-system-no-handle min-w-0 flex-grow rounded-xs bg-white text-neutral-90 p-4 -ml-4 ${className}`}
      autoFocus
    />
  ) : (
    <Tooltip content={originalText} visibleOnOverflow>
      <div className="flex-grow min-w-0 truncate py-8" onDoubleClick={handleDoubleClick}>
        {originalText}
      </div>
    </Tooltip>
  )
}

export default React.memo(RenameText)
