import { CapShape, EndShape, JoinShape, PointShape } from '@phase-software/types'
import { isVec2 } from '../commons'
import { Vector2 } from '../Vector2'
import { Cell } from './Cell'


/** @typedef {import('./Edge').Edge} Edge */
/** @typedef {import('./Edge').HalfEdge} HalfEdge */
/** @typedef {import('../../../data-store/src/DataStore').DataStore} DataStore */
/** @typedef {import('../../../data-store/src/Setter').SetterData} SetterData */


export class Vertex extends Cell {
    /**
     * Creates new Vertex instance
     * @param {boolean} [virtual=false]
     */
    constructor(virtual = false) {
        super()
        /** @todo Make it serializable in setter data */
        this.pos = new Vector2()
        this.cornerRadius = null
        this.cap = null
        this.end = null
        this.join = null
        this.mirror = PointShape.NONE
        /** @todo Changing the structure of HalfEdge */
        /** @type {Set<HalfEdge>} */
        this.inHalves = new Set()
        /** @type {Set<HalfEdge>} */
        this.outHalves = new Set()
        /** @type {Set<HalfEdge>} */
        this.virtualInHalves = new Set()
        /** @type {Set<HalfEdge>} */
        this.virtualOutHalves = new Set()

        this.isVirtual = virtual
        this.type = 'Vertex'

        /** @type {Vertex} `unlinkedCurveControl` is the curve control id on the same side as this vertex, not connected via an edge, used for independent curve manipulation. */
        this.unlinkedCurveControl = null
        /** @type {Edge} the edge which is controlled by this object as a curve control handle */
        this.controllingEdge = null
        /** @type {Vertex} The real vertex at same side of this vertex if this object is a control handle */
        this.adjacentMainVertex = null
    }

    /**
     * Create Vertex instance from data
     * @param {VertexData} options
     * @returns {Vertex}
     */
    static fromData({
        id,
        pos,
        cornerRadius = null,
        cap = null,
        end = null,
        join = null,
        mirror = PointShape.NONE,
    }) {
        super.fromData({ id })
        const v = new Vertex()
        /** @todo Instead of the id of the setter */
        v.id = id
        v.pos = new Vector2(pos)
        v.cornerRadius = cornerRadius
        v.cap = cap
        v.end = end
        v.join = join
        v.mirror = mirror
        v.type = 'Vertex'
        return v
    }

    /**
     * @param {Partial<Omit<VertexData, 'id'>>} options
     */
    set({ pos, cornerRadius, cap, join, mirror, end }) {
        let updatedPos = false
        if (isVec2(pos)) {
            this.pos = new Vector2(pos)
            updatedPos = true
        }
        // TODO: type and value checks
        if (cornerRadius === null) {
            this.cornerRadius = null
        } else {
            this.cornerRadius = cornerRadius >= 0 ? cornerRadius : this.cornerRadius
        }
        if (cap === null) {
            this.cap = null
        } else {
            this.cap = cap in CapShape ? cap : this.cap
        }
        if (end === null) {
            this.end = null
        } else {
            this.end = end in EndShape ? end : this.end
        }
        if (join === null) {
            this.join = null
        } else {
            this.join = join in JoinShape ? join : this.join
        }

        this.mirror = mirror in PointShape ? mirror : this.mirror

        if (updatedPos) {
            // update bezier curves and bounds of ingress Edges
            for (const e of this.getEdges({ virtual: this.isVirtual })) {
                e.updateBounds()
            }
        }
    }

    /**
     * Creates a copy of a Vertex
     * @returns {Vertex}
     */
    copy() {
        const copy = new Vertex()
        copy.pos.x = this.pos.x
        copy.pos.y = this.pos.y
        copy.cornerRadius = this.cornerRadius
        copy.cap = this.cap
        copy.end = this.end
        copy.join = this.join
        copy.mirror = this.mirror
        copy.adjacentMainVertex = this.adjacentMainVertex
        copy.unlinkedCurveControl = this.unlinkedCurveControl
        copy.flags = this.flags
        return copy
    }

    // TODO: do we need to consider id ?
    /**
     * Checks if this Vertex is equal to another Vertex
     * @param  {Vertex} v
     * @returns {boolean}      true if equal; false otherwise
     */
    eq(v) {
        return (
            v &&
            this.pos.eq(v)
            // this.cornerRadius === v.cornerRadius &&
            // this.cap === v.cap &&
            // this.join === v.join &&
            // this.mirror === v.mirror &&
            // arrEquals(this.faces, v.faces) &&
            // arrEquals(this.edges, v.edges)
        )
    }

    /**
     * Serializes Vertex data
     * @returns {VertexData}
     */
    save() {
        const data = {}
        data.id = this.id
        data.pos = [this.pos[0], this.pos[1]]
        data.cornerRadius = this.cornerRadius
        data.cap = this.cap
        data.end = this.end
        data.join = this.join
        data.mirror = this.mirror
        data.adjMainId = this.adjacentMainVertex?.id
        return data
    }

    /**
     * Returns iterator over all halves starting at this Vertex
     * @param {object} [options]
     * @param {boolean} [options.virtual=false]         if true, only searches virtual HalfEdges
     * @param {boolean} [options.all=false]             if true, searches both real and virtual HalfEdges
     */
    *getOutHalves({ virtual = false, all = false } = {}) {
        if (all || !virtual) {
            for (const half of this.outHalves) {
                yield half
            }
        }
        if (all || virtual) {
            for (const half of this.virtualOutHalves) {
                yield half
            }
        }
    }

    /**
     * Returns iterator over all halves ending at this Vertex
     * @param {object} [options]
     * @param {boolean} [options.virtual=false]         if true, only searches virtual HalfEdges
     * @param {boolean} [options.all=false]             if true, searches both real and virtual HalfEdges
     */
    *getInHalves({ virtual = false, all = false } = {}) {
        if (all || !virtual) {
            for (const half of this.inHalves) {
                yield half
            }
        }
        if (all || virtual) {
            for (const half of this.virtualInHalves) {
                yield half
            }
        }
    }

    /**
     * Returns iterator over all ingress Edges of this Vertex
     * @param {object} [options]
     * @param {boolean} [options.virtual=false]         if true, only searches virtual HalfEdges
     * @param {boolean} [options.all=false]             if true, searches both real and virtual HalfEdges
     */
    *getEdges(options) {
        for (const half of this.getOutHalves(options)) {
            yield half.edge
        }
    }

    /**
     * Returns iterator over all neighbouring Vertices of this Vertex
     *  Internaly allocates a Set to check for repeating neighbours,
     *  because there could be more than one edge between same pair of Vertices
     * @param {object} [options]
     * @param {boolean} [options.virtual=false]         if true, only searches in virtual HalfEdges
     * @param {boolean} [options.all=false]             if true, searches in both real and virtual HalfEdges
     */
    *getNeighbours(options) {
        const set = new Set()
        for (const half of this.getOutHalves(options)) {
            if (set.has(half.w)) {
                continue
            }
            set.add(half.w)
            yield half.w
        }
    }

    /**
     * Iterator for all HalfEdges that start from this Vertex and end at specified Vertex `w`
     * @param {Vertex} w   ending Vertex
     * @param {object} [options]
     * @param {boolean} [options.virtual=false]         if true, only searches in virtual HalfEdges
     * @param {boolean} [options.all=false]             if true, searches in both real and virtual HalfEdges
     * @yields {HalfEdge} HalfEdges that start from this Vertex and end at Vertex `w`
     */
    *findOutHalves(w, options) {
        for (const half of this.getOutHalves(options)) {
            if (half.w === w) {
                yield half
            }
        }
    }

    /**
     * Connects Edge to this vertex
     * @param {Edge} edge
     * @returns {boolean}      true if success; false if this Vertex does not belong to the specified Edge
     */
    connectEdge(edge) {
        let outHalf, inHalf
        if (edge.v === this) {
            outHalf = edge.halves[0]
            inHalf = edge.halves[1]
        } else if (edge.w === this) {
            outHalf = edge.halves[1]
            inHalf = edge.halves[0]
        }
        if (!outHalf || !inHalf) {
            return false
        }
        this.upperTierIDs.add(edge.id)
        if (edge.isVirtual) {
            this.virtualOutHalves.add(outHalf)
            this.virtualInHalves.add(inHalf)
        } else {
            this.outHalves.add(outHalf)
            this.inHalves.add(inHalf)
        }
        return true
    }

    /**
     * Disconnects edge from this vertex
     * @param  {Edge} edge
     */
    disconnectEdge(edge) {
        this.upperTierIDs.delete(edge.id)
        if (edge.v === this) {
            if (edge.isVirtual) {
                this.virtualOutHalves.delete(edge.halves[0])
                this.virtualInHalves.delete(edge.halves[1])
            } else {
                this.outHalves.delete(edge.halves[0])
                this.inHalves.delete(edge.halves[1])
            }
        } else if (edge.w === this) {
            if (edge.isVirtual) {
                this.virtualOutHalves.delete(edge.halves[1])
                this.virtualInHalves.delete(edge.halves[0])
            } else {
                this.outHalves.delete(edge.halves[1])
                this.inHalves.delete(edge.halves[0])
            }
        }
    }
}

/** @typedef {"NONE" | "ANGLE" | "ANGLE_AND_LENGTH"} HandleMirroring */

/** @typedef {(
    'NONE' | 'LINE_ARROW' | 'TRIANGLE_ARROW_SOLID' | 'TRIANGLE_ARROW_OUTLINE' |
    'CIRCLE_SOLID' | 'CIRCLE_OUTLINE' | 'SQUARE_SOLID' | 'SQUARE_OUTLINE'
)} CapShape */

/** @typedef {('STRAIGHT' | 'CONCAVE' | 'CONVEX' | 'SLANT' | 'NONE')} JoinShape */

/**
 * @typedef {object} VertexData
 * @property {string} id                                   id of the vertex
 * @property {Vector2} pos                                 position of the vertex (object space)
 * @property {undefined | number} [cornerRadius=null]      corner radius (if null, element's property is used)
 * @property {undefined | CapShape} [cap=null]             stroke cap type (if null, element's property is used)
 * @property {undefined | JoinShape} [join=null]           stroke join type (if null, element's property is used)
 * @property {undefined | HandleMirroring} [mirror='NONE'] how curve handles behave relative to one another (if null, element's proprety is used)
 * @property {string} type                                 is always 'Vertex'
 */
