import { Screen } from '@phase-software/data-store/src/Screen'
import Stats from '@phase-software/data-utils/src/Stats'
// import * as Sentry from '@sentry/react'
import IS from './input-system'
import { VisualServer } from './visual_server/VisualServer'
import { initPanes, updatePanes } from './panes'
import { Vector2, Color } from './math'
import * as actions from './actions'
import {
    findTopMostElementAt,
    clearScreenNameHitTest,
    updateHitTest,
    addScreenNameToHitTest,
    updateScreenNameHitTest
} from './actions/selection'
import {
    clearAllControllers
} from './update-controller'
import { takeSnapshotAsBlob } from './utils/snapshot'
import Status from './status'
import { MP4Encoder } from './actions/export_media/mp4'
import { GIFEncoder } from './actions/export_media/gif'
import { init as initDino, dino, getNodeDino } from './dino'
import { createDinoSubTree } from './utils/node'
import { RootTree } from './visual_server/RenderItem'

/** @typedef {import('@phase-software/data-store/src/Workspace').Workspace} Workspace */
/** @typedef {import('@phase-software/data-store/src/DataStore').DataStore} DataStore */
/** @typedef {import('./visual_server/RenderItem').RenderItem} RenderItem */
/** @typedef {import('@phase-software/data-store/src/layer/ComputedLayer').ComputedLayer} ComputedLayer */
/** @typedef {import('@phase-software/data-store/src/Element').Element} Element */
/** @typedef {import('./overlay/Overlay').Overlay} Overlay */
/** @typedef {import('./visual_server/VisualStorage').VisualStorage} VisualStorage */

let frameCounter = 0
// Disable Sentry snapshot integration for now
// const sentryCPF = 500 // capture the canvas once every 500 frames
let visible = false


let dataStore = null
let canvas = null

/**
 * There will be only one instance of `VisualServer` during
 * the whole app life time
 * @type {VisualServer}
 */
let VS = null
/**
 * @param {HTMLCanvasElement} canvas
 * @param {DataStore} dataStore
 * @param {boolean} useLowDPR
 */
export async function init(c, ds) {
    dataStore = ds
    canvas = c
    await initDino(c)
}

function loadStart() {
    Status.pause()
}

async function load() {
    IS.pause()

    await VS.dataStore.sync()

    IS.resume()

    Status.updateRootDirty(true)
    updateWorkspaceBackground()

    Status.resume()

    visible = true

    window.dispatchEvent(new Event("resize"))
    dino().resumeApp()
}

export function resize() {
    Status.updateResizeDirty(true)
}

/**
 * Moves viewport to center on selection
 */
export function centerSelection() {
    actions.centerSelection()
}

/**
 * @param {number} x
 * @param {number} y
 * @returns {Vector2}
 */
export const toWorld = (x, y) => {
    if (VS) {
        const v = new Vector2(x, y)
        const w = VS.viewport.toWorld(v)
        return w
    } else {
        return new Vector2(x, y)
    }
}

export function cleanupRenderer() {
    clearScreenNameHitTest()
    clearAllControllers()
    if (VS) VS.clear()

    const api = dino()
    api.purge()

    Status.pause()

    visible = false

    api.pauseApp()
}


/* Implementation */

window.__ph__ = {
    // Dino might not be ready yet, so add a onInited callback
    onReady: () => {
        console.log('onReady')

        VS = new VisualServer(canvas, dataStore, false)
        // canvas bg color is 0x0c0c0c
        dino().setBackgroundColor(12/255, 12/255, 12/255, 1)

        initPanes(VS)

        IS.watch(canvas)
        actions.init(VS)

        initRootListeners()

        VS.indexer.connectDataStore(dataStore)
        VS.selection.watchDSSelection()

        load()
    },
    onFrameBegin: () => {
        // TOFIX: this is a hack to fix the cursor issue
        // better solution might be to handle cursor in editor.zig (dino)
        canvas.style.cursor = 'inherit'

        checkRendererReadyState()
        // if (frameCounter % sentryCPF === 0) {
        //     const canvasRef = document.querySelector("#renderer-canvas")
        //     const client = Sentry.getClient()
        //     if (client) {
        //         client.getIntegrationByName("ReplayCanvas")
        //             .snapshot(canvasRef)
        //     }
        // }
        frameCounter++
    },
    onUpdateSceneTree: () => {
        if (Status.paused) return null

        const focusContent = setRoot()

        Stats.begin("node update")
        const updateList = [...VS.updateList.values()]
        VS.indexer.updateNodes(updateList)
        VS.updateList.clear()
        Stats.end("node update")

        if (VS.dataStore.isTablingState) {
            updateTable()
        }

        Stats.begin("hit test")
        updateHitTest()
        Stats.end("hit test")

        Stats.begin("misc update")
        VS.selection.updateBounds()

        if (focusContent) focusContent()
    },
    onPrepareCamera: () => {
        if (!Status.paused && visible) {
            VS.viewport.resize()
            const { x, y, scale } = VS.viewport
            dino().setCamera(-x, -y, scale)

            actions.update()
            VS.storage.update()
            Stats.end("misc update")
            return true
        } else {
            Stats.end("misc update")
            return false
        }
    },
    onOverlay: () => {
        if (Status.paused || !visible) return null

        if (VS.dataStore.isEditingState) {
            const node = VS.indexer.getScreenNode()
            addScreenNameToHitTest(node)
            updateScreenNameHitTest()
        }
        updatePanes()
    },
    onFrameEnd: () => {
        // console.log(`${dino().numNodesRender()} nodes, ${dino().numTilesRender()} tiles rendered`)
    },
    onCapture: (ptr, len) => {
        dino().pixels = new Uint8Array(window.Module.HEAPU8.buffer, ptr, len)
    }
}

let prevIdx = null
export const CELL_PADDING = 60
// TODO: different cell size switch support
// let prevBbox = null
function updateTable() {
    if (!VS.dataStore.isTablingState) {
        prevIdx = null
        // prevBbox = null
        return
    }
    const elId = VS.dataStore.selection.get('activeTableElement')
    if (!elId) return

    const node = VS.indexer.getNode(elId)
    if (!node) return

    const api = dino()
    const { idx } = getNodeDino(node.item)
    if (idx === -1) {
        VS.indexer.versions.bbox = node.boundsLocal.clone()
    }
    const padding = CELL_PADDING
    // TODO: Need to handle mask/clip
    for (let i = 0; i < VS.indexer.versions.children.length; i++) {
        api.setNodeTransform(
            VS.indexer.versions.children[i],
            0,  (i - idx - 1) * (VS.indexer.versions.bbox.height + 2 * padding), 0, 1, 1, 0, 0
        )
    }

    if (prevIdx !== idx) {
        if (prevIdx !== null) {
            const offset = VS.viewport.projectionTransform.xform_vec(new Vector2(0, 0 + (idx - prevIdx) * (VS.indexer.versions.bbox.height + 2 * padding)))
            VS.viewport.offsetPos(offset)
        }
        prevIdx = idx
        // prevBbox = VS.indexer.versions.bbox
    }

    VS.indexer.root = VS.indexer.getNode(VS.dataStore.selection.get('activeTableElement'))
    VS.indexer.updateTransformRecursively(VS.indexer.root, true, true)
}

function checkRendererReadyState() {
    if (frameCounter === 10) {
        // create a div with ready state on a page
        const g = document.createElement('div')
        g.setAttribute('data-test-id', 'rendererReady')
        document.getElementById('modal').appendChild(g)
    }
}

/**
 * @param {string} id
 * @param {string[]} actions
 * @returns
 */
const createActionNodes = (id, actions) => {
    const node = VS.indexer.nodeMap.get(id)
    for (let i = 0; i < actions.length; i++) {
        if (node.item.dino_actions[i]) continue

        node.item.dino_actions[i] = new RootTree()
        createDinoSubTree(node.item.type, node.item.dino_actions[i])
    }
}

function initRootListeners() {
    /** @type {import('@phase-software/data-store/src/DataStore').DataStore} */
    const dataStore = VS.dataStore
    const { viewport } = VS

    dataStore.on('LOAD-START', loadStart)
    dataStore.on('LOAD', load)

    const api = dino()
    dataStore.on('GO_TABLE', () => {
        // console.log('GO_TABLE')
        const activeElId = dataStore.selection.get('activeTableElement')
        const actionList = dataStore.interaction.getActionList(activeElId)

        // versions holder
        VS.indexer.versions.id = api.makeNode(api.GROUP)

        for (const elId of dataStore.traverseSubtree(activeElId, true, true)) {
            createActionNodes(elId, actionList)
            const node = VS.indexer.getNode(elId)
            if (elId === activeElId) continue

            const el = dataStore.getElement(elId)
            const parent = el.get('parent')
            const parentNode = VS.indexer.getNode(parent.get('id'))
            // link actions
            for (let i = 0; i < node.item.dino_actions.length; i++) {
                api.addNodeChild(parentNode.item.dino_actions[i].children.id, node.item.dino_actions[i].id)
            }
        }

        VS.indexer.versions.children = []
        const node = VS.indexer.getNode(activeElId)
        // move node design to versions holder
        const wrapper = api.makeNode(api.GROUP)
        api.addNodeChild(wrapper, node.item.dino.id)
        api.addNodeChild(VS.indexer.versions.id, wrapper)
        VS.indexer.versions.children.push(wrapper)
        // link actions to versions holder
        for (let i = 0; i < node.item.dino_actions.length; i++) {
            const wrapper = api.makeNode(api.GROUP)
            api.addNodeChild(wrapper, node.item.dino_actions[i].id)
            api.addNodeChild(VS.indexer.versions.id, wrapper)
            VS.indexer.versions.children.push(wrapper)
        }

        // link versions to root
        dino().addNodeChild(VS.indexer.root.item.dino.id, VS.indexer.versions.id)
        // hide root children
        api.setNodeVisible(VS.indexer.root.item.dino.children.id, false)
    })

    dataStore.on('ACTION_LIST_CHANGE', () => {
        const activeElId = dataStore.selection.get('activeTableElement')
        const actionList = dataStore.interaction.getActionList(activeElId)

        for (const elId of dataStore.traverseSubtree(activeElId, true, true)) {
            createActionNodes(elId, actionList)
            const node = VS.indexer.getNode(elId)
            if (elId === activeElId) continue

            const el = dataStore.getElement(elId)
            const parent = el.get('parent')
            const parentNode = VS.indexer.getNode(parent.get('id'))
            // link actions
            for (let i = 0; i < node.item.dino_actions.length; i++) {
                api.addNodeChild(parentNode.item.dino_actions[i].children.id, node.item.dino_actions[i].id)
            }
        }

        const node = VS.indexer.getNode(activeElId)
        // link actions to versions holder
        for (let i = 0; i < node.item.dino_actions.length; i++) {
            if (VS.indexer.versions.children[i + 1]) continue

            const wrapper = api.makeNode(api.GROUP)
            api.addNodeChild(wrapper, node.item.dino_actions[i].id)
            api.addNodeChild(VS.indexer.versions.id, wrapper)
            VS.indexer.versions.children.push(wrapper)
        }
    })

    dataStore.on('LEAVE_TABLE', () => {
        api.setNodeVisible(VS.indexer.root.item.dino.children.id, true)
    })

    const setDirtyIfInPreview = () => {
        if (dataStore.get('state') === 'PREVIEW') {
            Status.updateRootDirty(true)
        }
    }

    dataStore.on('preview', setDirtyIfInPreview)
    dataStore.on('previewZoom', setDirtyIfInPreview)
    viewport.on('resize', setDirtyIfInPreview) // because we need to refocus the content on resize

    dataStore.workspace.on('CHANGE-WATCH', () => {
        Status.updateRootDirty(true)
    })

    dataStore.workspace.on('CHANGE-WATCH', updateWorkspaceBackground)

    // listen to zoom changes
    dataStore.workspace.on('scale', scale => {
        viewport.setZoom(scale)
    })

    // set workspace viewport zoom and position
    viewport.on('update', () => {
        dataStore.workspace.sets({
            scale: viewport.scale,
            panX: viewport.x,
            panY: viewport.y
        })
    })
}

function updateWorkspaceBackground() {
    const isDark = !!VS.dataStore.workspace.children.find(c => c instanceof Screen)
    // if workspace contains Screens, background needs to be dark
    const bgColor = Color.hex(isDark ? 0x0D0C0C : 0xFFFFFF)
    VS.setBackgroundColor(bgColor)
}

/**
 * @returns {() => void} focusContent function
 */
function setRoot() {
    if (!Status.dirty.root) return
    Status.updateRootDirty(false)
    // force a resize check before focusing content
    Status.updateResizeDirty(true)

    /** @type {import('@phase-software/data-store/src/DataStore').State} */
    const state = VS.dataStore.get('state')
    switch (state) {
        case 'PREVIEW': {
            const root = VS.dataStore.get('preview').get('id')
            VS.setRoot(root)

            return () => actions.focusPreviewContent()
        }

        case 'EDITING':
        case 'VIEWING':
        case 'VERSIONING': {
            const prevRoot = VS.root
            const root = VS.dataStore.workspace.get('id')
            VS.setRoot(root)
            // if it is not first time setting root, then we will not focus content
            if (prevRoot) return
            return () => actions.focusContent()
        }
    }
}

export {
    IS,
    findTopMostElementAt,
    takeSnapshotAsBlob,
    MP4Encoder,
    GIFEncoder
}
