import {
    notNull,
    setAdd,
    id as createId
} from '@phase-software/data-utils'
import { Setter } from '../Setter'

const DEFAULT_ROW = Object.freeze({
    scale: 1,
    suffix: '',
    format: 'png'
})
const UNDO_CHANGES = [
    'name',
    'rows'
]

/**
 * @fires 'CHANGES'
 */
export default class ImagePreset extends Setter {
    /**
     * @param {DataStore} dataStore
     * @param {ImagePresetData} data
     */
    constructor(dataStore, data) {
        super(dataStore, data)

        setAdd(this.undoChanges, UNDO_CHANGES)
    }

    /**
     * Create a new preset with default row
     */
    create() {
        super.create()

        this.data.name = 'ImagePreset'
        this.data.type = 'IMAGE_PRESET'
        this.data.rows = [{
            ...DEFAULT_ROW,
            id: createId()
        }]
    }

    /**
     * Load from imagePreset data
     * @param {object} data
     */
    load(data) {
        super.load(data)

        // if the given row data does not have id, create id for it
        this.data.rows = data.rows.map(row => ({
            ...row,
            id: notNull(row.id) ? row.id : createId()
        }))
    }

    /**
     * Clone the image preset
     * @returns {ImagePreset}
     */
    clone() {
        const obj = super.clone()

        obj.data.rows = this.data.rows.map(row => ({
            ...row,
            id: createId()
        }))
        return obj
    }

    /**
     * Save as image preset data
     * @returns {ImagePresetData}
     */
    save() {
        const data = super.save()

        data.rows = this.data.rows
        return data
    }

    /**
     * Add the new row based on the last one in the rows by:
     * - Increase the scale by 1
     * - Set the suffix from the new scale
     * - Keep the same format from the last row
     */
    addRow() {
        const rows = this.get('rows')
        const lastRow = rows[rows.length - 1]

        this.set('rows', [...rows, {
            id: createId(),
            scale: lastRow.scale + 1,
            suffix: `@${lastRow.scale + 1}x`,
            format: lastRow.format       
        }])
    }

    /**
     * Remove the row
     * @param {string} rowId
     */
    removeRow(rowId) {
        const rows = this.get('rows')

        this.set('rows', rows.filter(row => row.id !== rowId))
    }

    /**
     * update the row by give prop name and value
     * @param {string} rowId
     * @param {string} prop
     * @param {string} value
     */
    updateRow(rowId, prop, value) {
        const rows = this.get('rows')
        const row = rows.find(row => row.id === rowId)

        if (!row || DEFAULT_ROW[prop] === undefined) return
        row[prop] = value
        this.set('rows', [...rows])
    }
}

/** @typedef {import('../Setter').SetterData} SetterData */

/**
 * @typedef {SetterData} ImagePresetData
 * @property {object[]} rows
 */
