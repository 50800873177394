import { Vector2 } from './Vector2'

/**
 * Get point of a line at specific segment t
 * @param {number} t
 * @param {number} fromX
 * @param {number} fromY
 * @param {number} toX
 * @param {number} toY
 * @returns {[number, number]}
 */
export const getLineP = (t, fromX, fromY, toX, toY) => {
    return [
        t * (toX - fromX) + fromX,
        t * (toY - fromY) + fromY
    ]
}

/**
 * @param {number} t
 * @param {number} fromX
 * @param {number} fromY
 * @param {number} cpX
 * @param {number} cpY
 * @param {number} toX
 * @param {number} toY
 * @returns {[number, number]}
 */
export const getQuadraticP = (t, fromX, fromY, cpX, cpY, toX, toY) => {
    const dt = 1 - t
    const a = dt ** 2
    const b = 2 * t * dt
    const c = t ** 2
    return [
        a * fromX + b * cpX + c * toX,
        a * fromY + b * cpY + c * toY
    ]
}

/**
 * @param {number} t
 * @param {number} fromX
 * @param {number} fromY
 * @param {number} cp0X
 * @param {number} cp0Y
 * @param {number} cp1X
 * @param {number} cp1Y
 * @param {number} toX
 * @param {number} toY
 * @returns {[number, number]}
 */
export const getCubicP = (t, fromX, fromY, cp0X, cp0Y, cp1X, cp1Y, toX, toY) => {
    const dt = 1 - t
    const a = dt ** 3
    const b = 3 * t * dt ** 2
    const c = 3 * dt * t ** 2
    const d = t ** 3
    return [
        a * fromX + b * cp0X + c * cp1X + d * toX,
        a * fromY + b * cp0Y + c * cp1Y + d * toY
    ]
}


export const getPFn = (t, s, c0, c1, e) => {
    let result
    if (c0 && c1) {
        result = getCubicP(t, s.x, s.y, c0.x, c0.y, c1.x, c1.y, e.x, e.y)
    } else if (c0 || c1) {
        const c = c0 || c1
        result = getQuadraticP(t, s.x, s.y, c.x, c.y, e.x, e.y)
    } else {
        result = getLineP(t, s.x, s.y, e.x, e.y)
    }
    return new Vector2(result[0], result[1])
}

export const getBezierPointFn = (t, s, c0, c1, e) => {
    let result
    if (c0 && c1) {
        result = getCubicP(t, s[0], s[1], c0[0], c0[1], c1[0], c1[1], e[0], e[1])
    } else if (c0 || c1) {
        const c = c0 || c1
        result = getQuadraticP(t, s[0], s[1], c[0], c[1], e[0], e[1])
    } else {
        result = getLineP(t, s[0], s[1], e[0], e[1])
    }
    return result
}

