import { Num } from "../math"

/**
 * @param {number} zoom
 * @returns {number}
 */
export function ScaleLevelOfZoom(zoom) {
    if (zoom > 1) {
        return Math.log2(Num.NearestPowerOf2(Math.ceil(zoom)))
    } else if (zoom < 1) {
        return -Math.log2(Num.NearestPowerOf2(Math.ceil(1 / zoom)))
    }
    return 0
}
