import { useCallback } from 'react'
import useEditorImage from '../../../hooks/useEditorImage'

const useImageDownloader = () => {
  const { getImageUrl } = useEditorImage()

  const downloadImage = useCallback(
    async (imageId: string) => {
      try {
        const imageUrl = imageId ? getImageUrl(imageId) : undefined
        if (!imageUrl) {
          throw new Error('Image URL is undefined')
        }
        const response = await fetch(imageUrl)
        const blob = await response.blob()
        const downloadUrl = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = downloadUrl
        link.download = `${imageId}.png`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        window.URL.revokeObjectURL(downloadUrl)
      } catch (error) {
        console.error('Download failed:', error)
      }
    },
    [getImageUrl]
  )

  return downloadImage
}

export default useImageDownloader
