import React from 'react'
import { useTranslation } from 'react-i18next'

import { MIX_VALUE } from '@phase-software/data-utils'
import { ElementType } from '@phase-software/types'

import { useEditor } from '../../../../providers/dataStore/EditorProvider'
import WideTitle from '../../../shared/WideTitle'

const ElementTypeNameMap: Partial<Record<ElementType, string>> = {
  [ElementType.SCREEN]: 'screen'
}

const mixTypeName = 'mix'

const getElementTypeName = (elementType: ElementType): string => {
  let name = ElementTypeNameMap[elementType]
  if (elementType === MIX_VALUE) {
    name = mixTypeName
  }

  return name || ''
}

const NormalHeader = () => {
  const elementType = useEditor((o) => o.elementType)
  const { t } = useTranslation('file', { keyPrefix: 'element_type' })
  const name: string = t(getElementTypeName(elementType))

  return <WideTitle dividerPosition="top">{name}</WideTitle>
}

export default React.memo(NormalHeader)
