import { useEffect, memo } from 'react'
import PropTypes from 'prop-types'
import { useApolloClient } from '@apollo/client'

import { useUI } from '../../providers/dataStore/UIProvider'
import { useSetTutorial, getTutorialSnapshot } from '../../providers/TutorialProvider'
import { TutorialType } from '../../tutorials'
import { FileFieldsFragmentDoc } from '../../generated/graphql'

const SubscribeRendererReady = ({ fileId }: { fileId: string }) => {
  const client = useApolloClient()
  const file = client.readFragment({
    fragment: FileFieldsFragmentDoc,
    fragmentName: 'fileFields',
    id: `files:${fileId}`
  })

  const isDemo = file?.props?.demo
  const hasRendererInit = useUI((o) => o.hasRendererInit)
  const { startTutorial } = useSetTutorial()
  useEffect(() => {
    if (hasRendererInit) {
      const state = getTutorialSnapshot()
      const generalTutorialViewed = state?.status ? !!(state.status as any)[TutorialType.GENERAL_INTERFACE] : false
      if (generalTutorialViewed && isDemo) {
        startTutorial(TutorialType.COMPLETE_INTERFACE)
      } else {
        // startTutorial(TutorialType.GENERAL_INTERFACE)
      }
    }
  }, [hasRendererInit, isDemo, startTutorial])
  return null
}

SubscribeRendererReady.propTypes = {
  fileId: PropTypes.string
}

export default memo(SubscribeRendererReady)
