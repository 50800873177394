import { optimize } from 'svgo';
import { createJSON, deg2Rad, getGroups, getIndex, getNodes, getSVGDOMRect, handleBlendMode, handleText, isPatternImage, liftAttributesToGroup, shapeQuery, shouldFillBeAdded, string2Nodes, useId, } from './utils';
import { handlePatternImage, handleSiblings } from './utils/elementHandlers';
import handleAttributes from './utils/handleAttributes';
import { addEffect, addFill, addLayer, addMask, addRepeater, addShape, addStroke, addStyle, addTransform, } from './utils/populateJSON';
import { MaskType, ShapeType, } from './utils/enums';
export function svg2Lottie(svg, name) {
    if (process.env.NODE_ENV === 'development') {
        console.clear();
    }
    const optimizedSVG = string2Nodes(optimize(svg.outerHTML, {
        multipass: true,
        plugins: [{
                name: 'preset-default',
                params: {
                    overrides: {
                        cleanupIds: false,
                        convertTransform: {
                            convertToShorts: false
                        },
                        inlineStyles: {
                            onlyMatchedOnce: false
                        },
                        removeViewBox: false,
                    }
                }
            }]
    }).data), nm = name || optimizedSVG.id || useId('lottie'), svgDOMRect = getSVGDOMRect(optimizedSVG), layers = [], assets = [], imageAssets = [], fontList = [], { atts, groups, maskElements, rootSVG } = getGroups(optimizedSVG);
    let groupIndex = 0;
    for (const group of groups.reverse()) {
        const shapeGroups = [], maskData = [], siblings = [], imageStrokeGroups = [], groupAttributes = handleAttributes({
            el: group,
            root: rootSVG,
            ancestorAtts: atts,
            maskElements,
            dimensions: svgDOMRect
        });
        if (groupAttributes.type === 2 && groupAttributes.id) {
            const image = group.querySelector('image');
            if (image) {
                image.id = groupAttributes.id;
                const { transform: imageTransform } = handleAttributes({ el: image });
                groupAttributes.transform = imageTransform;
                groupAttributes.transform.position[0] += imageTransform.translate[0];
                groupAttributes.transform.position[1] += imageTransform.translate[1];
                imageAssets.push({
                    image,
                });
            }
        }
        const { nodes } = getNodes({ group });
        if (groupAttributes.backgroundColor) {
            console.warn('TODO: Background color');
        }
        if (!nodes.length) {
            continue;
        }
        let nodeIndex = 0;
        for (const node of nodes.reverse()) {
            const nodeAttributes = handleAttributes({
                el: node,
                root: rootSVG,
                dimensions: svgDOMRect,
                ancestorAtts: atts,
                maskElements,
                groupAttributes
            }), isText = handleText({
                fontList,
                groupAttributes,
                node,
                root: rootSVG
            });
            liftAttributesToGroup({
                maskElements,
                node,
                nodeAttributes,
                groupAttributes,
                rootSVG
            });
            if (isText) {
                continue;
            }
            shapeGroups.push({
                ty: ShapeType.Group,
                it: [],
                nm: `Group ${nodeIndex + 1}, Layer ${groupIndex + 1}`,
                np: 0,
                bm: handleBlendMode(nodeAttributes.blendMode),
                mn: 'Vector Group',
                hd: false,
            });
            if (nodeAttributes.pattern.image.element) {
                const imageAttributes = handlePatternImage({
                    pattern: nodeAttributes.pattern,
                    images: imageAssets,
                    groupAttributes,
                    node: {
                        el: node,
                        blendMode: nodeAttributes.blendMode,
                        fillOpacity: nodeAttributes.fill.opacity,
                        transform: nodeAttributes.transform,
                        attributes: [...node.attributes],
                    }
                });
                if (nodeAttributes.stroke.color) {
                    groupAttributes.stroke = nodeAttributes.stroke;
                }
                groupAttributes.blendMode = imageAttributes.blendMode;
                groupAttributes.id = imageAttributes.id;
                groupAttributes.opacity = imageAttributes.opacity;
                groupAttributes.transform = imageAttributes.transform;
                groupAttributes.type = imageAttributes.type;
                for (const [siblingIndex, sibling] of Array.from(group.querySelectorAll(shapeQuery())).entries()) {
                    if (sibling === node
                        || sibling === node.parentElement
                        || !(sibling instanceof SVGElement)
                        || sibling.parentElement instanceof SVGMaskElement
                        || sibling.parentElement instanceof SVGClipPathElement
                        || isPatternImage(sibling)) {
                        continue;
                    }
                    const duplicate = nodes.find(n => n === sibling);
                    if (duplicate) {
                        nodes.splice(nodes.indexOf(duplicate), 1);
                    }
                    siblings.push({
                        el: sibling,
                        isPrev: siblingIndex < getIndex(node) || (!!sibling.dataset.hasPrev && sibling.dataset.hasPrev !== 'true')
                    });
                }
            }
            if (nodeAttributes.pattern.type && !nodeAttributes.pattern.image.element) {
                for (const p of nodeAttributes.pattern.data) {
                    addShape({
                        index: nodeIndex,
                        shapeGroup: shapeGroups[nodeIndex],
                        type: ShapeType.Path,
                        shapeData: [p],
                    });
                }
                const patternWidth = nodeAttributes.pattern.transform.width ?? 512, containerWidth = nodeAttributes.pattern.containerTransform.width ?? 512, repeatX = Math.ceil(containerWidth / patternWidth);
                addRepeater({
                    index: nodeIndex,
                    shapeGroup: shapeGroups[nodeIndex],
                    type: ShapeType.Repeater,
                    repeat: repeatX,
                    offset: [patternWidth, 0]
                });
            }
            if (!nodeAttributes.pattern.type
                || nodeAttributes.stroke.color) {
                for (const [shapeIndex, shapeData] of nodeAttributes.pathData.entries()) {
                    addShape({
                        index: shapeIndex,
                        shapeGroup: shapeGroups[nodeIndex],
                        shapeData: [shapeData],
                        type: ShapeType.Path
                    });
                }
            }
            if (nodeAttributes.stroke.color) {
                addStroke({
                    gradient: nodeAttributes.gradient,
                    index: nodeIndex,
                    shapeGroup: shapeGroups[nodeIndex],
                    stroke: nodeAttributes.stroke
                });
            }
            if (shouldFillBeAdded({ attributes: nodeAttributes, el: node })) {
                addFill({
                    fill: nodeAttributes.fill,
                    gradient: nodeAttributes.gradient,
                    index: nodeIndex,
                    shapeGroup: shapeGroups[nodeIndex],
                });
            }
            if (groupAttributes.type !== 2) {
                addTransform({
                    index: nodeIndex,
                    shapeGroup: shapeGroups[nodeIndex],
                    transform: nodeAttributes.transform,
                });
            }
            nodeIndex++;
        }
        const ef = [], activeEffects = [];
        if (groupAttributes.shadow.length) {
            for (const _a of groupAttributes.shadow) {
                activeEffects.push({
                    type: 25,
                    np: 5,
                });
            }
        }
        if (groupAttributes.blur.length) {
            for (const _b of groupAttributes.blur) {
                activeEffects.push({
                    type: 29,
                    np: 3,
                });
            }
        }
        for (const effect of activeEffects) {
            addEffect({
                attributes: groupAttributes,
                effect,
                effects: ef
            });
        }
        const styles = [];
        addStyle({
            attributes: groupAttributes,
            index: groupIndex,
            styles
        });
        if (groupAttributes.mask.type === MaskType.Mask) {
            for (const maskNode of groupAttributes.mask.node) {
                addMask({
                    attributes: groupAttributes,
                    dimensions: svgDOMRect,
                    index: groupIndex,
                    maskData,
                    shapeData: maskNode.data
                });
            }
        }
        if (groupAttributes.mask.type === MaskType.Matte) {
            const matteGroups = [];
            if (groupAttributes.transform.skew.length && groupAttributes.transform.width && groupAttributes.transform.height) {
                const scaledWidth = groupAttributes.transform.width * (groupAttributes.transform.scale[0] / 100), scaledHeight = groupAttributes.transform.height * (groupAttributes.transform.scale[1] / 100);
                groupAttributes.mask.transform.position[0] += (scaledWidth * deg2Rad(groupAttributes.transform.skew.length)) * 2;
                groupAttributes.mask.transform.position[1] += scaledHeight / 3;
            }
            if (imageAssets.find(({ image }) => image.id === groupAttributes.id)) {
                groupAttributes.mask.matteMode = 1;
            }
            for (const [matteIndex, matteNode] of groupAttributes.mask.node.entries()) {
                matteGroups.push({
                    ty: ShapeType.Group,
                    it: [],
                    nm: `Layer ${groupIndex + 1} Matte Shape Group`,
                    np: 0,
                    bm: 0,
                    mn: 'Matte Shape Group',
                    hd: false,
                });
                addShape({
                    index: matteIndex,
                    shapeGroup: matteGroups[matteIndex],
                    type: ShapeType.Path,
                    shapeData: matteNode.data
                });
                if (matteNode.stroke?.color) {
                    addStroke({
                        gradient: matteNode.gradient || groupAttributes.gradient,
                        index: groupIndex,
                        shapeGroup: matteGroups[matteIndex],
                        stroke: matteNode.stroke
                    });
                }
                if (matteNode.fill?.color) {
                    addFill({
                        fill: matteNode.fill,
                        gradient: matteNode.gradient || groupAttributes.gradient,
                        index: groupIndex,
                        shapeGroup: matteGroups[matteIndex],
                    });
                }
                addTransform({
                    index: groupIndex,
                    shapeGroup: matteGroups[matteIndex],
                    transform: {
                        anchorPoint: groupAttributes.mask.transform.anchorPoint,
                        height: null,
                        position: groupAttributes.mask.transform.position,
                        translate: groupAttributes.mask.transform.translate,
                        rotation: groupAttributes.mask.transform.rotation,
                        scale: groupAttributes.mask.transform.scale,
                        skew: groupAttributes.mask.transform.skew,
                        width: null
                    },
                });
            }
            addLayer({
                attributes: groupAttributes,
                index: groupIndex,
                layers,
                masksProperties: maskData,
                shapes: matteGroups,
                isMatte: true,
                svgDOMRect
            });
            groupIndex++;
        }
        if (groupAttributes.stroke.color && groupAttributes.type === 2) {
            imageStrokeGroups.push({
                ty: ShapeType.Group,
                it: [],
                nm: `Stroke Group ${nodeIndex + 1}, Layer ${groupIndex + 1}`,
                np: 0,
                bm: handleBlendMode(groupAttributes.blendMode),
                mn: 'Vector Group',
                hd: false,
            });
            for (const p of groupAttributes.stroke.data) {
                addShape({
                    index: nodeIndex,
                    shapeGroup: imageStrokeGroups[0],
                    type: ShapeType.Path,
                    shapeData: [p],
                });
            }
            addStroke({
                gradient: groupAttributes.gradient,
                index: nodeIndex,
                shapeGroup: imageStrokeGroups[0],
                stroke: groupAttributes.stroke
            });
            const adjustedScale = [
                1 / (groupAttributes.transform.scale[0] / 100),
                1 / (groupAttributes.transform.scale[1] / 100)
            ];
            addTransform({
                index: nodeIndex,
                shapeGroup: imageStrokeGroups[0],
                transform: {
                    anchorPoint: groupAttributes.transform.position,
                    height: null,
                    position: [0, 0],
                    translate: [0, 0],
                    rotation: 0,
                    scale: [
                        adjustedScale[0] * 100,
                        adjustedScale[1] * 100
                    ],
                    skew: {
                        angle: 0,
                        length: 0
                    },
                    width: null
                },
            });
            addLayer({
                attributes: groupAttributes,
                index: groupIndex,
                layers,
                shapes: imageStrokeGroups,
                isStroke: true,
                svgDOMRect
            });
            groupIndex++;
        }
        if (siblings.length) {
            const nextSiblings = siblings.filter(el => !el.isPrev).map(({ el }) => el), it = handleSiblings({
                ancestorAtts: atts,
                svgDOMRect,
                group,
                groupIndex,
                images: imageAssets,
                layers,
                maskElements,
                root: rootSVG,
                siblings: nextSiblings,
                isPrev: false
            });
            groupIndex += it;
        }
        let matteRef = groupIndex - 1;
        if (siblings.length && siblings.some(el => !el.isPrev)) {
            matteRef = groupIndex - 2;
        }
        addLayer({
            attributes: groupAttributes,
            index: groupIndex,
            layers,
            masksProperties: maskData,
            shapes: shapeGroups,
            effects: ef,
            styles,
            matteRef,
            svgDOMRect
        });
        groupIndex++;
        if (siblings.length) {
            const prevSiblings = siblings.filter(el => el.isPrev).map(({ el }) => el), it = handleSiblings({
                ancestorAtts: atts,
                svgDOMRect,
                group,
                groupIndex,
                images: imageAssets,
                layers,
                maskElements,
                root: rootSVG,
                siblings: prevSiblings,
                isPrev: true
            });
            groupIndex += it;
        }
    }
    for (const { image } of imageAssets) {
        if (!image.href) {
            continue;
        }
        assets.push({
            id: image.id,
            u: image.href.baseVal.startsWith('data:image') ? '' : image.href.baseVal,
            p: image.href.baseVal?.startsWith('data:image') ? image.href.baseVal : '',
            e: image.href.baseVal?.startsWith('data:image') ? 1 : 0,
            h: image.height.baseVal.value || 512,
            w: image.width.baseVal.value || 512
        });
    }
    return {
        $schema: 'https://lottiefiles.github.io/lottie-docs/schema/lottie.schema.json',
        v: '5.7.1',
        meta: {
            g: 'svg2Lottie',
            a: '',
            k: '',
            d: '',
            tc: '',
        },
        fr: 60,
        ip: 0,
        op: 60,
        w: Math.round(svgDOMRect.width),
        h: Math.round(svgDOMRect.height),
        mn: '',
        nm,
        ddd: 0,
        ao: 0,
        assets,
        fonts: {
            list: fontList
        },
        layers,
        markers: []
    };
}
export function createLottie(svg, fileName) {
    createJSON({
        animation: svg2Lottie(svg),
        fileName,
        shouldDownload: true
    });
}
globalThis.createLottie = createLottie;
globalThis.svg2Lottie = svg2Lottie;
