import React from 'react'
import { useTranslation } from 'react-i18next'

import { Select } from '../shared'
import Field from './Field'
import LOCATION_LIST from './location.json'

type LocationFieldProps = {
  value: string
  onChange: (value: string) => void
  size?: 'normal' | 'full'
}

const LocationField = ({ value, onChange, ...rest }: LocationFieldProps) => {
  const { t } = useTranslation()
  return (
    <Field label={t('setting:team_setting.country')} {...rest}>
      <Select
        data-test-id="location-input-edit"
        // @ts-ignore TODO: fix after refactor of Select
        showSearch
        placeholder={t('setting:team_setting.select_country')}
        value={value}
        onChange={onChange}
        options={LOCATION_LIST}
        size="l"
        variant="normal"
        caret
      />
    </Field>
  )
}
export default LocationField
