import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IfPermissionGranted, PERMISSIONS, useAccessControl } from '../../access-control'
import InviteTeamMemberDialog from '../../components/modals/InviteTeamMemberDialog'
import { Button, TabList } from '../../components/shared'
import { TeamMembersLoading } from '../../components/shared/Skeleton'
import { useOnTeamUserInvitationUpdatedSubscription, useOnTeamUserUpdatedSubscription } from '../../generated/graphql'
import useHeapAnalytics from '../../hooks/useHeapAnalytics'
import { useWorkspaceContext } from '../../providers/WorkspaceContextProvider'
import { track } from '../../services/heapAnalytics'
import TeamMembersInvitations from './TeamMembersInvitations'
import TeamMembersUsers from './TeamMembersUsers'

const TeamMembers = () => {
  const { t } = useTranslation('setting')
  const [tabActiveIndex, setTabActiveIndex] = useState(0)
  const [inviteMemberDialogOpen, setInviteMemberDialogOpen] = useState(false)

  const { workspaceData, isWorkspaceDataLoading } = useWorkspaceContext()
  const { userHasPermission } = useAccessControl()
  const { teamName } = useHeapAnalytics()

  const canInviteTeamMember = userHasPermission(PERMISSIONS.INVITE_TEAM_MEMBER)
  const canSeeInvitation = userHasPermission(PERMISSIONS.VIEW_TEAM_MEMBER_INVITATION)

  const listWrapperTop = canSeeInvitation ? 'mt-[160px]' : 'mt-[104px]'

  const { data: teamUsers, loading: isTeamUsersLoading } = useOnTeamUserUpdatedSubscription({
    variables: { slug: workspaceData.slug }
  })

  const { data: teamUserInvitations, loading: isTeamUserInvitationsLoading } =
    useOnTeamUserInvitationUpdatedSubscription({
      variables: { slug: workspaceData.slug },
      skip: !canInviteTeamMember
    })

  const tabs = useMemo(
    () => [
      {
        title: t('team_setting.users'),
        tag: teamUsers?.team_users.length
      },
      {
        title: t('team_setting.invitations'),
        tag: teamUserInvitations?.team_user_invitations.length
      }
    ],
    [teamUsers?.team_users.length, teamUserInvitations?.team_user_invitations.length, t]
  )

  if (isTeamUsersLoading || isTeamUserInvitationsLoading || isWorkspaceDataLoading) {
    return <TeamMembersLoading />
  }

  const handleInviteMemberDialog = (open: boolean) => {
    setInviteMemberDialogOpen(open)
    track('Invite To Team Clicked', { teamName })
  }

  const teamUserList = teamUsers?.team_users ?? []
  const teamUserInvitationList = teamUserInvitations?.team_user_invitations ?? []

  return (
    <>
      <div className="p-40 pb-0 fixed w-[680px] bg-neutral-90 z-1 rounded-xl">
        <div className="flex justify-between items-center mb-32">
          <p className="text-h3 font-semibold">{t('team_setting.members')}</p>
          <IfPermissionGranted perform={PERMISSIONS.INVITE_TEAM_MEMBER}>
            <Button size="l" icon="Plus" onClick={() => handleInviteMemberDialog(true)}>
              {t('team_setting.invite')}
            </Button>
            <InviteTeamMemberDialog
              open={inviteMemberDialogOpen}
              onClose={() => handleInviteMemberDialog(false)}
              teamUsers={teamUserList}
              teamUserInvitations={teamUserInvitationList}
            />
          </IfPermissionGranted>
        </div>
        <IfPermissionGranted perform={PERMISSIONS.VIEW_TEAM_MEMBER_INVITATION}>
          <TabList tabs={tabs} activeIdx={tabActiveIndex} onSelect={setTabActiveIndex} />
        </IfPermissionGranted>
      </div>
      <div className={`listWrapper p-40 pt-0 ${listWrapperTop}`}>
        {tabActiveIndex === 0 ? (
          <TeamMembersUsers teamId={workspaceData.id} data={teamUserList} />
        ) : (
          <TeamMembersInvitations data={teamUserInvitationList} teamId={workspaceData.id} />
        )}
      </div>
    </>
  )
}

export default TeamMembers
