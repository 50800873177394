import { EntityType, ChangeType, IDType } from '@phase-software/types'
import { notNull, Change, Undoable, id as createId, loadId } from '@phase-software/data-utils'


/** @typedef {import('../DataStore').DataStore} DataStore */
/** @typedef {import('../DataStore').PropData} PropData */
/** @typedef {import('../Changes').PropData} Changes */

/** @typedef {('px' | 'in' | 'cm' | 'mm' | '%')} Units */

const DEFAULT_OPTIONS = { regenId: false }

export default class PropertyComponent extends Undoable {
    /**
     * @param {DataStore} dataStore
     * @param {Partial<PropertyComponentData>} [data]
     * @param {object} [options] 
     * @param {bool} [options.regenId=false]   if set to true, will generate new ID
     */
    constructor(dataStore, data = {}, { regenId = false } = DEFAULT_OPTIONS) {
        super(dataStore, ChangeType.PROPERTY)
        this.dataStore = dataStore

        const { id, name, appliedTo, componentType } = data
        if (!regenId && notNull(id)) {
            loadId(id, IDType.PROPERTY_COMPONENT)
            this.id = id
        } else {
            this.id = createId(IDType.PROPERTY_COMPONENT)
        }
        this.type = EntityType.PROP_COMPONENT
        this.componentType = componentType || ''
        this.name = notNull(name) ? name : ''
        this.appliedTo = new Map(appliedTo)
    }

    /**
     * @param {string} propName
     * @param {any} value
     */
    updateProp(propName, value) {
        // TODO: need to check if the data is different from the current value
        this.changes.update(propName, new Change({
            before: this[propName],
            after: value
        }))
        this[propName] = value
    }

    /**
     * Undo changes
     * @param {Changes} changes
     */
    undo(changes) {
        changes.forEach(({ before }, key) => {
            this[key] = before
        })
        super.undo(changes)
    }

    /**
     * Redo changes
     * @param {Changes} changes
     */
    redo(changes) {
        changes.forEach(({ after }, key) => {
            this[key] = after
        })
        super.redo(changes)
    }

    /**
     * @param {Partial<PropertyComponentData>} data
     */
    set(data) {
        if (notNull(data.name)) {
            this.updateProp('name', data.name)
        }
    }

    /**
     * Clones this property or applies it to a new element+style+layer if it's shared
     * @param {AppliedRef} [ref]
     * @returns {PropertyComponent}
     */
    clone(ref) {
        // TODO: figure out what to do with this when we have shared components
        // if (this.isShared) {
        //     this.apply(ref)
        //     return this
        // } else {
        
        return this._clone(ref)
    }

    /**
     * Override this in subclasses
     * CALL super._clone() at the top of overriden method
     * @protected
     * @param {AppliedRef} ref
     * @returns {PropertyComponent}
     */
    // eslint-disable-next-line no-unused-vars
    _clone(ref) {
        const obj = new this.constructor(this.dataStore)
        obj.type = this.type
        obj.componentType = this.componentType
        obj.name = this.name
        obj.appliedTo = new Map(this.appliedTo)
        return obj
    }

    /**
     * Create reference key for appliedTo Map
     * @protected
     * @param {AppliedRef} ref
     * @returns {string} refKey
     */
    static createRefKey({ elementId, styleId, layerId }) {
        return `${elementId},${styleId}${layerId ? `,${layerId}` : ''}`
    }

    /**
     * Override this in subclasses if subclass has any inner sharable PropertyComponents
     * CALL super.apply() at the top of overriden method
     * @param {AppliedRef} ref
     */
    apply(ref) {
        const { elementId, styleId, layerId } = ref
        if (elementId && styleId) {
            const refKey = PropertyComponent.createRefKey({ elementId, styleId, layerId })
            this.appliedTo.set(refKey, { elementId, styleId, layerId })
        }
    }

    /**
     * Override this in subclasses if subclass has any inner sharable PropertyComponents
     * CALL super.cancel() at the top of overriden method
     * @param {AppliedRef} ref
     */
    cancel({ elementId, styleId, layerId } = {}) {
        if (this.isShared && elementId && styleId) {
            const refKey = PropertyComponent.createRefKey({ elementId, styleId, layerId })
            this.appliedTo.delete(refKey)
        }
    }

    /**
     * Serializes property
     * @returns {PropData}
     */
    save() {
        // TODO: figure out if a different structure needed for shared comps.
        // if (this.isShared) {
        //     return { refId: this.id }
        // }
        // return { data: this._save() }
        return this._save()
    }

    /**
     * Override this in subclasses
     * CALL super._save() at the top of overriden method
     * @protected
     * @returns {PropertyComponentData} data
     */
    _save() {
        const data = {}
        data.id = this.id
        data.type = this.type
        data.componentType = this.componentType
        data.name = this.name
        data.appliedTo = [...this.appliedTo]
        // data.appliedTo = [...this.appliedTo.values()].map(ref => {
        //     const refData = {
        //         elementId: ref.elementId,
        //         styleId: ref.styleId
        //     }
        //     if (ref.layerId) {
        //         refData.layerId = ref.layerId
        //     }
        //     return refData
        // })
        return data
    }

    /**
     * Forces to serialize property data instead of its refId if shared
     * @returns {PropData}
     */
    forceSave() {
        return { data: this._save() }
    }

    /** @returns {boolean} check is the component is shared one */
    get isShared() {
        return this.appliedTo.size > 0
    }

    getRef(ref) {
        const element = this.dataStore.getById(ref.elementId)
        const style = element.getStyleById(ref.styleId)
        const refObj = { element, style }
        if (ref.layerId) {
            refObj.layer = style.getLayerById(ref.layerId)
        }
        return refObj
    }

    /**
     * Propagate changes to other places this property is used in (applied to)
     * @param  {string} propName  name of the property in Style (or in Layer)
     * @param  {object} data      object with changes made to this property
     * @param  {string} refId     id of Style or Layer that started changes
     */
    propagateChanges(propName, data, refId) {
        if (!this.isShared) {
            return
        }
        // to prevent infinite loops
        if (data.__id__) {
            return
        }
        // set data flag (on first loop)
        data.__id__ = true

        for (const ref of this.appliedTo.values()) {
            // skip the ref that started changes propagation
            if (ref.styleId === refId || ref.layerId === refId) {
                continue
            }

            const { style, layer } = this.getRef(ref)
            if (layer) {
                layer.setProperty(propName, data)
            } else {
                style.setProperty(propName, data)
            }
        }
    }

    /**
     * Set nested component from data
     * @protected
     * @param {PropertyComponent} component
     * @param {Function} componentClass
     * @param {PropretyComponent | Partial<PropertyComponentData>} data
     * @returns {PropertyComponent}         either `component` (if defined) or `data` if `data` is of `componentClass` type
     */
    _setComponent(component, componentClass, data) {
        if (data instanceof componentClass) {
            return data
        }
        component.set(data)
        return component
    }

    clearChanges() {
        this.changes.clear()
    }
}

/**
 * @typedef {object} AppliedRef
 * @property {string} elementId    Element id
 * @property {string} styleId      Style id
 * @property {string} [layerId]    Layer id
 */

/**
 * @typedef {object} PropertyComponentData
 * @property {string} id
 * @property {string} name
 * @property {[string, AppliedRef][]} appliedTo
 */
