import React, { useState, createContext, useContext, useCallback, useMemo, useRef, RefObject } from 'react'
import { CommentType, FilterKeys } from '../components/shared/CommentThread/types'
import { useProfileActions } from './ProfileProvider'
import { useCommentVisibility } from '../hooks/useComment'
import { scrollIntoView } from '../utils/dom'

type selectedThreadProps = {
  id: string
  type: string
}

type filterOptions = {
  [key: string]: boolean
}

type CommentContextType = {
  threadRefs: React.MutableRefObject<ThreadRefs>
  hasUnread: boolean
  setHasUnread: React.Dispatch<React.SetStateAction<boolean>>
  filter: filterOptions
  toggleFilter: (filterKey: FilterKeys) => void
  animatedTagId: string
  setAnimatedTagId: React.Dispatch<React.SetStateAction<string>>
  selectedThread: selectedThreadProps
  selectCanvasCommentThread: (id: string) => void
  selectPanelCommentThread: (id: string) => void
  clearSelectedThread: () => void
  isCanvasCreateCommentOpen: boolean
  openCanvasCreateComment: () => void
  closeCanvasCreateComment: () => void
  editingCommentId: string
  setEditingCommentId: React.Dispatch<React.SetStateAction<string>>
  scrollTargetId: string
  setScrollTargetId: React.Dispatch<React.SetStateAction<string>>
  isCommentVisible: boolean
  setCommentVisibility: (visibility: boolean) => void
  scrollToThread: (threadId: string) => void
}

type CommentProviderProps = {
  fileId: string
  children: React.ReactNode
}

interface ThreadRefs {
  [key: string]: RefObject<HTMLDivElement>
}

const defaultValue = {
  threadRefs: { current: {} },
  hasUnread: false,
  setHasUnread: () => {},
  filter: {
    [FilterKeys.SHOW_RESOLVED]: false
  },
  toggleFilter: () => {},
  animatedTagId: '',
  setAnimatedTagId: () => {},
  selectedThread: {
    id: '',
    type: CommentType.PANEL
  },
  selectCanvasCommentThread: () => {},
  selectPanelCommentThread: () => {},
  clearSelectedThread: () => {},
  isCanvasCreateCommentOpen: false,
  openCanvasCreateComment: () => {},
  closeCanvasCreateComment: () => {},
  editingCommentId: '',
  setEditingCommentId: () => {},
  scrollTargetId: '',
  setScrollTargetId: () => {},
  isCommentVisible: false,
  setCommentVisibility: () => {},
  scrollToThread: () => {}
}

const CommentContext = createContext<CommentContextType>(defaultValue)

const CommentProvider = ({ fileId, children }: CommentProviderProps) => {
  const threadRefs = useRef<ThreadRefs>({})
  const [hasUnread, setHasUnread] = useState(defaultValue.hasUnread)
  const [filter, setFilter] = useState(defaultValue.filter)
  const [selectedThread, setSelectedThread] = useState(defaultValue.selectedThread)
  const [isCanvasCreateCommentOpen, setCanvasCreateCommentOpen] = useState(defaultValue.isCanvasCreateCommentOpen)
  const [editingCommentId, setEditingCommentId] = useState(defaultValue.editingCommentId)
  const [animatedTagId, setAnimatedTagId] = useState('')
  const [scrollTargetId, setScrollTargetId] = useState('')

  const { isCommentVisibleByFile, setIsCommentVisibleByFile } = useCommentVisibility(fileId)
  const { updateCommentVisibility } = useProfileActions()

  const selectCanvasCommentThread = useCallback(
    (id: string) => {
      setEditingCommentId('')
      setCanvasCreateCommentOpen(false)
      setSelectedThread({ id, type: CommentType.CANVAS })
    },
    [setSelectedThread]
  )

  const selectPanelCommentThread = useCallback(
    (id: string) => {
      setEditingCommentId('')
      setCanvasCreateCommentOpen(false)
      setSelectedThread({ id, type: CommentType.PANEL })
    },
    [setSelectedThread]
  )

  const clearSelectedThread = useCallback(() => {
    setSelectedThread(defaultValue.selectedThread)
  }, [setSelectedThread])

  const openCanvasCreateComment = useCallback(() => {
    clearSelectedThread()
    setEditingCommentId('')
    setCanvasCreateCommentOpen(true)
  }, [clearSelectedThread, setCanvasCreateCommentOpen])

  const closeCanvasCreateComment = useCallback(() => {
    setCanvasCreateCommentOpen(false)
  }, [setCanvasCreateCommentOpen])

  const isCommentVisible = useMemo(() => {
    return isCommentVisibleByFile.get(fileId) ?? true
  }, [isCommentVisibleByFile, fileId])

  const setCommentVisibility = useCallback(
    (visibility: boolean) => {
      setIsCommentVisibleByFile((prev) => {
        if (!visibility) {
          clearSelectedThread()
          setCanvasCreateCommentOpen(false)
          setEditingCommentId('')
        }
        const newMap = new Map(prev)
        newMap.set(fileId, visibility)
        updateCommentVisibility(JSON.stringify(Object.fromEntries(newMap)))
        return newMap
      })
    },
    [setIsCommentVisibleByFile, updateCommentVisibility, fileId, clearSelectedThread, setCanvasCreateCommentOpen]
  )

  const toggleFilter = useCallback(
    (filterKey: FilterKeys) => {
      clearSelectedThread()
      setFilter((prev) => {
        return {
          ...prev,
          [filterKey]: !prev[filterKey]
        }
      })
    },
    [setFilter, clearSelectedThread]
  )
  const scrollToThread = (threadId: string): void => {
    const threadRef = threadRefs.current[threadId]
    if (threadRef && threadRef.current) {
      scrollIntoView(threadRef.current)
    }
  }

  return (
    <CommentContext.Provider
      value={{
        threadRefs,
        hasUnread,
        setHasUnread,
        filter,
        toggleFilter,
        animatedTagId,
        setAnimatedTagId,
        selectedThread,
        selectCanvasCommentThread,
        selectPanelCommentThread,
        clearSelectedThread,
        isCanvasCreateCommentOpen,
        openCanvasCreateComment,
        closeCanvasCreateComment,
        editingCommentId: editingCommentId,
        setEditingCommentId: setEditingCommentId,
        scrollTargetId,
        setScrollTargetId,
        isCommentVisible,
        setCommentVisibility,
        scrollToThread
      }}
    >
      {children}
    </CommentContext.Provider>
  )
}

function useCommentContext(): CommentContextType {
  const context = useContext(CommentContext)
  if (context === undefined) {
    throw new Error('useCommentContext must be used within a CommentProvider')
  }
  return context
}

export { CommentProvider, useCommentContext }
