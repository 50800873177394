import handleAttributes from './handleAttributes';
import { addEffect, addFill, addLayer, addMask, addShape, addStroke, addTransform } from './populateJSON';
import { BlendMode, FillRule, MaskType, ShapeType } from './enums';
import { adjustScale, combineClipPaths, combineDegrees, filterInPlace, getClipPathShape, getDOMRect, getGeometryTransform, getIDFromQuery, handleBlendMode, handleMaskMode, handleMatteMode, handleQueries, handleTransform, isClipped, mergeTransforms, neutralizeTransforms, parseSVGData, shapeQuery, shouldFillBeAdded, string2RgbArr, useId } from '.';
export const getPatternData = ({ el, container, dimensions, root }) => {
    const child = el.firstElementChild;
    let containerTransform = null;
    if (!(child instanceof SVGElement) || !(container instanceof SVGGeometryElement)) {
        console.warn(`Unkown/uncaught pattern child element: ${child?.tagName}`);
        return {
            shapeData: null,
            image: null,
            transform: null,
            containerTransform
        };
    }
    containerTransform = getGeometryTransform({ el: container });
    if (!(child instanceof SVGUseElement) && !(child instanceof SVGImageElement)) {
        const patternData = (parseSVGData({ el: child }));
        return {
            shapeData: patternData,
            image: null,
            transform: null,
            containerTransform
        };
    }
    const { href, transform: useTransform } = handleAttributes({ el: child, root }), image = href ? (root?.querySelector(href) || null) : null, transform = {
        anchorPoint: [useTransform.anchorPoint[0], useTransform.anchorPoint[1]],
        height: useTransform.height,
        position: [useTransform.position[0], useTransform.position[1]],
        translate: [useTransform.translate[0], useTransform.translate[1]],
        rotation: useTransform.rotation,
        scale: [useTransform.scale[0], useTransform.scale[1]],
        width: useTransform.width,
        skew: {
            angle: useTransform.skew.angle,
            length: useTransform.skew.length
        }
    };
    if (!(child instanceof SVGUseElement) || !(container instanceof SVGGeometryElement)) {
        return {
            shapeData: null,
            image,
            transform,
            containerTransform
        };
    }
    if (!(container instanceof SVGRectElement)
        && !(container instanceof SVGCircleElement)
        && !(container instanceof SVGPathElement)
        && !(container instanceof SVGEllipseElement)) {
        console.warn(`Expected <rect>, <circle>, <elipse> or <path>, received: <${container.tagName}>`);
    }
    const containerSize = {
        width: containerTransform.width || 512,
        height: containerTransform.height || 512
    }, parentMaskQuery = container.parentElement?.getAttribute('clip-path');
    if (parentMaskQuery) {
        const { clipPath, shapeEl } = getClipPathShape({
            query: parentMaskQuery,
            dimensions,
            root
        });
        const hasParentMask = !!clipPath && isClipped({
            el: container,
            mask: shapeEl,
            root,
            id: clipPath.id
        });
        if (hasParentMask && shapeEl instanceof SVGGeometryElement) {
            if (container.dataset.maskParent !== 'partial') {
                const maskRect = getDOMRect(shapeEl);
                containerSize.width = maskRect.width;
                containerSize.height = maskRect.height;
            }
        }
    }
    if (!containerTransform.width || !containerTransform.height) {
        console.warn(`Image container: ${container} has missing width or height`);
        containerTransform.width = 512;
        containerTransform.height = 512;
    }
    const rotation = combineDegrees(useTransform.rotation, containerTransform.rotation), isUseRotated90degrees = useTransform.rotation === 90 || useTransform.rotation === -90, isContainerRotated90degrees = containerTransform.rotation === 90 || containerTransform.rotation === -90, useScaleX = useTransform.scale[isUseRotated90degrees ? 1 : 0], useScaleY = useTransform.scale[isUseRotated90degrees ? 0 : 1], translateX = useTransform.translate[0] * containerTransform.width, translateY = useTransform.translate[1] * containerTransform.height;
    transform.width = image?.width.baseVal.value || 512;
    transform.height = image?.height.baseVal.value || 512;
    transform.position[0] = containerTransform.position[0] + containerTransform.translate[0] + translateX;
    transform.position[1] = containerTransform.position[1] + containerTransform.translate[1] + translateY;
    transform.anchorPoint[0] = containerTransform.anchorPoint[0];
    transform.anchorPoint[1] = containerTransform.anchorPoint[1];
    transform.rotation = rotation;
    if (containerTransform.skew.length && !rotation) {
        transform.skew.angle = combineDegrees(useTransform.skew.angle, containerTransform.skew.angle);
        transform.skew.length = combineDegrees(useTransform.skew.length, containerTransform.skew.length);
    }
    transform.scale[0] = (useScaleX * (isContainerRotated90degrees ? containerTransform.height : containerTransform.width));
    transform.scale[1] = (useScaleY * (isContainerRotated90degrees ? containerTransform.width : containerTransform.height));
    if (!(container instanceof SVGPathElement)) {
        if (isContainerRotated90degrees) {
            transform.position[0] = containerTransform.position[0] - (containerTransform.anchorPoint[0] / 2) - (containerSize.width / 2);
            transform.position[1] = containerTransform.position[1] + translateX;
            transform.anchorPoint[0] = 0;
            transform.anchorPoint[1] = 0;
        }
    }
    if (containerTransform.rotation === 180 || containerTransform.rotation === -180) {
        transform.position[0] -= containerTransform.anchorPoint[0] - (containerTransform.width / 3);
        transform.position[1] -= containerTransform.anchorPoint[1] - (containerTransform.height / 3);
    }
    adjustScale({
        container,
        containerSize,
        transform
    });
    if (containerTransform.scale[0] < 0) {
        transform.scale[0] *= -1;
        transform.position[0] -= (translateX * 2);
    }
    if (containerTransform.scale[1] < 0) {
        transform.position[0] -= (translateY * 2);
        transform.scale[1] *= -1;
    }
    return {
        shapeData: null,
        image,
        transform,
        containerTransform
    };
}, handleClipPath = ({ attributes, el, dimensions, maskElements, root, val }) => {
    if (attributes.mask.node.length || el?.hasAttribute('mask')) {
        return;
    }
    const { shapeEl, clipPath } = getClipPathShape({
        dimensions,
        query: val,
        maskElements,
        root
    });
    if (!clipPath) {
        return;
    }
    if (val.startsWith('url')) {
        let href = null;
        attributes.mask.id = clipPath.id;
        if (shapeEl instanceof SVGElement) {
            if (el instanceof SVGImageElement || (el instanceof SVGGElement && el.firstElementChild instanceof SVGImageElement)) {
                const imageElement = el instanceof SVGImageElement ? el : el.firstElementChild, { transform } = handleAttributes({
                    el: imageElement,
                    dimensions,
                    maskElements
                });
                neutralizeTransforms({
                    el: shapeEl,
                    transform,
                    hasBlur: !!attributes.blur.length
                });
            }
            attributes.mask.node.push({
                data: parseSVGData({
                    el: shapeEl,
                    parseTranslate: true,
                    closePaths: true
                })
            });
        }
        if (!shapeEl && clipPath?.firstElementChild instanceof SVGUseElement) {
            href = clipPath.firstElementChild.getAttribute('href') ?? clipPath?.firstElementChild.getAttribute('xlink:href');
        }
        if (href) {
            const maskData = parseSVGData({
                el: root?.querySelector(handleQueries(href)),
                parseTranslate: true,
                closePaths: true
            });
            attributes.mask.node.push({
                data: maskData
            });
            if (maskData.length > 1) {
                attributes.mask.type = MaskType.Matte;
                const color = shapeEl?.getAttribute('fill') ?? clipPath.getAttribute('fill') ?? '#fff', fill = {
                    opacity: null,
                    color: string2RgbArr(color),
                    rule: FillRule.NonZero
                };
                attributes.mask.node[0].fill = fill;
            }
        }
    }
}, handlePatternImage = ({ pattern, images, groupAttributes, node }) => {
    if (!(pattern.image.element instanceof SVGImageElement)) {
        throw new Error(`Expected image, recieved: ${pattern.image.element}`);
    }
    const { blendMode: imageBlendmode, opacity: imageOpacity, } = handleAttributes({ el: pattern.image.element }), mergedTransform = mergeTransforms(groupAttributes.transform, pattern.transform), opacity = node.fillOpacity ?? (imageOpacity ?? 100) * ((groupAttributes.opacity ?? 100) / 100);
    let blendMode = imageBlendmode;
    if (groupAttributes.blendMode !== BlendMode.Normal) {
        blendMode = groupAttributes.blendMode;
    }
    else if (node.blendMode !== BlendMode.Normal) {
        blendMode = node.blendMode;
    }
    if (!images.find(({ image }) => image.id === pattern.image.element?.id)) {
        if (!pattern.image.element.id) {
            pattern.image.element.id = useId('image_asset');
        }
        images.push({
            image: pattern.image.element,
        });
    }
    return {
        blendMode,
        opacity,
        transform: mergedTransform,
        type: 2,
        id: pattern.image.element.id
    };
}, handleMask = ({ attributes, el, maskElements, root, val }) => {
    if (attributes.mask.node.length && !attributes.pattern.image.element) {
        return;
    }
    const mask = root?.querySelector(handleQueries(val))
        ?? maskElements?.find(({ id }) => `#${id}` === handleQueries(val));
    if (!(mask instanceof SVGMaskElement)) {
        console.warn(maskElements, `Invalid mask element: #${getIDFromQuery(val)}`);
        return;
    }
    attributes.mask.type = MaskType.Matte;
    attributes.mask.id = mask.id;
    if (mask.getAttribute('mode')) {
        attributes.mask.maskMode = handleMaskMode(mask.getAttribute('mode'));
    }
    if (mask.getAttribute('mask-type')) {
        attributes.mask.matteMode = handleMatteMode(mask.getAttribute('mask-type'));
    }
    if (mask.style) {
        if (mask.style.maskType) {
            attributes.mask.matteMode = handleMatteMode(mask.style.maskType);
        }
        if (mask.style.opacity) {
            attributes.mask.opacity = Number(mask.style.opacity) * 100;
        }
    }
    const geometryElements = Array.from(mask.querySelectorAll(shapeQuery()));
    let geoEls = geometryElements;
    const clipPathAttribute = el.getAttribute('clip-path');
    if (clipPathAttribute && root && geoEls.length === 1) {
        const clipPathEl = root?.querySelector(handleQueries(clipPathAttribute))
            ?? maskElements?.find(({ id }) => `#${id}` === handleQueries(clipPathAttribute)), shapeEl = clipPathEl?.querySelector(shapeQuery());
        if (shapeEl instanceof SVGGeometryElement) {
            geoEls = geometryElements.map(m => {
                const { clipElement } = combineClipPaths({
                    svg: root,
                    elements: [m, shapeEl],
                }), geoEl = clipElement.querySelector(shapeQuery());
                for (const att of Array.from(m.attributes)) {
                    if (att.name !== 'fill' &&
                        (geoEl.hasAttribute(att.name)
                            || att.name === 'd')) {
                        continue;
                    }
                    geoEl.setAttribute(att.name, att.value);
                }
                return geoEl;
            });
        }
    }
    attributes.mask.node = [];
    for (const [i, geoEl] of geoEls.reverse().entries()) {
        attributes.mask.node.push({
            data: parseSVGData({ el: geoEl, parseTranslate: false })
        });
        if (geoEl.hasAttribute('stroke') || geoEl.hasAttribute('fill') || mask.hasAttribute('fill') || mask.hasAttribute('stroke')) {
            attributes.mask.type = MaskType.Matte;
            const { fill: maskFill, gradient: maskGradient, stroke: maskStroke } = handleAttributes({ el: mask, root });
            let fill = maskFill, stroke = maskStroke, gradient = maskGradient;
            if (geoEl.hasAttribute('fill')) {
                const { fill: geoFill, gradient: geoGradient } = handleAttributes({
                    el: geoEl,
                    root,
                    isMask: true,
                    maskElements
                });
                fill = geoFill;
                if (geoGradient.fill) {
                    gradient = geoGradient;
                }
                if (geoEl.getAttribute('fill')?.startsWith('url(')) {
                    fill.color = [0, 0, 0];
                }
            }
            if (geoEl.hasAttribute('stroke')) {
                const { gradient: strokeGradient, stroke: geoStroke } = handleAttributes({
                    el: geoEl,
                    root,
                    isMask: true,
                    maskElements
                });
                stroke = geoStroke;
                gradient = strokeGradient;
            }
            attributes.mask.node[i].gradient = gradient;
            attributes.mask.node[i].fill = fill;
            attributes.mask.node[i].stroke = stroke;
            if (!geoEl.hasAttribute('fill') && !mask.hasAttribute('fill') && attributes.mask.node[i].fill) {
                attributes.mask.node[i].fill.opacity = 0;
            }
            if (!geoEl.hasAttribute('stroke') && !mask.hasAttribute('stroke') && attributes.mask.node[i].stroke) {
                attributes.mask.node[i].stroke.color = null;
            }
            if (geoEl.hasAttribute('transform')) {
                const maskPathTransform = handleTransform({
                    input: geoEl.getAttribute('transform'),
                    el: geoEl,
                    root
                });
                attributes.mask.transform.skew = maskPathTransform.skew;
                attributes.mask.transform.scale = maskPathTransform.scale;
                attributes.mask.transform.anchorPoint = maskPathTransform.anchorPoint;
                attributes.mask.transform.rotation = maskPathTransform.rotation;
                attributes.mask.transform.position = maskPathTransform.position;
                attributes.mask.transform.translate = maskPathTransform.translate;
            }
        }
    }
}, handlePatternData = (el, attributes, patternData) => {
    const { opacity, transform } = handleAttributes({ el });
    attributes.pattern.data = patternData;
    attributes.pattern.transform = transform;
    attributes.pattern.opacity = [(opacity || 1) * 100, (opacity || 1) * 100];
}, handleSiblings = ({ ancestorAtts, svgDOMRect, group, groupIndex, images, layers, maskElements, root, siblings, isPrev, isCousin = false }) => {
    if (!siblings.length) {
        return 0;
    }
    try {
        let increase = 1;
        const siblingShapeGroups = [], cousins = [], sGroupAttributes = handleAttributes({
            el: group,
            root,
            ancestorAtts,
            maskElements,
            dimensions: svgDOMRect
        }), maskData = [];
        let siblingIndex = 0;
        for (const sibling of (isCousin ? siblings : siblings.reverse())) {
            const siblingAttributes = handleAttributes({
                el: sibling,
                root,
                ancestorAtts,
                maskElements,
                groupAttributes: sGroupAttributes,
            });
            siblingShapeGroups.push({
                ty: ShapeType.Group,
                it: [],
                nm: `Group ${siblingIndex + 1}, Layer ${groupIndex + 1}`,
                np: 0,
                bm: handleBlendMode(sGroupAttributes.blendMode),
                mn: 'Vector Group',
                hd: false,
            });
            if (siblingAttributes.pattern.image.element) {
                const imageAttributes = handlePatternImage({
                    pattern: siblingAttributes.pattern,
                    images,
                    groupAttributes: sGroupAttributes,
                    node: {
                        el: sibling,
                        blendMode: siblingAttributes.blendMode,
                        fillOpacity: siblingAttributes.fill.opacity,
                        transform: siblingAttributes.transform,
                        attributes: [...sibling.attributes],
                    }
                });
                if (siblingAttributes.stroke.color) {
                    sGroupAttributes.stroke = siblingAttributes.stroke;
                }
                sGroupAttributes.blendMode = imageAttributes.blendMode;
                sGroupAttributes.id = imageAttributes.id;
                sGroupAttributes.opacity = imageAttributes.opacity;
                sGroupAttributes.transform = imageAttributes.transform;
                sGroupAttributes.type = imageAttributes.type;
                sGroupAttributes.mask = siblingAttributes.mask;
                let j = siblings.length - 1;
                for (const [cousinIndex, cousin] of siblings.entries()) {
                    if (cousin === sibling) {
                        j = cousinIndex;
                        continue;
                    }
                    cousins.push({
                        el: cousin,
                        isPrev: cousinIndex > j
                    });
                }
            }
            if (!siblingAttributes.pattern.type
                || siblingAttributes.stroke.color) {
                for (const [shapeIndex, shapeData] of siblingAttributes.pathData.entries()) {
                    addShape({
                        index: shapeIndex,
                        shapeGroup: siblingShapeGroups[siblingIndex],
                        shapeData: [shapeData],
                        type: ShapeType.Path
                    });
                }
            }
            if (siblingAttributes.stroke.color) {
                addStroke({
                    gradient: siblingAttributes.gradient,
                    index: siblingIndex,
                    shapeGroup: siblingShapeGroups[siblingIndex],
                    stroke: siblingAttributes.stroke
                });
            }
            if (shouldFillBeAdded({ attributes: siblingAttributes, el: sibling })) {
                addFill({
                    fill: siblingAttributes.fill,
                    gradient: siblingAttributes.gradient,
                    index: siblingIndex,
                    shapeGroup: siblingShapeGroups[siblingIndex]
                });
            }
            addTransform({
                index: siblingIndex,
                shapeGroup: siblingShapeGroups[siblingIndex],
                transform: siblingAttributes.transform,
            });
            if (siblingAttributes.shadow.length) {
                sGroupAttributes.shadow.push(...siblingAttributes.shadow);
            }
            if (siblingAttributes.blur.length) {
                sGroupAttributes.blur.push(...siblingAttributes.blur);
            }
            siblingIndex++;
        }
        const ef = [], activeEffects = [];
        if (sGroupAttributes.shadow.length) {
            for (const _a of sGroupAttributes.shadow) {
                activeEffects.push({
                    type: 25,
                    np: 5,
                });
            }
        }
        if (sGroupAttributes.blur.length) {
            filterInPlace(sGroupAttributes.blur, b => b.id === sGroupAttributes.blur[0].id);
            for (const _b of sGroupAttributes.blur) {
                activeEffects.push({
                    type: 29,
                    np: 3,
                });
            }
        }
        for (const effect of activeEffects) {
            addEffect({
                attributes: sGroupAttributes,
                effect,
                effects: ef
            });
        }
        if (sGroupAttributes.mask.type === MaskType.Mask) {
            for (const maskNode of sGroupAttributes.mask.node) {
                addMask({
                    attributes: sGroupAttributes,
                    dimensions: svgDOMRect,
                    index: groupIndex,
                    maskData,
                    shapeData: maskNode.data
                });
            }
        }
        if (isCousin && cousins.length) {
            console.warn('SVG file has deep nesting of groups, and some data may be lost in conversion. Consider restructuring the SVG.');
        }
        if (!isCousin && cousins.length) {
            const nextCousins = cousins.filter(el => !el.isPrev).map(({ el }) => el), it = handleSiblings({
                ancestorAtts,
                svgDOMRect,
                group,
                groupIndex,
                images,
                layers,
                maskElements,
                root,
                siblings: nextCousins,
                isPrev: false,
                isCousin: true
            });
            if (nextCousins.length) {
                increase += it;
            }
        }
        let matteRef = groupIndex - 1;
        if (isPrev) {
            matteRef = groupIndex - 2;
        }
        addLayer({
            attributes: sGroupAttributes,
            index: groupIndex,
            layers,
            masksProperties: maskData,
            shapes: siblingShapeGroups,
            effects: ef,
            matteRef,
            svgDOMRect
        });
        if (!isCousin && cousins.length) {
            const prevCousins = cousins.filter(el => el.isPrev).map(({ el }) => el), it = handleSiblings({
                ancestorAtts,
                svgDOMRect,
                group,
                groupIndex,
                images,
                layers,
                maskElements,
                root,
                siblings: prevCousins,
                isPrev: true,
                isCousin: true
            });
            if (prevCousins.length) {
                increase += it;
            }
        }
        return increase;
    }
    catch (err) {
        console.error(err);
        return 0;
    }
};
