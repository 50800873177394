import { WorkspaceData } from '../providers/WorkspaceContextProvider'

import {
  GetNormalFilesQueryVariables,
  useGetPersonalArchiveFilesQuery,
  useGetPersonalProjectsQuery,
  useGetPersonalNormalProjectsQuery,
  useGetTeamArchiveFilesQuery,
  useGetTeamProjectsQuery,
  useGetTeamNormalProjectsQuery,
  useGetProjectIdsWithPermissionQuery
} from '../generated/graphql'

type UseWorkspaceProps = {
  isPersonalWorkspace: boolean
  workspaceId: WorkspaceData['id']
}
export const useProjectsQuery = ({ isPersonalWorkspace, workspaceId }: UseWorkspaceProps) => {
  const personalProjects = useGetPersonalProjectsQuery({
    variables: {
      id: workspaceId
    },
    skip: !isPersonalWorkspace
  })

  // FIXME: BE should provide a way to fetch projects with permission in single function
  const allowedProjects = useGetProjectIdsWithPermissionQuery({
    variables: {
      team_id: workspaceId
    },
    skip: isPersonalWorkspace
  })
  const projectIds = allowedProjects.data?.team_project_ids_with_permission?.project_ids || []
  const teamProjects = useGetTeamProjectsQuery({
    variables: {
      id_list: projectIds
    },
    skip: isPersonalWorkspace
  })

  return isPersonalWorkspace ? personalProjects : teamProjects
}

export const useNormalProjectsQuery = ({ isPersonalWorkspace, workspaceId }: UseWorkspaceProps) => {
  const personalProjects = useGetPersonalNormalProjectsQuery({
    variables: {
      id: workspaceId
    },
    skip: !isPersonalWorkspace,
    fetchPolicy: 'network-only'
  })

  // FIXME: BE should provide a way to fetch projects with permission in single function
  const allowedProjects = useGetProjectIdsWithPermissionQuery({
    variables: {
      team_id: workspaceId
    },
    skip: isPersonalWorkspace,
    fetchPolicy: 'network-only'
  })
  const projectIds = allowedProjects.data?.team_project_ids_with_permission?.project_ids || []

  const teamProjects = useGetTeamNormalProjectsQuery({
    variables: {
      id_list: projectIds
    },
    skip: isPersonalWorkspace,
    fetchPolicy: 'network-only'
  })

  return isPersonalWorkspace ? personalProjects : teamProjects
}

interface UseArchiveFilesProps extends UseWorkspaceProps {
  isArchivePage: boolean
  orderBy: GetNormalFilesQueryVariables['orderBy']
  offset: GetNormalFilesQueryVariables['offset']
  limit: GetNormalFilesQueryVariables['limit']
}
export const useArchiveFilesQuery = ({
  isPersonalWorkspace,
  workspaceId,
  isArchivePage,
  orderBy,
  offset,
  limit
}: UseArchiveFilesProps) => {
  const personalFiles = useGetPersonalArchiveFilesQuery({
    variables: {
      creatorId: workspaceId,
      orderBy,
      limit,
      offset
    },
    skip: !isPersonalWorkspace || !isArchivePage
  })

  const teamFiles = useGetTeamArchiveFilesQuery({
    variables: {
      teamId: workspaceId,
      orderBy,
      limit,
      offset
    },
    skip: isPersonalWorkspace || !isArchivePage
  })

  return isPersonalWorkspace ? personalFiles : teamFiles
}
