export const pathStates = {
    on:{
        SELECTION: undefined,
    },
    entry: 'entryShapeState',
    exit: 'exitShapeState',
    initial: 'move',
    states: {
        move: {
            initial: 'idle',
            on: {
                SELECT_CELL: [
                    { target: '.idle', cond: 'noSelection' },
                    { target: '.moveSelectedCell' },
                ],
                ACTIVATE_PEN: [
                    { target: 'pen.idle', cond: 'noSelection' },
                    { target: 'pen.penSelectedCell' },
                ],
            },
            states: {
                idle: {},
                moveSelectedCell: {},
            }
        },
        pen: {
            initial: 'idle',
            on: {
                SELECT_CELL: [
                    { target: '.idle', cond: 'noSelection' },
                    { target: '.penSelectedCell' },
                ],
                ACTIVATE_MOVE: [
                    { target: 'move.idle', cond: 'noSelection' },
                    { target: 'move.moveSelectedCell' },
                ],
            },
            states: {
                idle: {},
                penSelectedCell: {},
            }
        }
    }
}