import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { MIX_VALUE } from '@phase-software/data-utils'
import { BooleanOperation, ContainerElementType, ElementType } from '@phase-software/types'

import { useEditor, useEditorActions } from '../../../../providers/dataStore/EditorProvider'
import { Dropdown, WideTitle } from '../../../shared'
import { MenuOptionProps } from '../../../shared/Menu/Menu.types'

const ElementTypeNameMap: Partial<Record<ElementType, string>> = {
  [ElementType.CONTAINER]: 'container',
  [ElementType.NORMAL_GROUP]: 'group'
}

const containerGroupOptions: MenuOptionProps[] = [
  {
    name: 'container',
    value: ContainerElementType.CONTAINER
  },
  {
    name: 'group',
    value: ContainerElementType.NORMAL_GROUP
  }
]

const mixTypeName = 'mix'
const booleanGroupName = 'boolean_group'
const maskGroupName = 'mask_group'

const getElementTypeName = (booleanType: BooleanOperation, isMask: boolean, containerType: ContainerElementType) => {
  let name = ElementTypeNameMap[ElementType.CONTAINER]
  if (booleanType === MIX_VALUE || isMask === MIX_VALUE) {
    name = mixTypeName
  } else if (booleanType !== BooleanOperation.NONE) {
    name = booleanGroupName
  } else if (isMask) {
    name = maskGroupName
  } else if (containerType === MIX_VALUE) {
    name = mixTypeName
  } else {
    name = ElementTypeNameMap[containerType]
  }

  return name
}

const ContainerHeader = () => {
  const containerType = useEditor((o) => o.containerType)
  const booleanType = useEditor((o) => o.booleanType)
  const isMask = useEditor((o) => o.isMask)
  const { changeContainerType } = useEditorActions()
  const { t } = useTranslation('file')

  const elementTypeName = t(getElementTypeName(booleanType, isMask, containerType) ?? '', {
    keyPrefix: 'element_type'
  })

  const translatedContainerGroupOptions = useMemo(
    () =>
      containerGroupOptions.map((option) => ({
        ...option,
        name: t(option.name ?? '', { keyPrefix: 'container_element_type' })
      })),
    [t]
  )

  return (
    <WideTitle dividerPosition="top">
      {booleanType || isMask ? (
        elementTypeName
      ) : (
        <Dropdown
          menu={{
            options: translatedContainerGroupOptions,
            onSelect: (selectedOption: MenuOptionProps) => changeContainerType(selectedOption.value),
            selectable: true,
            selectedValues: [containerType]
          }}
          dataTestId="editor-container-header"
          arrow
        >
          {elementTypeName}
        </Dropdown>
      )}
    </WideTitle>
  )
}

export default React.memo(ContainerHeader)
