import { FlagsEnum, Vector2 as duVector2 } from '@phase-software/data-utils'
import { Bezier } from 'bezier-js/src/bezier'
import { Rect2, Vector2, distanceFromPointToLine, projectToLine } from '../../math'
import { Line } from '../../geometry/bezier-shape/Line'

/** @typedef {import('../../visual_server/VisualServer').VisualServer} VisualServer */
/** @typedef {import('@phase-software/data-utils/src/mesh/Mesh').Mesh} Mesh */
/** @typedef {import('@phase-software/data-store/src/Element').Element} Element */
/** @typedef {import('../selection/vertex').Edge} Edge */
/** @typedef {import('../../math').Transform2D } Transform2D */
/** @typedef {import('../../visual_server/RenderItem').RenderItem} RenderItem */
/** @typedef {import('../../../../data-utils').Vertex} Vertex */

/**
 * @enum {number}
 */
export const SNAP_TARGET_TYPE = Object.freeze(
    {
        "NONE": -1,
        "Vertex": 0,
        "Edge": 1,
        "Contour": 2,
        "SameAxis": 3,
    }
)

const vec2Buffer = new duVector2()

/**
 * @typedef {object} SnapTarget
 * @property {SNAP_TARGET_TYPE} type
 * @property {Vertex} [vertex]
 * @property {Edge} [edge]
 * @property {Contour} [contour]
 * @property {Vector2} position
 * @property {number} [timeOnEdge] - [0, 1]
 */

/**
 * @typedef {object} EdgeTreeItem
 * @property {Rect2} bbox
 * @property {Edge} edge
 * @property {Bezier} [bezier]
 */

/**
 * @typedef {object} LUTItem
 * @property {number} x
 * @property {number} y
 * @property {number} t
 * @property {number} [i]
 * @property {number} [distance]
 */


export class SnappingPath {
    /**
     * @param {VisualServer} visualServer
     */
    constructor(visualServer) {
        this.visualServer = visualServer
        this.mesh = undefined
        /** @type {Map<Edge, EdgeTreeItem>} */
        this.mapEdge2TreeItem = new Map()
        this.dirty = false
        this.setDirtyFunc = this.setDirty.bind(this)
        this.onMeshChangedFunc = this.onMeshChanged.bind(this)
        this.positionStaticBuffer = new Vector2()
        this.positionStaticBuffer2 = new Vector2()
        // Vertex related properties
        this.vertexHittest = null
    }

    /**
     * @param {Element} element
     * @param {Mesh} mesh
     * @param {RenderItem} node
     */
    assign(element, mesh, node) {
        this.element = element
        this.mesh = mesh
        this.node = node
        if (!mesh) {
            console.error("No mesh is specified to the SnappingPath")
        }

        this.createEdgeTree()
        this.node.transform.on('updateWorld', this.setDirtyFunc)
        this.element.get('geometry').on('MESH_CHANGES', this.onMeshChangedFunc)
    }

    cancel() {
        this.clear()

        this.node?.transform.off('updateWorld', this.setDirtyFunc)
        this.element?.get('geometry').off("MESH_CHANGES", this.onMeshChangedFunc)

        this.element = null
        this.mesh = null
        this.node = null
    }

    createEdgeTree() {
        const size = this.element.get('size')
        for (const edge of this.mesh.edges) {
            /** @type {EdgeTreeItem} */
            const treeItem = {
                bbox: new Rect2(),
                edge
            }
            this.mapEdge2TreeItem.set(edge, treeItem)
            this._fillEdgeTreeItem(edge, size, treeItem)
        }
    }

    updateEdgeTree() {
        const size = this.element.get('size')
        for (const [edge, treeItem] of this.mapEdge2TreeItem) {
            this._fillEdgeTreeItem(edge, size, treeItem)
        }
    }

    /**
     *
     * @param {Edge} edge
     * @param {duVector2} size
     * @param {EdgeTreeItem} treeItem
     * @returns {EdgeTreeItem}
     */
    _fillEdgeTreeItem(edge, size, treeItem) {
        const transform = this.node.transform.world
        const rect = new Rect2()
        if (edge.isCurve) {
            return this._fillCurveTreeItem(edge, size, transform, treeItem, rect)
        } else {
            return this._fillLineTreeItem(edge, size, rect, transform, treeItem)
        }
    }

    _fillCurveTreeItem(edge, size, transform, treeItem, rect) {
        const cp = this.mesh.getEdgeCurve(edge.id)
        const cp0Vector2 = new Vector2(cp[0].x, cp[0].y)
        const cp1Vector2 = new Vector2(cp[1].x, cp[1].y)
        const vPos = this.mesh.getVertPos(edge.v.id)
        const vPosVector2 = new Vector2(vPos.x, vPos.y)
        const wPos = this.mesh.getVertPos(edge.w.id)
        const wPosVector2 = new Vector2(wPos.x, wPos.y)
        transform.xform(cp0Vector2, cp0Vector2)
        transform.xform(cp1Vector2, cp1Vector2)
        transform.xform(vPosVector2, vPosVector2)
        transform.xform(wPosVector2, wPosVector2)
        treeItem.bezier = new Bezier(
            vPosVector2.x, vPosVector2.y,
            cp0Vector2.x, cp0Vector2.y,
            cp1Vector2.x, cp1Vector2.y,
            wPosVector2.x, wPosVector2.y)
        const bbox = treeItem.bezier.bbox()
        rect.set(bbox.x.min, bbox.y.min, bbox.x.max - bbox.x.min, bbox.y.max - bbox.y.min)
        treeItem.bbox.copy(rect)
        return treeItem
    }

    _fillLineTreeItem(edge, size, rect, transform, treeItem) {
        const from = this.mesh.getVertPos(edge.v.id)
        const fromX = from.x, fromY = from.y
        const to = this.mesh.getVertPos(edge.w.id)
        const toX = to.x, toY = to.y
        const maxX = Math.max(fromX, toX)
        const minX = Math.min(fromX, toX)
        const maxY = Math.max(fromY, toY)
        const minY = Math.min(fromY, toY)
        /** @todo Use a particular function to set the threshold in different sacle of zoom instead of bbox detection */
        const defaultThresold = 5
        rect.set(minX, minY, Math.max(defaultThresold, maxX - minX), Math.max(defaultThresold, maxY - minY))
        const worldRect = transform.xform_rect(rect)
        treeItem.bbox.copy(worldRect)
        return treeItem
    }

    clear() {
        this.mapEdge2TreeItem.clear()
    }

    /**
     * Executes the update event about the actions once per frame.
     */
    update() {
        if (this.dirty) {
            this.dirty = false
            this.updateEdgeTree()
        }
    }

    setDirty() {
        this.dirty = true
    }

    onMeshChanged(changes) {
        if (changes.DELETE.size !== 0 || changes.CREATE.size !== 0) {
            this.clear()
            this.createEdgeTree()
        } else {
            this.dirty = true
        }
    }

    /**
     * @todo Integrate all of the items able to be the target
     * @param {Vector2} worldMousePos
     * @param {boolean} snapToGrid
     * @param {boolean} snapToObject
     * @returns {SnapTarget}
     */
    snapMousePos(worldMousePos, snapToGrid, snapToObject) {
        /** @type {SnapTarget} */
        const edgeTarget = {
            type : SNAP_TARGET_TYPE.NONE,
            position: this.positionStaticBuffer
        }
        const axisTarget = {
            type : SNAP_TARGET_TYPE.NONE,
            position: this.positionStaticBuffer2
        }

        if (!snapToObject) return axisTarget

        const elemSnapping = this.visualServer.snapping
        const threshold = this._getThreshold(this.visualServer.viewport.scale)
        const ghostPoint = this.visualServer.snapping._convertPosToUIPanelVersion(worldMousePos)
        if (snapToGrid) {
            ghostPoint.round()
        }
        this._snapOnEdge(ghostPoint, edgeTarget, threshold)


        this._snapOnSameAxis(elemSnapping, worldMousePos, axisTarget, [{pos: ghostPoint}], ghostPoint, snapToGrid)
        if (edgeTarget.type === SNAP_TARGET_TYPE.Edge){
            if (axisTarget.type === SNAP_TARGET_TYPE.SameAxis){
                this._snapOnSameAxisEdge(elemSnapping, edgeTarget, axisTarget)
            }
            return edgeTarget
        } else {
            return axisTarget
        }
    }

    _snapOnSameAxisEdge(elemSnapping, edgeTarget, axisTarget){
        /** @type {Edge} */
        const edge = edgeTarget.edge
        const threshold = this._getThreshold(this.visualServer.viewport.scale)
        if (elemSnapping.snapToElementXUI.size > 0) {
            const p1 = axisTarget.position.clone().add(0, -threshold)
            const p2 = axisTarget.position.clone().add(0, threshold)
            // only snap to x axis
            if (edge.isCurve) {
                const intersectRatio = edge._bezier.lineIntersects({p1, p2})
                if (intersectRatio.length > 0) {
                    const intersect = new Vector2().copy(edge._bezier.get(intersectRatio[0]))
                    if (edgeTarget.position.distance_squared_to(intersect) <= threshold * threshold) {
                        const colPosOnUISpace = this.visualServer.snapping._convertPosToUIPanelVersion(intersect)
                        edgeTarget.position.copy(colPosOnUISpace)
                        if (elemSnapping.snapToElementXUI.has(colPosOnUISpace.x)) {
                            elemSnapping.snapToElementXUI.get(colPosOnUISpace.x).delete(axisTarget.position.y)
                            elemSnapping.snapToElementXUI.get(colPosOnUISpace.x).set(colPosOnUISpace.y, 0)
                        }
                    } else {
                        elemSnapping.snapToElementXUI.clear()
                    }
                } else {
                    elemSnapping.snapToElementXUI.clear()
                }
            } else {
                // check the snapping axis position with offset is intersected with the segment
                const {q1, q2} = _getCollinearEdgePoints(this.mesh, edge, this.node.transform.world)
                let intersect = Line.intersect(p1.x, p1.y, p2.x, p2.y, q1.x, q1.y, q2.x, q2.y)
                // check is on the parallel axis
                if ((q1.x - q2.x === 0) && axisTarget.position.x === q1.x) {
                    intersect = axisTarget.position.clone()
                }
                if (intersect && edgeTarget.position.distance_squared_to(intersect) <= threshold *threshold) {
                    const colPosOnUISpace = this.visualServer.snapping._convertPosToUIPanelVersion(intersect)
                    edgeTarget.position.copy(colPosOnUISpace)
                    if (elemSnapping.snapToElementXUI.has(colPosOnUISpace.x)) {
                        elemSnapping.snapToElementXUI.get(colPosOnUISpace.x).delete(axisTarget.position.y)
                        elemSnapping.snapToElementXUI.get(colPosOnUISpace.x).set(colPosOnUISpace.y, 0)
                    }
                } else {
                    elemSnapping.snapToElementXUI.clear()
                }
            }
        }
        if (elemSnapping.snapToElementYUI.size > 0) {
            // only snap to y axis
            const p1 = axisTarget.position.clone().add(-threshold, 0)
            const p2 = axisTarget.position.clone().add(threshold, 0)
            if (edge.isCurve) {
                const intersectRatio = edge._bezier.lineIntersects({p1, p2})
                if (intersectRatio.length > 0) {
                    const intersect = new Vector2().copy(edge._bezier.get(intersectRatio[0]))
                    if (edgeTarget.position.distance_squared_to(intersect) <= threshold * threshold) {
                        const rowPosOnUISpace = this.visualServer.snapping._convertPosToUIPanelVersion(intersect)
                        edgeTarget.position.copy(rowPosOnUISpace)
                        if (elemSnapping.snapToElementYUI.has(rowPosOnUISpace.y)) {
                            elemSnapping.snapToElementYUI.get(rowPosOnUISpace.y).delete(axisTarget.position.x)
                            elemSnapping.snapToElementYUI.get(rowPosOnUISpace.y).set(rowPosOnUISpace.x, 0)
                        }
                    } else {
                        elemSnapping.snapToElementYUI.clear()
                    }
                } else {
                    elemSnapping.snapToElementYUI.clear()
                }

            } else {
                // check the snapping axis position with offset is intersected with the segment
                const {q1, q2} = _getCollinearEdgePoints(this.mesh, edge, this.node.transform.world)
                let intersect = Line.intersect(p1.x, p1.y, p2.x, p2.y, q1.x, q1.y, q2.x, q2.y)
                // check is on the parallel axis
                if ((q1.y - q2.y === 0) && axisTarget.position.y === q1.y) {
                    intersect = axisTarget.position.clone()
                }

                if (intersect && edgeTarget.position.distance_squared_to(intersect) <= threshold * threshold) {
                    const rowPosOnUISpace = this.visualServer.snapping._convertPosToUIPanelVersion(intersect)
                    edgeTarget.position.copy(rowPosOnUISpace)
                    if (elemSnapping.snapToElementYUI.has(rowPosOnUISpace.y)) {
                        elemSnapping.snapToElementYUI.get(rowPosOnUISpace.y).delete(axisTarget.position.x)
                        elemSnapping.snapToElementYUI.get(rowPosOnUISpace.y).set(rowPosOnUISpace.x, 0)
                    }
                } else {
                    elemSnapping.snapToElementYUI.clear()
                }
            }
        }
    }

    _snapOnSameAxis(elemSnapping, worldMousePos, target, selectedVertices = [], hoverVertPos, snapToGrid) {
        const viewportRect = this.visualServer.viewport.rectW.clone()
        // for removing the area of the rulers
        const rulerSize = 18 / this.visualServer.viewport.scale

        const threshold = this._getThreshold(this.visualServer.viewport.scale)
        /** @todo Row and col can use the same function */
        // get elements in cross area
        // increase one threshold in bbox rect to make sure have space for snapping

        const item = this.visualServer.getRenderItemOfElement(this.element)
        const worldTransform = item.transform.world
        const worldMousePosOnUISpace = elemSnapping._convertPosToUIPanelVersion(worldMousePos)
        const offset = worldMousePosOnUISpace.clone().sub(hoverVertPos)

        let alignXOnUISpace = undefined, alignYOnUISpace = undefined
        let isSnappingX = false, isSnappingY = false
        let minDistAxisX = threshold
        let minDistAxisY = threshold
        let snappingPosX = worldMousePos.x
        let snappingPosY = worldMousePos.y
        const snappedVerticesPosX = []
        const snappedVerticesPosY = []

        // if we need to clear previous snapping UI
        let clearXUI = false
        let clearYUI = false
        const delta = new Vector2()

        for (let i = 0; i < selectedVertices.length; i++) {
            alignXOnUISpace = undefined
            alignYOnUISpace = undefined
            const selectedVertex = selectedVertices[i]
            // if the selectedVertex is ghost point then it doesn't have id
            const selectedPos = selectedVertex.id ? new Vector2().fromArray(this.mesh.getVertPos(selectedVertex.id)) : selectedVertex.pos
            // if the vertex is not the dragging one, we should user the relative position as dragging vertex and mouse position
            const relativeDraggingPos = this.visualServer.snapping._convertPosToUIPanelVersion(offset.clone().add(selectedPos))
            // if the selectedVertex is ghost point, it doesn't need to append world transform
            if (selectedVertex.id) {
                worldTransform.xform(relativeDraggingPos, relativeDraggingPos)
            }
            const searchBoxCol = makeColBox(viewportRect, rulerSize, relativeDraggingPos, threshold)
            const colVertices = _searchVertices(this.mesh, searchBoxCol, worldTransform)
            for (const colVertex of colVertices) {
                // skip snapping to selected vertices
                if (selectedVertices.indexOf(colVertex) !== -1) {
                    continue
                }

                // skip curve handles
                if (colVertex.isFlagged(FlagsEnum.CURVE_VERT)){
                    continue
                }

                const colVertexPos = _getVertexWorldPos(this.mesh, worldTransform, colVertex)
                // rounding the position to the two decimal places
                const colPosInUISpace = this.visualServer.snapping._convertPosToUIPanelVersion(colVertexPos)
                if (snapToGrid && !Number.isInteger(colPosInUISpace.x)) continue

                const dist = Math.abs(colPosInUISpace.x - relativeDraggingPos.x)
                // if dist less or equal minDist, add the snapping UI on snapping vertices
                if (dist <= minDistAxisX && dist < threshold) {
                    const snapDelta = colPosInUISpace.x - relativeDraggingPos.x
                    // if the snapDelta is less than previous delta or first time the snapDelta less than threshold
                    // then clean snapping UI and update data
                    if (!clearXUI || snapDelta !== delta.x) {
                        // if this is the closer distance to snap then clear the previous UI data
                        if (clearXUI && dist !== minDistAxisX) {
                            snappedVerticesPosX.length = 0
                            elemSnapping.snapToElementXUI.clear()
                        }
                        delta.x = snapDelta
                        // add snapping vertices' position to snapping UI
                        minDistAxisX = dist
                        // record the snapping x axis's data
                        clearXUI = true
                    }
                    alignXOnUISpace = colPosInUISpace.x
                    elemSnapping._addSnapXUIData(colPosInUISpace.x, colPosInUISpace.y, elemSnapping.snapToElementXUI)
                    isSnappingX = true
                }
            }

            const searchBoxRow = makeRowBox(viewportRect, rulerSize, relativeDraggingPos, threshold)
            const rowVertices = _searchVertices(this.mesh, searchBoxRow, worldTransform)
            for (const rowVertex of rowVertices) {
                if (selectedVertices.indexOf(rowVertex) !== -1) {
                    continue
                }
                if (rowVertex.isFlagged(FlagsEnum.CURVE_VERT)){
                    continue
                }
                const rowPos = _getVertexWorldPos(this.mesh, worldTransform, rowVertex)
                const rowPosOnUISpace = this.visualServer.snapping._convertPosToUIPanelVersion(rowPos)
                if (snapToGrid && !Number.isInteger(rowPosOnUISpace.y)) continue

                const dist = Math.abs(rowPosOnUISpace.y - relativeDraggingPos.y)
                if (dist <= minDistAxisY && dist < threshold) {
                    const snapDelta = rowPosOnUISpace.y - relativeDraggingPos.y
                    if (!clearYUI || snapDelta !== delta.y) {
                        if (clearYUI && dist !== minDistAxisY) {
                            snappedVerticesPosY.length = 0
                            elemSnapping.snapToElementYUI.clear()
                        }
                        delta.y = snapDelta
                        minDistAxisY = dist
                        clearYUI = true
                    }
                    alignYOnUISpace = rowPosOnUISpace.y
                    elemSnapping._addSnapYUIData(rowPosOnUISpace.x, rowPosOnUISpace.y, elemSnapping.snapToElementYUI)
                    isSnappingY = true
                }
            }

            // if there is a record for x axis then add the new snapped vertex position to the snapping UI
            if (alignXOnUISpace !== undefined) {
                snappedVerticesPosX.push(new Vector2(alignXOnUISpace, relativeDraggingPos.y))
            }

            if (alignYOnUISpace !== undefined) {
                snappedVerticesPosY.push(new Vector2(relativeDraggingPos.x, alignYOnUISpace))
            }
        }

        // add snapped vertex pos to the snapping UI
        for (const snappedPos of snappedVerticesPosX) {
            elemSnapping._addSnapXUIData(snappedPos.x, snappedPos.y + delta.y, elemSnapping.snapToElementXUI)
        }

        for (const snappedPos of snappedVerticesPosY) {
            elemSnapping._addSnapYUIData(snappedPos.x + delta.x, snappedPos.y, elemSnapping.snapToElementYUI)
        }

        if (isSnappingX || isSnappingY) {
            snappingPosX = worldMousePos.x + delta.x
            snappingPosY = worldMousePos.y + delta.y
            target.position.set(snappingPosX, snappingPosY)
            target.type = SNAP_TARGET_TYPE.SameAxis
        }
    }

    snapDraggingVertex(worldMousePos, hoverVertPos, selectVertices, snapToGrid, snapToObject, mesh, worldTransform) {
        /** @type {SnapTarget} */
        const target = {
            type: SNAP_TARGET_TYPE.NONE,
            position: this.positionStaticBuffer
        }

        if (!snapToObject) return target

        const elemSnapping = this.visualServer.snapping

        /** @type {Map<Vertex, Vector2>} */
        const selectVertices2WorldPosPred = new Map()
        const delta = new Vector2(worldMousePos.x - hoverVertPos.x, worldMousePos.y - hoverVertPos.y)
        for (const currVertex of selectVertices) {
            const vertexWorldPosPred = _getVertexWorldPos(mesh, worldTransform, currVertex)
            vertexWorldPosPred.add(delta)
            selectVertices2WorldPosPred.set(currVertex, vertexWorldPosPred)
        }

        const threshold = this._getThreshold(this.visualServer.viewport.scale)
        this._snapOnSameAxis(elemSnapping, worldMousePos, target, selectVertices, hoverVertPos, snapToGrid)


        let minDist2Edge = threshold
        /** @type {SnapTarget} */
        const target2Edge = {
            type: SNAP_TARGET_TYPE.NONE,
            // the snapped vertex position
            position: new Vector2(),
            // the original vertex position
            sourcePos: new Vector2(),
        }
        for (const [vertex, worldPosPred] of selectVertices2WorldPosPred) {
            const dist2Edge = this._snapOnEdge(worldPosPred, target2Edge, minDist2Edge, selectVertices)
            if (dist2Edge < minDist2Edge) {
                minDist2Edge = dist2Edge
                const pos = this.mesh.getVertPos(vertex.id)
                target2Edge.sourcePos.fromArray(pos)
                worldTransform.xform(target2Edge.sourcePos, target2Edge.sourcePos)
            }
        }

        if (target2Edge.type === SNAP_TARGET_TYPE.Edge){
            if (target.type === SNAP_TARGET_TYPE.SameAxis){
                this._snapOnSameAxisEdge(elemSnapping, target2Edge, target)
            }
            return target2Edge
        } else {
            return target
        }
    }

    /**
     * @typedef PosAlignAxes
     * @property {Vector2} alignDelta
     * @property {boolean} isAligned
     */
    /**
     * @param {Rect2} viewportRect
     * @param {number} rulerSize
     * @param {number} threshold
     * @param {Map<Vertex, Vector2>} selectVertices2WorldPosPred
     * @param {Snapping} elemSnapping
     * @returns {PosAlignAxes}
     */
    _getPosAlignAxes(viewportRect , rulerSize, threshold, selectVertices2WorldPosPred, elemSnapping) {

        let minDistAxisX = threshold
        let minDistAxisY = threshold
        let alignXDelta = 0
        let alignYDelta = 0
        let isAligned = false

        const parentItem = this.visualServer.getRenderItemOfElement(this.element)
        const worldTransform = parentItem.transform.world
        for (const worldPosPred of selectVertices2WorldPosPred.values()) {
            const predPosOnUISpace = elemSnapping._convertPosToUIPanelVersion(worldPosPred)
            /** @todo Row and col can use the same function */
            // get elements in cross area
            // increase one threshold in bbox rect to make sure have space for snapping
            const searchBoxCol = makeColBox(viewportRect, rulerSize, worldPosPred, threshold)
            const colItems = _searchVertices(this.mesh, searchBoxCol, worldTransform)
            for (const colItem of colItems) {
                if (colItem.isFlagged(FlagsEnum.CURVE_VERT)) {
                    continue
                }
                if (selectVertices2WorldPosPred.has(colItem)) {
                    continue
                }
                const colPos = _getVertexWorldPos(this.mesh, worldTransform, colItem)
                const colPosOnUISpace = this.visualServer.snapping._convertPosToUIPanelVersion(colPos)
                const dist = Math.abs(colPosOnUISpace.x - predPosOnUISpace.x)
                if (dist < minDistAxisX) {
                    minDistAxisX = dist
                    alignXDelta = colPos.x - worldPosPred.x
                    isAligned = true
                }
            }

            const searchBoxRow = makeRowBox(viewportRect, rulerSize, worldPosPred, threshold)
            const rowItems = _searchVertices(this.mesh, searchBoxRow, worldTransform)
            for (const rowItem of rowItems) {
                if (rowItem.isFlagged(FlagsEnum.CURVE_VERT)) {
                    continue
                }
                if (selectVertices2WorldPosPred.has(rowItem)) {
                    continue
                }
                const rowPos = _getVertexWorldPos(this.mesh, worldTransform, rowItem)
                const rowPosOnUISpace = this.visualServer.snapping._convertPosToUIPanelVersion(rowPos)
                const dist = Math.abs(rowPosOnUISpace.y - predPosOnUISpace.y)
                if (dist < minDistAxisY) {
                    minDistAxisY = dist
                    alignYDelta = rowPos.y - worldPosPred.y
                    isAligned = true
                }
            }
        }
        const alignDelta = new Vector2(alignXDelta, alignYDelta)
        return { alignDelta, isAligned }
    }

    _snapOnEdge(worldMousePos, target, threshold, ignoreVertices = []) {
        /** @type {Iterable<EdgeTreeItem>} */
        const items = []
        // eslint-disable-next-line no-unused-vars
        for (const [_, item] of this.mapEdge2TreeItem) {
            if (item.bbox.contains(worldMousePos)) {
                items.push(item)
            }
            if (item.bbox.height === 0 || item.bbox.width === 0) {
                const mouseBox = new Rect2(worldMousePos.x, worldMousePos.y).grow_to(threshold)
                if (item.bbox.intersects(mouseBox)) {
                    items.push(item)
                }
            }
        }
        let minLength = threshold
        for (const item of items) {

            if (!item.edge) {
                continue
            }

            /** @type {Edge} */
            const edge = item.edge

            if (ignoreVertices.indexOf(edge.v) !== -1 || ignoreVertices.indexOf(edge.w) !== -1) {
                continue
            }

            if (edge.isCurve) {
                const bezier = item.bezier
                if (!bezier) {
                    console.error(`No bezier found for ${edge.id}`)
                    continue
                }
                const projectPoint = bezier.project(worldMousePos)
                if (Math.abs(projectPoint.d) < minLength) {
                    minLength = projectPoint.d
                    target.type = SNAP_TARGET_TYPE.Edge
                    target.edge = edge
                    target.position.set(projectPoint.x, projectPoint.y)
                    target.timeOnEdge = projectPoint.t
                }
            } else {

                this.mesh.getVertPos(edge.v.id, vec2Buffer)
                const fromX = vec2Buffer.x
                const fromY = vec2Buffer.y
                this.mesh.getVertPos(edge.w.id, vec2Buffer)
                const toX = vec2Buffer.x
                const toY = vec2Buffer.y
                const vec2From = new Vector2(fromX, fromY)
                const vec2To = new Vector2(toX, toY)

                this.node.transform.world.xform(vec2From, vec2From)
                this.node.transform.world.xform(vec2To, vec2To)

                if (vec2From.equals(vec2To)){
                    continue
                }
                const length = distanceFromPointToLine(
                    worldMousePos.x, worldMousePos.y,
                    vec2From.x, vec2From.y,
                    vec2To.x, vec2To.y
                )

                if (minLength > Math.abs(length)) {
                    target.type = SNAP_TARGET_TYPE.Edge
                    target.edge = edge
                    minLength = length
                    projectToLine(worldMousePos.x, worldMousePos.y,
                        vec2From.x, vec2From.y,
                        vec2To.x, vec2To.y,
                        target.position
                    )
                    const dir2End = new Vector2(vec2To.x - vec2From.x, vec2To.y - vec2From.y)
                    const dir2project = new Vector2(target.position.x - vec2From.x, target.position.y - vec2From.y)
                    target.timeOnEdge = dir2project.length() / dir2End.length()
                }
            }
        }
        return minLength
    }

    /**
     * @todo Use this function if bezier.project is the bottleneck
     * @param {Vector2} worldMousePos
     * @param {LUTItem[]} LUT
     * @param {number} threshold
     * @returns {LUTItem}
     */
    findClosestLUT(worldMousePos, LUT, threshold) {
        let q
        let minDistance = threshold
        for (let index = 0; index < LUT.length; index++) {
            const p = LUT[index]
            p.t = index/(LUT.length-1)
            const distance = worldMousePos.distance_to(p)
            if (distance < minDistance) {
                minDistance = distance
                p.i = index
                q = p
            }
        }
        return q
    }

    /**
     * @todo Use this function if bezier.project is the bottleneck
     * Binary search for the projected point on the curve within the closest LUTs
     * @param {Bezier} curve
     * @param {Vector2} worldMousePos
     * @param {LUTItem[]} LUT
     * @param {number} index
     * @returns {LUTItem}
     */
    refineBinary(curve, worldMousePos, LUT, index) {
        let i = index,
            currentLUT = LUT,
            q = currentLUT[i],
            count = 1,
            minDistance = Number.MAX_SAFE_INTEGER

        do {
            const i1 = i === 0 ? 0 : i - 1,
                i2 = i === currentLUT.length - 1 ? currentLUT.length - 1 : i + 1,
                t1 = currentLUT[i1].t,
                t2 = currentLUT[i2].t,
                nextLUT = [],
                step = (t2 - t1) / 4

            if (step < 0.001) break

            nextLUT.push(currentLUT[i1])
            for (let j = 1; j <= 3; j++) {
                const n = curve.get(t1 + j * step)
                n.distance = Math.abs(worldMousePos.distance_to(n))
                if (n.distance < minDistance) {
                    minDistance = n.distance
                    q = n
                    i = j
                }
                nextLUT.push(n)
            }
            nextLUT.push(currentLUT[i2])

            // update the LUT to be our new five point LUT, and run again.
            currentLUT = nextLUT
        } while (count++ < 25)

        return q
    }

    /**
     *
     * @param {Vector2} worldMousePos
     * @param {Vector2} basePoint
     * @param {Vector2} outputPoint
     * @param {boolean} snapToGrid
     * @returns {Vector2}
     */
    constraint(worldMousePos, basePoint, outputPoint, snapToGrid) {
        let maxDot = Number.MIN_VALUE
        worldMousePos.sub(basePoint)
        const dir = new Vector2()
        const closest = new Vector2()
        for (let i = 0; i < 8; i++) {
            const radians = 45 * i * (Math.PI / 180)
            dir.set(Math.cos(radians), Math.sin(radians))
            const dot = dir.dot(worldMousePos)
            if (maxDot < dot) {
                maxDot = dot
                closest.copy(dir)
            }
        }
        outputPoint.set(maxDot * closest.x, maxDot * closest.y)
        // prevent the case like the value is 0.4999 and get rounded out
        outputPoint.scale(100)
        outputPoint.x = outputPoint.x < 0 ? -Math.round(-outputPoint.x) : Math.round(outputPoint.x)
        outputPoint.y = outputPoint.y < 0 ? -Math.round(-outputPoint.y) : Math.round(outputPoint.y)
        outputPoint.scale(0.01)
        if (snapToGrid) {
            outputPoint.x = outputPoint.x < 0 ? -Math.round(-outputPoint.x) : Math.round(outputPoint.x)
            outputPoint.y = outputPoint.y < 0 ? -Math.round(-outputPoint.y) : Math.round(outputPoint.y)
        }
        outputPoint.add(basePoint)
        return outputPoint
    }

    _getThreshold(viewScale) {
        const zoomRatio = Math.floor(viewScale * 100)
        if (zoomRatio <= 2) {
            return 200
        } else if(zoomRatio <= 5) {
            return 100
        } else if(zoomRatio <= 10) {
            return 66
        } else if(zoomRatio <= 25) {
            return 21
        } else if (zoomRatio <= 50) {
            return 9
        } else if (zoomRatio <= 75) {
            return 7
        } else if (zoomRatio <= 100) {
            return 4
        } else if (zoomRatio <= 200) {
            return 3
        } else if (zoomRatio <= 400) {
            return 2
        } else if (zoomRatio <= 25600) {
            return 1
        }
    }
}

/**
 * @param {Rect2} viewportRect
 * @param {number} rulerSize
 * @param {Vector2} worldPos
 * @param {number} threshold
 * @returns {Rect2}
 */
function makeRowBox(viewportRect, rulerSize, worldPos, threshold) {
    const rowBoxPosX = viewportRect.x + rulerSize
    let rowBoxPosY = worldPos.y - threshold * 2
    if (rowBoxPosY < viewportRect.y + rulerSize) {
        rowBoxPosY = viewportRect.y + rulerSize
    }
    const rowBoxW = viewportRect.width - rulerSize
    let rowBoxH = threshold * 4
    if (rowBoxPosY + rowBoxH > viewportRect.bottom) {
        rowBoxH = viewportRect.bottom - rowBoxPosY
    }
    // get elements in same row area in the viewport
    const searchBoxRow = new Rect2(rowBoxPosX, rowBoxPosY, rowBoxW, rowBoxH)
    return searchBoxRow
}

/**
 * @param {Rect2} viewportRect
 * @param {number} rulerSize
 * @param {Vector2} worldPos
 * @param {number} threshold
 * @returns {Rect2}
 */
function makeColBox(viewportRect, rulerSize, worldPos, threshold) {
    let colBoxPosX = worldPos.x - threshold * 2
    if (colBoxPosX < viewportRect.x + rulerSize) {
        colBoxPosX = viewportRect.x + rulerSize
    }
    const colBoxPosY = viewportRect.y + rulerSize
    let colBoxW = threshold * 4
    if (colBoxPosX + colBoxW > viewportRect.right) {
        colBoxW = viewportRect.right - colBoxPosX
    }
    const colBoxH = viewportRect.height - rulerSize
    // get elements in same column area in the viewport
    const searchBoxCol = new Rect2(colBoxPosX, colBoxPosY, colBoxW, colBoxH)
    return searchBoxCol
}

/**
 *
 * @param {Mesh} mesh
 * @param {Transform2D} worldTransform
 * @param {import('..').Vertex} vertex
 * @returns {Vector2}
 */
function _getVertexWorldPos(mesh, worldTransform, vertex) {
    const dummy = mesh.getVertPos(vertex.id)
    const vertexPos = new Vector2()
    vertexPos.set(dummy[0], dummy[1])
    worldTransform.xform(vertexPos, vertexPos)
    return vertexPos
}

/**
 * @param {Mesh} mesh
 * @param {Edge} edge
 * @param {Transform2D} worldTransform
 * @returns {{Vector2, Vector2}}
 */
function _getCollinearEdgePoints(mesh, edge, worldTransform) {
    mesh.getVertPos(edge.v.id, vec2Buffer)
    const fromX = vec2Buffer.x
    const fromY = vec2Buffer.y
    mesh.getVertPos(edge.w.id, vec2Buffer)
    const toX = vec2Buffer.x
    const toY = vec2Buffer.y
    const vec2From = new Vector2(fromX, fromY)
    const vec2To = new Vector2(toX, toY)
    worldTransform.xform(vec2From, vec2From)
    worldTransform.xform(vec2To, vec2To)
    return {q1: vec2From, q2: vec2To}
}

/**
 * @param {Mesh} mesh
 * @param {Rect2} bbox
 * @param {Transform2D} worldTransform
 * @returns {Vertex[]}
 */
export function _searchVertices(mesh, bbox, worldTransform) {
    const vertices = []
    for (const vertex of mesh.vertices) {
        const dummy = mesh.getVertPos(vertex.id)
        const pos = worldTransform.xform(dummy)
        if (bbox.contains(pos)) vertices.push(vertex)
    }
    return vertices
}
