export const CUSTOM_EVENT_NAMES = {
  DEV_ERROR_MSG: 'DEV_ERROR_MSG',
  USER_ERROR_MSG: 'USER_ERROR_MSG',

  NATS_CONNECT_CLOSED: 'NATS_CONNECT_CLOSED',
  NATS_CONNECTED: 'NATS_CONNECTED',
  NATS_CONNECT_ERROR: 'NATS_CONNECT_ERROR',
  NATS_IS_SYNCED: 'NATS_IS_SYNCED',

  COUNT_TO_ZERO: 'COUNT_TO_ZERO',

  REDIRECT_TO_ERROR_PAGE: 'REDIRECT_TO_ERROR_PAGE'
} as const

export type CustomEventName = keyof typeof CUSTOM_EVENT_NAMES
