export default class Encoder {
    constructor() {}

    /**
     * Get estimated size of the exported data
     * @abstract
     */
    static getEstimatedSize() {}

    /**
     * Add frame data to encoder
     * @abstract
     */
    addFrame() {}

    /**
     * Get encoder data
     * @abstract
     * @returns {Uint8Array} bytes
     */
    getData() {}

    /**
     * Finish adding the last frame to encoder
     * @abstract
     */
    finalize() {}

    /**
     * End of the encoder
     * @abstract
     */
    end() {}

    /**
     * Cancel encoder
     * @abstract
     */
    cancel() {}
}