import { FrameType } from '@phase-software/types'

const keyMapComponentName = {
  motionPath: 'translate',
  rotation: 'rotation',
  opacity: 'opacity',
  blendMode: 'opacity',
  width: 'dimensions',
  height: 'dimensions',
  contentAnchor: 'contentAnchor',
  scaleX: 'scale',
  scaleY: 'scale',
  skewX: 'skew',
  skewY: 'skew',
  cornerRadius: 'cornerRadius',
  pathMorphing: 'pathMorphing'
}
const getComponentNameByPropKey = (key) => {
  return keyMapComponentName[key]
}

const KEY_INDEXS = {
  position: {
    x: 0,
    y: 1
  }
}

const PROP_INDEXS = {
  position: {
    0: 'x',
    1: '1'
  },
  dimensions: {
    0: 'width',
    1: 'height'
  },
  contentAnchor: {
    0: 'contentAnchorX',
    1: 'contentAnchorY'
  },
  referencePoint: {
    0: 'referencePointX',
    1: 'referencePointY'
  }
}

export const getBaseValue = (elementTrack, key, dataStore) => {
  const isLayerProp = key.includes('.')
  if (isLayerProp) {
    const [layerTrackKey, propKey] = key.split('.')
    const layerTrackId = elementTrack.propertyTrackMap.get(layerTrackKey)
    const layerTrack = dataStore.interaction.getPropertyTrack(layerTrackId)
    const layer = dataStore.library.getLayer(layerTrack.key)
    if (layer) {
      if (['opacity', 'blendMode', 'paint'].includes(propKey)) {
        // should extract `opacity` and `blendMode` as individual track
        if (propKey !== 'paint') {
          return layer.paint[propKey]
        }
        return layer.paint
      }
      return layer[propKey]
    } else {
      // non-base layer
      return undefined
    }
  }

  return getComponentValue(elementTrack, key, dataStore)
}

export const getComponentValue = (elementTrack, key, dataStore) => {
  const element = dataStore.getById(elementTrack.elementId)
  const componentKey = getComponentNameByPropKey(key)
  let value
  if (componentKey === 'pathMorphing') {
    value = element.get('geometry').get('mesh').vertices
    // FIXME: (motion-path) should not have position
  } else if (componentKey === 'position') {
    const translateComponent = dataStore.library.getComponent(element.base.translate)
    const referencePointComponent = dataStore.library.getComponent(element.base.referencePoint)
    const contentAnchorComponent = dataStore.library.getComponent(element.base.contentAnchor)
    if (!referencePointComponent || !contentAnchorComponent) {
      return value
    }

    const keyIdx = KEY_INDEXS.position[key]
    const referencePoint = referencePointComponent[PROP_INDEXS.referencePoint[keyIdx]]
    const contentAnchor = contentAnchorComponent[PROP_INDEXS.contentAnchor[keyIdx]]
    value = translateComponent[key] - referencePoint - contentAnchor
  } else {
    const componentId = element.base[componentKey]
    const component = dataStore.library.getComponent(componentId)
    value = component[key]
  }

  return value
}

export const getSegmentList = (keyFrameList, baseValue) => {
  return keyFrameList.reduce((segmentList, kf, idx, arr) => {
    let prev = { time: 0, value: baseValue }
    if (idx) {
      const { time, value, frameType, ref } = arr[idx - 1]
      const prevValue = frameType === FrameType.INITIAL ? baseValue : ref || value
      prev = { time, value: prevValue }
    }
    const currValue = kf.frameType === FrameType.INITIAL ? baseValue : kf.ref || kf.value
    if (prev.value === currValue) {
      return segmentList
    }
    if (!segmentList.length) {
      if (kf.time !== prev.time) {
        segmentList.push([prev.time, kf.time])
      }
      return segmentList
    } else {
      const lastSegment = segmentList[segmentList.length - 1]
      if (lastSegment[1] === prev.time) {
        lastSegment[1] = kf.time
        return segmentList
      } else {
        segmentList.push([prev.time, kf.time])
        return segmentList
      }
    }
  }, [])
}

export const mergeSegmentList = (segmentList) => {
  const list = segmentList.sort((a, b) => a[0] - b[0])
  return list.reduce((acc, cur) => {
    if (!acc.length) {
      acc.push([...cur])
    }
    const currSeg = acc[acc.length - 1]
    if (cur[0] <= currSeg[1]) {
      currSeg[1] = Math.max(cur[1], currSeg[1])
    } else {
      acc.push([...cur])
    }
    return acc
  }, [])
}
