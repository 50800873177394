export const State = {
  PREVIEW: 'PREVIEW',
  EDITING: 'EDITING'
}

export const ElementType = {
  SCREEN: 'SCREEN',
  ELEMENT: 'ELEMENT',
  TEXT: 'TEXT',
  PATH: 'PATH',
  GROUP: 'GROUP',
  GEOMETRY_GROUP: 'GEOMETRY_GROUP',
  SCROLL_GROUP: 'SCROLL_GROUP'
}

export const StyleType = {
  STYLE: 'STYLE',
  GROUP_STYLE: 'GROUP_STYLE',
  TEXT_STYLE: 'TEXT_STYLE'
}

export const PaintType = {
  SOLID: 'SOLID',
  GRADIENT: 'GRADIENT',
  IMAGE: 'IMAGE'
}

export const GradientType = {
  LINEAR: 'LINEAR',
  RADIAL: 'RADIAL',
  ANGULAR: 'ANGULAR',
  DIAMOND: 'DIAMOND'
}

export const LayerType = {
  FILL: 'FILL',
  STROKE: 'STROKE',
  SHADOW: 'SHADOW',
  INNER_SHADOW: 'INNER_SHADOW',
  EFFECT: 'EFFECT'
}

export const GeometryType = {
  RECTANGLE: 'RECTANGLE',
  ELLIPSE: 'ELLIPSE',
  POLYGON: 'POLYGON'
}

export const NavigateType = {
  SCREEN: 'screen',
  URL: 'url'
}
