export const EffectTypeFeature = {
  EFFECT_NONE: 'EFFECT_NONE',
  EFFECT_CUSTOM: 'EFFECT_CUSTOM',
  EFFECT_PAINT_OVER_TRANSPARENT: 'EFFECT_PAINT_OVER_TRANSPARENT',
  EFFECT_TINT: 'EFFECT_TINT',
  EFFECT_FILL: 'EFFECT_FILL',
  EFFECT_STROKE: 'EFFECT_STROKE',
  EFFECT_TRITONE: 'EFFECT_TRITONE',
  EFFECT_PRO_LEVELS: 'EFFECT_PRO_LEVELS',
  EFFECT_DROP_SHADOW: 'EFFECT_DROP_SHADOW',
  EFFECT_RADIAL_WIPE: 'EFFECT_RADIAL_WIPE',
  EFFECT_DISPLACEMENT_MAP: 'EFFECT_DISPLACEMENT_MAP',
  EFFECT_MATTE3: 'EFFECT_MATTE3',
  EFFECT_GAUSSIAN_BLUR: 'EFFECT_GAUSSIAN_BLUR',
  EFFECT_MESH_WRAP: 'EFFECT_MESH_WRAP',
  EFFECT_WAVY: 'EFFECT_WAVY',
  EFFECT_SPHERIZE: 'EFFECT_SPHERIZE',
  EFFECT_PUPPET: 'EFFECT_PUPPET'
}

export const EffectFeature = {
  ...EffectTypeFeature
}
