import React, { useCallback, useEffect, useMemo, useState } from 'react'
// @ts-ignore
import { TM_PROPS_MAP, TRANSITION_STATUS } from '@phase-software/transition-manager'

import { useTransitionManager, useTransitionManagerActions } from '../../../providers/TransitionManagerProvider'
import { useDataStore, useDataStoreActions } from '../../../providers/dataStore/DataStoreProvider'
import { convertMsToSeconds, formatSecondsWithDecimals } from '../../../utils/formatter'
import { Slider } from '../../shared'
import PlayBackControlButton from '../components/PlayBackControlButton'
import SpeedControlButton from '../components/SpeedControlButton'

const ProgressBarPlaybackControl = () => {
  return (
    <div className="flex items-center gap-x-4">
      <PlayControl />
      <LoopControl />
      <SpeedControl />
      <div className="border-r border-light-overlay-15 mx-12 h-16"></div>
      <ProgressBar />
    </div>
  )
}

export default ProgressBarPlaybackControl

const PlayControl = () => {
  const status = useTransitionManager((o) => o.status)
  const { playAnimation, stopAnimation } = useTransitionManagerActions()

  const isPlaying = status === TRANSITION_STATUS.START

  const togglePlayStatus = useCallback(() => {
    isPlaying ? stopAnimation() : playAnimation()
  }, [isPlaying, stopAnimation, playAnimation])

  return <PlayBackControlButton variant="play" isActive={isPlaying} onChange={togglePlayStatus} />
}

const LoopControl = () => {
  const { loop } = useTransitionManager()
  const { setViewerLoop } = useTransitionManagerActions()

  const toggleLoopStatus = useCallback(() => {
    setViewerLoop(!loop)
  }, [loop, setViewerLoop])

  return <PlayBackControlButton variant="loop" isActive={loop} onChange={toggleLoopStatus} />
}

const SpeedControl = () => {
  const { playbackRate } = useTransitionManager()
  const { setViewerPlaybackRate } = useTransitionManagerActions()

  const handleSpeedControl = useCallback(
    (speed: number) => {
      setViewerPlaybackRate(speed)
    },
    [setViewerPlaybackRate]
  )

  return <SpeedControlButton value={playbackRate} onChange={handleSpeedControl} />
}

const ProgressBar = () => {
  const dataStore = useDataStore()
  const { endTime } = useTransitionManager()
  const { setPlayheadTime } = useDataStoreActions()

  const [currentTime, setCurrentTime] = useState(dataStore.transition.currentTime || 0)

  const duration = useMemo(() => formatSecondsWithDecimals(convertMsToSeconds(endTime), true), [endTime])
  const playheadTime = useMemo(() => formatSecondsWithDecimals(convertMsToSeconds(currentTime), true), [currentTime])

  const handleCurrentTimeChange = useCallback(
    (value: number) => {
      setPlayheadTime(String(value))
    },
    [setPlayheadTime]
  )

  useEffect(() => {
    if (dataStore) {
      dataStore.transition.on(TM_PROPS_MAP.viewerTime, setCurrentTime)
    }
    return () => {
      if (dataStore) {
        dataStore.transition.off(TM_PROPS_MAP.viewerTime, setCurrentTime)
      }
    }
  }, [dataStore, setCurrentTime])

  return (
    <div className="grid gap-x-12 mr-4 grid-flow-col grid-cols-[40px,180px,1fr] items-center">
      <div className="text-center">{playheadTime}</div>
      <Slider
        max={endTime}
        min={0}
        noIS
        onChange={handleCurrentTimeChange}
        step={1}
        value={currentTime}
        variant="player"
        dataTestId="progress-bar-indicator"
      />
      <div>{duration}</div>
    </div>
  )
}
