import React from 'react'
import CustomContentLoader from './CustomContentLoader'

// file card loading
const FileLoading = () => {
  return (
    <div className="mb-32">
      <CustomContentLoader width="100%" height="100%" viewBox="0 0 304 292">
        <rect width="304" height="228" rx="10" />
        <rect y="244" width="239.37" height="16" rx="5" />
        <rect y="276" width="124.472" height="16" rx="5" />
      </CustomContentLoader>
    </div>
  )
}

export default FileLoading
