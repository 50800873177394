import React from 'react'
import LockOverlay from '../shared/LockOverlay'
import { useUI } from '../../providers/dataStore/UIProvider'

const Overlay = () => {
  const isTargeting = useUI((o) => o.isTargeting)
  return <LockOverlay enabled={isTargeting} />
}

export default Overlay
