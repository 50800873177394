import { LayerType } from '@phase-software/types'
import Shadow from './Shadow'

export default class InnerShadow extends Shadow {
    _init() {
        super._init()
        this.layerType = LayerType.INNER_SHADOW
        this._props.paintId = ''
    }
}

/** @typedef {import('./Shadow').ShadowData} ShadowData */

/**
 * @typedef {ShadowData} InnerShadowData
 */
