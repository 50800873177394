import React, { useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { Transition, TransitionStatus } from 'react-transition-group'
import Shortcut from './Shortcut'

const BOUNDING_SIZE = 8
const TRANSITION_DURATION = 300

export type TooltipProps = {
  content?: string | { text: string; shortcut?: string } | null
  customContent?: React.ReactNode
  type?: 'normal' | 'warning'
  offset?: number
  children: React.ReactElement
  visibleOnOverflow?: boolean
  hide?: boolean
}

const Tooltip = ({
  content,
  customContent,
  type = 'normal',
  offset = 8,
  children,
  visibleOnOverflow = false,
  hide = false
}: TooltipProps) => {
  const ref = useRef<HTMLDivElement>(null)

  const [isVisible, setIsVisible] = useState(false)
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)

  const text = typeof content === 'string' ? content : content?.text
  const shortcut = typeof content === 'string' ? undefined : content?.shortcut

  const originalOnMouseEnter = React.Children.only(children).props.onMouseEnter
  const originalOnMouseLeave = React.Children.only(children).props.onMouseLeave
  const originalOnFocus = React.Children.only(children).props.onFocus
  const originalOnMouseDown = React.Children.only(children).props.onMouseDown

  const handleMouseEnter = (e: MouseEvent) => {
    if (originalOnMouseEnter) {
      originalOnMouseEnter(e)
    }
    const target = e.currentTarget as HTMLElement
    const isOverflow = target.scrollWidth > target.clientWidth
    if (visibleOnOverflow && !isOverflow) return
    setIsVisible(true)
    setAnchorElement(target)
  }

  const adjustPosition = () => {
    if (!ref.current || !anchorElement) return

    const { clientWidth } = document.documentElement

    const tooltipHeight = ref.current.clientHeight
    const tooltipWidth = ref.current.clientWidth

    const { top, left, width, height } = anchorElement.getBoundingClientRect()

    const calculatedPosition: {
      top?: string
      left?: string
      right?: string
    } = {
      left: 'auto',
      right: 'auto'
    }

    if (top - offset - tooltipHeight < BOUNDING_SIZE) {
      // show tooltip below target
      calculatedPosition.top = `${top + height + offset + 4}px`
    } else {
      // show tooltip above target
      calculatedPosition.top = `${top - offset - tooltipHeight + 3}px`
    }

    calculatedPosition.left = `${left + width / 2}px`

    if (left + width / 2 < tooltipWidth / 2) {
      calculatedPosition.left = `${BOUNDING_SIZE + tooltipWidth / 2}px`
    }
    if (left + width / 2 + tooltipWidth / 2 > clientWidth - BOUNDING_SIZE) {
      calculatedPosition.left = 'auto'
      calculatedPosition.right = `${BOUNDING_SIZE - tooltipWidth / 2}px`
    }

    Object.assign(ref.current.style, calculatedPosition)
  }

  const handleMouseLeave = (e: MouseEvent) => {
    if (originalOnMouseLeave) {
      originalOnMouseLeave(e)
    }
    setIsVisible(false)
  }

  const handleFocus = (e: FocusEvent) => {
    if (originalOnFocus) {
      originalOnFocus(e)
    }
    setIsVisible(false)
  }

  const handleMouseDown = (e: MouseEvent) => {
    if (originalOnMouseDown) {
      originalOnMouseDown(e)
    }
    setIsVisible(false)
  }

  const renderContent = (transitionState: TransitionStatus) => {
    const transitionStyles: { [key in TransitionStatus]?: React.CSSProperties } = {
      entering: { opacity: 1, transform: 'translate(-50%, -4px)' },
      entered: { opacity: 1, transform: 'translate(-50%, -4px)' },
      exiting: { opacity: 0, transform: 'translate(-50%, 0)' },
      exited: { opacity: 0, transform: 'translate(-50%, 0)' }
    }

    const typeStyles = {
      warning: 'bg-warning-50 text-dark-overlay-80',
      normal: 'bg-black text-white'
    }

    return (
      <div
        ref={ref}
        className={`absolute w-max max-w-[368px] break-words leading-[16px] px-8 py-4 rounded-sm z-40 outline-neutral-1 drop-shadow-dark-overlay-40-0-1-2 pointer-events-none ${typeStyles[type]}`}
        style={{
          ...transitionStyles[transitionState],
          transition: `opacity ${TRANSITION_DURATION}ms ease-out, transform ${TRANSITION_DURATION}ms ease-out`,
          willChange: 'opacity, transform',
          wordBreak: 'break-word'
        }}
      >
        <div className="flex text-[11px]">
          <div>{text}</div>
          {shortcut && <Shortcut shortcut={shortcut} />}
          {customContent}
        </div>
      </div>
    )
  }

  return (
    <>
      {React.cloneElement(React.Children.only(children), {
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave,
        onBlur: handleMouseLeave,
        onFocus: handleFocus,
        onMouseDown: handleMouseDown
      })}
      <Transition
        in={isVisible && !!anchorElement && !hide && !!text}
        timeout={TRANSITION_DURATION}
        mountOnEnter
        unmountOnExit
        onEntering={adjustPosition}
      >
        {(state) => ReactDOM.createPortal(renderContent(state), document.querySelector('#modal') || document.body)}
      </Transition>
    </>
  )
}

export default Tooltip
