import React from 'react';
import ContentLoader, { IContentLoaderProps } from 'react-content-loader';

const CustomContentLoader: React.FC<IContentLoaderProps> = ({
  speed = 2,
  backgroundColor = 'rgba(255, 255, 255, 0.1)',
  foregroundColor = 'rgba(255, 255, 255, 0.2)',
  preserveAspectRatio = 'xMinYMin meet',
  backgroundOpacity = 1,
  foregroundOpacity = 0.7,
  ...props
}) => {
  return (
    <ContentLoader
      speed={speed}
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
      preserveAspectRatio={preserveAspectRatio}
      backgroundOpacity={backgroundOpacity}
      foregroundOpacity={foregroundOpacity}
      {...props}
    />
  );
};

export default CustomContentLoader;
