import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { CUSTOM_EVENT_NAMES } from '../constants'
import { useSetNotification } from '../providers/NotificationProvider'

const GlobalNotificationMessage = ({ children }: { children: React.ReactNode }) => {
  const { addNotification, removeNotification } = useSetNotification()
  const { t } = useTranslation()
  const devErrorMsgId = useRef<string>()
  const userErrorMsgId = useRef<string>()

  useEffect(() => {
    const handleDevErrorMsg = (e: any) => {
      devErrorMsgId.current = addNotification({
        id: devErrorMsgId.current,
        content: e.detail.message
      })
    }
    const handleUserErrorMsg = (e: any) => {
      userErrorMsgId.current = addNotification({
        id: userErrorMsgId.current,
        content: t(e.detail.message)
      })
    }

    document.addEventListener(CUSTOM_EVENT_NAMES.DEV_ERROR_MSG, handleDevErrorMsg)
    document.addEventListener(CUSTOM_EVENT_NAMES.USER_ERROR_MSG, handleUserErrorMsg)

    return () => {
      document.removeEventListener(CUSTOM_EVENT_NAMES.DEV_ERROR_MSG, handleDevErrorMsg)
      document.removeEventListener(CUSTOM_EVENT_NAMES.USER_ERROR_MSG, handleUserErrorMsg)
      if (devErrorMsgId.current) {
        removeNotification(devErrorMsgId.current)
        devErrorMsgId.current = undefined
      }
      if (userErrorMsgId.current) {
        removeNotification(userErrorMsgId.current)
        userErrorMsgId.current = undefined
      }
    }
  }, [addNotification, removeNotification, t])

  return children
}

export default GlobalNotificationMessage
