import { Vector2 } from './Vector2'
import { Range } from './Range'

/** @typedef {import('./Vector2').Vector2Like} Vector2Like */

/**
 * Rectangle object is an area defined by its position, as indicated by its top-left corner
 * point (x, y) and by its width and its height.
 * @param {number} [x=0] - The X coordinate of the upper-left corner of the rectangle
 * @param {number} [y=0] - The Y coordinate of the upper-left corner of the rectangle
 * @param {number} [width=0] - The overall width of this rectangle
 * @param {number} [height=0] - The overall height of this rectangle
 * @param {number} [rotation=0] - The overall rotation of this rectangle
 */
export class RotatedRect2 {
    x = 0
    y = 0
    w = 0
    h = 0
    r = 0

    /**
     * @param {number} x
     * @param {number} y
     * @param {number} width
     * @param {number} height
     * @param {number} rotation
     * @returns {RotatedRect2}
     */
    static initN(x, y, width, height, rotation) {
        const rr = new RotatedRect2()
        rr.x = x
        rr.y = y
        rr.w = width
        rr.h = height
        rr.r = rotation
        return rr
    }

    /**
     * @param {Rect2} rect
     * @returns {RotatedRect2}
     */
    static initWithRect2(rect) {
        const rr = new RotatedRect2()
        rr.x = rect.actualLeft()
        rr.y = rect.actualTop()
        rr.w = Math.abs(rect.w)
        rr.h = Math.abs(rect.h)
        return rr
    }

    /**
     * @param {number} x
     * @param {number} y
     * @param {number} width
     * @param {number} height
     * @param {number} rotation
     * @returns {RotatedRect2}
     */
    set(x, y, width, height, rotation = 0) {
        this.x = x
        this.y = y
        this.w = width
        this.h = height
        this.r = rotation
        return this
    }

    setP(x, y) {
        this.x = x
        this.y = y
        return this
    }

    center() {
        const center = Vector2.initN(this.w * 0.5, this.h * 0.5).rotate(this.r)
        center.x += this.x
        center.y += this.y
        return center
    }

    /**
     * @param {Vector2} axis
     * @returns {RotatedRect2}
     */
    project(axis) {
        const v = new Vector2()
        let p = 0
        let min = 0
        let max = 0

        v.set(this.w, 0).rotate(this.r).add(this.x, this.y)
        p = axis.dot(v)
        min = p
        max = p

        v.set(0, this.h).rotate(this.r).add(this.x, this.y)
        p = axis.dot(v)
        min = Math.min(min, p)
        max = Math.max(max, p)

        v.set(this.w, this.h).rotate(this.r).add(this.x, this.y)
        p = axis.dot(v)
        min = Math.min(min, p)
        max = Math.max(max, p)

        v.set(0, 0).rotate(this.r).add(this.x, this.y)
        p = axis.dot(v)
        min = Math.min(min, p)
        max = Math.max(max, p)

        return Range.initN(min, max)
    }

    /**
     * @param {RotatedRect2} other
     * @returns {RotatedRect2}
     */
    overlaps(other) {
        const axis = new Vector2()
        axis.set(1, 0).rotate(this.r)
        if (!this.project(axis).overlaps(other.project(axis))) {
            return false
        }
        axis.set(0, 1).rotate(this.r)
        if (!this.project(axis).overlaps(other.project(axis))) {
            return false
        }
        axis.set(1, 0).rotate(other.r)
        if (!this.project(axis).overlaps(other.project(axis))) {
            return false
        }
        axis.set(0, 1).rotate(other.r)
        if (!this.project(axis).overlaps(other.project(axis))) {
            return false
        }
        return true
    }
}