import React, { useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { LayerType, Mode } from '@phase-software/types'

import { useScrollIntoView } from '../../../../providers/ScrollProvider/useScrollIntoView'
import { useDataStoreActions } from '../../../../providers/dataStore/DataStoreProvider'
import { useEditor, useEditorActions } from '../../../../providers/dataStore/EditorProvider'
import { useFill } from '../../../../providers/dataStore/FillProvider'
import { useUI } from '../../../../providers/dataStore/UIProvider'
import { WideTitle } from '../../../shared'
import Content from '../../../shared/Content'
import MixContent from '../MixContent'
import Fill from './Fill'

const FillEditor = () => {
  const ref = useRef<HTMLDivElement>(null)
  const mode = useUI((o) => o.mode)
  const editor = useEditor()
  const fillList = useMemo(() => editor.fillList || [], [editor.fillList])
  const firstFillId = fillList[0]
  const isMix = useFill((o) => !!o[firstFillId]?.isMix)
  const notEditable = isMix && mode !== Mode.DESIGN
  const { addLayer, setLayerFocused } = useEditorActions()
  const { commitUndo } = useDataStoreActions()
  const { activateScroll, handleAutoScroll } = useScrollIntoView()
  const { t } = useTranslation('file', { keyPrefix: 'property_editor.fill_editor' })

  const handleAdd = () => {
    // if the paint modal opened with gradient PaintTypes, needs to set focus layer null before add new a layer
    setLayerFocused(null, false)
    addLayer(LayerType.FILL)
    commitUndo()
    activateScroll()
  }

  useEffect(() => {
    handleAutoScroll(ref?.current?.children[0])
  }, [fillList, handleAutoScroll])

  return (
    <>
      <WideTitle
        icons={[{ name: 'Plus', dataTestId: 'fill-plus-icon', onClick: handleAdd }]}
        disabled={notEditable}
        dividerPosition="top"
      >
        {t('title')}
      </WideTitle>
      <Content ref={ref} withHeader>
        {isMix ? (
          <MixContent notEditable={notEditable} />
        ) : (
          fillList
            .slice()
            .reverse()
            .map((id: string, idx: number) => <Fill key={idx} id={id} />)
        )}
      </Content>
    </>
  )
}
export default React.memo(FillEditor)
