/**
 * Wait one frame to get perfect pixels.
 * @returns {Promise}
 */
export const waitFrame = () => new Promise(resolve => requestAnimationFrame(resolve))


/**
 * Rounds a number to the specified number of decimal places.
 *
 * @param {number} number - The number to be rounded.
 * @param {number} decimalPlaces - The number of decimal places to round to (default: 0).
 * @returns {number} The rounded number.
 */
export const roundToDecimalPlaces = (number, decimalPlaces = 0) => {
    const factor = Math.pow(10, decimalPlaces)
    return Math.round(number * factor) / factor
}
