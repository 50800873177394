import React from 'react'
import { useTranslation } from 'react-i18next'

import { TextArea } from '../shared'
import Field from './Field'

type DescriptionFieldProps = {
  value: string
  onChange: (value: string) => void
  size?: 'normal' | 'full'
}

const DescriptionField = ({ value, onChange, ...rest }: DescriptionFieldProps) => {
  const { t } = useTranslation('setting')
  return (
    <Field label={t('team_setting.description')} {...rest}>
      <TextArea
        data-test-id="description-input-edit"
        placeholder={t('team_setting.description_placeholder')}
        defaultValue={value ?? ''}
        onChangeValue={onChange}
        rows={4}
      />
    </Field>
  )
}
export default DescriptionField
