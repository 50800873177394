import { Vector2 } from './Vector2'
import { isNull } from './commons'

export class Circle {
    /**
     * @param  {(number|Vector2)} x
     * @param  {number} [y]
     * @param  {number} radius
     */
    constructor(x, y, radius) {
        this.point = isNaN(x) ? x : new Vector2(x, y)
        this.radius = isNaN(x) ? y : radius
        this.radiusSquared = this.radius * this.radius
    }

    /**
     * Check if this Circle contains a point
     * @param  {(number|Vector2)} x    x coordinate or whole point as vec2
     * @param  {number} [y]         y coordinate
     * @returns {boolean}           true if point is inside (or on the boundary) of the AABB
     */
    containsPoint(x, y) {
        let xx = x,
            yy = y
        if (!isNull(x.x)) {
            xx = x.x
            yy = x.y
        }
        return Math.pow(xx - this.point.x, 2) + Math.pow(yy - this.point.y, 2) <= this.radiusSquared
    }

    get x() {
        return this.point.x
    }

    get y() {
        return this.point.y
    }
}
