import { createContext } from 'react'

/**
 * Currently only used to disable scrollIntoView when area selecting keyframes
 * TODO:
 *  - SelectionContext maintains the TrackInfoList selected item
 *  - ScrollContext maintains scrollLeft and scrollTop value from the TrackInfoList, TrackKeyFrameList and Ruler
 */
export type ScrollContextProp = {
  disableScrollIntoView: boolean
}
export const ScrollContext = createContext<ScrollContextProp>({
  disableScrollIntoView: false
})

export type ScrollActionContextProp = {
  setDisableScrollIntoView: (disableScrollIntoView: boolean) => void
}
export const ScrollActionContext = createContext<ScrollActionContextProp>({
  setDisableScrollIntoView: () => {}
})
