import React, { FC, HTMLAttributes, useEffect, useRef } from 'react'

import styled from 'styled-components'

import FocusLoop from '../FocusLoop'
import { color } from '../utils'

interface Tab {
  title?: string
  tag?: number
}

const TabHighlight = styled.div`
  position: absolute;
  height: 2px;
  left: 0;
  bottom: -1px;
  background: ${color('primary', 50)};
  transition: width 0.3s ease, transform 0.3s ease;
`

type TabListProps = Omit<HTMLAttributes<HTMLDivElement>, 'onSelect'> & {
  center?: boolean
  tabs?: Array<Tab>
  size?: string
  activeIdx: number
  onSelect?: (index: number) => void
  disabled?: boolean
}

const TabList: FC<TabListProps> = ({
  center = false,
  tabs = [],
  size = 'l',
  activeIdx = 0,
  onSelect = () => {},
  disabled = false
}) => {
  const tabListRef = useRef<HTMLFormElement>(null)
  const tabHighlightRef = useRef<HTMLDivElement>(null)
  const alignment = center ? 'justify-center' : ''
  const offset = size === 'l' ? 'h-56 px-24 text-14' : 'h-32 px-16 text-12'
  const cornerRadius = size === 'l' ? 'rounded-t-lg' : 'rounded-t-md'
  const dim = disabled ? 'opacity-20' : ''
  const hover = disabled ? '' : 'hover:text-white'

  const handleClick = (index: number) => {
    if (disabled) return
    onSelect(index)
  }
  const handleKeyDown = (index: number, e: React.KeyboardEvent<HTMLButtonElement>) => {
    switch (e.key) {
      case ' ':
        onSelect(index)
        break
      case 'Escape':
        ;(document.activeElement as HTMLElement)?.blur()
        break
    }
  }

  useEffect(() => {
    if (tabListRef.current && tabHighlightRef.current) {
      const tab = tabListRef.current.children[activeIdx]
      if (tab instanceof HTMLElement) {
        Object.assign(tabHighlightRef.current.style, {
          width: `${tab.offsetWidth}px`,
          transform: `translateX(${tab.offsetLeft}px)`
        })
      }
    }
  }, [tabListRef, tabHighlightRef, activeIdx])

  return (
    <div className={`w-full flex ${alignment} cursor-pointer border-b border-neutral-80 ${dim} relative`}>
      <FocusLoop ref={tabListRef} className="flex">
        {tabs.map((tab: Tab, index: number) => {
          const highlight = activeIdx === index ? 'text-white font-medium' : ''
          return (
            <button
              key={index}
              tabIndex={index + 1}
              disabled={disabled}
              onClick={() => handleClick(index)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              className={`flex relative justify-center items-center border border-transparent -mb-1 focus-visible:border-primary ${offset} ${cornerRadius} ${highlight} ${hover}`}
            >
              {tab.title}
              {typeof tab?.tag === 'number' ? (
                <div className="text-white text-12 ml-8 p-4 bg-light-overlay-10 rounded-3">{tab.tag}</div>
              ) : null}
            </button>
          )
        })}
      </FocusLoop>
      <TabHighlight ref={tabHighlightRef} />
    </div>
  )
}

export default TabList
