import React, { Key, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { Checkbox, Icon, Radio, ScrollView } from '../index'

const SELECT_WIDTH = 32

export enum SortOrder {
  // eslint-disable-next-line no-unused-vars
  ASCEND = 'ascend',
  // eslint-disable-next-line no-unused-vars
  DESCEND = 'descend'
}

type Sorter = { field: string; order: SortOrder }

interface Column<T> {
  title: string
  key: string
  render: (value: T, index: number) => React.ReactNode | string
  align?: 'left' | 'right'
  /**
   * if backend sort, use true, if frontend sort, use compare function
   */
  sorter?: true | ((a: T, b: T) => number)
  /**
   * When backends sorting, this state should be externally controlled
   */
  sortOrder?: SortOrder
  width?: number
  flex?: number
}

interface TableProps<T> {
  rowKey: keyof T
  dataSource: T[]
  columns: Column<T>[]
  rowSelection?: {
    onChange: (selectedRowKeys: T[keyof T][], selectedRows: T[]) => void
    type: 'checkbox' | 'radio'
  }
  className?: string
  onSortChange?: (sorter?: Sorter) => void
  size?: 's' | 'l'
  width?: number
  fixedHeader?: boolean
}
const fixHeaderStyle = `fixed bg-neutral-90 z-1`

function Table<T>({
  rowKey,
  dataSource,
  columns,
  onSortChange,
  className = '',
  rowSelection,
  size = 'l',
  width,
  fixedHeader = false
}: TableProps<T>) {
  const containerRef = useRef<HTMLDivElement>(null)

  const [sortedDataSource, setSortedDataSource] = useState<T[]>([])
  const [selected, setSelected] = useState<T[]>([])
  const [currentSorter, setCurrentSorter] = useState<Sorter | undefined>()
  const [cellWidths, setCellWidths] = useState<number[]>([])

  const isAllSelected = selected.length === dataSource.length
  const isPartialSelected = selected.length < dataSource.length && selected.length > 0
  const isLarge = size === 'l'

  const selectedRowKeys = useMemo(() => selected.map((row) => row[rowKey]), [selected, rowKey])

  const handleSorter = useCallback(
    (column: Column<T>, sort: Sorter | undefined) => {
      setCurrentSorter(sort)
      onSortChange?.(sort)
      if (typeof column.sorter === 'function') {
        if (!sort) {
          setSortedDataSource(dataSource)
          return
        }
        const sortedDataResource = [...dataSource]
        sortedDataResource.sort(column.sorter)
        if (sort.order === SortOrder.DESCEND) {
          sortedDataResource.reverse()
        }
        setSortedDataSource(sortedDataResource)
      }
    },
    [dataSource, onSortChange]
  )

  const onAllSelectedChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSelected(e.target.checked ? dataSource : [])
    },
    [dataSource]
  )

  const onSelectedChanged = useCallback(
    (item: T) => {
      const isSelected = selectedRowKeys.includes(item[rowKey])
      if (rowSelection?.type === 'checkbox') {
        setSelected((pre) => (isSelected ? pre.filter((sel) => sel[rowKey] !== item[rowKey]) : pre.concat(item)))
      } else {
        setSelected(isSelected ? [] : [item])
      }
    },
    [rowSelection?.type, selectedRowKeys, rowKey]
  )

  const onSorter = useCallback(
    (column: Column<T>) => {
      if (!column.sorter) {
        return
      }
      let sort: Sorter | undefined
      if (!currentSorter || currentSorter.field !== column.key || currentSorter.order === SortOrder.ASCEND) {
        sort = { field: column.key, order: SortOrder.DESCEND }
      } else if (currentSorter.order === SortOrder.DESCEND) {
        sort = { field: column.key, order: SortOrder.ASCEND }
      }
      handleSorter(column, sort)
    },
    [currentSorter, handleSorter]
  )

  useEffect(() => {
    setSortedDataSource(dataSource)
  }, [dataSource])

  useEffect(() => {
    const sorterColumn = columns.find((column) => column.sorter && column.sortOrder)
    const sorter = sorterColumn ? { field: sorterColumn.key, order: sorterColumn.sortOrder! } : undefined
    setCurrentSorter(sorter)
    onSortChange?.(sorter)
    if (sorter) {
      handleSorter(sorterColumn!, sorter)
    }
  }, [columns, onSortChange, handleSorter])

  useEffect(() => {
    rowSelection?.onChange(selectedRowKeys, selected)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowSelection?.onChange, selectedRowKeys, selected])

  useEffect(() => {
    const calculateCellWidths = () => {
      if (containerRef.current?.clientWidth) {
        const cellWidth = containerRef.current.clientWidth > 800 ? 200 : 150
        let cellWidths = new Array(columns.length)
          .fill(0)
          .map((_, index) => (columns[index].flex != null ? 0 : columns[index].width ?? cellWidth))
        const restWidth =
          containerRef.current.clientWidth -
          cellWidths.reduce((pre, cur) => pre + cur, 0) -
          (rowSelection?.onChange ? SELECT_WIDTH : 0)
        const flexColumns = columns.filter((item) => item.flex != null)
        if (flexColumns.length > 0 && restWidth > 0) {
          const totalFlex = flexColumns.reduce((pre, cur) => pre + (cur.flex ?? 0), 0)
          cellWidths = cellWidths.map((item, index) =>
            item === 0 ? (restWidth * (columns[index].flex ?? 0)) / totalFlex : item
          )
        }
        setCellWidths(cellWidths)
      }
    }
    calculateCellWidths()
    window.addEventListener('resize', calculateCellWidths)
    return () => window.removeEventListener('resize', calculateCellWidths)
  }, [columns, containerRef, rowSelection?.onChange])

  const handleSortClick = useCallback(
    (index: number) => () => {
      onSorter(columns[index])
    },
    [onSorter, columns]
  )

  const handleKeyDown = useCallback(
    (index: number) => (event: React.KeyboardEvent<HTMLDivElement>) => {
      const item = columns[index]
      switch (event.key) {
        case 'Enter':
        case ' ':
          onSorter(item)
          break
        case 'Escape':
          event.currentTarget.blur()
          break
      }
    },
    [onSorter, columns]
  )

  return (
    <div className={`text-12 font-normal overflow-hidden ${className}`} style={{ width }} ref={containerRef}>
      <div
        className={`border-neutral-80 border-b flex ${fixedHeader ? fixHeaderStyle : ''} ${isLarge ? 'h-48' : 'h-32'}`}
      >
        {rowSelection?.onChange && (
          <div className="flex items-center justify-end h-full" style={{ width: SELECT_WIDTH }}>
            {rowSelection.type === 'checkbox' ? (
              <Checkbox checked={isPartialSelected ? 'mixed' : isAllSelected} onChange={onAllSelectedChanged} />
            ) : null}
          </div>
        )}
        {columns.map(({ key, align = 'left', title, sorter }, index) => (
          <div
            key={key}
            className={`text-light-overlay-60 px-16 h-full flex items-center ${align === 'right' ? 'justify-end' : ''}`}
            style={{ width: cellWidths[index] }}
          >
            <div
              tabIndex={sorter ? 0 : -1}
              className={`${
                sorter ? 'hover:text-white group focus-visible:rounded-sm focus-visible:outline-primary-1' : ''
              } ${sorter ? 'cursor-pointer' : 'cursor-default'} flex items-center`}
              onClick={handleSortClick(index)}
              onKeyDown={handleKeyDown(index)}
            >
              {title}
              {currentSorter?.field === key && currentSorter && (
                <Icon
                  size="s"
                  name={currentSorter?.order === SortOrder.ASCEND ? 'ArrowSortDown' : 'ArrowSortUp'}
                  className="group-hover:text-white"
                />
              )}
            </div>
          </div>
        ))}
      </div>
      {/* @ts-ignore TODO: fix after refactor of ScrollView */}
      <ScrollView
        className={`overflow-y-auto h-[calc(100%-48px)] ${fixedHeader ? `${isLarge ? 'mt-[48px]' : 'mt-[32px]'}` : ''}`}
      >
        {sortedDataSource.map((item) => (
          <div
            key={item[rowKey] as Key}
            className={`flex hover:bg-light-overlay-10 rounded-md  ${isLarge ? 'h-56' : 'h-40'}`}
          >
            {rowSelection?.onChange && (
              <div className="flex items-center justify-end h-full" style={{ width: SELECT_WIDTH }}>
                {rowSelection.type === 'checkbox' ? (
                  <Checkbox
                    tabIndex={0}
                    checked={selectedRowKeys.includes(item[rowKey])}
                    onChange={() => onSelectedChanged(item)}
                  />
                ) : (
                  <div className="relative left-8">
                    {/* @ts-ignore TODO: fix after refactor of Radio */}
                    <Radio
                      tabIndex={0}
                      checked={selectedRowKeys.includes(item[rowKey])}
                      onChange={() => onSelectedChanged(item)}
                    />
                  </div>
                )}
              </div>
            )}
            {columns.map((column, index) => (
              <div
                key={column.key}
                style={{ width: cellWidths[index] }}
                className={`text-white px-16 h-full flex items-center ${column.align === 'right' ? 'justify-end' : ''}`}
              >
                {column.render(item, index)}
              </div>
            ))}
          </div>
        ))}
      </ScrollView>
    </div>
  )
}

export default Table
