import React, { Ref, forwardRef } from 'react'
import { NavLink } from 'react-router-dom'

export type LinkSwitcherProps = {
  className?: string
  to?: string
  children?: React.ReactNode
  noUnderline?: boolean
  defaultFocusStyle?: boolean
  onClick?: (e: React.MouseEvent) => void
  tabIndex?: number
}

const getPredefinedClassName = (defaultFocusStyle: boolean, noUnderline: boolean) => {
  return `
  ${
    defaultFocusStyle
      ? 'focus-visible:outline-primary-1 focus-visible:rounded-sm active:border-none active:outline-none active:rounded-none'
      : ''
  } ${noUnderline ? '' : 'hover:underline'}`
}
// TODO: use tailwindcss text-underline-offset when updating to tailwindcss v3
const linkStyle = { textUnderlineOffset: '2px' }

const LinkSwitcher = forwardRef<HTMLSpanElement | HTMLAnchorElement | HTMLButtonElement, LinkSwitcherProps>(
  ({ className = '', to, onClick = null, noUnderline = false, children, defaultFocusStyle = true, ...rest }, ref) => {
    const handleKeyDown = (e: React.KeyboardEvent<HTMLAnchorElement | HTMLButtonElement>) => {
      switch (e.key) {
        case ' ':
        case 'Enter':
          e.preventDefault()
          e.currentTarget.click()
          break
        case 'Escape':
          e.currentTarget.blur()
          e.stopPropagation()
          break
        default:
          break
      }
    }
    if (onClick) {
      return (
        <button
          ref={ref as Ref<HTMLButtonElement>}
          className={`${getPredefinedClassName(defaultFocusStyle, noUnderline)} ${className}`}
          style={linkStyle}
          onClick={onClick}
          onKeyDown={handleKeyDown}
          {...rest}
        >
          {children}
        </button>
      )
    }

    if (!to) {
      return (
        <span ref={ref as Ref<HTMLSpanElement>} className={className} {...rest}>
          {children}
        </span>
      )
    }
    const isExternal = to.startsWith('http')
    return isExternal ? (
      <a
        ref={ref as Ref<HTMLAnchorElement>}
        href={to}
        target="_blank"
        rel="noreferrer noopener"
        className={`${getPredefinedClassName(defaultFocusStyle, noUnderline)} ${className}`}
        style={linkStyle}
        onKeyDown={handleKeyDown}
        {...rest}
      >
        {children}
      </a>
    ) : (
      <NavLink
        ref={ref as Ref<HTMLAnchorElement>}
        to={to}
        activeClassName="active"
        className={`${getPredefinedClassName(defaultFocusStyle, noUnderline)} ${className}`}
        style={linkStyle}
        onKeyDown={handleKeyDown}
        {...rest}
      >
        {children}
      </NavLink>
    )
  }
)

LinkSwitcher.displayName = 'LinkSwitcher'

export default React.memo(LinkSwitcher)
