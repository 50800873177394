import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useApolloClient } from '@apollo/client'
import { LoadingStatus } from '@phase-software/types'

import { Dialog, InputField, TextArea } from '../../../../components/shared'
import { InputFieldRefProps } from '../../../../components/shared/InputField'
import { VersionFieldsFragmentDoc } from '../../../../generated/graphql'
import useFileVersionActions from '../../../../hooks/useFileVersionActions'
import useHeapAnalytics from '../../../../hooks/useHeapAnalytics'
import { useWorkspaceContext } from '../../../../providers/WorkspaceContextProvider'
import { track } from '../../../../services/heapAnalytics'
import { VersionType } from '../../../../utils/transformVersion'
import { versionNameValidator } from '../../../../utils/validator'

type UpdateVersionDialogProps = {
  fileId: string
  isNamedVersion: boolean
  onClose: () => void
  open: boolean
  originalDescription: string
  originalName: string
  projectId: string
  versionId: string
}

const UpdateVersionDialog = ({
  fileId,
  isNamedVersion,
  onClose,
  open,
  originalDescription,
  originalName,
  projectId,
  versionId
}: UpdateVersionDialogProps) => {
  const client = useApolloClient()
  const { t } = useTranslation(['file', 'common'], { keyPrefix: 'version' })
  const { workspaceData } = useWorkspaceContext()
  const { updateVersion } = useFileVersionActions()
  const { space, teamName } = useHeapAnalytics()
  const location = projectId === workspaceData.draftProjectId ? 'drafts' : 'project'

  const nameInputRef = useRef<InputFieldRefProps>(null)
  const [name, setName] = useState(originalName)
  const [description, setDescription] = useState(originalDescription)
  const [hasError, setHasError] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)

  useEffect(() => {
    if (open && nameInputRef.current) {
      nameInputRef.current.focus()
    }
  }, [open])

  useEffect(() => {
    setName(originalName)
    setDescription(originalDescription)
  }, [originalDescription, originalName])

  const handleChangeName = (newName: string) => {
    setHasError(false)
    setName(newName)
  }

  const handleChangeDescription = (newDescription: string) => {
    setDescription(newDescription)
  }

  const validateInputs = () => {
    if (!nameInputRef.current) return
    const isValid = nameInputRef.current?.onValidate()
    setHasError(!isValid)
    return isValid
  }

  const handleUpdate = async () => {
    if (isUpdating || !validateInputs()) return
    setIsUpdating(true)

    try {
      await updateVersion({ projectId, fileId, versionId, name, description })

      client.cache.updateFragment(
        {
          id: `versions:${versionId}`,
          fragment: VersionFieldsFragmentDoc,
          fragmentName: 'versionFields'
        },
        (data) => ({
          ...data,
          name,
          description,
          save_type: VersionType.NAMED
        })
      )

      if (isNamedVersion) {
        track('Version Info Edited', {
          fileId,
          space,
          teamName,
          location
        })
      }

      onClose()
    } catch (error) {
      console.error(error)
    } finally {
      setIsUpdating(false)
    }
  }

  const handleClose = () => {
    setName(originalName)
    setDescription(originalDescription)
    setHasError(false)
    onClose()
  }

  return (
    <Dialog
      data-test-id="update-version-dialog"
      size="xs"
      title={isNamedVersion ? t('edit_version_info') : t('name_version')}
      showProgressButton
      progressStatus={isUpdating ? LoadingStatus.WAITING : LoadingStatus.INITIAL}
      confirmBtnText={t('save')}
      cancelBtnText={t('cancel')}
      onConfirm={handleUpdate}
      onCancel={handleClose}
      disableConfirm={hasError || isUpdating}
      open={open}
    >
      <InputField
        ref={nameInputRef}
        type="text"
        value={name}
        placeholder={t('name_version')}
        validator={(value) => versionNameValidator(value, t)}
        onInput={handleChangeName}
        onEnterKey={handleUpdate}
        labelClassName="mb-8"
        validateOnChange={false}
      />
      <TextArea
        placeholder={t('describe_version')}
        defaultValue={description}
        onChangeValue={handleChangeDescription}
        height={92}
      />
    </Dialog>
  )
}

export default UpdateVersionDialog
