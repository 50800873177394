import { getIdToken } from '../cognito'
import { FetchParams, Middleware, ResponseContext } from '../../generated/api'

export default class AuthMiddleware implements Middleware {
  public async pre(context: ResponseContext): Promise<FetchParams | void> {
    return {
      url: context.url,
      init: {
        ...context.init,
        headers: new Headers({
          ...context.init.headers,
          Authorization: `Bearer ${getIdToken()}`
        })
      }
    }
  }
}
