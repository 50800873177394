import DashStack from './DashStack'

class GapStack extends DashStack {
  constructor(elementStack, data) {
    super(elementStack, data)
    
    this.type = 'GAP'
    this.key = 'gap'
    this.dataKey = 'gap'
    this.animatableProperties = new Set(['gap'])
  }

}

export default GapStack
