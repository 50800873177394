import PropertyStack from '../../PropertyStack'

class GrowDirectionStack extends PropertyStack {
  constructor(elementStack, data) {
    super(elementStack, data)
    this.type = 'GROW_DIRECTION'
    this.key = 'growDirection'
    this.dataKey = 'growDirection'
    this.animatableProperties = new Set()
  }
}

export default GrowDirectionStack
