import { useCallback } from 'react'
import { schema } from 'normalizr'
import { createProvider, useEntity } from '../utils'
import { useSetLayer } from './LayerProvider'
import { Paint } from './PaintProvider'

export const InnerShadow = new schema.Entity(
  'innerShadows',
  { paint: Paint },
  {
    processStrategy: (value, parent) => {
      return { ...value, styleId: parent.id, elementId: parent.elementId }
    }
  }
)

const defaultValue = {}
const [Provider, useSelectState, useSetState] = createProvider('InnerShadow', defaultValue)

export const useInnerShadow = useSelectState

export const useSetInnerShadow = () => {
  const { createLayer, destroyLayer } = useSetLayer()
  const setState = useSetState()
  const [
    setInnerShadow,
    addInnerShadow,
    updateInnerShadow,
    removeInnerShadow,
    mergeInnerShadow,
    reduceInnerShadow,
    create,
    destroy
  ] = useEntity(InnerShadow, setState)

  const createInnerShadow = useCallback(
    (entities) => {
      createLayer(entities)
      create(entities)
    },
    [create, createLayer]
  )

  const destroyInnerShadow = useCallback(
    (entities) => {
      destroy(entities)
      destroyLayer(entities)
    },
    [destroy, destroyLayer]
  )
  return {
    setInnerShadow,
    addInnerShadow,
    updateInnerShadow,
    removeInnerShadow,
    mergeInnerShadow,
    reduceInnerShadow,
    createInnerShadow,
    destroyInnerShadow
  }
}

export default Provider
