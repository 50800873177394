import React from 'react'
import { MIX_VALUE, MIX_DISPLAY_NAME } from '@phase-software/data-utils'
import Input from './Input'

const DISABLED_PLACEHOLDER = '-'

export type ValueType = string | number

export type MixInputProps = {
  type: 'text' | 'number'
  value?: ValueType | typeof MIX_VALUE
  formatter: (value: ValueType) => string
  toDisplay?: (value: ValueType) => ValueType
  disabled?: boolean
  [key: string]: any
  spinner?: boolean
  alwaysForceUpdate?: boolean
}

const MixInput = ({
  type,
  value,
  alwaysForceUpdate,
  formatter,
  toDisplay = (value) => value,
  ...props
}: MixInputProps) => {
  const isMixed = value === MIX_VALUE
  const displayValue: ValueType = isMixed
    ? MIX_DISPLAY_NAME
    : getDisplayValue(value as ValueType, type, toDisplay, props.disabled)

  return (
    <Input
      // @ts-ignore TODO: need to add type in Input
      type={isMixed ? 'text' : type}
      value={displayValue}
      formatter={value === undefined && props.disabled ? undefined : formatter}
      mixed={isMixed}
      alwaysForceUpdate={alwaysForceUpdate}
      {...props}
    />
  )
}

export default React.memo(MixInput)

const getDisplayValue = (
  value: ValueType,
  type: MixInputProps['type'],
  toDisplay?: MixInputProps['toDisplay'],
  disabled?: boolean
): ValueType => {
  if (value === undefined) {
    return disabled ? DISABLED_PLACEHOLDER : ''
  }

  return type === 'number' ? toDisplay?.(value) ?? value : value
}
