import { ElementType, BooleanOperation, EntityType, GeometryType, IDType } from '@phase-software/types'
import { notNull, setAdd } from '@phase-software/data-utils'
import ImagePreset from './export/ImagePreset'
import { Setter } from './Setter'
import Base from './Base'
import ComputedStyle from './ComputedStyle'

/** @typedef {import('./component/PropertyComponent').PropertyComponentData} PropertyComponentData */
/** @typedef {import('./DataStore').DataStore} DataStore */
/** @typedef {import('./Workspace').Workspace} Workspace */

/** @typedef {import('@phase-software/data-utils').Mesh} Mesh */

const UNDO_CHANGES = [
    // from Transform
    'position',
    'x',
    'xUnit',
    'y',
    'yUnit',
    'translateX',
    'translateY',
    'size',
    'rotation',
    'scale',
    'skew',
    // from ComputedStyle
    'opacity',
    'blendMode',
    // export preset
    'imagePreset',
    // from Editor mode
    'autoOrient',
    'visible',
    'locked',
    'virtualDisplay',
    'virtualSelected',
    'virtualLocked',
    'referencePoint',
    'referencePointX',
    'referencePointY',
    'contentAnchor',
    'contentAnchorX',
    'contentAnchorY',
    'contentAnchorAutoAdd',
    'contentAnchorType',
    'aspectRatioLocked',
    'scaleAspectRatioLocked'
]

const UNDO_EVENTS = []

const READ_ONLY = ['computedStyle']

/**
 * Abstract class
 * @fires 'CHANGES'
 * @fires 'STYLE_LIST_CHANGES'
 * @fires 'LAYER_CHANGES'
 */
export class Element extends Setter {
    /**
     * Do not create instances of Element class directly. Use subclasses instead.
     * @param {DataStore} dataStore
     * @param {ElementData} [data]
     * @fires 'BOUNDS'
     */
    constructor(dataStore, data, overrides) {
        super(dataStore, data)

        setAdd(this.readOnly, READ_ONLY)
        setAdd(this.undoChanges, UNDO_CHANGES)
        setAdd(this.undoEvents, UNDO_EVENTS)

        this.liftups.push('computedStyle')
        this._setupLiftupParents()

        this._handleModeChangeFn = this._handleModeChange.bind(this)
        this.setup(overrides)
    }

    setup(overrides = {}) {
        // Handle DataStore mode changed and reload computedStyle
        this.data.computedStyle.reload()
        
        if (!overrides.dataOnly) {
            this.dataStore.on('mode', this._handleModeChangeFn)
            this.on('name', (value, oldValue) => {
                this.dataStore.nameCounter.rename(this, value, oldValue)
            })
        }
    }

    _handleModeChange() {
        this.computedStyle.reload()
    }

    /**
     * creates a blank Element
     * @protected
     */
    create() {
        super.create(IDType.ELEMENT)
        this.data.name = 'Element'
        this.data.type = EntityType.ELEMENT
        this.data.elementType = null
        this.data.initGeometryType = null
        this.data.locked = false
        this.data.visible = true
        this.data.virtualDisplay = undefined  // For UI only. Undefined means we won't use it
        this.data.virtualSelected = undefined // For UI only. Undefined means we won't use it
        this.data.virtualLocked = undefined   // For UI only. Undefined means we won't use it
        this.data.autoOrient = false
        this.data.aspectRatioLocked = false
        this.data.scaleAspectRatioLocked = true

        // non-serialized
        this.data.base = new Base(this.dataStore, this.data.id)
        this.data.computedStyle = new ComputedStyle(this.dataStore, { element: this })
        this.data.imagePreset = null

        this.data.isAnimated = false

        this._setupLiftupParents()
    }

    /**
     * @param {ElementData} data
     */
    load(data) {
        super.load(data, IDType.ELEMENT)

        this.data.type = EntityType.ELEMENT
        this.data.elementType = data.elementType
        this.data.initGeometryType = data.geometryType ?? (data.geometry && data.geometry.geometryType) ?? null
        this.data.locked = notNull(data.locked) ? data.locked : false
        this.data.visible = notNull(data.visible) ? data.visible : true
        this.data.virtualDisplay = undefined
        this.data.virtualSelected = undefined
        this.data.virtualLocked = undefined
        this.data.autoOrient = notNull(data.autoOrient) ? data.autoOrient : false
        this.data.aspectRatioLocked = notNull(data.aspectRatioLocked) ? data.aspectRatioLocked : false
        this.data.scaleAspectRatioLocked = notNull(data.scaleAspectRatioLocked) ? data.scaleAspectRatioLocked : true

        // non-serialized
        this.data.base = new Base(this.dataStore, this.data.id, data.base)
        this.data.computedStyle = new ComputedStyle(this.dataStore, { element: this })
        if (data.imagePreset) {
            if (data.imagePreset.refId) {
                if (this.dataStore.imageExport) {
                    this.data.imagePreset = this.dataStore.imageExport.presets.get(data.imagePreset.refId)
                } else {
                    console.error('imageExport should be loaded before elements')
                }
            } else {
                this.data.imagePreset = new ImagePreset(this.dataStore, data.imagePreset.data)
            }
        } else {
            this.data.imagePreset = null
        }

        this.data.isAnimated = false

        this._setupLiftupParents()
    }

    /**
     * @param {object} [overrides] data object with overrides
     * @returns {Element}
     */
    clone(overrides = { dataOnly: false }) {
        const obj = super.clone(overrides)
        obj.data.elementType = this.data.elementType
        obj.data.locked = this.data.locked
        obj.data.visible = this.data.visible
        obj.data.autoOrient = this.data.autoOrient
        obj.data.aspectRatioLocked = this.data.aspectRatioLocked
        obj.data.scaleAspectRatioLocked = this.data.scaleAspectRatioLocked

        // non-serialized
        obj.data.base = this.data.base.clone(obj.data.id, overrides.base)
        obj.data.computedStyle = new ComputedStyle(this.dataStore, { element: obj })
        // TODO: Need to deal with the gobal presets clone
        obj.data.imagePreset = this.data.imagePreset && this.data.imagePreset.clone()

        obj.data.isAnimated = this.data.isAnimated

        obj._setupLiftupParents()
        return obj
    }

    cloneWithoutEventBinding() {
        return this.clone({ dataOnly: true })
    }

    /**
     * @returns {ElementData}
     */
    save() {
        const data = super.save()
        data.elementType = this.data.elementType
        data.locked = this.data.locked
        data.visible = this.data.visible
        data.autoOrient = this.data.autoOrient
        data.aspectRatioLocked = this.data.aspectRatioLocked
        data.scaleAspectRatioLocked = this.data.scaleAspectRatioLocked
        data.base = this.data.base.save()

        const presetId = this.data.imagePreset && this.data.imagePreset.get('id')
        if (this.dataStore.imageExport.presets.get(presetId)) {
            data.imagePreset = {
                refId: presetId
            }
        } else if (this.data.imagePreset) {
            data.imagePreset = {
                data: this.data.imagePreset.save()
            }
        } else {
            data.imagePreset = null
        }
        return data
    }

    // ////////////////////////////////////////////// //
    // -----------------  STYLES  ------------------- //
    // ////////////////////////////////////////////// //

    get base() {
        return this.data.computedStyle.base
    }

    get computedStyle() {
        return this.data.computedStyle
    }

    getBaseProp(propName) {
        return this.dataStore.library.getProperty(this.data.base[propName])
    }

    getBaseValue(propName) {
        if (propName === 'position') {
            const referencePoint = this.getBaseProp('referencePoint')
            const contentAnchor = this.getBaseProp('contentAnchor')
            const translate = this.getBaseProp('translate')
            return {
                x: translate.translateX - (referencePoint.referencePointX + contentAnchor.contentAnchorX),
                y: translate.translateY - (referencePoint.referencePointY + contentAnchor.contentAnchorY)
            }
        }

        return this.getBaseProp(propName)
    }

    /**
     * Set data to PropertyComponent
     * @param {string} propName
     * @param {PropertyComponentData} data
     * @param {bool} [undoable=true]
     * @returns {bool}
     */
    setBaseProp(propName, data, undoable = true) {
        return this.dataStore.library.setProperty(this.data.base[propName], data, undoable)
    }

    setBaseProps(propNameList, dataList, undoable = true) {
        return this.dataStore.library.setProperties(
            propNameList.map(propName => this.data.base[propName]),
            dataList,
            undoable
        )
    }

    /**
     * undo style list related event with given changes data
     * @param {string} type
     * @param {StyleListChanges} changes
     */
    undo(type, changes) {
        super.undo(type, changes)
        // TODO: need to think if we still need this for Element
    }

    /**
     * redo style list related event with given changes data
     * @param {string} type
     * @param {StyleListChanges} changes
     */
    redo(type, changes) {
        super.redo(type, changes)
        // TODO: need to think if we still need this for Element
    }

    clear() {
        this.data.base.clear()
        this.dataStore.off('mode', this._handleModeChangeFn)
    }

    /**
     * return whether any ancestor is locked
     * @returns {bool}
     */
    isAnyAncestorLocked() {
        if (!this.get('elementType')) return false
        let currParent = this.get('parent')
        while (currParent && currParent.get('elementType')) {
            if (currParent.get('locked')) return true
            currParent = currParent.get('parent')
        }
        return false
    }

    /**
     * return whether the element is locked by itself or its parent
     * @returns {bool}
     */
    isLocked() {
        return this.get('locked') || this.isAnyAncestorLocked()
    }

    /**
     * return whether any ancestor is hidden
     * @returns {bool}
     */
    isAnyAncestorHidden() {
        if (!this.get('elementType')) return false
        let currParent = this.get('parent')
        while (currParent && currParent.get('elementType')) {
            if (!currParent.get('visible')) return true
            currParent = currParent.get('parent')
        }
        return false
    }

    isLineElement() {
        return this.get('elementType') === ElementType.PATH && this.get('geometryType') === GeometryType.LINE
    }

    get hasLayers() {
        return this.computedStyle.hasLayers
    }

    get hasEffect() {
        return this.computedStyle.hasEffect
    }

    get canMorph() {
        return false
    }

    /**
     * return whether the element is hidden by itself or its parent
     * @returns {bool}
     */
    isHidden() {
        return !this.get('visible') || this.isAnyAncestorHidden()
    }

    // TODO: @Asa Should move to Container component
    //   Should use getter
    isBooleanType() {
        return this.isContainer && this.get('booleanType') !== BooleanOperation.NONE
    }

    // TODO: @Asa Should move to Container component
    //   Should use getter
    isMaskGroup() {
        return this.isContainer && this.get('isMask')
    }

    get isWorkspace() {
        return this.get('type') === EntityType.WORKSPACE
    }

    get isScreen() {
        return this.get('elementType') === ElementType.SCREEN
    }

    get isContainer() {
        return this.get('elementType') === ElementType.CONTAINER
    }
}

/** 
 * @typedef {object} PointChange
 * @property {string} id
 * @property {number} x
 * @property {number} y
 */

/** @typedef {import('./Setter').SetterData} SetterData */
/** @typedef {import('./ComputedStyle').StyleListChanges} StyleListChanges */

/** @typedef {('ELEMENT' | 'TEXT' | 'PATH' | 'GROUP' | 'SCREEN' | 'GEOMETRY_GROUP' | 'SCROLL_GROUP')} ElementType */

/**
 * @typedef {SetterData} ElementData
 * @property {ElementType} elementType
 * @property {boolean} locked
 * @property {boolean} visible
 * @property {boolean} aspectRatioLocked
 * @property {boolean} scaleAspectRatioLocked
 * @property {StyleData[]} styles
 */
