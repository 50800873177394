import { DEBUG_LOTTIE_STATS_FILE_NAME } from '../../constants'

export const getOPFSRoot = async () => {
  return navigator.storage.getDirectory()
}

export const findOPFS = async () => {
  const fileHandleList = []
  return getOPFSRoot().then(async (root) => {
    for await (const fileHandle of root.values()) {
      // skip STATS
      if (fileHandle.name === DEBUG_LOTTIE_STATS_FILE_NAME) {
        continue
      }
      fileHandleList.push(fileHandle)
    }
    fileHandleList.sort((a, b) => {
      const aName = Number(a.name.split('-')[0])
      const bName = Number(b.name.split('-')[0])
      return aName - bName
    })
    return fileHandleList
  })
}

export const addFileToOPFS = async (file) => {
  const root = await getOPFSRoot()
  const content = await file.text()
  const fileHandle = await root.getFileHandle(file.name, { create: true })
  const writable = await fileHandle.createWritable()
  await writable.write(content)
  await writable.close()
}

export const clearOPFS = async () => {
  const files = await findOPFS()
  const promises = files.map((file) => file.remove())
  await Promise.allSettled(promises)
}
