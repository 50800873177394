import PropertyStack from '../PropertyStack'

class ScaleXStack extends PropertyStack {
  constructor(elementStack, data) {
    super(elementStack, data)
    this.type = 'SCALE_X'
    this.key = 'scaleX'
    this.dataKey = 'scaleX'
    this.animatableProperties = new Set(['scaleX'])
  }
}

export default ScaleXStack
