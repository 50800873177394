import React from 'react'
import { ElementType, GeometryType, BooleanOperation } from '@phase-software/types'
import PropTypes from 'prop-types'
import Icon from './Icon'

const elementTypeMapIconName = {
  [ElementType.GROUP]: 'Folder',
  [ElementType.GEOMETRY_GROUP]: 'Compound',
  [ElementType.TEXT]: 'Text',
  [ElementType.CONTAINER]: 'Container',
  [ElementType.NORMAL_GROUP]: 'NormalGroup',
  [ElementType.SCREEN]: 'Screen'
}

const geometryTypeMapIconName = {
  [GeometryType.RECTANGLE]: 'Rectangle',
  [GeometryType.ELLIPSE]: 'Oval',
  [GeometryType.STAR]: 'Path',
  [GeometryType.LINE]: 'Path',
  [GeometryType.POLYGON]: 'Path',
  [GeometryType.REGULAR_POLYGON]: 'Path'
}

const booleanTypeMapIconName = {
  [BooleanOperation.UNION]: 'GeometryUnion',
  [BooleanOperation.SUBTRACT]: 'GeometrySubtract',
  [BooleanOperation.INTERSECT]: 'GeometryIntersect',
  [BooleanOperation.DIFFERENCE]: 'GeometryDifference'
}

const ElementIcon = ({ elementType, containerType, geometry, booleanType, isMask, active, ...args }) => {
  let name
  if (isMask) {
    name = 'GeometryMask'
  } else if (elementType === ElementType.PATH) {
    name = geometryTypeMapIconName[geometry.geometryType]
  } else if (booleanType !== undefined && booleanType !== BooleanOperation.NONE) {
    name = booleanTypeMapIconName[booleanType]
  } else {
    name = elementTypeMapIconName[elementType]
    if (containerType !== undefined) {
      name = elementTypeMapIconName[containerType]
    }
  }
  return <Icon name={name} interactive={false} disabled={!active} className="mr-4" {...args} />
}

ElementIcon.propTypes = {
  active: PropTypes.bool,
  elementType: PropTypes.number,
  containerType: PropTypes.number,
  geometry: PropTypes.shape({
    geometryType: PropTypes.number
  }),
  booleanType: PropTypes.number,
  isMask: PropTypes.bool
}

export default ElementIcon
