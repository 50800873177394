import React, { useCallback, useMemo } from 'react'
import { Icon } from '../../shared'

export type PlayBackControlButtonProps = {
  variant: 'play' | 'loop'
  isActive: boolean
  onChange: () => void
}

const PlayBackControlButton = ({ variant, isActive, onChange }: PlayBackControlButtonProps) => {
  const iconName = useMemo(() => {
    if (variant === 'loop') return 'LoopOn'
    return isActive ? 'PauseOutline' : 'PlayOutline'
  }, [variant, isActive])

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.currentTarget === document.activeElement) {
        e.currentTarget.blur()
      }
      onChange()
    },
    [onChange]
  )

  return (
    <label className="relative inline-block cursor-pointer" data-test-id={`playback-${variant}`}>
      <input
        name={variant}
        type="checkbox"
        onChange={handleChange}
        checked={isActive}
        className="absolute w-0 h-0 opacity-0 peer"
      />
      <div className="w-28 h-28 rounded-md flex items-center justify-center text-light-overlay-60 hover:text-white active:text-light-overlay-40 peer-checked:text-white peer-checked:bg-light-overlay-10 peer-checked:hover:bg-light-overlay-15 peer-checked:active:bg-light-overlay-5">
        <Icon name={iconName} interactive={false} useCurrentColor />
      </div>
    </label>
  )
}

export default PlayBackControlButton
