import { TFunction } from 'i18next'

export const secondFormatValidator = (value: string): string => {
  if (value.match(/^\d*\.?\d*s?$/i)) {
    return ''
  }
  return 'Invalid'
}

export const percentageFormatValidator = (value: string): string => {
  if (value.match(/^\d*\.?\d*%?$/)) {
    return ''
  }
  return 'Invalid'
}

export const transformPercentageFormatValidator = (value: string): string => {
  if (value.match(/^-?\d+\.?\d*%?$/)) {
    return ''
  }
  return 'Invalid'
}

export const scaleFormatValidator = (value: string): string => {
  if (value.match(/^\d*\.?\d*x?$/i)) {
    return ''
  }
  return 'Invalid'
}

export const degreeFormatValidator = (value: string): string => {
  if (value.match(/^-?\d+\.?\d*°?$/)) {
    return ''
  }
  return 'Invalid'
}

export const sizeFormatValidator = (value: string): string => {
  if (value.match(/^\d*\.?\d*(px|%)?$/i)) {
    return ''
  }
  return 'Invalid'
}

export const commaSeparatedValidator = (value: string): string => {
  const valid = value
    .split(',')
    .map((v) => v.trim())
    .filter(Boolean)
    .every((v) => !isNaN(Number(v)))
  return valid ? '' : 'Invalid'
}

export const bezierValidator = (value: string): string => {
  const parts = value.split(',').map((v) => Number(v))
  if (parts.length !== 4) {
    return 'Invalid'
  }
  if (parts.some((v) => isNaN(v))) {
    return 'Invalid'
  }
  if (parts[0] < 0 || parts[0] > 1) {
    return 'Invalid'
  }
  if (parts[2] < 0 || parts[2] > 1) {
    return 'Invalid'
  }
  return ''
}

export const decimalValidator = (value: string): string => {
  if (value.match(/^-?\d*\.?\d*$/i)) {
    return ''
  }
  return 'Invalid'
}

export const isValidEmail = (email: string): boolean => {
  const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i
  return emailRegex.test(email)
}

export const usernameValidator = (value: string, t: TFunction): string => {
  if (!value.trim()) return t('common:validator.please_enter_your_username')
  if (value.length > 50) return t('common:validator.username_exceed_character_limit')
  return ''
}

export const projectNameValidator = (value: string, t: TFunction): string => {
  if (value.trim().length === 0) return t('common:validator.please_enter_a_project_name')
  if (value.trim().length > 255) return t('common:validator.project_name_should_be_less_than_256_characters')
  return ''
}

export const fileNameValidator = (value: string, t: TFunction): string => {
  if (value.trim().length === 0) return t('common:validator.please_enter_a_file_name')
  if (value.trim().length > 255) return t('common:validator.file_name_should_be_less_than_256_characters')
  return ''
}

export const exportedFileNameValidator = (value: string, t: TFunction): string => {
  const trimmedValue = value.trim()
  if (trimmedValue.length === 0) return t('common:validator.please_enter_a_file_name')
  if (trimmedValue.length > 255) return t('common:validator.file_name_should_be_less_than_256_characters')
  if (trimmedValue.startsWith('.')) return t('common:validator.cannot_start_with_a_dot')
  if (/<|>|:|"|\/|\\|\||\?|\*|'|`/.test(trimmedValue)) {
    return t('common:validator.exported_file_name_invalid_characters')
  }
  return ''
}

export const teamNameValidator = (value: string, t: TFunction): string => {
  if (value.trim().length === 0) return t('common:validator.please_enter_team_name')
  if (value.trim().length > 255) return t('common:validator.team_name_should_be_less_than_256_characters')
  // eslint-disable-next-line no-useless-escape
  if (/[\&\$+,\/:;=?@#<>\[\]\{\}\|\\\^%]/.test(value)) {
    return t('common:validator.team_name_has_invalid_characters')
  }
  return ''
}

export const versionNameValidator = (value: string, t: TFunction): string => {
  if (value.trim().length === 0) return t('common:validator.please_enter_a_version_name')
  if (value.trim().length > 255) return t('common:validator.version_name_should_be_less_than_256_characters')
  return ''
}

export const verificationCodeValidator = (value: string, t: TFunction): string => {
  if (value.match(/^\d{6}$/)) {
    return ''
  }
  return t('common:validator.invalid_verification_code')
}

export const currentPasswordValidator = (value: string, t: TFunction): string => {
  return value.length ? '' : t('common:validator.profile_setting.please_enter_current_password')
}

export const passwordValidator = (value: string, email: string, username: string, t: TFunction): string => {
  if (!value.length) return t('common:validator.profile_setting.please_enter_new_password')
  if (email === value || username === value)
    return t('common:validator.profile_setting.password_not_same_as_name_or_email')
  if (value.length < 8) return t('common:validator.profile_setting.at_least_8_characters')
  return ''
}

export const confirmPasswordValidator = (newPassword: string, t: TFunction): ((value: string) => string) => {
  return (value: string): string => {
    if (!value.length) return t('profile_setting.please_enter_password_again')
    if (newPassword !== value) return t('profile_setting.not_match_new_password')
    return ''
  }
}
