import React from 'react'
import CurrentTime from '../PlaybackControls/CurrentTime'
import Duration from '../PlaybackControls/Duration'

type ActionPanelHeaderProps = {
  actionId: string
  playHeadRef: React.RefObject<{ scrollIntoView: () => void }>
}

const ActionPanelHeader = ({ playHeadRef, actionId }: ActionPanelHeaderProps) => {
  return (
    <div className="h-40 py-6 px-8 inline-flex items-center gap-x-8 bg-neutral-90 z-30 border-r border-b border-neutral-80">
      <CurrentTime playHeadRef={playHeadRef} actionId={actionId} />
      <span className="text-12 text-light-overlay-40">/</span>
      <Duration actionId={actionId} />
    </div>
  )
}

export default ActionPanelHeader
