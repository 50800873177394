import React, { forwardRef } from 'react'
import Icon, { IconSizeType, iconSizeToPixels } from '../Icon'
import Tooltip, { TooltipProps } from '../Tooltip'

export interface IconButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string
  iconClassName?: string
  icon: string
  tip?: TooltipProps['content']
  size?: IconSizeType
  disabled?: boolean
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  style?: React.CSSProperties
  tabIndex?: number
  disableGroupHover?: boolean
  onFocus?: (e: React.FocusEvent<HTMLButtonElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLButtonElement>) => void
}

const IconsHaveDifferentColorsInside = ['OriginCenter']

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      className = '',
      disabled,
      icon,
      iconClassName = '',
      onClick,
      size = 'l',
      tip,
      tabIndex,
      disableGroupHover,
      onFocus,
      onBlur,
      ...rest
    },
    ref
  ) => {
    const handleKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
      switch (e.key) {
        case ' ':
        case 'Enter':
          e.preventDefault()
          e.stopPropagation()
          e.currentTarget.click()
          break
        case 'Escape':
          e.stopPropagation()
          e.currentTarget.blur()
          break
        default:
          break
      }
    }

    const handleMouseUp = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.currentTarget.blur()
    }

    const isSpecialIcon = IconsHaveDifferentColorsInside.includes(icon)

    const iconSize = iconSizeToPixels(size)

    const focusClassName =
      iconSize > 16
        ? 'focus-visible:after:absolute focus-visible:after:outline-primary-1 focus-visible:after:rounded-md'
        : 'focus-visible:after:absolute focus-visible:after:outline-primary-1 focus-visible:after:rounded-sm'

    return (
      <button
        ref={ref}
        type="button"
        className={`${focusClassName} flex items-center justify-center focus-visible:relative focus-visible:after:inset-[-2px] ${
          isSpecialIcon
            ? 'special-button opacity-60 hover:opacity-100 active:opacity-40'
            : `text-light-overlay-60 active:text-light-overlay-40 hover:text-white ${
                !disableGroupHover &&
                'group-hover:active:text-light-overlay-40 group-hover:text-white group-active:text-light-overlay-40 group-hover:disabled:text-light-overlay-60'
              } disabled:hover:text-light-overlay-60 disabled:text-light-overlay-60`
        } ${className}`}
        style={{ height: `${iconSize}px`, lineHeight: `${iconSize}px` }}
        onKeyDown={handleKeyDown}
        onMouseUp={handleMouseUp}
        disabled={disabled}
        onClick={onClick}
        tabIndex={tabIndex}
        onFocus={onFocus}
        onBlur={onBlur}
        {...rest}
      >
        <Tooltip content={tip}>
          <Icon
            className={iconClassName}
            name={icon}
            size={size}
            disabled={disabled}
            interactive={false}
            useCurrentColor
          />
        </Tooltip>
      </button>
    )
  }
)

IconButton.displayName = 'IconButton'

export default IconButton

export const WhiteHighlightIconButton = (props: IconButtonProps) => (
  <IconButton {...props} className="focus-visible:after:border-white focus-visible:after:shadow-5" />
)
