import React from 'react'

export type BasicKnobProps = { className?: string; children?: React.ReactNode; focus?: boolean }

const BasicKnob = React.forwardRef<HTMLDivElement, BasicKnobProps>(({ className, children }, ref) => (
  <div
    ref={ref}
    className={`absolute m-auto w-8 h-8 rounded-circle bg-white top-0 cursor-pointer bottom-0 pointer-events-none ${className}`}
  >
    {children}
  </div>
))

BasicKnob.displayName = 'BasicKnob'

export default BasicKnob
