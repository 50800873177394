/* tslint:disable */
/* eslint-disable */
/**
 * PHASE API
 * Phase API service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  InternalDeleteFilesRequest,
  StatusResponse,
} from '../models';

export interface StatusApiControllerInternalUseForDeletingFilesByProjectIdRequest {
    userId: string;
    internalDeleteFilesRequest: InternalDeleteFilesRequest;
}

/**
 * 
 */
export class StatusApi extends runtime.BaseAPI {

    /**
     */
    async statusApiControllerInternalUseForDeletingFilesByProjectIdRaw(requestParameters: StatusApiControllerInternalUseForDeletingFilesByProjectIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling statusApiControllerInternalUseForDeletingFilesByProjectId.');
        }

        if (requestParameters.internalDeleteFilesRequest === null || requestParameters.internalDeleteFilesRequest === undefined) {
            throw new runtime.RequiredError('internalDeleteFilesRequest','Required parameter requestParameters.internalDeleteFilesRequest was null or undefined when calling statusApiControllerInternalUseForDeletingFilesByProjectId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/_private/v1/internal/user/{userId}/files`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.internalDeleteFilesRequest,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async statusApiControllerInternalUseForDeletingFilesByProjectId(requestParameters: StatusApiControllerInternalUseForDeletingFilesByProjectIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.statusApiControllerInternalUseForDeletingFilesByProjectIdRaw(requestParameters, initOverrides);
    }

    /**
     */
    async statusApiControllerRuokRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StatusResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/status/ruok`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async statusApiControllerRuok(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StatusResponse> {
        const response = await this.statusApiControllerRuokRaw(initOverrides);
        return await response.value();
    }

}
