import React, { useEffect, useRef, useState } from 'react'

import { Avatar, IconButton, LinkSwitcher, Tooltip } from '../../../../components/shared'
import { useSetModal } from '../../../../providers/ModalProvider'
import { VersionProps, VersionType } from '../../../../utils/transformVersion'
import VersionItemContextMenu from './VersionItemContextMenu'

const CONTRIBUTOR_DISPLAY_LIMIT = 3
const COLLAPSED_CONTRIBUTOR_DISPLAY_LIMIT = 2
const LEFT_LINE_DOWN_POSITION = 31

export type VersionItemProps = {
  id: string
  name: string
  editName: string
  defaultFileName: string
  description: string
  contributors: VersionProps['contributors']
  isSelected: boolean
  isLast?: boolean
  timestamp: string
  type: VersionType
  onClick?: () => void
  projectId: string
  fileId: string
}

const UpperLine = () => (
  <div className="absolute w-1 left-[11px] h-[17px] top-0 bg-neutral-60" data-test-id="version-upper-line"></div>
)

const LowerLine = () => (
  <div
    className="absolute w-1 left-[11px] bottom-0 bg-neutral-60"
    style={{ height: `calc(100% - ${LEFT_LINE_DOWN_POSITION}px)` }}
    data-test-id="version-lower-line"
  ></div>
)

const VersionItem = ({
  name,
  editName,
  defaultFileName,
  id,
  description,
  contributors = [],
  isSelected = false,
  isLast = false,
  timestamp,
  type,
  onClick,
  projectId,
  fileId
}: VersionItemProps) => {
  const { openModal: openContextMenu } = useSetModal(`VersionItemContextMenu-${id}`)

  const triggerRef = useRef<HTMLButtonElement>(null)

  const handleMenuWithButton = (e: React.MouseEvent) => {
    e.stopPropagation()
    openContextMenu({
      trigger: triggerRef
    })
  }
  const handleContextMenu = (e: React.MouseEvent) => {
    e.preventDefault()
    openContextMenu({
      cursorPosition: {
        top: e.clientY,
        left: e.clientX
      }
    })
  }

  const isLatestVersion = type === VersionType.LATEST
  const isNamedVersion = type === VersionType.NAMED
  const hasDescription = description && description.length > 0
  const hasContributors = contributors && contributors.length > 0

  return (
    <>
      <div
        className={`relative pl-[13px] pr-16 mx-8 rounded-md flex items-stretch group ${
          isSelected ? 'bg-light-overlay-10' : 'hover:bg-light-overlay-5'
        }`}
        onClick={onClick}
        onContextMenu={handleContextMenu}
      >
        {!isLatestVersion && <UpperLine />}
        {!isLast && <LowerLine />}
        <div className="w-[185px] ml-16 py-16 flex-col space-y-8 relative">
          <div className="relative flex items-center">
            <div className="inline-flex items-center w-full justify-between">
              <div className="absolute -left-20 flex items-center h-full">
                <div
                  className={`w-5 h-5 rounded-circle ${isNamedVersion ? 'bg-neutral-30' : 'border border-neutral-30'}`}
                ></div>
              </div>
              <Tooltip content={name} visibleOnOverflow>
                <div className="text-white font-medium truncate" data-test-id="version-name">
                  {name}
                </div>
              </Tooltip>
              <VersionItemContextMenu
                id={id}
                type={type}
                name={editName}
                description={description}
                defaultFileName={defaultFileName}
                projectId={projectId}
                fileId={fileId}
              >
                <IconButton
                  ref={triggerRef}
                  icon="More"
                  onClick={handleMenuWithButton}
                  className="ml-8 hidden group-hover:inline-flex"
                  data-test-id="version-item-more-button"
                />
              </VersionItemContextMenu>
            </div>
          </div>
          {hasDescription && <VersionDescription description={description} />}
          {hasContributors && <ContributorList contributors={contributors} />}
          {isNamedVersion && (
            <div className="text-light-overlay-60 text-10" data-test-id="version-create-timestamp">
              {timestamp}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

const VersionDescription = ({ description }: { description: string | null }) => {
  const ref = useRef(null)
  const [isCollapsed, setIsCollapsed] = useState(true)

  useEffect(() => {
    const element = ref.current
    const isOverflowing = element && (element as HTMLElement).scrollHeight > (element as HTMLElement).clientHeight
    setIsCollapsed(!!isOverflowing)
  }, [description])

  const handleCollapseDescription = (e: React.MouseEvent) => {
    e.stopPropagation()
    setIsCollapsed(false)
  }

  return (
    <div>
      <div ref={ref} className={`text-white text-11 ${isCollapsed ? 'line-clamp-2' : ''}`}>
        {description}
      </div>
      {isCollapsed && (
        <LinkSwitcher
          className="text-primary-40 text-11 mt-4"
          onClick={handleCollapseDescription}
          data-test-id="version-description-more-button"
        >
          Show more
        </LinkSwitcher>
      )}
    </div>
  )
}

const ContributorList = ({ contributors }: { contributors: VersionItemProps['contributors'] }) => {
  const [isCollapsed, setIsCollapsed] = useState(contributors.length > CONTRIBUTOR_DISPLAY_LIMIT)

  const visibleContributors = isCollapsed ? contributors.slice(0, COLLAPSED_CONTRIBUTOR_DISPLAY_LIMIT) : contributors

  const handleCollapseContributor = (e: React.MouseEvent) => {
    e.stopPropagation()
    setIsCollapsed(false)
  }

  return (
    <div className="flex-col space-y-8">
      {visibleContributors.map((item, index) => (
        <div className="flex" key={index} data-test-id={`version-contributor-${index}`}>
          <Avatar
            size="s"
            radiusSize="sm"
            alt={item.username || ''}
            showOverflowText
            className="flex-shrink-0"
            src={item.avatar}
          />
          <Tooltip content={item.username} visibleOnOverflow>
            <span className="ml-8 text-light-overlay-60 text-11 truncate">{item.username}</span>
          </Tooltip>
        </div>
      ))}
      {isCollapsed && (
        <div onClick={handleCollapseContributor} data-test-id="show-more-version-contributors-button">
          <Avatar
            size="s"
            radiusSize="sm"
            overflow={contributors.length - COLLAPSED_CONTRIBUTOR_DISPLAY_LIMIT}
            showOverflowText
          />
        </div>
      )}
    </div>
  )
}

export default VersionItem
