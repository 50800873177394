import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FileList } from './FileList'
import { Filter } from './Filter'
import { Dropzone } from './Dropzone'
import { findOPFS, addFileToOPFS, clearOPFS } from './opfs'

const searchParams = new URLSearchParams(location.search)
const searchString = searchParams.get('search') || ''

export const FileExporter = ({ onSelect, onRecord, recording, recordIndex, stats }) => {
  const [selected, setSelected] = useState(null)
  const [fileList, setFileList] = useState([])
  const [search, setSearch] = useState(searchString)

  // OPFS (Origin Private File System)
  const reloadFileList = useCallback(async () => {
    const files = await findOPFS()
    setFileList(files)
  }, [setFileList])

  const handleDrop = useCallback(
    async (files) => {
      await Promise.allSettled(files.map(addFileToOPFS))
      reloadFileList()
    },
    [reloadFileList]
  )

  const handleRemove = useCallback(
    async (file) => {
      await file.remove()
      reloadFileList()
    },
    [reloadFileList]
  )

  const handleSelect = useCallback(
    async (fileHandle, index) => {
      setSelected(fileHandle)
      onSelect(fileHandle, index)
    },
    [onSelect, setSelected]
  )

  const handleClear = useCallback(async () => {
    await clearOPFS()
    reloadFileList()
  }, [reloadFileList])

  useEffect(() => {
    reloadFileList()
  }, [reloadFileList])

  useEffect(() => {
    searchParams.set('search', search)
    history.replaceState(null, '', `?${searchParams.toString()}`)
  }, [search])

  const showStats = useCallback(() => {
    stats.printReport()
  }, [stats])

  return (
    <div className="overflow-auto flex-shrink-0 h-full flex flex-col border-r border-rubin">
      <div className=" bg-black border-b border-rubin p-8 flex flex-col gap-8">
        <Filter search={search} onChange={setSearch} />
        <button className="bg-neutral border border-solid px-2" onClick={handleClear}>
          Clear All
        </button>
        <div className="flex gap-8">
          <button className="bg-neutral border border-solid px-2 w-full" onClick={onRecord}>
            {recording ? `Recording (${recordIndex + 1})` : 'Record'}
          </button>
          <button className="bg-neutral border border-solid px-2 w-full" onClick={showStats}>
            Stats ({stats.getCount()})
          </button>
        </div>
      </div>
      <FileList
        fileList={fileList.filter((file) => file.name.toLowerCase().includes(search.toLowerCase()))}
        onSelect={handleSelect}
        selected={selected}
        onRemove={handleRemove}
      />
      <Dropzone onDrop={handleDrop} />
    </div>
  )
}

FileExporter.propTypes = {
  stats: PropTypes.object,
  recording: PropTypes.bool,
  recordIndex: PropTypes.number,
  selected: PropTypes.string,
  onRecord: PropTypes.func,
  onSelect: PropTypes.func
}
