import React, { useCallback, useState } from 'react'

import styled from 'styled-components'

import { useElementVirtualProperties } from '../../providers/ElementVirtualPropertiesProvider'
import { useElementActions } from '../../providers/dataStore/ElementProvider'
import { useUI } from '../../providers/dataStore/UIProvider'
import ElementIcon from '../shared/ElementIcon'
import Icon from '../shared/Icon'
import RenameText from '../shared/RenameText'

type ToolIconProps = {
  name: string
  visible: boolean
  onMouseDown: (e: React.MouseEvent) => void
}
const ToolIcon = ({ visible, name, onMouseDown }: ToolIconProps) => (
  <Icon name={name} onMouseDown={onMouseDown} className={`${visible ? 'visible' : 'invisible'} group-hover:visible`} />
)

const ElementTool = styled.div`
  display: none;
  .list-item:hover & {
    display: grid;
  }
  &.active {
    display: grid;
  }
`

type ElementProps = {
  data: {
    id: string
    name: string
    elementType: string
    containerType: string
    geometry: string
    booleanType: string
    isMask: boolean
    visible: boolean
    parentVisible: boolean
    locked: boolean
    parentLocked: boolean
  }
  hasAnimation: boolean
}

const Element = ({ data, hasAnimation }: ElementProps) => {
  const { setElementName, toggleLock, toggleVisible } = useElementActions()
  const elementsVirtualProperties = useElementVirtualProperties()
  const { isInspectingState } = useUI()
  const [isEditing, setIsEditing] = useState(false)
  const virtualDisplay = elementsVirtualProperties[data.id]?.virtualDisplay
  const virtualLocked = elementsVirtualProperties[data.id]?.virtualLocked
  const elementVisible = data.visible || virtualDisplay
  let contentVisible = data.visible && data.parentVisible
  let locked = data.locked || data.parentLocked
  if (virtualDisplay !== undefined && data.parentVisible) {
    contentVisible = virtualDisplay
  }
  if (virtualLocked !== undefined) {
    locked = virtualLocked
  }
  const active = !contentVisible || locked ? 'active' : ''
  const color = contentVisible ? 'text-white' : 'text-light-overlay-20'

  const handleRename = useCallback(
    (newName: string) => {
      const trimmedNewName = newName.trim()
      if (trimmedNewName !== data.name) {
        setElementName(data.id, trimmedNewName)
      }
      setIsEditing(false)
    },
    [setElementName, data.name, data.id]
  )

  const handleToggleLock = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      toggleLock(data.id)
    },
    [toggleLock, data.id]
  )

  const handleToggleVisible = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      toggleVisible(data.id)
    },
    [toggleVisible, data.id]
  )

  const handleCancelRename = () => {
    setIsEditing(false)
  }

  const handleActiveRename = () => {
    setIsEditing(true)
  }

  return (
    <div className={`flex items-center pr-8 flex-grow min-w-0 ${color} `} data-test-id="element">
      <ElementIcon
        elementType={data.elementType}
        containerType={data.containerType}
        geometry={data.geometry}
        booleanType={data.booleanType}
        isMask={data.isMask}
        active={contentVisible}
      />
      <RenameText
        originalText={data.name}
        onFinish={handleRename}
        onActive={handleActiveRename}
        onCancel={handleCancelRename}
      />
      {!isEditing && !isInspectingState && (
        <ElementTool className={`grid w-36 gap-4 ml-8 grid-flow-col group ${active}`}>
          <ToolIcon
            visible={locked}
            name={locked ? 'LockOn' : data.parentLocked ? 'Dot' : 'LockOff'}
            onMouseDown={handleToggleLock}
          />
          <ToolIcon
            visible={!contentVisible}
            name={elementVisible ? (data.parentVisible ? 'Eye' : 'Dot') : 'EyeClose'}
            onMouseDown={handleToggleVisible}
          />
        </ElementTool>
      )}
      {isInspectingState && hasAnimation && <Icon name="Animated" />}
    </div>
  )
}

export default Element
