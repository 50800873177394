import { useCallback } from 'react'
import { createProvider } from '../utils'
import { useDataStore } from './DataStoreProvider'

const defaultValue = {
  activeTool: 0
}
const [Provider, useSelectState, useSetState, getStateSnapshot] = createProvider('Tool', defaultValue)

export const useTool = useSelectState
export const getToolSnapshot = getStateSnapshot
export const useSetTool = () => {
  const setState = useSetState()

  const setActiveTool = useCallback(
    (activeTool) => {
      setState((state) => ({
        ...state,
        activeTool: activeTool
      }))
    },
    [setState]
  )

  return {
    setActiveTool
  }
}

export const useToolActions = () => {
  const dataStore = useDataStore()

  const setActiveTool = useCallback(
    (tool) => {
      dataStore.eam.setActiveTool(tool)
    },
    [dataStore]
  )

  const setLastGeneralTool = useCallback(() => {
    dataStore.eam.setLastGeneralTool()
  }, [dataStore])

  const activateEyeDropperTool = useCallback(() => {
    dataStore.eam.activateEyeDropperTool()
  }, [dataStore])

  return {
    setActiveTool,
    setLastGeneralTool,
    activateEyeDropperTool
  }
}

export const useBooleanGroup = () => {
  const dataStore = useDataStore()

  const booleanGroupElementList = useCallback(
    (tool) => {
      dataStore.eam.booleanGroupElements(tool)
    },
    [dataStore]
  )

  return {
    booleanGroupElementList
  }
}

export const useMaskGroup = () => {
  const dataStore = useDataStore()

  const maskGroupElementList = useCallback(() => {
    dataStore.eam.maskGroupElements()
  }, [dataStore])

  return {
    maskGroupElementList
  }
}

export default Provider
