import React from 'react'
import { hsv2rgb, rgb2HexString } from '../../../utils/color'

type ColorPickerProps = {
  hue: number
  onMouseDown: () => void
  onKeyDown: () => void
  onKeyUp: () => void
  className?: string
  children?: React.ReactNode
}

const ColorPicker = React.forwardRef<HTMLOutputElement, ColorPickerProps>(
  ({ hue, onMouseDown, onKeyDown, onKeyUp, className = '', children }, forwardedRef) => {
    return (
      <output
        tabIndex={0}
        ref={forwardedRef}
        onMouseDown={onMouseDown}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        data-test-id="color-picker"
        className={`relative block w-full h-[160px] rounded-md cursor-pointer ${className}`}
        style={{
          background: `linear-gradient(to top, #000000, #00000000), linear-gradient(to right, #FFFFFF, #FFFFFF00), ${rgb2HexString(
            hsv2rgb(hue, 1, 1)
          )}`
        }}
      >
        {children}
      </output>
    )
  }
)

ColorPicker.displayName = 'ColorPicker'

export default ColorPicker
