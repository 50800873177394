import { FileFieldsFragment, ProjectFieldsFragment } from '../generated/graphql'
import { zipContent } from '../utils/file'
import { projectFileService } from './api'

export const uploadContent = async ({
  projectId,
  fileId,
  content
}: {
  projectId: ProjectFieldsFragment['id']
  fileId: FileFieldsFragment['id']
  content: object
}) => {
  const base64 = (await zipContent(content, 'blob')) as Blob

  return projectFileService.projectFileControllerUploadContentV3({
    projectId,
    fileId,
    content: base64
  })
}
