import React, { useRef } from 'react'
import { PaintType } from '@phase-software/types'
import PaintPicker from '../../../PaintPicker'
import { usePaint } from '../../../../providers/dataStore/PaintProvider'
import SolidColorEditor from './SolidColorEditor'
import PaintPreviewText from './PaintPreviewText'

type PaintPreviewContentProps = Pick<PaintPreviewProps, 'layerItemId' | 'layerType'>

type PaintPreviewProps = {
  layerItemId: string
  layerType: number
  hiddenContent?: boolean
}

const PaintPreviewContent = ({ layerItemId, layerType }: PaintPreviewContentProps) => {
  const { paintType } = usePaint((o) => o[layerItemId])
  return paintType === PaintType.SOLID ? (
    <SolidColorEditor layerItemId={layerItemId} layerType={layerType} />
  ) : (
    <PaintPreviewText layerItemId={layerItemId} layerType={layerType} />
  )
}

const PaintPreview = ({ layerItemId, layerType, hiddenContent }: PaintPreviewProps) => {
  const ref = useRef<HTMLDivElement>(null)

  return (
    <div ref={ref} className="grid gap-x-8 grid-flow-col items-center" style={{ gridTemplateColumns: 'auto 1fr' }}>
      <div className="grid grid-flow-col items-center">
        <PaintPicker layerItemId={layerItemId} layerType={layerType} anchorRef={ref} />
      </div>
      {!hiddenContent && <PaintPreviewContent layerItemId={layerItemId} layerType={layerType} />}
    </div>
  )
}

export default React.memo(PaintPreview)
