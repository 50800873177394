// Represent mixed property value
export const MIX_VALUE = {}
export const MIX_VALUE_ID = 'MIX_VALUE'
export const MIX_DISPLAY_NAME = 'Mix'
export const IS_MIX = 'isMix'

export const MIN_SIZE_THRESHOLD = 0.1
export const MIN_SIZE_VALUE = MIN_SIZE_THRESHOLD * 0.9995
export const FAKE_IDS = Object.freeze(
    {
        CURVE_CONTROL: "__FAKE_IND_CURVE_CONTROL__",
    }
)

export const BASE_PROP_FULL_TO_SHORT_NAME = {
    translate: 'tr',
    dimensions: 'dm',
    rotation: 'ro',
    opacity: 'op',
    referencePoint: 'rp',
    contentAnchor: 'ca',
    scale: 'sc',
    skew: 'sk',
    cornerRadius: 'cr',
    blurGaussian: 'bl',
    overflow: 'of',
    font: 'fo',
    textAlignment: 'tal',
    textDecoration: 'tdc',
    textDirection: 'tdr',
    fills: 'fl',
    strokes: 'st',
    shadows: 'sd',
    innerShadows: 'is',
    effects: 'ef',
}

export const BASE_PROP_SHORT_TO_FULL_NAME = {
    tr: 'translate',
    dm: 'dimensions',
    ro: 'rotation',
    op: 'opacity',
    rp: 'referencePoint',
    ca: 'contentAnchor',
    sc: 'scale',
    sk: 'skew',
    cr: 'cornerRadius',
    bl: 'blurGaussian',
    of: 'overflow',
    fo: 'font',
    tal: 'textAlignment',
    tdc: 'textDecoration',
    tdr: 'textDirection',
    fl: 'fills',
    st: 'strokes',
    sd: 'shadows',
    is: 'innerShadows',
    ef: 'effects',
}
