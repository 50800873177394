import { nodeTypeMapClass } from './utils'

export class IRSceneTree {
  static defaultValue = []

  constructor() {
    // store the map for parenting
    this.maskMap = new Map()
    this.transformMap = new Map()
    this.nodeMap = new Map()
    this.nodeList = []
  }

  fromJSON(data) {
    data.forEach(nodeData => {
      const parent = this.nodeMap.get(nodeData.parent)
      const NodeClass = nodeTypeMapClass[nodeData.type]
      const node = new NodeClass().fromJSON({ ...nodeData, parent })
      this.nodeList.push(node)
      this.nodeMap.set(node.uid, node)
      if (!this.transformMap.has(node.id)) {
        this.transformMap.set(node.id, node.uid)
      }
      if (node.maskParent) {
        this.maskMap.set(node.uid, node.maskParent.uid)
        node.maskParent = this.nodeMap.get(node.maskParent)
      }
    })
    return this
  }

  appendNode(node, transformParentId = null) {
    this.nodeMap.set(node.uid, node)
    this.nodeList.push(node)

    // only set transform map if it doesn't already exist
    if (!this.transformMap.has(node.id)) {
      this.transformMap.set(node.id, node.uid)
    }

    if (transformParentId) {
      const parentNodeUid = this.transformMap.get(transformParentId)
      node.parent = this.nodeMap.get(parentNodeUid)
    }
  }

  mask(maskNode, maskedNode) {
    this.maskMap.set(maskedNode.uid, maskNode.uid)
    maskNode.mask = true
    maskedNode.maskParent = maskNode
  }

  hasTransform(elementId) {
    return this.transformMap.has(elementId)
  }

  toJSON() {
    return this.nodeList.map(node => node.toJSON())
  }
}
