import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import noResultIllustration from '../Files/components/EmptyState/no-results-found.svg'
import ErrorPage from './components/ErrorPage'
import error500Illustration from './error500.svg'
import restrictedIllustration from './restricted.svg'

type ErrorListProps = {
  errorCode: number
}

const ErrorList = ({ errorCode }: ErrorListProps) => {
  const { t } = useTranslation('common', { keyPrefix: 'error_page' })
  const history = useHistory()

  const navigateToHome = () => {
    history.push('/')
  }

  switch (errorCode) {
    case 400:
      return (
        <ErrorPage
          errorCode={400}
          title={t('page_isnt_working')}
          errorMessageA={t('page_isnt_working_error_message_1')}
          errorMessageB={t('page_isnt_working_error_message_2')}
          image={{ src: error500Illustration, width: 320, height: 200 }}
          onClick={navigateToHome}
        />
      )

    case 403:
      return (
        <ErrorPage
          errorCode={403}
          title={t('this_page_is_restricted')}
          errorMessageA={t('this_page_is_restricted_error_message_1')}
          errorMessageB={t('go_back_to_your_dashboard')}
          image={{ src: restrictedIllustration, width: 320, height: 200 }}
          onClick={navigateToHome}
        />
      )

    case 404:
      return (
        <ErrorPage
          errorCode={404}
          title={t('page_not_found')}
          errorMessageA={t('page_not_found_error_message_1')}
          errorMessageB={t('go_back_to_your_dashboard')}
          image={{ src: noResultIllustration, width: 320, height: 200 }}
          onClick={navigateToHome}
        />
      )

    case 500:
      return (
        <ErrorPage
          errorCode={500}
          title={t('something_went_wrong')}
          errorMessageA={t('something_went_wrong_error_message_1')}
          errorMessageB={t('something_went_wrong_error_message_2')}
          image={{ src: error500Illustration, width: 320, height: 200 }}
          onClick={navigateToHome}
        />
      )
    default:
      return (
        <ErrorPage
          errorCode={404}
          title={t('page_not_found')}
          errorMessageA={t('page_not_found_error_message_1')}
          errorMessageB={t('go_back_to_your_dashboard')}
          image={{ src: noResultIllustration, width: 320, height: 200 }}
          onClick={navigateToHome}
        />
      )
  }
}

export default ErrorList
