import React, { HTMLAttributes } from 'react'
interface ToggleButtonGroupProps extends HTMLAttributes<HTMLDivElement> {
  className?: string
  dataTestId?: string
}

const ToggleButtonGroup = ({ className = '', dataTestId, children, ...rest }: ToggleButtonGroupProps) => {
  return (
    <div
      {...rest}
      className={`relative grid grid-flow-col auto-cols-fr rounded-md ${className}`}
      data-test-id={dataTestId}
    >
      {children}
    </div>
  )
}

export default ToggleButtonGroup
