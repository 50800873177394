import { Events, Hover } from '@phase-software/data-store'
import { PaintType, GradientHandleType, ElementType, GeometryType } from '@phase-software/types'
import { Matrix2D, NO_COMMIT, minmax, lerp, getGradientColorByPosition } from '@phase-software/data-utils'
import {
    Transform2D,
    Vector2,
    Num,
    isInsideCircle,
    PI2,
    Rect2,
    distanceFromPointToLine,
    projectToLine,
    distance,
    projectToEllipse
} from '../math'
import { setGradient } from '../panes'


/** @typedef {import('@phase-software/data-store/src/Element').Element} Element */
/** @typedef {import('@phase-software/data-store/src/component/PaintComponent').default} PaintComponent */
/** @typedef {import('@phase-software/data-store/src/layer/Fill').default} Fill */
/** @typedef {import('@phase-software/data-store/src/layer/Shadow').default} Shadow */
/** @typedef {import('@phase-software/data-store/src/layer/Layer').LayerChanges} LayerChanges */
/** @typedef {import('../visual_server/VisualServer').VisualServer} VisualServer */
/** @typedef {import('../visual_server/RenderItem').RenderItem} RenderItem */
/** @typedef {import('../Viewport').Viewport} Viewport */
/** @typedef {import('./handles/Snapping').Snapping} Snapping */

const GRADIENT_PAINT_SET = new Set([
    PaintType.GRADIENT_LINEAR,
    PaintType.GRADIENT_RADIAL,
    PaintType.GRADIENT_ANGULAR,
    PaintType.GRADIENT_DIAMOND
])

/** @type {string} focused layer item id */
let _focusedLayerId = null

/** @type {Gradient} */
let _gradient = null

/** @type {number} */
let activeGradientStopIdx = -1


/**
 * @param {VisualServer} visualServer
 * @param {import('..').SetLocalCursorStateFn} setLocalCursorState
 */
export function initGradient(visualServer, setLocalCursorState) {
    const { dataStore, viewport, snapping } = visualServer
    const editor = dataStore.editor

    // Listen to LayerItem and paint related prop changes

    /**
     * @param {EditorChanges} changes
     */
    const _onLayerItemChange = changes => {
        if (changes.layerProps.size) {
            for (const layerItemId of changes.layerProps.keys()) {
                if (changes.layerProps.get(layerItemId).has('paintType')) {
                    _setGradientHandles()
                    return
                }
            }
        }
    }

    /**
     * @param {string} before
     */
    const _setGradientHandles = (before) => {
        const options = { commit: !before }
        if (
            editor &&
            _focusedLayerId &&
            GRADIENT_PAINT_SET.has(editor.getLayerProp(_focusedLayerId, 'paintType'))
        ) {
            _gradient = new Gradient(editor, _focusedLayerId)
            setGradient(_gradient)
            dataStore.eam.activateGradientHandlesMode(options)
        } else {
            setGradient(null)
            dataStore.eam.activateElementEditMode(options)
        }
    }

    /**
     * @param {string} focusedLayerId
     * @param {string} before
     */
    const _onFocusedLayerChange = (focusedLayerId, before) => {
        if (focusedLayerId === _focusedLayerId) {
            return
        }
        if (_focusedLayerId) {
            _focusedLayerId = null
        }
        if (focusedLayerId) {
            _focusedLayerId = focusedLayerId
        }
        _setGradientHandles(before)
    }

    editor.on('EDITOR_CHANGES', _onLayerItemChange)
    dataStore.selection.on('focusedLayer', _onFocusedLayerChange)
    dataStore.on('mode', () => {
        if (_focusedLayerId) {
            dataStore.eam.activateElementEditMode()
            _focusedLayerId = null
        }
    })

    dataStore.selection.on('activeGradientStopIdx', (idx) => {
        activeGradientStopIdx = idx
    })

    /** @type {Handle} */
    let hoveredHandle

    dataStore.eam.on(Events.HOVER_GRADIENT_HANDLE, (e) => {
        const first = visualServer.selection.first

        let elementSize
        let offset
        const isLineElement = first.element.get('elementType') === ElementType.PATH && first.element.get('geometryType') === GeometryType.LINE
        if (isLineElement) {
            const { x, y, width, height } = first.node.boundsVisualLocal
            elementSize = new Vector2(width, height)
            offset = new Vector2(-x, -y)
        } else {
            const { width, height } = first.node.boundsLocal
            elementSize = new Vector2(width, height)
            offset = _getOffsetToLeftTop(first)
        }
        const T = new Transform2D(..._gradient.getGradientTransform())
            .affine_inverse()
            .scale(elementSize.x, elementSize.y)
            .translate(-offset.x, -offset.y)
            .prepend(first.node.item.transform.world)
            .prepend(viewport.projectionTransform)

        hoveredHandle = _gradient.searchHandle(e.mousePos, T, viewport.scale)

        e.handled = false

        setLocalCursorState('default')
        if (hoveredHandle) {
            switch (hoveredHandle.type) {
                case GradientHandleType.STOP:
                    setLocalCursorState('default')
                    dataStore.eam.changeHover(Hover.GRADIENT_STOP_HANDLE)
                    break
                case GradientHandleType.GRADIENT:
                    setLocalCursorState('crossMove')
                    dataStore.eam.changeHover(Hover.GRADIENT_TRANSFORM_START_HANDLE)
                    break
                case GradientHandleType.HANDLE:
                    setLocalCursorState('crossMove')
                    dataStore.eam.changeHover(Hover.GRADIENT_TRANSFORM_END_HANDLE)
                    break
                case GradientHandleType.ASPECT_RATIO:
                    setLocalCursorState('crossMove')
                    dataStore.eam.changeHover(Hover.GRADIENT_TRANSFORM_SHAPE_HANDLE)
                    break
                case GradientHandleType.REFERENCE:
                    setLocalCursorState('add')
                    dataStore.eam.changeHover(Hover.GRADIENT_TRANSFORM_REFERENCE_LINE)
                    break
            }
        }
    })

    /** @type {(mousePos: Vector2) => void} */
    let update = null

    dataStore.eam
        .on(Events.START_DRAG_GRADIENT_HANDLE, (e, modifiers, { snapToGrid = true }) => {
            if (hoveredHandle && hoveredHandle.type !== GradientHandleType.REFERENCE) {
                const first = visualServer.selection.first
                if (!first) {
                    return
                }

                if (hoveredHandle.type === GradientHandleType.STOP) {
                    dataStore.eam.setActiveGradientStop(hoveredHandle.idx)
                    dataStore.editor.setLayerProps(
                        _gradient.layerItemId,
                        {
                            gradientStops: _gradient.getGradientStops(),
                            activeGradientStopIdx: hoveredHandle.idx
                        }
                    )
                }
                // console.log('editorKeys', modifiers, _gradient)
                update = gradientUpdateFunc(hoveredHandle, _gradient, first, e.mousePos, viewport, snapping, snapToGrid)
            } else {
                e.handled = false
            }
        })
        .on(Events.UPDATE_DRAG_GRADIENT_HANDLE, (e, modifiers) => {
            // console.log('UPDATE_DRAG_GRADIENT_HANDLE', modifiers)
            update(e.mousePos, modifiers)
        })
        .on(Events.END_DRAG_GRADIENT_HANDLE, () => {
            update = null
            snapping.setEndSnapping()
            dataStore.commitUndo()
        })

    dataStore.eam.on(Events.MOVE_GRADIENT_HANDLE_KEY, (delta) => {
        const newStops = _gradient.getGradientStops().map((stop, idx) => {
            if (idx === _gradient.activeGradientStopIdx) {
                return { ...stop, position: minmax(stop.position + delta / 100, 0, 1) }
            }
            return stop
        })
        dataStore.editor.setLayerProps(
            _gradient.layerItemId,
            { gradientStops: newStops }
        )
    })

    dataStore.eam.on(Events.ADD_NEW_GRADIENT_STOP, () => {
        const stops = _gradient.getGradientStops()
        const color = getGradientColorByPosition(
            stops,
            _gradient.activeGradientStopIdx,
            hoveredHandle.pos
        )

        const newStops = [
            ...stops,
            { color, position: hoveredHandle.pos }
        ]
        _gradient.setGradientStops(newStops)
        _gradient.setLayerProp('activeGradientStopIdx', stops.length)
    })
}

const vec2 = new Vector2()

export const CENTER_LINEAR = new Vector2(0, 0.5)
export const CENTER = new Vector2(0.5, 0.5)
export const BOTTOM = new Vector2(1, 0.5)
export const LEFT = new Vector2(0.5, 1)

const _rect = new Rect2()

export class Gradient {
    /**
     * @param {Editor} editor
     * @param {string} layerItemId
     */
    constructor(editor, layerItemId) {
        this._editor = editor
        this._layerItemId = layerItemId

        const paintType = editor.getLayerProp(layerItemId, 'paintType')
        this.isLinear = paintType === PaintType.GRADIENT_LINEAR
        this.isAngular = paintType === PaintType.GRADIENT_ANGULAR
        this.center = this.isLinear ? CENTER_LINEAR : CENTER
        this.bottom = BOTTOM
        this.left = LEFT

        this.handleSize = 10
        this.stopSize = 12
        this.activeStopSize = 16
        this.referenceSize = 2
        this.stopOutlineSize = 1.5
        this.activeStopOutlineSize = 2
        this.enlargeHitSize = 3
        this.stopHitSize = this.stopSize / 2 + this.stopOutlineSize
        this.activeStopHitSize = this.activeStopSize / 2 + this.activeStopOutlineSize
        this.handleHitSize = this.handleSize
    }

    get layerItemId() {
        return this._layerItemId
    }

    get activeGradientStopIdx() {
        return this._editor.getLayerProp(this._layerItemId, 'activeGradientStopIdx')
    }

    setLayerProp(propKey, value, options) {
        this._editor.setLayerProp(this._layerItemId, propKey, value, options)
    }

    // TODO: @nicktgn make color stops pooled
    /**
     * @returns {ColorStop[]} Sorted copy of gradient stops
     */
    getGradientStops() {
        return this._editor.getLayerProp(this._layerItemId, 'gradientStops', true)
    }

    setGradientStops(value) {
        this.setLayerProp('gradientStops', value, NO_COMMIT)
    }

    getGradientTransform() {
        return this._editor.getLayerProp(this._layerItemId, 'gradientTransform')
    }

    setGradientTransform(value) {
        // TODO: @nicktgn extend Transform2D from Float32Array so we don't need to do this extra instance creation
        //   since it will be copied again in the CL setter anyways
        const v = value instanceof Transform2D ? new Matrix2D().copy(value) : value
        this.setLayerProp('gradientTransform', v, NO_COMMIT)
    }

    /**
     * @param {number} position
     * @param {Vector2} out
     */
    getColorStopPos(position, out) {
        if (this.isAngular) {
            const angle = position * PI2
            out
                .set(Math.cos(angle), Math.sin(angle))
                .add(1, 1).scale(0.5) // map [-1, 1] range to [0, 1]
        } else {
            out.copy(this.center).linear_interpolate(this.bottom, position)
        }
    }

    /**
     * @param {Vector2} mousePos
     * @param {Transform2D} transform
     * @param {number} zoom
     * @returns {Handle}
     */
    searchHandle(mousePos, transform, zoom) {
        // Search for gradient stop
        const stops = this.getGradientStops()
        for (let i = 0; i < stops.length; i++) {
            const gradientStop = stops[i]
            this.getColorStopPos(gradientStop.position, vec2)
            transform.xform(vec2, vec2)

            const size = i === activeGradientStopIdx
                ? this.activeStopHitSize
                : this.stopHitSize
            if (isInsideCircle(mousePos.x, mousePos.y, vec2.x, vec2.y + size / 2, size)) {
                const offset = mousePos.clone().sub(vec2)
                return {
                    type: GradientHandleType.STOP,
                    idx: i,
                    stop: gradientStop,
                    stops,
                    offset
                }
            }
        }

        const VIEWPORT_TRANSFORM_HANDLE_SIZE = this.handleHitSize
        const VIEWPORT_TRANSFORM_HANDLE_AREA = this.handleHitSize * 2

        // Search for transform end handle
        transform.xform(this.bottom, vec2)
        _rect.set(vec2.x - VIEWPORT_TRANSFORM_HANDLE_SIZE, vec2.y - VIEWPORT_TRANSFORM_HANDLE_SIZE * 3 / 4, VIEWPORT_TRANSFORM_HANDLE_AREA, VIEWPORT_TRANSFORM_HANDLE_AREA)
        if (_rect.contains(mousePos.x, mousePos.y)) {
            const offset = mousePos.clone().sub(vec2)
            return {
                type: GradientHandleType.HANDLE,
                pivot: this.center,
                pos: this.bottom,
                offset
            }
        }

        // Search for aspect ratio handle
        transform.xform(this.left, vec2)
        _rect.set(vec2.x - VIEWPORT_TRANSFORM_HANDLE_SIZE, vec2.y - VIEWPORT_TRANSFORM_HANDLE_SIZE / 2, VIEWPORT_TRANSFORM_HANDLE_AREA, VIEWPORT_TRANSFORM_HANDLE_AREA)
        if (!this.isLinear && _rect.contains(mousePos.x, mousePos.y)) {
            const offset = mousePos.clone().sub(vec2)
            return {
                type: GradientHandleType.ASPECT_RATIO,
                offset
            }
        }

        // Search for transform start handle
        transform.xform(this.center, vec2)
        _rect.set(vec2.x - VIEWPORT_TRANSFORM_HANDLE_SIZE, vec2.y - VIEWPORT_TRANSFORM_HANDLE_SIZE * 3 / 4, VIEWPORT_TRANSFORM_HANDLE_AREA, VIEWPORT_TRANSFORM_HANDLE_AREA)
        if (_rect.contains(mousePos.x, mousePos.y)) {
            if (this.isLinear) {
                const offset = mousePos.clone().sub(vec2)
                return {
                    type: GradientHandleType.HANDLE,
                    pivot: this.bottom,
                    pos: this.center,
                    offset
                }
            } else {
                return {
                    type: GradientHandleType.GRADIENT
                }
            }
        }

        // Search for reference line
        if (this.isAngular) {
            // Get transformed center, left, bottom and mouse pos
            const center = transform.xform(this.center)
            const left = transform.xform(this.left).sub(center)
            const bottom = transform.xform(this.bottom).sub(center)
            const mouse = mousePos.clone().sub(center)
            const { dis, per } = projectToEllipse(mouse, left, bottom, center, new Transform2D())

            if (dis < (this.enlargeHitSize + this.referenceSize / 2) / zoom) {
                return {
                    type: GradientHandleType.REFERENCE,
                    pos: per
                }
            }
        } else {
            transform.xform(this.center, vec2)
            const start = vec2.clone()
            transform.xform(this.bottom, vec2)
            const end = vec2.clone()
            const dist = distanceFromPointToLine(mousePos.x, mousePos.y, start.x, start.y, end.x, end.y)
            if (dist < this.enlargeHitSize) {
                projectToLine(mousePos.x, mousePos.y, start.x, start.y, end.x, end.y, vec2)
                const toStart = distance(start.x, start.y, vec2.x, vec2.y)
                const toEnd = distance(end.x, end.y, vec2.x, vec2.y)
                const total = distance(start.x, start.y, end.x, end.y)
                if (toStart < total && toEnd < total) {
                    const pos = lerp(0, 1, toStart / total)
                    return {
                        type: GradientHandleType.REFERENCE,
                        pos: pos
                    }
                }
            }
        }

        return null
    }
}

const initialGradientTransform = new Transform2D()
const _initialMousePos = new Vector2()
const _initMousePosLocal = new Vector2()
const _T = new Transform2D()
const gradientToScreenTransform = new Transform2D()
const gradientCenterOffset = new Vector2()

/**
 * @param {Handle} handle
 * @param {Gradient} gradient
 * @param {SelectedItem} selectedItem
 * @param {Vector2} initialMousePos
 * @param {Viewport} viewport
 * @param {Snapping} snapping
 * @returns {(mousePos: Vector2) => void}
 */
function gradientUpdateFunc(handle, gradient, selectedItem, initialMousePos, viewport, snapping) {
    const { node, element } = selectedItem

    const isLineElement = element.get('elementType') === ElementType.PATH && element.get('geometryType') === GeometryType.LINE
    let offset, elementSize
    if (isLineElement) {
        const { x, y, width, height } = node.boundsVisualLocal
        elementSize = new Vector2(width, height)
        offset = new Vector2(-x, -y)
    } else {
        const { width, height } = node.boundsLocal
        elementSize = new Vector2(width, height)
        offset = _getOffsetToLeftTop(selectedItem)
    }
    initialGradientTransform.copy(gradient.getGradientTransform())
    _T.copy(viewport.invProjectionTransform)
        .prepend(node.item.transform.worldInv)
        .translate(offset.x, offset.y)
        .scale(1 / elementSize.x, 1 / elementSize.y)
        .prepend(initialGradientTransform)

    switch (handle.type) {
        case GradientHandleType.STOP:
            return mousePos => moveStop(mousePos, gradient, handle)
        case GradientHandleType.HANDLE: {
            gradientToScreenTransform.set(..._gradient.getGradientTransform())
                .affine_inverse()
                .scale(elementSize.x, elementSize.y)
                .prepend(node.item.transform.world)
                .prepend(viewport.projectionTransform)
            const gradientCenterLocalRound = viewport.toWorld(gradientToScreenTransform.xform(gradient.center.clone())).round()
            snapping.setSnappingOriginalPos(gradientCenterLocalRound)
            return (mousePos, modifiers, isSnapToGrid = true) =>
                moveHandle(mousePos, gradient, handle, offset, elementSize.x, elementSize.y, selectedItem, viewport, snapping, modifiers, isSnapToGrid)
        }
        case GradientHandleType.GRADIENT: {
            gradientToScreenTransform.set(..._gradient.getGradientTransform())
                .affine_inverse()
                .scale(elementSize.x, elementSize.y)
                .prepend(node.item.transform.world)
                .prepend(viewport.projectionTransform)

            const gradientCenterLocal = viewport.toWorld(gradientToScreenTransform.xform(gradient.center.clone()))
            const gradientCenterLocalRound = gradientCenterLocal.clone().round()

            const gradientCenterScreenRound = viewport.toScreen(gradientCenterLocalRound)
            const gradientCenterScreen = viewport.toScreen(gradientCenterLocal)
            gradientCenterOffset.copy(gradientCenterScreenRound.sub(gradientCenterScreen))
            snapping.setSnappingOriginalPos(gradientCenterLocalRound)

            const worldInitMousePos = viewport.toWorld(initialMousePos).round()
            const localInitMousePos = viewport.toScreen(worldInitMousePos).round()
            _initialMousePos.copy(worldInitMousePos)
            _T.xform(localInitMousePos, _initMousePosLocal)

            return (mousePos, modifiers, isSnapToGrid = true) => moveGradient(mousePos, gradient, viewport, snapping, modifiers, isSnapToGrid)
        }
        case GradientHandleType.ASPECT_RATIO:
            return (mousePos) => changeAspectRatio(mousePos, gradient, handle)
    }
}

const getMouseWithDragOffset = (mousePos, handle) => {
    if (handle.offset) {
        mousePos.sub(handle.offset)
    }
}

const moveStop = (mousePos, gradient, handle) => {
    getMouseWithDragOffset(mousePos, handle)
    const v0 = gradient.bottom.clone().sub(gradient.center)
    const v1 = _T.xform(mousePos).sub(gradient.center)

    if (gradient.isAngular) {
        handle.stop.position = v0.angle_to_2(v1) / PI2
    } else {
        handle.stop.position = Num.clamp(v1.divide(v0).x, 0, 1)
    }

    gradient.setGradientStops(handle.stops)
}

/**
 * @param {*} mousePos
 * @param {*} gradient
 * @param {*} handle
 * @param {Vector2} offset
 * @param {*} width
 * @param {*} height
 * @param {SelectedItem} selectedItem
 * @param {*} viewport
 * @param {*} snapping
 * @param {*} modifiers
 * @param {*} isSnapToGrid
 */
const moveHandle = (mousePos, gradient, handle, offset, width, height, selectedItem, viewport, snapping, modifiers, isSnapToGrid) => {
    const { node } = selectedItem
    const S = new Transform2D(width, 0, 0, height)
    S.translate(-offset.x, -offset.y)
    const mapHandle = initialGradientTransform
        .clone()
        .affine_inverse()
        .prepend(S)

    const mapMouse = viewport.invProjectionTransform
        .clone()
        .prepend(node.item.transform.worldInv)

    getMouseWithDragOffset(mousePos, handle)
    const pivot = mapHandle.xform(handle.pivot)
    if (gradient.isLinear) {
        const linearSnappingOriginPos = node.item.transform.world.xform(pivot)
        snapping.setSnappingOriginalPos(linearSnappingOriginPos)
    }

    const v0 = mapHandle.xform(handle.pos).sub(pivot)
    const v1 = mapMouse.xform(mousePos).sub(pivot)
    if (isSnapToGrid) {
        const pivotOffset = viewport.toWorld(gradientToScreenTransform.xform(handle.pivot))
        const pivotOffsetRound = pivotOffset.clone().round()
        const snapPivotOffset = pivotOffsetRound.clone().sub(pivotOffset)
        v1.round().add(snapPivotOffset)
        const delta = v1.clone()
        if (modifiers.shift) {
            delta.add(pivot)
            node.item.transform.world.xform(delta, delta)
            const pivotWorld = node.item.transform.world.xform(pivot)
            delta.sub(pivotWorld)
        }
        const newDelta = snapping.snapAxisAndDiagonal(delta, modifiers.shift, 'POINT')
        v1.copy(newDelta)
    }
    const delta = v1.angle_to(v0)
    const mult = v0.length() / v1.length()

    const M = S.translate(-pivot.x, -pivot.y)
    const T = new Transform2D()
        .prepend(M)
        .rotate(delta)
        .scale(mult, mult)
        .prepend(M.affine_inverse())

    gradient.setGradientTransform(T.prepend(initialGradientTransform))
}

const moveGradient = (mousePos, gradient, viewport, snapping, modifiers, isSnapToGrid) => {
    // get local position in the element coord
    const worldMousePos = isSnapToGrid ? viewport.toWorld(mousePos).round() : viewport.toWorld(mousePos)
    const offsetWorld = worldMousePos.clone().sub(_initialMousePos)
    const newDelta = snapping.snapAxisAndDiagonal(offsetWorld, modifiers.shift, 'POINT')
    worldMousePos.copy(_initialMousePos).add(newDelta)
    const localMousePos = isSnapToGrid ? viewport.toScreen(worldMousePos).round().add(gradientCenterOffset) : viewport.toScreen(worldMousePos)

    const pos = _T.xform(localMousePos)
    const offset = _initMousePosLocal.clone().sub(pos)

    const T = new Transform2D().set_origin(offset)
    gradient.setGradientTransform(T.append(initialGradientTransform))
}

const changeAspectRatio = (mousePos, gradient, handle) => {
    getMouseWithDragOffset(mousePos, handle)
    const pos = _T.xform(mousePos)
    const mult = (gradient.left.y - gradient.center.y) / (pos.y - gradient.center.y)

    const T = new Transform2D()
        .translate(-gradient.center.x, -gradient.center.y)
        .scale(1, mult)
        .translate(gradient.center.x, gradient.center.y)

    gradient.setGradientTransform(T.append(initialGradientTransform))
}

/**
 * Since new feature - Computed group (normal/mask/boolean) is start from reference point rather than top-left corner,
 * This kind of nodes need an offset value for gradient handles position.
 * For a long term goal, for example, after we let all elements start from reference point, can update this function.
 * @param {SelectedItem} selectedItem
 * @returns {Vector2}
 */
export const _getOffsetToLeftTop = ({ element, node }) => {
    const referencePoint = element.get('referencePoint')
    return node.item.isComputedGroup() ? referencePoint : new Vector2(0, 0)
}

