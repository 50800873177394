import React from 'react'

import Avatar from '../Avatar'
import Icon from '../Icon'
import Tooltip from '../Tooltip'

type SelectableProps =
  | {
      selectable: true
      selected: boolean
    }
  | {
      selectable?: false
      selected?: boolean
    }

type ActivatableProps =
  | {
      activatable: true
      active: boolean
    }
  | {
      activatable?: false
      active?: boolean
    }

type CustomRenderProps =
  | {
      customRender: (optionData: MenuOptionProps) => React.ReactNode
      optionData: MenuOptionProps
    }
  | {
      customRender?: undefined
      optionData?: MenuOptionProps
    }

type BasicProps = {
  value?: string | number // value is not used in MenuOption, but it is used in MenuListOptionProps
  name: string | undefined | null
  description?: string
  icon?: string
  showAvatar?: boolean
  avatarImage?: string | null
  rightComponent?: React.ReactNode
  shortcut?: string

  disabled?: boolean
  onClick?: (e: React.MouseEvent) => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void

  dataTestId?: string
  className?: string
  contentClassName?: string
  [key: string]: any
}

export type MenuOptionProps = BasicProps & SelectableProps & ActivatableProps & CustomRenderProps

const MenuOption = ({
  name,
  description,
  icon,
  showAvatar = false,
  avatarImage,
  rightComponent,
  shortcut,

  optionData,
  customRender,

  disabled = false,
  activatable = false,
  active,
  selectable,
  selected,

  onClick,
  onMouseEnter,
  onMouseLeave,

  dataTestId,
  className = '',
  contentClassName
}: MenuOptionProps) => {
  const selectableClasses = selectable ? 'pl-8 pr-16' : 'px-16'
  const disabledClasses = disabled ? 'opacity-40' : ''
  const hoverClasses = disabled ? '' : 'group-hover:bg-primary-50'

  const handleMouseEnter = () => {
    if (disabled) return
    onMouseEnter?.()
  }

  const handleClick = (e: React.MouseEvent) => {
    if (disabled) {
      return
    }
    onClick?.(e)
  }

  return (
    <div
      data-test-id={dataTestId}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={onMouseLeave}
      className={`group flex items-center min-h-[28px] text-white text-12 px-8 select-none w-full ${
        disabled ? 'cursor-not-allowed' : 'cursor-pointer'
      } ${className}`}
      aria-selected={selected}
      data-highlighted={active}
    >
      <div
        className={`${selectableClasses} ${disabledClasses} ${showAvatar && 'items-center'} ${
          activatable ? active && 'bg-primary-50' : hoverClasses
        }  ${contentClassName || 'flex py-6 rounded-sm w-full'}`}
      >
        {selectable && (
          <Icon
            name="Check"
            className={`${selected ? 'opacity-100' : 'opacity-0'} mr-8`}
            interactive={false}
            useCurrentColor
            data-test-id={`check-icon`}
          />
        )}
        {showAvatar && (
          <Avatar size="m" alt={name || ''} src={avatarImage} className="mr-8 flex-shrink-0" icon={icon} />
        )}
        {customRender && optionData ? (
          customRender(optionData)
        ) : (
          <div className="w-full overflow-hidden">
            <div className="flex">
              {icon && !showAvatar && (
                <Icon name={icon} className="mr-8" interactive={false} data-test-id={`${icon}-icon`} />
              )}
              <Tooltip content={name} visibleOnOverflow>
                <div className="flex-grow truncate max-w-[300px]">{name}</div>
              </Tooltip>
              {shortcut && (
                <div className="ml-32 grid grid-flow-col auto-cols-[12px] text-center text-light-overlay-60">
                  {Array.from(shortcut).map((char) => (
                    <div key={char}>{char}</div>
                  ))}
                </div>
              )}
              {rightComponent}
            </div>
            {description && <div className="text-light-overlay-60">{description}</div>}
          </div>
        )}
      </div>
    </div>
  )
}

export default MenuOption
