import PropertyStack from './PropertyStack'

class OffsetStack extends PropertyStack {
  constructor(elementStack, data) {
    super(elementStack, data)
    this.type = 'OFFSET'
    this.key = 'offset'
    this.dataKey = 'offset'
    this.animatableProperties = new Set(['offset'])
  }
}

export default OffsetStack
