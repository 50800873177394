import React, { Suspense, useEffect, useState } from 'react'
import { render } from 'react-dom'
import { matchPath } from 'react-router-dom'

import { createBrowserHistory } from 'history'

import { ApolloProvider } from '@apollo/client'
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react'
import { captureConsoleIntegration } from '@sentry/integrations'
import * as Sentry from '@sentry/react'
import { wasmIntegration } from '@sentry/wasm'
import { AwsRum } from 'aws-rum-web'
import PropTypes from 'prop-types'
import { parse } from 'query-string'
import { ThemeProvider } from 'styled-components'

import App from './App'
import Broadcaster, { BroadcasterProvider } from './Broadcaster'
import PresenceManager from './Broadcaster/PresenceManager'
import GlobalStyle from './GlobalStyle'
import LoadLanguage from './LoadLanguage'
import { createApolloClient } from './apolloClient'
import { Dialog, Message } from './components/Auth'
import GlobalNotificationMessage from './components/GlobalNotificationMessage'
import initFAIcons from './components/initFAIcons'
import { Button } from './components/shared'
import { configMomentLocale } from './configs/moment'
import {
  useGetPersonalDraftProjectsQuery,
  useOnTeamUpdatedSubscription,
  useOnUserUpdatedSubscription
} from './generated/graphql'
import { FEATURE_KEYS } from './growthbook-feature-keys'
import { configureI18n } from './i18n'
import './index.css'
import NotificationProvider from './providers/NotificationProvider'
import ProfileProvider, { useProfile, useSetProfile } from './providers/ProfileProvider'
import { registerServiceWorker } from './registerServiceWorker'
import routes from './routes'
import { getClientKey, setApiBasePath } from './services/api'
import {
  clearTokens,
  getAuthorize,
  getIdToken,
  getState,
  getToken,
  getTokenPayload,
  isNearlyExpired,
  logout,
  refreshToken,
  setCognitoConfig
} from './services/cognito'
import { initGoogleAnalytics } from './services/googleAnalytics'
import { captureJamMeta } from './services/jam'
import darkTheme from './theme/dark'
import { enableFPSTracker } from './tools/fps'
import { transformTeamList, transformUser } from './utils/transformWorkspaceList'

const history = createBrowserHistory()

registerServiceWorker()
initFAIcons()

captureJamMeta('lang', document.querySelector('html').lang)
captureJamMeta('git-commit', document.querySelector('head > meta[name=git-commit]').content.replace(/-/, ''))

const LoadProfile = ({ userId }) => {
  const setProfile = useSetProfile()

  const { data: userData } = useOnUserUpdatedSubscription({ variables: { id: userId }, skip: !userId })

  const { data: draftProjectsData } = useGetPersonalDraftProjectsQuery({
    variables: { id: userId },
    skip: !userId
  })

  const { data: teamsData } = useOnTeamUpdatedSubscription({
    variables: {
      id: userId
    },
    skip: !userId
  })

  const user = userData?.users?.[0]
  const draftProjectId = draftProjectsData?.projects?.[0]?.id
  const userTeams = teamsData?.teams

  useEffect(() => {
    // ensure the user only update one time
    if (user && draftProjectId && userTeams) {
      const workspaceList = [transformUser(user)].concat(transformTeamList(userTeams))

      setProfile({ ...user, draftProjectId, workspaceList })
    }
  }, [draftProjectId, setProfile, user, userTeams])

  return null
}

LoadProfile.propTypes = {
  userId: PropTypes.string
}

const Index = ({ routes, id, growthBook, apolloClient, broadcaster }) => {
  const isLoaded = useProfile((o) => !!o)
  const [isLanguageLoaded, setIsLanguageLoaded] = useState(false)
  // hide the loading in the index.html
  const overlayLoading = document.querySelector('#overlay-loading')
  useEffect(() => {
    if (isLoaded && isLanguageLoaded && overlayLoading) setTimeout(() => overlayLoading?.remove(), 0)
  }, [isLoaded, isLanguageLoaded, overlayLoading])

  return (
    <GrowthBookProvider growthbook={growthBook}>
      <BroadcasterProvider broadcaster={broadcaster}>
        <ThemeProvider theme={darkTheme}>
          <Suspense fallback={null}>
            <GlobalStyle />
            <NotificationProvider />
            <ProfileProvider />
            <GlobalNotificationMessage>
              <ApolloProvider client={apolloClient}>
                <LoadProfile userId={id} />
                <LoadLanguage
                  userId={id}
                  isLanguageLoaded={isLanguageLoaded}
                  setIsLanguageLoaded={setIsLanguageLoaded}
                />
                {isLoaded && isLanguageLoaded && <App routes={routes} id={id} />}
              </ApolloProvider>
            </GlobalNotificationMessage>
          </Suspense>
        </ThemeProvider>
      </BroadcasterProvider>
    </GrowthBookProvider>
  )
}

Index.propTypes = {
  id: PropTypes.string,
  routes: PropTypes.array,
  growthBook: PropTypes.object,
  broadcaster: PropTypes.object,
  apolloClient: PropTypes.object
}

const renderApp = async ({ userId, growthBook, apolloClient, broadcaster }) => {
  render(
    <Index routes={routes} id={userId} growthBook={growthBook} apolloClient={apolloClient} broadcaster={broadcaster} />,
    document.getElementById('root')
  )

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('./routes', () => {
        const nextRouter = require('./routes').default
        render(
          <Index routes={nextRouter} growthBook={growthBook} apolloClient={apolloClient} broadcaster={broadcaster} />,
          document.getElementById('root')
        )
      })
    }
  }

  const fpsMeter = growthBook.isOn(FEATURE_KEYS.FPS_METER)
  if (fpsMeter) {
    enableFPSTracker()
    const bgConsole = (bg, color, ...args) => {
      const [desc, ...rest] = args
      console.log(`%c ${desc}`, `background: ${bg}; color: ${color};`, ...rest)
    }
    window.bgConsole = bgConsole
  }
}

async function fetchWebConfig() {
  try {
    const response = await fetch('/web-config')
    if (!response.ok) {
      throw new Error(`Error fetching configuration: ${response.statusText}`)
    }
    const config = await response.json()
    return config
  } catch (error) {
    console.error('Failed to fetch configuration:', error)
    return 'null'
  }
}

const init = async () => {
  // check token
  const idToken = getIdToken()
  const { phase_uid: userId, exp, email } = getTokenPayload(idToken)

  const clientKey = await getClientKey()
  // get remote config
  const growthBook = new GrowthBook({
    apiHost: 'https://cdn.growthbook.io',
    clientKey,
    enableDevMode: process.env.NODE_ENV !== 'production',
    attributes: {
      loggedIn: !!userId,
      id: userId,
      email,
      employee: email?.endsWith('phase.com'),
      url: window.location.origin
    }
  })
  await growthBook.loadFeatures()
  const config = growthBook.getFeatureValue(FEATURE_KEYS.WEB_CONFIG)
  const supportedLanguages = growthBook.getFeatureValue(FEATURE_KEYS.AVAILABLE_LANGUAGES, [])
  const languageLoadPath = config.languageLoadPath
  configureI18n(supportedLanguages, languageLoadPath)

  const sentry = growthBook.getFeatureValue(FEATURE_KEYS.SENTRY)
  if (sentry) {
    try {
      const config = await fetchWebConfig()
      if (!config) {
        console.error('Sentry not initialized due to missing config')
        return
      }
    } catch {}
    Sentry.init({
      ...sentry,
      release: config?.build?.frontend, // Use build.frontend as the release key
      beforeSend(event) {
        if (event.exception && sentry.showReportDialog) {
          Sentry.showReportDialog({ eventId: event.event_id })
        }
        return event
      },
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.reactRouterV5BrowserTracingIntegration({ history, routes, matchPath }),
        Sentry.replayIntegration(),
        Sentry.replayCanvasIntegration({
          enableManualSnapshot: true
        }),
        captureConsoleIntegration(['warn', 'error']),
        wasmIntegration()
      ]
    })
  }

  // setup configurations
  const gbRumConfig = growthBook.getFeatureValue(FEATURE_KEYS.AWS_RUM)
  if (gbRumConfig) {
    try {
      const rumApp = gbRumConfig.app
      const awsRumCfg = gbRumConfig.cfg
      const awsRum = new AwsRum(rumApp.id, rumApp.ver, rumApp.region, awsRumCfg)
      window.awsRum = awsRum
    } catch (error) {
      // Ignore errors thrown during CloudWatch RUM web client initialization
    }
  }

  const apolloClient = createApolloClient(config.api)
  setCognitoConfig(config.cognito)
  setApiBasePath(config.api.rest)
  const broadcaster = new Broadcaster(config.api.nats, config.nats.subject, {
    saveEntireFile: !growthBook.getFeatureValue(FEATURE_KEYS.PARTIAL_SAVE)
  })

  await broadcaster.initialize()
  window.PresenceManager = new PresenceManager(broadcaster)

  initGoogleAnalytics(config.googleAnalytics)

  // TODO: use better way to check url is callback or not
  const isCallback = window.location.pathname.endsWith('/callback')
  if (isCallback) {
    const search = window.location.search
    const searchObj = parse(search) ?? {}
    const isStateMatch = searchObj.state === getState()
    const errorMessage = searchObj.error_description
    const isInvalidRequest = searchObj.error === 'invalid_request'
    if (!isStateMatch || isInvalidRequest) {
      const overlayLoading = document.querySelector('#overlay-loading')
      overlayLoading?.remove()
      render(
        <ThemeProvider theme={darkTheme}>
          <GlobalStyle />
          <div className="w-screen h-screen flex justify-center items-center text-white">
            <Dialog>
              <div className="text-h4 font-semibold mb-16">Invalid Request</div>
              {errorMessage && <Message content={errorMessage} />}
              <Button className="mt-16" size="l" fluid onClick={logout}>
                Sign in again
              </Button>
            </Dialog>
          </div>
        </ThemeProvider>,
        document.getElementById('root')
      )
      return
    }

    await getToken(searchObj.code)

    // the public url will be empty string if environment value is '/'
    window.location.replace(process.env.PUBLIC_URL || '/')

    return
  }

  if (!userId) {
    return getAuthorize()
  }

  if (isNearlyExpired(exp)) {
    const success = await refreshToken()
    if (!success) {
      clearTokens()
      getAuthorize()
      return
    }
  }

  await renderApp({ userId, apolloClient, growthBook, broadcaster })
}

const removeTrailingSlash = () => {
  const path = location.pathname

  if (path === '/' || !path.endsWith('/')) {
    return
  }
  const trimmedPathname = location.pathname.replace(/\/$/, '')
  const trimmedURL = trimmedPathname + location.search + location.hash
  location.replace(trimmedURL)
}

removeTrailingSlash()
init()

configMomentLocale()
