import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { MIN_SIZE_VALUE } from '../../../constant'
import { useModal, useSetModal } from '../../../providers/ModalProvider'
import { useDataStore } from '../../../providers/dataStore/DataStoreProvider'
import { formatNumberWithUnit } from '../../../utils/formatter'
import { Modal, ScrollView } from '../../shared'
import { parsePath } from '../utils'
import Header from './Header'

type PathDetailsModalProps = {
  showValues?: boolean
}

const PathDetailsModal = ({ showValues = true }: PathDetailsModalProps) => {
  const ref = React.useRef<HTMLDivElement>(null)
  const MODAL_KEY = `InspectContextMenu-PathDetails`
  const { t } = useTranslation('file', { keyPrefix: 'inspect' })
  const { closeModal } = useSetModal(MODAL_KEY)
  const dataStore = useDataStore()
  const currentMesh = dataStore.selection.get('elements')[0]?.get('geometry')?.get('mesh')
  const verticesData = useMemo(() => {
    if (currentMesh) {
      const path = dataStore.drawInfo?.getMeshOutlinesForExport(currentMesh, [0, 0])
      return parsePath(path, currentMesh.bounds.width < MIN_SIZE_VALUE, currentMesh.bounds.height < MIN_SIZE_VALUE)
    }
    return []
  }, [currentMesh, dataStore])

  const {
    open,
    data: { trigger }
  } = useModal((o: any) => o[MODAL_KEY])

  const closeStrokeAdvancedModal = useCallback(() => {
    closeModal()
  }, [closeModal])

  const getPointPosition = useCallback(
    (pos: number[]) => {
      const selection = dataStore.selection.get('elements')[0]
      const verticesBounds = dataStore.drawInfo.getVerticesBoundWorld(selection)
      const vertexPos = dataStore.drawInfo.vertex2World(selection.data.id, pos)
      const pathPointX = vertexPos[0] - verticesBounds.x
      const pathPointY = vertexPos[1] - verticesBounds.y

      return `${formatNumberWithUnit(pathPointX, 'px', 2)}, ${formatNumberWithUnit(pathPointY, 'px', 2)}`
    },
    [dataStore]
  )

  const getValues = useCallback(
    (vertex: number[], target: number[]) => {
      if (!showValues || (target[0] === 0 && target[1] === 0)) return 'N/A'
      return getPointPosition([vertex[0] + target[0], vertex[1] + target[1]])
    },
    [showValues, getPointPosition]
  )

  const totalPointsUpToIndex = useMemo(() => {
    const totals: number[] = []
    let totalPointsSoFar = 0
    verticesData.forEach((data: any, index: number) => {
      totals[index] = totalPointsSoFar
      totalPointsSoFar += data.segments.length
    })
    return totals
  }, [verticesData])

  const getTotalPointsBeforeIndex = useCallback(
    (index: number) => {
      return totalPointsUpToIndex[index]
    },
    [totalPointsUpToIndex]
  )

  useEffect(() => {
    if (!open) return
    const handleOutsideClick = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        closeModal()
      }
    }
    document.addEventListener('click', handleOutsideClick)
    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [closeModal, open])

  return (
    <Modal open={open} trigger={trigger} onClose={closeStrokeAdvancedModal} placement="horizontal" offsetX={148}>
      {open && (
        <div ref={ref} className="pb-16">
          <Header name={t('path_data_details')} modalKey={MODAL_KEY} />
          {/* @ts-ignore adjust when ScrollView has been refactored */}
          <ScrollView noScrollbar>
            <div className="max-h-[calc(100vh-100px)]">
              {verticesData?.map((data: any, verticesDataIndex: number) =>
                data.segments.map((point: any, index: number) => (
                  <div key={index} className="flex flex-col px-8">
                    <div className="flex p-8 gap-8">
                      <div className="w-[68px] text-light-overlay-60">{`${t('point')} ${
                        index + 1 + getTotalPointsBeforeIndex(verticesDataIndex)
                      }`}</div>
                      <div className="text-white">
                        {showValues ? getPointPosition([point.v[0], point.v[1]]) : 'N/A'}
                      </div>
                    </div>
                    {(point.i[0] !== 0 || point.i[1] !== 0 || point.o[0] !== 0 || point.o[1] !== 0) && (
                      <>
                        <div className="flex">
                          <div className="w-4 h-24 mt-8 ml-12 border-l border-solid border-light-overlay-60" />
                          <div className="flex p-8 gap-8">
                            <div className="w-[52px] text-light-overlay-60">{t('in')}</div>
                            <div className="text-white">{getValues(point.v, point.i)}</div>
                          </div>
                        </div>

                        <div className="flex">
                          <div className="w-4 h-24 mb-8 ml-12 border-l border-solid border-light-overlay-60" />
                          <div className="flex p-8 gap-8">
                            <div className="w-[52px] text-light-overlay-60">{t('out')}</div>
                            <div className="text-white">{getValues(point.v, point.o)}</div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                ))
              )}
            </div>
          </ScrollView>
        </div>
      )}
    </Modal>
  )
}

export default React.memo(PathDetailsModal)
