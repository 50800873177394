import React from 'react'
import { useUser, useSelf } from '../../liveblocks.config'
import ReactionTooltipContentWithDataProps from '../shared/CommentThread/ReactionTooltipContent'

interface ReactionTooltipContentWithDataProps {
  userId: string
  showSeparator: boolean
}

const ReactionTooltipContentWithData = ({ userId, ...props }: ReactionTooltipContentWithDataProps) => {
  const { user }: any = useUser(userId)
  const currentUser = useSelf()

  return (
    <ReactionTooltipContentWithDataProps
      userName={user?.name || ''}
      isCurrentUser={currentUser.id === user?.id}
      {...props}
    />
  )
}

export default ReactionTooltipContentWithData
