type SerializableMapOrSet = { dataType: 'Map' | 'Set'; value: any[] }
/**
 * Transform an object that contains Map and Set into a plain object
 * with values of the Map and Set to be serialized
 *
 * returns an Object value if it is a Map or Set, otherwise return the value
 */
export function replacer(key: string, value: object): object | object[] | SerializableMapOrSet {
  if (value instanceof Map) {
    return {
      dataType: 'Map',
      value: Array.from(value.entries())
    }
  } else if (value instanceof Set) {
    return {
      dataType: 'Set',
      value: Array.from(value.values())
    }
  } else {
    return value
  }
}

/**
 * Revive back to ES6 Map and Set
 * */
export function reviver(key: string, value: object | SerializableMapOrSet) {
  if (typeof value === 'object' && value !== null) {
    const valueAsObject = value as { dataType?: string; value: any }
    if (valueAsObject.dataType === 'Map') {
      return new Map(valueAsObject.value)
    } else if (valueAsObject.dataType === 'Set') {
      return new Set(valueAsObject.value)
    }
  }
  return value
}

const shouldResetTimestamps = (key: string, previousTimestamps: number[]): boolean => {
  if (previousTimestamps && previousTimestamps.length > 0) {
    const now = new Date().getTime()
    const latestTimestamp = previousTimestamps[previousTimestamps.length - 1]
    if (latestTimestamp !== null && latestTimestamp !== undefined && Math.floor((now - latestTimestamp) / 1000) > 10) {
      return true
    }
  }
  return false
}

export const cacheTimestamp = (subject: string, key: string, timestamp: number, previousTimestamps: number[]): void => {
  const tempMap = new Map()

  let timestamps = previousTimestamps
  if (shouldResetTimestamps(key, previousTimestamps)) {
    sessionStorage.removeItem(key)
    timestamps = []
  }
  if (timestamp !== null && timestamp !== undefined) {
    timestamps.push(timestamp)
  }
  tempMap.set(subject, timestamps)
  sessionStorage.setItem(key, JSON.stringify(tempMap, replacer))
}
