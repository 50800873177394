import { EasingType } from '@phase-software/types'

export const TM_PROPS_MAP = {
  mode: 'MODE',
  status: 'STATUS',
  time: 'TIME',
  looping: 'LOOPING',
  endTime: 'END_TIME',
  playbackRate: 'PLAYBACK_RATE',
  viewerTime: 'VIEWER_TIME',
  viewerPlaybackRate: 'VIEWER_PLAYBACK_RATE',
  viewerLoop: 'VIEWER_LOOP',
};

export const IM_PROPS_MAP = {
  'speed': 'playbackRate',
  'looping': 'looping',
  'maxTime': 'endTime',
}

/**
 * @constant
 * @type {object} default easing
 */
export const DEFAULT_EASING = EasingType.EASE;

/**
 * @constant
 * @type {object} default custom easing
 */
export const DEFAULT_CUSTOM_EASING = {
  x1: 0,
  y1: 1,
  x2: 1,
  y2: 0
};

/**
 * @constant
 * @type {object} default custom easing
 */
export const DEFAULT_TRANSITION = {
  delay: 0,
  duration: 0,
  easing: DEFAULT_EASING,
  customEasing: DEFAULT_CUSTOM_EASING
};

// Currently, there is only one event in SCREEN_EVENTS which is the only one
// needed. We should consider how to deal with the case of multiple screen
// events once we need it.
export const CUSTOM_EVENTS = {
  ON_ENTER_SCREEN: 'onenterscreen'
};

/**
 * @constant
 * @type {object} transition mode
 */
export const TRANSITION_MODES = {
  DESIGN: 'DESIGN',
  ACTION: 'ACTION',
  PREVIEW: 'PREVIEW',
  UNKNOWN: 'UNKNOWN'
};

/**
 * @constant
 * @type {object} transition manager status
 */
export const TRANSITION_STATUS = {
  INIT: 'INIT',
  START: 'START',
  PAUSE: 'PAUSE',
  IN_FRAME: 'IN_FRAME',
  STOP: 'STOP'
};

/**
 * @constant
 * @type {object} event type name map
 */
export const EVENT_TYPE_NAME_MAP = {
  CLICK: 'click'
};

/**
 * @constant
 * @type {object} cache type
 */
export const CACHE_TYPE = {
  NORMAL: 'NORMAL',
  NON_INHERITANCE: 'NON_INHERITANCE'
};

export const SWITCH_COMPONENT_PROPS = {
  x: 'translateX',
  y: 'translateY',
  translateX: 'x',
  translateY: 'y'
}

export const DELTA_PROP_KEY_SET = new Set(Object.keys(SWITCH_COMPONENT_PROPS))

export const NON_REPEATABLE_PROPERTIES_ALIAS = {
  motionPath: 'translate',
  width: 'dimensions',
  height: 'dimensions',
  scaleX: 'scale',
  scaleY: 'scale',
  skewX: 'skew',
  skewY: 'skew',
  contentAnchorX: 'contentAnchor',
  contentAnchorY: 'contentAnchor',
  opacity: 'opacity',
  rotation: 'rotation',
  blendMode: 'opacity',
  cornerRadius: 'cornerRadius'
}

export const MERGE_STACK_SUB_PROPERTIES = {
  contentAnchor: ['contentAnchorX', 'contentAnchorY', 'contentAnchorXUnit', 'contentAnchorYUnit']
}

export const MERGE_STACK_ALIAS = {
  contentAnchorX: 'contentAnchor',
  contentAnchorY: 'contentAnchor'
}
