import React from 'react'

import useSharePersonalFileActions from '../../hooks/useSharePersonalFileActions'
import { MenuOptionProps } from '../shared/Menu/MenuOption'
import { TextAreaTagItemProps } from '../shared/TextArea'
import BaseShareDialog from './BaseShareDialog'
import { useShareFileDialogContext } from './ShareFileDialogContextProvider'
import { CollaboratorPermission } from './shareTypes'

const SharePersonalFileDialog = () => {
  const { canEdit, collaboratorList, fileId, linkPathname, name, onClose, open, projectId } =
    useShareFileDialogContext()
  const {
    changePersonalFileCollaboratorPermission,
    removePersonalFileCollaborator,

    sendPersonalFileInvitation,
    resendPersonalFileInvitation,
    changePersonalFileInvitationPermission,
    cancelPersonalFileInvitation
  } = useSharePersonalFileActions()

  const changeCollaboratorPermission = async (collaborator: MenuOptionProps, permission: CollaboratorPermission) => {
    if (collaborator.isPending) {
      return changePersonalFileInvitationPermission({
        fileId,
        projectId,
        invitationId: collaborator.id,
        permission
      })
    }
    return changePersonalFileCollaboratorPermission({
      fileId,
      projectId,
      collaboratorId: collaborator.userId,
      permission
    })
  }

  const inviteCollaborators = async (
    invitedCollaborators: TextAreaTagItemProps[],
    permission: CollaboratorPermission
  ) => {
    try {
      const inviteCollaboratorRequests = invitedCollaborators.map((invitedCollaborator) =>
        sendPersonalFileInvitation({ fileId, projectId, email: invitedCollaborator.value, permission })
      )
      await Promise.all(inviteCollaboratorRequests)
    } catch (error) {
      console.error(error)
    }
  }

  const removeCollaborator = (collaborator: MenuOptionProps) => {
    return removePersonalFileCollaborator({
      fileId,
      projectId,
      collaboratorId: collaborator.userId
    })
  }

  const cancelInvitation = (collaborator: MenuOptionProps) => {
    return cancelPersonalFileInvitation({
      projectId,
      fileId,
      invitationId: collaborator.id
    })
  }

  const resendInvitation = (collaborator: MenuOptionProps) => {
    return resendPersonalFileInvitation({
      projectId,
      fileId,
      invitationId: collaborator.id
    })
  }

  return (
    <BaseShareDialog
      collaboratorList={collaboratorList}
      isEditable={canEdit}
      linkPathname={linkPathname}
      name={name}
      onClose={onClose}
      onCollaboratorPermissionChange={changeCollaboratorPermission}
      onInviteCollaborators={inviteCollaborators}
      onRemoveCollaborator={removeCollaborator}
      onCancelInvitation={cancelInvitation}
      onResendInvitation={resendInvitation}
      open={open}
      fileId={fileId}
      projectId={projectId}
    />
  )
}

export default SharePersonalFileDialog
