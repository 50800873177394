import React, { HTMLAttributes } from 'react'

import Tooltip from './Tooltip'

interface LabelProps extends HTMLAttributes<HTMLDivElement> {
  text?: string
  icon?: React.ReactNode
}

const Label = ({ text, icon, ...rest }: LabelProps) => (
  <div className="grid items-center text-light-overlay-60 grid-cols-[minmax(16px,1fr),auto]" {...rest}>
    {text && (
      <Tooltip content={text} visibleOnOverflow>
        <span className="flex-grow text-12 truncate">{text}</span>
      </Tooltip>
    )}
    {icon}
  </div>
)

export default React.memo(Label)
