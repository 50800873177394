import { mod } from '@phase-software/data-utils'
import { Transform2D } from '../math'
import { loadChar } from '../overlay/Overlay'
const RULER = {
    spacingMultiple: 3,
    size: 20,
    dashSize: 4,
    color: 0xFFFFFF,
    alpha: 0.6,
    borderColor: 0x1F1F22,
    background: 0x0D0C0C,
    fontSize: 10,
    originModeColour: 0x1C6EE8,
    originModeBorderWidth: 2
}

/** @typedef {import('../gfx').Gfx_Image_t} Gfx_Image_t */
/** @typedef {import('../visual_server/VisualServer').VisualServer} VisualServer */
/** @typedef {import('../overlay/Overlay').Pane} Pane */
/** @typedef {import('../Viewport').Viewport} Viewport */

/** @typedef {{ u: number, v: number, w: number, h: number, distW: number, distH: number }} TextImageInfo */

/** @type {VisualServer} the injected visual server */
let _visualServer = null
/** @type {Pane} the pane draws ruler */
let _pane = null

/**
 * @param {VisualServer} visualServer
 * @param {number} index
 */
export function init(visualServer, index) {
    _visualServer = visualServer
    _pane = _visualServer.overlay.createPane(index)
}

export function destroy() { }

/**
 * calculate the closest (nice looking) interval
 * @param {number} size
 * @returns {number} closest interval
 */
function _calculateInterval(size) {
    const sizes = [1, 2, 5, 10, 25, 50, 100, 250, 500, 1000, 2500, 5000]
    let diff = Infinity
    let interval
    for (const compare of sizes) {
        const delta = Math.abs(size - compare)
        if (delta < diff) {
            diff = delta
            interval = compare
        }
    }
    return interval
}

function _drawCorner() {
    const rectSize = RULER.size
    // The sytle of rectangles
    _pane.fillStyle(RULER.background)
    // The rectangle on top-left
    _pane.drawSolidRectTexture(0, 0, rectSize, rectSize)
    _pane.lineStyle(1, RULER.borderColor)
    _pane.drawRect(-0.5, -0.5, rectSize, rectSize)
}

function _drawSides() {
    const w = _visualServer.viewport.width + 1
    const h = _visualServer.viewport.height + 1
    const rectSize = RULER.size
    // The style of rectangles
    _pane.lineStyle(1, RULER.borderColor)
    _pane.fillStyle(RULER.background)
    // The rectangle on top
    _pane.drawSolidRect(-0.5, -0.5, w, rectSize)
    _pane.drawRect(-0.5, -0.5, w, rectSize)
    // The rectangle on left
    _pane.drawSolidRect(-0.5, -0.5, rectSize, h)
    _pane.drawRect(-0.5, -0.5, rectSize, h)
}

/**
 *
 * @param {number} scale
 * @param {number} x
 * @param {number} width
 * @param {boolean} [reverse] If the order should be reversed
 */
function _drawNumbers(scale, x, width, reverse) {
    const sign = reverse ? -1 : 1
    const invScale = 1 / scale
    const left = -x * invScale
    const right = (width - x) * invScale
    const textInfo8 = loadChar("8", "Inter", RULER.fontSize).imageInfo
    // use left and right labels to determine size of numbers and normalize the digits to '8' to avoid flickering
    const textSize = (textInfo8.w / textInfo8.pixelRatio) * (Math.max(Math.abs(Math.ceil(left)).toString().length, Math.abs(Math.ceil(right)).toString().length) + 1)
    const intervalSize = textSize * RULER.spacingMultiple
    const maximumCount = width / intervalSize
    const interval = _calculateInterval((right - left) / maximumCount)
    const intervalDistance = interval * scale
    const startX = mod(-left, interval) * scale
    const y = RULER.size - RULER.dashSize
    const centerY = y * 0.5 + textInfo8.h / textInfo8.pixelRatio * 0.15
    const limit = width + textSize
    const startCoord = interval * Math.ceil(left / interval) * sign
    _pane.lineStyle(1, RULER.color, RULER.alpha)
    _pane.fillStyle(0xFFFFFF)
    let coord = startCoord
    for (let x = startX; x < limit; x += intervalDistance) {
        _pane.drawLine(x, y - 1, x, RULER.size - 1)
        coord += sign * interval
    }
    coord = startCoord
    _pane.fillStyle(RULER.color, RULER.alpha)
    for (let x = startX; x < limit; x += intervalDistance) {
        _pane.drawText(x, centerY, coord.toString(), 4, "center", "center") // ruler text need apply c2 fontSize, so letterSpacing: 0.189=9*0.021
        coord += sign * interval
    }
}

export function update() {
    _pane.clear()

    _drawSides()

    const viewport = _visualServer.viewport
    _drawNumbers(viewport.scale, viewport.x, viewport.width)

    const T = new Transform2D().rotate(-Math.PI * 0.5).translate(0, viewport.height)
    _pane.appendTansform(T)

    _drawNumbers(viewport.scale, viewport.height - viewport.y, viewport.height, true)

    _pane.resetTransform()

    _drawCorner()
}

export function clear() {
    _pane.clear()
}
