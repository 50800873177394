import React from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, Switch, useHistory } from 'react-router-dom'

import { WorkspaceType } from '@phase-software/types'

import { PERMISSIONS, ProtectedRoute, useAccessControl } from '../../access-control'
import { NotificationList } from '../../components/Notification'
import { Icon, NavigationItem, ScrollView, SidebarTitle } from '../../components/shared'
import { useWorkspaceContext } from '../../providers/WorkspaceContextProvider'
import { TEAM_SETTINGS_GENERAL, TEAM_SETTINGS_GENERAL_VIEW_MODE, TEAM_SETTINGS_MEMBERS } from '../../routeConstants'
import {
  getTeamSettingsGeneralPath,
  getTeamSettingsGeneralViewModePath,
  getTeamSettingsIndexPath,
  getTeamSettingsMembersPath,
  getWorkspaceIndexPath
} from '../../utils/pathGenerators'
import TeamGeneral from './TeamGeneral'
import TeamGeneralViewMode from './TeamGeneralViewMode'
import TeamMembers from './TeamMembers'
import { TeamSettingsContextProvider } from './TeamSettingsContext'

const teamSettingWrapperStyle =
  'w-[680px] h-auto min-h-[640px] flex flex-col flex-grow bg-neutral-90 rounded-xl mx-auto my-64'

const TeamSettings = () => {
  const { t } = useTranslation('setting')
  const { workspaceData } = useWorkspaceContext()
  const { userHasPermission } = useAccessControl()
  const history = useHistory()

  const canAccess = userHasPermission(PERMISSIONS.ACCESS_TEAM_GENERAL_PAGE)
  const canEdit = userHasPermission(PERMISSIONS.EDIT_TEAM_GENERAL)

  const getSettingsIndexPath = (canAccess: boolean, canEdit: boolean) => {
    if (!canAccess && !canEdit) {
      return getTeamSettingsIndexPath(workspaceData.slug)
    }

    return canEdit
      ? getTeamSettingsGeneralPath(workspaceData.slug)
      : getTeamSettingsGeneralViewModePath(workspaceData.slug)
  }
  // React Router uses encodeURIComponent for encoding, so it doesn't encode characters like () and *.
  // It's recommended not to use these characters in React Router, as errors can occur when using the Redirect component.
  // However, React Router's issue #10546 explains that if you need to use these characters, you should encode them yourself.
  const encodeSpecialChars = (str: string): string => {
    return str.replace(/\(/g, '%28').replace(/\)/g, '%29').replace(/\*/g, '%2A')
  }
  const workspaceIndexPath = getWorkspaceIndexPath(WorkspaceType.TEAM, workspaceData.slug)
  const settingsIndexPath = getSettingsIndexPath(canAccess, canEdit)
  const encodeWorkspaceIndexPath = encodeSpecialChars(workspaceIndexPath)
  const encodeSettingsIndexPath = encodeSpecialChars(settingsIndexPath)

  return (
    <>
      <NotificationList offsetLeft={248} />
      <div className="grid grid-cols-[248px,1fr] items-stretch h-screen w-screen text-white bg-neutral-90">
        <TeamSettingsContextProvider>
          <div className="flex flex-col bg-neutral-90">
            <SidebarTitle dataTestId="back-to-dashboard" onClick={() => history.push(workspaceIndexPath)}>
              <Icon name="AngleLeft24" size="xxl" interactive={false} />
              <div>{t('back_to_dashboard')}</div>
            </SidebarTitle>

            <NavigationItem to={settingsIndexPath} iconName="Setting">
              {t('team_setting.general')}
            </NavigationItem>

            <NavigationItem to={getTeamSettingsMembersPath(workspaceData.slug)} iconName="Profile">
              {t('team_setting.members')}
            </NavigationItem>
          </div>
          <div className="bg-neutral-80 px-64 overflow-y-auto overflow-x-hidden relative">
            <Switch>
              <ProtectedRoute
                path={TEAM_SETTINGS_GENERAL}
                exact
                perform={PERMISSIONS.EDIT_TEAM_GENERAL}
                unauthorizedUrl={getTeamSettingsGeneralViewModePath(workspaceData.slug)}
              >
                <div className={`${teamSettingWrapperStyle} p-40`}>
                  <p className="text-h3 font-semibold border-b border-neutral-80 pb-16 w-full">
                    {t('team_setting.general')}
                  </p>
                  {/* @ts-ignore TODO: fix after refactor of ScrollView */}
                  <ScrollView className="p-4 -m-4">
                    <TeamGeneral />
                  </ScrollView>
                </div>
              </ProtectedRoute>
              <ProtectedRoute
                path={TEAM_SETTINGS_GENERAL_VIEW_MODE}
                exact
                perform={PERMISSIONS.VIEW_TEAM_GENERAL}
                unauthorizedUrl={getTeamSettingsGeneralPath(workspaceData.slug)}
              >
                <div className={`${teamSettingWrapperStyle} p-40`}>
                  <div className="flex flex-col flex-grow">
                    <p className=" text-h3 font-semibold border-b border-neutral-80 pb-16 w-full">
                      {t('team_setting.general')}
                    </p>
                    {/* @ts-ignore TODO: fix after refactor of ScrollView */}
                    <ScrollView className="p-4 -m-4">
                      <TeamGeneralViewMode />
                    </ScrollView>
                  </div>
                </div>
              </ProtectedRoute>
              <ProtectedRoute
                path={TEAM_SETTINGS_MEMBERS}
                perform={PERMISSIONS.VIEW_TEAM_MEMBER}
                unauthorizedUrl={settingsIndexPath}
              >
                <div
                  className={`${teamSettingWrapperStyle} before:block before:fixed before:bg-neutral-80 before:top-0 before:z-1 before:h-[100px] before:w-[680px]`}
                >
                  <TeamMembers />
                </div>
              </ProtectedRoute>
              <Redirect to={canAccess ? encodeSettingsIndexPath : encodeWorkspaceIndexPath} />
            </Switch>
          </div>
        </TeamSettingsContextProvider>
      </div>
    </>
  )
}

export default TeamSettings
