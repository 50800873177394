import React, { useCallback, useContext, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'

// @ts-ignore
import { TRANSITION_STATUS } from '@phase-software/transition-manager'
import { FrameType } from '@phase-software/types'

import {
  DragAction,
  ScrollDirection,
  activeKeyframeAtom,
  activeKeyframeTimeAtom,
  autoScrollDirectionAtom,
  dragActionAtom,
  highlightSelectedKeyframesAtom,
  highlightedKeyframesAtom,
  selectedKeyframeTimeRangeAtom
} from '../../../atoms'
import { UseDnDMeta, useDrag } from '../../../hooks/useDrag'
import { useSetModal } from '../../../providers/ModalProvider'
import { useTransitionManager } from '../../../providers/TransitionManagerProvider'
import { useDataStore, useDataStoreActions } from '../../../providers/dataStore/DataStoreProvider'
import { useInteractionActions } from '../../../providers/dataStore/InteractionProvider'
import { useKeyframeSelection } from '../../../providers/dataStore/KeyframeSelectionProvider'
import { KeyFrame, Tooltip } from '../../shared'
import { MINIMAL_MOVEMENT, SNAP_RATIO, easingTypeNameMap } from '../constant'
import { SizeContext } from '../contexts'
import { getScrollDirection, getSnappedTime, getTimeAtStep } from '../utils'
import { KeyframeStretchHandle } from './KeyframeStretchHandle'

type KeyFrameProps = {
  time: number
  timeToPixelRatio: number
  trackId: string
}

interface KeyFrame {
  easingType: keyof typeof easingTypeNameMap
  frameType: FrameType
}

const useZIndexAtom = (selected: boolean, time: number) => {
  return useMemo(
    () =>
      atom((get) => {
        const activeTime = get(activeKeyframeTimeAtom)
        const { start, end } = get(selectedKeyframeTimeRangeAtom)
        if (time === activeTime) {
          return 'z-40'
        }
        if (time === start || time === end) {
          return 'z-30'
        }
        if (selected) {
          return 'z-20'
        }
        return 'z-10'
      }),
    [time, selected]
  )
}

const findIntersection = (array1: string[], array2: string[]): string[] => {
  const set1 = new Set(array1)
  const intersection = array2.filter((item) => set1.has(item))
  return intersection
}

const TrackKeyframe = ({ time, timeToPixelRatio, trackId }: KeyFrameProps) => {
  const { dataStore } = useDataStore()

  const { selectKeyframeSelection, addKeyframeSelection, toggleKeyframeSelection } = useDataStoreActions()
  const {
    getKeyFrame,
    getAggregateKeyFrame,
    setKeyframeSelectionTimeByOffset,
    getSelectedKeyframesTimeRange,
    getAllKeyframeTimeList,
    stretchKeyframeList,
    getKeyframeIdList
  } = useInteractionActions()
  const { openModal } = useSetModal('KeyframeContextMenu')
  const selectedKeyframeIdList = useKeyframeSelection()
  const status = useTransitionManager((o) => o.status)
  const { t } = useTranslation('file', { keyPrefix: 'easing_type' })

  const keyframeIdList = getKeyframeIdList(trackId, time)
  const aggregateKeyframe = getAggregateKeyFrame(keyframeIdList) as KeyFrame

  const setAutoScrollDirection = useSetAtom(autoScrollDirectionAtom)
  const setActiveKeyframe = useSetAtom(activeKeyframeAtom)
  const setDragAction = useSetAtom(dragActionAtom)
  const [highlightedKeyframes, setHighlightedKeyframes] = useAtom(highlightedKeyframesAtom)
  const highlightSelectedKeyframes = useSetAtom(highlightSelectedKeyframesAtom)

  const ref = useRef(null)
  const [showTip, setShowTip] = useState(true)
  const { scale, duration } = useContext(SizeContext)

  // while dragging KF overlap another KF, should display selected style
  const isSelected = keyframeIdList.some((id: string) => selectedKeyframeIdList.includes(id))
  const zIndex = useAtomValue(useZIndexAtom(isSelected, time))

  const snapThreshold = Math.max(10, getTimeAtStep((SNAP_RATIO * duration) / scale))

  const keyframePosition = time * timeToPixelRatio
  const startStretchKeyframe = useDrag({
    containerSelector: '.pt-action-track-list',
    onStart: useCallback(
      (e: React.MouseEvent<HTMLElement>, meta: UseDnDMeta, setData: (data: any) => void) => {
        e.stopPropagation()

        if (e.button === 2) {
          return false
        }

        const snappableTimeList = getAllKeyframeTimeList(true)

        const trackList = meta.container
        const bounds = trackList
          ? trackList.getBoundingClientRect()
          : { left: 0, top: 0, width: window.innerWidth, height: window.innerHeight }

        const { min, max } = getSelectedKeyframesTimeRange()

        highlightSelectedKeyframes()

        const dir = e.currentTarget.dataset.dir || null
        setDragAction(DragAction.STRETCH)
        setActiveKeyframe({
          handle: dir,
          time: dir === 'left' ? min : max
        })
        setData({
          trackList,
          scrollLeft: trackList ? trackList.scrollLeft : 0,
          keyframeList: selectedKeyframeIdList,
          snappableTimeList,
          dir,
          bounds,
          startTime: min,
          endTime: max
        })
      },
      [
        setDragAction,
        getAllKeyframeTimeList,
        highlightSelectedKeyframes,
        getSelectedKeyframesTimeRange,
        selectedKeyframeIdList,
        setActiveKeyframe
      ]
    ),
    onUpdate: useCallback(
      (e: MouseEvent, meta: UseDnDMeta) => {
        const scrollDiff = meta.data.trackList?.scrollLeft - meta.data.scrollLeft
        const diffMs = getTimeAtStep((meta.dx + scrollDiff) / timeToPixelRatio)

        if (meta.data.dir === 'left') {
          const startTime = getSnappedTime(
            meta.data.startTime + diffMs,
            e.shiftKey ? meta.data.snappableTimeList : [],
            snapThreshold
          )
          const endTime = meta.data.endTime
          setActiveKeyframe({
            handle: meta.data.dir,
            time: Math.max(Math.min(startTime, duration), 0)
          })
          stretchKeyframeList(meta.data.keyframeList, startTime, endTime, meta.finished)
        } else {
          const startTime = meta.data.startTime
          const endTime = getSnappedTime(
            meta.data.endTime + diffMs,
            e.shiftKey ? meta.data.snappableTimeList : [],
            snapThreshold
          )
          setActiveKeyframe({
            handle: meta.data.dir,
            time: Math.max(Math.min(endTime, duration), 0)
          })
          stretchKeyframeList(meta.data.keyframeList, startTime, endTime, meta.finished)
        }

        const direction = getScrollDirection(e, meta.data.bounds)
        setAutoScrollDirection(direction)
      },
      [setAutoScrollDirection, duration, stretchKeyframeList, setActiveKeyframe, timeToPixelRatio, snapThreshold]
    ),
    onEnd: useCallback(() => {
      setAutoScrollDirection(new Set<ScrollDirection>())
      setHighlightedKeyframes(new Set())
      setDragAction(DragAction.NONE)
      setActiveKeyframe({ handle: null, time: null })

      dataStore.commitUndo()
    }, [setAutoScrollDirection, setHighlightedKeyframes, setDragAction, setActiveKeyframe, dataStore])
  })

  const startMovingKeyframe = useDrag({
    containerSelector: '.pt-action-track-list',
    onStart: useCallback(
      (e: React.MouseEvent<HTMLElement>, meta: UseDnDMeta, setData: (data: any) => void) => {
        e.stopPropagation()
        const snappableTimeList = getAllKeyframeTimeList(true)
        const snapThreshold = Math.max(10, getTimeAtStep((SNAP_RATIO * duration) / scale))

        // update selection
        let newSelectedKeyframeIdList = selectedKeyframeIdList
        if (e.metaKey || e.ctrlKey) {
          newSelectedKeyframeIdList = toggleKeyframeSelection(keyframeIdList)
        } else if (e.shiftKey) {
          newSelectedKeyframeIdList = addKeyframeSelection(keyframeIdList)
        } else if (!isSelected) {
          newSelectedKeyframeIdList = selectKeyframeSelection(keyframeIdList)
        }

        if (e.button === 2) {
          return false
        }

        const trackList = meta.container
        const bounds = trackList
          ? trackList.getBoundingClientRect()
          : { left: 0, top: 0, width: window.innerWidth, height: window.innerHeight }

        const scrollLeft = trackList?.scrollLeft || 0
        const wasPlaying = status === TRANSITION_STATUS.START

        setActiveKeyframe({ handle: 'keyframe', time: null })
        setDragAction(DragAction.MOVE)
        setShowTip(false)
        setData({
          wasPlaying,
          scrollLeft,
          trackList,
          snappableTimeList,
          snapThreshold,
          keyframeList: newSelectedKeyframeIdList,
          bounds,
          prevClientX: e.clientX,
          delta: 0
        })
      },
      [
        setActiveKeyframe,
        setDragAction,
        setShowTip,
        getAllKeyframeTimeList,
        selectKeyframeSelection,
        toggleKeyframeSelection,
        isSelected,
        scale,
        status,
        addKeyframeSelection,
        duration,
        keyframeIdList,
        selectedKeyframeIdList
      ]
    ),
    onUpdate: useCallback(
      (e: MouseEvent, meta: UseDnDMeta) => {
        // FIXME: rewrite setKeyframeSelectionTimeByOffset to be like stretchKeyframeList
        const intersectedKeyframeIdList = findIntersection(keyframeIdList, selectedKeyframeIdList)
        const initialTime = getKeyFrame(intersectedKeyframeIdList[0] || keyframeIdList[0])?.time

        // if delete keyframe while dragging, initialTime will be undefined
        if (initialTime === undefined || !trackId) {
          return
        }

        const scrollDiff = meta.data.trackList?.scrollLeft - meta.data.scrollLeft
        const mouseClickOffsetX = meta.sx - keyframePosition
        let newTime = getTimeAtStep((scrollDiff + e.clientX - mouseClickOffsetX) / timeToPixelRatio)
        meta.data.delta += e.clientX - meta.data.prevClientX

        if (
          (Math.abs(meta.data.delta) < MINIMAL_MOVEMENT || newTime === initialTime) &&
          !meta.finished &&
          !scrollDiff
        ) {
          return
        }

        newTime = e.shiftKey ? getSnappedTime(newTime, meta.data.snappableTimeList, meta.data.snapThreshold) : newTime
        setKeyframeSelectionTimeByOffset(newTime - initialTime, meta.finished)
        setActiveKeyframe({ handle: 'keyframe', time: newTime })

        const direction = getScrollDirection(e, meta.data.bounds)
        setAutoScrollDirection(direction)

        meta.data.delta = 0
        meta.data.prevClientX = e.clientX
      },
      [
        setAutoScrollDirection,
        getKeyFrame,
        setActiveKeyframe,
        setKeyframeSelectionTimeByOffset,
        timeToPixelRatio,
        keyframeIdList,
        selectedKeyframeIdList,
        keyframePosition,
        trackId
      ]
    ),
    onEnd: useCallback(() => {
      setAutoScrollDirection(new Set<ScrollDirection>())
      setHighlightedKeyframes(new Set())
      dataStore.commitUndo()
      setDragAction(DragAction.NONE)
      setActiveKeyframe({ handle: null, time: null })
    }, [setAutoScrollDirection, setHighlightedKeyframes, dataStore, setDragAction, setActiveKeyframe])
  })

  if (!aggregateKeyframe) {
    return null
  }

  // should display explicit style if Mix
  const aggregateKeyframeType = aggregateKeyframe.frameType === FrameType.INITIAL ? 'INITIAL' : 'EXPLICIT'

  const shouldHighlight = keyframeIdList.some((kfId: string) => highlightedKeyframes.has(kfId))

  return (
    <div
      ref={ref}
      className={`js-kf absolute cursor-pointer left-0 flex items-center justify-center w-16 h-16 ${zIndex}`}
      data-time={time}
      data-track-id={trackId}
      data-test-id={`keyframe-indicator-${time}-${trackId}`}
      style={{ transform: `translateX(calc(${keyframePosition}px - 50%))` }}
      onContextMenu={() => openModal({ trigger: ref })}
      onMouseDown={startMovingKeyframe}
    >
      {isSelected && <KeyframeStretchHandle dir="left" onDrag={startStretchKeyframe} time={time} />}
      <Tooltip content={showTip ? t(easingTypeNameMap[aggregateKeyframe.easingType]) : ''}>
        <KeyFrame type={aggregateKeyframeType} selected={isSelected} highlighted={shouldHighlight} />
      </Tooltip>
      {isSelected && <KeyframeStretchHandle dir="right" onDrag={startStretchKeyframe} time={time} />}
    </div>
  )
}

export default React.memo(TrackKeyframe)
