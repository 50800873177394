/**
 * Get origin value in real pixel
 * @param {object} referencePoint
 * @param {number} referencePoint.referencePointX
 * @param {number} referencePoint.referencePointY
 * @param {object} contentAnchor
 * @param {number} contentAnchor.contentAnchorX
 * @param {number} contentAnchor.contentAnchorY
 * @returns {object}
 */
export const getPivotOffset = (referencePoint, contentAnchor) => {
  return {
    originX: referencePoint.referencePointX + contentAnchor.contentAnchorX,
    originY: referencePoint.referencePointY + contentAnchor.contentAnchorY
  }
}
