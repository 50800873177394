import { EffectType } from '@phase-software/types'
import { notNull } from '@phase-software/data-utils'
import { DEFAULT_EFFECT_VALUE } from '../constant'
import Effect from './Effect'


export default class TrimPath extends Effect {
    /**
     * @param {DataStore} dataStore
     * @param {TrimPathData} data
     * @param {object} [options] 
     * @param {bool} [options.regenId=false]   if set to true, will generate new ID
     */
    constructor(dataStore, data = {}, options) {
        super(dataStore, data, options)

        const { start, end, offset, mode } = data

        this.start = notNull(start) ? start : DEFAULT_EFFECT_VALUE[EffectType.TRIM_PATH].start
        this.end = notNull(end) ? end : DEFAULT_EFFECT_VALUE[EffectType.TRIM_PATH].end
        this.offset = notNull(offset) ? offset : DEFAULT_EFFECT_VALUE[EffectType.TRIM_PATH].offset
        this.mode = notNull(mode) ? mode : DEFAULT_EFFECT_VALUE[EffectType.TRIM_PATH].mode

        if (!this.name) {
            this.name = 'trimPath'
        }
        if (!this.effectType || this.effectType === 'EFFECT') {
            this.effectType = EffectType.TRIM_PATH
        }
    }

    /**
     * @param {Partial<TrimPathData>} data
     */
    set(data) {
        super.set(data)

        if (notNull(data.start)) {
            this.updateProp('start', data.start)
        }
        if (notNull(data.end)) {
            this.updateProp('end', data.end)
        }
        if (notNull(data.offset)) {
            this.updateProp('offset', data.offset)
        }
        if (notNull(data.mode)) {
            this.updateProp('mode', data.mode)
        }
    }

    /**
     * Clones Trim Path Effect component
     * @returns {TrimPath}
     */
    clone() {
        const obj = super.clone()
        obj.start = this.start
        obj.end = this.end
        obj.offset = this.offset
        obj.mode = this.mode
        return obj
    }

    /**
     * Save Trim Path Effect
     * @returns {TrimPathData}
     */
    save() {
        const obj = super.save()
        obj.start = this.start
        obj.end = this.end
        obj.offset = this.offset
        obj.mode = this.mode
        return obj
    }
}


/**
 * @typedef {TrimPathData} TrimPathData
 * @property {number} start
 * @property {number} end
 * @property {number} offset
 * @property {TrimPathMode} mode
 */
