import { IRNode } from './IRNode'

export class IRRectangle extends IRNode {
  static props = [
    ...super.props,
    { key: 'width', animatable: true, defaultValue: 0 },
    { key: 'height', animatable: true, defaultValue: 0 },
    { key: 'cornerRadius', animatable: true, defaultValue: 0 }
  ]
}
