/* tslint:disable */
/* eslint-disable */
/**
 * PHASE API
 * Phase API service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ChangePassword,
  CreateOrLinkUserRequest,
  CreateUserResponse,
  GenerateFileOperateUrlRequest,
  GenerateFileOperateUrlResponse,
  MailInWhiteListResponse,
  PatchUserRequest,
  UpdateUserRequest,
  UpsertUserPreference,
  UserResponse,
} from '../models';

export interface UserApiControllerChangePasswordRequest {
    userId: string;
    changePassword: ChangePassword;
}

export interface UserApiControllerCheckIfMailInWhiteListRequest {
    email: string;
}

export interface UserApiControllerCreateOrLinkUserWhenSignupRequest {
    createOrLinkUserRequest: CreateOrLinkUserRequest;
}

export interface UserApiControllerCreateUserRequest {
    body: object;
}

export interface UserApiControllerDeleteUserRequest {
    userId: string;
}

export interface UserApiControllerGenerateFileOperateUrlRequest {
    userId: string;
    generateFileOperateUrlRequest: GenerateFileOperateUrlRequest;
}

export interface UserApiControllerGetUserRequest {
    userId: string;
}

export interface UserApiControllerGetUserByMailRequest {
    email: string;
}

export interface UserApiControllerPatchUserRequest {
    userId: string;
    patchUserRequest: PatchUserRequest;
}

export interface UserApiControllerUpdateUserRequest {
    userId: string;
    updateUserRequest: UpdateUserRequest;
}

export interface UserApiControllerUpsertUserPreferenceRequest {
    userId: string;
    preferenceKey: string;
    upsertUserPreference: UpsertUserPreference;
}

/**
 * 
 */
export class UserApi extends runtime.BaseAPI {

    /**
     */
    async userApiControllerChangePasswordRaw(requestParameters: UserApiControllerChangePasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling userApiControllerChangePassword.');
        }

        if (requestParameters.changePassword === null || requestParameters.changePassword === undefined) {
            throw new runtime.RequiredError('changePassword','Required parameter requestParameters.changePassword was null or undefined when calling userApiControllerChangePassword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/users/{userId}:changePassword`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.changePassword,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async userApiControllerChangePassword(requestParameters: UserApiControllerChangePasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.userApiControllerChangePasswordRaw(requestParameters, initOverrides);
    }

    /**
     */
    async userApiControllerCheckIfMailInWhiteListRaw(requestParameters: UserApiControllerCheckIfMailInWhiteListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MailInWhiteListResponse>> {
        if (requestParameters.email === null || requestParameters.email === undefined) {
            throw new runtime.RequiredError('email','Required parameter requestParameters.email was null or undefined when calling userApiControllerCheckIfMailInWhiteList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/_private/v1/users/whitelist/{email}`.replace(`{${"email"}}`, encodeURIComponent(String(requestParameters.email))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async userApiControllerCheckIfMailInWhiteList(requestParameters: UserApiControllerCheckIfMailInWhiteListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MailInWhiteListResponse> {
        const response = await this.userApiControllerCheckIfMailInWhiteListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async userApiControllerCreateOrLinkUserWhenSignupRaw(requestParameters: UserApiControllerCreateOrLinkUserWhenSignupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateUserResponse>> {
        if (requestParameters.createOrLinkUserRequest === null || requestParameters.createOrLinkUserRequest === undefined) {
            throw new runtime.RequiredError('createOrLinkUserRequest','Required parameter requestParameters.createOrLinkUserRequest was null or undefined when calling userApiControllerCreateOrLinkUserWhenSignup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/_private/v1/users/user/create-or-link`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.createOrLinkUserRequest,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async userApiControllerCreateOrLinkUserWhenSignup(requestParameters: UserApiControllerCreateOrLinkUserWhenSignupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateUserResponse> {
        const response = await this.userApiControllerCreateOrLinkUserWhenSignupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async userApiControllerCreateUserRaw(requestParameters: UserApiControllerCreateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateUserResponse>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling userApiControllerCreateUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/_private/v1/cognito-users`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async userApiControllerCreateUser(requestParameters: UserApiControllerCreateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateUserResponse> {
        const response = await this.userApiControllerCreateUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async userApiControllerDeleteUserRaw(requestParameters: UserApiControllerDeleteUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling userApiControllerDeleteUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async userApiControllerDeleteUser(requestParameters: UserApiControllerDeleteUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.userApiControllerDeleteUserRaw(requestParameters, initOverrides);
    }

    /**
     */
    async userApiControllerGenerateFileOperateUrlRaw(requestParameters: UserApiControllerGenerateFileOperateUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GenerateFileOperateUrlResponse>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling userApiControllerGenerateFileOperateUrl.');
        }

        if (requestParameters.generateFileOperateUrlRequest === null || requestParameters.generateFileOperateUrlRequest === undefined) {
            throw new runtime.RequiredError('generateFileOperateUrlRequest','Required parameter requestParameters.generateFileOperateUrlRequest was null or undefined when calling userApiControllerGenerateFileOperateUrl.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/users/{userId}:generateFileOperateUrl`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.generateFileOperateUrlRequest,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async userApiControllerGenerateFileOperateUrl(requestParameters: UserApiControllerGenerateFileOperateUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GenerateFileOperateUrlResponse> {
        const response = await this.userApiControllerGenerateFileOperateUrlRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async userApiControllerGetUserRaw(requestParameters: UserApiControllerGetUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserResponse>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling userApiControllerGetUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/_private/v1/users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async userApiControllerGetUser(requestParameters: UserApiControllerGetUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserResponse> {
        const response = await this.userApiControllerGetUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async userApiControllerGetUserByMailRaw(requestParameters: UserApiControllerGetUserByMailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserResponse>> {
        if (requestParameters.email === null || requestParameters.email === undefined) {
            throw new runtime.RequiredError('email','Required parameter requestParameters.email was null or undefined when calling userApiControllerGetUserByMail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/_private/v1/users/info/{email}`.replace(`{${"email"}}`, encodeURIComponent(String(requestParameters.email))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async userApiControllerGetUserByMail(requestParameters: UserApiControllerGetUserByMailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserResponse> {
        const response = await this.userApiControllerGetUserByMailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async userApiControllerPatchUserRaw(requestParameters: UserApiControllerPatchUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling userApiControllerPatchUser.');
        }

        if (requestParameters.patchUserRequest === null || requestParameters.patchUserRequest === undefined) {
            throw new runtime.RequiredError('patchUserRequest','Required parameter requestParameters.patchUserRequest was null or undefined when calling userApiControllerPatchUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.patchUserRequest,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async userApiControllerPatchUser(requestParameters: UserApiControllerPatchUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.userApiControllerPatchUserRaw(requestParameters, initOverrides);
    }

    /**
     */
    async userApiControllerUpdateUserRaw(requestParameters: UserApiControllerUpdateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling userApiControllerUpdateUser.');
        }

        if (requestParameters.updateUserRequest === null || requestParameters.updateUserRequest === undefined) {
            throw new runtime.RequiredError('updateUserRequest','Required parameter requestParameters.updateUserRequest was null or undefined when calling userApiControllerUpdateUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/_private/v1/users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.updateUserRequest,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async userApiControllerUpdateUser(requestParameters: UserApiControllerUpdateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.userApiControllerUpdateUserRaw(requestParameters, initOverrides);
    }

    /**
     */
    async userApiControllerUpsertUserPreferenceRaw(requestParameters: UserApiControllerUpsertUserPreferenceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling userApiControllerUpsertUserPreference.');
        }

        if (requestParameters.preferenceKey === null || requestParameters.preferenceKey === undefined) {
            throw new runtime.RequiredError('preferenceKey','Required parameter requestParameters.preferenceKey was null or undefined when calling userApiControllerUpsertUserPreference.');
        }

        if (requestParameters.upsertUserPreference === null || requestParameters.upsertUserPreference === undefined) {
            throw new runtime.RequiredError('upsertUserPreference','Required parameter requestParameters.upsertUserPreference was null or undefined when calling userApiControllerUpsertUserPreference.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/users/{userId}/preferences/{preferenceKey}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))).replace(`{${"preferenceKey"}}`, encodeURIComponent(String(requestParameters.preferenceKey))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.upsertUserPreference,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async userApiControllerUpsertUserPreference(requestParameters: UserApiControllerUpsertUserPreferenceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.userApiControllerUpsertUserPreferenceRaw(requestParameters, initOverrides);
    }

}
