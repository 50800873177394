import { getMatchedResponse } from '../utils/data';

/** @typedef {import('@phase-software/data-store/src/DataStore').DataStore} DataStore */
/** @typedef {import('@phase-software/data-store/src/interaction/index').Manager} Interaction */
/** @typedef {import('../index').default} TransitionManager */

class Event {

  /**
   * @param {TransitionManager} manager
   */
  constructor(manager) {
    /** @type {TransitionManager} */
    this.manager = manager
    this.eventBindings = {}
  }

  /** @returns {DataStore} */
  get dataStore() {
    return this.manager.dataStore
  }

  /**
   * Get first event handler
   * @returns {Array}
   */
  getFirstEvent() {
    return Object.values(this.eventBindings)[0]
  }

  /**
   * Bind Actions
   * @param {Action[]} actions
   */
  bindActions(actions) {
    actions.forEach((action) => {
      this.bindAction(action)
    })
  }

  /**
   * Bind action
   * @param {Action} action
   */
  bindAction(action) {
    const { triggerList, responseList } = action
    const triggers = triggerList.map((triggerId) => this.dataStore.interaction.getTrigger(triggerId))
    const responses = responseList.map((responseId) => this.dataStore.interaction.getResponse(responseId))
    triggers.forEach((trigger) => {
      this.bindActionTrigger(trigger, responses)
    })
  }

  /**
   * Bind action trigger event
   * @param {Trigger} trigger
   * @param {Response[]} responses
   */
  bindActionTrigger(trigger, responses) {
    const { triggerType, options, elementId } = trigger
    const element = this.dataStore.getElement(elementId)
    if (!element) {
      return;
    }

    // TODO: triggerType is enum, might have problem for event binding
    const handler = this.getEventHandler(responses, options.timing);
    element.on(triggerType, handler);

    if (!this.eventBindings[elementId]) {
      this.eventBindings[elementId] = [];
    }
    this.eventBindings[elementId].push({
      type: triggerType,
      handler
    });
  }


  /**
   * Bind events for all Elements in Actions
   */
  unregisterEvents() {
    Object.keys(this.eventBindings).forEach((elementId) => {
      this.eventBindings[elementId].forEach((event) => {
        const element = this.dataStore.getElement(elementId);
        element.off(event.type, event.handler);
      });
    });
    this.eventBindings = {};
  }

  /**
   * Get Event handler for Element
   * @param {Response[]} responses
   * @param {Timing} timing
   * @returns {Function}
   */
  getEventHandler(responses, timing) {
    const availableTiming = []
    if (timing === 'START_AND_END') {
      availableTiming.push('START', 'END')
    } else {
      availableTiming.push(timing)
    }
    return (e, t) => {
      if (!availableTiming.includes(t)) {
        return
      }
      const response = getMatchedResponse(this.dataStore, responses)
      this.execResponse(response)
    };
  }

  /**
   * exec response
   * @param {Response} response
   */
  execResponse(response) { // FIXME: better method name
    this.tracksAnimation(response);
  }

  /**
   * Do tracks animation
   * @param {Response} response
   */
  tracksAnimation(response) {
    response.elementTrackMap.forEach((elementTrackId) => {
      const elementTrack = this.dataStore.interaction.getElementTrack(elementTrackId)
      this.manager.cache.assignElementActions(elementTrack)
    })
  }

  /**
   * Clear event cache
   */
  clear() {
    this.unregisterEvents();
  }
}

export default Event;
