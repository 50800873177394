import React, { useCallback, useMemo } from 'react'

import { LayerType } from '@phase-software/types'
import PropTypes from 'prop-types'

import { useDataStoreActions } from '../../../../providers/dataStore/DataStoreProvider'
import { useEditorActions } from '../../../../providers/dataStore/EditorProvider'
import { useStroke } from '../../../../providers/dataStore/StrokeProvider'
import { parseToDecimals } from '../../../../utils/number'
import { commaSeparatedValidator } from '../../../../utils/validator'
import { Input } from '../../../shared'

const formatter = (value: string) => {
  return getValues(value).map((v) => parseToDecimals(v, 2))
}

const getValues = (value: string) => {
  const newGaps = value.split(',')
  const result = []
  for (let i = 0; i < newGaps.length; i++) {
    const v = parseFloat(newGaps[i].trim()) || 0
    result.push(v < 0 ? 0 : v)
  }

  return result
}

type GapInputProps = {
  layerItemId: string
}

const GapInput = ({ layerItemId, ...rest }: GapInputProps) => {
  const gap = useStroke((o) => o[layerItemId].gap)
  const { setLayerGap, toggleLayerVisible } = useEditorActions()
  const { commitUndo } = useDataStoreActions()

  const handleChange = useCallback(
    (value: string) => {
      if (commaSeparatedValidator(value)) {
        return
      }

      const newGaps = getValues(value)
      setLayerGap(layerItemId, newGaps)
      commitUndo()
    },
    [setLayerGap, commitUndo, layerItemId]
  )

  const _gap = gap.length ? gap.map((v: number) => (v < 0 ? 0 : parseToDecimals(v, 2))).join(', ') : '0'

  const keyFrameIconProps = useMemo(
    () => ({
      layerItemId,
      name: 'gap',
      layerType: LayerType.STROKE
    }),
    [layerItemId]
  )

  return (
    <Input
      // @ts-ignore TODO: fix after refactor of Input
      variant="normal"
      keyFrameIconProps={keyFrameIconProps}
      name="gap"
      data-test-id="border-gap-input"
      formatter={formatter}
      value={_gap}
      validator={commaSeparatedValidator}
      onChange={handleChange}
      onFocus={() => toggleLayerVisible(layerItemId, true)}
      {...rest}
    />
  )
}
GapInput.propTypes = {
  layerItemId: PropTypes.string.isRequired
}

export default React.memo(GapInput)
