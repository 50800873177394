import { BooleanOperation, ElementType, GeometryType, JoinShape, PointShape, IDType, EffectType } from '@phase-software/types'
import { id, isId, loadId } from '@phase-software/data-utils'
import { Element } from './Element'
import { Path } from './Path'
import { Workspace } from './Workspace'
import { Text } from './Text'
import { Screen } from './Screen'
import { GeometryGroup } from './GeometryGroup'
import { Container } from './Container'
import {
    CREATE_NEW_ELEMENT_DATA,
    DEFAULT_ELEMENT_SIZE,
    NOT_ALLOWED_PASTE_KEYFRAME_PROPERTIES,
    NOT_ALLOWED_PASTE_KEYFRAME_PROPERTIES_BY_ELEMENT_TYPE
} from './constant'
import { effectTypeKeySet } from './interaction/constants'
import { isPaintPropKey } from './interaction/utils'


/**
 * Check if `child` can be a child of `parent`
 * @param  {Element} child
 * @param  {Element} parent
 * @returns {boolean}
 */
export function canBeChildOf(child, parent) {
    if (parent.constructor === Element || parent.constructor === Text || parent.constructor === Path) {
        return false
    }
    if (parent.constructor === GeometryGroup && child.constructor !== Path && child.constructor !== GeometryGroup) {
        return false
    }
    if (parent.constructor !== Workspace && child.constructor === Screen) {
        return false
    }
    if (parent.constructor.name === 'DataStore' && child.constructor !== Workspace) {
        return false
    }
    if (child.constructor === Container) {
        let target = parent
        while (target && target.constructor !== Workspace) {
            const targetParent = target.get('parent')
            if (targetParent === child) {
                return false
            }
            target = targetParent
        }
    }
    return true
}

/**
 * creates element based on type
 * @param {DataStore} dataStore
 * @param {ElementType} type
 * @param {ElementData} data
 * @returns {Element}
 */
export function createElement(dataStore, type, data) {
    // Renew id to IdCounter to create the id if it's old id style
    if (!data.id || (data.id && !isId(data.id, IDType.ELEMENT))) {
        data.id = id(IDType.ELEMENT)
    } else {
        loadId(data.id, IDType.ELEMENT)
    }

    switch (type) {
        case 'ELEMENT':
            throw new Error('Not allowed to create element of abstract type ELEMENT')
        case ElementType.PATH:
            return new Path(dataStore, data)
        case ElementType.SCREEN:
            return new Screen(dataStore, data)
        case ElementType.TEXT:
            return new Text(dataStore, data)
        case ElementType.GEOMETRY_GROUP:
            return new GeometryGroup(dataStore, data)
        case ElementType.CONTAINER:
        case ElementType.BOOLEAN_CONTAINER:
        case ElementType.MASK_CONTAINER:
            return new Container(dataStore, data)
        default:
            throw new Error(`Failed to create element of unknown element type ${type}`)
    }
}

/**
 * Creates new element of specified type at specified position
 * Optionally can specify size. If geometryType is not specified, geometry type considered a Rectangle
 * @param {DataStore} dataStore
 * @param {ElementType} type
 * @param {object} position
 * @param {number} position.x
 * @param {number} position.y
 * @param {object} size
 * @param {number} size.width
 * @param {number} size.height
 * @param {GeometryType} geometryType
 * @param {object} containerData
 * @param {BooleanOperation} containerData.booleanType
 * @param {bool} containerData.isMask
 * @param {bool} containerData.invert
 * @param {ContainerElementType} containerData.containerType
 * @returns {Element}
 */
export function createNewElement(
    dataStore,
    type,
    position,
    size = DEFAULT_ELEMENT_SIZE,
    geometryType = GeometryType.RECTANGLE,
    containerData = {
        booleanType: BooleanOperation.NONE,
        isMask: false,
        containerType: ElementType.CONTAINER
    }
) {
    let nameWithElementType = type
    if (
        type === ElementType.CONTAINER &&
        containerData.booleanType === BooleanOperation.NONE &&
        !containerData.isMask
    ) {
        nameWithElementType = containerData.containerType
    }

    CREATE_NEW_ELEMENT_DATA.name = dataStore.nameCounter.next(nameWithElementType, geometryType)
    CREATE_NEW_ELEMENT_DATA.elementType = type
    const translate = {
        translateX: position.x + size.width * 0.5,
        translateY: position.y + size.height * 0.5
    }
    CREATE_NEW_ELEMENT_DATA.base.translate = translate
    CREATE_NEW_ELEMENT_DATA.base.dimensions = size
    CREATE_NEW_ELEMENT_DATA.geometryType = geometryType
    Object.keys(containerData).forEach((propKey) => {
        CREATE_NEW_ELEMENT_DATA[propKey] = containerData[propKey]
    })
    const referencePoint = {
        referencePointX: size.width * 0.5,
        referencePointY: size.height * 0.5,
    }
    CREATE_NEW_ELEMENT_DATA.base.referencePoint = referencePoint
    const geometryData = {}

    if (type === ElementType.PATH && geometryType === GeometryType.POLYGON) {
        geometryData.geometry = {
            geometryType: GeometryType.POLYGON,
            mesh: {
                vertices: [{
                    cap: null,
                    cornerRadius: 0,
                    id: id(IDType.MESH),
                    join: JoinShape.MITER,
                    mirror: PointShape.NONE,
                    pos: [0, 0]
                }],
                edges: [],
                contours: []
            }
        }
        delete CREATE_NEW_ELEMENT_DATA.geometryType
    }

    return createElement(dataStore, type, { ...CREATE_NEW_ELEMENT_DATA, ...geometryData })
}

/**
 * Check if the keyFrame is exist or need to create a keyFrame
 * @param {string} state
 * @returns {bool}
 */
export const existKeyFrame = (state) => {
    return state === 'EXPLICIT' || state === 'INITIAL'
}

/**
 * @param {number} value - The value needs to parse to specific decimals value.
 * @param {number} decimals - The specific decimal point.
 * @param {boolean} formatAsString
 * @returns {number} Return the value parsed with a specific decimal point.
 */
export const parseToDecimals = (value, decimals = 0, formatAsString = false) => {
    const power = Math.pow(10, decimals)
    let result = Math.round(value * power) / power
    // compare between -0 and 0
    result = result === 0 ? 0 : result
    return formatAsString ? result.toFixed(decimals) : result
}


/**
 * Gets the element type.
 * @param   {Element} element
 * @returns {ElementType | GeometryType }
 */
export const getElementType = element => {
    const elementType = element.get('elementType')
    switch (elementType) {
        case ElementType.SCREEN:
        case ElementType.MASK_CONTAINER:
        case ElementType.BOOLEAN_CONTAINER:
            return elementType
        case ElementType.PATH: {
            const geometryType = element.get('geometryType')
            return geometryType
        }
        case ElementType.CONTAINER: {
            if (element.isMaskGroup()) {
                return ElementType.MASK_CONTAINER
            } else if (element.isBooleanType()) {
                return ElementType.BOOLEAN_CONTAINER
            } else if (element.isNormalGroup) {
                return ElementType.NORMAL_GROUP
            } else {
                return ElementType.CONTAINER
            }
        }
    }
}

/**
 * Check if the element can have effect
 * @param {Element} element
 * @param {EffectType} effectType
 * @returns {boolean}
 */
export const canElementApplyEffect = (element, effectType) => {
    if (getElementType(element) === ElementType.MASK_CONTAINER && effectType === EffectType.TRIM_PATH) {
        return false
    }

    return true
}


export const isEffectTypeKey = (key) => {
    return effectTypeKeySet.has(key)
}

/**
 * Generates a property track key based on the given property key and property type key.
 * Property track key rules:
 * - generic: propKey, e.g. 'x', 'y', 'width', 'height'
 * - layer: `${layerId}.{propKey}`, e.g. 'im-280.paint', 'im-280.opacity'
 * - effect: `${effectType}.{propKey}`, e.g. 'trimPath.start', 'trimPath.end'
 * @param {string} propKey - The property key.
 * @param {string} propIdentifier - The property type key (optional).
 * @returns {string} The generated property track key.
 */
export const generatePropertyTrackKey = (propKey, propIdentifier) => {
    if (propIdentifier) return `${propIdentifier}.${propKey}`

    return propKey

}

export const generateLayerPropertyTrackKey = (propKey, layerIdentifier) => {
    return generatePropertyTrackKey(isPaintPropKey(propKey) ? 'paint' : propKey, layerIdentifier)
}

/**
 * Get the property identifier by track key.
 * - generic: prop key
 * - layer: layer cuid
 * - effect: effect type
 * @param {string} trackKey 
 * @returns {string} The property identifier.
 */
export const getPropertyIdentifierByTrackKey = (trackKey) => {
    const [propIdentifier] = trackKey.split('.');
    return propIdentifier
}

export const isAllowedToPasteProperty = (propertyKey, geometryOrElementType) => {
    const propIdentifier = getPropertyIdentifierByTrackKey(propertyKey);
    if (NOT_ALLOWED_PASTE_KEYFRAME_PROPERTIES.has(propIdentifier) || isId(propIdentifier, IDType.LAYER) || isId(propIdentifier, IDType.INTERACTION_MANAGER_COMPONENT)) {
        return false;
    }

    const restrictedProperties = NOT_ALLOWED_PASTE_KEYFRAME_PROPERTIES_BY_ELEMENT_TYPE[geometryOrElementType];
    return !(restrictedProperties && restrictedProperties.includes(propertyKey));
}
