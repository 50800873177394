import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router'

import { LoadingStatus } from '@phase-software/types'

import { Dialog, InputField, TextArea } from '../../../../components/shared'
import { InputFieldRefProps } from '../../../../components/shared/InputField'
import useFileVersionActions from '../../../../hooks/useFileVersionActions'
import useHeapAnalytics from '../../../../hooks/useHeapAnalytics'
import { useFileVersionContext } from '../../../../providers/FileVersionContextProvider'
import { useWorkspaceContext } from '../../../../providers/WorkspaceContextProvider'
import { track } from '../../../../services/heapAnalytics'
import { versionNameValidator } from '../../../../utils/validator'
import { SPECIFIC_VERSION_PARAMETER_NAME } from './useVersionLoader'

type CreateVersionDialogProps = {
  projectId: string
  fileId: string
  open: boolean
  onClose: () => void
}

const CreateVersionDialog = ({ projectId, fileId, open, onClose }: CreateVersionDialogProps) => {
  const history = useHistory()
  const location = useLocation()
  const { t } = useTranslation(['file', 'common'])
  const { createNewVersion } = useFileVersionActions()
  const { notifyVersionAdded } = useFileVersionContext()
  const { workspaceData } = useWorkspaceContext()
  const { space, teamName } = useHeapAnalytics()
  const nameInputRef = useRef<InputFieldRefProps>(null)
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [hasError, setHasError] = useState(false)
  const [isCreating, setIsCreating] = useState(false)
  const analyticLocation = projectId === workspaceData.draftProjectId ? 'drafts' : 'project'

  useEffect(() => {
    if (open && nameInputRef.current) {
      nameInputRef.current.focus()
    }
  }, [open])

  const handleChangeName = (newName: string) => {
    setHasError(false)
    setName(newName)
  }

  const handleChangeDescription = (newDescription: string) => {
    setDescription(newDescription)
  }

  const validateInputs = () => {
    if (!nameInputRef.current) return
    const isValid = nameInputRef.current?.onValidate()
    setHasError(!isValid)
    return isValid
  }

  const handleCreate = async () => {
    if (isCreating || !validateInputs()) return
    setIsCreating(true)

    try {
      const newVersionId = await createNewVersion({ projectId, fileId, name, description })

      notifyVersionAdded()

      track('New File Version Created', {
        fileId,
        space,
        teamName,
        location: analyticLocation
      })

      const searchParams = new URLSearchParams(location.search)
      searchParams.set(SPECIFIC_VERSION_PARAMETER_NAME, newVersionId)

      history.push({
        pathname: location.pathname,
        search: `?${searchParams.toString()}`
      })

      handleClose()
    } catch (error) {
      console.error(error)
    } finally {
      setIsCreating(false)
    }
  }

  const handleClose = () => {
    setName('')
    setDescription('')
    setHasError(false)
    onClose()
  }

  return (
    <Dialog
      data-test-id="add-new-version-dialog"
      size="xs"
      title={t('version.add_a_new_version')}
      showProgressButton
      progressStatus={isCreating ? LoadingStatus.WAITING : LoadingStatus.INITIAL}
      confirmBtnText={t('version.create')}
      cancelBtnText={t('version.cancel')}
      onConfirm={handleCreate}
      onCancel={handleClose}
      disableConfirm={hasError || isCreating}
      open={open}
    >
      <InputField
        ref={nameInputRef}
        type="text"
        value={name}
        placeholder={t('version.name_version')}
        validator={(value) => versionNameValidator(value, t)}
        onInput={handleChangeName}
        onEnterKey={handleCreate}
        labelClassName="mb-8"
        validateOnChange={false}
      />
      <TextArea
        placeholder={t('version.describe_version')}
        defaultValue={description}
        onChangeValue={handleChangeDescription}
        height={92}
      />
    </Dialog>
  )
}

export default CreateVersionDialog
