import { useRef, useEffect, useCallback } from 'react'
import { useToolActions } from '../../providers/dataStore/ToolProvider'
import { ToolType } from '@phase-software/types'
import { useCommentContext } from '../../providers/CommentProvider'
import { useMoveViewport } from './useMoveViewport'
import { useThreads, useSelf, useEditThreadMetadata } from '../../liveblocks.config'
import { parseMetadata, stringifyMetadata } from './utils'
import { FilterKeys, ThreadDataProps, MetadataProps, CommentType } from '../shared/CommentThread/types'

export const THREAD_ID = 'thread-id'

export default function useCommentLoader() {
  const initRef = useRef(false)
  const { moveToTopLeft } = useMoveViewport()
  const { setActiveTool } = useToolActions()

  const { selectCanvasCommentThread, selectPanelCommentThread, setScrollTargetId, toggleFilter } = useCommentContext()
  const { threads } = useThreads()
  const { id: currentUserId = '' } = useSelf()
  const editThreadMetadata = useEditThreadMetadata()
  const searchParams = new URLSearchParams(location.search)
  const linkThreadId = searchParams.get(THREAD_ID)
  const linkThreadType = searchParams.get('type')

  const updateThread = useCallback(
    (metadata: MetadataProps, linkThreadId: string): void => {
      if (!metadata.usersReadStatus[currentUserId]) {
        const newMetadata = stringifyMetadata({
          ...metadata,
          usersReadStatus: { ...metadata.usersReadStatus, [currentUserId]: true }
        })
        editThreadMetadata({ threadId: linkThreadId, metadata: newMetadata })
      }
      if (metadata.resolved) {
        toggleFilter(FilterKeys.SHOW_RESOLVED)
      }
    },
    [currentUserId, editThreadMetadata, toggleFilter]
  )

  const loadCanvasComment = useCallback(
    (parsedMetadata: MetadataProps, linkThreadId: string): void => {
      const data = parsedMetadata.position
      if (data) {
        updateThread(parsedMetadata, linkThreadId)
        setTimeout(() => {
          moveToTopLeft({ x: data.x, y: data.y, width: 0, height: 0 })
        }, 0)
        selectCanvasCommentThread(linkThreadId)
      }
    },
    [updateThread, moveToTopLeft, selectCanvasCommentThread]
  )

  const loadPanelComment = useCallback(
    (parsedMetadata: MetadataProps, linkThreadId: string): void => {
      updateThread(parsedMetadata, linkThreadId)
      selectPanelCommentThread(linkThreadId)
      setScrollTargetId(linkThreadId)
    },
    [updateThread, selectPanelCommentThread, setScrollTargetId]
  )

  const loadCommentsFromUrl = useCallback(() => {
    if (linkThreadId) {
      setActiveTool(ToolType.COMMENT)
      const getThread = (threadId: string) =>
        threads.find((currentThread: ThreadDataProps) => currentThread.id === threadId)
      const activeThread = getThread(linkThreadId)
      if (!activeThread) return
      const rawMetaData = activeThread.metadata
      const parsedMetadata = parseMetadata(rawMetaData)
      if (parsedMetadata.isDeleted) return
      if (linkThreadType === CommentType.CANVAS) {
        loadCanvasComment(parsedMetadata, linkThreadId)
      }
      if (linkThreadType === CommentType.PANEL) {
        loadPanelComment(parsedMetadata, linkThreadId)
      }
    }
  }, [linkThreadId, threads, linkThreadType, setActiveTool, loadCanvasComment, loadPanelComment])

  useEffect(() => {
    if (!initRef.current) {
      loadCommentsFromUrl()
      initRef.current = true
    }
  }, [loadCommentsFromUrl])

  return {}
}
