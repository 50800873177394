import React from 'react'
import { useTranslation } from 'react-i18next'

import { formatNumberWithUnit } from '../../../../utils/formatter'
import Property from '../Property'
import PropertyRow from '../PropertyRow'
import AutoOrient from './AutoOrient'

type PositionProps = {
  isShapeMode?: boolean
  isPathEditing?: boolean
  enableKF?: boolean
}

const Position = ({ isShapeMode, isPathEditing, enableKF }: PositionProps) => {
  const { t } = useTranslation('file', { keyPrefix: 'property_editor.position' })
  const showAutoOrient = !(isShapeMode || isPathEditing)
  return (
    <PropertyRow columns={isShapeMode ? [106, 106] : [94, 94]} isFirst={!isShapeMode}>
      <Property
        field="x"
        caption="X"
        tooltip={t('position_x')}
        data-test-id="position-X-input"
        toDisplay={formatNumberWithUnit}
        enableKF={enableKF}
        alwaysForceUpdate
      />
      <Property
        field="y"
        caption="Y"
        tooltip={t('position_y')}
        data-test-id="position-Y-input"
        toDisplay={formatNumberWithUnit}
        enableKF={enableKF}
        alwaysForceUpdate
      />
      {showAutoOrient && <AutoOrient />}
    </PropertyRow>
  )
}

export default Position
