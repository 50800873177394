import { useEffect } from 'react'

import { useSetAtom } from 'jotai'

// @ts-ignore TODO: fix after refactor of phase-software/renderer
import { IS } from '@phase-software/renderer'

import { modifierKeysAtom } from '../../../atoms'

type ModifierKeys = {
  modifier: boolean
  ctrl: boolean
  shift: boolean
  alt: boolean
}

export const SubscribeInputSystemModifierKeys = () => {
  const setModifierKeys = useSetAtom(modifierKeysAtom)
  useEffect(() => {
    const cb = (modifier: ModifierKeys) => {
      const set = new Set<string>()
      Object.entries(modifier).forEach(([key, value]: [string, boolean]) => {
        if (value) {
          set.add(key)
        }
      })
      setModifierKeys(set)
    }
    IS.on('MODIFIER_KEY_CHANGES', cb)
    return () => {
      IS.off('MODIFIER_KEY_CHANGES', cb)
    }
  }, [setModifierKeys])
  return null
}
