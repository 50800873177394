import { CURVETIME_EPSILON, GEOMETRIC_EPSILON, Vector2 } from "../../math"
import { Line } from "./Line"
import { CubicBez } from "./CubicBez"

/**
 * @typedef {[ number,number, number,number, number,number, number,number ]} CurveData
 * @typedef {{ winding: number, windingL: number, windingR: number, quality: number, onPath: boolean }} WindingInfo
 */

/** @enum {number} */
export const CuspTypes = {
    None: 0,
    Loop: 1,
    DoubleInflection: 2,
}

/**
 * @param {number} x
 * @param {number} y
 */
export function copySign(x, y) {
    const a = abs(x)
    return y < 0 ? -a : a
}

/**
 * Solve an arbitrary function for a zero-crossing
 * @param {(t: number) => number} f
 * @param {number} a
 * @param {number} b
 * @param {number} epsilon
 * @param {number} n0
 * @param {number} k1
 * @param {number} ya
 * @param {number} yb
 */
export function solveItp(f, a, b, epsilon, n0, k1, ya, yb) {
    const n1_2 = Math.max(Math.ceil(Math.log2((b - a) / epsilon)) - 1, 0)
    const nmax = n0 + n1_2
    let scaled_epsilon = epsilon * Math.exp(nmax * Math.LN2)
    // let scaled_epsilon = epsilon * (1 << nmax)
    while (b - a > 2 * epsilon) {
        const x1_2 = 0.5 * (a + b)
        const r = scaled_epsilon - 0.5 * (b - a)
        const xf = (yb * a - ya * b) / (yb - ya)
        const sigma = x1_2 - xf
        const delta = k1 * (b - a) * (b - a)
        const xt = delta <= Math.abs(x1_2 - xf) ? xf + copySign(delta, sigma) : x1_2
        const xitp = Math.abs(xt - x1_2) <= r ? xt : x1_2 - copySign(r, sigma)
        const yitp = f(xitp)
        if (yitp > 0) {
            // eslint-disable-next-line no-param-reassign
            b = xitp
            // eslint-disable-next-line no-param-reassign
            yb = yitp
        } else if (yitp < 0) {
            // eslint-disable-next-line no-param-reassign
            a = xitp
            // eslint-disable-next-line no-param-reassign
            ya = yitp
        } else {
            return xitp
        }
        scaled_epsilon *= 0.5
    }
    return 0.5 * (a + b)
}

/**
 * @param {number} c0
 * @param {number} c1
 * @param {number} c2
 */
export function solveQuadratic(c0, c1, c2) {
    const sc0 = c0 / c2
    const sc1 = c1 / c2
    if (!(isFinite(sc0) && isFinite(sc1))) {
        const root = -c0 / c1
        if (isFinite(root)) {
            return [root]
        } else if (c0 == 0 && c1 == 0) {
            return [0]
        } else {
            return []
        }
    }
    const arg = sc1 * sc1 - 4 * sc0
    let root1 = 0
    if (isFinite(arg)) {
        if (arg < 0) {
            return []
        } else if (arg == 0) {
            return [-0.5 * sc1]
        }
        root1 = -.5 * (sc1 + copySign(sqrt(arg), sc1))
    } else {
        root1 = -sc1
    }
    const root2 = sc0 / root1
    if (isFinite(root2)) {
        if (root2 > root1) {
            return [root1, root2]
        } else {
            return [root2, root1]
        }
    }
    return [root1]
}

/**
 * @param {number} in_c0
 * @param {number} in_c1
 * @param {number} in_c2
 * @param {number} in_c3
 * @returns {number[]}
 */
export function solveCubic(in_c0, in_c1, in_c2, in_c3) {
    const c2 = in_c2 / (3 * in_c3)
    const c1 = in_c1 / (3 * in_c3)
    const c0 = in_c0 / in_c3
    if (!(isFinite(c0) && isFinite(c1) && isFinite(c2))) {
        return solveQuadratic(in_c0, in_c1, in_c2)
    }
    const d0 = -c2 * c2 + c1
    const d1 = -c1 * c2 + c0
    const d2 = c2 * c0 - c1 * c1
    const d = 4 * d0 * d2 - d1 * d1
    const de = -2 * c2 * d0 + d1
    if (d < 0) {
        const sq = sqrt(-0.25 * d)
        const r = -0.5 * de
        const t1 = cbrt(r + sq) + cbrt(r - sq)
        return [t1 - c2]
    } else if (d == 0) {
        const t1 = copySign(sqrt(-d0), de)
        return [t1 - c2, -2 * t1 - c2]
    } else {
        const th = atan2(sqrt(d), -de) / 3
        const r0 = cos(th)
        const ss3 = sin(th) * sqrt(3)
        const r1 = 0.5 * (-r0 + ss3)
        const r2 = 0.5 * (-r0 - ss3)
        const t = 2 * sqrt(-d0)
        return [t * r0 - c2, t * r1 - c2, t * r2 - c2]
    }
}

// Factor a quartic polynomial into two quadratics. Based on Orellana and De Michele
// and very similar to the version in kurbo.
/**
 * @param {number} c0
 * @param {number} c1
 * @param {number} c2
 * @param {number} c3
 * @param {number} c4
 */
export function solveQuartic(c0, c1, c2, c3, c4) {
    // This doesn't special-case c0 = 0.
    if (c4 == 0) {
        return solveCubic(c0, c1, c2, c3)
    }
    const a = c3 / c4
    const b = c2 / c4
    const c = c1 / c4
    const d = c0 / c4
    let result = solveQuarticInner(a, b, c, d, false)
    if (result !== null) {
        return result
    }
    const K_Q = 7.16e76
    for (let i = 0; i < 2; i++) {
        result = solveQuarticInner(a / K_Q, b / (K_Q * K_Q), c / (K_Q * K_Q * K_Q),
            d / (K_Q * K_Q * K_Q * K_Q), i != 0)
        if (result !== null) {
            for (let j = 0; j < result.length; j++) {
                result[j] *= K_Q
            }
            return result
        }
    }
    // Really bad overflow happened.
    return []
}

/**
 * @param {number} a
 * @param {number} b
 * @param {number} c
 * @param {number} d
 * @param {boolean} rescale
 */
export function factorQuarticInner(a, b, c, d, rescale) {
    /**
     * @param {number} a1
     * @param {number} b1
     * @param {number} a2
     * @param {number} b2
     */
    function calc_eps_q(a1, b1, a2, b2) {
        const eps_a = epsRel(a1 + a2, a)
        const eps_b = epsRel(b1 + a1 * a2 + b2, b)
        const eps_c = epsRel(b1 * a2 + a1 * b2, c)
        return eps_a + eps_b + eps_c
    }
    /**
     * @param {number} a1
     * @param {number} b1
     * @param {number} a2
     * @param {number} b2
     */
    function calc_eps_t(a1, b1, a2, b2) {
        return calc_eps_q(a1, b1, a2, b2) + epsRel(b1 * b2, d)
    }
    const disc = 9 * a * a - 24 * b
    const s = disc >= 0 ? -2 * b / (3 * a + copySign(sqrt(disc), a)) : -0.25 * a
    const a_prime = a + 4 * s
    const b_prime = b + 3 * s * (a + 2 * s)
    const c_prime = c + s * (2 * b + s * (3 * a + 4 * s))
    const d_prime = d + s * (c + s * (b + s * (a + s)))
    let g_prime = 0
    let h_prime = 0
    const K_C = 3.49e102
    if (rescale) {
        const a_prime_s = a_prime / K_C
        const b_prime_s = b_prime / K_C
        const c_prime_s = c_prime / K_C
        const d_prime_s = d_prime / K_C
        g_prime = a_prime_s * c_prime_s - (4 / K_C) * d_prime_s - (1. / 3) * b_prime_s * b_prime_s
        h_prime = (a_prime_s * c_prime_s - (8 / K_C) * d_prime_s - (2. / 9) * b_prime_s * b_prime_s)
            * (1. / 3) * b_prime_s
            - c_prime_s * (c_prime_s / K_C)
            - a_prime_s * a_prime_s * d_prime_s
    } else {
        g_prime = a_prime * c_prime - 4 * d_prime - (1. / 3) * b_prime * b_prime
        h_prime = (a_prime * c_prime + 8 * d_prime - (2. / 9) * b_prime * b_prime) * (1. / 3) * b_prime
            - c_prime * c_prime
            - a_prime * a_prime * d_prime
    }
    if (!isFinite(g_prime) && isFinite(h_prime)) {
        return null
    }
    let phi = depressedCubicDominant(g_prime, h_prime)
    if (rescale) {
        phi *= K_C
    }
    const l_1 = a * 0.5
    const l_3 = (1. / 6) * b + 0.5 * phi
    const delt_2 = c - a * l_3
    const d_2_cand_1 = (2. / 3) * b - phi - l_1 * l_1
    const l_2_cand_1 = 0.5 * delt_2 / d_2_cand_1
    const l_2_cand_2 = 2 * (d - l_3 * l_3) / delt_2
    const d_2_cand_2 = 0.5 * delt_2 / l_2_cand_2
    let d_2_best = 0
    let l_2_best = 0
    let eps_l_best = Infinity
    for (let i = 0; i < 3; i++) {
        const d_2 = i == 1 ? d_2_cand_2 : d_2_cand_1
        const l_2 = i == 0 ? l_2_cand_1 : l_2_cand_2
        const eps_0 = epsRel(d_2 + l_1 * l_1 + 2 * l_3, b)
        const eps_1 = epsRel(2 * (d_2 * l_2 + l_1 * l_3), c)
        const eps_2 = epsRel(d_2 * l_2 * l_2 + l_3 * l_3, d)
        const eps_l = eps_0 + eps_1 + eps_2
        if (i == 0 || eps_l < eps_l_best) {
            d_2_best = d_2
            l_2_best = l_2
            eps_l_best = eps_l
        }
    }
    const d_2 = d_2_best
    const l_2 = l_2_best
    let alpha_1 = 0
    let beta_1 = 0
    let alpha_2 = 0
    let beta_2 = 0
    if (d_2 < 0.0) {
        const sq = sqrt(-d_2)
        alpha_1 = l_1 + sq
        beta_1 = l_3 + sq * l_2
        alpha_2 = l_1 - sq
        beta_2 = l_3 - sq * l_2
        if (abs(beta_2) < abs(beta_1)) {
            beta_2 = d / beta_1
        } else if (abs(beta_2) > abs(beta_1)) {
            beta_1 = d / beta_2
        }
        if (abs(alpha_1) != abs(alpha_2)) {
            let a1_cands = null
            let a2_cands = null
            if (abs(alpha_1) < abs(alpha_2)) {
                const a1_cand_1 = (c - beta_1 * alpha_2) / beta_2
                const a1_cand_2 = (b - beta_2 - beta_1) / alpha_2
                const a1_cand_3 = a - alpha_2
                a1_cands = [a1_cand_3, a1_cand_1, a1_cand_2]
                a2_cands = [alpha_2, alpha_2, alpha_2]
            } else {
                const a2_cand_1 = (c - alpha_1 * beta_2) / beta_1
                const a2_cand_2 = (b - beta_2 - beta_1) / alpha_1
                const a2_cand_3 = a - alpha_1
                a1_cands = [alpha_1, alpha_1, alpha_1]
                a2_cands = [a2_cand_3, a2_cand_1, a2_cand_2]
            }
            let eps_q_best = 0
            for (let i = 0; i < 3; i++) {
                const a1 = a1_cands[i]
                const a2 = a2_cands[i]
                if (isFinite(a1) && isFinite(a2)) {
                    const eps_q = calc_eps_q(a1, beta_1, a2, beta_2)
                    if (i == 0 || eps_q < eps_q_best) {
                        alpha_1 = a1
                        alpha_2 = a2
                        eps_q_best = eps_q
                    }
                }
            }
        }
    } else if (d_2 == 0) {
        const d_3 = d - l_3 * l_3
        alpha_1 = l_1
        beta_1 = l_3 + sqrt(-d_3)
        alpha_2 = l_1
        beta_2 = l_3 - sqrt(-d_3)
        if (abs(beta_1) > abs(beta_2)) {
            beta_2 = d / beta_1
        } else if (abs(beta_2) > abs(beta_1)) {
            beta_1 = d / beta_2
        }
    } else {
        // No real solutions
        return []
    }
    let eps_t = calc_eps_t(alpha_1, beta_1, alpha_2, beta_2)
    for (let i = 0; i < 8; i++) {
        if (eps_t == 0) {
            break
        }
        const f_0 = beta_1 * beta_2 - d
        const f_1 = beta_1 * alpha_2 + alpha_1 * beta_2 - c
        const f_2 = beta_1 + alpha_1 * alpha_2 + beta_2 - b
        const f_3 = alpha_1 + alpha_2 - a
        const c_1 = alpha_1 - alpha_2
        const det_j = beta_1 * beta_1 - beta_1 * (alpha_2 * c_1 + 2 * beta_2)
            + beta_2 * (alpha_1 * c_1 + beta_2)
        if (det_j == 0) {
            break
        }
        const inv = 1 / det_j
        const c_2 = beta_2 - beta_1
        const c_3 = beta_1 * alpha_2 - alpha_1 * beta_2
        const dz_0 = c_1 * f_0 + c_2 * f_1 + c_3 * f_2 - (beta_1 * c_2 + alpha_1 * c_3) * f_3
        const dz_1 = (alpha_1 * c_1 + c_2) * f_0
            - beta_1 * (c_1 * f_1 + c_2 * f_2 + c_3 * f_3)
        const dz_2 = -c_1 * f_0 - c_2 * f_1 - c_3 * f_2 + (alpha_2 * c_3 + beta_2 * c_2) * f_3
        const dz_3 = -(alpha_2 * c_1 + c_2) * f_0
            + beta_2 * (c_1 * f_1 + c_2 * f_2 + c_3 * f_3)
        const a1 = alpha_1 - inv * dz_0
        const b1 = beta_1 - inv * dz_1
        const a2 = alpha_2 - inv * dz_2
        const b2 = beta_2 - inv * dz_3
        const new_eps_t = calc_eps_t(a1, b1, a2, b2)
        if (new_eps_t < eps_t) {
            alpha_1 = a1
            beta_1 = b1
            alpha_2 = a2
            beta_2 = b2
            eps_t = new_eps_t
        } else {
            break
        }
    }
    return [alpha_1, beta_1, alpha_2, beta_2]
}

/**
 * @param {CurveData} v1
 * @param {CurveData} v2
 */
export function getOverlaps(v1, v2) {
    // Linear curves can only overlap if they are collinear. Instead of
    // using the #isCollinear() check, we pick the longer of the two curves
    // treated as lines, and see how far the starting and end points of the
    // other line are from this line (assumed as an infinite line). But even
    // if the curves are not straight, they might just have tiny handles
    // within geometric epsilon distance, so we have to check for that too.

    const getDistance = Line.getDistance,
        timeEpsilon = CURVETIME_EPSILON,
        geomEpsilon = GEOMETRIC_EPSILON
    let straight1 = CubicBez.isStraight(v1)
    let straight2 = CubicBez.isStraight(v2)
    let straightBoth = straight1 && straight2
    const flip = getSquaredLineLength(v1) < getSquaredLineLength(v2),
        l1 = flip ? v2 : v1,
        l2 = flip ? v1 : v2,
        // Get l1 start and end point values for faster referencing.
        px = l1[0], py = l1[1],
        vx = l1[6] - px, vy = l1[7] - py
    // See if the starting and end point of curve two are very close to the
    // picked line. Note that the curve for the picked line might not
    // actually be a line, so we have to perform more checks after.
    if (
        getDistance(px, py, vx, vy, l2[0], l2[1], true) < geomEpsilon &&
        getDistance(px, py, vx, vy, l2[6], l2[7], true) < geomEpsilon
    ) {
        // If not both curves are straight, check against both of their
        // handles, and treat them as straight if they are very close.
        if (
            !straightBoth &&
            getDistance(px, py, vx, vy, l1[2], l1[3], true) < geomEpsilon &&
            getDistance(px, py, vx, vy, l1[4], l1[5], true) < geomEpsilon &&
            getDistance(px, py, vx, vy, l2[2], l2[3], true) < geomEpsilon &&
            getDistance(px, py, vx, vy, l2[4], l2[5], true) < geomEpsilon
        ) {
            straight1 = true
            straight2 = true
            straightBoth = true
        }
    } else if (straightBoth) {
        // If both curves are straight and not very close to each other,
        // there can't be a solution.
        return null
    }
    if (straight1 ^ straight2) {
        // If one curve is straight, the other curve must be straight too,
        // otherwise they cannot overlap.
        return null
    }

    const v = [v1, v2]
    /** @type {[number, number][]} */
    let pairs = []
    // Iterate through all end points:
    // First p1 of curve 1 & 2, then p2 of curve 1 & 2.
    for (let i = 0; i < 4 && pairs.length < 2; i++) {
        const i1 = i & 1,  // 0, 1, 0, 1
            i2 = i1 ^ 1, // 1, 0, 1, 0
            t1 = i >> 1, // 0, 0, 1, 1
            t2 = CubicBez.getTimeOf(
                v[i1],
                new Vector2(
                    v[i2][t1 ? 6 : 0],
                    v[i2][t1 ? 7 : 1]
                )
            )
        if (t2 != null) {  // If point is on curve
            const pair = i1 ? [t1, t2] : [t2, t1]
            // Filter out tiny overlaps.
            if (
                // eslint-disable-next-line no-mixed-operators
                !pairs.length ||
                // eslint-disable-next-line no-mixed-operators
                abs(pair[0] - pairs[0][0]) > timeEpsilon &&
                abs(pair[1] - pairs[0][1]) > timeEpsilon
            ) {
                pairs.push(pair)
            }
        }
        // We checked 3 points but found no match, curves can't overlap.
        if (i > 2 && !pairs.length) {
            break
        }
    }
    if (pairs.length !== 2) {
        pairs = null
    } else if (!straightBoth) {
        // Straight pairs don't need further checks. If we found 2 pairs,
        // the end points on v1 & v2 should be the same.
        const o1 = CubicBez.getPart(v1, pairs[0][0], pairs[1][0])
        const o2 = CubicBez.getPart(v2, pairs[0][1], pairs[1][1])
        // Check if handles of the overlapping curves are the same too.
        if (
            abs(o2[2] - o1[2]) > geomEpsilon
            ||
            abs(o2[3] - o1[3]) > geomEpsilon
            ||
            abs(o2[4] - o1[4]) > geomEpsilon
            ||
            abs(o2[5] - o1[5]) > geomEpsilon
        ) {
            pairs = null
        }
    }
    return pairs
}


/* implementation */

/**
 * @param {number} raw
 * @param {number} a
 */
function epsRel(raw, a) {
    return a == 0 ? abs(raw) : abs((raw - a) / a)
}

/**
 * @param {number} g
 * @param {number} h
 */
function depressedCubicDominant(g, h) {
    const q = (-1. / 3) * g
    const r = 0.5 * h
    let phi_0
    let k = null
    if (abs(q) >= 1e102 || abs(r) >= 1e164) {
        if (abs(q) < abs(r)) {
            k = 1 - q * (q / r) * (q / r)
        } else {
            k = Math.sign(q) * ((r / q) * (r / q) / q - 1)
        }
    }
    // eslint-disable-next-line no-negated-condition
    if (k !== null && r == 0) {
        if (g > 0) {
            phi_0 = 0
        } else {
            phi_0 = sqrt(-g)
        }
    // eslint-disable-next-line no-negated-condition
    } else if (k !== null ? k < 0 : r * r < q * q * q) {
        // eslint-disable-next-line no-negated-condition
        const t = k !== null ? r / q / sqrt(q) : r / sqrt(q * q * q)
        phi_0 = -2 * sqrt(q) * copySign(cos(acos(abs(t)) * (1. / 3)), t)
    } else {
        let a
        // eslint-disable-next-line no-negated-condition
        if (k !== null) {
            if (abs(q) < abs(r)) {
                a = -r * (1 + sqrt(k))
            } else {
                a = -r - copySign(sqrt(abs(q)) * q * sqrt(k), r)
            }
        } else {
            a = cbrt(-r - copySign(sqrt(r * r - q * q * q), r))
        }
        const b = a == 0 ? 0 : q / a
        phi_0 = a + b
    }
    let x = phi_0
    let f = (x * x + g) * x + h
    const EPS_M = 2.22045e-16
    if (abs(f) < EPS_M * max(x * x * x, g * x, h)) {
        return x
    }
    for (let i = 0; i < 8; i++) {
        const delt_f = 3 * x * x + g
        if (delt_f == 0) {
            break
        }
        const new_x = x - f / delt_f
        const new_f = (new_x * new_x + g) * new_x + h
        if (new_f == 0) {
            return new_x
        }
        if (abs(new_f) >= abs(f)) {
            break
        }
        x = new_x
        f = new_f
    }
    return x
}

/**
 * @param {number} a
 * @param {number} b
 * @param {number} c
 * @param {number} d
 * @param {boolean} rescale
 */
function solveQuarticInner(a, b, c, d, rescale) {
    const result = factorQuarticInner(a, b, c, d, rescale)
    if (result !== null && result.length == 4) {
        let roots = []
        for (let i = 0; i < 2; i++) {
            const a = result[i * 2]
            const b = result[i * 2 + 1]
            roots = roots.concat(solveQuadratic(b, a, 1))
        }
        return roots
    }
}

/**
 * @param {CurveData} v
 */
function getSquaredLineLength(v) {
    const x = v[6] - v[0]
    const y = v[7] - v[1]
    return x * x + y * y
}

const max = Math.max
const abs = Math.abs
const sqrt = Math.sqrt
const sin = Math.sin
const cos = Math.cos
const acos = Math.acos
const atan2 = Math.atan2
const cbrt = Math.cbrt
