import React, { useCallback, useMemo } from 'react'
import { Mode } from '@phase-software/types'
import { useUI, useUIActions } from '../../../providers/dataStore/UIProvider'
import Tooltip from '../../shared/Tooltip'

export type ModeToggleButtonProps = {
  actionMode: boolean
  onChange: () => void
  tooltipText: string
  className?: string
}

export const ModeToggleButton = ({ actionMode, onChange, tooltipText, className = '' }: ModeToggleButtonProps) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.currentTarget === document.activeElement) {
        e.currentTarget.blur()
      }
      onChange()
    },
    [onChange]
  )

  const tipData = useMemo(
    () =>
      actionMode
        ? {
            text: tooltipText,
            shortcut: 'A'
          }
        : null,

    [actionMode, tooltipText]
  )

  return (
    <Tooltip content={tipData} offset={12}>
      <label
        data-test-id="mode-switcher"
        /**
         * The data-editor-mode is used to signal e2e tests what mode is currently selected in editor
         * Please try to preserve this attribute if component is refactored
         */
        data-editor-mode={actionMode ? 'action' : 'design'}
        className={`relative inline-block cursor-pointer ${className}`}
      >
        <input
          name="mode-switcher"
          type="checkbox"
          checked={actionMode}
          onChange={handleChange}
          className="absolute w-0 h-0 opacity-0 peer"
        />
        <div
          className="w-48 h-28 rounded-[24px] transition-colors duration-300 ease-out
          bg-light-overlay-10 peer-checked:bg-primary 
          hover:bg-light-overlay-15 peer-checked:hover:bg-primary-40 
          group-hover:bg-light-overlay-15
          active:bg-light-overlay-5 peer-checked:active:bg-primary-60
          group-active:bg-light-overlay-5"
        />
        <div
          className="absolute pointer-events-none left-4 top-4 w-20 h-20 rounded-circle bg-white transition-transform duration-300 ease-linear origin-center peer-checked:rotate-[360deg] peer-checked:translate-x-full"
          key="mode-switcher-knob"
          style={{
            clipPath: CLIP_PATH
          }}
        />
      </label>
    </Tooltip>
  )
}

const ModeToggleButtonWrapper = ({ tooltipText }: { tooltipText: string }) => {
  const { mode } = useUI()
  const isActionMode = useMemo(() => mode === Mode.ACTION, [mode])

  const { setMode } = useUIActions()

  const switchToDesignMode = useCallback(() => {
    if (isActionMode) {
      setMode(Mode.DESIGN)
    }
  }, [isActionMode, setMode])

  return (
    <ModeToggleButton
      actionMode={isActionMode}
      onChange={switchToDesignMode}
      tooltipText={tooltipText}
      className={isActionMode ? 'pt-action-mode' : ''}
    />
  )
}

const CLIP_PATH =
  "path('M20 20H0V0H20V20ZM10.7507 4.22985L6.07254 10.7073C5.83372 11.0379 6.06999 11.5 6.47788 11.5H9L8.3508 15.3952C8.26391 15.9165 8.9399 16.1986 9.24934 15.7702L13.9275 9.29274C14.1663 8.96208 13.93 8.5 13.5221 8.5H11L11.6492 4.60479C11.7361 4.08347 11.0601 3.8014 10.7507 4.22985Z')"

export default ModeToggleButtonWrapper
