import { useCallback, useEffect, useState } from 'react'
import { useProfile, useProfileActions } from '../providers/ProfileProvider'
import { useNewsletterSubscriptionPreferenceQuery, useOnPreferenceUpdatedSubscription } from '../generated/graphql'

export const useNewsletterSubscription = () => {
  const profile = useProfile()
  const { updateNewsletterSubscription } = useProfileActions()
  const [isSubscribed, setIsSubscribed] = useState(false)

  const { data, loading, refetch } = useNewsletterSubscriptionPreferenceQuery({
    variables: { id: profile.id }
  })

  const setSubscription = useCallback(
    async (flag: boolean) => {
      setIsSubscribed(flag)
      await updateNewsletterSubscription(flag)
    },
    [updateNewsletterSubscription]
  )

  useEffect(() => {
    if (loading) {
      return
    }
    const subscription = data?.user_preferences_by_pk?.value ?? false
    setIsSubscribed(subscription)
  }, [data, loading, setIsSubscribed])

  useEffect(() => {
    refetch()
  }, [refetch])

  const { data: updatedData, loading: preferenceSubscriptionLoading } = useOnPreferenceUpdatedSubscription({
    variables: { id: profile.id, key: 'newsletter-subscription' },
    skip: !profile.id
  })

  useEffect(() => {
    if (!preferenceSubscriptionLoading && updatedData?.user_preferences && updatedData.user_preferences.length > 0) {
      const updatedSubscription = updatedData?.user_preferences[0].value
      if (updatedSubscription == null) return

      setIsSubscribed(updatedSubscription)
    }
  }, [updatedData, preferenceSubscriptionLoading])

  return { isSubscribed: isSubscribed && !loading, setIsSubscribed: setSubscription }
}
