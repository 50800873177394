import { useState } from 'react'
import { useHistory, useLocation } from 'react-router'

import { MenuOptionProps } from '../../components/shared/Menu/MenuOption'
import { SESSION_STORAGE_KEYS } from '../../constant'
import { WORKSPACE_POPOVER_OPTIONS } from '../../constants/workspacePopoverConstants'
import useHeapAnalytics from '../../hooks/useHeapAnalytics'
import { WorkspaceData, useWorkspaceContext } from '../../providers/WorkspaceContextProvider'
import { logout } from '../../services/cognito'
import { resetIdentity, track } from '../../services/heapAnalytics'
import { getPersonalSettingsIndexPath, getTeamSettingsIndexPath } from '../../utils/pathGenerators'

interface UseWorkspaceProps {
  currentWorkspaceSlug: WorkspaceData['slug']
  workspacePopoverOptions: MenuOptionProps[]
}

export const useWorkSpacePopover = ({ currentWorkspaceSlug, workspacePopoverOptions }: UseWorkspaceProps) => {
  const history = useHistory()
  const location = useLocation()
  const [createTeamDialogOpen, setCreateTeamDialogOpen] = useState(false)
  const [leaveTeamDialogOpen, setLeaveTeamDialogOpen] = useState(false)
  const { teamName } = useHeapAnalytics()
  const { personalWorkspaceData } = useWorkspaceContext()
  const handleSelectWorkspace = (e: React.MouseEvent | React.KeyboardEvent, workspace: MenuOptionProps | undefined) => {
    e.stopPropagation()
    if (workspace?.value) history.push(workspace.value as string)
  }

  const handleClickOption = (
    value: MenuOptionProps['value'],
    e?: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element> | undefined,
    index?: number
  ) => {
    switch (value) {
      case WORKSPACE_POPOVER_OPTIONS.ADD_NEW_TEAM:
        setCreateTeamDialogOpen(true)
        break
      case WORKSPACE_POPOVER_OPTIONS.TEAM_SETTINGS:
        history.push(getTeamSettingsIndexPath(currentWorkspaceSlug))
        track('Team Settings Clicked', { teamName })
        break
      case WORKSPACE_POPOVER_OPTIONS.LEAVE_TEAM:
        setLeaveTeamDialogOpen(true)
        break
      case WORKSPACE_POPOVER_OPTIONS.ACCOUNT_SETTING:
        sessionStorage.setItem(SESSION_STORAGE_KEYS.REDIRECT_URL, location.pathname)
        history.push(getPersonalSettingsIndexPath(personalWorkspaceData.slug))
        track('Account Settings Clicked')
        break
      case WORKSPACE_POPOVER_OPTIONS.SIGN_OUT:
        track('User Logged Out')
        resetIdentity()
        logout()
        break
      default:
        if (e && index !== undefined) handleSelectWorkspace(e, workspacePopoverOptions?.[index])
        break
    }
  }

  return {
    handleSelectWorkspace,
    handleClickOption,
    createTeamDialogOpen,
    setCreateTeamDialogOpen,
    leaveTeamDialogOpen,
    setLeaveTeamDialogOpen
  }
}
