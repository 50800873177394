import React, { forwardRef } from 'react'

interface ContentProps {
  withHeader?: boolean
  extendedBottomPadding?: boolean
  children: React.ReactNode
  className?: string
}

const Content = forwardRef<HTMLDivElement, ContentProps>(
  ({ withHeader, extendedBottomPadding, children, className }, ref) => {
    const divClassName = `${Array.isArray(children) && children.length < 1 ? 'py-0' : ''}`
    const withHeaderClassName = withHeader ? 'pt-0' : ''
    const bottomPaddingClassName = extendedBottomPadding ? '' : 'pb-4'
    return (
      <div
        ref={ref}
        className={`ml-16 mr-12 py-12 ${divClassName} ${withHeaderClassName} ${bottomPaddingClassName} ${
          className || ''
        }`}
      >
        {children}
      </div>
    )
  }
)

Content.displayName = 'Content'

export default Content
