import { useEffect, useRef } from 'react'

const useAfterEffect = (callback: () => void) => {
  const cleanUpRef = useRef<void>()

  useEffect(() => {
    requestAnimationFrame(() => {
      setTimeout(() => {
        cleanUpRef.current = callback()
      }, 0)
    })
    return cleanUpRef.current
  }, [callback])
}

export default useAfterEffect
