/* tslint:disable */
/* eslint-disable */
/**
 * PHASE API
 * Phase API service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateProjectFileCollaboratorInvitationRequest,
  IdResponse,
  PatchProjectFileCollaboratorRequest,
  UpdateProjectFileInvitationRequest,
} from '../models';

export interface ProjectFileCollaboratorControllerAcceptTeamProjectFileCollaboratorInviteRequest {
    teamId: string;
    projectId: string;
    fileId: string;
    invitationId: string;
}

export interface ProjectFileCollaboratorControllerAcceptUserProjectFileCollaboratorInviteRequest {
    userId: string;
    projectId: string;
    fileId: string;
    invitationId: string;
}

export interface ProjectFileCollaboratorControllerCreateTeamProjectFileCollaboratorInvitationRequest {
    teamId: string;
    projectId: string;
    fileId: string;
    createProjectFileCollaboratorInvitationRequest: CreateProjectFileCollaboratorInvitationRequest;
}

export interface ProjectFileCollaboratorControllerCreateUserProjectFileCollaboratorInvitationRequest {
    userId: string;
    projectId: string;
    fileId: string;
    createProjectFileCollaboratorInvitationRequest: CreateProjectFileCollaboratorInvitationRequest;
}

export interface ProjectFileCollaboratorControllerDeleteTeamProjectFileCollaboratorRequest {
    teamId: string;
    projectId: string;
    fileId: string;
    collaboratorId: string;
}

export interface ProjectFileCollaboratorControllerDeleteUserProjectFileCollaboratorRequest {
    userId: string;
    projectId: string;
    fileId: string;
    collaboratorId: string;
}

export interface ProjectFileCollaboratorControllerPatchTeamProjectFileCollaboratorRequest {
    teamId: string;
    projectId: string;
    fileId: string;
    collaboratorId: string;
    patchProjectFileCollaboratorRequest: PatchProjectFileCollaboratorRequest;
}

export interface ProjectFileCollaboratorControllerPatchUserProjectFileCollaboratorRequest {
    userId: string;
    projectId: string;
    fileId: string;
    collaboratorId: string;
    patchProjectFileCollaboratorRequest: PatchProjectFileCollaboratorRequest;
}

export interface ProjectFileCollaboratorControllerRemovePersonalProjectFileCollaboratorInviteRequest {
    userId: string;
    projectId: string;
    fileId: string;
    invitationId: string;
}

export interface ProjectFileCollaboratorControllerRemoveTeamProjectFileCollaboratorInviteRequest {
    teamId: string;
    projectId: string;
    fileId: string;
    invitationId: string;
}

export interface ProjectFileCollaboratorControllerResendPersonalProjectCollaboratorInviteEmailRequest {
    projectId: string;
    fileId: string;
    invitationId: string;
}

export interface ProjectFileCollaboratorControllerResendTeamProjectCollaboratorInviteEmailRequest {
    teamId: string;
    projectId: string;
    fileId: string;
    invitationId: string;
}

export interface ProjectFileCollaboratorControllerUpdatePermissionOfPersonalProjectFileInvitationRequest {
    projectId: string;
    invitationId: string;
    fileId: string;
    updateProjectFileInvitationRequest: UpdateProjectFileInvitationRequest;
}

export interface ProjectFileCollaboratorControllerUpdatePermissionOfTeamProjectFileInvitationRequest {
    teamId: string;
    projectId: string;
    invitationId: string;
    fileId: string;
    updateProjectFileInvitationRequest: UpdateProjectFileInvitationRequest;
}

/**
 * 
 */
export class ProjectFileCollaboratorApi extends runtime.BaseAPI {

    /**
     */
    async projectFileCollaboratorControllerAcceptTeamProjectFileCollaboratorInviteRaw(requestParameters: ProjectFileCollaboratorControllerAcceptTeamProjectFileCollaboratorInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling projectFileCollaboratorControllerAcceptTeamProjectFileCollaboratorInvite.');
        }

        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectFileCollaboratorControllerAcceptTeamProjectFileCollaboratorInvite.');
        }

        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling projectFileCollaboratorControllerAcceptTeamProjectFileCollaboratorInvite.');
        }

        if (requestParameters.invitationId === null || requestParameters.invitationId === undefined) {
            throw new runtime.RequiredError('invitationId','Required parameter requestParameters.invitationId was null or undefined when calling projectFileCollaboratorControllerAcceptTeamProjectFileCollaboratorInvite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/teams/{teamId}/projects/{projectId}/files/{fileId}/invitations/{invitationId}:accept`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))).replace(`{${"invitationId"}}`, encodeURIComponent(String(requestParameters.invitationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async projectFileCollaboratorControllerAcceptTeamProjectFileCollaboratorInvite(requestParameters: ProjectFileCollaboratorControllerAcceptTeamProjectFileCollaboratorInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.projectFileCollaboratorControllerAcceptTeamProjectFileCollaboratorInviteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async projectFileCollaboratorControllerAcceptUserProjectFileCollaboratorInviteRaw(requestParameters: ProjectFileCollaboratorControllerAcceptUserProjectFileCollaboratorInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling projectFileCollaboratorControllerAcceptUserProjectFileCollaboratorInvite.');
        }

        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectFileCollaboratorControllerAcceptUserProjectFileCollaboratorInvite.');
        }

        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling projectFileCollaboratorControllerAcceptUserProjectFileCollaboratorInvite.');
        }

        if (requestParameters.invitationId === null || requestParameters.invitationId === undefined) {
            throw new runtime.RequiredError('invitationId','Required parameter requestParameters.invitationId was null or undefined when calling projectFileCollaboratorControllerAcceptUserProjectFileCollaboratorInvite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/users/{userId}/projects/{projectId}/files/{fileId}/invitations/{invitationId}:accept`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))).replace(`{${"invitationId"}}`, encodeURIComponent(String(requestParameters.invitationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async projectFileCollaboratorControllerAcceptUserProjectFileCollaboratorInvite(requestParameters: ProjectFileCollaboratorControllerAcceptUserProjectFileCollaboratorInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.projectFileCollaboratorControllerAcceptUserProjectFileCollaboratorInviteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async projectFileCollaboratorControllerCreateTeamProjectFileCollaboratorInvitationRaw(requestParameters: ProjectFileCollaboratorControllerCreateTeamProjectFileCollaboratorInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IdResponse>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling projectFileCollaboratorControllerCreateTeamProjectFileCollaboratorInvitation.');
        }

        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectFileCollaboratorControllerCreateTeamProjectFileCollaboratorInvitation.');
        }

        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling projectFileCollaboratorControllerCreateTeamProjectFileCollaboratorInvitation.');
        }

        if (requestParameters.createProjectFileCollaboratorInvitationRequest === null || requestParameters.createProjectFileCollaboratorInvitationRequest === undefined) {
            throw new runtime.RequiredError('createProjectFileCollaboratorInvitationRequest','Required parameter requestParameters.createProjectFileCollaboratorInvitationRequest was null or undefined when calling projectFileCollaboratorControllerCreateTeamProjectFileCollaboratorInvitation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/teams/{teamId}/projects/{projectId}/files/{fileId}/invitations`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.createProjectFileCollaboratorInvitationRequest,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async projectFileCollaboratorControllerCreateTeamProjectFileCollaboratorInvitation(requestParameters: ProjectFileCollaboratorControllerCreateTeamProjectFileCollaboratorInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IdResponse> {
        const response = await this.projectFileCollaboratorControllerCreateTeamProjectFileCollaboratorInvitationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectFileCollaboratorControllerCreateUserProjectFileCollaboratorInvitationRaw(requestParameters: ProjectFileCollaboratorControllerCreateUserProjectFileCollaboratorInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IdResponse>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling projectFileCollaboratorControllerCreateUserProjectFileCollaboratorInvitation.');
        }

        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectFileCollaboratorControllerCreateUserProjectFileCollaboratorInvitation.');
        }

        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling projectFileCollaboratorControllerCreateUserProjectFileCollaboratorInvitation.');
        }

        if (requestParameters.createProjectFileCollaboratorInvitationRequest === null || requestParameters.createProjectFileCollaboratorInvitationRequest === undefined) {
            throw new runtime.RequiredError('createProjectFileCollaboratorInvitationRequest','Required parameter requestParameters.createProjectFileCollaboratorInvitationRequest was null or undefined when calling projectFileCollaboratorControllerCreateUserProjectFileCollaboratorInvitation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/users/{userId}/projects/{projectId}/files/{fileId}/invitations`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.createProjectFileCollaboratorInvitationRequest,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async projectFileCollaboratorControllerCreateUserProjectFileCollaboratorInvitation(requestParameters: ProjectFileCollaboratorControllerCreateUserProjectFileCollaboratorInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IdResponse> {
        const response = await this.projectFileCollaboratorControllerCreateUserProjectFileCollaboratorInvitationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectFileCollaboratorControllerDeleteTeamProjectFileCollaboratorRaw(requestParameters: ProjectFileCollaboratorControllerDeleteTeamProjectFileCollaboratorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling projectFileCollaboratorControllerDeleteTeamProjectFileCollaborator.');
        }

        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectFileCollaboratorControllerDeleteTeamProjectFileCollaborator.');
        }

        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling projectFileCollaboratorControllerDeleteTeamProjectFileCollaborator.');
        }

        if (requestParameters.collaboratorId === null || requestParameters.collaboratorId === undefined) {
            throw new runtime.RequiredError('collaboratorId','Required parameter requestParameters.collaboratorId was null or undefined when calling projectFileCollaboratorControllerDeleteTeamProjectFileCollaborator.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/teams/{teamId}/projects/{projectId}/files/{fileId}/collaborators/{collaboratorId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))).replace(`{${"collaboratorId"}}`, encodeURIComponent(String(requestParameters.collaboratorId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async projectFileCollaboratorControllerDeleteTeamProjectFileCollaborator(requestParameters: ProjectFileCollaboratorControllerDeleteTeamProjectFileCollaboratorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.projectFileCollaboratorControllerDeleteTeamProjectFileCollaboratorRaw(requestParameters, initOverrides);
    }

    /**
     */
    async projectFileCollaboratorControllerDeleteUserProjectFileCollaboratorRaw(requestParameters: ProjectFileCollaboratorControllerDeleteUserProjectFileCollaboratorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling projectFileCollaboratorControllerDeleteUserProjectFileCollaborator.');
        }

        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectFileCollaboratorControllerDeleteUserProjectFileCollaborator.');
        }

        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling projectFileCollaboratorControllerDeleteUserProjectFileCollaborator.');
        }

        if (requestParameters.collaboratorId === null || requestParameters.collaboratorId === undefined) {
            throw new runtime.RequiredError('collaboratorId','Required parameter requestParameters.collaboratorId was null or undefined when calling projectFileCollaboratorControllerDeleteUserProjectFileCollaborator.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/users/{userId}/projects/{projectId}/files/{fileId}/collaborators/{collaboratorId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))).replace(`{${"collaboratorId"}}`, encodeURIComponent(String(requestParameters.collaboratorId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async projectFileCollaboratorControllerDeleteUserProjectFileCollaborator(requestParameters: ProjectFileCollaboratorControllerDeleteUserProjectFileCollaboratorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.projectFileCollaboratorControllerDeleteUserProjectFileCollaboratorRaw(requestParameters, initOverrides);
    }

    /**
     */
    async projectFileCollaboratorControllerPatchTeamProjectFileCollaboratorRaw(requestParameters: ProjectFileCollaboratorControllerPatchTeamProjectFileCollaboratorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling projectFileCollaboratorControllerPatchTeamProjectFileCollaborator.');
        }

        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectFileCollaboratorControllerPatchTeamProjectFileCollaborator.');
        }

        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling projectFileCollaboratorControllerPatchTeamProjectFileCollaborator.');
        }

        if (requestParameters.collaboratorId === null || requestParameters.collaboratorId === undefined) {
            throw new runtime.RequiredError('collaboratorId','Required parameter requestParameters.collaboratorId was null or undefined when calling projectFileCollaboratorControllerPatchTeamProjectFileCollaborator.');
        }

        if (requestParameters.patchProjectFileCollaboratorRequest === null || requestParameters.patchProjectFileCollaboratorRequest === undefined) {
            throw new runtime.RequiredError('patchProjectFileCollaboratorRequest','Required parameter requestParameters.patchProjectFileCollaboratorRequest was null or undefined when calling projectFileCollaboratorControllerPatchTeamProjectFileCollaborator.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/teams/{teamId}/projects/{projectId}/files/{fileId}/collaborators/{collaboratorId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))).replace(`{${"collaboratorId"}}`, encodeURIComponent(String(requestParameters.collaboratorId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.patchProjectFileCollaboratorRequest,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async projectFileCollaboratorControllerPatchTeamProjectFileCollaborator(requestParameters: ProjectFileCollaboratorControllerPatchTeamProjectFileCollaboratorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.projectFileCollaboratorControllerPatchTeamProjectFileCollaboratorRaw(requestParameters, initOverrides);
    }

    /**
     */
    async projectFileCollaboratorControllerPatchUserProjectFileCollaboratorRaw(requestParameters: ProjectFileCollaboratorControllerPatchUserProjectFileCollaboratorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling projectFileCollaboratorControllerPatchUserProjectFileCollaborator.');
        }

        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectFileCollaboratorControllerPatchUserProjectFileCollaborator.');
        }

        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling projectFileCollaboratorControllerPatchUserProjectFileCollaborator.');
        }

        if (requestParameters.collaboratorId === null || requestParameters.collaboratorId === undefined) {
            throw new runtime.RequiredError('collaboratorId','Required parameter requestParameters.collaboratorId was null or undefined when calling projectFileCollaboratorControllerPatchUserProjectFileCollaborator.');
        }

        if (requestParameters.patchProjectFileCollaboratorRequest === null || requestParameters.patchProjectFileCollaboratorRequest === undefined) {
            throw new runtime.RequiredError('patchProjectFileCollaboratorRequest','Required parameter requestParameters.patchProjectFileCollaboratorRequest was null or undefined when calling projectFileCollaboratorControllerPatchUserProjectFileCollaborator.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/users/{userId}/projects/{projectId}/files/{fileId}/collaborators/{collaboratorId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))).replace(`{${"collaboratorId"}}`, encodeURIComponent(String(requestParameters.collaboratorId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.patchProjectFileCollaboratorRequest,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async projectFileCollaboratorControllerPatchUserProjectFileCollaborator(requestParameters: ProjectFileCollaboratorControllerPatchUserProjectFileCollaboratorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.projectFileCollaboratorControllerPatchUserProjectFileCollaboratorRaw(requestParameters, initOverrides);
    }

    /**
     * Remove pending invitation in personal file share dialog
     */
    async projectFileCollaboratorControllerRemovePersonalProjectFileCollaboratorInviteRaw(requestParameters: ProjectFileCollaboratorControllerRemovePersonalProjectFileCollaboratorInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling projectFileCollaboratorControllerRemovePersonalProjectFileCollaboratorInvite.');
        }

        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectFileCollaboratorControllerRemovePersonalProjectFileCollaboratorInvite.');
        }

        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling projectFileCollaboratorControllerRemovePersonalProjectFileCollaboratorInvite.');
        }

        if (requestParameters.invitationId === null || requestParameters.invitationId === undefined) {
            throw new runtime.RequiredError('invitationId','Required parameter requestParameters.invitationId was null or undefined when calling projectFileCollaboratorControllerRemovePersonalProjectFileCollaboratorInvite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/users/{userId}/projects/{projectId}/files/{fileId}/invitations/{invitationId}:remove`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))).replace(`{${"invitationId"}}`, encodeURIComponent(String(requestParameters.invitationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove pending invitation in personal file share dialog
     */
    async projectFileCollaboratorControllerRemovePersonalProjectFileCollaboratorInvite(requestParameters: ProjectFileCollaboratorControllerRemovePersonalProjectFileCollaboratorInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.projectFileCollaboratorControllerRemovePersonalProjectFileCollaboratorInviteRaw(requestParameters, initOverrides);
    }

    /**
     * Remove pending invitation in team file share dialog
     */
    async projectFileCollaboratorControllerRemoveTeamProjectFileCollaboratorInviteRaw(requestParameters: ProjectFileCollaboratorControllerRemoveTeamProjectFileCollaboratorInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling projectFileCollaboratorControllerRemoveTeamProjectFileCollaboratorInvite.');
        }

        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectFileCollaboratorControllerRemoveTeamProjectFileCollaboratorInvite.');
        }

        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling projectFileCollaboratorControllerRemoveTeamProjectFileCollaboratorInvite.');
        }

        if (requestParameters.invitationId === null || requestParameters.invitationId === undefined) {
            throw new runtime.RequiredError('invitationId','Required parameter requestParameters.invitationId was null or undefined when calling projectFileCollaboratorControllerRemoveTeamProjectFileCollaboratorInvite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/teams/{teamId}/projects/{projectId}/files/{fileId}/invitations/{invitationId}:remove`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))).replace(`{${"invitationId"}}`, encodeURIComponent(String(requestParameters.invitationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove pending invitation in team file share dialog
     */
    async projectFileCollaboratorControllerRemoveTeamProjectFileCollaboratorInvite(requestParameters: ProjectFileCollaboratorControllerRemoveTeamProjectFileCollaboratorInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.projectFileCollaboratorControllerRemoveTeamProjectFileCollaboratorInviteRaw(requestParameters, initOverrides);
    }

    /**
     * Resent mail for personal-project-file-invitation
     */
    async projectFileCollaboratorControllerResendPersonalProjectCollaboratorInviteEmailRaw(requestParameters: ProjectFileCollaboratorControllerResendPersonalProjectCollaboratorInviteEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectFileCollaboratorControllerResendPersonalProjectCollaboratorInviteEmail.');
        }

        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling projectFileCollaboratorControllerResendPersonalProjectCollaboratorInviteEmail.');
        }

        if (requestParameters.invitationId === null || requestParameters.invitationId === undefined) {
            throw new runtime.RequiredError('invitationId','Required parameter requestParameters.invitationId was null or undefined when calling projectFileCollaboratorControllerResendPersonalProjectCollaboratorInviteEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/projects/{projectId}/files/{fileId}/invitations/{invitationId}:resendEmail`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))).replace(`{${"invitationId"}}`, encodeURIComponent(String(requestParameters.invitationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Resent mail for personal-project-file-invitation
     */
    async projectFileCollaboratorControllerResendPersonalProjectCollaboratorInviteEmail(requestParameters: ProjectFileCollaboratorControllerResendPersonalProjectCollaboratorInviteEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.projectFileCollaboratorControllerResendPersonalProjectCollaboratorInviteEmailRaw(requestParameters, initOverrides);
    }

    /**
     * Resent mail for team-project-file-invitation
     */
    async projectFileCollaboratorControllerResendTeamProjectCollaboratorInviteEmailRaw(requestParameters: ProjectFileCollaboratorControllerResendTeamProjectCollaboratorInviteEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling projectFileCollaboratorControllerResendTeamProjectCollaboratorInviteEmail.');
        }

        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectFileCollaboratorControllerResendTeamProjectCollaboratorInviteEmail.');
        }

        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling projectFileCollaboratorControllerResendTeamProjectCollaboratorInviteEmail.');
        }

        if (requestParameters.invitationId === null || requestParameters.invitationId === undefined) {
            throw new runtime.RequiredError('invitationId','Required parameter requestParameters.invitationId was null or undefined when calling projectFileCollaboratorControllerResendTeamProjectCollaboratorInviteEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/teams/{teamId}/projects/{projectId}/files/{fileId}/invitations/{invitationId}:resendEmail`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))).replace(`{${"invitationId"}}`, encodeURIComponent(String(requestParameters.invitationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Resent mail for team-project-file-invitation
     */
    async projectFileCollaboratorControllerResendTeamProjectCollaboratorInviteEmail(requestParameters: ProjectFileCollaboratorControllerResendTeamProjectCollaboratorInviteEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.projectFileCollaboratorControllerResendTeamProjectCollaboratorInviteEmailRaw(requestParameters, initOverrides);
    }

    /**
     * Update the permission of Pending Personal Project-File-Collaborator invitation
     */
    async projectFileCollaboratorControllerUpdatePermissionOfPersonalProjectFileInvitationRaw(requestParameters: ProjectFileCollaboratorControllerUpdatePermissionOfPersonalProjectFileInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectFileCollaboratorControllerUpdatePermissionOfPersonalProjectFileInvitation.');
        }

        if (requestParameters.invitationId === null || requestParameters.invitationId === undefined) {
            throw new runtime.RequiredError('invitationId','Required parameter requestParameters.invitationId was null or undefined when calling projectFileCollaboratorControllerUpdatePermissionOfPersonalProjectFileInvitation.');
        }

        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling projectFileCollaboratorControllerUpdatePermissionOfPersonalProjectFileInvitation.');
        }

        if (requestParameters.updateProjectFileInvitationRequest === null || requestParameters.updateProjectFileInvitationRequest === undefined) {
            throw new runtime.RequiredError('updateProjectFileInvitationRequest','Required parameter requestParameters.updateProjectFileInvitationRequest was null or undefined when calling projectFileCollaboratorControllerUpdatePermissionOfPersonalProjectFileInvitation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/projects/{projectId}/files/{fileId}/invitations/{invitationId}:updateRight`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"invitationId"}}`, encodeURIComponent(String(requestParameters.invitationId))).replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.updateProjectFileInvitationRequest,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update the permission of Pending Personal Project-File-Collaborator invitation
     */
    async projectFileCollaboratorControllerUpdatePermissionOfPersonalProjectFileInvitation(requestParameters: ProjectFileCollaboratorControllerUpdatePermissionOfPersonalProjectFileInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.projectFileCollaboratorControllerUpdatePermissionOfPersonalProjectFileInvitationRaw(requestParameters, initOverrides);
    }

    /**
     * Update the permission of Pending Team Project-File-Collaborator invitation
     */
    async projectFileCollaboratorControllerUpdatePermissionOfTeamProjectFileInvitationRaw(requestParameters: ProjectFileCollaboratorControllerUpdatePermissionOfTeamProjectFileInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling projectFileCollaboratorControllerUpdatePermissionOfTeamProjectFileInvitation.');
        }

        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectFileCollaboratorControllerUpdatePermissionOfTeamProjectFileInvitation.');
        }

        if (requestParameters.invitationId === null || requestParameters.invitationId === undefined) {
            throw new runtime.RequiredError('invitationId','Required parameter requestParameters.invitationId was null or undefined when calling projectFileCollaboratorControllerUpdatePermissionOfTeamProjectFileInvitation.');
        }

        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling projectFileCollaboratorControllerUpdatePermissionOfTeamProjectFileInvitation.');
        }

        if (requestParameters.updateProjectFileInvitationRequest === null || requestParameters.updateProjectFileInvitationRequest === undefined) {
            throw new runtime.RequiredError('updateProjectFileInvitationRequest','Required parameter requestParameters.updateProjectFileInvitationRequest was null or undefined when calling projectFileCollaboratorControllerUpdatePermissionOfTeamProjectFileInvitation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/teams/{teamId}/projects/{projectId}/files/{fileId}/invitations/{invitationId}:updateRight`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"invitationId"}}`, encodeURIComponent(String(requestParameters.invitationId))).replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.updateProjectFileInvitationRequest,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update the permission of Pending Team Project-File-Collaborator invitation
     */
    async projectFileCollaboratorControllerUpdatePermissionOfTeamProjectFileInvitation(requestParameters: ProjectFileCollaboratorControllerUpdatePermissionOfTeamProjectFileInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.projectFileCollaboratorControllerUpdatePermissionOfTeamProjectFileInvitationRaw(requestParameters, initOverrides);
    }

}
