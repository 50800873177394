import React from 'react'

import { InteractionEntityType } from '@phase-software/types'

import { useInteraction } from '../../../providers/dataStore/InteractionProvider'
import { PROPERTY_LIST } from '../constant'
import KeyFrameList from './KeyFrameList'
import PropertyTrack from './PropertyTrack'

type ElementTrackProps = {
  trackId: string
  place: string
}

const ElementTrack = ({ trackId, place }: ElementTrackProps) => {
  const elementTrack = useInteraction(trackId)

  if (!elementTrack) {
    return null
  }
  const isExpanded = elementTrack.expanded

  if (!isExpanded) {
    return (
      <KeyFrameList
        key={trackId}
        trackId={trackId}
        expanded={isExpanded}
        type={InteractionEntityType.ELEMENT_TRACK}
        showKeyFrame={!isExpanded}
      />
    )
  }

  const propertyTrackList = PROPERTY_LIST.map((propertyKey) => {
    if (elementTrack.propertyTrackMap.has(propertyKey)) {
      const propertyTrackId = elementTrack.propertyTrackMap.get(propertyKey)
      return <PropertyTrack key={propertyKey} trackId={propertyTrackId} indent={0} place={place} />
    }
  }).filter(Boolean)

  return (
    <>
      {[
        <KeyFrameList
          key={trackId}
          trackId={trackId}
          expanded={isExpanded}
          type={InteractionEntityType.ELEMENT_TRACK}
          showKeyFrame={!isExpanded}
        />,
        ...propertyTrackList
      ]}
    </>
  )
}

export default React.memo(ElementTrack)
