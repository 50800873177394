import React from 'react'

import { LoadingStatus } from '@phase-software/types'

import Button from '../Button'

type ProgressButtonProps = {
  size?: 's' | 'l'
  color?: 'primary' | 'secondary' | 'danger'
  variant?: 'solid' | 'flat' | 'ghost'
  disabled?: boolean
  children?: React.ReactNode
  className?: string
  status?: LoadingStatus
  autoFocus?: boolean
  fluid?: boolean
  type?: 'button' | 'submit'
  fixedWidth?: number
  onClick?: () => void
}
const ProgressButton = ({
  size = 'l',
  color = 'primary',
  className = '',
  children,
  disabled,
  autoFocus,
  type = 'button',
  variant,
  status = LoadingStatus.INITIAL,
  fixedWidth = 0,
  ...rest
}: ProgressButtonProps) => {
  const isInitialStatus = status === LoadingStatus.INITIAL

  return (
    <Button
      size={size}
      disabled={disabled || !isInitialStatus}
      color={color}
      variant={variant}
      autoFocus={autoFocus}
      type={type}
      fixedWidth={fixedWidth}
      className={`relative  ${className}`}
      {...rest}
    >
      <div className="absolute h-16 w-16 inset-1/2 -translate-x-1/2	-translate-y-1/2">
        {status === LoadingStatus.WAITING && <LoadingIcon />}
        <FinishedIcon visible={status === LoadingStatus.FINISHED} />
      </div>
      <div className={isInitialStatus ? '' : 'text-transparent'}>{children}</div>
    </Button>
  )
}

export default ProgressButton

const LoadingIcon = () => {
  return (
    <svg className="animate-spin text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  )
}

const FinishedIcon = ({ visible }: { visible: boolean }) => {
  return (
    <svg viewBox="0 0 16 16" fill="none">
      <path
        className={`progress-indicator-stroke-animation ${visible ? 'animate' : ''}`}
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeDasharray="14"
        strokeDashoffset="14"
        d="M3.10001 9L5.78701 11.687C5.86512 11.7651 5.99175 11.7651 6.06985 11.687L12.9995 4.75736"
      />
    </svg>
  )
}
