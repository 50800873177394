import { PERMISSIONS } from '../access-control'
import { MenuListOptionProps } from '../components/shared/Menu/Menu.types'

export const FILE_OPTIONS = {
  RENAME: 'RENAME',
  DUPLICATE: 'DUPLICATE',
  MOVE: 'MOVE',
  GO_TO_PROJECT: 'GO_TO_PROJECT',
  ARCHIVE: 'ARCHIVE',
  UNARCHIVE: 'UNARCHIVE',
  DELETE: 'DELETE',
  OPEN_IN_NEW_TAB: 'OPEN_IN_NEW_TAB',
  COPY_LINK: 'COPY_LINK',
  SHARE: 'SHARE',
  DOWNLOAD: 'DOWNLOAD'
}

const RENAME_OPTION = {
  name: 'common:menu.rename',
  value: FILE_OPTIONS.RENAME,
  dataTestId: 'rename-option',
  permission: PERMISSIONS.RENAME_FILE
}

const DUPLICATE_OPTION = {
  name: 'common:menu.duplicate',
  value: FILE_OPTIONS.DUPLICATE,
  dataTestId: 'duplicate-option',
  permission: PERMISSIONS.DUPLICATE_FILE
}

const MOVE_OPTION = {
  name: 'common:menu.move',
  value: FILE_OPTIONS.MOVE,
  dataTestId: 'move-option',
  permission: PERMISSIONS.MOVE_FILE
}

const GO_TO_PROJECT_OPTION = {
  name: 'common:menu.go_to_project',
  value: FILE_OPTIONS.GO_TO_PROJECT,
  dataTestId: 'go-to-project-option',
  permission: PERMISSIONS.MOVE_FILE
}

const ARCHIVE_OPTION = {
  name: 'common:menu.archive',
  value: FILE_OPTIONS.ARCHIVE,
  dataTestId: 'archive-option',
  permission: PERMISSIONS.ARCHIVE_FILE
}

const DELETE_OPTION = {
  name: 'common:menu.delete',
  value: FILE_OPTIONS.DELETE,
  dataTestId: 'delete-option',
  permission: PERMISSIONS.DELETE_FILE
}

export const DOWNLOAD_OPTIONS: MenuListOptionProps[] = [
  '-',
  {
    name: 'common:menu.download',
    value: FILE_OPTIONS.DOWNLOAD,
    dataTestId: 'download-option'
  }
]

export const PROJECT_FILE_OPTIONS: MenuListOptionProps[] = [
  {
    name: 'common:menu.open_in_new_tab',
    value: FILE_OPTIONS.OPEN_IN_NEW_TAB,
    dataTestId: 'open-file-new-tab-option'
  },
  {
    name: 'common:menu.copy_link',
    value: FILE_OPTIONS.COPY_LINK,
    dataTestId: 'copy-link-option'
  },
  {
    name: 'common:menu.share',
    value: FILE_OPTIONS.SHARE,
    dataTestId: 'share-option'
  },
  RENAME_OPTION,
  DUPLICATE_OPTION,
  MOVE_OPTION,
  '-',
  ARCHIVE_OPTION,
  DELETE_OPTION
]

export const ARCHIVE_FILE_OPTIONS: MenuListOptionProps[] = [
  {
    name: 'common:menu.unarchive',
    value: FILE_OPTIONS.UNARCHIVE,
    dataTestId: 'unarchive-option',
    permission: PERMISSIONS.ARCHIVE_FILE
  },
  '-',
  DELETE_OPTION
]

export const FILE_TITLE_DROPDOWN_OPTIONS: MenuListOptionProps[] = [
  RENAME_OPTION,
  DUPLICATE_OPTION,
  MOVE_OPTION,
  GO_TO_PROJECT_OPTION,
  '-',
  ARCHIVE_OPTION,
  DELETE_OPTION
]

export const TEMPLATE_OPTIONS: MenuListOptionProps[] = [
  {
    name: 'common:menu.open_in_new_tab',
    value: FILE_OPTIONS.OPEN_IN_NEW_TAB,
    dataTestId: 'open-template-new-tab-option'
  }
]
