import { pick } from 'lodash'
import { PaintType } from '../constants'

export const getPaintData = (paint) => {
  const data = pick(paint, ['id', 'paintType', 'blendMode', 'opacity'])
  switch (data.paintType) {
    case PaintType.SOLID:
      data.color = [...paint.color]
      break
    case PaintType.GRADIENT:
      Object.assign(data, pick(paint, ['gradientType', 'gradientStops']))
      data.gradientStops = data.gradientStops.map(({ position, color }) => ({
        position,
        color: [...color]
      }))
      break
    case PaintType.IMAGE:
      Object.assign(data, pick(paint, ['imageId', 'imageMode']))
      break
    default:
      break
  }
  return data
}
