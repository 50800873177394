import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { isEmpty } from 'lodash'

import { FrameType, Unit } from '@phase-software/types'

import { useInspectContext } from '../../../providers/InspectProvider'
import { useInspectPanelSectionState } from '../../../providers/RightPanelProvider'
import { useDataStore } from '../../../providers/dataStore/DataStoreProvider'
import { useEditor } from '../../../providers/dataStore/EditorProvider'
import { useInteractionActions } from '../../../providers/dataStore/InteractionProvider'
import { formatAsPercentage } from '../../../utils/formatter'
import { easingTypeNameMap } from '../../Interaction/constant'
import { useCopyKeyframesData } from '../Hooks/useCopyKeyframesData'
import InspectContent from '../PropertyInspection/InspectContent'
import InspectRow from '../PropertyInspection/InspectRow'
import InteractiveInspectContent from '../PropertyInspection/InteractiveInspectContent'
import { commonFormatter, shouldShowBezierRow } from '../utils'

type OriginInspectionProps = {
  elementId: string
}

type InitialInspectionRowProps = {
  elementId: string
  label: string
  handleXDisplay: (x: number) => string
  handleYDisplay: (y: number) => string
}

const InitialInspectionRow = ({ elementId, label, handleXDisplay, handleYDisplay }: InitialInspectionRowProps) => {
  const dataStore = useDataStore()
  const value = useMemo(
    () => dataStore.transition.getPropertyValue(elementId, 'contentAnchor'),
    [elementId, dataStore.transition]
  )

  return (
    <InspectRow
      type="wide"
      label={label}
      value={`${handleXDisplay(value.contentAnchorX)}, ${handleYDisplay(value.contentAnchorY)}`}
    />
  )
}

const OriginInspection = ({ elementId }: OriginInspectionProps) => {
  const { t } = useTranslation('file')
  const contentAnchorXUnit = useEditor((o) => o.contentAnchorXUnit)
  const contentAnchorYUnit = useEditor((o) => o.contentAnchorYUnit)
  const dataStore = useDataStore()
  const isPanelOpen = useInspectPanelSectionState('contentAnchor')
  const copyKeyframesData = useCopyKeyframesData()
  const { getPropertyKeyFrameGroupByTime, getKeyFrame } = useInteractionActions()
  const { getTimeString } = useInspectContext()

  const animationKeyFrames = useMemo(
    () => getPropertyKeyFrameGroupByTime(elementId, 'contentAnchor'),
    [elementId, getPropertyKeyFrameGroupByTime]
  )

  const handleXDisplay = (x: number) => {
    if (contentAnchorXUnit === Unit.PERCENT) return `${formatAsPercentage(x)}`
    return commonFormatter(x)
  }

  const handleYDisplay = (y: number) => {
    if (contentAnchorYUnit === Unit.PERCENT) return `${formatAsPercentage(y)}`
    return commonFormatter(y)
  }

  const handleCopy = () => {
    copyKeyframesData({ animationKeyFrames: animationKeyFrames, target: 'contentAnchor', elementId: elementId })
  }

  const renderContent = (): React.ReactNode => {
    const obj = {} as any
    Object.keys(animationKeyFrames)?.map((time: string) => {
      const keyframe = getKeyFrame(animationKeyFrames[time][0])
      const val = dataStore.transition.getPropertyValueByTime(elementId, 'contentAnchor', Number(time))
      obj[time] = {
        value: { ...val },
        easingType: keyframe.easingType,
        bezier: keyframe.bezier,
        frameType: keyframe.frameType
      }
    })

    return Object.keys(obj).map((key) => {
      return (
        <InspectContent key={key} spacedDivider>
          <InspectRow type="wide" label={t('inspect.time')} value={getTimeString(Number(key))} />
          {obj[key].frameType === FrameType.INITIAL ? (
            <InitialInspectionRow
              elementId={elementId}
              label={t('inspect.value')}
              handleXDisplay={handleXDisplay}
              handleYDisplay={handleYDisplay}
            />
          ) : (
            <InspectRow
              type="wide"
              label={t('inspect.value')}
              value={`${handleXDisplay(obj[key].value.contentAnchorX)}, ${handleYDisplay(
                obj[key].value.contentAnchorY
              )}`}
            />
          )}
          <InspectRow
            type="wide"
            label={t('inspect.ease')}
            value={t(easingTypeNameMap[obj[key].easingType as keyof typeof easingTypeNameMap], {
              keyPrefix: 'easing_type'
            })}
          />
          {shouldShowBezierRow(obj[key].easingType) && (
            <InspectRow type="wide" label={t('inspect.bezier')} value={obj[key]?.bezier?.join(', ')} />
          )}
        </InspectContent>
      )
    })
  }

  if (isEmpty(animationKeyFrames)) return null
  return (
    <>
      <InteractiveInspectContent title={t('inspect.origin')} target="contentAnchor" handleCopy={handleCopy}>
        <InitialInspectionRow
          elementId={elementId}
          label={t('inspect.initial')}
          handleXDisplay={handleXDisplay}
          handleYDisplay={handleYDisplay}
        />
      </InteractiveInspectContent>
      {isPanelOpen && renderContent()}
    </>
  )
}

export default React.memo(OriginInspection)
