import React, { ReactNode } from 'react'
import { ReactComponent as Logo } from './Logo.svg'

interface ResultProps {
  title: string
  subTitle: ReactNode
  extra?: ReactNode
}

const Result = ({ title, subTitle, extra }: ResultProps) => {
  return (
    <div className="fixed inset-0 w-full h-full flex flex-col items-center justify-center bg-dark-overlay-90">
      <div className="absolute top-40 left-40">
        <Logo />
      </div>
      <div className="max-w-[400px] text-center text-white">
        <div className="text-h3 font-semibold mb-16">{title}</div>
        <div className="mb-32">{subTitle}</div>
        {extra && <div>{extra}</div>}
      </div>
    </div>
  )
}

export default Result
