import React from 'react'
import { useTranslation } from 'react-i18next'

import Field from '../../components/Settings/Field'
import { Avatar, Grid } from '../../components/shared'
import { useTeamSettingsContext } from './TeamSettingsContext'

const TeamGeneralViewMode = () => {
  const { t } = useTranslation('setting')
  const { name, description, location, avatar } = useTeamSettingsContext()

  return (
    // @ts-ignore TODO: fix after refactor of Grid
    <Grid gridGap={24} className="mt-32 text-light-overlay-60">
      <Field label={t('team_setting.team_logo')}>
        <Avatar size={112} alt={name} src={avatar} />
      </Field>
      <Field label={t('team_setting.team_name')}>{name}</Field>
      {location && <Field label={t('team_setting.location')}>{location}</Field>}
      {description && <Field label={t('team_setting.description')}>{description}</Field>}
    </Grid>
  )
}

export default TeamGeneralViewMode
