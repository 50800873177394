import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { PERMISSIONS, useAccessControl } from '../../../../access-control'
import { Icon, ScrollView, WideTitle } from '../../../../components/shared'
import { type MenuOptionProps } from '../../../../components/shared/Menu/MenuOption'
import { type WideTitleIconProps } from '../../../../components/shared/WideTitle'
import { useGetVersionsQuery } from '../../../../generated/graphql'
import { useFilePermissionContext } from '../../../../providers/FilePermissionProvider'
import { useFileVersionContext } from '../../../../providers/FileVersionContextProvider'
import { useUI } from '../../../../providers/dataStore/UIProvider'
import { scrollIntoView } from '../../../../utils/dom'
import { LATEST_VERSION_ITEM, VersionProps, transformVersion } from '../../../../utils/transformVersion'
import CreateVersionDialog from './CreateVersionDialog'
import VersionItem from './VersionItem'

const PAGINATION_LIMIT = 20

type FileVersionHistoryProps = {
  fileId: string
  projectId: string
}

function FileVersionHistoryPanel({ projectId, fileId }: FileVersionHistoryProps) {
  const { isVersioningState } = useUI()
  const { t } = useTranslation()
  const { userHasPermission } = useAccessControl()
  const { fileWithPermission } = useFilePermissionContext()
  const { currentVersionId, debouncedLoadVersion, versionAdded, resetVersionAdded } = useFileVersionContext()

  const observerRef = useRef(null)
  const scrollViewRef = useRef<HTMLDivElement>(null)
  const [versionLoadLimit, setVersionLoadLimit] = useState(PAGINATION_LIMIT)
  const [isEndOfVersions, setIsEndOfVersions] = useState(false)
  const [showUnnamedVersion, setShowUnnamedVersion] = useState(true)
  const [isCreateVersionDialogOpen, setIsCreateVersionDialogOpen] = useState(false)
  const localizedVersionItem = useMemo(() => ({ ...LATEST_VERSION_ITEM, name: t(LATEST_VERSION_ITEM.name) }), [t])

  const {
    data: versionsData,
    fetchMore,
    refetch
  } = useGetVersionsQuery({
    variables: {
      fileId,
      offset: 0,
      limit: versionLoadLimit,
      excludeUnnamed: !showUnnamedVersion
    },
    skip: !isVersioningState
  })
  const fileVersions = [localizedVersionItem, ...(versionsData?.versions || [])].map((version) =>
    transformVersion(t, version)
  )
  const lastVersionIndex = fileVersions.length - 1

  useEffect(() => {
    if (!versionsData?.versions || isEndOfVersions) return
    setIsEndOfVersions(versionsData?.versions.length < versionLoadLimit)
  }, [isEndOfVersions, versionLoadLimit, versionsData?.versions])

  const handleVersionItemClick = (version: VersionProps) => {
    if (currentVersionId !== version.id) {
      debouncedLoadVersion(version)
    }
  }

  const fetchMoreVersions = useCallback(async () => {
    try {
      const currentLength = versionsData?.versions.length || 0
      const fetchMoreResult = await fetchMore({
        variables: {
          offset: currentLength,
          limit: PAGINATION_LIMIT,
          excludeUnnamed: !showUnnamedVersion
        }
      })

      const newVersionsCount = fetchMoreResult.data.versions.length
      setVersionLoadLimit(currentLength + newVersionsCount)
      if (newVersionsCount < PAGINATION_LIMIT) {
        setIsEndOfVersions(true)
      }
    } catch (error) {
      console.error('Error fetching more versions:', error)
    }
  }, [fetchMore, showUnnamedVersion, versionsData?.versions.length])

  useEffect(() => {
    if (versionAdded) {
      refetch({ offset: 0, limit: versionLoadLimit + 1, excludeUnnamed: !showUnnamedVersion })
      resetVersionAdded()
    }
  }, [refetch, resetVersionAdded, showUnnamedVersion, versionAdded, versionLoadLimit])

  useEffect(() => {
    if (!isVersioningState) return
    const handleArrowKeyNavigation = (event: KeyboardEvent) => {
      const currentVersionIndex = fileVersions.findIndex((version) => version.id === currentVersionId)
      if (currentVersionIndex === -1) return
      let newVersionIndex = currentVersionIndex

      switch (event.key) {
        case 'ArrowUp':
          newVersionIndex = Math.max(currentVersionIndex - 1, 0)
          break
        case 'ArrowDown':
          newVersionIndex = Math.min(currentVersionIndex + 1, lastVersionIndex)

          if (currentVersionIndex === lastVersionIndex && !isEndOfVersions) {
            fetchMoreVersions()
          }
          break
        default:
          return
      }

      if (newVersionIndex === currentVersionIndex) return

      const newVersion = fileVersions[newVersionIndex]
      scrollIntoView(scrollViewRef.current?.children[newVersionIndex])
      debouncedLoadVersion(newVersion)
    }

    document.addEventListener('keydown', handleArrowKeyNavigation)

    return () => {
      document.removeEventListener('keydown', handleArrowKeyNavigation)
    }
  }, [
    currentVersionId,
    debouncedLoadVersion,
    fetchMoreVersions,
    fileVersions,
    isEndOfVersions,
    isVersioningState,
    lastVersionIndex
  ])

  useEffect(() => {
    if (!versionsData?.versions.length) return

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          fetchMoreVersions()
        }
      },
      {
        threshold: 0
      }
    )
    const observerRefCurrent = observerRef.current
    if (observerRefCurrent) {
      observer.observe(observerRefCurrent)
    }

    return () => {
      if (observerRefCurrent) {
        observer.unobserve(observerRefCurrent)
      }
    }
  }, [fetchMore, fetchMoreVersions, versionsData?.versions.length])

  const filterOptions: MenuOptionProps[] = useMemo(() => {
    return [
      {
        selected: showUnnamedVersion,
        name: t('file:version.show_auto_saved_versions'),
        value: 'SHOW_UNNAMED_VERSIONS'
      }
    ]
  }, [showUnnamedVersion, t])

  const handleSelectFilterOption = useCallback(
    (option?: MenuOptionProps) => {
      if (option?.value === 'SHOW_UNNAMED_VERSIONS') {
        const newShowUnnamedVersion = !showUnnamedVersion
        setShowUnnamedVersion(newShowUnnamedVersion)
        refetch({ offset: 0, limit: versionLoadLimit, excludeUnnamed: !newShowUnnamedVersion })
      }
    },
    [refetch, showUnnamedVersion, versionLoadLimit]
  )

  const icons: WideTitleIconProps[] = useMemo(() => {
    const defaultIcons: WideTitleIconProps[] = [
      {
        name: 'Filter',
        tooltip: t('file:version.filter'),
        dataTestId: 'version-item-filter',
        dropdownMenu: { selectable: true, options: filterOptions, onSelect: handleSelectFilterOption }
      }
    ]
    const userCanEdit = userHasPermission(PERMISSIONS.EDIT_FILE, fileWithPermission)
    if (userCanEdit) {
      defaultIcons.push({
        name: 'Plus',
        tooltip: t('file:version.add_new_version'),
        dataTestId: 'add-version',
        onClick: () => setIsCreateVersionDialogOpen(true)
      })
    }
    return defaultIcons
  }, [filterOptions, fileWithPermission, handleSelectFilterOption, userHasPermission, t])

  return (
    <>
      <WideTitle icons={icons}>{t('file:version.version_history')}</WideTitle>
      {/* @ts-ignore TODO: fix after refactor of ScrollView */}
      <ScrollView ref={scrollViewRef} className="overflow-auto h-full pb-40" showScrollBarOnHover tabIndex="-1">
        {fileVersions.map((version, index) => (
          <VersionItem
            fileId={fileId}
            id={version.id}
            isLast={index === lastVersionIndex}
            isSelected={version.id === currentVersionId}
            key={version.id}
            name={version.displayName}
            editName={version.editName}
            defaultFileName={version.defaultFileName}
            onClick={() => handleVersionItemClick(version)}
            projectId={projectId}
            timestamp={version.createdAt}
            type={version.type}
            description={version.description}
            contributors={version.contributors}
          />
        ))}
        <div ref={observerRef} />
        {!isEndOfVersions && (
          <div className="flex justify-center py-16 text-white">
            <Icon size="xxl" name="Loading" className="animate-spin" interactive={false} useCurrentColor />
          </div>
        )}
      </ScrollView>
      <CreateVersionDialog
        projectId={projectId}
        fileId={fileId}
        open={isCreateVersionDialogOpen}
        onClose={() => setIsCreateVersionDialogOpen(false)}
      />
    </>
  )
}

export default FileVersionHistoryPanel
