import cursorImages from './cursorImages'

const SECOND_STATE_SET = new Set([
    'duplicate'
])

const CURSOR_CLASS_PREFIX = 'cursor-'

/** @typedef {'add'|'arrowMove'|'convertPath'|'crossMove'|'crossScale'|'crosshair'|'default'|'grab'|'grabbing'|'penAdd'|'penClose'|'penDefault'|'penOpen'|'resize'|'rotate'|'scale'|'comment'} CursorState */


/**
 * Generates a CSS string for setting a cursor image.
 * @param {string} className - The class name to use in the CSS string.
 * @param {string} image1x - The URL of the standard resolution image.
 * @param {string} image2x - The URL of the high resolution image.
 * @returns {string} - The generated CSS string.
 */
function generateCursorStyle(className, image1x, image2x) {
    return `.${className} { cursor: image-set(url(${image1x})1x, url(${image2x})2x) 15 15, auto; }`
}
/**
 * A utility function to create a rotated cursor image.
 * @param {string} imageSrc - The source URL of the image.
 * @param {number} angle - The angle of rotation in radians.
 * @returns {Promise<string>} - A promise that resolves to the data URL of the rotated image.
 */
function createRotatedCursor(imageSrc, angle) {
    return new Promise((resolve, reject) => {
        const img = new Image()
        img.src = imageSrc
        img.onload = () => {
            const canvas = document.createElement('canvas')
            canvas.width = img.width
            canvas.height = img.height
            const ctx = canvas.getContext('2d')
            if (ctx) {
                ctx.translate(canvas.width / 2, canvas.height / 2)
                ctx.rotate(angle)
                ctx.drawImage(img, -img.width / 2, -img.height / 2)
                const rotatedCursor = canvas.toDataURL()
                resolve(rotatedCursor)
            } else {
                reject('Failed to get canvas context')
            }
        }
        img.onerror = () => reject('Failed to load cursor image')
    })
}



document.addEventListener("DOMContentLoaded", () => {
    const style = document.createElement('style')
    const cursorStateStyles = Object.keys(cursorImages).map(state => generateCursorStyle(`${CURSOR_CLASS_PREFIX}${state}`, cursorImages[state]['1x'], cursorImages[state]['2x'])).join(' ')
    style.innerHTML = cursorStateStyles

    document.body.appendChild(style)
})

const cursorClassReg = new RegExp(`${CURSOR_CLASS_PREFIX}(\\w+)`, 'g')

class CursorManager {
    constructor() {
        this.currentClass = ''
        this.currentState = null
        this.currentRotation = 0
        this.secondState = null
        this.nextRotateId = 0
        this.existingRotatedCursors = new Map()
    }

    updateCanvasContainerClass(element, firstClass, secondClass) {
        element.classList.forEach(className => {
            if (className.startsWith(CURSOR_CLASS_PREFIX)) {
                element.classList.remove(className)
            }
        })

        // Clean up second state class
        SECOND_STATE_SET.forEach((secondState) => {
            element.classList.remove(`${CURSOR_CLASS_PREFIX}${secondState}`)
        })

        const firstStateMatch = firstClass.match(cursorClassReg)
        let secondStateMatch = true
        if (secondClass) {
            secondStateMatch = secondClass.match(cursorClassReg)
        }

        if (!firstStateMatch || !secondStateMatch) return

        element.classList.add(firstClass)
        if (secondClass) {
            element.classList.add(secondClass)
        }
    }

    /**
     * Sets the cursor state based on the given type and rotation.
     * @param {CursorState | null} state - The type of cursor state to set.
     * @param {number} [rotation=0] - The rotation value for the cursor state.
     */
    async updateCursorState(state, rotation = 0) {
        const canvasContainer = document.getElementById('canvas-container')
        if (!canvasContainer) return

        const isSecondState = SECOND_STATE_SET.has(state)
        if (!this.secondState && this.currentState === state && this.currentRotation === rotation) {
            return
        }

        if (isSecondState) {
            this.secondState = state
        } else {
            this.currentState = state
            this.currentRotation = rotation
            this.secondState = null
        }

        if (state === null) {
            return
        }

        if (isSecondState) {
            const currentClass = `${CURSOR_CLASS_PREFIX}${this.currentState}`
            const secondStateClass = `${CURSOR_CLASS_PREFIX}${state}`
            this.updateCanvasContainerClass(canvasContainer, currentClass, secondStateClass)
        } else if (state === 'resize' || state === 'scale') {
            await this.setRotatedCursor(canvasContainer, state, rotation)
        } else {
            this.currentClass = `${CURSOR_CLASS_PREFIX}${state}`
            this.updateCanvasContainerClass(canvasContainer, this.currentClass)
        }
    }

    async setRotatedCursor(canvasContainer, state, rotation) {
        try {
            const rotatedCursorCacheKey = `${state}-${rotation}`

            this.currentClass = this.existingRotatedCursors.get(rotatedCursorCacheKey)

            if (!this.currentClass) {
                const [rotatedCursorImage1x, rotatedCursorImage2x] = await Promise.all([
                    createRotatedCursor(cursorImages[state]['1x'], rotation),
                    createRotatedCursor(cursorImages[state]['2x'], rotation)
                ])
                this.currentClass = `${CURSOR_CLASS_PREFIX}${state}-${this.nextRotateId++}`
                this.secondState = null

                const cursorStylesElement = document.createElement('style')
                cursorStylesElement.innerHTML = generateCursorStyle(this.currentClass, rotatedCursorImage1x, rotatedCursorImage2x)
                document.body.appendChild(cursorStylesElement)

                this.existingRotatedCursors.set(rotatedCursorCacheKey, this.currentClass)
            }

            this.updateCanvasContainerClass(canvasContainer, this.currentClass)

        } catch (error) {
            console.error('Failed to create rotated cursor:', error)
        }
    }
}


const initLocalCursor = () => {
    const cursor = new CursorManager()
    return {
        setState: cursor.updateCursorState.bind(cursor),
        getCurrentState: () => ({
            firstState: cursor.currentState,
            secondState: cursor.secondState
        })
    }
}

export default initLocalCursor
