export const WEB_CONFIG_DEFAULT_VALUES = {
  api: {
    nats: '',
    graphql: '',
    websocket: '',
    rest: '',
    svg: '',
    pluginAuth: ''
  },
  cognito: {
    customUI: false,
    serverUrl: '',
    logoutUrl: '',
    clientId: ''
  },
  nats: {
    subject: ''
  },
  heap: {
    appId: ''
  },
  googleAnalytics: {
    token: ''
  },
  waitlist: {
    enable: true,
    url: 'http://waitlist.phase.com/'
  },
  tos: 'https://phase.com/terms/',
  privacy: 'https://phase.com/policy/',
  supportedLanguages: ['en-US'],
  languageLoadPath: 'https://d15n8lnxt3rp68.cloudfront.net/{{lng}}/{{ns}}.json'
}
