import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { uniqBy } from 'lodash'

import { MenuListOptionProps, MenuOptionProps } from '../Menu/Menu.types.js'

import { Menu, Tag } from '..'
import { MenuRef } from '../Menu'
import ErrorTextComponent from '../ErrorTextComponent'

export type TextAreaTagItemProps = {
  label: string
  value: string
  [key: string]: any
}

type TextAreaProps = {
  variant?: 'default' | 'flat'
  allowInputCommaAndSpace?: boolean
  allowTags?: boolean
  className?: string
  customTagValidator?: (tag: TextAreaTagItemProps) => boolean
  defaultTags?: TextAreaTagItemProps[]
  defaultValue?: string
  disabled?: boolean
  error?: string
  autoFocus?: boolean
  /**
   * If the function returns true, the option will be included in the filtered set; Otherwise, it will be excluded
   */
  filterOption?: (searchValue: string, option: MenuOptionProps) => boolean
  height?: number
  onChangeTags?: (tags: TextAreaTagItemProps[]) => void
  onChangeValue?: (value: string) => void
  onTagsValidation?: (allTagsValid: boolean) => void
  onTextAreaFocus?: () => void
  onClickOutside?: () => void
  handleCustomKeyDown?: (
    e: React.KeyboardEvent<HTMLTextAreaElement>,
    value: string,
    textAreaRef: any,
    isComposing: boolean
  ) => void
  handleClickBottomWrapper?: (textAreaRef: any) => void
  placeholder?: string
  rightComponent?: React.ReactNode
  bottomComponent?: React.ReactNode
  rows?: number
  searchMenuOptions?: MenuListOptionProps[]
  showSearch?: boolean
  size?: 's' | 'l'
  tagRegexp?: RegExp
  maxScrollHeight?: number
  minHeight?: number
  exposeClearInputMethod?: (func: any) => void
}

const TextArea = ({
  variant = 'default',
  allowInputCommaAndSpace,
  allowTags,
  className = '',
  customTagValidator,
  defaultTags,
  defaultValue = '',
  disabled,
  error,
  autoFocus = false,
  filterOption,
  height,
  onChangeTags,
  onChangeValue,
  onTagsValidation,
  onTextAreaFocus,
  onClickOutside,
  handleCustomKeyDown,
  handleClickBottomWrapper,
  placeholder,
  rightComponent,
  bottomComponent = null,
  rows = 1,
  searchMenuOptions,
  showSearch,
  size = 'l',
  tagRegexp,
  maxScrollHeight,
  minHeight,
  exposeClearInputMethod
}: TextAreaProps) => {
  const [openSearchMenu, setOpenSearchMenu] = useState(false)
  const [isComposing, setIsComposing] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [tags, setTags] = useState<TextAreaTagItemProps[]>([])
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const triggerRef = useRef<HTMLDivElement>(null)
  const menuRef = useRef<MenuRef>(null)
  const hasTags = tags.length > 0

  const [isTriggerAreaFocused, setIsTriggerAreaFocused] = useState(false)
  const handleFocus = () => {
    onTextAreaFocus?.()
    setIsTriggerAreaFocused(true)
  }

  const updateTags = useCallback(
    (newTags: TextAreaTagItemProps[]) => {
      const uniqTags = uniqBy(newTags, 'value')
      setTags(uniqTags)
      onChangeTags?.(uniqTags)
    },
    [onChangeTags]
  )

  const formatTagWithValidation = useCallback(
    (tag: TextAreaTagItemProps) => ({
      ...tag,
      isValid: (!customTagValidator || customTagValidator(tag)) && (!tagRegexp || tagRegexp.test(tag.value))
    }),
    [customTagValidator, tagRegexp]
  )

  const generateTags = useCallback(
    (values: string[]) => {
      // when generate tag, need reset textArea style
      if (textAreaRef.current) {
        textAreaRef.current.style.flexBasis = ''
        textAreaRef.current.style.overflow = ''
        textAreaRef.current.wrap = ''
      }
      let newTags: TextAreaTagItemProps[]

      if (allowInputCommaAndSpace) {
        newTags = [...tags, ...values.map((value) => formatTagWithValidation({ label: value, value }))]
      } else {
        newTags = [
          ...tags,
          ...values.flatMap((value) =>
            value
              .split(/[\s,]/)
              .filter((v) => v)
              .map((v) => formatTagWithValidation({ label: v, value: v }))
          )
        ]
      }

      updateTags(newTags)
      setInputValue('')
    },
    [allowInputCommaAndSpace, updateTags, tags, formatTagWithValidation]
  )

  useEffect(() => {
    setTags(defaultTags ?? [])
  }, [defaultTags])

  useEffect(() => {
    setInputValue(defaultValue)
  }, [defaultValue])

  useEffect(() => {
    const anyTagInvalid = tags.some((tag) => !tag.isValid)
    onTagsValidation?.(!anyTagInvalid)
  }, [onTagsValidation, tags])

  useEffect(() => {
    if (!textAreaRef.current) {
      return
    }
    if (allowTags || height) {
      textAreaRef.current.style.height = ''
      return
    }

    textAreaRef.current.style.height = 'inherit'
    if (maxScrollHeight && maxScrollHeight < textAreaRef.current.scrollHeight) {
      textAreaRef.current.style.height = `${maxScrollHeight}px`
    } else if (minHeight && textAreaRef.current.scrollHeight < minHeight) {
      textAreaRef.current.style.height = `${minHeight}px`
    } else {
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`
    }
  }, [inputValue, height, allowTags, maxScrollHeight, minHeight])

  useEffect(() => {
    if (height && contentRef.current && contentRef.current.scrollTop !== contentRef.current.scrollHeight) {
      contentRef.current.scrollTop = contentRef.current.scrollHeight
    }
  }, [inputValue, height])

  useEffect(() => {
    // the maximum height of a tag is 24, if >24, it should be wrapped
    if (allowTags && textAreaRef.current && textAreaRef.current.scrollHeight > 24) {
      if (!textAreaRef.current.style.flexBasis) {
        textAreaRef.current.style.flexBasis = '100%'
      } else {
        textAreaRef.current.wrap = 'off'
        textAreaRef.current.style.overflow = 'hidden'
      }
    }
  }, [inputValue, allowTags])

  useEffect(() => {
    if (!onClickOutside) return
    const handleOutsideClick = (event: MouseEvent) => {
      if (triggerRef.current && !triggerRef.current.contains(event.target as Node)) {
        onClickOutside()
      }
    }
    document.addEventListener('click', handleOutsideClick)
    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [onClickOutside])

  const handleClose = useCallback(
    (index: number) => {
      const newTags = tags.filter((_, tagIndex) => tagIndex !== index)
      updateTags(newTags)
    },
    [tags, updateTags]
  )

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (handleCustomKeyDown) handleCustomKeyDown(e, inputValue, textAreaRef, isComposing)
      const GENERATE_TAG_BY_KEYS = allowInputCommaAndSpace ? ['Enter'] : [' ', ',', 'Enter']
      const SELECT_ACCOUNT_BY_KEY = [' ', 'Enter']
      const TRIGGER_MENU_LIST_KEYDOWN_BY_KEY = ['Home', 'End', 'ArrowUp', 'ArrowDown']
      let activeIndex = menuRef.current?.getActiveIndex()
      if (!activeIndex && activeIndex !== 0) activeIndex = -1
      if (allowTags && GENERATE_TAG_BY_KEYS.includes(e.key) && inputValue) {
        if (SELECT_ACCOUNT_BY_KEY.includes(e.key) && activeIndex >= 0) {
          menuRef.current && menuRef.current.selectActiveOption()
        } else {
          generateTags([inputValue])
        }
        e.stopPropagation()
        e.preventDefault()
      } else if (TRIGGER_MENU_LIST_KEYDOWN_BY_KEY.includes(e.key) && inputValue && activeIndex >= 0) {
        const newEvent = new KeyboardEvent(e.type, e.nativeEvent)
        menuRef.current && menuRef.current.onKeyDown(newEvent)
        e.stopPropagation()
        e.preventDefault()
      }
      if (allowTags && !inputValue && e.key === 'Backspace') {
        // remove textarea flexBasis style
        if (textAreaRef.current?.style.flexBasis === '100%') {
          textAreaRef.current.style.flexBasis = ''
          return
        }
        const newTags = tags.slice(0, tags.length - 1)
        updateTags(newTags)
      }
      if (allowTags && !inputValue && e.key === 'Enter' && textAreaRef.current) {
        e.stopPropagation()
        e.preventDefault()
        textAreaRef.current.style.flexBasis = '100%'
      }
    },
    [
      allowInputCommaAndSpace,
      allowTags,
      inputValue,
      generateTags,
      tags,
      updateTags,
      menuRef,
      handleCustomKeyDown,
      isComposing
    ]
  )

  const handlePaste = useCallback(
    (e: React.ClipboardEvent<HTMLTextAreaElement>) => {
      const pastedData = e.clipboardData.getData('text/plain')
      if (allowTags && pastedData.includes(',')) {
        const pastedValues = pastedData.split(',')
        generateTags(pastedValues)
        e.preventDefault()
      }
    },
    [allowTags, generateTags]
  )

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setInputValue(e.target.value)
      onChangeValue?.(e.target.value)
    },
    [onChangeValue]
  )

  const handleBlur = useCallback(() => {
    setIsTriggerAreaFocused(false)

    if (allowTags && inputValue && !openSearchMenu) {
      generateTags([inputValue])
    }
  }, [allowTags, inputValue, generateTags, openSearchMenu])

  const handleSelect = useCallback(
    (selectedOption: MenuOptionProps) => {
      setInputValue('')
      updateTags([
        ...tags,
        formatTagWithValidation({
          ...selectedOption,
          label: String(selectedOption.name),
          value: String(selectedOption.value)
        })
      ])
    },
    [updateTags, tags, formatTagWithValidation]
  )

  const handleClickBottomComponent = (): void => {
    if (handleClickBottomWrapper) handleClickBottomWrapper(textAreaRef)
  }

  const handleCompositionStart = () => {
    setIsComposing(true)
  }

  const handleCompositionEnd = () => {
    setIsComposing(false)
  }

  const focusTextArea = () => textAreaRef.current?.focus()

  useEffect(() => {
    const clearInput = () => {
      setInputValue('')
      onChangeValue?.('')
    }
    if (exposeClearInputMethod) {
      exposeClearInputMethod(clearInput)
    }
  }, [exposeClearInputMethod, onChangeValue])

  const isLargeSize = size === 'l'

  const computedClassName = useMemo(() => {
    if (variant === 'flat') return ''
    if (disabled) return 'bg-light-overlay-5 opacity-40 cursor-not-allowed'
    if (error && isTriggerAreaFocused)
      return 'bg-light-overlay-5 highlight-border-rounded-md-error highlight-border-rounded-md-error-focus'
    if (error) return 'bg-light-overlay-5 highlight-border-rounded-md-error'
    if (isTriggerAreaFocused)
      return 'bg-light-overlay-5 highlight-border-rounded-md hover:bg-light-overlay-10 hover:outline-light-overlay-20-1-offset--1'
    return 'bg-light-overlay-5 hover:bg-light-overlay-10 hover:outline-light-overlay-20-1-offset--1'
  }, [disabled, error, variant, isTriggerAreaFocused])

  return (
    <>
      <ErrorTextComponent showErrorTips={!!error} errorTips={error}>
        <div ref={triggerRef} className={`flex rounded-md ${computedClassName} ${className}`}>
          <div
            ref={contentRef}
            className={`flex-1 flex flex-wrap overflow-auto ${
              isLargeSize ? `${hasTags ? 'pt-4 pl-4 pr-8' : 'p-8'}` : `${hasTags ? 'pt-6 pl-6 pr-8' : 'px-8 py-6'}`
            }`}
            style={{
              height: height || 'auto'
            }}
            onClick={focusTextArea}
          >
            {tags.map((tag, index) => (
              <Tag
                hasError={!tag.isValid}
                key={index}
                closable
                size={size}
                className="mr-4 mb-4"
                onClose={() => handleClose(index)}
              >
                {tag.label}
              </Tag>
            ))}
            <textarea
              autoFocus={autoFocus}
              placeholder={hasTags ? undefined : placeholder}
              disabled={disabled}
              ref={textAreaRef}
              rows={rows}
              className={`${
                hasTags ? `${isLargeSize ? 'py-4 mb-4' : 'pb-6'}` : ''
              } min-w-[1px] resize-none bg-transparent flex-1 placeholder-light-overlay-60 text-12 text-white disabled:cursor-not-allowed`}
              value={inputValue}
              onBlur={handleBlur}
              onKeyDown={handleKeyDown}
              onChange={handleChange}
              onPaste={handlePaste}
              onFocus={handleFocus}
              onCompositionStart={handleCompositionStart}
              onCompositionEnd={handleCompositionEnd}
            />
          </div>
          {rightComponent && <div className="mx-8">{rightComponent}</div>}
          {bottomComponent && <div onClick={handleClickBottomComponent}>{bottomComponent}</div>}
        </div>
      </ErrorTextComponent>
      {showSearch && searchMenuOptions && (
        <Menu
          type="select"
          filterOption={filterOption}
          ref={menuRef}
          hideNoSearchResult
          onOpenChange={setOpenSearchMenu}
          onSelect={handleSelect}
          open={openSearchMenu}
          options={searchMenuOptions}
          searchValue={inputValue}
          selectable={false}
          showSearch={showSearch}
          trigger={triggerRef}
        />
      )}
    </>
  )
}

export default TextArea
