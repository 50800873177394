import React from 'react'

import Icon from '../Icon'

export type NavigationButtonProps = {
  icon?: string
  disabled?: boolean
  className?: string
  children?: React.ReactNode
  dataTestId?: string
  onClick?: (e: React.MouseEvent) => void
}

const NavigationButton = ({
  icon,
  disabled,
  className = '',
  children,
  dataTestId = '',
  onClick
}: NavigationButtonProps) => {
  const handleClick = (e: React.MouseEvent) => {
    if (disabled) return
    e.stopPropagation()
    onClick?.(e)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    if (e.key === 'Escape') {
      e.currentTarget.blur()
    }
  }

  return (
    <div className="px-8 py-2">
      <button
        type="button"
        className={`
          flex items-center w-full pl-18 pr-16 py-4
          text-13 font-medium text-light-overlay-60 rounded-md
          hover:text-white hover:bg-neutral-80
          active:text-light-overlay-40 active:bg-neutral-80
          focus-visible:outline-primary-1-offset--1
          disabled:cursor-not-allowed disabled:opacity-40 disabled:text-light-overlay-60
          ${className}
        `}
        disabled={disabled}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        data-test-id={dataTestId}
      >
        <div className="flex items-center gap-16">
          {icon && <Icon name={icon} size="xxl" interactive={false} useCurrentColor />}
          <div className="truncate">{children}</div>
        </div>
      </button>
    </div>
  )
}

export default NavigationButton
