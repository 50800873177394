import React, { useCallback, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

const Form = ({ children, errors, onSubmit, ...rest }) => {
  const ref = useRef()
  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault()
      const valid = e.target.checkValidity()
      if (!valid) {
        e.target.querySelector(':invalid').focus()
        return
      }
      const formData = new FormData(e.target)
      const data = Array.from(formData).reduce((acc, [key, value]) => {
        acc[key] = value
        return acc
      }, {})
      const els = Array.from(e.target.elements)
      const cachedDisable = els.map((el) => el.disabled)
      els.forEach((el) => {
        el.disabled = true
      })
      new Promise((resolve) => resolve(onSubmit(data))).finally(() => {
        els.forEach((el, i) => {
          el.disabled = cachedDisable[i]
        })
      })
    },
    [onSubmit]
  )

  useEffect(() => {
    Object.entries(errors).forEach(([key, message], index) => {
      const el = ref.current.elements[key]
      if (!el) return
      el.setCustomValidity(message)
      el.checkValidity()
      if (!index) {
        el.focus()
      }
    })
  }, [errors])

  return (
    <form ref={ref} {...rest} onSubmit={handleSubmit}>
      {children}
    </form>
  )
}

Form.propTypes = {
  errors: PropTypes.object,
  children: PropTypes.node,
  onSubmit: PropTypes.func.isRequired
}

Form.defaultProps = {
  errors: {}
}

export default Form
