import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useModal, useSetModal } from '../../../../providers/ModalProvider'
import { useEditorActions } from '../../../../providers/dataStore/EditorProvider'
import { Grid, IconButton, Modal } from '../../../shared'
import Label from '../../../shared/Label'
// import StrokeJoinSize from './StrokeJoinSize'
import StrokeDash from './StrokeDash'
import StrokeEnds from './StrokeEnds'
import StrokeGap from './StrokeGap'
// import StrokeCapShape from './StrokeCapShape'
// import StrokeCapSize from './StrokeCapSize'
import StrokeJoinShape from './StrokeJoinShape'
// import StrokeOffset from './StrokeOffset';
import StrokeMiter from './StrokeMiter'

type HeaderProps = {
  title: string
  layerItemId: string
}

const Header = ({ title, layerItemId }: HeaderProps) => {
  const { closeModal } = useSetModal(`StrokeAdvancedModal-${layerItemId}`)
  const handleClick = useCallback(() => {
    closeModal()
  }, [closeModal])

  return (
    // @ts-ignore TODO: fix after refactor of Grid
    <Grid p={4} gridGap={2} gridTemplateColumns="minmax(0, 1fr) 16px" className="text-white">
      <span className="font-medium">{title}</span>
      <div className="cursor-pointer" data-test-id="border-advanced-menu-close">
        <IconButton icon="Cross" onClick={handleClick} aria-label="Close" />
      </div>
    </Grid>
  )
}

type StrokeWrapProps = {
  label: string
  right: React.ReactNode
  rightWidth: number | undefined
}

const StrokeWrap = ({ label, right, rightWidth = 96 }: StrokeWrapProps) => {
  return (
    <Grid
      // @ts-ignore TODO: fix after refactor of Grid
      px={4}
      gridGap={1}
      gridTemplateColumns={`${208 - rightWidth}px ${rightWidth}px`}
      className="pt-0 mb-8 last:mb-0"
    >
      <div className="grid grid-flow-col" style={{ gridTemplateColumns: 'auto 1fr' }}>
        <Label text={label} />
      </div>
      {right}
    </Grid>
  )
}

type ModalComponentProps = {
  key: string
  label: string
  component: React.FC<any>
  rightWidth?: number
}

const modalContentList: ModalComponentProps[] = [
  // {
  // label: 'Cap shape',
  // component: StrokeCapShape
  // },
  // {
  // label: 'Cap size',
  // component: StrokeCapSize
  // },
  {
    key: 'join',
    label: 'join_shape',
    component: StrokeJoinShape
  },
  // {
  // label: 'Join size',
  // component: StrokeJoinSize
  // },
  {
    key: 'dash',
    label: 'dash',
    component: StrokeDash
  },
  {
    key: 'gap',
    label: 'gap',
    component: StrokeGap
  },
  // {
  //   label: 'Offset',
  //   component: StrokeOffset
  // },
  {
    key: 'miter',
    label: 'miter_angle',
    component: StrokeMiter
  },
  {
    key: 'ends',
    label: 'ends',
    component: StrokeEnds,
    rightWidth: 116
  }
]
type StrokeAdvancedModalProps = {
  title: string
  layerItemId: string
}

const StrokeAdvancedModal = ({ title, layerItemId }: StrokeAdvancedModalProps) => {
  const MODAL_KEY = `StrokeAdvancedModal-${layerItemId}`
  const { closeModal } = useSetModal(MODAL_KEY)
  const { setLayerFocused } = useEditorActions()
  const { t } = useTranslation('file', { keyPrefix: 'property_editor.stroke_editor' })

  const {
    open,
    data: { trigger }
  } = useModal((o: any) => o[MODAL_KEY])

  const closeStrokeAdvancedModal = useCallback(() => {
    closeModal()
    setLayerFocused(layerItemId, false)
  }, [closeModal, setLayerFocused, layerItemId])
  return (
    <Modal
      open={open}
      trigger={trigger}
      onClose={closeStrokeAdvancedModal}
      draggable
      placement="horizontal"
      offsetX={32}
    >
      {open && (
        <div className="pb-16">
          <Header title={title} layerItemId={layerItemId} />
          {modalContentList.map((v: ModalComponentProps) => (
            <StrokeWrap
              key={v.key}
              label={t(v.label)}
              // @ts-ignore TODO: fix after refactor of StrokeWrap
              right={<v.component layerItemId={layerItemId} />}
              rightWidth={v.rightWidth}
            />
          ))}
        </div>
      )}
    </Modal>
  )
}

export default React.memo(StrokeAdvancedModal)
