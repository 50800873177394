import { useEffect } from 'react'

import { useSetAtom } from 'jotai'

import { keyframeOverlappedAtom, selectedKeyframeTimeRangeAtom } from '../../../atoms'
import { useDataStore } from '../../../providers/dataStore/DataStoreProvider'
import { useInteractionActions } from '../../../providers/dataStore/InteractionProvider'

export const SubscribeSelectedKeyframeRange = () => {
  const dataStore = useDataStore()
  const { getSelectedKeyframesTimeRange, getIsSelectedKeyframesOverlapped } = useInteractionActions()
  const setSelectedKeyframeTimeRange = useSetAtom(selectedKeyframeTimeRangeAtom)
  const setKeyframeOverlappedAtom = useSetAtom(keyframeOverlappedAtom)
  useEffect(() => {
    const updateTimeRangeAndOverlapStatus = () => {
      const isOverlapped = getIsSelectedKeyframesOverlapped()
      setKeyframeOverlappedAtom(isOverlapped)

      const { min, max } = getSelectedKeyframesTimeRange()
      if (min !== max) {
        setSelectedKeyframeTimeRange({ start: min, end: max })
      } else {
        setSelectedKeyframeTimeRange({ start: null, end: null })
      }
    }
    const handleKeyframeSelectionChange = (changes: any) => {
      const kfc = changes.get('kfs')
      if (kfc) {
        updateTimeRangeAndOverlapStatus()
      }
    }

    dataStore.selection.on('SELECT', handleKeyframeSelectionChange)
    dataStore.interaction.on('INTERACTION_CHANGES', updateTimeRangeAndOverlapStatus)
    return () => {
      dataStore.selection.off('SELECT', handleKeyframeSelectionChange)
      dataStore.interaction.off('INTERACTION_CHANGES', updateTimeRangeAndOverlapStatus)
    }
  }, [
    dataStore,
    setSelectedKeyframeTimeRange,
    getSelectedKeyframesTimeRange,
    setKeyframeOverlappedAtom,
    getIsSelectedKeyframesOverlapped
  ])
  return null
}
