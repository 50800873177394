import { useEffect, memo } from 'react'
import { useDataStore } from '../../providers/dataStore/DataStoreProvider'
import { useSetEditor } from '../../providers/dataStore/EditorProvider'

const SubscribeEditorChange = () => {
  const editor = useDataStore().editor
  const { setEditor } = useSetEditor()
  useEffect(() => {
    const cb = (changes) => {
      if (changes.size !== 0) {
      console.log('EDITOR_CHANGES', changes)
      }
      // controller, 當作 interface 去更新 editor
      setEditor(changes)
    }

    editor.on('EDITOR_CHANGES', cb)
    return () => {
      editor.off('EDITOR_CHANGES', cb)
    }
  }, [editor, setEditor])
  return null
}

export default memo(SubscribeEditorChange)
