import { useCallback } from 'react'
import { createProvider } from '../utils'

const [Provider, useSelectState, useSetState] = createProvider('ElementSelection', [])

export const useElementSelection = useSelectState

export const useSetElementSelection = () => {
  const setState = useSetState()
  const setElementSelection = useCallback((selection) => setState(selection), [setState])
  return { setElementSelection }
}

export default Provider
