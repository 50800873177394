import { EditMode, Mode, PointShape, ToolType } from '@phase-software/types'
import { Events , Hover } from '@phase-software/data-store'
import { Rect2, Transform2D } from '../math'
import { Vector2 } from '../math/Vector2'
import { loadImage } from '../overlay/Overlay'
import { BezierPath, BezierShape } from '../geometry/bezier-shape/BezierShape'
import { Segment } from '../geometry/bezier-shape/Segment'
import { buildDashedPath } from '../geometry/bezier-shape/stroke2'
import { findControlPoints } from '../../../data-utils/src/utils'
import Curve_Handle from './svg/Curve_Handle.svg'
import Hover_Curve_Handle from './svg/Hover_Curve_Handle.svg'
import Hover_Selected_Curve_Handle from './svg/Hover_Selected_Curve_Handle.svg'
import Selected_Curve_Handle from './svg/Selected_Curve_Handle.svg'
import Point_Handle from './svg/Point_Handle.svg'
import Hover_Point_Handle from './svg/Hover_Point_Handle.svg'
import Hover_Selected_Point_Handle from './svg/Hover_Selected_Point_Handle.svg'
import Selected_Point_Handle from './svg/Selected_Point_Handle.svg'
import Motion_Point from './svg/Motion_Point.svg'
import Motion_Point_Selected from './svg/Motion_Point_Selected.svg'
import Hover_Motion_Point from './svg/Hover_Motion_Point.svg'
import Hover_Motion_Point_Selected from './svg/Hover_Motion_Point_Selected.svg'
import Initial_Motion_Path_Point from './svg/Initial_Motion_Path_Point.svg'

//#region JSDOC type definitions
/** @typedef {import('@phase-software/data-utils').Mesh} Mesh */
/** @typedef {import('@phase-software/data-utils').Vertex} Vertex */
/** @typedef {import('@phase-software/data-utils').Edge} Edge */
/** @typedef {import('@phase-software/data-store/src/Element').Element} Element */
/** @typedef {import('../visual_server/VisualServer').VisualServer} VisualServer */
/** @typedef {import('../overlay/Overlay').Pane} Pane */
/** @typedef {import('@phase-software/data-utils/src/mesh/Mesh').Mesh} Mesh */
/** @typedef {import('../visual_server/RenderItem').RenderItem} RenderItem */
/** @typedef {import('@phase-software/data-store/src/_dataStore')._dataStore} _dataStore */
//#endregion

//#region global variables
/** @type {VisualServer} the injected visual server */
let _visualServer = null
/** @type {_dataStore} the injected visual server */
let _dataStore = null
/** @type {Pane} the pane draws UI of mesh */
let _generalPane = null
/** @type {Pane} */
let _pathPane = null
/** @type {Pane} */
let _highlightPane = null
/** @type {Transform2D} the transform of every frame when viewport updating */
const xform = new Transform2D()
let setLocalCursorState = null
let _element = null
let _node = null

let hoverData = null
let selectedData = null
let isPressingShift = false
let unSelectedFirst = false
const _dragDistance = new Vector2()
const _dragThreshold = 1.e-5
let _initMousePos = null
const initData = new Map()
const FAKE_INIT_KF_ID = 'FAKE_INIT_KF_ID'


const motionPathKFs = new Map()
const motionPathPoints = new Map()
const motionPathCurveControls = new Map()
/** @type {Map<string, Map<string, Vector2>>} */

const HIT_TOLERANCE = {
    VERT_HALF_BOX_WIDTH: 8,
    ORIGIN_HALF_BOX_WIDTH: 12,
    ROTATE_HANDLES_HALF_BOX_WIDTH: 12,
    RESIZE_HANDLES_HALF_BOX_WIDTH: 6
}

//#endregion

const constants = {
    LINE_WIDTH: 1.5,
    LINE_COLOR: 0x8B8B8B,
    SELECTED_LINE_COLOR: 0x1C6EE8,
    SVG_COLOR: 0xFFFFFF,
    GHOST_COLOR: 0x1C6EE8,
    CURVE_OFFSET: 4,
    HIGHLIGHTED_COLOR: 0xFF3232,
    ASSIST_LINE_CROSS_SIZE: 3
}

/**
 *
 * @param {VisualServer} visualServer
 * @param {number} pathIndex
 * @param {number} vertexIndex
 * @param {number} highLightIndex
 */
export function init(visualServer, pathIndex, vertexIndex, highLightIndex) {
    _visualServer = visualServer
    _dataStore = _visualServer.dataStore
    _pathPane = _visualServer.overlay.createPane(pathIndex)
    _generalPane = _visualServer.overlay.createPane(vertexIndex)
    _highlightPane = _visualServer.overlay.createPane(highLightIndex)

    _dataStore.selection.on('SELECT', (changes) => {
        const motionPointsChange = changes.get('motionPoints')
        if (motionPointsChange) {
            selectedData = motionPointsChange.after
        }
    })

    _dataStore.eam.on(Events.HOVER_MOTION_POINT, ({ mousePos }) => {
        const selectedElements = _dataStore.selection.get('elements')
        if (_dataStore.get('mode') !== Mode.ACTION || selectedElements.length !== 1 || selectedElements[0].get('locked') || _visualServer.dataStore.getFeature('editOrigin')) {
            hoverData = null
            return
        }

        const worldMousePos = _visualServer.viewport.toWorld(mousePos)
        hoverData = _findVertexAt(worldMousePos)
        if (hoverData) {
            _dataStore.eam.changeHover(Hover.MOTION_POINT)
            setLocalCursorState('default')
        }
        _dataStore.selection.set('hoverMotionPoint', hoverData)
    })

    // //#endregion
    _dataStore.eam.on(Events.SELECT_MOTION_POINT, (e, { shift }) => {
        /** @type {Selection} */
        const selection = _dataStore.selection
        const selectedElements = selection.get('elements')
        if (_dataStore.get('mode') !== Mode.ACTION || selectedElements.length !== 1  || _node.item.locked || _visualServer.dataStore.getFeature('editOrigin')) {
            hoverData = null
            return
        }
        const selectedVertex = selection.get('motionPoints')
        if (hoverData) {
            isPressingShift = shift
            // record whether the hover cell has been selected before
            unSelectedFirst = !_isSelectedCell(selectedVertex, hoverData)
            if (unSelectedFirst) {
                if (isPressingShift) {
                    // selectedData = _dataStore.selection.get('motionPoints')
                    // _updateCurveControlVisibility(selectedData)
                    // _dataStore.selection.toggleMotionPoints([hoverData])
                    _dataStore.selection.addMotionPoints([hoverData])
                } else {
                    _dataStore.selection.selectMotionPoints([hoverData])
                }
                selectedData = selection.get('motionPoints')
                _updateCurveControlVisibility(selectedData)
            }
        }
    })

    _dataStore.eam.on(Events.SELECT_ALL_MOTION_POINTS, () => {
        const currentElement = _dataStore.selection.get('elements')[0]
        const motionPathTrack = _dataStore.interaction.getPropertyTrackByElementIdAndPropKey(currentElement.get('id'), 'motionPath')
        const motionPoints = motionPathTrack.keyFrameList.map(keyFrameId => ({type: 'point', key: keyFrameId}))
        _dataStore.selection.selectMotionPoints(motionPoints)
    })

    _dataStore.eam.on(Events.DESELECT_MOTION_POINT, () => {
        _dataStore.selection.selectMotionPoints([])
        selectedData = null
    })

    _dataStore.eam
        .on(Events.START_DRAG_MOTION_POINT, (e, { shift }) => {
            // Get hover vertex
            if (!hoverData) {
                e.handled = false
                return
            }
            // Special case: Dragging will not occur if the vertex is about to be removed
            if (!motionPathKFs.has(hoverData.key)) {
                e.handled = false
                return
            }
            // Process clicking on a vertex
            isPressingShift = shift
            selectedData = _dataStore.selection.get('motionPoints')
            unSelectedFirst = !_isSelectedCell(selectedData, hoverData)
            if (unSelectedFirst) {
                if (isPressingShift) {
                    // the in and out point are the same type so it needs to check if the type is point or not
                    const isDiffPointType = selectedData.length > 0 &&
                        ((selectedData[0].type === 'point' && hoverData.type !== 'point') ||
                         (selectedData[0].type !== 'point' && hoverData.type === 'point'))
                    if (isDiffPointType) {
                        _dataStore.selection.selectMotionPoints([hoverData])
                    } else {
                        _dataStore.selection.addMotionPoints([hoverData])
                    }
                } else {
                    _dataStore.selection.selectMotionPoints([hoverData])
                }
                selectedData = _dataStore.selection.get('motionPoints')
                _updateCurveControlVisibility(selectedData)
            }
            for (const point of selectedData) {
                const type = point.type === 'point' ? 'pos' : point.type
                initData.set(point.key + point.type, new Vector2().fromArray(motionPathKFs.get(point.key)[type]))
            }
            // Record the mouse position
            _initMousePos = _visualServer.viewport.toWorld(e.mousePos)
            _dragDistance.set(0, 0)
        })
        .on(Events.UPDATE_DRAG_MOTION_POINT, ({ mousePos }, { shift }) => {
            isPressingShift = shift
            const worldMousePos = _visualServer.viewport.toWorld(mousePos)
            _dragDistance.copy(worldMousePos).sub(_initMousePos)
            const parentLocalPos = _node.parent.item.transform.worldInv.xform(worldMousePos)
            _dataStore.editor.setMotionPathProps(parentLocalPos, hoverData, {commit: false, delta: false})
        })
        .on(Events.END_DRAG_MOTION_POINT, () => {
            // Regard as clicking
            if (_dragDistance.length() < _dragThreshold) {
                const hover = hoverData
                const selected = _dataStore.selection.get('motionPoints')
                if (isPressingShift && _isSelectedCell(selected, hover)) {
                    // if the hover vertex was not selected,
                    // pressing shift and mouseup will toggle the vertex
                    if (unSelectedFirst) {
                        _dataStore.selection.addMotionPoints([hover])
                    } else {
                        _dataStore.selection.toggleMotionPoints([hover], _isSelectedCell)
                    }
                } else {
                    _dataStore.selection.selectMotionPoints([hover])
                }
                selectedData = _dataStore.selection.get('motionPoints')
                _updateCurveControlVisibility(selectedData)
            }
            initData.clear()
            _dataStore.commitUndo()
        })
    _preloadSVG()
}

/**
 * preload SVG files
 */
function _preloadSVG() {
    loadImage(Curve_Handle)
    loadImage(Hover_Curve_Handle)
    loadImage(Hover_Selected_Curve_Handle)
    loadImage(Selected_Curve_Handle)
    loadImage(Point_Handle)
    loadImage(Hover_Point_Handle)
    loadImage(Hover_Selected_Point_Handle)
    loadImage(Selected_Point_Handle)
    loadImage(Motion_Point)
    loadImage(Motion_Point_Selected)
    loadImage(Hover_Motion_Point)
    loadImage(Hover_Motion_Point_Selected)
    loadImage(Initial_Motion_Path_Point)
}

/**
 * @param {string} elementId
 */
function setMotionPathKFs(elementId) {
    const IM = _dataStore.interaction
    const motionPathKFList = IM.getPropertyTrackByElementIdAndPropKey(elementId, 'motionPath')?.keyFrameList
        .map(id => IM.getKeyFrame(id))
        .filter(Boolean)
        .sort((kf1, kf2) => kf2.time - kf1.time)

    if (!motionPathKFList || motionPathKFList.length === 0) {
        return
    }

    let prev = null
    motionPathKFs.clear()

    if (motionPathKFList[motionPathKFList.length - 1].time !== 0) {
        motionPathKFs.set(FAKE_INIT_KF_ID, {
            id: FAKE_INIT_KF_ID,
            time: 0,
            pos: [0, 0],
            in: [0, 0],
            out: [0, 0],
            mirror: 0,
            prev: null,
            next: motionPathKFList[0].id
        })
        prev = FAKE_INIT_KF_ID
    }

    for (let i = motionPathKFList.length - 1; i >= 0; i--) {
        const kf = motionPathKFList[i]
        if (kf.frameType === 1) {
            motionPathKFs.set(kf.id, {
                id: kf.id,
                time: kf.time,
                pos: [0, 0],
                in: [0, 0],
                out: [0, 0],
                mirror: 0,
                prev,
                next: i > 0 ? motionPathKFList[i - 1].id : null
            })
        } else {
            motionPathKFs.set(kf.id, {
                id: kf.id,
                time: kf.time,
                pos: kf.value.pos,
                in: kf.value.in,
                out: kf.value.out,
                mirror: kf.value.mirror,
                prev,
                next: i > 0 ? motionPathKFList[i - 1].id : null
            })
        }
        prev = kf.id
    }

    if (selectedData) {
        _updateCurveControlVisibility(selectedData)
    }
}

function _updateDrawHelper() {
    if (_dataStore.get('editMode') === EditMode.SHAPE ||
        _dataStore.get('editMode') === EditMode.GRADIENT_HANDLES ||
        _dataStore.get('activeTool') === ToolType.COMMENT ||
        _dataStore.get('mode') !== Mode.ACTION
    ) return

    const selectedElements = _dataStore.selection.get('elements')
    if (selectedElements.length !== 1) return

    _element = selectedElements[0]
    if (!_element) return

    selectedData = _dataStore.selection.get('motionPoints')
    setMotionPathKFs(_element.get('id'))
    if (motionPathKFs.size === 0) return
    _node = _visualServer.indexer.getNode(_element.get('id'))
    const translate = _node.item.baseTransform.translate
    const T = xform
        .copy(_node.parent.item.transform.world)
        .prepend(_visualServer.viewport.projectionTransform)

    const point1 = new Vector2()
    const point2 = new Vector2()
    const origin = T.xform(new Vector2())

    for (const [key, value] of motionPathKFs) {
        point1.set(value.pos[0], value.pos[1]).add(translate)
        motionPathPoints.set(key, T.xform(point1))

        point1.set(value.in[0], value.in[1])
        point2.set(value.out[0], value.out[1])
        motionPathCurveControls.set(key, {
            in: T.xform(point1).sub(origin),
            out: T.xform(point2).sub(origin)
        })
    }

    _drawPath()
}

function _drawPath() {
    const pathShape = new BezierShape()
    const segments = []

    // eslint-disable-next-line no-unused-vars
    for (const [key, value] of motionPathPoints) {
        const point = value
        const inPoint = motionPathCurveControls.get(key).in
        const outPoint = motionPathCurveControls.get(key).out
        segments.push(
            new Segment().initWithPoints(point, inPoint, outPoint)
        )
    }

    pathShape.addChild(BezierPath.createPath(segments, false))

    // draw path
    const dashShape = buildDashedPath(pathShape, [4, 2.5], false)
    const dashShapeData = dashShape.toPathData()
    _pathPane.lineStyle(constants.LINE_WIDTH, constants.LINE_COLOR).drawPath(0, 0, dashShapeData)

    if (_visualServer.dataStore.getFeature('editOrigin') || _node.item.locked) return
    // draw curve control
    for (const [key, value] of motionPathPoints) {
        if (key === FAKE_INIT_KF_ID) continue
        const point = value
        const inPoint = motionPathCurveControls.get(key).in
        const outPoint = motionPathCurveControls.get(key).out
        let motionInPointSVG = hoverData && hoverData.type === 'in' && hoverData.key === key ? Hover_Curve_Handle : Curve_Handle
        let motionOutPointSVG = hoverData && hoverData.type === 'out' && hoverData.key === key ? Hover_Curve_Handle : Curve_Handle
        if (motionPathKFs.get(key).selected) {
            for (const data of selectedData) {
                if (data.key === key && data.type === 'in') {
                    if (hoverData && hoverData.key === key && hoverData.type === 'in') {
                        motionInPointSVG = Hover_Selected_Curve_Handle
                    } else {
                        motionInPointSVG = Selected_Curve_Handle
                    }
                } else if (data.key === key && data.type === 'out') {
                    if (hoverData && hoverData.key === key && hoverData.type === 'out') {
                        motionOutPointSVG = Hover_Selected_Curve_Handle
                    } else {
                        motionOutPointSVG = Selected_Curve_Handle
                    }
                }
            }

            _pathPane.lineStyle(constants.LINE_WIDTH, constants.LINE_COLOR)
                .drawLine(point.x, point.y, point.x + inPoint.x, point.y + inPoint.y)
                .drawLine(point.x, point.y, point.x + outPoint.x, point.y + outPoint.y)
            _pathPane.fillStyle(constants.SVG_COLOR)
                .drawImageFromAtlas(motionInPointSVG, point.x + inPoint.x, point.y + inPoint.y, 1, 1)
                .drawImageFromAtlas(motionOutPointSVG, point.x + outPoint.x, point.y + outPoint.y, 1, 1)
        }
    }


    // draw motion point
    // eslint-disable-next-line no-unused-vars
    for (const [key, value] of motionPathPoints) {
        let motionPointSVG = hoverData && hoverData.type === 'point' && hoverData.key === key ? Hover_Motion_Point : Motion_Point
        if (selectedData) {
            for (const data of selectedData) {
                if (data.key === key && data.type === 'point') {
                    if (hoverData && hoverData.key === key && hoverData.type === 'point') {
                        motionPointSVG = Hover_Motion_Point_Selected
                    } else {
                        motionPointSVG = Motion_Point_Selected
                    }
                    break
                }
            }
        }
        if (key === FAKE_INIT_KF_ID) motionPointSVG = Initial_Motion_Path_Point

        _pathPane.fillStyle(0xffffff)
            .drawImageFromAtlas(motionPointSVG, value.x, value.y, 1, 1)
    }

}

export function update() {
    _generalPane.clear()
    _pathPane.clear()
    _highlightPane.clear()
    motionPathPoints.clear()
    motionPathCurveControls.clear()
    motionPathKFs.clear()

    _updateDrawHelper()
}



const mouse_rect = new Rect2(0, 0, 0, 0)
/**
 * @param {Vector2} mouse_point
 * @returns
 */
function _findVertexAt(mouse_point) {
    const hitBuffer = HIT_TOLERANCE.VERT_HALF_BOX_WIDTH
    const worldMousePos = _visualServer.viewport.toScreen(mouse_point)
    mouse_rect.set(worldMousePos.x - hitBuffer, worldMousePos.y - hitBuffer, 2 * hitBuffer, 2 * hitBuffer)
    for (const [key, value] of motionPathPoints) {
        if (key === FAKE_INIT_KF_ID) continue
        if (mouse_rect.contains(value)) {
            return {type: 'point', key, data: new Vector2().fromArray(motionPathKFs.get(key).pos)}
        }
    }
    for (const [key, value] of motionPathCurveControls) {
        if (key === FAKE_INIT_KF_ID) continue
        const point = motionPathPoints.get(key).clone().add(value.in)
        if (mouse_rect.contains(point)) {
            return {type: 'in', key, data: new Vector2().fromArray(motionPathKFs.get(key).in)}
        }
        point.copy(motionPathPoints.get(key)).add(value.out)
        if (mouse_rect.contains(point)) {
            return {type: 'out', key, data: new Vector2().fromArray(motionPathKFs.get(key).out)}
        }
    }
    return null
}

/**
 * @param {[]} selectedList
 * @param {object} cell
 * @returns
 */
function _isSelectedCell(selectedList, cell) {
    for (const selectedCell of selectedList) {
        if (selectedCell.key === cell.key && selectedCell.type === cell.type) return true
    }
    return false
}

/**
 * @param {object} selectData
 */
function _updateCurveControlVisibility(selectData) {
    for (const data of selectData) {
        const kf = motionPathKFs.get(data.key)
        kf.selected = true
        switch (data.type) {
            case 'in': {
                if (motionPathKFs.has(kf.prev)) {
                    motionPathKFs.get(kf.prev).selected = true
                }
                break
            }
            case 'out': {
                if (motionPathKFs.has(kf.next)) {
                    motionPathKFs.get(kf.next).selected = true
                }
                break
            }
            case 'point': {
                if (motionPathKFs.has(kf.prev)) {
                    motionPathKFs.get(kf.prev).selected = true
                }
                if (motionPathKFs.has(kf.next)) {
                    motionPathKFs.get(kf.next).selected = true
                }
                break
            }
        }
    }
}

/**
 * @param {Vector2} motionPoint
 * @param {Vector2} preMotionPoint
 * @param {Vector2} nextMotionPoint
 * @param {Vector2} inP
 * @param {Vector2} outP
 * @param {PointShape} pointShapeType
 * @param {PointShape} oldPointShapeType
 */
function _processPointShapeRegular(motionPoint, preMotionPoint, nextMotionPoint, inP, outP, pointShapeType, oldPointShapeType) {
    if (oldPointShapeType !== PointShape.NONE && pointShapeType === PointShape.NONE) {
        inP.copy(motionPoint)
        outP.copy(motionPoint)
    } else if (oldPointShapeType === PointShape.NONE) {
        if (
            pointShapeType === PointShape.ANGLE ||
            pointShapeType === PointShape.INDEPENDENT ||
            pointShapeType === PointShape.ANGLE_AND_LENGTH
        ) {
            const start = preMotionPoint
            const end = nextMotionPoint
            let e1, e2
            if (start.equals(end) || start.equals(motionPoint) || motionPoint.equals(end)) {
                const point0 = start.equals(end) ||  motionPoint.equals(end) ? start : end
                const edgeDir = new Vector2()
                edgeDir.copy(motionPoint).sub(point0)
                if (edgeDir.equals(Vector2.ZERO)) {
                    // if the curve control's len is 0, give it a default value
                    edgeDir.set(-10, 0)
                } else {
                    const len = edgeDir.length()
                    edgeDir.scale(1 / len)
                    if (start.equals(end)) {
                        const tmp = edgeDir.x
                        edgeDir.x = edgeDir.y
                        edgeDir.y = -tmp
                    }
                    edgeDir.scale(len * 0.2)
                }
                e1 = new Vector2()
                e1.copy(motionPoint).add(edgeDir)
                e2 = new Vector2()
                edgeDir.scale(-1)
                e2.copy(motionPoint).add(edgeDir)
            } else {
                const result = findControlPoints(start, motionPoint, end)
                e1 = result.e1
                e2 = result.e2
                if (pointShapeType === PointShape.ANGLE_AND_LENGTH) _alignPoints(e1, motionPoint, e2)
            }
            inP.copy(e1)
            outP.copy(e2)
        }
    } else if (oldPointShapeType === PointShape.ANGLE && pointShapeType === PointShape.ANGLE_AND_LENGTH) {
        const curve0 = inP
        const curve1 = outP
        const newPosList = _alignExistingCurves(curve0, motionPoint, curve1, pointShapeType)
        inP.copy(newPosList[0])
        outP.copy(newPosList[1])
    } else if (oldPointShapeType === PointShape.INDEPENDENT && pointShapeType === PointShape.ANGLE_AND_LENGTH) {
        const curve0 = inP
        const curve1 = outP
        const newPosList = _alignExistingCurves(curve0, motionPoint, curve1, pointShapeType)
        inP.copy(newPosList[0])
        outP.copy(newPosList[1])
    } else if (oldPointShapeType === PointShape.INDEPENDENT && pointShapeType === PointShape.ANGLE) {
        let defaultLen = 0
        if (motionPoint.equals(inP)) {
            const start = preMotionPoint
            const end = nextMotionPoint

            const { e1 } = findControlPoints(start, motionPoint, end)
            defaultLen = Math.sqrt(Math.pow((e1.x - motionPoint.x), 2) + Math.pow((e1.y - motionPoint.y), 2))
            inP.copy(e1)
        } else if (motionPoint.equals(outP)) {
            const start = preMotionPoint
            const end = nextMotionPoint

            const { e2 } = findControlPoints(start, motionPoint, end)
            defaultLen = Math.sqrt(Math.pow((e2.x - motionPoint.x), 2) + Math.pow((e2.y - motionPoint.y), 2))
            outP.copy(e2)
        }
        const newPosList = _alignExistingCurves(inP, motionPoint, outP, pointShapeType, defaultLen)
        inP.copy(newPosList[0])
        outP.copy(newPosList[1])
    }
}

/**
 * Uitilty function
 * @param {Vector2} a
 * @param {Vector2} b
 * @param {Vector2} c
 */
function _alignPoints(a, b, c) {
    let dir0x = a.x - b.x
    let dir0y = a.y - b.y
    let dir1x = c.x - b.x
    let dir1y = c.y - b.y
    const len0 = Math.sqrt(dir0x * dir0x + dir0y * dir0y)
    const len1 = Math.sqrt(dir1x * dir1x + dir1y * dir1y)
    if (len0 < len1) {
        dir0x = (dir0x / len0) * len1
        dir0y = (dir0y / len0) * len1
        a.x = b.x + dir0x
        a.y = b.y + dir0y
    } else {
        dir1x = (dir1x / len1) * len0
        dir1y = (dir1y / len1) * len0
        c.x = b.x + dir1x
        c.y = b.y + dir1y
    }
}
/**
 * @param {Vector2} curve0
 * @param {Vector2} vertex
 * @param {Vector2} curve1
 * @param {PointShape} pointShapeType
 * @param {number} defaultLength
 * @returns
 */
function _alignExistingCurves(curve0, vertex, curve1, pointShapeType, defaultLength = 0) {
    const dir0 = new Vector2()
    dir0.copy(vertex).sub(curve0)
    const dir1 = new Vector2()
    dir1.copy(vertex).sub(curve1)
    let longerCurve, shorterCurve, goalLen
    const len0 = dir0.length()
    const len1 = dir1.length()
    if (len0 < len1) {
        longerCurve = curve1
        shorterCurve = curve0
        goalLen = pointShapeType === PointShape.ANGLE_AND_LENGTH ? len1 : len0
    } else {
        longerCurve = curve0
        shorterCurve = curve1
        goalLen = pointShapeType === PointShape.ANGLE_AND_LENGTH ? len0 : len1
    }
    if (goalLen < 1.E-5) {
        goalLen = defaultLength
    }
    dir0.copy(longerCurve).sub(vertex)
    dir0.x = -1 * (longerCurve.x - vertex.x)
    dir0.y = -1 * (longerCurve.y - vertex.y)
    dir0.normalize()
    dir0.scale(goalLen)
    const newPos = new Vector2()
    newPos.copy(vertex).add(dir0)
    return shorterCurve === curve0 ? [newPos, longerCurve] : [longerCurve, newPos]
}

/**
 * @param {PointShape} pointShapeType
 * @param {object} [option = {}]
 * @param {boolean} [option.commit = true]
 */
export function updateMotionPointMirror(pointShapeType, { commit = true } = {}) {
    const translate = _node.item.baseTransform.translate
    for (const motionPointData of selectedData) {
        const motionPoint = motionPathKFs.get(motionPointData.key)
        const point =  new Vector2().fromArray(motionPoint.pos).add(translate)
        const prev =  motionPoint.prev ? new Vector2().fromArray(motionPathKFs.get(motionPoint.prev).pos).add(translate) : point.clone()
        const next =  motionPoint.next ? new Vector2().fromArray(motionPathKFs.get(motionPoint.next).pos).add(translate) : point.clone()
        const inP = new Vector2().fromArray(motionPoint.in)
        const outP = new Vector2().fromArray(motionPoint.out)
        _processPointShapeRegular(point, prev, next, inP.add(point), outP.add(point), pointShapeType, motionPoint.mirror)

        _dataStore.editor.setMotionPathProps({mirror: pointShapeType, x: inP.x, y: inP.y}, {key: motionPointData.key, type: 'in'}, {commit: false, delta: false})
        _dataStore.editor.setMotionPathProps({mirror: pointShapeType, x: outP.x, y: outP.y}, {key: motionPointData.key, type: 'out'}, {commit: false, delta: false})
    }

    if (commit) {
        _dataStore.commitUndo()
    }
}

/**
 * @param _setLocalCursorState
 */
export function initMotionSetCursor(_setLocalCursorState) {
    setLocalCursorState = _setLocalCursorState
}
