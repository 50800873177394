export const MIN_ZOOM = 0.02
export const MAX_ZOOM = 256

export const GRADIENT_DEBOUNCE_TIME = 100

export const AXIS_DIAGONAL_EPSILON = 0.001

export const MIN_SIZE_THRESHOLD = 0.1
export const MIN_SIZE = MIN_SIZE_THRESHOLD * 0.9995

export const HIDING_VALUE_RATIO = 1e3

// pool sizes
export const MATH_POOL_SIZE = 8192
export const TILE_POOL_SIZE = 8192
export const PANE_POOL_SIZE = 8192
export const RENDER_OBJ_POOL_SIZE = 8192

export const SCREEN_STYLE = {
    color: 0x888888,
    padding: 5
}

/** @type {number} */
export const SCREEN_FONT_SIZE = 14

export const MASK_BUFF_STATE_ENUM = Object.freeze(
    {
        "NONE": 0,
        "WAIT_DRAW_FILL": 1,
        "BILT": 2,
        "BILT_AND_CLEAR": 3,
        "NO_CONTENT": 4,
    }
)
