import { throttle, cloneDeep } from 'lodash'
import {
    ElementType,
    ContainerElementType,
    BooleanOperation,
    GeometryType,
    LayerType,
    PaintType,
    FrameType,
    EditMode,
    EntityType,
    Unit,
    PointShape,
    EventFlag,
} from '@phase-software/types'
import {
    FAKE_IDS,
    MIX_VALUE,
    MIX_VALUE_ID,
    IS_MIX,
    isVec2,
    isVec4,
    NOT_UNDOABLE,
    Vector4,
    ColorStop,
    EPSILON,
    FlagsEnum,
    round,
    minmax,
    MIN_SIZE_VALUE,
    NO_COMMIT,
    MIN_SIZE_THRESHOLD
} from '@phase-software/data-utils'
import EventEmitter from 'eventemitter3'
import { LayerListKeyList, LayerTypeMapLayerListKey } from '../../dist'
import {
    KEYS_TO_PROP,
    SWITCH_COMPONENT_KEYS,
    EI_LAYER_ALLOWED_KEYS,
    EI_EFFECT_ALLOWED_KEYS_SET,
    EI_SCREEN_ALLOWED_KEYS,
    PAINT_CATEGORY_MAP,
    PAINT_CATEGORY_PROPS_MAP,
    LAYER_PROP_STATE_NAME_MAP,
    LAYER_PROP_NAME_STATE_MAP,
    EFFECT_TYPE_NAME_MAP,
    EFFECT_PROP_STATE_NAME_MAP,
    EFFECT_PROP_NAME_STATE_MAP,
    UNDOABLE_ELEMENT_SETTER_PROPS,
    DEFAULT_MOTION_PATH_VALUE,
    PROP_STATES,
    SWITCH_COMPONENT_PROPS,
    PROPORTION_COMPONENT_PROPS,
    IM_UPDATE_RATE_LIMITER
} from '../constant'
import { canElementApplyEffect, existKeyFrame, parseToDecimals } from '../utils'
import { Events } from '../Eam/constants'
import { getPivotOffset } from '../helpers'
import EditorChanges from './EditorChanges'
import { EffectItem } from './EffectItem'
import { LayerItem } from './LayerItem'
import { getMotionPointValue } from './utils'

/** @typedef {import('@phase-software/data-utils').PropChange} PropChange */
/** @typedef {import('@phase-software/renderer/src/math').Vector2} Vector2 */
/** @typedef {import('./Setter').ChangesEvent} ChangesEvent */
/** @typedef {import('./ComputedStyle').ComputedStyle} ComputedStyle */
/** @typedef { import('./DataStore').DataStore} DataStore */
/** @typedef { import('./Element').Element} Element */

/** @typedef { import('@phase-software/types').PointShape} PointShape */

/**
 * @typedef PointChange
 * @property {string} id
 * @property {number} x
 * @property {number} y
 * @property {PointShape} mirror
 */

/**
 * @typedef KFChange
 * @property {string} elementId
 * @property {string} propKey
 * @property {PointChange[]} value
 * @property {boolean} delta
 * @property {FrameType} frameType
 */

// TODO: Share with the PROP_KEYS_TO_CS_KEYS?
export const COMPONENT_PROPERTY_MAP = {
    position: ['x', 'y'],
    dimensions: ['width', 'height'],
    rotation: ['rotation'],
    cornerRadius: ['cornerRadius', 'cornerSmoothing'],
    opacity: ['opacity', 'blendMode'],
    scale: ['scaleX', 'scaleY'],
    skew: ['skewX', 'skewY'],
    contentAnchor: ['contentAnchorX', 'contentAnchorY', 'contentAnchorXUnit', 'contentAnchorYUnit', 'contentAnchorAutoAdd', 'contentAnchorType'],
    blurGaussian: ['blurGaussian', 'blurGaussianAmount', 'blurGaussianVisible'],
    overflow: ['overflowX', 'overflowY']
    // TODO: Scroll
    // TODO: Text
}
export const ELEMENT_PROPERTY_SET = new Set([
    'elementType',
    'geometryType',
    'aspectRatioLocked',
    'scaleAspectRatioLocked',
    'autoOrient',
    'visible'
])

export const CONTAINER_PROPERTY_SET = new Set([
    'containerType',
    'booleanType',
    'isMask'
])

export const CONTAINER_PROPERTY_DEFAULT_VALUE = {
    containerType: ContainerElementType.CONTAINER,
    booleanType: BooleanOperation.NONE,
    isMask: false
}

const EI_LAYER_ALLOWED_KEYS_SET = {
    [LayerType.FILL]: new Set(EI_LAYER_ALLOWED_KEYS[LayerType.FILL]),
    [LayerType.STROKE]: new Set(EI_LAYER_ALLOWED_KEYS[LayerType.STROKE]),
    [LayerType.SHADOW]: new Set(EI_LAYER_ALLOWED_KEYS[LayerType.SHADOW]),
    [LayerType.INNER_SHADOW]: new Set(EI_LAYER_ALLOWED_KEYS[LayerType.INNER_SHADOW])
}

export const {
    props: GENERAL_PROPERTIES,
    stateNameMap: PROP_STATE_NAME_MAP,
    nameStateMap: PROP_NAME_STATE_MAP
} = Object.keys(COMPONENT_PROPERTY_MAP).reduce((result, component) => {
    const componentProps = COMPONENT_PROPERTY_MAP[component]
    componentProps.forEach(cp => {
        const cpState = `${cp}State`
        result.props.push(cp, cpState)
        result.stateNameMap.set(cpState, cp)
        result.nameStateMap.set(cp, cpState)
    })
    return result
}, {
    props: [...ELEMENT_PROPERTY_SET],
    stateNameMap: new Map(),
    nameStateMap: new Map()
})



export const LAYER_LIST_PROPERTIES = [
    'fillList',
    'strokeList',
    'shadowList',
    'innerShadowList'
]

export const EFFECT_LIST_PROPERTIES = 'effectList'

export const CUSTOM_PROPERTIES = new Set(['pathMorphing'])
export const CUSTOM_PROPERTIES_KEY_STATES_MAP = new Map([['pathMorphing', 'pathMorphingState']])
export const CUSTOM_PROPERTIES_STATES_NAME_MAP = new Map([['pathMorphingState', 'pathMorphing']])

export const VERTEX_PROPERTIES = [
    'x',
    'y',
    'mirror',
    'cornerRadius'
]

export const TOOL_STATES = [
    'alignment',
    'distortion'
]

export const MOTION_PATH_PROPERTIES = [
    'x',
    'y',
    'mirror'
]

const LAYER_TYPE_LIST_MAP = {
    [LayerType.FILL]: 'fillList',
    [LayerType.STROKE]: 'strokeList',
    [LayerType.SHADOW]: 'shadowList',
    [LayerType.INNER_SHADOW]: 'innerShadowList'
}

const LIST_NAME_TO_LAYER_LIST_MAP = {
    fills: 'fillList',
    strokes: 'strokeList',
    shadows: 'shadowList',
    innerShadows: 'innerShadowList'
}

const SYNC_PROP_KEY_MAP = {
    width: 'height',
    height: 'width',
    scaleX: 'scaleY',
    scaleY: 'scaleX'
}

const PROP_KEY_MAP_SYNC_KEY = {
    'width': 'aspectRatioLocked',
    'height': 'aspectRatioLocked',
    'scaleX': 'scaleAspectRatioLocked',
    'scaleY': 'scaleAspectRatioLocked',
}

const validDimension = (value) => {
    if (isNaN(value) || value < MIN_SIZE_THRESHOLD) {
        return MIN_SIZE_VALUE
    }
    return value
}

const SET_PROP_OPTIONS = { commit: true, delta: false }

const SELECTION_CHANGES_RATE_LIMITER = 16
const SELECTION_MESH_CHANGES_RATE_LIMITER = 10

const FREEZE_ELEMENT_BY_PROPERTIES_CHANGES = new Set(['width', 'height', 'contentAnchorX', 'contentAnchorY'])

const NO_AUTO_SELECTION_EVENT_FLAG_SET = new Set([EventFlag.FROM_DATA_SYNC, EventFlag.FROM_INTERACTION_CONTINUOUSLY_CHANGE])

export const shouldFreezeElement = (changes) =>
    Object.keys(changes).some(propKey => FREEZE_ELEMENT_BY_PROPERTIES_CHANGES.has(propKey))

/** @type {Map<string, object>} */
const vertexId2Change = new Map()

/**
 * @fires EDITOR_CHANGES
 */
export class Editor extends EventEmitter {
    /**
     *
     * @param {DataStore} dataStore
     */
    constructor(dataStore) {
        super()
        this.dataStore = dataStore

        this.init()

        this._cacheKeyframeSet = new Set()

        this.dataStore.selection.on('SELECT', (changes) => {
            const elementsChanges = changes.get('elements')
            if (elementsChanges) {
                this._handleElementSelectionChanges(elementsChanges.after)
            }

            const motionPointsChanges = changes.get('motionPoints')
            if (motionPointsChanges) {
                this.emitMotionPathChanges()
            }
        })

        const _throttledHandleTransactionEnd = throttle(
            this._handleTransactionEnd.bind(this),
            SELECTION_CHANGES_RATE_LIMITER,
            { leading: true, trailing: true }
        )
        this.dataStore.selection.on('SELECTION_CHANGES', (transaction) => {
            this._transactionList.push(transaction)
            _throttledHandleTransactionEnd()
        })

        this.dataStore.selection.on('SELECT_CELL', (changes) => {
            const verticesChanges = changes.get('vertices')
            if (!verticesChanges) {
                return
            }
            this._handleVertexSelectionChanges(verticesChanges.after)
        })

        this.dataStore.transition.on('KFINFO_CHANGES', (kfInfoChanges) => {
            if (!this.selectedElements.length) {
                return
            }
            this._handleStateChanges(kfInfoChanges)
        })

        this.dataStore.interaction.on('INTERACTION_CHANGES', throttle(this.handleKeyframeChanges.bind(this)), IM_UPDATE_RATE_LIMITER)

        this.reloadPropertiesAndStatesFn = this.reloadPropertiesAndStates.bind(this)
        this.dataStore.on('CHANGES', this.reloadPropertiesAndStatesFn)

        this.dataStore.on('UNDO', () => {
            this._cacheKeyframeSet.clear()
        })

        this.dataStore.workspace.on('SCENE_TREE_CHANGES', () => {

            // Because the renderer will be construct after the editor,
            // but we need to update the properties after the renderer has finished transforming updates.
            // So, we put emit in the call stack to be executed in the next event loop
            setTimeout(() => {
                this.emitChanges(new EditorChanges().addProps([...GENERAL_PROPERTIES, ...TOOL_STATES]))
            })
        })

        this.dataStore.eam
            .on(Events.DELETE_MOTION_POINT, () => {
                this.deleteMotionPoints()
            })
            .on(Events.DELETE_LAYER, (layerItemId) => {
                this._handleDeleteLayer(layerItemId)
            })
            .on(Events.ACTIVATE_SHAPE_MODE, () => {
                this._handleActivateShapeMode()
            })
            .on(Events.START_DRAG_CELL, () => {
                this._previousMeshHoldStatus = this._holdMeshChange
                this._holdMeshChange = false
            })
            .on(Events.UPDATE_DRAG_CELL, () => {
                this._previousMeshHoldStatus = this._holdMeshChange
                this._holdMeshChange = true
            })
            .on(Events.END_DRAG_CELL, () => {
                this._previousMeshHoldStatus = this._holdMeshChange
                this._holdMeshChange = false
                // Update mesh update data once after finish drag cell
                if (this._holdMeshChangeData) {
                    this._handleMeshChanges(this.dataStore.selection.get('elements')[0].get('id'), this._holdMeshChangeData.changes, this._holdMeshChangeData.options)
                }
                this._holdMeshChangeData = null
            })
            .on(Events.START_DRAG_MOTION_POINT, () => {
                this._previousMeshHoldStatus = this._holdMeshChange
                this._holdMeshChange = false
            })
            .on(Events.UPDATE_DRAG_MOTION_POINT, () => {
                this._previousMeshHoldStatus = this._holdMeshChange
                this._holdMeshChange = true
            })
            .on(Events.END_DRAG_MOTION_POINT, () => {
                this._previousMeshHoldStatus = this._holdMeshChange
                this._holdMeshChange = false
                // Update mesh update data once after finish drag cell
                if (this._holdMeshChangeData) {
                    this._handleMeshChanges(this.dataStore.selection.get('elements')[0].get('id'), this._holdMeshChangeData.changes, this._holdMeshChangeData.options)
                }
                this._holdMeshChangeData = null
            })
    }

    init() {
        /** @type {Element[]} */
        this.selectedElements = []
        this.selectedVertices = []
        this._transactionList = []
        this._holdMeshChange = false
        this._previousMeshHoldStatus = this._holdMeshChange
        this._holdMeshChangeData = null
        this._layerItemMap = {
            fills: new Map(),
            strokes: new Map(),
            shadows: new Map(),
            innerShadows: new Map()
        }
        this._effectItemMap = new Map()
        this.meshChangeHandlers = new Map()
    }

    handleKeyframeChanges(changes, options = {}) {
        if (!NO_AUTO_SELECTION_EVENT_FLAG_SET.has(options.flag)) {
            const { inUndo, inRedo } = this.dataStore.get('undo')
            const isReversed = inUndo || inRedo
            const modifiedList = Array.from(isReversed ? changes.DELETE : changes.CREATE)

            changes.UPDATE.forEach((change, id) => {
                if (change.has('ref') || change.has('value')) {
                    modifiedList.push(id)
                }
            })

            const modifiedKeyframeSet = new Set(modifiedList.filter(id => this.dataStore.interaction.getKeyFrame(id)));

            if (modifiedKeyframeSet.size) {
                modifiedKeyframeSet.forEach(id => this._cacheKeyframeSet.add(id));

                this.dataStore.selection.selectOnlyKFs([...this._cacheKeyframeSet], { undoable: false, commit: false });
            }
        }

        // Fix UI doesn't update for undo/redo mirror changes because there are no CS changes in this action.
        if (this.dataStore.get('editMode') === EditMode.MOTION_PATH) {
            this.emitMotionPathChanges()
        }
    }


    reloadPropertiesAndStates(changes) {
        if (changes.has('mode')) {
            this._resetLayerItemMap()
            this._resetEffectItemMap()
            // TODO: Should only need to update state
            this.emitChanges(new EditorChanges().addProps([...GENERAL_PROPERTIES, ...LAYER_LIST_PROPERTIES, EFFECT_LIST_PROPERTIES, ...CUSTOM_PROPERTIES, ...CONTAINER_PROPERTY_SET]))
        }

        if (changes.has('editMode')) {
            const editMode = changes.get('editMode').value
            switch (editMode) {
                case EditMode.SHAPE:
                    this.emitChanges(new EditorChanges().addProps([...VERTEX_PROPERTIES, ...TOOL_STATES, ...CUSTOM_PROPERTIES_STATES_NAME_MAP.keys()]))
                    break
                case EditMode.MOTION_PATH:
                    this.emitChanges(new EditorChanges().addProps([...MOTION_PATH_PROPERTIES, ...TOOL_STATES]))
                    break
                default:
                    this.emitChanges(new EditorChanges().addProps([...GENERAL_PROPERTIES, ...TOOL_STATES, ...CONTAINER_PROPERTY_SET]))
                    break
            }
        }
    }

    /**
     * Emit EDITOR_CHANGES with changes
     * @param {EditorChanges} changes
     */
    emitChanges(changes) {
        if (this.dataStore.selection.data.updatingVersions) return
        this.emit('EDITOR_CHANGES', changes)
    }

    _handleTransactionEnd() {
        this._changes = new EditorChanges()
        const layerTransaction = new Map()
        const effectTransaction = new Map()
        this._transactionList.forEach(transaction => {
            for (const [owner, changes] of transaction) {
                switch (owner.get('type')) {
                    case EntityType.COMPUTED_STYLE:
                        this._setEditorChangesEvent(changes)
                        break
                    case EntityType.COMPUTED_LAYER:
                        if (layerTransaction.has(owner)) {
                            const layerTransactionChange = layerTransaction.get(owner)
                            changes.forEach((change, key) => {
                                layerTransactionChange.set(key, change)
                            })
                        } else {
                            layerTransaction.set(owner, changes)
                        }
                        break
                    case EntityType.COMPUTED_EFFECT: {
                        if (effectTransaction.has(owner)) {
                            const effectTransactionChange = effectTransaction.get(owner)
                            changes.forEach((change, key) => {
                                effectTransactionChange.set(key, change)
                            })
                        } else {
                            effectTransaction.set(owner, changes)
                        }
                        break
                    }
                    case EntityType.GEOMETRY:
                        this._changes.addProps('geometryType')
                        break
                    case EntityType.ELEMENT:
                        this._changes.addProps([...changes.keys()].filter(key => UNDOABLE_ELEMENT_SETTER_PROPS.has(key)))
                        if (owner.isContainer) {
                            this._changes.addProps([...CONTAINER_PROPERTY_SET.keys()])
                        }
                        break
                }
            }
        })
        for (const [owner, changes] of layerTransaction) {
            const layerType = owner.get('layerType')
            const layerItemMap = this._layerItemMap[LayerTypeMapLayerListKey[layerType]]
            if (!layerItemMap) {
                continue
            }
            const layerItem = [...layerItemMap.values()].find(li => {
                return !li.isMix && li.computedLayerSet.has(owner)
            })
            this._setEditorLayerChangesEvent(layerItem, changes)
        }

        for (const [owner, changes] of effectTransaction) {
            const effectItem = [...this._effectItemMap.values()].find(ei => {
                return !ei.isMix && ei.computedEffectSet.has(owner)
            })
            this._setEditorEffectChangesEvent(effectItem, changes)
        }

        // Should always update contentAnchor with size change
        if (this._changes.props.has('width')) {
            this._changes.props.add('contentAnchorX')
        }
        if (this._changes.props.has('height')) {
            this._changes.props.add('contentAnchorY')
        }
        // Should always update position with contentAnchor change
        if (this._changes.props.has('contentAnchorX')) {
            this._changes.props.add('x')
        }
        if (this._changes.props.has('contentAnchorY')) {
            this._changes.props.add('y')
        }

        this.emitChanges(this._changes)
        this._changes = null
        this._transactionList = []
    }

    /**
     * Unset all the event listener in map
     * @private
     */
    _clearChangeHandler() {
        this.selectedElements.forEach((selectedElement) => {
            this._unbindMeshChanges(selectedElement, true)
        })
    }

    _unbindMeshChanges(selectedElement, needsUnbind) {
        if (needsUnbind) {
            const selectedElementId = selectedElement.get('id')
            const handlers = this.meshChangeHandlers.get(selectedElementId)
            if (handlers) {
                selectedElement.off('MESH_CHANGES', handlers.MESH_CHANGES)
                selectedElement.off('TRIGGER_VECTOR_FORCE_UPDATE', handlers.TRIGGER_VECTOR_FORCE_UPDATE)
            }
        }
        const isIndependent = this.isComputedGroup
        if (selectedElement.children) {
            for (const child of selectedElement.children) {
                this._unbindMeshChanges(child, isIndependent)
            }
        }
    }

    emitMotionPathChanges() {
        this.emitChanges(new EditorChanges().addProps(MOTION_PATH_PROPERTIES))
    }

    /**
     * Handle selected elements changes
     * @private
     * @param {Element[]} selectedElements
     */
    _handleElementSelectionChanges(selectedElements) {
        this._clearChangeHandler()

        // Set event listener to the selected elements
        this.selectedElements = selectedElements
        this.selectedElements.forEach((selectedElement) => {
            this._bindMeshChanges(selectedElement, true)
        })
        this._resetLayerItemMap()
        this._resetEffectItemMap()

        let propsChanges = []
        if (this.selectedElements[0]) {
            propsChanges = [...GENERAL_PROPERTIES, ...LAYER_LIST_PROPERTIES, EFFECT_LIST_PROPERTIES, ...TOOL_STATES]
            if (this.selectedElements[0].isContainer) {
                propsChanges.push(...CONTAINER_PROPERTY_SET.values())
            }
        }
        // Always update container properties for corner radius input visibility.
        propsChanges.push(...CONTAINER_PROPERTY_SET)

        this.emitChanges(new EditorChanges().addProps(propsChanges))
    }

    _bindMeshChanges(selectedElement, needsBind) {
        // Do not have to check whether the elemet has mesh because it can be switched
        if (needsBind) {
            const selectedElementId = selectedElement.get('id')
            const handlers = {
                MESH_CHANGES: throttle(
                    this._handleMeshChanges.bind(this, selectedElementId),
                    SELECTION_MESH_CHANGES_RATE_LIMITER,
                    { leading: true, trailing: true }
                ),
                TRIGGER_VECTOR_FORCE_UPDATE: throttle(
                    this._handleVectorForceChanges.bind(this, selectedElementId),
                    SELECTION_MESH_CHANGES_RATE_LIMITER,
                    { leading: true, trailing: true }
                )
            }
            this.meshChangeHandlers.set(selectedElementId, handlers)
            selectedElement.on('MESH_CHANGES', handlers.MESH_CHANGES)
            selectedElement.on('TRIGGER_VECTOR_FORCE_UPDATE', handlers.TRIGGER_VECTOR_FORCE_UPDATE)
        }
        const isIndependent = selectedElement.isComputedGroup
        if (selectedElement.children) {
            for (const child of selectedElement.children) {
                this._bindMeshChanges(child, isIndependent)
            }
        }
    }

    /**
     * Handle selected vertices changes
     * @private
     * @param {Vertex[]} selectedVertices
     */
    _handleVertexSelectionChanges(selectedVertices) {
        this.selectedVertices = selectedVertices
        this.emitChanges(new EditorChanges().addProps([...VERTEX_PROPERTIES, ...TOOL_STATES]))
    }

    _handleStateChanges(kfInfoChanges) {
        const editorChanges = new EditorChanges()
        kfInfoChanges.forEach(kfInfo => {
            if (kfInfo.prop === 'motionPath') {
                editorChanges.addProps('xState', 'yState')
            } else if (kfInfo.layerName) {
                // layer
                const { computedLayer, prop } = kfInfo
                // Early return if cl has been deleted
                if (!computedLayer) return

                const layerType = computedLayer.get('layerType')
                const layerItemId = this[LAYER_TYPE_LIST_MAP[layerType]].find(id => {
                    return id !== MIX_VALUE_ID && this.getLayerItem(id).computedLayerSet.has(computedLayer)
                })
                if (layerItemId && LAYER_PROP_NAME_STATE_MAP[layerType].has(prop)) {
                    editorChanges.addLayerProps(layerItemId, LAYER_PROP_NAME_STATE_MAP[layerType].get(prop))
                }
            } else if (kfInfo.effectName) {
                const { computedEffect, prop } = kfInfo
                if (!computedEffect) return

                const effectType = computedEffect.get('effectType')
                const effectItemId = this.effectList.find(id => {
                    return id !== MIX_VALUE_ID && this.getEffectItem(id).computedEffectSet.has(computedEffect)
                })
                if (effectItemId && EFFECT_PROP_NAME_STATE_MAP[effectType].has(prop)) {
                    editorChanges.addEffectProps(effectItemId, EFFECT_PROP_NAME_STATE_MAP[effectType].get(prop))
                }
            } else if (PROP_NAME_STATE_MAP.has(kfInfo.prop)) {
                // property
                editorChanges.addProps(PROP_NAME_STATE_MAP.get(kfInfo.prop))
            } else if (CUSTOM_PROPERTIES.has(kfInfo.prop)) {
                editorChanges.addProps(CUSTOM_PROPERTIES_KEY_STATES_MAP.get(kfInfo.prop))
            }
        })
        this.emitChanges(editorChanges)
    }

    /**
     * Handle vector force change
     */
    _handleVectorForceChanges() {
        if (this.dataStore.get('editMode') === EditMode.SHAPE) {
            this.emitChanges(new EditorChanges().addProps(['x', 'y']))
        }
    }

    /**
     * Handle selected element's mesh changes
     * @param {string} elementId
     * @param {ChangesEvent} changes
     * @param {object} [options={}]
     */
    _handleMeshChanges(elementId, changes, options = {}) {
        const { inUndo, inRedo } = this.dataStore.get('undo')
        const element = this.dataStore.getElement(elementId)
        element.markAsDirty()
        // const elementId = this.selectedElements[0].get('id')
        /** @type {KFChange} */
        const kfc = {
            elementId,
            propKey: 'pathMorphing',
            value: [],
            delta: false,
            frameType: FrameType.EXPLICIT
        }
        const editorChanges = new EditorChanges()
        const isActionMode = this.dataStore.isActionMode
        // if the update is about the corner radius, then skip the mesh change handling
        for (const change of changes.UPDATE) {
            if (change[1].has('cornerRadius')) return
        }

        // Update base path with mesh CREATE or DELETE
        if (changes.CREATE.size || changes.DELETE.size || !options.interaction) {
            this._handleBasePathWithMeshChangesWithCreateDelete(kfc, changes, isActionMode, elementId, editorChanges)
        }

        // The price of using Figma curve structure that allows a curve can be created following creating edges
        if (changes.CREATE.size && (!inUndo && !inRedo)) {
            this._copyKeyframeFromAdjacentMainVertex(elementId, changes, element)
        }

        // Update path morphing keyframes with mesh CREATE
        if (changes.CREATE.size && (!inUndo && !inRedo)) {
            this._addVerticesToKeyframeData(elementId, changes.CREATE)
        }

        // Update path morphing keyframes with mesh DELETE
        if (changes.DELETE.size && (!inUndo && !inRedo)) {
            this._removeVerticesFromKeyframeData(elementId, changes.DELETE)
        }

        if (Array.from(changes.UPDATE.keys()).some(vertexId => !changes.CREATE.has(vertexId))) {
            editorChanges.addProps(VERTEX_PROPERTIES)
            if (this._previousMeshHoldStatus && this._holdMeshChange) {
                this._holdMeshChangeData = {
                    changes,
                    options
                }
            } else {
                this._handleMeshChangesOnlyUpdate(kfc, changes, isActionMode, elementId, options.interaction, !inUndo && !inRedo, editorChanges)
            }
        }

        if (!editorChanges.isEmpty()) {
            this.emitChanges(editorChanges)
        }
        this.dataStore.interaction.fire()
    }

    /**
     * Get path morphing track by elementId
     * @param {string} elementId
     * @returns {null|PropertyTrack}
     */
    _getPathMorphingTrackByElementId(elementId) {
        const propertyTrackMaps = this.dataStore.interaction.getPropertyTrackMapsByElementId(elementId)
        if (!propertyTrackMaps) {
            return null
        }

        const tracks = []
        for (let i = 0; i < propertyTrackMaps.length; i++) {
            if (!propertyTrackMaps[i]) continue
            const pathMorphingTrackId = propertyTrackMaps[i].get('pathMorphing')
            tracks.push(this.dataStore.interaction.getPropertyTrack(pathMorphingTrackId))
        }

        return tracks
    }

    /**
     * Copy keyframe of adjacentMainVertex of new curve control
     * @param {string} elementId
     * @param {object} changes
     * @param {object} element
     * @returns {void}
     */
    _copyKeyframeFromAdjacentMainVertex(elementId, changes, element) {
        const pathMorphingTracks = this._getPathMorphingTrackByElementId(elementId)
        if (!pathMorphingTracks) {
            return
        }

        const copyMap = new Map()
        for (const newItemID of changes.CREATE) {
            /** @type {import('./Element').Mesh} */
            const mesh = element.get('geometry').get('mesh')
            const cell = mesh.cellTable.get(newItemID)

            if (cell.type !== 'Vertex' || !cell.isFlagged(FlagsEnum.CURVE_VERT)) {
                continue
            }

            const adjVertex = cell.adjacentMainVertex
            if (adjVertex && !changes.CREATE.has(adjVertex.id)) {
                copyMap.set(cell.adjacentMainVertex.id, cell.id)
            }

        }

        if (copyMap.size < 1) {
            return
        }

        for (let i = 0; i < pathMorphingTracks.length; i++) {
            const pathMorphingTrack = pathMorphingTracks[i]
            if (!pathMorphingTrack) {
                continue
            }
            for (const kfId of pathMorphingTrack.keyFrameList) {
                const kf = this.dataStore.interaction.getKeyFrame(kfId)
                const changeCopys = []

                for (const value of kf.value) {
                    if (!copyMap.has(value.id)) {
                        continue
                    }

                    const changeCopy = cloneDeep(value)
                    changeCopy.id = copyMap.get(value.id)
                    changeCopys.push(changeCopy)
                }

                if (!(changeCopys.length > 0)) {
                    continue
                }

                const newValueList = cloneDeep(kf.value)
                newValueList.push(...changeCopys)
                this.dataStore.interaction.updateKeyFrameData(kfId, newValueList, false)
            }
        }
    }

    _addVerticesToKeyframeData(elementId, createMap) {
        const pathMorphingTracks = this._getPathMorphingTrackByElementId(elementId)
        if (!pathMorphingTracks) {
            return
        }

        const mesh = this.dataStore.getElement(elementId).get('mesh')
        if (!mesh) {
            return
        }

        const newVerticesData = []
        createMap.forEach((cellId) => {
            const cell = mesh.cellTable.get(cellId)
            if (cell.type === 'Vertex') {
                newVerticesData.push(cell.save())
            }
        })

        for(let i = 0; i < pathMorphingTracks.length; i++) {
            const pathMorphingTrack = pathMorphingTracks[i]
            if (!pathMorphingTrack) {
                continue
            }
            for (const kfId of pathMorphingTrack.keyFrameList) {
                const kf = this.dataStore.interaction.getKeyFrame(kfId)
                kf.value = [...kf.value, ...newVerticesData]
                this.dataStore.interaction.updateKeyFrameData(kfId, kf.value, false)
            }
        }
    }

    _removeVerticesFromKeyframeData(elementId, deleteMap) {
        const pathMorphingTracks = this._getPathMorphingTrackByElementId(elementId)
        if (!pathMorphingTracks) {
            return
        }

        for (let i = 0; i < pathMorphingTracks.length; i++) {
            const pathMorphingTrack = pathMorphingTracks[i]
            if (!pathMorphingTrack) {
                continue
            }
            for (const kfId of pathMorphingTrack.keyFrameList) {
                const kf = this.dataStore.interaction.getKeyFrame(kfId)
                const newValueList = kf.value.filter(value => {
                    return !deleteMap.has(value.id)
                })

                // Update keyframe data if the new data is not empty.
                // Or, remove the whole keyframe if the data is empty.
                if (newValueList.length) {
                    this.dataStore.interaction.updateKeyFrameData(kfId, newValueList, false)
                } else {
                    this.dataStore.interaction.deleteKeyFrame(kfId, false)
                }
            }
        }
    }

    _handleMeshChangesOnlyUpdate(kfc, changes, isActionMode, elementId, canHaveAnimation, notInUndoRedo, editorChanges) {
        const shouldUpdateToIM = isActionMode && canHaveAnimation && notInUndoRedo

        // Collect editor changes
        for (const change of changes.UPDATE.values()) {
            for (const prop of change.keys()) {
                editorChanges.addProps(prop)
            }
        }

        // Save all points data to keyframe
        const mesh = this.dataStore.getElement(elementId).get('mesh')
        if (!mesh) {
            return
        }

        mesh.vertices.forEach((v) => {
            if (v.id === FAKE_IDS.CURVE_CONTROL) {
                return
            }

            kfc.value.push({
                id: v.id,
                pos: [v.pos[0], v.pos[1]],
                mirror: v.mirror
            })
        })

        if (shouldUpdateToIM) {
            this.dataStore.interaction.setProperties([kfc], { fire: false })
        }
    }

    _handleBasePathWithMeshChangesWithCreateDelete(kfc, changes, isActionMode, elementId, editorChanges) {
        editorChanges.addProps(VERTEX_PROPERTIES)
        if (isActionMode) {
            const element = this.dataStore.getElement(elementId)
            /** @type {import('./Element').Mesh} */
            const mesh = element.get('geometry').get('mesh')
            let updateTM = false

            for (const newItemID of changes.CREATE) {
                const cell = mesh.cellTable.get(newItemID)
                if (cell.type === 'Vertex') {
                    if (element.basePath) {
                        const vertexData = cell.save()
                        element.addBaseVertex(newItemID, vertexData)
                        updateTM = true
                    }
                }
            }

            for (const deleteItemID of changes.DELETE) {
                const cell = mesh.cellTable.get(deleteItemID)
                if (cell.type === 'Vertex') {
                    if (element.basePath) {
                        element.deleteBaseVertex(deleteItemID)
                        updateTM = true
                    }

                }
            }

            if (updateTM) {
                this.dataStore.transition.cacheSpecificElementBaseValue(elementId, 'pathMorphing')
                this._updateBaseValueWithMeshChanges(mesh, element)
            }
        }
    }

    _updateBaseValueWithMeshChanges(mesh, element) {
        const tempMesh = mesh.copy(true)
        if (element.basePath) {
            for (const [id, vertData] of element.basePath) {
                const cell = tempMesh.cellTable.get(id)
                if (cell && cell.type === 'Vertex') {
                    cell.pos.copy(vertData.pos)
                }
            }
            tempMesh.recalculateBounds()
            element.setBaseProp('referencePoint', { referencePointX: - tempMesh.bounds.position.x, referencePointY: - tempMesh.bounds.position.y })
            element.setBaseProp('dimensions', { width: tempMesh.bounds.width, height: tempMesh.bounds.height })
        }
    }

    _handleDeleteLayer(layerItemId) {
        this.deleteLayer(layerItemId)
    }

    _handleActivateShapeMode() {
        const fireIM = this.dataStore.isDesignMode
        this.dataStore.transition.movePlayheadOut()
        this.selectedElements.forEach((element) => {
            if (element.canMorph) {
                return
            }

            // Cache base path with current shape
            const elementId = element.get('id')
            const elementTrackId = this.dataStore.interaction.getElementTrackIdByElementId(elementId)
            const elementTrack = this.dataStore.interaction.getElementTrack(elementTrackId)
            if (!elementTrack) {
                element.changeGeometry(GeometryType.POLYGON)
                return
            }
            const sizeTrackId = elementTrack.propertyTrackMap.get('dimensions')
            if (!sizeTrackId) {
                element.changeGeometry(GeometryType.POLYGON)
                return
            }
            const sizeKfs = this.dataStore.interaction.getPropertyTrackKeyFrameGroupByTime(sizeTrackId)
            if (!sizeKfs) {
                element.changeGeometry(GeometryType.POLYGON)
                return
            }

            const convertKfTimes = Object.keys(sizeKfs).map((t) => parseInt(t)).sort((a, b) => a - b)
            const removedKfs = Object.values(sizeKfs).flat()
            // WENJIE-TODO change to reference point
            // -----------------------------------------------
            const referencePoint = element.getBaseValue('referencePoint')
            const contentAnchor = element.getBaseValue('contentAnchor')
            const baseSize = element.getBaseValue('dimensions')
            const originalOrigin = getPivotOffset(referencePoint, contentAnchor)

            // Reset base path value with original size
            const currTime = this.dataStore.transition.time
            const computedSize = element.get('size')
            const currWidth = computedSize.width
            const currHeight = computedSize.height
            const currContentAnchorData = this.dataStore.transition.getPropertyValueByTime(elementId, 'contentAnchor', currTime)
            const currentReferencePoint = {
                referencePointX: currWidth / 2,
                referencePointY: currHeight / 2
            }
            const currOrigin = getPivotOffset(currentReferencePoint, currContentAnchorData)

            const sizes = []
            const origins = []
            convertKfTimes.forEach((time) => {
                const width = this.dataStore.transition.getPropertyValueByTime(elementId, 'width', time).width
                const height = this.dataStore.transition.getPropertyValueByTime(elementId, 'height', time).height
                sizes.push({ width, height })

                const newContentAnchorData = this.dataStore.transition.getPropertyValueByTime(elementId, 'contentAnchor', time)
                const referencePointInActionMode = {
                    referencePointX: width / 2,
                    referencePointY: height / 2
                }
                const newOrigin = getPivotOffset(referencePointInActionMode, newContentAnchorData)
                origins.push(newOrigin)
            })

            element.changeGeometry(GeometryType.POLYGON)

            const base = element.basePath
            // If basePath is null, then don't convert animation settings.
            if (!base) {
                console.error('BasePath should not be null after convert normal element to path element')
                return
            }

            base.forEach((v) => {
                v.pos[0] = (v.pos[0] - currOrigin.originX) * (baseSize.width / currWidth) + originalOrigin.originX
                v.pos[1] = (v.pos[1] - currOrigin.originY) * (baseSize.height / currHeight) + originalOrigin.originY
            })

            convertKfTimes.forEach((time, idx) => {
                const newVertices = []
                const { width, height } = sizes[idx]
                const newOrigin = origins[idx]

                // Get new position for all vertices
                base.forEach((v) => {
                    newVertices.push({
                        id: v.id,
                        pos: [
                            (v.pos[0] - originalOrigin.originX) * (width / baseSize.width) + newOrigin.originX,
                            (v.pos[1] - originalOrigin.originY) * (height / baseSize.height) + newOrigin.originY
                        ],
                        mirror: v.mirror
                    })
                })

                const propertyTrackId = this.dataStore.interaction.setProperty(elementId, 'pathMorphing', newVertices, FrameType.EXPLICIT, null, { fire: fireIM })
                const kf = this.dataStore.interaction.getKeyFrameByTime(propertyTrackId, this.dataStore.transition.time)
                this.dataStore.interaction.setKeyFrameTime(kf.id, time, { fire: fireIM })
            })

            // -----------------------------------------------
            removedKfs.forEach((kfId) => {
                this.dataStore.interaction.deleteKeyFrame(kfId, fireIM)
            })
        })

        // Cache base path with new base path
        this.dataStore.transition.movePlayheadBack()
        if (!fireIM) {
            this.dataStore.interaction.fire()
            this.dataStore.transition.forceUpdateAnimation()
        }
    }

    /**
     * Emit EDITOR_CHANGES event while property changed
     * @private
     * @param {ChangesEvent} changes  CHANGES event object
     */
    _setEditorChangesEvent(changes) {
        // TODO: get actual editor property changes from computed changes, not whole PropertySet
        for (const key of changes.keys()) {
            const propKey = SWITCH_COMPONENT_KEYS[key] || key
            const props = COMPONENT_PROPERTY_MAP[KEYS_TO_PROP[propKey]]
            if (props) {
                this._changes.addProps(props)
            } else if (LIST_NAME_TO_LAYER_LIST_MAP[key]) {
                this._resetLayerItemMap([key])
                this._changes.addProps(LIST_NAME_TO_LAYER_LIST_MAP[key])
            } else if (key === 'effect') {
                this._resetEffectItemMap()
                this._changes.addProps(EFFECT_LIST_PROPERTIES)
            }
        }
    }

    /**
     * Emit EDITOR_CHANGES event while layer property changed
     * @private
     * @param {LayerItem} layerItem
     * @param {ChangesEvent} changes  CHANGES event object
     */
    _setEditorLayerChangesEvent(layerItem, changes) {
        if (!layerItem) return

        for (const key of changes.keys()) {
            if (key === 'paintType') {
                const paintProps = PAINT_CATEGORY_PROPS_MAP[PAINT_CATEGORY_MAP[changes.get('paintType').value]]
                paintProps.forEach(prop => {
                    if (EI_LAYER_ALLOWED_KEYS_SET[layerItem.layerType].has(key)) {
                        this._changes.addLayerProps(layerItem.id, prop)
                    }
                })
            }
            if (EI_LAYER_ALLOWED_KEYS_SET[layerItem.layerType].has(key)) {
                this._changes.addLayerProps(layerItem.id, key)
            }
        }
    }

    /**
     * Emit EDITOR_CHANGES event while effect property changed
     * @private
     * @param {EffectItem} effectItem
     * @param {ChangesEvent} changes  CHANGES event object
     */
    _setEditorEffectChangesEvent(effectItem, changes) {
        if (!effectItem) return

        for (const key of changes.keys()) {
            if (EI_EFFECT_ALLOWED_KEYS_SET[effectItem.effectType].has(key)) {
                this._changes.addEffectProps(effectItem.id, key)
            }
        }
    }

    _updateIMPropState(element, propKey, frameType) {
        const elementId = element.get('id')

        const { isMotionPath, updateKey, updateValue } = this.getIMPropUpdateData(element, propKey)
        this.dataStore.interaction.setProperty(elementId, updateKey, updateValue, frameType, isMotionPath)
    }

    getIMPropUpdateData(element, propKey) {
        const IMPropKey = SWITCH_COMPONENT_PROPS[propKey] || propKey
        const isCustomProp = CUSTOM_PROPERTIES.has(IMPropKey)
        const isMotionPath = IMPropKey === 'motionPath'

        let updateKey = propKey
        let updateValue
        if (isCustomProp) {
            updateValue = [...element.get('geometry').get('mesh').vertices].map((v) => ({
                id: v.id,
                pos: [v.pos[0], v.pos[1]],
                mirror: v.mirror
            }))
        } else if (isMotionPath) {
            const { translateX: baseX, translateY: baseY } = element.getBaseValue('translate')
            const [tx, ty] = element.get('translate')
            const workingInterval = this.dataStore.transition.getPropertyWorkingInterval(element.get('id'), 'motionPath')

            if (workingInterval) {
                const startPoint = workingInterval.start.value.pos // kf1
                const endPoint = workingInterval.end.value.pos // kf2
                const outTangent = workingInterval.start.value.out.slice(0, 2)
                const inTangent = workingInterval.end.value.in.slice(0, 2)
                const controlPoint1 = outTangent.every(v => !v) ? null : [startPoint[0] + outTangent[0], startPoint[1] + outTangent[1]]
                const controlPoint2 = inTangent.every(v => !v) ? null : [endPoint[0] + inTangent[0], endPoint[1] + inTangent[1]]
                const isStraight = !controlPoint1 && !controlPoint2

                if (isStraight) {
                    updateValue = {
                        pos: [tx - baseX, ty - baseY],
                        in: [0, 0],
                        out: [0, 0],
                        mirror: PointShape.NONE
                    }
                } else {
                    const percent = this.dataStore.transition.getPropertyWorkingIntervalProgress(element.get('id'), 'motionPath')
                    const curve = [...startPoint, ...outTangent, ...inTangent, ...endPoint]
                    const newCurves = this.dataStore.drawInfo.subdivideMotionPath(curve, percent)
                    const leftCurveData = newCurves[0]
                    const rightCurveData = newCurves[1]

                    updateValue = {
                        pos: [leftCurveData[6], leftCurveData[7]],
                        in: [leftCurveData[4] - leftCurveData[6], leftCurveData[5] - leftCurveData[7]],
                        out: [rightCurveData[2] - rightCurveData[0], rightCurveData[3] - rightCurveData[1]],
                        mirror: PointShape.INDEPENDENT
                    }

                    if (workingInterval.start.id) {
                        this.dataStore.interaction.updateKeyFrameData(workingInterval.start.id, {
                            pos: [leftCurveData[0], leftCurveData[1]],
                            in: workingInterval.start.value.in,
                            out: [leftCurveData[2] - leftCurveData[0], leftCurveData[3] - leftCurveData[1]],
                            mirror: PointShape.INDEPENDENT
                        }, false)
                    }

                    this.dataStore.interaction.updateKeyFrameData(workingInterval.end.id, {
                        pos: [rightCurveData[6], rightCurveData[7]],
                        in: [rightCurveData[4] - rightCurveData[6], rightCurveData[5] - rightCurveData[7]],
                        out: workingInterval.end.value.out,
                        mirror: PointShape.INDEPENDENT
                    }, false)

                }
            } else {
                updateValue = {...DEFAULT_MOTION_PATH_VALUE}
            }


        } else {
            updateValue = element.get(IMPropKey)
            if (IMPropKey === 'contentAnchor') {
                const {
                    width,
                    height,
                    contentAnchorX,
                    contentAnchorY,
                    referencePointX,
                    referencePointY
                } = element.gets(
                    'width',
                    'height',
                    'contentAnchorX',
                    'contentAnchorY',
                    'referencePointX',
                    'referencePointY'
                )

                const propAnchorX = this.getProp('contentAnchorX', [element])
                const propAnchorY = this.getProp('contentAnchorY', [element])

                const anchorX = propAnchorX - (contentAnchorX + referencePointX - width / 2)
                const anchorY = propAnchorY - (contentAnchorY + referencePointY - height / 2)

                updateKey = 'contentAnchor'
                updateValue = { contentAnchorX: anchorX, contentAnchorY: anchorY }
            }
        }

        return {
            isMotionPath,
            updateValue,
            updateKey,
        }
    }

    /** Non repeatable properties getter/setter */

    /**
     * Check if property key is valid
     * @param {string} propKey property name
     * @returns {boolean}
     */
    hasProp(propKey) {
        return GENERAL_PROPERTIES.includes(propKey) || this.hasMotionPathProp(propKey) || this.hasVertexProp(propKey) || this.isToolState(propKey) || CONTAINER_PROPERTY_SET.has(propKey)
    }

    isPropState(propKey) {
        return PROP_STATE_NAME_MAP.has(propKey)
    }

    isCustomPropState(propKey) {
        return CUSTOM_PROPERTIES_STATES_NAME_MAP.has(propKey)
    }

    isToolState(propKey) {
        return TOOL_STATES.includes(propKey)
    }

    getToolState(propKey) {
        switch (propKey) {
            case 'alignment':
                return this.getAlignment()
            case 'distortion':
                return this.getDistribution()
        }
    }

    hasCurveControl() {
        if (this.selectedElements.length > 0) {
            const selectedElement = this.selectedElements[0]
            const geometry = selectedElement.get('geometry')
            const vertices = this._getEditableVertices(geometry)
            for (const vertex of vertices) {
                const isCurveControl = vertex.isFlagged(FlagsEnum.CURVE_VERT)
                if (isCurveControl) {
                    return true
                }
            }
        }

        return false
    }

    getAlignment() {
        if (this.selectedElements.length === 0) {
            return false
        }

        if (this.selectedElements.length === 1) {
            const selectedElement = this.selectedElements[0]
            if (selectedElement.get('elementType') === ElementType.SCREEN) {
                return false
            }

            const elementParent = selectedElement.get('parent')
            if (!elementParent || (elementParent.isContainer && elementParent.isComputedGroup && !elementParent.isNormalGroup)) {
                return false
            }
        }

        if (this.dataStore.get('editMode') === EditMode.SHAPE) {
            if (this.hasCurveControl() || this.selectedVertices.length === 0) {
                return false
            }
        }

        return true
    }

    setAlignment(direction) {
        this.dataStore.eam.align(direction)
    }

    getDistribution() {
        if (this.dataStore.get('editMode') === EditMode.SHAPE && this.hasCurveControl()) {
            return false
        }

        return this.selectedElements.length > 2 || this.selectedVertices.length > 2
    }

    setDistribution(direction) {
        this.dataStore.eam.distribute(direction)
    }

    /**
     * Check if property key is valid vertex property
     * @param {string} propKey property name
     * @returns {boolean}
     */
    hasVertexProp(propKey) {
        return VERTEX_PROPERTIES.includes(propKey)
    }

    hasMotionPathProp(propKey) {
        return MOTION_PATH_PROPERTIES.includes(propKey)
    }

    /**
     * Get property state from the transition manager
     * @param {string} propStateKey property state name
     * @returns {any}
     */
    getPropState(propStateKey) {
        let propKey = PROP_STATE_NAME_MAP.get(propStateKey)
        if (this.dataStore.isActionMode && propKey) {
            let state = PROP_STATES.TWEEN

            if (propKey === 'x' || propKey === 'y') {
                propKey = 'motionPath'
            }
            for (const element of this.selectedElements) {
                const id = element.get('id')
                const s = this.dataStore.transition.getElementKeyframeState(id, propKey)
                if (s === FrameType.EXPLICIT) {
                    return PROP_STATES.EXPLICIT
                } else if (s === FrameType.INITIAL) {
                    state = PROP_STATES.INITIAL
                }
            }
            return state
        }
        return PROP_STATES.DEFAULT
    }

    /**
     * Get custom property state from the transition manager
     * @param {string} propStateKey property state name
     * @returns {any}
     */
    getCustomPropState(propStateKey) {
        const propKey = CUSTOM_PROPERTIES_STATES_NAME_MAP.get(propStateKey)
        let state = PROP_STATES.DEFAULT
        if (this.dataStore.isActionMode && propKey) {
            state = PROP_STATES.TWEEN
            for (const element of this.selectedElements) {
                const id = element.get('id')
                const s = this.dataStore.transition.getElementKeyframeState(id, propKey)
                if (s === FrameType.EXPLICIT) {
                    state = PROP_STATES.EXPLICIT
                    break
                } else if (s === FrameType.INITIAL) {
                    state = PROP_STATES.INITIAL
                }
            }
        }
        return state
    }

    /**
     * @param {string} propKey property name
     * @returns {MIX_VALUE | PointShape | number}
     */
    getMotionPathProp(propKey) {
        const element = this.selectedElements[0]

        const motionPoints = this.dataStore.selection.get('motionPoints')

        return motionPoints.reduce((acc, cur) => {
            const kf = this.dataStore.interaction.getKeyFrame(cur.key)
            if (!kf) {
                return acc
            }
            let value
            switch (propKey) {
                case 'x': {
                    const base = element.getBaseValue('translate').translateX
                    if (kf.frameType === FrameType.INITIAL) {
                        value = base
                    } else {
                        switch(cur.type) {
                            case 'point':
                                value = base + kf.value.pos[0]
                                break
                            case 'in':
                                value = base + kf.value.pos[0] + kf.value.in[0]
                                break
                            case 'out':
                                value = base + kf.value.pos[0] + kf.value.out[0]
                                break
                        }
                    }
                    break
                }
                case 'y': {
                    const base = element.getBaseValue('translate').translateY
                    if (kf.frameType === FrameType.INITIAL) {
                        value = base
                    } else {
                        switch(cur.type) {
                            case 'point':
                                value = base + kf.value.pos[1]
                                break
                            case 'in':
                                value = base + kf.value.pos[1] + kf.value.in[1]
                                break
                            case 'out':
                                value = base + kf.value.pos[1] + kf.value.out[1]
                                break
                        }
                    }
                    break
                }
                case 'mirror':
                    if (kf.frameType === FrameType.INITIAL) {
                        value = PointShape.NONE
                    } else {
                        value = kf.value.mirror
                    }
                    break
                default:
                    console.error('Unknown motion point key:', propKey)
                    break

            }

            if (acc === undefined) {
                return value
            } else if (acc !== value) {
                return MIX_VALUE
            }
            return acc
        }, undefined)
    }

    /**
     * Get vertex property value from the selected element's mesh
     * @param {string} propKey property name
     * @returns {MIX_VALUE | number}
     */
    getVertexProp(propKey) {
        const element = this.selectedElements[0]
        if (!element) {
            return undefined
        }

        if (propKey === 'mirror' && !this.selectedVertices.length) {
            return undefined
        }

        const elementId = element.get('id')
        const geometry = element.get('geometry')
        const vertices = this._getEditableVertices(geometry)
        const verticesBounds = this.dataStore.drawInfo.getVerticesBoundWorld(element)
        let value
        for (const vertex of vertices) {
            let vertexValue
            const vertexPos = this.dataStore.drawInfo.vertex2World(elementId, vertex.pos)
            switch (propKey) {
                case 'x':
                    vertexValue = vertexPos[0] - verticesBounds.x
                    break
                case 'y':
                    vertexValue = vertexPos[1] - verticesBounds.y
                    break
                case 'cornerRadius':
                    vertexValue = vertex.cornerRadius === null
                        ? vertexValue = element.get('cornerRadius')
                        : vertexValue = vertex[propKey]
                    break
                case 'mirror':
                    vertexValue = vertex.isFlagged(FlagsEnum.CURVE_VERT)
                        ? vertexValue = vertex.adjacentMainVertex[propKey]
                        : vertexValue = vertex[propKey]
                    break
                default:
                    vertexValue = vertex[propKey]
                    break
            }

            if (value === undefined) {
                value = vertexValue
            } else if (value !== vertexValue) {
                return MIX_VALUE
            }
        }

        return value
    }

    /**
     * Get property value from the selected elements' style
     * @param {string} propKey property name
     * @param {Element[]} [elements]
     * @returns {any}
     */
    getProp(propKey, elements = this.selectedElements) {
        const data = {}
        for (const element of elements) {
            const elementType = element.get('elementType')
            if (elementType === ElementType.SCREEN &&
                !EI_SCREEN_ALLOWED_KEYS.includes(propKey)) {
                continue
            }

            const elementValue = CONTAINER_PROPERTY_SET.has(propKey) && !element.isContainer
                ? CONTAINER_PROPERTY_DEFAULT_VALUE[propKey]
                : element.get(propKey)
            if (elementType === ElementType.PATH && propKey === 'cornerRadius') {
                const geometryType = element.get('geometryType')
                if (geometryType === GeometryType.ELLIPSE) {
                    continue
                }
                if (geometryType === GeometryType.RECTANGLE && Array.isArray(elementValue)) {
                    for (const c of elementValue) {
                        if (!this._sameValue(data, 'value', c)) {
                            return MIX_VALUE
                        }
                    }
                    continue
                }
                if (geometryType === GeometryType.POLYGON) {
                    const vertices = element.get('geometry').get('mesh').vertices
                    for (const v of vertices) {
                        if (v.cornerRadius === null) {
                            continue
                        }
                        if (!this._sameValue(data, 'value', v.cornerRadius)) {
                            return MIX_VALUE
                        }
                    }
                }
            }
            if (propKey === 'contentAnchorX' || propKey === 'contentAnchorY') {
                const nextUnit = element.get(`${propKey}Unit`)
                if (!this._sameValue(data, 'unit', nextUnit)) {
                    return MIX_VALUE
                }

                const elData = element.gets('width', 'height', 'referencePointX', 'referencePointY')
                const originValue = propKey === 'contentAnchorX'
                    ? elData.referencePointX + elementValue - elData.width / 2
                    : elData.referencePointY + elementValue - elData.height / 2
                if (!this._sameValue(data, 'value', originValue)) {
                    return MIX_VALUE
                }
                continue
            }

            if (!this._sameValue(data, 'value', elementValue)) {
                return MIX_VALUE
            }
        }

        return data.value
    }

    _sameValue(out, key, next) {
        if (out[key] === undefined) {
            out[key] = next
            return true
        } else if (round(out[key], 2) !== round(next, 2)) {
            return false
        }
        return true
    }

    /**
     * Set property state to the interaction manager
     * @param {string} propKey property name
     * @param {any} state property state
     * @param {object} [options]
     * @param {bool} [options.commit]   set to false to not commit automatically
     */
    setPropState(propKey, state, { commit = true } = SET_PROP_OPTIONS) {
        if (!this.dataStore.isActionMode) {
            return
        }
        for (const element of this.selectedElements) {
            if (element.get('elementType') === ElementType.SCREEN &&
                propKey !== 'width' && propKey !== 'height') {
                continue
            }
            if (propKey === 'cornerRadius' &&
                element.get('elementType') === ElementType.PATH &&
                element.get('geometryType') === GeometryType.ELLIPSE) {
                continue
            }

            let key = propKey
            if (propKey === 'x' || propKey === 'y') {
                key = 'motionPath'
            } else if (propKey === 'contentAnchorX' || propKey === 'contentAnchorY') {
                key = 'contentAnchor'
            }

            if (existKeyFrame(state)) {
                this._updateIMPropState(element, key, FrameType[state])
            } else {
                this.dataStore.interaction.deleteKeyFrameByElementProp(element.get('id'), key)
            }
        }

        if (commit) {
            this.dataStore.commitUndo()
        }
    }

    _getEditableVertices(geometry) {
        const geometryType = geometry.get('geometryType')
        if ([GeometryType.ELLIPSE, GeometryType.RECTANGLE].includes(geometryType)) {
            return []
        }

        const mesh = geometry.get('mesh')
        return this.selectedVertices.length
            ? this.selectedVertices
            : Array.from(mesh.getVertices())
    }

    /**
     * Set vertex property value to the element's mesh
     * @param {string} propKey property name
     * @param {any} value property value
     * @param {object} options
     */
    setVertexProp(propKey, value, options) {
        const element = this.selectedElements[0]
        const geometry = element.get('geometry')

        const vertices = this._getEditableVertices(geometry)

        vertexId2Change.clear()
        for (const vertex of vertices) {
            vertexId2Change.set(vertex.id, { [propKey]: value })
        }

        geometry.execute('setVertex', vertexId2Change, options)
        if (!options || options.commit) {
            this.dataStore.commitUndo()
        }
    }

    /**
     * Get element data changes
     * @param {Element} element
     * @param {object} data
     * @param {boolean} delta
     * @returns {object}
     */
    _getElementDataChange(element, data, delta) {
        const newData = {}
        const elementType = element.get('elementType')
        const geometryType = elementType === ElementType.PATH ? element.get('geometryType') : undefined

        for (const propKey in data) {
            let newValue = data[propKey]
            if (delta) {
                newValue = element.get(propKey) + newValue
                if (propKey === 'opacity') {
                    newValue = minmax(newValue, 0, 1)
                }
            }

            const switchPropsKey = SWITCH_COMPONENT_PROPS[propKey]
            if (switchPropsKey) {
                newData[switchPropsKey] = element.get(switchPropsKey) + (newValue - element.get(propKey))
            } else {
                newData[propKey] = newValue
            }

            if (propKey === 'scaleX' || propKey === 'scaleY') {
                const syncPropKey = SYNC_PROP_KEY_MAP[propKey]
                const sync = element.get(PROP_KEY_MAP_SYNC_KEY[propKey])

                let isNewDataZero;
                if (typeof newData[propKey] === 'undefined') {
                    isNewDataZero = false;
                } else if (newData[propKey] === MIX_VALUE) {
                    isNewDataZero = false;
                }
                if (sync && !isNewDataZero) {
                    const propValue = element.get(propKey)
                    const syncPropValue = element.get(syncPropKey)
                    const ratio = (Math.abs(propValue) <= EPSILON || Math.abs(syncPropValue) <= EPSILON) ? 1 : propValue / syncPropValue
                    if (Math.abs(parseFloat(newData[propKey])) <= 0.01) {
                        newData[syncPropKey] = 0
                    } else {
                        newData[syncPropKey] = newData[propKey] / ratio
                    }
                }
            }

            if (propKey === 'width' || propKey === 'height') {
                const currValue = element.get(propKey)
                const newValue = validDimension(newData[propKey])
                const sync = element.get(PROP_KEY_MAP_SYNC_KEY[propKey])

                if (currValue > MIN_SIZE_VALUE || newValue !== MIN_SIZE_VALUE) {
                    newData[propKey] = newValue
                    const changeList = [[propKey, currValue, newValue]]
                    const syncPropKey = SYNC_PROP_KEY_MAP[propKey]
                    if (sync) {
                        // FIXME: reuse scale with min value for scale/dimensions
                        const currSyncValue = element.get(syncPropKey)
                        if (newValue <= MIN_SIZE_VALUE) {
                            newData[syncPropKey] = MIN_SIZE_VALUE
                        } else {
                            const ratio = (Math.abs(currValue) <= EPSILON || Math.abs(currSyncValue) <= EPSILON) ? 1 : currValue / currSyncValue
                            newData[syncPropKey] = newData[propKey] / ratio
                        }
                        newData[syncPropKey] = validDimension(newData[syncPropKey])
                        changeList.push([syncPropKey, currSyncValue, newData[syncPropKey]])
                    }

                    // TODO: better naming
                    for (const change of changeList) {
                        const [sizePropKey, currValue, newValue] = change
                        const syncPropKey = SYNC_PROP_KEY_MAP[propKey]

                        const proportionProps = PROPORTION_COMPONENT_PROPS[sizePropKey]
                        const origin = proportionProps.reduce((acc, propKey) => acc + element.get(propKey), 0) / Math.max(currValue, MIN_SIZE_VALUE)

                        const freezeContentAnchor = (element.get(syncPropKey) < MIN_SIZE_THRESHOLD || currValue < MIN_SIZE_THRESHOLD) && (origin < 0 || origin > 1)

                        if (Math.abs(currValue) < MIN_SIZE_THRESHOLD && element.canMorph) {
                            for (const proportionProp of proportionProps) {
                                const proportionPropValue = element.get(proportionProp)
                                newData[proportionProp] = (newData[propKey] / MIN_SIZE_VALUE) * proportionPropValue
                            }
                        } else {
                            for (const proportionProp of proportionProps) {
                                const proportionPropValue = element.get(proportionProp)
                                if (freezeContentAnchor && proportionProp.startsWith('contentAnchor')) {
                                    const after = newValue < MIN_SIZE_THRESHOLD ? 0 : newValue
                                    const before = currValue < MIN_SIZE_THRESHOLD ? 0 : currValue
                                    const refP = element.get(proportionProps[1])
                                    const vv = refP / Math.max(currValue, MIN_SIZE_VALUE)
                                    newData[proportionProp] = proportionPropValue - (after - before) * vv
                                } else {
                                    newData[proportionProp] = proportionPropValue * (newValue / Math.max(currValue, MIN_SIZE_VALUE))
                                }
                            }
                        }
                    }

                } else {
                    // skip update if curr value is 0 and new value is 0
                    delete newData[propKey]
                }
            }

            if (elementType === ElementType.SCREEN &&
                !EI_SCREEN_ALLOWED_KEYS.includes(propKey)) {
                continue
            }
            if (propKey === 'cornerRadius') {
                newData[propKey] = minmax(newValue, 0)
                if (elementType === ElementType.CONTAINER) {
                    continue
                }
                if (elementType !== ElementType.PATH) {
                    continue
                }
                if (geometryType === GeometryType.ELLIPSE) {
                    continue
                }
                // Need to reset all the vertices corner radius when set to element
                if (geometryType === GeometryType.POLYGON) {
                    const geometry = element.get('geometry')
                    const vertices = geometry.get('mesh').vertices
                    geometry.execute('startEditing')
                    for (const v of vertices) {
                        vertexId2Change.set(v.id, { cornerRadius: null })
                    }
                    geometry.execute('setVertex', vertexId2Change)
                    geometry.execute('stopEditing')
                }
            }

            if (propKey === 'contentAnchorX') {
                const elData = element.gets('width', 'referencePointX')
                newData[propKey] = data[propKey] - elData.referencePointX + elData.width / 2
            }
            if (propKey === 'contentAnchorY') {
                const elData = element.gets('height', 'referencePointY')
                newData[propKey] = data[propKey] - elData.referencePointY + elData.height / 2
            }

            // TODO: Might need to enable it in the future once we need to support percentage of origin with contentAnchor change
            // const size = element.get('size')
            // if (propKey === 'originXUnit' && element.get('originXUnit') !== newValue) {
            //     newData.originX = this._getOriginValueWithUnit(element, 'originX', newValue, size.width)
            // }
            // if (propKey === 'originYUnit' && element.get('originYUnit') !== newValue) {
            //     newData.originY = this._getOriginValueWithUnit(element, 'originY', newValue, size.height)
            // }
        }
        return newData
    }

    // TODO: Might need to enable it in the future once we need to support percentage of origin with contentAnchor change
    // /**
    //  * Get origin value with different unit type
    //  * @param {Element} element
    //  * @param {string} key
    //  * @param {Unit} unit
    //  * @param {number} size
    //  * @returns {number}
    //  */
    // _getOriginValueWithUnit(element, key, unit, size) {
    //     let originValue = element.get(key)

    //     switch (unit) {
    //         case Unit.PIXEL:
    //             originValue = size * originValue * 0.01
    //             break
    //         case Unit.PERCENT:
    //             originValue = (originValue / size) * 100
    //             break
    //     }
    //     return originValue
    // }

    setMotionPathProps(data, refPoint = null, { commit = true, delta = false } = SET_PROP_OPTIONS) {
        this.dataStore.startTransaction()

        if (Object.keys(data).length === 1 && data.mirror !== undefined) {
            this.dataStore.drawInfo.updateMotionPathPointShape(data.mirror, {commit, delta})
            this.dataStore.endTransaction()
            return
        }

        const element = this.selectedElements[0]
        const base = element.getBaseValue('translate')
        const fromPointShapeBtn = data.mirror !== undefined

        const motionPoints = this.dataStore.selection.get('motionPoints')
        /** @typedef {{type: string, key: string, data: Vector2}} */
        const targetPointData = refPoint ? refPoint : motionPoints[0] // 
        const keyFrame = this.dataStore.interaction.getKeyFrame(targetPointData.key)
        /** @typedef {{pos: Vector2, mirror: number, in: Vector2, out: Vector2}} */
        const selectedPoint = getMotionPointValue(keyFrame)
        let dx = delta ? data.x || 0 : 0
        let dy = delta ? data.y || 0 : 0

        switch (targetPointData.type) {
            case 'point':
                if (!delta) {
                    if (data.x !== undefined) {
                        dx = data.x - base.translateX - selectedPoint.pos[0]
                    }
                    if (data.y !== undefined) {
                        dy = data.y - base.translateY - selectedPoint.pos[1]
                    }
                }
                selectedPoint.pos = [selectedPoint.pos[0] + dx, selectedPoint.pos[1] + dy]
                break
            case 'in':
            case 'out':
                if (!delta) {
                    if (data.x !== undefined) {
                        dx = data.x - base.translateX - selectedPoint.pos[0] - selectedPoint[targetPointData.type][0]
                    }
                    if (data.y !== undefined) {
                        dy = data.y - base.translateY - selectedPoint.pos[1] - selectedPoint[targetPointData.type][1]
                    }
                }
                selectedPoint[targetPointData.type] = [selectedPoint[targetPointData.type][0] + dx, selectedPoint[targetPointData.type][1] + dy]
                break
        }

        if (fromPointShapeBtn && selectedPoint.mirror !== data.mirror) {
            selectedPoint.mirror = data.mirror
        }

        this.dataStore.interaction.setKeyFrameValue(targetPointData.key, selectedPoint)


        motionPoints.forEach(({ key, type }) => {
            const oppositeType = type === 'in' ? 'out' : 'in'
            if (key === targetPointData.key && type === targetPointData.type) {
                // if the targetPoint opposite curve control is not in the selection, we need to update it here
                const isOppositeControlSelected = motionPoints.find(p => p.type === oppositeType && p.key === key)
                if (targetPointData.type !== 'point' && !isOppositeControlSelected) {
                    this.dataStore.drawInfo.updateMotionPointCurveControl(selectedPoint, type)
                }
                return
            }

            const keyFrame = this.dataStore.interaction.getKeyFrame(key)
            const point = getMotionPointValue(keyFrame)

            if (!fromPointShapeBtn) {
                switch (type) {
                    case 'point':
                        point.pos = [point.pos[0] + dx, point.pos[1] + dy]
                        break
                    case 'in':
                    case 'out': {
                        point[type] = [point[type][0] + dx, point[type][1] + dy]
                        if (motionPoints.find(p => p.type === oppositeType && p.key === key)) {
                            // if the opposite curve control is selected, we need to set point shape to independent
                            point.mirror = PointShape.INDEPENDENT
                        } else if (key !== targetPointData.key) {
                            // if the opposite curve control is not selected, we need to update here
                            this.dataStore.drawInfo.updateMotionPointCurveControl(point, type)
                        }
                    }
                }
            }

            this.dataStore.interaction.setKeyFrameValue(key, point)
        })


        if (commit) {
            this.dataStore.commitUndo()
        }

        this.dataStore.endTransaction()

    }
    /**
     * Set property value to the elements' style
     * @param {object} data
     * @param {string} data.name property name
     * @param {*} data.value property value
     * @param {object} [options]
     * @param {bool} [options.commit]   set to false to not commit automatically
     * @param {bool} [options.delta]   set to true to update value relative
     */
    setProps(data, { commit = true, delta = false } = SET_PROP_OPTIONS) {
        this.dataStore.startTransaction()

        this.selectedElements.forEach(element => {
            const changes = this._getElementDataChange(element, data, delta)

            // Freeze elements
            if (delta || shouldFreezeElement(changes)) {
                const refP = element.isComputedGroup ? element.get('referencePoint') : undefined
                const newTranslate = this.dataStore.drawInfo.getFixedPositionByChanges(element.get('id'), changes, refP)
                if (newTranslate) {
                    changes.translateX = newTranslate.x
                    changes.translateY = newTranslate.y
                }
            }
            element.sets(changes)
        })

        this.dataStore.endTransaction()

        // If changed property is element property, we fire editor change event back to UI directly
        const changedElementPropKeys = Object.keys(data).filter(propKey => ELEMENT_PROPERTY_SET.has(propKey))
        if (changedElementPropKeys.length) {
            this.emitChanges(new EditorChanges().addProps(changedElementPropKeys))
        }

        if (commit) {
            this.dataStore.commitUndo()
        }
    }

    /**
     * Layer item list getter
     * @param {Map} map         existing layer map
     * @param {LayerItem} layerItem   new layer item to match
     * @returns {LayerItem | null}  matched layer item or null
     */
    _findIdenticalLayerItem(map, layerItem) {
        for (const item of map.values()) {
            if (item.hash === layerItem.hash) {
                return item
            }
        }
        return null
    }

    /**
     * Effect item list getter
     * @param {Map} map         existing effect map
     * @param {EffectItem} effectItem   new effect item to match
     * @returns {EffectItem | null}  matched effect item or null
     */
    _findIdenticalEffectItem(map, effectItem) {
        for (const item of map.values()) {
            if (item.hash === effectItem.hash) {
                return item
            }
        }
        return null
    }

    _resetLayerItemMap(layerKeys = LayerListKeyList) {
        layerKeys.forEach(layerKey => {
            if (this.selectedElements.length === 0) {
                this._layerItemMap[layerKey] = new Map()
                return
            }

            let layerItemMap
            for (const element of this.selectedElements) {
                if (element.isContainer && (element.isNormalGroup || element.isMaskGroup())) {
                    continue
                }

                const cls = [...element.computedStyle[layerKey]]
                if (!layerItemMap) {
                    layerItemMap = this._createLayerItemMap(cls)
                    continue
                }

                if (layerItemMap.size !== cls.length) {
                    this._layerItemMap[layerKey] = new Map([[MIX_VALUE_ID, new LayerItem(MIX_VALUE)]])
                    return
                }
                for (let i = 0; i < cls.length; i++) {
                    const layerItem = new LayerItem(cls[i], i)
                    const matchedItem = this._findIdenticalLayerItem(layerItemMap, layerItem)
                    if (matchedItem) {
                        matchedItem.computedLayerSet.add(cls[i])
                    } else {
                        this._layerItemMap[layerKey] = new Map([[MIX_VALUE_ID, new LayerItem(MIX_VALUE)]])
                        return
                    }
                }
            }
            this._layerItemMap[layerKey] = layerItemMap
        })
    }

    _createLayerItemMap(cls) {
        const layerItemMap = new Map()
        for (let i = 0; i < cls.length; i++) {
            const layerItem = new LayerItem(cls[i], i)
            layerItemMap.set(layerItem.id, layerItem)
        }
        return layerItemMap
    }

    _resetEffectItemMap() {
        if (this.selectedElements.length === 0) {
            this._effectItemMap = new Map()
            return
        }

        let effectItemMap
        for (const e of this.selectedElements) {
            const ces = [...e.computedStyle.effects]
            if (!effectItemMap) {
                effectItemMap = this._createEffectItemMap(ces)
                continue
            }

            if (effectItemMap.size !== ces.length) {
                this._effectItemMap = new Map([[MIX_VALUE_ID, new EffectItem(MIX_VALUE)]])
                return
            }
            for (let i = 0; i < ces.length; i++) {
                const effectItem = new EffectItem(ces[i], i)
                const matchedItem = this._findIdenticalEffectItem(effectItemMap, effectItem)
                if (matchedItem) {
                    if (canElementApplyEffect(e, matchedItem.effectType)) {
                        matchedItem.computedEffectSet.add(ces[i])
                    }
                } else {
                    this._effectItemMap = new Map([[MIX_VALUE_ID, new EffectItem(MIX_VALUE)]])
                    return
                }
            }
        }
        this._effectItemMap = effectItemMap
    }

    _createEffectItemMap(ces) {
        const effectItemMap = new Map()
        for (let i = 0; i < ces.length; i++) {
            const effectItem = new EffectItem(ces[i], i)
            effectItemMap.set(effectItem.id, effectItem)
        }
        return effectItemMap
    }

    get fillList() {
        const layerItemMap = this._layerItemMap.fills
        if (!layerItemMap) {
            return []
        }
        return [...layerItemMap.keys()]
    }

    get strokeList() {
        const layerItemMap = this._layerItemMap.strokes
        if (!layerItemMap) {
            return []
        }
        return [...layerItemMap.keys()]
    }

    get shadowList() {
        const layerItemMap = this._layerItemMap.shadows
        if (!layerItemMap) {
            return []
        }
        return [...layerItemMap.keys()]
    }

    get innerShadowList() {
        const layerItemMap = this._layerItemMap.innerShadows
        if (!layerItemMap) {
            return []
        }
        return [...layerItemMap.keys()]
    }

    get effectList() {
        if (!this._effectItemMap) {
            return []
        }
        return [...this._effectItemMap.keys()]
    }

    /** Repeatable properties add/delete and getter/setter */

    /**
     * Add layers to the selected elements and create a layerItem
     * @param {LayerType} layerType
     */
    addLayer(layerType) {
        const layerItemIds = this[LAYER_TYPE_LIST_MAP[layerType]]
        const isMix = layerItemIds.length === 1 && layerItemIds[0] === `MIX_VALUE`
        const index = isMix ? 0 : layerItemIds.length

        // Add layer to the elements
        this.dataStore.startTransaction()
        this.selectedElements.forEach(element => {
            if (element.isContainer && (element.isNormalGroup || element.isMaskGroup())) {
                return
            }

            const lcId = element.base[LayerTypeMapLayerListKey[layerType]][0]
            if (isMix) {
                const lc = this.dataStore.library.getComponent(lcId)
                lc.layers.forEach(layerId => this.dataStore.library.deleteLayer(layerId))
            }
            if (this.dataStore.isActionMode) {
                this.dataStore.interaction.addLayer(element.get('id'), layerType)
            } else {
                this.dataStore.library.addLayer(lcId, index)
            }
        })
        this.dataStore.endTransaction()
    }

    deleteMotionPoints() {
        const motionPoints = this.dataStore.selection.get('motionPoints')
        this.dataStore.startTransaction()
        this.dataStore.selection.clearMotionPoints(NO_COMMIT)
        motionPoints.forEach(({key, type}) => {
            const keyFrame = this.dataStore.interaction.getKeyFrame(key)
            if (!keyFrame) {
                return
            }
            const point = { ...keyFrame.value }
            switch (type) {
                case 'point':
                    this.dataStore.interaction.deleteKeyFrame(key)
                    break
                case 'in':
                case 'out':
                    point[type] = [0, 0]
                    point.mirror = PointShape.INDEPENDENT
                    this.dataStore.interaction.setKeyFrameValue(key, point)
                    break
            }
        })
        this.dataStore.endTransaction()
        this.dataStore.commitUndo()
    }

    /**
     * Delete layerItem and layers that are referenced in layerItem
     * @param {string} id LayerItemId
     */
    deleteLayer(id) {
        const layerItem = this.getLayerItem(id)
        if (layerItem) {
            this.dataStore.startTransaction()
            layerItem.computedLayerSet.forEach(cl => {
                // only remove for non-base layers
                if (this.dataStore.isActionMode) {
                    if (cl.isNonBase) {
                        // in action mode can only delete non-base layers by their trackId
                        this.dataStore.interaction.deleteLayer(cl.element.get('id'), cl.get('trackId'))
                    }
                } else {
                    this.dataStore.selection.set('focusedLayer', null)
                    // in action mode can only delete base layers by their layerId
                    const layerId = cl.get('layerId')
                    this.dataStore.interaction.deleteLayer(cl.element.get('id'), layerId)
                    this.dataStore.library.deleteLayer(layerId)
                }
            })
            this.dataStore.endTransaction()
        }
        this.dataStore.eam.closeModal()
        this.dataStore.commitUndo()
    }

    getLayerItem(id) {
        for (const layerKey of LayerListKeyList) {
            if (this._layerItemMap[layerKey]?.has(id)) {
                return this._layerItemMap[layerKey].get(id)
            }
        }
        return null
    }

    isLayerPropState(id, propKey) {
        const layerItem = this.getLayerItem(id)
        return LAYER_PROP_STATE_NAME_MAP[layerItem.layerType].has(propKey)
    }

    isEffectPropState(id, propKey) {
        const effectItem = this.getEffectItem(id)
        return EFFECT_PROP_STATE_NAME_MAP[effectItem.effectType].has(propKey)
    }

    /**
     * Get layer property state from interaction manager
     * @param {string} id LayerItemId or MIX_VALUE
     * @param {string} propStateKey property name
     * @returns {any}
     */
    getLayerPropState(id, propStateKey) {
        if (this.dataStore.isActionMode && id !== MIX_VALUE_ID) {
            const layerItem = this.getLayerItem(id)
            const propKey = LAYER_PROP_STATE_NAME_MAP[layerItem.layerType].get(propStateKey)
            if (!propKey) return PROP_STATES.DEFAULT

            let state = PROP_STATES.TWEEN
            for (const cl of [...layerItem.computedLayerSet]) {
                // TODO: Wait for getElementKeyframeState update
                const elementId = cl.get('elementId')
                const idKey = cl.get('layerId') || cl.get('trackId')
                const layerListKey = LayerTypeMapLayerListKey[cl.get('layerType')]
                const s = this.dataStore.transition.getElementKeyframeState(elementId, propKey, layerListKey, idKey)
                if (s === FrameType.EXPLICIT) {
                    return PROP_STATES.EXPLICIT
                } else if (s === FrameType.INITIAL) {
                    state = PROP_STATES.INITIAL
                }
            }
            return state
        }
        return PROP_STATES.DEFAULT
    }

    /**
     * Get property value from the layerItem
     * @param {string} id LayerItemId
     * @param {string} propKey property name
     * @param {bool} copy  set to true to get a copy of the prop value from corresponding CL
     * @returns {any}
     */
    getLayerProp(id, propKey, copy = false) {
        if (id === MIX_VALUE_ID) {
            return null
        }

        const layerItem = this.getLayerItem(id)
        if (!layerItem) {
            return null
        }

        if (propKey === 'isNonBase') {
            return layerItem.isNonBase
        }

        if (!EI_LAYER_ALLOWED_KEYS_SET[layerItem.layerType].has(propKey)) {
            throw new Error(`Invalid property: ${propKey}`)
        }

        const [cl] = layerItem.computedLayerSet
        const val = cl.get(propKey, copy)
        return isVec2(val) || isVec4(val) ? [...val] : val
    }

    /**
     * Set property state to interaction manager
     * @param {string} id LayerItemId
     * @param {string} propKey property name
     * @param {any} state property state value
     * @param {object} [options]
     * @param {bool} [options.commit]   set to false to not commit automatically
     */
    setLayerPropState(id, propKey, state, { commit = true } = SET_PROP_OPTIONS) {
        if (!this.dataStore.isActionMode) {
            return
        }

        const layerItem = this.getLayerItem(id)
        layerItem.computedLayerSet.forEach(cl => {
            const elementId = cl.get('elementId')
            const meta = cl.gets('layerId', 'trackId', 'paintType', 'layerType')
            const layerKey = meta.layerId || meta.trackId
            if (existKeyFrame(state)) {
                if (propKey === 'paint') {
                    // TODO: Need to get the complete paint data for set layer?
                    const layerProps = meta.layerType === LayerType.SHADOW || meta.layerType === LayerType.INNER_SHADOW
                        ? ['color', 'paintType']
                        : ['color', 'gradientStops', 'gradientTransform', 'paintType']
                    const layerPropDataMap = new Map(layerProps.map(prop => {
                        const value = cl.get(prop)
                        const result = [prop]
                        switch (prop) {
                            case 'color':
                                result.push(new Vector4(value))
                                break
                            case 'gradientStops':
                                result.push(value.map(c => new ColorStop(c)))
                                break
                            case 'gradientTransform':
                                result.push(value.clone())
                                break
                            default:
                                result.push(value)
                        }
                        return result
                    }))
                    // Wrap with transaction because it will also include paint component creation/updates
                    this.dataStore.startTransaction()
                    layerPropDataMap.forEach((data, prop) => {
                        this.dataStore.interaction.setLayer(elementId, layerKey, prop, data, meta, FrameType[state])
                    })
                    this.dataStore.endTransaction()
                } else {
                    const value = cl.get(propKey)
                    this.dataStore.interaction.setLayer(elementId, layerKey, propKey, value, meta, FrameType[state])
                }
            } else {
                this.dataStore.interaction.deleteKeyFrameByElementProp(elementId, propKey, layerKey)
            }
        })

        if (commit) {
            this.dataStore.commitUndo()
        }
    }

    /**
     * Set property value to the layerItem and computedLayer
     * @param {string} id LayerItemId
     * @param {string} propKey property name
     * @param {any} value property value
     * @param {object} [options]
     * @param {bool} [options.commit]   set to false to not commit automatically
     */
    setLayerProp(id, propKey, value, { commit = true } = SET_PROP_OPTIONS) {
        this.setLayerProps(id, { [propKey]: value }, { commit })
    }

    /**
     * Set multiple properties value to the layerItem and computedLayer
     * @param {string} id LayerItemId
     * @param {object} data
     * @param {string} data.propKey
     * @param {*} data.value
     * @param {object} [options]
     * @param {bool} [options.commit]   set to false to not commit automatically
     */
    setLayerProps(id, data, { commit = true } = SET_PROP_OPTIONS) {
        if (id === MIX_VALUE_ID) return

        const layerItem = this.getLayerItem(id)
        console.log('layerItem', id, layerItem)
        if (!layerItem) {
            throw new Error(`Invalid layer ID ${id}`)
        }
        const propKeys = Object.keys(data)
        propKeys.forEach((propKey) => {
            if (!EI_LAYER_ALLOWED_KEYS_SET[layerItem.layerType].has(propKey)) {
                throw new Error(`Invalid property: ${propKey}`)
            }
        })

        this.dataStore.startTransaction()
        layerItem.computedLayerSet.forEach(cl => {
            const changes = {}
            propKeys.forEach((propKey) => {
                changes[propKey] = data[propKey]
                if (propKey === 'paintType') {
                    this.switchLayerPaintType(cl, data[propKey], changes)
                }
            })
            // force update all the props here is because imageId and imageMode are paired props
            // and we need to update both of them if one of them is changed
            cl.sets(changes, { ...NOT_UNDOABLE, force: true })
        })
        this.dataStore.endTransaction()

        if (commit) {
            this.dataStore.commitUndo()
        }
    }

    switchLayerPaintType(cl, paintType, changes) {
        const original = cl.get('paintType')
        const layerKey = cl.get('layerId') || cl.get('trackId')

        switch (paintType) {
            case PaintType.SOLID: {
                if (this.dataStore.isActionMode && original === PaintType.IMAGE) {
                    this.dataStore.interaction.deleteElementPropertyTrack(cl.element.get('id'), `${layerKey}.paint`)
                } else if (original !== PaintType.IMAGE) {
                    const color = cl.get('gradientStops')[0].color
                    changes.color = [...color]
                    changes.opacity = color[3]
                }
                break
            }
            case PaintType.GRADIENT_LINEAR:
            case PaintType.GRADIENT_RADIAL:
            case PaintType.GRADIENT_ANGULAR:
            case PaintType.GRADIENT_DIAMOND: {
                if (this.dataStore.isActionMode && original === PaintType.IMAGE) {
                    this.dataStore.interaction.deleteElementPropertyTrack(cl.element.get('id'), `${layerKey}.paint`)
                    // when change from image to gradient, the activeGradientStopIdx might not be 0
                    cl.set('activeGradientStopIdx', 0)
                } else if (original === PaintType.SOLID) {
                    const [r, g, b] = cl.get('color')
                    changes.gradientStops = [
                        { color: [r, g, b, 1], position: 0 },
                        { color: [r, g, b, 0], position: 1 }
                    ]
                    changes.opacity = 1
                }
                break
            }
            case PaintType.IMAGE: {
                changes.imageId = cl.get('imageId')
                if (this.dataStore.isActionMode) {
                    this.dataStore.interaction.deleteElementPropertyTrack(cl.element.get('id'), `${layerKey}.paint`)
                }
                break
            }
        }
    }

    setLayerFocused(id, focused) {
        if (!focused) {
            this.dataStore.selection.set('focusedLayer', null)
            return
        }

        // TODO: only set first layer in the selection
        //       Need to revisit in selection API
        const layerItem = this.getLayerItem(id)
        if (!layerItem) {
            throw new Error(`Invalid layer ID ${id}`)
        }
        this.dataStore.selection.set('focusedLayer', id)
    }

    getOrigin9PatchProp() {
        const data = { contentAnchorPercentX: undefined, contentAnchorPercentY: undefined }
        for (const element of this.selectedElements) {
            // screen doesn't have geometryType
            const elementType = element.get('elementType')
            if (elementType === ElementType.SCREEN) {
                continue
            }

            const {
                width,
                height,
                contentAnchorX,
                contentAnchorY,
                contentAnchorXUnit,
                contentAnchorYUnit,
                referencePointX,
                referencePointY,
                geometryType
            } = element.gets(
                'width',
                'height',
                'contentAnchorX',
                'contentAnchorY',
                'contentAnchorXUnit',
                'contentAnchorYUnit',
                'referencePointX',
                'referencePointY',
                'geometryType'
            )
            // TBD: Need to find a way to get origin position visually from center when content anchor unit is PERCENT in the future.
            let contentAnchorPercentX = parseToDecimals(contentAnchorX, 3)
            if (contentAnchorXUnit === Unit.PIXEL) {
                // true 0-size. Doesn't have width or height for division.
                if (geometryType === GeometryType.LINE && width === 0) {
                    contentAnchorPercentX = Math.round((referencePointX + contentAnchorX) * 100)
                } else {
                    contentAnchorPercentX = parseToDecimals(((referencePointX + contentAnchorX - width / 2) / width) * 100, 3)
                }
            }

            let contentAnchorPercentY = parseToDecimals(contentAnchorY, 3)
            if (contentAnchorYUnit === Unit.PIXEL) {
                if (geometryType === GeometryType.LINE && height === 0) {
                    contentAnchorPercentY = Math.round((referencePointY + contentAnchorY) * 100)
                } else {
                    contentAnchorPercentY = parseToDecimals(((referencePointY + contentAnchorY - height / 2) / height) * 100, 3)
                }
            }
            data.contentAnchorPercentX = contentAnchorPercentX
            data.contentAnchorPercentY = contentAnchorPercentY

            if (!this._sameValue(data, 'contentAnchorPercentX', contentAnchorPercentX)) {
                data.contentAnchorPercentX = MIX_VALUE
            }
            if (!this._sameValue(data, 'contentAnchorPercentY', contentAnchorPercentY)) {
                data.contentAnchorPercentY = MIX_VALUE
            }
        }

        return data
    }

    /**
     * Set origin by 9-patch
     * @param {object} contentAnchorPosition
     * @param {number} [contentAnchorPosition.contentAnchorX]
     * @param {number} [contentAnchorPosition.contentAnchorY]
     */
    setOrigin9Patch(contentAnchorPosition) {
        this.dataStore.startTransaction()
        for (const element of this.selectedElements) {
            // screen doesn't have geometryType
            const elementType = element.get('elementType')
            if (elementType === ElementType.SCREEN) {
                continue
            }

            // contentAnchorXUnit = contentAnchorYUnit, so we only need to get contentAnchorXUnit here
            const contentAnchorUnit = element.get('contentAnchorXUnit')
            const changes = { ...contentAnchorPosition }
            if (contentAnchorUnit === Unit.PIXEL) {
                const {
                    width,
                    height,
                    referencePointX,
                    referencePointY,
                    geometryType
                } = element.gets('width', 'height', 'referencePointX', 'referencePointY', 'geometryType')

                changes.contentAnchorX =
                    // true 0-size or others
                    geometryType === GeometryType.LINE && width === 0 ?
                        contentAnchorPosition.contentAnchorX * 0.01 - referencePointX / 2 :
                        contentAnchorPosition.contentAnchorX * width * 0.01 - referencePointX + width / 2
                changes.contentAnchorY =
                    geometryType === GeometryType.LINE && height === 0 ?
                        contentAnchorPosition.contentAnchorY * 0.01 - referencePointY / 2 :
                        contentAnchorPosition.contentAnchorY * height * 0.01 - referencePointY + height / 2
            }

            element.sets(changes)

            // Freeze element
            const newTranslate = this.dataStore.drawInfo.getFixedPositionByChanges(element.get('id'), changes)
            if (newTranslate) {
                element.sets({
                    translateX: newTranslate.x,
                    translateY: newTranslate.y
                })
            }
        }
        this.dataStore.endTransaction()
        this.dataStore.commitUndo()
    }

    /**
     * Get effectItem
     * @param {string} effectItemId
     * @returns {EffectItem}
     */
    getEffectItem(effectItemId) {
        return this._effectItemMap.get(effectItemId)
    }

    /**
     * Add a new effect
     * @param {EffectType} effectType
     */
    addEffect(effectType) {
        const effectItemIds = this.effectList
        const isMix = effectItemIds.length === 1 && effectItemIds[0] === `MIX_VALUE`
        const index = isMix ? 0 : effectItemIds.length

        // Not allow to add a new effect if effect list is mixed.
        if (isMix) {
            return
        }

        // Add effect to the elements
        this.dataStore.startTransaction()
        this.selectedElements.forEach(element => {
            if (canElementApplyEffect(element, effectType)) {
                this.dataStore.library.addEffect(element.base.effects[0], index, { effectType })
            }
        })
        this.dataStore.endTransaction()
        this.dataStore.commitUndo()
    }

    /**
     * Get effect property state from interaction manager
     * @param {string} id EffectItemId or MIX_VALUE
     * @param {string} propStateKey property name
     * @returns {any}
     */
    getEffectPropState(id, propStateKey) {
        if (this.dataStore.isActionMode && id !== MIX_VALUE_ID) {
            const effectItem = this.getEffectItem(id)
            const propKey = EFFECT_PROP_STATE_NAME_MAP[effectItem.effectType].get(propStateKey)
            if (!propKey) return PROP_STATES.DEFAULT

            let state = PROP_STATES.TWEEN
            for (const ce of [...effectItem.computedEffectSet]) {
                const elementId = ce.get('elementId')
                const idKey = ce.get('id')
                const s = this.dataStore.transition.getElementKeyframeState(elementId, propKey, 'effects', idKey)
                if (s === FrameType.EXPLICIT) {
                    return PROP_STATES.EXPLICIT
                } else if (s === FrameType.INITIAL) {
                    state = PROP_STATES.INITIAL
                }
            }
            return state
        }
        return PROP_STATES.DEFAULT
    }

    /**
     * Get effect property
     * @param {string} effectItemId
     * @param {string} propKey
     * @returns {IS_MIX | any | null}
     */
    getEffectProp(effectItemId, propKey) {
        if (effectItemId === MIX_VALUE_ID) {
            if (propKey === IS_MIX) {
                return true
            }
            return null
        }

        const effectItem = this.getEffectItem(effectItemId)
        if (!effectItem) {
            return null
        }

        if (propKey === IS_MIX) {
            return effectItem.isMix
        }

        const [ce] = effectItem.computedEffectSet
        return ce.get(propKey)
    }

    /**
     * Set property value to the effectItem and computedEffect
     * @param {string} effectItemId
     * @param {object} data
     * @param {object} [options]
     * @param {bool} [options.commit]   set to false to not commit automatically
     */
    setEffectProps(effectItemId, data, { commit = true } = SET_PROP_OPTIONS) {
        const effectItem = this.getEffectItem(effectItemId)
        if (!effectItem) {
            throw new Error(`Invalid effectItem ID ${effectItemId}`)
        }
        for (const propKey in data) {
            if (!EI_EFFECT_ALLOWED_KEYS_SET[effectItem.effectType].has(propKey)) {
                throw new Error(`Invalid property: ${propKey}`)
            }
        }

        this.dataStore.startTransaction()
        effectItem.computedEffectSet.forEach(ce => {
            ce.sets(data, NOT_UNDOABLE)
        })
        this.dataStore.endTransaction()

        if (commit) {
            this.dataStore.commitUndo()
        }
    }

    /**
     * Delete effect
     * @param {string} effectItemId
     */
    deleteEffect(effectItemId) {
        const effectItem = this.getEffectItem(effectItemId)
        if (!effectItem) {
            return
        }

        this.dataStore.startTransaction()
        effectItem.computedEffectSet.forEach(ce => {
            const effectId = ce.get('effectId')
            this.dataStore.interaction.deleteEffect(ce.element.get('id'), effectId)
            this.dataStore.library.deleteEffect(effectId)
        })
        this.dataStore.endTransaction()
        this.dataStore.commitUndo()
    }

    /**
     * Set property state to interaction manager
     * @param {string} id EffectItemId
     * @param {string} propKey property name
     * @param {any} state property state value
     * @param {object} [options]
     * @param {bool} [options.commit]   set to false to not commit automatically
     */
    setEffectPropState(id, propKey, state, { commit = true } = SET_PROP_OPTIONS) {
        if (!this.dataStore.isActionMode) {
            return
        }

        const effectItem = this.getEffectItem(id)
        effectItem.computedEffectSet.forEach(ce => {
            const { elementId, effectId, effectType } = ce.gets('elementId', 'effectId', 'effectType')
            if (existKeyFrame(state)) {
                const value = ce.get(propKey)
                this.dataStore.interaction.setEffect(elementId, effectId, propKey, value, FrameType[state])
            } else {
                this.dataStore.interaction.deleteKeyFrameByElementProp(elementId, propKey, EFFECT_TYPE_NAME_MAP[effectType])
            }
        })

        if (commit) {
            this.dataStore.commitUndo()
        }
    }

    changeContainerType(newContainerType) {
        this.dataStore.startTransaction()
        for (const element of this.selectedElements) {
            if (element.isContainer) {
                element.changeContainerType(newContainerType)
            }
        }
        this.dataStore.endTransaction()
        this.dataStore.commitUndo()
    }

    convertToPath() {
        this.dataStore.eam.activateShapeMode()
    }
}
