import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { AlignType, DistributeType } from '@phase-software/types'

import { useEditor, useEditorActions } from '../../../providers/dataStore/EditorProvider'
import { IconButton } from '../../shared'

const TOOLS = {
  H_ALIGN: [
    {
      tip: 'align_left',
      shortcut: '⌥A',
      name: 'HAlignLeft',
      dir: 'LEFT'
    },
    {
      tip: 'align_horizontal_center',
      shortcut: '⌥H',
      name: 'HAlignCenter',
      dir: 'CENTER'
    },
    {
      tip: 'align_right',
      shortcut: '⌥D',
      name: 'HAlignRight',
      dir: 'RIGHT'
    }
  ],
  V_ALIGN: [
    {
      tip: 'align_top',
      shortcut: '⌥W',
      name: 'VAlignTop',
      dir: 'TOP'
    },
    {
      tip: 'align_vertical_middle',
      shortcut: '⌥V',
      name: 'VAlignMiddle',
      dir: 'MIDDLE'
    },
    {
      tip: 'align_bottom',
      shortcut: '⌥S',
      name: 'VAlignBottom',
      dir: 'BOTTOM'
    }
  ],
  DIST: [
    {
      tip: 'distribute_horizontally',
      shortcut: '⌃⌥H',
      name: 'HSpacing',
      dir: 'HORIZONTAL'
    },
    {
      tip: 'distribute_vertically',
      shortcut: '⌃⌥V',
      name: 'VSpacing',
      dir: 'VERTICAL'
    }
  ]
}

type ToolProps = {
  tip: string
  shortcut: string
  name: string
  dir: string
  disabled: boolean
  className?: string
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
}

type ToolListProps = {
  children: ReactNode
  className?: string
}

const Tool = ({ tip, shortcut, name, dir, onClick, disabled, className = '' }: ToolProps) => {
  const { t } = useTranslation('file', { keyPrefix: 'property_editor.layout_tool' })
  const combinedTip = `${t(tip)} (${shortcut})`
  return (
    <IconButton
      className={className}
      tip={combinedTip}
      icon={name}
      size="l"
      onClick={onClick}
      data-dir={dir}
      data-test-id={name}
      disabled={disabled}
    />
  )
}

const ToolList = ({ children, className = '' }: ToolListProps) => {
  return <div className={`flex gap-4 ${className}`}>{children}</div>
}

const AlignmentTool = () => {
  const enable = useEditor((o) => o.alignment)
  const { setAlignment } = useEditorActions()

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const dir = e.currentTarget.dataset.dir as keyof typeof AlignType
    setAlignment(AlignType[dir])
  }

  return (
    <>
      <ToolList key="horizontal">
        {TOOLS.H_ALIGN.map((tool, index) => (
          <Tool key={index} {...tool} className="!w-24 !h-24" onClick={handleClick} disabled={!enable} />
        ))}
      </ToolList>
      <ToolList key="vertical">
        {TOOLS.V_ALIGN.map((tool, index) => (
          <Tool key={index} {...tool} className="!w-24 !h-24" onClick={handleClick} disabled={!enable} />
        ))}
      </ToolList>
    </>
  )
}

const DistributionTool = () => {
  const enable = useEditor((o) => o.distortion)
  const { setDistribution } = useEditorActions()

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const dir = e.currentTarget.dataset.dir as keyof typeof DistributeType
    setDistribution(DistributeType[dir])
  }

  return (
    <ToolList>
      {TOOLS.DIST.map((tool, index) => (
        <Tool key={index} {...tool} className="!w-24 !h-24" onClick={handleClick} disabled={!enable} />
      ))}
    </ToolList>
  )
}

const LayoutTool = () => {
  return (
    <div className="grid grid-flow-col h-40 px-12 gap-6 items-center">
      <AlignmentTool />
      <DistributionTool />
    </div>
  )
}

export default LayoutTool
