export const ShareTypeEnum = {
  projectFile: 'project',
  draftFile: 'team',
  project: 'team'
} as const

export type ShareTypeEnum = keyof typeof ShareTypeEnum

export enum ProjectFilePermissionEnum {
  CAN_ACCESS = 'CAN_ACCESS',
  REVIEW_ONLY = 'REVIEW_ONLY',
  NO_ACCESS = 'NO_ACCESS'
}
export enum DraftFilePermissionEnum {
  CAN_EDIT = 'CAN_EDIT',
  CAN_REVIEW = 'CAN_REVIEW',
  NO_ACCESS = 'NO_ACCESS'
}

export enum ProjectPermissionEnum {
  CAN_EDIT = 'CAN_EDIT',
  CAN_REVIEW = 'CAN_REVIEW',
  NO_ACCESS = 'NO_ACCESS'
}

export type FilePermissionEnum = ProjectFilePermissionEnum | DraftFilePermissionEnum

export type AllPermissionEnum = ProjectPermissionEnum | ProjectFilePermissionEnum | DraftFilePermissionEnum

export enum CollaboratorPermission {
  CAN_EDIT = 'CAN_EDIT',
  CAN_REVIEW = 'CAN_REVIEW'
}

export enum CollaboratorAction {
  LEAVE = 'LEAVE',
  REMOVE = 'REMOVE'
}

export enum InvitationAction {
  RESEND = 'RESEND',
  CANCEL = 'CANCEL'
}
