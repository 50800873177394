import {
    CMP_EPSILON,
    CURVETIME_EPSILON,
    EPSILON,
    GEOMETRIC_EPSILON,
    Rect2,
    Vector2,
} from "../../math"
import { CubicBezTypes } from "../utils"
import Numerical from "./utils/Numerical"
import { findCurveBoundsCollisions } from "./utils/CollisionDetection"
import {
    CuspTypes,
    solveItp,
    getOverlaps,
} from "./common"
import { Segment } from "./Segment"
import { Line } from "./Line"
import { QuadBez } from "./QuadBez"
import { CurveLocation } from "./BezierShape"

/** @typedef {import("../../math/Vector2").Vector2Like} Vector2Like */
/** @typedef {import("./common").CurveData} CurveData */
/** @typedef {import("./BezierShape").BezierPath} BezierPath */

export function CubicBez() {
    /** @type {BezierPath} */
    this._path = null
    /** @type {Segment} */
    this._segment1 = null
    /** @type {Segment} */
    this._segment2 = null
    /** @type {number} */
    this._length = null
    /** @type {Rect2} */
    this._bounds = null
}

CubicBez.prototype = {
    constructor: CubicBez,

    /**
     * @param {Segment} seg1
     * @param {Segment} seg2
     */
    initWithSegments(seg1, seg2) {
        this._segment1 = new Segment().copy(seg1)
        this._segment2 = new Segment().copy(seg2)
        return this
    },

    /**
     * @param {BezierPath} path
     * @param {Segment} seg1
     * @param {Segment} seg2
     */
    initWithPathAndSegments(path, seg1, seg2) {
        this._path = path
        this._segment1 = seg1
        this._segment2 = seg2
        return this
    },

    /**
     * initialize with 4 absoluate points
     * @param {Vector2Like} point1
     * @param {Vector2Like} control1
     * @param {Vector2Like} control2
     * @param {Vector2Like} point2
     */
    initWith4Points(point1, control1, control2, point2) {
        this._segment1 = new Segment().initWithPointsN(
            point1.x, point1.y,
            0, 0,
            control1.x - point1.x, control1.y - point1.y
        )
        this._segment2 = new Segment().initWithPointsN(
            point2.x, point2.y,
            control2.x - point2.x, control2.y - point2.y,
            0, 0
        )
        return this
    },

    /**
     * initialize with 4 points (2 relative handle)
     * @param {Vector2Like} point1
     * @param {Vector2Like} handle1
     * @param {Vector2Like} handle2
     * @param {Vector2Like} point2
     */
    initWithPointsAndHandles(point1, handle1, handle2, point2) {
        this._segment1 = new Segment().initWithPoints(point1, null, handle1)
        this._segment2 = new Segment().initWithPoints(point2, handle2, null)
        return this
    },

    /**
     * initialize with 4 numbered points
     * @param {number} x1
     * @param {number} y1
     * @param {number} hx1
     * @param {number} hy1
     * @param {number} hx2
     * @param {number} hy2
     * @param {number} x2
     * @param {number} y2
     */
    initWith4PointsN(x1, y1, hx1, hy1, hx2, hy2, x2, y2) {
        this._segment1 = new Segment().initWithPointsN(x1, y1, 0, 0, hx1 - x1, hy1 - y1)
        this._segment2 = new Segment().initWithPointsN(x2, y2, hx2 - x2, hy2 - y2, 0, 0)
        return this
    },

    /**
     * initialize with 4 numbered points (2 relative handle)
     * @param {number} x1
     * @param {number} y1
     * @param {number} hx1
     * @param {number} hy1
     * @param {number} hx2
     * @param {number} hy2
     * @param {number} x2
     * @param {number} y2
     */
    initWithPointsAndHandlesN(x1, y1, hx1, hy1, hx2, hy2, x2, y2) {
        this._segment1 = new Segment().initWithPointsN(x1, y1, 0, 0, hx1, hy1)
        this._segment2 = new Segment().initWithPointsN(x2, y2, hx2, hy2, 0, 0)
        return this
    },

    /**
     * @param {number} dimension
     */
    regularize(dimension) {
        const p0 = this.getP0()
        const p1 = this.getP1()
        const p2 = this.getP2()
        const p3 = this.getP3()

        const dim2 = dimension * dimension
        if (p0.distance_squared_to(p1) < dim2) {
            const d02 = p0.distance_squared_to(p2)
            if (d02 >= dim2) {
                p1.copy(p0).linear_interpolate(p2, sqrt(dim2 / d02))
            } else {
                p1.copy(p0).linear_interpolate(p3, 1/3)
                p2.copy(p3).linear_interpolate(p0, 1/3)
                return this.initWith4Points(p0, p1, p2, p3)
            }
        }
        if (p3.distance_squared_to(p2) < dim2) {
            const d13 = p1.distance_squared_to(p2)
            if (d13 >= dim2) {
                p2.copy(p3).linear_interpolate(p1, sqrt(dim2 / d13))
            } else {
                p1.copy(p0).linear_interpolate(p3, 1/3)
                p2.copy(p3).linear_interpolate(p0, 1/3)
                return this.initWith4Points(p0, p1, p2, p3)
            }
        }
        const cusp_type = this.detectCusp(dimension)
        if (cusp_type !== CuspTypes.None) {
            const d01 = p1.clone().sub(p0)
            const d01h = d01.length()
            const d23 = p3.clone().sub(p2)
            const d23h = d23.length()
            switch (cusp_type) {
                case CuspTypes.Loop: {
                    p1.add(d01.scale(dimension / d01h))
                    p2.sub(d23.scale(dimension / d23h))
                    break
                }
                case CuspTypes.DoubleInflection: {
                    if (d01h > 2 * dimension) {
                        p1.sub(d01.scale(dimension / d01h))
                    }
                    if (d23h > 2 * dimension) {
                        p2.add(d23.scale(dimension / d23h))
                    }
                    break
                }
            }
        }
        return this.initWith4Points(p0, p1, p2, p3)
    },

    /**
     * @param {number} dimension
     */
    detectCusp(dimension) {
        const p0 = this.getP0()
        const p1 = this.getP1()
        const p2 = this.getP2()
        const p3 = this.getP3()

        const d01 = p1.clone().sub(p0)
        const d02 = p2.clone().sub(p0)
        const d03 = p3.clone().sub(p0)
        const d12 = p2.clone().sub(p1)
        const d23 = p3.clone().sub(p2)
        const det_012 = d01.cross(d02)
        const det_123 = d12.cross(d23)
        const det_013 = d01.cross(d03)
        const det_023 = d02.cross(d03)
        if (det_012 * det_123 > 0.0 && det_012 * det_013 < 0.0 && det_012 * det_023 < 0.0) {
            const q = this.deriv()
            // accuracy isn't used for quadratic nearest
            const nearest = q.nearest(Vector2.ZERO, 1e-9)
            // detect whether curvature at minimum derivative exceeds 1/dimension,
            // without division.
            const d = q.eval(nearest.t)
            const d2 = q.deriv().eval(nearest.t)
            const cross = d.cross(d2)
            if (pow(nearest.distance_sq, 3) < pow(cross * dimension, 2)) {
                const a = 3. * det_012 + det_023 - 2. * det_013
                const b = -3. * det_012 + det_013
                const c = det_012
                const d = b * b - 4. * a * c
                if (d > 0.0) {
                    return CuspTypes.DoubleInflection
                } else {
                    return CuspTypes.Loop
                }
            }
        }
        return CuspTypes.None
    },

    deriv() {
        const p0 = this.getP0()
        const p1 = this.getP1()
        const p2 = this.getP2()
        const p3 = this.getP3()
        return new QuadBez().initN(
            3 * (p1.x - p0.x), 3 * (p1.y - p0.y),
            3 * (p2.x - p1.x), 3 * (p2.y - p1.y),
            3 * (p3.x - p2.x), 3 * (p3.y - p2.y)
        )
    },

    /**
     * @param {number} t
     */
    eval(t) {
        const p0 = this.getP0()
        const p1 = this.getP1()
        const p2 = this.getP2()
        const p3 = this.getP3()

        const mt = 1 - t
        const v = new Vector2()
        v.copy(p0).scale(mt * mt * mt).add(
            p1.clone().scale(mt * mt * 3).add(
                p2.clone().scale(mt * 3).add(
                    p3.clone().scale(t)
                ).scale(t)
            ).scale(t)
        )
        return v
    },

    /**
     * @param {number} w0
     * @param {number} w1
     * @param {number} w2
     * @param {number} w3
     */
    weightsum(w0, w1, w2, w3) {
        const p0 = this.getP0()
        const p1 = this.getP1()
        const p2 = this.getP2()
        const p3 = this.getP3()

        const x = w0 * p0.x + w1 * p1.x + w2 * p2.x + w3 * p3.x
        const y = w0 * p0.y + w1 * p1.y + w2 * p2.y + w3 * p3.y

        return new Vector2(x, y)
    },

    /**
     * @param {number} param
     */
    getDerivativeAt(param) {
        const [t, nt] = [param, 1 - param]
        const w0 = -3 * nt * nt
        const w1 = 3 * nt * nt - 6 * t * nt
        const w2 = -3 * t * t + 6 * t * nt
        const w3 = 3 * t * t

        return this.weightsum(w0, w1, w2, w3)
    },

    /**
     * @param {number} param
     */
    getSecondDerivativeAt(param) {
        const [t, nt] = [param, 1 - param]
        const w0 = 6 * nt
        const w1 = -12 * nt + 6 * t
        const w2 = -12 * t + 6 * nt
        const w3 = 6 * t

        return this.weightsum(w0, w1, w2, w3)
    },

    /**
     * The arc length of the curve
     * @param {number} accuracy
     */
    arclen(accuracy) {
        return arclenRec(this.getValues(), accuracy, 0)
    },
    /**
     * Solve for the parameter that has the given arc length from the start
     * @param {number} arclen
     * @param {number} accuracy
     */
    invArclen(arclen, accuracy) {
        if (arclen <= 0) {
            return 0
        }
        const total_arclen = this.arclen(accuracy)
        if (arclen >= total_arclen) {
            return 1
        }
        const epsilon = accuracy / total_arclen
        const curve = this.getValues()

        // const n = 1 - Math.min(0, Math.ceil(Math.log2(epsilon)))
        // const inner_accuracy = accuracy / n
        // let t_last = 0
        // let arcleng_last = 0
        // const f = (t) => {
        //     let range_start = t_last
        //     let range_end = t
        //     let dir = 1
        //     if (t <= t_last) {
        //         range_start = t
        //         range_end = t_last
        //         dir = -1
        //     }
        //     const arc = arclenRec(CubicBez.getPart(curve, range_start, range_end), inner_accuracy, 0)
        //     arcleng_last += arc * dir
        //     t_last = t
        //     return arcleng_last - arclen
        // }

        /**
         * For simplicity, measure arclen from 0 rather than stateful delta
         * @param {number} t
         */
        const f = (t) => arclenRec(CubicBez.getPart(curve, 0, t), accuracy, 0) - arclen

        return solveItp(f, 0, 1, epsilon, 1, 2, -arclen, total_arclen -arclen)
    },

    clone() {
        return new CubicBez().initWithSegments(this._segment1.clone(), this._segment2.clone())
    },

    remove() {
        let removed = false
        if (this._path) {
            const segment2 = this._segment2
            const handleOut = segment2._handleOut
            removed = segment2.remove()
            if (removed) {
                this._segment1._handleOut.set(handleOut.x, handleOut.y)
                this._segment1._changed(this._segment1._handleOut)
            }
        }
        return removed
    },

    /**
     * Divides the curve into two curves at the given curve-time parameter. The
     * curve itself is modified and becomes the first part, the second part is
     * returned as a new curve. If the modified curve belongs to a path item,
     * the second part is also added to the path.
     *
     * @param {number} time the curve-time parameter on the curve at which to
     *     divide
     * @param {boolean} _setHandles
     * @returns the second part of the divided curve, if the offset is
     *     within the valid range, {code null} otherwise.
     */
    divideAtTime(time, _setHandles) {
        // Only divide if not at the beginning or end.
        const tMin = CURVETIME_EPSILON
        const tMax = 1 - tMin
        /** @type {CubicBez} */
        let res = null
        if (time >= tMin && time <= tMax) {
            const parts = CubicBez.subdivide(this.getValues(), time)
            const left = parts[0]
            const right = parts[1]
            const setHandles = _setHandles || this.hasHandles()
            const seg1 = this._segment1
            const seg2 = this._segment2
            const path = this._path
            if (setHandles) {
                // Adjust the handles on the existing segments. The new segment
                // will be inserted between the existing segment1 and segment2:
                // Convert absolute -> relative
                seg1._handleOut.set(left[2] - left[0], left[3] - left[1])
                seg2._handleIn.set(right[4] - right[6], right[5] - right[7])
                seg1._changed(seg1._handleOut)
                seg2._changed(seg2._handleIn)
            }
            // Create the new segment:
            const x = left[6]
            const y = left[7]
            const segment = new Segment().initWithPoints(
                new Vector2(x, y),
                setHandles && new Vector2(left[4] - x, left[5] - y),
                setHandles && new Vector2(right[2] - x, right[3] - y)
            )
            // Insert it in the segments list, if needed:
            if (path) {
                // By inserting at seg1.index + 1, we make sure to insert at
                // the end if this curve is a closing curve of a closed path,
                // as with segment2.index it would be inserted at 0.
                path.insert(seg1._index + 1, segment)
                // The newly inserted segment is the start of the next curve:
                res = this.getNext()
            } else {
                // otherwise create it from the result of split
                this._segment2 = segment
                this._changed()
                res = new CubicBez().initWithSegments(segment, seg2)
            }
        }
        return res
    },

    /**
     * Clears the curve's handles by setting their coordinates to zero,
     * turning the curve into a straight line.
     */
    clearHandles() {
        this._segment1._handleOut.set(0, 0)
        this._segment2._handleIn.set(0, 0)
        this._segment1._changed(this._segment1._handleOut)
        this._segment2._changed(this._segment2._handleIn)
    },

    getIndex() {
        return this._segment1._index
    },

    getPrevious() {
        const curves = this._path && this._path._curves
        // eslint-disable-next-line no-mixed-operators
        return curves && (curves[this._segment1._index - 1] || this._path._closed && curves[curves.length - 1]) || null
    },
    getNext() {
        const curves = this._path && this._path._curves
        // eslint-disable-next-line no-mixed-operators
        return curves && (curves[this._segment1._index + 1] || this._path._closed && curves[0]) || null
    },

    getPoint1() {
        return this._segment1._point
    },
    getHandle1() {
        return this._segment1._handleOut
    },
    getHandle2() {
        return this._segment2._handleIn
    },
    getPoint2() {
        return this._segment2._point
    },

    getP0() {
        return this._segment1._point
    },
    getP1() {
        return this._segment1._handleOut.clone().add(this._segment1._point)
    },
    getP2() {
        return this._segment2._handleIn.clone().add(this._segment2._point)
    },
    getP3() {
        return this._segment2._point
    },

    getLength() {
        if (this._length == null) {
            this._length = CubicBez.getLength(this.getValues(), 0, 1)
        }
        return this._length
    },

    getSegment1() {
        return this._segment1
    },
    getSegment2() {
        return this._segment2
    },

    /**
     * @param {number} from
     * @param {number} to
     */
    getPartLength(from, to) {
        return CubicBez.getLength(this.getValues(), from, to)
    },

    /**
     * Returns the curve-time parameter of the specified point if it lies on the
     * curve, `null` otherwise.
     * Note that if there is more than one possible solution in a
     * self-intersecting curve, the first found result is returned.
     *
     * @param {Vector2} point the point on the curve
     * @returns {number} the curve-time parameter of the specified point
     */
    getTimeOf(point) {
        return CubicBez.getTimeOf(this.getValues(), point)
    },

    /**
     * @param {number} location
     * @param {boolean} _isTime
     */
    getPointAt(location, _isTime) {
        const values = this.getValues()
        return CubicBez.getPoint(
            values,
            _isTime
                ? location
                : CubicBez.getTimeAt(values, location)
        )
    },

    /**
     * Calculates the point on the curve at the given location.
     * @param {number} time curve-time, a value between 0 and 1
     */
    getPointAtTime(time) {
        return CubicBez.getPoint(this.getValues(), time)
    },

    /**
     * Calculates the normalized tangent vector of the curve at the given
     * location.
     * @param {number} time curve-time, a value between 0 and 1
     */
    getTangentAtTime(time) {
        return CubicBez.getTangent(this.getValues(), time)
    },

    /**
     * @param {number} location
     * @param {boolean} _isTime
     */
    getTangentAt(location, _isTime) {
        const values = this.getValues()
        return CubicBez.getTangent(
            values,
            _isTime
                ? location
                : CubicBez.getTimeAt(values, location)
        )
    },

    /**
     * Calculates the curve offset at the specified curve-time parameter on
     * the curve.
     *
     * @param {number} t the curve-time parameter on the curve
     * returns the curve offset at the specified the location
     */
    getOffsetAtTime(t) {
        return this.getPartLength(0, t)
    },

    /**
     * Calculates the curve location at the specified offset on the curve.
     *
     * @param {number} offset the offset on the curve
     * @param {boolean} _isTime
     */
    getLocationAt(offset, _isTime) {
        // TODO: Remove _isTime handling in 1.0.0? (deprecated Jan 2016):
        return this.getLocationAtTime(_isTime ? offset : this.getTimeAt(offset))
    },

    /**
     * Calculates the curve location at the specified curve-time parameter on
     * the curve.
     *
     * @param {number} t the curve-time parameter on the curve
     * @returns the curve location at the specified the location
     */
    getLocationAtTime(t) {
        return t != null && t >= 0 && t <= 1
            ? new CurveLocation().init(this, t)
            : null
    },

    /**
     * Calculates the curve-time parameter of the specified offset on the path,
     * relative to the provided start parameter. If offset is a negative value,
     * the parameter is searched to the left of the start parameter. If no start
     * parameter is provided, a default of `0` for positive values of `offset`
     * and `1` for negative values of `offset`.
     *
     * @param {number} offset the offset at which to find the curve-time, in
     *     curve length units
     * @param {number} [start] the curve-time in relation to which the offset is
     *     determined
     * @returns the curve-time parameter at the specified location
     */
    getTimeAt(offset, start) {
        return CubicBez.getTimeAt(this.getValues(), offset, start)
    },

    /**
     * @param {CubicBez} curve
     */
    getIntersections(curve) {
        const v1 = this.getValues()
        const v2 = curve && curve !== this && curve.getValues()
        return v2
            ? getCurveIntersections(v1, v2, this, curve, [])
            : getSelfIntersection(v1, this, [])
    },

    /**
     * Checks if this curve has any curve handles set.
     */
    hasHandles() {
        return (
            !this._segment1._handleOut.is_zero(CMP_EPSILON)
            ||
            !this._segment2._handleIn.is_zero(CMP_EPSILON)
        )
    },

    /**
     * Checks if this curve has any length.
     * @param {number} [epsilon=0] the epsilon against which to compare the curve's length
     * @returns true if the curve is longer than the given epsilon
     */
    hasLength(epsilon) {
        return (!this.getPoint1().equals(this.getPoint2()) || this.hasHandles())
                && this.getLength() > (epsilon || 0)
    },

    /**
     * @param {CubicBez} curve
     */
    isCollinear(curve) {
        return curve && this.isStraight() && curve.isStraight() && this.getLine().isCollinear(curve.getLine())
    },

    isStraight() {
        return CubicBez.isStraight(this.getValues(), GEOMETRIC_EPSILON)
    },

    getValues() {
        return CubicBez.getValues(this._segment1, this._segment2)
    },

    getBounds() {
        if (this._bounds == null) {
            const v = this.getValues()
            this._bounds = CubicBez.getBounds(v)
        }
        return this._bounds
    },

    /**
     * @returns {Line}
     */
    getLine() {
        return new Line().initP(this._segment1._point, this._segment2._point)
    },

    /**
     * Determines the type of cubic Bézier curve via discriminant
     * classification, as well as the curve-time parameters of the associated
     * points of inflection, loops, cusps, etc.
     */
    classify() {
        return CubicBez.classify(this.getValues())
    },

    _changed() {
        this._length = null
        this._bounds = null
    },

    toString() {
        return `CubicBez{ s1: ${this._segment1.toString()}, s2: ${this._segment2.toString()} }`
    },
}

/**
 * @param {CurveData} v
 * @param {number} a begin time
 * @param {number} b end time
 * @param {(t: number) => number} ds
 */
CubicBez.getLength = (v, a = 0, b = 1, ds) => {
    if (CubicBez.isStraight(v)) {
        // Sub-divide the linear curve at a and b, so we can simply
        // calculate the Pythagorean Theorem to get the range's length.
        let c = v
        if (b < 1) {
            c = CubicBez.subdivide(c, b)[0] // left
            // eslint-disable-next-line no-param-reassign
            a /= b // Scale parameter to new sub-curve.
        }
        if (a > 0) {
            c = CubicBez.subdivide(c, a)[1] // right
        }
        // The length of straight curves can be calculated more easily.
        const dx = c[6] - c[0] // x3 - x0
        const dy = c[7] - c[1] // y3 - y0
        return sqrt(dx * dx + dy * dy)
    }
    return Numerical.integrate(ds || getLengthIntegrand(v), a, b, getIterations(a, b))
}

/**
 * @param {CurveData} _v
 * @param {number} _from
 * @param {number} _to
 */
CubicBez.getPart = (_v, _from, _to) => {
    let v = _v, from = _from, to = _to
    const flip = from > to
    if (flip) {
        const tmp = from
        from = to
        to = tmp
    }
    if (from > 0) {
        v = CubicBez.subdivide(v, from)[1] // [1] right
    }
    // Interpolate the parameter at 'to' in the new curve and cut there.
    if (to < 1) {
        v = CubicBez.subdivide(v, (to - from) / (1 - from))[0] // [0] left
    }
    // Return reversed curve if from / to were flipped:
    return flip
        ? [v[6], v[7], v[4], v[5], v[2], v[3], v[0], v[1]]
        : v
}

/**
 * @param {CurveData} v
 * @param {number} t
 */
CubicBez.getPoint = (v, t) => evaluate(v, t, 0, false)

/**
 * @param {CurveData} v
 * @param {number} t
 */
CubicBez.getTangent = (v, t) => evaluate(v, t, 1, true)

/**
 * @param {CurveData} v
 * @param {number} t
 */
CubicBez.getWeightedTangent = (v, t) => evaluate(v, t, 1, false)

/**
 * @param {CurveData} v
 * @param {number} t
 */
CubicBez.getNormal = (v, t) => evaluate(v, t, 2, true)

/**
 * @param {CurveData} v
 * @param {number} t
 */
CubicBez.getWeightedNormal = (v, t) => evaluate(v, t, 2, false)

/**
 * @param {CurveData} v
 * @param {number} t
 */
CubicBez.getCurvature = (v, t) => evaluate(v, t, 3, false).x

/**
 * @param {CurveData} v
 * @param {Vector2} point
 */
CubicBez.getTimeOf = (v, point) => {
    // Before solving cubics, compare the beginning and end of the curve
    // with zero epsilon:
    const p0 = new Vector2(v[0], v[1]),
        p3 = new Vector2(v[6], v[7]),
        epsilon = EPSILON,
        geomEpsilon = GEOMETRIC_EPSILON,
        // eslint-disable-next-line no-nested-ternary
        t = point.isClose(p0, epsilon)
            ? 0
            : point.isClose(p3, epsilon)
                ? 1
                : null
    if (t === null) {
        // Solve the cubic for both x- and y-coordinates and consider all
        // solutions, testing with the larger / looser geometric epsilon.
        const coords = [point.x, point.y]
        /** @type {number[]} */
        const roots = []
        for (let c = 0; c < 2; c++) {
            const count = CubicBez.solveCubic(v, c, coords[c], roots, 0, 1)
            for (let i = 0; i < count; i++) {
                const u = roots[i]
                if (point.isClose(CubicBez.getPoint(v, u), geomEpsilon)) {
                    return u
                }
            }
        }
    }
    // Since we're comparing with geometric epsilon for any other t along
    // the curve, do so as well now for the beginning and end of the curve.
    // eslint-disable-next-line no-nested-ternary
    return point.isClose(p0, geomEpsilon)
        ? 0
        : point.isClose(p3, geomEpsilon)
            ? 1
            : null
}

/**
 * @param {CurveData} v
 * @param {number} offset
 * @param {number} start
 */
CubicBez.getTimeAt = (v, offset, start) => {
    if (start === undefined) {
        // eslint-disable-next-line no-param-reassign
        start = offset < 0 ? 1 : 0
    }
    if (offset === 0) {
        return start
    }
    // See if we're going forward or backward, and handle cases
    // differently
    const epsilon = GEOMETRIC_EPSILON
    const forward = offset > 0
    const a = forward ? start : 0
    const b = forward ? 1 : start
    // Use integrand to calculate both range length and part
    // lengths in f(t) below.
    const ds = getLengthIntegrand(v)
    // Get length of total range
    const rangeLength = CubicBez.getLength(v, a, b, ds)
    const diff = abs(offset) - rangeLength
    if (abs(diff) < epsilon) {
        // Matched the end:
        return forward ? b : a
    } else if (diff > epsilon) {
        // We're out of bounds.
        return null
    }
    // Use offset / rangeLength for an initial guess for t, to
    // bring us closer:
    const guess = offset / rangeLength
    let length = 0
    // Iteratively calculate curve range lengths, and add them up,
    // using integration precision depending on the size of the
    // range. This is much faster and also more precise than not
    // modifying start and calculating total length each time.
    /**
     * @param {number} t
     */
    function f(t) {
        // When start > t, the integration returns a negative value.
        length += Numerical.integrate(
            ds, start, t,
            getIterations(start, t)
        )
        // eslint-disable-next-line no-param-reassign
        start = t
        return length - offset
    }
    // Start with out initial guess for x.
    // NOTE: guess is a negative value when looking backwards.
    return Numerical.findRoot(
        f, ds, start + guess, a, b, 32,
        EPSILON
    )
}

/**
 * Returns the t values for the "peaks" of the curve. The peaks are
 * calculated by finding the roots of the dot product of the first and
 * second derivative.
 *
 * Peaks are locations sharing some qualities of curvature extrema but
 * are cheaper to compute. They fulfill their purpose here quite well.
 * See:
 * https://math.stackexchange.com/questions/1954845/bezier-curvature-extrema
 *
 * @param {CurveData[]} v the curve values array
 * @returns {CurveData[]} the roots of all found peaks
 */
CubicBez.getPeaks = (v) => {
    const x0 = v[0], y0 = v[1],
        x1 = v[2], y1 = v[3],
        x2 = v[4], y2 = v[5],
        x3 = v[6], y3 = v[7],
        ax =     -x0 + 3 * x1 - 3 * x2 + x3,
        bx =  3 * x0 - 6 * x1 + 3 * x2,
        cx = -3 * x0 + 3 * x1,
        ay =     -y0 + 3 * y1 - 3 * y2 + y3,
        by =  3 * y0 - 6 * y1 + 3 * y2,
        cy = -3 * y0 + 3 * y1,
        tMin = CURVETIME_EPSILON,
        tMax = 1 - tMin,
        roots = []
    Numerical.solveCubic(
        9 * (ax * ax + ay * ay),
        9 * (ax * bx + by * ay),
        2 * (bx * bx + by * by) + 3 * (cx * ax + cy * ay),
        (cx * bx + by * cy),
        // Exclude 0 and 1 as we don't count them as peaks.
        roots, tMin, tMax)
    return roots.sort()
}
/**
 * @param {CubicBez[]} curves1
 * @param {CubicBez[]} curves2
 * @param {(loc: CurveLocation) => boolean} include
 * @param {boolean} [_returnFirst]
 */
CubicBez.getIntersections = (curves1, curves2, include, _returnFirst) => {
    const epsilon = GEOMETRIC_EPSILON
    const self = !curves2
    // eslint-disable-next-line no-param-reassign
    if (self) curves2 = curves1
    const length1 = curves1.length
    const length2 = curves2.length
    /** @type {CurveData[]} */
    const values1 = Array(length1)
    /** @type {CurveData[]} */
    const values2 = self ? values1 : Array(length2)
    /** @type {CurveLocation[]} */
    const locations = []
    for (let i = 0; i < length1; i++) {
        values1[i] = curves1[i].getValues()
    }
    if (!self) {
        for (let i = 0; i < length2; i++) {
            values2[i] = curves2[i].getValues()
        }
    }
    const boundsCollisions = findCurveBoundsCollisions(values1, values2, epsilon)
    for (let index1 = 0; index1 < length1; index1++) {
        const curve1 = curves1[index1]
        const v1 = values1[index1]
        if (self) {
            // First check for self-intersections within the same curve.
            getSelfIntersection(v1, curve1, locations, include)
        }
        // Check for intersections with potentially intersecting curves.
        const collisions1 = boundsCollisions[index1]
        if (collisions1) {
            for (let j = 0; j < collisions1.length; j++) {
                // There might be already one location from the above
                // self-intersection check:
                if (_returnFirst && locations.length) {
                    return locations
                }
                const index2 = collisions1[j]
                if (!self || index2 > index1) {
                    const curve2 = curves2[index2]
                    const v2 = values2[index2]
                    getCurveIntersections(v1, v2, curve1, curve2, locations, include)
                }
            }
        }
    }
    return locations
}

/**
 * @param {CurveData} _v
 */
CubicBez.isStraight = (_v) => {
    const x0 = _v[0], y0 = _v[1], x3 = _v[6], y3 = _v[7]
    const p1 = new Vector2(x0, y0)
    const h1 = new Vector2(_v[2] - x0, _v[3] - y0)
    const h2 = new Vector2(_v[4] - x3, _v[5] - y3)
    const p2 = new Vector2(x3, y3)

    if (h1.is_zero(CMP_EPSILON) && h2.is_zero(CMP_EPSILON)) {
        // No handles.
        return true
    } else {
        const v = p2.clone().subtract(p1)
        if (v.is_zero(CMP_EPSILON)) {
            // Zero-length line, with some handles defined.
            return false
        } else if (v.isCollinear(h1) && v.isCollinear(h2)) {
            // Collinear handles: In addition to v.isCollinear(h) checks, we
            // need to measure the distance to the line, in order to be able
            // to use the same epsilon as in Curve#getTimeOf(), see #1066.
            const l = new Line().initP(p1, p2)
            const epsilon = GEOMETRIC_EPSILON
            if (
                l.getDistance(p1.clone().add(h1.x, h1.y)) < epsilon &&
                l.getDistance(p2.clone().add(h2.x, h2.y)) < epsilon
            ) {
                // Project handles onto line to see if they are in range:
                const div = v.clone().dot(v)
                const s1 = v.clone().dot(h1) / div
                const s2 = v.clone().dot(h2) / div
                return s1 >= 0 && s1 <= 1 && s2 <= 0 && s2 >= -1
            }
        }
    }
    return false
}

/**
 * @param {CurveData} v
 * @param {number} t
 * @returns {[CurveData, CurveData]}
 */
CubicBez.subdivide = (v, t = 0.5) => {
    const x0 = v[0], y0 = v[1],
        x1 = v[2], y1 = v[3],
        x2 = v[4], y2 = v[5],
        x3 = v[6], y3 = v[7]
    // Triangle computation, with loops unrolled.
    const u = 1 - t,
        // Interpolate from 4 to 3 points
        x4 = u * x0 + t * x1, y4 = u * y0 + t * y1,
        x5 = u * x1 + t * x2, y5 = u * y1 + t * y2,
        x6 = u * x2 + t * x3, y6 = u * y2 + t * y3,
        // Interpolate from 3 to 2 points
        x7 = u * x4 + t * x5, y7 = u * y4 + t * y5,
        x8 = u * x5 + t * x6, y8 = u * y5 + t * y6,
        // Interpolate from 2 points to 1 point
        x9 = u * x7 + t * x8, y9 = u * y7 + t * y8
    // We now have all the values we need to build the sub-curves:
    return [
        [x0, y0, x4, y4, x7, y7, x9, y9], // left
        [x9, y9, x8, y8, x6, y6, x3, y3] // right
    ]
}

/**
 * @param {Segment} segment1
 * @param {Segment} segment2
 * @param {boolean} isStraight
 * @returns {CurveData}
 */
CubicBez.getValues = (segment1, segment2, isStraight) => {
    const p1 = segment1._point
    const h1 = segment1._handleOut
    const h2 = segment2._handleIn
    const p2 = segment2._point
    const x1 = p1.x
    const y1 = p1.y
    const x2 = p2.x
    const y2 = p2.y
    return isStraight
        ? [
            x1, y1,
            x1, y1,
            x2, y2,
            x2, y2,
        ]
        : [
            x1, y1,
            x1 + h1.x, y1 + h1.y,
            x2 + h2.x, y2 + h2.y,
            x2, y2,
        ]
}

/**
 * @param {CurveData} v
 */
CubicBez.getArea = (v) => {
    // http://objectmix.com/graphics/133553-area-closed-bezier-curve.html
    const x0 = v[0]
    const y0 = v[1]
    const x1 = v[2]
    const y1 = v[3]
    const x2 = v[4]
    const y2 = v[5]
    const x3 = v[6]
    const y3 = v[7]
    return 3 * ((y3 - y0) * (x1 + x2) - (x3 - x0) * (y1 + y2)
        + y1 * (x0 - x2) - x1 * (y0 - y2)
        + y3 * (x2 + x0 / 3) - x3 * (y2 + y0 / 3)) / 20
}

/**
 * @param {CurveData} v
 */
CubicBez.getBounds = (v) => {
    const min = v.slice(0, 2) // Start with values of point1
    const max = min.slice() // clone
    const roots = [0, 0]
    for (let i = 0; i < 2; i++) {
        CubicBez._addBounds(v[i], v[i + 2], v[i + 4], v[i + 6], i, min, max, roots)
    }
    return new Rect2(min[0], min[1], max[0] - min[0], max[1] - min[1])
}

/**
 * Private helper for both CubicBez.getBounds() and BezierPath.getBounds(), which
 * finds the 0-crossings of the derivative of a bezier curve polynomial, to
 * determine potential extremas when finding the bounds of a curve.
 * NOTE: padding is only used for BezierPath.getBounds().
 *
 * @param {number} v0
 * @param {number} v1
 * @param {number} v2
 * @param {number} v3
 * @param {number} coord
 * @param {number[]} min
 * @param {number[]} max
 * @param {number[]} roots
 */
CubicBez._addBounds = (v0, v1, v2, v3, coord, min, max, roots) => {
    // Code ported and further optimised from:
    // http://blog.hackers-cafe.net/2009/06/how-to-calculate-bezier-curves-bounding.html

    const minPad = min[coord]
    const maxPad = max[coord]
    // Perform a rough bounds checking first: The curve can only extend the
    // current bounds if at least one value is outside the min-max range.
    if (v0 < minPad || v1 < minPad || v2 < minPad || v3 < minPad ||
        v0 > maxPad || v1 > maxPad || v2 > maxPad || v3 > maxPad) {
        // eslint-disable-next-line no-mixed-operators
        if (v1 < v0 != v1 < v3 && v2 < v0 != v2 < v3) {
            // If the values of a curve are sorted, the extrema are simply
            // the start and end point.
            // Only add strokeWidth to bounds for points which lie within 0
            // < t < 1. The corner cases for cap and join are handled in
            // getStrokeBounds()
            add(v0, coord, min, max)
            add(v3, coord, min, max)
        } else {
            // Calculate derivative of our bezier polynomial, divided by 3.
            // Doing so allows for simpler calculations of a, b, c and leads
            // to the same quadratic roots.
            const a = 3 * (v1 - v2) - v0 + v3,
                b = 2 * (v0 + v2) - 4 * v1,
                c = v1 - v0,
                count = Numerical.solveQuadratic(a, b, c, roots),
                // Add some tolerance for good roots, as t = 0, 1 are added
                // separately anyhow, and we don't want joins to be added
                // with radii in getStrokeBounds()
                tMin = CURVETIME_EPSILON,
                tMax = 1 - tMin
            // See above for an explanation of padding = 0 here:
            add(v3, coord, min, max)
            for (let i = 0; i < count; i++) {
                const t = roots[i],
                    u = 1 - t
                // Test for good roots and only add to bounds if good.
                if (tMin <= t && t <= tMax) {
                    // Calculate bezier polynomial at t.
                    add(
                        u * u * u * v0
                        + 3 * u * u * t * v1
                        + 3 * u * t * t * v2
                        + t * t * t * v3,
                        coord, min, max
                    )
                }
            }
        }
    }
}

/**
 * Converts from the point coordinates (p0, p1, p2, p3) for one axis to
 * the polynomial coefficients and solves the polynomial for val
 * @param {CurveData} v
 * @param {number} coord
 * @param {number} val
 * @param {number[]} roots
 * @param {number} min
 * @param {number} max
 */
CubicBez.solveCubic = (v, coord, val, roots, min, max) => {
    const v0 = v[coord]
    const v1 = v[coord + 2]
    const v2 = v[coord + 4]
    const v3 = v[coord + 6]
    let res = 0
    // If val is outside the curve values, no solution is possible.
    // eslint-disable-next-line no-mixed-operators
    if (!(v0 < val && v3 < val && v1 < val && v2 < val || v0 > val && v3 > val && v1 > val && v2 > val)) {
        const c = 3 * (v1 - v0)
        const b = 3 * (v2 - v1) - c
        const a = v3 - v0 - c - b
        res = Numerical.solveCubic(a, b, c, v0 - val, roots, min, max)
    }
    return res
}

/**
 * Splits the specified curve values into curves that are monotone in the
 * specified coordinate direction.
 *
 * @param {CurveData} v the curve values
 * @param {boolean} [dir=false] the direction in which the curves should be
 *     monotone, `false`: in x-direction, `true`: in y-direction
 * returns an array of curve value arrays of the resulting
 *     monotone curve. If the original curve was already monotone, an array
 *     only containing its values are returned.
 */
CubicBez.getMonoCurves = (v, dir) => {
    /** @type {CurveData[]} */
    const curves = []
    // Determine the ordinate index in the curve values array.
    const io = dir ? 0 : 1
    const o0 = v[io + 0]
    const o1 = v[io + 2]
    const o2 = v[io + 4]
    const o3 = v[io + 6]
    // eslint-disable-next-line no-mixed-operators
    if ((o0 >= o1) === (o1 >= o2) && (o1 >= o2) === (o2 >= o3) || CubicBez.isStraight(v)) {
        // Straight curves and curves with all involved points ordered
        // in coordinate direction are guaranteed to be monotone.
        curves.push(v)
    } else {
        const a = 3 * (o1 - o2) - o0 + o3
        const b = 2 * (o0 + o2) - 4 * o1
        const c = o1 - o0
        const tMin = CURVETIME_EPSILON
        const tMax = 1 - tMin
        /** @type {number[]} */
        const roots = []
        const n = Numerical.solveQuadratic(a, b, c, roots, tMin, tMax)
        // eslint-disable-next-line no-negated-condition
        if (!n) {
            curves.push(v)
        } else {
            roots.sort()
            let t = roots[0]
            let parts = CubicBez.subdivide(v, t)
            curves.push(parts[0])
            if (n > 1) {
                t = (roots[1] - t) / (1 - t)
                parts = CubicBez.subdivide(parts[1], t)
                curves.push(parts[0])
            }
            curves.push(parts[1])
        }
    }
    return curves
}

/**
 * Determines the type of cubic Bézier curve via discriminant
 * classification, as well as the curve-time parameters of the associated
 * points of inflection, loops, cusps, etc.
 * @param {CurveData} v
 */
CubicBez.classify = (v) => {
    // See: Loop and Blinn, 2005, Resolution Independent Curve Rendering
    // using Programmable Graphics Hardware, GPU Gems 3 chapter 25
    //
    // Possible types:
    //   line       (d1 == d2 == d3 == 0)
    //   quadratic  (d1 == d2 == 0)
    //   serpentine (d > 0)
    //   cusp       (d == 0)
    //   loop       (d < 0)
    //   arch       (serpentine, cusp or loop with roots outside 0..1)
    //
    // NOTE: Roots for serpentine, cusp and loop curves are only
    // considered if they are within 0..1. If the roots are outside,
    // then we degrade the type of curve down to an 'arch'.

    const x0 = v[0], y0 = v[1]
    const x1 = v[2], y1 = v[3]
    const x2 = v[4], y2 = v[5]
    const x3 = v[6], y3 = v[7]
    // Calculate coefficients of I(s, t), of which the roots are
    // inflection points.
    const a1 = x0 * (y3 - y2) + y0 * (x2 - x3) + x3 * y2 - y3 * x2
    const a2 = x1 * (y0 - y3) + y1 * (x3 - x0) + x0 * y3 - y0 * x3
    const a3 = x2 * (y1 - y0) + y2 * (x0 - x1) + x1 * y0 - y1 * x0
    let d3 = 3 * a3
    let d2 = d3 - a2
    // FIXME: should we replace formula below with `let d1 = d2 - 2 * a2 + a1`?
    let d1 = d2 - a2 + a1
    // Normalize the vector (d1, d2, d3) to keep error consistent.
    const l = sqrt(d1 * d1 + d2 * d2 + d3 * d3)
    const s = l === 0 ? 0 : 1 / l
    const isZero = Numerical.isZero
    d1 *= s
    d2 *= s
    d3 *= s

    if (isZero(d1)) {
        return isZero(d2)
            ? type(isZero(d3) ? CubicBezTypes.line : CubicBezTypes.quadratic) // 5. / 4.
            : type(CubicBezTypes.serpentine, d3 / (3 * d2))        // 3b.
    }
    const d = 3 * d2 * d2 - 4 * d1 * d3
    if (isZero(d)) {
        return type(CubicBezTypes.cusp, d2 / (2 * d1))               // 3a.
    }
    const f1 = d > 0 ? sqrt(d / 3) : sqrt(-d),
        f2 = 2 * d1
    return type(d > 0 ? CubicBezTypes.serpentine : CubicBezTypes.loop,              // 1. / 2.
        (d2 + f1) / f2,
        (d2 - f1) / f2)
}

const min = Math.min
const max = Math.max
const abs = Math.abs
const ceil = Math.ceil
const pow = Math.pow
const sqrt = Math.sqrt
const atan2 = Math.atan2

/**
 * @param {CubicBezTypes} type
 * @param {number} t1
 * @param {number} t2
 * @returns {{ type: CubicBezTypes, roots: number[] }}
 */
function type(type, t1, t2) {
    const hasRoots = t1 !== undefined
    let t1Ok = hasRoots && t1 > 0 && t1 < 1
    let t2Ok = hasRoots && t2 > 0 && t2 < 1
    let newType = type
    // Degrade to arch for serpentine, cusp or loop if no solutions
    // within 0..1 are found. loop requires 2 solutions to be valid.
    if (hasRoots && ((!(t1Ok || t2Ok)
            || newType === CubicBezTypes.loop) && !(t1Ok && t2Ok))) {
        newType = CubicBezTypes.arch
        t1Ok = false
        t2Ok = false
    }
    let roots = null
    if (t1Ok || t2Ok) {
        if (t1Ok && t2Ok) {
            roots = t1 < t2 ? [t1, t2] : [t2, t1]
        } else {
            roots = [t1Ok ? t1 : t2]
        }
    }

    return {
        type: newType,
        roots: roots,
    }
}

/**
 * @param {CurveLocation[]} locations
 * @param {(loc: CurveLocation) => boolean} include
 * @param {CubicBez} c1
 * @param {number} t1
 * @param {CubicBez} c2
 * @param {number} t2
 * @param {boolean} overlap
 */
function addLocation(locations, include, c1, t1, c2, t2, overlap) {
    // Determine if locations at the beginning / end of the curves should be
    // excluded, in case the two curves are neighbors, but do not exclude
    // connecting points between two curves if they were part of overlap
    // checks, as they could be self-overlapping.
    // NOTE: We don't pass p1 and p2, because v1 and v2 may be transformed
    // by their path.matrix, while c1 and c2 are untransformed. Passing null
    // for point in CurveLocation() will do the right thing.
    const excludeStart = !overlap && c1.getPrevious() === c2
    const excludeEnd = !overlap && c1 !== c2 && c1.getNext() === c2
    const tMin = CURVETIME_EPSILON
    const tMax = 1 - tMin
    // Check t1 and t2 against correct bounds, based on excludeStart/End:
    // - excludeStart means the start of c1 connects to the end of c2
    // - excludeEnd means the end of c1 connects to the start of c2
    // - If either c1 or c2 are at the end of the path, exclude their end,
    //   which connects back to the beginning, but only if it's not part of
    //   a found overlap. The normal intersection will already be found at
    //   the beginning, and would be added twice otherwise.
    if (t1 !== null && t1 >= (excludeStart ? tMin : 0) &&
        t1 <= (excludeEnd ? tMax : 1)) {
        if (t2 !== null && t2 >= (excludeEnd ? tMin : 0) &&
            t2 <= (excludeStart ? tMax : 1)) {
            const loc1 = new CurveLocation().init(c1, t1, null, overlap)
            const loc2 = new CurveLocation().init(c2, t2, null, overlap)
            // Link the two locations to each other.
            loc1._intersection = loc2
            loc2._intersection = loc1
            if (!include || include(loc1)) {
                CurveLocation.insert(locations, loc1, true)
            }
        }
    }
}

/**
 * @param {CurveData} v1
 * @param {CurveData} v2
 * @param {CubicBez} c1
 * @param {CubicBez} c2
 * @param {CurveLocation} locations
 * @param {(loc: CurveLocation) => boolean} include
 */
function addLineIntersection(v1, v2, c1, c2, locations, include) {
    const pt = Line.intersect(
        v1[0], v1[1], v1[6], v1[7],
        v2[0], v2[1], v2[6], v2[7]
    )
    if (pt) {
        addLocation(
            locations, include,
            c1, CubicBez.getTimeOf(v1, pt),
            c2, CubicBez.getTimeOf(v2, pt)
        )
    }
}

/**
 * @param {CurveData} v1
 * @param {CubicBez} c1
 * @param {CurveLocation[]} locations
 * @param {(loc: CurveLocation) => boolean} include
 */
function getSelfIntersection(v1, c1, locations, include) {
    const info = CubicBez.classify(v1)
    if (info.type === CubicBezTypes.loop) {
        const roots = info.roots
        addLocation(
            locations, include,
            c1, roots[0],
            c1, roots[1]
        )
    }
    return locations
}

/**
 * @param {number[]} v1
 * @param {number[]} v2
 * @param {CubicBez} c1
 * @param {CubicBez} c2
 * @param {CurveLocation[]} locations
 * @param {(loc: CurveLocation) => boolean} include
 */
function getCurveIntersections(v1, v2, c1, c2, locations, include) {
    // Avoid checking curves if completely out of control bounds.
    const epsilon = EPSILON

    if (max(v1[0], v1[2], v1[4], v1[6]) + epsilon >
        min(v2[0], v2[2], v2[4], v2[6]) &&
        min(v1[0], v1[2], v1[4], v1[6]) - epsilon <
        max(v2[0], v2[2], v2[4], v2[6]) &&
        max(v1[1], v1[3], v1[5], v1[7]) + epsilon >
        min(v2[1], v2[3], v2[5], v2[7]) &&
        min(v1[1], v1[3], v1[5], v1[7]) - epsilon <
        max(v2[1], v2[3], v2[5], v2[7])) {
        // Now detect and handle overlaps:
        const overlaps = getOverlaps(v1, v2)
        if (overlaps) {
            for (let i = 0; i < 2; i++) {
                const overlap = overlaps[i]
                addLocation(
                    locations,
                    include,
                    c1, overlap[0],
                    c2, overlap[1], true
                )
            }
        } else {
            const straight1 = CubicBez.isStraight(v1)
            const straight2 = CubicBez.isStraight(v2)
            const straight = straight1 && straight2
            const flip = straight1 && !straight2
            const before = locations.length
            // Determine the correct intersection method based on whether
            // one or curves are straight lines:
            // eslint-disable-next-line no-nested-ternary
            ;(straight
                ? addLineIntersection
                : straight1 || straight2
                    ? addCurveLineIntersections
                    : addCurveIntersections)(
                flip ? v2 : v1, flip ? v1 : v2,
                flip ? c2 : c1, flip ? c1 : c2,
                locations, include, flip,
                // Define the defaults for these parameters of
                // addCurveIntersections():
                // recursion, calls, tMin, tMax, uMin, uMax
                0, 0, 0, 1, 0, 1)
            // Handle the special case where the first curve's start- / end-
            // point overlaps with the second curve's start- / end-point,
            // but only if haven't found a line-line intersection already:
            // #805#issuecomment-148503018
            if (!straight || locations.length === before) {
                for (let i = 0; i < 4; i++) {
                    const t1 = i >> 1, // 0, 0, 1, 1
                        t2 = i & 1,  // 0, 1, 0, 1
                        i1 = t1 * 6,
                        i2 = t2 * 6,
                        p1 = new Vector2(v1[i1], v1[i1 + 1]),
                        p2 = new Vector2(v2[i2], v2[i2 + 1])
                    if (p1.isClose(p2, epsilon)) {
                        addLocation(
                            locations, include,
                            c1, t1,
                            c2, t2
                        )
                    }
                }
            }
        }
    }
    return locations
}

/**
 * @param {number} value
 * @param {number} coord
 * @param {number[]} min
 * @param {number[]} max
 */
function add(value, coord, min, max) {
    const left = value
    const right = value
    if (left < min[coord]) {
        min[coord] = left
    }
    if (right > max[coord]) {
        max[coord] = right
    }
}

/**
 * @param {CurveData} v
 * @param {number} t
 * @param {number} type
 * @param {boolean} normalized
 */
function evaluate(v, t, type, normalized) {
    // Do not produce results if parameter is out of range or invalid.
    if (t == null || t < 0 || t > 1) {
        return null
    }
    const x0 = v[0], y0 = v[1]
    let x1 = v[2], y1 = v[3], x2 = v[4], y2 = v[5]
    const x3 = v[6], y3 = v[7]
    const isZero = Numerical.isZero
    // If the curve handles are almost zero, reset the control points to the
    // anchors.
    if (isZero(x1 - x0) && isZero(y1 - y0)) {
        x1 = x0
        y1 = y0
    }
    if (isZero(x2 - x3) && isZero(y2 - y3)) {
        x2 = x3
        y2 = y3
    }
    // Calculate the polynomial coefficients.
    const cx = 3 * (x1 - x0)
    const bx = 3 * (x2 - x1) - cx
    const ax = x3 - x0 - cx - bx
    const cy = 3 * (y1 - y0)
    const by = 3 * (y2 - y1) - cy
    const ay = y3 - y0 - cy - by
    let x, y
    if (type === 0) {
        // type === 0: getPoint()
        // Calculate the curve point at parameter value t
        // Use special handling at t === 0 / 1, to avoid imprecisions.
        // See #960
        // eslint-disable-next-line no-nested-ternary
        x = t === 0
            ? x0
            : t === 1
                ? x3
                : ((ax * t + bx) * t + cx) * t + x0
        // eslint-disable-next-line no-nested-ternary
        y = t === 0
            ? y0
            : t === 1
                ? y3
                : ((ay * t + by) * t + cy) * t + y0
    } else {
        // type === 1: getTangent()
        // type === 2: getNormal()
        // type === 3: getCurvature()
        const tMin = CURVETIME_EPSILON
        const tMax = 1 - tMin
        // 1: tangent, 1st derivative
        // 2: normal, 1st derivative
        // 3: curvature, 1st derivative & 2nd derivative
        // Prevent tangents and normals of length 0:
        // https://stackoverflow.com/questions/10506868/
        if (t < tMin) {
            x = cx
            y = cy
        } else if (t > tMax) {
            x = 3 * (x3 - x2)
            y = 3 * (y3 - y2)
        } else {
            x = (3 * ax * t + 2 * bx) * t + cx
            y = (3 * ay * t + 2 * by) * t + cy
        }
        if (normalized) {
            // When the tangent at t is zero and we're at the beginning
            // or the end, we can use the vector between the handles,
            // but only when normalizing as its weighted length is 0.
            if (x === 0 && y === 0 && (t < tMin || t > tMax)) {
                x = x2 - x1
                y = y2 - y1
            }
            // Now normalize x & y
            const len = sqrt(x * x + y * y)
            if (len) {
                x /= len
                y /= len
            }
        }
        if (type === 3) {
            // Calculate 2nd derivative, and curvature from there:
            // http://cagd.cs.byu.edu/~557/text/ch2.pdf page#31
            // k = |dx * d2y - dy * d2x| / (( dx^2 + dy^2 )^(3/2))
            const x2 = 6 * ax * t + 2 * bx
            const y2 = 6 * ay * t + 2 * by
            const d = pow(x * x + y * y, 3 / 2)
            // For JS optimizations we always return a Point, although
            // curvature is just a numeric value, stored in x:
            x = d === 0
                ? 0
                : (x * y2 - y * x2) / d
            y = 0
        }
    }
    // The normal is simply the rotated tangent:
    return type === 2 ? new Vector2(y, -x) : new Vector2(x, y)
}

/**
 * @param {CurveData} v1
 * @param {CurveData} v2
 * @param {CubicBez} c1
 * @param {CubicBez} c2
 * @param {CurveLocation[]} locations
 * @param {(loc: CurveLocation) => boolean} include
 * @param {boolean} flip
 * @param {number} recursion
 * @param {number} calls
 * @param {number} tMin
 * @param {number} tMax
 * @param {number} uMin
 * @param {number} uMax
 */
function addCurveIntersections(v1, v2, c1, c2, locations, include, flip, recursion, calls, tMin, tMax, uMin, uMax) {
    // Avoid deeper recursion, by counting the total amount of recursions,
    // as well as the total amount of calls, to avoid massive call-trees as
    // suggested by @iconexperience in #904#issuecomment-225283430.
    // See also: #565 #899 #1074
    // eslint-disable-next-line no-param-reassign
    if (++calls >= 4096 || ++recursion >= 40) {
        return calls
    }
    // Use an epsilon smaller than CURVETIME_EPSILON to compare curve-time
    // parameters in fat-line clipping code.
    const fatLineEpsilon = 1e-9,
        // Let P be the first curve and Q be the second
        q0x = v2[0], q0y = v2[1], q3x = v2[6], q3y = v2[7],
        getSignedDistance = Line.getSignedDistance,
        // Calculate the fat-line L for Q is the baseline l and two
        // offsets which completely encloses the curve P.
        d1 = getSignedDistance(q0x, q0y, q3x, q3y, v2[2], v2[3]),
        d2 = getSignedDistance(q0x, q0y, q3x, q3y, v2[4], v2[5]),
        factor = d1 * d2 > 0 ? 3 / 4 : 4 / 9,
        dMin = factor * min(0, d1, d2),
        dMax = factor * max(0, d1, d2),
        // Calculate non-parametric bezier curve D(ti, di(t)):
        // - di(t) is the distance of P from baseline l of the fat-line
        // - ti is equally spaced in [0, 1]
        dp0 = getSignedDistance(q0x, q0y, q3x, q3y, v1[0], v1[1]),
        dp1 = getSignedDistance(q0x, q0y, q3x, q3y, v1[2], v1[3]),
        dp2 = getSignedDistance(q0x, q0y, q3x, q3y, v1[4], v1[5]),
        dp3 = getSignedDistance(q0x, q0y, q3x, q3y, v1[6], v1[7]),
        // Get the top and bottom parts of the convex-hull
        hull = getConvexHull(dp0, dp1, dp2, dp3),
        top = hull[0],
        bottom = hull[1]
    let tMinClip,
        tMaxClip
    // Stop iteration if all points and control points are collinear.
    // eslint-disable-next-line no-mixed-operators
    if (d1 === 0 && d2 === 0 && dp0 === 0 && dp1 === 0 && dp2 === 0 && dp3 === 0
        // Clip convex-hull with dMin and dMax, taking into account that
        // there will be no intersections if one of the results is null.
        // eslint-disable-next-line no-mixed-operators
        || (tMinClip = clipConvexHull(top, bottom, dMin, dMax)) == null
        || (tMaxClip = clipConvexHull(top.reverse(), bottom.reverse(), dMin, dMax)) == null
    ) {
        return calls
    }
    // tMin and tMax are within the range (0, 1). Project it back to the
    // original parameter range for v2.
    const tMinNew = tMin + (tMax - tMin) * tMinClip
    const tMaxNew = tMin + (tMax - tMin) * tMaxClip
    let t, u
    if (max(uMax - uMin, tMaxNew - tMinNew) < fatLineEpsilon) {
        // We have isolated the intersection with sufficient precision
        t = (tMinNew + tMaxNew) / 2
        u = (uMin + uMax) / 2
        addLocation(
            locations, include,
            flip ? c2 : c1, flip ? u : t,
            flip ? c1 : c2, flip ? t : u
        )
    } else {
        // Apply the result of the clipping to curve 1:
        // eslint-disable-next-line no-param-reassign
        v1 = CubicBez.getPart(v1, tMinClip, tMaxClip)
        const uDiff = uMax - uMin
        if (tMaxClip - tMinClip > 0.8) {
            // Subdivide the curve which has converged the least.
            if (tMaxNew - tMinNew > uDiff) {
                const parts = CubicBez.subdivide(v1, 0.5)
                t = (tMinNew + tMaxNew) / 2
                // eslint-disable-next-line no-param-reassign
                calls = addCurveIntersections(
                    v2, parts[0], c2, c1, locations, include, !flip,
                    recursion, calls, uMin, uMax, tMinNew, t
                )
                // eslint-disable-next-line no-param-reassign
                calls = addCurveIntersections(
                    v2, parts[1], c2, c1, locations, include, !flip,
                    recursion, calls, uMin, uMax, t, tMaxNew
                )
            } else {
                const parts = CubicBez.subdivide(v2, 0.5)
                u = (uMin + uMax) / 2
                // eslint-disable-next-line no-param-reassign
                calls = addCurveIntersections(
                    parts[0], v1, c2, c1, locations, include, !flip,
                    recursion, calls, uMin, u, tMinNew, tMaxNew
                )
                // eslint-disable-next-line no-param-reassign
                calls = addCurveIntersections(
                    parts[1], v1, c2, c1, locations, include, !flip,
                    recursion, calls, u, uMax, tMinNew, tMaxNew
                )
            }
        } else { // Iterate
            // For some unclear reason we need to check against uDiff === 0
            // here, to prevent a regression from happening, see #1638.
            // Maybe @iconexperience could shed some light on this.
            if (uDiff === 0 || uDiff >= fatLineEpsilon) {
                // eslint-disable-next-line no-param-reassign
                calls = addCurveIntersections(
                    v2, v1, c2, c1, locations, include, !flip,
                    recursion, calls, uMin, uMax, tMinNew, tMaxNew
                )
            } else {
                // The interval on the other curve is already tight enough,
                // therefore we keep iterating on the same curve.
                // eslint-disable-next-line no-param-reassign
                calls = addCurveIntersections(
                    v1, v2, c1, c2, locations, include, flip,
                    recursion, calls, tMinNew, tMaxNew, uMin, uMax
                )
            }
        }
    }
    return calls
}

/**
 * @param {number} dq0
 * @param {number} dq1
 * @param {number} dq2
 * @param {number} dq3
 */
function getConvexHull(dq0, dq1, dq2, dq3) {
    const p0 = [ 0, dq0 ],
        p1 = [ 1 / 3, dq1 ],
        p2 = [ 2 / 3, dq2 ],
        p3 = [ 1, dq3 ],
        // Find vertical signed distance of p1 and p2 from line [p0, p3]
        dist1 = dq1 - (2 * dq0 + dq3) / 3,
        dist2 = dq2 - (dq0 + 2 * dq3) / 3
    let hull
    // Check if p1 and p2 are on the opposite side of the line [p0, p3]
    if (dist1 * dist2 < 0) {
        // p1 and p2 lie on different sides of [p0, p3]. The hull is a
        // quadrilateral and line [p0, p3] is NOT part of the hull so we are
        // pretty much done here. The top part includes p1, we will reverse
        // it later if that is not the case.
        hull = [[p0, p1, p3], [p0, p2, p3]]
    } else {
        // p1 and p2 lie on the same sides of [p0, p3]. The hull can be a
        // triangle or a quadrilateral and line [p0, p3] is part of the
        // hull. Check if the hull is a triangle or a quadrilateral. We have
        // a triangle if the vertical distance of one of the middle points
        // (p1, p2) is equal or less than half the vertical distance of the
        // other middle point.
        const distRatio = dist1 / dist2
        hull = [
            // p2 is inside, the hull is a triangle.
            // eslint-disable-next-line no-nested-ternary
            distRatio >= 2
                ? [p0, p1, p3]
                // p1 is inside, the hull is a triangle.
                : distRatio <= 0.5
                    ? [p0, p2, p3]
                    // Hull is a quadrilateral, we need all lines in correct order.
                    : [p0, p1, p2, p3],
            // Line [p0, p3] is part of the hull.
            [p0, p3]
        ]
    }
    // Flip hull if dist1 is negative or if it is zero and dist2 is negative
    return (dist1 || dist2) < 0 ? hull.reverse() : hull
}

/**
 * Clips the convex-hull and returns [tMin, tMax] for the curve contained.
 * @param {number[][]} hullTop
 * @param {number[][]} hullBottom
 * @param {number} dMin
 * @param {number} dMax
 */
function clipConvexHull(hullTop, hullBottom, dMin, dMax) {
    if (hullTop[0][1] < dMin) {
        // Left of hull is below dMin, walk through the hull until it
        // enters the region between dMin and dMax
        return clipConvexHullPart(hullTop, true, dMin)
    } else if (hullBottom[0][1] > dMax) {
        // Left of hull is above dMax, walk through the hull until it
        // enters the region between dMin and dMax
        return clipConvexHullPart(hullBottom, false, dMax)
    } else {
        // Left of hull is between dMin and dMax, no clipping possible
        return hullTop[0][0]
    }
}

/**
 * @param {number[][]} part
 * @param {number[][]} top
 * @param {number} threshold
 */
function clipConvexHullPart(part, top, threshold) {
    let px = part[0][0],
        py = part[0][1]
    for (let i = 1, l = part.length; i < l; i++) {
        const qx = part[i][0],
            qy = part[i][1]
        if (top ? qy >= threshold : qy <= threshold) {
            return qy === threshold ? qx
                : px + (threshold - py) * (qx - px) / (qy - py)
        }
        px = qx
        py = qy
    }
    // All points of hull are above / below the threshold
    return null
}


/**
 * Curve functions
 * @param {CurveData} v
 */
const getLengthIntegrand = (v) => {
    // Calculate the coefficients of a Bezier derivative.
    const x0 = v[0], y0 = v[1],
        x1 = v[2], y1 = v[3],
        x2 = v[4], y2 = v[5],
        x3 = v[6], y3 = v[7],

        ax = 9 * (x1 - x2) + 3 * (x3 - x0),
        bx = 6 * (x0 + x2) - 12 * x1,
        cx = 3 * (x1 - x0),

        ay = 9 * (y1 - y2) + 3 * (y3 - y0),
        by = 6 * (y0 + y2) - 12 * y1,
        cy = 3 * (y1 - y0)

    /**
     * @param {number} t
     */
    return function(t) {
        // Calculate quadratic equations of derivatives for x and y
        const dx = (ax * t + bx) * t + cx
        const dy = (ay * t + by) * t + cy
        return sqrt(dx * dx + dy * dy)
    }
}

/**
 * @param {number} a
 * @param {number} b
 *
 * @checked
 */
const getIterations = (a, b) => max(2, min(16, ceil(abs(b - a) * 32)))

/**
 * @param {CurveData} v1
 * @param {CurveData} v2
 * @param {CubicBez} c1
 * @param {CubicBez} c2
 * @param {CurveLocation[]} locations
 * @param {(loc: CurveLocation) => boolean} include
 * @param {boolean} flip
 */
function addCurveLineIntersections(v1, v2, c1, c2, locations, include, flip) {
    // addCurveLineIntersections() is called so that v1 is always the curve
    // and v2 the line. flip indicates whether the curves need to be flipped
    // in the call to addLocation().
    const x1 = v2[0], y1 = v2[1],
        x2 = v2[6], y2 = v2[7],
        roots = getCurveLineIntersections(v1, x1, y1, x2 - x1, y2 - y1)
    // NOTE: count could be -1 for infinite solutions, but that should only
    // happen with lines, in which case we should not be here.
    for (let i = 0, l = roots.length; i < l; i++) {
        // For each found solution on the rotated curve, get the point on
        // the real curve and with that the location on the line.
        const t1 = roots[i]
        const p1 = CubicBez.getPoint(v1, t1)
        const t2 = CubicBez.getTimeOf(v2, p1)
        if (t2 !== null) {
            // Only use the time values if there was no recursion, and let
            // addLocation() figure out the actual time values otherwise.
            addLocation(
                locations, include,
                flip ? c2 : c1, flip ? t2 : t1,
                flip ? c1 : c2, flip ? t1 : t2
            )
        }
    }
}

/**
 * Intersections between curve and line becomes rather simple here mostly
 * because of Numerical class. We can rotate the curve and line so that the
 * line is on the X axis, and solve the implicit equations for the X axis
 * and the curve.
 * @param {CurveData} v
 * @param {number} px
 * @param {number} py
 * @param {number} vx
 * @param {number} vy
 */
function getCurveLineIntersections(v, px, py, vx, vy) {
    const isZero = Numerical.isZero
    if (isZero(vx) && isZero(vy)) {
        // Handle special case of a line with no direction as a point,
        // and check if it is on the curve.
        const t = CubicBez.getTimeOf(v, new Vector2(px, py))
        return t === null ? [] : [t]
    }
    // Calculate angle to the x-axis (1, 0).
    const angle = atan2(-vy, vx)
    const sin = Math.sin(angle)
    const cos = Math.cos(angle)
    // Calculate the curve values of the rotated curve.
    /** @type {number[]} */
    const rv = []
    /** @type {number[]} */
    const roots = []
    for (let i = 0; i < 8; i += 2) {
        const x = v[i] - px
        const y = v[i + 1] - py
        rv.push(
            x * cos - y * sin,
            x * sin + y * cos
        )
    }
    // Solve it for y = 0. We need to include t = 0, 1 and let addLocation()
    // do the filtering, to catch important edge cases.
    CubicBez.solveCubic(rv, 1, 0, roots, 0, 1)
    return roots
}

const GAUSS_LEGENDRE_COEFFS_8 = [
    0.3626837833783620, -0.1834346424956498,
    0.3626837833783620, 0.1834346424956498,
    0.3137066458778873, -0.5255324099163290,
    0.3137066458778873, 0.5255324099163290,
    0.2223810344533745, -0.7966664774136267,
    0.2223810344533745, 0.7966664774136267,
    0.1012285362903763, -0.9602898564975363,
    0.1012285362903763, 0.9602898564975363,
]

const GAUSS_LEGENDRE_COEFFS_8_HALF = [
    0.3626837833783620, 0.1834346424956498,
    0.3137066458778873, 0.5255324099163290,
    0.2223810344533745, 0.7966664774136267,
    0.1012285362903763, 0.9602898564975363,
]

const GAUSS_LEGENDRE_COEFFS_16_HALF = [
    0.1894506104550685, 0.0950125098376374,
    0.1826034150449236, 0.2816035507792589,
    0.1691565193950025, 0.4580167776572274,
    0.1495959888165767, 0.6178762444026438,
    0.1246289712555339, 0.7554044083550030,
    0.0951585116824928, 0.8656312023878318,
    0.0622535239386479, 0.9445750230732326,
    0.0271524594117541, 0.9894009349916499,
]

const GAUSS_LEGENDRE_COEFFS_24_HALF = [
    0.1279381953467522, 0.0640568928626056,
    0.1258374563468283, 0.1911188674736163,
    0.1216704729278034, 0.3150426796961634,
    0.1155056680537256, 0.4337935076260451,
    0.1074442701159656, 0.5454214713888396,
    0.0976186521041139, 0.6480936519369755,
    0.0861901615319533, 0.7401241915785544,
    0.0733464814110803, 0.8200019859739029,
    0.0592985849154368, 0.8864155270044011,
    0.0442774388174198, 0.9382745520027328,
    0.0285313886289337, 0.9747285559713095,
    0.0123412297999872, 0.9951872199970213,
]

/**
 * @param {CurveData} curve
 * @param {number} y0
 * @param {number} x1
 * @param {number} y1
 * @param {number} x2
 * @param {number} y2
 * @param {number} x3
 * @param {number} y3
 * @param {number} accuracy
 * @param {number} depth
 * @returns {number}
 */
function arclenRec(curve, accuracy, depth) {
    const [
        x0, y0,
        x1, y1,
        x2, y2,
        x3, y3,
    ] = curve

    const d03x = x3 - x0
    const d03y = y3 - y0
    const d01x = x1 - x0
    const d01y = y1 - y0
    const d12x = x2 - x1
    const d12y = y2 - y1
    const d23x = x3 - x2
    const d23y = y3 - y2
    const lp_lc = Math.hypot(d01x, d01y) + Math.hypot(d12x, d12y)
        + Math.hypot(d23x, d23y) - Math.hypot(d03x, d03y)
    const dd1x = d12x - d01x
    const dd1y = d12y - d01y
    const dd2x = d23x - d12x
    const dd2y = d23y - d12y
    const dmx = 0.25 * (d01x + d23x) + 0.5 * d12x
    const dmy = 0.25 * (d01y + d23y) + 0.5 * d12y
    const dm1x = 0.5 * (dd2x + dd1x)
    const dm1y = 0.5 * (dd2y + dd1y)
    const dm2x = 0.25 * (dd2x - dd1x)
    const dm2y = 0.25 * (dd2y - dd1y)
    const co_e = GAUSS_LEGENDRE_COEFFS_8
    let est = 0
    for (let i = 0; i < co_e.length; i += 2) {
        const xi = co_e[i + 1]
        const dx = dmx + dm1x * xi + dm2x * (xi * xi)
        const dy = dmy + dm1y * xi + dm2y * (xi * xi)
        const ddx = dm1x + dm2x * (2 * xi)
        const ddy = dm1y + dm2y * (2 * xi)
        est += co_e[i] * (ddx * ddx + ddy * ddy) / (dx * dx + dy * dy)
    }
    const est3 = est * est * est
    let co = null
    if (Math.min(est3 * 2.5e-6, 3e-2) * lp_lc < accuracy) {
        co = GAUSS_LEGENDRE_COEFFS_8_HALF
    } else if (Math.min(est3 * est3 * 1.5e-11, 9e-3) * lp_lc < accuracy) {
        co = GAUSS_LEGENDRE_COEFFS_16_HALF
    } else if (Math.min(est3 * est3 * est3 * 3.5e-16, 3.5e-3) * lp_lc < accuracy
        || depth >= 20)
    {
        co = GAUSS_LEGENDRE_COEFFS_24_HALF
    } else {
        const [c0, c1] = CubicBez.subdivide(curve, 0.5)
        return (
            arclenRec(c0, accuracy * 0.5, depth + 1)
            +
            arclenRec(c1, accuracy * 0.5, depth + 1)
        )
    }
    let sum = 0
    for (let i = 0; i < co.length; i += 2) {
        const xi = co[i + 1]
        const wi = co[i]
        const dx = dmx + dm2x * (xi * xi)
        const dy = dmy + dm2y * (xi * xi)
        const dp = Math.hypot(dx + dm1x * xi, dy + dm1y * xi)
        const dm = Math.hypot(dx - dm1x * xi, dy - dm1y * xi)
        sum += wi * (dp + dm)
    }
    return 1.5 * sum
}
