import React, { forwardRef, useState } from 'react'

import { TFunction } from 'i18next'

import { PropertyStateProps } from '../../Properties/PropertyEditors/PropertyState'
import Input from '../Input'
import Text from '../Text'

// @ts-ignore TODO: fix after refactor of Input
interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  variant?: 'normal' | 'flat'
  inputSize?: 's' | 'l'
  align?: 'left' | 'center' | 'right'
  value: string | number
  type?: 'text' | 'number' | 'password' | 'email' | 'search'
  min?: number
  max?: number
  step?: number
  onInput?: (value: any) => void
  onChange?: (value: any, options?: { commit: boolean; delta?: InputProps['mixed'] }, forceUpdate?: boolean) => void
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  onEnterKey?: () => void
  onStepChange?: () => void

  mixed?: boolean
  spinner?: boolean
  disabled?: boolean
  reachMinmax?: boolean
  keepInvalid?: boolean
  alwaysForceUpdate?: boolean

  formatter?: (props: any) => string
  validator?: (value: string, t: TFunction) => string
  validateOnChange?: boolean

  onErrorChange?: (error: string) => void
  showErrorTips?: boolean
  errorOverride?: string

  rightText?: string
  rightComponent?: React.ReactNode
  tooltip?: string
  keyFrameIconProps?: Omit<PropertyStateProps, 'hoverContent' | 'hoverWrap' | 'rightComponent' | 'className'>
  commitUndo?: () => void
  updateIS?: (flag: boolean) => void

  dataTestId?: string
  labelClassName?: string
  className?: string
}

interface InputFieldProps extends InputProps {
  label?: string
  labelClassName?: string
  labelFontClassName?: string
  className?: string
}

export type InputFieldRefProps = {
  onValidate: () => boolean
  focus: () => void
  blur: () => void
  select: () => void
}

const InputField = forwardRef<InputFieldRefProps, InputFieldProps>(
  ({ label, labelClassName = 'mb-12', labelFontClassName, className = '', ...props }, ref) => {
    const [error, setError] = useState('')

    return (
      <div className={`w-full ${labelClassName}`}>
        {label && <Text className={`mb-8 ${labelFontClassName}`}>{label}</Text>}
        <Input
          {...props}
          // @ts-ignore TODO: fix after refactor of Input
          variant="normal"
          showErrorTips={!!error}
          ref={ref}
          onErrorChange={setError}
          keepInvalid
          inputSize="l"
          labelClassName={className}
        />
      </div>
    )
  }
)

InputField.displayName = 'InputField'

export default InputField
