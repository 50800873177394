import { useCallback, useEffect, memo } from 'react'
import { useDataStore } from '../../providers/dataStore/DataStoreProvider'
import { useSetTransitionManager } from '../../providers/TransitionManagerProvider'

import { TM_PROPS_MAP } from '@phase-software/transition-manager'

const SubscribeTransitionManagerChange = () => {
  const instance = useDataStore().transition

  const { changeTransitionStatus, changeTransitionEndTime, changeTransitionLoop, changeTransitionPlaybackRate, changeTransitionPlayheadTime } =
    useSetTransitionManager()

  const bindEvents = useCallback(() => {
    if (!instance) {
      return
    }

    // Update endTime before bind events because endTime update at the very beginning.
    changeTransitionEndTime(instance.endTime)
    changeTransitionLoop(instance.currentLoopStatus)
    changeTransitionPlaybackRate(instance.currentPlaybackRate)

    instance.on(TM_PROPS_MAP.status, changeTransitionStatus)
    instance.on(TM_PROPS_MAP.endTime, changeTransitionEndTime)
    instance.on(TM_PROPS_MAP.looping, changeTransitionLoop)
    instance.on(TM_PROPS_MAP.viewerLoop, changeTransitionLoop)
    instance.on(TM_PROPS_MAP.playbackRate, changeTransitionPlaybackRate)
    instance.on(TM_PROPS_MAP.viewerPlaybackRate, changeTransitionPlaybackRate)
    instance.on(TM_PROPS_MAP.time, changeTransitionPlayheadTime)
  }, [instance, changeTransitionStatus, changeTransitionEndTime, changeTransitionLoop, changeTransitionPlaybackRate, changeTransitionPlayheadTime])

  const unbindEvents = useCallback(() => {
    if (!instance) {
      return
    }

    instance.off(TM_PROPS_MAP.status, changeTransitionStatus)
    instance.off(TM_PROPS_MAP.endTime, changeTransitionEndTime)
    instance.off(TM_PROPS_MAP.looping, changeTransitionLoop)
    instance.off(TM_PROPS_MAP.viewerLoop, changeTransitionLoop)
    instance.off(TM_PROPS_MAP.playbackRate, changeTransitionPlaybackRate)
    instance.off(TM_PROPS_MAP.viewerPlaybackRate, changeTransitionPlaybackRate)
    instance.off(TM_PROPS_MAP.time, changeTransitionPlayheadTime)
  }, [instance, changeTransitionStatus, changeTransitionEndTime, changeTransitionLoop, changeTransitionPlaybackRate, changeTransitionPlayheadTime])

  useEffect(() => {
    bindEvents()

    return () => unbindEvents()
  }, [bindEvents, unbindEvents])

  return null
}

export default memo(SubscribeTransitionManagerChange)
