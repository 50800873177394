import React, { useState, createContext, useContext, useMemo, useRef, useCallback, useEffect } from 'react'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { useProfile } from '../providers/ProfileProvider'
import { PresenceCallbackMessageData, palette } from '../Broadcaster/PresenceManager'
import { FEATURE_KEYS } from '../growthbook-feature-keys'
import { useFileContext } from '../providers/FileProvider'
import { presenceService } from '../services/api'

type PresenceUsersContextType = {
  users: Map<string, PresenceCallbackMessageData>
  setUsers: React.Dispatch<React.SetStateAction<Map<string, PresenceCallbackMessageData>>>
  onlineUsers: PresenceCallbackMessageData[]
  idleUsers: PresenceCallbackMessageData[]
}

type PresenceUsersProviderProps = {
  children: React.ReactNode
}

const defaultValue = {
  users: new Map(),
  setUsers: () => {},
  onlineUsers: [],
  idleUsers: []
}

const PresenceUsersContext = createContext<PresenceUsersContextType>(defaultValue)

const PresenceUsersProvider = ({ children }: PresenceUsersProviderProps) => {
  const [users, setUsers] = useState<Map<string, PresenceCallbackMessageData>>(defaultValue.users)
  const onlineUsers = useMemo(() => {
    const onlineGroup = Array.from(users.values()).filter((participant) => participant.idleTime === null)
    return onlineGroup.sort((a, b) => new Date(b.joinTime).getTime() - new Date(a.joinTime).getTime())
  }, [users])

  const idleUsers = useMemo(() => {
    const idleGroup = Array.from(users.values()).filter((participant) => participant.idleTime !== null)
    return idleGroup.sort((a, b) => new Date(b.joinTime).getTime() - new Date(a.joinTime).getTime())
  }, [users])

  return (
    <PresenceUsersContext.Provider
      value={{
        users,
        setUsers,
        onlineUsers,
        idleUsers
      }}
    >
      {children}
    </PresenceUsersContext.Provider>
  )
}

export const usePresenceUsers = () => {
  const { setUsers } = usePresenceUsersContext()
  const isPresenceEnabled = useFeatureIsOn(FEATURE_KEYS.PRESENCE)
  const { id: fileId } = useFileContext()
  const profile = useProfile()
  const profileRef = useRef(profile)

  const handlePresenceMessage = useCallback(
    (message: PresenceCallbackMessageData) => {
      const { type, tabId } = message
      setUsers((prev) => {
        const newMap = new Map(prev)
        if (type === 'presence.remove' && newMap.has(tabId)) {
          newMap.delete(tabId)
        } else {
          newMap.set(tabId, message)
        }
        return newMap
      })
    },
    [setUsers]
  )

  const fetchParticipants = useCallback(async (fileId: string) => {
    let res
    try {
      res = (await presenceService.apiPresenceCollaboratorsFileIdGet({ fileId })) as PresenceCallbackMessageData[]
    } catch (error) {
      console.error('[usePresence] fetchParticipants error :>> ', error)
    }
    return res
  }, [])

  const setFileParticipants = useCallback(
    (participants: PresenceCallbackMessageData[]) => {
      const userMap = participants.reduce((prev, curr) => {
        const { tabId, serialNo = 1 } = curr
        const newStates = {
          ...curr,
          color: palette[(serialNo - 1) % palette.length]
        }
        prev.set(tabId, newStates)
        return prev
      }, new Map())

      setUsers(userMap)

      if (window.PresenceManager) {
        window.PresenceManager.setUsers(userMap)
      }
    },
    [setUsers]
  )

  useEffect(() => {
    if (fileId && isPresenceEnabled && profileRef.current) {
      fetchParticipants(fileId)
        .then((res) => {
          if (res) {
            setFileParticipants(res)
          }
        })
        .finally(() => {
          if (window.PresenceManager) {
            window.PresenceManager.subscribePresenceEditor(fileId, profileRef.current, handlePresenceMessage)
          }
        })
    }
  }, [fileId, isPresenceEnabled, fetchParticipants, setFileParticipants, handlePresenceMessage])
}

function usePresenceUsersContext(): PresenceUsersContextType {
  const context = useContext(PresenceUsersContext)
  if (context === undefined) {
    throw new Error('usePresenceUsersContext must be used within a PresenceProvider')
  }
  return context
}

export { PresenceUsersProvider, usePresenceUsersContext }
