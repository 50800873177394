import { useCallback } from 'react'
import { Sanitizer } from '@phase-software/data-store'

import { projectFileService } from '../services/api'
import { unzipContent } from '../utils/file'

const sanitizer = new Sanitizer()

const useFileVersionActions = () => {
  const getVersionContent = useCallback(
    async ({ fileId, projectId, versionId }: { fileId: string; projectId: string; versionId: string }) => {
      try {
        const zippedContent = await projectFileService.projectFileControllerGetVersion({
          fileId,
          projectId,
          versionId
        })

        const content = await unzipContent(zippedContent, false)
        sanitizer.clear()
        sanitizer.sanitize(content)
        return content
      } catch (error) {
        console.error(error)
      }
    },
    []
  )

  const createNewVersion = async ({
    fileId,
    projectId,
    name,
    description
  }: {
    fileId: string
    projectId: string
    name: string
    description: string
  }) => {
    const { id } = await projectFileService.projectFileControllerCreateNamedVersion({
      fileId,
      projectId,
      createNamedVersionRequestV1: {
        name,
        description
      }
    })
    return id
  }

  const resetVersion = async ({
    fileId,
    projectId,
    versionId
  }: {
    fileId: string
    projectId: string
    versionId: string
  }) => {
    await projectFileService.projectFileControllerResetVersion({
      fileId,
      projectId,
      versionId
    })
  }

  const updateVersion = async ({
    fileId,
    projectId,
    versionId,
    name,
    description
  }: {
    fileId: string
    projectId: string
    versionId: string
    name: string
    description: string
  }) =>
    await projectFileService.projectFileControllerUpdateVersion({
      fileId,
      projectId,
      versionId,
      updateVersionRequestV1: {
        name,
        description
      }
    })

  const restoreVersion = async ({
    fileId,
    projectId,
    versionId,
    tabId
  }: {
    fileId: string
    projectId: string
    versionId: string
    tabId: string
  }) => {
    await projectFileService.projectFileControllerRestoreVersion({
      fileId,
      projectId,
      versionId,
      restoreVersionRequest: {
        tabId
      }
    })
  }

  const createFileByVersion = async ({
    fileId,
    projectId,
    versionId,
    fileName,
    copyCollaborators
  }: {
    fileId: string
    projectId: string
    versionId: string
    fileName: string
    copyCollaborators: boolean
  }) => {
    const { fileId: newFileId } = await projectFileService.projectFileControllerCreateFileFromAVersion({
      fileId,
      projectId,
      versionId,
      createFileFromAVersionRequest: {
        fileName,
        copyCollaborators
      }
    })
    return newFileId
  }

  return {
    getVersionContent,
    createNewVersion,
    resetVersion,
    updateVersion,
    restoreVersion,
    createFileByVersion
  }
}

export default useFileVersionActions
