import React, { forwardRef } from 'react'

import PropTypes from 'prop-types'

import PlayHead from './PlayHead'
import Ruler from './Ruler'

const TimeRuler = forwardRef(({ trackRef, cacheRef, playHeadRef }, ref) => {
  return (
    <div
      className="relative h-40 bg-neutral-90 z-30 pt-action-ruler border-b border-neutral-80"
      style={{ gridArea: 'ruler' }}
    >
      <Ruler ref={ref} trackRef={trackRef} cacheRef={cacheRef} playHeadRef={playHeadRef} />
      <PlayHead ref={playHeadRef} rulerRef={ref} cacheRef={cacheRef} />
    </div>
  )
})
TimeRuler.displayName = 'TimeRuler'
TimeRuler.propTypes = {
  playHeadRef: PropTypes.object,
  trackRef: PropTypes.object,
  cacheRef: PropTypes.object
}

export default React.memo(TimeRuler)
