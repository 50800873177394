import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { IfFeatureEnabled, useFeatureIsOn, useFeatureValue } from '@growthbook/growthbook-react'

import AvatarField from '../../components/Settings/AvatarField'
import EmailField from '../../components/Settings/EmailField'
import LanguageField from '../../components/Settings/LanguageField'
import NameField from '../../components/Settings/NameField'
import { Checkbox, Form, Grid, LinkSwitcher, ProgressButton } from '../../components/shared'
import { WEB_CONFIG_DEFAULT_VALUES } from '../../configs/growthBookDefaultValue'
import { FEATURE_KEYS } from '../../growthbook-feature-keys'
import { useSetNotification } from '../../providers/NotificationProvider'
import { track } from '../../services/heapAnalytics'
import { usernameValidator } from '../../utils/validator'
import { usePersonalSettingsContext } from './PersonalSettingsContext'

const ProfileSettings = () => {
  const {
    setUsername,
    setPreferredLanguage,
    setNewsletterSubscription,
    username,
    preferredLanguage,
    avatar,
    email,
    newsletterSubscription,
    changeUsername,
    changePreferredLanguage,
    changeNewsletterSubscription,
    updateAvatar,
    removeAvatar
  } = usePersonalSettingsContext()
  const { addNotification } = useSetNotification()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { tos, privacy } = useFeatureValue(FEATURE_KEYS.WEB_CONFIG, WEB_CONFIG_DEFAULT_VALUES)
  const isMultilingualSupported = useFeatureIsOn(FEATURE_KEYS.MULTILINGUAL_SUPPORT)

  const { t } = useTranslation(['setting', 'workspace'])
  const disabledSubmit = !username || username.trim().length === 0 || username.trim().length > 25

  const handleSubmit = async () => {
    if (disabledSubmit || isSubmitting) return

    try {
      setIsSubmitting(true)
      await changeUsername()
      await changeNewsletterSubscription()
      if (isMultilingualSupported) {
        await changePreferredLanguage()
      }
      addNotification({ type: 'success', content: t('workspace:message.profile_updated') })
      track('Account Settings Updated')
    } catch (error) {
      console.error(error)
      addNotification({ type: 'error', content: t('workspace:message.failed') })
    } finally {
      setIsSubmitting(false)
    }
  }

  const showNotification = (type: 'update' | 'remove') => {
    if (type === 'update') {
      addNotification({ type: 'success', content: t('profile_setting.avatar_photo_updated') })
    } else {
      addNotification({ type: 'success', content: t('profile_setting.avatar_photo_removed') })
    }
  }

  const handleUpdateAvatar = async (e: File) => {
    updateAvatar(e)
    showNotification('update')
  }
  const handleRemoveAvatar = async () => {
    await removeAvatar()
    showNotification('remove')
  }
  const onSubscriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewsletterSubscription(e.currentTarget.checked)
  }

  return (
    // @ts-ignore TODO: fix after refactor of Form
    <Form onSubmit={handleSubmit}>
      {/* @ts-ignore TODO: fix after refactor of Grid */}
      <Grid gridGap={24} className="mt-32">
        <AvatarField
          name={username}
          value={avatar}
          updateAvatar={handleUpdateAvatar}
          removeAvatar={handleRemoveAvatar}
        />
        <NameField
          label={t('profile_setting.username')}
          value={username}
          onChange={setUsername}
          validator={(value) => usernameValidator(value, t)}
          size="full"
        />
        <IfFeatureEnabled feature={FEATURE_KEYS.MULTILINGUAL_SUPPORT}>
          <LanguageField
            label={t('profile_setting.language')}
            selectedLanguage={preferredLanguage}
            onChange={setPreferredLanguage}
            size="full"
          />
        </IfFeatureEnabled>
        <div className="flex flex-col gap-y-12">
          <EmailField value={email} size="full" />
          <Checkbox
            // @ts-ignore TODO: fix after refactor of Checkbox
            checked={newsletterSubscription}
            onChange={onSubscriptionChange}
            label={
              <Trans
                i18nKey="setting:profile_setting.email_subscription"
                values={{ terms: t('profile_setting.terms_of_service'), privacy: t('profile_setting.privacy_policy') }}
                components={[
                  <LinkSwitcher key="tos" to={tos} className="text-primary-40" />,
                  <LinkSwitcher key="privacy" to={privacy} className="text-primary-40" />
                ]}
              />
            }
          />
        </div>
        <ProgressButton className="w-fit" type="submit" status={Number(isSubmitting)} data-test-id="name-input-submit">
          {t('update')}
        </ProgressButton>
      </Grid>
    </Form>
  )
}

export default ProfileSettings
