import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import { useApolloClient } from '@apollo/client'
import { LoadingStatus } from '@phase-software/types'

import { DEFAULT_DIALOG_TRANSITION_TIME } from '../../constant'
import { FileFieldsFragment } from '../../generated/graphql'
import useFileActions from '../../hooks/useFileActions'
import { useSetNotification } from '../../providers/NotificationProvider'
import { LiveblocksService } from '../../services/api'
import { Dialog, Text } from '../shared'

type DeleteFileDialogProps = {
  projectId: FileFieldsFragment['project_id']
  id: FileFieldsFragment['id']
  name: FileFieldsFragment['name']
  open: boolean
  redirectTo?: string
  onClose: () => void
}

const DeleteFileDialog = ({ projectId, id, name, open, onClose, redirectTo = '' }: DeleteFileDialogProps) => {
  const { t } = useTranslation(['workspace', 'common'])
  const client = useApolloClient()
  const history = useHistory()
  const { deleteFile } = useFileActions()
  const { addNotification } = useSetNotification()

  const [isDeleting, setIsDeleting] = useState(false)

  const handleDelete = async () => {
    if (isDeleting) return
    setIsDeleting(true)
    try {
      await deleteFile({ projectId, fileId: id })
      try {
        await LiveblocksService.deleteLiveblocksRoom({ deleteLiveblocksRoomRequest: { room: id } })
      } catch (error) {
        const message = (error as Error).message
        if (message !== 'ROOM_NOT_FOUND') {
          throw error
        }
      }
      // After client.cache.evict, filteredFiles will update, and the originally selected file will disappear immediately, leaving no time for a transition
      // The delay of DEFAULT_DIALOG_TRANSITION_TIME is to ensure that the transition works properly
      setTimeout(() => {
        client.cache.evict({ id: `files:${id}` })
      }, DEFAULT_DIALOG_TRANSITION_TIME)
      addNotification({
        type: 'success',
        content: (
          <Trans
            i18nKey="workspace:deleted"
            values={{ name }}
            components={[<span key="file-deleted" className="font-semibold" />]}
          />
        )
      })
      onClose()
      if (redirectTo) {
        history.push(redirectTo)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsDeleting(false)
    }
  }
  return (
    <Dialog
      data-test-id="delete-confirm-dialog"
      size="xs"
      title={t('delete_file')}
      dangerConfirmBtnText={t('common:button.delete')}
      cancelBtnText={t('common:button.cancel')}
      onDangerConfirm={handleDelete}
      onCancel={onClose}
      disableDangerConfirm={isDeleting}
      showProgressButton
      progressStatus={isDeleting ? LoadingStatus.WAITING : LoadingStatus.INITIAL}
      open={open}
    >
      <Text ellipsis={false} className="font-medium break-all">
        <Trans
          i18nKey="workspace:delete_file_confirmation"
          values={{ name }}
          components={[
            <span
              key="delete_file_confirmation"
              className="text-white font-semibold"
              data-test-id="delete_file_confirmation"
            />
          ]}
        />
      </Text>
    </Dialog>
  )
}

export default DeleteFileDialog
