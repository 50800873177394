import React from 'react'

type FieldProps = {
  label?: string
  children: React.ReactNode
  className?: string
  size?: 'normal' | 'full'
}
const Field = ({ label, children, className = '', size = 'normal' }: FieldProps) => {
  return (
    <div className={`${size === 'normal' ? 'w-[400px]' : 'w-auto'} ${className}`}>
      {label && <p className="text-13 font-medium text-white mb-8">{label}</p>}
      {children}
    </div>
  )
}

export default Field
