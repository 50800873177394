import { MenuOptionProps } from './MenuOption'
import { OPTION_SEPARATOR, ARROW_DOWN, ARROW_UP } from './Menu.constants'

export type { MenuProps } from './index'
export type { MenuOptionProps } from './MenuOption'
export type MenuListOptionProps = MenuOptionProps | typeof OPTION_SEPARATOR

export const MenuType = {
  SELECT_MENU: 'select',
  CONTEXT_MENU: 'context',
  DROPDOWN_MENU: 'dropdown'
} as const

export type ArrowDirection = typeof ARROW_UP | typeof ARROW_DOWN
