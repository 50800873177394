import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { PaintType } from '@phase-software/types'

import { ColorFormat, useInspectContext } from '../../../providers/InspectProvider'
import { rgb2hsb, rgb2hsl } from '../../../utils/color'
import { formatAsPercentage, formatNumberWithUnit, multiplyBy100 } from '../../../utils/formatter'
import { getPaintPreviewText } from '../../Properties/PropertyEditors/PaintPreview/utils'

const GRADIENT_NAME_MAP: Record<string, string> = {
  linear: 'file:paint_type.linear_gradient',
  radial: 'file:paint_type.radial_gradient',
  angular: 'file:paint_type.angular_gradient',
  diamond: 'file:paint_type.diamond_gradient'
}

const useColorTextGenerator = () => {
  const { t } = useTranslation('file')
  const { colorFormat } = useInspectContext()

  const generateColorString = useCallback(
    (color: number[], opacity: number, percent = true) => {
      const [r, g, b, a] = color
      let hsl
      let hsb
      let text

      switch (colorFormat) {
        case ColorFormat.HEX:
          text = getPaintPreviewText({ paintType: PaintType.SOLID, color })
          if (!percent) return `#${text}, ${formatNumberWithUnit(opacity)}`
          return `#${text}, ${formatAsPercentage(multiplyBy100(opacity))}`
        case ColorFormat.RGB:
          return `rgba(${Math.round(r * 255)}, ${Math.round(g * 255)}, ${Math.round(b * 255)}, ${a})`
        case ColorFormat.HSL:
          hsl = rgb2hsl(r, g, b)
          return `hsla(${hsl[0].toFixed(0)}, ${hsl[1].toFixed(0)}%, ${hsl[2].toFixed(0)}%, ${a})`
        case ColorFormat.HSB:
          hsb = rgb2hsb(r, g, b)
          return `hsba(${hsb[0].toFixed(0)}, ${hsb[1].toFixed(0)}%, ${hsb[2].toFixed(0)}%, ${a})`
        default:
          return ''
      }
    },
    [colorFormat]
  )

  const getValues = (paintType: PaintType, color: number[], opacity: number, percent = true) => {
    let colorText = getPaintPreviewText({ paintType, color })
    if (paintType === PaintType.SOLID) {
      colorText = generateColorString(color, opacity, percent)
      return colorText
    }
    if (paintType !== PaintType.IMAGE) {
      return t(GRADIENT_NAME_MAP[colorText])
    }
    if (paintType === PaintType.IMAGE) {
      return t('inspect.image')
    }
    return colorText
  }

  return { generateColorString, getValues }
}

export default useColorTextGenerator
