import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import { useApolloClient } from '@apollo/client'
import { LoadingStatus } from '@phase-software/types'

import useHeapAnalytics from '../../hooks/useHeapAnalytics'
import { useWorkspaceActions } from '../../hooks/useWorkspaceActions'
import { WorkspaceData } from '../../providers/WorkspaceContextProvider'
import { track } from '../../services/heapAnalytics'
import { getWorkspaceProjectPath } from '../../utils/pathGenerators'
import { projectNameValidator } from '../../utils/validator'
import { Dialog, InputField } from '../shared'
import { InputFieldRefProps } from '../shared/InputField'

type CreateProjectDialogProps = {
  workspaceId: WorkspaceData['id']
  workspaceType: WorkspaceData['type']
  workspaceSlug: WorkspaceData['slug']
  open: boolean
  onClose: () => void
}

const CreateProjectDialog = ({
  workspaceId,
  workspaceType,
  workspaceSlug,
  open,
  onClose
}: CreateProjectDialogProps) => {
  const { t } = useTranslation(['workspace', 'common'])
  const history = useHistory()
  const client = useApolloClient()
  const { space, teamName } = useHeapAnalytics()
  const { createProject } = useWorkspaceActions(workspaceType, workspaceId)

  const inputRef = useRef<InputFieldRefProps>(null)
  const [name, setName] = useState('')
  const [isCreating, setIsCreating] = useState(false)

  const isCreateButtonDisable: boolean = isCreating || !!projectNameValidator(name, t)

  useEffect(() => {
    if (open && inputRef.current) {
      inputRef.current.focus()
    }
  }, [open])

  const handleChangeName = (newName: string) => {
    setName(newName)
  }

  const handleCreate = async () => {
    if (isCreateButtonDisable) return
    setIsCreating(true)

    try {
      const { id } = await createProject(name)

      client.refetchQueries({
        include: 'active'
      })

      track('New Project Created', {
        projectId: id,
        space,
        teamName
      })

      history.push(getWorkspaceProjectPath(workspaceType, workspaceSlug, id))
      handleClose()
    } catch (error) {
      console.error(error)
    } finally {
      setIsCreating(false)
    }
  }

  const handleClose = () => {
    setName('')
    onClose()
  }

  return (
    <Dialog
      data-test-id="create-project-dialog"
      size="xs"
      title={t('workspace:add_a_new_project')}
      showProgressButton
      progressStatus={isCreating ? LoadingStatus.WAITING : LoadingStatus.INITIAL}
      confirmBtnText={t('common:button.create')}
      cancelBtnText={t('common:button.cancel')}
      onConfirm={handleCreate}
      onCancel={handleClose}
      disableConfirm={isCreateButtonDisable}
      open={open}
    >
      <InputField
        ref={inputRef}
        type="text"
        value={name}
        placeholder={t('workspace:project_name')}
        validator={(value) => projectNameValidator(value, t)}
        onInput={handleChangeName}
        onEnterKey={handleCreate}
        labelClassName="mb-0"
      />
    </Dialog>
  )
}

export default CreateProjectDialog
