import { ElementType } from '@phase-software/types'
import { getElementType } from './utils'

export class Group {
  constructor(node, parent) {
    this.id = node.get('id')
    this.parent = parent
    this.node = node
    this.children = []
  }
}

export class Shape {
  constructor(node, parent) {
    this.id = node.get('id')
    this.parent = parent
    this.node = node
  }
}

export class Layer {
  constructor(node, parentLayer) {
    this.id = node?.get('id')
    this.node = node
    this.children = []
    this.parent = parentLayer
    this.matte = false
    this.matteTarget = false
  }
}

export class TransformLayer {
  constructor(node, parentLayer) {
    this.id = node.get('id')
    this.node = node
    this.parent = parentLayer
  }
}

class SceneTree {
  constructor(screen) {
    this.layers = []
    this.layerMap = new Map()
    this.layerIndexMap = new Map()
    this.nodeMap = new Map()

    screen.children
      .slice()
      .reverse()
      .forEach(node => {
        this.parseNode(node, null, null)
      })
  }

  _addLayer(layer) {
    this.layers.push(layer)

    if (!this.layerIndexMap.has(layer.id)) {
      this.layerIndexMap.set(layer.id, this.layers.length - 1)
    }

    if (!this.layerMap.has(layer.id)) {
      this.layerMap.set(layer.id, layer)
    }
  }

  createNullLayer(container) {
    const parentIds = [container.node.get('id')]
    let parent = container.parent
    while (parent) {
      parentIds.push(parent.node.get('id'))
      parent = parent.parent
    }

    let parentLayer = null
    while (parentIds.length) {
      const id = parentIds.pop()
      if (this.layerMap.has(id)) {
        parentLayer = this.layerMap.get(id)
      } else {
        const node = this.nodeMap.get(id)
        parentLayer = new TransformLayer(node, parentLayer)
        this._addLayer(parentLayer)
      }
    }

    return parentLayer
  }
  parsePathNode(node, parent) {
    if (parent) {
      const shape = new Shape(node, parent)
      parent.children.push(shape)
    } else {
      const shapeLayer = new Layer(node, null)
      this._addLayer(shapeLayer)

      const shape = new Shape(node, shapeLayer)
      shapeLayer.children.push(shape)
    }
    return false
  }

  parseMaskGroupNode(node, parent, layer) {
    const matteLayer = new Layer(node, layer)
    matteLayer.matteTarget = true
    this._addLayer(matteLayer)

    const maskedLayer = new Layer(node, layer)
    maskedLayer.matte = true

    const [mask, ...masked] = node.children.slice().reverse()
    const maskElementType = getElementType(mask)
    if (maskElementType === ElementType.MASK_CONTAINER) {
      this.parseNode(mask, matteLayer, matteLayer)
      this.layers.push(maskedLayer)
    } else {
      this.layers.push(maskedLayer)
      this.parseNode(mask, matteLayer, matteLayer)
    }

    masked.forEach(child => {
      this.parseNode(child, maskedLayer, maskedLayer)
    })
    return false
  }

  parseContainerNode(node, parent, layer) {
    let container
    let currentLayer = layer
    if (parent) {
      container = new Group(node, parent)
      parent.children.push(container)
    } else {
      container = new Layer(node, null)
      this._addLayer(container)
      currentLayer = container
    }
    let shouldSplitContainer = false
    let shouldCreateNewLayer = false
    node.children
      .slice()
      .reverse()
      .forEach(child => {
        if (getElementType(child) === ElementType.MASK_CONTAINER) {
          // If the child is a mask container, create a new null layer for it
          // and parse its children under the new layer
          currentLayer = this.createNullLayer(container)
          container = currentLayer
          this.parseNode(child, currentLayer, currentLayer)
          shouldCreateNewLayer = true
        } else {
          // If we've just finished parsing a mask container, create a new
          // layer for the rest of the children and parse them under the new layer
          if (shouldCreateNewLayer) {
            shouldCreateNewLayer = false
            currentLayer = new Layer(null, container)
            this._addLayer(currentLayer)
            container = currentLayer
          }
          shouldCreateNewLayer = this.parseNode(child, container, currentLayer)
        }
        if (shouldCreateNewLayer) {
          shouldSplitContainer = true
        }
      })
    return shouldSplitContainer
  }

  parseNode(node, parent, layer) {
    this.nodeMap.set(node.get('id'), node)
    switch (getElementType(node)) {
      case ElementType.PATH:
        return this.parsePathNode(node, parent)
      case ElementType.MASK_CONTAINER:
        return this.parseMaskGroupNode(node, parent, layer)
      // parse boolean container as regular container
      case ElementType.BOOLEAN_CONTAINER:
      case ElementType.CONTAINER:
        return this.parseContainerNode(node, parent, layer)
    }
  }

  getIndex(layerId) {
    return this.layerIndexMap.get(layerId)
  }

  getLayers() {
    return this.layers
  }
}

export default SceneTree
