import React, { useRef, useEffect, useCallback, useMemo } from 'react'
import { checkerBackground } from '../utils'
import { CircleKnob } from '../Knob'
import { useDataStoreActions } from '../../../providers/dataStore/DataStoreProvider'

export interface PaintSliderProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  colors?: string[]
  fillColor?: string
  onChange: (value: number, commitUndo: boolean) => void
  height?: number
  knobSize?: number
  dataTestId?: string
}

export const PaintSlider = ({
  min = 0,
  max = 100,
  value = 50,
  colors = [],
  fillColor = 'transparent',
  onChange,
  onFocus,
  onBlur,
  disabled = false,
  height = 16,
  knobSize = 12,
  dataTestId,
  ...rest
}: PaintSliderProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const knobRef = useRef<HTMLDivElement>(null)
  const isDraggingRef = useRef(false)
  const isMouseDownRef = useRef(false)

  const offset = height / 2
  const background = useMemo(() => getBackground(colors), [colors])

  const updateKnobPositionOnValueChange = useCallback(() => {
    if (!ref.current || !knobRef.current || !inputRef.current) return
    const rect = ref.current.getBoundingClientRect()
    const percentage = calculatePercentage({
      value: Number(inputRef.current.value),
      min: Number(min),
      max: Number(max)
    })
    const knobPosition = ((rect.width - 2 * offset) * percentage) / 100 + offset
    knobRef.current.style.transform = `translateX(calc(${knobPosition}px - 50%))`
  }, [min, max, offset])

  const updateKnobPositionOnMouseEvents = useCallback(
    (e: MouseEvent | React.MouseEvent<HTMLInputElement>) => {
      if (!ref.current || !knobRef.current || !inputRef.current) return
      const rect = ref.current.getBoundingClientRect()
      const knobPosition = Math.min(Math.max(e.pageX - rect.x, offset), rect.width - offset)
      knobRef.current.style.transform = `translateX(calc(${knobPosition}px - 50%))`
    },
    [offset]
  )

  const handleValueChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => onChange(Number(e.target.value), false),
    [onChange]
  )

  const handleEditFinished = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') {
      e.currentTarget.blur()
      e.stopPropagation()
    }
  }

  const handleMouseMove = (e: MouseEvent) => {
    if (!ref.current || !knobRef.current || !inputRef.current) return
    if (inputRef.current === document.activeElement) inputRef.current.blur()
    isDraggingRef.current = true
    updateKnobPositionOnMouseEvents(e)
  }

  const handleMouseUpOrLeave = () => {
    if (!ref.current || !knobRef.current || !inputRef.current) return
    if (inputRef.current === document.activeElement) inputRef.current.blur()

    isDraggingRef.current = false
    isMouseDownRef.current = false

    onChange(Number(inputRef.current.value), true)
    window.removeEventListener('mousemove', handleMouseMove)
  }

  const handleMouseDown = (e: React.MouseEvent<HTMLInputElement>) => {
    isMouseDownRef.current = true
    updateKnobPositionOnMouseEvents(e)

    window.addEventListener('mousemove', handleMouseMove)
    window.addEventListener('mouseup', handleMouseUpOrLeave, { once: true })
    window.addEventListener('mouseleave', handleMouseUpOrLeave, { once: true })
  }

  useEffect(() => {
    if (isDraggingRef.current || isMouseDownRef.current) return
    updateKnobPositionOnValueChange()
  }, [value, updateKnobPositionOnValueChange])

  return (
    <div
      ref={ref}
      style={{
        opacity: disabled ? 0.4 : 1,
        background,
        height: `${height}px`,
        borderRadius: `${height / 2}px`
      }}
      className="relative"
      data-test-id={dataTestId}
    >
      <input
        {...rest}
        ref={inputRef}
        type="range"
        min={min}
        max={max}
        value={value}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={handleValueChange}
        onMouseDown={handleMouseDown}
        onKeyUp={handleEditFinished}
        disabled={disabled}
        className="peer absolute inset-0 cursor-pointer disabled:cursor-not-allowed opacity-0"
      />
      <CircleKnob
        ref={knobRef}
        color={fillColor}
        size={knobSize}
        className="absolute my-auto top-0 bottom-0 justify-center pointer-events-none rounded-circle peer-focus-visible:outline-primary-2"
      />
    </div>
  )
}

export interface PaintSliderWrapperProps extends PaintSliderProps {
  noIS?: boolean
}

const PaintSliderWrapper = ({ noIS = false, onFocus, onBlur, ...props }: PaintSliderWrapperProps) => {
  const { changeEAMInputState } = useDataStoreActions()
  const focusEAMInputState = useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      if (onFocus) onFocus(e)
      if (!noIS) changeEAMInputState(true)
    },
    [noIS, changeEAMInputState, onFocus]
  )
  const unfocusEAMInputState = useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      if (onBlur) onBlur(e)
      if (!noIS) changeEAMInputState(false)
    },
    [noIS, changeEAMInputState, onBlur]
  )

  return <PaintSlider {...props} onFocus={focusEAMInputState} onBlur={unfocusEAMInputState} />
}

// helper functions
const getBackground = (colors: string[]) => {
  const plates = colors.reduce((acc: string[], color, index, arr) => {
    if (index === arr.length - 1) acc.push(`${arr[arr.length - 1]} 100%`)
    else acc.push(`${color} ${(index / (arr.length - 1)) * 100}%`)
    return acc
  }, [])

  return `linear-gradient(to right, ${plates.join(', ')}),${checkerBackground(4, '#484755', 'black')}`
}

const calculatePercentage = ({ value, min, max }: { value: number; min: number; max: number }) =>
  max === min ? 0 : Math.max(0, ((value - min) / (max - min)) * 100)

export default PaintSliderWrapper
