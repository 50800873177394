import React from 'react'
import { PaintSlider, PaintSliderProps } from '../../shared'

const hueColors = [
  '#FF0000',
  '#FF5500',
  '#FFAA00',
  '#FFFF00',
  '#AAFF00',
  '#4CE500',
  '#00FF00',
  '#00FF55',
  '#00FFAA',
  '#00FFFF',
  '#00AAFF',
  '#0055FF',
  '#0000FF',
  '#5500FF',
  '#AA00FF',
  '#FF00FF',
  '#FF00AA',
  '#FF0055',
  '#FF0000'
]
const HuePicker = (props: PaintSliderProps) => {
  return <PaintSlider {...props} min={0} max={359} colors={hueColors} />
}

export default React.memo(HuePicker)
