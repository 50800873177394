import {
  CommentBody,
  CommentBodyInlineElement,
  CommentBodyParagraph,
  CommentData,
  ThreadData
} from '@liveblocks/client'
import { ThreadMetadata } from '../../../liveblocks.config'

export enum CommentType {
  PANEL = 'panel',
  CANVAS = 'canvas'
}

export enum FilterKeys {
  SHOW_RESOLVED = 'SHOW_RESOLVED'
}

export type Position = {
  x: number
  y: number
}

export type CommentBodyContentChildProps = CommentBodyInlineElement

export type CommentBodyContentProps = CommentBodyParagraph

export type CommentBodyProps = CommentBody

export interface ReactionUserProps {
  id: string
}

export interface ReactionProps {
  emoji: string
  createdAt: Date
  users: ReactionUserProps[]
}

export interface MetadataProps {
  type: CommentType
  resolved: boolean
  isDeleted?: boolean
  position?: {
    x: number
    y: number
  }
  usersReadStatus: {
    [key: string]: boolean
  }
}

export interface UserMeta {
  id: string
  name: string
  avatar: string
}

export type CommentDataProps = CommentData

export type ThreadDataProps = ThreadData<ThreadMetadata>
