import React, { useCallback, useEffect, useLayoutEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { useAtom } from 'jotai'

import { useFeatureIsOn } from '@growthbook/growthbook-react'
// @ts-ignore
import { IS, cleanupRenderer } from '@phase-software/renderer'
import { TutorialStatus } from '@phase-software/types'

import { useBroadcaster } from '../../Broadcaster'
import { nonChromiumWarning } from '../../atoms'
import Canvas from '../../components/Canvas'
import ContentPanel from '../../components/ContentPanel'
import CanvasContextMenu from '../../components/ContextMenus/CanvasContextMenu'
import ElementContextMenu from '../../components/ContextMenus/ElementContextMenu'
import KeyframeContextMenu from '../../components/ContextMenus/KeyframeContextMenu'
import SetDefaultPlayHeadTime from '../../components/DataStore/SetDefaultPlayHeadTime'
import SubscribeDataStoreChange from '../../components/DataStore/SubscribeDataStoreChange'
import SubscribeEditorChange from '../../components/DataStore/SubscribeEditorChange'
import SubscribeElementChange from '../../components/DataStore/SubscribeElementChange'
import SubscribeElementChildrenChange from '../../components/DataStore/SubscribeElementChildrenChange'
import SubscribeInteractionChange from '../../components/DataStore/SubscribeInteractionChange'
import SubscribeRendererReady from '../../components/DataStore/SubscribeRendererReady'
import SubscribeTransitionManagerChange from '../../components/DataStore/SubscribeTransitionManagerChange'
import FileSyncDialog from '../../components/FileSyncDialog'
import ImageUploader from '../../components/ImageUploader'
import Interaction from '../../components/Interaction'
import { NotificationList } from '../../components/Notification'
import SvgUploader from '../../components/SvgUploader'
import Toolbar from '../../components/TopTools/FileToolbar'
import LottieUnsupportedFeatureDialog from '../../components/modals/LottieUnsupportedFeatureDialog'
import TutorialDialog from '../../components/modals/TutorialDialog'
import { CUSTOM_EVENT_NAMES } from '../../constants'
import { FEATURE_KEYS } from '../../growthbook-feature-keys'
import useFileActions from '../../hooks/useFileActions'
import { useAnalyticElementUpdate } from '../../hooks/useHeapAnalytics'
import { useParticipate, useTogglePresencePreference } from '../../hooks/usePresence'
import { useFileVersionContext } from '../../providers/FileVersionContextProvider'
import { useSetModal } from '../../providers/ModalProvider'
import { PresenceUsersProvider } from '../../providers/PresenceUsersProvider'
import { useTransitionManagerActions } from '../../providers/TransitionManagerProvider'
import { useSetTutorial, useTutorial } from '../../providers/TutorialProvider'
import { WorkspaceData } from '../../providers/WorkspaceContextProvider'
import { useDataStore, useDataStoreActions } from '../../providers/dataStore/DataStoreProvider'
import HoverSelectionProvider from '../../providers/dataStore/HoverSelectionProvider'
import { useUI } from '../../providers/dataStore/UIProvider'
import { TutorialType } from '../../tutorials'
import FileLayout from './FileLayout'

const TUTORIAL_KEY = 'EDITOR'

type FileEditorProps = {
  workspaceData: WorkspaceData
  projectId: string
  fileId: string
}

const FileEditor = ({ workspaceData, projectId, fileId }: FileEditorProps) => {
  const partialSave = useFeatureIsOn(FEATURE_KEYS.PARTIAL_SAVE)
  const broadcaster = useBroadcaster()
  const { t } = useTranslation('tutorial')

  const dataStore = useDataStore()
  const { closeAllModals } = useSetModal()
  const { uploadContent } = useFileActions()
  const { isContentPanelHidden, isToolbarHidden, isEditingState, isTablingState, isInspectingState } = useUI()
  const { cancelExportMedia, setPlayheadTime } = useDataStoreActions()
  const { onFileVersionUpdate, currentVersionFileContent } = useFileVersionContext()
  const { forceUpdateAnimationState } = useTransitionManagerActions()
  const { startVideoTutorial, closeTutorial } = useSetTutorial()
  const activeTutorial = useTutorial((o) => o.active)
  const editorTutorial = useTutorial((o) => o.status)?.[TUTORIAL_KEY]
  const [isNonChromiumWarningOpen] = useAtom(nonChromiumWarning)

  const isPresenceFeatureEnabled = useFeatureIsOn(FEATURE_KEYS.PRESENCE)

  useTogglePresencePreference(fileId)
  useParticipate(fileId)
  useAnalyticElementUpdate(projectId, fileId, dataStore)

  useEffect(() => {
    if (editorTutorial?.status !== TutorialStatus.COMPLETED) {
      startVideoTutorial(TutorialType[TUTORIAL_KEY])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useLayoutEffect(() => {
    if (currentVersionFileContent) {
      dataStore.load(currentVersionFileContent)
      forceUpdateAnimationState()
    }
    return () => dataStore.clear()
    // reload file content when file id changes
  }, [currentVersionFileContent, dataStore, forceUpdateAnimationState, fileId])

  const projectIdRef = useRef(projectId)

  useEffect(() => {
    projectIdRef.current = projectId
  }, [projectId])

  useEffect(() => {
    return () => {
      if (partialSave) {
        return
      }
      const savedContent = dataStore.save()
      if (savedContent) {
        uploadContent({ projectId: projectIdRef.current, fileId, content: savedContent })
      }
    }
  }, [dataStore, fileId, uploadContent, partialSave])

  useEffect(() => {
    const disableDefaultContextMenu = (e: Event) => e.preventDefault()
    const disableTrackpadPinchToZoom = (e: WheelEvent) => {
      const isMacWithCtrlKey = navigator.userAgent.includes('Mac') && e.ctrlKey
      if (isMacWithCtrlKey) {
        e.preventDefault()
      }
    }
    window.addEventListener('contextmenu', disableDefaultContextMenu)
    window.addEventListener('wheel', disableTrackpadPinchToZoom, {
      passive: false
    })

    return () => {
      window.removeEventListener('contextmenu', disableDefaultContextMenu)
      window.removeEventListener('wheel', disableTrackpadPinchToZoom)
      setPlayheadTime(0)
      cleanupRenderer()
      closeAllModals()
      cancelExportMedia()
      dataStore.eam.exitEditor()
      IS.pause()
      dataStore.clearUndo()
    }
  }, [cancelExportMedia, closeAllModals, dataStore, setPlayheadTime])

  useEffect(() => {
    if (dataStore && isPresenceFeatureEnabled) {
      window.PresenceManager.bindDataStore(dataStore)
    }
    return () => {
      if (window.PresenceManager.dataStore) {
        window.PresenceManager.unbindDataStore()
      }
    }
  }, [dataStore, isPresenceFeatureEnabled])

  const handleNatsEvents = useCallback(() => {
    if (fileId && projectId && broadcaster) {
      broadcaster.subscribeFile(projectId, fileId)
      broadcaster.subscribeFileVersionUpdate(fileId, onFileVersionUpdate)
    }
  }, [fileId, projectId, broadcaster, onFileVersionUpdate])

  useEffect(() => {
    document.addEventListener(CUSTOM_EVENT_NAMES.NATS_CONNECT_CLOSED, handleNatsEvents)
    return () => {
      document.removeEventListener(CUSTOM_EVENT_NAMES.NATS_CONNECT_CLOSED, handleNatsEvents)
    }
  }, [handleNatsEvents])

  useEffect(() => {
    if (fileId && projectId && broadcaster) {
      broadcaster.subscribeFile(projectId, fileId)
    }
    return () => {
      if (broadcaster) {
        broadcaster.unsubscribeFile(projectId, fileId)
      }
    }
  }, [broadcaster, fileId, projectId])

  useEffect(() => {
    if (fileId && projectId && broadcaster) {
      broadcaster.subscribeFileVersionUpdate(fileId, onFileVersionUpdate)
    }
    return () => {
      if (broadcaster) {
        broadcaster.unsubscribeFileVersionUpdate(fileId)
      }
    }
  }, [broadcaster, fileId, onFileVersionUpdate, projectId])

  const handleTutorialComplete = useCallback(
    (doNotShowAgain: boolean) => {
      closeTutorial(doNotShowAgain)
    },
    [closeTutorial]
  )

  return (
    <>
      <NotificationList offsetTop={48} />
      <FileLayout>
        {!isToolbarHidden && (
          <PresenceUsersProvider>
            <Toolbar workspaceData={workspaceData} fileId={fileId} projectId={projectId} />
          </PresenceUsersProvider>
        )}

        {(isEditingState || isInspectingState || isTablingState) && (
          <>
            <HoverSelectionProvider />
            {!isContentPanelHidden && (
              <ContentPanel workspaceData={workspaceData} fileId={fileId} projectId={projectId} />
            )}
            {!isInspectingState && <Interaction />}
          </>
        )}
        <Canvas />
        <FileLayout.RightPanel fileId={fileId} projectId={projectId} />
        <SubscribeDataStoreChange fileId={fileId} projectId={projectId} />
        <SubscribeElementChange />
        <SubscribeElementChildrenChange />
        <SubscribeInteractionChange />
        <SubscribeTransitionManagerChange />
        <SubscribeEditorChange />
        <SubscribeRendererReady fileId={fileId} />
        <SetDefaultPlayHeadTime />
        <KeyframeContextMenu />
        <ElementContextMenu />
        <CanvasContextMenu />
      </FileLayout>
      <ImageUploader />
      <SvgUploader />
      {!isNonChromiumWarningOpen && (
        <>
          <LottieUnsupportedFeatureDialog />
          <TutorialDialog
            title={t('get_started_with_the_editor')}
            confirmText={t('start_creating')}
            activeTutorial={activeTutorial}
            onComplete={handleTutorialComplete}
          />
        </>
      )}
      <FileSyncDialog />
    </>
  )
}

export default FileEditor
