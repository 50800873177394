import { DEBUG_LOTTIE_STATS_FILE_NAME, DEBUG_WATCHED_LOTTIE_FEATURE_SET } from '../../constants'
import { getOPFSRoot } from './opfs'

/**
 * Converts an array of objects into a CSV string.
 * @param {Object[]} dataArray - Array of objects to convert to CSV.
 * @returns {string} A CSV string representing the input data.
 */
const convertToCSV = (dataArray) => {
  if (!dataArray || dataArray.length === 0) {
    return ''
  }

  // Extract headers
  const headers = Object.keys(dataArray[0])
  const csvRows = [headers.join(',')] // Start with the headers as the first row

  // Convert each object to a CSV row
  dataArray.forEach((obj) => {
    const row = headers
      .map((header) => {
        const value = obj[header]
        // Handle values that contain commas or quotes
        if (typeof value === 'string' && (value.includes(',') || value.includes('"'))) {
          return `"${value.replace(/"/g, '""')}"` // Escape double quotes
        }
        return value
      })
      .join(',')
    csvRows.push(row)
  })

  // Join all rows into a single string, separated by newlines
  return csvRows.join('\n')
}

class Record {
  constructor() {
    this.key = ''
    this.importTime = 0
    this.importSize = 0
    this.phaseTime = 0
    this.phaseSize = 0
    this.exportSize = 0
    this.exportTime = 0
    DEBUG_WATCHED_LOTTIE_FEATURE_SET.forEach((key) => {
      this[key] = false
    })
  }

  fromJSON({ key, importSize, importTime, phaseSize, phaseTime, exportSize, exportTime, ...features }) {
    this.key = key
    this.importSize = importSize
    this.importTime = importTime
    this.phaseTime = phaseTime
    this.phaseSize = phaseSize
    this.exportSize = exportSize
    this.exportTime = exportTime
    DEBUG_WATCHED_LOTTIE_FEATURE_SET.forEach((key) => {
      this[key] = features[key] || false
    })
    return this
  }

  toJSON() {
    return {
      key: this.key,
      importSize: this.importSize,
      importTime: this.importTime,
      phaseTime: this.phaseTime,
      phaseSize: this.phaseSize,
      exportSize: this.exportSize,
      exportTime: this.exportTime,
      ...DEBUG_WATCHED_LOTTIE_FEATURE_SET.reduce((acc, key) => {
        acc[key] = this[key]
        return acc
      }, {})
    }
  }
}

export class Stats {
  async getStatFileHandle() {
    const root = await getOPFSRoot()
    const fileHandle = await root.getFileHandle(DEBUG_LOTTIE_STATS_FILE_NAME, { create: true })
    return fileHandle
  }

  async loadFromOPFS() {
    const fileHandle = await this.getStatFileHandle()
    const file = await fileHandle.getFile()
    const content = await file.text()
    const json = JSON.parse(content || '[]')
    console.log(json)
    this.fromJSON(json)
  }

  async saveToOPFS() {
    const fileHandle = await this.getStatFileHandle()
    const writable = await fileHandle.createWritable()
    const content = this.toJSON()
    await writable.write(JSON.stringify(content))
    await writable.close()
  }

  setSVG(key, svgTime, svgSize) {
    const record = this.data.get(key) || new Record()
    record.key = key
    record.svgTime = svgTime
    record.svgSize = svgSize
    this.data.set(key, record)
    this.saveToOPFS()
  }

  setLottie(key, importTime, importSize, features) {
    console.log({ features })
    const record = this.data.get(key) || new Record()
    record.key = key
    record.importTime = importTime
    record.importSize = importSize
    DEBUG_WATCHED_LOTTIE_FEATURE_SET.forEach((feature) => {
      record[feature] = features.has(feature)
    })
    this.data.set(key, record)
    this.saveToOPFS()
  }

  setPhase(key, phaseTime, phaseSize) {
    const record = this.data.get(key) || new Record()
    record.key = key
    record.phaseTime = phaseTime
    record.phaseSize = phaseSize
    this.data.set(key, record)
    this.saveToOPFS()
  }

  setExport(key, exportTime, exportSize) {
    const record = this.data.get(key) || new Record()
    record.key = key
    record.exportTime = exportTime
    record.exportSize = exportSize
    this.data.set(key, record)
    this.saveToOPFS()
  }

  getRecord(key) {
    return this.data.get(key)
  }

  getCount() {
    return this.data.size
  }

  printReport() {
    console.log(convertToCSV(Array.from(this.data.values())))
  }

  fromJSON(content) {
    this.data = new Map()
    content.forEach(([key, value]) => {
      this.data.set(key, new Record().fromJSON(value))
    })
  }

  toJSON() {
    return Array.from(this.data.entries()).map(([key, record]) => [key, record.toJSON()])
  }
}
