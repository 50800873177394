import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { isEmpty } from 'lodash'

import { EasingType } from '@phase-software/types'

import { MIN_SIZE_VALUE } from '../../../constant'
import { useInspectContext } from '../../../providers/InspectProvider'
import { useInspectPanelSectionState } from '../../../providers/RightPanelProvider'
import { useDataStore, useDataStoreActions } from '../../../providers/dataStore/DataStoreProvider'
import { useInteractionActions } from '../../../providers/dataStore/InteractionProvider'
import { easingTypeNameMap } from '../../Interaction/constant'
import InspectContent from '../PropertyInspection/InspectContent'
import InspectRow from '../PropertyInspection/InspectRow'
import InteractiveInspectContent from '../PropertyInspection/InteractiveInspectContent'
import { parsePath, shouldShowBezierRow } from '../utils'

type AnimationPathProps = {
  elementId: string
}

type PathInspectionContentProps = {
  time: string
  easingType: EasingType
  bezier: number[]
}

const PathInspectionContent = ({ time, easingType, bezier }: PathInspectionContentProps) => {
  const { t } = useTranslation('file', { keyPrefix: 'inspect' })
  const { getTimeString } = useInspectContext()
  const { setPlayheadTime } = useDataStoreActions()

  return (
    <>
      <InspectContent spacedDivider>
        <InspectRow
          type="wide"
          label={t('time')}
          value={getTimeString(Number(time))}
          onClick={() => setPlayheadTime(Number(time))}
        />
        <InspectRow
          valueClassName="text-light-overlay-60"
          type="wide"
          label={t('value')}
          value={t('details_are_not_available')}
          copyDisabled
        />
        <InspectRow
          type="wide"
          label={t('ease')}
          value={t(easingTypeNameMap[easingType as keyof typeof easingTypeNameMap], {
            keyPrefix: 'easing_type'
          })}
        />
        {shouldShowBezierRow(easingType) && <InspectRow type="wide" label={t('bezier')} value={bezier?.join(', ')} />}
      </InspectContent>
    </>
  )
}

const AnimationPathInspection = ({ elementId }: AnimationPathProps) => {
  const { t } = useTranslation('file', { keyPrefix: 'inspect' })
  const dataStore = useDataStore()
  const isPanelOpen = useInspectPanelSectionState('pathMorphing')
  const { getPropertyKeyFrameGroupByTime, getKeyFrame } = useInteractionActions()

  const animationKeyFrames = useMemo(
    () => getPropertyKeyFrameGroupByTime(elementId, 'pathMorphing'),
    [elementId, getPropertyKeyFrameGroupByTime]
  )

  const renderContent = (): React.ReactNode => {
    const obj = {} as any
    Object.keys(animationKeyFrames)?.map((time: string) => {
      const keyframe = getKeyFrame(animationKeyFrames[time][0])
      const val = dataStore.transition.getPropertyValueByTime(elementId, 'pathMorphing', Number(time))
      const currentMesh = dataStore.selection.get('elements')[0]?.get('geometry')?.get('mesh')
      const tempMesh = currentMesh?.copy(true)

      if (val && tempMesh) {
        for (const obj of val) {
          const cell = tempMesh.cellTable.get(obj.id)
          if (cell && cell.type === 'Vertex') {
            cell.pos[0] = obj.x
            cell.pos[1] = obj.y
          }
        }
        tempMesh.recalculateBounds()
      }
      const path = dataStore?.drawInfo?.getMeshOutlinesForExport(tempMesh, [0, 0])
      let verticesData = [] as any
      verticesData = parsePath(path, tempMesh.bounds.width < MIN_SIZE_VALUE, tempMesh.bounds.height < MIN_SIZE_VALUE)

      obj[time] = { value: verticesData, easingType: keyframe.easingType, bezier: keyframe.bezier }
    })

    return Object.keys(obj).map((time) => {
      return (
        <>
          <PathInspectionContent time={time} easingType={obj[time].easingType} bezier={obj[time]?.bezier} />
        </>
      )
    })
  }

  if (isEmpty(animationKeyFrames)) return null
  return (
    <>
      <InteractiveInspectContent title={t('path_shape')} target="pathMorphing">
        <InspectRow
          valueClassName="text-light-overlay-60"
          type="wide"
          label={t('initial')}
          value={t('details_are_not_available')}
          copyDisabled
        />
      </InteractiveInspectContent>
      {isPanelOpen && renderContent()}
    </>
  )
}

export default React.memo(AnimationPathInspection)
