export class IRAsset {
  static defaultValue = {
    id: '',
    type: ''
  }

  constructor(data = IRAsset.defaultValue) {
    this.id = data.id
    this.type = data.type
  }

  toJSON() {
    return {
      id: this.id,
      type: this.type
    }
  }

  fromJSON(data) {
    this.id = data.id
    this.type = data.type
    return this
  }
}
