import { useCallback } from 'react'
import { Mode } from '@phase-software/types'
import { createProvider } from './utils'
import { useDataStore } from './dataStore/DataStoreProvider'

const defaultValue = {
  status: '',
  endTime: 0,
  loop: false,
  viewerPlaybackRate: 1,
  viewerLoop: false,
  playheadTime: 0,
}
const [Provider, useSelectState, useSetState, getStateSnapshot] = createProvider('TransitionManager', defaultValue)

export const useTransitionManager = useSelectState
export const getTransitionManagerSnapshot = getStateSnapshot
export const useSetTransitionManager = () => {
  const setState = useSetState()

  const changeTransitionStatus = useCallback(
    (status) => {
      setState((state) => ({
        ...state,
        status
      }))
    },
    [setState]
  )

  const changeTransitionMode = useCallback(
    (mode) => {
      setState((state) => ({
        ...state,
        mode
      }))
    },
    [setState]
  )

  const changeTransitionEndTime = useCallback(
    (endTime) => {
      setState((state) => ({
        ...state,
        endTime
      }))
    },
    [setState]
  )

  const changeTransitionLoop = useCallback(
    (loop) => {
      setState((state) => ({
        ...state,
        loop
      }))
    },
    [setState]
  )

  const changeTransitionPlaybackRate = useCallback(
    (playbackRate) => {
      setState((state) => ({
        ...state,
        playbackRate
      }))
    },
    [setState]
  )

  const changeTransitionPlayheadTime = useCallback(
    (playheadTime) => {
      setState((state) => ({
        ...state,
        playheadTime
      }))
    },
    [setState]
  )

  return {
    changeTransitionStatus,
    changeTransitionMode,
    changeTransitionEndTime,
    changeTransitionLoop,
    changeTransitionPlaybackRate,
    changeTransitionPlayheadTime
  }
}

export const useTransitionManagerActions = () => {
  const dataStore = useDataStore()

  const playAnimation = useCallback(() => {
    dataStore.eam.playAnimation()
  }, [dataStore])

  const stopAnimation = useCallback(() => {
    dataStore.eam.stopAnimation()
  }, [dataStore])

  const resetAnimation = useCallback(() => {
    dataStore.eam.resetAnimation()
  }, [dataStore])

  const goToEnd = useCallback(() => {
    dataStore.transition.goToEnd()
  }, [dataStore])

  const setViewerPlaybackRate = useCallback(
    (rate) => {
      dataStore.transition.changeViewerPlaybackRate(rate)
    },
    [dataStore]
  )

  const setViewerLoop = useCallback(
    (loop) => {
      dataStore.transition.changeViewerLoop(loop)
    },
    [dataStore]
  )

  const forceUpdateAnimationState = useCallback(
    () => {
      if (dataStore.get('mode') !== Mode.ACTION) return;
      dataStore.transition.forceUpdateAnimation()
    }
    , [dataStore])

  return {
    playAnimation,
    stopAnimation,
    resetAnimation,
    goToEnd,
    setViewerPlaybackRate,
    setViewerLoop,
    forceUpdateAnimationState
  }
}

export default Provider
