const MIN_CARD_WIDTH = 220
const GRID_GAP = 16

const gridConfigMap = {
  [MIN_CARD_WIDTH]: 'grid grid-cols-[repeat(auto-fill,minmax(220px,1fr))]'
}

const gridGapMap = {
  [GRID_GAP]: 'gap-16'
}

export { MIN_CARD_WIDTH, GRID_GAP, gridConfigMap, gridGapMap }
