import EventEmitter from 'eventemitter3'

export class Resource extends EventEmitter {
    constructor() {
        super()

        this.id = `@res[${uid++}]`

        this.name = ""

        this._dirty = false

        this.onUpdateDependency = () => { this._dirty = true }
    }

    getData() {
        return null
    }

    /** @protected */
    update() {

    }

    _update() {
        if (this._dirty) {
            this._dirty = false
            this.update()
            this.emit('update')
        }
    }

    /** @param {Resource} res */
    addDependency(res) {
        res.on('update', this.onUpdateDependency, this)
    }

    /** @param {Resource} res */
    removeDependency(res) {
        res.off('update', this.onUpdateDependency, this)
    }
}

let uid = 1
