import { pick } from 'lodash'
import { getComponentsData } from './helper'
import { getDimensionsData } from './Dimensions'
import { getLayerData } from './Layer'
import { getFontData } from './Font'
import { getOpacityData } from './Opacity'
import { getPositionData } from './Position'
import { getRotationData } from './Rotation'
import { getScrollData } from './Scroll'
import { getTextAlignmentData } from './TextAlignment'
import { getTextDecorationData } from './TextDecoration'
import { getTextDirectionData } from './TextDirection'
import { getTextSpacingData } from './TextSpacing'

export const getStyleData = (style, lazy = false) => {
  if (lazy) {
    return style.id
  }
  const data = pick(style, [
    'effectsInherit',
    'effectsOverride',
    'fillsInherit',
    'fillsOverride',
    'elementId',
    'id',
    'innerShadowsInherit',
    'innerShadowsOverride',
    'isDefault',
    'name',
    'shadowsInherit',
    'shadowsOverride',
    'strokesAdvancedValues',
    'strokesInherit',
    'strokesOverride',
    'type'
  ])

  Object.assign(
    data,
    getComponentsData(style, {
      dimensions: getDimensionsData,
      effects: getLayerData,
      fills: getLayerData,
      font: getFontData,
      innerShadows: getLayerData,
      opacity: getOpacityData,
      position: getPositionData,
      rotation: getRotationData,
      scroll: getScrollData,
      shadows: getLayerData,
      strokes: getLayerData,
      textAlignment: getTextAlignmentData,
      textDecoration: getTextDecorationData,
      textDirection: getTextDirectionData,
      textSpacing: getTextSpacingData
    })
  )
  return data
}
