import { PropComponentType, Unit } from '@phase-software/types'
import { notNull, isNum } from '@phase-software/data-utils'
import PropertyComponent from './PropertyComponent'

/** @typedef {import('../DataStore').DataStore} DataStore */
/** @typedef {import('./PropertyComponent').AppliedRef} AppliedRef */

export default class TranslateComponent extends PropertyComponent {
    /**
     * @param {DataStore} dataStore
     * @param {TranslateComponentData} [data]
     * @param {object} [options]
     * @param {bool} [options.regenId=false]   if set to true, will generate new ID
     */
    constructor(dataStore, data = {}, options) {
        super(dataStore, data, options)

        const {
            translateX,
            translateY,
            translateXUnit,
            translateYUnit
        } = data

        this.componentType = PropComponentType.TRANSLATE
        this.translateX = isNum(translateX) ? translateX : 0
        this.translateY = isNum(translateY) ? translateY : 0
        this.translateXUnit = translateXUnit in Unit ? translateXUnit : Unit.PIXEL
        this.translateYUnit = translateYUnit in Unit ? translateYUnit : Unit.PIXEL

        if (!this.name) {
            this.name = 'translate'
        }
    }

    /**
     * @param {Partial<TranslateComponentSetData>} data
     */
    set(data) {
        super.set(data)

        if (isNum(data.translateX)) {
            this.updateProp('translateX', data.translateX)
        } else if (data.translateX === null) {
            this.updateProp('translateX', undefined)
        }
        if (isNum(data.translateY)) {
            this.updateProp('translateY', data.translateY)
        } else if (data.translateY === null) {
            this.updateProp('translateY', undefined)
        }
        if (data.translateXUnit in Unit && notNull(this.translateXUnit)) {
            this.updateProp('translateXUnit', data.translateXUnit)
        }
        if (data.translateYUnit in Unit && notNull(this.translateYUnit)) {
            this.updateProp('translateYUnit', data.translateYUnit)
        }
    }

    /**
     * Override this in subclasses
     * CALL super._clone() at the top of overriden method
     * @protected
     * @param {AppliedRef} [ref]
     * @returns {TranslateComponent} obj
     */
    _clone(ref) {
        const obj = super._clone(ref)
        obj.translateX = this.translateX
        obj.translateY = this.translateY
        obj.translateXUnit = this.translateXUnit
        obj.translateYUnit = this.translateYUnit
        return obj
    }

    /**
     * Override this in subclasses
     * CALL super._save() at the top of overriden method
     * @protected
     * @returns {TranslateComponentData} data
     */
    _save() {
        const data = super._save()
        if (notNull(this.translateX)) {
            data.translateX = this.translateX
            data.translateXUnit = this.translateXUnit
        }
        if (notNull(this.translateY)) {
            data.translateY = this.translateY
            data.translateYUnit = this.translateYUnit
        }
        return data
    }
}

TranslateComponent.BASE_DEFAULT_DATA = {
    translateX: 0,
    translateY: 0,
    translateXUnit: 'px',
    translateYUnit: 'px'
}

/** @typedef {import('./PropertyComponent').PropertyComponentData} PropertyComponentData */

/**
 * @typedef {PropertyComponentData} TranslateComponentData
 * @property {(number | undefined)} translateX
 * @property {(number | undefined)} translateY
 * @property {(string | undefined)} translateXUnit
 * @property {(string | undefined)} translateYUnit
 */

/**
 * @typedef {PropertyComponentData} TranslateComponentSetData
 * @property {(number | null)} translateX
 * @property {(number | null)} translateY
 * @property {string} translateXUnit
 * @property {string} translateYUnit
 */
