import { Vector2 } from "../../math"
import { Change } from "./utils/ChangeFlag"

/** @typedef {import("../../math/Vector2").Vector2Like} Vector2Like */
/** @typedef {import("./common").WindingInfo} WindingInfo */
/** @typedef {import("./BezierShape").CurveLocation} CurveLocation */
/** @typedef {import("./BezierShape").BezierPath} BezierPath */

export function Segment() {
    /** @type {Vector2} */
    this._point = new Vector2()
    /** @type {Vector2} */
    this._handleIn = new Vector2()
    /** @type {Vector2} */
    this._handleOut = new Vector2()

    /** @type {BezierPath} */
    this._path = null
    /** @type {CurveLocation} */
    this._intersection = null
    /** @type {number} */
    this._index = null

    /** @type {WindingInfo} */
    this._winding = null
    /** @type {boolean} */
    this._visited = null
}

Segment.prototype = {
    constructor: Segment,

    /**
     * @param {Vector2Like} point
     */
    initP(point) {
        return this.initN(point.x, point.y)
    },

    /**
     * @param {number} x
     * @param {number} y
     */
    initN(x, y) {
        this._point.set(x, y)
        this._handleIn.set(0, 0)
        this._handleOut.set(0, 0)
        this._changed(this._point)
        this._changed(this._handleIn)
        this._changed(this._handleOut)
        return this
    },

    /**
     * @param {Vector2Like} point
     * @param {Vector2Like | null} handleIn
     * @param {Vector2Like | null} handleOut
     */
    initWithPoints(point, handleIn, handleOut) {
        this._point.set(point.x, point.y)
        this._changed(this._point)
        if (handleIn) {
            this._handleIn.set(handleIn.x, handleIn.y)
            this._changed(this._handleIn)
        }
        if (handleOut) {
            this._handleOut.set(handleOut.x, handleOut.y)
            this._changed(this._handleOut)
        }
        return this
    },

    /**
     * @param {number} x
     * @param {number} y
     * @param {number} inX
     * @param {number} inY
     * @param {number} outX
     * @param {number} outY
     */
    initWithPointsN(x, y, inX, inY, outX, outY) {
        this._point.set(x, y)
        this._handleIn.set(inX, inY)
        this._handleOut.set(outX, outY)
        this._changed(this._point)
        this._changed(this._handleIn)
        this._changed(this._handleOut)
        return this
    },

    /**
     * @param {Vector2Like} point
     */
    setPoint(point) {
        this._point.set(point.x, point.y)
        this._changed(this._point)
    },

    /**
     * @param {Vector2Like} handleIn
     */
    setHandleIn(handleIn) {
        this._handleIn.set(handleIn.x, handleIn.y)
        this._changed(this._handleIn)
    },

    /**
     * @param {number} x
     * @param {number} y
     */
    setHandleInN(x, y) {
        this._handleIn.set(x, y)
        this._changed(this._handleIn)
    },

    /**
     * @param {Vector2Like} handleOut
     */
    setHandleOut(handleOut) {
        this._handleOut.set(handleOut.x, handleOut.y)
        this._changed(this._handleOut)
    },

    /**
     * @param {number} x
     * @param {number} y
     */
    setHandleOutN(x, y) {
        this._handleOut.set(x, y)
        this._changed(this._handleOut)
    },

    /**
     * @param {Segment} other
     */
    copy(other) {
        return this.initWithPoints(other._point, other._handleIn, other._handleOut)
    },

    clone() {
        return new Segment().initWithPoints(this._point, this._handleIn, this._handleOut)
    },

    remove() {
        if (this._path) {
            return !!this._path.removeSegment(this._index)
        }
        return false
    },

    isFirst() {
        return !this._index
    },
    isLast() {
        const path = this._path
        // eslint-disable-next-line no-mixed-operators
        return path && this._index === path._segments.length - 1 || false
    },

    getPrevious() {
        const segments = this._path && this._path._segments
        // eslint-disable-next-line no-mixed-operators
        return segments && (segments[this._index - 1] || this._path._closed && segments[segments.length - 1]) || null
    },
    getNext() {
        const segments = this._path && this._path._segments
        // eslint-disable-next-line no-mixed-operators
        return segments && (segments[this._index + 1] || this._path._closed && segments[0]) || null
    },

    /**
     * The curve that the segment belongs to. For the last segment of an open
     * path, the previous segment is returned.
     */
    getCurve() {
        const path = this._path
        let index = this._index
        if (path) {
            // The last segment of an open path belongs to the last curve.
            if (index > 0 && !path._closed
                && index === path._bPoints.length - 1) {
                index--
            }
            return path.getCurves()[index] || null
        }
        return null
    },

    /**
     * @param {number} tolerance
     */
    hasHandles(tolerance) {
        return !this._handleIn.is_zero(tolerance) || !this._handleOut.is_zero(tolerance)
    },

    /**
     * @param {number[]} coords
     */
    _transformCoordinates(coords) {
        // Use matrix.transform version() that takes arrays of multiple
        // points for largely improved performance, as no calls to
        // Point.read() and Point constructors are necessary.
        // If change is true, only transform handles if they are set, as
        // _transformCoordinates is called only to change the segment, no
        // to receive the coords.
        // This saves some computation time. If change is false, always
        // use the real handles, as we just want to receive a filled
        // coords array for getBounds().
        const x = this._point.x
        const y = this._point.y
        let i = 2
        coords[0] = x
        coords[1] = y
        // We need to convert handles to absolute coordinates in order
        // to transform them.
        if (this._handleIn) {
            coords[i++] = this._handleIn.x + x
            coords[i++] = this._handleIn.y + y
        }
        if (this._handleOut) {
            coords[i++] = this._handleOut.x + x
            coords[i++] = this._handleOut.y + y
        }
        // If no matrix was previded, this was just called to get the coords and
        // we are done now.
        // if (matrix) {}
        return coords
    },

    /**
     * If segment's _point, _handlIn or _handleOut updated, should trigger this to refresh relate cache data
     * @param {Vector2} point
     */
    _changed(point) {
        const path = this._path
        if (!path) return
        // Delegate changes to affected curves if they exist.
        const curves = path._curves
        const index = this._index
        let curve
        if (curves) {
            // Updated the neighboring affected curves, depending on which point
            // is changing.
            // TODO: Consider exposing these curves too, through #curveIn,
            // and #curveOut, next to #curve?
            if ((!point || point === this._point || point === this._handleIn)
                    // eslint-disable-next-line no-nested-ternary
                    && (curve = index > 0
                        ? curves[index - 1]
                        : path._closed
                            ? curves[curves.length - 1]
                            : null
                    )) {
                curve._changed()
            }
            // No wrap around needed for outgoing curve, as only closed paths
            // will have one for the last segment.
            if ((!point || point === this._point || point === this._handleOut)
                    && (curve = curves[index])) {
                curve._changed()
            }
        }
        path._changed(Change.SEGMENTS)
    },

    toString() {
        return `Segment{ p:[${this._point.x}, ${this._point.y}], i:[${this._handleIn.x}, ${this._handleIn.y}], o:[${this._handleOut.x}, ${this._handleOut.y}] }`
    },
}
