import React from 'react'
import CustomContentLoader from './CustomContentLoader'
import { useLayout } from '../../../providers/LayoutProvider'
import { FILE_EDITOR_LAYOUT_AREAS } from '../../../constant'

const EditorLayerPanelLoading = () => {
  const width = useLayout((o) => o.contentWidth)

  return (
    <div
      style={{ gridArea: FILE_EDITOR_LAYOUT_AREAS.CONTENT_PANEL, width }}
      className="bg-neutral-90 flex flex-col h-full border-r border-solid border-neutral-80 p-32"
    >
      <CustomContentLoader width="100%" height={336} viewBox="0 0 120 336">
        <rect width="80" height="16" rx="5" />
        <rect y="40" width="120" height="16" rx="5" />
        <rect y="80" width="104" height="16" rx="5" />
        <rect y="120" width="80" height="16" rx="5" />
        <rect y="160" width="120" height="16" rx="5" />
        <rect y="200" width="104" height="16" rx="5" />
        <rect y="240" width="80" height="16" rx="5" />
        <rect y="280" width="120" height="16" rx="5" />
        <rect y="320" width="104" height="16" rx="5" />
      </CustomContentLoader>
    </div>
  )
}

export default EditorLayerPanelLoading
