import {
    TriggerType,
    PointerType,
    TimingType,
    DirectionType,
    IDType
} from '@phase-software/types'
import { loadId } from '@phase-software/data-utils'
import { PAINT_KEYS } from '../constant'

/**
 * update data with selection node
 * @param {object} self
 * @param {string} key
 * @param {Function} callback
 */
export const updateSelection = (self, key, callback) => {
    const selectedElements = self.dataStore.selection.get('selected')
    // Doesn't support multiple selection because the Selector in UI part
    // only support to select one element.
    if (selectedElements.length === 1) {
        const selectedNode = selectedElements[0]
        self.dataStore.get('undo').add(self, 'INTERACTION-UPDATE-SELECTOR', {
            original: self.dataStore.getById(self.get(key)),
            watch: selectedNode
        })
        if (callback) {
            callback(selectedNode)
        }
    }
}

/**
 * Gets trigger option by trigger type
 * @param   {TriggerType}   triggerType    The trigger type
 * @returns {TriggerOption}                The trigger option
 */
export function getTriggerOptions(triggerType) {
    switch (triggerType) {
        case TriggerType.CLICK:
            return {
                pointerType: PointerType.LEFT,
                pointers: 1
            }
        case TriggerType.DOUBLE_CLICK:
            return {
                pointerType: PointerType.LEFT
            }
        case TriggerType.DRAG:
            return {
                pointerType: PointerType.LEFT,
                timing: TimingType.DURING,
                direction: DirectionType.ANY
            }
        case TriggerType.EdgeSwipe:
            return {
                pointers: 1,
                direction: DirectionType.ANY,
                threshold: 0,
                velocity: 0.3,
                thickness: 20
            }
        case TriggerType.FORCE_TAP:
            return {
                force: 0,
                timing: TimingType.START
            }
        case TriggerType.HOVER:
            return {
                timing: TimingType.START
            }
        case TriggerType.KEY_PRESS:
            return {
                key: null,
                timing: TimingType.START
            }
        case TriggerType.LOAD:
            return {}
        case TriggerType.LONG_PRESS:
            return {
                pointerType: PointerType.LEFT,
                timing: TimingType.START,
                threshold: 500
            }
        case TriggerType.MANY_CLICK:
            return {
                pointerType: PointerType.LEFT,
                times: 3
            }
        case TriggerType.MOUSE_MOVE:
            return {
                timing: TimingType.DURING,
                direction: DirectionType.ANY
            }
        case TriggerType.PINCH:
            return {
                timing: TimingType.DURING,
                direction: DirectionType.ANY,
                pointers: 2,
                threshold: 0
            }
        case TriggerType.PRESS:
            return {
                pointerType: PointerType.LEFT,
                timing: TimingType.START
            }
        case TriggerType.ROTATE:
            return {
                pointerType: PointerType.LEFT,
                direction: DirectionType.ANY,
                timing: TimingType.START,
                threshold: 0
            }
        case TriggerType.SCROLL:
            return {
                direction: DirectionType.ANY,
                timing: TimingType.START,
                threshold: 0
            }
        case TriggerType.SWIPE:
            return {
                pointers: 1,
                direction: DirectionType.ANY,
                threshold: 0,
                velocity: 0.3
            }
    }
}

/**
 * Gets the next name.
 * @param   {string}   prefix The regexp
 * @param   {string[]} list   The list
 * @returns {string}          The new name with next no
 */
export function getNextName(prefix, list) {
    const pattern = new RegExp(`^${prefix} (\\d+)$`)
    const ids = list
        .map(str => str.match(pattern))
        .filter(Boolean)
        .map(match => Number(match[1]))
    const no = Math.max.apply(null, [0, ...ids])
    return `${prefix} ${no + 1}`
}

/**
 * Gets specified props from object
 * @param   {object}   obj    The source object
 * @param   {string[]} keys   The list of prop name
 * @returns {string}          The new object with keys
 */
export function pick(obj, keys) {
    return keys.reduce((acc, key) => {
        if (key === 'id') {
            loadId(obj[key], IDType.INTERACTION_MANAGER_COMPONENT)
        }
        acc[key] = obj[key]
        return acc
    }, {})
}

/**
 * Append child's id to parent's children and update child's parentId to parent.id
 * @param      {object} parent  The parent
 * @param      {object} child   The child
 */
export function append(parent, child) {
    parent.children.push(child.id)
    child.parentId = parent.id
}

/**
 * 
 * @param {number} startIdx 
 * @param {number} endIdx
 * @param {number} val 
 * @param {Function} accessorFn
 * @returns {number} index where to insert
 */
export function findIndexToInsert(startIdx, endIdx, val, accessorFn) {
    if (val < accessorFn(startIdx)) {
        return startIdx
    }
    if (val > accessorFn(endIdx)) {
        return endIdx + 1
    }

    let start = startIdx;
    let end = endIdx;
    while (start <= end) {
        const mid = Math.floor((start + end) / 2);

        if (accessorFn(mid) === val) {
            return mid;
        } else if (accessorFn(mid) < val && val < accessorFn(mid + 1)) {
            return mid + 1
        }

        if (val < accessorFn(mid)) {
            end = mid - 1;
        } else {
            start = mid + 1;
        }
    }
    return -1;
}

/**
 * @param {string} propKey
 * @returns {boolean}
 */
export function isPaintPropKey(propKey) {
    return PAINT_KEYS.includes(propKey);
}

// sync with frontends/phase/src/components/Interaction/utils.js
export const getTimeAtStep = (time) => {
  return Math.round(time / 10) * 10
}
