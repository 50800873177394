export var BlendMode;
(function (BlendMode) {
    BlendMode["Add"] = "add";
    BlendMode["Color"] = "color";
    BlendMode["ColorBurn"] = "color-burn";
    BlendMode["ColorDodge"] = "color-dodge";
    BlendMode["Darken"] = "darken";
    BlendMode["Difference"] = "difference";
    BlendMode["Exclusion"] = "exclusion";
    BlendMode["HardLight"] = "hard-light";
    BlendMode["HardMix"] = "hard-mix";
    BlendMode["Hue"] = "hue";
    BlendMode["Lighten"] = "lighten";
    BlendMode["Luminosity"] = "luminosity";
    BlendMode["Multiply"] = "multiply";
    BlendMode["Normal"] = "normal";
    BlendMode["Overlay"] = "overlay";
    BlendMode["Saturation"] = "saturation";
    BlendMode["Screen"] = "screen";
    BlendMode["SoftLight"] = "soft-light";
})(BlendMode || (BlendMode = {}));
export var FillRule;
(function (FillRule) {
    FillRule["NonZero"] = "nonzero";
    FillRule["EvenOdd"] = "evenodd";
})(FillRule || (FillRule = {}));
export var MaskMode;
(function (MaskMode) {
    MaskMode["Add"] = "add";
    MaskMode["Alpha"] = "alpha";
    MaskMode["None"] = "none";
    MaskMode["Darken"] = "darken";
    MaskMode["Difference"] = "difference";
    MaskMode["Intersect"] = "intersect";
    MaskMode["Lighten"] = "lighten";
    MaskMode["Luminance"] = "luminance";
    MaskMode["Subtract"] = "subtract";
})(MaskMode || (MaskMode = {}));
export var MaskType;
(function (MaskType) {
    MaskType["Mask"] = "mask";
    MaskType["Matte"] = "matte";
})(MaskType || (MaskType = {}));
export var ShapeType;
(function (ShapeType) {
    ShapeType["Rectangle"] = "rc";
    ShapeType["Ellipse"] = "el";
    ShapeType["PolygonStar"] = "sr";
    ShapeType["Path"] = "sh";
    ShapeType["Fill"] = "fl";
    ShapeType["Stroke"] = "st";
    ShapeType["GradientFill"] = "gf";
    ShapeType["GradientStroke"] = "gs";
    ShapeType["NoStyle"] = "no";
    ShapeType["Group"] = "gr";
    ShapeType["Transform"] = "tr";
    ShapeType["RoundedCorners"] = "rd";
    ShapeType["PuckerBloat"] = "pb";
    ShapeType["Merge"] = "mm";
    ShapeType["Twist"] = "tw";
    ShapeType["OffsetPath"] = "op";
    ShapeType["ZigZag"] = "zz";
    ShapeType["Repeater"] = "rp";
    ShapeType["Trim"] = "tm";
})(ShapeType || (ShapeType = {}));
export var StrokeLinecap;
(function (StrokeLinecap) {
    StrokeLinecap["Butt"] = "butt";
    StrokeLinecap["Round"] = "round";
    StrokeLinecap["Square"] = "square";
})(StrokeLinecap || (StrokeLinecap = {}));
export var StrokeLinejoin;
(function (StrokeLinejoin) {
    StrokeLinejoin["Arcs"] = "arcs";
    StrokeLinejoin["Bevel"] = "bevel";
    StrokeLinejoin["Miter"] = "miter";
    StrokeLinejoin["MiterClip"] = "miter-clip";
    StrokeLinejoin["Round"] = "round";
})(StrokeLinejoin || (StrokeLinejoin = {}));
