import React, { createContext, useContext, useState } from 'react'
import { formatNumberWithUnit } from '../utils/formatter'

enum ColorFormat {
  HEX = 0,
  RGB = 1,
  HSL = 2,
  HSB = 3
}

enum TimeFormat {
  SECOND = 0,
  PERCENT = 1
}

type InspectContextType = {
  colorFormat: ColorFormat
  timeFormat: TimeFormat
  duration: number
  setColorFormat: React.Dispatch<React.SetStateAction<ColorFormat>>
  setTimeFormat: React.Dispatch<React.SetStateAction<TimeFormat>>
  setDuration: React.Dispatch<React.SetStateAction<number>>
  getTimeString: (time: number) => string
}

type InspectProviderProps = {
  children: React.ReactNode
}

const defaultValue = {
  colorFormat: ColorFormat.HEX,
  timeFormat: TimeFormat.SECOND,
  duration: 0,
  setColorFormat: () => {},
  setTimeFormat: () => {},
  setDuration: () => {},
  getTimeString: () => ''
}

const InspectContext = createContext<InspectContextType>(defaultValue)

const InspectProvider = ({ children }: InspectProviderProps) => {
  const [colorFormat, setColorFormat] = useState(defaultValue.colorFormat)
  const [timeFormat, setTimeFormat] = useState(defaultValue.timeFormat)
  const [duration, setDuration] = useState(0)

  const getTimeString = (time: number) => {
    switch (timeFormat) {
      case TimeFormat.SECOND:
        return `${formatNumberWithUnit(time / 1000)}s`
      case TimeFormat.PERCENT:
        return `${formatNumberWithUnit((time / duration) * 100)}%`
      default:
        return `${formatNumberWithUnit(time / 1000)}s`
    }
  }

  return (
    <InspectContext.Provider
      value={{
        colorFormat,
        timeFormat,
        duration,
        setColorFormat,
        setTimeFormat,
        setDuration,
        getTimeString
      }}
    >
      {children}
    </InspectContext.Provider>
  )
}

function useInspectContext(): InspectContextType {
  const context = useContext(InspectContext)
  if (context === undefined) {
    throw new Error('useInspectContext must be used within a InspectProvider')
  }
  return context
}

export { InspectProvider, useInspectContext, ColorFormat, TimeFormat }
