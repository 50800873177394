import React, { createRef, PureComponent } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { color } from '../shared'
import { ELEMENT_PANEL_ADJUST_WIDTH } from '../../constant'

const ELEMENT_PANEL_MAX_WIDTH = 480
const TOPTOOL_HEADER_HEIGHT = 48

const LeftResizeHandlerContainer = styled.div.attrs(({ elementPanelWidth }) => ({
  style: { left: `${elementPanelWidth}px` }
}))`
  position: absolute;
  top: ${TOPTOOL_HEADER_HEIGHT}px;
  left: 0;
  bottom: 0;
  width: 8px;
  right: 0;
  z-index: 10;
  cursor: col-resize;
  transform: translate(-50%, 0);

  &:hover,
  &.active {
    ::after {
      content: '';
      display: block;
      background: ${color('primary', 50)};
      width: 2px;
      height: 100%;
      transform: translate(100%, 0);
    }
  }
`

export class LeftResizeHandler extends PureComponent {
  constructor(props) {
    super(props)
    this.ref = createRef()
    this.rafId = null
    this.rafHandleResizeMouseMove = (e) => {
      if (this.rafId) cancelAnimationFrame(this.rafId)
      this.rafId = requestAnimationFrame(() => {
        this.handleResizeMouseMove(e)
        this.rafId = null
      })
    }
  }

  componentDidMount() {
    window.addEventListener('mousemove', this.rafHandleResizeMouseMove)
    window.addEventListener('mouseup', this.handleResizeMouseUp)
  }

  componentWillUnmount() {
    window.removeEventListener('mousemove', this.rafHandleResizeMouseMove)
    window.removeEventListener('mouseup', this.handleResizeMouseUp)
  }

  /**
   * Resize Handler Mouse Down Event
   */
  handleResizeMouseDown = () => {
    document.body.style.setProperty('cursor', 'col-resize', 'important')
    this.ref.current.classList.add('active')
    this.isResizeMouseDown = true
    this.screenWidth = window.innerWidth
  }

  /**
   * Resize Handler Mouse Move Event
   * @param {object} e
   */
  handleResizeMouseMove = (e) => {
    if (!this.isResizeMouseDown) {
      return
    }

    const currentPosition = e.pageX
    let width = Math.min(
      currentPosition,
      ELEMENT_PANEL_MAX_WIDTH // max width
    )
    width = Math.max(
      width,
      ELEMENT_PANEL_ADJUST_WIDTH // min width
    )
    this.props.onPositionChange(width)
  }

  /**
   * Resize Handler Mouse Up Event
   */
  handleResizeMouseUp = () => {
    document.body.style.cursor = 'auto'
    this.isResizeMouseDown = false
    this.ref.current.classList.remove('active')
  }

  render() {
    return (
      <LeftResizeHandlerContainer
        elementPanelWidth={this.props.elementPanelWidth}
        ref={this.ref}
        onMouseDown={this.handleResizeMouseDown}
      />
    )
  }
}

LeftResizeHandler.propTypes = {
  elementPanelWidth: PropTypes.number.isRequired,
  onPositionChange: PropTypes.func.isRequired
}

export default LeftResizeHandler
