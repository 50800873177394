import { LayerListKey, LayerListKeyList } from '../../dist'

export const nonAnimatableKeySet = new Set([
    'blendMode',
    // 'fontFamily',
    // 'fontStyle',
    // 'horizontalAlignment',
    // 'underline',
    // 'strikethrough',
    // 'case',
    'growDirection',
    'cap',
    'join',
    'miter',
    'ends'
])

export const GENERAL_PROPERTY_GROUP_MAP = {
    width: 'dimensions',
    height: 'dimensions',
    contentAnchorX: 'contentAnchor',
    contentAnchorY: 'contentAnchor',
    scaleX: 'scale',
    scaleY: 'scale',
    skewX: 'skew',
    skewY: 'skew',
    blurGaussian: 'blurs'
}

export const generalKeySet = new Set([
    // keep the translate here to update CS value
    'translate',
    'motionPath',
    'dimensions',
    'rotation',
    'cornerRadius',
    'contentAnchor',
    'scale',
    'skew',
    'opacity',
    'blendMode',
    'blurGaussian'
])

export const effectListKeySet = new Set([
    'effects'
])

export const effectTypeKeySet = new Set(['trimPath'])

export const strokeKeySet = new Set([
    'width',
    'growDirection',
    'cap',
    'capSize',
    'join',
    'joinSize',
    'dash',
    'gap',
    'offset',
    'miter',
    'ends'
])

export const shadowKeySet = new Set([
    'offsetX',
    'offsetY',
    'blur',
    'spread'
])

export const childListMap = new Map([
    ['ROOT', [...generalKeySet.keys(), ...LayerListKeyList, ...effectListKeySet.keys(), 'blurs', ...effectTypeKeySet.keys(), 'pathMorphing']],
    ['dimensions', ['width', 'height']],
    ['contentAnchor', ['contentAnchorX', 'contentAnchorY']],
    ['scale', ['scaleX', 'scaleY']],
    ['skew', ['skewX', 'skewY']],
    ['opacity', ['opacity', 'blendMode']],
    [LayerListKey.FILL, []],
    [LayerListKey.STROKE, []],
    [LayerListKey.SHADOW, []],
    [LayerListKey.INNER_SHADOW, []],
    ['LAYER', ['blendMode', 'paint', 'opacity', ...strokeKeySet.keys(), ...shadowKeySet.keys()]],
    ['blurs', ['blurGaussian']],
    // effects
    ['trimPath', ['trimPath.start', 'trimPath.end', 'trimPath.offset']]
])

export const generalChildKeySet = new Set(Array.from(generalKeySet).flatMap(key => {
    const childKeys = childListMap.get(key) || [key];
    return [key, ...childKeys];
}));

export const effectChildKeySet = new Set(Array.from(effectTypeKeySet).flatMap(typeKey =>
    childListMap.get(typeKey) || [typeKey]
));


// TODO: create unified prop key component map for all modules
export const keyComponentNameMap = Array.from(childListMap.entries()).reduce(
    (map, [componentName, keyList]) => {
        keyList.forEach(key => {
            map.set(key, componentName)
        })
        return map
    },
    new Map()
)

