import { LayerComponentType, LayerType } from '@phase-software/types'
import LayerComponent from './LayerComponent'

/** @typedef {import('../DataStore').DataStore} DataStore */

export default class FillComponent extends LayerComponent {
    /**
     * @param {DataStore} dataStore
     * @param {FillComponentData} [data]
     * @param {object} [options] 
     * @param {bool} [options.regenId=false]   if set to true, will generate new ID
     */
    constructor(dataStore, data = {}, options) {
        super(dataStore, data, options)

        this.componentType = LayerComponentType.FILL

        if (!this.name || this.name === 'layer') {
            this.name = 'fill'
        }
        if (!this.layerType || this.layerType === 'LAYER') {
            this.layerType = LayerType.FILL
        }
    }
}

/** @typedef {import('./LayerComponent').LayerComponentData} LayerComponentData */
/** @typedef {(import('../layer/Fill').FillData)} FillData */

/**
 * @typedef {LayerComponentData} FillComponentData
 * @property {FillData[]} layers
 */
