import { vec2 } from 'gl-matrix'
import {
    AABB,
    Vector2,
    NO_COMMIT,
    NO_COMMIT_NO_ORDER_UPDATE,
    BASE_PROP_FULL_TO_SHORT_NAME
} from '@phase-software/data-utils'
import { EditMode, ElementType, FrameType } from '@phase-software/types'
import Base from '../Base'
import { getPivotOffset } from '../helpers'
import { getElementType, isAllowedToPasteProperty } from '../utils'
import { MAX_INTERACTION_TIME } from '../constant'


/** @typedef { import('@phase-software/data-utils').Mesh} Mesh */
/** @typedef { import('@phase-software/data-utils').Cell } Cell */
/** @typedef { import('../DataStore').DataStore} DataStore */

/** @typedef {import('../constant').ComponentDataMap} ComponentDataMap */

/**
 * @typedef {object} ClipboardContent
 * @property {*} content
 * @property {string} contentType
 */

/**
 * @typedef {object} ElementClipboardContent
 * @property {object[]} elements    list of serialized Element data
 * @property {ComponentDataMap} library    map of serialized components used in copied elements
 *                                          (nested components added before their parents)
 */

/**
 * @typedef {object} SerializedElementClipboardContent
 * @property {object[]} elements    list of serialized Element data
 * @property {object[]} library     list of serialized components used in copied elements
 *                                   (nested components added before their parents)
 */

/**
 * @typedef {object} SerializedKeyframeClipboardContent
 * @property {object.<string, KeyframeData[]>} keyframes with the element id as key
 */

/**
 * @typedef { object } KeyframeData
 * @property { string } id
 * @property { string } trackId
 * @property { number } time
 * @property { any } value
 * @property { string } propertyKey
 */

export const ContentType = {
    ELEMENT: 'com.phase/clip-element',
    CELL: 'com.phase/clip-cell',
    KEYFRAME: 'com.phase/clip-keyframe',
}

const SCREEN = new Set([
    ElementType.SCREEN,
])

const SCREEN_OR_CONTAINER = new Set([
    ElementType.SCREEN,
    ElementType.CONTAINER
])

const decodeHtmlEntities = (html) => {
    const txt = document.createElement('textarea')
    txt.innerHTML = html
    return txt.value
}

/** @type {AABB} AABB buffers */
const boundsBuf1 = new AABB()
const boundsBuf2 = new AABB()
const vecBuf1 = new Vector2()
const vecBuf2 = new Vector2()

export default class Clipboard {
    /**
     * Creates a new clipboard
     * @param {DataStore} dataStore
     */
    constructor(dataStore) {
        this.dataStore = dataStore

        this.init()
    }

    init() {
        this._content = undefined
        this._copySelectionIds = []
    }

    /**
     *
     * @param {string} contentType
     */
    copy(contentType) {
        const elementEditMode = this.dataStore.get('editMode')

        const { content: copiedContent, contentType: copiedContentType } = contentType ?
            this._handleSpecificCopy(contentType, elementEditMode) :
            this._handleCopyBasedOnSelection(elementEditMode);

        if (copiedContent) {
            this._serializeContent(copiedContent, copiedContentType)
            this.updateCopySelectionIds()
        }
    }


    _handleSpecificCopy(contentType, elementEditMode) {
        switch (contentType) {
            case ContentType.ELEMENT:
                return this._handleElementCopy(elementEditMode)
            case ContentType.KEYFRAME:
                return this._copyKeyframes()
            default:
                return {}
        }
    }


    // Selection priority: Input Text > Text Content > Keyframe > Path Node > Element
    _handleCopyBasedOnSelection(elementEditMode) {
        const selectedText = window.getSelection()?.toString()

        if (selectedText) {
            navigator.clipboard.writeText(selectedText).then(console.log).catch(console.log)
        }

        if (!this.dataStore.selection.isKFsEmpty()) {
            return this._copyKeyframes()
        }

        if (
            this.dataStore.selection.isElementsEmpty() &&
            this.dataStore.selection.isVerticesEmpty()
        ) {
            return {}
        }

        return this._handleElementCopy(elementEditMode)
    }


    _handleElementCopy(elementEditMode) {
        switch (elementEditMode) {
            case EditMode.SHAPE:
                return { contentType: ContentType.CELL, content: this._copyCells() }
            case EditMode.ELEMENT:
                return { contentType: ContentType.ELEMENT, content: this._copyElements() }
            default:
                return {}
        }
    }

    /**
     * @param {Element[]} selection
     */
    updateCopySelectionIds(selection) {
        const lastSelection =
            selection ?? this.dataStore.selection.get('elements') ?? []
        this._copySelectionIds = lastSelection.map(v => v.get('id'))
    }

    compareSelection() {
        const selection = this.dataStore.selection.get('elements') ?? []
        const currentSelectionIds = selection.map(v => v.get('id'))
        if (JSON.stringify(currentSelectionIds) === JSON.stringify(this._copySelectionIds)) {
            return true
        }
        return false
    }

    /**
     *
     * @param {string} contentType
     */
    cut(contentType) {
        const elementEditMode = this.dataStore.get('editMode')

        const { content: cutContent, contentType: cutContentType } = contentType ?
            this._handleSpecificCut(contentType, elementEditMode) :
            this._handleCutBasedOnSelection(elementEditMode);


        if (cutContent) {
            this._serializeContent(cutContent, cutContentType)
            this.updateCopySelectionIds()
        }
    }

    _handleSpecificCut(contentType, elementEditMode) {
        switch (contentType) {
            case ContentType.ELEMENT:
                return this._handleElementCut(elementEditMode)
            case ContentType.KEYFRAME:
                return this._cutKeyframes()
            default:
                return {}
        }
    }

    // Selection priority: Input Text > Text Content > Keyframe > Path Node > Element
    _handleCutBasedOnSelection(elementEditMode) {

        const selectedText = window.getSelection()?.toString()

        if (selectedText) {
            navigator.clipboard.writeText(selectedText).then(console.log).catch(console.log)
        }

        if (!this.dataStore.selection.isKFsEmpty()) {
            return this._cutKeyframes()
        }

        if (
            this.dataStore.selection.isElementsEmpty() &&
            this.dataStore.selection.isVerticesEmpty()
        ) {
            return {}
        }

        return this._handleElementCut(elementEditMode)

    }

    _handleElementCut(elementEditMode) {
        switch (elementEditMode) {
            case EditMode.SHAPE:
                return {}
            case EditMode.ELEMENT:
                return { contentType: ContentType.ELEMENT, content: this._cutElements() }
            default:
                return {}
        }
    }

    /**
     * Paste element
     * @param {ClipboardEvent} e
     * @param {Vector2} mousePosition
     * @returns {Promise<void | Error>}
     */
    async paste(e, mousePosition) {
        const editMode = this.dataStore.get('editMode')

        const { content, contentType } = await this._deserializeContent()

        if (content instanceof Error) {
            return content
        }
        if (!content) {
            return
        }

        if (this.dataStore.isActionMode && contentType === ContentType.KEYFRAME) {
            this.dataStore.startTransaction()
            this._pasteKeyframesToSelectedElements(content)
            this.dataStore.endTransaction()
            this.dataStore.commitUndo()
            this.dataStore.transition.forceUpdateAnimation()
            return
        }

        switch (editMode) {
            case EditMode.SHAPE: {
                this._pasteInPathEditingMode(content, contentType, mousePosition)
                break
            }
            case EditMode.ELEMENT: {
                this._pasteInElementEditingMode(content, contentType, mousePosition)
                break
            }
            default:
                break
        }

    }
    _pasteInPathEditingMode(content, contentType, mousePosition) {
        switch (contentType) {
            case ContentType.CELL:
                this._pasteCellsInPathMode(content)
                break
            case ContentType.ELEMENT:
                this._pasteElementsInPathMode(content, mousePosition)
                break
            default:
                break
        }
    }
    _pasteInElementEditingMode(content, contentType, mousePosition) {
        switch (contentType) {
            case ContentType.CELL:
                this._pasteCellsInElementMode(content)
                break
            case ContentType.ELEMENT:
                this._pasteElementsInElementMode(content, mousePosition)
                break
            default:
                break
        }
    }

    duplicate(options = {}) {
        if (
            this.dataStore.selection.isElementsEmpty() &&
            this.dataStore.selection.isVerticesEmpty()
        ) {
            return
        }

        const editMode = this.dataStore.get('editMode')
        switch (editMode) {
            case EditMode.SHAPE:
                return this._duplicateCells()
            case EditMode.ELEMENT:
                return this._duplicateElements(options)
        }
    }

    _hasContent() {
        return this._content
    }

    /**
     * @param {*} content
     * @param {ContentType} contentType
     */
    _serializeContent(content, contentType) {
        const serializedData = JSON.stringify({ content, contentType });
        this._content = serializedData
        const blob = new Blob([`<phase-format>${serializedData}</phase-format>`], { type: "text/html" });
        const data = [new ClipboardItem({ ["text/html"]: blob })];
        navigator.clipboard.write(data).then(console.log).catch(console.log)
    }

    /**
     * @returns {ClipboardContent} clipboard item
     */
    async _deserializeContent() {
        try {
            const clipboardItems = await navigator.clipboard.read()
            const blob = await clipboardItems[0].getType('text/html')
            const html = await blob.text()
            const matches = html.match(/<phase-format[^>]*>(.*?)<\/phase-format>/)
            return JSON.parse(decodeHtmlEntities(matches[1]))
        } catch (e) {
            console.log(e)
            return e
        }
    }

    // ---------------------------- //
    //          ELEMENTS            //
    // ---------------------------- //

    /**
     *
     * @returns {SerializedElementClipboardContent | undefined}
     */
    _copyElements() {
        /** @type {Element[]} list of selected elements */
        let selection = this.dataStore.selection.get('elements')
        if (selection.length === 0) {
            return
        }
        // TODO: change this when we can copy screens
        if (selection[0].get('elementType') === ElementType.SCREEN) {
            selection = selection[0].children
            if (selection.length === 0) {
                return
            }
        }

        const { drawInfo } = this.dataStore
        const selectionBounds = boundsBuf1
        const elBounds = boundsBuf2

        let commonAncestor = null
        const data = {
            images: {},
            elements: [],
            library: new Map(),
            interaction: new Map(),
            mapElementToTrack: {},
            mapKeyFrameToLibrary: {}
        }
        selectionBounds.reset()
        for (const el of selection) {
            // find common ancestor of elements in selection
            // if it becomes undefined - means there is no common ancestors for the selection
            if (commonAncestor !== undefined) {
                commonAncestor = commonAncestor === null
                    ? this.dataStore.getParentOf(el)
                    : this.dataStore.getCommonAncestor(commonAncestor.children[0], el)
            }

            // TODO: remove this when we'll have elements outside the Screens
            if (commonAncestor === undefined) {
                throw new Error('Elements should always have at least one common ancestor')
            }

            // save all components of the elements in subtree
            this.dataStore.cloneComponentsOfSubtree(el, data)

            const parent = el.get('parent')
            const { x, y } = el.getBaseValue('position')
            const { width, height } = el.getBaseProp('dimensions')
            const position = new Vector2(x, y)
            const size = new Vector2(width, height)
            drawInfo.toBaseWorldPosition(parent.get('id'), position, elBounds.min)
            vec2.add(elBounds.max, elBounds.min, size)
            selectionBounds.minMax(elBounds)

            const elData = el.save(true)
            elData.offset = [...elBounds.min]

            data.elements.push(elData)
        }
        // save world space bounds of the copied content
        data.bounds = selectionBounds.save()
        // set offset˜s from the TL of the selection bounds for every element in selection
        for (const elData of data.elements) {
            vec2.sub(elData.offset, elData.offset, data.bounds.min)
        }

        // set offset of selection from TL of the common ancestor (if any)
        if (commonAncestor) {
            const parent = commonAncestor.get('parent')
            const { x, y } = commonAncestor.computedStyle.get('position')
            drawInfo.toBaseWorldPosition(parent.get('id'), new Vector2(x, y), vecBuf1)

            vec2.sub(vecBuf1, data.bounds.min, vecBuf1)
            data.commonAncestorOffset = [...vecBuf1]
        }

        data.library = [...data.library.values()]
        data.interaction = Object.fromEntries(data.interaction)
        return data
    }


    /**
     *
     * @param {object} rootData
     * @param {Map<string, string>} idMap
     */
    remapComponentsInSubtree(rootData, idMap) {
        this._clearCopiedElementIDs(rootData)
        Base.remapDataComponents(rootData.base, idMap)
        if (!rootData.children) {
            return
        }
        const stack = [...rootData.children]
        while (stack.length) {
            const elData = stack.pop()
            this._clearCopiedElementIDs(elData)
            Base.remapDataComponents(elData.base, idMap)
            if (elData.children) {
                stack.push(...elData.children)
            }
        }
    }

    _clearCopiedElementIDs(elData) {
        elData.id = undefined
        elData.base.id = undefined
        if (elData.geometry) {
            elData.geometry.id = undefined
        }
    }

    /**
     * @returns {SerializedElementClipboardContent | undefined}
     */
    _cutElements() {
        const content = this._copyElements()
        this.dataStore.deleteSelectedElements()
        return content
    }

    /**
     * @param {Content} content
     * @param {Vector2} mousePos
     * @returns {bool} true if handled; false otherwise
     */
    _pasteElementsInElementMode(content, mousePos) {
        const { elements, library, images } = content
        const isSameSelection = this.compareSelection()
        const selection = this.dataStore.selection.get('elements')
        const vpBounds = this.dataStore.drawInfo.getViewportBounds(boundsBuf2)
        const { parent, index } = this._getPastedElementSceneTreePosition(selection, isSameSelection)
        const offset = this._getPastedElementOffset(content, selection, parent, vpBounds, mousePos, isSameSelection)

        this.dataStore.images.loadImages(images)
        // clone library components and get mapping from old components ids to cloned ones
        const libraryMap = this.dataStore.library.cloneComponentList(library)

        const { newElements, elementIdMap } = this.dataStore.processElements(elements, libraryMap, offset, null)

        // add them to designated parent (will also update element order)
        this.dataStore.addChildrenAt(parent, newElements, index, NO_COMMIT)

        this._remapInteraction(content, elementIdMap, libraryMap)

        // add them to selection (will also make undo commit)
        this.dataStore.selection.selectElements(newElements)

        if (isSameSelection) {
            this.updateCopySelectionIds(newElements)
        }

        this._moveViewportToPastedContent(offset, parent, content, vpBounds)
        return true
    }

    _remapInteraction(content, elementIdMap, libraryMap) {
        Object.entries(content.mapKeyFrameToLibrary).forEach(([keyFrameId, libraryId]) => {
            content.interaction[keyFrameId].ref = libraryMap.get(libraryId)
        })

        const elementTrackIdList = []
        Object.entries(content.mapElementToTrack).forEach(([elementId, trackId]) => {
            const elementTrack = content.interaction[trackId]
            elementTrack.elementId = elementIdMap.get(elementId)
            Object.entries(elementTrack.propertyTrackMap).forEach(([key, id]) => {
                // layer property track key must equal to the non-base layer component id,
                // so we need to update the property track map key-value pair
                if (libraryMap.has(key)) {
                    const newKey = libraryMap.get(key)
                    content.interaction[id].key = newKey

                    // update layer list property track's children key
                    const parentId = content.interaction[id].parentId
                    const index = content.interaction[parentId].children.indexOf(key)
                    content.interaction[parentId].children.splice(index, 1, newKey)
                    elementTrack.propertyTrackMap[newKey] = id

                    delete elementTrack.propertyTrackMap[key]
                }
            })
            elementTrackIdList.push(trackId)
        })

        const entityMap = this.dataStore.interaction.parseEntityData(content.interaction)
        elementTrackIdList.forEach(trackId => {
            const newElementId = content.interaction[trackId].elementId
            this.dataStore.interaction.cloneElementTrack(trackId, newElementId, false, entityMap)
        })
        this.dataStore.interaction.fire()
    }

    /**
     * If necessary moves viewport to center on pasted content
     * @param {Vector2} offset
     * @param {Element} parent
     * @param {object} data copied serialized content
     * @param {AABB} vpBounds viewport bounds
     */
    _moveViewportToPastedContent(offset, parent, data, vpBounds) {
        const { bounds } = data
        // original bounds
        const contentBounds = boundsBuf1.copy(bounds)
        // translate bounds to a new pasted position
        const { drawInfo } = this.dataStore
        const contentWorldPos = drawInfo.toWorldPosition(parent.get('id'), offset, vecBuf1)
        contentBounds.moveTo(contentWorldPos, false)

        if (!vpBounds.intersects(contentBounds)) {
            drawInfo.centerSelection()
        }
    }

    /**
     * Should be done after component IDs have been remapped
     * @param {object} elData  serialized element data with offset property set during copy
     * @param {Vector2} contentOffset   offset from the target parent of clipboard content
     */
    updatePastedElementPosition(elData, contentOffset) {
        vec2.add(vecBuf1, contentOffset, elData.offset)
        const referencePoint = this.dataStore.library.getComponent(elData.base[BASE_PROP_FULL_TO_SHORT_NAME.referencePoint])
        const contentAnchor = this.dataStore.library.getComponent(elData.base[BASE_PROP_FULL_TO_SHORT_NAME.contentAnchor])
        const originInPixel = getPivotOffset(referencePoint, contentAnchor)
        this.dataStore.library.setProperty(
            elData.base[BASE_PROP_FULL_TO_SHORT_NAME.translate],
            { translateX: vecBuf1.x + originInPixel.originX, translateY: vecBuf1.y + originInPixel.originY },
            false
        )
    }

    /**
     * @param {object} data copied serialized content
     * @param {Element[]} selection
     * @param {Element} parent
     * @param {AABB} vpBounds viewport bounds (in world space)
     * @param {Vector2} mousePos
     * @param {bool} isSameSelection compare the selections of copy and paste, if they are the same, add them to the sibling elements of a container
     * @returns {Vector2} offset
     */
    _getPastedElementOffset(data, selection, parent, vpBounds, mousePos, isSameSelection) {
        const { bounds, commonAncestorOffset } = data
        const { drawInfo } = this.dataStore
        const contentBounds = boundsBuf1.copy(bounds)
        const tempSet = isSameSelection ? SCREEN : SCREEN_OR_CONTAINER
        const offset = new Vector2()

        if (
            selection.length === 0 ||
            (selection.length > 0 && !tempSet.has(selection[selection.length - 1].get('elementType')))
        ) {
            // given the mouse position to paste 'here'
            if (mousePos) {
                offset.copy(drawInfo.convertMousePosToWorldPosition(mousePos))
            } else if (this.dataStore.isDesignMode) {
                // bounds of the content are (partially or fully) inside of the viewport
                if (vpBounds.intersects(contentBounds)) {
                    // translate current world position of content bounds into parent's space to get new offset
                    drawInfo.toObjectPosition(parent.get('id'), contentBounds.min, offset)
                }
                // bounds of content are outside of the viewport
                else {
                    vec2.set(
                        vecBuf1,
                        (vpBounds.width - contentBounds.width) / 2,
                        (vpBounds.height - contentBounds.height) / 2
                    )
                    // new position of the content in the center of the viewport (in world space)
                    const newWorldPos = vec2.add(vecBuf1, vpBounds.min, vecBuf1)
                    drawInfo.toObjectPosition(parent.get('id'), newWorldPos, offset)
                }
            } else {
                offset.copy(commonAncestorOffset)
            }
        } else if (commonAncestorOffset) {
            console.log('DEBUG paste commonAncestorOffset', commonAncestorOffset)
            // copied content has common ancestor and we paste it inside Screen / Container
            // with the offset like was in the common ancestor
            offset.copy(commonAncestorOffset)
        } else {
            // copied content doesn't have common ancestor and we paste it in the center of the Screen / Container
            const parentSize = parent.get('size')
            vec2.set(
                offset,
                (parentSize.width - contentBounds.width) / 2,
                (parentSize.height - contentBounds.height) / 2
            )
        }

        return offset
    }

    /**
     * @param {Element[]} selection current element selection
     * @param {bool} isSameSelection compare the selections of copy and paste, if they are the same, add them to the sibling elements of a container
     * @returns {{ parent: Element, index: number }}
     */
    _getPastedElementSceneTreePosition(selection, isSameSelection) {
        let parent
        let index
        const tempSet = isSameSelection ? SCREEN : SCREEN_OR_CONTAINER
        if (selection.length === 0) {
            const ws = this.dataStore.workspace.watched
            // top screen
            parent = ws.children[ws.children.length - 1]
            index = parent.children.length
        } else {
            const selectedEl = selection.length === 1
                ? selection[0]
                : selection[selection.length - 1]
            const elementType = selectedEl.get('elementType')
            if (tempSet.has(elementType)) {
                // paste as last child of that parent
                parent = selectedEl
                index = parent.children.length
            } else {
                // paste after the selected element in the same parent
                parent = selectedEl.get('parent')
                index = parent.children.indexOf(selectedEl) + 1
            }
        }
        return { parent, index }
    }

    /**
     * @param {*} content
     * @param {number} mousePos
     * @returns {bool}    true if handled; false otherwise
     */
    async _pasteElementsInPathMode(content, mousePos) {
        // need to exit path edit mode per requirement
        // https://phase-software.atlassian.net/wiki/spaces/DES/pages/195166439/Manage+Element#Paste-Element(s)
        this.dataStore.switchEditMode(EditMode.ELEMENT, { commit: false })
        return this._pasteElementsInElementMode(content, mousePos)
    }

    _collectClonedChildren(element, newElement, clonedMap) {
        if (element.children && element.children.length) {
            element.children.forEach((child, idx) => {
                const newChild = newElement.children[idx]
                clonedMap.set(child.get('id'), newChild.get('id'))
                this._collectClonedChildren(child, newChild, clonedMap)
            })
        }
    }

    /**
     * @param {object} [options={}]
     * @returns {bool} true if handled the action
     */
    _duplicateElements(options = {}) {
        /** @type {Element[]} list of selected elements */
        const selection = this.dataStore.selection.get('elements')
        if (selection.length === 0) {
            return false
        }

        // TODO: change this when we can duplicate screens
        if (selection[0].get('elementType') === ElementType.SCREEN) {
            return false
        }

        const newSelection = []
        const clonedMap = new Map()
        const parents = []
        const indices = []
        for (let i = 0; i < selection.length; i++) {
            const el = selection[i]
            const parent = this.dataStore.getParentOf(el)
            indices.push(parent.children.indexOf(el) + 1)
            parents.push(parent)
            const elData = el.cloneWithoutEventBinding().save()
            const newEl = this.dataStore.createElement(elData.elementType, elData)
            newSelection.push(newEl)

            this._collectClonedChildren(el, newEl, clonedMap)
        }

        // add each element before its original Element in the Element List
        for (let i = newSelection.length - 1; i >= 0; i--) {
            const el = newSelection[i]
            const parent = parents[i]
            const index = indices[i]
            this.dataStore.addChildrenAt(
                parent,
                [el],
                index,
                { ...NO_COMMIT_NO_ORDER_UPDATE, fire: false }
            )
        }


        // After clone children's animation data,
        // should add cloned selection to the clonedMap.
        for (let i = selection.length - 1; i >= 0; i--) {
            const oriEl = selection[i]
            const newEl = newSelection[i]
            clonedMap.set(oriEl.get('id'), newEl.get('id'))
        }

        this.dataStore.workspace.watched.fireSceneTreeChanges()

        // clonedMap.entries().forEach(([originalElementId, newElementId]) => {
        //     const originalElementTrackId = this.dataStore.interaction.getElementTrackIdByElementId(originalElementId)
        //     this.dataStore.interaction.cloneElementTrack(originalElementTrackId, newElementId, false)
        // })
        this.dataStore.updateElementOrder()
        this.dataStore.interaction.fire()

        if (this.dataStore.isActionMode) {
            this.dataStore.transition.forceUpdateAnimation()
        }

        this.dataStore.selection.selectElements(newSelection, options)

        this.dataStore.drawInfo.updateNodes()
        this.dataStore.drawInfo.vs.selection.updateBounds()
        const vpBounds = this.dataStore.drawInfo.getViewportBounds(boundsBuf1)
        const selectionBounds = this.dataStore.drawInfo.getSelectionBoundsWorld(boundsBuf2)
        if (!vpBounds.intersects(selectionBounds)) {
            this.dataStore.drawInfo.centerSelection()
        }

        return clonedMap
    }

    _duplicateElementsComputeOffsets(selection) {
        if (selection.length === 0) {
            return
        }
        const { drawInfo } = this.dataStore
        const selectionBounds = boundsBuf1
        const elBounds = boundsBuf2

        const elemOffsets = []

        // get selection bounds
        for (const el of selection) {
            const { parent, position, size } = el.gets('parent', 'position', 'size')
            drawInfo.toWorldPosition(parent.get('id'), position, elBounds.min)
            vec2.add(elBounds.max, elBounds.min, size)
            selectionBounds.minMax(elBounds)

            elemOffsets.push([...elBounds.min])
        }

        const vpBounds = drawInfo.getViewportBounds(boundsBuf2)
        if (vpBounds.intersects(selectionBounds)) {
            return
        }

        // selection offset
        const contentOffset = new Vector2(
            vpBounds.center[0] - selectionBounds.center[0],
            vpBounds.center[1] - selectionBounds.center[1]
        )
        // set offsets from the TL of the selection bounds for every element in selection
        for (const elOffset of elemOffsets) {
            vec2.sub(elOffset, elOffset, selectionBounds.min)
            vec2.add(elOffset, contentOffset, elOffset)
        }
        return elemOffsets
    }

    _getDuplicateElementNewPosition(el, elemOffset) {
        const pos = el.get('position')
        vec2.add(vecBuf1, pos, elemOffset)
        return { x: vecBuf1.x, y: vecBuf1.y }
    }


    // ---------------------------- //
    //          CELLS               //
    // ---------------------------- //

    /**
     *
     * @returns {object[] | undefined}    serialized cell data
     */
    _copyCells() {
        /* @type {Cell[]} selectedVert */
        const selectedVert = this.dataStore.selection.get('vertices')
        if (selectedVert.length === 0) {
            return
        }

        const selectedElement = this.dataStore.selection.get('elements')[0]
        /** @type {Mesh} mesh */
        const mesh = selectedElement.get('geometry').get('mesh')
        const elemID = selectedElement.get('id')
        const selectClosure = mesh.closureOfCells(selectedVert)
        const { drawInfo } = this.dataStore

        const cellDataList = []
        for (const cell of selectClosure) {
            const cellData = cell.save()
            cellData.type = cell.type
            switch (cellData.type) {
                case 'Vertex':
                    drawInfo.toWorldPosition(
                        elemID,
                        mesh.getVertPos(cellData.id),
                        cellData.pos
                    )
                    break
                case 'Edge':
                    if (cellData.curve) {
                        const curve = mesh.getEdgeCurve(cellData.id)
                        drawInfo.toWorldPosition(
                            elemID,
                            curve[0],
                            cellData.curve[0]
                        )
                        drawInfo.toWorldPosition(
                            elemID,
                            curve[1],
                            cellData.curve[1]
                        )
                    }
                    break
            }
            cellDataList.push(cellData)
        }
        return cellDataList
    }

    /**
     *
     * @returns {object[] | undefined}    serialized cell data
     */
    _cutCells() {
        const content = this._copyCells()
        // TODO: delete cells
        return content
    }

    /**
     *
     * @returns {bool}    true if handled; false otherwise
     */
    _pasteCellsInElementMode() {

        return false
    }

    /**
     * @param {*} content
     * @returns {bool}    true if handled; false otherwise
     */
    async _pasteCellsInPathMode(content) {
        const selectedElement = this.dataStore.selection.get('elements')[0]
        /** @type {Mesh} */
        const mesh = selectedElement.get('geometry').get('mesh')
        const elemID = selectedElement.get('id')
        const size = selectedElement.get('size')
        const newPositions = {}
        const { drawInfo } = this.dataStore

        const centroid = vecBuf2
        const contentBounds = boundsBuf1
        const vpBounds = drawInfo.getViewportBounds(boundsBuf2)
        contentBounds.reset()
        centroid.set(0, 0)
        let numPts = 0
        for (let i = 0; i < content.length; i++) {
            if (content[i].type === 'Vertex') {
                contentBounds.minMax(content[i].pos)
                centroid.x += content[i].pos[0]
                centroid.y += content[i].pos[1]
                numPts++
            }
        }
        centroid.x /= numPts
        centroid.y /= numPts

        const offset = new Vector2()
        if (!vpBounds.intersects(contentBounds)) {
            offset.x = (vpBounds.center[0] - centroid.x)
            offset.y = (vpBounds.center[1] - centroid.y)
        }

        const referencePoint = selectedElement.get('referencePoint')
        offset.x -= referencePoint[0]
        offset.y -= referencePoint[1]

        for (let i = 0; i < content.length; i++) {
            const cell = content[i]
            switch (cell.type) {
                case 'Vertex':
                    newPositions[cell.id] = drawInfo.toObjectPosition(
                        elemID,
                        cell.pos,
                        undefined,
                        offset
                    )
                    break
                case 'Edge':
                    if (cell.curve) {
                        newPositions[cell.id] = [
                            drawInfo.toObjectPosition(
                                elemID,
                                cell.curve[0],
                                undefined,
                                offset
                            ),
                            drawInfo.toObjectPosition(
                                elemID,
                                cell.curve[1],
                                undefined,
                                offset
                            ),
                        ]
                    }
                    break
            }
        }

        const newCells = mesh.duplicateCells(content, newPositions, size.x, size.y)
        // TODO: update this when we'll have not just vertices selection
        // also does commit
        this.dataStore.selection.selectVertices(newCells)
        return true
    }

    /**
     *
     * @returns {bool}   true if handled the action
     */
    _duplicateCells() {
        // TODO:
        return false
    }

    // ---------------------------- //
    //          KEYFRAMES           //
    // ---------------------------- //
    /**
     *
     * @returns {{contentType: ContentType.KEYFRAME, content: SerializedKeyframeClipboardContent}}
     */
    _copyKeyframes() {
        this.dataStore.transition.hold()
        const selectedKeyframeIds = this.dataStore.selection.get('kfs');
        const copiedKeyframes = selectedKeyframeIds.reduce((groupedKeyframes, kfId) => {
            const keyframe = this.dataStore.interaction.getKeyFrame(kfId);
            if (!keyframe) {
                return groupedKeyframes
            }

            const propertyTrack = this.dataStore.interaction.getPropertyTrack(keyframe.trackId)
            const elementId = this.dataStore.interaction.getElementIdByKeyFrame(kfId);
            const computedValue = this.dataStore.transition.getPropertyTrackValueByTime(elementId, propertyTrack.key, keyframe.time)
            const elementIdentifier = `${this.dataStore.get('id')}.${elementId}`

            groupedKeyframes[elementIdentifier] = groupedKeyframes[elementIdentifier] || [];
            groupedKeyframes[elementIdentifier].push({ ...keyframe, propertyKey: propertyTrack.key, value: computedValue === undefined ? keyframe.value : computedValue });

            return groupedKeyframes
        }, {});
        this.dataStore.transition.resume()

        return { contentType: ContentType.KEYFRAME, content: copiedKeyframes }
    }
    _cutKeyframes() {
        const copiedKeyframeContent = this._copyKeyframes();
        this.dataStore.interaction.deleteSelectedKeyFrame()
        return copiedKeyframeContent

    }
    _pasteKeyframesToSelectedElements(keyframesGroupByElementIdentifier) {
        const newKeyframeIds = []
        let someKeyframesOverMaxTime = false
        let someKeyframeIsUnmatched = false


        const targetElements = this.dataStore.selection.get('elements');

        if (targetElements.length === 0) {
            this.dataStore.eam.showNotification("info", "file:clipboard.paste.keyframe.no_element_selected");
            return
        }

        const sourceElementIdentifiers = Object.keys(keyframesGroupByElementIdentifier);

        // Check if pasting from multiple elements and notify if needed
        if (sourceElementIdentifiers.length > 1) {
            this.dataStore.eam.showNotification("info", "file:clipboard.paste.keyframe.multiple_element_selected");
            return
        }

        const targetElementIds = targetElements.map(element => element.get('id'));

        targetElementIds.forEach(targetElementId => {
            sourceElementIdentifiers.forEach(sourceElementIdentifier => {
                const targetElementIdentifier = `${this.dataStore.get('id')}.${targetElementId}`
                const isSameElementId = targetElementIdentifier === sourceElementIdentifier

                const keyframes = keyframesGroupByElementIdentifier[sourceElementIdentifier];
                const { newKeyframeIds: _newKeyframeIds, someKeyframesOverMaxTime: _someKeyframesOverMaxTime, someKeyframeIsUnmatched: _someKeyframeIsUnmatched } = this._pasteKeyframesToElement(keyframes, targetElementId, isSameElementId);

                newKeyframeIds.push(..._newKeyframeIds);
                someKeyframesOverMaxTime = _someKeyframesOverMaxTime;
                someKeyframeIsUnmatched = _someKeyframeIsUnmatched;
            });
        });

        if (someKeyframesOverMaxTime) {
            this.dataStore.eam.showNotification("info", "file:clipboard.paste.keyframe.over_duration_max_limit",)
        }

        if (someKeyframeIsUnmatched) {
            this.dataStore.eam.showNotification("info", "file:clipboard.paste.keyframe.unmatched");
        }

        if (newKeyframeIds.length > 0) {
            this.dataStore.selection.selectKFs(newKeyframeIds, { commit: false })
            this.dataStore.interaction.fire()
        }
    }

    _pasteKeyframesToElement(keyframes, targetElementId, isSameElementId) {
        let someKeyframeIsUnmatched = false
        let someKeyframesOverMaxTime = false

        const keyframesToPaste = []

        const targetElement = this.dataStore.getElement(targetElementId)
        const targetElementType = getElementType(targetElement)

        keyframes.forEach((keyframe) => {
            if (!isAllowedToPasteProperty(keyframe.propertyKey, targetElementType)) {
                console.warn(`Keyframe property ${keyframe.propertyKey} is not allowed to be pasted.`)
                someKeyframeIsUnmatched = true
                return
            }

            const modifiedKeyframe = isSameElementId ? keyframe : { ...keyframe, frameType: FrameType.EXPLICIT };
            keyframesToPaste.push(modifiedKeyframe);
        })

        if (!keyframesToPaste.length) {
            return { newKeyframeIds: [], someKeyframesOverMaxTime, someKeyframeIsUnmatched }
        }

        const maxTimeOffset = this.dataStore.interaction.getMaxKeyframeOffset(keyframesToPaste)

        const newKeyframeIds = new Set()

        keyframesToPaste.forEach(keyframe => {
            const newKeyframeTime = keyframe.time + maxTimeOffset

            if (newKeyframeTime > MAX_INTERACTION_TIME) {
                someKeyframesOverMaxTime = true
                return
            }

            const keyframePropertyKey = keyframe.propertyKey

            const propertyTrack = this.dataStore.interaction.getPropertyTrackByElementIdAndPropKey(targetElementId, keyframePropertyKey)

            const newKeyframeData = {
                ...keyframe,
                time: newKeyframeTime
            }

            if (propertyTrack) {
                const newKeyframe = this.dataStore.interaction.upsertKeyframeToPropertyTrack(propertyTrack, newKeyframeData)
                newKeyframeIds.add(newKeyframe.id)
            } else {
                const newKeyframe = this.dataStore.interaction.upsertKeyframeToElement(targetElement, keyframePropertyKey, newKeyframeData)
                if (newKeyframe) newKeyframeIds.add(newKeyframe.id)
            }
        })

        return { newKeyframeIds: Array.from(newKeyframeIds), someKeyframesOverMaxTime, someKeyframeIsUnmatched }
    }

}

Clipboard.ContentType = ContentType
