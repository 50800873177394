import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { color } from '../utils'

const RadioIconWrapper = styled.div`
  ${({ disabled }) => {
    if (disabled) return
    return css`
      &:active > input + div {
        background-color: ${color('lightOverlays', 3)};
        border-width: 1px;
        border-color: ${color('lightOverlays', 20)};
      }
      &:active > input:checked + div {
        background-color: white;
        border-width: 5px;
        border-color: ${({ error, theme }) => theme.colors[error ? 'danger' : 'primary'][60]};
      }
    `
  }}
`
const RadioInput = styled.input`
  &:hover + div {
    background-color: ${({ disabled, theme }) => theme.colors.lightOverlays[disabled ? 5 : 10]};
    border-width: ${({ disabled }) => (disabled ? '0px' : '1px')}};
    border-color: ${({ theme }) => theme.colors.lightOverlays[20]};
  }
  &:hover:focus + div {
    border-width: 0px;
  }
  &:hover:active + div {
    border-width: ${({ disabled }) => (disabled ? '0px' : '1px')};
  }
  &:checked:hover:active + div {
    border-width: 5px;
  }
  &:checked + div,
  &:checked:hover + div,
  &:checked:focus-visible + div,
  &:checked:has(:focus-visible) + div {
    border-width: 5px;
    background-color: white;
    border-color: ${({ error, theme }) => theme.colors[error ? 'danger' : 'primary'][50]};
  }
  &:checked:focus-visible + div:after,
  &:checked:has(:focus-visible) + div:after {
    content: '';
    position: absolute;
    inset: -7px;
    border-radius: 50%;
    border-width: ${({ error }) => [error ? '0px' : '1px']};
    pointer-events: none;
    border-color: ${({ theme }) => theme.colors.primary[50]};
  }
  &:checked:hover + div {
    border-width: 5px;
    border-color: ${({ disabled, theme, error }) => theme.colors[error ? 'danger' : 'primary'][disabled ? 50 : 40]};
  }
`

RadioInput.defaultProps = {
  type: 'radio'
}

const Radio = ({ inline, checked, label, error, disabled, onKeyDown, ...rest }) => {
  const highlightBorderClassName = disabled
    ? ''
    : error
    ? `highlight-border-rounded-circle-error`
    : `highlight-border-rounded-circle`
  const disableClassName = disabled ? 'cursor-not-allowed opacity-40' : 'cursor-pointer'
  const cursorClassName = disabled ? 'cursor-not-allowed' : 'cursor-pointer'
  const handleMouseUp = useCallback((e) => {
    e.target.blur()
  }, [])

  const handleKeyDown = (e) => {
    onKeyDown?.(e)
    switch (e.key) {
      case 'Escape':
        e.currentTarget.blur()
        e.stopPropagation()
        break
      case ' ':
      case 'Enter': 
        e.currentTarget.click()
        break
      default:
        break
    }
  }

  return (
    <label
      className={`${inline ? 'inline-flex' : 'flex'} items-center w-fit group`}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseUp}
    >
      <RadioIconWrapper
        error={error}
        disabled={disabled}
        className={`${highlightBorderClassName} ${disableClassName} w-16 h-16 relative`}
      >
        <RadioInput
          {...rest}
          error={error}
          disabled={disabled}
          checked={checked}
          onKeyDown={handleKeyDown}
          className={`${cursorClassName} absolute opacity-0 w-16 h-16 z-1 left-0 top-0`}
        />
        <div className={`bg-clip-content relative w-16 h-16 border-0 border-solid rounded-circle bg-light-overlay-5`} />
      </RadioIconWrapper>
      <div className={`${disableClassName} text-12 ${label?.length > 0 ? 'pl-8' : ''} text-white`}>{label}</div>
    </label>
  )
}

Radio.defaultProps = {
  inline: false,
  error: false,
  checked: false
}

Radio.propTypes = {
  inline: PropTypes.bool,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  label: PropTypes.string,
  onKeyDown: PropTypes.func
}

export default Radio
