import React from 'react'

import PropTypes from 'prop-types'

const formatBytes = (bytes = 0) => {
  if (bytes < 1024) return bytes + ' Bytes'

  const units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  let unitIndex = 0
  let formattedSize = bytes

  while (formattedSize >= 1024 && unitIndex < units.length) {
    formattedSize /= 1024
    unitIndex++
  }

  return `${formattedSize.toFixed(2)} ${units[unitIndex - 1]}`
}

export const PreviewBox = ({ title, children, fileSize, duration, translate, onClick }) => {
  const overlapped = translate !== '0'
  return (
    <div
      onClick={onClick}
      style={{
        zIndex: overlapped ? 1 : undefined,
        transform: `translateX(${translate})`,
        opacity: overlapped ? 0.5 : 1
      }}
    >
      <h2 className="mb-4">{title}</h2>
      {/* FIXME: not limit to specified size */}
      <div
        className="preview-box overflow-hidden"
        style={{ aspectRatio: '1/1', outline: `1px solid ${overlapped ? 'red' : 'gray'}`, maxWidth: 500 }}
      >
        {React.Children.map(children, (child) => {
          return React.cloneElement(child, { style: { height: '100%', width: '100%' } })
        })}
      </div>
      <div>
        {formatBytes(fileSize)} {duration ? ` - ${duration}ms` : ''}
      </div>
    </div>
  )
}
PreviewBox.propTypes = {
  title: PropTypes.string,
  fileSize: PropTypes.number,
  translate: PropTypes.string,
  children: PropTypes.node,
  duration: PropTypes.number,
  onClick: PropTypes.func
}

PreviewBox.defaultProps = {
  translate: '0'
}
