/**
 * Check object is a generator.
 *
 * @param  {Object}  obj
 * @return {Boolean}
 */
export function isGenerator(obj) {
  return obj && typeof obj.next === 'function' && typeof obj.throw === 'function'
}

/**
 * Check whether an object is a generator.
 *
 * @param  {Object}  obj
 * @return {Boolean}
 */
export function getComponentsData(obj, componentParserMap) {
  return Object.entries(componentParserMap).reduce((data, [prop, getComponentData]) => {
    if (obj[prop] !== undefined) {
      if (isGenerator(obj[prop])) {
        data[prop] = [...obj[prop]].map((o) => getComponentData(o))
      } else if (Array.isArray(obj[prop])) {
        data[prop] = obj[prop].map((o) => getComponentData(o))
      } else if (obj[prop] !== null) {
        data[prop] = getComponentData(obj[prop])
      }
    }
    return data
  }, {})
}
