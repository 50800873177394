import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { GrowDirection, LayerType } from '@phase-software/types'
import PropTypes from 'prop-types'

import { useDataStoreActions } from '../../../../providers/dataStore/DataStoreProvider'
import { useEditorActions } from '../../../../providers/dataStore/EditorProvider'
import { useStroke } from '../../../../providers/dataStore/StrokeProvider'
import { Select } from '../../../shared'
import { translateMenuOptions } from '../../../shared/Menu/utils'

const directionOptions = [
  { name: 'inside', value: GrowDirection.INSIDE },
  { name: 'center', value: GrowDirection.CENTER },
  { name: 'outside', value: GrowDirection.OUTSIDE }
]

type StrokeGrowDirectionProps = {
  layerItemId: string
}

export const StrokeGrowDirection = ({ layerItemId }: StrokeGrowDirectionProps) => {
  const { t } = useTranslation()
  const growDirection = useStroke((o) => o[layerItemId].growDirection)
  const { commitUndo } = useDataStoreActions()
  const { setLayerGrowDirection, toggleLayerVisible } = useEditorActions()
  const handleChange = (v: string) => {
    setLayerGrowDirection(layerItemId, v)
    commitUndo()
  }

  const keyFrameIconProps = useMemo(
    () => ({
      name: 'growDirection',
      layerItemId,
      layerType: LayerType.STROKE
    }),
    [layerItemId]
  )

  const translatedOptions = useMemo(
    () =>
      translateMenuOptions(t, directionOptions, {
        ns: 'file',
        keyPrefix: 'stroke_direction'
      }),
    [t]
  )

  return (
    <Select
      // @ts-ignore TODO: fix after this component is being used
      disabled
      caret
      keyFrameIconProps={keyFrameIconProps}
      variant="normal"
      name="capShape"
      options={translatedOptions}
      onChange={handleChange}
      onFocus={() => toggleLayerVisible(layerItemId, true)}
      value={growDirection}
    />
  )
}

StrokeGrowDirection.propTypes = {
  layerItemId: PropTypes.string.isRequired
}

export default React.memo(StrokeGrowDirection)
