import { checkerboardBase64Url, MIN_SIZE_THRESHOLD } from '@phase-software/data-utils'
import {
  PaintType,
  BooleanOperation,
  ImageMode,
  TrimPathMode,
  BlendMode,
  GeometryType,
  ElementType
} from '@phase-software/types'
import {
  nodeTypeMapClass,
  IRImageAsset,
  IRPaintType,
  IRBooleanType,
  IRImageMode,
  IRGradientType,
  IRTrimMode,
  IRBlendMode,
  IRNodeType,
  IREffectType,
  IRAssetType
} from '../ir'
import { getElementType, getPathBaseMesh, groupPath } from '../utils'

const paintTypeMap = {
  [PaintType.SOLID]: IRPaintType.SOLID,
  [PaintType.IMAGE]: IRPaintType.IMAGE,
  [PaintType.GRADIENT_LINEAR]: IRPaintType.GRADIENT,
  [PaintType.GRADIENT_RADIAL]: IRPaintType.GRADIENT,
  [PaintType.GRADIENT_ANGULAR]: IRPaintType.GRADIENT,
  [PaintType.GRADIENT_DIAMOND]: IRPaintType.GRADIENT
}

const booleanTypeMap = {
  [BooleanOperation.NONE]: IRBooleanType.NONE,
  [BooleanOperation.UNION]: IRBooleanType.UNION,
  [BooleanOperation.SUBTRACT]: IRBooleanType.SUBTRACT,
  [BooleanOperation.INTERSECT]: IRBooleanType.INTERSECT,
  [BooleanOperation.DIFFERENCE]: IRBooleanType.DIFFERENCE
}

const imageModeMap = {
  [ImageMode.FILL]: IRImageMode.FILL,
  [ImageMode.STRETCH]: IRImageMode.STRETCH,
  [ImageMode.FIT]: IRImageMode.FIT
}

const gradientTypeMap = {
  [PaintType.GRADIENT_LINEAR]: IRGradientType.LINEAR,
  [PaintType.GRADIENT_RADIAL]: IRGradientType.RADIAL,
  [PaintType.GRADIENT_ANGULAR]: IRGradientType.ANGULAR,
  [PaintType.GRADIENT_DIAMOND]: IRGradientType.DIAMOND
}

const trimModeMap = {
  [TrimPathMode.SIMULTANEOUSLY]: IRTrimMode.SIMULTANEOUSLY,
  [TrimPathMode.INDIVIDUALLY]: IRTrimMode.INDIVIDUALLY
}

const blendModeMap = {
  [BlendMode.PASS_THROUGH]: IRBlendMode.PASS_THROUGH,
  [BlendMode.NORMAL]: IRBlendMode.NORMAL,
  [BlendMode.DARKEN]: IRBlendMode.DARKEN,
  [BlendMode.MULTIPLY]: IRBlendMode.MULTIPLY,
  [BlendMode.COLOR_BURN]: IRBlendMode.COLOR_BURN,
  [BlendMode.LIGHTEN]: IRBlendMode.LIGHTEN,
  [BlendMode.SCREEN]: IRBlendMode.SCREEN,
  [BlendMode.COLOR_DODGE]: IRBlendMode.COLOR_DODGE,
  [BlendMode.OVERLAY]: IRBlendMode.OVERLAY,
  [BlendMode.SOFT_LIGHT]: IRBlendMode.SOFT_LIGHT,
  [BlendMode.HARD_LIGHT]: IRBlendMode.HARD_LIGHT,
  [BlendMode.DIFFERENCE]: IRBlendMode.DIFFERENCE,
  [BlendMode.EXCLUSION]: IRBlendMode.EXCLUSION,
  [BlendMode.HUE]: IRBlendMode.HUE,
  [BlendMode.SATURATION]: IRBlendMode.SATURATION,
  [BlendMode.COLOR]: IRBlendMode.COLOR,
  [BlendMode.LUMINOSITY]: IRBlendMode.LUMINOSITY,
  [BlendMode.DIVIDE]: IRBlendMode.DIVIDE,
  [BlendMode.ADD]: IRBlendMode.ADD,
  [BlendMode.SUBTRACT]: IRBlendMode.SUBTRACT,
  [BlendMode.DISSOLVE]: IRBlendMode.DISSOLVE
}

const geometryTypeMap = {
  [GeometryType.ELLIPSE]: IRNodeType.ELLIPSE,
  [GeometryType.RECTANGLE]: IRNodeType.RECTANGLE,
  [GeometryType.LINE]: IRNodeType.PATH,
  [GeometryType.POLYGON]: IRNodeType.PATH
}

const effectKeyMap = {
  [IREffectType.TRIM_PATH]: 'trimPath'
}

export const getIRPaintType = paintType => paintTypeMap[paintType]
export const getIRBooleanType = booleanType => booleanTypeMap[booleanType]
export const getIRImageMode = imageMode => imageModeMap[imageMode]
export const getIRGradientType = gradientType => gradientTypeMap[gradientType]
export const getIRTrimMode = trimMode => trimModeMap[trimMode]
export const getIRBlenMode = blendMode => blendModeMap[blendMode]

export const getIRNodeType = element => {
  const elementType = getElementType(element)
  switch (elementType) {
    case ElementType.PATH: {
      const geometryType = element.get('geometryType')
      return geometryTypeMap[geometryType]
    }
    default:
      return getContainableIRNodeType(element)
  }
}

export const getContainableIRNodeType = element => {
  const elementType = getElementType(element)
  switch (elementType) {
    case ElementType.MASK_CONTAINER:
      return IRNodeType.MASK
    case ElementType.BOOLEAN_CONTAINER:
      return IRNodeType.BOOLEAN
    case ElementType.SCREEN:
      return IRNodeType.SCREEN
    case ElementType.NORMAL_GROUP:
      return IRNodeType.COMPUTED_GROUP
    case ElementType.CONTAINER:
    default:
      return IRNodeType.CONTAINER
  }
}

export const getEffectKey = irEffectType => effectKeyMap[irEffectType]

const propKeyMapComponentKey = {
  translateX: 'translate',
  translateY: 'translate',
  width: 'dimensions',
  height: 'dimensions',
  scaleX: 'scale',
  scaleY: 'scale',
  skewX: 'skew',
  skewY: 'skew',
  contentAnchorX: 'contentAnchor',
  contentAnchorY: 'contentAnchor',
  referencePointX: 'referencePoint',
  referencePointY: 'referencePoint',
  opacity: 'opacity',
  rotation: 'rotation',
  blendMode: 'opacity',
  cornerRadius: 'cornerRadius'
}
export const getComponentKey = propKey => propKeyMapComponentKey[propKey]

export const getElementBaseValue = (element, propKey) => {
  const baseComponentKey = getComponentKey(propKey)
  const baseComponent = element.getBaseProp(baseComponentKey)
  return baseComponent[propKey]
}

const propKeyMapAliasKey = {
  originX: 'contentAnchorX',
  originY: 'contentAnchorY'
}

export const getPropAliasKey = irPropKey => propKeyMapAliasKey[irPropKey] || irPropKey

const propKeyMapTrackKey = {
  originX: 'contentAnchor',
  originY: 'contentAnchor',
  translateX: 'x',
  translateY: 'y'
}
export const getPropTrackKey = irPropKey => propKeyMapTrackKey[irPropKey] || irPropKey

const deltaValueInPhase = new Set(['originX', 'originY', 'translateX', 'translateY', 'motionPath'])
export const isDeltaProp = irPropKey => deltaValueInPhase.has(irPropKey)

export const downloadImage = async (imageId, imageSrc) =>
  new Promise(resolve => {
    const fallback = new IRImageAsset({
      id: imageId,
      type: IRAssetType.IMAGE,
      data: checkerboardBase64Url,
      width: 8,
      height: 8
    })
    if (!imageSrc) {
      resolve(fallback)
    }
    const image = new Image()
    image.crossOrigin = 'anonymous'
    image.onload = () => {
      const canvas = document.createElement('canvas')
      canvas.width = image.width * window.devicePixelRatio
      canvas.height = image.height * window.devicePixelRatio
      const ctx = canvas.getContext('2d')
      if (ctx) {
        ctx.drawImage(image, 0, 0, image.width * window.devicePixelRatio, image.height * window.devicePixelRatio)
        const irImage = new IRImageAsset({
          id: imageId,
          type: IRAssetType.IMAGE,
          data: canvas.toDataURL('image/png'),
          width: image.width,
          height: image.height
        })
        resolve(irImage)
      } else {
        resolve(fallback)
      }
    }
    image.onerror = () => {
      resolve(fallback)
    }
    image.src = imageSrc
  })

export const getVertices = element => {
  const mesh = getPathBaseMesh(element)
  const meshPath = element.dataStore.drawInfo.getMeshOutlinesForExport(mesh, [0, 0])
  const vertices = groupPath(meshPath, element.dataStore.drawInfo, mesh.bounds)
  return vertices
}

export const getTranslate = element => {
  const translateX = getElementBaseValue(element, 'translateX')
  const translateY = getElementBaseValue(element, 'translateY')
  return [translateX, translateY]
}

// PARSERS
const getContentAnchorX = (contentAnchor, element, irNodeType) => {
  let referencePoint = 0
  if (irNodeType === IRNodeType.IMAGE) {
    referencePoint = getElementBaseValue(element, 'referencePointX')
  }
  return contentAnchor + referencePoint
}
const getContentAnchorY = (contentAnchor, element, irNodeType) => {
  let referencePoint = 0
  if (irNodeType === IRNodeType.IMAGE) {
    referencePoint = getElementBaseValue(element, 'referencePointY')
  }
  return contentAnchor + referencePoint
}

// sync with renderer
const getWidth = width => (MIN_SIZE_THRESHOLD > width ? 0 : width)
const getHeight = height => (MIN_SIZE_THRESHOLD > height ? 0 : height)
const getMotionPath = keyFrameValue => keyFrameValue.pos

const parserMap = {
  width: getWidth,
  height: getHeight,
  blendMode: getIRBlenMode,
  contentAnchorX: getContentAnchorX,
  contentAnchorY: getContentAnchorY
}

const defaultParser = v => v
const getBasePropParser = aliasKey => parserMap[aliasKey] || defaultParser

const keyFrameParserMap = {
  width: getWidth,
  height: getHeight,
  blendMode: getIRBlenMode,
  motionPath: getMotionPath
}
export const getKeyFrameValueParser = aliasKey => keyFrameParserMap[aliasKey] || defaultParser

export const getNodeBaseProps = (element, irNodeType) => {
  const IRClass = nodeTypeMapClass[irNodeType]
  return IRClass.props.reduce((acc, prop) => {
    let animatable = true
    let propKey = prop
    if (typeof prop !== 'string') {
      animatable = prop.animatable
      propKey = prop.key
    }

    if (!animatable) {
      acc[propKey] = element.get(propKey)
      return acc
    }

    const aliasKey = getPropAliasKey(propKey)
    let value
    switch (aliasKey) {
      case 'vertices':
        value = getVertices(element)
        break
      case 'motionPath':
        value = getTranslate(element)
        break
      default:
        value = getElementBaseValue(element, aliasKey)
        break
    }
    const parser = getBasePropParser(aliasKey)

    acc[propKey] = parser(value, element, irNodeType)
    return acc
  }, {})
}

export const getDefaultLayerValue = layerData => {
  const { color, gradientStops, gradientTransform, opacity, imageId, imageMode, paintType, ...layerProps } = layerData
  return {
    ...layerProps,
    paint: {
      paintType,
      color,
      gradientStops,
      gradientTransform,
      opacity,
      imageId,
      imageMode
    }
  }
}

// only fills has image now
const layerListKeyList = ['fills']
export const baseHasImage = element => {
  const { dataStore } = element
  return layerListKeyList.some(layerListKey => {
    const layer = element.base[layerListKey]
    const layerCompId = layer[0]
    const layerComp = dataStore.library.getComponent(layerCompId)

    if (!layerComp) {
      return false
    }

    return layerComp.layers.some(layerId => {
      const layer = dataStore.library.getLayer(layerId)

      if (!layer) {
        return false
      }

      return layer.paint.paintType === PaintType.IMAGE
    })
  })
}

export const keyFrameHasImage = element => {
  const { dataStore } = element
  const elementTrackId = dataStore.interaction.getElementTrackIdByElementId(element.get('id'))
  const elementTrack = dataStore.interaction.getElementTrack(elementTrackId)

  if (!elementTrack) {
    return false
  }

  return layerListKeyList.some(layerListKey => {
    const layerTrackId = elementTrack.propertyTrackMap.get(layerListKey)
    const layerTrack = dataStore.interaction.getPropertyTrack(layerTrackId)
    if (!layerTrack) {
      return false
    }

    return Array.from(layerTrack.children).some(layerTrackKey => {
      const paintTrackId = elementTrack.propertyTrackMap.get(`${layerTrackKey}.paint`)
      const keyframeList = dataStore.interaction.getKeyFrameList(paintTrackId)

      if (!keyframeList) {
        return false
      }

      return keyframeList.some(keyframeId => {
        const keyframe = dataStore.interaction.getKeyFrame(keyframeId)
        const paint = dataStore.library.getComponent(keyframe.ref)
        return paint.paintType === PaintType.IMAGE
      })
    })
  })
}

export const hasImage = element => {
  if (baseHasImage(element)) {
    return true
  }

  if (keyFrameHasImage(element)) {
    return true
  }

  return false
}
