import { useCallback } from 'react'
import { EntityType } from '@phase-software/types'
import { createProvider } from '../utils'
import { useDataStore } from './DataStoreProvider'
const [Provider, useSelectState, useSetState] = createProvider('FlattenElementList', [])

export const useFlattenElementList = useSelectState

export const useSetFlattenElementList = () => {
  const setState = useSetState()

  const setFlattenElementList = useCallback(
    (workspace, forceUpdate = false) => {
      let list = workspace.elementOrderInvert
      if (forceUpdate) {
        list = new Map(list)
      }
      setState(list)
    },
    [setState]
  )

  return {
    setFlattenElementList
  }
}

export const useFlattenElementListActions = () => {
  const dataStore = useDataStore()
  const { setFlattenElementList } = useSetFlattenElementList()

  const expandAncestors = useCallback(
    (elementId) => {
      let parent = dataStore.getParentOf(dataStore.getElement(elementId))
      let changed = false
      while (parent && parent.get('type') !== EntityType.WORKSPACE) {
        if (!parent.get('expanded')) {
          parent.set('expanded', true)
          changed = true
        }
        parent = dataStore.getParentOf(parent)
      }
      setFlattenElementList(dataStore.workspace.watched, changed)
    },
    [dataStore, setFlattenElementList]
  )

  const exapnd = useCallback(
    (elementId) => {
      const el = dataStore.getById(elementId)
      el.set('expanded', true)
      setFlattenElementList(dataStore.workspace.watched, true)
    },
    [dataStore, setFlattenElementList]
  )

  const toggleExpand = useCallback(
    (elementId, toggleDescendant = false) => {
      const el = dataStore.getById(elementId)
      const expanded = !el.get('expanded')
      el.set('expanded', expanded)
      if (toggleDescendant) {
        const queue = el.children.filter((o) => o.children)
        while (queue.length) {
          const el = queue.pop()
          el.set('expanded', expanded)
          queue.push(...el.children.filter((o) => o.children))
        }
      }
      setFlattenElementList(dataStore.workspace.watched, true)
    },
    [dataStore, setFlattenElementList]
  )

  return {
    exapnd,
    toggleExpand,
    expandAncestors
  }
}

export default Provider
