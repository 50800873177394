import { Rect2 } from '../math'

/** @typedef {import('../geometry/PathData').PathData} PathData */

export function VectorResource() {
    /** @type {number} */
    this.id = 0
    /**
     * 0: base
     * 1: mod
     * 2..: stroke[geo_type-2]
     * @type {number}
     */
    this.geo_type = 0

    /** @type {Data} */
    this.data = { type: 'RAW' }

    /** @type {PathData} */
    this.path = null

    /** @type {number[]} */
    this.vertices = null

    /** @type {Float32Array} */
    this.triangles = null

    this.bounds = new Rect2()

    /** @type {[number, number] | null} */
    this.rect = null
}
VectorResource.prototype = {
    constructor: VectorResource,

    getData() {
        return this.triangles
    },

    setDataType(type) {
        this.data.type = type
    },

    /**
     * @param {PathData} data
     */
    setPathDirectly(data) {
        this.path = data
        this._dirty = true
    },

    clear() {
        this.vertices = null
        this.triangles = null
        if (this.buffer) {
            this.gfx.destroyBuffer(this.buffer)
            this.buffer = null
            this.vertLen = 0
        }
        this.bounds.set(0, 0, 0, 0)
    },

    free() {
        this.clear()
        this.path = null
    },
}

// /**
//  * @typedef {number[]} Vertices [x,y,k,l,m, ...]
//  * @param {Vertices} verts
//  * @param {number} A pointer to A
//  * @param {number} B pointer to B
//  * @param {number} C pointer to C
//  * @param {number} x
//  * @param {number} y
//  * @returns {number}
//  */
// function intersect(verts, A, B, C, x, y) {
//     const inv_det = 1 / ((verts[B+1] - verts[C+1]) * (verts[A] - verts[C]) + (verts[C] - verts[B]) * (verts[A+1] - verts[C+1]))
//     const factor_alpha = (verts[B+1] - verts[C+1]) * (x - verts[C]) + (verts[C] - verts[B]) * (y - verts[C+1])
//     const factor_beta = (verts[C+1] - verts[A+1]) * (x - verts[C]) + (verts[A] - verts[C]) * (y - verts[C+1])

//     const alpha = factor_alpha * inv_det
//     const beta = factor_beta  * inv_det
//     const gamma = 1.0 - alpha - beta

//     if (
//         (alpha < 0 || alpha > 1)
//         ||
//         (beta < 0 || beta > 1)
//         ||
//         (gamma < 0 || gamma > 1)
//     ) {
//         /* P is outside triangle ABC */
//         return 0
//     }

//     const k = verts[A+2] * alpha + verts[B+2] * beta + verts[C+2] * gamma
//     const l = verts[A+3] * alpha + verts[B+3] * beta + verts[C+3] * gamma
//     const m = verts[A+4] * alpha + verts[B+4] * beta + verts[C+4] * gamma

//     const ccw = (verts[A] * verts[B+1] + verts[B] * verts[C+1] + verts[C] * verts[A+1]) > (verts[B] * verts[A+1] + verts[C] * verts[B+1] + verts[A] * verts[C+1])
//     const dir = ccw ? 1 : -1

//     return (k * k * k <= l * m) ? dir : 0
// }
