import React, { useEffect, useRef, useState } from 'react'
import { AnimationItem } from 'lottie-web'
import { Player } from '@lottiefiles/react-lottie-player'
import { Icon, Slider } from '../../shared'
import { useDataStoreActions } from '../../../providers/dataStore/DataStoreProvider'

const PREVIEW_WIDTH = 368

type LottiePreviewPlayerProps = {
  animationData: object | null
}

const LottiePreviewPlayer = ({ animationData }: LottiePreviewPlayerProps) => {
  const { getFirstScreenElementSize } = useDataStoreActions()

  const { width, height } = getFirstScreenElementSize()
  const screenAspectRatio = width / height

  const playerRef = useRef<Player>(null)
  const [lottieInstance, setLottieInstance] = useState<AnimationItem>()

  const wasPlayingRef = useRef(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const [currentFrame, setCurrentFrame] = useState(0)

  const isGenerating = animationData === null

  useEffect(() => {
    setCurrentFrame(0)
  }, [])

  const handlePlay = () => {
    playerRef.current?.play()
  }

  const handlePause = () => {
    playerRef.current?.pause()
  }

  const handleSeekFrame = (value: number) => {
    playerRef.current?.setSeeker(value, true)
  }

  const handlePlayerEvent = (event: string) => {
    switch (event) {
      case 'play':
        setIsPlaying(true)
        break
      case 'frame':
        setCurrentFrame(Math.floor(lottieInstance?.currentFrame ?? 0))
        break
      case 'pause':
      case 'complete':
        setIsPlaying(false)
        break
    }
  }

  const handleStartDragging = () => {
    wasPlayingRef.current = isPlaying
    handlePause()
  }

  const handleStopDragging = () => {
    if (wasPlayingRef.current) {
      handlePlay()
    }
  }

  return (
    <div className="flex flex-col gap-16 mb-8">
      <div
        className="w-full min-h-[196px] max-h-[calc(100vh-500px)] bg-light-overlay-5 rounded-md overflow-hidden flex items-center justify-center"
        style={{
          height: PREVIEW_WIDTH / screenAspectRatio
        }}
      >
        {isGenerating ? (
          <div className="h-full flex items-center justify-center gap-4 text-light-overlay-60">
            <LoadingIcon />
            Generating...
          </div>
        ) : (
          <Player
            ref={playerRef}
            lottieRef={setLottieInstance}
            src={animationData}
            autoplay
            loop
            onEvent={handlePlayerEvent}
            style={{
              height: '100%',
              width: '100%'
            }}
          />
        )}
      </div>

      <div className="flex items-center gap-12">
        {isPlaying ? (
          <Icon interactive name="Pause24" onClick={handlePause} size="xxl" />
        ) : (
          <Icon interactive name="Play24" onClick={handlePlay} size="xxl" disabled={isGenerating} />
        )}
        <div className="w-full">
          <Slider
            disabled={isGenerating || !lottieInstance}
            max={lottieInstance?.totalFrames}
            min={0}
            noIS
            onChange={handleSeekFrame}
            onMouseDown={handleStartDragging}
            onMouseUp={handleStopDragging}
            step={1}
            value={currentFrame}
            variant="player"
          />
        </div>
      </div>
    </div>
  )
}

const LoadingIcon = () => {
  return (
    <svg
      className="animate-spin h-16 w-16 text-white"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 3.5C5.51472 3.5 3.5 5.51472 3.5 8C3.5 10.4853 5.51472 12.5 8 12.5C10.4853 12.5 12.5 10.4853 12.5 8C12.5 5.51472 10.4853 3.5 8 3.5ZM2.5 8C2.5 4.96243 4.96243 2.5 8 2.5C11.0376 2.5 13.5 4.96243 13.5 8C13.5 11.0376 11.0376 13.5 8 13.5C4.96243 13.5 2.5 11.0376 2.5 8Z"
        fill="currentColor"
        fillOpacity="0.2"
      />
      <g clipPath="url(#clip0_19381_108138)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.28227 3.21838C6.17652 2.71011 7.19537 2.4628 8.22312 2.50453C9.25087 2.54626 10.2463 2.87535 11.0964 3.45443C11.9465 4.03352 12.6172 4.83939 13.0323 5.78051C13.1437 6.03317 13.0292 6.32833 12.7766 6.43976C12.5239 6.5512 12.2288 6.43671 12.1173 6.18405C11.7777 5.41404 11.229 4.7547 10.5334 4.2809C9.8379 3.8071 9.02344 3.53785 8.18255 3.50371C7.34167 3.46956 6.50806 3.67191 5.7764 4.08776C5.04475 4.50361 4.44436 5.11631 4.04345 5.85625C3.64253 6.5962 3.45714 7.43374 3.50834 8.27376C3.55953 9.11378 3.84527 9.92261 4.33308 10.6084C4.82089 11.2942 5.49124 11.8294 6.26798 12.1533C7.04473 12.4772 7.89674 12.5769 8.72725 12.4408C8.99977 12.3962 9.25686 12.581 9.30149 12.8535C9.34611 13.126 9.16138 13.3831 8.88887 13.4277C7.87379 13.5939 6.83244 13.4722 5.88309 13.0763C4.93374 12.6804 4.11442 12.0262 3.51821 11.188C2.92199 10.3499 2.57276 9.36128 2.51019 8.33459C2.44761 7.3079 2.6742 6.28424 3.16421 5.37986C3.65422 4.47548 4.38802 3.72664 5.28227 3.21838Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_19381_108138">
          <rect width="12" height="12" fill="white" transform="translate(2 2)" />
        </clipPath>
      </defs>
    </svg>
  )
}
export default LottiePreviewPlayer
