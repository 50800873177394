import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { LayerType, PaintType } from '@phase-software/types'

import { useDataStoreActions } from '../../providers/dataStore/DataStoreProvider'
import { useEditorActions } from '../../providers/dataStore/EditorProvider'
import { usePaint } from '../../providers/dataStore/PaintProvider'
import { RadioButtonGroup } from '../shared'
import ImageIcon from './images/Image.svg'

const solidOption = {
  name: 'solid',
  tooltip: 'solid',
  value: PaintType.SOLID,
  background: 'color-mix(in srgb, #FFFFFF 60%, transparent)'
}
const linearOption = {
  name: 'linear',
  tooltip: 'linear',
  value: PaintType.GRADIENT_LINEAR,
  background: 'linear-gradient(to bottom, #FFFFFF 0%, #FFFFFF00 100%)'
}
const radialOption = {
  name: 'radial',
  tooltip: 'radial',
  value: PaintType.GRADIENT_RADIAL,
  background: 'radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, #FFFFFF00 100%)'
}
const angularOption = {
  name: 'angular',
  tooltip: 'angular',
  value: PaintType.GRADIENT_ANGULAR,
  background: 'conic-gradient(from 180deg at 50% 50%, #FFFFFF 0%, #FFFFFF00 100%)'
}
const diamondOption = {
  name: 'diamond',
  tooltip: 'diamond',
  value: PaintType.GRADIENT_DIAMOND,
  background: `linear-gradient(to right bottom, #FFFFFF 0%, #FFFFFF00 50%) right bottom / 50% 50% no-repeat,
              linear-gradient(to left bottom, #FFFFFF 0%, #FFFFFF00 50% ) left bottom / 50% 50% no-repeat,
              linear-gradient(to left top, #FFFFFF 0%, #FFFFFF00 50% ) left top / 50% 50% no-repeat,
              linear-gradient(to right top, #FFFFFF 0%, #FFFFFF00 50% ) right top / 50% 50% no-repeat`
}

const gradientOptions = [linearOption, radialOption, angularOption, diamondOption]

const imageOption = {
  name: 'image',
  tooltip: 'image',
  value: PaintType.IMAGE,
  background: `url(${ImageIcon}) center center no-repeat`
}

type PaintTypeSwitcherProps = {
  layerItemId: string
  layerType: LayerType
  resetActiveIdx: () => void
}

const PaintTypeSwitcher = ({ layerItemId, resetActiveIdx, layerType }: PaintTypeSwitcherProps) => {
  const activePaintType: PaintType = usePaint((o) => o[layerItemId].paintType)
  const { commitUndo } = useDataStoreActions()
  const { setLayerPaint } = useEditorActions()
  const { t } = useTranslation('file', { keyPrefix: 'paint_type' })

  const handleChange = (newPaintType: string) => {
    const paintType = Number(newPaintType)
    setLayerPaint(layerItemId, { paintType: paintType })
    commitUndo()
    if (activePaintType === PaintType.SOLID && paintType !== PaintType.SOLID) {
      resetActiveIdx()
    }
  }
  const layerOptions = useMemo(() => {
    let layerOptions = []
    switch (layerType) {
      case LayerType.FILL:
        layerOptions = [solidOption, ...gradientOptions, imageOption]
        break
      case LayerType.STROKE:
        layerOptions = [solidOption, ...gradientOptions]
        break
      default:
        layerOptions = [solidOption]
    }
    return layerOptions.map((option) => ({ ...option, name: t(option.name), tooltip: t(option.tooltip) }))
  }, [layerType, t])

  const radioButtonChildRenderer = useCallback(
    (option: { value: PaintType; background: string }) => {
      const active = activePaintType === option.value
      const activeEffect = active ? 'opacity-100' : ' opacity-60 group-hover:opacity-100 group-active:opacity-40'
      return (
        <div className={`relative group-hover:opacity-100 w-16 h-16 ${activeEffect}`}>
          <div
            className="absolute inset-2 border border-white rounded-circle overflow-hidden bg-origin-border"
            style={{ background: option.background }}
          />
        </div>
      )
    },
    [activePaintType]
  )

  return (
    <RadioButtonGroup
      options={layerOptions}
      selectedValue={activePaintType}
      onSelectionChange={handleChange}
      renderChild={radioButtonChildRenderer}
      className="mb-8"
      dataTestId="paint-type-switcher"
    />
  )
}

export default React.memo(PaintTypeSwitcher)
