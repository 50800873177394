import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSetModal } from '../../providers/ModalProvider'
import { useEditorActions } from '../../providers/dataStore/EditorProvider'
import { usePaint } from '../../providers/dataStore/PaintProvider'
import ColorPreview from './ColorPreview'
import PaintModal from '../modals/PaintModal'
import Flex from '../shared/Flex'

const PaintPicker = ({ layerItemId, layerType, anchorRef }) => {
  const { toggleModal, updateModalData, deleteModal } = useSetModal(`PaintModal-${layerItemId}`)
  const paintType = usePaint((o) => o[layerItemId].paintType)
  const { setLayerFocused, toggleLayerVisible } = useEditorActions()

  const handleTriggerItemClick = () => {
    toggleModal({ layerItemId, layerType, paintType, trigger: anchorRef })
    toggleLayerVisible(layerItemId, true)
    setLayerFocused(layerItemId, true)
  }

  const handleKeyDown = (e) => {
    switch (e.key) {
      case ' ':
      case 'Enter':
        e.preventDefault()
        e.currentTarget.click()
        break
      case 'Escape':
        e.currentTarget.blur()
        e.stopPropagation()
        break
    }
  }

  useEffect(() => {
    updateModalData({ paintType, layerItemId, layerType })
  }, [paintType, updateModalData, layerItemId, layerType, deleteModal])

  useEffect(() => {
    return () => {
      deleteModal()
    }
  }, [deleteModal])

  return (
    <>
      <Flex
        onClick={handleTriggerItemClick}
        onKeyDown={handleKeyDown}
        className="highlight-color-preview cursor-pointer"
      >
        <ColorPreview layerItemId={layerItemId} />
        <button />
      </Flex>
      <PaintModal layerItemId={layerItemId} />
    </>
  )
}

PaintPicker.propTypes = {
  layerType: PropTypes.number,
  layerItemId: PropTypes.string,
  anchorRef: PropTypes.object
}

export default React.memo(PaintPicker)
