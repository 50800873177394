import { throttle } from 'lodash'

import { PropChange } from '@phase-software/data-utils'
// @ts-ignore
import { toWorld } from '@phase-software/renderer'
import { EventFlag, Mode } from '@phase-software/types'
import { Subscription } from 'nats.ws'

import { SESSION_STORAGE_KEYS } from '../constant'
import { CUSTOM_EVENT_NAMES } from '../constants'
import { ProfileType } from '../types/User'
import Broadcaster, { BroadcasterMessageData } from './Broadcaster'

export type PresenceMessageData = {
  color?: string
  cursor: { x: number; y: number } | null
  fileId: string
  idleTime: string | null
  isUpdate: boolean
  isVersioningState: boolean
  joinTime: string
  mode: Mode
  protocol: string
  selections: string[]
  serialNo?: number
  type: string
  user: ProfileType
}

type PresenceUpdateMessageData = {
  cursor?: { x: number; y: number } | null
  idleTime: string | null
  isUpdate: boolean
  isVersioningState?: boolean
  mode?: Mode
  selections?: string[]
  user?: ProfileType
}

export type PresenceCallbackMessageData = PresenceMessageData & BroadcasterMessageData

export const palette = ['#5A53FF', '#FF5A5A', '#FE865A', '#E85AAB', '#47B1E5', '#9B5AE8', '#60C363', '#F5A83D']

let _timerId: ReturnType<typeof setTimeout> | number = -1

let focusBlurEventHandler: null | ((e: Event) => void) = null
let unloadEventHandler: null | (() => void) = null
let visibilityChangeEventHandler: null | (() => void) = null
let natsConnectClosedHandler: null | (() => void) = null
let canvasMouseMoveEventHandler: null | ((e: MouseEvent) => void) = null
let dataStoreSelectEventHandler: null | ((changes: PropChange, options: { flags: EventFlag }) => void) = null

class PresenceManager {
  broadcaster: Broadcaster
  canvas: HTMLCanvasElement | null
  cursor: { x: number; y: number } | null
  dataStore: any
  fileId: string
  isPresenceShow: boolean
  isVersioningState: boolean
  joinTime: string
  mode: Mode
  originCb: (() => void) | null
  profile: ProfileType
  selections: string[]
  subject: string
  tabId: string
  users: Map<string, PresenceCallbackMessageData>

  constructor(broadcaster: Broadcaster) {
    this.broadcaster = broadcaster
    this.fileId = ''
    this.subject = broadcaster.subject
    this.tabId = broadcaster.uid
    this.users = new Map()
    this.isPresenceShow = true
    this.canvas = null
    this.dataStore = null
    this.selections = []
    this.mode = 0
    this.isVersioningState = false
    this.cursor = null
    this.profile = {
      id: '',
      username: '',
      avatar: ''
    }
    this.joinTime = ''
    this.originCb = null

    if ((document as any)?.wasDiscarded) {
      const cachedTabId = sessionStorage.getItem(SESSION_STORAGE_KEYS.CACHED_TAB_ID)
      if (cachedTabId) {
        this.removePresence(
          cachedTabId,
          sessionStorage.getItem(SESSION_STORAGE_KEYS.CACHED_USER)
            ? JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.CACHED_USER) as string)
            : null
        )
      }
    }
  }

  // subscribe presence in the editor
  subscribePresenceEditor(
    fileId: string,
    profile: ProfileType,
    originCb?: ((message: PresenceCallbackMessageData) => void) | null
  ) {
    if (!fileId) {
      console.error('[PresenceManager] subscribePresenceEditor: fileId is required')
      return
    }

    const { id, username, avatar } = profile

    if (!id || !username) {
      console.error('[PresenceManager] subscribePresenceEditor: invalid profile')
      return
    }

    this.fileId = fileId
    this.profile = profile
    this.joinTime = new Date().toISOString()
    this.originCb = originCb as () => void

    const message: PresenceCallbackMessageData = {
      user: { id, username, avatar },
      type: 'presence',
      protocol: 'v1',
      fileId,
      joinTime: this.joinTime,
      idleTime: null,
      selections: this.selections,
      cursor: this.cursor,
      tabId: this.tabId,
      mode: this.mode,
      isUpdate: true,
      isVersioningState: this.isVersioningState,
      userId: id
    }

    const subscription = this.createSubscription(fileId, originCb)
    if (subscription) {
      this.broadcaster.presenceSubMap.set(fileId, subscription)
    }

    this.bindEvents()

    this.broadcaster.sendMessage(message, this.subject)
    this.users.set(this.tabId, { ...message, userId: id })

    this.bindNatsEvents()

    // When the file editor/viewer is mounted, the tab subscribes the presence right away.
    // If the idleTime initial state is null, the tab should be focus and online.
    // But if the user duplicates multiple file editor tabs at once, all of the duplicated tabs are online.
    // To avoid this situation, there needs a defer function check the current tab is hidden or not.
    setTimeout(() => {
      this.checkTabIsHidden()
    }, 1000)
  }

  checkTabIsHidden() {
    const isHidden = document?.hidden || false
    if (isHidden) {
      this.updatePresence({ idleTime: new Date().toISOString(), isUpdate: true })
    }
  }

  createSubscription(fileId: string, originCb?: ((message: PresenceCallbackMessageData) => void) | null) {
    const subscribeSubject = `${fileId}.UserPresenceNew`

    // handle this.broadcaster.connection is broken-down
    let subscription: Subscription | undefined
    try {
      const callback = this.broadcaster.createSubscriptionCallback((content: PresenceCallbackMessageData) => {
        this.onMessage(content, originCb)
      })
      subscription = this.broadcaster.connection?.subscribe(subscribeSubject, {
        callback
      })
    } catch (error) {
      console.error('[PresenceManager] createSubscription error:', error)
    }

    return subscription
  }

  bindEvents() {
    focusBlurEventHandler = this.handleTabFocus.bind(this)
    // handle tab or window is focus or blur
    if (focusBlurEventHandler) {
      window.addEventListener('focus', focusBlurEventHandler)
      window.addEventListener('blur', focusBlurEventHandler)
    }
    // handle when window refresh/close or navigate to other pages
    unloadEventHandler = this.handleUnload.bind(this)
    if (unloadEventHandler) {
      window.addEventListener('beforeunload', unloadEventHandler)
    }
    visibilityChangeEventHandler = this.handleVisibility.bind(this)
    if (visibilityChangeEventHandler) {
      document.addEventListener('visibilitychange', visibilityChangeEventHandler)
    }
  }

  unbindEvents() {
    if (focusBlurEventHandler) {
      window.removeEventListener('focus', focusBlurEventHandler)
      window.removeEventListener('blur', focusBlurEventHandler)
      focusBlurEventHandler = null
    }
    if (unloadEventHandler) {
      window.removeEventListener('beforeunload', unloadEventHandler)
      unloadEventHandler = null
    }
    if (visibilityChangeEventHandler) {
      document.removeEventListener('visibilitychange', this.handleVisibility)
      visibilityChangeEventHandler = null
    }
  }

  handleConnectClosed() {
    if (!this.fileId) return

    const subscription = this.createSubscription(this.fileId, this.originCb)
    if (subscription) {
      this.broadcaster.presenceSubMap.set(this.fileId, subscription)
    }

    const currState = this.users.get(this.tabId)
    if (currState) {
      this.broadcaster.sendMessage(currState, this.subject)
    }
  }

  bindNatsEvents() {
    natsConnectClosedHandler = this.handleConnectClosed.bind(this)
    if (natsConnectClosedHandler) {
      document.addEventListener(CUSTOM_EVENT_NAMES.NATS_CONNECT_CLOSED, natsConnectClosedHandler)
    }
  }

  unbindNatsEvents() {
    if (natsConnectClosedHandler) {
      document.removeEventListener(CUSTOM_EVENT_NAMES.NATS_CONNECT_CLOSED, natsConnectClosedHandler)
      natsConnectClosedHandler = null
    }
  }

  bindCanvas(canvas: HTMLCanvasElement) {
    this.canvas = canvas
    canvasMouseMoveEventHandler = throttle(this.handleMouseMove.bind(this), 60)
    if (this.canvas && canvasMouseMoveEventHandler) {
      this.canvas.addEventListener('mousemove', canvasMouseMoveEventHandler)
    }
  }

  unbindCanvas() {
    if (this.canvas && canvasMouseMoveEventHandler) {
      this.canvas.removeEventListener('mousemove', canvasMouseMoveEventHandler)
      this.canvas = null
      canvasMouseMoveEventHandler = null
    }
  }

  bindDataStore(dataStore: any) {
    this.dataStore = dataStore
    dataStoreSelectEventHandler = this.handleElementSelect.bind(this)
    if (this.dataStore && dataStoreSelectEventHandler) {
      this.dataStore.selection.on('SELECT', dataStoreSelectEventHandler)
    }
  }

  unbindDataStore() {
    if (this.dataStore && dataStoreSelectEventHandler) {
      this.dataStore.selection.off('SELECT', dataStoreSelectEventHandler)
      this.dataStore = null
      dataStoreSelectEventHandler = null
    }
  }

  // subscribe files presence in the dashboard
  subscribePresenceFile(fileId: string, originCb?: (message: PresenceCallbackMessageData) => void) {
    if (!fileId) {
      console.error('[PresenceManager] subscribePresenceFile: fileId is required')
      return
    }
    this.fileId = fileId
    this.originCb = originCb as () => void
    const subscription = this.createSubscription(this.fileId, originCb)
    if (subscription) {
      this.broadcaster.presenceSubMap.set(fileId, subscription)
    }
  }

  // broadcast when window refresh/close or navigate to other pages
  removePresence(tabId: string, userData?: PresenceMessageData) {
    if (!tabId) {
      console.error('[PresenceManager] removePresence: tabId is required')
      return
    }
    if (userData) {
      this.broadcaster.sendMessage(
        { ...userData, type: 'presence.remove', protocol: 'v1', isUpdate: true },
        this.subject
      )
      this.users.delete(tabId)
    } else {
      const currentStates = this.users.get(tabId)
      if (currentStates) {
        const newStates: PresenceMessageData = {
          ...currentStates,
          type: 'presence.remove',
          protocol: 'v1',
          isUpdate: true
        }
        this.broadcaster.sendMessage(newStates, this.subject)
        this.users.delete(tabId)
      }
    }
  }

  // broadcast when the user state or editor mode changed
  updatePresence(updates: PresenceUpdateMessageData) {
    const { id, username, avatar } = this.profile

    // prevent events trigger earlier than subscription
    if (!this.fileId || !id || !username || !this.joinTime) return

    const { isUpdate } = updates

    const updateStates: PresenceMessageData = {
      type: 'presence.update',
      cursor: this.cursor,
      fileId: this.fileId,
      joinTime: this.joinTime,
      mode: this.mode,
      protocol: 'v1',
      selections: this.selections,
      user: { id, username, avatar },
      isVersioningState: this.isVersioningState,
      ...updates
    }

    if (isUpdate) {
      this.broadcaster.sendMessage(updateStates, this.subject)
    } else {
      this.broadcaster.sendMessage(updateStates, `${this.fileId}.UserPresenceNew`)
    }
  }

  onMessage(message: PresenceCallbackMessageData, cb?: ((message: PresenceCallbackMessageData) => void) | null) {
    const { tabId, type, serialNo = 1 } = message

    if (type === 'presence.remove') {
      this.users.delete(tabId)

      if (cb) {
        cb(message)
      }
    } else if (type === 'presence' || type === 'presence.update') {
      let newStates: PresenceCallbackMessageData

      if (this.users.has(tabId)) {
        const currState = this.users.get(tabId)
        const currSerialNo = currState?.serialNo || 1

        newStates = {
          ...currState,
          ...message,
          color: palette[(currSerialNo - 1) % palette.length]
        }
      } else {
        newStates = {
          ...message,
          color: palette[(serialNo - 1) % palette.length]
        }
      }

      this.users.set(tabId, newStates)

      if (cb) {
        cb(newStates)
      }
    }
  }

  handleModeChange(mode: Mode) {
    if (Object.values(Mode).includes(mode)) {
      this.mode = mode
      this.updatePresence({ mode, idleTime: null, isUpdate: true })
    }
  }

  handleVersioningStateChange(isVersioningState: boolean) {
    this.isVersioningState = isVersioningState
    this.updatePresence({ isVersioningState, idleTime: null, isUpdate: true })
  }

  private handleVisibility() {
    if (document.visibilityState === 'hidden') {
      sessionStorage.setItem(SESSION_STORAGE_KEYS.CACHED_TAB_ID, this.tabId)

      if (this.tabId && this.users.has(this.tabId)) {
        const currentStates = this.users.get(this.tabId)
        sessionStorage.setItem(SESSION_STORAGE_KEYS.CACHED_USER, JSON.stringify(currentStates))
      }
    }
  }

  private handleTabFocus(e: Event) {
    clearTimeout(_timerId)

    _timerId = setTimeout(() => {
      const { type } = e
      if (type === 'focus') {
        this.updatePresence({ idleTime: null, isUpdate: true })
      } else if (type === 'blur') {
        this.updatePresence({ idleTime: new Date().toISOString(), isUpdate: true })
      }
    }, 500)
  }

  private handleUnload() {
    this.unsubscribePresenceEditor(this.fileId)
  }

  private handleMouseMove(e: MouseEvent) {
    const { clientX, clientY } = e
    if (this.canvas) {
      const rect = this.canvas.getBoundingClientRect()
      const { x, y } = toWorld(clientX - rect.left, clientY - rect.top)
      this.cursor = { x, y }
      this.updatePresence({ idleTime: null, cursor: { x, y }, isUpdate: true })
    }
  }

  private handleElementSelect(changes: PropChange, options: { flags: EventFlag }) {
    if (this.dataStore) {
      if (options.flags === EventFlag.FROM_DRAG_DUPLICATE || !changes.get('elements')) {
        return
      }

      const elements = this.dataStore.selection.get('elements')
      const selections = elements.map((element: any) => element.data.id)
      this.selections = selections
      this.updatePresence({ selections, idleTime: null, isUpdate: true })
    }
  }

  setUsers(users: Map<string, PresenceCallbackMessageData>) {
    this.users = new Map(users)
  }

  getUsers() {
    const participants = Array.from(this.users.values())
    // sort by joinTime
    return participants.sort((a, b) => new Date(a.joinTime).getTime() - new Date(b.joinTime).getTime())
  }

  getColors() {
    return palette
  }

  unset() {
    this.users.clear()
    this.fileId = ''
    this.cursor = null
    this.selections = []
    this.mode = 0
    this.isPresenceShow = true
    this.joinTime = ''
    this.originCb = null
  }

  unsubscribePresenceEditor(fileId: string) {
    this.removePresence(this.tabId)
    this.unbindEvents()
    this.unbindNatsEvents()
    if (fileId) {
      this.broadcaster.unsubscribePresence(fileId)
    } else {
      console.error('[PresenceManager] unsubscribePresenceEditor: fileId is required.')
    }
    this.unset()
  }
}

export default PresenceManager
