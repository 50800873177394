import React from 'react'
import { useTranslation } from 'react-i18next'

import { Dialog } from '../shared'

type ImportFailedDialogProps = {
  error: string
  setError: (error: string) => void
}

export const ImportFailedDialog = ({ error, setError }: ImportFailedDialogProps) => {
  const { t } = useTranslation('workspace')
  return (
    <Dialog
      size="xs"
      title={t('import_failed')}
      iconName="Error"
      confirmBtnText={t('got_it')}
      onCancel={() => setError('')}
      onConfirm={() => setError('')}
      open={!!error}
    >
      <div className="text-12 flex items-center">{error}</div>
    </Dialog>
  )
}
export default ImportFailedDialog
