import {
    PaintType,
    BlendMode,
    ImageMode,
} from '@phase-software/types'
import {
    Vector4,
    createPropTypes,
    setAdd,
    Matrix2D,
    ColorStop
} from '@phase-software/data-utils'
import {
    GRADIENT_PAINT_SET,
    DEFAULT_FILL_VALUE,
    DEFAULT_GRADIENT_PAINT_DATA,
    DEFAULT_GRADIENT_TRANSFORM
} from '../constant'
import { ComputedLayer } from './ComputedLayer'


const PROP_TYPES = createPropTypes({
    color: { type: Vector4 },
    gradientTransform: { type: Matrix2D },
    gradientStops: { type: Array, itemType: ColorStop }
})

const PROP_KEYS_LAYER_TO_CL = {
    paint: {
        __switch__: 'paintType',
        SOLID: [
            'paintType',
            'blendMode',
            'opacity',
            'color'
        ],
        IMAGE: [
            'paintType',
            'blendMode',
            'opacity',
            'imageId',
            'imageMode'
        ],
        GRADIENT: [
            'paintType',
            'blendMode',
            'opacity',
            'gradientTransform',
            'gradientStops',
            'activeGradientStopIdx'
        ]
    }
}

const PROP_KEYS_CL_TO_LAYER = [
    {
        prop: 'paint',
        keys: {
            __switch__: 'paintType',
            SOLID: [
                'paintType',
                'color',
                'opacity',
                'blendMode'
            ],
            IMAGE: [
                'paintType',
                'blendMode',
                'opacity',
                'imageId',
                'imageMode'
            ],
            GRADIENT: [
                'paintType',
                'gradientTransform',
                'gradientStops',
                'opacity',
                'blendMode',
                'activeGradientStopIdx'
            ]
        }
    }
]

const UNDO_CHANGES = [
    'paintType',
    'blendMode',
    'opacity',
    'color',
    'imageId',
    'imageMode',
    'gradientTransform',
    'gradientStops',
    'activeGradientStopIdx'
]


export class ComputedFill extends ComputedLayer {
    /**
     * @param {DataStore} dataStore
     * @param {ComputedLayerData} data
     */
    constructor(dataStore, data) {
        super(dataStore, data)

        setAdd(this.undoChanges, UNDO_CHANGES)
    }

    _init() {
        super._init()

        this.propTypes = { ...this.propTypes, ...PROP_TYPES }

        this._propKeysLayerToCL = { ...this._propKeysLayerToCL, ...PROP_KEYS_LAYER_TO_CL }
        this._propKeysCLToLayer = [ ...this._propKeysCLToLayer, ...PROP_KEYS_CL_TO_LAYER ]

        this.data.paintType = PaintType.SOLID
        this.data.blendMode = BlendMode.NORMAL
        this.data.opacity = 1

        // Solid paint
        this.data.color = new Vector4(...DEFAULT_FILL_VALUE.color)

        // Image paint
        this.data.imageId = null
        this.data.imageMode = ImageMode.FILL

        // Gradient paint
        this.data.gradientTransform = new Matrix2D(...DEFAULT_GRADIENT_TRANSFORM)
        this.data.gradientStops = DEFAULT_GRADIENT_PAINT_DATA.map(gradientStop => new ColorStop(gradientStop))
        this.data.activeGradientStopIdx = 0
    }


    /**
     * @param {ComputedFillData} data
     */
    load(data) {
        super.load(data)

        const { paintType } = data

        // keys from Paint
        this.data.paintType = paintType
        this.data.blendMode = data.blendMode
        this.data.opacity = data.opacity

        if (paintType === PaintType.SOLID) {
            this.data.color = new Vector4(data.color)
        }
        else if (paintType === PaintType.IMAGE) {
            this.data.imageId = data.imageId
            this.data.imageMode = data.imageMode
        }
        else if (GRADIENT_PAINT_SET.has(paintType)) {
            this.data.gradientTransform = new Matrix2D(data.gradientTransform)
            this.data.gradientStops = PROP_TYPES.gradientStops.copy(data.gradientStops)
            this.data.activeGradientStopIdx = 0
        }
    }

    defaultValue() {
        return DEFAULT_FILL_VALUE
    }
}
