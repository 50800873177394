import { Undoable, EntityChange, PropChange, reverseEntityChange, reversePropChange } from '@phase-software/data-utils'
import { ChangesEvent, reverseChangesEvent } from '../Setter'

/**
 * @interface UndoOwner
 */

/**
 * @function
 * @name UndoOwner#undo
 * @param {string} type
 * @param {*} event
 */

const UNDO_CONTEXTS = ['EDITOR', 'TARGETING']

export class UndoEventGroup {
    constructor() {
        this._init()
    }

    _init() {
        this.events = []
        this.owners = new Map()
        this._IMEvents = []
        this._LIBEvents = []
    }

    /**
     * add undo owner/type/event data to event list
     * @param {UndoOwner} owner
     * @param {string} type
     * @param {*} event
     */
    add(owner, type, event) {
        if (this._combineEvents(owner, type, event)) {
            return
        }
        const e = { owner, type, event }
        this.events.push(e)
        switch (type) {
            case 'INTERACTION_CHANGES':
                this._IMEvents.push(e)
                break
            case 'LIBRARY_CHANGES':
                this._LIBEvents.push(e)
                break
        }
        if (this.owners.has(owner)) {
            this.owners.get(owner)[type] = event
        } else {
            this.owners.set(owner, { [type]: event })
        }
    }

    /**
     * return whether the event list is empty or not
     * @returns {boolean}
     */
    isEmpty() {
        return this.events.length === 0
    }

    /** undo all the events in list */
    undo() {
        // Always fire Library events before Interaction events
        const undoIMLater = this._LIBEvents.length
        for (let i = this.events.length - 1; i >= 0; i--) {
            const event = this.events[i]
            if (undoIMLater && event.type === 'INTERACTION_CHANGES') {
                continue
            }

            this.undoEvent(this.events[i])
        }

        if (undoIMLater) {
            for (let i = this._IMEvents.length - 1; i >= 0; i--) {
                this.undoEvent(this._IMEvents[i])
            }
        }
    }

    /** redo all the events in list */
    redo() {
        // Always fire Library events before Interaction events
        const redoIMLater = this._LIBEvents.length
        this.events.forEach(event => {
            if (redoIMLater && event.type === 'INTERACTION_CHANGES') {
                return
            }

            this.redoEvent(event)
        })
        if (redoIMLater) {
            this._IMEvents.forEach(this.redoEvent)
        }
    }

    undoEvent({ owner, type, event }) {
        if (!owner.undo) {
            throw new Error(
                `Unknown owner type for undo: ${owner.get('type')}`
            )
        }
        if (owner instanceof Undoable) {
            owner.undo(event)
        } else {
            owner.undo(type, event)
        }
    }

    redoEvent({ owner, type, event }) {
        if (!owner.redo) {
            throw new Error(`Unknown owner type for redo: ${owner.get('type')}`)
        }
        if (owner instanceof Undoable) {
            owner.redo(event)
        } else {
            owner.redo(type, event)
        }
    }

    _combineEvents(owner, type, event) {
        if (!owner.combineUndo) {
            return false
        }
        const records = this.owners.get(owner)
        if (!records || !(type in records)) {
            return false
        }
        return owner.combineUndo(type, records[type], event)
    }

    clear() {
        this._init()
    }
}

export default class Undo {
    constructor(dataStore) {
        this.dataStore = dataStore
        this.untrack()
        this.currentGroup = new UndoEventGroup()
        this.dataStore.on('LOAD-START', () => this.untrack())
        this.dataStore.on('LOAD', () => this.track())

        /**
         * @private
         * @type {object}
         * Map that contains all the undo/redo lists for different contexts
         */
        this._undoListMap = {}
        this._redoListMap = {}
        this.context = 'EDITOR'
        /**
         * @type {number}
         * whether inside an undo action
         */
        this.inUndo = 0
        this.inRedo = 0
    }

    get undoList() {
        if (!this._undoListMap[this.context]) {
            this._undoListMap[this.context] = []
        }
        return this._undoListMap[this.context]
    }

    set undoList(list) {
        this._undoListMap[this.context] = list
    }

    get redoList() {
        if (!this._redoListMap[this.context]) {
            this._redoListMap[this.context] = []
        }
        return this._redoListMap[this.context]
    }

    set redoList(list) {
        this._redoListMap[this.context] = list
    }

    get context() {
        return this._context
    }

    set context(type) {
        if (!UNDO_CONTEXTS.includes(type)) {
            throw new Error(`Unknown context type: ${type}`)
        }
        this._context = type
    }

    /**
     * reset undo and redo list to empty under current context
     */
    clear() {
        this.currentGroup = new UndoEventGroup()
        this.undoList = []
        this.redoList = []
        this._undoListMap = {}
        this._redoListMap = {}
        this.context = 'EDITOR'
        this.inUndo = 0
        this.inRedo = 0
    }

    /** turn off tracking of changes */
    untrack() {
        this.tracked = false
    }

    /** turn on tracking of changes */
    track() {
        this.tracked = true
    }

    /**
     * commits the outstanding actions to the undo queue
     */
    commit() {
        if (
            this.currentGroup.isEmpty() ||
            this.inUndo !== 0 ||
            this.inRedo !== 0
        ) {
            return
        }

        this.currentGroup.owners = undefined
        this._undoCommit(this.currentGroup)

        //
        this._fireUndoEvent(this.currentGroup)

        this.currentGroup = new UndoEventGroup()
        if (this.redoList.length) {
            this.redoList = []
        }
    }

    /**
     * @private
     * @param {UndoEventGroup} redo
     */
    _undoCommit(redo) {
        this.undoList.push(redo)
    }

    /**
     * @private
     * @param {UndoEventGroup} undo
     */
    _redoCommit(undo) {
        this.redoList.push(undo)
    }

    /**
     * adds variable changes to the undo buffer
     * @param {UndoOwner} owner
     * @param {string} type
     * @param {*} data
     */
    add(owner, type, data) {
        if (
            this.tracked &&
            this.inUndo === 0 &&
            this.inRedo === 0 &&
            (type === 'CHANGES' || owner.undoEvents.has(type))
        ) {
            this.currentGroup.add(owner, type, data)
        }
    }

    /** redo event group and set it to undo list */
    redo() {
        this.inRedo++
        if (this.redoList.length) {
            const undoEventGroup = this.redoList.pop()
            this.dataStore.startTransaction()
            undoEventGroup.redo()
            this.dataStore.endTransaction()
            this._undoCommit(undoEventGroup)
            this._fireUndoEvent(undoEventGroup)
        }
        this.inRedo--
    }

    /** undo event group and set it to redo list */
    undo() {
        // check if has uncommitted action group before undo
        this.commit()
        this.inUndo++
        if (this.undoList.length) {
            const undoEventGroup = this.undoList.pop()
            this.dataStore.startTransaction()
            undoEventGroup.undo()
            this.dataStore.endTransaction()
            this._redoCommit(undoEventGroup)
            this._fireUndoEvent(undoEventGroup, true)
        }
        this.inUndo--
    }

    /*
     * Fire UNDO event
     * @param {UndoEventGroup} undo
     * @param {bool} isUndo
     * */
    _fireUndoEvent(undo, isUndo) {
        const newUndo = {...undo}
        if (isUndo) {
            // undo.events = undo.events.map((event) => {
            newUndo.events = undo.events.map((event) => {
                const newEvent = {...event}
                if (event.event instanceof EntityChange) {
                    newEvent.event = reverseEntityChange(event.event)
                } else if (event.event instanceof PropChange) {
                    newEvent.event = reversePropChange(event.event)
                } else if (event.event instanceof ChangesEvent) { // setter events
                    newEvent.event = reverseChangesEvent(event.event)
                }
                return newEvent
            })
        }

        this.dataStore.emit('UNDO', newUndo, isUndo)
    }
}
