import React from 'react'
import { LoadingStatus } from '@phase-software/types'
import IconButton from '../IconButton'
import ProgressButton from '../ProgressButton'
import Button from '../Button'
import LinkSwitcher from '../LinkSwitcher'

type FullScreenDialogProps = {
  /**
   * The text to display for the alternate action.
   */
  altActionText?: string
  children: React.ReactNode
  confirmText?: string
  contentWidth?: 400 | 600 | 800
  disableConfirm?: boolean
  onAltAction?: () => void
  onClose: () => void
  onConfirm?: () => void
  open: boolean
  progress?: LoadingStatus
  title: React.ReactNode
  footer?: React.ReactNode
}
const FullScreenDialog = ({
  altActionText,
  children,
  confirmText,
  contentWidth = 400,
  disableConfirm = false,
  onAltAction,
  onClose,
  onConfirm,
  open,
  progress = 0,
  title,
  footer
}: FullScreenDialogProps) => {
  if (!open) {
    return null
  }

  const ConfirmButton = progress ? ProgressButton : Button

  return (
    <div className="fixed inset-0 z-10 flex flex-col items-center bg-neutral-90 text-white text-center">
      <div className="h-[88px] p-32 flex w-full">
        <IconButton icon="Cross" onClick={onClose} className="text-light-overlay-60 ml-auto" aria-label="Close" />
      </div>
      <div
        className="flex-grow flex flex-col items-center justify-center max-h-full z-10 relative"
        style={{ width: contentWidth }}
      >
        <div className="text-h3 font-semibold mb-32">{title}</div>
        {children}
        {footer ? (
          <div className="flex w-full gap-16 mt-32">{footer}</div>
        ) : (
          <>
            {confirmText && (
              <ConfirmButton
                status={progress}
                disabled={disableConfirm}
                onClick={onConfirm}
                size="l"
                color="primary"
                className="mt-32"
                fluid
              >
                {confirmText}
              </ConfirmButton>
            )}
          </>
        )}
        {altActionText && (
          <LinkSwitcher onClick={onAltAction} className="text-primary-40 mt-16 highlight-border-rounded-3">
            {altActionText}
          </LinkSwitcher>
        )}
      </div>
    </div>
  )
}

export default FullScreenDialog
