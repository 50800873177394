/** @typedef {import('../visual_server/VisualServer').VisualServer} VisualServer */
/** @typedef {import('../visual_server/SpatialCache').SceneNode} SceneNode */
/** @typedef {import('@phase-software/data-store/src/Element').Element} Element */
/** @typedef {import('../overlay/Overlay').Pane} Pane */
/** @typedef {import('@phase-software/data-utils').AABB} AABB */
/** @typedef {import('@phase-software/data-utils').Mesh} Mesh */

import { EditMode, ElementType, GeometryType, Mode, ToolType } from '@phase-software/types'
import { Events } from '@phase-software/data-store'
import { isNull } from '@phase-software/data-utils'
import { meshToPathData } from '../geometry/generate'
import { Transform2D, Vector2, Rect2 } from '../math'
import { rgb2hex } from '../math/Color'
// import { ImageAtlas } from "../resources/ImageAtlas"
import { loadChar, loadImage } from '../overlay/Overlay'
import { BezierShape } from '../geometry/bezier-shape/BezierShape'
import { PathData } from '../geometry/PathData'
import { _getOffsetToLeftTop } from '../actions/gradient'
import { MIN_SIZE_THRESHOLD } from '../constants'
import { getSizeFlag } from '../visual_server/RenderItem'
import Origin_Enabled from './svg/Origin_Enabled.svg'
import Origin_Hover from './svg/Origin_Hover.svg'
import Origin_Inactive from './svg/Origin_Inactive.svg'
import Ring_Shadow from './svg/Ring_Shadow.svg'
import Gradient_Stop_Image from './svg/Gradient_Stop_Image.svg'
import Gradient_Stop_Outline_Shadow from './svg/Gradient_Stop_Outline_Shadow.svg'
import Gradient_Stop_Center from './svg/Gradient_Stop_Center.svg'
import Gradient_Handle from './svg/Gradient_Handle.svg'
import Move from './svg/Move.svg'
import Move_stroke from './svg/Move_stroke.svg'
import * as UIGrid from './grid'
import * as UIScreenNames from './screenNames'
import * as UIRuler from './ruler'
import * as UIScrollBar from './scrollbar'
import * as MeshHelper from './meshHelper'
import * as MotionPathHelper from './MotionPathHelper'
import { getNodeDino } from '../dino'
import { CELL_PADDING } from '..'

const HANDLE_SIZE = 8
const COLOR = 0x1C6EE8
const OUTLINE_COLOR = 0xffffff
const GRADIENT_HANDLE_IMAGE_SIZE = 64
const GRADIENT_STOP_IMAGE_SIZE = 12

const PRESENCE_CONFIG = {
    nameMaxLength: 180,
    tagPadding: 6,
    tagHeight: 20,
    cursorOffset: new Vector2(10, 10),
    tagCornerRadius: 6,
    tagBorderWidth: 1,
    tagBorderOpacity: 0.1,
    tagBorderColor: 0x00,
    tagOffset: new Vector2(10, 16),
    nameOffset: new Vector2(6, 18),
    fontWeight: 400,
    letterSpacing: 0.11,
    selectionWidth: 2,
    fontSize: 11,
    fontFamily: 'Roboto'
}

// Flags to control Screen name and Grid rendering
let _UIScreenNameEnabled = true
let _UIGridEnabled = true
let _UIOriginEditModeEnable = false

const xform = new Transform2D()
const xform2 = new Transform2D()
const point = new Vector2()
const point2 = new Vector2()
const shape = BezierShape.create()
const pathData = new PathData()

/** @type {VisualServer} */
let _visualServer

const _pane = {
    /** @type {Pane} */
    presenceCursor: null,
    /** @type {Pane} */
    presenceSelection: null,
    /** @type {Pane} */
    hover: null,
    /** @type {Pane} */
    selection: null,
    /** @type {Pane} */
    handles: null,
    /** @type {Pane} */
    gradientHandles: null,
    /** @type {Pane} */
    gradientHandlesCover: null,
    /** @type {Pane} */
    text: null,
    /** @type {Pane} */
    selectionArea: null,
    /** @type {Pane} */
    snapping: null,
    test: null,
    /** @type {Pane} */
    snappingSapcing: null,
    /** @type {Pane} */
    table: null,
}

// let _dirtyGridAndRuler = true
let _hideRuler = false
let _hideInterface = false

/**
 * @param {VisualServer} visualServer
 */
export function initPanes(visualServer) {
    exposeToConsole()
    _visualServer = visualServer
    let i = 0

    UIGrid.init(_visualServer, i++)
    UIScreenNames.init(_visualServer, i++)

    _pane.presenceSelection = _visualServer.overlay.createPane(i++)
    _pane.highlightBbox = _visualServer.overlay.createPane(i++)
    _pane.snappingSapcing = _visualServer.overlay.createPane(i++)
    _pane.selection = _visualServer.overlay.createPane(i++)
    _pane.handles = _visualServer.overlay.createPane(i++)
    _pane.gradientHandles = _visualServer.overlay.createPane(i++)
    _pane.gradientHandlesCover = _visualServer.overlay.createPane(i++)
    _pane.text = _visualServer.overlay.createPane(i++)
    MeshHelper.init(_visualServer, i++, i++, i++, i++) // 4 panes for mesh helper
    MotionPathHelper.init(_visualServer, i++, i++, i++) // 4 panes for mesh helper
    _pane.selectionArea = _visualServer.overlay.createPane(i++)
    _pane.snapping = _visualServer.overlay.createPane(i++)
    _pane.presenceCursor = _visualServer.overlay.createPane(i++)
    // _pane.test = _visualServer.overlay.createPane(i++)

    UIScrollBar.init(_visualServer, i++)
    // UIRuler will create 3 panes
    UIRuler.init(_visualServer, i++)

    _pane.editOrigin = _visualServer.overlay.createPane(i++)
    _pane.table = _visualServer.overlay.createPane(i++)
    _preloadSVGFiles()

    // _visualServer.viewport.on('update', () => { _dirtyGridAndRuler = true })
    // _visualServer.dataStore.on('state', () => { _dirtyGridAndRuler = true }) // TODO: should listen to IS state change

    _visualServer.dataStore.eam.on(Events.TOGGLE_INTERFACE, () => {
        _hideInterface = !_hideInterface
    })

    _visualServer.dataStore.eam.on(Events.TOGGLE_RULER, () => {
        _hideRuler = !_hideRuler
    })
}

/**
 * Enables the UI Screen Name feature.
 */
function setUIScreenNameEnabled() {
    _UIScreenNameEnabled = true
    UIScreenNames.update()
    if (_visualServer.dataStore.isEditingState) UIScreenNames.update()
}

/**
 * Enables the UI Grid feature.
 */
function setUIGridEnabled() {
    _UIGridEnabled = true
    UIGrid.update()
}

/**
 * Disables the UI Screen Name feature.
 */
function setUIScreenNameDisabled() {
    _UIScreenNameEnabled = false
    UIScreenNames.clear()
}

/**
 * Disables the UI Grid feature.
 */
function setUIGridDisabled() {
    _UIGridEnabled = false
    UIGrid.clear()
}

/**
 * Returns the current enabled state of the UI Grid.
 * @returns {boolean} True if UI Grid is enabled, false otherwise.
 */
function getUIGridEnabled() {
    return _UIGridEnabled
}

/**
 * Returns the current enabled state of the UI Screen Name.
 * @returns {boolean} True if UI Screen Name is enabled, false otherwise.
 */
function getUIScreenNameEnabled() {
    return _UIScreenNameEnabled
}

function setOriginEnable() {
    _UIOriginEditModeEnable = true
}

/**
 * Exposes certain UI functionalities to the browser's console for easy access.
 */
function exposeToConsole() {
    if (!window.__Panes__) {
        window.__Panes__ = {
            setUIScreenNameEnabled,
            setUIGridEnabled,
            setUIScreenNameDisabled,
            setUIGridDisabled,
            getUIGridEnabled,
            getUIScreenNameEnabled,
            setOriginEnable
        }
    }
}

export function updatePanes() {
    if (_UIGridEnabled) {
        UIGrid.update()
    }

    if (_UIScreenNameEnabled) {
        if (_visualServer.dataStore.isEditingState) UIScreenNames.update()
    }
    _drawPresenceSelectionUI()
    _drawSnappingSpacing()
    if (_visualServer.dataStore.get('activeTool') !== ToolType.COMMENT) {
        _drawHighlightBox() // hover and drag over bbox
        _drawSelection()
        _drawHandlesAndOrigin()
    }
    _drawGradientHandles()
    _drawSnapping()
    _drawText()
    MeshHelper.update()
    MotionPathHelper.update()
    _drawSelectionArea()
    _drawPresenceCursorNameUI()
    UIScrollBar.update()
    // if (_dirtyGridAndRuler) {
    if (_hideRuler || _hideInterface || (!_visualServer.dataStore.isEditingState && !_visualServer.dataStore.isInspectingState)) {
        UIRuler.clear()
    } else {
        UIRuler.update()
    }
    _UIOriginEditModeEnable = _visualServer.dataStore.getFeature('editOrigin')
    _drawTable()
    // _dirtyGridAndRuler = false
}

// Help to draw any test result.
// Please do not remove this.
// If need this, please call it directly.
// Do not put it into updatePanes.

/**
 * @callback anyCallback
 */
/**
 * Draw test api which help engineer to draw something to help implementing area detection.
 * @param {anyCallback} cb
 */
export function drawTest(cb) {
    _pane.test.clear()
    if (cb) {
        cb(_pane.test)
    }
}

function _drawSelection() {
    _pane.selection.clear().lineStyle(1.5, COLOR)
    if (_draggedElement) return

    const editMode = _visualServer.dataStore.get('editMode')
    const activeTool = _visualServer.dataStore.get('activeTool')
    if (editMode === EditMode.SHAPE || editMode === EditMode.GRADIENT_HANDLES) return

    if (_visualServer.selection.containsMultiple) {
        if (activeTool === ToolType.SCALE) {
            for (const { element, node } of _visualServer.selection.iter()) {
                if (!element.isLineElement()) {
                    _drawBounds(_pane.selection, element, node)
                }
            }
            for (const { element, node } of _visualServer.selection.iter()) {
                _drawOutline(_pane.selection, element, node)
            }
        } else {
            const bbox = _visualServer.selection.bounds.clone()
            const { width, height } = bbox
            const sizeFlagW = getSizeFlag(width)
            const sizeFlagH = getSizeFlag(height)
            if (sizeFlagW === 'f0') {
                bbox.width = 0
                bbox.x += width * 0.5
            }
            if (sizeFlagH === 'f0') {
                bbox.height = 0
                bbox.y += height * 0.5
            }
            _drawRect(_pane.selection, bbox)

            if (sizeFlagW === '!0' && sizeFlagH === '!0') {
                for (const { element, node } of _visualServer.selection.iter()) {
                    _drawOutline(_pane.selection, element, node)
                }
            }
        }
    } else if (_visualServer.selection.single) {
        // Note: This function is asynchronous. Ensure to check selection status promptly.
        const { element, node } = _visualServer.selection.single
        _drawOutline(_pane.selection, element, node)
        // Lines don't have bounds, so we wron't draw a rect
        if (!_draggedElement && !element.isLineElement()) {
            _drawBounds(_pane.selection, element, node)
        }
    }
}


let canDrawSpanning = true
export function enableSnapping() {
    canDrawSpanning = true
}
export function disableSnapping() {
    canDrawSpanning = false
}

function _drawTable() {
    _pane.table.clear()

    if (!_visualServer.dataStore.isTablingState) return

    const elId = _visualServer.dataStore.selection.get('activeTableElement')
    if (!elId) return

    const node = _visualServer.indexer.getNode(elId)

    _pane.table
        .clear()
        .fillStyle(0xFF0000)
        .lineStyle(2, 0xFF0000)

    if (node && _visualServer.indexer.versions.bbox) {
        const offset = node.item.baseTransform.getPivotOffset()
        const { translate } = node.item.baseTransform
        const { idx } = getNodeDino(node.item)
        const padding = CELL_PADDING
        const rowCount = node.item.dino_actions.length
        for (let i=0 ; i <= rowCount; i++) {
            const bbox = _visualServer.indexer.versions.bbox.clone()
            bbox.width += 2 * padding
            bbox.height += 2 * padding
            // const transform = node.item.baseTransform.local.clone().translate(- padding, - padding + i * bbox.height)
            const transform = new Transform2D().translate(
                - padding + translate.x - offset.x,
                - padding + (i - idx - 1) * bbox.height + translate.y - offset.y
            )

            const T = xform.copy(transform).prepend(_visualServer.viewport.projectionTransform)
            const left = T.xform(point.set(bbox.x, bbox.y + bbox.height))
            const right = T.xform(point.set(bbox.x + bbox.width, bbox.y + bbox.height))
            if (i !== rowCount) {
                _pane.table.drawLine(left.x, left.y, right.x, right.y)
            }
            _pane.table.fillStyle(((idx + 1) == i) ? 0xFF0000 : 0xFFCBCB)
            _pane.table.drawSolidRect(left.x + 10, left.y - 30, 80, 20, 8)
            _pane.table.fillStyle(0x000000)
            _pane.table.drawText(left.x + 20, left.y - 30, i===0 ? 'Design' : `Action ${i}`, 8, 'left', 'top')
        }

        const bbox = _visualServer.indexer.versions.bbox.clone()
        bbox.x -= padding
        bbox.y -= padding
        bbox.width += 2 * padding
        bbox.height += 2 * padding
        bbox.height *= (rowCount + 1)

        // const transform = node.item.baseTransform.local.clone()
        const transform = new Transform2D().translate(
            translate.x - offset.x,
            translate.y - offset.y - (idx + 1) * (_visualServer.indexer.versions.bbox.height + 2 * padding)
        )
        const T = xform.copy(transform).prepend(_visualServer.viewport.projectionTransform)
        _pane.table.appendTansform(T)
        _pane.table.drawRect(bbox.x, bbox.y, bbox.width, bbox.height, 8)
        _pane.table.resetTransform()
    }
}

function _drawSnapping() {
    if (!canDrawSpanning) {
        return
    }

    _visualServer.snapping.updateThreshold(_visualServer.viewport.scale)
    _pane.snapping.clear().lineStyle(1, 0xFF0000)
    if (!_visualServer.snapping.isSnappingUIDataDirty()) return
    const T = xform.reset().prepend(_visualServer.viewport.projectionTransform)
    _pane.snapping.appendTansform(T)
    if (_visualServer.snapping.axisDiagonalCount > 0) {
        const lineLength = (_visualServer.viewport.width + _visualServer.viewport.height) * 2
        const startP = new Vector2()
        const endP = new Vector2()
        for (let index = 0; index < _visualServer.snapping.axisDiagonalCount; index++) {
            startP.copy(_visualServer.snapping.axisDiagonalVec[index])
                .scale(lineLength)
                .add(_visualServer.snapping.startOrigin)
            endP.copy(_visualServer.snapping.axisDiagonalVec[index])
                .scale(lineLength)
                .negate()
                .add(_visualServer.snapping.startOrigin)
            _pane.snapping.drawLine(startP.x, startP.y, endP.x, endP.y)
        }
    }

    /** @type {Map<number, Map<number, number>>} */
    const xUIData = _visualServer.snapping.snapToElementXUI
    const yUIData = _visualServer.snapping.snapToElementYUI
    const s = T.get_scale()
    const crossLength = 3 / s.x
    let min = 0xffffff
    let max = -0xffffff
    if (xUIData.size > 0) {
        for (const [x1, mapY1] of xUIData.entries()) {
            min = 0xffffff
            max = -0xffffff
            for (const [y1,] of mapY1.entries()) {
                min = min > y1 ? y1 : min
                max = max > y1 ? max : y1
                _pane.snapping.drawLine(
                    x1 - crossLength,
                    y1 - crossLength,
                    x1 + crossLength,
                    y1 + crossLength
                )
                _pane.snapping.drawLine(
                    x1 + crossLength,
                    y1 - crossLength,
                    x1 - crossLength,
                    y1 + crossLength
                )
            }
            _pane.snapping.drawLine(x1, min, x1, max)
        }
    }


    if (yUIData.size > 0) {
        for (const [y1, mapX1] of yUIData.entries()) {
            min = 0xffffff
            max = -0xffffff
            for (const [x1,] of mapX1.entries()) {
                min = min > x1 ? x1 : min
                max = max > x1 ? max : x1
                _pane.snapping.drawLine(
                    x1 - crossLength,
                    y1 - crossLength,
                    x1 + crossLength,
                    y1 + crossLength
                )
                _pane.snapping.drawLine(
                    x1 + crossLength,
                    y1 - crossLength,
                    x1 - crossLength,
                    y1 + crossLength
                )
            }
            _pane.snapping.drawLine(min, y1, max, y1)
        }
    }

    _pane.snapping.resetTransform()

}

function _drawSnappingSpacing() {
    if (!canDrawSpanning) {
        return
    }
    if (!_visualServer.snapping.isSnappingUIDataDirty()) return
    const T = xform.reset().prepend(_visualServer.viewport.projectionTransform)
    _pane.snappingSapcing.clear()
    _pane.snappingSapcing.appendTansform(T)
    /** @type {Map<string, Rect2>} */
    const spacingArea = _visualServer.snapping.spacingAreaUIMap
    const spacingVerticalVec = _visualServer.snapping.spacingVerticalVec
    const spacingHorizonalVec = _visualServer.snapping.spacingHorizonalVec
    const spacingVertical = _visualServer.snapping.spacingVertical
    const spacingHorizonal = _visualServer.snapping.spacingHorizonal

    _pane.snappingSapcing.fillStyle(0xff0000, 0.2)
    for (const rect of spacingArea.values()) {
        _pane.snappingSapcing.drawSolidRect(rect.x, rect.y, rect.width, rect.height)
    }
    // if (_visualServer.snapping.debugRect){
    //     _pane.snappingSapcing.fillStyle(0x00ff00, 0.2)
    //     _pane.snappingSapcing.drawSolidRect(_visualServer.snapping.debugRect.x, _visualServer.snapping.debugRect.y, _visualServer.snapping.debugRect.width, _visualServer.snapping.debugRect.height)
    // }
    _pane.snappingSapcing.resetTransform()
    if (!spacingHorizonal && !spacingVertical) return
    const endMarginSize = 3 * _visualServer.viewport.pixelRatio / _visualServer.viewport.scale
    const pos = new Vector2()
    const dir = new Vector2()
    _pane.snappingSapcing.lineStyle(1, 0xDC4B5C)
    if (spacingHorizonal) {
        pos.set(spacingHorizonalVec.x, spacingHorizonalVec.y)
        dir.set(spacingHorizonalVec.x + spacingHorizonal, spacingHorizonalVec.y)
        _visualServer.viewport.projectionTransform.xform(pos, pos)
        _visualServer.viewport.projectionTransform.xform(dir, dir)
        _pane.snappingSapcing.drawLine(pos.x, pos.y, dir.x, dir.y)
        //
        pos.set(spacingHorizonalVec.x, spacingHorizonalVec.y - endMarginSize)
        dir.set(spacingHorizonalVec.x, spacingHorizonalVec.y + endMarginSize)
        _visualServer.viewport.projectionTransform.xform(pos, pos)
        _visualServer.viewport.projectionTransform.xform(dir, dir)
        _pane.snappingSapcing.drawLine(pos.x, pos.y, dir.x, dir.y)
        //
        pos.set(spacingHorizonalVec.x + spacingHorizonal, spacingHorizonalVec.y - endMarginSize)
        dir.set(spacingHorizonalVec.x + spacingHorizonal, spacingHorizonalVec.y + endMarginSize)
        _visualServer.viewport.projectionTransform.xform(pos, pos)
        _visualServer.viewport.projectionTransform.xform(dir, dir)
        _pane.snappingSapcing.drawLine(pos.x, pos.y, dir.x, dir.y)
        // Text
        pos.set(spacingHorizonalVec.x + spacingHorizonal * 0.5, spacingHorizonalVec.y)
        _visualServer.viewport.projectionTransform.xform(pos, pos)
        _drawSpacingValue(pos.x, pos.y, spacingHorizonal, true)
    }

    if (spacingVertical) {
        pos.set(spacingVerticalVec.x, spacingVerticalVec.y)
        dir.set(spacingVerticalVec.x, spacingVerticalVec.y + spacingVertical)
        _visualServer.viewport.projectionTransform.xform(pos, pos)
        _visualServer.viewport.projectionTransform.xform(dir, dir)
        _pane.snappingSapcing.drawLine(pos.x, pos.y, dir.x, dir.y)
        //
        pos.set(spacingVerticalVec.x - endMarginSize, spacingVerticalVec.y)
        dir.set(spacingVerticalVec.x + endMarginSize, spacingVerticalVec.y)
        _visualServer.viewport.projectionTransform.xform(pos, pos)
        _visualServer.viewport.projectionTransform.xform(dir, dir)
        _pane.snappingSapcing.drawLine(pos.x, pos.y, dir.x, dir.y)
        //
        pos.set(spacingVerticalVec.x - endMarginSize, spacingVerticalVec.y + spacingVertical)
        dir.set(spacingVerticalVec.x + endMarginSize, spacingVerticalVec.y + spacingVertical)
        _visualServer.viewport.projectionTransform.xform(pos, pos)
        _visualServer.viewport.projectionTransform.xform(dir, dir)
        _pane.snappingSapcing.drawLine(pos.x, pos.y, dir.x, dir.y)
        //
        pos.set(spacingVerticalVec.x, spacingVerticalVec.y + spacingVertical * 0.5)
        _visualServer.viewport.projectionTransform.xform(pos, pos)
        _drawSpacingValue(pos.x, pos.y, spacingVertical, false)
    }

}

/**
 * @param {number} x
 * @param {number} y
 * @param {number} value
 * @param {boolean} horizontal
 */
function _drawSpacingValue( x, y , value, horizontal) {
    const valueStr = value.toString()
    const textInfo8 = loadChar("8", "Inter", 9).imageInfo
    // use left and right labels to determine size of numbers and normalize the digits to '8' to avoid flickering
    const textWidth = (textInfo8.w / textInfo8.pixelRatio) * valueStr.length
    const textHeight = (textInfo8.h / textInfo8.pixelRatio)
    const fontAdjustX = 1.5
    const fontAdjustY = 4
    const bgCorner = 4
    const marginToLine = 4.5
    if (horizontal) {
        _pane.snappingSapcing.fillStyle(0xDC4B5C, 1).drawSolidRectTexture(x - textWidth * 0.5 - bgCorner, y + marginToLine, textWidth + bgCorner * 2, textHeight + fontAdjustY, bgCorner)
        _pane.snappingSapcing.fillStyle(OUTLINE_COLOR, 1).drawText(x, y + marginToLine + fontAdjustY, valueStr, 4, "center", "top")

    } else {
        _pane.snappingSapcing.fillStyle(0xDC4B5C, 1).drawSolidRectTexture(x - textWidth - marginToLine - bgCorner * 2, y - textHeight * 0.5 - fontAdjustY, textWidth + bgCorner * 2, textHeight + fontAdjustY, bgCorner)
        _pane.snappingSapcing.fillStyle(OUTLINE_COLOR, 1).drawText(x - marginToLine - bgCorner - fontAdjustX, y, valueStr, 4, "right", "center")
    }
}

/**
 * @param {Transform2D} T
 * @param {Vector2} offset
 * @param {number} r
 */
const drawCorner = (T, offset, r = 0) => {
    const pos = T.xform(offset)
    xform2.reset().rotate(r).translate(pos.x, pos.y)
    _pane.handles.appendTansform(xform2)
    _pane.handles
        .drawSolidRect(-HANDLE_SIZE * 0.5, -HANDLE_SIZE * 0.5, HANDLE_SIZE, HANDLE_SIZE)
        .drawRect(-HANDLE_SIZE * 0.5, -HANDLE_SIZE * 0.5, HANDLE_SIZE, HANDLE_SIZE)
    _pane.handles.resetTransform()
}

function _drawHandlesAndOrigin() {
    _pane.handles
        .clear()
        .lineStyle(1.5, COLOR)
        .fillStyle(0xFFFFFF)

    if (_draggedElement) return
    if (!_visualServer.dataStore.selection.get('elements').length) return
    if (_visualServer.dataStore.get('editMode') !== EditMode.ELEMENT &&
        _visualServer.dataStore.get('editMode') !== EditMode.MOTION_PATH) return

    /**
     * @param {Transform2D} T
     * @param {Rect2} rect
     * @param {Element} element
     * @param {SceneNode} node
     */
    const drawHandles = (T, rect, element, node) => {
        if (node && node.item.isEmpty) {
            const pos = T.xform(rect.center)
            xform2.reset().translate(pos.x, pos.y)
            _pane.handles.appendTansform(xform2)
            _pane.handles
                .drawEllipseShadow(0, 0, 2, 2)
            _pane.handles.resetTransform()
            return
        }

        const singleIsNotLocked = element && !element.get('locked')
        // TODO: need to optimize
        // check element and its any parent is locked
        const isAllElementLocked = _visualServer.selection.all(item => item.element.isLocked())

        if ((singleIsNotLocked || !element) && !isAllElementLocked) {
            const r = node ? node.item.transform.saveWorld.get_rotation() : 0
            if (element && element.isLineElement()) {
                _drawLineCorner(element, T, drawCorner, rect, r)
            } else {
                drawCorner(T, point.set(rect.left, rect.top), r)
                drawCorner(T, point.set(rect.left, rect.bottom), r)
                drawCorner(T, point.set(rect.right, rect.top), r)
                drawCorner(T, point.set(rect.right, rect.bottom), r)
            }
        }
    }

    /** @type {Rect2} */
    let rect
    if (!_visualServer.dataStore.isInspectingState) {
        if (_visualServer.dataStore.get('activeTool') === ToolType.SCALE) {
            for (const selectedItem of _visualServer.selection.iter()) {
                rect = selectedItem.node.boundsLocalVisualZero
                const handlesOverlapping = shouldHandlesHidden(rect, selectedItem.node.item.transform.world)
                if (handlesOverlapping) continue

                xform.copy(selectedItem.node.item.transform.world)
                const T = xform.prepend(_visualServer.viewport.projectionTransform)
                drawHandles(T, rect, selectedItem.element, selectedItem.node)
            }
        } else {
            const single = _visualServer.selection.single
            if (single === null) {
                xform.reset()
                rect = _visualServer.selection.bounds.clone()
                const { width, height } = _visualServer.selection.bounds
                const sizeFlagW = getSizeFlag(width)
                const sizeFlagH = getSizeFlag(height)
                if (sizeFlagW === 'f0') {
                    rect.width = 0
                    rect.x += width * 0.5
                }
                if (sizeFlagH === 'f0') {
                    rect.height = 0
                    rect.y += height * 0.5
                }
            } else {
                xform.copy(single.node.item.transform.world)
                rect = single.node.boundsLocalVisualZero.clone()
            }

            const handlesOverlapping = shouldHandlesHidden(rect, xform)
            if (!handlesOverlapping) {
                const T = xform.prepend(_visualServer.viewport.projectionTransform)
                drawHandles(T, rect, single?.element, single?.node)
            }
        }
    }

    // draw origins
    if (_visualServer.dataStore.get('hideOrigin')) return
    for (const { element, node } of _visualServer.selection.iter()) {
        const isElementLocked = element.isLocked()
        const isScreenElement = element.get('elementType') === ElementType.SCREEN
        const isHoveredElement = element.get('id') === _hoveredOriginId

        if (isScreenElement) {
            continue
        }
        if (_UIOriginEditModeEnable && !isElementLocked) {
            const originState = isHoveredElement ? Origin_Hover : Origin_Enabled
            _drawOrigin(_pane.handles, node, originState, 1, 1)
        } else {
            // Using an SVG at twice the intended size, so the actual size is half.
            _drawOrigin(_pane.handles, node, Origin_Inactive, 0.5, 1)
        }

    }
}

/**
 * @callback drawCornerCallback
 * @param {Transform2D} T
 * @param {Vector2} offset
 * @param {number} r
 */
/**
 * @param {Element} element
 * @param {SceneNode} node
 * @param {Transform2D} T
 * @param {drawCornerCallback} drawCorner
 * @param {Rect2} rect
 * @param {number} r
 */
function _drawLineCorner(element, T, drawCorner, rect, r = 0) {
    const mesh = element.get('geometry').get('mesh')
    const edge = mesh.edges.values().next().value
    const duVpos = mesh.getVertPos(edge.v.id)
    const duWPos = mesh.getVertPos(edge.w.id)
    const signX = Math.sign(duWPos.x - duVpos.x)
    const signY = Math.sign(duWPos.y - duVpos.y)
    if (signX * signY > 0) {
        drawCorner(T, point.set(rect.left, rect.top), r)
        drawCorner(T, point.set(rect.right, rect.bottom), r)
    } else {
        drawCorner(T, point.set(rect.right, rect.top), r)
        drawCorner(T, point.set(rect.left, rect.bottom), r)
    }
}

const sizeW = new Vector2()
const sizeH = new Vector2()
const trans = new Transform2D()
/**
 * @param {Rect2} rect
 * @param {Vector2} scale
 * @param transform
 * @returns {boolean} `true` if all handles are overlapping each other and rect size >= `ZERO_SIZE_THRESHOLD`
 */
export function shouldHandlesHidden(rect, transform) {
    const threshold = HANDLE_SIZE / _visualServer.viewport.projectionTransform.a
    sizeW.set(rect.width, 0)
    sizeH.set(0, rect.height)
    // because handle will rotate with world rotation, so the overlap distance detection here should ignore the rotation
    trans.copy(transform).rotate(-transform.get_rotation())
    trans.basis_xform(sizeW, sizeW)
    trans.basis_xform(sizeH, sizeH)

    const isZeroZero = Math.abs(sizeW.x) < MIN_SIZE_THRESHOLD && Math.abs(sizeH.y) < MIN_SIZE_THRESHOLD
    const bothDimensionTooClose = Math.abs(sizeW.x) <= (threshold + 1e-6) && Math.abs(sizeH.y) <= (threshold + 1e-6)
    return !isZeroZero && bothDimensionTooClose
}

/**
 * @param {Pane} pane
 * @param {SceneNode} node
 * @param {string} imageSrc
 * @param {number} ratio
 * @param {number} alpha
 */
function _drawOrigin(pane, node, imageSrc, ratio, alpha) {
    pane.fillStyle(0xFFFFFF, alpha)
    // origin image is using double resolution, so the size should be half
    const pos = node.item.transform.worldPivot

    _visualServer.viewport.projectionTransform.xform(pos, pos)
    pane.drawImageFromAtlas(imageSrc, pos.x, pos.y, ratio, ratio)
}

/** @type {import('../actions/gradient').Gradient} */
let _gradient = null

/** @param {import('../actions/gradient').Gradient} gradient */
export function setGradient(gradient) {
    _gradient = gradient
}

function _drawGradientHandles() {
    _pane.gradientHandles.clear()
    _pane.gradientHandlesCover.clear()

    if (!_gradient){
        return
    }
    const gradientTransform = _gradient.getGradientTransform()
    if (!gradientTransform){
        return
    }
    const first = _visualServer.selection.first
    if (
        first === null ||
        _visualServer.dataStore.get('editMode') !== EditMode.GRADIENT_HANDLES ||
        !_gradient ||
        !_visualServer.selection.single
    ) return

    const gradientScale = new Vector2()
    let offset, elementSize

    const isLineElement = first.element.get('elementType') === ElementType.PATH && first.element.get('geometryType') === GeometryType.LINE
    if (isLineElement) {
        const { x, y, width, height } = first.node.boundsVisualLocal
        elementSize = new Vector2(width, height)
        offset = new Vector2(-x, -y)
    } else {
        const { width, height } = first.node.boundsLocal
        elementSize = new Vector2(width, height)
        offset = _getOffsetToLeftTop(first)
    }

    const T = xform
        .set(...gradientTransform)
        .affine_inverse()
        .scale(elementSize.x, elementSize.y)
        .translate(-offset.x, -offset.y)
        .prepend(first.node.item.transform.world)
        .prepend(_visualServer.viewport.projectionTransform)

    _pane.gradientHandles.appendTansform(T)

    const screenBottomPos = _pane.gradientHandles.transform.xform(_gradient.bottom)
    const worldBottomPos = _visualServer.viewport.toWorld(screenBottomPos)
    const screenCenterPos = _pane.gradientHandles.transform.xform(_gradient.center)
    const worldCenterPos = _visualServer.viewport.toWorld(screenCenterPos)
    const screenLeftPos = _pane.gradientHandles.transform.xform(_gradient.left)
    const worldLeftPos = _visualServer.viewport.toWorld(screenLeftPos)
    gradientScale.x = worldLeftPos.distance_to(worldCenterPos) / (0.5 * elementSize.x)
    gradientScale.y = worldBottomPos.distance_to(worldCenterPos) / (0.5 * elementSize.y)

    const gradientHandlesTransform = _pane.gradientHandles.transform.clone()
    const uiTransform = xform.reset()
        .affine_inverse()
        .prepend(_visualServer.viewport.projectionTransform)
    _pane.gradientHandles.transform.copy(uiTransform)
    _pane.gradientHandlesCover.transform.copy(uiTransform)

    if (_gradient.isLinear) {
        gradientScale.y *= 0.5
        gradientScale.x = gradientScale.y
    }

    if (_gradient.isAngular) {
        _pane.gradientHandles.transform.copy(gradientHandlesTransform)
        const uiScale = uiTransform.get_scale()
        // scaleSign is for fixing the ring vertices order,
        // if ring is flipped, the vertices order will be inside out and some
        // segments will be missing cause the vertices get wrong vertices order
        const gradientTransformData = new Transform2D()
            .set(..._gradient.getGradientTransform()).decompose()
        const scaleSign = gradientTransformData.scale.sign()
        // element rotation add gradient rotation
        const uiRotation = first.node.item.transform.world.get_rotation() - (gradientTransformData.rotation + Math.PI * 0.5)
        // const s = _visualServer.viewport.pixelRatio / _visualServer.viewport.scale

        // const screenPos = gradientHandlesTransform.xform(_gradient.center)
        // const worldPos = _visualServer.viewport.toWorld(screenPos)

        // for large svg image, reference size needs to time 4
        _pane.gradientHandles
            .lineStyle(_gradient.referenceSize , OUTLINE_COLOR)
            .drawEllipseShadow(_gradient.center.x, _gradient.center.y,
                scaleSign.x, scaleSign.y, uiRotation
            )
            .lineStyle(_gradient.referenceSize, OUTLINE_COLOR)
        _pane.gradientHandles.transform.copy(uiTransform)
    } else {
        _pane.gradientHandles
            .lineStyle(_gradient.referenceSize * 2, OUTLINE_COLOR)
            .drawLineShadow(worldCenterPos.x, worldCenterPos.y, worldBottomPos.x, worldBottomPos.y)
            .lineStyle(_gradient.referenceSize, OUTLINE_COLOR)
            .drawLine(worldCenterPos.x, worldCenterPos.y, worldBottomPos.x, worldBottomPos.y)
            .lineStyle(_gradient.referenceSize, OUTLINE_COLOR)
    }

    if (!_gradient.isLinear) drawPosHandle(worldLeftPos, elementSize, gradientScale)
    drawPosHandle(worldBottomPos, elementSize, gradientScale)
    drawPosHandle(worldCenterPos, elementSize, gradientScale)

    const stops = _gradient.getGradientStops()
    const activeIdx = _gradient.activeGradientStopIdx

    _pane.gradientHandles.lineStyle(_gradient.stopOutlineSize, OUTLINE_COLOR)
    const pos = new Vector2()
    for (let i = stops.length - 1; i >= 0; i--) {
        // Skip drawing active gradient stop
        if (i !== activeIdx) _drawGradientStop(stops[i], pos, _gradient.stopSize, gradientHandlesTransform)
    }

    // Draw active gradient stop at the end
    _pane.gradientHandles.lineStyle(_gradient.activeStopOutlineSize, OUTLINE_COLOR)
    if (stops[activeIdx]) {
        _drawGradientStop(stops[activeIdx], pos, _gradient.activeStopSize, gradientHandlesTransform)
    }

    _pane.gradientHandles.resetTransform()
    _pane.gradientHandlesCover.resetTransform()
}

function _preloadSVGFiles() {
    loadImage(Origin_Inactive)
    loadImage(Origin_Enabled)
    loadImage(Origin_Hover)
    loadImage(Ring_Shadow)
    loadImage(Gradient_Stop_Image)
    loadImage(Gradient_Stop_Outline_Shadow)
    loadImage(Gradient_Stop_Center)
    loadImage(Gradient_Handle)
    loadImage(Move)
    loadImage(Move_stroke)
}


/**
 * @param {Vector2} pos
 */
const drawPosHandle = (pos) => {
    const s = 1 / _visualServer.viewport.scale
    const handleSVGSizeRatio = s * _gradient.handleSize / GRADIENT_HANDLE_IMAGE_SIZE

    _pane.gradientHandles
        .fillStyle(OUTLINE_COLOR)
        .drawImageFromAtlas(Gradient_Handle, pos.x, pos.y, handleSVGSizeRatio, handleSVGSizeRatio)
}

/**
 * Draw one gradient stop
 * @param {ColorStop} gradientStop
 * @param {Vector2} pos
 * @param {number} size
 * @param {Transform2D} gradientTransform
 */
function _drawGradientStop(gradientStop, pos, size, gradientTransform) {
    _gradient.getColorStopPos(gradientStop.position, pos)
    const screenPos = gradientTransform.xform(pos)
    const worldPos = _visualServer.viewport.toWorld(screenPos)
    const s = 1 / _visualServer.viewport.scale
    // size + 1 for hiding the aliasing edge of atlas images behind the stop outline
    const centerSizeRatio = s * (size) / GRADIENT_HANDLE_IMAGE_SIZE
    const imageSizeRatio = s * (size) / GRADIENT_STOP_IMAGE_SIZE
    const shadowSizeRatio = s * size / GRADIENT_HANDLE_IMAGE_SIZE
    _pane.gradientHandles
        // draw stop shadow
        .fillStyle(OUTLINE_COLOR)
        .drawImageFromAtlas(Gradient_Stop_Outline_Shadow, worldPos.x, worldPos.y, shadowSizeRatio, shadowSizeRatio)
        // draw stop bottom fill
        .drawImageFromAtlas(Gradient_Stop_Image, worldPos.x, worldPos.y, imageSizeRatio, imageSizeRatio)
        // draw stop top fill
        .fillStyle(rgb2hex(gradientStop.color), gradientStop.color.a)
        .drawImageFromAtlas(Gradient_Stop_Center, worldPos.x, worldPos.y, centerSizeRatio, centerSizeRatio)
    _pane.gradientHandles
        // draw stop outline
        .lineStyle(_gradient.referenceSize, OUTLINE_COLOR)
        .drawEllipseShadow(worldPos.x, worldPos.y, s * size, s * size, true, true)
}

/** @type {import('../actions/text').Text} */
let _text = null

/** @param {import('../actions/text').Text} text */
export function setText(text) {
    _text = text
}

function _drawText() {
    _pane.text.clear().lineStyle(2, 0x0000ee).fillStyle(0x0000ee, 0.1)

    const single = _visualServer.selection.single
    if (
        single === null ||
        _visualServer.dataStore.get('editMode') !== EditMode.TEXT ||
        !_text
    ) return

    const T = xform
        .copy(single.node.item.transform.world)
        .prepend(_visualServer.viewport.projectionTransform)

    _pane.text.appendTansform(T)

    // draw selection
    if (_text.isSelected) {
        const rect = new Rect2()

        let draw = false
        let selecting = false

        for (const glyphLine of _text.glyphLines) {
            for (const glyph of glyphLine.glyphs) {
                let offset0 = 0
                let offset1 = glyph.width

                // check if first
                if (!selecting && _text.isIndexPartOfGlyph(_text.first, glyph)) {
                    selecting = true
                    offset0 = _text.getGlyphOffset(_text.first, glyph)
                }

                if (selecting) {
                    // check if last
                    if (_text.isIndexPartOfGlyph(_text.last, glyph)) {
                        selecting = false
                        offset1 = _text.getGlyphOffset(_text.last, glyph)
                    }

                    if (draw) {
                        rect.expand_to_n(glyph.x + offset0, glyph.top)
                    } else {
                        draw = true
                        rect.set(glyph.x + offset0, glyph.top, 0, 0)
                    }
                    rect.expand_to_n(glyph.x + offset1, glyph.bottom)
                }
            }
            if (draw) {
                draw = false
                _pane.text.drawSolidRect(rect.x, rect.y, rect.width, rect.height)
            }
        }
    }

    // draw caret
    const glyph = _text.getGlyphAt(_text.caret)
    const x = glyph.x + _text.getGlyphOffset(_text.caret, glyph)
    _pane.text.drawLine(x, glyph.top, x, glyph.bottom)

    _pane.text.resetTransform()
}

/**
 * @param {Pane} pane
 * @param {AABB} localAABB
 * @param {AABB} paddingAABB
 * @param {Transform2D} transform
 */
// eslint-disable-next-line no-unused-vars
function _drawPaddingArea(pane, localAABB, paddingAABB, transform) {
    pane.fillStyle(0x00AA00, 0.25)

    const outer = paddingAABB
    const inner = localAABB

    const T = transform.prepend(_visualServer.viewport.projectionTransform)

    pane.appendTansform(T)

    // top padding
    pane.drawSolidRect(outer.left, outer.top, outer.width, inner.top - outer.top)
    // bottom padding
    pane.drawSolidRect(outer.left, inner.bottom, outer.width, outer.bottom - inner.bottom)
    // left padding
    pane.drawSolidRect(outer.left, inner.top, inner.left - outer.left, inner.height)
    // right padding
    pane.drawSolidRect(inner.right, inner.top, outer.right - inner.right, inner.height)

    pane.resetTransform()
}

function _drawHighlightBox() {
    _pane.highlightBbox.clear()

    _drawHoverBox()
    _drawDragOverBox()
}

function _drawHoverBox() {
    _pane.highlightBbox.lineStyle(2, COLOR)

    const selection = _visualServer.dataStore.selection
    let hoveredElement = selection.get('hover')

    if (!hoveredElement || selection.isSelected(hoveredElement)) {
        hoveredElement = selection.get('elements').find((value) => value.get('id') === _hoveredOriginId)
        _pane.highlightBbox.lineStyle(3, COLOR)
    }
    if (!hoveredElement) return

    const hoveredNode = _visualServer.indexer.getNode(hoveredElement.get('id'))
    if (isNull(hoveredNode)) return
    _drawOutline(_pane.highlightBbox, hoveredElement, hoveredNode)
}

function _drawDragOverBox() {
    _pane.highlightBbox.lineStyle(2, COLOR)

    const selection = _visualServer.dataStore.selection
    const element = selection.get('dragOver')
    if (!element) return

    const node = _visualServer.indexer.getNode(element.get('id'))
    if (isNull(node)) return
    _drawOutline(_pane.highlightBbox, element, node)
}

/**
 * @param {Pane} pane
 * @param {Element} element
 * @param {SceneNode} [node]
 */
function _drawOutline(pane, element, node = _visualServer.indexer.getNode(element.get('id'))) {
    if (isNull(node)) return

    const elementType = element.get('elementType')
    if (element.isLineElement()) {
        _drawPath(pane, element, node)
    } else {
        if (node.item.sizeFlag.w === 'f0') {
            const bbox = node.boundsLocalVisualZero.clone()
            bbox.width = 0
            _drawRect(pane, bbox, node.item.transform.world)
        } else if (node.item.sizeFlag.h === 'f0') {
            const bbox = node.boundsLocalVisualZero.clone()
            bbox.height = 0
            _drawRect(pane, bbox, node.item.transform.world)
        } else {
            if (elementType === ElementType.PATH || (element.isContainer && element.isComputedGroup)) {
                _drawPath(pane, element, node)
            } else {
                _drawRect(pane, node.boundsLocalVisualZero, node.item.transform.world)
            }
        }
    }
}

/**
 * @param {Pane} pane
 * @param {Element} element
 * @param {SceneNode} node
 */
function _drawPath(pane, element, node) {
    const T = xform
        .copy(node.item.transform.world)
        .prepend(_visualServer.viewport.projectionTransform)

    let path = null
    if (node.item.isBooleanGroup() || node.item.isMaskGroup()) {
        // TODO: boolean and mask outline should be base, but need to fix base vector first
        const item = node.item
        const vector = item.base.modVector || item.base.vector
        path = vector.path
    } else if (element.get('geometryType') === GeometryType.POLYGON || element.get('geometryType') === GeometryType.LINE) {
        const mesh = element.get('geometry').get('mesh')
        path = meshToPathData(mesh)
    } else {
        const item = node.item
        path = (item.base.mods[1] && item.base.mods[1][2].toPathData()) || item.base.vector.path
    }

    if (path) {
        pathData.commands = path.commands
        pathData.vertices = []
        for (let i = 0; i < path.vertices.length / 2; i++) {
            T.xform(point.set(path.vertices[i * 2], path.vertices[i * 2 + 1]), point2)
            pathData.vertices.push(point2.x, point2.y)
        }
        pane.drawPath(0, 0, pathData)
    }
}

/**
 * @param {Pane} pane
 * @param {Element} element
 * @param {SceneNode} node
 */
function _drawBounds(pane, element, node) {
    let realNode = node
    if (!node && element) {
        realNode = _visualServer.indexer.getNode(element.get('id'))
    }

    // Do not draw bounds rect if not having the real render node
    if (node) {
        const bbox = realNode.boundsLocalVisualZero.clone()
        _drawRect(pane, bbox, realNode.item.transform.world)
    }
}

/**
 * @param {Pane} pane
 * @param {Rect2} rect
 * @param {Transform2D} [transform]
 */
function _drawRect(pane, rect, transform = Transform2D.IDENTITY) {
    const T = xform.copy(transform).prepend(_visualServer.viewport.projectionTransform)
    const tl = T.xform(point.set(rect.x, rect.y))
    const tr = T.xform(point.set(rect.x + rect.width, rect.y))
    const bl = T.xform(point.set(rect.x, rect.y + rect.height))
    const br = T.xform(point.set(rect.x + rect.width, rect.y + rect.height))

    BezierShape.destroy(shape)
    shape.moveTo(tl.x, tl.y)
    shape.lineTo(tr.x, tr.y)
    shape.lineTo(br.x, br.y)
    shape.lineTo(bl.x, bl.y)
    shape.lineTo(tl.x, tl.y)
    shape.close()

    pane.drawPath(0, 0, shape.toPathData())
}

let _area
let _filled

/**
 * draw selection area
 * @param {Rect2} [area]
 * @param {boolean} [filled]
 */
export function setSelectionArea(area, filled = true) {
    _area = area
    _filled = filled
}

export function clearSelectionArea() {
    _area = null
    _filled = null
}

function _drawSelectionArea() {
    if (_visualServer.dataStore.get('state') === 'VERSIONING') return
    _pane.selectionArea
        .clear()
        .lineStyle(1, COLOR)
        .fillStyle(COLOR, 0.25)

    if (_area) {
        const T = xform.reset().prepend(_visualServer.viewport.projectionTransform)
        _pane.selectionArea.appendTansform(T)

        if (_filled) {
            _pane.selectionArea.drawSolidRect(_area.x, _area.y, _area.width, _area.height)
        } else {
            _pane.selectionArea.drawRect(_area.x, _area.y, _area.width, _area.height)
        }

        _pane.selectionArea.resetTransform()
    }
}

let _hoveredOriginId = null

let _draggedElement = false

/**
 *
 * @param {string} hoveredOriginId
 */
export function setHoveredOrigin(hoveredOriginId) {
    _hoveredOriginId = hoveredOriginId
}

/**
 * @param {string} draggedElementId
 */
export function setDraggedElement(draggedElementId) {
    _draggedElement = draggedElementId
}

const usersCursorPosMap = new Map()
const interval = 60

function _updatePresenceCursorPosition() {
    const tabId = window.PresenceManager.tabId
    const usersInCurrentTab = window.PresenceManager.getUsers().filter((user) => user.tabId !== tabId)
    const now = new Date().getTime()
    for (const user of usersInCurrentTab) {
        if (!usersCursorPosMap.has(user.tabId) && user.cursor) { // add cursor
            const pos = _visualServer.viewport.toScreen(new Vector2().copy(user.cursor))
            usersCursorPosMap.set(user.tabId, { pos, prev: new Vector2().copy(user.cursor), curr: new Vector2().copy(user.cursor), timer: now, ratio: 0, done: true })
        } else if (usersCursorPosMap.has(user.tabId) && !user.cursor) { // remove cursor
            usersCursorPosMap.delete(user.tabId)
        } else if (usersCursorPosMap.has(user.tabId) && user.cursor) { // update cursor
            const data = usersCursorPosMap.get(user.tabId)
            const currScreen = _visualServer.viewport.toScreen(data.curr)
            const prevScreen = _visualServer.viewport.toScreen(data.prev)
            // if the pos is in update progress then keeping update
            if (data.prev.equals(data.curr)) {
                data.pos = _visualServer.viewport.toScreen(data.curr)
            } else if (!data.done) {
                const delta = now - data.timer
                data.timer = now
                data.ratio += delta / interval
                data.done = data.ratio >= 1
                const ratio = Math.min(Math.max(data.ratio, 0), 1)
                data.pos.x = (1 - ratio) * prevScreen.x + ratio * currScreen.x
                data.pos.y = (1 - ratio) * prevScreen.y + ratio * currScreen.y
                continue
            }
            data.pos = currScreen
            data.prev = data.curr
            data.timer = now
            data.curr = new Vector2().copy(user.cursor)
            data.ratio = 0
            data.done = false
        }
    }
}

function _drawPresenceCursorNameUI() {
    _updatePresenceCursorPosition()
    const currentState = _visualServer.dataStore.get('state')
    const isVersioningState = currentState === 'VERSIONING'
    const isPresenceEnabled = window.PresenceManager.isPresenceShow

    if (isVersioningState || (!isVersioningState && !isPresenceEnabled)) return

    const currentTabId = window.PresenceManager.tabId
    /** @type {PresenceManager}  */
    const allUsers = window.PresenceManager.getUsers()
    const usersNotInCurrentTab = allUsers
        .filter((user) => user.tabId !== currentTabId)

    if (usersNotInCurrentTab.length === 0) return

    // when the user just open file the user data will not be added into the userList
    if (_shouldSkipDrawingPresenceCursor(currentTabId)) return

    usersNotInCurrentTab.forEach(user => _drawPresenceUserCursor(user))
}

/**
 * @param  {string} tabId
 * @returns {boolean}
 */
function _shouldSkipDrawingPresenceCursor(tabId) {
    return window.PresenceManager.users.has(tabId) && _visualServer.dataStore.get('mode') === Mode.ACTION
}

/**
 * Draws the user's cursor on the screen based on the provided parameters.
 * @param {object} presenceUser
 * @param {object} presenceUser.user
 * @param {object} presenceUser.cursor
 * @param {string} presenceUser.mode
 * @param {string} presenceUser.tabId
 * @param {string} presenceUser.color
 * @param {number} presenceUser.idleTime
 * @param {boolean} presenceUser.isVersioningState
 */
function _drawPresenceUserCursor({ user, cursor, mode, tabId, color, idleTime, isVersioningState }) {
    if (!user || !cursor || mode === Mode.ACTION || idleTime || isVersioningState) return

    const cursorPosition = usersCursorPosMap.get(tabId).pos
    const cursorColor = (parseInt(color.substring(1), 16) << 8) / 256
    _pane.presenceCursor.transform.clone(_pane.selection.transform)
    _drawPresenceCursor(cursorPosition, cursorColor)
    _drawPresenceName(cursorPosition, user.username, cursorColor)
}

function _drawPresenceSelectionUI() {
    const currentState = _visualServer.dataStore.get('state')
    const isVersioningState = currentState === 'VERSIONING'
    const isPresenceEnabled = window.PresenceManager.isPresenceShow

    if (isVersioningState || (!isVersioningState && !isPresenceEnabled)) return

    const currentTabId = window.PresenceManager.tabId
    /** @type {PresenceManager}  */
    const allUsers = window.PresenceManager.getUsers()
    const usersNotInCurrentTab = allUsers
        .filter((user) => user.tabId !== currentTabId)

    if (usersNotInCurrentTab.length === 0) return
    for (let i = 0; i < usersNotInCurrentTab.length; ++i) {
        const user = usersNotInCurrentTab[i]
        if (user.idleTime || !user.selections) continue
        const color = (parseInt(user.color.substr(1), 16) << 8) / 256
        // TODO: when the device pixel ratio is 1 the lineStyle will be thin than the one with the device pixel ratio is 2
        // here do the extra adjustment, will need to check the stroke2 and overlay's difference
        _pane.presenceSelection.lineStyle(_visualServer.viewport.pixelRatio === 1 ? (PRESENCE_CONFIG.selectionWidth + 1) : PRESENCE_CONFIG.selectionWidth, color)
        for (let j = 0; j < user.selections.length; j++) {
            const element = _visualServer.dataStore.getById(user.selections[j])
            const node = _visualServer.indexer.nodeMap.get(user.selections[j])
            if (node) _drawBounds(_pane.presenceSelection, element, node)
        }
    }
}


/**
 * @param {Vector2} pos
 * @param {number} color
 */
const _drawPresenceCursor = (pos, color) => {
    const moveCursorSVGSizeRatio = 1
    _pane.presenceCursor
        .fillStyle(OUTLINE_COLOR)
        .drawImageFromAtlas(Move_stroke, pos.x, pos.y, moveCursorSVGSizeRatio, moveCursorSVGSizeRatio)
    // the stroke SVG and cursor SVG do have the same size, so it needs to add offset on the position
    _pane.presenceCursor
        .fillStyle(color)
        .drawImageFromAtlas(Move, pos.x, pos.y, moveCursorSVGSizeRatio, moveCursorSVGSizeRatio)
}

/**
 * @param {Vector2} pos
 * @param {string} name
 * @param {number} color
 */
const _drawPresenceName = (pos, name, color) => {
    // draw background panel
    const tagOffset = pos.clone().add(PRESENCE_CONFIG.tagOffset)
    const textSize = new Vector2().fromArray(_pane.presenceCursor.measureText(name))
    textSize.x = Math.min(textSize.x, PRESENCE_CONFIG.nameMaxLength)
    const textOffset = tagOffset.clone().add(PRESENCE_CONFIG.tagPadding, (PRESENCE_CONFIG.tagHeight - textSize.y) * 0.5)
    _pane.presenceCursor
        .fillStyle(color)
        .drawSolidRectTexture(
            tagOffset.x, tagOffset.y,
            textSize.x + PRESENCE_CONFIG.tagPadding * 2, PRESENCE_CONFIG.tagHeight,
            PRESENCE_CONFIG.tagCornerRadius)
        .lineStyle(PRESENCE_CONFIG.tagBorderWidth, PRESENCE_CONFIG.tagBorderColor, PRESENCE_CONFIG.tagBorderOpacity)
        .drawRectTexture(
            tagOffset.x, tagOffset.y,
            textSize.x + PRESENCE_CONFIG.tagPadding * 2, PRESENCE_CONFIG.tagHeight,
            PRESENCE_CONFIG.tagBorderWidth, PRESENCE_CONFIG.tagCornerRadius)
    _pane.presenceCursor
        .fillStyle(OUTLINE_COLOR)
        .drawText(
            textOffset.x,
            textOffset.y,
            name,
            0,
            "left",
            "top",
        )
}
