const SCROLLBAR = {
    size: 20,
    padding: 5,
    color: 0x888888,
    colorHover: 0xcccccc,
    borderRadius: 5,
}

/** @typedef {import('../math').Rect2} Rect2 */
/** @typedef {import('../visual_server/VisualServer').VisualServer} VisualServer */
/** @typedef {import('../overlay/Overlay').Pane} Pane */
/** @typedef {import('../Viewport').Viewport} Viewport */
/** @typedef {import('../../../data-utils').AABB} AABB */
/** @typedef {import('../../../data-store').DataStore} DataStore */
/** @typedef {import('../actions/scrollbar').ScrollbarType} ScrollbarType */

/** @type {Pane} */
let _scrollBarPane = null
/** @type {VisualServer} */
let _visualServer = null
/** @type {number} */
let _scrollbarAlpha = 0.0
/** @type {ScrollbarType} */
let _hovering = null
/**
 * The scrollbar on right side has fixed poisition on x-axis and fixed width
 * The scrollbar on the bottom has fixed poisition on y-axis and fixed height
 * @private
 * @type {Rect2}
 * */
let _scrollRect = null

/**
 * Initlize a pane for drawing scroll bar
 * @param {VisualServer} visualServer The VisualServer the grid relys on
 * @param {number} index The index of the pane in the overlay
 * @returns {Pane} Inilized Pane
 */
export function init(visualServer, index) {
    _visualServer = visualServer
    _scrollBarPane = _visualServer.overlay.createPane(index)
    return _scrollBarPane
}

/**
 * Notice UI what type is hovering on scrollbar
 * @param {ScrollbarType} hovering
 */
export function setActiveScrollbar(hovering) {
    _hovering = hovering
}

/**
 * Set rectangle of scrollbar for drawing
 * @param {Rect2} rect
 */
export function setScrollbar( rect ) {
    _scrollRect = rect
}

/**
 * Set alpha of scrollbar for drawing
 * @param {number} alpha
 */
export function setAlpha( alpha ){
    _scrollbarAlpha = alpha
}

/**
 * The tick function of frame update
 */
export function update() {
    _scrollBarPane.clear()

    if ( _scrollRect === null) return
    if ( _scrollbarAlpha < Number.EPSILON ) return
    /** @type {Viewport} */
    const viewport = _visualServer.viewport
    const padding = SCROLLBAR.padding
    // draw the scrollbars
    _drawScrollbar( viewport, padding, _scrollRect.left, _scrollRect.width, _scrollRect.top, _scrollRect.height )
}

/**
 * Draw scrollbar
 * @private
 * @param {Viewport} viewport
 * @param {number} padding
 * @param {number} scrollLeft
 * @param {number} scrollWidth
 * @param {number} scrollTop
 * @param {number} scrollHeight
 */
function _drawScrollbar(viewport, padding, scrollLeft, scrollWidth, scrollTop, scrollHeight) {

    if (Math.round(_scrollRect.right - _scrollRect.left) < viewport.width) {
        _scrollBarPane.fillStyle(_hovering === 'x' ? SCROLLBAR.colorHover : SCROLLBAR.color, _scrollbarAlpha )
            .drawSolidRect(
                scrollLeft,
                viewport.height - SCROLLBAR.size + padding,
                scrollWidth,
                SCROLLBAR.size - padding * 2,
                SCROLLBAR.borderRadius)
    }
    if (Math.round(_scrollRect.bottom - _scrollRect.top) < viewport.height) {
        _scrollBarPane.fillStyle(_hovering === 'y' ? SCROLLBAR.colorHover : SCROLLBAR.color, _scrollbarAlpha )
            .drawSolidRect(
                viewport.width - SCROLLBAR.size + padding,
                scrollTop,
                SCROLLBAR.size - padding * 2,
                scrollHeight,
                SCROLLBAR.borderRadius
            )
    }
}

