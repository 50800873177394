import { useEffect } from 'react'
import PropTypes from 'prop-types'
import googleAnalytics from '../../services/googleAnalytics'
import { useProfile } from '../../providers/ProfileProvider'

const GoogleAnalytics = () => {
  const profile = useProfile()
  const userId = profile ? profile.id : null
  useEffect(() => {
    googleAnalytics('set', { user_id: userId })
  }, [userId])
  return null
}

GoogleAnalytics.propTypes = {
  user: PropTypes.object
}

export default GoogleAnalytics
