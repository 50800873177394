import { PropertyType } from '../constants';
import { KeyFrame } from '../timeline';
import { useRegistry } from '../utils/use-registry';
import { TextDocument } from '../values';
import { Color } from '../values/color';

/**
 * Represents animated properties of layers and shapes.
 */
export class Property {
  public UID = 0;

  public readonly type: PropertyType;

  public expression?: string;

  public isAnimated = false;

  public split = false;

  public index?: number;

  public maxColors?: number;

  public values: Array<KeyFrame> = [];

  public splitValues: { x: Array<KeyFrame>; y: Array<KeyFrame> } = {
    x: [],
    y: [],
  };

  public x: Array<KeyFrame> = [];
  public y: Array<KeyFrame> = [];

  /**
   * Parent instance.
   *
   * @protected
   */
  private _parent: any;

  public getParent(): any {
    return this._parent;
  }

  /**
   * Constructor.
   *
   * @param parent      Parent instance the property belongs to.
   * @param type        Property type.
   */
  constructor(parent: any, type: PropertyType, values: Array<KeyFrame> = []) {
    this._parent = parent;

    this.type = type;
    this.values = values;
    this.x = [];
    this.y = [];
    this.isAnimated = values.length > 1;

    useRegistry().set(this, parent);
  }

  private parseKeyFrameList(list: Array<any>, valueClass: any) {
    let s: any;
    return list.map((v: Record<string, any>, index: number) => {
      const nextV = list[index + 1];
      if (!v.s) {
        v.s = s;
      }
      if (!v.e && nextV?.e) {
        const delta = nextV.s.map((vv: number, ind: number) => {
          return v.s[ind] - vv;
        });
        v.e = v.s;
        delta.forEach((d: number, ind: number) => {
          nextV.s[ind] += d;
          nextV.e[ind] += d;
        });
      }

      const kf = new KeyFrame().fromJSON(v, valueClass);
      s = v.e;
      return kf;
    });
  }

  /**
   * Convert the Lottie JSON object to class instance.
   *
   * @param json    JSON object
   * @returns       ShapeLayer instance
   */
  public fromJSON(json: Record<string, any>): Property {
    // This property
    this.split = 's' in json;
    this.expression = this.split ? undefined : 'x' in json ? json.x : undefined;
    this.index = json.ix;
    this.isAnimated =
      'a' in json ? json.a === 1 : Array.isArray(json.k) && typeof json.k[0] === 'object' && 't' in json.k[0];

    let valueClass: any = undefined;
    if (this.type == PropertyType.COLOR) valueClass = Color;
    else if (this.type == PropertyType.TEXT_DATA) valueClass = TextDocument;

    if (this.type === PropertyType.COLOR) {
      this.maxColors = 'p' in json ? json.p : undefined;

      // this.values.forEach((kf: KeyFrame) => {
      //   const colorParts = kf.value as [number, number, number, number];

      //   kf.value = [colorParts[0], colorParts[1], colorParts[2], colorParts[3] || 1];
      // });
    }

    if (this.split && this.type === PropertyType.POSITION) {
      const { x, y } = json;
      this.x = x.a ? this.parseKeyFrameList(x.k, valueClass) : [new KeyFrame().fromJSON({ t: 0, s: x.k }, valueClass)];
      this.y = y.a ? this.parseKeyFrameList(y.k, valueClass) : [new KeyFrame().fromJSON({ t: 0, s: y.k }, valueClass)];
    } else {
      this.values =
        this.isAnimated || this.type == PropertyType.TEXT_DATA
          ? this.parseKeyFrameList(json.k, valueClass)
          : [new KeyFrame().fromJSON({ t: 0, s: json.k }, valueClass)];
    }

    return this;
  }

  /**
   * Convert the class instance to Lottie JSON object.
   *
   * Called by Javascript when serializing object with JSON.stringify()
   *
   * @returns       JSON object
   */
  public toJSON(): Record<string, any> {
    let value;
    const animated = this.isAnimated || this.values.length > 1 || this.type === PropertyType.TEXT_DATA;
    if (animated) {
      value = this.values;
    } else {
      value = this.values.length ? this.values[0].value : 0;
    }

    if (this.type == PropertyType.POSITION && this.split) {
      return {
        ix: this.index,
        x: {
          a: this.x.length > 1 ? 1 : 0,
          k: this.x.length > 1 ? this.x : this.x[0].value,
        },
        y: {
          a: this.y.length > 1 ? 1 : 0,
          k: this.y.length > 1 ? this.y : this.y[0].value,
        },
        s: this.split,
      };
    } else {
      return {
        x: this.expression,
        ix: this.index,
        a: animated ? 1 : 0,
        k: value,
        p: this.maxColors,
      };
    }
  }
}
