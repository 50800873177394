import { getEventData } from './Event'

export const getTriggerData = (trigger, lazy) => {
  if (lazy) {
    return trigger.get('id')
  }
  const data = trigger.gets('id', 'elementId', 'event')
  data.event = getEventData(data.event)
  return data
}
