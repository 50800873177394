import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'

import { startCase } from 'lodash'

import { EffectType } from '@phase-software/types'

import { EFFECT_PROPS_DISPLAY_NAME_MAP } from '../../../constant'
import Icon from '../Icon'

type EffectItemProps = {
  type: EffectType
  onClick: () => void
}

const preventAndStop = (e: React.KeyboardEvent<HTMLButtonElement>) => {
  e.preventDefault()
  e.stopPropagation()
}

const EffectItem = forwardRef<HTMLButtonElement, EffectItemProps>(({ type, onClick }, ref) => {
  const iconName = startCase(EFFECT_PROPS_DISPLAY_NAME_MAP[type]).replace(/\s*/g, '')
  const { t } = useTranslation('file', { keyPrefix: 'effect_type' })

  const handleClick = () => {
    if (ref && 'current' in ref) {
      ref.current?.blur()
    }
    onClick()
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    switch (e.key) {
      case 'Enter':
      case ' ':
        onClick()
        preventAndStop(e)
        break
      case 'Escape':
        if (ref && 'current' in ref) {
          ref.current?.blur()
          preventAndStop(e)
        }
        break
    }
  }
  const hoverClassName = 'hover:bg-light-overlay-10 hover:outline-light-overlay-20-1-offset--1'
  const activeClassName = 'active:bg-light-overlay-3'
  const focusClassName = 'focus-visible:outline-primary-1-offset--1'
  const disabledClassName = 'disabled:outline-none disabled:cursor-not-allowed'

  return (
    <button
      ref={ref}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      className={`w-full p-8 h-32 rounded-md flex items-center text-12 text-white bg-light-overlay-5 ${focusClassName} ${hoverClassName} ${activeClassName} ${disabledClassName}`}
    >
      <Icon name={iconName} interactive={false} className="mr-8" />
      {t(EFFECT_PROPS_DISPLAY_NAME_MAP[type])}
    </button>
  )
})

EffectItem.displayName = 'EffectItem'

export default EffectItem
