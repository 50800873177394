/** @todo It's critical that we align the calculations in this package with the renderer's transform calculations. Please, let's make this happen! */
import { vec2, vec4, mat2, mat2d } from 'gl-matrix'

const DEGREE = Math.PI / 180
const RAD = 180 / Math.PI

export const EPSILON = 0.0001


export const MAT2D_IDENTITY = mat2d.create()

/**
 * real mod
 * @param {number} a
 * @param {number} b
 * @returns {number}
 */
export function mod(a, b) {
    return ((a % b) + b) % b
}

/**
 * Wraps value between min and max.
 * @param {number} value
 * @param {number} min      inclusive
 * @param {number} max      exclusive
 * @returns {number}
 */
export function wrap(value, min, max) {
    const maxLessMin = max - min
    return ((((value - min) % maxLessMin) + maxLessMin) % maxLessMin) + min
}

/**
 * @param {number} value
 * @param {number} [decimals=0]
 * @returns {number}
 */
export function round(value, decimals = 0) {
    if (decimals < 0) {
        return value
    }
    const power = Math.pow(10, decimals)
    return Math.round(value * power) / power
}

/**
 * Converts degrees to radians
 * @param {number} a  value in degrees
 * @returns {number}  value in radians
 */
export function toRad(a) {
    return a * DEGREE
}

/**
 * Converts radians to degrees
 * @param  {number} a  value in radians
 * @returns {number}   value in degrees
 */
export function toDeg(a) {
    return a * RAD
}

/**
 * Checks if the variable is undefined or null
 * Moved it into a function cause `==` and `===` is easy to accidentally change
 * @param  {*} a        input variable
 * @returns {boolean}   true if null or undefined, false if has value
 */
export function isNull(a) {
    // eslint-disable-next-line eqeqeq
    return a == null
}

/**
 * Opposite of {@link isNull}
 * @param  {*} a   input variable
 * @returns {boolean}   true if has value; false if null or undefined
 */
export function notNull(a) {
    return !isNull(a)
}

/**
 * Checks if variable is a number
 * @param  {*}  a       input variable
 * @returns {boolean}   true if is a number; false otherwise
 */
export function isNum(a) {
    return typeof a === 'number'
}

/**
 * check if variable is a vec2
 * @param  {any}  a   any variable
 * @returns {boolean} true if its a vec2; false otherwise
 */
export function isVec2(a) {
    return a && a.length === 2 && typeof a[0] === 'number' && typeof a[1] === 'number'
}

/**
 * Checks if variable is a vec4
 * @param  {*}  a       input variable
 * @returns {boolean}   true if is a vec4; false otherwise
 */
export function isVec4(a) {
    return (
        a &&
        a.length === 4 &&
        typeof a[0] === 'number' &&
        typeof a[1] === 'number' &&
        typeof a[2] === 'number' &&
        typeof a[3] === 'number'
    )
}

/**
 * check if variable is a mat2d
 * @param  {*}  a    input variable
 * @returns {boolean}   true if it is a mat2d; false otherwise
 */
export function isMat2d(a) {
    return (
        a &&
        a.length === 6 &&
        typeof a[0] === 'number' &&
        typeof a[1] === 'number' &&
        typeof a[2] === 'number' &&
        typeof a[3] === 'number' &&
        typeof a[4] === 'number' &&
        typeof a[5] === 'number'
    )
}

/**
 * Checks if a variable is a string
 * @param {*} a         input variable
 * @returns {boolean}   true if is a string; false otherwise
 */
export function isStr(a) {
    return a && a.constructor === String
}

/**
 * Checks if a variable is an array
 * @param  {*}  a  input variable
 * @returns {boolean}   true if is an array; false otherwise
 */
export function isArr(a) {
    return Array.isArray(a)
}

/**
 * Checks if a variable is an object
 * @param  {*}  a  input variable
 * @returns {boolean}   true if is an object; false otherwise
 */
export function isObj(a) {
    return a && typeof a === 'object' && a.constructor === Object;
}

/**
 * Checks if a variable is a Map
 * @param  {*}  a  input variable
 * @returns {boolean}   true if is a Map; false otherwise
 */
export function isMap(a) {
    return a && a.constructor === Map
}

/**
 * Checks if a variable is a Function
 * @param  {*}  a  input variable
 * @returns {boolean}   true if is a Function; false otherwise
 */
export function isFun(a) {
    return a && a.constructor === Function
}

/**
 * Fills array in place
 * @param {Array} arr
 * @param {any} value
 * @param {number} from
 * @param {number} to
 */
export function fill(arr, value, from=0, to=undefined) {
    const _from = from < 0 || from >= arr.length ? 0 : from
    const _to = to < _from || to >= arr.length ? arr.length - 1 : to
    for (let i = _from; i <= _to; i++) {
        arr[i] = value
    }
}

/**
 * Generator that iterates a list in order specified by start and end.
 * Can iterate in both directions depending on the start and end value (start can be bigger than end).
 * Also allows for negative values indicating the index of the element from the end (last index being -1)
 * @param {Array<T>} list
 * @param {number} [start=0] start index (inclusive). Can be negative indicating
 *                             index of the element from the end (last index being -1)
 * @param {number} [end=-1]  end index (inclusive). Can be negative indicating
 *                             index of the element from the end (last index being -1)
 * @param {number} [step=1]
 * @yields {T}
 */
export function* iterator(list, start = 0, end = -1, step = 1) {
    if (!list.length) {
        return
    }
    const s = start < 0 ? list.length + start : start
    if (s < 0 || s >= list.length) return
    const e = end < 0 ? list.length + end : end
    if (e < 0 || e >= list.length) return

    if (s < e) {
        for (let i = s; i <= e; i+=step) {
            yield list[i]
        }
    } else {
        for (let i = s; i >= e; i-=step) {
            yield list[i]
        }
    }
}

/**
 * Array.filter() like method for any Iterable
 * @param {Iterable<T>} it
 * @param {(element: T, index: number) => bool} filterFn
 * @returns {Array<T>}
 */
export function itFilter(it, filterFn) {
    const newArray = []
    let i = 0
    for (const e of it) {
        if (filterFn(e, i)) {
            newArray.push(e)
        }
        i++
    }
    return newArray
}

/**
 * Array.map() like method for any Iterable
 * @param {Iterable<T>} it
 * @param {(element: T, index: number) => N} mapFn
 * @returns {Array<N>}
 */
export function itMap(it, mapFn) {
    const newArray = []
    let i = 0
    for (const e of it) {
        newArray.push(mapFn(e, i))
        i++
    }
    return newArray
}

/**
 * Filter and map and iterable in a single iteration and with single array allocation
 * @param {Iterable<T>} it
 * @param {(element: T, index: number) => bool} filterFn
 * @param {(element: T, index: number) => N} mapFn
 * @returns {Array<N>}
 */
export function itFilterMap(it, filterFn, mapFn) {
    const newArray = []
    let i = 0
    for (const e of it) {
        if (filterFn(e, i)) {
            newArray.push(mapFn(e, i))
        }
        i++
    }
    return newArray
}

/**
 * Generator that filters an Iterable
 * @param {Iterable<T>} it
 * @param {(item: T, index: number) => bool} filterFn
 * @yields {T}
 */
export function* genItFilter(it, filterFn) {
    let i = 0
    for (const item of it) {
        if (filterFn(item, i)) {
            yield item
        }
        i++
    }
}

/**
 * Generator that maps an Iterable
 * @param {Iterable<T>} it
 * @param {(item: T, index: number) => N} mapFn
 * @yields {N}
 */
export function* genItMap(it, mapFn) {
    let i = 0
    for (const item of it) {
        yield mapFn(item, i)
        i++
    }
}

/**
 * Generator that filters and maps an Iterable
 * @param {Iterable<T>} it
 * @param {(item: T, index: number) => bool} filterFn
 * @param {(item: T, index: number) => N} mapFn
 * @yields {N}
 */
export function* genItFilterMap(it, filterFn, mapFn) {
    let i = 0
    for (const item of it) {
        if (filterFn(item, i)) {
            yield mapFn(item, i)
        }
        i++
    }
}

/**
 * Checks if two arrays are equal, or not
 * @param {[]} a
 * @param {[]} b
 * @param {Function} [cmp]  optional comparator function; by default uses strict equality between array elements (===)
 * @returns {boolean}     true if arrays are equal; false otherwise
 */
export function arrEquals(a, b, cmp = (a, b) => a === b) {
    if (a === b) {
        return true
    }
    if (isNull(a) || isNull(b) || a.length !== b.length) {
        return false
    }
    for (let i = 0; i < a.length; ++i) {
        if (!cmp(a[i], b[i])) {
            return false
        }
    }
    return true
}

/**
 * Check if two objects are equal or not (shallow comparison)
 * @param {object} a
 * @param {object} b
 * @param {Function} [cmp]  optional comparator function; by default uses strict equality between object elements (===) with same key
 * @returns {boolean}   true if equals, false otherwise
 */
export function objEquals(a, b, cmp = (a, b) => a === b) {
    // if one is undefined or null
    if (notNull(a) !== notNull(b)) {
        return false
    }
    for (const key in a) {
        if (!(key in b) || !cmp(a[key], b[key])) {
            return false;
        }
    }
    for (const key in b) {
        if (!(key in a)) {
            return false;
        }
    }
    return true;
}

/**
 * Array.prototype.map() like function for Map type
 * @param {(Map | Array[])} map      Map object or the array of map entries
 * @param {Function} cb      function cb(key, value, map). Shoud return [newKey, newValue]
 * @returns {Map}       new Map object
 */
export function mapMap(map, cb) {
    const newMap = new Map()
    for (const [k, v] of map) {
        newMap.set(...cb(k, v, map))
    }
    return newMap
}

/**
 * Adds list of items to a Set
 * @param {Set} set
 * @param {any[]} list  array
 */
export function setAdd(set, list) {
    for (const item of list) {
        set.add(item)
    }
}

/**
 * Returns the value of the first element in the provided Set that
 *  satisfies the provided testing function.
 * @template T
 * @param {Iterable<T>}   set
 * @param {(item: T, iter: Iterable<T>) => boolean} cb  testing function
 * @returns {undefined | T}  first matching item or undefined if nothing found
 */
export function setFind(set, cb) {
    for (const item of set) {
        if (cb(item, set)) {
            return item
        }
    }
}

/**
 * Checks if two vec2 are equal with optional epsilon
 * Same as {@link vec2.equals} but with optional EPSILON
 * @param {vec2} a          first vec2
 * @param {vec2} b          second vec2
 * @param {number} epsilon  precision
 * @returns {boolean}       true if equal (up to epsilon precision); false otherwise
 */
export function vec2Equals(a, b, epsilon = EPSILON) {
    const a0 = a[0],
        a1 = a[1],
        b0 = b[0],
        b1 = b[1]
    return (
        Math.abs(a0 - b0) <= epsilon * Math.max(1.0, Math.abs(a0), Math.abs(b0)) &&
        Math.abs(a1 - b1) <= epsilon * Math.max(1.0, Math.abs(a1), Math.abs(b1))
    )
}

/**
 * Rounds each component of vec2 to a number decimals
 * @param {vec2} out
 * @param {vec2} a
 * @param {number} decimals
 * @returns {vec2}
 */
export function vec2Round(out, a, decimals) {
    out[0] = round(a[0], decimals)
    out[1] = round(a[1], decimals)
    return out
}

/**
 * Packs flat list of coordinated x,y,x,y... into list of vec2
 * @param  {number[]} coords    list of coordinates
 * @returns {vec2[]}            list of vec2
 */
export function vec2Pack(coords) {
    const packed = []
    for (let i = 0; i < coords.length; i += 2) {
        packed.push(vec2.fromValues(coords[i], coords[i + 1]))
    }
    return packed
}

/**
 * Flattens list of vec2 into a list of interleaved coordinates x,y,x,y...
 * @param  {vec2[]} vs      list of vec2
 * @returns {number[]}      list of coordinates
 */
export function vec2Flatten(vs) {
    return vs.reduce((acc, v) => acc.concat(...v), [])
}

/**
 * simple rotate vector (without origin)
 * @param {Vector2} out
 * @param {Vector2} v
 * @param {number} rotation
 * @returns {Vector2}
 */
export function vec2Rotate(out, v, rotation) {
    if (rotation === 0) {
        return v
    } else {
        const x = v.x
        const y = v.y
        const cos = Math.cos(rotation)
        const sin = Math.sin(rotation)
        out.x = cos * x - sin * y
        out.y = sin * x + cos * y
        return out
    }
}

/**
 * Adds scalar value to each component of the vec2
 * @param  {vec2} out       receiving vector
 * @param  {vec2} a         vector to add delta to
 * @param  {number} scalar   scalar value
 * @returns {vec2}           out
 */
export function vec2AddScalar(out, a, scalar) {
    out[0] = a[0] + scalar
    out[1] = a[1] + scalar
    return out
}

/**
 * Takes absolute value of each component in vec2
 * @param  {vec2} out       receiving vector
 * @param  {vec2} a         vector to take absolute values from
 * @returns {vec2}           out
 */
export function vec2Abs(out, a) {
    out[0] = Math.abs(a[0])
    out[1] = Math.abs(a[1])
    return out
}

/**
 * Takes sign of each component in vec2
 * @param  {vec2} out       receiving vector
 * @param  {vec2} a         vector to take sign from
 * @returns {vec2}           out
 */
export function vec2Sign(out, a) {
    out[0] = Math.sign(a[0])
    out[1] = Math.sign(a[1])
    return out
}

/**
 * Checks if one vec2 is less than distance away from another one
 * @param {vec2} a              first vec2
 * @param {vec2} b              second vec2
 * @param {number} [distance]   0.0001 by default
 * @returns {boolean}           true if close enough; false otherwise
 */
export function vec2CloseTo(a, b, distance = EPSILON) {
    return vec2.squaredDistance(a, b) <= distance * distance
}

/**
 * Performs an inverse of the linear interpolation between two vec2's:
 *  that is fining a t-value (interpolation amount in range [0, 1]) given
 *  an interpolation result
 * @param {vec2} a  the first operand
 * @param {vec2} b  the second operand
 * @param {vec2} x  linear interpolation result
 * @returns {number} t interpolation amount, in the range [0-1]
 */
export function vec2InverseLerp(a, b, x) {
    const ax = a[0]
    return (x[0] - ax) / (b[0] - ax)
}

/**
 * returns the ccw angle between vec `a` and `b`
 * @param {vec2} a
 * @param {vec2} b
 * @returns {number}
 */
export function vec2CCWAngle(a, b) {
    let angle = Math.atan2(a[1], a[0]) - Math.atan2(b[1], b[0])
    if (angle < 0) angle += 2 * Math.PI
    return angle
}

/**
 * Checks if two vec4 are equal with optional epsilon
 * Same as {@link vec4.equals} but with optional EPSILON
 * @param {vec4} a          first vec4
 * @param {vec4} b          second vec4
 * @param {number} epsilon  precision
 * @returns {boolean}       true if equal (up to epsilon precision); false otherwise
 */
export function vec4Equals(a, b, epsilon = EPSILON) {
    const a0 = a[0],
        a1 = a[1],
        a2 = a[2],
        a3 = a[3],
        b0 = b[0],
        b1 = b[1],
        b2 = b[2],
        b3 = b[3]
    return (
        Math.abs(a0 - b0) <= epsilon * Math.max(1.0, Math.abs(a0), Math.abs(b0)) &&
        Math.abs(a1 - b1) <= epsilon * Math.max(1.0, Math.abs(a1), Math.abs(b1)) &&
        Math.abs(a2 - b2) <= epsilon * Math.max(1.0, Math.abs(a2), Math.abs(b2)) &&
        Math.abs(a3 - b3) <= epsilon * Math.max(1.0, Math.abs(a3), Math.abs(b3))
    )
}

/**
 * Checks if one vec4 is less than distance away from another one
 * @param {vec4} a          first vec4
 * @param {vec4} b          second vec4
 * @param {number} distance  0.0001 by default
 * @returns {boolean}       true if close enough; false otherwise
 */
export function vec4CloseTo(a, b, distance = EPSILON) {
    return vec4.squaredDistance(a, b) <= distance * distance
}

/**
 * Checks if two mat2d are equal with optional epsilon
 * Same as {@link mat2d.equals} but with optional EPSILON
 * @param {mat2d} a          first mat2d
 * @param {mat2d} b          second mat2d
 * @param {number} epsilon  precision
 * @returns {boolean}       true if equal (up to epsilon precision); false otherwise
 */
export function mat2dEquals(a, b, epsilon = EPSILON) {
    const a0 = a[0],
        a1 = a[1],
        a2 = a[2],
        a3 = a[3],
        a4 = a[4],
        a5 = a[5],
        b0 = b[0],
        b1 = b[1],
        b2 = b[2],
        b3 = b[3],
        b4 = b[4],
        b5 = b[5]
    return (
        Math.abs(a0 - b0) <= epsilon * Math.max(1.0, Math.abs(a0), Math.abs(b0)) &&
        Math.abs(a1 - b1) <= epsilon * Math.max(1.0, Math.abs(a1), Math.abs(b1)) &&
        Math.abs(a2 - b2) <= epsilon * Math.max(1.0, Math.abs(a2), Math.abs(b2)) &&
        Math.abs(a3 - b3) <= epsilon * Math.max(1.0, Math.abs(a3), Math.abs(b3)) &&
        Math.abs(a4 - b4) <= epsilon * Math.max(1.0, Math.abs(a4), Math.abs(b4)) &&
        Math.abs(a5 - b5) <= epsilon * Math.max(1.0, Math.abs(a5), Math.abs(b5))
    )
}

/**
 * Skews a mat2d by the dimensions in the given vec2
 * @param  {mat2d} out  the receiving matrix
 * @param  {mat2d} a    matrix to skew
 * @param  {vec2} skew  the vec2 to skew the matrix by
 * @returns {mat2d}      out
 */
export function mat2dSkew(out, a, skew) {
    const x = Math.tan(skew[0]),
        y = Math.tan(skew[1])
    out[0] = a[0]
    out[1] = a[1] + x
    out[2] = a[2] + y
    out[3] = a[3]
    out[4] = a[4]
    out[5] = a[5]
    return out
}

/**
 * Creates mat2d from two-row (two-dimensional array) form
 * @param  {number[][]} a    two-dimensional array
 *                             [
 *                               [a, c, tx]
 *                               [b, d, tx]
 *                             ]
 *                             see http://glmatrix.net/docs/module-mat2d.html
 * @returns {mat2d}          a new mat2d
 */
export function mat2dFromTwoRow(a) {
    return mat2d.fromValues(a[0][0], a[1][0], a[0][1], a[1][1], a[0][2], a[1][2])
}

/**
 * Create mat2d from position, rotation, scale and skew
 * @param   {number[]} out               mat2d to write result into
 * @param   {object}   options
 * @param   {number[]} options.position  vec2
 * @param   {number}   options.rotation
 * @param   {number[]} options.scale     vec2
 * @param   {number[]} options.skew      vec2
 * @returns {number[]}                   out
 */
export function mat2dFrom(out, {
    position = [0, 0],
    rotation = 0,
    scale = [1, 1],
    skew = [0, 0]
} = {}) {
    if (!position || rotation === undefined || !scale || !skew) {
        return mat2d.set(out, 1, 0, 0, 1, 0, 0)
    }
    out[0] = Math.cos(rotation + skew[1]) * scale[0]
    out[1] = Math.sin(rotation + skew[1]) * scale[0]
    out[2] = -Math.sin(rotation - skew[0]) * scale[1]
    out[3] = Math.cos(rotation - skew[0]) * scale[1]
    out[4] = position[0]
    out[5] = position[1]
    return out
}

/**
 * Creates the basis transform matrix (leaving out translation)
 * @param  {mat2d} out  the receiving matrix
 * @param  {mat2d} a    original matrix
 * @returns {mat2d}     out
 */
export function mat2dBasis(out, a) {
    out[0] = a[0]
    out[1] = a[1]
    out[2] = a[2]
    out[3] = a[3]
    out[4] = 0
    out[5] = 0
    return out
}

/** typedef {{ position: vec2, rotation: number, scale: vec2, skew: vec2 }} TransformComponents */

/**
 * Decomposes mat2d transform into transform components (optionaly considering the pivot offset)
 * @param  {mat2d} t                    input transform
 * @param  {vec2} pivot                 pivot offset (in object space)
 * @returns {TransformComponents}       object of transform components (position, rotation, scale, skew )
 */
export function decomposeTransform(t, pivot = [0, 0]) {
    const a = t[0], b = t[1], c = t[2], d = t[3], tx = t[4], ty = t[5]

    const delta = a * d - b * c

    // const position = [tx, ty],
    const scale = [1, 1],
        skew = [0, 0]
    let rotation = 0

    if (a !== 0 || b !== 0) {
        const r = Math.sqrt(a * a + b * b)
        rotation = b > 0 ? Math.acos(a / r) : -Math.acos(a / r)
        vec2.set(scale, r, delta / r)
        vec2.set(skew, Math.atan((a * c + b * d) / (r * r)), 0)
    } else if (c !== 0 || d !== 0) {
        const s = Math.sqrt(c * c + d * d)
        rotation = Math.PI / 2 - (d > 0 ? Math.acos(-c / s) : -Math.acos(c / s))
        vec2.set(scale, delta / s, s)
        vec2.set(skew, 0, Math.atan((a * c + b * d) / (s * s)))
    } else {
        // a = b = c = d = 0
    }

    const position = [
        tx + pivot[0] * a + pivot[1] * c,
        ty + pivot[0] * b + pivot[1] * d
    ]

    return { position, rotation, scale, skew }
}

/**
 * Decomposes mat2d transform and returns rotation component
 * @param  {mat2d} t    input transform
 * @returns {number}    rotation component of the transform
 */
export function decomposeTransformToRotation(t) {
    const a = t[0], b = t[1], c = t[2], d = t[3]

    let rotation = 0
    if (a !== 0 || b !== 0) {
        const r = Math.sqrt(a * a + b * b)
        rotation = b > 0 ? Math.acos(a / r) : -Math.acos(a / r)
    } else if (c !== 0 || d !== 0) {
        const s = Math.sqrt(c * c + d * d)
        rotation = Math.PI / 2 - (d > 0 ? Math.acos(-c / s) : -Math.acos(c / s))
    }
    return rotation
}

/**
 * Get the value between minimum and maximum
 * @function minmax
 * @param      {number}  value                            The value
 * @param      {number}  [min=Number.MIN_SAFE_INTEGER]  The minimum
 * @param      {number}  [max=Number.MAX_SAFE_INTEGER]  The maximum
 * @returns     {number}
 */
export const minmax = (
    value,
    min = Number.MIN_SAFE_INTEGER,
    max = Number.MAX_SAFE_INTEGER
) => {
     return Math.max(Math.min(value, max), min)
}

/**
 * Get number between two value with percentage
 * @param {number} left
 * @param {number} right
 * @param {number} perc - 0 to 1
 * @returns {number}
 */
export const lerp = (left, right, perc) => {
  return left + (right - left) * perc
}

/**
 * 
 * @param {mat2} transform 
 * @param {vec2} skew 
 * @param {vec2} scale 
 * @param {number} rotation 
 * @returns {mat2}
 */
export function makeTransform(transform, skew, scale, rotation) {
    mat2.identity(transform)
    const tX = Math.tan(skew[1])
    const tY = Math.tan(skew[0])
    transform[2] -= tX
    transform[1] -= tY
    mat2.scale(transform, transform, scale)
    mat2.rotate(transform, transform, rotation)
    const tmp = transform[1]
    transform[1] = -transform[2]
    transform[2] = -tmp
    return transform
}

/**
 * 
 * @param {vec2} positon 
 * @param {vec2} origin 
 * @param {mat2} transform 
 * @returns {mat2}
 */
export function transformPosition(positon , origin, transform){
    vec2.copy(positon, origin)
    vec2.scale(positon, positon, -1)
    vec2.transformMat2(positon, positon, transform)
    vec2.add(positon, positon, origin)
    return positon
}
