import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { FrameType } from '@phase-software/types'

import { useModal, useSetModal } from '../../providers/ModalProvider'
import { useDataStoreActions } from '../../providers/dataStore/DataStoreProvider'
import { useInteractionActions } from '../../providers/dataStore/InteractionProvider'
import { useKeyframeSelection } from '../../providers/dataStore/KeyframeSelectionProvider'
import { ContextMenu } from '../shared'
import { isSeparatorOption, removeConsecutiveSeparators, translateMenuOptions } from '../shared/Menu/utils'

const KEY = 'KeyframeContextMenu'

const VALUES = {
  CUT_KEYFRAME: 'CUT_KEYFRAME',
  COPY_KEYFRAME: 'COPY_KEYFRAME',
  DUPLICATE_KEYFRAME: 'DUPLICATE_KEYFRAME',
  EXPLICIT_KEYFRAME: 'EXPLICIT_KEYFRAME',
  INITIAL_KEYFRAME: 'INITIAL_KEYFRAME',
  DELETE_KEYFRAME: 'DELETE_KEYFRAME'
}

const getOptions = (keyFrameList) => {
  const isSomeExplicit = keyFrameList.some((kf) => kf.frameType === FrameType.EXPLICIT)

  const options = [
    {
      name: 'cut',
      value: VALUES.CUT_KEYFRAME,
      show: true,
      shortcut: '⌘X'
    },
    {
      name: 'copy',
      value: VALUES.COPY_KEYFRAME,
      show: true,
      shortcut: '⌘C'
    },
    {
      name: 'duplicate_to_current_time',
      value: VALUES.DUPLICATE_KEYFRAME,
      show: true,
      shortcut: '⌘D'
    },
    '-',
    {
      name: 'reset_to_initial',
      value: VALUES.INITIAL_KEYFRAME,
      show: isSomeExplicit
    },
    {
      name: 'delete',
      value: VALUES.DELETE_KEYFRAME,
      show: true
    }
  ]
  return removeConsecutiveSeparators(options.filter((option) => isSeparatorOption(option) || option.show))
}

const KeyframeContextMenu = () => {
  const {
    open,
    data: { trigger }
  } = useModal((o) => o[KEY])
  const keyframeSelection = useKeyframeSelection()
  const { selectKeyframeSelection, commitUndo, copyKeyframes, cutKeyframes } = useDataStoreActions()
  const { getKeyFrame, setKeyFrameFrameType, duplicateSelectedKeyFrame, deleteSelectedKeyFrame } =
    useInteractionActions()
  const { t } = useTranslation('file')

  // Keyframe context menu will be opened with
  //    1. Explicit keyframe
  //    2. Initial keyframe
  //    3. Aggregate keyframe
  //    4. Multi keyframe selection

  // Property Panel can only change keyframe type.
  // So, it will pass keyframe id to context menu.
  // But Action Panel will manipulate with keyframe selection.
  // So, we need to EDIT/DUPLICATE/DELETE keyframes with keyframe selection.
  const { closeModal } = useSetModal(KEY)

  // Need to filter out undefined kfs here because it will get undefined while undo delete keyframe.
  const opts = useMemo(() => {
    const options = getOptions(keyframeSelection.map((id) => getKeyFrame(id)).filter((kf) => kf))
    return translateMenuOptions(t, options, { ns: 'file', keyPrefix: 'menu' })
  }, [t, getKeyFrame, keyframeSelection])

  const handleSelect = (op) => {
    switch (op.value) {
      case VALUES.CUT_KEYFRAME: {
        cutKeyframes()
        break
      }
      case VALUES.COPY_KEYFRAME: {
        copyKeyframes()
        break
      }
      case VALUES.DUPLICATE_KEYFRAME: {
        // Duplicate keyframes by playhead position
        const newKFs = duplicateSelectedKeyFrame()

        if (newKFs.length) {
          // Add duplicated keyframes to the keyframe selection
          selectKeyframeSelection(newKFs)
          commitUndo()
        }
        break
      }
      case VALUES.EXPLICIT_KEYFRAME:
        // Change keyframes to EXPLICIT frame type
        keyframeSelection.forEach((id) => {
          setKeyFrameFrameType(id, FrameType.EXPLICIT)
        })
        commitUndo()
        break
      case VALUES.INITIAL_KEYFRAME:
        // Change keyframes to INITIAL frame type
        keyframeSelection.forEach((id) => {
          setKeyFrameFrameType(id, FrameType.INITIAL)
        })
        commitUndo()
        break
      case VALUES.DELETE_KEYFRAME:
        // Delete keyframes (will also deselect and commit undo)
        deleteSelectedKeyFrame()
        break
      default:
        break
    }
  }

  return (
    <ContextMenu
      open={open}
      options={opts}
      onSelect={handleSelect}
      onClose={closeModal}
      trigger={trigger}
      offsetY={0}
    />
  )
}

export default React.memo(KeyframeContextMenu)
