import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import { useApolloClient } from '@apollo/client'
import { WorkspaceType } from '@phase-software/types'

import { TEAM_ROLE_OPTIONS } from '../../constants/teamConstants'
import { CreateTeamInvitationRequestTeamRoleEnum } from '../../generated/api'
import { GetTeamByIdDocument } from '../../generated/graphql'
import useTeamActions from '../../hooks/useTeamActions'
import useTeamMemberActions from '../../hooks/useTeamMemberActions'
import { useProfile } from '../../providers/ProfileProvider'
import { addUserProperties, track } from '../../services/heapAnalytics'
import { ProfileWorkspaceListType } from '../../types/User'
import { getWorkspaceIndexPath } from '../../utils/pathGenerators'
import { isValidEmail, teamNameValidator } from '../../utils/validator'
import { FullScreenDialog, InputField, Select, TextArea } from '../shared'
import { InputFieldRefProps } from '../shared/InputField'
import { translateMenuOptions } from '../shared/Menu/utils'
import { TextAreaTagItemProps } from '../shared/TextArea'

type CreateTeamDialogProps = {
  open: boolean
  onClose: () => void
}

const CreateTeamDialog = ({ open, onClose }: CreateTeamDialogProps) => {
  const { t } = useTranslation(['workspace', 'common', 'setting'])
  const user = useProfile()
  const client = useApolloClient()
  const history = useHistory()

  const { createTeam } = useTeamActions()
  const { inviteTeamMember } = useTeamMemberActions()

  const inputRef = useRef<InputFieldRefProps>(null)

  const [step, setStep] = useState(1)
  const isCreateTeamStep = step === 1

  const [isProcessing, setIsProcessing] = useState(false)

  const [name, setName] = useState('')

  const [teamSlug, setTeamSlug] = useState('')
  const [teamId, setTeamId] = useState('')
  const [invitedMemberRole, setInvitedMemberRole] = useState<CreateTeamInvitationRequestTeamRoleEnum>('MEMBER')
  const [invitedMembers, setInvitedMembers] = useState<TextAreaTagItemProps[]>([])
  const [invitedMembersError, setInvitedMembersError] = useState('')

  const isConfirmButtonDisabled = isProcessing || (isCreateTeamStep && !!teamNameValidator(name, t))

  const translatedTeamRoleOptions = useMemo(
    () =>
      translateMenuOptions(t, TEAM_ROLE_OPTIONS, {
        ns: 'setting',
        keyPrefix: 'team_setting'
      }),
    [t]
  )

  useEffect(() => {
    if (open && inputRef.current) {
      inputRef.current.focus()
    }
  }, [open])

  const handleChangeName = (newName: string) => {
    setName(newName)
  }
  const validateInvitedMember = ({ value: email }: TextAreaTagItemProps) => {
    return isValidEmail(email) && email !== user.email
  }

  const validateEmailFormat = ({ value: email }: TextAreaTagItemProps) => {
    // The reason for not using validateInvitedMember is because the two scenarios require different error messages.
    return isValidEmail(email)
  }

  const validateAsUserEmail = ({ value: email }: TextAreaTagItemProps) => email === user.email

  const checkMemberEmailsValid = () => {
    if (invitedMembers.length === 0) {
      setInvitedMembersError(t('please_enter_at_least_one_email'))
      return false
    }

    if (!invitedMembers.every(validateEmailFormat)) {
      setInvitedMembersError(t('some_emails_are_invalid'))
      return false
    }

    if (invitedMembers.some(validateAsUserEmail)) {
      setInvitedMembersError(t('users_already_a_team_member_or_invited'))
      return false
    }

    setInvitedMembersError('')
    return true
  }
  const handleConfirm = async () => {
    if (isConfirmButtonDisabled) return

    try {
      if (isCreateTeamStep) {
        await handleCreateTeam()

        const teamLength = user?.workspaceList?.length
          ? (user?.workspaceList.filter((ws: ProfileWorkspaceListType) => ws.description !== 'Personal')).length
          : 0
        const createdTeamCount = user?.workspaceList?.length
          ? user?.workspaceList.reduce((acc: number, cur: ProfileWorkspaceListType) => {
              if (cur.description !== 'Personal' && cur?.ownerId === user.id) {
                acc++
              }
              return acc
            }, 0)
          : 0
        addUserProperties({
          teamsCount: teamLength + 1, // the total number of teams
          [`teamName${teamLength}`]: name.trim()
        })
        track('New Team Created', {
          teamCount: createdTeamCount + 1, // the number of created team
          teamName: name.trim()
        })
      } else {
        await handleInviteTeamMember()
      }
    } catch (error) {
      console.log('[CreateTeamDialog] handleConfirm :>> ', error)
    }
  }

  const handleCreateTeam = async () => {
    try {
      setIsProcessing(true)
      const response = await createTeam(name.trim())
      const { data } = await client.query({
        query: GetTeamByIdDocument,
        variables: { id: response.id }
      })
      setTeamId(response.id)
      setTeamSlug(data.teams[0].custom_slug)
      setStep(2)
    } catch (error) {
      console.error(error)
    } finally {
      setIsProcessing(false)
    }
  }

  const handleInviteTeamMember = async () => {
    if (!teamId || !checkMemberEmailsValid()) return

    try {
      setIsProcessing(true)
      const inviteMemberRequests = invitedMembers.map((email) =>
        inviteTeamMember({ teamId, email: email.value, teamRole: invitedMemberRole })
      )

      await Promise.all(inviteMemberRequests)
      invitedMembers.forEach(() => track('Invitation To Team Sent', { teamName: name, userRole: invitedMemberRole }))
      navigateToTeamPage()
    } catch (error) {
      console.error(error)
    } finally {
      setIsProcessing(false)
    }
  }

  const navigateToTeamPage = () => {
    handleClose()
    history.push(getWorkspaceIndexPath(WorkspaceType.TEAM, teamSlug))
  }
  const handleClose = () => {
    setStep(1)
    setName('')
    onClose()
  }

  return open ? (
    <FullScreenDialog
      confirmText={isCreateTeamStep ? t('teams.create') : t('teams.send_invitations')}
      altActionText={isCreateTeamStep ? '' : t('teams.skip')}
      disableConfirm={isConfirmButtonDisabled}
      onClose={onClose}
      onConfirm={handleConfirm}
      onAltAction={navigateToTeamPage}
      open={open}
      progress={Number(isProcessing)}
      title={isCreateTeamStep ? t('teams.name_your_team') : t('teams.invite_your_team_members')}
    >
      {isCreateTeamStep ? (
        <InputField
          ref={inputRef}
          type="text"
          value={name}
          placeholder={t('teams.use_your_company_name_placeholder')}
          validator={(value) => teamNameValidator(value, t)}
          onInput={handleChangeName}
          onEnterKey={handleConfirm}
          labelClassName="mb-0"
        />
      ) : (
        <TextArea
          allowTags
          defaultTags={invitedMembers}
          error={invitedMembersError}
          onChangeTags={setInvitedMembers}
          placeholder={t('teams.enter_emails_separated_by_coma')}
          customTagValidator={validateInvitedMember}
          rightComponent={
            <Select
              // @ts-ignore TODO: fix after refactor of Select
              focusOnSelect={false}
              size="l"
              value={invitedMemberRole}
              onChange={setInvitedMemberRole}
              options={translatedTeamRoleOptions}
            />
          }
        />
      )}
    </FullScreenDialog>
  ) : null
}

export default CreateTeamDialog
