import React, { HTMLAttributes, useState, useEffect } from 'react'
interface OptionButtonListProps extends HTMLAttributes<HTMLDivElement> {
  className?: string
  radioDisabled?: boolean
  type: 'checkbox' | 'radio'
  dataTestId?: string
}

const OptionButtonList = ({
  className = '',
  children,
  type,
  radioDisabled,
  dataTestId,
  ...rest
}: OptionButtonListProps) => {
  const [animationPosition, setAnimationPosition] = useState(0)
  const [radioButtonChecked, setRadioButtonChecked] = useState(false)
  const [isAnimate, setIsAnimate] = useState(false)

  const radioButtonCheckedStyle = radioButtonChecked ? 'bg-light-overlay-10' : 'transparent'
  const radioButtonCheckedStyleAnimation = isAnimate ? 'transition-all ease-in-out duration-300' : ''
  const radioDisabledStyle = radioDisabled ? 'opacity-40 text-opacity-40' : ''
  const highlightClassName = type === 'radio' ? 'highlight-border-rounded-lg' : ''
  const radioBtnBackground = type === 'radio' ? 'bg-light-overlay-10' : ''

  const addAnimationHandler = () => {
    if (type === 'radio' && !isAnimate && animationPosition !== -1) {
      setIsAnimate(true)
    }
  }
  const removeAnimationHandler = () => {
    if (type === 'radio' && isAnimate) setIsAnimate(false)
  }

  let animationWidth = 0
  if (Array.isArray(children)) {
    animationWidth = children.length
  }

  useEffect(() => {
    if (type === 'radio' && Array.isArray(children)) {
      const current = children?.findIndex((child: any) => child.props.checked)
      setAnimationPosition(current)
      setRadioButtonChecked(children?.some((child: any) => child.props.checked))
    }
  }, [children, type])

  return (
    <div
      {...rest}
      className={`${className} ${highlightClassName} ${radioBtnBackground} ${radioDisabledStyle} grid grid-flow-col auto-cols-fr rounded-md relative`}
      onClick={addAnimationHandler}
      onTransitionEnd={removeAnimationHandler}
      data-test-id={dataTestId}
    >
      {children}
      {type === 'radio' && (
        <>
          <div
            className={`${radioButtonCheckedStyle} ${radioButtonCheckedStyleAnimation} rounded-md absolute h-full pointer-events-none`}
            style={{
              transform: `translate(${animationPosition * 100}%, 0)`,
              width: `calc(${100 / animationWidth}%)`
            }}
            onTransitionEnd={removeAnimationHandler}
          ></div>
        </>
      )}
    </div>
  )
}

export default OptionButtonList
