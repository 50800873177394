import PropertyStack from './PropertyStack'

class OpacityStack extends PropertyStack {
  constructor(elementStack, data) {
    super(elementStack, data)
    this.type = 'OPACITY'
    this.key = 'opacity'
    this.dataKey = 'opacity'
    this.animatableProperties = new Set(['opacity'])
  }

  /**
   * Get default value from base for default keyframe
   * @returns {object}
   */
  getInitValue() {
    const parent = this.manager.cache.getById(this.parentId)
    if (parent && parent.isRepeatableType) {
      return parent.getInitValue().opacity
    }
    return super.getInitValue()
  }
}

export default OpacityStack
