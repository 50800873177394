import React from 'react'
import { ElementType, EditMode } from '@phase-software/types'
import { useEditor } from '../../../../providers/dataStore/EditorProvider'
import { useUI } from '../../../../providers/dataStore/UIProvider'
import Dimensions from './Dimensions'
import Rotation from './Rotation'
import Position from './Position'

const LayoutEditor = () => {
  const elementType = useEditor((o) => o.elementType)
  const { editMode } = useUI()
  const isScreen = elementType === ElementType.SCREEN
  const isShapeMode = editMode === EditMode.SHAPE
  const isPathEditing = editMode === ElementType.PATH

  // TODO: Path mode need to display corner radius when ready
  return (
    <>
      {!isScreen && <Position isShapeMode={isShapeMode} isPathEditing={isPathEditing} enableKF={!isShapeMode} />}
      {!isShapeMode && <Dimensions isScreen={isScreen} />}
      {!isScreen && !isShapeMode && <Rotation isShapeMode={isShapeMode} />}
    </>
  )
}

export default React.memo(LayoutEditor)
