import { useRef } from 'react'
import { useScrollContext } from '.'

export const useScrollIntoView = () => {
  const shouldScroll = useRef(false)
  const { handleScroll } = useScrollContext()

  const activateScroll = () => {
    shouldScroll.current = true
  }

  const handleAutoScroll = (targetElement: Element | undefined | null) => {
    if (!targetElement) return
    if (shouldScroll.current) {
      handleScroll(targetElement)
      shouldScroll.current = false
    }
  }

  return { activateScroll, handleAutoScroll, shouldScroll }
}
