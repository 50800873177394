import { ToolType } from '@phase-software/types'
import { createMachine } from 'xstate/lib/Machine'
import { pathStates } from './pathStates'

/**
 * @typedef {object} StateData
 * @property {string} name
 * @property {number} level
 */

// can view it using https://xstate.js.org/viz
export const machine = createMachine({
    id: 'mainMachine',
    initial: 'idle',
    predictableActionArguments: true,
    states: {
        idle: {
            on: {
                ENTER_PREVIEW: 'preview',
                ENTER_EDITOR: 'workspace'
            }
        },
        preview: {
            on: { ENTER_EDITOR: 'workspace' }
        },
        workspace: {
            on: {
                ENTER_PREVIEW: 'preview',
                SELECTION: [
                    {
                        target: '.idle',
                        cond: 'noSelection'
                    },
                    {
                        target: '.selection.locked',
                        cond: 'allLocked'
                    },
                    {
                        target: '.selection.setOrigin',
                        cond: 'activeSetOrigin'
                    },
                    {
                        target: '.selection.single',
                        cond: 'isSingleSelection'
                    },
                    { target: '.selection.multiple' }
                ],
                ENTER_CREATE_ELEMENT_MODE: '.createElement'
            },
            initial: 'idle',
            states: {
                idle: {
                    on: {
                        ENTER_CREATE_ELEMENT_MODE: 'createElement'
                    }
                },
                createElement: {
                    on: {
                        EXIT_CREATE_ELEMENT_MODE: '#mainMachine.workspace'
                    }
                },
                selection: {
                    initial: 'single',
                    states: {
                        single: {
                            initial: 'handles',
                            states: {
                                handles: {
                                    on: {
                                        EDIT_TEXT: 'edit.text',
                                    }
                                },
                                edit: {
                                    states: {
                                        text: {},
                                        gradient: {},
                                        image: {},
                                        shape: {
                                            ...pathStates
                                        },
                                    },
                                    on: { EXIT: 'handles' }
                                }
                            },
                            on: {
                                EDIT_GRADIENT: '.edit.gradient',
                                EDIT_IMAGE: '.edit.image',
                                EDIT_SHAPE: '.edit.shape',
                                ACTIVATE_SET_ORIGIN: 'setOrigin'
                            }
                        },
                        multiple: {
                            initial: 'handles',
                            states: {
                                handles: {}
                            },
                            on: {
                                ACTIVATE_SET_ORIGIN: 'setOrigin'
                            }
                        },
                        locked: {},
                        setOrigin: {
                            entry: 'entrySetOrigin',
                            exit: 'exitSetOrigin',
                            on: {
                                ACTIVATE_SELECT: [
                                    { target: 'single', cond: 'isSingleSelection' },
                                    { target: 'multiple' }
                                ]
                            }
                        },
                    }
                }
            }
        }
    }
}, {
    actions:{
        entryShapeState:()=> {/* console.log('entryPathEditing') */},
        exitShapeState:()=> {/* console.log('exitPathEditing') */},
        entrySetOrigin:()=> {/* console.log('entrySetOrigin') */},
        exitSetOrigin:()=> {/* console.log('exitSetOrigin') */},
    },
    guards: {
        noSelection: (context, event) => event.nrOfSelectedElements === 0,
        allLocked: (context, event) => event.allLocked,
        isSingleSelection: (context, event) => event.nrOfSelectedElements === 1,
        activeSetOrigin: (context, event) => event.activeTool === ToolType.ORIGIN,
    }
})

/** @type {Record<string, number>} */
const levels = machine.stateIds
    .reduce((out, id) => {
        const path = id.split(machine.delimiter)
        const key = path[path.length - 1]
        if (!out[key]) out[key] = path.length
        return out
    }, {})

export const STATES = {
    WORKSPACE:          { name: 'workspace',        level: levels.workspace },
    PREVIEW:            { name: 'preview',          level: levels.preview },
    SELECTION:          { name: 'selection',        level: levels.selection },
    HANDLES:            { name: 'handles',          level: levels.handles },
    TEXT:               { name: 'text',             level: levels.text },
    GRADIENT:           { name: 'gradient',         level: levels.gradient },
    SHAPE:              { name: 'shape',            level: levels.shape},
    MOVE_TOOL:          { name: 'move',             level: levels.move },
    MOVE_TOOL_SELECTED: { name: 'moveSelectedCell', level: levels.moveSelectedCell },
    PEN_TOOL:           { name: 'pen',              level: levels.pen },
    PEN_TOOL_SELECTED:  { name: 'penSelectedCell',  level: levels.penSelectedCell },
    SET_ORIGIN:         { name: 'setOrigin',        level: levels.setOrigin },
    CREATE_ELEMENT:     { name: 'createElement',    level: levels.createElement }
}
