import React, { useEffect } from 'react'
import { Redirect, Switch, useLocation } from 'react-router'

import { PERMISSIONS, ProtectedRoute } from '../../access-control'
import { FileFieldsFragment } from '../../generated/graphql'

import FileEditor from './FileEditor'
import FileViewer from './FileViewer'

import { FILE_EDIT_MODE, FILE_VIEW_MODE } from '../../routeConstants'

import { useWorkspaceContext } from '../../providers/WorkspaceContextProvider'
import { useFilePermissionContext } from '../../providers/FilePermissionProvider'
import { getFilePathByMode } from '../../utils/pathGenerators'
import { FileVersionContextProvider } from '../../providers/FileVersionContextProvider'
import useHeapAnalytics from '../../hooks/useHeapAnalytics'
import { track } from '../../services/heapAnalytics'

type FileRoutesProps = {
  fileId: FileFieldsFragment['id']
  fileContent: object
  projectId: FileFieldsFragment['id']
}
const FileModeRoutes = ({ fileId, fileContent, projectId }: FileRoutesProps) => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const source = searchParams.get('source')

  const { workspaceData } = useWorkspaceContext()
  const { fetchPermissions, fileWithPermission, permissionsLoaded, canEditFile } = useFilePermissionContext()
  const { space, teamName } = useHeapAnalytics()
  const analyticLocation = projectId === workspaceData.draftProjectId ? 'drafts' : 'project'

  useEffect(() => {
    fetchPermissions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (source === 'join') {
      const accessType = canEditFile ? 'edit' : 'view'
      track('File Invitation Link Opened', {
        projectId,
        fileId,
        accessType,
        space,
        teamName,
        location: analyticLocation
      })
    }
  }, [source, canEditFile, fileId, location, projectId, space, teamName, analyticLocation])

  if (!permissionsLoaded) {
    return null
  }

  return (
    <FileVersionContextProvider
      fileContent={fileContent}
      fileId={fileId}
      projectId={projectId}
      canEditFile={canEditFile}
    >
      <Switch>
        <ProtectedRoute
          exact
          path={FILE_EDIT_MODE}
          perform={PERMISSIONS.EDIT_FILE}
          data={fileWithPermission}
          unauthorizedUrl={getFilePathByMode(fileId, 'view', searchParams)}
        >
          <FileEditor workspaceData={workspaceData} fileId={fileId} projectId={projectId} />
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path={FILE_VIEW_MODE}
          perform={PERMISSIONS.VIEW_FILE}
          data={fileWithPermission}
          unauthorizedUrl={getFilePathByMode(fileId, 'edit', searchParams)}
        >
          <FileViewer workspaceData={workspaceData} fileId={fileId} projectId={projectId} />
        </ProtectedRoute>
        <Redirect to={canEditFile ? getFilePathByMode(fileId, 'edit') : getFilePathByMode(fileId, 'view')} />
      </Switch>
    </FileVersionContextProvider>
  )
}

export default FileModeRoutes
