import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { SearchInput, Select } from '../../components/shared'
import { translateMenuOptions } from '../../components/shared/Menu/utils'
import { FileListLoading, WorkSpaceTitleLoading } from '../../components/shared/Skeleton'
import { ProjectFieldsFragment } from '../../generated/graphql'
import { useSkeletonLoadingContext } from '../../providers/SkeletonLoadingProvider'
import { useWorkspaceContext } from '../../providers/WorkspaceContextProvider'
import { getTemplatePath } from '../../utils/pathGenerators'
import NoResultsFound from '../Files/components/EmptyState/NoResultsFound'
import { GRID_GAP, MIN_CARD_WIDTH, gridConfigMap, gridGapMap } from '../Files/config'
import TemplateListItem from './components/TemplateListItem'
import useTemplateList, { SORT_OPTIONS, SortOption } from './useTemplateList'

type FileListProps = {
  id: ProjectFieldsFragment['id']
}
const TemplateList = ({ id }: FileListProps) => {
  const { t } = useTranslation('workspace')
  const { workspaceData, isWorkspaceDataLoading } = useWorkspaceContext()
  const { workspaceSidebarReady, updateWorkspaceFileListReady } = useSkeletonLoadingContext()

  const {
    observerRef,
    filteredTemplates,
    hasTemplates,
    hasFilteredTemplates,
    isLoadingTemplates,
    searchQuery,
    setSearchQuery,
    setSortBy,
    sortBy
  } = useTemplateList({
    projectId: id,
    workspaceData
  })

  const isPageLoading = !workspaceSidebarReady || isWorkspaceDataLoading || isLoadingTemplates
  const noSearchResults = !isPageLoading && !hasFilteredTemplates && searchQuery

  const translatedSortOptions = useMemo(
    () =>
      translateMenuOptions(t, SORT_OPTIONS, {
        ns: 'workspace',
        keyPrefix: 'sort_options'
      }),
    [t]
  )

  const handleSortChange = (sortOptionValue: SortOption['value']) => {
    setSortBy(sortOptionValue)
  }

  useEffect(() => {
    if (!isWorkspaceDataLoading && !isLoadingTemplates) {
      updateWorkspaceFileListReady(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWorkspaceDataLoading, isLoadingTemplates])

  return isPageLoading ? (
    <>
      <WorkSpaceTitleLoading />
      <FileListLoading iterations={7} />
    </>
  ) : (
    <>
      <div className="sticky top-0 z-10 pt-40 flex flex-col gap-y-32 bg-inherit min-w-0">
        <div className="flex justify-between items-center px-64 pb-24 mb-8 gap-32 bg-neutral-80">
          <div className="flex items-center gap-8 min-w-0">
            <h3 className="text-h3 font-semibold hide-project-search:max-w-[200px] truncate">{t('templates')}</h3>
          </div>
        </div>
      </div>
      {hasTemplates && (
        <div className="flex justify-between px-64 mb-16">
          <div className="w-[400px]">
            <SearchInput
              value={searchQuery}
              onChange={setSearchQuery}
              size="s"
              variant="flat"
              divider="none"
              padding={false}
            />
          </div>
          <Select
            // @ts-ignore TODO: fix after refactor of Select
            size="l"
            caret
            options={translatedSortOptions}
            value={sortBy}
            onChange={handleSortChange}
            className="w-min"
          />
        </div>
      )}
      {hasFilteredTemplates && (
        <div
          className={`${gridConfigMap[MIN_CARD_WIDTH]} ${gridGapMap[GRID_GAP]} px-64 pb-32 bg-neutral-80`}
          data-test-id="template-list"
        >
          {filteredTemplates?.map((template) => (
            <TemplateListItem
              key={template.id}
              templateId={template.id}
              name={template.name}
              imageUrl={template.thumbnail_url}
              templateUrl={getTemplatePath(template.id)}
              author={template.author}
              authorUrl={template.author_source}
            />
          ))}
          <div ref={observerRef} />
        </div>
      )}
      {noSearchResults && <NoResultsFound />}
    </>
  )
}

export default TemplateList
