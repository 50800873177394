export const getEditorData = (editor) => {
  const {
    x,
    xState,
    y,
    yState,
    sizeRatioLock,
    width,
    widthState,
    height,
    heightState,
    rotation,
    rotationState,
    opacity,
    opacityState,
    blendMode,
    blendModeState,
    originX,
    originXState,
    originY,
    originYState,
    scaleX,
    scaleXState,
    scaleY,
    scaleYState,
    blurGaussianEnabled,
    blurGaussianAmount,
    blurGaussianState,
    // Layers part
    fillList,
    borderList,
    shadowList,
    innerShadowList
  } = editor.data
  return {
    x,
    xState,
    y,
    yState,
    sizeRatioLock,
    width,
    widthState,
    height,
    heightState,
    rotation,
    rotationState,
    opacity,
    opacityState,
    blendMode,
    blendModeState,
    originX,
    originXState,
    originY,
    originYState,
    scaleX,
    scaleXState,
    scaleY,
    scaleYState,
    blurGaussianEnabled,
    blurGaussianAmount,
    blurGaussianState,
    // Layers part
    fillList: fillList.slice(),
    borderList: borderList.slice(),
    shadowList: shadowList.slice(),
    innerShadowList: innerShadowList.slice()
  }
}
