import React, { forwardRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import Icon from '../Icon'

export type SearchInputProps = {
  value: string
  size?: 's' | 'l'
  variant?: 'default' | 'flat'
  divider?: 'top' | 'bottom' | 'both' | 'none'
  dividerColor?: string
  padding?: boolean
  onChange: (value: string) => void
  noCrossButton?: boolean
  placeholder?: string
}

const heightStyleMap = {
  default: {
    s: 'h-28',
    l: 'h-32'
  },
  flat: {
    s: 'h-28',
    l: 'h-48'
  }
}

const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  (
    {
      value,
      size = 's',
      variant = 'default',
      onChange,
      divider = 'both',
      dividerColor = 'border-neutral-60',
      padding = true,
      noCrossButton = false,
      placeholder = 'search'
    },
    ref
  ) => {
    const { t } = useTranslation('common')
    const memorized = useMemo(() => {
      const isDefaultSize = size === 's'
      const isDefaultVariant = variant === 'default'
      const topDivider = divider === 'top' || divider === 'both'
      const bottomDivider = divider === 'bottom' || divider === 'both'
      const hideCrossButton = (!isDefaultVariant && !topDivider && !bottomDivider) || noCrossButton
      const showTopDivider = !isDefaultVariant && topDivider
      const showBottomDivider = !isDefaultVariant && bottomDivider

      const labelClassName = `w-full flex items-center text-white 
                              ${heightStyleMap[variant][size]}
                              ${
                                isDefaultVariant
                                  ? 'px-8 rounded-md bg-light-overlay-5 hover:bg-light-overlay-10 hover:outline-light-overlay-20-1-offset--1 border border-transparent focus-within:border-primary focus-within:hover:outline-none disabled:border-transparent'
                                  : `border-solid ${padding ? 'px-16' : 'px-0'}`
                              }
                              ${showTopDivider ? 'border-t' : ''}
                              ${showBottomDivider ? 'border-b' : ''}
                              ${dividerColor}
                              `
      const inputClassName = `w-full px-8 bg-transparent color-white input-system-ignore placeholder-light-overlay-60 text-12 ${
        !isDefaultSize && !isDefaultVariant ? 'h-24' : 'h-16'
      }`
      return {
        hideCrossButton,
        labelClassName,
        inputClassName
      }
    }, [size, variant, divider, padding, dividerColor, noCrossButton])

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value)
    }
    const handleClearClick = () => {
      onChange('')
    }
    const handleOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
      e.target.select()
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      e.stopPropagation()
      switch (e.key) {
        case 'Escape':
          e.currentTarget.blur()
          break
        case 'Enter':
          e.currentTarget.blur()
          onChange(e.currentTarget.value)
          break
        default:
          break
      }
    }

    return (
      <label className={memorized.labelClassName}>
        <Icon name="Search" interactive={false} className="text-light-overlay-60" />
        <input
          ref={ref}
          value={value}
          className={memorized.inputClassName}
          placeholder={t(placeholder)}
          onChange={handleSearchChange}
          onFocus={handleOnFocus}
          onKeyDown={handleKeyDown}
        />
        {!memorized.hideCrossButton && (
          <Icon name="Cross" onClick={handleClearClick} className={value ? 'opacity-100' : 'opacity-0'} />
        )}
      </label>
    )
  }
)

SearchInput.displayName = 'SearchInput'

export default SearchInput
