const neutrals = {
  100: '#0D0C0C',
  90: '#0E0D0D',
  80: '#1E1D1D',
  70: '#2D2C2C',
  60: '#3D3C3C',
  50: '#4D4C4C',
  40: '#5C5C5C',
  30: '#6C6B6B',
  20: '#7C7B7B',
  10: '#8B8B8B'
}

const blues = {
  60: '#1658BA',
  50: '#1C6EE8',
  40: '#498BED'
}

const rubins = {
  80: '#B72435',
  70: '#CC283B',
  60: '#D83649',
  50: '#DC4B5C',
  40: '#E0606F',
  30: '#E47683',
  20: '#E88B96'
}

const limes = {
  80: '#2B9429',
  70: '#31A82F',
  60: '#37BC34',
  50: '#43CA40',
  40: '#56CF54',
  30: '#6AD568',
  20: '#7EDB7C'
}

const yellows = {
  80: '#C0A000',
  70: '#D9B500',
  60: '#F3CA00',
  50: '#FFD70D',
  40: '#FFDB27',
  30: '#FFDF40',
  20: '#FFE45A'
}

const oranges = {
  80: '#FF4206',
  70: '#FF5520',
  60: '#FF693A',
  50: '#FF7C53',
  40: '#FF8F6D',
  30: '#FFA386',
  20: '#FFB6A0'
}

const golds = {
  80: '#ED8E00',
  70: '#FF9C07',
  60: '#FFA621',
  50: '#FFB03A',
  40: '#FFBA54',
  30: '#FFC46D',
  20: '#FFCF87'
}

const lightOverlays = {
  3: 'rgba(255, 255, 255, 0.03)',
  5: 'rgba(255, 255, 255, 0.05)',
  10: 'rgba(255, 255, 255, 0.1)',
  15: 'rgba(255, 255, 255, 0.15)',
  20: 'rgba(255, 255, 255, 0.2)',
  30: 'rgba(255, 255, 255, 0.3)',
  40: 'rgba(255, 255, 255, 0.4)',
  50: 'rgba(255, 255, 255, 0.5)',
  60: 'rgba(255, 255, 255, 0.6)',
  70: 'rgba(255, 255, 255, 0.7)',
  80: 'rgba(255, 255, 255, 0.8)',
  90: 'rgba(255, 255, 255, 0.9)'
}

const darkOverlays = {
  10: 'rgba(0, 0, 0, 0.1)',
  20: 'rgba(0, 0, 0, 0.2)',
  30: 'rgba(0, 0, 0, 0.3)',
  40: 'rgba(0, 0, 0, 0.4)',
  50: 'rgba(0, 0, 0, 0.5)',
  60: 'rgba(0, 0, 0, 0.6)',
  70: 'rgba(0, 0, 0, 0.7)',
  80: 'rgba(0, 0, 0, 0.8)',
  90: 'rgba(0, 0, 0, 0.9)'
}

const bluesOverlays = {
  10: 'rgba(28, 110, 232, 0.1)',
  20: 'rgba(28, 110, 232, 0.2)',
  30: 'rgba(28, 110, 232, 0.3)',
  40: 'rgba(28, 110, 232, 0.4)',
  60: 'rgba(28, 110, 232, 0.6)'
}
const neutral60Overlays = {
  80: 'rgba(61, 60, 60, 0.80)'
}

const colors = {
  // alias
  primary: blues,
  primaryOverlays: bluesOverlays,
  secondary: neutrals,
  danger: rubins,
  success: limes,
  warning: golds,
  default: neutrals[60],
  white: '#FFFFFF',
  black: '#000000',
  red: '#FF0000',
  'light-gray': '#DAD9D9',
  text: neutrals[20],
  // palette
  neutrals,
  blues,
  limes,
  rubins,
  yellows,
  oranges,
  golds,
  bluesOverlays,
  lightOverlays,
  darkOverlays,
  neutral60Overlays
}

const fontWeights = {
  400: 400,
  500: 500,
  600: 600,
  800: 800,
  normal: 400,
  medium: 500,
  semiBold: 600,
  bold: 800
}

const fontSizes = {
  h1: 30,
  h2: 24,
  h3: 20,
  h4: 16,
  16: 16,
  14: 14,
  13: 13,
  12: 12,
  11: 11,
  10: 10,
  9: 9,
  c1: 10,
  c2: 9
}

const lineHeights = {
  h1: '40px',
  h2: '36px',
  h3: '32px',
  h4: '24px',
  14: '24px',
  13: '24px',
  12: '16px',
  11: '16px',
  10: '16px',
  9: '16px',
  c1: '16px',
  c2: '16px'
}

const letterSpaces = {
  h1: '-0.016em',
  h2: '-0.014em',
  h3: '-0.012em',
  h4: '-0.006em',
  14: '-0.001em',
  13: '0.0025em',
  12: '0.005em',
  11: '0.01em',
  10: '0.015em',
  9: '0.021em',
  c1: '0.01em',
  c2: '0.021em'
}
const borderRadius = {
  cxs: '2px',
  cs: '4px',
  cm: '6px',
  cl: '8px'
}

const typography = {
  fontSize: fontSizes[12],
  fontWeight: fontWeights.normal,
  lineHeight: lineHeights[12]
}
const space = [0, 4, 8, 12, 16, 20, 24, 32]

export default {
  typography,
  space,
  fontSizes,
  fontWeights,
  lineHeights,
  letterSpaces,
  colors,
  borderRadius
}
