import { PropComponentType } from '@phase-software/types'
import { notNull } from '@phase-software/data-utils'
import PropertyComponent from './PropertyComponent'

/** @typedef {import('../DataStore').DataStore} DataStore */
/** @typedef {import('../effect/Layer').Effect} Effect */
/** @typedef {import('../effect/Layer').EffectData} EffectData */
/** @typedef {import('./PropertyComponent').AppliedRef} AppliedRef */

/**
 * @abstract
 */
export default class EffectComponent extends PropertyComponent {
    /**
     * @param {DataStore} dataStore
     * @param {EffectComponentData} [data]
     * @param {object} [options]
     * @param {bool} [options.regenId=false]   if set to true, will generate new ID
     */
    constructor(dataStore, data = {}, options) {
        super(dataStore, data, options)

        const { effects, effectsTypeSet } = data

        this.componentType = PropComponentType.EFFECT

        this.effects = notNull(effects) ? effects : []
        this.effectsTypeSet = notNull(effects) ? new Set(effectsTypeSet) : new Set()

        if (!this.name) {
            this.name = 'effect'
        }
    }

    /**
     * @param {Partial<EffectComponentData>} data
     */
    set(data) {
        super.set(data)

        if (notNull(data.effects)) {
            this.updateProp('effects', data.effects)
        }

        if (notNull(data.effectsTypeSet)) {
            this.updateProp('effectsTypeSet', data.effectsTypeSet)
        }
    }

    /**
     * Check if already has the effect
     * @param {EffectType} effectType
     * @returns {bool}
     */
    hasEffectType(effectType) {
        return this.effectsTypeSet.has(effectType)
    }

    /**
     * Add Effect to EffectComponent
     * @param {string} effectId
     * @param {number} [index=-1]
     */
    addEffect(effectId, index = -1) {
        if (this.effects.includes(effectId)) {
            return
        }

        const idx = index === -1 ? this.effects.length : index
        const newEffects = this.effects.slice()
        newEffects.splice(idx, 0, effectId)

        const newEffect = this.dataStore.library.getEffect(effectId)
        this.set({
            effects: newEffects,
            effectsTypeSet: new Set(this.effectsTypeSet).add(newEffect.effectType)
        })
    }

    /**
     * Remove Effet from EffectComponent
     * @param {string} effectId
     */
    removeEffect(effectId) {
        const effectIdx = this.effects.findIndex((eId) => eId === effectId)
        if (effectIdx === -1) {
            return
        }

        const effect = this.dataStore.library.getEffect(effectId)

        const newEffects = this.effects.slice()
        newEffects.splice(effectIdx, 1)

        const effectsTypeSet = new Set(this.effectsTypeSet)
        effectsTypeSet.delete(effect.effectType)

        this.set({
            effects: newEffects,
            effectsTypeSet
        })
    }

    /**
     * Override this in subclasses
     * CALL super._clone() at the top of overriden method
     * @protected
     * @param {AppliedRef} [ref]
     * @returns {PropertyComponent}
     */
    _clone(ref) {
        const obj = super._clone(ref)
        const clonedEffectsMap = this.dataStore.library.cloneComponentList(this.effects.map((effectId) => {
            return this.dataStore.library.getComponent(effectId)
        }))
        obj.effects = [...clonedEffectsMap.values()]
        obj.effectsTypeSet = new Set(this.effectsTypeSet)
        return obj
    }

    /**
     * Override this in subclasses
     * CALL super._save() at the top of overriden method
     * @protected
     * @returns {EffectComponentData} data
     */
    _save() {
        const data = super._save()
        data.effects = this.effects
        data.effectsTypeSet = [...this.effectsTypeSet]
        return data
    }

    /**
     * Override this in subclasses if subclass has any inner sharable PropertyComponents
     * CALL super.apply() at the top of overriden method
     * @param {AppliedRef} ref
     */
    apply(ref) {
        super.apply(ref)
        this.effects.forEach(effectId => {
            const effect = this.dataStore.library.getEffect(effectId)
            effect.apply(ref)
        })
    }

    /**
     * Override this in subclasses if subclass has any inner sharable PropertyComponents
     * CALL super.cancel() at the top of overriden method
     * @param {AppliedRef} ref
     */
    cancel(ref) {
        super.cancel(ref)
        this.effects.forEach(effectId => {
            const effect = this.dataStore.library.getEffect(effectId)
            effect.cancel(ref)
        })
    }
}

/** @typedef {import('./PropertyComponent').PropertyComponentData} PropertyComponentData */

/**
 * @typedef {PropertyComponentData} EffectComponentData
 * @property {Effect[]} effects
 */
