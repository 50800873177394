import { useCallback, useMemo, useState } from 'react'

import { scrollIntoView } from '../../../utils/dom'
import { useFocusHandler } from '../FocusLoop'
import { ARROW_DOWN, ARROW_UP, INITIAL_ACTIVE_INDEX } from './Menu.constants'
import { MenuListOptionProps, MenuOptionProps, MenuProps } from './Menu.types'
import { isNonSelectableOption } from './utils'

const ArrowDirection = {
  ARROW_UP,
  ARROW_DOWN
} as const

type ArrowDirection = (typeof ArrowDirection)[keyof typeof ArrowDirection]

export default function useMenuNavigation({
  firstSelectedOption,
  firstSelectedOptionIndex,
  filteredOptions,
  ref,
  onClose,
  selectOption,
  triggerInFocusLoop,
  triggerRef
}: {
  firstSelectedOption?: MenuOptionProps
  firstSelectedOptionIndex: number
  filteredOptions: MenuListOptionProps[]
  ref?: React.RefObject<HTMLDivElement>
  selectOption: (option?: MenuOptionProps) => void
  onClose: (open: boolean) => void
  triggerInFocusLoop?: boolean
  triggerRef?: MenuProps['trigger']
}) {
  const { switchFocus } = useFocusHandler()

  const [activeIndex, setActiveIndex] = useState(firstSelectedOptionIndex)

  const changeActiveIndex = useCallback(
    (index: number, shouldScrollIntoView = true) => {
      setActiveIndex(index)
      if (shouldScrollIntoView) scrollIntoView(ref?.current?.children[index + 1], 'center', null, 'bottom', true)
    },
    [ref]
  )

  const isInitialActiveIndex = activeIndex === INITIAL_ACTIVE_INDEX
  const isMovingForward = (direction: ArrowDirection) => direction === ArrowDirection.ARROW_DOWN

  const handleArrowKeySelection = useCallback(
    (direction: ArrowDirection) => {
      const isAtFirstOption = isInitialActiveIndex && isMovingForward(direction)
      const isAtLastOption = isInitialActiveIndex && !isMovingForward(direction)

      const adjustedActiveIndex = isAtFirstOption
        ? INITIAL_ACTIVE_INDEX
        : isAtLastOption
        ? filteredOptions.length
        : activeIndex

      // circular navigation
      let newActiveIndex = (adjustedActiveIndex + direction + filteredOptions.length) % filteredOptions.length

      while (isNonSelectableOption(filteredOptions[newActiveIndex])) {
        newActiveIndex = (newActiveIndex + direction + filteredOptions.length) % filteredOptions.length
      }
      changeActiveIndex(newActiveIndex)
    },
    [activeIndex, isInitialActiveIndex, filteredOptions, changeActiveIndex]
  )

  const selectOptionByActiveIndex = useCallback(() => {
    const targetOption = (filteredOptions[activeIndex] as MenuOptionProps) || firstSelectedOption
    selectOption(targetOption)
  }, [activeIndex, firstSelectedOption, selectOption, filteredOptions])

  const keyHandlers: {
    [key: string]: (
      e: KeyboardEvent,
      activeIndex: number,
      selectOption: MenuOptionProps,
      filteredOptions: MenuOptionProps[]
    ) => void
  } = useMemo(
    () => ({
      Tab: (e) => {
        if (triggerRef?.current) {
          const direction = e.shiftKey ? 'back' : 'forward'
          const targetElement =
            triggerRef.current.tagName === 'BUTTON' ? triggerRef.current : triggerRef.current.querySelector('button')
          if (targetElement) {
            if (!triggerInFocusLoop) {
              targetElement.focus()
            } else {
              e.preventDefault()
              switchFocus(targetElement as HTMLFormElement, direction)
            }
          }
        }
        onClose(false)
      },
      Enter: (e) => {
        e.preventDefault()
        const targetOption =
          activeIndex === INITIAL_ACTIVE_INDEX ? selectOption : (filteredOptions[activeIndex] as MenuOptionProps)
        if (targetOption) {
          selectOption(targetOption)
        }
      },
      ' ': (e) => {
        e.preventDefault()
        const targetOption =
          activeIndex === INITIAL_ACTIVE_INDEX ? selectOption : (filteredOptions[activeIndex] as MenuOptionProps)
        if (targetOption) {
          selectOption(targetOption)
        }
      },
      Escape: (e) => {
        e.preventDefault()
        onClose?.(false)
      },
      Home: (e) => {
        e.preventDefault()
        setActiveIndex(0)
        changeActiveIndex?.(0)
        scrollIntoView(ref?.current?.children[0])
      },
      End: (e) => {
        e.preventDefault()
        const lastIndex = filteredOptions.length - 1
        setActiveIndex(lastIndex)
        changeActiveIndex?.(lastIndex)
        scrollIntoView(ref?.current?.children[lastIndex])
      },
      ArrowUp: (e) => {
        e.preventDefault()
        handleArrowKeySelection(ARROW_UP)
      },
      ArrowDown: (e) => {
        e.preventDefault()
        handleArrowKeySelection(ARROW_DOWN)
      }
    }),
    [
      selectOption,
      ref,
      onClose,
      triggerRef,
      triggerInFocusLoop,
      switchFocus,
      activeIndex,
      filteredOptions,
      setActiveIndex,
      changeActiveIndex,
      handleArrowKeySelection
    ]
  )

  const handleKeyDown = (e: KeyboardEvent) => {
    e.stopPropagation()

    const handler = keyHandlers[e.key]
    if (handler) handler(e, activeIndex, selectOption as MenuOptionProps, filteredOptions as MenuOptionProps[])
  }

  return {
    activeIndex,
    handleKeyDown,
    changeActiveIndex,
    selectOptionByActiveIndex
  }
}
