import { LayerComponentType, LayerType } from '@phase-software/types'
import LayerComponent from './LayerComponent'

/** @typedef {import('../DataStore').DataStore} DataStore */

export default class StrokeComponent extends LayerComponent {
    /**
     * @param {DataStore} dataStore
     * @param {StrokeComponentData} [data]
     * @param {object} [options] 
     * @param {bool} [options.regenId=false]   if set to true, will generate new ID
     */
    constructor(dataStore, data = {}, options) {
        super(dataStore, data, options)

        this.componentType = LayerComponentType.STROKE

        if (!this.name || this.name === 'layer') {
            this.name = 'stroke'
        }
        if (!this.layerType || this.layerType === 'LAYER') {
            this.layerType = LayerType.STROKE
        }
    }
}

/** @typedef {import('./LayerComponent').LayerComponentData} LayerComponentData */
/** @typedef {(import('../layer/Stroke').StrokeData)} StrokeData */

/**
 * @typedef {LayerComponentData} StrokeComponentData
 * @property {StrokeData[]} layers
 */
