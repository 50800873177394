import { isNull, isMap, isArr, isFun, mapMap, arrEquals } from './commons'

/**
 * @typedef {object} PropType
 * @property {[]}                      [enum]         if exists the prop type is enum; allowed values are defined in the 'enum' array
 * @property {Function}                [type]         (if 'enum' is undefined) constructor function for the prop type
 * @property {(Function | undefined)}  [itemType]     (if 'enum' is undefined) if 'type' is a Map or Array then 'itemType' is a constructor
 *                                                    function for each item of the Map or Array. If 'type' is Map or Array and 'itemType'
 *                                                    is undefined, then literal values are used for each item (direct value assignment)
 */
export class PropType {
    constructor(propType = {}) {
        this.enum = propType.enum
        this.type = propType.type
        this.itemType = propType.itemType
    }

    /**
     * Load a new object of type specified by this PropType with data (serialized or deserialized, if compatible)
     * @param  {any} data
     * @returns {any}        new object of type specified by PropType
     */
    copy(data) {
        if (!this.type) {
            return
        }
        if (this.type === Map) {
            return mapMap(data, (k, v) => [k, this.itemType ? new this.itemType(v) : v])
        } else if (this.type === Array) {
            return data.map(v => (this.itemType ? new this.itemType(v) : v))
        } else {
            return new this.type(data)
        }
    }

    eq(v1, v2) {
        if (v1 === v2) {
            return true
        } else if (isNull(v1) || isNull (v2)) {
            return false
        }

        if (isFun(v1.eq)){
            return v1.eq(v2)
        } else if (this.type === Array) {
            return arrEquals(v1, v2, (a, b) => (a && isFun(a.eq) ? a.eq(b) : a === b))
        } else if (this.type === Map) {
            // TODO: consider if we ever need to actually compare Maps
            return false
        }
        return v1 === v2
    }

    save(data) {
        if (isMap(data)) {
            return [...mapMap(data, (k, v) => [k, v.save && v.save.constructor === Function ? v.save() : v])]
        } else if (isArr(data)) {
            return [...data.map(v => (v.save && v.save.constructor === Function ? v.save() : v))]
        }
    }

    get isIterable() {
        return (this.type === Array || this.type === Map)
    }
}

/**
 * Helper method to create an object of PropTypes instances
 * @param  {object} obj
 * @returns {object<string, PropType>}
 */
export function createPropTypes(obj) {
    const propTypes = {}
    for (const key in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(key)) {
            propTypes[key] = new PropType(obj[key])
        }
    }
    return propTypes
}
