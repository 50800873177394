import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

export const File = ({ file, index, onClick, onRemove, selected }) => {
  const handleClick = useCallback(() => {
    onClick(file, index)
  }, [onClick, file, index])

  const handleRemove = useCallback(
    (e) => {
      e.stopPropagation()
      onRemove(file)
    },
    [onRemove, file]
  )

  const active = selected ? 'text-coral' : 'hover:text-coral'

  return (
    <div className={`js-file whitespace-nowrap py-1 px-4 ${active}`} onClick={handleClick}>
      <span onClick={handleRemove}>🗑️</span> {file.name}
    </div>
  )
}
File.propTypes = {
  index: PropTypes.number,
  file: PropTypes.object,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  onRemove: PropTypes.func
}

export const FileList = ({ fileList, onSelect, onRemove, selected }) => {
  return (
    <div className="overflow-auto flex-shrink flex-grow flex flex-col">
      {fileList.length ? (
        fileList.map((file, idx) => (
          <File key={idx} index={idx} file={file} onClick={onSelect} onRemove={onRemove} selected={selected === file} />
        ))
      ) : (
        <div className="flex flex-grow justify-center items-center">Drop to import files</div>
      )}
    </div>
  )
}

FileList.propTypes = {
  fileList: PropTypes.array,
  onSelect: PropTypes.func,
  onRemove: PropTypes.func,
  selected: PropTypes.object
}
