import { schema } from 'normalizr'
import { createProvider, useEntity } from '../utils'

export const TextDecoration = new schema.Entity(
  'textDecorations',
  { data: {} },
  {
    processStrategy: (value, parent) => {
      return { ...value, styleId: parent.id }
    }
  }
)

const defaultValue = {}
const [Provider, useSelectState, useSetState] = createProvider('TextDecoration', defaultValue)

export const useTextDecoration = useSelectState

export const useSetTextDecoration = () => {
  const setState = useSetState()
  const [
    setTextDecoration,
    addTextDecoration,
    updateTextDecoration,
    removeTextDecoration,
    mergeTextDecoration,
    reduceTextDecoration,
    createTextDecoration,
    destroyTextDecoration
  ] = useEntity(TextDecoration, setState)

  return {
    setTextDecoration,
    addTextDecoration,
    updateTextDecoration,
    removeTextDecoration,
    mergeTextDecoration,
    reduceTextDecoration,
    createTextDecoration,
    destroyTextDecoration
  }
}

export default Provider
