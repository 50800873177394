import { TutorialType } from '../constant'
import { waitUntil, delay } from '../utils'
import tip11 from '../assets/1.1.gif'
import tip12 from '../assets/1.2.gif'
// import tip15 from '../assets/1.5.gif'
import tip14 from '../assets/1.4.gif'
import tip01 from '../assets/0.1.gif'
import tip21 from '../assets/2.1.gif'
import tip22 from '../assets/2.2.gif'
import tip23 from '../assets/2.3.gif'
import tip24 from '../assets/2.4.gif'
import tip25 from '../assets/2.5.gif'
import tip26 from '../assets/2.6.gif'
import addKeyFrameMp4 from '../assets/how-to-add-keyframe.mp4'

export default {
  key: TutorialType.COMPLETE_INTERFACE,
  mask: true,
  progress: true,
  interact: true,
  intro: {
    header: 'Introduce Phase panels',
    content: 'On this demo file, we quickly guide you through all major panels in the editor.'
  },
  steps: [
    {
      header: 'Toolbar',
      content: 'You can find size tool, scale tool, and set origin tool here.',
      anchor: '.pt-top-tool',
      onNext: async () => {
        document.querySelector('.pt-general-tool .pt-caret').click()
        await waitUntil('.pt-scale-option')
      },
      image: tip11,
      placement: 'bottom',
      relatedTo: TutorialType.GENERAL_INTERFACE
    },
    {
      header: 'Scale Tool',
      content: 'When dragging element’s edge with Scale Tool, you change its Scale not Size.',
      image: tip12,
      anchor: '.pt-scale-option',
      onNext: async ({ dataStore }) => {
        document.querySelector('.pt-general-tool .pt-caret').click()
        dataStore.selection.selectElements([dataStore.workspace.watched.children[0].children[0]])
        await waitUntil('.pt-transform-editor')
      },
      highlight: ['.pt-top-tool', '.pt-general-tool-menu'],
      relatedTo: TutorialType.TOOL_MENU
    },
    {
      header: 'Set Origin on Panel',
      content: 'You can change Origin with the 9-patch UI on the Panel or the inputbox.',
      anchor: '.pt-transform-editor',
      highlight: ['.pt-transform-editor'],
      image: tip14,
      relatedTo: TutorialType.TRANSFORM
    },
    {
      header: 'Switch Mode',
      content: 'Go check Animation Mode!',
      anchor: '.pt-mode-switcher',
      offset: 8,
      image: tip01,
      interact: true,
      onShow: (next) => {
        document.querySelector('.pt-action-mode').addEventListener('click', next)
        return () => {
          document.querySelector('.pt-action-mode').removeEventListener('click', next)
        }
      },
      onNext: async () => {
        document.querySelector('.pt-action-mode').click()
        await delay(500)
      }
    },
    {
      header: 'Action Panel',
      image: tip21,
      content: 'Action Panel shows all animation-related settings.',
      video: {
        title: '👀 How to add Keyframe?',
        src: addKeyFrameMp4
      },
      anchor: '.pt-action-panel'
    },
    {
      header: 'Playback Control',
      content: 'You can set up all player related items here, e.g. play, stop, set speed, set maximum time.',
      image: tip22,
      anchor: '.pt-player',
      placement: 'top'
    },
    {
      header: 'Action List',
      content: 'Here list all animated elements.',
      image: tip23,
      anchor: '.pt-action-list',
      placement: 'top'
    },
    {
      header: 'Playhead',
      content: 'You can use Playhead to inspect what the Canvas looks like at a specific time of the animation.',
      image: tip24,
      anchor: '.pt-playhead',
      highlight: ['.pt-action-ruler', '.pt-action-track-list']
    },
    {
      header: 'Keyframe',
      content: 'Keyframe indicates your setting at a specific time. You can edit its easing to make animation vivid.',
      image: tip25,
      anchor: '.js-kf', // select the first KF
      highlight: ['.pt-action-track-list'],
      placement: 'top'
    },
    {
      header: 'Keyframe Indicator',
      content: 'You can also add this current value as a Keyframe by clicking on the indicator.',
      image: tip26,
      anchor: '.pt-kf-indicator',
      placement: 'left',
      offset: [44, 4],
      relatedTo: TutorialType.INDICATOR
    }
  ]
}
