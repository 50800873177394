import { LayerType } from '@phase-software/types'
// layer imports
import { ComputedFill } from './ComputedFill'
import { ComputedStroke } from './ComputedStroke'
import { ComputedShadow } from './ComputedShadow'
import Fill from './Fill'
import Stroke from './Stroke'
import Shadow from './Shadow'
import InnerShadow from './InnerShadow'

/** @typedef {import('../DataStore').DataStore} DataStore */
/** @typedef {import('./Layer').Layer} Layer */
/** @typedef {import('./Layer').LayerType} LayerType */
/** @typedef {import('./Layer').LayerData} LayerData */
/** @typedef {import('./Effect').Effect} Effect */
/** @typedef {import('./Effect').EffectType} EffectType */
/** @typedef {import('./ComputedLayer').ComputedLayerData} ComputedLayerData */


/**
 * Creates a new instance of Layer
 * @param {DataStore} dataStore
 * @param {LayerType} type
 * @param {LayerData} [data]
 * @param {object} [options] 
 * @param {bool} [options.regenId=false]   if set to true, will generate new ID
 * @returns {Layer}
 */
export function createLayer(dataStore, type, data, options) {
    switch (type) {
        case LayerType.FILL:
            return new Fill(dataStore, data, options)
        case LayerType.STROKE:
            return new Stroke(dataStore, data, options)
        case LayerType.SHADOW:
            return new Shadow(dataStore, data, options)
        case LayerType.INNER_SHADOW:
            return new InnerShadow(dataStore, data, options)
    }
}

/**
 * Creates ComputedLayer with different types
 * @param {DataStore} dataStore
 * @param {LayerType} layerType
 * @param {object} layerData
 * @returns {ComputedLayer}
 */
function createComputedLayer(dataStore, layerType, layerData) {
    switch (layerType) {
        case LayerType.FILL:
            return new ComputedFill(dataStore, layerData)
        case LayerType.STROKE:
            return new ComputedStroke(dataStore, layerData)
        case LayerType.SHADOW:
        case LayerType.INNER_SHADOW:
            return new ComputedShadow(dataStore, layerData)
    }
}

/**
 * Creates or loads ComputedLayer from Layer (of respective type)
 * @param {DataStore} dataStore
 * @param {Layer} layer
 * @param {string} elementId
 * @returns {ComputedLayer}
 */
export function computeLayer(dataStore, layer, elementId) {
    const { layerType } = layer

    const computedLayerData = layerToComputedLayerData(layer)

    computedLayerData.layer = layer
    computedLayerData.elementId = elementId

    return createComputedLayer(dataStore, layerType, computedLayerData)
}

/**
 * Creates ComputedLayer with default layer value
 * @param {DataStore} dataStore
 * @param {LayerType} layerType
 * @param {object} layerData
 * @returns {ComputedLayer}
 */
export function computedLayerWithDefaultValue(dataStore, layerType, layerData) {
    return createComputedLayer(dataStore, layerType, layerData)
}

/**
 * Converts Layer to ComputedLayerData
 * @private
 * @param  {Layer} layer
 * @returns {ComputedLayerData}
 */
export function layerToComputedLayerData(layer) {
    // TODO: take into account that save could return refId instead of data for shared components
    const layerType = layer.layerType

    const computedLayerData = {
        layerType,
        layerId: layer.id,
        name: layer.name,
        visible: layer.visible
    }
    const paintData = layer.paint.save()

    if (layerType === LayerType.SHADOW || layerType === LayerType.INNER_SHADOW) {
        Object.assign(computedLayerData, paintData)

        computedLayerData.offsetX = layer.offsetX
        computedLayerData.offsetY = layer.offsetY
        computedLayerData.blur = layer.blur
        computedLayerData.spread = layer.spread
    } else {
        const paintInitValues = layer.paint.initValues
        Object.assign(computedLayerData, paintInitValues, paintData)

        if (layerType === LayerType.STROKE) {
            computedLayerData.growDirection = layer.growDirection
            computedLayerData.offset = layer.offset
            computedLayerData.width = layer.width
            computedLayerData.dash = layer.dash
            computedLayerData.gap = layer.gap
            computedLayerData.cap = layer.cap
            computedLayerData.capSize = layer.capSize
            computedLayerData.join = layer.join
            computedLayerData.joinSize = layer.joinSize
            computedLayerData.miter = layer.miter
            computedLayerData.ends = layer.ends
        }
    }
    // TODO: remove in the future (when sure we don't need it)

    // else if (layerType === 'EFFECT') {
    //     // TODO: Will move to computedStyle for extra component's CHANGES event handler
    //     const effectType = layer.effectType
    //     computedLayerData.effectType = effectType

    //     switch(effectType) {
    //         case 'ADJUSTMENT':
    //             computedLayerData.gamma = layer.gamma
    //             computedLayerData.saturation = layer.saturation
    //             computedLayerData.contrast = layer.contrast
    //             computedLayerData.brightness = layer.brightness
    //             computedLayerData.rgba = layer.rgba
    //             break
    //         case 'BASELINE':
    //             computedLayerData.baselineOffset = layer.baselineOffset
    //             break
    //         case 'BEVEL':
    //             computedLayerData.rotation = layer.rotation
    //             computedLayerData.thickness = layer.thickness
    //             computedLayerData.lightColor = layer.lightColor
    //             computedLayerData.shadowColor = layer.shadowColor
    //             break
    //         case 'CHARACTER_ROTATION':
    //             computedLayerData.rotation = layer.rotation
    //             break
    //         case 'CHARACTER_WRAP':
    //         case 'HIDE':
    //         case 'STICKY':
    //             // These effects don't hold any data!
    //             break
    //         case 'CHROMAL_ABERRATION':
    //             computedLayerData.objectDistance = layer.objectDistance
    //             computedLayerData.imageDistance = layer.imageDistance
    //             break
    //         case 'EXPOSURE':
    //             computedLayerData.exposure = layer.exposure
    //             break
    //         case 'GRID_WARP':
    //             computedLayerData.rows = layer.rows
    //             computedLayerData.columns = layer.columns
    //             computedLayerData.sourceGrid = layer.sourceGrid
    //             computedLayerData.destinationGrid = layer.destinationGrid
    //             break
    //         case 'HUE':
    //             computedLayerData.hue = layer.hue
    //             break
    //         case 'LAYER_BLUR':
    //             computedLayerData.blurType = layer.blurType
    //             computedLayerData.amount = layer.amount
    //             computedLayerData.angle = layer.angle
    //             computedLayerData.saturation = layer.saturation
    //             break
    //         case 'LENS':
    //             computedLayerData.distortion = layer.distortion
    //             break
    //         case 'LUT':
    //             computedLayerData.lutURL = layer.lutURL
    //             break
    //         case 'MOTION_BLUR':
    //             computedLayerData.velocity = layer.velocity
    //             computedLayerData.offset = layer.offset
    //             computedLayerData.amount = layer.amount
    //             break
    //         case 'ORDER':
    //             computedLayerData.order = layer.order
    //             computedLayerData.orderOffest = layer.orderOffest
    //             break
    //         case 'SCALE':
    //             computedLayerData.scale = layer.scale
    //             break
    //         case 'TWIRL':
    //             computedLayerData.center = layer.center
    //             computedLayerData.angle = layer.angle
    //             computedLayerData.radius = layer.radius
    //             break
    //     }
    // }

    return computedLayerData
}
