import { useEffect } from 'react'
import { useHistory } from 'react-router'

import { CUSTOM_EVENT_NAMES } from '../../../constants'

type RedirectEventDetail = {
  route: string
}

const ErrorPageRedirect = () => {
  const history = useHistory()

  useEffect(() => {
    const handleRedirect = (event: Event) => {
      const customEvent = event as CustomEvent<RedirectEventDetail>
      if (history) history.push(customEvent.detail.route)
    }
    document.addEventListener(CUSTOM_EVENT_NAMES.REDIRECT_TO_ERROR_PAGE, handleRedirect)
    return () => {
      document.removeEventListener(CUSTOM_EVENT_NAMES.REDIRECT_TO_ERROR_PAGE, handleRedirect)
    }
  }, [history])

  return null
}

export default ErrorPageRedirect
