import React, { useCallback } from 'react'
import { WorkspaceData } from '../../providers/WorkspaceContextProvider'

import { NavigationItem, Tooltip } from '../../components/shared'
import ProjectContextMenu from '../../components/ContextMenus/ProjectContextMenu'

import { useSetModal } from '../../providers/ModalProvider'

import { ProjectFieldsFragment } from '../../generated/graphql'
import { getWorkspaceProjectPath } from '../../utils/pathGenerators'
import { useProjectPermissionContext } from '../../providers/ProjectPermissionProvider'

type ProjectNavigationItemProps = {
  workspaceType: WorkspaceData['type']
  workspaceSlug: WorkspaceData['slug']
  id: ProjectFieldsFragment['id']
  name?: ProjectFieldsFragment['name']
}
const ProjectNavigationItem = ({ workspaceType, workspaceSlug, id, name }: ProjectNavigationItemProps) => {
  const { fetchPermissions } = useProjectPermissionContext()

  const { openModal } = useSetModal(`ProjectContextMenu-${id}`)

  const openProjectContextMenu = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      openModal({
        cursorPosition: {
          top: e.clientY,
          left: e.clientX
        }
      })
    },
    [openModal]
  )

  const handleMouseEnter = () => {
    fetchPermissions()
  }

  return (
    <>
      <NavigationItem
        to={getWorkspaceProjectPath(workspaceType, workspaceSlug, id)}
        dataTestId="page-link"
        onContextMenu={openProjectContextMenu}
        onMouseEnter={handleMouseEnter}
        iconName="FolderOutline"
      >
        <Tooltip content={name} visibleOnOverflow>
          <div className="truncate">{name}</div>
        </Tooltip>
      </NavigationItem>
      <ProjectContextMenu workspaceType={workspaceType} workspaceSlug={workspaceSlug} name={name} id={id} />
    </>
  )
}
export default ProjectNavigationItem
