import React, { forwardRef } from 'react'
import ReactDOM from 'react-dom'

const InsertLine = forwardRef((props, ref) => {
  return ReactDOM.createPortal(
    <div ref={ref} className="hidden rounded-r-1 absolute top-0 pointer-events-none z-10">
      <div
        className="w-8 h-8 absolute right-full rounded-circle border border-neutral-20 transform translate-y-[calc(-50%+0.5px)]"
      />
    </div>,
    document.body
  )
})
InsertLine.displayName = 'InsertLine'

export default InsertLine
