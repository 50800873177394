import { EPSILON, Num, Vector2 } from "../../math"
import Numerical from "./utils/Numerical"

/** @typedef {import("../../math/Vector2").Vector2Like} Vector2Like */

export function Line() {
    /** @type {number} */
    this._px = undefined
    /** @type {number} */
    this._py = undefined
    /** @type {number} */
    this._vx = undefined
    /** @type {number} */
    this._vy = undefined
}

/**
 * @param {number} p1x
 * @param {number} p1y
 * @param {number} v1x
 * @param {number} v1y
 * @param {number} p2x
 * @param {number} p2y
 * @param {number} v2x
 * @param {number} v2y
 * @param {boolean} asVector
 * @param {boolean} isInfinite
 */
Line.intersect = (p1x, p1y, v1x, v1y, p2x, p2y, v2x, v2y, asVector, isInfinite) => {
    // Convert 2nd points to vectors if they are not specified as such.
    if (!asVector) {
        // eslint-disable-next-line no-param-reassign
        v1x -= p1x
        // eslint-disable-next-line no-param-reassign
        v1y -= p1y
        // eslint-disable-next-line no-param-reassign
        v2x -= p2x
        // eslint-disable-next-line no-param-reassign
        v2y -= p2y
    }
    const cross = v1x * v2y - v1y * v2x
    // Avoid divisions by 0, and errors when getting too close to 0
    if (!Numerical.isMachineZero(cross)) {
        const dx = p1x - p2x, dy = p1y - p2y
        let u1 = (v2x * dy - v2y * dx) / cross
        const u2 = (v1x * dy - v1y * dx) / cross
        // Check the ranges of the u parameters if the line is not
        // allowed to extend beyond the definition points, but
        // compare with EPSILON tolerance over the [0, 1] bounds.
        const epsilon = EPSILON,
            uMin = -epsilon,
            uMax = 1 + epsilon
        if (isInfinite
                || (uMin < u1 && u1 < uMax && uMin < u2 && u2 < uMax)) {
            if (!isInfinite) {
                // Address the tolerance at the bounds by clipping to
                // the actual range.
                // eslint-disable-next-line no-nested-ternary
                u1 = u1 <= 0 ? 0 : u1 >= 1 ? 1 : u1
            }
            return new Vector2(
                p1x + u1 * v1x,
                p1y + u1 * v1y)
        }
    }
}

/**
 * @param {number} px
 * @param {number} py
 * @param {number} vx
 * @param {number} vy
 * @param {number} x
 * @param {number} y
 * @param {boolean} asVector
 * @param {boolean} isInfinite
 */
Line.getSide = (px, py, vx, vy, x, y, asVector, isInfinite) => {
    if (!asVector) {
        // eslint-disable-next-line no-param-reassign
        vx -= px
        // eslint-disable-next-line no-param-reassign
        vy -= py
    }
    const v2x = x - px, v2y = y - py
    let ccw = v2x * vy - v2y * vx
    // ccw = v2.cross(v1)
    if (!isInfinite && Numerical.isMachineZero(ccw)) {
        // If the point is on the infinite line, check if it's on the
        // finite line too: Project v2 onto v1 and determine ccw based
        // on which side of the finite line the point lies. Calculate
        // the 'u' value of the point on the line, and use it for ccw:
        // u = v2.dot(v1) / v1.dot(v1)
        ccw = (v2x * vx + v2x * vx) / (vx * vx + vy * vy)
        // If the 'u' value is within the line range, set ccw to 0,
        // otherwise its already correct sign is all we need.
        if (ccw >= 0 && ccw <= 1) {
            ccw = 0
        }
    }
    // eslint-disable-next-line no-nested-ternary
    return ccw < 0 ? -1 : ccw > 0 ? 1 : 0
}

/**
 * @param {number} px
 * @param {number} py
 * @param {number} vx
 * @param {number} vy
 * @param {number} x
 * @param {number} y
 * @param {boolean} asVector
 */
Line.getSignedDistance = (px, py, vx, vy, x, y, asVector) => {
    if (!asVector) {
        // eslint-disable-next-line no-param-reassign
        vx -= px
        // eslint-disable-next-line no-param-reassign
        vy -= py
    }
    // Based on the error analysis by @iconexperience outlined in #799
    // eslint-disable-next-line no-nested-ternary
    return  vx === 0 ? (vy > 0 ? x - px : px - x)
        // eslint-disable-next-line no-nested-ternary
        : vy === 0 ? (vx < 0 ? y - py : py - y)
            : ((x - px) * vy - (y - py) * vx) / (
                vy > vx
                    ? vy * sqrt(1 + (vx * vx) / (vy * vy))
                    : vx * sqrt(1 + (vy * vy) / (vx * vx))
            )
}

/**
 * @param {number} px
 * @param {number} py
 * @param {number} vx
 * @param {number} vy
 * @param {number} x
 * @param {number} y
 * @param {boolean} asVector
 */
Line.getDistance = (px, py, vx, vy, x, y, asVector) => abs(Line.getSignedDistance(px, py, vx, vy, x, y, asVector))

Line.prototype = {
    constructor: Line,

    /**
     * @param {number} p0x
     * @param {number} p0y
     * @param {number} p1x
     * @param {number} p1y
     */
    initN(p0x, p0y, p1x, p1y) {
        this._px = p0x
        this._py = p0y
        this._vx = p1x - p0x
        this._vy = p1y - p0y
        return this
    },

    /**
     * @param {Vector2Like} p0
     * @param {Vector2Like} p1
     */
    initP(p0, p1) {
        return this.initN(p0.x, p0.y, p1.x, p1.y)
    },

    /**
     * The start point of the line.
     */
    getP0() {
        return new Vector2(this._px, this._py)
    },
    /**
     * The end point of the line.
     */
    getP1() {
        return new Vector2(this._px + this._vx, this._py + this._vy)
    },

    /**
     * The direction of the line as a vector.
     */
    getVector() {
        return new Vector2(this._vx, this._vy)
    },

    /**
     * The length of the line.
     */
    getLength() {
        return this.getVector().length()
    },

    /**
     * @param {Line} line
     * @param {boolean} [isInfinite=false]
     * @returns the intersection point of the lines, `undefined` if the
     *     two lines are collinear, or `null` if they don't intersect.
     */
    intersect(line, isInfinite) {
        return Line.intersect(
            this._px, this._py, this._vx, this._vy,
            line._px, line._py, line._vx, line._vy,
            true, isInfinite
        )
    },

    /**
     * @param {Vector2} point
     * @param {boolean} [isInfinite=false]
     */
    getSide(point, isInfinite) {
        return Line.getSide(
            this._px, this._py, this._vx, this._vy,
            point.x, point.y, true, isInfinite
        )
    },

    /**
     * @param {Vector2} point
     */
    getDistance(point) {
        return abs(this.getSignedDistance(point))
    },

    /**
     * @param {Vector2} point
     */
    getSignedDistance(point) {
        return Line.getSignedDistance(
            this._px, this._py, this._vx, this._vy,
            point.x, point.y, true
        )
    },

    /**
     * @param {Line} line
     */
    isCollinear(line) {
        return Vector2.isCollinear(this._vx, this._vy, line._vx, line._vy)
    },

    /**
     * @param {Line} line
     */
    isOrthogonal(line) {
        return Vector2.isOrthogonal(this._vx, this._vy, line._vx, line._vy)
    },

    /**
     * @param {number} t
     */
    eval(t) {
        return new Vector2(
            this._px + Num.lerp(0, this._vx, t),
            this._py + Num.lerp(0, this._vy, t)
        )
    },

    /**
     * @param {Vector2} p
     * @param {number} _accuracy
     */
    // eslint-disable-next-line no-unused-vars
    nearest(p, _accuracy) {
        const p0 = this.getP0()
        const d = this.getVector()
        const p1 = p0.clone().add(d)
        const dotp = d.dot(p.clone().sub(p0))
        const d_squared = d.dot(d)
        let t = 0
        let distance_sq = 0
        if (dotp <= 0) {
            t = 0
            distance_sq = p.distance_squared_to(p0)
        } else if (dotp >= d_squared) {
            t = 1
            distance_sq = p.distance_squared_to(p1)
        } else {
            t = dotp / d_squared
            distance_sq = this.eval(t).sub(p).length_squared()
        }
        return { distance_sq, t }
    },
}

const abs = Math.abs
const sqrt = Math.sqrt
