import { useState, useRef, useEffect, useCallback } from 'react'
import { useDataStore } from '../../providers/dataStore/DataStoreProvider'
// @ts-ignore
import { toWorld } from '@phase-software/renderer'

export type useCanvasTagPositionProps = {
  ref: React.RefObject<HTMLDivElement>
  isTriggerBlocked?: React.MutableRefObject<boolean>

  currentPosition: React.MutableRefObject<{ x: number; y: number }>
  canvasPosition: { x: number; y: number }
  onMouseUpUpdate: (newPosition: { x: number; y: number }) => void
}

const MOUSE_MOVE_THRESHOLD = 2

export default function useCanvasTagPosition({
  ref,
  isTriggerBlocked,
  currentPosition,
  canvasPosition,
  onMouseUpUpdate
}: useCanvasTagPositionProps) {
  const [init, setInit] = useState(false)
  const [isOverlayShown, setIsOverlayShown] = useState(false)
  const isDragging = useRef(false)
  const isMoved = useRef(false)
  const previousScalePosition = useRef<{ x: number; y: number }>({ x: 0, y: 0 })
  const dataStore = useDataStore()

  const handleTagPositionUpdate = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      const { viewport } = dataStore.drawInfo.vs
      currentPosition.current = {
        x: canvasPosition.x * viewport.scale,
        y: canvasPosition.y * viewport.scale
      }
      let startPosition = { ...currentPosition.current }

      isDragging.current = true
      setIsOverlayShown(true)
      const handleMouseMove = (e: MouseEvent) => {
        if (isDragging.current && ref.current) {
          isMoved.current = true
          currentPosition.current.x += e.movementX
          currentPosition.current.y += e.movementY
          ref.current.style.transform = `translate(${currentPosition.current.x}px, ${currentPosition.current.y}px)`
        }
      }

      const handleMouseUp = (e: MouseEvent) => {
        if (isDragging.current) {
          e.stopPropagation()
          if (isMoved.current) {
            const { x, y } = currentPosition.current
            const canvasPosition = toWorld(x + viewport.x, y + viewport.y)
            onMouseUpUpdate(canvasPosition)
            if (
              isTriggerBlocked &&
              (Math.abs(currentPosition.current.x - startPosition.x) > MOUSE_MOVE_THRESHOLD ||
                Math.abs(currentPosition.current.y - startPosition.y) > MOUSE_MOVE_THRESHOLD)
            ) {
              isTriggerBlocked.current = true
            }
          }
          isMoved.current = false
          isDragging.current = false
          startPosition = { ...currentPosition.current }
          setIsOverlayShown(false)
        }
        window.removeEventListener('mousemove', handleMouseMove)
      }
      window.addEventListener('mousemove', handleMouseMove)
      window.addEventListener('mouseup', handleMouseUp, { once: true })
    },
    [canvasPosition, dataStore, onMouseUpUpdate, ref, isTriggerBlocked, currentPosition]
  )

  useEffect(() => {
    const updatePosition = () => {
      if (ref.current && dataStore?.drawInfo?.vs) {
        const { viewport } = dataStore.drawInfo.vs
        let newPosition = { ...currentPosition.current }
        if (isDragging.current) {
          const { x: prevX, y: prevY } = previousScalePosition.current
          newPosition = {
            x: prevX + newPosition.x - viewport.x,
            y: prevY + newPosition.y - viewport.y
          }
        } else {
          newPosition = {
            x: canvasPosition.x * viewport.scale,
            y: canvasPosition.y * viewport.scale
          }
        }
        previousScalePosition.current = { x: viewport.x, y: viewport.y }
        ref.current.style.transform = `translate(${newPosition.x}px, ${newPosition.y}px)`
        currentPosition.current = newPosition
      }
      setInit(true)
    }

    if (dataStore) {
      updatePosition()

      dataStore.workspace.on('scale', updatePosition)
    }
    return () => {
      if (dataStore) {
        dataStore.workspace.off('scale', updatePosition)
      }
    }
  }, [dataStore, canvasPosition, ref, currentPosition])

  return { handleTagPositionUpdate, init, isOverlayShown }
}
