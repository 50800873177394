import JSZip, { OutputType } from 'jszip'

export const b64toBlob = (base64: string, contentType: string): Blob => {
  const byteCharacters = atob(base64)
  const byteArrays = []
  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512)
    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }
    byteArrays.push(new Uint8Array(byteNumbers))
  }
  return new Blob(byteArrays, { type: contentType })
}

export const readFileAsArrayBuffer = (file: File) => {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.onload = (e) => {
      if (e.target !== null) {
        resolve(e.target.result)
      }
    }
    reader.readAsArrayBuffer(file)
  })
}

export const readFileAsDataURL = (file: File) => {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.onload = (e) => {
      if (e.target !== null) {
        resolve(e.target.result)
      }
    }
    reader.readAsDataURL(file)
  })
}

export const zipContent = async <T extends OutputType>(content: object | string, type: T) => {
  const zip = new JSZip()
  zip.file('file.phase', JSON.stringify(content))
  return zip.generateAsync({
    type,
    compression: 'DEFLATE',
    compressionOptions: {
      level: 3
    }
  })
}

export const unzipContent = async (content: string | Blob, isBase64 = true) => {
  const zip = await JSZip.loadAsync(content, { base64: isBase64 })
  const file = zip.file('file.phase')
  if (file !== null) {
    const data = await file.async('string')
    return JSON.parse(data)
  }
  return {}
}

export const calculateJSONSize = (jsonObject: object) => {
  const jsonString = JSON.stringify(jsonObject)
  const blob = new Blob([jsonString], { type: 'application/json' })
  const sizeInBytes = blob.size
  const sizeInKilobytes = sizeInBytes / 1024

  return sizeInKilobytes
}

export const formattedSize = (sizeInKilobytes: number) => {
  const sizeInMegabytes = sizeInKilobytes / 1024
  const formattedSize = sizeInMegabytes >= 1 ? `${sizeInMegabytes.toFixed(2)} MB` : `${sizeInKilobytes.toFixed(2)} KB`

  return formattedSize
}

export const fetchSVGFileFromURL = async (url: string, fileName: string): Promise<File> => {
  if (!url) {
    throw new Error('Invalid URL')
  }
  try {
    const response = await fetch(url)
    if (!response.ok) {
      throw new Error(`Failed to fetch SVG file: ${response.statusText}`)
    }

    const svgText = await response.text()
    const svgBlob = new Blob([svgText], { type: 'image/svg+xml' })
    return new File([svgBlob], fileName, { type: 'image/svg+xml' })
  } catch (error) {
    console.error(`Failed to fetch or process SVG file from URL: ${url}`, error)
    throw error
  }
}
