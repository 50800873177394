import React, { useRef, forwardRef, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'
import { isEqual } from 'lodash'

import { minmax } from '@phase-software/data-utils'
import ScrollView from '../ScrollView'

import { isSelected, getInsertAction, indentToLevel, levelToIndent, flatten, getGroupIndex } from './utils'
import ListItem, { ListItemHeight } from './ListItem'
import InsertLine from './InsertLine'

const SORT_THRESHOLD = 2

const lengthToFirstSibling = (element, list) => {
  const myIndex = list.findIndex((item) => item.id === element.id)
  const siblingIndex = list.slice(myIndex + 1).findIndex((item) => item.level <= element.level)
  return siblingIndex >= 0 ? siblingIndex : list.length - 1 - myIndex
}

const MemorizedListItem = React.memo(ListItem, (prevProps, nextProps) => {
  return (
    isEqual(prevProps.data, nextProps.data) &&
    prevProps.level === nextProps.level &&
    prevProps.selectedColor === nextProps.selectedColor &&
    prevProps.expandable === nextProps.expandable &&
    prevProps.expanded === nextProps.expanded &&
    prevProps.selected === nextProps.selected &&
    prevProps.highlighted === nextProps.highlighted &&
    prevProps.parentSelected === nextProps.parentSelected &&
    prevProps.prevSelected === nextProps.prevSelected &&
    prevProps.nextSelected === nextProps.nextSelected &&
    prevProps.isMask === nextProps.isMask &&
    prevProps.maskLength === nextProps.maskLength &&
    prevProps.align === nextProps.align &&
    prevProps.viewAnchor === nextProps.viewAnchor &&
    prevProps.disableScrollIntoView === nextProps.disableScrollIntoView
  )
})

const List = forwardRef(
  (
    {
      className,
      data,
      flatten,
      reversed,
      selectedColor,
      root,
      selectedList,
      highlightedList,
      animatedElementMap,
      sortable,
      multiSelectable,
      renderItem,
      onItemHover,
      onExpand,
      getInheritProps,
      onSelect,
      onSort,
      onContextMenu,
      align = 'center',
      viewAnchor,
      disableScrollIntoView,
      ...rest
    },
    forwardRef
  ) => {
    const sortingRef = useRef(false)
    const lastSelectRef = useRef(null)
    const lineRef = useRef()
    const ref = useRef()
    useImperativeHandle(forwardRef, () => ref.current)
    const selectedSet = new Set(selectedList)
    const list = flatten(data, root, selectedSet, reversed, getInheritProps)
    const handleMouseMove = (e) => {
      if (e.target === e.currentTarget && highlightedList.length) {
        onItemHover(null)
      }
    }
    const handleMouseLeave = () => {
      onItemHover(null)
    }

    const handleSelect = (e, id) => {
      let toggleSelect = e.metaKey || e.ctrlKey
      let rangeSelect = e.shiftKey
      if (!multiSelectable) {
        rangeSelect = false
        toggleSelect = false
      }
      const selectedItemList = Array.from(ref.current.children)
        .filter((item) => item.dataset.selected)
        .map((item) => item.dataset.id)
      const selectedItemSet = new Set(selectedItemList)

      if (toggleSelect) {
        if (selectedItemSet.has(id)) {
          const newSelectedList = selectedItemList.filter((o) => o !== id)
          onSelect(newSelectedList, id, true)
        } else if (selectedSet.has(id)) {
          let item = list.find((o) => o.id === id)
          const removeSet = new Set([item.id])
          const queue = [...item.children]
          while (queue.length) {
            const id = queue.pop()
            removeSet.add(id)
            item = list.find((o) => o.id === id)
            queue.push(...item.children)
          }
          const newSelectedList = selectedItemList.filter((o) => !removeSet.has(o))
          onSelect(newSelectedList, id, true)
        } else {
          onSelect([...selectedItemList, id], id, true)
        }
      } else if (rangeSelect && lastSelectRef.current !== null) {
        const fromIndex = list.findIndex((o) => o.id === lastSelectRef.current)
        const toIndex = list.findIndex((o) => o.id === id)
        const startIndex = Math.min(fromIndex, toIndex)
        const endIndex = Math.max(fromIndex, toIndex)
        const rangeList = list.slice(startIndex, endIndex + 1).map((o) => o.id)
        const uniqueList = Array.from(new Set([...rangeList, ...selectedItemList]))
        onSelect(uniqueList, id)
      } else {
        onSelect([id], id)
      }
      lastSelectRef.current = id
    }

    const handleMouseDown = (e) => {
      if (e.button) {
        return
      }
      // avoid conflict with toggle/range select
      if (e.ctrlKey || e.metaKey || e.shiftKey) {
        return
      }
      if (e.currentTarget === e.target) {
        onSelect([])
        return
      }

      if (!sortable) {
        return
      }

      // TODO: check with shiny, sometime it will get null
      const rowHeight = e.target.closest('.js-list-item')
        ? e.target.closest('.js-list-item').offsetHeight
        : ListItemHeight

      const $list = e.currentTarget
      const listRect = $list.getBoundingClientRect()
      Object.assign(lineRef.current.style, {
        left: `${listRect.left + 4}px`,
        right: `${window.innerWidth - (listRect.left + listRect.width - 16)}px`
      })
      const minX = listRect.left
      const top = listRect.top
      const bottom = listRect.top + listRect.height
      let itemList = Array.from($list.querySelectorAll('.js-list-item'))
      let autoExpandTimer = null
      let autoExpandId
      let moved = false
      let groupIdx = -1
      let index
      let pos
      let action
      const mousePos = { x: 0, y: 0 }
      const startPos = { x: e.clientX, y: e.clientY }

      const handleMove = (e) => {
        mousePos.x = e.clientX
        mousePos.y = e.clientY
        if (!sortingRef.current) {
          ref.current.classList.add('sorting')
        }
        sortingRef.current = true

        // auto scrolling if overflow
        if (e.clientY < top) {
          $list.scrollTop -= top - e.clientY
        }
        if (e.clientY > bottom) {
          $list.scrollTop += e.clientY - bottom
        }

        if (Math.abs(e.clientY - startPos.y) > SORT_THRESHOLD) {
          updateReorderStyles(mousePos)
          moved = true
        }
      }

      const handleScroll = () => {
        updateReorderStyles(mousePos)
      }

      const updateReorderStyles = (mousePos) => {
        const scrollIndex = Math.floor($list.scrollTop / rowHeight)
        const y = minmax(mousePos.y, top, bottom)
        const targetIndex = (y - top + ($list.scrollTop % rowHeight)) / rowHeight
        index = scrollIndex + Math.floor(targetIndex)
        pos = targetIndex % 1

        if (index >= itemList.length) {
          index = itemList.length - 1
          pos = 1
        }
        let item = itemList[index]
        let nextItem = itemList[index + 1]
        action = getInsertAction(item.dataset.type, pos)
        if (action === 'insert-before') {
          index--
          item = itemList[index]
          nextItem = itemList[index + 1]
          pos = 1 - pos
          action = 'insert-after'
        }
        if (isSelected(item?.dataset) && isSelected(nextItem?.dataset)) {
          action = 'none'
        }

        // auto expand
        if (item) {
          if (!autoExpandId || autoExpandId !== item.dataset.id) {
            clearTimeout(autoExpandTimer)
            autoExpandId = item.dataset.id
            autoExpandTimer = null
          }
          if (!autoExpandTimer && item.dataset.expandable && !item.dataset.expanded) {
            autoExpandTimer = setTimeout(() => {
              onExpand(autoExpandId, true)
              setTimeout(() => {
                itemList = Array.from($list.querySelectorAll('.js-list-item'))
                updateReorderStyles(mousePos)
              })
            }, 1000)
          }
        }

        // calc horizontal indent
        let relLevel = 0
        if (item) {
          relLevel = Number(item.dataset.level)
          if (isSelected(item.dataset)) {
            relLevel--
          }
        }
        const minLevel = nextItem ? Number(nextItem.dataset.level) : 0
        const maxLevel = Math.max(relLevel, minLevel)
        const level = indentToLevel(mousePos.x - minX)
        const targetLevel = minmax(level, minLevel, maxLevel)
        const indent = levelToIndent(targetLevel)

        // update insert line position
        const lineY = (index - scrollIndex + 1) * rowHeight
        Object.assign(lineRef.current.style, {
          transform: `translateY(calc(${lineY + top}px - 50%))`,
          marginLeft: `${indent}px`,
          backgroundColor: '#7C7B7B',
          height: '1px',
          display: action === 'insert-after' || (action === 'append' && item.dataset.expanded) ? 'block' : 'none'
        })

        // update target group and apply highlight style
        const newGroupIndex = getGroupIndex(itemList, index, pos, targetLevel)
        if (newGroupIndex >= 0) {
          $list.children[newGroupIndex].classList.add('group-highlight')
        }
        if (groupIdx !== newGroupIndex && groupIdx >= 0) {
          $list.children[groupIdx].classList.remove('group-highlight')
        }
        groupIdx = newGroupIndex
        moved = true
      }

      const handleMouseUp = () => {
        clearTimeout(autoExpandTimer)
        autoExpandTimer = null

        if (moved && index >= 0 && groupIdx >= 0) {
          const selectedList = itemList.filter((item) => item.dataset.selected).map((item) => item.dataset.id)
          const containerId = itemList[groupIdx].dataset.id
          const targetId = itemList[index].dataset.id
          if (containerId === targetId) {
            action = 'append'
          }
          onSort(selectedList, containerId, targetId, action)
        }
        lineRef.current.style.display = 'none'
        sortingRef.current = false
        ref.current.classList.remove('sorting')
        $list.querySelectorAll('.group-highlight').forEach((el) => el.classList.remove('group-highlight'))
        document.removeEventListener('mousemove', handleMove, { capture: true })
        ref.current.removeEventListener('scroll', handleScroll)
      }
      ref.current.addEventListener('scroll', handleScroll)
      document.addEventListener('mousemove', handleMove, { capture: true })
      document.addEventListener('mouseup', handleMouseUp, {
        capture: true,
        once: true
      })
    }

    const highlightedSet = new Set(highlightedList)
    return (
      <ScrollView
        ref={ref}
        className={`js-list group select-none ${className}`}
        onMouseMove={handleMouseMove}
        onMouseDown={handleMouseDown}
        onMouseLeave={handleMouseLeave}
        {...rest}
      >
        {list.map((item, idx) => {
          const highlighted = highlightedSet.has(item.id)
          const expandable = item.type === 'group' && item.children.length !== 0
          const prevSelected = isSelected(list[idx - 1])
          const nextSelected = isSelected(list[idx + 1])
          const isMask = item.isMask
          const maskLength = lengthToFirstSibling(item, list)
          const hasAnimation = !!animatedElementMap.get(item.id)
          return (
            <MemorizedListItem
              selectedColor={selectedColor}
              key={item.id}
              type={item.type}
              expanded={item.expanded}
              selected={item.selected}
              expandable={expandable}
              parentSelected={item.parentSelected}
              highlighted={!sortingRef.current && highlighted}
              prevSelected={prevSelected}
              nextSelected={nextSelected}
              level={item.level}
              renderItem={renderItem}
              onHover={onItemHover}
              onExpand={onExpand}
              onSelect={handleSelect}
              onContextMenu={onContextMenu}
              data={item}
              calcNeedDisableReselect={(isRightButton) => selectedList.length > 1 && isRightButton}
              isMask={isMask}
              hasAnimation={hasAnimation}
              maskLength={maskLength}
              align={align}
              viewAnchor={viewAnchor}
              disableScrollIntoView={disableScrollIntoView}
            />
          )
        })}
        {sortable && <InsertLine ref={lineRef} />}
        {/* an extra padding for scrolling down to the bottom-most of the list */}
        <div className="pb-8" />
      </ScrollView>
    )
  }
)

List.displayName = 'List'
List.propTypes = {
  root: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  reversed: PropTypes.bool,
  className: PropTypes.string,
  selectedList: PropTypes.array,
  highlightedList: PropTypes.array,
  animatedElementMap: PropTypes.instanceOf(Map),
  renderItem: PropTypes.elementType.isRequired,
  sortable: PropTypes.bool,
  multiSelectable: PropTypes.bool,
  flatten: PropTypes.func,
  onItemHover: PropTypes.func,
  onSelect: PropTypes.func,
  onExpand: PropTypes.func,
  onSort: PropTypes.func,
  onContextMenu: PropTypes.func,
  getInheritProps: PropTypes.func,
  selectedColor: PropTypes.oneOf(['primary', 'secondary']),
  align: PropTypes.oneOf(['center', 'start', 'end', 'nearest']),
  viewAnchor: PropTypes.oneOf(['top', 'bottom']),
  disableScrollIntoView: PropTypes.bool
}

List.defaultProps = {
  className: '',
  selectedList: [],
  highlightedList: [],
  animatedElementMap: new Map(),
  sortable: false,
  multiSelectable: true,
  reversed: true,
  flatten: flatten,
  selectedColor: 'primary',
  getInheritProps: () => {},
  onItemHover: () => {},
  onSort: () => {},
  onSelect: () => {},
  onExpand: () => {},
  onContextMenu: () => {},
  align: 'center',
  viewAnchor: 'top',
  disableScrollIntoView: false
}

export default List
