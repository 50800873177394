import React, { useCallback, useMemo, useRef } from 'react'
import { EffectType, LayerType } from '@phase-software/types'
import { useEditor, useEditorActions } from '../../../providers/dataStore/EditorProvider'
import { TutorialType } from '../../../tutorials'
import { useSetTutorial } from '../../../providers/TutorialProvider'
import { useSetModal } from '../../../providers/ModalProvider'
import { useFill } from '../../../providers/dataStore/FillProvider'
import { useStroke } from '../../../providers/dataStore/StrokeProvider'
import { useShadow } from '../../../providers/dataStore/ShadowProvider'
import { useInnerShadow } from '../../../providers/dataStore/InnerShadowProvider'
import { usePaint } from '../../../providers/dataStore/PaintProvider'
import { useSelectEffect } from '../../../providers/dataStore/EffectProvider'
import KeyFrame from '../../shared/KeyFrame'
import { PAINT_PROPS_SET } from '../../../constant'

const DEFAULT_TYPE = 'DEFAULT'

const LAYER_TYPE_TO_SELECTOR = {
  [LayerType.FILL]: useFill,
  [LayerType.STROKE]: useStroke,
  [LayerType.SHADOW]: useShadow,
  [LayerType.INNER_SHADOW]: useInnerShadow
}

export type PropertyStateProps = {
  name: string
  layerItemId?: string
  effectItemId?: string
  effectType?: EffectType
  className?: string
  layerType?: number
  rightComponent?: React.ReactNode
  hoverContent?: boolean
  hoverWrap?: boolean
  ignoreHover?: boolean
}

const PropertyState = ({
  name = '',
  layerItemId = '',
  effectItemId,
  effectType = EffectType.TRIM_PATH,
  className = '',
  layerType,
  rightComponent,
  hoverContent,
  hoverWrap,
  ignoreHover
}: PropertyStateProps) => {
  const { setPropertyStates, setLayerPropertyStates, setEffectPropertyStates } = useEditorActions()
  const { openModal } = useSetModal('PropertyStateContextMenu')
  const { startTutorial } = useSetTutorial()
  const timerRef = useRef<NodeJS.Timeout | null>(null)

  let type: 'EXPLICIT' | 'INITIAL' | 'TWEEN' | 'DEFAULT'
  const stateName = `${name}State`
  const layerProps = usePaint((o) => o[layerItemId])
  const propState = useEditor((o) => o[stateName])
  const effectProps = useSelectEffect((o) => o[effectType])
  if (layerItemId && PAINT_PROPS_SET.has(stateName) && layerProps) {
    type = layerProps[stateName]
  } else if (layerItemId && layerType !== undefined) {
    const layerProps = LAYER_TYPE_TO_SELECTOR[layerType as keyof typeof LAYER_TYPE_TO_SELECTOR](
      (o: any) => o[layerItemId]
    )
    type = layerProps[stateName]
  } else if (effectItemId) {
    type = effectProps[stateName]
  } else {
    type = propState
  }
  type = type || DEFAULT_TYPE

  const setProps = useMemo(() => {
    if (layerItemId) {
      return setLayerPropertyStates.bind(null, layerItemId)
    } else if (effectItemId) {
      return setEffectPropertyStates.bind(null, effectItemId)
    }

    return setPropertyStates
  }, [layerItemId, effectItemId, setPropertyStates, setLayerPropertyStates, setEffectPropertyStates])

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      if (type === 'EXPLICIT' || type === 'INITIAL') {
        setProps({ [name]: 'TWEEN' })
      } else {
        setProps({ [name]: 'EXPLICIT' })
      }
      e.preventDefault()
      e.stopPropagation()
    },
    [setProps, name, type]
  )

  const handleContextMenu = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      openModal({ prop: name, layerItemId, effectItemId, type, cursorPosition: { left: e.clientX, top: e.clientY } })
    },
    [openModal, layerItemId, effectItemId, type, name]
  )

  const handleMouserEnter = useCallback(
    (e: { currentTarget: { classList: { add: (arg0: string) => void } } }) => {
      e.currentTarget.classList.add('pt-kf-hover-indicator')
      timerRef.current = setTimeout(() => {
        startTutorial(TutorialType.INDICATOR)
      }, 1000)
    },
    [startTutorial]
  )

  const handleMouseLeave = useCallback((e: React.MouseEvent) => {
    e.currentTarget.classList.remove('pt-kf-hover-indicator')
    clearTimeout(timerRef.current ?? undefined)
  }, [])

  if (
    ((type === 'EXPLICIT' || type === 'INITIAL') && !hoverContent) ||
    (type === 'TWEEN' && hoverWrap) ||
    (ignoreHover && type !== 'DEFAULT')
  ) {
    return (
      <div
        onMouseDown={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
        className={`cursor-pointer w-16 ${className}`}
        onMouseEnter={handleMouserEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
        onContextMenu={handleContextMenu}
      >
        <KeyFrame type={type} isIndicator />
      </div>
    )
  }
  if (rightComponent) {
    return <div className={`${className}`}>{rightComponent}</div>
  }

  return null
}

export default React.memo(PropertyState)
