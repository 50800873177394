import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Icon from '../Icon'
import { color } from '../utils'

const Summary = styled.summary`
  display: flex;
  align-items: center;
  cursor: pointer;
  outline: 0;
  height: 36px;
  padding: 12px;
  background: ${color('neutrals', 70)};
  &::-webkit-details-marker {
    display: none;
  }
  & > .icon {
    margin-right: 4px;
  }
`

const Content = styled.div`
  padding: 12px;
  border-top: 1px solid ${color('neutrals', 50)};
`

const Collapse = ({ summary, children, open }) => {
  const [expanded, setExpanded] = useState(open)
  const toggleExpanded = (e) => setExpanded(e.target.open)
  useEffect(() => {
    setExpanded(open)
  }, [open, setExpanded])
  return (
    <details open={expanded} onToggle={toggleExpanded}>
      <Summary>
        <Icon name={expanded ? 'TriangleDown' : 'TriangleRight'} size="s" />
        {summary}
      </Summary>
      <Content>{children}</Content>
    </details>
  )
}

Collapse.propTypes = {
  open: PropTypes.bool,
  summary: PropTypes.node,
  children: PropTypes.node
}
export default Collapse
