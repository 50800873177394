export const WORKSPACE_INDEX = '/:workspaceType/:workspaceSlug'
export const WORKSPACE_WILDCARD = '/:workspaceType/:workspaceSlug/*'
export const WORKSPACE_DRAFTS = '/:workspaceType/:workspaceSlug/drafts'
export const WORKSPACE_ARCHIVE = '/:workspaceType/:workspaceSlug/archive'
export const WORKSPACE_TEMPLATE = '/:workspaceType/:workspaceSlug/template'
export const WORKSPACE_TEMPLATE_CLONE = '/:workspaceType/:workspaceSlug/template/:templateId/clone'
export const WORKSPACE_PROJECT = '/:workspaceType/:workspaceSlug/p/:projectId'
export const WORKSPACE_SETTINGS_INDEX = '/:workspaceType/:workspaceSlug/settings'
export const PERSONAL_SHARED = '/u/:workspaceSlug/shared'
export const PERSONAL_SETTINGS_INDEX = '/u/:workspaceSlug/settings'
export const PERSONAL_SETTINGS_PROFILE = '/u/:workspaceSlug/settings/profile'
export const PERSONAL_SETTINGS_CHANGE_PASSWORD = '/u/:workspaceSlug/settings/password'
export const TEAM_SETTINGS_INDEX = '/t/:workspaceSlug/settings'
export const TEAM_SETTINGS_GENERAL = '/t/:workspaceSlug/settings/general'
export const TEAM_SETTINGS_GENERAL_VIEW_MODE = '/t/:workspaceSlug/settings/general/view'
export const TEAM_SETTINGS_MEMBERS = '/t/:workspaceSlug/settings/members'
export const FILE_INDEX = '/f/:fileId'
export const FILE_MODE_SELECTOR = '/f/:fileId/:mode'
export const FILE_EDIT_MODE = '/f/:fileId/edit'
export const FILE_VIEW_MODE = '/f/:fileId/view'

export const JOIN_TEAM = '/t/:workspaceSlug/join'
export const JOIN_PROJECT_COLLABORATOR = '/t/:workspaceSlug/p/:projectId/join'
export const JOIN_FILE_COLLABORATOR = '/f/join'

export const TEMPLATE_CLONE = '/f/:templateId/clone'
