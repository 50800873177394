import { Vector2 } from './Vector2'

/** @typedef {import('./Vector2').Vector2Like} Vector2Like */

const tmp_vec = new Vector2()

/**
 * Rectangle object is an area defined by its position, as indicated by its top-left corner
 * point (x, y) and by its width and its height.
 * @param {number} [x=0] - The X coordinate of the upper-left corner of the rectangle
 * @param {number} [y=0] - The Y coordinate of the upper-left corner of the rectangle
 * @param {number} [width=0] - The overall width of this rectangle
 * @param {number} [height=0] - The overall height of this rectangle
 */
export function Rect2(x = 0, y = 0, width = 0, height = 0) {
    /** @type {number} */
    this.x = x
    /** @type {number} */
    this.y = y
    /** @type {number} */
    this.width = width
    /** @type {number} */
    this.height = height
}
Rect2.prototype = {
    constructor: Rect2,

    /**
     * @param {number} p_x
     * @param {number} p_y
     * @param {number} p_width
     * @param {number} p_height
     */
    set(p_x = 0, p_y = 0, p_width = 0, p_height = 0) {
        this.x = p_x
        this.y = p_y
        this.width = p_width
        this.height = p_height
        return this
    },

    /**
     * @param {Generator<Rect2>} generator
     */
    setFrom(generator) {
        this.set(0, 0, 0, 0)

        const first = generator.next().value
        if (first) this.copy(first)

        for (const rect of generator) {
            this.merge_with(rect)
        }

        return this
    },

    get left() {
        return this.x
    },

    get right() {
        return this.x + this.width
    },

    get top() {
        return this.y
    },

    get bottom() {
        return this.y + this.height
    },

    get center() {
        return new Vector2(this.x + this.width / 2, this.y + this.height / 2)
    },

    get topCenter() {
        return new Vector2(this.x + this.width / 2, this.y)
    },

    get leftCenter() {
        return new Vector2(this.x, this.y + this.height / 2)
    },

    get rightCenter() {
        return new Vector2(this.x + this.width, this.y + this.height / 2)
    },

    get bottomCenter() {
        return new Vector2(this.x + this.width / 2, this.y + this.height)
    },

    get topLeft() {
        return new Vector2(this.x, this.y)
    },

    get topRight() {
        return new Vector2(this.x + this.width, this.y)
    },

    get bottomLeft() {
        return new Vector2(this.x, this.y + this.height)
    },

    get bottomRight() {
        return new Vector2(this.x + this.width, this.y + this.height)
    },

    get w() { return this.width },

    set w(v) { this.width = v },

    get h() { return this.height },

    set h(v) { this.height = v },

    getCenter() {
        return new Vector2(this.x + this.width * 0.5, this.y + this.height * 0.5)
    },

    clone() {
        return new Rect2(this.x, this.y, this.width, this.height)
    },

    /**
     * Copies another rectangle to this one.
     * @param {Rect2} rectangle - The rectangle to copy.
     */
    copy(rectangle) {
        this.x = rectangle.x
        this.y = rectangle.y
        this.width = rectangle.width
        this.height = rectangle.height

        return this
    },

    is_zero() {
        return this.x === 0 && this.y === 0 && this.width === 0 && this.height === 0
    },

    /**
     * @param {Rect2} rect
     * @returns {boolean}
     */
    equals(rect) {
        return this.x === rect.x && this.y === rect.y && this.width === rect.width && this.height === rect.height
    },

    /**
     * Checks whether the x and y coordinates given are contained within this Rectangle
     *
     * @param {number | Vector2Like} x - The X coordinate of the point to test
     * @param {number} y - The Y coordinate of the point to test
     * @returns {boolean}
     */
    contains(x, y) {
        let xx, yy
        if (y === undefined) {
            xx = x.x
            yy = x.y
        } else {
            xx = x
            yy = y
        }

        if (this.width <= 0 || this.height <= 0) {
            return false
        }

        if (xx >= this.x && xx <= this.x + this.width) {
            if (yy >= this.y && yy <= this.y + this.height) {
                return true
            }
        }

        return false
    },

    /**
     * @param {Rect2} rect
     */
    containsRect(rect) {
        const x = rect.x
        const y = rect.y
        return x >= this.x && y >= this.y
            && x + rect.width <= this.x + this.width
            && y + rect.height <= this.y + this.height
    },

    has_no_area() {
        return this.width <= 0 || this.height <= 0
    },

    /**
     * @param {Vector2Like} p_point
     * @returns {boolean}
     */
    has_point(p_point) {
        if (p_point.x < this.x) {
            return false
        }
        if (p_point.y < this.y) {
            return false
        }

        if (p_point.x >= (this.x + this.width)) {
            return false
        }
        if (p_point.y >= (this.y + this.height)) {
            return false
        }

        return true
    },

    get_area() {
        return this.width * this.height
    },

    /**
     * Returns new Rect2 with absolute values.
     * @returns {Rect2}
     */
    abs() {
        return this.clone().abs_to()
    },

    /**
     */
    abs_to() {
        this.x += Math.min(this.width, 0)
        this.y += Math.min(this.height, 0)
        this.width = Math.abs(this.width)
        this.height = Math.abs(this.height)
        return this
    },

    /**
     * Returns new Rect2.
     * @param {Rect2} rect
     * @returns {Rect2}
     */
    clip(rect) {
        return this.clone().clip_by(rect)
    },

    /**
     * @param {Rect2} rect
     */
    clip_by(rect) {
        if (!this.intersects(rect)) {
            return this.set(0, 0, 0, 0)
        }

        const x = Math.max(rect.x, this.x)
        const y = Math.max(rect.y, this.y)

        const p_rect_end_x = rect.x + rect.width
        const p_rect_end_y = rect.y + rect.height
        const end_x = this.x + this.width
        const end_y = this.y + this.height

        this.x = x
        this.y = y
        this.width = Math.min(p_rect_end_x, end_x) - x
        this.height = Math.min(p_rect_end_y, end_y) - y

        return this
    },

    /**
     * @param {Rect2} rect
     * @returns {boolean}
     */
    encloses(rect) {
        return (rect.x >= this.x) && (rect.y >= this.y)
            &&
            ((rect.x + rect.width) < (this.x + this.width))
            &&
            ((rect.y + rect.height) < (this.y + this.height))
    },

    /**
     * Pads the rectangle making it grow in all directions.
     * Returns new Rect2.
     *
     * @param {number} p_by - The horizontal padding amount.
     * @returns {Rect2}
     */
    grow(p_by) {
        return this.clone().grow_to(p_by)
    },

    /**
     * Pads the rectangle making it grow in all directions.
     *
     * @param {number} p_by - The horizontal padding amount.
     */
    grow_to(p_by) {
        this.x -= p_by
        this.y -= p_by
        this.width += p_by * 2
        this.height += p_by * 2
        return this
    },

    /**
     * @param {number} p_left
     * @param {number} p_top
     * @param {number} p_right
     * @param {number} p_bottom
     * @returns {Rect2}
     */
    grow_individual(p_left, p_top, p_right, p_bottom) {
        const g = this.clone()
        g.x -= p_left
        g.y -= p_top
        g.width += (p_left + p_right)
        g.height += (p_top + p_bottom)
        return g
    },

    /**
     * Returns new Rect2.
     * @param {Vector2Like} p_vector
     * @returns {Rect2}
     */
    expand(p_vector) {
        return this.clone().expand_to(p_vector)
    },

    /**
     * Returns new Rect2.
     * @param {number} x
     * @param {number} y
     * @returns {Rect2}
     */
    expand_n(x, y) {
        return this.clone().expand_to(tmp_vec.set(x, y))
    },

    /**
     * @param {Vector2Like} p_vector
     */
    expand_to(p_vector) {
        const begin = expand_to_v1.set(this.x, this.y)
        const end = expand_to_v2.set(this.x + this.width, this.y + this.height)

        if (p_vector.x < begin.x) {
            begin.x = p_vector.x
        }
        if (p_vector.y < begin.y) {
            begin.y = p_vector.y
        }

        if (p_vector.x > end.x) {
            end.x = p_vector.x
        }
        if (p_vector.y > end.y) {
            end.y = p_vector.y
        }

        this.x = begin.x
        this.y = begin.y
        this.width = end.x - begin.x
        this.height = end.y - begin.y

        return this
    },

    /**
     * @param {number} x
     * @param {number} y
     */
    expand_to_n(x, y) {
        return this.expand_to(tmp_vec.set(x, y))
    },

    /**
     * @param {number} x
     * @param {number} y
     */
    offset(x, y) {
        this.x += x
        this.y += y
        return this
    },

    /**
     * Fits this rectangle around the passed one.
     *
     * @param {Rect2} p_rect - The rectangle to fit.
     */
    fit_to(p_rect) {
        if (this.x < p_rect.x) {
            this.width += this.x
            if (this.width < 0) {
                this.width = 0
            }

            this.x = p_rect.x
        }

        if (this.y < p_rect.y) {
            this.height += this.y
            if (this.height < 0) {
                this.height = 0
            }
            this.y = p_rect.y
        }

        if (this.x + this.width > p_rect.x + p_rect.width) {
            this.width = p_rect.width - this.x
            if (this.width < 0) {
                this.width = 0
            }
        }

        if (this.y + this.height > p_rect.y + p_rect.height) {
            this.height = p_rect.height - this.y
            if (this.height < 0) {
                this.height = 0
            }
        }

        return this
    },

    /**
     * Merge the given rectangle and return a new one.
     * Returns new Rect2.
     *
     * @param {Rect2} p_rect - The rectangle to merge.
     * @returns {Rect2}
     */
    merge(p_rect) {
        return this.clone().merge_with(p_rect)
    },

    /**
     * Merge this rectangle with the passed rectangle
     *
     * @param {Rect2} p_rect - The rectangle to merge.
     */
    merge_with(p_rect) {
        const x1 = Math.min(this.x, p_rect.x)
        const x2 = Math.max(this.x + this.width, p_rect.x + p_rect.width)
        const y1 = Math.min(this.y, p_rect.y)
        const y2 = Math.max(this.y + this.height, p_rect.y + p_rect.height)

        this.x = x1
        this.width = x2 - x1
        this.y = y1
        this.height = y2 - y1

        return this
    },

    /**
     * @param {Rect2} rect
     * @param {number} eps
     */
    intersects(rect, eps = 0) {
        return (
            rect.x + rect.width > this.x - eps
            &&
            rect.y + rect.height > this.y - eps
            &&
            rect.x < this.x + this.width + eps
            &&
            rect.y < this.y + this.height + eps
        )
    },

    /**
     * @param {Vector2} p_from
     * @param {Vector2} p_to
     * @param {Vector2} [r_pos]
     * @param {Vector2} [r_normal]
     * @returns {boolean}
     */
    intersects_segment(p_from, p_to, r_pos, r_normal) {
        let min = 0, max = 1
        let axis = 0
        let sign = 0

        {
            const seg_from = p_from.x
            const seg_to = p_to.x
            const box_begin = this.x
            const box_end = box_begin + this.width
            let cmin = 0, cmax = 0
            let csign = 0

            if (seg_from < seg_to) {
                if (seg_from > box_end || seg_to < box_begin) {
                    return false
                }
                const length = seg_to - seg_from
                cmin = (seg_from < box_begin) ? ((box_begin - seg_from) / length) : 0
                cmax = (seg_to > box_end) ? ((box_end - seg_from) / length) : 1
                csign = -1
            } else {
                if (seg_to > box_end || seg_from < box_begin)
                {return false}
                const length = seg_to - seg_from
                cmin = (seg_from > box_end) ? (box_end - seg_from) / length : 0
                cmax = (seg_to < box_begin) ? (box_begin - seg_from) / length : 1
                csign = 1
            }

            if (cmin > min) {
                min = cmin
                axis = 0
                sign = csign
            }
            if (cmax < max)
            {max = cmax}
            if (max < min)
            {return false}
        }
        {
            const seg_from = p_from.y
            const seg_to = p_to.y
            const box_begin = this.y
            const box_end = box_begin + this.height
            let cmin = 0, cmax = 0
            let csign = 0

            if (seg_from < seg_to) {
                if (seg_from > box_end || seg_to < box_begin) {
                    return false
                }
                const length = seg_to - seg_from
                cmin = (seg_from < box_begin) ? ((box_begin - seg_from) / length) : 0
                cmax = (seg_to > box_end) ? ((box_end - seg_from) / length) : 1
                csign = -1
            } else {
                if (seg_to > box_end || seg_from < box_begin)
                {return false}
                const length = seg_to - seg_from
                cmin = (seg_from > box_end) ? (box_end - seg_from) / length : 0
                cmax = (seg_to < box_begin) ? (box_begin - seg_from) / length : 1
                csign = 1
            }

            if (cmin > min) {
                min = cmin
                axis = 1
                sign = csign
            }
            if (cmax < max)
            {max = cmax}
            if (max < min)
            {return false}
        }

        const rel = p_to.clone().subtract(p_from)

        if (r_normal) {
            r_normal.set(0, 0)
            if (axis === 0) {
                r_normal.x = sign
            } else {
                r_normal.y = sign
            }
        }

        if (r_pos) {
            r_pos.copy(p_from).add(rel.scale(min))
        }

        return true
    },

    /**
     * @param {Rect2} other
     */
    overlaps(other) {
        return !(
            (this.actualBottom() <= other.actualTop())
            ||
            (this.actualTop() >= other.actualBottom())
            ||
            (this.actualLeft() >= other.actualRight())
            ||
            (this.actualRight() <= other.actualLeft())
        )
    },

    actualLeft() {
        return Math.min(this.x, this.x + this.w)
    },
    actualTop() {
        return Math.min(this.y, this.y + this.h)
    },
    actualRight() {
        return Math.max(this.x, this.x + this.w)
    },
    actualBottom() {
        return Math.max(this.y, this.y + this.h)
    },

    actualTopLeft() {
        return new Vector2(this.actualLeft(), this.actualTop())
    },
    actualTopRight() {
        return new Vector2(this.actualRight(), this.actualTop())
    },
    actualBottomLeft() {
        return new Vector2(this.actualLeft(), this.actualBottom())
    },
    actualBottomRight() {
        return new Vector2(this.actualRight(), this.actualBottom())
    },

    hash(epsilon = 0.00001) {
        const precision = 1 / epsilon
        return `${Math.round(this.x * precision)}-${Math.round(this.y * precision)}-${Math.round(this.width * precision)}-${Math.round(this.height * precision)}`
    }
}

const expand_to_v1 = new Vector2()
const expand_to_v2 = new Vector2()
