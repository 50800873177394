import { useCallback, useMemo, useState } from 'react'

import { Order_By as OrderBy, useGetTemplatesQuery } from '../../generated/graphql'

const LIMIT = 5
const DEFAULT_SORT_BY = { feature: OrderBy.DescNullsLast }

type useTemplateListProps = {
  projectId: string
}

const useFeaturedTemplateList = ({ projectId }: useTemplateListProps) => {
  const [limit, setLimit] = useState(LIMIT)

  const {
    data: templatesData,
    loading: isLoadingTemplates,
    fetchMore,
    refetch: refetchTemplates
  } = useGetTemplatesQuery({
    variables: {
      projectId,
      orderBy: DEFAULT_SORT_BY,
      limit: limit,
      offset: 0
    },
    skip: !projectId
  })

  const templates = useMemo(() => templatesData?.files ?? [], [templatesData])

  const fetchMoreTemplates = useCallback(() => {
    const currentLength = templates.length

    fetchMore({
      variables: {
        offset: currentLength,
        limit: LIMIT
      }
    }).then((fetchMoreResult) => {
      setLimit(currentLength + fetchMoreResult.data.files.length)
    })
  }, [fetchMore, templates.length])

  return {
    featuredTemplates: templates,
    hasTemplates: !!templates.length,
    isLoadingTemplates,
    fetchMoreTemplates,
    refetchTemplates
  }
}

export default useFeaturedTemplateList
