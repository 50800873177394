import add from './images/Arrow-add@1x.png'
import add2x from './images/Arrow-add@2x.png'
import arrowMove from "./images/Arrow-move@1x.png"
import arrowMove2x from "./images/Arrow-move@2x.png"
import convertPath from "./images/Convert-path@1x.png"
import convertPath2x from "./images/Convert-path@2x.png"
import crossMove from './images/Cross-move@1x.png'
import crossMove2x from './images/Cross-move@2x.png'
import crossScale from './images/Cross-scale@1x.png'
import crossScale2x from './images/Cross-scale@2x.png'
import crosshair from './images/Crosshair@1x.png'
import crosshair2x from './images/Crosshair@2x.png'
import duplicate from './images/Duplicate@1x.png'
import duplicate2x from './images/Duplicate@2x.png'
import grab from './images/Grab@1x.png'
import grab2x from './images/Grab@2x.png'
import grabbing from './images/Grabbing@1x.png'
import grabbing2x from './images/Grabbing@2x.png'
import move from './images/Move@1x.png'
import move2x from './images/Move@2x.png'
import penAdd from './images/Pen-add@1x.png'
import penAdd2x from './images/Pen-add@2x.png'
import penClose from './images/Pen-close@1x.png'
import penClose2x from './images/Pen-close@2x.png'
import penDefault from './images/Pen-default@1x.png'
import penDefault2x from './images/Pen-default@2x.png'
import penOpen from './images/Pen-open@1x.png'
import penOpen2x from './images/Pen-open@2x.png'
import resize from './images/Ew-resize@1x.png'
import resize2x from './images/Ew-resize@2x.png'
import rotate from './images/Rotate@1x.png'
import rotate2x from './images/Rotate@2x.png'
import scale from './images/Ew-scale@1x.png'
import scale2x from './images/Ew-scale@2x.png'
import comment from './images/Comment@1x.png'
import comment2x from './images/Comment@2x.png'

const cursorImages = {
    add: {
        '1x': add,
        '2x': add2x
    },
    arrowMove: {
        '1x': arrowMove,
        '2x': arrowMove2x
    },
    convertPath: {
        '1x': convertPath,
        '2x': convertPath2x
    },
    crossMove: {
        '1x': crossMove,
        '2x': crossMove2x
    },
    crossScale: {
        '1x': crossScale,
        '2x': crossScale2x
    },
    crosshair: {
        '1x': crosshair,
        '2x': crosshair2x
    },
    default: {
        '1x': move,
        '2x': move2x
    },
    duplicate: {
        '1x': duplicate,
        '2x': duplicate2x
    },
    grab: {
        '1x': grab,
        '2x': grab2x
    },
    grabbing: {
        '1x': grabbing,
        '2x': grabbing2x
    },
    penAdd: {
        '1x': penAdd,
        '2x': penAdd2x
    },
    penClose: {
        '1x': penClose,
        '2x': penClose2x
    },
    penDefault: {
        '1x': penDefault,
        '2x': penDefault2x
    },
    penOpen: {
        '1x': penOpen,
        '2x': penOpen2x
    },
    resize: {
        '1x': resize,
        '2x': resize2x
    },
    rotate: {
        '1x': rotate,
        '2x': rotate2x
    },
    scale: {
        '1x': scale,
        '2x': scale2x
    },
    comment: {
        '1x': comment,
        '2x': comment2x
    },
}

export default cursorImages
