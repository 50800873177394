import React from 'react'
import { useTranslation } from 'react-i18next'

import moment from 'moment'

import FileContextMenu from '../../../components/ContextMenus/FileContextMenu'
import { FileCard } from '../../../components/shared'
import { FileFieldsFragment } from '../../../generated/graphql'
import { usePresenceFileUser } from '../../../hooks/usePresence'
import { useFilePermissionContext } from '../../../providers/FilePermissionProvider'
import { useSetModal } from '../../../providers/ModalProvider'
import { getFileIndexPath } from '../../../utils/pathGenerators'
import { translateProjectName } from '../../../utils/transformProject'

type FileListItemProps = {
  id: string
  isArchived: boolean
  name?: FileFieldsFragment['name']
  projectId: string
  projectName?: string | null
  thumbnailUrl?: string | null
  updatedAt: string
}

const FileListItem = ({
  id,
  name = '',
  projectId,
  projectName,
  updatedAt,
  thumbnailUrl,
  isArchived
}: FileListItemProps) => {
  const { t } = useTranslation('workspace')
  const { fetchPermissions } = useFilePermissionContext()
  const { openModal } = useSetModal(`FileContextMenu-${id}`)
  const { users } = usePresenceFileUser(id)

  const openFileContextMenu = ({
    cursorPosition,
    trigger
  }: {
    cursorPosition?: { top: number; left: number }
    trigger?: React.RefObject<HTMLElement>
  }) => {
    openModal({ trigger, cursorPosition })
  }

  const metadata = isArchived
    ? t('from_project', { project_name: translateProjectName(projectName || '', t) })
    : moment(updatedAt).fromNow()

  const handleMouseEnter = () => {
    fetchPermissions()
  }

  return (
    <>
      <FileCard
        to={isArchived ? undefined : getFileIndexPath(id)}
        imageUrl={thumbnailUrl}
        name={name}
        metadata={metadata}
        participants={users}
        onContextMenu={openFileContextMenu}
        onMouseEnter={handleMouseEnter}
      />
      <FileContextMenu id={id} name={name} isArchived={isArchived} projectId={projectId} />
    </>
  )
}

export default React.memo(FileListItem)
