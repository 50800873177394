export class Range {
    min = 0
    max = 0

    /**
     * @param {number} min
     * @param {number} max
     */
    static initN(min, max) {
        const r = new Range()
        r.min = min
        r.max = max
        return r
    }

    /**
     * @param {Range} other
     */
    overlaps(other) {
        return !(
            this.max < other.min
            ||
            this.min > other.max
        )
    }
}