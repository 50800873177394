import React from 'react'
import BasicKnob, { BasicKnobProps } from './BasicKnob'
import RectangleKnob, { RectangleKnobProps } from './RectangleKnob'
import { SliderProps } from '..'

type KnobProps = {
  variant: SliderProps['variant']
  hideKnob?: boolean
  className?: string
}

const Knob = React.forwardRef<HTMLDivElement, KnobProps>(({ variant, hideKnob = false, className = '' }, ref) => {
  const knobStyle = `${hideKnob ? 'opacity-0' : ''} ${className}`
  if (variant === 'player') {
    return <RectangleKnob ref={ref} className={knobStyle} />
  }
  return <BasicKnob ref={ref} className={knobStyle} />
})

Knob.displayName = 'Knob'

export default Knob

export type { BasicKnobProps, RectangleKnobProps }

export { BasicKnob, RectangleKnob }
