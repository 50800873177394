import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { usePopper } from 'react-popper'

import { capitalize } from 'lodash'
import moment from 'moment'

import { Placement, Rect } from '@popperjs/core'

import { PresenceCallbackMessageData } from '../../../Broadcaster/PresenceManager'
import useElementResize from '../../../hooks/useElementResize'
import { useProfile } from '../../../providers/ProfileProvider'
import ScrollView from '../ScrollView'
import PresenceAvatar, { OverflowAvatar } from './PresenceAvatar'
import Avatar, { AvatarProps, sizeMap } from './index'

export type AvatarGroupProps = {
  avatars: AvatarProps[]
}

const AvatarGroup = ({ avatars = [] }: AvatarGroupProps) => {
  return (
    <div className="flex justify-start">
      {avatars.map((avatar, avatarIndex) => (
        <Avatar
          key={avatarIndex}
          {...avatar}
          className={`-mt-1 outline-neutral-90-2 inline ${avatarIndex === avatars.length - 1 ? '-ml-1' : '-ml-4'}`}
        />
      ))}
    </div>
  )
}

export default React.memo(AvatarGroup)

type PresenceAvatarGroupProps = {
  users: PresenceCallbackMessageData[]
  parentRef?: React.RefObject<HTMLDivElement> | null
  maxVisible?: number
  pruneWidth?: number
  avatarSpacing?: number
  disableStateEffect?: boolean
  popperPlacement?: 'top' | 'bottom'
}

const POPPER_OFFSET = 8

export const PresenceAvatarGroup = ({
  users,
  parentRef = null,
  maxVisible = Number.MAX_SAFE_INTEGER,
  pruneWidth = 0,
  avatarSpacing = 0,
  disableStateEffect = false,
  popperPlacement = 'bottom'
}: PresenceAvatarGroupProps) => {
  const triggerRef = useRef<HTMLDivElement>(null)
  const timer = useRef<NodeJS.Timeout | null>(null)
  const profile = useProfile()
  const { t } = useTranslation('common')

  const { innerWidth: containerWidth } = useElementResize(parentRef)

  const [scrollViewStyles, setScrollViewStyles] = useState<React.CSSProperties>({})

  const popperRef = useRef<HTMLDivElement>(null)
  const [popperClasses, setPopperClasses] = useState('opacity-0 pointer-events-none')
  const [popperOptions, setPopperOptions] = useState({
    placement: popperPlacement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, POPPER_OFFSET - 4]
        }
      },
      {
        name: 'computeStyles',
        options: {
          adaptive: false
        }
      },
      {
        name: 'preventOverflow',
        options: {
          tetherOffset: ({ reference, placement }: { reference: Rect; placement: Placement }) => {
            if (placement === 'bottom') {
              const height = reference.y + reference.height + POPPER_OFFSET + 16
              setScrollViewStyles({
                maxHeight: `calc(100vh - ${Math.ceil(height)}px)`
              })
            } else if (placement === 'top' && triggerRef.current) {
              const rect = triggerRef.current.getBoundingClientRect()
              setScrollViewStyles({
                maxHeight: `${Math.ceil(rect.top - POPPER_OFFSET - 16)}px`
              })
            }
          }
        }
      }
    ]
  })
  const popper = usePopper(triggerRef.current, popperRef.current, popperOptions)
  const { styles, attributes, update } = popper

  // avatar size + gap
  const avatarWidth = sizeMap.m + avatarSpacing

  const visibleWidth = containerWidth > pruneWidth ? containerWidth - pruneWidth : avatarWidth

  let visibleLength = 0
  // not enough room for all avatars
  if (visibleWidth < avatarWidth * users.length) {
    visibleLength = visibleWidth / avatarWidth < 1 ? 1 : Math.floor(visibleWidth / avatarWidth) - 1
  } else {
    visibleLength = users.length
  }

  if (visibleLength >= maxVisible) {
    visibleLength = maxVisible - 1
  }

  if (users.length - visibleLength === 1) {
    visibleLength = users.length
  }

  const splitArray = <T,>(array: T[], length: number): [T[], T[]] => {
    const firstPart = array.slice(0, length)
    const secondPart = array.slice(length)
    return [firstPart, secondPart]
  }

  const [firstPart, secondPart] = splitArray(users, visibleLength)

  const handleeMouseEnter = useCallback(async () => {
    if (timer.current !== null) {
      clearTimeout(timer.current)
    }
    setPopperOptions((opts) => {
      return {
        ...opts,
        modifiers: [
          ...opts.modifiers,
          {
            name: 'offset',
            options: {
              offset: [0, POPPER_OFFSET]
            }
          }
        ]
      }
    })
    setPopperClasses('opacity-100 pointer-events-auto')

    if (update) {
      await update()
    }
  }, [update])

  const handleMouseLeave = useCallback(() => {
    timer.current = setTimeout(() => {
      setPopperOptions((opts) => {
        return {
          ...opts,
          modifiers: [
            ...opts.modifiers,
            {
              name: 'offset',
              options: {
                offset: [0, POPPER_OFFSET - 4]
              }
            }
          ]
        }
      })
      setPopperClasses('opacity-0 pointer-events-none')
    }, 100)
  }, [])

  const visibleAvatarWidth = useMemo(() => {
    const totalWidth = firstPart.length * avatarWidth
    return firstPart.length > 0 ? totalWidth - avatarSpacing : totalWidth
  }, [firstPart, avatarWidth, avatarSpacing])

  const displayText = useMemo(
    () => ({
      online: t('presence_status.online')
    }),
    [t]
  )

  useEffect(() => {
    if (secondPart.length > 0 && update) {
      update()
    }
  }, [secondPart.length, update])

  return (
    <div className="flex items-center">
      <div className="transition-all duration-300 origin-left flex gap-8" style={{ minWidth: visibleAvatarWidth }}>
        {firstPart.map((item, index) => (
          <PresenceAvatar
            key={`${item.userId}-${index}`}
            participant={item}
            popperPlacement={popperPlacement}
            disableStateEffect={disableStateEffect}
          />
        ))}
      </div>
      {secondPart.length > 0 && (
        <div className="ml-8">
          <OverflowAvatar
            ref={triggerRef}
            count={secondPart.length}
            onMouseEnter={handleeMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
          <div
            ref={popperRef}
            style={{ ...styles.popper, ...scrollViewStyles }}
            {...attributes.popper}
            className={`z-10 rounded-lg shadow-5 bg-neutral-80 max-w-[200px] overflow-hidden transition-all duration-300 ${popperClasses}`}
            onMouseEnter={handleeMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {/* @ts-ignore */}
            <ScrollView className="overflow-auto py-8" style={scrollViewStyles} noScrollbar showScrollArrow>
              {secondPart.map((item, index) => (
                <div
                  key={`${item.userId}-${index}`}
                  className="flex items-center gap-8 px-16 h-40"
                  title={item.user?.username || ''}
                >
                  <Avatar
                    className={`flex-shrink-0`}
                    size="m"
                    radiusSize="md"
                    src={item.user?.avatar}
                    alt={item.user?.username || ''}
                    isIdle={item.idleTime !== null && !disableStateEffect}
                  />
                  <div className="flex-grow min-w-0">
                    <div className="text-12 text-white font-medium flex items-center">
                      <div className="truncate">{item.user?.username || ''}</div>
                      {profile.id === item.user.id && <div className="whitespace-nowrap">{` (${t('you')})`}</div>}
                    </div>
                    <div className="text-11 text-light-overlay-60">
                      {item.idleTime !== null ? capitalize(moment(item.idleTime).fromNow()) : displayText.online}
                    </div>
                  </div>
                </div>
              ))}
            </ScrollView>
          </div>
        </div>
      )}
    </div>
  )
}
