/* eslint-disable no-unused-vars */
/** @typedef {import('./geometry/PathData').PathData} PathData */
/** @typedef {import('./math/Color').Color} Color */

import { RenderItem, RootTree } from './visual_server/RenderItem'

export function dino() {
    return apis
}

/**
 * @param {RenderItem} node
 * @returns {{ dinoNode: RootTree, idx: number }}
 */
export function getNodeDino(node) {
    const dataStore = node.visualServer.dataStore
    const isTabling = dataStore.isTablingState
    const selectedAction = dataStore.selection.data.action
    if (isTabling) {
        // console.log('selectedAction', selectedAction)
        if (selectedAction === null) {
            // console.log('actionListactionList', node.name, -1)
            return { dinoNode: node.dino, idx: -1 }
        }

        const activeTableElement = dataStore.selection.get('activeTableElement')
        const actionList = dataStore.interaction.getActionList(activeTableElement)
        const idx = actionList.indexOf(selectedAction)
        // console.log(actionList, selectedAction)
        // console.log('actionListactionList', node.name, idx)
        if (idx !== -1) {
            return { dinoNode: node.dino_actions[idx] || new RootTree(), idx }
        }
        // console.log('actionListactionList', node.name, -1)
        return { dinoNode: node.dino, idx: -1 }
    } else {
        // console.log('actionListactionList', node.name, -1)
        return { dinoNode: node.dino, idx: -1 }
    }
}

/**
 * @param {HTMLCanvasElement} canvas
 */
export async function init(canvas) {
    if (apis.initialized) return

    await window.loadDinoModule()

    // extract APIs from Module, rename and save
    extract(apis, window.Module, "_ph_")

    // resize canvas for the first time
    await new Promise((resolve) => requestAnimationFrame(resolve))
    const { width, height } = canvas.parentElement.getBoundingClientRect()
    // canvas.style.width = `${width}px`
    // canvas.style.height = `${height}px`
    // await new Promise((resolve) => requestAnimationFrame(resolve))
    // canvas.style.width = undefined
    // canvas.style.height = undefined
    apis.setWindowSize(width, height)

    // extract APIs from Module, rename and save
    extract(apis, window.Module, "_ph_")
    apis.initialized = true

    const public_url = process.env.PUBLIC_URL.length > 0 ? process.env.PUBLIC_URL : "/"
    apis.setAssetsPath(public_url)
}

export class Slice {
    ptr = 0
    len = 0
}

/**
 * @param {number[]} arr
 * @returns {Slice}
 */
export function allocU8Arena(arr) {
    if (!arr || arr.length === 0) return { ptr: 0, len: 0 }
    const len = arr.length
    const ptr = window.Module._ph_allocArena(arr.length)
    const view = new Uint8Array(window.Module.HEAP8.buffer, ptr, len)
    view.set(arr)
    return { ptr, len }
}
/**
 * @param {number[]} arr
 * @returns {Slice}
 */
export function allocF32Arena(arr) {
    if (!arr || arr.length === 0) return { ptr: 0, len: 0 }
    const len = arr.length
    const ptr = window.Module._ph_allocArena32(len)
    const view = new Float32Array(window.Module.HEAP32.buffer, ptr, len)
    view.set(arr)
    return { ptr, len }
}
/**
 * @param {number[]} arr
 * @returns {Slice}
 */
export function allocU8Perm(arr) {
    if (!arr || arr.length === 0) return { ptr: 0, len: 0 }
    const len = arr.length
    const ptr = window.Module._ph_allocPerm(arr.length)
    const view = new Uint8Array(window.Module.HEAP8.buffer, ptr, len)
    view.set(arr)
    return { ptr, len }
}
/**
 * @param {number[]} arr
 * @returns {Slice}
 */
export function allocF32Perm(arr) {
    if (!arr || arr.length === 0) return { ptr: 0, len: 0 }
    const len = arr.length
    const ptr = window.Module._ph_allocPerm32(len)
    const view = new Float32Array(window.Module.HEAP32.buffer, ptr, len)
    view.set(arr)
    return { ptr, len }
}

/**
 * @param {number} id
 */
export function getPathBBox(id) {
    const ptr = apis.getPathBBox(id)
    const view = new Float32Array(window.Module.HEAP32.buffer, ptr, 4)
    return {
        x: view[0],
        y: view[1],
        w: view[2],
        h: view[3],
    }
}

export function getStrokeBBox(path_id, stroke_id) {
    const ptr = apis.getStrokeBBox(path_id, stroke_id)
    const view = new Float32Array(window.Module.HEAP32.buffer, ptr, 4)
    return {
        x: view[0],
        y: view[1],
        w: view[2],
        h: view[3],
    }
}

const apis = {
    initialized: false,

    /**
     * @param {number} w
     * @param {number} h
     */
    setWindowSize: (w, h) => {
        console.warn("API missing")
    },

    /**
     * @returns {number} width
     */
    getCanvasWidth: () => {
        console.warn("API missing")
    },

    /**
     * @returns {number} height
     */
    getCanvasHeight: () => {
        console.warn("API missing")
    },

    /**
     * @param {number} len
     */
    allocArena: (len) => {
        console.warn("API missing")
    },
    /**
     * @param {number} len
     */
    allocArena32: (len) => {
        console.warn("API missing")
    },
    /**
     * @param {number} len
     */
    allocPerm: (len) => {
        console.warn("API missing")
    },
    /**
     * @param {number} len
     */
    allocPerm32: (len) => {
        console.warn("API missing")
    },
    /**
     * @param {number} ptr
     */
    freePerm: (ptr) => {
        console.warn("API missing")
    },
    /**
     * @param {number} ptr
     */
    freePerm32: (ptr) => {
        console.warn("API missing")
    },

    /**
     * @param {string} path
     */
    setAssetsPath: (path) => {
        const encoder = new TextEncoder()
        const arr = encoder.encode(path)
        const mem = allocU8Arena(arr)
        apis.setAssetsPathEx(mem.ptr, mem.len)
    },
    /**
     * @param {number} ptr
     * @param {number} len
     */
    setAssetsPathEx: (ptr, len) => {
        console.warn("API missing")
    },

    /**
     * @param {number} id
     */
    getPathBBox: (id) => {
        console.warn("API missing")
    },
    /**
     * @param {number} path_id
     * @param {number} stroke_id
     */
    getStrokeBBox: (path_id, stroke_id) => {
        console.warn("API missing")
    },

    GROUP: 0,
    PATH: 1,
    TEXT: 2,
    /**
     * @param {number} node_id
     */
    markNodeChanged: (node_id) => {
        console.warn("API missing")
    },
    /**
     * @param {0|1|2} type
     */
    makeNode: (type) => {
        console.warn("API missing")
    },
    /**
     * @param {number} id
     */
    destroyNode: (id) => {
        console.warn("API missing")
    },
    /**
     * @param {number} parent_id
     * @param {number} child_id
     */
    addNodeChild: (parent_id, child_id) => {
        console.warn("API missing")
    },
    /**
     * @param {number} parent_id
     * @param {number} child_id
     * @param {number} index
     */
    insertNodeChild: (parent_id, child_id, index) => {
        console.warn("API missing")
    },
    /**
     * @param {number} node_id
     */
    addNodeToRoot: (node_id) => {
        console.warn("API missing")
    },
    /**
     * reorder node in parent
     * @param {number} node_id
     * @param {number} index
     */
    reorderNode: (node_id, index) => {
        console.warn("API missing")
    },
    /**
     * Remove relationship of node with its parent and siblings, but will keep the child
     * @param {number} node_id
     */
    removeNode: (node_id) => {
        console.warn("API missing")
    },

    /**
     * @param {number} node_id
     * @param {number} x
     * @param {number} y
     * @param {number} rotation
     * @param {number} scale_x
     * @param {number} scale_y
     * @param {number} skew_x
     * @param {number} skew_y
     */
    setNodeTransform: (node_id, x, y, rotation, scale_x, scale_y, skew_x, skew_y) => {
        console.warn("API missing")
    },

    /**
     * @param {number} node_id
     * @param {boolean} visible
     */
    setNodeVisible: (node_id, visible) => {
        console.warn("API missing")
    },
    /**
     * @param {number} node_id
     * @param {number} opacity
     */
    setNodeOpacity: (node_id, opacity) => {
        console.warn("API missing")
    },
    /**
     * @param {number} node_id
     * @param {number} blend
     */
    setNodeBlend: (node_id, blend) => {
        console.warn("API missing")
    },
    /**
     * @param {number} node_id
     * @param {number} idx
     * @param {number} comp_id
     */
    setNodeCompose: (node_id, idx, comp_id) => {
        console.warn("API missing")
    },
    /**
     * Remove compose from node, would not destroy anything
     * @param {number} node_id
     * @param {number} idx
     */
    removeNodeCompose: (node_id, idx) => {
        console.warn("API missing")
    },
    /**
     * Do three things
     * 1. Remove compose from node
     * 2. Destroy whole compose subtree
     * 3. Destroy compose
     * @param {number} node_id
     * @param {number} idx
     */
    destroyNodeCompose: (node_id, idx) => {
        console.warn("API missing")
    },
    /**
     * @param {number} node_id
     * @param {number} path_id
     */
    setNodePath: (node_id, path_id) => {
        console.warn("API missing")
    },

    COLOR: 1,
    GRADIENT: 2,
    IMAGE: 3,
    PATTERN: 4,

    /**
     * @param {number} node_id
     * @param {number} tag
     * @param {number} paint_id
     */
    setNodeFillPaint: (node_id, tag, paint_id) => {
        console.warn("API missing")
    },

    /**
     * @param {number} node_id
     * @param {number} opacity
     */
    setNodeFillOpacity: (node_id, opacity) => {
        console.warn("API missing")
    },

    /**
     * @param {number} node_id
     * @param {number} tag
     * @param {number} paint_id
     */
    setNodeStrokePaint: (node_id, tag, paint_id) => {
        console.warn("API missing")
    },

    /**
     * @param {number} node_id
     * @param {number} opacity
     */
    setNodeStrokeOpacity: (node_id, opacity) => {
        console.warn("API missing")
    },

    /**
     * @param {number} node_id
     * @param {number} stroke_id
     */
    setNodeStrokeData: (node_id, stroke_id) => {
        console.warn("API missing")
    },

    /**
     * @param {PathData} path
     */
    makePath: (path) => {
        if (
            (path.commands.length == 0)
            ||
            (path.vertices.length == 0)
        ) {
            return apis.makeEmptyPath()
        }
        const cmd_slice = allocU8Arena(path.commands)
        const vtx_slice = allocF32Arena(path.vertices)
        return apis.makePathEx(cmd_slice.ptr, cmd_slice.len, vtx_slice.ptr, vtx_slice.len)
    },
    makeEmptyPath: () => {
        console.warn("API missing")
    },
    /**
     * @param {number} cmd_ptr
     * @param {number} cmd_len
     * @param {number} vtx_ptr
     * @param {number} vtx_len
     */
    makePathEx: (cmd_ptr, cmd_len, vtx_ptr, vtx_len) => {
        console.warn("API missing")
    },
    /**
     * @param {number} path_id
     * @param {PathData} path
     */
    setPath: (path_id, path) => {
        if (
            (path.commands.length == 0)
            ||
            (path.vertices.length == 0)
        ) {
            apis.setEmptyPath(path_id)
        } else {
            const cmd_slice = allocU8Arena(path.commands)
            const vtx_slice = allocF32Arena(path.vertices)
            apis.setPathEx(path_id, cmd_slice.ptr, cmd_slice.len, vtx_slice.ptr, vtx_slice.len)
        }
    },
    /**
     * @param {number} path_id
     */
    setEmptyPath: (path_id) => {
        console.warn("API missing")
    },
    /**
     * @param {number} path_id
     * @param {number} cmd_ptr
     * @param {number} cmd_len
     * @param {number} vtx_ptr
     * @param {number} vtx_len
     */
    setPathEx: (path_id, cmd_ptr, cmd_len, vtx_ptr, vtx_len) => {
        console.warn("API missing")
    },
    /**
     * @param {number} path_id
     */
    destroyPath: (path_id) => {
        console.warn("API missing")
    },

    /**
     * @param {number} r
     * @param {number} g
     * @param {number} b
     * @param {number} a
     * @returns {number} color_id
     */
    makeColor: (r, g, b, a) => {
        console.warn("API missing")
    },
    /**
     * @param {number} id
     * @param {number} r
     * @param {number} g
     * @param {number} b
     * @param {number} a
     */
    setColor: (id, r, g, b, a) => {
        console.warn("API missing")
    },
    /**
     * @param {number} id
     */
    destroyColor: (id) => {
        console.warn("API missing")
    },

    /**
     * @param {number} gradient_type
     * @param {number} a grad matrix a
     * @param {number} b grad matrix b
     * @param {number} c grad matrix c
     * @param {number} d grad matrix d
     * @param {number} e grad matrix e
     * @param {number} f grad matrix f
     * @returns {number} gradient_id
     */
    makeGradientEx: (gradient_type, a, b, c, d, e, f) => {
        console.warn("API missing")
    },

    /**
     * @param {number} gradient_type
     * @param {Transform2D} matrix
     * @returns {number} gradient_id
     */
    makeGradient: (gradient_type, matrix) =>
        apis.makeGradientEx(gradient_type, matrix.a, matrix.b, matrix.c, matrix.d, matrix.tx, matrix.ty)
    ,

    /**
     * @param {number} gradient_id
     * @param {number} a grad matrix a
     * @param {number} b grad matrix b
     * @param {number} c grad matrix c
     * @param {number} d grad matrix d
     * @param {number} e grad matrix e
     * @param {number} f grad matrix f
     */
    setGradientMatrixEx: (gradient_id, a, b, c, d, e, f) => {
        console.warn("API missing")
    },

    /**
     * @param {number} gradient_id
     * @param {number} matrix
     */
    setGradientMatrix: (gradient_id, matrix) => {
        apis.setGradientMatrixEx(gradient_id, matrix.a, matrix.b, matrix.c, matrix.d, matrix.tx, matrix.ty)
    },

    /**
     * @param {number} gradient_id
     * @param {number} tag
     */
    setGradientTag: (gradient_id, tag) => {
        console.warn("API missing")
    },

    /**
     * note: Remember to call updateGradientPixels after setting all stops
     * @param {number} gradient_id
     * @param {number} len stop count
     */
    setGradientStopLen: (gradient_id, len) => {
        console.warn("API missing")
    },

    /**
     * note: Remember to call updateGradientPixels after setting all stops
     * @param {number} gradient_id
     * @param {number} index
     * @param {number} pos
     * @param {number} r
     * @param {number} g
     * @param {number} b
     * @param {number} a
     */
    setGradientStop: (gradient_id, index, pos, r, g, b, a) => {
        console.warn("API missing")
    },

    /**
     * @param {number} gradient_id
     */
    updateGradientPixels: (gradient_id) => {
        console.warn("API missing")
    },

    /**
     * @param {number} gradient_id
     */
    destroyGradient: (gradient_id) => {
        console.warn("API missing")
    },

    /**
     * @param {number} width
     * @param {number} cap
     * @param {number} join
     * @param {number} miter_limit
     * @param {number} dash
     * @param {number} gap
     * @returns {number} stroke_id
     */
    makeStroke: (width, cap, join, miter_limit, dash, gap) => {
        console.warn("API missing")
    },

    /**
     * @param {number} stroke_id
     * @param {number} width
     * @param {number} cap
     * @param {number} join
     * @param {number} miter_limit
     * @param {number} dash
     * @param {number} gap
     */
    setStroke: (stroke_id, width, cap, join, miter_limit, dash, gap) => {
        console.warn("API missing")
    },

    /**
     * @param {number} stroke_id
     */
    destroyStroke: (stroke_id) => {
        console.warn("API missing")
    },

    getStrokePathCmdEx: (path_id, stroke_id) => {
        console.warn("API missing")
    },

    /**
     * @param {number} path_id
     * @param {number} stroke_id
     * @returns {number[]} commands
     */
    getStrokePathCmd: (path_id, stroke_id) => {
        const ptr = apis.getStrokePathCmdEx(path_id, stroke_id)
        const view = new Uint32Array(window.Module.HEAP32.buffer, ptr, 2)
        if (view[1] === 0) return []
        const commands = new Uint8Array(window.Module.HEAP8.buffer, view[0], view[1])
        return Array.from(commands)
    },

    getStrokePathVtxEx: (path_id, stroke_id) => {
        console.warn("API missing")
    },

    /**
     * @param {number} path_id
     * @param {number} stroke_id
     * @returns {number[]} vertices
     */
    getStrokePathVtx: (path_id, stroke_id) => {
        const ptr = apis.getStrokePathVtxEx(path_id, stroke_id)
        const view = new Uint32Array(window.Module.HEAP32.buffer, ptr, 2)
        if (view[1] === 0) return []
        const vertices = new Float32Array(window.Module.HEAP32.buffer, view[0], view[1])
        return Array.from(vertices)
    },

    /**
     * @returns {number} image_id
     */
    allocImage: () => {
        console.warn("API missing")
    },

    /**
     * @param {number} ptr
     * @param {number} len
     * @returns {number} image_id
     */
    makeImageWithURLEx: (ptr, len) => {
        console.warn("API missing")
    },

    /**
     * @param {string} url
     * @returns {number} image_id
     */
    makeImageWithURL: (url) => {
        const { ptr, len } = allocU8Arena(Array.from(new TextEncoder().encode(url)))
        return apis.makeImageWithURLEx(ptr, len)
    },

    /**
     * @param {number} pixels_ptr
     * @param {number} pixels_len
     * @param {number} width
     * @param {number} height
     * @returns {number} image_id
     */
    makeImageWithPixelsEx: (pixels_ptr, pixels_len, width, height) => {
        console.warn("API missing")
    },

    /**
     * @param {number[]} pixels
     * @param {number} width
     * @param {number} height
     * @returns {number} image_id
     */
    makeImageWithPixels: (pixels, width, height) => {
        const { ptr, len } = allocU8Arena(pixels)
        return apis.makeImageWithPixelsEx(ptr, len, width, height)
    },

    /**
     * @param {number} ptr
     * @param {number} len
     * @returns {number} image_id
     */
    makeImageWithFileDataEx: (ptr, len) => {
        console.warn("API missing")
    },

    /**
     * @param {number[]} fileData
     * @returns {number} image_id
     */
    makeImageWithFileData: (fileData) => {
        const { ptr, len } = allocU8Arena(fileData)
        return apis.makeImageWithFileDataEx(ptr, len)
    },

    /**
     * @param {number} image_id
     * @param {number[]} pixels
     * @param {number} width
     * @param {number} height
     */
    setImageWithPixels: (image_id, pixels, width, height) => {
        const { ptr, len } = allocU8Arena(pixels)
        apis.setImageWithPixelsEx(image_id, ptr, len, width, height)
    },

    /**
     * @param {number} image_id
     * @param {number} pixels_ptr
     * @param {number} pixels_len
     * @param {number} width
     * @param {number} height
     */
    setImageWithPixelsEx: (image_id, pixels_ptr, pixels_len, width, height) => {
        console.warn("API missing")
    },

    /**
     * @param {number} image_id
     * @param {number} fill_mode
     */
    setImageFillMode: (image_id, fill_mode) => {
        console.warn("API missing")
    },

    /**
     * @param {number} image_id
     */
    destroyImage: (image_id) => {
        console.warn("API missing")
    },

    /**
     * @param {*} comp_tag
     * @param {*} root_id
     * @returns comp_id
     */
    makeCompose: (comp_tag, root_id) => {
        console.warn("API missing")
    },

    /**
     * @param {number} comp_id
     * @param {number} root_id
     * @returns comp_id
     */
    setComposeRoot: (comp_id, root_id) => { },

    /**
     * @param {number} comp_id
     */
    destroyCompose: (comp_id) => {
        console.warn("API missing")
    },

    /**
     * @param {number} r
     * @param {number} g
     * @param {number} b
     * @param {number} a
     */
    setBackgroundColor: (r, g, b, a) => {
        console.warn("API missing")
    },

    numNodesRender: () => {
        console.warn("API missing")
    },
    numTilesRender: () => {
        console.warn("API missing")
    },

    // overlay

    /**
     * @param {number} a
     * @param {number} b
     * @param {number} c
     * @param {number} d
     * @param {number} e
     * @param {number} f
     */
    setTransform: (a, b, c, d, e, f) => {
        console.warn("API missing")
    },

    /**
     * @param {number} r 0-1
     * @param {number} g 0-1
     * @param {number} b 0-1
     * @param {number} a 0-1
     */
    fillStyle: (r, g, b, a) => {
        console.warn("API missing")
    },

    /**
     * @param {number} width
     * @param {number} r 0-1
     * @param {number} g 0-1
     * @param {number} b 0-1
     * @param {number} a 0-1
     */
    strokeStyle: (width, r, g, b, a) => {
        console.warn("API missing")
    },

    /**
     * @param {number} layer_id
     * @param {number} x
     * @param {number} y
     * @param {number} cmd_ptr
     * @param {number} cmd_len
     * @param {number} vtx_ptr
     * @param {number} vtx_len
     */
    drawPathEx: (layer_id, x, y, cmd_ptr, cmd_len, vtx_ptr, vtx_len) => {
        console.warn("API missing")
    },

    /**
     * @param {number} layer_id
     * @param {number} x
     * @param {number} y
     * @param {PathData} path
     */
    drawPath: (layer_id, x, y, path) => {
        if (
            (path.commands.length == 0)
            ||
            (path.vertices.length == 0)
        ) {
            return
        }
        const cmd_slice = allocU8Arena(path.commands)
        const vtx_slice = allocF32Arena(path.vertices)
        apis.drawPathEx(layer_id, x, y, cmd_slice.ptr, cmd_slice.len, vtx_slice.ptr, vtx_slice.len)
    },

    /**
     * @param {number} layer_id
     * @param {number} x0
     * @param {number} y0
     * @param {number} x1
     * @param {number} y1
     */
    drawLine: (layer_id, x0, y0, x1, y1) => {
        console.warn("API missing")
    },

    /**
     * @param {number} layer_id
     * @param {number} x0
     * @param {number} y0
     * @param {number} x1
     * @param {number} y1
     */
    drawLineShadow: (layer_id, x0, y0, x1, y1) => {
        console.warn("API missing")
    },

    /**
     * @param {number} layer_id
     * @param {number} x
     * @param {number} y
     * @param {number} w
     * @param {number} h
     */
    drawRect: (layer_id, x, y, w, h) => {
        console.warn("API missing")
    },

    /**
     * @param {number} layer_id
     * @param {number} x
     * @param {number} y
     * @param {number} w
     * @param {number} h
     * @param {number} corner_radius
     */
    drawRoundedRect: (layer_id, x, y, w, h, corner_radius) => {
        console.warn("API missing")
    },

    /**
     * @param {number} layer_id
     * @param {number} x
     * @param {number} y
     * @param {number} w
     * @param {number} h
     */
    drawSolidRect: (layer_id, x, y, w, h) => {
        console.warn("API missing")
    },

    /**
     * @param {number} layer_id
     * @param {number} x
     * @param {number} y
     * @param {number} w
     * @param {number} h
     * @param {number} corner_radius
     */
    drawSolidRoundedRect: (layer_id, x, y, w, h, corner_radius) => {
        console.warn("API missing")
    },

    /**
     * @param {number} layer_id
     * @param {number} x
     * @param {number} y
     * @param {number} radius
     */
    drawCircle: (layer_id, x, y, radius) => {
        console.warn("API missing")
    },

    /**
     * @param {number} layer_id
     * @param {number} x
     * @param {number} y
     * @param {number} radius
     */
    drawSolidCircle: (layer_id, x, y, radius) => {
        console.warn("API missing")
    },

    /**
     * @param {number} layer_id
     * @param {number} x
     * @param {number} y
     * @param {number} radius
     */
    drawCircleShadow: (layer_id, x, y, radius) => {
        console.warn("API missing")
    },

    /**
     * @param {number} layer_id
     * @param {number} x
     * @param {number} y
     * @param {number} width
     * @param {number} height
     * @param {number} rot
     */
    drawEllipse: (layer_id, x, y, radius_x, radius_y, rot = 0) => {
        console.warn("API missing")
    },

    /**
     * @param {number} layer_id
     * @param {number} x
     * @param {number} y
     * @param {number} width
     * @param {number} height
     * @param {number} rot
     */
    drawSolidEllipse: (layer_id, x, y, radius_x, radius_y, rot = 0) => {
        console.warn("API missing")
    },

    /**
     * @param {number} layer_id
     * @param {number} x
     * @param {number} y
     * @param {number} width
     * @param {number} height
     * @param {number} rot
     */
    drawEllipseShadow: (layer_id, x, y, width, height, rot) => {
        throw "API missing"
    },


    /**
     * @param {number} layer_id
     * @param {number} x
     * @param {number} y
     * @param {number} text_ptr
     * @param {number} text_len
     * @param {number} hor_align
     * @param {number} ver_align
     * @param {number} font
     */
    drawTextEx: (layer_id, x, y, text_ptr, text_len, hor_align, ver_align, font) => {
        console.warn("API missing")
    },

    /**
     * @param {number} layer_id
     * @param {number} x
     * @param {number} y
     * @param {string} text
     * @param {number} hor_align
     * @param {number} ver_align
     * @param {number} font
     */
    drawText: (layer_id, x, y, text, hor_align, ver_align, font, rotation) => {
        const textAry = Array.from(new TextEncoder().encode(text))
        const { ptr, len } = allocU8Arena(textAry)
        apis.drawTextEx(layer_id, x, y, ptr, len, hor_align, ver_align, font, rotation)
    },

    /**
     * @param {number} text_ptr
     * @param {number} text_len
     */
    measureTextEx: (text_ptr, text_len, font) => {
        console.warn("API missing")
    },

    /**
     * @param {string} text
     * @returns {{ w: number, h: number }}
     */
    measureText: (text, font) => {
        const textAry = Array.from(new TextEncoder().encode(text))
        const { ptr, len } = allocU8Arena(textAry)
        const res_ptr = apis.measureTextEx(ptr, len, font)
        const view = new Float32Array(window.Module.HEAP32.buffer, res_ptr, 2)
        return {
            w: view[0],
            h: view[1],
        }
    },

    /**
     * @param {number} pixels_ptr
     * @param {number} pixels_len
     * @param {number} width
     * @param {number} height
     * @returns {number} id
     */
    uploadImageEx: (pixels_ptr, pixels_len, width, height) => {
        console.warn("API missing")
    },

    /**
     * @param {number[]} pixels
     * @param {number} width
     * @param {number} height
     * @returns {number} id
     */
    uploadImage: (pixels, width, height) => {
        const { ptr, len } = allocU8Arena(pixels)
        return apis.uploadImageEx(ptr, len, width, height)
    },

    /**
     * @param {number} layer_id
     * @param {number} image_id
     * @param {number} x
     * @param {number} y
     * @param {number} w
     * @param {number} h
     */
    drawImage: (layer_id, image_id, x, y, w, h) => {
        console.warn("API missing")
    },

    purge: () => {
        console.warn("API missing")
    },

    /**
     * @param {number} path_id
     * @param {number} x
     * @param {number} y
     * @returns {boolean}
     */
    isPointInPath: (path_id, x, y) => {
        console.warn("API missing")
    },
    /**
     * @param {number} path_id
     * @param {number} stroke_data_id
     * @param {number} x
     * @param {number} y
     * @returns {boolean}
     */
    isPointInStroke: (path_id, stroke_data_id, x, y) => {
        console.warn("API missing")
    },

    /**
     * @param {number} r 0.0 - 1.0
     * @param {number} g 0.0 - 1.0
     * @param {number} b 0.0 - 1.0
     * @param {number} a 0.0 - 1.0
     */
    setCaptureBackgroundColor: (r, g, b, a) => {
        console.warn("API missing")
    },
    /**
     * @param {number} node_id
     * @param {number} clip_x
     * @param {number} clip_y
     * @param {number} clip_w
     * @param {number} clip_h
     * @param {number} output_w
     * @param {number} output_y
     */
    capture: (node_id, clip_x, clip_y, clip_w, clip_h, output_w, output_y) => {
        console.warn("API missing")
    },

    pauseApp: () => {
        console.warn("API missing")
    },

    resumeApp: () => {
        console.warn("API missing")
    },
}

/**
 * @param {Record<string, Function>} out
 * @param {Record<string, Function>} obj
 * @param {string} prefix
 */
function extract(out, obj, prefix) {
    const len = prefix.length
    for (const key in obj) {
        if (typeof obj[key] === 'function' && key.startsWith(prefix)) {
            out[key.substring(len)] = obj[key]
        }
    }
}
