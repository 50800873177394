import { TutorialType } from '../constant'
import tip12 from '../assets/1.2.gif'

export default {
  key: TutorialType.TOOL_MENU,
  progress: true,
  mask: false,
  interact: true,
  steps: [
    {
      header: 'Scale Tool',
      image: tip12,
      content: 'When dragging element’s edge with Scale Tool, you change its Scale not Size.',
      anchor: '.pt-scale-option'
    }
  ]
}
