import { useDataStore } from '../../providers/dataStore/DataStoreProvider'
import { useLocation } from 'react-router-dom'

interface moveToProps {
  x: number
  y: number
  width?: number
  height?: number
}

const DEFAULT_X_POSITION = 200
const DEFAULT_Y_POSITION = 200
const VIEW_X_POSITION = 400

export const useMoveViewport = () => {
  const dataStore = useDataStore()
  const location = useLocation()

  const moveTo = ({ x, y, width = 0, height = 0 }: moveToProps): void => {
    dataStore?.drawInfo?.vs?.viewport?.moveTo({ x, y, width, height })
  }

  const moveToTopLeft = ({ x, y, width = 0, height = 0 }: moveToProps): void => {
    if (dataStore?.drawInfo?.vs?.viewport) {
      let defaultXPosition = DEFAULT_X_POSITION
      const defaultYPosition = DEFAULT_Y_POSITION
      if (location.pathname.includes('/view')) defaultXPosition = VIEW_X_POSITION

      const scaleX = defaultXPosition / dataStore.drawInfo.vs.viewport.width
      const scaleY = defaultYPosition / dataStore.drawInfo.vs.viewport.height

      dataStore.drawInfo.vs.viewport.moveTo({ x, y, width, height }, scaleX, scaleY)
    }
  }

  return { moveTo, moveToTopLeft }
}
