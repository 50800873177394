import React, { HTMLAttributes } from 'react'
import RemoveLayer from './RemoveLayer'

interface ContainerWithRemoveLayerProps extends HTMLAttributes<HTMLDivElement> {
  layerItemId?: string
  isNonBase?: boolean
  dataTestId?: string
}

export const ContainerWithRemoveLayer = ({
  children,
  layerItemId,
  isNonBase,
  dataTestId,
  ...rest
}: ContainerWithRemoveLayerProps) => {
  return (
    <div className="flex items-center justify-between pb-8" data-test-id={dataTestId} {...rest}>
      {children}
      <RemoveLayer layerItemId={layerItemId} isNonBase={isNonBase} />
    </div>
  )
}
