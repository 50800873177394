import React, { useMemo } from 'react'
import { EffectTrimPathItem } from '../types'
import { Input } from '../../../../shared'
import { transformPercentageFormatValidator } from '../../../../../utils/validator'
import { formatAsPercentage } from '../../../../../utils/formatter'
import { useEditorActions } from '../../../../../providers/dataStore/EditorProvider'
import { useDataStoreActions } from '../../../../../providers/dataStore/DataStoreProvider'

type TrimPathOffsetProps = {
  id: string
  value: EffectTrimPathItem['offset']
}

export default function TrimPathOffset({ id, value = 0 }: TrimPathOffsetProps) {
  const { setEffectProps } = useEditorActions()
  const { commitUndo, debounceCommitUndo } = useDataStoreActions()

  const handleChange = (
    updatedValue: EffectTrimPathItem['offset'],
    options: { commit: boolean; delta: number | undefined }
  ) => {
    setEffectProps(id, { offset: updatedValue }, options)
  }

  const keyFrameIconProps = useMemo(
    () => ({
      name: 'offset',
      effectItemId: id,
      effectType: 'trimPath'
    }),
    [id]
  )

  return (
    <div className="flex-1">
      <Input
        // @ts-ignore TODO: fix after refactor of Input
        variant="normal"
        type="number"
        value={Math.round(value)}
        validator={transformPercentageFormatValidator}
        onStepChange={debounceCommitUndo}
        onBlur={commitUndo}
        formatter={formatAsPercentage}
        onChange={handleChange}
        keyFrameIconProps={keyFrameIconProps}
        spinner
      />
    </div>
  )
}
