import {
    EntityType,
    PropComponentType,
    LayerComponentType,
    LayerType,
    ChangeType
} from '@phase-software/types'
import { isNull, Undoable } from '@phase-software/data-utils'
import { DEFAULT_LAYER_PAINT_DATA } from './constant'
import { COMPONENT_CLASSES } from './component'
import { createLayer } from './layer/helpers'
import { createEffect } from './effect/helpers'
import PropertyComponent from './component/PropertyComponent'
import Layer from './layer/Layer'


/** @typedef {import('./constant').ComponentDataMap} ComponentDataMap */

// TODO: combine different Layer classes into one, like was done with PaintComponents

const LAYER_SUB_COMPONENTS = [{
    subType: PropComponentType.PAINT,
    subPropName: 'paintId'
}]

// Have list of subcomponents for extensibility
export const HAS_SUB_COMPONENT = {
    [LayerType.FILL]: LAYER_SUB_COMPONENTS,
    [LayerType.STROKE]: LAYER_SUB_COMPONENTS,
    [LayerType.SHADOW]: LAYER_SUB_COMPONENTS,
    [LayerType.INNER_SHADOW]: LAYER_SUB_COMPONENTS
}

const REGEN_ID = { regenId: true }

const DEFAULT_CLONE_COMPONENT_OPTIONS = { fire: true, cloneShared: false }

class Library extends Undoable {
    constructor(dataStore, data) {
        super(dataStore, ChangeType.ENTITY, 'LIBRARY_CHANGES')
        this.dataStore = dataStore
        this.deletedMap = new Map()

        // TODO: consider if we need to remove deleted layers from _parents
        this.data = new Map()
        this._parents = new Map()
        this.load(data)
    }

    clear() {
        this.data.clear()
        this._parents.clear()
        this.changes.clear()
    }

    load(data = {}) {
        for (const id in data) {
            this._loadComponentOrLayer(id, data[id])
        }
    }

    save() {
        return Array.from(this.data).reduce((acc, [id, component]) => {
            const data = component.save()
            acc[id] = data
            return acc
        }, {})
    }

    /**
     * Saves a set of data for components (and each of their nested sub components)
     * specified in provided component list. Every nested component will appear before it's parent in the resulting list.
     * @param {Iterable<string>} componentList   list of component id from Base
     * @param {Map<string, PropertyComponentData | LayerData>} savedMap list of serialized data for all specified components (and each of their nested sub components)
     * @param {bool} saveShared
     * @returns {Map<string, PropertyComponentData | LayerData>}  savedMap
     */
    saveComponentList(componentList, savedMap = new Map(), saveShared = true) {
        for (const cmpId of componentList) {
            if (savedMap.has(cmpId)) {
                continue
            }
            // if shared - copy id directly
            if (!saveShared && this.isShared(cmpId)) {
                savedMap.set(cmpId, cmpId)
                continue
            }

            const cmp = this.getComponent(cmpId)
            if (!cmp) {
                continue
            }
            // check nested components first
            if (cmp.componentType in LayerComponentType) {
                this.saveComponentList(cmp.layers, savedMap, saveShared)
            } else if (cmp.componentType === PropComponentType.EFFECT) {
                this.saveComponentList(cmp.effects, savedMap, saveShared)
            } else {
                forEachSubcomponent(cmp, (_, subPropName) => {
                    this.saveComponentList([cmp[subPropName]], savedMap, saveShared)
                })
            }

            // save it's own data
            savedMap.set(cmpId, cmp.save())
        }
        return savedMap
    }

    /**
     * Clones component list from serialized data
     * @param {Iterable<PropertyComponent| Layer | object>} componentList
     * @returns {Map<string, string>}    mapping of old ids to new ones
     */
    cloneComponentList(componentList) {
        const idMap = new Map()
        for (const cmp of componentList) {
            const isInstance = cmp instanceof PropertyComponent || cmp instanceof Layer
            const newId = isInstance
                ? this._addMapping(cmp.clone())
                : this._loadComponentOrLayer(cmp.id, cmp, REGEN_ID)
            if (newId) {
                idMap.set(cmp.id, newId)
            }
        }

        for (const newId of idMap.values()) {
            const newCmp = this.getComponent(newId)
            if (newCmp.componentType in LayerComponentType) {
                newCmp.layers = newCmp.layers.map(id => {
                    const layerId = idMap.get(id)
                    if (!layerId) {
                        throw new Error('Failed to map cloned layer');
                    }
                    this._parents.set(layerId, newCmp.id)
                    return layerId
                })
            } else if (newCmp.componentType === PropComponentType.EFFECT) {
                newCmp.effects = newCmp.effects.map(id => {
                    const effectId = idMap.get(id)
                    if (!effectId) {
                        throw new Error('Failed to map cloned effect');
                    }
                    this._parents.set(effectId, newCmp.id)
                    return effectId
                })
            } else {
                forEachSubcomponent(newCmp, (_, subPropName) => {
                    if (!newCmp[subPropName]) {
                        return
                    }
                    const newId = idMap.get(newCmp[subPropName])
                    if (!newId) {
                        throw new Error('Failed to map cloned component');
                    }
                    newCmp[subPropName] = newId
                })
            }
            // Should clear changes from any property change while cloning component
            // Currently, update paintId will add paintId changes to Layer
            newCmp.clearChanges()
        }

        // We should add the changes with new Ids, not the cloned Ids
        this.changes.create(idMap.values())
        this.fire()

        return idMap
    }

    /**
     * @param {string} componentId id of PropertyComponent
     * @returns {bool} true if component is shared; false othewise
     */
    // eslint-disable-next-line no-unused-vars
    isShared(componentId) {
        // TODO: implement this when we have proper shared components
        return false
    }

    /**
     * Load property component or layer from data
     * @private
     * @param {string} id           prop component or layer id; if undefined then new id will be generated
     * @param {object} data
     * @param {object} [options]
     * @param {bool} [options.regenId=false]   if set to true, will generate new ID
     * @returns {string}  new component ID or layer ID
     */
    _loadComponentOrLayer(id, data, options = { regenId: false }) {
        // in file ID is stored as key of dict, but not in the component
        //   object itself to reduce file size
        // need to copy it back
        data.id = id
        let cmp
        switch (data.type) {
            case EntityType.LAYER:
                cmp = createLayer(this.dataStore, data.layerType, data, options)
                break
            case EntityType.PROP_COMPONENT:
                cmp = createComponent(this.dataStore, data.componentType, data, options)
                if (!options.regenId) {
                    if (cmp.componentType in LayerComponentType) {
                        cmp.layers.forEach(layerId => {
                            this._parents.set(layerId, cmp.id)
                        })
                    }
                    if (cmp.componentType === PropComponentType.EFFECT) {
                        cmp.effects.forEach(effectId => {
                            this._parents.set(effectId, cmp.id)
                        })
                    }
                }

                break
            case EntityType.EFFECT:
                cmp = createEffect(this.dataStore, data, options)
                break
        }
        if (!cmp) {
            console.error('Failed to create component instance', data)
            return
        }

        this._addMapping(cmp)


        return cmp.id
    }

    /**
     * Add a new PropertyComponent
     * @param {PropComponentType} type - componentType
     * @param {PropertyComponentData} data
     * @param {bool} [fire=true]
     * @returns {string}
     */
    addProperty(type, data, fire = true) {
        const newData = { ...data }
        delete newData.id

        if (type in LayerComponentType) {
            // TODO: figure out if need to add default layer
        } else {
            // If has sub-component, add the sub-component first.
            // And put sub-component id into data for creating the component.
            forEachSubcomponent(type, (subType, subPropName) => {
                // TODO: consider if passing same `data` to subcomponents is a good idea
                newData[subPropName] = this.addProperty(subType, data, false)
            })
        }

        const component = createComponent(this.dataStore, type, newData)
        this._addMapping(component)

        this.changes.create([component.id])
        if (fire) {
            this.fire()
        }
        return component.id
    }

    /**
     * Clone component and all it's nested descendant components.
     * @param {string} componentId
     * @param {object} [options]
     * @param {bool} [options.cloneShared=false]    set to true to clone shared components
     * @param {bool} [options.fire=true]            set to false to not fire event
     * @param {object} overrides                   data to set to a new cloned component
     * @returns {string}  new cloned component id
     */
    cloneComponent(
        componentId,
        { fire = true, cloneShared = false } = DEFAULT_CLONE_COMPONENT_OPTIONS,
        overrides
    ) {
        const cmp = this.getComponent(componentId)
        if (!cmp) {
            throw new Error("Specified component doesn't exist")
        }

        if (!cloneShared && this.isShared(componentId)) {
            // TODO: figure out what to do here when have shared components
            return cmp.id
        }

        const cloned = cmp.clone()
        // TODO: should overrides ever be nested?
        if (overrides) {
            // no need to add this change to Library updates, since it's a new component
            cloned.set(overrides)
        }

        // clone nested components
        //  if layer component - clone it's layers
        if (cmp.componentType in LayerComponentType) {
            cloned.layers = cmp.layers.map(layerId => {
                const newLayerId = this.cloneComponent(layerId, { fire: false, cloneShared })
                this._parents.set(newLayerId, cloned.id)
                return newLayerId
            })
        }
        else if (cmp.componentType === PropComponentType.EFFECT) {
            cloned.effects = cmp.effects.map(effectId => {
                const newEffectId = this.cloneComponent(effectId, { fire: false, cloneShared })
                this._parents.set(newEffectId, cloned.id)
                return newEffectId
            })
        }
        else {
            forEachSubcomponent(cloned, (_, subPropName) => {
                if (cloned[subPropName]) {
                    cloned[subPropName] = this.cloneComponent(
                        cloned[subPropName],
                        { fire: false, cloneShared }
                    )
                }
            })
        }

        // Should clear changes from any property change while cloning component
        // Currently, update paintId will add paintId changes to Layer
        cloned.clearChanges()

        this._addMapping(cloned)

        this.changes.create([cloned.id])
        if (fire) {
            this.fire()
        }
        return cloned.id
    }

    cloneLayerToLayerComponent(layerId, layerComponentId, fire = true) {
        const layer = this.getComponent(layerId)
        const layerComponent = this.getComponent(layerComponentId)
        if (!layerComponent || !layer) {
            throw new Error("Trying to clone a non-existent layer or it's parent component doesn't exist")
        }

        const cloned = layer.clone()
        forEachSubcomponent(cloned, (_, subPropName) => {
            if (cloned[subPropName]) {
                cloned[subPropName] = this.cloneComponent(
                    cloned[subPropName],
                    { fire: false, cloneShared: false }
                )
            }
        })

        this._parents.set(cloned.id, layerComponentId)
        cloned.changes.clear()
        this._addMapping(cloned)

        const newLayers = [...layerComponent.layers]
        newLayers.push(cloned.id)

        this.setProperty(layerComponentId, { layers: newLayers }, false, false)

        this.changes.create([cloned.id])
        if (fire) {
            this.fire()
        }
        return cloned.id
    }

    /**
     * Get PropertyComponent instance
     * @param {string} propId
     * @returns {PropertyComponent}
     */
    getProperty(propId) {
        return this.getComponent(propId)
    }

    /**
     * Set data to PropertyComponent
     * @param {string} propId
     * @param {PropertyComponentData} data
     * @param {bool} [undoable=true]
     * @param {bool} [fire=true]
     * @returns {bool}
     */
    setProperty(propId, data, undoable = true, fire = true) {
        if (!this._setProperty(propId, data, undoable)) {
            return false
        }

        // Library fire event, and add changes to UNDO(if undoable)
        if (fire) {
            this.fire(undoable)
        }
        return true
    }

    /**
     * @param {string} propId
     * @param {PropertyComponentData} data
     * @param {bool} undoable
     * @returns {bool}
     */
    _setProperty(propId, data, undoable) {
        const component = this.getComponent(propId)
        if (!component) {
            return false
        }

        component.set(data)

        if (undoable) {
            this.changes.UPDATE.set(propId, component.changes)
        }
        // Component only fire event, not add changes to UNDO
        component.fire(false)
        return true
    }

    /**
     * Set data for multiple PropertyComponents at once
     * @param {Array<string>} propIdList               list of property component IDs to change
     * @param {Array<PropertyComponentData>} dataList  list of data for each property component in the propIdList
     * @param {bool} [undoable=true]
     * @returns {bool}
     */
    setProperties(propIdList, dataList, undoable = true) {
        if (propIdList.length > dataList.length) {
            return false
        }

        let anythingChanged = false
        for (let i = 0; i < propIdList.length; i++) {
            anythingChanged |= this._setProperty(propIdList[i], dataList[i], undoable)
        }
        if (anythingChanged) {
            this.fire(undoable)
            return true
        }
        return false
    }

    /**
     * Delete PropertyComponent instance
     * @param {string} propId
     * @param {bool} [fire=true]
     */
    deleteProperty(propId, fire = true) {
        this.deleteComponent(propId, fire)
    }

    /**
     * Add a new Layer into a LayerComponent
     * @param {string} layerComponentId
     * @param {number} [index]      if omitted, will add at the end of the list
     * @param {LayerData} [data]
     * @returns {string}  new layer ID
     * @throws {Error} if layer component specified by layerComponentId does not exist
     */
    addLayer(layerComponentId, index, data) {
        const layerComponent = this.getComponent(layerComponentId)
        if (!layerComponent) {
            throw new Error("Layer component specified by layerComponentId does not exist")
        }
        const type = layerComponent.layerType
        const newData = { ...data }

        // Create paint component first, but not fire event
        newData.paintId = this.addProperty(
            PropComponentType.PAINT,
            DEFAULT_LAYER_PAINT_DATA[type],
            false
        )

        // Create layer
        const layer = createLayer(this.dataStore, type, newData)
        this._addMapping(layer)

        layerComponent.addLayer(layer.id, index)
        this.changes.UPDATE.set(layerComponentId, layerComponent.changes)
        layerComponent.fire(false)

        // need to keep the mapping to parent LayerComponent
        this._parents.set(layer.id, layerComponentId)

        this.changes.create([layer.id])
        // Library fire event, and add changes to UNDO
        this.fire()
        return layer.id
    }

    /**
     * Get PropertyComponent instance
     * @param {string} layerId
     * @returns {Layer}
     */
    getLayer(layerId) {
        return this.getComponent(layerId)
    }

    /**
     * Set data to Layer
     * @param {string} layerId
     * @param {string} propName     // layer property
     * @param {any} data
     * @param {object} [options]
     * @returns {bool}
     */
    setLayer(layerId, propName, data, options = { undoable: true }) {
        const layer = this.getLayer(layerId)
        if (!layer) {
            return false
        }

        layer.setProperty(propName, data, options)

        if (options?.undoable) {
            this.changes.UPDATE.set(layerId, layer.changes)
        }
        // Layer only fire event, not add changes to UNDO
        layer.fire(false)

        // Library fire event, and add changes to UNDO
        this.fire()
        return true
    }

    /**
     * Delete Layer instance
     * @param {string} layerId
     * @param {bool} fire
     */
    deleteLayer(layerId, fire = true) {
        const layer = this.getComponent(layerId)
        const layerComponentId = this._parents.get(layerId)
        const layerComponent = this.getComponent(layerComponentId)
        if (!layerComponent || !layer) {
            throw new Error("Trying to delete a non-existent layer or it's parent component doesn't exist")
        }

        // don't update the layer when deleting the element (fire = false)
        if (fire) {
            layerComponent.removeLayer(layerId)
            this.changes.update(layerComponentId, 'layers', layerComponent.changes.get('layers'))
        }

        // Component only fire event, not add changes to UNDO
        layerComponent.fire(false)

        // remove nested components first
        //  TODO: add a check for components being shared
        forEachSubcomponent(layer, (_, subPropName) => {
            this.deleteComponent(layer[subPropName], false)
        })

        this._deleteMapping(layerId)

        this.changes.delete([layerId])
        if (fire) {
            this.fire()
        }
    }

    /**
     * Add a new Effect into a EffectComponent
     * @param {string} effectComponentId
     * @param {number} [index]      if omitted, will add at the end of the list
     * @param {EffectData} [data]
     * @returns {string}  new effect ID
     * @throws {Error} if effect component specified by effectComponentId does not exist
     */
    addEffect(effectComponentId, index, data) {
        const effectComponent = this.getComponent(effectComponentId)
        if (!effectComponent) {
            throw new Error("Effect component specified by effectComponentId does not exist")
        }

        // Should not add effect if already has the same effect
        if (effectComponent.hasEffectType(data.effectType)) {
            return
        }

        const newData = { ...data }

        // Create Effect
        const effect = createEffect(this.dataStore, newData)
        this._addMapping(effect)

        effectComponent.addEffect(effect.id, index)
        this.changes.UPDATE.set(effectComponentId, effectComponent.changes)
        effectComponent.fire(false)

        // need to keep the mapping to parent EffectComponent
        this._parents.set(effect.id, effectComponentId)

        this.changes.create([effect.id])
        // Library fire event, and add changes to UNDO
        this.fire()
        return effect.id
    }

    /**
     * Get PropertyComponent instance
     * @param {string} effectId
     * @returns {Effect}
     */
    getEffect(effectId) {
        return this.getComponent(effectId)
    }

    /**
     * Set data to Effect
     * @param {string} effectId
     * @param {string} propName
     * @param {any} data
     * @param {object} [options]
     * @returns {bool}
     */
    setEffect(effectId, propName, data, options) {
        const effect = this.getEffect(effectId)
        if (!effect) {
            return false
        }

        effect.set({ [propName]: data }, options)
        this.changes.UPDATE.set(effectId, effect.changes)
        // Layer only fire event, not add changes to UNDO
        effect.fire(false)

        // Library fire event, and add changes to UNDO
        this.fire()
        return true
    }

    /**
     * Delete Effect instance
     * @param {string} effectId
     * @param {bool} fire
     */
    deleteEffect(effectId, fire = true) {
        const effect = this.getComponent(effectId)
        const effectComponentId = this._parents.get(effectId)
        const effectComponent = this.getComponent(effectComponentId)
        if (!effectComponent || !effect) {
            throw new Error("Trying to delete a non-existent effect or it's parent component doesn't exist")
        }

        effectComponent.removeEffect(effectId)

        this.changes.update(effectComponentId, 'effects', effectComponent.changes.get('effects'))
        // Component only fire event, not add changes to UNDO
        effectComponent.fire(false)

        this._deleteMapping(effectId)

        this.changes.delete([effectId])
        if (fire) {
            this.fire()
        }
    }

    /**
     * Get component instance
     * @param {string} componentId
     * @returns {Component}
     */
    getComponent(componentId) {
        return this.data.get(componentId)
    }

    /**
     * Delete a prop component instance
     *  For deleting layer use deleteLayer()
     * @param {string} componentId
     * @param {bool} [fire=true]
     * @returns {bool}
     */
    deleteComponent(componentId, fire = true) {
        const component = this.getComponent(componentId)
        if (!component) {
            console.error('Trying to delete non-existent component')
            return
        }

        component.removeAllListeners()

        // handle layer components
        if (component.componentType in LayerComponentType) {
            for (const layerId of component.layers) {
                this.deleteLayer(layerId, false)
            }
        } else {
            forEachSubcomponent(component, (subType, subPropName) => {
                // this is where optional components can be, check that id is defined
                if (component[subPropName]) {
                    this.deleteComponent(component[subPropName], false)
                }
            })
        }

        this._deleteMapping(componentId)
        this.changes.delete([componentId])
        if (fire) {
            this.fire()
        }
        return true
    }

    /**
     * Add new component instance mapping (to data map)
     * @private
     * @param {PropertyComponent | Layer} component
     * @returns {string} component ID
     */
    _addMapping(component) {
        this.data.set(component.id, component)
        return component.id
    }

    /**
     * Delete a component instance mapping (from data map to deleted data map)
     * @private
     * @param {string} componentId
     */
    _deleteMapping(componentId) {
        const component = this.data.get(componentId)
        if (!component) {
            return
        }
        this.deletedMap.set(component.id, component)
        this.data.delete(component.id)
    }

    /**
     * Restore a component instance mapping (from deleted map to data map)
     * @private
     * @param {string} componentId
     */
    _restoreMapping(componentId) {
        const component = this.deletedMap.get(componentId)
        if (!component) {
            return
        }
        this.data.set(component.id, component)
        this.deletedMap.delete(component.id)
    }

    /**
     * Undo events for Library
     * @param {Changes} changes
     */
    undo(changes) {
        changes.DELETE.forEach(componentId => {
            this._restoreMapping(componentId)
        })
        changes.UPDATE.forEach((changes, componentId) => {
            const component = this.getComponent(componentId)
            if (!component) {
                return
            }
            if (component.type === EntityType.LAYER) {
                for (const [propName, change] of changes) {
                    this.setLayer(componentId, propName, change.before, { undoable: false })
                }
            } else {
                const undoData = {}
                for (const [propName, change] of changes) {
                    undoData[propName] = change.before
                    if (propName === 'layers') {
                        change.before.forEach(layerId => {
                            this._parents.set(layerId, componentId)
                        })
                    }
                    if (propName === 'effects') {
                        change.before.forEach(effectId => {
                            this._parents.set(effectId, componentId)
                        })
                    }
                }
                this.setProperty(componentId, undoData, false)
            }
        })
        changes.CREATE.forEach(componentId => {
            this._deleteMapping(componentId)
        })
    }

    /**
     * Redo events for Library
     * @param {Changes} changes
     */
    redo(changes) {
        changes.CREATE.forEach(componentId => {
            this._restoreMapping(componentId)
        })
        changes.UPDATE.forEach((changes, componentId) => {
            const component = this.getComponent(componentId)
            if (!component) {
                return
            }
            if (component.type === EntityType.LAYER) {
                for (const [propName, change] of changes) {
                    this.setLayer(componentId, propName, change.after, { undoable: false })
                }
            } else {
                const undoData = {}
                for (const [propName, change] of changes) {
                    undoData[propName] = change.after
                    if (propName === 'layers') {
                        change.after.forEach(layerId => {
                            this._parents.set(layerId, componentId)
                        })
                    }
                    if (propName === 'effects') {
                        change.after.forEach(effectId => {
                            this._parents.set(effectId, componentId)
                        })
                    }
                }
                this.setProperty(componentId, undoData, false)
            }
        })
        changes.DELETE.forEach(componentId => {
            this._deleteMapping(componentId)
        })
    }

}

/**
 * @callback subCallback
 * @param {PropComponentType} subType
 * @param {string} subPropName
 */

/**
 * Iterate over all designated nested components in the provided component type
 * @param {Layer | PropertyComponent | LayerType | PropComponentType} cmp
 * @param {subCallback} cb
 */
function forEachSubcomponent(cmp, cb) {
    let type = isNull(cmp.componentType) ? cmp.layerType : cmp.componentType
    if (isNull(type)) {
        type = cmp
    }

    if (type in HAS_SUB_COMPONENT) {
        const subComponentList = HAS_SUB_COMPONENT[type]
        for (const componentConf of subComponentList) {
            cb(componentConf.subType, componentConf.subPropName)
        }
    }
}

/**
 * Creates a new property of provided class, loads property from data or find it in the library by reference
 * @param {DataStore} dataStore
 * @param  {PropComponentType} type - type of the PropertyComponent
 * @param  {PropData} propData
 * @param {object} [options]
 * @param {bool} [options.regenId=false]   if set to true, will generate new ID
 * @returns {PropretyComponent} instance of classOrClassName
 */
function createComponent(dataStore, type, propData, options) {
    if (!(type in PropComponentType)) {
        throw new Error(`Unsupported property component type ${type} was provided`)
    }

    const propClass = COMPONENT_CLASSES.get(type)
    const prop = new propClass(dataStore, propData, options)
    return prop
}

export default Library
