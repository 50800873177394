import { ImageMode, PaintType, BlendMode, PropComponentType } from '@phase-software/types'
import { notNull, Vector4, Matrix2D, ColorStop } from '@phase-software/data-utils'
import { DEFAULT_FILL_VALUE, DEFAULT_GRADIENT_PAINT_DATA } from '../constant'
import PropertyComponent from './PropertyComponent'

/** @typedef {import('../DataStore').DataStore} DataStore */
/** @typedef {import('./PropertyComponent').AppliedRef} AppliedRef */

// TODO: zod validation
const properties = {
    paintType: {
        default: PaintType.SOLID
    },
    blendMode: {
        default: BlendMode.NORMAL
    },
    opacity: {
        default: 1
    },
    color: {
        default: DEFAULT_FILL_VALUE.color,
        implementation: (v) => new Vector4(v)
    },
    gradientStops: {
        default: DEFAULT_GRADIENT_PAINT_DATA,
        implementation: (v) => v.map(gradientStop => new ColorStop(gradientStop))
    },
    gradientTransform: {
        default: [0, -1, 1, 0, 0, 1],
        implementation: (v) => new Matrix2D(v)
    },
    imageId: {
        default: null,
    },
    imageMode: {
        default: ImageMode.FILL,
    }
}

export default class PaintComponent extends PropertyComponent {
    /**
     * @param {DataStore} dataStore
     * @param {PaintComponentData} [data]
     * @param {object} [options]
     * @param {bool} [options.regenId=false]   if set to true, will generate new ID
     */
    constructor(dataStore, data = {}, options) {
        super(dataStore, data, options)

        // read-only
        this.componentType = PropComponentType.PAINT

        this._initValues(data)

        // FIXME (shiny): should not be the part of data
        this.activeGradientStopIdx = 0

        if (!this.name) {
            this.name = 'paint'
        }
    }

    get initValues() {
        return Object.entries(properties).reduce((acc, [key, define]) => {
            acc[key] = define.default
            return acc
        }, {})
    }

    _initValues(data = {}) {
        Object.entries(properties).forEach(([key, define]) => {
            const value = key in data ? data[key] : define.default
            if (define.implementation) {
                this[key] = define.implementation(value)
            } else {
                this[key] = value
            }
        })
    }

    /** @param {Partial<PaintComponentData>} data */
    set(data) {
        super.set(data)

        if (data.paintType in PaintType) {
            this.updateProp('paintType', data.paintType)
        }
        if (data.blendMode in BlendMode) {
            this.updateProp('blendMode', data.blendMode)
        }
        if (notNull(data.opacity)) {
            this.updateProp('opacity', data.opacity)
        }

        const paintType = data.paintType || this.paintType
        if (paintType === PaintType.SOLID) {
            if (notNull(data.color)) {
                this.updateProp('color', new Vector4(data.color))
            }
        } else if (paintType === PaintType.IMAGE) {
            if (notNull(data.imageId)) {
                this.updateProp('imageId', data.imageId)
            }
            if (data.imageMode in ImageMode) {
                this.updateProp('imageMode', data.imageMode)
            }
        } else { // gradient
            if (notNull(data.gradientTransform)) {
                const gradientTransform = new Matrix2D(data.gradientTransform)
                this.updateProp('gradientTransform', gradientTransform)
            }
            if (notNull(data.gradientStops)) {
                const gradientStops = data.gradientStops.map(gradientStop => new ColorStop(gradientStop))
                this.updateProp('gradientStops', gradientStops)
            }
            if (notNull(data.activeGradientStopIdx)) {
                this.updateProp('activeGradientStopIdx', data.activeGradientStopIdx)
            }
        }
    }

    /**
     * Override this in subclasses
     * CALL super._clone() at the top of overriden method
     * @protected
     * @param {AppliedRef} [ref]
     * @returns {PaintComponent} obj
     */
    _clone(ref) {
        const obj = super._clone(ref)
        obj.paintType = this.paintType
        obj.blendMode = this.blendMode
        obj.opacity = this.opacity

        // solid
        obj.color = new Vector4(this.color)

        // gradient
        obj.gradientTransform = new Matrix2D(this.gradientTransform)
        obj.gradientStops = this.gradientStops.map(gradientStop => new ColorStop(gradientStop))

        // image
        obj.imageId = this.imageId
        obj.imageMode = this.imageMode
        return obj
    }

    /*
     * Override this in subclasses
     * CALL super._save() at the top of overriden method
     * @protected
     * @returns {PaintComponentData} data
     */
    _save() {
        const data = super._save()
        data.paintType = this.paintType
        data.blendMode = this.blendMode
        data.opacity = this.opacity
        if (this.paintType === PaintType.SOLID) {
            data.color = [...this.color]
        } else if (this.paintType === PaintType.IMAGE) {
            data.imageId = this.imageId
            data.imageMode = this.imageMode
        } else { // gradient
            data.gradientTransform = [...this.gradientTransform]
            data.gradientStops = this.gradientStops.map(gradientStop => gradientStop.save())
        }
        return data
    }

    /**
     * Override this in subclasses if subclass has any inner sharable PropertyComponents
     * CALL super.apply() at the top of overriden method
     * @param {AppliedRef} ref
     */
    apply(ref) {
        super.apply(ref)
    }

    /**
     * Override this in subclasses if subclass has any inner sharable PropertyComponents
     * CALL super.cancel() at the top of overriden method
     * @param {AppliedRef} ref
     */
    cancel(ref) {
        super.cancel(ref)
    }
}

/** @typedef {('SOLID'|'GRADIENT'|'IMAGE')} PaintType */
/** @typedef {('NORMAL'|'DARKEN'|'MULTIPLY'|'COLOR_BURN'|'LIGHTEN'|'SCREEN'|'COLOR_DODGE'|'OVERLAY'|
 *      'SOFT_LIGHT'|'HARD_LIGHT'|'DIFFERENCE'|'EXCLUSION'|'HUE'|'SATURATION'|'COLOR'|'LUMINOSITY'|
 *      'DIVIDE'|'ADD'|'SUBTRACT'|'DISSOLVE')} BlendMode
 */

/** @typedef {import('./PropertyComponent').PropertyComponentData} PropertyComponentData */
/** @typedef {import('../../../data-utils/src/ColorStop').ColorStopData} ColorStopData */


/**
 * @typedef {PropertyComponentData} PaintComponentData
 * @property {PaintType} paintType
 * @property {BlendMode} blendMode
 * @property {number} opacity       range of [0.0, 1.0]
 * @property {Vector4} color
 * @property {Matrix2D} gradientTransform
 * @property {ColorStopData[]} gradientStops
 * @property {number} activeGradientStopIdx
 * @property {string} imageId
 * @property {ImageMode} imageMode
 */
