import { LogicType } from '@phase-software/types'

export const execRow = (dataStore, condition) => {
  const { elementId, operation } = condition;
  const element = dataStore.getById(elementId);
  let operationResult;
  // FIXME: Pass it for MVP
  if (element && operation) {
    operationResult = true
  }

  return operationResult;
};

const conditionsExec = (dataStore, conditionIds) => {
  if (!conditionIds.length) {
    return true;
  }

  let orStatements = [];
  const getORGroupResult = () => {
    if (orStatements.length === 0) {
      return true;
    }

    return orStatements.reduce((prev, curr) => prev || curr, false);
  };

  for (const conditionId of conditionIds) {
    const condition = dataStore.interaction.getCondition(conditionId)
    const rowResult = execRow(dataStore, condition);
    switch (condition.logic) {
      case LogicType.AND:
        if (!getORGroupResult()) {
          return false;
        }
        orStatements = [rowResult];
        break;
      case LogicType.OR:
        orStatements.push(rowResult);
        break;
      default:
        return false;
    }
  }
  return getORGroupResult();
};

export default conditionsExec;
