import { ElementType } from '@phase-software/types'
import { setAdd } from '@phase-software/data-utils'
import { Element } from './Element'


/** @typedef {import('./DataStore').DataStore} DataStore */


const UNDO_CHANGES = [
    'text',
    // from ComputedStyle text
    'fontFamily', 'fontStyle', 'fontSize', 'fontSizeUnit', 'minSize', 'maxSize',
    'horizontalAlignment', 'verticalAlignment', 'underline', 'strikethrough', 'cases',
    'subSuper', 'textDirection', 'characterSpacing', 'wordSpacing', 'lineSpacing',
    'paragraphSpacing', 'paragraphIndent'
]


export class Text extends Element {
    /**
     * @param {DataStore} dataStore
     * @param {TextData} [data]
     */
    constructor(dataStore, data) {
        super(dataStore, data)

        setAdd(this.undoChanges, UNDO_CHANGES)
    }

    /**
     * creates a blank Text element
     * @protected
     */
    create() {
        super.create()

        this.data.elementType = ElementType.TEXT

        this.data.text = ''
    }

    /**
     * @param {TextData} data
     */
    load(data) {
        super.load({...data, elementType: ElementType.TEXT})

        this.data.text = data.text
    }

    /**
     * @param {object} [overrides] data object with overrides
     * @returns {Text}
     */
    clone(overrides) {
        const obj = super.clone(overrides)

        obj.data.text = this.data.text
        return obj
    }

    /**
     * @returns {TextData}
     */
    save() {
        const data = super.save()

        data.text = this.data.text
        return data
    }
}

/** @typedef {import('./Element').ElementData} ElementData */

/**
 * @typedef {ElementData} TextData
 * @property {string} text
 */
