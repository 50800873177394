import { schema } from 'normalizr'
import { createProvider, useEntity } from '../utils'

export const TextDirection = new schema.Entity(
  'textDirections',
  { data: {} },
  {
    processStrategy: (value, parent) => {
      return { ...value, styleId: parent.id }
    }
  }
)

const defaultValue = {}
const [Provider, useSelectState, useSetState] = createProvider('TextDirection', defaultValue)

export const useTextDirection = useSelectState

export const useSetTextDirection = () => {
  const setState = useSetState()
  const [
    setTextDirection,
    addTextDirection,
    updateTextDirection,
    removeTextDirection,
    mergeTextDirection,
    reduceTextDirection,
    createTextDirection,
    destroyTextDirection
  ] = useEntity(TextDirection, setState)

  return {
    setTextDirection,
    addTextDirection,
    updateTextDirection,
    removeTextDirection,
    mergeTextDirection,
    reduceTextDirection,
    createTextDirection,
    destroyTextDirection
  }
}

export default Provider
