// 1. fallback heap
window.heap = window.heap || []

// 2. fallback heap[method]
const createHeapFunction = (methodName) => {
  return (...args) => {
    window.heap.push([methodName].concat(Array.prototype.slice.call(...args, 0)))
  }
}

const methods = [
  'addEventProperties',
  'addUserProperties',
  'clearEventProperties',
  'identify',
  'resetIdentity',
  'removeEventProperty',
  'setEventProperties',
  'track',
  'unsetEventProperty'
]

methods.forEach((methodName) => {
  window.heap[methodName] = createHeapFunction(methodName)
})

// 3. heap load
window.heap.load = (appId, config) => {
  window.heap.appid = appId
  window.heap.config = config || {}

  const scriptTag = document.createElement('script')
  scriptTag.type = 'text/javascript'
  scriptTag.async = true
  scriptTag.src = 'https://cdn.heapanalytics.com/js/heap-' + appId + '.js'
  const firstScript = document.getElementsByTagName('script')[0]
  firstScript.parentNode.insertBefore(scriptTag, firstScript)
}

export const load = (appId) => {
  if (!window.heap.loaded) {
    window.heap.load(appId)
  }
}

export const identify = (userId) => {
  window.heap.identify(userId)
}

export const resetIdentity = () => {
  window.heap.resetIdentity()
}

export const track = (eventName, properties = {}) => {
  window.heap.track(eventName, properties)
}

export const addUserProperties = (userProperties = {}) => {
  window.heap.addUserProperties(userProperties)
}

export const addEventProperties = (eventProperties = {}) => {
  window.heap.addEventProperties(eventProperties)
}

export const clearEventProperties = () => {
  window.heap.clearEventProperties()
}
