import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { ActionData } from '@phase-software/types'

import useHeapAnalytics from '../../hooks/useHeapAnalytics'
import { useFileContext } from '../../providers/FileProvider'
import { useSetModal } from '../../providers/ModalProvider'
import { useTransitionManagerActions } from '../../providers/TransitionManagerProvider'
import { useDataStoreActions } from '../../providers/dataStore/DataStoreProvider'
import { useInteraction } from '../../providers/dataStore/InteractionProvider'
import { useUI } from '../../providers/dataStore/UIProvider'
import { track } from '../../services/heapAnalytics'
import { Button } from '../shared'

export const useExportModal = () => {
  const actionList: ActionData['id'][] = useInteraction('actionList') || []
  const action: ActionData = useInteraction(actionList?.[0])
  const { openModal, closeAllModals } = useSetModal('ExportMediaDialog')
  const { stopAnimation } = useTransitionManagerActions()
  const { space, teamName } = useHeapAnalytics()
  const { id: fileId, projectId } = useFileContext()
  const { editOrigin } = useUI()
  const { setFeature } = useDataStoreActions()

  const handleExport = useCallback(() => {
    stopAnimation()
    closeAllModals()
    if (editOrigin) {
      setFeature('editOrigin', false)
    }
    openModal({ action })
    track('Export Button Clicked', { space, teamName, projectId, fileId })
  }, [action, stopAnimation, closeAllModals, editOrigin, openModal, space, teamName, projectId, fileId, setFeature])

  return { handleExport }
}

const Export = () => {
  const { handleExport } = useExportModal()
  const { t } = useTranslation('file')

  return (
    <Button
      size="l"
      variant="solid"
      color="primary"
      data-test-id="editor-top-tool-export-button"
      onClick={handleExport}
    >
      {t('export_button')}
    </Button>
  )
}

export default Export
