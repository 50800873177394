import { IS_MACINTOSH } from './constants'

const KEY_DELIMINATOR = '+'
const PASSTROUGH = 'PASSTROUGH'

/** @type {Map<string, KeyCombo>} */
const cache = new Map()

/**
 * @param {string} keyComboStr
 * @returns {KeyCombo}
 */
export const getKeyCombo = keyComboStr => {
    if (!keyComboStr) return null
    const found = cache.get(keyComboStr)
    if (found) return found
    const combo = new KeyCombo(keyComboStr)
    cache.set(keyComboStr, combo)
    return combo
}

export class KeyCombo {
    /**
     * @param {string} keyComboStr
     */
    constructor(keyComboStr) {
        this.isPasstrough = keyComboStr === PASSTROUGH
        this.sourceStr = keyComboStr

        const keyNames = KeyCombo._splitStr(keyComboStr, KEY_DELIMINATOR)
        this.triggerKeyCode = keyNames.pop()
        this.modifiers = keyNames.reduce((acc, keyName) => {
            acc[keyName.toLowerCase()] = true
            return acc
        }, {
            modifier: false,
            ctrl: false,
            shift: false,
            alt: false
        })
    }

    /**
     * @param {string} string
     * @param {string} separator
     * @returns {string[]}
     */
    static _splitStr(string, separator) {
        const str = string.replace(/ /g, '')
        let token = ''
        const res = []

        for (let i = 0; i < str.length; i++) {
            // if next char is the separator
            if (i !== str.length - 1 && str[i + 1] === separator) {
                // is separator escaped?
                token += str[i] === '\\' ? separator : str[i]
                res.push(token)
                token = ''
                i++
                continue
            }
            token += str[i]
        }
        if (token) res.push(token)

        return res
    }

    /**
     * @param {KeyCombo} keyCombo
     * @param {boolean} skipModifierCheck
     * @returns {boolean}
     */
    shouldTrigger(keyCombo, skipModifierCheck) {
        if (this.isPasstrough) return true
        if (keyCombo.key !== this.triggerKeyCode) return false
        if (skipModifierCheck) return true

        return Object.entries(this.modifiers).every(([modifier, pressed]) => {
            if (keyCombo.modifiers[modifier] === pressed) {
                return true
            }

            if (!IS_MACINTOSH) {
                switch (modifier) {
                    case 'ctrl':
                        return keyCombo.modifiers.modifier === this.modifiers.modifier
                    case 'modifier':
                        return keyCombo.modifiers.modifier || keyCombo.modifiers.ctrl
                }
            }
        })
    }
}
