import React from 'react'
import { useTranslation } from 'react-i18next'

import { LayerType } from '@phase-software/types'

import { IconButton, Text } from '../shared'

type PaintPickerHeaderProps = {
  activeLayerType: number
  onClickClose: () => void
}

const PaintPickerHeader = ({ activeLayerType, onClickClose }: PaintPickerHeaderProps) => {
  const { t } = useTranslation('file', { keyPrefix: 'property_editor.fill_editor.paint_picker' })
  const adjustingShadow = activeLayerType === LayerType.SHADOW || activeLayerType === LayerType.INNER_SHADOW

  return (
    <div className="flex items-center mb-16">
      <Text className="flex-1 text-white font-medium">{adjustingShadow ? t('background') : t('color')}</Text>
      <IconButton icon="Cross" onClick={onClickClose} />
    </div>
  )
}

export default PaintPickerHeader
