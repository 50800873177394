import { LayerListKey } from '@phase-software/data-store'
import {
  BlendMode,
  ConfirmationType,
  EffectType,
  EndShape,
  ImageMode,
  JoinShape,
  OpacityType,
  PaintType
} from '@phase-software/types'

export { MIN_SIZE_VALUE } from '@phase-software/data-store'

// FIXME: Need to fix this for UI. It's already removed from TM
/**
 * @constant
 * @type {Array} general transition properties
 */
export const GENERAL_TRANSITION_PROPERTIES = [
  {
    name: 'Position',
    key: 'position',
    children: [
      {
        name: 'X',
        key: 'x'
      },
      {
        name: 'Y',
        key: 'y'
      }
    ]
  },
  {
    name: 'Size',
    key: 'dimensions',
    children: [
      {
        name: 'Width',
        key: 'width'
      },
      {
        name: 'Height',
        key: 'height'
      }
    ]
  },
  {
    name: 'Rotation',
    key: 'rotation'
  },
  {
    name: 'Opacity',
    key: 'opacity'
  }
]

/**
 * @constant
 * @type {Array} layer transition properties
 */
export const LAYER_TRANSITION_PROPERTIES = [
  {
    name: 'Fill',
    key: LayerListKey.FILL
  },
  {
    name: 'Stroke',
    key: LayerListKey.STROKE,
    children: [
      {
        name: 'Opacity',
        key: 'opacity'
      },
      {
        name: 'Width',
        key: 'width'
      },
      {
        name: 'Offset',
        key: 'offset'
      }
    ]
  },
  {
    name: 'Shadow',
    key: LayerListKey.SHADOW,
    children: [
      {
        name: 'Paint',
        key: 'paint'
      },
      {
        name: 'OffsetX',
        key: 'offsetX'
      },
      {
        name: 'OffsetY',
        key: 'offsetY'
      },
      {
        name: 'Blur',
        key: 'blur'
      },
      {
        name: 'Spread',
        key: 'spread'
      }
    ]
  },
  {
    name: 'InnerShadow',
    key: LayerListKey.INNER_SHADOW,
    children: [
      {
        name: 'Paint',
        key: 'paint'
      },
      {
        name: 'OffsetX',
        key: 'offsetX'
      },
      {
        name: 'OffsetY',
        key: 'offsetY'
      },
      {
        name: 'Blur',
        key: 'blur'
      },
      {
        name: 'Spread',
        key: 'spread'
      }
    ]
  }
]
export const ROW_HEIGHT = 36
export const TRACK_HEIGHT = ROW_HEIGHT + 4

export const EMPTY_VALUE = 'none'

export const LAYER_MENU = 'LAYER_MENU'
export const STYLE_MENU = 'STYLE_MENU'
export const TRACK_MENU = 'TRACK_MENU'
export const EVENT_MENU = 'EVENT_MENU'

export const APP_BORDER_COLOR = '#d9d9d9'

export const STROKE_JOIN = {
  NONE: JoinShape.NONE,
  MITER: JoinShape.MITER,
  CONCAVE: JoinShape.CONCAVE,
  ROUND: JoinShape.ROUND,
  BEVEL: JoinShape.BEVEL
}

export const STROKE_ENDS = {
  STRAIGHT: EndShape.STRAIGHT,
  ROUND: EndShape.ROUND
}

export const SCREEN_FIT_MODE = {
  FIT: 'FIT',
  FILL: 'FILL'
}

export const FILE_EDITOR_LAYOUT_AREAS = {
  HEADER: 'header',
  CONTENT_PANEL: 'element',
  RIGHT_PANEL: 'right-panel',
  CANVAS: 'canvas',
  ACTION_PANEL: 'interaction'
}

/**
 * @constant
 * @type {number} Default Timeline adjustable height
 */
export const TIMELINE_ADJUST_HEIGHT = 280

/**
 * @constant
 * @type {number} Timeline adjustable minimum height
 */
export const TIMELINE_ADJUST_MIN_HEIGHT = 64

/**
 * @constant
 * @type {number} Default ElementPanel adjustable width
 */
export const ELEMENT_PANEL_ADJUST_WIDTH = 248

export const RIGHT_PANEL_WIDTH = 248

export const LAYER_BLEND_MODE_OPTIONS = [
  { name: 'normal', value: BlendMode.NORMAL },
  '-',
  { name: 'darken', value: BlendMode.DARKEN },
  { name: 'multiply', value: BlendMode.MULTIPLY },
  { name: 'color_burn', value: BlendMode.COLOR_BURN },
  '-',
  { name: 'lighten', value: BlendMode.LIGHTEN },
  { name: 'screen', value: BlendMode.SCREEN },
  { name: 'color_dodge', value: BlendMode.COLOR_DODGE },
  '-',
  { name: 'overlay', value: BlendMode.OVERLAY },
  { name: 'soft_light', value: BlendMode.SOFT_LIGHT },
  { name: 'hard_light', value: BlendMode.HARD_LIGHT },
  '-',
  { name: 'difference', value: BlendMode.DIFFERENCE },
  { name: 'exclusion', value: BlendMode.EXCLUSION },
  '-',
  { name: 'hue', value: BlendMode.HUE },
  { name: 'saturation', value: BlendMode.SATURATION },
  { name: 'color', value: BlendMode.COLOR },
  { name: 'luminosity', value: BlendMode.LUMINOSITY }
  // { name: 'Divide', value: BlendMode.DIVIDE },
  // { name: 'Add', value: BlendMode.ADD },
  // { name: 'Subtract', value: BlendMode.SUBTRACT },
  // { name: 'Dissolve', value: BlendMode.DISSOLVE }
]

export const OPACITY_BLEND_MODE_OPTIONS = [
  { name: 'pass_through', value: BlendMode.PASS_THROUGH },
  ...LAYER_BLEND_MODE_OPTIONS
]

export const IMAGE_REPEAT = {
  PLAIN: 'PLAIN',
  SPACE: 'SPACE',
  ROUND: 'ROUND'
}

export const IMAGE_REPEAT_OPTIONS = [
  { name: 'Plain', value: 'PLAIN' },
  { name: 'Space', value: 'SPACE' },
  { name: 'Round', value: 'ROUND' }
]

export const OPACITY_PAINT_TYPE_OPTIONS = [
  { name: 'Solid', value: OpacityType.SOLID },
  { name: 'Linear', value: OpacityType.LINEAR },
  { name: 'Radial', value: OpacityType.RADIAL },
  { name: 'Angular', value: OpacityType.ANGULAR },
  { name: 'Diamond', value: OpacityType.DIAMOND }
]

export const IMAGE_MODE_OPTIONS = [
  { name: 'fill', value: ImageMode.FILL },
  { name: 'stretch', value: ImageMode.STRETCH },
  { name: 'fit', value: ImageMode.FIT }
]

export const IMAGE_MODE_MAP = {
  [ImageMode.FILL]: 'fill',
  [ImageMode.STRETCH]: 'stretch',
  [ImageMode.FIT]: 'fit'
}

export const SHADOW_PAINT_TYPE_OPTIONS = [{ name: 'Solid', value: PaintType.SOLID }]

export const LAYER_PAINT_TYPE_OPTIONS = [...OPACITY_PAINT_TYPE_OPTIONS, { name: 'Image', value: PaintType.IMAGE }]

// TODO: keep all these constants in one place
export const PAINT_PROPS_SET = new Set([
  'imageId',
  'imageMode',
  'paintType',
  'paintState',
  'blendMode',
  'blendModeState',
  'opacity',
  'opacityState',
  'color',
  'gradientType',
  'gradientTransform',
  'gradientStops',
  'activeGradientStopIdx'
])

/**
 * @constant
 * @type {object} StyleSwitcher types
 */
export const SWITCHER_TYPES = {
  ADD: 'ADD',
  REMOVE: 'REMOVE',
  TOGGLE: 'TOGGLE',
  TO: 'TO'
}

/**
 * @constant
 * @type {object} StyleSwitcher icon maps
 */
export const SWITCHER_ICON_MAP = {
  ADD: 'Add',
  REMOVE: 'Remove',
  TOGGLE: 'Toggle'
}

/**
 * @constant
 * @type {object} StyleSwitcher color maps
 */
export const SWITCHER_COLOR_MAP = {
  ADD: ['limes', '50'],
  REMOVE: ['rubins', '50'],
  TOGGLE: ['golds', '50']
}

/**
 * @constant
 * @type {string} The delimiter of track id
 */
export const TRACK_DELIM = '_'

/**
 * @constant
 * @type {object} All types of tracks
 */
export const TRACK_TYPE = {
  ELEMENT_TRACK: 'ELEMENT_TRACK',
  STYLE_TRACK: 'STYLE_TRACK',
  PROPERTY_TRACK: 'PROPERTY_TRACK',
  ADD_SELECTOR_TRACK: 'ADD_SELECTOR_TRACK',
  PARENT_TRACK: 'PARENT_TRANSITION',
  TRANSITION_TRACK: 'TRANSITION'
}

/**
 * @constant
 * @type {number} The indent space between child track and its parent track
 */
export const TRACK_INDENT = 20

export const EVENTS = [
  { name: 'Click', value: 'CLICK', helpText: 'Tap' },
  { name: 'Double click', value: 'DOUBLE_CLICK', helpText: 'Tap' },
  { name: 'Press', value: 'PRESS' },
  { name: 'Long press', value: 'LONG_PRESS' },
  { name: 'Force tap', value: 'FORCE_TAP' },
  '-',
  { name: 'Hover', value: 'HOVER' },
  { name: 'Mouse move', value: 'MOUSE_MOVE' },
  '-',
  { name: 'Drag', value: 'DRAG' },
  { name: 'Swipe', value: 'SWIPE' },
  '-',
  { name: 'Pinch', value: 'PINCH' },
  { name: 'Rotate', value: 'ROTATE' },
  '-',
  { name: 'Key press', value: 'KEY_PRESS' },
  '-',
  { name: 'Scroll', value: 'SCROLL' }
]

export const SCREEN_EVENTS = [...EVENTS, '-', { name: 'Enter screen', value: 'LOAD' }]

/**
 * @constant
 * @type {string} Gradient name maps
 */
export const GRADIENT_NAME = {
  LINEAR: 'Linear gradient',
  RADIAL: 'Radial gradient',
  ANGULAR: 'Angular gradient',
  DIAMOND: 'Diamond gradient'
}
export const OPERATION_POINTER_Y = ROW_HEIGHT / 2

/**
 * @constant
 * @type {object} thumbnail width maps
 */
export const THUMBNAIL_WIDTH = {
  L: 360,
  M: 168,
  S: 110
}

/**
 * @constant
 * @type {number} maximum height of the thumbnail
 */
export const MAX_THUMBNAIL_HEIGHT = 250

export const DEFAULT_TRANSITION_PROPERTIES = {
  delay: 0,
  duration: 500,
  easing: 'ease',
  customEasing: { x1: 0, y1: 1, x2: 1, y2: 0 },
  folded: false,
  animation: true,
  disabled: false,
  exist: true
}

/**
 * @constant
 * @type {number} The ratio for changing viewbox of the EasingEditor
 */
export const EASING_HEIGHT_RATIO = 2

export const NON_INHERIT_TITLE = 'Erase Existing'

export const NOTIFICATION_MAP = {
  DELETE_MIN_GRADIENT_STOPS: {
    type: 'info',
    content: 'Need at least 2 gradient stop for gradient paint.'
  },
  UPLOADED_PHOTO_IS_OVER_SIZE_LIMIT: {
    type: 'warning',
    content: 'upload_photo_is_over_limit'
  }
}

export const CONFIRMATION_MAP = {
  [ConfirmationType.IMPORT_LOTTIE_REMINDER_DIALOG_IN_ACTION]: {
    key: 'IMPORT_LOTTIE_REMINDER_DIALOG',
    subject: 'Lottie import notice',
    content:
      'If the Lottie(.json) file that you imported contains features that Phase does not currently support, some elements may be missing or the result may look different than expected.',
    confirmText: 'Ok, I understand'
  },
  [ConfirmationType.IMPORT_DOTLOTTIE_MULTIPLE_ANIMATIONS_DIALOG_IN_ACTION]: {
    key: 'IMPORT_DOTLOTTIE_MULTIPLE_ANIMATIONS_DIALOG',
    subject: 'Multiple Animations Detected',
    content:
      "Phase currently doesn't support editing multiple animations in one file. Each animation from the file will be imported separately to the dashboard.",
    confirmText: 'Ok, I understand'
  }
}

export const EFFECT_PROPS_NAME_MAP = {
  [EffectType.TRIM_PATH]: 'trimPath'
}

export const EFFECT_PROPS_DISPLAY_NAME_MAP = {
  [EffectType.TRIM_PATH]: 'trim_path'
}

export const UPLOAD_SVG_ERROR_MESSAGE = `Oops! Something went wrong while processing your SVG file. Our server is currently experiencing issues. Please try importing your SVG file later.`

export const DISALLOWED_IMPORT_MULTIPLE_FILE_MESSAGE = `We currently don't support importing multiple files. Please select and import one file at a time.`

// https://developer.mozilla.org/en-US/docs/Web/Media/Formats/Image_types
export const ALLOWED_UPLOAD_IMAGE_TYPE_SET = new Set([
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/webp',
  'image/bmp',
  'image/x-icon',
  'image/vnd.microsoft.icon'
])

export const ALLOWED_UPLOAD_SVG_TYPE_SET = new Set(['image/svg+xml'])

export const ALLOWED_LAYER_IMAGE_TYPE_SET = new Set([...ALLOWED_UPLOAD_IMAGE_TYPE_SET, 'image/svg+xml'])

export const DEFAULT_DIALOG_TRANSITION_TIME = 200

export const UNSUPPORTED_FEATURE_STORAGE_KEY = 'unsupportedFeatureLottieFiles'

export const MY_WORKSPACE = 'my_workspace'

export const MY_WORKSPACE_DESCRIPTION = 'personal'

export const TEAM_WORKSPACE_DESCRIPTION = 'team'

export const NOTIFICATION_ANIMATION_MS = 300

export const NOTIFICATION_ANIMATION_S = NOTIFICATION_ANIMATION_MS / 1000

export const SESSION_STORAGE_KEYS = {
  REDIRECT_URL: 'redirectUrl',
  CACHED_TAB_ID: 'cachedTabId',
  CACHED_USER: 'cachedUser',
  KEY: 'key'
}
