import React from 'react'
import { PaintType } from '@phase-software/types'
import { checkerboardBase64Url } from '@phase-software/data-utils'

import { getCssColorString } from '../../utils/color'

const checkerboardBackground = `linear-gradient(45deg, #484755 25%, transparent 25%) 0 0 / 8px 8px,
        linear-gradient(-45deg, #484755 25%, transparent 25%) 0 4px / 8px 8px,
        linear-gradient(45deg, transparent 75%, #484755 75%) 4px -4px / 8px 8px,
        linear-gradient(-45deg, transparent 75%, #484755 75%) -4px 0 / 8px 8px`

const getCssUrlString = (url?: string) =>
  `url(${url || checkerboardBase64Url}) center center/contain  no-repeat border-box`

export type ColorBoxProps = {
  className?: string
  color: number[]
  gradientStops: object[]
  paintType: PaintType
  size: 's' | 'm' | 'l'
  imageUrl?: string
}

const getSizeStyles = (size: 's' | 'm' | 'l') => {
  switch (size) {
    case 's':
      return { iconSize: 'w-10 h-10', rounded: 'rounded-xs' }
    case 'm':
      return { iconSize: 'w-16 h-16', rounded: 'rounded' }
    case 'l':
      return { iconSize: 'w-24 h-24', rounded: 'rounded-md' }
    default:
      return { iconSize: 'w-24 h-24', rounded: 'rounded-md' }
  }
}

const ColorBox = ({ color, paintType, gradientStops, size = 'l', imageUrl }: ColorBoxProps) => {
  let preview
  let background
  if (paintType === PaintType.IMAGE) {
    preview = getCssUrlString(imageUrl)
    background = '#FFFFFF'
  } else {
    preview = getCssColorString({
      color,
      paintType,
      gradientStops
    })
    background = checkerboardBackground
  }

  const { iconSize, rounded } = getSizeStyles(size)

  return (
    <div className={`${iconSize} relative overflow-hidden ${rounded}`} style={{ background }}>
      <div
        className={`absolute -inset-0 z-0 ${rounded} border border-light-overlay-20 `}
        style={{ background: preview }}
      />
    </div>
  )
}
export default ColorBox
