import { useCallback, useEffect } from 'react'
import { createProvider } from '../utils'
import { useDataStore } from './DataStoreProvider'

const [Provider, useSelectState, useSetState] = createProvider('HoverSelection', null)

export const useHoverSelection = useSelectState

export const useSetHoverSelection = () => {
  const setState = useSetState()
  const setHoverSelection = useCallback((selection) => setState(selection), [setState])
  return { setHoverSelection }
}

export const useHoverSelectionActions = () => {
  const dataStore = useDataStore()
  const setState = useSetState()

  const handleSelectionHover = useCallback(
    (element) => {
      setState(element ? element.get('id') : null)
    },
    [setState]
  )

  useEffect(() => {
    if (!dataStore) return

    dataStore.selection.on('hover', handleSelectionHover)
    return () => {
      dataStore.selection.off('hover', handleSelectionHover)
    }
  }, [dataStore, handleSelectionHover])

  const hoverElement = useCallback(
    (elementId) => {
      if (dataStore.get('state') === 'VERSIONING') return
      const hover = dataStore.selection.get('hover')
      const element = dataStore.getElement(elementId)
      if (hover === element) {
        return
      }
      dataStore.selection.set('hover', element)
    },
    [dataStore]
  )

  return { hoverElement }
}

export default Provider
