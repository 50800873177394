import { AABB, Vector2 as duVector2, MIN_SIZE_THRESHOLD } from '@phase-software/data-utils'
import { PointShape } from '@phase-software/types'
import { constructHalfEdgePathsFromEdgesInPlace, pushContour } from './geometry/generate'
import { Vector2, Transform2D, Rect2 } from './math'
import { centerSelection } from './actions'
import {
    CENTER_LINEAR,
    CENTER,
    BOTTOM,
    LEFT
} from './actions/gradient'
import { Command, PathData } from './geometry/PathData'
import { getSourceBases } from './visual_server/SpatialCache'
import { BezierShape, BooleanAction } from './geometry/bezier-shape/BezierShape'
import { stroke2 } from './geometry/bezier-shape/stroke2'
import { fixInfiniteSkew } from './visual_server/Transform'
import { MIN_SIZE } from './constants'
import { updateMotionPointMirror } from './panes/MotionPathHelper'
import { CubicBez } from './geometry/bezier-shape/CubicBez'

/** @typedef {import('./visual_server/VisualServer').VisualServer} VisualServer */
/** @typedef {import('./visual_server/Transform').Transform} Transform */
/** @typedef {import('./visual_server/RenderItem').RenderItem} RenderItem */
/** @typedef {import('@phase-software/data-utils').Mesh} Mesh */
/** @typedef {import('./geometry/generate').GetVertexPositionCallback} GetVertexPositionCallback */

/**
 * @todo This class aims to implement Inversion of Control but is missing an interface to establish the required contract.
 */
export class DrawInfo {

    /**
     * Creates a new clipboard
     * @param {VisualServer} visualServer
     */
    constructor(visualServer) {
        this.vs = visualServer
        this.pathCommands = Command
    }

    inject() {
        this.vs.dataStore.injectDrawInfo(this)
    }

    /**
     * Gets reference to canvas element
     * @returns {HTMLCanvasElement}
     */
    getCanvas() {
        return this.vs.canvas
    }

    /**
     * Convert mouse position to world position
     * @param {Vector2} mousePos
     * @returns {Vector2} output position
     */
    convertMousePosToWorldPosition(mousePos) {
        return this.vs.viewport.toWorld(mousePos)
    }

    toBaseWorldPosition(elementId, pos, out = new duVector2()) {
        const node = this.vs.getRenderItem(elementId)
        if (!node) {
            throw new Error(`Invalid element ID provided ${elementId}`)
        }
        if (this.vs.dataStore.isWorkspace(elementId)) {
            out.x = pos.x
            out.y = pos.y
            return out
        }
        const world = node.baseTransform.world

        /** @type {Vector2} */
        const worldPos = this._applyMatrix(pos, world)
        if (out instanceof Vector2) {
            out.x = worldPos.x
            out.y = worldPos.y
        } else {
            out[0] = worldPos.x
            out[1] = worldPos.y
        }
        return out
    }

    /**
     * Converts object space position into world space position
     * @param {string} elementId  element in which space input position is defined.
     *                             for mesh point positions, it's the element itself
     *                             for element position, it is that element's parent
     * @param {duVector2 | Vector2} pos input position
     * @param {duVector2 | Vector2} [out] output position
     * @returns {[number, number]}  output position (in world space)
     */
    toWorldPosition(elementId, pos, out = new duVector2()) {
        const node = this.vs.getRenderItem(elementId)
        if (!node) {
            throw new Error(`Invalid element ID provided ${elementId}`)
        }
        if (this.vs.dataStore.isWorkspace(elementId)) {
            out.x = pos.x
            out.y = pos.y
            return out
        }
        const world = node.transform.world

        /** @type {Vector2} */
        const worldPos = this._applyMatrix(pos, world)
        if (out instanceof Vector2) {
            out.x = worldPos.x
            out.y = worldPos.y
        } else {
            out[0] = worldPos.x
            out[1] = worldPos.y
        }
        return out
    }

    vertex2World(elementId, pos, out = new duVector2()) {
        const node = this.vs.getRenderItem(elementId)
        if (!node) {
            throw new Error(`Invalid element ID provided ${elementId}`)
        }

        const pathXform = this._getPathTransform(node)
        /** @type {Vector2} */
        const worldPos = this._applyMatrix(pos, pathXform)
        if (out instanceof Vector2) {
            out.x = worldPos.x
            out.y = worldPos.y
        } else {
            out[0] = worldPos.x
            out[1] = worldPos.y
        }
        return out
    }

    getVerticesBoundWorld(element) {
        const verticesBounds = new AABB()
        const node = this.vs.getRenderItemOfElement(element)
        const pathXform = this._getPathTransform(node)
        const mesh = element.get('geometry').get('mesh')

        const vertexPos = new Vector2()
        const worldVertexPos = new Vector2()
        for (const vertex of mesh.getVertices()) {
            vertexPos.x = vertex.pos[0]
            vertexPos.y = vertex.pos[1]
            pathXform.xform(vertexPos, worldVertexPos)
            verticesBounds.minMax([worldVertexPos.x, worldVertexPos.y])
        }

        return verticesBounds
    }

    world2Vertex(elementId, pos, out = new duVector2()) {
        const node = this.vs.getRenderItem(elementId)
        if (!node) {
            throw new Error(`Invalid element ID provided ${elementId}`)
        }

        const pathXform = this._getPathTransform(node)
        pathXform.affine_inverse()
        /** @type {Vector2} */
        const worldPos = this._applyMatrix(pos, pathXform)
        if (out instanceof Vector2) {
            out.x = worldPos.x
            out.y = worldPos.y
        } else {
            out[0] = worldPos.x
            out[1] = worldPos.y
        }
        return out
    }

    /**
     *
     * @param {RenderItem} node
     * @returns {Transform2D}
     */
    _getPathTransform(node) {
        const { translate, rotation, scale, skew, contentAnchor } = node.transform

        const pathXform = node.transform.parent.clone()
            .translate_right(translate.x, translate.y)
            .rotate_right(rotation)
            .skew_right(fixInfiniteSkew(skew.x), fixInfiniteSkew(skew.y))
            .scale_right(scale.x, scale.y)
            .translate_right(-contentAnchor.x, -contentAnchor.y)
        return pathXform
    }

    /**
     * Converts world space position into object space position
     * @param {string} elementId  element to which space input position needs to be converted.
     *                             for mesh point positions, it's the element itself
     *                             for element position, it is that element's parent
     * @param {[number, number]} pos input position
     * @param {[number, number]} out output position
     * @param {Vector2} [offset]
     * @returns {[number, number]}  output position (in world space)
     */
    toObjectPosition(elementId, pos, out = new duVector2(), offset) {
        const node = this.vs.getRenderItem(elementId)
        if (!node) {
            throw new Error(`Invalid element ID provided ${elementId}`)
        }
        const worldInv = node.transform.worldInv

        /** @type {Vector2} */
        const objPos = this._applyMatrix(pos, worldInv, undefined, offset)
        out.x = objPos.x
        out.y = objPos.y
        return out
    }

    /**
     * Returns bounds of the viewport
     * @param {AABB} vpBounds   if provided, will set viewport bounds into this AABB and return it
     * @returns {AABB}  vpBounds if provided, otherwise new instance of AABB with viewport bounds
     */
    getViewportBounds(vpBounds = new AABB()) {
        vpBounds.copy(this.vs.viewport.rectW)
        return vpBounds
    }

    /**
     * Returns current selection bounds
     * @param {AABB} bounds   if provided, will set selection bounds into this AABB and return it
     * @param {boolean} onlyVisible if ture, the bound only includes visible elements
     * @returns {AABB}        if provided, otherwise new instance of AABB with selection bounds
     */
    getSelectionBoundsWorld(bounds = new AABB(), onlyVisible = false) {
        if (!onlyVisible) {
            return bounds.copy(this.vs.selection.bounds)
        }

        const rect = new Rect2()
        for (const { node } of this.vs.selection.iter()) {
            if (node.item.visible) {
                if (rect.is_zero()) {
                    rect.copy(node.boundsWorldAABB)
                } else {
                    rect.merge_with(node.boundsWorldAABB)
                }
            }
        }
        return bounds.copy(rect)
    }

    getElementsBounds(elementIds) {
        // const bounds = new Rect2()
        // return new AABB().copy(bounds.setFrom(this.vs._getWorldBoundsOf(elementIds)))
        let bbox = null
        for (let i = 0; i < elementIds.length; i++) {
            const node = this.vs.indexer.getNode(elementIds[i])

            // get base area bounds
            let shape = node.item.base.getFinalShape()
            shape = shape.clone().transform(node.item.transform.world)
            if (bbox == null) {
                bbox = shape.getBounds().clone()
            } else {
                bbox.merge_with(shape.getBounds())
            }
        }

        return new AABB().copy(bbox)
    }

    /**
     * Generate current boolean result bounds of the given elements
     * Notice: this function will utlize boolean algorithm which might be a heavy calculation,
     * should be careful to use it.
     * @param {BooleanOperation} booleanType
     * @param {Element} parent
     * @param {[Element]} elements
     */
    getBooleanlocalBounds(booleanType, parent, elements) {
        const bounds = new AABB()
        const parentNode = this.vs.indexer.getNode(parent.get('id'))

        const bases = []
        for (let i = 0; i < elements.length; i++) {
            const node = this.vs.indexer.getNode(elements[i].get('id'))
            getSourceBases(node, bases)
        }

        // calculate the boolean base with all bases
        /** @type {BezierShape} */
        let base = null
        for (let i = 0; i < bases.length; i++) {
            base = base ? base[BooleanAction[booleanType]](bases[i]) : bases[i]
        }

        // if no base, create a point at the top left of the selection bounds
        if (!base || base.isEmpty()) {
            const selectionBounds = this.getSelectionBoundsWorld(bounds)
            const point = selectionBounds.topLeft
            const pathData = new PathData()
            pathData.commands = [1]
            pathData.vertices = [point.x, point.y]
            base = BezierShape.createFromPathData(pathData)
        }

        base.transform(parentNode.item.transform.world.clone().affine_inverse())

        const bbox = base.getBounds()
        return bounds.copy(bbox)
    }

    /**
     * Generate current bounds of the element
     * Notice: this function will utlize stroke2 algorithm which might be a heavy calculation,
     * should be careful to use it.
     * @param {AABB} bounds
     * @param {Element} element
     * @param {boolean} including_stroke
     * @returns {AABB}
     */
    getElementBounds(bounds = new AABB(), element, including_stroke = false) {
        const node = this.vs.indexer.getNode(element.get('id'))

        // get base area bounds
        let shape = node.item.base.getFinalShape()
        shape = shape.clone().transform(node.item.transform.local)
        const bbox = shape.getBounds().clone()

        if (including_stroke) {
            // merge with all stroke area bounds
            for (let i = 0; i < node.item.strokeLayers.length; i++) {
                const style = node.item.strokes[i].style
                const shape = node.item.base.getFinalShape()
                let strokeShape = stroke2(shape, style)
                strokeShape = strokeShape.clone().transform(node.item.transform.local)
                bbox.merge_with(strokeShape.getBounds())
            }
        }

        return bounds.copy(bbox)
    }

    /**
     * @typedef {object} GradientHandles
     * @property {Vector2} center
     * @property {Vector2} bottom
     * @property {Vector2} left
     */
    /**
     * getGradientHandlesPosition
     * @param {number} width
     * @param {number} height
     * @param {boolean} isLinear
     * @param {number[]} gradientTransform
     * @returns {GradientHandles}
     */
    getGradientHandlesPosition(width, height, isLinear, gradientTransform) {
        const IT = new Transform2D()
        IT.set(...gradientTransform).affine_inverse().scale(width, height)
        return {
            center: IT.xform((isLinear ? CENTER_LINEAR : CENTER)),
            bottom: IT.xform(BOTTOM),
            left: IT.xform(LEFT)
        }
    }

    centerSelection() {
        centerSelection()
    }

    _applyMatrix(pos, xform, out = new Vector2(), offset = undefined) {
        if (pos instanceof Vector2) {
            out.set(pos.x, pos.y)
        } else {
            out.set(pos[0], pos[1])
        }
        if (offset) out.add(offset)
        xform.xform(out, out)
        return out
    }

    getMeshOutlinesForExport(mesh, basePosition) {
        /** @type {Set<HalfEdge[]>} */
        const pathSet = new Set()
        constructHalfEdgePathsFromEdgesInPlace(mesh, pathSet)
        const res = new PathData()
        /** @type {GetVertexPositionCallback} */
        const getVertexPosition = (mesh, id) => {
            const pos = mesh.cellTable.get(id).pos
            return [pos[0] - basePosition[0], pos[1] - basePosition[1]]
        }
        for (const path of pathSet) {
            pushContour(mesh, path, res, getVertexPosition, true)
        }
        return res
    }

    /**
     * Get position fixed by other properties changes, always call this function before apply changes, the change should only contain one type of property
     * @param {string} elementId
     * @param {object} changes
     * @param {duVector2} specifiedRefPoint
     * @returns {object|number|null} translate | translate.x | translate.y | null
     */
    getFixedPositionByChanges(elementId, changes, specifiedRefPoint) {
        /** @type {RenderItem} */
        const node = this.vs.getRenderItem(elementId)
        if (!node) {
            return null
        }
        /** @type {Transform} */
        const transform = node.transform
        // In the following cases, we don't need to fix position
        if (changes.contentAnchorX === undefined &&
            changes.contentAnchorY === undefined &&
            changes.width === undefined &&
            changes.height === undefined) {
            return null
        }

        let affinedLocalPos
        let referencePointX
        let referencePointY
        const contentAnchor = transform.contentAnchor
        if (specifiedRefPoint === undefined) {
            affinedLocalPos = transform.local.get_origin()
            const referencePoint = transform.referencePoint
            referencePointX = referencePoint.x
            referencePointY = referencePoint.y
        } else {
            referencePointX = specifiedRefPoint.x
            referencePointY = specifiedRefPoint.y
            const { translate, rotation, scale, skew } = transform
            const dummyTF = new Transform2D()
            dummyTF
                .translate_right(translate.x, translate.y)
                .rotate_right(rotation)
                .skew_right(fixInfiniteSkew(skew.x), fixInfiniteSkew(skew.y))
                .scale_right(scale.x, scale.y)
                .translate_right(-contentAnchor.x, -contentAnchor.y)
                .translate_right(-referencePointX, -referencePointY)
            affinedLocalPos = dummyTF.get_origin()
        }

        let contentAnchorX = contentAnchor.x
        let contentAnchorY = contentAnchor.y
        const currWidth = transform.size.x
        const currHeight = transform.size.y
        const translateX = transform.translate.x
        const translateY = transform.translate.y
        // Only contentAnchor
        if (changes.contentAnchorX !== undefined) {
            contentAnchorX = changes.contentAnchorX
        }
        if (changes.contentAnchorY !== undefined) {
            contentAnchorY = changes.contentAnchorY
        }

        // 0 size offset
        let offsetX = 0
        let offsetY = 0
        if (changes.width !== undefined) {
            const isNewWidthZero = changes.width !== 0 && changes.width < MIN_SIZE_THRESHOLD
            const isCurWidthZero = currWidth !== 0 && currWidth < MIN_SIZE_THRESHOLD
            if (isNewWidthZero && !isCurWidthZero) {
                offsetX = - changes.width * 0.5
            } else if (isCurWidthZero && !isNewWidthZero) {
                offsetX = currWidth * 0.5
            }
        }

        if (changes.height !== undefined) {
            const isNewHeightZero = changes.height !== 0 && changes.height < MIN_SIZE_THRESHOLD
            const isCurHeightZero = currHeight !== 0 && currHeight < MIN_SIZE_THRESHOLD
            if (isNewHeightZero && !isCurHeightZero) {
                offsetY = - changes.height * 0.5
            } else if (isCurHeightZero && !isNewHeightZero) {
                offsetY = currHeight * 0.5
            }
        }


        if (changes.referencePointX !== undefined) {
            referencePointX = changes.referencePointX
        }

        if (changes.referencePointY !== undefined) {
            referencePointY = changes.referencePointY
        }

        const newPivotOffset = new Vector2(
            contentAnchorX + referencePointX,
            contentAnchorY + referencePointY
        )
        const newTranslate = new Vector2(translateX, translateY)
        const newSkew = transform.skew
        const newScale = transform.scale
        const newRotation = transform.rotation

        const newLocal = new Transform2D()
            .translate_right(newTranslate.x, newTranslate.y)
            .rotate_right(newRotation)
            .skew_right(fixInfiniteSkew(newSkew.x), fixInfiniteSkew(newSkew.y))
            .scale_right(newScale.x, newScale.y)
            .translate_right(-newPivotOffset.x, -newPivotOffset.y)

        const newAffinedLocalPos = newLocal.get_origin()
        const x = offsetX + newTranslate.x + (affinedLocalPos.x - newAffinedLocalPos.x)
        const y = offsetY + newTranslate.y + (affinedLocalPos.y - newAffinedLocalPos.y)
        return new duVector2(x, y)
    }

    getChildrenPropetiesAfterResize(element, originalWidth, originalHeight, newWidth, newHeight) {
        const result = new Map()
        /** @type {RenderItem} */
        const parentItem = this.vs.getRenderItem(element.get('id'))
        if (!parentItem) {
            return result
        }

        // Get selection scale martix
        let ow = originalWidth, oh = originalHeight
        if (originalWidth === 0) {
            ow = MIN_SIZE
        }
        if (originalHeight === 0) {
            oh = MIN_SIZE
        }
        const v_scale = new Vector2(newWidth / ow, newHeight / oh)

        for (const child of element.children) {
            const childId = child.get('id')
            const childItem = this.vs.getRenderItem(childId)
            const childTransform = childItem.transform
            if (!childItem) continue

            // Get new transform by selection scale
            const t_new = new Transform2D()
                .scale_right(v_scale.x, v_scale.y)
                .rotate_right(childTransform.rotation)
                .skew_right(childTransform.skew.x, childTransform.skew.y)

            // Get ne w rotation, size and skew
            const { rotation: n_rotation, scale: n_scale, skew: n_skew } = t_new.decompose()

            const newSize = new Vector2().copy(childTransform.size).multiply(n_scale)
            const newTrasnalte = new Vector2().copy(childTransform.translate).multiply(v_scale)
            const newContentAnchor = new Vector2().copy(childTransform.contentAnchor).multiply(n_scale)
            const refPt = child.get('referencePoint')
            const newRefPt = new Vector2(refPt[0], refPt[1]).multiply(n_scale)

            const changes = {
                skew: new duVector2(n_skew.x, n_skew.y),
                rotation: n_rotation
            }

            if (childTransform.scale.x !== 0) {
                changes.width = newSize.x
                changes.translateX = newTrasnalte.x
                changes.contentAnchorX = newContentAnchor.x
                changes.referencePointX = newRefPt.x
            }

            if (childTransform.scale.y !== 0) {
                changes.height = newSize.y
                changes.translateY = newTrasnalte.y
                changes.contentAnchorY = newContentAnchor.y
                changes.referencePointY = newRefPt.y
            }
            result.set(childId, changes)
        }

        return result
    }

    /**
     * Update render data from dirty nodes at the moment
     */
    updateNodes() {
        const updateList = [...this.vs.updateList.values()]
        this.vs.indexer.updateNodes(updateList)
        this.vs.updateList.clear()
    }

    /**
     * Check whether the element is present in SpacialCache.nodeMap
     * @param {string} elementId
     */
    isNodePresent(elementId) {
        return Boolean(this.vs.indexer.getNode(elementId))
    }

    /**
     * Trigger updateNodes first then get the latest bounds of the element
     * @param {string} elementId
     * @returns {AABB}
     */
    getLatestLocalBounds(elementId) {
        const node = this.vs.indexer.getNode(elementId)
        return node.boundsLocal
    }

    updateMotionPathPointShape(pointShapeType, options) {
        updateMotionPointMirror(pointShapeType, options)
    }

    updateMotionPointCurveControl(point, type) {
        // if moving the curveControl, update the oppositeCurveControl
        const oppositeType = type === 'in' ? 'out' : 'in'
        const oppositeCurveControl = new Vector2().fromArray(point[oppositeType])
        const mirrorDir = new Vector2(-point[type][0], -point[type][1])
        switch (point.mirror) {
            case PointShape.ANGLE: {
                const norm = oppositeCurveControl.length()
                mirrorDir.normalize().scale(norm)
                point[oppositeType] = [mirrorDir.x, mirrorDir.y]
                break
            }
            case PointShape.ANGLE_AND_LENGTH: {
                point[oppositeType] = [mirrorDir.x, mirrorDir.y]
                break
            }
            case PointShape.NONE:
            case PointShape.INDEPENDENT:
                break
        }
    }

    subdivideMotionPath(curve, percent) {
        const bez = new CubicBez().initWithPointsAndHandlesN(...curve)
        const len = bez.getLength()
        const offset = len * percent
        return CubicBez.subdivide(bez.getValues(), bez.getTimeAt(offset))
    }
}
