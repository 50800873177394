import { FFmpeg } from '@ffmpeg/ffmpeg'
import { toBlobURL } from '@ffmpeg/util'
import { MediaType } from '@phase-software/types'
import { GIFEncoder } from './gif/index'
import { MP4Encoder } from './mp4/index'
import Recorder from './recorder'

export default class ExportMedia {
    onRecordProgress = null
    /**
     *
     * @param {import('../../visual_server/VisualServer').VisualServer} visualServer
     * @param {*} dataStore
     */
    constructor(visualServer, dataStore) {
        this.visualServer = visualServer
        this.dataStore = dataStore

        this.ffmpeg = new FFmpeg()
        this._encoder = null
        this.recorder = new Recorder(visualServer, dataStore)
        this.init()
    }

    init() {
        this.type = null
        this.exportFile = null
        this.recorder.init()
    }

    /**
     * Enable to record media
     */
    enable() {
        this.recorder.enable()
        this.dataStore.forceUpdateLayerListToRenderer()
        this.loadFFmpeg()
    }

    /**
     * Disable tp record media
     */
    disable() {
        this.recorder.disable()
    }

    /**
     * Set output media viewport size
     */
    setOutputSize() {
        this.recorder.setOutputSize(
            this.dataStore.workspace.children[0].get('size').width,
            this.dataStore.workspace.children[0].get('size').height
        )
    }

    /**
     * Set media type
     * @param {MediaType} type
     */
    async setMediaType(type) {
        this.type = type
        this.recorder.setMediaType(type)
        // Set specific settings for specific type here.
        // So, we can get correct user settings from outside of the encoder.
        if (this.type === MediaType.MP4) {
            this.updateRecordSetting({
                transparent: false
            })
        }
        await this._initEncoder()
        this.recorder.setEncoder(this._encoder)
    }

    /**
     * Update recorder settings
     * @param {object} newSettings
     */
    updateRecordSetting(newSettings) {
        this.recorder.updateSetting(newSettings)
    }

    /**
     * Init media encoder
     */
    async _initEncoder() {
        await this.loadFFmpeg()

        const options = {
            width: this.recorder.width,
            height: this.recorder.height,
            fps: this.recorder.settings.fps,
            speed: this.recorder.settings.speed,
            quality: this.recorder.settings.quality,
            loop: this.recorder.settings.loop,
        }
        switch (this.type) {
            case MediaType.GIF: {
                this._encoder = new GIFEncoder(this.ffmpeg)
                this._encoder.init(options)
                break
            }
            case MediaType.MP4: {
                this._encoder = new MP4Encoder(this.ffmpeg)
                this._encoder.init(options)
                break
            }
        }
    }

    /**
     * Record canvas for exporting video
     * @param {Function} onRecordProgress
     */
    async recordCanvas(onRecordProgress, onRecordComplete) {
        await this.recorder.start(onRecordProgress, (bytes) => {
            this.handleExportCompletion(bytes)
            onRecordComplete()
        })
    }

    /**
     * Create file blob
     * @param {Uint8Array} uint8Array
     * @returns {Blob}
     */
    createBlob(uint8Array) {
        let format
        switch (this.type) {
            case MediaType.GIF:
                format = { type: 'image/gif' }
                break
            case MediaType.MP4:
                format = { type: 'video/mp4' }
                break
        }

        return new Blob([uint8Array], format)
    }

    /**
     * Handle finish recording
     * @param {Uint8Array} bytes
     */
    handleExportCompletion(bytes) {
        this.exportFile = URL.createObjectURL(this.createBlob(bytes))
    }

    clear() {
        this.init()
    }

    loadFFmpeg = async () => {
        const public_url = process.env.PUBLIC_URL.length > 0 ? process.env.PUBLIC_URL : "/"
        const baseURL = 'https://unpkg.com/@ffmpeg/core@0.12.6/dist/esm'
        // this.ffmpeg.on('log', ({ message }) => {
        //     console.log(message)
        // })
        await this.ffmpeg.load({
            coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
            wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, 'application/wasm'),
            classWorkerURL: `${window.location.origin}${public_url}ffmpeg/worker.js`
        })
    }
}
