/* tslint:disable */
/* eslint-disable */
/**
 * PHASE API
 * Phase API service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddProjectMemberRequest,
  PatchProjectMemberRequest,
} from '../models';

export interface ProjectMemberApiControllerAddTeamProjectMemberRequest {
    teamId: string;
    projectId: string;
    addProjectMemberRequest: AddProjectMemberRequest;
}

export interface ProjectMemberApiControllerDeleteTeamProjectMemberRequest {
    teamId: string;
    projectId: string;
    memberId: string;
}

export interface ProjectMemberApiControllerPatchTeamProjectMemberRequest {
    teamId: string;
    projectId: string;
    memberId: string;
    patchProjectMemberRequest: PatchProjectMemberRequest;
}

/**
 * 
 */
export class ProjectMemberApi extends runtime.BaseAPI {

    /**
     */
    async projectMemberApiControllerAddTeamProjectMemberRaw(requestParameters: ProjectMemberApiControllerAddTeamProjectMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling projectMemberApiControllerAddTeamProjectMember.');
        }

        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectMemberApiControllerAddTeamProjectMember.');
        }

        if (requestParameters.addProjectMemberRequest === null || requestParameters.addProjectMemberRequest === undefined) {
            throw new runtime.RequiredError('addProjectMemberRequest','Required parameter requestParameters.addProjectMemberRequest was null or undefined when calling projectMemberApiControllerAddTeamProjectMember.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/teams/{teamId}/projects/{projectId}/members`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.addProjectMemberRequest,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async projectMemberApiControllerAddTeamProjectMember(requestParameters: ProjectMemberApiControllerAddTeamProjectMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.projectMemberApiControllerAddTeamProjectMemberRaw(requestParameters, initOverrides);
    }

    /**
     */
    async projectMemberApiControllerDeleteTeamProjectMemberRaw(requestParameters: ProjectMemberApiControllerDeleteTeamProjectMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling projectMemberApiControllerDeleteTeamProjectMember.');
        }

        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectMemberApiControllerDeleteTeamProjectMember.');
        }

        if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
            throw new runtime.RequiredError('memberId','Required parameter requestParameters.memberId was null or undefined when calling projectMemberApiControllerDeleteTeamProjectMember.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/teams/{teamId}/projects/{projectId}/members/{memberId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"memberId"}}`, encodeURIComponent(String(requestParameters.memberId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async projectMemberApiControllerDeleteTeamProjectMember(requestParameters: ProjectMemberApiControllerDeleteTeamProjectMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.projectMemberApiControllerDeleteTeamProjectMemberRaw(requestParameters, initOverrides);
    }

    /**
     */
    async projectMemberApiControllerPatchTeamProjectMemberRaw(requestParameters: ProjectMemberApiControllerPatchTeamProjectMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling projectMemberApiControllerPatchTeamProjectMember.');
        }

        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectMemberApiControllerPatchTeamProjectMember.');
        }

        if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
            throw new runtime.RequiredError('memberId','Required parameter requestParameters.memberId was null or undefined when calling projectMemberApiControllerPatchTeamProjectMember.');
        }

        if (requestParameters.patchProjectMemberRequest === null || requestParameters.patchProjectMemberRequest === undefined) {
            throw new runtime.RequiredError('patchProjectMemberRequest','Required parameter requestParameters.patchProjectMemberRequest was null or undefined when calling projectMemberApiControllerPatchTeamProjectMember.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/teams/{teamId}/projects/{projectId}/members/{memberId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"memberId"}}`, encodeURIComponent(String(requestParameters.memberId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.patchProjectMemberRequest,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async projectMemberApiControllerPatchTeamProjectMember(requestParameters: ProjectMemberApiControllerPatchTeamProjectMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.projectMemberApiControllerPatchTeamProjectMemberRaw(requestParameters, initOverrides);
    }

}
