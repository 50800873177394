import { getWorkspaceData } from './Workspace'

export const getDataStoreData = (dataStore) => {
  const data = {
    ...dataStore.gets('name', 'state'),
    interaction: dataStore.interaction,
    workspace: dataStore.workspace.watched?.get('id'),
    workspaceList: dataStore.workspaceList.map((o) => getWorkspaceData(o, true))
  }
  return data
}
