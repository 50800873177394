import { useCallback } from 'react'

import {
  EI_EFFECT_ALLOWED_KEYS_SET,
  EI_EFFECT_ALLOWED_STATE_KEYS,
  EI_LAYER_ALLOWED_KEYS,
  EI_LAYER_ALLOWED_STATE_KEYS,
  LayerPropMapKey,
  LayerTypeMapLayerListKey
} from '@phase-software/data-store'
import { IS_MIX, MIX_VALUE_ID } from '@phase-software/data-utils'
import { EditMode, LayerType } from '@phase-software/types'

import { EFFECT_PROPS_NAME_MAP, PAINT_PROPS_SET } from '../../constant'
import { createProvider } from '../utils'
import { useDataStore } from './DataStoreProvider'
import { useSetEffect } from './EffectProvider'
import { useSetFill } from './FillProvider'
import { useSetInnerShadow } from './InnerShadowProvider'
import { useSetPaint } from './PaintProvider'
import { useSetShadow } from './ShadowProvider'
import { useSetStroke } from './StrokeProvider'

const defaultValue = {}
const [Provider, useSelectState, useSetState] = createProvider('Editor', defaultValue)

export const useEditor = useSelectState

const LAYER_PROPS_MAP = {
  fillList: EI_LAYER_ALLOWED_KEYS[LayerType.FILL],
  strokeList: EI_LAYER_ALLOWED_KEYS[LayerType.STROKE],
  shadowList: EI_LAYER_ALLOWED_KEYS[LayerType.SHADOW],
  innerShadowList: EI_LAYER_ALLOWED_KEYS[LayerType.INNER_SHADOW]
}

const LAYER_PROPS_STATE_MAP = {
  fillList: EI_LAYER_ALLOWED_STATE_KEYS[LayerType.FILL],
  strokeList: EI_LAYER_ALLOWED_STATE_KEYS[LayerType.STROKE],
  shadowList: EI_LAYER_ALLOWED_STATE_KEYS[LayerType.SHADOW],
  innerShadowList: EI_LAYER_ALLOWED_STATE_KEYS[LayerType.INNER_SHADOW]
}

export const setLayerPropChange = (layerChanges, layerListKey, layerItemId, key, data) => {
  if (PAINT_PROPS_SET.has(key)) {
    // Set paint data changes
    if (!layerChanges.paints[layerItemId]) {
      layerChanges.paints[layerItemId] = {}
    }
    layerChanges.paints[layerItemId][key] = data
  } else {
    // Set rest of layer data changes
    if (!layerChanges[layerListKey][layerItemId]) {
      layerChanges[layerListKey][layerItemId] = {}
    }
    layerChanges[layerListKey][layerItemId][key] = data
  }
}

export const setEffectPropChange = (effectChanges, effectType, key, data) => {
  const dataType = EFFECT_PROPS_NAME_MAP[effectType]
  if (!effectChanges[dataType]) {
    effectChanges[dataType] = {}
  }

  effectChanges[dataType][key] = data
}

const updateContext = (update, instances) => {
  for (const id in instances) {
    update(id, instances[id])
  }
}

const layerItemIds = {
  fillList: [],
  strokeList: [],
  shadowList: [],
  innerShadowList: []
}
export const useSetEditor = () => {
  const dataStore = useDataStore()
  const editor = dataStore.editor
  const setState = useSetState()
  const { updateFill, mergeFill } = useSetFill()
  const { updateStroke, mergeStroke } = useSetStroke()
  const { updateShadow, mergeShadow } = useSetShadow()
  const { updateInnerShadow, mergeInnerShadow } = useSetInnerShadow()
  const { updatePaint, mergePaint } = useSetPaint()
  const { updateEffect, mergeEffect } = useSetEffect()

  const setEditor = useCallback(
    (changes) => {
      const editMode = dataStore.get('editMode')
      const changePropData = {}
      const layerChanges = {
        fills: {},
        strokes: {},
        shadows: {},
        innerShadows: {},
        paints: {}
      }
      const updateChanges = {
        fills: {},
        strokes: {},
        shadows: {},
        innerShadows: {},
        paints: {},
        effects: {}
      }
      const effectChanges = {}
      let removedLayers = []

      // FIXME: considering refactor this part
      changes.props.forEach((prop) => {
        if (editor.hasProp(prop)) {
          // Non-Repeatable properties
          if (editor.isToolState(prop)) {
            changePropData[prop] = editor.getToolState(prop)
          } else if (editor.isPropState(prop)) {
            changePropData[prop] = editor.getPropState(prop)
            const propName = prop.replace('State', '')
            // If already get prop, then no need to get the value for it again
            if (changePropData[propName] === undefined) {
              // FIXME: (motion-path) refactor this part
              if (editMode === EditMode.SHAPE && editor.hasVertexProp(propName)) {
                changePropData[propName] = editor.getVertexProp(propName)
              } else if (editMode === EditMode.MOTION_PATH && editor.hasMotionPathProp(propName)) {
                changePropData[propName] = editor.getMotionPathProp(propName)
              } else {
                changePropData[propName] = editor.getProp(propName)
              }
            }
          } else if (editMode === EditMode.SHAPE || editMode === EditMode.MOTION_PATH) {
            // FIXME: (motion-path) refactor this part
            if (editMode === EditMode.SHAPE && editor.hasVertexProp(prop)) {
              changePropData[prop] = editor.getVertexProp(prop)
            } else if (editMode === EditMode.MOTION_PATH && editor.hasMotionPathProp(prop)) {
              changePropData[prop] = editor.getMotionPathProp(prop)
            } else {
              changePropData[prop] = editor.getProp(prop)
            }
          } else {
            // Element mode should not update the vertex prop
            if (prop !== 'mirror') {
              if (prop === 'contentAnchorX' || prop === 'contentAnchorY') {
                changePropData[prop] = editor.getProp(prop)
                const contentAnchorPercentData = editor.getOrigin9PatchProp()
                changePropData.contentAnchorPercentX = contentAnchorPercentData.contentAnchorPercentX
                changePropData.contentAnchorPercentY = contentAnchorPercentData.contentAnchorPercentY
              } else {
                changePropData[prop] = editor.getProp(prop)
              }
            }
          }
        } else if (editor.isCustomPropState(prop)) {
          changePropData[prop] = editor.getCustomPropState(prop)
          if (prop === 'pathMorphingState') {
            changePropData.mirror = ''
            if (changePropData[prop] === 'EXPLICIT' || changePropData[prop] === 'INITIAL') {
              changePropData.mirror = editor.getVertexProp('mirror')
            }
          }
        } else if (prop === 'effectList') {
          const newEffectList = editor[prop]
          changePropData[prop] = []
          newEffectList.forEach((effectItemId) => {
            if (effectItemId === MIX_VALUE_ID) {
              changePropData[prop].push({
                id: IS_MIX
              })
            } else {
              const effectType = editor.getEffectProp(effectItemId, 'effectType')
              changePropData[prop].push({
                id: effectItemId,
                type: effectType
              })

              EI_EFFECT_ALLOWED_KEYS_SET[effectType].forEach((key) => {
                const data = editor.getEffectProp(effectItemId, key)
                setEffectPropChange(effectChanges, effectType, key, data)
              })
              EI_EFFECT_ALLOWED_STATE_KEYS[effectType].forEach((key) => {
                const data = editor.getEffectPropState(effectItemId, key)
                setEffectPropChange(effectChanges, effectType, key, data)
              })
            }
          })

          mergeEffect(effectChanges)
        } else if (editor[prop] != null) {
          // Set layerItemId list into editor context
          changePropData[prop] = editor[prop]
          removedLayers = getRemovedLayers(prop, changePropData[prop])
          layerItemIds[prop] = changePropData[prop]

          // Set all layerItem data into its layer changes
          changePropData[prop].forEach((layerItemId) => {
            const layerProps = LAYER_PROPS_MAP[prop]
            const layerPropStates = LAYER_PROPS_STATE_MAP[prop]
            const layerListKey = LayerPropMapKey[prop]

            // FIXME (shiny): better way to handle the mix layer
            if (layerItemId === MIX_VALUE_ID) {
              setLayerPropChange(layerChanges, layerListKey, layerItemId, 'isMix', true)
            }
            layerProps.forEach((key) => {
              const data = editor.getLayerProp(layerItemId, key)
              // console.log('getLayerProp', layerItemId, key, data)
              setLayerPropChange(layerChanges, layerListKey, layerItemId, key, data)
            })
            layerPropStates.forEach((key) => {
              const data = editor.getLayerPropState(layerItemId, key)
              setLayerPropChange(layerChanges, layerListKey, layerItemId, key, data)
            })
            const isNonBaseKey = 'isNonBase'
            const isNonBase = editor.getLayerProp(layerItemId, isNonBaseKey)
            setLayerPropChange(layerChanges, layerListKey, layerItemId, isNonBaseKey, isNonBase)
          })

          mergeFill(layerChanges.fills)
          mergeStroke(layerChanges.strokes)
          mergeShadow(layerChanges.shadows)
          mergeInnerShadow(layerChanges.innerShadows)
          mergePaint(layerChanges.paints)
        }
      })

      for (const [layerItemId, props] of changes.layerProps) {
        for (const prop of props) {
          let data
          if (editor.isLayerPropState(layerItemId, prop)) {
            data = editor.getLayerPropState(layerItemId, prop)
          } else {
            data = editor.getLayerProp(layerItemId, prop)
          }
          const layerListKey = LayerTypeMapLayerListKey[editor.getLayerItem(layerItemId).layerType]
          setLayerPropChange(updateChanges, layerListKey, layerItemId, prop, data)
        }
      }

      for (const [effectItemId, props] of changes.effectProps) {
        const effectType = editor.getEffectProp(effectItemId, 'effectType')
        updateChanges.effects[EFFECT_PROPS_NAME_MAP[effectType]] = {}
        for (const prop of props) {
          let data
          if (editor.isEffectPropState(effectItemId, prop)) {
            data = editor.getEffectPropState(effectItemId, prop)
          } else {
            data = editor.getEffectProp(effectItemId, prop)
          }
          updateChanges.effects[EFFECT_PROPS_NAME_MAP[effectType]][prop] = data
        }
      }

      // Set reset of layer data into layer context
      updateContext(updateFill, updateChanges.fills)
      updateContext(updateStroke, updateChanges.strokes)
      updateContext(updateShadow, updateChanges.shadows)
      updateContext(updateInnerShadow, updateChanges.innerShadows)
      updateContext(updatePaint, updateChanges.paints)
      updateContext(updateEffect, updateChanges.effects)

      setState((s) => ({ ...s, ...changePropData }), [setState, editor])

      if (removedLayers.length) {
        // Need to clean up removed layers color data in another stack so that we would not get undefined data for the non-update layer items.
        // But not to remove the whole instance, we still need that for undo/redo.
        removedLayers.forEach(() =>
          updatePaint({
            color: [0.85, 0.85, 0.85, 0]
          })
        )
      }
    },
    [
      dataStore,
      mergeEffect,
      updateEffect,
      mergeFill,
      mergeStroke,
      mergeShadow,
      mergeInnerShadow,
      mergePaint,
      updateFill,
      updateStroke,
      updateShadow,
      updateInnerShadow,
      updatePaint,
      setState,
      editor
    ]
  )

  return {
    setEditor
  }
}

const getRemovedLayers = (prop, ids) => {
  if (!layerItemIds[prop]) {
    return []
  }
  return layerItemIds[prop].filter((id) => !ids.includes(id))
}

export const useEditorActions = () => {
  const dataStore = useDataStore()
  const $editor = dataStore.editor
  const editMode = dataStore.get('editMode')

  const setProperties = useCallback(
    (changes, options) => {
      let hasVertexProp = false
      let prop
      for (prop in changes) {
        if ($editor.hasVertexProp(prop)) {
          hasVertexProp = true
          break
        }
      }

      if (editMode === EditMode.SHAPE && hasVertexProp) {
        // TODO: Might need to support vertext setter options
        for (prop in changes) {
          $editor.setVertexProp(prop, changes[prop], options)
        }
      } else if (editMode === EditMode.MOTION_PATH) {
        $editor.setMotionPathProps(changes, null, options)
      } else {
        $editor.setProps(changes, options)
      }
    },
    [$editor, editMode]
  )

  const setPropertyStates = useCallback(
    (changes) => {
      for (const prop in changes) {
        $editor.setPropState(prop, changes[prop])
      }
    },
    [$editor]
  )

  const setOrigin9Patch = useCallback(
    (changes) => {
      $editor.setOrigin9Patch(changes)
    },
    [$editor]
  )

  const addLayer = useCallback(
    (layerType) => {
      dataStore.eam.stopAnimation()
      // We don't need to remove the MIX first. Just add layer
      // Remove the MIX layerItem if it exist before add a new layer
      // if (firstLayerItemId && $editor.getLayerProp(firstLayerItemId, 'isMix')) {
      //   $editor.deleteLayer(firstLayerItemId)
      // }
      $editor.addLayer(layerType)
    },
    [$editor, dataStore]
  )

  const removeLayer = useCallback(
    (layerItemId) => {
      dataStore.eam.stopAnimation()
      $editor.deleteLayer(layerItemId)
    },
    [$editor, dataStore]
  )

  const cloneLayer = useCallback(() => {}, [])

  const toggleLayerVisible = useCallback(
    (layerItemId, visible) => {
      $editor.setLayerProp(layerItemId, 'visible', visible)
    },
    [$editor]
  )

  const setLayerPropertyStates = useCallback(
    (layerItemId, changes) => {
      for (const prop in changes) {
        $editor.setLayerPropState(layerItemId, prop, changes[prop])
      }
    },
    [$editor]
  )

  const setStrokesAdvancedValues = useCallback(() => {}, [])

  const setLayerPaint = useCallback(
    (layerItemId, paintData, options) => {
      dataStore.eam.stopAnimation()
      $editor.setLayerProps(layerItemId, paintData, options)
    },
    [$editor, dataStore]
  )

  const setLayerGrowDirection = useCallback(
    (layerItemId, growDirection, options) => {
      $editor.setLayerProp(layerItemId, 'growDirection', growDirection, options)
    },
    [$editor]
  )

  const setLayerCap = useCallback(
    (layerItemId, cap, options) => {
      $editor.setLayerProp(layerItemId, 'cap', cap, options)
    },
    [$editor]
  )

  const setLayerCapSize = useCallback(
    (layerItemId, capSize, options) => {
      $editor.setLayerProp(layerItemId, 'capSize', capSize, options)
    },
    [$editor]
  )

  const setLayerDash = useCallback(
    (layerItemId, dash, options) => {
      $editor.setLayerProp(layerItemId, 'dash', dash, options)
    },
    [$editor]
  )

  const setLayerGap = useCallback(
    (layerItemId, gap, options) => {
      $editor.setLayerProp(layerItemId, 'gap', gap, options)
    },
    [$editor]
  )

  const setLayerEnds = useCallback(
    (layerItemId, ends, options) => {
      $editor.setLayerProp(layerItemId, 'ends', ends, options)
    },
    [$editor]
  )

  const setLayerJoin = useCallback(
    (layerItemId, join, options) => {
      $editor.setLayerProp(layerItemId, 'join', join, options)
    },
    [$editor]
  )

  const setLayerJoinSize = useCallback(
    (layerItemId, joinSize, options) => {
      $editor.setLayerProp(layerItemId, 'joinSize', joinSize, options)
    },
    [$editor]
  )

  const setLayerMiter = useCallback(
    (layerItemId, miter, options) => {
      $editor.setLayerProp(layerItemId, 'miter', miter, options)
    },
    [$editor]
  )

  const setLayerOffset = useCallback(
    (layerItemId, offset, options) => {
      $editor.setLayerProp(layerItemId, 'offset', offset, options)
    },
    [$editor]
  )

  const setLayerWidth = useCallback(
    (layerItemId, width, options) => {
      $editor.setLayerProp(layerItemId, 'width', width, options)
    },
    [$editor]
  )

  const setLayerBlur = useCallback(
    (layerItemId, blur, options) => {
      $editor.setLayerProp(layerItemId, 'blur', blur, options)
    },
    [$editor]
  )

  const setLayerOffsetX = useCallback(
    (layerItemId, offsetX, options) => {
      $editor.setLayerProp(layerItemId, 'offsetX', offsetX, options)
    },
    [$editor]
  )

  const setLayerOffsetY = useCallback(
    (layerItemId, offsetY, options) => {
      $editor.setLayerProp(layerItemId, 'offsetY', offsetY, options)
    },
    [$editor]
  )

  const setLayerSpread = useCallback(
    (layerItemId, spread, options) => {
      $editor.setLayerProp(layerItemId, 'spread', spread, options)
    },
    [$editor]
  )

  const setLayerFocused = useCallback(
    (layerItemId, focused) => {
      $editor.setLayerFocused(layerItemId, focused)
    },
    [$editor]
  )

  const setAlignment = useCallback(
    (direction) => {
      $editor.setAlignment(direction)
    },
    [$editor]
  )

  const setDistribution = useCallback(
    (distribution) => {
      $editor.setDistribution(distribution)
    },
    [$editor]
  )

  const addEffect = useCallback(
    (effectType) => {
      dataStore.eam.stopAnimation()
      $editor.addEffect(effectType)
    },
    [$editor, dataStore]
  )

  const setEffectProps = useCallback(
    (effectItemId, data, options) => {
      $editor.setEffectProps(effectItemId, data, options)
    },
    [$editor]
  )

  const removeEffect = useCallback(
    (effectItemId) => {
      dataStore.eam.stopAnimation()
      $editor.deleteEffect(effectItemId)
    },
    [$editor, dataStore]
  )

  const setEffectPropertyStates = useCallback(
    (effectItemId, changes) => {
      for (const prop in changes) {
        $editor.setEffectPropState(effectItemId, prop, changes[prop])
      }
    },
    [$editor]
  )

  const changeContainerType = useCallback(
    (newContainerType) => {
      $editor.changeContainerType(newContainerType)
    },
    [$editor]
  )

  const convertToPath = useCallback(() => {
    $editor.convertToPath()
  }, [$editor])

  return {
    setProperties,
    setPropertyStates,
    setOrigin9Patch,
    addLayer,
    removeLayer,
    cloneLayer,
    toggleLayerVisible,
    setLayerPropertyStates,
    setStrokesAdvancedValues,
    setLayerPaint,
    setLayerGrowDirection,
    setLayerCap,
    setLayerCapSize,
    setLayerDash,
    setLayerGap,
    setLayerEnds,
    setLayerJoin,
    setLayerJoinSize,
    setLayerMiter,
    setLayerOffset,
    setLayerWidth,
    setLayerBlur,
    setLayerOffsetX,
    setLayerOffsetY,
    setLayerSpread,
    setLayerFocused,
    setAlignment,
    setDistribution,
    addEffect,
    setEffectProps,
    removeEffect,
    setEffectPropertyStates,
    changeContainerType,
    convertToPath
  }
}

export default Provider
