import { IRNode } from './IRNode'
import { nodeTypeMapClass } from './utils'

export class IRGroup extends IRNode {
  static props = [
    ...super.props,
    { key: 'width', animatable: true, defaultValue: 0 },
    { key: 'height', animatable: true, defaultValue: 0 }
  ]

  static defaultValue = {
    ...super.defaultValue,
    children: []
  }

  constructor(data = IRGroup.defaultValue, parent = null) {
    super(data, parent)
    this.children = []
  }

  fromJSON(data) {
    super.fromJSON(data)
    this.children = data.children.map(childData => {
      const NodeClass = nodeTypeMapClass[childData.type]
      return new NodeClass().fromJSON({ ...childData, parent: this })
    })
    return this
  }

  toJSON() {
    return {
      ...super.toJSON(),
      children: this.children.map(childNode => childNode.toJSON())
    }
  }

  appendChild(childNode) {
    childNode.parent = this
    this.children.push(childNode)
  }
}
