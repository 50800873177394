import React from 'react'
import CustomContentLoader from './CustomContentLoader'

// Workspace title loading
const WorkSpaceTitleLoading = () => {
  return (
    <div className="sticky top-0 z-10 flex justify-between items-center px-64 pt-40 pb-24 mb-8 gap-32 bg-neutral-80">
      <CustomContentLoader width={120} height={80} viewBox="0 0 120 80">
        <rect width="120" height="16" rx="5" />
        <rect y="64" width="80" height="16" rx="5" />
      </CustomContentLoader>
    </div>
  )
}

export default WorkSpaceTitleLoading
