import React from 'react'
import { useTranslation } from 'react-i18next'

import { PaintType } from '@phase-software/types'

import { IMAGE_MODE_MAP } from '../../../constant'
import { useModal, useSetModal } from '../../../providers/ModalProvider'
import { useEditor } from '../../../providers/dataStore/EditorProvider'
import { usePaint } from '../../../providers/dataStore/PaintProvider'
import { formatAsPercentage, multiplyBy100 } from '../../../utils/formatter'
import useColorTextGenerator from '../../InspectPanel/Hooks/useColorTextGenerator'
import ColorPreview from '../../PaintPicker/ColorPreview'
import { IconButton } from '../../shared'
import useImageDownloader from '../Hooks/useImageDownloader'
import GradientDetailsModal from '../Modal/GradientDetailsModal'
import InspectRow from './InspectRow'
import InteractiveInspectContent from './InteractiveInspectContent'

type FillInspectionProps = {
  id: string
}
const FillInspection = ({ id }: FillInspectionProps) => {
  const { t } = useTranslation('file')
  const { paintType, color, opacity, imageMode, imageId } = usePaint((o) => o[id])
  const downloadImage = useImageDownloader()

  const MODAL_KEY = `InspectContextMenu-${id}`
  const { toggleModal, closeAllModals } = useSetModal(MODAL_KEY)
  const { open } = useModal((o: any) => o[MODAL_KEY])
  const { getValues } = useColorTextGenerator()

  const handleClick = () => {
    downloadImage(imageId)
  }

  return (
    <div className="flex flex-col">
      <InspectRow
        value={getValues(paintType, color, opacity)}
        leftContent={<ColorPreview layerItemId={id} size="m" />}
        rightContent={
          paintType === PaintType.IMAGE && imageId && <IconButton size="l" icon="Download" onClick={handleClick} />
        }
        showDetailsButton={paintType !== PaintType.SOLID && paintType !== PaintType.IMAGE}
        valueClassName={paintType === PaintType.SOLID ? '' : 'text-light-overlay-60'}
        copyDisabled={paintType !== PaintType.SOLID}
        onClickDetails={() => {
          if (!open) closeAllModals()
          toggleModal()
        }}
      />
      {paintType === PaintType.IMAGE && (
        <InspectRow
          label={t('inspect.fill_mode')}
          value={t(IMAGE_MODE_MAP[imageMode as keyof typeof IMAGE_MODE_MAP], { keyPrefix: 'image_mode' })}
        />
      )}
      {paintType !== PaintType.SOLID && (
        <>
          <InspectRow label={t('inspect.opacity')} value={formatAsPercentage(multiplyBy100(opacity))} />
          <GradientDetailsModal id={id} />
        </>
      )}
    </div>
  )
}

const FillsInspection = () => {
  const { t } = useTranslation('file', { keyPrefix: 'inspect' })
  const fillList = useEditor((o) => o.fillList)

  if (fillList.length === 0) return null
  return (
    <InteractiveInspectContent title={t('fills')} target="static_fills">
      <div className="flex flex-col gap-8">
        {fillList.map((id: string) => (
          <FillInspection key={id} id={id} />
        ))}
      </div>
    </InteractiveInspectContent>
  )
}

export default FillsInspection
