import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useModal, useModalActions } from '../../providers/ModalProvider'
import { useDataStoreActions } from '../../providers/dataStore/DataStoreProvider'
import { useEditorActions } from '../../providers/dataStore/EditorProvider'
import { ContextMenu } from '../shared'
import { translateMenuOptions } from '../shared/Menu/utils'

const KEY = 'PropertyStateContextMenu'

const RESET_TO_INITIAL_OPTION = { name: 'reset_to_initial', value: 'INITIAL' }

const OPTION_MAP = {
  EXPLICIT: [RESET_TO_INITIAL_OPTION],
  INITIAL: [],
  TWEEN: [RESET_TO_INITIAL_OPTION],
  DEFAULT: []
}

const PropertyStateContextMenu = () => {
  const { t } = useTranslation('file')
  const {
    open,
    data: { prop, layerItemId, effectItemId, type = 'DEFAULT', cursorPosition }
  } = useModal((o) => o[KEY])

  const { closeModal } = useModalActions()
  const { setPropertyStates, setLayerPropertyStates, setEffectPropertyStates } = useEditorActions()
  const { commitUndo } = useDataStoreActions()

  const setProps = useMemo(() => {
    if (layerItemId) {
      return setLayerPropertyStates.bind(null, layerItemId)
    } else if (effectItemId) {
      return setEffectPropertyStates.bind(null, effectItemId)
    }

    return setPropertyStates
  }, [layerItemId, effectItemId, setPropertyStates, setLayerPropertyStates, setEffectPropertyStates])

  const options = OPTION_MAP[type]
  const translatedOptions = useMemo(
    () =>
      translateMenuOptions(t, options, {
        ns: 'file',
        keyPrefix: 'menu'
      }),
    [t, options]
  )

  const handleSelect = (option) => {
    switch (option.value) {
      case 'TWEEN':
      case 'EXPLICIT':
      case 'INITIAL':
        setProps({
          [prop]: option.value
        })
        break
      default:
        throw Error(`Unknown operation: ${option}`)
    }
    commitUndo()
  }
  const handleClose = () => {
    closeModal(KEY)
  }
  return (
    <ContextMenu
      open={open}
      options={translatedOptions}
      onSelect={handleSelect}
      onClose={handleClose}
      cursorPosition={cursorPosition}
      minViewportGap={8}
    />
  )
}

export default React.memo(PropertyStateContextMenu)
