import { Rule } from '../interfaces'

const evaluateRule = <Role, User, Data>(role: Role, rule: Rule<Role, User, Data>, user?: User, data?: Data) => {
  if (!rule) {
    return false
  }

  if (typeof rule === 'function') {
    return Boolean(rule(data, user))
  }

  return rule.includes(role)
}

export default evaluateRule
