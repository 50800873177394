import React from 'react'
import { RouteConfig, renderRoutes } from 'react-router-config'
import { BrowserRouter } from 'react-router-dom'

import Confirmation from './components/Confirmation'
import GoogleAnalytics from './components/GoogleAnalytics'
import HeapAnalytics from './components/HeapAnalytics'
import InputBlurBeforeMouseDown from './components/InputBlurBeforeMouseDown'
import RouterChangeWatcher from './components/RouterChangeWatcher'
import LoadingFullScreen from './components/shared/LoadingFullScreen'
import { useTutorialStatusQuery } from './generated/graphql'
import ErrorPageRedirect from './pages/ErrorPages/components/ErrorPageRedirect'
import ConfirmationProvider from './providers/ConfirmationProvider'
import ElementVirtualPropertiesProvider from './providers/ElementVirtualPropertiesProvider'
import { FullScreenLoadingProvider } from './providers/FullScreenLoadingProvider'
import LayoutProvider from './providers/LayoutProvider'
import ModalProvider from './providers/ModalProvider'
import { SkeletonLoadingProvider } from './providers/SkeletonLoadingProvider'
import { SpinnerLoadingProvider } from './providers/SpinnerLoadingProvider'
// import LoadingOverlay from './components/Tutorial/LoadingOverlay'
// import Tutorial from './components/Tutorial'
import TutorialProvider from './providers/TutorialProvider'
import { WorkspaceContextProvider } from './providers/WorkspaceContextProvider'
import UIProvider from './providers/dataStore/UIProvider'

type AppProps = {
  routes: RouteConfig[]
  id: string
}

const App = ({ routes, id }: AppProps) => {
  const { loading: loadingTutorial, data: tutorial } = useTutorialStatusQuery({
    variables: { id },
    skip: !id
  })

  if (loadingTutorial) {
    return null
  }

  const tutorialStatus = tutorial?.user_preferences_by_pk?.value

  return (
    <>
      {/* @ts-ignore */}
      <TutorialProvider value={{ status: tutorialStatus }} />
      {/* @ts-ignore */}
      <ConfirmationProvider />
      {/* @ts-ignore */}
      <ElementVirtualPropertiesProvider />
      {/* @ts-ignore */}
      <ModalProvider />
      {/* @ts-ignore */}
      <LayoutProvider />
      {/* @ts-ignore */}
      <UIProvider />
      {/* <Tutorial /> */}
      <InputBlurBeforeMouseDown />
      <FullScreenLoadingProvider>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <RouterChangeWatcher />
          <ErrorPageRedirect />
          <WorkspaceContextProvider routes={routes}>
            <SkeletonLoadingProvider>
              <SpinnerLoadingProvider>
                <div id="modal" key="modal" className="relative z-20" />
                {renderRoutes(routes)}
                <LoadingFullScreen />
              </SpinnerLoadingProvider>
            </SkeletonLoadingProvider>
          </WorkspaceContextProvider>
        </BrowserRouter>
      </FullScreenLoadingProvider>

      {/* <LoadingOverlay /> */}
      <Confirmation />
      <HeapAnalytics />
      <GoogleAnalytics />
    </>
  )
}

export default App
