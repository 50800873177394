import { notNull, isArr, isVec2 } from '../commons'
import { Vector2 } from '../Vector2'
import { AABB } from '../AABB'
import { Cell } from './Cell'

/** @typedef {import('./Vertex').Vertex} Vertex */
/** @typedef {import('./Edge').HalfEdge} HalfEdge */


const BOUNDS_OFFSET = 10


export class Contour extends Cell {
    /**
     * Creates new empty Contour object
     * @param {boolean} [virtual=false]
     */
    constructor(virtual = false) {
        super()
        this.isClosed = false
        this.isFilled = false
        this.isHole = false
        this.isSimple = true
        /** @type {Set<HalfEdge>} */
        this.halves = new Set()

        this.isVirtual = virtual

        this.bounds = new AABB()

        /** @type {Set<Vertex>} */
        this._vertices = null
        this.type = 'Contour'
    }

    /**
     * Create Contour object from data
     * @param {ContourData} data
     * @returns {Contour}
     */
    static fromData({ id, isClosed = false, isFilled = false, isHole = false }) {
        super.fromData({ id })
        const c = new Contour()
        /** @todo Instead of the id of SetterData */
        c.id = id
        c.isClosed = isClosed
        c.isFilled = isFilled
        c.isHole = isHole
        c.type = 'Contour'
        return c
    }

    /**
     * @param {object} options
     * @param {boolean} [options.isClosed]
     * @param {boolean} [options.isFilled]
     * @param {boolean} [options.isHole]
     * @param {Set<HalfEdge>|HalfEdge[]} [options.halves]
     */
    set({ isClosed, isFilled, isHole, halves }) {
        if (notNull(isClosed)) {
            this.isClosed = isClosed
        }
        if (notNull(isFilled)) {
            this.isFilled = isFilled
        }
        if (notNull(isHole)) {
            this.isHole = isHole
        }
        if (notNull(halves)) {
            if (halves instanceof Set) {
                this.halves = halves
                this.halves.forEach(half => {
                    if (half.v.upperTierIDs.size > 2){
                        this.isSimple = false
                    }
                    half.addContour(this)
                })
                this.updateBounds()
            }
            else if (isArr(halves)) {
                this.halves.clear()
                this.bounds.reset()
                this.addHalfEdgeList(halves)
            }
        }
    }

    /**
     * Creates a copy of the Contour
     * @returns  {Contour}
     */
    copy() {
        const copy = new Contour()
        copy.isFilled = this.isFilled
        copy.isClosed = this.isClosed
        return copy
    }

    // TODO: do we need to consider id ?
    /**
     * Checks if this Contour is equal to another Contour
     * @param  {Contour} c
     * @returns {boolean}      true if equal; false otherwise
     */
    eq(c) {
        // TODO: how to compare all contour's edges?
        return c &&
            this.isClosed === c.isClosed &&
            this.isFilled === c.isFilled &&
            this.isHole === c.isHole
    }

    /**
     * Serialize Contour data
     * @returns {ContourData}
     */
    save() {
        /** @type {ContourData} */
        const data = {}
        data.id = this.id
        data.isClosed = this.isClosed
        data.isFilled = this.isFilled
        data.isHole = this.isHole
        const halves = [...this.halves]
        data.halves = [
            halves.map(half => half.edge.id),
            halves.map(half => half.index)
        ]
        return data
    }

    disconnect() {
        for (const half of this.halves) {
            half.removeContour(this)
            this.halves.delete(half)
        }
    }

    /**
     * Adds HalfEdge to a contour, as well as connects this contour to added HalfEdge
     * @param {HalfEdge} half
     */
    addHalfEdge(half) {
        if (half.v.upperTierIDs.size > 2){
            this.isSimple = false
        }
        this.halves.add(half)
        half.contours.add(this)

        this.bounds.minMax(half.bounds)
    }

    /**
     * @param {HalfEdge[]} halfList
     */
    addHalfEdgeList(halfList) {
        for (const half of halfList) {
            this.addHalfEdge(half)
        }
    }

    /**
     * Removes HalfEdge from this contour (if has one)
     * @param  {HalfEdge} half
     * @returns {boolean}      true if successfull; false if `half` is not part of this contour
     */
    removeHalfEdge(half) {
        const success = this.halves.delete(half)
        if (success && this.isClosed) {
            this.isClosed = false
        }
        if (success) {
            this.updateBounds()
        }
        return success
    }

    get numEdges() {
        return this.halves.size
    }

    get numPoints() {
        return this.isClosed ? this.halves.size : this.halves.size + 1
    }

    /**
     * @returns {Set<Vertex>}
     */
    get vertices() {
        if (!this._vertices) {
            this._vertices = new Set()
            for (const half of this.halves) {
                this._vertices.add(half.v)
            }
        }
        return this._vertices
    }

    /** @returns {Vertex[]} */
    getVertexList() {
        /** @type {Map<Vertex, Vertex[]>} */
        const refs = new Map()
        for (const half of this.halves) {
            const vRef = refs.get(half.v)
            const wRef = refs.get(half.w)
            // both refs - already have lists on both ends
            if (vRef && wRef) {
                // not same lists on both ends - combine them
                if (vRef !== wRef) {
                    vRef.push(...wRef)
                    refs.set(half.w, vRef)
                    refs.set(vRef[vRef.length - 1], vRef)
                }
            }
            else if (vRef) {
                vRef.push(half.w)
                refs.set(half.w, vRef)
            }
            else if (wRef) {
                wRef.unshift(half.v)
                refs.set(half.v, wRef)
            }
            // no refs - start new list
            else {
                const list = [half.v, half.w]
                refs.set(half.v, list)
                refs.set(half.w, list)
            }
        }
        return refs.values().next().value
    }

    /** @returns {HalfEdge[]} */
    getEdgeList() {
        /** @type {Map<Vertex, HalfEdge[]>} */
        const refs = new Map()
        for (const half of this.halves) {
            const vRef = refs.get(half.v)
            const wRef = refs.get(half.w)
            // both refs - already have lists on both ends
            if (vRef && wRef) {
                vRef.push(half)
                // not same lists on both ends - combine them
                if (vRef !== wRef) {
                    vRef.push(...wRef)
                    refs.set(half.w, vRef)
                    refs.set(vRef[vRef.length - 1].w, vRef)
                }
            }
            else if (vRef) {
                vRef.push(half)
                refs.set(half.w, vRef)
            }
            else if (wRef) {
                wRef.unshift(half)
                refs.set(half.v, wRef)
            }
            // no refs - start new list
            else {
                const list = [half]
                refs.set(half.v, list)
                refs.set(half.w, list)
            }
        }
        return refs.values().next().value
    }

    /** @returns {Vector2[]} */
    getPointList() {
        return this.getVertexList().map(v => v.pos)
    }

    /**
     * @param {Vertex} v
     * @returns {boolean}
     */
    hasVertex(v) {
        for (const half of this.halves) {
            if (half.v === v) {
                return true
            }
        }
        return false
    }

    /**
     * Checks if a point is inside of this Contour
     * @param {[number, number]}  point  vec2-like point coordinates
     * @param {boolean} [isOnEdgeInside=false]  set to true to count point being inside
     *                                       if it lies on any of the Contour's HalfEdges
     * @returns {boolean}      true if point is inside the Contour
     */
    hasPoint(point, isOnEdgeInside = false) {
        if (!isVec2(point)) {
            return false
        }
        if (!this.bounds.containsPoint(point)) {
            return false
        }

        const ray = [point, new Vector2(this.bounds.max[0] + BOUNDS_OFFSET, point[1])]
        let count = 0
        for (const h of this.halves) {
            if (isOnEdgeInside && h.edge.hasPoint(point)) {
                return true
            }
            count += Number(h.edge.hasIntersectionWith(ray))
        }
        return (count % 2 === 1)
    }

    updateBounds() {
        this.bounds.reset()
        for (const h of this.halves) {
            this.bounds.minMax(h.bounds)
        }
    }
}

/**
 * @typedef {object} ContourData
 * @property {string} id
 * @property {boolean} isClosed             true if the contour is closed; false if it is open
 * @property {boolean} isFilled             true if this contour should be filled; false otherwise
 * @property {boolean} isHole               true if this contour is a hole; false otherwise
 * @property {[string[], number[]]} halves  array of two lists: list of edge IDs and list of
 *                                            corresponding HalfEdge indices for each Edge
 * @property {string} type                  is always 'Contour'
 */
