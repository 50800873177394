import { ElementType, BooleanOperation, ContainerElementType, EventFlag } from '@phase-software/types'
import { EPSILON, setAdd, Vector2 } from '@phase-software/data-utils'
import { Group } from './Group'
import { Geometry } from './geometry/Geometry'

// FIXME: create BooleanContainer and MaskContainer
const UNDO_CHANGES = [
    'containerType',
    'booleanType',
    'isMask',
    'invert'
]

const UNDO_EVENTS = ['ADD-CHILDREN', 'REMOVE-CHILDREN']
const CONTAINER_LAYER_COMPATIBILITY_NAMES = ['fills', 'strokes']
const CONTAINER_NORMAL_COMPATIBILITY_NAMES = ['cornerRadius', 'dimensions']

export class Container extends Group {
    /**
     * @param {DataStore} dataStore
     * @param {ContainerData} [data]
     * @param {object} overrides
     */
    constructor(dataStore, data, overrides) {
        super(dataStore, data, overrides)
        this.data.elementType = ElementType.CONTAINER

        setAdd(this.undoChanges, UNDO_CHANGES)
        setAdd(this.undoEvents, UNDO_EVENTS)

        this._hasChangeContainerTypeOnce = false

        this.liftups.push('geometry')

        if (this.isComputedGroup) {
            this.connectWithChildren()
        }
    }

    get clipContent() {
        return !this.get('overflowX') && !this.get('overflowY')
    }

    get isNormalContainer() {
        return this.get('containerType') === ContainerElementType.CONTAINER
    }

    get isNormalGroup() {
        return this.get('containerType') === ContainerElementType.NORMAL_GROUP
    }

    get isComputedGroup() {
        return this.isNormalGroup ||
            this.isBooleanType() ||
            this.isMaskGroup()
    }

    setup(overrides) {
        super.setup(overrides)
        this.dataStore.nameCounter.load(this)
    }

    /**
     * creates a blank scroll group
     * @protected
     */
    create() {
        super.create()
        this.data.elementType = ElementType.CONTAINER
        this.data.expanded = false
        this.data.containerType = ContainerElementType.CONTAINER
        this.data.booleanType = BooleanOperation.NONE
        this.data.invert = false
        this.data.isMask = false

        // non-serializable
        this.data.geometry = new Geometry(this.dataStore)
    }

    /**
     * populates data from a Group
     * @param {ContainerData} data
     */
    load(data) {
        super.load({ ...data, elementType: ElementType.CONTAINER })

        this.data.expanded = data.expanded
        this.data.booleanType = data.booleanType ?? BooleanOperation.NONE
        this.data.invert = data.invert ?? false
        this.data.isMask = data.isMask ?? false
        this.data.containerType = data.containerType ?? ContainerElementType.CONTAINER
        this.data.geometry = new Geometry(this.dataStore)
    }

    /**
     * @param {object} [overrides] data object with overrides
     * @returns {Container}
     */
    clone(overrides) {
        const obj = super.clone(overrides)

        obj.data.expanded = this.data.expanded
        obj.data.containerType = this.data.containerType
        obj.data.booleanType = this.data.booleanType
        obj.data.invert = this.data.invert
        obj.data.isMask = this.data.isMask
        obj.data.geometry = this.data.geometry.clone()

        return obj
    }

    /**
     * saves data for a Group
     * @param {bool} [copy]
     * @returns {ContainerData}
     */
    save(copy) {
        const data = super.save()
        if (copy) {
            data.expanded = this.data.expanded
        }

        data.booleanType = this.data.booleanType
        data.invert = this.data.invert
        data.isMask = this.data.isMask
        data.containerType = this.data.containerType

        return data
    }

    changeContainerType(newContainerType) {
        const currentContainerType = this.get('containerType')
        if (
            (newContainerType !== ContainerElementType.CONTAINER &&
                newContainerType !== ContainerElementType.NORMAL_GROUP) ||
            newContainerType === currentContainerType
        ) {
            return false
        }

        this.dataStore.startTransaction()
        if (newContainerType === ContainerElementType.CONTAINER && currentContainerType === ContainerElementType.NORMAL_GROUP) {
            if (!this._hasChangeContainerTypeOnce) {
                this.sets({ overflowX: true, overflowY: true })
                this.setBaseProp('overflow', { overflowX: true, overflowY: true })
                this._hasChangeContainerTypeOnce = true
            }
            this.disconnectWithChildren()
            this.set('containerType', newContainerType)
            const refPt = this.get('referencePoint')
            const contentAnchor = this.get('contentAnchor')
            const size = this.get('size')
            this.setBaseProp('dimensions', { width: size.x, height: size.y })
            this.setBaseProp('referencePoint', { referencePointX: refPt.x, referencePointY: refPt.y })
            this.setBaseProp('contentAnchor', { contentAnchorX: contentAnchor.x, contentAnchorY: contentAnchor.y })
            this._adjustReferencePoint(false)

        } else if (newContainerType === ContainerElementType.NORMAL_GROUP && currentContainerType === ContainerElementType.CONTAINER) {
            // Remove all layers and their animation tracks
            const elementId = this.get('id')

            // Remove all layer animation tracks
            CONTAINER_LAYER_COMPATIBILITY_NAMES.forEach((layerName) => {
                const computedLayers = [...this.computedStyle[layerName]]
                computedLayers.forEach((computedLayer) => {
                    const layerKey = computedLayer.isNonBase ? computedLayer.get('trackId') : computedLayer.get('layerId')
                    this.dataStore.interaction.deleteLayer(elementId, layerKey)
                })

                // Revmoe all layers
                const layerComponent = this.dataStore.library.getComponent(this.data.base[layerName][0])
                layerComponent.layers.forEach((layerId) => {
                    this.dataStore.library.deleteLayer(layerId)
                })
            })

            // Remove corner radius and size animation tracks
            CONTAINER_NORMAL_COMPATIBILITY_NAMES.forEach((propName) => {
                this.dataStore.interaction.deleteElementPropertyTrack(this.get('id'), propName)
            })

            // Reset corner radius to 0 because normal group won't have corner radius
            this.setBaseProp('cornerRadius', { cornerRadius: 0 })
            this.set('cornerRadius', 0, { commit: false, interaction: false })

            this.dataStore.interaction.fire()

            this.connectWithChildren()
            this._adjustReferencePoint(true)
            this.sets({
                containerType: newContainerType
            })

            // Update bounds to fit children bounds
            this.recalculateBounds()
        }
        this.dataStore.endTransaction()

        return true
    }

    _adjustReferencePoint(container2group) {
        const factor = container2group ? -1 : 1
        if (this.dataStore.isActionMode) {
            const transitionMgr = this.dataStore.transition
            // Base
            const { referencePointX, referencePointY } = this.getBaseProp('referencePoint')
            for (const child of this.children) {
                const { translateX, translateY } = child.getBaseProp('translate')
                const changes = {
                    translateX: translateX + referencePointX * factor,
                    translateY: translateY + referencePointY * factor,
                }
                child.setBaseProp('translate', changes)
                transitionMgr.cacheSpecificElementBaseValue(child.get('id'), 'motionPath')
            }
        }
        // Current
        const refPt = this.get('referencePoint')
        for (const child of this.children) {
            const translate = child.get('translate')
            const changes = {
                translateX: translate.x + refPt.x * factor,
                translateY: translate.y + refPt.y * factor
            }
            child.sets(changes, { flags: EventFlag.FROM_CHANGE_CONTAINER_TYPE, interaction: false })
        }

    }

    recalculateBounds(forceUpdate = false, relocate = true) {
        const { inUndo, inRedo } = this.dataStore.get('undo')
        if (inUndo || inRedo) {
            return
        }

        // Only computedGroup can recalculate boudns with children's change
        if (!forceUpdate && (!this.isComputedGroup || !this.canSyncWithChildren)) {
            return
        }

        // Check whether current element is present as Node on Render side already
        if (!this.dataStore.drawInfo?.isNodePresent(this.get('id'))) {
            return
        }

        this.dataStore.drawInfo.updateNodes()
        const bounds = this.dataStore.drawInfo.getLatestLocalBounds(this.get('id'))

        this.updatePropertiesByBounds(bounds, relocate)
    }

    updatePropertiesByBounds(bounds, relocate){
        if (!Number.isFinite(bounds.x) || !Number.isFinite(bounds.y) || !Number.isFinite(bounds.width) || !Number.isFinite(bounds.height)){
            return
        }

        const contentAnchor = this.get('contentAnchor')
        const newReferencePoint = new Vector2(-bounds.x, -bounds.y)
        const originalReferencePoint = new Vector2(this.get('referencePoint'))
        const originalSize = new Vector2(this.get('size'))

        this._updateBoundsAndReferencePoint(bounds, newReferencePoint)
        if (!this.dataStore.isActionMode && relocate && this._previousMode === this.dataStore.get('mode')) {
            this._updateContentAnchorAndTranslate(bounds, newReferencePoint, originalSize, originalReferencePoint, contentAnchor)
        }
    }

    _updateBoundsAndReferencePoint(newBounds, referencePoint) {
        this.sets({
            width: newBounds.width,
            height: newBounds.height,
            referencePointX: referencePoint.x,
            referencePointY: referencePoint.y,
        }, {
            flags: EventFlag.FROM_CHILDREN_CHANGE
        })
    }

    _updateContentAnchorAndTranslate(newBounds, referencePoint, originalSize, originalReferencePoint, contentAnchor) {
        // Adjusted method to include originalReferencePoint in the calculation
        const ratioX = Math.abs(originalSize.x) < EPSILON ? 0.5 : (originalReferencePoint.x + contentAnchor.x) / originalSize.x
        const ratioY = Math.abs(originalSize.y) < EPSILON ? 0.5 : (originalReferencePoint.y + contentAnchor.y) / originalSize.y

        const changes = {
            contentAnchorX: newBounds.width * ratioX - referencePoint.x,
            contentAnchorY: newBounds.height * ratioY - referencePoint.y,
        }

        const translate = this.dataStore.drawInfo.getFixedPositionByChanges(this.get('id'), changes)
        if (translate) {
            changes.translateX = translate[0]
            changes.translateY = translate[1]
            this.sets(changes, {
                flags: EventFlag.FROM_CHILDREN_CHANGE
            })
        }
    }

    updateChildrenSizeWith(changes) {
        const { inUndo, inRedo } = this.dataStore.get('undo')
        if (inUndo || inRedo) {
            return
        }

        if (!changes.has('size') || !this.children || !this.children.length) {
            return
        }

        if (!this.isComputedGroup) {
            return
        }

        const sizeChagne = changes.get('size')
        const result = this.dataStore.drawInfo.getChildrenPropetiesAfterResize(
            this,
            sizeChagne.original[0],
            sizeChagne.original[1],
            sizeChagne.value[0],
            sizeChagne.value[1]
        )

        for (const child of this.children) {
            const childId = child.get('id')
            if (result.has(childId)) {
                // Add flags to stop event propagation
                child.sets(result.get(childId), { flags: EventFlag.FROM_PARENT_CHANGE })
            }
        }

        this.recalculateBounds()
    }

    clear() {
        super.clear()
        this.dataStore.nameCounter.remove(this)
    }
}
