import { EventEmitter } from 'eventemitter3'
import { BooleanOperation, PaintType, TrimPathMode } from '@phase-software/types'
import { Rect2 } from '../math'
import { Layer } from '../tile_renderer/TileRenderer'
import { VectorResource } from '../resources/VectorResource'
import { StrokeStyle } from '../geometry/bezier-shape/stroke2'
import { MIN_SIZE_THRESHOLD } from '../constants'
import { Transform } from './Transform'
import { Bounds } from './Bounds'
import { BaseTransform } from './BaseTransform'
import { dino, getNodeDino } from '../dino'

/** @typedef {import('../math').Color} Color */
/** @typedef {import('../math').Transform2D} Transform2D */
/** @typedef {import('../math/Vector2').Vector2Like } Vector2Like */
/** @typedef {import('../geometry/bezier-shape/BezierShape').BezierShape} BezierShape */
/** @typedef {import('../resources/GradientResource').GradientResource} GradientResource */
/** @typedef {import('./VisualServer').VisualServer} VisualServer */
/** @typedef {import('../tile_renderer/TileRenderer').BlendModes} BlendModes */
/** @typedef {import('@phase-software/types').JoinShape} JoinShape */
/** @typedef {import('@phase-software/types').CapShape} CapShape */
/** @typedef {import('@phase-software/types').PaintType} PaintType */
/** @typedef {import('../tile_renderer/TileRenderer').ImageOptions} ImageOptions */

/**
 * @typedef { "path" | "text" | "container" | "screen" | "group" } NodeTypes
 * @typedef { "value" | "gradient" } OpacityTypes
 * @typedef { "mask" | "union" | "subtract" | "intersect" | "difference" } GeometryValues
 * @typedef { "linear" | "radial" | "angular" | "diamond" } GradientTypes
 *
 * @typedef TrimData
 * @property {boolean} enabled
 * @property {number} begin
 * @property {number} end
 * @property {number} offset
 * @property {TrimPathMode} mode
 * @property {number} version
 */

/** @enum {number} */
export const UpdateType = {
    TRANSFORM: 1,
    GEOMETRY: 2,
    STYLE: 4,
}

/** @enum {number} */
export const MaskType = {
    NONE: 0,
    ALPHA: 1,
    INV_ALPHA: 2,
    LUMEN: 3,
    INV_LUMEN: 4,
}

/** @enum {string} */
export const ShapeType = {
    BASE: 'BASE',
    CORNER: 'CORNER',
    SELF_TRIM: 'SELF_TRIM',
    PARENT_TRIM: 'PARENT_TRIM',
}

export const PathTypeOrder = [
    ShapeType.BASE,
    ShapeType.CORNER,
    ShapeType.SELF_TRIM,
    ShapeType.PARENT_TRIM,
]

export function RenderGeometry() {
    this.id = ''
    /** @type {BezierShape} */
    this.shape = null

    this.vector = new VectorResource()
    /** @type {VectorResource} */
    this.modVector = null
    /** @type {[number, ShapeType, BezierShape][]} */
    this.mods = []

    /** @type {StrokeStyle} */
    this.style = new StrokeStyle()
}
RenderGeometry.prototype = {
    constructor: RenderGeometry,
    /**
     * @param {number} relativeDepth // the depth we're looking for
     * @param {string} type // the return path will become to this type of path
     * @returns {BezierShape}
     */
    getFinalShape(relativeDepth = 1, type = ShapeType.PARENT_TRIM) {
        for (let index = this.mods.length - 1; index >= 0; index--) {
            if (!this.mods[index]) continue
            if (relativeDepth === 1) return this.mods[index][2]
            const depth = this.mods[index][0]
            if (depth >= relativeDepth) {
                if (relativeDepth === 0) {
                    const orderIndex = PathTypeOrder.indexOf(type) - 1
                    if (orderIndex >= 0) {
                        for (let i = orderIndex; i >= 0; i--) {
                            if (this.mods[i]) return this.mods[i][2]
                        }
                    }
                    // return base path
                    return this.mods[0][2]
                }
                return this.mods[index][2]
            }
        }
    },

    /**
     * @param {number} depth
     * @param {ShapeType} type
     * @param {BezierShape} shape
     */
    setShape(depth, type, shape) {
        switch (type) {
            case ShapeType.BASE:
                this.mods[0] = [0, type, shape]
                break
            case ShapeType.CORNER:
                this.mods[1] = [0, type, shape]
                break
            case ShapeType.SELF_TRIM:
                this.mods[2] = [0, type, shape]
                break
            case ShapeType.PARENT_TRIM:
                this.mods[2 - depth] = [depth, type, shape]
                break
            default:
                console.error('setShape() get wrong path type')
                break
        }
    },

    /**
     * @param {ShapeType} type
     * @param {number} depth
     */
    deleteShape(type, depth) {
        if (type === ShapeType.PARENT_TRIM) {
            this.mods[2 - depth] = null
        } else {
            const index = PathTypeOrder.indexOf(type)
            this.mods[index] = null
        }
        this.modVector = null
    },

    getFinalVector() {
        const finalPath = this.getFinalShape()
        // TOFIX: Need a better way to verify if it's base shape?
        // Currently, base shape will be re-newed/re-create, for exmaple, when size changes in node-setup.js,
        // this causes it to be treated as a different shape even though the shape data are totally the same.
        if (finalPath === this.shape) return this.vector

        if (!this.modVector) {
            this.modVector = new VectorResource()
            this.modVector.geo_type = 1
        }
        return this.modVector
    },

    hasMods() {
        for (let index = this.mods.length - 1; index >= 0; index--) {
            if (this.mods[index]) return true
        }
        return false
    },
}

export class RootTree {
    id = 0
    fills = new Tree()
    strokes = new Tree()
    children = new Tree()

    /** Base path, use for RenderNode's bbox */
    base_path_id = 0
    /** Final (Modified) path, use for all fills and strokes  */
    path_id = 0
}

export class Tree {
    id = 0
    /** @type {Tree[]} */
    children = []
    path_id = 0
    fill_id = 0
    fill_tag = 0
    stroke_id = 0
    stroke_tag = 0
    stroke_data_id = 0
    /** @type {Tree} */
    compose = [null, null]
}

/**
 * @param {string} id
 */
export function RenderItem(id) {
    /** @type {string} */
    this.id = id

    /** @type {string} */
    this.name = ''

    /** @type {NodeTypes} */
    this.type = 'group'

    /** @type {MaskType} */
    this.maskType = MaskType.NONE

    /** @type {BooleanOperation} */
    this.booleanType = BooleanOperation.NONE

    /** @type {number} */
    this.depth = 0

    /** @type {Rect2} */
    this.childUnionBounds = new Rect2()

    /** @type {EventEmitter} */
    this.events = new EventEmitter()

    /** @type {Transform} */
    this.transform = new Transform()
    /**
     * NOTE: `baseTransform` is ONLY used for "group" action
     * @type {BaseTransform}
     */
    this.baseTransform = new BaseTransform(this.transform)
    /** @type {Bounds} */
    this.bounds = new Bounds()
    /** @type {Rect2} */
    this.bbox = new Rect2()

    /** @type {BlendModes} */
    this.blendMode = 'passthrough'

    /** @type {TrimData} */
    this.trim = {
        get enabled() { return this.begin !== 0 || this.end !== 1 || this.offset !== 0 },
        offset: 0,
        begin: 0,
        end: 1,
        mode: TrimPathMode.SIMULTANEOUSLY,
        version: 0
    }

    /** @type {{ visible: boolean, amount: number }} */
    this.blur = {
        visible: false,
        amount: 0,
    }

    /** @type {{ type: OpacityTypes, value: number, gradient: GradientResource }} */
    this.opacity = {
        type: 'value',
        value: 1,
        gradient: null,
    }

    // geometries
    /** @type { RenderGeometry } */
    this.base = new RenderGeometry()
    /** @type { RenderGeometry [] } */
    this.strokes = []

    // layers
    /** @type {Layer[]} */
    this.fillLayers = []
    /** @type {Layer[]} */
    this.strokeLayers = []

    // effects
    /** @type {Layer[]} */
    this.innerShadows = []
    /** @type {Layer[]} */
    this.dropShadows = []

    // t0 (true zero), f0 (fake zero), !0 (non-zero)
    this.sizeFlag = {
        /** @type {'!0' | 't0' | 'f0'} */
        w: '!0',
        /** @type {'!0' | 't0' | 'f0'} */
        h: '!0'
    }
    // 0 (property panel zero), !0 (non-zero)
    this.scaleFlag = {
        /** @type {'!0' | '0'} */
        x: '!0',
        /** @type {'!0' | '0'} */
        y: '!0'
    }

    /**
     * @private
     * @type {boolean}
     */
    this.visible = true
    /**
     * @private
     * @type {boolean}
     */
    this.locked = false

    /**
     * @private
     * @type {boolean}
     */
    this.freed = false

    /**
     * @type {boolean}
     */
    this.clipping = false

    /**
     * @type {boolean}
     */
    this.autoOrient = false

    /**
     * @type {number}
     */
    this.orientRotation = 0

    /**
     * @type {boolean}
     */
    this.isEmpty = false

    /**
     * @type {number}
     */
    this.version = 0

    /**
     * @type {number}
     */
    this.updateFlags = 0

    /** @type {VisualServer} */
    this.visualServer = null

    /** @type {RootTree} */
    this.dino = new RootTree()
    /** @type {RootTree[]} */
    this.dino_actions = []
}
RenderItem.prototype = {
    constructor: RenderItem,
    isVisible() {
        return this.freed ? false : this.visible
    },

    isContainer() {
        return this.type === "container"
    },

    isContainerNormalGroup() {
        return this.type === "container" || this.type === "group"
    },

    isNormalGroup() {
        return this.type === "group" && !this.isMaskGroup() && !this.isBooleanGroup()
    },

    isComputedGroup() {
        return this.type === "group" || this.isMaskGroup() || this.isBooleanGroup()
    },

    isScreen() {
        return this.type === "screen"
    },

    isMaskGroup() {
        return (
            this.maskType === MaskType.ALPHA
            ||
            this.maskType === MaskType.INV_ALPHA
            ||
            this.maskType === MaskType.LUMEN
            ||
            this.maskType === MaskType.INV_LUMEN
        )
    },

    isBooleanGroup() {
        return (
            this.booleanType === BooleanOperation.UNION
            ||
            this.booleanType === BooleanOperation.SUBTRACT
            ||
            this.booleanType === BooleanOperation.INTERSECT
            ||
            this.booleanType === BooleanOperation.DIFFERENCE
        )
    },

    /* general */

    onTransformUpdated() {
        this.update(UpdateType.TRANSFORM)
    },

    /**
     * @param {number} flags
     */
    update(flags) {
        this.updateFlags |= flags
        this.visualServer.updateList.add(this)
        this.version = this.visualServer.currentVersion()
    },

    clear() {
        this.removeFills()
        this.removeStrokes()
        this.removeInnerShadows()
        this.removeDropShadows()

        // reset states
        this.version = 0
        this.clipping = false
        this.isEmpty = false
        this.updateFlags = 0
        this.freed = false
        this.visible = true
        this.depth = 0

        this.blur.amount = 0
        this.blur.visible = false

        this.blendMode = 'normal'

        this.maskType = MaskType.NONE
        this.booleanType = BooleanOperation.NONE

        this.bounds.reset()
        this.bbox.set(0, 0, 0, 0)
        this.transform.reset()
        this.baseTransform.connectRef(this.transform)
        this.transform.off('updateWorld', this.onTransformUpdated, this)

        this.events.removeAllListeners()

        this.name = ''
        this.type = 'group'

        if (this.base.vector) {
            this.base.vector.free()
            this.base.vector = null
        }
        if (this.strokes.length) {
            for (const s of this.strokes) {
                if (s.vector) {
                    s.vector.free()
                    s.vector = null
                }
            }
        }

        return true
    },

    /**
     * @param {Vector2Like} size
     */
    setSizeFlag(size) {
        this.sizeFlag.w = getSizeFlag(size.x)
        this.sizeFlag.h = getSizeFlag(size.y)
    },

    /**
     * @param {Vector2Like} scale
     */
    setScaleFlag(scale) {
        const scaleX = scale.x
        const scaleY = scale.y
        this.scaleFlag.x = Math.abs(scaleX) < 5e-3 ? '0' : '!0'
        this.scaleFlag.y = Math.abs(scaleY) < 5e-3 ? '0' : '!0'
    },

    /**
     * @param {boolean} visible
     */
    setVisible(visible) {
        if (this.visible === visible) return
        const before = this.visible

        this.visible = visible
        if (before !== this.visible) {
            this.update(UpdateType.STYLE | UpdateType.TRANSFORM)
        }
    },

    /**
     * @param {number} value
     */
    setOpacity(value) {
        if (this.opacity.type === "value" && this.opacity.value === value) return

        this.opacity.type = "value"
        this.opacity.value = value

        this.update(UpdateType.STYLE)
    },
    /**
     * @param {GradientResource} gradient
     */
    setOpacityGradient(gradient) {
        if (!gradient) {
            console.error(`Cannot set null opacity gradient`)
            return
        }

        this.opacity.type = "gradient"
        this.opacity.gradient = gradient

        this.update(UpdateType.STYLE)
    },
    /**
     * @param {number} locked
     */
    setLocked(locked) {
        if (this.locked === locked) return

        this.locked = locked
        this.update(UpdateType.STYLE | UpdateType.TRANSFORM)
    },

    /**
     * @param {BlendModes} blend
     */
    setBlendMode(blend) {
        this.blendMode = blend

        this.update(UpdateType.STYLE)
    },

    /**
     * @param {number} geometryIndex
     * @param {number} index
     * @param {boolean} visible
     */
    setLayerVisible(geometryIndex, index, visible) {
        const item = getLayer(this, geometryIndex, index)
        if (item) {
            item.visible = visible
        }

        this.update(UpdateType.STYLE | UpdateType.TRANSFORM)
    },
    /**
     * @param {number} index
     * @param {boolean} visible
     */
    setInnerShadowVisible(index, visible) {
        const item = getInnerShadow(this, index)
        if (item) {
            item.visible = visible
        }

        this.update(UpdateType.STYLE | UpdateType.TRANSFORM)
    },
    /**
     * @param {number} index
     * @param {boolean} visible
     */
    setDropShadowVisible(index, visible) {
        const item = getDropShadow(this, index)
        if (item) {
            item.visible = visible
        }

        this.update(UpdateType.STYLE | UpdateType.TRANSFORM)
    },

    /* paint */

    /**
     * @param {number} geometryIndex
     * @param {number} index
     * @param {Color} color
     * @param {number} opacity
     */
    solid(geometryIndex, index, color, opacity) {
        const layer = getLayer(this, geometryIndex, index)
        layer.solid(color, opacity)
        this.update(UpdateType.STYLE)
    },

    /**
     * @param {number} geometryIndex
     * @param {number} index
     * @param {PaintType} type
     * @param {{ position: number, color: RGBA }[]} colorStops
     * @param {Transform2D} transform
     * @param {number} opacity
     */
    gradient(geometryIndex, index, type, colorStops, transform, opacity) {
        const layer = getLayer(this, geometryIndex, index)
        /** @type {GradientResource} */
        let gradient = layer?.paint?.gradient
        if (!gradient) {
            gradient = this.visualServer.storage.createGradientResource()
        }
        layer.gradient(type, gradient, transform, opacity)
        // update gradient color stops
        gradient.setColorStops(colorStops)
        this.update(UpdateType.STYLE)
    },

    /**
     * @param {number} geometryIndex
     * @param {number} index
     * @param {string} imageID
     * @param {ImageOptions} options
     * @param {number} opacity
     */
    image(geometryIndex, index, imageID, options, opacity) {
        const layer = getLayer(this, geometryIndex, index)
        layer.image(imageID, options, opacity)

        this.update(UpdateType.STYLE)
    },

    styleUpdated() {
        this.update(UpdateType.STYLE)
    },

    removeFills() {
        const api = dino()
        const { dinoNode, idx } = getNodeDino(this)
        console.log('removeFills', dinoNode, idx)

        for (let i = 0; i < this.fillLayers.length; i++) {
            const layer = this.fillLayers[i]
            if (!dinoNode.fills.children[i]) continue

            switch (layer.paint.type) {
                case PaintType.SOLID: {
                    api.destroyColor(dinoNode.fills.children[i].fill_id)
                    dinoNode.fills.children[i].fill_id = 0
                    dinoNode.fills.children[i].fill_tag = 0
                    break
                }
                case PaintType.GRADIENT_LINEAR:
                case PaintType.GRADIENT_RADIAL:
                case PaintType.GRADIENT_ANGULAR:
                case PaintType.GRADIENT_DIAMOND: {
                    api.destroyGradient(dinoNode.fills.children[i].fill_id)
                    dinoNode.fills.children[i].fill_id = 0
                    dinoNode.fills.children[i].fill_tag = 0
                    break
                }
                case PaintType.IMAGE: {
                    // Image is shared, no need to destroy
                    dinoNode.fills.children[i].fill_id = 0
                    dinoNode.fills.children[i].fill_tag = 0
                    break
                }
            }
            api.destroyNode(dinoNode.fills.children[i].id)
            dinoNode.fills.children[i].id = 0

            layer.clear()
        }
        dinoNode.fills.children = []
        this.fillLayers.length = 0

        this.update(UpdateType.STYLE)
    },
    removeStrokes() {
        const api = dino()
        const { dinoNode } = getNodeDino(this)
        for (let i = 0; i < this.strokeLayers.length; i++) {
            const layer = this.strokeLayers[i]
            if (!dinoNode.strokes.children[i]) continue

            switch (layer.paint.type) {
                case PaintType.SOLID: {
                    api.destroyColor(dinoNode.strokes.children[i].stroke_id)
                    dinoNode.strokes.children[i].stroke_id = 0
                    dinoNode.strokes.children[i].stroke_tag = 0
                    break
                }
                case PaintType.GRADIENT_LINEAR:
                case PaintType.GRADIENT_RADIAL:
                case PaintType.GRADIENT_ANGULAR:
                case PaintType.GRADIENT_DIAMOND: {
                    api.destroyGradient(dinoNode.strokes.children[i].stroke_id)
                    dinoNode.strokes.children[i].stroke_id = 0
                    dinoNode.strokes.children[i].stroke_tag = 0
                    break
                }
                case PaintType.IMAGE: {
                    // Image is shared, no need to destroy
                    dinoNode.strokes.children[i].stroke_id = 0
                    dinoNode.strokes.children[i].stroke_tag = 0
                    break
                }
            }
            api.destroyNode(dinoNode.strokes.children[i].id)
            dinoNode.strokes.children[i].id = 0
            api.destroyStroke(dinoNode.strokes.children[i].stroke_data_id)
            dinoNode.strokes.children[i].stroke_data_id = 0

            layer.clear()
        }
        dinoNode.strokes.children = []
        this.strokeLayers.length = 0

        this.update(UpdateType.STYLE | UpdateType.GEOMETRY | UpdateType.TRANSFORM)
    },
    removeInnerShadows() {
        for (const layer of this.innerShadows) {
            layer.clear()
        }
        this.innerShadows.length = 0

        this.update(UpdateType.STYLE)
    },
    removeDropShadows() {
        for (const layer of this.dropShadows) {
            layer.clear()
        }
        this.dropShadows.length = 0

        this.update(UpdateType.STYLE | UpdateType.TRANSFORM)
    },


    /* effect */

    /**
     * @param {number} blur
     */
    setBlur(blur) {
        this.blur.amount = blur
        this.blur.visible = blur > 0.5

        this.update(UpdateType.STYLE | UpdateType.TRANSFORM)
    },

    /**
     * @param {number} index
     * @param {number} x
     * @param {number} y
     * @param {number} blur
     * @param {Color} color
     */
    setDropShadow(index, x, y, blur, color) {
        const layer = getDropShadow(this, index)
        layer.dropShadow(x, y, blur, color)

        this.update(UpdateType.STYLE | UpdateType.TRANSFORM)
    },

    /**
     * @param {number} index
     * @param {number} x
     * @param {number} y
     * @param {number} blur
     * @param {Color} color
     */
    setInnerShadow(index, x, y, blur, color) {
        const layer = getInnerShadow(this, index)
        layer.innerShadow(x, y, blur, color)

        this.update(UpdateType.STYLE)
    },

    /**
     * @param {number} offset
     * @param {number} begin
     * @param {number} end
     * @param {TrimPathMode} mode
     */
    setTrimPath(offset, begin, end, mode) {
        this.trim.offset = Number.isFinite(offset) ? offset : 0
        this.trim.begin = Number.isFinite(begin) ? begin : 0
        this.trim.end = Number.isFinite(end) ? end : 1
        this.trim.mode = mode || TrimPathMode.SIMULTANEOUSLY
        this.trim.version = -1
        this.update(UpdateType.GEOMETRY | UpdateType.TRANSFORM)
    },
}

/**
 * @param {RenderItem} node
 * @returns {Layer}
 */
function makeLayer(node) {
    const layer = new Layer()
    layer.storage = node.visualServer.storage
    return layer
}

/**
 * @param {RenderItem} node
 * @param {number} geometryIndex -1 means base, otherwise strokes
 * @param {number} index
 * @returns {Layer}
 */
function getLayer(node, geometryIndex, index) {
    /** @type {Layer} */
    let layer = null
    if (geometryIndex < 0) {
        if (index >= node.fillLayers.length) {
            node.fillLayers[index] = makeLayer(node)
        }
        layer = node.fillLayers[index]
    } else {
        if (index >= node.strokeLayers.length) {
            node.strokeLayers[index] = makeLayer(node)
        }
        layer = node.strokeLayers[index]
    }
    return layer
}

/**
 * @param {RenderItem} node
 * @param {number} index
 * @returns {Layer}
 */
function getInnerShadow(node, index) {
    /** @type {Layer} */
    let item = null
    if (index >= node.innerShadows.length) {
        node.innerShadows[index] = makeLayer(node)
    }
    item = node.innerShadows[index]
    return item
}

/**
 * @param {RenderItem} node
 * @param {number} index
 * @returns {Layer}
 */
function getDropShadow(node, index) {
    /** @type {Layer} */
    let item = null
    if (index >= node.dropShadows.length) {
        node.dropShadows[index] = makeLayer(node)
    }
    item = node.dropShadows[index]
    return item
}

/**
 * @param {number} value
 * @returns {'t0' | 'f0' | '!0'}
 */
export function getSizeFlag(value) {
    if (value === 0) return 't0'
    if (value < MIN_SIZE_THRESHOLD) return 'f0'
    return '!0'
}
