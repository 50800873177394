import { TFunction } from 'i18next'

import { VersionFieldsFragment } from '../generated/graphql'
import { i18n } from '../i18n'
import { formatUnnamedVersionName, formatVersionCreatedTime } from './formatter'

export enum VersionType {
  LATEST = 'current',
  UNNAMED = 'unnamed',
  NAMED = 'named'
}

export type VersionProps = {
  id: VersionFieldsFragment['id']
  displayName: string
  editName: string
  createdAt: string
  type: VersionType
  description: string
  contributors: VersionContributorProps[]
  defaultFileName: string
}

export type VersionContributorProps = {
  username?: string | null
  avatar?: string | null
}

export const LATEST_VERSION_ITEM_ID = 'latest'

export const LATEST_VERSION_ITEM = {
  id: LATEST_VERSION_ITEM_ID,
  name: 'file:version.latest',
  save_type: VersionType.LATEST,
  created_at: null
}

export const transformVersion = (t: TFunction, version: VersionFieldsFragment): VersionProps => {
  const isUnnamedType = version.save_type === VersionType.UNNAMED

  const formattedCreatedAt = formatVersionCreatedTime(version.created_at, t)

  const displayName = isUnnamedType ? formattedCreatedAt : version.name || ''

  const fileName = version.parent_file?.name
  const defaultFileNamePrefix = fileName && fileName.length > 200 ? `${fileName.substring(0, 200)}...` : fileName

  return {
    id: version.id,
    displayName,
    editName: isUnnamedType ? formatUnnamedVersionName(version.created_at, i18n.language) : version.name || '',
    defaultFileName: t('workspace:duplicated_file_name', { file_name: `${defaultFileNamePrefix}-${displayName}` }),
    type: version.save_type as VersionType,
    createdAt: formattedCreatedAt,
    description: version.description || '',
    contributors: version.contributed_users || []
  }
}
