import { useCallback } from 'react'

import JSZip from 'jszip'

import { ConfirmationType, MediaType } from '@phase-software/types'

import { CONFIRMATION_MAP } from '../constant'
import dataStore from '../dataStore'
import { useSetConfirmation } from '../providers/ConfirmationProvider'
import { useWorkspaceContext } from '../providers/WorkspaceContextProvider'
import { useDataStoreActions } from '../providers/dataStore/DataStoreProvider'
import { b64toBlob } from '../utils/file'
import useFileActions, { FileCreationMethod } from './useFileActions'

// Define interfaces for clearer type definitions
interface JsonData {
  assets: Asset[]
}

interface Animation {
  id?: string
  file?: string
  loop?: boolean
  themeColor?: string
  speed?: number
}

interface Asset {
  id: string
  u?: string // URL path for the asset
  p?: string // URL/Path for the image or base64 string
  e?: number // Extra properties that might exist
  w?: number // Width, if applicable
  h?: number // Height, if applicable
}

interface Manifest {
  version: string
  animations: Animation[]
  author: string
  generator: string
  license?: string
  framerate?: number
}

interface LottieJSON {
  v?: string // Version
  fr?: number // Frame rate
  ip?: number // In point
  op?: number // Out point
  w?: number // Width
  h?: number // Height
  nm?: string // Name
  ddd?: number // 3D property
  assets: Asset[] // Array of assets
  layers?: any[] // Array of animation layers
}

// Define the structure for the MIME types object
const MIME_TYPES: Record<string, string> = {
  png: 'image/png',
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  gif: 'image/gif',
  svg: 'image/svg+xml'
}

const useDotLottie = () => {
  const { showConfirmation } = useSetConfirmation()
  const { workspaceData } = useWorkspaceContext()
  const { createOrImportFile } = useFileActions()
  const { createLottieJSON } = useDataStoreActions()

  // Function to get MIME type based on file extension
  const getMimeType = (extension: string): string => {
    const defaultMimeType = 'application/octet-stream' // Fallback MIME type
    return MIME_TYPES[extension.toLowerCase()] || defaultMimeType
  }

  const convertToBase64 = useCallback(async (file: Blob, extension: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          // Determine the correct MIME type
          const mimeType = getMimeType(extension)
          const base64Data = reader.result.replace(/^data:.+;base64,/, `data:${mimeType};base64,`)
          resolve(base64Data)
        } else {
          reject(new Error('Expected reader result to be a string'))
        }
      }
      reader.onerror = (error) => reject(error)
      reader.readAsDataURL(file)
    })
  }, [])

  // Helper function to process images in JSON data
  const processImagesAndReplaceImport = useCallback(
    async (jsonData: LottieJSON, zipContent: JSZip) => {
      for (const asset of jsonData.assets) {
        if (asset.p && !asset.p.startsWith('data:image')) {
          // Use asset.u if available, otherwise default to "images/"
          const imagePath = `${asset.u || 'images/'}${asset.p}`
          const imageFile = zipContent.files[imagePath]
          if (imageFile) {
            const extension = imagePath.split('.').pop() || '' // Extract extension from filename
            const base64 = await convertToBase64(await imageFile.async('blob'), extension)
            asset.p = base64 // Replace image path with base64 data
            asset.u = ''
            console.log(`Converted image to base64 with MIME type: ${getMimeType(extension)} for path: ${imagePath}`)
          } else {
            console.error(`Image file not found in zip: ${imagePath}`)
          }
        }
      }
    },
    [convertToBase64]
  )

  // Function to process images: extract base64 and convert to files
  const processImagesAndReplaceExport = useCallback(async (jsonData: JsonData, zip: JSZip): Promise<JsonData> => {
    let index = 0
    const updatedAssets = jsonData.assets.map((asset) => {
      if (asset.p && asset.p.startsWith('data:image')) {
        const base64Content = asset.p.split(',')[1]
        const mimeType = asset.p.match(/data:([^;]+);base64/)?.[1] ?? ''
        const extension = mimeType.split('/')[1]
        const filename = `image_${index}.${extension}`
        const blob = b64toBlob(base64Content, mimeType)

        zip.file(`images/${filename}`, blob, { binary: true })
        index++
        return { ...asset, u: 'images/', p: filename } // Create a new asset object with updated path
      }
      return asset // Return the unmodified asset if no changes are needed
    })

    return { ...jsonData, assets: updatedAssets } // Return new JSON data with updated assets array
  }, [])

  const downloadDotLottieFile = useCallback(
    async ({
      fileName: exportedFileName,
      fps,
      speed,
      start,
      end
    }: {
      fileName: string
      fps: number
      speed: number
      start: number
      end: number
    }) => {
      // Step 1: Generate the Lottie JSON
      const lottieJson = await createLottieJSON({ fps, speed, start, end })
      // const jsonString = JSON.stringify(lottieJson);
      const fileName = 'animation.json' // Name of the Lottie JSON file inside the dotLottie

      // Step 2: Create the manifest.json content
      const manifest = {
        version: '1.0',
        author: 'Phase Software',
        generator: 'Phase-' + process.env.REACT_APP_GIT_SHA,
        animations: [
          {
            id: fileName.replace(/\.[^/.]+$/, ''),
            autoplay: true,
            loop: true,
            direction: 1,
            playMode: 'normal',
            speed: speed,
            themeColor: '#ffcc00' // Example theme color, adjust as necessary
          }
        ]
      }

      // Step 3: Create a .dotlottie file (which is essentially a zip)
      const zip = new JSZip()
      const updatedLottieJson = await processImagesAndReplaceExport(lottieJson, zip) // Get updated JSON data
      zip.file('animations/' + fileName, JSON.stringify(updatedLottieJson)) // Add the JSON file to the zip
      zip.file('manifest.json', JSON.stringify(manifest)) // Add the manifest file to the zip
      // Optional: Add any additional files or a manifest file if required by the .dotlottie specification

      // Step 4: Generate the zip file and trigger a download
      const blob = await zip.generateAsync({ type: 'blob' })
      const reader = new FileReader()
      reader.onload = function () {
        const dataUrl = reader.result
        dataStore.eam.exportFinish(dataUrl, {
          fileName: exportedFileName,
          type: MediaType.DOTLOTTIE
        })
      }
      reader.readAsDataURL(blob)
    },
    [createLottieJSON, processImagesAndReplaceExport]
  )

  const handleDotLottieFiles = useCallback(
    async (file: File, projectId: string): Promise<void> => {
      const zip = new JSZip()
      const zipContent = await zip.loadAsync(file)
      let manifest: Manifest
      if (zipContent.files['manifest.json']) {
        const manifestContent = await zipContent.files['manifest.json'].async('string')
        manifest = JSON.parse(manifestContent)
      } else {
        console.error('Manifest file missing.')
        return
      }

      const animations = manifest?.animations
      const ignoreReminderDotLottieDialog = Boolean(localStorage.getItem('ignoreReminderDotLottieDialog'))
      if (!ignoreReminderDotLottieDialog && animations.length > 1) {
        const confirmation = CONFIRMATION_MAP[ConfirmationType.IMPORT_DOTLOTTIE_MULTIPLE_ANIMATIONS_DIALOG_IN_ACTION]
        // @ts-ignore TODO: need to add type in showConfirmation
        showConfirmation({
          ...confirmation,
          onConfirm: (checked: boolean) => {
            if (checked) {
              localStorage.setItem('ignoreReminderDotLottieDialog', 'true')
            }
            // Dynamically determine the base URL from the current location
            const url = new URL(window.location.href)
            const baseUrl = `${url.origin}${process.env.PUBLIC_URL}`

            const toGo = `${baseUrl}/${workspaceData.type}/${workspaceData.slug}/p/${projectId}`
            if (window.location.href !== toGo) {
              // Redirect to the correct project URL if not already there
              window.location.href = toGo
            } else {
              // Reload the page if already on the correct project page
              // IF ALREADY ON THE RIGHT PAGE, THEN REFRESH THE DATA NOT THE PAGE
              window.location.reload()
            }
          }
        })
      }

      for (let i = 0; i < animations.length; i++) {
        const animation = animations[i]
        const filename = animation.file || `animations/${animation.id}.json`
        const fileDetails = zipContent.files[filename]

        if (fileDetails && !fileDetails.dir) {
          const data = await fileDetails.async('string')
          const jsonData = JSON.parse(data)
          await processImagesAndReplaceImport(jsonData, zipContent)
          const updatedAnimationData = JSON.stringify(jsonData)
          const jsonBlob = new Blob([updatedAnimationData], { type: 'application/json' })
          const jsonFile = new File([jsonBlob], animation.id + '.json', { type: 'application/json' })
          await createOrImportFile({
            projectId,
            file: jsonFile,
            method: FileCreationMethod.IMPORT,
            openInNewTab: false,
            multipleAnimations: animations.length > 1
          })
        } else {
          console.error(`File specified in manifest not found or is a directory: ${filename}`)
        }
      }
    },
    [createOrImportFile, showConfirmation, workspaceData, processImagesAndReplaceImport]
  )

  return {
    processImagesAndReplaceImport,
    processImagesAndReplaceExport,
    handleDotLottieFiles,
    downloadDotLottieFile
  }
}

export default useDotLottie
