import { Bezier } from 'bezier-js/src/bezier'
import { Mesh, MIN_SIZE_THRESHOLD } from '@phase-software/data-utils'
import {
  ElementType,
  Unit,
  EasingType,
  PaintType,
  LayerType,
  GeometryType,
  CapShape,
  JoinShape,
  TrimPathMode,
  BooleanOperation,
  BlendMode as PhaseBlendMode
} from '@phase-software/types'
import {
  BlendMode,
  KeyFrame,
  GradientFillType,
  GradientStrokeType,
  ShapeType,
  LineCapType,
  LineJoinType,
  TrimMode,
  MergeMode,
  StrokeDashType
} from '@phase-software/lottie-js'

/**
 * convert radius to degree
 * @param      {number} rad   The radians
 * @returns    {number} degree
 */
export const rad2deg = rad => (rad * 180) / Math.PI

/**
 * get frame number of specified time
 * @param      {number} ms time in ms
 * @param      {number} fps
 * @returns    {number} frame number
 */
export const time2frame = (ms, fps = 60) => {
  const msPerFrame = 1000 / fps
  return Math.round(ms / msPerFrame)
}

/**
 * the helper to add keyframe to animatable property
 * @param      {AnimatedProp} animatedProp  The animated property
 * @param      {number} frame          The frame
 * @param      {any} value         The value
 * @param      {any} groupKey     The value key
 * @returns    {KeyFrame} The keyframe
 */
export const addKeyFrameToProp = (animatedProp, frame, value, groupKey) => {
  let dataValue = null
  if (animatedProp.split) {
    dataValue = animatedProp[groupKey]
  } else {
    dataValue = animatedProp.values
  }
  if (frame === 0) {
    dataValue.length = 0
  }

  let kf
  if (dataValue.length) {
    if (dataValue.length === 1) {
      const firstKf = dataValue[0]
      if (!Array.isArray(firstKf.value)) {
        firstKf.value = [firstKf.value]
      }
      animatedProp.isAnimated = true
    }
    const arrayOfValue = Array.isArray(value) ? value : [value]
    kf = new KeyFrame(round3dp(frame), arrayOfValue)
  } else {
    kf = new KeyFrame(round3dp(frame), value)
    animatedProp.isAnimated = false
  }

  dataValue.push(kf)
  return kf
}

/**
 * get origin in pixel by origin and dimensions
 * @param      {object}   origin                 Origin
 * @param      {number}   origin.originX         Origin X
 * @param      {Unit}     origin.originXUnit     Origin X Unit
 * @param      {number}   origin.originY         Origin Y
 * @param      {Unit}     origin.originYUnit     Origin Y Unit
 * @param      {object}   dimensions             Dimensions
 * @param      {number}   dimensions.width       Width
 * @param      {number}   dimensions.height      Height
 * @returns    {number[]} originX and originY in pixel
 */
export const getOriginInPixel = (origin, dimensions) => {
  const { originX, originY, originXUnit, originYUnit } = origin
  const { width, height } = dimensions
  let ox = originX
  if (originXUnit === Unit.PERCENT) {
    ox = (width * originX) / 100
  }

  let oy = originY
  if (originYUnit === Unit.PERCENT) {
    oy = (height * originY) / 100
  }
  return [ox, oy]
}

export const getEasing = (keySet, easings, delta = null) => {
  let target
  let ref
  // Get target easing and ref easing
  for (const key of keySet) {
    const next = easings[key]
    if (!next.current) {
      continue
    }

    if (!target) {
      target = next
    } else if (next.current.time > target.current.time) {
      ref = target
      target = next
    } else {
      ref = next
    }
  }

  let easing = target.current.bezier
  let hold = target.current.easingType === EasingType.STEP_END
  // If only have target easing, then use it directly.
  if (!ref) {
    return {
      easing,
      hold
    }
  }

  if (ref.current.time === target.current.time && delta && delta.x === 0) {
    const tmp = ref
    ref = target
    target = tmp
    easing = target.current.bezier
    hold = target.current.easingType === EasingType.STEP_END
  }
  // If has ref easing, then split easing and get second subcurve as export easing.
  const totalTime = target.current.time - (target.last ? target.last.time : 0)
  const targetLastTime = target.last ? target.last.time : 0
  const refLastTime = ref.last ? ref.last.time : 0
  let splitTime = ref.current.time
  if (target.current.time === ref.current.time) {
    splitTime = Math.max(targetLastTime, refLastTime)
  }
  const offset = splitTime - targetLastTime
  const bezier = new Bezier([0, 0, ...easing, 1, 1])
  // Get the right part from the split curves
  const split = bezier.split(totalTime === 0 ? offset : offset / totalTime).right
  // Convert new position to coordinate as [[0, 0], [1, 1]]
  const invWidth = split.points[3].x - split.points[0].x
  const invHeight = split.points[3].y - split.points[0].y

  return {
    easing: [
      (split.points[1].x - split.points[0].x) / invWidth,
      (split.points[1].y - split.points[0].y) / invHeight,
      (split.points[2].x - split.points[0].x) / invWidth,
      (split.points[2].y - split.points[0].y) / invHeight
    ],
    hold
  }
}

/**
 * get lottie gradient type by layer type and gradient type
 * @param      {LayerType} layerType     The layer type
 * @param      {PaintType} gradientType  The gradient type
 * @returns    {GradientFillType|GradientStrokeType} The lottie gradient type
 */
export const getLayerGradientType = (layerType, gradientType) => {
  switch (layerType) {
    case LayerType.FILL:
      return getFillGradientType(gradientType)
    case LayerType.STROKE:
      return getStrokeGradientType(gradientType)
  }
}

/**
 * get lottie fill gradient type by gradient type
 * @param      {PaintType} gradientType  The gradient type
 * @returns    {GradientFillType} The lottie gradient type
 */
export const getFillGradientType = gradientType => {
  switch (gradientType) {
    case PaintType.GRADIENT_LINEAR:
      return GradientFillType.LINEAR
    case PaintType.GRADIENT_RADIAL:
      return GradientFillType.RADIAL
    // export as RADIAL
    case PaintType.GRADIENT_ANGULAR:
    case PaintType.GRADIENT_DIAMOND:
      return GradientFillType.RADIAL
  }
}

/**
 * get lottie stroke gradient type by gradient type
 * @param      {PaintType} gradientType  The gradient type
 * @returns    {GradientStrokeType} The lottie gradient type
 */
export const getStrokeGradientType = gradientType => {
  switch (gradientType) {
    case PaintType.GRADIENT_LINEAR:
      return GradientStrokeType.LINEAR
    case PaintType.GRADIENT_RADIAL:
      return GradientStrokeType.RADIAL
    // export as RADIAL
    case PaintType.GRADIENT_ANGULAR:
    case PaintType.GRADIENT_DIAMOND:
      return GradientStrokeType.RADIAL
  }
}

/**
 * get lottie gradient colors by gradient stops
 * @param      {GradientStop[]} gradientStops  The gradient stops
 * @returns    {number[]} The lottie gradient colors and position
 */
export const getGradientColors = gradientStops => {
  const pa = []
  const prgb = []
  gradientStops
    .sort((a, b) => a.position - b.position)
    .forEach(stop => {
      const [r, g, b, a] = stop.color
      prgb.push([stop.position, r, g, b])
      pa.push([stop.position, a])
    })
  return [...prgb.flat(), ...pa.flat()]
}

/**
 * get cloned gradient stops
 * @param      {GradientStop[]} gradientStops  The gradient stops
 * @returns    {GradientStop[]} The cloned gradient stops
 */
export const cloneGradientStops = gradientStops =>
  gradientStops.reduce((acc, stop) => {
    acc.push({
      color: {
        r: stop.color.r,
        g: stop.color.g,
        b: stop.color.b,
        a: stop.color.a
      },
      position: stop.position
    })
    return acc
  }, [])

/**
 * get shape type by geometry type
 * @param      {GeometryType} geometryType  The geometry type
 * @returns    {ShapeType} The lottie shape type
 */
export const getShapeType = geometryType => {
  switch (geometryType) {
    case GeometryType.LINE:
    case GeometryType.POLYGON:
      return ShapeType.PATH
    case GeometryType.ELLIPSE:
      return ShapeType.ELLIPSE
    case GeometryType.RECTANGLE:
      return ShapeType.RECTANGLE
  }
}

/**
 * get line join type by join shape type
 * @param      {JoinShape} join  The join shape
 * @returns    {LineJoinType} The lottie line join type
 */
export const getLineJoinType = join => {
  switch (join) {
    case JoinShape.MITER:
      return LineJoinType.MITER
    case JoinShape.ROUND:
      return LineJoinType.ROUND
    case JoinShape.BEVEL:
    default:
      return LineJoinType.BEVEL
  }
}

/**
 * get line cap type by cap shape type
 * @param      {CapShape} cap  The cap shape
 * @returns    {LineCapType} The lottie line cap type
 */
export const getLineCapType = cap => {
  switch (cap) {
    case CapShape.ROUND:
      return LineCapType.ROUND
    default:
      return LineCapType.BUTT
  }
}

/**
 * Gets the element type.
 * @param   {Element} node
 * @returns {ElementType}
 */
export const getElementType = node => {
  const elementType = node.get('elementType')
  switch (elementType) {
    case ElementType.SCREEN:
    case ElementType.PATH:
    case ElementType.MASK_CONTAINER:
    case ElementType.BOOLEAN_CONTAINER:
      return elementType
    case ElementType.CONTAINER: {
      if (node.isMaskGroup()) {
        return ElementType.MASK_CONTAINER
      } else if (node.isBooleanType()) {
        return ElementType.BOOLEAN_CONTAINER
      } else if (node.isNormalGroup) {
        return ElementType.NORMAL_GROUP
      } else {
        return ElementType.CONTAINER
      }
    }
  }
}

/**
 * get trim mode by trim path mode
 * @param      {TrimPathMode} mode  The trim path mode
 * @returns    {TrimMode} The lottie trim mode
 */
export const getTrimMode = mode => {
  switch (mode) {
    case TrimPathMode.SIMULTANEOUSLY:
      return TrimMode.SIMULTANEOUSLY
    case TrimPathMode.INDIVIDUALLY:
      return TrimMode.INDIVIDUALLY
  }
}

/**
 * get merge mode by boolean type
 * @param      {BooleanType} booleanType  The boolean type
 * @returns    {MergeMode} The merge mode
 */
export const getMergeMode = booleanType => {
  switch (booleanType) {
    case BooleanOperation.NONE:
      return MergeMode.NORMAL
    case BooleanOperation.UNION:
      return MergeMode.ADD
    case BooleanOperation.SUBTRACT:
      return MergeMode.SUBTRACT
    case BooleanOperation.INTERSECT:
      return MergeMode.INTERSECT
    case BooleanOperation.DIFFERENCE:
      return MergeMode.EXCLUDE
  }
}

export const getStrokeDashType = dashType => {
  switch (dashType) {
    case 'dash':
      return StrokeDashType.DASH
    case 'gap':
      return StrokeDashType.GAP
    case 'offset':
      return StrokeDashType.OFFSET
  }
}

export const getBlendMode = blendMode => {
  switch (blendMode) {
    default:
    case PhaseBlendMode.NORMAL:
      return BlendMode.NORMAL
    case PhaseBlendMode.MULTIPLY:
      return BlendMode.MULTIPLY
    case PhaseBlendMode.SCREEN:
      return BlendMode.SCREEN
    case PhaseBlendMode.OVERLAY:
      return BlendMode.OVERLAY
    case PhaseBlendMode.DARKEN:
      return BlendMode.DARKEN
    case PhaseBlendMode.LIGHTEN:
      return BlendMode.LIGHTEN
    case PhaseBlendMode.COLOR_DODGE:
      return BlendMode.COLOR_DODGE
    case PhaseBlendMode.COLOR_BURN:
      return BlendMode.COLOR_BURN
    case PhaseBlendMode.HARD_LIGHT:
      return BlendMode.HARD_LIGHT
    case PhaseBlendMode.SOFT_LIGHT:
      return BlendMode.SOFT_LIGHT
    case PhaseBlendMode.DIFFERENCE:
      return BlendMode.DIFFERENCE
    case PhaseBlendMode.EXCLUSION:
      return BlendMode.EXCLUSION
    case PhaseBlendMode.HUE:
      return BlendMode.HUE
    case PhaseBlendMode.SATURATION:
      return BlendMode.SATURATION
    case PhaseBlendMode.COLOR:
      return BlendMode.COLOR
    case PhaseBlendMode.LUMINOSITY:
      return BlendMode.LUMINOSITY
  }
}

export const groupPath = (path, drawInfo, bounds) => {
  const Command = drawInfo.pathCommands
  const pathData = []
  let index = 0
  let pathIndex = -1
  let groupIndex = 0
  let firstX = null
  let firstY = null
  let fromX = null
  let fromY = null
  const ZERO_POINT = [0, 0]

  const isZeroWidth = bounds.width < MIN_SIZE_THRESHOLD
  const isZeroHeight = bounds.height < MIN_SIZE_THRESHOLD
  for (const command of path.commands) {
    let toX = null
    let toY = null

    switch (command) {
      case Command.M:
        pathIndex++
        groupIndex = 0
        pathData[pathIndex] = {
          i: [],
          o: [],
          v: [],
          c: 0
        }
        pathData[pathIndex].i[groupIndex] = ZERO_POINT
        pathData[pathIndex].o[groupIndex] = ZERO_POINT
        firstX = path.vertices[index++]
        firstY = path.vertices[index++]
        toX = firstX
        toY = firstY
        pathData[pathIndex].v[groupIndex] = [toX, toY]
        groupIndex++
        break

      case Command.L:
        toX = path.vertices[index++]
        toY = path.vertices[index++]
        pathData[pathIndex].i[groupIndex] = ZERO_POINT
        pathData[pathIndex].o[groupIndex] = ZERO_POINT
        pathData[pathIndex].v[groupIndex] = [isZeroWidth ? firstX : toX, isZeroHeight ? firstY : toY]
        groupIndex++
        break

      // Uncomment and complete this part if needed
      // case Command.Q:
      //   ...
      //   break;

      case Command.C:
        {
          const cp0X = path.vertices[index++]
          const cp0Y = path.vertices[index++]
          const cp1X = path.vertices[index++]
          const cp1Y = path.vertices[index++]
          toX = path.vertices[index++]
          toY = path.vertices[index++]
          pathData[pathIndex].o[groupIndex - 1] = [cp0X - fromX, cp0Y - fromY]
          pathData[pathIndex].i[groupIndex] = [cp1X - toX, cp1Y - toY]
          pathData[pathIndex].o[groupIndex] = ZERO_POINT
          pathData[pathIndex].v[groupIndex] = [isZeroWidth ? firstX : toX, isZeroHeight ? firstY : toY]
          groupIndex++
        }
        break
      case Command.Z: {
        pathData[pathIndex].c = true
        const vList = pathData[pathIndex].v
        const [x1, y1] = vList[0]
        const [x2, y2] = vList[vList.length - 1]
        if (Math.abs(x1 - x2) < Number.EPSILON && Math.abs(y1 - y2) < Number.EPSILON) {
          pathData[pathIndex].o.pop()
          const i = pathData[pathIndex].i.pop()
          pathData[pathIndex].i[0] = i
          pathData[pathIndex].v.pop()
        }
        break
      }
    }

    fromX = toX
    fromY = toY
  }

  return pathData
}

// FIXME: share the code with importer
const shapeSet = new Set([ShapeType.ELLIPSE, ShapeType.GROUP, ShapeType.PATH, ShapeType.RECTANGLE, ShapeType.STAR])
export const isShapeShape = shape => shapeSet.has(shape.type)

export const getPathBaseMesh = el => {
  const meshData = el
    .get('geometry')
    .get('mesh')
    .save()
  const mesh = Mesh.fromData(meshData)

  const basePathData = []
  for (const [, v] of el.basePath) {
    basePathData.push({
      id: v.id,
      mirror: v.mirror,
      x: v.pos[0],
      y: v.pos[1]
    })
  }

  mesh.applyVerticesPosition(basePathData)

  return mesh
}

export const getElementDimensions = el => {
  let dimensions
  if (
    el.get('elementType') === ElementType.PATH &&
    [GeometryType.LINE, GeometryType.POLYGON].includes(el.get('geometryType'))
  ) {
    const mesh = getPathBaseMesh(el)
    dimensions = {
      width: mesh.bounds.width,
      height: mesh.bounds.height
    }
  } else {
    dimensions = el.dataStore.library.getComponent(el.base.dimensions)
  }
  return dimensions
}

// round to 3 decimal places
export const round3dp = v => Math.round(v * 1000) / 1000
