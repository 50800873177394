import { GrowthBook } from '@growthbook/growthbook-react'
import { createClient } from '@liveblocks/client'
import { createRoomContext } from '@liveblocks/react'

import { createApolloClient, getHeaders } from './apolloClient'
import { CommentType } from './components/shared/CommentThread/types'
import { WEB_CONFIG_DEFAULT_VALUES } from './configs/growthBookDefaultValue'
import { GetUsersByIdsDocument, UserFieldsFragment } from './generated/graphql'
import { FEATURE_KEYS } from './growthbook-feature-keys'
import { getClientKey } from './services/api'
import { getIdToken, getTokenPayload } from './services/cognito'

let apolloClient: any

const init = async () => {
  const idToken = getIdToken() as any
  const { phase_uid: userId, email } = getTokenPayload(idToken)
  const clientKey = await getClientKey()
  const growthBook = new GrowthBook({
    apiHost: 'https://cdn.growthbook.io',
    clientKey,
    enableDevMode: process.env.NODE_ENV !== 'production',
    attributes: {
      loggedIn: !!userId,
      id: userId,
      email,
      employee: email?.endsWith('phase.com')
    }
  })
  await growthBook.loadFeatures()
  const config = growthBook.getFeatureValue(FEATURE_KEYS.WEB_CONFIG, WEB_CONFIG_DEFAULT_VALUES)
  apolloClient = createApolloClient(config.api as any)

  return config
}

const client = createClient({
  authEndpoint: async (room) => {
    const config = await init()
    // TODO: [Commenting] add liveblock path to growbook
    // @ts-ignore
    const response = await fetch(`${config.api?.rest || ''}/api/liveblocks`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify({ room })
    })
    return await response.json()
  }
})

// Presence represents the properties that exist on every user in the Room
// and that will automatically be kept in sync. Accessible through the
// `user.presence` property. Must be JSON-serializable.
type Presence = {
  // cursor: { x: number, y: number } | null,
  // ...
}

// Optionally, Storage represents the shared document that persists in the
// Room, even after all users leave. Fields under Storage typically are
// LiveList, LiveMap, LiveObject instances, for which updates are
// automatically persisted and synced to all connected clients.
type Storage = {
  // author: LiveObject<{ firstName: string, lastName: string }>,
  // ...
}

// Optionally, UserMeta represents static/readonly metadata on each user, as
// provided by your own custom auth back end (if used). Useful for data that
// will not change during a session, like a user's name or avatar.
type UserMeta = {
  id?: string // Accessible through `user.id`
  name?: string
  avatar?: string
  // info?: Json,  // Accessible through `user.info`
}

// Optionally, the type of custom events broadcast and listened to in this
// room. Use a union for multiple events. Must be JSON-serializable.
type RoomEvent = {
  // type: "NOTIFICATION",
  // ...
}

// Optionally, when using Comments, ThreadMetadata represents metadata on
// each thread. Can only contain booleans, strings, and numbers.
export type ThreadMetadata = {
  type: CommentType
  resolved?: boolean
  isDeleted?: boolean
  position?: string
  usersReadStatus?: string
}

export const {
  suspense: {
    RoomProvider,
    useRoom,
    useMyPresence,
    useUpdateMyPresence,
    useSelf,
    useOthers,
    useOthersMapped,
    useOthersConnectionIds,
    useOther,
    useBroadcastEvent,
    useEventListener,
    useErrorListener,
    useStorage,
    useObject,
    useMap,
    useList,
    useBatch,
    useHistory,
    useUndo,
    useRedo,
    useCanUndo,
    useCanRedo,
    useMutation,
    useStatus,
    useLostConnectionListener,
    useThreads,
    useUser,
    useCreateThread,
    useEditThreadMetadata,
    useCreateComment,
    useEditComment,
    useDeleteComment,
    useAddReaction,
    useRemoveReaction
  }
} = createRoomContext<Presence, Storage, UserMeta, RoomEvent, ThreadMetadata>(client, {
  async resolveUsers({ userIds }) {
    const { data } = await apolloClient.query({
      query: GetUsersByIdsDocument,
      variables: { ids: userIds }
    })
    // Used only for Comments. Return a list of user information retrieved
    // from `userIds`. This info is used in comments, mentions etc.

    // const usersData = await __fetchUsersFromDB__(userIds);
    //
    // return usersData.map((userData) => ({
    //   name: userData.name,
    //   avatar: userData.avatar.src,
    // }));

    const users = data.users.map((user: UserFieldsFragment) => {
      return { ...user, name: user.username }
    })

    return users
  },
  async resolveMentionSuggestions() {
    // Used only for Comments. Return a list of userIds that match `text`.
    // These userIds are used to create a mention list when typing in the
    // composer.
    //
    // For example when you type "@jo", `text` will be `"jo"`, and
    // you should to return an array with John and Joanna's userIds:
    // ["john@example.com", "joanna@example.com"]

    // const userIds = await __fetchAllUserIdsFromDB__(roomId);
    //
    // Return all userIds if no `text`
    // if (!text) {
    //   return userIds;
    // }
    //
    // Otherwise, filter userIds for the search `text` and return
    // return userIds.filter((userId) =>
    //   userId.toLowerCase().includes(text.toLowerCase())
    // );

    return []
  }
})
