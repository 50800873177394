import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { UNSUPPORTED_FEATURE_STORAGE_KEY } from '../../constant'
import { LottieFeatureByFile, UNSUPPORTED_LOTTIE_FEATURE_MAP } from '../../constants'
import { useFileContext } from '../../providers/FileProvider'
import { Dialog, Icon, ScrollView, Text } from '../shared'
import ListItem from '../shared/List/ListItemWithContent'

const LOTTIE_IMPORT_NOTIFY = 'LOTTIE_IMPORT_NOTIFY'

const LottieUnsupportedFeatureDialog = () => {
  const { id: fileId } = useFileContext()
  const { t } = useTranslation('file', { keyPrefix: 'lottie_unsupported_feature_dialog' })

  const scrollRef = useRef<HTMLElement | null>(null)
  const [isScrollOverTop, setIsScrollOverTop] = useState(false)
  const [isScrollToBottom, setIsScrollToBottom] = useState(false)
  const [unsupportedFeatures, setUnsupportedFeatures] = useState<string[]>([])
  // current for e2e not showing dialog, if the value equal to 'skip'
  const lottieImportNotify = localStorage.getItem(LOTTIE_IMPORT_NOTIFY)

  const handleClose = useCallback(() => {
    setUnsupportedFeatures([])

    // delete the record in localStorage
    const unsupportedFeatureLottieFiles = JSON.parse(
      localStorage.getItem(UNSUPPORTED_FEATURE_STORAGE_KEY) || '{}'
    ) as LottieFeatureByFile
    if (fileId in unsupportedFeatureLottieFiles) {
      delete unsupportedFeatureLottieFiles[fileId]
    }
    localStorage.setItem(UNSUPPORTED_FEATURE_STORAGE_KEY, JSON.stringify(unsupportedFeatureLottieFiles))
  }, [fileId])

  useEffect(() => {
    if (fileId && lottieImportNotify !== 'skip') {
      const unsupportedFeatureLottieFiles = JSON.parse(
        localStorage.getItem(UNSUPPORTED_FEATURE_STORAGE_KEY) || '{}'
      ) as LottieFeatureByFile

      if (fileId in unsupportedFeatureLottieFiles) {
        const usedUnsupportedFeatures = new Set(unsupportedFeatureLottieFiles[fileId])
        const featureTextSet = new Set<string>()
        usedUnsupportedFeatures.forEach((feat: string) => {
          if (UNSUPPORTED_LOTTIE_FEATURE_MAP.has(feat)) {
            const feature = UNSUPPORTED_LOTTIE_FEATURE_MAP.get(feat)
            if (feature) {
              featureTextSet.add(feature.text)
            }
          }
        })
        setUnsupportedFeatures(Array.from(featureTextSet))
      }
    }
  }, [fileId, lottieImportNotify])

  const handleScroll = (e: Event) => {
    const target = e.target as Element
    const targetScrollTop = target?.scrollTop
    if (targetScrollTop === 0) {
      setIsScrollOverTop(false)
    } else {
      setIsScrollOverTop(true)
    }
    if (scrollRef?.current) {
      const scrollViewportPos = scrollRef.current?.scrollHeight - scrollRef.current?.clientHeight
      if (targetScrollTop === scrollViewportPos) {
        setIsScrollToBottom(true)
      } else {
        setIsScrollToBottom(false)
      }
    }
  }

  if (unsupportedFeatures.length === 0) {
    return null
  }

  return (
    <Dialog
      size="xs"
      title={t('title')}
      confirmBtnText={t('confirm_button_text')}
      onCancel={handleClose}
      onConfirm={handleClose}
      open={unsupportedFeatures.length > 0}
      className="flex flex-col max-h-full"
      rootClassName="max-h-[100vh] py-32 box-border"
      bodyClassName="flex-1 min-h-0 px-0 relative"
    >
      <Text ellipsis={false} className="font-medium pb-8 px-16">
        {t('note', { count: unsupportedFeatures.length })}
      </Text>
      {/* @ts-ignore TODO: fix after refactor of ScrollView */}
      <ScrollView onScroll={handleScroll} ref={scrollRef} noScrollbar>
        {scrollRef?.current && scrollRef?.current.scrollHeight > scrollRef?.current.clientHeight && isScrollOverTop && (
          <div className="border-t border-solid border-neutral-60 sticky top-0" />
        )}
        {unsupportedFeatures.map((feature: string, i: number) => (
          <ListItem key={i} size="s" title={t(feature)} after={<Icon name="Error" interactive={false} />} />
        ))}
        {scrollRef?.current &&
          scrollRef?.current.scrollHeight > scrollRef?.current.clientHeight &&
          !isScrollToBottom && <div className="border-t border-solid border-neutral-60 sticky bottom-0" />}
      </ScrollView>
    </Dialog>
  )
}

export default LottieUnsupportedFeatureDialog
