import { vec2, mat2d } from 'gl-matrix'
import { notNull, decomposeTransformToRotation, mat2dBasis } from './commons'
import { lineNormal } from './utils'
import { Vector2 } from './Vector2'
import { Matrix2D } from './Matrix2D'

/**
 * Represents an oriented bounding box. This type of bounding box is described by an axis-aligned
 * bounding box coupled with a transformation.
 */
export class OBB {
    /**
     * Creates an OBB
     * @param  {AABB} aabb                 - aabb to use as a basis for this OBB (stored as reference!)
     * @param  {mat2d} [transform]         - transform from AABB's space, usually object to world (if undefined use identity)
     * @param  {number} [rotation]         - rotation component of the transform (if undefined will calculate one from transform)
     */
    constructor(aabb, transform = new Matrix2D(), rotation) {
        this.aabb = aabb
        this.transform = transform
        this.rotation = notNull(rotation) ? rotation : decomposeTransformToRotation(transform)
    }

    get transform() {
        return this._transform
    }
    set transform(transform) {
        this._transform = transform
        this._inverse = mat2d.invert(new Matrix2D(), transform)
        this._computeNormals()
    }

    get x() {
        return this.aabb.x
    }

    get y() {
        return this.aabb.y
    }

    get position() {
        return this.aabb.position
    }

    get min() {
        return this.aabb.min
    }

    get max() {
        return this.aabb.max
    }

    get topLeft() {
        return this.aabb.topLeft
    }

    get bottomRight() {
        return this.aabb.bottomRight
    }

    get height() {
        return this.aabb.height
    }

    get width() {
        return this.aabb.width
    }

    get size() {
        return this.aabb.size
    }

    /**
     * If this OBB contains world space point
     * @param  {(number|Vector2)} x     x coordinate or whole point as Vector2
     * @param  {number}          [y]    y coordinate
     * @returns {boolean}               true if point is inside (or on the boundary) of this OBB
     */
    containsPoint(x, y) {
        const point = new Vector2(x, y)
        vec2.transformMat2d(point, point, this._inverse)
        return this.aabb.containsPoint(point)
    }

    // world transform (basis only)
    _computeNormals() {
        const t = mat2dBasis(mat2d.create(), this.transform)
        this.leftNormal = vec2.transformMat2d(
            new Vector2(),
            lineNormal([this.min.x, this.max.y], [this.min.x, this.min.y]),
            t
        )
        vec2.normalize(this.leftNormal, this.leftNormal)
        this.rightNormal = vec2.negate(new Vector2(), this.leftNormal)
        this.topNormal = vec2.transformMat2d(
            new Vector2(),
            lineNormal([this.min.x, this.min.y], [this.max.x, this.min.y]),
            t
        )
        vec2.normalize(this.topNormal, this.topNormal)
        this.bottomNormal = vec2.negate(new Vector2(), this.topNormal)

        this.topLeftNormal = vec2.add(new Vector2(), this.topNormal, this.leftNormal)
        vec2.normalize(this.topLeftNormal, this.topLeftNormal)
        this.topRightNormal = vec2.add(new Vector2(), this.topNormal, this.rightNormal)
        vec2.normalize(this.topRightNormal, this.topRightNormal)
        this.bottomLeftNormal = vec2.add(new Vector2(), this.bottomNormal, this.leftNormal)
        vec2.normalize(this.bottomLeftNormal, this.bottomLeftNormal)
        this.bottomRightNormal = vec2.add(new Vector2(), this.bottomNormal, this.rightNormal)
        vec2.normalize(this.bottomRightNormal, this.bottomRightNormal)
    }

    // clone() {
    //     const obb = new OBB(this.aabb)
    //     obb.rect = this.rect.map(p => new Vector2(p))
    //     return obb
    // }

    // serialize() {
    //     return { rect: this.rect.map(p => [...p]) }
    // }

    // static parse(aabb, data) {
    //     return new OBB(aabb, data.rect.map(p => new Vector2(p)))
    // }
}
