import React, { forwardRef, useContext, useEffect, useLayoutEffect } from 'react'

import { useSetAtom } from 'jotai'

import { TRANSITION_STATUS } from '@phase-software/transition-manager'
import PropTypes from 'prop-types'

import { highlightKeyframesByTimeAtom, highlightedKeyframesAtom } from '../../../atoms'
import { useTransitionManager, useTransitionManagerActions } from '../../../providers/TransitionManagerProvider'
import { useDataStore, useDataStoreActions } from '../../../providers/dataStore/DataStoreProvider'
import { useInteractionActions } from '../../../providers/dataStore/InteractionProvider'
import { ScrollView } from '../../shared'
import { MAX_TICK_WIDTH, MIN_TICK_WIDTH, SNAP_RATIO, TICKS, TRACK_INFO_WIDTH, TRACK_PADDING_X } from '../constant'
import { SizeActionContext, SizeContext } from '../contexts'
import { animateWithDuration, getMsPerPx, getSnappedTime, getTimeAtStep } from '../utils'

const getTick = (width, scale, duration) => {
  const msPx = getMsPerPx(width, scale, duration)
  for (const tick of TICKS) {
    const tickWidth = tick / msPx
    if (tickWidth > MAX_TICK_WIDTH) {
      return width * msPx
    }
    if (tickWidth >= MIN_TICK_WIDTH) {
      return tick
    }
  }
  return TICKS[TICKS.length - 1]
}

const RULER_SEPARATOR = 'l'

const Ruler = forwardRef(({ trackRef, cacheRef, playHeadRef }, ref) => {
  const { dataStore } = useDataStore()
  const { setPlayheadTime } = useDataStoreActions()
  const { scale, width, duration } = useContext(SizeContext)
  const { setWidth } = useContext(SizeActionContext)
  const { getAllKeyframeTimeList } = useInteractionActions()
  const setHighlightedKeyframes = useSetAtom(highlightedKeyframesAtom)
  const highlightKeyframesByTime = useSetAtom(highlightKeyframesByTimeAtom)
  const { playAnimation } = useTransitionManagerActions()
  const status = useTransitionManager((o) => o.status)

  const highlightKeyframes = (kfMapByTime, time) => {
    if (kfMapByTime.has(time)) {
      highlightKeyframesByTime(time)
    }
  }

  const setKeyframeHighlightHidden = () => {
    setHighlightedKeyframes(new Set())
  }

  const handleMouseDown = (e) => {
    const snappableTimeList = getAllKeyframeTimeList()
    const snapThreshold = Math.max(10, getTimeAtStep((SNAP_RATIO * duration) / scale))

    let isMousePressed = true
    const wasPlaying = status === TRANSITION_STATUS.START
    const kfSet = new Set()
    const kfMapByTime = Array.from(document.querySelectorAll('.pt-action-track-list .js-kf')).reduce((map, kf) => {
      kfSet.add(kf)
      const time = Number(kf.dataset.time)
      if (!map.has(time)) {
        map.set(time, new Set())
      }
      const kfSetByTime = map.get(time)
      kfSetByTime.add(kf)
      return map
    }, new Map())

    const step = Math.min(4, 10 * getMsPerPx(width, scale, duration))
    let time = getTimeAtStep((ref.current.scrollLeft * duration) / width / scale)
    let dir = ''
    let prevClientX = e.clientX

    let delta = 0

    const pos = e.clientX - TRACK_INFO_WIDTH - TRACK_PADDING_X + ref.current.scrollLeft
    const newTime = Math.min(Math.max(0, (pos * duration) / width / scale), duration)
    const currPlayheadTime = getTimeAtStep(dataStore.transition.currentTime)
    const animationTime = newTime - currPlayheadTime

    const cancelTransition = animateWithDuration((progress) => {
      const currentTime = currPlayheadTime + progress * animationTime
      setPlayheadTime(currentTime)
      if (progress === 1 && wasPlaying && !isMousePressed) {
        playAnimation()
      }
    }, 200)

    const timer = setInterval(() => {
      if (dir === 'left') {
        time = getTimeAtStep((ref.current.scrollLeft * duration) / width / scale)
        ref.current.scrollLeft = Math.max(ref.current.scrollLeft - step, 0)
        setPlayheadTime(time)
      } else if (dir === 'right') {
        time = Math.min(
          duration,
          getTimeAtStep(
            ((ref.current.scrollLeft + ref.current.offsetWidth - 2 * TRACK_PADDING_X) * duration) / width / scale
          )
        )
        if (time === duration) {
          ref.current.scrollLeft = ref.current.scrollWidth
        } else {
          ref.current.scrollLeft += step
        }
        setPlayheadTime(time)
      }
    })

    const handleMove = (e) => {
      cacheRef.current.panel = 'ruler'

      delta += e.clientX - prevClientX

      if (Math.abs(delta) <= 2) return

      cancelTransition()

      setKeyframeHighlightHidden(kfSet)

      const pos = e.clientX - TRACK_INFO_WIDTH - TRACK_PADDING_X + ref.current.scrollLeft

      if (ref.current.scrollLeft && pos < ref.current.scrollLeft + TRACK_PADDING_X && !e?.shiftKey) {
        dir = 'left'
      } else if (ref.current.offsetWidth < e.clientX - TRACK_INFO_WIDTH + 2 * TRACK_PADDING_X && !e?.shiftKey) {
        dir = 'right'
      } else {
        dir = ''
        let newTime = Math.min(Math.max(0, (pos * duration) / width / scale), duration)
        newTime = e.shiftKey ? getSnappedTime(newTime, snappableTimeList, snapThreshold) : getTimeAtStep(newTime)

        highlightKeyframes(kfMapByTime, newTime)
        setPlayheadTime(newTime)
      }

      delta = 0
      prevClientX = e.clientX
    }

    const handleMouseUp = () => {
      isMousePressed = false
      setKeyframeHighlightHidden(kfSet)
      window.removeEventListener('mousemove', handleMove)
      clearInterval(timer)
      if (wasPlaying) {
        playAnimation()
      }
    }

    window.addEventListener('mousemove', handleMove)
    window.addEventListener('mouseup', handleMouseUp, { once: true })
  }

  // Init width and resizeObserver
  useEffect(() => {
    const node = ref.current
    let _width = node.clientWidth - TRACK_PADDING_X * 2
    setWidth(_width)
    const resizeObserver = new ResizeObserver((entries) => {
      const newWidth = entries[0].contentRect.width - TRACK_PADDING_X * 2
      if (newWidth === _width) {
        return
      }
      _width = newWidth
      setWidth(_width)
    })
    resizeObserver.observe(node.parentNode)

    return () => {
      resizeObserver.unobserve(node.parentNode)
    }
  }, [setWidth, ref])

  // Tick rendering while width/scale/max duration changed
  useLayoutEffect(() => {
    const tick = getTick(width, scale, duration)
    const count = duration / (tick / 5)
    const stepWidth = (width / count) * scale
    const html = Array.from(new Array(Math.ceil(count) + 1))
      .map((_, i) => {
        if (i % 5 === 0) {
          return Math.round((i / 5) * (tick / 100)) / 10
        }
        return RULER_SEPARATOR
      })
      .reduce((html, val, index) => {
        const textStyle =
          val === RULER_SEPARATOR ? 'text-8 text-light-overlay-20 font-bold' : 'text-12 text-light-overlay-60'
        const style =
          index > 0
            ? `width:${stepWidth}px;`
            : `width:${stepWidth}px; margin-left: ${TRACK_PADDING_X - stepWidth / 2}px`
        return `${html}<span class='${textStyle} flex-shrink-0' style='${style}'>${val}</span>`
      }, '')
    ref.current.innerHTML = `<div class='flex flex-shrink-0 overflow-hidden' style='width: ${
      width * scale + 32
    }px'>${html}</div>`
  }, [width, scale, duration, ref])

  const handleScroll = (e) => {
    if (cacheRef.current.panel === 'ruler') {
      trackRef.current.scrollLeft = e.currentTarget.scrollLeft
    }
    playHeadRef.current?.reposition()
  }

  const handleWheel = () => {
    cacheRef.current.panel = 'ruler'
  }

  return (
    <ScrollView
      ref={ref}
      className="flex items-center text-center text-10 h-40 overflow-auto"
      onMouseDown={handleMouseDown}
      onWheel={handleWheel}
      onScroll={handleScroll}
      hideScrollbar
    />
  )
})
Ruler.displayName = 'Ruler'
Ruler.propTypes = {
  trackRef: PropTypes.object,
  cacheRef: PropTypes.object,
  playHeadRef: PropTypes.object
}
export default Ruler
