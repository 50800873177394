const DEFAULT_UPDATE_INTERVAL = 1000
const MIN_CAPTURE_FRAMES = 1000
const DEFAULT_CLAMP_VALUE = 60
const DEFAULT_CAP_LOG_VALUE = 60

const FPS_DISPLAY = `
<div style="color: white; z-index: 10;width: 150px; height: 20px; position: absolute; top:0; left: 0; right: 0; margin: auto;">
  <button id="captureStart" type="button" style="margin-right:10px">Start</button>
  <button id="captureStop" type="button" style="margin-right:10px">Stop</button>
  <input id="fps" type="text" value="0" disabled style="width:24px;text-align:center;margin-right:5px"></input>
  <label>FPS</label>
</div>
`

let _clampValue = DEFAULT_CLAMP_VALUE
let _capLogValue = DEFAULT_CAP_LOG_VALUE

let _enabled = false
let _clampFPS = false
let _capLogFPS = false
let _recordFPSLog = false
let _interval

let _fps
let _rafID
let _lastFrameTS
let _lastIntervalTS
let _intervalSamples
let _intervalTime

let _fpsDisplay
let _fpsDisplayValue
let _fpsDisplayCapStart
let _fpsDisplayCapStop

let _capture = false
let _captureFPSLog

const lastFPSLog = {}
const captureResults = {}

function showFPSDisplay() {
  if (!_fpsDisplay) {
    _fpsDisplay = document.createElement('div')
    _fpsDisplay.innerHTML = FPS_DISPLAY
    _fpsDisplayValue = _fpsDisplay.getElementsByTagName('input')[0]
    const buttons = _fpsDisplay.getElementsByTagName('button')
    _fpsDisplayCapStart = buttons[0]
    _fpsDisplayCapStop = buttons[1]
    _fpsDisplayCapStart.onclick = startCapture
    _fpsDisplayCapStop.onclick = stopCapture
  }
  document.body.appendChild(_fpsDisplay)
}

function hideFPSDisplay() {
  document.body.removeChild(_fpsDisplay)
}

function updateFPSDisplay() {
  _fpsDisplayValue.value = _fps
}

function startCapture() {
  if (_capture) {
    return
  }
  _fpsDisplayCapStart.disabled = true
  _capture = true
  _captureFPSLog = []
  console.log('Starting FPS logging...')
}

function stopCapture() {
  if (!_capture) {
    return
  }
  _capture = false
  _fpsDisplayCapStop.disabled = true
  _fpsDisplayCapStart.disabled = false
  console.log('Stopped FPS logging. Report below...')

  processCaptureLog()
}

function capture(fps) {
  if (!_capture) {
    return
  }

  if (_capLogFPS) {
    fps = fps > _capLogValue ? _capLogValue : fps
  }
  _captureFPSLog.push(fps)

  if (_captureFPSLog.length >= MIN_CAPTURE_FRAMES) {
    _fpsDisplayCapStop.disabled = false
  }
}

function processCaptureLog() {
  let minFPS = 0
  let maxFPS = 0
  let avgFPS = 0
  let p1FPS = 0
  let p5FPS = 0
  let p10FPS = 0
  let p90FPS = 0
  let p95FPS = 0
  let p99FPS = 0

  // Get log saved before sorting if enabled
  if (_recordFPSLog) lastFPSLog.data = [..._captureFPSLog]

  const num = _captureFPSLog.length
  if (_captureFPSLog.length > 0) {
    _captureFPSLog.sort((a, b) => {
      if (a < b) {
        return -1
      } else if (a === b) {
        return 0
      } else {
        return 1
      }
    })

    minFPS = _captureFPSLog[0]
    maxFPS = _captureFPSLog[num - 1]
    avgFPS = Math.trunc(_captureFPSLog.reduce((sum, x) => sum + x) / num)
    p1FPS = _captureFPSLog[Math.trunc((num / 100) * 1)]
    p5FPS = _captureFPSLog[Math.trunc((num / 100) * 5)]
    p10FPS = _captureFPSLog[Math.trunc((num / 100) * 10)]
    p90FPS = _captureFPSLog[Math.trunc((num / 100) * 90)]
    p95FPS = _captureFPSLog[Math.trunc((num / 100) * 95)]
    p99FPS = _captureFPSLog[Math.trunc((num / 100) * 99)]
  }

  captureResults.minFPS = minFPS
  captureResults.maxFPS = maxFPS
  captureResults.avgFPS = avgFPS
  captureResults.p1FPS = p1FPS
  captureResults.p5FPS = p5FPS
  captureResults.p10FPS = p10FPS
  captureResults.p90FPS = p90FPS
  captureResults.p95FPS = p95FPS
  captureResults.p99FPS = p99FPS

  console.log(`Min FPS: ${minFPS}`)
  console.log(`Max FPS: ${maxFPS}`)
  console.log(`Average FPS: ${avgFPS}`)
  console.log(`1% FPS: ${p1FPS}`)
  console.log(`5% FPS: ${p5FPS}`)
  console.log(`10% FPS: ${p10FPS}`)
  console.log(`90% FPS: ${p90FPS}`)
  console.log(`95% FPS: ${p95FPS}`)
  console.log(`99% FPS: ${p99FPS}`)
}

function raf() {
  const newTS = performance.now()
  _intervalSamples++
  const frameTime = newTS - _lastFrameTS
  _intervalTime += frameTime

  let fps = 1000 / frameTime
  fps = ~~fps

  capture(fps)

  // end of update interval
  if (newTS - _lastIntervalTS >= _interval) {
    let intervalFPS = 1000 / (_intervalTime / _intervalSamples)
    // floor
    intervalFPS = ~~intervalFPS

    const clampedFPS = fps > _clampValue ? _clampValue : fps
    const fpsToDisplay = _clampFPS ? clampedFPS : intervalFPS

    if (fpsToDisplay !== _fps) {
      _fps = fpsToDisplay
      updateFPSDisplay()
    }

    // reset interval
    _intervalTime = 0
    _intervalSamples = 0
    _lastIntervalTS = newTS
  }

  _lastFrameTS = newTS

  if (_enabled) {
    // eslint-disable-next-line
    _rafID = requestAnimationFrame(raf)
  }
}

export function fpsTrackerOptions(
  {
    interval = DEFAULT_UPDATE_INTERVAL,
    clampFPS = false,
    clampValue = DEFAULT_CLAMP_VALUE,
    capLogFPS = false,
    capLogValue = DEFAULT_CAP_LOG_VALUE,
    recordFPSLog = false
  } = {
    interval: DEFAULT_UPDATE_INTERVAL,
    clampFPS: false,
    clampValue: DEFAULT_CLAMP_VALUE,
    capLogFPS: false,
    capLogValue: DEFAULT_CAP_LOG_VALUE,
    recordFPSLog: false
  }
) {
  _interval = typeof interval === 'number' && interval > 0 ? interval : DEFAULT_UPDATE_INTERVAL
  _clampFPS = !!clampFPS
  _clampValue = typeof clampValue === 'number' && clampValue > 0 ? clampValue : DEFAULT_CLAMP_VALUE
  _capLogFPS = !!capLogFPS
  _capLogValue = typeof capLogValue === 'number' && capLogValue > 0 ? capLogValue : DEFAULT_CAP_LOG_VALUE
  _recordFPSLog = !!recordFPSLog
}

export function enableFPSTracker(options) {
  exposeToConsole()

  if (_enabled) {
    return
  }
  _enabled = true

  fpsTrackerOptions(options)

  _fps = 0
  _intervalTime = 0
  _intervalSamples = 0
  _lastFrameTS = performance.now()
  _lastIntervalTS = _lastFrameTS

  showFPSDisplay()

  // start
  _rafID = requestAnimationFrame(raf)
}

export function disableFPSTracker() {
  if (!_enabled) {
    return
  }
  _enabled = false

  hideFPSDisplay()

  if (_rafID) {
    cancelAnimationFrame(_rafID)
  }
}

function exposeToConsole() {
  // expose fps commands to console
  if (!window.__FPS__) {
    window.__FPS__ = {
      enableFPSTracker,
      disableFPSTracker,
      fpsTrackerOptions,
      startCapture,
      stopCapture,
      captureResults,
      lastFPSLog
    }
  }
}
