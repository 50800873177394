import { Vector2 as duVector2 } from '@phase-software/data-utils'
import { Rot, Transform2D, Vector2 } from '../../math'
import { MIN_SIZE, MIN_SIZE_THRESHOLD } from '../../constants'
import { fixInfiniteSkew } from '../../visual_server/Transform'
import { rotationTracker } from './utils'
import { Snapping } from './Snapping'

/** @typedef {import('@phase-software/data-store/src/Element').Element} Element */
/** @typedef {import('../../visual_server/RenderItem').RenderItem} RenderItem */
/** @typedef {import('./utils').OriginalSizeData} OriginalSizeData */
/** @typedef {import('./utils').NewSizeData} NewSizeData */
/** @typedef {import('./index').Handle} Handle */
/** @typedef {import('./index').Anchor} Anchor */
/** @typedef {import('./Snapping').Snapping} Snapping */

const oldSize = new Vector2()
const oldOrigin = new Vector2()
const oldOriginPercent = new Vector2()
const oldReferencePoint = new Vector2()
const oldContentAnchor = new Vector2()
const oldScale = new Vector2()
const oldSkew = new Vector2()
let oldRotation = 0
const oldTransform = new Transform2D()
const oldTransformWorld = new Transform2D()
const oldTransformWorldInv = new Transform2D()
const mouseStartLocal = new Vector2()
const mouseMotionLocal = new Vector2()
const initMousePos = new Vector2()

let handle = null
let sign = null
let snapping = null
let isAlignAxis = null
const sizeFlag = { w: '!0', h: '!0' }
const scaleFlag = { x: '!0', y: '!0' }
let element = null
let oldAspect = null
let freezeOriginX = false
let freezeOriginY = false
/**
 * @callback resizeCallback
 * @param {Vector2} mousePos
 * @param {boolean} keepAspect
 * @param {Anchor?} anchorOverride
 * @returns {void}
 */
/**
 * @param {Handle} _handle
 * @param {Element} _element
 * @param {SceneNode} node
 * @param {Snapping} _snapping
 * @param {Vector2} mousePos
 * @param {boolean} _freezeOriginX
 * @param {boolean} _freezeOriginY
 * @returns {resizeCallback}
 */
export function resize(_handle, _element, node, _snapping, mousePos, _freezeOriginX, _freezeOriginY) {
    handle = _handle
    snapping = _snapping
    element = _element
    freezeOriginX = _freezeOriginX
    freezeOriginY = _freezeOriginY
    sign = new Vector2(1, 1)
    oldSize.set(...element.get('size'))
    oldContentAnchor.set(...element.get('contentAnchor'))
    oldReferencePoint.set(...element.get('referencePoint'))
    if (element.isComputedGroup) {
        _assambleGroupMatrix(node, oldOrigin, oldTransform, oldTransformWorld)
    } else {
        oldOrigin.copy(node.item.transform.getPivotOffset())
        oldTransform.copy(node.item.transform.local)
        oldTransformWorld.copy(node.item.transform.world)
    }
    oldOriginPercent.copy(oldOrigin).divide(oldSize)
    _checkVector(oldOriginPercent, MIN_SIZE * 0.5, MIN_SIZE * 0.5) // if zero size, set to 0.5
    oldScale.set(...element.get('scale'))
    oldSkew.set(...element.get('skew'))
    oldRotation = element.get('rotation')
    oldAspect = oldSize.x / oldSize.y

    if (node.item.transform.local.basis_determinant() === 0) {
        const { translate, scale, skew, rotation } = node.item.transform
        const pivotOffset = node.item.transform.getPivotOffset()

        // TOFIX: parent basis determinant is 0
        oldTransformWorldInv
            .reset()
            .append(node.parent.item.transform.world.clone().affine_inverse())
            .translate_right(pivotOffset.x, pivotOffset.y)
            .scale_right(scale.x===0 ? 0 : 1/scale.x, scale.y===0 ? 0 : 1/scale.y)
            .skew_right(fixInfiniteSkew(-skew.x), fixInfiniteSkew(-skew.y))
            .rotate_right(-rotation)
            .translate_right(-translate.x, -translate.y)
    } else {
        oldTransformWorldInv.copy(oldTransformWorld).affine_inverse()
    }

    initMousePos.copy(mousePos)
    isAlignAxis = snapping.isMultipleOf90Degree(node.item.transform.world.get_rotation())

    // detect resizing from size flag
    sizeFlag.w = node.item.sizeFlag.w
    sizeFlag.h = node.item.sizeFlag.h
    scaleFlag.x = node.item.scaleFlag.x
    scaleFlag.y = node.item.scaleFlag.y

    return resizeElement
}

/**
 * @param {RenderItem} node
 * @param {Vector2} oldOrigin
 * @param {Transform2D} oldTransform
 * @param {oldTransformWorld} oldTransformWorld
 */
function _assambleGroupMatrix(node, oldOrigin, oldTransform, oldTransformWorld) {
    oldOrigin.copy(oldReferencePoint).add(oldContentAnchor)
    const { translate, rotation, scale, skew } = node.item.transform

    oldTransform.reset()
        .translate_right(translate.x, translate.y)
        .rotate_right(rotation)
        .skew_right(fixInfiniteSkew(skew.x), fixInfiniteSkew(skew.y))
        .scale_right(scale.x, scale.y)
        .translate_right(-oldOrigin.x, -oldOrigin.y)
    oldTransformWorld.copy(node.item.transform.parent).append(oldTransform)
}

/**
 * @param {Element} element
 * @param {RenderItem} node
 * @param {Vector2} initialMousePos
 * @param {import('..').SetLocalCursorStateFn} setLocalCursorState
 * @returns {(mousePos: Vector2, shift: boolean) => void}
 */
export function rotate(element, node, initialMousePos, setLocalCursorState) {
    const _worldPivotPosition = node.item.transform.worldPivot
    const _rotation = element.get('rotation')

    const tracker = rotationTracker(_worldPivotPosition, initialMousePos)

    /**
     * @param {Vector2} mousePos
     * @param {boolean} shift
     */
    return (mousePos, { shift }) => {
        const { delta } = tracker(mousePos)

        let newRot = _rotation + delta

        if (shift) {
            const delta = newRot % Rot[15]
            newRot -= delta >= (Rot[15] * 0.5) ? delta - Rot[15] : delta
        }

        element.dataStore.startTransaction()
        element.set('rotation', newRot)
        element.dataStore.endTransaction()

        setLocalCursorState('rotate')
    }
}

/**
 * @param {Vector2} mousePos
 * @param {Vector2} mouseStartLocal
 * @param {Vector2} oldSize
 * @param {Vector2} drag
 * @param {Vector2} anchor
 * @param {number} keepAspect
 * @param {number} oldAspect
 * @param {number} resizeFromCenter
 * @returns {Vector2}
 */
function _getNewSize(mousePos, mouseStartLocal, oldSize, drag, anchor, keepAspect, oldAspect, resizeFromCenter) {
    // calculate mouse local position and local movement
    const mousePointLocal = oldTransformWorldInv.xform(mousePos)
    mouseMotionLocal.copy(mousePointLocal).sub(mouseStartLocal)
    // calculate new size
    const sizeFactor = resizeFromCenter ? 2 : 1
    const sizeChange = drag.clone().sub(anchor).multiply(mouseMotionLocal).multiply(sizeFactor, sizeFactor)
    const newSize = sizeChange.clone().add(oldSize)


    // modify new size if we need to keep aspect
    if (keepAspect && oldAspect !== 0 && !Number.isNaN(oldAspect)) {
        // If anchor is centered on y-axis or sizeChange is greater in x direction
        if (anchor.y === 0.5 || (anchor.x !== 0.5 && sizeChange.x >= sizeChange.y)) {
            // adjust Height Based On Width
            newSize.y = newSize.x / oldAspect
        } else {
            // adjust Width Based On Height
            newSize.x = newSize.y * oldAspect
        }
    }
    return newSize
}

/**
 * @param {Vector2} vec
 * @param {number} defaultX
 * @param {number} defaultY
 */
function _checkVector(vec, defaultX, defaultY) {
    if (!Number.isFinite(vec.x)) {
        vec.x = defaultX
    }
    if (!Number.isFinite(vec.y)) {
        vec.y = defaultY
    }
}

// eslint-disable-next-line no-unused-vars
const resizeElement = (mousePos, keepAspect, resizeFromCenter, anchorOverride, snapToGrid = true, snapToObject = true, canResizeOverAxis = false) => {
    const shouldDoZeroOffset = !resizeFromCenter
    const resizeCenterHandleAnchor = anchorOverride && resizeFromCenter ? anchorOverride.clone().sub(handle.anchor) : anchorOverride
    const anchor = anchorOverride ? resizeCenterHandleAnchor : handle.anchor
    const drag = new Vector2(1 - anchor.x, 1 - anchor.y)

    sign.x = Math.sign(drag.x - 0.5)
    sign.y = Math.sign(drag.y - 0.5)
    mouseStartLocal.set(0, 0)
    oldTransformWorldInv.xform(initMousePos, mouseStartLocal)
    if (shouldDoZeroOffset) {
        if (sizeFlag.w === 'f0') {
            mouseStartLocal.x += oldSize.x * 0.5 * sign.x
        }
        if (sizeFlag.h === 'f0') {
            mouseStartLocal.y += oldSize.y * 0.5 * sign.y
        }
    }

    const scale = oldScale.clone()
    const skew = oldSkew.clone()
    const rotation = oldRotation
    // get selected side/vertex position
    const selectedVertexLocalPos = drag.clone().multiply(oldSize)
    const selectedVertexWorldPos = oldTransformWorld.xform(selectedVertexLocalPos)
    const isClickEdge = anchor.x === 0.5 || anchor.y === 0.5

    snapping.updateSnapMovingData(false)
    snapping.vs.selection.updateBounds()
    snapping.setResizeSelectedElementOAB(anchor)

    if (snapToGrid) {
        // snapping element size to pixel grid
        snapping.updateMoved(mousePos.clone().sub(initMousePos))
        const snapToGridPos = snapping.snapResizeElementToPixelGrid(
            mousePos,
            initMousePos,
            selectedVertexWorldPos,
            isClickEdge,
            isAlignAxis
        )
        mousePos.copy(snapToGridPos)

    }
    if (snapToObject) {
        // snapping element to element.
        // If snapToGrid is true, it won't snap to the element
        // which the position of side is not on pixel grid.
        const snapToElementPos = snapping.comparingVerticesWithResize(
            mousePos,
            initMousePos,
            selectedVertexWorldPos,
            anchor,
            snapToGrid,
            isAlignAxis,
            Snapping.ResizeTypes.RESIZE_ONE_ELEMENT,
            keepAspect
        )
        mousePos.copy(snapToElementPos)
    }

    // * New size
    const canKeepAspect =
        keepAspect
        && sizeFlag.w !== 'f0' && sizeFlag.h !== 'f0' && sizeFlag.w !== 't0' && sizeFlag.h !== 't0'  // can not keep aspect if it is any kind of zero size
        && scaleFlag.x !== '0' && scaleFlag.y !== '0' // can not keep aspect if it has zero scale
    const newSize = _getNewSize(mousePos, mouseStartLocal, oldSize, drag, anchor, canKeepAspect, oldAspect, resizeFromCenter)

    // Set to tiny size if it is smaller than threshold
    if (newSize.x < MIN_SIZE_THRESHOLD) newSize.x = MIN_SIZE
    if (newSize.y < MIN_SIZE_THRESHOLD) newSize.y = MIN_SIZE

    // Ignore size change if that side has scale 0
    if (scaleFlag.x === '0') newSize.x = oldSize.x
    if (scaleFlag.y === '0') newSize.y = oldSize.y

    if (keepAspect) {
        if (sizeFlag.w === 'f0' && sizeFlag.h === 'f0' && (scaleFlag.x !== '0' && scaleFlag.y !== '0')) {
            // Special case for resize from zero size with shift key (condition: both side is fake zero and scale is not zero)
            newSize.y = newSize.x * (scale.x / (scale.y === 0 ? 1 : scale.y))
        } else {
            // Ignore shift resize the tiny side which can lead to jump to huge value
            if (sizeFlag.w === 'f0') newSize.x = oldSize.x
            if (sizeFlag.h === 'f0') newSize.y = oldSize.y
        }
    }

    // * New reference point
    const newReferencePoint = oldReferencePoint.clone().multiply(newSize).divide(oldSize)
    _checkVector(newReferencePoint, oldReferencePoint.x, oldReferencePoint.y)

    // * New content anchor
    const newContentAnchor = oldContentAnchor.clone().multiply(newSize).divide(oldSize)
    _checkVector(newContentAnchor, oldContentAnchor.x, oldContentAnchor.y)
    if (freezeOriginX) {
        newContentAnchor.x = oldContentAnchor.x + oldReferencePoint.x + oldSize.x * 0.5 - newReferencePoint.x - MIN_SIZE * 0.5 - (newSize.x > MIN_SIZE_THRESHOLD ? MIN_SIZE * 0.5 : 0)
    }
    if (freezeOriginY) {
        newContentAnchor.y = oldContentAnchor.y + oldReferencePoint.y + oldSize.y * 0.5 - newReferencePoint.y - MIN_SIZE * 0.5 - (newSize.y > MIN_SIZE_THRESHOLD ? MIN_SIZE * 0.5 : 0)
    }

    // * New translate
    const ignoreSizeChangeForTranslate = newSize.clone()
    if (freezeOriginX) {
        ignoreSizeChangeForTranslate.x = oldSize.x
    }
    if (freezeOriginY) {
        ignoreSizeChangeForTranslate.y = oldSize.y
    }
    const anchorPointLocal = anchor.clone().multiply(oldSize)
    const newOriginLocal = oldOriginPercent.clone().multiply(ignoreSizeChangeForTranslate)
    if (shouldDoZeroOffset) {
        // if resize from zero size, offset the origin
        if (sizeFlag.w === 'f0') {
            newOriginLocal.x += oldSize.x * 0.5 * sign.x
        }
        if (sizeFlag.h === 'f0') {
            newOriginLocal.y += oldSize.y * 0.5 * sign.y
        }
        // if resize to zero size, offset the origin
        if (newSize.x < MIN_SIZE_THRESHOLD) {
            newOriginLocal.x -= MIN_SIZE * 0.5 * sign.x
        }
        if (newSize.y < MIN_SIZE_THRESHOLD) {
            newOriginLocal.y -= MIN_SIZE * 0.5 * sign.y
        }
    }
    if (freezeOriginX) {
        newOriginLocal.x -= (newSize.x > MIN_SIZE_THRESHOLD ? MIN_SIZE * 0.5 : 0)
    }
    if (freezeOriginY) {
        newOriginLocal.y -= (newSize.y > MIN_SIZE_THRESHOLD ? MIN_SIZE * 0.5 : 0)
    }
    const anchorPointWorld = oldTransform.xform(anchorPointLocal)
    const anchorToOriginLocal = anchor.clone().multiply(ignoreSizeChangeForTranslate).negate().add(newOriginLocal)
    // TOFIX: Need a better way to handle true zero Collinear resize, now it will jump when having ex rotation
    if (sizeFlag.w === 't0') anchorToOriginLocal.x = 0
    if (sizeFlag.h === 't0') anchorToOriginLocal.y = 0
    const anchorToTranslateLocal = oldTransform.basis_xform(anchorToOriginLocal)
    const newTranslateLocal = anchorPointWorld.clone().add(anchorToTranslateLocal)

    if (resizeFromCenter) {
        const resizeFactor = drag.clone().sub(anchor).divide(2, 2)
        const halfSize = newSize.clone().sub(oldSize).multiply(resizeFactor)
        newTranslateLocal.sub(halfSize)
        const translate = new Vector2(element.get('translate').x, element.get('translate').y)
        // change less than CMP_EPSILON: 0.00001 not set translate
        if (translate.equals(newTranslateLocal)) {
            newTranslateLocal.copy(translate)
        }
    }

    newSize.abs()

    element.dataStore.startTransaction()
    const changes = {
        size: new duVector2(newSize),
        scale: new duVector2(scale),
        skew: new duVector2(skew),
        rotation,
    }
    if (sizeFlag.w !== 't0') {
        changes.translateX = newTranslateLocal.x
        changes.referencePointX = newReferencePoint.x
        changes.contentAnchorX = newContentAnchor.x
    }
    if (sizeFlag.h !== 't0') {
        changes.translateY = newTranslateLocal.y
        changes.referencePointY = newReferencePoint.y
        changes.contentAnchorY = newContentAnchor.y
    }
    element.sets(changes)
    element.dataStore.endTransaction()
}
