import React, { useCallback } from 'react'

import Menu from '../Menu'
import { MenuOptionProps, MenuProps } from '../Menu/Menu.types'

type ContextMenuProps = {
  checkedOpts?: MenuProps['selectedValues']
  className?: string
  cursorPosition?: MenuProps['cursorPosition']
  offsetY?: MenuProps['offsetY']
  onClose: () => void
  onSelect: MenuProps['onSelect']
  open: MenuProps['open']
  options: MenuProps['options']
  trigger?: MenuProps['trigger']
  triggerInFocusLoop?: boolean
}

const ContextMenu = ({
  className = '',
  options,
  open,
  onSelect,
  onClose,
  checkedOpts = [],
  cursorPosition,
  trigger,
  triggerInFocusLoop = false,
  offsetY,
  ...rest
}: ContextMenuProps) => {
  const handleSelect = useCallback(
    (option: MenuOptionProps) => {
      if (option) {
        onSelect(option)
      }
      onClose()
    },
    [onClose, onSelect]
  )

  const onOpenChange = useCallback(
    (open: boolean) => {
      if (!open) onClose()
    },
    [onClose]
  )

  return (
    <Menu
      {...rest}
      type="context"
      className={`${className} context-menu`}
      onOpenChange={onOpenChange}
      open={open}
      options={options}
      onSelect={handleSelect}
      selectable={checkedOpts.length !== 0}
      showSearch={false}
      cursorPosition={cursorPosition}
      trigger={trigger}
      triggerInFocusLoop={triggerInFocusLoop}
      offsetY={offsetY}
      selectedValues={checkedOpts}
    />
  )
}

export default ContextMenu
