import { useCallback, useState } from 'react'

import { UPLOAD_SVG_ERROR_MESSAGE } from '../constant'
import useFileActions, { FileCreationMethod } from '../hooks/useFileActions'
import { useFullScreenLoadingContext } from '../providers/FullScreenLoadingProvider'
import { useWorkspaceContext } from '../providers/WorkspaceContextProvider'
import { upload } from '../providers/utils'
import { track } from '../services/heapAnalytics'
import useDotLottie from './useDotLottie'
import useHeapAnalytics from './useHeapAnalytics'

type UseImportFileProps = {
  canImportPhase: boolean
  projectId: string
  openInNewTab?: boolean
  importErrorMessage: string
}

const useImportFile = ({ canImportPhase, projectId, openInNewTab, importErrorMessage }: UseImportFileProps) => {
  const { startFullScreenLoading, stopFullScreenLoading } = useFullScreenLoadingContext()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const { createOrImportFile } = useFileActions()
  const { handleDotLottieFiles } = useDotLottie()
  const [fileType, setFileType] = useState<string>('')
  const { space, teamName } = useHeapAnalytics()
  const { workspaceData } = useWorkspaceContext()

  const handleFileUpload = async (canImportPhase: boolean): Promise<File | null> => {
    try {
      const file: File | null = await upload(canImportPhase)
      return file // Simply return the file, null if nothing was uploaded
    } catch (e) {
      console.error('Error during file upload:', e)
      return null // Return null if the upload fails
    }
  }

  interface ImportFileParams {
    projectId: string
    blob: File // This assumes 'blob' is a file, renamed from 'file' to match your request
  }

  const processImportedFile = useCallback(
    async ({ blob, projectId }: ImportFileParams): Promise<void> => {
      const file = blob
      if (!file) {
        console.error('No file to process.')
        return
      }

      try {
        startFullScreenLoading()
        setLoading(true)

        const parts = file.name.split('.')
        const ext = parts.pop() || ''
        setFileType(ext)
        const isDotLottie = ext?.toLowerCase() === 'lottie'
        if (isDotLottie) {
          await handleDotLottieFiles(file, projectId)
        } else {
          await createOrImportFile({
            projectId,
            file,
            method: FileCreationMethod.IMPORT,
            openInNewTab,
            translatedFileName: file.name
          })
        }
      } catch (e) {
        console.error(e)
        if (e instanceof Error && e?.cause === 'network') {
          setError(UPLOAD_SVG_ERROR_MESSAGE)
        } else {
          setError(importErrorMessage)
        }
        const location = projectId === workspaceData.draftProjectId ? 'drafts' : 'project'
        track('File Import Failed', {
          space,
          teamName,
          location,
          projectId,
          fileImportType: fileType,
          errorMessage: (e instanceof Error && e.message) || UPLOAD_SVG_ERROR_MESSAGE || importErrorMessage
        })
      } finally {
        stopFullScreenLoading()
        setLoading(false)
      }
    },
    [
      startFullScreenLoading,
      handleDotLottieFiles,
      createOrImportFile,
      openInNewTab,
      workspaceData.draftProjectId,
      space,
      teamName,
      fileType,
      importErrorMessage,
      stopFullScreenLoading
    ]
  )

  const handleImportFile = useCallback(async () => {
    const file = await handleFileUpload(canImportPhase)
    if (file) {
      await processImportedFile({ blob: file, projectId })
    } else {
      console.error('Failed to upload file or no file selected.')
    }
  }, [canImportPhase, projectId, processImportedFile])

  const importWithNewPhaseFile = useCallback(
    async (fileData: object, fileName: string) => {
      try {
        if (!fileData) return
        const blob = new Blob([JSON.stringify(fileData)], { type: 'text/plain' })
        const file = new File([blob], `${fileName}.phase`, { type: 'text/plain' })
        if (!file) return
        startFullScreenLoading()
        setLoading(true)
        await createOrImportFile({
          projectId,
          file,
          method: FileCreationMethod.NEW,
          openInNewTab,
          translatedFileName: fileName
        })
      } catch (e) {
        console.error(e)
        setError(importErrorMessage)
      } finally {
        stopFullScreenLoading()
        setLoading(false)
      }
    },
    [startFullScreenLoading, createOrImportFile, projectId, openInNewTab, importErrorMessage, stopFullScreenLoading]
  )

  return {
    handleImportFile,
    processImportedFile,
    loading,
    error,
    setError,
    importWithNewPhaseFile
  }
}

export default useImportFile
