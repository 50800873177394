import { CUSTOM_EVENT_NAMES } from '../../constants'
import { ErrorContext, Middleware, ResponseContext } from '../../generated/api'
import { createCustomEvent } from '../../utils/customEventUtils'
import { refreshToken } from './../cognito'

export default class ErrorMiddleware implements Middleware {
  async post(context: ResponseContext) {
    if (!context.response.ok) {
      const data = await context.response.json()

      if (process.env.NODE_ENV !== 'production') {
        const error = new Error(data?.error?.message == null ? 'Failed' : data.error.message)
        const metadata = data?.error?.details[0]?.metadata
        error.stack = metadata
        const errorMsgEvent = createCustomEvent(CUSTOM_EVENT_NAMES.DEV_ERROR_MSG, {
          detail: { message: 'API error: ' + error.message }
        })
        console.error(`[Local Environment]\nAPI error: ${error.message}\n`, error.stack)
        document.dispatchEvent(errorMsgEvent)
      }

      if (context?.response?.status === 500) {
        const redirectEvent = createCustomEvent(CUSTOM_EVENT_NAMES.REDIRECT_TO_ERROR_PAGE, {
          detail: { route: '/oops' }
        })
        document.dispatchEvent(redirectEvent)
      }
    }
  }

  async onError(context: ErrorContext): Promise<Response | void> {
    const message = (context as any)?.error?.message
    if (message) {
      // token expired
      if (message === 'Failed to fetch' && !context.response) {
        await refreshToken()
        const errorMsgEvent = createCustomEvent(CUSTOM_EVENT_NAMES.USER_ERROR_MSG, {
          detail: { message: 'common:please_try_again' }
        })
        document.dispatchEvent(errorMsgEvent)
      }
    }
    return context.response
  }
}
