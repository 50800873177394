import React, { useEffect } from 'react'
import { useLocation } from 'react-router'

import { useApolloClient } from '@apollo/client'
import { WorkspaceType } from '@phase-software/types'

import { PERMISSIONS, useAccessControl } from '../../access-control'
import {
  ProjectFieldsFragmentDoc,
  useGetTeamUsersQuery,
  useOnProjectCollaboratorsUpdatedSubscription
} from '../../generated/graphql'
import useHeapAnalytics from '../../hooks/useHeapAnalytics'
import useShareTeamProjectActions from '../../hooks/useShareTeamProjectActions'
import { useProfile } from '../../providers/ProfileProvider'
import { useProjectPermissionContext } from '../../providers/ProjectPermissionProvider'
import { useWorkspaceContext } from '../../providers/WorkspaceContextProvider'
import { track } from '../../services/heapAnalytics'
import { getWorkspaceProjectPath } from '../../utils/pathGenerators'
import { MenuOptionProps } from '../shared/Menu/MenuOption'
import { TextAreaTagItemProps } from '../shared/TextArea'
import BaseShareDialog, { BaseShareDialogProps } from './BaseShareDialog'
import { transformCollaborator, transformTeamMember } from './shareHelper'
import { AllPermissionEnum, CollaboratorPermission, ProjectPermissionEnum } from './shareTypes'

type ShareTeamProjectDialogProps = Pick<BaseShareDialogProps, 'onClose' | 'open'> & {
  projectId: string
}

const ShareTeamProjectDialog = ({ open, onClose, projectId }: ShareTeamProjectDialogProps) => {
  const client = useApolloClient()
  const { workspaceData } = useWorkspaceContext()
  const { userHasPermission } = useAccessControl()
  const { projectWithPermission, refetchPermissions } = useProjectPermissionContext()
  const location = useLocation()
  const profile = useProfile()
  const { space, teamName } = useHeapAnalytics()

  const searchParams = new URLSearchParams(location.search)
  const source = searchParams.get('source')
  const analyticLocation = projectId === workspaceData.draftProjectId ? 'drafts' : 'project'

  const {
    changeTeamProjectDefaultPermission,
    changeTeamProjectCollaboratorPermission,
    removeTeamProjectCollaborator,

    sendTeamProjectInvitation,
    resendTeamProjectInvitation,
    changeTeamProjectInvitationPermission,
    cancelTeamProjectInvitation
  } = useShareTeamProjectActions()

  const linkPathname = getWorkspaceProjectPath(WorkspaceType.TEAM, workspaceData.slug, projectId)

  const projectData = client.readFragment({
    fragment: ProjectFieldsFragmentDoc,
    fragmentName: 'projectFields',
    id: `projects:${projectId}`
  })

  const globalAccess = projectData?.permission ?? ProjectPermissionEnum.CAN_REVIEW

  const { data: collaboratorListData, loading: collaboratorListLoading } = useOnProjectCollaboratorsUpdatedSubscription(
    {
      variables: {
        projectId
      },
      onData: () => {
        refetchPermissions()
      }
    }
  )
  const { data: teamMemberListData } = useGetTeamUsersQuery({ variables: { slug: workspaceData.slug } })

  const collaboratorList = collaboratorListData?.project_members_view?.map(transformCollaborator) ?? []

  const teamMemberList: MenuOptionProps[] = teamMemberListData?.team_users.map(transformTeamMember) ?? []

  const currentUser = collaboratorList.find((collaborator) => collaborator.userId === profile.id)
  let accessType = ''
  if (currentUser && !collaboratorListLoading) {
    if (currentUser.permission === CollaboratorPermission.CAN_EDIT) {
      accessType = 'edit'
    } else if (currentUser.permission === CollaboratorPermission.CAN_REVIEW) {
      accessType = 'view'
    }
  }

  const changeDefaultPermission = async (permission: AllPermissionEnum) => {
    await changeTeamProjectDefaultPermission({
      projectId,
      permission
    })

    client.cache.updateFragment(
      {
        id: `projects:${projectId}`,
        fragment: ProjectFieldsFragmentDoc,
        fragmentName: 'projectFields'
      },
      (data) => ({ ...data, permission })
    )
  }

  const changeCollaboratorPermission = async (collaborator: MenuOptionProps, permission: CollaboratorPermission) => {
    if (collaborator.isPending) {
      return changeTeamProjectInvitationPermission({
        projectId,
        invitationId: collaborator.id,
        permission
      })
    }
    return changeTeamProjectCollaboratorPermission({
      projectId,
      memberId: collaborator.userId,
      permission
    })
  }

  const inviteCollaborators = async (
    invitedCollaborators: TextAreaTagItemProps[],
    permission: CollaboratorPermission
  ) => {
    try {
      const inviteCollaboratorRequests = invitedCollaborators.map((invitedCollaborator) =>
        sendTeamProjectInvitation({
          projectId,
          email: invitedCollaborator.value,
          permission,
          collaboratorId: invitedCollaborator.id
        })
      )
      await Promise.all(inviteCollaboratorRequests)
    } catch (error) {
      console.error(error)
    }
  }

  const removeCollaborator = async (collaborator: MenuOptionProps) => {
    try {
      removeTeamProjectCollaborator({
        projectId,
        memberId: collaborator.userId
      })
    } catch (error) {
      console.error(error)
    }
  }

  const cancelInvitation = (collaborator: MenuOptionProps) => {
    return cancelTeamProjectInvitation({
      projectId,
      invitationId: collaborator.id
    })
  }

  const resendInvitation = (collaborator: MenuOptionProps) => {
    return resendTeamProjectInvitation({
      projectId,
      invitationId: collaborator.id
    })
  }

  useEffect(() => {
    if (source === 'join' && accessType) {
      track('Project Invitation Link Opened', {
        space,
        location: analyticLocation,
        teamName,
        projectId,
        accessType
      })
    }
  }, [accessType, analyticLocation, projectId, source, space, teamName])

  return (
    <BaseShareDialog
      collaboratorList={collaboratorList}
      defaultPermission={globalAccess}
      type="project"
      isEditable={userHasPermission(PERMISSIONS.MANAGE_PROJECT_COLLABORATOR, projectWithPermission)}
      linkPathname={linkPathname}
      name={projectData?.name}
      onClose={onClose}
      onCollaboratorPermissionChange={changeCollaboratorPermission}
      onDefaultPermissionChange={changeDefaultPermission}
      onInviteCollaborators={inviteCollaborators}
      onResendInvitation={resendInvitation}
      onCancelInvitation={cancelInvitation}
      onRemoveCollaborator={removeCollaborator}
      open={open}
      teamMemberList={teamMemberList}
      projectId={projectId}
    />
  )
}

export default ShareTeamProjectDialog
