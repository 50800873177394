import React from 'react'
import { ElementType } from '@phase-software/types'
import { useEditor } from '../../../../providers/dataStore/EditorProvider'
import ContainerHeader from './ContainerHeader'
import PathElementHeader from './PathElementHeader'
import NormalHeader from './NormalHeader'

const renderContent = (elementType: ElementType) => {
  switch (elementType) {
    case ElementType.CONTAINER:
      return <ContainerHeader />
    case ElementType.PATH:
      return <PathElementHeader />
    default:
      return <NormalHeader />
  }
}

const ElementHeader = () => {
  const elementType = useEditor((o) => o.elementType)
  return renderContent(elementType)
}

export default React.memo(ElementHeader)
