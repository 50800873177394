import { throttle } from 'lodash'
import { EntityType, FrameType, PaintType, IDType } from '@phase-software/types'
import { isStr, setAdd, id } from '@phase-software/data-utils'
import { Setter } from '../Setter'
import { childListMap } from '../interaction/constants'
import { IM_UPDATE_RATE_LIMITER, PAINT_KEYS } from '../constant'


/** @typedef {import('../DataStore').DataStore} DataStore */
/** @typedef {import('../Element').Element} Element */
/** @typedef {import('./Layer').Layer} Layer */
/** @typedef {import('./Layer').LayerChanges} LayerChanges */


const PROP_TYPES = {}

const PROP_KEYS_LAYER_TO_CL = {
    visible: null
}

const PROP_KEYS_CL_TO_LAYER = []

const READ_ONLY = []

const RESET_FLAG = 1
const UNDO_CHANGES = [
    'visible'
]

const PAINT_TYPE_MAP = {
    [PaintType.SOLID]: 'SOLID',
    [PaintType.IMAGE]: 'IMAGE',
    [PaintType.GRADIENT_LINEAR]: 'GRADIENT',
    [PaintType.GRADIENT_RADIAL]: 'GRADIENT',
    [PaintType.GRADIENT_ANGULAR]: 'GRADIENT',
    [PaintType.GRADIENT_DIAMOND]: 'GRADIENT',
}


/**
 * ComputedLayers used by a ComputedStyle
 * @abstract
 */
export class ComputedLayer extends Setter {
    /**
     * @param {DataStore} dataStore
     * @param {ComputedLayerData} data
     */
    constructor(dataStore, data) {
        super(dataStore, data)

        setAdd(this.undoChanges, UNDO_CHANGES)
        setAdd(this.readOnly, READ_ONLY)

        this._layerChangesLn = this._handleLayerChanges.bind(this)
        this._subComonentChangesFn = {}
        this.bindComputedLayerChange()

        this._throttledUpdateIMLayerProps = throttle(
            this._updateIMLayerProps.bind(this),
            IM_UPDATE_RATE_LIMITER,
            { leading: true, trailing: true }
        )
    }

    _init() {
        this.type = EntityType.COMPUTED_LAYER
        this.propTypes = PROP_TYPES

        // flags / change ids, SHOULD start from 1
        this._ids = {
            layerChanges: 1
        }

        this._propKeysLayerToCL = PROP_KEYS_LAYER_TO_CL
        this._propKeysCLToLayer = PROP_KEYS_CL_TO_LAYER

        this.data.visible = true
    }

    /** @param {ComputedLayerData} data */
    load(data) {
        if (!this.data || !this.data.id) {
            super.create(IDType.PROPERTY_COMPONENT)
            this.data.type = EntityType.COMPUTED_LAYER
        }

        this._init()

        // keys from Layer
        this.data.elementId = data.elementId
        this.data.id = id(IDType.PROPERTY_COMPONENT)
        this.data.visible = data.visible
        this.data.layerId = data.layerId
        this.data.layerType = data.layerType
        this.data.trackId = data.trackId
    }

    /**
     * Clear reference ids
     */
    clearRef() {
        this.data.layerId = undefined
        this.data.trackId = undefined
    }

    get layer() {
        if (!this.data.layerId) {
            return undefined
        }
        return this.dataStore.library.getLayer(this.data.layerId)
    }

    get element() {
        return this.dataStore.getById(this.data.elementId)
    }

    getData() {
        return {
            visible: this.data.visible
        }
    }

    clone() {
        throw new Error('Not used')
    }

    /**
     * binds listeners for Layer & all its nested subcomponents change events
     */
    bindLayerChanges() {
        const layer = this.layer
        if (layer) {
            layer.on('CHANGES', this._layerChangesLn)
            this.bindSubComponentsChange(layer, Object.values(layer._subComponentIds))
        }
    }

    /**
     * unbinds listeners for Layer & all its nested subcomponents change events
     */
    unbindLayerChanges() {
        const layer = this.layer
        if (layer) {
            layer.off('CHANGES', this._layerChangesLn)
            this.unbindSubComponentsChange(layer, Object.values(layer._subComponentIds))
        }
    }

    bindSubComponentsChange(instance, subComponentNames) {
        if (!subComponentNames) {
            return
        }

        subComponentNames.forEach((propName) => {
            const subId = instance[propName]
            const sub = this.dataStore.library.getComponent(subId)
            if (!sub) {
                return
            }
            if (!this._subComonentChangesFn[subId]) {
                this._subComonentChangesFn[subId] = this._handleSubChanges.bind(this, instance, propName)
            }
            sub.on('CHANGES', this._subComonentChangesFn[subId])
            if (sub._subComponentIds && Object.values(sub._subComponentIds).length) {
                this.bindSubComponentsChange(sub, Object.values(sub._subComponentIds))
            }
        })
    }

    unbindSubComponentsChange(instance, subComponentNames) {
        if (!subComponentNames) {
            return
        }

        subComponentNames.forEach((propName) => {
            const subId = instance[propName]
            const sub = this.dataStore.library.getComponent(subId)
            if (!sub) {
                return
            }

            sub.off('CHANGES', this._subComonentChangesFn[subId])
            delete this._subComonentChangesFn[subId]
            if (sub._subComponentIds && Object.values(sub._subComponentIds).length) {
                this.unbindSubComponentsChange(sub, Object.values(sub._subComponentIds))
            }
        })
    }

    bindComputedLayerChange() {
        this.on('CHANGES', (changes, options) => {
            // propagate changes to Base/IM only when:
            // - no flags set
            // - in EDITING state
            // - not during undo/redo(should be handled by changes in UndoGroup)
            const { inUndo, inRedo } = this.dataStore.get('undo');
            if (
                options.flags ||
                (this.dataStore.get('state') !== 'EDITING' && this.dataStore.get('state') !== 'TABLING') ||
                (inUndo || inRedo)
            ) {
                return
            }

            if (this.dataStore.isDesignMode) {
                this._updateLayerProps(changes)
            } else {
                this._throttledUpdateIMLayerProps(changes)
            }
        })
    }

    sets(changes, options) {
        // Not allowed computedLayer add undo event
        super.sets(changes, { ...options, undoable: false })
    }

    /**
     * Reset ComputedLayer values to match values in bound Layer
     * NOTE: currently resets `paint.opacity` only
     */
    reset() {
        if (!this.layer) {
            return
        }

        // TODO: use full code of computeLayer from helpers in case we need to reset all
        //  properties in the future
        this.sets({
            opacity: this.layer.paint.opacity
        }, { undoable: false, flags: RESET_FLAG })
    }

    get propKeysCLToLayer() {
        return this._propKeysCLToLayer
    }

    get isNonBase() {
        return this.data.layerId === undefined && this.data.trackId !== undefined
    }

    /**
     * Handler for LAYER_CHANGES event fired by bound Layer
     * Propagate changes Layer -> ComputedLayer
     * @private
     * @param  {PropChanges} changes
     */
    _handleLayerChanges(changes) {
        // don't handle LAYER_CHANGES event that originated from CHANGES event
        //   OR if the element is currently in transition
        // TODO: Need to think how to pass flags with new Changes event
        // if (layerChanges.changesId) {
        //     return
        // }

        const allChanges = {}

        for (const [propName, change] of changes) {
            allChanges[propName] = change.after
        }

        // set `flags` (3rd parameter) to be id from LayerChanges; used in _handleChanges
        this.sets(allChanges, { flags: this.data.id })
    }

    _handleSubChanges(instance, propName, changes) {
        const allChanges = {}

        const mapName = instance._subComponentPropMap[propName]
        let mapKeyProp = {}
        let mapKeys = []

        if (mapName) {
            mapKeyProp = this._propKeysLayerToCL[mapName]
            const subId = instance[propName]
            const sub = this.dataStore.library.getComponent(subId)
            mapKeys = mapKeyProp[PAINT_TYPE_MAP[sub[mapKeyProp.__switch__]]]
        }

        for (const [propName, change] of changes) {
            if (mapKeys.includes(propName)) {
                allChanges[propName] = change.after
            }
        }

        // set `flags` (3rd parameter) to be id from LayerChanges; used in _handleChanges
        this.sets(allChanges, { flags: this.data.id })
    }

    /**
     * Propagate ComputedLayer changes to Layer
     * @private
     * @param {ChangesEvent} changes    CHANGES event object
     */
    _updateLayerProps(changes) {
        const checkFn = key => changes.has(key)

        for (let prop of this.propKeysCLToLayer) {
            let propChangeObj, keys
            // handle PropertyComponents
            if (!isStr(prop)) {
                ({ prop, keys } = prop)
                const paintId = this.dataStore.library.getLayer(this.get('layerId')).paintId
                // if keys differ by some type (`__switch__` has the key of the type)
                if (keys && keys.__switch__) {
                    if (keys.__switch__ === 'paintType') {
                        const type = this.get([keys.__switch__])
                        keys = keys[PAINT_TYPE_MAP[type]]
                    } else {
                        const type = this.get([keys.__switch__])
                        keys = keys[type]
                    }
                }
                // check if any of the keys are actually in `changes`
                if (!keys.find(checkFn)) {
                    continue
                }
                // add every change to property change object
                propChangeObj = {}
                for (const key of keys) {
                    if (changes.has(key)) {
                        propChangeObj[key] = changes.get(key).value
                    }
                }
                this.dataStore.library.setProperty(paintId, propChangeObj)
                continue
            }
            // handle objects or literals
            else if (changes.has(prop)) {
                propChangeObj = changes.get(prop).value
            }
            else {
                continue
            }
            this.dataStore.library.setLayer(this.get('layerId'), prop, propChangeObj)
        }
    }

    /**
     * set changes to IM layer property
     * @private
     * @param {ChangesEvent} changes  CHANGES event object
     */
    _updateIMLayerProps(changes) {
        const elementId = this.element.get('id')
        const meta = this.gets('layerId', 'trackId', 'paintType', 'layerType')
        const layerKey = meta.layerId || meta.trackId
        const layerProp = childListMap.get('LAYER')
        for (const [key, change] of changes) {
            // FIXME (shiny): need to use new interface to update layer props in one call
            // TODO: Need to deal with the properties in subComponent like paint
            if (layerProp.includes(key) || PAINT_KEYS.includes(key)) {
                // TODO: may should change the key to `paint` if is paintKeys
                this.dataStore.interaction.setLayer(elementId, layerKey, key, change.value, meta, FrameType.EXPLICIT)
            }
        }
    }
}

/** @typedef {import('../component/PaintComponent').BlendMode} BlendMode */
/** @typedef {import('../component/PaintComponent').PaintType} PaintType */
/** @typedef {import('../Setter').SetterData} SetterData */

/**
 * @typedef {SetterData} ComputedLayerData
 * @property {boolean} visible
 * @property {BlendMode} blendMode
 * @property {string} layerType
 * @property {PaintType} paintType
 * @property {string} layerId
 * @property {Layer} layer
 * @property {Element} element
 */
