import { useCallback } from 'react'
import { createProvider } from '../utils'

const defaultValue = null
const [Provider, useSelectState, useSetState] = createProvider('ActionSelection', defaultValue)

export const useActionSelection = useSelectState

export const useSetActionSelection = () => {
  const setState = useSetState()
  const setActionSelection = useCallback((selection) => setState(selection), [setState])
  return { setActionSelection }
}

export default Provider
