import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { TFunction } from 'i18next'
import { isEmpty } from 'lodash'

import { FrameType, PaintType } from '@phase-software/types'

import useEditorImage from '../../../hooks/useEditorImage'
import { useInspectContext } from '../../../providers/InspectProvider'
import { useSetModal } from '../../../providers/ModalProvider'
import { useInspectPanelSectionState } from '../../../providers/RightPanelProvider'
import { useDataStore, useDataStoreActions } from '../../../providers/dataStore/DataStoreProvider'
import { useInteractionActions } from '../../../providers/dataStore/InteractionProvider'
import { formatAsDegrees } from '../../../utils/formatter'
import { easingTypeNameMap } from '../../Interaction/constant'
import { IconButton } from '../../shared'
import ColorBox from '../../shared/ColorBox'
import useColorTextGenerator from '../Hooks/useColorTextGenerator'
import { useCopyKeyframesData } from '../Hooks/useCopyKeyframesData'
import useImageDownloader from '../Hooks/useImageDownloader'
import GradientInitialModal from '../Modal/GradientInitialModal'
import InspectContent from '../PropertyInspection/InspectContent'
import InspectRow from '../PropertyInspection/InspectRow'
import InteractiveInspectContent from '../PropertyInspection/InteractiveInspectContent'
import { endShapeMap, imageModeMap, joinShapeMap, layerNameMap, layerPropertyKeyMap } from '../constant'
import { commonFormatter, percentFormatter, shouldShowBezierRow } from '../utils'

type InitialInspectionRowProps = {
  elementId: string
  target: string
  propKey: string
  label: string
  paintType?: PaintType
}

type InitialInspectionProps = {
  elementId: string
  target: string
  propKey: string
  paintType: PaintType
  handleCopy: () => void
}

type LayerInspectionProps = {
  elementId: string
  target: string
  propKey: string
}

type GradientInspectionProps = {
  target: string
  keyframe: any
  elementId: string
  propKey: string
}

const formatterMap = (t: TFunction) =>
  ({
    width: commonFormatter,
    opacity: percentFormatter,
    ends: (value: number) => t(endShapeMap[value], { ns: 'file', keyPrefix: 'stroke_end_shape' }),
    join: (value: number) => t(joinShapeMap[value], { ns: 'file', keyPrefix: 'layer_blend_mode' }),
    dash: commonFormatter,
    gap: commonFormatter,
    miter: formatAsDegrees
  } as any)

const InitialInspectionRow = ({ elementId, target, propKey, label }: InitialInspectionRowProps) => {
  const { getImageUrl } = useEditorImage()
  const downloadImage = useImageDownloader()

  const MODAL_KEY = `InspectContextMenu-Initial-${target}`
  const { toggleModal } = useSetModal(MODAL_KEY)
  const { getValues } = useColorTextGenerator()
  const dataStore = useDataStore()

  const layer = useMemo(() => target.split('.')[0], [target])
  const originPaint = dataStore.transition.getPropertyValue(elementId, propKey)[propKey][layer]
  const imageUrl = getImageUrl(originPaint.imageId)
  const colorText = useMemo(
    () => getValues(originPaint.paintType, originPaint.color, originPaint.opacity),
    [originPaint.color, originPaint.opacity, originPaint.paintType, getValues]
  )
  const handleClick = () => {
    downloadImage(originPaint.imageId)
  }

  const previewColorBox = (
    <div className="rounded-lg bg-light-overlay-5">
      <ColorBox
        color={originPaint.color}
        paintType={originPaint.paintType}
        gradientStops={originPaint.gradientStops}
        imageUrl={imageUrl}
        size="m"
      />
    </div>
  )

  return (
    <InspectRow
      label={label}
      type="wide"
      value={colorText}
      leftContent={previewColorBox}
      rightContent={
        originPaint.paintType === PaintType.IMAGE &&
        originPaint.imageId && <IconButton size="l" icon="Download" onClick={handleClick} />
      }
      showDetailsButton={originPaint.paintType !== PaintType.SOLID && originPaint.paintType !== PaintType.IMAGE}
      copyDisabled={originPaint.paintType !== PaintType.SOLID}
      onClickDetails={toggleModal}
    />
  )
}

const InitialInspection = ({ elementId, target, propKey, paintType, handleCopy }: InitialInspectionProps) => {
  const { t } = useTranslation('file', { keyPrefix: 'inspect' })
  const { getComputedLayerIndex } = useDataStoreActions()
  const MODAL_KEY = `InspectContextMenu-Initial-${target}`
  const { getValues } = useColorTextGenerator()
  const dataStore = useDataStore()

  const layer = useMemo(() => target.split('.')[0], [target])
  const layerIndex = getComputedLayerIndex(elementId, propKey, target.split('.')[0]) || 0
  const originPaint = dataStore.transition.getPropertyValue(elementId, propKey)[propKey][layer]

  const titleText = useMemo(
    () => getValues(paintType, originPaint.color, originPaint.opacity),
    [originPaint.color, originPaint.opacity, paintType, getValues]
  )

  return (
    <InteractiveInspectContent
      title={`${t(layerNameMap[propKey])} ${layerIndex + 1} - ${
        paintType === PaintType.SOLID ? t('color') : titleText
      }`}
      target={target}
      handleCopy={handleCopy}
    >
      <InitialInspectionRow elementId={elementId} target={target} propKey={propKey} label={t('initial')} />
      {originPaint.paintType !== PaintType.SOLID && (
        <>
          <GradientInitialModal paint={originPaint} modalKey={MODAL_KEY} />
        </>
      )}
    </InteractiveInspectContent>
  )
}

const GradientInspection = ({ target, keyframe, elementId, propKey }: GradientInspectionProps) => {
  const { t } = useTranslation('file')
  const { getImageUrl } = useEditorImage()
  const { getTimeString } = useInspectContext()
  const MODAL_KEY = `InspectContextMenu-${target}-${keyframe.time}`
  const { toggleModal } = useSetModal(MODAL_KEY)
  const { getValues } = useColorTextGenerator()
  const dataStore = useDataStore()
  const paint = dataStore.library.getComponent(keyframe.ref)
  const imageUrl = getImageUrl(paint.imageId)
  const downloadImage = useImageDownloader()
  const { setPlayheadTime } = useDataStoreActions()

  const handleClick = () => {
    downloadImage(paint.imageId)
  }

  const previewColorBox = (
    <div className="rounded-lg bg-light-overlay-5">
      <ColorBox
        color={paint.color}
        paintType={paint.paintType}
        gradientStops={paint.gradientStops}
        imageUrl={imageUrl}
        size="m"
      />
    </div>
  )

  return (
    <>
      <InspectContent spacedDivider>
        <InspectRow
          type="wide"
          label={t('time', { keyPrefix: 'inspect' })}
          value={getTimeString(Number(keyframe.time))}
          onClick={() => setPlayheadTime(Number(keyframe.time))}
        />
        {keyframe.frameType === FrameType.INITIAL ? (
          <InitialInspectionRow elementId={elementId} target={target} propKey={propKey} label="Value" />
        ) : (
          <InspectRow
            type="wide"
            label={t('value', { keyPrefix: 'inspect' })}
            value={getValues(paint.paintType, paint.color, paint.opacity)}
            leftContent={previewColorBox}
            rightContent={
              paint.paintType === PaintType.IMAGE &&
              paint.imageId && <IconButton size="l" icon="Download" onClick={handleClick} />
            }
            showDetailsButton={paint.paintType !== PaintType.SOLID && paint.paintType !== PaintType.IMAGE}
            copyDisabled={paint.paintType !== PaintType.SOLID}
            onClickDetails={toggleModal}
          />
        )}
        {paint.paintType === PaintType.IMAGE && (
          <InspectRow
            type="wide"
            label={t('fill_mode', { keyPrefix: 'inspect' })}
            value={t(imageModeMap[paint.imageMode], {
              keyPrefix: 'image_mode'
            })}
          />
        )}
        <InspectRow
          type="wide"
          label={t('ease', { keyPrefix: 'inspect' })}
          value={t(easingTypeNameMap[keyframe.easingType as keyof typeof easingTypeNameMap], {
            keyPrefix: 'easing_type'
          })}
        />
        {shouldShowBezierRow(keyframe.easingType) && (
          <InspectRow type="wide" label={t('bezier', { keyPrefix: 'inspect' })} value={keyframe.bezier?.join(', ')} />
        )}
      </InspectContent>
      {paint.paintType !== PaintType.SOLID && (
        <>
          <GradientInitialModal paint={paint} modalKey={MODAL_KEY} />
        </>
      )}
    </>
  )
}

const LayerInspection = ({ elementId, target, propKey }: LayerInspectionProps) => {
  const { t } = useTranslation('file')
  const { getComputedLayerIndex, setPlayheadTime } = useDataStoreActions()
  const dataStore = useDataStore()
  const isPanelOpen = useInspectPanelSectionState(target)
  const { getPropertyKeyFrameGroupByTime, getKeyFrame } = useInteractionActions()
  const { getTimeString } = useInspectContext()
  const copyKeyframesData = useCopyKeyframesData()
  const layer = useMemo(() => target.split('.')[0], [target])
  const property = useMemo(() => target.split('.')[1], [target])
  const originPaint = useMemo(
    () => dataStore.transition.getPropertyValue(elementId, propKey)[propKey][layer],
    [elementId, propKey, layer, dataStore.transition]
  )

  const animationKeyFrames = useMemo(
    () => getPropertyKeyFrameGroupByTime(elementId, target),
    [elementId, getPropertyKeyFrameGroupByTime, target]
  )

  const handleCopy = () => {
    copyKeyframesData({ animationKeyFrames: animationKeyFrames, target: target, isLayer: true, originPaint })
  }

  const renderContent = (): React.ReactNode => {
    return Object.keys(animationKeyFrames)?.map((time: string, index) => {
      const keyframe = getKeyFrame(animationKeyFrames[time][0])
      if (keyframe.ref) {
        const paint = dataStore.library.getComponent(keyframe.ref)
        return (
          <>
            {index === 0 && (
              <InitialInspection
                elementId={elementId}
                target={target}
                propKey={propKey}
                paintType={paint.paintType}
                handleCopy={handleCopy}
              />
            )}
            {isPanelOpen && (
              <GradientInspection target={target} keyframe={keyframe} elementId={elementId} propKey={propKey} />
            )}
          </>
        )
      } else {
        const layerIndex = getComputedLayerIndex(elementId, propKey, target.split('.')[0]) || 0
        return (
          <>
            {index === 0 && (
              <InteractiveInspectContent
                title={`${t(layerNameMap[propKey], { keyPrefix: 'inspect' })} ${layerIndex + 1} - ${t(
                  layerPropertyKeyMap[target.split('.')[1]],
                  { keyPrefix: 'inspect' }
                )}`}
                key={time}
                spacedDivider
                target={target}
                handleCopy={handleCopy}
              >
                <InspectRow
                  label={t('inspect.initial')}
                  type="wide"
                  value={formatterMap(t)[property](originPaint[property])}
                />
              </InteractiveInspectContent>
            )}
            {isPanelOpen && (
              <InspectContent key={time} spacedDivider>
                <InspectRow
                  type="wide"
                  label={t('inspect.time')}
                  value={getTimeString(Number(keyframe.time))}
                  onClick={() => setPlayheadTime(Number(keyframe.time))}
                />
                <InspectRow
                  type="wide"
                  label={t('inspect.value')}
                  value={
                    keyframe.frameType === FrameType.INITIAL
                      ? formatterMap(t)[property](originPaint[property])
                      : formatterMap(t)[target.split('.')[1]](keyframe.value)
                  }
                />
                <InspectRow
                  type="wide"
                  label={t('inspect.ease')}
                  value={t(easingTypeNameMap[keyframe.easingType as keyof typeof easingTypeNameMap], {
                    keyPrefix: 'easing_type'
                  })}
                />
                <InspectRow type="wide" label={t('inspect.bezier')} value={keyframe?.bezier?.join(', ')} />
              </InspectContent>
            )}
          </>
        )
      }
    })
  }

  if (isEmpty(animationKeyFrames)) return null
  return <>{renderContent()}</>
}

export default React.memo(LayerInspection)
