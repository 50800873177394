import PropertyStack from '../../PropertyStack'

class MiterStack extends PropertyStack {
  constructor(elementStack, data) {
    super(elementStack, data)
    this.type = 'MITER'
    this.key = 'miter'
    this.dataKey = 'miter'
    this.animatableProperties = new Set()
  }
}

export default MiterStack
