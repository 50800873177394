import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { EffectType, TrimPathMode } from '@phase-software/types'

import { Effect } from '../../../components/Properties/PropertyEditors/EffectEditor/types'
import { EFFECT_PROPS_NAME_MAP } from '../../../constant'
import { useEditor } from '../../../providers/dataStore/EditorProvider'
import { useSelectEffect } from '../../../providers/dataStore/EffectProvider'
import { formatAsPercentage } from '../../../utils/formatter'
import InspectRow from './InspectRow'
import InteractiveInspectContent from './InteractiveInspectContent'

type MapProps = {
  [key: number]: string
}

const trimPathModeMap: MapProps = {
  [TrimPathMode.SIMULTANEOUSLY]: 'simultaneously',
  [TrimPathMode.INDIVIDUALLY]: 'individually'
}

const EffectInspection = () => {
  const { t } = useTranslation('file')
  const editor = useEditor()
  const effectList = useMemo(() => editor.effectList || [], [editor.effectList])
  const effect: Effect = useSelectEffect((o) => o[EFFECT_PROPS_NAME_MAP[effectList[0]?.type as EffectType]]) || {}

  if (effectList.length === 0) return null

  return (
    <InteractiveInspectContent title={t('inspect.trim_path')} target="static_trimPath">
      <InspectRow label={t('inspect.start')} value={formatAsPercentage(effect.start)} />
      <InspectRow label={t('inspect.end')} value={formatAsPercentage(effect.end)} />
      <InspectRow label={t('inspect.offset')} value={formatAsPercentage(effect.offset)} />
      <InspectRow label={t('inspect.mode')} value={t(trimPathModeMap[effect.mode], { keyPrefix: 'trim_path_mode' })} />
    </InteractiveInspectContent>
  )
}

export default EffectInspection
