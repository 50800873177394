import { LayerType, PaintType, ElementType, EasingType, FrameType } from '@phase-software/types'
import { LayerTypeMapLayerListKey } from '@phase-software/data-store'
import { getElementType, groupPath } from '../utils'
import {
  IRAnimation,
  IRImage,
  IRAssetType,
  IRFill,
  IRTrimEffect,
  IRStroke,
  IRNodeType,
  IRPaintType,
  IRKeyFrame,
  nodeTypeMapClass,
  IRComputedGroup,
  IRImageMask,
  IRContainer,
  IRPrecomposition,
  IRPrecompositionAsset
} from '../ir'
import { MASK_PRECOMPOSITION_SIZE } from './constants'
import { IRConverter } from './IRConverter'
import {
  downloadImage,
  isDeltaProp,
  getPropTrackKey,
  getPropAliasKey,
  getElementBaseValue,
  getIRPaintType,
  getIRBooleanType,
  getIRImageMode,
  getIRGradientType,
  getIRTrimMode,
  getContainableIRNodeType,
  getIRNodeType,
  getEffectKey,
  getNodeBaseProps,
  getDefaultLayerValue,
  getKeyFrameValueParser
} from './phase-helper'

export class PhaseConverter extends IRConverter {
  constructor({ fps, speed, start, end } = {}) {
    super()
    this.speed = speed
    this.downloadList = []
    this.imageAssets = new Map()
    this.animation = new IRAnimation({
      frameRate: fps,
      inTime: start / speed,
      outTime: end / speed
    })
    this.sceneTree = this.animation.sceneTree
    this.dataStore = null
  }

  async toIR(dataStore) {
    this.dataStore = dataStore

    this.parseWorkspace(dataStore.workspaceList[0])

    await Promise.all(this.downloadList).then(imageList => {
      imageList.forEach(image => {
        if (image) {
          this.animation.addAsset(image)
        }
      })
    })

    return this.animation.toJSON()
  }

  parseWorkspace(workspace) {
    this.parseScreen(workspace.children[0])
  }

  parseScreen(screen) {
    this.animation.width = screen.get('width')
    this.animation.height = screen.get('height')

    const container = screen

    const children = container.children.slice().reverse()
    children.forEach(child => {
      // FIXME: pass this.sceneTree to the parseElement
      this.parseElement(child, null)
    })

    this.parseFill(container, null)
  }

  parseElement(element, parentNode, precompositionAsset) {
    const elementType = getElementType(element)
    switch (elementType) {
      case ElementType.PATH:
        return this.parsePathElement(element, parentNode, precompositionAsset)
      case ElementType.MASK_CONTAINER:
        return this.parseMaskContainerElement(element, precompositionAsset)
      case ElementType.BOOLEAN_CONTAINER:
        return this.parseContainerElement(element, parentNode, IRNodeType.BOOLEAN, precompositionAsset)
      case ElementType.CONTAINER: {
        // in here only check the X, because the X & Y are synced
        const isOverflowHidden = element.get('overflowX')
        if (isOverflowHidden) {
          return this.parseOverflowHidden(element, precompositionAsset)
        }
        return this.parseContainerElement(element, parentNode, IRNodeType.CONTAINER, precompositionAsset)
      }
      case ElementType.NORMAL_GROUP:
        return this.parseContainerElement(element, parentNode, IRNodeType.COMPUTED_GROUP, precompositionAsset)
    }
  }

  parseChildren(container, children, parentNode, precompositionAsset) {
    let targetNode = parentNode
    let shouldCreateNewNode
    let parentId
    if (precompositionAsset) {
      parentId = parentNode.id
    } else {
      const parent = this.dataStore.getParentOf(container)
      parentId = container.isScreen || parent.isScreen ? null : parent.get('id')
    }

    children.forEach((element, index, arr) => {
      shouldCreateNewNode = this.parseElement(element, targetNode, precompositionAsset)
      if (shouldCreateNewNode && index !== arr.length - 1) {
        let irNodeType = getIRNodeType(container)
        // not create the MASK layer for the child
        if (irNodeType === IRNodeType.MASK) {
          irNodeType = IRNodeType.COMPUTED_GROUP
        }
        const IRClass = nodeTypeMapClass[irNodeType]
        targetNode = new IRClass({
          id: container.get('id'),
          name: container.get('name'),
          visible: !container.isHidden(),
          autoOrient: container.get('autoOrient'),
          // use default value for new created Node
          ...(precompositionAsset ? {} : getNodeBaseProps(container, irNodeType)),
          type: irNodeType
        })

        // new created Node should not apply the keyframes
        if (!precompositionAsset) {
          this.parseNodeKeyFrames(targetNode)
        }

        if (precompositionAsset instanceof IRPrecompositionAsset) {
          precompositionAsset.appendChild(targetNode, parentId)
        } else {
          this.sceneTree.appendNode(targetNode, parentId)
        }
        if (parentNode.maskParent) {
          this.sceneTree.mask(parentNode.maskParent, targetNode)
        }
      }
    })
    return shouldCreateNewNode || parentNode !== targetNode
  }

  createPathNode(element) {
    const irNodeType = getIRNodeType(element)
    const IRClass = nodeTypeMapClass[irNodeType]
    const node = new IRClass({
      id: element.get('id'),
      name: element.get('name'),
      visible: !element.isHidden(),
      autoOrient: element.get('autoOrient'),
      ...getNodeBaseProps(element, irNodeType),
      type: irNodeType
    })

    this.parseNodeKeyFrames(node)
    return node
  }

  parsePathElement(path, parentNode, precompositionAsset) {
    let parentId
    const pathNode = this.createPathNode(path)
    const autoOrient = pathNode.autoOrient && pathNode.motionPath.keyFrames.length !== 0
    if (autoOrient) {
      this.createParentTransformNodes(path, precompositionAsset)
      const parent = this.dataStore.getParentOf(path)
      parentId = parent.isScreen ? null : parent.get('id')
    }

    if (parentNode && !autoOrient) {
      parentNode.appendChild(pathNode)
    } else if (precompositionAsset) {
      precompositionAsset.appendChild(pathNode, parentId)
    } else {
      this.sceneTree.appendNode(pathNode, parentId)
    }

    const shouldCreateNewNode = this.parseFill(path, pathNode, precompositionAsset)
    this.parseStroke(path, pathNode)
    this.parseEffect(path, pathNode)

    return shouldCreateNewNode
  }

  parseOverflowHidden(container, precompositionAsset) {
    this.createParentTransformNodes(container, precompositionAsset)
    const parent = this.dataStore.getParentOf(container)
    const parentId = container.isScreen || parent.isScreen ? null : parent.get('id')
    const { width, height } = container.getBaseValue('dimensions')

    // create precomposition assets
    const newPrecompositionAsset = new IRPrecompositionAsset({
      id: container.get('id'),
      type: IRAssetType.PRECOMPOSITION
    })
    this.animation.addAsset(newPrecompositionAsset)

    this.parseChildren(container, container.children.slice().reverse(), newPrecompositionAsset, newPrecompositionAsset)

    const containerNode = new IRPrecomposition({
      id: container.get('id'),
      name: container.get('name'),
      visible: !container.isHidden(),
      autoOrient: container.get('autoOrient'),
      ...getNodeBaseProps(container, IRNodeType.PRECOMPOSITION),
      type: IRNodeType.PRECOMPOSITION,
      width,
      height,
      // FIXME: better naming or refactor it
      static: false,
      refId: newPrecompositionAsset.id
    })
    this.parseNodeKeyFrames(containerNode)

    if (precompositionAsset) {
      precompositionAsset.appendChild(containerNode, parentId)
    } else {
      this.sceneTree.appendNode(containerNode, parentId)
    }

    this.parseStroke(container, containerNode)
    this.parseFill(container, containerNode)
    this.parseEffect(container, containerNode)

    return true
  }

  parseContainerElement(container, parentNode, irNodeType, precompositionAsset) {
    const parent = this.dataStore.getParentOf(container)
    const parentId = container.isScreen || parent.isScreen ? null : parent.get('id')
    const IRClass = nodeTypeMapClass[irNodeType]
    const containerNode = new IRClass({
      id: container.get('id'),
      name: container.get('name'),
      visible: !container.isHidden(),
      autoOrient: container.get('autoOrient'),
      ...getNodeBaseProps(container, irNodeType),
      type: irNodeType
    })
    this.parseNodeKeyFrames(containerNode)

    if (parentNode) {
      parentNode.appendChild(containerNode)
    } else {
      this.sceneTree.appendNode(containerNode, parentId)
    }

    const shouldCreateNewNode = this.parseChildren(
      container,
      container.children.slice().reverse(),
      containerNode,
      precompositionAsset
    )

    this.parseEffect(container, containerNode)

    if (irNodeType === IRNodeType.BOOLEAN) {
      this.parseBooleanType(container, containerNode)
    }

    let targetNode = containerNode
    if (!parentNode && this.sceneTree.nodeList[this.sceneTree.nodeList.length - 1] !== containerNode) {
      const parent = this.dataStore.getParentOf(container)
      const parentId = parent.isScreen ? null : parent.get('id')
      targetNode = new IRClass({
        id: container.get('id'),
        name: container.get('name'),
        visible: !container.isHidden(),
        autoOrient: container.get('autoOrient'),
        ...getNodeBaseProps(container, irNodeType),
        type: irNodeType
      })
      this.parseNodeKeyFrames(targetNode)
      this.sceneTree.appendNode(targetNode, parentId)
    }
    this.parseStroke(container, targetNode)
    const hasImage = this.parseFill(container, targetNode)
    return shouldCreateNewNode || hasImage
  }

  parseMaskContainerElement(maskContainer, precompositionAsset) {
    // create null layers for current mask container
    this.createParentTransformNodes(maskContainer, precompositionAsset)

    // get parent & parentId
    const parent = this.dataStore.getParentOf(maskContainer)
    const parentId = maskContainer.isScreen || parent.isScreen ? null : parent.get('id')

    const [mask, ...masked] = maskContainer.children.slice().reverse()

    const precompList = [
      { key: 'mask', children: [mask] },
      { key: 'masked', children: masked }
    ]

    const [maskNode, maskedNode] = precompList.map(({ key, children }) => {
      // create precomposition assets for mask element
      const assetId = `${maskContainer.get('id')}-${key}`

      // create asset
      const newPrecompositionAsset = new IRPrecompositionAsset({
        id: assetId,
        type: IRAssetType.PRECOMPOSITION
      })
      this.animation.addAsset(newPrecompositionAsset)

      const centerNode = new IRContainer({
        id: assetId,
        name: 'center',
        visible: true,
        autoOrient: false,
        originX: -MASK_PRECOMPOSITION_SIZE / 2,
        originY: -MASK_PRECOMPOSITION_SIZE / 2,
        type: IRNodeType.CONTAINER
      })

      const targetNode = new IRComputedGroup({
        id: maskContainer.get('id'),
        name: maskContainer.get('name'),
        visible: !maskContainer.isHidden(),
        autoOrient: maskContainer.get('autoOrient'),
        ...getNodeBaseProps(maskContainer, IRNodeType.COMPUTED_GROUP),
        type: IRNodeType.COMPUTED_GROUP
      })

      this.parseNodeKeyFrames(targetNode)
      newPrecompositionAsset.appendChild(centerNode, parentId)
      newPrecompositionAsset.appendChild(targetNode, assetId)

      this.parseChildren(maskContainer, children, targetNode, newPrecompositionAsset)

      const node = new IRPrecomposition({
        id: maskContainer.get('id'),
        name: maskContainer.get('name'),
        visible: !maskContainer.isHidden(),
        autoOrient: maskContainer.get('autoOrient'),
        type: IRNodeType.PRECOMPOSITION,
        width: MASK_PRECOMPOSITION_SIZE,
        height: MASK_PRECOMPOSITION_SIZE,
        static: true,
        refId: assetId
      })

      if (precompositionAsset) {
        precompositionAsset.appendChild(node, parentId)
      } else {
        this.sceneTree.appendNode(node, parentId)
      }
      return node
    })

    this.sceneTree.mask(maskNode, maskedNode)

    return true
  }

  getAnimatedLayerMetaList(element, layerType) {
    const elementId = element.get('id')
    const elementTrackId = this.dataStore.interaction.getElementTrackIdByElementId(elementId)
    const elementTrack = this.dataStore.interaction.getElementTrack(elementTrackId)

    const listKey = LayerTypeMapLayerListKey[layerType]
    const layerMetaList = []
    if (elementTrack) {
      elementTrack[listKey].forEach(trackId => {
        layerMetaList.push({ type: 'track', id: trackId })
      })
    }

    const layerComponentId = element.base[listKey][0]
    const layerComponent = this.dataStore.library.getComponent(layerComponentId)
    layerComponent.layers
      .slice()
      .reverse()
      .map(layerId => {
        layerMetaList.push({ type: 'layer', id: layerId })
      })

    return layerMetaList
  }

  getLayerBaseProps(layer, IRClass) {
    return IRClass.props.reduce((obj, propKey) => {
      switch (propKey) {
        case 'paint':
          obj[propKey] = this.parsePaint(layer.paint)
          break
        case 'blendMode':
        case 'opacity':
          obj[propKey] = layer.paint[propKey]
          break
        default:
          obj[propKey] = layer[propKey]
          break
      }
      return obj
    }, {})
  }

  createIRLayer(elementId, layerTrack, layer, IRClass) {
    let irLayer
    const basePaintType = getIRPaintType(layer.paint.paintType)
    if (layerTrack) {
      const paintTrackKey = `${layerTrack.key}.paint`
      const elementTrackMap = this.dataStore.interaction.getPropertyTrackMap(layerTrack.elementTrackId)
      const paintTrackId = elementTrackMap.get(paintTrackKey)
      const paintTrack = this.dataStore.interaction.getPropertyTrack(paintTrackId)
      if (paintTrack) {
        const firstNonInitKeyFrame = paintTrack.keyFrameList
          .map(keyframeId => this.dataStore.interaction.getKeyFrame(keyframeId))
          .find(keyFrame => keyFrame.frameType !== FrameType.INITIAL)

        if (firstNonInitKeyFrame) {
          const kfPaint = this.dataStore.library.getComponent(firstNonInitKeyFrame.ref)
          const kfPaintType = getIRPaintType(kfPaint.paintType)

          if (kfPaintType !== basePaintType) {
            irLayer = new IRClass({
              type: kfPaintType,
              ...this.getLayerBaseProps({ ...layer, paint: kfPaint }, IRClass)
            })
          }
        }
      }
    }

    if (!irLayer) {
      irLayer = new IRClass({
        type: basePaintType,
        ...this.getLayerBaseProps(layer, IRClass)
      })
    }

    if (layerTrack) {
      this.parseLayerKeyFrame(elementId, layerTrack, irLayer)
    }

    return irLayer
  }

  parseFill(element, node, precompositionAsset) {
    const elementId = element.get('id')
    const parent = this.dataStore.getParentOf(element)
    const parentId = element.isScreen || parent.isScreen ? null : parent.get('id')
    let targetNode = node

    const fillMetaList = this.getAnimatedLayerMetaList(element, LayerType.FILL)

    fillMetaList.forEach(({ type, id }) => {
      // create the targetNode if create new node is needed
      if (!targetNode) {
        const irNodeType = getContainableIRNodeType(element)
        const IRClass = nodeTypeMapClass[irNodeType]
        targetNode = new IRClass({
          id: element.get('id'),
          name: element.get('name'),
          visible: !element.isHidden(),
          autoOrient: element.get('autoOrient'),
          ...getNodeBaseProps(element, irNodeType),
          type: irNodeType
        })
        this.parseNodeKeyFrames(targetNode)
        if (precompositionAsset) {
          precompositionAsset.appendChild(targetNode, parentId)
        } else {
          this.sceneTree.appendNode(targetNode, parentId)
        }
      }

      const fill =
        type === 'layer'
          ? this.dataStore.library.getLayer(id)
          : getDefaultLayerValue(this.dataStore.transition.getLayerTrackInitValue(elementId, id))

      const paint = fill.paint
      const layerTrack = this.dataStore.interaction.getPropertyTrackByElementIdAndPropKey(elementId, id)
      const irFill = this.createIRLayer(elementId, layerTrack, fill, IRFill)

      switch (irFill.type) {
        case IRPaintType.IMAGE: {
          this.createParentTransformNodes(element, precompositionAsset)

          // create matte parent
          const matteNode = new IRImageMask({
            id: element.get('id'),
            name: element.get('name'),
            visible: !element.isHidden(),
            autoOrient: element.get('autoOrient'),
            ...getNodeBaseProps(element, IRNodeType.IMAGE_MASK),
            computed: !!element.isComputedGroup || !!element.canMorph,
            type: IRNodeType.IMAGE_MASK
          })
          this.parseNodeKeyFrames(matteNode)

          const pathNode = this.createPathNode(element)

          const fill = new IRFill({
            type: IRPaintType.SOLID,
            blendMode: paint.blendMode,
            opacity: paint.opacity,
            paint: { color: [0, 0, 0] }
          })

          if (layerTrack) {
            const elementTrackMap = this.dataStore.interaction.getPropertyTrackMap(layerTrack.elementTrackId)
            const opacityPropKey = `${layerTrack.key}.opacity`
            const opacityTrackId = elementTrackMap.get(opacityPropKey)
            const opacityTrack = this.dataStore.interaction.getPropertyTrack(opacityTrackId)
            if (opacityTrack) {
              opacityTrack.keyFrameList.map(keyframeId => {
                const keyFrame = this.dataStore.interaction.getKeyFrame(keyframeId)
                const irKeyFrame = this.createIRKeyFrame(keyFrame, keyFrame.value)
                fill.opacity.addKeyFrame(irKeyFrame)
              })
            }
          }

          pathNode.addFill(fill)

          matteNode.appendChild(pathNode)

          const matteParent = this.dataStore.getParentOf(element)
          const matteParentId = matteParent ? matteParent.get('id') : null
          // this.sceneTree.appendNode(matteNode, matteParentId)
          if (precompositionAsset) {
            precompositionAsset.appendChild(matteNode, matteParentId)
          } else {
            this.sceneTree.appendNode(matteNode, matteParentId)
          }

          // image layer
          const imageNode = new IRImage({
            id: element.get('id'),
            name: element.get('name'),
            visible: !element.isHidden(),
            autoOrient: element.get('autoOrient'),
            ...getNodeBaseProps(element, IRNodeType.IMAGE),
            type: IRNodeType.IMAGE
          })
          this.parseNodeKeyFrames(imageNode)

          if (element.canMorph) {
            this.parsePathMorphing(imageNode)
          }
          if (precompositionAsset) {
            precompositionAsset.appendChild(imageNode, parentId)
          } else {
            this.sceneTree.appendNode(imageNode, parentId)
          }
          imageNode.addFill(irFill)
          if (precompositionAsset) {
            precompositionAsset.mask(matteNode, imageNode)
          } else {
            this.sceneTree.mask(matteNode, imageNode)
          }

          // reset
          targetNode = null
          break
        }
        case IRPaintType.SOLID:
        case IRPaintType.GRADIENT: {
          targetNode.addFill(irFill)
          break
        }
      }
    })
    return targetNode !== node
  }

  parsePathMorphing(node) {
    const propTrack = this.dataStore.interaction.getPropertyTrackByElementIdAndPropKey(node.id, 'pathMorphing')

    if (propTrack) {
      const elementId = node.id
      const element = this.dataStore.getElement(elementId)
      const currentAnimationTime = this.dataStore.transition.time

      const basePosition = [0, 0]
      propTrack.keyFrameList.map(keyframeId => {
        const keyFrame = this.dataStore.interaction.getKeyFrame(keyframeId)

        const pathMorphingAnimationData = this.dataStore.transition.getPropertyValueByTime(
          elementId,
          'pathMorphing',
          keyFrame.time
        )

        // mutation of element
        element.updateVerticesPosition(pathMorphingAnimationData)
        const currentMesh = element.get('geometry').get('mesh')
        const path = this.dataStore.drawInfo.getMeshOutlinesForExport(currentMesh, basePosition)
        const bounds = currentMesh.bounds
        const verticesData = groupPath(path, this.dataStore.drawInfo, bounds)

        // ImageNode won't have vertices
        if (node.vertices) {
          const irKeyFrame = this.createIRKeyFrame(keyFrame, verticesData)
          node.vertices.addKeyFrame(irKeyFrame)
        } else {
          const contentAnchorData = this.dataStore.transition.getPropertyValueByTime(
            elementId,
            'contentAnchor',
            keyFrame.time
          )

          node.width.addKeyFrame(this.createIRKeyFrame(keyFrame, bounds.width))
          node.height.addKeyFrame(this.createIRKeyFrame(keyFrame, bounds.height))
          node.originX.addKeyFrame(
            this.createIRKeyFrame(keyFrame, contentAnchorData.contentAnchorX - bounds.position.x)
          )
          node.originY.addKeyFrame(
            this.createIRKeyFrame(keyFrame, contentAnchorData.contentAnchorY - bounds.position.y)
          )
        }
      })

      // restore values
      if (propTrack.keyFrameList.length !== 0) {
        if (this.dataStore.isDesignMode) {
          // Reset path with the base path data
          const basePathData = []
          for (const [, v] of element.basePath) {
            basePathData.push({
              id: v.id,
              mirror: v.mirror,
              x: v.pos[0],
              y: v.pos[1]
            })
          }
          element.updateVerticesPosition(basePathData)
        } else {
          // Reset path with current animation time
          const pathMorphingAnimationData = this.dataStore.transition.getPropertyValueByTime(
            elementId,
            'pathMorphing',
            currentAnimationTime
          )
          element.updateVerticesPosition(pathMorphingAnimationData)
        }
      }
    }
  }

  createIRKeyFrame(keyFrame, value = undefined, inTangent = undefined, outTangent = undefined) {
    return new IRKeyFrame({
      value: value === undefined ? keyFrame.value : value,
      time: keyFrame.time / this.speed,
      easing: keyFrame.bezier,
      step: keyFrame.easingType === EasingType.STEP_END,
      inTangent,
      outTangent
    })
  }

  parseKeyFrameList(node, propTrack, key, baseValue, isDeltaProp = false) {
    propTrack.keyFrameList.map((keyframeId, index) => {
      const aliasKey = getPropAliasKey(key)
      const keyFrame = this.dataStore.interaction.getKeyFrame(keyframeId)
      const parser = getKeyFrameValueParser(aliasKey)

      let value
      let inTangent
      let outTangent
      if (keyFrame.frameType === FrameType.INITIAL) {
        value = baseValue
      } else {
        if (aliasKey === 'contentAnchorX' || aliasKey === 'contentAnchorY') {
          // the contentAnchorX and contentAnchorY are stored in single keyframe of contentAnchor track
          value = parser(keyFrame.value[aliasKey])
        } else {
          value = parser(keyFrame.value)
        }

        if (aliasKey === 'motionPath') {
          // insert keyframe at 0s if the first keyframe has non-zero value of inTangent
          if (index === 0 && keyFrame.value.in.some(v => v !== 0)) {
            const irKeyFrame = this.createIRKeyFrame(
              {
                time: 0,
                bezier: [0.25, 0.25, 0.75, 0.75],
                easingType: EasingType.LINEAR
              },
              baseValue,
              [0, 0],
              [0, 0]
            )
            node[key].addKeyFrame(irKeyFrame)
          }

          value = value.map((val, idx) => val + baseValue[idx])
          inTangent = keyFrame.value.in
          outTangent = keyFrame.value.out
        } else if (isDeltaProp) {
          value += baseValue
        }
      }

      const irKeyFrame = this.createIRKeyFrame(keyFrame, value, inTangent, outTangent)
      node[key].addKeyFrame(irKeyFrame)
    })
  }

  parseNodeKeyFrames(node) {
    const elementId = node.id
    const element = this.dataStore.getElement(elementId)
    node.animatableKeys.forEach(key => {
      if (key === 'vertices') {
        this.parsePathMorphing(node)
        return
      }

      const trackKey = getPropTrackKey(key)
      const propTrack = this.dataStore.interaction.getPropertyTrackByElementIdAndPropKey(node.id, trackKey)
      if (!propTrack) {
        return
      }

      if (key === 'motionPath') {
        const translateX = getElementBaseValue(element, 'translateX')
        const translateY = getElementBaseValue(element, 'translateY')
        const baseValue = [translateX, translateY]
        this.parseKeyFrameList(node, propTrack, key, baseValue, isDeltaProp(key))
        return
      }

      const aliasKey = getPropAliasKey(key)
      const baseValue = getElementBaseValue(element, aliasKey)
      this.parseKeyFrameList(node, propTrack, key, baseValue, isDeltaProp(key))
    })
  }

  parseBooleanType(element, node) {
    node.booleanType.value = getIRBooleanType(element.get('booleanType'))
  }

  parseStroke(element, node) {
    const elementId = element.get('id')
    const strokeMetaList = this.getAnimatedLayerMetaList(element, LayerType.STROKE)

    strokeMetaList.forEach(({ type, id }) => {
      const stroke =
        type === 'layer'
          ? this.dataStore.library.getLayer(id)
          : getDefaultLayerValue(this.dataStore.transition.getLayerTrackInitValue(elementId, id))

      const layerTrack = this.dataStore.interaction.getPropertyTrackByElementIdAndPropKey(elementId, id)
      const irStroke = this.createIRLayer(elementId, layerTrack, stroke, IRStroke)

      node.addStroke(irStroke)
    })
  }

  parsePaint(paint) {
    switch (paint.paintType) {
      case PaintType.SOLID: {
        return {
          color: [...paint.color]
        }
      }
      case PaintType.IMAGE: {
        const image = this.dataStore.images.getImage(paint.imageId)
        const downloadRequest = downloadImage(paint.imageId, image?.src)
        this.downloadList.push(downloadRequest)
        return {
          imageId: paint.imageId,
          imageMode: getIRImageMode(paint.imageMode)
        }
      }
      case PaintType.GRADIENT_LINEAR:
      case PaintType.GRADIENT_RADIAL:
      case PaintType.GRADIENT_ANGULAR:
      case PaintType.GRADIENT_DIAMOND: {
        return {
          gradientType: getIRGradientType(paint.paintType),
          gradientStops: paint.gradientStops.map(gradientStop => ({
            color: [...gradientStop.color],
            position: gradientStop.position
          })),
          gradientTransform: [...paint.gradientTransform]
        }
      }
    }
  }

  parseInitLayerKeyFrame(paintType, initialValue) {
    switch (paintType) {
      case PaintType.SOLID: {
        return {
          color: initialValue
        }
      }
      case PaintType.IMAGE: {
        const image = this.dataStore.images.getImage(initialValue.imageId)
        const downloadRequest = downloadImage(initialValue.imageId, image?.src)
        this.downloadList.push(downloadRequest)
        return {
          imageId: initialValue.imageId,
          imageMode: getIRImageMode(initialValue.imageMode)
        }
      }
      case PaintType.GRADIENT_LINEAR:
      case PaintType.GRADIENT_RADIAL:
      case PaintType.GRADIENT_ANGULAR:
      case PaintType.GRADIENT_DIAMOND: {
        return {
          ...initialValue,
          gradientType: getIRGradientType(paintType)
        }
      }
    }
  }

  parseLayerKeyFrame(elementId, layerTrack, irLayer) {
    const elementTrackMap = this.dataStore.interaction.getPropertyTrackMap(layerTrack.elementTrackId)
    irLayer.propKeys.forEach(propKey => {
      const subPropKey = `${layerTrack.key}.${propKey}`
      const subTrackId = elementTrackMap.get(subPropKey)
      const subTrack = this.dataStore.interaction.getPropertyTrack(subTrackId)
      if (subTrack) {
        subTrack.keyFrameList.map(keyframeId => {
          const keyFrame = this.dataStore.interaction.getKeyFrame(keyframeId)

          let value = keyFrame.value
          if (propKey === 'paint') {
            const paint = this.dataStore.library.getComponent(keyFrame.ref)
            if (keyFrame.frameType === FrameType.INITIAL) {
              const initialValue = this.dataStore.transition.getLayerTrackInitValue(elementId, subTrackId)
              value = this.parseInitLayerKeyFrame(paint.paintType, initialValue)
            } else {
              value = this.parsePaint(paint)
            }
          }

          const irKeyFrame = this.createIRKeyFrame(keyFrame, value)
          irLayer[propKey].addKeyFrame(irKeyFrame)
        })
      }
    })
  }

  parseEffect(element, node) {
    const effectComponent = this.dataStore.library.getComponent(element.base.effects[0])
    effectComponent.effects
      .slice()
      .reverse()
      .forEach(effectId => {
        const effectData = this.dataStore.library.getEffect(effectId).save()
        const effect = new IRTrimEffect({
          start: effectData.start,
          end: effectData.end,
          offset: effectData.offset,
          mode: getIRTrimMode(effectData.mode)
        })
        const effectKey = getEffectKey(effect.type)
        const effectTrack = this.dataStore.interaction.getPropertyTrackByElementIdAndPropKey(
          element.get('id'),
          effectKey
        )
        if (effectTrack) {
          effectTrack.children.forEach(propTrackKey => {
            const prop = propTrackKey.split('.').pop()
            const propTrack = this.dataStore.interaction.getPropertyTrackByElementIdAndPropKey(
              element.get('id'),
              propTrackKey
            )
            propTrack.keyFrameList.map(keyframeId => {
              const keyFrame = this.dataStore.interaction.getKeyFrame(keyframeId)
              const irKeyFrame = this.createIRKeyFrame(keyFrame)
              effect[prop].addKeyFrame(irKeyFrame)
            })
          })
        }
        node.addEffect(effect)
      })
  }

  createParentTransformNodes(element, precompositionAsset) {
    let parent = this.dataStore.getParentOf(element)
    let parentId = element.isScreen || parent.isScreen ? null : parent.get('id')
    const stacks = []
    while (parentId) {
      if (this.sceneTree.hasTransform(parentId)) {
        break
      }

      if (precompositionAsset) {
        if (precompositionAsset.hasTransform(parentId)) {
          break
        }
        if (precompositionAsset.id === parentId) {
          break
        }
      }

      stacks.push(parent)
      parent = this.dataStore.getParentOf(parent)
      parentId = parent.isScreen ? null : parent.get('id')
    }

    while (stacks.length) {
      const element = stacks.pop()
      const irNodeType = getIRNodeType(element)
      const IRClass = nodeTypeMapClass[irNodeType]
      const transformNode = new IRClass({
        id: element.get('id'),
        name: element.get('name'),
        visible: !element.isHidden(),
        autoOrient: element.get('autoOrient'),
        ...getNodeBaseProps(element, irNodeType),
        type: irNodeType
      })
      this.parseNodeKeyFrames(transformNode)
      parent = this.dataStore.getParentOf(element)
      parentId = parent ? parent.get('id') : null
      if (precompositionAsset) {
        precompositionAsset.appendChild(transformNode, parentId)
      } else {
        this.sceneTree.appendNode(transformNode, parentId)
      }
    }
  }
}
