import { GlyphMaps } from './FontData'

/** @type {GlyphMap} */
let _GlyphMapsNumbers = null

let _CachedFont = null
let _CachedRange = null
let _CachedType = null

/**
 * inital GlyphMap
 * @returns {GlyphMap}
 */
function getList() {
    if (_GlyphMapsNumbers) {
        return _GlyphMapsNumbers
    }
    _GlyphMapsNumbers = new Map()
    for (const type of ['Sans', 'Serif']) {
        const m = new Map()
        for (const k in GlyphMaps[type]) {
            const r = k.split('-').map(s => Number(`0x${s}`))
            m.set(r, GlyphMaps[type][k])
        }

        _GlyphMapsNumbers.set(type, new Map(Array.from(m).sort((a, b) => b[0][0] - a[0][0])))
    }
    return _GlyphMapsNumbers
}

/**
 * get a suitable font from pre-defined Noto Font table,
 * it supports Sans and Serif now
 * @param {number} charCorde
 * @param {'Sans'|'Serif'} [type='Sans']
 * @returns {string} postscript name
 */
function getGlyphFont(charCorde, type = 'Sans') {
    const list = getList().get(type)
    if (!list) {
        return null
    }

    // check cached Font and range first
    if (_CachedRange && _CachedType === type) {
        if (_CachedRange[0] === charCorde) {
            return _CachedFont
        }
        if (charCorde >= _CachedRange[0] && charCorde <= _CachedRange[1]) {
            return _CachedFont
        }
    }

    const range = binarySearch(Array.from(list.keys()), charCorde, 0, list.size)
    if (!range) {
        return null
    }
    let psn = list.get(range)
    psn = `${psn}-Regular`
    // cache the font, speed up sentense exploring
    _CachedFont = psn
    _CachedRange = range
    _CachedType = type

    return psn
}

/**
 * binary search range
 * @param {number[]} arr - ranges
 * @param {number} x - unicode
 * @param {number} start
 * @param {number} end
 * @returns {string} - postscript name
 */
function binarySearch(arr, x, start, end) {
    // Base Condition
    if (start > end) {
        return null
    }

    // Find the middle index
    const mid = Math.floor((start + end) / 2)

    // Compare mid with given key x
    const range = arr[mid]
    if (x < range[0]) {
        return binarySearch(arr, x, mid + 1, end)
    }
    if (x === range[0]) {
        return range
    }
    if (range[1] && x <= range[1]) {
        return range
    }

    return binarySearch(arr, x, start, mid - 1)
}

export { getGlyphFont }

/**
 * @typedef {Map<'Sans'|'Serif', Map<number[], string>>} GlyphMap
 */
