import GENERAL_INTERFACE from './scripts/generalInterface'
import COMPLETE_INTERFACE from './scripts/completeInterface'
import TOOL_MENU from './scripts/toolMenu'
import TRANSFORM from './scripts/transform'
import INDICATOR from './scripts/indicator'
import KEYFRAME from './scripts/keyframe'

export { TutorialType, LOADING_TIME } from './constant'

export default {
  GENERAL_INTERFACE,
  COMPLETE_INTERFACE,
  TOOL_MENU,
  TRANSFORM,
  INDICATOR,
  KEYFRAME
}
