import { CUSTOM_EVENT_NAMES } from '../constants'
import { createCustomEvent } from '../utils/customEventUtils'

class Counter extends EventTarget {
  private _count: number
  private _timer: NodeJS.Timeout | null
  private _TTL: number
  private _countToZeroEvent: CustomEvent

  constructor(private timeToLiveInMs: number) {
    super()
    this._TTL = timeToLiveInMs || 3000
    this._count = 0
    this._timer = null
    this._resetTimer()

    this._countToZeroEvent = createCustomEvent(CUSTOM_EVENT_NAMES.COUNT_TO_ZERO)
  }

  get count(): number {
    return this._count
  }

  private _resetCounter(): void {
    this._count = 0
  }

  private _resetTimer(): void {
    if (this._timer) {
      clearTimeout(this._timer)
    }
    this._timer = setTimeout(() => {
      this._resetCounter()
    }, this._TTL)
  }

  public countUp(): void {
    this._count++
    this._resetTimer()
  }

  public countDown(): void {
    if (this._count > 0) {
      this._count--
      this._resetTimer()
    }
    // after count down is zero, all counts have been subtracted
    if (this._count === 0) {
      this.dispatchEvent(this._countToZeroEvent)
    }
  }

  public clear(): void {
    this._count = 0
    if (this._timer) {
      clearTimeout(this._timer)
    }
  }
}

export default Counter
