import { isArr } from '@phase-software/data-utils'

export default class EditorChanges {
    constructor() {
        this.props = new Set()
        this.layerProps = new Map()
        this.effectProps = new Map()
    }

    addProps(...props) {
        let list = props
        if (isArr(props[0])) {
            list = props[0]
        }
        for (const p of list) {
            this.props.add(p)
        }
        return this
    }

    addLayerProps(layerItemId, ...props) {
        if (!this.layerProps.has(layerItemId)) {
            this.layerProps.set(layerItemId, new Set())
        }
        const layerPropSet = this.layerProps.get(layerItemId)
        let list = props
        if (isArr(props[0])) {
            list = props[0]
        }
        for (const p of list) {
            layerPropSet.add(p)
        }
        return this
    }

    addEffectProps(effectItemId, ...props) {
        if (!this.effectProps.has(effectItemId)) {
            this.effectProps.set(effectItemId, new Set())
        }
        const effectPropSet = this.effectProps.get(effectItemId)
        for (const p of props) {
            effectPropSet.add(p)
        }
        return this
    }

    isEmpty() {
        return this.props.size === 0
    }
}
