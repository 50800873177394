import React, { useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import { LoadingStatus } from '@phase-software/types'

import { FileFieldsFragment, ProjectFieldsFragment } from '../../generated/graphql'
import useHeapAnalytics from '../../hooks/useHeapAnalytics'
import { useSetNotification } from '../../providers/NotificationProvider'
import { useWorkspaceContext } from '../../providers/WorkspaceContextProvider'
import { track } from '../../services/heapAnalytics'
import { getPersonalSharedPath } from '../../utils/pathGenerators'
import { Dialog, Select, Text } from '../shared'
import { MenuListOptionProps } from '../shared/Menu/Menu.types'
import { MenuOptionProps } from '../shared/Menu/MenuOption'
import { translateMenuOptions } from '../shared/Menu/utils'
import { getAvailableCollaboratorActions, getCollaboratorPermissionName } from './shareHelper'
import { CollaboratorAction, CollaboratorPermission, InvitationAction, ShareTypeEnum } from './shareTypes'

type BaseShareCollaboratorActionProps = {
  collaborator: MenuOptionProps
  isCurrentUser: boolean
  isEditable: boolean
  name: ProjectFieldsFragment['name'] | FileFieldsFragment['name']
  onPermissionChange: (collaborator: MenuOptionProps, permission: CollaboratorPermission) => Promise<void>
  onRemove: (collaborator: MenuOptionProps) => Promise<void>
  onResendInvitation: (collaborator: MenuOptionProps) => Promise<void>
  onCancelInvitation: (collaborator: MenuOptionProps) => Promise<void>
  type?: ShareTypeEnum
  fileId?: string
  projectId: string
}

const BaseShareCollaboratorAction = ({
  collaborator,
  isCurrentUser,
  isEditable,
  name,
  onPermissionChange,
  onRemove,
  onResendInvitation,
  onCancelInvitation,
  type,
  fileId,
  projectId
}: BaseShareCollaboratorActionProps) => {
  const { t } = useTranslation(['workspace', 'setting', 'common'])
  const isProjectType = type === 'project'

  const [leaveDialogOpen, setLeaveDialogOpen] = useState(false)
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false)

  const [isRemoving, setIsRemoving] = useState(false)
  const history = useHistory()
  const { workspaceData } = useWorkspaceContext()
  const { space, teamName } = useHeapAnalytics()
  const { addNotification } = useSetNotification()
  const location = projectId === workspaceData.draftProjectId ? 'drafts' : 'project'

  const closeDialog = useCallback(() => {
    setLeaveDialogOpen(false)
    setRemoveDialogOpen(false)
  }, [])

  const handleConfirmRemove = useCallback(async () => {
    if (isRemoving) return
    try {
      setIsRemoving(true)
      await onRemove?.(collaborator)
      if (fileId) {
        track('User Removed From File', { space, location, teamName, projectId, fileId })
      }
      if (isProjectType) {
        track('User Removed From Project', { space, teamName, projectId })
      }
    } catch (error) {
      console.error(error)
    } finally {
      setRemoveDialogOpen(false)
      setIsRemoving(false)
    }
  }, [collaborator, fileId, isProjectType, isRemoving, onRemove, projectId, space, location, teamName])

  const handleConfirmLeave = useCallback(async () => {
    if (isRemoving) return
    setIsRemoving(true)
    try {
      await onRemove?.(collaborator)
      if (isCurrentUser) {
        history.push(getPersonalSharedPath(workspaceData.slug))
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLeaveDialogOpen(false)
      setIsRemoving(false)
    }
  }, [collaborator, history, isCurrentUser, isRemoving, onRemove, workspaceData.slug])

  const handleCollaboratorActionClick = useCallback(
    async (action: CollaboratorPermission | CollaboratorAction | InvitationAction) => {
      switch (action) {
        case CollaboratorPermission.CAN_EDIT:
        case CollaboratorPermission.CAN_REVIEW:
          try {
            await onPermissionChange?.(collaborator, action)
            const accessType = action === CollaboratorPermission.CAN_EDIT ? 'edit' : 'view'
            if (fileId) {
              track('User Access To File Changed', { space, location, teamName, projectId, fileId, accessType })
            }
            if (isProjectType) {
              track('User Access To Project Changed', { space, teamName, projectId, accessType })
            }
          } catch (error) {}
          break
        case CollaboratorAction.LEAVE:
          setLeaveDialogOpen(true)
          break
        case CollaboratorAction.REMOVE:
          setRemoveDialogOpen(true)
          break
        case InvitationAction.RESEND:
          try {
            await onResendInvitation(collaborator)
            addNotification({
              type: 'success',
              content: t('setting:team_setting.invitation_resent')
            })
          } catch (error) {
            console.log(error)
          }
          break
        case InvitationAction.CANCEL:
          try {
            await onCancelInvitation(collaborator)
            addNotification({
              type: 'success',
              content: (
                <Trans
                  i18nKey="workspace:message.invitation_cancelled"
                  values={{ email: collaborator.value }}
                  components={[<span key="invitation_cancelled" className="font-semibold" />]}
                />
              )
            })
          } catch (error) {
            console.log(error)
          }
          break
        default:
          break
      }
    },
    [
      collaborator,
      addNotification,
      fileId,
      isProjectType,
      location,
      onPermissionChange,
      onResendInvitation,
      onCancelInvitation,
      projectId,
      space,
      teamName,
      t
    ]
  )

  const translatedAvailableOptions = useMemo(() => {
    const availableOptions = getAvailableCollaboratorActions({
      isEditable,
      isCurrentUser,
      isPending: collaborator.isPending
    }) as MenuListOptionProps[]

    return translateMenuOptions(t, availableOptions, { ns: 'workspace' })
  }, [t, isEditable, isCurrentUser, collaborator.isPending])

  if (collaborator.isCreator) return <span className="text-12 text-white font-sans font-normal">{t('creator')}</span>

  if (!isEditable && !isCurrentUser)
    return (
      <span className="text-12 text-white font-sans font-normal">
        {t(getCollaboratorPermissionName(collaborator.permission) ?? '')}
      </span>
    )

  return (
    <>
      <Select
        // @ts-ignore TODO: fix after refactor of Select
        options={translatedAvailableOptions}
        value={collaborator.permission}
        onChange={handleCollaboratorActionClick}
        className="w-fit"
      />
      <Dialog
        size="xs"
        title={isProjectType ? t('leave_project') : t('leave_file')}
        dangerConfirmBtnText={t('leave')}
        cancelBtnText={t('common:button.cancel')}
        onDangerConfirm={handleConfirmLeave}
        onCancel={closeDialog}
        showProgressButton
        progressStatus={isRemoving ? LoadingStatus.WAITING : LoadingStatus.INITIAL}
        open={leaveDialogOpen}
      >
        <Text ellipsis={false} className="font-medium break-all">
          {isProjectType ? (
            <Trans
              i18nKey="workspace:dialog.are_you_sure_to_leave_project"
              values={{ name }}
              components={[<span key="leave_project" className="text-white font-semibold" />]}
            />
          ) : (
            <Trans
              i18nKey="workspace:dialog.leave_file_confirmation"
              values={{ name }}
              components={[<span key="leave_file" className="text-white font-semibold" />]}
            />
          )}
        </Text>
      </Dialog>
      <Dialog
        size="xs"
        title={isProjectType ? t('remove_project_collaborator') : t('remove_file_collaborator')}
        dangerConfirmBtnText={t('remove')}
        cancelBtnText={t('common:button.cancel')}
        onDangerConfirm={handleConfirmRemove}
        onCancel={closeDialog}
        showProgressButton
        progressStatus={isRemoving ? LoadingStatus.WAITING : LoadingStatus.INITIAL}
        open={removeDialogOpen}
      >
        <Text ellipsis={false} className="font-medium break-all">
          {isProjectType ? (
            <Trans
              i18nKey="workspace:dialog.remove_collaborator_from_project"
              values={{ name: collaborator.name }}
              components={[<span key="remove_collaborator_from_project" className="text-white font-semibold" />]}
            />
          ) : (
            <Trans
              i18nKey="workspace:dialog.remove_collaborator_from_file"
              values={{ name: collaborator.name }}
              components={[<span key="remove_collaborator_from_file" className="text-white font-semibold" />]}
            />
          )}
        </Text>
      </Dialog>
    </>
  )
}

export default BaseShareCollaboratorAction
