import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import { WorkspaceType } from '@phase-software/types'

import { TeamFieldsFragment } from '../../generated/graphql'
import useTeamActions from '../../hooks/useTeamActions'
import { useSetNotification } from '../../providers/NotificationProvider'
import { useProfile } from '../../providers/ProfileProvider'
import { track } from '../../services/heapAnalytics'
import { getWorkspaceDraftsPath } from '../../utils/pathGenerators'
import { verificationCodeValidator } from '../../utils/validator'
import { Button, Checkbox, FullScreenDialog, InputField, Message, ProgressButton } from '../shared'

type DeleteTeamDialogProps = {
  id: string
  requestId: string
  name: TeamFieldsFragment['name']
  onClose: () => void
  open: boolean
}

const DeleteTeamDialog = ({ id, requestId, name, onClose, open }: DeleteTeamDialogProps) => {
  const { t } = useTranslation(['setting', 'common'])
  const history = useHistory()
  const profile = useProfile()

  const { resendDeleteTeamRequest, confirmDeleteTeamRequest } = useTeamActions()
  const { addNotification } = useSetNotification()

  const [verificationCode, setVerificationCode] = useState('')
  const [confirmed, setConfirmed] = useState(false)
  const [lastSentTime, setLastSentTime] = useState(0)

  const [showResendMessage, setShowResendMessage] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  const handleConfirm = async () => {
    if (isDeleting) return
    setIsDeleting(true)

    try {
      await confirmDeleteTeamRequest(id, requestId, verificationCode)
      track('Team Deleted', { teamName: name })
      history.replace(getWorkspaceDraftsPath(WorkspaceType.PERSONAL, profile.custom_slug))
    } catch (error) {
      console.error(error)
    } finally {
      setIsDeleting(false)
    }
  }

  const handleResendVerificationCode = async () => {
    const now = Date.now()
    const timeDiff = now - lastSentTime

    if (timeDiff < 30000) {
      setShowResendMessage(true)
      return
    }

    setShowResendMessage(false)
    setLastSentTime(now)

    try {
      await resendDeleteTeamRequest(id, requestId)

      addNotification({
        type: 'success',
        content: t('team_setting.verification_code_sent')
      })
    } catch (error) {
      console.error(error)
    }
  }

  const handleClose = () => {
    setVerificationCode('')
    onClose()
  }
  const handleCloseMessage = () => {
    setShowResendMessage(false)
  }
  return open ? (
    <FullScreenDialog
      altActionText={t('team_setting.resend_verification_code')}
      onAltAction={handleResendVerificationCode}
      onClose={onClose}
      open={open}
      title={
        showResendMessage ? (
          <>
            <Message
              type="warning"
              description={t('team_setting.verification_code_sent')}
              className="mb-32"
              onClose={handleCloseMessage}
            />
            {t('team_setting.delete_team_confirmation', { name: name })}
          </>
        ) : (
          <>{t('team_setting.delete_team_confirmation', { name: name })}</>
        )
      }
      footer={[
        <ProgressButton
          fluid
          color="danger"
          disabled={
            isDeleting ||
            verificationCode.trim().length === 0 ||
            !!verificationCodeValidator(verificationCode, t) ||
            !confirmed
          }
          key="confirm-delete"
          onClick={handleConfirm}
          status={Number(isDeleting)}
        >
          {t('team_setting.delete_team')}
        </ProgressButton>,
        <Button fluid key="cancel-delete" color="secondary" onClick={handleClose}>
          {t('not_now')}
        </Button>
      ]}
    >
      <p className="mb-24">{t('team_setting.please_enter_verification_code')}</p>
      <InputField
        value={verificationCode}
        placeholder={t('team_setting.enter_deletion_code')}
        validator={(value) => verificationCodeValidator(value, t)}
        onInput={setVerificationCode}
        labelClassName="mb-24"
      />
      <Checkbox
        checked={confirmed}
        label={t('team_setting.delete_checkbox')}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setConfirmed(e.target.checked)}
      />
    </FullScreenDialog>
  ) : null
}

export default DeleteTeamDialog
