import { useCallback } from 'react'
import { MenuType, MenuProps } from './Menu.types'
import { applyStyleToElementRef } from './utils'

export type useMenuPositionProps = {
  cursorPosition: MenuProps['cursorPosition']
  firstSelectedOptionIndex: number
  scrollViewRef: React.RefObject<HTMLDivElement>
  viewportGap?: MenuProps['viewportGap']
  offsetY: MenuProps['offsetY']
  ref: React.RefObject<HTMLDivElement>
  showSearch: MenuProps['showSearch']
  triggerRef: MenuProps['trigger']
  type: MenuProps['type']
}

type MenuPlacement = 'top' | 'bottom'

export default function useMenuPosition({
  cursorPosition,
  firstSelectedOptionIndex,
  scrollViewRef,
  viewportGap = 0,
  offsetY = 0,
  ref,
  showSearch,
  triggerRef,
  type
}: useMenuPositionProps) {
  const computeMenuPlacement = useCallback(() => {
    const { top = 0, height = 0 } = triggerRef?.current?.getBoundingClientRect() || {}
    const offsetHeight = ref.current?.offsetHeight ?? 0
    if (top + height + offsetHeight + offsetY + viewportGap > window.innerHeight) return 'top'
    return 'bottom'
  }, [triggerRef, ref, offsetY, viewportGap])

  const updateSelectMenuPosition = useCallback(() => {
    const { innerWidth, innerHeight } = window
    const { offsetWidth = 0, offsetHeight = 0, scrollHeight } = ref.current ?? {}
    const {
      top: triggerTop = 0,
      left: triggerLeft = 0,
      height: triggerHeight = 0,
      width: triggerWidth = 0
    } = triggerRef?.current?.getBoundingClientRect() ?? {}
    const safeLeft = Math.max(Math.min(triggerLeft, innerWidth - offsetWidth - viewportGap), viewportGap)

    const triggerCenter = triggerTop - triggerHeight / 2

    const selectedIndex = firstSelectedOptionIndex === -1 ? 0 : firstSelectedOptionIndex
    const selectedOptionElement = scrollViewRef.current?.children[selectedIndex] as HTMLElement
    const selectedOptionOffsetTop = selectedOptionElement?.offsetTop ?? 0
    const selectedOptionOffsetHeight = selectedOptionElement?.offsetHeight ?? 0
    const menuOffsetHeight = scrollViewRef.current?.offsetHeight ?? 0

    let newTopPosition: number
    let newBottomPosition: number

    if (showSearch) {
      const menuTopOffset = triggerTop + triggerHeight + offsetY
      if (menuTopOffset + menuOffsetHeight > innerHeight - 2 * viewportGap) {
        newBottomPosition = innerHeight - triggerTop + offsetY
        newTopPosition = Math.max(viewportGap, innerHeight - newBottomPosition - menuOffsetHeight)
      } else {
        newTopPosition = menuTopOffset
        newBottomPosition = viewportGap
      }
    } else {
      if (offsetHeight < innerHeight - 2 * viewportGap) {
        newTopPosition = Math.max(triggerCenter - selectedOptionOffsetTop + selectedOptionOffsetHeight / 2, viewportGap)
        const bottomOverflow = newTopPosition + offsetHeight + viewportGap - innerHeight
        if (bottomOverflow > 0) {
          newTopPosition = newTopPosition - bottomOverflow
        }
        newBottomPosition = viewportGap
      } else {
        newTopPosition = viewportGap
        newBottomPosition = viewportGap
      }
    }

    applyStyleToElementRef(ref, {
      top: newTopPosition,
      left: safeLeft,
      bottom: newBottomPosition,
      maxHeight: scrollHeight,
      minWidth: triggerWidth
    })

    if (!showSearch && scrollViewRef.current && scrollViewRef.current) {
      scrollViewRef.current.scrollTop = newTopPosition + selectedOptionOffsetTop - triggerTop

      const selectedOptionOverflow =
        selectedOptionOffsetTop +
        selectedOptionOffsetHeight -
        scrollViewRef.current.scrollTop -
        scrollViewRef.current.offsetHeight
      if (selectedOptionOverflow > 0) {
        scrollViewRef.current.scrollTop = scrollViewRef.current.scrollTop + selectedOptionOverflow
      }
    }
  }, [ref, triggerRef, viewportGap, firstSelectedOptionIndex, scrollViewRef, showSearch, offsetY])

  const updatePositionByCursor = useCallback(() => {
    const { innerWidth, innerHeight } = window
    const { offsetWidth = 0, offsetHeight = 0, scrollHeight } = ref.current ?? {}

    const { top: cursorTop = 0, left: cursorLeft = 0 } = cursorPosition ?? {}

    let newTopPosition: number
    let newLeftPosition: number
    const newBottomPosition = viewportGap

    newTopPosition = cursorTop
    newLeftPosition = cursorLeft

    const bottomOverflow = newTopPosition + offsetHeight + viewportGap - innerHeight
    const isRightOverflow = newLeftPosition + offsetWidth + viewportGap > innerWidth

    if (bottomOverflow > 0) {
      newTopPosition = cursorTop - offsetHeight
      const isTopOverflow = newTopPosition < viewportGap
      if (isTopOverflow) {
        newTopPosition = innerHeight - newBottomPosition - offsetHeight
        newTopPosition = Math.max(newTopPosition, viewportGap)
      }
    }
    if (isRightOverflow) {
      newLeftPosition = cursorLeft - offsetWidth
    }

    applyStyleToElementRef(ref, {
      top: newTopPosition,
      left: newLeftPosition,
      bottom: newBottomPosition,
      maxHeight: scrollHeight
    })
  }, [ref, cursorPosition, viewportGap])

  const updatePositionByTrigger = useCallback(
    (placement: MenuPlacement) => {
      const { innerWidth, innerHeight } = window
      const { offsetWidth = 0, offsetHeight = 0, scrollHeight } = ref.current ?? {}

      const {
        top: triggerTop = 0,
        left: triggerLeft = 0,
        height: triggerHeight = 0
      } = triggerRef?.current?.getBoundingClientRect() ?? {}
      const safeLeft = Math.max(Math.min(triggerLeft, innerWidth - offsetWidth - viewportGap), viewportGap)

      let newTopPosition: number
      let newLeftPosition: number

      switch (placement) {
        case 'top': {
          newTopPosition = triggerTop - offsetHeight - offsetY
          const isTopOverflow = newTopPosition < viewportGap
          if (isTopOverflow) {
            newTopPosition =
              triggerTop > innerHeight - triggerTop - triggerHeight ? viewportGap : triggerTop + triggerHeight + offsetY
          }
          newLeftPosition = safeLeft
          break
        }
        case 'bottom': {
          newTopPosition = triggerTop + triggerHeight + offsetY
          newLeftPosition = safeLeft
          break
        }
        default:
          newTopPosition = viewportGap
          newLeftPosition = safeLeft
          break
      }

      applyStyleToElementRef(ref, {
        top: newTopPosition,
        left: newLeftPosition,
        bottom: viewportGap,
        maxHeight: scrollHeight
      })
    },
    [ref, triggerRef, viewportGap, offsetY]
  )

  const updatePosition = useCallback(() => {
    ref.current?.removeAttribute('style')

    const placement = computeMenuPlacement()

    switch (type) {
      case MenuType.SELECT_MENU:
        return updateSelectMenuPosition()

      case MenuType.DROPDOWN_MENU:
        return updatePositionByTrigger(placement)

      case MenuType.CONTEXT_MENU: {
        if (cursorPosition) return updatePositionByCursor()
        return updatePositionByTrigger(placement)
      }

      default:
        break
    }
  }, [
    computeMenuPlacement,
    cursorPosition,
    ref,
    type,
    updatePositionByCursor,
    updatePositionByTrigger,
    updateSelectMenuPosition
  ])

  return { updatePosition }
}
