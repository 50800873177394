import React from 'react'

import PropTypes from 'prop-types'

import { DEBUG_PRACTICAL_SUPPORTED_LOTTIE_FEATURE_SET, DEBUG_UNSUPPORTED_LOTTIE_FEATURE_SET } from '../../constants'

const getFeatColor = (feat) => {
  if (DEBUG_UNSUPPORTED_LOTTIE_FEATURE_SET.has(feat)) {
    return 'text-red'
  }
  if (DEBUG_PRACTICAL_SUPPORTED_LOTTIE_FEATURE_SET.has(feat)) {
    return 'text-orange'
  }
  return 'text-green'
}

export const FeatureList = ({ features }) => {
  return (
    <div className="mt-8">
      <h3>Features:</h3>
      <ul className="flex flex-wrap my-8 gap-8">
        {features.sort().map((feat) => (
          <li key={feat} className={`${getFeatColor(feat)}`}>
            {feat}
          </li>
        ))}
      </ul>
    </div>
  )
}
FeatureList.propTypes = {
  features: PropTypes.array
}
