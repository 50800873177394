import { LayerType } from '@phase-software/types'
import LayerStack from '../LayerStack'

class ShadowStack extends LayerStack {
  constructor(elementStack, data) {
    super(elementStack, data)
    this.type = LayerType.SHADOW
    this.key = 'shadows'
    this.animatableProperties = new Set([
      'opacity',
      'paint',
      'offsetX',
      'offsetY',
      'blur',
      'spread'
    ])
  }
}

export default ShadowStack
