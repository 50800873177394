import { TeamMemberRoleEnum } from '@phase-software/types'
import {
  FileFieldsFragment,
  ProjectFieldsFragment,
  TeamUserFieldsFragment,
  UserFieldsFragment
} from '../generated/graphql'
import { PERMISSIONS } from './permissions'

export interface UserType extends UserFieldsFragment {
  canEdit?: boolean
  canView?: boolean
  role: TeamMemberRoleEnum
}

type BasePermissions = {
  canView: boolean
  canEdit: boolean
}

type ProjectPermissions = BasePermissions & {
  canDelete: boolean
}

export type FileWithPermissions = FileFieldsFragment & BasePermissions
export type ProjectWithPermissions = ProjectFieldsFragment & ProjectPermissions
type ProjectOrFileWithPermissions = ProjectWithPermissions | FileWithPermissions

const { OWNER, ADMIN, MEMBER, GUEST } = TeamMemberRoleEnum

const teamRoleAccessLevels = {
  [GUEST]: 1,
  [MEMBER]: 2,
  [ADMIN]: 3,
  [OWNER]: 4
}

const isCurrentUser = (member: TeamUserFieldsFragment, user?: UserType) => member?.user_id === user?.id
const isOwnerOrAdmin = (role?: TeamMemberRoleEnum) => role && [OWNER, ADMIN].includes(role)
const isCreator = (creatorId: ProjectOrFileWithPermissions['creator_id'], userId: string) => creatorId === userId

export const isPersonalFile = (file: FileWithPermissions) => file.project?.team === null

export const isEditableForPersonalFile = (file: FileWithPermissions, user?: UserType, allowSharedFile = false) =>
  isCreator(file.creator_id, user?.id) || (file.canEdit && allowSharedFile)

export const isEditableForTeamFile = (
  file: FileWithPermissions,
  user?: UserType,
  allowRoles: UserType['role'][] = []
) =>
  isOwnerOrAdmin(user?.role) ||
  isCreator(file.creator_id, user?.id) ||
  (!!user?.role && allowRoles.includes(user.role) && file.canEdit)

export const evaluateFilePermission = (
  file: FileWithPermissions,
  user?: UserType,
  options: {
    allowSharedFile?: boolean
    allowTeamRoles: UserType['role'][]
  } = {
    allowSharedFile: false,
    allowTeamRoles: []
  }
) =>
  isPersonalFile(file)
    ? isEditableForPersonalFile(file, user, options.allowSharedFile)
    : isEditableForTeamFile(file, user, options.allowTeamRoles)

const rules = {
  // ------------------------
  // Team
  // ------------------------
  [PERMISSIONS.ACCESS_TEAM_GENERAL_PAGE]: [OWNER, ADMIN, MEMBER],
  [PERMISSIONS.EDIT_TEAM_GENERAL]: [OWNER],
  [PERMISSIONS.VIEW_TEAM_GENERAL]: [ADMIN, MEMBER],
  [PERMISSIONS.VIEW_TEAM_MEMBER]: [OWNER, ADMIN, MEMBER],
  [PERMISSIONS.VIEW_TEAM_MEMBER_INVITATION]: [OWNER, ADMIN],
  [PERMISSIONS.INVITE_TEAM_MEMBER]: [OWNER, ADMIN],
  [PERMISSIONS.REMOVE_TEAM_MEMBER]: (member: TeamUserFieldsFragment, user?: UserType) =>
    member.team_role !== OWNER && isOwnerOrAdmin(user?.role),
  [PERMISSIONS.EDIT_TEAM_MEMBER_ROLE]: (member: TeamUserFieldsFragment, user?: UserType) =>
    teamRoleAccessLevels[member.team_role as TeamMemberRoleEnum] <=
      teamRoleAccessLevels[user?.role as TeamMemberRoleEnum] &&
    isOwnerOrAdmin(user?.role) &&
    !isCurrentUser(member, user),
  [PERMISSIONS.LEAVE_TEAM]: (member: TeamUserFieldsFragment, user?: UserType) =>
    member.team_role !== OWNER && isCurrentUser(member, user),
  [PERMISSIONS.DELETE_TEAM]: [OWNER],
  [PERMISSIONS.TRANSFER_TEAM_OWNERSHIP]: (member: TeamUserFieldsFragment, user?: UserType) =>
    user?.role === OWNER && isCurrentUser(member, user),
  [PERMISSIONS.VIEW_TEMPLATE]: [OWNER, ADMIN, MEMBER],
  // ------------------------
  // Project
  // ------------------------
  [PERMISSIONS.CREATE_PROJECT]: [OWNER, ADMIN, MEMBER],
  [PERMISSIONS.RENAME_PROJECT]: (project: ProjectWithPermissions) => project.canEdit,
  [PERMISSIONS.DELETE_PROJECT]: (project: ProjectWithPermissions) => project.canDelete,
  [PERMISSIONS.SHARE_PROJECT]: (project: ProjectWithPermissions) => project.canView,
  [PERMISSIONS.MANAGE_PROJECT_COLLABORATOR]: (project: ProjectWithPermissions) => project.canEdit,
  [PERMISSIONS.CREATE_FILE]: (project: ProjectWithPermissions, user?: UserType) =>
    project.status === 'DRAFT' ? user?.role !== GUEST : project.canEdit,
  // ------------------------
  // File
  // ------------------------
  [PERMISSIONS.VIEW_FILE]: (file: FileWithPermissions, user?: UserType) =>
    !evaluateFilePermission(file, user, {
      allowSharedFile: true,
      allowTeamRoles: [MEMBER, GUEST]
    }) && file.canView,
  [PERMISSIONS.EDIT_FILE]: (file: FileWithPermissions, user?: UserType) =>
    evaluateFilePermission(file, user, {
      allowSharedFile: true,
      allowTeamRoles: [MEMBER, GUEST]
    }),
  [PERMISSIONS.RENAME_FILE]: (file: FileWithPermissions, user?: UserType) =>
    evaluateFilePermission(file, user, {
      allowSharedFile: true,
      allowTeamRoles: [MEMBER, GUEST]
    }),
  [PERMISSIONS.DUPLICATE_FILE]: (file: FileWithPermissions, user?: UserType) =>
    evaluateFilePermission(file, user, {
      allowSharedFile: true,
      allowTeamRoles: [MEMBER, GUEST]
    }),
  [PERMISSIONS.MOVE_FILE]: (file: FileWithPermissions, user?: UserType) =>
    evaluateFilePermission(file, user, {
      allowTeamRoles: [MEMBER, GUEST]
    }),
  [PERMISSIONS.DELETE_FILE]: (file: FileWithPermissions, user?: UserType) => evaluateFilePermission(file, user),
  [PERMISSIONS.ARCHIVE_FILE]: (file: FileWithPermissions, user?: UserType) => evaluateFilePermission(file, user),
  [PERMISSIONS.MANAGE_FILE_COLLABORATOR]: (file: FileWithPermissions, user?: UserType) =>
    evaluateFilePermission(file, user, {
      allowSharedFile: true,
      allowTeamRoles: [MEMBER]
    })
}

export default rules
