import React, { isValidElement, useCallback, useRef, useState } from 'react'

import Dropdown from '../Dropdown'
import IconButton from '../IconButton'
import { MenuProps } from '../Menu'

const dividerPositionMapper = {
  none: '',
  top: 'border-t',
  bottom: 'border-b',
  both: 'border-t border-b'
} as const

type WideTitleProps = {
  children: string | React.ReactNode
  childrenTextId?: string
  childrenClassName?: string
  disabled?: boolean
  disabledTooltip?: string
  dividerPosition?: keyof typeof dividerPositionMapper
  icons?: WideTitleIconProps[]
  // rightComponent should only be used in the special case of this component for the PathShapeEditor
  rightComponent?: React.ReactNode
}

export type WideTitleIconProps = {
  name: string
  className?: string
  buttonClassName?: string
  dataTestId?: string
  tooltip?: string
  onClick?: () => void
  dropdownMenu?: Omit<MenuProps, 'open' | 'onOpenChange' | 'type'>
}

const isChildrenValidElement = (children: WideTitleProps['children']): children is React.ReactNode =>
  isValidElement(children)

const WideTitle = ({
  children,
  childrenTextId,
  childrenClassName = '',
  disabled = false,
  disabledTooltip,
  dividerPosition = 'none',
  icons = [],
  rightComponent
}: WideTitleProps) => {
  const iconGroupRef = useRef<HTMLDivElement | null>(null)
  const [disableGroupHover, setDisableGroupHover] = useState(false)
  const hasMultipleIcons = Array.isArray(icons) && icons.length > 1
  const hasRightComponent = !!rightComponent && isValidElement(rightComponent)

  const cursorStyle = !icons.length || hasRightComponent ? 'cursor-default' : 'cursor-pointer'

  const handleClickTitle = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      if (!disabled && icons && !hasRightComponent) {
        const lastIconButton = iconGroupRef.current?.lastElementChild as HTMLElement
        lastIconButton?.click()
      }
    },
    [disabled, icons, hasRightComponent]
  )

  const renderIcon = useCallback(
    (
      { name, dropdownMenu, dataTestId, tooltip, className, buttonClassName, onClick }: WideTitleIconProps,
      index: number
    ) => {
      const iconButton = (
        <IconButton
          onMouseEnter={() => {
            setDisableGroupHover(true)
          }}
          onMouseLeave={() => {
            setDisableGroupHover(false)
          }}
          key={name}
          data-test-id={dataTestId}
          disabled={disabled}
          icon={name}
          tip={disabled ? disabledTooltip : tooltip}
          className={buttonClassName}
          iconClassName={className}
          onClick={onClick}
          disableGroupHover={(index < icons.length - 1 && hasMultipleIcons) || disableGroupHover}
        />
      )

      return dropdownMenu ? (
        <Dropdown key={name} menu={dropdownMenu} inline>
          {iconButton}
        </Dropdown>
      ) : (
        iconButton
      )
    },
    [icons.length, disabled, disabledTooltip, hasMultipleIcons, disableGroupHover]
  )

  const renderIconButtons = useCallback(() => {
    if (!icons.length) return null

    return icons.map((icon, index) => renderIcon(icon, index))
  }, [icons, renderIcon])

  const preventPropagation = useCallback((e: React.MouseEvent) => e.stopPropagation(), [])

  const renderRightComponent = useCallback(() => {
    if (rightComponent) {
      return rightComponent
    }
    return (
      <div className="flex gap-x-8" ref={iconGroupRef} onClick={preventPropagation}>
        {renderIconButtons()}
      </div>
    )
  }, [rightComponent, renderIconButtons, preventPropagation])

  return (
    <div
      data-test-id="wide-title"
      className={`${dividerPositionMapper[dividerPosition]} ${
        disabled ? 'cursor-not-allowed' : cursorStyle
      } group border-solid border-neutral-80 pl-16 pr-12 py-12 text-12 font-medium flex items-center justify-between gap-8 h-40`}
      onClick={handleClickTitle}
    >
      <div
        data-test-id={childrenTextId}
        className={`${
          isChildrenValidElement(children) ? '' : 'flex-grow'
        } text-white overflow-ellipsis overflow-hidden whitespace-nowrap ${childrenClassName}`}
      >
        {children}
      </div>
      {renderRightComponent()}
    </div>
  )
}
export default WideTitle
