import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { CommentType, ThreadDataProps } from '../shared/CommentThread/types'
import { FocusLoop } from '../shared'
import { useCommentContext } from '../../providers/CommentProvider'
import CommentThreadWithData from './CommentThreadWithData'

export type CanvasCommentThreadProps = {
  thread: ThreadDataProps
  isSelected: boolean
  expandThread: () => void
  onThreadClose?: () => void
  className?: string
}

export type CanvasCommentThreadRef = {
  openCommentThread: () => void
  closeCommentThread: () => void
}

const CanvasCommentThread = forwardRef<CanvasCommentThreadRef, CanvasCommentThreadProps>(
  ({ thread, isSelected, expandThread, onThreadClose }, forwardRef) => {
    const [open, setOpen] = useState(false)

    const { clearSelectedThread } = useCommentContext()

    useImperativeHandle(forwardRef, () => ({
      openCommentThread,
      closeCommentThread
    }))

    const openCommentThread = useCallback(() => {
      setOpen(true)
    }, [setOpen])

    const closeCommentThread = useCallback(() => {
      setOpen(false)
    }, [setOpen])

    useEffect(() => {
      if (isSelected) {
        openCommentThread()
        return
      }
      closeCommentThread()
    }, [isSelected, openCommentThread, closeCommentThread])

    return (
      <TransitionGroup component={null}>
        {open && (
          <CSSTransition
            timeout={{
              appear: 150,
              enter: 150
            }}
            classNames="canvas-comment-thread"
          >
            <FocusLoop undoable>
              <CommentThreadWithData
                type={CommentType.CANVAS}
                thread={thread}
                isExpanded={isSelected}
                expandThread={expandThread}
                onThreadClose={onThreadClose}
                onTextAreaEscape={clearSelectedThread}
                className="w-[280px] origin-bottom-left duration-[150ms] ease-out"
              />
            </FocusLoop>
          </CSSTransition>
        )}
      </TransitionGroup>
    )
  }
)

CanvasCommentThread.displayName = 'CanvasCommentThread'

export default CanvasCommentThread
