import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import CanvasCommentThread, { CanvasCommentThreadRef } from './CanvasCommentThread'
import { useCommentContext } from '../../providers/CommentProvider'
import { useDataStore } from '../../providers/dataStore/DataStoreProvider'
import useCanvasTagPosition from './useCanvasTagPosition'
import { CommentType, ThreadDataProps, UserMeta } from '../shared/CommentThread/types'
import { parseMetadata, stringifyMetadata } from './utils'
import { useEditThreadMetadata, useSelf, useUser } from '../../liveblocks.config'
import CommentTag from '../shared/CommentThread/CommentTag'
import { CSSTransition } from 'react-transition-group'

enum CommentThreadState {
  COLLAPSED,
  EXPANDED
}

type CanvasCommentProps = {
  thread: ThreadDataProps
  onMouseDown?: (e: React.MouseEvent<HTMLDivElement>) => void
}

const CanvasCommentThreadWithTag = ({ thread }: CanvasCommentProps) => {
  const [isAnimating, setIsAnimating] = useState(false)
  const commentThreadRef = useRef<CanvasCommentThreadRef>(null)
  const ref = useRef<HTMLDivElement>(null)
  const isTriggerBlocked = useRef(false)
  const currentPosition = useRef<{ x: number; y: number }>({ x: 0, y: 0 })
  const { selectedThread, selectCanvasCommentThread, clearSelectedThread, animatedTagId, setAnimatedTagId } =
    useCommentContext()
  const editThreadMetadata = useEditThreadMetadata()
  const dataStore = useDataStore()

  const threadId = thread.id
  const authorId = thread.comments[0].userId
  const { user } = useUser(authorId) as { user?: UserMeta }
  const { id: currentUserId = '' } = useSelf()

  const metadata = useMemo(() => {
    const rawMetaData = thread.metadata
    return parseMetadata(rawMetaData)
  }, [thread.metadata])

  const onMouseUpUpdate = useCallback(
    (newPosition: { x: number; y: number }) => {
      editThreadMetadata({ threadId: threadId, metadata: { position: JSON.stringify(newPosition) } })
    },
    [editThreadMetadata, threadId]
  )

  const { isOverlayShown, init, handleTagPositionUpdate } = useCanvasTagPosition({
    ref,
    canvasPosition: metadata.position || { x: 0, y: 0 },
    onMouseUpUpdate,
    isTriggerBlocked,
    currentPosition
  })

  const isRead = metadata.usersReadStatus?.[currentUserId]
  const isThreadSelected = selectedThread.id === threadId && selectedThread.type === CommentType.CANVAS

  const setUserIsRead = useCallback(() => {
    const newMetadata = stringifyMetadata({
      ...metadata,
      usersReadStatus: { ...metadata.usersReadStatus, [currentUserId]: true }
    })
    editThreadMetadata({ threadId, metadata: newMetadata })
  }, [threadId, editThreadMetadata, currentUserId, metadata])

  const openCanvasCommentThread = useCallback(
    (state: CommentThreadState) => {
      switch (state) {
        case CommentThreadState.EXPANDED:
          selectCanvasCommentThread(threadId)
          break
        case CommentThreadState.COLLAPSED:
          if (isThreadSelected) return
          commentThreadRef.current?.openCommentThread()
          break
        default:
          break
      }
    },
    [isThreadSelected, selectCanvasCommentThread, threadId]
  )

  const closeCanvasCommentThread = useCallback(
    (state: CommentThreadState) => {
      switch (state) {
        case CommentThreadState.EXPANDED:
          clearSelectedThread()
          break
        case CommentThreadState.COLLAPSED:
          if (isThreadSelected) return
          commentThreadRef.current?.closeCommentThread()
          break
        default:
          break
      }
    },
    [isThreadSelected, clearSelectedThread]
  )

  const expandCanvasThread = useCallback(() => {
    if (isThreadSelected) return
    openCanvasCommentThread(CommentThreadState.EXPANDED)
  }, [isThreadSelected, openCanvasCommentThread])

  const handleWrapperClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation()
  }, [])

  const handleWrapperMouseEnter = useCallback(() => {
    openCanvasCommentThread(CommentThreadState.COLLAPSED)
  }, [openCanvasCommentThread])

  const handleWrapperMouseLeave = useCallback(() => {
    closeCanvasCommentThread(CommentThreadState.COLLAPSED)
  }, [closeCanvasCommentThread])

  const handleTagClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      if (isTriggerBlocked.current) {
        isTriggerBlocked.current = false
        return
      }
      if (isThreadSelected) {
        closeCanvasCommentThread(CommentThreadState.EXPANDED)
        return
      }
      openCanvasCommentThread(CommentThreadState.EXPANDED)
      setUserIsRead()
    },
    [openCanvasCommentThread, closeCanvasCommentThread, setUserIsRead, isThreadSelected]
  )

  const handleCommentThreadClose = useCallback(() => {
    closeCanvasCommentThread(CommentThreadState.EXPANDED)
  }, [closeCanvasCommentThread])

  const resetAnimationState = () => {
    setIsAnimating(false)
  }

  useEffect(() => {
    const onEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        e.stopPropagation()
        closeCanvasCommentThread(CommentThreadState.EXPANDED)
        window.removeEventListener('keydown', onEscape)
      }
    }
    window.addEventListener('keydown', onEscape)
    return () => {
      window.removeEventListener('keydown', onEscape)
    }
  }, [closeCanvasCommentThread, editThreadMetadata, threadId, dataStore])

  useEffect(() => {
    if (animatedTagId === threadId) {
      setIsAnimating(true)
      setAnimatedTagId('')
    }
  }, [animatedTagId, setAnimatedTagId, threadId])

  if (metadata.isDeleted) return null

  return (
    <div
      className={`absolute h-32 flex gap-x-12 flex-row left-0 bottom-0 hover:z-10 cursor-auto will-change-transform ${
        isThreadSelected && 'z-20'
      }`}
      ref={ref}
      onMouseEnter={handleWrapperMouseEnter}
      onMouseLeave={handleWrapperMouseLeave}
      onClick={handleWrapperClick}
    >
      {isOverlayShown && (
        <div className="fixed left-[-512px] top-[-512px] w-[1056px] h-[1056px] cursor-pointer bg-transparent" />
      )}
      {init && (
        <>
          <CSSTransition in={isAnimating} timeout={150} classNames="canvas-comment-tag" onEntered={resetAnimationState}>
            <CommentTag
              user={user}
              isRead={isRead}
              isResolved={metadata.resolved}
              isSelected={isThreadSelected}
              onClick={handleTagClick}
              onMouseDown={handleTagPositionUpdate}
            />
          </CSSTransition>
          <CanvasCommentThread
            thread={thread}
            ref={commentThreadRef}
            isSelected={isThreadSelected}
            expandThread={expandCanvasThread}
            onThreadClose={handleCommentThreadClose}
          />
        </>
      )}
    </div>
  )
}

export default CanvasCommentThreadWithTag
