import { Vector2 } from "./Vector2"

/** @typedef {import("./rect2").Rect2} Rect2 */

/**
 * @param {number} deg
 */
export const deg2rad = (deg) => deg / 180 * Math.PI

/**
 * @param {number} x1
 * @param {number} y1
 * @param {number} x2
 * @param {number} y2
 * @returns {number}
 */
export function distance(x1, y1, x2, y2) {
    return Math.hypot(x1 - x2, y1 - y2)
}

/**
 * @param {number} x1
 * @param {number} y1
 * @param {number} x2
 * @param {number} y2
 * @returns {number}
 */
export function distance2(x1, y1, x2, y2) {
    return (x1 - x2) * (x1 - x2) + (y1 - y2) * (y1 - y2)
}

/**
 * Get distance from a point to a line
 * @param {number} px - point x
 * @param {number} py - point y
 * @param {number} fromX - line start x
 * @param {number} fromY - line start y
 * @param {number} toX - line end x
 * @param {number} toY - line end y
 * @returns {number}
 */
export function distanceFromPointToLine(px, py, fromX, fromY, toX, toY) {
    // If point is at the same place as lut, then the distance is 0.
    if ((px === fromX && py === fromY) || (px === toX && py === toY)) {
        return 0
    }

    // If two points from lut are the same point, then just calculate the distance from the point to lut.
    if (fromX === toX && fromY === toY) {
        return Math.sqrt((px - fromX) ** 2 + (py - fromY) ** 2)
    }

    return Math.abs((fromY - toY) * px + (toX - fromX) * py + (fromX * toY - toX * fromY)) / Math.sqrt((fromY - toY) ** 2 + (toX - fromX) ** 2)
}

/**
 * Get distance from a point to a line
 * @param {number} px - point x
 * @param {number} py - point y
 * @param {number} fromX - line start x
 * @param {number} fromY - line start y
 * @param {number} toX - line end x
 * @param {number} toY - line end y
 * @param {Vector2Like} out
 * @returns {Vector2Like}
 */
export function projectToLine(px, py, fromX, fromY, toX, toY, out) {
    const a = fromY - toY
    const b = toX - fromX
    const c = fromX * toY - toX * fromY
    const t = (a * px + b * py + c) / (a ** 2 + b ** 2)

    out.set(px - a * t, py - b * t)
    return t
}

/**
 * Project point to ellipse
 * @param {Vector2} mouse - related to the center of ellipse
 * @param {Vector2} left - related to the center of ellipse
 * @param {Vector2} bottom - related to the center of ellipse
 * @param {Vector2} center - center of ellipse
 * @param {Transform2D} newTransform - need an empty transform2D to transform final result. Do not import Transform2D in this file directly
 * @returns {object}
 */
export function projectToEllipse(mouse, left, bottom, center, newTransform) {
    // Get rotate angle
    // Because the start point is the bottom, not left or right, so we would not use Vector2 to get angle.
    let rotate = Math.atan2(-bottom.x, bottom.y)
    if (rotate < 0) {
        rotate += Math.PI * 2
    }

    // Invert mouse pos
    const T = newTransform.rotate(rotate)
    const IT = T.affine_inverse()
    IT.xform(mouse, mouse)
    mouse.multiply(1, -1)

    // Get length of major and minor axis
    const a = left.length()
    const b = bottom.length()

    // Get intersect points, [px, py] and [-px, -py]
    const m = mouse.y / mouse.x
    const px = a * b * Math.sqrt(1 / (b ** 2 + a ** 2 * m ** 2))
    const py = m * px

    // Find closet point
    const { pos, dis } = findClosetPoint(
        mouse,
        [
            [px, py],
            [-px, -py]
        ]
    )

    // Transform closet point
    const intersect = T.xform({ x: pos[0], y: pos[1] })
    const ip = intersect.set(intersect.x + center.x, -intersect.y + center.y)

    // Scale project point from ellipse to circle and get angle between vCB -> vCP'
    let rad = Math.atan2(-pos[0] * b / a, -pos[1])
    if (rad < 0) {
        rad += Math.PI * 2
    }

    return {
        pos: ip,
        dis,
        per: rad / Math.PI / 2
    }
}

/**
 * Find closet point
 * @param {Vector2} p
 * @param {number[][]} points
 * @returns {{ pos: number[], dis: number }}
 */
function findClosetPoint(p, points) {
    let pos = null
    let dis = Number.MAX_SAFE_INTEGER
    for (let i = 0; i < points.length; i++) {
        const len = distance(p.x, p.y, points[i][0], points[i][1])
        if (len < dis) {
            dis = len
            pos = points[i]
        }
    }

    return { pos, dis }
}

/**
 * @param {number} x
 * @param {number} y
 * @param {number} cX
 * @param {number} cY
 * @param {number} radius
 * @returns {boolean} true if the point at x, y is inside or on the edge of the circle centered at cX, cY
 */
export function isInsideCircle(x, y, cX, cY, radius) {
    return Math.pow(x - cX, 2) + Math.pow(y - cY, 2) <= Math.pow(radius, 2)
}

const int8 = new Int8Array(4)
const int32 = new Int32Array(int8.buffer, 0, 1)
const float32 = new Float32Array(int8.buffer, 0, 1)
/**
 * @param {number} i
 * @returns {number}
 */
const int_bits_to_float = (i) => {
    int32[0] = i
    return float32[0]
}
/**
 * @param {number} value
 * @returns {number}
 */
const int_to_float_color = (value) => int_bits_to_float(value & 0xfeffffff)

/**
 * Pack float color (1.0, 1.0, 1.0, 1.0) into a f32
 * @param {number} r
 * @param {number} g
 * @param {number} b
 * @param {number} a
 * @returns {number}
 */
export function pack_color_f(r, g, b, a) {
    const bits = (
        (((a * 255) | 0) << 24) |
        (((b * 255) | 0) << 16) |
        (((g * 255) | 0) << 8) |
        ((r * 255) | 0)
    )
    return int_to_float_color(bits)
}
/**
 * Pack u8 color (255, 255, 255, 255) into a f32
 * @param {number} r
 * @param {number} g
 * @param {number} b
 * @param {number} a
 * @returns {number}
 */
export function pack_color_u(r, g, b, a) {
    const bits = ((a << 24) | (b << 16) | (g << 8) | r)
    return int_to_float_color(bits)
}

/**
 * @param {number} p1x
 * @param {number} p1y
 * @param {number} p1z
 * @param {number} p2x
 * @param {number} p2y
 * @param {number} p2z
 * @param {number} p3x
 * @param {number} p3y
 * @param {number} p3z
 * @param {number} x
 * @param {number} y
 * @returns {number}
 */
export function bary_centric(p1x, p1y, p1z, p2x, p2y, p2z, p3x, p3y, p3z, x, y) {
    const det_inv = 1 / ((p2z - p3z) * (p1x - p3x) + (p3x - p2x) * (p1z - p3z))
    const l1 = ((p2z - p3z) * (x - p3x) + (p3x - p2x) * (y - p3z)) * det_inv
    const l2 = ((p3z - p1z) * (x - p3x) + (p1x - p3x) * (y - p3z)) * det_inv
    const l3 = 1 - l1 - l2
    return l1 * p1y + l2 * p2y + l3 * p3y
}

/**
 *
 * @param {number} p1x
 * @param {number} p1y
 * @param {number} p2x
 * @param {number} p2y
 * @param {number} q1x
 * @param {number} q1y
 * @param {number} q2x
 * @param {number} q2y
 * @param {Vector2} [output]
 * @returns {Vector2}
 */
export function linesIntersect(p1x, p1y, p2x, p2y, q1x, q1y, q2x, q2y, output) {
    const det = (p1x - p2x) * (q1y - q2y) - (p1y - p2y) * (q1x - q2x)
    if (det === 0) {
        throw new Error('lines are parallel')
    }

    const inv = 1 / det
    const x = ((p1x * p2y - p1y * p2x) * (q1x - q2x) - (p1x - p2x) * (q1x * q2y - q1y * q2x)) * inv
    const y = ((p1x * p2y - p1y * p2x) * (q1y - q2y) - (p1y - p2y) * (q1x * q2y - q1y * q2x)) * inv
    if (output) {
        return output.set(x, y)
    }
    return new Vector2(x, y)
}

/**
 * @param {Rect2} rect
 * @param {number} targetWidth
 * @param {number} targetHeight
 */
export function innerBox(rect, targetWidth, targetHeight) {
    // eslint-disable-next-line prefer-const
    let { x, y, width, height } = rect
    const targetRatio = targetWidth / targetHeight
    const ratio = width / height
    let scale = 1
    if (ratio >= targetRatio) {
        scale = targetHeight / height
    } else {
        scale = targetWidth / width
    }
    x = x * scale + (width * scale - targetWidth) / 2
    y = y * scale + (height * scale - targetHeight) / 2
    return { x, y, width: targetWidth, height: targetHeight, scale }
}

/**
 * @param {Rect2} rect
 * @param {number} targetWidth
 * @param {number} targetHeight
 */
export function outerBox(rect, targetWidth, targetHeight) {
    // eslint-disable-next-line prefer-const
    let { x, y, width, height } = rect
    const targetRatio = targetWidth / targetHeight
    const ratio = width / height
    let scale = 1
    if (ratio <= targetRatio) {
        scale = targetHeight / height
    } else {
        scale = targetWidth / width
    }
    x = x * scale + (width * scale - targetWidth) / 2
    y = y * scale + (height * scale - targetHeight) / 2
    return { x, y, width: targetWidth, height: targetHeight, scale }
}

/**
 * @param {number} f  original function
 * @param {number} df derivative function of f
 * @param {number} x0 initial guess
 * @param {number} epsilon
 * @param {number} maxIterations
 */
export function newtonRaphson(f, df, x0, epsilon = 1e-6, maxIterations = 100) {
    let x = x0
    for (let i = 0; i < maxIterations; i++) {
        const fx = f(x)
        if (Math.abs(fx) < epsilon) {
            return x
        }
        x -= fx / df(x)
    }
    return x
}
