import React from 'react'
import PropTypes from 'prop-types'
import bannerImg from './banner-mobile.png'

const Dialog = ({ children }) => {
  return (
    <div className="overflow-hidden shadow-5" style={{ width: 350, borderRadius: 14 }}>
      <img src={bannerImg} className="w-full" width={350} height={228} alt="banner" />
      <div className="px-24 py-40">{children}</div>
    </div>
  )
}

Dialog.propTypes = {
  children: PropTypes.node
}

export default Dialog
