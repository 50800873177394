import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Mode } from '@phase-software/types'

import { useModal, useSetModal } from '../../../providers/ModalProvider'
import { useUI } from '../../../providers/dataStore/UIProvider'
import { Modal } from '../../shared'
import ColorRow from './ColorRow'
import Header from './Header'
import TimeRow from './TimeRow'

const InspectConfigModal = () => {
  const MODAL_KEY = 'InspectContextMenu-Main'
  const { t } = useTranslation('file', { keyPrefix: 'inspect' })
  const { closeModal } = useSetModal(MODAL_KEY)
  const { mode } = useUI()
  const isDesignMode = mode === Mode.DESIGN

  const {
    open,
    data: { trigger }
  } = useModal((o: any) => o[MODAL_KEY])

  const closeStrokeAdvancedModal = useCallback(() => {
    closeModal()
  }, [closeModal])

  return (
    <Modal open={open} trigger={trigger} onClose={closeStrokeAdvancedModal}>
      {open && (
        <div className="pb-16">
          <Header name={t('inspect_settings')} modalKey="InspectContextMenu-Main" />
          <div className="flex flex-col gap-8">
            <ColorRow />
            {!isDesignMode && <TimeRow />}
          </div>
        </div>
      )}
    </Modal>
  )
}

export default React.memo(InspectConfigModal)
