import React, { HTMLAttributes } from 'react'
import RemoveEffect from './RemoveEffect'

interface ContainerWithRemoveEffectProps extends HTMLAttributes<HTMLDivElement> {
  effectItemId: string
  dataTestId?: string
}

export const ContainerWithRemoveEffect = ({
  children,
  effectItemId,
  dataTestId,
  ...rest
}: ContainerWithRemoveEffectProps) => {
  return (
    <div className="flex items-center justify-between pb-8" data-test-id={dataTestId} {...rest}>
      {children}
      <RemoveEffect effectItemId={effectItemId} />
    </div>
  )
}
