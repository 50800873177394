import React, { useCallback } from 'react'
// @ts-ignore
import { TRANSITION_STATUS } from '@phase-software/transition-manager'

import { useTransitionManager, useTransitionManagerActions } from '../../../providers/TransitionManagerProvider'
import { useInteraction, useInteractionActions } from '../../../providers/dataStore/InteractionProvider'
import PlayBackControlButton from '../components/PlayBackControlButton'
import SpeedControlButton from '../components/SpeedControlButton'

export type BasicPlaybackControlProps = {
  actionId: string
}

const BasicPlaybackControl = ({ actionId }: BasicPlaybackControlProps) => {
  return (
    <div className="flex gap-x-4">
      <PlayControl />
      <LoopControl actionId={actionId} />
      <SpeedControl actionId={actionId} />
    </div>
  )
}

export default BasicPlaybackControl

const PlayControl = () => {
  const status = useTransitionManager((o) => o.status)
  const { playAnimation, stopAnimation } = useTransitionManagerActions()

  const isPlaying = status === TRANSITION_STATUS.START

  const togglePlayStatus = useCallback(() => {
    isPlaying ? stopAnimation() : playAnimation()
  }, [isPlaying, stopAnimation, playAnimation])

  return <PlayBackControlButton variant="play" isActive={isPlaying} onChange={togglePlayStatus} />
}

const LoopControl = ({ actionId }: { actionId: string }) => {
  const loop = useTransitionManager((o) => o.loop)
  const { setActionLooping } = useInteractionActions()

  const toggleLoopStatus = useCallback(() => {
    setActionLooping(actionId, !loop)
  }, [setActionLooping, actionId, loop])

  return <PlayBackControlButton variant="loop" isActive={loop} onChange={toggleLoopStatus} />
}

const SpeedControl = ({ actionId }: { actionId: string }) => {
  const { speed } = useInteraction(actionId)
  const { setActionSpeed } = useInteractionActions()

  const handleSpeedControl = useCallback(
    (speed: number) => {
      setActionSpeed(actionId, speed)
    },
    [setActionSpeed, actionId]
  )

  return <SpeedControlButton value={speed} onChange={handleSpeedControl} />
}
