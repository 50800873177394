import React from 'react'

import { Avatar, Checkbox, Radio } from '../index'

type ListItemProps = {
  avatarAlt?: string
  avatarSrc?: string
  checked?: boolean
  className?: string
  after?: React.ReactNode
  description?: React.ReactNode
  onChecked?: (check: boolean) => void
  size?: 's' | 'l'
  tag?: string
  title: React.ReactNode
  type?: 'checkbox' | 'radio'
}

const ListItem: React.FC<ListItemProps> = ({
  avatarAlt,
  avatarSrc,
  checked,
  className = '',
  after,
  description,
  onChecked,
  size = 's',
  title,
  tag,
  type
}) => {
  const isCheckable = type != null
  const hasAvatar = avatarSrc != null || avatarAlt != null

  const handleCheckedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChecked?.(e.target.checked)
  }

  const renderAvatar = () => {
    if (!hasAvatar) return null
    return (
      <div className="mr-8">
        <Avatar src={avatarSrc} alt={avatarAlt} size="m" />
      </div>
    )
  }

  const renderTitle = () => {
    if (typeof title === 'string') {
      return (
        <div className="inline-flex gap-4">
          <span
            className={`text-12 text-white font-sans flex-grow-0 truncate ${
              description == null ? 'font-normal' : 'font-medium'
            }`}
          >
            {title}
          </span>
          {tag && <span className="w-fit text-12 text-white font-sans font-medium">({tag})</span>}
        </div>
      )
    }
    return title
  }

  const renderDescription = () => {
    if (typeof description === 'string') {
      return <span className="text-11 text-light-overlay-60 font-sans font-normal">{description}</span>
    }
    return description
  }

  const renderAfter = () => {
    if (typeof after === 'string') {
      return <span className="text-12 text-white font-sans font-normal">{after}</span>
    }
    return after
  }

  return (
    <div
      className={`flex items-center justify-between w-full px-16 rounded-5 ${size === 'l' ? 'h-56' : 'h-40'} ${
        isCheckable ? 'hover:bg-light-overlay-5 rounded-sm' : ''
      } ${className}`}
    >
      <div className="flex items-center min-w-0">
        {type === 'checkbox' && (
          <div className="mr-16">
            <Checkbox checked={checked} onChange={handleCheckedChange} />
          </div>
        )}
        {type === 'radio' && (
          <div className="mr-8">
            {/* @ts-ignore TODO: fix after refactor of Radio */}
            <Radio checked={checked} onChange={handleCheckedChange} />
          </div>
        )}
        {renderAvatar()}
        <div className="flex flex-col min-w-0 pr-16">
          {renderTitle()}
          {renderDescription()}
        </div>
      </div>
      {renderAfter()}
    </div>
  )
}

export default React.memo(ListItem)
