import { PropComponentType } from '@phase-software/types'
import { notNull } from '@phase-software/data-utils'
import PropertyComponent from './PropertyComponent'

/** @typedef {import('../DataStore').DataStore} DataStore */
/** @typedef {import('./PropertyComponent').AppliedRef} AppliedRef */

export default class OverflowComponent extends PropertyComponent {
    /**
     * @param {DataStore} dataStore
     * @param {ScrolComponentData} [data]
     * @param {object} [options] 
     * @param {bool} [options.regenId=false]   if set to true, will generate new ID
     */
    constructor(dataStore, data = {}, options) {
        super(dataStore, data, options)

        const {
            overflowX,
            overflowY,
            scrollX,
            scrollY,
            scrollBarX,
            scrollBarY
        } = data

        this.componentType = PropComponentType.OVERFLOW

        this.overflowX = notNull(overflowX) ? overflowX : false
        this.overflowY = notNull(overflowY) ? overflowY : false
        this.scrollX = notNull(scrollX) ? scrollX : false
        this.scrollY = notNull(scrollY) ? scrollY : false
        this.scrollBarX = notNull(scrollBarX) ? scrollBarX : false
        this.scrollBarY = notNull(scrollBarY) ? scrollBarY : false

        if (!this.name) {
            this.name = 'overflow'
        }
    }

    /**
     * @param {Partial<ScrollComponentData>} data
     */
    set(data) {
        super.set(data)

        if (notNull(data.overflowX)) {
            this.updateProp('overflowX', data.overflowX)
        }
        if (notNull(data.overflowY)) {
            this.updateProp('overflowY', data.overflowY)
        }
        if (notNull(data.scrollX)) {
            this.updateProp('scrollX', data.scrollX)
        }
        if (notNull(data.scrollY)) {
            this.updateProp('scrollY', data.scrollY)
        }
        if (notNull(data.scrollBarX)) {
            this.updateProp('scrollBarX', data.scrollBarX)
        }
        if (notNull(data.scrollBarY)) {
            this.updateProp('scrollBarY', data.scrollBarY)
        }
    }

    /**
     * Override this in subclasses
     * CALL super._clone() at the top of overriden method
     * @protected
     * @param {AppliedRef} [ref]
     * @returns {ScrollComponent} obj
     */
    _clone(ref) {
        const obj = super._clone(ref)
        obj.overflowX = this.overflowX
        obj.overflowY = this.overflowY
        obj.scrollX = this.scrollX
        obj.scrollY = this.scrollY
        obj.scrollBarX = this.scrollBarX
        obj.scrollBarY = this.scrollBarY
        return obj
    }

    /**
     * Override this in subclasses
     * CALL super._save() at the top of overriden method
     * @protected
     * @returns {ScrollComponentData} data
     */
    _save() {
        const data = super._save()
        data.overflowX = this.overflowX
        data.overflowY = this.overflowY
        data.scrollX = this.scrollX
        data.scrollY = this.scrollY
        data.scrollBarX = this.scrollBarX
        data.scrollBarY = this.scrollBarY
        return data
    }
}

/** @typedef {import('./PropertyComponent').PropertyComponentData} PropertyComponentData */

/**
 * @typedef {PropertyComponentData} OverflowComponentData
 * @property {boolean} overflowX                    false when overflow-x=hidden
 * @property {boolean} overflowY                    false when overflow-y=hidden
 * @property {boolean} scrollX                      true if scrollable in x direction
 * @property {boolean} scrollY                      true if scrollable is y direction
 * @property {boolean} scrollBarX                   true if shows horizontal scrollbars 
 * @property {boolean} scrollBarY                   true if shows vertical scrollbars
 */
