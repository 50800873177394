import React, { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react'

// @ts-ignore
import { TM_PROPS_MAP } from '@phase-software/transition-manager'

import { useTransitionManager } from '../../../providers/TransitionManagerProvider'
import { useDataStore } from '../../../providers/dataStore/DataStoreProvider'
import { PLAYHEAD_WIDTH, TRACK_PADDING_X } from '../constant'
import { SizeContext } from '../contexts'
import { getTimeAtStep } from '../utils'
import { ReactComponent as Head } from './icons/head.svg'

type PlayHeadProps = {
  rulerRef: React.MutableRefObject<HTMLDivElement>
  cacheRef: React.MutableRefObject<{ panel?: string; playHeadPosition: number }>
}

type PlayHeadRef = {
  scrollIntoView: () => void
  reposition: () => void
}

const PlayHead = forwardRef<PlayHeadRef, PlayHeadProps>(({ rulerRef, cacheRef }, ref) => {
  const dataStore = useDataStore()
  const status = useTransitionManager((o: { status: string }) => o.status)
  const playheadTime = useTransitionManager((o: { playheadTime: number }) => o.playheadTime)
  const size = useContext(SizeContext)
  const [displayTime, setDisplayTime] = useState<string | number>(0)

  const playHeadRef = useRef<HTMLDivElement>(null)
  const ratio = (size.scale * size.width) / size.duration

  cacheRef.current.playHeadPosition =
    getTimeAtStep(playheadTime) * ratio + (TRACK_PADDING_X - PLAYHEAD_WIDTH / 2) - (rulerRef.current?.scrollLeft || 0)

  const scrollIntoView = useCallback(() => {
    // FIXME: when animation is playing, the playhead is behind a clicked keyframe the view is incorrect
    if (cacheRef.current.playHeadPosition < 0 || cacheRef.current.playHeadPosition > rulerRef.current?.offsetWidth) {
      cacheRef.current.panel = 'ruler'
      rulerRef.current.scrollLeft += cacheRef.current.playHeadPosition - rulerRef.current.offsetWidth / 2
    }
  }, [cacheRef, rulerRef])

  const reposition = useCallback(() => {
    if (!playHeadRef.current) return
    cacheRef.current.playHeadPosition =
      getTimeAtStep(playheadTime) * ratio + (TRACK_PADDING_X - PLAYHEAD_WIDTH / 2) - rulerRef.current?.scrollLeft
    playHeadRef.current.style.transform = `translateX(${Math.round(cacheRef.current.playHeadPosition)}px)`
    const displayPlayHeadTime = getTimeAtStep(playheadTime) / 1000
    if (displayPlayHeadTime === 0) {
      setDisplayTime(displayPlayHeadTime)
    } else {
      setDisplayTime(displayPlayHeadTime.toFixed(2))
    }
    playHeadRef.current.hidden =
      cacheRef.current.playHeadPosition < 0 || cacheRef.current.playHeadPosition > rulerRef.current?.offsetWidth
  }, [playheadTime, ratio, rulerRef, cacheRef])

  useImperativeHandle(
    ref,
    () => ({
      scrollIntoView,
      reposition
    }),
    [scrollIntoView, reposition]
  )

  useEffect(() => {
    if (dataStore) {
      reposition()
      dataStore.transition.on(TM_PROPS_MAP.time, reposition)
      return () => {
        dataStore.transition.off(TM_PROPS_MAP.time, reposition)
      }
    }
  }, [dataStore, reposition])

  useEffect(() => {
    if (status === 'STOP') {
      scrollIntoView()
    }
  }, [status, scrollIntoView])

  return (
    <div
      ref={playHeadRef}
      hidden
      className="absolute pointer-events-none pt-playhead will-change-transform"
      style={{
        transform: `translateX(${Math.round(cacheRef.current.playHeadPosition)}px)`
      }}
    >
      <div className="absolute h-20 px-6 py-2 top-[-30px] left-1/2 rounded-md bg-light-gray font-medium text-12 text-neutral transform -translate-x-1/2">
        {displayTime}
      </div>
      <Head />
      <div className="mx-auto -my-1 w-1 bg-light-gray" style={{ height: window.innerHeight * 2 + 'px' }}></div>
    </div>
  )
})

PlayHead.displayName = 'PlayHead'

export default PlayHead
