import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { MIX_VALUE } from '@phase-software/data-utils'
import { ImageMode } from '@phase-software/types'

import { ALLOWED_LAYER_IMAGE_TYPE_SET, IMAGE_MODE_OPTIONS } from '../../../constant'
import useEditorImage from '../../../hooks/useEditorImage'
import { useEditorActions } from '../../../providers/dataStore/EditorProvider'
import { usePaint } from '../../../providers/dataStore/PaintProvider'
import FillOpacity from '../../Properties/PropertyEditors/FillEditor/FillOpacity'
import { Select } from '../../shared'
import { translateMenuOptions } from '../../shared/Menu/utils'

type ImagePaintPickerProps = {
  layerItemId: string
}
const accept = Array.from(ALLOWED_LAYER_IMAGE_TYPE_SET).join(',')

const defaultImage = (
  <svg width="216" height="160" viewBox="0 0 216 160" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_9912_30001)">
      <rect x="-4" width="32" height="32" fill="black" fillOpacity="0.2" />
      <rect x="60" width="32" height="32" fill="black" fillOpacity="0.2" />
      <rect x="124" width="32" height="32" fill="black" fillOpacity="0.2" />
      <rect x="188" width="32" height="32" fill="black" fillOpacity="0.2" />
      <rect x="28" y="32" width="32" height="32" fill="black" fillOpacity="0.2" />
      <rect x="92" y="32" width="32" height="32" fill="black" fillOpacity="0.2" />
      <rect x="156" y="32" width="32" height="32" fill="black" fillOpacity="0.2" />
      <rect x="-4" y="64" width="32" height="32" fill="black" fillOpacity="0.2" />
      <rect x="60" y="64" width="32" height="32" fill="black" fillOpacity="0.2" />
      <rect x="124" y="64" width="32" height="32" fill="black" fillOpacity="0.2" />
      <rect x="188" y="64" width="32" height="32" fill="black" fillOpacity="0.2" />
      <rect x="28" y="96" width="32" height="32" fill="black" fillOpacity="0.2" />
      <rect x="92" y="96" width="32" height="32" fill="black" fillOpacity="0.2" />
      <rect x="156" y="96" width="32" height="32" fill="black" fillOpacity="0.2" />
      <rect x="-4" y="128" width="32" height="32" fill="black" fillOpacity="0.2" />
      <rect x="60" y="128" width="32" height="32" fill="black" fillOpacity="0.2" />
      <rect x="124" y="128" width="32" height="32" fill="black" fillOpacity="0.2" />
      <rect x="188" y="128" width="32" height="32" fill="black" fillOpacity="0.2" />
    </g>
    <defs>
      <clipPath id="clip0_9912_30001">
        <rect width="216" height="160" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

const ImagePaintPicker = ({ layerItemId }: ImagePaintPickerProps) => {
  const paint = usePaint((o) => o[layerItemId])
  const { setLayerPaint } = useEditorActions()
  const { uploadAndCreateImage, getImageUrl } = useEditorImage()
  const { t } = useTranslation('file', { keyPrefix: 'property_editor.fill_editor.image_paint_picker' })

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist()
    if (!e.target.files || !e.target.files.length) {
      return
    }
    const file = e.target.files[0]
    const image = await uploadAndCreateImage({ name: file.name, content: file })
    setLayerPaint(layerItemId, { imageId: image.data.id, imageMode: paint.imageMode })
    e.target.value = ''
    e.target.blur()
  }
  const handleImageModeChange = (imageMode: ImageMode) => {
    setLayerPaint(layerItemId, { imageMode, imageId: paint.imageId })
  }
  const url = getImageUrl(paint.imageId)
  const effect = paint.imageId ? 'group-hover:bg-dark-overlay-60' : 'group-hover:bg-dark-overlay-20'

  const translatedOptions = useMemo(() => {
    return translateMenuOptions(t, IMAGE_MODE_OPTIONS, {
      ns: 'file',
      keyPrefix: 'image_mode'
    })
  }, [t])

  return (
    <div className="flex flex-col gap-8">
      <label className="flex items-center cursor-pointer justify-center w-[216px] h-[160px] group relative rounded-md overflow-hidden bg-light-overlay-5 ">
        {paint.imageId ? (
          <img src={url} className="max-w-full max-h-full w-full h-full object-contain" />
        ) : (
          defaultImage
        )}
        <div
          className={`opacity-0 group-hover:opacity-100 ${effect} absolute pointer-events-none inset-0 flex items-center justify-center font-medium text-light-overlay-60 group-hover:text-white group-hover:bg-dark-overlay-20 duration-200 transition-colors transition-opacity`}
        >
          {t('upload_image')}
        </div>
        <input
          className="absolute opacity-0 inset-0 pointer-events-none"
          type="file"
          accept={accept}
          onChange={handleChange}
        />
      </label>
      <div className="flex justify-between gap-8">
        <Select
          // @ts-ignore TODO: fix after refactor of Select
          caret={true}
          variant="normal"
          options={translatedOptions}
          mixed={paint.imageMode === MIX_VALUE}
          value={paint.imageMode}
          data-test-id="colorpicker-image-mode-select"
          onChange={handleImageModeChange}
        />
        <FillOpacity layerItemId={layerItemId} />
      </div>
    </div>
  )
}

export default ImagePaintPicker
