import React, { useMemo } from 'react'
import { Input } from '../../../shared'
import { parseToDecimals } from '../../../../utils/number'
import { useDataStoreActions } from '../../../../providers/dataStore/DataStoreProvider'
import { usePaint } from '../../../../providers/dataStore/PaintProvider'
import { useEditorActions } from '../../../../providers/dataStore/EditorProvider'
import { percentageFormatValidator } from '../../../../utils/validator'
import { InputOptions } from '../../../shared/Input/Input.types'

type FillOpacityProps = {
  layerItemId: string
  showIndicator?: boolean
}

const FillOpacity = ({ layerItemId, showIndicator = false }: FillOpacityProps) => {
  const opacity = usePaint((o) => o[layerItemId].opacity)
  const { commitUndo, debounceCommitUndo } = useDataStoreActions()
  const { setLayerPaint, toggleLayerVisible } = useEditorActions()
  const _opacity = isNaN(opacity) ? opacity : parseToDecimals(opacity * 100, 2)
  const keyFrameIconProps = useMemo(
    () =>
      showIndicator
        ? {
            name: 'opacity',
            layerItemId
          }
        : undefined,
    [layerItemId, showIndicator]
  )

  return (
    <Input
      // @ts-ignore TODO: fix after refactor of Input
      keyFrameIconProps={keyFrameIconProps}
      min={0}
      max={100}
      data-test-id="fill-opacity-input"
      type="number"
      variant="normal"
      formatter={(v: number) => `${parseToDecimals(v, 2)}%`}
      validator={percentageFormatValidator}
      value={_opacity}
      onChange={(v: number, options: InputOptions) => setLayerPaint(layerItemId, { opacity: v / 100 }, options)}
      onFocus={() => toggleLayerVisible(layerItemId, true)}
      onBlur={commitUndo}
      onStepChange={debounceCommitUndo}
      spinner
    />
  )
}

export default FillOpacity
