window.dataLayer = window.dataLayer || []

const googleAnalytics = (...args) => {
  window.dataLayer.push(args)
}

googleAnalytics({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })

export const initGoogleAnalytics = ({ token }) => {
  if (!token) {
    return
  }
  const script = document.createElement('script')
  script.async = true
  script.src = `https://www.googletagmanager.com/gtm.js?id=${token}`

  const firstScript = document.getElementsByTagName('script')[0]
  firstScript.parentNode.insertBefore(script, firstScript)
}

export default googleAnalytics
