import { TutorialType } from '../constant'
import tip26 from '../assets/2.6.gif'
export default {
  key: TutorialType.INDICATOR,
  mask: false,
  progress: true,
  interact: false,
  steps: [
    {
      header: 'Keyframe Indicator',
      content: 'You can also add this current value as a Keyframe by clicking on the indicator.',
      anchor: '.pt-kf-hover-indicator',
      image: tip26,
      placement: 'left'
    }
  ]
}
