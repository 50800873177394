import { IRSceneTree } from './IRSceneTree'
import { IRImageAsset } from './IRImageAsset'
import { IRPrecompositionAsset } from './IRPrecompositionAsset'
import { IRAsset } from './IRAsset'
import { IRAssetType } from './constants'
import { resetId } from './utils'

export class IRAnimation {
  static defaultValue = {
    frameRate: 60,
    inTime: 0,
    outTime: 1000,
    width: 500,
    height: 500,
    assets: []
  }

  constructor(data = {}) {
    const mergedData = { ...IRAnimation.defaultValue, ...data }

    resetId()
    this.frameRate = mergedData.frameRate
    this.inTime = mergedData.inTime
    this.outTime = mergedData.outTime
    this.width = mergedData.width
    this.height = mergedData.height
    this.assets = []
    this.sceneTree = new IRSceneTree()
  }

  get inPoint() {
    return Math.round((this.inTime / 1000) * this.frameRate)
  }

  get outPoint() {
    return Math.round((this.outTime / 1000) * this.frameRate)
  }

  addAsset(asset) {
    const existAsset = this.assets.find(a => a.id === asset.id)
    if (!existAsset) {
      this.assets.push(asset)
    }
  }

  fromJSON(data) {
    this.frameRate = data.frameRate
    this.inTime = data.inTime
    this.outTime = data.outTime
    this.width = data.width
    this.height = data.height
    this.assets = data.assets.map(assetData => {
      let AssetClass
      switch (assetData.type) {
        case IRAssetType.IMAGE:
          AssetClass = IRImageAsset
          break
        case IRAssetType.PRECOMPOSITION:
          AssetClass = IRPrecompositionAsset
          break
        default:
          AssetClass = IRAsset
          break
      }
      return new AssetClass().fromJSON(assetData)
    })
    this.sceneTree.fromJSON(data.sceneTree)
    return this
  }

  toJSON() {
    return {
      frameRate: this.frameRate,
      inTime: this.inTime,
      outTime: this.outTime,
      width: this.width,
      height: this.height,
      assets: this.assets.map(asset => asset.toJSON()),
      sceneTree: this.sceneTree.toJSON()
    }
  }
}
