export const getTransitionData = (transition) => {
  return transition.gets(
    'animation',
    'easing',
    'customEasing',
    'delay',
    'disabled',
    'duration',
    'folded',
    'id',
    'type',
    'name',
    'styleTrackId'
  )
}
