import React, { forwardRef } from 'react'

import { TemplateCard } from '../../../components/shared'
import { useSetModal } from '../../../providers/ModalProvider'
import TemplateDropdownMenu from './TemplateDropdownMenu'

type TemplateListItemProps = {
  templateId: string
  name?: string | null
  imageUrl?: string | null
  templateUrl: string
  author?: string | null
  authorUrl?: string | null
}

const TemplateListItem = forwardRef<HTMLDivElement, TemplateListItemProps>(
  ({ templateId, name, imageUrl, templateUrl, author, authorUrl }, forwardedRef) => {
    const { openModal } = useSetModal(`TemplateDropdownMenu-${templateId}`)

    const openDropdown = ({ trigger }: { trigger?: React.RefObject<HTMLElement> }) => {
      openModal({ trigger })
    }

    return (
      <>
        <TemplateCard
          name={name ?? ''}
          imageUrl={imageUrl ?? undefined}
          templateUrl={templateUrl}
          author={author ?? ''}
          authorUrl={authorUrl ?? undefined}
          onDropdownMenu={openDropdown}
          ref={forwardedRef}
        />
        <TemplateDropdownMenu templateId={templateId} />
      </>
    )
  }
)

TemplateListItem.displayName = 'TemplateListItem'

export default React.memo(TemplateListItem)
