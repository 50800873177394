import React from 'react'

import { useInspectPanelSectionState, useRightPanelContext } from '../../../providers/RightPanelProvider'
import InspectContent, { InspectContentProps } from './InspectContent'

type InteractiveInspectContentProps = InspectContentProps & {
  target: string
  handleCopy?: (target: string) => void
}

const InteractiveInspectContent = ({ target, handleCopy, ...props }: InteractiveInspectContentProps) => {
  const { toggleInspectPanelSection } = useRightPanelContext()
  const isPanelOpen = useInspectPanelSectionState(target)
  const icons = handleCopy
    ? [
        {
          name: 'Copy',
          onClick: () => handleCopy(target)
        },
        {
          name: isPanelOpen ? 'KeyboardArrowDown' : 'KeyboardArrowRight',
          onClick: () => toggleInspectPanelSection(target)
        }
      ]
    : [
        {
          name: isPanelOpen ? 'KeyboardArrowDown' : 'KeyboardArrowRight',
          onClick: () => toggleInspectPanelSection(target)
        }
      ]

  return (
    <InspectContent {...props} icons={icons} showChildren={isPanelOpen}>
      {props.children}
    </InspectContent>
  )
}

export default InteractiveInspectContent
