import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { EditMode } from '@phase-software/types'

import {
  COMMENT_OPTION,
  GENERAL_SCALE_OPTION,
  GENERAL_SELECT_OPTION,
  HAND_OPTION,
  INSERT_OPTION,
  PEN_OPTION,
  SHAPE_OPTIONS,
  TABLE_OPTION,
  ADD_ACTION_OPTION,
  UNREAD_COMMENT_OPTION
} from '../../constants/fileEditorConstants'
import useEditorImage from '../../hooks/useEditorImage'
import { useCommentContext } from '../../providers/CommentProvider'
import { useSetTutorial } from '../../providers/TutorialProvider'
import { useTool, useToolActions } from '../../providers/dataStore/ToolProvider'
import { useUI } from '../../providers/dataStore/UIProvider'
import { TutorialType } from '../../tutorials'
import { ToolButton } from '../shared'
import { isSeparatorOption } from '../shared/Menu/utils'
import { ToolbarProps } from '../shared/ToolButton'
import { useDataStore } from '../../providers/dataStore/DataStoreProvider'
import { useInteractionActions } from '../../providers/dataStore/InteractionProvider'
import { PropertyInspectionList } from '../Interaction/constant'

export type PrimaryToolProps = {
  tool: ToolbarProps['value']
  onSelect: ToolbarProps['onChange']
}

type GeneralToolProps = {
  isPathEditMode: boolean
} & PrimaryToolProps

const GeneralTool = ({ isPathEditMode, tool, onSelect }: GeneralToolProps) => {
  const { startTutorial, markTutorialAsCompleted } = useSetTutorial()
  const { t } = useTranslation('file', { keyPrefix: 'tools' })
  const options = isPathEditMode ? [GENERAL_SELECT_OPTION] : [GENERAL_SELECT_OPTION, GENERAL_SCALE_OPTION]
  const translatedOptions = options.map((option) => {
    if (isSeparatorOption(option)) return option
    return { ...option, name: t(option.name ?? '') }
  })

  const handleMenuChange = (open: boolean) => {
    if (open) {
      startTutorial(TutorialType.TOOL_MENU)
    } else {
      markTutorialAsCompleted(TutorialType.TOOL_MENU)
    }
  }

  return (
    <ToolButton
      data-test-id="tool-menu"
      className="pt-general-tool"
      menuClassName="pt-general-tool-menu"
      options={translatedOptions}
      onChange={onSelect}
      value={tool}
      showArrowIcon={!isPathEditMode}
      onOpenChange={handleMenuChange}
    />
  )
}

const ShapeTools = ({ tool, onSelect }: PrimaryToolProps) => {
  const { t } = useTranslation('file', { keyPrefix: 'tools' })
  const translatedShareOptions = useMemo(
    () =>
      SHAPE_OPTIONS.map((option) => {
        return { ...option, name: t(option.name ?? '') }
      }),
    [t]
  )
  return <ToolButton options={translatedShareOptions} onChange={onSelect} value={tool} data-test-id="object-menu" />
}

const PenTool = ({ tool, onSelect }: PrimaryToolProps) => {
  const { t } = useTranslation('file', { keyPrefix: 'tools' })
  const translatedPenOption = useMemo(() => {
    return {
      ...PEN_OPTION,
      name: t(PEN_OPTION.name ?? '')
    }
  }, [t])

  return (
    <ToolButton
      options={[translatedPenOption]}
      onChange={onSelect}
      value={tool}
      showArrowIcon={false}
      data-test-id="path-tool"
    />
  )
}

export const HandTool = ({ tool, onSelect }: PrimaryToolProps) => {
  const { t } = useTranslation('file', { keyPrefix: 'tools' })
  const translatedHandOption = useMemo(() => {
    return {
      ...HAND_OPTION,
      name: t(HAND_OPTION.name ?? '')
    }
  }, [t])

  return (
    <ToolButton
      options={[translatedHandOption]}
      onChange={onSelect}
      value={tool}
      showArrowIcon={false}
      data-test-id="hand-tool"
    />
  )
}

export const CommentTool = ({ tool, onSelect }: PrimaryToolProps) => {
  const { hasUnread } = useCommentContext()
  const { t } = useTranslation('file', { keyPrefix: 'tools' })
  const commentOption = hasUnread ? UNREAD_COMMENT_OPTION : COMMENT_OPTION
  const testId = hasUnread ? 'unread-comment-tool' : 'comment-tool'
  const translatedCommentOption = useMemo(() => {
    return {
      ...commentOption,
      name: t(commentOption.name ?? '')
    }
  }, [t, commentOption])

  return (
    <ToolButton
      key={testId}
      options={[translatedCommentOption]}
      onChange={onSelect}
      value={tool}
      showArrowIcon={false}
      data-test-id={testId}
    />
  )
}

export const InsertTool = ({ tool, onSelect }: PrimaryToolProps) => {
  const { t } = useTranslation('file', { keyPrefix: 'tools' })
  const translatedInsertOption = useMemo(() => {
    return {
      ...INSERT_OPTION,
      name: t(INSERT_OPTION.name ?? '')
    }
  }, [t])

  return (
    <ToolButton
      options={[translatedInsertOption]}
      onChange={onSelect}
      value={tool}
      showArrowIcon={false}
      data-test-id="insert-tool"
    />
  )
}

export const TableTool = ({ tool, onSelect }: PrimaryToolProps) => {
  const { t } = useTranslation('file', { keyPrefix: 'tools' })
  const translatedOption = useMemo(() => {
    return {
      ...TABLE_OPTION,
      name: t(TABLE_OPTION.name ?? '')
    }
  }, [t])

  return (
    <ToolButton
      options={[translatedOption]}
      onChange={onSelect}
      value={tool}
      showArrowIcon={false}
      data-test-id="table-tool"
    />
  )
}

export const PlusActionTool = ({ tool, onSelect }: PrimaryToolProps) => {
  const { t } = useTranslation('file', { keyPrefix: 'tools' })
  const translatedOption = useMemo(() => {
    return {
      ...ADD_ACTION_OPTION,
      name: t(ADD_ACTION_OPTION.name ?? '')
    }
  }, [t])

  return (
    <ToolButton
      options={[translatedOption]}
      onChange={onSelect}
      value={tool}
      showArrowIcon={false}
      data-test-id="plus-action-tool"
    />
  )
}

const PrimaryTools = () => {
  const { isEditingState, editMode, isVersioningState, isInspectingState, isTablingState } = useUI()
  const { activeTool } = useTool()
  const { setActiveTool } = useToolActions()
  const { insertImage } = useEditorImage()
  const isPathEditMode = editMode === EditMode.SHAPE
  const dataStore = useDataStore()
  const {
    setActionSelection, setActiveTableElementSelection,
    
  } = useInteractionActions()

  const selectImage = useCallback(() => {
    insertImage({ multiple: true })
  }, [insertImage])

  const switchToTable = useCallback(() => {
    console.log('switch table state')
    if (dataStore.isTablingState) {
      dataStore.switchState('EDITING')
      dataStore.emit('LEAVE_TABLE')

      setActiveTableElementSelection(null)

      dataStore.eam.switchMode(1)
      dataStore.eam.switchMode(0)
      dataStore.eam.switchMode(1)
    } else {
      const activeElement = dataStore.selection.get('elements')[0]
      if (!activeElement) return

      setActiveTableElementSelection(activeElement.get('id'))

      dataStore.switchState('TABLING')
      dataStore.emit('GO_TABLE')

      const actionList = dataStore.interaction.getActionList(activeElement)
      calcVersionsAtBackground(actionList)
      
      let _dirty = false
      for (const elId of dataStore.traverseSubtree(activeElement.get('id'), true, true)) {
        const element = dataStore.getElement(elId)
        element.on('CHANGES', (data: Map<string, Object>, changesFromStyle: any) => {
          console.log('element changes', data, changesFromStyle)
          if (changesFromStyle && changesFromStyle.flags) return

          let needUpdateCount = 0
          data.forEach((_, key) => { if (PropertyInspectionList.indexOf(key) !== -1) needUpdateCount++ })
          if (needUpdateCount === 0) return

          if (dataStore.isDesignMode) {
            _dirty = true
          }
        })
        const style = element.get('computedStyle')
        style.on('LAYER_LIST_CHANGES', (changes: any, ctrl: any) => {
          console.log('LAYER_LIST_CHANGES', changes, ctrl)
          if (ctrl && (ctrl.flags)) return

          if (dataStore.isDesignMode) {
            _dirty = true
          }
        })
        for (const fill of style.fills) {
          fill.on('CHANGES', (changes: any, ctrl: any) => {
            console.log('fill changes', changes, ctrl)
            if (ctrl && (ctrl.flags)) return

            if (dataStore.isDesignMode) {
              _dirty = true
            }
          })
        }
        for (const stroke of style.strokes) {
          stroke.on('CHANGES', (changes: any, ctrl: any) => {
            console.log('stroke changes', changes, ctrl)
            if (ctrl && (ctrl.flags)) return

            if (dataStore.isDesignMode) {
              _dirty = true
            }
          })
        }
      }

      const updateActionsIfDirty = () => {
        if (_dirty) {
          console.log('** update actions **')
          _dirty = false
          const id = dataStore.selection.get('activeTableElement')
          const actionList = dataStore.interaction.getActionList(id)
          calcVersionsAtBackground(actionList)
        }
        window.requestAnimationFrame(updateActionsIfDirty)
      }
      updateActionsIfDirty()
    }
  }, [dataStore])

  const calcAction = (actionId: string | null) => {
    const { interaction, transition, selection } = dataStore

    // selection.data.action = actionId

    // const id = selection.get('activeTableElement')
    // for (const elId of dataStore.traverseSubtree(id, true, true)) {
    //   const el = dataStore.getElement(elId)
    //   // el.computedStyle._cachedComputedLayers = new Map()
    //   el.computedStyle.reload(true)
    //   // el.computedStyle.load({ element: el})
    // }

    const id = selection.get('activeTableElement')
    for (const elId of dataStore.traverseSubtree(id, true, true)) {
      const el = dataStore.getElement(elId)
      // el.computedStyle._cachedComputedLayers = new Map()
      el.computedStyle._unbindBaseAndComponentChangeListeners()
      // el.computedStyle.load({ element: el})
    }
    selection.data.action = actionId
    for (const elId of dataStore.traverseSubtree(id, true, true)) {
      const el = dataStore.getElement(elId)
      // el.computedStyle._cachedComputedLayers = new Map()
      el.computedStyle._applyBaseProps()
      el.computedStyle._bindBaseAndComponentChangeListeners()
      // el.computedStyle.forceFireLayerListChanges()
      // el.computedStyle.load({ element: el})
    }

    interaction._cacheKeyframeGroupByTime = new WeakMap()
    transition.clear()
    transition._initInteraction()
    transition.prepareActionTransition()

    if (actionId !== null) {
      const action = interaction.getAction(actionId)
      transition.setPlayheadTime(action.maxTime, true)
    }

    const updateList = [...dataStore.drawInfo.vs.updateList.values()]
    dataStore.drawInfo.vs.indexer.updateNodes(updateList)
    dataStore.drawInfo.vs.updateList.clear()
  }

  const calcVersionsAtBackground = async (actionIds: string | any[]) => {
    console.log('calcVersionsAtBackground')
    dataStore.selection.data.updatingVersions = true
    const cacheAction = dataStore.selection.data.action
    for (let i = 0; i < actionIds.length; i++) {
      const actionId = actionIds[i]
      calcAction(actionId)
    }
    calcAction(cacheAction)
    dataStore.selection.data.updatingVersions = false
  }

  const addAction = useCallback(() => {
    console.log('add action')
    const { interaction, transition, selection } = dataStore
    if (!interaction || !transition || !selection) return

    // add action/response
    const elId = selection.get('activeTableElement')
    const actionId = interaction.addAction()
    interaction.addResponse(actionId)
    interaction.setActionElement(actionId, elId)

    dataStore.emit('ACTION_LIST_CHANGE')

    // set active action selection
    switchToNewAction(actionId)
  }, [dataStore, setActionSelection])

  const switchToNewAction = useCallback(async (id: string | null) => {
    console.log('switch to new action', id)

    const prevActionId = dataStore.selection.get('action')
    if (prevActionId !== null) {
      const action = dataStore.interaction.getAction(prevActionId)
      dataStore.transition.setPlayheadTime(action.maxTime, true)
    }

    setActionSelection(id)

    // dataStore.eam.switchMode(1)
    // dataStore.eam.switchMode(0)
    dataStore.eam.switchMode(id !== null ? 1 : 0)

    calcAction(id)

    // const selectedElements = dataStore.selection.get('elements')
    // dataStore.selection.selectElements([])
    // if (selectedElements.length !== 0) {
    //   const activeId = dataStore.selection.get('activeTableElement')
    //   dataStore.selection.selectElements([dataStore.getElement(activeId)])
    // }
  }, [dataStore, setActionSelection])

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (dataStore.isTablingState) {
        if (e.key === 'z') {
          const element = dataStore.selection.get('activeTableElement')
          const actionList = dataStore.interaction.getActionList(element)
          calcVersionsAtBackground(actionList)
        }

        if (e.key === 'x') {
          const currentMode = dataStore.get('mode')
          dataStore.eam.switchMode(1)
          dataStore.eam.switchMode(0)
          dataStore.eam.switchMode(currentMode)
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [setActionSelection])

  return (
    <div className="pt-top-tool">
      {(isEditingState || isTablingState) && (
        <>
          <GeneralTool key="general" tool={activeTool} onSelect={setActiveTool} isPathEditMode={isPathEditMode} />
          {!isPathEditMode && <ShapeTools key="creation" tool={activeTool} onSelect={setActiveTool} />}
          <PenTool key="pen" tool={activeTool} onSelect={setActiveTool} />
          <InsertTool key="insert" tool={activeTool} onSelect={selectImage} />
        </>
      )}
      {isInspectingState && <GeneralTool key="general" tool={activeTool} onSelect={setActiveTool} isPathEditMode />}

      <HandTool key="hand" tool={activeTool} onSelect={setActiveTool} />
      {!isPathEditMode && !isVersioningState && (
        <CommentTool key="comment" tool={activeTool} onSelect={setActiveTool} />
      )}
      {(!dataStore.isTablingState && dataStore.selection.get('elements')[0]) && <TableTool key="table" tool={activeTool} onSelect={switchToTable} />}
      {dataStore.isTablingState && <PlusActionTool key="plus" tool={activeTool} onSelect={addAction} />}
      
      {dataStore.isTablingState && (
        <ToolButton
          options={[{ name: 'design' }]}
          onChange={e => switchToNewAction(null)}
        />
      )}  
      {dataStore.isTablingState && dataStore.interaction.getActionList(dataStore.selection.get('activeTableElement')).map((id : string) => (
        <ToolButton
          key={id}
          options={[{ name: id }]}
          onChange={e => switchToNewAction(id)}
        />
      ))}
    </div>
  )
}

export default PrimaryTools


const waitFrame = () => new Promise(resolve => requestAnimationFrame(resolve))
