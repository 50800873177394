import React, { useState, useRef, useEffect } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { color } from '../utils'
import Text from '../Text'

const TooltipWrapper = styled.div`
  position: fixed;
  opacity: ${(props) => (props.open ? 1 : 0)};
  padding: 2px 8px;
  border-radius: 3px;
  background: ${color('lightOverlays', 90)};
  color: ${color('neutrals', 90)};
  white-space: nowrap;
  pointer-event: none;
  will-change: opacity, left, top;
`

TooltipWrapper.propTypes = {
  text: PropTypes.string
}

const Tooltip = React.forwardRef((props, ref) =>
  ReactDOM.createPortal(<TooltipWrapper ref={ref} {...props} />, document.querySelector('#modal'))
)
Tooltip.displayName = 'Tooltip'

const Wrapper = styled.div`
  display: inline-block;
  max-width: 100%;
  cursor: pointer;
  border-radius: 2px;
  padding: 2px 6px;
  &:hover {
    background: ${color('lightOverlays', 10)};
  }
  transform: translateX(2px);
`
const ClipText = ({ children, ...rest }) => {
  const [isTextOverflowing, setIsTextOverflowing] = useState(false)
  const [copied, setCopied] = useState(false)
  const [hover, setHover] = useState(false)

  const ref = useRef()
  const tooltipRef = useRef()

  const handleCopy = (e) => {
    const { pageX, pageY } = e
    navigator.clipboard
      .writeText(children)
      .then(() => {
        setCopied(true)
        Object.assign(tooltipRef.current.style, {
          top: `${pageY}px`,
          left: `${pageX + 14}px`
        })
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err)
      })
  }

  const handleMouseMove = (e) => {
    const el = tooltipRef.current
    if (!el) return
    Object.assign(el.style, {
      top: `${e.pageY}px`,
      left: `${e.pageX + 14}px`
    })
  }

  const handleMouseEnter = () => setHover(true)
  const handleMouseLeave = () => {
    setHover(false)
    setCopied(false)
  }

  useEffect(() => {
    setIsTextOverflowing(ref.current?.scrollWidth > ref.current?.offsetWidth)
  }, [])
  return (
    <Wrapper
      onClick={handleCopy}
      onMouseMove={handleMouseMove}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Text ref={ref} {...rest}>
        {children}
      </Text>
      {(isTextOverflowing || copied) && (
        <Tooltip ref={tooltipRef} open={hover}>
          {copied ? 'Copied!' : children}
        </Tooltip>
      )}
    </Wrapper>
  )
}

ClipText.propTypes = {
  children: PropTypes.node
}

export default React.memo(ClipText)
