import React, { RefObject, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useSetModal } from '../../../../providers/ModalProvider'
import { Tooltip } from '../../../shared'
import SolidButton from '../../../shared/Button'
import StrokeAdvancedModal from './StrokeAdvancedModal'

type StrokeAdvancedMenuProps = {
  layerItemId: string
  menuRef: RefObject<HTMLButtonElement>
}

const StrokeAdvancedMenu = ({ layerItemId, menuRef }: StrokeAdvancedMenuProps) => {
  const { toggleModal, updateModalData, deleteModal } = useSetModal(`StrokeAdvancedModal-${layerItemId}`)
  const { t } = useTranslation('file', { keyPrefix: 'property_editor.stroke_editor' })

  const handleClick = useCallback(() => {
    toggleModal({ layerItemId, trigger: menuRef })
  }, [layerItemId, menuRef, toggleModal])

  useEffect(() => {
    updateModalData({ layerItemId })
  }, [layerItemId, updateModalData, deleteModal])
  useEffect(() => {
    return () => {
      deleteModal()
    }
  }, [layerItemId, deleteModal])

  return (
    <>
      <Tooltip content={t('advanced_menu_modal_title')}>
        <SolidButton
          data-test-id="border-advanced-menu-button"
          size="s"
          color="secondary"
          ref={menuRef}
          icon="More"
          onClick={handleClick}
        />
      </Tooltip>
      <StrokeAdvancedModal title={t('advanced_menu_modal_title')} layerItemId={layerItemId} />
    </>
  )
}

export default StrokeAdvancedMenu
