import React, { useMemo } from 'react'
import { EffectTrimPathItem } from '../types'
import { Slider, Input } from '../../../../shared'
import { percentageFormatValidator } from '../../../../../utils/validator'
import { formatAsPercentage } from '../../../../../utils/formatter'
import { useEditorActions } from '../../../../../providers/dataStore/EditorProvider'
import { useDataStoreActions } from '../../../../../providers/dataStore/DataStoreProvider'

type TrimPathEndProps = {
  id: string
  value: EffectTrimPathItem['end']
}

export default function TrimPathEnd({ id, value = 0 }: TrimPathEndProps) {
  const { setEffectProps } = useEditorActions()
  const { commitUndo, debounceCommitUndo } = useDataStoreActions()

  const handleInputChange = (
    updatedValue: EffectTrimPathItem['end'],
    options: { commit: boolean; delta: number | undefined }
  ) => {
    setEffectProps(id, { end: updatedValue }, options)
  }

  const handleSliderChange = (updatedValue: EffectTrimPathItem['end'], commitUndo: boolean) => {
    setEffectProps(id, { end: updatedValue }, { commit: !!commitUndo })
  }

  const keyFrameIconProps = useMemo(
    () => ({
      name: 'end',
      effectItemId: id,
      effectType: 'trimPath'
    }),
    [id]
  )

  return (
    <>
      <div className="w-64">
        <Slider
          value={Math.round(value)}
          variant="solid"
          min={0}
          max={100}
          onChange={handleSliderChange}
          onBlur={commitUndo}
        />
      </div>
      <div className="flex-1">
        <Input
          // @ts-ignore TODO: fix after refactor of Input
          variant="normal"
          type="number"
          value={Math.round(value)}
          validator={percentageFormatValidator}
          onStepChange={debounceCommitUndo}
          onBlur={commitUndo}
          formatter={formatAsPercentage}
          min={0}
          max={100}
          onChange={handleInputChange}
          keyFrameIconProps={keyFrameIconProps}
          spinner
        />
      </div>
    </>
  )
}
