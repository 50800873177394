import React, { createContext, useContext } from 'react'
import Broadcaster from './Broadcaster'

type BroadcasterProviderProps = {
  broadcaster: Broadcaster
  children: React.ReactNode
}

export const BroadcasterContext = createContext<Broadcaster | null>(null)

export const BroadcasterProvider = ({ broadcaster, children }: BroadcasterProviderProps) => {
  return <BroadcasterContext.Provider value={broadcaster}>{children}</BroadcasterContext.Provider>
}

export const useBroadcaster = () => useContext(BroadcasterContext)
