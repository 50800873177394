import React, { useMemo } from 'react'

import PropTypes from 'prop-types'

import useEditorImage from '../../hooks/useEditorImage'
import { usePaint } from '../../providers/dataStore/PaintProvider'
import ColorBox from '../shared/ColorBox'

const ColorPreview = ({ layerItemId, size = 'l' }) => {
  const { getImageUrl } = useEditorImage()
  const { color, paintType, gradientStops, opacity, imageId } = usePaint((o) => o[layerItemId])
  const imageUrl = getImageUrl(imageId)
  const c = useMemo(() => color?.slice(0, 3).concat(opacity), [color, opacity])
  return (
    <div className="p-2 rounded-lg bg-light-overlay-5">
      <ColorBox color={c} paintType={paintType} gradientStops={gradientStops} imageUrl={imageUrl} size={size} />
    </div>
  )
}
ColorPreview.propTypes = {
  layerItemId: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['s', 'm', 'l'])
}

export default React.memo(ColorPreview)
