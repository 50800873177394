import { pick } from 'lodash'

export const getScrollData = (scroll) => {
  return pick(scroll, [
    'id',
    'paddingBottom',
    'paddingLeft',
    'paddingRight',
    'paddingTop',
    'scrollHeight',
    'scrollWidth'
  ])
}
