import React, { useCallback, useLayoutEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { MIX_VALUE, MIX_VALUE_ID, arrEquals } from '@phase-software/data-utils'
import { EasingType } from '@phase-software/types'

import { useInteraction, useInteractionActions } from '../../../providers/dataStore/InteractionProvider'
import { parseToDecimals } from '../../../utils/number'
import { bezierValidator } from '../../../utils/validator'
import { MixInput, ScrollView, ToggleButton, Tooltip } from '../../shared'
import { ValueType } from '../../shared/MixInput'
import { COMMON_EASING_TYPE_OPTIONS } from '../constant'
import EasingEditor, { BezierCurve } from './EasingEditor'
import EasingTypeSelect from './EasingTypeSelect'

export type EasingPanelProps = {
  keyframeIdList: string[]
}

const EasingPanel = ({ keyframeIdList }: EasingPanelProps) => {
  const scrollViewRef = useRef<HTMLDivElement>(null)
  const { entityMap } = useInteraction()
  const { setSelectedKeyFrameEasingType, setSelectedKeyFrameBezier } = useInteractionActions()
  const { t } = useTranslation('file')

  const keyframeList = keyframeIdList
    .map((keyframeId: string) => entityMap.get(keyframeId))
    .filter((keyframe) => keyframe)

  const {
    easingType,
    bezier = [0, 0, 0, 0],
    animatable
  } = keyframeList.slice(1).reduce(
    (currentKeyframe, nextKeyframe) => {
      if (
        currentKeyframe.easingType !== nextKeyframe.easingType ||
        (currentKeyframe.easingType === EasingType.CUSTOM && !arrEquals(currentKeyframe.bezier, nextKeyframe.bezier))
      ) {
        currentKeyframe.easingType = MIX_VALUE_ID
      }
      currentKeyframe.animatable ||= nextKeyframe.animatable
      return currentKeyframe
    },
    { ...keyframeList[0] }
  ) || {}

  const isMixed = easingType === MIX_VALUE_ID
  const isStepEnd = easingType === EasingType.STEP_END

  const onEasingTypeChange = useCallback(
    (easingType: string | EasingType) => {
      setSelectedKeyFrameEasingType(Number(easingType))
    },
    [setSelectedKeyFrameEasingType]
  )
  const onBezierChange = useCallback(
    (bezier: BezierCurve, commit: boolean) => {
      setSelectedKeyFrameBezier(bezier, commit)
    },
    [setSelectedKeyFrameBezier]
  )

  const onBezierStringChange = useCallback(
    (bezierString: string) => {
      setSelectedKeyFrameBezier(convertBezierCurve(bezierString), true)
    },
    [setSelectedKeyFrameBezier]
  )

  const scrollToTop = () => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTop = 0
    }
  }

  useLayoutEffect(() => {
    scrollToTop()
  }, [keyframeIdList])

  if (!keyframeList.length) {
    return (
      // @ts-ignore adjust when ScrollView has been refactored
      <ScrollView ref={scrollViewRef} className="h-full" noScrollbar>
        <div className="flex flex-col p-16 gap-8 min-h-[280px] h-full min-w-[248px]">
          <div className="rounded-md bg-light-overlay-3 text-light-overlay-40 h-full flex items-center justify-center">
            {t('select_keyframe')}
          </div>
        </div>
      </ScrollView>
    )
  }
  return (
    // @ts-ignore adjust when ScrollView has been refactored
    <ScrollView ref={scrollViewRef} className="h-full" noScrollbar>
      <div className="flex flex-col p-16 gap-8 min-w-[248px]">
        <EasingEditor value={bezier} onChange={onBezierChange} isStepType={isStepEnd} isMixed={isMixed} />
        <div className="flex gap-8">
          <EasingTypeSelect
            value={easingType}
            onChange={onEasingTypeChange}
            mixed={isMixed}
            disabled={!animatable}
            data-test-id="easing-type-selector"
          />

          {animatable &&
            COMMON_EASING_TYPE_OPTIONS.map((option) => (
              <Tooltip key={option.value} content={option.name}>
                <ToggleButton
                  name={option.name}
                  value={option.value}
                  checked={easingType === option.value}
                  onChange={onEasingTypeChange}
                  icon={option.icon}
                />
              </Tooltip>
            ))}
        </div>

        {isStepEnd ? null : (
          <MixInput
            type="text"
            noIS
            key="input"
            value={isMixed ? MIX_VALUE : bezier.join(', ')}
            formatter={formatBezierString}
            onChange={onBezierStringChange}
            validator={bezierValidator}
            disabled={!animatable}
            variant="normal"
            data-test-id="bezier-input"
          />
        )}
      </div>
    </ScrollView>
  )
}

export default EasingPanel

const formatBezierString = (bezierString: ValueType) => convertBezierCurve(bezierString.toString()).join(', ')

const convertBezierCurve = (bezierString: string): BezierCurve =>
  bezierString.split(',').map((bezierValue) => parseToDecimals(Number(bezierValue), 2)) as BezierCurve
