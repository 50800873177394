import PropertyStack from '../../PropertyStack'

class OffsetXStack extends PropertyStack {
  constructor(elementStack, data) {
    super(elementStack, data)
    this.type = 'OFFSET_X'
    this.key = 'offsetX'
    this.dataKey = 'offsetX'
    this.animatableProperties = new Set(['offsetX'])
  }
}

export default OffsetXStack
