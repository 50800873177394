import { CapShape, ElementType, EndShape, GeometryType } from '@phase-software/types'
import { BezierShape } from './geometry/bezier-shape/BezierShape'
import {
    rectangle,
    ellipse,
} from './geometry/index'
import { ShapeType, RenderGeometry, UpdateType } from './visual_server/RenderItem'
import { applyCornerRadiusModifier, meshToPathData } from './geometry/generate'
import { deg2rad } from './math'

/** @typedef {import('@phase-software/data-store/src/Element').Element} Element */
/** @typedef {import('@phase-software/data-utils').Mesh} Mesh */
/** @typedef {import('./visual_server/VisualStorage').VisualStorage} VisualStorage */
/** @typedef {import('./visual_server/RenderItem').RenderItem} RenderItem */
/** @typedef {import('./visual_server/SpatialCache').SceneNode} SceneNode */

/**
 * @param {VisualStorage} storage
 * @param {Element} element
 * @param {SceneNode} node
 */
export function setupBaseGeometry(storage, element, node) {
    const item = node.item
    const elementType = element.get('elementType')

    if (elementType === ElementType.PATH) {
        const geometry = element.get('geometry')

        const updateRectangle = () => {
            const size = element.get('size')
            const cornerRadius = element.get('cornerRadius')

            node.setGeometry(rectangle(size), [size[0], size[1]])

            if (hasCornerRadius(cornerRadius)) {
                const data = rectangle(size, cornerRadius)
                const path = BezierShape.createFromPathData(data)
                path.version = -1
                item.base.setShape(0, ShapeType.CORNER, path)
            } else if (item.base.mods[1]) {
                item.base.deleteShape(ShapeType.CORNER)
            }
            item.update(UpdateType.TRANSFORM | UpdateType.GEOMETRY)

            item.base.shape.version = -1
        }

        const updateEllipse = () => {
            const size = element.get('size')
            node.setGeometry(ellipse(size))
            item.update(UpdateType.TRANSFORM | UpdateType.GEOMETRY)

            item.base.shape.version = -1
        }

        const updatePolygon = () => {
            /** @type {Mesh} */
            const mesh = geometry.get('mesh')
            // Recalculating bounds is neccsary until remove all 'TRIGGER_VECTOR_FORCE_UPDATE'
            mesh.recalculateBounds()
            // TODO: Implement corner radius support in BezierPath. This will allow us to use a normalized path that can be scaled by node.base.path.transform.
            const cornerRadius = element.get('cornerRadius')

            // create the base path of the node
            const data = meshToPathData(mesh)
            node.setGeometry(data)
            const needToApplyCornerRadius = Object.keys(data.cornerRadiusOverrides).length > 0 || hasCornerRadius(cornerRadius)

            if (needToApplyCornerRadius) {
                const dataWithCornerRadius = applyCornerRadiusModifier(data, cornerRadius)
                const path = BezierShape.createFromPathData(dataWithCornerRadius)
                path.version = -1
                // set the corner path of the node
                item.base.setShape(0, ShapeType.CORNER, path)
            } else if (item.base.mods[1]) {
                // remove corner mods while cornerRadius is 0
                item.base.deleteShape(ShapeType.CORNER)
            }
            // TODO: line without cap is also a rect
            item.update(UpdateType.TRANSFORM | UpdateType.GEOMETRY)

            item.base.shape.version = -1

            // Trim shape is updated by its parent
            if (node.parent && node.parent.item.trim.enabled) {
                node.parent.item.trim.version = -1
                node.parent.item.update(UpdateType.GEOMETRY)
            }
        }

        const updatePath = () => {
            switch (geometry.get('geometryType')) {
                case GeometryType.RECTANGLE: {
                    element.on('size', updateRectangle)
                    element.on('cornerRadius', updateRectangle)
                    updateRectangle()
                    break
                }
                case GeometryType.ELLIPSE: {
                    element.on('size', updateEllipse)
                    updateEllipse()
                    break
                }
                case GeometryType.POLYGON:
                case GeometryType.LINE:
                {
                    element.on('cornerRadius', updatePolygon)
                    element.on('MESH_CHANGES', updatePolygon)
                    element.on('TRIGGER_VECTOR_FORCE_UPDATE', updatePolygon)
                    updatePolygon()
                    break
                }
            }
        }

        /**
         * @param {Element} element
         */
        const disconnectElementChanges = (element) => {
            element.off('size', updateRectangle)
            element.off('cornerRadius', updateRectangle)

            element.off('size', updateEllipse)

            element.off('cornerRadius', updatePolygon)
            element.off('MESH_CHANGES', updatePolygon)
            element.off('TRIGGER_VECTOR_FORCE_UPDATE', updatePolygon)
        }

        geometry.on('geometryType', () => {
            disconnectElementChanges(element)
            updatePath()
        })
        updatePath()
    } else if (elementType === ElementType.TEXT) {
        // const res = storage.createTextResource()
        // const props = [
        //     'fontFamily',
        //     'fontStyle',
        //     'fontSize',
        //     'textDirection',
        //     'underline',
        //     'strikethrough',
        //     'cases',
        //     'subSuper',
        //     'lineSpacing',
        //     'characterSpacing',
        //     'paragraphIndent',
        //     'horizontalAlignment',
        //     'verticalAlignment',
        //     'size'
        // ]
        // const update = () => {
        //     const content = element.get('text')
        //     const options = element.gets(...props)
        //     res.setData(content, options)
        //     // TODO: generate path from text
        // }
        // element.ons('text', ...props, update)
        // update()
    } else if (elementType === ElementType.SCREEN) {
        const update = () => {
            const size = element.get('size')
            const cornerRadius = element.get('cornerRadius')
            if (item.isBooleanGroup()) {
                return
            }
            // The event callback that changes the item type will behind this callback
            if (item.isMaskGroup() || element.isNormalGroup) {
                const path = rectangle(size)
                const offset = element.get('referencePoint')
                for (let i = 0; i < path.vertices.length; i += 2) {
                    path.vertices[i] -= offset[0]
                    path.vertices[i + 1] -= offset[1]
                }
                node.setGeometry(path)
            } else {
                node.setGeometry(rectangle(size), [size[0], size[1]])
            }

            if (hasCornerRadius(cornerRadius)) {
                const data = rectangle(size, cornerRadius)
                const path = BezierShape.createFromPathData(data)
                path.version = -1
                item.base.setShape(0, ShapeType.CORNER, path)
            } else if (item.base.mods[1]) {
                item.base.deleteShape(ShapeType.CORNER)
            }
            item.update(UpdateType.TRANSFORM | UpdateType.GEOMETRY)

            item.base.shape.version = -1
        }
        element.on('size', update)
        element.on('cornerRadius', update)
        element.on('containerType', update)
        update()
    } else if (elementType === ElementType.CONTAINER) {
        const update = () => {
            const size = element.get('size')
            const cornerRadius = element.get('cornerRadius')
            if (item.isBooleanGroup()) {
                return
            }
            // The event callback that changes the item type will behind this callback
            if (item.isMaskGroup() || element.isNormalGroup) {
                const path = rectangle(size)
                const offset = element.get('referencePoint')
                for (let i = 0; i < path.vertices.length; i += 2) {
                    path.vertices[i] -= offset[0]
                    path.vertices[i + 1] -= offset[1]
                }
                node.setGeometry(path)
            } else {
                node.setGeometry(rectangle(size), [size[0], size[1]])
            }

            if (hasCornerRadius(cornerRadius)) {
                const data = rectangle(size, cornerRadius)
                const path = BezierShape.createFromPathData(data)
                path.version = -1
                item.base.setShape(0, ShapeType.CORNER, path)
            } else if (item.base.mods[1]) {
                item.base.deleteShape(ShapeType.CORNER)
            }
            item.update(UpdateType.TRANSFORM | UpdateType.GEOMETRY)

            item.base.shape.version = -1
        }
        element.on('size', update)
        element.on('cornerRadius', update)
        element.on('containerType', update)
        update()
    }
    // eslint-disable-next-line no-empty
    else if (elementType === ElementType.GROUP) {
    }
}


/**
 * @param {VisualStorage} storage
 * @param {RenderItem} item
 * @param {number} index
 * @param {ComputedLayer} layer
 */
export function setupStrokeGeometry(storage, item, index, layer) {
    item.strokes[index] = new RenderGeometry(`${item.id}[stroke@${index}]`)

    const update = () => {
        if (!item.strokes[index]) {
            item.strokes[index] = new RenderGeometry(`${item.id}[stroke@${index}]`)
        }

        const options = getStrokeOptions(layer)
        const style = item.strokes[index].style
        // item.strokes[index].options = options // for old stroke generation
        style.width = options.width
        style.join = options.joinType
        let cap = options.capType
        style.miterLimit = 1 / Math.sin(deg2rad(options.miter) * 0.5)
        if (cap === CapShape.NONE && options.ends === EndShape.ROUND) {
            cap = CapShape.ROUND
        }
        style.startCap = cap
        style.endCap = cap
        style.dashPattern.length = 0
        for (let i = 0; i < options.dashes.length; i++) {
            style.dashPattern.push(options.dashes[i] || 0, options.gaps[i] || 0)
        }
        // TODO: offset, grow, join/cap size
        item.update(UpdateType.GEOMETRY | UpdateType.TRANSFORM)
    }
    layer.ons('width', 'growDirection', 'offset', 'dash', 'gap', 'join', 'joinSize', 'miter', 'ends', 'cap', 'capSize', update)
    update()
}

/**
 * @param {ComputedLayer} layer
 */
function getStrokeOptions(layer) {
    const { width, growDirection, offset, dash, gap, join, joinSize, miter, ends, cap, capSize } =
        layer.gets('width', 'growDirection', 'offset', 'dash', 'gap', 'join', 'joinSize', 'miter', 'ends', 'cap', 'capSize')
    return {
        width, offset: offset,
        dashes: dash, gaps: gap,
        joinType: join, joinSize, miter,
        ends, capType: cap, capSize,
        growDirection
    }
}

/**
 * @param {number | number[]} cornerRadius
 * @returns
 */
function hasCornerRadius(cornerRadius) {
    if (Array.isArray(cornerRadius)) {
        for (const radius of cornerRadius) {
            if (radius !== 0) return true
        }
    } else {
        return cornerRadius !== 0
    }
    return false
}
