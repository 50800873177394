import {
  NON_REPEATABLE_PROPERTIES_KEYS,
  REPEATABLE_PROPERTIES_KEYS,
  EFFECT_PROPERTIES_KEYS,
  CUSTOM_PROPERTIES_KEYS
} from '../components/instance'
import {
  MERGE_STACK_SUB_PROPERTIES,
  MERGE_STACK_ALIAS
} from '../constant'

const NO_ANIMATION_KEYS = [
  'id',
  'name',
  'type',
  'parent',
  'layerId',
  'trackId',
  'elementId',
  'layerType'
]

const DEFAULT_STATE = 'DEFAULT'

class ElementKFStates {
  constructor(elementStack) {
    this.manager = elementStack.manager
    this.dataStore = elementStack.dataStore
    this.elementStack = elementStack
    this._reset()
  }

  /**
   * Reset states
   */
  _reset() {
    this._states = {
      fills: {},
      strokes: {},
      shadows: {},
      innerShadows: {},
      effects: {}
    }
    this._changes = []
  }

  /**
   * Get cached state changes
   * @returns {object[]}
   */
  getChanges() {
    return this._changes
  }

  /**
   * Clear cached state changes
   */
  clearChanges() {
    this._changes = []
  }

  init() {
    this._initNonRepeatablePropsState()
    this._initRepeatablePropsState()
    this._initEffectPropsState()
    this._initCustomPropsState()
  }

  /**
   * Update prop state
   * @param {string} prop
   * @param {string|FrameType} state
   */
  _updateNonRepeatableProps(prop, state) {
    let hasChange = false
    if (this._states[prop] !== state) {
      this._states[prop] = state
      hasChange = true
    }

    if (hasChange) {
      if (MERGE_STACK_SUB_PROPERTIES[prop]) {
        MERGE_STACK_SUB_PROPERTIES[prop].forEach((childProp) => {
          this._changes.push({ prop: childProp })
        })
      } else {
        this._changes.push({ prop })
      }
    }
  }

  /**
   * Update prop state
   * @param {string} prop
   * @param {string|FrameType} state
   * @param {string} layerName
   * @param {string} clKey
   */
  _updateRepeatableProps(prop, state, layerName, clKey) {
    let hasChange = false
    if (layerName && this._states[layerName][clKey] && this._states[layerName][clKey][prop] !== state) {
      this._states[layerName][clKey][prop] = state
      hasChange = true
    }
    if (hasChange) {
      this._changes.push({ prop, layerName, clKey })
    }
  }

  /**
   * Update prop state
   * @param {string} prop
   * @param {string|FrameType} state
   * @param {string} effectName
   * @param {string} ceKey
   */
  _updateEffectProps(prop, state, effectName, ceKey) {
    let hasChange = false
    if (effectName && this._states.effects[ceKey] && this._states.effects[ceKey][prop] !== state) {
      this._states.effects[ceKey][prop] = state
      hasChange = true
    }
    if (hasChange) {
      this._changes.push({ prop, effectName, ceKey })
    }
  }

  /**
   * Init non-repeatable props states
   */
  _initNonRepeatablePropsState() {
    NON_REPEATABLE_PROPERTIES_KEYS.forEach((propKey) => {
      // TODO: Currently we only have path morphing stack in custom properties so that we can use the same flow as non-repeatable properies.
      this._updateNonRepeatableProps(propKey, DEFAULT_STATE)
    })
  }

  /**
   * Init layer props states
   * @param {object} clData
   * @param {string} layerName
   * @param {string} clKey
   */
  _initLayerPropsState(clData, layerName, clKey) {
    this._states[layerName][clKey] = {}
    // Set DEFAULT state to all props for a computedLayer
    Object.keys(clData).forEach((propKey) => {
      if (NO_ANIMATION_KEYS.includes(propKey)) {
        return
      }

      const key = propKey === 'color' ? 'paint' : propKey
      this._updateRepeatableProps(key, DEFAULT_STATE, layerName, clKey)
    })
  }

  /**
   * Init repeatable props states
   */
  _initRepeatablePropsState() {
    REPEATABLE_PROPERTIES_KEYS.forEach((layerName) => {
      this.elementStack[layerName].forEach((layerStack) => {
        // in case of layerStack.computedLayer is undefined
        // this only happened when sync the changes from remote
        if (layerStack.computedLayer) {
            this._initLayerPropsState(layerStack.computedLayer.data, layerName, layerStack.clKey)
        }
      })
    })
  }

  /**
   * Init effect props states
   */
  _initEffectPropsState() {
    EFFECT_PROPERTIES_KEYS.forEach((effectName) => {
      const effectStack = this.elementStack.effects.get(effectName)
      if (effectStack) {
        this._states.effects[effectStack.ceKey] = {}
        Object.keys(effectStack.computedEffect.data).forEach((propKey) => {
          if (NO_ANIMATION_KEYS.includes(propKey)) {
            return
          }

          this._updateEffectProps(propKey, DEFAULT_STATE, effectName, effectStack.ceKey)
        })
      }
    })
  }

  /**
   * Init custom props states
   */
  _initCustomPropsState() {
    CUSTOM_PROPERTIES_KEYS.forEach((propKey) => {
      this._updateNonRepeatableProps(propKey, DEFAULT_STATE)
    })
  }

  /**
   * Check non-repeatable props state changes
   * @param {number} time
   */
  _checkNonRepeatablePropsStateChanges(time) {
    NON_REPEATABLE_PROPERTIES_KEYS.forEach((propKey) => {
      const propertyStack = this.elementStack[propKey]
      let newState = DEFAULT_STATE
      if (propertyStack) {
        newState = propertyStack.getKFState(time)[propertyStack.dataKey]
      }
      this._updateNonRepeatableProps(propKey, newState)
    })
  }

  /**
   * Check repeatable props state changes
   * @param {number} time
   */
  _checkRepeatablePropsStateChanges(time) {
    REPEATABLE_PROPERTIES_KEYS.forEach((layerName) => {
      const existClKeys = new Set()
      this.elementStack[layerName].forEach((layerStack) => {
        const clKey = layerStack.clKey
        existClKeys.add(clKey)
        if (!this._states[layerName][clKey]) {
          this._initLayerPropsState(layerStack.computedLayer.data, layerName, clKey)
        }

        // Check all prop state from a computedLayer
        const newStates = layerStack.getKFState(time)
        Object.keys(newStates).forEach((propKey) => {
          this._updateRepeatableProps(propKey, newStates[propKey], layerName, clKey)
        })
      })

      this._removeLayerPropState(layerName, existClKeys)
    })
  }

  /**
   * Remove layer prop state which is not exist anymore
   * @param {string} layerName
   * @param {Set} existClKeys
   */
  _removeLayerPropState(layerName, existClKeys) {
    const layerPropStates = this._states[layerName]
    if (!layerPropStates) {
      return
    }

    const clKeys = Object.keys(layerPropStates)

    for (let i = 0; i < clKeys.length; i++) {
      const clKey = clKeys[i]
      const layerPropState = layerPropStates[clKey]
      if (!layerPropState || existClKeys.has(clKey)) {
        continue
      }

      Object.keys(layerPropState).forEach((propKey) => {
        this._updateRepeatableProps(propKey, DEFAULT_STATE, layerName, clKey)
      })
    }
  }

  /**
   * Check effect props state changes
   * @param {number} time
   */
  _checkEffectPropsStateChanges(time) {
    EFFECT_PROPERTIES_KEYS.forEach((effectName) => {
      const existCeKeys = new Set()
        this.elementStack.effects.forEach((effectStack) => {
        const ceKey = effectStack.ceKey
        existCeKeys.add(ceKey)
        if (!this._states.effects[ceKey]) {
          this._initEffectPropsState(effectStack.computedEffect.data, effectName, ceKey)
        }

        // Check all prop state from a computedLayer
        const newStates = effectStack.getKFState(time)
        Object.keys(newStates).forEach((propKey) => {
          this._updateEffectProps(propKey, newStates[propKey], effectName, ceKey)
        })
      })

      this._removeEffectPropState(effectName, existCeKeys)
    })
  }

  /**
   * Remove layer prop state which is not exist anymore
   * @param {string} effectName
   * @param {Set} existCeKeys
   */
  _removeEffectPropState(effectName, existCeKeys) {
    const ceKeys = Object.keys(this._states.effects)

    for (let i = 0; i < ceKeys.length; i++) {
      const ceKey = ceKeys[i]
      const effectPropState = this._states.effects[ceKey]
      if (!effectPropState || existCeKeys.has(ceKey)) {
        continue
      }

      Object.keys(effectPropState).forEach((propKey) => {
        this._updateEffectProps(propKey, DEFAULT_STATE, effectName, ceKey)
      })
      delete this._states.effects[ceKey]
    }
  }

  /**
   * Check non-repeatable props state changes
   * @param {number} time
   */
  _checkCustomPropsStateChanges(time) {
    CUSTOM_PROPERTIES_KEYS.forEach((propKey) => {
      const propertyStack = this.elementStack[propKey]
      let newState = DEFAULT_STATE
      if (propertyStack) {
        newState = propertyStack.getKFState(time)[propertyStack.dataKey]
      }
      // TODO: Currently we only have path morphing stack in custom properties so that we can use the same flow as non-repeatable properies.
      this._updateNonRepeatableProps(propKey, newState)
    })
  }

  /**
   * Check all props state changes
   * @param {number} time
   */
  checkChanges(time) {
    this._checkNonRepeatablePropsStateChanges(time)
    this._checkRepeatablePropsStateChanges(time)
    this._checkEffectPropsStateChanges(time)
    this._checkCustomPropsStateChanges(time)
  }

  /**
   * Delete single prop state
   * @param {string} propKey
   * @param {string} layerOrEffectName
   * @param {string} cKey
   */
  deletePropState(propKey, layerOrEffectName, cKey) {
    if (REPEATABLE_PROPERTIES_KEYS.includes(layerOrEffectName)) {
      this._updateRepeatableProps(propKey, DEFAULT_STATE, layerOrEffectName, cKey)
    } else if (EFFECT_PROPERTIES_KEYS.includes(layerOrEffectName)) {
      this._updateEffectProps(propKey, DEFAULT_STATE, layerOrEffectName, cKey)
    } else {
      // TODO: Currently we only have path morphing stack in custom properties so that we can use the same flow as non-repeatable properies.
      this._updateNonRepeatableProps(propKey, DEFAULT_STATE)
    }
  }

  /**
   * Delete layer state
   * @param {string} layerName
   * @param {string} clKey
   */
  deleteLayerState(layerName, clKey) {
    const layerState = this._states[layerName][clKey]
    if (layerState) {
      Object.keys(layerState).forEach((propKey) => {
        this._updateRepeatableProps(propKey, DEFAULT_STATE, layerName, clKey)
      })
      delete this._states[layerName][clKey]
    }
  }

  /**
   * Delete layer state
   * @param {string} effectName
   * @param {string} ceKey
   */
  deleteEffectState(effectName, ceKey) {
    const effectState = this._states.effects[ceKey]
    if (effectState) {
      Object.keys(effectState).forEach((propKey) => {
        this._updateEffectProps(propKey, DEFAULT_STATE, effectName, ceKey)
      })
      delete this._states.effects[ceKey]
    }
  }

  /**
   * Get single prop state
   * @param {string} propKey
   * @param {string} layerOrEffectName
   * @param {string} cKey
   * @returns {string|FrameType}
   */
  getPropState(propKey, layerOrEffectName, cKey) {
    if (layerOrEffectName) {
      return this._states[layerOrEffectName][cKey] && this._states[layerOrEffectName][cKey][propKey]
    }

    const key = MERGE_STACK_ALIAS[propKey] || propKey
    return this._states[key]
  }

  /**
   * Reset all states to DEFAULT
   */
  resetAll() {
    this._initNonRepeatablePropsState()
    REPEATABLE_PROPERTIES_KEYS.forEach((layerName) => {
      Object.keys(this._states[layerName]).forEach((clKey) => {
        Object.keys(this._states[layerName][clKey]).forEach((propKey) => {
          this._updateRepeatableProps(propKey, DEFAULT_STATE, layerName, clKey)
        })
      })
      this._states[layerName] = {}
    })
    EFFECT_PROPERTIES_KEYS.forEach((effectName) => {
      Object.keys(this._states.effects).forEach((ceKey) => {
        Object.keys(this._states.effects[ceKey]).forEach((propKey) => {
          this._updateEffectProps(propKey, DEFAULT_STATE, effectName, ceKey)
        })
      })
    })
    this._initCustomPropsState()
  }

  debug() {
    return this._states
  }
}

export default ElementKFStates
