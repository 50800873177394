import { IRAsset } from './IRAsset'

export class IRImageAsset extends IRAsset {
  static defaultValue = {
    id: '',
    data: '',
    width: 0,
    height: 0
  }

  constructor(data = IRImageAsset.defaultValue) {
    super(data)
    this.data = data.data
    this.width = data.width
    this.height = data.height
  }

  fromJSON(data) {
    super.fromJSON(data)
    this.data = data.data
    this.width = data.width
    this.height = data.height
    return this
  }

  toJSON() {
    return {
      ...super.toJSON(),
      data: this.data,
      width: this.width,
      height: this.height
    }
  }
}
