import React, { MouseEvent } from 'react'
import Icon from '../Icon'
import { isPrimaryModifierKey } from '../../../utils/keyboard'

type FoldToggleProps = {
  className?: string
  expanded: boolean
  onToggle: (expanded: boolean, isModifierKeyPressed: boolean) => void
}

const FoldToggle = ({ className = '', expanded = false, onToggle }: FoldToggleProps) => {
  const handleMouseDown = (e: MouseEvent) => {
    e.stopPropagation()
    onToggle(!expanded, isPrimaryModifierKey(e))
  }

  return (
    <div
      className={`${className} group-hover:block hidden absolute right-full`}
      data-test-id="element-unfold"
      onMouseDown={handleMouseDown}
    >
      <Icon name={expanded ? 'Unfold' : 'Fold'} />
    </div>
  )
}

export default FoldToggle
