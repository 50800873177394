import { useCallback, useEffect, useState } from 'react'

function useElementResize(ref: React.RefObject<HTMLDivElement> | null) {
  const [size, setSize] = useState({
    offsetWidth: 0,
    offsetHeight: 0,
    clientWidth: 0,
    clientHeight: 0,
    innerWidth: 0,
    innerHeight: 0
  })

  // Update size
  const updateSize = useCallback(() => {
    if (ref?.current) {
      const computedStyle = getComputedStyle(ref.current)
      setSize({
        offsetWidth: ref.current.offsetWidth,
        offsetHeight: ref.current.offsetHeight,
        clientWidth: ref.current.clientWidth,
        clientHeight: ref.current.clientHeight,
        innerWidth:
          ref.current.clientWidth - parseInt(computedStyle.paddingLeft) - parseInt(computedStyle.paddingRight),
        innerHeight:
          ref.current.clientHeight - parseInt(computedStyle.paddingTop) - parseInt(computedStyle.paddingBottom)
      })
    }
  }, [ref])

  // Handle resize
  useEffect(() => {
    window.addEventListener('resize', updateSize)
    updateSize() // Initial size on mount

    return () => {
      window.removeEventListener('resize', updateSize)
    }
  }, [updateSize])

  return size
}

export default useElementResize
