import { CommentBodyContentProps, CommentType, MetadataProps } from '../shared/CommentThread/types'
import { ThreadMetadata } from '../../liveblocks.config'
import { CommentBody, CommentBodyElement, CommentBodyLink, CommentBodyMention } from '@liveblocks/client'

interface CommentThreadDataProps {
  body: CommentBody
  metadata: ThreadMetadata
}

interface CommentDataProps {
  body: CommentBody
  threadId: string
}

export const toLiveblocksCommentThread = (value: string, metadata: MetadataProps): CommentThreadDataProps => {
  return {
    body: {
      version: 1,
      content: [
        {
          type: 'paragraph',
          children: [
            {
              text: value
            }
          ]
        }
      ]
    },
    metadata: stringifyMetadata(metadata)
  }
}

export const toLiveblocksComment = (value: string, id: string): CommentDataProps => {
  return {
    body: {
      version: 1,
      content: [
        {
          type: 'paragraph',
          children: [
            {
              text: value
            }
          ]
        }
      ]
    },
    threadId: id
  }
}

export const parseMetadata = ({
  type,
  position = '{ x: 0, y: 0 }',
  usersReadStatus = '{}',
  resolved = false,
  isDeleted = false
}: ThreadMetadata): MetadataProps => {
  const isPanelType = type === CommentType.PANEL

  return {
    type,
    position: isPanelType ? undefined : JSON.parse(position),
    usersReadStatus: JSON.parse(usersReadStatus),
    resolved,
    isDeleted
  }
}

export const stringifyMetadata = ({
  type,
  position = { x: 0, y: 0 },
  usersReadStatus = {},
  resolved = false,
  isDeleted
}: MetadataProps): ThreadMetadata => {
  const isPanelType = type === CommentType.PANEL

  return {
    type,
    position: isPanelType ? undefined : JSON.stringify(position),
    usersReadStatus: JSON.stringify(usersReadStatus),
    resolved,
    isDeleted
  }
}

const isCommentBodyMention = (element: CommentBodyElement): element is CommentBodyMention => {
  return 'type' in element && element.type === 'mention'
}

const isCommentBodyLink = (element: CommentBodyElement): element is CommentBodyLink => {
  return 'type' in element && element.type === 'link'
}

export const getCommentContent = (blocks: CommentBodyContentProps[]) => {
  const contextArr = blocks
    .filter((block) => block.type === 'paragraph')
    .flatMap((block) =>
      block.children.map((inlineElement) => {
        if (isCommentBodyMention(inlineElement)) {
          return inlineElement.id
        }
        if (isCommentBodyLink(inlineElement)) {
          return inlineElement.url
        }
        return inlineElement.text
      })
    )

  return contextArr.join('')
}
