import { library } from '@fortawesome/fontawesome'
import faPencilAlt from '@fortawesome/fontawesome-free-solid/faPencilAlt'
import faFont from '@fortawesome/fontawesome-free-solid/faFont'
import faImage from '@fortawesome/fontawesome-free-solid/faImage'
import faFileVideo from '@fortawesome/fontawesome-free-solid/faFileVideo'
import faFileAudio from '@fortawesome/fontawesome-free-solid/faFileAudio'
import faHandPaper from '@fortawesome/fontawesome-free-solid/faHandPaper'
import faObjectGroup from '@fortawesome/fontawesome-free-solid/faObjectGroup'
import faFileAlt from '@fortawesome/fontawesome-free-solid/faFileAlt'
import faDesktop from '@fortawesome/fontawesome-free-solid/faDesktop'
import faEdit from '@fortawesome/fontawesome-free-solid/faEdit'
import faThLarge from '@fortawesome/fontawesome-free-solid/faThLarge'
import faBook from '@fortawesome/fontawesome-free-solid/faBook'
import faAlignJustify from '@fortawesome/fontawesome-free-solid/faAlignJustify'
import faCog from '@fortawesome/fontawesome-free-solid/faCog'
import faPlus from '@fortawesome/fontawesome-free-solid/faPlus'
import faToggleOn from '@fortawesome/fontawesome-free-solid/faToggleOn'
import faNewspaper from '@fortawesome/fontawesome-free-solid/faNewspaper'
import faDatabase from '@fortawesome/fontawesome-free-solid/faDatabase'
import faClock from '@fortawesome/fontawesome-free-solid/faClock'
import faPause from '@fortawesome/fontawesome-free-solid/faPause'
import faPlay from '@fortawesome/fontawesome-free-solid/faPlay'
import faRedo from '@fortawesome/fontawesome-free-solid/faRedo'
import faMagnet from '@fortawesome/fontawesome-free-solid/faMagnet'
import faTh from '@fortawesome/fontawesome-free-solid/faTh'
import faAngleDown from '@fortawesome/fontawesome-free-solid/faAngleDown'
import faAngleRight from '@fortawesome/fontawesome-free-solid/faAngleRight'
import faFolder from '@fortawesome/fontawesome-free-solid/faFolder'
import faEye from '@fortawesome/fontawesome-free-solid/faEye'
import faLock from '@fortawesome/fontawesome-free-solid/faLock'

export default function () {
  library.add(
    faPencilAlt,
    faFont,
    faImage,
    faFileVideo,
    faFileAudio,
    faHandPaper,
    faObjectGroup,
    faFileAlt,
    faDesktop,
    faEdit,
    faThLarge,
    faBook,
    faAlignJustify,
    faCog,
    faPlus,
    faToggleOn,
    faNewspaper,
    faDatabase,
    faClock,
    faPause,
    faPlay,
    faRedo,
    faMagnet,
    faTh,
    faAngleDown,
    faAngleRight,
    faFolder,
    faEye,
    faLock
  )
}
