import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { MIX_VALUE } from '@phase-software/data-utils'
import { EasingType } from '@phase-software/types'

import { Select } from '../../shared'
import { MenuListOptionProps } from '../../shared/Menu/Menu.types'
import { translateMenuOptions } from '../../shared/Menu/utils'
import { CUSTOM_EASING_OPTIONS as CUSTOM_EASING_TYPE_OPTIONS, EASING_TYPE_OPTIONS } from '../constant'

type EasingTypeSelectProps = {
  value: EasingType | typeof MIX_VALUE
  disabled: boolean
  onChange: (value: EasingType) => void
  mixed: boolean
}

const EasingTypeSelect = ({ value, onChange, disabled, mixed, ...args }: EasingTypeSelectProps) => {
  const { t } = useTranslation('file')
  const translatedOptions = useMemo(() => {
    const options: MenuListOptionProps[] =
      value === EasingType.CUSTOM ? CUSTOM_EASING_TYPE_OPTIONS : EASING_TYPE_OPTIONS
    return translateMenuOptions(t, options, { ns: 'file', keyPrefix: 'easing_type' })
  }, [value, t])

  return (
    <Select
      // @ts-ignore TODO: fix after refactor of Select
      noIS
      options={translatedOptions}
      value={value}
      onChange={onChange}
      disabled={disabled}
      mixed={mixed}
      variant="normal"
      caret
      className="w-[108px]"
      {...args}
    />
  )
}

export default EasingTypeSelect
