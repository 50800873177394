export const CMP_EPSILON = 1e-5

/**
 * A very small absolute value used to check if a value is very close to
 * zero. The value should be large enough to offset any floating point
 * noise, but small enough to be meaningful in computation in a nominal
 * range (see MACHINE_EPSILON).
 *
 * http://docs.oracle.com/cd/E19957-01/806-3568/ncg_goldberg.html
 * http://www.cs.berkeley.edu/~wkahan/Math128/Cubic.pdf
 */
export const EPSILON = 1e-12
/**
 * The machine epsilon for a double precision (Javascript Number) is
 * 2.220446049250313e-16. (try this in the js console:
 *     (function(){ for (var e = 1; 1 < 1+e/2;) e/=2; return e }())
 *
 * The constant MACHINE_EPSILON here refers to the constants δ and ε
 * such that, the error introduced by addition, multiplication on a
 * 64bit float (js Number) will be less than δ and ε. That is to say,
 * for all X and Y representable by a js Number object, S and P be their
 * 'exact' sum and product respectively, then
 * |S - (x+y)| <= δ|S|, and |P - (x*y)| <= ε|P|.
 * This amounts to about half of the actual machine epsilon.
 */
export const MACHINE_EPSILON = 1.12e-16
/**
 * The epsilon to be used when handling curve-time parameters. This
 * cannot be smaller, because errors add up to around 2e-7 in the bezier
 * fat-line clipping code as a result of recursive sub-division.
 */
export const CURVETIME_EPSILON = 1e-8

/**
 * The epsilon to be used when performing "geometric" checks, such as
 * distances between points and lines.
 */
export const GEOMETRIC_EPSILON = 1e-7

/**
 * The epsilon to be used when performing "trigonometric" checks, such
 * as examining cross products to check for collinearity.
 */
export const TRIGONOMETRIC_EPSILON = 1e-8

/**
 * The epsilon to be used when performing angular checks in degrees,
 * e.g. in `arcTo()`.
 */
export const ANGULAR_EPSILON = 1e-5
// /**
//  * Kappa is the value which which to scale the curve handles when
//  * drawing a circle with bezier curves.
//  *
//  * http://whizkidtech.redprince.net/bezier/circle/kappa/
//  */
// KAPPA: 4 * (sqrt(2) - 1) / 3,

// export const UNIT_EPSILON = 1e-5

export const RAD_TO_DEG = 180 / Math.PI
export const DEG_TO_RAD = Math.PI / 180

export const PI_2 = Math.PI / 2
export const PI = Math.PI
export const PI2 = Math.PI * 2
export const PI4 = Math.PI * 4

/** @enum {number} */
export const Rot = {
    0: 0,
    15: Math.PI / 12,
    45: Math.PI / 4,
    90: Math.PI / 2,
    180: Math.PI,
    270: Math.PI * 3 / 2,
    360: Math.PI * 2,
}

/** a value below this one should be treated as zero */
export const ZERO_SIZE_THRESHOLD = 0.01
