import { ElementType } from '@phase-software/types'
import { Group } from './Group'
import { Geometry } from './geometry/Geometry'

export class Screen extends Group {
    /**
     * creates a default Screen
     * @param {DataStore} dataStore
     * @param {ContainerData} [data]
     */
    create(dataStore, data) {
        super.create(dataStore, data)
        this.data.elementType = ElementType.SCREEN
        this.data.expanded = false

        this.data.screenPreset = 'CUSTOM'
        this.data.orientation = 'LANDSCAPE'

        // non-serializable
        this.data.geometry = new Geometry(this.dataStore)
    }

    /**
     * loads Screen with ScreenData
     * @param {ScreenData} data
     */
    load(data) {
        super.load({...data, elementType: ElementType.SCREEN})
        this.data.expanded = data.expanded

        this.data.screenPreset = data.screenPreset || 'CUSTOM'
        this.data.orientation = data.orientation || 'LANDSCAPE'

        // non-serializable
        this.data.geometry = new Geometry(this.dataStore)
    }

    /**
     * @param {object} [overrides] data object with overrides
     * @returns {Screen}
     */
    clone(overrides) {
        const obj = super.clone(overrides)
        obj.expanded = this.data.expanded
        obj.data.screenPreset = this.data.screenPreset
        obj.data.orientation = this.data.orientation

        // non-serializable
        this.data.geometry = new Geometry(this.dataStore)
        return obj
    }

    /**
     * saves data for a Screen
     * @param {bool} [copy]
     * @returns {ScreenData}
     */
    save(copy) {
        const data = super.save()
        if (copy) {
            data.expanded = this.data.expanded
        }
        data.screenPreset = this.data.screenPreset
        data.orientation = this.data.orientation
        return data
    }

    get clipContent() {
        return !this.get('overflowX') && !this.get('overflowY')
    }
}

/** @typedef {import('./Group').GroupData} GroupData */

/** @typedef {('LANDSCAPE' | 'PORTRAIT')} ScreenOrientation */

/**
 * @typedef {ScreenData} GroupData
 * @property {string} screenPreset
 * @property {ScreenOrientation} orientation
 */
