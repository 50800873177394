export const DEFAULT_PREFIX_KEY = 'default'
export const DEFAULT_COUNTER = 0
export const SPLIT_CODE = '-'
export const prefixes = [
    'el', // Element
    'bs', // Base
    'pc', // Property Component
    'im', // Interaction Manager component
    'ly', // Layer Component
    'ms'  // Mesh component
]

export const createId = (prefixKey, number) => {
    if (prefixKey === DEFAULT_PREFIX_KEY) {
        return `${number}`
    }
    return `${prefixKey}${SPLIT_CODE}${number}`
}

const createPrefixes = () => {
    const prefixesMap = prefixes.reduce((acc, prefix) => {
        acc[prefix] = createId(prefix, DEFAULT_COUNTER)
        return acc
    }, {})
    prefixesMap[DEFAULT_PREFIX_KEY] = `${DEFAULT_COUNTER}`
    return prefixesMap
}

export class IdCounter {
    constructor() {
        this._counter = createPrefixes()
    }

    getCurrentCount(prefix) {
        const prefixKey = this._getPrefixKey(prefix)
        const number = this._getNumber(prefixKey)
        return number
    }

    getIdBy(prefix) {
        const prefixKey = this._getPrefixKey(prefix)
        return this._counter[prefixKey]
    }

    create(prefix) {
        const prefixKey = this._getPrefixKey(prefix)
        const number = this._getNumber(prefixKey)
        this._counter[prefixKey] = createId(prefixKey, number + 1)
        return this._counter[prefixKey]
    }

    load(id, prefix) {
        const prefixKey = this._getPrefixKey(prefix)
        const currentNumber = this._getNumber(prefixKey)
        const newNumber = this._getNumber(prefixKey, id)
        const finalNumber = Math.max(currentNumber, newNumber)
        this._counter[prefixKey] = createId(prefixKey, finalNumber)
    }

    _getPrefixKey(prefix) {
        return this._counter[prefix] ? prefix : DEFAULT_PREFIX_KEY
    }

    _getNumber(prefix, id) {
        if (id && !this.isInCounter(id, prefix)) {
            return parseInt(this._counter[DEFAULT_PREFIX_KEY])
        }

        const prefixKey = this._getPrefixKey(prefix)
        const str = id || this._counter[prefixKey]
        const numberOnly = isNaN(Number(str))
            ? this._counter[DEFAULT_PREFIX_KEY]
            : str
        const numberStr = prefixKey === DEFAULT_PREFIX_KEY
            ? numberOnly
            : str.split(SPLIT_CODE)[1]
        return parseInt(numberStr)
    }

    isInCounter(id, prefix) {
        if (!id) {
            return false
        }
        if (typeof id !== 'string' && typeof id !== 'number') {
            return false
        }

        let str = id.toString()
        if (typeof id === 'number') {
            str = `${id}`
        }

        const prefixKey = this._getPrefixKey(prefix)
        let number = str
        if (prefixKey !== DEFAULT_PREFIX_KEY) {
            const splits = str.split(SPLIT_CODE)
            if (splits.length !== 2 || splits[0] !== prefixKey) {
                return false
            }

            number = splits[1]
        }

        return !isNaN(Number(number))
    }
}

const idCounter = new IdCounter()

/**
 * Create a unique id
 * @param {string} prefix
 * @returns {string}
 */
export function id(prefix = '') {
    return idCounter.create(prefix)
}

/**
 * Load id to counter
 * @param {string} id
 * @param {string} prefix
 */
export function loadId(id, prefix = '') {
    idCounter.load(id, prefix)
}

/**
 * Check if id belongs to counter
 * @param {string} id
 * @param {string} prefix
 * @returns {boolean} 
 */
export function isId(id, prefix = '') {
    return idCounter.isInCounter(id, prefix)
}
