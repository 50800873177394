import React from 'react'
import { useTranslation } from 'react-i18next'

import { ColorFormat, useInspectContext } from '../../../providers/InspectProvider'
import { Select } from '../../shared'

const capOptions = [
  {
    value: ColorFormat.HEX,
    name: 'HEX'
  },
  {
    value: ColorFormat.RGB,
    name: 'RGB'
  },
  {
    value: ColorFormat.HSL,
    name: 'HSL'
  },
  {
    value: ColorFormat.HSB,
    name: 'HSB'
  }
]

const ColorRow = () => {
  const { colorFormat, setColorFormat } = useInspectContext()
  const { t } = useTranslation('file', { keyPrefix: 'inspect' })

  const handleChange = (type: ColorFormat) => {
    setColorFormat(type)
  }

  return (
    <div className="flex gap-8 px-16 items-center">
      <div className="w-64 text-light-overlay-60">{t('color')}</div>
      <div className="flex-grow">
        <Select
          // @ts-ignore TODO: fix after refactor of Select
          caret
          variant="normal"
          options={capOptions}
          onChange={handleChange}
          value={colorFormat}
        />
      </div>
    </div>
  )
}

export default React.memo(ColorRow)
