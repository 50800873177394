import { useMemo } from 'react'
import { WorkspaceType } from '@phase-software/types'

import { ProjectPermissionEnum } from '../components/Share/shareTypes'
import { ProjectFieldsFragment } from '../generated/graphql'
import { WorkspaceData } from '../providers/WorkspaceContextProvider'
import { projectService } from '../services/api'

export const useWorkspaceActions = (workspaceType: WorkspaceData['type'], workspaceId: WorkspaceData['id']) => {
  return useMemo(() => {
    if (workspaceType === WorkspaceType.PERSONAL) {
      return {
        getCurrentUserProjectPermissions: (id: ProjectFieldsFragment['id']) =>
          projectService.projectApiControllerGetPersonalProjectPermission({
            userId: workspaceId,
            projectId: id
          }),
        createProject: (name: string) =>
          projectService.projectApiControllerCreateUserProject({
            userId: workspaceId,
            createProjectRequest: { name, permission: ProjectPermissionEnum.CAN_EDIT, status: 'NORMAL' }
          }),
        renameProject: (id: ProjectFieldsFragment['id'], name: string) =>
          projectService.projectApiControllerPatchUserProject({
            userId: workspaceId,
            projectId: id,
            patchProjectRequest: { name, updateMask: ['name'] }
          }),
        deleteProject: (id: ProjectFieldsFragment['id']) =>
          projectService.projectApiControllerDeleteUserProject({ userId: workspaceId, projectId: id })
      }
    }

    return {
      getCurrentUserProjectPermissions: (id: ProjectFieldsFragment['id']) =>
        projectService.projectApiControllerGetTeamProjectPermission({
          teamId: workspaceId,
          projectId: id
        }),
      createProject: (name: string) =>
        projectService.projectApiControllerCreateTeamProject({
          teamId: workspaceId,
          createProjectRequest: { name, permission: ProjectPermissionEnum.CAN_REVIEW, status: 'NORMAL' }
        }),
      renameProject: (id: ProjectFieldsFragment['id'], name: string) =>
        projectService.projectApiControllerPatchTeamProject({
          teamId: workspaceId,
          projectId: id,
          patchProjectRequest: { name, updateMask: ['name'] }
        }),
      deleteProject: (id: ProjectFieldsFragment['id']) =>
        projectService.projectApiControllerDeleteTeamProject({ teamId: workspaceId, projectId: id })
    }
  }, [workspaceType, workspaceId])
}
