import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'

import CreateTeamDialog from '../../components/modals/CreateTeamDialog'
import { Icon, Popover, SidebarTitle } from '../../components/shared'
import { useWorkSpacePopover } from './useWorkSpacePopover'

const AddNewTeamButton = (currentWorkspaceSlug: any) => {
  const { t } = useTranslation('workspace')

  const popoverTriggerRef = useRef<HTMLDivElement>(null)
  const { setCreateTeamDialogOpen, createTeamDialogOpen } = useWorkSpacePopover({
    currentWorkspaceSlug: currentWorkspaceSlug,
    workspacePopoverOptions: []
  })
  const openCreateTeamDialog = () => {
    setCreateTeamDialogOpen(true)
  }

  return (
    <>
      <SidebarTitle type="secondary" onClick={openCreateTeamDialog}>
        <div className="flex items-center gap-14">
          <div className="flex w-28 h-28 justify-center items-center bg-light-overlay-10 rounded-md">
            <Icon name="Plus" useCurrentColor interactive={false} className="text-white" />
          </div>
          <div className="truncate">{t('add_new_team')}</div>
        </div>
      </SidebarTitle>
      <Popover
        trigger={popoverTriggerRef}
        open={createTeamDialogOpen}
        onClose={() => setCreateTeamDialogOpen(false)}
        className="py-8 w-[232px]"
      >
        <CreateTeamDialog open={createTeamDialogOpen} onClose={() => setCreateTeamDialogOpen(false)} />
      </Popover>
    </>
  )
}

export default AddNewTeamButton
