import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { isEmpty } from 'lodash'

import { EasingType, FrameType } from '@phase-software/types'

import { useInspectContext } from '../../../providers/InspectProvider'
import { useSetNotification } from '../../../providers/NotificationProvider'
import { useInspectPanelSectionState } from '../../../providers/RightPanelProvider'
import { useDataStore, useDataStoreActions } from '../../../providers/dataStore/DataStoreProvider'
import { useEditor } from '../../../providers/dataStore/EditorProvider'
import { useInteractionActions } from '../../../providers/dataStore/InteractionProvider'
import { formatNumberWithUnit } from '../../../utils/formatter'
import { easingTypeNameMap } from '../../Interaction/constant'
import InspectContent from '../PropertyInspection/InspectContent'
import InspectRow from '../PropertyInspection/InspectRow'
import InteractiveInspectContent from '../PropertyInspection/InteractiveInspectContent'
import { shouldShowBezierRow } from '../utils'

type MotionPathInspectionProps = {
  elementId: string
}

type MotionPathContentProps = {
  elementId: string
  animationKeyFrames: any
}

type InitialInspectionRowProps = {
  elementId: string
  label: string
}

const getPointXValue = (value: Number[], origin: any, curveValue?: Number[]) => {
  const curveX = curveValue ? curveValue[0] : 0
  const pointX = formatNumberWithUnit(origin.pos[0] + value[0] + curveX, 'px')
  return pointX
}

const getPointYValue = (value: Number[], origin: any, curveValue?: Number[]) => {
  const curveY = curveValue ? curveValue[1] : 0
  const pointY = formatNumberWithUnit(origin.pos[1] + value[1] + curveY, 'px')
  return pointY
}

const getPointValue = (value: Number[], origin: any, curveValue?: Number[]) => {
  return `${getPointXValue(value, origin, curveValue)}, ${getPointYValue(value, origin, curveValue)}`
}

const InitialInspectionRow = ({ elementId, label }: InitialInspectionRowProps) => {
  const dataStore = useDataStore()
  const origin = dataStore.transition.getPropertyValue(elementId, 'motionPath').motionPath

  return (
    <InspectRow
      type="wide"
      label={label}
      value={`${formatNumberWithUnit(origin.pos[0], 'px')}, ${formatNumberWithUnit(origin.pos[1], 'px')}`}
    />
  )
}

const MotionPathContent = ({ elementId, animationKeyFrames }: MotionPathContentProps) => {
  const { t } = useTranslation('file')
  const dataStore = useDataStore()
  const { getKeyFrame } = useInteractionActions()
  const { getTimeString } = useInspectContext()
  const { setPlayheadTime } = useDataStoreActions()
  const origin = dataStore.transition.getPropertyValue(elementId, 'motionPath').motionPath

  const obj = {} as any
  Object.keys(animationKeyFrames)?.map((time: string) => {
    const keyframe = getKeyFrame(animationKeyFrames[time][0])
    obj[time] = {
      value: keyframe.value,
      easingType: keyframe.easingType,
      bezier: keyframe.bezier,
      frameType: keyframe.frameType
    }
  })

  if (Object.keys(obj).length === 0) return null

  return (
    <>
      {Object.keys(obj).map((key) => {
        return (
          <InspectContent key={key} spacedDivider>
            <InspectRow
              type="wide"
              label={t('inspect.time')}
              value={getTimeString(Number(key))}
              onClick={() => setPlayheadTime(Number(key))}
            />
            {obj[key].frameType === FrameType.INITIAL ? (
              <InitialInspectionRow elementId={elementId} label={t('inspect.value')} />
            ) : (
              <InspectRow type="wide" label={t('inspect.value')} value={getPointValue(obj[key].value.pos, origin)} />
            )}
            {(obj[key].value.in[0] !== 0 || obj[key].value.in[1] !== 0) && obj[key].frameType !== FrameType.INITIAL && (
              <InspectRow
                type="wide"
                label={t('inspect.in')}
                value={getPointValue(obj[key].value.pos, origin, obj[key].value.in)}
                divider="partialBorderBottom"
              />
            )}
            {(obj[key].value.out[0] !== 0 || obj[key].value.out[1] !== 0) &&
              obj[key].frameType !== FrameType.INITIAL && (
                <InspectRow
                  type="wide"
                  label={t('inspect.out')}
                  value={getPointValue(obj[key].value.pos, origin, obj[key].value.out)}
                  divider="partialBorderTop"
                />
              )}
            <InspectRow
              type="wide"
              label={t('inspect.ease')}
              value={t(easingTypeNameMap[obj[key].easingType as keyof typeof easingTypeNameMap], {
                ns: 'file',
                keyPrefix: 'easing_type'
              })}
            />
            {shouldShowBezierRow(obj[key].easingType) && (
              <InspectRow type="wide" label={t('inspect.bezier')} value={obj[key]?.bezier?.join(', ')} />
            )}
          </InspectContent>
        )
      })}
    </>
  )
}

const MotionPathInspection = ({ elementId }: MotionPathInspectionProps) => {
  const { t } = useTranslation(['file', 'workspace'])
  const dataStore = useDataStore()
  const { getPropertyKeyFrameGroupByTime } = useInteractionActions()
  const isPanelOpen = useInspectPanelSectionState('motionPath')
  const alignment = useEditor((o) => o.alignment)
  const { getKeyFrame } = useInteractionActions()
  const { getTimeString } = useInspectContext()
  const { addNotification } = useSetNotification()
  const origin = dataStore.transition.getPropertyValue(elementId, 'motionPath').motionPath

  const animationKeyFrames = useMemo(
    () => getPropertyKeyFrameGroupByTime(elementId, 'motionPath'),
    [elementId, getPropertyKeyFrameGroupByTime]
  )

  const handleCopy = () => {
    let value = ''
    value += `"${t('file:inspect.auto_orient')}": "${String(alignment)}",\n\n`
    Object.keys(animationKeyFrames)?.forEach((time: string, index: number) => {
      const keyframe = getKeyFrame(animationKeyFrames[time][0])
      value += `"${t('file:inspect.keyframe_time')}": "${getTimeString(Number(time))}",\n`
      if (keyframe.frameType === FrameType.INITIAL) {
        value += `"${t('file:inspect.translate')}": {"X": "${formatNumberWithUnit(
          origin.pos[0],
          'px'
        )}", "Y": "${formatNumberWithUnit(origin.pos[1], 'px')}"},\n`
      } else {
        value += `"${t('file:inspect.translate')}": {"X": "${getPointXValue(
          keyframe.value.pos,
          origin
        )}", "Y": "${getPointYValue(keyframe.value.pos, origin)}"},\n`
      }
      if (keyframe.frameType !== FrameType.INITIAL) {
        if (keyframe.value.in[0] !== 0 || keyframe.value.in[1] !== 0) {
          value += `"${t('file:inspect.in')} ${t('file:inspect.position')}": {`
          value += `"X": "${getPointXValue(keyframe.value.pos, origin, keyframe.value.in)}", `
          value += `"Y": "${getPointYValue(keyframe.value.pos, origin, keyframe.value.in)}"},\n`
        }
        if (keyframe.value.out[0] !== 0 || keyframe.value.out[1] !== 0) {
          value += `"${t('file:inspect.out')} ${t('file:inspect.position')}": {`
          value += `"X": "${getPointXValue(keyframe.value.pos, origin, keyframe.value.out)}", `
          value += `"Y": "${getPointYValue(keyframe.value.pos, origin, keyframe.value.out)}"},\n`
        }
      }
      value += `"${t('file:inspect.ease')}": "${t(
        easingTypeNameMap[keyframe.easingType as keyof typeof easingTypeNameMap],
        {
          ns: 'file',
          keyPrefix: 'easing_type'
        }
      )?.toLowerCase()}",\n`
      if (keyframe.bezier && keyframe.easingType !== EasingType.STEP_END) {
        value += `"${t('file:inspect.bezier')}": "(${keyframe.bezier.join(', ')})",\n`
      }
      if (index < Object.keys(animationKeyFrames).length - 1) {
        value += '\n'
      }
    })
    navigator.clipboard.writeText(value?.toString() || '')
    addNotification({
      type: 'success',
      content: t('workspace:message.copied_to_clipboard')
    })
  }

  if (isEmpty(animationKeyFrames)) return null
  return (
    <>
      <InteractiveInspectContent title={t('file:inspect.translate')} target="motionPath" handleCopy={handleCopy}>
        <InspectRow
          type="wide"
          label={t('file:inspect.auto_orient')}
          value={t(String(alignment), {
            ns: 'file',
            keyPrefix: 'inspect'
          })}
        />
      </InteractiveInspectContent>
      {isPanelOpen && (
        <>
          <InspectContent spacedDivider>
            <InitialInspectionRow elementId={elementId} label={t('file:inspect.initial')} />
          </InspectContent>
          <MotionPathContent elementId={elementId} animationKeyFrames={animationKeyFrames} />
        </>
      )}
    </>
  )
}

export default React.memo(MotionPathInspection)
