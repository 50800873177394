import { fetchFile } from '@ffmpeg/util'
import Encoder from '../encoder'

/** @typedef {import('../recorder').RecorderOption} RecorderOption */
/** @typedef {import('@ffmpeg/ffmpeg').FFmpeg} FFmpeg */

export class GIFEncoder extends Encoder {
    /**
     * @param {FFmpeg} ffmpeg
     */
    constructor(ffmpeg) {
        super()

        this.ffmpeg = ffmpeg

        this._width = 0
        this._height = 0
        this._frameRate = 0
        this._speed = 0
        this._loop = true
        this._index = 0

        this._onProgressHandler = null
    }

    /**
     * @param {RecorderOption} options
     */
    init(options) {
        this._width = options.width
        this._height = options.height
        this._frameRate = options.fps
        this._speed = options.speed
        this._loop = options.loop
        this._index = 0

        this._onProgressHandler = null
    }

    static getEstimatedSize({ width, height, fps, duration, transparency, loop, speed }) {
        const headerSize = 14 // 6 bytes for GIF Header + 7 bytes for Logical Screen Descriptor + 1 byte for GCT flag
        const gceSize = 8 // Graphic Control Extension size
        const imageDescriptorSize = 10 // Image Descriptor size
        const netscapeExtensionSize = loop ? 19 : 0 // Netscape Extension block size
        const colorTableSize = 256 * 3 // Assuming full 256 color table used, 3 bytes per color
        const estimatedCompressionEfficiency = 0.05

        const adjustedDuration = duration / speed
        const frameCount = fps * adjustedDuration
        const pixelCount = width * height
        const bytesPerFrame = pixelCount * (transparency ? 4 : 3) // 4 bytes for RGBA, 3 for RGB
        const estimatedCompressedSizePerFrame = bytesPerFrame * estimatedCompressionEfficiency

        // Total estimate
        const estimatedTotalSize = headerSize +
                                (gceSize + imageDescriptorSize + estimatedCompressedSizePerFrame) * frameCount +
                                colorTableSize +
                                netscapeExtensionSize

        const sizeInKilobytes = estimatedTotalSize / 1024

        return sizeInKilobytes
    }

    /**
     * Add frame data to encoder
     * @param {Uint8ClampedArray} pixels
     */
    async addFrame(pixels) {
        const imageBuffer = await fetchFile(pixels)
        await this.ffmpeg.writeFile(`${this._index}.png`, imageBuffer)
        this._index++
    }

    /**
     * Get encoder data
     */
    getData() {
        return this.ffmpeg.readFile("output.gif")
    }

    /**
     * Encode frames to gif
     */
    finalize() {
        return this.ffmpeg.exec([
            '-i', '%d.png',
            '-vf', 'palettegen',
            'palette.png'
        ]).then(() => this.ffmpeg.exec([
            '-framerate', `${this._frameRate}`,
            '-i', '%d.png',
            '-i', 'palette.png',
            `${this._loop ? '-ignore_loop' : '-loop'}`, `${this._loop ? 0 : -1}`,
            '-filter_complex', `[0:v]setpts=${1 / this._speed}*PTS[v];[v][1:v]paletteuse`,
            'output.gif'
        ]))
    }

    /**
     * End of the encoder
     */
    end() {
        if (!this.ffmpeg.loaded) return

        const promises = [this.ffmpeg.deleteFile("output.gif")]
        for (let i = 0; i <= this._index; i++) {
            promises.push(this.ffmpeg.deleteFile(`${i}.png`))
        }

        Promise.all(promises).then(() => {
        }).catch(error => {
            console.error("Error during cleanup:", error)
        }).then(() => {
            this.ffmpeg.terminate()
        })

        if (this._onProgressHandler) {
            this.ffmpeg.off("progress", this._onProgressHandler)
            this._onProgressHandler = null
        }
    }

    /**
     * Cancel encoder
     */
    cancel() {
        this.end()
    }

    onProgress(onProgress) {
        this._onProgressHandler = ({ progress }) => {
            onProgress(Math.min(progress, 1) * 100)
        }
        this.ffmpeg.on("progress", this._onProgressHandler)
    }
}
