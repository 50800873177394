import React from 'react'
import PropTypes from 'prop-types'

export const BackgroundToggle = ({ gray, onChange }) => {
  const handleV = (e) => {
    const v = Number(e.target.value)
    onChange(v)
  }
  return (
    <div className="flex gap-8">
      <span onClick={() => onChange(0)}>🌑</span>
      <input className="w-[32px]" type="range" min={0} max={1} value={gray} onChange={handleV} />
      <span onClick={() => onChange(1)}>☀️</span>
    </div>
  )
}
BackgroundToggle.propTypes = {
  gray: PropTypes.number,
  onChange: PropTypes.func
}
