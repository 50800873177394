import { ElementType, GeometryType } from '@phase-software/types'
import { Group } from './Group'
import { Watcher } from './Watcher'
import { Geometry } from './geometry/Geometry'

/** @typedef {import('@phase-software/data-utils').AABB} AABB */

/** @typedef {import('./DataStore').DataStore} DataStore */
/** @typedef {import('./Path').Path} Path */
/** @typedef {import('./Path').BooleanOperation} BooleanOperation */

export class GeometryGroup extends Group {
    /**
     * creates a blank geometry group
     * @protected
     */
    create() {
        super.create()
        this.data.elementType = ElementType.GEOMETRY_GROUP

        this.data.mask = false
        this.data.booleanOperation = 'NONE'

        /** @type {Path[]} */
        this.data.operands = []
        /** @type {BooleanOperation[]} */
        this.data.operations = []

        // TODO: should we create a polygon here?
        const polygon = new Geometry(this.dataStore)
        polygon.setGeometryType(GeometryType.POLYGON)
        this.data.geometry = new Watcher(polygon)
    }

    /**
     * populates data from a Group
     * @param {GroupData} data
     */
    load(data) {
        super.load({...data, elementType: ElementType.GEOMETRY_GROUP})

        this.data.mask = data.mask
        this.data.booleanOperation = data.booleanOperation

        this.data.operands = this.children.filter(path => path.data.booleanOperation !== 'NONE')
        this.data.operations = this.children.filter(path => path.data.booleanOperation)

        const polygon = new Geometry(this.dataStore, data.geometry)
        this.data.geometry = new Watcher()
        this.data.geometry.change(polygon)
    }

    /**
     * @param {object} [overrides] data object with overrides
     * @returns {GeometryGroup}
     */
    clone(overrides) {
        const obj = super.clone(overrides)

        obj.data.mask = this.data.mask
        obj.data.booleanOperation = this.data.booleanOperation

        obj.data.operands = obj.children.filter(path => path.data.booleanOperation !== 'NONE')
        obj.data.operations = obj.children.filter(path => path.data.booleanOperation)

        obj.data.geometry = this.data.geometry.clone()
        return obj
    }

    save() {
        const data = super.save()
        data.mask = this.data.mask
        data.booleanOperation = this.data.booleanOperation
        data.geometry = this.data.geometry.watched.save()

        return data
    }
}

/** @typedef {import('./Group').GroupData} GroupData */
/** @typedef {import('./geometry/Polygon').PolygonData} PolygonData */

/**
 * @typedef {GroupData} GeometryGroupData
 * @property {PolygonData} geometry
 */
