import { useCallback } from 'react'
import { createProvider } from './utils'
import { useDataStore } from './dataStore/DataStoreProvider'
const defaultValue = {}
const [Provider, useSelectState, useSetState, getStateSnapshot] = createProvider('Modal', defaultValue)

export const defaultModalState = { open: false, data: {} }

export const useModal = (fn) => {
  return useSelectState(fn) || defaultModalState
}
export const getModalSnapshot = getStateSnapshot

export const useModalActions = () => {
  const setState = useSetState()

  const closeModal = useCallback(
    (modalKey) => {
      setState((state) => ({
        ...state,
        [modalKey]: defaultModalState
      }))
    },
    [setState]
  )

  return { closeModal }
}

export const useSetModal = (modalKey) => {
  const dataStore = useDataStore()
  const setState = useSetState()

  const openModal = useCallback(
    (data = {}) => {
      dataStore?.eam.openModal(modalKey)
      setState((state) => ({
        ...state,
        [modalKey]: { open: true, data }
      }))
    },
    [dataStore, modalKey, setState]
  )

  const closeModal = useCallback(() => {
    dataStore?.eam.closeModal(modalKey)
    setState((state) => ({
      ...state,
      [modalKey]: defaultModalState
    }))
  }, [dataStore, modalKey, setState])

  const toggleModal = useCallback(
    (data = {}) => {
      setState((state) => {
        const isModalOpen = state[modalKey]?.open ?? defaultModalState.open

        if (isModalOpen) {
          closeModal(data)
        } else {
          openModal(data)
        }
      })
    },
    [setState, modalKey, closeModal, openModal]
  )

  const updateModalData = useCallback(
    (data) => {
      setState((state) => ({
        ...state,
        [modalKey]: {
          ...state[modalKey],
          data: {
            ...(state[modalKey] || defaultModalState).data,
            ...data
          }
        }
      }))
    },
    [modalKey, setState]
  )

  const closeAllModals = useCallback(() => {
    setState((state) =>
      Object.keys(state).reduce((acc, key) => {
        acc[key] = {
          ...state[key],
          open: false
        }
        return acc
      }, {})
    )
  }, [setState])

  const deleteModal = useCallback(() => {
    setState((state) => {
      const newState = { ...state }
      delete newState[modalKey]
      return newState
    })
  }, [modalKey, setState])

  return { openModal, toggleModal, closeModal, updateModalData, closeAllModals, deleteModal }
}

export default Provider
