import { PropComponentType } from '@phase-software/types'
import { notNull } from '@phase-software/data-utils'
import PropertyComponent from './PropertyComponent'

/** @typedef {import('../DataStore').DataStore} DataStore */
/** @typedef {import('./PropertyComponent').AppliedRef} AppliedRef */

export default class ScaleComponent extends PropertyComponent {
    /**
     * @param {DataStore} dataStore
     * @param {ScaleComponentData} [data]
     * @param {object} [options] 
     * @param {bool} [options.regenId=false]   if set to true, will generate new ID
     */
    constructor(dataStore, data = {}, options) {
        super(dataStore, data, options)

        const { scaleX, scaleY } = data

        this.scaleX = notNull(scaleX) ? scaleX : 1
        this.scaleY = notNull(scaleY) ? scaleY : 1
        this.componentType = PropComponentType.SCALE

        if (!this.name) {
            this.name = 'scale'
        }
    }

    /** @param {Partial<ScaleComponentData>} data */
    set(data) {
        super.set(data)

        if (notNull(data.scaleX)) {
            this.updateProp('scaleX', data.scaleX)
        }
        if (notNull(data.scaleY)) {
            this.updateProp('scaleY', data.scaleY)
        }
    }

    /**
     * Override this in subclasses
     * CALL super._clone() at the top of overriden method
     * @protected
     * @param {AppliedRef} [ref]
     * @returns {ScaleComponent}
     */
    _clone(ref) {
        const obj = super._clone(ref)
        obj.scaleX = this.scaleX
        obj.scaleY = this.scaleY
        return obj
    }

    /**
     * Override this in subclasses
     * CALL super._save() at the top of overriden method
     * @protected
     * @returns {ScaleComponentData}
     */
    _save() {
        const data = super._save()
        data.scaleX = this.scaleX
        data.scaleY = this.scaleY
        return data
    }
}

/** @typedef {import('./PropertyComponent').PropertyComponentData} PropertyComponentData */

/**
 * @typedef {ScaleComponentData} ScaleComponentData
 * @property {number} scaleX
 * @property {number} scaleY
 */
