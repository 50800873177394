import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useApolloClient } from '@apollo/client'

import { useBroadcaster } from '../../../../Broadcaster'
import { PERMISSIONS, useAccessControl } from '../../../../access-control'
import { ContextMenu } from '../../../../components/shared'
import { MenuListOptionProps, MenuOptionProps } from '../../../../components/shared/Menu/Menu.types'
import { isSeparatorOption, removeConsecutiveSeparators } from '../../../../components/shared/Menu/utils'
import { VersionFieldsFragmentDoc } from '../../../../generated/graphql'
import useFileVersionActions from '../../../../hooks/useFileVersionActions'
import useHeapAnalytics from '../../../../hooks/useHeapAnalytics'
import { useFilePermissionContext } from '../../../../providers/FilePermissionProvider'
import { useFileVersionContext } from '../../../../providers/FileVersionContextProvider'
import { useModal, useSetModal } from '../../../../providers/ModalProvider'
import { useSetNotification } from '../../../../providers/NotificationProvider'
import { useWorkspaceContext } from '../../../../providers/WorkspaceContextProvider'
import { useDataStoreActions } from '../../../../providers/dataStore/DataStoreProvider'
import { useUIActions } from '../../../../providers/dataStore/UIProvider'
import { track } from '../../../../services/heapAnalytics'
import { VersionType } from '../../../../utils/transformVersion'
import CreateFileFromVersionDialog from './CreateFileFromVersionDialog'
import UpdateVersionDialog from './UpdateVersionDialog'
import { SPECIFIC_VERSION_PARAMETER_NAME } from './useVersionLoader'

type VersionItemContextMenuProps = {
  id: string
  type: VersionType
  name: string
  description: string
  defaultFileName: string
  projectId: string
  fileId: string
  children: React.ReactNode
}

const VALUES = {
  CREATE_VERSION: 'CREATE_VERSION',
  NAME_VERSION: 'NAME_VERSION',
  EDIT_VERSION_INFO: 'EDIT_VERSION',
  RESTORE_VERSION: 'RESTORE_VERSION',
  MAKE_A_COPY: 'COPY_VERSION_TO_FILE',
  COPY_LINK: 'COPY_LINK',
  REMOVE_NAME: 'REMOVE_NAME'
}

const VersionItemContextMenu = ({
  id,
  type,
  name,
  description,
  defaultFileName,
  projectId,
  fileId,
  children
}: VersionItemContextMenuProps) => {
  const client = useApolloClient()
  const { userHasPermission } = useAccessControl()
  const { fileWithPermission } = useFilePermissionContext()
  const { notifyVersionAdded } = useFileVersionContext()
  const { t } = useTranslation(['file', 'workspace', 'common'])
  const broadcaster = useBroadcaster()

  const options: MenuListOptionProps[] = useMemo(
    () => [
      {
        name: t('version.name_version'),
        value: VALUES.NAME_VERSION,
        dataTestId: 'name-version',
        permission: PERMISSIONS.EDIT_FILE
      },
      {
        name: t('version.edit_version_info'),
        value: VALUES.EDIT_VERSION_INFO,
        dataTestId: 'edit-version-info',
        permission: PERMISSIONS.EDIT_FILE
      },
      {
        name: t('version.restore_version'),
        value: VALUES.RESTORE_VERSION,
        dataTestId: 'restore-version',
        permission: PERMISSIONS.EDIT_FILE
      },
      {
        name: t('version.make_a_copy'),
        value: VALUES.MAKE_A_COPY,
        dataTestId: 'make-a-copy',
        permission: PERMISSIONS.EDIT_FILE
      },
      {
        name: t('version.copy_link'),
        value: VALUES.COPY_LINK,
        dataTestId: 'copy-link'
      },
      '-',
      {
        name: t('version.remove_name'),
        value: VALUES.REMOVE_NAME,
        dataTestId: 'remove-name',
        permission: PERMISSIONS.EDIT_FILE
      }
    ],
    [t]
  )

  const {
    open,
    data: { trigger, cursorPosition }
  } = useModal((o: any) => o[`VersionItemContextMenu-${id}`])
  const { addNotification } = useSetNotification()
  const { leaveVersionPreview } = useUIActions()
  const { clearUndo } = useDataStoreActions()
  const { workspaceData } = useWorkspaceContext()

  const { resetVersion, restoreVersion } = useFileVersionActions()
  const { closeModal } = useSetModal(`VersionItemContextMenu-${id}`)
  const { teamName, space } = useHeapAnalytics()

  const [isUpdateVersionDialogOpen, setIsUpdateVersionDialogOpen] = useState(false)
  const [isCreateFileFromVersionDialogOpen, setIsCreateFileFromVersionOpen] = useState(false)

  const isLatestVersion = type === VersionType.LATEST
  const isNamedVersion = type === VersionType.NAMED
  const location = projectId === workspaceData.draftProjectId ? 'drafts' : 'project'

  const menuOptions = useMemo(() => {
    const filteredOptions = options.filter((option) => {
      if (isSeparatorOption(option)) {
        return true
      }

      if (isLatestVersion) {
        return false
      }

      const isAllowed = !option.permission || userHasPermission(option.permission, fileWithPermission)

      if (isNamedVersion) {
        return option.value !== VALUES.NAME_VERSION && isAllowed
      }

      return option.value !== VALUES.EDIT_VERSION_INFO && option.value !== VALUES.REMOVE_NAME && isAllowed
    })
    return removeConsecutiveSeparators(filteredOptions)
  }, [fileWithPermission, isLatestVersion, isNamedVersion, userHasPermission, options])

  const handleSelect = async (option: MenuOptionProps) => {
    switch (option.value) {
      case VALUES.NAME_VERSION:
      case VALUES.EDIT_VERSION_INFO: {
        setIsUpdateVersionDialogOpen(true)
        break
      }
      case VALUES.RESTORE_VERSION: {
        await restoreVersion({ fileId, projectId, versionId: id, tabId: broadcaster?.uid || '' })
        notifyVersionAdded()
        clearUndo()
        leaveVersionPreview()
        addNotification({
          type: 'success',
          content: t('workspace:message.version_restored')
        })
        track('Version Restored', { fileId, teamName, location, space })
        break
      }
      case VALUES.MAKE_A_COPY: {
        setIsCreateFileFromVersionOpen(true)
        break
      }
      case VALUES.COPY_LINK: {
        const url = new URL(window.location.href)
        url.searchParams.set(SPECIFIC_VERSION_PARAMETER_NAME, id)
        navigator.clipboard.writeText(url.toString())

        addNotification({
          type: 'success',
          content: t('common:link_copied')
        })
        break
      }
      case VALUES.REMOVE_NAME:
        await resetVersion({ fileId, projectId, versionId: id })
        client.cache.updateFragment(
          {
            id: `versions:${id}`,
            fragment: VersionFieldsFragmentDoc,
            fragmentName: 'versionFields'
          },
          (data) => ({
            ...data,
            save_type: VersionType.UNNAMED,
            name: null,
            description: null,
            creator_id: null
          })
        )
        track('Version Name Removed', { fileId, teamName, location, space })
        break
      default:
        break
    }
  }

  return (
    <>
      {menuOptions.length > 0 && children}
      <ContextMenu
        open={open}
        options={menuOptions}
        onSelect={handleSelect}
        onClose={closeModal}
        trigger={trigger}
        cursorPosition={cursorPosition}
        offsetY={8}
      />
      <UpdateVersionDialog
        projectId={projectId}
        fileId={fileId}
        versionId={id}
        isNamedVersion={isNamedVersion}
        originalName={name}
        originalDescription={description}
        open={isUpdateVersionDialogOpen}
        onClose={() => setIsUpdateVersionDialogOpen(false)}
      />
      <CreateFileFromVersionDialog
        fileId={fileId}
        onClose={() => setIsCreateFileFromVersionOpen(false)}
        open={isCreateFileFromVersionDialogOpen}
        originalName={defaultFileName}
        projectId={projectId}
        versionId={id}
      />
    </>
  )
}

export default VersionItemContextMenu
