import React, { createContext, useContext } from 'react'
import { Rules } from '../interfaces'
import roleHasPermission from '../utils/roleHasPermission'
import ensureArray from '../utils/ensureArray'

interface AccessControlProviderProps<Role extends string, Permission extends string, User> {
  children: React.ReactNode
  role: Role
  rules: Rules<Role, Permission, User>
  user: User
}

type AccessControlContextProps<Permission extends string> = {
  userHasPermission: <Data>(requiredPermissions: Permission, data?: Data) => boolean
}

const AccessControlContext = createContext<AccessControlContextProps<any>>({
  userHasPermission: () => {
    console.error(`Can't call userHasPermission, wrap your app with an <AccessControlProvider />.`)
    return false
  }
})

const AccessControlProvider = <Role extends string, Permission extends string, User>({
  user,
  role,
  rules,
  children
}: AccessControlProviderProps<Role, Permission, User>) => {
  const userHasPermission = (requiredPermissions: Permission[] | Permission, data: any) =>
    ensureArray(requiredPermissions).every((permission: Permission) =>
      roleHasPermission(rules, role, permission, user, data)
    )

  return <AccessControlContext.Provider value={{ userHasPermission }}>{children}</AccessControlContext.Provider>
}

const useAccessControl = () => {
  const context = useContext(AccessControlContext)
  if (!context) {
    throw new Error('useAccessControl must be used within a <AccessControlProvider />')
  }
  return context
}

export { AccessControlProvider, useAccessControl }
