export type LottieFeature = {
  text: string
  compatible?: string
}

export type LottieFeatureByFile = {
  [key: string]: string[]
}

export const DEBUG_LOTTIE_STATS_FILE_NAME = 'STATS'

export const DEBUG_UNSUPPORTED_LOTTIE_FEATURE_SET = new Set([
  // animation
  'THREE_D',
  'MOTION_BLUR',
  // prop
  'EXPRESSIONS',
  // layer
  'TEXT_LAYER',
  'AUDIO_LAYER',
  'VIDEO_PLACEHOLDER_LAYER',
  'IMAGE_SEQUENCE_LAYER',
  'VIDEO_LAYER',
  'IMAGE_PLACEHOLDER_LAYER',
  'GUIDE_LAYER',
  'ADJUSTMENT_LAYER',
  'CAMERA_LAYER',
  'LIGHT_LAYER',

  // matte
  'MATTE_INVERTED_ALPHA',
  'MATTE_LUMA',
  'MATTE_INVERTED_LUMA',

  // shape
  'REPEATER_SHAPE',
  'OFFSET_PATH_SHAPE',
  'STAR_SHAPE',
  'TWIST_SHAPE',
  'PUCKER_BLOAT_SHAPE',
  'ZIG_ZAG_SHAPE',
  // mask
  'MASK_SUBTRACT',
  'MASK_INTERSECT',
  'MASK_LIGHTEN',
  'MASK_DARKEN',
  'MASK_DIFFERENCE',
  'MASK_INVERT',
  // effect
  'EFFECT_NONE',
  'EFFECT_CUSTOM',
  'EFFECT_PAINT_OVER_TRANSPARENT',
  'EFFECT_TINT',
  'EFFECT_FILL',
  'EFFECT_STROKE',
  'EFFECT_TRITONE',
  'EFFECT_PRO_LEVELS',
  'EFFECT_DROP_SHADOW',
  'EFFECT_RADIAL_WIPE',
  'EFFECT_DISPLACEMENT_MAP',
  'EFFECT_MATTE3',
  'EFFECT_GAUSSIAN_BLUR',
  'EFFECT_MESH_WRAP',
  'EFFECT_WAVY',
  'EFFECT_SPHERIZE',
  'EFFECT_PUPPET',
  // stroke prop
  'HIGHLIGHT_LENGTH',
  'HIGHLIGHT_ANGLE',
  // repeater
  'REPEATER_OFFSET',
  // shape direction
  'SHAPE_DIRECTION_REVERSED',
  'TIME_REMAPPING',
  'SKEW_ANGLE'
])

export const DEBUG_PRACTICAL_SUPPORTED_LOTTIE_FEATURE_SET = new Set(['SKEW'])

export const DEBUG_WATCHED_LOTTIE_FEATURE_SET = new Set(['PRECOMP_LAYER', 'IMAGE_LAYER', 'NULL_LAYER', 'PARENTING'])

export const UNSUPPORTED_LOTTIE_FEATURE_MAP = new Map(
  Object.entries({
    // shapes
    STAR_SHAPE: {
      text: 'polystar'
    },
    // transform
    SKEW_ANGLE: {
      text: 'skew',
      compatible: 'partial'
    },

    // masks
    MASK_DARKEN: {
      text: 'mask_darken'
    },
    MASK_DIFFERENCE: {
      text: 'mask_difference',
      compatible: 'partial'
    },
    MASK_INTERSECT: {
      text: 'mask_intersect'
    },
    MASK_INVERT: {
      text: 'mask_inverted'
    },
    MASK_LIGHTEN: {
      text: 'mask_lighten'
    },
    MASK_SUBTRACT: {
      text: 'mask_subtract'
    },

    // mattes
    MATTE_INVERTED_ALPHA: {
      text: 'alpha_inverted_matte'
    },
    MATTE_INVERTED_LUMA: {
      text: 'luma_inverted_matte'
    },
    MATTE_LUMA: {
      text: 'luma_matte'
    },

    // layer effects
    EFFECT_DISPLACEMENT_MAP: {
      text: 'displacement_map_effect'
    },
    EFFECT_DROP_SHADOW: {
      text: 'drop_shadow_effect'
    },
    EFFECT_FILL: {
      text: 'fill_effect'
    },
    EFFECT_GAUSSIAN_BLUR: {
      text: 'gaussian_blur_effect'
    },
    EFFECT_MATTE3: {
      text: 'matte3_effect'
    },
    EFFECT_MESH_WRAP: {
      text: 'mesh_warp_effect'
    },
    EFFECT_PRO_LEVELS: {
      text: 'pro_levels_effect'
    },
    EFFECT_PUPPET: {
      text: 'puppet_effect'
    },
    EFFECT_RADIAL_WIPE: {
      text: 'radial_wipe_effect'
    },
    EFFECT_SPHERIZE: {
      text: 'spherize_effect'
    },
    EFFECT_STROKE: {
      text: 'stroke_effect'
    },
    EFFECT_TINT: {
      text: 'tint_effect'
    },
    EFFECT_TRITONE: {
      text: 'tritone_effect'
    },
    EFFECT_WAVY: {
      text: 'wavy_effect'
    },

    // text
    TEXT_LAYER: {
      text: 'text'
    },

    // others
    THREE_D: {
      text: '3d'
    },
    EXPRESSIONS: {
      text: 'expression'
    },
    MOTION_BLUR: {
      text: 'motion_blur'
    },
    TIME_REMAPPING: {
      text: 'time_ramp'
    },

    // layers
    ADJUSTMENT_LAYER: {
      text: 'adjustment_layer'
    },
    AUDIO_LAYER: {
      text: 'audio_layer'
    },
    CAMERA_LAYER: {
      text: 'camera_layer'
    },
    GUIDE_LAYER: {
      text: 'guide_layer'
    },
    LIGHT_LAYER: {
      text: 'light_layer'
    },
    VIDEO_LAYER: {
      text: 'video_layer'
    },
    VIDEO_PLACEHOLDER_LAYER: {
      text: 'video_placeholder'
    },

    // shape modifier
    OFFSET_PATH_SHAPE: {
      text: 'offset_path'
    },
    PUCKER_BLOAT_SHAPE: {
      text: 'pucker_bloat_shape'
    },
    REPEATER_OFFSET: {
      text: 'repeater'
    },
    REPEATER_SHAPE: {
      text: 'repeater'
    },
    TWIST_SHAPE: {
      text: 'twist'
    },
    ZIG_ZAG_SHAPE: {
      text: 'zig_zag'
    },

    // layer style
    HIGHLIGHT_LENGTH: {
      text: 'gradient_overlay_style'
    },
    HIGHLIGHT_ANGLE: {
      text: 'gradient_overlay_style'
    }
  })
)
