import PropertyStack from '../../PropertyStack'

class JoinShapeStack extends PropertyStack {
  constructor(elementStack, data) {
    super(elementStack, data)
    this.type = 'JOIN_SHAPE'
    this.key = 'joinShape'
    this.dataKey = 'join'
    this.animatableProperties = new Set()
  }
}

export default JoinShapeStack
