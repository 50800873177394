import { Bezier } from 'bezier-js/src/bezier'
import { vec2 } from 'gl-matrix'
import { EPSILON,
    isNull,
    vec2Equals,
    vec2InverseLerp
} from '../commons'
import { isPointOnSegment } from '../utils'
import { Vector2 } from '../Vector2'
import { AABB } from '../AABB'
import { Cell, FlagsEnum } from './Cell'


/** @typedef {import('./Vertex').Vertex} Vertex */
/** @typedef {import('./Contour').Contour} Contour */

/**
 *  @typedef {object} EdgeSplits
 *  @property {Vector2[]} atPositions    positions (in the edge's coordinate space) of the split
 *                                       points; one for each split t-value
 *  @property {Vector2[][]} newCurves   new curves (two control points in the format of Edge#curves)
 *                                      resulting from spliting original curve at split point t-values;
 *                                      newCurves.length === ts.length + 1
 */



export class HalfEdge {
    /**
     * @param {Edge} edge           the parent Edge of this HalfEdge
     * @param {Vertex} v            starting Vertex of this HalfEdge
     */
    constructor(edge, v) {
        if (isNull(edge) || isNull(v)) {
            throw new Error("Trying to create HalfEdge with no parent edge or undefined origin")
        }
        // parent edge object
        this.edge = edge
        // origin of the edge
        this.v = v
        // incident contours - to the left of the half-edge
        /** @type {Set<Contour>} */
        this.contours = new Set()
        // twin half-edge
        /** @type {HalfEdge} */
        this.twin = undefined
    }

    get isVirtual() {
        return this.edge.isVirtual
    }

    /**
     * @returns {number} index of this HalfEdge in its parent Edge (0 or 1)
     */
    get index() {
        return this.edge.getHalfIndex(this)
    }

    /**
     * @returns {Vertex} end Vertex of this HalfEdge
     */
    get w() {
        return this.twin.v
    }

    /**
     * @returns {HalfEdge[]} all HalfEdges going out of the end of this HalfEdge
     */
    get next() {
        return this.w.getOutHalves()
    }

    /**
     * @returns {HalfEdge[]} all HalfEdges going into the start of this HalfEdge
     */
    get prev() {
        return this.v.getInHalves()
    }

    /*
     * @returns {AABB}  bound of the Edge this HalfEdge belong too
     */
    get bounds() {
        return this.edge.bounds
    }

    /**
     * Test if position `pos` is on this half edge (excluding the edge's endpoints)
     * @param  {Vector2}  pos
     * @returns {boolean}       true if `pos` is on this edge;
     */
    has(pos) {
        const a = this.v.pos,
            b = this.w.pos
        const collinear = (b[0] - a[0]) * (pos[1] - a[1]) === (pos[0] - a[0]) * (b[1] - a[1])
        return collinear && Math.abs(a[0] - b[0]) > EPSILON
            ? (a[0] < pos[0] && pos[0] < b[0]) || (b[0] < pos[0] && pos[0] < a[0])
            : (a[1] < pos[1] && pos[1] < b[1]) || (b[1] < pos[1] && pos[1] < a[1])
    }

    /**
     * @param {Contour} contour
     */
    addContour(contour) {
        this.contours.add(contour)
    }

    /**
     * @param {Contour} contour
     */
    removeContour(contour) {
        this.contours.delete(contour)
    }

    /**
     * @param {[Vector2, Vector2]} curve
     * @param {number} [epsilon]
     * @returns {boolean}
     */
    hasMatchingCurve(curve, epsilon = EPSILON) {
        if (!curve || !this.edge.isCurve) {
            return false
        }
        const index = this.index
        const curveControls = this.edge.curve
        return vec2Equals(curveControls[index === 0 ? 0 : 1].pos, curve[0], epsilon) &&
            vec2Equals(curveControls[index === 0 ? 1 : 0].pos, curve[1], epsilon)
    }

    hasVirtualEdges() {
        return this.edge.hasVirtualEdges()
    }
}

export class Edge extends Cell {
    /**
     * Creates new Edge instance
     * @param {boolean} [virtual=false]
     */
    constructor(virtual = false) {
        super()
        /** @type {[HalfEdge, HalfEdge]} */
        this.halves = []
        /** @type {[Vertex, Vertex]} The absolute position of the control points */
        this.curve = []

        this.virtualEdges = new Set()
        this.bounds = new AABB()

        this.isVirtual = virtual

        /** @type {Bezier} */
        this._bezier = null
        this.type = 'Edge'
    }

    /**
     * Creates new Edge object from data
     * @param {EdgeDataIn} data
     * @param {boolean} [keepCurveControl]
     * @returns {Edge}
     */
    static fromData({ id, v, cpV, cpW, w }, keepCurveControl = false) {
        super.fromData({ id })
        const e = new Edge()
        /** @todo Instead of the id of Setter */
        e.id = id
        e._createHalves(v, w)
        e.setCurve(cpV, cpW, keepCurveControl)
        e.updateBounds()
        e.type = 'Edge'
        return e
    }

    /**
     * @param {Omit<EdgeDataIn, 'id'>} options
     * @param {boolean} [keepCurveControl]
     */
    set({ v, cpV, cpW, w }, keepCurveControl = false) {
        if (isNull(v) || isNull(w) || !v.pos || !w.pos || isNull(cpV) || isNull(cpW) || !cpV.pos || !cpW.pos) {
            return
        }
        this._createHalves(v, w)

        this.setCurve(cpV, cpW, keepCurveControl)
        this.updateBounds()
    }

    /**
     * @param {Vertex} cpV
     * @param {Vertex} cpW
     * @param {boolean} keepCurveControl
     */
    setCurve(cpV, cpW, keepCurveControl = false) {
        this.curve = [cpV, cpW]
        for (let i = 0; i < 2; i++) {
            const connVert = this.halves[i].v
            if (!keepCurveControl) {
                this.curve[i].set(connVert)
            }
            this.curve[i].flag(FlagsEnum.CURVE_VERT)
            this.curve[i].adjacentMainVertex = connVert
            this.curve[i].cornerRadius = connVert.cornerRadius
            this.curve[i].controllingEdge = this
        }

        if (!this.isCurve) {
            this._bezier = null
        }
    }

    /**
     * Create a copy of the edge provided
     * @param  {Vertex} v
     * @param {Vertex} cpV
     * @param {Vertex} cpW
     * @param  {Vertex} w
     * @returns {Edge}
     */
    copy(v,cpV, cpW, w) {
        const copy = new Edge()
        copy.set({v, cpV, cpW, w}, true)
        copy.bounds.copy(this.bounds)
        return copy
    }

    /**
     * @returns {Vertex} start Vertex of this Edge
     */
    get v() {
        return this.halves[0].v
    }

    /**
     * @returns {Vertex} end Vertex of this Edge
     */
    get w() {
        return this.halves[1].v
    }

    /**
     * Serialize Edge data
     * @param {boolean} [figmaFormat]
     * @returns {EdgeData}
     */
    save( figmaFormat = false ) {
        /** @type {EdgeData} */
        const data = {}
        data.id = this.id
        data.v = this.v.id
        data.w = this.w.id
        if (!figmaFormat) {
            data.curveIds = [this.curve[0].id, this.curve[1].id]
            data.curve = this.isCurve ? [[this.curve[0].pos[0], this.curve[0].pos[1]], [this.curve[1].pos[0], this.curve[1].pos[1]]] : null
        } else {
            data.curve = this.isCurve ? [[...this.curve[0].pos], [...this.curve[1].pos]] : null
        }
        return data
    }

    /**
     * Get curve control point on the V side
     * @returns {Vertex}
     */
    get cpV() {
        return this.curve[0]
    }

    /**
     * Get curve control point on the W side
     * @returns {Vertex}
     */
    get cpW() {
        return this.curve[1]
    }

    get isStraight() {
        // They are definitely in the coordinate without any transformation, and can use the simple conditions accordingly.
        return Math.abs(this.cpV.pos[0] - this.v.pos[0]) < EPSILON &&
            Math.abs(this.cpV.pos[1] - this.v.pos[1]) < EPSILON &&
            Math.abs(this.cpW.pos[0] - this.w.pos[0]) < EPSILON &&
            Math.abs(this.cpW.pos[1] - this.w.pos[1]) < EPSILON
    }

    get isCurve() {
        return !this.isStraight
    }
    
    *getContours() {
        for (const half of this.halves) {
            for (const c of half.contours) {
                yield c
            }
        }
    }

    disconnect() {
        this.v.disconnectEdge(this)
        this.w.disconnectEdge(this)

        const contours = []
        for (const half of this.halves) {
            for (const c of half.contours) {
                c.removeHalfEdge(half)
                contours.push(c)
            }
            half.contours = new Set()
        }
        return contours
    }

    /**
     * Get index of the HalfEdge
     * @param  {HalfEdge} half
     * @returns {number}
     */
    getHalfIndex(half) {
        if (this.halves[0] === half) {
            return 0
        } else if (this.halves[1] === half) {
            return 1
        }
        return -1
    }

    /**
     * Returns the half directed from v to w
     * @param  {Vertex} v
     * @param  {Vertex} w
     * @returns {HalfEdge}
     */
    getHalf(v, w) {
        if (this.v === v && this.w === w) {
            return this.halves[0]
        } else if (this.w === v && this.v === w) {
            return this.halves[1]
        }
    }

    /**
     * Returns the opposite end of the edge
     * @param  {Vertex} v
     * @returns {Vertex}
     */
    getOtherEnd(v) {
        if (this.v === v) {
            return this.w
        } else if (this.w === v) {
            return this.v
        }
    }

    /**
     * Checks if point is on this edge
     * @param  {[number, number]}  point        vec2-like point coordinate
     * @returns {boolean}       true if point is on the Edge, false otherwise
     */
    hasPoint(point) {
        if (vec2Equals(this.v.pos, point)) {
            return true
        }
        if (vec2Equals(this.w.pos, point)) {
            return true
        }
        return isPointOnSegment(this.v.pos, this.w.pos, point)
        // TODO: if this is a curve
    }

    hasVirtualEdges() {
        return this.virtualEdges.size > 0
    }

    /**
     * Checks if this Edge has intersection with another edge
     *  (excluding when both edges share one or both points)
     * @param  {Edge | [Vector2, Vector2]}  edge          Edge or an array of two points
     * @returns {boolean}       true if intersects, false otherwise
     */
    hasIntersectionWith(edge) {
        if (!this.isCurve && !edge.isCurve) {
            const ep1 = edge instanceof Edge ? edge.v.pos : edge[0]
            const ep2 = edge instanceof Edge ? edge.w.pos : edge[1]
            return segmentIntersection(this.v.pos, this.w.pos, ep1, ep2)[0].length > 0
        }
        if (this.isCurve && edge.isCurve) {
            // TODO: if edge is not real Edge and doesn't have edge._bezier
            return this._bezier.intersects(edge._bezier).length > 0
        }

        const line = this.isCurve
            ? {
                p1: edge instanceof Edge ? edge.v.pos : edge[0],
                p2: edge instanceof Edge ? edge.w.pos : edge[1]
            }
            : { p1: this.v.pos, p2: this.w.pos }
        const curve = this.isCurve ? this._bezier : edge._bezier
        return curve.intersects(line).length > 0
    }

    /**
     * Returns list of intersection points
     * @param {Edge} [edge]
     * @returns {number[][]}    list of intersection points t-values [0, 1] along the
     *                          length of the edge, one for each of the two intersecting edges
     */
    intersect(edge) {
        if (!edge) {
            // self-intersections (for curves)
            if (this.isCurve) {
                // results always have two t-values, and only for a single edge: [[t1, t2]]
                return this._bezier.intersects().slice(0, 1)
                    .map(pairStr => pairStr.split('/').map(t => parseFloat(t)))
            }
        }
        // two lines
        if (!this.isCurve && !edge.isCurve) {
            //  results will have one t-value for each edge: [[e1_t], [e2_t]]
            return segmentIntersection(this.v.pos, this.w.pos, edge.v.pos, edge.w.pos)
        }
        // two curves
        if (this.isCurve && edge.isCurve) {
            // intersect curves and filter out repeating entries
            // results will have list of t-values (up to two) for each orginal curve: [[e1_t1, e1_t2], [e2_t1, e2_t2]]
            return this._bezier.intersects(edge._bezier)
                .map(pairStr => pairStr.split("/")
                .map(t => parseFloat(t)))
                .reduce((acc, pair, i) => {
                    if (i === 0 || (
                        Math.abs(acc[0][acc[0].length - 1] - pair[0]) > 0.05 &&
                        Math.abs(acc[1][acc[1].length - 1] - pair[1]) > 0.05
                    )) {
                        acc[0].push(pair[0])
                        acc[1].push(pair[1])
                    }
                    return acc
                }, [[], []])
        }
        // line and curve
        const line = this.isCurve ? { p1: edge.v.pos, p2: edge.w.pos } : { p1: this.v.pos, p2: this.w.pos }
        const curve = edge.isCurve ? edge._bezier : this._bezier
        const result = curve.intersects(line).sort()
        const lineTValues = result.map(
            t => vec2InverseLerp(edge.v.pos, edge.w.pos, new Vector2(curve.get(t)))
        )
        return this.isCurve ? [result, lineTValues] : [lineTValues, result]
    }

    /**
     * Split the edge at particular interfals defined by t-values along the length of the edge.
     * @param  {number[]} ts  list of t-values [0, 1] along the length of the edge to split at
     * @returns {EdgeSplits}    split results
     */
    split(ts) {
        /** @type {Vector2[][]} */
        let newCurves
        /** @type {Vector2[]} */
        let atPositions
        if (!ts || ts.length === 0) {
            return { atPositions: [], newCurves: [] }
        }

        if (this.isCurve) {
            const curve = this._bezier
            atPositions = ts.map(t => new Vector2(curve.get(t)))
            newCurves = splitCurve(curve, ts)
        }
        else {
            atPositions = ts.map(t => vec2.lerp(new Vector2(), this.v.pos, this.w.pos, t))
            newCurves = []
        }
        return { atPositions, newCurves }
    }

    /**
     * @param {Edge[]} edges
     */
    addVirtualEdges(edges) {
        for (const e of edges) {
            if (e.isVirtual) {
                this.virtualEdges.add(e)
            }
        }
    }

    removeVirtualEdges(edges) {
        if (!edges) {
            this.virtualEdges.clear()
            return
        }
        for (const e of edges) {
            this.virtualEdges.delete(e)
        }
    }

    /**
     * Update edge bounds
     */
    updateBounds() {
        this.bounds.reset()
        if (this.isCurve) {
            this._updateBezier()
            const bbox = this._bezier.bbox()
            this.bounds.minMax({
                min: new Vector2(bbox.x.min, bbox.y.min),
                max: new Vector2(bbox.x.max, bbox.y.max)
            })
        } else {
            this.bounds.minMax(this.v.pos)
            this.bounds.minMax(this.w.pos)
        }
    }

    // ---------- private methods -------- //

    /**
     * @private
     * @param {Vertex} v
     * @param {Vertex} w
     */
    _createHalves(v, w) {
        // create halves
        const halves = [
            new HalfEdge(this, v),
            new HalfEdge(this, w)
        ]
        halves[0].twin = halves[1]
        halves[1].twin = halves[0]
        this.halves = halves

        // add incidence to vertices
        v.connectEdge(this)
        w.connectEdge(this)
    }

    /**
     * @private
     */
    _updateBezier() {
        if (this._bezier) {
            this._bezier.points[0].x = this.v.pos[0]
            this._bezier.points[0].y = this.v.pos[1]
            this._bezier.points[1].x = this.cpV.pos[0]
            this._bezier.points[1].y = this.cpV.pos[1]
            this._bezier.points[2].x = this.cpW.pos[0]
            this._bezier.points[2].y = this.cpW.pos[1]
            this._bezier.points[3].x = this.w.pos[0]
            this._bezier.points[3].y = this.w.pos[1]
            this._bezier.update()
        } else {
            /** @todo Replace this libray 'bezier-js' with renderer's libray */
            this._bezier = new Bezier(
                ...this.v.pos,
                ...this.cpV.pos,
                ...this.cpW.pos,
                ...this.w.pos
            )
        }
    }
}

/**
 * Split Bezier curve into parts at specific points
 * @param {Bezier} bezier
 * @param {number[]} splitPoints  list of split points (t values [0, 1]) along the length of the curve
 * @returns {Vector2[][]} list of new curves in Edge#curve format
 */
function splitCurve(bezier, splitPoints) {
    const newCurves = []
    // first sub-curve
    bezier.split(0, splitPoints[0])
    newCurves.push(getBezierCP(bezier.split(0, splitPoints[0])))
    for (let i = 0; i < splitPoints.length - 1; i++) {
        newCurves.push(getBezierCP(bezier.split(splitPoints[i], splitPoints[i + 1])))
    }
    // last sub-curve
    newCurves.push(getBezierCP(bezier.split(splitPoints[splitPoints.length - 1], 1)))
    return newCurves
}

/**
 * Returns control points of Bezier curve
 * @param  {Bezier} bezier
 * @returns {Vector2[]}     list of Vector2-like objects: {x, y}
 */
function getBezierCP(bezier) {
    return [
        new Vector2(bezier.points[1]),
        new Vector2(bezier.points[2])
    ]
}


const SMALL_EPSILON = 0.000001

/**
 * Segment - segment intersection
 * From https://gist.github.com/gordonwoodhull/50eb65d2f048789f9558
 * @param  {Vector2} p0
 * @param  {Vector2} p1
 * @param  {Vector2} p2
 * @param  {Vector2} p3
 * @returns {number[][]}  list of split point t-values for each edge in format [[e1t], [e2t]]
 */
function segmentIntersection(p0, p1, p2, p3) {
    const result = [[], []]
    const s10_x = p1[0] - p0[0], s10_y = p1[1] - p0[1],
          s32_x = p3[0] - p2[0], s32_y = p3[1] - p2[1]
    const denom = s10_x * s32_y - s32_x * s10_y
    if(Math.abs(denom) < SMALL_EPSILON) return result // collinear
    const s02_x = p0[0] - p2[0],
          s02_y = p0[1] - p2[1]
    const s_number = s10_x * s02_y - s10_y * s02_x
    if(s_number < 0 === denom > 0) return result // no collision
    const t_number = s32_x * s02_y - s32_y * s02_x
    if(t_number < 0 === denom > 0) return result // no collision
    if(s_number > denom === denom > 0 || t_number > denom === denom > 0) return result // no collision
    // collision detected
    result[0].push(t_number / denom)
    result[1].push(s_number / denom)
    return result
}

/**
 * @typedef {object} EdgeDataIn
 * @property {string}   id       id of the Edge
 * @property {Vertex}   v        Vertex of the starting point of this Edge
 * @property {Vertex}   cpV      Vertex of the starting curve control point of this Edge
 * @property {Vertex}   cpW      Vertex of the end point curve control of this Edge
 * @property {Vertex}   w        Vertex ID of the end point of this Edge
 */

/**
 * @typedef {object} EdgeData
 * @property {string}   id              id of the Edge
 * @property {string}   v      Vertex ID of the starting point of this Edge
 * @property {string}   w      Vertex ID of the end point of this Edge
 * @property {number[][]|null} [curve]        two Vector2 (one for each control point, offsets from endpoints v and w)
 * @property {string[]|null} [curveIds]
 * @property {string} type                    is always 'Edge'
 */
