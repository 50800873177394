import React, { useEffect, useRef, useState } from 'react'

import ContextMenu from '../ContextMenu'
import Icon from '../Icon'
import { MenuOptionProps, MenuProps } from '../Menu/Menu.types'
import { isSeparatorOption } from '../Menu/utils'
import Tooltip from '../Tooltip'

const DefaultSelectedOption = ({ icon }: { icon: string }) => (
  <Icon interactive={false} name={icon} size={24} useCurrentColor />
)

const wrapperBaseClassName =
  'inline-flex justify-center h-48 cursor-pointer relative before:absolute before:inset-x-[4px] before:inset-y-[8px] before:rounded-md before:pointer-events-none'

const transformClassName = 'transform duration-100 hover:translate-y-2 group-hover:translate-y-2'

const getClassNames = (isSelected: boolean, shouldOpenDropdown: boolean, shouldShowArrowIcon: boolean) => {
  const childBaseClassName = `h-full flex items-center justify-center group ${
    shouldShowArrowIcon ? 'first:pl-8 last:pr-8' : 'px-12'
  }`
  if (isSelected)
    return {
      wrapper: `${wrapperBaseClassName} ${
        shouldOpenDropdown ? 'group active:before:bg-light-overlay-5' : 'before:bg-light-overlay-15'
      } before:bg-light-overlay-15 hover:before:bg-light-overlay-10 active:before:outline-none highlight-border-selectedToolButton`,
      button: `${childBaseClassName} ${shouldOpenDropdown ? '' : 'text-white'}`,
      arrow: `${childBaseClassName} text-light-overlay-60 hover:text-white`
    }

  return {
    wrapper: `${wrapperBaseClassName} ${
      shouldOpenDropdown ? 'group' : ''
    } active:before:bg-light-overlay-5 hover:before:bg-light-overlay-10 active:before:outline-none highlight-border-toolButton`,
    button: `${childBaseClassName} text-light-overlay-60 hover:text-white group`,
    arrow: `${childBaseClassName} text-light-overlay-60 hover:text-white group`
  }
}

export type ToolbarProps = {
  className?: string
  childClassName?: string
  menuClassName?: string
  onChange: (value: MenuOptionProps['value']) => void
  onMouseEnter?: (e: React.MouseEvent) => void
  onOpenChange?: (open: boolean) => void
  options: MenuProps['options']
  renderSelectedOption?: Function
  selectable?: boolean
  selectedValues?: MenuProps['selectedValues']
  showArrowIcon?: boolean
  shouldOpenDropdown?: boolean
  tooltip?: string | { text: string; shortcut?: string }
  value?: MenuOptionProps['value']
}

const ToolButton = ({
  className = '',
  childClassName = '',
  menuClassName = '',
  onChange,
  onMouseEnter,
  onOpenChange,
  options,
  renderSelectedOption = DefaultSelectedOption,
  selectable = true,
  selectedValues,
  showArrowIcon = true,
  shouldOpenDropdown = false,
  tooltip = '',
  value,
  ...rest
}: ToolbarProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)

  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState(options[0] as MenuOptionProps)

  const isSelected = selectable && selectedOption.value === value

  const hasDropdown = options.length > 1
  const shouldShowArrowIcon = hasDropdown && showArrowIcon

  const classNames = getClassNames(isSelected, shouldOpenDropdown, shouldShowArrowIcon)

  useEffect(() => {
    const targetOption = options.find((option) => !isSeparatorOption(option) && option.value === value)
    if (targetOption) {
      setSelectedOption(targetOption as MenuOptionProps)
    }
  }, [options, value, selectedOption])

  const toggleDropdown = () => {
    wrapperRef.current?.blur()
    buttonRef.current?.blur()
    setIsDropdownOpen(!isDropdownOpen)
    onOpenChange?.(!isDropdownOpen)
  }
  const closeDropdown = () => {
    setIsDropdownOpen(false)
    onOpenChange?.(false)
  }
  const selectDefaultOption = (e: React.MouseEvent) => {
    e.stopPropagation()
    wrapperRef.current?.blur()
    buttonRef.current?.blur()
    onChange(selectedOption.value)
  }

  const selectOption = (option: MenuOptionProps) => {
    setSelectedOption(option)
    onChange(option.value)
    closeDropdown()
  }
  const handleKeyDown = (e: React.KeyboardEvent) => {
    switch (e.key) {
      case 'Enter':
      case ' ':
        if (!shouldOpenDropdown && !shouldShowArrowIcon) {
          onChange(selectedOption.value)
        } else {
          toggleDropdown()
        }
        break
      case 'Escape':
        buttonRef.current?.blur()
        break
    }
  }

  return (
    <Tooltip content={isDropdownOpen ? '' : tooltip || selectedOption.name}>
      <div
        {...rest}
        ref={wrapperRef}
        className={`${classNames.wrapper} ${className}`}
        onClick={shouldOpenDropdown ? toggleDropdown : undefined}
        onKeyDown={handleKeyDown}
      >
        <button
          ref={buttonRef}
          className={`${classNames.button} ${childClassName} `}
          onClick={shouldOpenDropdown ? undefined : selectDefaultOption}
          onMouseEnter={onMouseEnter}
        >
          <div className={`${transformClassName} ${shouldOpenDropdown ? 'group-hover:text-white' : ''}`}>
            {renderSelectedOption(selectedOption)}
          </div>
        </button>
        {shouldShowArrowIcon && (
          <div className={`${classNames.arrow} self-end`} onClick={shouldOpenDropdown ? undefined : toggleDropdown}>
            <div
              className={`${transformClassName} h-24 flex items-end ${
                shouldOpenDropdown ? 'group-hover:translate-y-2 group-hover:text-white' : ''
              }`}
            >
              <Icon interactive={false} name="FilledAngle" size="s" useCurrentColor />
            </div>
          </div>
        )}
        {hasDropdown && (
          <ContextMenu
            className={menuClassName}
            trigger={wrapperRef}
            triggerInFocusLoop
            open={isDropdownOpen}
            offsetY={8}
            options={options}
            onSelect={selectOption}
            onClose={closeDropdown}
            checkedOpts={selectedValues}
          />
        )}
      </div>
    </Tooltip>
  )
}

export default React.memo(ToolButton)
