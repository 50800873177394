import {
  getProgress,
  getPropPercentage,
  getStepPercentage,
  getAnimationByPercentage
} from '../../utils/timing-functions'
import Interval from '../../helpers/Interval'
import PropertyStack from '../PropertyStack'

class ContentAnchorStack extends PropertyStack {
  constructor(elementStack, data) {
    super(elementStack, data)
    this.type = 'CONTENT_ANCHOR'
    this.key = 'contentAnchor'
    this.dataKey = 'contentAnchor'
    this.basePropKeys = ['contentAnchorX', 'contentAnchorY']
    this.unitKeys = new Set(['contentAnchorXUnit', 'contentAnchorYUnit'])
    this.animatableProperties = new Set(['contentAnchor'])
    this._subInterval = new Interval()
    this._animateData = {}
  }

  /**
   * Update a keyframe
   * @param {string} KFId
   * @param {object} data
   */
  updateKF(KFId, data) {
    // Should update base cache if contentAnchor value has changed
    const shouldUpdateBaseCacheWithUnitChange = typeof data.value === 'object' && Object.keys(data.value).some(key => this.unitKeys.has(key))
    if (shouldUpdateBaseCacheWithUnitChange) {
      this.elementStack.cacheNonRepeatablePropertyBaseValue(this.dataKey)
      const baseValue = this.getBaseValue(this)
      this.unitKeys.forEach((key) => {
        this._animateData[key] = baseValue[key]
      })
    }

    // TODO: Need to know which action is the newKFs from when we have multi-actions
    this.actions[0].updateKF(KFId, data)
  }

  /**
   * Get animation data
   * @param {number} time
   * @returns {any | null}
   */
  getAnimateData(time) {
    const interval = this.getWorkingInterval(time)
    if (!interval) {
      return null
    }

    const workingTime = this.getWorkingTime(time)
    const result = this._isAnimatable()
      ? this._getInterpolateData(workingTime, interval)
      : this._getInstantChangeData(workingTime, interval)

    const baseValue = this.elementStack.getBaseValueByPropKey('contentAnchor')
    result.contentAnchorX += baseValue.contentAnchorX
    result.contentAnchorY += baseValue.contentAnchorY

    return result
  }

  /**
   * Get interpolate data from interval
   * @param {number} time
   * @param {[object, object]} interval
   * @returns {object|number}
   */
  _getInterpolateData(time, interval) {
    // Get kfs from a interval and calculate the intepolate data
    const singleInterval = this._subInterval.copy(interval, true)

    this.basePropKeys.forEach((key) => {
      singleInterval.updateValues(interval[0].value[key], interval[1].value[key])
      const progress = getProgress(time, singleInterval)
      const percent = getPropPercentage(singleInterval[1], progress)
      this._animateData[key] = getAnimationByPercentage(percent, singleInterval[0].value, singleInterval[1].value)
    })

    return this._animateData
  }

  /**
   * Get instant change data
   * @param {number} time
   * @param {[object, object]} interval
   * @returns {object|number}
   */
  _getInstantChangeData(time, interval) {
    // Get kfs from a interval and calculate the instant change data
    const singleInterval = this._subInterval.copy(interval, true)
    this.basePropKeys.forEach((key) => {
      singleInterval.updateValues(interval[0].value[key], interval[1].value[key])
      const progress = getProgress(time, singleInterval)
      const percent = getStepPercentage(progress)
      this._animateData[key] = getAnimationByPercentage(percent, singleInterval[0].value, singleInterval[1].value)
    })

    return this._animateData
  }

  getInitValue() {
    return { contentAnchorX: 0, contentAnchorY: 0 }
  }
}

export default ContentAnchorStack
