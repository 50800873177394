import React, { useEffect, useState } from 'react'
import { useDataStore } from '../../providers/dataStore/DataStoreProvider'
import { useSetTool, useTool } from '../../providers/dataStore/ToolProvider'
import { ToolType } from '@phase-software/types'
import EyeDropper from '../EyeDropper'
import { RendererCanvasContainer } from '../shared/RendererCanvas'
import { useCommentContext } from '../../providers/CommentProvider'

const SubscribeToolChange = () => {
  const dataStore = useDataStore()
  const { setActiveTool } = useSetTool()
  const { activeTool } = useTool()
  const { setCommentVisibility } = useCommentContext()
  const [position, setPosition] = useState({ x: 0, y: 0 })

  useEffect(() => {
    if (!dataStore) return
    const handleToolChange = (tool) => {
      if (tool === ToolType.COMMENT) {
        setCommentVisibility(true)
      }
      setActiveTool(tool)
    }

    dataStore.on('activeTool', handleToolChange)

    return () => {
      dataStore.off('activeTool', handleToolChange)
    }
  }, [dataStore, setActiveTool, setCommentVisibility])

  useEffect(() => {
    const onMouseMove = (e) => {
      setPosition({ x: e.layerX, y: e.layerY })
    }

    RendererCanvasContainer.addEventListener('mousemove', onMouseMove)
    return () => {
      RendererCanvasContainer.removeEventListener('mousemove', onMouseMove)
    }
  }, [])

  return activeTool === ToolType.EYE_DROPPER ? <EyeDropper position={position} /> : null
}

export default SubscribeToolChange
