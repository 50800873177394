import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { convertColorToRGBA } from './utils'
import { CircleKnob } from '../../shared'

const DEACTIVE_STOP_SIZE = 12
const ACTIVE_STOP_SIZE = 16

const getStopSize = (active) => {
  return active ? ACTIVE_STOP_SIZE : DEACTIVE_STOP_SIZE
}

const getPositionPercentage = (position, width) => {
  const STOP_SIZE = ACTIVE_STOP_SIZE
  const newWidth = width - STOP_SIZE
  const newPos = position * newWidth
  return (100 * newPos) / width
}

export default class ColorStop extends PureComponent {
  static propTypes = {
    index: PropTypes.number.isRequired,
    active: PropTypes.bool,
    position: PropTypes.number.isRequired,
    color: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object // Vector2 type
    ]).isRequired,
    onActivate: PropTypes.func,
    onMove: PropTypes.func,
    onMoveEnd: PropTypes.func,
    onBlur: PropTypes.func
  }

  static defaultProps = {
    active: false,
    color: [0, 0, 0, 0],
    onActivate: () => { },
    onMove: () => { },
    onMoveEnd: () => { },
    onFocus: () => { },
    onBlur: () => { }
  }

  rafId = null

  componentDidMount() {
    const { position } = this.props
    const width = this.element.parentNode.getBoundingClientRect().width
    this.element.style.left = `${getPositionPercentage(position, width)}%`
  }

  componentDidUpdate(propProps) {
    const { position } = this.props
    if (propProps !== position) {
      const width = this.element.parentNode.getBoundingClientRect().width
      this.element.style.left = `${getPositionPercentage(position, width)}%`
    }
  }

  handleMouseUp = (e) => {
    if (this.element === document.activeElement) {
      this.element.blur()
    }
    e.stopPropagation()
    document.body.removeEventListener('mousemove', this.handleMouseMove)
    window.cancelAnimationFrame(this.rafId)

    this.rafId = null
    const { left, width } = this.rect

    const STOP_SIZE = getStopSize(this.props.active)
    const minX = Math.max(e.pageX - left - STOP_SIZE / 2, 0)
    const maxX = Math.min(minX, width - STOP_SIZE)
    const pos = maxX / (width - STOP_SIZE)
    this.props.onMove(pos)
    this.props.onMoveEnd(pos)
  }

  handleMouseMove = (e) => {
    if (this.element === document.activeElement) {
      this.element.blur()
    }
    const { left, width } = this.rect
    const STOP_SIZE = getStopSize(this.props.active)
    const minX = Math.max(e.pageX - left - STOP_SIZE / 2, 0)
    const maxX = Math.min(minX, width - STOP_SIZE)
    const pos = maxX / (width - STOP_SIZE)
    this.element.style.left = `${getPositionPercentage(pos, width)}%`
    if (!this.rafId) {
      this.rafId = window.requestAnimationFrame(() => {
        this.rafId = null
        this.props.onMove(pos)
      })
    }
  }

  /**
   * Handle mouse down on a gradient stop
   * @param {event} e
   */
  handleMouseDown = (e) => {
    e.stopPropagation()
    document.body.addEventListener('mousemove', this.handleMouseMove)
    document.body.addEventListener('mouseup', this.handleMouseUp, {
      once: true
    })
    this.rect = this.element.parentNode.getBoundingClientRect()
    if (!this.props.active) {
      this.props.onActivate(this.props.index)
    }
  }

  handleKeyDown = (e) => {
    switch (e.key) {
      case 'Escape':
        this.element.blur()
        e.stopPropagation()
        break
      case ' ':
      case 'Enter':
        this.props.onActivate(this.props.index)
        break
      default:
        break
    }
  }

  render() {
    const { active, color } = this.props
    return (
      <output
        ref={(el) => {
          this.element = el
        }}
        color={color}
        onMouseDown={this.handleMouseDown}
        onKeyDown={this.handleKeyDown}
        onBlur={this.props.onBlur}
        tabIndex="0"
        style={{ willChange: 'left' }}
        className={`group absolute block h-[` + ACTIVE_STOP_SIZE + `px] w-[` + ACTIVE_STOP_SIZE + `px] ${active ? 'p-0' : 'p-2'}  ${active ? 'z-[2]' : 'z-[1]'}`}
      >
        <CircleKnob
          size={getStopSize(active)}
          color={convertColorToRGBA(color)}
          className="group-focus-visible:outline-primary-2"
        />
      </output>
    )
  }
}
