import { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { debounce } from 'lodash'
import { takeSnapshotAsBlob } from '@phase-software/renderer'
import { useDataStore } from '../../providers/dataStore/DataStoreProvider'
import useFileActions from '../../hooks/useFileActions'
import { useUIActions } from '../../providers/dataStore/UIProvider'
import { Vector2 } from '@phase-software/data-utils'

const SubscribeDataStoreChange = ({ projectId, fileId }) => {
  const dataStore = useDataStore()
  const { uploadFileThumbnail } = useFileActions()
  const { setEditOrigin } = useUIActions()

  const saveThumbnail = useMemo(
    () =>
      debounce(({ fileId, screen }) => {
        takeSnapshotAsBlob(screen.get('id'), 'png', new Vector2(440, 440)).then((blob) => {
          uploadFileThumbnail({ projectId, fileId, blob })
        })
      }, 3000, { leading: true }),
    [uploadFileThumbnail, projectId]
  )

  useEffect(() => {
    const onUndo = (undo) => {
      const filterEvent = (e) => {
        const blockedEventSet = new Set(['SELECT', 'SELECT_CELL'])
        if (blockedEventSet.has(e.type)) {
          return false
        }
        if (e.owner === dataStore) {
          return false
        }
        return true
      }

      const updated = undo.events.filter(filterEvent).length > 0

      if (updated) {
        saveThumbnail({ fileId, screen: dataStore.workspace.children[0] })
      }
    }
    dataStore.on('UNDO', onUndo)
    return () => {
      saveThumbnail.cancel()
      dataStore.off('UNDO', onUndo)
    }
  }, [fileId, dataStore, saveThumbnail])

  useEffect(() => {
    if (!dataStore) return

    const handleFeature = (features) => {
      if (features.has('editOrigin')) {
        setEditOrigin(features.get('editOrigin'))
      }
    }

    dataStore.on('features', handleFeature)
    return () => {
      dataStore.off('features', handleFeature)
    }
  }, [dataStore, setEditOrigin])

  return null
}

SubscribeDataStoreChange.propTypes = {
  projectId: PropTypes.string.isRequired,
  fileId: PropTypes.string
}

export default SubscribeDataStoreChange
