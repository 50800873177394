import React, { useCallback, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import useHeapAnalytics from '../../hooks/useHeapAnalytics'
import { useCreateThread, useEditThreadMetadata, useSelf, useThreads } from '../../liveblocks.config'
import { useCommentContext } from '../../providers/CommentProvider'
import { useFileContext } from '../../providers/FileProvider'
import { useWorkspaceContext } from '../../providers/WorkspaceContextProvider'
import { track } from '../../services/heapAnalytics'
import { scrollIntoView } from '../../utils/dom'
import CommentThreadWithData from '../Comment/CommentThreadWithData'
import { useMoveViewport } from '../Comment/useMoveViewport'
import { parseMetadata, stringifyMetadata, toLiveblocksCommentThread } from '../Comment/utils'
import { Dropdown, FocusLoop, IconButton, ScrollView } from '../shared'
import CommentTextArea from '../shared/CommentThread/CommentTextArea'
import { MARK_ALL_AS_READ_OPTION, SHOW_RESOLVED_OPTION } from '../shared/CommentThread/commentMenuConstants'
import { CommentType, FilterKeys, ThreadDataProps } from '../shared/CommentThread/types'
import { MenuListOptionProps, MenuOptionProps } from '../shared/Menu/Menu.types'
import { translateMenuOptions } from '../shared/Menu/utils'
import noCommentImage from './no-comment.svg'

const CommentPanel = () => {
  const { t } = useTranslation('file', { keyPrefix: 'commenting' })
  const { threads } = useThreads()
  const createThread = useCreateThread()
  const editThreadMetadata = useEditThreadMetadata()
  const { id: currentUserId = '' } = useSelf()
  const {
    threadRefs,
    hasUnread,
    selectedThread,
    filter,
    toggleFilter,
    selectPanelCommentThread,
    clearSelectedThread,
    setAnimatedTagId
  } = useCommentContext()

  const headRef = useRef<HTMLDivElement>(null)

  const nonDeletedThreads = useMemo(() => threads.filter((thread) => !thread.metadata.isDeleted), [threads])

  const { id: fileId, projectId } = useFileContext()
  const { space, teamName } = useHeapAnalytics()
  const { moveTo } = useMoveViewport()
  const { workspaceData } = useWorkspaceContext()
  const location = projectId === workspaceData.draftProjectId ? 'drafts' : 'project'

  const createNewThread = async (value: string) => {
    const metadata = {
      type: CommentType.PANEL,
      usersReadStatus: {
        [currentUserId]: true
      },
      resolved: false
    }
    const data = toLiveblocksCommentThread(value, metadata)
    try {
      const thread = await createThread(data)
      selectPanelCommentThread(thread.id)
      setTimeout(() => {
        scrollToThread(thread.id)
      }, 0)
      track('Comment Added', {
        fileId,
        space,
        teamName,
        location,
        commentType: 'FileLevel'
      })
    } catch (error) {
      console.log('[CommentPanel] createNewThread error: ', error)
    }
  }

  const scrollToThread = (threadId: string): void => {
    const threadRef = threadRefs.current[threadId]
    if (threadRef && threadRef.current) {
      scrollIntoView(threadRef.current)
    }
  }

  const expandPanelThread = useCallback(
    (thread: ThreadDataProps) => () => {
      const isSelected = selectedThread.id === thread.id && selectedThread.type === CommentType.PANEL
      if (isSelected) return
      selectPanelCommentThread(thread.id)
      if (thread.metadata.type === CommentType.CANVAS) {
        const data = parseMetadata(thread.metadata)
        const pos = data.position as { x: number; y: number }

        moveTo({ x: pos.x, y: pos.y })
        setAnimatedTagId(thread.id)
      }
    },
    [selectedThread, selectPanelCommentThread, moveTo, setAnimatedTagId]
  )

  const markAllAsRead = useCallback(() => {
    nonDeletedThreads.forEach((thread) => {
      const metadata = parseMetadata(thread.metadata)
      if (metadata.usersReadStatus[currentUserId]) return
      const newMetadata = stringifyMetadata({
        ...metadata,
        usersReadStatus: { ...metadata.usersReadStatus, [currentUserId]: true }
      })
      editThreadMetadata({ threadId: thread.id, metadata: newMetadata })
    })
  }, [nonDeletedThreads, editThreadMetadata, currentUserId])

  const menuOptions: MenuListOptionProps[] = useMemo(
    () => [
      { ...SHOW_RESOLVED_OPTION, selected: !!filter[SHOW_RESOLVED_OPTION.value] },
      '-',
      { ...MARK_ALL_AS_READ_OPTION, disabled: !hasUnread }
    ],
    [hasUnread, filter]
  )

  const selectedOptions = useMemo(() => Object.keys(filter).filter((key) => filter[key]), [filter])

  const handleSelectOption = (option: MenuOptionProps) => {
    switch (option.value) {
      case SHOW_RESOLVED_OPTION.value:
        toggleFilter(FilterKeys.SHOW_RESOLVED)
        break
      case MARK_ALL_AS_READ_OPTION.value:
        markAllAsRead()
        break
      default:
        break
    }
  }

  return (
    <>
      <FocusLoop className="flex flex-col w-full h-full" undoable>
        <div ref={headRef} className="w-full flex gap-8 py-12 pl-16 pr-12 text-white font-medium">
          <div className="flex-1">{t('comments')}</div>
          <Dropdown
            inline
            menu={{
              options: translateMenuOptions(t, menuOptions, {
                ns: 'file',
                keyPrefix: 'commenting'
              }),
              onSelect: handleSelectOption,
              selectable: true,
              selectedValues: selectedOptions
            }}
          >
            <IconButton icon="Filter" size="l" />
          </Dropdown>
        </div>
        {nonDeletedThreads.length > 0 ? (
          <div className="flex flex-col flex-1 overflow-y-hidden">
            {/* @ts-ignore TODO: fix after refactor of ScrollView */}
            <ScrollView noScrollbar={true}>
              {nonDeletedThreads
                .slice(0)
                .reverse()
                .map((thread: ThreadDataProps) => {
                  if (thread.metadata.resolved && !filter[FilterKeys.SHOW_RESOLVED]) return null

                  if (!threadRefs.current[thread.id]) {
                    threadRefs.current[thread.id] = React.createRef()
                  }

                  return (
                    <CommentThreadWithData
                      ref={threadRefs.current[thread.id]}
                      key={thread.id}
                      thread={thread}
                      expandThread={expandPanelThread(thread)}
                      onThreadClose={clearSelectedThread}
                      isExpanded={selectedThread.id === thread.id && selectedThread.type === CommentType.PANEL}
                      type={CommentType.PANEL}
                      scrollToThread={scrollToThread}
                    />
                  )
                })}
            </ScrollView>
          </div>
        ) : (
          <div className="flex-1 p-16 flex flex-col items-center justify-center">
            <img className="mb-16" src={noCommentImage} />
            <div className="text-12 text-center px-16">{t('empty_comment_thread')}</div>
          </div>
        )}
        <div className="flex w-full p-16 border-t border-solid border-neutral-80">
          <CommentTextArea
            placeholder={t('write_a_comment')}
            type={CommentType.PANEL}
            rows={1}
            variant="create"
            onInputSubmit={createNewThread}
            maxScrollHeight={198}
          />
        </div>
      </FocusLoop>
    </>
  )
}

export default CommentPanel
