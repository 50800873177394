import { atom } from 'jotai'

import { atomWithStorage, createJSONStorage } from 'jotai/utils'

const IS_CHROMIUM = 'isChromium'

const checkIsChromium = () => {
  const userAgent = navigator.userAgent
  const isChromium = userAgent.toLowerCase().includes('chrome')
  return isChromium
}

export const isChromiumAtom = atomWithStorage<Boolean | null>(
  IS_CHROMIUM,
  null,
  createJSONStorage(() => sessionStorage)
)

export const showNonChromiumWarning = atom<Boolean>(!checkIsChromium())

export const nonChromiumWarning = atom(
  (get) => get(showNonChromiumWarning) && get(isChromiumAtom) === null,
  (_, set) => {
    const isChromium = checkIsChromium()
    set(showNonChromiumWarning, false)
    set(isChromiumAtom, isChromium)
  }
)
