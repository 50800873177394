import { vec2 } from 'gl-matrix'
import { EPSILON, isNull, vec2AddScalar, isVec2, isNum } from './commons'
import { Vector2 } from './Vector2'

/**
 * Another format for an axis-aligned bounding box that is _compatible_ with pixi.js `PIXI.Rectangle`.
 * @typedef {object} Rect
 * @property {number} x
 * @property {number} y
 * @property {number} width
 * @property {number} height
 */

/**
 * Represents an axis-aligned bounding box. It is defined by the minimum and maximum
 * coordinate values a point can have inside the box.
 */
export class AABB {
    /**
     * Creates new AABB in one of the following ways:
     *  - empty (if first parameter is not defined)
     *  - from another {@link AABB}
     *  - from {@link Rect} object
     *  - from two {@link Vector2}-like objects representing min and max components
     *  - from x, y, width and height number components
     * @param  {number | AABB | Rect | Vector2 } x    x component or {@link AABB}, or {@link Rect} object or {@link Vector2}-like object
     * @param  {number | Vector2 } [y]      y component or {@link Vector2}-like object
     * @param  {number} [width]             width component
     * @param  {number} [height]            height component
     */
    constructor(x, y, width, height) {
        /**
         * The minimum coordinates of a point inside the box.
         * @name AABB#min
         * @type {Vector2}
         */

        /**
         * The maximum coordinates of a point inside the box.
         * @name AABB#max
         * @type {Vector2}
         */

        // empty
        if (isNull(x)) {
            this.min = new Vector2(Infinity, Infinity)
            this.max = new Vector2(-Infinity, -Infinity)
        }
        // from AABB
        else if (x.min) {
            this.min = new Vector2(x.min)
            this.max = new Vector2(x.max)
        }
        // from {x, y, width, height}
        else if (x.x && isNull(y)) {
            this.min = new Vector2(x.x, x.y)
            this.max = new Vector2(x.x + x.width, x.y + x.height)
        }
        // from min max
        else if (!isNull(y) && isNull(width)) {
            this.min = new Vector2(x)
            this.max = new Vector2(y)
        }
        // from x, y, width, height
        else {
            this.min = new Vector2(x, y)
            this.max = new Vector2(x + width, y + height)
        }
    }

    /**
     * Copies the passed AABB into `this` AABB.
     * @param  {AABB | Rect} val  other AABB or Rect object
     * @returns {AABB} self
     */
    copy(val) {
        if (isNull(val)) {
            return
        }

        const { min, max, x, y, width, height } = val

        if (isVec2(min) && isVec2(max)) {
            this.min.copy(min)
            this.max.copy(max)
        } else if (isNum(x) && isNum(y) && isNum(width) && isNum(height)) {
            this.min.x = x
            this.min.y = y
            this.max.x = x + width
            this.max.y = y + height
        }
        return this
    }

    /**
     * Checks if this AABB is equal (has same corresponding component values) to another AABB or Rect object
     * @param {AABB | Rect} val  - other AABB or Rect object
     * @param {number} [epsilon] - precision, default is 0.0001
     * @returns {boolean} true if vectors are equal; false othewise
     */
    eq(val, epsilon = EPSILON) {
        if (isNull(val)) {
            return false
        }
        const min = isVec2(val.min) ? val.min : [val.x, val.y]
        const max = isVec2(val.max) ? val.max : [val.x + val.width, val.y + val.height]
        return this.min.eq(min, epsilon) && this.max.eq(max, epsilon)
    }

    /**
     * Serializes AABB data
     * @returns {{min: [], max: []}}
     */
    save() {
        return { min: [...this.min], max: [...this.max] }
    }

    get isInfinite() {
        return (
            this.min.x === Infinity && this.min.y === Infinity && this.max.x === -Infinity && this.max.y === -Infinity
        )
    }

    get isZero() {
        return this.min.eq(Vector2.ZERO) && this.max.eq(Vector2.ZERO)
    }

    get size() {
        return vec2.sub(new Vector2(), this.max, this.min)
    }

    get position() {
        return this.min
    }

    get x() {
        return this.min.x
    }

    get y() {
        return this.min.y
    }

    get width() {
        return this.max.x - this.min.x
    }

    get height() {
        return this.max.y - this.min.y
    }

    get topLeft() {
        return this.min
    }

    get bottomRight() {
        return this.max
    }

    get topRight() {
        return new Vector2(this.max.x, this.min.y)
    }

    get bottomLeft() {
        return new Vector2(this.min.x, this.max.y)
    }

    get left() {
        return this.min.x
    }

    get top() {
        return this.min.y
    }

    get right() {
        return this.max.x
    }

    get bottom() {
        return this.max.y
    }

    get center() {
        return new Vector2(this.x + this.width / 2, this.y + this.height / 2)
    }

    /**
     * Translates both min and max by delta
     * @param  {vec2}  delta          - translation vector
     * @param  {boolean} newInstance  - whether to create a new instance for output AABB
     * @returns {AABB} the output AABB
     */
    translate(delta, newInstance = true) {
        const out = newInstance ? new AABB() : this

        vec2.add(out.min, this.min, delta)
        vec2.add(out.max, this.max, delta)

        return out
    }

    /**
     * Moves bounds to specific position
     * @param  {vec2}  pos          -  position
     * @param  {boolean} newInstance  - whether to create a new instance for output AABB
     * @returns {AABB} the output AABB
     */
    moveTo(pos, newInstance = true) {
        const out = newInstance ? new AABB() : this

        const width = this.width
        const height = this.height

        vec2.set(out.min, pos[0], pos[1])
        vec2.set(out.max, pos[0] + width, pos[1] + height)
        return out
    }

    /**
     * Transforms the rectangle form by this AABB and recalcultes AABB around the transformed rectangle
     * @param  {mat2d}  transform       transform matrix
     * @param  {boolean} newInstance    if true returns a new AABB instance, otherwise modifies this instance
     * @returns {AABB}                  new AABB, unless newInstance was set to false; otherwise returns new AABB instance
     */
    transform(transform, newInstance = true) {
        const aabb = newInstance ? new AABB() : this
        const rect = this.rect().map(p => vec2.transformMat2d(p, p, transform))

        if (!newInstance) {
            aabb.reset()
        }

        rect.forEach(p => aabb.minMax(p))
        return aabb
    }

    /**
     * Resizes (expands or shrinks) the AABB by the vec2 delta
     * @param  {vec2} delta
     * @param  {boolean} newInstance    if true returns a new AABB instance, otherwise modifies this instance
     * @returns {AABB}                  new AABB, unless newInstance was set to false; otherwise returns new AABB instance
     */
    resizeBy(delta, newInstance = true) {
        const aabb = newInstance ? new AABB(this) : this
        vec2.sub(aabb.min, this.min, delta)
        vec2.add(aabb.max, this.max, delta)
        return aabb
    }

    /**
     * Resizes (expands or shrinks) the AABB by the scalar amount
     * @param  {number} scalar
     * @param  {boolean} newInstance    if true returns a new AABB instance, otherwise modifies this instance
     * @returns {AABB}                  new AABB, unless newInstance was set to false; otherwise returns new AABB instance
     */
    resizeByScalar(scalar, newInstance = true) {
        const aabb = newInstance ? new AABB(this) : this
        vec2AddScalar(aabb.min, this.min, -scalar)
        vec2AddScalar(aabb.max, this.max, scalar)
        return aabb
    }

    /**
     * Transforms min and max points only (not rectangle). Recalculates min and max afterwards.
     * @param  {mat2d}  transform       transform matrix
     * @param  {boolean} newInstance    if true returnes a new AABB instance, otherwise modifies this instance
     * @returns {AABB}
     */
    transformMinMax(transform, newInstance = true) {
        const aabb = newInstance ? new AABB() : this
        vec2.transformMat2d(aabb.min, this.min, transform)
        vec2.transformMat2d(aabb.max, this.max, transform)
        aabb.minMax()
        return aabb
    }

    /**
     * Finds whether this AABB intersects with the AABB represented by the arguments. This also includes
     * if one AABB lies fully inside the other.
     * @param {(number|AABB|{x:number, y:number, width:number, height: number})} x - x coord, AABB (or Vector4) or just object with x,y,width,height
     * @param {number} [y]
     * @param {number} [width]
     * @param {number} [height]
     * @returns {boolean} false if the two AABB don't overlap at all (no intersection); true otherwise (intersection)
     */
    intersects(x, y, width, height) {
        let left, top, right, bottom
        if (typeof x === 'number' && typeof y === 'number' && typeof width === 'number' && typeof height === 'number') {
            left = x
            top = y
            right = x + width
            bottom = y + height
        } else {
            left = isNull(x.left) ? x.x : x.left
            top = isNull(x.top) ? x.y : x.top
            right = isNull(x.right) ? x.x + x.width : x.right
            bottom = isNull(x.bottom) ? x.y + x.height : x.bottom
        }
        return left < this.right && right > this.left && top < this.bottom && bottom > this.top
    }

    /**
     * Calculates whether `this` AABB fully lies inside the passed AABB, i.e. whether the intersection
     * of these two AABBs is equal to `this`.
     * @param {(number|AABB|{x:number, y:number, width:number, height: number})} x - x coord, AABB (or Vector4) or just object with x,y,width,height
     * @param {number} [y]
     * @param {number} [width]
     * @param {number} [height]
     * @returns {boolean} true, if this AABB lies fully inside the passed AABB; false, otherwise
     */
    containedWithin(x, y, width, height) {
        let left, top, right, bottom
        if (typeof x === 'number' && typeof y === 'number' && typeof width === 'number' && typeof height === 'number') {
            left = x
            top = y
            right = x + width
            bottom = y + height
        } else {
            left = isNull(x.left) ? x.x : x.left
            top = isNull(x.top) ? x.y : x.top
            right = isNull(x.right) ? x.x + x.width : x.right
            bottom = isNull(x.bottom) ? x.y + x.height : x.bottom
        }
        return this.left >= left && this.right <= right && this.top >= top && this.bottom <= bottom
    }

    /**
     * Calculates whether this AABB contains the point `(x,y)` in the same coordinate space.
     * @param {(number|vec2)} x - x coordinate or whole point as vec2
     * @param {number} [y]      - y coordinate
     * @returns {boolean} true if point is inside (or on the boundary) of the AABB
     */
    containsPoint(x, y) {
        let xx = x,
            yy = y
        if (!isNull(x.x)) {
            xx = x.x
            yy = x.y
        }
        return xx >= this.left && xx <= this.right && yy >= this.top && yy <= this.bottom
    }

    /**
     * Returns the vertices of this rectangle in a clockwise fashion, starting from the top-left
     * vertex.
     * @returns {Vector2[]} - vertices of this AABB rectangle
     */
    rect() {
        return [
            new Vector2(this.min),
            new Vector2(this.max[0], this.min[1]),
            new Vector2(this.max),
            new Vector2(this.min[0], this.max[1])
        ]
    }

    /**
     * Extends min max bounds (if necesary) by point, another AABB or OBB.
     * If argument is undefined, recalculates min max from its own min max values
     * (useful after manualy changing min or max)
     * @param {(vec2|AABB|OBB)} [p]  could be a single point, AABB or OBB, or undefined
     */
    minMax(p) {
        if (isNull(p)) {
            // recalculate from itself
            const minX = Math.min(this.min[0], this.max[0]),
                minY = Math.min(this.min[1], this.max[1]),
                maxX = Math.max(this.min[0], this.max[0]),
                maxY = Math.max(this.min[1], this.max[1])
            vec2.set(this.min, minX, minY)
            vec2.set(this.max, maxX, maxY)
        } else {
            if (!isNull(p.min) && !isNull(p.max)) {
                vec2.min(this.min, this.min, p.min)
                vec2.max(this.max, this.max, p.max)
            } else if (!isNull(p.aabb)) {
                this.minMax(p.aabb)
            } else {
                vec2.min(this.min, this.min, p)
                vec2.max(this.max, this.max, p)
            }
        }
    }

    /**
     * Intersects this and the given bounds.
     * @param {AABB} bounds            - bounds to intersect this with
     * @param {AABB}[newInstance=true] - whether to create a new instance for output bounds
     * @returns {AABB} the output AABB
     */
    intersectWith(bounds, newInstance = true) {
        const out = newInstance ? new AABB() : this

        vec2.max(out.min, this.min, bounds.min)
        vec2.min(out.max, this.max, bounds.max)

        // Prevent nonsense bounds
        if (out.max.y < out.min.y) {
            this.min.y = 0
            this.max.y = 0
        }
        if (out.max.x < out.min.x) {
            this.min.x = 0
            this.max.x = 0
        }

        return out
    }

    /**
     * Calculates the set-union of `this` and `other`.
     *
     * @param {AABB} other
     * @param {boolean}[newInstance=true] - whether to create new instance for output bounds
     * @returns {AABB} the output AABB
     */
    union(other, newInstance = true) {
        const out = newInstance ? new AABB() : this

        out.min.x = Math.min(this.min.x, other.min.x)
        out.min.y = Math.min(this.min.y, other.min.y)
        out.max.x = Math.max(this.max.x, other.max.x)
        out.max.y = Math.max(this.max.y, other.max.y)

        return out
    }

    /**
     * Calculates the set-intersection of `this` and `other`.
     * @param {AABB} other
     * @param {boolean}[newInstance=true] - whether to create new instance for output bounds
     * @returns {AABB} the output AABB
     */
    intersect(other, newInstance = true) {
        const out = newInstance ? new AABB() : this

        out.min.x = Math.max(this.min.x, other.min.x)
        out.min.y = Math.max(this.min.y, other.min.y)
        out.max.x = Math.min(this.max.x, other.max.x)
        out.max.y = Math.min(this.max.y, other.max.y)

        return out
    }

    // /**
    //  * Checks if this AABB is equal to other AABB or OBB
    //  * @param  {AABB | OBB}     other AABB or OBB
    //  * @returns {boolean}      true if equal; false otherwise
    //  */
    // isEqual(other) {
    //     const otherAABB = other.aabb || other
    //     return vec2.exactEquals(this.min, otherAABB.min) && vec2.exactEquals(this.max, otherAABB.max)
    // }

    /**
     * Resets this AABB so that:
     * + it has zero area
     * + min point is at positive infinity
     * + max point is at negative infinity
     *
     * @returns {AABB} this AABB
     */
    reset() {
        vec2.set(this.min, Infinity, Infinity)
        vec2.set(this.max, -Infinity, -Infinity)

        return this
    }
}
