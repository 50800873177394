import { useRef, useEffect, memo } from 'react'
import { parseSceneTreeChanges } from '@phase-software/data-utils'

import { useSetElement } from '../../providers/dataStore/ElementProvider'
import { useSetFlattenElementList } from '../../providers/dataStore/FlattenElementListProvider'
import { useDataStore } from '../../providers/dataStore/DataStoreProvider'
import { getElementData } from '../../dataStore'

const SubscribeElementChildrenChange = () => {
  const callbackRef = useRef(new Map())
  const dataStore = useDataStore()
  const { mergeElement, updateElement } = useSetElement()
  const { setFlattenElementList } = useSetFlattenElementList()

  useEffect(() => {
    const node = callbackRef.current
    return () => {
      node.forEach(([workspace, cb, event]) => {
        workspace.off(event, cb)
      })
    }
  }, [])

  const handleChildrenChange = (entityChange) => {
    const { added, moved } = parseSceneTreeChanges(entityChange)
    const workspace = dataStore.workspace.watched

    if (added.size || moved.size) {
      const elements = {}

      const queue = [...added, ...moved]
      while (queue.length) {
        const id = queue.shift()
        elements[id] = getElementData(workspace.getElement(id))
        if (elements[id].children) {
          elements[id].children = elements[id].children.map((child) => {
            queue.push(child.id)
            return child.id
          })
        }
      }

      mergeElement(elements)
    }

    setFlattenElementList(workspace, true)
    entityChange.UPDATE.forEach((propChange, id) => {
      updateElement(id, { children: propChange.get('children').after })
    })
  }

  const workspace = dataStore.workspace
  workspace.on('SCENE_TREE_CHANGES', handleChildrenChange)
  callbackRef.current.set('SCENE_TREE_CHANGES-listener', [workspace, handleChildrenChange, 'SCENE_TREE_CHANGES'])

  return null
}

export default memo(SubscribeElementChildrenChange)
