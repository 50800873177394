import React, { useCallback, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { Mode } from '@phase-software/types'

import { DEFAULT_SPEED } from '../../constants/interactionConstants'
import useAfterEffect from '../../hooks/useAfterEffect'
import { useTransitionManager } from '../../providers/TransitionManagerProvider'
import { useUI, useUIActions } from '../../providers/dataStore/UIProvider'
import Tooltip from '../shared/Tooltip'
import PlayBackControl, { PlayBackControlType } from './PlayBackControl'
import ModeToggleButton from './components/ModeToggleButton'
import { useDataStore } from '../../providers/dataStore/DataStoreProvider'

type FloatingControlProps = {
  actionId?: string
}

const FloatingControl = React.memo(({ actionId = '' }: FloatingControlProps) => {
  const { mode, isEditingState, isTablingState } = useUI()
  const isActionMode = useMemo(() => mode === Mode.ACTION, [mode])
  const { setMode } = useUIActions()
  const { t } = useTranslation('file', { keyPrefix: 'floating_control.mode_switcher_text' })
  const dataStore = useDataStore()

  const ref = useRef<HTMLDivElement>(null)
  const baseWidth = useRef(0)
  const controllerRef = useRef<HTMLDivElement>(null)

  const switchToAnimationState = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (isActionMode) return
      e.stopPropagation()
      e.preventDefault()
      setMode(Mode.ACTION)
    },
    [isActionMode, setMode]
  )

  const textConfig = useMemo(() => {
    if (isEditingState || isTablingState) {
      return {
        displayText: t('animate'),
        tooltipText: t('animate_mode')
      }
    }
    return {
      displayText: t('animation'),
      tooltipText: t('animation_mode')
    }
  }, [isEditingState, isTablingState, t])

  const tipData = useMemo(
    () =>
      isActionMode
        ? null
        : {
            text: textConfig.tooltipText,
            shortcut: 'A'
          },
    [isActionMode, textConfig]
  )

  // speed control
  const playbackRate = useTransitionManager((o) => o.playbackRate)
  const speedRef = useRef<typeof DEFAULT_SPEED.value>()

  const updateWidth = useCallback(() => {
    const currentWidth = ref.current?.offsetWidth
    const controllerWidth = controllerRef.current?.offsetWidth
    if (!currentWidth || !controllerWidth) return
    if (!baseWidth.current) baseWidth.current = currentWidth - controllerWidth

    if (speedRef.current === undefined) speedRef.current = playbackRate
    const isSpeedChanging = speedRef.current !== playbackRate
    if (isSpeedChanging) speedRef.current = playbackRate

    const width = baseWidth.current + controllerWidth
    const transition = isActionMode && isSpeedChanging ? 'width 0.1s ease-out' : 'width 0.3s ease-out'

    Object.assign(ref.current.style, {
      width: `${width}px`,
      transition
    })
  }, [isActionMode, playbackRate])

  useAfterEffect(updateWidth)

  return (
    <Tooltip content={tipData} offset={4}>
      <div
        ref={ref}
        data-test-id="floating-control"
        onClick={switchToAnimationState}
        className="group w-auto py-8 pl-8 pr-16 flex gap-x-8 rounded-[22px] bg-dark-overlay-80 outline-light-overlay-10-2 backdrop-blur-[6px] overflow-hidden"
        style={{
          cursor: isActionMode ? 'default' : 'pointer'
        }}
      >
        {!isTablingState && <ModeToggleButton tooltipText={textConfig.tooltipText} />}
        <ModeToggleButton tooltipText={textConfig.tooltipText} />
        <div className="relative w-fit flex">
          {/* {isTablingState && dataStore.selection.get('action') && (
            <div 
              className="h-full pl-6 flex items-center font-medium pointer-events-none whitespace-nowrap"
            >{dataStore.interaction.getAction(dataStore.selection.get('action')).name}</div>
          )} */}
          {isTablingState && dataStore.selection.get('action') && (
            <div 
              className="h-full pl-6 flex items-center font-medium pointer-events-none whitespace-nowrap"
            >{`Action ${dataStore.interaction.getActionList(dataStore.selection.get('activeTableElement')).indexOf(dataStore.selection.get('action')) + 1}`}</div>
          )}

          <div
            ref={isActionMode ? controllerRef : undefined}
            className="h-full transition-opacity duration-300 ease-out"
            style={{
              position: isActionMode ? 'static' : 'absolute',
              opacity: isActionMode ? 1 : 0,
              pointerEvents: isActionMode ? 'auto' : 'none'
            }}
          >
            <PlayBackControl
              type={(isEditingState || isTablingState) ? PlayBackControlType.BASIC : PlayBackControlType.PROGRESS_BAR}
              actionId={actionId}
            />
          </div>

          <div
            ref={isActionMode ? undefined : controllerRef}
            className="h-full flex items-center font-medium text-light-overlay-60 group-hover:text-white transition-opacity duration-300 ease-out pointer-events-none"
            style={{
              position: isActionMode ? 'absolute' : 'static',
              opacity: isActionMode ? 0 : 1,
              pointerEvents: isActionMode ? 'none' : 'auto'
            }}
          >
            {textConfig.displayText}
          </div>
        </div>
      </div>
    </Tooltip>
  )
})

FloatingControl.displayName = 'FloatingControl'

export default FloatingControl
