import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

// @ts-ignore
import { GIFEncoder, MP4Encoder } from '@phase-software/renderer'
import { LoadingStatus, MediaType } from '@phase-software/types'

import { SPEED_OPTIONS } from '../../../constants/interactionConstants'
import useDotLottie from '../../../hooks/useDotLottie'
import useHeapAnalytics from '../../../hooks/useHeapAnalytics'
import { useFileContext } from '../../../providers/FileProvider'
import { useModal, useSetModal } from '../../../providers/ModalProvider'
import { useWorkspaceContext } from '../../../providers/WorkspaceContextProvider'
import { useDataStoreActions } from '../../../providers/dataStore/DataStoreProvider'
import { useUI, useUIActions } from '../../../providers/dataStore/UIProvider'
import { track } from '../../../services/heapAnalytics'
import { calculateJSONSize, formattedSize } from '../../../utils/file'
import { exportedFileNameValidator, secondFormatValidator } from '../../../utils/validator'
import { Dialog, Icon, Input, InputField, RadioButtonGroup, Select, Tooltip } from '../../shared'
import { translateMenuOptions } from '../../shared/Menu/utils'
import LottiePreviewPlayer from './LottiePreviewPlayer'
import {
  BOOLEAN_RADIO_BUTTON_OPTIONS,
  DEFAULT_FILE_SIZE,
  DefaultSettings,
  EXPORT_FORMAT_MAP,
  EXPORT_TYPE_MAP,
  fpsOptions,
  mediaTypes,
  qualities
} from './constants'

const KEY = 'ExportMediaDialog'

const Row = ({ dataTestId, children }: { dataTestId: string; children: React.ReactNode }) => {
  return (
    <div
      data-test-id={dataTestId}
      className="grid grid-flow-col items-start last:mb-0 gap-8"
      style={{ gridTemplateColumns: 'auto 178px' }}
    >
      {children}
    </div>
  )
}

const RowLabel = ({ text }: { text: string }) => {
  return <div className="py-6 min-w-16 text-12 text-left text-light-overlay-60 whitespace-nowrap">{text}</div>
}

type BooleanRadioButtonProps = {
  name: string
  value: boolean
  onChange: (value: boolean) => void
  disabled?: boolean
}

const BooleanRadioButton = ({ name, value, onChange, disabled }: BooleanRadioButtonProps) => {
  const { t } = useTranslation()

  const handleChange = (newValue: string) => {
    onChange(Boolean(newValue))
  }
  const radioButtonChildRenderer = useCallback(
    (option: { label: string }) => <span className="text-12">{option.label}</span>,
    []
  )

  const radioButtonOptions = useMemo(
    () =>
      BOOLEAN_RADIO_BUTTON_OPTIONS.map((option) => ({
        label: t(option.label),
        value: option.value.toString(),
        name: `${name}-${option.value}`,
        key: `${name}-${option.value}`
      })),
    [name, t]
  )

  return (
    <RadioButtonGroup
      options={radioButtonOptions}
      selectedValue={String(value)}
      onSelectionChange={handleChange}
      renderChild={radioButtonChildRenderer}
      disabled={disabled}
    />
  )
}

const sanitizeFileName = (fileName: string) => {
  const processedName = fileName.replace(/[<>:"/\\|?*'`]/g, '').trim()
  const validName = processedName.startsWith('.') ? processedName.slice(1) : processedName
  return validName === '' ? '_' : validName
}

const mediaTypeOptionRenderer = ({ name, tooltip }: any) => {
  return (
    <div className="w-full flex gap-x-4 overflow-hidden ">
      {<div className="text-white">{name}</div>}
      {tooltip && (
        <Tooltip content={tooltip}>
          <Icon name="Help" interactive={false} className="cursor-default text-light-overlay-60 hover:text-white" />
        </Tooltip>
      )}
    </div>
  )
}

const ExportMediaDialog = () => {
  const { changeExportStatus } = useUIActions()
  const { exportProgress, exportStatus } = useUI()
  const { exportMedia, cancelExportMedia, downloadLottieFile, getFirstScreenElementSize, createLottieJSON } =
    useDataStoreActions()
  const { downloadDotLottieFile } = useDotLottie()
  const { closeModal } = useSetModal(KEY)
  const { teamName, space } = useHeapAnalytics()
  const { id: fileId, projectId, fileName: rawFileName } = useFileContext()
  const { workspaceData } = useWorkspaceContext()
  const {
    open,
    data: { action }
  } = useModal((o: any) => o[KEY])
  const { t } = useTranslation(['file', 'common'])

  const [mediaType, updateMediaType] = useState(DefaultSettings.TYPE)
  const [quality, updateQuality] = useState(DefaultSettings.QUALITY)
  const [transparent, updateTransparent] = useState(DefaultSettings.TRANSPARENT)
  const [loop, updateLoop] = useState(DefaultSettings.LOOP)
  const [speed, updateSpeed] = useState(DefaultSettings.SPEED)
  const [fps, updateFps] = useState(DefaultSettings.FPS)
  const [start, updateStart] = useState(DefaultSettings.START)
  const [end, updateEnd] = useState(DefaultSettings.END)
  const [maxTime, updateMaxTime] = useState(DefaultSettings.MAX_TIME)
  const [fileName, updateFileName] = useState(sanitizeFileName(rawFileName))

  const [fileSize, setFileSize] = useState(DEFAULT_FILE_SIZE)
  const [animationData, setAnimationData] = useState<object | null>(null)

  const translatedQualitiesOptions = useMemo(
    () =>
      translateMenuOptions(t, qualities, {
        ns: 'file',
        keyPrefix: 'export_dialog'
      }),
    [t]
  )

  const handleMediaTypeChange = useCallback(
    (type: MediaType) => {
      updateMediaType(type)
      const options = fpsOptions[type]
      let minimalDiff = Number.MAX_SAFE_INTEGER
      let closestIndex = 0
      const option = options.find((option, index) => {
        const diff = Math.abs(option.value - fps)
        if (minimalDiff > diff) {
          minimalDiff = diff
          closestIndex = index
        }
        return option.value === fps
      })
      if (!option) {
        updateFps(options[closestIndex].value)
      }

      if (type === MediaType.MP4) {
        updateQuality(DefaultSettings.QUALITY)
      }
    },
    [updateMediaType, updateFps, fps]
  )

  const startTimeValidator = (v: string) => {
    const secondError = secondFormatValidator(v)
    return !secondError && parseFloat(v) < end ? '' : 'Start time can not be larger than end time'
  }

  const endTimeValidator = (v: string) => {
    const secondError = secondFormatValidator(v)
    return !secondError && parseFloat(v) > start ? '' : 'End time can not be smaller than start time'
  }

  const handleTransparentChange = useCallback(() => {
    updateTransparent(!transparent)
  }, [transparent, updateTransparent])

  const handleLoopChange = useCallback(() => {
    updateLoop(!loop)
  }, [loop, updateLoop])

  const handleFPSChange = useCallback(
    (v: number) => {
      updateFps(v)
    },
    [updateFps]
  )

  const trackExportEvent = useCallback(
    (mediaType: MediaType) => {
      const exportType = EXPORT_TYPE_MAP[mediaType] ?? EXPORT_TYPE_MAP[MediaType.LOTTIE]
      const location = projectId === workspaceData.draftProjectId ? 'drafts' : 'project'
      track('File Exported', {
        fileId,
        space,
        teamName,
        exportType,
        location
      })
    },
    [fileId, projectId, space, teamName, workspaceData.draftProjectId]
  )

  const handleExport = useCallback(() => {
    if (exportStatus !== LoadingStatus.INITIAL) {
      return
    }

    changeExportStatus(LoadingStatus.WAITING)

    const trimmedFileName = fileName.trim()

    if (mediaType === MediaType.LOTTIE) {
      downloadLottieFile({ fileName: trimmedFileName, fps, speed, start, end })
    } else if (mediaType === MediaType.DOTLOTTIE) {
      downloadDotLottieFile({ fileName: trimmedFileName, fps, speed, start, end })
    } else {
      exportMedia({
        fileName: trimmedFileName,
        mediaType,
        quality,
        transparent,
        loop,
        speed,
        fps,
        start,
        end
      })
    }
    trackExportEvent(mediaType)
  }, [
    exportStatus,
    trackExportEvent,
    changeExportStatus,
    mediaType,
    downloadLottieFile,
    downloadDotLottieFile,
    fps,
    speed,
    start,
    end,
    exportMedia,
    quality,
    transparent,
    loop,
    fileName
  ])

  const closeExportModal = useCallback(
    (forceClose = false) => {
      if (exportStatus === LoadingStatus.WAITING && !forceClose) {
        return
      }

      closeModal()
      changeExportStatus(LoadingStatus.INITIAL)
    },
    [exportStatus, changeExportStatus, closeModal]
  )

  const mediaTypesOptions = useMemo(
    () => mediaTypes.map((type) => ({ ...type, tooltip: type.tooltip ? t(type.tooltip) : '' })),
    [t]
  )

  const handleCancelExport = () => {
    cancelExportMedia()
    closeExportModal(true)
  }

  useEffect(() => {
    if (open) {
      changeExportStatus(LoadingStatus.INITIAL)
      updateFileName(sanitizeFileName(rawFileName))
    }
  }, [open, changeExportStatus, rawFileName, updateFileName])

  useEffect(() => {
    if (!action) {
      return
    }

    // Reset all data to default when open modal
    updateMediaType(DefaultSettings.TYPE)
    updateQuality(DefaultSettings.QUALITY)
    updateTransparent(DefaultSettings.TRANSPARENT)
    updateFps(DefaultSettings.FPS)
    updateStart(DefaultSettings.START)

    // Get action data as default value
    updateLoop(action.looping)
    updateSpeed(action.speed)
    updateEnd(action.maxTime / 1000)
    updateMaxTime(action.maxTime / 1000)
  }, [action])

  useEffect(() => {
    const { width, height } = getFirstScreenElementSize()
    let fileSize = 0
    switch (mediaType) {
      case MediaType.GIF:
        fileSize = GIFEncoder.getEstimatedSize({
          width,
          height,
          fps,
          transparency: transparent,
          loop,
          duration: end - start,
          speed
        })
        break
      case MediaType.MP4:
        fileSize = MP4Encoder.getEstimatedSize({
          width,
          height,
          fps,
          duration: end - start,
          speed,
          videoQuality: quality
        })
        break
      default: {
        fileSize = animationData === null ? 0 : calculateJSONSize(animationData)
      }
    }
    setFileSize(fileSize)
  }, [
    mediaType,
    fps,
    end,
    start,
    speed,
    quality,
    transparent,
    loop,
    animationData,
    setFileSize,
    getFirstScreenElementSize
  ])

  useEffect(() => {
    setAnimationData(null)
    if (open && end) {
      createLottieJSON({ fps, speed, start, end }).then((lottieJson) => {
        setAnimationData(lottieJson)
      })
    }
  }, [open, end, fps, createLottieJSON, speed, start])

  return (
    <Dialog
      cancelBtnText={t('file:export_dialog.cancel')}
      confirmBtnText={`${t('file:export_dialog.export')}${!fileSize ? ` (${formattedSize(fileSize)})` : ''}`}
      disableConfirm={!!exportedFileNameValidator(fileName, t)}
      data-test-id="export-dialog"
      onCancel={handleCancelExport}
      onCancelProgress={cancelExportMedia}
      onConfirm={handleExport}
      progressPercentage={exportProgress}
      progressStatus={exportStatus}
      rootClassName="overflow-y-scroll py-32 export-dialog-fixed-top:items-start"
      showProgressIndicator={mediaType !== MediaType.LOTTIE}
      size="s"
      title={t('file:export_dialog.title')}
      open={open}
    >
      <div className="grid gap-x-16 gap-y-8 text-light-overlay-40">
        {(mediaType === MediaType.LOTTIE || mediaType === MediaType.DOTLOTTIE) && (
          <LottiePreviewPlayer animationData={animationData} />
        )}
        <Row dataTestId="file-name-setting">
          <RowLabel text={t('file:export_dialog.file_name')} />
          <InputField
            // @ts-ignore TODO: fix after refactor of Input
            variant="normal"
            value={fileName}
            onInput={updateFileName}
            rightText={EXPORT_FORMAT_MAP[mediaType]}
            validator={(value) => exportedFileNameValidator(value, t)}
            labelClassName=""
            dataTestId="file-name-input"
          />
        </Row>
        <Row dataTestId="format-setting">
          <RowLabel text={t('file:export_dialog.format')} />
          <Select
            // @ts-ignore TODO: fix after refactor of Select
            variant="normal"
            caret
            value={mediaType}
            options={mediaTypesOptions}
            onChange={handleMediaTypeChange}
            optionRender={mediaTypeOptionRenderer}
            dataTestId="format-select"
          />
        </Row>
        {mediaType === MediaType.GIF && (
          <>
            <Row dataTestId="transparency-setting">
              <RowLabel text={t('file:export_dialog.transparent')} />
              <BooleanRadioButton name="transparent" value={transparent} onChange={handleTransparentChange} />
            </Row>
            <Row dataTestId="loop-setting">
              <RowLabel text={t('file:export_dialog.loop')} />
              <BooleanRadioButton name="loop" value={loop} onChange={handleLoopChange} />
            </Row>
          </>
        )}
        {mediaType === MediaType.MP4 && (
          <Row dataTestId="quality-setting">
            <RowLabel text={t('file:export_dialog.quality')} />
            <Select
              // @ts-ignore TODO: fix after refactor of Select
              variant="normal"
              caret
              value={quality}
              options={translatedQualitiesOptions}
              onChange={updateQuality}
            />
          </Row>
        )}
        <Row dataTestId="speed-setting">
          <RowLabel text={t('file:export_dialog.speed')} />
          <Select
            // @ts-ignore TODO: fix after refactor of Select
            variant="normal"
            caret
            value={speed}
            options={SPEED_OPTIONS}
            onChange={updateSpeed}
          />
        </Row>
        <Row dataTestId="fps-setting">
          <div className="w-full flex items-center gap-x-4 overflow-hidden">
            <RowLabel text={t('file:export_dialog.frame_rate')} />
            <Tooltip content={t('file:export_dialog.fps_tooltip')}>
              <Icon name="Help" interactive={false} className="text-light-overlay-60 hover:text-white" />
            </Tooltip>
          </div>
          <Select
            // @ts-ignore TODO: fix after refactor of Select
            variant="normal"
            caret
            value={fps}
            options={fpsOptions[mediaType]}
            onChange={handleFPSChange}
          />
        </Row>
        <Row dataTestId="start-time-setting">
          <RowLabel text={t('file:export_dialog.start_time')} />
          <Input
            // @ts-ignore TODO: fix after refactor of Input
            variant="normal"
            type="number"
            value={start}
            min={0}
            max={end}
            reachMinmax={false}
            validator={startTimeValidator}
            onChange={updateStart}
            rightText="s"
            spinner
          />
        </Row>
        <Row dataTestId="end-time-setting">
          <RowLabel text={t('file:export_dialog.end_time')} />
          <Input
            // @ts-ignore TODO: fix after refactor of Input
            variant="normal"
            type="number"
            min={start}
            max={maxTime}
            reachMinmax={false}
            value={end}
            validator={endTimeValidator}
            onChange={updateEnd}
            rightText="s"
            spinner
          />
        </Row>
        {(mediaType === MediaType.LOTTIE || mediaType === MediaType.DOTLOTTIE) && (
          <p className="mt-8">{t('file:export_dialog.lottie_export_note')}</p>
        )}
      </div>
    </Dialog>
  )
}

export default React.memo(ExportMediaDialog)
