import React from 'react'
import { useTranslation } from 'react-i18next'

import { useEditor } from '../../../providers/dataStore/EditorProvider'
import { useElementActions } from '../../../providers/dataStore/ElementProvider'
import { IconButton } from '../../shared'

type VisibleProps = {
  elementIds: string[]
  className?: string
  disabled?: boolean
}

const Visible = ({ elementIds, className, disabled }: VisibleProps) => {
  const value = useEditor((o) => o.visible)
  const { toggleVisible, setElementsVisible } = useElementActions()
  const { t } = useTranslation('file', { keyPrefix: 'property_editor.opacity_editor' })

  const handleToggleVisible = () => {
    if (elementIds.length > 1) {
      if (typeof value === 'boolean') {
        setElementsVisible(elementIds, !value)
      } else {
        // visible is MIX
        setElementsVisible(elementIds, true)
      }
    } else {
      toggleVisible(elementIds[0])
    }
  }

  return (
    <IconButton
      size="l"
      className={className}
      tip={t('show_hide_button_tooltip')}
      icon={value ? 'Eye' : 'EyeClose'}
      onClick={handleToggleVisible}
      disabled={disabled}
    />
  )
}

export default Visible
