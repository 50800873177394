import { useCallback } from 'react'
import Downloader from 'js-file-downloader'
import JSZip from 'jszip'
import { isEqual } from 'lodash'
import { takeSnapshotAsBlob } from '@phase-software/renderer'
import { createProvider } from '../utils'
import { useDataStore } from './DataStoreProvider'
import { getPresetData } from '../../dataStore'

const [Provider, useSelectState, useSetState] = createProvider('ExportImagePreset', null)

export const useExportImagePreset = useSelectState

export const useSetExportImagePreset = () => {
  const dataStore = useDataStore()
  const setState = useSetState()

  const handleChange = useCallback(
    (changes) => {
      setState((state) => {
        const newState = { ...state }
        changes.forEach((data, key) => {
          newState[key] = data.value
        })
        return newState
      })
    },
    [setState]
  )

  const setExportImagePreset = useCallback(
    (elementIds) => {
      setState((state) => {
        const newPreset = dataStore.imageExport.getPreset(elementIds.map((id) => dataStore.getById(id)))
        const oldPreset = state && dataStore.getById(state.id)

        if (newPreset === oldPreset) {
          return state
        }
        newPreset.on('CHANGES', handleChange)
        return getPresetData(newPreset)
      })
    },
    [dataStore, setState, handleChange]
  )
  const clearExportImagePreset = useCallback(() => {
    setState((state) => {
      const preset = state && dataStore.getById(state.id)
      preset && preset.off('CHANGES')
      return null
    })
  }, [dataStore, setState])
  return {
    setExportImagePreset,
    clearExportImagePreset
  }
}

export const useExportImagePresetActions = () => {
  const dataStore = useDataStore()
  const $imageExport = dataStore.imageExport
  const $elements = dataStore.selection.get('selected')
  const addRow = useCallback(() => {
    const preset = $imageExport.getPreset($elements)
    // If current preset is global one, clone the preset to apply further changes
    if ($imageExport.presets.has(preset.get('id'))) {
      // TODO: Clone preset and add row
    } else {
      const currentPreset = $elements[0].get('imagePreset')
      if ($elements.length === 1 && currentPreset !== preset) {
        // this means current preset is default one(not custom nor global)
        // set the current preset as custom one and clone current preset as the new latest preset
        $imageExport.set('latest', preset.clone())
        $elements[0].set('imagePreset', preset)
      }
      preset.addRow()
    }
  }, [$imageExport, $elements])
  const removeRow = useCallback(
    (rowId) => {
      const preset = $imageExport.getPreset($elements)
      // If current preset is global one, clone the preset to apply further changes
      if ($imageExport.presets.has(preset.get('id'))) {
        // TODO: Clone preset and add row
      } else {
        preset.removeRow(rowId)
      }
    },
    [$imageExport, $elements]
  )
  const updateRow = useCallback(
    (rowId, prop, value) => {
      const preset = $imageExport.getPreset($elements)
      // If current preset is global one, clone the preset to apply further changes
      if ($imageExport.presets.has(preset.get('id'))) {
        // TODO: Clone preset and add row
      } else {
        preset.updateRow(rowId, prop, value)
      }
    },
    [$imageExport, $elements]
  )
  const elementToImageBlob = useCallback((id, format) => {
    // TODO: we can now export element as image, add this back?
    return takeSnapshotAsBlob(id, format === 'jpg' ? 'jpeg' : format)
  }, [])

  const purgeDuplicateRows = (rows) => {
    const newRows = []
    for (const id in rows) {
      if (newRows.every((newRow) => !isEqual(newRow, rows[id]))) {
        newRows.push(rows[id])
      }
    }
    return newRows
  }

  const exportToFile = useCallback(async () => {
    const imgBlobs = {}
    const preset = $imageExport.getPreset($elements)
    for (const element of $elements) {
      for (const { format, suffix, scale } of purgeDuplicateRows(preset.get('rows'))) {
        const blob = await elementToImageBlob(element.get('id'), format, scale)
        imgBlobs[`${element.get('name')}${suffix}.${format}`] = blob
      }
    }

    const names = Object.keys(imgBlobs)
    if (names.length === 0) {
      return
    }
    if (names.length === 1) {
      return new Downloader({
        url: window.URL.createObjectURL(imgBlobs[names[0]]),
        filename: names[0]
      })
    }
    const zip = new JSZip()
    names.forEach((name) => {
      zip.file(name, imgBlobs[name])
    })
    const zipBlob = await zip.generateAsync({ type: 'blob' })

    return new Downloader({
      url: window.URL.createObjectURL(zipBlob),
      filename: 'images.zip'
    })
  }, [$elements, elementToImageBlob, $imageExport])

  return {
    addRow,
    removeRow,
    updateRow,
    elementToImageBlob,
    exportToFile
  }
}

export default Provider
