import React from 'react'
import { useTranslation } from 'react-i18next'

import { toDeg } from '@phase-software/data-utils'
import { Unit } from '@phase-software/types'

import { useEditor } from '../../../providers/dataStore/EditorProvider'
import { formatAsDegrees, formatAsPercentage, formatNumberWithUnit, multiplyBy100 } from '../../../utils/formatter'
import InspectRow from './InspectRow'
import InteractiveInspectContent from './InteractiveInspectContent'

const TransformInspection = () => {
  const { t } = useTranslation('file', { keyPrefix: 'inspect' })
  const { contentAnchorX, contentAnchorY, contentAnchorXUnit, contentAnchorYUnit, scaleX, scaleY, skewX, skewY } =
    useEditor()

  const handleXDisplay = () => {
    if (contentAnchorXUnit === Unit.PERCENT) return `${formatAsPercentage(contentAnchorX)}`
    return `${formatNumberWithUnit(contentAnchorX)}px`
  }

  const handleYDisplay = () => {
    if (contentAnchorYUnit === Unit.PERCENT) return `${formatAsPercentage(contentAnchorY)}`
    return `${formatNumberWithUnit(contentAnchorY)}px`
  }

  return (
    <InteractiveInspectContent title={t('transform')} target="static_transform">
      <InspectRow label={t('origin')} value={`${handleXDisplay()}, ${handleYDisplay()}`} />
      <InspectRow label={t('scale_x')} value={`${formatAsPercentage(multiplyBy100(scaleX))}`} />
      <InspectRow label={t('scale_y')} value={`${formatAsPercentage(multiplyBy100(scaleY))}`} />
      <InspectRow label={t('skew_x')} value={`${formatAsDegrees(toDeg(skewX))}`} />
      <InspectRow label={t('skew_y')} value={`${formatAsDegrees(toDeg(skewY))}`} />
    </InteractiveInspectContent>
  )
}

export default TransformInspection
