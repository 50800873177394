import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon, IconButton, LinkSwitcher } from '../../../components/shared'
import { GRID_GAP, MIN_CARD_WIDTH, gridConfigMap, gridGapMap } from '../config'

const NUM_OF_ROW = 1

export type TemplateSectionProps = {
  url: string
  onClose: () => void
  fetchMore: () => void
  children?: React.ReactNode
}

const TemplateSection = ({ url, onClose, fetchMore, children }: TemplateSectionProps) => {
  const { t } = useTranslation('workspace')
  const sectionRef = useRef<HTMLDivElement | null>(null)
  const [numOfChild, setNumOfChild] = useState(1)

  // calculate number of child to be fit in <NUM_OF_ROW> row
  // can't use overflow-hidden because it will crop the card's focus outline
  useEffect(() => {
    const calculateNumOfChild = () => {
      if (!sectionRef.current) return
      const { width } = sectionRef.current.getBoundingClientRect()
      const maxNumOfChild = Math.floor((NUM_OF_ROW * (width + GRID_GAP)) / (MIN_CARD_WIDTH + GRID_GAP))
      setNumOfChild(maxNumOfChild === 0 ? 1 : maxNumOfChild)
      const numberOfChild = React.Children.count(children)

      if (numberOfChild < maxNumOfChild) {
        fetchMore()
      }
    }

    calculateNumOfChild()
    window.addEventListener('resize', calculateNumOfChild)
    return () => {
      window.removeEventListener('resize', calculateNumOfChild)
    }
  }, [setNumOfChild, fetchMore, children])

  return (
    <div className="mx-32 px-32 pb-32 pt-16 flex flex-col gap-y-16 bg-light-overlay-10 rounded-lg">
      <div className="w-full flex justify-between items-center">
        <LinkSwitcher
          to={url}
          noUnderline
          defaultFocusStyle={false}
          tabIndex={0}
          className="h-40 flex items-center text-light-overlay-60 group focus-visible:text-white focus-visible:outline-primary-1-offset-2 rounded-sm"
        >
          <h3 className="text-h3 font-semibold truncate text-white">{t('templates')}</h3>
          <Icon name="AngleRight24" size="xxl" interactive={false} useCurrentColor className="group-hover:text-white" />
        </LinkSwitcher>
        <IconButton icon="Cross" size="xxl" data-test-id="close-template-section-button" onClick={onClose} />
      </div>
      <div
        className={`${gridConfigMap[MIN_CARD_WIDTH]} ${gridGapMap[GRID_GAP]} gap-16 h-fit`}
        data-test-id="featured-template-list"
        ref={sectionRef}
      >
        {React.Children.map(children, (child, index) => {
          return index < numOfChild ? child : null
        })}
      </div>
    </div>
  )
}

export default TemplateSection
