import { useCallback } from 'react'
import { schema } from 'normalizr'
import { createProvider, useEntity } from '../utils'
import { useSetLayer } from './LayerProvider'
import { Paint } from './PaintProvider'

export const Stroke = new schema.Entity(
  'strokes',
  { paint: Paint },
  {
    processStrategy: (value, parent) => {
      return { ...value, styleId: parent.id, elementId: parent.elementId }
    }
  }
)

const defaultValue = {}
const [Provider, useSelectState, useSetState] = createProvider('Stroke', defaultValue)

export const useStroke = useSelectState

export const useSetStroke = () => {
  const { createLayer, destroyLayer } = useSetLayer()
  const setState = useSetState()

  const [setStroke, addStroke, updateStroke, removeStroke, mergeStroke, reduceStroke, create, destroy] = useEntity(
    Stroke,
    setState
  )

  const createStroke = useCallback(
    (entities) => {
      createLayer(entities)
      create(entities)
    },
    [create, createLayer]
  )

  const destroyStroke = useCallback(
    (entities) => {
      destroy(entities)
      destroyLayer(entities)
    },
    [destroy, destroyLayer]
  )
  return {
    setStroke,
    addStroke,
    updateStroke,
    removeStroke,
    mergeStroke,
    reduceStroke,
    createStroke,
    destroyStroke
  }
}

export default Provider
