import { notNull } from '@phase-software/data-utils'
import {
  getProgress,
  getPropPercentage,
  getStepPercentage,
  getAnimationByPercentage
} from '../utils/timing-functions'
import Interval from '../helpers/Interval'
import ActionStack from './ActionStack'


/** @typedef {import('@phase-software/types').KeyFrame} KeyFrame */


class PropertyStack {
  constructor(elementStack, data) {
    this.elementStack = elementStack
    this.manager = elementStack.manager
    this.dataStore = elementStack.dataStore

    this._init(data)
  }

  _init(data = {}) {
    // TODO: Should move type to phase-types repo
    this.animatableProperties = new Set()
    this.type = 'PROPERTY'
    this.isRepeatableType = false

    this.id = data.id
    this.key = data.propKey
    this.basePropKeys = []
    // Only child stack can have parentId for its parent property stack
    this.parentId = data.parentId || null
    this.children = new Map()
    this.childrenKeys = new Set()
    /** @type {ActionStack[]} */
    this.actions = []
    if (data.kfs && data.kfs.length) {
      this.addAction(data.kfs, data.startTime)
    }
    this._interval = new Interval()
  }

  init() {
    if (this.actions.length) {
      this.actions[0].init()
    }
  }

  renewBaseValue() {
    this._updateNonBaseCacheAndDefaultKF()
  }

  /**
   * Add a action
   * @param {string[]} keyFrameList
   * @param {number} startTime
   */
  addAction(keyFrameList, startTime) {
    // TODO: MVP only have one action
    // TODO: Should have actionId to create a new ActionStack
    if (!this.actions[0]) {
      const actionData = {
        keyFrameList
      }
      const newAction = new ActionStack(
        this,
        actionData,
        startTime,
      )
      this.actions.push(newAction)
    } else {
      this.updateKeyFrameOrder(keyFrameList)
    }
  }

  /**
   * Get keyframe state by time
   * @param {number} time
   * @returns {object}
   */
  getKFState(time) {
    const key = this.key === 'paint' ? this.key : this.dataKey
    return {
      [key]: this.actions[0].getKFState(time)
    }
  }

  /**
   * Set parentId
   * @param {string} parentId
   */
  setParentId(parentId) {
    this.parentId = parentId
  }

  /**
   * Add children to a property stack
   * @param {PropertyStack} childStack
   */
  addChildren(childStack) {
    if (!childStack) {
      return
    }

    const childKey = childStack.key
    if (!childKey || this.childrenKeys.has(childKey)) {
      return
    }

    this.elementStack.propertyStacksMap.set(childStack.id, childStack)
    this.childrenKeys.add(childKey)
    this.children.set(childStack.key, childStack)
    this.manager.cache.addToMap(childStack)
    childStack.renewBaseValue()
  }

  /**
   * Remove children from a property stack
   * @param {PropertyStack} childStack
   */
  removeChildren(childStack) {
    this.resetComputedData(childStack.dataKey)
    this.childrenKeys.delete(childStack.key)
    this.children.delete(childStack.key)
    this.manager.cache.deleteFromMap(childStack.id)
    this.elementStack.deletePropertyStackFromMap(childStack.id)
  }

  resetComputedData() {

  }

  /**
   * Update order of KFs
   * @param {string[]} newKFList  new list of KFs ids in order
   */
  updateKeyFrameOrder(newKFList) {
    this._interval.clear()
    // TODO: Need to know which action is the newKFs from when we have multi-actions
    this.actions[0].updateKeyFrameOrder(newKFList)
  }

  /**
   * Add a keyframe
   * @param {object} newKF
   * @param {number} index
   */
  addKF(newKF, index) {
    // TODO: Need to know which action is the newKFs from when we have multi-actions
    this.actions[0].addKF(newKF, index)
    this._updateNonBaseCacheAndDefaultKF()
  }

  /**
   * Add a keyframe
   * @param {string} deletedKFId
   */
  deleteKF(deletedKFId) {
    // TODO: Need to know which action is the newKFs from when we have multi-actions
    this.actions[0].deleteKF(deletedKFId)
    if (this.parentId) {
      const parent = this.manager.cache.getById(this.parentId)
      this.elementStack.deletePropState(this.key, parent.key, parent.clKey)
    } else {
      this.elementStack.deletePropState(this.key)
    }
    this._updateNonBaseCacheAndDefaultKF()
  }

  /**
   * Update a keyframe
   * @param {string} KFId
   * @param {object} data
   */
  updateKF(KFId, data) {
    // TODO: Need to know which action is the newKFs from when we have multi-actions
    this.actions[0].updateKF(KFId, data)
    this._updateNonBaseCacheAndDefaultKF()
  }

  _updateNonBaseCacheAndDefaultKF() {
    if (this.parentId) {
      const parentStack = this.elementStack.getPropertyStack(this.parentId)
      if (parentStack && parentStack.isRepeatableType) {
        parentStack.updateNonBaseValue()
        this.updateDefaultKF()
        this.actions[0].updateWorkingInterval(this.manager.currentTime, true)
      }
    }
  }

  /**
   * Get animation data
   * @param {number} time
   * @returns {any | null}
   */
  getAnimateData(time) {
    const interval = this.getWorkingInterval(time)
    if (!interval) {
      return null
    }

    const workingTime = this.getWorkingTime(time)
    if (this._isAnimatable()) {
      // TODO: not Create objects; re-use static Object on the instance of this class instead
      return {
        [this.dataKey]: this._getInterpolateData(workingTime, interval)
      }
    }

    // TODO: not Create objects; re-use static Object on the instance of this class instead
    return {
      [this.dataKey]: this._getInstantChangeData(workingTime, interval)
    }
  }

  /**
   * Get working time for animation
   * @param {number} time
   * @returns {number}
   */
  getWorkingTime(time) {
    // TODO: Need to consider if we have multi-actions
    return this.actions[0].getWorkingTime(time)
  }

  /**
   * Get working interval by time
   * @protected
   * @param {number} time
   * @returns {Interval | undefined}  interval (READONLY) or undefined if no interval found
   *                                    DO NOT modify this returned value (outside of this class and it's subclasses)
   */
  getWorkingInterval(time) {
    // TODO: Need to think if need to have override animate data by action if have multi-actions
    const actionInterval = this.actions[0].getWorkingInterval(time)
    if (!actionInterval) {
      return
    }

    const interval = this._interval
    interval.copy(actionInterval, true).updateValues(
      this.actions[0].getKFValue(actionInterval.start),
      this.actions[0].getKFValue(actionInterval.end)
    )

    return interval
  }

  /**
   * Get instant change data
   * @param {number} time
   * @param {[object, object]} interval
   * @returns {object|number}
   */
  _getInstantChangeData(time, interval) {
    // Get kfs from a interval and calculate the instant change data
    const progress = getProgress(time, interval)
    const percent = getStepPercentage(progress)
    return getAnimationByPercentage(percent, interval[0].value, interval[1].value)
  }

  /**
   * Get instant change extra data
   * @param {number} time
   * @param {[object, object]} interval
   * @param {string} key key inside extra
   * @returns {object|number}
   */
  _getInstantChangeExtraData(time, interval, key) {
    // Get kfs from a interval and calculate the instant change data
    const progress = getProgress(time, interval)
    const percent = getStepPercentage(progress)
    const start = interval[0].extra || 0
    const end = interval[1].extra || 0
    return getAnimationByPercentage(
      percent,
      notNull(start[key]) ? start[key] : 0,
      notNull(end[key]) ? end[key] : 0
    )
  }

  /**
   * Get interpolate data from interval
   * @param {number} time
   * @param {[object, object]} interval
   * @returns {object|number}
   */
  _getInterpolateData(time, interval) {
    // Get kfs from a interval and calculate the interpolate data
    const progress = getProgress(time, interval)
    const percent = getPropPercentage(interval[1], progress)
    return getAnimationByPercentage(percent, interval[0].value, interval[1].value)
  }

  /**
   * Check if the prop is animatable
   * @param {string} propName
   * @returns {bool}
   */
  _isAnimatable(propName) {
    const key = propName || this.dataKey
    return this.animatableProperties.has(key)
  }

  /**
   * Get value from base
   * @param {{ key:string }} [propertyStack]    optionally get any prop value for other property stack
   * @returns {number|object}
   */
  getBaseValue(propertyStack) {
    if (this.parentId) {
      const parentStack = this.elementStack.getPropertyStack(this.parentId)
      // If is layer prop, we should get init value from layer stack
      const baseValue = this.elementStack.getBaseValue(parentStack)
      return baseValue[this.key]
    }

    return this.elementStack.getBaseValue(propertyStack || this)
  }

  /**
   * Get default value from base for default keyframe
   * @returns {number|object}
   */
  getInitValue() {
    return this.getBaseValue()
  }

  /**
   * Check if the property is end of animation
   * @param {number} time
   * @returns {bool}
   */
  isPropertyFinished(time) {
    return this.actions.every((action) => action.isActionFinished(time))
  }

  /**
   * Update default KF for all ActionStacks
   */
  updateDefaultKF() {
    this.actions.forEach((action) => {
      action.recalculateDefaultKF()
    })
  }

  /**
   * Update default KF for all ActionStacks
   */
  updateDefaultKFAndInterval() {
    this.actions.forEach((action) => {
      action.recalculateDefaultKF()
      action.updateWorkingInterval(this.manager.currentTime, true)
    })
  }

  /**
   * Reset start time for all actions
   * @param {number} time
   */
  resetStartTime(time) {
    this.actions.forEach((action) => {
      action.resetStartTime(time)
    })
  }

  /**
   * Clear property stack
   */
  clear() {
    this.actions.forEach((action) => {
      action.clear()
    })
    this.actions = []
    this.children.forEach((childStack) => {
      this.removeChildren(childStack)
    })
    this.children = new Map()
    this.childrenKeys = new Set()
  }
}

export default PropertyStack
