import { generatePath } from 'react-router-dom'
import {
  WORKSPACE_INDEX,
  WORKSPACE_DRAFTS,
  WORKSPACE_ARCHIVE,
  WORKSPACE_TEMPLATE,
  WORKSPACE_PROJECT,
  PERSONAL_SETTINGS_CHANGE_PASSWORD,
  PERSONAL_SETTINGS_PROFILE,
  TEAM_SETTINGS_GENERAL,
  TEAM_SETTINGS_MEMBERS,
  FILE_INDEX,
  FILE_MODE_SELECTOR,
  PERSONAL_SETTINGS_INDEX,
  TEAM_SETTINGS_INDEX,
  PERSONAL_SHARED,
  TEAM_SETTINGS_GENERAL_VIEW_MODE,
  TEMPLATE_CLONE,
  WORKSPACE_TEMPLATE_CLONE
} from '../routeConstants'

import { FileFieldsFragment } from '../generated/graphql'
import { WorkspaceData } from '../providers/WorkspaceContextProvider'

export const getWorkspaceIndexPath = (workspaceType: WorkspaceData['type'], workspaceSlug: WorkspaceData['slug']) =>
  generatePath(WORKSPACE_INDEX, { workspaceType, workspaceSlug })
export const getWorkspaceDraftsPath = (workspaceType: WorkspaceData['type'], workspaceSlug: WorkspaceData['slug']) =>
  generatePath(WORKSPACE_DRAFTS, { workspaceType, workspaceSlug })
export const getWorkspaceArchivePath = (workspaceType: WorkspaceData['type'], workspaceSlug: WorkspaceData['slug']) =>
  generatePath(WORKSPACE_ARCHIVE, { workspaceType, workspaceSlug })
export const getWorkspaceTemplatePath = (
  workspaceType: WorkspaceData['type'],
  workspaceSlug: WorkspaceData['slug']
) => {
  return generatePath(WORKSPACE_TEMPLATE, { workspaceType, workspaceSlug })
}
export const getTemplatePath = (
  templateId: string,
  workspaceType?: WorkspaceData['type'],
  workspaceSlug?: WorkspaceData['slug']
) => {
  if (workspaceType && workspaceSlug) {
    return generatePath(WORKSPACE_TEMPLATE_CLONE, { workspaceType, workspaceSlug, templateId })
  }
  return generatePath(TEMPLATE_CLONE, { templateId })
}

export const getWorkspaceProjectPath = (
  workspaceType: WorkspaceData['type'],
  workspaceSlug: WorkspaceData['slug'],
  projectId: string
) => generatePath(WORKSPACE_PROJECT, { workspaceType, workspaceSlug, projectId })

export const getPersonalSharedPath = (workspaceSlug: WorkspaceData['slug']) =>
  generatePath(PERSONAL_SHARED, { workspaceSlug })

// Personal Settings
export const getPersonalSettingsIndexPath = (workspaceSlug: WorkspaceData['slug']) =>
  generatePath(PERSONAL_SETTINGS_INDEX, { workspaceSlug })
export const getPersonalProfilePath = (workspaceSlug: WorkspaceData['slug']) =>
  generatePath(PERSONAL_SETTINGS_PROFILE, { workspaceSlug })
export const getPersonalChangePasswordPath = (workspaceSlug: WorkspaceData['slug']) =>
  generatePath(PERSONAL_SETTINGS_CHANGE_PASSWORD, { workspaceSlug })

// Team Settings
export const getTeamSettingsIndexPath = (workspaceSlug: WorkspaceData['slug']) =>
  generatePath(TEAM_SETTINGS_INDEX, { workspaceSlug })
export const getTeamSettingsGeneralPath = (workspaceSlug: WorkspaceData['slug']) =>
  generatePath(TEAM_SETTINGS_GENERAL, { workspaceSlug })
export const getTeamSettingsGeneralViewModePath = (workspaceSlug: WorkspaceData['slug']) =>
  generatePath(TEAM_SETTINGS_GENERAL_VIEW_MODE, { workspaceSlug })

// Team Members
export const getTeamSettingsMembersPath = (workspaceSlug: WorkspaceData['slug']) =>
  generatePath(TEAM_SETTINGS_MEMBERS, { workspaceSlug })

// File
export const getFileIndexPath = (fileId: FileFieldsFragment['id']) => generatePath(FILE_INDEX, { fileId })
export const getFilePathByMode = (
  fileId: FileFieldsFragment['id'],
  mode: 'edit' | 'view',
  searchParams?: URLSearchParams
) => {
  const path = generatePath(FILE_MODE_SELECTOR, { fileId, mode })
  const search = searchParams?.toString()

  return search ? `${path}?${search}` : path
}
