import { useEffect, useMemo, useRef, useState } from 'react'

import { debounce } from 'lodash'

import { GetTemplatesQueryVariables, Order_By as OrderBy, useGetTemplatesQuery } from '../../generated/graphql'
import { WorkspaceData } from '../../providers/WorkspaceContextProvider'

export type SortOption = {
  name: string
  value: string
  orderBy: GetTemplatesQueryVariables['orderBy']
}

export const SORT_OPTIONS: SortOption[] = [
  { name: 'alphabetical', value: 'alphabetical', orderBy: { name: OrderBy.Asc } },
  { name: 'date_created', value: 'created_at', orderBy: { created_at: OrderBy.Desc } }
]
const PAGINATION_LIMIT = 10
const DEFAULT_SORT_BY = SORT_OPTIONS[0].value

type useTemplateListProps = {
  projectId: string
  workspaceData: WorkspaceData
}

const useTemplateList = ({ projectId }: useTemplateListProps) => {
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('')
  const [searchQuery, setSearchQuery] = useState('')
  const [sortBy, setSortBy] = useState(DEFAULT_SORT_BY)
  const [limit, setLimit] = useState(PAGINATION_LIMIT)

  const selectedMenuOption = useMemo(() => SORT_OPTIONS.find((option) => option.value === sortBy), [sortBy])
  const observerRef = useRef(null)

  const {
    data: templatesData,
    loading: isLoadingTemplates,
    fetchMore: fetchMoreTemplates,
    refetch: refetchTemplates
  } = useGetTemplatesQuery({
    variables: {
      projectId,
      orderBy: selectedMenuOption?.orderBy,
      limit,
      offset: 0
    },
    skip: !projectId
  })

  const templates = useMemo(() => templatesData?.files ?? [], [templatesData])

  const filteredTemplates = useMemo(() => {
    if (!debouncedSearchQuery) {
      return templates
    }
    return templates.filter((file) => file?.name?.toLowerCase().includes(debouncedSearchQuery.toLowerCase()))
  }, [debouncedSearchQuery, templates])

  useEffect(() => {
    refetchTemplates()
  }, [refetchTemplates])

  useEffect(() => {
    const delayDebounceFn = debounce(() => {
      setDebouncedSearchQuery(searchQuery)
    }, 500)

    delayDebounceFn()

    return delayDebounceFn.cancel
  }, [searchQuery])

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          const currentLength = templates.length
          fetchMoreTemplates({
            variables: {
              offset: currentLength,
              limit: PAGINATION_LIMIT
            }
          }).then((fetchMoreResult) => {
            setLimit(currentLength + fetchMoreResult.data.files.length)
          })
        }
      },
      {
        threshold: 0,
        rootMargin: '500px 0px 0px 0px'
      }
    )
    const observerRefCurrent = observerRef.current
    if (observerRefCurrent) {
      observer.observe(observerRefCurrent)
    }

    return () => {
      if (observerRefCurrent) {
        observer.unobserve(observerRefCurrent)
      }
    }
  }, [fetchMoreTemplates, templates])

  return {
    observerRef,
    filteredTemplates,
    hasTemplates: !!templates.length,
    hasFilteredTemplates: !!filteredTemplates.length,
    isLoadingTemplates,
    searchQuery,
    setSearchQuery,
    setSortBy,
    sortBy
  }
}

export default useTemplateList
