import React from 'react'
import { useTranslation } from 'react-i18next'

import { formatNumberWithUnit } from '../../../../utils/formatter'
import { Icon } from '../../../shared'
import Property from '../Property'

const cornerRadius = <Icon interactive={false} name="Radius" />

type CornerRadiusProps = {
  isShapeMode: boolean
  columns?: string
}

const CornerRadius = ({ isShapeMode, columns }: CornerRadiusProps) => {
  const { t } = useTranslation('file', { keyPrefix: 'property_editor' })

  return (
    <Property
      field="cornerRadius"
      caption={cornerRadius}
      tooltip={t('corner_radius')}
      data-test-id="corner-radius-input"
      min={0}
      columns={columns}
      hideWhenNoValue={!isShapeMode}
      toDisplay={formatNumberWithUnit}
    />
  )
}

export default CornerRadius
