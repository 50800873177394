import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { PresenceCallbackMessageData } from '../../../Broadcaster/PresenceManager'
import useElementResize from '../../../hooks/useElementResize'
import { PresenceAvatarGroup } from '../Avatar/AvatarGroup'
import IconButton from '../IconButton'
import LinkSwitcher from '../LinkSwitcher'
import Tooltip from '../Tooltip'

export type FileCardProps = {
  to: string | undefined
  imageUrl?: string | null
  name?: string | null
  metadata: string
  className?: string
  participants: PresenceCallbackMessageData[]
  onContextMenu: ({
    cursorPosition,
    trigger
  }: {
    cursorPosition?: { top: number; left: number }
    trigger?: React.RefObject<HTMLElement>
  }) => void
  onMouseEnter?: () => void
}

const FileCard = ({
  to,
  imageUrl,
  name,
  metadata,
  onContextMenu,
  onMouseEnter,
  className = '',
  participants = []
}: FileCardProps) => {
  const { t } = useTranslation('workspace')
  const bottomRef = useRef<HTMLDivElement>(null)
  const infoRef = useRef<HTMLDivElement>(null)
  const triggerRef = React.useRef<HTMLButtonElement>(null)
  const { offsetWidth: infoWidth } = useElementResize(infoRef)

  const handleImageBroken = (e: React.SyntheticEvent<HTMLImageElement>) => {
    e.currentTarget.hidden = true
  }
  const openDropdownMenu = (e: React.MouseEvent) => {
    e.preventDefault()
    onContextMenu({
      trigger: triggerRef
    })
  }

  const handleContextMenu = (e: React.MouseEvent) => {
    e.preventDefault()
    onContextMenu({
      cursorPosition: {
        top: e.clientY,
        left: e.clientX
      }
    })
  }

  return (
    <LinkSwitcher
      data-test-id="file-card"
      noUnderline
      to={to}
      defaultFocusStyle={false}
      className={`
      group rounded-xl text-white bg-light-overlay-5 relative
      after:rounded-xl after:border after:border-transparent after:transition after:duration-300 after:ease-out after:absolute after:inset-0
      hover:after:border-light-overlay-10 hover:bg-light-overlay-10 after:pointer-events-none
      focus-visible:outline-primary-2
      focus:hover:after:border-transparent
      active:after:hidden
      ${className}`}
    >
      <div className="px-8 pt-8 pb-16" onContextMenu={handleContextMenu} onMouseEnter={onMouseEnter}>
        <div className="aspect-h-3 aspect-w-4 w-full rounded-sm overflow-hidden bg-secondary-60 relative">
          <img
            src={imageUrl ?? ''}
            alt="animation thumbnail"
            loading="lazy"
            onError={handleImageBroken}
            className="object-cover object-center w-full h-full duration-300 ease-out group-hover:transform group-hover:scale-105"
          />
          <div className="absolute bg-black opacity-0 transition-opacity group-hover:duration-300 ease-out group-hover:opacity-60 focus-within:opacity-60"></div>
          <div className="transition-opacity group-hover:duration-300 ease-out opacity-0 group-hover:opacity-100 focus-within:opacity-100 pointer-events-none group-hover:pointer-events-auto focus-within:pointer-events-auto z-1 absolute left-auto bottom-auto right-16 top-16 w-24 h-24">
            <IconButton
              data-test-id="file-settings"
              icon="More"
              size="xxl"
              disableGroupHover={true}
              onClick={openDropdownMenu}
              ref={triggerRef}
            />
          </div>
        </div>
        <div className="flex items-center justify-between px-8 pt-8" ref={bottomRef}>
          <div className="min-w-[140px] flex-shrink-1 pr-16" ref={infoRef}>
            <Tooltip content={name} visibleOnOverflow>
              <div className="text-14 font-semibold truncate" data-test-id="file-name">
                {name}
              </div>
            </Tooltip>
            <div className="text-12 text-light-overlay-60 truncate">{t('modified', { metadata })}</div>
          </div>
          <div>
            <PresenceAvatarGroup
              users={participants}
              parentRef={bottomRef}
              maxVisible={10}
              avatarSpacing={8}
              pruneWidth={infoWidth}
              disableStateEffect
              popperPlacement="top"
            />
          </div>
        </div>
      </div>
    </LinkSwitcher>
  )
}

export default FileCard
