import React, { useEffect, useLayoutEffect } from 'react'

// @ts-ignore
import { IS, cleanupRenderer } from '@phase-software/renderer'

import { useBroadcaster } from '../../Broadcaster'
import Canvas from '../../components/Canvas'
import SubscribeTransitionManagerChange from '../../components/DataStore/SubscribeTransitionManagerChange'
import { NotificationList } from '../../components/Notification'
import Toolbar from '../../components/TopTools/FileToolbar'
import { DEFAULT_SPEED } from '../../constants/interactionConstants'
import { FileFieldsFragment } from '../../generated/graphql'
import { useParticipate, useTogglePresencePreference } from '../../hooks/usePresence'
import { useFileVersionContext } from '../../providers/FileVersionContextProvider'
import { useSetModal } from '../../providers/ModalProvider'
import { PresenceUsersProvider } from '../../providers/PresenceUsersProvider'
import { useTransitionManagerActions } from '../../providers/TransitionManagerProvider'
import { WorkspaceData } from '../../providers/WorkspaceContextProvider'
import { useDataStore, useDataStoreActions } from '../../providers/dataStore/DataStoreProvider'
import { useUI } from '../../providers/dataStore/UIProvider'
import FileLayout from './FileLayout'

type FileViewerProps = {
  fileId: FileFieldsFragment['id']
  projectId: FileFieldsFragment['id']
  workspaceData: WorkspaceData
}

const FileViewer = ({ fileId, projectId, workspaceData }: FileViewerProps) => {
  const dataStore = useDataStore()
  const broadcaster = useBroadcaster()

  const { closeAllModals } = useSetModal()
  const { setViewerLoop, setViewerPlaybackRate, forceUpdateAnimationState } = useTransitionManagerActions()
  const { cancelExportMedia, setPlayheadTime } = useDataStoreActions()
  const { isToolbarHidden } = useUI()

  const { onFileVersionUpdate, currentVersionFileContent } = useFileVersionContext()

  useParticipate(fileId)
  useTogglePresencePreference(fileId)

  useLayoutEffect(() => {
    if (currentVersionFileContent) {
      dataStore.load(currentVersionFileContent)
      forceUpdateAnimationState()
    }
    return () => dataStore.clear()
    // reload file content when file id changes
  }, [currentVersionFileContent, dataStore, forceUpdateAnimationState, fileId])

  useEffect(() => {
    const disableDefaultContextMenu = (e: Event) => e.preventDefault()
    const disableTrackpadPinchToZoom = (e: WheelEvent) => {
      const isMacWithCtrlKey = navigator.userAgent.includes('Mac') && e.ctrlKey
      if (isMacWithCtrlKey) {
        e.preventDefault()
      }
    }
    window.addEventListener('contextmenu', disableDefaultContextMenu)
    window.addEventListener('wheel', disableTrackpadPinchToZoom, {
      passive: false
    })

    setViewerLoop(true)
    setViewerPlaybackRate(DEFAULT_SPEED.value)

    return () => {
      window.removeEventListener('contextmenu', disableDefaultContextMenu)
      window.removeEventListener('wheel', disableTrackpadPinchToZoom)
      setPlayheadTime(0)
      cleanupRenderer()
      closeAllModals()
      cancelExportMedia()
      dataStore.eam.exitEditor()
      IS.pause()
      dataStore.clear()
      dataStore.clearUndo()
    }
  }, [cancelExportMedia, closeAllModals, dataStore, setViewerLoop, setViewerPlaybackRate, setPlayheadTime])

  useEffect(() => {
    if (fileId && projectId && broadcaster) {
      broadcaster.subscribeFileVersionUpdate(fileId, onFileVersionUpdate)
    }
    return () => {
      if (broadcaster) {
        broadcaster.unsubscribeFileVersionUpdate(fileId)
      }
    }
  }, [broadcaster, fileId, onFileVersionUpdate, projectId])

  return (
    <>
      <NotificationList offsetTop={48} />
      <FileLayout>
        {!isToolbarHidden && (
          <PresenceUsersProvider>
            <Toolbar workspaceData={workspaceData} fileId={fileId} projectId={projectId} />
          </PresenceUsersProvider>
        )}
        <Canvas />
        <FileLayout.RightPanel fileId={fileId} projectId={projectId} />
        <SubscribeTransitionManagerChange />
      </FileLayout>
    </>
  )
}
export default FileViewer
