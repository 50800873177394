import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { NOTIFICATION_MAP } from '../../constant'
import { TeamFieldsFragment, UserFieldsFragment } from '../../generated/graphql'
import { useSetNotification } from '../../providers/NotificationProvider'
import { Avatar, ProgressButton } from '../shared'
import Field from './Field'

type AvatarFieldProps = {
  label?: string
  name: string
  value: UserFieldsFragment['avatar'] | TeamFieldsFragment['icon']
  onChange?: () => void
  updateAvatar: (file: File) => Promise<void>
  removeAvatar: () => void
}

const AvatarField = ({ name, value, onChange, updateAvatar, removeAvatar }: AvatarFieldProps) => {
  const { t } = useTranslation('setting')
  const { addNotification } = useSetNotification()

  const ref = useRef<HTMLInputElement | null>(null)
  const [isRemoving, setIsRemoving] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [loadableSrc, setLoadableSrc] = useState<string | null>(null)

  const checkImageLoadable = (src: string): Promise<boolean> => {
    return new Promise((resolve) => {
      const img = new Image()
      img.onload = () => resolve(true)
      img.onerror = () => resolve(false)
      img.src = src
    })
  }

  const retryLoadableSrc = useCallback(async (src: string, retries = 3, delay = 1000): Promise<boolean> => {
    for (let i = 0; i < retries; i++) {
      const isLoadable = await checkImageLoadable(src)
      if (isLoadable) {
        return true
      }
      await new Promise((resolve) => setTimeout(resolve, delay))
    }
    return false
  }, [])

  useEffect(() => {
    const verifySrc = async (src: string) => {
      const isLoadable = await retryLoadableSrc(src)
      if (isLoadable) {
        setLoadableSrc(src)
      }
    }
    if (value) {
      verifySrc(value)
    } else {
      setLoadableSrc(null)
    }
  }, [value, retryLoadableSrc])

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setIsUploading(true)
      const file = e.target.files?.[0]
      if (!file) {
        return
      }
      const size = file.size / (1024 * 1024)
      if (size >= 10) {
        addNotification({
          type: 'error',
          content: t(NOTIFICATION_MAP.UPLOADED_PHOTO_IS_OVER_SIZE_LIMIT.content)
        })
        return
      }

      await updateAvatar(file)
      onChange?.()
      if (ref.current) ref.current.value = ''
    } catch (error) {
      console.error(error)
    } finally {
      setIsUploading(false)
    }
  }

  const handleRemove = async () => {
    if (isRemoving) return

    try {
      setIsRemoving(true)
      await removeAvatar()
    } catch (error) {
      console.error(error)
    } finally {
      setIsRemoving(false)
    }
  }

  const handleUpload = () => {
    if (isUploading) {
      return
    }
    ref.current?.click()
  }

  return (
    <Field>
      <div className="w-auto">
        <Avatar size={136} alt={name} src={loadableSrc} data-test-id="avatar-image" radiusSize="xl" />
        <p className="text-12 font-normal text-light-overlay-60 my-16">{t('maximum_size')}: 10MB</p>
      </div>
      <input ref={ref} type="file" accept="image/*" hidden onChange={handleChange} />
      <div className="mb-8">
        <ProgressButton
          data-test-id="upload-avatar-button"
          color="secondary"
          onClick={handleUpload}
          status={Number(isUploading)}
          disabled={isUploading || isRemoving}
        >
          {t('upload')}
        </ProgressButton>
        <ProgressButton
          data-test-id="remove-avatar-button"
          color="secondary"
          onClick={handleRemove}
          status={Number(isRemoving)}
          disabled={!value || isRemoving || isUploading}
          className="ml-8"
          fixedWidth={64}
        >
          {t('remove')}
        </ProgressButton>
      </div>
    </Field>
  )
}
export default AvatarField
