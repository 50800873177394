import React from 'react'
import CustomContentLoader from './CustomContentLoader'
import { FILE_EDITOR_LAYOUT_AREAS } from '../../../constant'

const EditorPropertyPanelLoading = () => {
  return (
    <div
      className="bg-neutral-90 border-l border-neutral-80 p-32"
      style={{ gridArea: FILE_EDITOR_LAYOUT_AREAS.RIGHT_PANEL }}
    >
      <CustomContentLoader width="100%" height={96} viewBox="0 0 184 96">
        <rect width="91" height="16" rx="5" />
        <rect y="40" width="184" height="16" rx="5" />
        <rect y="80" width="184" height="16" rx="5" />
      </CustomContentLoader>
    </div>
  )
}

export default EditorPropertyPanelLoading
