import { minmax } from '@phase-software/data-utils'

export { minmax }

/**
 * @function parseToDecimals
 * @param {number} value - The value needs to parse to specific decimals value.
 * @param {number} decimals - The specific decimal point.
 * @returns {number} Return the value parsed with a specific decimal point.
 */
export const parseToDecimals = (value, decimals = 0, formatAsString = false) => {
  const power = Math.pow(10, decimals)
  let result = Math.round(value * power) / power
  // compare between -0 and 0
  result = result === 0 ? 0 : result
  return formatAsString ? result.toFixed(decimals) : result
}

/**
 * Get remain of multiple
 * @function remainOf
 * @param {number} number
 * @param {number} multiple
 * @returns {number}
 */
export const remainOf = (number, multiple) => {
  return Math.round(number % multiple)
}

/**
 * Check if is multiple of a number
 * @function isMultipleOf
 * @param {number} number
 * @param {number} multiple
 * @returns {boolean}
 */
export const isMultipleOf = (number, multiple) => {
  return remainOf(remainOf(number, multiple) + multiple, multiple) === 0
}

/**
 * Get middle multiple of rotation between two rotation value
 * @function getMultipleOfRotationBetween
 * @param {number} prev
 * @param {number} curr
 * @param {number} [multipleOf=10]
 * @returns {number}
 */
export const getMultipleBetween = (prev, curr, multipleOf = 10) => {
  if (prev === null || curr === null) {
    return null
  }

  const max = Math.max(prev, curr)
  const min = Math.min(prev, curr)
  let midMultipleOfRotation = curr - remainOf(curr, multipleOf)
  if (curr > prev && curr < 0) {
    midMultipleOfRotation -= multipleOf
  } else if (curr < prev && curr > 0) {
    midMultipleOfRotation += multipleOf
  }
  const result = Math.round(midMultipleOfRotation)
  return result < max && result > min ? result : null
}
