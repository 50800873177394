import { CapShape, JoinShape, PaintType } from '@phase-software/types'
import { data } from './SpatialCache'

/** @typedef {import("../math").Rect2} Rect2 */
/** @typedef {import("../math").Transform2D} Transform2D */

let ii = 0
/** @enum {number} */
export const RenderCommandTypes = {
    _invalid_: ii++,

    fill: ii++, // 1
    stroke: ii++, // 2
    push_canvas: ii++, // 3
    push_fill_as_mask: ii++, // 4
    pop_mask_and_free: ii++, // 5
    pop_canvas_as_mask: ii++, // 6
    pop_canvas_and_blit_with_opacity: ii++, // 7
    pop_canvas_and_blit_with_blend: ii++, // 8
}
const GradientTypes = {
    [PaintType.GRADIENT_LINEAR]: 0,
    [PaintType.GRADIENT_RADIAL]: 1,
    [PaintType.GRADIENT_ANGULAR]: 2,
    [PaintType.GRADIENT_DIAMOND]: 3
}

/**
 * @typedef RenderCommand
 * @property {RenderCommandTypes} type
 * @property {*} [geometry]
 * @property {Rect2} [cover]
 * @property {Transform2D} [transform]
 * @property {Paint_t} [paint]
 * @property {number} [opacity]
 * @property {BlendModes} [blend]
 */

export const CommandFill = (function() {
    return {
        /**
         * @param {number} geometry
         * @param cmd
         * @param ver
         * @param {Transform2D} transform
         * @param {Paint_t} paint
         * @param {number} opacity
         * @returns {number[]}
         */
        create(geometry, cmd, ver, transform, paint, opacity) {
            const group = []
            group.push(RenderCommandTypes.fill, geometry, cmd, ver, transform.a, transform.b, transform.c, transform.d, transform.tx, transform.ty, opacity * paint.params.opacity[0])
            switch (paint.type) {
                case PaintType.SOLID: {
                    group.push(1, paint.params.fill_color[0], paint.params.fill_color[1], paint.params.fill_color[2])
                    break
                }
                case PaintType.GRADIENT_LINEAR:
                case PaintType.GRADIENT_RADIAL:
                case PaintType.GRADIENT_ANGULAR:
                case PaintType.GRADIENT_DIAMOND: {
                    // store gradient colorStops to blobs
                    const colorStops = paint.gradient.colorStops.map(stop => [stop.color[0], stop.color[1], stop.color[2], stop.color[3], stop.pos]).flat()
                    const cstops = data.views_id
                    data.views_u32Array.set([data.blobs_curr_idx, colorStops.length * 4, 0], data.views_id * 3)
                    data.views_id += 1
                    data.blobs_f32Array.set(colorStops, data.blobs_curr_idx / 4)
                    data.blobs_curr_idx += colorStops.length * 4
                    colorStops.length = 0

                    group.push(2, GradientTypes[paint.type], paint.transform.a, paint.transform.b, paint.transform.c, paint.transform.d, paint.transform.tx, paint.transform.ty, cstops)
                    break
                }
                case PaintType.IMAGE: {
                    group.push(3, paint.image.texture_id, paint.imageOptions.mode)
                    break
                }
                default: {
                    group.push(1, 1, 0, 0, 1)
                }
            }
            return group
        },
    }
})()

export const CommandStroke = (function() {
    return {
        /**
         * @param {number} geometry
         * @param cmd
         * @param ver
         * @param {number} strokeIndex
         * @param {Transform2D} transform
         * @param {StrokeStyle} stroke
         * @param {Paint_t} paint
         * @param {number} opacity
         */
        create(geometry, cmd, ver, strokeIndex, transform, stroke, paint, opacity) {
            const group = []
            group.push(RenderCommandTypes.stroke, geometry, cmd, ver, strokeIndex, transform.a, transform.b, transform.c, transform.d, transform.tx, transform.ty, opacity * paint.params.opacity[0])
            let cap = 0
            switch (stroke.startCap) {
                case CapShape.NONE: {
                    cap = 0
                    break
                }
                case CapShape.ROUND: {
                    cap = 1
                    break
                }
                // TODO: replace this with SQUARE when it's added
                case CapShape.SQUARE_SOLID: {
                    cap = 2
                    break
                }
            }
            let join = 0
            switch (stroke.join) {
                case JoinShape.MITER: {
                    join = 0
                    break
                }
                case JoinShape.BEVEL: {
                    join = 1
                    break
                }
                case JoinShape.ROUND: {
                    join = 2
                    break
                }
            }

            // store dashpattern to blobs
            const dashPattern = data.views_id
            data.blobs_curr_idx = Math.ceil(data.blobs_curr_idx / 8) * 8
            data.views_u32Array.set([data.blobs_curr_idx, stroke.dashPattern.length * 8, 0], data.views_id * 3)
            data.views_id += 1
            data.blobs_f64Array.set(stroke.dashPattern, data.blobs_curr_idx / 8)
            data.blobs_curr_idx += stroke.dashPattern.length * 8

            group.push(stroke.width, cap, join, stroke.miterLimit, dashPattern)
            switch (paint.type) {
                case PaintType.SOLID: {
                    group.push(1, paint.params.fill_color[0], paint.params.fill_color[1], paint.params.fill_color[2])
                    break
                }
                case PaintType.GRADIENT_LINEAR:
                case PaintType.GRADIENT_RADIAL:
                case PaintType.GRADIENT_ANGULAR:
                case PaintType.GRADIENT_DIAMOND: {
                    // store gradient colorStops to blobs
                    const colorStops = paint.gradient.colorStops.map(stop => [stop.color[0], stop.color[1], stop.color[2], stop.color[3], stop.pos]).flat()

                    const cstops = data.views_id
                    data.views_u32Array.set([data.blobs_curr_idx, colorStops.length * 4, 0], data.views_id * 3)
                    data.views_id += 1
                    data.blobs_f32Array.set(colorStops, data.blobs_curr_idx / 4)
                    data.blobs_curr_idx += colorStops.length * 4
                    colorStops.length = 0

                    group.push(2, GradientTypes[paint.type], paint.transform.a, paint.transform.b, paint.transform.c, paint.transform.d, paint.transform.tx, paint.transform.ty, cstops)
                    break
                }
                case PaintType.IMAGE: {
                    group.push(3, paint.image.texture_id, paint.imageOptions.mode)
                    break
                }
                default: {
                    group.push(1, 1, 0, 0, 1)
                }
            }
            return group
        },
    }
})()

export const CommandPushFillAsMask = (function() {
    return {
        /**
         * @param {number} geometry
         * @param cmd
         * @param ver
         * @param {Transform2D} transform
         */
        create(geometry, cmd, ver, transform) {
            return [RenderCommandTypes.push_fill_as_mask, geometry, cmd, ver, transform.a, transform.b, transform.c, transform.d, transform.tx, transform.ty]
        },
    }
})()

export const CommandPush = (function() {
    return {
        create() {
            return RenderCommandTypes.push_canvas
        },
    }
})()

export const CommandPopAsMask = (function() {
    return {
        create() {
            return RenderCommandTypes.pop_canvas_as_mask
        },
    }
})()

export const CommandPopMaskAndFree = (function() {
    return {
        create() {
            return RenderCommandTypes.pop_mask_and_free
        },
    }
})()

export const CommandPopAndBlitWithBlend = (function() {
    let ii = 0
    const blend_index = {
        darken: ii++,
        multiply: ii++,
        color_burn: ii++,
        lighten: ii++,
        screen: ii++,
        color_dodge: ii++,
        overlay: ii++,
        soft_light: ii++,
        hard_light: ii++,
        difference: ii++,
        exclusion: ii++,
        hue: ii++,
        saturation: ii++,
        color: ii++,
        luminosity: ii++,
    }

    return {
        /**
         * @param {BlendModes} blend
         */
        create(blend) {
            return [RenderCommandTypes.pop_canvas_and_blit_with_blend, blend_index[blend]]
        },
    }
})()

export const CommandPopAndBlitWithOpacity = (function() {
    return {
        /**
         * @param {number} opacity
         */
        create(opacity) {
            return [RenderCommandTypes.pop_canvas_and_blit_with_opacity, opacity]
        },
    }
})()
