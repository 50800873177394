/* eslint-disable @typescript-eslint/no-unused-vars */
import { Vertex, Mesh, EntityChange } from '@phase-software/data-utils'
import { ElementType } from '@phase-software/types'

export default class MeshChangeHandler {
  constructor(dataStore) {
    this.dataStore = dataStore
  }

  /*
   * Prepare base data from mesh changes undo event
   * */
  _prepareDataForChanges = ({ owner, event }) => {
    if (owner.data) {
      const ownerId = owner.get('id') || null
      const ownerType = 'Element'

      return {
        payload: event,
        ownerId: ownerId,
        ownerType: ownerType
      }
    } else {
      return null
    }
  }

  onParseMeshChange({ owner, event }) {
    const original = this._prepareDataForChanges({ owner, event })
    const data = {}
    let isAddElement = false
    if (original !== null) {
      Array.from(event.CREATE).reduce((entityMap, entityId) => {
        // return entityMap
        isAddElement = true
        const entity = this.onParseMeshChangeCreate(owner, entityMap, entityId)
        if (entity) {
          // isAddElement = true
        } else {
          console.log('can not get element or component in event.CREATE')
        }
        return entity
      }, data)

      Array.from(event.UPDATE).reduce((entityMap, [entityId, changeValue]) => {
        const specialCaseChangeKey = 'unlinkedCurveControl' // change value contains circular structure
        if (changeValue.has(specialCaseChangeKey)) {
          // address the change data
          if (!entityMap.specialCases) {
            entityMap.specialCases = []
          }

          // override the value in payload
          const propChanges = original.payload.UPDATE.get(entityId)
          const changeValueInPayload = propChanges.get(specialCaseChangeKey)
          if (changeValueInPayload.before) {
            if (changeValueInPayload.before.save) {
              changeValueInPayload.before = changeValueInPayload.before.save()
            }
            const beforeCopy = { ...changeValueInPayload.before }
            entityMap.specialCases.push(beforeCopy)
          }
          if (changeValueInPayload.after) {
            if (changeValueInPayload.after.save) {
              changeValueInPayload.after = changeValueInPayload.after.save()
            }
            const afterCopy = { ...changeValueInPayload.after }
            entityMap.specialCases.push(afterCopy)
          }
        }
        return entityMap
      }, data)

      return { data, ...original, isAddElement }
    } else {
      return null
    }
  }

  /*
   * return {*} entityMap
   * */
  onParseMeshChangeCreate(owner, entityMap, entityId) {
    if (owner.constructor.name === 'Mesh') {
      entityMap.mesh = owner.save()
      return entityMap
    }

    const elementType = owner.get('elementType')

    if (elementType === ElementType.PATH) {
      const mesh = owner.get('geometry').get('mesh')
      if (!entityMap.mesh) {
        entityMap.mesh = mesh.save()
      }
    }
    return entityMap
  }

  _setVertexIntoMesh(mesh, vertex) {
    // const newV = mesh.addVertex(vertex.pos, { data: vertex })
    const newV = Vertex.fromData(vertex)
    mesh.cellTable.set(vertex.id, newV)
  }

  onReceiveMeshChange(change) {
    const pathPrevious = this.dataStore.getElement(change.ownerId)
    // check the path element have been created
    if (pathPrevious) {
      if (change.payload.CREATE.size > 0) {
        // handle mesh first
        const mesh = pathPrevious.get('geometry').get('mesh')
        // add specialCases to vertices
        if (change.data.specialCases) {
          for (const specialCase of change.data.specialCases) {
            change.data.mesh.vertices.push(specialCase)
          }
        }
        Mesh.addFromData(mesh, change.data.mesh)

        // skip fake independent curve control
        if (change.payload.UPDATE.has('__FAKE_IND_CURVE_CONTROL__'))
          change.payload.UPDATE.delete('__FAKE_IND_CURVE_CONTROL__')

        const onlyUpdateAndDelete = new EntityChange({
          CREATE: [],
          UPDATE: [...change.payload.UPDATE],
          DELETE: [...change.payload.DELETE]
        })
        // FIXME: It's workaround for temporary. Should not do this.
        if (onlyUpdateAndDelete.isEmpty()) {
          onlyUpdateAndDelete.isEmpty = () => false
        }
        pathPrevious.data.geometry.watched.redo('MESH_CHANGES', onlyUpdateAndDelete)
      } else {
        pathPrevious.redo('MESH_CHANGES', new EntityChange(change.payload))
      }
    }
  }
}
