import React from 'react'
import { useAccessControl } from './AccessControlProvider'

type IfPermissionGrantedProps<Permission extends string> = {
  perform: Permission
  children: React.ReactNode
  data?: any
  authorizedComponent?: React.ComponentType
  unauthorizedComponent?: React.ComponentType
}
const IfPermissionGranted = <Permission extends string>({
  perform,
  children,
  data,
  authorizedComponent: AuthorizedComponent,
  unauthorizedComponent: UnauthorizedComponent
}: IfPermissionGrantedProps<Permission>) => {
  const { userHasPermission } = useAccessControl()

  if (userHasPermission(perform, data)) {
    return AuthorizedComponent ? <AuthorizedComponent /> : <React.Fragment>{children}</React.Fragment>
  }

  return UnauthorizedComponent ? <UnauthorizedComponent /> : null
}

export default IfPermissionGranted
