import React, { createContext, useCallback, useContext, useMemo, useState } from 'react'

type FullScreenLoadingContextProps = {
  isFullScreenLoading: boolean
  startFullScreenLoading: () => void
  stopFullScreenLoading: () => void
}

const defaultFullScreenLoadingContext: FullScreenLoadingContextProps = {
  isFullScreenLoading: false,
  startFullScreenLoading: () => {},
  stopFullScreenLoading: () => {}
}

export const FullScreenLoadingContext = createContext<FullScreenLoadingContextProps>(defaultFullScreenLoadingContext)

const FullScreenLoadingProvider = ({ children }: { children: React.ReactNode }) => {
  const [isFullScreenLoading, setIsFullScreenLoading] = useState(false)

  const startFullScreenLoading = useCallback(() => {
    setIsFullScreenLoading(true)
  }, [])

  const stopFullScreenLoading = useCallback(() => {
    setIsFullScreenLoading(false)
  }, [])

  const value = useMemo(
    () => ({
      isFullScreenLoading,
      startFullScreenLoading,
      stopFullScreenLoading
    }),
    [isFullScreenLoading, startFullScreenLoading, stopFullScreenLoading]
  )

  return <FullScreenLoadingContext.Provider value={value}>{children}</FullScreenLoadingContext.Provider>
}

function useFullScreenLoadingContext(): FullScreenLoadingContextProps {
  const context = useContext(FullScreenLoadingContext)
  if (context === undefined) {
    throw new Error('useFullScreenLoadingContext must be used within a FullScreenLoadingProvider')
  }
  return context
}

export { FullScreenLoadingProvider, useFullScreenLoadingContext }
