import React, { useCallback, useMemo } from 'react'
import RadioButton, { RadioButtonProps } from '.'

type option = {
  key?: string
} & Omit<RadioButtonProps, 'onChange'>

type RadioButtonGroupProps<T> = {
  disabled?: boolean
  options: (T & option)[]
  selectedValue: RadioButtonProps['value']
  onSelectionChange: RadioButtonProps['onChange']
  onSelectionFocus?: RadioButtonProps['onFocus']
  renderChild?: (props: T & Omit<RadioButtonProps, 'onChange'>) => React.ReactNode
  className?: string
  dataTestId?: string
}

function RadioButtonGroup<T>({
  disabled = false,
  options,
  selectedValue,
  onSelectionChange,
  onSelectionFocus,
  renderChild,
  className = '',
  dataTestId
}: RadioButtonGroupProps<T>) {
  const selectionIndex = useMemo(
    () => options.findIndex((option) => option.value === selectedValue),
    [options, selectedValue]
  )
  const moveSelection = useCallback(
    (step: -1 | 1) => {
      const currentIndex = selectionIndex ?? 0
      const targetIndex = (currentIndex + step + options.length) % options.length
      const targetValue = options[targetIndex].value
      onSelectionChange(String(targetValue))
    },
    [onSelectionChange, options, selectionIndex]
  )

  return (
    <div
      className={`relative rounded-md bg-light-overlay-10 grid grid-flow-col auto-cols-fr highlight-border-rounded-lg ${
        disabled && 'opacity-40 text-opacity-40'
      } ${className}`}
      data-test-id={dataTestId}
    >
      <div
        className="absolute h-full bg-light-overlay-10 rounded-md pointer-events-none transition-all ease-in-out duration-300"
        style={{
          transform: `translateX(${selectionIndex * 100}%)`,
          width: `calc(${100 / options.length}%)`,
          display: selectionIndex === -1 || undefined ? 'none' : 'block'
        }}
      />
      {options.map((option) => {
        return (
          <RadioButton
            {...option}
            key={option.key ?? option.name}
            checked={selectedValue === option.value}
            onChange={onSelectionChange}
            onFocus={onSelectionFocus}
            moveSelection={moveSelection}
            className={`group ${option.className || ''}`}
            disabled={disabled}
          >
            {renderChild ? renderChild(option) : null}
          </RadioButton>
        )
      })}
    </div>
  )
}

export default RadioButtonGroup
