import { ElementType, CapShape, JoinShape, TrimPathMode } from "@phase-software/types"
import { NOT_UNDO_NO_INTERACTION, parseSceneTreeChanges } from "@phase-software/data-utils"
import Stats from '@phase-software/data-utils/src/Stats'
import { Vector2, Rect2, Transform2D } from "../math"
import {
    initHitTestWithRoot,
    addNewNodeHitTest,
    deleteHitTest
} from "../actions/selection/index"
import { destroyDinoNode, initSceneNodeWithElement, updateBooleanParent } from '../utils/node'
import { decomposeWithOffset } from "../actions/handles/utils"
import { stopWatchElementChanges } from "../update-controller"
import { BezierShape, BooleanAction } from "../geometry/bezier-shape/BezierShape"
import { allocF32Arena, allocU8Arena, getPathBBox, getStrokeBBox , dino, getNodeDino } from "../dino"
import { PathData } from "../geometry/PathData"
import { rectangle } from "../geometry"
import { UpdateType, ShapeType, Tree, RootTree } from "./RenderItem"

/** @typedef {import("@phase-software/data-store").DataStore} DataStore */
/** @typedef {import("../math/Vector2").Vector2Like} Vector2Like */
/** @typedef {import("../math/Transform2D").Transform2D} Transform2D */
/** @typedef {import("../geometry/PathData").PathData} PathData */
/** @typedef {import("./RenderItem").RenderItem} RenderItem */
/** @typedef {import("./VisualServer").VisualServer} VisualServer */
/** @typedef {import('./RenderCommand').RenderCommand} RenderCommand */

/** @type {VisualServer} */
let VS = null
/** @type {SpatialCache} */
let SC = null

/**
 * @param {SceneNode} owner
 */
function SceneTreeTransform(owner) {
    this.active = false

    this.origin = new Vector2(0, 0)
    this.position = new Vector2(0, 0)
    this.rotation = 0
    this.scale = new Vector2(1, 1)
    this.skew = new Vector2(0, 0)

    /** @type {SceneNode} */
    this.owner = owner
}
SceneTreeTransform.prototype = {
    constructor: SceneTreeTransform,

    reset() {
        this.active = false

        this.origin.set(0, 0)
        this.position.set(0, 0)
        this.rotation = 0
        this.scale.set(1, 1)
        this.skew.set(0, 0)
    },

    getPosition() {
        if (!this.active) return new Vector2().copy(this.owner.item.transform.translate)
        return new Vector2().copy(this.position)
    },
    /**
     * @param {Vector2Like} vec
     */
    setPosition(vec) {
        this.active = true
        this.position.copy(vec)
        this.owner.item.update(UpdateType.TRANSFORM)
    },
    getRotation() {
        if (!this.active) return this.owner.item.transform.rotation
        return this.rotation
    },
    /**
     * @param {number} rot
     */
    setRotation(rot) {
        this.active = true
        this.rotation = rot
        this.owner.item.update(UpdateType.TRANSFORM)
    },
    getScale() {
        if (!this.active) return new Vector2().copy(this.owner.item.transform.scale)
        return new Vector2().copy(this.scale)
    },
    /**
     * @param {Vector2Like} vec
     */
    setScale(vec) {
        this.active = true
        this.scale.copy(vec)
        this.owner.item.update(UpdateType.TRANSFORM)
    },
    getSkew() {
        if (!this.active) return new Vector2().copy(this.owner.item.transform.skew)
        return new Vector2().copy(this.skew)
    },
    /**
     * @param {Vector2Like} vec
     */
    setSkew(vec) {
        this.active = true
        this.skew.copy(vec)
        this.owner.item.update(UpdateType.TRANSFORM)
    },
    getOrigin() {
        if (!this.active) return new Vector2().copy(this.owner.item.transform.referencePoint).add(this.owner.item.transform.contentAnchor)
        return new Vector2().copy(this.origin)
    },
    /**
     * TODO: add both pixel/percent type support
     * @param {Vector2Like} vec
     */
    setOrigin(vec) {
        this.active = true
        this.origin.copy(vec)
        this.owner.item.update(UpdateType.TRANSFORM)
    },
}

export function SceneNode() {
    /**
     * ID of RenderItem instance
     * @type {string}
     */
    this.id = null

    /**
     * @type {RenderItem}
     */
    this.item = null

    /** @type {Rect2} */
    this.boundsLocal = new Rect2()
    /** @type {Rect2} */
    this.boundsLocalVisualZero = new Rect2()
    /** @type {Rect2} */
    this.boundsVisualLocal = new Rect2()
    /** @type {Rect2} */
    this.boundsVisualWorld = new Rect2()
    /** @type {Rect2} */
    this.boundsLocalAABB = new Rect2()
    /** @type {Rect2} */
    this.boundsWorldAABB = new Rect2()
    /** @type {Rect2} */
    this.boundsWorldVisualZeroAABB = new Rect2()
    /** @type {Rect2} */
    this.subtreeBounds = new Rect2()
    /** @type {number} */
    this.subtreeBoundsVersion = 0

    /**
     * index within parent's children, -1 means no parent
     * @type {number}
     */
    this.pos = -1
    /** @type {SceneNode} */
    this.parent = null
    /** @type {SceneNode[]} */
    this.children = []
    /** @type {SceneNode} */
    this.mask = null

    /** @type {number} */
    this.version = 0

    /** @type {number} */
    this.geometryVersion = 0

    /** @type {SceneTreeTransform} */
    this.transform = new SceneTreeTransform(this)
}
SceneNode.prototype = {
    constructor: SceneNode,

    reset() {
        this.id = null

        this.item = null

        this.boundsLocal.set(0, 0, 0, 0)
        this.boundsLocalVisualZero.set(0, 0, 0, 0)
        this.boundsVisualLocal.set(0, 0, 0, 0)
        this.boundsVisualWorld.set(0, 0, 0, 0)
        this.boundsLocalAABB.set(0, 0, 0, 0)
        this.boundsWorldAABB.set(0, 0, 0, 0)
        this.boundsWorldVisualZeroAABB.set(0, 0, 0, 0)
        this.subtreeBounds.set(0, 0, 0, 0)
        this.subtreeBoundsVersion = 0

        this.pos = -1
        this.parent = null
        this.children.length = 0
        this.mask = null

        this.version = 0

        this.geometryVersion = 0

        this.transform.reset()
    },

    /* transform */

    hasCustomTransform() {
        return this.transform.active
    },
    applyCustomTransform() {
        const t = this.item.transform
        const { position, origin, rotation, scale, skew } = this.transform
        const a = Math.cos(rotation + skew.y) * scale.x
        const b = Math.sin(rotation + skew.y) * scale.x
        const c = -Math.sin(rotation - skew.x) * scale.y
        const d = Math.cos(rotation - skew.x) * scale.y
        t.setTranslateX(position.x - (origin.x * a + origin.y * c))
        t.setTranslateY(position.y - (origin.x * b + origin.y * d))
        t.setRotation(rotation)
        t.setScale(scale)
        t.setSkew(skew)
    },

    /* hierarchy */

    getFirstChild() {
        return this.children.length > 0 ? this.children[0] : null
    },
    getLastChild() {
        return this.children.length > 0 ? this.children[this.children.length - 1] : null
    },
    getNextSibling() {
        if (!this.parent || this.pos < 0) return null
        return this.parent.getChild(this.pos + 1)
    },
    getPrevSibling() {
        if (!this.parent || this.pos < 0) return null
        return this.parent.getChild(this.pos - 1)
    },

    /**
     * @param {SceneNode} item
     */
    addChild(item) {
        item.pos = this.children.length
        this.children.push(item)
        item.parent = this
        updateItemDepth(item, this.item.depth + 1)
    },
    /**
     * @param {SceneNode} item
     * @param {number} pos
     */
    addChildAt(item, pos) {
        if (pos < 0 || pos > this.children.length) return
        this.children.length += 1
        for (let i = this.children.length - 1; i > pos; i--) {
            this.children[i] = this.children[i - 1]
            this.children[i].pos = i
        }
        this.children[pos] = item
        item.pos = pos
        item.parent = this
        updateItemDepth(item, this.item.depth + 1)
    },
    /**
     * @param {SceneNode} item
     * @param {number} pos
     */
    insertChildAt(item, pos) {
        if (this.children[pos]) {
            for (let i = this.children.length; i > pos; i--) {
                this.children[i] = this.children[i - 1]
                this.children[i].pos = i
            }
        }
        this.children[pos] = item
        item.pos = pos
        item.parent = this
        updateItemDepth(item, this.item.depth + 1)
    },
    /**
     * @param {SceneNode} item
     */
    removeChild(item) {
        const index = this.children.indexOf(item)
        if (index < 0) return
        this.removeChildAt(index)
    },
    /**
     * @param {number} index
     */
    removeChildAt(index) {
        if (index < 0 || index >= this.children.length) return
        const item = this.children[index]
        item.parent = null
        item.pos = -1
        const len = this.children.length - 1
        for (let i = index; i < len; i++) {
            const next = this.children[i + 1]
            next.pos = i
            this.children[i] = next
        }
        this.children.length = len
    },
    /**
     * @param {number} index
     * @returns {SceneNode}
     */
    getChild(index) {
        if (index < 0 || index >= this.children.length) return null
        return this.children[index]
    },
    hasChildren() {
        return this.children.length > 0
    },

    deleteTree() {
        if (this.parent) {
            this.parent.removeChild(this)
        }
        deleteRecursive(this)
    },

    deleteChildren() {
        for (let i = 0, children = this.children, len = children.length; i < len; i++) {
            deleteRecursive(children[i])
        }
        this.children.length = 0
    },
    reverseChildren() {
        this.children = this.children.slice().reverse()
        for (let i = 0, len = this.children.length; i < len; i++) {
            this.children[i].pos = i
        }
    },

    /* geometry */

    /**
     * @param {PathData} pathData
     * @param {[number, number]} rect
     */
    setGeometry(pathData, rect = null) {
        const item = this.item
        // skip if already freed
        if (item.freed || !item.base.vector) {
            return
        }
        item.base.shape = BezierShape.createFromPathData(pathData)
        // TODO: add flag to control whether we want to do reduce/simplify
        // item.base.shape = item.base.shape.reduce()
        item.base.shape.version = -1
        item.base.vector.rect = rect
        item.base.setShape(0, ShapeType.BASE, item.base.shape)
        item.update(UpdateType.GEOMETRY)
        let parent = this.parent
        while (parent) {
            parent = parent.parent
        }
    },

    /* calculation */

    calcBounds(updateChildren = false) {
        const node = this.item

        const { dinoNode } = getNodeDino(node)

        if (dinoNode.base_path_id === 0) {
            this.boundsLocal.set(0, 0, 0, 0)
            this.boundsLocalVisualZero.set(0, 0, 0, 0)
            this.boundsLocalAABB.set(0, 0, 0, 0)
            this.boundsWorldAABB.set(0, 0, 0, 0)
            this.boundsWorldVisualZeroAABB.set(0, 0, 0, 0)
        } else {
            const bbox = getPathBBox(dinoNode.base_path_id)
            this.boundsLocal.set(bbox.x, bbox.y, bbox.w, bbox.h)

            // boundsLocalVisualZero
            // We need visually zero experence if it is a tiny size node. these kind of "VisualZero" bounds mainly used for Canvas UI / Sanpping / HitTest for now
            let bounds = normalizedBoundsData(node, this.boundsLocal.clone())
            this.boundsLocalVisualZero.copy(bounds)

            bounds = node.transform.local.xform_rect(this.boundsLocal)
            this.boundsLocalAABB.set(bounds.x, bounds.y, bounds.width, bounds.height)
            bounds = node.transform.world.xform_rect(this.boundsLocal)
            this.boundsWorldAABB.set(bounds.x, bounds.y, bounds.width, bounds.height)

            // boundsWorldVisualZeroAABB
            // We need visually zero experence if it is a tiny size node. these kind of "VisualZero" bounds mainly used for Canvas UI / Sanpping / HitTest for now
            bounds = node.transform.world.xform_rect(this.boundsLocalVisualZero)
            this.boundsWorldVisualZeroAABB.set(bounds.x, bounds.y, bounds.width, bounds.height)

            node.transform.world.xform_rect(this.boundsVisualLocal, this.boundsVisualWorld)
        }

        node.bounds.rect.copy(this.boundsLocal)
        node.bounds.visual.copy(this.boundsVisualLocal)

        if (updateChildren) {
            for (let i = 0, children = this.children, len = children.length; i < len; i++) {
                children[i].calcBounds(true)
            }
        }
    },
    /**
     * @param {boolean} skipClippedArea if content clipped by parent should be ignored?
     * @returns {Rect2}
     */
    calcSubtreeBounds(skipClippedArea = false) {
        const currentVersion = VS.currentVersion()
        if (currentVersion && this.subtreeBoundsVersion === currentVersion) {
            return this.subtreeBounds
        }
        this.subtreeBounds.copy(this.boundsVisualWorld)

        if (!this.item.clipping || !skipClippedArea) {
            for (let i = 0, children = this.children, len = children.length; i < len; i++) {
                const bounds = children[i].calcSubtreeBounds(skipClippedArea)
                if (!bounds.is_zero()) {
                    if (this.subtreeBounds.is_zero()) {
                        this.subtreeBounds.copy(bounds)
                    } else {
                        this.subtreeBounds.merge_with(bounds)
                    }
                }
            }
        }

        // TODO: check `bounds` use cases and see if we need to add extra calculation somewhere
        const node = this.item
        node.bounds.local.copy(this.boundsLocalVisualZero)
        node.bounds.world.copy(this.boundsWorldVisualZeroAABB)
        node.bounds.rect.copy(node.bounds.local)
        VS.selection.markDirty(node.id)

        if (currentVersion) {
            this.subtreeBoundsVersion = currentVersion
        }

        return this.subtreeBounds
    },
}

export class SpatialCache {
    /**
     * @param {VisualServer} vs
     */
    constructor(vs) {
        if (!VS) VS = vs
        if (!SC) SC = this

        /** @type {SceneNode} */
        this.root = null
        /** @type {Map<string, SceneNode>} `id` -> node */
        this.nodeMap = new Map()
        /** @type {Map<string, string[]>} `col.row.level` -> node.id[] */
        this.tileNodeMap = new Map()

        this.versions = {
            id: 0,
            children: [],
            bbox: null
        }
    }

    clear() {
        if (this.root) {
            deleteRecursive(this.root)
            this.root = null
        }

        this.nodeMap.clear()
        this.tileNodeMap.clear()
    }

    /**
     * @param {DataStore} ds
     */
    connectDataStore(ds) {
        ds.workspace.on('CHANGE-WATCH', () => {
            this.clear()
            this.loadFromDataStore(ds)
        })

        ds.workspace.on('SCENE_TREE_CHANGES', (changes) => {
            // TODO: replace this with manual change handling
            const { oldParents, newParents, removed, added, moved, reordered } = parseSceneTreeChanges(changes)
            for (const elemID of removed) {
                this.removeNode(elemID, oldParents.get(elemID), moved.size > 0)
            }

            for (const elemID of added) {
                // insert element
                const parentID = newParents.get(elemID)

                const item = this.createNodeFromDataStore(elemID)
                this.insertNode(item, parentID, ds.getElement(parentID).children.indexOf(ds.getElement(elemID)))

                // create a group (ghost) if added element itself is a Container
                const element = ds.getElement(elemID)
                if (
                    element.get('elementType') === ElementType.CONTAINER
                    ||
                    element.get('elementType') === ElementType.BOOLEAN_CONTAINER
                    ||
                    element.get('elementType') === ElementType.MASK_CONTAINER
                ) {
                    // insert its children if it's duplcating (moved size === 0)
                    // and in the undo to the deleting all children in the boolean/mask group element
                    // "!changes.UPDATE.has(elemID)" is not to add children repeatedly
                    // because the children will be added in the changes.UPDATE
                    if (moved.size === 0 && !changes.UPDATE.has(elemID)) {
                        /** @type {string} */
                        let elId
                        for (elId of ds.traverseSubtree(elemID, false, true)) {
                            const el = ds.getElement(elId)
                            const parent = el.get('parent')
                            const index = parent.children.indexOf(el)
                            this.insertNode(this.createNodeFromDataStore(elId), parent.get('id'), index)
                        }
                    }
                }

                this.updateTransformRecursively(item)
            }

            for (const elemID of moved) {
                const newParentID = newParents.get(elemID)
                const parentElem = ds.getElement(newParentID)
                const pos = parentElem.children.indexOf(ds.getElement(elemID))

                this.moveNode(elemID, newParentID, pos)

                const oldParentID = oldParents.get(elemID)

                const childNode = VS.getRenderItem(elemID)
                if (!childNode) {
                    console.warn(`[SpatialCache] cannot add item (id="${childNode.id}") to non-exist parent (id="${elemID}")`)
                    continue
                }
                childNode.transform._dirty = true
                childNode.baseTransform._dirty = true

                const newParentNode = VS.getRenderItem(newParentID)
                newParentNode.transform._dirty = true
                newParentNode.baseTransform._dirty = true

                if (oldParentID) {
                    const element = VS.dataStore.getById(elemID)

                    // update transform data inside DataStore.Library
                    this._updateBaseValues(element, childNode, newParentNode)

                    if (ds.isActionMode) {
                        /**
                         * @todo This is a hard code that all transition operations should not be in the renderer.
                         * However, it is not a right time to find a good place between updating base value and computed value without longterm QA support.
                         * The refactoring might need to remove the invoking of the base value calculation from the renderer
                         */
                        ds.transition.cacheSpecificElementBaseValue(elemID, 'motionPath')
                        // only update computed transform for the non-animated elements in action mode
                        this._updateComputedValues(element, childNode, newParentNode)
                    }
                }
            }

            for (const elemID of reordered) {
                const parentID = newParents.get(elemID)
                const pos = ds.getElement(parentID).children.indexOf(ds.getElement(elemID))
                this.reorderNode(elemID, pos)
            }
        })

        ds.on('LOAD-START', () => {
            this.clear()
        })
        ds.on('LOAD', () => {
            this.loadFromDataStore(ds)
        })

        // load only when have workspace
        if (ds.workspace.watched) {
            this.loadFromDataStore(ds)
        }
    }
    /**
     * @param {DataStore} ds
     */
    loadFromDataStore(ds) {
        // root
        const rootID = ds.workspace.watched.get("id")
        this.root = new SceneNode()
        this.root.item = VS.makeRenderItem(rootID)
        initSceneNodeWithElement(VS, this.root, ds.workspace.watched)
        {
            const api = dino()
            const { dinoNode } = getNodeDino(this.root.item)
            api.addNodeToRoot(dinoNode.id)
        }
        this.root.id = rootID
        this.root.pos = 0
        this.nodeMap.set(this.root.id, this.root)
        // add hit test2
        initHitTestWithRoot(this.root)

        // children
        /** @type {string} */
        let elId
        for (elId of ds.traverseSubtree(rootID, false, true)) {
            const el = ds.getElement(elId)
            const parent = el.get('parent')
            const index = parent.children.indexOf(el)
            this.insertNode(this.createNodeFromDataStore(elId), parent.get('id'), index)
        }
    }

    /**
     * @param {string} id
     * @returns {SceneNode}
     */
    makeNode(id) {
        const item = new SceneNode()
        item.id = id
        item.item = VS.makeRenderItem(id)
        this.nodeMap.set(id, item)
        return item
    }
    /**
     * @param {string} id
     * @returns {SceneNode}
     */
    getNode(id) {
        return this.nodeMap.get(id)
    }
    /**
     * @returns {SceneNode}
     */
    getScreenNode() {
        return this.root?.getFirstChild()
    }
    /**
     * @param {SceneNode} node
     * @param {string} parentID
     * @param {number} index
     * @returns {SceneNode}
     */
    insertNode(node, parentID, index) {
        const parent = this.nodeMap.get(parentID)
        if (!parent) {
            console.warn(`[SpatialCache] cannot add item (id="${node.id}") to non-exist parent (id="${parentID}")`)
            return
        }
        // NOTE: should guarantee lower index are all filled with node
        parent.insertChildAt(node, index)
        if (parent.item.isComputedGroup()) {
            parent.item.update(UpdateType.GEOMETRY)
        }

        // add hit test2
        addNewNodeHitTest(node, parentID)
        node.item.update(UpdateType.GEOMETRY | UpdateType.TRANSFORM | UpdateType.STYLE)
        // remove all trim paths which effected by parent's trim data
        node.item.base.mods.length = 3

        {

            const api = dino()
            const { dinoNode: pDinoNode } = getNodeDino(parent.item)
            const { dinoNode: dinoNode } = getNodeDino(node.item)
            // If is mask group, need to handle last child related cases
            if (parent.item.isMaskGroup()) {
                const isFirstChild = parent.children.length === 1
                if (isFirstChild) {
                    pDinoNode.children.compose[1] = new Tree()
                    pDinoNode.children.compose[1].id = dinoNode.id
                    const maskType = 2
                    pDinoNode.children.compose[1].comp_id = api.makeCompose(maskType, pDinoNode.children.compose[1].id)
                    api.setNodeCompose(pDinoNode.children.id, 1, pDinoNode.children.compose[1].comp_id)
                } else {
                    const isInsertAsLastChild = index === parent.children.length - 1
                    if (isInsertAsLastChild) {
                        api.setComposeRoot(pDinoNode.children.compose[1].comp_id, dinoNode.id)
                        api.insertNodeChild(pDinoNode.children.id, pDinoNode.children.compose[1].id, index - 1)
                        pDinoNode.children.compose[1].id = dinoNode.id
                        api.markNodeChanged(pDinoNode.children.id)
                    } else {
                        api.insertNodeChild(pDinoNode.children.id, dinoNode.id, index)
                    }
                }
            } else {
                api.insertNodeChild(pDinoNode.children.id, dinoNode.id, index)
            }
        }

        return node
    }
    /**
     * @param {string} id
     * @returns {SceneNode}
     */
    createNodeFromDataStore(id) {
        const node = this.makeNode(id)
        const element = VS.dataStore.getElement(id)
        initSceneNodeWithElement(VS, node, element)

        parent.version = VS.currentVersion()
        node.version = parent.version
        return node
    }

    /**
     * @param {string} id
     * @param {string} parentID
     * @param {bool} ignoreChildren
     */
    removeNode(id, parentID, ignoreChildren = false) {
        const parent = this.nodeMap.get(parentID)
        if (!parent) {
            console.warn(`[SpatialCache] cannot remove item (id="${id}") from non-exist parent (id="${parentID}")`)
            return
        }
        const item = this.nodeMap.get(id)
        if (!item) {
            console.warn(`[SpatialCache] cannot remove non-exist item (id="${id}")`)
            return
        }
        // check if any parent is boolean group element, then update the boolean parent
        updateBooleanParent(item)

        const isMaskElement = parent.item.isMaskGroup() && (parent.getLastChild().id === item.id)

        item.parent.removeChild(item)
        parent.version = VS.currentVersion()
        item.version = parent.version
        if (ignoreChildren) {
            deleteItem(item)
        } else {
            deleteRecursive(item)
        }

        if (isMaskElement) {
            // move last child to compose
            const { dinoNode: pDinoNode } = getNodeDino(parent.item)
            const { dinoNode: cDinoNode } = getNodeDino(lastChild.item)
            const lastChild = parent.getLastChild()
            lastChild.item.update(UpdateType.TRANSFORM | UpdateType.GEOMETRY)
            dino().removeNode(cDinoNode.id)
            dino().setComposeRoot(pDinoNode.children.compose[1].comp_id, cDinoNode.id)
            pDinoNode.children.compose[1].id = cDinoNode.id
            dino().markNodeChanged(pDinoNode.children.id)
        }

        if (parent.item.trim.enabled || parent.item.isComputedGroup()) {
            parent.item.update(UpdateType.GEOMETRY)
        }
    }
    /**
     * @param {string} id
     * @param {string} parentID
     * @param {number} index
     */
    moveNode(id, parentID, index) {
        const newParent = this.nodeMap.get(parentID)
        if (!newParent) {
            console.warn(`[SpatialCache] cannot find new parent (id="${parentID}")`)
            return
        }
        const node = this.nodeMap.get(id)
        if (!node) {
            console.warn(`[SpatialCache] cannot find node (id="${id}")`)
            return
        }
        const parent = node.parent
        parent.removeChild(node)

        parent.version = VS.currentVersion()
        parent.item.update(UpdateType.GEOMETRY)

        newParent.addChildAt(node, index)
        newParent.version = VS.currentVersion()
        if (newParent.item.isComputedGroup()) {
            newParent.item.update(UpdateType.GEOMETRY)
        }

        node.version = parent.version
        node.item.update(UpdateType.TRANSFORM | UpdateType.GEOMETRY)
        // remove all trim paths which effected by parent's trim data
        node.item.base.mods.length = 3

        {
            const api = dino()
            const { dinoNode: pDinoNode } = getNodeDino(newParent.item)
            const { dinoNode: dinoNode } = getNodeDino(node.item)
            if (newParent.item.isMaskGroup()) {
                api.removeNode(dinoNode.id)

                const isFirstChild = newParent.children.length === 1
                if (isFirstChild) {
                    pDinoNode.children.compose[1] = new Tree()
                    pDinoNode.children.compose[1].id = dinoNode.id
                    const clipType = 2
                    pDinoNode.children.compose[1].comp_id = api.makeCompose(clipType, pDinoNode.children.compose[1].id)
                    api.setNodeCompose(pDinoNode.children.id, 1, pDinoNode.children.compose[1].comp_id)
                } else {
                    const isMoveInAsLastChild = index === newParent.children.length - 1
                    if (isMoveInAsLastChild) {
                        api.setComposeRoot(pDinoNode.children.compose[1].comp_id, dinoNode.id)
                        api.insertNodeChild(pDinoNode.children.id, pDinoNode.children.compose[1].id, index - 1)
                        pDinoNode.children.compose[1].id = dinoNode.id
                        api.markNodeChanged(pDinoNode.children.id)
                    } else {
                        api.insertNodeChild(pDinoNode.children.id, dinoNode.id, index)
                    }
                }
            } else {
                api.insertNodeChild(pDinoNode.children.id, dinoNode.id, index)
            }
        }
    }
    /**
     * @param {string} id
     * @param {number} pos
     */
    reorderNode(id, pos) {
        const item = this.nodeMap.get(id)
        if (!item) {
            console.warn(`[SpatialCache] cannot find item (id="${id}")`)
            return
        }
        if (item.pos === pos) return
        item.parent.version = VS.currentVersion()
        item.parent.item.update(UpdateType.GEOMETRY)
        const siblings = item.parent.children
        const inc = (pos < item.pos) ? -1 : +1
        for (let i = item.pos; i !== pos; i += inc) {
            siblings[i] = siblings[i + inc]
            siblings[i].pos = i
            siblings[i].version = item.parent.version
        }
        siblings[pos] = item
        item.pos = pos
        item.version = item.parent.version
        item.item.update(UpdateType.TRANSFORM | UpdateType.GEOMETRY)

        {
            const api = dino()
            const { dinoNode: pDinoNode } = getNodeDino(parent.item)
            const { dinoNode: dinoNode } = getNodeDino(item.item)
            const parent = item.parent
            if (parent.item.isMaskGroup()) {
                const originalPositionIsLastChild = dinoNode.id === pDinoNode.children.compose[1].id
                if (originalPositionIsLastChild) {
                    // Add new last child to compose
                    const newLastChild = parent.getLastChild()
                    const { dinoNode: lcDinoNode } = getNodeDino(newLastChild.item)
                    newLastChild.item.update(UpdateType.TRANSFORM | UpdateType.GEOMETRY)
                    api.removeNode(lcDinoNode.id)
                    api.setComposeRoot(pDinoNode.children.compose[1].comp_id, lcDinoNode.id)
                    // Insert previous compose to children
                    api.insertNodeChild(pDinoNode.children.id, pDinoNode.children.compose[1].id, pos)

                    pDinoNode.children.compose[1].id = lcDinoNode.id
                    api.markNodeChanged(pDinoNode.id)
                }

                const newPositionIsLastChild = pos === parent.children.length - 1
                if (newPositionIsLastChild) {
                    // Add node to compose
                    api.removeNode(dinoNode.id)
                    api.setComposeRoot(pDinoNode.children.compose[1].comp_id, dinoNode.id)
                    // Insert previous compose to children
                    api.insertNodeChild(pDinoNode.children.id, pDinoNode.children.compose[1].id, pos)

                    pDinoNode.children.compose[1].id = dinoNode.id
                    api.markNodeChanged(pDinoNode.id)
                } else {
                    api.reorderNode(dinoNode.id, pos)
                }
            } else {
                api.reorderNode(dinoNode.id, pos)
            }
        }
    }

    /**
     * @param {SceneNode} item
     */
    updateTransformRecursively(item, isRoot = false, force = false) {
        const node = item.item
        // const parent = isRoot ? null : item.parent?.item
        const parent = item.parent?.item
        if (parent) {
            // transform
            if (parent.updateFlags & UpdateType.TRANSFORM || force) {
                node.updateFlags |= UpdateType.TRANSFORM
            }
            if (node.updateFlags & UpdateType.TRANSFORM || force) {
                if (item.hasCustomTransform()) {
                    item.applyCustomTransform()
                }
                node.transform.update()
                node.transform.updateWorld(parent.transform)
                node.bounds.updateLocal(node.transform.local)
                node.bounds.updateWorld(node.transform.world)
            }
            node.transform.parent
                .copy(parent.transform.world)
        } else {
            // transform
            node.transform.parent
                .identity()
            node.transform.world
                .copy(node.transform.local)
        }
        node.transform.worldInv
            .copy(node.transform.world)
            .affine_inverse()

        if (node.updateFlags & UpdateType.TRANSFORM || force) {
            node.baseTransform.update()
            if (parent) {
                node.baseTransform.updateWorld(parent.baseTransform)
            }

            {
                const api = dino()
                const { dinoNode } = getNodeDino(node)
                const isMaskElement = item.parent && item.parent.item.isMaskGroup() && item.parent.getLastChild().id === item.id
                if (isMaskElement) {
                    // Mask element is a compose using world transform
                    const { scale, skew, rotation, translation } = node.transform.world.decompose()
                    api.setNodeTransform(
                        dinoNode.id,
                        translation.x,
                        translation.y,
                        rotation,
                        scale.x,
                        scale.y,
                        skew.x,
                        skew.y
                    )
                    api.setNodeTransform(
                        dinoNode.fills.id,
                        0, 0, 0, 1, 1, 0, 0
                    )
                    api.setNodeTransform(
                        dinoNode.strokes.id,
                        0, 0, 0, 1, 1, 0, 0
                    )
                    api.setNodeTransform(
                        dinoNode.children.id,
                        0, 0, 0, 1, 1, 0, 0
                    )
                } else {
                    api.setNodeTransform(
                        dinoNode.id,
                        node.transform.translate.x,
                        node.transform.translate.y,
                        node.transform.rotation,
                        node.transform.scale.x,
                        node.transform.scale.y,
                        node.transform.skew.x,
                        node.transform.skew.y
                    )

                    const pivotOffset = node.transform.getPivotOffset()
                    api.setNodeTransform(
                        dinoNode.fills.id,
                        -pivotOffset.x, -pivotOffset.y,
                        0, 1, 1, 0, 0
                    )
                    api.setNodeTransform(
                        dinoNode.strokes.id,
                        -pivotOffset.x, -pivotOffset.y,
                        0, 1, 1, 0, 0
                    )
                    api.setNodeTransform(
                        dinoNode.children.id,
                        -pivotOffset.x, -pivotOffset.y,
                        0, 1, 1, 0, 0
                    )
                }

                if (dinoNode.children.compose[0] !== null) {
                    const { scale, skew, rotation, translation } = node.transform.world.decompose()
                    api.setNodeTransform(
                        dinoNode.children.compose[0].id,
                        translation.x,
                        translation.y,
                        rotation,
                        scale.x,
                        scale.y,
                        skew.x,
                        skew.y
                    )
                }
            }

            node.version = VS.currentVersion()
        }
        // continue process of children
        if (item.children.length > 0) {
            for (let i = 0, children = item.children, len = children.length; i < len; i++) {
                this.updateTransformRecursively(children[i], false, force)
            }
        }
    }

    /**
     * @param {RenderItem[]} nodeList
     */
    updateNodes(nodeList) {
        if (!this.root) return

        const screen = this.root.getFirstChild()

        // arrange by depth and convert to items
        let itemList = nodeList
            .map((node) => this.nodeMap.get(node.id))
            .filter(exist)
            .sort(sortItemByDepth)
        Stats.log("max depth of the scene tree", itemList.length >= 1 ? itemList[0].item.depth : "no scene node updates")

        Stats.begin("node update/transform")
        // 1. process all the items from top to bottom
        /** @type {Set<SceneNode>} */
        const updatedItemList = new Set()
        for (const item of itemList) {
            updatedItemList.add(item)

            // 1. transform
            Stats.beginSub("node update/transform")
            this.updateTransformRecursively(item)
            Stats.endSub("node update/transform")

            // 2. propagate trim effects to children
            if (item.item.trim.enabled && item.hasChildren()) {
                // FIXME: how to reset children's trim when container's trim is disabled/deleted
                propageteTrimRecursively(updatedItemList, item)
            }

            // 3. add parent if it has the trim data
            addParentWithTrimData(updatedItemList, item)
        }
        Stats.end("node update/transform")

        itemList = [...updatedItemList].sort(sortItemByDepth)
        for (let i = itemList.length - 1; i >= 0; i--) {
            let item = itemList[i]
            updatedItemList.add(item)

            /** @type {SceneNode} */
            let childItem = item
            let parent = item.parent
            while (parent && parent !== screen) {
                // add parent boolean groups
                if (parent.item.isBooleanGroup()) {
                    updatedItemList.add(parent)
                    parent.item.update(UpdateType.GEOMETRY)
                }
                // add parent mask groups
                if (parent.item.isMaskGroup() && childItem === parent.getLastChild()) {
                    updatedItemList.add(parent)
                    parent.item.update(UpdateType.GEOMETRY)
                }
                if (parent.item.isNormalGroup()) {
                    updatedItemList.add(parent)
                    parent.item.update(UpdateType.GEOMETRY)
                }
                childItem = item
                item = parent
                parent = parent.parent
            }
        }
        itemList = [...updatedItemList].sort(sortItemByDepth)

        // 2. process all the items from bottom to top
        for (let i = itemList.length - 1; i >= 0; i--) {
            const item = itemList[i]
            const node = item.item
            // self geometry
            if (node.updateFlags & UpdateType.GEOMETRY) {
                // base
                if (node.base.shape) {
                    node.base.shape.version = -1
                }
                if (node.base.shape) {
                    // apply trim for node itself
                    if (node.trim.enabled && !item.item.isContainerNormalGroup()) {
                        // check individually
                        // get the corner radius path data if have
                        const currentPath = node.base.getFinalShape(0, ShapeType.SELF_TRIM)
                        const path = currentPath.trimmed(
                            node.trim.begin, node.trim.end,
                            node.trim.offset,
                            node.trim.mode
                        )
                        node.base.setShape(0, ShapeType.SELF_TRIM, path)
                        path.version = -1
                    }
                }
            }
            // style changes
            if (node.updateFlags & UpdateType.STYLE) {
                // TODO: support inner shadow and drop shadows
            }

            node.version = VS.currentVersion()
            item.version = node.version
        }

        Stats.begin("node update/geometry modifier")
        Stats.begin("boolean")
        Stats.begin("trim")
        const effectedItems = []
        for (let i = itemList.length - 1; i >= 0; i--) {
            const item = itemList[i]
            updateGeometryModifier(item, effectedItems)
        }
        itemList = itemList.concat(effectedItems
            .filter((node) => {
                for (let i = 0; i < itemList.length; i++) {
                    if (node === itemList[i]) return false
                }
                return true
            })
            .sort(sortItemByDepth))
        Stats.end("node update/geometry modifier")
        Stats.end("boolean")
        Stats.end("trim")

        Stats.begin("node update/stroke")
        Stats.begin("node update/element hit test")
        // 5. build vector resource from path
        for (let i = itemList.length - 1; i >= 0; i--) {
            const item = itemList[i]
            const node = item.item

            // a. Update computed group's geometry
            // If it's normal group, merge all children's bounds for its shape
            if (node.isNormalGroup()) {
                let rect = null
                for (let i = 0; i < item.children.length; i++) {
                    const child = item.children[i]
                    if (!child.item.visible || !child.item.base.shape || child.item.base.shape.isEmpty()) continue
                    if (rect) {
                        rect.merge_with(child.boundsLocalAABB)
                    } else {
                        rect = child.boundsLocalAABB.clone()
                    }
                }
                if (rect) {
                    node.base.shape = BezierShape.createFromPathData(rectangle([rect.width, rect.height]))
                    node.base.shape = node.base.shape.transform(new Transform2D().translate(rect.x, rect.y))
                } else {
                    node.base.shape = BezierShape.create()
                }
                node.base.setShape(0, ShapeType.BASE, node.base.shape)
                node.base.vector.clear()
                node.base.modVector = null
                node.base.shape.version = -1
            }
            if (node.isMaskGroup() && item.hasChildren()) {
                // for mask group's hover outline, the outline should be the first children's vector
                const maskItem = item.getLastChild()
                const maskNode = maskItem.item

                if (maskNode.fillLayers.length) {
                    const maskNodeFillShape = maskNode.base.getFinalShape()
                    if (maskNodeFillShape) { // It cloud be miss in the imported legency files
                        node.base.shape = maskNodeFillShape.clone().transform(maskNode.transform.local)
                    }
                } else if (maskNode.strokes.length) {
                    node.base.shape = new BezierShape()
                    for (let j = 0; j < maskNode.strokes.length; j++) {
                        const strokeShape = maskNode.strokes[j].shape.clone().transform(maskNode.transform.local)
                        node.base.shape = node.base.shape.union(strokeShape)
                    }
                } else if (maskNode.isContainerNormalGroup()) {
                    const rect = maskItem.boundsLocalAABB.clone()
                    if (rect) {
                        node.base.shape = BezierShape.createFromPathData(rectangle([rect.width, rect.height]))
                        node.base.shape = node.base.shape.transform(new Transform2D().translate(rect.x, rect.y))
                    }
                }

                if (!node.base.shape) {
                    node.base.shape = new BezierShape()
                }

                node.base.setShape(0, ShapeType.BASE, node.base.shape)
                node.base.shape.version = -1
            }


            // b. Update geometry data
            // base shape
            const baseVector = node.base.vector
            const baseChanged = node.base.shape?.version < 0
            if (baseChanged) {
                node.base.shape.version = VS.currentVersion()
                node.changed = true
                node.isEmpty = false

                if (node.isBooleanGroup() || node.isMaskGroup()) {
                    node.base.vector.rect = null
                }

                const pathData = node.base.shape.toPathData()
                node.base.vector.path = pathData
                const cmd_ptr = allocU8Arena(pathData.commands)
                const vtr_ptr = allocF32Arena(pathData.vertices)
                if (cmd_ptr && vtr_ptr) {
                    if (baseVector.rect) {
                        // updateRectGeometry(baseVector, geo_id, 0)
                    } else {
                        // WASM().updateGeometry(geo_id, 0, cmd_ptr, pathData.commands.length, vtr_ptr, pathData.vertices.length)
                    }
                }
            }
            // mod shape
            const finalShape = node.base.getFinalShape()
            const modChanged = finalShape?.version < 0
            const finalVector = node.base.getFinalVector()
            if (baseChanged || modChanged) {
                finalShape.version = VS.currentVersion()
                node.changed = true

                const pathData = finalShape.toPathData()
                finalVector.path = pathData

                // if (finalVector.rect) {
                //     updateRectGeometry(finalVector, geo_id, 1)
                // } else {
                //     WASM().updateGeometry(geo_id, 1, cmd_ptr, pathData.commands.length, vtr_ptr, pathData.vertices.length)
                // }
            }

            // if the shape is empty, destroy the geometry
            if (!node.base.shape || node.base.shape.isEmpty()) {
                // WASM().destroyGeometry(geo_id)
                node.isEmpty = true
            }

            const api = dino()
            const { dinoNode, idx } = getNodeDino(node)

            // dino update
            {
                // Update base geometry
                if (baseChanged) {
                    const pathData = node.base.shape.toPathData()
                    if (dinoNode.base_path_id === 0) {
                        console.log(node.name)
                        dinoNode.base_path_id = api.makePath(pathData)
                    }
                    api.setPath(dinoNode.base_path_id, pathData)
                }

                // Update mod geometry
                if (baseChanged || modChanged || node.updateFlags & UpdateType.STYLE) {
                    console.log(idx)
                    updateDinoFinalPath(node, dinoNode)
                }

                // Destroy the compose if the node is not clipping anymore
                if (!node.clipping && dinoNode.children.compose[0] !== null) {
                    api.destroyNodeCompose(dinoNode.children.id, 0)
                    dinoNode.children.compose[0] = null

                    // swap the order of children with strokes back
                    api.reorderNode(dinoNode.children.id, 2)
                }

                // Generate Clipping composition if it hasn't been created yet
                if (node.clipping && dinoNode.children.compose[0] === null) {
                    // create subtree for clipping composition
                    dinoNode.children.compose[0] = new Tree()
                    dinoNode.children.compose[0].id = api.makeNode(api.GROUP)
                    dinoNode.children.compose[0].children[0] = new Tree()
                    dinoNode.children.compose[0].children[0].id = api.makeNode(api.PATH)
                    api.addNodeChild(dinoNode.children.compose[0].id, dinoNode.children.compose[0].children[0].id)
                    const paint_id = api.makeColor(0, 0, 0, 1)
                    api.setNodeFillPaint(dinoNode.children.compose[0].children[0].id, api.COLOR, paint_id)
                    dinoNode.children.compose[0].children[0].path_id = dinoNode.path_id
                    console.log('dinoNode.path_id', dinoNode.path_id)
                    api.setNodePath(dinoNode.children.compose[0].children[0].id, dinoNode.path_id)

                    const { scale, skew, rotation, translation } = node.transform.world.decompose()
                    api.setNodeTransform(
                        dinoNode.children.compose[0].id,
                        translation.x,
                        translation.y,
                        rotation,
                        scale.x,
                        scale.y,
                        skew.x,
                        skew.y
                    )

                    // create clipping composition for node's children root group
                    const maskType = 1
                    dinoNode.children.compose[0].comp_id = api.makeCompose(maskType, dinoNode.children.compose[0].id)
                    api.setNodeCompose(dinoNode.children.id, 0, dinoNode.children.compose[0].comp_id)

                    // swap the order of children with strokes
                    api.reorderNode(dinoNode.children.id, 1)
                }

                // Clip compose update
                if (dinoNode.children.compose[0] !== null) {
                    if (baseChanged || modChanged) {
                        api.setPath(dinoNode.children.compose[0].children[0].path_id, finalVector.path)
                        api.markNodeChanged(dinoNode.children.compose[0].children[0].id)
                    }
                }
            }

            // generate strokes, if the style changes the hittest needs to be updated
            if (node.updateFlags & UpdateType.GEOMETRY) {
                Stats.beginSub("node update/stroke")
                updateNodeStrokes(node)
                node.meshChanged = true
                Stats.endSub("node update/stroke")

                // It seems not be used anymore, can be removed after db confirm
                const bbox = getPathBBox(dinoNode.path_id)
                const bounds = new Rect2(bbox.x, bbox.y, bbox.w, bbox.h)
                finalVector.bounds.copy(bounds)

                for (let i=0; i<dinoNode.strokes.children.length; i++) {
                    const stroke = dinoNode.strokes.children[i]
                    const stroke_bbox = getStrokeBBox(stroke.path_id, stroke.stroke_data_id)
                    bounds.merge_with(new Rect2(stroke_bbox.x, stroke_bbox.y, stroke_bbox.w, stroke_bbox.h))
                }
                item.boundsVisualLocal.copy(bounds)
                node.bbox.copy(item.boundsVisualLocal)
            }

            // calculate item bounds
            item.calcBounds(!!(node.updateFlags & UpdateType.TRANSFORM))
            node.updateFlags = 0
        }
        Stats.end("node update/stroke")
        Stats.end("node update/element hit test")

        // 6. update subtree bounds for query calculation
        this.root.calcSubtreeBounds()
    }

    /**
     * @param {Element} element
     * @param {RenderItem} node
     * @param {RenderItem} newParentNode
     */
    _updateBaseValues(element, node, newParentNode) {
        const parentTransform = newParentNode.baseTransform
        const childTransform = node.baseTransform

        // if parent world inverse is invalid, skip
        if (parentTransform.world.basis_determinant() === 0) {
            return
        }

        const newTransform = parentTransform.worldInv.clone()
        newTransform.append(childTransform.world)

        const offset = childTransform.getPivotOffset()
        const { rotation, scale, skew, position } = decomposeWithOffset(newTransform, offset)
        /** @todo We haven't support map position to translate on setBaseProps */
        position.add(offset)
        element.setBaseProps(UPDATED_TRANSFORM_PROPS, [
            _toTranslateData(position),
            _toScaleData(scale),
            _toSkewData(skew),
            _toRotationData(rotation)
        ], true)
    }

    /**
     * @param {Element} element
     * @param {RenderItem} node
     * @param {RenderItem} newParentNode
     */
    _updateComputedValues(element, node, newParentNode) {
        const parentTransform = newParentNode.transform
        const childTransform = node.transform

        // if parent world inverse is invalid, skip
        if (parentTransform.world.basis_determinant() === 0) {
            return
        }

        // update computed values
        const newTransform = parentTransform.worldInv.clone()
        newTransform.append(childTransform.world)

        const offset = childTransform.getPivotOffset()
        const { rotation, scale, skew, position } = decomposeWithOffset(newTransform, offset)
        const translate = position.clone().add(offset)

        element.sets({
            scale,
            skew,
            rotation,
            translateX: translate.x,
            translateY: translate.y
        }, NOT_UNDO_NO_INTERACTION)
    }
}

/**
 * @param {SceneNode} node
 */
const deleteRecursive = (node) => {
    for (let i = 0, children = node.children, len = children.length; i < len; i++) {
        deleteRecursive(children[i])
    }
    deleteItem(node)
}

/**
 * @param {SceneNode} item
 * @param {SceneNode[]} out
 */
const updateGeometryModifier = (item, out) => {
    const node = item.item
    if (node.isBooleanGroup()) {
        Stats.beginSub("boolean")
        calcBooleanResult(item)
        Stats.endSub("boolean")
        out.push(item)
    } else if (node.isContainerNormalGroup()) {
        /** @type {SceneNode[]} */
        const childrenItems = []
        for (let i = 0, children = item.children, len = children.length; i < len; i++) {
            updateGeometryModifier(children[i], childrenItems)
        }
        childrenItems.reverse()

        // if the trim data or node geometry updated then update path data
        if (node.trim.version < 0) {
            if (node.trim.enabled) {
                Stats.beginSub("trim")
                switch (item.item.trim.mode) {
                    case TrimPathMode.INDIVIDUALLY: {
                        const pathArray = BezierShape.trimMultiple(
                            item.item.trim.begin,
                            item.item.trim.end,
                            item.item.trim.offset,
                            childrenItems.map((child) => child.item.base.getFinalShape(item.item.depth - child.item.depth + 1, ShapeType.PARENT_TRIM))
                        )
                        for (let index = 0; index < childrenItems.length; index++) {
                            node.base.mods.length = 2 - item.item.depth + childrenItems[index].item.depth
                            const childPath = pathArray[index].clone()
                            childPath.version = -1
                            childrenItems[index].item.base.setShape(
                                item.item.depth - childrenItems[index].item.depth,
                                ShapeType.PARENT_TRIM,
                                childPath
                            )
                            childrenItems[index].item.updateFlags |= UpdateType.GEOMETRY
                        }
                        break
                    }
                    case TrimPathMode.SIMULTANEOUSLY:
                        for (let i = 0, len = childrenItems.length; i < len; i++) {
                            const child = childrenItems[i]
                            const path = child.item.base.getFinalShape(
                                item.item.depth - child.item.depth + 1,
                                ShapeType.PARENT_TRIM
                            ).trimmed(
                                item.item.trim.begin,
                                item.item.trim.end,
                                item.item.trim.offset,
                                item.item.trim.mode,
                            )
                            child.item.base.setShape(
                                item.item.depth - child.item.depth,
                                ShapeType.PARENT_TRIM,
                                path
                            )
                            child.item.updateFlags |= UpdateType.GEOMETRY
                        }
                        break
                    default:
                        break
                }
                Stats.endSub("trim")
            } else {
                for (let i = 0, len = childrenItems.length; i < len; i++) {
                    const child = childrenItems[i]
                    child.item.base.deleteShape(ShapeType.PARENT_TRIM, node.depth - child.item.depth)
                    child.item.updateFlags |= UpdateType.GEOMETRY
                    child.item.base.shape.version = -1
                }
                node.base.deleteShape(ShapeType.SELF_TRIM)
                node.base.mods.length = 2
                node.updateFlags |= UpdateType.GEOMETRY
                if (node.base.shape) {
                    node.base.shape.version = -1
                }
            }
            out.push(item)
            node.trim.version = VS.currentVersion()
        }
        for (let i = 0, len = childrenItems.length; i < len; i++) {
            out.push(childrenItems[i])
        }
    } else if (!(node.isMaskGroup())) { // skip mask
        // if trim data is changed
        if (node.trim.version < 0) {
            if (node.trim.enabled) {
                const path = node.base.getFinalShape(0, ShapeType.SELF_TRIM).trimmed(
                    node.trim.begin,
                    node.trim.end,
                    node.trim.offset,
                    node.trim.mode
                )
                path.version = -1
                node.base.setShape(0, ShapeType.SELF_TRIM, path)
            } else {
                node.base.deleteShape(ShapeType.SELF_TRIM)
                node.base.mods.length = 2
            }
            node.trim.version = VS.currentVersion()
            node.updateFlags |= UpdateType.GEOMETRY
        }
        out.push(item)
    }
}

/**
 * @param {SceneNode} node
 */
const deleteItem = (node) => {
    // remove self from ctx
    deleteHitTest(node.id)

    VS.destroyRenderItem(node.id)
    SC.nodeMap.delete(node.id)
    stopWatchElementChanges(node.id)

    const { dinoNode, idx } = getNodeDino(node.item)
    console.log('destroy node', node.item.name, idx)
    destroyDinoNode(dinoNode)
}

const UPDATED_TRANSFORM_PROPS = ['translate', 'scale', 'skew', 'rotation']
const _scaleBuf = { scaleX: 0, scaleY: 0 }
const _skewBuf = { skewX: 0, skewY: 0 }
const _rotationBuf = { rotation: 0 }
const _translateBuf = { translateX: 0, translateY: 0 }
/**
 * @param {Vector2} scale
 * @returns {{scaleX: number, scaleY: number}}
 */
function _toScaleData(scale) {
    _scaleBuf.scaleX = scale.x
    _scaleBuf.scaleY = scale.y
    return _scaleBuf
}
/**
 * @param {Vector2} skew
 * @returns {{skewX: number, skewY: number}}
 */
function _toSkewData(skew) {
    _skewBuf.skewX = skew.x
    _skewBuf.skewY = skew.y
    return _skewBuf
}
/**
 * @param {number} rotation
 * @returns {{rotation: number}}
 */
function _toRotationData(rotation) {
    _rotationBuf.rotation = rotation
    return _rotationBuf
}
/**
 * @param {Vector2} position
 * @returns {{translateX: number, translateY: number}}
 */
function _toTranslateData(position) {
    _translateBuf.translateX = position.x
    _translateBuf.translateY = position.y
    return _translateBuf
}

/**
 * @param {SceneNode} a
 * @param {SceneNode} b
 * @returns {number}
 */
const sortItemByDepth = (a, b) => (a.item.depth - b.item.depth)
const exist = (obj) => (!!obj)

/**
 * collect all elements base data to out array without considering their hierarchical relationships
 * @param {SceneNode} source
 * @param {BezierShape[]} out
 */
export function getSourceBases(source, out) {
    if (!source.item.visible) return

    /** @type {RenderItem} */
    const shape = source.item

    // if the source is a Container or mask group element, return the union path of its children and
    // skip container or mask group element itself to boolean calculation
    if (shape.isContainerNormalGroup() || shape.isMaskGroup()) {
        const children = source.children
        for (let i = 0, len = children.length; i < len; i++) {
            getSourceBases(children[i], out)
        }
    } else {
        let path = null
        if (!shape.fillLayers.length && shape.strokeLayers.length) {
            updateNodeStrokes(shape)
            path = shape.strokes[0].shape
        } else {
            path = shape.base.getFinalShape()
        }
        const base = path.clone().transform(shape.transform.world)
        out.push(base)
    }
}

/**
 * @param {SceneNode} tree
 */
function calcBooleanResult(tree) {
    // already updated?
    // if (tree.geometryVersion >= VS.currentVersion()) return
    /** @type {SceneNode[]} */
    const sources = tree.children
    /** @type {BezierShape[]} */
    const bases = []

    // retrieve all the elements in a tree structure without considering their hierarchical relationships
    for (let index = 0; index < sources.length; index++) {
        getSourceBases(sources[index], bases)
    }

    tree.geometryVersion = VS.currentVersion()

    const node = tree.item

    // calculate the boolean base with all bases
    /** @type {BezierShape | null} */
    let base = null
    for (let i = 0; i < bases.length; i++) {
        base = base ? base[BooleanAction[node.booleanType]](bases[i]) : bases[i]
    }

    // if the base is null then there is not valid path to do boolean calculation
    // this case could be the boolean children are all hidden but the container type
    // children, and container children are also hidden
    if (!base || bases.length === 0 || base.isEmpty()) {
        base = BezierShape.create()
    }
    base.transform(node.transform.world.clone().affine_inverse())
    node.base.shape = base
    node.base.shape.version = -1
    node.base.setShape(0, ShapeType.BASE, base.clone())
    node.base.vector.clear()
    node.base.modVector = null
    // apply trim for node itself
    if (node.trim.enabled) {
        const path = node.base.shape.trimmed(
            node.trim.begin, node.trim.end,
            node.trim.offset,
            // according to the spec, always use individual mode when trimming a boolean group
            TrimPathMode.INDIVIDUALLY
        )
        node.base.setShape(0, ShapeType.SELF_TRIM, path)
        path.version = -1
    } else {
        node.base.deleteShape(ShapeType.SELF_TRIM)
    }
    node.updateFlags |= UpdateType.GEOMETRY
}

/**
 * @param {Set<SceneNode>} updatedItemList
 * @param {SceneNode} item
 */
function propageteTrimRecursively(updatedItemList, item) {
    if (
        item.item.isBooleanGroup()   // trim applies to boolean group itself
        ||
        item.item.isMaskGroup()      // trim does not work on mask group at all
    ) return

    for (let i = 0, children = item.children, len = children.length; i < len; i++) {
        const child = children[i]
        updatedItemList.add(child)
        child.item.update(UpdateType.GEOMETRY)
        propageteTrimRecursively(updatedItemList, child)
    }
}

/**
 * @param {Set<SceneNode>} updatedItemList
 * @param {SceneNode} item
 */
function addParentWithTrimData(updatedItemList, item) {
    if (
        item.item.isBooleanGroup()   // trim applies to boolean group itself
        ||
        item.item.isMaskGroup()      // trim does not work on mask group at all
    ) {
        item.item.update(UpdateType.GEOMETRY)
        return
    }

    let parent = item.parent ? item.parent.parent : null
    while (parent) {
        if (parent.item.trim.enabled) {
            updatedItemList.add(parent)
            parent.item.update(UpdateType.GEOMETRY)
            if (item.item.trim.version < 0) {
                parent.item.trim.version = -1
            }
        }
        parent = parent.parent
    }
}

/**
 * @param {RenderItem} node
 */
function updateNodeStrokes(node) {
    for (let i = 0; i < node.strokes.length; i++) {
        const stroke = node.strokes[i]
        if (!stroke) {
            // WASM().destroyStroke(geo_id, i)
            break
        }

        let cap = 0
        switch (stroke.style.startCap) {
            case CapShape.NONE: {
                cap = 0
                break
            }
            case CapShape.ROUND: {
                cap = 1
                break
            }
            // TODO: replace this with SQUARE when it's added
            case CapShape.SQUARE_SOLID: {
                cap = 2
                break
            }
        }
        let join = 0
        switch (stroke.style.join) {
            case JoinShape.MITER: {
                join = 0
                break
            }
            case JoinShape.BEVEL: {
                join = 1
                break
            }
            case JoinShape.ROUND: {
                join = 2
                break
            }
        }
        {
            const { dinoNode } = getNodeDino(node)
            updateDinoFinalPath(node, dinoNode)

            const api = dino()
            if (dinoNode.strokes.children[i]) {
                if (dinoNode.strokes.children[i].stroke_data_id === 0) {
                    dinoNode.strokes.children[i].stroke_data_id = api.makeStroke(stroke.style.width, cap, join, stroke.style.miterLimit, stroke.style.dashPattern[0], stroke.style.dashPattern[1])
                    api.setNodeStrokeData(dinoNode.strokes.children[i].id, dinoNode.strokes.children[i].stroke_data_id)
                } else {
                    api.setStroke(dinoNode.strokes.children[i].stroke_data_id, stroke.style.width, cap, join, stroke.style.miterLimit, stroke.style.dashPattern[0], stroke.style.dashPattern[1])
                    api.markNodeChanged(dinoNode.strokes.children[i].id)
                }

                const commands = api.getStrokePathCmd(dinoNode.path_id, dinoNode.strokes.children[i].stroke_data_id)
                const vertices = api.getStrokePathVtx(dinoNode.path_id, dinoNode.strokes.children[i].stroke_data_id)
                stroke.shape = BezierShape.createFromPathData(new PathData(vertices, commands))
            }
        }
    }
}

export const data = {
    views: [],
    views_id: 0,
    views_curr_idx: 0,
    blobs_curr_idx: 0,
    blobs_ptr: null,
    views_ptr: null,
    blobs_u8Array: null,
    blobs_f64Array: null,
    blobs_f32Array: null,
    views_u32Array: null
}
const offset = new Vector2(0, 0)

/**
 * @param {RenderItem} node
 * @param {Rect2} bounds
 * @returns {Rect2}
 */
const normalizedBoundsData = (node, bounds) => {
    offset.set(0, 0)
    if (node.isComputedGroup()) {
        const element = VS.dataStore.getElement(node.id)
        const referencePoint = element.get('referencePoint')
        offset.set(referencePoint[0], referencePoint[1])
    }

    const sizeFlag = node.sizeFlag
    const realW = node.transform.size.x
    const realH = node.transform.size.y
    if ((sizeFlag.w === 'f0' && sizeFlag.h === 'f0') || (sizeFlag.w === 't0' && sizeFlag.h === 'f0') || (sizeFlag.w === 'f0' && sizeFlag.h === 't0')) {
        const x = realW * 0.5 - offset.x
        const y = realH * 0.5 - offset.y
        const width = 0
        const height = 0
        return new Rect2(x, y, width, height)
    }
    if (sizeFlag.w === 'f0') {
        const x = realW * 0.5 - offset.x
        const y = -offset.y
        const width = 0
        const height = realH
        return new Rect2(x, y, width, height)
    }
    if (sizeFlag.h === 'f0') {
        const x = -offset.x
        const y = realH * 0.5 - offset.y
        const width = realW
        const height = 0
        return new Rect2(x, y, width, height)
    }

    return bounds
}

/**
 * @param {SceneNode} node
 * @param {number} depth
 */
const updateItemDepth = (node, depth) => {
    node.item.depth = depth
    for (let i = 0, children = node.children, len = children.length; i < len; i++) {
        updateItemDepth(children[i], depth + 1)
    }
}

/**
 * @param {RenderItem} node
 * @param {RootTree} dinoNode
 */
const updateDinoFinalPath = (node, dinoNode) => {
    const api = dino()

    const pathData = node.base.getFinalShape().toPathData()
    // console.log('updateDinoFinalPath pathData', dinoNode, pathData)
    if (dinoNode.path_id === 0) {
        // console.log(node.name, 'makePath Base')
        dinoNode.path_id = api.makePath(pathData)
    }
    api.setPath(dinoNode.path_id, pathData)
    // set all fill layers to the same path
    for (let i = 0; i < dinoNode.fills.children.length; i++) {
        if (dinoNode.fills.children[i].path_id === 0) {
            dinoNode.fills.children[i].path_id = dinoNode.path_id
            api.setNodePath(dinoNode.fills.children[i].id, dinoNode.path_id)
        } else {
            api.markNodeChanged(dinoNode.fills.children[i].id)
        }
    }
    // set all stroke layers to the same path
    for (let i = 0; i < dinoNode.strokes.children.length; i++) {
        if (dinoNode.strokes.children[i].path_id === 0) {
            dinoNode.strokes.children[i].path_id = dinoNode.path_id
            api.setNodePath(dinoNode.strokes.children[i].id, dinoNode.path_id)
        } else {
            api.markNodeChanged(dinoNode.strokes.children[i].id)
        }
    }
}