import {
  Configuration,
  ProjectApi,
  ProjectCollaboratorApi,
  ProjectFileApi,
  ProjectFileCollaboratorApi,
  ProjectMemberApi,
  TeamApi,
  TeamUserApi,
  UserApi
} from '../generated/api'
import { DefaultApi as LiveblocksApi, Configuration as LiveblocksConfig } from '../generated/api/liveblocks'
import { DefaultApi as PresenceApi, Configuration as PresenceConfig } from '../generated/api/presence'
import { ProjectFileImageApi } from './ProjectFileImageApi'
import AuthMiddleware from './middlewares/AuthMiddleware'
import ErrorMiddleware from './middlewares/ErrorWrappingMiddleware'
import LiveblocksErrorMiddleware from './middlewares/LiveblocksErrorWrappingMiddleware'

export let projectCollaboratorService: ProjectCollaboratorApi
export let projectFileCollaboratorService: ProjectFileCollaboratorApi
export let projectFileService: ProjectFileApi
export let projectMemberService: ProjectMemberApi
export let projectService: ProjectApi
export let teamService: TeamApi
export let teamUserService: TeamUserApi
export let userService: UserApi
export let presenceService: PresenceApi
export let projectFileImageService: ProjectFileImageApi
export let LiveblocksService: LiveblocksApi

export const getClientKey = async () => {
  if (process.env.NODE_ENV !== 'production') {
    return process.env.REACT_APP_CLIENT_KEY
  }

  try {
    const res = await fetch('/web-config')
    const json = await res.json()
    const { clientKey } = json
    return clientKey
  } catch (e) {
    console.log(e)
  }
}

export const setApiBasePath = (basePath: string) => {
  const configuration = new Configuration({
    basePath,
    middleware: [new AuthMiddleware(), new ErrorMiddleware()]
  })
  projectCollaboratorService = new ProjectCollaboratorApi(configuration)
  projectFileCollaboratorService = new ProjectFileCollaboratorApi(configuration)
  projectFileService = new ProjectFileApi(configuration)
  projectMemberService = new ProjectMemberApi(configuration)
  projectService = new ProjectApi(configuration)
  teamService = new TeamApi(configuration)
  teamUserService = new TeamUserApi(configuration)
  userService = new UserApi(configuration)
  projectFileImageService = new ProjectFileImageApi(configuration)
  const presenceConf = new PresenceConfig({
    basePath,
    middleware: [new AuthMiddleware(), new ErrorMiddleware()]
  })
  presenceService = new PresenceApi(presenceConf)
  const liveblocksConf = new LiveblocksConfig({
    basePath,
    middleware: [new AuthMiddleware(), new LiveblocksErrorMiddleware()]
  })
  LiveblocksService = new LiveblocksApi(liveblocksConf)
}
