/**
 * Get offset left and top of the element to the page body
 * @param {DOM} element
 * @returns {object}
 */
export const getOffset = (element) => {
  if (!element) {
    return { top: 0, left: 0, width: 0, height: 0 }
  }

  const offset = {
    top: 0,
    left: 0,
    width: element.offsetWidth,
    height: element.offsetHeight
  }

  let target = element
  while (target) {
    offset.top += target.offsetTop
    offset.left += target.offsetLeft
    /* eslint no-param-reassign: 1 */
    target = target.offsetParent
  }

  return offset
}

/** @typedef {('center' | 'start' | 'end' | 'nearest')} Align */
/** @typedef {('top' | 'bottom')} ViewAnchor */
/**
 * Scroll element into view with specified alignment
 * @param {DOM} element
 * @param {Align} [align = 'center']
 * @param {ViewAnchor} [viewAnchor = 'top']
 * @param {boolean} [forceCenter=false]
 * @param {Element | null} scrollRef
 * @returns {object}
 */
export const scrollIntoView = (
  element,
  align = 'center',
  scrollRef = null,
  viewAnchor = 'top',
  forceCenter = false
) => {
  if (!element) {
    return
  }
  const parent = scrollRef || element.parentNode
  const isAbove = parent.scrollTop > element.offsetTop
  let isBelow = parent.offsetHeight + parent.scrollTop < element.offsetTop

  if (viewAnchor === 'bottom') {
    isBelow = parent.scrollTop + parent.offsetHeight < element.offsetTop + element.offsetHeight
  }

  const centerAlignment = () => {
    const absoluteTop = parent.scrollTop + element.getBoundingClientRect().top - parent.getBoundingClientRect().top
    const scrollTop = absoluteTop + element.offsetHeight / 2 - parent.offsetHeight / 2
    parent.scrollTop = scrollTop
  }

  if (forceCenter && !(isAbove || isBelow) && align === 'center') {
    centerAlignment()
  } else if (isAbove || isBelow) {
    switch (align) {
      case 'nearest':
        parent.scrollTop = isAbove ? element.offsetTop : element.offsetTop + element.offsetHeight - parent.offsetHeight
        break
      case 'start':
        parent.scrollTop = element.offsetTop
        break
      case 'end':
        parent.scrollTop = element.offsetTop + element.offsetHeight - parent.offsetHeight
        break
      case 'center':
        centerAlignment()
        break
    }
  }
}
