import React, {
  useCallback,
  forwardRef,
  createContext,
  useContext,
  FormEvent,
  KeyboardEvent,
  MouseEvent,
  CSSProperties,
  ReactNode
} from 'react'

type FocusNavigationDirection = 'forward' | 'back'

type FocusHandlerContextData = {
  switchFocus: (currentElement: HTMLElement, navigationDirection: FocusNavigationDirection) => void
}

type FocusLoopProps = {
  children: ReactNode
  className?: string
  undoable?: boolean
  dataTestId?: string
  onClick?: (e: MouseEvent<HTMLFormElement>) => void
  onMouseDown?: (e: MouseEvent<HTMLFormElement>) => void
  onMouseEnter?: (e: MouseEvent<HTMLFormElement>) => void
  onMouseLeave?: (e: MouseEvent<HTMLFormElement>) => void
  style?: CSSProperties
  tabIndex?: number
}

const FocusHandlerContext = createContext<FocusHandlerContextData>({ switchFocus: () => {} })
export const useFocusHandler = () => useContext(FocusHandlerContext)

const FocusLoop = forwardRef<HTMLFormElement, FocusLoopProps>(
  (
    { undoable, children, className, style, onClick, dataTestId, onMouseDown, onMouseEnter, onMouseLeave, ...args },
    ref
  ) => {
    const handleSwitchFocus = useCallback(
      (currentElement: HTMLElement, navigationDirection: FocusNavigationDirection) => {
        if (!isFormAssociatedElement(currentElement)) return

        const formElements = currentElement.form?.elements

        if (!formElements) {
          return
        }

        const focusableElements = Array.from(formElements as HTMLFormElement[]).filter(
          (element) => !element.disabled && (!element.tabIndex || element.tabIndex >= 0)
        )

        const currentElementIndex = focusableElements.indexOf(currentElement)
        const lastElementIndex = focusableElements.length - 1

        const isNavigatingBackwards = navigationDirection === 'back'
        const isAtStartOfForm = currentElementIndex === 0
        const isAtEndOfForm = currentElementIndex === lastElementIndex

        const nextElementIndex = isNavigatingBackwards
          ? isAtStartOfForm
            ? lastElementIndex
            : currentElementIndex - 1
          : isAtEndOfForm
          ? 0
          : currentElementIndex + 1

        focusableElements[nextElementIndex].focus()
      },
      []
    )

    const handleKeyDown = useCallback(
      (e: KeyboardEvent) => {
        if (!undoable) {
          if ((e.metaKey || e.ctrlKey) && e.key === 'z') {
            e.preventDefault()
          }
        }
        if (e.key === 'Tab') {
          e.stopPropagation()
          e.preventDefault()
          handleSwitchFocus(e.target as HTMLFormElement, e.shiftKey ? 'back' : 'forward')
        }
      },
      [handleSwitchFocus, undoable]
    )
    const handleSubmit = useCallback((e: FormEvent) => {
      e.preventDefault()
    }, [])

    return (
      // @ts-ignore
      <FocusHandlerContext.Provider value={{ switchFocus: handleSwitchFocus }}>
        <form
          className={className}
          data-test-id={dataTestId}
          onClick={onClick}
          onKeyDown={handleKeyDown}
          onMouseDown={onMouseDown}
          onMouseEnter={onMouseEnter}
          onSubmit={handleSubmit}
          onMouseLeave={onMouseLeave}
          ref={ref}
          style={style}
          {...args}
        >
          {children}
        </form>
      </FocusHandlerContext.Provider>
    )
  }
)

FocusLoop.displayName = 'FocusLoop'

export default React.memo(FocusLoop)

function isFormAssociatedElement(element: HTMLElement): element is HTMLFormElement {
  return 'form' in element && element.form instanceof HTMLFormElement
}
