import { Vector2, CMP_EPSILON } from '../math'
import { Command, PathData, PathDataBuilder } from './PathData'

/** @typedef {import('@phase-software/types').CapShape} CapShape */
/** @typedef {import('@phase-software/types').JoinShape} JoinShape */
/** @typedef {import('@phase-software/types').EndShape} EndShape */
/** @typedef {import('@phase-software/types').GrowDirection} GrowDirection */
/** @typedef {import('@phase-software/data-store/src/Element').Element} Element */
/** @typedef {import('bezier-js/src/bezier').Bezier} Bezier */
/** @typedef {import('./PathData').ForEachFn} ForEachFn */
/** @typedef {import('./PathData').Subpath} Subpath */
/** @typedef {import('./PathData').Part} Part */

/**
 * @typedef {object} StrokeOptions
 * @property {number} width
 * @property {number} offset
 * @property {JoinShape} joinType
 * @property {number} joinSize
 * @property {number} miter
 * @property {number[]} dashes
 * @property {number[]} gaps
 * @property {EndShape} ends
 * @property {CapShape} capType
 * @property {number} capSize
 * @property {GrowDirection} growDirection
 */

/**
 * @typedef {object} JoinOptions
 * @property {JoinShape} joinType
 * @property {number} joinSize
 * @property {number} miter
 */

/**
 * @typedef {object} EndOptions
 * @property {number} width
 * @property {EndShape} ends
 * @property {CapShape} capType
 * @property {number} capSize
 */

/**
 * Interleaves the contents of the given arrays
 * @private
 * @param {any[][]} arrays
 */
export function* interleave(...arrays) {
    const indicies = Array(arrays.length).fill(0)
    let i = 0
    while (true) {
        if (++indicies[i] === arrays[i].length) indicies[i] = 0
        yield arrays[i][indicies[i]]
        if (++i === arrays.length) i = 0
    }
}

/**
 * generates an aproximation of an arc in cubic bezier format
 *
 * the aproximation is most accurate for an arc with an angle of less than 90 degrees
 *
 * from https://stackoverflow.com/a/44829356
 *
 * @param {PathDataBuilder} builder
 * @param {Vector2} start
 * @param {Vector2} end
 * @param {Vector2} center
 */
function genArcCurve(builder, start, end, center) {
    const q1 = start.dot(start)
    const q2 = start.dot(end) + q1
    const q = Math.sqrt(2 * q1 * q2) - q2

    const k = (4 / 3) * q / start.cross(end)

    const x0c = center.x + start.x - k * start.y
    const y0c = center.y + start.y + k * start.x
    const x1c = center.x + end.x + k * end.y
    const y1c = center.y + end.y - k * end.x
    const x1 = center.x + end.x
    const y1 = center.y + end.y

    builder.cubic(x0c, y0c, x1c, y1c, x1, y1)
}

/**
 * @param {PathDataBuilder} builder
 * @param {Vector2} center
 * @param {Vector2} start
 * @param {number} angle
 * @param {boolean} [concave]
 * @param {boolean} [flip]
 */
export function genArcCurves(builder, center, start, angle, concave = false, flip = false) {
    builder.assure_vec(start)
    const a = concave ? Math.PI * 2 - angle : angle
    const nrOfArcs = Math.ceil(2 * a / Math.PI)
    const arcAngle = a / nrOfArcs
    const last = new Vector2()
    last.copy(start).sub(center)
    const mod = (flip ? -1 : 1) * (concave ? -1 : 1)
    for (let i = 0; i < nrOfArcs; i++) {
        const start = last
        const end = start.clone().rotate(arcAngle * mod)
        genArcCurve(builder, start, end, center)
        last.copy(end)
    }
}

/**
 * will the segments intersect if offset?
 *
 * @param {Vector2} p0
 * @param {Vector2} p1
 * @param {Vector2} p2
 * @param {number} offset
 * @returns {boolean}
 */
export function willSegmentsIntersect(p0, p1, p2, offset) {
    const v0 = p1.clone().sub(p0)
    const v1 = p1.clone().sub(p2)
    const a = v0.angle_to_ccw(v1)
    if (Math.abs(a - Math.PI) < CMP_EPSILON) return false
    return (a < Math.PI && offset < 0) || (a > Math.PI && offset > 0)
}

/** @typedef {import('@phase-software/data-utils/src/mesh/Mesh').Mesh} Mesh */
/** @typedef {import('@phase-software/data-utils/src/mesh/Edge').HalfEdge} HalfEdge */
/** @typedef {import('@phase-software/data-utils/src/mesh/Vertex').Vertex} Vertex */


/**
 * @param {PathData} path
 * @param {number | number[]} cornerRadius
 * @returns {PathData}
 */
export function applyCornerRadiusModifier(path, cornerRadius) {
    if (cornerRadius <= 0) {
        if (Object.keys(path.cornerRadiusOverrides).length === 0) return path
        let skip = true
        for (const value of Object.values(path.cornerRadiusOverrides)){
            if (value > 0){
                skip = false
                break
            }
        }
        if (skip) return path
    }
    const builder = new PathDataBuilder()

    // Remove path close commands to temporary trick the algorithm
    const originCommands = path.commands
    path.commands = originCommands.filter(c => c !== Command.Z)
    /*
    * TODO: need to support close command later, for now is safe since user cannot add 2 close points without
    * merging them (snapping always turned on). Potential problem will happen when there are 2 close first &
    * last point, it will always be detected as a closed path (by algorithm) even if it should be an open path.
    *
    * see PathData.getSubpathBoundaries for detail of iterating subpath
    */

    for (const subpath of path.iter()) {
        const cmdS = builder.path.commands.length
        const startIndex = builder.path.vertices.length

        if (subpath.first().isLast) {
            builder.copySubpath(subpath)
        } else {
            const isClosed = subpath.isClosed
            const getGenFn = subpath.hasCurves ? getPathGenFn : getSimplePathGenFn
            const gen = getGenFn(builder, subpath, cornerRadius)

            /** @type {ForEachFn} */
            const build = (curr, next, isFirstIter, isLastIter) => {
                if (isFirstIter) {
                    builder.start_vec(curr.p0)
                }

                gen(curr, next, isFirstIter, isLastIter)

                if (!isClosed && isLastIter) {
                    switch (next.cmd) {
                        case Command.L:
                            builder.line_vec(next.p1)
                            break
                        case Command.Q:
                        case Command.C: {
                            const s = next.bezier().project({ x: builder.lastX, y: builder.lastY }).t
                            const e = 1
                            if (s !== e) {
                                const res = next.bezier().split(s, e)
                                builder.bezier_assure_first_point(res)
                            }
                        }
                    }
                }
            }

            subpath.forEach(build)

            // If the path is closed, the position of the first vertex needs to be updated due to corner radius
            if (isClosed && builder.path.vertices.length >= 2) {
                builder.path.vertices[startIndex] = builder.lastX
                builder.path.vertices[startIndex + 1] = builder.lastY
                builder.firstX = builder.lastX
                builder.firstY = builder.lastY
            }
            builder.end(isClosed)
        }
        builder.path.attachMetadata(cmdS, 'isEnd0Cap', subpath.isEnd0Cap)
        builder.path.attachMetadata(cmdS, 'isEnd1Cap', subpath.isEnd1Cap)
    }

    builder.path.hasOpenOrNetworkSubaths = path.hasOpenOrNetworkSubaths

    path.commands = originCommands

    return builder.path
}

/**
 * @param {PathDataBuilder} builder
 * @param {Subpath} subpath
 * @param {number} cornerRadius
 * @returns {ForEachFn} gen function for subpaths that have no curves
 */
function getSimplePathGenFn(builder, subpath, cornerRadius) {
    const isClosed = subpath.isClosed

    /** @type {number[]} */
    const rData = []

    /** @type {number[]} */
    const segLengths = []
    /** @type {number[]} */
    const tangents = []

    /** @type {ForEachFn} */
    const build = (curr, next, _, isLastIter) => {
        segLengths.push(curr.p0.distance_to(curr.p1))
        const v0 = curr.p1.clone().sub(curr.p0)
        const v1 = curr.p1.clone().sub(next.p1)
        const a = v0.angle_to(v1)
        tangents.push(Math.tan(Math.abs(a) / 2))

        if (!isClosed && isLastIter) {
            segLengths.push(next.p0.distance_to(next.p1))
        }
    }

    subpath.forEach(build)

    if (!isClosed) {
        const r = segLengths[0] * tangents[0]
        rData.push(r)
    }

    let lastA = isClosed ? tangents[tangents.length - 1] : tangents[0]

    for (let i = isClosed ? 0 : 1; i < tangents.length; i++) {
        const a = tangents[i]
        const r = (segLengths[i] * lastA * a) / (lastA + a)
        rData.push(r)
        lastA = a
    }

    if (isClosed) {
        rData.push(rData[0])
    } else {
        const r = segLengths[segLengths.length - 1] * tangents[tangents.length - 1]
        rData.push(r)
    }

    // prevents floating point errors when generating
    for (let i = 0; i < rData.length; i++) {
        rData[i] -= CMP_EPSILON
    }

    const out = []
    for (let i = 0; i < rData.length - 1; i++) {
        out.push(Math.min(rData[i], rData[i + 1]))
    }

    return (curr, next) => {
        const cR = curr.cornerRadiusOverride1 === undefined ? cornerRadius : curr.cornerRadiusOverride1
        const r = Math.min(out.shift(), cR)
        if (r <= 0) {
            builder.assure_vec(curr.p1)
        } else {
            genCorner(builder, curr.p0, curr.p1, next.p1, r)
        }
    }
}

/**
 * @todo The comments below is guessing. I am not the original developer
 * @typedef {object} RData
 * @property {number} r - The radius of the corner
 * @property {number} [t] - The time of the bezier function
 * @property {Vector2} [p] - The position of current time of the look-up table
 * @property {Vector2} [i] - The intersections of tengent of the time of the bezier and truncated corner
 */

/**
 * @todo I don't think computing all of the LUT of the bezier is necessary, but I have not time to rewrite another
 * @param {number} x
 * @param {number} y
 * @param {Bezier} bezier
 * @param {number} t
 * @param {boolean} limit
 * @param {Vector2} p0
 * @param {Vector2} p1
 * @param {boolean} negateDir
 * @returns {RData|undefined}
 */
export function getRData(x, y, bezier, t, limit, p0, p1, negateDir) {
    const pos = new Vector2(x, y)
    const mult = limit ? 1 : 0.5
    const _d = bezier.derivative(t)
    const dir = new Vector2(_d.x, _d.y).normalize()
    if (negateDir) dir.negate()
    const inter = getRayToLineIntersection(pos, dir, p1, p0, mult)

    if (inter !== null) {
        const v0 = inter.clone().sub(pos)
        const v1 = inter.clone().sub(p0.clone().scale_with_center(mult, p1))
        const a = v0.angle_to(v1)
        const tan = Math.tan(Math.abs(a) / 2)
        const maxR = Math.min(v0.length(), v1.length()) * tan

        return {
            p: pos,
            i: inter,
            r: maxR,
            t
        }
    }
}

/**
 * @param {PathDataBuilder} builder
 * @param {Subpath} subpath
 * @param {number} cornerRadius
 * @returns {ForEachFn} gen function for subpaths
 */
function getPathGenFn(builder, subpath, cornerRadius) {
    const isClosed = subpath.isClosed

    /** @type {RData[]} */
    const rData = []

    /** @type {ForEachFn} */
    const build = (curr, next, isFirstIter, isLastIter) => {
        const cR = curr.cornerRadiusOverride1 === undefined ? cornerRadius : curr.cornerRadiusOverride1

        if (cR <= 0) {
            rData.push({ r: cR })
            return
        }

        if (curr.isCurve && !next.isCurve) {
            // TODO: cache LUT
            const b = curr.bezier()
            const data = b.getLUT(256)
                .map(p => {
                    const maxT = (isFirstIter && !isClosed) ? 0 : 0.5
                    if (p.t >= maxT) {
                        return getRData(p.x, p.y, b, p.t, isLastIter && !isClosed, next.p1, next.p0, false)
                    }
                })
                .filter(entry => !!entry)

            data.reverse()

            const best = data.find(entry => entry.r > cR)
            if (best) {
                rData.push(best)
            } else {
                data.sort((a, b) => b.r - a.r)
                rData.push(data[0])
            }
        }

        if (!curr.isCurve && next.isCurve) {
            // TODO: cache LUT
            const b = next.bezier()
            const data = b.getLUT(256)
                .map(p => {
                    const maxT = (isLastIter && !isClosed) ? 1 : 0.5
                    if (p.t <= maxT) {
                        return getRData(p.x, p.y, b, p.t, isFirstIter && !isClosed, curr.p0, curr.p1, true)
                    }
                })
                .filter(entry => !!entry)

            const best = data.find(entry => entry.r > cR)
            if (best) {
                rData.push(best)
            } else {
                data.sort((a, b) => b.r - a.r)
                rData.push(data[0])
            }
        }

        if (curr.isCurve && next.isCurve) {
            rData.push({ r: 0 })
        }

        if (!curr.isCurve && !next.isCurve) {
            const l0 = curr.p0.distance_to(curr.p1) * 0.5
            const l1 = next.p0.distance_to(next.p1) * 0.5
            const v0 = curr.p1.clone().sub(curr.p0)
            const v1 = curr.p1.clone().sub(next.p1)
            const a = v0.angle_to(v1)
            const tan = Math.tan(Math.abs(a) / 2)
            const maxR = Math.min(l0, l1) * tan
            const r = Math.min(cR, maxR)
            rData.push({ r })
        }
    }

    subpath.forEach(build)

    let lastT = (isClosed && rData[rData.length - 1].t) || 0
    return (curr, next) => {
        const data = rData.shift()

        if (curr.isCurve) {
            const s = lastT
            const e = data.t || 1
            if (s !== e) {
                const res = curr.bezier().split(s, e)
                builder.bezier_assure_first_point(res)
            }
        }

        lastT = data.t || 0

        if (data.r <= 0) {
            builder.assure_vec(curr.p1)
        } else if (!curr.isCurve && !next.isCurve) {
            genCorner(builder, curr.p0, curr.p1, next.p1, data.r)
        } else if (!curr.isCurve && next.isCurve) {
            genCorner(builder, curr.p0, data.i, data.p, data.r)
        } else if (curr.isCurve && !next.isCurve) {
            genCorner(builder, data.p, data.i, next.p1, data.r)
        }
    }
}

/**
 * @param {PathDataBuilder} builder
 * @param {Vector2} p0
 * @param {Vector2} p1
 * @param {Vector2} p2
 * @param {number} r
 */
function genCorner(builder, p0, p1, p2, r) {
    const d0 = p0.clone().sub(p1)
    const d1 = p2.clone().sub(p1)
    const _a = d0.angle_to(d1)
    const a = Math.abs(_a)
    const l = r / Math.tan(a / 2)
    const start = d0.clone().normalize().scale(l).add(p1)
    const center = p0.normal(p1).scale(r).scale(Math.sign(_a)).add(start)
    genArcCurves(builder, center, start, Math.PI - a, false, _a > 0)
}

/**
 * @param {Vector2} rayOrigin
 * @param {Vector2} rayDirection
 * @param {Vector2} point1
 * @param {Vector2} point2
 * @param {number} limit [0, 1]
 * @returns {Vector2|null} will return `point1` if the ray is parallel with the line
 */
function getRayToLineIntersection(rayOrigin, rayDirection, point1, point2, limit = 0.5) {
    const v2 = point2.clone().sub(point1)
    const v3 = new Vector2(-rayDirection.y, rayDirection.x)

    const dot = v2.dot(v3)

    if (Math.abs(dot) < CMP_EPSILON) {
        return point1.clone()
    }

    const v1 = rayOrigin.clone().sub(point1)
    const t1 = v2.cross(v1) / dot
    const t2 = v1.dot(v3) / dot

    if (t1 >= 0.0 && t2 <= limit) {
        return rayDirection.clone().scale(t1).add(rayOrigin)
    }

    return null
}


/**
 * @callback GetVertexPositionCallback
 * @param {Mesh} mesh
 * @param {string} id
 * @returns {number[]}
 */

/**
 * @param {Mesh} mesh
 * @param {Iterable<HalfEdge>} edges
 * @param {PathData} path
 * @param {GetVertexPositionCallback} getVertexPosition
 * @param {boolean} allowOpenPath
 * @param {boolean} skipCaps
 */
export function pushContour(mesh, edges, path, getVertexPosition, allowOpenPath = false, skipCaps = false) {
    const cmdS = path.commands.length
    let isLastPointANetworkPoint = false

    let first = null
    let prev = null
    for (const edge of edges) {
        if (first === null) {
            first = edge.v
            path.vertices.push(...getVertexPosition(mesh, first.id))
            path.commands.push(Command.M)
            const isNetworkPoint = edge.v.inHalves.size > 2
            if (isNetworkPoint||skipCaps) path.attachMetadata(cmdS, 'isEnd0Cap', false)
            const rV = isNetworkPoint ? 0 : edge.v.cornerRadius
            if (rV !== null) {
                path.cornerRadiusOverrides[path.vertices.length - 2] = rV
            }
        } else if (prev !== edge.v) {
            console.warn('previous end vertex is not the same as current start vertex!')
        }

        prev = edge.w
        if (edge.edge.isCurve) {
            if (prev === edge.edge.w){
                path.vertices.push(...getVertexPosition(mesh, edge.edge.cpV.id), ...getVertexPosition(mesh, edge.edge.cpW.id), ...getVertexPosition(mesh, prev.id))
            } else{
                path.vertices.push(...getVertexPosition(mesh, edge.edge.cpW.id), ...getVertexPosition(mesh, edge.edge.cpV.id), ...getVertexPosition(mesh, prev.id))
            }
            path.commands.push(Command.C)
        } else {
            path.vertices.push(...getVertexPosition(mesh, prev.id))
            path.commands.push(Command.L)
        }

        const isNetworkPoint = edge.w.inHalves.size > 2
        isLastPointANetworkPoint = isNetworkPoint
        path.hasOpenOrNetworkSubaths ||= isNetworkPoint
        const rW = isNetworkPoint ? 0 : edge.w.cornerRadius
        if (rW !== null) {
            path.cornerRadiusOverrides[path.vertices.length - 2] = rW
        }
    }

    if (isLastPointANetworkPoint || skipCaps) {
        path.attachMetadata(cmdS, 'isEnd1Cap', false)
    }

    const isOpenPath = prev !== first
    if (!isOpenPath) {
        path.commands.push(Command.Z)
    }
    path.hasOpenOrNetworkSubaths ||= isOpenPath
    if (isOpenPath && !allowOpenPath) {
        console.warn('first and last verticies should be the same!')
    }
}

/**
 * Returns an iterator for stroke drawable edge lists (longest paths)
 * @param {Mesh} mesh
 * @returns {PathData}
 */
export function meshToPathData(mesh) {
    /** @type {Set<HalfEdge[]>} */
    const pathSet = new Set()
    constructHalfEdgePathsFromEdgesInPlace(mesh, pathSet)
    const res = new PathData()
    /** @type {GetVertexPositionCallback} */
    const getVertexPosition = (mesh, id) => mesh.getVertPos(id)
    for (const path of pathSet) {
        pushContour(mesh, path, res,getVertexPosition, true)
    }
    return res
}

/**
 *
 * @param {Mesh} mesh
 * @param {Set<HalfEdge[]>} mutablePathSet
 */
export function constructHalfEdgePathsFromEdgesInPlace(mesh, mutablePathSet) {
    /** @type {Map<Vertex, { path: HalfEdge[], last: boolean }>} */
    const endsMap = new Map()

    for (const edge of mesh.getEdges()) {
        const v = edge.v
        const w = edge.w
        const refV = endsMap.get(v)
        const refW = endsMap.get(w)
        // both points are at paths ends
        if (refV && refW) {
            // both points are on the ends of the same paths - close the contour
            if (refV.path === refW.path) {
                // TODO: should we continue building the path even if it's closed ?
                if (refV.last) {
                    refV.path.push(edge.halves[0])
                } else {
                    refV.path.push(edge.halves[1])
                }
                endsMap.delete(v)
                endsMap.delete(w)
            }
            // edge points are at ends of different paths - combine paths
            else {
                let path
                if (refV.last && refW.last) {
                    path = refV.path
                    path.push(edge.halves[0])
                    pushReversed(refW.path, path)
                } else if (!refV.last && !refW.last) {
                    path = refV.path
                    path.reverse()
                    for (let i = 0; i < path.length; i++) {
                        path[i] = path[i].twin
                    }
                    path.push(edge.halves[0], ...refW.path)
                } else if (refV.last && !refW.last) {
                    path = refV.path
                    path.push(edge.halves[0], ...refW.path)
                } else {
                    path = refW.path
                    path.push(edge.halves[1], ...refV.path)
                }
                endsMap.delete(v)
                endsMap.delete(w)
                mutablePathSet.delete(path === refV.path ? refW.path : refV.path)
                endsMap.set(path[0].v, { path, last: false })
                endsMap.set(path[path.length - 1].w, { path, last: true })
            }
        }
        // start point is at some path end - append end point to that path
        else if (refV) {
            if (refV.last) {
                refV.path.push(edge.halves[0])
            } else {
                refV.path.unshift(edge.halves[1])
            }
            endsMap.delete(v)
            endsMap.set(w, { path: refV.path, last: refV.last })
        }
        // end point is at some path end - append start point to that path
        else if (refW) {
            if (refW.last) {
                refW.path.push(edge.halves[1])
            } else {
                refW.path.unshift(edge.halves[0])
            }
            endsMap.delete(w)
            endsMap.set(v, { path: refW.path, last: refW.last })
        }
        // edge is not connected to any of the paths ends - create new path
        else {
            const path = [edge.halves[0]]
            mutablePathSet.add(path)
            // last means last point in the path (end of array)
            endsMap.set(v, { path, last: false })
            endsMap.set(w, { path, last: true })
        }
    }
}

/**
 * Helper method to push content of the reversed HalfEdge list
 *  (with each HalfEdge being reversed too) into another list.
 * @param  {HalfEdge[]} fromList
 * @param  {HalfEdge[]} toList
 */
function pushReversed(fromList, toList) {
    for (let i = fromList.length - 1; i >= 0; i--) {
        toList.push(fromList[i].twin)
    }
}
