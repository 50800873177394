export const NO_COMMIT = { commit: false }

export const NO_FIRE = { fire: false }

export const NO_INTERACTION = { interaction: false }

export const NOT_UNDOABLE = { undoable: false }

export const NOT_UNDO_NO_INTERACTION = { ...NOT_UNDOABLE, ...NO_INTERACTION }

export const NO_FIRE_NO_COMMIT = { ...NO_FIRE, ...NO_COMMIT }

export const NO_COMMIT_NO_ORDER_UPDATE = { ...NO_COMMIT, updateOrder: false }
