import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { TFunction } from 'i18next'

import { LayerListKey, LayerListKeySet } from '@phase-software/data-store'
import { BooleanOperation, ContainerElementType, ElementType, GeometryType, Mode } from '@phase-software/types'

import { useInspectContext } from '../../providers/InspectProvider'
import { useModal, useSetModal } from '../../providers/ModalProvider'
import { useDataStore } from '../../providers/dataStore/DataStoreProvider'
import { useEditor } from '../../providers/dataStore/EditorProvider'
import { useElementSelection } from '../../providers/dataStore/ElementSelectionProvider'
import { useInteraction } from '../../providers/dataStore/InteractionProvider'
import { useUI } from '../../providers/dataStore/UIProvider'
import { formatAsPercentage } from '../../utils/formatter'
import { PropertyInspectionList } from '../Interaction/constant'
import { IconButton, ScrollView } from '../shared'
import { ModalsState } from '../shared/ContextMenu/types'
import AnimationPathInspection from './AnimationInspection/AnimationPathInspection'
import CommonInspection from './AnimationInspection/CommonInspection'
import LayerInspection from './AnimationInspection/LayerInspection'
import MotionPathInspection from './AnimationInspection/MotionPathInspection'
import OriginInspection from './AnimationInspection/OriginInspection'
import PositionInspection from './AnimationInspection/PositionInspection'
import DesignCopyButton from './Copy/DesignCopyButton'
import InspectConfigModal from './Modal/InspectConfigModal'
import BasePropertyInspection from './PropertyInspection/BasePropertyInspection'
import EffectInspection from './PropertyInspection/EffectInspection'
import FillsInspection from './PropertyInspection/FillsInspection'
import OpacityInspection from './PropertyInspection/OpacityInspection'
import OverflowInspection from './PropertyInspection/OverflowInspection'
import PathInspection from './PropertyInspection/PathInspection'
import StrokesInspection from './PropertyInspection/StrokesInspection'
import TransformInspection from './PropertyInspection/TransformInspection'
import { blendMap, titleKeyMap } from './constant'
import noAnimationIllustration from './no-animation.png'
import noSelectionIllustration from './no-selection.png'
import { commonFormatter, degreeFormatter, percentFormatter } from './utils'

const formatterMap = (t: TFunction) =>
  ({
    width: commonFormatter,
    height: commonFormatter,
    rotation: degreeFormatter,
    cornerRadius: commonFormatter,
    contentAnchor: commonFormatter,
    scaleX: percentFormatter,
    scaleY: percentFormatter,
    skewX: degreeFormatter,
    skewY: degreeFormatter,
    opacity: percentFormatter,
    'trimPath.start': formatAsPercentage,
    'trimPath.end': formatAsPercentage,
    'trimPath.offset': formatAsPercentage,
    pathMorphing: commonFormatter,
    blendMode: (value: number) => t(blendMap[value], { ns: 'file', keyPrefix: 'layer_blend_mode' })
  } as any)

const InspectPanel = () => {
  const { mode } = useUI()
  const { dataStore } = useDataStore()
  const selection = useElementSelection()
  const { t } = useTranslation('file', { keyPrefix: 'inspect' })

  const elementType = useEditor((o) => o.elementType)
  const containerType = useEditor((o) => o.containerType)
  const geometryType = useEditor((o) => o.geometryType)
  const {
    data: { trigger, cursorPosition }
  } = useModal((o: ModalsState) => o['InspectContextMenu-Main'])
  const { openModal } = useSetModal('InspectContextMenu-Main')
  const { setDuration } = useInspectContext()
  const elementId = selection[0]
  const isDesignMode = mode === Mode.DESIGN
  const isScreen = elementType === ElementType.SCREEN
  const isContainer = elementType === ElementType.CONTAINER
  const isPath = elementType === ElementType.PATH
  const isMaskGroup = useEditor((o) => o.isMask)
  const isBooleanGroup = useEditor((o) => o.booleanType !== BooleanOperation.NONE)
  const isNormalGroup =
    isContainer && containerType === ContainerElementType.NORMAL_GROUP && !isMaskGroup && !isBooleanGroup
  const isComputedGroup = isMaskGroup || isBooleanGroup || isNormalGroup
  const actionList = useInteraction('actionList') || []
  const action = useInteraction(actionList[0])
  const response = useInteraction(action?.responseList?.[0])
  const elementTrack = useInteraction(response?.elementTrackMap?.get(elementId))

  const directPropertyKeys = [] as any
  const nestedPropertyKeys = [] as any

  PropertyInspectionList.forEach((propertyKey) => {
    if (elementTrack?.propertyTrackMap?.has(propertyKey)) {
      if (LayerListKeySet.has(propertyKey as LayerListKey)) {
        const propertyTrackId = elementTrack?.propertyTrackMap?.get(propertyKey)
        dataStore.interaction.getChildList(propertyTrackId).forEach((childList: any) => {
          dataStore.interaction.getChildList(childList).forEach((child: any) => {
            const data = dataStore.interaction.getEntity(child)
            nestedPropertyKeys.push({ id: data.key, propKey: propertyKey })
          })
        })
      } else {
        directPropertyKeys.push(propertyKey)
      }
    }
  })

  const handleClick = () => {
    openModal({ trigger, cursorPosition })
  }

  const translateKey = (key: string) => {
    const keyParts = key.split('.')
    const isTrimPath = ['trim_path.start', 'trim_path.end', 'trim_path.offset'].includes(key)
    const isScaleOrSkew = ['scale', 'skew'].includes(keyParts[0]) && ['x', 'y'].includes(keyParts[1])

    if (isTrimPath) {
      return `${t('trim_path')} - ${t(keyParts[1])}`
    }
    if (isScaleOrSkew) {
      return `${t(keyParts[0])} ${keyParts[1].toUpperCase()}`
    }
    return t(key)
  }

  useEffect(() => {
    if (action.maxTime) {
      setDuration(action.maxTime)
    }
  }, [action, setDuration])

  const renderContent = () => {
    if (directPropertyKeys.length === 0 && nestedPropertyKeys.length === 0 && !isDesignMode) return renderEmptyState()
    return (
      <>
        {/* @ts-ignore adjust when ScrollView has been refactored */}
        <ScrollView noScrollbar>
          {isDesignMode ? (
            <div className="flex flex-col w-full h-full">
              <BasePropertyInspection />
              {(isScreen || !isComputedGroup) && <OverflowInspection />}
              {!isScreen && <TransformInspection />}
              {!isScreen && <OpacityInspection />}
              <FillsInspection />
              {!isScreen && <StrokesInspection />}
              {!isScreen && <EffectInspection />}
              {isPath && (geometryType === GeometryType.POLYGON || geometryType === GeometryType.LINE) && (
                <PathInspection />
              )}
            </div>
          ) : (
            <>
              <div className="flex flex-col w-full h-full">
                {directPropertyKeys.map((key: string) => {
                  if (key === 'position') return <PositionInspection elementId={elementId} />
                  if (key === 'contentAnchor') return <OriginInspection elementId={elementId} />
                  if (key === 'motionPath') return <MotionPathInspection key={key} elementId={elementId} />
                  if (key === 'pathMorphing') return <AnimationPathInspection elementId={elementId} />
                  return (
                    <CommonInspection
                      key={key}
                      elementId={elementId}
                      target={key}
                      title={translateKey(titleKeyMap[key])}
                      formatter={formatterMap(t)[key]}
                    />
                  )
                })}
                {nestedPropertyKeys.map((data: { id: string; propKey: string }) => {
                  return <LayerInspection key={data.id} elementId={elementId} target={data.id} propKey={data.propKey} />
                })}
              </div>
            </>
          )}
        </ScrollView>
      </>
    )
  }

  const renderEmptyState = () => {
    return (
      <div className="flex-grow flex flex-col justify-center items-center px-16">
        <img
          className="mb-32"
          src={isDesignMode ? noSelectionIllustration : noAnimationIllustration}
          width={216}
          height={120}
        />
        <div className="text-center text-light-overlay-40">
          {isDesignMode ? t('please_select') : t('no_animations')}
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex justify-between p-16 text-white font-medium">
        <div>{t('inspect')}</div>
        <div className="flex gap-8">
          {isDesignMode && <DesignCopyButton />}
          <IconButton icon="More" onClick={handleClick} />
        </div>
      </div>
      {elementId ? renderContent() : renderEmptyState()}
      <InspectConfigModal />
    </div>
  )
}
export default React.memo(InspectPanel)
