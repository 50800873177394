import { useCallback } from 'react'
import { TutorialStatus } from '@phase-software/types'
// import { IS } from '@phase-software/renderer'
import { useProfileActions } from './ProfileProvider'
import { createProvider } from './utils'
import tutorials from '../tutorials'
const defaultValue = { active: null, loading: null, status: {} }

const [Provider, useSelectState, useSetState, useStateSnapshot] = createProvider('Tutorial', defaultValue)

export const useTutorial = useSelectState
export const getTutorialSnapshot = useStateSnapshot

export const useSetTutorial = () => {
  const setState = useSetState()
  const { trackTutorialStatus } = useProfileActions()

  const loadStatus = useCallback(
    (status) => {
      if (!status) {
        console.error('Status is not defined')
        return
      }
      if (typeof status !== 'object') {
        console.error('Status should be an object')
        return
      }
      setState((s) => ({ ...s, status }))
    },
    [setState]
  )

  const showLoading = useCallback(() => {
    setState((s) => ({ ...s, loading: true }))
  }, [setState])

  const hideLoading = useCallback(() => {
    setState((s) => ({ ...s, loading: false }))
  }, [setState])

  const closeTutorial = useCallback(
    (finish) => {
      const state = getTutorialSnapshot()
      if (!state.active) {
        return
      }

      let status = finish ? TutorialStatus.COMPLETED : TutorialStatus.SKIPPED
      const hideCheck = state.active.hideCheck
      if (hideCheck) {
        status = state.active.previous
      }
      const newStatus = { ...state.status, [state.active.key]: { status, hideCheck } }
      trackTutorialStatus(newStatus)
      setState((s) => ({
        ...s,
        active: null,
        status: newStatus
      }))
      // IS.resume()
    },
    [setState, trackTutorialStatus]
  )

  const startTutorial = useCallback(
    (key, force) => {
      const state = getTutorialSnapshot()
      if (state.active) {
        return
      }
      const viewed = state.status ? !!state.status[key] : false
      if (!viewed || force) {
        // IS.pause()
        setState((s) => ({ ...s, active: tutorials[key] }))
      }
    },
    [setState]
  )

  const startVideoTutorial = useCallback(
    (key, hideCheck = false) => {
      const state = getTutorialSnapshot()
      if (state.active) {
        return
      }
      setState((s) => ({
        ...s,
        active: {
          key,
          status: TutorialStatus.IN_PROGRESS,
          previous: s?.status?.[key]?.status,
          hideCheck
        }
      }))
    },
    [setState]
  )

  const trackTutorialProgress = useCallback(() => {
    const state = getTutorialSnapshot()
    const newStatus = {
      ...state.status,
      [state.active.key]: { status: TutorialStatus.IN_PROGRESS }
    }
    trackTutorialStatus(newStatus)
    setState((s) => ({
      ...s,
      status: newStatus
    }))
  }, [setState, trackTutorialStatus])

  const markTutorialAsCompleted = useCallback(
    (key) => {
      const state = getTutorialSnapshot()
      const newStatus = {
        ...state.status,
        [key]: { status: TutorialStatus.COMPLETED }
      }
      trackTutorialStatus(newStatus)
      setState((s) => {
        return {
          ...s,
          status: newStatus
        }
      })
    },
    [setState, trackTutorialStatus]
  )

  return {
    loadStatus,
    startTutorial,
    startVideoTutorial,
    closeTutorial,
    showLoading,
    hideLoading,
    trackTutorialProgress,
    markTutorialAsCompleted
  }
}

export default Provider
