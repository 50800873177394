import { useCallback } from 'react'

import uuid from 'uuid'

import { createProvider } from './utils'

const defaultValue = {}
const genId = () => uuid.v4()

const [NotificationProvider, useSelectState, useSetState] = createProvider('Notification', defaultValue)

export type NotificationMessageType = {
  id?: string
  type?: string
  content: string | React.ReactNode
  action?: string
  callback?: () => void
  escape?: boolean
  duration?: number
}

export const useSetNotification = () => {
  const setState = useSetState()

  const addNotification = useCallback(
    ({ id = genId(), type = 'general', content, action, callback, escape, duration }: NotificationMessageType) => {
      // @ts-ignore TODO: setState
      setState((prevState: any) => ({
        ...prevState,
        [id]: { id, type, content, action, callback, escape, duration }
      }))
      return id
    },
    [setState]
  )

  const updateNotification = useCallback(
    (id: string, content: string) => {
      // @ts-ignore TODO: setState
      setState((prevState: any) => ({
        ...prevState,
        [id]: { ...prevState[id], content }
      }))
    },
    [setState]
  )

  const removeNotification = useCallback(
    (id: string) => {
      // @ts-ignore TODO: setState
      setState((state: any) => {
        const newState = { ...state }
        delete newState[id]
        return newState
      })
    },
    [setState]
  )

  const clearNotification = useCallback(() => {
    // @ts-ignore TODO: setState
    setState({})
  }, [setState])

  return { addNotification, updateNotification, removeNotification, clearNotification }
}

export const useNotification = useSelectState

export default NotificationProvider
