import React, { createContext, useCallback, useContext, useMemo, useState } from 'react'
import { useApolloClient } from '@apollo/client'

import useFileActions from '../hooks/useFileActions'
import { FileFieldsFragment, FileFieldsFragmentDoc } from '../generated/graphql'
import { PERMISSIONS, useAccessControl } from '../access-control'

type FilePermissionContextProps = {
  fetchPermissions: () => void
  refetchPermissions: () => void
  fileWithPermission: {
    canEdit: boolean
    canView: boolean
  } & FileFieldsFragment
  permissionsLoaded: boolean
  canEditFile: boolean
}

export type FilePermissionProviderProps = {
  children: React.ReactNode
  id: FileFieldsFragment['id']
  projectId: FileFieldsFragment['project_id']
}

export const FilePermissionContext = createContext<FilePermissionContextProps | undefined>(undefined)

const FilePermissionProvider = ({ id, projectId, children }: FilePermissionProviderProps) => {
  const client = useApolloClient()

  const { userHasPermission } = useAccessControl()
  const { getCurrentUserFilePermissions } = useFileActions()

  const [permissions, setPermissions] = useState({
    canEdit: false,
    canView: false
  })
  const [isFetching, setIsFetching] = useState(false)
  const [permissionsLoaded, setPermissionsLoaded] = useState(false)

  const file = client.readFragment({
    fragment: FileFieldsFragmentDoc,
    fragmentName: 'fileFields',
    id: `files:${id}`
  })

  const refetchPermissions = useCallback(() => {
    setIsFetching(true)
    getCurrentUserFilePermissions({ projectId, fileId: id })
      .then(({ edit, view }) => {
        setPermissions({ canEdit: edit, canView: view })
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setIsFetching(false)
        setPermissionsLoaded(true)
      })
  }, [getCurrentUserFilePermissions, id, projectId])

  const fetchPermissions = useCallback(() => {
    if (isFetching || permissionsLoaded) return
    refetchPermissions()
  }, [isFetching, permissionsLoaded, refetchPermissions])

  const fileWithPermission = useMemo(
    () => ({
      ...file,
      ...permissions
    }),
    [file, permissions]
  )

  const canEditFile = userHasPermission(PERMISSIONS.EDIT_FILE, fileWithPermission)

  const value = useMemo(
    () => ({ fileWithPermission, fetchPermissions, refetchPermissions, permissionsLoaded, canEditFile }),
    [canEditFile, fetchPermissions, fileWithPermission, permissionsLoaded, refetchPermissions]
  )

  return <FilePermissionContext.Provider value={value}>{children}</FilePermissionContext.Provider>
}

function useFilePermissionContext(): FilePermissionContextProps {
  const context = useContext(FilePermissionContext)
  if (context === undefined) {
    throw new Error('useFilePermissionContext must be used within a FilePermissionContextProvider')
  }
  return context
}

export { FilePermissionProvider, useFilePermissionContext }
