import { memo, useEffect, useRef } from 'react'

import { useSetElementVirtualProperties } from '../../providers/ElementVirtualPropertiesProvider'
import { useDataStore, useSetDataStore } from '../../providers/dataStore/DataStoreProvider'
import { useElement } from '../../providers/dataStore/ElementProvider'

const allowedPropertySet = new Set([
  'name',
  'aspectRatioLocked',
  'booleanOperation',
  'currentStyle',
  'elementType',
  'containerType',
  'id',
  'locked',
  'expanded',
  'parent',
  'type',
  'visible',
  'children',
  'orientation',
  'screenPreset',
  'styles',
  'geometry',
  'geometryType',
  'text',
  'mask',
  'booleanType',
  'virtualDisplay',
  'virtualSelected',
  'virtualLocked'
])

const SubscribeElementChange = () => {
  const dataStore = useDataStore()
  const elementCacheRef = useRef(new Set())
  const callbackRef = useRef(new Map())
  const elements = useElement()
  const { updateElement } = useSetDataStore()
  const { updateElementVirtualProperties } = useSetElementVirtualProperties()

  useEffect(() => {
    const callbackList = callbackRef.current

    const clearElementCache = () => {
      elementCacheRef.current.clear()
    }
    dataStore.on('LOAD', clearElementCache)
    return () => {
      dataStore.off('LOAD', clearElementCache)

      callbackList.forEach(([$element, cb, type]) => {
        $element.off(type, cb)
      })
    }
  }, [dataStore])

  Object.keys(elements).forEach((elementId) => {
    if (elementCacheRef.current.has(elementId)) return
    elementCacheRef.current.add(elementId)

    const handleChange = (data, changesFromStyle) => {
      if (changesFromStyle && changesFromStyle.flags) return
      const virtualPropertiesChange = {}

      const changes = Array.from(data).reduce((acc, [key, { value }]) => {
        if (!allowedPropertySet.has(key)) return acc
        if (key === 'parent') {
          let parentId
          try {
            parentId = dataStore.getParentOf(dataStore.getElement(elementId)).get('id')
          } catch (e) {
            // FIXME: may need to figure out the good way to update parentId
            // ignore error because of element will be removed fist from data-store
          } finally {
            acc.parentId = parentId
          }
        } else if (key === 'geometryType') {
          if (!acc.geometry) {
            acc.geometry = {}
          }
          acc.geometry[key] = value
        } else if (key.startsWith('virtual')) {
          virtualPropertiesChange[key] = value
        } else {
          acc[key] = value
        }
        return acc
      }, {})

      if (Object.keys(virtualPropertiesChange).length) {
        updateElementVirtualProperties(elementId, virtualPropertiesChange)
      }

      if (!Object.keys(changes).length) return
      updateElement(elementId, changes)
    }

    const $element = dataStore.getById(elementId)

    // just in case, we check if element is still in data-store
    // the element may be removed from data-store earlier than this component render
    if (!$element) return

    $element.on('CHANGES', handleChange)
    callbackRef.current.set(`${elementId}_CHANGES`, [$element, handleChange, 'CHANGES'])
  })

  return null
}

export default memo(SubscribeElementChange)
