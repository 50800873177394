import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { TrimPathMode as TrimPathModeType } from '@phase-software/types'

import { useEditorActions } from '../../../../../providers/dataStore/EditorProvider'
import { Select } from '../../../../shared'
import { translateMenuOptions } from '../../../../shared/Menu/utils'
import { EffectTrimPathItem } from '../types'

type TrimPathModeProps = {
  id: string
  value: EffectTrimPathItem['mode']
}

const options = [
  {
    name: 'simultaneously',
    /** @ts-ignore */
    value: TrimPathModeType.SIMULTANEOUSLY
  },
  {
    name: 'individually',
    /** @ts-ignore */
    value: TrimPathModeType.INDIVIDUALLY
  }
]

export default function TrimPathMode({ id, value }: TrimPathModeProps) {
  const { setEffectProps } = useEditorActions()
  const { t } = useTranslation()

  const handleChange = (updatedValue: EffectTrimPathItem['mode']) => {
    setEffectProps(id, { mode: updatedValue })
  }

  const translatedOptions = useMemo(() => {
    return translateMenuOptions(t, options, {
      ns: 'file',
      keyPrefix: 'trim_path_mode'
    })
  }, [t])

  return (
    <Select
      // @ts-ignore TODO: fix after refactor of Select
      caret
      variant="normal"
      name="trimPathMode"
      options={translatedOptions}
      value={value}
      onChange={handleChange}
    />
  )
}
