import { TutorialType } from '../constant'
import tip14 from '../assets/1.4.gif'

export default {
  key: TutorialType.TRANSFORM,
  progress: true,
  mask: false,
  interact: false,
  steps: [
    {
      header: 'Set Origin on Panel',
      content: 'You can change Origin with the 9-patch UI on the Panel or the inputbox.',
      anchor: '.pt-transform-dialog',
      placement: 'left',
      image: tip14
    }
  ]
}
