import React, { useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { CSSTransition } from 'react-transition-group'

import useHeapAnalytics from '../../hooks/useHeapAnalytics'
import { useCreateThread, useSelf } from '../../liveblocks.config'
import { useCommentContext } from '../../providers/CommentProvider'
import { useFileContext } from '../../providers/FileProvider'
import { useWorkspaceContext } from '../../providers/WorkspaceContextProvider'
import { track } from '../../services/heapAnalytics'
import { FocusLoop } from '../shared'
import CommentTag from '../shared/CommentThread/CommentTag'
import CommentTextArea from '../shared/CommentThread/CommentTextArea'
import { CommentType, Position } from '../shared/CommentThread/types'
import useCanvasTagPosition from './useCanvasTagPosition'
import { toLiveblocksCommentThread } from './utils'

type CanvasCreateCommentProps = {
  position: Position
  handleTagDrag: (position: Position) => void
}

const CanvasCreateComment = ({ position, handleTagDrag }: CanvasCreateCommentProps) => {
  const { t } = useTranslation('file', { keyPrefix: 'commenting' })
  const ref = useRef<HTMLDivElement>(null)
  const tagRef = useRef<HTMLDivElement>(null)
  const textAreaContainerRef = useRef<HTMLDivElement>(null)
  const textAreaWrapperRef = useRef<HTMLDivElement>(null)
  const isTriggerBlocked = useRef(false)
  const currentPosition = useRef<{ x: number; y: number }>({ x: 0, y: 0 })

  const createThread = useCreateThread()
  const { id: currentUserId = '' } = useSelf()
  const { selectCanvasCommentThread, isCanvasCreateCommentOpen, closeCanvasCreateComment } = useCommentContext()
  const { id: fileId, projectId } = useFileContext()
  const { space, teamName } = useHeapAnalytics()
  const { workspaceData } = useWorkspaceContext()
  const location = projectId === workspaceData.draftProjectId ? 'drafts' : 'project'

  const onMouseUpUpdate = useCallback(
    (newPosition: { x: number; y: number }) => {
      handleTagDrag(newPosition)
    },
    [handleTagDrag]
  )

  const { isOverlayShown, handleTagPositionUpdate } = useCanvasTagPosition({
    ref,
    canvasPosition: position,
    onMouseUpUpdate,
    isTriggerBlocked,
    currentPosition
  })

  const createNewThread = useCallback(
    async (commentText: string) => {
      const metadata = {
        type: CommentType.CANVAS,
        position,
        usersReadStatus: {
          [currentUserId]: true
        },
        resolved: false
      }
      const data = toLiveblocksCommentThread(commentText, metadata)

      try {
        const newThread = await createThread(data)
        selectCanvasCommentThread(newThread?.id)
        track('Comment Added', {
          space,
          teamName,
          location,
          fileId,
          commentType: 'OnCanvas'
        })
      } catch (error) {
        console.log('[CanvasCreateComment] createNewThread error:', error)
      }
    },
    [position, currentUserId, createThread, selectCanvasCommentThread, space, teamName, location, fileId]
  )

  const handleWrapperClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
  }, [])

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        e.stopPropagation()
        closeCanvasCreateComment()
        window.removeEventListener('keydown', handleKeyDown)
      }
    }
    if (!isCanvasCreateCommentOpen) {
      window.removeEventListener('keydown', handleKeyDown)
      return
    }
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [isCanvasCreateCommentOpen, closeCanvasCreateComment])

  useEffect(() => {
    if (textAreaWrapperRef.current) {
      textAreaWrapperRef.current.focus()
    }
  }, [])
  return (
    <div
      ref={ref}
      onClick={handleWrapperClick}
      className={`absolute h-32 gap-x-12 flex flex-row left-0 bottom-0 z-30 will-change-transform ${
        isCanvasCreateCommentOpen ? 'cursor-default' : 'hidden  pointer-events-none'
      }`}
    >
      <div
        className={`fixed -left-32 -top-32 w-[96px] h-[96px] cursor-pointer bg-transparent ${
          isOverlayShown ? 'block' : 'hidden'
        }`}
      />

      <CSSTransition
        mountOnEnter
        unmountOnExit
        in={isCanvasCreateCommentOpen}
        nodeRef={tagRef}
        timeout={{
          enter: 150,
          exit: 0
        }}
        classNames={{
          enter: 'scale-0',
          enterActive: 'origin-bottom-left transition-scale duration-[150ms] ease-out scale-100'
        }}
      >
        <CommentTag
          ref={tagRef}
          isRead
          isResolved={false}
          isSelected
          onMouseDown={handleTagPositionUpdate}
          className="origin-bottom-left"
        />
      </CSSTransition>

      <CSSTransition
        mountOnEnter
        unmountOnExit
        in={isCanvasCreateCommentOpen}
        nodeRef={textAreaContainerRef}
        timeout={{
          enter: 150,
          exit: 0
        }}
        classNames={{
          enter: 'scale-0',
          enterActive: 'transition-scale duration-[150ms] ease-out scale-100'
        }}
      >
        <div ref={textAreaContainerRef} className="origin-center-left">
          <div ref={textAreaWrapperRef} className="w-[280px] bg-neutral-80 rounded-md shadow-5">
            <FocusLoop undoable>
              <CommentTextArea
                type={CommentType.CANVAS}
                variant="create"
                className="bg-neutral-80 px-8 pt-10 pb-8"
                placeholder={t('write_a_comment')}
                onInputSubmit={createNewThread}
                onEscape={closeCanvasCreateComment}
                maxScrollHeight={572}
                autoFocus
              />
            </FocusLoop>
          </div>
        </div>
      </CSSTransition>
    </div>
  )
}

export default CanvasCreateComment
