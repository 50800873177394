import PropertyStack from '../../PropertyStack'

class CapSizeStack extends PropertyStack {
  constructor(elementStack, data) {
    super(elementStack, data)
    this.type = 'CAP_SIZE'
    this.key = 'capSize'
    this.dataKey = 'capSize'
    this.animatableProperties = new Set(['capSize'])
  }
}

export default CapSizeStack
