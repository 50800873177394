import { MIX_VALUE_ID, MIX_VALUE, id } from '@phase-software/data-utils'
import { getLayerKeys } from './utils'

export class LayerItem {
    constructor(computedLayer, index) {
        this._data = {}
        if (computedLayer === MIX_VALUE) {
            this.id = MIX_VALUE_ID
            this.isMix = true
            return
        }
        const { layerType, paintType } = computedLayer.gets('layerType', 'paintType')
        this.layerType = layerType
        this.paintType = paintType

        const layerKeys = getLayerKeys(layerType, paintType)
        layerKeys.forEach(layerKey => {
            this._data[layerKey] = computedLayer.gets(layerKey)
        })
        this.id = id()
        this.computedLayerSet = new Set([computedLayer])
        this.index = index
        this.isNonBase = computedLayer.isNonBase
        this.generateHash()
    }

    generateHash() {
        // TODO: Better way to generate hash?
        this._data.index = this.index
        this._data.isMix = this.isMix
        this._data.isNonBase = this.isNonBase
        this.hash = JSON.stringify(this._data)
    }
}
