import { useLayoutEffect } from 'react'

import { useAtomValue } from 'jotai'

import { ScrollDirection, autoScrollDirectionAtom } from '../../../atoms'

type AutoScrollProp = {
  step: number
  targetRef: React.RefObject<HTMLElement>
}

export const AutoScroll = ({ targetRef, step = 8 }: AutoScrollProp) => {
  const autoScrollDirection = useAtomValue(autoScrollDirectionAtom)

  useLayoutEffect(() => {
    let _raf: number

    const doScroll = () => {
      if (!targetRef.current || !autoScrollDirection.size) {
        return
      }

      if (autoScrollDirection.has(ScrollDirection.TOP)) {
        targetRef.current.scrollTop -= step
      }
      if (autoScrollDirection.has(ScrollDirection.BOTTOM)) {
        targetRef.current.scrollTop += step
      }
      if (autoScrollDirection.has(ScrollDirection.LEFT)) {
        targetRef.current.scrollLeft -= step
      }
      if (autoScrollDirection.has(ScrollDirection.RIGHT)) {
        targetRef.current.scrollLeft += step
      }
      _raf = requestAnimationFrame(doScroll)
    }

    _raf = requestAnimationFrame(doScroll)
    return () => {
      cancelAnimationFrame(_raf)
    }
  }, [autoScrollDirection, targetRef, step])

  return null
}
