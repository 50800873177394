import { EventFlag } from '@phase-software/types'
import { getLineP } from '@phase-software/data-utils'
import { CubicBez } from '@phase-software/renderer/src/geometry/bezier-shape/CubicBez'
import {
  getProgress,
  getPropPercentage
} from '../../utils/timing-functions'
import PropertyStack from '../PropertyStack'

class MotionPathStack extends PropertyStack {
  constructor(elementStack, data) {
    super(elementStack, data)
    this.type = 'MOTION_PATH'
    this.key = 'motionPath'
    this.dataKey = 'motionPath'
    this.animatableProperties = new Set(['motionPath'])
  }

  updateKF(KFId, data) {
    super.updateKF(KFId, data)

    this.elementStack.element.computedStyle.sets(
      this.getAnimateData(this.manager.currentElapsedTime),
      { undoable: false, flags: EventFlag.FROM_ANIMATION }
    )
  }

  /**
   * Get animation data
   * @param {number} time
   * @returns {any | null}
   */
  getAnimateData(time) {
    const interval = this.getWorkingInterval(time)
    if (!interval) {
      return null
    }

    const base = this.getBaseValue()

    const workingTime = this.getWorkingTime(time)
    const progress = getProgress(workingTime, interval)

    const percent = getPropPercentage(interval[1], progress)

    const startPoint = [base.pos[0] + interval[0].value.pos[0], base.pos[1] + interval[0].value.pos[1]]
    const endPoint = [base.pos[0] + interval[1].value.pos[0], base.pos[1] + interval[1].value.pos[1]]

    // FIXME: in earlier stage to truncte the z
    const outTangent = interval[0].value.out.slice(0, 2)
    const inTangent = interval[1].value.in.slice(0, 2)

    const controlPoint1 = outTangent.every(v => !v) ? null : [startPoint[0] + outTangent[0], startPoint[1] + outTangent[1]]
    const controlPoint2 = inTangent.every(v => !v) ? null : [endPoint[0] + inTangent[0], endPoint[1] + inTangent[1]]

    const isStraight = !controlPoint1 && !controlPoint2
    if (isStraight) {
        const dx = endPoint[0] - startPoint[0];
        const dy = endPoint[1] - startPoint[1];
        const [translateX, translateY] = getLineP(percent, startPoint[0], startPoint[1], endPoint[0], endPoint[1])
        if (dx || dy) {
            const ao = Math.atan2(dy, dx);
            return { translateX, translateY, orientRotation: ao }
        }

        return { translateX, translateY }
    } else {
        const curve = [...startPoint, ...outTangent, ...inTangent, ...endPoint]
        const bez = new CubicBez().initWithPointsAndHandlesN(...curve)
        const len = bez.getLength()
        const offset = len * percent
        if (percent < 0) {
            const pos = bez.getPointAtTime(0)
            const overShoot = len * (Math.abs(interval[1].bezier[1]))
            const tangent = bez.getTangentAtTime(0)
            const ao = Math.atan2(tangent.y, tangent.x)
            pos.add(tangent.scale(overShoot * percent))
            return { translateX: pos.x, translateY: pos.y, orientRotation: ao }
        } else if (percent > 1) {
            const pos = bez.getPointAtTime(1)
            const overShoot = len * (interval[1].bezier[3] - 1)
            const tangent = bez.getTangentAtTime(1)
            const ao = Math.atan2(tangent.y, tangent.x)
            pos.add(tangent.scale(overShoot * (percent - 1)))
            return { translateX: pos.x, translateY: pos.y, orientRotation: ao }
        } else {
            const t = bez.getTimeAt(offset)
            const pos = bez.getPointAtTime(t)
            const tangent = bez.getTangentAtTime(t)
            const ao = Math.atan2(tangent.y, tangent.x)
            return { translateX: pos.x, translateY: pos.y, orientRotation: ao }
        }
    }
  }

  getInitValue() {
    return { in: [0, 0], out: [0, 0], pos: [0, 0] }
  }
}

export default MotionPathStack
