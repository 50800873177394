import React from 'react'
import { Redirect } from 'react-router'

import { useFeatureValue } from '@growthbook/growthbook-react'

import { PERMISSIONS, useAccessControl } from '../../access-control'
import MotionChallengeBanner from '../../components/MotionChallengeBanner'
import { FEATURE_KEYS } from '../../growthbook-feature-keys'
import { ProjectPermissionProvider } from '../../providers/ProjectPermissionProvider'
import { useWorkspaceContext } from '../../providers/WorkspaceContextProvider'
import { getWorkspaceDraftsPath } from '../../utils/pathGenerators'
import TemplateList from './TemplateList'

export type BuiltInTemplateType = {
  value?: boolean
  projectId?: string
}

const Templates = () => {
  const { workspaceData } = useWorkspaceContext()
  const { userHasPermission } = useAccessControl()
  const userCanViewTemplate = userHasPermission(PERMISSIONS.VIEW_TEMPLATE)
  const builtInTemplate = useFeatureValue<BuiltInTemplateType>(FEATURE_KEYS.TEMPLATE, {
    value: false,
    projectId: ''
  })

  const projectId = builtInTemplate.projectId

  if (!builtInTemplate.value || !userCanViewTemplate) {
    return <Redirect to={getWorkspaceDraftsPath(workspaceData.type, workspaceData.slug)} />
  }

  return (
    <ProjectPermissionProvider key={projectId} id={projectId}>
      <MotionChallengeBanner />
      <TemplateList id={projectId} />
    </ProjectPermissionProvider>
  )
}

export default Templates
