/* eslint-disable no-case-declarations */
/* eslint-disable jsdoc/valid-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable eqeqeq */
/* eslint-disable no-return-assign */
/* eslint-disable no-mixed-operators */
/* eslint-disable jsdoc/check-tag-names */
/* eslint-disable no-param-reassign */
/* eslint-disable jsdoc/require-returns */
import { BooleanOperation, TrimPathMode } from "@phase-software/types"
import { ANGULAR_EPSILON, CURVETIME_EPSILON, EPSILON, GEOMETRIC_EPSILON, Rect2, Transform2D } from "../../math"
import { Vector2 } from "../../math/Vector2"
import { Command } from "../PathData"
import Numerical from "./utils/Numerical"
import {
    findCurveBoundsCollisions,
    findItemBoundsCollisions,
} from "./utils/CollisionDetection"
import { Change, ChangeFlag } from "./utils/ChangeFlag"
import { getOverlaps } from "./common"
import { Segment } from "./Segment"
import { CubicBez } from "./CubicBez"
import { QuadBez } from "./QuadBez"

/** @typedef {import("../PathData").PathData} PathData */
/** @typedef {import("../../math/Vector2").Vector2Like} Vector2Like */
/** @typedef {import("../../math/Transform2D").Transform2D} Transform2D */
/** @typedef {import("./common").CurveData} CurveData */

export const BooleanAction = {
    [BooleanOperation.SUBTRACT]: 'subtract',
    [BooleanOperation.INTERSECT]: 'intersect',
    [BooleanOperation.DIFFERENCE]: 'exclude',
    [BooleanOperation.UNION]: 'union'
}

export function BezierPath() {
    /** @type {boolean} */
    this._closed = false
    /** @type {Segment[]} */
    this._segments = []
    /** @type {CubicBez[]} */
    this._curves = null
    /** @type {Rect2} */
    this._bounds = null

    /** @type {number} */
    this._version = 0
    /** @type {number} */
    this._length = null
    /** @type {number} */
    this._area = null
    /** @type {boolean} */
    this._overlapsOnly = false

    /** @type {number} */
    this._id = Path_uid++
}

BezierPath.prototype = {
    constructor: BezierPath,

    toPathData() {
        const segments = this._segments
        const length = segments.length
        /** @type {number[]} */
        const coords = Array(6)
        let first = true
        let curX = 0
        let curY = 0
        let prevX = 0
        let prevY = 0
        let inX = 0
        let inY = 0
        let outX = 0
        let outY = 0

        /** @type {PathData} */
        const pathData = {
            commands: [],
            vertices: [],
        }

        /**
         * @param {Segment} segment
         * @param {number[]} coords
         *
         * @checked
         */
        function getSegmentCoords(segment, coords) {
            const { x, y } = segment._point
            coords[0] = x
            coords[1] = y
            coords[2] = segment._handleIn.x + x
            coords[3] = segment._handleIn.y + y
            coords[4] = segment._handleOut.x + x
            coords[5] = segment._handleOut.y + y
        }

        /**
         * @param {Segment} segment
         * @param {boolean} skipLine
         */
        function addSegment(segment, skipLine) {
            getSegmentCoords(segment, coords)

            curX = coords[0]
            curY = coords[1]
            if (first) {
                pathData.commands.push(1)
                pathData.vertices.push(curX, curY)
                first = false
            } else {
                inX = coords[2]
                inY = coords[3]
                if (
                    inX === curX
                    &&
                    inY === curY
                    &&
                    outX === prevX
                    &&
                    outY === prevY
                ) {
                    if (!skipLine) {
                        pathData.commands.push(2)
                        pathData.vertices.push(curX, curY)
                    }
                } else {
                    pathData.commands.push(4)
                    pathData.vertices.push(
                        outX, outY,
                        inX, inY,
                        curX, curY
                    )
                }
            }
            prevX = curX
            prevY = curY
            outX = coords[4]
            outY = coords[5]
        }

        if (!length) {
            return pathData
        }

        for (let i = 0; i < length; i++) {
            addSegment(segments[i], false)
        }
        // Close path by drawing first segment again
        if (this._closed && length > 0) {
            addSegment(segments[0], false)
        }
        if (this._closed) {
            pathData.commands.push(5)
        }
        return pathData
    },

    /**
     * @param {boolean} absolute
     * @param {number} precision
     */
    toSVG(absolute = false, precision = 5) {
        format.setup(precision)

        const segments = this._segments
        const length = segments.length
        /** @type {number[]} */
        const coords = Array(6)
        let first = true
        let curX = 0
        let curY = 0
        let prevX = 0
        let prevY = 0
        let inX = 0
        let inY = 0
        let outX = 0
        let outY = 0
        const parts = []

        /**
         * @param {Segment} segment
         * @param {number[]} coords
         *
         * @checked
         */
        function getSegmentCoords(segment, coords) {
            const { x, y } = segment._point
            coords[0] = x
            coords[1] = y
            coords[2] = segment._handleIn.x + x
            coords[3] = segment._handleIn.y + y
            coords[4] = segment._handleOut.x + x
            coords[5] = segment._handleOut.y + y
        }

        /**
         * @param {Segment} segment
         * @param {boolean} skipLine
         */
        function addSegment(segment, skipLine) {
            getSegmentCoords(segment, coords)

            curX = coords[0]
            curY = coords[1]
            if (first) {
                // eslint-disable-next-line prefer-template
                parts.push('M' + format.pair(curX, curY))
                first = false
            } else {
                inX = coords[2]
                inY = coords[3]
                if (
                    inX === curX
                    &&
                    inY === curY
                    &&
                    outX === prevX
                    &&
                    outY === prevY
                ) {
                    // l = relative lineto:
                    // L = abs lineto:
                    if (!skipLine) {
                        if (absolute) {
                            parts.push(`L${format.pair(curX, curY)}`)
                        } else {
                            const dx = curX - prevX
                            const dy = curY - prevY
                            parts.push(
                                // eslint-disable-next-line no-nested-ternary
                                dx === 0
                                    // eslint-disable-next-line prefer-template
                                    ? `v${format.number(dy)}`
                                    : dy === 0
                                        ? `h${format.number(dx)}`
                                        : `l${format.pair(dx, dy)}`
                            )
                        }
                    }
                } else {
                    if (absolute) {
                        // C = abs curveto:
                        // eslint-disable-next-line prefer-template
                        parts.push('C' + format.pair(outX, outY)
                                 + ' ' + format.pair( inX,  inY)
                                 + ' ' + format.pair(curX, curY)
                        )
                    } else {
                        // c = relative curveto:
                        // eslint-disable-next-line prefer-template
                        parts.push('c' + format.pair(outX - prevX, outY - prevY)
                                 + ' ' + format.pair( inX - prevX,  inY - prevY)
                                 + ' ' + format.pair(curX - prevX, curY - prevY)
                        )
                    }
                }
            }
            prevX = curX
            prevY = curY
            outX = coords[4]
            outY = coords[5]
        }

        if (!length) {
            return ''
        }

        for (let i = 0; i < length; i++) {
            addSegment(segments[i], false)
        }
        // Close path by drawing first segment again
        if (this._closed && length > 0) {
            addSegment(segments[0], true)
        }
        if (this._closed) {
            parts.push('z')
        }
        return parts.join('')
    },

    toString() {
        return this._segments.join(', ')
    },

    clear() {
        this._closed = false
        this._segments.length = 0
        this._curves = null
        this._bounds = null

        this._version = 0
        this._length = null
        this._area = null
        this._overlapsOnly = false

        this._id = Path_uid++

        return this
    },

    /**
     * @param {number} tolerance
     */
    close(tolerance = 0) {
        this.setClosed(true)
        this.join(this, tolerance)
    },

    /**
     * Joins the path with the other specified path, which will be removed in
     * the process. They can be joined if the first or last segments of either
     * path lie in the same location. Locations are optionally compare with a
     * provide `tolerance` value.
     *
     * If `null` or `this` is passed as the other path, the path will be joined
     * with itself if the first and last segment are in the same location.
     *
     * @param {BezierPath} path
     * @param {number} tolerance
     * @returns {-1 | 1 | 2} -1: failed, +1: joined, +2: joined and closed the path
     */
    join(path, tolerance = 0) {
        const epsilon = tolerance || 0
        if (path && path !== this) {
            const segments = path._segments
            const last1 = this.getLastSegment()
            let last2 = path.getLastSegment()
            if (!last2) { // an empty path?
                return -1
            }
            if (last1 && last1._point.isClose(last2._point, epsilon)) {
                path.reverse()
            }
            const first2 = path.getFirstSegment()
            if (last1 && last1._point.isClose(first2._point, epsilon)) {
                last1.setHandleOut(first2._handleOut)
                this._add(segments.slice(1))
            } else {
                const first1 = this.getFirstSegment()
                if (first1 && first1._point.isClose(first2._point, epsilon)) {
                    path.reverse()
                }
                last2 = path.getLastSegment()
                if (first1 && first1._point.isClose(last2._point, epsilon)) {
                    first1.setHandleIn(last2._handleIn)
                    // Prepend all segments from path except the last one.
                    this._add(segments.slice(0, segments.length - 1), 0)
                } else {
                    this._add(segments.slice())
                }
            }
            if (path._closed) {
                this._add([segments[0]])
            }
            path.remove()
        }
        // If the first and last segment touch, close the resulting path and
        // merge the end segments. Also do this if no path argument was provided
        // in which cases the path is joined with itself only if its ends touch.
        const first = this.getFirstSegment()
        const last = this.getLastSegment()
        if (first !== last && first._point.isClose(last._point, epsilon)) {
            first.setHandleIn(last._handleIn)
            last.remove()
            this.setClosed(true)
            return 2
        }
        return 1
    },

    /**
     * @param {BezierPath} path
     */
    compare(path) {
        // If a compound-path is involved, redirect to PathItem#compare()
        // if (!path || path instanceof CompoundPath)
        //     return compare.base.call(this, path);
        const curves1 = this.getCurves()
        const curves2 = path.getCurves()
        const length1 = curves1.length
        const length2 = curves2.length
        if (!length1 || !length2) {
            // If one path defines curves and the other doesn't, we can't have
            // matching geometries.
            return length1 === length2
        }
        let v1 = curves1[0].getValues()
        const values2 = []
        let pos1 = 0
        let end1 = 0
        let pos2, end2
        // First, loop through curves2, looking for the start of the overlapping
        // sequence with curves1[0]. Also cache curve values for later reuse.
        for (let i = 0; i < length2; i++) {
            const v2 = curves2[i].getValues()
            values2.push(v2)
            const overlaps = getOverlaps(v1, v2)
            if (overlaps) {
                // If the overlap doesn't start at the beginning of v2, then it
                // can only be a partial overlap with curves2[0], and the start
                // will be at curves2[length2 - 1]:
                pos2 = !i && overlaps[0][0] > 0 ? length2 - 1 : i
                // Set end2 to the start of the first overlap on curves2, so
                // connection checks further down can work.
                end2 = overlaps[0][1]
                break
            }
        }
        // Now loop through both curve arrays, find their overlaps, verify that
        // they keep joining, and see if we end back at the start on both paths.
        // const abs = Math.abs
        const epsilon = CURVETIME_EPSILON
        let v2 = values2[pos2]
        let start2
        while (v1 && v2) {
            const overlaps = getOverlaps(v1, v2)
            if (overlaps) {
                // Check that the overlaps are joining on curves1.
                const t1 = overlaps[0][0]
                if (abs(t1 - end1) < epsilon) {
                    end1 = overlaps[1][0]
                    if (end1 === 1) {
                        // Skip to the next curve if we're at the end of the
                        // current, and set v1 to null if at the end of curves1.
                        v1 = ++pos1 < length1 ? curves1[pos1].getValues() : null
                        end1 = 0
                    }
                    // Check that the overlaps are joining on curves2.
                    const t2 = overlaps[0][1]
                    if (abs(t2 - end2) < epsilon) {
                        if (!start2) {
                            start2 = [pos2, t2]
                        }
                        end2 = overlaps[1][1]
                        if (end2 === 1) {
                            // Wrap pos2 around the end on values2:
                            if (++pos2 >= length2) {
                                pos2 = 0
                            }
                            // Reuse cached values from initial search.
                            v2 = values2[pos2] || curves2[pos2].getValues()
                            end2 = 0
                        }
                        if (!v1) {
                            // We're done with curves1. If we're not back at the
                            // start on curve2, the two paths are not identical.
                            return start2[0] === pos2 && start2[1] === end2
                        }
                        // All good, continue to avoid the break; further down.
                        continue
                    }
                }
            }
            // No overlap match found, break out early.
            break
        }
        return false
    },

    /**
     * @param {Segment[]} segments
     */
    setSegments(segments) {
        let length = segments.length
        this._segments.length = 0
        this._curves = null
        if (length > 0) {
            const last = segments[length - 1]
            if (typeof(last) === "boolean") {
                this.setClosed(last)
                length--
            }
            this._add(segments)
        }
        return this
    },

    /**
     * @param {number} index
     */
    removeSegment(index) {
        return this.removeSegments(index, index + 1)[0] || null
    },

    /**
     * @param {number} start
     * @param {number} end
     * @param {boolean} _includeCurves
     */
    removeSegments(start, end, _includeCurves = false) {
        // eslint-disable-next-line no-param-reassign
        start = start || 0
        // eslint-disable-next-line no-param-reassign
        end = end || this._segments.length

        const segments = this._segments
        let curves = this._curves
        const count = segments.length // segment count before removal
        const removed = segments.splice(start, end - start)
        const amount = removed.length
        if (!amount) {
            return removed
        }
        // Update selection state accordingly
        for (let i = 0; i < amount; i++) {
            const segment = removed[i]
            // Clear the indices and path references of the removed segments
            segment._index = null
            segment._path = null
        }
        // Adjust the indices of the segments above.
        for (let i = start, l = segments.length; i < l; i++) {
            segments[i]._index = i
        }
        // Keep curves in sync
        if (curves) {
            // If we're removing the last segment, remove the last curve (the
            // one to the left of the segment, not to the right, as normally).
            // Also take into account closed paths, which have one curve more
            // than segments.
            const index = start > 0 && end === count + (this._closed ? 1 : 0)
                ? start - 1
                : start
            curves = curves.splice(index, amount)
            // Unlink the removed curves from the path.
            for (let i = curves.length - 1; i >= 0; i--) {
                curves[i]._path = null
            }
            // Return the removed curves as well, if we're asked to include
            // them, but exclude the first curve, since that's shared with the
            // previous segment and does not connect the returned segments.
            if (_includeCurves) {
                removed._curves = curves.slice(1)
            }
            // Adjust segments for the curves before and after the removed ones
            this._adjustCurves(index, index)
        }
        // Use SEGMENTS notification instead of GEOMETRY since curves are kept
        // up-to-date by _adjustCurves() and don't need notification.
        this._changed(Change.SEGMENTS)
        return removed
    },

    /**
     * @param {boolean} closed
     */
    setClosed(closed) {
        // On-the-fly conversion to boolean:
        // eslint-disable-next-line no-param-reassign
        if (this._closed != (closed = !!closed)) {
            this._closed = closed
            // Update _curves length
            if (this._curves) {
                const length = this._countCurves()
                this._curves.length = length
                // If we were closing this path, we need to add a new curve now
                if (closed) {
                    this._curves[length - 1] = new CubicBez().initWithPathAndSegments(
                        this,
                        this._segments[length - 1],
                        this._segments[0]
                    )
                }
            }
            // Use SEGMENTS notification instead of GEOMETRY since curves are
            // up-to-date and don't need notification.
            this._changed(Change.SEGMENTS)
        }
    },

    /**
     * Specifies whether the path as a whole is oriented clock-wise, by looking
     * at the path's area.
     * Note that self-intersecting paths and sub-paths of different orientation
     * can result in areas that cancel each other out.
     */
    isClockwise() {
        return this.getArea() >= 0
    },

    /**
     * @param {boolean} clockwise
     */
    setClockwise(clockwise) {
        if (this.isClockwise() !== (!!clockwise)) {
            this.reverse()
        }
    },

    isEmpty() {
        return this._segments.length === 0
    },

    /**
     * The approximate length of the path.
     */
    getLength() {
        if (this._length == null) {
            const curves = this.getCurves()
            let length = 0
            for (let i = 0, l = curves.length; i < l; i++) {
                length += curves[i].getLength()
            }
            this._length = length
        }
        return this._length
    },

    /**
     * The area that the path's geometry is covering. Self-intersecting paths
     * can contain sub-areas that cancel each other out.
     */
    getArea() {
        let area = this._area
        if (area == null) {
            area = 0
            const segments = this._segments
            const closed = this._closed
            for (let i = 0, l = segments.length; i < l; i++) {
                const last = i + 1 === l
                area += CubicBez.getArea(
                    CubicBez.getValues(
                        segments[i],
                        segments[last ? 0 : i + 1],
                        // If this is the last curve and the last is not closed,
                        // connect with a straight curve and ignore the handles.
                        last && !closed
                    )
                )
            }
            this._area = area
        }
        return area
    },

    getFillRule() {
        return 'nonzero'
    },

    getFirstSegment() {
        return this._segments[0]
    },

    getLastSegment() {
        return this._segments[this._segments.length - 1]
    },

    /**
     * The curves contained within the path.
     * @returns {CubicBez[]}
     */
    getCurves() {
        if (!this._curves) {
            const length = this._countCurves()
            // eslint-disable-next-line no-multi-assign
            this._curves = new Array(length)
            for (let i = 0; i < length; i++) {
                this._curves[i] = new CubicBez().initWithPathAndSegments(
                    this,
                    this._segments[i],
                    this._segments[i + 1] || this._segments[0]
                )
            }
        }
        return this._curves
    },

    getLastCurve() {
        const curves = this.getCurves()
        return curves[curves.length - 1]
    },

    getPointAt(offset) {
        const loc = this.getLocationAt(offset)
        return loc && loc.getPoint()
    },

    /**
     * Returns the curve location of the specified offset on the path.
     *
     * @param {number} offset the offset on the path, where `0` is at
     * the beginning of the path and {@link BezierPath#length} at the end
     * @return {CurveLocation} the curve location at the specified offset
     */
    getLocationAt(offset) {
        if (typeof offset === 'number') {
            const curves = this.getCurves()
            let length = 0
            for (let i = 0, l = curves.length; i < l; i++) {
                const start = length
                const curve = curves[i]
                length += curve.getLength()
                if (length > offset) {
                    // Found the segment within which the length lies
                    return curve.getLocationAt(offset - start)
                }
            }
            // It may be that through imprecision of getLength, that the end of
            // the last curve was missed:
            if (curves.length > 0 && offset <= this.getLength()) {
                return new CurveLocation().init(curves[curves.length - 1], 1)
            }
        } else if (offset && offset.getPath && offset.getPath() === this) {
            // offset is already a CurveLocation on this path, just return it.
            return offset
        }
        return null
    },

    getBounds() {
        if (this._bounds == null) {
            this._bounds = BezierPath.getBounds(this._segments, this._closed)
        }
        return this._bounds
    },

    getHandleBounds() {
        return BezierPath.getHandleBounds(this._segments, this._closed)
    },

    getInteriorPoint() {
        return getInteriorPoint(this)
    },

    /**
     * @param {Vector2} point
     */
    _contains(point) {
        const winding = point.isInside(this.getHandleBounds())
            ? this._getWinding(point)
            : {}
        return winding.onPath || !!(
            this.getFillRule() === 'evenodd'
                ? winding.windingL & 1 || winding.windingR & 1
                : winding.winding
        )
    },

    /**
     * @param {Vector2Like} point
     */
    contains(point) {
        return !!this._contains(point)
    },

    /**
     * @param {boolean} simplify
     * @returns {BezierPath}
     */
    reduce(simplify = false) {
        const curves = this.getCurves()
        // TODO: Find a better name, to not confuse with PathItem#simplify()
        // When not simplifying, only remove curves if their lengths are
        // absolutely 0.
        const tolerance = simplify ? GEOMETRIC_EPSILON : 0
        for (let i = curves.length - 1; i >= 0; i--) {
            const curve = curves[i]
            // When simplifying, compare curves with isCollinear() will remove
            // any collinear neighboring curves regardless of their orientation.
            // This serves as a reliable way to remove linear overlaps but only
            // as long as the lines are truly overlapping.
            if (
                !curve.hasHandles() && (!curve.hasLength(tolerance)
                // eslint-disable-next-line no-mixed-operators
                || simplify && curve.isCollinear(curve.getNext()))
            ) {
                curve.remove()
            }
        }
        return this
    },

    /**
     * @checked
     */
    reverse() {
        this._segments.reverse()
        // Reverse the handles:
        for (let i = 0, l = this._segments.length; i < l; i++) {
            const segment = this._segments[i]
            const handleIn = segment._handleIn
            segment._handleIn = segment._handleOut
            segment._handleOut = handleIn
            segment._index = i
        }
        // Clear curves since it all has changed.
        this._curves = null
        this._changed(Change.GEOMETRY)
    },

    /**
     * @param {Transform2D} t
     */
    transform(t) {
        for (let i = 0, len = this._segments.length; i < len; i++) {
            const segment = this._segments[i]
            t.xform(segment._point, segment._point)
            t.basis_xform(segment._handleIn, segment._handleIn)
            t.basis_xform(segment._handleOut, segment._handleOut)
        }
        return this
    },

    clone() {
        return clonePath(this)
    },

    /**
     * @param {BezierPath} path
     * @param {(loc: CurveLocation) => boolean} filterer
     */
    getIntersections(path, filterer) {
        return getIntersections(this, path, filterer)
    },

    resolveCrossings() {
        return resolveCrossings(this)
    },

    reorient(isNonZero, isClockwise) {
        return reorient(this, isNonZero, isClockwise)
    },

    /**
     * @param {number} index
     * @param {Segment} segment1
     */
    insert(index, segment1) {
        return this._add([segment1], index)
    },

    /**
     * @param {Segment} segment1
     */
    add(segment1) {
        return this._add([segment1])[0]
    },

    /**
     * @param {Segment[]} segs
     * @param {number} [index]
     */
    _add(segs, index) {
        const append = !Number.isFinite(index)
        if (append) {
            // eslint-disable-next-line no-param-reassign
            index = this._segments.length
        }

        const amount = segs.length
        for (let i = 0; i < amount; i++) {
            let segment = segs[i]
            if (segment._path) {
                segment = segment.clone()
                segs[i] = segment
            }
            segment._path = this
            segment._index = index + i
        }

        if (append) {
            Base_push(this._segments, segs)
        } else {
            // Insert somewhere else
            // eslint-disable-next-line prefer-spread
            this._segments.splice.apply(this._segments, [index, 0].concat(segs))
            // Adjust the indices of the segments above.
            for (let i = index + amount, l = this._segments.length; i < l; i++) {
                this._segments[i]._index = i
            }
        }

        if (this._curves) {
            const total = this._countCurves()
            // If we're adding a new segment to the end of an open path,
            // we need to step one index down to get its curve.
            const start = index > 0 && index + amount - 1 === total
                ? index - 1
                : index
            let insert = start
            const end = Math.min(start + amount, total)
            if (segs._curves) {
                // Reuse removed curves.
                this.curves.splice.apply(this._curves, [start, 0].concat(segs._curves))
                insert += segs._curves.length
            }
            for (let i = insert; i < end; i++) {
                this._curves.splice(i, 0, new CubicBez().initWithPathAndSegments(this, null, null))
            }
            // Adjust segments for the curves before and after the removed ones
            this._adjustCurves(start, end)
        }
        // Use SEGMENTS notification instead of GEOMETRY since curves are kept
        // up-to-date by _adjustCurves() and don't need notification.
        this._changed(Change.SEGMENTS)
        return segs
    },

    _remove() {
        return Item_remove(this)
    },

    remove() {
        return Item_remove(this)
    },

    _countCurves() {
        const length = this._segments.length
        // Reduce length by one if it's an open path:
        return !this._closed && length > 0
            ? length - 1
            : length
    },

    /**
     * @param {number} start
     * @param {number} end
     */
    _adjustCurves(start, end) {
        const segments = this._segments
        const curves = this._curves
        let curve
        for (let i = start; i < end; i++) {
            curve = curves[i]
            curve._path = this
            curve._segment1 = segments[i]
            curve._segment2 = segments[i + 1] || segments[0]
            curve._changed()
        }
        // If it's the first segment, correct the last segment of closed
        // paths too:
        // eslint-disable-next-line no-cond-assign
        if (curve = curves[this._closed && !start
            ? segments.length - 1
            : start - 1
        ]) {
            curve._segment2 = segments[start] || segments[0]
            curve._changed()
        }
        // Fix the segment after the modified range, if it exists
        // eslint-disable-next-line no-cond-assign
        if (curve = curves[end]) {
            curve._segment1 = segments[end]
            curve._changed()
        }
    },

    /**
     * Returns the winding contribution number of the given point in respect
     * to this PathItem.
     *
     * @param {Vector2} point the location for which to determine the winding
     *     contribution
     * @param {number} [dir=0] the direction in which to determine the
     *     winding contribution, `0`: in x-direction, `1`: in y-direction
     * @param {boolean} closed
     */
    _getWinding(point, dir, closed) {
        return getWinding(point, this.getCurves(), dir, closed)
    },

    /**
     * @param {ChangeFlag} flags
     */
    _changed(flags) {
        if (flags & ChangeFlag.GEOMETRY) {
            this._bounds = null
            this._length = null
            this._area = null
            if (flags & ChangeFlag.SEGMENTS) {
                this._version++ // See CurveLocation
            } else if (this._curves) {
                // Only notify all curves if we're not told that only segments
                // have changed and took already care of notifications.
                for (let i = 0, l = this._curves.length; i < l; i++) {
                    this._curves[i]._changed()
                }
            }
        }
    },

    /** trim path */

    /**
     * @param {number} s start
     * @param {number} e end
     * @param {boolean} [isTrimEndWrapped = false]
     * @returns {BezierPath[]}
     */
    trim(s, e, isTrimEndWrapped = false) {
        const curves = this.getCurves()
        // no curves
        if (curves.length === 0) {
            return this
        }

        const lengths = []
        let totalLength = 0
        for (let i = 0; i < curves.length; i++) {
            const len = curves[i].getLength()
            lengths.push(len)
            totalLength += len
        }
        const startOffset = s * totalLength
        const endOffset = e * totalLength
        let startIndex = 0, endIndex = 0, offsetInStartCurve = 0, offsetInEndCurve = 0, currOffset = 0
        for (let i = 0; i < curves.length; i++) {
            if (currOffset < startOffset) {
                startIndex = i
                offsetInStartCurve = startOffset - currOffset
            }
            if (currOffset < endOffset) {
                endIndex = i
                offsetInEndCurve = endOffset - currOffset
            }
            if (currOffset >= startOffset && currOffset >= endOffset) {
                break
            }
            currOffset += lengths[i]
        }

        const pathArray = []
        if (isTrimEndWrapped) {
            const segmentArray1 = []
            const segmentArray2 = []
            const startCurveData = curves[startIndex].getValues()
            const endCurveData = curves[endIndex].getValues()
            const startCurveDataT = CubicBez.subdivide(startCurveData, CubicBez.getTimeAt(startCurveData, offsetInStartCurve))[1]
            const endCurveDataT = CubicBez.subdivide(endCurveData, CubicBez.getTimeAt(endCurveData, offsetInEndCurve))[0]
            // 1. add starting curve
            segmentArray1.push(
                new Segment().initWithPointsN(startCurveDataT[0], startCurveDataT[1], startCurveDataT[0] - startCurveDataT[2], startCurveDataT[1] - startCurveDataT[3], startCurveDataT[2] - startCurveDataT[0], startCurveDataT[3] - startCurveDataT[1]),
                new Segment().initWithPointsN(startCurveDataT[6], startCurveDataT[7], startCurveDataT[4] - startCurveDataT[6], startCurveDataT[5] - startCurveDataT[7], 0, 0)
            )
            // 2. add middle curves (first path)
            let isSecondCurve = true
            for (let i = startIndex + 1; i < curves.length; i++) {
                if (isSecondCurve) {
                    curves[i]._segment1._handleIn = segmentArray1.pop()._handleIn
                    segmentArray1.push(curves[i]._segment1)
                    isSecondCurve = false
                }
                segmentArray1.push(curves[i]._segment2)
            }
            // eslint-disable-next-line no-negated-condition
            if (!this._closed) {
                // 3. add middle curves (second path)
                let isFirstCurveInSecondPath = true
                for (let i = 0; i <= endIndex - 1; i++) {
                    if (isFirstCurveInSecondPath) {
                        segmentArray2.push(curves[i]._segment1)
                        isFirstCurveInSecondPath = false
                    }
                    segmentArray2.push(curves[i]._segment2)
                }
                // 4. add ending curve
                if (isFirstCurveInSecondPath) {
                    segmentArray2.push(
                        new Segment().initWithPointsN(endCurveDataT[0], endCurveDataT[1], 0, 0, endCurveDataT[2] - endCurveDataT[0], endCurveDataT[3] - endCurveDataT[1])
                    )
                } else {
                    const last = segmentArray2.pop()
                    segmentArray2.push(
                        new Segment().initWithPointsN(endCurveDataT[0], endCurveDataT[1], last._handleIn.x, last._handleIn.y, endCurveDataT[2] - endCurveDataT[0], endCurveDataT[3] - endCurveDataT[1])
                    )
                }
                // NOTE: the _handleOut of the last segment must be set
                segmentArray2.push(
                    new Segment().initWithPointsN(endCurveDataT[6], endCurveDataT[7], endCurveDataT[4] - endCurveDataT[6], endCurveDataT[5] - endCurveDataT[7], endCurveDataT[6] - endCurveDataT[4], endCurveDataT[7] - endCurveDataT[5])
                )

                pathArray.push(new BezierPath().setSegments(segmentArray1))
                pathArray.push(new BezierPath().setSegments(segmentArray2))
            } else {
                // 3. add middle curves (second path)
                for (let i = 0; i <= endIndex - 1; i++) {
                    if (isSecondCurve) {
                        curves[i]._segment1._handleIn = segmentArray1.pop()._handleIn
                        segmentArray1.push(curves[i]._segment1)
                        isSecondCurve = false
                    }
                    segmentArray1.push(curves[i]._segment2)
                }
                // 4. add ending curve
                const last = segmentArray1.pop()
                segmentArray1.push(
                    new Segment().initWithPointsN(endCurveDataT[0], endCurveDataT[1], last._handleIn.x, last._handleIn.y, endCurveDataT[2] - endCurveDataT[0], endCurveDataT[3] - endCurveDataT[1])
                )
                segmentArray1.push(
                    new Segment().initWithPointsN(endCurveDataT[6], endCurveDataT[7], endCurveDataT[4] - endCurveDataT[6], endCurveDataT[5] - endCurveDataT[7], 0, 0)
                )

                pathArray.push(new BezierPath().setSegments(segmentArray1))
            }
        } else {
            const segmentArray1 = []
            if (startIndex === endIndex) {
                const curveData = curves[startIndex].getValues()
                const curveDataT = CubicBez.getPart(curveData, CubicBez.getTimeAt(curveData, offsetInStartCurve), CubicBez.getTimeAt(curveData, offsetInEndCurve))
                segmentArray1.push(
                    new Segment().initWithPointsN(curveDataT[0], curveDataT[1], curveDataT[0] - curveDataT[2], curveDataT[1] - curveDataT[3], curveDataT[2] - curveDataT[0], curveDataT[3] - curveDataT[1]),
                    new Segment().initWithPointsN(curveDataT[6], curveDataT[7], curveDataT[4] - curveDataT[6], curveDataT[5] - curveDataT[7], 0, 0)
                )
            } else {
                const startCurveData = curves[startIndex].getValues()
                const endCurveData = curves[endIndex].getValues()
                const startCurveDataT = CubicBez.subdivide(startCurveData, CubicBez.getTimeAt(startCurveData, offsetInStartCurve))[1]
                const endCurveDataT = CubicBez.subdivide(endCurveData, CubicBez.getTimeAt(endCurveData, offsetInEndCurve))[0]
                // 1. add starting curve
                segmentArray1.push(
                    new Segment().initWithPointsN(startCurveDataT[0], startCurveDataT[1], startCurveDataT[0] - startCurveDataT[2], startCurveDataT[1] - startCurveDataT[3], startCurveDataT[2] - startCurveDataT[0], startCurveDataT[3] - startCurveDataT[1]),
                    new Segment().initWithPointsN(startCurveDataT[6], startCurveDataT[7], startCurveDataT[4] - startCurveDataT[6], startCurveDataT[5] - startCurveDataT[7], 0, 0)
                )
                // 2. add middle curves
                let isSecondCurve = true
                for (let i = startIndex + 1; i <= endIndex - 1; i++) {
                    if (isSecondCurve) {
                        curves[i]._segment1._handleIn = segmentArray1.pop()._handleIn
                        segmentArray1.push(curves[i]._segment1)
                        isSecondCurve = false
                    }
                    segmentArray1.push(curves[i]._segment2)
                }
                // 3. add ending curve
                const last = segmentArray1.pop()
                segmentArray1.push(
                    new Segment().initWithPointsN(endCurveDataT[0], endCurveDataT[1], last._handleIn.x, last._handleIn.y, endCurveDataT[2] - endCurveDataT[0], endCurveDataT[3] - endCurveDataT[1])
                )
                // NOTE: the _handleOut of the last segment must be set
                segmentArray1.push(
                    new Segment().initWithPointsN(endCurveDataT[6], endCurveDataT[7], endCurveDataT[4] - endCurveDataT[6], endCurveDataT[5] - endCurveDataT[7], endCurveDataT[6] - endCurveDataT[4], endCurveDataT[7] - endCurveDataT[5])
                )
            }
            // 5. finish the paths
            pathArray.push(new BezierPath().setSegments(segmentArray1))
        }

        return pathArray
    },

    /**
     * @param {number} x
     * @param {number} y
     */
    moveTo(x, y) {
        if (this._segments.length === 1) {
            this.removeSegment(0)
        }
        if (this._segments.length === 0) {
            this._add([
                new Segment().initN(x, y)
            ])
        }
        return this
    },

    /**
     * @param {number} x
     * @param {number} y
     */
    lineTo(x, y) {
        this._add([
            new Segment().initN(x, y)
        ])
    },

    /**
     * @param {number} h1x
     * @param {number} h1y
     * @param {number} h2x
     * @param {number} h2y
     * @param {number} x
     * @param {number} y
     */
    cubicCurveTo(h1x, h1y, h2x, h2y, x, y) {
        const current = this.getLastSegment()
        // Convert to relative values:
        current.setHandleOut(new Vector2(h1x, h1y).subtract(current._point))
        // And add the new segment, with handleIn set to c2
        this._add([
            new Segment().initWithPointsN(x, y, h2x - x, h2y - y)
        ])
    },

    /**
     * @param {number} hx
     * @param {number} hy
     * @param {number} x
     * @param {number} y
     */
    quadraticCurveTo(hx, hy, x, y) {
        const current = this.getLastSegment()._point
        const handle = new Vector2(hx, hy)
        const to = new Vector2(x, y)
        const p1 = handle.clone().add(current.clone().subtract(handle).multiply(1/3, 1/3))
        const p2 = handle.clone().add(to.clone().subtract(handle).multiply(1/3, 1/3))
        const p3 = to
        this.cubicCurveTo(
            p1.x, p1.y,
            p2.x, p2.y,
            p3.x, p3.y
        )
    },

    /**
     * @param {number} px
     * @param {number} py
     * @param {{ width: number, height: number }} radius
     * @param {number} rotation
     * @param {number} clockwise
     * @param {number} large
     */
    arcTo(px, py, radius, rotation, clockwise, large) {
        const to = new Vector2(px, py)
        rotation *= Math.PI / 180
        /** @type {Segment} */
        const current = this.getLastSegment()
        const from = current._point

        // Peek at next value to see if it's clockwise, with true as the default value.
        // peek = Base.peek(args),
        // clockwise = Base.pick(peek, true),
        // We're handling three different approaches to drawing arcs in one

        // #3: arcTo(to, radius, rotation, clockwise, large)
        // Draw arc in SVG style, but only if `from` and `to` are not equal (#1613).
        const isZero = Numerical.isZero
        // If rx = 0 or ry = 0 then this arc is treated as a
        // straight line joining the endpoints.
        // NOTE: radius.isZero() would require both values to be 0.
        if (isZero(radius.width) || isZero(radius.height)) {
            return this.lineTo(to.x, to.y)
        }
        // See for an explanation of the following calculations:
        // https://www.w3.org/TR/SVG/implnote.html#ArcImplementationNotes
        // var rotation = Base.read(args),
        // clockwise = !!Base.read(args),
        // large = !!Base.read(args),
        const middle = from.clone().add(to.x, to.y).divide(2, 2)
        const _pt = from.clone().subtract(middle.x, middle.y).rotate(-rotation)
        const x = _pt.x
        const y = _pt.y
        let rx = abs(radius.width)
        let ry = abs(radius.height)
        let rxSq = rx * rx
        let rySq = ry * ry
        const xSq = x * x
        const ySq = y * y
        // "...ensure radii are large enough"
        let factor = sqrt(xSq / rxSq + ySq / rySq)
        if (factor > 1) {
            rx *= factor
            ry *= factor
            rxSq = rx * rx
            rySq = ry * ry
        }
        factor = (rxSq * rySq - rxSq * ySq - rySq * xSq) /
                (rxSq * ySq + rySq * xSq)
        if (abs(factor) < EPSILON) {
            factor = 0
        }
        if (factor < 0) {
            throw new Error('Cannot create an arc with the given arguments')
        }
        const m = (large === clockwise ? -1 : 1) * sqrt(factor)
        const center = new Vector2(rx * y / ry, -ry * x / rx)
            // "...where the + sign is chosen if fA != fS,
            // and the - sign is chosen if fA = fS."
            .multiply(m, m)
            .rotate(rotation).clone().add(middle)
        // Now create a matrix that maps the unit circle to the ellipse,
        // for easier construction below.
        const transform = new Transform2D().translate(center.x, center.y).rotate_basis(rotation).scale_basis(rx, ry)
        // Transform from and to to the unit circle coordinate space
        // and calculate start vector and extend from there.
        const vector = new Vector2()
        new Transform2D().copy(transform).affine_inverse().xform(from, vector)
        const vector2 = new Vector2()
        new Transform2D().copy(transform).affine_inverse().xform(to, vector2)
        let extent = vector.angle_to(vector2) * 180 / Math.PI
        // "...if fS = 0 and extent is > 0, then subtract 360, whereas
        // if fS = 1 and extend is < 0, then add 360."
        if (!clockwise && extent > 0) {
            extent -= 360
        } else if (clockwise && extent < 0) {
            extent += 360
        }
        if (extent) {
            const epsilon = ANGULAR_EPSILON,
                ext = abs(extent),
                // Calculate amount of segments required to approximate over
                // `extend` degrees (extend / 90), but prevent ceil() from
                // rounding up small imprecisions by subtracting epsilon.
                count = ext >= 360
                    ? 4
                    : Math.ceil((ext - epsilon) / 90),
                inc = extent / count,
                half = inc * Math.PI / 360,
                z = 4 / 3 * Math.sin(half) / (1 + Math.cos(half)),
                segments = []
            let pt = new Vector2()
            for (let i = 0; i <= count; i++) {
                // Explicitly use to point for last segment, since depending
                // on values the calculation adds imprecision:
                pt.copy(to)
                let out = null
                if (i < count) {
                    out = vector.clone().rotate(90 * Math.PI / 180).multiply(z, z)
                    if (transform) {
                        transform.xform(vector, pt)
                        transform.xform(vector.clone().add(out), out)
                        out.subtract(pt)
                    } else {
                        pt = center.clone().add(vector)
                    }
                }
                // eslint-disable-next-line no-negated-condition
                if (!i) {
                    // Modify startSegment
                    current.setHandleOut(out)
                } else {
                    // Add new Segment
                    const _in = vector.clone().rotate(-90 * Math.PI / 180).multiply(z, z)
                    if (transform) {
                        transform.xform(vector.clone().add(_in), _in)
                        _in.subtract(pt)
                    }
                    segments.push(new Segment().initWithPoints(pt, _in, out))
                }
                vector.rotate(inc * Math.PI / 180)
            }
            // Add all segments at once at the end for higher performance
            this._add(segments)
        }
    },
}

/**
 * @param {Segment[]} segments
 * @param {boolean} closed
 */
BezierPath.createPath = (segments, closed) => {
    const path = new BezierPath()
    path._add(segments)
    // FIXME: `changed()` is ignored in out port
    path._closed = closed
    return path
}

/**
 * @param {number} x
 * @param {number} y
 * @param {number} w
 * @param {number} h
 */
BezierPath.makeRectN = (x, y, w, h) => {
    const w_2 = w / 2, h_2 = h / 2
    // const w_3 = w / 3, h_3 = h / 3
    return BezierPath.createPath([
        new Segment().initWithPointsN(x - w_2, y + h_2, 0, 0, 0, 0),
        new Segment().initWithPointsN(x - w_2, y - h_2, 0, 0, 0, 0),
        new Segment().initWithPointsN(x + w_2, y - h_2, 0, 0, 0, 0),
        new Segment().initWithPointsN(x + w_2, y + h_2, 0, 0, 0, 0),
    ], true).transform(new Transform2D().translate(w_2, h_2))
}

/**
 * @param {number} x
 * @param {number} y
 * @param {number} r
 */
BezierPath.makeCircleN = (x, y, r) => {
    const magic = 0.55228475
    const c_len = r * magic
    return BezierPath.createPath([
        new Segment().initWithPointsN(x - r, y, 0, c_len, 0, -c_len),
        new Segment().initWithPointsN(x, y - r, -c_len, 0, c_len, 0),
        new Segment().initWithPointsN(x + r, y, 0, -c_len, 0, c_len),
        new Segment().initWithPointsN(x, y + r, c_len, 0, -c_len, 0),
    ], true)
}

/**
 * @param {Segment[]} segments
 * @param {boolean} closed
 * @returns {Rect2}
 */
BezierPath.getBounds = (segments, closed) => {
    const first = segments[0]
    // If there are no segments, return "empty" rectangle, just like groups,
    // since #bounds is assumed to never return null.
    if (!first) {
        return new Rect2()
    }
    /** @type {number[]} */
    const coords = new Array(6)
    // Make coordinates for first segment available in prevCoords.
    const prevCoords = first._transformCoordinates(new Array(6))
    // const coords = first._transformCoordinates(new Array(6))
    const min = prevCoords.slice(0, 2) // Start with values of first point
    const max = min.slice() // clone
    const roots = new Array(2)

    for (let i = 1, l = segments.length; i < l; i++) {
        processSegment(segments[i], coords, prevCoords, min, max, roots)
    }
    if (closed) {
        processSegment(first, coords, prevCoords, min, max, roots)
    }
    return new Rect2(min[0], min[1], max[0] - min[0], max[1] - min[1])
}

/**
 * @param {Segment[]} segments
 * @param {boolean} closed
 * @returns {Rect2}
 */
// eslint-disable-next-line no-unused-vars
BezierPath.getHandleBounds = (segments, closed) => {
    const coords = new Array(6)
    let x1 = Infinity, x2 = -x1, y1 = x1, y2 = x2
    for (let i = 0, l = segments.length; i < l; i++) {
        const segment = segments[i]
        segment._transformCoordinates(coords)
        for (let j = 0; j < 6; j += 2) {
            const x = coords[j],
                y = coords[j + 1],
                xn = x,
                xx = x,
                yn = y,
                yx = y
            if (xn < x1) x1 = xn
            if (xx > x2) x2 = xx
            if (yn < y1) y1 = yn
            if (yx > y2) y2 = yx
        }
    }
    return new Rect2(x1, y1, x2 - x1, y2 - y1)
}

export function BezierShape() {
    /** @type {BezierPath[]} */
    this._children = []
    /** @type {Rect2} */
    this._bounds = new Rect2()
    /** @type {number} */
    this.version = 0
    /** @type {Vector2} */
    this._currentPoint = null
}

BezierShape.prototype = {
    constructor: BezierShape,

    /**
     * @param {boolean} absolute
     * @param {number} precision
     */
    toSVG(absolute, precision) {
        let svg = ""
        for (let i = 0, len = this._children.length; i < len; i++) {
            const path = this._children[i]
            svg += path.toSVG(absolute, precision)
        }
        return svg
    },

    toPathData() {
        /** @type {PathData} */
        const pathData = {
            commands: [],
            vertices: [],
        }
        for (let i = 0, len = this._children.length; i < len; i++) {
            const path = this._children[i]
            const data = path.toPathData()
            pathData.commands = pathData.commands.concat(data.commands)
            pathData.vertices = pathData.vertices.concat(data.vertices)
        }
        return pathData
    },

    /**
     * @param {Transform2D} t
     */
    transform(t) {
        for (let i = 0, len = this._children.length; i < len; i++) {
            this._children[i].transform(t)
        }
        return this
    },

    /**
     * @param {BezierShape} shape
     */
    copy(shape) {
        this.setChildren(shape._children)
        this.version = shape.version
        this._bounds = shape._bounds
        return this
    },

    /**
     * @returns {BezierShape}
     */
    clone() {
        return clonePath(this)
    },

    isEmpty() {
        for (let i = 0, len = this._children.length; i < len; i++) {
            if (!this._children[i].isEmpty()) {
                return false
            }
        }
        return true
    },

    remove() {
        return Item_remove(this)
    },

    /**
     * @param {BezierPath} item
     */
    addChild(item) {
        this.insertChild(undefined, item)
        return this
    },

    /**
     * @param {number} index
     * @param {BezierPath} item
     */
    insertChild(index, item) {
        const children = this.insertChildren(index, [item])
        return children[0]
    },

    /**
     * @param {BezierPath[]} items
     */
    addChildren(items) {
        this.insertChildren(this._children.length, items)
        return this
    },

    /**
     * @param {number} index
     * @param {BezierPath[]} items
     */
    insertChildren(index, items) {
        const children = this._children
        if (children && items && items.length > 0) {
            // We need to clone items because it may be an Item#children array.
            // Also, we're removing elements if they don't match _type.
            // eslint-disable-next-line no-param-reassign
            items = items.slice()
            // Remove the items from their parents first, since they might be
            // inserted into their own parents, affecting indices.
            // Use the loop also to filter invalid items.
            /** @type {{ [id: number]: boolean }} */
            const inserted = {}
            for (let i = items.length - 1; i >= 0; i--) {
                const item = items[i]
                const id = item && item._id
                // If an item was inserted already, it must be included multiple
                // times in the items array. Only insert once.
                if (!item || inserted[id]) {
                    items.splice(i, 1)
                } else {
                    item._remove()
                    inserted[id] = true
                }
            }
            Base_splice(children, items, index, 0)
            for (let i = 0, l = items.length; i < l; i++) {
                const item = items[i]
                item._parent = this
            }
        } else {
            // eslint-disable-next-line no-param-reassign
            items = null
        }
        return items
    },

    /**
     * @param {boolean} simplify
     */
    reduce(simplify = false) {
        for (let i = this._children.length - 1; i >= 0; i--) {
            const path = this._children[i].reduce(simplify)
            if (path.isEmpty()) {
                path._remove()
            }
        }
        if (!this._children.length) { // Replace with a empty BezierShape
            const path = new BezierShape()
            this.remove()
            return path
        }

        return this
    },

    getIntersections(path, filterer) {
        return getIntersections(this, path, filterer)
    },

    resolveCrossings() {
        return resolveCrossings(this)
    },

    reorient(isNonZero, isClockwise) {
        return reorient(this, isNonZero, isClockwise)
    },

    /**
     * @param {BezierPath[]} items
     */
    setChildren(items) {
        this.removeChildren()
        this.addChildren(items)
    },

    /**
     * @param {number} start
     * @param {number} end
     */
    removeChildren(start, end) {
        if (!this._children) return null
        // eslint-disable-next-line no-param-reassign
        start = start || 0
        // eslint-disable-next-line no-negated-condition, no-param-reassign
        end = (end !== undefined ? end : this._children.length)
        // Use Base.splice(), which adjusts #_index for the items above, and
        // deletes it for the removed items. Calling #_remove() afterwards is
        // fine, since it only calls Base.splice() if #_index is set.
        const removed = Base_splice(this._children, null, start, end - start)
        for (let i = removed.length - 1; i >= 0; i--) {
            removed[i]._remove()
        }
        return removed
    },

    /**
     * Specifies whether the path as a whole is oriented clock-wise, by looking
     * at the path's area.
     * Note that self-intersecting paths and sub-paths of different orientation
     * can result in areas that cancel each other out.
     */
    isClockwise() {
        return this.getArea() >= 0
    },

    /**
     * @param {boolean} clockwise
     */
    setClockwise(clockwise) {
        if (this.isClockwise() !== (!!clockwise)) {
            this.reverse()
        }
    },

    /**
     * @returns {number}
     */
    getLength() {
        const children = this._children
        let length = 0
        for (let i = 0, l = children.length; i < l; i++) {
            length += children[i].getLength()
        }
        return length
    },

    /**
     * The area that the compound-path's geometry is covering, calculated by
     * getting the area of each sub-path and it adding up.
     * Note that self-intersecting paths and sub-paths of different orientation
     * can result in areas that cancel each other out.
     */
    getArea() {
        const children = this._children
        let area = 0
        for (let i = 0, l = children.length; i < l; i++) {
            area += children[i].getArea()
        }
        return area
    },

    /**
     * All the curves contained within the compound-path, from all its child items.
     */
    getCurves() {
        const children = this._children
        /** @type {CubicBez[]} */
        const curves = []
        for (let i = 0, l = children.length; i < l; i++) {
            Base_push(curves, children[i].getCurves())
        }
        return curves
    },

    getFillRule() {
        return 'nonzero'
    },

    reverse() {
        const children = this._children
        for (let i = 0, l = children.length; i < l; i++) {
            children[i].reverse()
        }
    },

    getInteriorPoint() {
        return getInteriorPoint(this)
    },

    /**
     * @param {Vector2} point
     */
    _contains(point) {
        const winding = point.isInside(this.getBounds())
            ? this._getWinding(point)
            : {}
        return winding.onPath || !!(
            this.getFillRule() === 'evenodd'
                ? winding.windingL & 1 || winding.windingR & 1
                : winding.winding
        )
    },

    /**
     * @param {Vector2Like} point
     */
    contains(point) {
        return !!this._contains(point)
    },

    /**
     * Gets the combined bounds of all specified items.
     * @returns {Rect2}
     */
    getBounds() {
        const path = this.toPathData()
        this._bounds.set(0, 0, 0, 0)
        const { commands, vertices } = path

        let minX = Number.MAX_SAFE_INTEGER, maxX = Number.MIN_SAFE_INTEGER
        let minY = Number.MAX_SAFE_INTEGER, maxY = Number.MIN_SAFE_INTEGER

        let i = 0
        let x1 = 0, y1 = 0
        let x2 = 0, y2 = 0
        let cx = 0, cy = 0
        let c1x = 0, c1y = 0
        let c2x = 0, c2y = 0

        const end = () => {
            const tmp = new Rect2(minX, minY, maxX - minX, maxY - minY)
            this._bounds.merge_with(tmp)

            minX = Number.MAX_SAFE_INTEGER
            maxX = Number.MIN_SAFE_INTEGER
            minY = Number.MAX_SAFE_INTEGER
            maxY = Number.MIN_SAFE_INTEGER
        }

        // calc bounds and fill polygon
        for (const command of commands) {
            x1 = x2
            y1 = y2

            switch (command) {
                case Command.M: {
                    x1 = vertices[i++]
                    y1 = vertices[i++]
                    x2 = x1
                    y2 = y1

                    minX = Math.min(minX, x1)
                    minY = Math.min(minY, y1)
                    maxX = Math.max(maxX, x1)
                    maxY = Math.max(maxY, y1)

                    if (i === 2) {
                        this._bounds.set(x1, y1, 0, 0)
                    } else {
                        end()
                    }
                } break
                case Command.L: {
                    x2 = vertices[i++]
                    y2 = vertices[i++]

                    minX = Math.min(minX, x2)
                    minY = Math.min(minY, y2)
                    maxX = Math.max(maxX, x2)
                    maxY = Math.max(maxY, y2)
                } break
                case Command.Q: {
                    cx = vertices[i++]
                    cy = vertices[i++]
                    x2 = vertices[i++]
                    y2 = vertices[i++]

                    const bounds = new QuadBez().initN(x1, y1, cx, cy, x2, y2).getBounds()
                    minX = Math.min(minX, bounds.x)
                    minY = Math.min(minY, bounds.y)
                    maxX = Math.max(maxX, bounds.x + bounds.width)
                    maxY = Math.max(maxY, bounds.y + bounds.height)
                } break
                case Command.C: {
                    c1x = vertices[i++]
                    c1y = vertices[i++]
                    c2x = vertices[i++]
                    c2y = vertices[i++]
                    x2 = vertices[i++]
                    y2 = vertices[i++]

                    const curve = new CubicBez().initWith4PointsN(x1, y1, c1x, c1y, c2x, c2y, x2, y2)
                    const bounds = curve.getBounds()
                    minX = Math.min(minX, bounds.x)
                    minY = Math.min(minY, bounds.y)
                    maxX = Math.max(maxX, bounds.x + bounds.width)
                    maxY = Math.max(maxY, bounds.y + bounds.height)
                } break
            }
        }

        if (i > 2) {
            end()
        }

        return this._bounds
    },

    _getWinding(point, dir, closed) {
        return getWinding(point, this.getCurves(), dir, closed)
    },

    /** boolean */

    /**
     * @param {BezierShape} path
     * @returns {BezierShape}
     */
    union(path) {
        return traceBoolean(this, path, BooleanOperation.UNION)
    },

    /**
     * @param {BezierShape} path
     * @returns {BezierShape}
     */
    intersect(path) {
        return traceBoolean(this, path, BooleanOperation.INTERSECT)
    },

    /**
     * @param {BezierShape} path
     * @returns {BezierShape}
     */
    subtract(path) {
        return traceBoolean(this, path, BooleanOperation.SUBTRACT)
    },

    /**
     * @param {BezierShape} path
     * @returns {BezierShape}
     */
    difference(path) {
        return traceBoolean(this, path, BooleanOperation.DIFFERENCE)
    },
    exclude(path) {
        return this.difference(path)
    },

    /** trim path */

    /**
     * @param {number} start
     * @param {number} end
     * @param {number} offset
     * @param {TrimPathMode} mode
     * @returns {BezierShape}
     */
    trim(start, end, offset, mode) {
        // no contours
        if (this._children.length === 0) {
            return this
        }

        const [s, e, isTrimEndWrapped] = processTrimValues(start, end, offset)

        // all trimmed out
        if (areNumbersClose(s, e) && !isTrimEndWrapped) {
            this._children = []
            this._bounds = null
            return this
        }

        switch (mode) {
            case TrimPathMode.SIMULTANEOUSLY:
                return this._trimSimultaneously(s, e, isTrimEndWrapped)
            case TrimPathMode.INDIVIDUALLY:
            default:
                return this._trimIndividually(s, e, isTrimEndWrapped)
        }
    },

    /**
     * @param {number} start
     * @param {number} end
     * @param {number} offset
     * @param {number} mode
     * @returns {BezierShape}
     */
    trimmed(start, end, offset, mode) {
        return this.clone().trim(start, end, offset, mode)
    },

    /**
     * @param {number} s start
     * @param {number} e end
     * @param {boolean} [isTrimEndWrapped = false]
     * @returns {BezierShape}
     */
    // eslint-disable-next-line no-unused-vars
    _trimIndividually(s, e, isTrimEndWrapped = false) {
        if (this._children.length === 1) {
            this._children = this._children[0].trim(s, e, isTrimEndWrapped)
            this.reduce()
            return this
        }
        const lengths = []
        const indices = []
        let totalLength = 0
        for (let j = 0; j < this._children.length; j++) {
            const path = this._children[j]
            const curves = path.getCurves()
            for (let i = 0; i < curves.length; i++) {
                const len = curves[i].getLength()
                totalLength += len
                lengths.push(len)
                indices.push([j, i])
            }
        }
        const startOffset = s * totalLength
        const endOffset = e * totalLength
        let startIndex = 0, endIndex = 0, offsetInStartCurve = 0, offsetInEndCurve = 0, currOffset = 0
        for (let i = 0; i < lengths.length; i++) {
            if (currOffset < startOffset) {
                startIndex = i
                offsetInStartCurve = startOffset - currOffset
            }
            if (currOffset < endOffset) {
                endIndex = i
                offsetInEndCurve = endOffset - currOffset
            }
            if (currOffset >= startOffset && currOffset >= endOffset) {
                break
            }
            currOffset += lengths[i]
        }

        const paths = this._children
        this._children = []
        if (isTrimEndWrapped) {
            const [startPathIdx, startCurveIdx] = indices[startIndex]
            const [endPathIdx, endCurveIdx] = indices[endIndex]
            const startCurveData = paths[startPathIdx].getCurves()[startCurveIdx].getValues()
            const endCurveData = paths[endPathIdx].getCurves()[endCurveIdx].getValues()
            const startCurveDataT = CubicBez.subdivide(startCurveData, CubicBez.getTimeAt(startCurveData, offsetInStartCurve))[1]
            const endCurveDataT = CubicBez.subdivide(endCurveData, CubicBez.getTimeAt(endCurveData, offsetInEndCurve))[0]

            let currPathIdx = startPathIdx, currSegmentArray = []
            // 1. add starting curve
            currSegmentArray.push(
                new Segment().initWithPointsN(startCurveDataT[0], startCurveDataT[1], startCurveDataT[0] - startCurveDataT[2], startCurveDataT[1] - startCurveDataT[3], startCurveDataT[2] - startCurveDataT[0], startCurveDataT[3] - startCurveDataT[1]),
                new Segment().initWithPointsN(startCurveDataT[6], startCurveDataT[7], startCurveDataT[4] - startCurveDataT[6], startCurveDataT[5] - startCurveDataT[7], 0, 0)
            )
            // 2. add middle curves
            let isSecondCurve = true
            for (let i = startIndex + 1; i <= lengths.length + endIndex - 1; i++) {
                const [pathIdx, curveIdx] = indices[i % lengths.length]
                const curve = paths[pathIdx].getCurves()[curveIdx]
                // eslint-disable-next-line no-negated-condition
                if (currPathIdx !== pathIdx) {
                    // finish the last path
                    this.addChild(BezierPath.createPath(currSegmentArray, false))
                    // prepare for the new path
                    currPathIdx = pathIdx
                    currSegmentArray = [
                        curve._segment1
                    ]
                } else {
                    if (isSecondCurve) {
                        curve._segment1._handleIn = currSegmentArray.pop()._handleIn
                        currSegmentArray.push(curve._segment1)
                        isSecondCurve = false
                    }
                }
                currSegmentArray.push(curve._segment2)
            }
            // 3. add ending curve
            if (currPathIdx !== endPathIdx) {
                // finish the last path
                this.addChild(BezierPath.createPath(currSegmentArray, false))
                // prepare for the new path
                currPathIdx = endPathIdx
                currSegmentArray = [
                    new Segment().initWithPointsN(endCurveDataT[0], endCurveDataT[1], 0, 0, endCurveDataT[2] - endCurveDataT[0], endCurveDataT[3] - endCurveDataT[1])
                ]
            }
            const last = currSegmentArray.pop()
            currSegmentArray.push(
                new Segment().initWithPointsN(endCurveDataT[0], endCurveDataT[1], last._handleIn.x, last._handleIn.y, endCurveDataT[2] - endCurveDataT[0], endCurveDataT[3] - endCurveDataT[1])
            )
            // NOTE: the _handleOut of the last segment must be set
            currSegmentArray.push(
                new Segment().initWithPointsN(endCurveDataT[6], endCurveDataT[7], endCurveDataT[4] - endCurveDataT[6], endCurveDataT[5] - endCurveDataT[7], endCurveDataT[6] - endCurveDataT[4], endCurveDataT[7] - endCurveDataT[5])
            )
            // 4. finish the last path
            this.addChild(BezierPath.createPath(currSegmentArray, false))
        } else {
            if (startIndex === endIndex) {
                const [pathIdx, curveIdx] = indices[startIndex]
                const curveData = paths[pathIdx].getCurves()[curveIdx].getValues()
                const curveDataT = CubicBez.getPart(curveData, CubicBez.getTimeAt(curveData, offsetInStartCurve), CubicBez.getTimeAt(curveData, offsetInEndCurve))
                this.addChild(BezierPath.createPath([
                    new Segment().initWithPointsN(curveDataT[0], curveDataT[1], 0, 0, curveDataT[2] - curveDataT[0], curveDataT[3] - curveDataT[1]),
                    new Segment().initWithPointsN(curveDataT[6], curveDataT[7], curveDataT[4] - curveDataT[6], curveDataT[5] - curveDataT[7], 0, 0)
                ], false))
            } else {
                const [startPathIdx, startCurveIdx] = indices[startIndex]
                const [endPathIdx, endCurveIdx] = indices[endIndex]
                const startCurveData = paths[startPathIdx].getCurves()[startCurveIdx].getValues()
                const endCurveData = paths[endPathIdx].getCurves()[endCurveIdx].getValues()
                const startCurveDataT = CubicBez.subdivide(startCurveData, CubicBez.getTimeAt(startCurveData, offsetInStartCurve))[1]
                const endCurveDataT = CubicBez.subdivide(endCurveData, CubicBez.getTimeAt(endCurveData, offsetInEndCurve))[0]

                let currPathIdx = startPathIdx, currSegmentArray = []
                // 1. add starting curve
                currSegmentArray.push(
                    new Segment().initWithPointsN(startCurveDataT[0], startCurveDataT[1], startCurveData[0] - startCurveData[2], startCurveData[1] - startCurveData[3], startCurveDataT[2] - startCurveDataT[0], startCurveDataT[3] - startCurveDataT[1]),
                    new Segment().initWithPointsN(startCurveDataT[6], startCurveDataT[7], startCurveDataT[4] - startCurveDataT[6], startCurveDataT[5] - startCurveDataT[7], 0, 0),
                )
                // 2. add middle curves
                let isSecondCurve = true
                for (let i = startIndex + 1; i < endIndex; i++) {
                    const [pathIdx, curveIdx] = indices[i % lengths.length]
                    const curve = paths[pathIdx].getCurves()[curveIdx]
                    // eslint-disable-next-line no-negated-condition
                    if (currPathIdx !== pathIdx) {
                        // finish the last path
                        this.addChild(BezierPath.createPath(currSegmentArray, false))
                        // prepare for the new path
                        currPathIdx = pathIdx
                        currSegmentArray = [
                            curve._segment1
                        ]
                    } else {
                        if (isSecondCurve) {
                            curve._segment1._handleIn = currSegmentArray.pop()._handleIn
                            currSegmentArray.push(curve._segment1)
                            isSecondCurve = false
                        }
                    }
                    currSegmentArray.push(curve._segment2)
                }
                // 3. add ending curve
                if (currPathIdx !== endPathIdx) {
                    // finish the last path
                    this.addChild(BezierPath.createPath(currSegmentArray, false))
                    // prepare for the new path
                    currPathIdx = endPathIdx
                    currSegmentArray = [
                        new Segment().initWithPointsN(endCurveDataT[0], endCurveDataT[1], 0, 0, endCurveDataT[2] - endCurveDataT[0], endCurveDataT[3] - endCurveDataT[1])
                    ]
                }
                const last = currSegmentArray.pop()
                currSegmentArray.push(
                    new Segment().initWithPointsN(endCurveDataT[0], endCurveDataT[1], last._handleIn.x, last._handleIn.y, endCurveDataT[2] - endCurveDataT[0], endCurveDataT[3] - endCurveDataT[1])
                )
                // NOTE: the _handleOut of the last segment must be set
                currSegmentArray.push(
                    new Segment().initWithPointsN(endCurveDataT[6], endCurveDataT[7], endCurveDataT[4] - endCurveDataT[6], endCurveDataT[5] - endCurveDataT[7], endCurveDataT[6] - endCurveDataT[4], endCurveDataT[7] - endCurveDataT[5])
                )
                // 4. finish the last path
                this.addChild(BezierPath.createPath(currSegmentArray, false))
            }
        }

        this.reduce()
        return this
    },

    /**
     * @param {number} s start
     * @param {number} e end
     * @param {boolean} [isTrimEndWrapped = false]
     * @returns {BezierPath}
     */
    _trimSimultaneously(s, e, isTrimEndWrapped = false) {
        this._children = this._children.flatMap(path =>
            // TODO: might need to update _closed for the trimmed paths
            path.trim(s, e, isTrimEndWrapped)
        )
        this.reduce()
        return this
    },

    /** drawing */

    /**
     * @param {number} x
     * @param {number} y
     */
    moveTo(x, y) {
        return this.addChild(
            BezierPath.createPath([
                new Segment().initWithPointsN(x, y, 0, 0, 0, 0),
            ]),
            false
        )
    },

    /**
     * @param {number} x
     * @param {number} y
     */
    lineTo(x, y) {
        this.getCurrentPath()
            .lineTo(x, y)
        return this
    },

    /**
     * @param {number} hx
     * @param {number} hy
     * @param {number} x
     * @param {number} y
     */
    quadraticCurveTo(hx, hy, x, y) {
        this.getCurrentPath()
            .quadraticCurveTo(hx, hy, x, y)
        return this
    },

    /**
     * @param {number} h1x
     * @param {number} h1y
     * @param {number} h2x
     * @param {number} h2y
     * @param {number} x
     * @param {number} y
     */
    cubicCurveTo(h1x, h1y, h2x, h2y, x, y) {
        this.getCurrentPath()
            .cubicCurveTo(h1x, h1y, h2x, h2y, x, y)
        return this
    },

    /**
     * @param {number} px
     * @param {number} py
     * @param {{ width: number, height: number }} radius
     * @param {number} rotation
     * @param {number} clockwise
     * @param {number} large
     */
    arcTo(px, py, radius, rotation, clockwise, large) {
        this.getCurrentPath()
            .arcTo(px, py, radius, rotation, clockwise, large)
        return this
    },

    /**
     * @param {number} tolerance
     * @returns {BezierShape}
     */
    close(tolerance = 0) {
        const path = this.getCurrentPath()
        path.close(tolerance)
        return this
    },

    /**
     * @returns {BezierPath}
     */
    getFirstPath() {
        if (this._children.length === 0) {
            return null
        }
        return this._children[0]
    },
    /**
     * @returns {BezierPath}
     */
    getCurrentPath() {
        if (this._children.length === 0) {
            return null
        }
        return this._children[this._children.length - 1]
    },
}

/**
 * @returns {BezierShape}
 */
BezierShape.create = () => {
    const path = new BezierShape()
    return path
}

/**
 * @param {BezierShape} compundPath
 */
BezierShape.destroy = (compundPath) => {
    compundPath._children.length = 0
    compundPath.version = 0
    compundPath._bounds = null
}

/**
 * @param {PathData} pathData
 * @returns {BezierShape}
 */
BezierShape.createFromPathData = (pathData) => {
    const { commands, vertices } = pathData

    const shape = new BezierShape()

    let prev_cmd = Command.M
    const p1 = new Vector2()
    const p2 = new Vector2()
    const p3 = new Vector2()

    // First clear the previous content
    shape.removeChildren()

    for (let i = 0, l = commands.length, vi = 0; i < l; i++) {
        const command = commands[i]
        // Fix issues with z in the middle of path data, not followed by a m command
        if (prev_cmd === Command.Z && !(command === Command.M || command === Command.Z)) {
            shape.moveTo(p3.x, p3.y)
        }
        switch (command) {
            case Command.M: {
                p3.x = vertices[vi++]
                p3.y = vertices[vi++]
                shape.moveTo(p3.x, p3.y)
                break
            }
            case Command.L: {
                p3.x = vertices[vi++]
                p3.y = vertices[vi++]
                shape.lineTo(p3.x, p3.y)
                break
            }
            case Command.Q: {
                p1.x = vertices[vi++]
                p1.y = vertices[vi++]
                p3.x = vertices[vi++]
                p3.y = vertices[vi++]
                shape.quadraticCurveTo(p1.x, p1.y, p3.x, p3.y)
                break
            }
            case Command.C: {
                p1.x = vertices[vi++]
                p1.y = vertices[vi++]
                p2.x = vertices[vi++]
                p2.y = vertices[vi++]
                p3.x = vertices[vi++]
                p3.y = vertices[vi++]
                shape.cubicCurveTo(p1.x, p1.y, p2.x, p2.y, p3.x, p3.y)
                break
            }
            case Command.Z: {
                // FIXME: do we need to pass tolerance?
                shape.close(EPSILON)
                break
            }
        }
        prev_cmd = command
    }

    return shape
}

/**
 * @param {string} data SVG style path data
 */
BezierShape.createFromSVG = (data) => {
    const shape = new BezierShape()
    // NOTE: #getPathData() is defined in CompoundPath / Path
    // This is a very compact SVG Path Data parser that works both for Path
    // and CompoundPath.

    // First split the path data into parts of command-coordinates pairs
    // Commands are any of these characters: mzlhvcsqta
    const parts = data && data.match(/[mlhvcsqtaz][^mlhvcsqtaz]*/ig)
    let coords
    let relative = false
    let previous
    let control
    let current = new Vector2()
    let start = new Vector2()

    /**
     * @param index
     * @param coord
     */
    function getCoord(index, coord) {
        let val = +coords[index]
        if (relative) {
            val += current[coord]
        }
        return val
    }

    /**
     * @param index
     */
    function getPoint(index) {
        return new Vector2(
            getCoord(index, 'x'),
            getCoord(index + 1, 'y')
        )
    }

    // First clear the previous content
    shape.removeChildren()

    for (let i = 0, l = parts && parts.length; i < l; i++) {
        const part = parts[i]
        const command = part[0]
        const lower = command.toLowerCase()
        // Match all coordinate values
        coords = part.match(/[+-]?(?:\d*\.\d+|\d+\.?)(?:[eE][+-]?\d+)?/g)
        const length = coords && coords.length
        relative = command === lower
        // Fix issues with z in the middle of SVG path data, not followed by
        // a m command, see #413:
        if (previous === 'z' && !/[mz]/.test(lower)) {
            shape.moveTo(current.x, current.y)
        }
        switch (lower) {
            case 'm':
            case 'l':
                let move = (lower === 'm')
                for (let j = 0; j < length; j += 2) {
                    current = getPoint(j)
                    shape[move ? 'moveTo' : 'lineTo'](current.x, current.y)
                    if (move) {
                        start = current
                        move = false
                    }
                }
                control = current
                break
            case 'h':
            case 'v':
                const coord = (lower === 'h' ? 'x' : 'y')
                current = current.clone() // Clone as we're going to modify it.
                for (let j = 0; j < length; j++) {
                    current[coord] = getCoord(j, coord)
                    shape.lineTo(current.x, current.y)
                }
                control = current
                break
            case 'c':
                for (let j = 0; j < length; j += 6) {
                    const p1 = getPoint(j)
                    control = getPoint(j + 2)
                    current = getPoint(j + 4)
                    shape.cubicCurveTo(
                        p1.x, p1.y,
                        control.x, control.y,
                        current.x, current.y
                    )
                }
                break
            case 's':
                // Smooth cubicCurveTo
                for (let j = 0; j < length; j += 4) {
                    const p1 = /[cs]/.test(previous)
                        ? current.multiply(2, 2).subtract(control)
                        : current
                    control = getPoint(j)
                    current = getPoint(j + 2)
                    shape.cubicCurveTo(
                        p1.x, p1.y,
                        control.x, control.y,
                        current.x, current.y
                    )
                    previous = lower
                }
                break
            case 'q':
                for (let j = 0; j < length; j += 4) {
                    control = getPoint(j)
                    current = getPoint(j + 2)
                    shape.quadraticCurveTo(
                        control.x, control.y,
                        current.x, current.y
                    )
                }
                break
            case 't':
                // Smooth quadraticCurveTo
                for (let j = 0; j < length; j += 2) {
                    control = (
                        /[qt]/.test(previous)
                            ? current.multiply(2, 2).subtract(control)
                            : current
                    )
                    current = getPoint(j)
                    shape.quadraticCurveTo(
                        control.x, control.y,
                        current.x, current.y
                    )
                    previous = lower
                }
                break
            case 'a':
                for (let j = 0; j < length; j += 7) {
                    current = getPoint(j + 5)
                    shape.arcTo(
                        current.x, current.y,
                        { width: +coords[j], height: +coords[j + 1] },
                        +coords[j + 2],
                        +coords[j + 4],
                        +coords[j + 3]
                    )
                }
                break
            case 'z':
                // Merge first and last segment with Numerical.EPSILON tolerance
                // to address imprecisions in relative SVG data.
                shape.close(EPSILON)
                // Correctly handle relative m commands, see #1101:
                current = start
                break
        }
        previous = lower
    }

    return shape
}

/**
 * @param {number} start
 * @param {number} end
 * @param {number} offset
 * @param {BezierShape[]} shapeArray
 * @returns {Array<{ start: number, end: number, offset: number }>}
 */
BezierShape.trimMultiple = (start, end, offset, shapeArray) => {
    // no paths
    if (shapeArray.length === 0) {
        return shapeArray
    }

    const [s, e, isTrimEndWrapped] = processTrimValues(start, end, offset)

    // all trimmed out
    if (areNumbersClose(s, e) && !isTrimEndWrapped) {
        return Array(shapeArray.length).fill(new BezierShape())
    }

    let totalLength = 0
    const lengthArray = []
    for (let i = 0; i < shapeArray.length; i++) {
        lengthArray.push(shapeArray[i].getLength())
        totalLength += lengthArray[i]
    }

    const startOffset = s * totalLength
    const endOffset = e * totalLength
    let startIndex = 0, endIndex = 0, offsetInStartShape = 0, offsetInEndShape = 0, currOffset = 0
    for (let i = 0; i < shapeArray.length; i++) {
        if (currOffset < startOffset) {
            startIndex = i
            offsetInStartShape = startOffset - currOffset
        }
        if (currOffset < endOffset) {
            endIndex = i
            offsetInEndShape = endOffset - currOffset
        }
        if (currOffset >= startOffset && currOffset >= endOffset) {
            break
        }
        currOffset += lengthArray[i]
    }

    if (startIndex === endIndex) {
        return lengthArray.map((len, i) => {
            if (i !== startIndex) {
                return isTrimEndWrapped ? shapeArray[i].clone() : new BezierShape()
            }
            return shapeArray[i].clone()._trimIndividually(offsetInStartShape / len, offsetInEndShape / len, isTrimEndWrapped)
        })
    } else {
        return lengthArray.map((len, i) => {
            const outOfTrimRange = isTrimEndWrapped ? i > endIndex && i < startIndex : i < startIndex || i > endIndex
            if (outOfTrimRange) {
                return new BezierShape()
            }
            if (i === startIndex) {
                return shapeArray[i].clone()._trimIndividually(offsetInStartShape / len, 1)
            }
            if (i === endIndex) {
                return shapeArray[i].clone()._trimIndividually(0, offsetInEndShape / len)
            }
            return shapeArray[i]
        })
    }
}

export function CurveLocation() {
    /** @type {number} a value between 0 (beginning of the curve) and 1 (end of the curve) */
    this._time = null
    this._point = new Vector2()
    this._overlap = false
    /** @type {number} */
    this._distance = null
    /** @type {number} */
    this._offset = null
    /** @type {number} */
    this._curveOffset = null

    /** @type {BezierPath} */
    this._path = null
    /** @type {CubicBez} */
    this._curve = null
    /** @type {Segment} */
    this._segment = null
    /** @type {Segment} */
    this._segment1 = null
    /** @type {Segment} */
    this._segment2 = null

    this._version = 0

    /** @type {CurveLocation} */
    this._intersection = null
    /** @type {CurveLocation} */
    this._previous = null
    /** @type {CurveLocation} */
    this._next = null
}
CurveLocation.prototype = {
    constructor: CurveLocation,

    /**
     * @param {CubicBez} curve
     * @param {number} time
     * @param {Vector2} point
     * @param {number} [_overlap]
     * @param {number} [_distance]
     */
    init(curve, time, point, _overlap, _distance) {
        // Merge intersections very close to the end of a curve with the
        // beginning of the next curve.
        if (time >= (1 - CURVETIME_EPSILON)) {
            const next = curve.getNext()
            if (next) {
                time = 0
                curve = next
            }
        }
        this._setCurve(curve)
        this._time = time
        this._point = point || curve.getPointAtTime(time)
        this._overlap = _overlap
        this._distance = _distance
        // Properties related to linked intersection locations
        this._intersection = null
        this._next = null
        this._previous = null
        return this
    },

    getPoint() {
        return this._point
    },

    /**
     * The curve that this location belongs to.
     */
    getCurve() {
        const path = this._path
        if (path && path._version !== this._version) {
            // If the path's segments have changed, clear the cached time and
            // offset values and force re-fetching of the correct curve.
            this._time = null
            this._offset = null
            this._curveOffset = null
            this._curve = null
        }

        return this._curve
            || this.trySegment(this._segment)
            || this.trySegment(this._segment1)
            || this.trySegment(this._segment2.getPrevious())
    },

    /**
     * If path is out of sync, access current curve objects through segment1
     * segment2. Since path splitting or dividing might have happened in
     * the meantime, try segment1's curve, and see if _point lies on it
     * still, otherwise assume it's the curve before segment2.
     * @param {Segment} segment
     */
    trySegment(segment) {
        const curve = segment && segment.getCurve()
        if (curve && (this._time = curve.getTimeOf(this._point)) !== null) {
            // Fetch path again as it could be on a new one through split()
            this._setCurve(curve)
            return curve
        }
    },

    /**
     * The segment of the curve which is closer to the described location.
     */
    getSegment() {
        // Request curve first, so _segment gets invalidated if it's out of sync
        let segment = this._segment
        if (!segment) {
            const curve = this.getCurve()
            const time = this.getTime()
            if (time === 0) {
                segment = curve._segment1
            } else if (time === 1) {
                segment = curve._segment2
            } else if (time != null) {
                // Determine the closest segment by comparing curve lengths
                segment = (curve.getPartLength(0, time) < curve.getPartLength(time, 1))
                    ? curve._segment1
                    : curve._segment2
            }
            this._segment = segment
        }
        return segment
    },

    getPath() {
        const curve = this.getCurve()
        return curve && curve._path
    },

    getIndex() {
        const curve = this.getCurve()
        return curve && curve.getIndex()
    },

    getTime() {
        const curve = this.getCurve()
        const time = this._time
        return curve && time == null
            ? this._time = curve.getTimeOf(this._point)
            : time
    },

    /**
     * The length of the path from its beginning up to the location described
     * by this object. If the curve is not part of a path, then the length
     * within the curve is returned instead.
     */
    getOffset() {
        let offset = this._offset
        if (offset == null) {
            offset = 0
            const path = this.getPath()
            const index = this.getIndex()
            if (path && index != null) {
                const curves = path.getCurves()
                for (let i = 0; i < index; i++) {
                    offset += curves[i].getLength()
                }
            }
            // eslint-disable-next-line no-multi-assign
            this._offset = offset += this.getCurveOffset()
        }
        return offset
    },

    /**
     * The length of the curve from its beginning up to the location described
     * by this object.
     */
    getCurveOffset() {
        let offset = this._curveOffset
        if (offset == null) {
            const curve = this.getCurve()
            const time = this.getTime()
            // eslint-disable-next-line no-multi-assign
            this._curveOffset = offset = (time != null)
                && curve
                && curve.getPartLength(0, time)
        }
        return offset
    },

    getTangent() {
        const curve = this.getCurve()
        const time = this.getTime()
        return time != null && curve && curve.getTangentAt(time, true)
    },

    hasOverlap() {
        return !!this._overlap
    },

    /**
     * Checks if the location is an intersection with another curve and is
     * merely touching the other curve, as opposed to crossing it.
     */
    isTouching() {
        const inter = this._intersection
        if (inter && this.getTangent().isCollinear(inter.getTangent())) {
            // Only consider two straight curves as touching if their lines
            // don't intersect.
            const curve1 = this.getCurve()
            const curve2 = inter.getCurve()
            return !(
                curve1.isStraight()
                &&
                curve2.isStraight()
                &&
                curve1.getLine().intersect(curve2.getLine())
            )
        }
        return false
    },

    isCrossing() {
        // Implementation loosely based on work by Andy Finnell:
        // http://losingfight.com/blog/2011/07/09/how-to-implement-boolean-operations-on-bezier-paths-part-3/
        const inter = this._intersection
        if (!inter) {
            return false
        }
        const t1 = this.getTime()
        const t2 = inter.getTime()
        const tMin = CURVETIME_EPSILON
        const tMax = 1 - tMin
        // t*Inside specifies if the found intersection is inside the curve.
        const t1Inside = t1 >= tMin && t1 <= tMax
        const t2Inside = t2 >= tMin && t2 <= tMax
        // If the intersection is in the middle of both paths, it is either a
        // tangent or a crossing, no need for the detailed corner check below:
        if (t1Inside && t2Inside) {
            return !this.isTouching()
        }
        // Now get the references to the 4 curves involved in the intersection:
        // - c1 & c2 are the curves on the first intersecting path, left and
        //   right of the intersection.
        // - c3 & c4 are the same for the second intersecting path.
        // - If the intersection is in the middle of the curve (t*Inside), then
        //   both values point to the same curve, and the curve-time is to be
        //   handled accordingly further down.
        let c2 = this.getCurve()
        const c1 = c2 && t1 < tMin ? c2.getPrevious() : c2
        let c4 = inter.getCurve()
        const c3 = c4 && t2 < tMin ? c4.getPrevious() : c4
        // If t1 / t2 are at the end, then step to the next curve.
        if (t1 > tMax) {
            c2 = c2.getNext()
        }
        if (t2 > tMax) {
            c4 = c4.getNext()
        }
        if (!c1 || !c2 || !c3 || !c4) {
            return false
        }

        // Calculate unambiguous angles for all 4 tangents at the intersection:
        // - If the intersection is inside a curve (t1 / t2Inside), the tangent
        //   at t1 / t2 is unambiguous, because the curve is continuous.
        // - If the intersection is on a segment, step away at equal offsets on
        //   each curve, to calculate unambiguous angles. The vector from the
        //   intersection to this new location is used to determine the angle.

        /** @type {number[]} */
        const offsets = []

        if (!t1Inside) {
            addOffsets(offsets, c1, true)
            addOffsets(offsets, c2, false)
        }
        if (!t2Inside) {
            addOffsets(offsets, c3, true)
            addOffsets(offsets, c4, false)
        }
        const pt = this.getPoint()
        // Determined the shared unambiguous offset by the taking the
        // shortest offsets on all involved curves that are unambiguous.
        // eslint-disable-next-line prefer-spread
        const offset = Math.min.apply(Math, offsets)
        const v2 = t1Inside
            ? c2.getTangentAtTime(t1)
            : c2.getPointAt(offset)?.clone().subtract(pt)
        const v1 = t1Inside
            ? v2?.clone().negate()
            : c1.getPointAt(-offset)?.clone().subtract(pt)
        const v4 = t2Inside
            ? c4.getTangentAtTime(t2)
            : c4.getPointAt(offset)?.clone().subtract(pt)
        const v3 = t2Inside
            ? v4?.clone().negate()
            : c3.getPointAt(-offset)?.clone().subtract(pt)

        const a1 = v1?.angle() * 180 / Math.PI
        const a2 = v2?.angle() * 180 / Math.PI
        const a3 = v3?.angle() * 180 / Math.PI
        const a4 = v4?.angle() * 180 / Math.PI
        // Count how many times curve2 angles appear between the curve1 angles.
        // If each pair of angles split the other two, then the edges cross.
        // Use t1Inside to decide which angle pair to check against.
        // If t1 is inside the curve, check against a3 & a4, otherwise a1 & a2.
        return !!(t1Inside
            ? (isInRange(a1, a3, a4) ^ isInRange(a2, a3, a4)) &&
                (isInRange(a1, a4, a3) ^ isInRange(a2, a4, a3))
            : (isInRange(a3, a1, a2) ^ isInRange(a4, a1, a2)) &&
                (isInRange(a3, a2, a1) ^ isInRange(a4, a2, a1)))
    },

    /**
     * Checks whether tow CurveLocation objects are describing the same location
     * on a path, by applying the same tolerances as elsewhere when dealing with
     * curve-time parameters.
     *
     * @param {CurveLocation} loc
     * @param {boolean} _ignoreOther true if the locations are equal
     * @returns {boolean}
     */
    equals(loc, _ignoreOther) {
        let res = this === loc
        if (!res && loc instanceof CurveLocation) {
            const c1 = this.getCurve()
            const c2 = loc.getCurve()
            const p1 = c1._path
            const p2 = c2._path
            if (p1 === p2) {
                // instead of comparing curve-time, compare the actual offsets
                // of both locations to determine if they're in the same spot,
                // taking into account the wrapping around path ends. This is
                // necessary to avoid issues wit CURVETIME_EPSILON imprecisions.
                const epsilon = GEOMETRIC_EPSILON
                const diff = abs(this.getOffset() - loc.getOffset())
                const i1 = !_ignoreOther && this._intersection
                const i2 = !_ignoreOther && loc._intersection
                res = (
                    diff < epsilon
                    ||
                    p1 && abs(p1.getLength() - diff) < epsilon
                )
                // Compare the the other location, but prevent endless
                // recursion by passing `true` for _ignoreOther.
                &&
                (
                    !i1 && !i2 || i1 && i2 && i1.equals(i2, true)
                )
            }
        }
        return res
    },

    /**
     * @param {Segment} segment
     */
    _setSegment(segment) {
        const curve = segment.getCurve()
        if (curve) {
            this._setCurve(curve)
        } else {
            this._setPath(segment._path)
            this._segment1 = segment
            this._segment2 = null
        }
        this._segment = segment
        this._time = segment === this._segment1 ? 0 : 1
        // To avoid issues with imprecision in getCurve() / trySegment()
        this._point = segment._point.clone()
    },

    /**
     * @param {BezierPath} path
     */
    _setPath(path) {
        // We only store the path to verify versions for cached values.
        // To ensure we use the right path (e.g. after splitting), we shall
        // always access the path on the result of getCurve().
        this._path = path
        this._version = path ? path._version : 0
    },
    /**
     * @param {CubicBez} curve
     */
    _setCurve(curve) {
        this._setPath(curve._path)
        this._curve = curve
        this._segment = null // To be determined, see #getSegment()
        // Also store references to segment1 and segment2, in case path
        // splitting / dividing is going to happen, in which case the segments
        // can be used to determine the new curves, see #getCurve(true)
        this._segment1 = curve._segment1
        this._segment2 = curve._segment2
    },
}

/**
 * @param {CurveLocation[]} locations
 * @param {CurveLocation} loc
 * @param {boolean} merge
 */
CurveLocation.insert = (locations, loc, merge) => {
    // Insert-sort by path-id, curve, time so we can easily merge
    // duplicates with calls to equals() after.
    const length = locations.length
    let l = 0
    let r = length - 1

    while (l <= r) {
        const m = (l + r) >>> 1
        const loc2 = locations[m]
        /** @type {CurveLocation} */
        let found = null
        // See if the two locations are actually the same, and merge if
        // they are. If they aren't check the other neighbors with search()
        if (
            merge
            &&
            (
                found = loc.equals(loc2)
                    ? loc2
                    : (search(locations, loc, length, m, -1) || search(locations, loc, length, m, 1))
            )
        ) {
            // We're done, don't insert, merge with the found location
            // instead, and carry over overlap:
            if (loc._overlap) {
                found._overlap = true
                found._intersection._overlap = true
            }
            return found
        }
        const path1 = loc.getPath()
        const path2 = loc2.getPath()
        // NOTE: equals() takes the intersection location into account,
        // while this calculation of diff doesn't!
        // eslint-disable-next-line no-negated-condition
        const diff = path1 !== path2
            // Sort by path id to group all locs on same path.
            ? path1._id - path2._id
            // Sort by both index and time on the same path. The two values
            // added together provides a convenient sorting index.
            : (loc.getIndex() + loc.getTime()) - (loc2.getIndex() + loc2.getTime())
        if (diff < 0) {
            r = m - 1
        } else {
            l = m + 1
        }
    }
    // We didn't merge with a preexisting location, insert it now.
    locations.splice(l, 0, loc)
    return loc
}

/**
 * @param {CurveLocation[]} locations
 */
CurveLocation.expand = (locations) => {
    // Create a copy since insert() keeps modifying the array and
    // inserting at sorted indices.
    const expanded = locations.slice()
    for (let i = locations.length - 1; i >= 0; i--) {
        CurveLocation.insert(expanded, locations[i]._intersection, false)
    }
    return expanded
}

/* implementation */

/**
 * @param {number[]} offsets
 * @param {CubicBez} curve
 * @param {boolean} end
 */
function addOffsets(offsets, curve, end) {
    // Find the largest offset of unambiguous direction on the curve,
    // taking their loops, cusps, inflections, and "peaks" into account.
    const v = curve.getValues()
    const roots = CubicBez.classify(v).roots || CubicBez.getPeaks(v)
    const count = roots.length
    const offset = CubicBez.getLength(
        v,
        end && count ? roots[count - 1] : 0,
        !end && count ? roots[0] : 1
    )
    // When no root was found, the full length was calculated. Use a
    // fraction of it. By trial & error, 64 was determined to work well.
    offsets.push(count ? offset : offset / 32)
}

/**
 * @param {number} angle
 * @param {number} min
 * @param {number} max
 */
function isInRange(angle, min, max) {
    if (typeof angle !== 'number' || typeof min !== 'number' || typeof max !== 'number') {
        return false
    }
    return min < max
        ? angle > min && angle < max
        // min > max: the range wraps around -180 / 180 degrees
        : angle > min || angle < max
}

/**
 * @param {CurveLocation[]} locations
 * @param {CurveLocation} loc
 * @param {number} length
 * @param {number} index
 * @param {number} dir
 */
function search(locations, loc, length, index, dir) {
    // If we reach the beginning/end of the list, also compare with the
    // location at the other end, as paths are circular lists.
    // NOTE: When merging, the locations array will only contain
    // locations on the same path, so it is fine that check for the end
    // to address circularity.
    for (let i = index + dir; i >= -1 && i <= length; i += dir) {
        // Wrap the index around, to match the other ends:
        const loc2 = locations[((i % length) + length) % length]
        // Once we're outside the spot, we can stop searching.
        if (!loc.getPoint().isClose(loc2.getPoint(), GEOMETRIC_EPSILON)) {
            break
        }
        if (loc.equals(loc2)) {
            return loc2
        }
    }
    return null
}

let Path_uid = 1

const format = {
    precision: 5,
    multiplier: 1,

    /**
     * @param {number} precision
     */
    setup(precision = 5) {
        this.precision = precision | 0
        this.multiplier = Math.pow(10, this.precision)
    },

    /**
     * Utility function for rendering numbers as strings at a precision of
     * up to the amount of fractional digits.
     * @param {number} val the number to be converted to a string
     */
    number(val) {
        // It would be nice to use Number#toFixed() instead, but it pads with 0,
        // unnecessarily consuming space.
        // If precision is >= 16, don't do anything at all, since that appears
        // to be the limit of the precision (it actually varies).
        return this.precision < 16
            ? Math.round(val * this.multiplier) / this.multiplier
            : val
    },
    /**
     * @param {number} val1
     * @param {number} val2
     * @param {string} separator
     */
    pair(val1, val2, separator = ',') {
        return this.number(val1) + (separator) + this.number(val2)
    },
}

/**
 * @param {Segment} segment
 * @param {number[]} coords
 * @param {number[]} prevCoords
 * @param {number[]} min
 * @param {number[]} max
 * @param {number[]} roots
 */
function processSegment(segment, coords, prevCoords, min, max, roots) {
    segment._transformCoordinates(coords)
    for (let i = 0; i < 2; i++) {
        CubicBez._addBounds(
            prevCoords[i], // prev.point
            prevCoords[i + 4], // prev.handleOut
            coords[i + 2], // segment.handleIn
            coords[i], // segment.point,
            i,
            min, max, roots
        )
    }
    // Swap coordinate buffers.
    const tmp = prevCoords
    prevCoords = coords
    coords = tmp
}

/**
 * @param {number} start
 * @param {number} end
 * @param {number} offset
 * @returns {[ number, number, boolean ]}
 */
function processTrimValues(start, end, offset) {
    const o = fraction(offset)
    let [s, e] = [start, end]
    if (s > e) {
        [s, e] = [e, s]
    }
    // Clamp the start and the end value to the range [0, 1]
    s = clamp01(s)
    e = clamp01(e)
    // Offset the start and the end value, and then round them to a precision of 4 decimal places
    s = Math.round((s + o) * 10000) * 0.0001
    e = Math.round((e + o) * 10000) * 0.0001
    let isTrimEndWrapped = false
    if (s > 1) {
        s = fraction(s)
        isTrimEndWrapped = !isTrimEndWrapped
    }
    if (e > 1) {
        e = fraction(e)
        isTrimEndWrapped = !isTrimEndWrapped
    }
    return [s, e, isTrimEndWrapped]
}

/**
 * @param {number} value
 * @returns {number}
 */
function clamp01(value) {
    return clamp(0, 1, value)
}

/**
 * @param {number} value
 * @returns {number}
 */
function fraction(value) {
    return value - Math.floor(value)
}

/**
 * @param {number} a minimum
 * @param {number} b maximum
 * @param {number} value
 * @returns {number}
 */
function clamp(a, b, value) {
    return Math.min(b, Math.max(value, a))
}

/**
 * @template T
 * @param {T[]} _list
 * @param {T[]} items
 */
function Base_push(_list, items) {
    const list = _list
    const itemsLength = items.length
    // It seems for "small" amounts of items, this performs better,
    // but once it reaches a certain amount, some browsers start crashing:
    if (itemsLength < 4096) {
        // eslint-disable-next-line prefer-spread
        list.push.apply(list, items)
    } else {
        // Use a loop as the best way to handle big arrays (see #1493).
        // Set new array length once before the loop for better performance.
        const startLength = list.length
        list.length += itemsLength
        for (let i = 0; i < itemsLength; i++) {
            list[startLength + i] = items[i]
        }
    }
    return list
}

/**
 * @template T
 * @param {T[]} list
 * @param {T[]} items
 * @param {number} index
 * @param {number} remove
 * @returns {T[]}
 */
function Base_splice(list, items, index, remove) {
    const amount = items && items.length
    const append = index === undefined
    // eslint-disable-next-line no-param-reassign
    index = append ? list.length : index
    if (index > list.length) {
        // eslint-disable-next-line no-param-reassign
        index = list.length
    }
    // Update _index on the items to be added first.
    for (let i = 0; i < amount; i++) {
        items[i]._index = index + i
    }
    if (append) {
        // Append them all at the end by using push
        Base_push(list, items)
        // Nothing removed, and nothing to adjust above
        return []
    } else {
        // Insert somewhere else and/or remove
        const args = [index, remove]
        if (items) {
            Base_push(args, items)
        }
        // eslint-disable-next-line prefer-spread
        const removed = list.splice.apply(list, args)
        // Erase the indices of the removed items
        for (let i = 0, l = removed.length; i < l; i++) {
            removed[i]._index = undefined
        }
        // Adjust the indices of the items above.
        for (let i = index + amount, l = list.length; i < l; i++) {
            list[i]._index = i
        }
        return removed
    }
}

/**
 * @template T
 * @param {T[]} iter
 * @param {(t: T, i: number) => void} f
 * @param {any} bind
 */
function Base_each(iter, f, bind) {
    for (let i = 0, len = iter.length; i < len; i++) {
        f.call(bind, iter[i], i)
    }
    return bind
}

/**
 * @param {BezierPath | BezierShape} path
 */
function Item_remove(path) {
    /** @type {BezierShape} */
    const owner = path._parent
    const index = path._index
    if (owner) {
        // Handle index separately from owner: There are situations where
        // the item is already removed from its list through Base.splice()
        // and index set to undefined, but the owner is still set,
        // e.g. in #removeChildren():
        if (Number.isFinite(index)) {
            Base_splice(owner._children, null, index, 1)
        }
        path._parent = null
        return true
    }
    return false
}

/**
 * @param {BezierShape | BezierPath} item
 */
function clonePath(item) {
    if (item._children) {
        const copy = new BezierShape()
        for (let i = 0, len = item._children.length; i < len; i++) {
            copy.addChild(clonePath(item._children[i]), true)
        }
        copy.version = item.version
        return copy
    } else {
        const copy = new BezierPath()
        copy.setSegments(item._segments.slice())
        copy._closed = item._closed
        return copy
    }
}

/**
 * @param {BezierPath} self
 * @param {BezierPath} path
 * @param {(loc: CurveLocation) => boolean} include
 * @param {boolean} [_returnFirst]
 */
function getIntersections(self, path, include, _returnFirst) {
    // NOTE: For self-intersection, path is null. This means you can also
    // just call path.getIntersections() without an argument to get self
    // intersections.
    const isTestingSelf = self === path || !path
    // First check the bounds of the two paths. If they don't intersect,
    // we don't need to iterate through their curves.
    const hasBoundIntersection = isTestingSelf || self.getBounds().intersects(path.getBounds(), EPSILON)
    return (hasBoundIntersection)
        ? CubicBez.getIntersections(
            self.getCurves(),
            !isTestingSelf && path.getCurves(),
            include,
            _returnFirst
        )
        : []
}

/* Boolean functions */

/**
 * @param {Segment} seg
 * @param {BezierPath} path
 */
function hasOverlap(seg, path) {
    const inter = seg && seg._intersection
    return inter && inter._overlap && inter._path === path
}

/**
 * @param {BezierShape | BezierPath} self
 */
const resolveCrossings = (self) => {
    /** @type {BezierPath[]} */
    const children = self._children
    /** @type {BezierPath[]} */
    let paths = children || [self]

    // First collect all overlaps and crossings while taking note of the
    // existence of both.
    let hasOverlaps = false
    let hasCrossings = false
    let intersections = self.getIntersections(null, function(inter) {
        return (
            // eslint-disable-next-line no-mixed-operators
            inter.hasOverlap() && (hasOverlaps = true) || inter.isCrossing() && (hasCrossings = true)
        )
    })
    // We only need to keep track of curves that need clearing
    // outside of divideLocations() if two calls are necessary.
    /** @type {CubicBez[]} */
    const clearCurves = hasOverlaps && hasCrossings && []
    intersections = CurveLocation.expand(intersections)
    if (hasOverlaps) {
        // First divide in all overlaps, and then remove the inside of
        // the resulting overlap ranges.
        const overlaps = divideLocations(
            intersections,
            (inter) => inter.hasOverlap(),
            clearCurves
        )
        for (let i = overlaps.length - 1; i >= 0; i--) {
            const overlap = overlaps[i],
                path = overlap._path,
                seg = overlap._segment,
                prev = seg.getPrevious(),
                next = seg.getNext()
            if (hasOverlap(prev, path) && hasOverlap(next, path)) {
                seg.remove()
                prev._handleOut.set(0, 0)
                next._handleIn.set(0, 0)
                prev._changed(prev._handleOut)
                next._changed(next._handleIn)
                // If the curve that is left has no length, remove it
                // altogether. Check for paths with only one segment
                // before removal, since `prev.getCurve() == null`.
                if (prev !== seg && !prev.getCurve().hasLength()) {
                    // Transfer handleIn when removing segment:
                    next._handleIn.set(prev._handleIn.x, prev._handleIn.y)
                    next._changed(next._handleIn)
                    prev.remove()
                }
            }
        }
    }
    if (hasCrossings) {
        // Divide any remaining intersections that are still part of
        // valid paths after the removal of overlaps.
        divideLocations(
            intersections,
            hasOverlaps && ((inter) => {
                // Check both involved curves to see if they're still valid,
                // meaning they are still part of their paths.
                const curve1 = inter.getCurve()
                const seg1 = inter.getSegment()
                // Do not call getCurve() and getSegment() on the other
                // intersection yet, as it too is in the intersections
                // array and will be divided later. But check if its
                // current curve is valid, as required by some rare edge
                // cases, related to intersections on the same curve.
                const other = inter._intersection
                const curve2 = other._curve
                const seg2 = other._segment
                if (curve1 && curve2 && curve1._path && curve2._path) {
                    return true
                }
                // Remove all intersections that were involved in the
                // handling of overlaps, to not confuse tracePaths().
                if (seg1) {
                    seg1._intersection = null
                }
                if (seg2) {
                    seg2._intersection = null
                }
            }),
            clearCurves
        )
        if (clearCurves) {
            clearCurveHandles(clearCurves)
        }
        // Finally resolve self-intersections through tracePaths()
        paths = tracePaths(
            Base_each(
                paths,
                function(path) { Base_push(this, path._segments) },
                []
            )
        )
    }
    // Determine how to return the paths: First try to recycle the
    // current path / compound-path, if the amount of paths does not
    // require a conversion.
    const length = paths.length
    /** @type {BezierPath | BezierShape} */
    let item
    if (length > 1 && children) {
        if (paths !== children) {
            self.setChildren(paths)
        }
        item = self
    } else if (length === 1 && !children) {
        if (paths[0] !== self) {
            self.setSegments(paths[0].removeSegments())
        }
        item = self
    }
    // Otherwise create a new compound-path and see if we can reduce it,
    // and attempt to replace this item with it.
    if (!item) {
        item = new BezierShape()
        item.addChildren(paths)
        item = item.reduce()
    }
    return item
}

/**
 * Fixes the orientation of the sub-paths of a compound-path, assuming
 * that non of its sub-paths intersect, by reorienting them so that they
 * are of different winding direction than their containing paths,
 * except for disjoint sub-paths, i.e. islands, which are oriented so
 * that they have the same winding direction as the the biggest path.
 *
 * @param {BezierShape | BezierPath} self
 * @param {boolean} nonZero
 * @param {boolean} clockwise
 * @returns {BezierShape | BezierPath}
 */
function reorient(self, nonZero, clockwise) {
    const children = self._children
    if (children && children.length) {
        self.setChildren(
            reorientPaths(
                self.removeChildren(),
                // Handle both even-odd and non-zero rule.
                (w) => !!(nonZero ? w : w & 1),
                clockwise
            )
        )
    } else if (clockwise !== undefined) {
        self.setClockwise(clockwise)
    }
    return self
}

/**
 * Returns a point that is guaranteed to be inside the path.
 * @param {BezierShape | BezierPath} self
 */
function getInteriorPoint(self) {
    const bounds = self.getBounds()
    const point = bounds.getCenter()
    if (!self.contains(point)) {
        // Since there is no guarantee that a poly-bezier path contains
        // the center of its bounding rectangle, we shoot a ray in x
        // direction and select a point between the first consecutive
        // intersections of the ray on the left.
        const curves = self.getCurves()
        const y = point.y
        /** @type {number[]} */
        const intercepts = []
        /** @type {number[]} */
        const roots = []
        // Process all y-monotone curves that intersect the ray at y:
        for (let i = 0, l = curves.length; i < l; i++) {
            const v = curves[i].getValues()
            const o0 = v[1]
            const o1 = v[3]
            const o2 = v[5]
            const o3 = v[7]
            if (y >= min(o0, o1, o2, o3) && y <= max(o0, o1, o2, o3)) {
                const monoCurves = CubicBez.getMonoCurves(v)
                for (let j = 0, m = monoCurves.length; j < m; j++) {
                    const mv = monoCurves[j],
                        mo0 = mv[1],
                        mo3 = mv[7]
                    // Only handle curves that are not horizontal and
                    // that can cross the point's ordinate.
                    // eslint-disable-next-line no-mixed-operators
                    if ((mo0 !== mo3) && (y >= mo0 && y <= mo3 || y >= mo3 && y <= mo0)) {
                        // eslint-disable-next-line no-nested-ternary
                        const x = y === mo0
                            ? mv[0]
                            // eslint-disable-next-line no-nested-ternary
                            : y === mo3
                                ? mv[6]
                                : CubicBez.solveCubic(mv, 1, y, roots, 0, 1) === 1
                                    ? CubicBez.getPoint(mv, roots[0]).x
                                    : (mv[0] + mv[6]) / 2
                        intercepts.push(x)
                    }
                }
            }
        }
        if (intercepts.length > 1) {
            intercepts.sort((a, b) => (a - b))
            point.x = (intercepts[0] + intercepts[1]) / 2
        }
    }
    return point
}

/**
 * @param {CubicBez[]} curves
 */
function clearCurveHandles(curves) {
    // Clear segment handles if they were part of a curve with no handles.
    for (let i = curves.length - 1; i >= 0; i--) {
        curves[i].clearHandles()
    }
}

/* [boolean] */

const min = Math.min
const max = Math.max
const abs = Math.abs
const sqrt = Math.sqrt

/**
 * @typedef {{ [winding: number]: boolean }} OperatorLUT
 */

/**
 * Set up lookup tables for each operator, to decide if a given segment
 * is to be considered a part of the solution, or to be discarded, based
 * on its winding contribution, as calculated by propagateWinding().
 * Boolean operators return true if a segment with the given winding
 * contribution contributes to the final result or not. They are applied
 * to for each segment after the paths are split at crossings.
 *
 * @type {{ [key: number]: OperatorLUT }}
 */
const operators = {
    [BooleanOperation.UNION]: { '1': true, '2': true },
    [BooleanOperation.INTERSECT]: { '2': true },
    [BooleanOperation.SUBTRACT]:  { '1': true },
    // exclude only needs -1 to support reorientPaths() when there are
    // no crossings. The actual boolean code uses unsigned winding.
    [BooleanOperation.DIFFERENCE]:   { '1': true, '-1': true },
}

/**
 * @param {BezierPath} path1
 * @param {BezierPath} path2
 * @param {BooleanOperation} operation
 */
export function traceBoolean(path1, path2, operation) {
    // We do not modify the operands themselves, but create copies instead,
    // fas produced by the calls to preparePath().
    // NOTE: The result paths might not belong to the same type i.e.
    // subtract(A:Path, B:Path):BezierShape etc.
    const _path1 = preparePath(path1, true)
    const _path2 = path2 && path1 !== path2 && preparePath(path2, true)
    // Retrieve the operator lookup table for winding numbers.
    /** @type {OperatorLUT} */
    const operator = operators[operation]
    // Add a simple boolean property to check for a given operation,
    // e.g. `if (operator.unite)`
    operator[operation - 10] = true
    // Give both paths the same orientation except for subtraction
    // and exclusion, where we need them at opposite orientation.
    if (_path2 && (operator[BooleanOperation.SUBTRACT - 10] || operator[BooleanOperation.DIFFERENCE - 10]) ^ (_path2.isClockwise() ^ _path1.isClockwise())) {
        _path2.reverse()
    }
    // Split curves at crossings on both paths. Note that for self-
    // intersection, path2 is null and getIntersections() handles it.
    const results = CurveLocation.expand(
        _path1.getIntersections(_path2, filterIntersection)
    )
    const crossings = divideLocations(results)

    const paths1 = getPaths(_path1)
    /** @type {BezierPath[]} */
    const paths2 = _path2 && getPaths(_path2)
    /** @type {Segment[]} */
    const segments = []
    /** @type {CubicBez[]} */
    const curves = []
    /** @type {BezierPath[]} */
    let paths

    if (crossings.length) {
        // Collect all segments and curves of both involved operands.
        collectPaths(paths1, segments, curves)
        if (paths2) {
            collectPaths(paths2, segments, curves)
        }

        /** @type {CurveData[]} */
        const curvesValues = Array(curves.length)
        for (let i = 0, l = curves.length; i < l; i++) {
            curvesValues[i] = curves[i].getValues()
        }
        const curveCollisions = findCurveBoundsCollisions(
            curvesValues, curvesValues, 0, true
        )
        /** @type {{ [index: number]: { hor: CubicBez[], ver: CubicBez[] } }} */
        const curveCollisionsMap = {}
        for (let i = 0; i < curves.length; i++) {
            const curve = curves[i],
                id = curve._path._id
            curveCollisionsMap[id] = curveCollisionsMap[id] || {}
            const map = curveCollisionsMap[id]
            map[curve.getIndex()] = {
                hor: getCurves(curveCollisions[i].hor, curves),
                ver: getCurves(curveCollisions[i].ver, curves)
            }
        }

        // Propagate the winding contribution. Winding contribution of
        // curves does not change between two crossings.
        // First, propagate winding contributions for curve chains starting
        // in all crossings:
        for (let i = 0, l = crossings.length; i < l; i++) {
            propagateWinding(
                crossings[i]._segment, _path1, _path2,
                curveCollisionsMap, operator
            )
        }
        for (let i = 0, l = segments.length; i < l; i++) {
            const segment = segments[i]
            const inter = segment._intersection
            if (!segment._winding) {
                propagateWinding(
                    segment, _path1, _path2,
                    curveCollisionsMap, operator
                )
            }
            // See if all encountered segments in a path are overlaps.
            if (!(inter && inter._overlap)) {
                segment._path._overlapsOnly = false
            }
        }
        paths = tracePaths(segments, operator)
    } else {
        // When there are no crossings, the result can be determined through
        // a much faster call to reorientPaths():
        paths = reorientPaths(
            // Make sure reorientPaths() never works on original
            // _children arrays by calling paths1.slice()
            paths2
                ? paths1.concat(paths2)
                : paths1.slice(),
            (w) => (!!operator[w])
        )
    }
    return createResult(paths, true)
}

/**
 * @param {BezierPath[]} paths
 * @param {Segment[]} segments
 * @param {CubicBez[]} curves
 */
function collectPaths(paths, segments, curves) {
    for (let i = 0, l = paths.length; i < l; i++) {
        const path = paths[i]
        Base_push(segments, path._segments)
        Base_push(curves, path.getCurves())
        // See if all encountered segments in a path are overlaps, to
        // be able to separately handle fully overlapping paths.
        path._overlapsOnly = true
    }
}

/**
 * @param {number[]} indices
 * @param {CubicBez[]} curves
 */
function getCurves(indices, curves) {
    /** @type {CubicBez[]} */
    const list = []
    for (let i = 0, l = indices && indices.length; i < l; i++) {
        list.push(curves[indices[i]])
    }
    return list
}

/**
 * @param {BezierShape | BezierPath} _path
 * @param {boolean} resolve
 */
function preparePath(_path, resolve) {
    let path = _path
    let res = path
        .clone(false)
        .reduce(true)
    if (resolve) {
        // For correct results, close open paths with straight lines:
        const paths = getPaths(res)
        for (let i = 0, l = paths.length; i < l; i++) {
            path = paths[i]
            if (!path._closed && !path.isEmpty()) {
                // Close with epsilon tolerance, to avoid tiny straight
                // that would cause issues with intersection detection.
                path.close(EPSILON)
                path.getFirstSegment().setHandleIn(new Vector2(0, 0))
                path.getLastSegment().setHandleOut(new Vector2(0, 0))
            }
        }
        // TODO*: need to a 'fillRule' for our Path? and need to update to our RenderNode?
        // res.reorient(res, res.getFillRule() === 'nonzero', true)
        res = res
            .resolveCrossings()
            .reorient(res.getFillRule() === 'nonzero', true)
    }
    return res
}

/**
 * @param {CurveLocation[]} locations an array of the locations to split the path-item at.
 * @param {(loc: CurveLocation) => boolean} [include] a function that determines if dividing should happen at a given location.
 * @param {CubicBez[]} [clearLater]
 */
function divideLocations(locations, include, clearLater) {
    /** @type {CurveLocation[]} */
    const results = include && []
    const tMin = CURVETIME_EPSILON
    const tMax = 1 - tMin
    let clearHandles = false
    const clearCurves = clearLater || []
    /** @type {{ [id: string]: boolean }} */
    const clearLookup = clearLater && {}
    /** @type {CurveLocation[]} */
    let renormalizeLocs
    /** @type {CubicBez} */
    let prevCurve
    /** @type {number} */
    let prevTime

    for (let i = (clearLater && clearLater.length) - 1; i >= 0; i--) {
        const curve = clearLater[i]
        if (curve._path) {
            clearLookup[getId(curve)] = true
        }
    }

    // Loop backwards through all sorted locations, from right to left, so
    // we can assume a predefined sequence for curve-time renormalization.
    for (let i = locations.length - 1; i >= 0; i--) {
        const loc = locations[i]
        // Retrieve curve-time before calling include(), because it may
        // be changed to the scaled value after splitting previously.
        // See CurveLocation#getCurve(), #resolveCrossings()
        let time = loc._time
        const origTime = time
        const exclude = include && !include(loc)
        // Retrieve curve after calling include(), because it may cause
        // a change in the cached location values, see above.
        const curve = loc._curve
        /** @type {Segment} */
        let segment

        if (curve) {
            if (curve !== prevCurve) {
                // This is a new curve, update clearHandles setting.
                clearHandles = !curve.hasHandles()
                    ||
                    clearLookup && clearLookup[getId(curve)]
                // Keep track of locations for later curve-time
                // renormalization within the curve.
                renormalizeLocs = []
                prevTime = null
                prevCurve = curve
            } else if (prevTime >= tMin) {
                // Rescale curve-time when we are splitting the same curve
                // multiple times, if splitting was done previously.
                time /= prevTime
            }
        }
        if (exclude) {
            // Store excluded locations for later renormalization, in case
            // the same curve is divided to their left.
            if (renormalizeLocs) {
                renormalizeLocs.push(loc)
            }
            continue
        } else if (include) {
            results.unshift(loc)
        }
        prevTime = origTime
        if (time < tMin) {
            segment = curve._segment1
        } else if (time > tMax) {
            segment = curve._segment2
        } else {
            // Split the curve at time, passing true for _setHandles to
            // always set the handles on the sub-curves even if the original
            // curve had no handles.
            const newCurve = curve.divideAtTime(time, true)
            // Keep track of curves without handles, so they can be cleared
            // again at the end.
            if (clearHandles) {
                clearCurves.push(curve, newCurve)
            }
            segment = newCurve._segment1
            // Handle locations that need their curve-time renormalized
            // within the same curve after dividing at this location.
            for (let j = renormalizeLocs.length - 1; j >= 0; j--) {
                const l = renormalizeLocs[j]
                l._time = (l._time - time) / (1 - time)
            }
        }
        loc._setSegment(segment)
        // Create links from the new segment to the intersection on the
        // other curve, as well as from there back. If there are multiple
        // intersections on the same segment, we create linked lists between
        // the intersections through linkIntersections(), linking both ways.
        const inter = segment._intersection
        const dest = loc._intersection
        if (inter) {
            linkIntersections(inter, dest)
            // Each time we add a new link to the linked list, we need to
            // add links from all the other entries to the new entry.
            let other = inter
            while (other) {
                linkIntersections(other._intersection, inter)
                other = other._next
            }
        } else {
            segment._intersection = dest
        }
    }
    // Clear curve handles right away if we're not storing them for later.
    if (!clearLater) {
        clearCurveHandles(clearCurves)
    }
    return results || locations
}

/**
 * When dealing with overlaps and crossings, divideLocations() is called
 * twice. If curve handles of curves that originally didn't have handles
 * are cleared after the first call , we loose  curve-time consistency
 * and CurveLocation#_time values become invalid.
 * In those situations, clearLater is passed as a container for all
 * curves of which the handles need to be cleared in the end.
 * Create a lookup table that allows us to quickly determine if a given
 * curve was resulting from an original curve without handles.
 * @param {CubicBez} curve
 */
function getId(curve) {
    // eslint-disable-next-line prefer-template
    return curve._path._id + '.' + curve._segment1._index
}

/**
 * Creates linked lists between intersections through their _next and _prev
 * properties.
 * @param {CurveLocation} from
 * @param {CurveLocation} to
 */
function linkIntersections(from, to) {
    // Only create the link if it's not already in the existing chain, to
    // avoid endless recursions. First walk to the beginning of the chain,
    // and abort if we find `to`.
    let prev = from
    while (prev) {
        if (prev === to) {
            return
        }
        prev = prev._previous
    }
    // Now walk to the end of the existing chain to find an empty spot, but
    // stop if we find `to`, to avoid adding it again.
    while (from._next && from._next !== to) {
        // eslint-disable-next-line no-param-reassign
        from = from._next
    }
    // If we're reached the end of the list, we can add it.
    if (!from._next) {
        // Go back to beginning of the other chain, and link the two up.
        while (to._previous) {
            // eslint-disable-next-line no-param-reassign
            to = to._previous
        }
        from._next = to
        to._previous = from
    }
}

/**
 * Returns the winding contribution number of the given point in respect
 * to the shapes described by the passed curves.
 *
 * @param {Vector2} point the location for which to determine the winding
 *     contribution
 * @param {CubicBez[]} curves The curves that describe the shape against which
 *     to check, as returned by curves
 * @param {boolean} [dir=false] the direction in which to determine the
 *     winding contribution, `false`: in x-direction, `true`: in y-direction
 * @param {boolean} [closed=false] determines how areas should be closed
 *     when a curve is part of an open path, `false`: area is closed with a
 *     straight line, `true`: area is closed taking the handles of the first
 *     and last segment into account
 * @param {boolean} [dontFlip=false] controls whether the algorithm is
 *     allowed to flip direction if it is deemed to produce better results
 */
function getWinding(point, curves, dir, closed, dontFlip) {
    // `curves` can either be an array of curves, or an object containing of
    // the form `{ hor: [], ver: [] }` (see `curveCollisionsMap`), with each
    // key / value pair holding only those curves that can be crossed by a
    // horizontal / vertical line through the point to be checked.
    const curvesList = Array.isArray(curves)
        ? curves
        : curves[dir ? 'hor' : 'ver']
    // Determine the index of the abscissa and ordinate values in the curve
    // values arrays, based on the direction:
    const ia = dir ? 1 : 0 // the abscissa index
    const io = ia ^ 1 // the ordinate index
    const pv = [point.x, point.y]
    const pa = pv[ia] // the point's abscissa
    const po = pv[io] // the point's ordinate
    // Use separate epsilons for winding contribution code.
    const windingEpsilon = 1e-9
    const qualityEpsilon = 1e-6
    const paL = pa - windingEpsilon
    const paR = pa + windingEpsilon
    let windingL = 0
    let windingR = 0
    let pathWindingL = 0
    let pathWindingR = 0
    let onPath = false
    let onAnyPath = false
    let quality = 1
    /** @type {number[]} */
    const roots = []
    /** @type {CurveData} */
    let vPrev
    /** @type {CurveData} */
    let vClose

    /**
     * @param {CurveData} v
     */
    function addWinding(v) {
        const o0 = v[io + 0]
        const o3 = v[io + 6]
        if (po < min(o0, o3) || po > max(o0, o3)) {
            // If the curve is outside the ordinates' range, no intersection
            // with the ray is possible.
            return
        }
        const a0 = v[ia + 0]
        const a1 = v[ia + 2]
        const a2 = v[ia + 4]
        const a3 = v[ia + 6]
        if (o0 === o3) {
            // A horizontal curve is not necessarily between two non-
            // horizontal curves. We have to take cases like these into
            // account:
            //          +-----+
            //     +----+     |
            //          +-----+
            if (a0 < paR && a3 > paL || a3 < paR && a0 > paL) {
                onPath = true
            }
            // If curve does not change in ordinate direction, windings will
            // be added by adjacent curves.
            // Bail out without updating vPrev at the end of the call.
            return
        }
        // Determine the curve-time value corresponding to the point.
        const t = (po === o0)
            ? 0
            : (po === o3)
                // If the abscissa is outside the curve, we can use any
                // value except 0 (requires special handling). Use 1, as it
                // does not require additional calculations for the point.
                ? 1
                : (paL > max(a0, a1, a2, a3) || paR < min(a0, a1, a2, a3))
                    ? 1
                    : CubicBez.solveCubic(v, io, po, roots, 0, 1) > 0
                        ? roots[0]
                        : 1
        const a = (t === 0)
            ? a0
            : t === 1
                ? a3
                : CubicBez.getPoint(v, t)[dir ? 'y' : 'x']
        const winding = o0 > o3 ? 1 : -1
        const windingPrev = vPrev[io] > vPrev[io + 6] ? 1 : -1
        const a3Prev = vPrev[ia + 6]
        // eslint-disable-next-line no-negated-condition
        if (po !== o0) {
            // Standard case, curve is not crossed at its starting point.
            if (a < paL) {
                pathWindingL += winding
            } else if (a > paR) {
                pathWindingR += winding
            } else {
                onPath = true
            }
            // Determine the quality of the winding calculation. Reduce the
            // quality with every crossing of the ray very close to the
            // path. This means that if the point is on or near multiple
            // curves, the quality becomes less than 0.5.
            if (a > pa - qualityEpsilon && a < pa + qualityEpsilon) {
                quality /= 2
            }
        } else {
            // Curve is crossed at starting point.
            if (winding !== windingPrev) {
                // Winding changes from previous curve, cancel its winding.
                if (a0 < paL) {
                    pathWindingL += winding
                } else if (a0 > paR) {
                    pathWindingR += winding
                }
            } else if (a0 !== a3Prev) {
                // Handle a horizontal curve between the current and
                // previous non-horizontal curve. See
                // #1261#issuecomment-282726147 for a detailed explanation:
                if (a3Prev < paR && a > paR) {
                    // Right winding was not added before, so add it now.
                    pathWindingR += winding
                    onPath = true
                } else if (a3Prev > paL && a < paL) {
                    // Left winding was not added before, so add it now.
                    pathWindingL += winding
                    onPath = true
                }
            }
            quality /= 4
        }
        vPrev = v
        // If we're on the curve, look at the tangent to decide whether to
        // flip direction to better determine a reliable winding number:
        // If the tangent is parallel to the direction, call getWinding()
        // again with flipped direction and return that result instead.
        return !dontFlip && a > paL && a < paR
                && CubicBez.getTangent(v, t)[dir ? 'x' : 'y'] === 0
                && getWinding(point, curves, !dir, closed, true)
    }

    /**
     * @param {CurveData} v
     */
    function handleCurve(v) {
        // Get the ordinates:
        const o0 = v[io + 0]
        const o1 = v[io + 2]
        const o2 = v[io + 4]
        const o3 = v[io + 6]
        // Only handle curves that can cross the point's ordinate.
        if (po <= max(o0, o1, o2, o3) && po >= min(o0, o1, o2, o3)) {
            // Get the abscissas:
            const a0 = v[ia + 0]
            const a1 = v[ia + 2]
            const a2 = v[ia + 4]
            const a3 = v[ia + 6]
            // Get monotone curves. If the curve is outside the point's
            // abscissa, it can be treated as a monotone curve:
            const monoCurves = paL > max(a0, a1, a2, a3) || paR < min(a0, a1, a2, a3)
                ? [v]
                : CubicBez.getMonoCurves(v, dir)
            /** @type {WindingInfo} */
            let res
            for (let i = 0, l = monoCurves.length; i < l; i++) {
                // Calling addWinding() my lead to direction flipping, in
                // which case we already have the result and can return it.
                res = addWinding(monoCurves[i])
                if (res) {
                    return res
                }
            }
        }
    }

    for (let i = 0, l = curvesList.length; i < l; i++) {
        const curve = curvesList[i]
        const path = curve._path
        const v = curve.getValues()
        let res
        if (!i || curvesList[i - 1]._path !== path) {
            // We're on a new (sub-)path, so we need to determine values of
            // the last non-horizontal curve on this path.
            vPrev = null
            // If the path is not closed, connect the first and last segment
            // based on the value of `closed`:
            // - `false`: Connect with a straight curve, just like how
            //   filling open paths works.
            // - `true`: Connect with a curve that takes the segment handles
            //   into account, just like how closed paths behave.
            if (!path._closed) {
                vClose = CubicBez.getValues(
                    path.getLastCurve().getSegment2(),
                    curve.getSegment1(),
                    !closed
                )
                // This closing curve is a potential candidate for the last
                // non-horizontal curve.
                if (vClose[io] !== vClose[io + 6]) {
                    vPrev = vClose
                }
            }

            if (!vPrev) {
                // Walk backwards through list of the path's curves until we
                // find one that is not horizontal.
                // Fall-back to the first curve's values if none is found:
                vPrev = v
                let prev = path.getLastCurve()
                while (prev && prev !== curve) {
                    const v2 = prev.getValues()
                    if (v2[io] !== v2[io + 6]) {
                        vPrev = v2
                        break
                    }
                    prev = prev.getPrevious()
                }
            }
        }

        // Calling handleCurve() my lead to direction flipping, in which
        // case we already have the result and can return it.
        res = handleCurve(v)
        if (res) {
            return res
        }

        if (i + 1 === l || curvesList[i + 1]._path !== path) {
            // We're at the last curve of the current (sub-)path. If a
            // closing curve was calculated at the beginning of it, handle
            // it now to treat the path as closed:
            if (vClose && (res = handleCurve(vClose))) {
                return res
            }
            if (onPath && !pathWindingL && !pathWindingR) {
                // If the point is on the path and the windings canceled
                // each other, we treat the point as if it was inside the
                // path. A point inside a path has a winding of [+1,-1]
                // for clockwise and [-1,+1] for counter-clockwise paths.
                // If the ray is cast in y direction (dir == true), the
                // windings always have opposite sign.
                pathWindingL = path.isClockwise(closed) ^ dir ? 1 : -1
                pathWindingR = pathWindingL
            }
            windingL += pathWindingL
            windingR += pathWindingR
            pathWindingL = 0
            pathWindingR = 0
            if (onPath) {
                onAnyPath = true
                onPath = false
            }
            vClose = null
        }
    }
    // Use the unsigned winding contributions when determining which areas
    // are part of the boolean result.
    windingL = abs(windingL)
    windingR = abs(windingR)
    // Return the calculated winding contributions along with a quality
    // value indicating how reliable the value really is.
    return {
        winding: max(windingL, windingR),
        windingL: windingL,
        windingR: windingR,
        quality: quality,
        onPath: onAnyPath,
    }
}

/**
 *
 * @param {Segment} segment
 * @param {BezierPath} path1
 * @param {BezierPath} path2
 * @param {{ [index: number]: { hor: CubicBez[], ver: CubicBez[] } }} curveCollisionsMap
 * @param {OperatorLUT} operator
 */
function propagateWinding(segment, path1, path2, curveCollisionsMap, operator) {
    // Here we try to determine the most likely winding number contribution
    // for the curve-chain starting with this segment. Once we have enough
    // confidence in the winding contribution, we can propagate it until the
    // next intersection or end of a curve chain.
    /** @type {{ segment: Segment, curve: CubicBez, length: number }[]} */
    const chain = []
    const start = segment
    let totalLength = 0
    /** @type {WindingInfo} */
    let winding
    do {
        const curve = segment.getCurve()
        // We can encounter paths with only one segment, which would not
        // have a curve.
        if (curve) {
            const length = curve.getLength()
            chain.push({ segment: segment, curve: curve, length: length })
            totalLength += length
        }
        segment = segment.getNext()
    } while (segment && !segment._intersection && segment !== start)
    // Determine winding at three points in the chain. If a winding with
    // sufficient quality is found, use it. Otherwise use the winding with
    // the best quality.
    const offsets = [0.5, 0.25, 0.75]
    winding = { winding: 0, quality: -1 }
    // Don't go too close to segments, to avoid special winding cases:
    const tMin = 1e-3
    const tMax = 1 - tMin
    for (let i = 0; i < offsets.length && winding.quality < 0.5; i++) {
        let length = totalLength * offsets[i]
        for (let j = 0, l = chain.length; j < l; j++) {
            const entry = chain[j]
            const curveLength = entry.length
            if (length <= curveLength) {
                const curve = entry.curve
                const path = curve._path
                const parent = path._parent
                const operand = parent instanceof BezierShape ? parent : path
                const t = Numerical.clamp(curve.getTimeAt(length), tMin, tMax)
                const pt = curve.getPointAtTime(t)
                // Determine the direction in which to check the winding
                // from the point (horizontal or vertical), based on the
                // curve's direction at that point. If tangent is less
                // than 45°, cast the ray vertically, else horizontally.
                const dir = abs(curve.getTangentAtTime(t).y) < Math.SQRT1_2
                // While subtracting, we need to omit this curve if it is
                // contributing to the second operand and is outside the
                // first operand.
                /** @type {WindingInfo} */
                let wind = null
                if (operator[BooleanOperation.SUBTRACT - 10] && path2) {
                    // Calculate path winding at point depending on operand.
                    const otherPath = operand === path1 ? path2 : path1
                    const pathWinding = otherPath._getWinding(pt, dir, true)
                    // Check if curve should be omitted.
                    if (operand === path1 && pathWinding.winding ||
                        operand === path2 && !pathWinding.winding) {
                        // Check if quality is not good enough...
                        if (pathWinding.quality < 1) {
                            // ...and if so, skip this point...
                            continue
                        } else {
                            // ...otherwise, omit this curve.
                            wind = { winding: 0, quality: 1 }
                        }
                    }
                }
                wind =  wind || getWinding(
                    pt, curveCollisionsMap[path._id][curve.getIndex()],
                    dir, true)
                if (wind.quality > winding.quality) {
                    winding = wind
                }
                break
            }
            length -= curveLength
        }
    }
    // Now assign the winding to the entire curve chain.
    for (let j = chain.length - 1; j >= 0; j--) {
        chain[j].segment._winding = winding
    }
}

/**
 * Private method to trace closed paths from a list of segments, according
 * to a the their winding number contribution and a custom operator.
 *
 * @param {Segment[]} segments array of segments to trace closed paths
 * @param {OperatorLUT} [operator] the operator lookup table that receives as key
 *     the winding number contribution of a curve and returns a boolean
 *     value indicating whether the curve should be included in result
 * @returns the traced closed paths
 */
function tracePaths(segments, operator) {
    /** @type {BezierPath[]} */
    const paths = []
    /** @type {Segment[]} */
    const starts = []

    // Sort segments to give non-ambiguous segments the preference as
    // starting points when tracing: prefer segments with no intersections
    // over intersections, and process intersections with overlaps last:
    segments.sort(function(seg1, seg2) {
        const inter1 = seg1._intersection
        const inter2 = seg2._intersection
        const over1 = !!(inter1 && inter1._overlap)
        const over2 = !!(inter2 && inter2._overlap)
        const path1 = seg1._path
        const path2 = seg2._path
        // Use bitwise-or to sort cases where only one segment is an overlap
        // or intersection separately, and fall back on natural order within
        // the path.
        // eslint-disable-next-line no-nested-ternary
        return over1 ^ over2
            ? over1 ? 1 : -1
        // NOTE: inter1 & 2 are objects, convert to boolean first
        // as otherwise toString() is called on them.
            // eslint-disable-next-line no-nested-ternary
            : !inter1 ^ !inter2
                ? inter1 ? 1 : -1
            // All other segments, also when comparing two overlaps
            // or two intersections, are sorted by their order.
            // Sort by path id to group segments on the same path.
            // eslint-disable-next-line no-negated-condition
                : path1 !== path2
                    ? path1._id - path2._id
                    : seg1._index - seg2._index
    })

    for (let i = 0, l = segments.length; i < l; i++) {
        let seg = segments[i]
        let valid = isValid(seg, operator)
        /** @type {BezierPath} */
        let path = null
        let finished = false
        let closed = true
        /** @typedef {{ start: number, crossings: Segment[], visited: Segment[], handleIn: Vector2 }} Branch */
        /** @type {Branch[]} */
        const branches = []
        /** @type {Branch} */
        let branch
        /** @type {Segment[]} */
        let visited
        /** @type {Vector2} */
        let handleIn
        // If all encountered segments in a path are overlaps, we may have
        // two fully overlapping paths that need special handling.
        if (valid && seg._path._overlapsOnly) {
            // TODO: Don't we also need to check for multiple overlaps?
            const path1 = seg._path,
                path2 = seg._intersection._segment._path
            if (path1.compare(path2)) {
                // Only add the path to the result if it has an area.
                if (path1.getArea()) {
                    paths.push(path1.clone(false))
                }
                // Now mark all involved segments as visited.
                visitPath(path1)
                visitPath(path2)
                valid = false
            }
        }
        // Do not start with invalid segments (segments that were already
        // visited, or that are not going to be part of the result).
        // eslint-disable-next-line no-unmodified-loop-condition
        while (valid) {
            // For each segment we encounter, see if there are multiple
            // crossings, and if so, pick the best one:
            const first = !path
            const crossings = getCrossingSegments(seg, first, starts, operator)
            // Get the other segment of the first found crossing.
            const other = crossings.shift()
            finished = !first && (isStart(seg, starts) || isStart(other, starts))
            const cross = !finished && other
            if (first) {
                path = new BezierPath()
                // Clear branch to start a new one with each new path.
                branch = null
            }
            if (finished) {
                // If we end up on the first or last segment of an operand,
                // copy over its closed state, to support mixed open/closed
                // scenarios as described in #1036
                if (seg.isFirst() || seg.isLast()) {
                    closed = seg._path._closed
                }
                seg._visited = true
                break
            }
            if (cross && branch) {
                // If we're about to cross, start a new branch and add the
                // current one to the list of branches.
                branches.push(branch)
                branch = null
            }
            if (!branch) {
                // Add the branch's root segment as the last segment to try,
                // to see if we get to a solution without crossing.
                if (cross) {
                    crossings.push(seg)
                }
                branch = {
                    start: path._segments.length,
                    crossings: crossings,
                    visited: visited = [],
                    handleIn: handleIn,
                }
            }
            if (cross) {
                seg = other
            }
            // If an invalid segment is encountered, go back to the last
            // crossing and try other possible crossings, as well as not
            // crossing at the branch's root.
            if (!isValid(seg, operator)) {
                // Remove the already added segments, and mark them as not
                // visited so they become available again as options.
                path.removeSegments(branch.start)
                for (let j = 0, k = visited.length; j < k; j++) {
                    visited[j]._visited = false
                }
                visited.length = 0
                // Go back to the branch's root segment where the crossing
                // happened, and try other crossings. Note that this also
                // tests the root segment without crossing as it is added to
                // the list of crossings when the branch is created above.
                do {
                    seg = branch && branch.crossings.shift()
                    if (!seg || !seg._path) {
                        seg = null
                        // If there are no segments left, try previous
                        // branches until we find one that works.
                        branch = branches.pop()
                        if (branch) {
                            visited = branch.visited
                            handleIn = branch.handleIn
                        }
                    }
                } while (branch && !isValid(seg, operator))
                if (!seg) {
                    break
                }
            }
            // Add the segment to the path, and mark it as visited.
            // But first we need to look ahead. If we encounter the end of
            // an open path, we need to treat it the same way as the fill of
            // an open path would: Connecting the last and first segment
            // with a straight line, ignoring the handles.
            const next = seg.getNext()
            path.add(
                new Segment().initWithPoints(
                    seg._point,
                    handleIn,
                    next && seg._handleOut
                )
            )
            seg._visited = true
            visited.push(seg)
            // If this is the end of an open path, go back to its first
            // segment but ignore its handleIn (see above for handleOut).
            seg = next || seg._path.getFirstSegment()
            handleIn = next && next._handleIn
        }
        if (finished) {
            if (closed) {
                // Carry over the last handleIn to the first segment.
                path.getFirstSegment().setHandleIn(handleIn)
                path.setClosed(closed)
            }
            // Only add finished paths that cover an area to the result.
            if (path.getArea() !== 0) {
                paths.push(path)
            }
        }
    }
    return paths
}

/**
 * @param {Segment} seg
 * @param {OperatorLUT} operator
 */
function isValid(seg, operator) {
    /** @type {WindingInfo} */
    let winding
    return !!(seg && !seg._visited && (!operator
        || operator[(winding = seg._winding || {}).winding]
            // Unite operations need special handling of segments
            // with a winding contribution of two (part of both
            // areas), which are only valid if they are part of the
            // result's contour, not contained inside another area.
            && !(operator[BooleanOperation.UNION - 10] && winding.winding === 2
                // No contour if both windings are non-zero.
                && winding.windingL && winding.windingR)))
}

/**
 * @param {Segment} seg
 * @param {Segment[]} starts
 */
function isStart(seg, starts) {
    if (seg) {
        for (let i = 0, l = starts.length; i < l; i++) {
            if (seg === starts[i]) {
                return true
            }
        }
    }
    return false
}

/**
 * @param {BezierPath} path
 */
function visitPath(path) {
    const segments = path._segments
    for (let i = 0, l = segments.length; i < l; i++) {
        segments[i]._visited = true
    }
}

/**
 * If there are multiple possible intersections, find the ones that's
 * either connecting back to start or are not visited yet, and will be
 * part of the boolean result:
 * @param {Segment} segment
 * @param {boolean} collectStarts
 * @param {Segment[]} starts
 * @param {OperatorLUT} operator
 */
function getCrossingSegments(segment, collectStarts, starts, operator) {
    let inter = segment._intersection
    const start = inter
    /** @type {Segment[]} */
    const crossings = []
    if (collectStarts) {
        starts.length = 0
        starts[0] = segment
    }

    if (inter) {
        collect(inter, null, segment, crossings, collectStarts, starts, operator)
        // Find the beginning of the linked intersections and loop all
        // the way back to start, to collect all valid intersections.
        while (inter && inter._previous) {
            inter = inter._previous
        }
        collect(inter, start, segment, crossings, collectStarts, starts, operator)
    }
    return crossings
}

/**
 * @param {CurveLocation} inter
 * @param {CurveLocation} end
 * @param {Segment} segment
 * @param {Segment[]} crossings
 * @param {boolean} collectStarts
 * @param {Segment[]} starts
 * @param {OperatorLUT} operator
 */
function collect(inter, end, segment, crossings, collectStarts, starts, operator) {
    while (inter && inter !== end) {
        const other = inter._segment
        const path = other && other._path
        if (path) {
            const next = other.getNext() || path.getFirstSegment()
            const nextInter = next._intersection
            // See if this segment and the next are not visited yet,
            // or are bringing us back to the start, and are both
            // valid, meaning they're part of the boolean result.
            if (other !== segment && (isStart(other, starts)
                || isStart(next, starts)
                || next && (isValid(other, operator) && (isValid(next, operator)
                    // If next segment isn't valid, its intersection
                    // to which we may switch may be, so check that.
                    || nextInter && isValid(nextInter._segment, operator))))
            ) {
                crossings.push(other)
            }
            if (collectStarts) {
                starts.push(other)
            }
        }
        inter = inter._next
    }
}

/**
 * Reorients the specified paths.
 *
 * @param {BezierPath[]} paths the paths of which the orientation needs to be
 *     reoriented
 * @param {(w: number) => boolean} isInside determines if the inside of a path is filled.
 *     For non-zero fill rule this function would be implemented as follows:
 *
 *     function isInside(w) {
 *       return w != 0;
 *     }
 * @param {boolean} [clockwise] if provided, the orientation of the root
 *     paths will be set to the orientation specified by `clockwise`,
 *     otherwise the orientation of the largest root child is used.
 * @returns the reoriented paths
 */
function reorientPaths(paths, isInside, clockwise) {
    const length = paths && paths.length
    if (length) {
        /** @type {{ [id: number]: { container: BezierPath, winding: -1|1, index: number } }} */
        const lookup = Base_each(paths, function (path, i) {
            // Build a lookup table with information for each path's
            // original index and winding contribution.
            this[path._id] = {
                container: null,
                winding: path.isClockwise() ? 1 : -1,
                index: i
            }
        }, {})
        // Now sort the paths by their areas, from large to small.
        const sorted = paths.slice().sort((a, b) => (abs(b.getArea()) - abs(a.getArea())))
        // Get reference to the first, largest path and insert it
        // already.
        const first = sorted[0]
        // create lookup containing potentially overlapping path bounds
        const collisions = findItemBoundsCollisions(
            sorted,
            null,
            GEOMETRIC_EPSILON
        )
        if (clockwise == null) {
            clockwise = first.isClockwise()
        }
        // Now determine the winding for each path, from large to small.
        for (let i = 0; i < length; i++) {
            const path1 = sorted[i]
            const entry1 = lookup[path1._id]
            let containerWinding = 0
            const indices = collisions[i]
            if (indices) {
                /** @type {Vector2} */
                let point = null // interior point, only get it if required.
                for (let j = indices.length - 1; j >= 0; j--) {
                    if (indices[j] < i) {
                        point = point || path1.getInteriorPoint()
                        const path2 = sorted[indices[j]]
                        // As we run through the paths from largest to
                        // smallest, for any current path, all potentially
                        // containing paths have already been processed and
                        // their orientation fixed. To achieve correct
                        // orientation of contained paths based on winding,
                        // find one containing path with different
                        // "insideness" and set opposite orientation.
                        if (path2.contains(point)) {
                            const entry2 = lookup[path2._id]
                            containerWinding = entry2.winding
                            entry1.winding += containerWinding
                            entry1.container = entry2.exclude
                                ? entry2.container
                                : path2
                            break
                        }
                    }
                }
            }
            // Only keep paths if the "insideness" changes when crossing the
            // path, e.g. the inside of the path is filled and the outside
            // is not, or vice versa.
            if (isInside(entry1.winding) === isInside(containerWinding)) {
                entry1.exclude = true
                // No need to delete excluded entries. Setting to null is
                // enough, as #setChildren() can handle arrays with gaps.
                paths[entry1.index] = null
            } else {
                // If the containing path is not excluded, we're done
                // searching for the orientation defining path.
                const container = entry1.container
                path1.setClockwise(
                    container
                        ? !container.isClockwise()
                        : clockwise
                )
            }
        }
    }
    return paths
}

/**
 * @param {BezierPath[]} paths
 * @param {boolean} simplify
 */
function createResult(paths, simplify) {
    let result = new BezierShape()
    result.addChildren(paths, true)
    // See if the item can be reduced to just a simple Path.
    result = result.reduce(simplify)
    return result
}

/**
 * @param {CurveLocation} inter
 */
function filterIntersection(inter) {
    // TODO: Change isCrossing() to also handle overlaps (hasOverlap())
    // that are actually involved in a crossing! For this we need proper
    // overlap range detection / merging first... But as we call
    // #resolveCrossings() first in boolean operations, removing all
    // self-touching areas in paths, this works for the known use cases.
    // The ideal implementation would deal with it in a way outlined in:
    // https://github.com/paperjs/paper.js/issues/874#issuecomment-168332391
    return inter.hasOverlap() || inter.isCrossing()
}

/**
 * @param {BezierShape | BezierPath} path
 * @returns {BezierPath[]}
 */
function getPaths(path) {
    return path._children || [path]
}

/* [/boolean] */

const POINT_CLOSENESS_THRESHOLD = 1e-10 // very precise in case there are close yet not merged vertices
// const PARAM_CLOSENESS_THRESHOLD = 1e-5

/**
 * @param {number} a
 * @param {number} b
 * @param {number} threshold
 */
export function areNumbersCloseWithThreshold(a, b, threshold = POINT_CLOSENESS_THRESHOLD) {
    return ((a - b) >= 0)
        ? (a - b) <= threshold
        : (b - a) <= threshold

}

/**
 * @param {number} a
 * @param {number} b
 */
function areNumbersClose(a, b) {
    return areNumbersCloseWithThreshold(a, b, POINT_CLOSENESS_THRESHOLD)
}
