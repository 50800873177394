import { BlendMode, MergeMode, ShapeType, GradientFillType, GradientStrokeType } from '@phase-software/lottie-js'
import {
  IRBlendMode,
  IRBooleanType,
  IRPaintType,
  IRNodeType,
  IREffectType,
  IRImageMode,
  IRGradientType,
  IRFill,
  IRStroke
} from '../ir'
import { Transform2D } from '../Transform2D'

export const blendModeMap = {
  [IRBlendMode.PASS_THROUGH]: BlendMode.NORMAL,
  [IRBlendMode.NORMAL]: BlendMode.NORMAL,
  [IRBlendMode.DARKEN]: BlendMode.DARKEN,
  [IRBlendMode.MULTIPLY]: BlendMode.MULTIPLY,
  [IRBlendMode.COLOR_BURN]: BlendMode.COLOR_BURN,
  [IRBlendMode.LIGHTEN]: BlendMode.LIGHTEN,
  [IRBlendMode.SCREEN]: BlendMode.SCREEN,
  [IRBlendMode.COLOR_DODGE]: BlendMode.COLOR_DODGE,
  [IRBlendMode.OVERLAY]: BlendMode.OVERLAY,
  [IRBlendMode.SOFT_LIGHT]: BlendMode.SOFT_LIGHT,
  [IRBlendMode.HARD_LIGHT]: BlendMode.HARD_LIGHT,
  [IRBlendMode.DIFFERENCE]: BlendMode.DIFFERENCE,
  [IRBlendMode.EXCLUSION]: BlendMode.EXCLUSION,
  [IRBlendMode.HUE]: BlendMode.HUE,
  [IRBlendMode.SATURATION]: BlendMode.SATURATION,
  [IRBlendMode.COLOR]: BlendMode.COLOR,
  [IRBlendMode.LUMINOSITY]: BlendMode.LUMINOSITY,
  [IRBlendMode.DIVIDE]: BlendMode.DIVIDE,
  [IRBlendMode.ADD]: BlendMode.ADD,
  [IRBlendMode.SUBTRACT]: BlendMode.SUBTRACT,
  [IRBlendMode.DISSOLVE]: BlendMode.DISSOLVE
}

export const mergeModeMap = {
  [IRBooleanType.NONE]: MergeMode.NORMAL,
  [IRBooleanType.UNION]: MergeMode.ADD,
  [IRBooleanType.SUBTRACT]: MergeMode.SUBTRACT,
  [IRBooleanType.INTERSECT]: MergeMode.INTERSECT,
  [IRBooleanType.DIFFERENCE]: MergeMode.EXCLUDE
}

export const strokeTypeMap = {
  [IRPaintType.SOLID]: ShapeType.STROKE,
  [IRPaintType.GRADIENT]: ShapeType.GRADIENT_STROKE
}

export const shapeTypeMap = {
  [IRNodeType.RECTANGLE]: ShapeType.RECTANGLE,
  [IRNodeType.ELLIPSE]: ShapeType.ELLIPSE,
  [IRNodeType.PATH]: ShapeType.PATH
}

export const fillTypeMap = {
  [IRPaintType.SOLID]: ShapeType.FILL,
  [IRPaintType.GRADIENT]: ShapeType.GRADIENT_FILL
}

export const effectTypeMap = {
  [IREffectType.TRIM_PATH]: ShapeType.TRIM
}

export const gradientFillTypeMap = {
  [IRGradientType.LINEAR]: GradientFillType.LINEAR,
  [IRGradientType.RADIAL]: GradientFillType.RADIAL,
  [IRGradientType.ANGULAR]: GradientFillType.RADIAL,
  [IRGradientType.DIAMOND]: GradientFillType.RADIAL
}

export const gradientStrokeTypeMap = {
  [IRGradientType.LINEAR]: GradientStrokeType.LINEAR,
  [IRGradientType.RADIAL]: GradientStrokeType.RADIAL,
  [IRGradientType.ANGULAR]: GradientStrokeType.RADIAL,
  [IRGradientType.DIAMOND]: GradientStrokeType.RADIAL
}

export const getBlendMode = irBlendMode => blendModeMap[irBlendMode]
export const getMergeMode = irBooleanType => mergeModeMap[irBooleanType]
export const getStrokeShapeType = irPaintType => strokeTypeMap[irPaintType]
export const getNodeShapeType = irNodeType => shapeTypeMap[irNodeType]
export const getFillShapeType = irPaintType => fillTypeMap[irPaintType]
export const getEffectShapeType = irEffectType => effectTypeMap[irEffectType]
export const getFillGradientType = irGradientType => gradientFillTypeMap[irGradientType]
export const getStrokeGradientType = irGradientType => gradientStrokeTypeMap[irGradientType]

export const getLayerGradientType = (layer, irGradientType) => {
  switch (layer.constructor) {
    case IRFill:
      return getFillGradientType(irGradientType)
    case IRStroke:
      return getStrokeGradientType(irGradientType)
  }
}

export const getGradientHandlesPosition = (width, height, isLinear, gradientTransform) => {
  const transform = new Transform2D(...gradientTransform)
  transform.affine_inverse().scale(width, height)
  return {
    center: transform.xform(isLinear ? [0, 0.5] : [0.5, 0.5]),
    bottom: transform.xform([1, 0.5]),
    left: transform.xform([0.5, 1])
  }
}

export const getShapeScale = (shapeDimensions, shapeScale, imageDimensions, imageMode) => {
  const widthScale = (shapeDimensions[0] / imageDimensions[0]) * shapeScale[0]
  const heightScale = (shapeDimensions[1] / imageDimensions[1]) * shapeScale[1]
  switch (imageMode) {
    case IRImageMode.FILL: {
      const scale = Math.max(widthScale, heightScale)
      return [scale * 100, scale * 100]
    }
    case IRImageMode.FIT: {
      const scale = Math.min(widthScale, heightScale)
      return [scale * 100, scale * 100]
    }
    case IRImageMode.STRETCH: {
      return [widthScale * 100, heightScale * 100]
    }
  }
}

export const getImageOffset = (shapeDimensions, shapeScale, imageScale, origin) => {
  const scaledWidth = shapeDimensions[0] * imageScale[0]
  const scaledHeight = shapeDimensions[1] * imageScale[1]
  const deltaX = origin[0] / shapeDimensions[0] - 0.5
  const deltaY = origin[1] / shapeDimensions[1] - 0.5
  return [(scaledWidth * deltaX) / shapeScale[0], (scaledHeight * deltaY) / shapeScale[1]]
}

export const getImageScale = (imageDimensions, shapeDimensions, shapeScale, imageMode) => {
  const widthScale = (imageDimensions[0] / shapeDimensions[0]) * shapeScale[0]
  const heightScale = (imageDimensions[1] / shapeDimensions[1]) * shapeScale[1]
  switch (imageMode) {
    case IRImageMode.FILL: {
      const scale = Math.min(widthScale, heightScale)
      return [scale, scale]
    }
    case IRImageMode.FIT: {
      const scale = Math.max(widthScale, heightScale)
      return [scale, scale]
    }
    case IRImageMode.STRETCH: {
      return [widthScale, heightScale]
    }
  }
}

/**
 * copy from transition-manager
 * Match the gradientStops in-place for each of them to make sure that
 * when doing animation we will get the same amount of gradientStops
 * and get the interpolation data for position and color.
 * @param {Array} bigGradient
 * @param {Array} smallGradient
 */
export const matchGradientStops = (bigGradient, smallGradient) => {
  const sorted = smallGradient.slice().sort((a, b) => a.position - b.position)
  for (let i = sorted.length; i < bigGradient.length; i++) {
    const position = bigGradient[i].position
    let ref = null
    if (position <= sorted[0].position) {
      // Use left one as the ref gradient stop
      ref = sorted[0]
    } else if (position >= sorted[sorted.length - 1].position) {
      // Use right one as the ref gradient stop
      ref = sorted[sorted.length - 1]
    } else {
      // Find the closest gradient stop
      for (let j = 0; j < sorted.length - 1; j++) {
        const curr = sorted[j]
        const next = sorted[j + 1]
        const left = position - curr.position
        const right = next.position - position
        if (left >= 0 && right > 0) {
          if (left <= right) {
            ref = curr
          } else {
            ref = next
          }
          break
        }
      }
    }
    smallGradient[i] = ref
  }
}
