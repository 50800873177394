import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import { WorkspaceType } from '@phase-software/types'

import { useAccessControl } from '../../access-control'
import { PROJECT_CONTEXT_MENU_OPTIONS, PROJECT_OPTIONS } from '../../constants/projectConstants'
import { ProjectFieldsFragment } from '../../generated/graphql'
import { useModal, useSetModal } from '../../providers/ModalProvider'
import { useProjectPermissionContext } from '../../providers/ProjectPermissionProvider'
import { WorkspaceData } from '../../providers/WorkspaceContextProvider'
import { getWorkspaceProjectPath } from '../../utils/pathGenerators'
import DeleteProjectDialog from '../modals/DeleteProjectDialog'
import RenameProjectDialog from '../modals/RenameProjectDialog'
import { ContextMenu } from '../shared'
import { MenuOptionProps } from '../shared/Menu/Menu.types'
import { isSeparatorOption, removeConsecutiveSeparators, translateMenuOptions } from '../shared/Menu/utils'

export type ProjectContextMenuProps = {
  workspaceType: WorkspaceData['type']
  workspaceSlug: WorkspaceData['slug']
  id: ProjectFieldsFragment['id']
  name: ProjectFieldsFragment['name']
}
const ProjectContextMenu = ({ workspaceType, workspaceSlug, name, id }: ProjectContextMenuProps) => {
  const { t } = useTranslation('workspace')
  const history = useHistory()
  const { userHasPermission } = useAccessControl()
  const { projectWithPermission } = useProjectPermissionContext()

  const {
    open,
    data: { cursorPosition }
  } = useModal((o: any) => o[`ProjectContextMenu-${id}`])
  const { closeModal: closeContextMenu } = useSetModal(`ProjectContextMenu-${id}`)

  const [renameModalOpen, setRenameModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  const menuOptions = useMemo(() => {
    const filteredOptions = PROJECT_CONTEXT_MENU_OPTIONS.filter((option) => {
      if (isSeparatorOption(option)) return true

      if (workspaceType === WorkspaceType.PERSONAL && option.value === PROJECT_OPTIONS.COPY_LINK) {
        return false
      }

      return !option.permission || userHasPermission(option.permission, projectWithPermission)
    })
    return removeConsecutiveSeparators(filteredOptions)
  }, [projectWithPermission, userHasPermission, workspaceType])

  const translatedMenuOptions = useMemo(() => {
    return translateMenuOptions(t, menuOptions, { ns: 'workspace' })
  }, [t, menuOptions])

  const handleSelectOption = (option: MenuOptionProps) => {
    switch (option.value) {
      case PROJECT_OPTIONS.OPEN_IN_NEW_TAB:
        window.open(history.createHref({ pathname: getWorkspaceProjectPath(workspaceType, workspaceSlug, id) }))
        break
      case PROJECT_OPTIONS.RENAME:
        setRenameModalOpen(true)
        break
      case PROJECT_OPTIONS.DELETE:
        setDeleteModalOpen(true)
        break
      case PROJECT_OPTIONS.COPY_LINK: {
        const fullUrl = new URL(
          history.createHref({ pathname: getWorkspaceProjectPath(workspaceType, workspaceSlug, id) }),
          window.location.origin
        )
        navigator.clipboard.writeText(fullUrl.toString())
        break
      }
      default:
        break
    }
  }

  return (
    <>
      <ContextMenu
        open={open}
        options={translatedMenuOptions}
        onSelect={handleSelectOption}
        onClose={closeContextMenu}
        cursorPosition={cursorPosition}
      />
      <RenameProjectDialog
        id={id}
        originalName={name || ''}
        open={renameModalOpen}
        onClose={() => setRenameModalOpen(false)}
      />
      <DeleteProjectDialog id={id} name={name} open={deleteModalOpen} onClose={() => setDeleteModalOpen(false)} />
    </>
  )
}

export default ProjectContextMenu
