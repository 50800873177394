import { cloneDeep } from 'lodash'
import { Events, Hover } from '@phase-software/data-store'
import { EditMode, ToolType, PointShape } from '@phase-software/types'
import { NO_COMMIT, NO_FIRE_NO_COMMIT, FlagsEnum } from '@phase-software/data-utils'
import { Vector2, Rect2 } from '../../math'
/** @todo encapsulate pane mesh helper  */
import {
    applyMeshHelperPane,
    cleanMeshHelperPane,
    appendGhostSegment,
    snapGhostSegment,
    hideGhostSegment,
    enableGhostPoint,
    disableGhostPoint,
    stopHighlightEdges,
    highlightEdges,
    drawAssistLinesOnScreen,
    markMeshDirty,
} from '../../panes/meshHelper'
import { createElement } from '../creationTools'
import { SNAP_TARGET_TYPE } from '../handles/SnappingPath'
import { findHoverVertices } from '../../visual_server/HitTest'

/** @typedef {import('@phase-software/data-store/src/Element').Element} Element */
/** @typedef {import('../../visual_server/VisualServer').VisualServer} VisualServer */
/** @typedef {import('@phase-software/data-utils/src/mesh/Mesh').Mesh} Mesh */
/** @typedef {import('@phase-software/data-utils/src/AABB').AABB} AABB */
/** @typedef {import('@phase-software/data-utils/src/mesh/Vertex').Vertex} Vertex */
/** @typedef {import('@phase-software/data-utils/src/mesh/Edge').Edge} Edge */
/** @typedef {import('./../handles/Snapping').Snapping} Snapping */
/** @typedef {import('@phase-software/data-store/src/geometry').Geometry} Geometry */

/** @type {VisualServer} */
let _visualServer = null
/** @type {Mesh} */
let _mesh = null
/** @type {Element} */
let _element = null
/** @type {Geometry} */
let _geometry = null

let _isCreatingElement = false

const vec2Buffer = new Vector2()
const boundsBuf = new Rect2()
// const elementChangesBuf = {}


/**
 * @todo There is no definition for setLocalCursorState
 * @param {VisualServer} visualServer
 * @param {import('..').SetLocalCursorStateFn} setLocalCursorState
 */
export function initVertexSelection(visualServer, setLocalCursorState) {
    _visualServer = visualServer
    _watchBasicEvents(setLocalCursorState)
    _watchModifiedEvents(setLocalCursorState)
    _watchPenToolEvents(setLocalCursorState)
}

const _meshChangeCallback = () => {
    markMeshDirty()
}


/**
 * Updates the current editing element
 * @todo Move this to another file after we support edges and faces selection
 * @param {Element} element
 */
function _assignPathCtrl(element) {
    if (!element) {
        if (_geometry) {
            _geometry.off('MESH_CHANGES', _meshChangeCallback)
            _geometry = null
        }
        if (_mesh) {
            _mesh = null
        }
        if (_element) {
            _element.off('size', markMeshDirty)
            _element.off('TRIGGER_VECTOR_FORCE_UPDATE', markMeshDirty)
            _isCreatingElement = false
            _element.removeIfEmpty()
        }
        if (_isCreatingElement) {
            console.log('should correct the origin')
        }
        _element = null
        cleanMeshHelperPane()
        _cleanSnappingUI()
        _visualServer.snappingPath.cancel()
        return
    }

    _element = element
    _element.on('TRIGGER_VECTOR_FORCE_UPDATE', markMeshDirty)
    _geometry = _element.get('geometry')
    _geometry.execute('startEditing')
    _mesh = _geometry.get('mesh')
    _geometry.on('MESH_CHANGES', _meshChangeCallback)
    applyMeshHelperPane(_element)
    _visualServer.snappingPath.assign(_element, _mesh, _visualServer.getRenderItemOfElement(_element))
}

/**
 * @param {Element} element
 * @param {Vector2} point
 * @returns {Vertex|null}
 */
function _findVertexAt(element, point) {
    const selectedPathMash = element.get('mesh')
    const vertices = findHoverVertices(selectedPathMash, _visualServer.getRenderItemOfElement(element), _visualServer.viewport, point)
    const filteredVertices = []
    const curveItemFilterMask = (1 << FlagsEnum.CONNECT_SELECTED) | (1 << FlagsEnum.SELECTED)
    for (const vertex of vertices) {
        if (vertex.isFlagged(FlagsEnum.CURVE_VERT)) {
            if (vertex.adjacentMainVertex) {
                if ((vertex.adjacentMainVertex.flags & curveItemFilterMask) === 0) {
                    continue
                }

                if (vertex.adjacentMainVertex.pos.eq(vertex.pos)) {
                    continue
                }
            } else {
                console.warn(`There is a curve control without real vertex`)
            }
        }
        filteredVertices.push(vertex)
    }
    return filteredVertices.length > 0 ? filteredVertices.sort((a, b) => a.index - b.index)[0] : null
}

/**
 * Add a listener for basic interaction events
 *  @param {import('..').SetLocalCursorStateFn} setLocalCursorState
 */
function _watchBasicEvents(setLocalCursorState) {
    const viewport = _visualServer.viewport
    const dataStore = _visualServer.dataStore

    dataStore.selection.on('SELECT', (changes) => {
        const editMode = dataStore.get('editMode')
        if (editMode === EditMode.SHAPE) {
            const elements = changes.get('elements')
            if (elements && (!elements.after.length !== 1 || elements.after[0] !== _element)) {
                dataStore.eam.activateElementEditMode(NO_COMMIT)
            }
        }
    })

    dataStore.selection.on('SELECT_CELL', (changes) => {
        const before = changes.get('vertices').before
        if (before.length === 1) {
            if (!_mesh) {
                console.error('Can not deselect node not in the path edit mode')
                return
            }
            if (
                _mesh.vertices.has(before[0])
                && !before[0].isFlagged(FlagsEnum.CURVE_VERT)
                && before[0].upperTierIDs.size === 0
            ) {
                _mesh.deleteCellsByVertex(before)
            }
        }
    })

    let _previousEditMode
    dataStore.on('editMode', (editMode) => {
        if (editMode === EditMode.SHAPE) {
            const selected = _visualServer.selection.single

            if (selected && selected.element) {
                setLocalCursorState(dataStore.eam.states.activeTool === ToolType.PEN ? 'penDefault' : 'default')
                _assignPathCtrl(selected.element)
            } else {
                console.warn('Entrying ShapeState without selected element')
            }
            if (dataStore.get('editMode') !== EditMode.SHAPE) {
                console.error('The editMode should be shape mode')
            }
        } else if (_previousEditMode === EditMode.SHAPE) {
            _assignPathCtrl(null)
            if (dataStore.get('editMode') !== EditMode.ELEMENT) {
                console.error('The editMode should be element mode')
            }
        }
        _previousEditMode = editMode
    })

    dataStore.eam.on(Events.SELECT_ALL_CELLS, () => {
        dataStore.selection.selectVertices([..._mesh.vertices].filter(v => !v.isFlagged(FlagsEnum.CURVE_VERT)))
    })

    //#region Hand over events

    dataStore.eam.on(Events.HOVER_CREATE_PATH, ({ mousePos }, _, { snapToGrid = true, snapToObject = true }) => {
        enableGhostPoint(mousePos.x, mousePos.y, snapToGrid, snapToObject)
    })

    dataStore.eam.on(Events.SWITCH_BEND_TOOL, ({ mousePos }) => {
        const p = viewport.toWorld(mousePos)
        const vertex = _findVertexAt(_visualServer.selection.first.element, p)
        dataStore.selection.set('hoverVertex', vertex)
        if (vertex) {
            if (vertex.isFlagged(FlagsEnum.CURVE_VERT)) {
                dataStore.eam.changeHover(Hover.CURVE_CONTROL)
                setLocalCursorState('convertPath')
            } else {
                dataStore.eam.changeHover(Hover.VERTEX)
                setLocalCursorState('default')
            }
        } else {
            setLocalCursorState('default')
        }
    })

    dataStore.eam.on(Events.HOVER_CELL, ({ mousePos }, { shift = false }, { snapToGrid = true, snapToObject = true }) => {
        const isActivePenTool = dataStore.eam.states.activeTool === ToolType.PEN
        const selectedVerts = dataStore.selection.get('vertices')
        const appending = _canCreateEdge(selectedVerts)
        const worldMousePos = viewport.toWorld(mousePos)

        _cleanSnappingUI()
        drawAssistLinesOnScreen([])
        // Setting cursor
        if (isActivePenTool) {
            if (snapToGrid) {
                worldMousePos.round()
            }
            const targetOnScreen = viewport.toScreen(worldMousePos)
            enableGhostPoint(targetOnScreen.x, targetOnScreen.y, snapToGrid)
            setLocalCursorState('penDefault')
        } else {
            setLocalCursorState('default')
        }

        if (dataStore.get('editMode') !== EditMode.SHAPE) {
            return
        }
        const vertex = _findVertexAt(_visualServer.selection.first.element, worldMousePos)

        dataStore.selection.set('hoverVertex', vertex)
        highlightEdges([])
        if (vertex) {

            if (vertex.isFlagged(FlagsEnum.CURVE_VERT)) {
                setLocalCursorState('default')
                dataStore.eam.changeHover(Hover.CURVE_CONTROL)
                disableGhostPoint(false)
            } else {
                let vertType = Hover.VERTEX
                if (!(vertex.upperTierIDs.size > 1)) {
                    vertType = Hover.ENDPOINT
                }
                dataStore.eam.changeHover(vertType)
                if (vertType !== Hover.ENDPOINT) {
                    setLocalCursorState('default')
                } else if (isActivePenTool) {
                    if (appending) {
                        setLocalCursorState('penClose')
                    } else {
                        setLocalCursorState('penOpen')
                    }
                }
                disableGhostPoint(isActivePenTool && snapToObject)
            }

            dataStore.selection.set('snapTarget', null)
            return
        }

        if (!isActivePenTool) {
            dataStore.selection.set('snapTarget', null)
            return
        }

        // Search for closest segement first
        const target = _visualServer.snappingPath.snapMousePos(worldMousePos, snapToGrid, snapToObject)
        const worldMousePosClone = target.position.clone()
        let snappingToDiagonal = false
        if (isActivePenTool && shift && appending) {
            const basePointWorld = _getVertexWorldPos(_element, _mesh, selectedVerts[0])
            _visualServer.snappingPath.constraint(worldMousePos, basePointWorld, worldMousePosClone, snapToGrid)
            drawAssistLinesOnScreen([[viewport.toScreen(basePointWorld), viewport.toScreen(worldMousePosClone)]])
            snappingToDiagonal = true
            if (!worldMousePosClone.equals(target.position)) _cleanSnappingUI()
            target.position.copy(worldMousePosClone)
        } else {
            drawAssistLinesOnScreen([])
        }

        switch (target.type) {
            case SNAP_TARGET_TYPE.Edge: {
                if (!target.edge) {
                    console.warn(`The edge of the target is missing`)
                }
                const targetOnScreen = viewport.toScreen(target.position)
                enableGhostPoint(targetOnScreen.x, targetOnScreen.y, snapToGrid)
                if (!snappingToDiagonal) {
                    dataStore.eam.changeHover(Hover.EDGE)
                    dataStore.selection.set('snapTarget', target)
                    setLocalCursorState('penAdd')
                    highlightEdges([target.edge.id])
                }
                break
            }

            case SNAP_TARGET_TYPE.SameAxis: {
                const targetOnScreen = viewport.toScreen(target.position)
                enableGhostPoint(targetOnScreen.x, targetOnScreen.y, snapToGrid)
                break
            }

            case SNAP_TARGET_TYPE.NONE: {
                if (snappingToDiagonal) {
                    const targetOnScreen = viewport.toScreen(worldMousePosClone)
                    enableGhostPoint(targetOnScreen.x, targetOnScreen.y, snapToGrid)
                } else {
                    target.position.copy(worldMousePos)
                }
            }
        }
        dataStore.selection.set('snapTarget', target)
    })

    //#endregion
    dataStore.eam.on(Events.SELECT_CELL, () => {
        const selection = _visualServer.dataStore.selection
        const vertex = selection.get('hoverVertex')
        const selectedVertex = selection.get('vertices')
        if (vertex) {
            if (selectedVertex.indexOf(vertex) === -1) {
                selection.selectVertices([vertex])
            }
        }
    })

    dataStore.eam.on(Events.DESELECT_CELL, () => {
        _visualServer.dataStore.selection.selectVertices([])
    })

    _visualServer.dataStore.eam.on(Events.TOGGLE_CELL_SELECTION, () => {
        const selection = _visualServer.dataStore.selection

        const vertex = selection.get('hoverVertex')
        if (vertex) {
            selection.toggleVertices([vertex])
        }
    })
    _visualServer.dataStore.eam.on(Events.MOVE_CELL_SELECTION_KEY, (offset) => {
        _moveSelection(offset)
    })
}

/**
 * @param {Function}setLocalCursorState
 */
function _watchModifiedEvents(setLocalCursorState) {
    const dataStore = _visualServer.dataStore
    const selection = dataStore.selection
    const viewport = _visualServer.viewport
    //#region Move selected cells by mouse position
    {
        const _lastMousePos = new Vector2()
        /** @todo Should distinguish between mouse click and mouse drag by the event */
        const _dragDistance = new Vector2()
        /** @type {boolean} The hover hasn't been selected at begining of a dragging */
        let unSelectedFirst
        // END_xx can not have input parameter, so add this variable to store the last shift value
        let isPressingShift = false
        const _dragThreshold = 1.e-5

        /** @type {Snapping} */
        const snapping = _visualServer.snapping
        const _initMousePos = new Vector2()
        const hoverPosInWorld = new Vector2()
        let hoverVert
        let worldTransform
        dataStore.eam
            .on(Events.START_DRAG_CELL, (e, { shift, alt }) => {
                // Get hover vertex
                hoverVert = selection.get('hoverVertex')
                if (!hoverVert) {
                    e.handled = false
                    return
                }
                // Special case: Dragging will not occur if the vertex is about to be removed
                if (
                    hoverVert.isFlagged(FlagsEnum.CURVE_VERT)
                    && hoverVert.adjacentMainVertex
                    && hoverVert.adjacentMainVertex.upperTierIDs.size < 1
                ) {
                    e.handled = false
                    return
                }
                // If pressing alt
                if (alt && hoverVert.isFlagged(FlagsEnum.CURVE_VERT)) {
                    _mesh.prepareVertexPropertyChanges(hoverVert.adjacentMainVertex.id, 'mirror', PointShape.INDEPENDENT)
                }
                // Process clicking on a vertex
                isPressingShift = shift
                const selectedVert = selection.get('vertices')
                unSelectedFirst = selectedVert.indexOf(hoverVert) === -1
                if (unSelectedFirst) {
                    // The hover vertex is not selected
                    if (isPressingShift) {
                        // add the hover vertex when mousedown
                        selection.addVertices([hoverVert])
                    } else {
                        selection.selectVertices([hoverVert])
                    }
                }
                // Record the mouse position
                const worldPos = viewport.toWorld(e.mousePos)
                hoverPosInWorld.copy(_getVertexWorldPos(_element, _mesh, hoverVert))
                _initMousePos.copy(worldPos)
                _lastMousePos.copy(hoverPosInWorld)

                _dragDistance.set(0, 0)
                snapping.setSnappingOriginalPos(_lastMousePos)

                worldTransform = _visualServer.getRenderItemOfElement(_element).transform.world
                highlightEdges([])
            })
            .on(Events.UPDATE_DRAG_CELL, ({ mousePos }, { shift }, { snapToGrid = true, snapToObject = true }) => {
                _cleanSnappingUI()
                const worldMousePos = viewport.toWorld(mousePos)
                isPressingShift = shift
                _dragDistance.copy(worldMousePos).sub(_initMousePos)
                const snappingVertex = _findVertexAt(_visualServer.selection.first.element, worldMousePos)
                setLocalCursorState('default')
                onMouseDragVertex(snappingVertex, selection,
                    worldTransform, worldMousePos,
                    hoverVert, hoverPosInWorld, snapToGrid, snapToObject,
                    _lastMousePos, isPressingShift)
            })
            .on(Events.END_DRAG_CELL, () => {
                // Regard as clicking
                if (_dragDistance.length() < _dragThreshold) {
                    const vertex = selection.get('hoverVertex')
                    const selectedVert = selection.get('vertices')
                    if (isPressingShift && selectedVert.indexOf(vertex) !== -1) {
                        // if the hover vertex was not selected,
                        // pressing shift and mouseup will toggle the vertex
                        if (unSelectedFirst) {
                            selection.addVertices([vertex])
                        } else {
                            selection.toggleVertices([vertex])
                        }
                    } else {
                        selection.selectVertices([vertex])
                    }
                }
                drawAssistLinesOnScreen([])
                _cleanSnappingUI()
                snapping.setEndSnapping()
                dataStore.commitUndo()
            })
    }
    //#endregion
    dataStore.eam.on(Events.DELETE_CELL, () => {
        const selectedVerts = selection.get('vertices')
        if (selectedVerts.length === 0) return
        _mesh.deleteCellsByVertex(selectedVerts)
        selection.selectVertices([], NO_COMMIT)
        dataStore.commitUndo()
    })

    dataStore.eam.on(Events.DUPLICATE_CELL, () => {
        const selectedVerts = selection.get('vertices')
        if (selectedVerts.length === 0) return
        const closure = _mesh.closureOfCells(selectedVerts)
        const cellDataList = []
        const world = _visualServer.getRenderItemOfElement(_element).transform.world
        const size = _element.get('size')
        for (const cell of closure) {
            const cellData = cell.save()
            cellData.type = cell.type
            switch (cellData.type) {
                case 'Vertex':
                    {
                        const vertexPos = _mesh.getVertPos(cellData.id)
                        vec2Buffer.set(vertexPos[0], vertexPos[1])
                        world.xform(vec2Buffer, vec2Buffer)
                        cellData.pos[0] = vec2Buffer.x
                        cellData.pos[1] = vec2Buffer.y
                    }
                    break
                case 'Edge':
                    if (cellData.curve) {
                        const curve = _mesh.getEdgeCurve(cellData.id)
                        // curve0
                        vec2Buffer.set(curve[0][0], curve[0][1])
                        world.xform(vec2Buffer, vec2Buffer)
                        cellData.curve[0][0] = vec2Buffer.x
                        cellData.curve[0][1] = vec2Buffer.y
                        // curve1
                        vec2Buffer.set(curve[1][0], curve[1][1])
                        world.xform(vec2Buffer, vec2Buffer)
                        cellData.curve[1][0] = vec2Buffer.x
                        cellData.curve[1][1] = vec2Buffer.y
                    }
                    break
            }
            cellDataList.push(cellData)
        }
        const vpBounds = _visualServer.viewport.rectW
        const centroid = vec2Buffer
        const contentBounds = boundsBuf
        contentBounds.set()
        const offset = new Vector2()
        centroid.set(0, 0)
        let numPts = 0
        const mediator = new Vector2() // Conver two types of vectors
        for (let i = 0; i < cellDataList.length; i++) {
            if (cellDataList[i].type === 'Vertex') {
                if (contentBounds.is_zero()) {
                    contentBounds.set(cellDataList[i].pos[0], cellDataList[i].pos[1], 0, 0)
                } else {
                    mediator.set(cellDataList[i].pos[0], cellDataList[i].pos[1])
                    contentBounds.expand_to(mediator)
                }
                centroid.x += cellDataList[i].pos[0]
                centroid.y += cellDataList[i].pos[1]
                numPts++
            }
        }
        centroid.x /= numPts
        centroid.y /= numPts
        if (!vpBounds.intersects(contentBounds)) {
            offset.x = (vpBounds.center.x - centroid.x)
            offset.y = (vpBounds.center.y - centroid.y)
        }
        const newPositions = {}

        for (let i = 0; i < cellDataList.length; i++) {
            const cell = cellDataList[i]
            switch (cell.type) {
                case 'Vertex':
                    vec2Buffer.set(cell.pos[0], cell.pos[1])
                    _world2NodeSpace(vec2Buffer, vec2Buffer)
                    newPositions[cell.id] = [vec2Buffer.x + offset.x, vec2Buffer.y + offset.y]
                    break
                case 'Edge':
                    if (cell.curve) {
                        // curve 0
                        vec2Buffer.set(cell.curve[0][0], cell.curve[0][1])
                        _world2NodeSpace(vec2Buffer, vec2Buffer)
                        cell.curve[0][0] = vec2Buffer.x + offset.x
                        cell.curve[0][1] = vec2Buffer.y + offset.y
                        // curve 1
                        vec2Buffer.set(cell.curve[1][0], cell.curve[1][1])
                        _world2NodeSpace(vec2Buffer, vec2Buffer)
                        cell.curve[1][0] = vec2Buffer.x + offset.x
                        cell.curve[1][1] = vec2Buffer.y + offset.y
                        // Set
                        newPositions[cell.id] = [cell.curve[0], cell.curve[1]]
                    }
                    break
            }
        }
        const newCells = _mesh.duplicateCells(cellDataList, newPositions, size.x, size.y)
        selection.selectVertices(newCells, NO_COMMIT)

        dataStore.commitUndo()
    })
}

/**
 * @todo Should not keep code in module scope
 * @param {Vertex} snappingVertex
 * @param {Selection} selection
 * @param {import('../../math').Transform2D} worldTransform
 * @param {Vector2} worldMousePos
 * @param {Vertex} hoverVert
 * @param {Vector2} mutableHoverVertLastWorldPos
 * @param {boolean} snapToGrid
 * @param {boolean} snapToObject
 * @param {Vector2} lastMousePos
 * @param {boolean} shift
 */
export function onMouseDragVertex(
    snappingVertex, selection,
    worldTransform, worldMousePos,
    hoverVert, mutableHoverVertLastWorldPos, snapToGrid, snapToObject,
    lastMousePos, shift) {

    const selectedVert = selection.get('vertices')
    const viewport = _visualServer.viewport

    const { delta, adjustedMousePos } = _processVertexDragSnapping(snappingVertex, hoverVert, viewport, mutableHoverVertLastWorldPos, snapToGrid, snapToObject, worldMousePos, selectedVert, worldTransform)

    if (shift) {
        const basePointWorld = lastMousePos.clone()
        adjustedMousePos.add(delta)
        _visualServer.snappingPath.constraint(worldMousePos, basePointWorld, adjustedMousePos, snapToGrid)
        drawAssistLinesOnScreen([[viewport.toScreen(lastMousePos), viewport.toScreen(adjustedMousePos)]])
        const newDelta = adjustedMousePos.sub(mutableHoverVertLastWorldPos)
        if (!newDelta.equals(delta)) _cleanSnappingUI()
        delta.copy(newDelta)
    } else {
        drawAssistLinesOnScreen([])
    }

    // Before projecting to local space
    mutableHoverVertLastWorldPos.add(delta)
    _world2NodeSpaceBasis(delta, delta)

    _mesh.moveVertex(
        selectedVert,
        delta.x, delta.y
    )
}

/**
 *
 * @param {Vertex} snappingVertex
 * @param {Vertex} hoverVert
 * @param {import('..').Viewport} viewport
 * @param {Vector2} hoverVertexLastWorldPosition
 * @param {boolean} snapToGrid
 * @param {boolean} snapToObject
 * @param {Vector2} worldMousePos
 * @param {Vertex[]} selectedVert
 * @param {import('../../math').Transform2D} worldTransform
 * @returns {{delta:Vector2, adjustedMousePos:Vector2}}
 */
function _processVertexDragSnapping(snappingVertex, hoverVert, viewport, hoverVertexLastWorldPosition, snapToGrid, snapToObject, worldMousePos, selectedVert, worldTransform) {
    const delta = new Vector2()
    const adjustedMousePos = new Vector2()
    if (snapToObject && snappingVertex && snappingVertex !== hoverVert) {
        highlightEdges([])
        const snapVertPos = _getVertexWorldPos(_element, _mesh, snappingVertex)
        drawAssistLinesOnScreen([[viewport.toScreen(snapVertPos), viewport.toScreen(snapVertPos)]])
        delta.set(snapVertPos.x - hoverVertexLastWorldPosition.x, snapVertPos.y - hoverVertexLastWorldPosition.y)
        adjustedMousePos.copy(snapVertPos)
    } else {
        if (snapToGrid) {
            worldMousePos.round()
        }
        // Search for closest segment first
        const target = _visualServer.snappingPath.snapDraggingVertex(
            worldMousePos,
            hoverVertexLastWorldPosition,
            selectedVert,
            snapToGrid,
            snapToObject,
            _mesh,
            worldTransform
        )
        if (target.type === SNAP_TARGET_TYPE.Edge && target.edge &&
            target.edge.v !== hoverVert && target.edge.w !== hoverVert) {
            delta.set(target.position.x - target.sourcePos.x, target.position.y - target.sourcePos.y)
            highlightEdges([target.edge.id])
            adjustedMousePos.copy(target.position)
        } else {
            highlightEdges([])
            delta.set(worldMousePos.x - hoverVertexLastWorldPosition.x, worldMousePos.y - hoverVertexLastWorldPosition.y)
            adjustedMousePos.copy(worldMousePos)
        }

        if (target.type === SNAP_TARGET_TYPE.SameAxis) {
            delta.set(target.position.x - hoverVertexLastWorldPosition.x, target.position.y - hoverVertexLastWorldPosition.y)
            adjustedMousePos.copy(target.position)
        }
    }

    return { delta, adjustedMousePos }
}

function _cleanSnappingUI() {
    _visualServer.snapping.snapToElementXUI.clear()
    _visualServer.snapping.snapToElementYUI.clear()
}

/**
 *
 * @param {Vector2} mousePos
 * @param {boolean} curved
 * @param {boolean} isSnapGrid
 * @param {import('..').SetLocalCursorStateFn} setLocalCursorState
 */
// eslint-disable-next-line no-unused-vars
function _controlGhostSegment(mousePos, curved, isSnapGrid = true, setLocalCursorState) {
    const hoverVert = _visualServer.dataStore.selection.get('hoverVertex')
    const selectedVert = _visualServer.dataStore.selection.get('vertices')
    if (!_canCreateEdge(selectedVert)) {
        hideGhostSegment()
        return
    }
    if (hoverVert) {
        if (hoverVert.upperTierIDs.size < 2) {
            snapGhostSegment(hoverVert.id, curved)
        } else {
            hideGhostSegment()
        }
    } else {
        /** @type {import('../handles/SnappingPath').SnapTarget} */
        const target = _visualServer.dataStore.selection.get('snapTarget')
        if (target && target.type !== SNAP_TARGET_TYPE.Edge) {
            const targetOnScreen = _visualServer.viewport.toScreen(target.position)
            // Don't transform the mouse position here, because it might haven't changed since the last frame
            appendGhostSegment(targetOnScreen.x, targetOnScreen.y, curved)
        } else {
            hideGhostSegment()
        }
    }

}

/**
 *
 * @param {Vertex[]} selectedVertices
 * @returns {because}
 */
function _canCreateEdge(selectedVertices) {
    return selectedVertices.length === 1 && !selectedVertices[0].isFlagged(FlagsEnum.CURVE_VERT) && (selectedVertices[0].upperTierIDs.size < 2)
}

/**
 * @param {import('..').SetLocalCursorStateFn} setLocalCursorState
 */
function _watchPenToolEvents(setLocalCursorState) {
    const viewport = _visualServer.viewport
    const dataStore = _visualServer.dataStore
    const selection = dataStore.selection

    {
        /** @todo Extact method calls */
        /** world space, for filtering small dragging */
        const _clickPos = new Vector2()
        /** world space */
        const threshold = 0.99

        let createdEdge = null
        let movedCurveCtrl = null
        let oppositeCurveCtrl = null

        //#region clousure
        const startDrawPathOnBlankEvent = (e, pressModifier, isSnapGrid = true) => {
            movedCurveCtrl = null
            oppositeCurveCtrl = null
            hideGhostSegment()
            _cleanSnappingUI()
            const worldMousePos = _visualServer.viewport.toWorld(e.mousePos)
            _clickPos.copy(worldMousePos)
            const selectedVerts = selection.get('vertices')
            if (_element) {
                /** @type {import('../handles/SnappingPath').SnapTarget} */
                const target = selection.get('snapTarget')
                if (target) {
                    worldMousePos.copy(target.position)
                }
                // If all vertices are deleted, reset the element's translate/dimensions before drawing new path
                if (!_mesh.vertices.size) {
                    _element.setBaseProp('translate', { translateX: worldMousePos.x, translateY: worldMousePos.y })
                    _element.setBaseProp('dimensions', { width: 0, height: 0 })
                }
                const localPos = _world2NodeSpace(worldMousePos, vec2Buffer)
                let vertex
                if (_canCreateEdge(selectedVerts)) {
                    const result = _mesh.appendLine(
                        selectedVerts[0].id,
                        localPos.x, localPos.y,
                        !pressModifier,
                    )
                    vertex = result.vertex
                    oppositeCurveCtrl = result.curveCtrl
                } else {
                    const startPos = _mesh.edges.size ? localPos : _visualServer.indexer.getScreenNode().item.transform.worldInv.xform(worldMousePos, vec2Buffer)
                    vertex = _mesh.startNewPath(
                        startPos.x, startPos.y
                    )
                }
                selection.selectVertices([vertex], NO_COMMIT)
            } else {
                createElement(e.mousePos, ToolType.PEN, _visualServer, { width: 0, height: 0 }, false, isSnapGrid)
                const path = dataStore.selection.get('elements')[0]
                _mesh = path.get('geometry').get('mesh')
                dataStore.eam.activateShapeMode(NO_COMMIT)
                // Inside datastore, when switch to shape mode, it will automatically set active tool to last general tool.
                // So, we need to set it back to pen tool again.
                dataStore.eam.activatePenTool(NO_FIRE_NO_COMMIT)
                const firstVertex = [..._mesh.vertices][0]
                dataStore.selection.selectVertices([firstVertex], NO_COMMIT)
                _isCreatingElement = true
            }
        }

        const startDrawPathOnVertexEvent = (e, pressModifier) => {
            movedCurveCtrl = null
            oppositeCurveCtrl = null
            createdEdge = null
            hideGhostSegment()
            const worldMousePos = _visualServer.viewport.toWorld(e.mousePos)
            _clickPos.copy(worldMousePos)

            const selectedVerts = selection.get('vertices')
            const vertex = _visualServer.dataStore.selection.get('hoverVertex')

            // Main function
            if (_canCreateEdge(selectedVerts)) {
                if (selectedVerts[0].id === vertex.id) {
                    if (selectedVerts[0].unlinkedCurveControl) {
                        const unlinkedCurveControl = _mesh.cellTable.get(selectedVerts[0].unlinkedCurveControl)
                        if (unlinkedCurveControl) {
                            _mesh.deleteCellsByVertex([unlinkedCurveControl])
                        }
                    }
                } else {
                    const result = _mesh.connectVertices(selectedVerts[0].id, vertex.id, !pressModifier)
                    if (result) {
                        oppositeCurveCtrl = result.curveCtrl
                        createdEdge = result.edge
                    }
                }
            }
            selection.selectVertices([vertex], NO_COMMIT)
        }

        const updateDragCurveCtrlOnBlank = (e) => {
            const worldMousePos = viewport.toWorld(e.mousePos)
            if (_clickPos.distance_to(worldMousePos) < threshold) return
            /** @type {Vertex} */
            const selectedVert = selection.get('vertices')[0]
            if (!selectedVert) {
                e.handled = false
                return
            }
            if (!movedCurveCtrl) {
                if (!oppositeCurveCtrl) oppositeCurveCtrl = _mesh.falsifyIndCurveCtrl(selectedVert)
                movedCurveCtrl = _mesh.addIndpendentCurveCtrl(selectedVert, PointShape.ANGLE_AND_LENGTH)
            }
            setLocalCursorState('default')

            _world2NodeSpace(worldMousePos, worldMousePos)
            _mesh.moveIndCurveControl(movedCurveCtrl, selectedVert, oppositeCurveCtrl, worldMousePos.x, worldMousePos.y, true)
        }

        const updateDragCurveCtrlOnVertex = (e) => {
            const worldMousePos = viewport.toWorld(e.mousePos)
            if (_clickPos.distance_to(worldMousePos) < threshold) return
            /** @type {Vertex} */
            const selectedVert = selection.get('vertices')[0]
            if (!selectedVert) {
                e.handled = false
                return
            }
            if (!movedCurveCtrl) {
                movedCurveCtrl = oppositeCurveCtrl ?
                    _mesh.falsifyIndCurveCtrl(selectedVert) :
                    _mesh.addIndpendentCurveCtrl(selectedVert, PointShape.INDEPENDENT)
            }
            setLocalCursorState('convertPath')

            _world2NodeSpace(worldMousePos, worldMousePos)
            _mesh.moveIndCurveControl(movedCurveCtrl, selectedVert, oppositeCurveCtrl, worldMousePos.x, worldMousePos.y, true)
        }

        dataStore.eam.on(Events.HOVER_CELL_WITH_SELECTION, (e, { toggle, reconnect }, { snapToGrid = false } = {}) => {
            if (reconnect) {
                const vertex = selection.get('vertices')[0]
                if (vertex) {
                    _visualServer.dataStore.selection.selectVertices([vertex], NO_COMMIT)
                }
            }
            _controlGhostSegment(e.mousePos, !toggle, snapToGrid, setLocalCursorState)
        })

        dataStore.eam
            .on(Events.START_DRAW_PATH, (e, { modifier }, { snapToGrid = true }) => {
                startDrawPathOnBlankEvent(e, modifier, snapToGrid)
            })
            .on(Events.UPDATE_DRAW_PATH, (e) => {
                updateDragCurveCtrlOnBlank(e)
            })
            .on(Events.END_DRAW_PATH, () => {
                _mesh?.hideFakeIndCurveCtrl()
                dataStore.commitUndo()
            })
        dataStore.eam
            .on(Events.START_DRAW_PATH_ON_VERTEX, (e, { modifier }) => {
                startDrawPathOnVertexEvent(e, modifier)
            })
            .on(Events.UPDATE_DRAW_PATH_ON_VERTEX, (e) => {
                updateDragCurveCtrlOnVertex(e)
            })
            .on(Events.END_DRAW_PATH_ON_VERTEX, () => {
                _mesh?.hideFakeIndCurveCtrl()
                if (createdEdge) {
                    if (movedCurveCtrl) {
                        // Have dragged
                        _mesh?.prepareVertexPropertyChanges(
                            selection.get('vertices')[0].id, 'mirror', PointShape.INDEPENDENT, true
                        )
                    }
                    _visualServer.dataStore.selection.selectVertices([], NO_COMMIT)
                }
                dataStore.commitUndo()
            })

        const _updateAffectedCurveControlsData = (keyFrameId, originalCVInterval, newCVPos, targetCurveControlId, affectedCurveControlPos) => {
            if (!newCVPos) {
                return
            }
            if (!Number.isFinite(newCVPos[0]) || !Number.isFinite(newCVPos[1])) {
                console.warn(`_updateAffectedCurveControlsData get wrong control vertex posiotion druing action mode from interpolation`)
                return
            }
            if (originalCVInterval && originalCVInterval.x[0].id) {
                // Update previous kf if it exist
                const keyFrame = dataStore.interaction.getKeyFrame(keyFrameId)
                const newKeyFrameValue = cloneDeep(keyFrame.value)
                newKeyFrameValue.forEach((v) => {
                    if (v.id !== targetCurveControlId) {
                        return
                    }

                    v.pos[0] = newCVPos[0]
                    v.pos[1] = newCVPos[1]
                })
                dataStore.interaction.setKeyFrameValue(keyFrame.id, newKeyFrameValue, false)
            } else {
                // Update basePath if previous kf does not exist
                affectedCurveControlPos[0] = newCVPos[0]
                affectedCurveControlPos[1] = newCVPos[1]
                _element.updateBaseVertex(targetCurveControlId, new Map([['pos', { after: newCVPos }]]))
            }
        }

        const _getCurveControlStartPosition = (originalCpVInterval, originalCVPos, time) => {
            if (!originalCpVInterval) {
                return null
            }
            const newCpVX = dataStore.transition.getStartValueWithCurrentValueAndInterval(originalCpVInterval.x, originalCVPos[0], time)
            const newCpVY = dataStore.transition.getStartValueWithCurrentValueAndInterval(originalCpVInterval.y, originalCVPos[1], time)
            return [newCpVX, newCpVY]
        }

        const _updateCurveControlsData = (splitEdgeData) => {
            const elementId = _element.get('id')
            const propertyTracksMaps = dataStore.interaction.getPropertyTrackMapsByElementId(elementId)

            for (let i = 0; i< propertyTracksMaps.length; i++) {
                const propertyTracksMap = propertyTracksMaps[i]
                if (!splitEdgeData.edge.isCurve || !propertyTracksMap || !propertyTracksMap.has('pathMorphing')) {
                    return
                }

                const pathMorphingTrackId = propertyTracksMap.get('pathMorphing')
                const pathMorphingTrackKfs = dataStore.interaction.getPropertyTrackKeyFrameGroupByTime(pathMorphingTrackId)
                const allTimes = Object.keys(pathMorphingTrackKfs).sort((a, b) => (a - b > 0 ? 1 : -1))
                const validTimes = allTimes.filter((t) => t <= dataStore.transition.currentTime)
                const previousKfTime = validTimes.length > 0 ? validTimes[validTimes.length - 1] : null
                const vertexWorkingInterval = dataStore.transition.getPropertyWorkingInterval(elementId, 'pathMorphing')
                const originalCpVInterval = vertexWorkingInterval.get(splitEdgeData.originalCpV.id)
                const originalCpWInterval = vertexWorkingInterval.get(splitEdgeData.originalCpW.id)
                const animationTime = dataStore.transition.currentTime
                const newCpVPos = _getCurveControlStartPosition(originalCpVInterval, splitEdgeData.leftEdgeCurveControlsPos[0], animationTime)
                const newCpWPos = _getCurveControlStartPosition(originalCpWInterval, splitEdgeData.rightEdgeCurveControlsPos[1], animationTime)
                const keyFrameId = pathMorphingTrackKfs && pathMorphingTrackKfs[previousKfTime]
                    ? pathMorphingTrackKfs[previousKfTime][0]
                    : null

                _updateAffectedCurveControlsData(keyFrameId, originalCpVInterval, newCpVPos, splitEdgeData.originalCpV.id, splitEdgeData.leftEdgeCurveControlsPos[0])
                _updateAffectedCurveControlsData(keyFrameId, originalCpWInterval, newCpWPos, splitEdgeData.originalCpW.id, splitEdgeData.rightEdgeCurveControlsPos[1])
            }
        }

        // //////////////////////////////////////////////////////////////
        const _lastMousePos = new Vector2()
        let grippedWorldPos = null
        let worldTransform = null
        let hoverVert = null
        const startDrawPathOnEdgeEvent = (e) => {
            movedCurveCtrl = null
            oppositeCurveCtrl = null
            createdEdge = null
            grippedWorldPos = null
            worldTransform = null
            hoverVert = null
            hideGhostSegment()
            stopHighlightEdges()
            disableGhostPoint(false)
            const worldMousePos = _visualServer.viewport.toWorld(e.mousePos)
            _clickPos.copy(worldMousePos)

            /** @type {import('../handles/SnappingPath').SnapTarget} */
            const hoverTarget = _visualServer.dataStore.selection.get('snapTarget')
            const splitEdgeData = _visualServer.dataStore.meshManager.prepareSplitEdgeData(_mesh, hoverTarget.edge.id, hoverTarget.timeOnEdge)
            if (!splitEdgeData) {
                return
            }
            // In action mode, we need to consider if adding a new vertex on the edge in keyframe time or in between an animation interval.
            if (dataStore.isActionMode) {
                _updateCurveControlsData(splitEdgeData)
            }
            _visualServer.dataStore.meshManager.splitEdge(_mesh, splitEdgeData)
            dataStore.transition.cacheSpecificElementBaseValue(_element.get('id'), 'pathMorphing')
            selection.selectVertices([splitEdgeData.midVertex], NO_COMMIT)
            hoverVert = splitEdgeData.midVertex
            _lastMousePos.copy(worldMousePos)
        }

        const updateDragPointAfterCreate = ({ mousePos }, { shift }, { snapToGrid, snapToObject }) => {
            const worldMousePos = viewport.toWorld(mousePos)
            if (_clickPos.distance_to(worldMousePos) < threshold) return

            if (!grippedWorldPos) {
                grippedWorldPos = _getVertexWorldPos(_element, _mesh, hoverVert)

            }
            if (!worldTransform) {
                worldTransform = _visualServer.getRenderItemOfElement(_element).transform.world
            }

            _cleanSnappingUI()

            const snappingVertex = _findVertexAt(_visualServer.selection.first.element, worldMousePos)
            setLocalCursorState('default')
            onMouseDragVertex(snappingVertex, selection,
                worldTransform, worldMousePos,
                hoverVert, grippedWorldPos, snapToGrid, snapToObject,
                _lastMousePos, shift)
        }

        dataStore.eam
            .on(Events.START_DRAW_PATH_ON_EDGE, (e) => {
                startDrawPathOnEdgeEvent(e)
            })
            .on(Events.UPDATE_DRAW_PATH_ON_EDGE, (e, { shift }, { snapToGrid = true, snapToObject = true }) => {
                updateDragPointAfterCreate(e, { shift }, { snapToGrid, snapToObject })
            })
            .on(Events.END_DRAW_PATH_ON_EDGE, () => {
                drawAssistLinesOnScreen([])
                _cleanSnappingUI()
                _visualServer.snapping.setEndSnapping()
                dataStore.commitUndo()
            })
    }
}


/** @param {Vector2} delta */
function _moveSelection(delta) {
    const selectedVert = _visualServer.dataStore.selection.get('vertices')
    _world2NodeSpaceBasis(delta, vec2Buffer)

    _mesh.moveVertex(
        selectedVert,
        vec2Buffer.x, vec2Buffer.y
    )

    _visualServer.dataStore.debounceCommitUndo()
}

/**
 *
 * @param {Vector2} vec2
 * @param {Vector2} output
 * @returns {Vector2}
 */
function _world2NodeSpace(vec2, output) {
    const transform = _visualServer.getRenderItemOfElement(_element).transform
    transform.worldInv.xform(vec2, output)
    const referencePoint = _element.get('referencePoint')
    output.subtract(referencePoint)
    return output
}

/**
 *
 * @param {Vector2} vec2
 * @param {Vector2} output
 * @returns {Vector2}
 */
function _world2NodeSpaceBasis(vec2, output) {
    return _visualServer.getRenderItemOfElement(_element).transform.worldInv.basis_xform(vec2, output)
}

/**
 * Get vertex world position (warn: need to free the return Vector2 after using)
 * @param {Element} element
 * @param {Mesh} mesh
 * @param {Vertex} vertex
 * @returns {Vector2}
 */
function _getVertexWorldPos(element, mesh, vertex) {
    const rowPos = mesh.getVertPos(vertex.id)
    const vertexPos = new Vector2()
    vertexPos.set(rowPos[0], rowPos[1])
    const nodeTransform = _visualServer.getRenderItemOfElement(element).transform
    nodeTransform.world.xform(vertexPos, vertexPos)
    return vertexPos
}
