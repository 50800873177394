import { getComponentsData } from './helper'
import { getTransitionData } from './Transition'

export const getFillTransitionData = (o) => getTransitionData(o)
export const getStrokeTransitionData = (o) => {
  return {
    opacity: getTransitionData(o.opacity),
    width: getTransitionData(o.width),
    offset: getTransitionData(o.offset)
  }
}
export const getShadowTransitionData = (o) => {
  return {
    paint: getTransitionData(o.paint),
    offsetX: getTransitionData(o.offsetX),
    offsetY: getTransitionData(o.offsetY),
    blur: getTransitionData(o.blur),
    spread: getTransitionData(o.spread)
  }
}

export const getTransitionWrapperData = (transitionWrapper) => {
  const data = transitionWrapper.gets('id', 'parentId', 'type')
  Object.assign(
    data,
    getComponentsData(
      transitionWrapper.gets(
        'fills',
        'fillsInherit',
        'height',
        'innerShadows',
        'innerShadowsInherit',
        'opacity',
        'rotation',
        'shadows',
        'shadowsInherit',
        'strokes',
        'strokesInherit',
        'width',
        'x',
        'y'
      ),
      {
        fills: getFillTransitionData,
        fillsInherit: getTransitionData,
        height: getTransitionData,
        innerShadows: getShadowTransitionData,
        innerShadowsInherit: getTransitionData,
        opacity: getTransitionData,
        rotation: getTransitionData,
        shadows: getShadowTransitionData,
        shadowsInherit: getTransitionData,
        strokes: getStrokeTransitionData,
        strokesInherit: getTransitionData,
        width: getTransitionData,
        x: getTransitionData,
        y: getTransitionData
      }
    )
  )
  return data
}
