/* tslint:disable */
/* eslint-disable */
/**
 * Express TypeScript Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ConnectLiveblocksRoom200Response,
  ConnectLiveblocksRoomRequest,
  DeleteLiveblocksRoomRequest,
} from '../models';

export interface ConnectLiveblocksRoomOperationRequest {
    connectLiveblocksRoomRequest: ConnectLiveblocksRoomRequest;
}

export interface DeleteLiveblocksRoomOperationRequest {
    deleteLiveblocksRoomRequest: DeleteLiveblocksRoomRequest;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Retrieves session information for a given room based on the room ID and user token.
     * Returns session information for a specific room.
     */
    async connectLiveblocksRoomRaw(requestParameters: ConnectLiveblocksRoomOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConnectLiveblocksRoom200Response>> {
        if (requestParameters.connectLiveblocksRoomRequest === null || requestParameters.connectLiveblocksRoomRequest === undefined) {
            throw new runtime.RequiredError('connectLiveblocksRoomRequest','Required parameter requestParameters.connectLiveblocksRoomRequest was null or undefined when calling connectLiveblocksRoom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/liveblocks`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.connectLiveblocksRoomRequest,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Retrieves session information for a given room based on the room ID and user token.
     * Returns session information for a specific room.
     */
    async connectLiveblocksRoom(requestParameters: ConnectLiveblocksRoomOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConnectLiveblocksRoom200Response> {
        const response = await this.connectLiveblocksRoomRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes a room based on the room ID provided in the request body.
     * Deletes a specific room.
     */
    async deleteLiveblocksRoomRaw(requestParameters: DeleteLiveblocksRoomOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.deleteLiveblocksRoomRequest === null || requestParameters.deleteLiveblocksRoomRequest === undefined) {
            throw new runtime.RequiredError('deleteLiveblocksRoomRequest','Required parameter requestParameters.deleteLiveblocksRoomRequest was null or undefined when calling deleteLiveblocksRoom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/liveblocks`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.deleteLiveblocksRoomRequest,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes a room based on the room ID provided in the request body.
     * Deletes a specific room.
     */
    async deleteLiveblocksRoom(requestParameters: DeleteLiveblocksRoomOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteLiveblocksRoomRaw(requestParameters, initOverrides);
    }

}
