import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { useApolloClient } from '@apollo/client'
import { WorkspaceType } from '@phase-software/types'

import { ProjectFieldsFragment, ProjectFieldsFragmentDoc, TeamFieldsFragmentDoc } from '../generated/graphql'
import { useWorkspaceActions } from '../hooks/useWorkspaceActions'
import { useWorkspaceContext } from './WorkspaceContextProvider'

type ProjectPermissionContextProps = {
  fetchPermissions: () => void
  refetchPermissions: () => void
  projectWithPermission: {
    canEdit: boolean
    canView: boolean
    canDelete: boolean
  } & ProjectFieldsFragment
}

export type ProjectPermissionProviderProps = {
  children: React.ReactNode
  id: ProjectFieldsFragment['id']
}

export const ProjectPermissionContext = createContext<ProjectPermissionContextProps | undefined>(undefined)

const ProjectPermissionProvider = ({ id, children }: ProjectPermissionProviderProps) => {
  const client = useApolloClient()
  const { workspaceData, isWorkspaceDataLoading } = useWorkspaceContext()
  const { getCurrentUserProjectPermissions } = useWorkspaceActions(workspaceData.type, workspaceData.id)
  const [shouldRefetch, setShouldRefetch] = useState(false)
  const [permissions, setPermissions] = useState({
    canEdit: false,
    canView: false,
    canDelete: false
  })

  const team = client.readFragment({
    fragment: TeamFieldsFragmentDoc,
    fragmentName: 'teamFields',
    id: `teams:${workspaceData.id}`
  })

  const project = client.readFragment({
    fragment: ProjectFieldsFragmentDoc,
    fragmentName: 'projectFields',
    id: `projects:${id}`
  })

  const refetchPermissions = useCallback(() => {
    if (isWorkspaceDataLoading) {
      setShouldRefetch(true)
      return
    }
    getCurrentUserProjectPermissions(id)
      .then(({ edit, view, canDelete }) => {
        setPermissions({ canEdit: edit, canView: view, canDelete })
      })
      .catch((error) => {
        console.error(error)
      })
  }, [getCurrentUserProjectPermissions, id, isWorkspaceDataLoading])

  const fetchPermissions = useCallback(() => {
    if (isWorkspaceDataLoading) {
      setShouldRefetch(true)
      return
    }

    //  if the team does not exist or has been deleted (team is null or undefined)
    if (!getCurrentUserProjectPermissions || (workspaceData.type === WorkspaceType.TEAM && !team)) {
      return
    }

    refetchPermissions()
  }, [getCurrentUserProjectPermissions, refetchPermissions, team, workspaceData.type, isWorkspaceDataLoading])

  const value = useMemo(
    () => ({ projectWithPermission: { ...project, ...permissions }, fetchPermissions, refetchPermissions }),
    [project, permissions, fetchPermissions, refetchPermissions]
  )

  useEffect(() => {
    if (!isWorkspaceDataLoading && shouldRefetch) {
      refetchPermissions()
      setShouldRefetch(false)
    }
  }, [workspaceData.id, isWorkspaceDataLoading, shouldRefetch, refetchPermissions, setShouldRefetch])

  return <ProjectPermissionContext.Provider value={value}>{children}</ProjectPermissionContext.Provider>
}

function useProjectPermissionContext(): ProjectPermissionContextProps {
  const context = useContext(ProjectPermissionContext)
  if (context === undefined) {
    throw new Error('useProjectPermissionContext must be used within a ProjectPermissionContextProvider')
  }
  return context
}

export { ProjectPermissionProvider, useProjectPermissionContext }
