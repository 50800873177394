import React, { useCallback, useMemo } from 'react'

import { TFunction } from 'i18next'

import { MIX_VALUE } from '@phase-software/data-utils'
import { ElementType, GeometryType } from '@phase-software/types'

import { useDataStore, useDataStoreActions } from '../../../providers/dataStore/DataStoreProvider'
import { useEditor, useEditorActions } from '../../../providers/dataStore/EditorProvider'
import { formatNumberWithUnit } from '../../../utils/formatter'
import MixInput, { ValueType } from '../../shared/MixInput'

type PropertyProps = {
  caption?: string | React.ReactNode
  captionAlign?: string
  className?: string
  columns?: string
  disabled?: boolean
  enableKF?: boolean
  field: string
  formatter?: (value: any) => string
  hideWhenNoValue?: boolean
  kfClassName?: string
  onChange?: (value: any) => void
  tooltip?: string
  toValue?: (value: any) => any
  toDisplay?: (value: any) => any
  value?: any
  min?: number
  alwaysForceUpdate?: boolean
  max?: number
  validator?:
    | ((value: number, unit?: string, decimalPlaces?: number, formatAsString?: boolean) => void)
    | ((value: string, t: TFunction) => void)
}

type SetPropertyOptions = {
  commit: boolean
  delta: boolean
}

const Property = ({
  caption,
  captionAlign = 'center',
  className,
  columns = 'auto auto',
  disabled,
  enableKF = true,
  field,
  formatter = formatNumberWithUnit,
  hideWhenNoValue = false,
  kfClassName,
  toValue = (value) => value,
  toDisplay = (value) => value,
  tooltip = '',
  ...rest
}: PropertyProps) => {
  const value = useEditor((o) => o[field])
  const elementType = useEditor((o) => o.elementType)
  const dataStore = useDataStore()

  const { setProperties } = useEditorActions()
  const { commitUndo, debounceCommitUndo } = useDataStoreActions()

  const selectElements = dataStore.selection.get('elements')
  const IsMultiSelectHasVerticalLine =
    selectElements.length > 1 &&
    selectElements
      .filter((el: any) => el.get('elementType') !== ElementType.SCREEN)
      .some((el: any) => el.get('geometryType') === GeometryType.LINE && el.get('width') === 0)
  const IsMultiSelectHasHorizontalLine =
    selectElements.length > 1 &&
    selectElements
      .filter((el: any) => el.get('elementType') !== ElementType.SCREEN)
      .some((el: any) => el.get('geometryType') === GeometryType.LINE && el.get('height') === 0)

  const handleChange = useCallback(
    (newValue: any, options?: SetPropertyOptions, isSpinner?: boolean) => {
      let newPropValue = toValue(newValue)
      // If previous value is mix value, the input will change to text type.
      // Then we will get newValue as string type, it will break some calculation for render.
      // So, we need to make sure that all new value should be number type.
      if (value === MIX_VALUE && !isNaN(newValue)) {
        newPropValue = Number(newPropValue)
      }
      if (!isSpinner && formatNumberWithUnit(newPropValue) === formatNumberWithUnit(value)) {
        return
      }

      setProperties({ [field]: newPropValue }, options)
    },
    [toValue, value, field, setProperties]
  )

  const handleFormat = (val: ValueType) => {
    // If a multi selection has collinear line width/height input always show Mix, even the value is changed
    if ((field === 'width' && IsMultiSelectHasVerticalLine) || (field === 'height' && IsMultiSelectHasHorizontalLine)) {
      return 'Mix'
    }
    return formatter(val)
  }

  const keyFrameIconProps = useMemo(
    () => ({
      name: field,
      className: kfClassName
    }),
    [field, kfClassName]
  )

  if (value === undefined && hideWhenNoValue) {
    return null
  }

  return (
    <div className={`grid grid-flow-col items-center ${className}`} style={{ gridTemplateColumns: columns }}>
      <MixInput
        disabled={disabled || value === undefined}
        keyFrameIconProps={elementType !== ElementType.SCREEN && enableKF ? keyFrameIconProps : undefined}
        onBlur={commitUndo}
        onChange={handleChange}
        onStepChange={debounceCommitUndo}
        tooltip={tooltip}
        type="number"
        value={value}
        variant="normal"
        rightComponent={
          caption && (
            <div className={`min-w-16 text-12 text-${captionAlign} text-light-overlay-60 whitespace-nowrap`}>
              {caption}
            </div>
          )
        }
        formatter={handleFormat}
        toDisplay={toDisplay}
        spinner
        {...rest}
      />
    </div>
  )
}

export default Property
