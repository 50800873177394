import { EffectType } from '@phase-software/types'
import EffectStack from '../EffectStack'

class TrimPathStack extends EffectStack {
  constructor(elementStack, data) {
    super(elementStack, data, EffectType.TRIM_PATH)
    this.type = ''
    this.key = 'trimPath'
    this.dataKey = ''
    this.animatableProperties = new Set(['start', 'end', 'offset'])
  }
}

export default TrimPathStack
