import {
  Animation,
  ShapeLayer,
  ImageLayer,
  AssetType,
  GroupLayer,
  ShapeType,
  MatteMode,
  ShapeDirection,
  StrokeDashProperty,
  MergeMode,
  PrecompositionLayer
} from '@phase-software/lottie-js'
import { Track, AABB, getLottieStyleBeziersBounds } from '@phase-software/data-utils'
import {
  IRPrecompositionAsset,
  IRAssetType,
  IRAnimation,
  IRNodeType,
  IRPaintType,
  IRGradientType,
  IREffectType,
  IRPath
} from '../ir'
import {
  addKeyFrameToProp,
  getGradientColors,
  rad2deg,
  getLineJoinType,
  getLineCapType,
  isShapeShape,
  getStrokeDashType,
  round3dp
} from '../utils'
import { IRConverter } from './IRConverter'
import {
  getBlendMode,
  getMergeMode,
  getStrokeShapeType,
  getNodeShapeType,
  getFillShapeType,
  getEffectShapeType,
  getGradientHandlesPosition,
  getShapeScale,
  getLayerGradientType,
  getImageOffset,
  getImageScale,
  matchGradientStops
} from './lottie-helper'

export class LottieConverter extends IRConverter {
  static version = '0.7.0'

  constructor() {
    super()
    this.animation = new IRAnimation()
    this.lottie = new Animation()
  }

  clear() {
    this.animation = new IRAnimation()
    this.lottie = new Animation()
  }

  fromIR(irContent) {
    this.clear()
    this.animation.fromJSON(irContent)

    this.lottie.frameRate = this.animation.frameRate
    this.lottie.inPoint = this.animation.inPoint
    this.lottie.outPoint = this.animation.outPoint
    this.lottie.width = this.animation.width
    this.lottie.height = this.animation.height

    this.lottie.meta.generator = `@phase-software/lottie-exporter ${LottieConverter.version}`

    this.animation.assets.forEach(irAsset => {
      const asset = this.createAsset(irAsset)
      this.lottie.assets.push(asset)
    })

    this.animation.sceneTree.nodeList.forEach(node => {
      this.toLottieLayer(node, this.lottie)
    })

    return this.lottie.toJSON()
  }

  applyLayerProps(node, layer) {
    layer.index = node.uid
    layer.id = node.id
    layer.name = node.name
    layer.autoOrient = node.autoOrient
    layer.blendMode = getBlendMode(node.blendMode.value)
    layer.isHidden = !node.visible
    layer.outPoint = this.lottie.outPoint + 1
  }

  getParent(parentId, wrapper) {
    return wrapper.layers.find(layer => layer.index === parentId)
  }

  createAsset(irAsset) {
    switch (irAsset.type) {
      case IRAssetType.IMAGE: {
        const imageAsset = this.lottie.createAsset(AssetType.IMAGE)
        imageAsset.id = irAsset.id
        imageAsset.path = irAsset.asset || ''
        imageAsset.data = irAsset.data
        imageAsset.width = irAsset.width
        imageAsset.height = irAsset.height
        imageAsset.embedded = 1
        return imageAsset
      }
      case IRAssetType.PRECOMPOSITION: {
        const precompositionAsset = this.lottie.createAsset(AssetType.PRECOMPOSITION)
        precompositionAsset.id = irAsset.id

        irAsset.children.forEach(node => {
          this.toLottieLayer(node, precompositionAsset)
        })
        return precompositionAsset
      }
      default:
        console.error(`Unhandled asset type: ${irAsset.type}`)
        break
    }
  }

  applyParentOpacity(node, layer) {
    let targetNode = node.parent
    let parent = layer
    while (targetNode) {
      if (targetNode instanceof IRPrecompositionAsset) {
        break
      }

      if (targetNode.opacity.value !== 1 || targetNode.opacity.animated) {
        const group = parent.createShape(ShapeType.GROUP)
        group.name = `${targetNode.name} opacity`
        this.applyKeyFrame(group.transform.opacity, targetNode.opacity, { transform: v => round3dp(v * 100) })
        parent.addShape(group)

        parent = group
      }

      targetNode = targetNode.parent
    }
    return parent
  }

  // TODO: refactor
  toLottieLayer(node, wrapper) {
    const parent = this.getParent(node.parentId, wrapper)

    switch (node.type) {
      case IRNodeType.IMAGE: {
        const { paint } = node.fills[0]

        const kfs = paint.keyFrames.reduce(
          (acc, cur, index) => {
            const prev = acc[index]
            prev.op = cur.time
            acc.push({ ...cur.value, ip: cur.time })
            return acc
          },
          [{ ip: 0, ...paint.value }]
        )

        kfs.forEach(({ imageId, imageMode, ip, op }, index) => {
          const irAsset = this.animation.assets.find(asset => asset.id === imageId)
          let imageAsset = this.lottie.assets.find(asset => asset.id === imageId)
          if (!imageAsset) {
            imageAsset = this.createAsset(irAsset)
            this.lottie.assets.push(imageAsset)
          }

          const layer = new ImageLayer(parent)
          layer.matteParent = node.maskParent.uid
          layer.matteMode = MatteMode.ALPHA
          layer.refId = imageAsset.id
          this.applyLayerProps(node, layer)
          this.applyTransform(node, layer)
          layer.id = `${node.id}_${index}`
          layer.inPoint = (ip / 1000) * this.lottie.frameRate
          layer.outPoint = op ? (op / 1000) * this.lottie.frameRate : this.lottie.outPoint + 1
          wrapper.layers.push(layer)

          const imageDimensions = [irAsset.width, irAsset.height]

          if (node.maskParent.children[0] instanceof IRPath) {
            this.applyKeyFrame(
              layer.transform.anchor,
              [node.width, node.height, node.originX, node.originY, node.scaleX, node.scaleY],
              {
                transform: ([width, height, originX, originY, scaleX, scaleY]) => {
                  const imageScale = getImageScale(imageDimensions, [width, height], [scaleX, scaleY], imageMode)
                  const imageOffset = getImageOffset([width, height], [scaleX, scaleY], imageScale, [originX, originY])
                  return [imageDimensions[0] / 2 + imageOffset[0], imageDimensions[1] / 2 + imageOffset[1]].map(
                    round3dp
                  )
                }
              }
            )
          } else {
            // FIXME: only works if no origin(cA) change
            addKeyFrameToProp(layer.transform.anchor, 0, [imageDimensions[0] / 2, imageDimensions[1] / 2])
            // FIXME: image transform has different anchor point
            // this.applyKeyFrame(layer.transform.anchor, [node.width, node.height, node.originX, node.originY], {
            //   transform: ([width, height, originX, originY]) => {
            //     const imageScale = getImageScale(imageDimensions, [width, height], [1, 1], imageMode)
            //     const imageOffset = getImageOffset([width, height], [1, 1], imageScale, [originX, originY])

            //     return [imageDimensions[0] / 2 + imageOffset[0], imageDimensions[1] / 2 + imageOffset[1]].map(round3dp)
            //   }
            // })
          }
          this.applyKeyFrame(layer.transform.scale, [node.width, node.height, node.scaleX, node.scaleY], {
            transform: ([width, height, scaleX, scaleY]) =>
              getShapeScale([width, height], [scaleX, scaleY], imageDimensions, imageMode).map(round3dp)
          })
        })

        break
      }
      case IRNodeType.PATH:
      case IRNodeType.ELLIPSE:
      case IRNodeType.RECTANGLE: {
        const layer = new ShapeLayer(parent)
        wrapper.layers.push(layer)
        this.applyLayerProps(node, layer)
        this.applyTransform(node, layer)
        this.toLottieShape(node, layer)
        break
      }
      case IRNodeType.BOOLEAN:
      case IRNodeType.IMAGE_MASK:
      case IRNodeType.SCREEN:
      case IRNodeType.COMPUTED_GROUP:
      case IRNodeType.CONTAINER: {
        const layer = new ShapeLayer(parent)
        wrapper.layers.push(layer)

        const groupShape = this.applyParentOpacity(node, layer)
        this.applyLayerProps(node, layer)
        this.applyTransform(node, layer)
        node.children.forEach(childNode => {
          this.toLottieShape(childNode, groupShape)
        })

        if (node.type === IRNodeType.IMAGE_MASK) {
          layer.matteTarget = 1
        }
        if (node.maskParent) {
          layer.matteParent = node.maskParent.uid
          layer.matteMode = MatteMode.ALPHA
        }
        if (node.hasLayer) {
          if (node.type === IRNodeType.BOOLEAN) {
            const mergeMode = getMergeMode(node.booleanType.value)

            // moving latest child to top if subtract
            if (mergeMode === MergeMode.SUBTRACT) {
              const lastShapeIndex = groupShape.shapes.findLastIndex(isShapeShape)
              if (lastShapeIndex !== -1) {
                const shape = groupShape.shapes[lastShapeIndex]
                groupShape.shapes.splice(lastShapeIndex, 1)
                groupShape.shapes.unshift(shape)
              }
            }

            const mergeShape = groupShape.createShape(ShapeType.MERGE)
            mergeShape.mergeMode = mergeMode
            groupShape.shapes.push(mergeShape)
            this.applyStrokes(node, groupShape)
            this.applyFills(node, groupShape)
          } else {
            this.applyContainerLayer(node, groupShape)
          }
        }
        this.applyEffect(node, groupShape)
        break
      }
      case IRNodeType.PRECOMPOSITION: {
        const layer = new PrecompositionLayer(parent)
        layer.width = node.width.value
        layer.height = node.height.value
        layer.refId = node.refId
        wrapper.layers.push(layer)
        this.applyLayerProps(node, layer)
        this.applyTransform(node, layer)

        if (node.mask) {
          layer.matteTarget = 1
        }
        if (node.maskParent) {
          layer.matteParent = node.maskParent.uid
          layer.matteMode = MatteMode.ALPHA
        }

        if (node.hasLayer) {
          const precompositionAsset = this.lottie.assets.find(asset => asset.id === node.refId)
          const layer = new ShapeLayer()
          this.applyLayerProps(node, layer)
          this.applyContainerLayer(node, layer)
          precompositionAsset.layers.push(layer)
        }

        // FIXME: scene tree should be done in phase converter (shiny)
        if (node.cornerRadius.value || node.cornerRadius.animated) {
          const cornerRadiusPrecomp = this.lottie.createAsset(AssetType.PRECOMPOSITION)
          cornerRadiusPrecomp.id = `${node.refId}-cr`
          this.lottie.assets.push(cornerRadiusPrecomp)

          // corner radius mask
          const maskLayer = new ShapeLayer(parent)
          this.applyLayerProps(node, maskLayer)

          maskLayer.matteTarget = 1
          const rectangleShape = maskLayer.createShape(ShapeType.RECTANGLE)
          addKeyFrameToProp(rectangleShape.position, 0, [node.width.value / 2, node.height.value / 2].map(round3dp))
          addKeyFrameToProp(rectangleShape.size, 0, [node.width.value, node.height.value].map(round3dp))
          this.applyKeyFrame(rectangleShape.roundness, node.cornerRadius)
          maskLayer.shapes.push(rectangleShape)
          const fillShape = maskLayer.createShape(ShapeType.FILL)
          addKeyFrameToProp(fillShape.color, 0, [0, 0, 0])
          addKeyFrameToProp(fillShape.opacity, 0, 100)
          maskLayer.shapes.push(fillShape)
          cornerRadiusPrecomp.layers.push(maskLayer)

          // original precomp
          const maskedLayer = new PrecompositionLayer(parent)
          maskedLayer.matteMode = MatteMode.ALPHA
          maskedLayer.width = node.width.value
          maskedLayer.height = node.height.value
          maskedLayer.refId = node.refId
          this.applyLayerProps(node, maskedLayer)
          cornerRadiusPrecomp.layers.push(maskedLayer)

          layer.refId = cornerRadiusPrecomp.id
        }

        // TODO: effect (phase-only)
        break
      }
      default:
        console.error(`Unhandled type: ${node.type} (${node.name})`)
        break
    }
  }

  applyEffect(node, groupShape) {
    node.effects.forEach(effect => {
      switch (effect.type) {
        case IREffectType.TRIM_PATH: {
          const effectShape = groupShape.createShape(getEffectShapeType(effect.type))
          this.applyKeyFrame(effectShape.trimStart, effect.start)
          this.applyKeyFrame(effectShape.trimEnd, effect.end)
          this.applyKeyFrame(effectShape.trimOffset, effect.offset, { transform: v => round3dp(v * 3.6) })
          groupShape.shapes.push(effectShape)
          break
        }
      }
    })
  }

  toLottieShape(node, parent) {
    let groupShape
    // to optimize the shape structure
    if (node.id === parent.id && node.typ !== IRNodeType.PATH) {
      groupShape = parent
    } else {
      groupShape = parent.createShape(ShapeType.GROUP)
      groupShape.name = `${node.name} Group`
      groupShape.isHidden = !node.visible
      groupShape.blendMode = getBlendMode(node.blendMode.value)
      this.applyTransform(node, groupShape)

      parent.shapes.push(groupShape)
    }

    switch (node.type) {
      case IRNodeType.BOOLEAN: {
        node.children.forEach(childNode => {
          this.toLottieShape(childNode, groupShape)
        })

        const mergeMode = getMergeMode(node.booleanType.value)

        // moving latest child to top if subtract
        if (mergeMode === MergeMode.SUBTRACT) {
          const lastShapeIndex = groupShape.shapes.findLastIndex(isShapeShape)
          if (lastShapeIndex !== -1) {
            const shape = groupShape.shapes[lastShapeIndex]
            groupShape.shapes.splice(lastShapeIndex, 1)
            groupShape.shapes.unshift(shape)
          }
        }

        const mergeShape = groupShape.createShape(ShapeType.MERGE)
        mergeShape.mergeMode = mergeMode
        groupShape.shapes.push(mergeShape)

        this.applyStrokes(node, groupShape)
        this.applyFills(node, groupShape)
        break
      }
      case IRNodeType.SCREEN:
      case IRNodeType.COMPUTED_GROUP:
      case IRNodeType.CONTAINER: {
        node.children.forEach(childNode => {
          this.toLottieShape(childNode, groupShape)
        })

        if (node.hasLayer) {
          this.applyContainerLayer(node, groupShape)
        }
        break
      }
      case IRNodeType.RECTANGLE:
      case IRNodeType.ELLIPSE: {
        const basicShape = groupShape.createShape(getNodeShapeType(node.type))
        basicShape.name = node.name
        basicShape.isHidden = !node.visible
        basicShape.blendMode = getBlendMode(node.blendMode.value)
        basicShape.direction = ShapeDirection.NORMAL

        this.applyKeyFrame(basicShape.size, [node.width, node.height])
        this.applyKeyFrame(basicShape.position, [node.width, node.height], {
          transform: ([w, h]) => [w / 2, h / 2].map(round3dp)
        })

        if (node.type === IRNodeType.RECTANGLE) {
          this.applyKeyFrame(basicShape.roundness, node.cornerRadius)
        }

        groupShape.shapes.push(basicShape)

        this.applyStrokes(node, groupShape)
        this.applyFills(node, groupShape)
        break
      }
      case IRNodeType.PATH: {
        node.vertices.value.forEach((vertex, index) => {
          const pathShape = groupShape.createShape(getNodeShapeType(node.type))
          pathShape.name = node.name
          pathShape.isHidden = !node.visible
          pathShape.blendMode = getBlendMode(node.blendMode.value)
          pathShape.direction = ShapeDirection.NORMAL
          this.applyKeyFrame(pathShape.vertices, node.vertices, {
            transform: value => {
              const { c, i, o, v } = value[index]
              return {
                c: !!c,
                i: i.map(p => p.map(round3dp)),
                o: o.map(p => p.map(round3dp)),
                v: v.map(p => p.map(round3dp))
              }
            }
          })

          groupShape.shapes.push(pathShape)
        })
        if (node.cornerRadius.value || node.cornerRadius.animated) {
          const roundedShape = groupShape.createShape(ShapeType.ROUNDED_CORNERS)
          this.applyKeyFrame(roundedShape.roundness, node.cornerRadius)
          groupShape.shapes.push(roundedShape)
        }

        this.applyStrokes(node, groupShape)
        this.applyFills(node, groupShape)
        break
      }
    }
    this.applyEffect(node, groupShape)
  }

  applyStrokes(node, parentShape) {
    node.strokes.forEach(stroke => {
      const strokeShapeType = getStrokeShapeType(stroke.type)
      const strokeShape = parentShape.createShape(strokeShapeType)

      if (stroke.type === IRPaintType.SOLID) {
        this.applySolid(stroke, strokeShape)
      } else {
        this.applyGradient(node, stroke, strokeShape)
      }

      strokeShape.lineJoinType = getLineJoinType(stroke.join.value)
      strokeShape.lineCapType = getLineCapType(stroke.ends.value)
      strokeShape.miterLimit = stroke.miter.value

      // width
      this.applyKeyFrame(strokeShape.width, stroke.width)

      // dash
      const strokeDash = getStrokeDashType('dash')
      stroke.dash.value?.forEach((dash, index) => {
        const dashProp = new StrokeDashProperty(strokeDash)
        dashProp.name = `${stroke.id}_dash_${index}`

        this.applyKeyFrame(dashProp.value, stroke.dash, { transform: v => round3dp(v[index]) })

        strokeShape.addDashes(dashProp)
      })

      // gap
      const strokeGap = getStrokeDashType('gap')
      stroke.gap.value?.forEach((gap, index) => {
        const dashProp = new StrokeDashProperty(strokeGap)
        dashProp.name = `${stroke.id}_gap_${index}`

        this.applyKeyFrame(dashProp.value, stroke.gap, { transform: v => round3dp(v[index]) })

        strokeShape.addDashes(dashProp)
      })

      parentShape.shapes.push(strokeShape)
    })
  }

  applyFills(node, parentShape) {
    node.fills.forEach(fill => {
      const fillShapeType = getFillShapeType(fill.type)
      const fillShape = parentShape.createShape(fillShapeType)

      if (fill.type === IRPaintType.SOLID) {
        this.applySolid(fill, fillShape)
      } else {
        this.applyGradient(node, fill, fillShape)
      }

      parentShape.shapes.push(fillShape)
    })
  }

  applyMotionPath(keyFrame, inTangent, outTangent) {
    keyFrame.valueOutTangent = outTangent
    keyFrame.valueInTangent = inTangent
  }

  applyEasing(keyFrame, easing, hold) {
    keyFrame.hold = hold
    keyFrame.frameOutTangent = easing.slice(0, 2)
    keyFrame.frameInTangent = easing.slice(2)
  }

  applySplitVector(position, translate) {
    position.split = true

    const groupKeyList = ['x', 'y']

    translate.forEach((tx, index) => {
      let kf
      const track = new Track(tx)
      track.times.forEach(time => {
        const interval = track.getInterval(time)
        if (interval.startTime === 0) {
          kf = addKeyFrameToProp(position, 0, round3dp(interval.start), groupKeyList[index])
        }
        if (kf) {
          this.applyEasing(kf, interval.easing, interval.step)
        }
        kf = addKeyFrameToProp(
          position,
          (time / 1000) * this.lottie.frameRate,
          round3dp(interval.end),
          groupKeyList[index]
        )
      })
    })
  }

  applyMotionPathKeyFrame(animatedProperty, nodeProp, { transform = v => v } = {}) {
    const track = new Track(nodeProp, { allowTimeZero: true })
    let kf = addKeyFrameToProp(animatedProperty, 0, transform(nodeProp.value, 0))

    track.times.forEach(time => {
      const interval = track.getInterval(time)
      this.applyEasing(kf, interval.easing, interval.step)
      if (interval.inTangent && interval.outTangent) {
        this.applyMotionPath(kf, interval.inTangent, interval.outTangent)
      }
      kf = addKeyFrameToProp(animatedProperty, (time / 1000) * this.lottie.frameRate, transform(interval.end, time))
    })
  }

  applySingleValueKeyFrame(animatedProperty, nodeProp, transform) {
    const track = new Track(nodeProp)
    let kf = addKeyFrameToProp(animatedProperty, 0, transform(nodeProp.value, 0))

    track.times.forEach(time => {
      const interval = track.getInterval(time)
      this.applyEasing(kf, interval.easing, interval.step)
      kf = addKeyFrameToProp(animatedProperty, (time / 1000) * this.lottie.frameRate, transform(interval.end, time))
    })
  }

  applyMultiValueKeyFrame(animatedProperty, nodeProps, transform) {
    let kf = addKeyFrameToProp(
      animatedProperty,
      0,
      transform(
        nodeProps.map(nodeProp => nodeProp.value),
        0
      )
    )
    const tracks = nodeProps.map(nodeProp => new Track(nodeProp))
    const timeSet = new Set(
      tracks
        .map(track => track.times)
        .flat()
        .sort((a, b) => a - b)
    )
    timeSet.forEach(time => {
      // TODO: index by nodeProp
      let easing
      let hold
      const values = tracks.map(track => {
        if (!easing && track.hasTime(time)) {
          const interval = track.getInterval(time)
          easing = interval.easing
          hold = interval.step
        }
        return track.getValueAtTime(time)
      })
      this.applyEasing(kf, easing, hold)
      kf = addKeyFrameToProp(animatedProperty, (time / 1000) * this.lottie.frameRate, transform(values, time))
    })
  }

  applyKeyFrame(animatedProperty, nodeProps, { transform = v => v } = {}) {
    if (Array.isArray(nodeProps)) {
      this.applyMultiValueKeyFrame(animatedProperty, nodeProps, transform)
    } else {
      this.applySingleValueKeyFrame(animatedProperty, nodeProps, transform)
    }
  }

  applyTransform(node, shapeOrLayer) {
    if (node.type === IRNodeType.PRECOMPOSITION) {
      if (node.static) {
        // Mask
        this.applyKeyFrame(shapeOrLayer.transform.anchor, [node.width, node.height], {
          transform: ([w, h]) => [w / 2, h / 2].map(round3dp)
        })
      } else {
        // Container Overflow Hidden
        this.applyKeyFrame(shapeOrLayer.transform.anchor, [node.originX, node.originY, node.width, node.height], {
          transform: ([ox, oy, w, h]) => [w / 2 + ox, h / 2 + oy].map(round3dp)
        })
      }
    } else {
      if (
        node.type === IRNodeType.PATH ||
        node.type === IRNodeType.COMPUTED_GROUP ||
        node.type === IRNodeType.BOOLEAN ||
        (node.type === IRNodeType.IMAGE_MASK && node.computed)
      ) {
        this.applyKeyFrame(shapeOrLayer.transform.anchor, [node.originX, node.originY], {
          transform: v => v.map(round3dp)
        })
      } else {
        this.applyKeyFrame(shapeOrLayer.transform.anchor, [node.originX, node.originY, node.width, node.height], {
          transform: ([ox, oy, w, h]) => [w / 2 + ox, h / 2 + oy].map(round3dp)
        })
      }
    }

    // keep it for the future we need to support motion path + split vector
    // this.applySplitVector(shapeOrLayer.transform.position, [node.translateX, node.translateY], {
    //   transform: v => v.map(round3dp)
    // })
    this.applyMotionPathKeyFrame(shapeOrLayer.transform.position, node.motionPath, {
      transform: v => v.map(round3dp)
    })

    this.applyKeyFrame(shapeOrLayer.transform.rotation, node.rotation, { transform: v => round3dp(rad2deg(v)) })
    this.applyKeyFrame(shapeOrLayer.transform.opacity, node.opacity, { transform: v => round3dp(v * 100) })
    this.applyKeyFrame(shapeOrLayer.transform.scale, [node.scaleX, node.scaleY], {
      transform: v => v.map(vv => round3dp(vv * 100))
    })

    if (node.skewX.value || node.skewX.animated) {
      this.applyKeyFrame(shapeOrLayer.transform.skew, node.skewX, { transform: v => -round3dp(rad2deg(v)) })
    } else if (node.skewY.value || node.skewY.animated) {
      addKeyFrameToProp(shapeOrLayer.transform.skewAxis, 0, 90)
      this.applyKeyFrame(shapeOrLayer.transform.skew, node.skewY, { transform: v => round3dp(rad2deg(v)) })
    }
  }

  applyContainerLayer(node, layer) {
    const groupShape = layer.createShape(ShapeType.GROUP)
    groupShape.name = `${node.name} Group`
    groupShape.isHidden = !node.visible
    groupShape.blendMode = getBlendMode(node.blendMode.value)
    layer.shapes.push(groupShape)

    // child transform
    const rectangleShape = groupShape.createShape(ShapeType.RECTANGLE)
    rectangleShape.name = node.name
    rectangleShape.isHidden = !node.visible
    rectangleShape.blendMode = getBlendMode(node.blendMode.value)
    rectangleShape.direction = ShapeDirection.NORMAL

    if (node.cornerRadius) {
      this.applyKeyFrame(rectangleShape.roundness, node.cornerRadius, { transform: round3dp })
    }

    this.applyKeyFrame(rectangleShape.size, [node.width, node.height], { transform: v => v.map(round3dp) })
    this.applyKeyFrame(rectangleShape.position, [node.width, node.height], {
      transform: ([w, h]) => [w / 2, h / 2].map(round3dp)
    })

    groupShape.shapes.push(rectangleShape)

    this.applyStrokes(node, groupShape)
    this.applyFills(node, groupShape)
  }

  applySolid(layer, solidShape) {
    this.applyKeyFrame(solidShape.color, layer.paint, {
      transform: v => v.color.slice(0, 3).map(round3dp)
    })
    this.applyKeyFrame(solidShape.opacity, layer.opacity, { transform: v => round3dp(v * 100) })
  }

  applyGradient(node, layer, shape) {
    // gradient stops
    const maxStops = layer.paint.keyFrames.reduce(
      (max, kf) => (max.length < kf.value.gradientStops.length ? kf.value.gradientStops : max),
      layer.paint.value.gradientStops
    )
    shape.gradientColors.colorCount = maxStops.length
    this.applyKeyFrame(shape.gradientColors.gradientColors, layer.paint, {
      transform: v => {
        const copied = v.gradientStops.slice()
        matchGradientStops(maxStops, copied)
        return getGradientColors(copied).map(round3dp)
      }
    })

    // gradient type
    shape.gradientType = getLayerGradientType(layer, layer.paint.value.gradientType)

    // opacity
    this.applyKeyFrame(shape.opacity, layer.opacity, { transform: v => round3dp(v * 100) })

    // transform
    const wTrack = new Track(node.width)
    const hTrack = new Track(node.height)
    const width = wTrack.getValueAtTime(0)
    const height = hTrack.getValueAtTime(0)
    const handles = getGradientHandlesPosition(
      width,
      height,
      layer.paint.value.gradientType === IRGradientType.LINEAR,
      layer.paint.value.gradientTransform
    )

    const start = handles.center
    const end = handles.bottom

    if (node.type === IRNodeType.PATH) {
      const aabb = new AABB()
      for (const value of node.vertices.value) {
        getLottieStyleBeziersBounds(value, aabb)
      }
      start[0] += aabb.min[0]
      start[1] += aabb.min[1]
      end[0] += aabb.min[0]
      end[1] += aabb.min[1]
    }

    addKeyFrameToProp(shape.startPoint, 0, start)
    addKeyFrameToProp(shape.endPoint, 0, end)
    layer.paint.keyFrames.forEach(keyFrame => {
      const width = wTrack.getValueAtTime(keyFrame.time)
      const height = hTrack.getValueAtTime(keyFrame.time)

      const handles = getGradientHandlesPosition(
        width,
        height,
        keyFrame.value.gradientType === IRGradientType.LINEAR,
        keyFrame.value.gradientTransform
      )
      const start = handles.center
      const end = handles.bottom
      if (node.type === IRNodeType.PATH) {
        const aabb = new AABB()
        for (const value of node.vertices.value) {
          getLottieStyleBeziersBounds(value, aabb)
        }
        start[0] += aabb.min[0]
        start[1] += aabb.min[1]
        end[0] += aabb.min[0]
        end[1] += aabb.min[1]
      }
      const frame = (keyFrame.time / 1000) * this.lottie.frameRate
      addKeyFrameToProp(shape.startPoint, frame, start)
      addKeyFrameToProp(shape.endPoint, frame, end)
    })
  }
}
