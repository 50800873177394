import React, { useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import { useApolloClient } from '@apollo/client'
import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { useAccessControl } from '../../access-control'
import {
  ARCHIVE_FILE_OPTIONS,
  DOWNLOAD_OPTIONS,
  FILE_OPTIONS,
  PROJECT_FILE_OPTIONS
} from '../../constants/fileConstants'
import { FileFieldsFragment } from '../../generated/graphql'
import { FEATURE_KEYS } from '../../growthbook-feature-keys'
import useFileActions from '../../hooks/useFileActions'
import { useFilePermissionContext } from '../../providers/FilePermissionProvider'
import { useModal, useSetModal } from '../../providers/ModalProvider'
import { useSetNotification } from '../../providers/NotificationProvider'
import { getFileIndexPath } from '../../utils/pathGenerators'
import ShareFileDialog from '../Share/ShareFileDialog'
import DeleteFileDialog from '../modals/DeleteFileDialog'
import MoveFileDialog from '../modals/MoveFileDialog'
import RenameFileDialog from '../modals/RenameFileDialog'
import { ContextMenu } from '../shared'
import { MenuOptionProps } from '../shared/Menu/Menu.types'
import { isSeparatorOption, removeConsecutiveSeparators, translateMenuOptions } from '../shared/Menu/utils'

type FileContextMenuProps = {
  id: string
  name: FileFieldsFragment['name']
  isArchived: boolean
  projectId: string
}

const FileContextMenu = ({ id, name, isArchived, projectId }: FileContextMenuProps) => {
  const { t } = useTranslation(['common', 'workspace', 'file'])
  const history = useHistory()
  const client = useApolloClient()

  const canDownload = useFeatureIsOn(FEATURE_KEYS.EXPORT_PHASE_FILE)
  const { userHasPermission } = useAccessControl()
  const { fileWithPermission } = useFilePermissionContext()

  const { addNotification } = useSetNotification()
  const {
    open,
    data: { trigger, cursorPosition }
  } = useModal((o: any) => o[`FileContextMenu-${id}`])
  const { closeModal: closeContextMenu } = useSetModal(`FileContextMenu-${id}`)
  const { duplicateFile, unarchiveFile, archiveFile, exportFile } = useFileActions()

  const [renameModalOpen, setRenameModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [moveModalOpen, setMoveModalOpen] = useState(false)
  const [shareDialogOpen, setShareDialogOpen] = useState(false)

  const menuOptions = useMemo(() => {
    let options = isArchived ? ARCHIVE_FILE_OPTIONS : PROJECT_FILE_OPTIONS

    if (canDownload && !isArchived) {
      options = [...options, ...DOWNLOAD_OPTIONS]
    }

    const filteredOptions = options.filter((option) => {
      if (isSeparatorOption(option)) return true

      return !option.permission || userHasPermission(option.permission, fileWithPermission)
    })

    return translateMenuOptions(t, removeConsecutiveSeparators(filteredOptions), { ns: 'workspace' })
  }, [canDownload, fileWithPermission, isArchived, userHasPermission, t])

  const handleSelectOption = (option: MenuOptionProps) => {
    switch (option.value) {
      case FILE_OPTIONS.OPEN_IN_NEW_TAB:
        window.open(history.createHref({ pathname: getFileIndexPath(id) }))
        break
      case FILE_OPTIONS.COPY_LINK:
        {
          const a = document.createElement('a')
          a.href = history.createHref({ pathname: getFileIndexPath(id) })
          navigator.clipboard.writeText(a.href)
        }
        addNotification({
          type: 'success',
          content: t('common:link_copied')
        })
        break
      case FILE_OPTIONS.SHARE:
        setShareDialogOpen(true)
        break
      case FILE_OPTIONS.RENAME:
        setRenameModalOpen(true)
        break
      case FILE_OPTIONS.DUPLICATE:
        duplicateFile({ projectId, fileId: id, name: t('workspace:duplicated_file_name', { file_name: name }) }).then(
          (meta) => {
            client.refetchQueries({
              include: 'active'
            })
            addNotification({
              type: 'success',
              content: (
                <Trans
                  i18nKey="workspace:duplicated"
                  values={{ file_name: name }}
                  components={[
                    <span
                      key="file-duplicated-notification"
                      className="font-semibold"
                      data-test-id="file-duplicated-notification"
                    />
                  ]}
                />
              ),
              action: t('common:notification.open'),
              callback: () => history.push(getFileIndexPath(meta.id))
            })
          }
        )
        break
      case FILE_OPTIONS.MOVE:
        setMoveModalOpen(true)
        break
      case FILE_OPTIONS.ARCHIVE:
        archiveFile({ projectId, fileId: id }).then(() => {
          client.refetchQueries({
            include: 'active'
          })

          addNotification({
            type: 'success',
            content: (
              <Trans
                i18nKey="workspace:archived"
                values={{ file_name: name }}
                components={[
                  <span
                    key="file-archived-notification"
                    className="font-semibold"
                    data-test-id="file-archived-notification"
                  />
                ]}
              />
            )
          })
        })
        break
      case FILE_OPTIONS.DELETE:
        setDeleteModalOpen(true)
        break
      case FILE_OPTIONS.DOWNLOAD:
        exportFile({ projectId, fileId: id, fileName: `${name}.phase` }).then(() => {
          console.log('DEBUG: exported file')
        })
        break
      case FILE_OPTIONS.UNARCHIVE:
        unarchiveFile({ projectId, fileId: id }).then(() => {
          client.refetchQueries({
            include: 'active'
          })

          addNotification({
            type: 'success',
            content: (
              <Trans
                i18nKey="workspace:unarchived"
                values={{ file_name: name }}
                components={[<span key="file-unarchived-notification" className="font-semibold" />]}
              />
            )
          })
        })
    }
  }

  return (
    <>
      <ContextMenu
        open={open}
        options={menuOptions}
        onSelect={handleSelectOption}
        onClose={closeContextMenu}
        cursorPosition={cursorPosition}
        trigger={trigger}
      />
      <RenameFileDialog
        projectId={projectId}
        id={id}
        originalName={name}
        open={renameModalOpen}
        onClose={() => setRenameModalOpen(false)}
      />
      <DeleteFileDialog
        projectId={projectId}
        id={id}
        name={name}
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
      />
      <MoveFileDialog
        fileId={id}
        originalProjectId={projectId}
        open={moveModalOpen}
        onClose={() => setMoveModalOpen(false)}
      />
      <ShareFileDialog
        projectId={projectId}
        fileId={id}
        open={shareDialogOpen}
        onClose={() => setShareDialogOpen(false)}
      />
    </>
  )
}

export default FileContextMenu
