import { initReactI18next } from 'react-i18next'

import i18n from 'i18next'

import Backend from 'i18next-http-backend'

import { DEFAULT_LOCALE } from './constants/localeConstants'

const configureI18n = (supportedLanguages: string[], loadPath: string) => {
  i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
      fallbackLng: DEFAULT_LOCALE,
      debug: false,
      supportedLngs: supportedLanguages,
      ns: ['common', 'workspace', 'setting', 'tutorial', 'file'],
      load: 'currentOnly',
      backend: {
        loadPath
      }
    })
}

export { i18n, configureI18n }
