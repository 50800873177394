import { useEffect } from 'react'
import { useSetInteraction } from '../../providers/dataStore/InteractionProvider'
import { useDataStore, useSetDataStore } from '../../providers/dataStore/DataStoreProvider'
import { useSetDocument } from '../../providers/dataStore/DocumentProvider'
import { useSkeletonLoadingContext } from '../../providers/SkeletonLoadingProvider'
import { useSetUI } from '../../providers/dataStore/UIProvider'
import { useFullScreenLoadingContext } from '../../providers/FullScreenLoadingProvider'
import { getDataStoreData, getWorkspaceData, getInteractionData } from '../../dataStore'
import { useBroadcaster } from '../../Broadcaster'

const SubscribeDataStoreLoad = () => {
  const { stopFullScreenLoading } = useFullScreenLoadingContext()

  const dataStore = useDataStore()
  const broadcaster = useBroadcaster()
  const { setWorkspace } = useSetDataStore()
  const { setDocument } = useSetDocument()
  const { setInteraction } = useSetInteraction()
  const setUI = useSetUI()

  const { updateFileEditorReady } = useSkeletonLoadingContext()

  useEffect(() => {
    if (!dataStore) return

    const handleLoad = () => {
      const data = getDataStoreData(dataStore)
      const workspace = dataStore.workspaceList
        .map((o) => getWorkspaceData(o))
        .reduce((acc, cur) => {
          acc[cur.id] = cur
          return acc
        }, {})

      setDocument(data)
      setInteraction(getInteractionData(data.interaction, dataStore))
      setWorkspace(workspace)
      const screen = dataStore.workspace.watched.children[0]
      screen.set('expanded', true)
      dataStore.workspace.on('scale', (scale) => {
        setUI((s) => ({ ...s, zoom: scale }))
      })
      dataStore.on('editMode', (editMode) => {
        // FIXME: remove the timer function
        // delay the UIProvider updates until EditorProvider is updated
        // to avoid the property panel get the incorrect data from EditorProvider
        setTimeout(() => {
          setUI((s) => ({ ...s, editMode }))
        })
      })

      setUI((s) => ({
        ...s,
        mode: dataStore.get('mode'),
        isEditingState: dataStore.get('state') === 'EDITING',
        isTablingState: dataStore.get('state') === 'TABLING',
        isVersioningState: dataStore.get('state') === 'VERSIONING',
        isInspectingState: dataStore.get('state') === 'INSPECTING'
      }))
      stopFullScreenLoading()

      updateFileEditorReady(true)
    }

    dataStore.on('LOAD', handleLoad)

    broadcaster.setDataStore(dataStore)

    return () => {
      dataStore.off('LOAD', handleLoad)
    }
  }, [dataStore, setDocument, setWorkspace, setUI, setInteraction, broadcaster, updateFileEditorReady, stopFullScreenLoading])

  return null
}
export default SubscribeDataStoreLoad
