import React from 'react'
import CustomContentLoader from './CustomContentLoader'

// Sidebar project list loading
const ProjectListLoading = () => {
  return (
    <div className="flex items-center p-24 text-light-overlay-10">
      <CustomContentLoader width={120} height={216} viewBox="0 0 120 216">
        <rect width="80" height="16" rx="5" />
        <rect y="40" width="120" height="16" rx="5" />
        <rect y="80" width="104" height="16" rx="5" />
        <rect y="120" width="80" height="16" rx="5" />
        <rect y="160" width="120" height="16" rx="5" />
        <rect y="200" width="104" height="16" rx="5" />
      </CustomContentLoader>
    </div>
  )
}

export default ProjectListLoading
