import { useCallback } from 'react'
import { createProvider } from '../utils'

const defaultValue = null
const [Provider, useSelectState, useSetState] = createProvider('ActiveTableElementSelection', defaultValue)

export const useActiveTableElementSelection = useSelectState

export const useSetActiveTableElementSelection = () => {
  const setState = useSetState()
  const setActiveTableElementSelection = useCallback((selection) => setState(selection), [setState])
  return { setActiveTableElementSelection }
}

export default Provider
