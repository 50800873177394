import { useCallback } from 'react'
import { schema } from 'normalizr'
import { createProvider, useEntity } from '../utils'
import { Element, useSetElement } from './ElementProvider'

export const Workspace = new schema.Entity('workspaces', {
  children: [Element]
})

const defaultValue = {}
const [Provider, useSelectState, useSetState] = createProvider('Workspace', defaultValue)

export const useWorkspace = useSelectState

export const useSetWorkspace = () => {
  const { createElement, destroyElement } = useSetElement()
  const setState = useSetState()
  const [
    setWorkspace,
    addWorkspace,
    updateWorkspace,
    removeWorkspace,
    mergeWorkspace,
    reduceWorkspace,
    create,
    destroy
  ] = useEntity(Workspace, setState)

  const createWorkspace = useCallback(
    (entities) => {
      createElement(entities)
      create(entities)
    },
    [create, createElement]
  )

  const destroyWorkspace = useCallback(
    (entities) => {
      destroyElement(entities)
      destroy(entities)
    },
    [destroy, destroyElement]
  )

  return {
    setWorkspace,
    addWorkspace,
    updateWorkspace,
    removeWorkspace,
    mergeWorkspace,
    reduceWorkspace,
    createWorkspace,
    destroyWorkspace
  }
}

export default Provider
