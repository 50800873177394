import React, { useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

// @ts-ignore
import { MIN_SIZE_THRESHOLD } from '@phase-software/data-utils'
import { Unit } from '@phase-software/types'

import { useScrollIntoView } from '../../../../providers/ScrollProvider/useScrollIntoView'
import { useDataStoreActions } from '../../../../providers/dataStore/DataStoreProvider'
import { useEditor } from '../../../../providers/dataStore/EditorProvider'
import { useUI } from '../../../../providers/dataStore/UIProvider'
import { formatAsPercentage, formatNumberWithUnit } from '../../../../utils/formatter'
import { decimalValidator, transformPercentageFormatValidator } from '../../../../utils/validator'
import { Button, Tooltip } from '../../../shared'
import Property from '../Property'
import PropertyRow from '../PropertyRow'
import OriginMap from './OriginMap'

const getHelpers = (unit: Unit) => {
  if (unit === Unit.PERCENT) {
    return [formatAsPercentage, transformPercentageFormatValidator]
  }

  return [formatNumberWithUnit, decimalValidator]
}

const Origin = () => {
  const ref = useRef<HTMLDivElement>(null)
  const { contentAnchorXUnit, contentAnchorYUnit, width, height } = useEditor()
  const [contentAnchorXFormatter, contentAnchorXValidator] = getHelpers(contentAnchorXUnit)
  const [contentAnchorYFormatter, contentAnchorYValidator] = getHelpers(contentAnchorYUnit)
  const { editOrigin } = useUI()
  const { setFeature } = useDataStoreActions()
  const { activateScroll, handleAutoScroll } = useScrollIntoView()
  const { t } = useTranslation('file', { keyPrefix: 'property_editor.transform_editor' })

  const handleEditOriginClick = useCallback(() => {
    setFeature('editOrigin', !editOrigin)
    activateScroll()
  }, [editOrigin, setFeature, activateScroll])

  const isWidthTinySize = width !== 0 && Math.abs(width) < MIN_SIZE_THRESHOLD
  const isHeightTinySize = height !== 0 && Math.abs(height) < MIN_SIZE_THRESHOLD

  const handleXDisplay = (val: number) => {
    // reach the minimum display value for 0-size and collinear line
    if (isWidthTinySize && val <= 1) return 0
    return val
  }

  const handleYDisplay = (val: number) => {
    // reach the minimum display value for 0-size and collinear line
    if (isHeightTinySize && val <= 1) return 0
    return val
  }
  const EditOriginBtnText = editOrigin ? t('exit_origin_mode_button_text') : t('enter_origin_mode_button_text')

  useEffect(() => {
    if (editOrigin) {
      handleAutoScroll(ref?.current)
    }
  }, [editOrigin, handleAutoScroll])

  return (
    <>
      <div className="pr-24 pb-8">
        <Tooltip content={{ text: EditOriginBtnText, shortcut: 'Y' }}>
          <Button
            fluid
            size="s"
            variant="solid"
            className="input-system-handle-event"
            color={editOrigin ? 'primary' : 'secondary'}
            onClick={handleEditOriginClick}
          >
            {EditOriginBtnText}
          </Button>
        </Tooltip>
      </div>
      {editOrigin && (
        <>
          <PropertyRow columns={[94, 94]}>
            <Property
              captionAlign="center"
              field="contentAnchorX"
              caption="X"
              data-test-id="origin-X-input"
              formatter={contentAnchorXFormatter}
              validator={contentAnchorXValidator}
              toDisplay={handleXDisplay}
            />
            <Property
              captionAlign="center"
              field="contentAnchorY"
              caption="Y"
              data-test-id="origin-Y-input"
              formatter={contentAnchorYFormatter}
              validator={contentAnchorYValidator}
              toDisplay={handleYDisplay}
            />
          </PropertyRow>
          <PropertyRow ref={ref} columns={[196]} className="h-fit">
            <div className="w-full h-72 bg-light-overlay-3 rounded-md">
              <OriginMap />
            </div>
          </PropertyRow>
        </>
      )}
    </>
  )
}

export default React.memo(Origin)
