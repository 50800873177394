import { BooleanOperation, ElementType, EntityType } from '@phase-software/types'
import { setupBaseGeometry } from '../node-setup'
import {
    watchElementChanges,
} from '../update-controller'
import { MaskType, UpdateType } from '../visual_server/RenderItem'
import { dino, getNodeDino } from '../dino'

/** @typedef {import('../visual_server/SpatialCache').SceneNode} SceneNode */
/** @typedef {import('../visual_server/RenderItem').RenderItem} RenderItem */

// <element.id, wasm.geo_bank.id>
/** @type {Map<string, number>} */
export const idMap = new Map()
export const bank = {
    geo_bank_id: 1
}

/**
 * @param {VisualServer} VS
 * @param {SceneNode} node
 * @param {Element} element
 * @returns {RenderItem}
 */
export function initSceneNodeWithElement(VS, node, element) {
    const item = node.item

    item.name = element.get('name')

    const id = element.get('id')
    idMap.set(id, bank.geo_bank_id++)

    const entityType = element.get('type')
    const elementType = element.get('elementType')

    item.booleanType = BooleanOperation.NONE
    if (entityType !== EntityType.WORKSPACE && elementType === ElementType.CONTAINER) {
        if (element.get('isMask')) {
            item.maskType = MaskType.ALPHA
        }
        item.booleanType = element.get('booleanType') || BooleanOperation.NONE
    }

    item.type = _determineItemType(elementType, item, element)
    {
        const { dinoNode } = getNodeDino(item)
        createDinoSubTree(item.type, dinoNode)
    }

    if (entityType === EntityType.ELEMENT) {
        setupBaseGeometry(VS.storage, element, node)
    }

    // subscribe to change events
    watchElementChanges(element, item)

    return item
}

/**
 * @param {RootTree} node
 */
export function destroyDinoNode(node) {
    const api = dino()

    // Destroy or remove compose
    if (node.children.compose[0] !== null) api.destroyNodeCompose(node.children.id, 0)
    if (node.children.compose[1] !== null) api.removeNodeCompose(node.children.id, 1)
    node.children.compose[0] = null
    node.children.compose[1] = null

    // Destroy paths
    if (node.path_id !== 0) api.destroyPath(node.path_id)
    if (node.base_path_id !== 0) api.destroyPath(node.base_path_id)
    node.path_id = 0
    node.base_path_id = 0

    // Destroy itself
    if (node.id !== 0) api.destroyNode(node.id)
    if (node.children.id !== 0) api.destroyNode(node.children.id)
    if (node.fills.id !== 0) api.destroyNode(node.fills.id)
    if (node.strokes.id !== 0) api.destroyNode(node.strokes.id)
    node.id = 0
    node.children.id = 0
    node.fills.id = 0
    node.strokes.id = 0
}

/**
 * One Element/RenderNode is corresponding to a Dino subtree
 * @param {string} type
 * @param {*} node Dino node object
 */
export function createDinoSubTree(type, node) {
    const api = dino()
    // each item at least has two level hierarchy for represeting phase transform model
    switch (type) {
        case "screen": {
            node.id = api.makeNode(api.GROUP)
            // fill/stroke holder
            node.fills.id = api.makeNode(api.GROUP)
            api.addNodeChild(node.id, node.fills.id)
            // child container
            node.children.id = api.makeNode(api.GROUP)
            api.addNodeChild(node.id, node.children.id)
            break
        }
        case "group": {
            node.id = api.makeNode(api.GROUP)
            node.children.id = api.makeNode(api.GROUP)
            api.addNodeChild(node.id, node.children.id)
            break
        }
        case "container": {
            node.id = api.makeNode(api.GROUP)
            // fill/stroke holder
            node.fills.id = api.makeNode(api.GROUP)
            api.addNodeChild(node.id, node.fills.id)
            node.strokes.id = api.makeNode(api.GROUP)
            api.addNodeChild(node.id, node.strokes.id)
            // child container
            node.children.id = api.makeNode(api.GROUP)
            api.addNodeChild(node.id, node.children.id)
            break
        }
        case "path": {
            node.id = api.makeNode(api.GROUP)
            // fill/stroke holder
            node.fills.id = api.makeNode(api.GROUP)
            api.addNodeChild(node.id, node.fills.id)
            node.strokes.id = api.makeNode(api.GROUP)
            api.addNodeChild(node.id, node.strokes.id)
            break
        }
        case "mask": {
            node.id = api.makeNode(api.GROUP)
            // child container
            node.children.id = api.makeNode(api.GROUP)
            api.addNodeChild(node.id, node.children.id)
            break
        }
        case "text": {
            node.id = api.makeNode(api.TEXT)
            node.fills.id = node.id
            break
        }
    }
}

/**
 * @param {SceneNode} item
 */
export function updateBooleanParent(item) {
    let booleanParent = item.parent
    while (booleanParent) {
        if (booleanParent.item.isBooleanGroup()) {
            booleanParent.item.update(UpdateType.GEOMETRY)
        }
        booleanParent = booleanParent.parent
    }
}

// Define a function to determine the type of an item
/**
 * @param {ElementType} elementType
 * @param {RenderItem} item
 * @param {Element}element
 * @returns {import('../visual_server/RenderItem').NodeTypes}
 */
function _determineItemType(elementType, item, element) {
    switch (elementType) {
        case ElementType.CONTAINER: {
            if (element.isNormalGroup) {
                return "group"
            } else if (element.isBooleanType()) {
                return "path"
            } else if (element.isMaskGroup()) {
                return "mask"
            } else {
                return "container"
            }
        }
        case ElementType.SCREEN: return "screen"
        case ElementType.PATH: return "path"
        case ElementType.TEXT: return "text"
    }
    return "group"
}
