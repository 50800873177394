import { useEffect, useCallback, memo } from 'react'
import { useDataStore, useSetDataStore } from '../../providers/dataStore/DataStoreProvider'
import { useSetInteraction } from '../../providers/dataStore/InteractionProvider'

const SubscribeKeyframeSelectionChange = () => {
  const dataStore = useDataStore()
  const { setKeyframeSelection } = useSetDataStore()
  const { updateTrackSelected } = useSetInteraction()
  const cb = useCallback(
    (changes) => {
      const kfc = changes.get('kfs')
      if (kfc) {
        setKeyframeSelection(kfc.after)
        updateTrackSelected(kfc.after)
      }
    },
    [setKeyframeSelection, updateTrackSelected]
  )
  useEffect(() => {
    if (!dataStore) return
    dataStore.selection.on('SELECT', cb)

    return () => {
      dataStore.selection.off('SELECT', cb)
    }
  }, [dataStore, cb])
  return null
}

export default memo(SubscribeKeyframeSelectionChange)
