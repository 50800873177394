import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { RouteConfig, matchRoutes } from 'react-router-config'

import { useApolloClient } from '@apollo/client'
import { TeamMemberRoleEnum, WorkspaceType } from '@phase-software/types'

import { AccessControlProvider } from '../access-control'
import rules from '../access-control/rules'
import { MY_WORKSPACE } from '../constant'
import {
  GetFileByIdDocument,
  GetTeamBySlugDocument,
  GetTeamDraftProjectsDocument,
  GetTeamMemberByUserIdDocument,
  ProjectFieldsFragment,
  TeamFieldsFragment,
  UserFieldsFragment
} from '../generated/graphql'
import { useProfile } from './ProfileProvider'

export type WorkspaceData = {
  avatarUrl: UserFieldsFragment['avatar'] | TeamFieldsFragment['icon']
  draftProjectId: ProjectFieldsFragment['id']
  id: UserFieldsFragment['id'] | TeamFieldsFragment['id']
  isOwner: boolean
  isPersonal: boolean
  name: UserFieldsFragment['username'] | TeamFieldsFragment['name']
  role: TeamMemberRoleEnum
  slug: string
  type: WorkspaceType
}

type WorkspaceContextData = {
  personalWorkspaceData: WorkspaceData
  workspaceData: WorkspaceData
  isWorkspaceDataLoading: boolean
}

type WorkspaceDataProviderProps = {
  children: React.ReactNode
  routes: RouteConfig[]
}

export const WorkspaceContext = createContext<WorkspaceContextData | undefined>(undefined)

const WorkspaceContextProvider = ({ routes, children }: WorkspaceDataProviderProps) => {
  const { t } = useTranslation('workspace')
  const history = useHistory()
  const client = useApolloClient()

  const profile = useProfile()
  const [isWorkspaceDataLoading, setIsWorkspaceDataLoading] = useState<boolean>(true)

  const personalWorkspaceData: WorkspaceData = useMemo(
    () => ({
      avatarUrl: profile.avatar,
      draftProjectId: profile.draftProjectId,
      id: profile.id,
      isOwner: false,
      isPersonal: true,
      name: t(MY_WORKSPACE),
      role: TeamMemberRoleEnum.OWNER,
      slug: profile.custom_slug,
      type: WorkspaceType.PERSONAL
    }),
    [profile, t]
  )

  const [workspaceData, setWorkspaceData] = useState<WorkspaceData>(personalWorkspaceData)
  const workspaceDataRef = useRef(workspaceData)

  useEffect(() => {
    workspaceDataRef.current = workspaceData
  }, [workspaceData])

  const handleFileRoute = useCallback(
    async (fileId: string) => {
      const { data: fileData } = await client.query({
        query: GetFileByIdDocument,
        variables: {
          id: fileId
        }
      })
      // invalid file id
      if (!fileData.files_by_pk) {
        history.push('/404')
        return
      }

      const teamData = fileData.files_by_pk.project?.team
      const isTeamWorkspace = teamData !== null

      if (isTeamWorkspace) {
        const { data: teamDraftProjects } = await client.query({
          query: GetTeamDraftProjectsDocument,
          variables: {
            id: teamData?.id
          }
        })

        const { data: teamMemberData } = await client.query({
          query: GetTeamMemberByUserIdDocument,
          variables: {
            id: profile.id,
            teamId: teamData?.id
          }
        })

        const currentUserTeamRole = teamMemberData?.team_users[0].team_role

        setWorkspaceData({
          avatarUrl: teamData?.icon,
          draftProjectId: teamDraftProjects?.projects?.[0]?.id,
          id: teamData?.id,
          isOwner: currentUserTeamRole === TeamMemberRoleEnum.OWNER,
          isPersonal: false,
          name: teamData?.name,
          role: currentUserTeamRole,
          slug: teamData?.custom_slug,
          type: WorkspaceType.TEAM
        })
      } else {
        setWorkspaceData(personalWorkspaceData)
      }
      setIsWorkspaceDataLoading(false)
    },
    [client, history, personalWorkspaceData, profile.id]
  )

  const handleWorkspaceRoute = useCallback(
    async (type: WorkspaceData['type'], slug: WorkspaceData['slug']) => {
      const isTeamWorkspace = type === WorkspaceType.TEAM
      if (isTeamWorkspace) {
        const { data: teamData } = await client.query({
          query: GetTeamBySlugDocument,
          variables: {
            slug
          },
          fetchPolicy: 'network-only'
        })

        // invalid team slug
        if (teamData.teams.length === 0) {
          history.push('/404')
          return
        }

        const { data: teamDraftProjectData } = await client.query({
          query: GetTeamDraftProjectsDocument,
          variables: {
            id: teamData.teams[0].id
          },
          fetchPolicy: 'network-only'
        })

        const { data: teamMemberData } = await client.query({
          query: GetTeamMemberByUserIdDocument,
          variables: {
            id: profile.id,
            teamId: teamData.teams[0].id
          },
          fetchPolicy: 'network-only'
        })

        const currentUserTeamRole = teamMemberData?.team_users[0].team_role

        setWorkspaceData({
          avatarUrl: teamData.teams[0].icon,
          draftProjectId: teamDraftProjectData?.projects?.[0]?.id,
          id: teamData.teams[0].id,
          isOwner: currentUserTeamRole === TeamMemberRoleEnum.OWNER,
          isPersonal: false,
          name: teamData.teams[0].name,
          slug,
          type,
          role: currentUserTeamRole
        })
      } else {
        // invalid personal slug
        if (slug !== personalWorkspaceData.slug) {
          history.push('/404')
          return
        }
        setWorkspaceData(personalWorkspaceData)
      }
      setIsWorkspaceDataLoading(false)
    },
    [client, history, personalWorkspaceData, profile.id]
  )

  const handleLocationChange = useCallback(
    (location: any) => {
      const branch = matchRoutes(routes, location.pathname.replace(process.env.PUBLIC_URL, ''))
      const { fileId, workspaceType, workspaceSlug } = branch?.[0]?.match.params as {
        fileId?: string
        workspaceType?: WorkspaceData['type']
        workspaceSlug?: WorkspaceData['slug']
      }

      const decodedWorkspaceSlug = workspaceSlug ? decodeURIComponent(workspaceSlug) : ''

      if (fileId) {
        setIsWorkspaceDataLoading(true)
        handleFileRoute(fileId)
      } else if (workspaceType && decodedWorkspaceSlug) {
        // When the same slug is present, the Workspace sidebar's skeleton should not appear.
        if (decodedWorkspaceSlug !== workspaceDataRef.current.slug) setIsWorkspaceDataLoading(true)
        handleWorkspaceRoute(workspaceType, decodedWorkspaceSlug)
      } else {
        setIsWorkspaceDataLoading(false)
      }
    },
    [handleFileRoute, handleWorkspaceRoute, routes]
  )

  useEffect(() => {
    handleLocationChange(history.location)

    const unlisten = history.listen((location) => {
      handleLocationChange(location)
    })

    return unlisten
  }, [handleLocationChange, history])

  return (
    <WorkspaceContext.Provider value={{ workspaceData, personalWorkspaceData, isWorkspaceDataLoading }}>
      <AccessControlProvider
        role={workspaceData.role}
        rules={rules}
        user={{
          ...profile,
          role: workspaceData.role
        }}
      >
        {children}
      </AccessControlProvider>
    </WorkspaceContext.Provider>
  )
}

function useWorkspaceContext(): WorkspaceContextData {
  const context = useContext(WorkspaceContext)
  if (context === undefined) {
    throw new Error('useWorkspaceContext must be used within a WorkspaceContextProvider')
  }
  return context
}

export { WorkspaceContextProvider, useWorkspaceContext }
