import styled from 'styled-components'
import { compose, color, space, layout, typography, grid, flexbox } from 'styled-system'

const Grid = styled('div')(compose(color, grid, flexbox, layout, space, typography))

Grid.defaultProps = {
  display: 'grid',
  alignItems: 'center'
}

export default Grid
