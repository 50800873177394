import React from 'react'
import { useTranslation } from 'react-i18next'

import { useEditor } from '../../../providers/dataStore/EditorProvider'
import { formatAsPercentage, multiplyBy100 } from '../../../utils/formatter'
import { blendMap } from '../constant'
import InspectRow from './InspectRow'
import InteractiveInspectContent from './InteractiveInspectContent'

const OpacityInspection = () => {
  const { t } = useTranslation('file')
  const { blendMode, opacity } = useEditor()

  return (
    <InteractiveInspectContent title={t('inspect.opacity')} target="static_opacity">
      <InspectRow label={t('inspect.blend')} value={t(blendMap[blendMode], { keyPrefix: 'layer_blend_mode' })} />
      <InspectRow label={t('inspect.opacity')} value={formatAsPercentage(multiplyBy100(opacity))} />
    </InteractiveInspectContent>
  )
}

export default React.memo(OpacityInspection)
