import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import PropertyState from '../../Properties/PropertyEditors/PropertyState'
import ErrorTextComponent from '../ErrorTextComponent'
import Icon from '../Icon'
import { color } from '../utils'
import SelectText from './SelectText'

const SearchInput = styled.input`
  flex: 1;
  height: 100%;
  outline: 0;
  background: none;
  border: 0;
  color: white;
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  &::-webkit-search-cancel-button {
    display: none;
  }
  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
  &::placeholder {
    color: ${color('lightOverlays', 60)};
  }
`

const DefaultSelect = forwardRef(
  (
    {
      className,
      size,
      selected,
      disabled,
      selectTextRender,
      inline,
      caret,
      mixed,
      error,
      keyFrameIconProps,
      showSearch,
      openMenu,
      onChangeSearch,
      search,
      placeholder,
      hideFocusStyle,
      dataTestId,
      ...rest
    },
    forwardRef
  ) => {
    const searchRef = useRef()
    const [isSearchInputFocused, setIsSearchInputFocused] = useState(false)
    const [hoverSelect, setHoverSelect] = useState(false)
    const [hoverSelectWrap, setHoverSelectWrap] = useState(false)

    const handleMouseSelectEnter = useCallback(() => {
      setHoverSelect(true)
    }, [])

    const handleMouseSelectLeave = useCallback(() => {
      setHoverSelect(false)
    }, [])

    const handleMouseSelectWrapEnter = useCallback(() => {
      setHoverSelectWrap(true)
    }, [])

    const handleMouseSelectWrapLeave = useCallback(() => {
      setHoverSelectWrap(false)
    }, [])

    const handleSearchInputOnFocus = useCallback(() => {
      setIsSearchInputFocused(true)
    }, [])

    const handleSearchInputOnBlur = useCallback(() => {
      setIsSearchInputFocused(false)
    }, [])

    useEffect(() => {
      if (showSearch && !openMenu) {
        onChangeSearch('')
      }
    }, [showSearch, openMenu, onChangeSearch])

    const SelectText = selectTextRender

    const iconHoverStyle = 'group-hover:text-white group-active:text-light-overlay-40 active:text-light-overlay-40'
    const iconDisabledStyle = 'text-opacity-100'
    const iconBasicStyle = 'flex items-center justify-center text-light-overlay-60'
    const rightIcon = useMemo(() => {
      if (!caret) return null
      const iconStyle = `${iconBasicStyle} ${disabled ? iconDisabledStyle : iconHoverStyle}`
      return <Icon className={`${iconStyle}`} name="Angle" interactive={false} />
    }, [caret, disabled])

    const right = useMemo(() => {
      if (keyFrameIconProps) {
        return (
          <PropertyState
            {...keyFrameIconProps}
            hoverContent={hoverSelect}
            hoverWrap={hoverSelectWrap}
            rightComponent={rightIcon}
          />
        )
      }
      if (rightIcon) {
        return rightIcon
      }
      return null
    }, [rightIcon, keyFrameIconProps, hoverSelectWrap, hoverSelect])

    const highlightBorderClassName = disabled
      ? ''
      : error
      ? 'highlight-border-rounded-md-error'
      : 'highlight-border-rounded-md'
    const sizeClass = size === 'l' ? 'h-32 rounded-md' : 'h-28 rounded-md'
    const hideFocusStyleClassName = hideFocusStyle ? '' : highlightBorderClassName
    const inlineClassName = inline ? 'inline-flex w-auto' : 'flex w-full'
    const activeClassName = 'active:bg-light-overlay-3 active:outline-light-overlay-20-1-offset--1'
    const hoverClassName = 'hover:bg-light-overlay-10 hover:outline-light-overlay-20-1-offset--1'
    const disabledClassName =
      'disabled:bg-light-overlay-10 disabled:text-opacity-100 disabled:opacity-40 disabled:cursor-not-allowed disabled:outline-none'
    const normalClassName =
      'group pl-8 justify-between items-center flex-grow min-w-0 text-12 text-white bg-light-overlay-5'
    return (
      <ErrorTextComponent showErrorTips={!!error} errorTips={error} className="mt-8">
        <button
          onMouseEnter={handleMouseSelectWrapEnter}
          onMouseLeave={handleMouseSelectWrapLeave}
          disabled={disabled}
          ref={forwardRef}
          className={`input-system-handle-event ${normalClassName} ${inlineClassName} ${hideFocusStyleClassName} ${sizeClass} ${disabledClassName} ${activeClassName} ${hoverClassName} ${className}`}
          type="button"
          data-test-id={dataTestId}
          {...rest}
        >
          {showSearch ? (
            <SearchInput
              onBlur={handleSearchInputOnBlur}
              onFocus={handleSearchInputOnFocus}
              ref={searchRef}
              disabled={disabled}
              value={search || (isSearchInputFocused ? '' : selected?.name)}
              placeholder={isSearchInputFocused && selected?.name ? selected?.name : placeholder}
              onChange={(e) => {
                onChangeSearch(e.target.value)
              }}
              tabIndex={-1}
            />
          ) : (
            <div
              className="flex-1 flex h-full items-center truncate"
              onMouseEnter={handleMouseSelectEnter}
              onMouseLeave={handleMouseSelectLeave}
            >
              <SelectText selected={selected} mixed={mixed} />
            </div>
          )}
          <div className={`${size === 'l' ? 'p-8' : 'p-6'}`}>{right}</div>
        </button>
      </ErrorTextComponent>
    )
  }
)
DefaultSelect.displayName = 'DefaultSelect'
DefaultSelect.propTypes = {
  size: PropTypes.oneOf(['s', 'l']),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
  mixed: PropTypes.bool,
  selected: PropTypes.object,
  selectTextRender: PropTypes.func,
  caret: PropTypes.bool,
  error: PropTypes.string,
  keyFrameIconProps: PropTypes.object,
  showSearch: PropTypes.bool,
  openMenu: PropTypes.bool,
  search: PropTypes.string,
  onChangeSearch: PropTypes.func,
  placeholder: PropTypes.string,
  hideFocusStyle: PropTypes.bool,
  dataTestId: PropTypes.string
}
DefaultSelect.defaultProps = {
  selected: null,
  selectTextRender: SelectText,
  className: ''
}

export default DefaultSelect
