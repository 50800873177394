import React from 'react'
import FileLayout from '../../../pages/File/FileLayout'
import EditorLayerPanelLoading from './EditorLayerPanelLoading'
import EditorPropertyPanelLoading from './EditorPropertyPanelLoading'
import { FILE_EDITOR_LAYOUT_AREAS } from '../../../constant'

const FileEditorLoading = () => {
  return (
    <FileLayout>
      <div
        className="grid items-center bg-neutral-90 border-b border-solid border-neutral-80"
        style={{ gridArea: FILE_EDITOR_LAYOUT_AREAS.HEADER, gridTemplateColumns: '1fr auto 1fr' }}
      ></div>
      <EditorLayerPanelLoading />
      <EditorPropertyPanelLoading />
    </FileLayout>
  )
}

export default FileEditorLoading
