import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react'

import { Icon, Menu } from '../'
import { MenuProps } from '../Menu/Menu.types'

export interface DropdownProps {
  arrow?: boolean
  children: React.ReactNode
  inline?: boolean
  selectable?: boolean
  className?: string
  dataTestId?: string
  menu?: Omit<MenuProps, 'open' | 'onOpenChange' | 'type'>
}

const Dropdown = forwardRef<HTMLDivElement, DropdownProps>(
  ({ children, inline = false, className = '', dataTestId, menu, arrow = false }, forwardRef) => {
    const ref = useRef<HTMLDivElement>(null)
    const [open, setOpen] = useState(false)
    const [needFocusWhenDismiss, setNeedFocusWhenDismiss] = useState(false)

    useImperativeHandle(forwardRef, () => ref.current as HTMLDivElement)
    const handleClick = (e: React.MouseEvent) => {
      e.stopPropagation()
      e.preventDefault()
      setOpen(!open)
      setNeedFocusWhenDismiss(false)
    }
    const handleKeyDown = (e: React.KeyboardEvent) => {
      if (e.key === ' ' || e.key === 'Enter') {
        e.stopPropagation()
        e.preventDefault()
        ;(e.target as HTMLElement).click()
        setNeedFocusWhenDismiss(true)
      }
    }
    const closeMenu = useCallback(() => {
      setOpen(false)
      if (needFocusWhenDismiss) (ref.current?.firstChild as HTMLDivElement)?.focus()
    }, [setOpen, needFocusWhenDismiss])

    const displayClassName = inline ? 'inline' : 'block'

    if (!menu?.options || menu.options.length === 0) {
      return <div className={`${displayClassName} ${className}`}>{children}</div>
    }
    return (
      <>
        <div
          ref={ref}
          onClick={handleClick}
          onKeyDown={handleKeyDown}
          className={`group cursor-pointer ${displayClassName} ${className}`}
          data-test-id={dataTestId}
        >
          <div className="flex">
            {children}
            {arrow && (
              <Icon
                className="flex items-center justify-center ml-4 text-light-overlay-60 group-hover:text-white"
                name="Angle"
                interactive={false}
                useCurrentColor
              />
            )}
          </div>
        </div>
        <Menu
          type="dropdown"
          open={open}
          options={menu.options}
          trigger={ref}
          onSelect={menu.onSelect}
          onOpenChange={closeMenu}
          className="dropdown-menu"
          selectable={menu.selectable || false}
          selectedValues={menu.selectedValues}
        />
      </>
    )
  }
)
Dropdown.displayName = 'Dropdown'

export default Dropdown
