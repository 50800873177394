import { Mode, DirectionType, AlignType, DistributeType, EditMode } from '@phase-software/types'
import { Events } from '@phase-software/data-store'
import { NO_FIRE } from '@phase-software/data-utils'
import IS from '../input-system'
import { Vector2 } from '../math'
import initLocalCursor from './local_cursor'
import { initSelection } from './selection'
import SelectionArea from './selection/area'
import { initHandles } from './handles'
import { initGradient } from './gradient'
import { initText } from './text'
import { initScrollbar } from './scrollbar'
import { initCreationTools } from './creationTools'
import ExportMedia from './export_media'
import Alignment, { initAlignmentDistribution } from './alignment'
import { roundToDecimalPlaces, waitFrame } from './export_media/utils'

/** @typedef {import('@phase-software/data-utils').Vertex} Vertex */
/** @typedef {import('@phase-software/data-utils').AABB} AABB */
/** @typedef {import('@phase-software/data-store/src/Element').Element} Element */
/** @typedef {import('../visual_server/VisualServer').VisualServer} VisualServer */
/** @typedef {import('../Viewport').Viewport} Viewport */
/** @typedef {import('../math/rect2').Rect2} Rect2 */

/** @typedef {import('./local_cursor').CursorHandler} CursorHandler */
/** @typedef {import('./local_cursor').GetCursorStateFn} GetCursorStateFn */
/** @typedef {import('./local_cursor').SetCursorStateFn} SetCursorStateFn */

/** @type {number} */
const DEFAULT_FPS = 30

/** @type {number} */
const DEFAULT_SPEED = 1

/** @type {VisualServer} */
let _visualServer

/** @type {CursorHandler} */
let _localCursorHandler

/** @type {DataStore} */
let _dataStore

/** @type {Viewport} */
let _viewport

/** @type {ElementHandler} */
let _elementHandlers

/** @type {SelectionArea} */
let _selectionArea

/** @type {ExportMedia} */
let exportMedia

/**
 * @param {VisualServer} visualServer
 */
export function init(visualServer) {
    _visualServer = visualServer
    _dataStore = visualServer.dataStore
    _viewport = visualServer.viewport
    _localCursorHandler = initLocalCursor(_visualServer.canvas)

    initSelection(_visualServer, _localCursorHandler.setState)
    _elementHandlers = initHandles(_visualServer, _localCursorHandler)
    _selectionArea = new SelectionArea(_visualServer)
    initGradient(_visualServer, _localCursorHandler.setState)
    initText(_visualServer)
    initScrollbar(_visualServer, _localCursorHandler.setState)
    initCreationTools(_visualServer, _elementHandlers, _selectionArea, _localCursorHandler.setState)
    initAlignmentDistribution(_visualServer)
    exportMedia = new ExportMedia(_visualServer, _dataStore)

    _bindInputsListeners()
    _onEAMEvents()
    _onResizeEvents()
    _bindViewportActionHandlers()
    _bindGeneralShortcuts()

    /** @type {Set<Element>} */
    const listeners = new Set()

    const onSelectionChange = () => {
        const elements = new Set(_dataStore.selection.get('elements'))
        for (const element of elements) {
            if (listeners.has(element)) continue
            listeners.add(element)
        }
        for (const element of listeners) {
            if (elements.has(element)) continue
            listeners.delete(element)
        }
    }

    /** @param {import('@phase-software/data-store/src/DataStore').State} state */
    const onStateChange = state => {
        switch (state) {
            case 'PREVIEW': {
                _dataStore.eam.switchMode(Mode.PREVIEW)
                break
            }
            case 'EDITING': {
            // case 'TABLING': {
                // set cursor to default to prevent cursor not set when reload the page
                _localCursorHandler.setState('default')
                onSelectionChange()
                break
            }
            case 'VIEWING':
            case 'VERSIONING': {
                // set cursor to grab to prevent cursor not set when reload the page
                _localCursorHandler.setState('grab')
                _dataStore.selection.clear()
                break
            }
        }
    }

    _dataStore.on('state', onStateChange)
    onStateChange(_dataStore.get('state'))

    _dataStore.selection.on('SELECT', onSelectionChange)
}
//#region dataStore changes callback functions
/**
 * @callback dataStoreChangesCallback This callback is controlling the changes of the dataStore in actions of the renderer
 * @param {object} value The value of the data
 * @param {object} original The original value of the data before the change
 */

//#endregion


/**
 * @param {Rect2} area
 * @param {number} padding
 */
// eslint-disable-next-line no-unused-vars
function focusArea(area, padding = 25) {
    // offset the viewport, taking ruler into account
    const rulerWidth = 20
    const bounds = area.clone()
    bounds.width -= rulerWidth
    bounds.height -= rulerWidth
    _viewport.focus(bounds, 25 + rulerWidth * 0.5)
}

/**
 * Fits whole Canvas content in the viewport
 */
export function focusContent() {
    const node = _visualServer.indexer.root?.getFirstChild().item
    if (node) {
        focusArea(node.bounds.world)
    }
}

/**
 * Fits the viewport to selection
 */
export function focusSelection() {
    if (_visualServer.selection.size > 0) {
        focusArea(_visualServer.selection.bounds)
    } else {
        focusContent()
    }
}

/**
 * Moves viewport to center on selection
 */
export function centerSelection() {
    _viewport.moveTo(_visualServer.selection.bounds)
}

export function focusPreviewContent() {
    const screen = _dataStore.get('preview')
    /** @type {import('@phase-software/data-store/src/DataStore').PreviewZoom} */
    const mode = _dataStore.get('previewZoom')
    _viewport.focus(_visualServer.getRenderItemOfElement(screen).bounds.world, 0, 0, 0, mode === 'FILL')
}

function _bindInputsListeners() {
    IS.get('MOUSE_MOVE').on('trigger', (eventData, keyCombo) => {
        _dataStore.eam.mouseMove(eventData, keyCombo.modifiers)
    })

    IS.get('DELETE').on('trigger', (eventData, keyCombo) => {
        _dataStore.eam.delete(eventData, keyCombo.modifiers)
    })

    IS.get('DOUBLE_LEFT_CLICK').on('trigger', (eventData) => {
        _dataStore.eam.doubleLeftClick(eventData)
    })

    IS.get('DRAG_OVER').on('trigger', (eventData) => {
        _dataStore.eam.dragOver(eventData)
    })
    IS.get('DRAG_END').on('trigger', (eventData) => {
        _dataStore.eam.dragEnd(eventData)
    })
    IS.get('DROP').on('trigger', (eventData) => {
        _dataStore.eam.drop(eventData)
    })

    let _raf = null
    const direction = new Set()
    const offset = new Vector2()
    const updateMoveDirection = (pos) => {
        direction.clear()
        if (pos.x < 0) {
            direction.add('left')
        }
        if (pos.y < 0) {
            direction.add('top')
        }
        if (pos.x > _viewport.width) {
            direction.add('right')
        }
        if (pos.y > _viewport.height) {
            direction.add('bottom')
        }
    }
    const moveViewport = () => {
        if (direction.size) {
            if (direction.has('left')) {
                offset.x = 8
            }
            if (direction.has('right')) {
                offset.x = -8
            }
            if (direction.has('top')) {
                offset.y = 8
            }
            if (direction.has('bottom')) {
                offset.y = -8
            }
            _viewport.offsetPos(offset)
            offset.x = 0
            offset.y = 0
        }
        _raf = requestAnimationFrame(moveViewport)
    }
    IS.get('LEFT_CLICK_MOVE')
        .on('start', (eventData, keyCombo) => {
            _raf = requestAnimationFrame(moveViewport)
            _dataStore.eam.leftClickMove(eventData, keyCombo.modifiers)
        })
        .on('update', (eventData, keyCombo) => {
            updateMoveDirection(eventData.mousePos)
            _dataStore.eam.updateAction(eventData, keyCombo.modifiers)
        })
        .on('end', () => {
            direction.clear()
            cancelAnimationFrame(_raf)
            _dataStore.eam.endAction()
        })

    IS.get('MIDDLE_CLICK_MOVE')
        .on('start', (eventData) => {
            _dataStore.eam.middleClickMove(eventData)
        })
        .on('update', (eventData) => {
            _dataStore.eam.updateAction(eventData)
        })
        .on('end', () => {
            _dataStore.eam.endAction()
            _dataStore.eam.spaceOff()
        })

    let framesOfClickMove = 0
    const clickMoveThld = 20
    let mousePos
    IS.get('RIGHT_CLICK_MOVE')
        .on('start', (eventData) => {
            mousePos = eventData.mousePos
            framesOfClickMove = 0
            _dataStore.eam.rightClickMove(eventData)
        })
        .on('update', (eventData) => {
            mousePos = eventData.mousePos
            framesOfClickMove++
            _dataStore.eam.updateAction(eventData)
        })
        .on('end', () => {
            if (framesOfClickMove < clickMoveThld) {
                const selectedElements = _dataStore.selection.get('elements')
                const worldPos = _visualServer.viewport.toWorld(mousePos)
                const hoveredElement = _dataStore.selection.get('hover')
                const hoverSelection = _visualServer.selection.bounds.has_point(worldPos)
                if (hoveredElement || hoverSelection) {
                    if (!selectedElements.includes(hoveredElement) && !hoverSelection) {
                        _dataStore.selection.clear()
                        _dataStore.selection.addElements([hoveredElement])
                    }
                    _dataStore.eam.fire(Events.OPEN_ELEMENT_CONTEXT_MENU, mousePos.clone())
                } else {
                    _dataStore.eam.fire(Events.OPEN_CANVAS_CONTEXT_MENU, mousePos.clone())
                }
            }
            _dataStore.eam.endAction()
            _dataStore.eam.spaceOff()
        })

    IS.get('SPACE')
        .on('start', (eventData) => {
            _dataStore.eam.spaceOn(eventData)
        })
        .on('update', (eventData) => {
            _dataStore.eam.spaceOn(eventData)
        })
        .on('end', () => {
            _dataStore.eam.spaceOff()
            _dataStore.eam.toggleAnimation()
            _dataStore.eam.endAction()
        })

    IS.get('MOVE_RIGHT').on('trigger', (eventData, keyCombo) => {
        _dataStore.eam.arrowKey(DirectionType.RIGHT, keyCombo.modifiers)
    })
    IS.get('MOVE_LEFT').on('trigger', (eventData, keyCombo) => {
        _dataStore.eam.arrowKey(DirectionType.LEFT, keyCombo.modifiers)
    })
    IS.get('MOVE_UP').on('trigger', (eventData, keyCombo) => {
        _dataStore.eam.arrowKey(DirectionType.UP, keyCombo.modifiers)
    })
    IS.get('MOVE_DOWN').on('trigger', (eventData, keyCombo) => {
        _dataStore.eam.arrowKey(DirectionType.DOWN, keyCombo.modifiers)
    })

    IS.get('SELECT_PREVIOUS_ELEMENT').on('trigger', () => {
        _dataStore.eam.selectElementSibling(DirectionType.UP)
    })
    IS.get('SELECT_NEXT_ELEMENT').on('trigger', () => {
        _dataStore.eam.selectElementSibling(DirectionType.DOWN)
    })

    IS.get('TEXT_MOVE_CARET_TO_LINE_START').on('trigger', () => {
        _dataStore.eam.moveTextToEdge(DirectionType.START)
    })
    IS.get('TEXT_MOVE_CARET_TO_TEXT_START').on('trigger', () => {
        _dataStore.eam.moveTextToEdge(DirectionType.START, { modifier: true })
    })
    IS.get('TEXT_EXPAND_SELECTION_TO_LINE_START').on('trigger', () => {
        _dataStore.eam.moveTextToEdge(DirectionType.START, { shift: true })
    })
    IS.get('TEXT_EXPAND_SELECTION_TO_TEXT_START').on('trigger', () => {
        _dataStore.eam.moveTextToEdge(DirectionType.START, { modifier: true, shift: true })
    })
    IS.get('TEXT_MOVE_CARET_TO_LINE_END').on('trigger', () => {
        _dataStore.eam.moveTextToEdge(DirectionType.END)
    })
    IS.get('TEXT_MOVE_CARET_TO_TEXT_END').on('trigger', () => {
        _dataStore.eam.moveTextToEdge(DirectionType.END, { modifier: true })
    })
    IS.get('TEXT_EXPAND_SELECTION_TO_LINE_END').on('trigger', () => {
        _dataStore.eam.moveTextToEdge(DirectionType.END, { shift: true })
    })
    IS.get('TEXT_EXPAND_SELECTION_TO_TEXT_END').on('trigger', () => {
        _dataStore.eam.moveTextToEdge(DirectionType.END, { modifier: true, shift: true })
    })

    IS.get('ENTER').on('trigger', (eventData) => {
        _dataStore.eam.enter(eventData)
    })
    IS.get('ENTER_SHIFT').on('trigger', (eventData) => {
        _dataStore.eam.enter(eventData, { shift: true })
    })
    IS.get('ESCAPE').on('trigger', (eventData) => {
        _dataStore.eam.escape(eventData)
    })

    IS.get('MOVE_VIEWPORT_WHEEL').on('trigger', (eventData, keyCombo) => {
        _dataStore.eam.wheelPanAndZoom(eventData, keyCombo.modifiers)
    })
    IS.get('ZOOM_TO_POINTER').on('trigger', (eventData) => {
        _dataStore.eam.wheelPanAndZoom(eventData, { modifier: true })
    })
    if (IS.platform === 'Mac') {
        IS.get('MAC_PINCH_ZOOM_TO_POINTER').on('trigger', (eventData) => {
            _dataStore.eam.wheelPanAndZoom(eventData, { ctrl: true })
        })
    }
}

function _onEAMEvents() {
    const zoomLevels = [0.02, 0.03, 0.06, 0.13, 0.25, 0.5, 1, 2, 4, 8, 16, 32, 64, 128, 256]
    _dataStore.eam.on(Events.ZOOM_IN, () => {
        const zoom = _viewport.getZoom()
        const index = zoomLevels.findIndex(z => zoom < z)
        if (zoomLevels[index]) {
            _viewport.setZoom(zoomLevels[index])
        }
    })
    _dataStore.eam.on(Events.ZOOM_OUT, () => {
        const zoom = _viewport.getZoom()
        const index = zoomLevels.findIndex(z => zoom <= z)
        const prevZoom = index === 0 ? zoomLevels[0] : zoomLevels[index - 1]
        _viewport.setZoom(prevZoom)
    })
    _dataStore.eam.on(Events.ZOOM_RESET, () => {
        _viewport.setZoom(1)
    })
    _dataStore.eam.on(Events.ZOOM_FIT_CONTENT, () => {
        focusContent()
    })
    _dataStore.eam.on(Events.ZOOM_FIT_SELECTION, () => {
        focusSelection()
    })
    _dataStore.eam.on(Events.ZOOM_CENTER_SELECTION, () => {
        centerSelection()
    })
    _dataStore.eam.on(Events.ZOOM_TO_VALUE, (zoom) => {
        _dataStore.workspace.watched.set('scale', zoom)
    })

    _dataStore.eam.on(Events.ALIGN, (direction) => {
        Alignment.align(direction)
    })

    _dataStore.eam.on(Events.DISTRIBUTE, (direction) => {
        Alignment.distribute(direction)
    })

    _dataStore.eam.on(Events.COPY, (contentType) => {
        _dataStore.clipboard.copy(contentType)
    })
    _dataStore.eam.on(Events.PASTE, (e) => {
        _dataStore.clipboard.paste(e)
    })
    _dataStore.eam.on(Events.CUT, (contentType) => {
        _dataStore.clipboard.cut(contentType)
    })
    _dataStore.eam.on(Events.DUPLICATE_ELEMENT, () => {
        _dataStore.clipboard.duplicate()
    })
    _dataStore.eam.on(Events.UNDO, () => {
        _dataStore.get('undo').undo()
    })
    _dataStore.eam.on(Events.REDO, () => {
        _dataStore.get('undo').redo()
    })

    // CURSOR CHANGES
    _dataStore.eam.on(Events.EXIT_EDITOR, () => {
        _localCursorHandler.setState(null)
    })
    _dataStore.eam.on(Events.ACTIVATE_SELECT_TOOL, () => {
        _localCursorHandler.setState('default')
    })
    _dataStore.eam.on(Events.ACTIVATE_SCALE_TOOL, () => {
        _localCursorHandler.setState('default')
    })
    _dataStore.eam.on(Events.ACTIVATE_HAND_TOOL, () => {
        _localCursorHandler.setState('grab')
    })
    _dataStore.eam.on(Events.ACTIVATE_RECTANGLE_TOOL, () => {
        _localCursorHandler.setState('crosshair')
    })
    _dataStore.eam.on(Events.ACTIVATE_ELLIPSE_TOOL, () => {
        _localCursorHandler.setState('crosshair')
    })
    _dataStore.eam.on(Events.ACTIVATE_CONTAINER_TOOL, () => {
        _localCursorHandler.setState('crosshair')
    })
    _dataStore.eam.on(Events.ACTIVATE_PEN_TOOL, () => {
        _localCursorHandler.setState('penDefault')
    })
    _dataStore.eam.on(Events.TOGGLE_EXPAND, () => {
        _dataStore.workspace.watched.toggleExpand()
    })
    _dataStore.eam.on(Events.ACTIVATE_COMMENT_TOOL, () => {
        _localCursorHandler.setState('comment')
    })

    let _cacheMode = _dataStore.get('mode')
    _dataStore.eam.on(Events.SWITCH_MODE, mode => {
        _cacheMode = mode
    })
    _dataStore.eam.on(Events.EXPORT_MEDIA, async (exportOptions) => {
        const { fileName, mediaType, quality, transparent, loop, speed, fps, start, end } = exportOptions

        _dataStore.set('mode', Mode.ACTION, NO_FIRE)

        // Enable export
        exportMedia.enable()

        // Pause current render loop
        IS.pause()

        // Cache TM settings
        _dataStore.transition.hold()
        _dataStore.transition.prepareActionTransition()

        // Wait for TM to finish drawing startTime animation view
        await waitFrame()

        const startTime = (start === undefined ? 0 : start) * 1000
        const endTime = (end === undefined ? 0 : end) * 1000
        const aniFps = fps === undefined ? DEFAULT_FPS : fps
        const aniSpeed = speed === undefined ? DEFAULT_SPEED : speed
        const iterTime = (1000 / aniFps) * aniSpeed

        exportMedia.setOutputSize()
        exportMedia.updateRecordSetting({
            startTime,
            endTime,
            iterTime,
            speed: aniSpeed,
            fps,
            quality,
            transparent,
            loop
        })

        // Set type after updating settings because we might need specific settings for specific types
        await exportMedia.setMediaType(mediaType)


        const onRecordProgress = (progress) => {
            _dataStore.eam.exportProgress(roundToDecimalPlaces(progress, 1))
        }

        const onRecordComplete = () => {
            if (exportMedia.exportFile) {
                _dataStore.eam.exportFinish(exportMedia.exportFile, { fileName, type: mediaType })
            }
            exportMedia.clear()
        }

        await exportMedia.recordCanvas(onRecordProgress, onRecordComplete)

        _dataStore.set('mode', _cacheMode, NO_FIRE)
    })

    _dataStore.eam.on(Events.CANCEL_EXPORT_MEDIA, () => {
        _dataStore.set('mode', _cacheMode, NO_FIRE)
        exportMedia.disable()
        exportMedia.clear()
    })
}

/**
 * @todo The resizing functions are insanely muddy
 */
function _onResizeEvents() {
    _dataStore.eam
        .on(Events.START_RESIZE_ELEMENT, (e, _, { snapToGrid, snapToObject }) => { _elementHandlers.resizeStart(e, snapToGrid, snapToObject) })
        .on(Events.UPDATE_RESIZE_ELEMENT, (e, keys, { snapToGrid, snapToObject }) => { _elementHandlers.resizeUpdate(e, keys, snapToGrid, snapToObject) })
        .on(Events.END_RESIZE_ELEMENT, _elementHandlers.resizeEnd)

    _dataStore.eam
        .on(Events.START_SCALE_ELEMENT, (e, _, { snapToGrid, snapToObject }) => { _elementHandlers.resizeStart(e, snapToGrid, snapToObject) })
        .on(Events.UPDATE_SCALE_ELEMENT, (e, keys, { snapToGrid, snapToObject }) => { _elementHandlers.resizeUpdate(e, keys, snapToGrid, snapToObject) })
        .on(Events.END_SCALE_ELEMENT, _elementHandlers.resizeEnd)

    _dataStore.eam
        .on(Events.START_ROTATE_ELEMENT, (e, shift) => {
            _elementHandlers.rotateStart(e, shift)
        })
        .on(Events.UPDATE_ROTATE_ELEMENT, (e, shift) => {
            _elementHandlers.rotateUpdate(_viewport.toWorld(e.mousePos), shift)
        })
        .on(Events.END_ROTATE_ELEMENT, _elementHandlers.rotateEnd)

    // TODO: might be useful later, keep it for now
    _dataStore.eam
        .on(Events.START_ZOOM_TO_SELECTION, _selectionArea.start)
        .on(Events.UPDATE_ZOOM_TO_SELECTION, (e, keys) => {
            _selectionArea.update(e, keys, true)
        })
        .on(Events.END_ZOOM_TO_SELECTION, _selectionArea.end)

    let allSelection = new Set()
    const addSelection = new Set()
    const removeSelection = new Set()
    _dataStore.eam
        .on(Events.START_AREA_SELECT_ELEMENT, (e) => {
            allSelection = new Set(_dataStore.selection.get('elements'))
            _selectionArea.start(e)
        })
        .on(Events.UPDATE_AREA_SELECT_ELEMENT, (e, keys) => {
            const elements = _selectionArea.update(e, keys, false)

            // Restore original selection and clear cache
            addSelection.forEach((el) => allSelection.delete(el))
            addSelection.clear()
            removeSelection.forEach((el) => allSelection.add(el))
            removeSelection.clear()

            // Check if need to update selection and add to cache, so we can restore it instead of create a new instance.
            elements.forEach((el) => {
                if (allSelection.has(el)) {
                    removeSelection.add(el)
                    allSelection.delete(el)
                } else {
                    addSelection.add(el)
                    allSelection.add(el)
                }
            })
            _selectionArea.selectNodesInSelectionArea(allSelection)

            if (allSelection.size) {
                _dataStore.eam.stopAnimation()
            }
        })
        .on(Events.END_AREA_SELECT_ELEMENT, () => {
            _selectionArea.end()
            allSelection.clear()
            addSelection.clear()
            removeSelection.clear()
        })

    const currentSelectedVertices = new Set()
    _dataStore.eam
        .on(Events.START_AREA_SELECT_CELL, (e) => {
            const vertices = _dataStore.selection.get('vertices')
            vertices.forEach(el => currentSelectedVertices.add(el))
            _selectionArea.start(e)
        })
        .on(Events.UPDATE_AREA_SELECT_CELL, (e, keys) => {
            const vertices = _selectionArea.update(e, keys, false)
            currentSelectedVertices.forEach(el => {
                if (vertices.has(el)) {
                    vertices.delete(el)
                } else {
                    vertices.add(el)
                }
            })
            _selectionArea.selectVerticesInSelectionArea(vertices)
        })
        .on(Events.END_AREA_SELECT_CELL, () => {
            _selectionArea.end()
            currentSelectedVertices.clear()
        })
}

function _bindViewportActionHandlers() {
    _dataStore.eam.on(Events.ACTIVATE_PAN, () => {
        _localCursorHandler.setState('grab')
    })
    _dataStore.eam.on(Events.DEACTIVATE_PAN, () => {
        _localCursorHandler.setState('default')
    })
    _dataStore.eam.on(Events.COMMENT_DEACTIVATE_PAN, () => {
        _localCursorHandler.setState('comment')
    })

    {
        // TODO: figure out why Space + LeftMouseClick doesn't have
        //  `start` but has `update` and `end`. Then remove the hack bellow
        let _hasLastMousePos = false

        const _lastMousePos = new Vector2()

        const _startMoveViewport = ({ mousePos }) => {
            _hasLastMousePos = true
            _lastMousePos.copy(mousePos)
            _localCursorHandler.setState('grabbing')
        }

        _dataStore.eam
            .on(Events.START_PANNING, _startMoveViewport)
            .on(Events.UPDATE_PANNING, (e) => {
                const { mousePos } = e
                // TODO: figure out why Space + LeftMouseClick doesn't have
                //  `start` but has `update` and `end`. Remove this hack afterwards
                if (!_hasLastMousePos) {
                    _startMoveViewport(e)
                }
                const d = mousePos.clone().sub(_lastMousePos)
                _viewport.offsetPos(d)
                _lastMousePos.copy(mousePos)
            })
            .on(Events.END_PANNING, () => {
                _hasLastMousePos = false
            })
    }
    _dataStore.eam.on(Events.MOVE_VIEWPORT_KEY, (v) => {
        _viewport.offsetPos(v)
    })

    _dataStore.eam.on(Events.MOVE_VIEWPORT_WHEEL, (e) => {
        const o = e.wheelDelta.clone().negate()
        _viewport.offsetPos(o)
    })

    {
        const deltaToMult = (/** @type {number} */ delta) => 1 + Math.abs(delta) / 1000
        _dataStore.eam.on('ZOOM_TO_POINTER', (e) => {
            if (e.wheelDelta.y < 0) {
                _viewport.zoomToPos(e.mousePos, deltaToMult(e.wheelDelta.y))
            } else {
                _viewport.zoomToPos(e.mousePos, 1 / deltaToMult(e.wheelDelta.y))
            }
        })
    }

    if (IS.platform === 'Mac') {
        /** @type {"linear" | "quadratic" | "cubic"} */
        const easing = "quadratic"
        const deltaToMult = (/** @type {number} */ delta) => {
            switch (easing) {
                case "cubic": {
                    const mult = 1 + Math.abs(delta) / 150
                    return mult ** 3
                }
                case "quadratic": {
                    const mult = 1 + Math.abs(delta) / 100
                    return mult ** 2
                }
                default: {
                    const mult = 1 + Math.abs(delta) / 75
                    return mult
                }
            }
        }
        _dataStore.eam.on('MAC_ZOOM_TO_POINTER', (e) => {
            if (e.wheelDelta.y < 0) {
                _viewport.zoomToPos(e.mousePos, deltaToMult(e.wheelDelta.y))
            } else {
                _viewport.zoomToPos(e.mousePos, 1 / deltaToMult(e.wheelDelta.y))
            }
        })
    }
}

function _bindGeneralShortcuts() {
    IS.get('ZOOM_IN').on('trigger', (eventData) => {
        _dataStore.eam.zoomIn(eventData)
    })
    IS.get('ZOOM_OUT').on('trigger', (eventData) => {
        _dataStore.eam.zoomOut(eventData)
    })
    IS.get('ZOOM_RESET').on('trigger', () => {
        _dataStore.eam.zoomReset()
    })
    IS.get('ZOOM_FIT_CONTENT').on('trigger', () => {
        _dataStore.eam.zoomFitContent()
    })
    IS.get('ZOOM_FIT_SELECTION').on('trigger', () => {
        _dataStore.eam.zoomFitSelection()
    })
    IS.get('ZOOM_CENTER_SELECTION').on('trigger', () => {
        _dataStore.eam.zoomCenterSelection()
    })

    IS.get('ACTIVATE_SELECT_TOOL').on('trigger', () => {
        _dataStore.eam.activateSelectTool()
    })
    IS.get('ACTIVATE_SCALE_TOOL').on('trigger', () => {
        _dataStore.eam.activateScaleTool()
    })
    IS.get('ACTIVATE_HAND_TOOL').on('trigger', (eventData) => {
        _dataStore.eam.activateHandTool(eventData)
    })
    IS.get('ACTIVATE_RECTANGLE_TOOL').on('trigger', () => {
        _dataStore.eam.activateRectangleTool()
    })
    IS.get('ACTIVATE_CONTAINER_TOOL').on('trigger', () => {
        _dataStore.eam.activateContainerTool()
    })
    IS.get('ACTIVATE_ELLIPSE_TOOL').on('trigger', () => {
        _dataStore.eam.activateEllipseTool()
    })
    IS.get('ACTIVATE_PEN_TOOL').on('trigger', () => {
        _dataStore.eam.activatePenTool()
    })
    IS.get('ACTIVATE_COMMENT_TOOL').on('trigger', () => {
        _dataStore.eam.activateCommentTool()
    })
    IS.get('TOGGLE_EYE_DROPPER_TOOL').on('trigger', () => {
        _dataStore.eam.toggleEyeDropperTool()
    })

    IS.get('GROUP_ELEMENTS').on('trigger', (_, keyData) => {
        _dataStore.eam.groupElements(keyData.modifiers)
    })
    IS.get('UNGROUP_ELEMENT').on('trigger', () => {
        _dataStore.eam.ungroupElements()
    })

    IS.get('INCREASE_CORNER_RADIUS').on('trigger', () => {
        _dataStore.eam.increaseCornerRadius()
    })
    IS.get('DECREASE_CORNER_RADIUS').on('trigger', () => {
        _dataStore.eam.decreaseCornerRadius()
    })

    IS.get('SELECT_ALL').on('trigger', () => {
        _dataStore.eam.selectAll()
    })

    IS.get('COPY').on('trigger', () => {
        _dataStore.eam.copy()
    })
    IS.get('PASTE').on('trigger', (e) => {
        _dataStore.eam.paste(e)
    })
    IS.get('CUT').on('trigger', () => {
        _dataStore.eam.cut()
    })
    IS.get('DUPLICATE').on('trigger', () => {
        _dataStore.eam.duplicate()
    })

    IS.get('UNDO').on('trigger', () => {
        _dataStore.eam.undo()
    })
    IS.get('REDO').on('trigger', () => {
        _dataStore.eam.redo()
    })

    IS.get('TOGGLE_EXPAND').on('trigger', () => {
        _dataStore.eam.toggleExpand()
    })

    IS.get('ALIGN_LEFT').on('trigger', () => {
        _dataStore.eam.align(AlignType.LEFT)
    })
    IS.get('ALIGN_CENTER').on('trigger', () => {
        _dataStore.eam.align(AlignType.CENTER)
    })
    IS.get('ALIGN_RIGHT').on('trigger', () => {
        _dataStore.eam.align(AlignType.RIGHT)
    })
    IS.get('ALIGN_TOP').on('trigger', () => {
        _dataStore.eam.align(AlignType.TOP)
    })
    IS.get('ALIGN_MIDDLE').on('trigger', () => {
        _dataStore.eam.align(AlignType.MIDDLE)
    })
    IS.get('ALIGN_BOTTOM').on('trigger', () => {
        _dataStore.eam.align(AlignType.BOTTOM)
    })
    IS.get('DISTRIBUTE_HORIZONTAL').on('trigger', () => {
        _dataStore.eam.distribute(DistributeType.HORIZONTAL)
    })
    IS.get('DISTRIBUTE_VERTICAL').on('trigger', () => {
        _dataStore.eam.distribute(DistributeType.VERTICAL)
    })
    IS.get('TOGGLE_ANIMATE_MODE').on('trigger', () => {
        const targetMode = _dataStore.isActionMode ? Mode.DESIGN : Mode.ACTION
        _dataStore.eam.switchMode(targetMode)
    })
    IS.get('TOGGLE_ORIGIN').on('trigger', () => {
        _dataStore.eam.toggleOrigin()
    })
    IS.get('TOGGLE_RULER').on('trigger', () => {
        _dataStore.eam.toggleRuler()
    })
    IS.get('TOGGLE_INTERFACE').on('trigger', () => {
        _dataStore.eam.toggleInterface()
    })
    IS.get('TOGGLE_COMMENT_VISIBILITY').on('trigger', () => {
        _dataStore.eam.toggleCommentVisibility()
    })
    IS.get('TOGGLE_VISIBLE').on('trigger', () => {
        _dataStore.eam.toggleVisible()
    })
    IS.get('TOGGLE_LOCK').on('trigger', () => {
        _dataStore.eam.toggleLock()
    })
    IS.get('BRING_TO_FRONT').on('trigger', () => {
        _dataStore.eam.bringToFront()
    })
    IS.get('SEND_TO_BACK').on('trigger', () => {
        _dataStore.eam.sendToBack()
    })
    IS.get('MOVE_FORWARD').on('trigger', () => {
        _dataStore.eam.moveForward()
    })
    IS.get('MOVE_BACKWARD').on('trigger', () => {
        _dataStore.eam.moveBackward()
    })
    IS.get('MASK_SELECTION').on('trigger', () => {
        _dataStore.eam.maskGroupElements(true)
    })
    IS.get('TOGGLE_PRESENCE').on('trigger', () => {
        _dataStore.eam.togglePresencePreference()
    })
    IS.get('EDIT_ORIGIN').on('trigger', () => {
        const originState = _dataStore.getFeature('editOrigin')
        _dataStore.setFeature('editOrigin', !originState)
    })
    IS.get('TOGGLE_INSPECTING').on('trigger', () => {
        _dataStore.eam.toggleInspecting()
    })
}


/**
 * Executes the update event about the actions once per frame.
 */
export function update() {
    switch (_dataStore.get('editMode')) {
        case EditMode.SHAPE:
            _visualServer.snappingPath.update()
            break
        default:
            break
    }
}
