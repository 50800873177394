import { useCallback } from 'react'

import { schema } from 'normalizr'

import { LayerListKeyList, MAX_INTERACTION_TIME } from '@phase-software/data-store'
import { InteractionEntityType } from '@phase-software/types'

import { EFFECT_PROPS_NAME_MAP } from '../../constant'
import { mergeSegmentList } from '../../utils/interaction'
import { useSetNotification } from '../NotificationProvider'
import { createProvider } from '../utils'
import { useDataStore, useDataStoreActions } from './DataStoreProvider'

const rootPropList = [
  'motionPath',
  'dimensions',
  'pathMorphing',
  'rotation',
  'cornerRadius',
  'contentAnchor',
  'scale',
  'skew',
  'blendMode',
  'opacity',
  ...LayerListKeyList,
  'blurs',
  'effects'
]

const layerKeySet = new Set(LayerListKeyList)

export const Interaction = new schema.Entity('interaction', {})

const defaultValue = {
  entityMap: new Map()
}
const [Provider, useSelectState, useSetState, getSnapshot] = createProvider('Interaction', defaultValue)

export const getInteractionSnapshot = getSnapshot

export const useInteraction = (key) => {
  let fn
  if (key === 'actionList') {
    fn = (o) => o.actionList
  } else if (key) {
    fn = (o) => o.entityMap.get(key)
  }
  return useSelectState(fn)
}

export const useSetInteraction = () => {
  const setState = useSetState()
  const setInteraction = useCallback((interaction) => setState(interaction), [setState])
  const updateEntities = useCallback(
    (changes, deleteList) => {
      setState((s) => {
        const parentTrackList = []
        const elementTrackList = []
        Object.entries(changes).forEach(([entityId, change]) => {
          const entity = s.entityMap.get(entityId)
          if (change.segmentList) {
            const parentId = change?.parentId || entity?.parentId
            if (parentId) {
              parentTrackList.push(parentId)
            }
            const elementTrackId = change?.elementTrackId || entity?.elementTrackId
            if (!elementTrackList.includes(elementTrackId)) {
              elementTrackList.push(elementTrackId)
            }
          }
          if (change.type === InteractionEntityType.ACTION) {
            s.actionList.push(entityId)
          }
          s.entityMap.set(entityId, { ...entity, ...change })
        })

        // TODO: bottom-up aggregate segments
        // update parent track segments
        while (parentTrackList.length) {
          const trackId = parentTrackList.shift()
          const track = s.entityMap.get(trackId)
          const elementTrack = s.entityMap.get(track.elementTrackId)

          let segmentList = []
          track.children.forEach((key) => {
            const childTrack = s.entityMap.get(elementTrack.propertyTrackMap.get(key))
            if (childTrack) {
              segmentList = segmentList.concat(childTrack.segmentList)
            }
          })
          segmentList = mergeSegmentList(segmentList)
          s.entityMap.set(trackId, { ...track, segmentList })

          if (track.parentId && !parentTrackList.includes(track.parentId)) {
            parentTrackList.push(track.parentId)
          }
        }

        // TODO: bottom-up aggregate segments
        // update element track segments
        elementTrackList.forEach((elementTrackId) => {
          const track = s.entityMap.get(elementTrackId)

          let segmentList = []
          track.propertyTrackMap.forEach((trackId) => {
            const track = s.entityMap.get(trackId)
            if (track) {
              segmentList = segmentList.concat(track.segmentList)
            }
          })
          segmentList = mergeSegmentList(segmentList)
          s.entityMap.set(elementTrackId, { ...track, segmentList })
        })

        deleteList.forEach((entityId) => {
          s.entityMap.delete(entityId)
        })

        return {
          ...s
        }
      }, {})
    },
    [setState]
  )

  const setTrackFolded = useCallback(
    (trackId, expanded) => {
      setState((s) => {
        const track = s.entityMap.get(trackId)
        s.entityMap.set(track.id, { ...track, expanded })
        return {
          ...s
        }
      })
    },
    [setState]
  )

  const setAllTrackUnfolded = useCallback(() => {
    setState((s) => {
      const newEntityMap = new Map(s.entityMap)
      newEntityMap.forEach((track, trackId) => {
        if (!track.expanded && (track?.children?.size > 0 || track?.propertyTrackMap?.size > 0)) {
          newEntityMap.set(trackId, { ...track, expanded: true })
        }
      })
      return { ...s, entityMap: newEntityMap }
    })
  }, [setState])

  const updateTrackSelected = useCallback(
    (kfList) => {
      setState((s) => {
        const selectedKfs = kfList
          .map((kfId) => s.entityMap.get(kfId))
          // might already deleted in IM@deleteElementPropertyTrack by switchLayerPaintType
          .filter((kf) => kf && s.entityMap.has(kf.trackId))

        const selectedTrackSet = new Set()
        selectedKfs.map((kf) => {
          let propTrack = s.entityMap.get(kf.trackId)
          selectedTrackSet.add(propTrack.elementTrackId)
          selectedTrackSet.add(propTrack.id)
          while (propTrack.parentId) {
            propTrack = s.entityMap.get(propTrack.parentId)
            selectedTrackSet.add(propTrack.id)
          }
        })
        return {
          ...s,
          selectedTrackList: Array.from(selectedTrackSet)
        }
      })
    },
    [setState]
  )

  return { setInteraction, setTrackFolded, setAllTrackUnfolded, updateEntities, updateTrackSelected }
}

export const useInteractionActions = () => {
  const dataStore = useDataStore()
  const { addNotification } = useSetNotification()
  const { setTrackFolded } = useSetInteraction()
  const {
    selectKeyframeSelection, getKeyframeSelection, 
    selectActionSelection, getActionSelection, 
    selectActiveTableElementSelection, getActiveTableElementSelection
  } = useDataStoreActions()

  const setActionSelection = useCallback(
    (actionId) => {
      selectActionSelection(actionId)
    },
    [selectActionSelection]
  )

  const getSelectedAction = useCallback(() => {
    return getActionSelection()
  }, [getActionSelection]) 

  const setActiveTableElementSelection = useCallback(
    (elementId) => {
      selectActiveTableElementSelection(elementId)
    },
    [selectActiveTableElementSelection]
  )

  const getSelectedActiveTableElement = useCallback(() => {
    return getActiveTableElementSelection()
  }, [getActiveTableElementSelection])

  const getKeyFrame = useCallback(
    (keyFrameId) => {
      return dataStore.interaction.getKeyFrame(keyFrameId)
    },
    [dataStore]
  )

  const getKeyframeIdList = useCallback(
    (trackId, time) => {
      return dataStore.interaction.getKeyframeIdList(trackId, time)
    },
    [dataStore]
  )

  const getElementIdByPropertyTrack = useCallback(
    (propertyTrackId) => {
      const propertyTrack = dataStore.interaction.getPropertyTrack(propertyTrackId)
      const elementTrack = dataStore.interaction.getElementTrack(propertyTrack.elementTrackId)
      return elementTrack.elementId
    },
    [dataStore]
  )

  const getTrackKeyFramePaintType = useCallback(
    (propertyTrackId) => {
      const keyFrameList = dataStore.interaction.getKeyFrameList(propertyTrackId)
      const firstKeyFrameId = keyFrameList[0]
      const keyFrame = dataStore.interaction.getKeyFrame(firstKeyFrameId)
      const paint = dataStore.library.getComponent(keyFrame.ref)
      return paint.paintType
      // return paint
    },
    [dataStore]
  )

  const getTrackKeyFramePaint = useCallback(
    (propertyTrackId) => {
      const keyFrameList = dataStore.interaction.getKeyFrameList(propertyTrackId)
      const firstKeyFrameId = keyFrameList[0]
      const keyFrame = dataStore.interaction.getKeyFrame(firstKeyFrameId)
      const paint = dataStore.library.getComponent(keyFrame.ref)
      return paint
    },
    [dataStore]
  )

  const getKeyFrameListAtTime = useCallback(
    (trackId, time) => {
      const { interaction } = dataStore
      let track = interaction.getEntity(trackId)
      const kfList = []
      if (!track) {
        return kfList
      }
      if (track.type === InteractionEntityType.ELEMENT_TRACK) {
        const propTrackMap = track.propertyTrackMap
        propTrackMap.forEach((trackId) => {
          kfList.push(interaction.getKeyFrameByTime(trackId, time))
        })
        return kfList.filter(Boolean).map((kf) => kf.id)
      }
      if (track.type === InteractionEntityType.PROPERTY_TRACK) {
        const propTrackMap = interaction.getPropertyTrackMap(track.elementTrackId)
        kfList.push(interaction.getKeyFrameByTime(trackId, time))
        const queue = Array.from(track.children)
        while (queue.length) {
          const trackKey = queue.shift()
          track = interaction.getEntity(propTrackMap.get(trackKey))
          queue.push(...Array.from(track.children))
          kfList.push(interaction.getKeyFrameByTime(track.id, time))
        }
        return kfList.filter(Boolean).map((kf) => kf.id)
      }
    },
    [dataStore]
  )

  const getKeyFrameList = useCallback(
    (trackId, aggregate = false) => {
      const { interaction } = dataStore
      let track = interaction.getEntity(trackId)
      const kfList = []
      if (!track) {
        return kfList
      }
      if (track.type === InteractionEntityType.ELEMENT_TRACK) {
        if (aggregate) {
          const propTrackMap = track.propertyTrackMap
          propTrackMap.forEach((trackId) => {
            kfList.push(...interaction.getKeyFrameList(trackId))
          })
        }
        return kfList
      }
      if (track.type === InteractionEntityType.PROPERTY_TRACK) {
        const propTrackMap = interaction.getPropertyTrackMap(track.elementTrackId)
        kfList.push(...interaction.getKeyFrameList(trackId))
        if (aggregate) {
          const queue = Array.from(track.children)
          while (queue.length) {
            const trackKey = queue.shift()
            track = interaction.getEntity(propTrackMap.get(trackKey))
            queue.push(...Array.from(track.children))
            kfList.push(...interaction.getKeyFrameList(track.id))
          }
        }
        return kfList
      }
      return []
    },
    [dataStore]
  )

  const getSelectedKeyFrameList = useCallback(() => {
    return dataStore.selection.get('kfs')
  }, [dataStore])

  const toggleTrackExpanded = useCallback(
    (trackId, expanded) => {
      const trackKfs = getKeyFrameList(trackId, true)
      const kfs = dataStore.selection.get('kfs')
      const selectedTrackKfs = kfs
        .filter((kfId) => trackKfs.includes(kfId))
        .map((kfId) => dataStore.interaction.getKeyFrame(kfId))
      if (expanded) {
        const kfsGroupByTime = trackKfs.reduce((acc, kfId) => {
          const kf = dataStore.interaction.getKeyFrame(kfId)
          if (acc[kf.time]) {
            acc[kf.time].push(kfId)
          } else {
            acc[kf.time] = [kfId]
          }
          return acc
        }, {})
        const deselectList = []
        selectedTrackKfs.forEach((kf) => {
          const kfList = kfsGroupByTime[kf.time]
          const shouldDeselect = kfList.some((kfId) => !selectedTrackKfs.find((kf) => kf.id === kfId))
          if (shouldDeselect) {
            deselectList.push(kf.id)
          }
        })
        if (deselectList.length) {
          dataStore.selection.removeKFs(deselectList)
        }
      } else {
        // If at least one Child Keyframe isn't selected in this time value,
        // collapse a Parent Track will remove all Child Keyframe(s) from Keyframe Selection
        if (trackKfs.length !== selectedTrackKfs.length) {
          dataStore.selection.removeKFs(selectedTrackKfs.map((kf) => kf.id))
        }
      }
      setTrackFolded(trackId, expanded)
    },
    [dataStore, setTrackFolded, getKeyFrameList]
  )

  const setKeyFrameFrameType = useCallback(
    (id, frameType) => {
      dataStore.interaction.setKeyFrameFrameType(id, frameType)
    },
    [dataStore]
  )

  const duplicateSelectedKeyFrame = useCallback(() => {
    const playheadTime = dataStore.transition.currentTime
    const kfs = dataStore.selection.get('kfs')
    const isOverflow = kfs
      .map((kfId) => dataStore.interaction.getKeyFrame(kfId))
      .map((kf) => kf.time)
      .sort()
      .some((t, i, arr) => {
        return t + (playheadTime - arr[0]) > MAX_INTERACTION_TIME
      })
    if (isOverflow) {
      addNotification({
        type: 'info',
        content: 'Keyframes that over duration max limit won’t be duplicated'
      })
    }
    const newKfIds = dataStore.interaction.duplicateSelectedKeyFrame()
    return newKfIds
  }, [dataStore, addNotification])

  const deleteSelectedKeyFrame = useCallback(() => {
    dataStore.interaction.deleteSelectedKeyFrame()
    dataStore.commitUndo()
  }, [dataStore])

  const setActionMaxTime = useCallback(
    (id, time) => {
      dataStore.interaction.setActionMaxTime(id, time)
      dataStore.commitUndo()
    },
    [dataStore]
  )

  const setActionSpeed = useCallback(
    (id, speed) => {
      dataStore.interaction.setActionSpeed(id, speed)
    },
    [dataStore]
  )

  const setActionLooping = useCallback(
    (id, looping) => {
      dataStore.interaction.setActionLooping(id, looping)
    },
    [dataStore]
  )

  const setKeyframeSelectionTimeByOffset = useCallback(
    (offset, replace) => {
      dataStore.interaction.setSelectedKeyFrameTimeByOffset(offset, replace)
    },
    [dataStore]
  )

  const setSelectedKeyFrameEasingType = useCallback(
    (easingType) => {
      dataStore.interaction.setSelectedKeyFrameEasingType(easingType)
      dataStore.commitUndo()
    },
    [dataStore]
  )

  const setSelectedKeyFrameBezier = useCallback(
    (bezier, commit) => {
      const result = dataStore.interaction.setSelectedKeyFrameBezier(bezier)
      if (commit) {
        dataStore.commitUndo()
      }
      return result
    },
    [dataStore]
  )

  const getIsSelectedKeyframesOverlapped = useCallback(() => {
    return dataStore.interaction.getIsSelectedKeyframesOverlapped()
  }, [dataStore])

  const getKeyFrameListGroupByTime = useCallback(
    (trackId) => {
      const track = dataStore.interaction.getEntity(trackId)
      if (!track) {
        return {}
      }
      if (track.type === InteractionEntityType.ELEMENT_TRACK) {
        return dataStore.interaction.getElementTrackKeyFrameGroupByTime(trackId)
      }
      if (track.type === InteractionEntityType.PROPERTY_TRACK) {
        return dataStore.interaction.getPropertyTrackKeyFrameGroupByTime(trackId)
      }
    },
    [dataStore]
  )

  const getKeyFrameByTime = useCallback(
    (trackId, time) => {
      const keyFrameByTime = getKeyFrameListGroupByTime(trackId)
      return keyFrameByTime[time] || []
    },
    [getKeyFrameListGroupByTime]
  )

  const getEffectType = useCallback(
    (effectId) => {
      const effect = dataStore.library.getComponent(effectId)
      return effect.effectType
    },
    [dataStore]
  )

  const groupKeyframeByTime = useCallback(
    (keyframeList) => dataStore.interaction.groupKeyframeByTime(keyframeList),
    [dataStore]
  )

  const getAggregateKeyFrame = useCallback(
    (idList) => {
      return idList
        .map((id) => dataStore.interaction.getKeyFrame(id))
        .filter(Boolean)
        .reduce((acc, kf) => {
          Object.entries(kf).forEach(([key, value]) => {
            if (acc[key] === undefined) {
              acc[key] = value
            }
            if (acc[key] !== value) {
              acc[key] = 'Mix'
            }
          })
          return acc
        }, {})
    },
    [dataStore]
  )

  const getFlattenTrackList = useCallback(
    (data, root, selectedSet) => {
      return root
        .map((elTrackId) => {
          const itemMap = new Map()
          const elTrack = data.get(elTrackId)
          if (!elTrack) {
            return undefined
          }

          const selected = selectedSet.has(elTrack.id)
          const elTrackItem = {
            id: elTrackId,
            propKey: null,
            parentId: null,
            level: 0,
            children: [],
            selected,
            type: 'group',
            expanded: elTrack.expanded,
            elementId: elTrack.elementId,
            parentKey: null
          }
          itemMap.set(elTrackId, elTrackItem)
          const elementTrack = data.get(elTrackId)

          const queue = []
          rootPropList.forEach((propKey) => {
            if (propKey === 'effects') {
              // FIXME: hide details
              const element = dataStore.getById(elTrack.elementId)
              Array.from(element.computedStyle.effects).forEach((ce) => {
                const propTrackId = elementTrack.propertyTrackMap.get(EFFECT_PROPS_NAME_MAP[ce.get('effectType')])
                elTrackItem.children.push(propTrackId)
                if (elementTrack.expanded) {
                  queue.push([propTrackId, elTrackId, 1])
                }
              })
            } else {
              const propTrackId = elementTrack.propertyTrackMap.get(propKey)
              if (propTrackId) {
                const keyFrameByTime = getKeyFrameListGroupByTime(propTrackId)
                if (Object.keys(keyFrameByTime).length) {
                  elTrackItem.children.push(propTrackId)
                  if (elementTrack.expanded) {
                    queue.push([propTrackId, elTrackId, 1])
                  }
                }
              }
            }
          })

          while (queue.length) {
            const [trackId, parentId, level, index] = queue.shift()
            const parent = data.get(parentId)
            const track = data.get(trackId)
            if (track) {
              const children = dataStore.interaction.getChildList(trackId)
              if (track.expanded) {
                if (layerKeySet.has(track.key)) {
                  children.reverse()
                }
                children
                  .slice()
                  .reverse()
                  .forEach((id, index) => {
                    const keyFrameByTime = getKeyFrameListGroupByTime(id)
                    if (id && Object.keys(keyFrameByTime).length) {
                      queue.unshift([id, trackId, level + 1, index])
                    }
                  })
              }
              const type = children.length ? 'group' : 'item'
              const selected = selectedSet.has(track.id) && !track.expanded
              itemMap.set(trackId, {
                id: trackId,
                type,
                parentId,
                propKey: track.key,
                level,
                children,
                selected,
                expanded: track.expanded,
                parentKey: parent.key,
                index
              })
            }
          }
          return [...itemMap.values()]
        })
        .filter(Boolean)
        .flat()
    },
    [dataStore, getKeyFrameListGroupByTime]
  )

  const updateKeyFrameSelectionByTrack = useCallback(
    (trackId, set, operation) => {
      const keyFrameList = getKeyFrameList(trackId, true)
      keyFrameList.forEach((kfId) => operation(set, kfId))
    },
    [getKeyFrameList]
  )

  const selectKeyframesByTrack = useCallback(
    (trackId, set) => {
      updateKeyFrameSelectionByTrack(trackId, set, (set, kfId) => set.add(kfId))
    },
    [updateKeyFrameSelectionByTrack]
  )

  const deselectKeyframesByTrack = useCallback(
    (trackId, set) => {
      updateKeyFrameSelectionByTrack(trackId, set, (set, kfId) => set.delete(kfId))
    },
    [updateKeyFrameSelectionByTrack]
  )

  const getAllKeyframeIdList = useCallback(
    (trackId) => {
      return dataStore.interaction.getChildList(trackId).flatMap((track) => getKeyFrameList(track, true))
    },
    [dataStore, getKeyFrameList]
  )

  const toggleKeyFrameSelectionByTrack = useCallback(
    (trackList, trackId, toggleSelect = false) => {
      if (toggleSelect) {
        const parentKfList = getKeyFrameList(trackId, true)
        const kfList = getKeyFrameList(trackId)
        const allkfselection = getKeyframeSelection()
        const allKfSelectedByToggleTrack = kfList.every((kfid) => allkfselection.includes(kfid))
        const allParentKfListKfSelected = parentKfList.every((kfid) => allkfselection.includes(kfid))
        const isParentTrack = parentKfList.length > kfList.length // aggregate track
        let allKfSelectSet = new Set(allkfselection)

        if (!allKfSelectedByToggleTrack || !allParentKfListKfSelected) {
          // if none or partial selected, then select all
          if (isParentTrack) {
            allKfSelectSet = new Set(getAllKeyframeIdList(trackId))
          } else {
            selectKeyframesByTrack(trackId, allKfSelectSet)
          }
        } else {
          // if all selected, then deselect all
          deselectKeyframesByTrack(trackId, allKfSelectSet)
        }
        selectKeyframeSelection(Array.from(allKfSelectSet))
      } else {
        const selectKeyframeSet = trackList.reduce((set, trackId) => {
          getKeyFrameList(trackId, true).forEach((kfId) => set.add(kfId))
          return set
        }, new Set())
        selectKeyframeSelection(Array.from(selectKeyframeSet))
      }
    },
    [
      selectKeyframeSelection,
      getKeyFrameList,
      deselectKeyframesByTrack,
      getAllKeyframeIdList,
      selectKeyframesByTrack,
      getKeyframeSelection
    ]
  )

  const getPropertyKeyFrameGroupByTime = useCallback(
    (elementId, propKey) => {
      const list = dataStore.interaction.getPropertyTrackByElementIdAndPropKey(elementId, propKey)
      if (!list) return {}
      return dataStore.interaction.getPropertyTrackKeyFrameGroupByTime(list.id)
    },
    [dataStore]
  )

  const getAnimatiedElementMap = useCallback(() => {
    return dataStore.interaction.getAnimatiedElementMap()
  }, [dataStore])

  const getSelectedKeyframesTimeRange = useCallback(() => {
    return dataStore.interaction.getSelectedKeyframesTimeRange()
  }, [dataStore])

  const stretchKeyframeList = useCallback(
    (keyframeList, startTime, endTime, finished) => {
      return dataStore.interaction.stretchKeyframeList(keyframeList, startTime, endTime, finished)
    },
    [dataStore]
  )

  const getAllKeyframeTimeList = useCallback(
    (excludedSelected = false) => {
      const excludedSet = excludedSelected ? new Set(dataStore.selection.get('kfs')) : new Set()
      const allKeyframeList = dataStore.interaction.getAllKeyframes(excludedSet)
      return dataStore.interaction.getKeyframeTimeList(allKeyframeList)
    },
    [dataStore]
  )

  return {
    getSelectedKeyFrameList,
    setActionMaxTime,
    setActionSpeed,
    setActionLooping,
    toggleTrackExpanded,
    setActionSelection,
    getSelectedAction,
    setActiveTableElementSelection,
    getSelectedActiveTableElement,
    getKeyFrame,
    getKeyFrameListAtTime,
    getTrackKeyFramePaintType,
    getTrackKeyFramePaint,
    getElementIdByPropertyTrack,
    getKeyFrameList,
    setKeyFrameFrameType,
    deleteSelectedKeyFrame,
    duplicateSelectedKeyFrame,
    setKeyframeSelectionTimeByOffset,
    setSelectedKeyFrameEasingType,
    setSelectedKeyFrameBezier,
    getIsSelectedKeyframesOverlapped,
    getKeyFrameListGroupByTime,
    getAggregateKeyFrame,
    getKeyFrameByTime,
    getFlattenTrackList,
    getEffectType,
    toggleKeyFrameSelectionByTrack,
    getPropertyKeyFrameGroupByTime,
    getAllKeyframeIdList,
    getKeyframeIdList,
    getAnimatiedElementMap,
    groupKeyframeByTime,
    getSelectedKeyframesTimeRange,
    stretchKeyframeList,
    getAllKeyframeTimeList
  }
}

export default Provider
