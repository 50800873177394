import PropertyStack from '../PropertyStack'

class BlurStack extends PropertyStack {
  constructor(elementStack, data) {
    super(elementStack, data)
    this.type = 'BLUR'
    this.key = 'blur'
    this.dataKey = 'blur'
    this.animatableProperties = new Set(['blur'])
  }
}

export default BlurStack
