import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { useApolloClient } from '@apollo/client'
// @ts-ignore
import { DataStore, Migrator, Sanitizer } from '@phase-software/data-store'
// @ts-ignore
import { NO_COMMIT } from '@phase-software/data-utils'
import { PhaseImporter } from '@phase-software/phase-importer'
import { BlendMode, Mode } from '@phase-software/types'

import { UNSUPPORTED_FEATURE_STORAGE_KEY } from '../constant'
import { LottieFeatureByFile, UNSUPPORTED_LOTTIE_FEATURE_MAP } from '../constants'
import {
  FileFieldsFragment,
  FileFieldsFragmentDoc,
  GetFileByIdDocument,
  ProjectFieldsFragment
} from '../generated/graphql'
import { useSetNotification } from '../providers/NotificationProvider'
import { useWorkspaceContext } from '../providers/WorkspaceContextProvider'
import { useDataStore } from '../providers/dataStore/DataStoreProvider'
import { download } from '../providers/utils'
import { projectFileImageService, projectFileService } from '../services/api'
import { track } from '../services/heapAnalytics'
import { formatPatchParams } from '../services/utils'
import { unzipContent, zipContent } from '../utils/file'
import { getFilePathByMode } from '../utils/pathGenerators'
import useHeapAnalytics from './useHeapAnalytics'

const phaseImporter = new PhaseImporter()
const sanitizer = new Sanitizer()

export enum FileCreationMethod {
  NEW = 'new',
  IMPORT = 'import'
}

type ElementProperties = {
  blendMode: BlendMode
  opacity: number
  scaleX: number
  scaleY: number
  skewX: number
  skewY: number
}

const groupDefaultProps: ElementProperties = {
  blendMode: BlendMode.PASS_THROUGH,
  opacity: 1,
  scaleX: 1,
  scaleY: 1,
  skewX: 0,
  skewY: 0
}

const storeUnsupportedFeatures = (fileId: string, featureSet: Set<string>) => {
  const usedUnsupportedFeatures = new Set()
  featureSet.forEach((feat) => {
    if (UNSUPPORTED_LOTTIE_FEATURE_MAP.has(feat)) {
      usedUnsupportedFeatures.add(feat)
    }
  })

  // change from sessionStorage to localStorage
  // since copy & paste url to new tab not getting unsupported features by fileId
  if (usedUnsupportedFeatures.size > 0) {
    const unsupportedFeatureLottieFiles = JSON.parse(
      localStorage.getItem(UNSUPPORTED_FEATURE_STORAGE_KEY) || '{}'
    ) as LottieFeatureByFile
    unsupportedFeatureLottieFiles[fileId] = Array.from(usedUnsupportedFeatures) as string[]
    localStorage.setItem(UNSUPPORTED_FEATURE_STORAGE_KEY, JSON.stringify(unsupportedFeatureLottieFiles))
  }
}

/**
 * Creates a Blob data URL from the given content.
 * @param content - The content to be converted to a Blob data URL.
 * @param contentType - The MIME type of the content (default is 'text/plain').
 * @returns A promise that resolves to the data URL of the Blob.
 */
const createBlobDataURL = (content: string, contentType = 'text/plain'): Promise<string> => {
  return new Promise((resolve, reject) => {
    // Create a Blob from the content
    const blob = new Blob([content], { type: contentType })

    // Create a FileReader to read the Blob
    const reader = new FileReader()

    // Event handler for when the FileReader has finished reading the Blob
    reader.onloadend = () => {
      if (reader.result) {
        resolve(reader.result as string)
      } else {
        reject(new Error('Failed to create data URL from Blob'))
      }
    }

    // Event handler for errors during reading the Blob
    reader.onerror = () => {
      reject(new Error('Failed to read Blob as data URL'))
    }

    // Read the Blob as a data URL
    reader.readAsDataURL(blob)
  })
}

type FileEditorUrlOptions = {
  fileId: string
  source: string
  isImport: boolean
  dataUrl?: string
}
const getFileEditorLocation = ({
  fileId,
  source,
  isImport,
  dataUrl
}: FileEditorUrlOptions): { pathname: string; search: string } => {
  const pathname = getFilePathByMode(fileId, 'edit')
  const searchParams = new URLSearchParams({ source })

  if (isImport) {
    searchParams.append('import', 'true')
  }

  if (dataUrl) {
    searchParams.append('dataUrl', dataUrl)
  }

  return {
    pathname,
    search: `?${searchParams.toString()}`
  }
}

const useFileActions = () => {
  const { t } = useTranslation('workspace')
  const history = useHistory()
  const client = useApolloClient()
  const { space, teamName } = useHeapAnalytics()
  const { workspaceData } = useWorkspaceContext()
  const { addNotification, removeNotification } = useSetNotification()

  const dataStore = useDataStore()

  const getCurrentUserFilePermissions = useCallback(
    ({ projectId, fileId }: { projectId: ProjectFieldsFragment['id']; fileId: FileFieldsFragment['id'] }) =>
      projectFileService.projectFileControllerGetMyPermissions({
        projectId,
        fileId
      }),
    []
  )

  const updateFileName = useCallback(
    async ({
      projectId,
      fileId,
      name
    }: {
      projectId: ProjectFieldsFragment['id']
      fileId: FileFieldsFragment['id']
      name: FileFieldsFragment['name']
    }) => {
      await projectFileService.projectFileControllerPatchFile({
        projectId,
        fileId,
        patchFileRequest: formatPatchParams(name && { name })
      })
    },
    []
  )

  const exportFile = useCallback(
    async ({
      projectId,
      fileId,
      fileName
    }: {
      projectId: ProjectFieldsFragment['id']
      fileId: FileFieldsFragment['id']
      fileName: FileFieldsFragment['name']
    }) => {
      const { content } = await projectFileService.projectFileControllerDownloadContentV3({
        projectId,
        fileId
      })
      const unzippedContent = await unzipContent(content)
      if (fileName) download(JSON.stringify(unzippedContent), fileName)
    },
    []
  )

  const insertSvg = useCallback(async () => {
    dataStore.eam.insertSvg()
  }, [dataStore])

  const uploadContent = useCallback(
    async ({
      projectId,
      fileId,
      content
    }: {
      projectId: ProjectFieldsFragment['id']
      fileId: FileFieldsFragment['id']
      content: object
    }) => {
      const zipBlob = (await zipContent(content, 'blob')) as Blob

      return await projectFileService.projectFileControllerUploadContentV3({
        projectId,
        fileId,
        content: zipBlob
      })
    },
    []
  )

  const deleteFile = useCallback(
    async ({ projectId, fileId }: { projectId: ProjectFieldsFragment['id']; fileId: FileFieldsFragment['id'] }) => {
      await projectFileService.projectFileControllerDeleteFile({
        projectId,
        fileId
      })
      const location = projectId === workspaceData.draftProjectId ? 'drafts' : 'project'
      track('File Deleted', { space, teamName, location, projectId, fileId })
    },
    [space, teamName, workspaceData.draftProjectId]
  )

  const createOrImportFile = useCallback(
    async ({
      projectId,
      file,
      method,
      openInNewTab = false,
      multipleAnimations = false,
      translatedFileName = 'Untitled'
    }: {
      projectId: ProjectFieldsFragment['id']
      file: File
      method: FileCreationMethod
      openInNewTab?: boolean
      multipleAnimations?: boolean
      translatedFileName?: string
    }) => {
      const parts = file.name.split('.')
      const ext = parts.pop()
      let source = 'phase'
      if (ext?.toLowerCase() === 'json') {
        source = 'lottie'
      } else if (ext?.toLowerCase() === 'svg') {
        source = 'svg'
      }

      const fileName = parts.join('.') || translatedFileName

      const projectFile = await projectFileService.projectFileControllerCreateFile({
        projectId,
        createProjectFileRequest: { name: fileName }
      })

      try {
        const uploadImage = async (name: string, blob: Blob) => {
          const imageResponse = await projectFileImageService.projectFileControllerImportImageV1({
            projectId,
            fileId: projectFile.id,
            content: blob,
            name
          })
          return imageResponse
        }

        const content = await phaseImporter.importAsFile(file, { uploadImage })

        if (source === 'lottie') {
          storeUnsupportedFeatures(projectFile.id, phaseImporter.usedLottieFeatures)
        }

        await client.query({
          query: GetFileByIdDocument,
          variables: { id: projectFile.id },
          fetchPolicy: 'network-only'
        })

        const location = projectId === workspaceData.draftProjectId ? 'drafts' : 'project'
        if (method === FileCreationMethod.NEW) {
          track('New File Created', { space, teamName, location, projectId, fileId: projectFile.id })
        } else if (method === FileCreationMethod.IMPORT) {
          track('File Imported', {
            location,
            space,
            teamName,
            projectId,
            fileImportType: ext,
            fileId: projectFile.id
          })
        }

        if (multipleAnimations) {
          await uploadContent({ projectId, fileId: projectFile.id, content })
        } else {
          const fileEditorUrlOptions: FileEditorUrlOptions = {
            fileId: projectFile.id,
            source,
            isImport: method === FileCreationMethod.IMPORT
          }

          if (method === FileCreationMethod.IMPORT && !openInNewTab) {
            sanitizer.clear()
            const sanitizedContent = sanitizer.sanitize(content)
            const dataUrl = await createBlobDataURL(JSON.stringify(sanitizedContent), 'application/json')
            fileEditorUrlOptions.dataUrl = dataUrl
          }

          const fileEditorLocation = getFileEditorLocation(fileEditorUrlOptions)

          if (openInNewTab) {
            await uploadContent({ projectId, fileId: projectFile.id, content })
            window.open(history.createHref(fileEditorLocation))
          } else {
            history.push(fileEditorLocation)
          }
        }
      } catch (e) {
        await deleteFile({ projectId, fileId: projectFile.id })
        throw e
      }
    },
    [client, workspaceData.draftProjectId, space, teamName, history, uploadContent, deleteFile]
  )

  const insertSVGToFile = useCallback(
    async (dataStore: any, file: File, projectId: string, fileId: string, fromFigma = false) => {
      if (!dataStore) {
        console.warn('Data Store is not available')
        return
      }

      let notifyId
      try {
        const prevDataStoreId = dataStore.get('id')

        notifyId = addNotification({
          type: 'loading',
          content: t('message.uploading_file_01')
        })

        // lottie-importer needs this function to upload images
        const uploadImage = async (name: string, blob: Blob) => {
          const imageResponse = await projectFileImageService.projectFileControllerImportImageV1({
            projectId,
            fileId,
            content: blob,
            name
          })
          return imageResponse
        }

        const parsedSvgElements = await phaseImporter.importAsElement(file, { uploadImage })

        // Avoid file switch when upload image
        if (prevDataStoreId !== dataStore.get('id')) {
          removeNotification(notifyId)
          return
        }

        if (Object.values(parsedSvgElements.images).length) {
          dataStore.images.loadImages(parsedSvgElements.images)
        }

        const deserializedElements = dataStore.deserializeElements(parsedSvgElements)
        const targetContainer = dataStore.getContainableTarget()
        const insertIndex = dataStore.getAssetInsertIndex(targetContainer)

        if (fromFigma) {
          targetContainer.sets(deserializedElements[0].gets('width', 'height'), NO_COMMIT)
          // escape main thread for a frame to allow the UI to update
          setTimeout(() => {
            dataStore.eam.zoomFitContent()
          })
        }

        dataStore.positionNewElement(targetContainer, deserializedElements, null)
        dataStore.addChildrenAt(targetContainer, deserializedElements, insertIndex, NO_COMMIT)

        if (!fromFigma) {
          dataStore.selection.selectElements(deserializedElements)
        }

        dataStore.updateGroupProperties(deserializedElements, true)
        dataStore.eam.activateSelectTool()
      } catch (e) {
        console.error(e)
      } finally {
        if (notifyId) {
          removeNotification(notifyId)
        }
      }
    },
    [addNotification, removeNotification, t]
  )

  const duplicateFile = useCallback(
    async ({
      projectId,
      fileId,
      name
    }: {
      projectId: ProjectFieldsFragment['id']
      fileId: FileFieldsFragment['id']
      name: string
    }) => {
      const duplicatedFile = await projectFileService.projectFileControllerDuplicateFileV3({
        fileId,
        projectId,
        duplicateProjectFileRequest: { name }
      })
      const location = projectId === workspaceData.draftProjectId ? 'drafts' : 'project'
      track('File Duplicated', { space, teamName, location, projectId, fileId })
      return duplicatedFile
    },
    [space, teamName, workspaceData.draftProjectId]
  )

  const archiveFile = useCallback(
    async ({ projectId, fileId }: { projectId: ProjectFieldsFragment['id']; fileId: FileFieldsFragment['id'] }) => {
      await projectFileService.projectFileControllerPatchFile({
        projectId,
        fileId,
        patchFileRequest: formatPatchParams({ status: 'ARCHIVE' })
      })
      const location = projectId === workspaceData.draftProjectId ? 'drafts' : 'project'
      track('File Archived', { space, teamName, location, projectId, fileId })
    },
    [space, teamName, workspaceData.draftProjectId]
  )

  const unarchiveFile = useCallback(
    async ({ projectId, fileId }: { projectId: ProjectFieldsFragment['id']; fileId: FileFieldsFragment['id'] }) => {
      await projectFileService.projectFileControllerPatchFile({
        projectId,
        fileId,
        patchFileRequest: formatPatchParams({ status: 'NORMAL' })
      })
    },
    []
  )

  const uploadFileThumbnail = useCallback(
    async ({
      projectId,
      fileId,
      blob
    }: {
      projectId: ProjectFieldsFragment['id']
      fileId: FileFieldsFragment['id']
      blob: Blob
    }) => {
      const { uploadUrl, downloadUrl: thumbnailUrl } =
        await projectFileService.projectFileControllerGenerateFileOperateUrl({
          projectId,
          fileId,
          generateFileOperateUrlRequest: {
            contentType: 'image/jpg'
          }
        })

      const uploadRequest = fetch(uploadUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/octet-stream',
          'Content-Length': blob.size.toString()
        },
        body: blob
      })

      const updateRequest = projectFileService.projectFileControllerPatchFile({
        projectId,
        fileId,
        patchFileRequest: formatPatchParams({ thumbnailUrl })
      })

      await Promise.all([uploadRequest, updateRequest])

      client.cache.updateFragment(
        {
          id: `files:${fileId}`,
          fragment: FileFieldsFragmentDoc,
          fragmentName: 'fileFields'
        },
        (data) => ({ ...data, thumbnail_url: thumbnailUrl })
      )
    },
    [client]
  )

  const downloadContent = useCallback(
    async ({ projectId, fileId }: { projectId: ProjectFieldsFragment['id']; fileId: FileFieldsFragment['id'] }) => {
      const { content } = await projectFileService.projectFileControllerDownloadContentV3({
        projectId,
        fileId
      })
      const doc = await unzipContent(content)
      sanitizer.clear()
      sanitizer.sanitize(doc)
      try {
        const migrated = await new Migrator(DataStore.version).migrate(doc)
        if (migrated) {
          await uploadContent({ projectId, fileId, content: doc })
        }
      } catch (e) {
        console.error(e)
      }
      return doc
    },
    [uploadContent]
  )

  const moveFile = useCallback(
    async ({
      projectId,
      fileId,
      targetProjectId
    }: {
      projectId: ProjectFieldsFragment['id']
      fileId: FileFieldsFragment['id']
      targetProjectId: ProjectFieldsFragment['id']
    }) => {
      await projectFileService.projectFileControllerMoveFileBetweenProjects({
        projectId,
        fileId,
        moveFileBetweenProjectsRequest: { targetProjectId }
      })
      track('File Moved', { space, teamName, fileId, projectId })
    },
    [space, teamName]
  )

  const createFileByDataStore = useCallback(() => {
    const ds = new DataStore()
    ds.addWorkspace()
    ds.addScreenToCurrentWorkspace()
    ds.isLoaded = true
    return ds.save()
  }, [])

  const postProcessLottieFile = useCallback((dataStore: any) => {
    const screen = dataStore.workspace.watched.children[0]

    // TODO: find another efficient way to update group size without mode switching
    dataStore.set('mode', Mode.ACTION)
    dataStore.set('mode', Mode.DESIGN)

    const queue = screen.children.filter((el: any) => el.isContainer)
    while (queue.length) {
      const element = queue.shift()
      queue.push(...element.children.filter((el: any) => el.isContainer))

      if (element.isNormalGroup) {
        const props: ElementProperties = element.gets('opacity', 'blendMode', 'scaleX', 'scaleY', 'skewX', 'skewY')
        const isEqualToDefault = Object.keys(groupDefaultProps).every((key) => {
          const propKey = key as keyof ElementProperties
          return props[propKey] === groupDefaultProps[propKey]
        })
        const isAnimated = dataStore.interaction.getElementTrackIdByElementId(element.get('id'))
        const hasEffect = element.hasEffect
        const isInvalidWorldInv =
          dataStore.drawInfo.vs.indexer.getNode(element.get('id')).item.transform.world.basis_determinant() === 0
        const canUngroup = !isAnimated && !hasEffect && isEqualToDefault && !isInvalidWorldInv

        if (canUngroup || !element.children.length) {
          dataStore.ungroupContainer(element)
          dataStore.workspace.watched.fireSceneTreeChanges()
        }
      }
    }
  }, [])

  /**
   * @todo The importer plug-in is a third party library, we are not available to modify the plug-in. Acoordingly, we do fix after importing and we should remove it in the future
   */
  const postProcessSVGFile = useCallback((dataStore: any) => {
    const screen = dataStore.workspace.watched.children[0]
    const topElements = screen.children.filter((el: any) => el.isContainer)

    for (const element of topElements) {
      const { width, height, referencePointX, referencePointY } = element.gets(
        'width',
        'height',
        'referencePointX',
        'referencePointY'
      )

      const contentAnchorX =
        width === 0 ? 0.5 * 0.01 - referencePointX * 0.5 : 0.5 * width * 0.01 - referencePointX + width * 0.5
      const contentAnchorY =
        height === 0 ? 0.5 * 0.01 - referencePointY * 0.5 : 0.5 * height * 0.01 - referencePointY + height * 0.5

      const changes = { contentAnchorX, contentAnchorY }
      element.sets(changes)

      // Freeze element
      const newTranslate = dataStore.drawInfo.getFixedPositionByChanges(element.get('id'), changes)
      if (newTranslate) {
        element.sets({
          translateX: newTranslate.x,
          translateY: newTranslate.y
        })
      }
    }
  }, [])

  return {
    archiveFile,
    deleteFile,
    downloadContent,
    duplicateFile,
    exportFile,
    getCurrentUserFilePermissions,
    createOrImportFile,
    moveFile,
    unarchiveFile,
    updateFileName,
    uploadContent,
    uploadFileThumbnail,
    createFileByDataStore,
    postProcessLottieFile,
    postProcessSVGFile,
    insertSVGToFile,
    insertSvg
  }
}
export default useFileActions
