export const TransformFeature = {
  MOTION_PATH: 'MOTION_PATH',
  SPLIT_VECTOR: 'SPLIT_VECTOR',
  POSITION: 'POSITION',
  POSITION_ANIMATION: 'POSITION_ANIMATION',
  ANCHOR: 'ANCHOR',
  ANCHOR_ANIMATION: 'ANCHOR_ANIMATION',
  ROTATION: 'ROTATION',
  ROTATION_ANIMATION: 'ROTATION_ANIMATION',
  ROTATION_X: 'ROTATION_X',
  ROTATION_X_ANIMATION: 'ROTATION_X_ANIMATION',
  ROTATION_Y: 'ROTATION_Y',
  ROTATION_Y_ANIMATION: 'ROTATION_Y_ANIMATION',
  ROTATION_Z: 'ROTATION_Z',
  ROTATION_Z_ANIMATION: 'ROTATION_Z_ANIMATION',
  OPACITY: 'OPACITY',
  OPACITY_ANIMATION: 'OPACITY_ANIMATION',
  SCALE: 'SCALE',
  SCALE_ANIMATION: 'SCALE_ANIMATION',
  SKEW: 'SKEW',
  SKEW_ANIMATION: 'SKEW_ANIMATION',
  SKEW_ANGLE: 'SKEW_ANGLE',
  SKEW_ANGLE_ANIMATION: 'SKEW_ANGLE_ANIMATION'
}
