import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'

import { IfFeatureEnabled, useFeatureValue } from '@growthbook/growthbook-react'
import { WorkspaceType } from '@phase-software/types'

import { FilesPageEnum } from '.'
import { IfPermissionGranted, PERMISSIONS, useAccessControl } from '../../access-control'
import ShareTeamProjectDialog from '../../components/Share/ShareTeamProjectDialog'
import { Button, SearchInput, Select, Tooltip } from '../../components/shared'
import { translateMenuOptions } from '../../components/shared/Menu/utils'
import { FileListLoading, WorkSpaceTitleLoading } from '../../components/shared/Skeleton'
import { ProjectFieldsFragment } from '../../generated/graphql'
import { FEATURE_KEYS } from '../../growthbook-feature-keys'
import { useFeaturedTemplate } from '../../hooks/useFeaturedTemplate'
import useHeapAnalytics from '../../hooks/useHeapAnalytics'
import { FilePermissionProvider } from '../../providers/FilePermissionProvider'
import { useProfile } from '../../providers/ProfileProvider'
import { useProjectPermissionContext } from '../../providers/ProjectPermissionProvider'
import { useSkeletonLoadingContext } from '../../providers/SkeletonLoadingProvider'
import { useWorkspaceContext } from '../../providers/WorkspaceContextProvider'
import { addUserProperties, track } from '../../services/heapAnalytics'
import { ProfileWorkspaceListType } from '../../types/User'
import { getTemplatePath, getWorkspaceTemplatePath } from '../../utils/pathGenerators'
import { translateProjectName } from '../../utils/transformProject'
import { type BuiltInTemplateType } from '../Templates'
import TemplateListItem from '../Templates/components/TemplateListItem'
import useFeaturedTemplateList from '../Templates/useFeaturedTemplateList'
import CreateFileButton from './components/CreateFileButton'
import EmptyList from './components/EmptyState/EmptyList'
import NoResultsFound from './components/EmptyState/NoResultsFound'
import FileDropUploader from './components/FileDropUploader'
import FileListItem from './components/FileListItem'
import ImportFileButton from './components/ImportFileButton'
import { LottieExportButtonForQATesting } from './components/LottieExportButtonForQATesting'
import ProjectDropdown from './components/ProjectDropdown'
import TemplateSection from './components/TemplateSection'
import { GRID_GAP, MIN_CARD_WIDTH, gridConfigMap, gridGapMap } from './config'
import useFileList, { SORT_OPTIONS, SortOption } from './useFileList'

type FileListProps = {
  id: ProjectFieldsFragment['id']
  page: FilesPageEnum
}

const FileList = ({ id, page }: FileListProps) => {
  const { t } = useTranslation(['workspace', 'common'])
  const profile = useProfile()
  const location = useLocation()
  const builtInTemplate = useFeatureValue<BuiltInTemplateType>(FEATURE_KEYS.TEMPLATE, {
    value: false,
    projectId: ''
  })
  const { workspaceData, isWorkspaceDataLoading } = useWorkspaceContext()
  const { projectWithPermission, fetchPermissions } = useProjectPermissionContext()
  const { userHasPermission } = useAccessControl()
  const { isSectionVisible: isTemplateSectionVisible, hideTemplateSection } = useFeaturedTemplate()
  const { space, teamName } = useHeapAnalytics()
  const { workspaceSidebarReady, updateWorkspaceFileListReady } = useSkeletonLoadingContext()
  const [shareDialogOpen, setShareDialogOpen] = useState(false)

  const searchParams = new URLSearchParams(location.search)
  const source = searchParams.get('source')
  const analyticsLocation = id === workspaceData.draftProjectId ? 'drafts' : 'project'
  const isDraftProject = page === FilesPageEnum.DRAFT_PROJECT
  const isNormalProjectPage = page === FilesPageEnum.NORMAL_PROJECT
  const isProjectOrDraftPage = isNormalProjectPage || isDraftProject
  const teamsCount = profile?.workspaceList?.length
    ? profile?.workspaceList.filter((ws: ProfileWorkspaceListType) => ws.description !== 'Personal').length
    : 0
  const joinTeamIndex = profile?.workspaceList?.length
    ? profile?.workspaceList
        .filter((ws: ProfileWorkspaceListType) => ws.description !== 'Personal')
        .findIndex((ws: ProfileWorkspaceListType) => {
          return ws?.teamId === workspaceData.id
        })
    : -1

  const {
    observerRef,
    filteredFiles,
    hasFiles,
    isLoadingFiles,
    isLoadingProject,
    projectName,
    searchQuery,
    setSearchQuery,
    setSortBy,
    sortBy
  } = useFileList({
    isProjectOrDraftPage,
    isArchivePage: page === FilesPageEnum.ARCHIVE,
    isSharedPage: page === FilesPageEnum.SHARED,
    projectId: id,
    workspaceData
  })

  const { featuredTemplates, hasTemplates, fetchMoreTemplates, isLoadingTemplates } = useFeaturedTemplateList({
    projectId: builtInTemplate.projectId ?? ''
  })

  const hasFilteredFiles = !!filteredFiles.length
  const isPageLoading = !workspaceSidebarReady || isWorkspaceDataLoading || isLoadingProject || isLoadingFiles
  const noSearchResults = !isPageLoading && !hasFilteredFiles && searchQuery
  const isEmptyList = !isPageLoading && !hasFilteredFiles && !searchQuery
  const showTemplateSection = builtInTemplate.value && !isLoadingTemplates && hasTemplates && isTemplateSectionVisible

  const pageTitleMapping = {
    [FilesPageEnum.ARCHIVE]: t('workspace:archive'),
    [FilesPageEnum.SHARED]: t('workspace:shared'),
    [FilesPageEnum.NORMAL_PROJECT]: projectName,
    [FilesPageEnum.DRAFT_PROJECT]: translateProjectName(projectName, t)
  }

  const translatedSortOptions = useMemo(
    () =>
      translateMenuOptions(t, SORT_OPTIONS, {
        ns: 'workspace'
      }),
    [t]
  )

  const handleSortChange = (sortOptionValue: SortOption['value']) => {
    setSortBy(sortOptionValue)
  }

  const handleProjectShare = () => {
    track('Project Share Clicked', {
      projectId: id,
      space,
      teamName,
      location: analyticsLocation
    })
    setShareDialogOpen(true)
  }

  useEffect(() => {
    fetchPermissions()
  }, [fetchPermissions])

  useEffect(() => {
    if (!isLoadingFiles && !isWorkspaceDataLoading) {
      updateWorkspaceFileListReady(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingFiles, isWorkspaceDataLoading])

  // access from invitation link add event tracking
  useEffect(() => {
    if (source === 'join' && workspaceData.type === WorkspaceType.TEAM && joinTeamIndex > -1) {
      track('Team Invitation Link Opened', {
        teamName: workspaceData.name,
        userRole: workspaceData.role
      })
      addUserProperties({
        teamsCount,
        [`teamName${joinTeamIndex + 1}`]: workspaceData.name
      })
    }
  }, [
    analyticsLocation,
    source,
    space,
    teamsCount,
    joinTeamIndex,
    workspaceData.name,
    workspaceData.role,
    workspaceData.type
  ])

  return isPageLoading ? (
    <>
      <WorkSpaceTitleLoading />
      <FileListLoading iterations={7}></FileListLoading>
    </>
  ) : (
    <>
      <div className="top-0 z-10 pt-40 flex flex-col gap-y-32 bg-inherit min-w-0">
        {isDraftProject && (
          <IfFeatureEnabled feature={FEATURE_KEYS.TEMPLATE}>
            <IfPermissionGranted perform={PERMISSIONS.VIEW_TEMPLATE}>
              {showTemplateSection && (
                <TemplateSection
                  onClose={hideTemplateSection}
                  fetchMore={fetchMoreTemplates}
                  url={getWorkspaceTemplatePath(workspaceData.type, workspaceData.slug)}
                >
                  {featuredTemplates.map((template) => (
                    <TemplateListItem
                      key={template.id}
                      templateId={template.id}
                      name={template.name}
                      imageUrl={template.thumbnail_url}
                      templateUrl={getTemplatePath(template.id)}
                      author={template.author}
                      authorUrl={template.author_source}
                    />
                  ))}
                </TemplateSection>
              )}
            </IfPermissionGranted>
          </IfFeatureEnabled>
        )}
        <div className="flex justify-between items-center px-64 pb-24 mb-8 gap-32 bg-neutral-80">
          <div className="flex items-center gap-8 min-w-0">
            <Tooltip content={pageTitleMapping[page]} visibleOnOverflow>
              <h3 className="text-h3 font-semibold hide-project-search:max-w-[200px] truncate">
                {pageTitleMapping[page]}
              </h3>
            </Tooltip>
            {isNormalProjectPage && !isLoadingProject && (
              <ProjectDropdown
                id={id}
                name={projectName}
                workspaceType={workspaceData.type}
                workspaceSlug={workspaceData.slug}
              />
            )}
          </div>
          <div className="flex-shrink-0 flex gap-16">
            <IfPermissionGranted perform={PERMISSIONS.SHARE_PROJECT} data={projectWithPermission}>
              {!workspaceData.isPersonal && isNormalProjectPage && (
                <Button
                  variant="solid"
                  color="secondary"
                  size="l"
                  data-test-id="workspace-share-button"
                  onClick={handleProjectShare}
                >
                  {t('common:button.share')}
                </Button>
              )}
            </IfPermissionGranted>

            <IfPermissionGranted perform={PERMISSIONS.CREATE_FILE} data={projectWithPermission}>
              {isProjectOrDraftPage && (
                <>
                  {/* This LottieExportButtonForQATesting button is for QA testing only. It allows us to test the SVG Import code to examine the intermediate Lottie file.
                      When we have a new version of the SVG importer, which does not use Lottie, this Button can be deleted. Check with Max/QA first. */}
                  <LottieExportButtonForQATesting />

                  <ImportFileButton projectId={id} />
                  <CreateFileButton projectId={id} />
                </>
              )}
            </IfPermissionGranted>
          </div>
        </div>
      </div>
      {hasFiles && (
        <div className="flex justify-between px-64 mb-16">
          <div className={`w-[400px] ${isNormalProjectPage ? 'hide-project-search:hidden' : ''}`}>
            <SearchInput
              value={searchQuery}
              onChange={setSearchQuery}
              size="s"
              variant="flat"
              divider="none"
              padding={false}
            />
          </div>
          <Select
            // @ts-ignore TODO: fix after refactor of Select
            size="l"
            caret
            options={translatedSortOptions}
            value={sortBy}
            onChange={handleSortChange}
            className="w-min"
          />
        </div>
      )}
      {hasFilteredFiles && (
        <div
          className={`${gridConfigMap[MIN_CARD_WIDTH]} ${gridGapMap[GRID_GAP]} px-64 pb-32 bg-neutral-80`}
          data-test-id="file-list"
        >
          {filteredFiles?.map((file) => (
            <FilePermissionProvider key={file.id} id={file.id} projectId={file.project_id}>
              <FileListItem
                id={file.id}
                isArchived={file.status === 'ARCHIVE'}
                name={file.name}
                projectId={file.project_id}
                projectName={file.project?.name || ''}
                thumbnailUrl={`${file.thumbnail_url}?lastUpdate=${file.updated_at}`}
                updatedAt={file.updated_at}
              />
            </FilePermissionProvider>
          ))}
          <div ref={observerRef} />
        </div>
      )}
      {noSearchResults && <NoResultsFound />}
      {isEmptyList && (
        <EmptyList
          page={page}
          hasCreateFilePermission={userHasPermission(PERMISSIONS.CREATE_FILE, projectWithPermission)}
        />
      )}

      {isProjectOrDraftPage && <FileDropUploader projectId={id} />}
      <ShareTeamProjectDialog projectId={id} open={shareDialogOpen} onClose={() => setShareDialogOpen(false)} />
    </>
  )
}

export default FileList
