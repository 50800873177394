import React, { useRef, forwardRef, useImperativeHandle, useEffect, useCallback, ReactNode } from 'react'
import Tick from './tick.svg'
import Dash from './dash.svg'

type CheckboxRef = HTMLInputElement
type OmitChecked = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'checked'>
interface CheckboxProps extends OmitChecked {
  checked?: boolean | 'mixed'
  label?: string | ReactNode
  inline?: boolean
  error?: boolean
}

const defaultClassName = 'relative appearance-none w-16 h-16 rounded-sm cursor-pointer rounded-sm'
const focusOutlineOffset =
  "focus-visible:after:content-[''] focus-visible:after:absolute focus-visible:after:inset-[-3px] focus-visible:after:rounded-[6px] focus-visible:after:ring-1 focus-visible:after:ring-primary"
const focusBorder = 'focus-visible:border focus-visible:border-primary'
const disabledClassName = 'disabled:opacity-40 disabled:cursor-not-allowed'

const getComputedClassName = (error: boolean, checked: boolean | string) => {
  if (error) {
    return {
      inputClassName: `
        bg-light-overlay-15 hover:bg-light-overlay-25 active:bg-light-overlay-10
        border border-danger
        checked:border-none checked:bg-rubin-50 hover:checked:bg-rubin-40 active:checked:bg-rubin-60 
        ${disabledClassName}
        ${focusOutlineOffset}`
    }
  }
  if (checked === 'mixed') {
    return {
      inputClassName: `
        bg-primary hover:bg-primary-40 active:bg-primary-60
        ${disabledClassName} disabled:hover:bg-primary
        ${focusOutlineOffset}`
    }
  }
  return {
    inputClassName: `
      bg-light-overlay-15 hover:bg-light-overlay-25 active:bg-light-overlay-10
      checked:bg-primary hover:checked:bg-primary-40 active:checked:bg-primary-60
      ${disabledClassName} disabled:hover:bg-light-overlay-15 disabled:checked:hover:bg-primary
      ${checked ? focusOutlineOffset : focusBorder}`
  }
}

const Checkbox = forwardRef<CheckboxRef, CheckboxProps>(
  ({ checked = false, label, inline, error = false, disabled = false, ...rest }, forwardRef) => {
    const ref = useRef<HTMLInputElement>(null)
    useImperativeHandle(forwardRef, () => ref.current as HTMLInputElement)

    const inputClassName = getComputedClassName(error, checked).inputClassName

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      switch (e.key) {
        case 'Escape':
          e.currentTarget.blur()
          e.stopPropagation()
          break
        case ' ':
          e.preventDefault()
          e.currentTarget.click()
          break
        case 'Enter':
          e.currentTarget.click()
          break
        default:
          break
      }
    }

    useEffect(() => {
      if (ref.current === null) return

      if (checked === 'mixed') {
        ref.current.indeterminate = true
        ref.current.checked = false
      } else {
        ref.current.indeterminate = false
        ref.current.checked = checked
      }
    }, [checked, ref])

    const handleMouseUp = useCallback(() => {
      if (ref.current) {
        ref.current.blur()
      }
    }, [])

    return (
      <div className={`${inline ? 'inline-flex' : 'flex'} items-start text-left w-fit`}>
        <label
          onMouseUp={handleMouseUp}
          className={`${disabled ? 'opacity-40 cursor-not-allowed' : ''} flex items-start cursor-pointer`}
        >
          <div className="relative flex items-center justify-center peer">
            <input
              {...rest}
              ref={ref}
              onKeyDown={handleKeyDown}
              disabled={disabled}
              type="checkbox"
              className={`h-16 w-16 peer ${defaultClassName} ${inputClassName}`}
              id="custom"
            />
            <img
              src={Tick}
              alt="tick"
              className={`absolute text-white transition-opacity opacity-0 peer-checked:opacity-100
              } pointer-events-none top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2`}
            />
            <img
              src={Dash}
              alt="dash"
              className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-indeterminate:opacity-100"
            />
          </div>
          {label && <div className={`text-12 pl-8 text-white`}>{label}</div>}
        </label>
      </div>
    )
  }
)

Checkbox.displayName = 'Checkbox'

export default Checkbox
