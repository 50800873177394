import React, { HTMLAttributes, FC, useRef, useEffect, useState } from 'react'

type ErrorTextComponentProps = HTMLAttributes<HTMLDivElement> & {
  showErrorTips?: boolean
  errorTips?: string
  className?: string
}

const ErrorTextComponent: FC<ErrorTextComponentProps> = ({
  showErrorTips,
  children,
  errorTips,
  className,
  ...props
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const [clientWith, setClientWith] = useState(0)
  useEffect(() => {
    if (ref.current?.firstElementChild) {
      setClientWith(ref.current.firstElementChild.clientWidth)
    }
  }, [ref.current?.firstElementChild?.clientWidth])

  return (
    <div {...props} className="relative w-full" ref={ref}>
      {children}
      {showErrorTips && !!errorTips && (
        <div
          style={{
            width: clientWith
          }}
          className={`${className || ''} text-12 text-left text-rubin-50 mt-4`}
        >
          {errorTips}
        </div>
      )}
    </div>
  )
}
export default ErrorTextComponent
