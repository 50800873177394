import React from 'react'

import svgs from '../Icon/svgs'
import Tooltip from '../Tooltip'

type SwitcherButtonProps = {
  checked: boolean
  name: string
  tipData?: { text: string; shortcut: string }
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const SwitcherButton = ({ checked, name, tipData, handleChange }: SwitcherButtonProps) => {
  const SvgIcon = svgs[name] || (() => null)
  return (
    <div className="relative cursor-pointer">
      <Tooltip content={tipData} offset={12}>
        <label className="group">
          <input
            name="mode-switcher"
            type="checkbox"
            checked={checked}
            onChange={handleChange}
            className="absolute w-0 h-0 opacity-0 peer"
          />
          <div
            className="w-48 h-28 rounded-[24px] transition-colors duration-300 ease-out
            bg-light-overlay-10 peer-checked:bg-primary 
            hover:bg-light-overlay-15 peer-checked:hover:bg-primary-40 
            group-hover:bg-light-overlay-15
            active:bg-light-overlay-5 peer-checked:active:bg-primary-60
            group-active:bg-light-overlay-5 cursor-pointer"
          />
          <SvgIcon
            name="Code"
            className={`absolute pointer-events-none 
            left-4 top-4 w-20 h-20 p-2 rounded-circle bg-white 
            transition-transform duration-300 ease-linear 
            origin-center peer-checked:translate-x-full 
            ${checked ? 'text-primary group-hover:text-primary-40' : 'text-dark-overlay-80'}`}
            key="mode-switcher-knob"
          />
        </label>
      </Tooltip>
    </div>
  )
}

export default SwitcherButton
