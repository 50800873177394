import { minmax } from '@phase-software/data-utils'
import { Unit, PaintType } from '@phase-software/types'
import { EFFECT_TYPE_NAME_MAP } from '../components/constants'
import { SWITCH_COMPONENT_PROPS, MERGE_STACK_SUB_PROPERTIES, NON_REPEATABLE_PROPERTIES_ALIAS } from '../constant'
import conditionsExec from './conditionsExec'

export { minmax }

/**
 * Determine if the diffs of two numbers are less than the threshold.
 * @param {number} v1 the first number
 * @param {number} v2 the second number
 * @returns {boolean} true if they are close otherwise, false.
 */
export const areNumbersClose = (v1, v2) => Math.abs(v1 - v2) < 0.01

/**
 * Get available Response
 * @param {DataStore} dataStore
 * @param {Response[]} responses
 * @returns {Response}
 */
export const getMatchedResponse = (dataStore, responses) => {
  return responses.find(response => conditionsExec(dataStore, response.conditionList))
}

/**
 * Get end time of a Response
 * @param {Response} response
 * @returns {number}
 */
export const getResponseEndTime = response => {
  if (!response) {
    return 0
  }

  let max = 0
  response.elementTrackMap.forEach(elt => {
    elt.propertyTrackMap.forEach(pt => {
      pt.keyFrameList.forEach(kf => {
        max = Math.max(max, kf.time)
      })
    })
  })

  return max
}

/**
 * Get the rounded value of a specific decimal point
 * @param {number} value
 * @param {number} [precision=3]
 * @returns {number}
 */
export const roundDecimal = (value, precision = 3) => {
  return Math.round(value * Math.pow(10, precision)) / Math.pow(10, precision)
}

/**
 * Get previous data inside Map
 * @param {Map} map
 * @param {any} key
 * @returns {any}
 */
export const getPreviousMap = (map, key) => {
  const keys = [...map.keys()]
  const idx = keys.findIndex(k => key === k)
  const previousKey = keys[idx - 1]
  return previousKey ? map.get(previousKey) : null
}

/**
 * Get diffs from the first Map to the second Map
 * @param {Map} a
 * @param {Map} b
 * @returns {Map}
 */
export const getMapDiffs = (a, b) => {
  const diffs = new Map()
  a.forEach((v, k) => {
    if (!b.get(k)) {
      diffs.set(k, v)
    }
  })

  return diffs
}

/**
 * Get diffs from the first array to the second array
 * @param {Array} a
 * @param {Array} b
 * @returns {Array}
 */
export const getArrayDiffs = (a, b) => {
  return a.filter(c => !b.includes(c))
}

/**
 * Get origin value in real pixel
 * @param {object} data
 * @param {number} width
 * @param {number} height
 * @returns {object}
 */
export const getOrigin = (data, width, height) => {
  const originInPixel = { originX: data.originX, originY: data.originY }
  if (data.originXUnit === Unit.PERCENT) {
    originInPixel.originX = data.originX * width * 0.01
    originInPixel.originY = data.originY * height * 0.01
  }

  return originInPixel
}

/**
 * Cache non-repeatable property base value
 * @param {obj} out
 * @param {DataStore} dataStore
 * @param {Element} element
 * @param {string} propKey
 */
export const cacheNonRepeatablePropertyBaseValue = (out, dataStore, element, propKey) => {
  // FIXME: (motion-path) should not switch prop anymore
  const newPropKey = SWITCH_COMPONENT_PROPS[propKey] || propKey
  const alias = NON_REPEATABLE_PROPERTIES_ALIAS[newPropKey] || newPropKey
  const propId = element.base[alias]
  if (propId) {
    const propCom = dataStore.library.getComponent(propId)
    if (propCom) {
      // FIXME: (motion-path) const for it
      if (newPropKey === 'motionPath') {
        out[newPropKey] = { in: [0, 0], out: [0, 0], pos: [0, 0] }
        const keys = ['translateX', 'translateY']
        keys.forEach((key, index) => {
          out[newPropKey].pos[index] = propCom[key]
        })
      } else if (MERGE_STACK_SUB_PROPERTIES[newPropKey]) {
        out[newPropKey] = MERGE_STACK_SUB_PROPERTIES[newPropKey].reduce((acc, key) => {
          acc[key] = propCom[key]
          return acc
        }, {})
      } else {
        out[newPropKey] = propCom[newPropKey]
      }
    }
  }
}

/**
 * Cache all repeatable property base value
 * @param {obj} out
 * @param {DataStore} dataStore
 * @param {Element} element
 * @param {string} layerKey
 */
export const cacheAllRepeatablePropertyBaseValue = (out, dataStore, element, layerKey) => {
  out[layerKey] = {}
  const layerCom = dataStore.library.getComponent(element.base[layerKey][0])
  if (!layerCom) {
    return
  }

  layerCom.layers.forEach((layerId) => {
    const layer = dataStore.library.getLayer(layerId)
    const layerData = {}
    layer.properties.forEach(propName => {
      layerData[propName] = layer[propName]
    })
    if (layerData.paintId) {
      const paintCom = dataStore.library.getComponent(layerData.paintId)
      if (paintCom) {
        layerData.paintType = paintCom.paintType
        layerData.opacity = paintCom.opacity
        layerData.blendMode = paintCom.blendMode

        // solid
        layerData.color = [...paintCom.color]

        // gradient
        layerData.gradientStops = paintCom.gradientStops.map(gs => ({ position: gs.position, color: [...gs.color] }))
        layerData.gradientTransform = paintCom.gradientTransform.save()

        // image
        if (paintCom.paintType === PaintType.IMAGE) {
          layerData.imageId = paintCom.imageId
          layerData.imageMode = paintCom.imageMode
        }
      }
    }

    cacheRepeatablePropertyBaseValue(out, layerKey, layerId, layerData)
  })
}

/**
 * Cache repeatable property base value
 * @param {obj} out
 * @param {string} layerKey
 * @param {string} layerId
 * @param {object} layerData
 */
export const cacheRepeatablePropertyBaseValue = (out, layerKey, layerId, layerData) => {
  out[layerKey][layerId] = layerData
}

/**
 * Cache repeatable property base value
 * @param {obj} out
 * @param {LayerStack} layerStacks
 */
export const cacheAllNonBaseLayerBaseValue = (out, layerStacks) => {
  if (!layerStacks) {
    return
  }

  layerStacks.forEach(layerStack => {
    if (!layerStack.layerId) {
      cacheRepeatablePropertyBaseValue(out, layerStack.dataKey, layerStack.id, layerStack.getInitValue())
    }
  })
}

/**
 * Cache effect property base value
 * @param {obj} out
 * @param {DataStore} dataStore
 * @param {Element} element
 * @param {EffectType} effectType
 */
export const cacheEffectPropertyBaseValue = (out, dataStore, element, effectType) => {
  const effectCom = dataStore.library.getComponent(element.base.effects[0])
  if (!effectCom) {
    return
  }
  
  effectCom.effects.forEach((effectId) => {
    const effect = dataStore.library.getEffect(effectId)
    if (!effect) {
      return
    }
    
    if (effectType === undefined || (effectType && EFFECT_TYPE_NAME_MAP[effect.effectType] === effectType)) {
      out.effects[EFFECT_TYPE_NAME_MAP[effect.effectType]] = effect.save()
    }
  })
}

/**
 * Cache custom property base value
 * @param {obj} out
 * @param {DataStore} dataStore
 * @param {Element} element
 * @param {string} propKey
 */
export const cacheCustomPropertyBaseValue = (out, dataStore, element, propKey) => {
  switch (propKey) {
    case 'pathMorphing': {
      if (element.canMorph) {
        out[propKey] = element.basePath || new Map()
      }
      break
    }
  }
}

/**
 * Match the gradientStops in-place for each of them to make sure that
 * when doing animation we will get the same amount of gradientStops
 * and get the interpolation data for position and color.
 * @param {Array} bigGradient
 * @param {Array} smallGradient
 */
export const matchGradientStops = (bigGradient, smallGradient) => {
  const sorted = smallGradient.slice().sort((a, b) => a.position - b.position)
  for (let i = sorted.length; i < bigGradient.length; i++) {
    const position = bigGradient[i].position
    let ref = null
    if (position <= sorted[0].position) {
      // Use left one as the ref gradient stop
      ref = sorted[0]
    } else if (position >= sorted[sorted.length - 1].position) {
      // Use right one as the ref gradient stop
      ref = sorted[sorted.length - 1]
    } else {
      // Find the closest gradient stop
      for (let j = 0; j < sorted.length - 1; j++) {
        const curr = sorted[j]
        const next = sorted[j + 1]
        const left = position - curr.position
        const right = next.position - position
        if (left >= 0 && right > 0) {
          if (left <= right) {
            ref = curr
          } else {
            ref = next
          }
          break
        }
      }
    }

    smallGradient[i] = ref
  }
}
