import React from 'react'
import { useTranslation } from 'react-i18next'

// @ts-ignore
import { MIN_SIZE_THRESHOLD } from '@phase-software/data-utils'
import { ContainerElementType, ElementType, GeometryType } from '@phase-software/types'

import { useDataStore } from '../../../../providers/dataStore/DataStoreProvider'
import { useEditor } from '../../../../providers/dataStore/EditorProvider'
import { formatNumberWithUnit } from '../../../../utils/formatter'
import Lock from '../Lock'
import Property from '../Property'
import PropertyRow from '../PropertyRow'

type DimensionsProps = {
  isScreen?: boolean
}

const Dimensions = ({ isScreen }: DimensionsProps) => {
  const { elementType, containerType, geometryType, width, height } = useEditor()
  const dataStore = useDataStore()
  const { t } = useTranslation('file', { keyPrefix: 'property_editor.dimension' })

  const selectedElements = dataStore.selection.get('elements')
  const isLine = geometryType === GeometryType.LINE
  const isVerticalCollinearLine = isLine && width === 0 && height > MIN_SIZE_THRESHOLD
  const isHorizontalCollinearLine = isLine && width > MIN_SIZE_THRESHOLD && height === 0

  const min = isScreen ? 1 : 0

  function isLockDisabled(width: number | undefined, height: number | undefined): boolean {
    if (typeof width === 'number' && typeof height === 'number') {
      if ((isLine && width < MIN_SIZE_THRESHOLD) || (isLine && height < MIN_SIZE_THRESHOLD)) {
        return true
      }
      if (width < MIN_SIZE_THRESHOLD && height < MIN_SIZE_THRESHOLD) return false
      return (
        (width < MIN_SIZE_THRESHOLD && height > MIN_SIZE_THRESHOLD) ||
        (width > MIN_SIZE_THRESHOLD && height < MIN_SIZE_THRESHOLD)
      )
    }

    // FIXME: This should be implemented in Editor
    const areAllDisable = selectedElements.every(
      (el: any) =>
        (el.get('elementType') !== ElementType.SCREEN &&
          ((el.get('width') < MIN_SIZE_THRESHOLD && el.get('height') > MIN_SIZE_THRESHOLD) ||
            (el.get('height') < MIN_SIZE_THRESHOLD && el.get('width') > MIN_SIZE_THRESHOLD))) ||
        (el.get('geometryType') === GeometryType.LINE &&
          (el.get('width') < MIN_SIZE_THRESHOLD || el.get('height') < MIN_SIZE_THRESHOLD))
    )
    if (areAllDisable) {
      return true
    }

    return typeof width === 'undefined' || typeof height === 'undefined'
  }

  const disabledLock = isLockDisabled(width, height)
  const enableKF = isKeyframeEnabled(elementType, containerType, geometryType)

  const handleDisplay = (val: number) => {
    // reach the minimum display value for 0-size and collinear line
    if (val > -1 * MIN_SIZE_THRESHOLD && val < MIN_SIZE_THRESHOLD) return 0
    return formatNumberWithUnit(val)
  }

  return (
    <div className="relative">
      <PropertyRow columns={[94, 94]}>
        <Property
          caption="W"
          data-test-id="width-input"
          enableKF={enableKF}
          field="width"
          kfClassName="pt-kf-indicator"
          toDisplay={handleDisplay}
          tooltip={t('width')}
          min={isVerticalCollinearLine ? 0 : min}
          max={isVerticalCollinearLine ? 0 : Number.MAX_SAFE_INTEGER}
        />
        <Property
          caption="H"
          data-test-id="height-input"
          enableKF={enableKF}
          field="height"
          toDisplay={handleDisplay}
          tooltip={t('height')}
          min={isHorizontalCollinearLine ? 0 : min}
          max={isHorizontalCollinearLine ? 0 : Number.MAX_SAFE_INTEGER}
        />
        <Lock field="aspectRatioLocked" disabled={disabledLock} className="h-16" />
      </PropertyRow>
    </div>
  )
}

export default Dimensions

function isKeyframeEnabled(
  elementType: ElementType,
  containerType: ContainerElementType,
  geometryType: GeometryType
): boolean {
  const isPath =
    elementType === ElementType.PATH && (geometryType === GeometryType.POLYGON || geometryType === GeometryType.LINE)
  const isNormalGroup = elementType === ElementType.CONTAINER && containerType === ContainerElementType.NORMAL_GROUP
  return !(isPath || isNormalGroup)
}
