import { useCallback } from 'react'

import { AllPermissionEnum, DraftFilePermissionEnum } from '../components/Share/shareTypes'
import { CreateProjectFileCollaboratorInvitationRequestPermissionEnum } from '../generated/api'
import { FileFieldsFragment, ProjectFieldsFragment, TeamFieldsFragment } from '../generated/graphql'
import { useWorkspaceContext } from '../providers/WorkspaceContextProvider'
import { projectFileCollaboratorService, projectFileService } from '../services/api'
import { formatPatchParams } from '../services/utils'

const useShareTeamFileActions = () => {
  const { workspaceData } = useWorkspaceContext()

  const changeTeamFileDefaultPermission = ({
    projectId,
    fileId,
    permission,
    draftPermission
  }: {
    projectId: ProjectFieldsFragment['id']
    fileId: FileFieldsFragment['id']
    permission?: AllPermissionEnum
    draftPermission?: DraftFilePermissionEnum
  }) =>
    projectFileService.projectFileControllerPatchFile({
      fileId,
      projectId,
      // @ts-ignore
      patchFileRequest: formatPatchParams({ permission, draftPermission })
    })

  const changeTeamFileCollaboratorPermission = ({
    fileId,
    permission,
    projectId,
    collaboratorId
  }: {
    fileId: FileFieldsFragment['id']
    permission: CreateProjectFileCollaboratorInvitationRequestPermissionEnum
    projectId: ProjectFieldsFragment['id']
    collaboratorId: string
  }) =>
    projectFileCollaboratorService.projectFileCollaboratorControllerPatchTeamProjectFileCollaborator({
      teamId: workspaceData.id,
      projectId,
      fileId,
      collaboratorId,
      patchProjectFileCollaboratorRequest: {
        permission,
        updateMask: ['permission']
      }
    })

  const removeTeamFileCollaborator = ({
    projectId,
    fileId,
    collaboratorId
  }: {
    projectId: ProjectFieldsFragment['id']
    fileId: FileFieldsFragment['id']
    collaboratorId: string
  }) =>
    projectFileCollaboratorService.projectFileCollaboratorControllerDeleteTeamProjectFileCollaborator({
      teamId: workspaceData.id,
      projectId,
      fileId,
      collaboratorId
    })

  const sendTeamFileInvitation = ({
    email,
    fileId,
    permission,
    projectId
  }: {
    email: string
    fileId: FileFieldsFragment['id']
    permission: CreateProjectFileCollaboratorInvitationRequestPermissionEnum
    projectId: ProjectFieldsFragment['id']
  }) =>
    projectFileCollaboratorService.projectFileCollaboratorControllerCreateTeamProjectFileCollaboratorInvitation({
      teamId: workspaceData.id,
      projectId,
      fileId,
      createProjectFileCollaboratorInvitationRequest: {
        email,
        permission
      }
    })

  const resendTeamFileInvitation = ({
    projectId,
    fileId,
    invitationId
  }: {
    projectId: ProjectFieldsFragment['id']
    fileId: FileFieldsFragment['id']
    invitationId: string
  }) =>
    projectFileCollaboratorService.projectFileCollaboratorControllerResendTeamProjectCollaboratorInviteEmail({
      teamId: workspaceData.id,
      projectId,
      fileId,
      invitationId
    })

  const changeTeamFileInvitationPermission = ({
    projectId,
    fileId,
    invitationId,
    permission
  }: {
    projectId: ProjectFieldsFragment['id']
    fileId: FileFieldsFragment['id']
    invitationId: string
    permission: CreateProjectFileCollaboratorInvitationRequestPermissionEnum
  }) =>
    projectFileCollaboratorService.projectFileCollaboratorControllerUpdatePermissionOfTeamProjectFileInvitation({
      teamId: workspaceData.id,
      projectId,
      fileId,
      invitationId,
      updateProjectFileInvitationRequest: {
        permission
      }
    })

  const cancelTeamFileInvitation = ({
    projectId,
    fileId,
    invitationId
  }: {
    projectId: ProjectFieldsFragment['id']
    fileId: FileFieldsFragment['id']
    invitationId: string
  }) =>
    projectFileCollaboratorService.projectFileCollaboratorControllerRemoveTeamProjectFileCollaboratorInvite({
      teamId: workspaceData.id,
      projectId,
      fileId,
      invitationId
    })

  const joinTeamFileCollaborator = useCallback(
    ({
      teamId,
      projectId,
      fileId,
      invitationId
    }: {
      teamId: TeamFieldsFragment['id']
      projectId: ProjectFieldsFragment['id']
      fileId: FileFieldsFragment['id']
      invitationId: string
    }) =>
      projectFileCollaboratorService.projectFileCollaboratorControllerAcceptTeamProjectFileCollaboratorInvite({
        teamId,
        projectId,
        fileId,
        invitationId
      }),
    []
  )

  return {
    changeTeamFileDefaultPermission,
    changeTeamFileCollaboratorPermission,
    removeTeamFileCollaborator,

    sendTeamFileInvitation,
    resendTeamFileInvitation,
    changeTeamFileInvitationPermission,
    cancelTeamFileInvitation,

    joinTeamFileCollaborator
  }
}

export default useShareTeamFileActions
