// TODO: remove this
/* eslint-disable no-param-reassign */

import { CMP_EPSILON } from "./const"
import { Num } from "./num"

/**
 * @param {number} num
 * @returns {boolean}
 */
function is_zero(num) {
    return (num < CMP_EPSILON) && (num > -CMP_EPSILON)
}

const max = Math.max
const abs = Math.abs
const round = Math.round
const sqrt = Math.sqrt
const pow = Math.pow
const log2 = Math.log2

const SOLVE_EPS = 1e-12
const MACHINE_EPS = 1.12e-16
const CURVE_EPS = 1e-3
const CURVE_TIME_EPS = 1e-8

const ad = [0, 0], bd = [0, 0], cd = [0, 0]
/**
 * @param {number} v
 * @param {number[]} r_out
 */
function Split(v, r_out) {
    const x = v * 134217729
    const y = v - x
    const hi = y + x
    const lo = v - hi
    r_out[0] = hi
    r_out[1] = lo
}
/**
 * @param {number} a
 * @param {number} b
 * @param {number} c
 * @returns {number}
 */
function GetDiscriminant(a, b, c) {
    let D = b * b - a * c
    const E = b * b + a * c
    if (abs(D) * 3 < E) {
        Split(a, ad)
        Split(b, bd)
        Split(c, cd)
        const p = b * b
        const dp = (bd[0] * bd[0] - p + 2 * bd[0] * bd[1]) + bd[1] * bd[1]
        const q = a * c
        const dq = (ad[0] * cd[0] - q + ad[0] * cd[1] + ad[1] * cd[0]) + ad[1] * cd[1]
        D = (p - q) + (dp - dq)
    }
    return D
}
/**
 * @param {number} a
 * @param {number} b
 * @param {number} c
 * @returns {number}
 */
function GetNormalizationFactor(a, b, c) {
    const norm = max(a, b, c)
    return (norm && (norm < 1e-8 || norm > 1e8))
        ? pow(2, -round(log2(norm)))
        : 0
}
/**
 * @param {number[]} r_roots
 * @param {number} a
 * @param {number} b
 * @param {number} c
 * @param {number} min
 * @param {number} max
 * @returns {number} how many roots exist
 */
function SolveQuadratic(r_roots, a, b, c, min, max) {
    let x1, x2 = Infinity
    if (abs(a) < SOLVE_EPS) {
        // This could just be a linear equation
        if (abs(b) < SOLVE_EPS) {
            return abs(c) < SOLVE_EPS ? -1 : 0
        }
        x1 = -c / b
    } else {
        // a, b, c are expected to be the coefficients of the equation:
        // Ax² - 2Bx + C == 0, so we take b = -b/2:
        b *= -0.5
        let D = GetDiscriminant(a, b, c)
        // If the discriminant is very small, we can try to normalize
        // the coefficients, so that we may get better accuracy.
        if (D && abs(D) < MACHINE_EPS) {
            const f = GetNormalizationFactor(abs(a), abs(b), abs(c))
            if (f) {
                a *= f
                b *= f
                c *= f
                D = GetDiscriminant(a, b, c)
            }
        }
        if (D >= -MACHINE_EPS) { // No real roots if D < 0
            const Q = D < 0 ? 0 : sqrt(D),
                R = b + (b < 0 ? -Q : Q)
            // Try to minimize floating point noise.
            if (R === 0) {
                x1 = c / a
                x2 = -x1
            } else {
                x1 = R / a
                x2 = c / R
            }
        }
    }
    let count = 0
    // eslint-disable-next-line eqeqeq
    const boundless = (min == null)
    const minB = min - SOLVE_EPS
    const maxB = max + SOLVE_EPS
    // We need to include EPSILON in the comparisons with min / max,
    // as some solutions are ever so lightly out of bounds.
    if (isFinite(x1) && (boundless || (x1 > minB && x1 < maxB))) {
        r_roots[count++] = boundless ? x1 : Num.clamp(x1, min, max)
    }
    if (x2 !== x1 && isFinite(x2) && (boundless || (x2 > minB && x2 < maxB))) {
        r_roots[count++] = boundless ? x2 : Num.clamp(x2, min, max)
    }
    return count
}

/**
 * @param {number} x0
 * @param {number} y0
 * @param {number} x1
 * @param {number} y1
 * @param {number} x2
 * @param {number} y2
 * @param {number} x3
 * @param {number} y3
 * @param {number} t
 * @returns {number}
 */
function CalcCurvature(x0, y0, x1, y1, x2, y2, x3, y3, t) {
    // Do not produce results if parameter is out of range or invalid.
    if (t < 0 || t > 1) return NaN

    // If the curve handles are almost zero, reset the control points to the
    // anchors.
    if (is_zero(x1 - x0) && is_zero(y1 - y0)) {
        x1 = x0
        y1 = y0
    }
    if (is_zero(x2 - x3) && is_zero(y2 - y3)) {
        x2 = x3
        y2 = y3
    }
    // Calculate the polynomial coefficients.
    const cx = 3 * (x1 - x0)
    const bx = 3 * (x2 - x1) - cx
    const ax = x3 - x0 - cx - bx
    const cy = 3 * (y1 - y0)
    const by = 3 * (y2 - y1) - cy
    const ay = y3 - y0 - cy - by
    let x = 0, y = 0

    const tMin = CURVE_TIME_EPS
    const tMax = 1 - tMin

    // Prevent tangents and normals of length 0:
    // https://stackoverflow.com/questions/10506868/
    if (t < tMin) {
        x = cx
        y = cy
    } else if (t > tMax) {
        x = 3 * (x3 - x2)
        y = 3 * (y3 - y2)
    } else {
        x = (3 * ax * t + 2 * bx) * t + cx
        y = (3 * ay * t + 2 * by) * t + cy
    }

    // Calculate 2nd derivative, and curvature from there:
    // http://cagd.cs.byu.edu/~557/text/ch2.pdf page#31
    // k = |dx * d2y - dy * d2x| / (( dx^2 + dy^2 )^(3/2))
    x2 = 6 * ax * t + 2 * bx
    y2 = 6 * ay * t + 2 * by

    const d = pow(x * x + y * y, 3 / 2)

    x = (d === 0) ? 0 : (x * y2 - y * x2) / d

    return x
}

const roots = [0, 0]

/**
 * @param {number[]} r_out
 * @param {number} p0x
 * @param {number} p0y
 * @param {number} p1x
 * @param {number} p1y
 * @param {number} p2x
 * @param {number} p2y
 * @param {number} p3x
 * @param {number} p3y
 * @returns {number} amount of inflections found
 */
export function CalcCubicInflections(r_out, p0x, p0y, p1x, p1y, p2x, p2y, p3x, p3y) {
    let a = 0, b = 0, c = 0

    // Solve for Numerator[k] == 0
    // Where, Curvature k = (dx d^2y - d^2x dy) / (dx^2 - dy^2)^(3/2)
    // Coefficients from Mathematica
    a = -p1x * p0y + 2 * p2x * p0y - p3x * p0y + p0x * p1y - 3 * p2x * p1y + 2 * p3x * p1y - 2 * p0x * p2y + 3 * p1x * p2y - p3x * p2y + p0x * p3y - 2 * p1x * p3y + p2x * p3y
    b = (2 * p1x * p0y - 3 * p2x * p0y + p3x * p0y - 2 * p0x * p1y + 3 * p2x * p1y - p3x * p1y + 3 * p0x * p2y - 3 * p1x * p2y - p0x * p3y + p1x * p3y)
    c = (-p1x * p0y + p2x * p0y + p0x * p1y - p2x * p1y - p0x * p2y + p1x * p2y)

    // Use any method to solve the quadratic equation (a x^2 + b x + c = 0)
    const rootCount = SolveQuadratic(roots, a, b, c, 0, 1)

    // Find the root where, the curve changes from concave to convex
    let found = 0
    for (let i = 0; i < rootCount; i++) {
        const r = roots[i]
        if (r > -CURVE_EPS && r < 1 + CURVE_EPS) {
            if ((
                CalcCurvature(p0x, p0y, p1x, p1y, p2x, p2y, p3x, p3y, r - CURVE_EPS)
                *
                CalcCurvature(p0x, p0y, p1x, p1y, p2x, p2y, p3x, p3y, r + CURVE_EPS)
            ) < 0) {
                r_out[found++] = r
            }
        }
    }

    return found
}

/**
 * @param {number} t
 * @param {Vector2} p1
 * @param {Vector2} c
 * @param {Vector2} p2
 * @returns {number}
 */
export function quadBezierSpeed(t, p1, c, p2) {
    const x0 = p1.x
    const y0 = p1.y
    const x1 = c.x
    const y1 = c.y
    const x2 = p2.x
    const y2 = p2.y

    const nt = 1 - t

    return Math.hypot(
        -2 * (nt * x0 - (1 - 2 * t) * x1 - t * x2),
        -2 * (nt * y0 - (1 - 2 * t) * y1 - t * y2)
    )
}

/**
 * @param {number} t
 * @param {Vector2} p1
 * @param {Vector2} c1
 * @param {Vector2} c2
 * @param {Vector2} p2
 * @returns {number}
 */
export function cubicBezierSpeed(t, p1, c1, c2, p2) {
    const x0 = p1.x
    const y0 = p1.y
    const x1 = c1.x
    const y1 = c1.y
    const x2 = c2.x
    const y2 = c2.y
    const x3 = p2.x
    const y3 = p2.y

    const nt = 1 - t
    const w0 = -3 * nt * nt
    const w1 = 3 * nt * nt - 6 * t * nt
    const w2 = -3 * t * t + 6 * t * nt
    const w3 = 3 * t * t

    return Math.hypot(
        w0 * x0 + w1 * x1 + w2 * x2 + w3 * x3,
        w0 * y0 + w1 * y1 + w2 * y2 + w3 * y3
    )
}

/**
 * @param {number} a
 * @param {number} b
 * @param {Vector2} p1
 * @param {Vector2} p2
 * @param {Vector2} p3
 * @param {Vector2} p4
 * @returns {number}
 */
export function cubicRombergIntegral(a, b, p1, p2, p3, p4) {
    const rom = [[], []]
    const degree = 7
    let half = b - a
    rom[0][0] = 0.5 * half * (cubicBezierSpeed(a, p1, p2, p3, p4) + cubicBezierSpeed(b, p1, p2, p3, p4))
    for (let i0 = 2, iP0 = 1; i0 <= degree; i0++, iP0 *= 2, half *= 0.5) {
        let sum = 0
        for (let i1 = 1; i1 <= iP0; i1++) {
            sum += cubicBezierSpeed(a + half * (i1 - 0.5), p1, p2, p3, p4)
        }

        rom[1][0] = 0.5 * (rom[0][0] + half * sum)
        for (let i2 = 1, iP2 = 4; i2 < i0; i2++, iP2 *= 4) {
            rom[1][i2] = (iP2 * rom[1][i2 - 1] - rom[0][i2 - 1]) / (iP2 - 1)
        }
        for (let i1 = 0; i1 < i0; i1++) {
            rom[0][i1] = rom[1][i1]
        }
    }

    return rom[0][degree - 1]
}

/**
 * @param {number} a
 * @param {number} b
 * @param {Vector2} p1
 * @param {Vector2} p2
 * @param {Vector2} p3
 * @returns {number}
 */
export function quadRombergIntegral(a, b, p1, p2, p3) {
    const rom = [[], []]
    const degree = 7
    let half = b - a
    rom[0][0] = 0.5 * half * (quadBezierSpeed(a, p1, p2, p3) + quadBezierSpeed(b, p1, p2, p3))
    for (let i0 = 2, iP0 = 1; i0 <= degree; i0++, iP0 *= 2, half *= 0.5) {
        let sum = 0
        for (let i1 = 1; i1 <= iP0; i1++) {
            sum += quadBezierSpeed(a + half * (i1 - 0.5), p1, p2, p3)
        }

        rom[1][0] = 0.5 * (rom[0][0] + half * sum)
        for (let i2 = 1, iP2 = 4; i2 < i0; i2++, iP2 *= 4) {
            rom[1][i2] = (iP2 * rom[1][i2 - 1] - rom[0][i2 - 1]) / (iP2 - 1)
        }
        for (let i1 = 0; i1 < i0; i1++) {
            rom[0][i1] = rom[1][i1]
        }
    }

    return rom[0][degree - 1]
}
