// Non-repeatable properties
import WidthStack from './props/WidthStack'
import HeightStack from './props/HeightStack'
import RotationStack from './props/RotationStack'
import ScaleXStack from './props/ScaleXStack'
import ScaleYStack from './props/ScaleYStack'
import SkewXStack from './props/SkewXStack'
import SkewYStack from './props/SkewYStack'
import MotionPathStack from './props/MotionPathStack'
import ContentAnchorStack from './props/ContentAnchorStack'
import CornerRadiusStack from './props/CornerRadiusStack'
import BlurStack from './props/BlurStack'
import BlendModeStack from './props/BlendModeStack'
// Fill
import FillStack from './layers/fill/FillStack'
// Stroke
import StrokeStack from './layers/stroke/StrokeStack'
import PaintStack from './layers/PaintStack'
import BorderWidthStack from './layers/stroke/BorderWidthStack'
import CapShapeStack from './layers/stroke/CapShapeStack'
import CapSizeStack from './layers/stroke/CapSizeStack'
import DashStack from './layers/stroke/DashStack'
import EndsStack from './layers/stroke/EndsStack'
import GapStack from './layers/stroke/GapStack'
import GrowDirectionStack from './layers/stroke/GrowDirectionStack'
import JoinShapeStack from './layers/stroke/JoinShapeStack'
import JoinSizeStack from './layers/stroke/JoinSizeStack'
import MiterStack from './layers/stroke/MiterStack'
import SpreadStack from './layers/stroke/SpreadStack'
// Shadow
import ShadowStack from './layers/shadow/ShadowStack'
import InnerShadowStack from './layers/shadow/InnerShadowStack'
import OffsetXStack from './layers/shadow/OffsetXStack'
import OffsetYStack from './layers/shadow/OffsetYStack'
// Effect
import TrimPathStack from './effects/trimPath/TrimPathStack'
import StartStack from './effects/trimPath/StartStack'
import EndStack from './effects/trimPath/EndStack'
// Shared
import OpacityStack from './OpacityStack'
import OffsetStack from './OffsetStack'
// Custom
import PathMorphingStack from './custom/PathMorphingStack'


export const NON_REPEATABLE_PROPERTIES = {
  motionPath: MotionPathStack,
  width: WidthStack,
  height: HeightStack,
  rotation: RotationStack,
  opacity: OpacityStack,
  blendMode: BlendModeStack,
  scaleX: ScaleXStack,
  scaleY: ScaleYStack,
  skewX: SkewXStack,
  skewY: SkewYStack,
  contentAnchor: ContentAnchorStack,
  cornerRadius: CornerRadiusStack,
  blurGaussian: BlurStack
}

export const NON_REPEATABLE_PROPERTIES_KEYS = Object.keys(NON_REPEATABLE_PROPERTIES)

export const REPEATABLE_PROPERTIES = {
  fills: FillStack,
  strokes: StrokeStack,
  shadows: ShadowStack,
  innerShadows: InnerShadowStack
}

export const REPEATABLE_PROPERTIES_KEYS = Object.keys(REPEATABLE_PROPERTIES)

export const SUB_PROPERTIES = {
  opacity: OpacityStack,
  paint: PaintStack,
  blur: BlurStack,
  blendMode: BlendModeStack,
  borderWidth: BorderWidthStack,
  width: WidthStack,
  cap: CapShapeStack,
  capSize: CapSizeStack,
  dash: DashStack,
  ends: EndsStack,
  gap: GapStack,
  growDirection: GrowDirectionStack,
  join: JoinShapeStack,
  joinSize: JoinSizeStack,
  miter: MiterStack,
  offset: OffsetStack,
  offsetX: OffsetXStack,
  offsetY: OffsetYStack,
  spread: SpreadStack
}

export const SUB_PROPERTIES_KEYS = Object.keys(SUB_PROPERTIES)

export const EFFECT_PROPERTIES = {
  trimPath: TrimPathStack
}

export const EFFECT_PROPERTIES_KEYS = Object.keys(EFFECT_PROPERTIES)

export const SUB_EFFECT_PROPERTIES = {
  start: StartStack,
  end: EndStack,
  offset: OffsetStack
}

export const CUSTOM_PROPERTIES = {
  pathMorphing: PathMorphingStack
}

export const CUSTOM_PROPERTIES_KEYS = Object.keys(CUSTOM_PROPERTIES)


export const PROP_CLASSES = {
  ...NON_REPEATABLE_PROPERTIES,
  ...REPEATABLE_PROPERTIES,
  ...SUB_PROPERTIES,
  ...EFFECT_PROPERTIES,
  ...SUB_EFFECT_PROPERTIES,
  ...CUSTOM_PROPERTIES
}
