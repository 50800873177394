import React from 'react'

import { TRACK_PADDING_X } from '../constant'

type SegmentProps = {
  list?: number[][]
  pxRatio: number
}

const Segment = ({ list = [], pxRatio }: SegmentProps) => {
  return (
    <div
      className={`flex items-center h-24 absolute left-0 right-0 z-0 bg-light-overlay-5 rounded-sm mr-${TRACK_PADDING_X}`}
    >
      {list.map(([start, end]) => {
        return (
          <div
            key={start}
            className="absolute rounded-sm h-24 bg-neutral-70"
            style={{ left: start * pxRatio, width: (end - start) * pxRatio }}
          />
        )
      })}
    </div>
  )
}

export default React.memo(Segment)
