import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { useApolloClient } from '@apollo/client'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { ElementType } from '@phase-software/types'

import { useAccessControl } from '../../../access-control'
import {
  COPY_OPTION,
  CREATE_NEW_FILE_FROM_IMPORT_OPTION,
  CREATE_NEW_FILE_OPTION,
  DELETE_OPTION,
  DOWNLOAD_OPTION,
  EXPORT_OPTION,
  GO_TO_DASHBOARD_OPTION,
  IMPORT_LOTTIE_OPTION,
  INSERT_IMAGE_OPTION,
  INSERT_SVG_OPTION,
  PASTE_OPTION,
  REDO_OPTION,
  SELECT_ALL_OPTION,
  SHOW_HIDE_COMMENT_VISIBILITY_OPTION,
  SHOW_HIDE_INTERFACE_OPTION,
  SHOW_HIDE_ORIGIN_OPTION,
  SHOW_HIDE_PRESENCE_OPTION,
  SHOW_HIDE_RULER_OPTION,
  SUPPORT_LINKS,
  SUPPORT_OPTION,
  TOGGLE_SNAP_TO_OBJECT_OPTION,
  TOGGLE_SNAP_TO_PIXEL_GRID_OPTION,
  TOOLBAR_OPTIONS,
  UNDO_OPTION,
  WATCH_TUTORIAL_VIDEO_OPTION
} from '../../../constants/fileEditorConstants'
import { FileFieldsFragment, FileFieldsFragmentDoc } from '../../../generated/graphql'
import { FEATURE_KEYS } from '../../../growthbook-feature-keys'
import useEditorImage from '../../../hooks/useEditorImage'
import useFileActions from '../../../hooks/useFileActions'
import useImportFile from '../../../hooks/useImportFile'
import { useCommentContext } from '../../../providers/CommentProvider'
import { useFilePermissionContext } from '../../../providers/FilePermissionProvider'
import { usePresenceContext } from '../../../providers/PresenceProvider'
import { useProjectPermissionContext } from '../../../providers/ProjectPermissionProvider'
import { useSetTutorial } from '../../../providers/TutorialProvider'
import { WorkspaceData } from '../../../providers/WorkspaceContextProvider'
import { useDataStore, useDataStoreActions } from '../../../providers/dataStore/DataStoreProvider'
import { useElement } from '../../../providers/dataStore/ElementProvider'
import { useElementSelection } from '../../../providers/dataStore/ElementSelectionProvider'
import { useUI, useUIActions } from '../../../providers/dataStore/UIProvider'
import { TutorialType } from '../../../tutorials'
import { getWorkspaceIndexPath } from '../../../utils/pathGenerators'
import ImportFailedDialog from '../../modals/ImportFailedDialog'
import { Icon, ToolButton } from '../../shared'
import { MenuListOptionProps, MenuOptionProps } from '../../shared/Menu/Menu.types'
import { isSeparatorOption, translateMenuOptions } from '../../shared/Menu/utils'
import { useExportModal } from '../Export'

const TUTORIAL_KEY = 'EDITOR'

type HamburgerMenuProps = {
  projectId: FileFieldsFragment['project_id']
  workspaceData: WorkspaceData
  fileId: FileFieldsFragment['id']
}

const HamburgerMenu = ({ workspaceData, projectId, fileId }: HamburgerMenuProps) => {
  const canImportPhase = useFeatureIsOn(FEATURE_KEYS.IMPORT_PHASE_FILE)
  const canDownload = useFeatureIsOn(FEATURE_KEYS.EXPORT_PHASE_FILE)
  const { userHasPermission } = useAccessControl()
  const { projectWithPermission, fetchPermissions } = useProjectPermissionContext()
  const { canEditFile } = useFilePermissionContext()

  const client = useApolloClient()
  const history = useHistory()
  const { t } = useTranslation(['file', 'common'])

  const importErrorMessage = canImportPhase
    ? t('file:phase_enabled_import_error_message')
    : t('file:phase_disabled_import_error_message')

  const { isContentPanelHidden, hideRuler, hideOrigin, snapToPixelGrid, snapToObject, isVersioningState, editOrigin } = useUI()
  const {
    toggleRuler,
    toggleInterface,
    toggleOrigin,
    toggleSnapToPixelGrid,
    toggleSnapToObject,
    leaveVersionPreview,
    toggleCommentVisibility
  } = useUIActions()
  const { handleExport } = useExportModal()
  const { exportFile, createFileByDataStore, insertSvg } = useFileActions()
  const { copyBySelectionPriority, pasteToElementList, selectAll, undo, redo, deleteElementList, setFeature } =
    useDataStoreActions()
  const { insertImage } = useEditorImage()
  const {
    handleImportFile,
    importWithNewPhaseFile,
    error: importFileError,
    setError: setImportFileError
  } = useImportFile({ projectId, canImportPhase, openInNewTab: true, importErrorMessage })

  const { isPresenceShowByFile, syncPresencePreference } = usePresenceContext()
  const { isCommentVisible } = useCommentContext()

  const primaryOptions = usePrimaryOptions()
  const historyOptions = useHistoryOptions()
  const interactiveOptions = useInteractiveOptions()
  const staticOptions = useStaticOptions()
  const { startVideoTutorial } = useSetTutorial()

  const file = client.readFragment({
    fragment: FileFieldsFragmentDoc,
    fragmentName: 'fileFields',
    id: `files:${fileId}`
  })

  const shouldExcludeImportOption = (option: MenuOptionProps, canImportPhase: boolean) => {
    if (option.value === TOOLBAR_OPTIONS.IMPORT_LOTTIE_OR_PHASE && !canImportPhase) return true
    if (option.value === TOOLBAR_OPTIONS.IMPORT_LOTTIE && canImportPhase) return true
    return false
  }
  const shouldExcludeDownloadOption = (option: MenuOptionProps, canDownload: boolean) =>
    option.value === TOOLBAR_OPTIONS.DOWNLOAD && !canDownload

  const menuOptions = useMemo(() => {
    const options: MenuListOptionProps[] = canEditFile
      ? [...primaryOptions, ...historyOptions, ...interactiveOptions, ...staticOptions]
      : [
          ...primaryOptions,
          '-',
          SHOW_HIDE_INTERFACE_OPTION,
          SHOW_HIDE_COMMENT_VISIBILITY_OPTION,
          SHOW_HIDE_PRESENCE_OPTION,
          '-',
          SUPPORT_OPTION
        ]

    const filteredOptions = options.filter((option) => {
      if (isSeparatorOption(option)) return true

      if (shouldExcludeImportOption(option, canImportPhase)) return false

      if (shouldExcludeDownloadOption(option, canDownload)) return false

      return !option.permission || userHasPermission(option.permission, projectWithPermission)
    })
    return translateMenuOptions(t, filteredOptions)
  }, [
    primaryOptions,
    historyOptions,
    interactiveOptions,
    staticOptions,
    canEditFile,
    canImportPhase,
    canDownload,
    userHasPermission,
    projectWithPermission,
    t
  ])

  const handleMouseEnter = () => {
    fetchPermissions()
  }

  const handleClickOption = (value: MenuOptionProps['value']) => {
    switch (value) {
      case TOOLBAR_OPTIONS.GO_TO_DASHBOARD:
        if (isVersioningState) leaveVersionPreview()
        history.push(getWorkspaceIndexPath(workspaceData.type, workspaceData.slug))
        break
      case TOOLBAR_OPTIONS.NEW: {
        const fileData = createFileByDataStore()
        importWithNewPhaseFile(fileData, t('common:untitled'))
        break
      }
      case TOOLBAR_OPTIONS.IMPORT_LOTTIE:
      case TOOLBAR_OPTIONS.IMPORT_LOTTIE_OR_PHASE:
        if (editOrigin) setFeature('editOrigin', false)
        setFeature('editOrigin', false)
        handleImportFile()
        break
      case TOOLBAR_OPTIONS.IMPORT_SVG:
        insertSvg()
        break
      case TOOLBAR_OPTIONS.INSERT:
        if (editOrigin) setFeature('editOrigin', false)
        insertImage({ multiple: true })
        break
      case TOOLBAR_OPTIONS.EXPORT:
        handleExport()
        break
      case TOOLBAR_OPTIONS.UNDO:
        undo()
        break
      case TOOLBAR_OPTIONS.REDO:
        redo()
        break
      case TOOLBAR_OPTIONS.COPY:
        copyBySelectionPriority()
        break
      case TOOLBAR_OPTIONS.PASTE:
        pasteToElementList()
        break
      case TOOLBAR_OPTIONS.DELETE:
        deleteElementList()
        break
      case TOOLBAR_OPTIONS.SELECT_ALL:
        selectAll()
        break
      case TOOLBAR_OPTIONS.TOGGLE_RULER:
        toggleRuler()
        break
      case TOOLBAR_OPTIONS.TOGGLE_INTERFACE:
        toggleInterface()
        break
      case TOOLBAR_OPTIONS.TOGGLE_COMMENT_VISIBILITY:
        toggleCommentVisibility()
        break
      case TOOLBAR_OPTIONS.TOGGLE_ORIGIN:
        toggleOrigin()
        break
      case TOOLBAR_OPTIONS.TOGGLE_SNAP_TO_PIXEL_GRID:
        toggleSnapToPixelGrid()
        break
      case TOOLBAR_OPTIONS.TOGGLE_SNAP_TO_OBJECT:
        toggleSnapToObject()
        break
      case TOOLBAR_OPTIONS.DOWNLOAD:
        exportFile({ projectId, fileId, fileName: `${file.name}.phase` })
        break
      case TOOLBAR_OPTIONS.SHOW_HIDE_PRESENCE: {
        const toggle = isPresenceShowByFile.has(fileId) ? !isPresenceShowByFile.get(fileId) : false
        syncPresencePreference(fileId, toggle)
        break
      }
      case TOOLBAR_OPTIONS.SUPPORT:
        window.open(SUPPORT_LINKS, '_blank')
        break
      case TOOLBAR_OPTIONS.WATCH_TUTORIAL_VIDEO:
        startVideoTutorial(TutorialType[TUTORIAL_KEY], true)
        break
    }
  }

  const selectedOptions = useMemo(() => {
    const selectableOptions: { [optionValue: string]: boolean } = {
      [TOOLBAR_OPTIONS.TOGGLE_RULER]: !hideRuler,

      [TOOLBAR_OPTIONS.TOGGLE_INTERFACE]: !isContentPanelHidden,
      [TOOLBAR_OPTIONS.TOGGLE_COMMENT_VISIBILITY]: isCommentVisible,
      [TOOLBAR_OPTIONS.TOGGLE_ORIGIN]: !hideOrigin,
      [TOOLBAR_OPTIONS.TOGGLE_SNAP_TO_PIXEL_GRID]: snapToPixelGrid,
      [TOOLBAR_OPTIONS.TOGGLE_SNAP_TO_OBJECT]: snapToObject,
      [TOOLBAR_OPTIONS.SHOW_HIDE_PRESENCE]: isPresenceShowByFile.has(fileId) ? isPresenceShowByFile.get(fileId) : true
    }

    return Object.keys(selectableOptions).filter((optionValue) => selectableOptions[optionValue])
  }, [hideOrigin, hideRuler, isContentPanelHidden, snapToPixelGrid, snapToObject, isPresenceShowByFile, isCommentVisible, fileId])

  return (
    <>
      <ToolButton
        data-test-id="editor-main-menu"
        options={menuOptions}
        selectedValues={selectedOptions}
        onChange={handleClickOption}
        tooltip={t('hamburger_menu')}
        showArrowIcon={false}
        shouldOpenDropdown
        selectable={false}
        renderSelectedOption={() => <Icon interactive={false} name="ListView" size={24} useCurrentColor />}
        onMouseEnter={handleMouseEnter}
      />
      <ImportFailedDialog error={importFileError} setError={setImportFileError} />
    </>
  )
}

export default HamburgerMenu

/* -------------------------------------------------------------------------------------------------
 * HamburgerMenu Options
 * ----------------------------------------------------------------------------------------------- */

const usePrimaryOptions = (): MenuListOptionProps[] => {
  const { isVersioningState, isInspectingState } = useUI()
  const disabled = isVersioningState || isInspectingState

  return useMemo(
    () => [
      GO_TO_DASHBOARD_OPTION,
      '-',
      CREATE_NEW_FILE_OPTION,
      IMPORT_LOTTIE_OPTION,
      CREATE_NEW_FILE_FROM_IMPORT_OPTION,
      { ...INSERT_IMAGE_OPTION, disabled: disabled },
      { ...INSERT_SVG_OPTION, disabled: disabled },
      EXPORT_OPTION,
      DOWNLOAD_OPTION
    ],
    [disabled]
  )
}
const useHistoryOptions = (): MenuListOptionProps[] => {
  const { isVersioningState, isInspectingState } = useUI()
  const undoList = useDataStore((o) => o.undoList)
  const redoList = useDataStore((o) => o.redoList)
  const canUndo = undoList.length > 0 && !isVersioningState && !isInspectingState
  const canRedo = redoList.length > 0 && !isVersioningState && !isInspectingState

  return useMemo(
    () => ['-', { ...UNDO_OPTION, disabled: !canUndo }, { ...REDO_OPTION, disabled: !canRedo }],
    [canUndo, canRedo]
  )
}

const useInteractiveOptions = (): MenuListOptionProps[] => {
  const { isVersioningState, isInspectingState } = useUI()
  const elements = useElement()
  const elementSelection = useElementSelection()
  const disabled = isVersioningState || isInspectingState
  const hasSelection = Array.isArray(elementSelection) && elementSelection.length > 0
  const hasSelectScreen = hasSelection && elementSelection.some((id) => elements[id].elementType === ElementType.SCREEN)

  return useMemo(
    () => [
      '-',
      { ...COPY_OPTION, disabled: disabled || !hasSelection },
      { ...PASTE_OPTION, disabled: disabled },
      { ...DELETE_OPTION, disabled: disabled || !hasSelection || hasSelectScreen },
      '-',
      { ...SELECT_ALL_OPTION, disabled: disabled }
    ],
    [hasSelection, hasSelectScreen, disabled]
  )
}

const useStaticOptions = (): MenuListOptionProps[] => {
  const { isInspectingState } = useUI()

  return useMemo(
    () => [
      '-',
      SHOW_HIDE_RULER_OPTION,
      SHOW_HIDE_INTERFACE_OPTION,
      SHOW_HIDE_COMMENT_VISIBILITY_OPTION,
      SHOW_HIDE_ORIGIN_OPTION,
      SHOW_HIDE_PRESENCE_OPTION,
      '-',
      { ...TOGGLE_SNAP_TO_PIXEL_GRID_OPTION, disabled: isInspectingState },
      { ...TOGGLE_SNAP_TO_OBJECT_OPTION, disabled: isInspectingState },
      '-',
      SUPPORT_OPTION,
      WATCH_TUTORIAL_VIDEO_OPTION
    ],
    [isInspectingState]
  )
}
