import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { PaintType, LayerType } from '@phase-software/types'
import { Modal } from '../shared'

import { useModal, useSetModal } from '../../providers/ModalProvider'
import { useEditorActions } from '../../providers/dataStore/EditorProvider'
import ColorPaintPicker from '../PaintPicker/ColorPaintPicker'
import ImagePaintPicker from '../PaintPicker/ImagePaintPicker'
import PaintTypeSwitcher from '../PaintPicker/PaintTypeSwitcher'
import GradientStops from '../PaintPicker/ColorPaintPicker/GradientStops'
import PaintPickerHeader from '../PaintPicker/PaintPickerHeader'
import { usePaint } from '../../providers/dataStore/PaintProvider'

const PaintModal = ({ layerItemId }) => {
  const MODAL_KEY = `PaintModal-${layerItemId}`
  const { paintType } = usePaint((o) => o[layerItemId])
  const { closeModal, updateModalData } = useSetModal(MODAL_KEY)
  const { setLayerFocused, setLayerPaint } = useEditorActions()
  const {
    open,
    data: { trigger, activeIdx = 0, layerType }
  } = useModal((o) => o[MODAL_KEY])
  const setActiveIdx = useCallback(
    (idx) => {
      updateModalData({ activeIdx: idx })
    },
    [updateModalData]
  )

  const resetActiveIdx = useCallback(() => {
    setLayerPaint(layerItemId, { activeGradientStopIdx: 0 })
  }, [setLayerPaint, layerItemId])

  const closePaintModal = useCallback(() => {
    closeModal()
    setLayerFocused(layerItemId, false)
  }, [closeModal, layerItemId, setLayerFocused])

  useEffect(() => {
    if (open && layerItemId) {
      setLayerFocused(layerItemId, true)

      // Reset active gradient stop idx back to 0
      if (layerType === LayerType.FILL || layerType === LayerType.STROKE) {
        resetActiveIdx()
      }
    } else {
      setLayerFocused(layerItemId, false)
    }
  }, [open, layerItemId, layerType, setLayerFocused, resetActiveIdx])

  const isGradientType = [
    PaintType.GRADIENT_LINEAR,
    PaintType.GRADIENT_RADIAL,
    PaintType.GRADIENT_ANGULAR,
    PaintType.GRADIENT_DIAMOND
  ].includes(paintType)
  const isImageType = paintType === PaintType.IMAGE

  return (
    <Modal
      open={open}
      trigger={trigger}
      onClose={closePaintModal}
      data-test-id="paint-modal"
      draggable
      placement="horizontal"
      offsetX={32}
      className="p-16"
    >
      {open && (
        <div className="grid items-center w-min">
          <PaintPickerHeader activeLayerType={layerType} onClickClose={closePaintModal} />
          <PaintTypeSwitcher layerType={layerType} layerItemId={layerItemId} resetActiveIdx={resetActiveIdx} />
          {isGradientType && (
            <GradientStops layerItemId={layerItemId} activeIdx={activeIdx} onActiveChange={setActiveIdx} />
          )}
          {!isImageType && <ColorPaintPicker layerItemId={layerItemId} activeIdx={activeIdx} />}
          {isImageType && <ImagePaintPicker layerItemId={layerItemId} />}
        </div>
      )}
    </Modal>
  )
}

PaintModal.propTypes = {
  layerItemId: PropTypes.string
}

export default React.memo(PaintModal)
