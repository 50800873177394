import { IDType } from '@phase-software/types'
import { id, loadId } from '../id'

/** @typedef {import('./Mesh').VertexData} VertexData */
/** @typedef {import('./Mesh').EdgeData} EdgeData */
/** @typedef {import('./Mesh').ContourData} ContourData */
/** @enum {number} FlagsEnum */
export const FlagsEnum = Object.freeze(
    {
        "SELECTED": 1,
        "CURVE_VERT": 2, // Only for vertices
        "CONNECT_SELECTED": 4, // 
    }
)

/**
 * A abstract class
 */
export class Cell {
    constructor(cellId = null) {
        this.id = cellId === null ? id(IDType.MESH) : cellId
        /**
         * @type {Set<string>} backpointer for all cells. Its restore id
         * 1. The upperTiers of a vertex are the edges which composed of the vertex
         * 2. The upperTiers of a edge are the contour which composed of the edge
         * */
        this.upperTierIDs = new Set()
        /** @type {'undefined'|'Edge'|'Vertex'|'Contour'} */
        this.type = 'undefined'
        this.flags = 0
        this.index = 0
    }

    static fromData({ id }) {
        loadId(id, IDType.MESH)
    }

    /**
     * Checks whether this cell has been turned the flags specified on
     * @param {FlagsEnum} flag
     * @returns {boolean} true if the flag is set, false otherwise
     */
    isFlagged(flag) {
        return (this.flags & (1 << flag)) !== 0
    }
    /**
     * Flag the given flag
     * @param {FlagsEnum} flag 
     */
    flag(flag) {
        this.flags |= (1 << flag)
    }

    /**
     * Remove the given flag
     * @param {FlagsEnum} flag 
     */
    removeFlag(flag) {
        this.flags &= ~(1 << flag)
    }
    /**
     * @abstract
     * @returns {VertexData|EdgeData|ContourData}
     */
    save(){
        throw new Error('must be implemented by subclass!');
    }
}
