export class IRKeyFrame {
  static defaultValue = {
    value: null,
    time: 0,
    easing: [0, 0, 1, 1],
    step: false,
    inTangent: undefined,
    outTangent: undefined
  }

  constructor(data = IRKeyFrame.defaultValue) {
    this.value = data.value
    this.time = data.time
    this.easing = data.easing
    this.step = data.step
    this.inTangent = data.inTangent
    this.outTangent = data.outTangent
  }

  fromJSON(data) {
    this.value = data.value
    this.time = data.time
    this.easing = data.easing
    this.step = data.step
    this.inTangent = data.inTangent
    this.outTangent = data.outTangent
    return this
  }

  toJSON() {
    return {
      value: this.value,
      time: this.time,
      easing: this.easing,
      step: this.step,
      inTangent: this.inTangent,
      outTangent: this.outTangent
    }
  }
}
