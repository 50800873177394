import { throttle } from 'lodash'
import { FrameType, EntityType, IDType } from '@phase-software/types'
import { setAdd, id } from '@phase-software/data-utils'
import { Setter } from '../Setter'
import { IM_UPDATE_RATE_LIMITER } from '../constant'


/** @typedef {import('../DataStore').DataStore} DataStore */
/** @typedef {import('../Element').Element} Element */
/** @typedef {import('./Effect').Effect} Effect */
/** @typedef {import('./Effect').EffectChanges} EffectChanges */


const PROP_TYPES = {}


const READ_ONLY = []

const UNDO_CHANGES = [
    'visible'
]

/**
 * ComputedEffects used by a ComputedStyle
 * @abstract
 */
export class ComputedEffect extends Setter {
    /**
     * @param {DataStore} dataStore
     * @param {ComputedEffectData} data
     */
    constructor(dataStore, data) {
        super(dataStore, data)

        setAdd(this.undoChanges, UNDO_CHANGES)
        setAdd(this.readOnly, READ_ONLY)

        this.notAnimatableProps = new Set()


        this._effectChangesLn = this._handleEffectChanges.bind(this)
        this.bindComputedEffectChange()

        this._throttledUpdateIMEffectProps = throttle(
            this._updateIMEffectProps.bind(this),
            IM_UPDATE_RATE_LIMITER,
            { leading: true, trailing: true }
        )
    }

    _init() {
        this.type = EntityType.COMPUTED_EFFECT
        this.propTypes = PROP_TYPES

        // flags / change ids, SHOULD start from 1
        this._ids = {
            effectChanges: 1
        }

        this.data.visible = true
    }

    /** @param {ComputedEffectData} data */
    load(data) {
        if (!this.data || !this.data.id) {
            super.create(IDType.PROPERTY_COMPONENT)
            this.data.type = EntityType.COMPUTED_EFFECT
        }

        this._init()

        // keys from Effect
        this.data.elementId = data.elementId
        this.data.id = id(IDType.PROPERTY_COMPONENT)
        this.data.visible = data.visible
        this.data.effectId = data.effectId
        this.data.effectType = data.effectType || 'EFFECT'
        this.data.trackId = data.trackId
    }

    get effect() {
        if (!this.data.effectId) {
            return undefined
        }
        return this.dataStore.library.getEffect(this.data.effectId)
    }

    get element() {
        return this.dataStore.getById(this.data.elementId)
    }

    getData() {
        return {
            visible: this.data.visible
        }
    }

    /**
     * binds listeners for Effect & all its nested subcomponents change events
     */
    bindEffectChanges() {
        const effect = this.effect
        if (effect) {
            effect.on('CHANGES', this._effectChangesLn)
        }
    }

    /**
     * unbinds listeners for Effect & all its nested subcomponents change events
     */
    unbindEffectChanges() {
        const effect = this.effect
        if (effect) {
            effect.off('CHANGES', this._effectChangesLn)
        }
    }

    bindComputedEffectChange() {
        this.on('CHANGES', (changes, options) => {
            // propagate changes to Base/IM only when:
            // - no flags set
            // - in EDITING state
            // - not during undo/redo(should be handled by changes in UndoGroup)
            const { inUndo, inRedo } = this.dataStore.get('undo');
            if (
                options.flags ||
                (this.dataStore.get('state') !== 'EDITING' && this.dataStore.get('state') !== 'TABLING') ||
                (inUndo || inRedo)
            ) {
                return
            }

            if (this.dataStore.isDesignMode) {
                this._updateEffectProps(changes)
            } else {
                if (changes.has('mode')) {
                    this._updateEffectProps(new Map([['mode', { value: changes.get('mode').value }]]))
                }
                this._throttledUpdateIMEffectProps(changes)
            }
        })
    }

    sets(changes, options) {
        // Not allowed computedEffect add undo event
        super.sets(changes, {...options, undoable: false})
    }

    /**
     * Reset ComputedEffect values to match values in Effect
     */
    reset() {
        console.log('The system calls ComputedEffect but it is empty for now')
    }

    /**
     * Handler for EFFECT_CHANGES event fired by effect
     * Propagate changes Effect -> ComputedEffect
     * @private
     * @param  {PropChanges} changes
     */
    _handleEffectChanges(changes) {
        const allChanges = {}

        for (const [propName, change] of changes) {
            allChanges[propName] = change.after
        }

        // set `flags` (3rd parameter) to be id from EffectChanges; used in _handleChanges
        this.sets(allChanges, { flags: this.data.id })
    }

    /**
     * Propagate ComputedEffect changes to Effect
     * @private
     * @param {ChangesEvent} changes    CHANGES event object
     */
     _updateEffectProps(changes) {
        changes.forEach((data, key) => {
            this.dataStore.library.setEffect(this.get('effectId'), key, data.value)
        })
    }

    /**
     * set changes to IM effect property
     * @private
     * @param {ChangesEvent} changes  CHANGES event object
     */
    _updateIMEffectProps(changes) {
        const elementId = this.element.get('id')
        const effectId = this.get('effectId')

        for (const [key, change] of changes) {
            if (this.notAnimatableProps.has(key)) {
                continue
            }
            this.dataStore.interaction.setEffect(elementId, effectId, key, change.value, FrameType.EXPLICIT)
        }
    }
}

/** @typedef {import('../Setter').SetterData} SetterData */

/**
 * @typedef {SetterData} ComputedEffectData
 * @property {string} effectType
 * @property {string} effectId
 * @property {Effect} effect
 * @property {Element} element
 */
