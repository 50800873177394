import { useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router'

import { debounce } from 'lodash'

import {
  GetNormalFilesQueryVariables,
  Order_By as OrderBy,
  useGetNormalFilesQuery,
  useGetPersonalSharedFilesQuery,
  useGetProjectByIdQuery
} from '../../generated/graphql'
import { useArchiveFilesQuery } from '../../hooks/useWorkspace'
import { WorkspaceData } from '../../providers/WorkspaceContextProvider'
import { getWorkspaceDraftsPath } from '../../utils/pathGenerators'

export type SortOption = {
  name: string
  value: string
  orderBy: GetNormalFilesQueryVariables['orderBy']
}

export const SORT_OPTIONS: SortOption[] = [
  {
    name: 'sort_options.last_viewed',
    value: 'last_viewed',
    orderBy: { last_viewed: { last_viewed_at: OrderBy.Desc } }
  },
  { name: 'sort_options.last_modified', value: 'last_modified', orderBy: { updated_at: OrderBy.Desc } },
  { name: 'sort_options.created_at', value: 'created_at', orderBy: { created_at: OrderBy.Desc } },
  { name: 'sort_options.alphabetical', value: 'alphabetical', orderBy: { name: OrderBy.Asc } }
]

type useFileListProps = {
  isArchivePage: boolean
  isSharedPage: boolean
  isProjectOrDraftPage: boolean
  projectId: string
  workspaceData: WorkspaceData
}

const PAGINATION_LIMIT = 10

const useFileList = ({
  isProjectOrDraftPage,
  isArchivePage,
  isSharedPage,
  projectId,
  workspaceData
}: useFileListProps) => {
  const history = useHistory()

  const observerRef = useRef(null)

  const [searchQuery, setSearchQuery] = useState('')
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('')
  const [sortBy, setSortBy] = useState('last_viewed')
  const [limit, setLimit] = useState(PAGINATION_LIMIT)

  const selectedMenuOption = useMemo(() => SORT_OPTIONS.find((option) => option.value === sortBy), [sortBy])

  const { data: projectData, loading: isLoadingProject } = useGetProjectByIdQuery({
    variables: { id: projectId },
    skip: !projectId || !isProjectOrDraftPage,
    onError: () => history.replace(getWorkspaceDraftsPath(workspaceData.type, workspaceData.slug)),
    onCompleted(data) {
      // redirect deleted project to drafts
      if (data?.projects?.length === 0) history.replace(getWorkspaceDraftsPath(workspaceData.type, workspaceData.slug))
    }
  })

  const {
    data: filesData,
    loading: filesLoading,
    fetchMore: fetchMoreNormalFiles,
    refetch: refetchNormalFiles
  } = useGetNormalFilesQuery({
    variables: {
      projectId,
      orderBy: selectedMenuOption?.orderBy,
      offset: 0,
      limit
    },
    skip: !projectId || !isProjectOrDraftPage || projectData?.projects?.length === 0
  })

  const {
    data: archiveFilesData,
    loading: archiveFilesLoading,
    fetchMore: fetchMoreArchiveFiles,
    refetch: refetchArchiveFiles
  } = useArchiveFilesQuery({
    isPersonalWorkspace: workspaceData.isPersonal,
    workspaceId: workspaceData.id,
    isArchivePage,
    orderBy: selectedMenuOption?.orderBy,
    offset: 0,
    limit
  })

  const {
    data: sharedFilesData,
    loading: sharedFilesLoading,
    fetchMore: fetchMoreSharedFiles,
    refetch: refetchSharedFiles
  } = useGetPersonalSharedFilesQuery({
    variables: {
      collaboratorId: workspaceData.id,
      orderBy: selectedMenuOption?.orderBy,
      offset: 0,
      limit
    },
    skip: !isSharedPage
  })

  const files = useMemo(
    () => (isArchivePage ? archiveFilesData?.files : isSharedPage ? sharedFilesData?.files : filesData?.files) ?? [],
    [archiveFilesData?.files, filesData?.files, isArchivePage, isSharedPage, sharedFilesData?.files]
  )

  const fetchMore = useMemo(
    () => (isArchivePage ? fetchMoreArchiveFiles : isSharedPage ? fetchMoreSharedFiles : fetchMoreNormalFiles),
    [fetchMoreArchiveFiles, fetchMoreNormalFiles, fetchMoreSharedFiles, isArchivePage, isSharedPage]
  )

  const refetchFiles = useMemo(
    () => (isArchivePage ? refetchArchiveFiles : isSharedPage ? refetchSharedFiles : refetchNormalFiles),
    [isArchivePage, isSharedPage, refetchArchiveFiles, refetchNormalFiles, refetchSharedFiles]
  )

  const isLoadingFiles = isArchivePage ? archiveFilesLoading : isSharedPage ? sharedFilesLoading : filesLoading

  const filteredFiles = useMemo(() => {
    if (!debouncedSearchQuery) {
      return files
    }
    return files.filter((file) => file?.name?.toLowerCase().includes(debouncedSearchQuery.toLowerCase()))
  }, [debouncedSearchQuery, files])

  useEffect(() => {
    refetchFiles()
  }, [refetchFiles])

  useEffect(() => {
    const delayDebounceFn = debounce(() => {
      setDebouncedSearchQuery(searchQuery)
    }, 500)

    delayDebounceFn()

    return delayDebounceFn.cancel
  }, [searchQuery])

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          const currentLength = files.length
          fetchMore({
            variables: {
              offset: currentLength,
              limit: PAGINATION_LIMIT
            }
          }).then((fetchMoreResult) => {
            setLimit(currentLength + fetchMoreResult.data.files.length)
          })
        }
      },
      {
        threshold: 0,
        rootMargin: '500px 0px 0px 0px'
      }
    )
    const observerRefCurrent = observerRef.current
    if (observerRefCurrent) {
      observer.observe(observerRefCurrent)
    }

    return () => {
      if (observerRefCurrent) {
        observer.unobserve(observerRefCurrent)
      }
    }
  }, [fetchMore, files])

  return {
    observerRef,
    filteredFiles,
    hasFiles: files?.length !== 0,
    isLoadingFiles,
    isLoadingProject,
    projectName: projectData?.projects?.[0]?.name || '',
    searchQuery,
    setSearchQuery,
    setSortBy,
    sortBy
  }
}

export default useFileList
