import React from 'react'
import { useTranslation } from 'react-i18next'

import { IconButton } from '../../shared'

type AspectRatioLockProps = {
  aspectRatioLocked: boolean
  toggleAspectRatioLock: () => void
  className?: string
  disabled?: boolean
}

const AspectRatioLock = ({ aspectRatioLocked, toggleAspectRatioLock, className, disabled }: AspectRatioLockProps) => {
  const { t } = useTranslation('file', { keyPrefix: 'property_editor' })
  return (
    <div className={className}>
      <IconButton
        size="l"
        tip={t('ratio_lock')}
        data-test-id="ratio-lock-button"
        icon={aspectRatioLocked ? 'Link' : 'Unlink'}
        onClick={toggleAspectRatioLock}
        disabled={disabled}
      />
    </div>
  )
}

export default AspectRatioLock
