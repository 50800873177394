import PropertyStack from '../PropertyStack'

class ScaleYStack extends PropertyStack {
  constructor(elementStack, data) {
    super(elementStack, data)
    this.type = 'SCALE_Y'
    this.key = 'scaleY'
    this.dataKey = 'scaleY'
    this.animatableProperties = new Set(['scaleY'])
  }
}

export default ScaleYStack
