import { stringify } from 'query-string'

import { base64URLEncode, parseJwt, randomBuffer, sha256 } from '../utils/encode'

/* eslint-disable no-unused-vars */
export enum StorageEnum {
  CodeVerifier = 'codeVerifier',
  IdToken = 'id_token',
  AccessToken = 'access_token',
  RefreshToken = 'refresh_token',
  State = 'state'
}
/* eslint-enable no-unused-vars */

type CognitoConfig = {
  customUI: boolean
  serverUrl: string
  logoutUrl: string
  clientId: string
}

let _config: CognitoConfig
export const setCognitoConfig = (config: CognitoConfig) => {
  _config = config
}

export const getRandomString = () => {
  return base64URLEncode(randomBuffer())
}

export const createCodeChallenge = () => {
  const codeVerifier = getRandomString()
  localStorage.setItem(StorageEnum.CodeVerifier, codeVerifier)
  const codeChallenge = base64URLEncode(sha256(Buffer.from(codeVerifier)))
  return codeChallenge
}

export const createSate = () => {
  const state = getRandomString()
  localStorage.setItem(StorageEnum.State, state)
  return state
}

export const getState = () => {
  return localStorage.getItem(StorageEnum.State)
}

export const getAccessToken = () => {
  return localStorage.getItem(StorageEnum.AccessToken)
}

export const getIdToken = () => {
  return localStorage.getItem(StorageEnum.IdToken)
}

export const getRefreshToken = () => {
  return localStorage.getItem(StorageEnum.RefreshToken)
}

export const getCodeVerifier = () => {
  return localStorage.getItem(StorageEnum.CodeVerifier)
}

export const clearTokens = () => {
  localStorage.clear()
}

export const getTokenPayload = (token: string) => {
  return parseJwt(token)
}

export const isNearlyExpired = (exp: number) => {
  return exp * 1000 - Date.now() < 3600 * 1000
}

export const isLogin = () => {
  const idToken = getIdToken()
  const refreshToken = getRefreshToken()
  if (idToken && refreshToken) {
    return true
  }
  return false
}

export const getRedirectPath = (path: string) => {
  const { protocol, host } = window.location
  return `${protocol}//${host}${process.env.PUBLIC_URL}${path}`
}

const getCognitoPath = (path: string, params: object) => {
  return `${_config.serverUrl}${path}?${stringify(params)}`
}

export const getAuthorize = () => {
  const codeChallenge = createCodeChallenge()
  const state = createSate()

  const params = {
    response_type: 'code',
    client_id: _config.clientId,
    redirect_uri: getRedirectPath('/callback'),
    code_challenge_method: 'S256',
    code_challenge: codeChallenge,
    scope: 'openid email aws.cognito.signin.user.admin',
    state
  }

  const url = new URL(getCognitoPath('/oauth2/authorize', params))
  const searchParams = new URLSearchParams(window.location.search)
  const source = searchParams.get('source')
  if (source) {
    url.searchParams.append('source', source)
  }

  if (_config.customUI) {
    let encodedUrl = encodeURIComponent(window.location.href)
    if (source) encodedUrl += `&source=${source}`
    window.location.href = `${window.location.origin}/login?targetUrl=${encodedUrl}`
  } else {
    window.location.href = url.toString()
  }
}

export const getToken = async (code: string) => {
  const codeVerifier = localStorage.getItem(StorageEnum.CodeVerifier)
  const params = {
    grant_type: 'authorization_code',
    client_id: _config.clientId,
    code,
    code_verifier: codeVerifier,
    redirect_uri: getRedirectPath('/callback')
  }
  const url = getCognitoPath('/oauth2/token', params)
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
  if (response.status === 200) {
    const res = await response.json()
    localStorage.setItem(StorageEnum.IdToken, res.id_token)
    localStorage.setItem(StorageEnum.RefreshToken, res.refresh_token)
    localStorage.setItem(StorageEnum.AccessToken, res.access_token)
    localStorage.removeItem(StorageEnum.State)
    localStorage.removeItem(StorageEnum.CodeVerifier)
  } else {
    clearTokens()
  }
}

export const logout = () => {
  clearTokens()
  const params = {
    client_id: _config.clientId,
    logout_uri: _config.logoutUrl
  }
  const url = getCognitoPath('/logout', params)
  window.location.href = url
}

export const refreshToken = async () => {
  const refreshToken = getRefreshToken()
  const params = {
    grant_type: 'refresh_token',
    client_id: _config.clientId,
    refresh_token: refreshToken
  }
  const url = getCognitoPath('/oauth2/token', params)
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
  if (response.status === 200) {
    const res = await response.json()
    localStorage.setItem(StorageEnum.IdToken, res.id_token)
    localStorage.setItem(StorageEnum.AccessToken, res.access_token)
    return true
  }
  return false
}

export const getConnectGoolgeURL = () => {
  const codeChallenge = createCodeChallenge()
  const state = createSate()

  const params = {
    response_type: 'code',
    client_id: _config.clientId!,
    redirect_uri: getRedirectPath('/google/connect'),
    scope: 'openid',
    code_challenge_method: 'S256',
    code_challenge: codeChallenge,
    state,
    identity_provider: 'Google'
  }
  const url = getCognitoPath('/oauth2/authorize', params)
  return url
}
