import React, { useRef } from 'react'
import { LayerType } from '@phase-software/types'
import { useStroke } from '../../../../providers/dataStore/StrokeProvider'
import PaintPreview from '../PaintPreview'
import StrokeOpacity from './StrokeOpacity'
import StrokeWidth from './StrokeWidth'
import StrokeGrowDirection from './StrokeGrowDirection'
import StrokeAdvancedMenu from './StrokeAdvancedMenu'
import { ContainerWithRemoveLayer } from '../../ContainerWithRemoveLayer'
import PropertyRow from '../PropertyRow'

type StrokeProps = {
  id: string
  showBottomBorder?: boolean
  isFirst?: boolean
  isLast?: boolean
}

const Stroke = ({ id, showBottomBorder, isFirst, isLast }: StrokeProps) => {
  const propertyRowRef = useRef<HTMLDivElement | null>(null)
  const menuRef = useRef<HTMLButtonElement | null>(null)
  const { isNonBase } = useStroke((o) => o[id])

  return (
    <>
      <ContainerWithRemoveLayer isNonBase={isNonBase} layerItemId={id} dataTestId="border-layer">
        <PropertyRow className="pb-0" columns={[124, 64]} isFirst={isFirst}>
          <div data-test-id="border-color">
            <PaintPreview layerItemId={id} layerType={LayerType.STROKE} />
          </div>
          <div className="grid grid-flow-col items-center">
            <StrokeOpacity layerItemId={id} />
          </div>
        </PropertyRow>
      </ContainerWithRemoveLayer>

      <PropertyRow ref={propertyRowRef} columns={[124, 64]} isLast={isLast} showBottomBorder={showBottomBorder}>
        <div
          className="grid grid-flow-col gap-x-8 items-center"
          data-test-id="border-grow-direction"
          style={{ gridTemplateColumns: 'auto 1fr' }}
        >
          <StrokeAdvancedMenu layerItemId={id} menuRef={menuRef} />
          <StrokeGrowDirection layerItemId={id} />
        </div>
        <div className="grid grid-flow-col items-center">
          <StrokeWidth layerItemId={id} />
        </div>
      </PropertyRow>
    </>
  )
}

export default React.memo(Stroke)
