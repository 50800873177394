import { Rect2 } from '../math'

/** @typedef {import('@phase-software/data-store/src/Element').Element} Element */
/** @typedef {import('./VisualServer').VisualServer} VisualServer */
/** @typedef {import('./RenderItem').RenderItem} RenderItem */

/**
 * @typedef {object} SelectedItem
 * @property {string} id
 * @property {Element} element
 * @property {SceneNode} node
 */

/** @type {SelectedItem} */
const _tmp = {}

export class Selection {
    /**
     * @param {VisualServer} vs
     */
    constructor(vs) {
        this.vs = vs

        /** @type {Set<string>} */
        this._selected = new Set()
        this.bounds = new Rect2()

        this._dirtyBounds = true
    }

    get anySelected() {
        return this._selected.size > 0
    }

    /**
     * @returns {number} number of nodes in selection
     */
    get size() {
        return this._selected.size
    }

    get containsMultiple() {
        return this._selected.size > 1
    }

    /**
     * Returns selected node/element only if it is single. Otherwise returns null
     * @returns {SelectedItem|null}
     */
    get single() {
        if (this._selected.size === 1) {
            const id = this._selected.values().next().value
            this._assignToTmp(id)
            return _tmp
        }
        return null
    }

    /**
     * @returns {SelectedItem|null} Returns first element in selection. Or null if no elements are selected
     */
    get first() {
        if (this._selected.size > 0) {
            const id = this._selected.values().next().value
            this._assignToTmp(id)
            return _tmp
        }
        return null
    }

    /**
     * @param {(item: SelectedItem) => boolean} fn
     * @returns {boolean}
     */
    all(fn) {
        for (const item of this.iter()) {
            if (!fn(item)) {
                return false
            }
        }
        return true
    }


    *iter() {
        for (const id of this._selected) {
            this._assignToTmp(id)
            yield _tmp
        }
    }

    /**
     * @param {string} nodeId
     */
    markDirty(nodeId) {
        if (this._selected.has(nodeId)) {
            this._dirtyBounds = true
        }
    }

    updateBounds() {
        if (this._dirtyBounds) {
            this._dirtyBounds = false

            this.bounds.setFrom(this.vs._getWorldBoundsOf(this._selected))
        }
    }

    /** @param {string} id */
    _assignToTmp(id) {
        _tmp.id = id
        _tmp.element = this.vs.dataStore.getById(id)
        _tmp.node = this.vs.indexer.getNode(id)
    }

    watchDSSelection() {
        this.vs.dataStore.selection.on('SELECT', () => {
            this._selected.clear()
            for (const el of this.vs.dataStore.selection.get('elements')) {
                this._selected.add(el.get('id'))
            }
            this._dirtyBounds = true
        })
    }

    reset() {
        this._selected = new Set()
        this.bounds.set(0, 0, 0, 0)
        this._dirtyBounds = true

        return true
    }
}
