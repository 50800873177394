import { EntityType, EventFlag, GeometryType, PointShape } from '@phase-software/types'
import { EPSILON, Mesh, createPropTypes, EntityChange, Vector2, getRectangleMesh, getEllipseMesh, FlagsEnum } from '@phase-software/data-utils'
import { Setter } from '../Setter'

/** @typedef {import('../DataStore').DataStore} DataStore */
/** @typedef {import('../Setter').SetterData} SetterData */
/** @typedef {import('@phase-software/data-utils/src/mesh/Vertex').Vertex} Vertex */

const PROP_TYPES = createPropTypes({
    mesh: { type: Mesh }
})

/**
 * Geometry Class
 */
export class Geometry extends Setter {
    /**
     *
     * @param {DataStore} dataStore
     * @param {GeometryData} [data]
     */
    constructor(dataStore, data) {
        super(dataStore, data)
        this.refireHandler = this._refire.bind(this)
        this.forceUpdateHandler = this._forceUpdate.bind(this)
        this._inEditing = false
        this._bindMeshChanges()
        /** @type {Vector2} */
        this.originInPercent = new Vector2()
    }

    _bindMeshChanges() {
        if (this.data.mesh) {
            const meshListeners = this.data.mesh.listeners('MESH_CHANGES');
            // Check if the callback is already in the list of listeners
            if (!meshListeners || !meshListeners.includes(this.refireHandler)) {
                this.data.mesh.on('MESH_CHANGES', this.refireHandler)
            }
            // Should remove this event
            const vectorListeners = this.data.mesh.listeners('TRIGGER_VECTOR_FORCE_UPDATE');
            if (!vectorListeners || !vectorListeners.includes(this.forceUpdateHandler)) {
                this.data.mesh.on('TRIGGER_VECTOR_FORCE_UPDATE', this.forceUpdateHandler)
            }
        }
    }

    _unbindMeshChanges() {
        if (this.data.mesh) {
            this.data.mesh.off('MESH_CHANGES', this.refireHandler)
            this.data.mesh.off('TRIGGER_VECTOR_FORCE_UPDATE', this.forceUpdateHandler)
        }
    }

    clone() {
        const obj = super.clone()
        obj.data.geometryType = this.data.geometryType

        if (this.data.mesh) {
            // FIXME: should not use basePath, the mesh should be the base data
            obj.propTypes = PROP_TYPES
            const mesh = this.data.mesh.copy(false, this.liftupParent.basePath)
            mesh.bounds.copy(this.data.mesh.bounds)
            obj.data.mesh = mesh

        }
        return obj
    }

    /**
     * creates a blank Geometry
     * @protected
     */
    create() {
        super.create()
        this.data.type = EntityType.GEOMETRY
        this.data.geometryType = GeometryType.RECTANGLE
    }

    save() {
        const data = super.save()
        data.geometryType = this.data.geometryType
        if (
            this.data.geometryType === GeometryType.POLYGON ||
            this.data.geometryType === GeometryType.LINE
        ) {
            // FIXME: should not use basePath, the mesh should be the base data
            data.mesh = this.data.mesh.save(this.liftupParent.basePath)
        }
        return data
    }

    /** @param {GeometryData} data */
    load(data) {
        super.load(data)
        this.data.type = EntityType.GEOMETRY
        this.data.geometryType = data.geometryType || GeometryType.RECTANGLE
        if (
            this.data.geometryType === GeometryType.POLYGON ||
            this.data.geometryType === GeometryType.LINE
        ) {
            this.data.mesh = Mesh.fromData(data.mesh)
            this.set('geometryType', this.data.mesh.isCollinear ? GeometryType.LINE : GeometryType.POLYGON, { undoable: false, fire: false })
            this.propTypes = PROP_TYPES
            // FIXME: Should not add additional event for first point
            // STR: Redo path editing when there is no point will crash
            if (this.dataStore.isLoaded) {
                this.data.mesh.changes.CREATE.add([...this.data.mesh.vertices][0].id)
                this.data.mesh.applyChanges(this.data.mesh.changes)
                this.dataStore.addUndo(this.data.mesh, this.data.mesh.eventName, this.data.mesh.changes)
                this.data.mesh.renewChanges()
            }
        }
    }

    /**
     * @param {GeometryType} newGeometryType
     * @param {object} [options]
     * @param {boolean} [options.fire=true] set to false to not fire events (won't add it to Undo either)
     * @param {boolean} [options.undoable=true] set to false to not add event to Undo
     * @param {boolean} [options.force=false] set to true to allow setting read-only properties or same value
     * @param {any} [options.flags=undefined] if defined, will be emitted as extra `flags` argument of CHANGES event
     */
    setGeometryType(
        newGeometryType, {
            fire = true,
            undoable = true,
            force = false,
            flags = undefined
        } = { fire: true, undoable: true, force: false, flags: undefined }
    ) {
        if (this.data.geometryType === newGeometryType) return

        if (this.data.geometryType === undefined) {
            this.set('geometryType', newGeometryType, { fire, undoable, force, flags })
            return
        }

        if (newGeometryType !== GeometryType.POLYGON) {
            console.error('Only allow to convert to polygon')
            return
        }

        this._unbindMeshChanges()
        const element = this.liftupParent
        // Create the mesh
        switch (this.data.geometryType) {
            case GeometryType.RECTANGLE: {
                const cornerRadius = element.get('cornerRadius')
                this.data.mesh = getRectangleMesh(0, 0, element.get('size'), cornerRadius)
                if (cornerRadius && cornerRadius.length === 4) {
                    element.set('cornerRadius', 0)
                }
            }
                break
            case GeometryType.ELLIPSE:
                this.data.mesh = getEllipseMesh(0, 0, element.get('size'))
                break
            default:
                console.error(`Unexpected geometry type ${this.data.geometryType}`)
                return
        }
        this._bindMeshChanges()

        this.set('geometryType', newGeometryType, { fire, undoable, force })
        this.propTypes = PROP_TYPES
    }

    /**
     * 
     * @param {Map<string, object>} vertexId2Change
     * @param {boolean} [undoable]
     */
    setVertex(vertexId2Change, { undoable = true, delta = false } = {}) {
        if (!this._isPolygonOrLine()) {
            console.error('Only allow to set vertex properties on polygon')
            return
        }

        const mesh = this.data.mesh

        const worldPointBound = this.dataStore.drawInfo.getVerticesBoundWorld(this.liftupParent)
        /** Don't let mesh apply and fire changes */
        const disppearVertices = this._processVerticesChanges(mesh, vertexId2Change, worldPointBound, delta)
        // --------------------------------------
        mesh.applyChanges(mesh.changes)
        mesh.fire(undoable)

        if (disppearVertices.length) {
            this.dataStore.selection.removeVertices(disppearVertices)
        }
    }

    _isPolygonOrLine() {
        const geometryType = this.data.geometryType
        return geometryType === GeometryType.POLYGON || geometryType === GeometryType.LINE
    }

    /**
     * Processes the changes for a set of vertices, constructs the required changes, and stores them, but doesn't apply the updates immediately.
     * 
     * @param {Mesh} mesh - The mesh object containing the vertices to be processed.
     * @param {Map<string, object>} vertexId2Change - A map of vertex IDs to the respective changes for each vertex.
     * @param {Vector2} worldBoundingbox - The current world bounding of all points in the mesh
     * @param {boolean} delta - Indicates whether the positional changes are absolute or relative to the current position.
     * 
     * @returns {Vertex[]}
     * 
     * Notes:
     * - The function iterates over the provided vertices and their associated changes.
     * - If a change pertains to the position of a vertex, it updates the vertex's position and adds the vertex to the list of 'moving' vertices.
     * - If a vertex has a 'mirror' change to the `PointShape.NONE` value and is flagged as a curve vertex, it is considered a 'disappearing' vertex.
     * - All other changes are treated as property updates for the vertices and are prepared using the `prepareVertexPropertyChanges` method of the mesh.
     * - Changes are constructed and stored in the mesh's changes object, but they aren't applied immediately. To apply the changes after this function execution, 
     *   use a separate function like `this.applyChanges(this.changes)`.
     * - At the end of the function:
     *   1. Any 'moving' vertices may have their associated point shapes changed.
     *   2. Any 'disappearing' vertices are removed from the selection.
     * 
     * @throws {Error} - Logs an error if a vertex is not found in the mesh's cell table.
     */
    _processVerticesChanges(mesh, vertexId2Change, worldBoundingbox, delta) {
        const movingVertices = []
        const disppearVertices = []
        for (const [vertexId, changes] of vertexId2Change) {
            const vertex = mesh.cellTable.get(vertexId)
            if (!vertex) {
                // console.error(`Vertex ${vertexId} not found`)
                continue
            }

            for (const key in changes) {
                if (this._isPositionKey(key)) {
                    this._updateVertexPosition(mesh, vertex, key, changes, worldBoundingbox, delta)
                    movingVertices.push(vertex)
                } else {
                    if (key === 'mirror' && changes[key] === PointShape.NONE && vertex.isFlagged(FlagsEnum.CURVE_VERT)) {
                        disppearVertices.push(vertex)
                    }
                    mesh.prepareVertexPropertyChanges(vertex.id, key, changes[key])
                }
            }
        }
        mesh._changePointShapesWhenMoving(movingVertices)

        return disppearVertices
    }

    _isPositionKey(key) {
        return POSITION_KEY_IN_CHANGE.has(key)
    }
    /**
     * Updates the position of a vertex based on the specified changes.
     * 
     * @param {Mesh} mesh - The mesh object containing the vertex to be updated.
     * @param {Vertex} vertex - The target vertex whose position needs to be updated.
     * @param {string} key - The key indicating the type of positional update ('position', 'x', or 'y').
     * @param {object} changes - An object containing positional updates for the vertex.
     * @param {Vector2} worldPointBound - The current bounds of all points in the mesh or associated object.
     * @param {boolean} delta - Indicates whether the changes are absolute or relative to the current position.
     * 
     * @returns {void}
     */
    _updateVertexPosition(mesh, vertex, key, changes, worldPointBound, delta) {
        if (!this.liftupParent) {
            return
        }
        const elementId = this.liftupParent.get('id')
        const newPos = new Vector2()

        const worldCurrPos = this.dataStore.drawInfo.vertex2World(elementId, new Vector2(vertex.pos))
        worldCurrPos.x -= worldPointBound.x
        worldCurrPos.y -= worldPointBound.y
        if (key === 'position') {
            newPos[0] = changes[key][0]
            newPos[1] = changes[key][1]
        } else if (key === 'x') {
            const base = delta ? worldCurrPos.x : 0
            newPos[0] = base + changes[key]
            newPos[1] = worldCurrPos.y
        } else if (key === 'y') {
            const base = delta ? worldCurrPos.y : 0
            newPos[0] = worldCurrPos.x
            newPos[1] = base + changes[key]
        }
        newPos[0] += worldPointBound.x
        newPos[1] += worldPointBound.y
        const worldNewPos = this.dataStore.drawInfo.world2Vertex(elementId, newPos)
        mesh.situateVertexToPos(vertex, worldNewPos[0], worldNewPos[1])
    }

    undo(type, changes) {
        switch (type) {
            case 'MESH_CHANGES':
                this.data.mesh.undo(changes)
                return
        }
        super.undo(type, changes)
    }

    redo(type, changes) {
        switch (type) {
            case 'MESH_CHANGES':
                this.data.mesh.redo(changes)
                return
        }
        super.redo(type, changes)
    }

    /**
     * @param {string} type   event type
     * @param {*} original
     * @param {*} current
     * @returns {bool}    true if handled; false otherwise
     */
    combineUndo(type, original, current) {
        switch (type) {
            case 'MESH_CHANGES':
                return this.data.mesh.combineUndo(type, original, current)
        }
        return super.combineUndo(type, original, current)
    }

    /**
     * @todo Ensure that the 'undoable' trigger emits 'options' when the action it fires is not undoable
     * @param {object} changes
     * @param {object} options default to using { undoable: true, interaction: true } as options.
     * This typically occurs when the change originates from 'undoable.js'. I dare to use using default because all mesh fire is undable
     * @returns {void}
     */
    _refire(changes, options = { undoable: true, interaction: true }) {
        if (!(changes instanceof EntityChange)) {
            console.warn('Polygon receiving wrong changes')
            return
        }

        if (options.undoable) {
            this._setElementPropByMesh(this.data.mesh, this.liftupParent)
        }

        this.fire('MESH_CHANGES', changes, options)
    }

    _forceUpdate() {
        this._setElementPropByMesh(this.data.mesh, this.liftupParent, true)
        if (this.liftupParent) {
            this.liftupParent.emit('TRIGGER_VECTOR_FORCE_UPDATE')
        }
    }

    startEditing() {
        this._inEditing = true
        const element = this.liftupParent
        const referencePoint = element.get('referencePoint')
        const contentAnchor = element.get('contentAnchor')
        const size = element.get('size')
        this.originInPercent.x = Math.abs(size[0]) > EPSILON ? (referencePoint[0] + contentAnchor[0]) / size[0] : 0.5
        this.originInPercent.y = Math.abs(size[1]) > EPSILON ? (referencePoint[1] + contentAnchor[1]) / size[1] : 0.5
    }

    stopEditing() {
        this._inEditing = false
        this.set('geometryType', this.data.mesh.isCollinear ? GeometryType.LINE : GeometryType.POLYGON, { undoable: true, fire: true })

        // FIXME: Should not do this when user leave path editing mode.
        //        CG bounds should handled by the path bounds changes.
        //        Remove this part once we fixed the bounds in Container.recalculateBounds.
        const parent = this.dataStore.getParentOf(this.liftupParent)
        if (parent && parent.isComputedGroup) {
            parent.recalculateBounds()
        }

        const { inUndo } = this.dataStore.get('undo')
        if (this.dataStore.isDesignMode && !inUndo) {

            const element = this.liftupParent
            const size = element.get('size')
            const referencePoint = element.get('referencePoint')
            const changes = {
                contentAnchorX: size[0] * this.originInPercent.x - referencePoint[0],
                contentAnchorY: size[1] * this.originInPercent.y - referencePoint[1]
            }
            const translate = this.dataStore.drawInfo.getFixedPositionByChanges(element.get('id'), changes)
            changes.translateX = translate[0]
            changes.translateY = translate[1]
            element.sets(changes)
        }
    }

    /**
     * @param {Mesh} mesh
     * @param {Element} element
     * @param {boolean} playAnimation
     */
    _setElementPropByMesh(mesh, element, playAnimation = false) {
        mesh.recalculateBounds()
        if (mesh.bounds.isInfinite) {
            return
        }

        const changes = { size: [mesh.bounds.width, mesh.bounds.height] }

        changes.referencePointX = - mesh.bounds.position.x
        changes.referencePointY = - mesh.bounds.position.y

        element.sets(changes, { interaction: false, flags: EventFlag.FROM_MESH_CHANGE, undoable: !playAnimation })
    }
}

/** @typedef {('RECTANGLE' | 'ELLIPSE' | 'POLYGON')} GeometryType */

/**
 * @typedef {SetterData} GeometryData
 * @property {GeometryType} geometryType
 */

/**
 * @event Geometry#update
 * @type {object}
 * @property {Geometry} Geometry
 * @property {string} key of variable updated
 * @property {*} value
 * @property {*} old value
 * fires an update whenever a parameter is updated
 */

const POSITION_KEY_IN_CHANGE = new Set(['position', 'x', 'y'])
