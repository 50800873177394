import { useEffect } from 'react'

import { useFeatureValue } from '@growthbook/growthbook-react'

import { FEATURE_KEYS } from '../../growthbook-feature-keys'
import { useProfile } from '../../providers/ProfileProvider'
import { addUserProperties, identify, load } from '../../services/heapAnalytics'

const HeapAnalytics = () => {
  const config = useFeatureValue(FEATURE_KEYS.WEB_CONFIG)
  const appId = config?.heap?.appId
  const profile = useProfile()
  const userId = profile?.id || null
  const email = profile?.email || null

  useEffect(() => {
    if (appId) {
      load(appId)
    }
  }, [appId])

  useEffect(() => {
    if (userId) {
      identify(userId)
    }
    // after sign-in always landing to personal page, no teamNameX for now
    addUserProperties({ Email: email, UserID: userId })
  }, [email, userId])
  return null
}

export default HeapAnalytics
