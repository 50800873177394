import { schema } from 'normalizr'
import { createProvider, useEntity } from '../utils'

export const TextSpacing = new schema.Entity(
  'textSpacings',
  { data: {} },
  {
    processStrategy: (value, parent) => {
      return { ...value, styleId: parent.id }
    }
  }
)

const defaultValue = {}
const [Provider, useSelectState, useSetState] = createProvider('TextSpacing', defaultValue)

export const useTextSpacing = useSelectState

export const useSetTextSpacing = () => {
  const setState = useSetState()
  const [
    setTextSpacing,
    addTextSpacing,
    updateTextSpacing,
    removeTextSpacing,
    mergeTextSpacing,
    reduceTextSpacing,
    createTextSpacing,
    destroyTextSpacing
  ] = useEntity(TextSpacing, setState)

  return {
    setTextSpacing,
    addTextSpacing,
    updateTextSpacing,
    removeTextSpacing,
    mergeTextSpacing,
    reduceTextSpacing,
    createTextSpacing,
    destroyTextSpacing
  }
}

export default Provider
