import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useSetModal } from '../../../../providers/ModalProvider'
import { useEditorActions } from '../../../../providers/dataStore/EditorProvider'
import { usePaint } from '../../../../providers/dataStore/PaintProvider'
import Text from '../../../shared/Text'
import PropertyState from '../PropertyState'
import { getPaintPreviewText } from './utils'

type PaintPreviewTextProps = {
  layerItemId: string
  layerType: number
  disabled?: boolean
}

const PaintPreviewText = ({ layerItemId, layerType, disabled }: PaintPreviewTextProps) => {
  const { paintType, color } = usePaint((o) => o[layerItemId])
  const { t } = useTranslation('file', { keyPrefix: 'paint_type' })
  const text = t(getPaintPreviewText({ paintType, color }))

  const [hoverText, setHoverText] = useState(false)
  const [hoverWrap, setHoverWrap] = useState(false)

  const ref = useRef<HTMLButtonElement>(null)
  const { toggleModal, updateModalData } = useSetModal(`PaintModal-${layerItemId}`)
  const { setLayerFocused, toggleLayerVisible } = useEditorActions()

  const handleTriggerItemClick = () => {
    toggleModal({ layerItemId, layerType, paintType, trigger: ref })
    toggleLayerVisible(layerItemId, true)
    setLayerFocused(layerItemId, true)
  }

  useEffect(() => {
    updateModalData({ paintType })
  }, [paintType, updateModalData])

  const handleMouseTextEnter = useCallback(() => {
    setHoverText(true)
  }, [])

  const handleMouseTextLeave = useCallback(() => {
    setHoverText(false)
  }, [])

  const handleMouseWrapEnter = useCallback(() => {
    setHoverWrap(true)
  }, [])

  const handleMouseWrapLeave = useCallback(() => {
    setHoverWrap(false)
  }, [])

  const disabledClassName =
    'disabled:opacity-40 disabled:cursor-not-allowed disabled:outline-none disabled:bg-light-overlay-5'
  const styleClassName =
    'hover:bg-light-overlay-10 hover:outline-light-overlay-20-1-offset--1 active:bg-light-overlay-3'
  const highlightClassName = 'highlight-border-rounded-md'

  return (
    <button
      className={`flex justify-between items-center w-full text-left text-white cursor-pointer h-28 rounded-md bg-light-overlay-5 py-6 pl-8 ${highlightClassName} ${styleClassName} ${disabledClassName}`}
      ref={ref}
      onClick={handleTriggerItemClick}
      onMouseEnter={handleMouseWrapEnter}
      onMouseLeave={handleMouseWrapLeave}
      disabled={disabled}
      data-test-id="paint-preview-text"
    >
      <Text
        ellipsis={false}
        className="text-white"
        onMouseEnter={handleMouseTextEnter}
        onMouseLeave={handleMouseTextLeave}
      >
        {text}
      </Text>
      <div className="p-6">
        <PropertyState
          name="paint"
          layerItemId={layerItemId}
          layerType={layerType}
          hoverContent={hoverText}
          hoverWrap={hoverWrap}
        />
      </div>
    </button>
  )
}

export default PaintPreviewText
