import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useUI, useUIActions } from '../../providers/dataStore/UIProvider'
import { ToolButton } from '../shared'
import { MenuListOptionProps, MenuOptionProps } from '../shared/Menu/Menu.types'
import { isSeparatorOption } from '../shared/Menu/utils'

const options: MenuListOptionProps[] = [
  { name: 'zoom_in', value: 'ZOOM_IN', shortcut: '＋' },
  { name: 'zoom_out', value: 'ZOOM_OUT', shortcut: '－' },
  { name: 'zoom_to_fit', value: 'ZOOM_TO_FIT', shortcut: '⌘1' },
  { name: 'zoom_to_selection', value: 'ZOOM_TO_SELECTION', shortcut: '⌘2' },
  '-',
  { name: 'zoom_to_value', value: '200' },
  { name: 'zoom_to_value', value: '100', shortcut: '⌘0' },
  { name: 'zoom_to_value', value: '50' }
]

const Zoom = () => {
  const { zoom, isEditingState } = useUI()
  const { changeZoom, zoomIn, zoomOut, zoomToFit, zoomToSelection } = useUIActions()
  const { t } = useTranslation('file', { keyPrefix: 'zoom_options' })

  const menuOptions = useMemo(
    () =>
      options
        .map((option) => {
          if (isSeparatorOption(option)) return option
          return { ...option, name: t(option.name ?? '', { value: option.value }) }
        })
        .filter((option) => {
          if (isSeparatorOption(option)) return true
          return isEditingState ? true : option.value !== 'ZOOM_TO_SELECTION'
        }),
    [t, isEditingState]
  )

  const handleChange = (value: MenuOptionProps['value']) => {
    switch (value) {
      case '50':
      case '100':
      case '200':
        changeZoom(Number(value) / 100)
        break
      case 'ZOOM_IN':
        zoomIn()
        break
      case 'ZOOM_OUT':
        zoomOut()
        break
      case 'ZOOM_TO_FIT':
        zoomToFit()
        break
      case 'ZOOM_TO_SELECTION':
        zoomToSelection()
        break
    }
  }
  return (
    <ToolButton
      className="justify-center !w-72"
      options={menuOptions}
      onChange={handleChange}
      tooltip={t('zoom')}
      shouldOpenDropdown
      value={zoom}
      renderSelectedOption={() => (
        <div className="h-24 flex text-12 font-medium items-center">{Math.round(zoom * 100)}%</div>
      )}
    />
  )
}

export default Zoom
