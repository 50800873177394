import { PaintType } from '@phase-software/types'

import { rgb2HexString } from '../../../../utils/color'

type PaintAttributes = {
  paintType: PaintType
  color: number[]
}

const PAINT_PREVIEW_TEXT = {
  [PaintType.IMAGE]: 'image',
  [PaintType.GRADIENT_LINEAR]: 'linear',
  [PaintType.GRADIENT_RADIAL]: 'radial',
  [PaintType.GRADIENT_ANGULAR]: 'angular',
  [PaintType.GRADIENT_DIAMOND]: 'diamond'
}

export const getPaintPreviewText = ({ paintType, color }: PaintAttributes) => {
  if (paintType === PaintType.SOLID) {
    return rgb2HexString(color, false).toUpperCase()
  }

  return PAINT_PREVIEW_TEXT[paintType] || ''
}
