import React from 'react'

export type RectangleKnobProps = { children?: React.ReactNode; focus?: boolean; className?: string }

const RectangleKnob = React.forwardRef<HTMLDivElement, RectangleKnobProps>(
  ({ children = null, className = '' }, ref) => (
    <div
      ref={ref}
      className={`absolute m-auto w-4 h-16 top-0 bottom-0 cursor-pointer pointer-events-none bg-white rounded-xs ${className}`}
    >
      {children}
    </div>
  )
)

RectangleKnob.displayName = 'RectangleKnob'

export default RectangleKnob
