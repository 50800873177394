import { EntityType, ElementType } from '@phase-software/types'
// import { getStyleData } from './Style'
import { getGeometryData } from './Geometry'

export const getElementData = (element, lazy = false) => {
  if (lazy) {
    return element.get('id')
  }
  const data = element.gets('elementType', 'id', 'locked', 'name', 'visible')

  if (data.elementType === ElementType.SCREEN) {
    data.parentId = null
  } else {
    data.parentId = element.get('parent').get('id')
  }

  if (data.elementType === ElementType.PATH) {
    data.geometry = getGeometryData(element.get('geometry'))
  }

  if (element.children) {
    data.expanded = element.get('expanded')
    data.children = element.children.map((o) => getElementData(o))
  }
  if (data.elementType === ElementType.CONTAINER && data.type !== EntityType.WORKSPACE) {
    data.booleanType = element.get('booleanType')
    data.isMask = element.get('isMask')
    data.containerType = element.get('containerType')
  }

  return data
}
