import { getConditionData } from './Condition'
import { getElementTrackData } from './ElementTrack'

export const getSequenceData = (sequence, lazy, nested = false) => {
  if (lazy) {
    return sequence.get('id')
  }
  const data = sequence.gets('id', 'conditionGroupName', 'conditions', 'elementTracks')
  data.elementTracks = data.elementTracks.map((o) => getElementTrackData(o, !nested))
  data.conditions = data.conditions.map((o) => getConditionData(o, !nested))
  return data
}
