/**
 * format patch params and add updateMask
 */
export function formatPatchParams<T extends { [P in keyof T]: T[P] }>(params: T): T & { updateMask: any[] } {
  const paramKeys = Object.keys(params).filter((key) => params[key as keyof T] !== undefined) as Array<keyof T>
  paramKeys.forEach((key) => {
    if (params[key] === '') {
      params[key] = null as T[keyof T]
    }
  })
  return { ...params, updateMask: paramKeys }
}
