import React from 'react'
import { useTranslation } from 'react-i18next'

import { Empty } from '../../../../components/shared'
import noResultIllustration from './no-results-found.svg'

const SearchNoResultState = () => {
  const { t } = useTranslation('workspace', { keyPrefix: 'empty_list' })
  return (
    <Empty
      title={t('no_result_found')}
      description={t('no_result_found_description')}
      image={{ src: noResultIllustration, width: 320, height: 200 }}
    />
  )
}

export default SearchNoResultState
