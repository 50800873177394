import { createContext } from 'react'

export type SizeContextProp = {
  width: number
  scale: number
  duration: number
  maxScale: number
}
export const SizeContext = createContext<SizeContextProp>({ width: 0, scale: 1, duration: 0, maxScale: 1 })

export type SizeActionContextProp = {
  setWidth: (width: number) => void
  setScale: (scale: number) => void
  setDuration: (duration: number) => void
}
export const SizeActionContext = createContext<SizeActionContextProp>({
  setWidth: () => {},
  setScale: () => {},
  setDuration: () => {}
})
