import EventEmitter from 'eventemitter3'
import { ChangeType } from '@phase-software/types'
import {
    CHANGE_TYPE_MAP,
    PropChange,
    EntityChange,
    reverseEntityChange,
    reversePropChange,
    combineEntityChange,
    combinePropChange
} from './Changes'

/**
 * @typedef {object} Undoer
 * @property {(owner: any, type: string, changes: any) => void} addUndo
 */


export class Undoable extends EventEmitter {

    /**
     * @param {Undoer} undoer
     * @param {ChangeType} changeType
     * @param {string} eventName
     */
    constructor(undoer, changeType, eventName) {
        super()
        this.undoer = undoer
        this.changeType = changeType
        this.eventName = eventName || 'CHANGES'
        this.undoEvents = new Set([this.eventName])
        this.changes = new CHANGE_TYPE_MAP[this.changeType]()
    }

    /**
     * Fire events with data changes
     * @param {Changes} changes
     * @param {object} options
     */
    emit(changes, options) {
        super.emit(this.eventName, changes, options)
    }

    /**
     * Fire changes event and add to undo
     * @param {boolean} [undoable=true]
     * @param {object} options
     */
    fire(undoable = true, options) {
        if (this.changes.isEmpty()) {
            return
        }
        this.emit(this.changes, options)
        // Need this because Mesh is using Undoable interface but not providing dataStore
        if (undoable && this.undoer && this.undoer.addUndo) {
            this.undoer.addUndo(this, this.eventName, this.changes)
        }
        this.changes = new CHANGE_TYPE_MAP[this.changeType]()
    }

    /**
     * Renew changes without doing anything
     */
    renewChanges() {
        if (this.changes.isEmpty()) {
            return
        }
        this.changes = new CHANGE_TYPE_MAP[this.changeType]()
    }

    /**
     * Undo changes
     * @param {Changes} changes
     */
    undo(changes) {
        if (changes instanceof PropChange) {
            this.emit(reversePropChange(changes))
        }
        if (changes instanceof EntityChange) {
            this.emit(reverseEntityChange(changes))
        }
    }

    /**
     * Redo changes
     * @param {Changes} changes
     */
    redo(changes, options) {
        if (changes instanceof PropChange) {
            this.emit(changes, options)
        }
        if (changes instanceof EntityChange) {
            this.emit(changes, options)
        }
    }

    /**
     *
     * @param {string} eventName event name
     * @param {PropChange | EntityChange} original   original changes object
     * @param {PropChange | EntityChange} current    new chnages object
     * @returns {bool}      true if handled; false otherwise
     */
    combineUndo(eventName, original, current) {
        if (original.type !== current.type) {
            return false
        }
        switch(original.type) {
            case ChangeType.ENTITY:
                return combineEntityChange(original, current)
            case ChangeType.PROPERTY:
                return combinePropChange(original, current)
        }
        return false
    }
}
