import React, { forwardRef } from 'react'

import PropTypes from 'prop-types'

import Icon from '../Icon'
import SelectText from './SelectText'

const FlatSelect = forwardRef(
  (
    {
      className,
      size,
      selected,
      disabled,
      selectTextRender,
      caret,
      mixed,
      inline,
      alignRight,
      // eslint-disable-next-line react/prop-types, @typescript-eslint/no-unused-vars
      onChangeSearch,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      showSearch,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      openMenu,
      hideFocusStyle,
      dataTestId,
      ...rest
    },
    forwardRef
  ) => {
    const SelectText = selectTextRender
    const highlightBorderClassName = disabled ? '' : `highlight-border-rounded-md`
    const selectClassName = disabled ? 'opacity-40 cursor-not-allowed' : 'active:text-white'
    const iconClassName = disabled
      ? ''
      : 'group-hover:text-white group-focus-hover:text-white group-active:text-light-overlay-40 active:text-light-overlay-40'
    const inlineClassName = inline ? 'inline-flex w-auto' : 'flex w-full'
    const sizeClassName = size === 'l' ? 'h-32 rounded-md' : 'h-28 rounded-md'
    const alignRightClassName = alignRight ? 'justify-end' : ''
    const hideFocusStyleClassName = hideFocusStyle ? '' : highlightBorderClassName
    const normalClassName = 'group items-center py-4 text-12 text-white'
    return (
      <button
        ref={forwardRef}
        disabled={disabled}
        className={`input-system-handle-event ${normalClassName} ${inlineClassName} ${sizeClassName} ${alignRightClassName} ${hideFocusStyleClassName} ${selectClassName} ${className}`}
        data-test-id={dataTestId}
        {...rest}
      >
        <SelectText selected={selected} mixed={mixed} />
        {caret && (
          <Icon
            className={`flex items-center justify-center ml-4 text-light-overlay-60 ${iconClassName}`}
            name="Angle"
            size="s"
            interactive={false}
            useCurrentColor
          />
        )}
      </button>
    )
  }
)
FlatSelect.displayName = 'FlatSelect'
FlatSelect.propTypes = {
  alignRight: PropTypes.bool,
  size: PropTypes.oneOf(['s', 'l']),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
  mixed: PropTypes.bool,
  selected: PropTypes.object,
  selectTextRender: PropTypes.func,
  caret: PropTypes.bool,
  showSearch: PropTypes.bool,
  openMenu: PropTypes.bool,
  hideFocusStyle: PropTypes.bool,
  dataTestId: PropTypes.string
}
FlatSelect.defaultProps = {
  className: '',
  caret: true,
  selectTextRender: SelectText
}

export default FlatSelect
