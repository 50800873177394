import { createHash, randomBytes } from 'crypto'

export const base64URLDecode = (str: string) => {
  return Buffer.from(str, 'base64')
}

export const base64URLEncode = (str: Buffer): string => {
  return str.toString('base64').replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '')
}

export const sha256 = (buffer: Buffer): Buffer => {
  return createHash('sha256').update(buffer).digest()
}

export const randomBuffer = () => randomBytes(64)

export const parseJwt = (token: string | null) => {
  if (!token) {
    return {}
  }
  try {
    return JSON.parse(base64URLDecode(token.split('.')[1]).toString())
  } catch (e) {
    return {}
  }
}
