import { BlendMode, FillRule, MaskMode, MaskType, ShapeType } from './enums';
import { calculateNewPoint, handleBlendMode, handleMaskMode, useId, } from '.';
export const addEffect = ({ attributes, effect: { np, type }, effects, }) => {
    if (type === 29) {
        for (const e of attributes.blur) {
            effects.push({
                np,
                ty: type,
                nm: 'Effect',
                en: 1,
                ef: [
                    {
                        ty: 0,
                        v: {
                            a: 0,
                            k: e.value
                        }
                    },
                    {
                        ty: 0,
                        v: {
                            k: 1,
                            a: 0
                        }
                    },
                    {
                        ty: 4,
                        v: {
                            k: 0,
                            a: 0
                        }
                    }
                ]
            });
        }
    }
    if (type === 25) {
        for (const s of attributes.shadow) {
            if (s.inner
                || effects.some(({ nm }) => nm === s.id)) {
                continue;
            }
            effects.push({
                np,
                ty: type,
                nm: s.id,
                en: 1,
                ef: [
                    {
                        ty: 2,
                        v: {
                            a: 0,
                            k: s.color
                        }
                    },
                    {
                        ty: 0,
                        v: {
                            a: 0,
                            k: s.opacity
                        }
                    },
                    {
                        ty: 1,
                        v: {
                            a: 0,
                            k: s.angle
                        }
                    },
                    {
                        ty: 1,
                        v: {
                            a: 0,
                            k: s.distance
                        }
                    },
                    {
                        ty: 1,
                        v: {
                            a: 0,
                            k: s.blur
                        }
                    }
                ]
            });
        }
        if (attributes.shadow.some(s => !s.inner)
            && !effects.some(e => e.ty === 29)) {
            effects.push({
                np: 3,
                ty: 29,
                nm: 'Expand filter',
                en: 1,
                ef: [
                    {
                        ty: 0,
                        v: {
                            a: 0,
                            k: 0
                        }
                    },
                    {
                        ty: 0,
                        v: {
                            k: 1,
                            a: 0
                        }
                    },
                    {
                        ty: 4,
                        v: {
                            k: 0,
                            a: 0
                        }
                    }
                ]
            });
        }
    }
}, addFill = ({ blendMode = BlendMode.Normal, fill, gradient, index, shapeGroup, }) => {
    const startPoint = gradient.fill?.startPoint || [0, 0], endPoint = gradient.fill?.endPoint || [0, 0], radius = gradient.fill?.startRadius || 0.5;
    if (gradient.fill) {
        const unit = gradient.fill?.unit || 2;
        if (unit === 2) {
            gradient.transform.translate[0] *= (gradient.transform.width || 1);
            gradient.transform.translate[1] *= (gradient.transform.height || 1);
        }
        else if (gradient.fill.type === 2) {
            endPoint[0] = startPoint[0] + (radius / 2);
            endPoint[1] = startPoint[1] + (radius / 2);
        }
        startPoint[0] = startPoint[0] * (gradient.transform.scale[0] / 100) + gradient.transform.translate[0];
        startPoint[1] = startPoint[1] * (gradient.transform.scale[1] / 100) + gradient.transform.translate[1];
        endPoint[0] = (endPoint[0] + (radius / 6)) * (gradient.transform.scale[0] / 100) + gradient.transform.translate[0];
        endPoint[1] = (endPoint[1] + (radius / 6)) * (gradient.transform.scale[1] / 100) + gradient.transform.translate[1];
        if (gradient.transform.rotation) {
            const p1 = calculateNewPoint(startPoint, startPoint, gradient.transform.rotation), p2 = calculateNewPoint(endPoint, endPoint, gradient.transform.rotation);
            startPoint[0] = p1[0];
            startPoint[1] = p1[1];
            endPoint[0] = p2[0];
            endPoint[1] = p2[1];
        }
    }
    shapeGroup.np += 1;
    shapeGroup.it?.push({
        ty: gradient.fill ? ShapeType.GradientFill : ShapeType.Fill,
        c: !gradient.fill && fill.color ? {
            a: 0,
            k: fill.color,
        } : undefined,
        o: {
            a: 0,
            k: fill.opacity ?? 100,
        },
        s: gradient.fill ? {
            a: 0,
            k: startPoint
        } : undefined,
        e: gradient.fill ? {
            a: 0,
            k: endPoint
        } : undefined,
        t: gradient.fill?.type,
        g: gradient.fill ? {
            p: gradient.fill.numOfColors,
            k: {
                a: 0,
                k: gradient.fill.gradientColors
            }
        } : undefined,
        a: gradient.fill ? {
            a: 0,
            k: 0
        } : undefined,
        h: gradient.fill ? {
            a: 0,
            k: 0
        } : undefined,
        r: fill.rule === FillRule.NonZero ? 1 : 2,
        bm: handleBlendMode(blendMode),
        nm: gradient.fill ? `Gradient ${index + 1}` : `Fill ${index + 1}`,
        hd: false
    });
}, addLayer = ({ attributes, effects: ef, index, isMatte = false, isStroke = false, matteRef, layers, styles: sy, parent, masksProperties, shapes, svgDOMRect }) => {
    const hasMatte = !!attributes.mask?.node.length && attributes.mask.type === MaskType.Matte, refId = !isMatte && attributes.type === 2 ? attributes.id : undefined, ty = (isMatte || isStroke) ? 4 : attributes.type ?? 4, { x, y } = svgDOMRect;
    if (!attributes.transform) {
        attributes.transform = {
            anchorPoint: [0, 0],
            height: null,
            position: [0, 0],
            rotation: 0,
            scale: [100, 100],
            translate: [0, 0],
            width: null,
            skew: {
                length: 0,
                angle: 0
            }
        };
    }
    attributes.transform.position[0] -= x;
    attributes.transform.position[1] -= y;
    layers.push({
        ddd: 0,
        ind: index,
        refId,
        ty,
        nm: `Layer ${index + 1}`,
        sr: 1,
        ks: {
            o: {
                a: 0,
                k: isMatte ? 100 : attributes.opacity ?? 100,
            },
            r: {
                a: 0,
                k: isMatte ? 0 : attributes.transform.rotation,
            },
            p: {
                a: 0,
                k: isMatte ? [-x, -y] : attributes.transform.position,
            },
            a: {
                a: 0,
                k: isMatte ? [0, 0] : attributes.transform.anchorPoint,
            },
            s: {
                a: 0,
                k: isMatte ? [100, 100] : attributes.transform.scale,
            },
            sk: {
                a: 0,
                k: attributes.transform?.skew.length || 0
            },
            sa: {
                a: 0,
                k: attributes.transform?.skew.angle || 0
            }
        },
        ef,
        ao: 0,
        shapes: attributes.type === 5 ? undefined : shapes,
        ip: 0,
        op: 60,
        st: 0,
        parent,
        t: (attributes.type !== 5 || !attributes.text) ? undefined : {
            d: {
                k: [{
                        s: {
                            s: attributes.text.fontSize,
                            f: attributes.text.fontName,
                            t: attributes.text.content ?? '',
                            ls: 0,
                            j: attributes.text.justify,
                            fc: attributes.text.fill.color ?? [0, 0, 0],
                            sc: attributes.text.stroke.color ?? undefined,
                            sw: attributes.text.stroke.width,
                            of: attributes.text.stroke.strokeOverFill || true
                        },
                        t: 0
                    }]
            },
            p: {},
            m: {
                g: 1,
                a: {
                    a: 0,
                    k: [0, 0]
                }
            },
            a: [{
                    nm: 'Text Range',
                    s: {
                        t: 0,
                        o: {
                            a: 0,
                            k: 0
                        },
                        s: {
                            a: 0,
                            k: 0
                        },
                        e: {
                            a: 0,
                            k: 100
                        },
                        a: {
                            a: 0,
                            k: 100
                        },
                        b: 1,
                        rn: 0,
                        sh: 1,
                        xe: {
                            a: 0,
                            k: 0
                        },
                        ne: {
                            a: 0,
                            k: 0,
                        },
                        sm: {
                            a: 0,
                            k: 100
                        },
                        r: 1
                    },
                    a: {
                        p: {
                            a: 0,
                            k: [0, 0]
                        },
                        r: {
                            a: 0,
                            k: 0
                        },
                        o: {
                            a: 0,
                            k: attributes.text.fill.opacity ?? 100
                        },
                        fc: {
                            a: 0,
                            k: attributes.text.fill.color ?? [0, 0, 0]
                        },
                        fh: {
                            a: 0,
                            k: 0
                        },
                        fs: {
                            a: 0,
                            k: 0
                        },
                        fb: {
                            a: 0,
                            k: 0
                        },
                        sc: attributes.text.stroke.color ? {
                            a: 0,
                            k: attributes.text.stroke.color
                        } : undefined,
                        sw: {
                            a: 0,
                            k: attributes.text.stroke.width
                        },
                        t: {
                            a: 0,
                            k: attributes.text.letterSpacing
                        }
                    }
                }]
        },
        sy,
        hasMask: !!masksProperties?.length,
        masksProperties,
        ct: attributes.type === 2 ? 1 : 0,
        tt: (!isMatte && hasMatte) ? attributes.mask?.matteMode : undefined,
        tp: (!isMatte && hasMatte) ? matteRef : undefined,
        td: isMatte ? 1 : 0,
        bm: handleBlendMode(attributes.blendMode)
    });
}, addMask = ({ attributes, index, maskData, shapeData }) => {
    for (const [i, sh] of shapeData.entries()) {
        maskData.push({
            nm: `Layer ${index + 1} Mask ${i + 1}`,
            inv: false,
            pt: {
                a: 0,
                k: {
                    i: sh.inTangent,
                    o: sh.outTangent,
                    v: sh.verticies,
                    c: sh.closePath
                }
            },
            o: {
                a: 0,
                k: attributes?.mask.opacity ?? 100,
            },
            mode: attributes?.mask.maskMode ?? handleMaskMode(MaskMode.Add),
            x: {
                a: 0,
                k: 0
            }
        });
    }
}, addRepeater = ({ anchorPoint = [0, 0], index, shapeGroup, type: ty, offset, repeat, }) => {
    if (!shapeGroup.it) {
        shapeGroup.it = [];
    }
    shapeGroup.np++;
    shapeGroup.it.push({
        ind: index,
        ty,
        nm: 'Repeater',
        mn: `{${useId('rp')}}`,
        c: {
            a: 0,
            k: repeat
        },
        o: {
            a: 0,
            k: 0
        },
        m: 1,
        tr: {
            a: {
                a: 0,
                k: anchorPoint
            },
            p: {
                a: 0,
                k: offset
            },
            s: {
                a: 0,
                k: [
                    100,
                    100
                ]
            },
            r: {
                a: 0,
                k: 0
            },
            so: {
                a: 0,
                k: 100
            },
            eo: {
                a: 0,
                k: 100
            }
        }
    });
}, addShape = ({ attributes, index, shapeGroup, shapeData, type: ty, }) => {
    const shapeType = Object.keys(ShapeType).find(key => ShapeType[key] === ty);
    if (!shapeGroup.it) {
        shapeGroup.it = [];
    }
    for (const [si, sh] of (shapeData || Array(1)).entries()) {
        shapeGroup.np++;
        shapeGroup.it.push({
            ind: index,
            ty,
            ks: shapeData ? {
                a: 0,
                k: {
                    i: sh.inTangent,
                    o: sh.outTangent,
                    v: sh.verticies,
                    c: sh.closePath
                },
            } : undefined,
            bm: handleBlendMode(attributes?.blendMode),
            nm: `${shapeType} ${si + 1}`,
            mn: `Shape ${shapeType}`,
            hd: false
        });
    }
}, addStroke = ({ gradient, index, shapeGroup, stroke }) => {
    const startPoint = gradient.stroke?.startPoint || [0, 0], endPoint = gradient.stroke?.endPoint || [0, 0], radius = gradient.stroke?.startRadius || 0.5;
    if (gradient.stroke) {
        const unit = gradient.stroke?.unit || 2;
        if (unit === 2) {
            gradient.transform.translate[0] *= (gradient.transform.width || 1);
            gradient.transform.translate[1] *= (gradient.transform.height || 1);
        }
        else if (gradient.stroke.type === 2) {
            endPoint[0] = startPoint[0] + (radius / 2);
            endPoint[1] = startPoint[1] + (radius / 2);
        }
        startPoint[0] = startPoint[0] * (gradient.transform.scale[0] / 100) + gradient.transform.translate[0];
        startPoint[1] = startPoint[1] * (gradient.transform.scale[1] / 100) + gradient.transform.translate[1];
        endPoint[0] = (endPoint[0] + (radius / 6)) * (gradient.transform.scale[0] / 100) + gradient.transform.translate[0];
        endPoint[1] = (endPoint[1] + (radius / 6)) * (gradient.transform.scale[1] / 100) + gradient.transform.translate[1];
        if (gradient.transform.rotation) {
            const p1 = calculateNewPoint(startPoint, startPoint, gradient.transform.rotation), p2 = calculateNewPoint(endPoint, endPoint, gradient.transform.rotation);
            startPoint[0] = p1[0];
            startPoint[1] = p1[1];
            endPoint[0] = p2[0];
            endPoint[1] = p2[1];
        }
    }
    shapeGroup.np += 1;
    shapeGroup.it?.push({
        ind: index,
        ty: gradient.stroke ? ShapeType.GradientStroke : ShapeType.Stroke,
        c: {
            a: 0,
            k: stroke.color ?? [0, 0, 0]
        },
        s: gradient.stroke ? {
            a: 0,
            k: startPoint
        } : undefined,
        e: gradient.stroke ? {
            a: 0,
            k: endPoint
        } : undefined,
        t: gradient.stroke?.type,
        g: gradient?.stroke ? {
            p: gradient.stroke.numOfColors,
            k: {
                a: 0,
                k: gradient.stroke.gradientColors
            }
        } : undefined,
        a: gradient.stroke ? {
            a: 0,
            k: 0
        } : undefined,
        h: gradient.stroke ? {
            a: 0,
            k: 0
        } : undefined,
        d: stroke.dasharray ? [
            {
                n: 'o',
                nm: 'offset',
                v: {
                    a: 0,
                    k: stroke.dashoffset
                }
            },
            {
                n: 'd',
                nm: 'dash',
                v: {
                    a: 0,
                    k: stroke.dasharray.dash
                }
            },
            {
                n: 'g',
                nm: 'gap',
                v: {
                    a: 0,
                    k: stroke.dasharray.gap
                }
            }
        ] : undefined,
        o: {
            a: 0,
            k: stroke.opacity ?? 100
        },
        w: {
            a: 0,
            k: stroke.width || 1
        },
        lc: stroke.linecap,
        lj: stroke.linejoin,
        ml: stroke.miterlimit,
        nm: `Stroke ${index + 1}`,
        mn: 'Shape Stroke',
        hd: false
    });
}, addStyle = ({ attributes, index, styles }) => {
    for (const shadow of attributes.shadow) {
        if (!shadow.inner && shadow.grow === 0) {
            continue;
        }
        styles.push({
            nm: `${shadow.inner ? 'Inset' : 'Extended'} Shadow`,
            mn: `${shadow.inner ? 'Inset' : 'Extended'} Shadow ${index + 1}`,
            ty: shadow.inner ? 2 : 1,
            c: {
                a: 0,
                k: shadow.color
            },
            o: {
                a: 0,
                k: shadow.opacity
            },
            a: {
                a: 0,
                k: shadow.angle
            },
            s: {
                a: 0,
                k: shadow.blur
            },
            d: {
                a: 0,
                k: shadow.distance
            },
            ch: {
                a: 0,
                k: shadow.grow
            },
        });
    }
}, addTransform = ({ index, shapeGroup, transform = {
    anchorPoint: [0, 0],
    height: null,
    position: [0, 0],
    rotation: 0,
    scale: [100, 100],
    translate: [0, 0],
    width: null,
    skew: {
        length: 0,
        angle: 0
    }
}, }) => {
    const position = [
        transform.position[0] + transform.translate[0],
        transform.position[1] + transform.translate[1]
    ];
    shapeGroup.np += 1;
    shapeGroup.it?.push({
        ty: ShapeType.Transform,
        a: {
            a: 0,
            k: transform.anchorPoint,
        },
        p: {
            a: 0,
            k: position,
        },
        s: {
            a: 0,
            k: transform.scale,
        },
        r: {
            a: 0,
            k: transform.rotation,
        },
        o: {
            a: 0,
            k: 100,
        },
        sk: {
            a: 0,
            k: transform.skew.length,
        },
        sa: {
            a: 0,
            k: transform.skew.angle,
        },
        nm: `Transform ${index + 1}`,
        hd: false,
    });
};
