import { LayerComponentType, LayerType } from '@phase-software/types'
import LayerComponent from './LayerComponent'

/** @typedef {import('../DataStore').DataStore} DataStore */

export default class InnerShadowComponent extends LayerComponent {
    /**
     * @param {DataStore} dataStore
     * @param {InnerShadowComponentData} [data]
     * @param {object} [options] 
     * @param {bool} [options.regenId=false]   if set to true, will generate new ID
     */
    constructor(dataStore, data = {}, options) {
        super(dataStore, data, options)

        this.componentType = LayerComponentType.INNER_SHADOW

        if (!this.name || this.name === 'layer') {
            this.name = 'inner-shadow'
        }
        if (!this.layerType || this.layerType === 'LAYER') {
            this.layerType = LayerType.INNER_SHADOW
        }
    }
}

/** @typedef {import('./LayerComponent').LayerComponentData} LayerComponentData */
/** @typedef {import('../layer/InnerShadow').InnerShadowData} InnerShadowData */

/**
 * @typedef {LayerComponentData} InnerShadowComponentData
 * @property {InnerShadowData[]} layers
 */
