import React from 'react'
import { Trans } from 'react-i18next'

import emptyPropertyIllustration from './empty-property.svg'

const EmptyState = () => {
  return (
    <div className="h-full flex flex-col items-center justify-center gap-y-16">
      <img src={emptyPropertyIllustration} alt="Empty state" className="w-[216px]" />
      <span className="text-12 text-center text-light-overlay-40 whitespace-pre-line">
        <Trans i18nKey="file:property_editor.no_element_selected" />
      </span>
    </div>
  )
}

export default EmptyState
