import { Rect2, Vector2 } from "../../math"
import { solveCubic } from "./common"
import { Line } from "./Line"

/** @typedef {import("../../math/Vector2").Vector2Like} Vector2Like */

export function QuadBez() {
    this.p0 = new Vector2()
    this.p1 = new Vector2()
    this.p2 = new Vector2()
    /** @type {Rect2} */
    this._bounds = null
}

QuadBez.prototype = {
    constructor: QuadBez,

    /**
     * @param {number} p0x
     * @param {number} p0y
     * @param {number} cx
     * @param {number} cy
     * @param {number} p1x
     * @param {number} p1y
     */
    initN(p0x, p0y, cx, cy, p1x, p1y) {
        this.p0.set(p0x, p0y)
        this.p1.set(cx, cy)
        this.p2.set(p1x, p1y)
        return this
    },

    /**
     * @param {number} t
     */
    eval(t) {
        const mt = 1 - t
        return this.p0.clone().scale(mt * mt).add(
            this.p1.clone().scale(mt * 2).add(
                this.p2.clone().scale(t)
            ).scale(t)
        )
    },

    deriv() {
        return new Line().initN(
            2 * (this.p1.x - this.p0.x), 2 * (this.p1.y - this.p0.y),
            2 * (this.p2.x - this.p1.x), 2 * (this.p2.y - this.p1.y)
        )
    },

    /**
     * @param {Vector2Like} p
     * @param {number} _accuracy
     */
    // eslint-disable-next-line no-unused-vars
    nearest(p, _accuracy) {
        const d0 = this.p1.clone().sub(this.p0)
        const d1 = this.p1.clone().scale(-2).add(this.p0).add(this.p2)
        const d = this.p0.clone().sub(p)
        const c0 = d.dot(d0)
        const c1 = 2.0 * d0.length_squared() + d.dot(d1)
        const c2 = 3.0 * d1.dot(d0)
        const c3 = d1.length_squared()
        const roots = solveCubic(c0, c1, c2, c3)
        /** @type {{ r_best?: number, t_best: number }} */
        const ret = { r_best: null, t_best: 0 }
        let need_ends = false
        for (let i = 0, len = roots.length; i < len; i++) {
            need_ends |= tryT(this, p, ret, roots[i])
        }
        if (need_ends) {
            evalT(p, ret, 0.0, this.p0)
            evalT(p, ret, 1.0, this.p2)
        }

        return {
            t: ret.t_best,
            distance_sq: ret.r_best,
        }
    },

    getBounds() {
        if (this._bounds == null) {
            const points = [this.p0, this.p1, this.p2]
            let minX = points[0].x
            let minY = points[0].y
            let maxX = points[0].x
            let maxY = points[0].y
            for (let i = 1; i < 3; i++) {
                const { x, y } = points[i]
                minX = Math.min(minX, x)
                minY = Math.min(minY, y)
                maxX = Math.max(maxX, x)
                maxY = Math.max(maxY, y)
            }
            this._bounds = new Rect2(minX, minY, maxX - minX, maxY - minY)
        }
        return this._bounds
    }
}

/**
 * @param {Vector2Like} p
 * @param {{ t_best: number, r_best?: number }} ret
 * @param {number} t
 * @param {Vector2} p0
 */
function evalT(p, ret, t, p0) {
    const r = p0.distance_squared_to(p)
    const r_is_best = Number.isFinite(ret.r_best)
        ? r < ret.r_best
        : true
    if (r_is_best) {
        ret.r_best = r
        ret.t_best = t
    }
}

/**
 * @param {QuadBez} q
 * @param {Vector2Like} p
 * @param {{ t_best: number, r_best?: number }} ret
 * @param {number} t
 */
function tryT(q, p, ret, t) {
    if (t < 0 || t > 1) {
        return true
    }
    evalT(p, ret, t, q.eval(t))
    return false
}
