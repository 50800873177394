import { schema } from 'normalizr'
import { useState, useCallback, useEffect } from 'react'

const DEFAULT_FILE_TYPE = 'text/plain'

type ProviderProps<T> = {
  value?: T
  children?: React.ReactNode
}

type StateSetter<T> = (value: T | ((prevState: T) => T)) => void

// TODO: replace by jotai or recoil (atomic state management library)
export const createProvider = <T>(name: string, defaultValue: T) => {
  const Provider: React.FC<ProviderProps<T>> & {
    _setState?: StateSetter<T>
    _currentValue?: T
    _subscribers: Map<(s: T) => any, (s: T) => void>
  } = (props: ProviderProps<T>) => {
    const [state, setState] = useState<T>(() => {
      const value = props.value || defaultValue
      Provider._currentValue = value
      return value
    })

    Provider._setState = setState

    useEffect(() => {
      Provider._currentValue = state
      Provider._subscribers.forEach((cb) => {
        cb(state)
      })
    }, [state])

    useEffect(() => {
      return () => {
        Provider._setState = undefined
        Provider._currentValue = undefined
        Provider._subscribers.clear()
      }
    }, [])
    return null
  }

  Provider.displayName = `${name}Provider`

  Provider._subscribers = new Map()

  const useSelectState = (fn: (s: any) => any = (s) => s) => {
    const currentState = fn(Provider._currentValue!)
    const [state, setState] = useState(currentState)

    useEffect(() => {
      const cb = (s: T) => {
        const newState = fn(s)
        if (newState !== state) {
          setState(newState)
        }
      }
      Provider._subscribers.set(fn, cb)

      return () => {
        Provider._subscribers.delete(fn)
      }
    }, [fn, state])

    useEffect(() => {
      if (currentState !== state) {
        setState(currentState)
      }
    }, [currentState, state])
    return state
  }

  const getStateSnapshot = () => Provider._currentValue

  const useSetState = () => Provider._setState

  return [Provider, useSelectState, useSetState, getStateSnapshot] as const
}

// Check if entities is null or empty object
const isEmpty = (entities: object): boolean => {
  if (entities) {
    for (const e in entities) {
      return false
    }
  }
  return true
}

export const useEntity = (Entity: schema.Entity, setState: StateSetter<{ [key: string]: any }>) => {
  const set = useCallback((entities: { [key: string]: any }) => setState(entities), [setState])
  const add = useCallback(
    (id: string, data: { [key: string]: any }) => setState((state) => ({ ...state, [id]: data })),
    [setState]
  )
  const update = useCallback(
    (id: string, data: { [key: string]: any } | ((data: { [key: string]: any }) => { [key: string]: any })) =>
      setState((state) => {
        const newData = typeof data === 'function' ? data(state[id]) : { ...state[id], ...data }
        return {
          ...state,
          [id]: newData
        }
      }),
    [setState]
  )
  const remove = useCallback(
    (id: string) =>
      setState((state) => {
        const newState = { ...state }
        delete newState[id]
        return newState
      }),
    [setState]
  )

  const merge = useCallback(
    (entities: { [key: string]: any }) => !isEmpty(entities) && setState((state) => ({ ...state, ...entities })),
    [setState]
  )

  const reduce = useCallback(
    (entities: { [key: string]: any } = {}) =>
      setState((state) => {
        const removeSet = new Set(Object.keys(entities))
        return Object.keys(state).reduce((acc: { [key: string]: any }, cur: string) => {
          if (removeSet.has(cur)) return acc
          acc[cur] = state[cur]
          return acc
        }, {})
      }),
    [setState]
  )

  const create = useCallback(
    (entities: { [key: string]: any }) => {
      merge(entities[Entity.key])
    },
    [merge, Entity.key]
  )

  const destroy = useCallback(
    (entities: { [key: string]: any }) => {
      reduce(entities[Entity.key])
    },
    [reduce, Entity.key]
  )

  return [set, add, update, remove, merge, reduce, create, destroy]
}

/**
 * Trigger download of data
 */
export function download(data: any, fileName: string, type: string = DEFAULT_FILE_TYPE) {
  const url = window.URL.createObjectURL(new Blob([data], { type }))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName)
  link.setAttribute('style', 'display:none')
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export async function upload(canImportPhase: boolean) {
  return new Promise((resolve: (file: File) => void) => {
    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('class', 'absolute top-0 opacity-0')
    // TODO: feature toggle
    input.setAttribute('accept', canImportPhase ? '.svg,.phase,.json,.lottie' : '.svg,.json,.lottie')
    input.addEventListener(
      'change',
      () => {
        const file = input.files && input.files[0]
        if (file) {
          resolve(file)
        }
      },
      false
    )

    input.onclick = () => {
      input.focus()
      // focus will change to body when dialog opened
      document.body.onfocus = () => {
        // there have no way to get updated value at correct timing
        setTimeout(() => {
          document.body.onfocus = null
          if (document.body.contains(input)) {
            document.body.removeChild(input)
          }
        }, 1000)
      }
    }
    document.body.appendChild(input)
    input.click()
  })
}
