import React, { useEffect } from 'react'

import { useWorkspaceContext } from '../../providers/WorkspaceContextProvider'
import { BaseShareDialogProps } from './BaseShareDialog'
import SharePersonalFileDialog from './SharePersonalFileDialog'
import ShareTeamFileDialog from './ShareTeamFileDialog'

import { ShareFileDialogContextProvider } from './ShareFileDialogContextProvider'
import { useGetTeamUsersQuery } from '../../generated/graphql'
import { MenuOptionProps } from '../shared/Menu/MenuOption'
import { transformTeamMember } from './shareHelper'
import useHeapAnalytics from '../../hooks/useHeapAnalytics'
import { track } from '../../services/heapAnalytics'

type ShareFileDialogProps = Pick<BaseShareDialogProps, 'onClose' | 'open'> & {
  fileId: string
  projectId: string
}

const ShareFileDialog = ({ fileId, projectId, open, onClose }: ShareFileDialogProps) => {
  const { workspaceData } = useWorkspaceContext()
  const { space, teamName } = useHeapAnalytics()

  const isPersonalWorkspace = workspaceData.isPersonal
  const isDraftFile = projectId === workspaceData.draftProjectId

  const { data: teamMemberListData } = useGetTeamUsersQuery({
    variables: { slug: workspaceData.slug },
    skip: isPersonalWorkspace
  })
  const teamMemberList: MenuOptionProps[] = teamMemberListData?.team_users.map(transformTeamMember) ?? []

  useEffect(() => {
    if (open) {
      track('File Share Clicked', {
        space,
        location: isDraftFile ? 'drafts' : 'project',
        teamName,
        fileId,
        projectId
      })
    }
  }, [fileId, open, projectId, space, teamName, isDraftFile])

  if (!open) return null

  return (
    <ShareFileDialogContextProvider fileId={fileId} onClose={onClose} open={open} projectId={projectId}>
      {isPersonalWorkspace ? (
        <SharePersonalFileDialog />
      ) : (
        <ShareTeamFileDialog isDraftFile={isDraftFile} teamMemberList={teamMemberList} />
      )}
    </ShareFileDialogContextProvider>
  )
}

export default ShareFileDialog
