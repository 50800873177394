import TransitionManager from './src';

export default TransitionManager;
export { easePoints, bezierCurveFunc } from './src/utils/timing-functions';
export {
  CUSTOM_EVENTS,
  TRANSITION_MODES,
  TRANSITION_STATUS,
  TM_PROPS_MAP,
  NON_REPEATABLE_PROPERTIES_ALIAS
} from './src/constant';

export {
  matchGradientStops
} from './src/utils/data'
