import { Events, DEFAULT_ELEMENT_SIZE } from '@phase-software/data-store'
import { ElementType, GeometryType, ToolType } from '@phase-software/types'
import { NO_COMMIT } from '@phase-software/data-utils'
import { Vector2 } from '../math'
import { findScalableElementAt } from './selection'


/** @typedef {import('@phase-software/data-store/src/Element').Element} Element */
/** @typedef {import('../visual_server/VisualServer').VisualServer} VisualServer */
/** @typedef {import('../Viewport').Viewport} Viewport */
/** @typedef {import('./handles').Handlers} Handlers */


const TOOL_TO_ELEMENT_TYPE = {
    [ToolType.RECTANGLE]: ElementType.PATH,
    [ToolType.ELLIPSE]: ElementType.PATH,
    [ToolType.PEN]: ElementType.PATH,
    [ToolType.CONTAINER]: ElementType.CONTAINER
}

const TOOL_TO_GEOMETRY_TYPE = {
    [ToolType.RECTANGLE]: GeometryType.RECTANGLE,
    [ToolType.ELLIPSE]: GeometryType.ELLIPSE,
    [ToolType.PEN]: GeometryType.POLYGON,
    [ToolType.CONTAINER]: undefined
}

const STARTING_SIZE_SPEC = { width: 10, height: 10 }

/**
 * @param {VisualServer} visualServer
 * @param {Handlers} elementHandlers
 * @param {AreaSelection} areaSelection
 * @param {import('..').SetLocalCursorStateFn} setLocalCursorState
 */
export function initCreationTools(visualServer, elementHandlers, areaSelection, setLocalCursorState) {
    const { dataStore } = visualServer

    let _toolType
    let _startCreate = false
    let _startDrag = false
    const _startMousePos = new Vector2()
    const _endMousePos = new Vector2()

    /**
     * @param {ToolType} toolType
     */
    const _enterCreateMode = (toolType) => {
        // do nothing if in action mode
        if (dataStore.get('mode') === 'ACTION') {
            return
        }
        // if tool is different, switch tool
        _toolType = toolType
    }

    const _exitCreateMode = (switchToGeneralTool = true) => {
        if (!_toolType) {
            return
        }
        _toolType = undefined
        _startCreate = false
        _startDrag = false
        _newELement = null
        _alt = false

        setLocalCursorState('default')
        // switch tool to last general tool, if needed
        if (switchToGeneralTool) {
            dataStore.eam.setLastGeneralTool()
        }
    }

    dataStore.eam.on(Events.ACTIVATE_RECTANGLE_TOOL, () => {
        _enterCreateMode(ToolType.RECTANGLE)
    })
    dataStore.eam.on(Events.ACTIVATE_CONTAINER_TOOL, () => {
        _enterCreateMode(ToolType.CONTAINER)
    })
    dataStore.eam.on(Events.ACTIVATE_ELLIPSE_TOOL, () => {
        _enterCreateMode(ToolType.ELLIPSE)
    })
    dataStore.eam.on(Events.ACTIVATE_UNION_TOOL, () => {
        dataStore.eam.booleanGroupElements('UNION')
    })
    dataStore.eam.on(Events.ACTIVATE_SUBTRACT_TOOL, () => {
        dataStore.eam.booleanGroupElements('SUBTRACT')

    })
    dataStore.eam.on(Events.ACTIVATE_INTERSECT_TOOL, () => {
        dataStore.eam.booleanGroupElements('INTERSECT')

    })
    dataStore.eam.on(Events.ACTIVATE_EXCLUDE_TOOL, () => {
        dataStore.eam.booleanGroupElements('EXCLUDE')
    })

    /** @type {Element} */
    let _newELement = null
    let _alt = false
    dataStore.eam
        .on(Events.START_CREATE_ELEMENT, (e) => {
            _startCreate = true
            _startMousePos.copy(e.mousePos)
            _endMousePos.copy(e.mousePos)
        })
        .on(Events.UPDATE_CREATE_ELEMENT, (e, keys, { snapToGrid = true, snapToObject = true }) => {
            if (!_startDrag) {
                // Container element will add into different ancestor with different situations.
                // So, do not create element when start to detect elements inside new Container area.
                _newELement = createElement(_startMousePos, _toolType, visualServer, STARTING_SIZE_SPEC, false, snapToGrid)
                // trigger a manual transform update before starting the resize Action
                // in order for the world transform of the newly created element to be updated
                visualServer.updateTransformRecursively(_newELement.get('id'))

                // Call element handler helper to do element resize
                elementHandlers.setHandle('BOTTOM_RIGHT')
                elementHandlers.ignoreActiveTool = true
                elementHandlers.resizeStart(e, snapToGrid, snapToObject)
            }

            _startDrag = true
            elementHandlers.resizeUpdate(e, keys, snapToGrid, snapToObject)
            _endMousePos.copy(e.mousePos)
            _alt = keys.alt
        })
        .on(Events.END_CREATE_ELEMENT, () => {
            const isContainer = _toolType === ToolType.CONTAINER
            elementHandlers.resizeEnd(!isContainer)
            if (!_startCreate) {
                return
            }
            if (!_startDrag) {
                _newELement = createElement(_startMousePos, _toolType, visualServer)
                visualServer.indexer.updateTransformRecursively(visualServer.indexer.nodeMap.get(_newELement.get('id')))
                _newELement.get('parent').recalculateBounds()
                _endMousePos.add(DEFAULT_ELEMENT_SIZE.width, DEFAULT_ELEMENT_SIZE.height)
            }
            if (isContainer) {
                const { startPos, endPos } = getContainerArea(_alt)
                areaSelection.start({ mousePos: startPos })
                areaSelection.insideContainer = true
                /* @type {Set<Element>} */
                const _groupedElements = areaSelection.update({ mousePos: endPos }, { alt: true }, false, true)
                areaSelection.insideContainer = false
                areaSelection.end(false)
                dataStore.addElementsToContainer(dataStore.sortElements([..._groupedElements]), _newELement)
                dataStore.commitUndo()
            }
            _exitCreateMode()
        })

    /**
     * @param {bool} alt
     * @returns {object}
     */
    function getContainerArea(alt) {
        if (!alt) {
            return {
                startPos: _startMousePos,
                endPos: _endMousePos
            }
        }

        const centerPos = _startMousePos.clone()
        const endPos = _endMousePos.clone()
        const posDiff = endPos.clone().sub(centerPos)
        const startPos = centerPos.clone().sub(posDiff)

        return {
            startPos,
            endPos
        }
    }
}

/**
 * @param {Vector2} mousePos    in world space
 * @returns {{ parent: Element, index: numer }}
 */
function _getSceneTreeParentAndPosition(mousePos) {
    const parent = findScalableElementAt(mousePos)
    const index = parent.children.length
    return { parent, index }
}

/**
 * @param {Vector2} mousePos   in screen space
 * @param {ToolType} toolType
 * @param {VisualServer} visualServer
 * @param {{width: number, height: number} | undefined} sizeSpec
 * @param {bool} [forceCommit=true]
 * @param {bool} [isSnapping=true]
 * @returns {Element} new element
 */
export function createElement(mousePos, toolType, visualServer, sizeSpec, forceCommit = true, isSnapping = true) {
    const { dataStore, viewport } = visualServer
    const elementType = TOOL_TO_ELEMENT_TYPE[toolType]
    const worldMousePos = viewport.toWorld(mousePos)

    if (isSnapping) {
        worldMousePos.round()
    }

    // To prevent the screen translate from affecting world position calculation,
    // we offset the element pos here
    visualServer.indexer.getScreenNode().item.transform.worldInv.xform(worldMousePos, worldMousePos)

    const { parent, index } = _getSceneTreeParentAndPosition(worldMousePos)
    const newSelection = [
        dataStore.createNewElement(elementType, worldMousePos, sizeSpec, TOOL_TO_GEOMETRY_TYPE[toolType])
    ]

    // add the new created element to screen first and then to the parent
    // to ensure the new element transform is updated correctly
    const screen = dataStore.workspace.watched.children[0]
    dataStore.addChildrenAt(screen, newSelection, screen.children.length , NO_COMMIT)

    if (!dataStore.addChildrenAt(parent, newSelection, index, NO_COMMIT)) {
        console.warn("Failed to create new element")
        dataStore.clearUndo()
        return
    }

    // will also commit
    dataStore.selection.selectElements(newSelection, forceCommit ? {} : NO_COMMIT)
    return newSelection[0]
}
