import {
    LayerType,
    EffectType,
    BlendMode,
    GrowDirection,
    CapShape,
    JoinShape,
    EndShape,
    PaintType,
    TrimPathMode,
    ImageMode,
    ElementType,
    GeometryType,
    PointShape,
} from '@phase-software/types'
import { Vector4, ColorStop, Matrix2D, IS_MIX } from '@phase-software/data-utils'
import { childListMap, generalChildKeySet } from './interaction/constants'


/** @typedef { import('./component/PropertyComponent').PropertyComponentData} PropertyComponentData */
/** @typedef { import('./layer/Layer').LayerData} LayerData */

/**
 * @typedef {Map<string, PropertyComponentData | LayerData>} ComponentDataMap
 */


export const MAX_INTERACTION_TIME = 60 * 1000
export const IM_UPDATE_RATE_LIMITER = 50

export const UNDOABLE_ELEMENT_SETTER_PROPS = new Set([
    'name',
    'locked',
    'visible',
    'autoOrient',
    'aspectRatioLocked',
    'scaleAspectRatioLocked',
    'overflowX',
    'overflowY',
])

export const CREATE_NEW_ELEMENT_DATA = {
    name: undefined,
    elementType: undefined,
    geometryType: undefined,
    base: {
        override: true,
        translate: undefined,
        dimensions: undefined
    }
}

export const PROP_STATES = {
    DEFAULT: 'DEFAULT',
    TWEEN: 'TWEEN',
    EXPLICIT: 'EXPLICIT',
    INITIAL: 'INITIAL'
}

export const DEFAULT_ELEMENT_SIZE = { width: 100, height: 100 }

export const DEFAULT_GRADIENT_PAINT_DATA = [
    { position: 0, color: [0.85, 0.85, 0.85, 1] },
    { position: 1, color: [0.85, 0.85, 0.85, 0] }
]

const DEFAULT_STROKE_GRADIENT_PAINT_DATA = [
    { position: 0, color: [0, 0, 0, 1] },
    { position: 1, color: [0, 0, 0, 0] }
]

export const DEFAULT_LAYER_PAINT_DATA = {
    [LayerType.FILL]: {
        color: new Vector4([0.85, 0.85, 0.85, 1]), // #D8D8D8
        gradientStops: DEFAULT_GRADIENT_PAINT_DATA.map(gradientStop => new ColorStop(gradientStop)),
        gradientTransform: new Matrix2D(0, -1, 1, 0, 0, 1),
        opacity: 1,
        imageId: null,
        imageMode: ImageMode.FILL
    },
    [LayerType.STROKE]: {
        color: new Vector4([0, 0, 0, 1]), // #222222
        gradientStops: DEFAULT_STROKE_GRADIENT_PAINT_DATA.map(gradientStop => new ColorStop(gradientStop)),
        gradientTransform: new Matrix2D(0, -1, 1, 0, 0, 1),
        opacity: 1,
    },
    [LayerType.SHADOW]: {
        color: new Vector4([0, 0, 0, 1]), // #000
        opacity: 0.25
    },
    [LayerType.INNER_SHADOW]: {
        color: new Vector4([1, 1, 1, 1]), // #fff
        opacity: 0.25
    }
}

export const DEFAULT_MOTION_PATH_VALUE = {
    pos: [0, 0],
    in: [0, 0],
    out: [0, 0],
    mirror: PointShape.NONE
}

export const DEFAULT_FILL_VALUE = {
    ...DEFAULT_LAYER_PAINT_DATA[LayerType.FILL],
    layerType: LayerType.FILL,
    visible: true,
    paintType: PaintType.SOLID,
    blendMode: BlendMode.NORMAL
}

export const DEFAULT_STROKE_VALUE = {
    ...DEFAULT_LAYER_PAINT_DATA[LayerType.STROKE],
    layerType: LayerType.STROKE,
    visible: true,
    paintType: PaintType.SOLID,
    blendMode: BlendMode.NORMAL,
    width: 1,
    growDirection: GrowDirection.CENTER,
    offset: 0,
    dash: [],
    gap: [],
    cap: CapShape.NONE,
    capSize: 100,
    join: JoinShape.MITER,
    joinSize: 100,
    miter: 28.96,
    ends: EndShape.STRAIGHT
}

export const DEFAULT_SHADOW_VALUE = {
    ...DEFAULT_LAYER_PAINT_DATA[LayerType.SHADOW],
    layerType: LayerType.SHADOW,
    visible: true,
    paintType: PaintType.SOLID,
    blendMode: BlendMode.NORMAL,
    offsetX: 0,
    offsetY: 4,
    blur: 4,
    spread: 0
}

export const DEFAULT_INNER_SHADOW_VALUE = {
    ...DEFAULT_LAYER_PAINT_DATA[LayerType.INNER_SHADOW],
    layerType: LayerType.INNER_SHADOW,
    visible: true,
    paintType: PaintType.SOLID,
    blendMode: BlendMode.NORMAL,
    offsetX: 0,
    offsetY: 4,
    blur: 4,
    spread: 0
}

export const DEFAULT_GRADIENT_TRANSFORM = [0, -1, 1, 0, 0, 1]

export const DEFAULT_LAYER_VALUE = {
    [LayerType.FILL]: DEFAULT_FILL_VALUE,
    [LayerType.STROKE]: DEFAULT_STROKE_VALUE,
    [LayerType.SHADOW]: DEFAULT_SHADOW_VALUE,
    [LayerType.INNER_SHADOW]: DEFAULT_INNER_SHADOW_VALUE
}

export const DEFAULT_TRIM_PATH_DATA = {
    start: 0,
    end: 100,
    offset: 0,
    mode: TrimPathMode.SIMULTANEOUSLY
}

export const DEFAULT_EFFECT_VALUE = {
    [EffectType.TRIM_PATH]: DEFAULT_TRIM_PATH_DATA
}

export const EFFECT_TYPE_NAME_MAP = {
    [EffectType.TRIM_PATH]: 'trimPath'
}

export const EFFECT_TYPE_VALUE_MAP = {
    'trimPath': EffectType.TRIM_PATH
}

export const PROP = {
    motionPath: 'motionPath',
    position: 'position',
    translate: 'translate',
    dimensions: 'dimensions',
    rotation: 'rotation',
    origin: 'origin',
    referencePoint: 'referencePoint',
    contentAnchor: 'contentAnchor',
    opacity: 'opacity',
    scale: 'scale',
    skew: 'skew',
    cornerRadius: 'cornerRadius',
    overflow: 'overflow',
    font: 'font',
    textAlignment: 'textAlignment',
    textDecoration: 'textDecoration',
    textDirection: 'textDirection',
    blurGaussian: 'blurGaussian'
}

export const KEYS_TO_PROP = {
    position: PROP.position,
    translate: PROP.translate,
    contentSize: PROP.dimensions,
    size: PROP.dimensions,
    scale: PROP.scale,
    skew: PROP.skew,
    rotation: PROP.rotation,
    originX: PROP.origin,
    originY: PROP.origin,
    originXUnit: PROP.origin,
    originYUnit: PROP.origin,
    referencePointX: PROP.referencePoint,
    referencePointY: PROP.referencePoint,
    contentAnchorX: PROP.contentAnchor,
    contentAnchorY: PROP.contentAnchor,
    contentAnchorAutoAdd: PROP.contentAnchor,
    contentAnchorType: PROP.contentAnchor,
    opacity: PROP.opacity,
    blendMode: PROP.opacity,
    cornerRadius: PROP.cornerRadius,
    cornerSmoothing: PROP.cornerRadius,
    overflowX: PROP.overflow,
    overflowY: PROP.overflow,
    scrollX: PROP.overflow,
    scrollY: PROP.overflow,
    scrollBarX: PROP.overflow,
    scrollBarY: PROP.overflow,
    fontFamily: PROP.font,
    fontStyle: PROP.font,
    fontSize: PROP.font,
    fontSizeUnit: PROP.font,
    textDirection: PROP.font,
    characterSpacing: PROP.font,
    wordSpacing: PROP.font,
    lineSpacing: PROP.font,
    paragraphSpacing: PROP.font,
    paragraphIndent: PROP.font,
    horizontalAlignment: PROP.textAlignment,
    verticalAlignment: PROP.textAlignment,
    underline: PROP.textDecoration,
    strikethrough: PROP.textDecoration,
    cases: PROP.textDecoration,
    subSuper: PROP.textDecoration,
    blurGaussian: PROP.blurGaussian,
    blurGaussianAmount: PROP.blurGaussian,
    blurGaussianVisible: PROP.blurGaussianVisible
}

export const CS_TO_BASE_ALLOWED_KEYS = {
    position: new Set(['position']),
    translate: new Set(['translate']),
    dimensions: new Set(['size']),
    rotation: new Set(['rotation']),
    origin: new Set(['originX', 'originY', 'originXUnit', 'originYUnit']),
    referencePoint: new Set(['referencePointX', 'referencePointY']),
    contentAnchor: new Set(['contentAnchorX', 'contentAnchorY', 'contentAnchorAutoAdd', 'contentAnchorType']),
    opacity: new Set(['opacity', 'blendMode']),
    scale: new Set(['scale']),
    skew: new Set(['skew']),
    cornerRadius: new Set(['cornerRadius', 'cornerSmoothing']),
    overflow: new Set(['overflowX', 'overflowY', 'scrollX', 'scrollY', 'scrollBarX', 'scrollBarY']),
    font: new Set(['fontFamily', 'fontStyle', 'fontSize', 'fontSizeUnit', 'characterSpacing', 'wordSpacing', 'lineSpacing', 'paragraphSpacing', 'paragraphIndent']),
    textAlignment: new Set(['horizontalAlignment', 'verticalAlignment']),
    textDecoration: new Set(['underline', 'strikethrough', 'cases', 'subSuper']),
    textDirection: new Set(['textDirection']),
    blurGaussian: new Set([
        // mapping from CS key to Prop Component key (if they are different)
        ['blurGaussianAmount', 'amount'],
        ['blurGaussianVisible', 'visible']
    ])
}

export const SWITCH_COMPONENT_KEYS = {
    position: 'translate',
    translate: 'position'
}

export const SWITCH_COMPONENT_PROPS = {
    x: 'translateX',
    y: 'translateY',
    translateX: 'x',
    translateY: 'y'
}

export const PROPORTION_COMPONENT_PROPS = {
    width: ['contentAnchorX', 'referencePointX'],
    height: ['contentAnchorY', 'referencePointY']
}

// paint
export const SolidPaintKeys = ['color']
export const GradientPaintKeys = ['gradientTransform', 'gradientStops', 'activeGradientStopIdx']
export const ImagePaintKeys = ['imageId', 'imageMode']

// layer props
export const LayerKeys = ['visible', 'layerType', 'paintType', 'blendMode', 'opacity']
export const FillBaseKeys = [...LayerKeys]
export const StrokeBaseKeys = [...LayerKeys, 'width', 'growDirection', 'offset', 'dash', 'gap', 'cap', 'capSize', 'join', 'joinSize', 'miter', 'ends']
export const ShadowBaseKeys = [...LayerKeys, 'offsetX', 'offsetY', 'blur', 'spread']

export const PAINT_CATEGORY_PROPS_MAP = {
    'solid': SolidPaintKeys,
    'gradient': GradientPaintKeys,
    'image': ImagePaintKeys
}

export const PAINT_KEYS = ['paintType', ...SolidPaintKeys, 'gradientTransform', 'gradientStops', ...ImagePaintKeys];

export const ALLOWED_LAYER_KEYS = ['visible', 'layerType', 'paintType', 'blendMode', 'opacity']
export const ALLOWED_FILL_KEYS = [
    ...ALLOWED_LAYER_KEYS,
    ...PAINT_CATEGORY_PROPS_MAP.solid,
    ...PAINT_CATEGORY_PROPS_MAP.image,
    ...PAINT_CATEGORY_PROPS_MAP.gradient
]
export const ALLOWED_FILL_STATE_KEYS = ['paintState', 'opacityState', 'blendModeState']
export const ALLOWED_STROKE_KEYS = [
    ...ALLOWED_FILL_KEYS,
    'width',
    'growDirection',
    'offset',
    'dash',
    'gap',
    'cap',
    'capSize',
    'join',
    'joinSize',
    'miter',
    'ends'
]
export const ALLOWED_STROKE_STATE_KEYS = [
    ...ALLOWED_FILL_STATE_KEYS,
    'widthState',
    'growDirectionState',
    'offsetState',
    'dashState',
    'gapState',
    'capState',
    'capSizeState',
    'joinState',
    'joinSizeState',
    'miterState',
    'endsState'
]
export const ALLOWED_SHADOW_KEYS = [
    ...ALLOWED_LAYER_KEYS,
    ...PAINT_CATEGORY_PROPS_MAP.solid,
    'offsetX',
    'offsetY',
    'blur',
    'spread',
]
export const ALLOWED_SHADOW_STATE_KEYS = [
    ...ALLOWED_FILL_STATE_KEYS,
    'offsetXState',
    'offsetYState',
    'blurState',
    'spreadState'
]

export const ALLOWED_TRIM_PATH_KEYS = [
    ...Object.keys(DEFAULT_TRIM_PATH_DATA)
]

export const EI_LAYER_ALLOWED_KEYS = {
    [LayerType.FILL]: ALLOWED_FILL_KEYS,
    [LayerType.STROKE]: ALLOWED_STROKE_KEYS,
    [LayerType.SHADOW]: ALLOWED_SHADOW_KEYS,
    [LayerType.INNER_SHADOW]: ALLOWED_SHADOW_KEYS
}

export const EI_LAYER_ALLOWED_STATE_KEYS = {
    [LayerType.FILL]: ALLOWED_FILL_STATE_KEYS,
    [LayerType.STROKE]: ALLOWED_STROKE_STATE_KEYS,
    [LayerType.SHADOW]: ALLOWED_SHADOW_STATE_KEYS,
    [LayerType.INNER_SHADOW]: ALLOWED_SHADOW_STATE_KEYS
}

export const EI_SCREEN_ALLOWED_KEYS = [
    'elementType',
    'width',
    'height',
    'aspectRatioLocked',
    'overflowX',
    'overflowY',
]

export const GRADIENT_PAINT_SET = new Set([
    PaintType.GRADIENT_LINEAR,
    PaintType.GRADIENT_RADIAL,
    PaintType.GRADIENT_ANGULAR,
    PaintType.GRADIENT_DIAMOND
])

export const PAINT_CATEGORY_MAP = {
    [PaintType.SOLID]: 'solid',
    [PaintType.IMAGE]: 'image',
    [PaintType.GRADIENT_LINEAR]: 'gradient',
    [PaintType.GRADIENT_RADIAL]: 'gradient',
    [PaintType.GRADIENT_ANGULAR]: 'gradient',
    [PaintType.GRADIENT_DIAMOND]: 'gradient',
}

export const ARROW_KEY_DEBOUNCE_TIME = 500

export const FILL_STATE_MAP_ARRAY = [
    ['paintState', 'paint'],
    ['opacityState', 'opacity'],
    ['blendModeState', 'blendMode'],
]
export const FILL_NAME_STATE_MAP_ARRAY = FILL_STATE_MAP_ARRAY.map(pair => {
    return [...pair].reverse()
})
export const STROKE_STATE_MAP_ARRAY = [
    ['widthState', 'width'],
    ['growDirectionState', 'growDirection'],
    ['offsetState', 'offset'],
    ['dashState', 'dash'],
    ['gapState', 'gap'],
    ['capState', 'cap'],
    ['capSizeState', 'capSize'],
    ['joinState', 'join'],
    ['joinSizeState', 'joinSize'],
    ['miterState', 'miter'],
    ['endsState', 'ends']
]
export const STROKE_NAME_STATE_MAP_ARRAY = STROKE_STATE_MAP_ARRAY.map(pair => {
    return [...pair].reverse()
})
export const SHADOW_STATE_MAP_ARRAY = [
    ['offsetXState', 'offsetX'],
    ['offsetYState', 'offsetY'],
    ['blurState', 'blur'],
    ['spreadState', 'spread']
]
export const SHADOW_NAME_STATE_MAP_ARRAY = SHADOW_STATE_MAP_ARRAY.map(pair => {
    return [...pair].reverse()
})
export const LAYER_PROP_STATE_NAME_MAP = {
    [LayerType.FILL]: new Map([
        ...FILL_STATE_MAP_ARRAY,
    ]),
    [LayerType.STROKE]: new Map([
        ...FILL_STATE_MAP_ARRAY,
        ...STROKE_STATE_MAP_ARRAY
    ]),
    [LayerType.SHADOW]: new Map([
        ...FILL_STATE_MAP_ARRAY,
        ...SHADOW_STATE_MAP_ARRAY
    ]),
    [LayerType.INNER_SHADOW]: new Map([
        ...FILL_STATE_MAP_ARRAY,
        ...SHADOW_STATE_MAP_ARRAY
    ])
}
export const LAYER_PROP_NAME_STATE_MAP = {
    [LayerType.FILL]: new Map([
        ...FILL_NAME_STATE_MAP_ARRAY,
    ]),
    [LayerType.STROKE]: new Map([
        ...FILL_NAME_STATE_MAP_ARRAY,
        ...STROKE_NAME_STATE_MAP_ARRAY
    ]),
    [LayerType.SHADOW]: new Map([
        ...FILL_NAME_STATE_MAP_ARRAY,
        ...SHADOW_NAME_STATE_MAP_ARRAY
    ]),
    [LayerType.INNER_SHADOW]: new Map([
        ...FILL_NAME_STATE_MAP_ARRAY,
        ...SHADOW_NAME_STATE_MAP_ARRAY
    ])
}

export const TRIM_PATH_PROP_STATE_NAME_MAP = [
    ['startState', 'start'],
    ['endState', 'end'],
    ['offsetState', 'offset'],
]
export const TRIM_PATH_PROP_NAME_STATE_MAP = TRIM_PATH_PROP_STATE_NAME_MAP.map(pair => {
    return [...pair].reverse()
})

export const EFFECT_PROP_STATE_NAME_MAP = {
    [EffectType.TRIM_PATH]: new Map([
        ...TRIM_PATH_PROP_STATE_NAME_MAP
    ])
}
export const EFFECT_PROP_NAME_STATE_MAP = {
    [EffectType.TRIM_PATH]: new Map([
        ...TRIM_PATH_PROP_NAME_STATE_MAP
    ])
}

export const ALWAYS_UPDATE_WITH_UNIT_CHANGE = new Set()

export const UNIT_CHANGE_PROPS_MAP = new Map()

export const AVAILABLE_UNIT_CHANGE = new Map()

export const SHOULD_UPDATE_ALL_KFS_WITH_UNIT_CHANGES = new Map()

export const EI_EFFECT_ALLOWED_KEYS_SET = {
    [EffectType.TRIM_PATH]: new Set([
        'start',
        'end',
        'offset',
        'mode',
        IS_MIX
    ])
}

export const EI_EFFECT_ALLOWED_STATE_KEYS = {
    [EffectType.TRIM_PATH]: new Set([
        'startState',
        'endState',
        'offsetState',
        'modeState',
    ])
}

export const EffectList = [
    'effects'
]


export const NOT_ALLOWED_PASTE_KEYFRAME_PROPERTIES = new Set([
    "pathMorphing",
    "cornerRadius",
])

export const NOT_ALLOWED_PASTE_KEYFRAME_PROPERTIES_BY_ELEMENT_TYPE = {
    [ElementType.SCREEN]: [...generalChildKeySet.keys(), ...childListMap.get('trimPath')],
    [ElementType.CONTAINER]: [],
    [ElementType.NORMAL_GROUP]: childListMap.get('dimensions'),
    [ElementType.BOOLEAN_CONTAINER]: childListMap.get('dimensions'),
    [ElementType.MASK_CONTAINER]: [...childListMap.get('dimensions'), ...childListMap.get('trimPath')],
    [GeometryType.RECTANGLE]: [],
    [GeometryType.ELLIPSE]: [],
    [GeometryType.POLYGON]: childListMap.get('dimensions'),
};
