import { WorkspaceType } from '@phase-software/types'

import { MenuOptionProps } from '../components/shared/Menu/MenuOption'
import { MY_WORKSPACE, MY_WORKSPACE_DESCRIPTION, TEAM_WORKSPACE_DESCRIPTION } from '../constant'
import { TeamFieldsFragment, UserFieldsFragment } from '../generated/graphql'
import { getWorkspaceIndexPath } from './pathGenerators'

export const transformUser = (user: UserFieldsFragment): MenuOptionProps => ({
  name: MY_WORKSPACE,
  value: getWorkspaceIndexPath(WorkspaceType.PERSONAL, user.custom_slug || ''),
  avatarImage: user.avatar,
  description: MY_WORKSPACE_DESCRIPTION
})
export const transformTeamList = (teams: TeamFieldsFragment[]): MenuOptionProps[] =>
  teams.map((team) => ({
    name: team.name,
    value: getWorkspaceIndexPath(WorkspaceType.TEAM, team.custom_slug || ''),
    avatarImage: team.icon,
    description: TEAM_WORKSPACE_DESCRIPTION,
    ownerId: team.owner_id,
    teamId: team.id
  }))
