// FIXME: should not use utils/data
import React, { useCallback, useEffect, useRef, useState } from 'react'

import { Player } from '@lottiefiles/react-lottie-player'
import { DataStore, Migrator } from '@phase-software/data-store'
import { LottieConverter, PhaseConverter } from '@phase-software/lottie-exporter'
import { LottieImporter } from '@phase-software/lottie-importer'
import { cleanupRenderer, init, resize } from '@phase-software/renderer'
import { svg2Lottie } from '@phase-software/svg-importer'
import { BlendMode } from '@phase-software/types'

import { waitUntil } from '../../utils/data'
import { BackgroundToggle } from './BackgroundToggle'
import { FeatureList } from './FeatureList'
import { FileExporter } from './FileExporter'
import { PlayControls } from './PlayControls'
import { PreviewBox } from './PreviewBox'
import { Stats } from './Stats'

document.body.style.background = '#000'

const stats = new Stats()
stats.loadFromOPFS()

const dataStore = new DataStore()
dataStore.set('state', 'VIEWING')

window.dataStore = dataStore

const migrator = new Migrator(DataStore.version)

const translateList = ['0', 'calc(100% + 16px)', 'calc(-100% - 16px)']

export const readFileAsDataURL = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.onload = (e) => {
      if (e.target !== null) {
        resolve(e.target.result)
      }
    }
    reader.readAsDataURL(file)
  })
}

const uploadImage = async (name, blob) => {
  const dataUrl = await readFileAsDataURL(blob)
  return { id: name, download_url: dataUrl }
}

const importer = new LottieImporter(new DataStore(), { uploadImage })
const lottieConverter = new LottieConverter()

// renderer
let inited = false
const canvas = document.createElement('canvas')

const adjustZoomLevel = () => {
  if (!dataStore.workspace.watched) {
    return
  }
  const screen = dataStore.workspace.watched.children[0]
  const bbox = dataStore.drawInfo.vs.getRenderItem(screen.get('id')).bbox
  const scale = Math.max(bbox.width, bbox.height) / canvas.getBoundingClientRect().width || 1
  dataStore.eam.changeZoom(1 / scale)
  dataStore.drawInfo.vs.viewport.moveTo(bbox)
}
const resizeObserver = new ResizeObserver(() => {
  adjustZoomLevel()
})

resizeObserver.observe(canvas)

const delay = (ms = 0) => new Promise((resolve) => setTimeout(resolve, ms))

const LottiePage = () => {
  const selectedRef = useRef('')
  const [recording, setRecording] = useState(false)
  const [recordIndex, setRecordIndex] = useState(0)
  const [loading, setLoading] = useState(false)
  const [stage, setStage] = useState('IDLE')
  const [play, setPlay] = useState(false)
  const [progress, setProgress] = useState(0)
  const [v, setV] = useState(0)
  const [posIdx, setPosIdx] = useState(0)

  const [svgSource, setSvgSource] = useState()
  const [lottieSource, setLottieSource] = useState()
  const [phaseSource, setPhaseSource] = useState()
  const [exportResult, setExportResult] = useState()

  const [time, setTime] = useState(0)
  const [maxTime, setMaxTime] = useState(0)

  const sourceRef = useRef()
  const previeweRef = useRef()
  const canvasRef = useRef()

  // sync with hooks/useFileActions.ts
  const postProcessLottieFile = useCallback((dataStore) => {
    const screen = dataStore.workspace.watched.children[0]
    const defaultProps = {
      blendMode: BlendMode.PASS_THROUGH,
      opacity: 1,
      scaleX: 1,
      scaleY: 1,
      skewX: 0,
      skewY: 0
    }

    const queue = screen.children.filter((el) => el.isContainer)
    while (queue.length) {
      const element = queue.shift()
      if (element.isContainer) {
        queue.push(...element.children.filter((el) => el.isContainer))
      }

      if (element.isNormalGroup) {
        const props = element.gets('opacity', 'blendMode', 'scaleX', 'scaleY', 'skewX', 'skewY')
        const isDefaultProp = Object.keys(props).every((prop) => props[prop] === defaultProps[prop])
        const isAnimated = dataStore.interaction.getElementTrackIdByElementId(element.get('id'))
        const hasEffect = element.hasEffect
        const isInvalidWorldInv =
          dataStore.drawInfo.vs.indexer.getNode(element.get('id')).item.transform.world.basis_determinant() === 0
        const canUngroup = !isAnimated && !hasEffect && isDefaultProp && !isInvalidWorldInv

        if (canUngroup || !element.children.length) {
          dataStore.ungroupContainer(element)
          dataStore.workspace.watched.fireSceneTreeChanges({ undoable: false })
        }
      }
    }
  }, [])

  useEffect(() => {
    canvasRef.current.appendChild(canvas)
  }, [])

  useEffect(() => {
    if (stage === 'LOAD') {
      setLoading(true)
    }
    if (stage === 'LOADED') {
      setLoading(false)
    }
  }, [stage, setLoading])

  // load from opfs
  const loadFile = useCallback(
    async (fileHandle, index) => {
      setLoading(true)
      const file = await fileHandle.getFile()
      const content = await file.text()
      selectedRef.current = file.name
      setRecordIndex(index)
      if (file.name.endsWith('.svg')) {
        setSvgSource(content)
      } else {
        const json = JSON.parse(content)
        if (file.name.endsWith('.phase')) {
          setLottieSource(null)
          setPhaseSource(json)
        } else {
          setLottieSource(json)
        }
      }
    },
    [setLottieSource, setPhaseSource, setLoading, setSvgSource]
  )

  // sync player time
  useEffect(() => {
    dataStore.transition.setPlayheadTime(time)
    const pf = (time / 1000) * 60
    const sf = (time / 1000) * lottieSource?.fr
    sourceRef.current?.setSeeker(sf, false)
    previeweRef.current?.setSeeker(pf, false)
  }, [time, lottieSource])

  // SVG => lottie
  useEffect(() => {
    if (svgSource) {
      const size = JSON.stringify(svgSource).length
      const parser = new DOMParser()
      const doc = parser.parseFromString(svgSource, 'image/svg+xml')
      const start = Date.now()
      const lottie = JSON.parse(JSON.stringify(svg2Lottie(doc.documentElement)))
      const duration = Date.now() - start
      stats.setSVG(selectedRef.current, duration, size)
      setLottieSource(lottie)
    }
  }, [svgSource, setLottieSource])

  // lottie => phase (importer)
  useEffect(() => {
    if (lottieSource) {
      setTime(0)
      setPlay(false)
      setStage('IMPORT')
      const size = JSON.stringify(lottieSource).length
      const start = Date.now()
      importer.parse(lottieSource, setProgress).then((phaseFormat) => {
        const duration = Date.now() - start
        stats.setLottie(selectedRef.current, duration, size, importer.usedFeatures)
        setPhaseSource(phaseFormat)
      })
    }
  }, [lottieSource, setTime, setPlay, setStage])

  // phase load
  useEffect(() => {
    let start
    const handleLoad = () => {
      const promise = inited
        ? Promise.resolve()
        : init(canvas, dataStore, false).then(async () => {
            dataStore.eam.toggleRuler()
            await waitUntil(() => dataStore.drawInfo)
          })

      promise.then(async () => {
        inited = true

        const { maxTime } = dataStore.interaction.getAction(dataStore.interaction._getCurrentActionId())
        setMaxTime(maxTime)

        await delay(33)
        if (selectedRef.current.endsWith('json')) {
          postProcessLottieFile(dataStore)
        }
        const duration = new Date() - start
        const size = JSON.stringify(dataStore.save()).length
        stats.setPhase(selectedRef.current, duration, size)

        adjustZoomLevel()
        setStage('LOADED')
      })
    }

    if (phaseSource) {
      dataStore.clear()
      if (inited) {
        cleanupRenderer()
      }
      resize()
      setStage('LOAD')
      start = Date.now()
      migrator.migrate(phaseSource).then(() => {
        dataStore.load(phaseSource)
      })
    }

    dataStore.once('LOAD', handleLoad)
    return () => {
      dataStore.off('LOAD', handleLoad)
    }
  }, [phaseSource, setStage, postProcessLottieFile])

  // load to DS
  useEffect(() => {}, [postProcessLottieFile])

  const updateBackground = useCallback((v) => {
    document.querySelectorAll('.preview-box').forEach((el) => {
      el.style.background = `rgb(${v * 255}, ${v * 255}, ${v * 255})`
    })
    if (dataStore.workspace.watch && selectedRef.current.endsWith('.json')) {
      const screen = dataStore.workspace.watch.children[0]
      const fillComponent = dataStore.library.getComponent(screen.base.fills[0])
      const fill = dataStore.library.getLayer(fillComponent.layers[0])
      fill.paint.opacity = 0
      dataStore.drawInfo?.vs.setBackgroundColor({ r: v, g: v, b: v })
      dataStore.drawInfo?.vs.indexer.getScreenNode().item.update()
    }
  }, [])

  // phase => lottie
  useEffect(() => {
    updateBackground(v)
    if (stage === 'LOADED') {
      // action mode (force update)
      dataStore.eam.switchMode(1)
      dataStore.eam.switchMode(0)
      dataStore.eam.switchMode(1)

      setStage('EXPORT')
      const start = Date.now()

      // FIXME: not new instance
      const phaseConverter = new PhaseConverter({ fps: 60, speed: 1, start: 0, end: maxTime })
      phaseConverter.toIR(dataStore).then((ir) => {
        const lottie = lottieConverter.fromIR(ir)
        const duration = Date.now() - start
        const size = JSON.stringify(lottie).length
        stats.setExport(selectedRef.current, duration, size)
        setExportResult(lottie)
        setStage('DONE')
      })
    }
  }, [v, maxTime, stage, updateBackground])

  // use context
  const handleRecord = () => {
    console.time('recording')
    setRecording(true)
  }

  useEffect(() => {
    if (recording) {
      const fileNodeList = document.querySelectorAll('.js-file')
      const fileNode = fileNodeList[recordIndex]
      if (fileNode) {
        fileNode.click()
      } else {
        setRecording(false)
        setRecordIndex(0)
        console.timeEnd('recording')
      }
    }
  }, [recording, recordIndex, setRecording, setRecordIndex])

  useEffect(() => {
    if (stage === 'DONE') {
      setRecordIndex((i) => i + 1)
    }
  }, [stage])

  const togglePos = useCallback(() => {
    setPosIdx((s) => (s + 1) % translateList.length)
  }, [setPosIdx])

  const record = stats.getRecord(selectedRef.current)
  const pointerEvents = loading ? 'pointer-events-none cursor-wait' : 'pointer-events-auto'
  return (
    <div className={`w-screen h-screen flex text-lg leading-normal ${pointerEvents}`}>
      <div className="w-[200px] flex-shrink-0">
        <FileExporter
          onSelect={loadFile}
          onRecord={handleRecord}
          recording={recording}
          recordIndex={recordIndex}
          stats={stats}
        />
      </div>
      <div className="p-16 flex-grow flex flex-col h-full">
        <div className="flex justify-between">
          <h1>
            {selectedRef.current ? `${selectedRef.current} - ` : ''}
            {stage} {progress !== 0 && progress !== 1 && `(${(progress * 100).toFixed(2)}%)`}
          </h1>
          <BackgroundToggle gray={v} onChange={setV} />
        </div>
        <div className="flex-grow flex-shrink">
          <div className="grid grid-flow-col gap-16 auto-cols-fr">
            <PreviewBox title="SVG" fileSize={record?.svgSize} duration={record?.svgTime}>
              <div className="w-full h-full svg-preview" dangerouslySetInnerHTML={{ __html: svgSource }} />
            </PreviewBox>
            <style>{`.svg-preview > svg { width: 100% !important; height: 100% !important;}`}</style>
            <PreviewBox title="Import" fileSize={record?.importSize} duration={record?.importTime}>
              <Player ref={sourceRef} src={JSON.stringify(lottieSource)} keepLastFrame />
            </PreviewBox>
            <PreviewBox
              title="Phase"
              fileSize={record?.phaseSize}
              duration={record?.phaseTime}
              onClick={togglePos}
              translate={translateList[posIdx]}
            >
              <div className="w-full h-full" ref={canvasRef} />
            </PreviewBox>
            <PreviewBox title="Export" fileSize={record?.exportSize} duration={record?.exportTime}>
              <Player ref={previeweRef} src={JSON.stringify(exportResult)} keepLastFrame />
            </PreviewBox>
          </div>
          <FeatureList features={Array.from(importer.usedFeatures)} />
        </div>
        <div className="p-32">
          <PlayControls play={play} onPlayToggle={setPlay} time={time} onTimeChange={setTime} maxTime={maxTime} />
        </div>
      </div>
    </div>
  )
}

export default LottiePage
