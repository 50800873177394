import PropertyStack from '../../PropertyStack'

class CapShapeStack extends PropertyStack {
  constructor(elementStack, data) {
    super(elementStack, data)
    this.type = 'CAP_SHAPE'
    this.key = 'capShape'
    this.dataKey = 'cap'
    this.animatableProperties = new Set()
  }
}

export default CapShapeStack
