import React from 'react'
import { useTranslation } from 'react-i18next'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import ImportFailedDialog from '../../../components/modals/ImportFailedDialog'
import { Button, Tooltip } from '../../../components/shared'
import { FEATURE_KEYS } from '../../../growthbook-feature-keys'
import useImportFile from '../../../hooks/useImportFile'

const ImportFileButton = ({ projectId }: { projectId: string }) => {
  const { t } = useTranslation(['file', 'workspace', 'common'])
  const canImportPhase = useFeatureIsOn(FEATURE_KEYS.IMPORT_PHASE_FILE)

  const importErrorMessage = canImportPhase
    ? t('file:phase_enabled_import_error_message')
    : t('file:phase_disabled_import_error_message')

  const { handleImportFile, loading, error, setError } = useImportFile({
    projectId,
    canImportPhase,
    importErrorMessage
  })

  const tooltip = canImportPhase
    ? t('workspace:tooltip.allow_import_phase_file_description')
    : t('workspace:tooltip.no_import_phase_file_description')

  return (
    <>
      <Tooltip content={tooltip}>
        <Button
          variant="solid"
          color="secondary"
          size="l"
          data-test-id="import-button"
          onClick={handleImportFile}
          disabled={loading}
        >
          {t('common:import')}
        </Button>
      </Tooltip>
      <ImportFailedDialog error={error} setError={setError} />
    </>
  )
}

export default ImportFileButton
